import { createContext } from 'react';
import { InternalEventPropsType, TransformationListType } from './types';
import { MappedValueTransformationOptionType } from './components/types';

type ContextType = {
  canEditProfiles: boolean;
  transformations: TransformationListType[];
  valTransforms?: MappedValueTransformationOptionType[];
  internalProps?: InternalEventPropsType[];
  refetchTransformations: () => void;
  setMappingPath?: (path: string) => void;
};

const defaultValues = {
  valTransforms: [],
  internalProps: [],
  canEditProfiles: false,
  transformations: [],
  setMappingPath: (path: string) => path,
  refetchTransformations: () => {
    // intentionally left empty
  },
};

export const TransformationContext = createContext<ContextType>(defaultValues);
