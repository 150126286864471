/**
 * @description Angular main module for Profiles -> Engagements -> Dashboard
 * @memberOf webUi.ui.profiles.engagements
 * @namespace webUi.ui.profiles.engagements.dashboard
 */
angular.module('webUi.ui.profiles.engagements.dashboard',
    [
        'webUi.ui.profiles.engagements.base'
    ]
).config(['$stateProvider',
    function config($stateProvider) {
        $stateProvider
            .state('site.profiles.engagements.dashboard', {
                url: '/dashboard',
                isReact: true
            }
            );
    }
]);
