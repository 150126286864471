export const getMandatoryProperties = availableFilters => {
  const mandatoryFilters = availableFilters.filter(({ mandatory }) => mandatory);
  return mandatoryFilters.map(({ dataField, availableConstraints, restrictedValues }) => ({
    dataField,
    // Filters with "mandatory": true have defaultConstraint value in "availableConstraints" instead of "defaultConstraint"
    defaultConstraint: availableConstraints[0],
    constraint: availableConstraints[0],
    filterValue: restrictedValues[0],
  }));
};

export const mapFiltersForDynamicTimeTrigger = availableFilters => {
  if (!availableFilters.length) return [];

  const { dataField, dataFieldId } = availableFilters[0];

  return [
    {
      dataField,
      dataFieldId, // This is needed for Dynamic trigger rule for Backend to know which variable to point to
      filterValue: '',
    },
  ];
};
