angular.module('webUi.ui.tagmanagement.deploy',[])

    .controller('TagmanagementDeployController', ['dialogsModel', '$scope', '$uibModalInstance',
        function TagmanagementDeployController(dialogsModel, $scope, $uibModalInstance) {

            $scope.configurationSnapshot = dialogsModel.configurationSnapshot;
            $scope.environment = dialogsModel.environment;

            $scope.accept = function() {
                $uibModalInstance.close(true);
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
