import React, { useContext, useEffect, useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';
import { isEqual } from 'lodash';
import * as Yup from 'yup';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { fetchJourneyTree, updateExperiments } from '~/workflows/dataService';
import { TExperiment } from '~/workflows/types';
import { AudienceContext } from '../AudienceContext';
import Actions from './ActionButtons';
import DirtyFormPopup from './DirtyFormPopup';
import { useDirtyFormHandler } from '../hooks';
import StepHeader from './StepHeader';

const validationSchema = Yup.object().shape({
  experiments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(i18n.t('validation:validation.required')),
      weight: Yup.number()
        .required(i18n.t('validation:validation.required'))
        .min(0, i18n.t('validation:validation.minLength', { min: 0 })),
    }),
  ),
});
const defaultExperimentValue: TExperiment = { name: '', weight: 0 };

const ExperimentsConfiguration = () => {
  const { processDirtyForm, showDirtyFormPopup, setShowDirtyFormPopup, cbRef } = useDirtyFormHandler();
  const { experimentSteps, triggerId, workflowId, goToNextStep, setTree, isOptional } = useContext(AudienceContext);
  const [showConfigure, setShowConfigure] = useState(false);

  const handleSubmit = async (values: { experiments: typeof experimentSteps }) => {
    const payload = values.experiments.map(({ name, weight, nodeId }: TExperiment) => ({
      name,
      weight,
      stepId: nodeId || '',
    }));
    if (!isEqual(values?.experiments, experimentSteps)) {
      await updateExperiments(payload, workflowId, triggerId || '');
      showSuccess({ body: i18n.t('workflow:create.steps.step3.api.updated') });
      const res = await fetchJourneyTree(workflowId);
      setTree(res);
    }
    goToNextStep();
  };

  const hasWeightError = (values: { experiments: typeof experimentSteps }) =>
    values.experiments.reduce((acc: number, experiment: TExperiment) => acc + (experiment.weight || 0), 0) !== 100;

  const hasUniqueNameError = (values: { experiments: typeof experimentSteps }) => {
    const names = values?.experiments?.map(({ name }) => name.toLowerCase()) || [];

    return names.length !== new Set(names).size;
  };

  const hasValidationsError = (values: { experiments: typeof experimentSteps }) =>
    hasWeightError(values) || hasUniqueNameError(values);

  useEffect(() => {
    if (experimentSteps.length > 0) {
      setShowConfigure(true);
    }
  }, [experimentSteps]);

  return (
    <>
      <div className="w-2/5 px-2">
        <StepHeader title={i18n.t('workflow:create.steps.step3.title')} isOptional={isOptional} />

        <p className="py-2 text-gray-600">{i18n.t('workflow:create.steps.step3.header')}</p>
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{ experiments: [...experimentSteps] }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isValid, errors, values, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} className="p-2 py-4">
              {!showConfigure && (
                <BtnOutlined
                  color="gray"
                  size="sm"
                  onClick={() => {
                    setShowConfigure(true);
                    setFieldValue('experiments', [{ name: '', weight: 100 }]);
                  }}
                >
                  {i18n.t('workflow:create.steps.step3.configureBtn')}
                </BtnOutlined>
              )}
              {showConfigure && (
                <FieldArray
                  name="experiments"
                  render={arrayHelpers => (
                    <div>
                      {values.experiments.map((_experiment, index) => (
                        <div className="mb-3 flex" key={index}>
                          <Field
                            name={`experiments[${index}].name`}
                            as={FormikInputField}
                            className="w-2/3"
                            label={i18n.t('workflow:create.steps.step3.form.variantName')}
                            placeholder={i18n.t('workflow:common.placeholder')}
                            errorText={(errors?.experiments?.[index] as unknown as TExperiment)?.name}
                          />
                          <Field
                            name={`experiments[${index}].weight`}
                            as={FormikInputField}
                            className="w-1/3 px-2"
                            type="number"
                            label={i18n.t('workflow:create.steps.step3.form.weight')}
                            placeholder={i18n.t('workflow:common.placeholder')}
                            errorText={(errors?.experiments?.[index] as unknown as TExperiment)?.weight}
                          />
                          <div className="flex w-1/6 justify-center pt-10">
                            <BtnIcon
                              icon="delete"
                              testHook="removeExperiment"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </div>
                        </div>
                      ))}

                      <BtnOutlined
                        testHook="addExperiment"
                        icon="add"
                        onClick={() => arrayHelpers.push(defaultExperimentValue)}
                        color="gray"
                        size="sm"
                        className="my-4"
                        disabled={values.experiments.length === 3}
                      >
                        {i18n.t('workflow:create.steps.step3.addVariant')}
                      </BtnOutlined>

                      {hasWeightError(values) && (
                        <Notification kind="error" className="my-3">
                          {i18n.t('workflow:create.steps.step3.validation.experimentMaxSum100')}
                        </Notification>
                      )}

                      {hasUniqueNameError(values) && (
                        <Notification kind="error" className="my-3">
                          {i18n.t('workflow:create.steps.step3.validation.uniqueName')}
                        </Notification>
                      )}
                    </div>
                  )}
                />
              )}
              <Actions
                checkByIsConfirm
                onConfirm={handleSubmit}
                isConfirmEnabled={
                  values.experiments.length === 0 ||
                  (isValid && !hasValidationsError(values) && showConfigure && values.experiments.length >= 2)
                }
                confirmText={i18n.t(
                  `workflow:actions.${
                    values.experiments.length === 0 && experimentSteps.length === 0 ? 'skip' : 'next'
                  }`,
                )}
                dirty={dirty}
                processDirtyForm={processDirtyForm}
              />
            </form>
          )}
        </Formik>
      </div>

      <DirtyFormPopup {...{ cbRef, showDirtyFormPopup, setShowDirtyFormPopup }} />
    </>
  );
};

export default ExperimentsConfiguration;
