import React from 'react';
import PropTypes from 'prop-types';

const ElementError = ({ error }) => (
  <div className="Element-errors">
    <span className="Element-error">{error}</span>
  </div>
);

ElementError.propTypes = {
  error: PropTypes.node.isRequired,
};

export default ElementError;
