import { sanitize } from '~/common';

/**
 * A service for AdServing related queries
 */
angular.module('webUi.service.content.creative.preview', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('CreativePreviewService', ['Restangular', '$q', '$uibModal', 'ModalManagementService',  function(Restangular, $q, $uibModal, ModalManagementService){

        // the base path through which all calls should go
        var BASE_PATH = '/assets/';
        var restangularInstance = Restangular.withConfig(function(Configurer) {
            Configurer.setBaseUrl(BASE_PATH);
            Configurer.setResponseExtractor(function(response){
                return response.content;
            });
        });

        return {

            openCreativePreviewPopup: function(ad, creative, dataFeedKeys, allowMulti) {

                var dialogsModel = {
                    ad: ad,
                    creative: creative,
                    dataFeedKeys: dataFeedKeys,
                    allowMulti: allowMulti,
                    isRequestParameter: !_.isEmpty(ad.placementParameter)
                };

                var modalOptions = { css : 'modal-huge' };

                var d = ModalManagementService.openDialog('AdservingCreativesPreviewController', 'ui/content/campaigns/ads/creatives/preview/preview.tpl.html', dialogsModel, modalOptions);
                return d.result;
            },

            handlePreview: function(ad, creative, hasDataManagementModule, previousState, activatedByHotkey) {

                var wrapperPromise = $q.defer();

                var activateDataFeedDialog = function  activateDataFeedDialog(ad, previousState){
                    var dataFeedDialog = ModalManagementService.openDialog('DataFeedKeyChooserController', 'ui/content/campaigns/ads/creatives/form/feedKeyChooser.tpl.html', {
                        ad: ad,
                        previousState: previousState
                    });
                    return dataFeedDialog.result;
                };

                // For HTML creative, if the Data module is enabled, before the preview open a popup to ask the user
                // to select the feed key. Render the preview only after the selection
                var self = this;
                if (hasDataManagementModule && creative.content.clazz === 'HtmlCreativeContent' && !_.isUndefined(ad.dataFeed) && !_.isNull(ad.dataFeed) && !_.isUndefined(ad.dataFeed.dataFeedId)) {
                // check if there was a previous state and it is activated by hot key to directly enable preview
                    if(previousState && activatedByHotkey) {
                        self.openCreativePreviewPopup(ad, creative, previousState.keys, previousState.allowMulti).then(function(result){
                            wrapperPromise.resolve(result);
                        });
                    } else {
                        var dataFeedDialog = activateDataFeedDialog(ad, previousState, wrapperPromise);
                        dataFeedDialog.then(function(dataFeedDialogResult) {
                            if (dataFeedDialogResult && !_.isEmpty(dataFeedDialogResult.keys)) {
                                self.openCreativePreviewPopup(ad, creative, dataFeedDialogResult.keys, dataFeedDialogResult.allowMulti).then(function(){
                                    wrapperPromise.resolve(dataFeedDialogResult);
                                });
                            } else {
                                wrapperPromise.resolve(false);
                            }
                        });
                        dataFeedDialog['catch'](function() {
                            wrapperPromise.reject();
                        });
                        dataFeedDialog['finally'](function(){
                        });
                    }
                } else {
                    self.openCreativePreviewPopup(ad, creative, [], false).then(function(result){
                        wrapperPromise.resolve(result);
                    });
                }
                return wrapperPromise.promise;
            },

            previewCreative: function(siteNumber, ad, creative, dataFeedKeys, multiple) {
                var preview = $q.defer();

                if (_.isEqual(creative.content.clazz, 'RedirectCreativeContent')) {
                    preview.resolve('<a href="' + sanitize(creative.content.url, true) + '" target="_blank">Redirect link</a>');
                } else {
                // For HTML/Image/Flash creatives, make a call to the server to get the correct content (with replaced placeholders, etc)
                    restangularInstance.one(siteNumber + '/content/campaigns/ads/preview').post('creative', {
                        ad: ad,
                        creative: creative,
                        dataFeedKeys: dataFeedKeys,
                        multiple: multiple
                    }).then(function(result){
                        preview.resolve(result);
                    });
                }
                return preview.promise;
            }
        };
    }]);
