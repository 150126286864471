import React, { useState } from 'react';
import { Column, useTable, UseTableRowProps } from 'react-table';
import moment from 'moment';
import cx from 'classnames';
import i18n from '~/i18n';
import { Empty, Link, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import { buildUrl } from '~/common';
import DeletePopup from '~/components/src/DeletePopup';
import { showSuccess } from '~/notificationCenter';
import { deleteTransformation } from './dataService';
import { TransformationListType, TransformationsTableProps, TransformationTableRowType } from './types';

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm:ss';

const filterTransformations = (transformations: TransformationListType[], query: string) =>
  query
    ? transformations.filter(transformation => transformation.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    : transformations;

const EmptyList = () => (
  <Empty
    className="!top-2"
    header={i18n.t('transformations:list.emptyHeader')}
    body={i18n.t('transformations:list.emptyBody')}
  />
);

function TransformationsTable({
  transformationsList,
  transformationQuery,
  canEditProfiles,
  refetchTransformation,
  setSelectedTransformation,
}: TransformationsTableProps): React.ReactElement {
  const [selectDeleteTransformation, setSelectDeleteTransformation] = useState<TransformationListType | null>(null);

  const handleDeleteTransformation = async (transformationId: string) => {
    await deleteTransformation(transformationId);
    refetchTransformation();
    setSelectDeleteTransformation(null);
    showSuccess({ header: i18n.t('transformations:form.deleteSuccess') });
  };

  const transformations = filterTransformations(transformationsList, transformationQuery);

  const columns: Column<TransformationTableRowType>[] = React.useMemo(
    () => [
      {
        Header: i18n.t('transformations:columns.name'),
        accessor: 'name',
        className: 'w-[40%]',
        Cell: ({ row }: { row: UseTableRowProps<TransformationTableRowType> }) => (
          <Link
            className="t-transformationName w-[30%]"
            href={buildUrl(`profiles/transformations/view/${row.original.transformationId}`)}
          >
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: i18n.t('transformations:columns.createdAt'),
        accessor: 'createdAt',
        Cell: ({ row }: { row: UseTableRowProps<TransformationTableRowType> }) => (
          <div className="flex flex-col">
            <p className="t-transformationCreatedDate">{moment(row.values.createdAt).format(dateFormat)}</p>
            <small className="t-transformationCreatedTime">{moment(row.values.createdAt).format(timeFormat)}</small>
          </div>
        ),
      },
      {
        Header: i18n.t('transformations:columns.modifiedAt'),
        accessor: 'lastModifiedAt',
        Cell: ({ row }: { row: UseTableRowProps<TransformationTableRowType> }) => (
          <div className="flex flex-col">
            <p className="t-transformationModifiedDate">{moment(row.values.lastModifiedAt).format(dateFormat)}</p>
            <small className="t-transformationModifiedTime">
              {moment(row.values.lastModifiedAt).format(timeFormat)}
            </small>
          </div>
        ),
      },
      {
        Header: i18n.t('transformations:columns.dependants'),
        accessor: 'dependants',
        className: 'flex justify-center',
        Cell: ({ row }: { row: UseTableRowProps<TransformationTableRowType> }) => (
          <div className="flex justify-center">
            {row.values.dependants.length > 0 && (
              <BtnIcon
                color="blue"
                icon="mappingsAndTransformations"
                onClick={() => setSelectedTransformation(row.original)}
                testHook="transformationDependants"
                tooltip={i18n.t('transformations:list.connectors')}
              />
            )}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }: { row: UseTableRowProps<TransformationTableRowType> }) => (
          <div className="flex justify-center">
            {canEditProfiles && (
              <BtnIcon
                icon="delete"
                onClick={() => setSelectDeleteTransformation(row.original)}
                testHook="deleteTransformation"
                disabled={row.original.dependants.length > 0}
                tooltip={i18n.t(
                  `transformations:list.${row.original.dependants.length > 0 ? 'disableDelete' : 'delete'}`,
                )}
              />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: transformations || [],
    autoResetSortBy: false,
    autoResetFilters: false,
  });

  if (transformations?.length === 0) {
    return <EmptyList />;
  }

  return (
    <div className="flex flex-col py-2">
      <Panel className="flex-1 overflow-auto p-0 ">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={cx(
                      't-transformationTableHead border-b bg-gray-50 px-2 py-3 text-sm font-medium',
                      column.className,
                    )}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="t-transformationTableRow border-b last:border-none">
                  {row.cells.map(cell => (
                    <td className="p-2" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Panel>
      {selectDeleteTransformation && (
        <DeletePopup
          title={i18n.t('transformations:list.deleteHeader')}
          hidePopup={() => setSelectDeleteTransformation(null)}
          onClick={() => handleDeleteTransformation(selectDeleteTransformation.transformationId)}
        >
          <Notification testHook="transformationDeleteDescription">
            {i18n.t('transformations:list.deleteBody', {
              name:
                selectDeleteTransformation.name.length > 50
                  ? `${selectDeleteTransformation.name.slice(0, 45)}...`
                  : selectDeleteTransformation.name,
            })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
}

export default TransformationsTable;
