angular.module('webUi.ui.support.cookiepermissionforms.base', ['webUi.ui.support.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.cookiepermissionforms', {
                'abstract': true,
                url: '/cookiepermissionforms'
            });
    }]);

