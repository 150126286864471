import React, { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification, PickerView, SkeletonLoader } from '~/components';
import Btn from '~/components/src/Btn';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import SamplesDialog from './SamplesDialog';
import { TMissedProperty, TSampleRow } from './types';

const columnHelper = createColumnHelper<TMissedProperty & { id: string }>();
const PAGE_SIZE = 15;

interface MissedPropertiesListProps {
  t: TFunction;
  list: TMissedProperty[];
  canEditProfiles: boolean;
  canAccessData: boolean;
  isFetchingProperties: boolean;
  isPropertyBeingAdded: boolean;
  handleAddProperties: (properties: string[]) => void;
}

const MissedPropertiesList: React.FC<MissedPropertiesListProps> = ({
  t,
  list,
  canAccessData,
  canEditProfiles,
  isFetchingProperties,
  isPropertyBeingAdded,
  handleAddProperties,
}) => {
  const [showSamplesDialog, setShowSamplesDialog] = useState(false);
  const [selectedRowSamples, setSelectedRowSamples] = useState<TSampleRow[]>([]);
  const [paginatedList, setPaginatedList] = useState(list);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const handleAddAllProperties = () => {
    const properties = list.map(property => property.name);
    handleAddProperties(properties);
  };

  const handleAddProperty = (row: TMissedProperty) => {
    handleAddProperties([row.name]);
  };

  const handleShowSamples = (samples: TSampleRow[]) => {
    setSelectedRowSamples(samples);
    setShowSamplesDialog(true);
  };

  useEffect(() => {
    const pages = list.length / PAGE_SIZE || 1;
    setTotalPages(Math.ceil(pages));
    setPage(0);
  }, []);

  useEffect(() => {
    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    setPaginatedList(list.slice(start, end));
  }, [page, list]);

  const getSamplesToolTip = (canAccessData: boolean, samplesLength: number) => {
    if (canAccessData) {
      return samplesLength > 0 ? t('actions.samples') : t('actions.noSamples');
    }
    return t('messages.noPermission');
  };

  const renderFoundInSamples = (property: TMissedProperty) => {
    const { frequency, percentage, samples } = property;

    if (!frequency) return <div className="text-center text-xs italic text-gray-500">{t('messages.notFound')}</div>;

    return (
      <div className="grid grid-cols-2 items-center gap-2">
        <div className="flex items-center justify-end gap-2">
          {frequency} <small className="text-r42-blue">({parseFloat(percentage).toFixed(2)}%)</small>
        </div>
        <BtnIcon
          icon="visit"
          className="size-6"
          disabled={!canAccessData || samples?.length === 0}
          onClick={() => handleShowSamples(samples)}
          tooltip={getSamplesToolTip(canAccessData, samples.length)}
        />
      </div>
    );
  };

  const columns = [
    columnHelper.accessor('id', {
      header: () => <div className="text-left">{t('propertiesTable.number')}</div>,
      cell: info => <div className="text-left">{info.row.index + 1}</div>,
      size: 50,
    }),
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{t('propertiesTable.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('frequency', {
      header: () => <div className="text-center">{t('propertiesTable.frequency')}</div>,
      cell: ({ row }) => renderFoundInSamples(row.original),
      size: 200,
    }),
  ];

  const actionsColumn = columnHelper.display({
    id: 'actions',
    header: () => <div className="text-left"></div>,
    cell: info => (
      <div className="text-center">
        <BtnIcon
          icon="add"
          onClick={() => handleAddProperty(info.row.original)}
          tooltip={t(`actions.${isPropertyBeingAdded ? 'adding' : 'addProperty'}`)}
          disabled={isPropertyBeingAdded}
        />
      </div>
    ),
    size: 100,
  });

  const finalColumns = canEditProfiles ? [...columns, actionsColumn] : columns;

  if (isFetchingProperties) return <SkeletonLoader type="image" width="100%" height={180} />;

  return (
    <div className="flex flex-col gap-2">
      <Notification kind="error" className="mt-2 py-3">
        {t('messages.missingPropertiesText')}
      </Notification>
      {list.length > 0 && (
        <div className="flex flex-row-reverse px-2">
          <Btn
            color="blue"
            tooltip={t(`actions.${isPropertyBeingAdded ? 'addingAll' : 'addAll'}`)}
            disabled={isPropertyBeingAdded}
            onClick={handleAddAllProperties}
          >
            {t('actions.addAll')}
          </Btn>
        </div>
      )}
      <UITable
        data={paginatedList ?? []}
        columns={finalColumns}
        page={page}
        totalPages={totalPages}
        enablePagination={totalPages > 1}
        onPaginate={setPage}
        testHook="missedPropertiesTable"
        emptyMessage={t('tabs.missedProperties.empty.header')}
        emptyBody={t('tabs.missedProperties.empty.body')}
        emptyClassName="mt-0"
      />

      {showSamplesDialog && (
        <PickerView
          pickerTitle={t('actions.samples')}
          handlePickerVisibility={() => setShowSamplesDialog(false)}
          className="!h-auto !max-h-[400px] !w-[500px]"
        >
          <SamplesDialog t={t} samples={selectedRowSamples} />
        </PickerView>
      )}
    </div>
  );
};

export default MissedPropertiesList;
