import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import { saveVariant } from '~/support/cookies/actions';
import CookieVariantForm from '~/support/components/CookieForm';
import { Panel } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

export const CookiePage = ({ cancelHref, handleSubmit, submitting, t }) => (
  <Page>
    <Heading title={t('form.header')} />
    <Panel>
      <CookieVariantForm
        {...{
          cancelHref,
          handleSubmit,
          submitting,
          t,
        }}
      />
    </Panel>
  </Page>
);

export default compose(
  withRouter,
  connect(
    () => ({
      cancelHref: buildUrl('support/cookiepermissionforms/list'),
    }),
    dispatch => ({
      onSubmit: values => dispatch(saveVariant(values)),
    }),
  ),
  reduxForm({
    form: 'cookieVariantNew',
  }),
  translate(['cookies']),
)(CookiePage);
