angular.module('webUi.directive.dragToActivate', [])

    .directive('dragToActivate', function() {
        return {
            restrict : 'A',
            scope: {
                draggingNamespace: '@'
            },
            link : function(scope, element, attr) {
                var namespace = scope.draggingNamespace;
                var timeoutHandle;
                var timeout = attr.hoverToActivateTimeout || 500;

                element.bind('dragenter', function() {
                    timeoutHandle = new Date().getTime();
                });

                element.bind('dragover', function() {
                    if(timeoutHandle) {
                        var now = new Date().getTime();
                        if(timeoutHandle + timeout <= now) {
                            element.click();
                            scope.$emit('DRAG-START', namespace);
                        }
                    }
                });
            }
        };
    });
