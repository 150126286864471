angular.module('webUi.ui.test.validation.form', [
    'webUi.ui.test.validation.base',
    'webUi.ui.test.validation.popup'
])
    .config(['$stateProvider', function config($stateProvider) {

        //define the same objects to be resolved for both states just once, no need to duplicate code
        var sharedResolve = {
            numbers: [function () {
                return [1];
            }],
            nationalities: ['TestService', function (TestService) {
                return TestService.getNationalities();
            }]
        };

        $stateProvider.state('site.test.validation.add', {
            url: '/add',
            views: {
                '@site.test': {
                    templateUrl: 'ui/test/validation/form/form.tpl.html',
                    controller: 'ValidFormController as vm'
                }
            },
            leftMenu: true,
            resolve: angular.extend({
                developer: [function() {
                    return {};
                }]
            }, sharedResolve)
        });
        $stateProvider.state('site.test.validation.edit', {
            url: '/{developerId}/edit',
            views: {
                '@site.test': {
                    templateUrl: 'ui/test/validation/form/form.tpl.html',
                    controller: 'ValidFormController as vm'
                }
            },
            leftMenu: true,
            resolve: angular.extend({
                developer: ['TestService', '$stateParams', function(TestService, $stateParams) {
                    return TestService.getDeveloper($stateParams.developerId);
                }]
            }, sharedResolve)
        });
    }])

    .controller('ValidFormController',['$state','$uibModal','$stateParams','PubSubService','TestService','ValidService','ModalManagementService','numbers','nationalities','developer',
        function ValidFormController(   $state,  $uibModal,  $stateParams,  PubSubService,  TestService,  ValidService,  ModalManagementService , numbers,  nationalities,  developer) {

            'use strict';
            
            var vm = this;
            vm.data = {
                showcase: 0,
                developer: developer,
                numbers: numbers,
                nationalities: nationalities
            };

            vm.addNewSkill = function(newSkill) {
                vm.data.developer.skills = vm.data.developer.skills || [];
                PubSubService.publishFormValidate(vm.newSkillForm.$name);
                if(vm.newSkillForm.$valid) {
                    vm.data.developer.skills.push(newSkill);
                    vm.data.newSkill = null;
                }
            };

            vm.addNewLanguage = function(newLanguage) {
                vm.data.developer.languages = vm.data.developer.languages || [];
                PubSubService.publishFormValidate(vm.newLanguageForm.$name);
                if(vm.newLanguageForm.$valid) {
                    vm.data.developer.languages.push(newLanguage);
                    vm.data.newLanguage = null;
                }
            };

            vm.inAllowedNationalities = function (value) {
                return value !== 'UN'; //Ungabunga not allowed
            };

            vm.isEmpty = ValidService.isEmpty;

            vm.isUniqueNumber = function isUniqueNumber(value, existingNumbers) {
                //don't handle cases where it's not a number
                if (ValidService.isNumber(value) && _.contains(existingNumbers, _.parseInt(value))) {
                    return false;
                }
                return true;
            };

            /**
     * Function to verify whether valueToFind is found in valuesToSearch
     * TODO improve this function and move it to the ValidService + unit tests
     *
     * @param valueToFind - the String value to find in the list
     * @param valuesToSearch - list of objects/entities to search in
     * @param idx - the index of the valueToFind IF the value is already in the list (used for editables)
     * @param property - if the valuesToSearch are objects, specify the property to compare
     * @returns {boolean}
     */
            vm.isUniqueValue = function isUniqueValue(valueToFind, valuesToSearch, idx, property) {
                valuesToSearch = valuesToSearch || [];

                //look up the index of "value" in one of the existingValues (in the given property)
                var lookupIndexByProperty = function lookupIndexByProperty() {
                    return _.findIndex(valuesToSearch, function(value) {
                        return value[property] === valueToFind;
                    });
                };

                var lookupIndexByValue = function lookupIndexByValue() {
                    return _.findIndex(valuesToSearch, function(value) {
                        return value === valueToFind;
                    });
                };

                var foundIndex = property ? lookupIndexByProperty() : lookupIndexByValue() ;
                //idx can be undefined, in which case the comparison will pass
                return foundIndex === -1 || idx === foundIndex;
            };

            /**
     * Adds a hobby to the model via a modal
     */
            vm.addHobbyModal = function addHobbyModal() {

                var dialogsModel = function() {
                    return {
                        data: {
                            developer: vm.data.developer,
                            //for convenience, the function should be extracted to service soon
                            isUniqueCallback: vm.isUniqueValue
                        },
                        onSave: function (newHobby) {
                            if (newHobby) {
                                vm.data.developer.hobbies = vm.data.developer.hobbies || [];
                                vm.data.developer.hobbies.push(newHobby);
                            }
                        }
                    };
                };

                ModalManagementService.openDialog(
                    'ValidationAddNewHobbyController',
                    'ui/test/validation/form/popup/popup.tpl.html',
                    dialogsModel,
                    {'css': 'modal-medium'}
                );
            };

            vm.hasMin = function hasMin(array, size) {
                array = array || [];
                return array.length >= size;
            };

            vm.submit = function submit(developer) {
                if(!developer.developerId) {
                    TestService.createDeveloper(developer).then(function(developerId){
                        if(developerId) {
                            PubSubService.publishTypeCreated(PubSubService.TYPES.SUPPORT.TEST, {});
                            $state.transitionToStateWithSite('site.test.validation.view', { developerId: developerId});
                        }
                    });
                } else {
                    TestService.updateDeveloper(developer).then(function(developerId){
                        if(developerId) {
                            PubSubService.publishTypeChanged(PubSubService.TYPES.SUPPORT.TEST, {});
                            $state.transitionToStateWithSite('site.test.validation.view', { developerId: developerId});
                        }
                    });
                }
            };

            vm.cancel = function () {
                if($stateParams.developerId) {
                    $state.transitionToStateWithSite('site.test.validation.view', { developerId: $stateParams.developerId});
                } else {
                    $state.transitionToStateWithSite('site.test.validation.dashboard', { });
                }

            };

            PubSubService.publishTypeSelected(PubSubService.TYPES.SUPPORT.TEST, {id: $stateParams.developerId});

        }]);
