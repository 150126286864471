angular.module('webUi.ui.profiles.partners',[
    'webUi.ui.profiles.base',
    'webUi.ui.profiles.partners.stats',
    'webUi.ui.profiles.partners.configuration.server',
    'webUi.ui.profiles.partners.configuration.event',
    'webUi.ui.profiles.partners.form',
    'ui.bootstrap'
])
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.partners', {
            url: '/partners',
            abstract: true
        });
        $stateProvider.state('site.profiles.partners.list', {
            url: '/list',
            isReact: true,
        });
    }])
    .constant('serverPartnerConstants', {
        RELAY42_API: 'RELAY42_API'
    });