angular.module('webUi.service.tagmanagement.cookiePermission', [
    'restangular',
    'webUi.common.Utils',
    'ui.bootstrap',
    'webUi.service.pubSub'
])

    .factory('CookiePermissionService', ['Restangular', '$q', 'Utils', '$uibModal', 'PubSubService', function(Restangular, $q, Utils, $uibModal, PubSubService){

        // The base path through which all calls should go
        var BASE_PATH = 'tagmanagement/';

        var enumCache = {};

        var getCachedEnums = function(entityType) {
            var deferred = $q.defer();

            // check cache
            if(!_.isUndefined(enumCache[entityType])) {
                deferred.resolve(enumCache[entityType]);
            } else {
                // retrieve data and set cache for next usage
                Restangular.all(BASE_PATH + entityType).getList().then(function(result) {
                    result = Utils.sanitizeRestangularAll(result);
                    enumCache[entityType] = result;
                    deferred.resolve(result);
                });
            }
            return deferred.promise;
        };

        var service = {


            /**
		 * retrieves the base path through which all rest requests should go
		 * @returns {String} the base path
		 */
            getBasePath: function(){
                return BASE_PATH;
            },


            /**
		 * Attempts to create CookiePermissionSettings and returns the newly generated id
		 * @param {CookiePermissionSetting} cookiePermissionSetting
		 * @returns {UUID} a promise of the newly generated id
		 */
            addCookiePermissionSetting: function(cookiePermissionSetting){
                return Restangular.one( BASE_PATH + 'cookiePermissionSettings')
                    .post('', cookiePermissionSetting);
            },

            /**
		 *
		 * @param {CookiePermissionSetting} cookiePermissionSetting
		 * @returns {UUID} a promise of the edited id
		 */
            updateCookiePermissionSetting: function (cookiePermissionSetting){
                return Restangular.one( BASE_PATH + 'cookiePermissionSettings/edit' )
                    .post('', {
                        settingsId: cookiePermissionSetting.settingsId,
                        name: cookiePermissionSetting.name,
                        javascriptSource: cookiePermissionSetting.javascriptSource,
                        properties: cookiePermissionSetting.properties
                    });
            },

            /**
		 * Removes a CookiePermissionSetting
		 * @param {UUID} settingsId
		 * @returns {void}
		 */
            deleteCookiePermissionSetting: function (settingsId){
                return Restangular.one( BASE_PATH + 'cookiePermissionSettings', settingsId).remove();
            },

            /**
		 * Retrieves an Array of all CookiePermissionSettings
		 * @returns {Array} a promise of an Array of all CookiePermissionSettings
		 */
            findAllCookiePermissionSettings: function(){
                var deferred = $q.defer();
                Restangular.all(BASE_PATH + 'cookiePermissionSettings').getList().then(function(result){
                    result = Utils.sanitizeRestangularAll(result);
                    deferred.resolve(result);
                });
                return deferred.promise;
            },
            /**
		 * Retrieves a CookiePermissionSetting object by the specified settingsId
		 * @param {UUID} settingsId
		 * @returns a promise of the CookiePermissionSetting object with the specified settingsId
		 */
            findCookiePermissionSetting: function(settingsId){
                var deferred = $q.defer();
                Restangular.one(BASE_PATH  + 'cookiePermissionSettings', settingsId).get().then(function(result) {
                    if(result) {
                        result = Utils.sanitizeRestangularOne(result);
                        deferred.resolve(result);
                    }
                });
                return deferred.promise;
            },

            /**
		 * Retrieves the cookie permission groups
		 */
            getCookiePermissionGroups: function() {
                var deferred = $q.defer();
                Restangular.all(BASE_PATH + 'cookiePermissionGroups').getList()
                    .then(function(result){
                        result = Utils.sanitizeRestangularAll(result);
                        deferred.resolve(result);
                    });
                return deferred.promise;
            },

            deleteCookiePermissionGroup: function (groupId){
                return Restangular.one( BASE_PATH + 'cookiePermissionGroups', groupId).remove();
            },

            /**
		 * Retrieves a promise of the list of all CookiePermissionVariants that are available for the current client
		 * @returns {Array} a promise of the list of all CookiePermissionVariants that are available for the current client
		 */
            getAvailableCookiePermissionVariants: function(){
                return Restangular.all(BASE_PATH + 'cookiePermissionVariants').getList();
            },

            /**
		 * Retrieves a promise of a CookiePermissionVariant with the specified cookiePermissionVariantId
		 * @param {UUID} cookiePermissionVariantId
		 * @returns {Object} a promise of the CookiePermissionVariant with the specified cookiePermissionVariantId
		 * @see CookiePermissionFormService#getCookiePermissionVariant
		 */
            getCookiePermissionVariant: function (cookiePermissionVariantId) {
                return Restangular.one(BASE_PATH + 'cookiepermissionforms/' + cookiePermissionVariantId).get();
            },

            /**
		 * Retrieves a list of possible cookie permission execution types
		 * @returns {Array} of cookie permission execution types
		 */
            getCookiePermissionExecutionTypes: function() {
                return getCachedEnums('cookiePermissionExecutionTypes');
            },

            /**
		 * Opens a cookie permission setting popup
		 * @param {CookiePermissionSetting} cookiePermissionSetting. The cookiePermissionSetting object. Must not be a promise
		 * @returns {void}
		 */
            openCookiePermissionSettingPopup : function(cookiePermissionSetting){
                $uibModal.open({
                    windowClass: 'modal-preview modal-huge',
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/tagmanagement/cookiePermission/form/cookiePopup/cookiePopup.tpl.html',
                    controller: 'CookiePopupController',
                    resolve: {
                        dialogsModel: function() {
                            return {
                                settings: cookiePermissionSetting
                            };
                        }
                    }
                }).result.then(function() {
                    delete window.getSource;
                    delete window.getProperties;
                });
            },

            addCookiePermissionGroup: function(cookiePermissionGroup) {

                var deferred = $q.defer();
                Restangular.all(BASE_PATH + 'cookiePermissionGroups').post(cookiePermissionGroup)
                    .then(function(result){
                        result = Utils.sanitizeRestangularOne(result);
                        deferred.resolve(result);
                    });
                return deferred.promise;
            },

            updateCookiePermissionGroupName: function(groupId, newName) {
                return Restangular.all(BASE_PATH + 'cookiePermissionGroups/edit').post({
                    groupId: groupId,
                    name: newName
                });
            },

            getSetsById: function(assetsPrefix) {
                return [{
                    id: '2',
                    src: assetsPrefix + '/img/permissionGroupImg/set2-3.png',
                    groupCount: 3
                }, {
                    id: '3',
                    src: assetsPrefix + '/img/permissionGroupImg/set3-2.png',
                    groupCount: 2
                }, {
                    id: '4',
                    src: assetsPrefix + '/img/permissionGroupImg/set4-3.png',
                    groupCount: 3
                }, {
                    id: '5',
                    src: assetsPrefix + '/img/permissionGroupImg/set5-2.png',
                    groupCount: 2
                }];
            },
            /**
		 * Checks whether the cookie permission setting with this id can be deleted based on the fact if it is used in any tag
		 * @param {UUID} cookiePermissionSettingId
		 * @returns {Boolean} whether the cookie permission setting with this id can be deleted
		 * @see https://synovite.atlassian.net/browse/RP-1066
		 */
            canDelete : function(cookiePermissionSettingId){
                return Restangular.one(BASE_PATH + 'cookiePermissionSettings/' + cookiePermissionSettingId, 'canDelete').get();
            }
        };

        Utils.wrapModifierFunctions(service, function(){
            PubSubService.publishEnvironmentChange();
        });

        return service;
    }]);

