/**
 * @class webUi.VariableService
 * @memberOf webUi
 */
angular.module('webUi.service.profiles.variables', [
    'restangular',
    'webUi.common.Utils',
    'ui.bootstrap'
])
    .factory('VariableService',[ 'Restangular', '$q', 'Utils', '$uibModal',
        /**
         * @function VariableService
         * @memberOf webUi.VariableService
         * @description This is an angularjs service.
         *
         * @param {Restangular} Restangular
         * @param $q
         * @param Utils
         *
         * @returns {VariableService}
         */
        function VariableService(Restangular, $q, Utils, $uibModal) {

            // The base path through which all calls should go
            var BASE_PATH = 'profiles/variables';

            /**
             * @return {String} Current base path
             */
            var getBasePath = function getBasePath() {
                return BASE_PATH;
            };

            /**
             *
             * @param {UUID} id the variable
             * @returns {Object} a promise of the variable
             */
            var getVariable = function getVariable(id) {
                return Restangular.one(BASE_PATH, id).get().then(function (variable) {
                    if (variable) {
                        return Restangular.stripRestangular(variable);
                    }
                });
            };

            /**
             * @returns {List} a promise of the variables
             */
            var getVariables = function getVariables() {
                return Restangular.all(BASE_PATH).getList().then(function (variables) {
                    if (variables) {
                        return Restangular.stripRestangular(variables);
                    }
                });
            };

            var getVariableUsage = function (journeyId, triggerId) {
                return Restangular.all(`${BASE_PATH}/usage`).getList({
                    journeyId,
                    triggerId
                }).then(variables => variables && Restangular.stripRestangular(variables));
            };


            var newVariableModal = function newVariableModal(variables) {
                return $uibModal.open({
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/profiles/variables/form/form.tpl.html',
                    controller: 'VariablesFormController as vm',
                    resolve: {
                        dialogsModel: function () {
                            return {
                                variables: variables
                            };
                        }
                    }
                });
            };

            /**
             * Create an variable object by command object
             *
             * @returns {Integer} id of the newly created variable
             */
            var createVariable = function createVariable(createCommand) {
                return Restangular.all(BASE_PATH).post(createCommand).then(function (variableId) {
                    if (variableId) {
                        return Restangular.stripRestangular(variableId);
                    }
                });
            };

            /**
             * @description Update variable object based on updateCommand
             * @param updateCommand
             * @returns {Boolean}
             */
            var updateVariable = function updateVariable(updateCommand) {
                return Restangular.all(BASE_PATH + 'update').post(updateCommand);
            };

            /**
             * @description Delete an variable object by id
             * @param updateCommand
             * @returns {Boolean}
             */
            var deleteVariable = function deleteVariable(variableId) {
                return Restangular.one(BASE_PATH, variableId).remove();
            };


            return {
                createVariable: createVariable,
                deleteVariable: deleteVariable,
                getBasePath: getBasePath,
                getVariable: getVariable,
                getVariables: getVariables,
                getVariableUsage: getVariableUsage,
                newVariableModal: newVariableModal,
                updateVariable: updateVariable,
            };

        }
    ]);

