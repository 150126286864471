angular.module('webUi.ui.profiles.partners.configuration.event', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.partners.editEvent', {
                url: '/editEvent/{partnerId}',
                isReact: true,
            })
            .state('site.profiles.partners.editEventTransformations', {
                url: '/editEvent/{partnerId}/transformations',
                isReact: true,
            });
    }]);


