import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';

export const Tick = ({ isEnabled = false, disableAnimation = true, ...other }) => (
  <div className={cx('Tick')} {...other}>
    <div className="Tick-container">
      <span className={cx('Tick-element', { 'is-checked': isEnabled })} />
      <svg className={cx('Tick-svg', { 'is-checked': isEnabled })} viewBox="0 0 52 52">
        <path
          className={cx('Tick-svgTick', { 'is-animated': !disableAnimation })}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  </div>
);

Tick.propTypes = {
  isEnabled: PropTypes.bool,
  disableAnimation: PropTypes.bool,
};

export const TickOn = ({ ...props }) => <Tick isEnabled={true} {...props} />;
export const TickOff = ({ ...props }) => <Tick isEnabled={false} {...props} />;
export default Tick;
