import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { actionCreators, selectors } from 'react-redux-composable-list';
import { buildUrl } from '~/common/history';
import { fetchClientsIfNeeded } from '~/context/clients';
import {
  getNonSelectedClients,
  isAddingClients,
  isFetchingUserClients,
  fetchUserClients,
  addClientManager,
  USER_PROMOTE_LIST,
} from '~/context/users';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import ClientList from '~/context/components/ClientList';
import { SearchContainer } from '~/common';
import { withRouter } from '~/common/withRouter';

const USERS_TRANSLATION_KEY = 'users';
const PROMOTE_SEARCH_KEY = 'CONTEXT/USERS/PROMOTE_USER_SEARCH';

const searchFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.clientNumber.toString().indexOf(query.toLowerCase()) !== -1;

export class UserPromote extends Component {
  componentDidMount() {
    this.props.fetchClients();
    this.props.fetchUserClients();
  }

  componentWillUnmount() {
    this.props.cleanSelection();
  }

  render() {
    const { isFetching, cancelUrl, clients, onSave, isSubmitting, stateKey, t } = this.props;
    return isFetching ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={t('userPromote.header')}>
          <SearchContainer stateKey={stateKey} searchKey={PROMOTE_SEARCH_KEY} searchFn={searchFn} />
        </Heading>
        <Form onSubmit={onSave} submitting={isSubmitting}>
          <ClientList isSelectable={true} list={clients} t={t} stateKey={stateKey} />
          <FormActions
            actionsClassName="u-marginLeftNone"
            cancelHref={cancelUrl}
            submitting={isSubmitting}
            submitText={t('userPromote.actions.choose')}
            t={t}
          />
        </Form>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      cancelUrl: buildUrl(`context/users/view/${ownProps.params.userId}`),
      clients: getNonSelectedClients(state, ownProps.params.userId),
      stateKey: USER_PROMOTE_LIST,
      selected: selectors.getSelection(state, USER_PROMOTE_LIST),
      isSubmitting: isAddingClients(state),
      isFetching: isFetchingUserClients(state),
    }),
    (dispatch, ownProps) => ({
      fetchClients: () => dispatch(fetchClientsIfNeeded()),
      fetchUserClients: () => dispatch(fetchUserClients(ownProps.params.userId)),
      cleanSelection: () => dispatch(actionCreators.doSelectItemsReset(USER_PROMOTE_LIST)),
      onSave: e => {
        e.preventDefault();
        dispatch(addClientManager(ownProps.params.userId));
      },
    }),
  ),
  translate([USERS_TRANSLATION_KEY]),
)(UserPromote);
