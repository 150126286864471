/**
 * @description a Publish/Subscribe Service
 * @class PubSubService
 * @namespace webUi.service.pubSub
 *
 */
angular.module('webUi.service.pubSub', [])

    .factory('PubSubService', [ '$rootScope', function PubSubService ($rootScope) {

        var topics = {
            GLOBAL: 'global.',
            TAGMANAGEMENT: 'tagmanagement.',
            PROFILES: 'profiles.',
            CONTENT : 'content.',
            CUSTOMER : 'customer.',
            REMOTE: 'remote.',
            SUPPORT : 'support.',
            EXPORT: 'export.',
            ADMIN: 'admin.'
        };

        var TYPES = {
            GLOBAL: {
                SITE_SWITCH:                topics.GLOBAL + 'siteSwitch',
                BODY_CLICK:                 topics.GLOBAL + 'bodyClick',
                EDITING_ACTIVATED:          topics.GLOBAL + 'editingActivated',
                EDITING_CANCELLED:          topics.GLOBAL + 'editingCancelled',
                EDITING_FINISHED:           topics.GLOBAL + 'editingFinished',
                FORM_VALIDATE:              topics.GLOBAL + 'formValidate',
                WIZARD_STEP_SWITCH:         topics.GLOBAL + 'wizardStepSwitch',
                NODE_SELECTED:              topics.GLOBAL + 'nodeSelected',
                DEMO_MODE:                  topics.GLOBAL + 'demoMode',
                PROFILE_VARS:               topics.GLOBAL + 'variable',
                FORM_SUBMIT:                topics.GLOBAL + 'formSubmit'
            },
            TAGMANAGEMENT: {
                // an event to signify that the tagmanagement left menu selection has changed
                TAG_MENU_CHANGED:           topics.TAGMANAGEMENT + 'tagMenuChanged',
                // an event to signify that a path location has been changed
                LOCATION_CHANGED:           topics.TAGMANAGEMENT + 'locationChanged',
                //event to signify change of location in tree of newPaths
                PATHS_LOCATION_CHANGED:     topics.TAGMANAGEMENT + 'pathsLocationChanged',
                // event to signify that a new path wasn't added to the managed paths
                NEW_PATH_NOT_ADDED:         topics.TAGMANAGEMENT + 'newPathNotAdded',
                TAG:                        topics.TAGMANAGEMENT + 'tag',
                EXPERIMENT:                 topics.TAGMANAGEMENT + 'experiment',
                WEBSITE_PATH:               topics.TAGMANAGEMENT + 'websitePath',
                WEBSITE_PATH_GROUP:         topics.TAGMANAGEMENT + 'websitePathGroup'
            },
            PROFILES: {
                SEGMENT:                    topics.PROFILES + 'segment',
                ENGAGEMENT:                 topics.PROFILES + 'engagement',
                EXTERNAL_FACT:              topics.PROFILES + 'externalFact',
                INTERACTION:                topics.PROFILES + 'interaction',
                THIRD_PARTY_DATA_COLLECTOR: topics.PROFILES + 'thirdPartyDataCollector',
                AUDIENCE:                   topics.PROFILES + 'audience',
                VARIABLE:                   topics.PROFILES + 'variable'
            },
            CONTENT: {
                CAMPAIGN:                   topics.CONTENT + 'campaign',
                // an event to signify that a campaign has been published
                CAMPAIGN_PUBLISHED:         topics.CONTENT + 'campaignPublished',
                PLACEMENT_GROUP:            topics.CONTENT + 'placementGroup',
                AD:                         topics.CONTENT + 'ad',
                // an event to signify that the order of ads has changed
                AD_CHANGED_ORDER:           topics.CONTENT + 'adOrderChanged',
                // an event to signify that an ad has been activated/deactivated for a placement
                AD_TOGGLED_FOR_PLACEMENT:   topics.CONTENT + 'adToggledForPlacement',
                // an event to signify that an ad has been copied
                AD_COPIED:                  topics.CONTENT + 'adCopied',
                MEDIA_LIBRARY:              topics.CONTENT + 'mediaLibrary',
                MEDIA_FILE_ADDED:           topics.CONTENT + 'mediaFileAdded',
                MEDIA_FILE_DELETED:         topics.CONTENT + 'mediaFileDeleted',
                CREATIVE:                   topics.CONTENT + 'creative',
                DATA_FEED:                  topics.CONTENT + 'dataFeed',
                RECOMMENDER_PROFILE:        topics.CONTENT + 'recommenderProfile'
            },
            CUSTOMER: {
                JOURNEY:                    topics.CUSTOMER + 'journey',
                JOURNEY_NODE:               topics.CUSTOMER + 'journeyNode',
                JOURNEY_UPDATED:            topics.CUSTOMER + 'journeyUpdated',
                JOURNEY_PUBLISHING_FAILED:  topics.CUSTOMER + 'journeyPublishingFailed',
                JOURNEY_ERROR_REMOVED:      topics.CUSTOMER + 'journeyErrorRemoved'
            },
            REMOTE: {
                URLB_CAMPAIGN:              topics.REMOTE + 'urlBuilderCampaign',
                URLB_MAPPING:               topics.REMOTE + 'urlBuilderMapping',
                EXPORT_REQUEST:             topics.REMOTE + 'exportRequest',
                BEACON:                     topics.REMOTE + 'beacon',
                PARTNER:                    topics.REMOTE + 'partner'

            },
            ADMIN: {
                URLB_TYPE:                  topics.ADMIN + 'urlBuilderType',
                URLB_COLUMN:                topics.ADMIN + 'urlBuilderColumn',
                URLB_PREDEFINED_VALUE_SET:  topics.ADMIN + 'urlBuilderPredefinedValueSet',
                URLB_EXPORT_CONFIG:         topics.ADMIN + 'urlBuilderExportConfig'
            },
            SUPPORT: {
                TAG_TEMPLATE_GROUP:         topics.SUPPORT + 'tagTemplateGroup',
                TAG_TEMPLATE:               topics.SUPPORT + 'tagTemplate',
                TEST:                       topics.SUPPORT + 'test'
            }
        };

        var EVENTS = {
            CREATED: 'Created',
            CHANGED: 'Changed',
            DELETED: 'Deleted',
            PUBLISHED: 'Published',
            SELECTED: 'Selected'
        };

        /**
         *
         * @param eventNames
         * @param scope
         * @param callback
         * @returns {*}
         */
        var subscribe = function subscribe(eventNames, scope, callback) {

            if ( _.isArray(eventNames) ){

                var unsubscribeFunctions = [];

                _.forEach(eventNames, function ( eventName ) {
                    var unsubscribeFunction = scope.$on(eventName, function(event, message){
                        callback(event, message);
                    });

                    unsubscribeFunctions.push(unsubscribeFunction);
                });

                return unsubscribeFunctions;
            } else {
                return scope.$on(eventNames, function(event, message){
                    callback(event, message);
                });
            }
        };

        var publish = function publish(eventName, msg) {
            $rootScope.$broadcast(eventName, msg);
        };

        /**
         * Broadcast creation of a type with a data object that the subscribers need
         * The publishers are responsible with putting the correct data
         * @param type Type of object
         * @param data object with properties needed by the subscribers
         */
        var publishTypeCreated = function publishTypeCreated(type, data) {
            $rootScope.$broadcast(type + EVENTS.CREATED, data || {});
        };

        /**
         * Broadcast the change of a type with a data object that the subscribers need
         * The publishers are responsible with putting the correct data
         * @param type Type of object
         * @param data object with properties needed by the subscribers
         */
        var publishTypeChanged = function publishTypeChanged(type, data) {
            $rootScope.$broadcast(type + EVENTS.CHANGED, data || {});
        };

        /**
         * Broadcast the deletion of a type with a data object that the subscribers need
         * The publishers are responsible with putting the correct data
         * @param type Type of object
         * @param data object with properties needed by the subscribers
         */
        var publishTypeDeleted = function publishTypeDeleted(type, data) {
            $rootScope.$broadcast(type + EVENTS.DELETED, data || {});
        };

        /**
         * Broadcast the publishing of a type with a data object that the subscribers need
         * The publishers are responsible with putting the correct data
         * @param type Type of object
         * @param data object with properties needed by the subscribers
         */
        var publishTypePublished = function publishTypePublished(type, data) {
            $rootScope.$broadcast(type + EVENTS.PUBLISHED, data || {});
        };

        var subscribeForType = function subscribeForType(type, scope, callback) {
            var eventsToSubscribeTo = [
                type + EVENTS.CREATED,
                type + EVENTS.CHANGED,
                type + EVENTS.DELETED,
                type + EVENTS.PUBLISHED
            ];
            subscribe(eventsToSubscribeTo, scope, callback);
        };

        /**
         * Broadcast the selection of a type with a data object that the subscribers need
         * The publishers are responsible with putting the correct data
         * @param type Type of object
         * @param data object with properties needed by the subscribers
         */
        var publishTypeSelected = function publishTypeSelected(type, data) {
            $rootScope.$broadcast(type + EVENTS.SELECTED, data || {});
        };

        var subscribeTypeSelected = function subscribeTypeSelected(type, scope, callback) {
            subscribe(type + EVENTS.SELECTED, scope, callback);
        };

        /**
         * @description site switch pub
         * @param siteNumber
         *
         */
        var publishSiteSwitch = function publishSiteSwitch(options){
            $rootScope.$broadcast(TYPES.GLOBAL.SITE_SWITCH, {
                siteNumber: options.siteNumber || null,
                siteId: options.siteId || null,
                name: options.name || null
            });
        };

        /**
         * @description site switch sub
         * @param scope
         * @param callback
         */
        var subscribeSiteSwitch = function subscribeSiteSwitch( scope, callback ) {
            subscribe(TYPES.GLOBAL.SITE_SWITCH, scope, callback);
        };

        var publishDemoActivate = function publishDemoActivate() {
            $rootScope.$broadcast(TYPES.DEMO_MODE, {});
        };
        var subscribeDemoActivate = function subscribeDemoActivate(scope, callback){
            subscribe(TYPES.DEMO_MODE, scope, callback);
        };
        /**************************Tagmanagement************************/

        /**
         * Publishes a change in the left menu of Tagmanagement
         * @param tagMenuLocation is an object that can contain:
         *	{
     *		type: 'paths' / 'groups' / 'all'
     *		identifierParam: websitePathKey / websitePathGroupId (optional)
     *		locationUrlEncoded: websitePathKey urlEncoded (optional)
     *	}
         *TODO
         */
        var publishTagMenuLocationChanged = function publishTagMenuLocationChanged(tagMenuLocation) {
            $rootScope.$broadcast(TYPES.TAGMANAGEMENT.TAG_MENU_CHANGED, tagMenuLocation);
        };

        var subscribeTagMenuLocationChanged = function subscribeTagMenuLocationChanged(scope, callback) {
            subscribe(TYPES.TAGMANAGEMENT.TAG_MENU_CHANGED, scope, callback);
        };

        /**
         * New paths
         */

        var publishPathTreeLocationChanged = function publishPathTreeLocationChanged(location) {
            $rootScope.$broadcast(TYPES.TAGMANAGEMENT.PATHS_LOCATION_CHANGED, location);
        };

        var subscribePathTreeLocationChanged = function subscribePathTreeLocationChanged(scope, callback) {
            subscribe(TYPES.TAGMANAGEMENT.PATHS_LOCATION_CHANGED, scope, callback);
        };

        var publishNewPathNotAdded = function publishNewPathNotAdded(path) {
            $rootScope.$broadcast(TYPES.TAGMANAGEMENT.NEW_PATH_NOT_ADDED, path);
        };

        var subscribeNewPathNotAdded = function subscribeNewPathNotAdded(scope, callback) {
            subscribe(TYPES.TAGMANAGEMENT.NEW_PATH_NOT_ADDED, scope, callback);
        };

        /*
         * Publishing
         */
        var subscribeEnvironmentChange = function subscribeEnvironmentChange(scope, callback){
            subscribe(TYPES.TAGMANAGEMENT.ENVIRONMENT_CHANGED, scope, callback);
        };

        var publishEnvironmentChange  = function publishEnvironmentChange(){
            $rootScope.$broadcast(TYPES.TAGMANAGEMENT.ENVIRONMENT_CHANGED, {});
        };


        /** ****************************Support****************************/
        //todo remove this and just use two subscribes
        var subscribeSupport = function subscribeSupport(scope, callback){
            var eventsToSubscribeTo = [
                TYPES.SUPPORT.TAG_TEMPLATE_GROUP + EVENTS.CREATED,
                TYPES.SUPPORT.TAG_TEMPLATE_GROUP + EVENTS.CHANGED,
                TYPES.SUPPORT.TAG_TEMPLATE_GROUP + EVENTS.DELETED,
                TYPES.SUPPORT.TAG_TEMPLATE + EVENTS.CREATED,
                TYPES.SUPPORT.TAG_TEMPLATE + EVENTS.CHANGED,
                TYPES.SUPPORT.TAG_TEMPLATE + EVENTS.DELETED
            ];
            subscribe(eventsToSubscribeTo, scope, callback);
        };

        var publishNodeSelected = function publishNodeSelected(id) {
            $rootScope.$broadcast(TYPES.GLOBAL.NODE_SELECTED, {
                id: id
            });
        };

        var subscribeNodeSelected = function subscribeNodeSelected(scope, callback) {
            subscribe(TYPES.GLOBAL.NODE_SELECTED, scope, callback);
        };

        /* Subscribe on events that change the contents of the campaign and would make the publish
         * notification bar show */
        var subscribeCampaignContentChanged = function subscribeCampaignContentChanged(scope, callback) {
            var eventsToSubscribeTo = [
                TYPES.CONTENT.AD + EVENTS.CREATED,
                TYPES.CONTENT.AD + EVENTS.DELETED,
                TYPES.CONTENT.AD_CHANGED_ORDER,
                TYPES.CONTENT.AD_TOGGLED_FOR_PLACEMENT,
                TYPES.CONTENT.PLACEMENT_GROUP + EVENTS.DELETED
            ];
            subscribe(eventsToSubscribeTo, scope, callback);
        };

        //TODO
        var subscribeAdCopied = function subscribeAdCopied(scope, callback) {
            subscribe(TYPES.CONTENT.AD_COPIED, scope, callback);
        };

        //TODO
        var publishAdOrderChanged = function publishAdOrderChanged() {
            $rootScope.$broadcast(TYPES.CONTENT.AD_CHANGED_ORDER, {});
        };

        var publishAdToggledForPlacement = function publishAdToggledForPlacement() {
            $rootScope.$broadcast(TYPES.CONTENT.AD_TOGGLED_FOR_PLACEMENT);
        };

        var publishAdCopied = function publishAdCopied(adId, adName, campaignId, campaignName) {
            $rootScope.$broadcast(TYPES.CONTENT.AD_COPIED, {
                adId: adId,
                adName: adName,
                campaignId: campaignId,
                campaignName: campaignName
            });
        };

        //TODO
        var subscribeCreativeListChanged = function subscribeCreativeListChanged(scope, callback) {
            var eventsToSubscribeTo = [
                TYPES.CONTENT.CREATIVE + EVENTS.CREATED,
                TYPES.CONTENT.CREATIVE + EVENTS.CHANGED,
                TYPES.CONTENT.CREATIVE + EVENTS.DELETED
            ];
            subscribe(eventsToSubscribeTo, scope, callback);
        };

        /**********************Validation**********************/
        var publishFormValidate = function publishFormValidate(formName) {
            $rootScope.$broadcast(TYPES.GLOBAL.FORM_VALIDATE  + formName, {});
        };

        var subscribeFormValidate = function subscribeFormValidate(scope, formName, callback) {
            subscribe(TYPES.GLOBAL.FORM_VALIDATE + formName, scope, callback);
        };

        /*********************Editable events*********************************/
        /**
         * Activate editing state for an editable on the fieldName
         * @param fieldName
         */
        var publishEditingActivated = function publishEditingActivated(fieldName, randomIdentifier) {
            $rootScope.$broadcast(TYPES.GLOBAL.EDITING_ACTIVATED, {
                fieldName: fieldName,
                id: randomIdentifier
            });
        };

        /**
         * Subscribe to the editing activation (fieldName is internal to the scope of the editable so it's not necessary)
         * @param scope
         * @param callback
         */
        var subscribeEditingActivated = function subscribeEditingActivated(scope, callback) {
            subscribe(TYPES.GLOBAL.EDITING_ACTIVATED, scope, callback);
        };

        /**
         * Publish editing cancelled event
         */
        var publishEditingCancelled = function publishEditingCancelled() {
            $rootScope.$broadcast(TYPES.GLOBAL.EDITING_CANCELLED, {});
        };

        /**
         * Subscribe to the editing cancelled event
         * @param scope
         * @param callback
         */
        var subscribeEditingCancelled = function subscribeEditingCancelled( scope, callback ){
            subscribe(TYPES.GLOBAL.EDITING_CANCELLED, scope, callback);
        };

        /**
         * Publish an editing finished event for the fieldName with current fieldValue
         * @param fieldName
         * @param fieldValue
         */
        var publishEditingFinished = function publishEditingFinished(fieldName, fieldValue, randomIdentifier) {
            $rootScope.$broadcast(TYPES.GLOBAL.EDITING_FINISHED  + fieldName, {
                val: fieldValue,
                id: randomIdentifier
            });
        };

        /**
         * Subscribe to editing finished for the fieldName
         * @param scope
         * @param fieldName
         * @param callback
         * @returns the unsubscribe function, to use if we want to re-subscribe for a different fieldName
         */
        var subscribeEditingFinished  = function subscribeEditingFinished(scope, fieldName, callback ) {
            return subscribe(TYPES.GLOBAL.EDITING_FINISHED + fieldName, scope, callback);
        };

        /**
         * Publish editing finished for all editables in editing mode
         */
        var publishBodyClick = function publishBodyClick(element, $event) {
            $rootScope.$broadcast(TYPES.GLOBAL.BODY_CLICK, { element: element, $event: $event });
        };

        var subscribeBodyClick = function subscribeBodyClick(scope, callback) {
            return subscribe(TYPES.GLOBAL.BODY_CLICK, scope, callback);
        };


        /**********************Submit form**********************/
        //publish is done only to the sub-scopes
        var publishFormSubmitToChild = function publishFormSubmitToChild(scope, formName) {
            scope.$broadcast(TYPES.GLOBAL.FORM_SUBMIT  + formName, {});
        };

        var subscribeFormSubmit = function subscribeFormSubmit(scope, formName, callback) {
            subscribe(TYPES.GLOBAL.FORM_SUBMIT + formName, scope, callback);
        };

        var publishJourneyUpdated = function publishJourneyUpdated(node, action, payload) {
            $rootScope.$broadcast(TYPES.CUSTOMER.JOURNEY_UPDATED, { node: node, action: action, payload: payload });
        };

        var publishCustomerJourneyPublishingFailed = function publishCustomerJourneyPublishingFailed(errors) {
            $rootScope.$broadcast(TYPES.CUSTOMER.JOURNEY_PUBLISHING_FAILED, errors);
        };

        var subscribeCustomerJourneyPublishingFailed = function subscribeCustomerJourneyPublishingFailed(scope, callback) {
            return subscribe(TYPES.CUSTOMER.JOURNEY_PUBLISHING_FAILED, scope, callback);
        };

        var publishJourneyErrorRemoved = function publishJourneyErrorRemoved(nodeId, nodeType, errorType) {
            $rootScope.$broadcast(TYPES.CUSTOMER.JOURNEY_ERROR_REMOVED, {nodeId: nodeId, nodeType: nodeType, errorType: errorType});
        };

        var subscribeJourneyErrorRemoved = function subscribeJourneyErrorRemoved(scope, callback) {
            return subscribe(TYPES.CUSTOMER.JOURNEY_ERROR_REMOVED, scope, callback);
        };

        /**
         * @type {PubSubService}
         */
        var service = {
            TYPES: TYPES,
            EVENTS: EVENTS,

            publish: publish,
            subscribe: subscribe,

            publishTypeCreated: publishTypeCreated,
            publishTypeChanged: publishTypeChanged,
            publishTypeDeleted: publishTypeDeleted,
            publishTypePublished: publishTypePublished,
            subscribeForType: subscribeForType,

            publishTypeSelected: publishTypeSelected,
            subscribeTypeSelected: subscribeTypeSelected,

            //GLOBAL events
            publishSiteSwitch: publishSiteSwitch,
            subscribeSiteSwitch: subscribeSiteSwitch,

            publishBodyClick: publishBodyClick,
            subscribeBodyClick: subscribeBodyClick,

            publishEditingActivated: publishEditingActivated,
            subscribeEditingActivated: subscribeEditingActivated,
            publishEditingCancelled: publishEditingCancelled,
            subscribeEditingCancelled: subscribeEditingCancelled,
            publishEditingFinished: publishEditingFinished,
            subscribeEditingFinished: subscribeEditingFinished,

            publishFormValidate: publishFormValidate,
            subscribeFormValidate: subscribeFormValidate,

            publishDemoActivate: publishDemoActivate,
            subscribeDemoActivate: subscribeDemoActivate,

            //TAG MANAGEMENT
            publishTagMenuLocationChanged: publishTagMenuLocationChanged,
            subscribeTagMenuLocationChanged: subscribeTagMenuLocationChanged,

            publishPathTreeLocationChanged: publishPathTreeLocationChanged,
            subscribePathTreeLocationChanged: subscribePathTreeLocationChanged,

            publishNewPathNotAdded: publishNewPathNotAdded,
            subscribeNewPathNotAdded: subscribeNewPathNotAdded,

            subscribeEnvironmentChange: subscribeEnvironmentChange,
            publishEnvironmentChange: publishEnvironmentChange,

            subscribeSupport: subscribeSupport,

            publishNodeSelected: publishNodeSelected,
            subscribeNodeSelected: subscribeNodeSelected,

            subscribeCampaignContentChanged: subscribeCampaignContentChanged,

            publishAdOrderChanged: publishAdOrderChanged,

            publishAdToggledForPlacement: publishAdToggledForPlacement,

            publishAdCopied: publishAdCopied,
            subscribeAdCopied: subscribeAdCopied,

            subscribeCreativeListChanged: subscribeCreativeListChanged,

            publishFormSubmitToChild: publishFormSubmitToChild,
            subscribeFormSubmit: subscribeFormSubmit,

            publishJourneyUpdated: publishJourneyUpdated,

            publishCustomerJourneyPublishingFailed: publishCustomerJourneyPublishingFailed,
            subscribeCustomerJourneyPublishingFailed: subscribeCustomerJourneyPublishingFailed,

            publishJourneyErrorRemoved: publishJourneyErrorRemoved,
            subscribeJourneyErrorRemoved: subscribeJourneyErrorRemoved

        };

        return service;
    }]);
