export const COOKIE_FETCH_START = 'app/support/cookies/COOKIE_FETCH_START';
export const COOKIE_FETCH_FAIL = 'app/support/cookies/COOKIE_FETCH_FAIL';
export const COOKIE_FETCH_SUCCESS = 'app/support/cookies/COOKIE_FETCH_SUCCESS';

export const COOKIES_FETCH_START = 'app/support/cookies/COOKIES_FETCH_START';
export const COOKIES_FETCH_FAIL = 'app/support/cookies/COOKIES_FETCH_FAIL';
export const COOKIES_FETCH_SUCCESS = 'app/support/cookies/COOKIES_FETCH_SUCCESS';

export const SAVE_START = 'app/support/cookies/SAVE_START';
export const SAVE_FAIL = 'app/support/cookies/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/support/cookies/SAVE_SUCCESS';

export const CLIENT_UNRESTRICT_START = 'app/support/cookies/CLIENT_UNRESTRICT_START';
export const CLIENT_UNRESTRICT_FAIL = 'app/support/cookies/CLIENT_UNRESTRICT_FAIL';
export const CLIENT_UNRESTRICT_SUCCESS = 'app/support/cookies/CLIENT_UNRESTRICT_SUCCESS';

export const CLIENTS_RESTRICT_START = 'app/support/cookies/CLIENTS_RESTRICT_START';
export const CLIENTS_RESTRICT_FAIL = 'app/support/cookies/CLIENTS_RESTRICT_FAIL';
export const CLIENTS_RESTRICT_SUCCESS = 'app/support/cookies/CLIENTS_RESTRICT_SUCCESS';

export const CLIENT_TOGGLE_RESTRICTION = 'app/support/cookies/CLIENT_TOGGLE_RESTRICTION';
