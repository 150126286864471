import React, { useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import ModelView from './ModelView';
import ModelsList from './ModelList';
import ModelStats from './ModelStats';
import ModelCreate from './ModelCreate';
import AuditLog from './components/AuditLog';
import { ModelContext } from './ModelContext';

const ModelRoutes = (): React.ReactChild => {
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);

  return (
    <ModelContext.Provider value={{ setSelectedModelId }}>
      <Routes>
        <Route path="list" element={<ModelsList />} />
        <Route path="new" element={<ModelCreate />} />
        <Route path="view/:id" element={<ModelView />} />
        <Route path="edit/:id" element={<ModelCreate />} />
        <Route path="stats/:id" element={<ModelStats />} />
      </Routes>
      <AuditLog selectedModelId={selectedModelId} handleClose={() => setSelectedModelId(null)} />
    </ModelContext.Provider>
  );
};
export default ModelRoutes;
