import { combineReducers } from 'redux';
import * as types from './types';
import * as engagementTypes from '../engagements/types';
import * as customerFactTypes from '../customerFacts/types';

const defaultAction = {
  payload: {},
};

/**
 * Dependencies Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let element;
  let properties;
  let nextState;
  let nextProperties;
  switch (action.type) {
    case types.DEPENDANTS_INFO_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload.dependantsInfo,
      };
    case types.PROPERTY_DEPENDANTS_INFO_FETCH_SUCCESS:
      element = state[action.payload.elementId] || {};
      properties = element.properties || {};
      nextProperties = { ...properties, [action.payload.propertyId]: action.payload.dependantsInfo };
      return {
        ...state,
        [action.payload.elementId]: { ...element, properties: nextProperties },
      };
    case engagementTypes.ENGAGEMENTS_DELETE_SUCCESS:
    case customerFactTypes.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Dependencies Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.DEPENDANTS_INFO_FETCH_SUCCESS:
      if (state.indexOf(action.payload.id) !== -1) {
        return state;
      }
      return [...state, action.payload.id];
    case types.PROPERTY_DEPENDANTS_INFO_FETCH_SUCCESS:
      if (state.indexOf(action.payload.elementId) !== -1) {
        return state;
      }
      return [...state, action.payload.elementId];
    case engagementTypes.ENGAGEMENTS_DELETE_SUCCESS:
    case customerFactTypes.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = [...state];
      nextState.splice(
        state.findIndex(item => item === action.payload),
        1,
      );
      return nextState;
    default:
      return state;
  }
};

const adminConnectorsReducer = combineReducers({
  byId,
  allIds,
});

export default adminConnectorsReducer;
