angular.module('webUi.ui.tagmanagement.tags.conditions.selection', ['webUi.common.Utils', 'ui.validate'])

    .controller('TagmanagementTagFormSelectionConditionController', ['$scope', 'ValidService', 'TagmanagementService',
        function TagmanagementTagFormSelectionConditionController    ($scope,   ValidService,   TagmanagementService) {
		
            function determineWhetherInSelection(formCondition){
                if ( formCondition.isInSelections.length > 0 ){
                    return true;
                } else if ( formCondition.isNotInSelections.length > 0 ){
                    return false;
                }
                return true;
            }
		
            function determineSelection(condition){
                if ( condition.isInSelections.length > 0 ) {
                    return condition.isInSelections[0];
                } else if ( condition.isNotInSelections.length > 0) {
                    return condition.isNotInSelections[0];
                }
                return undefined;
            }
		
            function handleSelectionChange(condition, selection, inSelection){
                if ( inSelection ){
                    condition.isInSelections = [];
                    condition.isNotInSelections = [];
                    condition.isInSelections.push(selection);
                } else {
                    condition.isInSelections = [];
                    condition.isNotInSelections = [];
                    condition.isNotInSelections.push(selection);
                }
            }
		
            var conditionClazz = 'SelectionCondition';
	
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                mode: 'new',
                isInSelections: [],
                isNotInSelections: [],
                cacheInSeconds: 0
            };
		
            $scope.internal = {
                selection: determineSelection($scope.state.formCondition),
                inSelection: determineWhetherInSelection($scope.state.formCondition)
            };
		
            TagmanagementService.getSegments().then(function(segments){
                $scope.segments = segments;
            });
		
            $scope.$watch('internal.selection', function(newVal) {
                var selection = newVal;
                var inSelection = $scope.internal.inSelection;
                var condition = $scope.state.formCondition;
                if ( selection ){
                    handleSelectionChange(condition, selection, inSelection);
                }
            });
		
            $scope.$watch('internal.inSelection', function(newVal){
                var selection = $scope.internal.selection;
                var inSelection = newVal;
                var condition = $scope.state.formCondition;
			
                if ( selection ){
                    handleSelectionChange(condition, selection, inSelection);
                }
            });
		
        }
    ]);

