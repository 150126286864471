/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';
import * as types from './types';

const API_SUCCESS = 'success';
const BASE_PATH = `${API_BASE}/admin/url-builder/exportconfigs`;
const API_REMOTE_TEST_PATH = `${API_BASE}/remoteConnectionTest/check`;

/**
 * A service for configurations related queries.
 */
export class ConfigurationUrlBuilderClass {
  getConfiguration(configurationId) {
    return Api.callGet(`${BASE_PATH}/${configurationId}`).then(response => response.data);
  }

  getConfigurations() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveConfiguration(configuration) {
    return Api.callPost(`${API_REMOTE_TEST_PATH}`, configuration.configuration)
      .then(response => response.data)
      .then(isConnected => {
        if (isConnected.success) {
          return Api.callPost(BASE_PATH, configuration).then(response => response.data);
        }
        throw types.CONFIGTEST_CONNECTION_ERROR;
      })
      .then(response => response.data);
  }

  updateConfiguration(configuration) {
    return Api.callPost(`${API_REMOTE_TEST_PATH}`, configuration.configuration)
      .then(response => response.data)
      .then(isConnected => {
        if (isConnected.success) {
          return Api.callPut(`${BASE_PATH}/${configuration.configId}`, configuration);
        }
        throw types.CONFIGTEST_CONNECTION_ERROR;
      })
      .then(response => response.data);
  }

  deleteConfiguration(configurationId) {
    return Api.callDelete(`${BASE_PATH}/${configurationId}`, configurationId).then(response => response.data);
  }

  testConnection(configuration) {
    return Api.callPost(`${API_REMOTE_TEST_PATH}`, configuration).then(response => response.data);
  }

  runReport(configurationId) {
    return Api.callPost(`${API_BASE}/admin/url-builder/report`, { configId: configurationId }).then(response => {
      const status = response && response.data && response.data.status;
      if (status === API_SUCCESS) {
        return true;
      }
      return Promise.reject(response && response.data && response.data.errorMessage);
    });
  }
}

const configurationUrlBuilderService = new ConfigurationUrlBuilderClass();
export default configurationUrlBuilderService;
