export const COLUMN_FETCH_START = 'app/admin/urlBuilder/COLUMN_FETCH_START';
export const COLUMN_FETCH_SUCCESS = 'app/admin/urlBuilder/COLUMN_FETCH_SUCCESS';
export const COLUMN_FETCH_FAIL = 'app/admin/urlBuilder/COLUMN_FETCH_FAIL';

export const COLUMNS_FETCH_START = 'app/admin/urlBuilder/COLUMNS_FETCH_START';
export const COLUMNS_FETCH_SUCCESS = 'app/admin/urlBuilder/COLUMNS_FETCH_SUCCESS';
export const COLUMNS_FETCH_FAIL = 'app/admin/urlBuilder/COLUMNS_FETCH_FAIL';

export const COLUMN_SAVE_START = 'app/admin/urlBuilder/COLUMN_SAVE_START';
export const COLUMN_SAVE_SUCCESS = 'app/admin/urlBuilder/COLUMN_SAVE_SUCCESS';
export const COLUMN_SAVE_FAIL = 'app/admin/urlBuilder/COLUMN_SAVE_FAIL';

export const COLUMN_EDIT_START = 'app/admin/urlBuilder/COLUMN_EDIT_START';
export const COLUMN_EDIT_SUCCESS = 'app/admin/urlBuilder/COLUMN_EDIT_SUCCESS';
export const COLUMN_EDIT_FAIL = 'app/admin/urlBuilder/COLUMN_EDIT_FAIL';

export const COLUMN_DELETE_START = 'app/admin/urlBuilder/COLUMNS_DELETE_START';
export const COLUMN_DELETE_SUCCESS = 'app/admin/urlBuilder/COLUMNS_DELETE_SUCCESS';
export const COLUMN_DELETE_FAIL = 'app/admin/urlBuilder/COLUMNS_DELETE_FAIL';
