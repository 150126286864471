import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Formik, Field } from 'formik';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/common/formValidation/i18n';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation:validation.required')),
  description: Yup.string().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  name: '',
  description: '',
};

const AddUserListForm = ({ hideModal, createNewUserList, setFieldValue, t }) => {
  const handleAddUserList = ({ name, description }) => createNewUserList(name, description, setFieldValue);
  return (
    <Formik onSubmit={handleAddUserList} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, isValid, isSubmitting }) => (
        <>
          <Field
            name="name"
            as={FormikInputField}
            label={t('connectors.doubleClick.userListAdd.nameLabel')}
            placeholder={t('connectors.doubleClick.userListAdd.placeholder')}
            errorText={errors.name}
            autoFocus={true}
          />
          <Field
            name="description"
            as={FormikInputField}
            label={t('connectors.doubleClick.userListAdd.descriptionLabel')}
            placeholder={t('connectors.doubleClick.userListAdd.placeholder')}
            errorText={errors.description}
          />
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={hideModal}
            isConfirmEnabled={isValid && !isSubmitting}
            className="mr-0"
          />
        </>
      )}
    </Formik>
  );
};

AddUserListForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(translate('audiences'))(AddUserListForm);
