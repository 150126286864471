angular.module('webUi.ui.tagmanagement.tags.view', [
    'webUi.ui.tagmanagement.base',
    'webUi.ui.tagmanagement.tags.view.right',
    'webUi.ui.tagmanagement.tags.view.header',
    'webUi.ui.tagmanagement.tags.view.content',
    'webUi.ui.tagmanagement.tags.view.advancedSettings',
    'webUi.ui.tagmanagement.tags.view.content.codemirrorDialog',
    'webUi.ui.tagmanagement.tags.view.content.templateContentEdit'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tags.view', {
                url: '/view/{id}?autoOpenConditions&snapshotId',
                views: {
                    '@site.tagmanagement': {
                        controller: 'TagViewController',
                        templateUrl: 'ui/tagmanagement/tags/view/view.tpl.html'
                    }
                },
                leftMenu: true,
                resolve: {
                    tagViewPojo: ['TagmanagementService', '$stateParams', function (TagmanagementService, $stateParams) {
                        return TagmanagementService.getTag($stateParams.id, $stateParams.snapshotId);
                    }],
                    tagExecutionTypes: ['TagmanagementService', function (TagmanagementService) {
                        return TagmanagementService.getTagExecutionTypes();
                    }],
                    conditionGroups: ['TagmanagementService', function (TagmanagementService) {
                        return TagmanagementService.getTagConditionsGroupedBy(TagmanagementService.TAG_CONDITION_GROUPING_OPTIONS.CONFIGURATION_GROUP_NAME);
                    }]
                }
            });
    }])
    .controller('TagViewController', [
        '$scope',
        'tagViewPojo',
        'PubSubService',
        'TagmanagementService',
        'Restangular',
        'tagExecutionTypes',
        '$stateParams',
        'conditionGroups',
        function TagViewController($scope,
            tagViewPojo,
            PubSubService,
            TagmanagementService,
            Restangular,
            tagExecutionTypes,
            $stateParams,
            conditionGroups) {

            // overrides hasTagEdit if it historical version
            var _historicalView = ($stateParams.snapshotId && !tagViewPojo.latestVersion) ? true : false;
            $scope.hasTagEdit = ($scope.hasTagEdit && !_historicalView && !tagViewPojo.tag.partnerTag) ? true : false;
            $scope.historicalView = _historicalView;
            $scope.tagViewPojo = tagViewPojo;
            $scope.tagExecutionTypes = tagExecutionTypes;
            $scope.conditionGroups = conditionGroups;

            //if the tag belongs to a WebsitePathGroup, broadcast the event with type 'groups'
            if (tagViewPojo.tag.location.clazz === 'WebsitePathGroupLocation') {
                $scope.tagMenuLocation = {type: 'groups', identifierParam: tagViewPojo.tag.location.groupId};
            } else {
            //otherwise the event is of type 'paths', including Global
                $scope.tagMenuLocation = {type: 'paths', identifierParam: tagViewPojo.tag.location.path};
            }

            //notify the left menu that the menu has changed
            PubSubService.publishTagMenuLocationChanged($scope.tagMenuLocation);
            /**
         * @deprecated
         * @param modification
         */
            $scope.handleModification = function handleModification() {
            // on modification update the whole tagViewPojo
                TagmanagementService.getTag($stateParams.id, $stateParams.snapshotId).then(
                    function onSuccess(res) {
                        $scope.tagViewPojo = Restangular.stripRestangular(res);
                    },
                    function onError() {
                    }
                );
            };

            PubSubService.subscribeForType(PubSubService.TYPES.TAGMANAGEMENT.TAG, $scope,
                function cb(event, data) {

                    //
                    //beware of falling into a race here, it's only for visual purpose
                    //

                    //#1 touching tagViewPojo will trigger the watch
                    if (data.tagContent) {
                        $scope.tagViewPojo.tag.content = _.clone(data.tagContent);
                    }

                    //#2 we update anyways the whole object
                    TagmanagementService.getTag($stateParams.id, $stateParams.snapshotId).then(
                        function onSuccess(result) {
                            $scope.tagViewPojo = Restangular.stripRestangular(result);
                        },
                        function onError() {
                        }
                    );
                }
            );
        }
    ]);
