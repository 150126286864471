angular
  .module('webUi.ui.user.settingsEdit', [])

  .config([
    '$stateProvider',
    function config($stateProvider) {
      $stateProvider.state('site.user.settingsEdit', {
        url: '/settings/edit',
        isReact: true,
      });
    },
  ]);
