import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { getDateAndTime } from '~/common/utils/DateUtil';
import { Panel } from '~/components/src/Containers';
import { buildUrl } from '~/common';
import { HeaderCell } from '~/components/src/Table/components';
import Icons from '~/components/src/Icons';

const { Table, Row, Cell, RowActions } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('adminUsers:list.empty.header')} body={t('adminUsers:list.empty.body')} />
);

const NoResults = ({ t }) => (
  <Empty header={t('adminUsers:list.noResults.header')} body={t('adminUsers:list.noResults.body')} />
);

const WIDTHS = {
  USERNAME: 'u-size2of10',
  NAME: 'u-size2of10',
  LAST_LOGIN: 'u-size1of10',
  PHONE: 'u-size1of10',
  SUBNETS: 'u-size1of6',
  CLIENT: 'u-size1of6',
  ACTIONS: 'u-size1of12',
};

export const UserBasicList = ({
  list,
  stateKey,
  onUserLockSettingsClick,
  onEditClick,
  onDeleteClick,
  t,
  getClientName,
  isContextAdmin,
}) => {
  const renderActions = item => {
    const actions = item.hasSameClient
      ? [
          {
            name: t('users:userLockSettings.name'),
            tooltip: t('users:userLockSettings.toolTip'),
            isDisabled: false,
            icon: 'lockPerson',
            onClick: () => onUserLockSettingsClick(item),
            testHook: 'lock',
          },
        ]
      : [];

    actions.push(
      {
        name: t('common:actions.edit'),
        tooltip: t('common:actions.edit'),
        isDisabled: false,
        icon: 'edit',
        onClick: () => onEditClick(item),
        testHook: 'edit',
      },
      {
        name: t('common:actions.remove'),
        tooltip: t('common:actions.remove'),
        isDisabled: false,
        icon: 'delete',
        onClick: () => onDeleteClick(item),
        testHook: 'delete',
        type: 'delete',
      },
    );

    return (
      (onEditClick || onDeleteClick) && (
        <RowActions className={`${WIDTHS.ACTIONS} flex justify-end`} actions={actions} iconsLimit={3} />
      )
    );
  };

  return (
    <Panel>
      <Table stateKey={stateKey}>
        <Row>
          <SortableHeader className={WIDTHS.USERNAME} sortKey="username" title={t('list.username')} />
          <SortableHeader className={WIDTHS.NAME} sortKey="name" title={t('list.name')} />
          <SortableHeader className={WIDTHS.LAST_LOGIN} sortKey="lastLoginDate" title={t('list.lastLogin')} />
          <SortableHeader className={WIDTHS.PHONE} sortKey="phoneNumber" title={t('list.phoneNumber')} />
          <SortableHeader className={WIDTHS.SUBNETS} sortKey="restrictedSubnets" title={t('list.restrictedSubnets')} />
          <SortableHeader className={WIDTHS.CLIENT} sortKey="clientId" title={t('list.client')} />
          <HeaderCell className={WIDTHS.ACTIONS} />
        </Row>
        {list.map(item => (
          <Row key={item.userId} withActions className="t-usersRow">
            <Cell className={`flex items-center gap-2 ${WIDTHS.USERNAME}`}>
              <div className="flex items-center">
                {isContextAdmin ? (
                  <Link href={buildUrl(`context/users/view/${item.userId}`)} testHook="userEmail">
                    {item.username}
                  </Link>
                ) : (
                  <span className="t-userEmail">{item.username}</span>
                )}
                {item.hasSameClient && item.lock?.locked && (
                  <Icons className="h-5 w-5 text-red-500" icon="lock" tooltip={item.lock.reason} />
                )}
              </div>
            </Cell>
            <Cell className={WIDTHS.NAME}>
              <span className="t-userName whitespace-nowrap pr-2">{item.name ?? '-'}</span>
            </Cell>
            <Cell className={WIDTHS.LAST_LOGIN}>{getDateAndTime(item.lastLoginDate)}</Cell>
            <Cell className={WIDTHS.PHONE}>{item.phoneNumber || '-'}</Cell>
            <Cell className={`${WIDTHS.SUBNETS} t-restrictedSubnets`}>{item.restrictedSubnets || '-'}</Cell>
            <Cell className={WIDTHS.CLIENT}>{getClientName(item.clientId)}</Cell>
            {renderActions(item)}
          </Row>
        ))}
      </Table>
    </Panel>
  );
};

UserBasicList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  getClientName: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
  withEmpty({ component: EmptyList }),
)(UserBasicList);
