/* eslint-disable no-prototype-builtins */
/**
 * @description Service that handles validation utility functions
 * @namespace webUi.service.validation
 * @memberOf webUi
 * @class ValidService
 */
angular.module('webUi.service.valid', [
    'webUi.common.Utils',
    'regjsparser'
])
    .factory('ValidService', ['Utils', 'RegJsParser',
    
        /**
         * @function ValidService
         * @param {Utils} Utils
         * @param RegJsParser
         * @returns {ValidService}
         */
        function ValidService(Utils, RegJsParser) {

            // regex for validating a decimal number and a number of emails which are comma separated
            var FLOAT_REGEXP = /^-?\d+((\.)\d+)?$/;
            var EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/;
            var INTEGER_REGEX = /^-?[0-9]+$/;
            var MAX_INTEGER = (Math.pow(2, 31) - 1);
            var MIN_INTEGER = -MAX_INTEGER;
            var STRING_VARIABLE_REGEX =  /^[a-zA-Z]{1}[a-zA-Z0-9_]{1,}$/;
            var ALPHANUMERIC_REGEX =  /^[a-zA-Z0-9]*$/;
            var RESERVED_JS_KEYWORDS_REGEX=/^(do|if|in|for|let|new|try|var|case|else|enum|eval|false|null|this|true|void|with|await|break|catch|class|const|super|throw|while|yield|delete|export|import|public|return|static|switch|typeof|default|extends|finally|package|private|continue|debugger|function|arguments|interface|protected|implements|instanceof|int|byte|char|goto|long|final|float|short|double|native|throws|boolean|abstract|volatile|transient|synchronized|NaN|Infinity|undefined)$/;
            var URL_REGEXP = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/;
            var FOLDER_REGEXP = /^[^?%*:|"<>.#\\]+$/;
            var IP_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/[0-9]{1,2}$/;

            var TIME_RULE_CONSTRAINTS = {
                minutes: {
                    suffix: 'm'
                },
                hours: {
                    suffix: 'h'
                },
                days: {
                    suffix: 'd'
                },
                weeks: {
                    suffix: 'w'
                }
            };

            /**
         * @description Validates number
         * @param {Integer} value
         * @returns {*}
         */
            var isNumber = function (value) {
                if (!value) {
                    return true;
                }
                // If not a number return
                if (!INTEGER_REGEX.test(value)) {
                    return false;
                }
                // Validate that the provided value is not bigger than Integer.MAX_SIZE
                // See https://synovite.atlassian.net/browse/RP-1782
                var n = _.parseInt(value);
                return n <= MAX_INTEGER && n >= MIN_INTEGER;
            };

            /**
             * @description Validates floating point number
             * @param {Float} value
             * @returns {*}
             */
            var isFloatNumber = function (value) {
                if (!value) {
                    return true;
                }
                // If not a number return
                if (!FLOAT_REGEXP.test(value)) {
                    return false;
                }
                return true;
            };


            var isPositiveNumber = function (value) {
                if (isEmpty(value)) {
                    return true;
                }
                return isNumber(value) && _.parseInt(value) > 0;
            };

            var isPositiveNumberWithoutLeadingZeroes = function (value) {
                if (!value) {
                    return false;
                }

                // the same regex is used in the backend in case this validation is somehow bypassed
                var regex = new RegExp('^[1-9]([0-9]*)$');
                return regex.test(value);
            };
            /**
         * @description Validates variable name
         * @param {String} value
         * @returns {*}
         */
            var isValidVariableName = function (value) {
                if (!value) {
                    return false;
                }
                return STRING_VARIABLE_REGEX.test(value);
            };

            var isAlphanumeric = function (value) {
                if (!value) {
                    return false;
                }
                return ALPHANUMERIC_REGEX.test(value);
            };

            /**
         * @description ...
         * @param value
         * @returns {boolean}
         */
            var isValidNotJsReservedKeyword = function (value) {
                if (!value) {
                    return true;
                }
                return !RESERVED_JS_KEYWORDS_REGEX.test(value);
            };

            /**
         * @param {Object} obj
         * @returns {boolean}
         */
            var isEmptyObject = function (obj) {
                if (typeof obj !== 'object') {
                    return false;
                }
                var isEmpty = true;
                for (var property in obj) {
                    if (obj.hasOwnProperty(property) && property.charAt(0) !== '$') {
                        isEmpty = false;
                        break;
                    }
                }
                return isEmpty;
            };

            /**
         * @description from: inclusive to: exclusive. if to is not defined, MAX_INTEGER value will be used
         * @param input
         * @param from
         * @param to
         * @returns {boolean}
         */
            var isNumberRange = function (input, from, to) {
                if (!isNumber(input)) {
                    return false;
                }
                if (_.isUndefined(to) || to === null) {
                    to = MAX_INTEGER;
                }
                var number = _.parseInt(input);
                return number >= from && number < to;
            };

            /**
         * @description from: inclusive to: inclusive
         * @param input
         * @param from
         * @param to
         * @returns {boolean}
         */
            var isNumberRangeInclusive = function (input, from, to) {
                if (!isNumber(input)) {
                    return false;
                }
                var number = _.parseInt(input);
                return number >= from && number <= to;
            };

            /**
         * @param {String} dateInput
         * @returns {boolean}
         */
            var isValidTimeCondition = function (dateInput) {
                if (_.isUndefined(dateInput) || _.isNull(dateInput) || _.isEmpty(dateInput)) {
                    return false;
                }
                var trimmedInput = dateInput.trim();
                var numericPart = parseInt(trimmedInput, 10);
                if (_.isNaN(numericPart)) {
                    return false;
                }
                var len = trimmedInput.length;
                if (len !== numericPart.toString().length + 1) {
                    return false;
                }
                if (numericPart <= 0) {
                    return false;
                }
                var dateTypeSuffix = trimmedInput
                    .substring(len - 1, len)
                    .toLowerCase();
                var dateTypeConstant;
                _.forEach(TIME_RULE_CONSTRAINTS, function (constant, name) {
                    if (constant.suffix === dateTypeSuffix) {
                        dateTypeConstant = name;
                    }
                });
                if (_.isUndefined(dateTypeConstant) || _.isUndefined(TIME_RULE_CONSTRAINTS[dateTypeConstant])) {
                    return false;
                }
                return true;
            };

            /**
         * @description Checks the validity of the value as a percentage (0.00 - 100.00)
         * Returns true for null or undefined or empty values
         * Note: only takes dot, not comma - feel free to modify the regexp
         * @param {String} the value to be tested
         * @returns {boolean} true or false
         */

            var isValidPercentage = function (value) {
                if (!value) {
                    return true;
                }
                if (!FLOAT_REGEXP.test(value)) {
                    return false;
                }
                var floatValue = parseFloat(value).toFixed(2);
                if (floatValue <= 0 || floatValue > 100) {
                    return false;
                }
                return true;
            };

            /**
         * Checks that a string is a list of comma-separated valid emails
         * @param {String} value the value to be tested
         * @returns {boolean} true or false
         */

            var isValidEmailList = function (value) {
                if (!value) {
                    return true;
                }
                var emails = value.split(',');
                for (var i = 0; i < emails.length; i++) {
                    if (!EMAIL_REGEXP.test(emails[i].trim())) {
                        return false;
                    }
                }
                return true;
            };

            var isValidEmail = function (value) {
                if (!value) {
                    return true;
                }
                if (!EMAIL_REGEXP.test(value.trim())) {
                    return false;
                }
                return true;
            };

            /**
         * Checks if a string is a valid url
         * @param {type} value
         * @returns {Boolean}
         * @see http://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-an-url
         */
            var isValidURL = function isValidURL(value) {
                return URL_REGEXP.test(value);
            };


            /**
         * Check that a value is empty, null or undefined
         * Works for null, undefined, string, number, array or object
         * @param {*} value
         * @returns {boolean}
         */
            var isEmpty = function (value) {
            //check for null or undefined first
                if (_.isNull(value) || _.isUndefined(value) || _.isNaN(value)) {
                    return true;
                } else if (_.isDate(value)) {
                    return false;
                } else if (!_.isNumber(value) && !_.isString(value) && _.isEmpty(value)) {
                    return true;
                } else if (_.isString(value) && _.isEmpty(value.trim())) {
                    return true;
                } else if (typeof value === 'object' && isEmptyObject(value)) {
                    return true;
                }
                return false;
            };

            var isNotEmpty = function (value) {
                return !isEmpty(value);
            };

            var isValidSubnet = function isValidSubnet(newValue) {
                function subnetValid(subnet) {
                    return !_.isNull(subnet.match(IP_REGEXP));
                }

                if (_.isUndefined(newValue) || _.isEmpty(newValue)) {
                    return true;
                }

                if (_.isArray(newValue)) {
                    for (var i = 0; i < newValue.length; i++) {
                        if (!subnetValid(newValue[i])) {
                            return false;
                        }
                    }
                } else {
                    newValue = newValue.replace(/\s/g, '');
                    var tokens = newValue.split(',');
                    for (var j = 0; j < tokens.length; j++) {
                        if (!subnetValid(tokens[j])) {
                            return false;
                        }
                    }
                }
                return true;
            };

            var dateToNumber = function (input) {
                if (!input) {
                    return;
                } else if (input.getTime) {
                    return input.getTime();
                } else {
                    return input;
                }
            };

            var isDateBefore = function (dateToValidate, dateToCompare) {
                if (dateToValidate) {
                    return dateToNumber(dateToValidate) < dateToNumber(dateToCompare);
                }

                return true;
            };

            var isDateAfter = function (dateToValidate, dateToCompare) {
                if (dateToValidate) {
                    return dateToNumber(dateToValidate) > dateToNumber(dateToCompare);
                }

                return true;
            };

            /**
         * Validate a pattern given as parameter
         * Return false if RegExp throws an error, otherwise it's valid
         * @param {String} pattern
         * @returns {boolean}
         */
            var isValidPattern = function (pattern) {
                if (pattern) {
                    var valid = true;
                    for (var idx = 0; idx < pattern.length; idx++) {
                        if (pattern[idx] === '\\') {
                            var escapedChar = pattern[idx + 1];
                            if (!_.contains(Utils.SPECIAL_CHARS, escapedChar)) {
                                valid = false;
                            }
                            // skip escaped character
                            idx++;
                        }
                    }
                    if (!valid) {
                        return false;
                    }
                }
                try {
                // Use the super regex implementation, similar to new RegExp()
                    RegJsParser.parser.parse(pattern);
                    return true;
                } catch (e) {
                    return false;
                }
            };

            var isValidFolderName = function isValidFolderName(folderName) {
                return FOLDER_REGEXP.test(folderName);
            };

            var isValidLength = function (valueToValidate, maxLength) {
                if (!valueToValidate) {
                    return true;
                }
                return valueToValidate.length <= maxLength;

            };

            /**
         * Test that a value has no loose space delimiters inside the string
         * @param value
         * @returns {boolean}
         */
            var hasNoEmptySpaces = function hasNoEmptySpaces(value) {
                if (isEmpty(value)) {
                    return true;
                }
                return value.indexOf(' ') < 0;
            };

            var PREDEFINED_VALIDATIONS = {
                isRequired: isNotEmpty,
                isNumber: isNumber,
                isFloatNumber: isFloatNumber,
                isPositiveNumber: isPositiveNumber,
                isValidSubnet: isValidSubnet,
                isValidPattern: isValidPattern,
                isDateBefore: isDateBefore,
                isDateAfter: isDateAfter,
                isValidPercentage: isValidPercentage,
                isValidEmail: isValidEmail,
                isValidEmailList: isValidEmailList,
                isValidFolderName: isValidFolderName,
                isValidLength: isValidLength,
                hasNoEmptySpaces: hasNoEmptySpaces,
                isValidTimeCondition: isValidTimeCondition,
                isPositiveNumberWithoutLeadingZeroes: isPositiveNumberWithoutLeadingZeroes
            };

            var PREDEFINED_MESSAGES = {
                isRequired: 'Value is required',
                isNumber: 'Value should be a number',
                isPositiveNumber: 'Value should be a positive number',
                isValidSubnet: 'Value should be a valid subnet',
                isValidPattern: 'Value should be a valid regular expression',
                isValidPercentage: 'Value should be a valid percentage bigger than 0 and less or equal to 100',
                isValidEmail: 'Value should be a valid email',
                isValidEmailList: 'Value should a valid comma separated list of emails',
                isValidFolderName: 'Value should not contain ? % * : | " < > . # \\',
                isValidLength: 'Value should be shorter in length',
                hasNoEmptySpaces: 'Value should not contain empty spaces',
                isPositiveNumberWithoutLeadingZeroes: 'Value should be a positive number without leading zeros'
            };

            /**
         * Iterate the validationTokens that contains various tokens and set the validity on the form input
         * Also set the error class on the element if the $showError flag is true
         *
         * @param validationTokens Array passed through data-custom-validate, holds multiple validation tokens
         * @param formInput The (custom) validation input on the form
         * @param element the element on which to set the error class
         */
            var setValidationTokens = function (validationTokens, formInput, valueToValidate, element) {
                formInput.$errors = [];

                // object will be in the form of { isRequired: true, isNumber: false, isUnique: true }
                // or { isRequired: 'isRequired', isNumber: 'isNumber'} as predefined validations
                var setValidationToken = function (validationObj, formInput) {
                    for (var token in validationObj) {
                    //read all the validation tokens except the message
                        if (validationObj.hasOwnProperty(token) && !_.isUndefined(token) && !_.contains(['msg', 'args'], token)) {

                            var validStatus;
                            //if it's a token in the predefined
                            if (PREDEFINED_VALIDATIONS[token] && angular.isFunction(PREDEFINED_VALIDATIONS[token])) {
                            // determine validation arguments
                                var validationArgs = [valueToValidate];
                                if (validationObj.args) {
                                    validationArgs = validationArgs.concat(validationObj.args);
                                }
                                // compute the valid status on the spot, based on the $viewValue
                                validStatus = PREDEFINED_VALIDATIONS[token].apply(this, validationArgs);
                            } else {
                            //set the valid status as true/false as already computed
                                validStatus = !!validationObj[token];
                            }

                            //set the token validity if it has been defined
                            if (!_.isUndefined(validStatus)) {
                                formInput.$setValidity(token, validStatus);
                            }
                        }
                        //read the message token if there is a custom or predefined message
                        if (formInput.$error[token] === true) {
                            var errorMsg = validationObj['msg'] || PREDEFINED_MESSAGES[token];
                            if (errorMsg) {
                                formInput.$errors.push(errorMsg);
                            }
                        }
                    }
                };
                //if defined as array
                if (_.isArray(validationTokens)) {
                    for (var i = 0; i < validationTokens.length; i++) {
                        setValidationToken(validationTokens[i], formInput);
                    }
                } else if (_.isObject(validationTokens)) {
                    setValidationToken(validationTokens, formInput);
                }
                if (element) {
                //by default don't show the element in error state
                    element.removeClass('error');
                    formInput.$showError = formInput.$invalid && formInput.$dirty;
                    if (formInput.$showError) {
                        element.addClass('error');
                    } else {
                        element.removeClass('error');
                    }
                }
                formInput.$dirty = true;
                formInput.$pristine = false;
            };

            /**
         * @description Compares two variables for Loose Equality, converting one of the variables when needed.
         * @param var1 first variable to be compared
         * @param var2 second variable to be compared
         * @returns {boolean} if the variables are equal or contain the same value when their type is compatible
         */
            var areEqual = function (var1, var2) {
            //if values are already strictly equal, return true
                if (var1 === var2) {
                    return true;
                //if values are of the same type, return loadash isEquals
                } else if (typeof var1 === typeof var2) {
                    return _.isEqual(var1, var2);
                //if values are a number and a string, return == (that will convert values properly)
                } else if (_.isNumber(var1) && _.isString(var2) || _.isString(var1) && _.isNumber(var2)) {
                    // eslint-disable-next-line eqeqeq
                    return var1 == var2;
                //if values are a date and a number(timestamp), covert the date into timestamp and compare with ===
                } else if (_.isDate(var1) && _.isNumber(var2) || _.isNumber(var1) && _.isDate(var2)) {
                    return _.isDate(var1) ? var1.getTime() === var2 : var1 === var2.getTime();
                //if values are a boolean and a string, convert the boolean and compare the strings with ===
                } else if (_.isBoolean(var1) && _.isString(var2) || _.isString(var1) && _.isBoolean(var2)) {
                    return _.isBoolean(var1) ? var1.toString() === var2 : var1 === var2.toString();
                }
                //otherwise return false
                return false;
            };

            /**
         * RMP API
         * @type {ValidService}
         */
            return {
                INTEGER_REGEX: INTEGER_REGEX,
                PREDEFINED_MESSAGES: PREDEFINED_MESSAGES,
                PREDEFINED_VALIDATIONS: PREDEFINED_VALIDATIONS,
                isNumberRange: isNumberRange,
                isNumberRangeInclusive: isNumberRangeInclusive,
                isValidTimeCondition: isValidTimeCondition,
                isValidPercentage: isValidPercentage,
                isValidEmailList: isValidEmailList,
                isValidEmail: isValidEmail,
                isValidSubnet: isValidSubnet,
                isDateAfter: isDateAfter,
                isDateBefore: isDateBefore,
                isEmpty: isEmpty,
                isEmptyObject: isEmptyObject,
                isValidURL: isValidURL,
                isValidPattern: isValidPattern,
                isNumber: isNumber,
                isFloatNumber: isFloatNumber,
                isPositiveNumber: isPositiveNumber,
                isValidVariableName: isValidVariableName,
                isAlphanumeric: isAlphanumeric,
                isValidNotJsReservedKeyword: isValidNotJsReservedKeyword,
                setValidationTokens: setValidationTokens,
                isValidFolderName: isValidFolderName,
                isValidLength: isValidLength,
                areEqual: areEqual,
                hasNoEmptySpaces: hasNoEmptySpaces,
                isPositiveNumberWithoutLeadingZeroes: isPositiveNumberWithoutLeadingZeroes
            };
        }
    ]);
