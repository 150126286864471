import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Messages Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.messageId]: action.payload,
      };
    case types.MESSAGES_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(message => {
        nextState[message.messageId] = message;
      });
      return nextState;
    case types.DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Messages Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.MESSAGE_FETCH_SUCCESS:
      if (state.indexOf(action.payload.messageId) !== -1) {
        return state;
      }
      return [...state, action.payload.messageId];
    case types.MESSAGES_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(message => {
        if (state.indexOf(message.messageId) === -1) {
          nextState.push(message.messageId);
        }
      });
      return nextState;
    case types.DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};
const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.MESSAGES_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    default:
      return state;
  }
};

const messagesReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default messagesReducer;
