import React, { ReactElement } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';
import '~/components/src/Form/Elements/elements.scss';

interface ISelect {
  value?: any;
  hasError?: boolean;
  options: any[];
  isCreatable?: boolean;
  allowSelectAll?: boolean;
  onChange: (e: any) => void;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  noOptionsMessage?: (optionInfo: { inputValue: string }) => React.ReactNode;
  allOption?: any;
  className?: string;
  testHook?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  isClearable?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  onCreateOption?: (inputValue: string) => void;
  isValidNewOption?: (
    inputValue: string,
    selectValue: Array<any>,
    selectOptions: Array<any>,
    accessors: any,
  ) => boolean;
  createOptionPosition?: string;
  blurInputOnSelect?: boolean;
  formatCreateLabel?: (label: string) => void;
  id?: string;
  name?: string;
  onBlur?: () => void;
  onMenuClose?: () => void;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  defaultValue?: any;
  styles?: any;
  classNamePrefix?: string;
  components?: any;
  menuIsOpen?: boolean;
  menuPlacement?: string;
  maxMenuHeight?: number;
  formatGroupLabel?: boolean;
  getNewOptionData?: any;
  showOptionsCount?: boolean;
  menuPosition?: 'fixed' | 'absolute';
  error?: string;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly any[];
}

const convertValue = ({
  value,
  options,
  getOptionValue,
}: {
  value: any;
  options: any[];
  getOptionValue?: (option: any) => void;
}) => {
  if (typeof value === 'object') {
    // Default behavior of React Select v2+
    return value;
  }
  // Legacy behavior of React Select v1
  return options.find(option => {
    const targetValue = getOptionValue ? getOptionValue(option) : option.value;
    return targetValue === value;
  });
};

const getOptionsToRender = (value: any, options: any[], allowSelectAll = false, allOption: any) => {
  if (allowSelectAll) {
    if (value && value.length === options.length) {
      return options;
    }
    return [allOption, ...options];
  }
  return options;
};

const MoreSelectedBadge = ({ items }: { items: string[] }) => {
  const title = items.join(', ');
  const { length } = items;
  const label = `+ ${length} item${length !== 1 ? 's' : ''} selected`;

  return (
    <div className="SelectElement-countlabel" title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label);

  if (index < maxToShow) return <components.MultiValue {...props} />;
  if (index === maxToShow) return <MoreSelectedBadge items={overflow} />;
  return null;
};

const SelectElement = ({
  value,
  hasError,
  options,
  isCreatable,
  allowSelectAll,
  onChange,
  getOptionLabel,
  getOptionValue,
  noOptionsMessage,
  allOption,
  className,
  testHook,
  disabled,
  isLoading,
  isOptionDisabled,
  isClearable,
  autoFocus,
  placeholder,
  isSearchable = true,
  onCreateOption,
  isValidNewOption,
  createOptionPosition,
  blurInputOnSelect,
  formatCreateLabel,
  id,
  name,
  onBlur,
  onMenuClose,
  isMulti,
  defaultValue,
  styles,
  classNamePrefix,
  closeMenuOnSelect,
  components,
  hideSelectedOptions,
  menuIsOpen,
  menuPlacement,
  maxMenuHeight,
  formatGroupLabel,
  getNewOptionData,
  showOptionsCount,
  error,
  menuPosition = 'absolute',
}: ISelect): ReactElement => {
  const selectedValue = convertValue({ value, options, getOptionValue });
  const optionsToRender = getOptionsToRender(value, options, allowSelectAll, allOption);
  const newOptionFormatter = formatCreateLabel || ((label: string) => label && `Custom: ${label}`);

  const NormalOrCreatableSelect: any = isCreatable ? CreatableSelect : Select;

  const formatSelectGroupLabel = (data: GroupedOption) => (
    <div className="SelectGroup">
      <span>{data.label}</span>
      <span className="SelectGroup-badge">{data.options.length}</span>
    </div>
  );

  return (
    <div className="InputElement w-full">
      <NormalOrCreatableSelect
        value={selectedValue}
        defaultValue={defaultValue}
        options={optionsToRender}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        noOptionsMessage={noOptionsMessage}
        classNamePrefix={classNamePrefix || 'react-select'}
        className={cx(
          'SelectElement',
          {
            'SelectElement--hasError': hasError,
            'SelectElement--disabled': disabled,
            [`t-${testHook || name}`]: testHook || name,
          },
          className,
        )}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder={placeholder}
        isOptionDisabled={isOptionDisabled}
        autoFocus={autoFocus}
        formatCreateLabel={newOptionFormatter}
        isSearchable={isSearchable}
        onCreateOption={onCreateOption}
        isValidNewOption={isValidNewOption}
        createOptionPosition={createOptionPosition}
        blurInputOnSelect={blurInputOnSelect}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        onMenuClose={onMenuClose}
        isMulti={isMulti}
        styles={styles}
        closeMenuOnSelect={closeMenuOnSelect}
        components={showOptionsCount ? { MultiValue, ...components } : components}
        hideSelectedOptions={hideSelectedOptions}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        formatGroupLabel={formatGroupLabel && formatSelectGroupLabel}
        getNewOptionData={getNewOptionData}
        menuPosition={menuPosition}
      />
      {error && <small className="InputElement-error">{error}</small>}
    </div>
  );
};

export default SelectElement;
