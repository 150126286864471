import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import SortableTree, { ExtendedNodeData, find, ThemeProps } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import { refetchTaxonomy } from './dataService';
import { SelectTaxonomyProps, TTaxonomyTreeNode } from './types';
import { expandDirectory, expandToSelectedNode, getNodeKey, selectNode } from './utils';

const SelectTaxonomy = ({
  taxonomies,
  partnerId,
  selectedTaxonomy,
  canSelectParentNode = false,
  setNode,
  hideModal,
}: SelectTaxonomyProps) => {
  const [treeData, setTreeData] = useState(taxonomies);
  const [selectedNode, setSelectedNode] = useState(selectedTaxonomy);
  const [isRefetching, setIsRefetching] = useState(false);
  const parentNode = treeData && treeData[0];

  const handleRefreshTaxonomies = async () => {
    try {
      setIsRefetching(true);
      const newTaxonomies = await refetchTaxonomy(partnerId);
      setTreeData(newTaxonomies);

      setIsRefetching(false);
    } catch (err) {
      setIsRefetching(false);
    }
  };

  const handleNodeClick = (treeItem: ExtendedNodeData<TTaxonomyTreeNode>) => {
    const { path, node } = treeItem;
    const selectEnabled = canSelectParentNode || parentNode.id !== node.id;

    if (node.children.length) {
      expandDirectory(path, treeData, selectEnabled, setTreeData);
    } else {
      selectNode(path, treeData, setTreeData);
    }

    setSelectedNode(selectEnabled ? { ...node, selected: true } : undefined);
  };

  useEffect(() => {
    if (selectedNode?.id) {
      find({
        treeData,
        getNodeKey,
        searchMethod: ({ node, path }) => {
          if (node.id === selectedNode.id) {
            const newTree = expandToSelectedNode(treeData, node, path);
            setTreeData(newTree);
            return true;
          }
          return false;
        },
      });
    }
  }, []);

  return (
    <>
      <div className="absolute right-1 top-4 justify-end pr-4">
        <BtnIcon
          icon="refresh"
          onClick={() => handleRefreshTaxonomies()}
          disabled={isRefetching}
          tooltip={i18n.t('audiences:connectors.yahoo.tooltips.refresh')}
        />
      </div>

      <div className={cx('TaxonomyTree box-border flex-1 duration-100 ease-in')}>
        {treeData.length ? (
          <>
            <SortableTree
              className="!max-h-[35vh] !min-h-[20vh] rounded border p-2"
              canDrag={false}
              getNodeKey={getNodeKey}
              generateNodeProps={(treeItem: ExtendedNodeData<TTaxonomyTreeNode>) => ({
                title: () => {
                  const { node } = treeItem;
                  const isItemSelected = node.selected;

                  return (
                    <p
                      className={cx('flex cursor-pointer items-center gap-2 text-base', {
                        'rounded border border-blue-600 px-1 text-blue-600': isItemSelected,
                        'text-gray-600 hover:text-blue-500': !isItemSelected,
                      })}
                      onClick={() => handleNodeClick(treeItem)}
                    >
                      <span className="t-treeItem">{node.title}</span>
                    </p>
                  );
                },
              })}
              theme={FileExplorerTheme as ThemeProps<TTaxonomyTreeNode>}
              treeData={treeData}
              onChange={(updatedTreeData: TTaxonomyTreeNode[]) => setTreeData(updatedTreeData)}
            />
            <Notification className="mt-3" kind="information">
              {i18n.t('audiences:connectors.yahoo.notifications.taxonomyInfo')}
            </Notification>
          </>
        ) : (
          <Notification kind="error">{i18n.t('audiences:connectors.yahoo.selectTaxonomy.noRecords')}</Notification>
        )}
        <ActionButtons
          testHook="selectTaxonomy"
          className="!m-0 !pt-2"
          isConfirmEnabled={!!selectedNode && !!treeData.length}
          onConfirm={() => (selectedNode ? setNode(selectedNode) : null)}
          onDecline={() => hideModal()}
          confirmText={i18n.t('audiences:connectors.yahoo.selectTaxonomy.confirmText')}
        />
      </div>
    </>
  );
};

export default SelectTaxonomy;
