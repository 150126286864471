angular.module('webUi.ui.shared.browserNotSupported', ['ui.bootstrap'])

    .controller('BrowserNotSupportedController',['$uibModalInstance','$scope',
        function BrowserNotSupportedController(   $uibModalInstance , $scope) {

            /* modal actions */
            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
