import i18n from '~/i18n';
import { HpOptionsType } from './types';

export const HPWorkflowTypes = {
  Journey: 'Journey',
  Audience: 'Audience',
};

export const DEFAULTS = {
  DATA_RETENTION_MONTHS: 0,
  DATA_RETENTION_DAYS: 364,
  ALLOWED_HP_DAYS: 31,
} as const;

export const HpOptionIds = {
  BACKFILLING: 'BACKFILLING',
  REEVALUATE_GOALS: 'REEVALUATE_GOALS',
  REEVALUATE_EXIT_CONDITIONS: 'REEVALUATE_EXIT_CONDITIONS',
};

export const HpOptions = (workflowType: string): HpOptionsType[] => [
  {
    id: HpOptionIds.BACKFILLING,
    title: i18n.t('workflow:orchestration.historicProcessing.hpOptions.backfilling'),
    description: i18n.t('workflow:orchestration.historicProcessing.hpOptions.backfillingDescription', { workflowType }),
    icon: 'journey',
    color: 'emerald',
    disabledTooltip: '',
    hasOnlyCustomerFacts: false,
    mode: 'start trigger',
  },
  {
    id: HpOptionIds.REEVALUATE_GOALS,
    title: i18n.t('workflow:orchestration.historicProcessing.hpOptions.reevaluateGoal'),
    description: i18n.t('workflow:orchestration.historicProcessing.hpOptions.reevaluateGoalDescription'),
    icon: 'goal',
    color: 'amber',
    disabledTooltip: i18n.t('workflow:orchestration.historicProcessing.disabledCard', { workflowType, type: 'goals' }),
    hasOnlyCustomerFacts: false,
    mode: 'goals',
  },
  {
    id: HpOptionIds.REEVALUATE_EXIT_CONDITIONS,
    title: i18n.t('workflow:orchestration.historicProcessing.hpOptions.reevaluateExit'),
    description: i18n.t('workflow:orchestration.historicProcessing.hpOptions.reevaluateExitDescription', {
      workflowType,
    }),
    icon: 'exit',
    color: 'red',
    disabledTooltip: i18n.t('workflow:orchestration.historicProcessing.disabledCard', {
      workflowType,
      type: 'exit conditions',
    }),
    hasOnlyCustomerFacts: false,
    mode: 'exit conditions',
  },
];
