import * as types from './types';
import DependenciesService from './dataService.js';

export const fetchDependantsInfo = elementId => dispatch => {
  dispatch({
    type: types.DEPENDANTS_INFO_FETCH_START,
  });

  return DependenciesService.getDependantsInfo(elementId)
    .then(dependantsInfo => {
      dispatch({
        type: types.DEPENDANTS_INFO_FETCH_SUCCESS,
        payload: { id: elementId, dependantsInfo },
      });
    })
    .catch(error => dispatch({ type: types.DEPENDANTS_INFO_FETCH_FAIL, error }));
};

export const fetchDependantsConnectorInfo = connectorId => dispatch => {
  dispatch({
    type: types.DEPENDANTS_INFO_FETCH_START,
  });

  return DependenciesService.getDependantsConnectorInfo(connectorId)
    .then(dependantsInfo => {
      dispatch({
        type: types.DEPENDANTS_INFO_FETCH_SUCCESS,
        payload: { id: connectorId, dependantsInfo },
      });
    })
    .catch(error => dispatch({ type: types.DEPENDANTS_INFO_FETCH_FAIL, error }));
};

export const fetchPropertyDependantsInfo = (elementId, propertyId) => dispatch => {
  dispatch({
    type: types.PROPERTY_DEPENDANTS_INFO_FETCH_START,
  });

  return DependenciesService.getPropertyDependantsInfo(elementId, propertyId)
    .then(dependantsInfo => {
      dispatch({
        type: types.PROPERTY_DEPENDANTS_INFO_FETCH_SUCCESS,
        payload: { elementId, propertyId, dependantsInfo },
      });
    })
    .catch(error => dispatch({ type: types.PROPERTY_DEPENDANTS_INFO_FETCH_FAIL, error }));
};
