angular.module('webUi.service.exceptionHandling', ['uuid4'])

    .config(['$provide', function ($provide) {

        var errors = [];

        var openErrorModal = function ($uibModal, error, supportData) {
            var modal = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'ui/globalui/errorPopup/errorPopup.tpl.html',
                controller: 'ErrorPopupController as vm',
                dialogClass: 'modal error-modal',
                resolve: {
                    dialogsModel: function () {
                        return {
                            error: error,
                            supportData: supportData
                        };
                    }
                }
            });
            modal.result.then(function (res) {
                if (res) {
                    error.submitted = true;
                }
            });
            return modal;
        };

        $provide.decorator('$exceptionHandler', ['$delegate', '$injector', function ($delegate, $injector) {

            return function (exception, cause) {
                //ignore recoverable errors
                if (cause && (cause.status === 422)) {
                    return null;
                }
                $delegate(exception, cause);

                // get trace
                var trace = TraceKit.computeStackTrace(exception);
                var $state = $injector.get('$state');
                var uuid4 = $injector.get('uuid4');
                var $uibModal = $injector.get('$uibModal');

                // convert stack
                var stack = [];
                for (var i = 0; i < trace.stack.length; i++) {
                    var elem = trace.stack[i];
                    stack.push({
                        declaringClass: 'WebUi',
                        methodName: elem.func,
                        fileName: elem.url,
                        lineNumber: elem.line
                    });
                }

                // create report
                var errorReport = {
                    message: trace.message,
                    url: trace.url,
                    state: $state.current.name,
                    userAgent: trace.useragent,
                    stackTrace: stack,
                    identifier: uuid4.generate(),
                    submitted: false,
                    knownError: false
                };

                if (cause && cause.data && cause.data.status) {
                    errorReport.sentryEventId = cause.data.status.sentryEventId;
                }

                var equality = function (a, b) {
                    var propsToOmit = ['identifier', 'submitted', 'knownError'];
                    return _.isEqual(_.omit(a, propsToOmit), _.omit(b, propsToOmit));
                };
                // don't send same error twice
                for (var j = 0; j < errors.length; j++) {
                    if (_.isEqual(errors[j], errorReport, equality)) {
                        errors[j].knownError = true;
                        errorReport = errors[j];
                        break;
                    }
                }

                var SupportContactService = $injector.get('SupportContactService');
                var ExceptionHandlerHelperService = $injector.get('ExceptionHandlerHelperService');

                var supportData = {
                    supportPhone: SupportContactService.getSupportPhone(),
                    supportEmail: SupportContactService.getSupportEmail()
                };

                var isDigestError = errorReport.message && errorReport.message.indexOf('$digest') > -1;

                if (!errorReport.knownError || (isDigestError && !ExceptionHandlerHelperService.digestErrorIsSent())) {
                    errors.push(errorReport);

                    // mark sent digestError
                    if (isDigestError) {
                        ExceptionHandlerHelperService.setDigestError();
                    }

                    var Restangular = $injector.get('Restangular');
                    Restangular.all('errorReport').post(errorReport).then(function () {

                        // close existing
                        if (!_.isEmpty(ExceptionHandlerHelperService.getQueue())) {
                            ExceptionHandlerHelperService.closeAllErrorPopups();
                        }

                        ExceptionHandlerHelperService.register(openErrorModal($uibModal, errorReport, supportData));

                    });
                    // if known error and popup already open, don't show again
                } else {
                    if (_.isEmpty(ExceptionHandlerHelperService.getQueue())) {
                        ExceptionHandlerHelperService.register(openErrorModal($uibModal, errorReport, supportData));
                    }
                }
            };
        }]);
    }])


    .service('ExceptionHandlerHelperService', [function () {

        var errorPopupsOpenedQueue = [];
        var digestErrorIsSent = false;

        var service = {

            /**
             * Registers the modal instance with the errorQueue service so it can be closed at a future time
             */
            register: function (modalInstance) {
                errorPopupsOpenedQueue.push(modalInstance);
            },

            /**
             * Closes all popups in the queue and empties it
             */
            closeAllErrorPopups: function () {
                _.each(errorPopupsOpenedQueue, function (modalInstance) {
                    modalInstance.close(false);
                });
                errorPopupsOpenedQueue = [];
            },

            getQueue: function () {
                return errorPopupsOpenedQueue;
            },

            digestErrorIsSent: function () {
                return digestErrorIsSent;
            },

            resetDigestError: function () {
                digestErrorIsSent = false;
            },

            setDigestError: function () {
                digestErrorIsSent = true;
            }
        };

        return service;
    }]);
