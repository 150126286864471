import React, { useReducer, useState, useEffect } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Empty from '~/components/src/Empty';
import DateRangePicker from '~/components/src/DateRangePicker';
import { getAngularService } from 'ReactAngular/react.service';
import { R42AnimatedDashboard } from '~/components/src/R42Icons';
import { I18NextT } from '~/components/src/Common/types';
import Insights from './sections/Insights';
import { reducer, initialState, actionTypes } from './reducer';
import { DashboardContext } from './context';
import Metrics from './Metrics';

interface DashboardProps {
  t: I18NextT;
}

interface SecurityContext {
  isSupportEngineer: () => boolean;
  hasModule: (moduleName: string) => boolean;
  hasPermission: (permissionName: string) => boolean;
}

const Dashboard = ({ t }: DashboardProps) => {
  const SecurityService = getAngularService(document, 'SecurityService');
  const [state, dispatch] = useReducer(reducer, initialState);
  const { startDate, endDate } = state;

  const [permissions, setPermissions] = useState({
    isSupportEngineer: false,
    hasTagViewPermission: false,
    hasDataAccessPermission: false,
    hasProfilesViewPermission: false,
    hasCustomerJourneyViewPermission: false,
    hasAudiencesPermission: false,
  });

  useEffect(() => {
    SecurityService.getSecurityContext().then((context: SecurityContext) => {
      const isSupportEngineer = context.isSupportEngineer();

      /* Note that support engineers always have TAG_VIEW and CUSTOMER_JOURNEY_VIEW permissions, even if these are disabled in Context Panel */
      const hasTagViewPermission = context.hasModule('TAG_MANAGEMENT') && context.hasPermission('TAG_VIEW');
      const hasProfilesViewPermission = context.hasModule('DATA_MANAGEMENT') && context.hasPermission('PROFILES_VIEW');
      const hasDataAccessPermission = context.hasModule('DATA_MANAGEMENT') && context.hasPermission('DATA_ACCESS');
      const hasAudiencesPermission = context.hasModule('AUDIENCES') && context.hasPermission('AUDIENCES_VIEW');
      const hasCustomerJourneyViewPermission =
        context.hasModule('CUSTOMER_JOURNEY') && context.hasPermission('WORKFLOW_VIEW');

      setPermissions({
        isSupportEngineer,
        hasTagViewPermission,
        hasDataAccessPermission,
        hasProfilesViewPermission,
        hasCustomerJourneyViewPermission,
        hasAudiencesPermission,
      });
    });
  }, []);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    dispatch({ type: actionTypes.SET_DATE_RANGE, startDate, endDate });
  };

  const {
    isSupportEngineer,
    hasProfilesViewPermission,
    hasDataAccessPermission,
    hasCustomerJourneyViewPermission,
    hasTagViewPermission,
    hasAudiencesPermission,
  } = permissions;

  const canViewEvents = isSupportEngineer || hasProfilesViewPermission || hasCustomerJourneyViewPermission;
  const canViewTags = isSupportEngineer || hasTagViewPermission;
  const canViewTotalCalls = canViewTags || canViewEvents;

  const canViewAudiences = hasDataAccessPermission && hasProfilesViewPermission && hasAudiencesPermission;
  const canViewPartners = hasDataAccessPermission || hasProfilesViewPermission;

  if (!canViewTotalCalls && !canViewAudiences && !hasCustomerJourneyViewPermission && !canViewPartners) {
    return (
      <Empty
        excludePanel
        icon={R42AnimatedDashboard}
        iconSize={96}
        header={t('error.noModulesHead')}
        body={t('error.noModulesBody')}
      />
    );
  }

  const dashboardContextValue = { state, dispatch };

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <Heading
        title={t('dashboard:header')}
        className="z-50 border-b border-gray-200 bg-slate-50 px-4 py-8 dark:border-gray-700  dark:bg-gray-800 md:sticky md:top-0"
      >
        <DateRangePicker
          className="Dashboard-heading__datePicker"
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={handleDatesChange}
        />
      </Heading>

      <Metrics canViewEvents={canViewEvents} canViewTags={canViewTags} canViewTotalCalls={canViewTotalCalls} />

      {(canViewAudiences || hasCustomerJourneyViewPermission || canViewPartners) && (
        <Insights
          canViewAudiences={canViewAudiences}
          canViewPartners={canViewPartners}
          hasCustomerJourneyViewPermission={hasCustomerJourneyViewPermission}
        />
      )}
    </DashboardContext.Provider>
  );
};

const DashboardT = translate('dashboard')(Dashboard);

const DashboardPage = (): JSX.Element => <DashboardT />;

export default DashboardPage;
