import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import {
  saveTemplate,
  fetchTemplateIfNeeded,
  fetchTagContentTypesIfNeeded,
  fetchPlatformTypesIfNeeded,
} from '~/support/tagtemplates/templates/actions';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import { fetchGroups } from '~/support/tagtemplates/groups/actions';
import { getGroupsSelect } from '~/support/tagtemplates/groups/selectors';
import * as selectors from '~/support/tagtemplates/templates/selectors';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import TagTemplateForm from '~/support/components/TagTemplateForm';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

const FORM_EDIT = 'tagTemplateEdit';

export class TagTemplateAdd extends Component {
  componentDidMount() {
    const { id, fetchPlatformTypes, fetchTagContentTypes, fetchTemplate, fetchGroups } = this.props;
    fetchTemplate(id);
    fetchPlatformTypes();
    fetchTagContentTypes();
    fetchGroups();
  }

  render() {
    const { t, ...other } = this.props;

    return !this.props.initialValues ? (
      <Spinner />
    ) : (
      <Page>
        <MediumWrapper>
          <Heading title={t('templateEdit.header')} />
          <Panel>
            <TagTemplateForm t={t} {...other} />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps.params.templateId;
      const initialValues = selectors.getTemplateById(state, id);
      const currentLogoPath =
        initialValues && initialValues.logo ? `/assets/img/templates/${encodeURIComponent(initialValues.logo)}` : '';
      return {
        id,
        isGroupEditable: true,
        cancelHref: buildUrl(`support/tagtemplates/templates/view/${id}`),
        initialValues,
        currentLogoPath,
        contentTypes: selectors.getTagContentTypes(state),
        platformTypes: selectors.getPlatformTypes(state),
        groups: getGroupsSelect(state),
      };
    },
    dispatch => ({
      fetchTemplate: id => dispatch(fetchTemplateIfNeeded(id)),
      fetchGroups: () => dispatch(fetchGroups()),
      fetchPlatformTypes: () => dispatch(fetchPlatformTypesIfNeeded()),
      fetchTagContentTypes: () => dispatch(fetchTagContentTypesIfNeeded()),
      onSubmit: values => dispatch(saveTemplate(values)),
    }),
  ),
  reduxForm({
    form: FORM_EDIT,
    enableReinitialize: true,
  }),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagTemplateAdd);
