import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '~/components/src/Page';
import { ShowUsageContainer } from '~/context/clients/containers';

const ClientSitesUsagePage = () => {
  const { clientId } = useParams();
  return (
    <Page>
      <ShowUsageContainer clientId={clientId} />
    </Page>
  );
};

export default ClientSitesUsagePage;
