import { cloneDeep } from 'lodash';
import { withChangeHandler } from '../utils';
import {
  DateFormatFields,
  DateToEpochSecondsFields,
  CreateValueTransformation,
  TransformationType,
  ValueTransformation,
  ValueTransformationPayload,
  EpochSecondsToDateFields,
  HashingFields,
  StringToArrayFields,
  ValueMappingFields,
  Fields,
  SuggestedValueTransformationResonseItem,
  StringToNumberFields,
} from '../transformerTypes';

const DATE_FORMAT: DateFormatFields = {
  inputFormat: '',
  outputFormat: '',
};

const DATE_TO_EPOCH_SECONDS: DateToEpochSecondsFields = {
  inputFormat: '',
};

const EPOCH_SECONDS_TO_DATE: EpochSecondsToDateFields = {
  dateFormat: '',
  offset: '',
};

const HASHING: HashingFields = {
  algorithm: '',
};

const STRING_TO_ARRAY: StringToArrayFields = {
  delimiter: ',',
};

const STRING_TO_NUMBER: StringToNumberFields = {
  groupingSeparator: '.',
  decimalSeparator: ',',
};

const VALUE_MAPPING: ValueMappingFields = {
  valueMappings: [{ from: '', to: '' }],
};

const DEFAULT_FIELDS: { [transformationType: string]: Fields } = {
  DATE_FORMAT,
  DATE_TO_EPOCH_SECONDS,
  EPOCH_SECONDS_TO_DATE,
  HASHING,
  STRING_TO_ARRAY,
  VALUE_MAPPING,
  STRING_TO_NUMBER,
};

export const temporaryValueTransformationId = (): string =>
  `newValueTransformation-${Math.random().toString().slice(-5)}`;

const substituteNullValuesWithEmptyStrings = (fields: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(fields).map(([key, value]) => {
      if (value === null) {
        value = '';
      }
      return [key, value];
    }),
  );

export const createValueTransformation: CreateValueTransformation = (valueTransformationConfiguration, onChange) => {
  let _tranformationType = valueTransformationConfiguration.transformationType || ('' as const);

  let _fields = cloneDeep(valueTransformationConfiguration.fields || DEFAULT_FIELDS[_tranformationType]);

  let _reason = valueTransformationConfiguration.reason || null;

  const setTransformationType = (transformationType: TransformationType) => {
    _tranformationType = transformationType;

    const defaultFields = DEFAULT_FIELDS[transformationType];

    _fields = cloneDeep(defaultFields || {});

    _reason = null;
  };

  const setFieldValue = (name: string, value: string | Record<string, unknown>[]) => {
    const isExistingField = typeof _fields[name] !== 'undefined';

    if (isExistingField) {
      _fields[name] = value;
    }
  };

  const methods = withChangeHandler({ setTransformationType, setFieldValue }, onChange);

  return {
    id: valueTransformationConfiguration.id,
    get transformationType() {
      return _tranformationType;
    },
    get fields() {
      return _fields;
    },
    get reason() {
      return _reason;
    },
    setTransformationType: methods.setTransformationType,
    setFieldValue: methods.setFieldValue,
  };
};

export const createValueTransformationsFromPayload = (
  valueTransformations: ValueTransformationPayload[],
  onChange: () => void,
): ValueTransformation[] =>
  valueTransformations.map(
    (valueTransformationsPayload: ValueTransformationPayload | SuggestedValueTransformationResonseItem) => {
      const { transformationType, reason, ...fields } = valueTransformationsPayload;

      const fieldsWithoutNulls = substituteNullValuesWithEmptyStrings(fields);

      const valueTransformationConfiguration = {
        id: temporaryValueTransformationId(),
        transformationType,
        fields: fieldsWithoutNulls,
        reason,
      };

      return createValueTransformation(valueTransformationConfiguration, onChange);
    },
  );
