import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import CodeElement from '../Elements/Code';

const CodeRow = ({ disabled, input, label, mode, meta }) => (
  <FormRow isBlock={true} disabled={disabled} name={input.name} label={label} meta={meta}>
    <CodeElement mode={mode} disabled={disabled} input={input} isError={meta.touched && !!meta.error} />
  </FormRow>
);

CodeRow.propTypes = {
  isBlock: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.any.isRequired,
  mode: PropTypes.oneOf(['css', 'xml', 'javascript']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default CodeRow;
