import React, { Component } from 'react';
import { getAngularService } from 'ReactAngular/react.service';
import Spinner from '~/components/src/Spinner';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import UserPermissionsList from '~/admin/components/UserPermissionsList';
import UserBasicList from '~/admin/components/UserBasicList';
import { SearchContainer } from '~/common';
import { USERS_KEY, USERS_FILTER_KEY } from '~/admin/users';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';

const searchFilterFn = query => item =>
  (item.username && item.username.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
  (item.name && item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);

class UserListContainer extends Component {
  componentDidMount() {
    if (this.props.shouldFetchClients) {
      this.props.fetchClients();
    }
    if (this.props.siteClientId) {
      this.props.fetchSiteUsers();
    }
    this.props.fetchPermissionsMap();
  }

  render() {
    const {
      addUserHref,
      isContextAdmin,
      isSiteAdmin,
      t,
      isFetchingPermissions,
      isFetchingUsers,
      siteNumber,
      permissionsUiFlat,
      permissionsApiFlat,
      getClientName,
      permissionsUi,
      permissionsApi,
      site,
      users,
      stateKey,
      onUserLockSettingsClick,
      onEditUser,
      onDeleteUser,
      disableAddingNewUser,
    } = this.props;
    const AdminService = getAngularService(document, 'AdminService');
    const onExportClick = () => {
      AdminService.exportUsers(users, siteNumber, permissionsUiFlat, permissionsApiFlat);
    };
    const commonProps = {
      list: users,
      t,
      stateKey,
      isContextAdmin,
    };
    const adminActions = !(isSiteAdmin || isContextAdmin)
      ? {}
      : {
          onUserLockSettingsClick: user => onUserLockSettingsClick(t, user, site),
          onEditClick: user => onEditUser(t, user, site),
          onDeleteClick: user => onDeleteUser(user, site),
        };
    const TabsPart = (
      <Tabs className="u-paddingTopNone">
        <TabList>
          <Tab>{t('list.tabs.webui')}</Tab>
          <Tab>{t('list.tabs.api')}</Tab>
          <Tab>{t('list.tabs.basic')}</Tab>
        </TabList>

        {/* WebUI Roles */}
        <TabPanel>
          <UserPermissionsList {...commonProps} {...adminActions} permissions={permissionsUi} />
        </TabPanel>

        {/* API Roles */}
        <TabPanel>
          <UserPermissionsList {...commonProps} {...adminActions} permissions={permissionsApi} />
        </TabPanel>

        {/* Basic Info */}
        <TabPanel>
          <UserBasicList {...commonProps} {...adminActions} getClientName={getClientName} />
        </TabPanel>
      </Tabs>
    );

    return (
      <Page>
        <Heading title={t('list.header')}>
          <SearchContainer stateKey={USERS_KEY} searchKey={USERS_FILTER_KEY} searchFn={searchFilterFn} />
          <BtnIcon onClick={() => onExportClick()} tooltip={t('list.actions.exportCSV')} icon="download" />
          {!isSiteAdmin || disableAddingNewUser ? null : (
            <LinkIcon
              url={addUserHref}
              tooltip={t('list.actions.addUser')}
              icon="add"
              color="blue"
              className="h-8 w-8"
            />
          )}
        </Heading>
        {isFetchingPermissions || isFetchingUsers || !site ? <Spinner /> : TabsPart}
      </Page>
    );
  }
}

export default UserListContainer;
