/**
 *
 */
angular.module('webUi.ui.profiles.externalFacts.base',
    [
        'webUi.ui.profiles.base'
    ]
)
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.externalFacts', {
                url: '/externalFacts',
                isReact: true
            });
    }]);
