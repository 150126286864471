export const CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_START = 'app/context/clients/CLIENT_VIEW_FETCH_DOMAINS_START';
export const CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_SUCCESS = 'app/context/clients/CLIENT_VIEW_FETCH_DOMAINS_SUCCESS';
export const CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_FAIL = 'app/context/clients/CLIENT_VIEW_FETCH_DOMAINS_FAIL';

export const CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_START = 'app/context/clients/CLIENT_VIEW_DELETE_DOMAINS_START';
export const CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_SUCCESS = 'app/context/clients/CLIENT_VIEW_DELETE_DOMAINS_SUCCESS';
export const CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_FAIL = 'app/context/clients/CLIENT_VIEW_DELETE_DOMAINS_FAIL';

export const CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_START = 'app/context/clients/CLIENT_VIEW_SAVE_DOMAINS_START';
export const CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_SUCCESS = 'app/context/clients/CLIENT_VIEW_SAVE_DOMAINS_SUCCESS';
export const CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_FAIL = 'app/context/clients/CLIENT_VIEW_SAVE_DOMAINS_FAIL';

export const CONTEXT_CLIENT_VIEW_SHOULD_FETCH_DOMAINS = 'app/context/clients/CONTEXT_CLIENT_VIEW_SHOULD_FETCH_DOMAINS';
