import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    clickDistrictId: Yup.number()
      .required(t('cards.validation.platformId'))
      .positive(t('cards.validation.positiveNumber'))
      .typeError(t('cards.validation.positiveNumber')),
  });

export const Platform161 = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="clickDistrictId"
          label={t('cards.label.clickDistrictId')}
          placeholder={t('common.commonField.placeholder')}
          errorText={formProps.touched.clickDistrictId && formProps.errors.clickDistrictId}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
