import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';

const { Table, Row, Cell } = components;
const { withEmpty, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('variantHistory.empty.header')} body={t('variantHistory.empty.body')} />;

export const VariantHistoryList = ({ stateKey, t, list, showVariant }) => (
  <Table stateKey={stateKey}>
    <Row>
      <SortableHeader width="60%" sortKey="name" title={t('variantHistory.name')} />
      <SortableHeader width="20%" sortKey="sequenceNr" title={t('variantHistory.sequenceNr')} />
      <SortableHeader width="20%" sortKey="deleted" title={t('variantHistory.deleted')} />
    </Row>
    {list.map((item, index) => (
      <Row key={index}>
        <Cell style={{ width: '60%' }}>
          <Link onClick={() => showVariant(item)}>{item.name}</Link>
        </Cell>
        <Cell style={{ width: '20%' }}>{item.sequenceNr}</Cell>
        <Cell style={{ width: '20%' }}>{item.deleted ? t('common:checkbox.truthy') : t('common:checkbox.falsy')}</Cell>
      </Row>
    ))}
  </Table>
);

VariantHistoryList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  showVariant: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withEmpty({ component: EmptyList }), withSort(), withPaginate({ size: 10 }))(VariantHistoryList);
