import constants from '~/profiles/connectors/form/constants';

export const EVENT_TYPES = ['ENGAGEMENT', 'EXTERNAL_FACT'] as const;

export const PARTNER_TYPES_WITH_TRANSFORMER_SUPPORT = [constants.partnerTypes.FACEBOOK_CONVERSIONS];

export const transformationTypes = {
  DATE_FORMAT: 'DATE_FORMAT',
  DATE_TO_EPOCH_SECONDS: 'DATE_TO_EPOCH_SECONDS',
  EPOCH_MILLIS_TO_SECONDS: 'EPOCH_MILLIS_TO_SECONDS',
  EPOCH_SECONDS_TO_DATE: 'EPOCH_SECONDS_TO_DATE',
  EPOCH_SECONDS_TO_MILLIS: 'EPOCH_SECONDS_TO_MILLIS',
  HASHING: 'HASHING',
  COUNTRY_CODE: 'COUNTRY_CODE',
  STRING_TO_ARRAY: 'STRING_TO_ARRAY',
  STRING_TO_NUMBER: 'STRING_TO_NUMBER',
  STRING_TO_BOOLEAN: 'STRING_TO_BOOLEAN',
  VALUE_MAPPING: 'VALUE_MAPPING',
};

export const mappingPropertyTypes = {
  STATIC_SOURCE_PROPERTY: 'STATIC_SOURCE_PROPERTY',
  EVENT_SOURCE_PROPERTY: 'EVENT_SOURCE_PROPERTY',
};
