import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import { truncateString } from '~/common/utils/StringUtils';
import { Tooltip } from '~/components';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import { TStepCardProps } from '~/workflows/types';
import CopyID from '../CopyID';

const StepCard = ({ step, onClick, selectedStepId, testHook = 'step', label }: TStepCardProps) => (
  <div
    className={cx(
      'flex cursor-pointer flex-col rounded border  bg-white p-2',
      `t-stepCard-${testHook}`,
      selectedStepId === step?.nodeId ? 'border-blue-500' : 'border',
    )}
    onClick={() => onClick(step?.nodeId)}
  >
    <div className={`border-b py-3 t-stepCard-${testHook}-header flex flex-col`}>
      <div className="flex justify-between">
        <Tooltip tooltip={step?.label}>
          <div
            className={cx('w-full truncate text-lg font-medium', {
              'text-blue-500': selectedStepId === step?.nodeId,
              'w-4/5': step?.experimentVariant?.weight,
            })}
          >
            {truncateString(label, 25)}
          </div>
        </Tooltip>
        {!isNil(step?.experimentVariant?.weight) && (
          <span className="text-gray-500">{step?.experimentVariant?.weight} %</span>
        )}
      </div>
      <CopyID testHook="copyStepId" id={step?.nodeId} />
    </div>

    <div className="my-3 flex justify-between">
      <div className="flex items-center gap-1">
        <Icons icon="connector" className="mr-1 h-6 w-6" />
        {i18n.t('workflow:overview.headers.connectors')}
      </div>
      <span className={`text-gray-500 t-stepCard-${testHook}-integrationCount`}>{step?.integrationCount}</span>
    </div>
    <div className="my-3 flex justify-between">
      <div className="flex items-center gap-1">
        <Icons icon="event" className="mr-1 h-6 w-6" />
        {i18n.t('workflow:overview.headers.eventListeners')}
      </div>
      <span className={`text-gray-500 t-stepCard-${testHook}-eventListenerCount`}>{step?.eventListenerCount}</span>
    </div>
  </div>
);

export default StepCard;
