import React from 'react';

// Properties which are added by table context and should not appear on DOM nodes
export const TABLE_CONTEXT_PROPS = ['stateKey', 'isTable', 'isSelectable'];

/**
 * Trim properties which aren't needed to be on DOM nodes
 * @param {Object} props
 */
export const excludeContextualProps = props =>
  Object.keys(props).reduce((newProps, key) => {
    if (!TABLE_CONTEXT_PROPS.includes(key)) {
      newProps[key] = props[key];
    }
    return newProps;
  }, {});

export const TableContext = React.createContext({});

/**
 * Context wrapper which injects context properties on child component
 * @param {React.Component} Component
 */
export const tableContextWrapper = Component => props => {
  Component.displayName = 'ContextfulComponent';
  return <TableContext.Consumer>{tableContext => <Component {...props} {...tableContext} />}</TableContext.Consumer>;
};
