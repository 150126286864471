import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import { withRouter } from '~/common/withRouter';
import Spinner from '~/components/src/Spinner';
import * as selectors from '../selectors';
import { fetchVariantsIfNeeded } from '../actions';
import CookiesList from '../../components/CookiesList';

class CookiesVariantsContainer extends Component {
  componentDidMount() {
    this.props.fetchVariantsIfNeeded();
  }

  render() {
    const { isFetching, ...props } = this.props;
    return (
      <div>
        <CookiesList {...props} />
        <Spinner isActive={isFetching} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: selectors.isFetchingVariants(state),
  list: selectors.getVariants(state),
  stateKey: 'SUPPORT/COOKIES_LIST',
  variantUrl: item => buildUrl(`support/cookiepermissionforms/view/${item.cookiePermissionVariantId}`),
});

const mapDispatchToProps = dispatch => ({
  fetchVariantsIfNeeded: () => dispatch(fetchVariantsIfNeeded()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate('cookies'),
)(CookiesVariantsContainer);
