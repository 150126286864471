import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { buildUrl, useAPI } from '~/common';
import { Link, Notification, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { WORKFLOW_STATUS } from '~/workflows/constants';
import {
  getExitConditionStats,
  getExperimentsStats,
  getGeneralStats,
  getGoalStats,
  getWorkflowSteps,
} from '~/workflows/dataService';
import { TWorkflow } from '~/workflows/types';
import { formatExpiryTime } from '~/workflows/util';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import WorkflowStatusTag from '../WorkflowStatusTag';
import ConnectorStats from './components/ConnectorStats';
import GeneralStats from './components/GeneralStats';
import ActivityStats from './components/ActivityStats';
import TriggerStats from './components/TriggerStats';
import ExperimentStats from './components/ExperimentStats';

const StatsHeader = ({ workflowData }: { workflowData: TWorkflow }) => {
  const navigate = useNavigate();

  const { journeyId, state, expiryTime } = workflowData;

  const { hasWorkflowAudienceEdit: canEdit } = useContext(WorklfowContext);
  const goToJourneyView = () => navigate(buildUrl(`workflows/view/${journeyId}`), { replace: true });
  const goToJourneyEdit = () => navigate(buildUrl(`workflows/edit/${journeyId}`), { replace: true });

  const title = workflowData?.name || i18n.t('workflow:journey.journeyStatistics.header');
  const statusText =
    state.name === WORKFLOW_STATUS.PUBLISHED
      ? i18n.t('workflow:orchestration.publish.publishedLabel')
      : i18n.t('workflow:orchestration.publish.unpublishedChangesLabel');

  return (
    <div className="flex border-b border-gray-200 bg-white p-4">
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <Link onClick={() => goToJourneyView()} className="PageTitleHeader-titleContainer--link">
            <Icons
              icon="back"
              tooltip={i18n.t('workflow:orchestration.header.backToJourneyList')}
              className="h-6 w-6 text-gray-400"
            />
          </Link>
          <Icons icon="audience" className="h-6 w-6 text-blue-600" />
          <p className="text-xl">{title}</p>
        </div>

        <div className="ml-8 mt-2 flex items-center gap-1 text-sm">
          <WorkflowStatusTag status={state.name} text={statusText} />

          <div className="flex items-center gap-1">
            <Icons icon="schedule" className="h-4 w-4" />
            {formatExpiryTime(expiryTime)}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
        <BtnIcon
          icon="edit"
          testHook="audienceEdit"
          onClick={() => goToJourneyEdit()}
          tooltip={i18n.t(`workflow:orchestration.${canEdit ? 'editJourney' : 'permissions.notEnoughPermissions'}`)}
          disabled={!canEdit}
        />
      </div>
    </div>
  );
};

const AudienceStatistics = ({ workflowData }: { workflowData: TWorkflow }) => {
  const params = useParams();
  const workflowId = params.workflowId || '';

  const { data: generalStats, isLoading: isGeneralStatsLoading, error } = useAPI(() => getGeneralStats(workflowId));
  const { data: allSteps, isLoading: isStepsLoading } = useAPI(() => getWorkflowSteps(workflowId));
  const { data: allGoals, isLoading: isGoalsLoading } = useAPI(() => getGoalStats(workflowId));
  const { data: allExits, isLoading: isExitsLoading } = useAPI(() => getExitConditionStats(workflowId));
  const { data: experimentsStats, isLoading: isExperimentsLoading } = useAPI(() => getExperimentsStats(workflowId));

  const [selectedTab, setSelectedTab] = useState(0);

  const hasSteps = allSteps && allSteps.length > 0;
  const hasGoals = allGoals && allGoals.length > 0;
  const hasExitConditions = allExits && allExits.length > 0;
  const hasExperiments = experimentsStats && experimentsStats?.stats?.length > 0;

  const isLoading = isGeneralStatsLoading || isStepsLoading || isGoalsLoading || isExitsLoading || isExperimentsLoading;

  return (
    <>
      <StatsHeader workflowData={workflowData} />
      <Page excludeContainer className="p-4">
        {isLoading && <Spin />}
        {!isLoading && error && <Notification kind="error" header={error.message} />}
        {!isLoading && generalStats && <GeneralStats stats={generalStats} />}

        <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
          <TabList>
            <Tab testHook="activity-tab">{i18n.t('workflow:stats.tabs.activity')}</Tab>
            <Tab testHook="experiments-tab" disabled={!hasExperiments}>
              {i18n.t('workflow:stats.tabs.experiments')}
            </Tab>
            <Tab testHook="connectors-tab" disabled={!hasSteps}>
              {i18n.t('workflow:stats.tabs.connectors')}
            </Tab>
            <Tab testHook="goals-tab" disabled={!hasGoals}>
              {i18n.t('workflow:stats.tabs.goals')}
            </Tab>
            <Tab testHook="exits-tab" disabled={!hasExitConditions}>
              {i18n.t('workflow:stats.tabs.exits')}
            </Tab>
          </TabList>
          <TabPanel>
            <ActivityStats workflowId={workflowId} allSteps={allSteps || []} />
          </TabPanel>

          <TabPanel>
            {hasExperiments ? (
              <ExperimentStats
                experimentsStats={experimentsStats}
                totalEntered={generalStats?.profilesEnteredJourney || 0}
              />
            ) : null}
          </TabPanel>

          <TabPanel>{hasSteps ? <ConnectorStats allSteps={allSteps} workflowId={workflowId} /> : <></>}</TabPanel>
          <TabPanel>
            {hasGoals ? (
              <TriggerStats
                workFlowId={workflowId}
                triggers={allGoals}
                totalEntered={generalStats?.profilesEnteredJourney || 0}
                isGoal
              />
            ) : null}
          </TabPanel>
          <TabPanel>
            {hasExitConditions ? (
              <TriggerStats
                workFlowId={workflowId}
                triggers={allExits}
                totalEntered={generalStats?.profilesEnteredJourney || 0}
              />
            ) : null}
          </TabPanel>
        </Tabs>
      </Page>
    </>
  );
};

export default AudienceStatistics;
