angular.module('webUi.ui.tagmanagement.tags.view.placeholderbuttons.placeholderdialog', [
    'webUi.ui.tagmanagement.tags.view.placeholderbuttons.placeholderdialog.propertydialog'
])

    .controller('PlaceholderDialogController', ['$scope', 'dialogsModel', '$uibModalInstance',
        function PlaceholderDialogController($scope, dialogsModel, $uibModalInstance){

            $scope.placeholder = dialogsModel.placeholder;
            $scope.placeholderValue = {};

            $scope.close = function(){
                $uibModalInstance.close(false);
            };
            $scope.add = function(placeholderValue){
                if(placeholderValue) {
                    var returnVal = $scope.placeholder;
                    returnVal.value = placeholderValue;
                    $uibModalInstance.close(returnVal);
                } else {
                    return true;
                }
            };
        }]);


