import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BtnIcon from '~/components/src/BtnIcon';

const RowAction = props => (
  <BtnIcon
    disabled={props.isDisabled}
    icon={props.icon}
    onClick={props.onClick}
    testHook={props.testHook}
    tooltip={props.tooltip}
    className={cx('text-gray-600', { 'text-gray-400': props.isDisabled })}
  />
);

RowAction.propTpes = {
  tooltip: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  testHook: PropTypes.string.isRequired,
};

export default RowAction;
