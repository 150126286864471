import React, { ReactNode } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, useAPI } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormSensitiveField,
  ReduxFormSelectField,
  ReduxFormInputField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { TDotDigitalProps } from './types';
import { fetchRegions } from './dataService';

function DotDigitalForm({ t, values, partner, canUpdate, touch }: TDotDigitalProps): ReactNode {
  const partnerNumber = values.partnerNumber || partner.partnerNumber;
  const { data: regions = [], isLoading: isRegionsLoading } = useAPI(() => fetchRegions(), []);

  if (isRegionsLoading || !regions) {
    return <Spin />;
  }

  return (
    <FormSection name={constants.partnerTypes.DOTDIGITAL}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <div className="flex flex-col gap-3">
          <Field
            label={t('form.formFields.authenticationType')}
            name="authentication.authenticationType"
            component={ReduxFormSelectField}
            options={[{ value: constants.STANDARD, label: t('form.formFields.standard') }]}
            disabled={true}
          />
          <Field
            label={t('form.formFields.region')}
            infoTooltip={t('form.formFields.tooltip.region')}
            name="regionUrl"
            component={ReduxFormSelectField}
            options={regions}
            validate={isRequired}
            touch={touch}
          />
          <FieldWithEditMode
            label={t('form.formFields.username')}
            infoTooltip={t('form.formFields.tooltip.username')}
            name="authentication.username"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.password')}
            infoTooltip={t('form.formFields.tooltip.password')}
            name="authentication.password"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
        </div>
      </ContainerFormSection>
    </FormSection>
  );
}

export default DotDigitalForm;
