import React from 'react';

import { fetchAudiencesTree } from '~/profiles/audiences/actions';
import { showSuccess, showError } from '~/notificationCenter';
import i18n from '~/i18n';
import * as fromModals from '~/modals';
import GroupAdd from '~/profiles/components/GroupAdd';
import * as types from '../types';
import * as AudienceService from '../dataService.js';

/**
 * Add new Group
 */
export const addGroup = groupName => dispatch => {
  dispatch({
    type: types.GROUP_ADD_START,
  });

  return AudienceService.addGroup(groupName)
    .then(id => {
      dispatch({
        type: types.GROUP_ADD_SUCCESS,
        payload: {
          id,
          allowChildren: true,
          isNew: true,
          hasChildren: false,
          childrenIds: [],
          name: groupName,
          searchableProperties: null,
          type: 'GROUP',
        },
      });

      dispatch(fetchAudiencesTree()).then(() => {
        showSuccess({ header: i18n.t('common:groups.groupCreated') });
      });
    })
    .catch(error => {
      dispatch({ type: types.GROUP_ADD_FAIL, error });
    });
};

/**
 * Delete single group
 * @param {UUID} id
 */
export const deleteGroup = id => dispatch => {
  dispatch({
    type: types.GROUP_DELETE_START,
  });

  return AudienceService.removeGroup(id)
    .then(() => {
      dispatch({
        type: types.GROUP_DELETE_SUCCESS,
        payload: {
          id,
        },
      });

      dispatch(fetchAudiencesTree()).then(() => {
        showSuccess({ header: i18n.t('common:groups.groupDeleted') });
      });
    })
    .catch(error => {
      dispatch({ type: types.GROUP_DELETE_FAIL, error });
      showError({ header: i18n.t('common:groups.groupNotDeleted'), body: i18n.t('common:errors.genericError') });
    });
};

/**
 * Move audience to or from the group
 * @param {*} group
 * @param {*} audience
 */
export const moveItem = (group, audience) => dispatch => {
  dispatch({
    type: types.MOVE_ITEM_START,
  });

  return AudienceService.moveItem(group.id, audience.id)
    .then(() => {
      dispatch({
        type: types.MOVE_ITEM_SUCCESS,
        payload: {
          groupId: group.id,
          audienceId: audience.id,
        },
      });
      if (group.id) {
        showSuccess({
          body: i18n.t('audiences:list.alerts.movedToFolder', { itemName: audience.name, folderName: group.name }),
        });
      } else {
        showSuccess({ body: i18n.t('audiences:list.alerts.movedToRoot', { itemName: audience.name }) });
      }
    })
    .catch(error => dispatch({ type: types.MOVE_ITEM_FAIL, error }));
};

export const openCreateAudienceGroupModal = () => dispatch => {
  const handleAddGroup = ({ groupName }) => {
    dispatch(addGroup(groupName));
    dispatch(fromModals.hideModal());
  };

  const hideModal = () => dispatch(fromModals.hideModal());

  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:groupAdd.header'),
      confirmText: i18n.t('audiences:groupAdd.confirmText'),
      content: <GroupAdd handleAddGroup={handleAddGroup} hideModal={hideModal} />,
    }),
  );
};

export const openDeleteAudienceGroupModal = (name, id) => dispatch => {
  dispatch(
    fromModals.showDeleteModal({
      title: i18n.t('audiences:groupDelete.title'),
      message: i18n.t('audiences:groupDelete.message', { name }),
      onConfirm: () => dispatch(deleteGroup(id)),
    }),
  );
};
