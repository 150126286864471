/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/context`;

/**
 * A service for basescript related queries.
 */
export class ContextBasescriptsClass {
  getBasescripts() {
    return Api.callGet(`${BASE_PATH}/basescripts`).then(response => response.data);
  }

  getBasescriptModules() {
    return Api.callGet(`${BASE_PATH}/basescriptModules`).then(response => response.data);
  }

  createBasescript(basescript) {
    return Api.callPost(`${API_BASE}/context/basescripts/add`, basescript).then(response => response.data);
  }

  editBasescript(basescript) {
    return Api.callPost(`${BASE_PATH}/basescripts/update`, basescript).then(response => response.data);
  }

  linkBasescriptToSites(basescriptId, siteIds) {
    return Api.callPost(`${BASE_PATH}/basescripts/linkToSites`, {
      basescriptId,
      siteIds,
    }).then(response => response.data);
  }
}

const contextBasescriptsService = new ContextBasescriptsClass();
export default contextBasescriptsService;
