import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchMessageIfNeeded, deleteMessage } from '~/support/messages/actions';
import * as selectors from '~/support/messages/selectors';
import Message from '~/components/src/Message';
import Spinner from '~/components/src/Spinner';
import { buildUrl } from '~/common/history';
import * as fromModals from '~/modals';
import i18n from '~/support/i18n';
import { mergeTranslations } from '~/common/i18n';

class MessageContainer extends Component {
  componentDidMount() {
    const { fetchMessage, id } = this.props;
    fetchMessage(id);
  }

  render() {
    return this.props.message ? <Message {...this.props} /> : <Spinner />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  ts: mergeTranslations(ownProps.t, 'message', 'common'),
  editLink: buildUrl(`support/messages/edit/${ownProps.id}`),
  message: selectors.getMessageById(state, ownProps.id),
});

const mapDispatchToProps = dispatch => ({
  fetchMessage: id => {
    dispatch(fetchMessageIfNeeded(id));
  },
  onDeleteClick: message => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: i18n.t('messages:message.deleteModal.title'),
        message: i18n.t('messages:message.deleteModal.message', { title: message.title }),
        onConfirm: () => dispatch(deleteMessage(message.messageId)),
      }),
    );
  },
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(MessageContainer);

Connected.propTypes = {
  id: PropTypes.string.isRequired,
};

export default translate('messages')(Connected);
