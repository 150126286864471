import { RulesTypes } from '~/common/modules/rules/types';
import { getOverlapCandidates } from '../dataService';
import { TRule } from '../types';

export const fetchJourneyOverlaps = (rule: TRule, journeyId: string, alreadySelectedOverlapJourneyIds: string[]) =>
  getOverlapCandidates(journeyId).then(data =>
    data
      .filter(item => !alreadySelectedOverlapJourneyIds.includes(item.journeyId))
      .map(item => ({
        title: item.journeyName,
        ruleDefinitionId: rule.ruleDefinitionId,
        availableFilters: [],
        type: RulesTypes.JourneyOverlap,
        key: item.journeyId,
        extra: {
          dependantJourneyId: item.journeyId,
        },
      })),
  );
