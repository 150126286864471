import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import { Field } from 'formik';
import CardMessage, { MessageTypes } from '~/components/src/CardMessage';
import { useAPI } from '~/common/ApiHooks';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { getProjects, getDataSources, createAttribute } from './service';

const validations = t =>
  Yup.object().shape({
    project: Yup.object().shape({
      name: Yup.string().required(t('cards.validation.fieldIsRequired')),
      proeject: Yup.string(),
    }),
    dcpDatasource: Yup.object().shape({
      name: Yup.string().required(t('cards.validation.fieldIsRequired')),
      id: Yup.string(),
    }),
  });

const Optimizely = ({ onEdit, connector, t, stageName, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const { data: projects, error: projectsError, isLoading: isProjectLoading } = useAPI(() => getProjects(partnerId));

  const [dataSources, setDataSources] = useState([]);
  const [isDataSourceLoading, setIsDataSourceLoading] = useState(false);

  const connectorFormProps = get(connector, 'form.connectorFormProps');
  const dcpServiceId = get(connectorFormProps, 'values.project.dcpServiceId');

  const fetchDataSources = id => {
    setDataSources([]);
    if (id) {
      setIsDataSourceLoading(true);
      getDataSources(partnerId, id)
        .then((data = []) => {
          setDataSources(
            data.filter(
              dataSource => dataSource.keyfieldLocatorType === 'cookie' && dataSource.keyfieldLocatorName !== null,
            ),
          );
        })
        .finally(() => setIsDataSourceLoading(false));
    }
  };

  useEffect(() => fetchDataSources(dcpServiceId), []);

  const onCreateAttribute = ({ id }, setFieldValue) => {
    createAttribute(partnerId, id, `Relay Selection ${stageName}`).then(data => {
      setFieldValue('attributeId', data.id);
      setFieldValue('attributeName', data.name);
    });
  };

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => {
        const attributeName = get(formProps, 'values.attributeName');
        const projectId = get(formProps, 'values.project.projectId');
        const dcpDatasource = get(formProps, 'values.dcpDatasource');
        return (
          <>
            <SmallWrapperNoOffset>
              <Field
                name="project"
                as={FormikSelectField}
                label={t('audiences:connectors.optimizely.projects')}
                errorText={formProps.touched.project && formProps.errors.project && formProps.errors.project.name}
                options={projects ? projects.filter(item => item.dcpServiceId) : []}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.projectId}
                customSetFieldValue={(name, value) => {
                  formProps.setFieldValue(name, value);
                  formProps.setFieldValue('dcpDatasource', {});
                  formProps.setFieldValue('attributeName', null);
                  fetchDataSources(value.dcpServiceId);
                }}
                isClearable={false}
                isLoading={isProjectLoading}
                testHook="optimizelyProjects"
              />

              <Field
                name="dcpDatasource"
                as={FormikSelectField}
                label={t('audiences:connectors.optimizely.dcpDatasource')}
                errorText={
                  formProps.touched.dcpDatasource &&
                  formProps.errors.dcpDatasource &&
                  formProps.errors.dcpDatasource.name
                }
                options={dataSources}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                customSetFieldValue={(name, value) => {
                  formProps.setFieldValue(name, value);
                  onCreateAttribute(value, formProps.setFieldValue);
                }}
                isClearable={false}
                isLoading={isDataSourceLoading}
                testHook="dcpDatasource"
              />
            </SmallWrapperNoOffset>

            {attributeName && projectId && dcpDatasource && (
              <CardMessage
                type={MessageTypes.INFO}
                message={
                  <Trans
                    i18nKey="audiences:connectors.optimizely.optimizelyAttribute"
                    values={{
                      attributeName,
                      projectId,
                    }}
                  >
                    An attribute named <strong>{{ attributeName }}</strong> will be created and can be used in the
                    Optimizely interface to define an audience. You will also need to publish an Optimizely tag for this
                    site with project ID <strong>{{ projectId }}</strong>
                  </Trans>
                }
              />
            )}
            {projectsError && <CardMessage message={t('audiences:connectors.optimizely.error')} />}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default Optimizely;
