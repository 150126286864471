angular
    .module('webUi.ui.profiles.thirdPartyDataCollectors.collectorsEdit', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.profiles.thirdPartyDataCollectors.collectorsEdit', {
                url: '/edit/{collectorId}',
                isReact: true,
            });
        },
    ]);
