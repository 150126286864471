import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { TNewExperimentGroup } from '../types';

const ExpGroupModal = ({
  group,
  closeModal,
  addGroup,
  editGroup,
}: {
  group: any;
  closeModal: () => void;
  addGroup?: (group: TNewExperimentGroup) => void;
  editGroup: (group: any) => void;
}) => {
  const isEdit = !!group;
  const initialValues = group || {
    name: '',
    weight: undefined,
    controlGroup: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    weight: Yup.number().min(0).max(100).required(i18n.t('validation:validation.required')),
    controlGroup: Yup.bool(),
  });

  const handleCreateNewGroup = (values: TNewExperimentGroup) => {
    if (isEdit) editGroup(values);
    else addGroup?.(values);
    closeModal();
  };

  return (
    <Formik
      onSubmit={handleCreateNewGroup}
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnChange={false}
    >
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <Field
            name="name"
            as={FormikInputField}
            label={i18n.t('experiments:form.group.name')}
            placeholder={i18n.t('experiments:placeholders.input')}
            errorText={errors.name}
            required
          />
          <Field
            name="weight"
            type="number"
            label={i18n.t('experiments:form.group.traffic')}
            as={FormikInputField}
            placeholder={i18n.t('experiments:placeholders.input')}
            errorText={errors.weight}
            required
          />

          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={() => closeModal()}
            className="m-0"
            confirmText={i18n.t(`experiments:form.group.${isEdit ? 'update' : 'create'}Group`)}
            testHook="createUpdateGroup"
          />
        </form>
      )}
    </Formik>
  );
};

export default ExpGroupModal;
