import { combineReducers } from 'redux';
import { isArray } from 'lodash';
import { USER_SITE_REMOVE_SUCCESS } from '~/permissions';
import * as types from './types';

const byId = (state = {}, action) => {
  let userSites;
  switch (action.type) {
    case types.USER_SITES_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: action.payload.sites,
      };
    case USER_SITE_REMOVE_SUCCESS:
      userSites = state[action.payload.userId];
      return !isArray(userSites)
        ? state
        : {
            ...state,
            [action.payload.userId]: userSites.filter(item => item.siteId !== action.payload.siteId),
          };
    default:
      return state;
  }
};

/**
 * UI State
 */
const defaultUI = {
  isFetchingUserSites: false,
  isAddingSites: false,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.USER_SITES_FETCH_START:
      return {
        ...state,
        isFetchingUserSites: true,
      };
    case types.USER_SITES_FETCH_SUCCESS:
    case types.USER_SITES_FETCH_FAIL:
      return {
        ...state,
        isFetchingUserSites: false,
      };
    case types.USER_SITES_ADD_START:
      return {
        ...state,
        isAddingSites: true,
      };
    case types.USER_SITES_ADD_SUCCESS:
    case types.USER_SITES_ADD_FAIL:
      return {
        ...state,
        isAddingSites: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ui,
});
