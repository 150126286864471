import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, isNumber } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { FacebookFormProps, FacebookPayloadType } from './types';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const FacebookForm = (props: FacebookFormProps): React.ReactElement => {
  const { t, payloadTypes, values, change, partner, canUpdate, touch, fetchPayloadTypes, mode } = props;
  useEffect(() => {
    fetchPayloadTypes(constants.FACEBOOK);
  }, []);

  const payloadTypesByName = payloadTypes.reduce(
    (payloadTypesByName, payloadType) => {
      payloadTypesByName[payloadType.name] = payloadType;
      return payloadTypesByName;
    },
    {} as Record<string, FacebookPayloadType>,
  );

  const isPartnerNumberFieldVisible =
    mode === constants.modes.CREATE_SERVER &&
    values.payloadType &&
    payloadTypesByName[values.payloadType].allowsPartnerNumberOverride;
  return (
    <FormSection name={constants.partnerTypes.FACEBOOK}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <div className="flex flex-col gap-3">
          <Field
            label={t('form.formFields.accountId')}
            name="accountId"
            component={ReduxFormInputField}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.systemUserAccessToken')}
            name="accessToken"
            component={ReduxFormSensitiveField}
            validate={isRequired}
            isNeedActivateEditMode={canUpdate}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            disabled={canUpdate}
            validate={isRequired}
            onChange={(event, value) => {
              change('FACEBOOK.partnerNumber', payloadTypesByName[value].partnerNumber);
            }}
            touch={touch}
          />
          {isPartnerNumberFieldVisible && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="number"
              min={1}
            />
          )}
          {values?.payloadType === constants.HASHED_ID && (
            <Field
              label={t('form.formFields.applicationId')}
              name="applicationId"
              component={ReduxFormInputField}
              validate={[isRequired, isNumber]}
              type="text"
            />
          )}
        </div>
      </ContainerFormSection>
    </FormSection>
  );
};

export default FacebookForm;
