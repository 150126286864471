import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Empty from '~/components/src/Empty';
import { Elements } from '~/components/src/Form';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Tooltip } from '~/components';
import { toggleRestriction } from '../actions';
import * as selectors from '../selectors';

const { Table, Row, Cell } = components;
const { withEmpty, withSort, withPaginate } = enhancements;
const { Checkbox } = Elements;

const EmptyList = ({ t }) => <Empty header={t('restrict.empty.header')} body={t('restrict.empty.body')} />;

export const RestrictClientsModal = ({ list, stateKey, t, toggleRestrict }) => (
  <Table stateKey={stateKey}>
    <Row>
      <SortableHeader width="50%" sortKey="clientName" title={t('clients.clientName')} />
      <SortableHeader width="40%" sortKey="markedToRestrict" title={t('restrict.restrictHeader')} />
    </Row>
    {list.map((item, index) => (
      <Row key={item.clientId}>
        <Cell style={{ width: '50%' }}>
          <span className="truncate text-ellipsis">
            <Tooltip tooltip={item.clientName}>{item.clientName}</Tooltip>
          </span>
        </Cell>
        <Cell style={{ width: '40%' }}>
          <Checkbox
            input={{
              name: `restrict${index}`,
              value: item.markedToRestrict,
              onChange: () => toggleRestrict(item.clientId),
            }}
          >
            {item.markedToRestrict ? t('restrict.restrict') : t('restrict.unrestrict')}
          </Checkbox>
        </Cell>
      </Row>
    ))}
  </Table>
);

export default compose(
  connect(
    (state, ownProps) => ({
      t: ownProps.t,
      stateKey: 'SUPPORT/COOKIES/RESTRICT_CLIENTS',
      list: selectors.getUnRestrictedClientsForVariant(state, ownProps.variantId),
    }),
    (dispatch, ownProps) => ({
      toggleRestrict: clientId => dispatch(toggleRestriction(ownProps.variantId, clientId)),
    }),
  ),
  withEmpty({ component: EmptyList }),
  withSort(),
  withPaginate({ size: 10 }),
)(RestrictClientsModal);
