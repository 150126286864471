import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAngularService } from 'ReactAngular/react.service';
import PrivateRoute from '~/components/src/PrivateRoute';
import SitesPage from './Sites';
import SiteAddPage from './SiteAdd';
import SiteViewPage from './SiteView';
import SiteUsagePage from './SiteUsage';
import SiteEditPage from './SiteEdit';
import BasescriptsPage from './Basescripts';
import EditBasescriptPage from './BasescriptEdit';
import AddBasescriptPage from './BasescriptAdd';
import ClientPages from './clients';
import UsersPage from './Users';
import UserNewPage from './UserNew';
import UserEditPage from './UserEdit';
import UserPage from './User';
import PromoteUserPage from './UserPromote';
import LinkUserToSite from './LinkUserToSite';

class ContextPages extends Component {
  constructor(props) {
    super(props);
    this.state = { isContextAdmin: false };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isContextAdmin: context.isContextAdmin(),
        isReady: true,
        loggedInUser: context.user,
      });
    });
  }

  render() {
    const { isReady, isContextAdmin, loggedInUser } = this.state;
    if (isReady) {
      return (
        <Routes>
          <Route path="sites/list" element={<SitesPage />} />
          <Route path="sites/add" element={<SiteAddPage />} />
          <Route path="sites/edit/:siteId" element={<SiteEditPage />} />
          <Route
            path="sites/view/:siteId"
            element={<SiteViewPage isContextAdmin={isContextAdmin} loggedInUser={loggedInUser} />}
          />
          <Route path="sites/view/:siteId/usage" element={<SiteUsagePage />} />

          <Route path="clients/*" element={<ClientPages />} />

          <Route path="basescripts/list" element={<BasescriptsPage isContextAdmin={isContextAdmin} />} />
          <Route
            path="basescripts/view/:basescriptId"
            element={<EditBasescriptPage authenticated={isContextAdmin} />}
          />
          <Route
            path="basescripts/add"
            element={<PrivateRoute authenticated={isContextAdmin} component={AddBasescriptPage} />}
          />
          <Route path="users/list" element={<UsersPage isContextAdmin={isContextAdmin} />} />
          <Route path="users/view/:userId" element={<UserPage isContextAdmin={isContextAdmin} />} />
          <Route
            path="users/edit/:userId"
            element={<PrivateRoute authenticated={isContextAdmin} component={UserEditPage} />}
          />
          <Route
            path="users/promoteUserToClientAccountManager/:userId"
            element={<PrivateRoute authenticated={isContextAdmin} component={PromoteUserPage} />}
          />
          <Route
            path="users/linkusersite/:userId"
            element={<PrivateRoute authenticated={isContextAdmin} component={LinkUserToSite} />}
          />
          <Route path="users/new" element={<UserNewPage />} />
        </Routes>
      );
    }
    return null;
  }
}

export default ContextPages;
