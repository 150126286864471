angular.module('webUi.ui.context.sites.view', [
    'webUi.ui.context.sites.base',
    'webUi.ui.shared.editPermissions',
    'ui.bootstrap'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.sites.view', {
                url : '/view/{siteId}',
                isReact: true,
            })
            .state('site.context.sites.viewUsage', {
                url: '/view/{siteId}/usage',
                isReact: true
            });
    }]);
