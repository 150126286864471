export const COLLECTORS_FETCH_START = 'app/profiles/collectors/COLLECTORS_FETCH_START';
export const COLLECTORS_FETCH_SUCCESS = 'app/profiles/collectors/COLLECTORS_FETCH_SUCCESS';
export const COLLECTORS_FETCH_FAIL = 'app/profiles/collectors/COLLECTORS_FETCH_FAIL';

export const COLLECTORS_SAVE_START = 'app/profiles/collectors/COLLECTORS_SAVE_START';
export const COLLECTORS_SAVE_SUCCESS = 'app/profiles/collectors/COLLECTORS_SAVE_SUCCESS';
export const COLLECTORS_SAVE_FAIL = 'app/profiles/collectors/COLLECTORS_SAVE_FAIL';

export const COLLECTORS_UPDATE_START = 'app/profiles/collectors/COLLECTORS_UPDATE_START';
export const COLLECTORS_UPDATE_SUCCESS = 'app/profiles/collectors/COLLECTORS_UPDATE_SUCCESS';
export const COLLECTORS_UPDATE_FAIL = 'app/profiles/collectors/COLLECTORS_UPDATE_FAIL';

export const COLLECTORS_DELETE_START = 'app/profiles/collectors/COLLECTORS_DELETE_START';
export const COLLECTORS_DELETE_SUCCESS = 'app/profiles/collectors/COLLECTORS_DELETE_SUCCESS';
export const COLLECTORS_DELETE_FAIL = 'app/profiles/collectors/COLLECTORS_DELETE_FAIL';

export const CODE_FETCH_START = 'app/profiles/collectors/CODE_FETCH_START';
export const CODE_FETCH_SUCCESS = 'app/profiles/collectors/CODE_FETCH_SUCCESS';
export const CODE_FETCH_FAIL = 'app/profiles/collectors/CODE_FETCH_FAIL';

export const COLLECTORS_EDIT_NAME = 'collectorEdit';
export const COLLECTORS_NEW_NAME = 'collectorNew';
export const DEFAULT_R42_CONNECTOR = { optionId: 0, optionLabel: 'Relay42 Content Platform' };
