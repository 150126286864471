import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  TTagCounter,
  TTagCounterNumbersResponse,
  TTagCountersForm,
  IUpdateTagCounterNumber,
  IUpdateTagCounterName,
  IUpdateTagCounterDescription,
  IUpdateTagCounterShownOnDashboard,
  CounterStatsData,
} from './types';
import { generateRangeQueryParams, parseCounterStats } from './utils';

const BASE_URL_TAGMANAGEMENT = `${API_BASE}/tagmanagement`;
const BASE_URL = `${BASE_URL_TAGMANAGEMENT}/tagCounters`;
const BASE_URL_NUMBERS = `${BASE_URL_TAGMANAGEMENT}/tagCounterNumbers`;

export const fetchTagCounters = (): Promise<TTagCounter[]> => Api.callGet(`${BASE_URL}`).then(parseResponse);

export const fetchtagCounterById = (counterId: string): Promise<TTagCounter> =>
  Api.callGet(`${BASE_URL}/${counterId}`).then(parseResponse);

export const createTagCounter = (payload: TTagCountersForm): Promise<string> =>
  Api.callPost(`${BASE_URL}`, payload).then(parseResponse);

export const fetchTagCounterNumbers = (): Promise<TTagCounterNumbersResponse> =>
  Api.callGet(`${BASE_URL_NUMBERS}`).then(parseResponse);

export const updateTagCounterNumber = (payload: IUpdateTagCounterNumber): Promise<string> =>
  Api.callPost(`${BASE_URL}/number`, payload).then(parseResponse);

export const updateTagCounterName = (payload: IUpdateTagCounterName): Promise<string> =>
  Api.callPost(`${BASE_URL}/name`, payload).then(parseResponse);

export const updateTagCounterDescription = (payload: IUpdateTagCounterDescription): Promise<string> =>
  Api.callPost(`${BASE_URL}/description`, payload).then(parseResponse);

export const updateTagCounterShownOnDashboard = (payload: IUpdateTagCounterShownOnDashboard): Promise<string> =>
  Api.callPost(`${BASE_URL}/shownOnDashboard`, payload).then(parseResponse);

export const removeTagCounter = (counterId: string): Promise<string> =>
  Api.callDelete(`${BASE_URL}/${counterId}`).then(parseResponse);

export const getTagCounterStats = (startDate: number, endDate: number, ids = ''): Promise<CounterStatsData> =>
  Api.callGet(
    `${BASE_URL_TAGMANAGEMENT}/tagCounterStatsSelectedRange?${generateRangeQueryParams(startDate, endDate)}&ids=${ids}`,
  )
    .then(parseResponse)
    .then(parseCounterStats);
