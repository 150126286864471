angular.module('webUi.ui.content.campaigns.placementGroups.form', ['webUi.ui.content.campaigns.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.placementGroups.form', {
                url: '/form?campaignId',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/content/campaigns/placementGroups/form/form.tpl.html',
                        controller: 'AdservingPlacementGroupFormController as vm'
                    }
                },
                leftMenu: true,
                resolve: {
                    defaultPlacements: ['PlacementGroupService', function (PlacementGroupService) {
                        return PlacementGroupService.getDefaultPlacements();
                    }],
                    campaign: ['$stateParams', 'CampaignService', function ($stateParams, CampaignService) {
                        return CampaignService.getCampaign($stateParams.campaignId);
                    }]
                }
            });
    }])

    .controller('AdservingPlacementGroupFormController', ['$state', '$stateParams', 'PubSubService', 'PlacementGroupService', 'ValidService', 'defaultPlacements', 'campaign',
        function AdservingPlacementGroupFormController($state, $stateParams, PubSubService, PlacementGroupService, ValidService, defaultPlacements, campaign) {

            'use strict';
            
            var vm = this;

            vm.data = {
                placementGroup: {
                    campaignId: campaign.campaignId,
                    campaignName: campaign.name,
                    placements: defaultPlacements || []
                },
                newPlacement: {}
            };

            //validation function checking that there are placements
            vm.hasPlacements = function (placementGroup) {
                return placementGroup.placements && placementGroup.placements.length > 0;
            };

            //check that the new placement to be added is valid numerically
            vm.isValidPlacement = function isValidPlacement(placement) {
                if (ValidService.isEmpty(placement)) {
                    return false;
                }
                return ValidService.isPositiveNumber(placement.width) && ValidService.isPositiveNumber(placement.height);
            };

            vm.isUniquePlacement = function (placement, placements) {
                if (ValidService.isEmpty(placement)) {
                    return false;
                }
                var foundIdx = _.findIndex(placements, function (p) {
                    return p.width === _.parseInt(placement.width) && p.height === _.parseInt(placement.height);
                });
                return foundIdx === -1;
            };

            //only add the newPlacement if the innerForm is valid
            vm.addPlacement = function (placement, placementGroup) {
                placementGroup.placements.push({width: _.parseInt(placement.width), height: _.parseInt(placement.height)});
                vm.data.newPlacement = {};
            };

            /**
         * removes placement from group
         * @param placement
         */
            vm.removePlacement = function (placement, placementGroup) {
                placementGroup.placements = _.without(placementGroup.placements, placement);
            };

            vm.submit = function (placementGroup) {
                PlacementGroupService.addPlacementGroup(placementGroup).then(function () {
                    $state.transitionToStateWithSite('site.content.campaigns.view', { campaignId: $stateParams.campaignId });
                });
            };
        }
    ]);
