import React from 'react';
import { useAPI } from '~/common';
import { getAllRuleVariables, getRulesDefinitions } from '~/common/modules/rules/dataService';
import { Heading, RulesList } from '~/components';
import { RuleCardTypes } from '~/components/src/RuleList';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { fetchTrigger } from '~/workflows/dataService';
import { prepareDataForViewTrigger } from '~/workflows/form/Journey/Trigger/utils';
import { TTriggerDetailsProps } from '~/workflows/types';

const TriggerDetails = ({ triggerId, type = NodeTypes.TRIGGER }: TTriggerDetailsProps): JSX.Element => {
  const { data, isLoading } = useAPI(async () => {
    const [trigger, ruleDefinitions, variables] = await Promise.all([
      fetchTrigger(triggerId),
      getRulesDefinitions(type),
      getAllRuleVariables(),
    ]);

    return prepareDataForViewTrigger(trigger, ruleDefinitions, variables);
  }, [triggerId]);

  if (isLoading || !data) return <Spin />;

  const { criteria } = data;
  const isGoal = type === NodeTypes.GOAL;
  const isExitCondition = type === NodeTypes.EXIT_BY_CONDITION;
  const isJourneyCondition = isGoal || isExitCondition;

  return (
    <>
      <Heading title={i18n.t('workflow:overview.rules')} kind="h4" testHook="eventListenersHeading" />
      <RulesList
        criteria={criteria}
        ruleCardType={RuleCardTypes.ViewCard}
        className="ViewTrigger-rulesList"
        isJourneyCondition={isJourneyCondition}
      />
    </>
  );
};

export default TriggerDetails;
