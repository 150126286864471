export default {
  goals: [
    {
      nodeId: '01dfc3db-c77c-4fbe-8476-588508c6ab5c',
      label: 'Journey Goal',
      stats: {
        profilesReachedGoal: 38980,
        variantStats: [
          {
            experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
            name: 'Experiment Variant',
            profilesReached: 29809,
            profilesEnteredVariant: 49440,
          },
          {
            experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
            name: 'Control group Variant',
            profilesReached: 17221,
            profilesEnteredVariant: 38980,
          },
        ],
      },
    },
  ],
  exitConditions: [
    {
      nodeId: 'bb73ef1f-36a3-4f92-8b88-f1570b7c3eb5',
      label: 'Journey Exit',
      stats: {
        profilesExitedByCondition: 5921,
        variantStats: [
          {
            experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
            name: 'Experiment Variant',
            profilesReached: 3024,
            profilesEnteredVariant: 49320,
          },
          {
            experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
            name: 'Control group Variant',
            profilesReached: 2931,
            profilesEnteredVariant: 38034,
          },
        ],
      },
    },
  ],
  stats: {},
  totalProfilesReachedExit: 30,
  nodes: {
    nodeId: 'f-trigger-start',
    type: 'START_TRIGGER',
    label: 'User looking for information via onsite search',
    children: [
      {
        nodeId: 'f-step-experiment-1',
        type: 'STEP',
        label: 'Experiment',
        experimentVariant: {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e45',
          name: 'Variant 1',
          weight: 60,
        },
        stats: {
          profilesInStep: 28380,
        },
        children: [
          {
            nodeId: 'f-trigger-has-digital-solution-experiment-1',
            type: 'TRIGGER',
            label: 'Digital solution available for topic',
            children: [
              {
                nodeId: 'f-step-show-solution-experiment',
                type: 'STEP',
                label: 'Show digital solution page',
                children: [],
                stats: {
                  profilesInStep: 13444,
                },
              },
            ],
            stats: {
              profilesEntered: 78012,
            },
          },
          {
            nodeId: 'f-trigger-no-digital-solution-experiment-1',
            type: 'TRIGGER',
            label: 'Digital not solution available for topic',
            children: [
              {
                nodeId: 'f-step-show-link-experiment',
                type: 'STEP',
                label: 'Show link to call center information',
                children: [],
                stats: {
                  profilesInStep: 78012,
                },
              },
            ],
            stats: {
              profilesEntered: 184370,
            },
          },
        ],
      },
      {
        nodeId: 'f-step-control',
        type: 'STEP',
        label: 'Control group',
        experimentVariant: {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Variant 2',
          weight: 40,
        },
        stats: {
          profilesInStep: 182108,
        },
        children: [
          {
            nodeId: 'f-trigger-has-digital-solution-control',
            type: 'TRIGGER',
            label: 'Digital solution available for topic',
            children: [],
            stats: {
              profilesEntered: 45216,
            },
          },
          {
            nodeId: 'f-trigger-no-digital-solution-control',
            type: 'TRIGGER',
            label: 'Digital not solution available for topic',
            children: [],
            stats: {
              profilesEntered: 81120,
            },
          },
        ],
      },
    ],
  },
  profilesEnteredJourney: 541207,
  totalProfilesReachedGoal: 38210,
  profilesExited: {
    totalProfilesRemoved: 29210,
    totalProfilesExpired: 23443,
    totalProfilesMerged: 4559,
    totalProfilesExitedByCondition: 5921,
    variantStats: [
      {
        experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
        name: 'Experiment Variant',
        profilesRemoved: 103290,
        profilesExpired: 45320,
        profilesMerged: 555,
        profilesExitedByCondition: 4023,
      },
      {
        experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
        name: 'Control group Variant',
        profilesRemoved: 6654,
        profilesExpired: 374399,
        profilesMerged: 4630,
        profilesExitedByCondition: 1063,
      },
    ],
  },
  profilesActive: 78670,
} as any;
