import React, { useState } from 'react';
import i18n from '~/i18n';
import { Notification } from '~/components';
import DeletePopup from '~/components/src/DeletePopup';
import { showSuccess } from '~/notificationCenter';
import { BeaconObject } from './types';
import { deleteBeacon } from './dataService';

type DeleteBeaconProps = {
  beacon: BeaconObject;
  callback?: () => void;
  setIsPickerVisible: (val: boolean) => void;
};

function DeleteBeacon({ beacon, setIsPickerVisible, callback }: DeleteBeaconProps): React.ReactElement {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteBeacon(beacon.beaconId).then(() => {
      if (callback) {
        callback();
      }
      setIsPickerVisible(false);
      showSuccess({ header: i18n.t('beacons:notifications.beaconDeleteSuccess') });
    });
    setIsDeleting(false);
  };
  return (
    <DeletePopup
      isLoading={isDeleting}
      title={i18n.t('beacons:form.deleteHeader')}
      hidePopup={() => setIsPickerVisible(false)}
      onClick={() => handleDelete()}
    >
      <Notification>{i18n.t('beacons:form.deleteMessage', { beaconName: beacon.beaconName })}</Notification>
    </DeletePopup>
  );
}

export default DeleteBeacon;
