import React from 'react';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import { TimeFrameOptions } from '../constants';
import { TElement } from '../types';
import './styles.scss';

export const DateInput = ({ onChange, onBlur, errorText, filter, value, autoFocus }: TElement) => {
  const selectedOption = TimeFrameOptions.find(option => option.value === filter.timeFrame);

  return (
    <div className="DateFormat-Container">
      <FieldWrapper errorText={errorText}>
        <InputElement
          className="t-dateInput w-[30%]"
          value={value}
          onChange={e => onChange(e.target.value)}
          hasError={!!errorText}
          onBlur={onBlur}
          autoFocus={autoFocus}
          placeholder=""
        />
      </FieldWrapper>
      <SelectElement
        className="DateFormat-dateSelect"
        value={selectedOption}
        options={TimeFrameOptions}
        onChange={selectedOption => onChange(selectedOption.value, 'timeFrame')}
      />
    </div>
  );
};

export default DateInput;
