import i18n from '~/i18n';
import { MergedBeaconObject, CSVObject } from './types';

export const headers = {
  beaconNumber: i18n.t('beacons:csv.number'),
  name: i18n.t('beacons:csv.name'),
  numberOfCallsToday: i18n.t('beacons:csv.activityToday'),
  numberOfCallsSevenDays: i18n.t('beacons:csv.activityOneWeek'),
  numberOfCallsThirtyDays: i18n.t('beacons:csv.activityThirtyDays'),
};

export const formattedCSVData = (data: MergedBeaconObject[]): CSVObject[] =>
  data.map((obj: MergedBeaconObject) => ({
    beaconNumber: obj.beaconNumber,
    name: obj.name,
    numberOfCallsToday: obj.numberOfCallsToday,
    numberOfCallsSevenDays: obj.numberOfCallsSevenDays,
    numberOfCallsThirtyDays: obj.numberOfCallsThirtyDays,
  }));
