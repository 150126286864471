import React, { ReactElement, useContext } from 'react';
import cx from 'classnames';
import SortableTree from 'react-sortable-tree';
import Icons from '~/components/src/Icons';

import { expandDirectory, getLastItem, getNodeUrl } from '../utils';
import { CampaignTreeNode, CampaignsTreeProps, RowDataType } from '../types';
import { UrlBuilderContext } from '../UrlBuilderContext';
import './styles.scss';

const rowHeight = 32;
const maxScrollbarHeight = 17; // Taken from here: https://codepen.io/sambible/post/browser-scrollbar-widths
const lastRowHeight = rowHeight + maxScrollbarHeight;
const styleToHideHorizontalScrollbar = { marginBottom: `-${maxScrollbarHeight}px` };

export const CampaignsTree = ({ treeData, searchQuery, campaignId, theme }: CampaignsTreeProps): ReactElement => {
  const lastItem = getLastItem(treeData);
  const { setTreeData, navigate } = useContext(UrlBuilderContext);

  const handleNodeClick = (rowData: RowDataType) => {
    const { isLeafNode, path, node } = rowData;
    if (isLeafNode) {
      expandDirectory(path, treeData, setTreeData);
    } else {
      navigate(getNodeUrl(node.nodeId));
    }
  };

  return (
    <div
      className={cx('CampaignsTree -ml-1 box-border h-auto flex-1 px-4 py-2 duration-100 ease-in')}
      style={styleToHideHorizontalScrollbar}
    >
      <SortableTree
        canDrag={false}
        getNodeKey={({ node }: { node: any }) => node.id}
        generateNodeProps={(treeItem: any) => ({
          title: () => {
            const { node, path } = treeItem;
            const isLeafNode = path.length === 1;
            const isItemSelected = node.nodeId === campaignId;

            return (
              <p
                className={cx('flex cursor-pointer items-center gap-2 text-base', {
                  'font-medium text-blue-600': isItemSelected,

                  'text-gray-600 hover:text-blue-500': !isItemSelected,
                })}
                onClick={() => handleNodeClick({ node, path, isLeafNode })}
              >
                <Icons
                  // eslint-disable-next-line no-nested-ternary
                  icon={isLeafNode ? (node.expanded ? 'folderOpen' : 'folderFilled') : 'folder'}
                  className={cx('h-5 w-5')}
                />
                <span className="t-treeItem">{node.title}</span>
              </p>
            );
          },
        })}
        onChange={(updatedTreeData: CampaignTreeNode[]) => {
          if (!searchQuery) {
            setTreeData(updatedTreeData);
          }
        }}
        rowHeight={({ node }: { node: any }) => (node.id === lastItem?.id ? lastRowHeight : rowHeight)}
        theme={theme}
        treeData={treeData}
      />
    </div>
  );
};
