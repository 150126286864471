angular.module('webUi.service.tagmanagement.newPath', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.tree',
    'webUi.service.tagmanagement'
])

    .factory('NewPathService', ['Restangular','TreeService','TagmanagementService','$q','Utils', function(Restangular, TreeService, TagmanagementService, $q, Utils) {

        var BASE_PATH = 'tagmanagement/newPaths';

        var ALL_PATHS = TagmanagementService.GLOBAL_PATH;

        var TREE_STATES = [
            'site.tagmanagement.newPaths.list'
        ];

        var TREE_STATES_ID = [
            'location'
        ];

        return {

            /**
         * Makes a Restangular call to the server and retrieves the tree of new paths from Cassandra
         * @param {prefix} prefix the scope prefix ($scope.prefix) e.g (site-101)
         * @param {location} location the node from which we request the subtree, null means get the full tree of new paths
         * @param {boolean} addSref whether to add the href option to the nodes
         * @returns tagTreeData, which is usable by dynatree
         */
            getPathTreeCassandra : function(prefix, location){

                var pathTreeDeferred = $q.defer();

                Restangular.one(BASE_PATH).get({path: location}).then(function(result) {
                //if location is 'sv_root', attach the 'ALL_PATHS' node to the tree
                    if(location === null || location === ALL_PATHS) {
                        result[ALL_PATHS] = {};
                    }
                    result = Utils.sanitizeRestangularOne(result);
                    var pathTree = Utils.parseNodes(result, TREE_STATES, TREE_STATES_ID, function(node, obj, level) {
                        if(level === 0 && node.id === TagmanagementService.GLOBAL_PATH) {
                            node.addClass = 'node-global';
                            node.closeIcon = 'globe';
                        }
                    });

                    pathTreeDeferred.resolve(pathTree);
                });

                return pathTreeDeferred.promise;
            },

            /**
         * Get a list of full paths from cassandra, located at the specified location in the "tree"
         * @param location
         * @returns {*}
         */
            getNewPathList: function(location, timestamp) {

                var pathListDeferred = $q.defer();

                Restangular.one(BASE_PATH + '/list').get({
                    path: location,
                    timestamp: timestamp
                }).then(function(result) {
                    if(result) {
                        pathListDeferred.resolve(Utils.sanitizeRestangularOne(result));
                    }
                });

                return pathListDeferred.promise;
            },

            getNewPathCount: function(location) {

                return Restangular.one(BASE_PATH + '/listCount').get({
                    path: location
                });
            },

            /**
         * retrieves the base path through which all rest requests should go
         * @returns {String} the base path
         */
            getBasePath: function(){
                return BASE_PATH;
            }
        };
    }]);
