angular.module('webUi.ui.profiles.engagements.view',
    [
        'webUi.ui.profiles.engagements.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.engagements.view', {
            url: '/view/{engagementId}',
            isReact: true,
        });
    }]);
