import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { groupBy } from 'lodash';
import { SetIsPickerVisibleContext } from '~/components/src/RuleConnectorPicker/context';
import i18n from '~/i18n';
import BtnIcon from '../BtnIcon';
import Empty from '../Empty';
import Icons from '../Icons';
import SearchElement from '../Form/Elements/SearchElement';
import RulePickerCard from '../RulePickerCard';
import RulePickerItem from '../RulePickerItem';
import SelectableListItem from '../SelectableListItem';
import './style.scss';

const RuleCardGrid = props => {
  const {
    menuTree,
    isSearchable = false,
    searchPlaceholder = '',
    onSelect,
    emptyMessage = '',
    enableGroupBy = false,
  } = props;
  const [selectedGroupName, setSelectedGroupName] = useState(null);

  const menu = Object.entries(groupBy(menuTree, 'groupName'));
  const setIsPickerVisible = useContext(SetIsPickerVisibleContext);

  const [audienceRules, setAudienceRules] = useState(menuTree);
  const [filter, setFilter] = useState('');
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleClick = rule => {
    setFilter('');
    setSelectedGroupName(null);
    if (!rule) {
      setShowSubMenu(false);
      setAudienceRules(menuTree);
    } else if (rule.customSubMenu) {
      rule.customSubMenu.fetchData().then(data => {
        setShowSubMenu(true);
        setAudienceRules(data);
      });
    } else if (rule.subMenu) {
      setShowSubMenu(true);
      setAudienceRules(rule.subMenu);
    } else {
      const { ruleDefinitionId, type, title, availableFilters, extra, ruleDefinitionName, ruleName } = rule;

      const clickedRuleDescription = {
        ruleDefinitionId,
        type,
        title,
        availableFilters,
        ruleDefinitionName,
        ruleName,
        extra: extra || null,
      };

      onSelect(clickedRuleDescription);
      setIsPickerVisible(false);
    }
  };

  const filteredRules = audienceRules.filter(
    rule =>
      rule.title.toLowerCase().includes(filter.trim().toLowerCase()) &&
      (!selectedGroupName || rule.groupName === selectedGroupName),
  );

  const filteredSubitems = audienceRules
    .filter(({ subMenu }) => Array.isArray(subMenu) && subMenu.length > 0)
    .reduce((allSubitems, rule) => {
      const subitems = rule.subMenu.map(subitem => ({
        title: subitem.title,
        description: rule.title,
        icon: rule.icon,
        key: subitem.ruleDefinitionId,
        subitem,
      }));

      return [...allSubitems, ...subitems];
    }, [])
    .filter(({ title }) => title.toLowerCase().includes(filter.trim().toLowerCase()));

  const hasSearchResults = !!(filteredRules.length || filteredSubitems.length);
  const enableGroup = enableGroupBy && menu?.length > 1;

  return (
    <>
      {isSearchable && (
        <SearchElement
          className="RuleCard-search"
          placeholder={searchPlaceholder}
          onChange={e => {
            setSelectedGroupName(null);
            setFilter(e.target.value);
          }}
          value={filter}
          autoFocus
        />
      )}
      <>
        {showSubMenu ? (
          <>
            <BtnIcon
              testHook="RuleCard-goBackButton"
              icon="back"
              tooltip={i18n.t('audiences:backButton')}
              onClick={() => handleClick()}
            />
            <div className="RuleCard-list">
              {filteredRules.map(rule => (
                <RulePickerItem
                  key={rule.key || rule.ruleDefinitionId}
                  title={rule.title}
                  onClick={() => {
                    handleClick(rule);
                  }}
                />
              ))}
            </div>
          </>
        ) : (
          hasSearchResults && (
            <div className="RuleCard-initialViewSearchResults">
              <div className="flex">
                {enableGroup && filter.length === 0 && (
                  <div className="w-1/5 overflow-y-auto pr-3">
                    {!!selectedGroupName && (
                      <div
                        onClick={() => {
                          setSelectedGroupName(null);
                        }}
                        className="mb-4 flex cursor-pointer items-center justify-between rounded border bg-white px-4 py-2 text-lg text-gray-700 hover:border-gray-800 hover:text-gray-600 hover:drop-shadow"
                      >
                        <span>{i18n.t('audiences:clearFilter')}</span>
                        <Icons icon="close" className="mr-1 h-4 w-4" />
                      </div>
                    )}
                    {menu.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleClick(); // setShowSubMenu(false);
                          setSelectedGroupName(item[0]);
                        }}
                        className={cx(
                          'mb-4 flex cursor-pointer items-center justify-between rounded border bg-white px-4 py-2 text-lg text-gray-700 hover:border-gray-800 hover:text-gray-600  hover:drop-shadow',
                          { 'border-gray-800': selectedGroupName === item[0] },
                        )}
                      >
                        <span>{item[0]}</span>
                        <span className="ms-2 inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-200 text-xs font-semibold text-gray-700">
                          {item[1].length}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                <div className={cx({ 'w-4/5': enableGroup })}>
                  <div className="RuleCard-grid">
                    {filteredRules.map(rule => (
                      <RulePickerCard
                        icon={rule.icon}
                        title={rule.title}
                        subtitle={rule.subtitle}
                        isDisabled={rule.isDisabled}
                        key={rule.ruleDefinitionId || rule.key}
                        testHook={rule.type || rule.key}
                        onClick={() => {
                          handleClick(rule);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {filter && (
                <div>
                  {filteredSubitems.map(({ title, description, icon, subitem, key }) => (
                    <SelectableListItem
                      title={title}
                      description={description}
                      icon={icon}
                      key={key}
                      onClick={() => {
                        handleClick(subitem);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </>
      {!hasSearchResults && <Empty excludePanel body={emptyMessage} />}
    </>
  );
};

export default RuleCardGrid;
