import { useEffect, useState } from 'react';
import { useAPI } from '~/common';
import { getAllRuleVariables } from '~/common/modules/rules/dataService';

export const useAllVariables = () => {
  const [allVariables, setAllVariables] = useState(null);
  const { data, isLoading, error } = useAPI(() => getAllRuleVariables());

  useEffect(() => {
    if (data !== null) {
      setAllVariables(data);
    }
  }, [data]);

  const refetchAllVariables = async () => {
    const refetchedAllVariables = await getAllRuleVariables();
    setAllVariables(refetchedAllVariables);
  };

  return {
    allVariables,
    isLoading: isLoading || allVariables === null,
    error,
    refetchAllVariables,
  };
};
