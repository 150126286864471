import React from 'react';
import cx from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl, useAPI } from '~/common';
import { Heading } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Link from '~/components/src/Link';
import Spin from '~/components/src/Spin';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { fetchDependencies } from '~/workflows/dataService';
import { TDependenciesProps, TDependency } from '~/workflows/types';
import StatusIndicator from './StatusIndicator';

const columnHelper = createColumnHelper<TDependency>();

const baseUrl = 'workflows';

const getWorkflowUrl = (type: string, id: string) =>
  buildUrl(`${baseUrl}/${type === WORKFLOW_TYPE.Audience ? 'view' : 'edit'}/${id}`);

const getTriggerUrl = (type: string, id: string, triggerId: string) => {
  const url =
    type === WORKFLOW_TYPE.Audience
      ? `${baseUrl}/view/${id}?nodeId=${triggerId}`
      : `${baseUrl}/edit/${id}/triggerEdit/${triggerId}`;
  return buildUrl(url);
};

const Dependencies = ({ selectedDependency, onClose }: TDependenciesProps) => {
  const { journeyId, workflowType } = selectedDependency;
  const { data: dependencies, isLoading } = useAPI(() => fetchDependencies(journeyId), [journeyId]);

  const getColumns = () => [
    columnHelper.accessor('journeyName', {
      header: () => <div className="text-left">{i18n.t('workflow:dependencies.table.header.workflow')}</div>,
      cell: ({ getValue, row }) => (
        <div className="text-left">
          <Link
            href={getWorkflowUrl(row.original.workflowType.name, row?.original.journeyId)}
            onClick={onClose}
            testHook="journeyLink"
          >
            {getValue()}
          </Link>
        </div>
      ),
      size: 200,
    }),
    columnHelper.accessor('workflowType', {
      header: () => <div className="text-center">{i18n.t('workflow:dependencies.table.header.type')}</div>,
      cell: ({ row }) => <div className="text-center">{row?.original?.workflowType?.label || '-'}</div>,
      size: 100,
    }),
    columnHelper.accessor('triggerName', {
      header: () => <div className="text-left">{i18n.t('workflow:dependencies.table.header.trigger')}</div>,
      cell: ({ getValue, row }) => (
        <Link
          href={getTriggerUrl(row.original.workflowType.name, row?.original.journeyId, row?.original.triggerId)}
          onClick={onClose}
          testHook="triggerLink"
        >
          {getValue()}
        </Link>
      ),
      size: 150,
    }),
  ];

  return (
    <div className="fixed right-0 top-0 z-10 h-full w-1/3 bg-white pt-12 drop-shadow-md">
      <div className={cx('relative flex h-full flex-col gap-2')}>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <Heading kind="h4" title={i18n.t('workflow:dependencies.title')} className="border-b border-gray-100 p-4">
              <BtnIcon icon="close" onClick={onClose} testHook="closePanel" />
            </Heading>
            <div className="flex justify-between border-b p-4">
              <StatusIndicator workflow={selectedDependency} testHook="dependencyHeader" />,
              <span>{workflowType?.label || '-'}</span>
            </div>
            <div className="mb-28 ">
              <UITable testHook="dependenciesTable" data={dependencies ?? []} columns={getColumns()} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dependencies;
