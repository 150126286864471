import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { buildUrl, useAPI } from '~/common';
import { Heading, Notification, Page } from '~/components';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';

import { getJourneyDataAndStats } from './util';
import AnalysisTable from './components/AnalysisTable';
import OverallAnalysis from './components/OverallAnalysis';

const JourneyStatistics = () => {
  const params = useParams();
  const navigate = useNavigate();
  const journeyId = params.workflowId || '';
  const goToJourneyView = () => navigate(buildUrl(`workflows/edit/${journeyId}`), { replace: true });
  const { data: stats, isLoading, error } = useAPI(() => getJourneyDataAndStats(journeyId));

  const title = !isLoading && stats ? stats.journeyName : i18n.t('workflow:journey.journeyStatistics.header');

  return (
    <Page>
      <Heading
        testHook="journeyStatsHeader"
        title={title}
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => {
              goToJourneyView();
            },
          },
        ]}
      />
      {isLoading && <Spin />}
      {!isLoading && error && <Notification kind="error" header={error.message} />}
      {!isLoading && stats && (
        <div className="flex flex-col gap-10 pb-8">
          <OverallAnalysis totalStats={stats.totalStats} />
          {stats.stepAnalysis && (
            <AnalysisTable
              analytics={stats.stepAnalysis}
              hasVariants={stats.experimentAnalysis.length > 0}
              header={i18n.t('workflow:journey.journeyStatistics.stepAnalysis.header')}
              totalStats={stats.totalStats}
            />
          )}
          {stats.experimentAnalysis.length > 0 && (
            <AnalysisTable
              isExperimentAnalysis
              analytics={stats.experimentAnalysis}
              header={i18n.t('workflow:journey.journeyStatistics.experimentAnalysis.header')}
              totalStats={stats.totalStats}
            />
          )}
        </div>
      )}
    </Page>
  );
};

export default JourneyStatistics;
