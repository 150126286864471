import React from 'react';
import Icon from 'react-icon-base';

const R42ArrowRight = ({ className, ...props }) => (
  <div className={className}>
    <Icon viewBox="0 0 24 24" color={props.color} {...props}>
      <g>
        <path d="M19.5,12a2.3,2.3,0,0,1-.78,1.729L7.568,23.54a1.847,1.847,0,0,1-2.439-2.773l9.752-8.579a.25.25,0,0,0,0-.376L5.129,3.233A1.847,1.847,0,0,1,7.568.46l11.148,9.808A2.31,2.31,0,0,1,19.5,12Z" />
      </g>
    </Icon>
  </div>
);

export default R42ArrowRight;
