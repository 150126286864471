import React, { useState } from 'react';
import { useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { getPublishingHistory, getReleaseHistory } from '../dataService';
import { HistoryProps } from '../types';
import Deployments from './Deployments';
import Releases from './Releases';

const History = ({ environments, hasTagEdit, hasTagPublish, refetchEnvironments }: HistoryProps) => {
  const [index, setIndex] = useState(0);
  const {
    state: releases,
    isLoading: isReleasesLoading,
    refetch: refetchPublising,
  } = useApiWithState(() => getPublishingHistory());
  const {
    state: deployments,
    isLoading: isDeploymentsLoading,
    refetch: refetchReleases,
  } = useApiWithState(() => getReleaseHistory());

  if (isReleasesLoading || isDeploymentsLoading || !releases || !deployments) return <Spin />;

  const refetch = () => {
    refetchReleases();
    refetchPublising();
    refetchEnvironments();
  };

  return (
    <Tabs selectedIndex={index} onSelect={index => setIndex(index)}>
      <TabList>
        <Tab testHook="releases-tab">{i18n.t('publishing:history.tabs.releases')}</Tab>
        <Tab testHook="deployment-tab">{i18n.t('publishing:history.tabs.deployments')}</Tab>
      </TabList>
      <TabPanel>
        <Releases
          hasTagEdit={hasTagEdit}
          hasTagPublish={hasTagPublish}
          releases={releases}
          environments={environments}
          refetch={refetch}
        />
      </TabPanel>
      <TabPanel>
        <Deployments deployments={deployments} environments={environments} />
      </TabPanel>
    </Tabs>
  );
};

export default History;
