angular.module('webUi.ui.content.campaigns.ads.creatives.form.datafeedkeychooser', ['webUi.service'])

    .controller('DataFeedKeyChooserController', ['$scope', '$uibModalInstance', 'dialogsModel', 'DataFeedService',
        function DataFeedKeyChooserController($scope, $uibModalInstance, dialogsModel, DataFeedService) {

            var initializeEntries = function initializeEntries(previousState){
                if (previousState && _.size(previousState.keys) > 0) {
                    var requestedEntries = [];
                    _.forEach(previousState.keys, function (key) {
                        requestedEntries.push({
                            key: key
                        });
                    });
                    return requestedEntries;
                } else {
                    return [{
                        key: ''
                    }];
                }
            };

            var ad = dialogsModel.ad;

            $scope.ad = ad;

            $scope.selection = {
                requestedEntries : initializeEntries(dialogsModel.previousState)
            };

            $scope.addEntry = function(keyToAdd, requestedEntries) {
                requestedEntries.push({key: keyToAdd || ''});
            };

            $scope.removeEntry = function(entryIdx) {
                $scope.selection.requestedEntries.splice(entryIdx, 1);
            };

            $scope.clearState = function(state) {
                state.active = false;
            };

            DataFeedService.getDataFeed(ad.dataFeed.dataFeedId).then(function(result) {
                $scope.feed = result;
            });

            $scope.allowMultiProducts = ad.profileVariable && ad.profileVariable.variableType.name === 'LIST'
            || !_.isEmpty(ad.placementParameter);

            $scope.submit = function(requestedEntries) {

                var keys = _.filter(_.pluck(requestedEntries, 'key'));
                if(!keys || _.isEmpty(keys)) {
                    return;
                }

                $uibModalInstance.close({
                    keys: _.filter(_.pluck(requestedEntries, 'key')),
                    allowMulti: $scope.allowMultiProducts
                });
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
