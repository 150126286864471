import React, { ReactNode, useState } from 'react';
import cx from 'classnames';
import { Field, FormSection, FieldArray } from 'redux-form';
import { twMerge } from 'tailwind-merge';
import { ReduxFormSelectField, ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, isUrlWithProtocol } from '~/common';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { ConfigurationTabsProps, DIYFormProps } from './types';
import ConfigurationBuilder from './ConfigurationBuilder';
import AuthorizationBuilder from './AuthorizationBuilder';
import { APIMethodTypes } from './constants';

const ConfiguationTabs = ({ touch, values, change, canUpdate }: ConfigurationTabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const jsonPlaceholder = `{
    "type": "service_account",
    "project_id": "...
    ...`;

  return (
    <Tabs
      selectedIndex={selectedTabIndex}
      onSelect={setSelectedTabIndex}
      className="mb-8 border-b border-gray-300 pb-8"
    >
      <TabList>
        <Tab>{i18n.t('partners:form.formFields.authorization')}</Tab>
        <Tab>{i18n.t('partners:form.formFields.headers')}</Tab>
        <Tab>{i18n.t('partners:form.formFields.properties')}</Tab>
        <Tab>{i18n.t('partners:form.formFields.payload')}</Tab>
      </TabList>
      <TabPanel
        forceRender={true}
        tabId={0}
        testHook="tab-0"
        className={twMerge(cx('invisible h-0', { 'visible h-auto': selectedTabIndex === 0 }))}
      >
        <AuthorizationBuilder touch={touch} values={values} change={change} canUpdate={canUpdate} />
      </TabPanel>
      <TabPanel
        forceRender={true}
        tabId={1}
        testHook="tab-1"
        className={twMerge(cx('invisible h-0', { 'visible h-auto': selectedTabIndex === 1 }))}
      >
        <FieldArray
          name="headers"
          component={ConfigurationBuilder}
          canUpdate={canUpdate}
          values={values.headers}
          arrayTitle="headers"
        />
      </TabPanel>
      <TabPanel
        forceRender={true}
        tabId={2}
        testHook="tab-2"
        className={twMerge(cx('invisible h-0', { 'visible h-auto': selectedTabIndex === 2 }))}
      >
        <FieldArray
          name="properties"
          component={ConfigurationBuilder}
          canUpdate={canUpdate}
          values={values.properties}
          arrayTitle="properties"
        />
      </TabPanel>
      <TabPanel
        forceRender={true}
        tabId={3}
        testHook="tab-3"
        className={twMerge(cx('invisible h-0', { 'visible h-auto': selectedTabIndex === 3 }))}
      >
        <Field
          label={i18n.t('partners:form.formFields.payload')}
          name="payload"
          component={ReduxFormTextAreaField}
          minRows={6}
          placeholder={jsonPlaceholder}
        />
      </TabPanel>
    </Tabs>
  );
};

const DIYForm = ({ t, canUpdate, values, partner, touch, change }: DIYFormProps): ReactNode => (
  <FormSection name={constants.partnerTypes.DIY}>
    <FormHeader t={t} partner={partner}>
      <Field
        label={t('form.formFields.method')}
        name="method"
        component={ReduxFormSelectField}
        options={APIMethodTypes}
        validate={isRequired}
        touch={touch}
      />
      <Field
        label={t('form.formFields.url')}
        name="url"
        component={ReduxFormTextAreaField}
        minRows={6}
        placeholder={t('form.formFields.inputPlaceHolder')}
        validate={[isRequired, isUrlWithProtocol]}
        type="text"
      />
    </FormHeader>
    <ConfiguationTabs touch={touch} values={values} change={change} canUpdate={canUpdate} />
  </FormSection>
);

export default DIYForm;
