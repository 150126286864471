import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/remote/export-requests`;

const getRequestTypes = () => Api.callGet(`${BASE_PATH}/automationTypes`);

const getExportTypes = () => Api.callGet(`${BASE_PATH}/export-types`);

const getEngagementTypes = () => Api.callGet(`${API_BASE}/profiles/engagements`);

const createExportRequest = (url, payload) => Api.callPost(url, payload);

const testRemoteConnection = payload => Api.callPost(`${API_BASE}/remoteConnectionTest/check`, payload);

const getEditExportData = id => Api.callGet(`${BASE_PATH}/scheduled/${id}`);

export {
  BASE_PATH,
  getRequestTypes,
  getExportTypes,
  getEngagementTypes,
  createExportRequest,
  testRemoteConnection,
  getEditExportData,
};
