import React from 'react';
import Icon from 'react-icon-base';

const R42GoogleDisabled = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g id="btn_google_dark_disabled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fill-opacity="0.4">
      <path
        d="M12.1744898,13.71 L12.1744898,10.362 L20.7704082,10.362 C20.8989796,10.929 21,11.46 21,12.207 C21,17.346 17.4826531,21 12.1836735,21 C7.11428571,21 3,16.968 3,12 C3,7.032 7.11428571,3 12.1836735,3 C14.6632653,3 16.7387755,3.891 18.327551,5.349 L15.7193878,7.833 C15.0581633,7.221 13.9010204,6.501 12.1836735,6.501 C9.14387755,6.501 6.66428571,8.976 6.66428571,12.009 C6.66428571,15.042 9.14387755,17.517 12.1836735,17.517 C15.7010204,17.517 16.9959184,15.132 17.2346939,13.719 L12.1744898,13.719 L12.1744898,13.71 Z"
        id="Shape-Copy"
        fill="#000000"
      ></path>
    </g>
  </Icon>
);

export default R42GoogleDisabled;
