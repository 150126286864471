angular.module('webUi.directive.button',
    [
        'app-templates'
    ])
    .directive('button', function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'directive/button/button.tpl.html',
            scope: { buttonText: '@?', buttonColor: '@?', icon: '@?', buttonClass: '@?', buttonDisabled: '@?', buttonSize: '@?', buttonType: '@?', disabled: '@?'},
            compile: function () {
                return {
                    pre: function (scope, el, attrs) {
                        scope.$watchGroup(['buttonText', 'buttonColor', 'icon', 'buttonDisabled'], function() {
                            scope.isDisabled = scope.$eval(attrs.buttonDisabled);
                            scope.buttonType = attrs.buttonType || DEFAULTS.buttonType;
                            scope.iconPlacement = attrs.iconPlacement || DEFAULTS.iconPlacement;
                            scope.buttonSize = attrs.buttonSize || DEFAULTS.buttonSize;
                            scope.buttonColor = attrs.buttonColor || DEFAULTS.buttonColor;
                            scope.buttonClass = DEFAULTS.buttonClass +
                                COLORS[scope.buttonColor] +
                                SIZE[scope.buttonSize] +
                                (scope.isDisabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[scope.buttonColor]}`);
                        });
                    },
                };
            }
        };
    });

var DEFAULTS = {
    buttonType: 'button',
    buttonColor: 'blue',
    buttonClass: 'rounded px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring-4',
    buttonSize: 'bs',
};

var COLORS = {
    gray: 'text-gray-600 dark:text-gray-400  bg-gray-200 dark:bg-gray-700',
    blue: 'text-white bg-blue-600 dark:bg-blue-700',
    red: 'text-white bg-red-600 dark:bg-red-700',
};

const COLOR_STATES = {
    gray: `${COLORS.gray} hover:bg-gray-300 hover:text-gray-600 dark:hover:bg-gray-600 focus:ring-gray-300  dark:focus:ring-gray-500`,
    blue: `${COLORS.blue} hover:bg-blue-700 hover:text-white dark:hover:bg-blue-600 focus:ring-blue-300  dark:focus:ring-blue-500`,
    red: `${COLORS.red} hover:bg-red-700 hover:text-white dark:hover:bg-red-600 focus:ring-red-300  dark:focus:ring-red-500`,
};

var SIZE = {
    xs: 'px-3 py-2 text-xs',
    sm: 'px-3 py-2 text-sm',
    bs: 'px-5 py-2.5 text-sm',
    lg: 'px-5 py-3 text-base',
    xl: 'px-6 py-3.5 text-base',
};