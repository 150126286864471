import React from 'react';
import * as Sentry from '@sentry/react';
import version from './assetsVersion';

Sentry.init({
  // eslint-disable-next-line no-undef
  dsn: window.SENTRY_API_KEY || '',
  release: version,
  autoSessionTracking: false,
});

export const FallbackComponent = () => <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>;
