/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_USERS = `${API_BASE}/context/users`;

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ContextUsersMatchClass {
  getNotMatchedUsersForClient(clientId) {
    return Api.callGet(`${API_USERS}/${clientId}/nomatch`).then(response => response.data);
  }

  updateNotMatchedUsersForClient(clientId) {
    return Api.callPost(`${API_USERS}/${clientId}/bindClient`, { clientId });
  }
}

const contextUsersMatchService = new ContextUsersMatchClass();
export default contextUsersMatchService;
