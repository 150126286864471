import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { getSiteId } from '~/common/SecurityMetaService';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { saveUser, getUsersForCurrentSite, fetchSiteUsers, isFetchingUsers } from '~/admin/users';
import { fetchSiteInfoIfNeeded, getSiteInfo, isFetchingInfo } from '~/admin/site';
import { fetchPermissionsMapIfNeeded, selectors as permissionsSelectors } from '~/permissions';
import UserAddForm from '~/admin/components/UserAddForm';

export class UsersAdd extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldWait: true };
  }

  componentDidMount() {
    this.props.fetchSiteInfo();
    this.props.fetchSiteUsers();
    this.props.fetchPermissionsMap();
    this.setState({
      shouldWait: false,
    });
  }

  render() {
    const { props } = this;
    const { t, shouldFetchPermissions, isFetchingPermissions, isFetchingInfo, isFetchingUsers } = props;
    return this.state.shouldWait ||
      shouldFetchPermissions ||
      isFetchingPermissions ||
      isFetchingInfo ||
      isFetchingUsers ? (
      <Spinner />
    ) : (
      <Page>
        <MediumWrapper>
          <Heading title={t('addNew.header')} />
          <Panel>
            <UserAddForm {...props} />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      site: getSiteInfo(state),
      backHref: buildUrl('admin/users'),
      users: getUsersForCurrentSite(state),
      initialValues: {
        email: null,
        permissions: [],
      },
      isFetchingInfo: isFetchingInfo(state),
      isFetchingUsers: isFetchingUsers(state),
      isFetchingPermissions: permissionsSelectors.isFetchingPermissions(state),
      shouldFetchPermissions: permissionsSelectors.shouldFetchPermissions(state),
    }),
    dispatch => ({
      fetchPermissionsMap: () => dispatch(fetchPermissionsMapIfNeeded()),
      fetchSiteUsers: () => dispatch(fetchSiteUsers(getSiteId())),
      fetchSiteInfo: () => dispatch(fetchSiteInfoIfNeeded(getSiteId())),
      saveUser: (site, user) => dispatch(saveUser(site, user)),
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      onSubmit: user => dispatchProps.saveUser(stateProps.site, user),
    }),
  ),
  reduxForm({
    form: 'addSiteUser',
    enableReinitialize: true,
  }),
  translate(['adminUsers']),
)(UsersAdd);
