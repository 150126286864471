import React from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import { Link } from '~/components';

interface CardHeaderProps {
  title?: string;
  subtitle?: string;
  tooltipText: string;
  onDelete?: () => void;
  icon: React.ReactNode;
  children?: React.ReactNode;
  isReadOnly: boolean;
  url?: string;
  className?: string;
}

const CardHeader = ({
  title,
  subtitle,
  tooltipText,
  onDelete,
  icon,
  isReadOnly,
  url,
  children,
  className,
}: CardHeaderProps): React.ReactElement => (
  <div>
    <div className={cx('flex items-center p-2', className)}>
      <div className="flex w-full items-center gap-2 p-2">
        {icon}
        <div>
          {title && (
            <div className="flex items-center gap-1">
              <p className="text-lg font-medium text-gray-800">{url ? <Link href={url}>{title}</Link> : title}</p>
              <Icons icon="info" className="h-6 w-5 p-0.5 text-gray-400" tooltip={tooltipText} />
            </div>
          )}
          {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
        </div>
      </div>
      {!isReadOnly && (
        <BtnIcon
          icon="delete"
          onClick={onDelete}
          tooltip={i18n.t('common:actions.delete')}
          testHook="deleteEventRule"
        />
      )}
    </div>
    {children}
  </div>
);

export default CardHeader;
