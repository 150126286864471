angular.module('webUi.directive.linkButton',
    [
        'app-templates'
    ])
    .directive('linkButton', function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'directive/linkButton/linkButton.tpl.html',
            scope: { buttonText: '@?', buttonColor: '@?', icon: '@?', buttonClass: '@?', buttonSize: '@?', buttonLink: '@?'},
            compile: function () {
                return {
                    pre: function (scope, el, attrs) {
                        scope.iconPlacement = attrs.iconPlacement || DEFAULTS.iconPlacement;
                        scope.buttonLink = attrs.buttonLink || DEFAULTS.buttonLink;
                        scope.buttonSize = attrs.buttonSize || DEFAULTS.buttonSize;
                        scope.buttonColor = attrs.buttonColor || DEFAULTS.buttonColor;
                        scope.buttonClass = DEFAULTS.buttonClass +
                            COLORS[scope.buttonColor] +
                            SIZE[scope.buttonSize] +
                            (attrs.disabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[scope.buttonColor]}`);
                    },
                };
            }
        };
    });

var DEFAULTS = {
    buttonLink: '#',
    buttonColor: 'blue',
    buttonClass: 'rounded px-5 py-3 text-sm font-medium focus:outline-none focus:ring-4',
    buttonSize: 'bs',
};

var COLORS = {
    gray: 'text-gray-600 dark:text-gray-400 bg-gray-200 dark:bg-gray-700',
    blue: 'text-white bg-blue-600 dark:bg-blue-700',
};

var COLOR_STATES = {
    gray: `${COLORS.gray} hover:text-white hover:bg-gray-300 dark:hover:bg-gray-600 focus:ring-gray-300 dark:focus:ring-gray-500`,
    blue: `${COLORS.blue} hover:text-white hover:bg-blue-700 dark:hover:bg-blue-600 focus:ring-blue-300 dark:focus:ring-blue-500`,
};

var SIZE = {
    xs: 'px-3 py-2 text-xs',
    sm: 'px-3 py-2 text-sm',
    bs: 'px-5 py-2.5 text-sm',
    lg: 'px-5 py-3 text-base',
    xl: 'px-6 py-3.5 text-base',
};