angular
    .module('webUi.ui.profiles.thirdPartyDataCollectors.view', [
        'webUi.ui.profiles.thirdPartyDataCollectors.base',
        'webUi.service',
        'ui.bootstrap',
    ])
    /**
     *
     */
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.profiles.thirdPartyDataCollectors.view', {
                url: '/view/{collectorId}',
                isReact: true,
                leftMenu: false,
            });
        },
    ]);
