/* eslint-disable newline-per-chained-call */
import { changeUrl } from '~/common';
import moment from 'moment';
import i18n from '~/i18n';
import { showError } from '~/notificationCenter';
import { API_BASE } from '~/common/ApiService';
import newExportActionTypes from './newExportActionTypes';
import { getScheduledExportsData } from '../exportSelector';
import {
  BASE_PATH,
  getRequestTypes,
  getExportTypes,
  getEngagementTypes,
  createExportRequest,
  testRemoteConnection,
  getEditExportData,
} from './exportService';
import {
  getFormValues,
  getEngagementTypes as getEngagementTypesSelector,
  getExportId,
  isRemoteConnectionSuccessful,
} from './newExportSelector';
import constants from './constants';
import newExportInitialState from './newExportInitialState';

const setIsRemoteConnectionSuccessful =
  (data = false) =>
  dispatch => {
    dispatch({
      type: newExportActionTypes.SET_IS_REMOTE_CONNECTION_SUCCESSFUL,
      isRemoteConnectionSuccessful: data,
    });
  };

const setAreConnectionCredentialsChecked = areConnectionCredentialsChecked => dispatch => {
  dispatch({
    type: newExportActionTypes.SET_ARE_CONNECTION_CREDENTIALS_CHECKED,
    areConnectionCredentialsChecked,
  });
};

const setEditExportData =
  (editExportData = newExportInitialState.editExportData, exportId = newExportInitialState.exportId) =>
  dispatch => {
    dispatch({
      type: newExportActionTypes.SET_EDIT_EXPORT_DATA,
      editExportData,
      exportId,
    });
  };

const fetchRequestTypes = () => dispatch => {
  dispatch({ type: newExportActionTypes.FETCH_REQUEST_TYPES_START });

  return getRequestTypes()
    .then(payload =>
      dispatch({
        type: newExportActionTypes.FETCH_REQUEST_TYPES_SUCCESS,
        data: payload.data.map(data => ({ label: data.label, value: data.name })),
      }),
    )
    .catch(() => {
      dispatch({ type: newExportActionTypes.FETCH_REQUEST_TYPES_FAIL });
    });
};

const fetchExportTypes = () => dispatch => {
  dispatch({ type: newExportActionTypes.FETCH_EXPORT_TYPES_START });

  return getExportTypes()
    .then(payload =>
      dispatch({
        type: newExportActionTypes.FETCH_EXPORT_TYPES_SUCCESS,
        data: payload.data.map(data => ({ label: data.label, value: data.name })),
      }),
    )
    .catch(() => {
      dispatch({ type: newExportActionTypes.FETCH_EXPORT_TYPES_FAIL });
    });
};

const fetchEngagementTypes = () => dispatch => {
  dispatch({ type: newExportActionTypes.FETCH_ENGAGEMENT_TYPES_START });

  return getEngagementTypes()
    .then(payload =>
      dispatch({
        type: newExportActionTypes.FETCH_ENGAGEMENT_TYPES_SUCCESS,
        data: payload.data.map(data => ({ ...data, optionLabel: data.type, optionId: data.engagementId })),
      }),
    )
    .catch(() => {
      dispatch({ type: newExportActionTypes.FETCH_ENGAGEMENT_TYPES_FAIL });
    });
};

const getExportConfiguration = (formValues, engagementTypes) => {
  switch (formValues.type.exportType) {
    case constants.EXPORT_TYPES.SESSIONS:
      // eslint-disable-next-line no-case-declarations
      const parameters = formValues.settings ? formValues.settings.urlParameters.split('/,/') : [];
      return parameters.map(param => ({
        exportType: formValues.type.exportType,
        source: constants.LANDING_URL_PARAMS,
        param,
        columnName: param,
      }));
    case constants.EXPORT_TYPES.ENGAGEMENTS:
      return [].concat({
        exportType: formValues.type.exportType,
        engagements: formValues.settings
          ? engagementTypes
              .filter(option => formValues.settings.engagementTypes.indexOf(option.type) !== -1)
              .map(option => option.optionLabel)
          : [],
      });
    case constants.EXPORT_TYPES.TRACKING_EVENTS_STREAM:
      return [].concat({
        exportType: formValues.type.exportType,
        query: formValues.settings ? formValues.settings.query : '',
      });
    default:
      return [];
  }
};

const buildConnectionConfig = formValues => {
  const payload = {
    connectionType: formValues?.connection?.connectionType,
    directory: formValues?.connection?.directory,
    host: formValues?.connection?.host,
    loginType: formValues?.connection?.loginType,
    port: parseInt(formValues?.connection?.port, 10),
    validateConnection: null,
  };

  return formValues?.connection?.loginType === constants.LOGIN_TYPES.NORMAL
    ? {
        ...payload,
        username: formValues?.connection?.username,
        password: formValues?.connection?.password,
      }
    : payload;
};

const check = () => (dispatch, getState) => {
  const formValues = getFormValues(getState());
  const payload = buildConnectionConfig(formValues);

  return testRemoteConnection(payload)
    .then(response => {
      dispatch(setIsRemoteConnectionSuccessful(response.data.success));
    })
    .catch(() => {
      dispatch(setIsRemoteConnectionSuccessful(false));
    })
    .finally(() => {
      dispatch(setAreConnectionCredentialsChecked(true));
    });
};

const createNewRequest = () => async (dispatch, getState) => {
  const scheduledExportsData = getScheduledExportsData(getState());
  const formValues = getFormValues(getState());
  const engagementTypes = getEngagementTypesSelector(getState());
  const exportId = getExportId(getState());

  const { requestType } = formValues.type;

  if (
    requestType === constants.REQUEST_TYPES.SCHEDULED &&
    scheduledExportsData.length >= constants.SCHEDULE_EXPORTS_LIMIT
  ) {
    showError({
      toastId: constants.SCHEDULE_EXPORTS_LIMIT_TOAST_ID,
      header: i18n.t('exports:error'),
      body: i18n.t('exports:scheduledExportLimitError'),
    });
    return Promise.resolve();
  }

  const requiresConnectionCheckBeforeSaving =
    requestType === constants.REQUEST_TYPES.SCHEDULED || requestType === constants.REQUEST_TYPES.MANUAL_REMOTE;

  if (requiresConnectionCheckBeforeSaving) {
    await dispatch(check());

    const isConnectionWorking = isRemoteConnectionSuccessful(getState());

    if (!isConnectionWorking) {
      return Promise.resolve();
    }
  }

  dispatch({ type: newExportActionTypes.CREATE_NEW_REQUEST_START });

  const emails = formValues.notify.email.split(',').filter(item => item.trim() !== '');
  let payload = {
    end: moment(formValues.range && formValues.range.endDate)
      .endOf('day')
      .toDate()
      .getTime(),
    exportReportConfiguration: getExportConfiguration(formValues, engagementTypes),
    exportType: formValues.type.exportType,
    requestType,
    notifyEmails: emails.map(item => item.trim()),
    start: moment(formValues.range && formValues.range.startDate)
      .startOf('day')
      .toDate()
      .getTime(),
    connectionConfig: requestType === constants.REQUEST_TYPES.MANUAL ? {} : buildConnectionConfig(formValues),
  };

  let url = BASE_PATH;

  if (requestType === constants.REQUEST_TYPES.MANUAL_REMOTE) {
    url = `${API_BASE}/remote/export-requests`;
  } else if (requestType === constants.REQUEST_TYPES.SCHEDULED) {
    url = exportId
      ? `${API_BASE}/remote/export-requests/scheduled/edit`
      : `${API_BASE}/remote/export-requests/scheduled`;
    const { start, end, ...modified } = payload;
    payload = {
      ...modified,
      range: {
        _from: moment().subtract(7, 'days').startOf('day').toDate().getTime(),
        _to: moment().endOf('day').toDate().getTime(),
      },
      isDisabledRequestType: false,
      createNotAllowed: false,
      frequency: {
        name: formValues.frequency.frequency,
      },
      validateConnection: true,
    };
    if (exportId) {
      payload = { ...payload, scheduledExportId: exportId };
    }
  }

  return createExportRequest(url, payload)
    .then(() => {
      dispatch({ type: newExportActionTypes.CREATE_NEW_REQUEST_SUCCESS });
      const tabName = requestType === constants.REQUEST_TYPES.SCHEDULED ? 0 : 1;
      changeUrl(`profiles/export?tab=${tabName}`);
    })
    .catch(() => {
      dispatch({ type: newExportActionTypes.CREATE_NEW_REQUEST_FAIL });
    });
};

const fetchEditExportData = id => dispatch => {
  dispatch({ type: newExportActionTypes.FETCH_EDIT_EXPORT_START });
  return getEditExportData(id)
    .then(response => {
      dispatch(setEditExportData(response.data, id));
      dispatch({ type: newExportActionTypes.FETCH_EDIT_EXPORT_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: newExportActionTypes.FETCH_EDIT_EXPORT_FAIL });
    });
};

export {
  fetchRequestTypes,
  fetchExportTypes,
  fetchEngagementTypes,
  createNewRequest,
  check,
  fetchEditExportData,
  setEditExportData,
  setIsRemoteConnectionSuccessful,
  setAreConnectionCredentialsChecked,
};
