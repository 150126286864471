import { connect } from 'react-redux';
import {
  fetchPayloadTypes,
  fetchConsentTypes,
  redirectToAuthorizationUrl,
  fetchPartnerInformation,
} from './googleAdsActions';
import {
  fetchingPayloadTypes,
  fetchingConsentTypes,
  getPayloadTypes,
  getConsentTypes,
  fetchingPartnerInformation,
  getPartnerInformation,
} from './googleAdsSelector';
import GoogleAdsForm from './GoogleAdsForm';

const mapStateToProps = state => ({
  fetchingPayloadTypes: fetchingPayloadTypes(state),
  fetchingConsentTypes: fetchingConsentTypes(state),
  payloadTypes: getPayloadTypes(state),
  consentTypes: getConsentTypes(state),
  fetchingPartnerInformation: fetchingPartnerInformation(state),
  partnerInformation: getPartnerInformation(state),
});

const mapDispatchToProps = {
  fetchPayloadTypes,
  fetchConsentTypes,
  redirectToAuthorizationUrl,
  fetchPartnerInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAdsForm);
