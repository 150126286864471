angular.module('webUi.ui.tagmanagement.tags.view.placeholderbuttons', [
    'webUi.ui.tagmanagement.tags.view.placeholderbuttons.placeholderdialog'
])

    .controller('PlaceholderButtonsController', ['$scope', 'TagmanagementService', 'CodeMirrorService', '$uibModal',
        function PlaceholderButtonsController($scope, TagmanagementService, CodemirrorService, $uibModal){

            // get the codemirror instance
            var codemirrorInstance = $('#tagCodeForm .codemirror-tag-container').data('codemirrorInstance');
            // expose the available placeholder types
            $scope.availablePlaceholderTypes = TagmanagementService.getAvailablePlaceholderTypes();

            var modal;
            $scope.placeholderDialog = function(placeholder){
                // check to close, because if it is opened from another placeholder button, should close the existing one first
                if ( !_.isUndefined(modal) ){
                    modal.close(false);
                }
                modal = $uibModal.open( {
                    templateUrl: 'ui/tagmanagement/tags/view/placeholderbuttons/placeholderdialog/placeholderdialog.tpl.html',
                    resolve: {
                        dialogsModel : function() {
                            return {
                                placeholder : placeholder
                            };
                        }
                    },
                    // the controller used for the dialog
                    controller: 'PlaceholderDialogController'
                });
                modal.result.then(function(result){
                    if ( result ){
                        CodemirrorService.insertPlaceholder(codemirrorInstance, result);
                    }
                });
            };
        }]);
