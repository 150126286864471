/**
 * Created by Alexandra Vargas on 11/14/16.
 */

angular.module('webUi.filter.lower', [])
/**
* Filter to replace spaces for dahses and lower case text
**/
    .filter('lower', function() {
        return function(str) {
            str = str.replace(/\s+/g, '-');
            return str.toLowerCase();
        };
    });
