import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import { buildUrl } from '~/common';
import * as fromModals from '~/modals';
import { fetchVariantIfNeeded, lockVariant, deleteVariant } from '~/support/tagtemplates/variants/actions';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import * as selectors from '~/support/tagtemplates/variants/selectors';
import Link from '~/components/src/Link';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import TagVariantDetail from '~/support/components/TagVariantDetail';
import TagVariantHistoryModal from '~/support/tagtemplates/containers/VariantHistoryModal';
import { Panel } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';

export class TagTemplateVariant extends Component {
  componentDidMount() {
    const { fetchVariant, id, sequenceNr } = this.props;
    fetchVariant(id, sequenceNr);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.props.fetchVariant(nextProps.id, nextProps.sequenceNr);
    }
  }

  render() {
    const { props } = this;
    const { variant, t, isLocked, onLockClick, onHistoryClick, onDeleteClick, editHref } = props;
    const isDeleted = variant && variant.deleted;
    const isDisabled = isLocked || isDeleted;

    const oldVariantPart = !props.sequenceNr ? null : (
      <Notification kind="warning" header={t('common:notifications:warning')}>
        <Trans i18nKey="variant.alerts.historicVersion">
          You're viewing an historic version of this tag. <Link href={props.latestVersionHref}>Click here</Link> to see
          the current version.
        </Trans>
      </Notification>
    );

    return !variant ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={t('variant.header')}>
          <BtnIcon
            tooltip={t('variant.actions.remove')}
            disabled={isDisabled}
            onClick={() => onDeleteClick(variant, t)}
            icon="delete"
          />
          <LinkIcon icon="edit" tooltip={t('variant.actions.edit')} disabled={isDisabled} url={editHref}></LinkIcon>
          <BtnIcon
            disabled={isDeleted}
            onClick={() => onLockClick(variant)}
            tooltip={isLocked ? t('variant.actions.unlock') : t('variant.actions.lock')}
            icon={isLocked ? 'lock' : 'lockOpen'}
          />
          <BtnIcon icon="history" onClick={() => onHistoryClick(variant, t)} tooltip={t('variant.actions.history')} />
        </Heading>
        <Panel>
          {oldVariantPart}
          <TagVariantDetail variant={variant} t={t} />
        </Panel>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps.params.variantId;
      const { sequenceNr } = ownProps.params;
      const variant = selectors.getVariantVersionById(state, id, sequenceNr);
      const editHref = sequenceNr
        ? buildUrl(`support/tagtemplates/variants/edit/${id}?sequenceNr=${sequenceNr}`)
        : buildUrl(`support/tagtemplates/variants/edit/${id}`);
      return {
        id,
        sequenceNr,
        variant,
        editHref,
        isLocked: selectors.isVariantLocked(state, id, sequenceNr),
        latestVersionHref: buildUrl(`support/tagtemplates/variants/view/${id}`),
      };
    },
    dispatch => ({
      fetchVariant: (id, sequenceNr) => dispatch(fetchVariantIfNeeded(id, sequenceNr)),
      onLockClick: variant => dispatch(lockVariant(variant)),
      onHistoryClick: (variant, t) => {
        dispatch(
          fromModals.showModal(fromModals.CONTENT_MODAL, {
            title: t('variantHistory.header'),
            cancelText: t('variantHistory.cancelText'),
            content: <TagVariantHistoryModal variant={variant} t={t} />,
          }),
        );
      },
      onDeleteClick: (variant, t) => {
        dispatch(
          fromModals.showModal(fromModals.DELETE_MODAL, {
            title: t('variant.deleteModal.title'),
            message: t('variant.deleteModal.message', { name: variant.name }),
            onConfirm: () => dispatch(deleteVariant(variant)),
          }),
        );
      },
    }),
  ),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagTemplateVariant);
