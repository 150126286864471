import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    mediaMathId: Yup.string().required(t('cards.validation.mediaMathId')),
  });

export const Mediamath = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="mediaMathId"
          label={t('cards.label.mediamathId')}
          placeholder={t('common.audienceCard.placeholder')}
          errorText={formProps.touched.mediaMathId && formProps.errors.mediaMathId}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
