import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { useAPI } from '~/common/ApiHooks';
import { Field } from 'formik';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import CardMessage from '~/components/src/CardMessage';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { getClangCampaignsList } from './service';

const validations = t =>
  Yup.object().shape({
    clangCampaign: Yup.object().required(t('connectors:cards.validation.clangCampaign')),
  });

const Clang = ({ onEdit, connector, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const {
    data: listItems,
    error,
    isLoading: isListLoading,
  } = useAPI(() => getClangCampaignsList(partnerId), [partnerId]);

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <Fragment>
          <SmallWrapperNoOffset>
            <Field
              testHook="formikDropdown--clangCampaign"
              name="clangCampaign"
              as={FormikSelectField}
              label={t('connectors.clang.fieldLabel')}
              errorText={formProps?.touched?.clangCampaign && formProps?.errors?.clangCampaign?.name}
              options={listItems || []}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.campaignId}
              value={formProps.values.clangCampaign}
              isLoading={isListLoading}
            />
          </SmallWrapperNoOffset>
          {error && <CardMessage message={t('connectors.clang.campaign.genericError')} />}
        </Fragment>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null), translate('audiences'))(Clang);
