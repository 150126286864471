angular.module('webUi.ui.support.messages.form', ['webUi.ui.support.messages.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.messages.add', {
                url : '/add',
                isReact: true
            })
            .state('site.support.messages.edit', {
                url : '/edit/{messageId}',
                isReact: true
            });
    }]);
