angular.module('webUi.ui.profiles.filters.view',
    [
        'webUi.ui.profiles.filters.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.filters.view', {
            url: '/view/{filterId}',
            isReact: true,
        });
    }]);