/* eslint-disable no-console */
window.jQuery = window.$ = require('jquery/jquery.js');
require('angular/angular.js');

// React
require('react');
require('react-dom');
require('prop-types');
require('redux');
require('react-redux');
require('redux-thunk');
require('react-modal');
require('react-router-dom');

// jQuery plugins
require('jquery-ui/ui/jquery-ui.js');
require('datatables/media/js/jquery.dataTables.js');
require('jquery.dynatree/src/jquery.dynatree.js');
require('jquery.scrollto/jquery.scrollTo.js');
// swfobject

// Generic
window.moment = require('moment');
require('moment-timezone');
window.TraceKit = require('tracekit/tracekit');
window.jstz = require('jstimezonedetect');
window.Flow = require('@flowjs/ng-flow/dist/ng-flow-standalone.js');
require('ng-flow');
require('ng-flow-provider');
require('ng-flow-btn');
require('ng-flow-init');
require('ng-flow-events');
require('ng-flow-drop');
require('ng-flow-transfers');
require('ng-flow-img');
require('ng-flow-dragevents');
require('fusty-flow');
window.d3 = require('d3');

// Angular
require('angular-resource');
require('angular-sanitize');
require('restangular');
require('angular-animate');
require('angular-loading-bar');
require('iso-3166-country-codes-angular');
require('angular-local-storage');
require('angular-uuid4');
require('angular-encode-uri');

// CodeMirror
window.CodeMirror = require('codemirror/lib/codemirror.js');
window.JSHINT = require('jshint').JSHINT;
require('../downloaded/lint.js');
require('codemirror/addon/selection/mark-selection.js');
require('codemirror/addon/lint/javascript-lint.js');
require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/javascript-hint.js');
require('codemirror/addon/mode/overlay.js');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
require('codemirror/mode/css/css.js');
require('codemirror/mode/htmlmixed/htmlmixed.js');

// Angular UI
require('angular-ui-utils-event');
require('angular-ui-utils-highlight');
require('angular-ui-utils-keypress');
require('angular-ui-utils-validate');
require('ui-scrollfix');
require('angular-ui-router');
require('angular-ui-codemirror');
require('../downloaded/angular-ui-sortable/sortable.js');
require('angular-ui-router-uib-modal');
require('angularjs-toaster');


// Bootstrap Main
require('angular-ui-bootstrap/dist/ui-bootstrap-tpls.js');

// Custom bootstrap components
require('angular-colorpicker');

// Offline.js
require('../downloaded/offline/offline.min.js');

// Hopscotch
window.hopscotch = require('hopscotch');

// Ad-block test
window.ad_block_test = require('../downloaded/abt');

// Regex parser
require('regjsparser');

// ca-9.js
require('../downloaded/ca-9.js');

// lodash
_ = require('../downloaded/lodash-3.10.1.min.js');