/**
 *
 */
angular.module('webUi.ui.tagmanagement.tags.view.advancedSettings',
    [
        'webUi.ui.tagmanagement.base',
        'ui.bootstrap'
    ]
)
/**
 *
 */
    .controller('TagViewAdvancedSettingsController', [
        //$s
        '$scope',
        '$uibModalInstance',
        //services
        'TagmanagementService',
        'PubSubService',
        //vars
        'dialogsModel',
        '$q',
        /**
         *
         * @param $scope
         * @param TagmanagementService
         * @param PubSubService
         * @constructor
         */
        function TagViewAdvancedSettingsController($scope,
            $uibModalInstance,
            TagmanagementService,
            PubSubService,
            dialogsModel,
            $q) {

            $scope.tagViewPojo = dialogsModel.tagViewPojo;
            $scope.hasTagEdit = dialogsModel.hasTagEdit;
            $scope.tagExecutionTypes = dialogsModel.tagExecutionTypes;
            $scope.handleModification = dialogsModel.handleModification;
            $scope.validSize = true;
            $scope.compressionOverrideOptions = {
                editSuccessCallback: function (newValue) {
                    var res = $q.defer();
                    TagmanagementService.checkTagContentSize($scope.tagViewPojo.tag.content.code, !$scope.tagViewPojo.tag.compression).then(function (result) {
                        if (result.tagSizeAccepted) {
                            TagmanagementService.updateCompression($scope.tagViewPojo.tag.tagId, newValue).then(function (modification) {
                                $scope.validSize = true;
                                //@todo marius: Doesn't bubble?
                                $scope.handleModification(modification);
                                res.resolve(true);
                            });
                        } else {
                            $scope.tagViewPojo.tag.compression = true;
                            $scope.validSize = false;
                            res.resolve(false);
                        }
                    });
                    return res.promise;
                }
            };

            $scope.selectOptions = {
                editSuccessCallback: function (newValue) {
                    return TagmanagementService.updateTagExecutionType($scope.tagViewPojo.tag.tagId, newValue).then(function (modification) {
                        //@todo marius: Doesn't bubble?
                        $scope.handleModification(modification);
                    });
                }
            };

            $scope.close = function () {
                $uibModalInstance.close(false);
            };

            $scope.shouldShowExecutionType = function (tag) {

                var conditions = tag.conditions;

                if (_.isUndefined(conditions) || _.isNull(conditions) || _.isEmpty(conditions)) {
                    return true;
                }
                for (var i = 0; i < conditions.length; i++) {
                    var condition = conditions[i];
                    if (condition.clazz === 'EventCondition') {
                        return false;
                    }
                }
                return true;
            };

            $scope.isOkSize = function isOkSize() {
                return TagmanagementService.checkTagContentSize($scope.tagViewPojo.tag.content.code, $scope.tagViewPojo.tag.compression);
            };

        }
    ]);
