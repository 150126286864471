import { isFunction, isArray } from 'lodash';
import { getFormValues, getFormInitialValues, isPristine, isInvalid } from 'redux-form';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import * as fromModals from '~/modals';
import * as types from './types';
import * as selectors from './selectors';
import ContextUsersService from './dataService.js';

/**
 * Fetch Permissions Map
 */
export const fetchPermissionsMap = () => dispatch => {
  dispatch({
    type: types.PERMISSIONS_FETCH_START,
  });

  return ContextUsersService.getSitePermissionsMap()
    .then(payload =>
      dispatch({
        type: types.PERMISSIONS_FETCH_SUCCESS,
        payload,
      }),
    )
    .catch(error => dispatch({ type: types.PERMISSIONS_FETCH_FAIL, error }));
};

/**
 * Fetch permissions only if not fetched yet
 */
export const fetchPermissionsMapIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchPermissions(getState())) {
    return dispatch(fetchPermissionsMap());
  }
  return Promise.resolve();
};

/**
 * Remove all permissions to given site for a given user
 * @param userId
 * @param siteId
 */
export const removeUserAccessToSite = (userId, siteId) => dispatch => {
  const model = {
    userId,
    siteId,
  };

  dispatch({
    type: types.USER_SITE_REMOVE_START,
  });

  return ContextUsersService.removeUserAccessToSite(model)
    .then(() => {
      showSuccess({ body: i18n.t('adminUsers:api.deletionSuccess') });
      dispatch({
        type: types.USER_SITE_REMOVE_SUCCESS,
        payload: model,
      });
    })
    .catch(error => dispatch({ type: types.USER_SITE_REMOVE_FAIL, error }));
};

/**
 * Update permissions for a given site for a given user
 * @param userId
 * @param siteId
 * @param updateInfo
 * @param callback
 */
export const updateUserAccessToSite =
  ({ userId, siteId, updateInfo, callback }) =>
  (dispatch, getState) => {
    const state = getState();
    const isFormPristine = isPristine(types.USER_EDIT_PERMISSIONS_KEY)(state);
    const isFormInvalid = isInvalid(types.USER_EDIT_PERMISSIONS_KEY)(state);
    const initialValues = getFormInitialValues(types.USER_EDIT_PERMISSIONS_KEY)(state);
    const values = getFormValues(types.USER_EDIT_PERMISSIONS_KEY)(state);
    const { permissions, phoneNumber, restrictedSubnets } = values;

    const infoModel = {
      siteId,
      userId,
      phoneNumber,
      restrictedSubnets,
      isPhoneEdited: initialValues.phoneNumber !== phoneNumber,
      isRestrictedSubnetsEdited: initialValues.restrictedSubnets !== restrictedSubnets,
    };
    const permissionsModel = {
      siteId,
      userId,
      permissions,
    };

    if (isFormInvalid) {
      return Promise.reject();
    }

    if (isFormPristine) {
      dispatch(fromModals.hideModal());
      return Promise.resolve();
    }

    if (updateInfo && isFunction(updateInfo)) {
      dispatch(updateInfo(infoModel));
    }

    if (!isArray(permissions)) {
      return Promise.resolve();
    }

    dispatch({
      type: types.PERMISSIONS_UPDATE_START,
    });

    return ContextUsersService.editUserAccessToSite(permissionsModel)
      .then(() => {
        dispatch({
          type: types.PERMISSIONS_UPDATE_SUCCESS,
          payload: permissionsModel,
        });
        dispatch(fromModals.hideModal());
        // call callback
        if (isFunction(callback)) {
          return dispatch(callback());
        }
        return undefined;
      })
      .catch(error => dispatch({ type: types.PERMISSIONS_UPDATE_FAIL, error }));
  };
