import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import i18n from '~/context/i18n';
import { getAngularService } from 'ReactAngular/react.service';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import { Panel } from '~/components/src/Containers';
import { fetchUserSites, getUserSites, isFetchingUserSites } from '~/context/users';
import {
  EditUserPermissionsToSiteContainer,
  fetchPermissionsMapIfNeeded,
  getUiPermissions,
  getApiPermissions,
  removeUserAccessToSite,
  updateUserAccessToSite,
} from '~/permissions';
import UserSitesList from '~/context/components/UserSitesList';
import { SearchContainer, buildUrl } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';
import { USER_EVENTS } from '~/gaActions';

const searchFilterFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.siteNumber.toString().indexOf(query.toLowerCase()) !== -1;

const USER_SITES_KEY = 'CONTEXT/USERS/USER_SITES';
const USER_SITES_FILTER_KEY = 'CONTEXT/USERS/USER_SITES_SEARCH';

class UserListContainer extends Component {
  componentDidMount() {
    this.props.fetchAccessibleSites();
    this.props.fetchPermissionsMap();
  }

  render() {
    const { props } = this;
    const { t, isContextAdmin, isFetching } = props;
    const { permissionsUi, permissionsApi, user, linkToSite, sites, siteUrl, stateKey, onEditSite, onDeleteSite } =
      props;
    const ContextService = getAngularService(document, 'ContextService');
    const onExportClick = () => {
      ContextService.exportSites(sites);
    };

    const commonProps = {
      list: sites,
      t,
      siteUrl,
      stateKey,
      isTable: true,
    };

    const adminActions = !isContextAdmin
      ? {}
      : {
          onEditClick: site => onEditSite(t, user, site),
          onDeleteClick: site => onDeleteSite(user, site),
        };

    const TabsPart = (
      <Tabs className="u-marginTopXl">
        <TabList>
          <Tab>{t('view.sites.tabs.webui')}</Tab>
          <Tab>{t('view.sites.tabs.api')}</Tab>
        </TabList>

        {/* WebUI Roles */}
        <TabPanel>
          <Panel>
            <UserSitesList permissions={permissionsUi} {...commonProps} {...adminActions} />
            <Spinner isActive={isFetching} />
          </Panel>
        </TabPanel>

        {/* API Roles */}
        <TabPanel>
          <Panel>
            <UserSitesList permissions={permissionsApi} {...commonProps} {...adminActions} />
            <Spinner isActive={isFetching} />
          </Panel>
        </TabPanel>
      </Tabs>
    );

    return (
      <div>
        <Heading title={t('view.sites.header')}>
          <SearchContainer stateKey={USER_SITES_KEY} searchKey={USER_SITES_FILTER_KEY} searchFn={searchFilterFn} />

          <BtnIcon icon="download" onClick={() => onExportClick()} tooltip={t('view.sites.actions.exportCSV')} />
          {isContextAdmin && (
            <LinkIcon icon="link" className="h-8 w-8" url={linkToSite} tooltip={t('view.sites.actions.linkToSite')} />
          )}
        </Heading>
        {isFetching && !sites.length ? <Spinner /> : TabsPart}
      </div>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const id = ownProps.userId;
  return {
    isContextAdmin: ownProps.isContextAdmin,
    stateKey: USER_SITES_KEY,
    linkToSite: buildUrl(`context/users/linkusersite/${id}`),
    sites: getUserSites(state, id),
    siteUrl: item => buildUrl(`context/sites/view/${item.siteId}`),
    isFetching: isFetchingUserSites(state),
    permissionsUi: getUiPermissions(state),
    permissionsApi: getApiPermissions(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.userId;
  return {
    fetchAccessibleSites: () => dispatch(fetchUserSites(id)),
    fetchPermissionsMap: () => dispatch(fetchPermissionsMapIfNeeded()),
    onEditSite: (t, user, site) =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('users:editPermissions.header'),
          confirmText: t('users:editPermissions.confirmText'),
          onConfirm: () =>
            dispatch(
              updateUserAccessToSite({
                userId: user.userId,
                siteId: site.siteId,
                callback: () => fetchUserSites(id),
              }),
            ),
          gaAction: USER_EVENTS.PERMISSION.MODIFIED,
          content: (
            <EditUserPermissionsToSiteContainer
              canEditInfo={false}
              user={user}
              initialValues={user}
              site={site}
              availablePermissions={site.siteModules?.map(item => (item.name ? item.name : item))}
              permissions={site.permissions}
              t={t}
            />
          ),
        }),
      ),
    onDeleteSite: (user, site) => {
      const userName = (user && user.name) || '';
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('users:view.sites.deleteModal.title'),
          message: i18n.t('users:view.sites.deleteModal.message', { userName, siteName: site.name }),
          onConfirm: () => dispatch(removeUserAccessToSite(id, site.siteId)),
        }),
      );
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('users'))(UserListContainer);
