import React from 'react';
import { useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { showSuccess } from '~/notificationCenter/actions';
import * as fromModals from '~/modals';
import R42Add from '~/components/src/R42Icons/R42Add';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

import CreateNewVariableForm from './CreateNewVariableForm';
import './styles.scss';

const onStoredVariableChange = (setFieldValue, propertyIndex) => (selectedOption, triggeredAction) => {
  const isClearButtonClicked = triggeredAction.action === 'clear';

  if (isClearButtonClicked) {
    setFieldValue(`filters[${propertyIndex}].profileVarName`, null);
    setFieldValue(`filters[${propertyIndex}].profileVarId`, null);
    return;
  }
  setFieldValue(`filters[${propertyIndex}].profileVarName`, selectedOption.label);
  setFieldValue(`filters[${propertyIndex}].profileVarId`, selectedOption.value);
};

const openCreateNewVariableModal = (options, setVariableSelectValue, fetchAllVariables, t) => dispatch => {
  const hideModal = () => dispatch(fromModals.hideModal());

  const updateVariables = async newlyCreatedVariable => {
    await fetchAllVariables(true);

    setVariableSelectValue(newlyCreatedVariable.variableId, newlyCreatedVariable.name);
    showSuccess({
      body: t('variables:api.creationSuccess', { variableName: newlyCreatedVariable.name }),
    });
    hideModal();
  };

  const existingVariableNames = options.map(({ label }) => label);

  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: t('variables:list.addNewVariable'),
      className: 'AudienceEdit-createNewVariableModal',
      content: (
        <CreateNewVariableForm
          existingVariableNames={existingVariableNames}
          updateVariables={updateVariables}
          hideModal={hideModal}
          t={t}
        />
      ),
    }),
  );
};

const CreatableOption = ({ t }) => (
  <div className="StoredVariablesInput-creatableOption">
    <R42Add />
    {t('variables:list.addNew')}
  </div>
);

const StoredVariablesInput = ({
  availableVariables,
  setFieldValue,
  propertyIndex,
  selectedPropertyName,
  fetchAllVariables,
  openCreateNewVariableModal,
  t,
  isCreatable = true,
}) => {
  if (!selectedPropertyName) {
    return null;
  }

  const context = useFormikContext();
  const value = context.values.filters[propertyIndex].profileVarId;
  const options = availableVariables.map(variable => ({
    label: variable.name,
    value: variable.variableId,
    disabled: variable.disabled,
  }));

  const setVariableSelectValue = (variableId, variableName) => {
    setFieldValue(`filters[${propertyIndex}].profileVarId`, variableId);
    setFieldValue(`filters[${propertyIndex}].profileVarName`, variableName);
  };

  const selectedOption = options.find(option => option.value === value);

  return (
    <div className="StoreVariables-Container">
      <SelectElement
        isCreatable={isCreatable}
        value={selectedOption}
        options={[...options]}
        disabled={!options.length}
        isClearable={true}
        isSearchable={true}
        onChange={onStoredVariableChange(setFieldValue, propertyIndex)}
        isOptionDisabled={option => option.disabled}
        className="StoredVariablesInput-Select"
        onCreateOption={() => {
          openCreateNewVariableModal(options, setVariableSelectValue, fetchAllVariables, t);
        }}
        isValidNewOption={userInput => !userInput}
        createOptionPosition="first"
        formatCreateLabel={() => <CreatableOption t={t} />}
        blurInputOnSelect /* This is needed to fix select being stuck after canceling the variable modal */
        testHook="variablesSelect"
      />
    </div>
  );
};

export default compose(
  connect(null, {
    openCreateNewVariableModal,
  }),
  translate(),
)(StoredVariablesInput);
