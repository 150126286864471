import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { buildUrl, useAPI, useApiWithState } from '~/common';
import { Heading, Notification, Page, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { fetchCumulativeStats, fetchExperimentById } from './dataService';
import DeleteExperiment from './DeleteExperiment';
import { Traffic } from './components/Traffic';
import { CumulativeStats } from './components/CumulativeStats';
import { mapExperimentCumulativeStats } from './util';
import ExperimentStats from './components/ExperimentStats';
import { LinkedTags } from './components/LinkedTags';
import EditExperimentName from './EditExperimentName';

const ExperimentView = ({ hasTagEdit }: { siteNumber?: number; hasTagEdit: boolean }) => {
  const params = useParams();
  const navigate = useNavigate();
  const experimentId = params.experimentId || '';

  const {
    state: experimentDetails,
    isLoading: isExperimentLoading,
    error: isExperimentError,
    refetch: refetchExperimentDetails,
  } = useApiWithState(() => fetchExperimentById(experimentId));

  const { data: cumulativeStats, isLoading: isCumulativeStatsLoading } = useAPI(() =>
    fetchCumulativeStats(experimentId),
  );

  const [isDeletePickerVisible, setIsDeletePickerVisible] = useState(false);
  const [showEditExptNameModal, setShowEditExptNameModal] = useState(false);

  const goToExperimentsList = () => navigate(buildUrl('tagmanagement/experiments/dashboard'), { replace: true });

  if (isExperimentLoading || isCumulativeStatsLoading || !experimentDetails || !cumulativeStats) {
    return <Spin />;
  }

  const mappedCumulativeStats = mapExperimentCumulativeStats(experimentDetails.experimentGroups, cumulativeStats);

  if (isExperimentError) {
    return (
      <Notification kind="error">
        {typeof isExperimentError === 'string' ? isExperimentError : JSON.stringify(isExperimentError)}
      </Notification>
    );
  }

  return (
    <Page>
      <Heading
        title={experimentDetails.name}
        badge={experimentDetails.experimentNumber}
        crumbs={[
          {
            title: i18n.t('experiments:back'),
            onClick: () => goToExperimentsList(),
          },
        ]}
      >
        <BtnIcon
          icon="edit"
          onClick={() => setShowEditExptNameModal(true)}
          testHook="editExperiment"
          disabled={!hasTagEdit}
          tooltip={hasTagEdit ? i18n.t('experiments:actions.editName') : i18n.t('experiments:tooltips.noPermission')}
        />
        <BtnIcon
          icon="delete"
          onClick={() => setIsDeletePickerVisible(true)}
          testHook="deleteExperiment"
          disabled={!hasTagEdit}
          tooltip={hasTagEdit ? i18n.t('experiments:actions.delete') : i18n.t('experiments:tooltips.noPermission')}
        />
      </Heading>
      <h3 className="!mt-2 pb-2 text-xl text-gray-600">{i18n.t('experiments:view.traffic')}</h3>
      <Traffic
        hasTagEdit={hasTagEdit}
        experimentGroups={experimentDetails.experimentGroups}
        experimentId={experimentId}
        refetchExperimentDetails={refetchExperimentDetails}
      />
      <ExperimentStats experimentId={experimentId} />

      <h3 className="!mt-10 pb-2 text-xl text-gray-600">{i18n.t('experiments:view.cumulativeStats')}</h3>
      <CumulativeStats cumulativeStats={mappedCumulativeStats} />

      <h3 className="!mt-10 pb-2 text-xl text-gray-600">{i18n.t('experiments:view.linkedTags')}</h3>
      <LinkedTags linkedTags={experimentDetails.linkedTags} />

      {showEditExptNameModal && (
        <PickerView
          className="!h-auto !w-1/3"
          pickerTitle={i18n.t('experiments:form.editLabel')}
          handlePickerVisibility={() => setShowEditExptNameModal(false)}
        >
          <EditExperimentName
            refetch={refetchExperimentDetails}
            experiment={experimentDetails}
            closeModal={() => setShowEditExptNameModal(false)}
          />
        </PickerView>
      )}
      {isDeletePickerVisible && (
        <DeleteExperiment
          experimentId={experimentDetails.experimentId}
          experimentName={experimentDetails.name}
          setIsPickerVisible={setIsDeletePickerVisible}
          callback={goToExperimentsList}
        />
      )}
    </Page>
  );
};

export default ExperimentView;
