import moment from 'moment';
import { random } from 'lodash';
import fakeVersionTree from './fakeVersionTree';
import {
  TriggerDailyStats,
  GoalStats,
  StepDailyStats,
  StepDailyStatsOrigin,
  TotalStepStats,
} from '../form/Journey/types';
import { JourneyContents } from '../types';
import { StatsResponse } from '../form/Statistics/types';

export const demoJourneyId = 'demoJourney';

const FakeVersionTrees: Record<string, any> = fakeVersionTree;

export const demoJourneyListData = {
  activeProfiles: 0,
  goalsReached: 0,
  historicProcessing: { state: { name: 'SUCCESSFUL', label: 'Successful' }, triggeredOn: 1695904136416 },
  isUsedInJourneys: false,
  journeyId: demoJourneyId,
  labels: [],
  lastModified: 1695993887415,
  name: 'Demo Journey',
  profilesEntered: 0,
  profilesExited: 0,
  state: { name: 'PUBLISHED', label: 'Published' },
  workflowType: {
    name: 'JOURNEY',
    label: 'Journey',
  },
};

export const demoJourneydetails = {
  journeyId: '68676371-3d8c-405f-b37a-fc0a1ba1d967',
  name: 'Demo Journey',
  expiryTime: '90d',
  state: {
    name: 'PUBLISHED',
    label: 'Published',
  },
  description: '',
  lastModified: 1695898164074,
  lastModifiedBy: '2fe8db4a-086f-4b10-a959-81d01f92fc18',
  historicProcessing: {
    eligible: false,
  },
  workflowType: {
    name: 'JOURNEY',
    label: 'Journey',
  },
} as any;

const makeId = (...params: string[]) => `fake:${params.join('_')}`;

export const getIsFakeMode = () => localStorage.getItem('useJoDemoMode') === 'true';

export const makeFake = (fn: any) => (data: any) => {
  const isFakeMode = getIsFakeMode();

  if (isFakeMode) {
    return fn(data);
  }
  return data;
};

export const getFakeStepStats = (journeyId: string, stepId: string, origin: StepDailyStatsOrigin): StepDailyStats[] => {
  const id = makeId(journeyId, stepId, origin);

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data: StepDailyStats[] = [];

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      for (let i = 90; i > 0; i -= 1) {
        const timestamp = moment().subtract(i, 'days').valueOf();
        data.push({
          key: {
            dayTimestamp: timestamp,
            journeyId,
            origin,
            nodeId: stepId,
            timestamp,
          },
          profilesAdded: random(0, 30000),
          profilesMovedToNextStep: random(0, 15000),
          profilesReachedGoal: random(0, 10000),
          profilesReachedExit: random(0, 1000),
          profilesRemoved: random(0, 1000),
          profilesMerged: 0,
          profilesExitedByCondition: 0,
        });
      }
      localStorage.setItem(id, JSON.stringify(data));
    }

    return data;
  } catch (error) {
    return [];
  }
};

export const getFakeConnectorStats = (partnerId: string, sourceId: string, _startDate: number, _endDate: number) => {
  const id = makeId(partnerId, sourceId);
  const makeResponse = (stats: []) => ({
    data: {
      stats,
    },
  });

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = [];

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      for (let i = 90; i > 0; i -= 1) {
        const timestamp = moment().subtract(i, 'days').valueOf();
        data.push({
          day: timestamp,
          statusCounts: [
            { status: 'ADD', count: random(0, 30000) },
            { status: 'REMOVE', count: random(0, 30000) },
          ],
        });
      }
      localStorage.setItem(id, JSON.stringify(data));
    }

    return makeResponse(data);
  } catch (error) {
    return makeResponse([]);
  }
};

export const getFakeTriggerData = (
  triggerId: string,
  _startDate: number,
  _endDate: number,
  originalStatsData: TriggerDailyStats,
): TriggerDailyStats => {
  const id = makeId(`triggerDailyStats_${triggerId}`);
  const makeResponse = (stats: []) => ({
    nodeId: originalStatsData.nodeId,
    profilesEnteredJourney: random(0, 30000),
    stats,
  });

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = [];
    const { stats } = originalStatsData;

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      data = stats.map(stat => ({
        ...stat,
        profilesReachedGoal: random(0, 30000),
        variantStats: stat.variantStats.map(vs => ({
          ...vs,
          profilesEnteredVariant: random(0, 30000),
          profilesReached: random(0, 30000),
        })),
      }));
    }

    localStorage.setItem(id, JSON.stringify(data));
    return makeResponse(data);
  } catch (error) {
    return originalStatsData;
  }
};

export const getFakeGoalStats = (goalId: string, originalGoalStats: GoalStats): GoalStats => {
  const id = makeId(`goalStats_${goalId}`);

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = originalGoalStats;

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      data = {
        ...originalGoalStats,
        goalAnalysis: originalGoalStats.goalAnalysis.map(ga => ({
          ...ga,
          profilesEntered: random(40000, 50000),
          profilesReachedGoal: random(0, 40000),
        })),
        variantAnalysis: originalGoalStats.variantAnalysis.map(va => ({
          ...va,
          profilesEntered: random(40000, 50000),
          profilesReachedGoal: random(0, 40000),
        })),
      };

      data.totalProfilesReachedGoal = data.goalAnalysis.reduce(
        (acc: any, val: any) => acc + val.profilesReachedGoal,
        0,
      );
      localStorage.setItem(id, JSON.stringify(data));
    }

    return data;
  } catch (error) {
    return originalGoalStats;
  }
};

export const getFakeTrees = (journeyId: string, versionId: string): JourneyContents => FakeVersionTrees[versionId];

export const getFakeVersions = (journeyId: string): any[] => [
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e3a',
    journeyId,
    name: 'Retargeting users',
    description: 'Retargeting by transitioning users with constraints',
    lastModifiedBy: 'demo-user@relay2.com',
    lastModified: 1686731854207,
    isLatest: true,
  },
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e2a',
    journeyId,
    name: 'Cluster profile scenarios',
    description: 'October enhanced campaign with experiments',
    lastModifiedBy: 'user-marketing@relay2.com',
    lastModified: 1686297468756,
    isLatest: false,
  },
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e1a',
    journeyId,
    name: 'October user campaign',
    description: "Assign product interest to user profiles and target via banners & AD's",
    lastModifiedBy: 'demo-user@relay2.com',
    lastModified: 1683293584196,
    isLatest: false,
  },
];

export const getFakeTotalStatsForStep = (journeyId: string, stepId: string): TotalStepStats => ({
  journeyId,
  nodeId: stepId,
  totalProfilesAdded: random(0, 30000),
  totalProfilesMovedToNextStep: random(0, 15000),
  totalProfilesReachedGoal: random(0, 2000),
  totalProfilesReachedExit: random(0, 3000),
  totalProfilesRemoved: random(0, 10000),
  totalProfilesMerged: random(0, 5000),
  totalProfilesActive: random(0, 5000),
});

export const getFakeJourneyStats = (originalStats: StatsResponse): StatsResponse => {
  try {
    let data = originalStats;

    data = {
      ...originalStats,
      experimentAnalysis: originalStats.experimentAnalysis.map(ea => ({
        ...ea,
        exitConditions: ea.exitConditions.map(ec => ({ ...ec, profilesExitedByCondition: random(0, 10000) })),
        goals: ea.goals.map(goal => ({ ...goal, profilesReachedGoal: random(0, 10000) })),
        profilesEntered: random(50000, 100000),
        profilesEnteredHp: random(0, 10000),
        profilesExited: random(0, 10000),
        profilesExpired: random(0, 10000),
        profilesMerged: random(0, 10000),
        profilesRemoved: random(0, 10000),
      })),
      stepAnalysis: originalStats.stepAnalysis.map(sa => ({
        ...sa,
        exitConditions: sa.exitConditions.map(ec => ({ ...ec, profilesExitedByCondition: random(0, 10000) })),
        goals: sa.goals.map(goal => ({ ...goal, profilesReachedGoal: random(0, 10000) })),
        profilesActive: random(0, 10000),
        profilesEntered: random(50000, 100000),
        profilesEnteredHp: random(0, 10000),
        profilesExited: random(0, 10000),
        profilesExpired: random(0, 10000),
        profilesMerged: random(0, 10000),
        profilesMovedToNextSteps: random(0, 10000),
        profilesRemoved: random(0, 10000),
      })),
    };

    data.journeyAnalysis = {
      ...data.journeyAnalysis,
      profilesActive: data.stepAnalysis.reduce((acc, val) => acc + val.profilesActive, 0),
      profilesEntered: random(700000, 900000),
      profilesExited: data.stepAnalysis.reduce(
        (acc, val) =>
          acc +
          val.exitConditions.reduce((ecAcc, ec) => ecAcc + ec.profilesExitedByCondition, 0) +
          val.profilesExpired +
          val.profilesExited,
        0,
      ),
      profilesReachedGoals: data.stepAnalysis.reduce(
        (acc, val) => acc + val.goals.reduce((gAcc, goal) => gAcc + goal.profilesReachedGoal, 0),
        0,
      ),
    };

    return data;
  } catch (error) {
    return originalStats;
  }
};

export const addFakeDataForJourney = (journeyId: string, originalJourney: JourneyContents): JourneyContents => {
  const getTotalFromVariantStats = (stats: [], key: string) => stats.reduce((acc: any, val: any) => acc + val[key], 0);
  const getFromLocalStorageOrGenerateRandom = (id: string, from: number, to: number) => {
    try {
      const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');
      if (dataFromLocalStorage) {
        return dataFromLocalStorage;
      }
      const randomValue = random(from, to);
      localStorage.setItem(id, String(randomValue));
      return randomValue;
    } catch (err) {
      return 0;
    }
  };

  try {
    // const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    const data = { ...originalJourney };
    data.goals = data.goals.map((item: any) => {
      const newVariantStats = item.stats.variantStats.map((variant: any) => ({
        ...variant,
        profilesEnteredVariant: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'profilesEnteredVariant'),
          20000,
          100000,
        ),
        profilesReached: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'profilesReached'),
          0,
          20000,
        ),
      }));
      return {
        ...item,
        stats: {
          ...item.stats,
          profilesReachedGoal: getTotalFromVariantStats(newVariantStats, 'profilesReached') || random(10000, 20000),
          variantStats: newVariantStats,
        },
      };
    });

    data.exitConditions = data.exitConditions.map((item: any) => {
      const newVariantStats = item.stats.variantStats.map((variant: any) => ({
        ...variant,
        profilesEnteredVariant: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'exit_profilesEnteredVariant'),
          20000,
          100000,
        ),
        profilesReached: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'exit_profilesReached'),
          0,
          20000,
        ),
      }));
      return {
        ...item,
        stats: {
          ...item.stats,
          profilesExitedByCondition:
            getTotalFromVariantStats(newVariantStats, 'profilesReached') || random(10000, 20000),
          variantStats: newVariantStats,
        },
      };
    });

    const traverseNode = (node: any) => {
      if (node.type === 'STEP') {
        node.stats = {
          profilesInStep: getFromLocalStorageOrGenerateRandom(makeId(journeyId, node.nodeId, 'tree'), 0, 100000),
        };
      }
      if (node.type === 'TRIGGER') {
        node.stats = {
          profilesEntered: getFromLocalStorageOrGenerateRandom(makeId(journeyId, node.nodeId, 'tree'), 0, 100000),
        };
      }
      if (node.children) {
        node.children.forEach(traverseNode);
      }
      return node;
    };

    traverseNode(data.nodes);

    const newExpiredVariantStats: any = data.profilesExited.variantStats.map((variant: any) => ({
      ...variant,
      profilesRemoved: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesRemoved'),
        0,
        20000,
      ),
      profilesExpired: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesExpired'),
        0,
        20000,
      ),
      profilesMerged: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesMerged'),
        0,
        20000,
      ),
      profilesExitedByCondition: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesExitedByCondition'),
        0,
        20000,
      ),
    }));

    data.profilesActive = random(2000, 20000);
    data.profilesEnteredJourney = random(200000, 400000);
    data.totalProfilesReachedGoal = random(10000, 20000);
    data.profilesExited = {
      ...data.profilesExited,
      variantStats: newExpiredVariantStats,
      totalProfilesRemoved: getTotalFromVariantStats(newExpiredVariantStats, 'profilesRemoved'),
      totalProfilesExpired: getTotalFromVariantStats(newExpiredVariantStats, 'profilesExpired'),
      totalProfilesMerged: getTotalFromVariantStats(newExpiredVariantStats, 'profilesMerged'),
      totalProfilesExitedByCondition: getTotalFromVariantStats(newExpiredVariantStats, 'profilesExitedByCondition'),
    };

    return data;
  } catch (error) {
    return originalJourney;
  }
};
