import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import { Tabs, kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import { requestPasswordReset, togglePasswordInvalidation } from '~/context/users';
import UserDetail from '~/context/components/UserDetail';
import RequestResetPassword from '~/context/components/RequestResetPassword';
import ClientManagerContainer from '~/context/users/containers/ClientManagerContainer';
import UserSitesContainer from '~/context/users/containers/UserSitesContainer';
import Btn from '~/components/src/Btn';
import LinkIcon from '~/components/src/LinkIcon';
import contextUsersService from '../users/records/dataService';

export class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
    this.fetchUser = this.fetchUser.bind(this);
  }

  fetchUser = async () => {
    const { id } = this.props;
    const user = await contextUsersService.getUser(id);
    this.setState({ user });
  };
  componentDidMount() {
    this.fetchUser();
  }

  render() {
    const { props } = this;
    const { isContextAdmin, id, t, showResetPasswordModal } = props;
    const { user } = this.state;

    const ts = t('common:checkbox', { returnObjects: true });

    const commonProps = {
      userId: id,
      isContextAdmin,
      user,
    };

    return !user ? (
      <Spinner />
    ) : (
      <Page>
        <Tabs kind={kind.heading}>
          <TabList>
            <Tab>{t('view.tabs.basic')}</Tab>
            <Tab>{t('view.tabs.clients')}</Tab>
            <Tab>{t('view.tabs.sites')}</Tab>
          </TabList>

          {/* User Basic Information */}
          <TabPanel>
            <Heading title={user.name}>
              {isContextAdmin && (
                <Btn onClick={() => showResetPasswordModal(user, t)}>{t('view.basic.actions.resetPassword')}</Btn>
              )}
              {isContextAdmin && (
                <LinkIcon
                  testHook="editUser"
                  className="h-10 w-10"
                  color="blue"
                  icon="edit"
                  url={buildUrl(`context/users/edit/${user.userId}`)}
                  tooltip={t('view.basic.actions.edit')}
                />
              )}
            </Heading>
            <Panel>
              <UserDetail user={user} ts={ts} t={t} />
            </Panel>
          </TabPanel>

          {/* Client Manager */}
          <TabPanel>
            <ClientManagerContainer {...commonProps} />
          </TabPanel>

          {/* Accessible Sites */}
          <TabPanel>
            <UserSitesContainer {...commonProps} />
          </TabPanel>
        </Tabs>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (_state, ownProps) => {
  const id = ownProps?.params.userId;
  return {
    id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps?.params.userId;
  return {
    showResetPasswordModal: (user, t) =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('resetPassword.header'),
          confirmText: t('resetPassword.confirmText'),
          onConfirm: () => dispatch(requestPasswordReset(id)),
          content: (
            <RequestResetPassword
              t={t}
              username={user.username}
              onInvalidateChange={() => dispatch(togglePasswordInvalidation())}
            />
          ),
        }),
      ),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), translate(['users']))(UserPage);
