import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import BtnOutlined from '~/components/src/BtnOutlined';

const ListButtonItem = ({ className, children, onClick, testHook = 'listActionButton' }) => (
  <BtnOutlined icon="add" testHook={testHook} className={cx(className, 'mt-4')} onClick={onClick} size="xs">
    {children}
  </BtnOutlined>
);

ListButtonItem.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ListButtonItem;
