import React from 'react';
import BtnOutlined from '~/components/src/BtnOutlined';
import { ReduxFormInputField, ReduxFormCheckboxField } from '~/components/src/Form/Fields/ReduxFormFields';
import i18n from '~/i18n';
import { Field } from 'redux-form';
import { isRequired } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { HeadersType } from './types';

const ConfigurationBuilder = ({ fields, values, canUpdate, arrayTitle }: any): JSX.Element => {
  const isFieldRequired = !canUpdate ? isRequired : [];
  return (
    <ul className="mb-4 divide-y divide-dashed">
      {fields?.map((header: HeadersType, index: number) => {
        const currentValue = values && values[index];
        return (
          <li key={index} className="mt-2 flex w-full items-center gap-6">
            <Field
              className="flex-1"
              label={i18n.t('partners:form.formFields.key')}
              name={`${header}.key`}
              component={ReduxFormInputField}
              placeholder={i18n.t('partners:form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
            <FieldWithEditMode
              wrapperLabel={i18n.t('partners:form.formFields.value')}
              className="flex-1"
              label={i18n.t('partners:form.formFields.value')}
              name={`${header}.value`}
              component={ReduxFormInputField}
              dependentFieldEdit={!!currentValue?.value || !currentValue?.secure}
              isNeedActivateEditMode={canUpdate}
              placeholder={i18n.t('partners:form.formFields.inputPlaceHolder')}
              validate={isFieldRequired}
              type="text"
            />
            <Field
              label={i18n.t('partners:form.formFields.secure')}
              name={`${header}.secure`}
              component={ReduxFormCheckboxField}
              className="mb-0 mt-8"
            />
            <BtnIcon icon="close" onClick={() => fields?.splice(index, 1)} />
          </li>
        );
      })}
      <li>
        <BtnOutlined
          size="xs"
          onClick={() => fields.push({ key: '', value: '', secure: false })}
          className="mt-6"
          testHook={`${arrayTitle}-button`}
        >
          {i18n.t('common:addRow')}
        </BtnOutlined>
      </li>
    </ul>
  );
};

export default ConfigurationBuilder;
