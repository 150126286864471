import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { showSuccess } from '~/notificationCenter';
import { components as tableComponents } from '~/components/src/Table';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import identitiesService from '~/admin/identities/dataService';

const { EditableRowControls, ListButtonItem } = tableComponents;
const MIN_PARTNER_NUMBER = 1;
const MAX_PARTNER_NUMBER = 9999;

const makeUniqueNumberValidator = identityConfigurations => inputValue => {
  const isUsedAlready = identityConfigurations.map(({ partnerNumber }) => partnerNumber).includes(inputValue);
  return !isUsedAlready;
};

const getValidationSchema = (identityConfigurations, t) =>
  Yup.object().shape({
    partnerNumber: Yup.number()
      .min(
        MIN_PARTNER_NUMBER,
        t('profileIdentities:formValidation.numberMustBeInRange', { MIN_PARTNER_NUMBER, MAX_PARTNER_NUMBER }),
      )
      .max(
        MAX_PARTNER_NUMBER,
        t('profileIdentities:formValidation.numberMustBeInRange', { MIN_PARTNER_NUMBER, MAX_PARTNER_NUMBER }),
      )
      .test(
        'number-is-unique',
        t('profileIdentities:formValidation.numberMustBeUnique'),
        makeUniqueNumberValidator(identityConfigurations),
      )
      .required(t('profileIdentities:formValidation.numberIsRequired')),
  });

const PartnerNumberForm = ({ hidePartnerNumberForm, identityConfigurations, addIdentityConfiguration, t }) => {
  const handleAddPartnerNumber = (formValues = {}) => {
    identitiesService.addIdentityConfiguration(formValues).then(newlyCreatedMapping => {
      addIdentityConfiguration(newlyCreatedMapping);
      hidePartnerNumberForm();
      showSuccess({
        header: t('profileIdentities:configurationAddedSuccessfully', {
          partnerNumber: formValues.partnerNumber,
        }),
      });
    });
  };

  return (
    <div className="Table-row Identities-addPartnerNumberRow">
      <Formik
        onSubmit={handleAddPartnerNumber}
        validationSchema={getValidationSchema(identityConfigurations, t)}
        isInitialValid={false}
        initialValues={{}}
      >
        {({ handleSubmit, errors, isValid, isSubmitting, touched }) => (
          <form className="mt-2 flex w-full items-start gap-4" onSubmit={handleSubmit}>
            <Field
              as={FormikInputField}
              disabled={isSubmitting}
              type="number"
              name="partnerNumber"
              placeholder={t('profileIdentities:formPlaceholder')}
              className="Identities-partnerNumberInput t-partnerNumberInput flex-1"
              errorText={touched.partnerNumber && errors.partnerNumber}
              min={MIN_PARTNER_NUMBER}
              max={MAX_PARTNER_NUMBER}
            />
            <EditableRowControls
              onHandleCancel={hidePartnerNumberForm}
              isAddButtonEnabled={isValid && !isSubmitting}
              className="mt-2"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const PartnerNumberAddButton = ({ showPartnerNumberForm, t }) => (
  <ListButtonItem testHook="partnerNumberAddButton" onClick={showPartnerNumberForm}>
    {t('profileIdentities:addConfiguration')}
  </ListButtonItem>
);

const AddIdentityConfigurationRow = ({ identityConfigurations, addIdentityConfiguration, t }) => {
  const [isPartnerNumberFormVisible, setIsPartnerNumberFormVisible] = useState(false);

  const showPartnerNumberForm = () => {
    setIsPartnerNumberFormVisible(true);
  };

  const hidePartnerNumberForm = () => {
    setIsPartnerNumberFormVisible(false);
  };

  return isPartnerNumberFormVisible ? (
    <PartnerNumberForm
      hidePartnerNumberForm={hidePartnerNumberForm}
      identityConfigurations={identityConfigurations}
      addIdentityConfiguration={addIdentityConfiguration}
      t={t}
    />
  ) : (
    <PartnerNumberAddButton showPartnerNumberForm={showPartnerNumberForm} t={t} />
  );
};

AddIdentityConfigurationRow.propTypes = {
  identityConfigurations: PropTypes.arrayOf(
    PropTypes.exact({
      configurationId: PropTypes.string.isRequired,
      partnerNumber: PropTypes.number.isRequired,
    }),
  ),
  addIdentityConfiguration: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AddIdentityConfigurationRow;
