import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { googleAdsActionTypes } from './googleAdsActions';

const googleAdsReducer = (state, action) => {
  switch (action.name) {
    case googleAdsActionTypes.FETCH_PAYLOAD_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setFulfilled());
    case googleAdsActionTypes.FETCH_PAYLOAD_TYPES.pending:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setPending());
    case googleAdsActionTypes.FETCH_PAYLOAD_TYPES.rejected:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setRejected());
    case googleAdsActionTypes.FETCH_CONSENT_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingConsentTypes'], setFulfilled());
    case googleAdsActionTypes.FETCH_CONSENT_TYPES.pending:
      return state.setIn(['promise', 'fetchingConsentTypes'], setPending());
    case googleAdsActionTypes.FETCH_CONSENT_TYPES.rejected:
      return state.setIn(['promise', 'fetchingConsentTypes'], setRejected());
    case googleAdsActionTypes.SET_PAYLOAD_TYPES:
      return state.set('payloadTypes', action.data);
    case googleAdsActionTypes.SET_CONSENT_TYPES:
      return state.set('consentTypes', action.data);
    case googleAdsActionTypes.FETCH_PARTNER_INFORMATION.fulfilled:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setFulfilled());
    case googleAdsActionTypes.FETCH_PARTNER_INFORMATION.pending:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setPending());
    case googleAdsActionTypes.FETCH_PARTNER_INFORMATION.rejected:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setRejected());
    case googleAdsActionTypes.SET_PARTNER_INFORMATION:
      return state.set('partnerInformation', action.data);

    default:
      return state;
  }
};

export default googleAdsReducer;
