import Api, { API_BASE, parseResponse } from '~/common/ApiService';

export const RULE_GROUP_TYPES = {
  ALL: 'ALL',
  START_TRIGGER: 'START_TRIGGER',
  TRIGGER: 'TRIGGER',
  GOAL: 'GOAL',
  VARIABLE_MODIFICATION: 'VARIABLE_MODIFICATION',
  EXIT_BY_CONDITION: 'EXIT_BY_CONDITION',
};

const BASE_PROFILES_PATH = `${API_BASE}/profiles`;
const BASE_JOURNEY_ORCHESTRATION_PATH = `${API_BASE}/journeyorchestration`;

export const getRulesDefinitionsForAudience = () =>
  Api.callGet(`${BASE_PROFILES_PATH}/ruleDefinitions`).then(parseResponse);

export const getRulesDefinitions = (ruleGroup = RULE_GROUP_TYPES.ALL) =>
  Api.callGet(`${BASE_JOURNEY_ORCHESTRATION_PATH}/ruleDefinitions?ruleGroup=${ruleGroup}`).then(parseResponse);

export const getAllRuleVariables = () => Api.callGet(`${BASE_PROFILES_PATH}/variables`).then(parseResponse);

export const getRuleVariablesForEventListeners = (journeyId, previousTriggerId) =>
  Api.callGet(
    `${BASE_JOURNEY_ORCHESTRATION_PATH}/${journeyId}/triggers/variables?previousTriggerId=${previousTriggerId}`,
  ).then(parseResponse);
