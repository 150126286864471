import React from 'react';
import Page from '~/components/src/Page';
import SiteListContainer from '~/context/sites/containers/SiteListContainer';
import { MediumWrapper } from '~/components/src/Containers';

export const DEFAULT_MAX_INPUTS_FOR_AI_MODEL = 10;
export const DEFAULT_AI_MODEL_LIMIT = 3;
export const DEFAULT_JO_LIMIT = null;

export const SitesPage = () => (
  <Page>
    <MediumWrapper>
      <SiteListContainer />
    </MediumWrapper>
  </Page>
);

export default SitesPage;
