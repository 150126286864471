import i18n from '~/context/i18n';

/**
 * types
 * @type {string}
 */
export const CONTEXT_CLIENTS_FETCH_START = 'app/context/clients/CLIENTS_FETCH_START';
export const CONTEXT_CLIENTS_FETCH_SUCCESS = 'app/context/clients/CONTEXT_CLIENTS_FETCH_SUCCESS';
export const CONTEXT_CLIENTS_FETCH_FAIL = 'app/context/clients/CONTEXT_CLIENTS_FETCH_FAIL';

export const CONTEXT_CLIENT_FETCH_START = 'app/context/clients/CONTEXT_CLIENT_FETCH_START';
export const CONTEXT_CLIENT_FETCH_SUCCESS = 'app/context/clients/CONTEXT_CLIENT_FETCH_SUCCESS';
export const CONTEXT_CLIENT_FETCH_FAIL = 'app/context/clients/CONTEXT_CLIENT_FETCH_FAIL';

export const CONTEXT_CLIENT_DELETE_START = 'app/context/clients/CONTEXT_CLIENT_DELETE_START';
export const CONTEXT_CLIENT_DELETE_SUCCESS = 'app/context/clients/CONTEXT_CLIENT_DELETE_SUCCESS';
export const CONTEXT_CLIENT_DELETE_FAIL = 'app/context/clients/CONTEXT_CLIENT_DELETE_FAIL';

export const CONTEXT_CLIENT_SAVE_START = 'app/context/clients/CONTEXT_CLIENT_SAVE_START';
export const CONTEXT_CLIENT_SAVE_SUCCESS = 'app/context/clients/CONTEXT_CLIENT_SAVE_SUCCESS';
export const CONTEXT_CLIENT_SAVE_FAIL = 'app/context/clients/CONTEXT_CLIENT_SAVE_FAIL';

export const SET_FILTER = 'app/context/clients/SET_FILTER';
export const REMOVE_FILTER = 'app/context/clients/REMOVE_FILTER';

export const DEFAULT_API_RATE_LIMIT = 50;
export const DEFAULT_PRIORITY_LEVELS = 50;
export const DEFAULT_DATA_RETENTION_PERIOD = 24;
export const MINIMUM_DATA_RETENTION_PERIOD = 1;
export const MINIMUM_HP_DAYS = 1;

export const LOCATION_DEFAULT_VALUE = 'eu-west-1';

export const CHAR_SET_SELECT = [
  { value: null, label: i18n.t('clients:passwordChars.none') },
  { value: 'SIMPLE', label: i18n.t('clients:passwordChars.simple') },
  { value: 'ADVANCED', label: i18n.t('clients:passwordChars.advanced') },
];

// Should come from endpoint
export const DEFAULT_PASSWORD_POLICY = {
  minChars: 12,
  charSet: null,
  rotationPeriod: null,
  rotationHistory: null,
};
