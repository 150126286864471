import { isArray } from 'lodash';
import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Current site information
 * @param state
 * @param action
 * @returns {{}}
 */
const info = (state = {}, action) => {
  switch (action.type) {
    case types.INFO_FETCH_SUCCESS:
      return {
        ...action.payload,
        restrictedSubnets: isArray(action.payload.restrictedSubnets)
          ? action.payload.restrictedSubnets.join(', ')
          : action.payload.restrictedSubnets,
      };
    default:
      return state;
  }
};

/**
 * UI State
 */
const defaultUI = {
  isFetchingInfo: false,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.INFO_FETCH_START:
      return {
        ...state,
        isFetchingInfo: true,
      };
    case types.INFO_FETCH_SUCCESS:
    case types.INFO_FETCH_FAIL:
      return {
        ...state,
        isFetchingInfo: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  info,
  ui,
});
