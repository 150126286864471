/**
 * @description Angular main module for Workflows -> Dashboard
 * @memberOf webUi.ui.workflows
 * @namespace webUi.ui.workflows.dashboard
 */
angular.module('webUi.ui.workflows.dashboard', ['webUi.ui.siteState', 'webUi.ui.workflows.base']).config([
  '$stateProvider',
  function config($stateProvider) {
    $stateProvider
      .state('site.workflows.dashboard', {
        url: '/dashboard',
        isReact: true,
      })
      .state('site.workflows.exp', {
        url: '/joDemoMode',
        isReact: true,
      });
  },
]);
