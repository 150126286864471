import React, { useEffect, useState } from 'react';
import { Navigate, NavigateFunction, NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import i18n from '~/i18n';
import { getAngularService } from 'ReactAngular/react.service';
import { buildUrl, useAPI, useApiWithState } from '~/common';
import { Empty, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { fetchDataFeeds } from './dataService';
import DataFeedView from './DataFeedView';
import DataFeedForm from './form/DataFeedForm';
import { DataFeedContext } from './DataFeedContext';
import DataFeedInfo from './DataFeedInfo';

const handleCreateNewDataFeed = (navigate: NavigateFunction) =>
  navigate(buildUrl('content/datafeeds/add'), { replace: true });

function DataFeedList(): JSX.Element {
  const SecurityService = getAngularService(document, 'SecurityService');
  const location = useLocation();
  const path = location.pathname;

  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [showDataFeedInfo, setShowDataFeedInfo] = useState(false);
  const [initialDataFeedId, setInitialDataFeedId] = useState('');

  const { data: hasAdservingEdit } = useAPI(async () => {
    const context = await SecurityService.getSecurityContext();
    return context.hasPermission('ADSERVING_EDIT');
  });

  const {
    state: dataFeeds,
    isLoading: datafeedsLoading,
    refetch: refetchDataFeeds,
  } = useApiWithState(async () => fetchDataFeeds());

  useEffect(() => {
    if (dataFeeds && dataFeeds.length) setInitialDataFeedId(dataFeeds[0]?.dataFeedId);
  }, [dataFeeds]);

  if (datafeedsLoading || !dataFeeds) {
    return <Spin />;
  }

  const filteredDataFeeds = dataFeeds.filter(feed => feed.name.toLowerCase().includes(query.toLowerCase()));

  return (
    <div className="flex h-full">
      <div className="fixed h-full w-[30%] overflow-y-auto bg-white xl:w-[20%]">
        <div className="sticky top-0 bg-white px-2 py-4">
          <SearchElement
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder={i18n.t('dataFeeds:filter')}
            testHook="searchDataFeeds"
          />
        </div>

        <div className="pb-24">
          {filteredDataFeeds?.map((feed, index) => (
            <NavLink key={index} to={buildUrl(`content/datafeeds/view/${feed.dataFeedId}`)}>
              <div
                className={cx(
                  { 'bg-gray-100': path.includes(feed.dataFeedId) },
                  't-leftmenu flex items-center px-4 py-2 hover:bg-gray-200',
                )}
              >
                <p
                  className={cx('inline-block w-full overflow-hidden text-ellipsis whitespace-nowrap text-gray-600', {
                    'text-gray-800': path.includes(feed.dataFeedId),
                  })}
                >
                  {feed.name}
                </p>
              </div>
              <hr />
            </NavLink>
          ))}
        </div>
      </div>
      <div className="flex-1 pl-[30%] xl:pl-[20%]">
        <div className="relative flex h-full flex-col bg-gray-100 px-4">
          <div className="sticky top-1 z-[1] flex border-b border-gray-200 bg-gray-100">
            <div className="my-2 flex items-center gap-2 py-2 dark:text-white">
              <span className="text-2xl">{i18n.t('dataFeeds:title')}</span>
              <Icons className="h-5 w-5 text-blue-600" icon="info" onClick={() => setShowDataFeedInfo(true)} />
            </div>
            <div className="Heading-right">
              {!(path.includes('edit') || path.includes('add')) && (
                <BtnIcon
                  icon="add"
                  color="blue"
                  testHook="addDataFeed"
                  onClick={() => handleCreateNewDataFeed(navigate)}
                  disabled={!hasAdservingEdit}
                  tooltip={i18n.t('dataFeeds:toolTips.new')}
                />
              )}
            </div>
          </div>

          {dataFeeds.length === 0 && path.includes('view') && (
            <Empty
              className="!top-0"
              header={i18n.t('dataFeeds:messages.noDataFeeds.header')}
              body={i18n.t('dataFeeds:messages.noDataFeeds.body')}
            />
          )}

          <DataFeedContext.Provider
            value={{ hasAdservingEdit, dataFeeds, initialDataFeedId, setInitialDataFeedId, refetchDataFeeds }}
          >
            <Routes>
              <Route path="view/:id" element={<DataFeedView />} />
              <Route path="edit/:id" element={<DataFeedForm />} />
              <Route path="add" element={<DataFeedForm />} />
              <Route path="*" element={<Navigate to={`view/${initialDataFeedId}`} replace />} />
            </Routes>
          </DataFeedContext.Provider>

          {showDataFeedInfo && (
            <PickerView
              pickerTitle={i18n.t('dataFeeds:title')}
              handlePickerVisibility={() => setShowDataFeedInfo(false)}
              className="!h-auto"
            >
              <DataFeedInfo />
            </PickerView>
          )}
        </div>
      </div>
    </div>
  );
}

export default DataFeedList;
