/**
 * @description Angular module for Content -> Campaigns -> Dashboard
 * @class ContentCampaignsDashboardController
 * @namespace webUi.ui.content.campaigns.dashboard
 * @memberOf webUi.ui.content.campaigns
 */
angular.module('webUi.ui.content.campaigns.dashboard', [
    'webUi.ui.content.campaigns.base'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.dashboard', {
                url : '/dashboard',
                views : {
                    'mainContent@' : {
                        controller : 'ContentCampaignsDashboardController as vm',
                        templateUrl : 'ui/content/campaigns/dashboard/dashboard.tpl.html'
                    }
                },
                leftMenu : true
            });
    }])
    .controller('ContentCampaignsDashboardController',
        [
            '$scope',
            '$state',
            'hasAdservingEdit',
            'PubSubService',
            /**
     * @function ContentCampaignsDashboardController
     * @memberOf webUi.ui.content.campaigns.dashboard
     *
     * @param $scope
     * @param $state
     * @param hasAdservingEdit
     * @param PubSubService
     *
     * @returns ContentCampaignsDashboardController
     */
            function ContentCampaignsDashboardController($scope, $state, hasAdservingEdit, PubSubService) {
                'use strict';
                
                var vm = this;

                vm.hasAdservingEdit = hasAdservingEdit;
                PubSubService.publishTypeSelected(PubSubService.TYPES.CONTENT.CAMPAIGN, {id: null});

                $scope.$on('hotKeyEvent', function onHotKeyEvent(event, data) {
                    if (hasAdservingEdit && !data.altKey && data.charLower === 'n') {
                        $state.transitionToStateWithSite('site.content.campaigns.form', {});
                    }
                });

            }
        ]);
