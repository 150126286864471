import icons from '~/components/src/Icons/icons';
import i18n from '~/i18n';
import ExitConditionWrapper from './form/Audience/components/ExitConditionWrapper';
import ExperimentsConfiguration from './form/Audience/components/ExperimentsConfiguration';
import GoalWrapper from './form/Audience/components/GoalWrapper';
import InitialConfiguration from './form/Audience/components/InitialConfiguration';
import StepConfigurationWrapper from './form/Audience/components/StepConfigurationWrapper';
import TargetRuleWrapper from './form/Audience/components/TargetRuleWrapper';

const DEFAULT_FILTER = { label: 'All', value: '' };

export const WORKFLOW_STATUS_FILTER = [
  DEFAULT_FILTER,
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  {
    label: 'Unpublished',
    value: 'UNPUBLISHED',
  },
];

export const WORKFLOW_TYPE_FILTER = [
  DEFAULT_FILTER,
  {
    label: 'Journey',
    value: 'JOURNEY',
  },
  {
    label: 'Audience',
    value: 'AUDIENCE',
  },
];

export const WORKFLOW_HP_STATUS_FILTER = [
  DEFAULT_FILTER,
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Running',
    value: 'RUNNING',
  },
  {
    label: 'Scheduled',
    value: 'SCHEDULED',
  },
  {
    label: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    label: 'Cancellation Failed',
    value: 'CANCELLATION_FAILED',
  },
];

export const WORKFLOW_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const HP_STATUS = {
  FAILED: 'FAILED',
  RUNNING: 'RUNNING',
  SCHEDULED: 'SCHEDULED',
  SUCCESSFUL: 'SUCCESSFUL',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  CANCELLATION_FAILED: 'CANCELLATION_FAILED',
};

export const HP_STATUS_ICON_STYLES: Record<string, { icon: keyof typeof icons; iconClass: string }> = {
  FAILED: { icon: 'tagError', iconClass: 'text-red-500' },
  RUNNING: { icon: 'hourglass', iconClass: 'text-yellow-600' },
  SCHEDULED: { icon: 'schedule', iconClass: 'text-yellow-600' },
  SUCCESSFUL: { icon: 'done', iconClass: 'text-green-500' },
  CANCELLING: { icon: 'retract', iconClass: 'text-yellow-600' },
  CANCELLED: { icon: 'success', iconClass: 'text-yellow-500' },
  CANCELLATION_FAILED: { icon: 'unpublished', iconClass: 'text-red-600' },
  DEFAULT: { icon: 'minus', iconClass: 'text-gray-200' },
};

export const DATE_FORMAT = 'DD/MM/YYYY';

export const TIME_FORMAT = 'HH:mm:ss';

export const EXPIRY_OPTIONS = [
  { label: 'Minutes', value: 'm' },
  { label: 'Hours', value: 'h' },
  { label: 'Days', value: 'd' },
  { label: 'Weeks', value: 'w' },
];

export const WORKFLOW_TYPE = {
  Audience: 'AUDIENCE',
  Journey: 'JOURNEY',
};

export const AUDIENCE_STEPS = [
  {
    id: 1,
    title: i18n.t('workflow:create.steps.step1.title'),
    descriptions: [i18n.t('workflow:create.steps.step1.menu.primary')],
    component: InitialConfiguration,
    isOptional: false,
  },
  {
    id: 2,
    title: i18n.t('workflow:create.steps.step2.title'),
    descriptions: [i18n.t('workflow:create.steps.step2.menu.primary')],
    component: TargetRuleWrapper,
    isOptional: false,
  },
  {
    id: 3,
    title: i18n.t('workflow:create.steps.step3.title'),
    descriptions: [i18n.t('workflow:create.steps.step3.menu.primary')],
    component: ExperimentsConfiguration,
    isOptional: true,
  },
  {
    id: 4,
    title: i18n.t('workflow:create.steps.step4.title'),
    descriptions: [i18n.t('workflow:create.steps.step4.menu.primary')],
    component: StepConfigurationWrapper,
    isOptional: false,
  },
  {
    id: 5,
    title: i18n.t('workflow:create.steps.step5.title'),
    descriptions: [
      i18n.t('workflow:create.steps.step5.menu.primary'),
      i18n.t('workflow:create.steps.step5.menu.secondary'),
    ],
    component: GoalWrapper,
    isOptional: true,
  },
  {
    id: 6,
    title: i18n.t('workflow:create.steps.step6.title'),
    descriptions: [
      i18n.t('workflow:create.steps.step6.menu.primary'),
      i18n.t('workflow:create.steps.step6.menu.secondary'),
    ],
    component: ExitConditionWrapper,
    isOptional: true,
  },
];

export const RULE_GROUP_TYPES = {
  ALL: 'ALL',
  START_TRIGGER: 'START_TRIGGER',
  TRIGGER: 'TRIGGER',
  GOAL: 'GOAL',
  VARIABLE_MODIFICATION: 'VARIABLE_MODIFICATION',
  EXIT_BY_CONDITION: 'EXIT_BY_CONDITION',
};

export const NodeTypes = {
  EXIT_BY_CONDITION: 'EXIT_BY_CONDITION',
  GOAL: 'GOAL',
  START_TRIGGER: 'START_TRIGGER',
  STEP: 'STEP',
  TRIGGER: 'TRIGGER',
} as const;

export const OVERVIEW_MENU_NAMES = {
  configuration: 'CONFIGURATION',
  goals: 'GOALS',
  exit: 'EXIT CONDITIONS',
};

export const STATS_MENU_NAME = {
  goals: 'GOALS',
  exit: 'EXIT CONDITIONS',
};

export const OVERVIEW_MENU = [
  {
    label: i18n.t('workflow:overview.menu.configurations'),
    name: OVERVIEW_MENU_NAMES.configuration,
    expand: true,
  },
  {
    label: i18n.t('workflow:overview.menu.goals'),
    name: OVERVIEW_MENU_NAMES.goals,
    expand: true,
  },
  {
    label: i18n.t('workflow:overview.menu.exitConditions'),
    name: OVERVIEW_MENU_NAMES.exit,
    expand: true,
  },
];

export const STATS_MENU = [
  {
    label: i18n.t('workflow:overview.menu.goals'),
    name: STATS_MENU_NAME.goals,
    expand: true,
  },
  {
    label: i18n.t('workflow:overview.menu.exitConditions'),
    name: STATS_MENU_NAME.exit,
    expand: true,
  },
];

export const NODE_SHOWN_NAME = {
  rules: 'Rules',
  connectors: 'Connectors',
};
