import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAPI } from '~/common';
import { getAngularService } from 'ReactAngular/react.service';
import Beacons from './beacons';
import BeaconView from './beacons/BeaconView';
import TagCounterPages from './tagCounter';
import CookiePermission from './cookiePermission';
import ErrorReporting from './errorReporting';
import ExperimentsPages from './experiments';
import IndexedPaths from './indexedPaths';
import PublishingView from './publishing';
import StatsView from './stats';
import UrlBuilderList from './urlBuilder';

const TagManagementPages = (): React.ReactNode => {
  const SecurityService = getAngularService(document, 'SecurityService');

  const { data: ctx } = useAPI(async () => {
    const context = await SecurityService.getSecurityContext();
    return {
      currentSiteNumber: context.currentSiteNumber,
      hasTagEdit: context.hasPermission('TAG_EDIT'),
      hasTagPublish: context.hasPermission('TAG_PUBLISH'),
      hasUrlBuilderPermission: context.hasPermission('URL_BUILDER'),
    };
  });

  return (
    <Routes>
      <Route path="stats" element={<StatsView />} />
      <Route path="experiments/*" element={<ExperimentsPages hasTagEdit={ctx?.hasTagEdit} />} />
      <Route path="cookiePermission/*" element={<CookiePermission hasTagEdit={ctx?.hasTagEdit} />} />
      <Route
        path="publishing"
        element={<PublishingView hasTagEdit={ctx?.hasTagEdit} hasTagPublish={ctx?.hasTagPublish} />}
      />
      <Route path="errorReporting/view" element={<ErrorReporting hasTagEdit={ctx?.hasTagEdit} />} />
      <Route path="beacons" element={<Beacons hasTagEdit={ctx?.hasTagEdit} />} />
      <Route
        path="beacons/:beaconId"
        element={<BeaconView siteNumber={ctx?.currentSiteNumber} hasTagEdit={ctx?.hasTagEdit} />}
      />
      <Route path="urlbuilder/*" element={<UrlBuilderList hasUrlBuilderPermission={ctx?.hasUrlBuilderPermission} />} />
      <Route path="tagCounters/*" element={<TagCounterPages hasTagEdit={ctx?.hasTagEdit} />} />
      <Route path="newPaths/*" element={<IndexedPaths hasTagEdit={ctx?.hasTagEdit} />} />
    </Routes>
  );
};

export default TagManagementPages;
