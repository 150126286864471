angular.module('webUi.ui.admin.siteSecurity', [
    'webUi.ui.admin.base'
])
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.admin.siteSecurity', {
                url : '/sitesecurity',
                isReact: true,
            })
            .state('site.admin.siteSecurityEdit', {
                url : '/sitesecurity/edit',
                isReact: true
            });
    }]);
