import React from 'react';
import { showSuccess } from '~/notificationCenter';

import RowActions from '~/components/src/Table/components/RowActions';
import { components as tableComponents } from '~/components/src/Table';

import identitiesService from '~/admin/identities/dataService';

const { Row, Cell } = tableComponents;

const IdentityConfigurationRows = ({
  identityConfigurations,
  openDeleteConfigurationModal,
  deleteIdentityConfiguration,
  t,
}) =>
  identityConfigurations.map(({ partnerNumber, configurationId }) => {
    const requestNumberRemoval = configurationId => {
      identitiesService.deleteIdentityConfiguration(configurationId).then(() => {
        deleteIdentityConfiguration(partnerNumber);
        showSuccess({
          header: t('profileIdentities:configurationDeletedSuccessfully', {
            partnerNumber,
          }),
        });
      });

      // Returning a self-resolving promise to close the modal immideately after user clicked "Delete" button
      // This is because modal component waits for promise to resolve and only after that closes the modal
      return Promise.resolve();
    };

    const actions = [
      {
        tooltip: t('profileIdentities:deleteButtonTooltip'),
        isDisabled: false,
        icon: 'delete',
        name: 'delete',
        testHook: 'delete',
        type: 'delete',
        onClick: () => {
          openDeleteConfigurationModal(partnerNumber, configurationId, requestNumberRemoval, t);
        },
      },
    ];

    return (
      <Row withActions key={partnerNumber}>
        <Cell className="u-size1of4">{partnerNumber}</Cell>
        <RowActions actions={actions} />
      </Row>
    );
  });

export default IdentityConfigurationRows;
