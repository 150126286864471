import React, { ReactElement } from 'react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';

type LinkBtnType = {
  className?: string;
  url: string;
  testHook?: string;
  children?: string;
  color?: string;
  tooltip?: string;
  placement?: Placement;
  disabled?: boolean;
};

const COLORS: Record<string, string> = {
  gray: 'text-gray-600 dark:text-gray-400  bg-gray-200 dark:bg-gray-700',
  blue: 'text-white bg-blue-600 dark:bg-blue-700',
};

const COLOR_STATES: Record<string, string> = {
  gray: `${COLORS.gray} hover:bg-gray-300 hover:text-gray-600 dark:hover:bg-gray-600 focus:ring-gray-300  dark:focus:ring-gray-500`,
  blue: `${COLORS.blue} hover:bg-blue-700 hover:text-white dark:hover:bg-blue-600 focus:ring-blue-300  dark:focus:ring-blue-500`,
};

function LinkBtn({
  className,
  url,
  children,
  color = 'gray',
  testHook = 'button',
  tooltip,
  placement = 'bottom',
  disabled = false,
  ...props
}: LinkBtnType): ReactElement {
  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement}>
      <div className="inline-block">
        <a
          className={cx(
            'rounded px-5 py-[0.7325rem] text-sm font-medium focus:outline-none focus:ring-4',
            COLORS[color],
            disabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[color]}`,
            `t-${testHook}`,
            className,
          )}
          href={url}
          {...props}
        >
          {children}
        </a>
      </div>
    </Tippy>
  );
}

export default LinkBtn;
