import { createContext } from 'react';
import constants from './constants';

type ModeType = keyof typeof constants.modes;

type ContextType = {
  mode: ModeType | null;
};

const defaultValues = {
  mode: null,
};

export const ConnectorModeContext = createContext<ContextType>(defaultValues);
