import React from 'react';
import ConnectorCard, { INTEGRATION_TYPE } from '~/components/src/ConnectorCard';
import { getSiteId } from '~/common';
import { translate } from 'react-i18next';
import { getLogoUrlByConnectorType, getConnectorImage } from '~/profiles/connectors/connectorUtils';

const AudienceConnectors = ({ connectors, t }) => {
  const imageUrl = getConnectorImage(getSiteId());
  return (
    <div className="u-paddingTopXl">
      <ConnectorCard
        className="u-marginBottomXxl"
        name={t('card.labels.default')}
        logoUrl={getLogoUrlByConnectorType('RELAY42')}
        integrationType={INTEGRATION_TYPE.r42}
        t={t}
        connector={{
          activated: true,
          image: getLogoUrlByConnectorType('RELAY42'),
          partnerName: t('list.defaultPsuedoConnector'),
          partnerDetails: {
            partnerType: INTEGRATION_TYPE.r42,
            partnerId: INTEGRATION_TYPE.r42,
          },
        }}
      />
      {connectors.map(connector => (
        <ConnectorCard
          connector={connector}
          key={connector.partnerDetails.partnerId}
          className="u-marginBottomXxl"
          name={connector.name}
          logoUrl={imageUrl(connector.partnerDetails)}
          properties={connector.properties}
          integrationType={connector.integrationType}
          t={t}
        />
      ))}
    </div>
  );
};

export default translate('partners')(AudienceConnectors);
