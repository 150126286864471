import React from 'react';
import Icon from 'react-icon-base';

const R42EventListenerDisabled = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g id="event-disabled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="wifi-signal-2" transform="translate(1.000000, 2.000000)" fill="#CCCCCC" fillRule="nonzero">
        <path
          d="M25.42375,0.8325 C25.1099069,0.507554269 24.6451526,0.377234254 24.2081158,0.491628554 C23.7710791,0.606022855 23.4297729,0.947329055 23.3153786,1.38436585 C23.2009843,1.82140263 23.3313043,2.28615691 23.65625,2.6 C26.1179546,5.06145345 27.500947,8.40005152 27.500947,11.88125 C27.500947,15.3624485 26.1179546,18.7010465 23.65625,21.1625 C23.1678232,21.6505817 23.1675434,22.4421982 23.655625,22.930625 C24.1437067,23.4190518 24.9353232,23.4193316 25.42375,22.93125 C28.3545146,20.0009265 30.0010382,16.0262902 30.0010382,11.881875 C30.0010382,7.73745983 28.3545146,3.7628235 25.42375,0.8325 Z"
          id="Path"
        ></path>
        <path
          d="M21.88625,4.3675 C21.3912811,3.92118956 20.6332036,3.94171036 20.1631047,4.41414472 C19.6930058,4.88657909 19.6762421,5.644749 20.125,6.1375 C23.2976864,9.31045437 23.2976864,14.4545456 20.125,17.6275 C19.8092665,17.9434568 19.6860777,18.4038546 19.8018375,18.8352662 C19.9175974,19.2666778 20.2547192,19.6035614 20.6862125,19.7190162 C21.1177059,19.834471 21.5780165,19.7109568 21.89375,19.395 C23.886777,17.4023658 25.006481,14.6995381 25.006481,11.88125 C25.006481,9.06296191 23.886777,6.36013419 21.89375,4.3675 L21.88625,4.3675 Z"
          id="Path"
        ></path>
        <path
          d="M2.5,11.8825 C2.49056263,8.39925015 3.87458365,5.05689564 6.34375,2.6 C6.81746526,2.10952639 6.81069047,1.32989403 6.32852322,0.847726781 C5.84635597,0.365559533 5.06672361,0.358784738 4.57625,0.8325 C1.64548537,3.7628235 -0.00103815051,7.73745983 -0.00103815051,11.881875 C-0.00103815051,16.0262902 1.64548537,20.0009265 4.57625,22.93125 C5.06467683,23.4193316 5.85629334,23.4190518 6.34437499,22.930625 C6.83245663,22.4421982 6.83217681,21.6505816 6.34375,21.1625 C3.87425617,18.7068474 2.49011548,15.3651042 2.5,11.8825 L2.5,11.8825 Z"
          id="Path"
        ></path>
        <path
          d="M9.875,4.3675 C9.64053968,4.1327787 9.32238656,4.00089168 8.990625,4.00089168 C8.65886344,4.00089168 8.34071032,4.1327787 8.10625,4.3675 C3.95760875,8.51670401 3.95760875,15.243296 8.10625,19.3925 C8.42198346,19.7084568 8.88229414,19.8319711 9.31378746,19.7165162 C9.74528078,19.6010614 10.0824026,19.2641778 10.1981625,18.8327662 C10.3139223,18.4013546 10.1907335,17.9409568 9.875,17.625 C8.35103298,16.1012358 7.49486017,14.0344464 7.49486017,11.879375 C7.49486017,9.72430361 8.35103298,7.65751416 9.875,6.13375 C10.3621449,5.64576866 10.3621449,4.85548134 9.875,4.3675 L9.875,4.3675 Z"
          id="Path"
        ></path>
        <path
          d="M15,7.96624319 C13.1757034,7.96279325 11.6148988,9.27561015 11.3057658,11.0735275 C10.9966329,12.8714448 12.0293156,14.6301846 13.75,15.23625 L13.75,25.77875 C13.75,26.4691059 14.3096441,27.02875 15,27.02875 C15.6903559,27.02875 16.25,26.4691059 16.25,25.77875 L16.25,15.23625 C17.9706844,14.6301846 19.0033671,12.8714448 18.6942342,11.0735275 C18.3851012,9.27561015 16.8242966,7.96279325 15,7.96624319 L15,7.96624319 Z"
          id="Path"
        ></path>
      </g>
      <rect
        id="Rectangle"
        fill="#CCCCCC"
        transform="translate(16.000000, 15.000000) scale(-1, 1) rotate(45.000000) translate(-16.000000, -15.000000) "
        x="15"
        y="-6"
        width="2"
        height="42"
        rx="1"
      ></rect>
    </g>
  </Icon>
);

export default R42EventListenerDisabled;
