import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FaSearch } from 'react-icons/fa';
import Icon from '../Icon';
import { Elements } from '../Form';
import './styles.scss';
import '../Media/styles.scss';

const Search = ({ testHook = 'search', children, className, ...other }) => (
  <div className={cx('Search', className, `t-${testHook}`)}>
    <div className="Media Media--formIcon">
      <Elements.Input {...other} size="block" className="Search-input Media-input--left t-searchInput" />
      <Icon className="Search-icon Media-icon Media-icon--left" icon={FaSearch} />
    </div>
    {children}
  </div>
);

Icon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
  testHook: PropTypes.string,
};

export default Search;
