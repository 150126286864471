import React, { ReactElement } from 'react';
import cx from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '~/common';
import { Notification } from '~/components';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { TMappedCumulativeStats } from '../types';
import { formatFloatValue } from '../util';

const columnHelper = createColumnHelper<TMappedCumulativeStats>();

export const CumulativeStats = ({ cumulativeStats }: { cumulativeStats: TMappedCumulativeStats[] }): ReactElement => {
  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <span>{i18n.t('experiments:cumulativeStats.name')}</span>,
      cell: info => <span className="t-groupName">{info.getValue()}</span>,
    }),
    columnHelper.accessor('uniqueVisitors', {
      header: () => <div className="text-right">{i18n.t('experiments:cumulativeStats.visitors')}</div>,
      cell: info => <div className="t-visitors text-right">{formatNumber(info.getValue()) || 'n/a'}</div>,
      size: 100,
    }),
    columnHelper.accessor('conversionRate', {
      header: () => <span>{i18n.t('experiments:cumulativeStats.conversionRate')}</span>,
      cell: info => (
        <div className="flex items-center justify-between">
          <span>{formatFloatValue(info.getValue(), 3, true)} %</span>
          {!info.row.original.controlGroup && (
            <div>
              <span className="flex items-center gap-1">
                <Icons
                  className={cx('h-5 w-5', {
                    'text-red-600': info.row.original.changeInCrFromCG <= 0,
                    'text-green-600': info.row.original.changeInCrFromCG > 0,
                  })}
                  icon={info.row.original.changeInCrFromCG > 0 ? 'trendingUp' : 'trendingDown'}
                />
                {formatFloatValue(info.row.original.changeInCrFromCG)} %
              </span>
              <div className="pl-2">
                <span className="text-xs">p = {formatFloatValue(info.row.original.significanceConversionRate)}</span>
              </div>
            </div>
          )}
        </div>
      ),
    }),
    columnHelper.accessor('avo', {
      header: () => <span>{i18n.t('experiments:cumulativeStats.averageOrderValue')}</span>,
      cell: info => (
        <div className="flex items-center justify-between">
          <span>{formatFloatValue(info.getValue() / 100)}</span>
          {!info.row.original.controlGroup && (
            <div>
              <span className="flex items-center gap-1">
                <Icons
                  className={cx('h-5 w-5', {
                    'text-red-600': info.row.original.changeInAvoFromCG <= 0,
                    'text-green-600': info.row.original.changeInAvoFromCG > 0,
                  })}
                  icon={info.row.original.changeInAvoFromCG > 0 ? 'trendingUp' : 'trendingDown'}
                />
                {formatFloatValue(info.row.original.changeInAvoFromCG)}
              </span>
              <div className="pl-2">
                <span className="text-xs">p = {formatFloatValue(info.row.original.significanceConversionValue)}</span>
              </div>
            </div>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      {!cumulativeStats?.length ? (
        <Notification kind="information" testHook="noCumulativeStats">
          {i18n.t('experiments:messages.noCumulativeStats')}
        </Notification>
      ) : (
        <UITable testHook="cumulativeStats" data={cumulativeStats} columns={getColumns()} />
      )}
    </>
  );
};
