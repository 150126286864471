import React from 'react';

import { round } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from '~/i18n';
import { Panel } from '~/components/src/Containers';
import { buildUrl, useApiWithState } from '~/common';
import { Empty, Heading, Notification, Page, Spinner } from '~/components';

import { MODEL_ACTIONS } from './constants';
import { ModelStats as MStats } from './types';
import { fetchModelStatsById } from './dataService';
import ModelActions from './components/ModelActions';

import './styles.scss';

const decimalToPercent = (value: number) => {
  if (value > 0) return round(value * 100, 2);
  return 0;
};

function Trained({ stats }: { stats: MStats }) {
  return (
    <Panel className="ModelStats-panel">
      <p className="t-status">
        <b>{i18n.t('ai:modelStats.status')}</b>: {stats?.status}
      </p>
      <p className="t-precision">
        <b>{i18n.t('ai:modelStats.precision')}</b>: {decimalToPercent(stats?.properties?.precision)}%
      </p>
      <p className="t-accuracy">
        <b>{i18n.t('ai:modelStats.accuracy')}</b>: {decimalToPercent(stats?.properties?.accuracy)}%
      </p>
      <p className="t-f1Score">
        <b>{i18n.t('ai:modelStats.f1Score')}</b>: {decimalToPercent(stats?.properties?.f1Score)}%
      </p>
      <p className="t-recall">
        <b>{i18n.t('ai:modelStats.recall')}</b>:{' '}
        {stats?.properties?.recall ? `${decimalToPercent(stats?.properties?.recall)}%` : 'NA'}
      </p>
    </Panel>
  );
}

function Metrics({ stats }: { stats: MStats }) {
  const rowKeys = Object.keys(stats?.results);
  const columnKeys = Object.keys(stats?.results[rowKeys[0]]);
  columnKeys.unshift('');
  return (
    <Panel className="ModelStats-panel">
      <b>{i18n.t('ai:modelStats.metrics')}</b>
      <table>
        <tr>
          {columnKeys.map((c, i) => (
            <td key={`col${i}`}>
              <b>{c}</b>
            </td>
          ))}
        </tr>

        {rowKeys.map(r => (
          <tr>
            {columnKeys.map((c, j) => {
              if (j === 0) {
                return (
                  <td key={`row${j}`} className="t-metricType">
                    <b>{r}</b>
                  </td>
                );
              }
              return <td key={`row${j}`}>{stats?.results[r][c]}</td>;
            })}
          </tr>
        ))}
      </table>
    </Panel>
  );
}

function ModelStats(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const modelId = params.id || '';
  const { state: stats, isLoading, refetch: refetchStats } = useApiWithState(() => fetchModelStatsById(modelId));

  const goToModelsList = () => navigate(buildUrl('ai/models/list'), { replace: true });

  if (isLoading || !stats) {
    return <Spinner />;
  }

  const modelInfo = { modelId, modelName: '' };
  const actions = stats.actions.filter(action => action.name !== MODEL_ACTIONS.STATS);

  return (
    <Page className="ModelStats">
      <Heading
        title={i18n.t('ai:modelsPage.stats')}
        crumbs={[
          {
            title: i18n.t('ai:actions.backToModels'),
            onClick: () => {
              goToModelsList();
            },
          },
        ]}
      >
        <ModelActions allowedActions={actions} modelInfo={modelInfo} refetchData={refetchStats} />
      </Heading>

      {!stats && <Empty header={i18n.t('ai:modelStats.emptyHeader')} />}

      {stats?.properties?.maxPredictionDays && (
        <Notification kind="information" testHook="perdictionDaysInfo">
          {i18n.t('ai:notifications.maxFuturePredictionDaysInfo', { days: stats.properties.maxPredictionDays })}
        </Notification>
      )}
      {stats && (
        <>
          <Trained stats={stats} />
          <Metrics stats={stats} />
        </>
      )}
    </Page>
  );
}

export default ModelStats;
