import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const EditableRowErrors = ({ errors }) => (
  <div className={cx({ 'Table-row': errors && errors.length })}>
    {errors && errors.length !== 0 && (
      <ul className="u-paddingM">
        {errors.map(item => (
          <li key={item} className="Element-error">
            {item}
          </li>
        ))}
      </ul>
    )}
  </div>
);

EditableRowErrors.propTypes = {
  errors: PropTypes.array,
};

export default EditableRowErrors;
