import React, { ReactElement } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Details, Field, Form, reduxForm } from '~/components';
import Detail from '~/components/src/Form/Detail/Default';
import { ReduxFormInputField, ReduxFormToggleField } from '~/components/src/Form/Fields/ReduxFormFields';
import { defaultMaxInput, isRequired } from '~/common';
import * as fromModals from '~/modals';
import ModalHeader from '~/modals/containers/ModalHeader';
import Btn from '~/components/src/Btn';
import { User } from '~/common/types';
import { fetchSiteUsers } from '~/admin/users';
import { modifyUserLockSettings } from './dataservice';
import { UserLockSettingsProps } from './types';
import { LOCK_TYPE, FORM_SELECTOR, DATE_FORMAT } from './constants';

const selector = formValueSelector(FORM_SELECTOR);

export const UserLockSettings = ({
  t,
  user,
  site,
  canEditInfo,
  loggedInUser,
  isUserLocked,
  onCancel,
  handleSubmit,
}: UserLockSettingsProps): ReactElement => {
  const ts = t('common:checkbox', { returnObjects: true });
  const siteClientId = site && site.clientId;
  const userClientId = user && user.clientId;
  const hasSameClient = !!siteClientId && !!userClientId && siteClientId === userClientId;
  const isMaxAttemptsLock = user.lock.type === LOCK_TYPE.MAX_INVALID_PASSWORD_ATTEMPTS;
  const canEdit = canEditInfo && hasSameClient && loggedInUser?.userId !== user?.userId && !isMaxAttemptsLock;

  const userLocked = !canEdit ? (
    <>
      <Details.Bool label={t('users:user.lock.locked')} value={user.lock?.locked} translations={ts} />
      <Detail textColor="red" label={t('users:user.lock.reason')} value={user.lock?.reason} />
      {user.lock?.locked && (
        <Detail label={t('users:user.lock.lockedBy')}>
          <div className="flex items-center gap-1">
            <span>{user.lock?.lockedBy}</span>
            <span className="text-sm text-blue-500">on {moment(user.lock?.lockedAt).format(DATE_FORMAT)}</span>
          </div>
        </Detail>
      )}
    </>
  ) : (
    <>
      <Field
        label={t(`users:userForm.lock.${isUserLocked ? 'locked' : 'unLocked'}`)}
        name="lock.locked"
        type="checkbox"
        component={ReduxFormToggleField}
      />
      {(isUserLocked || user?.lock?.locked) && (
        <div className="flex flex-col gap-2">
          <Field
            label={t('users:userForm.lock.reason')}
            name="lock.reason"
            type="text"
            disabled={user?.lock?.locked}
            component={ReduxFormInputField}
            validate={isUserLocked ? [isRequired, defaultMaxInput] : [defaultMaxInput]}
          />
          {user?.lock?.lockedBy && (
            <span className="t-lockedBy text-red-500">
              {t('users:userForm.lock.lockedMessage', {
                lockedBy: user?.lock?.lockedBy,
                lockedAt: moment(user?.lock?.lockedAt).format(DATE_FORMAT),
              })}
            </span>
          )}
        </div>
      )}
    </>
  );

  return (
    <div className="px-2">
      <ModalHeader title={t('users:userLockSettings.header')} onCloseClick={onCancel} />
      <Form className="px-4" onSubmit={handleSubmit}>
        <div className="Modal-body">{userLocked}</div>
        <div className="flex items-center justify-end gap-2 py-4">
          <Btn testHook="cancel" onClick={onCancel}>
            {t('settings:actionsForm.cancel')}
          </Btn>
          {canEdit && (
            <Btn testHook="save" color="blue" type="submit">
              {t('settings:actionsForm.save')}
            </Btn>
          )}
        </div>
      </Form>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      isUserLocked: selector(state, 'lock.locked'),
    }),
    (dispatch, ownProps: any) => ({
      onSubmit: async (user: User) => {
        await modifyUserLockSettings(user);
        dispatch(fetchSiteUsers(ownProps?.site.siteNumber, ownProps?.site.clientId) as any);
        dispatch(fromModals.hideModal());
      },
      onCancel: () => {
        dispatch(fromModals.hideModal());
      },
    }),
  ),
  reduxForm({
    form: FORM_SELECTOR,
  }),
)(UserLockSettings as any);
