import Api, { API_BASE } from '~/common/ApiService';

const apiPath = `${API_BASE}/v1/partners/adform`;

export const fetchSegments = partnerId =>
  Api.callGet(`${apiPath}/${partnerId}/segments`, {
    shouldShowToast: false,
  }).then(response => response.data);

export const createSegment = (partnerId, segment, t) =>
  Api.callPost(`${apiPath}/${partnerId}/segment`, segment, {
    toastText: t('connectors.adform.segmentCreationError'),
  }).then(response => response.data);

export const fetchCategories = partnerId =>
  Api.callGet(`${apiPath}/${partnerId}/categories`, {
    shouldShowToast: false,
  }).then(response => response.data);
