import React, { useEffect, useRef, useState } from 'react';
import * as d3lib from 'd3';
import { ZOOM_BUTTON_FACTOR, ZOOM_MAX_LIMIT, ZOOM_MIN_LIMIT } from '../constants';
import { getInitialChartTransform } from './utils';
import { PortalToReactRoot, ZoomButtons } from './ZoomButtons';

const Zoom = ({ nodes, children }: any) => {
  const [zoomTransform, setZoomTransform] = useState();
  const [initialTransform, setInitialTransform] = useState();
  const zoomBaseElementRef = useRef(null);

  const d3 = d3lib as any;
  const zoomBehavior = d3
    .zoom()
    .scaleExtent([ZOOM_MIN_LIMIT, ZOOM_MAX_LIMIT])
    .on('zoom', () => {
      const { transform } = d3.event;
      setZoomTransform(transform.toString());
    }) as any;

  useEffect(() => {
    const zoomBaseElement = zoomBaseElementRef.current;

    // Attach pan / zoom handlers to zoomBaseElement
    d3.select(zoomBaseElement).call(zoomBehavior);

    const initialChartTransform = getInitialChartTransform(nodes, zoomBaseElement) as any;

    setInitialTransform(initialChartTransform);
    d3.select(zoomBaseElement).call(zoomBehavior.transform, initialChartTransform);
  }, []);

  const zoomBy = (factor: number) => {
    const zoomBaseElement = zoomBaseElementRef.current;
    d3.select(zoomBaseElement).transition().call(zoomBehavior.scaleBy, factor);
  };

  const onZoomInClick = () => {
    zoomBy(1 + ZOOM_BUTTON_FACTOR);
  };
  const onZoomOutClick = () => {
    zoomBy(1 - ZOOM_BUTTON_FACTOR);
  };
  const onZoomReset = () => {
    const zoomBaseElement = zoomBaseElementRef.current;
    d3.select(zoomBaseElement).transition().call(zoomBehavior.transform, initialTransform);
  };

  return (
    <>
      <rect width="100%" height="100%" opacity="0" ref={zoomBaseElementRef} />
      <g transform={zoomTransform}>{children}</g>
      <PortalToReactRoot>
        <ZoomButtons onZoomInClick={onZoomInClick} onZoomOutClick={onZoomOutClick} onZoomReset={onZoomReset} />
      </PortalToReactRoot>
    </>
  );
};

export default Zoom;
