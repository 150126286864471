import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import * as selectors from './selectors';
import AdminEnvironmentsService from './dataService';

/**
 * Fetch environments
 */
export const fetchEnvironments = () => dispatch => {
  dispatch({
    type: types.ENVIRONMENTS_FETCH_START,
  });

  return AdminEnvironmentsService.getEnvironments()
    .then(environments => {
      dispatch({
        type: types.ENVIRONMENTS_FETCH_SUCCESS,
        payload: environments,
      });
    })
    .catch(error => dispatch({ type: types.ENVIRONMENTS_FETCH_FAIL, error }));
};

// fetchEnvironmentsIfNeeded can be used when changing environments is no more possible in angular
//
/**
 * Fetch the environments if they are not in the store
 */
export const fetchEnvironmentsIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchEnvironments(getState())) {
    return dispatch(fetchEnvironments());
  }
  return Promise.resolve();
};

/**
 * Save environment
 * Creates a new environment
 * @param environment
 */
export const saveEnvironment = environment => dispatch => {
  dispatch({
    type: types.ENVIRONMENT_SAVE_START,
  });

  return AdminEnvironmentsService.saveEnvironment(environment)
    .then(environmentId => {
      dispatch({
        type: types.ENVIRONMENT_SAVE_SUCCESS,
        payload: {
          ...environment,
          environmentId,
        },
      });
      changeUrl('admin/environments');
    })
    .catch(error => dispatch({ type: types.ENVIRONMENT_SAVE_FAIL, error }));
};

/**
 * Update environment
 * Updates existing environment
 * @param environment
 */
export const updateEnvironment = environment => dispatch => {
  dispatch({
    type: types.ENVIRONMENT_UPDATE_START,
  });

  return AdminEnvironmentsService.updateEnvironment(environment)
    .then(environmentId => {
      dispatch({
        type: types.ENVIRONMENT_UPDATE_SUCCESS,
        payload: { ...environment, environmentId },
      });
      changeUrl('admin/environments');
    })
    .catch(error => dispatch({ type: types.ENVIRONMENT_UPDATE_FAIL, error }));
};

/**
 * Delete environment
 */
export const deleteEnvironment = environmentId => dispatch => {
  dispatch({ type: types.ENVIRONMENT_DELETE_START });

  return AdminEnvironmentsService.deleteEnvironment(environmentId)
    .then(() => {
      showSuccess({ body: i18n.t('environments:api.deletionSuccess') });
      dispatch({
        type: types.ENVIRONMENT_DELETE_SUCCESS,
        payload: environmentId,
      });
    })
    .catch(error => dispatch({ type: types.ENVIRONMENT_DELETE_FAIL, error }));
};

/**
 * Fetch non deletable codes
 */
export const fetchNonDeletableCodes = () => dispatch => {
  dispatch({ type: types.CANTDELETE_FETCH_START });

  return AdminEnvironmentsService.getNonDeletableCodes()
    .then(codes => {
      dispatch({
        type: types.CANTDELETE_FETCH_SUCCESS,
        payload: codes,
      });
    })
    .catch(error => dispatch({ type: types.CANTDELETE_FETCH_FAIL, error }));
};

/**
 * Fetch the nonDeletableCodes if they are none in the store
 */
export const fetchNonDeletableCodesIfNeeded = () => (dispatch, getState) => {
  if (selectors.getNonDeletableCodes(getState()).length === 0) {
    return dispatch(fetchNonDeletableCodes());
  }
  return Promise.resolve();
};
