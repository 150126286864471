import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { mode } from '~/components/src/Code';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, defaultMaxText } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
  ReduxFormCodeField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';

export const TagVariantForm = ({
  handleSubmit,
  submitting,
  cancelHref,
  clients,
  siteModules,
  executionTypes,
  t,
  extendActions,
  touch,
}) => (
  <form onSubmit={handleSubmit}>
    <div>
      <Field
        label={t('variantForm.name.label')}
        name="name"
        component={ReduxFormInputField}
        placeholder={t('variantForm.name.placeholder')}
        validate={[isRequired, defaultMaxInput]}
      />
      <Field
        label={t('variantForm.generatedName.label')}
        name="generatedName"
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.js}
      />
      <Field
        label={t('variantForm.description.label')}
        name="description"
        component={ReduxFormTextAreaField}
        placeholder={t('variantForm.description.placeholder')}
        validate={defaultMaxText}
      />
      <Field
        label={t('variantForm.client')}
        name="clientId"
        component={ReduxFormSelectField}
        options={clients}
        touch={touch}
      />
      <Field
        label={t('variantForm.siteModule')}
        name="siteModule"
        component={ReduxFormSelectField}
        options={siteModules}
        touch={touch}
      />
      <Field label={t('variantForm.private')} name="privateMode" component={ReduxFormCheckboxField} />
      <Field
        label={t('variantForm.priority.label')}
        name="defaultPriority"
        component={ReduxFormInputField}
        placeholder={t('variantForm.priority.placeholder')}
        type="number"
        validate={isRequired}
      />
      <Field
        label={t('variantForm.order.label')}
        name="sortingOrder"
        component={ReduxFormInputField}
        placeholder={t('variantForm.order.placeholder')}
        type="number"
        validate={isRequired}
      />
      <Field
        label={t('variantForm.executionType')}
        name="executionType"
        component={ReduxFormSelectField}
        isLoading={!executionTypes.length}
        options={executionTypes}
        touch={touch}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.js}
        label={t('variantForm.customCode')}
        name="jsCode"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.html}
        label={t('variantForm.templateView')}
        name="templateView"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.html}
        label={t('variantForm.templateEdit')}
        name="templateEdit"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        mode={mode.js}
        label={t('variantForm.staticTemplate')}
        name="templateEditStatic"
      />
      <Field label={t('variantForm.disableEditing')} name="hasNoEdit" component={ReduxFormCheckboxField} />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.js}
        label={t('variantForm.templateScope')}
        name="templateScope"
        validate={isRequired}
      />
    </div>
    <ActionsRow t={t} submitting={submitting} cancelHref={cancelHref} extend={extendActions} />
  </form>
);

TagVariantForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  clients: PropTypes.array,
  siteModules: PropTypes.array,
  executionTypes: PropTypes.array,
  initialValues: PropTypes.object,
  extendActions: PropTypes.element,
  t: PropTypes.func.isRequired,
};

export default TagVariantForm;
