angular.module('webUi.service.ajaxBusy', [
    'angular-loading-bar'
])

    .config(['$httpProvider', 'cfpLoadingBarProvider', function($httpProvider, cfpLoadingBarProvider) {

        var offlineCheck = function(event) {
            // online, but needs login
            if(event.target.status === 401) {
                location.reload();
            }
        };

        // Configure Offline.js support
        Offline.options = {checks: {xhr: {url: '/connection-test', timeout:3000}}};
        setInterval(Offline.check, 30000);

        Offline.on('confirmed-down', function () {
            $('#glassOverlay').show();
        });

        Offline.on('confirmed-up', function () {
            $('#glassOverlay').hide();
        });

        Offline.onXHR(function(request) {
            if(request.url === Offline.options.checks.xhr.url) {
                request.xhr.addEventListener('load', offlineCheck, false);
            }
        });

        // Configure loading bar not to show spinner
        cfpLoadingBarProvider.includeSpinner = false;
        cfpLoadingBarProvider.latencyThreshold = 0;

        // Setup the interceptor to show busy information
        var interceptor = ['$timeout', '$rootScope', function ($timeout, $rootScope) {
            var requestsInProgress = 0;
            var timer;

            function showIndicator() {
                $rootScope.busyIndicator = true;
            }

            function updateIndicators() {
                if(requestsInProgress < 1) {
                    hideIndicator();
                    if(timer) {
                        $timeout.cancel(timer);
                        timer = false;
                    }
                }
            }

            function hideIndicator() {
                $rootScope.busyIndicator = false;
            }

            return {
                'request': function(config) {
                    if (requestsInProgress === 0) {
                        // If this is the first request we should set the timer
                        timer = $timeout(showIndicator, 500);
                    }

                    requestsInProgress++;

                    // Return config unmodified
                    return config;
                },

                'response': function(response) {
                    requestsInProgress--;
                    updateIndicators();

                    // Return results unmodified
                    return response;
                },

                'responseError': function(rejection) {
                    requestsInProgress--;
                    updateIndicators();

                    // Return results unmodified
                    return rejection;
                }
            };
        }];

        $httpProvider.interceptors.push(interceptor);
    }]);
