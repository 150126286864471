angular.module('webUi.filter.formatNumber', [])
/**
 * Add commas or spaces to group every three digits
 */
    .filter(
        'formatNumber',
        [
            function() {

                return function formatNumber(n) {
                    if (!n) {
                        return '';
                    }

                    n = ('' + n).split('.');
                    var num = n[0];
                    var dec = n[1];
                    var s, t;

                    if (num.length > 3) {
                        s = num.length % 3;

                        if (s) {
                            t = num.substring(0,s);
                            num = t + num.substring(s).replace(/(\d{3})/g, '.$1');
                        } else {
                            num = num.substring(s).replace(/(\d{3})/g, '.$1').substring(1);
                        }
                    }

                    if (dec && dec.length > 3) {
                        dec = dec.replace(/(\d{3})/g, '$1 ');
                    }

                    return num + (dec? ',' + dec : '');
                };

            }]
    );
