import React, { useRef, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useAPI } from '~/common/ApiHooks';
import { Heading, StatusTag } from '~/components';
import { Panel } from '~/components/src/Containers';
import DateRangePicker from '~/components/src/DateRangePicker';
import Empty from '~/components/src/Empty';
import Icons from '~/components/src/Icons';
import LinkIcon from '~/components/src/LinkIcon';
import StackedChart from '~/components/src/StackedChart';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import { getFakeConnectorStats, getIsFakeMode } from '~/workflows/fake';
import i18n from '~/i18n';
import * as ActivityApi from '~/profiles/audiences/activity/service';
import { prepareForDisplay } from './utils';
import './DataExchangeGraph.scss';
import './documentationLink.scss';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const fetchPartnerStats = async (partnerId, partnerType, sourceId, startDate, endDate) => {
  let response = null;
  if (getIsFakeMode()) {
    response = getFakeConnectorStats(partnerId, sourceId, startDate, endDate);
  } else {
    response = await ActivityApi.getPartnerStats(
      partnerId,
      partnerType,
      sourceId,
      startDate.startOf('day').toDate().getTime(),
      endDate.endOf('day').toDate().getTime(),
    );
  }

  const { stats, statsMetadata } = response.data;

  return { stats, statsMetadata };
};

const FetchFail = ({ integrationName }) => (
  <div className="DataExchangeGraph-fetchFail">
    <div className="DataExchangeGraph-fetchFailContent">
      <div>
        {i18n.t('audiences:failedToFetchIntegrationStats', {
          integrationName,
        })}
      </div>
      <div>{i18n.t('common:tryAgainLater')}</div>
    </div>
  </div>
);

const DataExchangeGraph = ({ integrations, sourceId, type = 'audience' }) => {
  const statsRef = useRef({ stats: [], statsMetadata: {} });
  const [filter, setFilter] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedIntegration = integrations[selectedTabIndex];
  const partnerId = selectedIntegration?.partnerDetails?.partnerId;
  const partnerType = selectedIntegration?.partnerDetails?.partnerType;
  const { startDate, endDate } = filter;

  const { data, error } = useAPI(async () => {
    if (partnerId && partnerType) {
      return fetchPartnerStats(partnerId, partnerType, sourceId, startDate, endDate).then(
        ({ stats, statsMetadata }) => ({
          stats: prepareForDisplay(stats),
          statsMetadata,
        }),
      );
    }

    return null;
  }, [selectedTabIndex, startDate, endDate]);

  if (data) {
    statsRef.current = data;
  }

  const partnerErrorCodeDocUrl = statsRef.current?.statsMetadata?.partnerErrorCodeDocUrl;

  const handleDatesChange = ({ startDate, endDate }) => {
    setFilter({
      startDate,
      endDate,
    });
  };

  return (
    <div className="AudienceActivity t-dataExchangeGraph">
      <div className="DataExchangeHeader flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Heading kind="h3" className="inline-block" title={i18n.t('audiences:dataExchange')} />
          <Icons icon="info" tooltip={i18n.t('audiences:statistics.tooltip')} className="h-4 w-4 text-gray-500" />
        </div>
        <DateRangePicker
          testHook="dataExchangeDatePicker"
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={handleDatesChange}
        />
      </div>

      <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
        <TabList className="AudienceActivity-dataExchange--tabs">
          {integrations.map(integration => (
            <Tab
              key={integration.partnerDetails.partnerId}
              testHook="dataExchangeTab"
              className={cx({ 'DataExchangeGraph-tab--deactivated': !integration?.activated })}
            >
              {integration.name}
            </Tab>
          ))}
        </TabList>
        {integrations.length ? (
          <Panel>
            <div className="flex justify-end pb-4">
              {!selectedIntegration?.activated && (
                <StatusTag className="!relative" text={i18n.t('connectors:deactivated')} />
              )}
            </div>

            <div className="u-flex-column">
              <div className="u-md-size12of12">
                {error ? (
                  <FetchFail integrationName={selectedIntegration.name} />
                ) : (
                  <div className="DocumentationLink-chartContainer">
                    <StackedChart data={statsRef.current.stats} />
                    {partnerErrorCodeDocUrl && (
                      <div className="DocumentationLink-linkContainer">
                        <LinkIcon
                          icon="document"
                          url={partnerErrorCodeDocUrl}
                          tooltip={i18n.t('audiences:chart.documentationLink')}
                          target="_blank"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Panel>
        ) : (
          <Empty
            header={i18n.t('audiences:noIntegrationsTitle')}
            body={i18n.t('audiences:noIntegrationsMessage', { type })}
          />
        )}
      </Tabs>
    </div>
  );
};

export default DataExchangeGraph;
