import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const getTripolisContactGroupsURL = partnerId => `${API_BASE}/remote/partners/tripolis/${partnerId}/contactGroups`;

const getContactGroupsOptions = partnerId =>
  Api.callGet(getTripolisContactGroupsURL(partnerId), {
    shouldShowToast: false,
    shouldHandleCommonErrors: false,
  }).then(parseResponse);

export { getContactGroupsOptions };
