/**
 * A directive that will watch the svfocus attribute and when it evaluates to true, will set the focus to the element
 */
angular.module('webUi.directive.svfocus', [])
    .directive('svfocus', ['$timeout', function ($timeout) {
        var doFocus = function doFocus(el) {
            $timeout(function () {
                if (el.focus) {
                    el.focus();
                }
            }, 100);
        };
        var svFocus = {
            priority: 10000,
            transclude: false,
            restrict: 'A',
            compile: function compile() {
                return {
                    post: function postLink(scope, iElement, iAttrs) {
                        var observeFn = function (newValue) {
                            var evaluatedValue = scope.$eval(newValue);
                            // If newValue is not an object always focus on the first element
                            if (!_.isObject(evaluatedValue)) {
                                if (evaluatedValue) {
                                    doFocus(iElement[0]);
                                }
                            } else {
                                // In case the state is set and is true, check if a selector for focus is provided. If not focus on the 1st element
                                if (evaluatedValue.state) {
                                    if (evaluatedValue.focus) {
                                        var matches = iElement.find(evaluatedValue.focus);
                                        if (matches.length > 0) {
                                            doFocus(matches[0]);
                                        }
                                    } else {
                                        doFocus(iElement[0]);
                                    }
                                }
                            }
                        };
                        iAttrs.$observe('svfocus', observeFn);
                    }
                };
            }
        };

        return svFocus;
    }]);

