import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { PayloadTypeWithValue, RegionTypeWithValue } from './types';
import { mapPayloadTypes, mapRegions } from './utils';

const apiPath = `${API_BASE}/v1/partners/amazondsp`;

const fetchAmazonRegions = (redirectPath: string): Promise<RegionTypeWithValue[]> =>
  Api.callGet(`${apiPath}/regions?redirectUrl=${encodeURI(redirectPath)}`)
    .then(parseResponse)
    .then(mapRegions);

const fetchAmazonPayloadTypes = (): Promise<PayloadTypeWithValue[]> =>
  Api.callGet(`${apiPath}/payloadTypes`).then(parseResponse).then(mapPayloadTypes);

export { fetchAmazonRegions, fetchAmazonPayloadTypes };
