import { changeUrl, normalizeValue } from '~/common';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import * as types from './types';
import * as selectors from './selectors';
import ContextClientsListService from './dataService';

/**
 * Fetching Clients from the server
 * @returns {function(*)}
 */
export const fetchClients = () => (dispatch, getState) => {
  if (selectors.isFetchingClients(getState())) {
    return Promise.resolve();
  }

  dispatch({ type: types.CONTEXT_CLIENTS_FETCH_START });

  return ContextClientsListService.getAllClients()
    .then(response => {
      dispatch({
        type: types.CONTEXT_CLIENTS_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch(error =>
      dispatch({
        type: types.CONTEXT_CLIENTS_FETCH_FAIL,
        error,
      }),
    );
};

/**
 *
 * @param clientId
 * @returns {function(*, *)}
 */
export const fetchClient = clientId => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_FETCH_START });
  return ContextClientsListService.getClient(clientId)
    .then(response => {
      dispatch({
        type: types.CONTEXT_CLIENT_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_FETCH_FAIL, error }));
};

/**
 *
 * @param client
 * @returns {function(*)}
 */
export const saveClient = client => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_SAVE_START });
  const apiRateLimit = client.apiRateLimit || types.DEFAULT_API_RATE_LIMIT;
  const model = {
    ...client,
    name: normalizeValue(client.name),
    passwordPolicy: client.clientId ? client.passwordPolicy : types.DEFAULT_PASSWORD_POLICY,
    externalAuthentication: client?.externalAuthentication?.enabled ? client.externalAuthentication : null,
    apiRateLimit,
  };

  if (model.mappingRetentionMonths === null) {
    delete model.mappingRetentionMonths;
  }

  return ContextClientsListService.saveClient(model)
    .then(payload => {
      dispatch({
        type: types.CONTEXT_CLIENT_SAVE_SUCCESS,
        payload,
      });
      changeUrl(`context/clients/view/${payload.clientId}`);
    })
    .catch(error =>
      dispatch({
        type: types.CONTEXT_CLIENT_SAVE_FAIL,
        error,
      }),
    );
};

/**
 * Check if we already have the data to avoid many server calls.
 * @returns {function()}
 */
export const fetchClientsIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchClients(getState())) {
    return dispatch(fetchClients());
  }
  return Promise.resolve();
};
/**
 *
 * @param clientId
 * @returns {Function}
 */
export const fetchClientIfNeeded = clientId => (dispatch, getState) => {
  /**
   * fetching client information if needed.
   */
  if (selectors.byClientId(getState(), clientId)) {
    return Promise.resolve();
  }
  return dispatch(fetchClient(clientId));
};

export const deleteClient = clientId => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_DELETE_START });
  return ContextClientsListService.deleteClient(clientId)
    .then(() => {
      showSuccess({ body: i18n.t('clients:client.api.deletionSuccess') });
      changeUrl('context/clients/list/');
      dispatch({
        type: types.CONTEXT_CLIENT_DELETE_SUCCESS,
        payload: { clientId },
      });
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_DELETE_FAIL, error }));
};
/**
 *
 * @param clientId
 * @param passwordPolicy
 * @returns {Function}
 */
export const updatePasswordPolicy = (clientId, passwordPolicy) => (dispatch, getState) => {
  const model = {
    ...passwordPolicy,
    charSet: passwordPolicy.charSet || null,
  };

  dispatch({ type: types.CONTEXT_CLIENT_SAVE_START });
  return ContextClientsListService.updatePaswwordPolicy(clientId, model)
    .then(() => {
      dispatch({
        type: types.CONTEXT_CLIENT_SAVE_SUCCESS,
        payload: {
          ...selectors.byClientId(getState(), clientId),
          passwordPolicy: {
            ...model,
            invalidatePasswords: false,
          },
        },
      });
      changeUrl(`context/clients/view/${clientId}`);
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_SAVE_FAIL, error }));
};
