import React from 'react';
import InputElement from '~/components/src/Form/Elements/InputElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import { TElement } from '../types';

const NumberInput = ({ value, onChange, onBlur, placeholder, errorText, autoFocus }: TElement) => (
  <FieldWrapper errorText={errorText}>
    <InputElement
      type="number"
      value={value}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur}
      autoFocus={autoFocus}
      placeholder={placeholder}
      hasError={!!errorText}
    />
  </FieldWrapper>
);

export default NumberInput;
