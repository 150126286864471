import React from 'react';
import PropTypes from 'prop-types';
import { components } from '~/components/src/Table';
import Link from '~/components/src/Link';

const WIDTHS = {
  TYPE: 'u-size3of10',
  PHONE: 'u-size3of10',
  DEFAULT: 'u-size2of10',
};

const authenticationTypeConst = {
  SMS: 'SMS',
  GOOGLE_AUTH: 'GOOGLE_AUTH',
};
const { Row, Cell, RowActions } = components;

const optionsDefault2FA = (user, t, authenticationType, makeDefault2FA) => {
  const valueSecondStep = (user.default2FA && user.default2FA.name) || '';
  const defaultLabel = <span> {t('settings:securitySettings.default2FA.default')} </span>;
  const makeDefaultLabel = <span> {t('settings:securitySettings.default2FA.makeDefault')} </span>;

  const { SMS, GOOGLE_AUTH } = authenticationTypeConst;
  let secondStepText;
  switch (authenticationType) {
    case SMS:
      secondStepText =
        valueSecondStep === authenticationType ? (
          defaultLabel
        ) : (
          <Link onClick={() => makeDefault2FA(authenticationType)}>{makeDefaultLabel}</Link>
        );
      break;
    case GOOGLE_AUTH:
      secondStepText =
        valueSecondStep === authenticationType ? (
          defaultLabel
        ) : (
          <Link onClick={() => makeDefault2FA(authenticationType)}>{makeDefaultLabel}</Link>
        );
      break;
    default:
  }
  return secondStepText;
};

export const RowTwoFactorAuth = ({
  makeDefault2FA,
  user,
  t,
  actions,
  hasTwoFactorEnabled,
  nameType,
  authenticationType,
  authenticationValue,
}) => (
  <div>
    <Row withActions>
      <Cell className={WIDTHS.TYPE}>{nameType}</Cell>
      <Cell className={WIDTHS.PHONE}>{authenticationValue}</Cell>
      <Cell className={WIDTHS.DEFAULT}>
        {hasTwoFactorEnabled && optionsDefault2FA(user, t, authenticationType, makeDefault2FA)}
      </Cell>
      {!hasTwoFactorEnabled && (
        <RowActions
          actions={[
            {
              name: t('settings:securitySettings.actions.add'),
              tooltip: t('settings:securitySettings.actions.add'),
              isDisabled: false,
              icon: 'add',
              onClick: () => actions.onAdd(),
              testHook: 'addTimebasedToken',
            },
          ]}
        />
      )}
      {hasTwoFactorEnabled && (
        <RowActions
          actions={[
            {
              name: t('settings:securitySettings.actions.reset'),
              tooltip: t('settings:securitySettings.actions.reset'),
              isDisabled: false,
              icon: 'refresh',
              onClick: () => actions.onReset(),
              testHook: 'deleteTimebasedToken',
            },
            {
              name: t('settings:securitySettings.actions.delete'),
              tooltip: t('settings:securitySettings.actions.delete'),
              isDisabled: false,
              icon: 'delete',
              onClick: () => actions.onRemove(authenticationValue, t),
              testHook: 'deleteTimebasedToken',
              type: 'delete',
            },
          ]}
        />
      )}
    </Row>
  </div>
);

RowTwoFactorAuth.propTypes = {
  user: PropTypes.object.isRequired,
  hasTwoFactorEnabled: PropTypes.bool.isRequired,
  authenticationType: PropTypes.string.isRequired,
  authenticationValue: PropTypes.string.isRequired,
  nameType: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  makeDefault2FA: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default RowTwoFactorAuth;
