import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-grid-system';
import cx from 'classnames';
import Spinner from '../Spinner';
import './styles.scss';

const Page = ({ isReady = true, testHook = 'page', className, children, excludeContainer = false }) => {
  const contentPart = isReady ? children : <Spinner />;
  return (
    <div className={cx('Page', className, `t-${testHook}`)}>
      {excludeContainer ? contentPart : <Container>{contentPart}</Container>}
    </div>
  );
};

Page.propTypes = {
  isReady: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.node,
  testHook: PropTypes.string,
};

export default Page;
