import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import i18n from '~/i18n';
import { useAPI } from '~/common';
import { FormikCheckboxField, FormikInputField, FormikTextAreaField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import Spin from '~/components/src/Spin';
import { fetchTagCounterNumbers, fetchtagCounterById } from '../dataService';
import { getNextAvailableCounterNumber } from '../utils';
import { TTagCountersForm, TTagCountersFormProps } from '../types';

const TagCounterForm = ({ counterId, onSubmit, setShowForm }: TTagCountersFormProps) => {
  const isEdit = !!counterId;
  const { data: tagCounterNumbers, isLoading } = useAPI(() => fetchTagCounterNumbers());
  const { data: tagCounter, isLoading: isLoadingTagCounter } = useAPI(() =>
    isEdit ? fetchtagCounterById(counterId) : Promise.resolve({} as TTagCountersForm),
  );

  const numbers: number[] = Object.values(tagCounterNumbers || []) ?? [];
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    number: Yup.number()
      .test(
        'number-is-unique',
        i18n.t('tagCounters:form.validations.number'),
        (value: any) => !numbers.includes(value) || (isEdit && value === tagCounter?.number),
      )
      .required(i18n.t('validation:validation.required')),
    description: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  const initialValue = tagCounter || {
    name: '',
    number: getNextAvailableCounterNumber(numbers),
    description: '',
    shownOnDashboard: false,
  };

  if (isLoading || isLoadingTagCounter) return <Spin />;
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValue}
      enableReinitialize={true}
    >
      {({ handleSubmit, isValid, errors, isSubmitting, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="p-2 py-4">
          <Field
            className="mb-3"
            name="name"
            as={FormikInputField}
            label={i18n.t('tagCounters:form.fields.name')}
            placeholder={i18n.t('tagCounters:form.fields.placeholder')}
            errorText={errors?.name}
            autoFocus={true}
          />

          <Field
            className="mb-3"
            type="number"
            name="number"
            as={FormikInputField}
            label={i18n.t('tagCounters:form.fields.counterNumber')}
            placeholder={i18n.t('tagCounters:form.fields.placeholder')}
            errorText={errors?.number}
          />
          <Field
            className="mb-3"
            name="description"
            as={FormikTextAreaField}
            label={i18n.t('tagCounters:form.fields.description')}
            placeholder={i18n.t('tagCounters:form.fields.placeholder')}
            errorText={errors?.description}
          />

          <Field
            name="shownOnDashboard"
            className="mt-3 inline-flex flex-row-reverse gap-3"
            labelClassName="!mt-0.5"
            as={FormikCheckboxField}
            label={i18n.t('tagCounters:form.fields.showOnDashboard')}
            onChange={() => setFieldValue('shownOnDashboard', !values.shownOnDashboard)}
            errorText={errors.shownOnDashboard}
          />

          <ActionButtons
            testHook="counter"
            isConfirmEnabled={isValid && !isSubmitting}
            onConfirm={handleSubmit}
            onDecline={() => {
              setShowForm(false);
            }}
          />
        </form>
      )}
    </Formik>
  );
};

export default TagCounterForm;
