import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { mapValues } from 'lodash';
import { store } from '~';
import  i18n from '~/i18n';
import { I18nextProvider } from 'react-i18next';


export function helperAngularReactService () {
    return {
        resetStore: () => store.dispatch({
            type: 'USER_CHANGE_SITE',
        })
    };
}

function render(element, Component, props) {
    ReactDOM.render(
        <Component { ...props } />,
        element
    );
}

function renderWithProvider(element, Component, props) {
    ReactDOM.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18n} initialLanguage={'en'}>
                <Component { ...props } />
            </I18nextProvider>
        </Provider>,
        element
    );
}

function toBindings(propTypes) {
    return mapValues(propTypes, () => '<');
}

function toProps(propTypes, controller) {
    return mapValues(propTypes, (val, key) => {
        return controller[key];
    });
}

export function getAngularService(document, name) {
    const injector = angular.element(document.body).injector();
    return injector && injector.get(name);
}

export function reactToAngularComponent(Component, withProvider) {
    const { propTypes = {} } = Component;
    const renderFn = withProvider ? renderWithProvider : render;

    controller.$inject = ['$element'];
    function controller($element) {
        this.$onChanges = () => renderFn($element[0], Component, toProps(propTypes, this));
        this.$onDestroy = () => ReactDOM.unmountComponentAtNode($element[0]);
    }

    return {
        bindings: toBindings(propTypes),
        controller: controller
    };
}
