import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, numberRange, isUnique, isUrl } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class ClangForm extends Component {
  constructor(props) {
    super(props);
    this.partnerRangeValidation = props.isBatch ? numberRange(1, 9999) : numberRange(2011, 3000);
    this.isPartnerUnique = isUnique(
      this.props.connectors,
      'partnerNumber',
      this.props.t('form.notification.isPartnerUniqueError'),
    );
  }
  componentDidMount() {
    const { fetchIdentifierTypes, fetchEndpoints } = this.props;
    fetchIdentifierTypes(constants.CLANG);
    fetchEndpoints(constants.CLANG);
  }

  render() {
    const { t, change, endpoints, identifierTypes, values, partner, canUpdate, isBatch, touch } = this.props;

    const getPartnerNumber = value =>
      isBatch ? constants.CLANG_BATCH_PARTNER_NUMBER[value] : constants.CLANG_PARTNER_NUMBERS[value];

    return (
      <FormSection name={isBatch ? constants.partnerTypes.CLANG_BATCH : constants.partnerTypes.CLANG}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          <FieldWithEditMode
            label={t('form.formFields.token')}
            name="clangToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={canUpdate}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          {isBatch ? (
            <Field
              label={t('form.formFields.endpoint')}
              name="clangUrl"
              component={ReduxFormInputField}
              validate={isUrl}
            />
          ) : (
            <Field
              label={t('form.formFields.endpoint')}
              name="endpoint"
              component={ReduxFormSelectField}
              options={endpoints}
              disabled={canUpdate}
              validate={isRequired}
              touch={touch}
            />
          )}
          <Field
            label={t('form.formFields.identifierType')}
            name="identifierType"
            component={ReduxFormSelectField}
            options={identifierTypes}
            disabled={canUpdate}
            validate={isRequired}
            onChange={(e, value) =>
              change(isBatch ? 'CLANG_BATCH.partnerNumber' : 'CLANG.partnerNumber', getPartnerNumber(value))
            }
            touch={touch}
          />
          {!canUpdate && (
            <>
              <Field
                label={t('form.formFields.advancedSettings')}
                name="advancedSettings"
                component={ReduxFormCheckboxField}
                options={identifierTypes}
                disabled={canUpdate}
              />
              {values.advancedSettings && (
                <Field
                  label={t('form.formFields.partnerNumber')}
                  name="partnerNumber"
                  component={ReduxFormInputField}
                  validate={[isRequired, this.partnerRangeValidation, this.isPartnerUnique]}
                  type="text"
                  disabled={canUpdate}
                />
              )}
            </>
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default ClangForm;
