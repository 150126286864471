angular.module('webUi.ui.context.clients.view', ['webUi.ui.context.clients.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.clients.view', {
                url: '/view/{clientId}',
                isReact: true,
            }).state('site.context.clients.view.domain', {
                url: '/domain',
                isReact: true
            }).state('site.context.clients.view.domain.new', {
                url: '/new',
                isReact: true
            }).state('site.context.clients.view.domain.edit', {
                url: '/edit/{clientDomainId}',
                isReact: true
            }).state('site.context.clients.view.sitestats', {
                parent: 'site.context.clients.view',
                url: '/sitestats',
                isReact: true
            }).state('site.context.clients.view.passwordPolicy', {
                parent: 'site.context.clients.view',
                url: '/passwordpolicy',
                isReact: true
            });
    }]);
