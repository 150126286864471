import React from 'react';
import BtnIcon from '~/components/src/BtnIcon';
import icons from '~/components/src/Icons/icons';
import i18n from '~/i18n';
import { HP_STATUS } from '~/workflows/constants';
import { HPWorkflowTypes } from '../constants';
import { TriggerHistoricProcesingButtonProps } from '../types';

const TriggerHistoricProcesingButton = ({
  hpJobData,
  eligible,
  isHpRunning,
  isHpScheduled,
  canEdit,
  workflowType = HPWorkflowTypes.Journey,
  setShowHistoricProcessingModal,
  setShowHistoricProcessRunningModal,
}: TriggerHistoricProcesingButtonProps): React.ReactElement => {
  const isHpCancelling = hpJobData.state?.name === HP_STATUS.CANCELLING;
  const isHpCancelFailed = hpJobData.state?.name === HP_STATUS.CANCELLATION_FAILED;

  let historicProcessingTooltip = '';

  if (isHpRunning || isHpScheduled) {
    historicProcessingTooltip = i18n.t('workflow:orchestration.tooltips.journeyQueuedForHistoricProcessing', {
      workflowType,
    });
  } else if (isHpCancelling) {
    historicProcessingTooltip = i18n.t('workflow:orchestration.tooltips.hpJobCancelling');
  } else if (isHpCancelFailed) {
    historicProcessingTooltip = i18n.t('workflow:orchestration.tooltips.hpCancelFailed');
  } else if (eligible) {
    historicProcessingTooltip = i18n.t('workflow:orchestration.tooltips.triggerHistoricProcessing');
  } else {
    historicProcessingTooltip = i18n.t('workflow:orchestration.tooltips.publishStartTriggerChangeFirst');
  }

  let icon: keyof typeof icons = 'historicProcessing';
  if (isHpRunning) icon = 'hourglass';
  if (isHpScheduled) icon = 'schedule';
  if (isHpCancelling) icon = 'retract';
  if (isHpCancelFailed) icon = 'unpublished';

  if (isHpRunning || isHpScheduled || isHpCancelFailed) {
    return (
      <BtnIcon
        icon={icon}
        color={isHpCancelFailed ? 'red' : 'amber'}
        onClick={() => setShowHistoricProcessRunningModal(true)}
        testHook={`triggerHpButton-${icon}Icon`}
        tooltip={i18n.t('workflow:orchestration.tooltips.journeyQueuedForHistoricProcessing', { workflowType })}
      />
    );
  }

  return (
    <>
      <BtnIcon
        disabled={!canEdit || !eligible}
        icon={icon}
        onClick={() => setShowHistoricProcessingModal(true)}
        testHook={`triggerHpButton-${icon}Icon`}
        tooltip={
          canEdit ? historicProcessingTooltip : i18n.t('workflow:orchestration.permissions.notEnoughPermissions')
        }
      />
    </>
  );
};

export default TriggerHistoricProcesingButton;
