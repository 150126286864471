import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import Link from '~/components/src/Link';
import Empty from '~/components/src/Empty';
import { components, enhancements } from '~/components/src/Table';
import { Panel } from '~/components/src/Containers';
import './ClientList.scss';

const { Table, Row, Cell, Selection, SelectionCell, RowActions } = components;
const { withSort, withPaginate, withFilter, withEmpty, withSelectables } = enhancements;

const EmptyList = ({ t, emptyBody = t('clients:list.empty.body') }) => (
  <Empty header={t('clients:list.empty.header')} body={emptyBody} />
);

const NoResults = ({ t }) => (
  <Empty header={t('clients:list.noResult.header')} body={t('clients:list.noResult.body')} />
);

const props = (className, sortKey, title, sortFn) => ({
  className,
  sortKey,
  title,
  sortFn,
});

const COLUMNS_WIDTH = {
  NAME: 'u-size6of8',
  NUMBER: 'u-size2of8',
};
const COLUMN_NAME = { NAME: 'name', NUMBER: 'clientNumber' };

const ConditionalLink = ({ value = 'name', url, item }) =>
  url && isFunction(url) ? <Link href={url(item)}>{item[value]}</Link> : <span>{item[value]}</span>;

/**
 * Client List component
 * [stateless]
 * @param list
 * @param stateKey
 * @param clientUrl
 * @param onDelete
 * @param t
 * @returns {*}
 * @constructor
 */
export const ClientList = ({ isSelectable = false, list, stateKey, clientUrl, onDeleteClick, t }) => {
  const renderActions = item => {
    const actions = [
      {
        name: t('common:actions.remove'),
        tooltip: t('common:actions.remove'),
        isDisabled: false,
        icon: 'delete',
        onClick: () => onDeleteClick(item),
        testHook: 'delete',
        type: 'delete',
      },
    ];
    return onDeleteClick && <RowActions actions={actions} />;
  };

  return (
    <Panel>
      <Table stateKey={stateKey} isSelectable={isSelectable}>
        <Row className="ClientList-tableHeader">
          <SelectionCell />
          <SortableHeader
            {...props(`${COLUMNS_WIDTH.NUMBER}`, `${COLUMN_NAME.NUMBER}`, t('clients:list.column.number'))}
            sortFn={numberSort('clientNumber')}
          />
          <SortableHeader {...props(`${COLUMNS_WIDTH.NAME}`, `${COLUMN_NAME.NAME}`, t('clients:list.column.name'))} />
        </Row>
        {list.map(item => (
          <Row key={item.clientId} id={item.clientId} withActions>
            <SelectionCell>
              <Selection id={item.clientId} />
            </SelectionCell>
            <Cell className={COLUMNS_WIDTH.NUMBER}>
              <ConditionalLink url={clientUrl} item={item} value="clientNumber" />
            </Cell>
            <Cell className={COLUMNS_WIDTH.NAME}>
              <ConditionalLink url={clientUrl} item={item} />
            </Cell>
            {renderActions(item)}
          </Row>
        ))}
      </Table>
    </Panel>
  );
};

ClientList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  emptyBody: PropTypes.string,
  isSelectable: PropTypes.bool,
  clientUrl: PropTypes.func,
  onDeleteClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default compose(
  withSelectables(),
  withEmpty({ component: EmptyList }),
  withSort(),
  withFilter(),
  withEmpty({ component: NoResults }),
  withPaginate({ size: 25 }),
)(ClientList);
