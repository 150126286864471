angular.module('webUi.ui.tagmanagement.tags.conditions.pageProperty', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormPagePropertyConditionController',
        ['$scope', 'TagmanagementService','Utils',
            function TagmanagementTagFormPagePropertyConditionController ($scope, TagmanagementService,  Utils) {

                TagmanagementService.getMatchTypes().then(function(matchTypes) {
                    $scope.matchTypes = Utils.objectMap(matchTypes, 'name');
                });


                var conditionClazz = 'PagePropertyCondition';

                $scope.state.conditions = $scope.getConditions(conditionClazz);

                $scope.newCondition = function() {
                    $scope.state.formCondition = {
                        clazz: conditionClazz,
                        mode: 'new'
                    };
                };

                /**
		 * Check whether we should show a propertyValue input field (with validation) based on the allowed values of the matchType in this form
		 * @param inArray - the array of matchType values that would trigger showing a particular propertyValue input field
		 */
                $scope.showPropertyValue = function(inArray) {
                    var matchType = $scope.state.formCondition.matchType;
                    if(!matchType) {
                        return false;
                    }
                    // matchType can be either literal string value or enum object, so check both
                    return _.contains(inArray, matchType.name) || _.contains(inArray, matchType);
                };

                $scope.selectCondition = function(condition) {
                    $scope.state.formCondition = angular.copy(condition);
                    $scope.state.formCondition.mode = 'edit';
                    $scope.state.formCondition.matchTypeIdx = _.findIndex($scope.matchTypes, condition.matchType);
                };

                /**
         * Whenever the match type changes, clear the property value
         */
                $scope.clearConditionValue = function () {
                    $scope.state.formCondition.value = undefined;
                };
            }
        ]);

