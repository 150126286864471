import PropTypes from 'prop-types';
import React from 'react';
import Step from './Step';
import './styles.scss';

const Pagination = ({ paginatedLists, currentPage, get, onPaginate }) => {
  if (paginatedLists.length < 2) {
    return null;
  }

  return (
    <div className="Pagination t-pagination">
      {paginatedLists.map((step, key) => {
        const props = {
          key,
          step,
          currentPage,
          get,
          page: key,
          onPaginate,
        };
        return <Step {...props} />;
      })}
    </div>
  );
};

Pagination.propTypes = {
  paginatedLists: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  get: PropTypes.func,
  onPaginate: PropTypes.func.isRequired,
};

export default Pagination;
