import Api, { API_BASE } from '~/common/ApiService';

const apiPath = `${API_BASE}/v1/partners/doubleClickOffline`;

export const fetchFloodlightActivities = partnerId =>
  Api.callGet(`${apiPath}/${partnerId}/floodlightActivities`, {
    shouldShowToast: false,
  }).then(response => response.data);

export const createFloodlightActivity = (partnerId, activityFields, t) =>
  Api.callPost(`${apiPath}/${partnerId}/floodlightActivities`, activityFields, {
    toastText: t('connectors.doubleClickOffline.floodlightActivityCreationError'),
  }).then(response => response.data);

export const fetchUserDefinedVariables = partnerId =>
  Api.callGet(`${apiPath}/${partnerId}/floodlightUserDefinedVariables`, {
    shouldShowToast: false,
  }).then(response => response.data.map(({ variableType }) => ({ id: variableType, title: variableType })));

export const fetchCountingMethods = t =>
  Api.callGet(`${apiPath}/countingMethods`, {
    toastText: t('connectors.doubleClickOffline.countingMethodsApiError'),
  }).then(response => response.data);

export const fetchTagTypes = t =>
  Api.callGet(`${apiPath}/tagTypes`, {
    toastText: t('connectors.doubleClickOffline.tagTypesApiError'),
  }).then(response => response.data);
