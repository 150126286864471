import React, { MouseEvent, ReactElement } from 'react';
import { R42Google, R42GoogleDisabled } from '~/components/src/R42Icons';
import './styles.scss';
import Btn from '../Btn';

type GoogleButtonProps = {
  disabled?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  label: string;
  testHook?: string;
};

function GoogleButton(props: GoogleButtonProps): ReactElement {
  return (
    <Btn
      disabled={props.disabled}
      onClick={props.onClick}
      testHook={props.testHook || 'googleButton'}
      color="blue"
      className="flex flex-1 items-center gap-2"
    >
      <>
        {props.disabled ? R42GoogleDisabled() : R42Google()}
        {props.label}
      </>
    </Btn>
  );
}

export default GoogleButton;
