import React from 'react';
import { BaseFormSection } from '~/components/src/Containers';
import './styles.scss';

export const ConnectorHeaderFormSection = ({ logo, title, description, children }) => (
  <BaseFormSection
    className="ContainerHeader"
    left={
      <>
        {logo}
        {title && <h4 className="ContainerHeader-title t-title">{title}</h4>}
        {description && <p className="t-description">{description}</p>}
      </>
    }
  >
    {children}
  </BaseFormSection>
);
