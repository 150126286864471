import React from 'react';
import Icon from 'react-icon-base';

const R42Partners = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g className="nc-icon-wrapper" fill="currentColor">
      <path d="M23 6.393h-2.062a2.081 2.081 0 0 0-.9.211.25.25 0 0 1-.2.007l-4.3-1.645a3.324 3.324 0 0 0-2.574.143l-2.123 1.03a.251.251 0 0 1-.241-.009 2.978 2.978 0 0 0-2.641-.2L4.966 7.206a.249.249 0 0 1-.288-.067 2.123 2.123 0 0 0-1.616-.746H1a.75.75 0 0 0-.75.75v9.5a.75.75 0 0 0 .75.75h2.062a2.122 2.122 0 0 0 1.73-.895.253.253 0 0 1 .2-.105h.931a.255.255 0 0 1 .155.053l2.794 2.2.022.019a1.936 1.936 0 0 0 2.6.123l3.057-2.508a.254.254 0 0 1 .11-.052l3.895-.779a.251.251 0 0 1 .19.039.254.254 0 0 1 .105.163 2.115 2.115 0 0 0 2.085 1.743H23a.75.75 0 0 0 .75-.75v-9.5a.75.75 0 0 0-.75-.751zm-8.216 7.113l-1.133.947-.023.017a.94.94 0 0 0-.123.1l-.009.008-3.038 2.541a.22.22 0 0 1-.276 0l-3.2-2.521a1 1 0 0 0-.619-.213h-.925a.249.249 0 0 1-.25-.25V9.45a.248.248 0 0 1 .152-.229l3.378-1.439h.006a.225.225 0 0 1 .3.267 2.629 2.629 0 0 0 .1 1.643 2.662 2.662 0 0 0 1.415 1.462 2.634 2.634 0 0 0 1.049.216 2.706 2.706 0 0 0 .993-.189l.158-.065a.25.25 0 0 1 .28.064l1.788 1.971a.249.249 0 0 1-.024.36zm4.028-.353a.25.25 0 0 1-.2.245l-1.312.262a.246.246 0 0 1-.21-.054.25.25 0 0 1-.089-.2 2.03 2.03 0 0 0-.441-1.306l-.013-.016-2.636-2.9a1.071 1.071 0 0 0-1.12-.254l-.958.393a.65.65 0 0 1-.844-.366.642.642 0 0 1 .3-.812L13.845 6.9a1.322 1.322 0 0 1 .99-.063l3.84 1.47a.2.2 0 0 1 .13.19z" />
    </g>
  </Icon>
);

export default R42Partners;
