import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import Checkbox from '../Elements/Checkbox';

export * from '../Elements/defaultSizes';

const CheckboxRow = ({ meta, input, label, hint, disabled }) => (
  <FormRow disabled={true} name={input.name} label="" meta={meta}>
    <Checkbox disabled={disabled} input={input} id={input.name} isError={meta.touched && !!meta.error}>
      {label}
    </Checkbox>
    <span className="Form-hint u-block u-marginTopS">{hint}</span>
  </FormRow>
);

CheckboxRow.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.any.isRequired,
  hint: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default CheckboxRow;
