/* eslint-disable no-prototype-builtins */
/**
 * Service methods to use for validation
 */
angular.module('webUi.service.template', [])

    .factory('TemplateService', [function TemplateService() {

        var INPUT = 'input';
        var EMAIL = 'email';
        var SELECT = 'select';
        var TEXTAREA = 'textarea';
        var CHECKBOX = 'checkbox';
        var DATE = 'date';
        var PASSWORD = 'password';
        var CUSTOM = 'custom';
        var HIDDEN_PASSWORD = 'hidden-password';
        var ATTRS_TO_COPY = ['class', 'ngClass', 'style'];

        /**
     * Replace potential indices in between square brackets with the actual values of the indices
     * E.g. replace [indexCriterion] with [0]
     * @param modelStr
     * @param scope
     * @returns {*}
     */
        var processModel = function processModel(modelStr, scope) {
            var temp = modelStr;
            var OPEN = '[';
            var CLOSE = ']';
            var fromIndex = 0;
            while (!_.isEmpty(modelStr)) {
                var openBracketIdx = _.indexOf(modelStr, OPEN, fromIndex);
                var closeBracketIdx = _.indexOf(modelStr, CLOSE, fromIndex);
                if (openBracketIdx > -1 && closeBracketIdx > -1) {
                    var indexName = modelStr.substring(openBracketIdx + 1, closeBracketIdx);
                    //todo mihai - evaluate against parent scopes as well (Utils.getValue) in case this scope doesn't have the value
                    var indexValue = scope[indexName];
                    temp = temp.replace(indexName, indexValue);
                } else {
                    break;
                }
                fromIndex = closeBracketIdx + 1;
            }

            return temp;
        };

        /**
     * Retrieves the id that should be placed on an element based on its model name
     * @param modelName
     */
        var processId = function processId(modelName) {
        //if the input starts with "vm.", remove it
            var id = modelName.replace(/^vm./, '');
            //replace every . in the input with a -
            return id.replace(/\./g, '-');
        };

        var copyModelToInput = function (input, attrs, scope) {
            var model = attrs['ngModel'];
            model = processModel(model, scope);
            input.attr('data-ng-model', model);
            input.attr('name', model);
            input.attr('id', processId(model));
        };



        var copyGeneralAttrsToInput = function (input, attrs) {
            var classInput = attrs['inputClass'] || 'input-xlarge';
            if ( attrs['disabled']) {
                classInput += ' '+'disabled';
            }

            input.attr('placeholder', attrs['placeholder'] || 'Please provide a value');
            input.attr('class', classInput);
            input.attr('autocomplete', attrs['autocomplete'] || 'on');
            //TODO check how to pass custom classes to generated inputs
            input.attr('data-svfocus', attrs['svfocus'] || undefined);
            input.attr('data-ng-focus', attrs['ngFocus'] || undefined);
            input.attr('readonly', attrs['readonly'] || undefined);
            input.attr('data-ng-disabled', attrs['ngDisabled'] || undefined);

            if (attrs['ngList']) {
                input.attr('data-ng-list', attrs['ngList'] || '');
            }

            if (_.isFunction(attrs.$observe)) {
                attrs.$observe('disabled', function (isDisabled) {
                    if (isDisabled) {
                        (input[0]).classList.add('disabled');
                    } else {
                        (input[0]).classList.remove('disabled');
                    }
                });
            }
        };

        var copyTypeaheadAttrsToInput = function (input, attrs) {
            if (attrs['uibTypeahead']) {
                input.attr('data-uib-typeahead', attrs['uibTypeahead']);
                input.attr('data-typeahead-wait-ms', attrs['typeaheadWaitMs'] || undefined);
                input.attr('data-typeahead-append-to-body', attrs['typeaheadAppendToBody'] || undefined);
                input.attr('data-typeahead-editable', attrs['typeaheadEditable'] || undefined);
                input.attr('data-typeahead-popup-template-url', attrs['typeaheadPopupTemplateUrl'] || undefined);
                input.attr('data-typeahead-no-results', attrs['typeaheadNoResults'] || undefined);
                input.attr('data-typeahead-select-on-blur', attrs['typeaheadSelectOnBlur'] || undefined);
            }
        };

        var copyUiKeypressAttrsToInput = function (input, attrs) {
            input.attr('data-ui-keypress', attrs['uiKeypress'] || undefined);

        };

        var copyValidationAttrsToInput = function (input, attrs) {
        //validation, including pattern, required, uiValidate
            input.attr('data-ng-required', attrs['ngRequired'] || undefined);
            input.attr('data-ui-validate', attrs['uiValidate'] || undefined);
            input.attr('data-ng-pattern', attrs['ngPattern'] || undefined);
        };

        var copyEventHandlersToInput = function (input, attrs) {
            input.attr('data-ng-click', attrs['ngClick'] || undefined);
            input.attr('data-ng-change', attrs['ngChange'] || undefined);
            input.attr('data-ng-blur', attrs['ngBlur'] || undefined);
        };

        // INPUT
        var buildInput = function (attrs, scope, type) {
            var validationElem = $('<input type="' + type + '"/>');
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);
            copyTypeaheadAttrsToInput(validationElem, attrs);
            copyUiKeypressAttrsToInput(validationElem, attrs);
            copyValidationAttrsToInput(validationElem, attrs);

            copyEventHandlersToInput(validationElem, attrs);

            return validationElem;
        };
        var buildInputPassword = function (attrs, scope) {
        //read property from the attrs
            var input = $('<input type="{{showPassword ?' + '\'text\':\'password\'' + '}}"/>');
            copyModelToInput(input, attrs, scope);
            copyGeneralAttrsToInput(input, attrs);
            copyTypeaheadAttrsToInput(input, attrs);
            copyUiKeypressAttrsToInput(input, attrs);
            copyValidationAttrsToInput(input, attrs);
            copyEventHandlersToInput(input, attrs);
            var validationElem = $('<div class=\'password-input input-group\'><button class=\'btn\' type=\'button\' ' +
            'data-ng-click=\'showPassword =! showPassword\'>{{showPassword ? \'Hide\':\'Show\'}}</button></div>');

            var mockInput = $('<input type="password" style="display:none;" />');
            copyModelToInput(mockInput, attrs, scope);
            copyGeneralAttrsToInput(mockInput, attrs);

            mockInput.prependTo(validationElem);
            input.prependTo(validationElem);
            return validationElem;
        };

        var buildHiddenPassword = function (attrs, scope) {
            var fakeInput = $('<input data-ng-show="!editMode" type="password" readonly/>');
            fakeInput.attr('data-ng-model', 'vm.data.fakeInput');
            fakeInput.attr('name', 'vm.data.fakeInput');
            fakeInput.attr('id', 'data-fake-input');
            copyGeneralAttrsToInput(fakeInput, attrs);

            var realInput = $('<input data-ng-show="editMode" type="{{showPassword ?' + '\'text\':\'password\'' + '}}"/>');
            copyModelToInput(realInput, attrs, scope);
            copyGeneralAttrsToInput(realInput, attrs);
            copyTypeaheadAttrsToInput(realInput, attrs);
            copyUiKeypressAttrsToInput(realInput, attrs);
            copyValidationAttrsToInput(realInput, attrs);
            copyEventHandlersToInput(realInput, attrs);
            var validationElem = $('<div class=\'password-input input-group\'>' +
            '<button class=\'btn\' type=\'button\' data-ng-show=\'editMode\' data-ng-click=\'showPassword =! showPassword\'>{{showPassword ? \'Hide\':\'Show\'}}</button>' +
            '<button class=\'btn btn-info\' type=\'button\' data-ng-show=\'!editMode\' data-ng-click=\'editMode =! editMode; vm.data.partner.configuration.password = ""\'>Edit</button></div>');

            fakeInput.prependTo(validationElem);
            realInput.prependTo(validationElem);
            return validationElem;
        };

        // EMAIL
        var buildEmail = function (attrs, scope) {
            var validationElem = $('<input type="email"/>');
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);
            copyTypeaheadAttrsToInput(validationElem, attrs);
            copyUiKeypressAttrsToInput(validationElem, attrs);

            copyValidationAttrsToInput(validationElem, attrs);

            copyEventHandlersToInput(validationElem, attrs);

            return validationElem;
        };

        // SELECT
        var buildSelect = function (attrs, scope) {
            var validationElem;
            var opts = attrs['options'];
            if (opts) {
                scope.opts = scope.$eval(opts);
                var optionsKey = attrs['optionsKey'];
                var optionsValue = attrs['optionsValue'];
                if (optionsKey && optionsValue) {
                    validationElem = $('<select data-ng-model="model" ng-options="item.' + optionsKey + ' as item.' + optionsValue + ' for (key, item) in '+ opts + '">' +
                    '<option class="default-select-opt" value="">Select an option</option>' +
                    '</select>');
                }else {
                    validationElem = $('<select data-ng-model="model" ng-options="item for item in ' + opts + '">' +
                    '<option class="default-select-opt" value="">Select an option</option>' +
                    '</select>');
                }
            }else {
                validationElem = $('<select></select>');
            }
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);
            copyEventHandlersToInput(validationElem, attrs);
            copyValidationAttrsToInput(validationElem, attrs);
            copyEventHandlersToInput(validationElem, attrs);
            return validationElem;
        };

        var buildDatetimepicker = function (attrs, scope) {

            var validationElem = $('<datetimepicker></datetimepicker>');
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);

            validationElem.attr('data-show-time', _.isUndefined(attrs['showTime']) ? 'data-show-time' : '');
            //<datetimepicker data-ng-model="person.birthDate2" data-ng-change="onChange()" ></datetimepicker>

            copyValidationAttrsToInput(validationElem, attrs);
            copyEventHandlersToInput(validationElem, attrs);
            return validationElem;
        };

        // TEXTAREA
        var buildTextarea = function (attrs, scope) {
            var validationElem = $('<textarea></textarea>');
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);
            copyValidationAttrsToInput(validationElem, attrs);
            copyEventHandlersToInput(validationElem, attrs);
            return validationElem;
        };

        // CHECKBOX
        var buildCheckbox = function (attrs, scope) {
            var validationElem = $('<input type="checkbox"  />');
            copyModelToInput(validationElem, attrs, scope);
            copyGeneralAttrsToInput(validationElem, attrs);
            copyValidationAttrsToInput(validationElem, attrs);
            copyEventHandlersToInput(validationElem, attrs);
            return validationElem;
        };

        var buildCustom = function (attrs, scope) {
            var validationElem = $('<input type="hidden"/>');
            copyModelToInput(validationElem, attrs, scope);
            return validationElem;
        };

        var initTemplateScope = function initTemplateScope($injector, $scope, templateScope) {
            try {
                /*jslint evil: true */
                $scope.templateHelper = eval('(' + templateScope + ')');
            } catch (exception) {
                $scope.templateHelper = {};
            }

            $scope.templateHelper.$injector = $injector;
            $scope.templateHelper.$parent = $scope;

            // call init if applicable (view only)
            if ($scope.templateHelper.init) {
                $scope.templateHelper.init(true);
            }

            if ($scope.state) {
                $scope.state.templateHelper = $scope.templateHelper;
            }
        };

        return {

            INPUT: INPUT,
            SELECT: SELECT,
            TEXTAREA: TEXTAREA,
            CHECKBOX: CHECKBOX,
            CUSTOM: CUSTOM,
            PASSWORD: PASSWORD,
            HIDDEN_PASSWORD: HIDDEN_PASSWORD,
            DATE: DATE,

            initTemplateScope: initTemplateScope,

            copyAttributes: function (template, attrs) {
                if (!angular.isElement(template)) {
                    template = angular.element(template);
                }
                for (var attrName in attrs.$attr) {
                    if (attrs.$attr.hasOwnProperty(attrName) && _.contains(ATTRS_TO_COPY, attrName)) {
                        template.attr(attrs.$attr[attrName], attrs[attrName]);
                    }
                }
            },

            /**
         * Create the validation based on the type and the various attributes defined on the original element
         * @param scope
         * @param type - of type input, select, textarea, checkbox, or 'other'
         * @param attrs
         * @returns {*}
         */
            generateElement: function (type, attrs, scope) {
                var generatedElement;
                switch (type) {
                    case INPUT:
                        generatedElement = buildInput(attrs, scope, 'text');
                        break;
                    case PASSWORD:
                        generatedElement = buildInputPassword(attrs, scope);
                        generatedElement.find('input').addClass('r42-generated');
                        break;
                    case HIDDEN_PASSWORD:
                        generatedElement = buildHiddenPassword(attrs, scope);
                        generatedElement.find('input').addClass('r42-generated');
                        break;
                    case EMAIL:
                        generatedElement = buildEmail(attrs, scope);
                        break;
                    case SELECT:
                        generatedElement = buildSelect(attrs, scope);
                        break;
                    case TEXTAREA:
                        generatedElement = buildTextarea(attrs, scope);
                        break;
                    case CHECKBOX:
                        generatedElement = buildCheckbox(attrs, scope);
                        break;
                    case DATE:
                        generatedElement = buildDatetimepicker(attrs, scope);
                        break;
                    default:
                        generatedElement = buildCustom(attrs, scope);
                        break;
                }
                generatedElement.addClass('r42-generated');
                return generatedElement;
            }
        };
    }]);
