angular.module('webUi.directive.validation.valid', [])

    .directive('valid', ['PubSubService', 'TemplateService', 'ValidService', '$compile', function (PubSubService, TemplateService, ValidService, $compile) {

        return {
            restrict: 'AE',
            scope: true,
            link: function (scope, element, attrs) {
                var inputType = attrs['type'] || TemplateService.CUSTOM;

                function init() {
                    var generatedInput, inputName;
                    generatedInput = TemplateService.generateElement(inputType, attrs, scope);
                    if (inputType === TemplateService.PASSWORD || inputType === TemplateService.HIDDEN_PASSWORD) {
                        inputName = generatedInput.find('input').attr('name');
                    } else {
                        inputName = generatedInput.attr('name');
                    }

                    var childInput = element.find('.input-container input[name="' + inputName + '"]');
                    if (childInput && childInput.first()) {
                        childInput.remove();
                    }

                    $(generatedInput).addClass('r42-generated');

                    //only attach a generated input if there is a model defined on the element
                    if (inputName) {
                    //attach the input under the "input-container" class

                        var inputContainer = element.find('.input-container');
                        if (inputContainer.length > 0) {
                            inputContainer.prepend(generatedInput);
                        } else {
                            element.prepend(generatedInput);
                        }
                    }

                    $compile(generatedInput)(scope);

                    var formElement;
                    //try to get the innerForm first
                    if (element.closest('.innerForm').length > 0) {
                    //we're interested in the closest inner form identified by class
                        formElement = element.closest('.innerForm');
                    } else if (element.closest('[data-ng-form]').length > 0) {
                    //else we might be interested in the closest ngForm
                        formElement = element.closest('[data-ng-form]');
                    } else {
                    //otherwise just get the top form
                        formElement = element.closest('form');
                    }
                    var formName = formElement.attr('name');
                    //get the form from the scope by name
                    var currentForm = scope[formName];
                    var parentScope = scope.$parent;
                    while (_.isUndefined(currentForm) && !_.isUndefined(parentScope)) {
                    //as backup, check the parent hierarchy
                        currentForm = parentScope[formName];
                        parentScope = parentScope.$parent;
                    }
                    //by default don't show error on element
                    element.removeClass('error');

                    /**
                 * Listeners
                 */
                    var formInput = currentForm[inputName];
                    //initialize an error array for this field
                    formInput.$errors = formInput.$errors || [];

                    // re-evaluate the custom validation object and set the validation tokens accordingly on the form input object
                    function evalAndSetValidationTokens() {
                        var customValidation = scope.$eval(attrs['customValidate'], {'$value': formInput.$modelValue});
                        ValidService.setValidationTokens(customValidation, formInput, formInput.$modelValue, element);
                    }

                    if (inputType !== TemplateService.CUSTOM) {
                    //do onFocus and onBlur for everything except hidden inputs
                        element.find('.r42-generated').on('focus', function () {
                        //never show the error on focus
                            formInput.$showError = false;
                            element.removeClass('error');
                        });
                        element.find('.r42-generated').on('blur', function () {

                            // don't trigger validation on pristine form
                            if(formInput.$pristine) {
                                return;
                            }

                            evalAndSetValidationTokens();

                            //show error on blur if it's invalid
                            formInput.$showError = formInput.$invalid;
                            if (formInput.$showError) {
                                element.addClass('error');
                            }
                        });
                    } else {
                    //handle the hidden inputs
                        scope.$watch(attrs['customWatch'], function () {
                        //evaluate the custom validate attribute into an object
                            evalAndSetValidationTokens();
                        }, true);

                        //when the attribute customReset is changed, revert the validation state to empty and pristine
                        scope.$watch(attrs['customReset'], function () {
                            formInput.$setPristine();
                            evalAndSetValidationTokens();
                        });

                    }
                    PubSubService.subscribeFormValidate(scope, formName, function () {

                        //re-read the validation tokens, in case the value hadn't changed
                        evalAndSetValidationTokens();

                        //if the form element is invalid at submit time, show the error
                        if (formInput && formInput.$invalid) {
                            formInput.$showError = formInput.$invalid;
                            element.addClass('error');
                        }
                    });
                }

                //attrs.observe below doesn't trigger for undefined, so I'm calling it explicitly
                if(typeof attrs['type'] === 'undefined') {
                    init();
                }else {
                //register the observe anyway, if it does change value (hopefully not to undefined)
                    attrs.$observe('type', init);
                }

            }
        };
    }]);
