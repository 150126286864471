import { get, size } from 'lodash';
import * as dependencySelectors from '../dependencies/selectors';
/**
 * Get Engagement By Id
 * @param state
 * @param engagementId
 * @returns {*}
 */
export const getEngagementById = (state, engagementId) => get(state, `profiles.engagements.byId[${engagementId}]`);

/**
 * Get the currently selected engagement with its dependants info
 * @param state
 * @param engagementId
 */
export const getSelectedEngagement = (state, engagementId) => {
  const engagement = getEngagementById(state, engagementId);
  const engagementDependantsInfo = dependencySelectors.getDependantsInfoById(state, engagementId);
  return {
    ...engagement,
    dependantsInfo: engagementDependantsInfo,
  };
};

/**
 * Get All Engagements
 * @param state
 */
export const getEngagements = state => state.profiles.engagements.allIds.map(id => state.profiles.engagements.byId[id]);

/**
 * Get the names of all the engagements
 * @param state
 */
export const getAllEngagementNames = state =>
  state.profiles.engagements.allIds.map(id => state.profiles.engagements.byId[id].type);

/**
 * Get missed engagements
 * @param state
 */
export const getMissedEvents = state => state.profiles.engagements.missingEvents;

/**
 * Is engagement fetch currently in progress?
 * @param state
 */
export const isFetchingEngagement = state => get(state, 'profiles.engagements.ui.isFetchingEngagement');

/**
 * Is adding property in progress
 * @param state
 */
export const isPropertyBeingAdded = state => get(state, 'profiles.engagements.ui.isPropertyBeingAdded');

/**
 * Is fetching all engagements currently in progress?
 * @param state
 */
export const isFetchingEngagements = state => get(state, 'profiles.engagements.ui.isFetchingEngagements');

/**
 * Is engagement activity fetch currently in progress?
 * @param state
 */
export const isFetchingEngagementActivity = state => get(state, 'profiles.engagements.ui.isFetchingEngagementActivity');

/**
 * Is engagement properties fetch currently in progress?
 * @param state
 */
export const isFetchingEngagementProperties = state =>
  get(state, 'profiles.engagements.ui.isFetchingEngagementProperties');

/**
 * Is engagement traffic sources fetch currently in progress?
 * @param state
 */
export const isFetchingEngagementTrafficSources = state =>
  get(state, 'profiles.engagements.ui.isFetchingEngagementTrafficSources');

/**
 * Is fetching all engagement activities currently in progress?
 * @param state
 */
export const isFetchingEngagementActivities = state =>
  get(state, 'profiles.engagements.ui.isFetchingEngagementActivities');

/**
 * Is the dependants container visible?
 * @param state
 */
export const isDependantsContainerVisible = state => get(state, 'profiles.engagements.ui.dependantsContainerIsVisible');

/**
 * Get the currently selected engagement/property
 * @param state
 */
export const getSelectedId = state => get(state, 'profiles.engagements.ui.selectedId');

/**
 * Get the currently selected tab
 * @param state
 */
export const getSelectedTab = state => get(state, 'profiles.engagements.ui.selectedTab');

/**
 * Get the currently selected engagement/property
 * @param engagementId the engagement id
 * @param propertyId the property id, can be null
 * @param state
 */
export const getSelectedProperty = (state, engagementId, propertyId) => {
  if (engagementId === propertyId) {
    return getSelectedEngagement(state, engagementId);
  }
  const propertyDependantsInfo = dependencySelectors.getPropertyDependantsInfoById(state, engagementId, propertyId);
  return {
    name: propertyId,
    dependantsInfo: propertyDependantsInfo,
  };
};

/**
 * Get all the data for the engagements needed for showing in table
 * @param state
 */
export const getEngagementsTableInfo = state =>
  state.profiles.engagements.allIds.map(id => {
    const engagement = state.profiles.engagements.byId[id];
    return {
      engagementId: engagement.engagementId,
      name: engagement.type,
      numberOfUsedProperties: size(engagement.usedProperties),
      numberOfProperties: size(engagement.properties),
      dependantTypes: engagement.dependantTypes,
      hasDependants: engagement.hasDependants,
      activity: engagement.activity,
    };
  });

/**
 * Get all the data for the engagement properties needed for showing in a table
 * @param state
 * @param engagementId the id of the engagement
 */
export const getPropertiesTableInfo = (state, engagementId) => {
  const engagement = getEngagementById(state, engagementId);
  if (!engagement) {
    return [];
  }
  const propertiesInsights = engagement.insights?.usedProperties || [];

  return engagement.properties.map(propertyName => {
    const propertyWithDependants = engagement.usedProperties.find(usedProperty => usedProperty.name === propertyName);
    const usedPropertiesInsights = propertiesInsights.find(usedProperty => usedProperty.name === propertyName);

    return {
      name: propertyName,
      dependantTypes: propertyWithDependants?.dependantTypes || [],
      frequency: usedPropertiesInsights?.frequency || 0,
      percentage: usedPropertiesInsights?.percentage || '0.00',
      samples: usedPropertiesInsights?.samples || [],
    };
  });
};

/**
 * Is user currently editing some property
 * @param state
 */
export const isUserEditing = state => get(state, 'profiles.engagements.ui.userIsEditing');
