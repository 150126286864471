import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners`;

const getOptimizelyUrl = partnerId => `${BASE_PATH}/optimizely/${partnerId}`;

const getProjects = partnerId =>
  Api.callGet(`${getOptimizelyUrl(partnerId)}/projects`, {
    shouldShowToast: false,
  }).then(parseResponse);

const getDataSources = (partnerId, dcpServiceId) =>
  Api.callGet(`${getOptimizelyUrl(partnerId)}/dcpServices/${dcpServiceId}/datasources`).then(parseResponse);

const createAttribute = (partnerId, dataSourceId, name) =>
  Api.callPost(`${getOptimizelyUrl(partnerId)}/datasources/${dataSourceId}/attributes/${name}`).then(parseResponse);

export { getProjects, getDataSources, createAttribute };
