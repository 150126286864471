import React, { ReactElement } from 'react';
import { useApiWithState } from '~/common';
import { Heading, Notification } from '~/components';
import Alert, { AlertType } from '~/components/src/Alert';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { fetchConnectorErrors } from './dataServices';
import ErrorsList from './ErrorsList';
import { ConnectorErrorsProps } from './types';

function ConnectorErrors({ connectorId }: ConnectorErrorsProps): ReactElement {
  const {
    state: data,
    isLoading,
    error,
    refetch: refetchErrors,
  } = useApiWithState(() => fetchConnectorErrors(connectorId));

  const { retentionPeriod } = data || {};
  return (
    <>
      {!isLoading && (
        <div className="t-retentionPeriod m-2 flex flex-row-reverse text-sm text-gray-500">
          {i18n.t('connectors:errorStats.retentionPeriod', { retentionPeriod })}
        </div>
      )}
      <Panel className="mt-4">
        <Heading
          testHook="errorHeading"
          title={i18n.t('connectors:errorStats.connectorErrorsTitle')}
          kind="h3"
          className="Connector-statsHeading u-paddingTopNone"
        >
          <BtnIcon
            icon="refresh"
            onClick={() => refetchErrors()}
            testHook="refreshErrors"
            tooltip={i18n.t('connectors:errorStats.refresh')}
          />
        </Heading>
        {!isLoading && (
          <Notification kind="information" className="mb-4">
            {i18n.t('connectors:errorStats.retentionMessage', { retentionPeriod })}
          </Notification>
        )}
        {error && (
          <Alert
            type={AlertType.DANGER}
            testHook="apiErrorAlert"
            suffix={error.message || i18n.t('common:errors.genericError')}
          />
        )}
        {isLoading && <Spin />}
        {!isLoading && !error && data && <ErrorsList data={data} />}
      </Panel>
    </>
  );
}

export default ConnectorErrors;
