/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/admin/url-builder/predefined-values`;

/**
 * A service for value Sets related queries.
 */
export class ValueSetUrlBuilderClass {
  getValueSet(ValueSetId) {
    return Api.callGet(`${BASE_PATH}/${ValueSetId}`).then(response => response.data);
  }

  getValueSets() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveValueSet(valueSet) {
    return Api.callPost(BASE_PATH, valueSet).then(response => response.data);
  }

  updateValueSet(valueSet) {
    return Api.callPut(`${BASE_PATH}/${valueSet.predefinedValuesSetId}`, valueSet).then(response => response.data);
  }

  deleteValueSet(ValueSetId) {
    return Api.callDelete(`${BASE_PATH}/${ValueSetId}`, ValueSetId).then(response => response.data);
  }
}

const valueSetsUrlBuilderService = new ValueSetUrlBuilderClass();
export default valueSetsUrlBuilderService;
