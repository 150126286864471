import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import { Panel } from '~/components/src/Containers';
import Link from '~/components/src/Link';
import SortableHeader, { dateSort } from '~/components/src/Table/sortableHeader';

const { Table, Row, Cell } = components;
const { withSort, withPaginate } = enhancements;

const props = (className, sortKey, title, sortFn) => ({
  className,
  sortKey,
  title,
  sortFn,
});

const clientName = (clientUrl, item) =>
  !item.clientName ? null : <Link href={clientUrl(item)}>{item.clientName}</Link>;

const WIDTHS = {
  TITLE: 'u-size3of10',
  NAME: 'u-size3of10',
  SHOW_DATE: 'u-size2of10',
  HIDE_DATE: 'u-size2of10',
};

export const MessageList = ({ list, stateKey, messageUrl, clientUrl, t }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader {...props(WIDTHS.TITLE, 'title', t('common.title'))} />
        <SortableHeader {...props(WIDTHS.NAME, 'clientName', t('common.client'))} />
        <SortableHeader {...props(WIDTHS.SHOW_DATE, 'showDate', t('common.showDate'), dateSort('showDate'))} />
        <SortableHeader {...props(WIDTHS.HIDE_DATE, 'hideDate', t('common.hideDate'), dateSort('hideDate'))} />
      </Row>
      {list.map(item => (
        <Row key={item.messageId}>
          <Cell className={WIDTHS.TITLE}>
            <Link href={messageUrl(item)}>{item.title}</Link>
          </Cell>
          <Cell className={WIDTHS.NAME}>{clientName(clientUrl, item)}</Cell>
          <Cell className={WIDTHS.SHOW_DATE}>{item.showDate}</Cell>
          <Cell className={WIDTHS.HIDE_DATE}>{item.hideDate}</Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

MessageList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  messageUrl: PropTypes.func.isRequired,
  clientUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withSort(), withPaginate({ size: 10 }))(MessageList);
