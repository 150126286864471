import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { PickerView } from '~/components';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { buildUrl, useApiWithState } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import Btn from '~/components/src/Btn';
import { showSuccess } from '~/notificationCenter';
import { deleteDataFeed, fetchDataFeedById } from './dataService';
import { FeedVariable } from './types';
import { ImportVariables } from './ImportVariables';
import { SampleKeys } from './SampleKeys';
import { DataFeedContext } from './DataFeedContext';

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

function DataFeedView(): JSX.Element {
  const { hasAdservingEdit, initialDataFeedId, refetchDataFeeds, setInitialDataFeedId } = useContext(DataFeedContext);

  const navigate = useNavigate();
  const params = useParams();
  const dataFeedId = params.id || initialDataFeedId;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportVariablesModal, setShowImportVariablesModal] = useState(false);
  const [sampleKey, setSampleKey] = useState('');

  const {
    state: dataFeed,
    isLoading,
    refetch: refetchDataFeed,
  } = useApiWithState(async () => {
    setSampleKey('');
    return fetchDataFeedById(dataFeedId);
  }, [dataFeedId]);

  if (!dataFeedId) {
    return <></>;
  }

  const goToDataFeedList = () => navigate(buildUrl('content/datafeeds/dashboard'), { replace: true });
  const goToEditDataFeed = () => navigate(buildUrl(`content/datafeeds/edit/${dataFeedId}`), { replace: true });

  const handleDeleteDataFeed = async () => {
    await deleteDataFeed(dataFeedId);
    setShowDeleteModal(false);
    showSuccess({ header: i18n.t('dataFeeds:notifications.deleteSuccessful') });

    // Check if the deleted one is the first and set the initial to ''
    if (dataFeedId === initialDataFeedId) setInitialDataFeedId('');
    refetchDataFeeds();
    goToDataFeedList();
  };

  if (isLoading || !dataFeed) {
    return <Spin />;
  }

  return (
    <div key={dataFeedId}>
      <div className="block rounded-md border border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
        <div className="flex items-center justify-between">
          <p className="t-dataFeedTitle m-0 py-2 text-2xl font-medium text-gray-600">{dataFeed?.name}</p>
          <div className="flex gap-2">
            <BtnIcon
              icon="edit"
              testHook="editDataFeed"
              onClick={() => goToEditDataFeed()}
              tooltip={i18n.t('dataFeeds:toolTips.editFeed')}
              disabled={!hasAdservingEdit}
            />
            <BtnIcon
              icon="download"
              testHook="importVariables"
              onClick={() => setShowImportVariablesModal(true)}
              tooltip={i18n.t('dataFeeds:toolTips.importVariables')}
              disabled={!hasAdservingEdit}
            />
            <BtnIcon
              icon="delete"
              testHook="deleteDataFeed"
              onClick={() => setShowDeleteModal(true)}
              tooltip={i18n.t('dataFeeds:toolTips.deleteFeed')}
              disabled={!hasAdservingEdit}
            />
          </div>
        </div>

        <div className="leading-10">
          <p className="t-feedprefix">
            <span className="font-medium">{i18n.t('dataFeeds:form.feedPrefix')} : </span>
            {dataFeed.feedPrefix}
          </p>
          <p className="t-lastUpdate my-1">
            <span className="font-medium">{i18n.t('dataFeeds:form.lastUpdate')} : </span>
            <span className="italic">
              {dataFeed.lastUpdate ? moment(dataFeed.lastUpdate).format(DATE_FORMAT) : i18n.t('dataFeeds:form.unknown')}
            </span>
          </p>
          <div className="flex">
            <span className="font-medium">{i18n.t('dataFeeds:form.exampleKeys')} : </span>
            {dataFeed.exampleKeys && dataFeed.exampleKeys.length > 0 ? (
              <div className="mx-1 mb-1 flex flex-col">
                {dataFeed.exampleKeys.map((key, index) => (
                  <p key={index} className="t-exampleKeys flex items-center gap-2 px-2 py-1 leading-4">
                    <span className="basis-1/3">{key}</span>
                    <BtnIcon
                      icon="download"
                      testHook="retrieveSamples"
                      onClick={() => setSampleKey(key)}
                      tooltip={i18n.t('dataFeeds:toolTips.retrieveSample')}
                    />
                  </p>
                ))}
              </div>
            ) : (
              <span className="mx-1 italic">{i18n.t('dataFeeds:form.noExampleKeys')}</span>
            )}
          </div>
          <p className="flex">
            <span className="whitespace-nowrap font-medium">{i18n.t('dataFeeds:form.feedVariables')} :</span>
            <span className="mx-2 flex max-h-52 min-w-[25%] flex-col overflow-y-auto rounded border px-2 py-1 leading-8">
              {dataFeed.feedVariables.map((feedVariable: FeedVariable, index: number) => (
                <span key={index} className="hover:bg-gray-100">
                  {feedVariable.name}
                </span>
              ))}
            </span>
          </p>
        </div>
      </div>

      <SampleKeys dataFeedId={dataFeedId} sampleKey={sampleKey} />
      {showDeleteModal && (
        <PickerView
          pickerTitle={i18n.t('dataFeeds:delete.title')}
          handlePickerVisibility={() => setShowDeleteModal(false)}
          className="ModelActionPicker"
        >
          <>
            <p className="t-deleteText">{i18n.t('dataFeeds:delete.message', { name: dataFeed.name })}</p>
            <div className="mt-6 flex justify-end gap-2">
              <Btn onClick={() => setShowDeleteModal(false)}>{i18n.t('common:actions.cancel')}</Btn>
              <Btn testHook="deleteDataFeedAction" color="red" onClick={() => handleDeleteDataFeed()}>
                {i18n.t('common:actions.delete')}
              </Btn>
            </div>
          </>
        </PickerView>
      )}
      {showImportVariablesModal && (
        <PickerView
          pickerTitle={i18n.t('dataFeeds:importVariables.title')}
          handlePickerVisibility={() => setShowImportVariablesModal(false)}
          className="!h-auto !w-2/5"
        >
          <ImportVariables
            dataFeedId={dataFeedId}
            setShowImportVariablesModal={setShowImportVariablesModal}
            refetchDataFeed={refetchDataFeed}
          />
        </PickerView>
      )}
    </div>
  );
}

export default DataFeedView;
