import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { updateExpGroupName, updateExpGroupWeight } from '../dataService';
import { TExperimentGroup } from '../types';
import { formatFloatValue } from '../util';
import ExpGroupModal from './ExpGroupModal';

const columnHelper = createColumnHelper<TExperimentGroup>();

export const Traffic = ({
  hasTagEdit,
  experimentGroups,
  experimentId,
  refetchExperimentDetails,
}: {
  hasTagEdit: boolean;
  experimentGroups: TExperimentGroup[];
  experimentId: string;
  refetchExperimentDetails: () => void;
}) => {
  const totalWeight = experimentGroups.reduce((acc, val) => acc + val.weight, 0);

  const [selectedGroup, setSelectedGroup] = useState<TExperimentGroup | null>();
  const [showGroupModel, setShowGroupModel] = useState(false);

  const closeGroupModal = (refetch = false) => {
    setShowGroupModel(false);
    setSelectedGroup(null);
    if (refetch) refetchExperimentDetails();
  };

  const handleUpdateValue = async (group: TExperimentGroup) => {
    const payload = {
      experimentGroupId: group.experimentGroupId,
      experimentId,
      name: group.name,
    };
    await updateExpGroupName(payload);
  };

  const handleUpdateWeight = async (group: TExperimentGroup) => {
    const payload = {
      experimentGroupId: group.experimentGroupId,
      experimentId,
      weight: group.weight,
    };
    await updateExpGroupWeight(payload);
  };

  const handleGroupUpdate = async (group: TExperimentGroup) => {
    const promises = [];
    if (selectedGroup && group.name !== selectedGroup.name) promises.push(handleUpdateValue(group));
    if (selectedGroup && group.weight !== selectedGroup.weight) promises.push(handleUpdateWeight(group));
    Promise.all(promises)
      .then(() => closeGroupModal(true))
      .catch(() => closeGroupModal());
  };

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <span>{i18n.t('experiments:traffic.name')}</span>,
      cell: info => <span>{info.getValue()}</span>,
      size: 250,
    }),
    columnHelper.accessor('experimentGroupNumber', {
      header: () => <div>{i18n.t('experiments:traffic.groupNumber')}</div>,
      cell: info => <div>{info.getValue()}</div>,
      size: 100,
    }),
    columnHelper.accessor('controlGroup', {
      header: () => <span>{i18n.t('experiments:traffic.baseline')}</span>,
      cell: info => <span>{i18n.t(`experiments:traffic.${info.getValue() ? 'yes' : 'no'}`)}</span>,
      size: 100,
    }),
    columnHelper.accessor('weight', {
      header: () => <div className="text-right">{i18n.t('experiments:traffic.trafficWeight')}</div>,
      cell: info => (
        <div className="flex items-center justify-end gap-2">
          <span>{info.getValue()}</span>
          <span className="text-xs text-r42-blue">({formatFloatValue(info.getValue() / totalWeight, 2, true)}%)</span>
        </div>
      ),
      size: 100,
    }),
    columnHelper.accessor('actions', {
      header: () => <div className="text-center">{i18n.t('experiments:traffic.actions')}</div>,
      cell: info => (
        <div className="text-center">
          <BtnIcon
            color="gray"
            icon="edit"
            onClick={() => {
              setSelectedGroup(info.row.original);
              setShowGroupModel(true);
            }}
            tooltip={hasTagEdit ? i18n.t('experiments:actions.edit') : i18n.t('experiments:tooltips.noPermission')}
            disabled={!hasTagEdit}
            testHook="editGroup"
          />
        </div>
      ),
      size: 100,
    }),
  ];

  return (
    <>
      {!experimentGroups?.length ? (
        <Notification kind="information" testHook="noMappings">
          {i18n.t('experiments:messages.noMappings')}
        </Notification>
      ) : (
        <UITable testHook="trafficTable" data={experimentGroups} columns={getColumns()} />
      )}
      {showGroupModel && (
        <PickerView
          className="!h-auto !w-1/3"
          pickerTitle={i18n.t('experiments:form.group.label')}
          handlePickerVisibility={() => closeGroupModal()}
        >
          <ExpGroupModal
            group={selectedGroup}
            closeModal={() => closeGroupModal()}
            editGroup={group => handleGroupUpdate(group)}
          />
        </PickerView>
      )}
    </>
  );
};
