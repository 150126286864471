import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { withRouter } from '~/common/withRouter';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import * as selectors from '../urlBuilder/columns/selectors';
import { editColumn, fetchColumns } from '../urlBuilder/columns/actions';
import ColumnForm from '../components/ColumnForm';

export class EditColumnPage extends Component {
  componentDidMount() {
    this.props.fetchColumns();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('columns.formEditColumn.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('columns.formNewColumn.intro')}</p>
            <ColumnForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      initialValues: selectors.getColumnById(state, ownProps.params.columnId),
    }),
    dispatch => ({
      fetchColumns: () => dispatch(fetchColumns()),
      onSubmit: column => dispatch(editColumn(column)),
    }),
  ),
  reduxForm({
    form: 'columnEdit',
  }),
  translate(['urlBuilder']),
)(EditColumnPage);
