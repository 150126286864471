import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    audience: Yup.string().required(t('cards.validation.audience')),
  });

export const TradeDesk = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="audience"
          label={t('cards.label.audience')}
          placeholder={t('common.audienceCard.placeholder')}
          errorText={formProps.touched.audience && formProps.errors.audience}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
