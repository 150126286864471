import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Get all the site information by the id
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.ENVIRONMENTS_FETCH_SUCCESS:
      return action.payload.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.environmentId]: currentValue,
        }),
        {},
      );
    case types.ENVIRONMENT_SAVE_SUCCESS:
    case types.ENVIRONMENT_UPDATE_SUCCESS:
      return {
        ...state,
        [action.payload.environmentId]: action.payload,
      };
    case types.ENVIRONMENT_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * We will keep an array with just the ids of all the environments
 * @param state
 * @param action
 * @returns {*}
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.ENVIRONMENTS_FETCH_SUCCESS:
      return action.payload.map(item => item.environmentId);
    case types.ENVIRONMENT_SAVE_SUCCESS:
      return [...state, action.payload.environmentId];
    case types.ENVIRONMENT_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

/**
 * We will keep an array of non deletable evironment codes
 * @param state
 * @param action
 * @returns []
 */

const nonDeletableCodes = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.CANTDELETE_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.map(code => !state.includes(code) && nextState.push(code));
      return nextState;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.ENVIRONMENTS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.ENVIRONMENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  nonDeletableCodes,
  ui,
});
