import { boolean, string, object, array } from 'yup';
import { Mapping } from './transformerTypes';
import { valueTransformationSchema } from './valueTransformation/validateValueTransformation';

const mappingSchema = object().shape({
  id: string(),
  sourceName: string().required('SOURCE_NAME_REQUIRED'),
  destination: object().shape({
    name: string().required('DESTINATION_NAME_REQUIRED'),
  }),
  required: boolean(),
  valueTransformations: array().of(valueTransformationSchema),
  // .required('VALUE_TRANSFORMATIONS_REQUIRED'),
});

const eventSchema = object().shape({
  mappings: array()
    .of(mappingSchema)
    .test('should-have-at-least-one-mapping-with-user-data', 'HAS_NO_USER_DATA_MAPPINGS', (mappings: Mapping[]) =>
      mappings.some(mapping => mapping.destination.element === 'user_data'),
    )
    .required('EVENT_HAS_NO_MAPPINGS'),
});

export const transformerSchema = array().of(eventSchema);
