import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { ImportPayload, ImportType, ImportResponse } from './types';

const BASE_PATH = `${API_BASE}/support/import`;

export const fetchImports = (): Promise<ImportType[]> => Api.callGet(BASE_PATH).then(parseResponse);

export const submitImport = (payload: ImportPayload): Promise<string> =>
  Api.callPost(BASE_PATH, payload).then(parseResponse);

export const fetchImport = (importId: string): Promise<ImportResponse> =>
  Api.callGet(`${BASE_PATH}/${importId}`).then(parseResponse);

export const deleteImport = (importId: string): Promise<Record<string, any>> =>
  Api.callDelete(`${BASE_PATH}/${importId}`);
