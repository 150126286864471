import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import { fetchConnectors, fetchEventConnectors, deleteConnector } from '~/profiles/connectors';
import { selectConnectorForStatsScreen } from '~/profiles/connectors/selectors';
import { fetchPartnerStats } from '~/profiles/audiences/activity/actions';
import {
  getFetchingPartnerStats,
  getPartnerStats,
  selectDocumentationUrl,
} from '~/profiles/audiences/activity/selectors';
import ConnectorPage from './presenter';

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const connectorId = ownProps?.params.connectorId;

  return {
    connectorId,
    connector: selectConnectorForStatsScreen(connectorId, ownProps.isEventConnector)(state),
    editLink: ownProps.isEventConnector
      ? buildUrl(`profiles/partners/editEvent/${connectorId}`)
      : buildUrl(`profiles/partners/edit/${connectorId}`),
    partnerStats: getPartnerStats(ownProps.isEventConnector, ownProps.t)(state),
    documentationUrl: selectDocumentationUrl(state),
    fetchingPartnerStats: getFetchingPartnerStats(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchData: () => (ownProps.isEventConnector ? dispatch(fetchEventConnectors()) : dispatch(fetchConnectors())),
  fetchPartnerStats: (partnerId, partnerType, sourceId, startDate, endDate) =>
    dispatch(fetchPartnerStats(partnerId, partnerType, sourceId, startDate, endDate)),
  onDeleteClick: connector => {
    dispatch(
      fromModals.showDeleteModal({
        title: ownProps.t('partners:deleteModal.title'),
        message: ownProps.t('partners:deleteModal.message', { partnerName: connector.partnerName }),
        onConfirm: () => dispatch(deleteConnector(connector, true)),
      }),
    );
  },
});

/* withStatsReady - passes down "areStatsReady" prop to avoid flickering of stats chart */
const withStatsReady = Component => props => {
  const previousPropsRef = useRef(props);
  const previousProps = previousPropsRef.current;

  const [areStatsReady, setAreStatsReady] = useState(false);

  if (previousProps.fetchingPartnerStats.isPending && props.fetchingPartnerStats.isFulfilled && !areStatsReady) {
    setAreStatsReady(true);
  }

  useEffect(() => {
    previousPropsRef.current = props;
  });

  return <Component {...props} areStatsReady={areStatsReady} />;
};

export default compose(
  withRouter,
  translate('partners'),
  connect(mapStateToProps, mapDispatchToProps),
  withStatsReady,
)(ConnectorPage);
