/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/admin/tagmanagement/environments`;
const NON_DELETABLE_CODES = 'nonDeletableCodes';

/**
 * A service for environments related queries.
 */
export class AdminEnvironmentsClass {
  getEnvironments() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveEnvironment(environment) {
    return Api.callPost(BASE_PATH, environment).then(response => response.data);
  }

  updateEnvironment(environment) {
    return Api.callPut(`${BASE_PATH}/${environment.environmentId}`, environment).then(response => response.data);
  }

  deleteEnvironment(environmentId) {
    return Api.callDelete(`${BASE_PATH}/${environmentId}`, environmentId).then(response => response.data);
  }

  getNonDeletableCodes() {
    return Api.callGet(`${BASE_PATH}/${NON_DELETABLE_CODES}`).then(response => response.data);
  }
}

const adminEnvironmentsService = new AdminEnvironmentsClass();
export default adminEnvironmentsService;
