import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'formik';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { useAPI } from '~/common/ApiHooks';
import { showSuccess } from '~/notificationCenter/actions';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import BtnIcon from '~/components/src/BtnIcon';
import { Notification } from '~/components';
import { getDoubleClickUsersList, addUserListInDoubleClick } from './service';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import AddUserListForm from './AddUserListForm';
import '../../ConnectorGenericForm.scss';

const validations = t =>
  Yup.object().shape({
    userList: Yup.object().required(t('connectors:cards.validation.doubleClickUserList')),
  });

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const openUserListModal = (createNewUserList, setFieldValue) => dispatch => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:connectors.doubleClick.userListAdd.header'),
      confirmText: i18n.t('audiences:connectors.doubleClick.userListAdd.confirmText'),
      content: (
        <AddUserListForm
          hideModal={() => dispatch(hideModal())}
          createNewUserList={createNewUserList}
          setFieldValue={setFieldValue}
        />
      ),
    }),
  );
};

const DoubleClick = ({ onEdit, connector, openUserListModal, hideModal, t, isSubmitted }) => {
  const { partnerId, userList } = connector.partnerDetails;

  const [listItems, setListItems] = useState([]);
  const { data, error, isLoading: isListLoading } = useAPI(() => getDoubleClickUsersList(partnerId), [partnerId]);

  useEffect(() => {
    setListItems(data || []);
  }, [data]);

  const createUserList = (name, description, setFieldValue) => {
    const payload = {
      name,
      description,
    };
    return addUserListInDoubleClick(partnerId, payload, t).then(response => {
      setListItems([...listItems, response]);
      setFieldValue('userList', response);
      showSuccess({
        body: t('connectors.doubleClick.userListAdd.successToastText'),
      });
      hideModal();
    });
  };

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <div className="flex items-center gap-4">
              <Field
                name="userList"
                as={FormikSelectField}
                label={t('connectors.doubleClick.fieldLabel')}
                errorText={formProps.touched.userList && formProps.errors.userList}
                options={listItems || []}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.userListId}
                value={userList?.userListId}
                isLoading={isListLoading}
                className="flex-1"
              />
              <BtnIcon
                className="mt-3 rounded-full"
                icon="add"
                tooltip={t('connectors.doubleClick.userListAdd.iconDesc')}
                testHook="newUserButton"
                onClick={() => openUserListModal(createUserList, formProps.setFieldValue)}
              />
            </div>
          </SmallWrapperNoOffset>
          {error && <Notification kind="error">{t('connectors.doubleClick.userList.genericError')}</Notification>}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { hideModal, openUserListModal }), translate('audiences'))(DoubleClick);
