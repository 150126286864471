import React from 'react';
import cx from 'classnames';
import { Tab as ReactTab, Tabs as ReactTabs, TabList as ReactTabList, TabPanel as ReactTabPanel } from 'react-tabs';
import { TabListType, TabPanelType, TabsType, TabType } from './types';
import './styles.scss';

/** Tabs Modifiers * */
const kinds = {
  heading: 'heading',
};
export const kind = kinds;

/**
 * Main Tabs Component
 * @param testHook
 * @param className
 * @param kind
 * @param props
 */
export const Tabs = ({ testHook = 'tabs', className, kind, ...props }: TabsType): JSX.Element => {
  const classes = [];

  /** Add Tabs Modifiers * */
  switch (kind) {
    case kinds.heading:
      classes.push('Tabs--heading');
      break;
  }

  return (
    <ReactTabs
      {...props}
      className={cx('Tabs', classes, className, `t-${testHook}`)}
      disabledTabClassName="is-disabled"
      selectedTabClassName="is-selected"
      selectedTabPanelClassName="is-selected"
    />
  );
};

/**
 * TabList
 * @param className
 * @param props
 */
export const TabList = ({ className, ...props }: TabListType): JSX.Element => (
  <ReactTabList {...props} className={['Tabs-nav', className].join(' ')} />
);
TabList.tabsRole = 'TabList';

/**
 * Tab
 * @param testHook
 * @param className
 * @param props
 */
export const Tab = ({ testHook = 'tabLink', className, ...props }: TabType): JSX.Element => (
  <ReactTab {...props} className={cx('Tabs-navLink', className, `t-${testHook}`)} />
);
Tab.tabsRole = 'Tab';

/**
 * Tab Panel
 * @param testHook
 * @param className
 * @param props
 */
export const TabPanel = ({ testHook = 'tabPanel', className, ...props }: TabPanelType): JSX.Element => (
  <ReactTabPanel {...props} className={cx('Tabs-panel', className, `t-${testHook}`)} />
);
TabPanel.tabsRole = 'TabPanel';
