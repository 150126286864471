import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as fromModals from '~/modals';
import { fetchEventConnectors, toggleEventConnectorActivation, deleteConnector } from '~/profiles/connectors/actions';
import { fetchConnectorTypes, isFetchingConnectorTypes, getEventConnectorTypes } from '~/profiles/connectors';
import { selectEventConnectors } from '~/profiles/connectors/form/connectorFormSelector';
import ConnectorSection from '~/profiles/components/ConnectorSection';
import { buildUrl, changeUrl } from '~/common/history';
import { selectIsFetchingEventConnectors } from '~/profiles/connectors/selectors';
import { EVENT_CONNECTORS_KEY } from '~/profiles/connectors/types';
import { isDIYConnector } from '~/profiles/helpers/connectorsHelper';
import { CONNECTOR_EVENTS } from '~/gaActions';

const mapStateToProps = state => ({
  list: selectEventConnectors(state).map(eventConnector => ({
    partnerId: eventConnector.eventConnectorId,
    partnerType: eventConnector.destinationPartnerType,
    partnerName: eventConnector.name,
    activated: eventConnector.activated,
    url: buildUrl(`profiles/partners/list/eventStats/${eventConnector.eventConnectorId}`),
    ...(isDIYConnector(eventConnector.destinationPartnerType) && {
      imagePartnerId: eventConnector?.destinationPartnerId,
    }),
  })),
  isFetching: selectIsFetchingEventConnectors(state),
  isFetchingConnectorTypes: isFetchingConnectorTypes(state),
  connectorTypes: getEventConnectorTypes(state),
});

const mapDispatchToProps = (dispatch, { t }) => ({
  showDeleteEventConnectorModal: eventConnector => {
    dispatch(
      fromModals.showDeleteModal({
        title: t('partners:deleteModal.title'),
        message: t('partners:deleteModal.message', { partnerName: eventConnector.partnerName }),
        gaAction: CONNECTOR_EVENTS.DELETED,
        onConfirm: () =>
          dispatch(
            deleteConnector({
              eventConnectorId: eventConnector.partnerId,
              partnerName: eventConnector.partnerName,
            }),
          ),
      }),
    );
  },
  toggleEventConnectorActivation: (eventConnectorId, activated, partnerName) =>
    dispatch(toggleEventConnectorActivation(eventConnectorId, activated, partnerName)),
  fetchData: () => dispatch(fetchEventConnectors()),
  fetchConnectorTypes: () => dispatch(fetchConnectorTypes('events')),
});

const withEventProps = Component => props => {
  const { showDeleteEventConnectorModal, toggleEventConnectorActivation, t, canEdit } = props;

  const actions = eventConnector => {
    if (!canEdit) {
      return [];
    }
    return [
      {
        name: t('partners:list.actions.edit'),
        tooltip: t('partners:list.actions.edit'),
        isDisabled: false,
        onClick: () => {
          changeUrl(`profiles/partners/editEvent/${eventConnector.partnerId}`);
        },
        testHook: 'edit',
      },
      {
        name: t(`partners:list.actions.${eventConnector.activated ? 'deactivate' : 'activate'}`),
        tooltip: t(`partners:list.actions.${eventConnector.activated ? 'deactivate' : 'activate'}`),
        isDisabled: false,
        onClick: () => {
          toggleEventConnectorActivation(
            eventConnector.partnerId,
            eventConnector.activated,
            eventConnector.partnerName,
          );
        },
        testHook: 'activationToggle',
      },
      {
        name: t('partners:list.actions.delete'),
        tooltip: t('partners:list.actions.delete'),
        isDisabled: false,
        onClick: () => {
          showDeleteEventConnectorModal(eventConnector);
        },
        testHook: 'delete',
        type: 'delete',
      },
    ];
  };

  return (
    <Component
      {...props}
      actions={actions}
      title={t('eventConnector.title')}
      description={t('eventConnector.description')}
      listKey={EVENT_CONNECTORS_KEY}
      columns={['STATUS']}
      addNewButtonText={t('eventConnector.new')}
      isEventType={true}
    />
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withEventProps)(ConnectorSection);
