import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'formik';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Notification from '~/components/src/Notification';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import BtnIcon from '~/components/src/BtnIcon';
import * as formModals from '~/modals';
import { useAPI } from '~/common/ApiHooks';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import * as ApiService from './service';
import AddSegmentForm from './AddSegmentForm';
import './styles.scss';

const makeValidations = t =>
  Yup.object().shape({
    segment: Yup.object().required(t('connectors:cards.validation.adformSegment')),
  });

const useSegments = partnerId => {
  const [segments, setSegments] = useState(null);

  const { data, error } = useAPI(() => ApiService.fetchSegments(partnerId), []);

  useEffect(() => {
    setSegments(data);
  }, [data]);

  const addSegments = segment => {
    setSegments([...segments, segment]);
  };

  return { segments, addSegments, segmentsError: error };
};

const openSegmentModal = (selectNewlyCreatedSegment, partnerId, t) => dispatch => {
  const hideModal = () => dispatch(formModals.hideModal());

  dispatch(
    formModals.showModal(formModals.CONTENT_MODAL, {
      title: t('connectors.adform.newSegment'),
      className: 'Adform-segmentModal',
      content: (
        <AddSegmentForm
          partnerId={partnerId}
          selectNewlyCreatedSegment={selectNewlyCreatedSegment}
          hideModal={hideModal}
          t={t}
        />
      ),
    }),
  );
};

const SegmentField = ({ formProps, partnerId, openSegmentModal, t }) => {
  const { segments, addSegments, segmentsError } = useSegments(partnerId);

  const selectNewlyCreatedSegment = segment => {
    addSegments(segment);
    formProps.setFieldValue('segment', segment);
  };

  return (
    <div className="Adform-segmentField">
      <SmallWrapperNoOffset>
        <div className="flex items-center gap-4">
          <Field
            name="segment"
            as={FormikSelectField}
            label={t('connectors.adform.segment')}
            errorText={formProps.touched.segment && formProps.errors.segment}
            options={segments || []}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            testHook="segmentSelect"
            className="flex-1"
          />
          <BtnIcon
            className="mt-3 rounded-full"
            testHook="addSegment"
            icon="add"
            tooltip={t('connectors.adform.createNewSegment')}
            onClick={() => openSegmentModal(selectNewlyCreatedSegment, partnerId, t)}
          />
        </div>
      </SmallWrapperNoOffset>
      {segmentsError && (
        <Notification className="u-marginTopL" kind="error" header="">
          {t('connectors.adform.segmentsApiError')}
        </Notification>
      )}
    </div>
  );
};

const Adform = ({ onEdit, connector, t, isSubmitted, openSegmentModal }) => {
  const { partnerId } = connector.partnerDetails;

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      connector={connector}
      validations={makeValidations(t)}
      isSubmitted={isSubmitted}
    >
      {formProps => (
        <SegmentField formProps={formProps} partnerId={partnerId} openSegmentModal={openSegmentModal} t={t} />
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { openSegmentModal }), translate('audiences'))(Adform);
