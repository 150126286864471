import React from 'react';
import Dropzone from 'react-dropzone';
import cx from 'classnames';

import '~/components/src/Form/Elements/elements.scss';

const DropzoneElement = ({
  children,
  className,
  hasError,
  onDropAccepted,
  onDropRejected,
  accept,
  testHook,
  multiple,
  disabled,
}) => (
  <Dropzone
    onDropAccepted={onDropAccepted}
    onDropRejected={onDropRejected}
    accept={accept}
    multiple={multiple}
    disabled={disabled}
  >
    {({ getRootProps, getInputProps }) => (
      <div className="container">
        <div
          {...getRootProps({
            className: cx(
              'DropzoneElement',
              { 'DropzoneElement--hasError': hasError, [`t-${testHook}`]: testHook },
              className,
            ),
          })}
        >
          <input {...getInputProps()} />
          {typeof children === 'function' ? children(getRootProps) : children}
        </div>
      </div>
    )}
  </Dropzone>
);

export default DropzoneElement;
