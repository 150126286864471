import { memoize } from 'lodash';
import { changeUrl } from '~/common';

const FACT_TYPE_LABEL_KEYS = {
  AI: 'customerFacts:general.typeAI',
  EXTERNAL: 'customerFacts:general.typeExternal',
};

const getUsedPropertiesCount = (definedFactProperties, usedFactProperties) => {
  const definedUsedProperties = Object.keys(usedFactProperties).filter(property =>
    definedFactProperties.includes(property),
  );

  return definedUsedProperties.length;
};

export const transformIntoFactsById = (profileFactsApiResponse, t) => {
  if (!Array.isArray(profileFactsApiResponse)) {
    return null;
  }

  return profileFactsApiResponse.reduce(
    (factsById, fact) => ({
      ...factsById,
      [fact.factId]: {
        customerFactId: fact.factId,
        name: fact.name,
        typeLabel: t(FACT_TYPE_LABEL_KEYS[fact.type]),
        hasDependants: fact.hasDependants,
        dependantTypes: fact.dependantTypes,
        numberOfUsedProperties: getUsedPropertiesCount(fact.definedProperties, fact.properties),
        numberOfProperties: fact.definedProperties.length,
        originalTtl: fact.originalTtl,
        expireTimestamp: fact.expireTimestamp,
      },
    }),
    {},
  );
};

export const getProfileFactsForTable = memoize(profileFactsById => Object.values(profileFactsById));

export const goToFactPage = factId => {
  changeUrl(`profiles/externalFacts/view/${factId}`);
};
