angular
  .module('webUi.common.test.TestUtils', [])

  .factory('TestUtils', [
    '$q',
    function ($q) {
      var sanitizeRestangularOne = function (item) {
        return _.omit(
          item,
          'route',
          'parentResource',
          'getList',
          'get',
          'post',
          'put',
          'remove',
          'head',
          'trace',
          'options',
          'patch',
          '$get',
          '$save',
          '$query',
          '$remove',
          '$delete',
          '$put',
          '$post',
          '$head',
          '$trace',
          '$options',
          '$patch',
          '$then',
          '$resolved',
          'restangularCollection',
          'customOperation',
          'customGET',
          'customPOST',
          'customPUT',
          'customDELETE',
          'customGETLIST',
          '$getList',
          '$resolved',
          'restangularCollection',
          'one',
          'all',
          'doGET',
          'doPOST',
          'doPUT',
          'doDELETE',
          'doGETLIST',
          'addRestangularMethod',
          'getRestangularUrl',
          'putElement',
        );
      };

      var TestUtils = {
        getEmptyPromise: function () {
          return $q.defer();
        },

        // Remove all Restangular/AngularJS added methods in order to use Jasmine toEqual between the retrieve resource and the model
        sanitizeRestangularOne: sanitizeRestangularOne,

        /**
         * @param {type} promisepromise
         * @param {$q} q
         * @param {$scope} scope
         * @param {any} value the value with which the then will be called
         * @returns {undefined}
         */
        resolvePromise: function (promise, q, scope) {
          var defer = q.defer();
          var unproxiedPromise;
          promise.then(function (value) {
            unproxiedPromise = value;
          });
          defer.resolve();
          scope.$apply();
          return unproxiedPromise;
        },

        /**
         * If you have some promise that's been resolved already, you want to (in a blocking way) retrieve its value.
         * @param promise
         * @param scope
         * @returns {*} the actual value of the promise
         */
        getPromiseValue: function (promise, scope) {
          scope.$digest();
          if (promise.$$state.status === 1) {
            //resolved
            return promise.$$state.value;
          }
        },

        /**
         * If you have some promise that you expect have been rejected, you can get the rejection value
         * @param promise
         * @param scope
         * @returns {*}
         */
        getPromiseRejectionValue: function (promise, scope) {
          scope.$digest();
          if (promise.$$state.status === 2) {
            //rejected
            return promise.$$state.value;
          }
          throw 'Promise was not actually rejected';
        },

        /**
         * URL helper
         * @returns {string}
         */
        buildURL: function () {
          if (arguments.length === 0) {
            return '';
          } else {
            return '/' + Array.prototype.slice.call(arguments).join('/');
          }
        },
      };
      return TestUtils;
    },
  ]);
