import React from 'react';

import moment from 'moment';

import i18n from '~/i18n';
import { buildUrl } from '~/common';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import { Empty, Link, Spinner, Tooltip } from '~/components';

import ModelActions from './components/ModelActions';
import { DATE_FORMAT, MODEL_STATUS, TIME_FORMAT } from './constants';
import { ModelsTableProps, ModelStatusType, ModelTableRowType } from './types';

import './styles.scss';

const columnHelper = createColumnHelper<ModelTableRowType>();

const EmptyList = () => (
  <Empty header={i18n.t('ai:modelsPage.list.emptyHeader')} body={i18n.t('ai:modelsPage.list.emptyBody')} />
);

const ModelStatusTag = ({ status, tooltipText = '' }: { status: ModelStatusType; tooltipText?: string | null }) => {
  switch (status.toLowerCase()) {
    case MODEL_STATUS.ERROR.toLowerCase():
      return (
        <Tooltip tooltip={tooltipText || ''}>
          <span className="flex items-center gap-1">
            <Icons icon="warning" className="h-5 w-5 text-red-500" /> {status}
          </span>
        </Tooltip>
      );

    case MODEL_STATUS.TRAINING.toLowerCase():
    case MODEL_STATUS.SUBMITTED_FOR_TRAINING.toLowerCase():
      return (
        <>
          <span className="ModelTable-statusCircle ModelTable-statusCircle--training" />
          {MODEL_STATUS.TRAINING}
        </>
      );

    case MODEL_STATUS.DEPLOYING.toLowerCase():
      return (
        <>
          <span className="ModelTable-statusCircle ModelTable-statusCircle--deploying" />
          {status}
        </>
      );

    case MODEL_STATUS.DELETING.toLowerCase():
      return (
        <>
          <span className="ModelTable-statusCircle ModelTable-statusCircle--deleting" />
          {status}
        </>
      );

    case MODEL_STATUS.SUBMITTED_FOR_RETRACTION.toLowerCase():
      return (
        <>
          <span className="ModelTable-statusCircle ModelTable-statusCircle--retracting" />
          {MODEL_STATUS.RETRACTING}
        </>
      );

    default:
      return <>{status}</>;
  }
};

function ModelsTable({ models = [], isLoading, refetchModels }: ModelsTableProps): JSX.Element {
  const columns = [
    columnHelper.accessor('modelName', {
      header: () => <div>{i18n.t('ai:columns.name')}</div>,
      cell: ({ row }) => (
        <Link className="t-modelName" href={buildUrl(`ai/models/view/${row.original.modelId}`)}>
          {row.original.modelName}
        </Link>
      ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{i18n.t('ai:columns.createdAt')}</div>,
      cell: ({ row }) => (
        <p>
          {moment(row.original.createdAt).format(DATE_FORMAT)}
          <br />
          <small>{moment(row.original.createdAt).format(TIME_FORMAT)}</small>
        </p>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <div>{i18n.t('ai:columns.status')}</div>,
      cell: ({ row }) => (
        <p
          className={`t-modelTableStatus ModelTable-status ModelTable-status--${row.original.status
            .toLowerCase()
            .replaceAll(' ', '_')}`}
        >
          <ModelStatusTag status={row.original.status} tooltipText={row.original.error} />
        </p>
      ),
    }),
    columnHelper.accessor('actions', {
      header: () => <div>{i18n.t('ai:columns.actions')}</div>,
      cell: ({ row }) => (
        <div className="t-modelActions flex items-center gap-4">
          <ModelActions
            isListPage
            allowedActions={row.original.actions}
            modelInfo={{ modelId: row.original.modelId, modelName: row.original.modelName }}
            refetchData={refetchModels}
          />
        </div>
      ),
    }),
  ];

  if (isLoading) {
    return <Spinner />;
  }

  if (models?.length === 0) {
    return <EmptyList />;
  }

  return <UITable testHook="modelTable" data={models || []} columns={columns} />;
}

export default ModelsTable;
