import React, { useState } from 'react';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { buildUrl } from '~/common';
import { Notification } from '~/components';
import Btn from '~/components/src/Btn';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { fetchStepDataBypassError } from '~/workflows/dataService';

const StepSearch = ({ handleClose }: { handleClose: () => void }) => {
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const handleStepSearch = async (values: { stepId: string }) => {
    try {
      const stepData = await fetchStepDataBypassError(values.stepId);
      if (stepData) {
        setError(null);
        const { workflowType, journeyId, stepId } = stepData;

        if (workflowType.name === WORKFLOW_TYPE.Audience) {
          navigate(buildUrl(`workflows/view/${journeyId}?nodeId=${stepId}`));
        } else {
          navigate(buildUrl(`workflows/edit/${journeyId}/step/${stepId}`));
        }
      } else setError(i18n.t('workflow:stepSearch:error', { stepId: values.stepId }));
    } catch (error) {
      setError(i18n.t('workflow:stepSearch:error', { stepId: values.stepId }));
    }
  };

  const initialValues = {
    stepId: '',
  };

  const validationSchema = Yup.object().shape({
    stepId: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  return (
    <Formik
      onSubmit={handleStepSearch}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {({ handleSubmit, errors }) => (
        <>
          <div className="fixed right-0 top-0 z-10 h-full w-1/4 bg-white pt-12 drop-shadow-md">
            <div className={cx('relative flex h-full flex-col gap-2')}>
              <div className={cx('flex items-center justify-between border-b border-gray-100 px-2 py-4')}>
                <div className={cx('flex items-center gap-2 text-gray-500')}>
                  <Icons icon="searchStep" className="h-6 w-6" />
                  {i18n.t('workflow:common:searchStep')}
                </div>
              </div>
              <div className="t-filterContent mb-28 h-full overflow-y-auto px-4">
                <div className="flex flex-col gap-2 p-2 py-4 ">
                  <Field
                    name="stepId"
                    as={FormikInputField}
                    label={i18n.t('workflow:stepSearch.label')}
                    placeholder={i18n.t('workflow:stepSearch.placeholder')}
                    errorText={errors?.stepId}
                    autoFocus={true}
                  />
                  <div className="mt-4 flex justify-end gap-2">
                    <Btn onClick={() => handleClose()} testHook="closeFilter">
                      {i18n.t('common:actions.close')}
                    </Btn>
                    <Btn color="blue" testHook="searchStepAction" onClick={() => handleSubmit()}>
                      {i18n.t('workflow:stepSearch:action')}
                    </Btn>
                  </div>
                </div>
                {error && (
                  <Notification kind="error" testHook="stepSearchError">
                    {error}
                  </Notification>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default StepSearch;
