import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { UITable } from '~/components/src/UITable';
import { Spinner } from '~/components';
import { TCustomeEvent, TCustomerEventsComponentProps } from './types';
import { DATE_FORMAT, TIME_FORMAT } from './constants';

const columnHelper = createColumnHelper<TCustomeEvent>();

const CustomerEventsComponent = ({ customerEventsState, t }: TCustomerEventsComponentProps) => {
  const getColumns = () => [
    columnHelper.accessor('timestamp', {
      header: () => <div className="text-left">{t('customerData:events.timestamp')}</div>,
      cell: info => (
        <div className="text-left">
          <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>{' '}
          <span className="text-r42-blue text-xs">{moment(info.getValue()).format(TIME_FORMAT)}</span>
        </div>
      ),
      size: 600,
    }),
    columnHelper.accessor('eventType', {
      header: () => <div className="text-left">{t('customerData:events.eventType')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 600,
    }),
    columnHelper.accessor('type', {
      header: () => <div className="text-left">{t('customerData:events.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 600,
    }),
    columnHelper.accessor('properties', {
      header: () => <div className="text-left">{t('customerData:events.properties')}</div>,
      cell: info => {
        const properties = info.getValue();
        return (
          <div className="flex flex-col gap-1">
            {properties &&
              Object.entries(properties).map(([key, value]) => (
                <div key={key} className="text-left">
                  {key}: {value}
                </div>
              ))}
          </div>
        );
      },
      size: 600,
    }),
  ];

  const { value, error } = customerEventsState || {};

  if (!value && !error) return <Spinner />;

  return (
    <UITable
      data={value || []}
      stickyHeader={false}
      testHook="customerEventsTable"
      emptyBody={t('notFound')}
      columns={getColumns()}
    />
  );
};

export default CustomerEventsComponent;
