import React, { Fragment } from 'react';
import * as _ from 'lodash';
import cx from 'classnames';
import { getAngularService } from 'ReactAngular/react.service';
import filterTreeData from '~/profiles/helpers/treeFilteringHelper';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import Breadcrumb from '~/components/src/Breadcrumb';
import Tree from '~/components/src/Tree';
import LeftPanel from '~/components/src/LeftPanel/LeftPanel';
import RightPanel from '~/components/src/RightPanel';
import RulesList, { RuleCardTypes } from '~/components/src/RuleList';
import PageWithLeftMenu from '~/components/src/PageWithLeftMenu';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import AudienceDetails from '~/profiles/components/AudienceDetails';
import AudienceConnectors from '~/profiles/components/AudienceConnectors';
import AudienceActivityContainer from '~/profiles/audiences/activity/container';
import { changeUrl, buildUrl } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import { MediumWrapper } from '~/components/src/Containers';
import { AudienceHeaderButtons } from '../../components/AudienceHeaderButtons';
import './styles.scss';

const handleCreateAudience = parentId => {
  let createAudiencePageUrl = 'audiences/segments/add';

  if (parentId) {
    createAudiencePageUrl += `?parent=${parentId}`;
  }

  changeUrl(createAudiencePageUrl);
};

export const getAudienceRowButtons = (rowInfo, openDeleteAudienceGroupModal, t, canEditAudience) => {
  const isGroup = rowInfo.node.isDirectory;
  const isEmptyGroup = isGroup && rowInfo.node.children.length === 0;

  const { id, title } = rowInfo.node;

  const buttons = [];

  if (isGroup && canEditAudience) {
    buttons.push(
      <BtnIcon
        testHook="createAudienceButton"
        onClick={() => {
          handleCreateAudience(id);
        }}
        className="h-5 w-5 p-0.5"
        icon="add"
        tooltip={t('list.actions.createAudienceInGroup')}
      />,
    );
  }

  if (isEmptyGroup && canEditAudience) {
    buttons.push(
      <BtnIcon
        testHook="deleteGroupButton"
        onClick={() => {
          openDeleteAudienceGroupModal(title, id);
        }}
        icon="delete"
        tooltip={t('list.actions.deleteGroup')}
        className="h-5 w-5 p-0.5"
      />,
    );
  }

  return buttons;
};

const triggerWindowResizeToRedrawCharts = event => {
  // Resizes Highcharts in the RightPanel after expanding / shrinking the LeftPanel
  const isLeftPanelToggleFinished = event.propertyName === 'width' && event.target.classList.contains('LeftPanel');

  if (isLeftPanelToggleFinished) {
    window.dispatchEvent(new Event('resize'));
  }
};

const readLeftPanelStateFromLocalStorage = () => {
  try {
    return localStorage.getItem('audienceViewLeftPanelExpanded') === 'true';
  } catch (error) {
    return false;
  }
};

const saveLeftPanelStateInLocalStorage = isLeftPanelExpanded => {
  try {
    localStorage.setItem('audienceViewLeftPanelExpanded', isLeftPanelExpanded);
  } catch (error) {
    // Ignore localStorage errors
  }
};

export class AudienceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSupportEngineer: false,
      canEditAudience: false,
      treeData: [],
      selectedNode: {},
      treeFilter: '',
      isLeftPanelExpanded: readLeftPanelStateFromLocalStorage() || false,
    };
  }

  componentDidMount() {
    const { params, fetchAudience, fetchAudienceBreadcrumbs, fetchAudiencesTree } = this.props;
    const { audienceId } = params;
    const SecurityService = getAngularService(document, 'SecurityService');

    this.onDeleteAudience = this.onDeleteAudience.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCloneAudience = this.onCloneAudience.bind(this);
    this.updateTreeData = this.updateTreeData.bind(this);
    this.handleTreeSelection = this.handleTreeSelection.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterValues = this.filterValues.bind(this);
    this.toggleLeftPanel = this.toggleLeftPanel.bind(this);

    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isSupportEngineer: context.isSupportEngineer(),
        canEditAudience: context.hasPermission('AUDIENCES_EDIT'),
      });
    });

    fetchAudience(audienceId);
    fetchAudienceBreadcrumbs();
    fetchAudiencesTree();

    if (_.isEmpty(this.state.selectedNode)) {
      this.setState({ selectedNode: { node: { id: audienceId } } });
    }
  }

  componentDidUpdate(prevProps) {
    const { treeData, params, fetchAudienceBreadcrumbs } = this.props;
    const { audienceId } = params;
    if (prevProps.treeData !== treeData) {
      fetchAudienceBreadcrumbs();
      this.setState({ treeData });
    }
    // Originally was added to fix back navigation but is used for the tree to
    // navigate between audiences
    if (prevProps.params.audienceId !== audienceId) {
      this.setState({ selectedNode: { node: { id: audienceId } } });
      this.props.fetchAudience(audienceId, false);
    }
  }

  onDeleteAudience() {
    const id = _.get(this.props.audience, 'segment.segmentId');
    const title = _.get(this.props.audience, 'segment.name');
    this.props.openDeleteAudienceDialog(id, title);
  }

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  onCloneAudience() {
    const { audience, parentId, navigator, cloneAudience } = this.props;
    cloneAudience(audience.segment.segmentId, parentId, navigator);
  }

  onEdit() {
    const url = `audiences/segments/${this.props.audience.segment.segmentId}/edit`;
    changeUrl(url);
  }

  handleTreeSelection(rowInfo) {
    if (!rowInfo.node.isDirectory) {
      this.setState({ selectedNode: rowInfo }, () => {
        const audienceURL = buildUrl(`audiences/segments/view/${rowInfo.node.id}`);
        this.props.navigate(audienceURL, { replace: true });
      });
    }
  }

  renderRules() {
    if (this.props.audience.segment) {
      return (
        <div className="u-marginTopL">
          <RulesList criteria={this.props.criteria} ruleCardType={RuleCardTypes.ViewCard} type="audience" />
        </div>
      );
    }
    return null;
  }

  onFilterChange(event) {
    const treeFilter = event.target.value ? event.target.value.toLowerCase() : '';
    this.setState({ treeFilter }, () => {
      this.filterValues();
    });
  }

  filterValues() {
    const { treeFilter } = this.state;
    const { treeData } = this.props;

    this.setState({ treeData: filterTreeData(treeFilter, treeData) });
  }

  toggleLeftPanel() {
    this.setState(currentState => {
      const isLeftPanelExpanded = !currentState.isLeftPanelExpanded;

      saveLeftPanelStateInLocalStorage(isLeftPanelExpanded);

      return {
        isLeftPanelExpanded,
      };
    });
  }

  render() {
    const {
      audience,
      details,
      isFetchingAudience,
      isFetchingTree,
      connectors,
      breadcrumbs,
      t,
      linkAudience,
      openCreateAudienceGroupModal,
      openDeleteAudienceGroupModal,
    } = this.props;
    const { treeData, selectedNode, isLeftPanelExpanded } = this.state;

    const isAudienceViewLoading = !audience || !audience.segment || isFetchingAudience;
    return (
      <PageWithLeftMenu className="Audience u-flex-row">
        <LeftPanel
          className={cx('', { 'w-96': isLeftPanelExpanded })}
          onTransitionEnd={triggerWindowResizeToRedrawCharts}
          isLoading={isFetchingTree}
          localStorageKey="audienceViewLeftPanelExpanded"
        >
          {isFetchingTree ? (
            <Spinner />
          ) : (
            <div className="flex h-full flex-col">
              <div className="relative flex items-center border-b border-gray-200 bg-gray-50 px-2 pb-3 pt-4">
                <SearchElement
                  className="flex-1"
                  onChange={this.onFilterChange}
                  placeholder={t('audiencePage.filter')}
                  testHook="searchInput"
                  value={this.state.treeFilter}
                />
                {this.state.canEditAudience && (
                  <>
                    <BtnIcon
                      onClick={() => {
                        handleCreateAudience();
                      }}
                      className="ml-2"
                      icon="add"
                      tooltip={t('list.actions.createRootAudience')}
                      testHook="createRootAudience"
                    />
                    <BtnIcon
                      onClick={() => {
                        this.setState({ treeFilter: '' });
                        openCreateAudienceGroupModal();
                      }}
                      className="ml-2"
                      icon="addFolder"
                      tooltip={t('list.actions.createRootGroup')}
                      testHook="createRootGroup"
                    />
                  </>
                )}
                <BtnIcon
                  className={cx('absolute bottom-[-8px] right-[-8px] z-10 h-5 w-5 rounded-full p-0.5', {
                    'rotate-180 transition': isLeftPanelExpanded,
                  })}
                  onClick={this.toggleLeftPanel}
                  icon="cheveronRight"
                />
              </div>
              <Tree
                treeData={treeData}
                onNodeClick={this.handleTreeSelection}
                handleTreeChange={data => {
                  if (treeData !== data) {
                    this.updateTreeData(data);
                  }
                }}
                handleMove={data =>
                  linkAudience(data.node.id, data.nextParentNode ? data.nextParentNode.id : undefined)
                }
                selectedNode={selectedNode}
                t={t}
                getRowButtons={rowInfo =>
                  getAudienceRowButtons(rowInfo, openDeleteAudienceGroupModal, t, this.state.canEditAudience)
                }
              />
            </div>
          )}
        </LeftPanel>
        <RightPanel>
          <Page className="u-paddingXl">
            {isAudienceViewLoading ? (
              <Spinner />
            ) : (
              <Fragment>
                <header className="Aligner Aligner--spaceBetween">
                  <Breadcrumb fileName={audience.segment.name} crumbs={breadcrumbs} />
                  <AudienceHeaderButtons
                    audience={audience}
                    t={this.props.t}
                    onEditClick={this.onEdit}
                    onCloneClick={this.onCloneAudience}
                    onDeleteClick={this.onDeleteAudience}
                    canEdit={this.state.canEditAudience}
                    canClone={this.state.canEditAudience}
                    canDelete={this.state.canEditAudience && audience.deletability && audience.deletability.canDelete}
                    showClone={this.state.isSupportEngineer}
                  />
                </header>
                <div className="u-marginTopM Audience-ServiceData">
                  <b>{t('audiencePage.apiId')}: </b>
                  {audience.apiIdentifier}
                </div>
                <Tabs>
                  <TabList>
                    <Tab testHook="activityTab">{t('audiencePage.tabs.audienceActivity')}</Tab>
                    <Tab testHook="ruleTab">{t('audiencePage.tabs.rules')}</Tab>
                    <Tab testHook="connectorTab">{t('audiencePage.tabs.connectors')}</Tab>
                    <Tab testHook="detailsTab">{t('audiencePage.tabs.details')}</Tab>
                  </TabList>

                  <TabPanel>
                    <AudienceActivityContainer
                      key={`audienceActivity-${audience.segment.segmentId}`}
                      audience={audience}
                    />
                  </TabPanel>
                  <TabPanel>{this.renderRules()}</TabPanel>
                  <TabPanel>
                    <MediumWrapper>
                      <AudienceConnectors
                        key={`audienceActivity-${audience.segment.segmentId}`}
                        connectors={connectors}
                      />
                    </MediumWrapper>
                  </TabPanel>
                  <TabPanel>
                    <MediumWrapper>
                      <AudienceDetails key={`audienceActivity-${audience.segment.segmentId}`} details={details} />
                    </MediumWrapper>
                  </TabPanel>
                </Tabs>
              </Fragment>
            )}
          </Page>
        </RightPanel>
      </PageWithLeftMenu>
    );
  }
}
