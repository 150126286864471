import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mode } from '~/components/src/Code';
import { Field, change } from 'redux-form';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import Link from '~/components/src/Link';
import { Panel } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import { findIndex, max } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isRequired, isUnique, isPositiveNumber, normalizeToInteger } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormCodeField,
} from '~/components/src/Form/Fields/ReduxFormFields';

const createPossibleOptions = basescriptModule => {
  const possibleVersions = basescriptModule.possibleVersions.map(possibleVersion => ({
    value: possibleVersion,
    label: possibleVersion,
  }));
  if (basescriptModule.optional) {
    possibleVersions.push({ value: '', label: '-' });
  }
  return possibleVersions;
};
const findModuleIndex = (basescript, moduleName) =>
  findIndex(basescript.modules, module => module.basescriptModule.name === moduleName);

export class BasescriptForm extends Component {
  constructor(props) {
    super(props);
    this.isVersionUnique = isUnique(
      props.otherBasescripts,
      'version',
      props.t('basescripts:form.version.uniqueVersion'),
    );
  }

  render() {
    const {
      initialValues,
      basescriptModules,
      cancelHref,
      handleSubmit,
      submitting,
      t,
      formBasescriptModules,
      onUseLatestClick,
      authenticated,
      touch,
    } = this.props;
    return (
      <>
        <Heading title={t('basescript.header')} />
        <Panel>
          <Form onSubmit={handleSubmit} isSubmitting={submitting}>
            <div>
              <Field
                component={ReduxFormInputField}
                label={t('form.version.label')}
                name="version"
                placeholder={t('form.version.placeholder')}
                type="text"
                validate={[isRequired, this.isVersionUnique]}
                disabled={!authenticated}
              />
              <Field
                component={ReduxFormCodeField}
                isBlock={true}
                mode={mode.js}
                label={t('form.prependScript.label')}
                name="prependScript"
                validate={isRequired}
              />
              {basescriptModules.map(basescriptModule => {
                const moduleIndex = findModuleIndex(initialValues, basescriptModule.module.name);
                const latestVersion = max(basescriptModule.possibleVersions);
                const selectMaxField =
                  authenticated &&
                  formBasescriptModules &&
                  formBasescriptModules[moduleIndex].version !== latestVersion ? (
                    <div className="u-marginTopM">
                      <Link onClick={() => onUseLatestClick(moduleIndex, latestVersion)}>{t('form.useLatest')}</Link>
                    </div>
                  ) : null;
                return (
                  <div key={basescriptModule.module.name}>
                    <Field
                      label={basescriptModule.module.label}
                      name={`modules[${moduleIndex}].version`}
                      component={ReduxFormSelectField}
                      options={createPossibleOptions(basescriptModule)}
                      type="text"
                      disabled={!authenticated}
                      suffix={selectMaxField}
                      touch={touch}
                    />
                  </div>
                );
              })}
              <Field
                label={t('form.cacheExpireTime.label')}
                name="cacheExpiryTimeSeconds"
                component={ReduxFormInputField}
                normalize={normalizeToInteger}
                validate={isPositiveNumber}
                type="number"
                disabled={!authenticated}
              />
            </div>
            {authenticated ? <FormActions t={t} submitting={submitting} cancelHref={cancelHref} /> : null}
          </Form>
        </Panel>
      </>
    );
  }
}

BasescriptForm.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  basescriptModules: PropTypes.array.isRequired,
  formBasescriptModules: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  connect(null, (dispatch, ownProps) => ({
    onUseLatestClick: (moduleIndex, maxOption) =>
      dispatch(change(ownProps.form, `modules[${moduleIndex}].version`, maxOption)),
  })),
)(BasescriptForm);
