/* eslint-disable no-prototype-builtins */
/**
 * @description Service coordinating the URL Builder Admin management
 * @class UrlBuilderConfigService
 * @namespace webUi.service.admin.urlbuilderconfig
 */
angular.module('webUi.service.admin.urlbuilder', [
    'restangular'
])

    .factory('UrlBuilderConfigService', ['$q', 'Restangular',
        function UrlBuilderConfigService($q, Restangular) {

            // The base path through which all calls should go
            var BASE_PATH = 'admin/url-builder/';

            /**
         * @returns {String}
         */
            var getBasePath = function(){
                return BASE_PATH;
            };

            /**
         * @description Gets a column
         * @returns {*}
         */
            var getColumn = function (columnId) {

                var columnPromise = $q.defer();

                if (!columnId) {
                    columnPromise.resolve({});
                    return columnPromise.promise;
                }

                Restangular.all(BASE_PATH + 'columns', columnId).get().then(
                    function onSuccess(response) {
                        if(response) {
                            columnPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return columnPromise.promise;
            };

            /**
         * @description Gets all columns
         * @returns {*}
         */
            var getColumns = function getColumns() {

                var columnsPromise = $q.defer();

                Restangular.all(BASE_PATH + 'columns').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            columnsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return columnsPromise.promise;
            };

            /**
         * @description Creates a column
         * @param {Object} createCommand
         * @returns {*}
         */
            var addColumn = function(createCommand) {

                var _request = {};

                if (createCommand.name) {
                    _request.name = createCommand.name;
                }

                if (createCommand.orderNumber) {
                    _request.orderNumber = parseInt(createCommand.orderNumber, 10);
                }

                if (createCommand.description) {
                    _request.description = createCommand.description;
                }

                var columnPromise = $q.defer();

                Restangular.all(BASE_PATH + 'columns').post(_request).then(
                    function onSuccess(response) {
                        if(response) {
                            columnPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return columnPromise.promise;
            };

            /**
         * @description Updates a column
         * @param {Object} updateCommand
         * @returns {*}
         */
            var updateColumn = function updateColumn(updateCommand) {

                var columnPromise = $q.defer();

                var _request = {};

                if (updateCommand.columnId) {
                    _request.columnId = updateCommand.columnId;
                } else {
                    columnPromise.reject({'error': 'Column ID not specified'});
                    return columnPromise.promise;
                }

                if (updateCommand.name) {
                    _request.name = updateCommand.name;
                }

                if (updateCommand.orderNumber) {
                    _request.orderNumber = parseInt(updateCommand.orderNumber,10);
                }

                if (updateCommand.description) {
                    _request.description = updateCommand.description;
                }

                Restangular.all(BASE_PATH + 'columns' + '/' + _request.columnId).customPUT(_request).then(
                    function onSuccess() {
                        columnPromise.resolve({'columnId': _request.columnId});
                    },
                    function onError() {
                        columnPromise.reject({'error' : 'Error updating column'});
                    }
                );

                return columnPromise.promise;
            };

            /**
         * @description Deletes a column
         * @param columnId
         * @returns {*}
         */
            var deleteColumn = function deleteColumn(columnId) {

                var columnPromise = $q.defer();

                Restangular.one(BASE_PATH + 'columns', columnId).remove().then(
                    function onSuccess() {
                        columnPromise.resolve({});
                    },
                    function onError() {}
                );
                return columnPromise.promise;
            };

            /**
         * @description Creates a type
         * @param {Object} createCommand
         * @returns {*}
         */
            var addType = function(createCommand) {

                var _request = {};

                if (createCommand.name) {
                    _request.name = createCommand.name;
                }

                if (createCommand.prefix) {
                    _request.prefix = createCommand.prefix;
                }
                if (createCommand.keyParameter) {
                    _request.keyParameter = createCommand.keyParameter;
                }

                var typePromise = $q.defer();

                Restangular.all(BASE_PATH + 'types').post(_request).then(
                    function onSuccess(response) {
                        if(response) {
                            typePromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return typePromise.promise;
            };

            /**
         * @description Gets all (available) types
         * @returns {*}
         */
            var getTypes = function getTypes() {

                var typesPromise = $q.defer();

                Restangular.all(BASE_PATH + 'types').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            typesPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return typesPromise.promise;
            };

            /**
         * @description Updates a type
         * @param {Object} updateCommand
         * @returns {*}
         */
            var updateType = function updateType(updateCommand) {

                var typePromise = $q.defer();

                var _request = {};

                if (updateCommand.typeId) {
                    _request.typeId = updateCommand.typeId;
                } else {
                    typePromise.resolve({'error': 'Type ID not specified'});
                    return typePromise.promise;
                }

                if (updateCommand.name) {
                    _request.name = updateCommand.name;
                }

                if (updateCommand.prefix) {
                    _request.prefix = updateCommand.prefix;
                }
                if (updateCommand.keyParameter) {
                    _request.keyParameter = updateCommand.keyParameter;
                }

                Restangular.all(BASE_PATH + 'types' + '/' + _request.typeId).customPUT(_request).then(
                    function onSuccess() {
                        typePromise.resolve({'typeId': _request.typeId});
                    },
                    function onError() {}
                );

                return typePromise.promise;
            };

            /**
         * @description Deletes a type
         * @param {UUID} typeId
         * @returns {*}
         */
            var deleteType = function deleteType(typeId) {
                return Restangular.one(BASE_PATH + 'types', typeId).remove();
            };


            var getPredefinedValues = function getPredefinedValues(){

                var predefinedPromise = $q.defer();

                Restangular.all(BASE_PATH + 'predefined-values').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            predefinedPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return predefinedPromise.promise;
            };

            var addPredefinedValue = function addPredefinedValue(createCommand) {

                var _request = {};

                if (createCommand.predefinedValuesSetId) {
                    _request.predefinedValuesSetId = createCommand.predefinedValuesSetId;
                }

                if (createCommand.predefinedValuesSetName) {
                    _request.predefinedValuesSetName = createCommand.predefinedValuesSetName;
                }
                if (createCommand.predefinedValuesSetValues) {
                    _request.predefinedValuesSetValueList = createCommand.predefinedValuesSetValues;
                }

                var predefinedPromise = $q.defer();

                Restangular.all(BASE_PATH + 'predefined-values').post(_request).then(
                    function onSuccess(response) {
                        if(response) {
                            predefinedPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return predefinedPromise.promise;
            };


            var updatePredefinedValue = function updatePredefinedValue(updateCommand) {
                var predefinedPromise = $q.defer();

                var _request = {};

                if (updateCommand.predefinedValuesSetId) {
                    _request.predefinedValuesSetId = updateCommand.predefinedValuesSetId;
                } else {
                    return;
                }

                if (updateCommand.predefinedValuesSetName) {
                    _request.predefinedValuesSetName = updateCommand.predefinedValuesSetName;
                }
                if (updateCommand.predefinedValuesSetValues) {
                    _request.predefinedValuesSetValueList = updateCommand.predefinedValuesSetValues;
                }



                Restangular.all(BASE_PATH + 'predefined-values' + '/' + _request.predefinedValuesSetId).customPUT(_request).then(
                    function onSuccess() {
                        predefinedPromise.resolve({'predefinedValuesSetId': _request.predefinedValuesSetId});
                    },
                    function onError() {}
                );

                return predefinedPromise.promise;
            };

            var deletePredefinedValue = function deletePredefinedValue(pvId) {

                return Restangular.one(BASE_PATH + 'predefined-values', pvId).remove();
            };

            /**
         *
         * @param command
         * @private
         */
            var _exportConfigRequestBuilder = function(command) {

                var _request = {};
                var _configuration = {};

                if (command.hasOwnProperty('enabled')) {
                    _request['enabled'] = command['enabled'];
                }

                if (command.configId) {
                    _request.configId = command.configId;
                }

                if (command.exportAccountId) {
                    _request.exportAccountId = command.exportAccountId;
                }

                if (command.connectionConfiguration) {

                    if (command.connectionConfiguration.connectionType) {
                        _configuration.connectionType = command.connectionConfiguration.connectionType;
                    }

                    if (command.connectionConfiguration.loginType) {
                        _configuration.loginType = command.connectionConfiguration.loginType;
                    }

                    if (command.connectionConfiguration.host) {
                        _configuration.host = command.connectionConfiguration.host;
                    }

                    if (command.connectionConfiguration.port) {
                        _configuration.port = command.connectionConfiguration.port;
                    }

                    if (command.connectionConfiguration.username) {
                        if (command.connectionConfiguration.loginType !== 'ANONYMOUS') {
                            _configuration.username = command.connectionConfiguration.username;
                        }
                    }

                    if (command.connectionConfiguration.password) {
                        if (command.connectionConfiguration.loginType !== 'ANONYMOUS') {
                            _configuration.password = command.connectionConfiguration.password;
                        }
                    }

                    if (command.connectionConfiguration.directory) {
                        _configuration.directory = command.connectionConfiguration.directory;
                    }

                    //FTPS Only
                    if (command.connectionConfiguration.useExplicitTls) {
                        _configuration.useExplicitTls = command.connectionConfiguration.useExplicitTls;
                    }
                }

                _request.configuration = _configuration;
                return _request;
            };

            /**
         * @description Creates an export config
         * @param {Object} createCommand
         * @returns {*}
         */
            var addExportConfig = function addExportConfig(createCommand) {

                var exportConfigPromise = $q.defer();

                var _request = _exportConfigRequestBuilder(createCommand);

                Restangular.all(BASE_PATH + 'exportconfigs').post(_request).then(
                    function onSuccess(response) {
                        if(response) {
                            exportConfigPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return exportConfigPromise.promise;
            };

            /**
         * @description Gets an export config
         * @param {UUID} configId
         * @returns {*}
         */
            var getExportConfig = function getExportConfig(configId) {

                var exportConfigPromise = $q.defer();

                if (!configId) {
                    exportConfigPromise.resolve({});
                    return exportConfigPromise.promise;
                }

                Restangular.all(BASE_PATH + 'exportconfigs', configId).get().then(
                    function onSuccess(response) {
                        if(response) {
                            exportConfigPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return exportConfigPromise.promise;
            };

            /**
         * @description Gets all (available) export configs
         * @returns {*}
         */
            var getExportConfigs = function getExportConfigs() {

                var exportConfigsPromise = $q.defer();
                Restangular.all(BASE_PATH + 'exportconfigs').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            exportConfigsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return exportConfigsPromise.promise;
            };

            /**
         * @description Updates an export config
         * @param {Object} updateCommand
         * @returns {*}
         */
            var updateExportConfig = function updateExportConfig(updateCommand) {

                var updateExportPromise = $q.defer();
                var _request = _exportConfigRequestBuilder(updateCommand);

                Restangular.all(BASE_PATH + 'exportconfigs' + '/' + _request.configId).customPUT(_request).then(
                    function onSuccess() {
                        updateExportPromise.resolve({'configId': _request.configId});
                    },
                    function onError() {}
                );

                return updateExportPromise.promise;
            };

            /**
         * @description Deletes an export config
         * @param {UUID} typeId
         * @returns {*}
         */
            var deleteExportConfig = function deleteExportConfig(exportConfigId) {
                return Restangular.one(BASE_PATH + 'exportconfigs', exportConfigId).remove();
            };


            /**
         *
         * @param runCommand
         * @returns {*}
         */
            var runExport = function runExport(configId) {

                var exportPromise = $q.defer();

                if (!configId) {
                    exportPromise.resolve({});
                    return exportPromise.promise;
                }

                var _request = {
                    'configId': configId
                };

                //In case exception: {status: "failed", errorMessage: "Exception"}
                Restangular.all(BASE_PATH + 'report').post(_request).then(
                    function onSuccess(response) {
                        if(response) {
                            exportPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return exportPromise.promise;
            };

            var getNextAvailableColumnOrder = function getNextAvailableColumnOrder(columnOrderNumbers) {
                var next = 1;
                if (columnOrderNumbers && !_.isEmpty(columnOrderNumbers)) {
                    next = _.max(columnOrderNumbers) + 1;
                }
                return next;
            };

            /**
         * RMP API
         * @type {UrlBuilderConfigService}
         */
            var service = {
                getBasePath: getBasePath,

                getColumn: getColumn,
                getColumns: getColumns,
                addColumn: addColumn,
                deleteColumn: deleteColumn,
                updateColumn: updateColumn,

                getTypes: getTypes,
                addType: addType,
                deleteType: deleteType,
                updateType: updateType,

                getPredefinedValues : getPredefinedValues,
                addPredefinedValue : addPredefinedValue,
                updatePredefinedValue : updatePredefinedValue,
                deletePredefinedValue : deletePredefinedValue,

                getExportConfig: getExportConfig,
                getExportConfigs: getExportConfigs,
                addExportConfig: addExportConfig,
                deleteExportConfig: deleteExportConfig,
                updateExportConfig: updateExportConfig,

                runExport: runExport,
                getNextAvailableColumnOrder: getNextAvailableColumnOrder
            };
            return service;

        }
    ]);
