import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Panel } from '~/components/src/Containers';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import cx from 'classnames';
import UsageIcons from './UsageIcons';
import { ALLOWED_NUMBER_OF_PROPERTIES, CUSTOMER_FACT_USED_IN } from '../customerFacts/types';

const { Table, Row, Cell, RowActions, HeaderCell } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('list.empty.header')} body={t('list.empty.body')} />;
const NoResults = ({ t }) => <Empty header={t('list.noResults.header')} body={t('list.noResults.body')} />;

const getReadableTtl = ttl => {
  let formattedTtl = '';
  const days = Math.floor(ttl / (3600 * 24));
  ttl -= days * 3600 * 24;
  const hrs = Math.floor(ttl / 3600);
  ttl -= hrs * 3600;
  const mnts = Math.floor(ttl / 60);
  ttl -= mnts * 60;
  if (days !== 0) formattedTtl += `${days}d `;
  if (hrs !== 0) formattedTtl += `${hrs}h `;
  if (mnts !== 0) formattedTtl += `${mnts}m `;
  if (ttl !== 0) formattedTtl += `${ttl}s`;
  return formattedTtl;
};

const getReadableExpireDate = expiryDate => moment(expiryDate).format('DD/MM/YY, HH:mm:ss');

const createCustomerFactRow = (customerFact, otherProps) => {
  const {
    shouldShowPropertiesColumn,
    shouldShowExpireDate,
    shouldShowTtl,
    shouldShowActionsMenu,
    selectedCustomerFact,
    canEditProfiles,
    onEditClick,
    onViewClick,
    onDeleteClick,
    onDependantIconClick,
    t,
  } = otherProps;

  const actions = [
    {
      name: t('common.view'),
      isDisabled: false,
      onClick: () => onViewClick(customerFact.customerFactId),
      tooltip: t('common.view'),
      icon: () => {},
      testHook: 'view',
    },
    {
      name: t('common.edit'),
      isDisabled: !canEditProfiles,
      onClick: () => onEditClick(customerFact.customerFactId),
      tooltip: t('common.edit'),
      icon: () => {},
      testHook: 'edit',
    },
    {
      name: t('common.delete'),
      type: 'delete',
      isDisabled: !canEditProfiles || customerFact.hasDependants,
      onClick: () => onDeleteClick(customerFact),
      tooltip: customerFact.hasDependants ? t('edit.removeActionDisabledTooltip') : t('common.delete'),
      icon: () => {},
      testHook: 'delete',
    },
  ];
  const isSelected = selectedCustomerFact && customerFact.customerFactId === selectedCustomerFact.customerFactId;

  return (
    <Row
      key={customerFact.customerFactId}
      className={cx({ isSelected }, `t-${customerFact.name}Row`, 't-CustomerFactsListTableRow')}
      withActions
    >
      <Cell className="u-size3of12">
        <Link onClick={() => onViewClick(customerFact.customerFactId)}>{customerFact.name}</Link>
      </Cell>
      <Cell className="u-size1of12">{customerFact.typeLabel}</Cell>
      {shouldShowPropertiesColumn && (
        <Cell className="u-size2of12">{`${customerFact.numberOfProperties}/${ALLOWED_NUMBER_OF_PROPERTIES}`}</Cell>
      )}
      <Cell className="u-size2of12">{`${customerFact.numberOfUsedProperties}/${customerFact.numberOfProperties}`}</Cell>
      {shouldShowTtl && <Cell className="u-size2of12">{getReadableTtl(customerFact.originalTtl)}</Cell>}
      {shouldShowExpireDate && (
        <Cell className="u-size2of12">{getReadableExpireDate(customerFact.expireTimestamp)}</Cell>
      )}
      <Cell className="u-size3of12">
        <UsageIcons
          dependantTypes={customerFact.dependantTypes}
          elementUsedIn={CUSTOMER_FACT_USED_IN}
          elementId={customerFact.customerFactId}
          onDependantIconClick={onDependantIconClick}
        />
      </Cell>
      {shouldShowActionsMenu ? (
        <RowActions actions={actions} className="u-size1of12" />
      ) : (
        <Cell className="u-size1of12"> </Cell>
      )}
    </Row>
  );
};

export const CustomerFactsList = props => {
  const { list, shouldShowPropertiesColumn, shouldShowTtl, shouldShowExpireDate, className, stateKey, t } = props;

  return (
    <Panel>
      <Table stateKey={stateKey} className={className} testHook="CustomerFactsListTable">
        <Row>
          <SortableHeader className="u-size3of12" sortKey="name" title={t('list.name')} />
          <SortableHeader className="u-size1of12" sortKey="type" title={t('list.type')} />
          {shouldShowPropertiesColumn && (
            <SortableHeader
              className="u-size2of12"
              sortKey="numberOfProperties"
              sortFn={numberSort('numberOfProperties')}
              title={t('list.numberOfProperties')}
            />
          )}
          <SortableHeader
            className="u-size2of12"
            sortKey="numberOfUsedProperties"
            sortFn={numberSort('numberOfUsedProperties')}
            title={t('list.numberOfUsedProperties')}
          />
          {shouldShowTtl && (
            <SortableHeader className="u-size2of12" sortKey="ttl" sortFn={numberSort('ttl')} title={t('list.ttl')} />
          )}
          {shouldShowExpireDate && (
            <SortableHeader
              className="u-size2of12"
              sortKey="expiredDate"
              sortFn={numberSort('expiredDate')}
              title={t('list.expiredDate')}
            />
          )}
          <HeaderCell className="u-size3of12">{t('list.usedIn')}</HeaderCell>
          <HeaderCell className="u-size1of12"> </HeaderCell>
        </Row>
        {list.map(customerFact => createCustomerFactRow(customerFact, props))}
      </Table>
    </Panel>
  );
};

CustomerFactsList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  canEditProfiles: PropTypes.bool,
  onEditClick: PropTypes.func,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  onDependantIconClick: PropTypes.func.isRequired,
  selectedCustomerFact: PropTypes.object,
  shouldShowPropertiesColumn: PropTypes.bool,
  shouldShowActionsMenu: PropTypes.bool,
};

CustomerFactsList.defaultProps = {
  canEditProfiles: true,
  onEditClick: () => {},
  onDeleteClick: () => {},
  selectedCustomerFact: {},
  shouldShowPropertiesColumn: true,
  shouldShowActionsMenu: true,
  shouldShowExpireDate: false,
  shouldShowTtl: false,
};

export default compose(
  translate('customerFacts'),
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate(),
)(CustomerFactsList);
