import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as types from '../types';
import Delete from './ModalDelete';
import Content from './ModalContent';
import Custom from './CustomModal';
import Notification from './ModalNotification';

const MODAL_COMPONENTS = {
  [types.CONTENT_MODAL]: Content,
  [types.DELETE_MODAL]: Delete,
  [types.CUSTOM_MODAL]: Custom,
  [types.NOTIFICATION_MODAL]: Notification,
};

const ModalRoot = ({ type, props, t }) => {
  if (!type) {
    return null;
  }
  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} t={t} />;
};

ModalRoot.propTypes = {
  type: PropTypes.string,
  props: PropTypes.object,
};

export default compose(
  connect(state => state.modal),
  translate(['common']),
)(ModalRoot);
