angular
    .module('webUi.ui.admin.environmentsEdit', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.environmentsEdit', {
                url: '/environments/edit/{environmentId}',
                isReact: true,
            });
        },
    ]);
