import moment from 'moment';
import { formatNumber } from '~/common';
import { TCumulativeStats, TExperimentGroup, TMappedCumulativeStats } from './types';

export const mapExperimentCumulativeStats = (
  experimentGroups: TExperimentGroup[],
  experimentCumulativeStats: TCumulativeStats[],
): TMappedCumulativeStats[] =>
  experimentCumulativeStats?.map(stats => ({
    ...stats,
    name: experimentGroups.find(eg => eg.experimentGroupId === stats.experimentGroupId)?.name || '',
  }));

export const formatFloatValue = (val: number, precision = 2, convertToPercent = false) => {
  if (!Number.isNaN(val) && typeof val === 'number') {
    const value = convertToPercent ? parseFloat(val.toString()) * 100 : parseFloat(val.toString());
    return value.toFixed(precision);
  } else if (typeof val === 'string' && parseFloat(val) === 0) {
    return 0;
  }
  return 'n/a';
};

const mapChartSeries = (statsObj: any, days: number) => {
  const statsKeysArray = Object.keys(statsObj);
  const pointInterval = days === 7 ? 3600 * 1000 : 3600 * 24 * 1000;

  return statsKeysArray.map((statsKey: string) => {
    const statsValue = statsObj[statsKey];

    return {
      name: `Conversion for ${statsValue[0].experimentGroupName}`,
      data: statsValue.map((st: any) => st.cumulativeConversionRate),
      yAxis: 0,
      pointInterval,
      pointStart: statsValue.length === 0 ? 0 : statsValue[0].timestamp,
      animation: false,
      r42ChartSeriesIdentifier: statsKey,
      r42ChartSeriesVisible: true,
    };
  });
};

const getPlotBands = (days: number, color: string) => {
  const data = [];
  while (days > 0) {
    const date = moment().subtract(days, 'days');
    if (date.isoWeekday() === 7) {
      const _to = moment(date.toDate()).endOf('day').toDate();
      const _from = date.subtract(1, 'days').startOf('day').toDate();

      data.push({
        to: _to,
        from: _from,
        color,
        label: {
          text: `Weekend - ${moment(_from).week()}`,
          style: {
            color: '#ccc',
          },
        },
        id: `day-${days}`,
        borderColor: '#666',
      });
    }
    days -= 1;
  }

  return data;
};

const mapChartOptions = (stats: any, days: number) => {
  const series = mapChartSeries(stats, days);
  let _chartOptions = {};

  let _tickInterval;
  if (days === 7) {
    _tickInterval = 3600 * 1000; // hourly
  }
  if (days === 30 || days === 90) {
    _tickInterval = 24 * 3600 * 1000; // daily
  }

  _chartOptions = {
    series,
    title: '',
    chart: {
      zoomType: 'xy',
      type: 'spline',
      spacingBottom: 30,
      height: series.length ? 400 : 150,
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      title: {
        text: 'Date',
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e.%m',
      },
      tickInterval: _tickInterval,
      plotBands: getPlotBands(days, '#efefef'),
    },
    yAxis: {
      title: {
        text: 'Conversion rate (%)',
      },
    },
    plotOptions: {
      spline: {
        fillOpacity: 0.4,
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter() {
        let _return = `<span style="font-size:10px">${moment(this.x).format('D. MMM')}</span><table>`;

        this.points.forEach((point: any) => {
          _return +=
            `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td>` +
            `<td style="padding-left:30px;"><b>${formatNumber(point.y)}%</b></td></tr>`;
        });

        _return += '</table>';
        return _return;
      },

      shared: true,
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
  };

  return _chartOptions;
};

export const mapExperimentStats = (stats: any[], days: number) => {
  if (!stats) return {};

  return mapChartOptions(stats, days);
};

export const makeNewGroup = () => ({
  name: '',
  weight: 1,
  controlGroup: false,
});
