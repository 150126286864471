import { setPending, setFulfilled, setRejected } from '~/common';
import audienceActivityActionTypes from './actionTypes';
import audienceActivityInitialState from './initialState';

const audienceActivityReducer = (state = audienceActivityInitialState, action) => {
  switch (action.type) {
    case audienceActivityActionTypes.FETCH_STATS.fulfilled:
      return state.setIn(['promise', 'fetchingAudienceStats'], setFulfilled());
    case audienceActivityActionTypes.FETCH_STATS.pending:
      return state.setIn(['promise', 'fetchingAudienceStats'], setPending());
    case audienceActivityActionTypes.FETCH_STATS.rejected:
      return state.setIn(['promise', 'fetchingAudienceStats'], setRejected());
    case audienceActivityActionTypes.SET_AUDIENCE_STATS:
      return state.set('stats', action.data);

    case audienceActivityActionTypes.FETCH_PARTNER_STATS.fulfilled:
      return state.setIn(['promise', 'fetchingPartnerStats'], setFulfilled());
    case audienceActivityActionTypes.FETCH_PARTNER_STATS.pending:
      return state.setIn(['promise', 'fetchingPartnerStats'], setPending());
    case audienceActivityActionTypes.FETCH_PARTNER_STATS.rejected:
      return state.setIn(['promise', 'fetchingPartnerStats'], setRejected());
    case audienceActivityActionTypes.SET_PARTNER_STATS:
      return state.set('partnerStats', action.data);
    case audienceActivityActionTypes.SET_DOCUMENTATION_URL:
      return state.set('documentationUrl', action.data);

    default:
      return state;
  }
};

export default audienceActivityReducer;
