import React from 'react';
import PropTypes from 'prop-types';
import { changeUrl } from '~/common';
import { Select } from '~/components/src/Form/Elements/ReactSelect';
import { Tab, TabList, TabPanel } from '~/components/src/Tabs';
import RouterTabs from '~/components/src/RouterTabs';
import BtnIcon from '~/components/src/BtnIcon';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import Notification from '~/components/src/Notification';
import constants from './constants';
import ExportsTableComponent from './ExportsTableComponent';

const renderScheduledExports = (props, stateKey = 'scheduledColumns') => (
  <ExportsTableComponent
    t={props.t}
    stateKey={stateKey}
    columnCategory={stateKey}
    columns={constants.scheduledColumns}
    list={props.scheduledExportsData}
    fetchData={() => props.fetchScheduledExports()}
    isDataFetched={props.isFetchingScheduledExports}
    emptyHeader={props.t('empty.header')}
    emptyBody={props.t('empty.scheduledBody')}
    onDeleteExport={(type, id) => props.onDeleteExport(type, id)}
  />
);

const renderManualExports = (props, stateKey = 'manualColumns') => (
  <ExportsTableComponent
    t={props.t}
    stateKey={stateKey}
    columnCategory={stateKey}
    columns={constants.manualColumns}
    list={props.manualExportsData}
    fetchData={() => props.fetchManualExports(props.selectedRange)}
    isDataFetched={props.isFetchingManualExports}
    emptyHeader={props.t('empty.header')}
    emptyBody={props.t('empty.manualBody')}
    downloadManualExport={id => props.downloadManualExport(id)}
  />
);

const renderTabs = props => {
  const { t, isFetchingScheduledExports, isFetchingManualExports, selectedRange, setSelectedRange, error } = props;

  const options = [
    { value: '0', label: t('options.today') },
    { value: '7', label: t('options.last7days') },
    { value: '30', label: t('options.last30days') },
    { value: '90', label: t('options.last90days') },
  ];

  return (
    <RouterTabs className="u-paddingTopNone">
      {error && (
        <Notification kind="error" header={t('error')}>
          {error}
        </Notification>
      )}

      <TabList>
        <Tab testHook="scheduledTab">{t('tabs.scheduled')}</Tab>
        <Tab testHook="manualTab">{t('tabs.manual')}</Tab>
      </TabList>

      {/* Scheduled exports */}
      <TabPanel testHook="scheduledPanel">
        <Heading title={t('tabs.scheduled')} />
        <Spinner isActive={isFetchingScheduledExports} />
        {renderScheduledExports(props)}
      </TabPanel>

      {/* Manual exports */}
      <TabPanel testHook="manualPanel">
        <Heading title={t('tabs.manual')}>
          {t('selectLabel')} &nbsp;{' '}
          <Select
            className="TabSelect"
            defaultValue={options[1]}
            value={options.filter(option => option.value === selectedRange)[0]}
            onChange={option => setSelectedRange(option ? option.value : options[1].value)}
            options={options}
          />
        </Heading>
        <Spinner isActive={isFetchingManualExports} />
        {renderManualExports(props)}
      </TabPanel>
    </RouterTabs>
  );
};

const redirectToAddPage = props => {
  const { search } = props.location;
  changeUrl(`profiles/export/add${search}`);
};

const Export = props => (
  <Page>
    <Heading title={props.t('header')}>
      {props.canEditProfiles && (
        <BtnIcon
          icon="add"
          tooltip={props.t('addExport')}
          color="blue"
          onClick={() => redirectToAddPage(props)}
          testHook="addExport"
        />
      )}
    </Heading>
    {renderTabs(props)}
  </Page>
);

Export.propTypes = {
  t: PropTypes.func.isRequired,
  fetchScheduledExports: PropTypes.func.isRequired,
  isFetchingScheduledExports: PropTypes.bool.isRequired,
  scheduledExportsData: PropTypes.array.isRequired,
  fetchManualExports: PropTypes.func.isRequired,
  manualExportsData: PropTypes.array.isRequired,
  isFetchingManualExports: PropTypes.bool.isRequired,
  setSelectedRange: PropTypes.func.isRequired,
  selectedRange: PropTypes.string.isRequired,
  onDeleteExport: PropTypes.func.isRequired,
  downloadManualExport: PropTypes.func.isRequired,
  error: PropTypes.string,
  canEditProfiles: PropTypes.bool,
};

export default Export;
