angular.module('webUi.ui.context.base', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context', {
                'abstract': true,
                url: '/context',
                resolve : {
                    allowView: ['securityContext', function getPermissions(securityContext) {
                        if (securityContext.isContextAdmin() || securityContext.isSupportEngineer()) {
                            return true;
                        }
                        throw new AccessDeniedError('No access allowed');
                    }],
                    allowEdit : ['securityContext', function getPermissions(securityContext){
                        return securityContext.isContextAdmin();
                    }]
                }
            });	
    }]);
