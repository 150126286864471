import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '~/common';
import { getToastMessage } from '~/common/ApiService';
import { Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import DeletePopup from '~/components/src/DeletePopup';
import { AI_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { showError, showSuccess } from '~/notificationCenter';
import { MODEL_ACTIONS } from '../constants';
import { deleteModel, retractModel, trainModel } from '../dataService';
import { ModelContext } from '../ModelContext';
import { ModelActionProps } from '../types';
import DeployModel from './ModelDeploy';

const ActionIconMap = {
  AUDIT_LOG: 'schedule',
  DELETE: 'delete',
  DEPLOY: 'play',
  EDIT: 'edit',
  RETRACT: 'retract',
  STATS: 'dataUsage',
  TRAIN: 'train',
} as const;

const ModelActions = ({
  allowedActions,
  modelInfo,
  refetchData,
  isListPage = false,
}: ModelActionProps): React.ReactElement => {
  const { setSelectedModelId } = useContext(ModelContext);
  const navigate = useNavigate();

  const [modelIdForDeployment, setModelIdForDeployment] = useState<string | null>(null);
  const [selectedModelForDelete, setSelectedModelForDelete] = useState<string | null>(null);

  const { modelId, modelName } = modelInfo;

  const hideDeploymentModal = () => {
    setModelIdForDeployment(null);
    refetchData();
  };

  const deployModelAction = () => {
    setModelIdForDeployment(modelId);
  };

  const trainModelAction = async () => {
    try {
      await trainModel(modelId);
      refetchData();
      showSuccess({ header: i18n.t('ai:notifications.modelTrainSuccess') });
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  const retractModelAction = async () => {
    try {
      await retractModel(modelId);
      refetchData();
      showSuccess({ header: i18n.t('ai:notifications.modelRetractSuccess') });
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  const deleteModelAction = async () => {
    try {
      await deleteModel(modelId);
      if (isListPage) refetchData();
      else navigate(buildUrl('ai/models/list'));

      showSuccess({ header: i18n.t('ai:notifications.modelDeleteSuccess') });
      setSelectedModelForDelete(null);
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  const handleAction = (action: string) => {
    switch (action) {
      case MODEL_ACTIONS.AUDIT_LOG:
        setSelectedModelId(modelId);
        break;
      case MODEL_ACTIONS.DELETE:
        setSelectedModelForDelete(modelId);
        break;
      case MODEL_ACTIONS.DEPLOY:
        deployModelAction();
        break;
      case MODEL_ACTIONS.EDIT:
        navigate(buildUrl(`ai/models/edit/${modelId}`));
        break;
      case MODEL_ACTIONS.RETRACT:
        retractModelAction();
        break;
      case MODEL_ACTIONS.STATS:
        navigate(buildUrl(`ai/models/stats/${modelId}`));
        break;
      case MODEL_ACTIONS.TRAIN:
        trainModelAction();
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`No action defined for ${action}`);
    }
  };

  return (
    <div className="flex items-center gap-4">
      {allowedActions.map(action => (
        <BtnIcon
          key={action.name}
          icon={ActionIconMap[action.name]}
          onClick={() => handleAction(action.name)}
          tooltip={action.disabled ? action.reason : action.label}
          disabled={action.disabled}
          testHook={`${action.name.toLowerCase()}Model`}
          gaAction={action.name === MODEL_ACTIONS.TRAIN ? AI_EVENTS.MODEL.TRAINED : ''}
        />
      ))}

      {modelIdForDeployment && (
        <PickerView
          pickerTitle={i18n.t('ai:modelsPage.modelActions.deploy')}
          handlePickerVisibility={hideDeploymentModal}
          className="ModelActionPicker"
        >
          <DeployModel modelId={modelIdForDeployment} hideModal={hideDeploymentModal} />
        </PickerView>
      )}
      {selectedModelForDelete && (
        <DeletePopup
          title={i18n.t('ai:modelsPage.delete.header')}
          hidePopup={() => setSelectedModelForDelete(null)}
          onClick={deleteModelAction}
          gaAction={AI_EVENTS.MODEL.DELETED}
        >
          <Notification testHook="modelDeleteDescription">
            {i18n.t('ai:modelsPage.delete.body', { name: modelName })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
};

export default ModelActions;
