import React, { useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useAPI } from '~/common/ApiHooks';
import { Link, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { I18NextT } from '~/components/src/Common/types';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Spin from '~/components/src/Spin';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { getError } from './ErrorBlock';
import { addUserToGoogleAds, getConsentTypes, getGoogleAdsUsersList, getPayloadAndConsentType } from './service';
import {
  ConsentType,
  CreateNewUserType,
  GoogleAdsProps,
  IAddUserPayload,
  IGoogleAdsFormValues,
  IRemarketingUserListItem,
  IUseApiReturnValue,
} from './types';

const validations = (t: I18NextT) =>
  Yup.object().shape({
    userList: Yup.object().required(t('connectors:cards.validation.remarketingUser')),
    consent: Yup.string().nullable(),
  });

const GoogleAds = ({ onEdit, connector, openCreateUserModal, hideModal, t, isSubmitted, type }: GoogleAdsProps) => {
  const { partnerId, consent: initialFormConsent } = connector.partnerDetails;

  const [listItems, setListItems] = useState<IRemarketingUserListItem[]>([]);
  const [payloadType, setPayloadType] = useState('');
  const [connectorConsent, setConnectorConsent] = useState<null | ConsentType>(null);
  const [overrideConsent, setOverrideConsent] = useState(false);
  const {
    data,
    error,
    isLoading: isListLoading,
  }: IUseApiReturnValue = useAPI(() => getGoogleAdsUsersList(partnerId), [partnerId]);

  const { data: consentTypesData, isLoading: isConsentTypesLoading } = useAPI(() => getConsentTypes());

  useEffect(() => {
    getPayloadAndConsentType(partnerId).then((resp: { payloadType: string; consent: ConsentType }) => {
      setPayloadType(resp.payloadType);
      setConnectorConsent(resp.consent);
    });
  }, [partnerId]);

  const createNewUser: CreateNewUserType = ({ name, description, appId }, setFieldValue) => {
    const payload = {
      name,
      description,
    } as IAddUserPayload;

    if (appId) {
      payload.appId = appId;
    }

    addUserToGoogleAds(partnerId, payload)
      .then((response: IRemarketingUserListItem) => {
        setListItems([...listItems, response]);
        setFieldValue('userList', response);
        hideModal();
      })
      .catch(() => {
        // Ignore thrown errors
      });
  };

  // Set consent value to undefined on change of override
  const handleOverrideChange = (value: boolean, setFieldValue: FormikProps<IGoogleAdsFormValues>['setFieldValue']) => {
    setOverrideConsent(value);
    if (!value) setFieldValue('consent', null);
  };

  const isMobileId = payloadType === 'MOBILE_ID';

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {({ values, touched, errors, setFieldValue }: FormikProps<IGoogleAdsFormValues>) => {
        useEffect(() => {
          const userList = data || [];
          setListItems(userList);

          const userListId = values?.userList?.id;

          if (userListId) {
            const findUserList = userList.find(item => userListId === item?.id);

            if (findUserList) {
              setFieldValue('userList', findUserList);
            }
          }
        }, [data]);

        const consentTypes = consentTypesData || [];
        const existingUserNames = listItems.map(item => item.name);

        // Set override consent value on load if consent is already configured
        useEffect(() => {
          if (initialFormConsent) {
            setOverrideConsent(true);
            if (typeof initialFormConsent === 'object' && 'name' in initialFormConsent)
              setFieldValue('consent', initialFormConsent.name);
          }
        }, []);

        if (isConsentTypesLoading) return <Spin />;

        return (
          <>
            <div className="flex gap-4">
              <Field
                name="userList"
                as={FormikSelectField}
                label={t('connectors.googleAds.fieldLabel')}
                errorText={touched.userList && errors.userList}
                options={listItems}
                getOptionLabel={(option: IRemarketingUserListItem) => option.name}
                getOptionValue={(option: IRemarketingUserListItem) => option.id}
                value={values.userList}
                isLoading={isListLoading}
                testHook="googleAdsUserList"
                className="w-1/2"
              />
              <BtnIcon
                className="mt-10 rounded-full"
                testHook="addSegment"
                icon="add"
                tooltip={t('connectors.googleAds.userAdd.iconDesc')}
                onClick={() => openCreateUserModal(createNewUser, setFieldValue, isMobileId, existingUserNames)}
              />
            </div>
            <div className="py-4">
              {/* Consent Message */}
              <Notification
                kind="information"
                className="width my-6"
                header={t('partners:form.notification.acceptEUConsent.header')}
              >
                <p>{t('partners:form.notification.acceptEUConsent.message1.googleAds')}</p>
                <p className="mt-2">{t('partners:form.notification.acceptEUConsent.message2')}</p>
                <Link target="_blank" href={t('partners:form.notification.acceptEUConsent.link')}>
                  {t('partners:form.notification.acceptEUConsent.link')}
                </Link>
              </Notification>

              {/* Connector Consent configuration */}
              <div className="my-4 pb-2">
                <span className="text-gray-600">
                  {t('partners:form.notification.acceptEUConsent.connectorDefaultText')}:{' '}
                </span>
                <span className="text-r42-blue">
                  {connectorConsent?.label || t('partners:form.notification.acceptEUConsent.notConfigured')}
                </span>
              </div>

              {/* Override Checkbox */}
              <label className="t-overrideConsent inline-flex cursor-pointer items-center gap-3">
                <CheckboxElement
                  name="overrideConsent"
                  value={overrideConsent}
                  onChange={() => handleOverrideChange(!overrideConsent, setFieldValue)}
                />
                <span className="truncate text-ellipsis">
                  {t('partners:form.notification.acceptEUConsent.overrideLabel', { type })}
                </span>
              </label>

              {/* Consent field */}
              {overrideConsent && (
                <Field
                  name="consent"
                  as={FormikSelectField}
                  label={t('partners:form.formFields.acceptEUConsentSelection')}
                  errorText={touched?.consent && errors?.consent}
                  options={consentTypes || []}
                  getOptionLabel={(option: { name: string; label: string }) => option.label}
                  getOptionValue={(option: { name: string; label: string }) => option.name}
                  customSetFieldValue={(name: string, option: { name: string; label: string }) =>
                    setFieldValue(name, option.name)
                  }
                  value={values.consent ?? ''}
                  isLoading={isConsentTypesLoading}
                  className="mt-4 w-1/2"
                />
              )}
            </div>

            {error && <Notification kind="error">{getError(error?.status?.error?.errorType, t)}</Notification>}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default GoogleAds;
