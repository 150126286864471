import { getEndpointLocations, getPayloadTypes } from '../../connectorFormService';
import connectorFormActionTypes from '../../connectorFormActionTypes';

const tradeDeskActionTypes = {
  SET_ENDPOINT_LOCATIONS: 'SET_ENDPOINT_LOCATIONS',
  SET_PAYLOAD_TYPES: 'SET_PAYLOAD_TYPES',
};

const fetchEndpointLocations = partner => dispatch =>
  getEndpointLocations(partner)
    .then(payload => {
      dispatch({
        name: tradeDeskActionTypes.SET_ENDPOINT_LOCATIONS,
        data: payload.data,
        type: connectorFormActionTypes.TRADEDESK,
      });
    })
    .catch(() => {});

const fetchPayloadTypes = partner => dispatch =>
  getPayloadTypes(partner)
    .then(payload => {
      dispatch({
        name: tradeDeskActionTypes.SET_PAYLOAD_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.TRADEDESK,
      });
    })
    .catch(() => {});

export { tradeDeskActionTypes, fetchEndpointLocations, fetchPayloadTypes };
