import React from 'react';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import Page from '~/components/src/Page';
import LinkIcon from '~/components/src/LinkIcon';
import Heading from '~/components/src/Heading';
import CookieVariantsContainer from '../cookies/containers/CookiesVariantsContainer';

export const CookiesPage = ({ t }) => (
  <Page>
    <Heading title={t('list.header')}>
      <LinkIcon
        icon="add"
        color="blue"
        className="h-8 w-8"
        url={buildUrl('support/cookiepermissionforms/form')}
        tooltip={t('list.addNew')}
        testHook="addNew"
      />
    </Heading>
    <CookieVariantsContainer />
  </Page>
);

export default translate('cookies')(CookiesPage);
