import React, { useState } from 'react';
import { Moment } from 'moment';
import i18n from '~/i18n';
import { useApiWithState } from '~/common';
import { Chart } from '~/components';
import Spin from '~/components/src/Spin';
import { Panel } from '~/components/src/Containers';
import { TaggingCallsData } from '../types';
import { getTaggingCalls } from '../dataService';
import ActivityHeader from './ActivityHeader';
import { defaultDate } from '../constants';

const DeliveryNetwork = (): React.ReactElement => {
  const [filter, setFilter] = useState<Record<string, Moment>>({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const { startDate, endDate } = filter;

  const { state: taggingCalls, isLoading } = useApiWithState<TaggingCallsData>(
    () => getTaggingCalls(startDate.startOf('day').toDate().getTime(), endDate.endOf('day').toDate().getTime()),
    [startDate, endDate],
  );

  const { series, timestamp, total } = taggingCalls || {};

  if (isLoading && !taggingCalls) {
    return <Spin />;
  }

  return (
    <Panel>
      <ActivityHeader
        startDate={startDate}
        endDate={endDate}
        setFilter={setFilter}
        title={i18n.t('stats:deliveryNetwork.total', { total })}
        tooltip={i18n.t('stats:deliveryNetwork.tooltip')}
      />

      <Chart
        chartType="column"
        series={[
          {
            color: '#068df4',
            name: i18n.t('stats:deliveryNetwork.name'),
            data: series,
          },
        ]}
        yAxisTitle={i18n.t('stats:deliveryNetwork.yAxisTitle')}
        xAxisTitle={i18n.t('stats:chart.xAxisTitle')}
        xAxisLabels={timestamp}
        noDataMessage={i18n.t('stats:deliveryNetwork.noData')}
        height={290}
      />
    </Panel>
  );
};

export default DeliveryNetwork;
