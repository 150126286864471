import React, { useMemo } from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import Icons from '~/components/src/Icons';
import BtnOutlined from '~/components/src/BtnOutlined';
import { TFilter, TLabel } from '~/workflows/types';
import { Field, Formik } from 'formik';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { WORKFLOW_HP_STATUS_FILTER, WORKFLOW_STATUS_FILTER, WORKFLOW_TYPE_FILTER } from '~/workflows/constants';
import LabelSelect from './labelSelect';
import { filterInitialValue } from '..';

function Filter({
  toggleFilterMenu,
  labels,
  filters,
  setFilters,
  totalElements = 0,
  resetPage,
}: {
  toggleFilterMenu: () => void;
  labels: TLabel[];
  filters: TFilter;
  setFilters: React.Dispatch<React.SetStateAction<TFilter>>;
  totalElements?: number;
  resetPage: () => void;
}) {
  const hasFilter = useMemo(
    () =>
      ({ name, labelIds, journeyStatus, workflowType, historicProcessingStatus }: TFilter) =>
        name || labelIds.length !== 0 || journeyStatus || workflowType || historicProcessingStatus,
    [],
  );

  const filterCount = useMemo(
    () =>
      ({ name, labelIds, journeyStatus, workflowType, historicProcessingStatus }: TFilter) =>
        [name, labelIds.length !== 0, journeyStatus, workflowType, historicProcessingStatus].filter(Boolean).length,
    [],
  );

  const handleSubmit = (values: TFilter) => {
    setFilters(values);
    toggleFilterMenu();
    resetPage();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={filters} enableReinitialize={true}>
      {({ handleSubmit, setFieldValue, values, setValues }) => (
        <>
          <div className="fixed right-0 top-0 z-10 h-full w-1/4 bg-white pt-12 drop-shadow-md">
            <div className={cx('relative flex h-full flex-col gap-2')}>
              <div className={cx('flex items-center justify-between border-b border-gray-100 px-2 py-4')}>
                <div
                  className={cx('flex items-center gap-2', {
                    'text-blue-500': hasFilter(values),
                    'text-gray-500': !hasFilter(values),
                  })}
                >
                  <Icons icon="filter" className="h-6 w-6" />
                  {i18n.t('common:filters')}
                  {hasFilter(values) && ':'}
                  {hasFilter(values) && <span className="font-medium">{filterCount(values)}</span>}
                </div>

                {i18n.t('common:rows', { row: totalElements })}
              </div>

              <div className="t-filterContent mb-28 h-full overflow-y-auto px-4">
                <div className="flex flex-col gap-2 p-2 py-4">
                  <Field
                    name="name"
                    as={FormikInputField}
                    label={i18n.t('workflow:filter.label.search')}
                    placeholder={i18n.t('workflow:filter.placeholder.search')}
                    autoFocus={true}
                  />
                  <LabelSelect
                    {...{
                      labels,
                      labelIds: values?.labelIds,
                      setLabelIds: (values: string[]) => {
                        setFieldValue('labelIds', values);
                      },
                    }}
                  />
                  <Field
                    name="workflowType"
                    label={i18n.t('workflow:filter.label.workflowType')}
                    as={FormikSelectField}
                    options={WORKFLOW_TYPE_FILTER}
                    getOptionLabel={(option: { label: string; value: string }) => option.label}
                    getOptionValue={(option: { label: string; value: string }) => option.value}
                    customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                      setFieldValue(name, option.value);
                    }}
                  />
                  <Field
                    name="journeyStatus"
                    label={i18n.t('workflow:filter.label.status')}
                    as={FormikSelectField}
                    options={WORKFLOW_STATUS_FILTER}
                    getOptionLabel={(option: { label: string; value: string }) => option.label}
                    getOptionValue={(option: { label: string; value: string }) => option.value}
                    customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                      setFieldValue(name, option.value);
                    }}
                  />
                  <Field
                    name="historicProcessingStatus"
                    label={i18n.t('workflow:filter.label.hpStatus')}
                    as={FormikSelectField}
                    options={WORKFLOW_HP_STATUS_FILTER}
                    getOptionLabel={(option: { label: string; value: string }) => option.label}
                    getOptionValue={(option: { label: string; value: string }) => option.value}
                    customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                      setFieldValue(name, option.value);
                    }}
                  />
                </div>
              </div>
              <div className="fixed bottom-12 flex w-full items-center gap-2 border-t border-gray-100 bg-white p-4">
                <Btn onClick={() => toggleFilterMenu()} testHook="closeFilter">
                  {i18n.t('common:actions.close')}
                </Btn>
                <BtnOutlined
                  testHook="clearFilter"
                  onClick={() => {
                    setValues(filterInitialValue);
                    handleSubmit();
                  }}
                >
                  {i18n.t('common:actions.clear')}
                </BtnOutlined>
                <Btn onClick={() => handleSubmit()} testHook="applyFilter" color="blue">
                  {i18n.t('common:actions.search')}
                </Btn>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default Filter;
