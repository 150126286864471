import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { saveMessage } from '../messages/actions';
import MessageForm from '../components/MessageForm';
import { getClients } from '../helpers/clientsHelper';

export class NewMessagePage extends Component {
  constructor(props) {
    super(props);
    this.state = { clients: [] };
  }

  componentDidMount() {
    // AngularJS Service, not migrated to redux yet
    getClients(document).then(clients => {
      this.setState({
        clients,
      });
    });
  }

  render() {
    const { props, state } = this;
    return (
      <Page>
        <MediumWrapper>
          <Heading title={props.t('new.header')} />
          <Panel>
            <MessageForm backHref={buildUrl('support/messages/list')} clients={state.clients} {...props} />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    () => ({
      initialValues: {
        showDate: Date.now(),
      },
    }),
    dispatch => ({
      onSubmit: values => dispatch(saveMessage(values)),
    }),
  ),
  reduxForm({
    form: 'messageNew',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['messages']),
)(NewMessagePage);
