import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { EventResponseType } from './types';

const API_EC = `${API_BASE}/v1/eventConnectors`;

export const getAllEcTransformations = (eventConnectorId: string): Promise<any[]> =>
  Api.callGet(`${API_EC}/${eventConnectorId}/transformations`).then(parseResponse);

export const createTransformation = (eventConnectorId: string, payload: any): Promise<any> =>
  Api.callPost(`${API_EC}/${eventConnectorId}/transformations`, payload).then(parseResponse);

export const getEngagementProperties = (engagementId: string): Promise<EventResponseType> =>
  Api.callGet(`${API_BASE}/profiles/engagements/${engagementId}`).then(parseResponse);

export const getFactProperties = (factId: string): Promise<EventResponseType> =>
  Api.callGet(`${API_BASE}/profiles/externalfacts/${factId}`).then(parseResponse);
