import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { mapOptionType } from './utils';
import {
  CampaignDetails,
  CampaignMapping,
  CampaignMappingPayload,
  CampaignTree,
  CampaignType,
  CampaignsPayload,
  ColumnType,
  MappingColumn,
  PredefinedValue,
  UpdateMappingPayload,
} from './types';

const BASE_URL = `${API_BASE}/remote/url-builder`;

export const fetchCampaignsTree = (): Promise<CampaignTree[]> => Api.callGet(`${BASE_URL}/tree`).then(parseResponse);

export const fetchColumns = (): Promise<MappingColumn[]> => Api.callGet(`${BASE_URL}/columns`).then(parseResponse);

export const fetchCampaignTypes = (): Promise<CampaignType[]> => Api.callGet(`${BASE_URL}/types`).then(parseResponse);

export const fetchColumnTypes = (): Promise<ColumnType[]> =>
  Api.callGet(`${BASE_URL}/column-types`).then(parseResponse).then(mapOptionType);

export const fetchPredefinedValues = (): Promise<PredefinedValue[]> =>
  Api.callGet(`${BASE_URL}/predefined-values`).then(parseResponse);

export const fetchCampaignDetails = (campaignId: string): Promise<CampaignDetails> =>
  Api.callGet(`${BASE_URL}/campaigns/${campaignId}`).then(parseResponse);

export const fetchCampaignMappings = (campaignId: string): Promise<CampaignMapping[]> =>
  Api.callGet(`${BASE_URL}/mappings/${campaignId}`).then(parseResponse);

export const createCampaign = (payload: CampaignsPayload): Promise<string> =>
  Api.callPost(`${BASE_URL}/campaigns`, payload).then(parseResponse);

export const createMapping = (payload: CampaignMappingPayload): Promise<void> =>
  Api.callPost(`${BASE_URL}/mappings`, payload).then(parseResponse);

export const updateCampaign = (payload: CampaignsPayload, campaignId: string): Promise<void> =>
  Api.callPut(`${BASE_URL}/campaigns/${campaignId}`, payload).then(parseResponse);

export const updateMapping = (payload: UpdateMappingPayload): Promise<void> =>
  Api.callPut(`${BASE_URL}/mappings`, payload).then(parseResponse);

export const deleteCampaign = (campaignId: string): Promise<void> =>
  Api.callDelete(`${BASE_URL}/campaigns/${campaignId}`).then(parseResponse);

export const deleteMapping = (campaignId: string, mappingId: string): Promise<void> =>
  Api.callDelete(`${BASE_URL}/campaigns/${campaignId}/mappings/${mappingId}`).then(parseResponse);
