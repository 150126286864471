import React, { ReactElement } from 'react';
import Tippy from '@tippyjs/react';
import cx from 'classnames';
import { Placement, Content } from 'tippy.js';

interface TooltipProps {
  children?: React.ReactNode;
  className?: string;
  testHook?: string;
  tooltip?: Content | ReactElement;
  placement?: Placement;
}

const Tooltip = ({
  testHook = 'tooltip',
  children,
  tooltip,
  className,
  placement = 'bottom',
}: TooltipProps): React.ReactElement => (
  <Tippy className={cx(`t-${testHook}`, className)} content={tooltip} disabled={!tooltip} placement={placement}>
    <span>{children}</span>
  </Tippy>
);

export default Tooltip;
