import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import AdminCollectorsService from './dataService.js';
import { getChartConfig } from './collectorsChart';
/**
 * Fetch collectors
 */
export const fetchCollectors = () => dispatch => {
  dispatch({
    type: types.COLLECTORS_FETCH_START,
  });

  return AdminCollectorsService.getCollectors()
    .then(collectors => {
      dispatch({
        type: types.COLLECTORS_FETCH_SUCCESS,
        payload: collectors,
      });
    })
    .catch(error => dispatch({ type: types.COLLECTORS_FETCH_FAIL, error }));
};

/**
 * Save collector
 */
export const saveCollector = collector => dispatch => {
  dispatch({
    type: types.COLLECTORS_SAVE_START,
  });

  return AdminCollectorsService.saveCollector(collector)
    .then(collector => {
      dispatch({
        type: types.COLLECTORS_SAVE_SUCCESS,
        payload: {
          ...collector,
        },
      });
      changeUrl('profiles/collectors/dashboard');
    })
    .catch(error => dispatch({ type: types.COLLECTORS_SAVE_FAIL, error }));
};

/**
 * Update collector
 */
export const updateCollector = collector => dispatch => {
  dispatch({
    type: types.COLLECTORS_UPDATE_START,
  });

  return AdminCollectorsService.updateCollector(collector)
    .then(collectorId => {
      dispatch({
        type: types.COLLECTORS_UPDATE_SUCCESS,
        payload: { ...collector, collectorId },
      });
      changeUrl('profiles/collectors/dashboard');
    })
    .catch(error => dispatch({ type: types.COLLECTORS_UPDATE_FAIL, error }));
};

/**
 * Gets stats and format then for a chart
 * @param collectorId
 * @param startDate
 * @param endDate
 */
export const getCollectorStatsChart = (collectorId, startDate, endDate) =>
  AdminCollectorsService.getPartnerStats(collectorId, startDate, endDate).then(stats => ({
    collectorId,
    config: getChartConfig(stats),
    startDate,
    endDate,
  }));

/**
 * Fetches code samples
 *  @param collectorId
 */
export const fetchCodeForCollector = collectorId => dispatch => {
  dispatch({
    type: types.CODE_FETCH_START,
  });

  return AdminCollectorsService.getCode(collectorId)
    .then(code => {
      dispatch({
        type: types.CODE_FETCH_SUCCESS,
        payload: { collectorId, code },
      });
    })
    .catch(() => {});
};

/**
 * Delete collector
 */
export const deleteCollector = collectorId => dispatch => {
  dispatch({ type: types.COLLECTORS_DELETE_START });

  return AdminCollectorsService.deleteCollector(collectorId)
    .then(() => {
      showSuccess({ body: i18n.t('collectors:api.deletionSuccess') });
      dispatch({
        type: types.COLLECTORS_DELETE_SUCCESS,
        payload: collectorId,
      });
      changeUrl('profiles/collectors/dashboard');
    })
    .catch(error => dispatch({ type: types.COLLECTORS_DELETE_FAIL, error }));
};
