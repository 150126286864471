export const USER_SITES_FETCH_START = 'app/context/users/userSites/USER_SITES_FETCH_START';
export const USER_SITES_FETCH_FAIL = 'app/context/users/userSites/USER_SITES_FETCH_FAIL';
export const USER_SITES_FETCH_SUCCESS = 'app/context/users/userSites/USER_SITES_FETCH_SUCCESS';

export const USER_SITES_ADD_START = 'app/context/users/userSites/USER_SITES_ADD_START';
export const USER_SITES_ADD_FAIL = 'app/context/users/userSites/USER_SITES_ADD_FAIL';
export const USER_SITES_ADD_SUCCESS = 'app/context/users/userSites/USER_SITES_ADD_SUCCESS';

export const USER_EDIT_ACCESS_KEY = 'app/context/users/userSites/USER_EDIT_ACCESS_KEY';
export const USER_SITES_LIST = 'app/context/users/userSites/USER_SITES_LIST';

export const USER_SITE_EDIT_START = 'app/context/users/userSites/USER_SITE_EDIT_START';
export const USER_SITE_EDIT_FAIL = 'app/context/users/userSites/USER_SITE_EDIT_FAIL';
export const USER_SITE_EDIT_SUCCESS = 'app/context/users/userSites/USER_SITE_EDIT_SUCCESS';
