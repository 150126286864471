angular.module('webUi.filter.propFilter', [])

    .filter('propFilter', [function() {
        return function(input, propertyName, filterList) {

            var results = [];

            // make list of filters based on array, keys of object or single value
            var filterObjects;
            if(_.isArray(filterList)) {
                filterObjects = filterList;
            } else if(_.isObject(filterList)) {
                // get all keys for which value not evaluates to true
                filterObjects = _.keys(_.omit(filterList, function(val) { return !val; } ));
            } else {
                filterObjects = [filterList];
            }

            _.forEach(input, function(ip) {
                // check whether input matches one of the filters
                if(_.contains(filterObjects, ip[propertyName])) {
                    results.push(ip);
                }
            });

            return results;
        };
    }]);
