import { get } from 'lodash';
import { getAngularService } from 'ReactAngular/react.service';
import { changeUrl, broadcastAngularEvent, normalizeValue } from '~/common';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import * as types from './types';
import * as selectors from './selectors';
import SupportTagsService from './dataService';

/**
 * Fetch group
 * @param groupId
 */
export const fetchGroup = groupId => dispatch => {
  dispatch({
    type: types.GROUP_FETCH_START,
    payload: groupId,
  });

  return SupportTagsService.getGroup(groupId)
    .then(payload => {
      dispatch({
        type: types.GROUP_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.GROUP_FETCH_FAIL, error }));
};

/**
 * Fetch group only if not in store
 * @param groupId
 */
export const fetchGroupIfNeeded = groupId => (dispatch, getState) => {
  if (selectors.getGroup(getState(), groupId)) {
    return Promise.resolve();
  }
  return dispatch(fetchGroup(groupId));
};

/**
 * Delete group
 * @param groupId
 */
export const deleteGroup = groupId => dispatch => {
  dispatch({
    type: types.DELETE_START,
    payload: groupId,
  });

  // Remove Message on the backend
  return SupportTagsService.deleteGroup(groupId)
    .then(() => {
      // Remove Message from Store
      dispatch({
        type: types.DELETE_SUCCESS,
        payload: groupId,
      });

      // Notify sidebar (still in Angular)
      const PubSubService = getAngularService(document, 'PubSubService');
      PubSubService.publishTypeDeleted(get(PubSubService, 'TYPES.SUPPORT.TAG_TEMPLATE_GROUP'), { id: groupId });

      // Go to Message List
      changeUrl('support/tagtemplates');
      showSuccess({ body: i18n.t('tagTemplates:api.deletionSuccess') });
    })
    .catch(error => dispatch({ type: types.DELETE_FAIL, error }));
};

/**
 * Save group
 * Updates existing group or creates a new one if there is no groupId in saving object
 * @param group
 */
export const saveGroup = group => dispatch => {
  const apiCall = group.groupId ? SupportTagsService.updateGroup : SupportTagsService.saveGroup;

  const model = {
    ...group,
    name: normalizeValue(group.name),
  };

  dispatch({
    type: types.SAVE_START,
    payload: model,
  });

  // Save Message on the backend
  return apiCall(model)
    .then(data => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: {
          ...model,
          groupId: data,
          templates: model.templates || [],
        },
      });

      // Notify sidebar (still in Angular)
      const eventType = model.groupId ? 'support.tagTemplateGroupChanged' : 'support.tagTemplateGroupCreated';
      broadcastAngularEvent(eventType, {
        groupId: data,
      });

      // Go to the message view
      changeUrl(`support/tagtemplates/groups/view/${data}`);
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Fetch groups
 */
export const fetchGroups = () => dispatch => {
  dispatch({
    type: types.GROUPS_FETCH_START,
  });

  return SupportTagsService.getGroups()
    .then(payload => {
      dispatch({
        type: types.GROUPS_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.GROUPS_FETCH_FAIL, error }));
};
