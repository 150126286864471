/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_TAGS = `${API_BASE}/support/tagtemplates`;
const API_TEMPLATES = `${API_TAGS}/templates`;

/**
 * A service for Tag Templates related queries
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class SupportTagTemplatesClass {
  getTemplate(templateId) {
    return Api.callGet(`${API_TEMPLATES}/${templateId}`).then(response => response.data);
  }

  deleteTemplate(templateId) {
    return Api.callDelete(`${API_TEMPLATES}/${templateId}`);
  }

  saveTemplate(template) {
    return Api.callPost(`${API_TEMPLATES}/add`, template).then(response => response.data);
  }

  updateTemplate(template) {
    return Api.callPost(`${API_TEMPLATES}/edit`, template).then(response => response.data);
  }

  getTagContentTypes() {
    return Api.callGet(`${API_TEMPLATES}/tagContentTypes`).then(response => response.data);
  }

  getPlatformTypes() {
    return Api.callGet(`${API_TEMPLATES}/platformTypes`).then(response => response.data);
  }
}

const supportTagTemplatesService = new SupportTagTemplatesClass();
export default supportTagTemplatesService;
