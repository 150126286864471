import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { getSiteId, getAssetsPrefix } from '~/common/SecurityMetaService';
import { isFunction } from 'lodash';

let angularUrlRouter;
let angularTimeout;
let rootScope;

/**
 * Create new browser history
 */
export const history = createBrowserHistory();

/**
 * Set AngularJS dependencies
 * @param router
 * @param timeout
 * @param scope
 */
export const setAngularDeps = (router, timeout, scope) => {
  angularUrlRouter = router;
  angularTimeout = timeout;
  rootScope = scope;
};

/**
 * Enhance url with current site id
 * @param url
 * @returns {string}
 */
export const buildUrl = url => `/site-${getSiteId()}/${url}`;
export const buildUrlNew = url => `/site-${getSiteId()}/${url}`;

/**
 * Change url and sync angular router
 * @param url
 * @param isAbsolute, boolean to identify if the url is absolute
 */
export const changeUrl = (url, isAbsolute = false, forceReload = false) => {
  const urlToRedirect = isAbsolute ? url : buildUrl(url);
  if (forceReload) {
    window.location.replace(urlToRedirect);
  } else {
    history.push(urlToRedirect);
  }

  if (angularTimeout) {
    angularTimeout(() => {
      angularUrlRouter.sync();
    }, 1);
  }
};

/**
 * Enhance url with current assets prefix
 * @param url
 * @returns {string}
 */
export const buildAssetsUrl = url => `${getAssetsPrefix()}/${url}`;

/**
 * Get the query param from url
 * @param search
 * @param param
 * @returns {*}
 */
export const getParam = (search, param) => {
  const parsedQuery = queryString.parse(search);
  return parsedQuery && parsedQuery[param];
};

/**
 *
 * @param {string} url
 * @param {object<string|number>} search
 */
export const buildSearchUrl = (url, search, history) => {
  const existingParameters = queryString.parse(history);
  const targetParameters = { ...existingParameters, ...search };
  return `${url}?${queryString.stringify(targetParameters)}`;
};

/**
 *
 * @param event
 * @param payload
 */
export const broadcastAngularEvent = (event, payload) => {
  if (!rootScope || !isFunction(rootScope.$broadcast)) {
    return;
  }

  rootScope.$broadcast(event, payload);
};

export default history;
