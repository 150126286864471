import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader, { numberSort, boolSort } from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell, RowActions } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('environments:list.empty.header')} body={t('environments:list.empty.body')} />
);

export const EnvironmentsList = ({ stateKey, t, list, onDeleteClick, onEditClick, nonDeletableCodes, isSiteAdmin }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader
          className="u-size1of10"
          sortKey="environmentOrder"
          title={t('environments:common.environmentOrder')}
          sortFn={numberSort('environmentOrder')}
        />
        <SortableHeader
          className="u-size2of10"
          sortKey="environmentName"
          title={t('environments:common.environmentName')}
        />
        <SortableHeader
          className="u-size2of10"
          sortKey="environmentCode"
          title={t('environments:common.environmentCode')}
        />
        <SortableHeader
          className="u-size2of10"
          sortKey="requiresAdmin"
          title={t('environments:common.requiresAdmin')}
          sortFn={boolSort('requiresAdmin')}
        />
        <SortableHeader
          className="u-size1of10"
          sortKey="sampleRate"
          title={t('environments:common.sampleRate')}
          sortFn={numberSort('sampleRate')}
        />
        <SortableHeader sortKey="utils" title="" />
      </Row>
      {list.map(environment => {
        const { environmentCode, environmentName, environmentOrder, environmentId, requiresAdmin, sampleRate } =
          environment;
        const isDeleteDisabled = (code, codesList, isAdmin) => codesList.includes(code) || !isAdmin;
        const actions = [
          {
            name: t('environments:common.edit'),
            tooltip: isSiteAdmin ? t('environments:common.edit') : t('environments:list.editPermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'edit',
            onClick: () => onEditClick(environmentId),
            testHook: 'edit',
          },
          {
            name: t('environments:common.delete'),
            tooltip: isDeleteDisabled(environmentCode, nonDeletableCodes, isSiteAdmin)
              ? t('environments:list.deletePermisionError')
              : t('environments:common.delete'),
            isDisabled: isDeleteDisabled(environmentCode, nonDeletableCodes, isSiteAdmin),
            icon: 'delete',
            onClick: () => onDeleteClick(environment),
            testHook: 'delete',
            type: 'delete',
          },
        ];
        return (
          <Row key={environmentId} withActions>
            <Cell className="u-size1of10">{environmentOrder}</Cell>
            <Cell className="u-size2of10">{environmentName}</Cell>
            <Cell className="u-size2of10">{environmentCode}</Cell>
            <Cell className="u-size2of10">
              {requiresAdmin ? t('common:checkbox.truthy') : t('common:checkbox.falsy')}
            </Cell>
            <Cell className="u-size1of10">{sampleRate}</Cell>
            <RowActions actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

EnvironmentsList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  onDeleteClic: PropTypes.func,
  onEditClick: PropTypes.func,
  nonDeletableCodes: PropTypes.array,
};

export default compose(withSort(), withPaginate({ size: 11 }), withEmpty({ component: EmptyList }))(EnvironmentsList);
