/**
 * A service for AdServing related queries
 */
angular.module('webUi.service.content.creative', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('CreativeService', ['Restangular', '$q', 'Utils',  function(Restangular, $q, Utils){

        // the base path through which all calls should go
        var BASE_PATH = 'content/';

        return {
            getCreativeDimensions: function(campaignId, adId) {
                var dimensions = $q.defer();
                Restangular.one(BASE_PATH + 'ads/creativeDimensions', campaignId).getList('', { adId: adId}).then(function(result){
                    result = Utils.sanitizeRestangularAll(result);
                    dimensions.resolve(result);
                });
                return dimensions.promise;
            },

            initDefaultCreative: function(ad) {
                var creative = {adId: ad.adId, width: '', height: '', campaignId: ad.campaignId};

                switch (ad.creativeType.name) {
                    case 'HTML':
                        creative.content = { clazz: 'HtmlCreativeContent', html: this.getDefaultHtml() };
                        break;
                    case 'FLASH':
                        creative.content = { clazz: 'FlashCreativeContent', swfUrl: '', imgUrl: '' };
                        break;
                    case 'IMAGE':
                        creative.content = { clazz: 'ImageCreativeContent', imgUrl: '' };
                        break;
                    case 'REDIRECT':
                        creative.content = { clazz: 'RedirectCreativeContent', url : '' };
                        break;
                    case 'JAVASCRIPT':
                        creative.content = { clazz: 'JavascriptCreativeContent', html: '' };
                        break;
                    case 'VIDEO':
                        creative.content = { clazz: 'VideoCreativeContent', backupUrl: '' };
                        break;
                    default:
                        throw new Error('Unsupported creative type');
                }

                return creative;
            },

            addCreative: function(creative) {
                return Restangular.all(BASE_PATH + 'ads/creatives').post({
                    creative: creative
                });
            },

            updateCreative: function(creative) {
                return Restangular.all(BASE_PATH + 'ads/creatives/update').post({
                    creative: creative
                });
            },

            deleteCreative: function(creative) {
                return Restangular.one(BASE_PATH + 'ads', creative.adId).one('creatives', creative.creativeId).remove();
            },

            getCreativesForAd: function(adId) {
                var creatives = $q.defer();
                Restangular.one(BASE_PATH + 'ads/creatives', adId).getList().then(function(result){
                    result = Utils.sanitizeRestangularAll(result);
                    creatives.resolve(result);
                });
                return creatives.promise;
            },

            getCreative: function(adId, creativeId) {
                var creatives = $q.defer();
                Restangular.one(BASE_PATH + 'ads', adId).one('creatives/view', creativeId).get().then(function(result){
                    result = Utils.sanitizeRestangularOne(result);
                    creatives.resolve(result);
                });
                return creatives.promise;
            },

            getDefaultHtml: function() {
                return '<!DOCTYPE html>\n' +
            '<html>\n' +
            '\t<head>\n' +
            '\t\t<!-- svBase -->\n' +
            '\t\t<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />\n' +
            '\t</head>\n' +
            '\t<body>\n' +
            '\t\t<p>Content Here</p>\n' +
            '\t</body>\n' +
            '</html>';
            },

            getClickTrackerPlaceholders: function() {
                var placeholders = [];
                for (var i = 0; i < 10; i++) {
                    placeholders.push({
                        id: 'clickTracker' + i,
                        value: '${clickTracker' + i + '}'
                    });
                }
                return placeholders;
            },

            /**
         * Returns an import tag to insert in the creative HTML code
         * If the file source extension is 'js', then return a script
         * Otherwise return an image tag
         * (Can be improved with other types of files)
         * @param fileSrc source of file to be inserted
         */
            getImportTagForCodeMirror: function(fileSrc) {
                var extension = fileSrc.substr(fileSrc.lastIndexOf('.') + 1);
                if(extension !== fileSrc && extension === 'js') {
                //insert script
                    return '<script type="text/javascript" src="' + fileSrc + '"></script>';
                } else if (extension !== fileSrc && extension === 'css') {
                    return '<link rel="stylesheet" type="text/css" href="' + fileSrc + '">';
                } else {
                    return '<img src="' + fileSrc + '"/>';
                }
            },

            /**
         * Returns an example code for iterating over data feed
         * @param ad the ad for which the data feed code is generated. In case of a JS ad, the <script> tag is removed
         */
            getDataFeedPlaceholder: function getDataFeedPlaceholder(ad) {
                var code =
                '\n\t\t\tvar entries = [];' +
                '\n\t\t\t<#list dataFeeds.primaryValues as val>' +
                '\n\t\t\t\tvar entry = {};' +
                '\n\t\t\t\t<#list val?keys as key>' +
                '\n\t\t\t\t\tentry["${key}"] = "${val[key]}";' +
                '\n\t\t\t\t</#list>' +
                '\n\t\t\t\tentries.push(entry);' +
                '\n\t\t\t</#list>';

                if (ad.creativeType.name !== 'JAVASCRIPT') {
                    code = '\n\t\t<script type="text/javascript">' + code;
                    code += '\n\t\t</script>\n';
                }
                return code;

            },

            getPlacementParameterPlaceholder: function getPlacementParameterPlaceholder(placementParam) {
                return '${placementParams.' + placementParam.name + '}';
            },

            getProfileVarPlaceholder: function getProfileVarPlaceholder(profileVar) {
                var placeholder;
                if (profileVar.variableType.name === 'STRING') {
                    placeholder = '${profileVars.' + profileVar.name + '}';
                } else {
                    placeholder = '<#list profileVars.' + profileVar.name + ' as val></#list>';
                }
                return placeholder;
            }
        };
    }]);
