import React from 'react';
import cx from 'classnames';
import { RulesMap } from '~/common/modules/rules/selectors';
import { RulesTypeNames } from '~/common/modules/rules/types';
import { truncateString } from '~/common/utils/StringUtils';
import { Tooltip } from '~/components';
import Icons from '~/components/src/Icons';
import icons from '~/components/src/Icons/icons';
import i18n from '~/i18n';
import { TTriggerConfiguration, TTriggerProps } from '~/workflows/types';

const TriggerCard = ({
  trigger = {} as TTriggerConfiguration,
  onClick,
  selectedTrigger,
  testHook = 'trigger',
  label,
}: TTriggerProps) => (
  <div
    className={cx(
      'flex cursor-pointer flex-col rounded border bg-white p-2',
      `t-triggerCard-${testHook}`,
      selectedTrigger === trigger?.nodeId ? 'border-blue-500' : 'border',
    )}
    onClick={() => onClick(trigger?.nodeId)}
  >
    <div className={`flex justify-between border-b py-3 t-triggerCard-${testHook}-header`}>
      <Tooltip tooltip={label}>
        <div
          className={cx('w-4/5 w-full truncate text-lg font-medium', {
            'text-blue-500': selectedTrigger === trigger?.nodeId,
          })}
        >
          {truncateString(label, 25)}
        </div>
      </Tooltip>
      <span className="text-gray-500">
        {trigger?.rules?.length} {i18n.t('workflow:overview.rules')}
      </span>
    </div>

    {trigger?.rules?.map(({ ruleName, ruleType }) => (
      <div className={`my-3 flex justify-between t-triggerCard-${testHook}-rules`} key={ruleName + ruleType}>
        <div className="flex items-center gap-1">
          <div>
            <Icons
              icon={RulesMap[RulesTypeNames[ruleType?.name]].icon as keyof typeof icons}
              className="mr-1 h-6 w-6"
            />
          </div>
          <span>{ruleName}</span>
        </div>
      </div>
    ))}
  </div>
);

export default TriggerCard;
