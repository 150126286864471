/**
 * @description Angular main module for Dashboard
 * @memberOf webUi
 * @namespace webUi.ui.dashboard
 */
angular.module('webUi.ui.dashboard', ['webUi.ui.siteState'])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.dashboard', {
            url: '/dashboard',
            isReact: true,
        });
    }]);
