import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { capitalize } from 'lodash';
import { components, enhancements } from '~/components/src/Table';
import { changeUrl } from '~/common';
import SortableHeader, { textSort, dateSort } from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';
import './styles.scss';
import ScheduledExportsComponent from './ScheduledExportsComponent';
import ManualExportsComponent from './ManualExportsComponent';
import HeaderCell from '../../components/src/Table/components/HeaderCell/presenter';

const { Table, Row } = components;

const { withSort } = enhancements;

class ExportsTableComponent extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    const {
      stateKey,
      columnCategory,
      columns,
      t,
      list,
      isDataFetched,
      emptyHeader,
      emptyBody,
      onDeleteExport,
      downloadManualExport,
    } = this.props;

    const redirectToUpdatePage = id => {
      changeUrl(`profiles/export/edit/${id}`);
    };

    const renderScheduledRows = () =>
      list.map(row => {
        const actions = [
          {
            name: t('actions.edit.name'),
            tooltip: t('actions.edit.tooltip'),
            isDisabled: false,
            icon: 'edit',
            onClick: () => redirectToUpdatePage(row.scheduledExportId),
            testHook: 'edit',
          },
          {
            name: t('actions.delete.name'),
            tooltip: t('actions.delete.tooltip'),
            isDisabled: false,
            icon: 'delete',
            onClick: () => onDeleteExport(row.exportType, row.scheduledExportId),
            testHook: 'delete',
            type: 'delete',
          },
        ];
        return <ScheduledExportsComponent key={row.scheduledExportId} row={row} actions={actions} />;
      });

    const renderManualRows = () => {
      let iconDisabled;
      let customTooltip;
      return list.map(row => {
        iconDisabled = false;
        customTooltip = t('actions.export.tooltip');
        if (row.lessThanMaxNumberGB === false) {
          iconDisabled = true;
          customTooltip = t('actions.export.tooBigFileTooltip');
        }
        const actions = [
          {
            name: t('actions.export.name'),
            tooltip: customTooltip,
            isDisabled: iconDisabled,
            icon: 'download',
            onClick: () => downloadManualExport(row.id),
            testHook: 'export',
          },
        ];

        return <ManualExportsComponent key={row.id} row={row} actions={actions} />;
      });
    };

    return (
      !isDataFetched &&
      (list.length ? (
        <Panel>
          <Table stateKey={stateKey}>
            <Row>
              {columns.map((column, i) => {
                if (i !== columns.length - 1) {
                  return (
                    <SortableHeader
                      className={`${column.columnSize} u-pointer`}
                      key={column.title}
                      sortKey={column.sortKey}
                      title={t(`${columnCategory}.${column.title}`)}
                      sortFn={column.type === 'date' ? dateSort(column.sortKey) : textSort(column.sortKey)}
                    />
                  );
                }

                return (
                  <HeaderCell className="u-size1of12" key={column.title} title={t(`${columnCategory}.${column.title}`)}>
                    <div className="u-alignCenter TableSpacingOnXlHeader">{capitalize(column.sortKey)}</div>
                  </HeaderCell>
                );
              })}
            </Row>
            {stateKey === 'scheduledColumns' ? renderScheduledRows() : renderManualRows()}
          </Table>
        </Panel>
      ) : (
        <Empty header={emptyHeader} body={emptyBody} />
      ))
    );
  }
}

ExportsTableComponent.propTypes = {
  stateKey: PropTypes.string.isRequired,
  columnCategory: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  list: PropTypes.array,
  fetchData: PropTypes.func,
  isDataFetched: PropTypes.bool,
  emptyHeader: PropTypes.string,
  emptyBody: PropTypes.string,
  onDeleteExport: PropTypes.func,
  downloadManualExport: PropTypes.func,
  error: PropTypes.string,
};

export default compose(withSort())(ExportsTableComponent);
