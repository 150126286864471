import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Groups Objects
 * @param state
 * @param action
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.templateId]: {
          ...action.payload,
          group: {
            id: action.payload.groupId,
            label: action.payload.groupName,
          },
        },
      };
    case types.DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload.templateId];
      return nextState;
    default:
      return state;
  }
};

/**
 * Groups Look Up Table
 * @param state
 * @param action
 */
const allIds = (state = [], action) => {
  switch (action.type) {
    case types.TEMPLATE_FETCH_SUCCESS:
      if (state.indexOf(action.payload.templateId) !== -1) {
        return state;
      }
      return [...state, action.payload.templateId];
    case types.DELETE_SUCCESS:
      return state.filter(id => id !== action.payload.templateId);
    default:
      return state;
  }
};

/**
 * Tag Variant Execution Types
 * @param state
 * @param action
 * @returns {Array}
 */
const tagContentTypes = (state = [], action) => {
  switch (action.type) {
    case types.TAG_CONTENT_TYPES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

/**
 * Tag Variant Execution Types
 * @param state
 * @param action
 * @returns {Array}
 */
const platformTypes = (state = [], action) => {
  switch (action.type) {
    case types.PLATFORM_TYPES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  showDeleted: false,
  error: null,
};
const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.TOGGLE_DELETED:
      return {
        ...state,
        showDeleted: !state.showDeleted,
      };
    default:
      return state;
  }
};

const templatesReducer = combineReducers({
  byId,
  allIds,
  tagContentTypes,
  platformTypes,
  ui,
});

export default templatesReducer;
