import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import i18n from '~/i18n';
import moment from 'moment';
import { components } from '~/components/src/Table';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { HeaderCell } from '~/components/src/Table/components';
import { useApiWithState } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { showSuccess } from '~/notificationCenter';
import { Heading, Spinner, Tooltip } from '~/components';
import BtnOutlined from '~/components/src/BtnOutlined';
import { truncateString } from '~/common/utils/StringUtils';
import { createAPIKey, deleteAPIKey, fetchApiKeys } from './dataService';
import { APIActionProps, APIKey, APITableProps, FormikInputFieldType } from './types';

const { Table, Row, Cell } = components;
const DATE_FORMAT = 'DD/MM/YYYY';
const DEFAULT_MAX = 255;
const HIDDEN_KEY = '*'.repeat(16);

const WIDTHS = {
  NAME: 'w-[40%]',
  API_KEY: 'w-[40%]',
  CREATED_AT: 'w-[10%]',
  ACTIONS: 'w-[10%]',
};

const initialValues = {
  name: '',
};

const makeUniqueAPIKeyValidator = (existingKeys: APIKey[]) => (inputValue: FormikInputFieldType) => {
  const lowerCaseVariables = existingKeys.map(existingKey => existingKey.name.toLowerCase());
  const isUsedAlready = inputValue && lowerCaseVariables.includes(inputValue?.toLowerCase());
  return !isUsedAlready;
};

const makeValidationSchema = (existingKeys: APIKey[]) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .test(
        'apiKey-name-is-unique',
        i18n.t('apiKeys:validation.nameAlreadyExists'),
        makeUniqueAPIKeyValidator(existingKeys),
      )
      .max(DEFAULT_MAX, i18n.t('validation:validation.maxLength', { max: DEFAULT_MAX }))
      .required(i18n.t('validation:validation.required')),
  });

const filterAPIKeys = (apiKeys: APIKey[], query: string) =>
  query ? apiKeys.filter(apikey => apikey.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) : apiKeys;

const APIKeysActions = ({ refetchAPIKeys, apiKeys }: APIActionProps) => {
  const [showActions, setShowActions] = useState(false);

  const handleSubmit = async (values: { name: string }) => {
    await createAPIKey(values);
    showSuccess({ header: i18n.t('apiKeys:notifications.success') });
    setShowActions(false);
    refetchAPIKeys();
  };

  const validationSchema = makeValidationSchema(apiKeys || []);

  if (showActions) {
    return (
      <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit} className="flex items-center gap-2 pt-8">
            <Field
              className="w-1/3"
              placeholder={i18n.t('apiKeys:placeholders.name')}
              name="name"
              as={FormikInputField}
              type="text"
              errorText={errors.name}
            />
            <div className="mb-[18px] flex gap-2">
              <BtnIcon icon="close" color="gray" onClick={() => setShowActions(false)} />
              <BtnIcon icon="done" color="blue" testHook="createNewAPIKey" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    );
  }

  return (
    <div className="pt-8">
      <BtnOutlined
        className="pr-6"
        onClick={() => {
          setShowActions(true);
        }}
        icon="add"
        testHook="newapikey"
      >
        {i18n.t('apiKeys:actions.new')}
      </BtnOutlined>
    </div>
  );
};

const EmptyList = ({ refetchAPIKeys, filtered }: APIActionProps) => (
  <>
    <Empty
      header={i18n.t('apiKeys:table.empty.header')}
      body={filtered ? i18n.t('apiKeys:table.filtered.body') : i18n.t('apiKeys:table.empty.body')}
    />
    <APIKeysActions refetchAPIKeys={refetchAPIKeys} />
  </>
);

const APIKeysTable = ({ apiKeysList, filter, refetchAPIKeys }: APITableProps): React.ReactElement => {
  const [apiKeyVisibile, setApiKeyVisibile] = useState<Record<string, boolean>>({});

  const handleDelete = async (apiKeyId: string) => {
    await deleteAPIKey(apiKeyId);
    showSuccess({ header: i18n.t('apiKeys:notifications.deleteSuccess') });
    refetchAPIKeys();
  };

  const handleShowAPIKey = (id: string) => {
    setApiKeyVisibile({ ...apiKeyVisibile, [id]: !apiKeyVisibile[id] });
  };

  const apiKeys = filterAPIKeys(apiKeysList, filter);
  if (apiKeys.length === 0) {
    return <EmptyList filtered={!!filter} refetchAPIKeys={refetchAPIKeys} />;
  }

  return (
    <Panel>
      <Table stateKey="">
        <Row>
          <HeaderCell className={`${WIDTHS.NAME} t-apikeysTableHead`}>{i18n.t('apiKeys:common.name')}</HeaderCell>
          <HeaderCell className={`${WIDTHS.API_KEY} t-apikeysTableHead`}>{i18n.t('apiKeys:common.apiKey')}</HeaderCell>
          <HeaderCell className={`${WIDTHS.CREATED_AT} t-apikeysTableHead`}>
            {i18n.t('apiKeys:common.createdAt')}
          </HeaderCell>
          <HeaderCell className={WIDTHS.ACTIONS}> </HeaderCell>
        </Row>

        {apiKeys.map(apikey => (
          <Row key={apikey.apiKeyId} id={apikey.apiKeyId} className="t-apikeysTableRow">
            <Cell className={`${WIDTHS.NAME} t-apikeyName`}>
              <Tooltip tooltip={apikey.name}>{truncateString(apikey.name, 40)}</Tooltip>
            </Cell>
            <Cell className={`${WIDTHS.API_KEY} t-apikey`}>
              {apiKeyVisibile[apikey.apiKeyId] ? apikey.apiKey : HIDDEN_KEY}
            </Cell>
            <Cell className={`${WIDTHS.CREATED_AT} t-apikeyCreatedAt`}>
              {moment(apikey.createdAt).format(DATE_FORMAT)}
            </Cell>
            <Cell className={`${WIDTHS.ACTIONS} gap-2`}>
              <BtnIcon
                testHook="hideOrViewAPIKey"
                icon={apiKeyVisibile[apikey.apiKeyId] ? 'hide' : 'view'}
                tooltip={
                  apiKeyVisibile[apikey.apiKeyId] ? i18n.t('apiKeys:tooltips.hide') : i18n.t('apiKeys:tooltips.show')
                }
                color="gray"
                onClick={() => handleShowAPIKey(apikey.apiKeyId)}
              />
              <BtnIcon
                icon="delete"
                tooltip={i18n.t('apiKeys:tooltips.delete')}
                testHook="deleteAPIKey"
                color="red"
                onClick={() => handleDelete(apikey.apiKeyId)}
              />
            </Cell>
          </Row>
        ))}
      </Table>
      <APIKeysActions apiKeys={apiKeysList} refetchAPIKeys={refetchAPIKeys} />
    </Panel>
  );
};

export const APIKeysList = (): React.ReactElement => {
  const { state: apiKeysList, isLoading, refetch: refetchAPIKeys } = useApiWithState<APIKey[]>(() => fetchApiKeys());
  const [filter, setFilter] = useState('');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Heading title={i18n.t('apiKeys:header')}>
        <SearchElement
          className="Aligner-item"
          value={filter}
          onChange={event => setFilter(event.target.value)}
          placeholder={i18n.t('common:actions.search')}
        />
      </Heading>
      <APIKeysTable apiKeysList={apiKeysList || []} filter={filter} refetchAPIKeys={refetchAPIKeys} />
    </>
  );
};
