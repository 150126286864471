angular.module('webUi.ui.context.clients.form', ['webUi.ui.context.clients.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.clients.add', {
                url: '/add',
                isReact: true
            })
            .state('site.context.clients.edit', {
                url: '/edit/{clientId}',
                isReact: true
            });
    }]);
