import React, { useContext } from 'react';
import i18n from '~/i18n';
import { useFormikContext } from 'formik';
import { getIcon } from '~/common/modules/rules/utils';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import Btn from '~/components/src/Btn';
import Mapping, { MappingListHeader } from './Mapping';
import CardHeader from './CardHeader';
import { EventProps } from './types';
import { addMapping, fetchAdditionalSources } from '../core/util';
import { MappingType } from '../types';
import { TransformationContext } from '../context';

const Event = ({ event, eventPath, deleteEvent, isReadOnly = false }: EventProps): React.ReactElement => {
  const { rule, mappings } = event;
  const { data: additionalSources } = useAPI(async () => fetchAdditionalSources(rule), [rule.clazz]);
  const { internalProps } = useContext(TransformationContext);
  const form = useFormikContext();
  const { setFieldValue } = form;
  const allProperties = (additionalSources || [])?.concat(internalProps || []);

  const handleAddMapping = () => {
    const newMapping = addMapping();
    setFieldValue(`${eventPath}.mappings`, [...mappings, newMapping]);
  };

  const handleAddAllMappings = () => {
    const remainingProperties = allProperties?.filter(property =>
      mappings.every(mapping => mapping.sourceName !== property.name),
    );

    if (remainingProperties && remainingProperties?.length > 0) {
      const newMappings = remainingProperties?.map(property => addMapping(property.name, property.name));
      if (newMappings) {
        setFieldValue(`${eventPath}.mappings`, [...mappings, ...newMappings]);
      }
    }
  };

  const handleDeleteMapping = (mappingIndex: number) => {
    mappings.splice(mappingIndex, 1);
    setFieldValue(`${eventPath}.mappings`, mappings);
  };

  return (
    <div className="t-eventCard flex rounded-md border border-gray-300 bg-white p-4">
      <div className="w-full">
        <CardHeader
          title={rule.ruleName}
          subtitle={rule.ruleDefinitionName}
          tooltipText={i18n.t(`audiences:rules.descriptions.${rule.clazz}`)}
          icon={getIcon(rule.clazz)}
          onDelete={deleteEvent}
          isReadOnly={isReadOnly}
        />
        <div className="mt-4">
          {mappings.length > 0 && (
            <table className="w-full">
              <MappingListHeader isReadOnly={isReadOnly} />
              <tbody>
                {mappings.map((mapping: MappingType, mappingIndex: number) => (
                  <Mapping
                    key={mappingIndex}
                    configuredMappings={mappings}
                    mapping={mapping}
                    mappingPath={`${eventPath}.mappings[${mappingIndex}]`}
                    remove={() => handleDeleteMapping(mappingIndex)}
                    isReadOnly={isReadOnly}
                    additionalSources={additionalSources || []}
                  />
                ))}
              </tbody>
            </table>
          )}
          {mappings.length === 0 && (
            <Notification kind="error">{i18n.t('transformations:messages.minOneMapping')}</Notification>
          )}
          {!isReadOnly && (
            <div className="flex gap-2">
              <Btn
                size="xs"
                color="blue"
                onClick={() => handleAddMapping()}
                icon="add"
                className="mt-4"
                testHook="addMappingButton"
              >
                {i18n.t('partners:eventConnector.addMapping')}
              </Btn>
              <Btn size="xs" onClick={() => handleAddAllMappings()} icon="add" className="mt-4" testHook="addAllButton">
                {i18n.t('partners:eventConnector.addAllMappings')}
              </Btn>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Event;
