import React, { useState } from 'react';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { useApiWithState } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import Detail from '~/components/src/Form/Detail/Default';
import { Details, Empty, Heading, Notification, Page } from '~/components';
import { ErrorReportingProps } from './types';
import { removeTrailingCommas } from './utils';
import { getErrorReporting } from './dataService';
import EditGlobalSettings from './components/EditGlobalSettings';
import CreateGlobalSettings from './components/CreateGlobalSettings';

const ErrorReporting = ({ hasTagEdit }: ErrorReportingProps): React.ReactElement => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { state: errorReporting, isLoading, refetch } = useApiWithState(() => getErrorReporting());

  const { errorThreshold, emailsToNotify, enabled, settingsId } = errorReporting || {};
  return (
    <Page>
      <Heading title={i18n.t('errorReporting:title')}>
        {!isLoading && hasTagEdit && (
          <>
            {settingsId ? (
              <BtnIcon
                tooltip={i18n.t('errorReporting:updateTooltip')}
                icon="edit"
                color="blue"
                testHook="editGlobalSettings"
                onClick={() => setShowEditModal(true)}
              />
            ) : (
              <BtnIcon
                tooltip={i18n.t('errorReporting:createTooltip')}
                icon="add"
                color="blue"
                testHook="createGlobalSettings"
                onClick={() => setShowCreateModal(true)}
              />
            )}
          </>
        )}
      </Heading>
      {isLoading && <Spin />}
      {!isLoading && (
        <>
          <Notification kind="information">{i18n.t('errorReporting:info')}</Notification>
          {!settingsId && (
            <Empty header={i18n.t('errorReporting:empty.header')} body={i18n.t('errorReporting:empty.body')} />
          )}
          {settingsId && (
            <div>
              <Detail label={i18n.t('errorReporting:form.threshold')} value={errorThreshold} />
              <Details.Bool
                label={i18n.t('errorReporting:form.enabled')}
                value={enabled}
                translations={{
                  truthy: i18n.t('errorReporting:yes'),
                  falsy: i18n.t('errorReporting:no'),
                }}
              />
              <div className="mt-6 flex">
                <span className="w-60 whitespace-nowrap font-medium">{i18n.t('errorReporting:form.email')}</span>
                <span className="mx-4 flex max-h-52 min-w-[25%] flex-col overflow-y-auto rounded border px-2 py-1 leading-8">
                  {removeTrailingCommas(emailsToNotify)
                    ?.split(',')
                    ?.map((item: string, index: number) => (
                      <span key={index} className="t-emailList hover:bg-gray-100">
                        {item}
                      </span>
                    ))}
                </span>
              </div>
            </div>
          )}
        </>
      )}

      {showCreateModal && <CreateGlobalSettings setShowCreateModal={setShowCreateModal} refetch={refetch} />}
      {showEditModal && errorReporting && (
        <EditGlobalSettings setShowEditModal={setShowEditModal} refetch={refetch} errorReporting={errorReporting} />
      )}
    </Page>
  );
};

export default ErrorReporting;
