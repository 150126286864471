angular.module('webUi.service.content.placement', ['restangular'])
    .factory('PlacementService', ['Restangular', function(Restangular){
        var BASE_PATH = 'content/campaigns/';
        var getPlacementExportCode = function (campaign, placement, siteNumber, adserverHost) {
            var width = placement.width;
            var height = placement.height;
            var iabCompliant = campaign.compliant;
            var hostFile = iabCompliant ? 'ad-iframe-iab.js' : 'ad-iframe-https.js';

            var code = '';
            code += '<!-- Placement: ' + width + 'x' + height + '-->\n';
            code += '<script language ="javascript">\n';
            code += '\t_svAd = {\n';
            code +=            '\t\tsiteId :' + siteNumber + ',\n';
            code +=  '\t\tplacement : ' + placement.placementNumber + ',\n';
            code +=            '\t\twidth : ' + width + ',\n';
            code +=            '\t\theight : ' + height + ',\n';
            code +=            '\t\tclickTracker : [], // Optional\n';
            code += '\t\timpTrackers : [], // Optional\n';
            if (iabCompliant) {
                code +=            '\t\tIABTimeout : 1000,\n';
            } else {
                code +=            '\t\tfallbackImg : "http://c.svmds.com/fb/s/' + siteNumber +  '/p/' + placement.placementNumber + '",\n';
                code +=            '\t\tfallbackUrl : "",\n';
            }
            code +=            '\t\tparams : {\n';

            if(campaign.placementParameters) {
                for(var paramIdx = 0; paramIdx < campaign.placementParameters.length; paramIdx ++) {
                    var placementParam = campaign.placementParameters[paramIdx];
                    code += '\t\t\t"' + _.escape(placementParam.name) + '" : "' + _.escape(placementParam.defaultValue) + '"';
                    if(paramIdx + 1 < campaign.placementParameters.length) {
                        code += ',';
                    }
                    code += '\n';
                }
            }

            code +=            '\t\t}\n';
            code +=        '\t};\n';
            code +=        '\tdocument.write(\'<scr\' + \'ipt type="text/javascript" src="//' + adserverHost +'/media/'+ hostFile +'"></scr\' + \'ipt>\');\n';
            code +=    '</script>';

            return code;
        };
        var getAdserverHost = function(){
            return Restangular.one(BASE_PATH + 'adserverHost').get();
        };

        var getPlacementExportCodeJs = function(campaign, placement,siteNumber, adserverHost){
            var width = placement.width;
            var height = placement.height;
            var code = '';
            code += '// Placement: ' + width + 'x' + height + '\n';
            code +=     '(function(){\n';
            code +=         '\tvar _svAd = {\n';
            code +=            '\t\tsiteId : ' + siteNumber + ',\n';
            code +=            '\t\tplacement : ' + placement.placementNumber + ',\n';
            code +=            '\t\tcb : new Date().getTime()\n';
            code +=        '\t};\n';
            code +=        '\tvar head = document.getElementsByTagName("head")[0];\n';
            code +=        '\tvar scriptElement = document.createElement(\'script\');\n';
            code +=        '\tscriptElement.type = \'text/javascript\';\n';
            code +=        '\tscriptElement.src = ';
            code += '\'https://' + adserverHost + '/s/\' + _svAd.siteId + \'/p/\' + _svAd.placement + \'?cb=\' + _svAd.cb;\n';
            code +=        '\thead.appendChild(scriptElement);\n';
            code +=        '})();\n';
            return code;
        };
        return {
            getAdserverHost: getAdserverHost,
            getPlacementExportCode: getPlacementExportCode,
            getPlacementExportCodeJs: getPlacementExportCodeJs
        };
    }]);
