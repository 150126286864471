/**
 * A directive that will watch the svfocus attribute and when it evaluates to true, will set the focus to the element
 */
angular.module('webUi.directive.tagList', [])
    .directive('tagList',
        [
            '$state',
            '$q',
            'TagmanagementService',
            'SecurityService',
            'CsvService',
            'Utils',
            'ValidService',
            /**
             *
             * @param $state
             * @param $q
             * @param TagmanagementService
             * @param SecurityService
             * @param CsvService
             * @param Utils
             * @returns {*}
             * @constructor
             */
            function TagListDirective($state, $q, TagmanagementService, SecurityService, CsvService, Utils, ValidService) {

                return {
                    restrict: 'EA',
                    replace: true,
                    scope: {
                        hasTagEdit : '=',
                        tagList : '=',
                        tagListSearch : '=',
                        listLocation: '=',
                        listType: '=',
                        tagPagingInfo: '=?',
                        isDraggable: '='
                    },

                    link: function (scope, element, attrs) {

                        scope.tagListMode = attrs.tagListMode || 'detail';

                        var defaultPagingInfo = {};

                        if ( _.isUndefined(scope.tagPagingInfo)){
                            scope.tagPagingInfo = defaultPagingInfo;
                        } else {
                            scope.tagPagingInfo = _.defaults(scope.tagPagingInfo, defaultPagingInfo);
                        }

                        scope.prefix = '/' + SecurityService.getPrefix();

                        scope.tagListOrder = {
                            field: ['priority','tagNumber'],
                            reverse: false
                        };

                        scope.listLocationEncoded = encodeURIComponent(scope.listLocation);

                        //get types from service
                        TagmanagementService.getAvailableTagConditions().then(function(types) {
                            var actualTypes = [];
                            var seen = [];
                            types.forEach(function(type){
                                if(type.tagListGroupName && !_.contains(seen, type.tagListGroupName.name)){
                                    seen.push( type.tagListGroupName.name);
                                    actualTypes.push({label: type.tagListGroupName.label, name: type.tagListGroupName.name});
                                }
                            });
                            scope.tagConditionTypes = actualTypes;
                        });

                        scope.isNull = function isNull(val){
                            return _.isNull(val);
                        };

                        scope.selectTag = function(tag, openConditions) {
                            $state.transitionToStateWithSite('site.tagmanagement.tags.view', {
                                id: tag.tagId,
                                type: scope.listType,
                                identifierParam: scope.listLocationEncoded,
                                autoOpenConditions: openConditions
                            });
                        };

                        scope.exportToCsv = function(tags) {
                            let tagIds = tags.map(tag => tag.tagId);

                            TagmanagementService.getTagsListForExport(tagIds).then(function(exportTags) {
                                CsvService.exportAsFile(
                                    CsvService.generateFileName('tags', true),
                                    exportTags,
                                    ['tagId', 'jsCode', 'name', 'priority', 'templateTag',
                                        function(tag) {
                                            if(!_.isNull(tag.path)) {
                                                return scope.pathName(tag.path);
                                            } else if(!_.isUndefined(tag.view) && tag.view.isWebsitePathGroup) {
                                                return tag.groupName;
                                            } else {
                                                return '';
                                            }
                                        },
                                        function(tag) {
                                            return tag.status ? 'Enabled' : 'Disabled';
                                        },
                                        function(tag) {
                                            return moment(tag.lastModified).format('YYYY-MM-DD HH:mm');
                                        },
                                        'lastModifiedBy'],
                                    ['TagId', 'TagCode', 'Name', 'Priority', 'Template Tag', 'Source', 'Status', 'Last Modified', 'Last Modified By']);
                            });
                        };

                        scope.isExcluded = TagmanagementService.isExcludedPaths;

                        // only excludable if we are on a specific path which is not itself
                        scope.isExcludable = function(tag){
                            if ( _.isEmpty(scope.listLocation) || scope.listType !== 'paths') {
                                return false;
                            }
                            return tag.path !== scope.listLocation;
                        };

                        scope.pathName = function(path) {
                            if(path === TagmanagementService.GLOBAL_PATH ) {
                                return TagmanagementService.GLOBAL_NAME;
                            } else {
                                return this.readablePath(path);
                            }
                        };

                        /**
                         * Modifier functions
                         */

                        scope.toggleStatus = function(tag){
                            TagmanagementService.updateEnabled(tag.tagId, !tag.status).then(function(){
                                tag.status = !tag.status;
                            });
                        };

                        scope.priorityOverrideOptions = {
                            editSuccessCallback: function(newValue, oldValue, editableFieldName, editableObjName){
                                var pathToTag = editableFieldName.substring(0, editableFieldName.lastIndexOf('.'));
                                var tagFromScope = Utils.getScopeValue(editableObjName.$parent, pathToTag, true);
                                return TagmanagementService.updatePriority(tagFromScope.tagId, newValue);
                            }
                        };

                        //@todo remove
                        scope.getPerPageOptions = function(pagingInfo){
                            var defaults = [10, 25, 50];
                            var perPage = pagingInfo.perPage;
                            if (_.contains(defaults, parseInt(perPage, 10)) ){
                                return defaults;
                            } else {
                                defaults.push(perPage);
                                return _.sortBy(defaults, function(numb){
                                    return parseInt(numb, 10);
                                });
                            }
                        };

                        // function to toggle the exclusion of a tag
                        scope.toggleExcludeTag = function(tag) {
                            var def = $q.defer();

                            if (scope.isExcluded(tag, scope.listLocation) ){
                                TagmanagementService.removePathExclusion(tag.tagId, scope.listLocation).then(function(){
                                    for ( var i = 0; i < tag.excludedPaths.length; i ++ ){
                                        if ( tag.excludedPaths[i] === scope.listLocation ){
                                            tag.excludedPaths.splice(i,1);
                                            break;
                                        }
                                    }
                                    def.resolve();
                                });
                            } else {
                                TagmanagementService.addPathExclusion(tag.tagId, scope.listLocation).then(function(){
                                    tag.excludedPaths.push(scope.listLocation);
                                    def.resolve();
                                });
                            }

                            return def.promise;
                        };

                        scope.readablePath = function(path) {
                            if ( _.isNull(path) ){
                                return '';
                            }
                            return path.split('|').join(' > ');
                        };

                        scope.isNumberRange = ValidService.isNumberRange;

                    },
                    templateUrl: 'directive/tagList/tagList.tpl.html'
                };
            }
        ]);

