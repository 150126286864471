import { get } from 'lodash';

/**
 * Get type by Id
 * @param state
 * @param typeId
 * @returns {*}
 */
export const getTypeById = (state, typeId) => get(state, `admin.urlBuilder.types.byId[${typeId}]`);

/**
 * Get all types
 * @param state
 */
export const getTypes = state => state.admin.urlBuilder.types.allIds.map(id => state.admin.urlBuilder.types.byId[id]);

/**
 * Should fetch all types?
 * @param state
 */
// @TODO Check it's not used and remove
export const shouldFetchTypes = state => get(state, 'admin.urlBuilder.types.ui.shouldFetchAll');

/**
 * Is fetching all types currently in progress?
 * @param state
 */
export const isFetchingTypes = state => get(state, 'admin.urlBuilder.types.ui.isFetchingAll');
