import React from 'react';
import PropTypes from 'prop-types';
import { get, isFunction } from 'lodash';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import Link from '~/components/src/Link';
import Icon from '~/components/src/Icon';
import Empty from '~/components/src/Empty';
import SortableHeader from '~/components/src/Table/sortableHeader';
import MdCheck from 'react-icons/lib/md/check';

const { Table, Row, Cell, HeaderCell, RowActions } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t, emptyBody = t('users:siteList.empty.body') }) => (
  <Empty header={t('users:siteList.empty.header')} body={emptyBody} />
);

const NoResults = ({ t }) => (
  <Empty header={t('users:siteList.noResults.header')} body={t('users:siteList.noResults.body')} />
);

const WIDTHS = {
  NUMBER: '60px',
  NAME: '10%',
  CLIENT: '7%',
  PERMISSION: '70px',
  ACTIONS: '75px',
};

const props = (sortKey, title, sortFn) => ({
  sortKey,
  title,
  sortFn,
});

const SiteName = ({ url, item }) =>
  url && isFunction(url) ? <Link href={url(item)}>{item.name}</Link> : <span>{item.name}</span>;

export const UserSitesList = ({ list, permissions, stateKey, siteUrl, onEditClick, onDeleteClick, t, isTable }) => {
  const renderActions = item => {
    const actions = [
      {
        name: t('common:actions.edit'),
        tooltip: t('common:actions.edit'),
        isDisabled: false,
        icon: 'edit',
        onClick: () => onEditClick(item),
        testHook: 'edit',
      },
      {
        name: t('common:actions.remove'),
        tooltip: t('common:actions.remove'),
        isDisabled: false,
        icon: 'delete',
        onClick: () => onDeleteClick(item),
        testHook: 'delete',
        type: 'delete',
      },
    ];

    return (
      onDeleteClick && (
        <Cell className="Table-stickyCell right-0 z-10 float-right">
          <RowActions actions={actions} iconsLimit={3} />
        </Cell>
      )
    );
  };
  return (
    <div className="OverflowXScrollContainer OverflowYHiddenContainer">
      <Table stateKey={stateKey} isTable={isTable} className="Table--withStickyColumns">
        <colgroup>
          <col style={{ width: WIDTHS.NUMBER }} />
          <col style={{ width: WIDTHS.NAME }} />
          <col style={{ width: WIDTHS.CLIENT }} />
          {permissions.map(module =>
            module.permissions.map(permission => <col style={{ width: WIDTHS.PERMISSION }} key={permission.name} />),
          )}
          <col style={{ width: WIDTHS.ACTIONS }} />
        </colgroup>
        <thead>
          <Row>
            <SortableHeader
              rowSpan={2}
              {...props('siteNumber', t('siteList.siteNumber'))}
              className="Table-stickyCell z-10"
            />
            <SortableHeader
              rowSpan={2}
              {...props('name', t('list.name'))}
              className="Table-stickyCell z-10"
              style={{ left: WIDTHS.NUMBER }}
            />
            <SortableHeader rowSpan={2} {...props('clientName', t('list.clientName'))} />
            {permissions.map(item => (
              <HeaderCell colSpan={item.permissions.length} key={item.name}>
                <div className="u-alignCenter">{item.label}</div>
              </HeaderCell>
            ))}
            {!onDeleteClick ? null : <HeaderCell className="Table-stickyCell right-0 z-10">&nbsp;</HeaderCell>}
          </Row>
          <Row>
            {permissions.map(item =>
              item.permissions.map(permission => (
                <Cell className="u-alignCenter" key={permission.name}>
                  <span>{permission.label}</span>
                </Cell>
              )),
            )}
            {!onDeleteClick ? null : <Cell className="Table-stickyCell right-0 z-10">&nbsp;</Cell>}
          </Row>
        </thead>
        <tbody>
          {list.map(item => (
            <Row key={item.siteId} withActions>
              <Cell className="Table-stickyCell">{item.siteNumber}</Cell>
              <Cell className="Table-stickyCell" style={{ left: WIDTHS.NUMBER }}>
                <SiteName url={siteUrl} item={item} />
              </Cell>
              <Cell>{get(item, 'clientName') || t('common:notSpecified')}</Cell>
              {permissions.map(module =>
                module.permissions.map(permission => (
                  <Cell className="u-alignCenter" key={permission.name}>
                    {item.permissions[permission.name] ? <Icon icon={MdCheck} size={21} /> : <span>&ndash;</span>}
                  </Cell>
                )),
              )}
              {renderActions(item)}
            </Row>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

UserSitesList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  siteUrl: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  isTable: PropTypes.bool.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
)(UserSitesList);
