angular.module('webUi.ui.tagmanagement.tags.websitePathGroups.path.delete', ['webUi.service', 'ui.bootstrap'])

    .controller('WebsitePathGroupPathDeleteController', ['$scope', 'WebsitePathGroupService', 'dialogsModel', '$uibModalInstance',

        function WebsitePathGroupPathDeleteController($scope, WebsitePathGroupService, dialogsModel, $uibModalInstance) {

            $scope.path = dialogsModel.path;
            $scope.websitePathGroupId = dialogsModel.websitePathGroupId;
            $scope.deletePath = function() {
                WebsitePathGroupService.removePath($scope.websitePathGroupId, $scope.path).then(function() {
                    $uibModalInstance.close(true);
                });
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };

        }]);
