import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Client's sites stats for currently viewing period of time
 * @param state
 * @param action
 * @returns []
 */
const list = (state = [], action) => {
  switch (action.type) {
    case types.CLIENT_VIEW_FETCH_SITE_STATS_SUCCESS:
      return action.payload;
    case types.CLIENT_VIEW_FETCH_SITE_STATS_START:
    case types.CLIENT_VIEW_FETCH_SITE_STATS_FAIL:
      return [];
    default:
      return state;
  }
};

const defaultUI = {
  isFetching: false,
  shouldFetchAll: true,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.CLIENT_VIEW_FETCH_SITE_STATS_START:
      return {
        ...state,
        isFetching: true,
        shouldFetchAll: false,
      };
    case types.CLIENT_VIEW_FETCH_SITE_STATS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shouldFetchAll: true,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  ui,
});
