import { combineReducers } from 'redux';
import { isArray } from 'lodash';
import * as types from './types';

/**
 * Clients for user
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let userClients;
  switch (action.type) {
    case types.USER_CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: action.payload.clients,
      };
    case types.REMOVE_CLIENT_MANAGER_SUCCESS:
      userClients = state[action.payload.userId];
      return !isArray(userClients)
        ? state
        : {
            ...state,
            [action.payload.userId]: userClients.filter(item => item.clientId !== action.payload.clientId),
          };
    default:
      return state;
  }
};

/**
 * UI State
 */
const defaultUI = {
  isFetchingUserClients: false,
  isAddingClients: false,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.USER_CLIENTS_FETCH_START:
      return {
        ...state,
        isFetchingUserClients: true,
      };
    case types.USER_CLIENTS_FETCH_SUCCESS:
    case types.USER_CLIENTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingUserClients: false,
      };
    case types.ADD_CLIENT_MANAGER_START:
      return {
        ...state,
        isAddingClients: true,
      };
    case types.ADD_CLIENT_MANAGER_SUCCESS:
    case types.ADD_CLIENT_MANAGER_FAIL:
      return {
        ...state,
        isAddingClients: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ui,
});
