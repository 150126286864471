import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import { TConsentTypes, TCreateUserList, TUserList } from './types';
import { mapConsentTypes } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/googlecustomermatch`;

export const fetchUserLists = (partnerId: string): Promise<TUserList[]> =>
  Api.callGet(`${BASE_PATH}/${partnerId}/userLists`).then(parseResponse);

export const fetchConsentTypes = (): Promise<TConsentTypes[]> =>
  Api.callGet(`${BASE_PATH}/consentTypes`).then(parseResponse).then(mapConsentTypes);

export const createUserList = (payload: TCreateUserList, partnerId: string): Promise<TUserList> =>
  Api.callPost(`${BASE_PATH}/${partnerId}/userLists`, payload).then(parseResponse);

export const getConnectorDetails = (partnerId: string) =>
  Api.callGet(`${API_BASE}/v1/partners/${partnerId}`).then(parseResponse);
