import { get } from 'lodash';

export const getPermissions = state => get(state, 'permissions.list');

export const getUiPermissions = state =>
  getPermissions(state)
    .map(item => ({
      ...item.module,
      permissions: item.permissions.reduce((currents, current) => {
        if (!current.apiPermission) {
          currents.push(current.permission);
        }
        return currents;
      }, []),
    }))
    .filter(item => item.permissions.length);

export const getApiPermissions = state =>
  getPermissions(state)
    .map(item => ({
      ...item.module,
      permissions: item.permissions.reduce((currents, current) => {
        if (current.apiPermission) {
          currents.push(current.permission);
        }
        return currents;
      }, []),
    }))
    .filter(item => item.permissions.length);

export const shouldFetchPermissions = state => get(state, 'permissions.list').length === 0;

export const isFetchingPermissions = state => get(state, 'permissions.ui.isFetchingPermissions');

const selectors = {
  getPermissions,
  getUiPermissions,
  getApiPermissions,
  shouldFetchPermissions,
  isFetchingPermissions,
};

export default selectors;
