export const VARIABLES_FETCH_START = 'app/profiles/variables/VARIABLES_FETCH_START';
export const VARIABLES_FETCH_FAIL = 'app/profiles/variables/VARIABLES_FETCH_FAIL';
export const VARIABLES_FETCH_SUCCESS = 'app/profiles/variables/VARIABLES_FETCH_SUCCESS';

export const VARIABLES_DELETE_START = 'app/profiles/variables/VARIABLES_DELETE_START';
export const VARIABLES_DELETE_FAIL = 'app/profiles/variables/VARIABLES_DELETE_FAIL';
export const VARIABLES_DELETE_SUCCESS = 'app/profiles/variables/VARIABLES_DELETE_SUCCESS';

export const VARIABLES_SAVE_START = 'app/profiles/variables/VARIABLES_SAVE_START';
export const VARIABLES_SAVE_FAIL = 'app/profiles/variables/VARIABLES_SAVE_FAIL';
export const VARIABLES_SAVE_SUCCESS = 'app/profiles/variables/VARIABLES_SAVE_SUCCESS';

export const VARIABLES_SHOW_DEPENDANTS_CONTAINER = 'app/profiles/variables/VARIABLES_SHOW_DEPENDANTS_CONTAINER';
export const VARIABLES_HIDE_DEPENDANTS_CONTAINER = 'app/profiles/variables/VARIABLES_HIDE_DEPENDANTS_CONTAINER';
export const VARIABLES_DEPENDANTS_TYPE_TAB_CHANGE = 'app/profiles/variables/VARIABLES_DEPENDANTS_TYPE_TAB_CHANGE';

export const VARIABLES_NEW_FORM_NAME = 'variablesNew';
