import React from 'react';
import cx from 'classnames';
import { TStatusIndicatorProps } from '~/workflows/types';
import Link from '~/components/src/Link';
import { buildUrl } from '~/common';
import { WORKFLOW_TYPE } from '~/workflows/constants';

const StatusIndicator = ({ workflow, testHook = '' }: TStatusIndicatorProps) => {
  const { state, name, journeyId, workflowType } = workflow;

  return (
    <div className={`t-${testHook} flex items-center`}>
      <div className="mr-2 w-3">
        <span
          className={cx(
            'block h-2 w-2 rounded-full',
            { 'bg-green-500': state.name === 'PUBLISHED' },
            { 'bg-orange-500': state.name !== 'PUBLISHED' },
          )}
        />
      </div>
      <Link
        className="text-r42-blue"
        href={
          workflowType?.name === WORKFLOW_TYPE?.Journey
            ? buildUrl(`workflows/edit/${journeyId}`)
            : buildUrl(`workflows/view/${journeyId}`)
        }
      >
        {name}
      </Link>
    </div>
  );
};

export default StatusIndicator;
