import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';
import './styles.scss';

const convertValue = ({ options, value, getOptionValue }) => {
  if (typeof value === 'object') {
    // Default behavior of React Select v2+
    return value;
  }
  // Legacy behavior of React Select v1
  return options.find(option => {
    const targetValue = getOptionValue ? getOptionValue(option) : option.value;
    return targetValue === value;
  });
};
// We need those components for proper styling
const ReactSelect = props => {
  const {
    options,
    value,
    getOptionLabel,
    getOptionValue,
    noOptionsMessage,
    className,
    testHook,
    allowSelectAll,
    allOption,
    hint,
    ...otherProps
  } = props;
  const selectValue = convertValue({ value, options, getOptionValue });
  const selectClassName = cx('SelectElement', className, { [`t-${testHook}`]: testHook });

  const renderOptions = options => {
    if (allowSelectAll) {
      if (value && value.length === options.length) {
        return options;
      }
      return [allOption, ...options];
    }
    return options;
  };

  return (
    <>
      <Select
        {...props}
        value={selectValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        noOptionsMessage={() => noOptionsMessage}
        className={selectClassName}
        classNamePrefix="react-select"
        {...otherProps}
        options={renderOptions(options)}
      />
      {hint && <span className="Form-hint u-block u-marginTopS">{hint}</span>}
    </>
  );
};

const ReactCreatableSelect = props => {
  const { options, value, getOptionLabel, getOptionValue, className, testHook, ...otherProps } = props;
  const selectValue = convertValue({ value, options, getOptionValue });
  const selectClassName = cx(className, { [`t-${testHook}`]: testHook });

  return (
    <CreatableSelect
      {...props}
      value={selectValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      classNamePrefix="react-select"
      className={selectClassName}
      {...otherProps}
    />
  );
};

export { ReactSelect as Select, ReactCreatableSelect as CreatableSelect };
