import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import AdminValueSetService from './dataService.js';

/**
 * Fetch valueSets
 */
export const fetchValueSets = () => dispatch => {
  dispatch({ type: types.VALUESETS_FETCH_START });

  return AdminValueSetService.getValueSets()
    .then(valueSets => {
      dispatch({ type: types.VALUESETS_FETCH_SUCCESS, payload: valueSets });
    })
    .catch(error => dispatch({ type: types.VALUESETS_FETCH_FAIL, payload: error }));
};

/**
 * Saves new value sets
 * @param valueSets
 */
export const saveValueSet = valueSets => dispatch => {
  dispatch({ type: types.VALUESETS_SAVE_START });

  return AdminValueSetService.saveValueSet(valueSets)
    .then(predefinedValuesSetId => {
      dispatch({
        type: types.VALUESETS_SAVE_SUCCESS,
        payload: {
          ...valueSets,
          predefinedValuesSetId,
        },
      });
      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: types.VALUESETS_SAVE_FAIL, payload: error }));
};

/**
 * Edit value sets
 */
export const editValueSet = valueSets => dispatch => {
  dispatch({ type: types.VALUESETS_EDIT_START });

  return AdminValueSetService.updateValueSet(valueSets)
    .then(() => {
      dispatch({ type: types.VALUESETS_EDIT_SUCCESS, payload: valueSets });

      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: types.VALUESETS_EDIT_FAIL, payload: error }));
};

/**
 * Delete value sets
 * @param ValueSetId
 */
export const deleteValueSet = ValueSetId => dispatch => {
  dispatch({ type: types.VALUESETS_DELETE_START });

  return AdminValueSetService.deleteValueSet(ValueSetId)
    .then(() => {
      showSuccess({ body: i18n.t('urlBuilder:valueSets.api.deletionSuccess') });
      dispatch({ type: types.VALUESETS_DELETE_SUCCESS, payload: ValueSetId });
    })
    .catch(error => dispatch({ type: types.VALUESETS_DELETE_FAIL, payload: error }));
};

export const handleSetsValidationShow = () => dispatch => {
  dispatch({ type: types.VALUESETS_VALIDATION_SHOW });
};

export const handleSetsValidationHide = () => dispatch => {
  dispatch({ type: types.VALUESETS_VALIDATION_HIDE });
};
