import React, { useState } from 'react';

import i18n from '~/i18n';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import DateValidator from '~/components/src/DateValidator';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

import { TimeFrameOptions } from '../constants';

import { TElement } from '../types';
import './styles.scss';

export const DateInput = ({ onChange, onBlur, errorText, filter, value, autoFocus }: TElement) => {
  const [showDateFormatModal, setShowDateFormatModal] = useState(false);

  const selectedOption = TimeFrameOptions.find(option => option.value === filter.timeFrame);

  const onModalChange = (value: string) => {
    onChange(value, 'dateFormat');
    setShowDateFormatModal(false);
  };

  const ModalContents = ({ dateFormat }: { dateFormat: string }) => (
    <DateValidator initialValue={dateFormat} onChange={value => onModalChange(value)} />
  );

  return (
    <>
      <div className="DateFormat-Container">
        <FieldWrapper errorText={errorText}>
          <InputElement
            className="t-dateInput w-[30%]"
            value={value}
            onChange={e => onChange(e.target.value)}
            hasError={!!errorText}
            onBlur={onBlur}
            autoFocus={autoFocus}
            placeholder=""
          />
        </FieldWrapper>
        <SelectElement
          className="DateFormat-dateSelect"
          value={selectedOption}
          options={TimeFrameOptions}
          onChange={selectedOption => onChange(selectedOption.value, 'timeFrame')}
        />
        <BtnIcon
          className="mt-1 h-10 w-10"
          onClick={() => setShowDateFormatModal(true)}
          icon="calendar"
          testHook="dateFormatModalButton"
        />
      </div>
      {showDateFormatModal && (
        <PickerView
          pickerTitle={i18n.t('forms:dateFormat.modal.title')}
          handlePickerVisibility={() => setShowDateFormatModal(false)}
          className="!h-auto"
        >
          <ModalContents dateFormat={filter.dateFormat} />
        </PickerView>
      )}
    </>
  );
};

export default DateInput;
