angular.module('webUi.ui.test.base', [
    'webUi.ui.siteState'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.test', {
                url: '/test',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/test/base/base.tpl.html',
                        controller: 'TestBaseController as vm'
                    }
                },
                resolve: {
                    isSupportEngineer: ['securityContext', function (securityContext) {
                        return securityContext.isSupportEngineer();
                    }]
                }
            });
    }])
    .controller('TestBaseController',[ '$state', 'isSupportEngineer', function TestBaseController($state, isSupportEngineer) {

        'use strict';
        
        var vm = this;
        vm.isSupportEngineer = isSupportEngineer;
        if (!vm.isSupportEngineer) {
            $state.transitionToStateWithSite('site.error.403', {});
            return;
        } else {
        //$state.transitionToStateWithSite('site.test.validation.dashboard', {});
        //return;
        }
    }]);
