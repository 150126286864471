import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IndexedPaths from './IndexedPaths';

const IndexedPathList = ({ hasTagEdit }: { hasTagEdit: boolean }): JSX.Element => (
  <Routes>
    <Route path="list/:path" element={<IndexedPaths hasTagEdit={hasTagEdit} />} />
  </Routes>
);

export default IndexedPathList;
