angular.module('webUi.directive.support.template.r42Intro', [])

    .directive('r42intro', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: false,
            transclude: true,
            templateUrl: 'directive/support/template/r42Intro.tpl.html'
        };
    }
    ]);
