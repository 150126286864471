import React from 'react';
import i18n from '~/i18n';
import InputElement from '~/components/src/Form/Elements/InputElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import { TElement } from '../types';

const NumberWithInInput = ({ onChange, filter, onBlur, errorText, autoFocus }: TElement) => (
  <FieldWrapper errorText={errorText}>
    <div className="!flex gap-2">
      <InputElement
        type="number"
        value={filter?.rangeFrom}
        onChange={e => onChange(e.target.value, 'rangeFrom')}
        onBlur={onBlur}
        autoFocus={autoFocus}
        placeholder={i18n.t('common:placeholders.rangeFrom')}
        hasError={!!errorText}
      />
      <InputElement
        type="number"
        value={filter?.rangeTo}
        onChange={e => onChange(e.target.value, 'rangeTo')}
        onBlur={onBlur}
        placeholder={i18n.t('common:placeholders.rangeTo')}
        hasError={!!errorText}
      />
    </div>
  </FieldWrapper>
);

export default NumberWithInInput;
