/* eslint-disable no-prototype-builtins */
angular.module('webUi.ui.tagmanagement.tags.form.step1', [
    'rt.encodeuri'
])
    .controller('TagmanagementTagFormStep1Controller', [
        '$scope',
        '$timeout',
        'TagmanagementService',
        'SecurityService',
        'CookiePermissionService',
        /**
         * @function TagmanagementTagFormStep1Controller
         * @memberOf webUi.ui.tagmanagement.tags.form
         *
         * @param $scope
         * @param $timeout
         * @param TagmanagementService
         * @param SecurityService
         *
         * @returns {TagmanagementTagFormStep1Controller}
         */
        function TagmanagementTagFormStep1Controller($scope, $timeout, TagmanagementService, SecurityService, CookiePermissionService) {

            SecurityService.getSecurityContext().then(function (securityContext) {
                $scope.availableModules = securityContext.getAvailableModules();
            });

            TagmanagementService.getTemplates().then(function (templates) {
                $scope.state.templates = templates;

                // get all groups and enable by default
                var templateGroups = [];
                var selectedGroups = {};

                _.forEach(templates, function (tpl) {
                    templateGroups.push({groupId: tpl.groupId, groupName: tpl.groupName});
                    selectedGroups[tpl.groupId] = true;
                });

                $scope.templateGroups = _.uniq(templateGroups, 'groupId');
                $scope.selectedGroups = selectedGroups;

            });

            //focus the search input
            $timeout(function () {
                $('#searchTemplates').focus();
            }, 1);

            $scope.state.submitCallback = function () {
            };

            $scope.templateSearch = {
                text: '',
                includePrivate: Boolean(JSON.parse(window.sessionStorage.tagsIncludePrivate || 'false'))
            };

            // track changes and store in sessionStorage
            $scope.$watch('templateSearch.includePrivate', function (newVal) {
                window.sessionStorage.tagsIncludePrivate = newVal;
            });

            $scope.selectTemplate = function (template) {
                $scope.state.selectedTemplate = template;
                // unselect all templates
                $scope.state.templates = $scope.state.templates.map(function (item) {
                    item.isSelected = false;
                    return item;
                });
                // select the current one
                template.isSelected = true;
                $('.template-sidebar').effect('highlight', {color: '#33b062'}, 500);
            };

            $scope.selectVariant = function (variant) {

                if ($scope.state.selectedTemplate.variants.length > 0) {
                    var indexedVariants = _.indexBy($scope.state.selectedTemplate.variants, 'variantId');
                    if (indexedVariants.hasOwnProperty(variant.variantId)) {
                        $scope.state.selectedVariant = indexedVariants[variant.variantId];
                    }
                }

                return initVariant(variant) || false;
            };

            /**
             * Inits variant, basically force-feeding $scope.tag
             * @param variant
             */
            var initVariant = function (variant) {

                var newVariables = {};

                if (!_.isUndefined($scope.tag.content) && variant.variantId === $scope.tag.content.variantId) {
                    // Clicked the same variant: Don't overwrite the variables
                    newVariables = $scope.tag.content.variables;
                }

                var clazzName;
                if ($scope.state.selectedTemplate.tagContentType.name) {
                    switch ($scope.state.selectedTemplate.tagContentType.name) {
                        case 'TEMPLATE_TAG':
                            clazzName = 'TemplateTagContent';
                            break;
                        case 'TEXT_TAG':
                            clazzName = 'TextTagContent';
                            break;
                        case 'COOKIE_SETTING_TAG':
                            clazzName = 'CookieSettingTagContent';
                            break;
                        case 'HTTP_REQUEST_TAG':
                            clazzName = 'HttpRequestTagContent';
                            break;
                    }
                }

                //
                //only TEXT_TAG and COOKIE_SETTING_TAG require special treatment regarding attrs
                //
                $scope.tag.content = {
                    clazz: clazzName,
                    variantId: variant.variantId,
                    sequenceNr: variant.sequenceNr,
                    variables: newVariables
                };

                if ($scope.state.selectedTemplate.tagContentType.name === 'TEXT_TAG') {
                    $scope.tag.content.code = $scope.state.selectedVariant.jsCode || null;
                }

                if ($scope.state.selectedTemplate.tagContentType.name === 'COOKIE_SETTING_TAG') {
                    CookiePermissionService.findAllCookiePermissionSettings().then(
                        function onSuccess(settings) {

                            $scope.state.selectedVariant.settings = settings;
                            $scope.tag.compression = true;

                            $scope.tag.content = _.extend($scope.tag.content, {
                                cookieSettingId: (typeof settings !== 'undefined' && settings.length > 0) ? settings[0].cookiePermissionSettingKey.settingsId : undefined,
                                userAgentFilter: 'Google Web Preview|Googlebot',
                                cookiesEnabled: true
                            });
                        },
                        function onError() {
                        }
                    );
                }

                if (_.isNull(variant.name) || _.isEmpty(variant.name)) {
                    $scope.tag.name = $scope.state.selectedTemplate.name;
                } else {
                    $scope.tag.name = $scope.state.selectedTemplate.name + ': ' + variant.name;
                }

                $scope.tag.priority = variant.defaultPriority;

                $scope.nextStep();
            };
        }
    ])
    .filter('templateVariantFilter', function () {
        return function (templates, searchStr) {

            if (_.isUndefined(templates)) {
                return [];
            }

            if (_.isEmpty(searchStr)) {
                return templates;
            }

            searchStr = searchStr.toLowerCase();

            return _.filter(templates, function (template) {
                if (template.name && template.name.toLowerCase().indexOf(searchStr) > -1 || template.description && template.description.toLowerCase().indexOf(searchStr) > -1) {
                    return true;
                }
                for (var i = 0; i < template.variants.length; i++) {
                    var variant = template.variants[i];
                    if (variant.name && variant.name.toLowerCase().indexOf(searchStr) > -1 || variant.description && variant.description.toLowerCase().indexOf(searchStr) > -1) {
                        return true;
                    }
                }

                return false;
            });
        };
    })
    .filter('templatePrivateFilter', function () {
        return function (templates, allowPrivate) {

            if (_.isUndefined(templates)) {
                return [];
            }

            if (allowPrivate === true) {
                return templates;
            }

            return _.filter(templates, function (template) {

                // allow if there is at least 1 not private variant
                for (var i = 0; i < template.variants.length; i++) {
                    var variant = template.variants[i];
                    if (!variant.privateMode) {
                        return true;
                    }
                }

                return false;
            });
        };
    })
// Filters templates based on the modules allowed for this site
    .filter('templateModulesFilter', function () {
        return function (templates, availableSiteModules) {
            if (_.isUndefined(templates)) {
                return [];
            }

            if (_.isUndefined(availableSiteModules)) {
                return [];
            }

            return _.filter(templates, function (template) {
                for (var i = 0; i < template.variants.length; i++) {
                    var variant = template.variants[i];
                    // List only variants with site modules that part of the available site modules
                    if (_.isUndefined(variant.siteModule) || _.isNull(variant.siteModule)) {
                        // No site module has been specified for this variant, so display it
                        return true;
                    }
                    return _.contains(_.pluck(availableSiteModules, 'name'), variant.siteModule.name);
                }
            });
        };
    });
