import ApiService from '~/common/ApiService';

const BASE_PATH = '/api/ga/userActions';

export const handleGAEvent = async ({ gaAction = 'Default Action' }) => {
  try {
    await ApiService.callPost(
      BASE_PATH,
      { userAction: gaAction },
      {
        shouldHandleCommonErrors: false,
        shouldShowToast: false,
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error triggering GA event:', error);
  }
};
