import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { BeaconObject, BeaconStatObject, TotalUsageObject } from './types';

const BASE_PATH = `${API_BASE}/remote/beacons`;

export const fetchBeacons = (): Promise<BeaconObject[]> => Api.callGet(BASE_PATH).then(response => response.data);

export const fetchBeaconById = (beaconId: string): Promise<BeaconObject> =>
  Api.callGet(`${BASE_PATH}/${beaconId}`).then(response => response.data);

export const fetchBeaconStats = (beaconId: string, startDate: number, endDate: number): Promise<BeaconStatObject[]> =>
  Api.callGet(`${BASE_PATH}/${beaconId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}`).then(
    response => response.data,
  );

export const fetchTotalUsage = (): Promise<TotalUsageObject[]> =>
  Api.callGet(`${BASE_PATH}/stats/totalUsage`).then(response => response.data);

export const createBeacon = (payload: { beaconName: string }): Promise<BeaconObject> =>
  Api.callPost(BASE_PATH, payload).then(parseResponse);

export const deleteBeacon = (beaconId: string): Promise<void> =>
  Api.callDelete(`${BASE_PATH}/${beaconId}`).then(parseResponse);
