import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import Link from '~/components/src/Link';
import { Panel } from '~/components/src/Containers';
import SortableHeader from '~/components/src/Table/sortableHeader';

const { Table, Row, Cell } = components;
const { withSort, withPaginate } = enhancements;

export const CookiesList = ({ list, stateKey, variantUrl, t }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader sortKey="name" title={t('list.templateName')} />
      </Row>
      {list.map(item => (
        <Row key={item.cookiePermissionVariantId}>
          <Cell>
            <Link href={variantUrl(item)}>{item.name}</Link>
          </Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

CookiesList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  variantUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withSort(), withPaginate({ size: 10 }))(CookiesList);
