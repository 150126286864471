import moment from 'moment';
import i18n from '~/i18n';
import { CSVType, ErrorType, MappingType } from './types';

export const headers = {
  eventType: i18n.t('connectors:errorStats.csvHeaders.eventType'),
  eventName: i18n.t('connectors:errorStats.csvHeaders.eventName'),
  sourceProperty: i18n.t('connectors:errorStats.csvHeaders.sourceProperty'),
  destinationProperty: i18n.t('connectors:errorStats.csvHeaders.destinationProperty'),
  sourceValue: i18n.t('connectors:errorStats.csvHeaders.sourceValue'),
  transformationType: i18n.t('connectors:errorStats.csvHeaders.transformationType'),
  errorMessage: i18n.t('connectors:errorStats.csvHeaders.errorMessage'),
  timestamp: i18n.t('connectors:errorStats.csvHeaders.timestamp'),
};

export const formattedCSVData = (data: ErrorType[]): CSVType[] => {
  const array: CSVType[] = [];
  data.forEach((tError: ErrorType) => {
    tError.mappings.forEach((mError: MappingType) => {
      array.push({
        eventType: tError.eventType,
        eventName: tError.eventName,
        sourceProperty: mError.sourceProperty,
        destinationProperty: mError.destinationProperty,
        sourceValue: mError.sourceValue,
        transformationType: mError.transformationType,
        errorMessage: mError.error.message,
        timestamp: moment(mError.timestamp).format('HH:mm:ss DD/MM/YYYY'),
      });
    });
  });
  return array;
};
