import React from 'react';
import PropTypes from 'prop-types';
import Empty from '~/components/src/Empty';

const EmptyList = ({ t, searchQuery }) =>
  searchQuery ? (
    <Empty header={t('list.noResults.header')} body={t('list.noResults.body', { searchQuery })} />
  ) : (
    <Empty header={t('list.empty.header')} body={t('list.empty.body')} />
  );

EmptyList.propTypes = {
  t: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

export default EmptyList;
