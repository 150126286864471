/**
 * Profiles Duck
 */
import { combineReducers } from 'redux';
import audiences from './audiences';
import audiencesTree, { audienceEpics } from './audiencesTree';
import collectors from './collectors';
import dependencies from './dependencies';
import engagements from './engagements';
import customerFacts from './customerFacts';
import connectors, { eventConnectorsReducer as eventConnectors } from './connectors';
import variables from './variables';
import { exportReducer } from './export';
import { newExportReducer } from './export/new';
import connectorFormReducer from './connectors/form/connectorFormReducer';
import audienceActivityReducer from './audiences/activity/reducer';

export * from './audiencesTree';
export * from './collectors';
export * from './variables';

// Combine and export epics
const epicsObject = {
  ...audienceEpics,
};
export const epics = Object.keys(epicsObject).map(key => epicsObject[key]);

// Default Profiles Reducer
export default combineReducers({
  audiences,
  audiencesTree,
  collectors,
  dependencies,
  engagements,
  customerFacts,
  connectors,
  eventConnectors,
  connectorsForm: connectorFormReducer,
  variables,
  export: exportReducer,
  newExport: newExportReducer,
  audienceActivity: audienceActivityReducer,
});
