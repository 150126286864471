import React from 'react';
import { ClientListContainer } from '~/context/clients/containers';
import Page from '~/components/src/Page';
import { MediumWrapper } from '~/components/src/Containers';

const ClientsPage = ({ isContextAdmin }) => (
  <Page>
    <MediumWrapper>
      <ClientListContainer isContextAdmin={isContextAdmin} />
    </MediumWrapper>
  </Page>
);

export default ClientsPage;
