import { createContext } from 'react';
import { NavigateFunction } from 'react-router-dom';

type ContextType = {
  navigate: NavigateFunction;
  hasWorkflowJourneyEdit: boolean;
  hasWorkflowAudienceEdit: boolean;
  joDemoMode: boolean;
};

const defaultValues = {
  hasWorkflowJourneyEdit: false,
  hasWorkflowAudienceEdit: false,
  joDemoMode: false,
  navigate: () => {
    // Intentionally left blank
  },
};

export const WorklfowContext = createContext<ContextType>(defaultValues);
