import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common';
import { saveGroup } from '~/support/tagtemplates/groups/actions';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import TagGroupForm from '~/support/components/TagGroupForm';
import { Panel, MediumWrapper } from '~/components/src/Containers';

export const TagTemplateGroup = ({ t, ...other }) => (
  <Page>
    <MediumWrapper>
      <Heading title={t('groupForm.header')} />
      <Panel>
        <TagGroupForm t={t} {...other} />
      </Panel>
    </MediumWrapper>
  </Page>
);

export default compose(
  connect(
    () => ({
      backHref: buildUrl('support/tagtemplates'),
    }),
    dispatch => ({
      onSubmit: values => dispatch(saveGroup(values)),
    }),
  ),
  reduxForm({
    form: 'tagGroupNew',
  }),
  translate(['tagTemplates']),
)(TagTemplateGroup);
