/**
 * A directive that disables the clicked button and re-enables it after the promise is resolved. For buttons that
 * use state transition there is no need to create a promise - they will be disabled until the transition to state is completed.
 *
 * In order to use this directive, put it as an attribute on a <button> or <a> and give it the name of the function that should
 * be executed on click or submit.
 *
 *  E.g:
 * <a data-click-and-wait="next" data-ng-click="next()">Next</a>
 * <button type="button" data-click-and-wait="next" data-ng-click="next()">Next</button>
 * <button type="submit" data-click-and-wait="next">Submit</button>
 *
 * @param {type} param1
 * @param {type} param2
 */
angular.module('webUi.directive.clickAndWait', ['webUi.common.Utils'])

    .directive('clickAndWait', ['$compile', 'Utils', function($compile, Utils) {

        return {
            restrict: 'A',
            compile: function(el, attrs) {

                var elemIdentifier = (attrs.clickAndWaitId || 'default');
                var fExpr = attrs.clickAndWait;

                return function(scope, el) {

                    scope.disabledButtons = scope.disabledButtons || {};

                    var actualFunction = scope.$eval(fExpr);

                    var wrapper = function() {
                        var loading;
                        scope.disabledButtons[elemIdentifier] = true;
                        el.attr('disabled', 'true');
                        el.addClass('click-disabled');
                        if(_.indexOf(['A', 'DIV', 'BUTTON'], el[0].tagName) !== -1) {
                            loading = $('<span />').addClass('progress-loading-button');
                            el.append(loading);
                        }

                        var result = actualFunction.apply(this, arguments);

                        var removedDisabled = function() {
                            scope.disabledButtons[elemIdentifier] = false;
                            el.removeAttr('disabled');
                            el.removeClass('click-disabled');
                            el.find('span.progress-loading-button').remove();
                        };

                        if (result && Utils.isPromise(result)) {
                            result.then(function(res) {
                                removedDisabled();
                                return res;
                            }, function () {
                                removedDisabled();
                            });
                        } else {
                            removedDisabled();
                        }
                    };
                    scope[fExpr] = wrapper;
                };
            }
        };
    }]);
