( function() {

    const RULE_DEF_TYPES = {
        VISIT: 'Visit',
        CONVERSION: 'Conversion',
        ENGAGEMENT: 'Engagement',
        EXPERIMENT: 'Experiment',
        EXTERNAL: 'External',
        REUSABLE_AUDIENCE: 'Reusable audience',
        AI: 'AI Fact'
    };

    angular.module('webUi.service.profilesConstants', [])
        .constant(
            'INTERACTION_CONSTANTS', {
                engagementType: 'ENGAGEMENT',
                externalFactType: 'EXTERNAL FACT'
            }
        )
        .constant(
            'OVERVIEW_TYPES_CONSTANTS', {
                interaction: 'Interaction',
                variable: 'Variable',
                disabledAdType: 'AD',
                disabledCollectorType: 'THIRD_PARTY_DATA_COLLECTOR'
            }
        )
        .constant(
            'USAGE_OVERVIEW_CONSTANTS', {
                overviewAlertMessages: {
                    information: 'information',
                    alert: 'forbidden'
                },
                overviewTypes : [
                    {
                        key: 'selectionDependants',
                        name: 'Audiences',
                        subKey: 'dependantsSet',
                        dependantKey: 'SELECTION',
                        iconFile: 'cj-selection',
                        propertyKeys: [
                            {
                                name: 'Name',
                                key: 'selections',
                            }
                        ],
                        enabled: true

                    },
                    {
                        key: 'dataCollectorDependants',
                        name: 'Data Partners',
                        subKey: 'dependantsSet',
                        dependantKey: 'THIRD_PARTY_DATA_COLLECTOR',
                        iconFile: 'cj-data-collector',
                        propertyKeys: [
                            {
                                name: 'Name',
                                key: 'contents'
                            }
                        ],
                        enabled: true
                    },
                    {
                        key: 'contentDependants',
                        name: 'Ad',
                        subKey: 'dependantsSet',
                        dependantKey: 'AD',
                        iconFile: 'cj-ad',
                        propertyKeys: [
                            {
                                name: 'Name',
                                key: 'contents'
                            }
                        ],
                        enabled: true
                    },
                    {
                        key: 'journeyDependants',
                        name: 'Journeys',
                        subKey: 'dependantsSet',
                        dependantKey: 'JOURNEY',
                        iconFile: 'cj-share',
                        propertyKeys: [
                            {
                                name: 'Name',
                                key: 'journeyIdName'
                            },
                            {
                                name: 'Trigger',
                                key: 'dependantTriggers'
                            }
                        ],
                        enabled: true
                    }
                ]
            }
        )
        .constant(
            'STORE_VARIABLE', {
                availableVariableTypes: [
                    {'name': 'STRING', 'label': 'string', options: {}},
                    {'name': 'LIST', 'label': 'list', options: {}}
                ],
                maxLengthVariableName: 65

            }
        )
        .constant('REMOTE_CONSTANT', {
            engagementType: 'ENGAGEMENT',
            externalFactType: 'EXTERNAL FACT'
        })
        .constant('RULE_DEFINITION_TYPES', {
            defaultRuleDefinitions: [
                RULE_DEF_TYPES.VISIT,
                RULE_DEF_TYPES.CONVERSION,
                RULE_DEF_TYPES.ENGAGEMENT,
                RULE_DEF_TYPES.EXPERIMENT,
                RULE_DEF_TYPES.AI,
                RULE_DEF_TYPES.EXTERNAL,
                RULE_DEF_TYPES.REUSABLE_AUDIENCE
            ],
            visit: RULE_DEF_TYPES.VISIT,
            conversion: RULE_DEF_TYPES.CONVERSION,
            engagement: RULE_DEF_TYPES.ENGAGEMENT,
            experiment: RULE_DEF_TYPES.EXPERIMENT,
            external: RULE_DEF_TYPES.EXTERNAL,
            reusableAudience: RULE_DEF_TYPES.REUSABLE_AUDIENCE,
            ai: RULE_DEF_TYPES.AI
        })
        .constant('DEFAULT_PAGING', {
            page: 0,
            size: 20,
            sort: ['name,ASC']
        });
})();
