import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildUrl, useApiWithState } from '~/common';
import { Heading, Notification, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import CampaignsTable from './CampaignsTable';
import { fetchAllCampaigns } from './dataService';
import { TCampaign } from './types';

const CampaignsDashboard = () => {
  const navigate = useNavigate();

  const {
    state: data = [],
    isLoading,
    error,
    refetch: refetchCampaigns,
  } = useApiWithState(async () => fetchAllCampaigns());

  const goToCreateScreen = () => navigate(buildUrl('content/campaigns2/form'));

  const [query, setQuery] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState<TCampaign[]>(data || []);

  useEffect(() => {
    setFilteredCampaigns(data || []);
  }, [data]);

  useEffect(() => {
    if (query) {
      const filteredCampaigns = data?.filter(campaign => campaign.name.toLowerCase().includes(query));
      setFilteredCampaigns(filteredCampaigns || []);
    } else {
      setFilteredCampaigns(data || []);
    }
  }, [query]);

  const isEmpty = filteredCampaigns?.length === 0;

  return (
    <Page>
      <Heading title={i18n.t('external:title')}>
        <SearchElement
          testHook="campaignsSearch"
          value={query}
          onChange={e => setQuery(e.target.value.toLowerCase())}
          placeholder={i18n.t('common:actions.search')}
        />
        <BtnIcon
          icon="add"
          color="blue"
          testHook="addCampaign"
          onClick={() => goToCreateScreen()}
          tooltip={i18n.t('external:actions.create')}
        />
      </Heading>
      {!isEmpty && error && <Notification kind="error">{error}</Notification>}
      {isLoading && !data ? (
        <Spin />
      ) : (
        <CampaignsTable campaigns={filteredCampaigns || []} refetchCampaigns={refetchCampaigns} />
      )}
    </Page>
  );
};

export default CampaignsDashboard;
