/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_MESSAGES = `${API_BASE}/support/messages`;

/**
 * A service for Messages related queries
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class SupportMessagesClass {
  getMessage(messageId) {
    return Api.callGet(`${API_MESSAGES}/${messageId}`).then(response => response.data);
  }

  deleteMessage(messageId) {
    return Api.callDelete(`${API_MESSAGES}/${messageId}`).then(response => response.data);
  }

  updateMessage(message) {
    return Api.callPost(`${API_MESSAGES}/edit`, message).then(response => response.data);
  }

  saveMessage(message) {
    return Api.callPost(`${API_MESSAGES}/add`, message).then(response => response.data);
  }

  getAllMessages() {
    return Api.callGet(`${API_MESSAGES}`).then(response => response.data);
  }
}

const supportMessagesService = new SupportMessagesClass();
export default supportMessagesService;
