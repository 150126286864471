import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';

type TCopyIDProps = {
  id: string;
  testHook?: string;
  className?: string;
};

const CopyID = ({ id, testHook = 'copyId', className = '' }: TCopyIDProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <div className={cx('flex items-center', `t-${testHook}`, className)}>
      <span className="rounded-lg bg-gray-200 px-2 py-1 text-xs">{id}</span>
      <CopyToClipboard
        text={id}
        onCopy={() => {
          setCopied(true);
        }}
      >
        <BtnIcon
          className="h-6 w-6 cursor-pointer"
          icon="copy"
          color="grayTransparent"
          tooltip={i18n.t(`workflow:common.${copied ? 'copied' : 'copyId'}`)}
          onClick={e => {
            e.stopPropagation();
          }}
          hideOnClick={false}
          testHook={`${testHook}-btn`}
        />
      </CopyToClipboard>
    </div>
  );
};

export default CopyID;
