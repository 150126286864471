import React from 'react';
import Heading from '~/components/src/Heading';
import i18n from '~/context/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

const ModalHeader = ({ title, onCloseClick }) => (
  <Heading kind="h3" title={title} className="Modal-header p-4">
    {onCloseClick && (
      <BtnIcon icon="close" tooltip={i18n.t('generic:close')} onClick={onCloseClick} testHook="closeModalButton" />
    )}
  </Heading>
);

export default ModalHeader;
