import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface SkeletonLoaderProps {
  type?: 'text' | 'image' | 'rect';
  count?: number;
  height?: number | string;
  width?: number | string;
  circle?: boolean;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ type = 'text', count = 1, height, width, circle = false }) => {
  const renderSkeleton = () => {
    switch (type) {
      case 'text':
        return <Skeleton count={count} height={height} width={width} />;
      case 'image':
        return <Skeleton circle={circle} height={height} width={width} />;
      case 'rect':
        return <Skeleton count={count} height={height} width={width} />;
      default:
        return <Skeleton count={count} />;
    }
  };

  return <div>{renderSkeleton()}</div>;
};

export default SkeletonLoader;
