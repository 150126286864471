angular.module('webUi.ui.tagmanagement.tags.list.newPath', [])

    .controller('NewTagPathController', ['$scope', '$uibModalInstance', 'dialogsModel', function($scope, $uibModalInstance, dialogsModel){
        $scope.tree = dialogsModel.tree;
        $scope.hasTagEdit = dialogsModel.hasTagEdit;
        $scope.newPath = dialogsModel.newPath.replace(/\|/g,' > ');
        $scope.close = function(){
            $uibModalInstance.close(false);
        };
        $scope.ok = function(){
            $uibModalInstance.close(true);
        };
    }]);

