angular.module('webUi.ui.iplock', ['webUi.service.security', 'ui.router'])

    .constant('constantCodes', {
        smsCodeInvalid: 'SMS_CODE_INVALID',
        smsCodeExpired: 'SMS_CODE_EXPIRED',
        userLocked: 'USER_LOCKED',
        userBlocked: 'USER_BLOCKED',
        googleAuthCodeInvalid: 'GOOGLE_AUTH_CODE_INVALID',
        googleAuthDefault: 'GOOGLE_AUTH',
        EXIT: 'EXIT',
        NEXT: 'NEXT'
    })

    .constant('RESEND_SMS_WAIT_SECONDS', 180)

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('iplock', {
                url : '/iplock'
            });
    }])

    .controller('IPLockController', ['$scope', 'IPLockService', '$window', '$location', 'CsrfService', 'constantCodes', 'RESEND_SMS_WAIT_SECONDS', 'localStorageService',
        function IPLockController($scope, IPLockService, $window, $location, CsrfService, constantCodes, RESEND_SMS_WAIT_SECONDS, localStorageService) {

            var initializeVariablesState = function initializeVariablesState() {
                $scope.isCodeExpired = false;
                $scope.authFailed = false;
            };

            var siteIdRegex = new RegExp('/site-([0-9]+)');

            $scope.isSmsReSent = false;
            $scope.outsideOfSubnet = $location.search().outsideOfSubnet;

            initializeVariablesState();

            var resendSmsIntervalId = null;

            $scope.init = function (params) {
                CsrfService.setCsrfToken(params.csrfToken);
            };


            IPLockService.getUserInfo().then(function (result) {
                $scope.userInfo = result;
                if ($scope.userInfo.default2FA === constantCodes.googleAuthDefault) {
                    $scope.googleAuthDefault = true;
                }
                $scope.bothAuthEnabled = $scope.userInfo.smsLoginEnabled && $scope.userInfo.googleAuthEnabled;
                $scope.showSmsForm = !$scope.outsideOfSubnet && $scope.userInfo.smsLoginEnabled && !$scope.googleAuthDefault;
                if ($scope.showSmsForm) {
                    $scope.sendSmsAuthCode();
                }
                $scope.showGoogleAuthForm = !$scope.outsideOfSubnet && $scope.userInfo.googleAuthEnabled && $scope.googleAuthDefault;
            });

            $scope.sendSmsAuthCode = function () {
                if ($scope.isSmsReSent) {
                    // Don't do anything if the countdown is already running
                    return Promise.resolve();
                }

                $scope.isSmsReSent = true;

                var nowTimestamp = Date.now();
                var startTimestampFromLocalStorage = localStorageService.get('iplockSmsResendStartTimestamp');

                var isLocalStorageTimestampUsable = false;
                if (typeof startTimestampFromLocalStorage === 'number') {
                    isLocalStorageTimestampUsable = (startTimestampFromLocalStorage + RESEND_SMS_WAIT_SECONDS * 1000) > nowTimestamp;
                }

                var startTimestamp = isLocalStorageTimestampUsable ? startTimestampFromLocalStorage : nowTimestamp;
                localStorageService.set('iplockSmsResendStartTimestamp', startTimestamp);

                var secondsLeft = RESEND_SMS_WAIT_SECONDS - (nowTimestamp - startTimestamp) / 1000;
                var isNewCountdown = secondsLeft === RESEND_SMS_WAIT_SECONDS;

                window.clearInterval(resendSmsIntervalId);
                $scope.resendSmsWaitSecondsLeft = secondsLeft;

                return (isNewCountdown ? IPLockService.sendSmsAuthCode() : Promise.resolve())
                    .then(function onSuccess() {
                        resendSmsIntervalId = window.setInterval(function () {
                            $scope.resendSmsWaitSecondsLeft -= 1;

                            if ($scope.resendSmsWaitSecondsLeft <= 0) {
                                window.clearInterval(resendSmsIntervalId);
                                $scope.isSmsReSent = false;
                            }

                            $scope.$apply();
                        }, 1000);
                    }, function onError() {
                        $scope.isSmsReSent = false;
                    });
            };

            $scope.getRedirectPath = function (type) {
                var path = '/';
                var siteIdResult;
                if (type === constantCodes.NEXT) {
                    if (!_.isUndefined(localStorageService.get('nextUrl')) && !_.isNull(localStorageService.get('nextUrl'))) {
                        siteIdResult = siteIdRegex.exec(localStorageService.get('nextUrl'));
                        if (siteIdResult !== null) {
                            path = localStorageService.get('nextUrl');
                        }
                    }
                } else if (type === constantCodes.EXIT) {
                    if (!_.isUndefined(localStorageService.get('currentUrl')) && !_.isNull(localStorageService.get('currentUrl'))) {
                        siteIdResult = siteIdRegex.exec(localStorageService.get('currentUrl'));
                        if (siteIdResult !== null) {
                            path = localStorageService.get('currentUrl');
                        }
                    }
                }
                return path;
            };

            $scope.exit = function () {
                var path = $scope.getRedirectPath(constantCodes.EXIT);
                $window.location.href = path;
            };

            $scope.attemptLogin = function (smsCode) {
                var formScope = this;
                initializeVariablesState();
                IPLockService.attemptLogin(smsCode).then(function (result) {
                    formScope.smsForm.$setPristine();
                    if (!result) {
                        $scope.authFailed = true;
                    } else {
                        if (!result.succeeded) {
                            if (result.reason === constantCodes.smsCodeExpired) {
                                $scope.isCodeExpired = true;
                            } else if (result.reason === constantCodes.smsCodeInvalid) {
                                $scope.authFailed = true;
                            } else if (result.reason === constantCodes.userLocked) {
                                $scope.userInfo.userIsLocked = true;
                            } else if (result.reason === constantCodes.userBlocked) {
                                $scope.userInfo.userIsBlocked = true;
                            }
                        } else {
                            var path = $scope.getRedirectPath(constantCodes.NEXT);
                            $window.location.href = path;
                        }
                    }
                });
            };

            $scope.attemptLoginGoogleAuth = function (googleAuthCode) {
                var formScope = this;
                IPLockService.attemptLoginGoogleAuth(googleAuthCode).then(function (result) {
                    formScope.googleAuthForm.$setPristine();
                    if (!result.succeeded) {
                        if (result.reason === constantCodes.userLocked) {
                            $scope.userInfo.userIsLocked = true;
                        } else if (result.reason === constantCodes.userBlocked) {
                            $scope.userInfo.userIsLocked = true;
                        } else if (result.reason === constantCodes.googleAuthCodeInvalid) {
                            $scope.authFailed = true;
                        }
                    } else {
                        var path = $scope.getRedirectPath(constantCodes.NEXT);
                        $window.location.href = path;
                    }
                });
            };
            $scope.authWithGoogle = function () {
                $scope.showSmsForm = false;
                $scope.showGoogleAuthForm = true;
                $scope.authFailed = false;
            };
            $scope.authWithSMS = function () {
                $scope.sendSmsAuthCode().then(function onSuccess() {
                    $scope.showSmsForm = true;
                    $scope.showGoogleAuthForm = false;
                    $scope.authFailed = false;
                });
            };
        }]);