import React from 'react';
import cx from 'classnames';
import Icon from '~/components/src/Icon';
import { IconBaseProps } from 'react-icon-base';
import './styles.scss';

interface SpinnerProps {
  isActive?: boolean;
  testHook?: string;
  className?: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconSize?: number;
}

const Spinner = ({ isActive = true, testHook = 'spinner', className, icon, iconSize = 96 }: SpinnerProps) => {
  if (isActive) {
    return icon ? (
      <Icon icon={icon} size={iconSize} className={cx('Spinner-icon', className, `t-${testHook}`)} />
    ) : (
      <div className={cx('Spinner', className, `t-${testHook}`)}>&nbsp;</div>
    );
  }

  return null;
};

export default Spinner;
