/* eslint-disable no-prototype-builtins */
angular.module('webUi.directive.modal', [])

    .directive('modalOpen', function() {
        return function(scope, element, attrs) {
            var closeModal = function() {
                var backdrop = $('#modal-backdrop');
                var modal = $('#' + attrs.modalOpen);
                var body = $('body');

                // backdrop could be null but shouldn't ever be undefined.
                if (typeof backdrop !== 'undefined' && backdrop !== null) {
                    backdrop.unbind();
                    backdrop.remove();
                }

                $(body).unbind('keypress');
                modal.hide();

                body.removeClass('modal-open');
            };

            function open() {
                var modal = $('#' + attrs.modalOpen);
                var body = $('body');

                // add backdrop div even if there won't be a backdrop. probably not neccesary
                body.append('<div id="modal-backdrop"></div>');
                var backdropAttr = attrs.hasOwnProperty('backdrop') ? attrs.backdrop : true;
                var escapeAttr = attrs.hasOwnProperty('escapeExit') ? attrs.escapeExit : true;
                var backdrop = $('#modal-backdrop');

                // typechecking boolean values but not string. not sure why.
                if (backdropAttr === true || backdropAttr === 'static') {
                    backdrop.addClass('modal-backdrop');

                    if (backdropAttr !== 'static') {
                        // calling the callback within the bind breaks the backdrop (weird)
                        $(backdrop).bind('click', function() {
                            closeModal();
                        });
                    }
                }

                if (escapeAttr === true) {
                    $(body).bind('keypress', function(e) {
                        if (e.keyCode === 27) {
                            closeModal();
                        }
                    });
                }

                body.addClass('modal-open');
                modal.show();
            }

            $(element).bind('click', open);

            if ( attrs.open ){
                open();
            }
        };
    })

/*
   this is somewhat repetitive since we already have a closeModal() in the original
   directive. However, the only alternative is traversing the DOM looking for it. If
   angular implements a $().trigger() function, that might be the way to go
*/
    .directive('modalClose', function() {
        return function(scope, element, attrs) {

            $(element).bind('click', function() {

                var backdrop = $('#modal-backdrop');
                var modal = $('#' + attrs.modalClose);
                var body = $('body');

                // backdrop could be null but shouldn't ever be undefined.
                if (typeof backdrop !== 'undefined' && backdrop !== null) {
                    backdrop.unbind();
                    backdrop.remove();
                }

                $(body).unbind('keypress');
                modal.hide();

                body.removeClass('modal-open');

            });
        };
    })


    .directive('modalSave', function () {
        return {
            restrict: 'A',
            scope : {
                modalSave : '&'
            },
            require: '?ModalSaveController',
            controller: ['$scope', '$element', function ModalSaveController ($scope,   $element) {
                $($element).bind('click', function() {
                    $scope.modalSave();
                });
            }]
        };
    });
