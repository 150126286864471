import React from 'react';
import { toast, ToastId } from 'react-toastify';
import Toast, { TOAST_TYPES, ToastShake, ToastType, NotificationType } from './toast';

interface IShowToastOptions {
  header?: string;
  body?: string;
  [x: string]: string | undefined;
}

const showToast = (
  { header, body, ...options }: IShowToastOptions,
  toastType: ToastType,
  notificationType: NotificationType,
) =>
  toast(<Toast header={header} body={body} kind={notificationType} />, {
    type: toastType,
    ...options,
  });

export const showSuccess = (props: IShowToastOptions) => showToast(props, toast.TYPE.SUCCESS, TOAST_TYPES.SUCCESS);

export const showWarning = (props: IShowToastOptions) => showToast(props, toast.TYPE.WARNING, TOAST_TYPES.WARNING);

export const showInfo = (props: IShowToastOptions) => showToast(props, toast.TYPE.INFO, TOAST_TYPES.INFO);

interface IShowError {
  header?: string;
  body: string;
  [x: string]: string | undefined;
}

interface IToast {
  id: ToastId;
  count: number;
}

export const showError = (() => {
  const activeToastsByBody = {} as Record<string, IToast>;

  return ({ header, body, ...options }: IShowError) => {
    const existingToast = activeToastsByBody[body];

    if (existingToast && toast.isActive(existingToast.id)) {
      existingToast.count += 1;

      toast.update(existingToast.id, {
        render: () => (
          <ToastShake count={existingToast.count}>
            {shakeAnimationClassName => (
              <Toast
                className={shakeAnimationClassName}
                header={header}
                body={body}
                kind={TOAST_TYPES.ERROR}
                closeToast={() => {
                  delete activeToastsByBody[existingToast.id];
                  toast.dismiss(existingToast.id);
                }}
              />
            )}
          </ToastShake>
        ),
      });
      return;
    }

    const toastId = toast(<Toast header={header} body={body} kind={TOAST_TYPES.ERROR} />, {
      type: toast.TYPE.ERROR,
      autoClose: false,
      ...options,
    });

    activeToastsByBody[body] = {
      id: toastId,
      count: 1,
    };
  };
})();

export const hideNotification = (toastId: ToastId) => toast.dismiss(toastId);
