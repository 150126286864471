/**
 * @description Angular main module for Content -> campaigns2 -> Base (left)
 * @memberOf webUi.ui.content.campaigns2
 * @namespace webUi.ui.content.campaigns2.base
 */
angular.module('webUi.ui.content.campaigns2.base',
    ['webUi.ui.content.base']
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns2', {
                url: '/campaigns2',
                isReact: true,
            });
    }]);