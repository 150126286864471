import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import Toast from '~/notificationCenter/toast';
import Heading from '~/components/src/Heading';
import Btn from '~/components/src/Btn';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import { buildUrl } from '~/common/history';
import { showError, showSuccess } from '~/notificationCenter';
import Breadcrumb from '~/components/src/Breadcrumb';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import DeleteModal from '~/components/src/ModalWindow/DeleteModal';
import Notification from '~/components/src/Notification';
import MediaLibraryContentTable from './MediaLibraryContentTable';
import LinkedAdsTable from './LinkedAdsTable';
import api from './dataService';
import { getMediaLibraryOrFolderUrl, makeUniqueNameValidator } from './utils';
import {
  MediaLibrariesSortableTreeNode,
  LinkedAd,
  MediaLibraryContent as MediaLibraryContentType,
  MediaLibrariesTreeNode,
} from './types';

interface MediaLibraryHeaderProps {
  getTreeData: (
    mediaLibrariesTreeNode: MediaLibrariesTreeNode[],
    folderPath?: string[],
  ) => MediaLibrariesSortableTreeNode[];
  existingMediaLibraryNames: string[];
  fetchMediaLibrariesTree: () => Promise<MediaLibrariesTreeNode[]>;
  hasAdservingEdit: boolean;
  hasLinkedAds: boolean;
  isEditNameFormVisible: boolean;
  mediaLibraryContent: MediaLibraryContentType;
  loadedFolderPath: string[];
  refreshMediaLibraryContent: () => void;
  setDeleteModalMediaLibraryId: (mediaLibraryId: string) => void;
  setIsEditNameFormVisible: (isVisible: boolean) => void;
  setTree: (tree: MediaLibrariesSortableTreeNode[]) => void;
}

const MediaLibraryHeader = ({
  existingMediaLibraryNames,
  fetchMediaLibrariesTree,
  getTreeData,
  hasAdservingEdit,
  hasLinkedAds,
  isEditNameFormVisible,
  loadedFolderPath,
  mediaLibraryContent,
  refreshMediaLibraryContent,
  setDeleteModalMediaLibraryId,
  setIsEditNameFormVisible,
  setTree,
}: MediaLibraryHeaderProps) => {
  const crumbs = loadedFolderPath.length
    ? [mediaLibraryContent.name, ...loadedFolderPath].map((itemName, index) => ({
        title: itemName,
        pathname: getMediaLibraryOrFolderUrl(
          mediaLibraryContent.mediaLibraryId,
          [mediaLibraryContent.name, ...loadedFolderPath].slice(0, index + 1),
        ),
      }))
    : [];

  const renameMediaLibrary = async (name: string) => {
    setIsEditNameFormVisible(false);

    if (name !== mediaLibraryContent.name) {
      await api.updateMediaLibraryName(mediaLibraryContent.mediaLibraryId, name);
      showSuccess({ header: i18n.t('mediaLibraries:mlNameSuccessfullyUpdated') });
      refreshMediaLibraryContent();
      fetchMediaLibrariesTree().then(getTreeData).then(setTree);
    }
  };

  if (isEditNameFormVisible) {
    return (
      <Formik
        initialValues={{ name: mediaLibraryContent.name }}
        onSubmit={values => {
          renameMediaLibrary(values.name);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .required(i18n.t('validation:validation.required'))
            .test(
              'media-library-name-is-unique',
              i18n.t('mediaLibraries:mlNameMustBeUnique'),
              makeUniqueNameValidator(existingMediaLibraryNames, mediaLibraryContent.name),
            ),
        })}
      >
        {({ handleSubmit, errors, isValid }) => (
          <Form className="flex items-start gap-2">
            <Field
              as={FormikInputField}
              autoFocus
              className="w-96"
              errorText={errors.name}
              name="name"
              placeholder={i18n.t('mediaLibraries:newMlName')}
              testHook="renameMlInput"
            />
            <BtnIcon
              className="mt-2"
              color="gray"
              icon="close"
              onClick={() => {
                setIsEditNameFormVisible(false);
              }}
              testHook="renameMlCancelButton"
              tooltip={i18n.t('common:actions.cancel')}
            />
            <BtnIcon
              className="mt-2"
              color="blue"
              disabled={!isValid}
              icon="done"
              onClick={() => {
                handleSubmit();
              }}
              testHook="renameMlSubmitButton"
              tooltip={i18n.t('common:actions.save')}
            />
          </Form>
        )}
      </Formik>
    );
  }

  let deleteButtonTooltip = i18n.t('mediaLibraries:deleteMl');
  if (!hasAdservingEdit) {
    deleteButtonTooltip = i18n.t('mediaLibraries:deleteMlNoPermissions');
  } else if (hasLinkedAds) {
    deleteButtonTooltip = i18n.t('mediaLibraries:deleteMlBlocked');
  }

  return (
    <>
      <div className="flex items-start gap-2">
        <Heading className="-mt-2 inline-block pb-0 pt-0" testHook="mlName-heading" title={mediaLibraryContent.name} />
        <div className="mt-1 flex gap-2">
          <BtnIcon
            className="bg-transparent text-gray-400 hover:text-gray-600"
            disabled={!hasAdservingEdit}
            icon="edit"
            onClick={() => {
              setIsEditNameFormVisible(true);
            }}
            testHook="renameMlButton"
            tooltip={
              hasAdservingEdit ? i18n.t('mediaLibraries:editMlName') : i18n.t('mediaLibraries:editMlNameNoPermissions')
            }
          />
          <BtnIcon
            className="bg-transparent text-gray-400 hover:text-gray-600"
            disabled={hasLinkedAds || !hasAdservingEdit}
            icon="delete"
            onClick={() => {
              setDeleteModalMediaLibraryId(mediaLibraryContent.mediaLibraryId);
            }}
            testHook="deleteMlButton"
            tooltip={deleteButtonTooltip}
          />
        </div>
      </div>
      <Breadcrumb crumbs={crumbs} />
    </>
  );
};

interface UploadMediaFileButtonProps {
  hasAdservingEdit: boolean;
  loadedFolderPath: string[];
  mediaLibraryId: string;
  refreshMediaLibraryContent: () => void;
}

const UploadMediaFileButton = ({
  hasAdservingEdit,
  loadedFolderPath,
  mediaLibraryId,
  refreshMediaLibraryContent,
}: UploadMediaFileButtonProps) => {
  const hiddenUploadInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Btn
        color="blue"
        disabled={!hasAdservingEdit}
        onClick={() => {
          if (hiddenUploadInputRef.current) {
            hiddenUploadInputRef.current.click();
          }
        }}
        testHook="uploadFileButton"
        tooltip={!hasAdservingEdit && i18n.t('mediaLibraries:uploadFileNoPermissions')}
      >
        {i18n.t('mediaLibraries:uploadFile')}
      </Btn>
      <input
        className="hidden"
        id="uploadInput"
        onChange={async event => {
          const { files } = event.target;
          if (!files) return;

          const toastIds = Array.from(files).map(file =>
            toast(
              <Toast header={i18n.t('mediaLibraries:uploadingFile', { fileName: file.name })} kind="information" />,
              {
                progress: 0,
                progressClassName: 'NotificationCenter-controlledProgressBar',
                type: toast.TYPE.INFO,
              },
            ),
          );

          try {
            await Promise.all(
              Array.from(files).map((file, index) =>
                api.uploadFile(mediaLibraryId, loadedFolderPath, file, progress => {
                  toast.update(toastIds[index], { progress });
                }),
              ),
            );

            toastIds.forEach(id =>
              toast.update(id, {
                render: <Toast header={i18n.t('mediaLibraries:uploadSuccessful')} kind="success" />,
                type: toast.TYPE.SUCCESS,
                progressClassName: 'NotificationCenter-progressBar',
                progress: undefined,
              }),
            );
          } catch (error) {
            toastIds.forEach(id =>
              toast.update(id, {
                progress: 0,
                render: (
                  <Toast
                    header={i18n.t('mediaLibraries:uploadFailed')}
                    body={i18n.t('mediaLibraries:uploadFailedTryAgain')}
                    kind="error"
                  />
                ),
                type: toast.TYPE.ERROR,
              }),
            );
          }

          refreshMediaLibraryContent();
        }}
        ref={hiddenUploadInputRef}
        type="file"
        multiple
      />
    </>
  );
};

interface MediaLibraryContentProps {
  existingMediaLibraryNames: string[];
  fetchMediaLibrariesTree: () => Promise<MediaLibrariesTreeNode[]>;
  folderPath: string[];
  getTreeData: (
    mediaLibrariesTreeNode: MediaLibrariesTreeNode[],
    folderPath?: string[],
  ) => MediaLibrariesSortableTreeNode[];
  hasAdservingEdit: boolean;
  mediaLibraryId: string;
  setTree: (tree: MediaLibrariesSortableTreeNode[]) => void;
}

const MediaLibraryContent = ({
  existingMediaLibraryNames,
  fetchMediaLibrariesTree,
  folderPath,
  getTreeData,
  hasAdservingEdit,
  mediaLibraryId,
  setTree,
}: MediaLibraryContentProps): React.ReactElement | null => {
  const navigate = useNavigate();

  const [isEditNameFormVisible, setIsEditNameFormVisible] = useState(false);

  const [isNewFolderFormVisible, setIsNewFolderFormVisible] = useState(false);

  const [deleteModalMediaLibraryId, setDeleteModalMediaLibraryId] = useState('');

  const timeoutIdRef = useRef(0);
  const [isLongLoading, setIsLongLoading] = useState(false);

  const [state, setState] = useState<{
    mediaLibraryContent: MediaLibraryContentType | null | undefined;
    loadedFolderPath: string[];
    linkedAds: LinkedAd[];
  }>({
    mediaLibraryContent: null,
    loadedFolderPath: [],
    linkedAds: [],
  });

  const refreshMediaLibraryContent = () => {
    setIsLongLoading(false);
    timeoutIdRef.current = setTimeout(() => {
      // Will cause a spinner to be shown if content doesn't load within 500ms
      setIsLongLoading(true);
    }, 500) as unknown as number;

    Promise.all([api.fetchMediaLibraryContent(mediaLibraryId, folderPath), api.fetchLinkedAds(mediaLibraryId)]).then(
      ([mediaLibraryContent, linkedAds]) => {
        setState({
          loadedFolderPath: folderPath,
          mediaLibraryContent,
          linkedAds,
        });
        clearTimeout(timeoutIdRef.current);
        setIsLongLoading(false);
      },
    );
  };

  useEffect(() => {
    refreshMediaLibraryContent();
    // Hide new folder folder if user switches to another ML or folder
    setIsNewFolderFormVisible(false);
    setIsEditNameFormVisible(false);
  }, [mediaLibraryId, folderPath]);

  const { mediaLibraryContent, loadedFolderPath, linkedAds } = state;

  if (mediaLibraryContent === null) {
    // Initial content load
    return null;
  }

  if (mediaLibraryContent === undefined) {
    return (
      <div className="mt-12 ml-8 mr-8">
        <Notification kind="information">{i18n.t('mediaLibraries:noMlWithThisIdExist')}</Notification>
      </div>
    );
  }

  const deleteMediaLibrary = async () => {
    await api.deleteMediaLibrary(mediaLibraryId);
    const tree = await fetchMediaLibrariesTree().then(getTreeData);
    setTree(tree);

    const mediaLibraryIdToRedirectTo = tree[0]?.mediaLibraryId;
    navigate(
      buildUrl(
        mediaLibraryIdToRedirectTo
          ? `content/medialibraries/view/${mediaLibraryIdToRedirectTo}`
          : 'content/medialibraries/dashboard',
      ),
    );

    showSuccess({ header: i18n.t('mediaLibraries:mlSuccessfullyDeleted', { mlName: mediaLibraryContent.name }) });
  };

  const createFolder = async (name: string) => {
    setIsNewFolderFormVisible(false);

    try {
      await api.createFolder(mediaLibraryId, name, loadedFolderPath);
      showSuccess({
        header: i18n.t('mediaLibraries:folderCreated'),
        body: i18n.t('mediaLibraries:youCanNowSeeFolder', { name }),
      });
    } catch {
      showError({
        header: i18n.t('mediaLibraries:folderCreationFailed'),
        body: i18n.t('common:tryAgainLater'),
      });
    }

    refreshMediaLibraryContent();
    return fetchMediaLibrariesTree().then(getTreeData).then(setTree);
  };

  const deleteFileOrFolder = async (fullPath: string, isFolder: boolean) => {
    await api.deleteFileOrFolder(mediaLibraryId, fullPath);
    showSuccess({ header: isFolder ? i18n.t('mediaLibraries:folderDeleted') : i18n.t('mediaLibraries:fileDeleted') });
    refreshMediaLibraryContent();
    return fetchMediaLibrariesTree().then(getTreeData).then(setTree);
  };

  return (
    <>
      <div className="ml-8 mr-5">
        <div className="mt-8 flex h-24">
          <div className="flex-1">
            <MediaLibraryHeader
              existingMediaLibraryNames={existingMediaLibraryNames}
              fetchMediaLibrariesTree={fetchMediaLibrariesTree}
              getTreeData={getTreeData}
              hasAdservingEdit={hasAdservingEdit}
              hasLinkedAds={linkedAds.length > 0}
              isEditNameFormVisible={isEditNameFormVisible}
              loadedFolderPath={loadedFolderPath}
              mediaLibraryContent={mediaLibraryContent}
              refreshMediaLibraryContent={refreshMediaLibraryContent}
              setDeleteModalMediaLibraryId={setDeleteModalMediaLibraryId}
              setIsEditNameFormVisible={setIsEditNameFormVisible}
              setTree={setTree}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end gap-4">
              <BtnIcon
                disabled={!hasAdservingEdit}
                icon="addFolder"
                onClick={() => {
                  setIsNewFolderFormVisible(true);
                }}
                testHook="createNewFolderButton"
                tooltip={
                  hasAdservingEdit
                    ? i18n.t('mediaLibraries:createFolder')
                    : i18n.t('mediaLibraries:createFolderNoPermissions')
                }
              />
              <UploadMediaFileButton
                hasAdservingEdit={hasAdservingEdit}
                loadedFolderPath={loadedFolderPath}
                mediaLibraryId={mediaLibraryId}
                refreshMediaLibraryContent={refreshMediaLibraryContent}
              />
            </div>
            <div className="t-mlNumber mt-4 text-slate-500">
              {i18n.t('mediaLibraries:mlNumber', { mlNumber: mediaLibraryContent.mediaLibraryNumber })}
            </div>
          </div>
        </div>
        <Heading kind="h2" title={i18n.t('mediaLibraries:mediaFiles')} />
        {isLongLoading ? (
          <Spin />
        ) : (
          <MediaLibraryContentTable
            createFolder={createFolder}
            deleteFileOrFolder={deleteFileOrFolder}
            hasAdservingEdit={hasAdservingEdit}
            isFolder={loadedFolderPath.length > 0}
            isNewFolderFormVisible={isNewFolderFormVisible}
            mediaFiles={mediaLibraryContent.mediaFiles}
            mediaLibraryId={mediaLibraryContent.mediaLibraryId}
            mediaLibraryName={mediaLibraryContent.name}
            setIsNewFolderFormVisible={setIsNewFolderFormVisible}
          />
        )}
        <div className="mt-12 mb-24">
          <Heading kind="h2" title={i18n.t('mediaLibraries:linkedAds')} />
          {isLongLoading ? <Spin /> : <LinkedAdsTable linkedAds={state.linkedAds} />}
        </div>
      </div>
      {deleteModalMediaLibraryId && (
        <DeleteModal
          closeModal={() => {
            setDeleteModalMediaLibraryId('');
          }}
          onDelete={async () => {
            setDeleteModalMediaLibraryId('');
            await deleteMediaLibrary();
          }}
          title={i18n.t('mediaLibraries:deleteModalHeader', { name: mediaLibraryContent.name })}
        >
          {i18n.t('mediaLibraries:deleteAreYouSure', { name: mediaLibraryContent.name })}
        </DeleteModal>
      )}
    </>
  );
};

export default MediaLibraryContent;
