/**
 * @description Angular main module for Profiles -> Transformations -> Base (left)
 * @memberOf webUi.ui.profiles.transformations
 * @namespace webUi.ui.profiles.transformations.base
 */
angular.module('webUi.ui.profiles.transformations.base',
    [
        'webUi.ui.profiles.base'
    ]
)
    /**
    *
    */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.transformations', {
                url: '/transformations',
                isReact: true,
            });
    }]);