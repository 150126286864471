import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  parseCounterStats,
  parseTaggingErrors,
  parseTaggingCalls,
  parseNewPaths,
  parseTagErrors,
  generateRangeQueryParams,
} from './utils';
import { CounterStatsData, TaggingCallsData, TaggingErrorsData, NewPathsData } from './types';

const BASE_PATH_TOTAL_USAGE = `${API_BASE}/totalUsage`;
const BASE_PATH_TAG_USAGE = `${API_BASE}/tagUsage`;
const BASE_PATH_NEW_PATHS = `${API_BASE}/newPaths`;
const BASE_PATH_TAG = `${API_BASE}/tagmanagement`;

export const getCounterStats = (startDate: number, endDate: number): Promise<CounterStatsData> =>
  Api.callGet(`${BASE_PATH_TAG}/tagCounterStatsAll?${generateRangeQueryParams(startDate, endDate)}`)
    .then(parseResponse)
    .then(parseCounterStats);

export const getTaggingCalls = (startDate: number, endDate: number): Promise<TaggingCallsData> =>
  Api.callGet(`${BASE_PATH_TOTAL_USAGE}/list?${generateRangeQueryParams(startDate, endDate)}`)
    .then(parseResponse)
    .then(parseTaggingCalls);

export const getTaggingErrors = (startDate: number, endDate: number): Promise<TaggingErrorsData[]> =>
  Api.callGet(`${BASE_PATH_TAG_USAGE}/errors?${generateRangeQueryParams(startDate, endDate)}`)
    .then(parseResponse)
    .then(parseTaggingErrors);

export const getNewPaths = (startDate: number, endDate: number): Promise<NewPathsData> =>
  Api.callGet(`${BASE_PATH_NEW_PATHS}/list?${generateRangeQueryParams(startDate, endDate)}`)
    .then(parseResponse)
    .then(parseNewPaths);

export const getTagErrors = (startDate: number, endDate: number): Promise<ReturnType<typeof parseTagErrors>> =>
  Api.callGet(`${BASE_PATH_TOTAL_USAGE}/list?${generateRangeQueryParams(startDate, endDate)}`)
    .then(parseResponse)
    .then(parseTagErrors);
