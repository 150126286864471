import { get } from 'lodash';

/**
 * @param state
 * @returns {*}
 */
export const getClientSites = state => get(state, 'context.clients.siteList.list');

/**
 * @param state
 * @returns {*}
 */
export const isFetchingClientSites = state => get(state, 'context.clients.siteList.ui.isFetching');

/**
 * State flag
 * @param state
 * @returns {*}
 */
export const shouldFetchClientSites = state => get(state, 'context.clients.siteList.ui.shouldFetchAll');
