import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Btn from '~/components/src/Btn';
import i18n from '~/i18n';
import { PAGE_SIZE } from '../constants';
import { ButtonProps, DeploymentProgressProps, LineProps } from '../types';

const UpperLine = ({ row, env, page }: LineProps) => (
  <div
    className={twMerge(
      cx('absolute -top-6 h-6 w-2 flex-1 border-x border-black bg-white', {
        'bg-blue-400 ': env.snapshotIndex !== -1 && env.snapshotIndex < row.index + PAGE_SIZE * page,
      }),
    )}
  >
    &nbsp;
  </div>
);

const DeployButton = ({ row, env, page, disabled, testHook, setShowDeployModal }: ButtonProps) => {
  const testHookCls = `t-${testHook?.replaceAll(' ', '-')}-button`;
  return (
    <Btn
      className={twMerge(
        cx(`${testHookCls} h-6 w-6 rounded-full border border-black !bg-white !p-0 hover:bg-white`, {
          '!bg-blue-400 hover:!bg-blue-300':
            env.snapshotIndex !== -1 && env.snapshotIndex <= row.index + PAGE_SIZE * page,
        }),
      )}
      tooltip={i18n.t('publishing:history.actions.deployToolTip', { env: env.environmentName })}
      onClick={() => setShowDeployModal({ row: row.original, env })}
      disabled={disabled}
    ></Btn>
  );
};

const BottomLine = ({ row, env, page, lastRow }: LineProps) => (
  <div
    className={twMerge(
      cx('absolute top-5 w-2 border-x border-black bg-white', {
        'bg-blue-400': env.snapshotIndex !== -1 && env.snapshotIndex <= row.index + PAGE_SIZE * page,
        '!h-4': lastRow,
      }),
    )}
    // Tailwind doesn't support dynamic css styling
    style={{ height: row.getIsExpanded() ? `${(row.original?.diff?.length || 1) * 50 + 80}px` : '3rem' }}
  >
    &nbsp;
  </div>
);

export const DeploymentProgress = ({
  row,
  env,
  page,
  disabled,
  lastRow = false,
  setShowDeployModal,
}: DeploymentProgressProps) => (
  <div className="relative -mx-4 -my-2 flex h-auto justify-center">
    {(row.index > 0 || page > 0) && <UpperLine row={row} env={env} page={page} />}
    <DeployButton
      testHook={env.environmentName}
      row={row}
      env={env}
      page={page}
      disabled={disabled}
      setShowDeployModal={setShowDeployModal}
    />
    <BottomLine row={row} env={env} page={page} lastRow={lastRow} />
  </div>
);
