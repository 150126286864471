import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { reduxForm, isValid } from 'redux-form';
import { withRouter } from '~/common/withRouter';
import NewExportComponent from './NewExportComponent';
import {
  getExportTypes,
  getFormValues,
  getRequestTypes,
  getEngagementTypes,
  isFetchingExportTypes,
  isFetchingRequestTypes,
  isFetchingEngagementTypes,
  isRemoteConnectionSuccessful,
  areConnectionCredentialsChecked,
  getEditExportData,
  getInitialValues,
  isFetchingEditExport,
} from './newExportSelector';
import {
  fetchExportTypes,
  fetchRequestTypes,
  fetchEngagementTypes,
  createNewRequest,
  check,
  fetchEditExportData,
  setEditExportData,
  setIsRemoteConnectionSuccessful,
  setAreConnectionCredentialsChecked,
} from './newExportActions';

const mapStateToProps = (state, ownProps) => ({
  isFetchingRequestTypes: isFetchingRequestTypes(state),
  isFetchingExportTypes: isFetchingExportTypes(state),
  isFetchingEngagementTypes: isFetchingEngagementTypes(state),
  isRemoteConnectionSuccessful: isRemoteConnectionSuccessful(state),
  areConnectionCredentialsChecked: areConnectionCredentialsChecked(state),
  isFetchingEditExport: isFetchingEditExport(state),
  requestTypes: getRequestTypes(state),
  exportTypes: getExportTypes(state),
  engagementTypes: getEngagementTypes(state),
  formValues: getFormValues(state),
  exportId: ownProps.params ? ownProps.params.id : null,
  editExportData: getEditExportData(state),
  initialValues: !_.isEmpty(getEditExportData(state)) && getInitialValues(state),
  isValid: isValid('exportNew')(state),
});

const mapDispatchToProps = {
  fetchRequestTypes,
  fetchExportTypes,
  fetchEngagementTypes,
  onSubmit: createNewRequest,
  check,
  fetchEditExportData,
  setEditExportData,
  setIsRemoteConnectionSuccessful,
  setAreConnectionCredentialsChecked,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'exportNew',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate('exports'),
)(NewExportComponent);
