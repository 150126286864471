import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { FilterRestResponseType, FilterListType, RuleDefinition, FilterPayload, FilterWithRules } from './types';
import { mapFilterToEvent } from './util';

const API_FILTERS = `${API_BASE}/v1/filters`;
const API_PROFILES = `${API_BASE}/v1/eventConnectors`;

export const getAllFilters = (): Promise<FilterListType[]> => Api.callGet(API_FILTERS).then(response => response.data);

export const getLinkedFilters = (eventConnectorId: string): Promise<FilterWithRules[]> =>
  Api.callGet(`${API_PROFILES}/${eventConnectorId}/filters`).then(parseResponse);

export const getRulesDefinitions = (): Promise<RuleDefinition[]> =>
  Api.callGet(`${API_PROFILES}/ruleDefinitions`).then(parseResponse);

export const getFilter = (filterId: string): Promise<FilterWithRules> =>
  Api.callGet(`${API_FILTERS}/${filterId}`).then(parseResponse).then(mapFilterToEvent);

export const createFilter = (payload: FilterPayload): Promise<FilterRestResponseType> =>
  Api.callPost(`${API_FILTERS}`, payload).then(parseResponse);

export const updateFilter = (filterId: string, payload: FilterPayload): Promise<FilterRestResponseType> =>
  Api.callPut(`${API_FILTERS}/${filterId}`, payload).then(parseResponse);

export const deleteFilter = (filterId: string): Promise<FilterRestResponseType> =>
  Api.callDelete(`${API_FILTERS}/${filterId}`).then(parseResponse);
