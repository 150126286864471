import { tradeDeskActionTypes } from './tradeDeskActions';

const tradeDeskReducer = (state, action) => {
  switch (action.name) {
    case tradeDeskActionTypes.SET_ENDPOINT_LOCATIONS:
      return state.set('endpointLocations', action.data);
    case tradeDeskActionTypes.SET_PAYLOAD_TYPES:
      return state.set('payloadTypes', action.data);

    default:
      return state;
  }
};

export default tradeDeskReducer;
