angular.module('webUi.directive.moDateInput', [])

    .directive('moDateInput', ['$window', function ($window) {
        return {
            require:'^ngModel',
            restrict:'A',
            link: function (scope, elm, attrs, ctrl) {
                var moment = $window.moment;
                var dateFormat = attrs.moMediumDate;

                attrs.$observe('moDateInput', function (newValue) {
                    if (dateFormat === newValue) {
                        return;
                    }
                    dateFormat = newValue;
                    ctrl.$modelValue = new Date(ctrl.$setViewValue);
                });

                ctrl.$formatters.unshift(function (modelValue) {
                    if (!dateFormat || !modelValue) {
                        return '';
                    }
                    var retVal = moment(modelValue).format(dateFormat);
                    return retVal;
                });

                ctrl.$parsers.unshift(function (viewValue) {
                    var date = moment(viewValue, dateFormat);
                    return (date && date.isValid() && date.year() > 1950 ) ? date.toDate() : '';
                });
            }
        };
    }]);
