import Api, { API_BASE } from '~/common/ApiService';
import { prepareImageFormData } from '~/common/formData';
import { getCsrfToken, getSiteId } from '~/common/SecurityMetaService';
import { MediaLibraryContent, MediaLibrariesTreeNode, LinkedAd } from './types';

const fetchMediaLibrariesTree = (): Promise<MediaLibrariesTreeNode[]> =>
  Api.callGet(`${API_BASE}/content/medialibraries/tree`).then(response => response.data);

const createMediaLibrary = (name: string): Promise<string> =>
  Api.callPost(`${API_BASE}/content/medialibraries`, { name }).then(response => response.data);

const fetchMediaLibraryContent = (
  mediaLibraryId: string,
  folderPath: string[],
): Promise<MediaLibraryContent> | undefined => {
  let url = `${API_BASE}/content/medialibraries/${mediaLibraryId}`;

  if (folderPath.length) {
    url += `/list?path=${folderPath.join('/')}`;
  }

  // response.data is undefined in cases when there's no media library with this ID
  return Api.callGet(url).then(response => response.data);
};

const updateMediaLibraryName = (mediaLibraryId: string, name: string): Promise<void> =>
  Api.callPost(`${API_BASE}/content/medialibraries/updateMediaLibraryName`, { mediaLibraryId, name }).then(
    response => response.data,
  );

const deleteMediaLibrary = (mediaLibraryId: string): Promise<void> =>
  Api.callDelete(`${API_BASE}/content/medialibraries/${mediaLibraryId}`) as unknown as Promise<void>;

const createFolder = (mediaLibraryId: string, folderName: string, folderPath: string[]): Promise<void> =>
  Api.callPost(
    `${API_BASE}/content/medialibraries/${mediaLibraryId}/create`,
    {
      folderName,
      path: folderPath.join('/'),
    },
    { shouldShowToast: false },
  ) as unknown as Promise<void>;

const deleteFileOrFolder = (mediaLibraryId: string, fullPath: string): Promise<void> =>
  Api.callDelete(
    `${API_BASE}/content/medialibraries/${mediaLibraryId}/deleteFile?file=${fullPath}`,
  ) as unknown as Promise<void>;

const uploadFile = (
  mediaLibraryId: string,
  folderPath: string[],
  file: File,
  onProgressUpdate: (progress: number) => void,
): Promise<void> => {
  let url = `${API_BASE}/content/medialibraries/${mediaLibraryId}?path=`;

  if (folderPath.length) {
    url += folderPath.join('/');
  }

  const payload = prepareImageFormData(file);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.setRequestHeader('X-CSRF-TOKEN', getCsrfToken());
    xhr.setRequestHeader('X-siteId', getSiteId()?.toString());

    xhr.upload.addEventListener('progress', event => {
      onProgressUpdate(event.loaded / event.total);
    });

    xhr.addEventListener('load', () => {
      resolve(xhr.response);
    });

    xhr.addEventListener('error', event => {
      reject(event);
    });

    xhr.send(payload);
  });
};

const fetchLinkedAds = (mediaLibraryId: string): Promise<LinkedAd[]> =>
  Api.callGet(`${API_BASE}/content/ads/medialibrary/${mediaLibraryId}`).then(response => response.data);

export default {
  fetchMediaLibrariesTree,
  createMediaLibrary,
  fetchMediaLibraryContent,
  updateMediaLibraryName,
  deleteFileOrFolder,
  createFolder,
  fetchLinkedAds,
  deleteMediaLibrary,
  uploadFile,
};
