angular.module('webUi.service.profiles.segments.stats', [
    'restangular',
    'webUi.common.Utils'
])
    .factory('SegmentStatsService', ['Restangular', '$q', 'Utils', 'ChartService', 'CsvService',
        function SegmentStatsService(Restangular, $q, Utils, ChartService, CsvService) {

            // The base path through which all calls should go
            var BASE_PATH = 'profiles/segments/';

            /**
     *
     * @param {UUID} segmentId - the id of the segment to retrieve stats for
     * @param {Integer} days - the number of days for which stats should be retrieved
     * @returns a promise of the segment call stats for the last x days
     */


            var resolvePromiseStats = function (promiseSegmentStats) {
                return promiseSegmentStats.then(function (data) {
                    if (data) {
                        return Restangular.stripRestangular(data);
                    }
                    return undefined;
                });
            };

            var getTotalSegmentsWithStats = function () {
                return resolvePromiseStats(Restangular.all(BASE_PATH + 'extendedStats').getList());
            };

            var exportToCsv = function exportToCsv () {
                getTotalSegmentsWithStats().then( (totalSegments) => {
                    var data = [];

                    _.forEach( totalSegments,
                        function processSegmentActivityToCSV(segment) {

                            var oneDaysData = segment.segmentStats['1'] || {};
                            var sevenDaysData = segment.segmentStats['7'] || {};
                            var thirtyDaysData = segment.segmentStats['30'] || {};


                            var row = {};
                            row.name = segment.segmentName;
                            row.size = segment.segmentSize || 0;

                            row['activity24h-added'] = oneDaysData['totalCallsAdded'] || 0;
                            row['activity24h-removed'] = oneDaysData['totalCallsRemoved'] || 0;

                            row['activity7d-added'] = sevenDaysData['totalCallsAdded'] || 0;
                            row['activity7d-removed'] = sevenDaysData['totalCallsRemoved'] || 0;

                            row['activity30d-added'] = thirtyDaysData['totalCallsAdded'] || 0;
                            row['activity30d-removed'] = thirtyDaysData['totalCallsRemoved'] || 0;

                            data.push( row );
                        }
                    );

                    CsvService.exportAsFile(
                        CsvService.generateFileName( 'Segments-Activity', true ),
                        data,
                        [
                            'name',
                            'size',

                            'activity24h-added',
                            'activity24h-removed',

                            'activity7d-added',
                            'activity7d-removed',

                            'activity30d-added',
                            'activity30d-removed'
                        ],
                        ['Selection', 'Size', 'Activity24h+', 'Activity24h-', 'Activity7d+', 'Activity7d-', 'Activity30d+', 'Activity30d-']
                    );
                } );
            };

            return {
                exportToCsv: exportToCsv,
                getTotalSegmentsWithStats: getTotalSegmentsWithStats,
            };
        }]);
