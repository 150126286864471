angular.module('webUi.ui.tagmanagement.tags.delete', ['ngResource', 'webUi.service', 'ui.bootstrap'])

    .controller('TagmanagementTagDeleteController', ['$scope', 'Restangular', '$uibModalInstance', 'dialogsModel',

        function TagmanagementTagDeleteController($scope, Restangular, $uibModalInstance, dialogsModel) {
            $scope.tag = dialogsModel.tag;

            $scope.confirm = function() {
                $uibModalInstance.close(true);
            };

            $scope.cancel = function() {
                $uibModalInstance.close(false);
            };
        }]);
