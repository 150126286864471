import { get, isArray } from 'lodash';
import { getAngularService } from 'ReactAngular/react.service';
import { changeUrl, broadcastAngularEvent, prepareImageFormData } from '~/common';
import Api from '~/common/ApiService';
import * as types from './types';
import * as selectors from './selectors';
import SupportTagsService from './dataService';

/**
 * Toggle displaying deleted variants
 */
export const toggleDeleted = () => ({
  type: types.TOGGLE_DELETED,
});

/**
 * Fetch template
 * @param templateId
 */
export const fetchTemplate = templateId => dispatch => {
  dispatch({
    type: types.TEMPLATE_FETCH_START,
    payload: templateId,
  });

  return SupportTagsService.getTemplate(templateId)
    .then(payload => {
      dispatch({
        type: types.TEMPLATE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.TEMPLATE_FETCH_FAIL, error }));
};

/**
 * Fetch template only if not in store
 * @param templateId
 */
export const fetchTemplateIfNeeded = templateId => (dispatch, getState) => {
  if (selectors.getTemplateById(getState(), templateId)) {
    return Promise.resolve();
  }
  return dispatch(fetchTemplate(templateId));
};

/**
 * Delete template
 * @param template
 */
export const deleteTemplate =
  ({ templateId, groupId }) =>
  dispatch => {
    dispatch({
      type: types.DELETE_START,
      payload: templateId,
    });

    // Remove Message on the backend
    return SupportTagsService.deleteTemplate(templateId)
      .then(() => {
        // Remove Message from Store
        dispatch({
          type: types.DELETE_SUCCESS,
          payload: { templateId, groupId },
        });

        // Notify sidebar (still in AngularJS)
        const PubSubService = getAngularService(document, 'PubSubService');
        PubSubService.publishTypeDeleted(get(PubSubService, 'TYPES.SUPPORT.TAG_TEMPLATE'), { id: templateId });

        // Go to Message List
        changeUrl(`support/tagtemplates/groups/view/${groupId}`);
      })
      .catch(error => dispatch({ type: types.DELETE_FAIL, error }));
  };

/**
 * Save template
 * Updates existing template or creates a new one if there is no templateId in saving object
 * @param template
 */
export const saveTemplate = template => dispatch => {
  const logoFile = isArray(template.logo) ? template.logo[0] : null;
  const logoName = logoFile ? logoFile.name : template.logo;

  const apiCall = template.templateId ? SupportTagsService.updateTemplate : SupportTagsService.saveTemplate;

  const model = template.group
    ? {
        ...template,
        groupId: template.group.id,
        groupName: template.group.label,
      }
    : template;

  dispatch({
    type: types.SAVE_START,
    payload: template,
  });

  const sendData = {
    ...model,
    platformType: template.platformType.id,
    tagContentType: template.tagContentType.id,
  };

  // If logo is a File instance we will upload it in multipart call
  if (logoFile) {
    delete sendData.logo;
  }

  // Save Template on the backend
  return apiCall(sendData)
    .then(data => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: {
          ...model,
          logo: logoName,
          templateId: data,
          variants: model.variants || [],
        },
      });

      // Notify sidebar (still in AngularJS)
      const eventType = template.templateId ? 'support.tagTemplateChanged' : 'support.tagTemplateCreated';
      broadcastAngularEvent(eventType, {
        templateId: data,
      });

      if (logoFile && data) {
        const body = prepareImageFormData(logoFile);
        Api.callPostMultiPart(`/api/support/tagtemplates/templates/${data}/uploadLogo`, body).then(() => {
          // Go to the tag template view
          changeUrl(`support/tagtemplates/templates/view/${data}`);
        });
      } else {
        // Go to the tag template view
        changeUrl(`support/tagtemplates/templates/view/${data}`);
      }
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Fetch Tag Execution Types
 */
export const fetchTagContentTypes = () => dispatch => {
  dispatch({
    type: types.TAG_CONTENT_TYPES_FETCH_START,
  });

  return SupportTagsService.getTagContentTypes()
    .then(payload => {
      dispatch({
        type: types.TAG_CONTENT_TYPES_FETCH_SUCCESS,
        payload: payload.map(item => {
          const type = item && item.content;
          return (
            type && {
              value: { id: type.name, label: type.label },
              label: type.label,
            }
          );
        }),
      });
    })
    .catch(error => dispatch({ type: types.TAG_CONTENT_TYPES_FETCH_FAIL, error }));
};

/**
 * Fetch Tag Execution Types if not in store
 */
export const fetchTagContentTypesIfNeeded = () => (dispatch, getState) => {
  if (selectors.getTagContentTypes(getState()).length) {
    return Promise.resolve();
  }
  return dispatch(fetchTagContentTypes());
};

/**
 * Fetch Tag Execution Types
 */
export const fetchPlatformTypes = () => dispatch => {
  dispatch({
    type: types.PLATFORM_TYPES_FETCH_START,
  });

  return SupportTagsService.getPlatformTypes()
    .then(payload => {
      dispatch({
        type: types.PLATFORM_TYPES_FETCH_SUCCESS,
        payload: payload.map(item => ({
          value: { id: item.name, label: item.label },
          label: item.label,
        })),
      });
    })
    .catch(error => dispatch({ type: types.PLATFORM_TYPES_FETCH_FAIL, error }));
};

/**
 * Fetch Tag Execution Types if not is store
 */
export const fetchPlatformTypesIfNeeded = () => (dispatch, getState) => {
  if (selectors.getPlatformTypes(getState()).length) {
    return Promise.resolve();
  }
  return dispatch(fetchPlatformTypes());
};
