import PropTypes from 'prop-types';
import React from 'react';
import { CellSelected } from './index';
import { TickOff, TickOn } from '../../Tick';

const Selection = ({ id }) => (
  <CellSelected id={id}>
    {{
      SELECTED: <TickOn />,
      NOT_SELECTED: <TickOff />,
      PRE_SELECTED: <TickOn />,
      UNSELECTABLE: <TickOff />,
    }}
  </CellSelected>
);

Selection.propTypes = {
  id: PropTypes.any.isRequired,
};

export default Selection;
