import React, { ReactElement, useEffect, useState } from 'react';
import { useAPI } from '~/common';
import i18n from '~/i18n';
import { Chart, DateRangePicker, Heading, Notification } from '~/components';
import moment, { Moment } from 'moment';
import { fetchBeaconStats } from './dataService';

function BeaconStats({ beaconId }: { beaconId: string }): ReactElement {
  const [chartCalls, setChartCalls] = useState<number[]>([]);
  const [chartTimestamps, setChartTimestamps] = useState<string[]>([]);

  const [filter, setFilter] = useState<Record<string, Moment>>({
    startDate: moment().subtract(6, 'days').startOf('day'),
    endDate: moment().endOf('day'),
  });

  const { startDate, endDate } = filter;

  const {
    data: stats,
    isLoading: isBeaconStatsLoading,
    error: isBeaconStatsError,
  } = useAPI(
    () =>
      fetchBeaconStats(beaconId, startDate.startOf('day').toDate().getTime(), endDate.endOf('day').toDate().getTime()),
    [startDate, endDate],
  );

  useEffect(() => {
    const calls: number[] = [];
    const timestamps: string[] = [];
    stats?.forEach(stat => {
      calls.push(stat.calls);
      timestamps.push(moment(stat.key.dayTimestamp).format('DD/MM/YYYY'));
    });
    setChartCalls(calls);
    setChartTimestamps(timestamps);
  }, [stats]);

  if (isBeaconStatsError) {
    <Notification kind="error">
      {typeof isBeaconStatsError === 'string' ? isBeaconStatsError : JSON.stringify(isBeaconStatsError)}
    </Notification>;
  }

  return (
    <>
      <Heading title={i18n.t('beacons:stats.title')} kind="h3" className="p-0 pt-4">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
            setFilter({ startDate, endDate });
          }}
        />
      </Heading>

      <Chart
        chartType="column"
        series={[
          {
            color: '#199C49',
            name: i18n.t('beacons:stats.calls'),
            data: chartCalls || [],
          },
        ]}
        legend={{
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom',
        }}
        yAxisTitle={i18n.t('beacons:stats.calls')}
        xAxisLabels={chartTimestamps}
        noDataMessage={i18n.t(`beacons:stats.${isBeaconStatsLoading ? 'loading' : 'noData'}`)}
      />
    </>
  );
}

export default BeaconStats;
