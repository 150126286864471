import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { Panel } from '~/components/src/Containers';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';

const { Table, Row, Cell } = components;
const { withEmpty, withSort, withFilter, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('sites.empty.header')} body={t('sites.empty.body')} />;

const NoResults = ({ t }) => <Empty header={t('sites.noResults.header')} body={t('sites.noResults.body')} />;

const WIDTHS = {
  SITE_NUMBER: '20%',
  SITE_NAME: '30%',
};

export const SitesLinkedToBasescript = ({ list, buildSiteLink, stateKey, t }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width={WIDTHS.SITE_NUMBER} sortKey="siteNumber" title={t('sites.siteNumber')} />
        <SortableHeader width={WIDTHS.SITE_NAME} sortKey="name" title={t('sites.siteName')} />
      </Row>
      {list.map(item => (
        <Row key={item.siteId} withActions>
          <Cell style={{ width: `${WIDTHS.SITE_NUMBER}` }}>{item.siteNumber}</Cell>
          <Cell style={{ width: `${WIDTHS.SITE_NAME}` }}>
            <Link href={buildSiteLink(item.siteId)}>{item.name}</Link>
          </Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

SitesLinkedToBasescript.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  buildSiteLink: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 10 }),
)(SitesLinkedToBasescript);
