import React, { ReactElement, useContext, useState } from 'react';
import { changeUrl } from '~/common';
import { Heading, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import i18n from '~/i18n';
import TransformationDependencyList from './TransformationDependencyList';
import TransformationsTable from './TransformationsTable';
import { TransformationListType } from './types';
import { TransformationContext } from './context';

function TransformationsDashboard(): ReactElement {
  const [query, setQuery] = useState<string>('');
  const [selectedTransformation, setSelectedTransformation] = useState<TransformationListType | null>(null);

  const {
    canEditProfiles,
    transformations: transformationsList,
    refetchTransformations,
  } = useContext(TransformationContext);

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50">
      <div className="w-3/4">
        <Heading title={i18n.t('transformations:list.header')}>
          <div className="flex items-center gap-2">
            <SearchElement
              filledClassName="!rounded !h-10"
              value={query}
              onChange={e => setQuery(e.target.value || '')}
              placeholder="Search"
              testHook="transformationSearch"
            />

            {canEditProfiles && (
              <BtnIcon
                onClick={() => changeUrl('profiles/transformations/add')}
                testHook="createTransformation"
                color="blue"
                icon="add"
                tooltip={i18n.t('transformations:tooltips.add')}
              />
            )}
          </div>
        </Heading>
        <Notification kind="information">
          <p>{i18n.t('transformations:list.description')}</p>
        </Notification>

        <TransformationsTable
          transformationsList={transformationsList}
          transformationQuery={query}
          canEditProfiles={canEditProfiles}
          refetchTransformation={refetchTransformations}
          setSelectedTransformation={setSelectedTransformation}
        />

        <TransformationDependencyList
          selectedTransformation={selectedTransformation}
          handleClose={() => setSelectedTransformation(null)}
        />
      </div>
    </div>
  );
}

export default TransformationsDashboard;
