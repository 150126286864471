import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Btn from '~/components/src/Btn';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import { buildUrl } from '~/common';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import { AudienceContext } from '../AudienceContext';

const Actions = ({
  onConfirm,
  onNext,
  isConfirmEnabled,
  confirmText,
  checkByIsConfirm = false,
  dirty,
  processDirtyForm,
  testHook = 'audience',
}: {
  onConfirm: () => void;
  onNext?: () => void;
  isConfirmEnabled: boolean;
  confirmText: string;
  checkByIsConfirm?: boolean;
  dirty?: boolean;
  processDirtyForm?: (isDirty: boolean, cb: () => void) => void;
  testHook?: string;
}) => {
  const { validations, setValidations } = useContext(AudienceContext);
  const { isValid } = useFormikContext();
  const valid = checkByIsConfirm ? isConfirmEnabled : isValid;

  const { navigate } = useContext(WorklfowContext);

  useEffect(() => {
    if (validations[0] !== valid) {
      setValidations([valid, validations[1]]);
    }
  });

  const navigateToDashboard = () => {
    navigate(buildUrl('workflows/dashboard'));
  };

  const onDecline = () => {
    if (processDirtyForm && dirty) {
      processDirtyForm(dirty, () => {
        navigateToDashboard();
      });
    } else {
      navigateToDashboard();
    }
  };

  const handleNext = () => {
    if (processDirtyForm && dirty) {
      processDirtyForm(dirty, () => {
        onNext?.();
      });
    } else {
      onNext?.();
    }
  };

  return (
    <div className="flex justify-end gap-2 pt-10">
      <Btn className={`t-${testHook}-declineButton`} onClick={onDecline}>
        {i18n.t('common:actions.cancel')}
      </Btn>
      <Btn
        type="submit"
        className={`t-${testHook}-confirmButton`}
        onClick={onConfirm}
        disabled={!isConfirmEnabled}
        color="blue"
      >
        {confirmText}
      </Btn>
      {onNext && (
        <BtnOutlined className={`t-${testHook}-nextButton`} onClick={handleNext} color="blue">
          {i18n.t('common:actions.next')}
        </BtnOutlined>
      )}
    </div>
  );
};

export default Actions;
