import React from 'react';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import InputElement from '~/components/src/Form/Elements/InputElement';
import Icons from '../../Icons';
import './elements.scss';

type DateTimeElementProps = {
  name: string;
  className: string;
  disabled?: boolean;
  value: any;
  placeholder: string;
  hasError: boolean;
  testHook?: string;
  dateFormat?: string;
  timeFormat?: string | false;
  onChange: (e: any) => void;
  isValidDate?: () => boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

const DateTimeElement = ({
  name,
  disabled = false,
  value,
  placeholder,
  hasError,
  testHook = name,
  dateFormat = 'DD/MM/YYYY',
  timeFormat = false,
  onFocus,
  onBlur,
  onChange,
  className = '',
  isValidDate = () => true,
}: DateTimeElementProps) => (
  <div className={`DateTimeElement ${className}`}>
    <DateTime
      locale="en"
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      closeOnSelect={true}
      renderInput={InputElement}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      inputProps={{
        placeholder,
        id: name,
        disabled,
        autoComplete: 'off',
        hasError,
        testHook,
        onBlur,
      }}
      value={value}
      isValidDate={isValidDate}
    />
    <div className="absolute left-2.5 top-1/2 translate-y-[-55%]">
      <Icons icon="calendar" className=" h-4 w-4 text-gray-400" />
    </div>
  </div>
);

export default DateTimeElement;
