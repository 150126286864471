import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SnapshotsList from './Snapshots/SnapshotsList';
import SnapshotDetails from './Snapshots/SnapshotDetails';
import ModelRoutes from './Models';

const JAI = () => (
  <Routes>
    <Route path="snapshots/list" element={<SnapshotsList />} />
    <Route path="snapshots/view/:id" element={<SnapshotDetails />} />
    <Route path="models/*" element={<ModelRoutes />} />
  </Routes>
);

export default JAI;
