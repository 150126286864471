import exportActionTypes from './exportActionTypes';
import exportsInitialState from './exportsInitialState';

const exportReducer = (state = exportsInitialState, action) => {
  switch (action.type) {
    case exportActionTypes.FETCH_SCHEDULED_EXPORTS_START:
      return state.set('isFetchingScheduledExports', true);
    case exportActionTypes.FETCH_SCHEDULED_EXPORTS_FAIL:
      return state.set('isFetchingScheduledExports', false);
    case exportActionTypes.FETCH_SCHEDULED_EXPORTS_SUCCESS:
      return state.set('isFetchingScheduledExports', false).set('scheduledExportsData', action.data);
    case exportActionTypes.FETCH_MANUAL_EXPORTS_START:
      return state.set('isFetchingManualExports', true);
    case exportActionTypes.FETCH_MANUAL_EXPORTS_FAIL:
      return state.set('isFetchingManualExports', false);
    case exportActionTypes.FETCH_MANUAL_EXPORTS_SUCCESS:
      return state.set('isFetchingManualExports', false).set('manualExportsData', action.data);
    case exportActionTypes.SET_SELECTED_RANGE:
      return state.set('selectedRange', action.selectedRange);
    case exportActionTypes.SET_EXPORT_ERROR:
      return state.set('exportError', action.exportError);
    default:
      return state;
  }
};

export default exportReducer;
