/**
 * @description Angular main module for Profiles -> CustomerData
 * @memberOf webUi
 * @namespace webUi.ui.profiles.customerData
 */
angular.module('webUi.ui.profiles.customerData', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.customerData', {
            url: '/customerData/dashboard',
            isReact: true,
        });
    }]);