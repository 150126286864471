import { createContext, useContext, Dispatch } from 'react';
import { DashboardStateType, Action } from './types';

type DashboardContextValue = { state: DashboardStateType; dispatch: Dispatch<Action> } | null;

const DashboardContext = createContext<DashboardContextValue>(null);

function useDashboard() {
  return useContext(DashboardContext);
}

export { DashboardContext, useDashboard };
