// Module
var code = ` <div class="dynatree-node {{node.addClass}}" data-ng-init="initializeNode(node);" data-ng-class="{
		'dynatree-folder' : node.children.length>0,
		'dynatree-has-children' : node.children.length>0,
		'dynatree-expanded' : node.expanded,
		'dynatree-nosubmatch' : filter && !TreeService.isSubmatch(node, filter),
		'dynatree-active' : node.active,
		'dynatree-focused' : node.active,
		'dynatree-exp-e' : node.expanded,
		'dynatree-exp-c' : !node.expanded,
		'dynatree-ico-ef' : node.expanded && node.children.length>0,
		'dynatree-ico-cf' : !node.expanded && node.children.length>0,
		'dynatree-lastsib' : node.collectionInfo.last,
		'dynatree-ico-c' : isEmpty(node.children),
		'sv-dynatree-invisible': !TreeService.isVisible(node, filter)
	}"> <span style="display:inline-block" data-ng-click="toggleNodeExpand(node);" data-ng-class="{
				'dynatree-connector' : isEmpty(node.children),
				'dynatree-expander' : node.children.length>0
			}"> </span> <icon class="shrink-0 text-gray-600" p="{{getNodeIcon(node)}}" data-ng-click="TreeService.activateNode(node, nodes);"></icon> <a data-ng-if="!node.sref" class="droppable-theme-gray" data-ng-class="{
			'sv-dynatree-href-replacement': isEmpty(node.sref)
		}" data-drag-value="{{node.id}}" data-drop-value="{{node.id}}" data-drag-namespace="{{dragOptions.namespace || node.dragNamespace }}" data-drop-namespace="{{dropOptions.namespace || node.dropNamespace }}" data-lvl-draggable="{{dragOptions.enabled || node.dragEnabled }}" data-lvl-drop-target="{{dropOptions.enabled || node.dropEnabled }}" data-on-hover="node.expanded = true;" data-name="{{node.name}}" data-on-drop="onDrop(dragEl, dropEl)" data-ng-bind="node.name" data-ng-click="toggleNodeExpand(node);"> </a> <a data-ng-if="node.sref" data-ui-sref="{{node.sref}}" class="droppable-theme-gray" data-ng-class="{
			'sv-dynatree-href-replacement': isEmpty(node.sref)
		}" data-drag-value="{{node.id}}" data-drop-value="{{node.id}}" data-drag-namespace="{{dragOptions.namespace || node.dragNamespace }}" data-drop-namespace="{{dropOptions.namespace || node.dropNamespace }}" data-lvl-draggable="{{dragOptions.enabled || node.dragEnabled }}" data-lvl-drop-target="{{dropOptions.enabled || node.dropEnabled }}" data-on-hover="node.expanded = true;" data-name="{{node.name}}" data-on-drop="onDrop(dragEl, dropEl)" data-ng-bind="node.name" data-ng-click="TreeService.activateNode(node, nodes);"> </a> <span data-ng-if="isEmpty(node.children) && (canDelete === true && node.canDelete !== false )" data-ng-click="deleteNode(node)" data-ng-attr-title="{node.title.remove ? node.title.remove : 'Remove'" class="sv-dynatree-remove"> <icon class="shrink-0 text-gray-500 hover:text-gray-800" p="delete"></icon> </span> </div> <ul class="dynatree-container" data-ng-init="parentNode = node"> <li data-ng-init="assignParent(node, parentNode)" data-ng-if="parentNode.expanded" data-ng-repeat="node in node.children  | orderBy:'name'" data-ng-include data-src="'directive/tree/treeNode.tpl.html'"></li> <li data-ng-if="canEdit && ((node.active && nodes.activeNode.allowChildren) || node.expanded)" data-ng-init="treeStateAddNew = {active : false};" class="dynatree-add-external"> <span data-ng-if="!treeStateAddNew.active" data-ng-click="addNode(node)"> <i class="r42-plus"></i><span class="dynatree-add-label">Add child</span> </span> </li> </ul>`;
// Exports
var _module_exports =code;;
var path = 'directive/tree/treeNode.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;