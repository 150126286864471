import React from 'react';
import cx from 'classnames';
import Icon from '~/components/src/Icon';
import { IoMdInformationCircleOutline, IoIosWarning } from 'react-icons/io';
import { MdError } from 'react-icons/md';
import './styles.scss';

export const MessageTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING',
};

function CardMessage({ message, className = '', type = MessageTypes.ERROR }) {
  let icon = MdError;
  switch (type) {
    case MessageTypes.INFO:
      icon = IoMdInformationCircleOutline;
      break;
    case MessageTypes.WARNING:
      icon = IoIosWarning;
      break;
    default:
      break;
  }
  return (
    <div className={cx('CardMessage', `CardMessage-${type}`, className)}>
      <Icon icon={icon} size={24} className="CardMessage-icon" />
      <p>{message}</p>
    </div>
  );
}

export default CardMessage;
