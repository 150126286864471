import Api, { API_BASE } from '~/common/ApiService';
import { PayloadType, AuthenticationType } from './types';

const apiPath = `${API_BASE}/v1/partners/adform`;

export const fetchPayloadTypes = (): Promise<PayloadType[]> =>
  Api.callGet(`${apiPath}/payloadTypes`).then(response => response.data);

export const fetchAuthenticationTypes = (): Promise<AuthenticationType[]> =>
  Api.callGet(`${apiPath}/authenticationTypes`).then(response => response.data);
