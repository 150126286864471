import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import { useAPI } from '~/common';
import Link from '~/components/src/Link';
import { SubPathType, SubPathsTableProps } from '../types';
import { changePathShowType } from '../utils';
import { getPathList, getPathListCount } from '../dataService';
import { ActionTypes, DATE_FORMAT, TIME_FORMAT } from '../constants';

const columnHelper = createColumnHelper<SubPathType>();

export const SubPathsTable = ({
  subPaths,
  addPath,
  path,
  dispatch,
  changePath,
  hasTagEdit,
  searchQuery,
}: SubPathsTableProps): React.ReactElement => {
  const { data: listCount, isLoading } = useAPI(() => getPathListCount(path), [path]);

  const onShowMore = async () => {
    const { timestamp } = subPaths.at(-1) || {};
    const { subPaths: data } = await getPathList(path, timestamp);
    dispatch({ action: ActionTypes.SHOW_MORE, subPaths: data });
  };
  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="inline-block text-left">{i18n.t('indexedPaths:tables.subPath.fullPath')}</div>,
      cell: info => (
        <div className="text-left">
          <Link onClick={() => changePath(info.getValue())}>{changePathShowType(info.getValue())}</Link>
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('timestamp', {
      header: () => <div className="inline-block text-left">{i18n.t('indexedPaths:tables.subPath.added')}</div>,
      cell: info => (
        <div className=" text-left">
          <span>
            {moment(info?.row?.original?.timestamp).format(DATE_FORMAT)}{' '}
            <span className="text-sm text-r42-blue">{moment(info?.row?.original?.timestamp).format(TIME_FORMAT)}</span>
          </span>
        </div>
      ),
      size: 100,
    }),

    columnHelper.accessor('exists', {
      header: () => <div className="text-center">{i18n.t('indexedPaths:tables.subPath.exists')}</div>,
      cell: info => (
        <div className="text-center">
          {info.getValue() ? (
            <Icons icon="done" className="h-6 w-6 text-green-600" />
          ) : (
            <BtnIcon
              testHook="addPath"
              icon="add"
              tooltip={i18n.t('indexedPaths:tooltips.add')}
              onClick={() => {
                addPath(info?.row?.original?.name, () => {
                  dispatch({ action: ActionTypes.ADD_PATH, path: info?.row?.original?.name });
                });
              }}
              disabled={!hasTagEdit}
            />
          )}
        </div>
      ),
      size: 100,
      enableSorting: false,
    }),
  ];

  const showMore = !isLoading && listCount && listCount > subPaths.length;
  return (
    <>
      <div className="mt-5">
        <UITable
          emptyMessage={i18n.t('indexedPaths:empty.indexedPaths.header')}
          emptyBody={searchQuery.length !== 0 ? i18n.t('indexedPaths:empty.body') : ''}
          testHook="subPathsTable"
          data={subPaths}
          columns={getColumns()}
          searchQuery={searchQuery}
          enableSorting
          enableFilters
        />
      </div>
      {!!showMore && (
        <div className="mt-4 flex justify-center">
          <Btn
            className="rounded border border-blue-500 bg-transparent px-4 py-2 text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white"
            onClick={onShowMore}
          >
            {i18n.t('indexedPaths:showMore')}
          </Btn>
        </div>
      )}
    </>
  );
};

export default SubPathsTable;
