import React from 'react';
import { components } from '~/components/src/Table';
import PropTypes from 'prop-types';
import fileSize from 'filesize';
import i18n from '~/i18n';
import Block from '~/components/src/Block/Block';
import Tooltip from '~/components/src/Tooltip';
import constants from './constants';
import './styles.scss';

const { Row, Cell, RowActions } = components;

const ScheduledExportsComponent = props => {
  const { row, actions } = props;
  const host = row.connectionConfig ? row.connectionConfig.host : 'host';

  const fileSizeLabel = row.fileSize ? fileSize(row.fileSize) : '-';

  return (
    <Row withActions>
      <Cell className="u-size1of12" title={row.frequency}>
        {row.frequency}
      </Cell>
      <Cell className="u-size2of12" title={row.exportType}>
        {row.exportType}
      </Cell>
      <Cell className="u-size2of12" title={host}>
        {host}
      </Cell>
      <Cell className="u-size1of12 u-pointer Row--attempt">
        {row.lastAttempts
          ? row.lastAttempts.map((attempt, index) => (
              <Block
                key={index}
                tooltip={`${attempt.timestamp} - ${attempt.status.label}`}
                isSuccessful={attempt.status.name === constants.isBlockSuccessful}
                className="!h-4 !w-4"
              />
            ))
          : '-'}
      </Cell>
      <Cell className="u-size2of12 flex flex-col">
        <div>{row.createdOn}</div>
        <div className="truncate">
          <Tooltip
            tooltip={row.createdBy ? `${i18n.t('exports:scheduledColumns.createdBy')}: ${row.createdBy}` : '-'}
            placement="top"
          >
            <span>{row.createdBy}</span>
          </Tooltip>
        </div>
      </Cell>
      <Cell className="u-size2of12 flex flex-col">
        {row.modifiedOn}
        <div className="truncate">
          <Tooltip
            tooltip={row.modifiedBy ? `${i18n.t('exports:scheduledColumns.lastModifiedBy')}: ${row.modifiedBy}` : '-'}
            placement="top"
          >
            <span>{row.modifiedBy ? row.modifiedBy : '-'}</span>
          </Tooltip>
        </div>
      </Cell>
      <Cell className="u-size1of12" title={fileSizeLabel}>
        {fileSizeLabel}
      </Cell>
      <RowActions className="u-size1of12 flex" actions={actions} />
    </Row>
  );
};

ScheduledExportsComponent.propTypes = {
  row: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
};

export default ScheduledExportsComponent;
