import { get } from 'lodash';
import { buildUrl } from '~/common';
import { GO_BACK_GROUP } from './types';

/**
 * Get All Audiences and Groups
 * @param state
 */
export const getAllAudiences = state =>
  state.profiles.audiencesTree.allIds.map(id => state.profiles.audiencesTree.byId[id]);

/**
 * Get item from Breadcrumbs
 * @param state
 * @param itemId
 */
export const getBreadcrumbItem = (breadcrumbs, itemId) => breadcrumbs[itemId];

export const getParentId = (state, itemId) => get(state, `profiles.audiencesTree.breadcrumbs[${itemId}].parentId`);

const getBreadcrumbsForItemId = (breadcrumbs, itemId) => {
  if (!breadcrumbs) {
    return [];
  }

  const parents = [
    {
      title: 'Audiences',
      pathname: buildUrl('audiences/segments/list/0'),
    },
  ];
  let item = getBreadcrumbItem(breadcrumbs, itemId);
  let itemParent;
  if (!item) return parents;
  while (item.parentId !== null) {
    itemParent = getBreadcrumbItem(breadcrumbs, item.parentId);
    parents.push({
      title: itemParent.name,
      pathname: buildUrl(`audiences/segments/list/${item.parentId}/0`),
    });
    item = itemParent;
  }
  return parents;
};
/**
 * Get parent of item
 * @param state
 */
export const getBreadcrumbs = (state, itemId) => {
  const { breadcrumbs } = get(state, 'profiles.audiencesTree', null);

  return getBreadcrumbsForItemId(breadcrumbs, itemId);
};

/**
 * Get Root Level Audiences and Groups
 * @param state
 */
export const getRootAudiences = state => getAllAudiences(state).filter(item => !item.parentId);

/**
 * Get Audiences for a given Group
 * @param state
 * @param parentId
 */
export const getSubAudiences = (state, parentId) => [
  GO_BACK_GROUP,
  ...getAllAudiences(state).filter(item => item.parentId === parentId),
];

/**
 * Get Audiences
 * @param state
 * @param parentId
 */
export const getAudiences = (state, parentId) =>
  parentId ? getSubAudiences(state, parentId) : getRootAudiences(state);

/**
 * Get single Audience or Group
 * @param state
 * @param itemId
 */
export const getAudienceOrGroup = (state, itemId) => get(state, `profiles.audiencesTree.byId[${itemId}]`);

/**
 * Is fetching all audiences currently in progress?
 * @param state
 */
export const isFetchingAudiences = state => get(state, 'profiles.audiencesTree.ui.isFetchingAll');

/**
 * Is moving audience currently in progress?
 * @param state
 */
export const isMovingItem = state => get(state, 'profiles.audiencesTree.ui.isMovingItem');

/**
 * Get the total number of groups and audiences on given level available on the backend
 * @param {*} state
 */
export const getNodesQty = state => get(state, 'profiles.audiencesTree.ui.nodesQty');

export const getSelectedId = state => get(state, 'profiles.audiencesTree.ui.selectedId');

export const isAudienceDependantsContainerVisible = state =>
  get(state, 'profiles.audiencesTree.ui.dependantsContainerIsVisible');

export const getSelectedAudience = state => {
  const selectedId = getSelectedId(state);

  return selectedId ? get(state, `profiles.audiencesTree.byId.${selectedId}`) : null;
};
