export const ENGAGEMENTS_FETCH_START = 'app/profiles/engagements/ENGAGEMENTS_FETCH_START';
export const ENGAGEMENTS_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENTS_FETCH_FAIL';
export const ENGAGEMENTS_FETCH_SUCCESS = 'app/profiles/engagements/ENGAGEMENTS_FETCH_SUCCESS';

export const ENGAGEMENTS_ACTIVITIES_FETCH_START = 'app/profiles/engagements/ENGAGEMENTS_ACTIVITIES_FETCH_START';
export const ENGAGEMENTS_ACTIVITIES_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENTS_ACTIVITIES_FETCH_FAIL';
export const ENGAGEMENTS_ACTIVITIES_FETCH_SUCCESS = 'app/profiles/engagements/ENGAGEMENTS_ACTIVITIES_FETCH_SUCCESS';

export const ENGAGEMENT_FETCH_START = 'app/profiles/engagements/ENGAGEMENT_FETCH_START';
export const ENGAGEMENT_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENT_FETCH_FAIL';
export const ENGAGEMENT_FETCH_SUCCESS = 'app/profiles/engagements/ENGAGEMENT_FETCH_SUCCESS';

export const ENGAGEMENT_ACTIVITY_FETCH_START = 'app/profiles/engagements/ENGAGEMENT_ACTIVITY_FETCH_START';
export const ENGAGEMENT_ACTIVITY_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENT_ACTIVITY_FETCH_FAIL';
export const ENGAGEMENT_ACTIVITY_FETCH_SUCCESS = 'app/profiles/engagements/ENGAGEMENT_ACTIVITY_FETCH_SUCCESS';

export const ENGAGEMENT_TRAFFIC_SOURCES_FETCH_START = 'app/profiles/engagements/ENGAGEMENT_TRAFFIC_SOURCES_FETCH_START';
export const ENGAGEMENT_TRAFFIC_SOURCES_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENT_TRAFFIC_SOURCES_FETCH_FAIL';
export const ENGAGEMENT_TRAFFIC_SOURCES_FETCH_SUCCESS =
  'app/profiles/engagements/ENGAGEMENT_TRAFFIC_SOURCES_FETCH_SUCCESS';

export const ENGAGEMENT_PROPERTIES_FETCH_START = 'app/profiles/engagements/ENGAGEMENT_PROPERTIES_FETCH_START';
export const ENGAGEMENT_PROPERTIES_FETCH_FAIL = 'app/profiles/engagements/ENGAGEMENT_PROPERTIES_FETCH_FAIL';
export const ENGAGEMENT_PROPERTIES_FETCH_SUCCESS = 'app/profiles/engagements/ENGAGEMENT_PROPERTIES_FETCH_SUCCESS';

export const ENGAGEMENT_SAVE_START = 'app/profiles/engagements/ENGAGEMENT_SAVE_START';
export const ENGAGEMENT_SAVE_FAIL = 'app/profiles/engagements/ENGAGEMENT_SAVE_FAIL';
export const ENGAGEMENT_SAVE_SUCCESS = 'app/profiles/engagements/ENGAGEMENT_SAVE_SUCCESS';

export const ENGAGEMENT_ADD_PROPERTY_START = 'app/profiles/engagements/ENGAGEMENT_ADD_PROPERTY_START';
export const ENGAGEMENT_ADD_PROPERTY_FAIL = 'app/profiles/engagements/ENGAGEMENT_ADD_PROPERTY_FAIL';
export const ENGAGEMENT_ADD_PROPERTY_SUCCESS = 'app/profiles/engagements/ENGAGEMENT_ADD_PROPERTY_SUCCESS';

export const ENGAGEMENTS_DELETE_START = 'app/profiles/engagements/ENGAGEMENTS_DELETE_START';
export const ENGAGEMENTS_DELETE_FAIL = 'app/profiles/engagements/ENGAGEMENTS_DELETE_FAIL';
export const ENGAGEMENTS_DELETE_SUCCESS = 'app/profiles/engagements/ENGAGEMENTS_DELETE_SUCCESS';

export const SHOW_DEPENDANTS_CONTAINER = 'app/profiles/engagements/SHOW_DEPENDANTS_CONTAINER';
export const HIDE_DEPENDANTS_CONTAINER = 'app/profiles/engagements/HIDE_DEPENDANTS_CONTAINER';
export const DEPENDANTS_TYPE_TAB_CHANGE = 'app/profiles/engagements/DEPENDANTS_TYPE_TAB_CHANGE';

export const SET_USER_IS_EDITING = 'app/profiles/engagements/SET_USER_IS_EDITING';

export const ENGAGEMENT_USED_IN = [
  'SELECTION',
  'THIRD_PARTY_DATA_COLLECTOR',
  'JOURNEY',
  'FILTER',
  'PROPERTY_TRANSFORMATION',
];
export const ALLOWED_NUMBER_OF_PROPERTIES = 64;
