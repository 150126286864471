import React, { MouseEvent, ReactElement } from 'react';
import cx from 'classnames';
import { formatNumber } from '~/common';
import { Link, Tooltip } from '~/components';
import i18n from '~/i18n';
import EmptyPlaceholder from '~/components/src/EmptyPlaceholder';

type StatsPlaceholderType = {
  icon: string;
  type?: string;
  error: Error | null;
  redirectUrl: string;
  onClick: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
};

export const StatsTableHead = ({ className, label }: { className?: string; label: string }): ReactElement => (
  <p className={cx('text-slate-400', className)}>{label}</p>
);

export const StatsKeyValue = ({
  label,
  value,
  valueClassName,
}: {
  label: string;
  value: number;
  valueClassName?: string;
}): ReactElement => (
  <>
    <StatsTableHead label={label} className="col-span-2 md:hidden" />
    <p className={cx('text-right text-base text-slate-700 dark:text-slate-200 md:col-span-1', valueClassName)}>
      {formatNumber(value || 0)}
    </p>
  </>
);

export const StatsPlaceholder = ({ icon, type, error, redirectUrl, onClick }: StatsPlaceholderType): ReactElement => {
  const translationPrefix = `dashboard:panels.${icon}${type ? `.${type}` : ''}`;

  return (
    <EmptyPlaceholder
      icon={icon}
      title={i18n.t(`${translationPrefix}.${error ? 'errorHeader' : 'emptyHeader'}`)}
      subTitle={
        <div>
          <span>{i18n.t(`${translationPrefix}.${error ? 'errorBody' : 'emptyBody'}`)} </span>
          {!error && (
            <Link href={redirectUrl} color="slate">
              {i18n.t(`${translationPrefix}.emptyBodyLink`)}
            </Link>
          )}
        </div>
      }
      onClick={error ? () => onClick : undefined}
      action={i18n.t('dashboard:refresh')}
    />
  );
};

export const StatsPropertyWithNumber = ({
  number,
  name,
  redirectUrl,
  className,
}: {
  number: number;
  name: string;
  redirectUrl: string;
  className?: string;
}): ReactElement => (
  <div className={cx('pb-2 md:pb-0', className)}>
    <span className="mr-2 rounded bg-emerald-500 bg-opacity-10 px-2.5 py-0.5 text-sm font-medium text-emerald-500">
      {number}
    </span>
    <Link href={redirectUrl} color="slate">
      {name}
    </Link>
  </div>
);

export const StatsPropertyWithStatus = ({
  status,
  name,
  redirectUrl,
  className,
}: {
  status: string;
  name: string;
  redirectUrl: string;
  className?: string;
}): ReactElement => (
  <div className={cx('flex items-center pb-2 md:pb-0', className)}>
    <Tooltip tooltip={status} placement="bottom">
      <div className={cx('mr-2 h-2 w-2 rounded-lg', status === 'Published' ? 'bg-emerald-500' : 'bg-amber-500')} />
    </Tooltip>
    <Link href={redirectUrl} color="slate">
      {name}
    </Link>
  </div>
);
