import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import { buildUrl } from '~/common';
import EditableRulesList from '~/common/modules/rules/components/EditableRulesList';
import { RulesTypes } from '~/common/modules/rules/types';
import { truncateString } from '~/common/utils/StringUtils';
import { Notification, Tooltip } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import DeletePopup from '~/components/src/DeletePopup';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { shapeCriteriaRequest } from '~/profiles/audiences/utils';
import { EXPIRY_OPTIONS, NodeTypes } from '~/workflows/constants';
import { createExitCondition, deleteTrigger, updateTriggers } from '~/workflows/dataService';
import { ExitCondition, TExitConditionConfigurationProps, TRule } from '~/workflows/types';
import { joinCriteriaVariables } from '~/workflows/util';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import { AudienceContext } from '../AudienceContext';
import { useDirtyFormHandler } from '../hooks';
import Actions from './ActionButtons';
import DirtyFormPopup from './DirtyFormPopup';
import StepHeader from './StepHeader';

const ExitConditionConfiguration = ({
  exitConditions,
  allVariables = [],
  ruleTypesTree = [],
  selectedExitCondition,
  triggerData,
  workflowId,
  isLoading,
  refetchTree,
  refetchAllVariables,
  setSelectedExitCondition,
}: TExitConditionConfigurationProps) => {
  const isEdit = !!selectedExitCondition;
  const { processDirtyForm, showDirtyFormPopup, setShowDirtyFormPopup, cbRef } = useDirtyFormHandler();
  const [selectEConditionToDelete, setSelectEConditionToDelete] = useState<ExitCondition | null>(null);
  const [showForm, setShowForm] = useState(false);
  const { navigate } = useContext(WorklfowContext);
  const { validations, setValidations, updateSearchParams, isOptional } = useContext(AudienceContext);

  const [isValid, setIsValid] = useState(isEdit);

  const { defaultTimeCondition, name } = triggerData || {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    expiry: Yup.number().required(i18n.t('validation:validation.required')),
  });

  useEffect(() => {
    if (!selectedExitCondition) {
      setIsValid(false);
    }
    setShowForm(showForm || !!selectedExitCondition);
  }, [selectedExitCondition]);

  useEffect(() => {
    if (isValid !== validations?.[1]) {
      setValidations([validations?.[0], isValid]);
    }
  }, [isValid, isLoading]);

  const mapCriteria = (criteria: any) => joinCriteriaVariables(criteria || [], allVariables || []);

  const initialValues = {
    name: name || '',
    expiry: defaultTimeCondition ? defaultTimeCondition.substring(0, defaultTimeCondition.length - 1) : 30,
    expiryOption: defaultTimeCondition ? defaultTimeCondition[defaultTimeCondition.length - 1] : 'd',
    criteria: mapCriteria(triggerData?.criteria ?? []),
  };

  const handleDelete = async () => {
    setSelectEConditionToDelete(null);
    if (selectEConditionToDelete?.nodeId) {
      await deleteTrigger(workflowId, selectEConditionToDelete?.nodeId);
      if (triggerData?.triggerId === selectEConditionToDelete?.nodeId) {
        setSelectedExitCondition(null);
      }
      showSuccess({ body: i18n.t('workflow:create.steps.step6.delete.deleted') });
      refetchTree();
    }
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const { name, expiry, expiryOption } = values;
    const payload = {
      name,
      defaultTimeCondition: expiry + expiryOption,
      criteria: shapeCriteriaRequest(values?.criteria),
      journeyId: workflowId,
      type: NodeTypes.TRIGGER,
    };

    if (!isEdit) {
      const res = await createExitCondition(payload, workflowId);
      setSelectedExitCondition(res);
      showSuccess({ body: i18n.t('workflow:create.steps.step6.api.created') });
    } else {
      await updateTriggers({ ...payload, triggerId: selectedExitCondition });
      showSuccess({ body: i18n.t('workflow:create.steps.step6.api.updated') });
    }
    refetchTree();
  };
  const alreadySelectedOverlapJourneyIds =
    triggerData?.criteria ||
    []
      .flat()
      .filter((rule: TRule) => rule.type === RulesTypes.JourneyOverlap)
      .map((rule: TRule) => rule?.dependantJourneyId);
  const isOverlapRulesLimitReached = alreadySelectedOverlapJourneyIds.length >= 5;
  const ruleTypes = ruleTypesTree?.map((rule: TRule) => {
    if (rule.type === RulesTypes.JourneyOverlap) {
      return {
        ...rule,
        isDisabled: isOverlapRulesLimitReached,
        subtitle: isOverlapRulesLimitReached
          ? i18n.t('audiences:rules.descriptions.JourneyOverlapRuleLimitReached', { limit: 5 })
          : rule?.subtitle,
        subMenu: null,
      };
    }
    return rule;
  });

  const goToNextStep = () => navigate(buildUrl(`workflows/view/${workflowId}`));

  return (
    <>
      <div className="flex">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
          validateOnMount
        >
          {({ handleSubmit, isValid, errors, setFieldValue, isSubmitting, values, dirty, resetForm }) => (
            <>
              <div className="w-full px-2 xl:w-4/6">
                <StepHeader title={i18n.t('workflow:create.steps.step6.title')} isOptional={isOptional} />

                <div className="mt-3 flex">
                  {exitConditions.map(item => (
                    <div
                      key={item.nodeId}
                      className={twMerge(
                        cx(
                          't-exitConditions mr-3 w-40 cursor-pointer flex-col rounded-lg border border-gray-300 px-3 py-4',
                          {
                            'border-blue-500': item.nodeId === selectedExitCondition,
                          },
                        ),
                      )}
                      onClick={() => {
                        processDirtyForm(dirty && showForm, () => {
                          updateSearchParams(item?.nodeId);
                          setSelectedExitCondition(item?.nodeId);
                        });
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <Tooltip tooltip={item.label}>
                          <span
                            className={twMerge(
                              cx('text-gray-600', { 'text-blue-600': item.nodeId === selectedExitCondition }),
                            )}
                          >
                            {truncateString(item.label, 12)}
                          </span>
                        </Tooltip>

                        <BtnIcon
                          className="h-7 w-7"
                          icon="delete"
                          color="grayTransparent"
                          onClick={e => {
                            e.stopPropagation();
                            setSelectEConditionToDelete(item);
                          }}
                          tooltip={i18n.t('workflow:common.actions.delete')}
                          testHook="deleteExitCondition"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <BtnOutlined
                  className="my-4"
                  icon="add"
                  testHook="addNew"
                  onClick={() => {
                    processDirtyForm(dirty && showForm, () => {
                      setShowForm(true);
                      setSelectedExitCondition(null);
                      resetForm();
                    });
                  }}
                >
                  {i18n.t('workflow:create.steps.step6.addNew')}
                </BtnOutlined>
                {!isLoading ? (
                  <>
                    {!showForm && (
                      <Notification kind="information" className="my-2">
                        {i18n.t('workflow:create.steps.step6.info.createExitCondition')}
                      </Notification>
                    )}
                    <form onSubmit={handleSubmit} className="py-4">
                      {showForm && (
                        <>
                          <div className="mb-5 w-1/2">
                            <Field
                              name="name"
                              as={FormikInputField}
                              label={i18n.t('workflow:create.fields.name')}
                              placeholder={i18n.t('workflow:common.placeholder')}
                              errorText={errors?.name}
                              autoFocus={true}
                            />

                            <p className="mb-4 mt-8 text-gray-600">
                              {i18n.t('workflow:create.steps.step6.configureRules')}
                            </p>

                            <label className="pb-2">
                              {i18n.t('workflow:create.steps.step6.expiry')}
                              <Icons
                                className="mx-2 h-5 w-5 text-gray-500"
                                icon="info"
                                tooltip={i18n.t('workflow:create.steps.step6.tooltip')}
                              />
                            </label>
                            <div className="flex gap-2">
                              <Field
                                name="expiry"
                                as={FormikInputField}
                                className="w-full"
                                type="number"
                                placeholder={i18n.t('workflow:common.placeholder')}
                                errorText={errors?.expiry}
                              />

                              <Field
                                name="expiryOption"
                                className="w-full"
                                as={FormikSelectField}
                                getOptionLabel={(option: { label: string; value: string }) => option.label}
                                getOptionValue={(option: { label: string; value: string }) => option.value}
                                customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                                  setFieldValue(name, option.value);
                                }}
                                options={EXPIRY_OPTIONS}
                              />
                            </div>
                          </div>

                          <EditableRulesList
                            criteria={values.criteria}
                            ruleTypesTree={ruleTypes}
                            onChange={(criteriaState: any) => {
                              setFieldValue('criteria', mapCriteria(criteriaState.criteria));
                              setIsValid(criteriaState.isValid);
                            }}
                            fetchAllVariables={refetchAllVariables}
                            isJourneyCondition
                            isSubmitted={isSubmitting}
                            isUsedInJourneys={false}
                            defaultWaitTime={`${values.expiry}${values.expiryOption}`}
                            isJoTrigger
                            enableGroupBy
                          />
                        </>
                      )}
                      <Actions
                        onConfirm={showForm ? handleSubmit : goToNextStep}
                        isConfirmEnabled={(isValid && values?.criteria?.length > 0 && showForm) || !showForm}
                        confirmText={i18n.t(`workflow:actions.${showForm ? 'save' : 'skip'}`)}
                        dirty={dirty}
                        processDirtyForm={processDirtyForm}
                        onNext={showForm ? goToNextStep : undefined}
                      />
                    </form>
                  </>
                ) : (
                  <Spin />
                )}
              </div>
            </>
          )}
        </Formik>
      </div>

      <DirtyFormPopup {...{ cbRef, showDirtyFormPopup, setShowDirtyFormPopup }} />

      {selectEConditionToDelete && (
        <DeletePopup
          title={i18n.t('workflow:create.steps.step6.delete.title')}
          hidePopup={() => setSelectEConditionToDelete(null)}
          onClick={() => {
            handleDelete();
          }}
        >
          <Notification>
            {i18n.t('workflow:create.steps.step6.delete.message', { label: selectEConditionToDelete?.label })}
          </Notification>
        </DeletePopup>
      )}
    </>
  );
};

export default ExitConditionConfiguration;
