import { PERMISSION_DEPENDENCIES } from '~/permissions';

export const calculatePermissions = (permissions, updatedPermission, updatedValue) => {
  const dependenciesType = updatedValue ? 'requires' : 'disables';
  const dependencies =
    (PERMISSION_DEPENDENCIES[updatedPermission] && PERMISSION_DEPENDENCIES[updatedPermission][dependenciesType]) || {};

  return {
    ...permissions,
    ...dependencies,
  };
};

export const convertPermissionsToArray = permissions =>
  Object.keys(permissions).reduce((list, current) => {
    if (permissions[current]) {
      list.push(current);
    }
    return list;
  }, []);

export const updateState = (prevState, props) => {
  const { availablePermissions, uiPermissions, apiPermissions } = props;
  return {
    ...prevState,
    uiPermissions: availablePermissions
      ? uiPermissions.filter(permission => availablePermissions.includes(permission.name))
      : uiPermissions,
    apiPermissions: availablePermissions
      ? apiPermissions.filter(permission => availablePermissions.includes(permission.name))
      : apiPermissions,
  };
};
