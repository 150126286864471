import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Detail = ({ align = 'left', label, value, children, className, textColor = '' }) => {
  if (!value && !children) return null;
  return (
    <div className={cx('Form-field Form-field--detail', className)}>
      <span className={`Form-label Form-label--detail  u-align${align.charAt(0).toUpperCase()}${align.slice(1)}`}>
        {label}
      </span>
      <div className="Form-detail">
        <span
          className={`Element Element--value t-${label.split(' ').join('')}  ${
            textColor ? `text-${textColor}-500` : ''
          } `}
        >
          {children || value}
        </span>
      </div>
    </div>
  );
};

Detail.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.node.isRequired,
  value: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Detail;
