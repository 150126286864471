import { createContext } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { CampaignType, MappingColumn, ColumnType, PredefinedValue, CampaignTreeNode } from './types';

type ContextType = {
  hasUrlBuilderPermission: boolean;
  columns: MappingColumn[];
  columnTypes: ColumnType[];
  predefinedValues: PredefinedValue[];
  campaignTypes: CampaignType[];
  navigate: NavigateFunction;
  setTreeData: (tree: CampaignTreeNode[]) => void;
  refetchTree: () => void;
};

const defaultValues = {
  hasUrlBuilderPermission: false,
  columns: [],
  columnTypes: [],
  predefinedValues: [],
  campaignTypes: [],
  existingCampaigns: [],
  navigate: () => {
    // intentionally left empty
  },
  setTreeData: () => {
    // intentionally left empty
  },
  refetchTree: () => {
    // intentionally left empty
  },
};

export const UrlBuilderContext = createContext<ContextType>(defaultValues);
