/**
 * @description Angular module for Content -> External -> View
 * @class ContentCampaignsViewController
 * @namespace webUi.ui.content.campaigns.view
 * @memberOf webUi.ui.content.campaigns
 */
angular.module('webUi.ui.content.campaigns.view', [
    'webUi.ui.content.campaigns.base',
    'webUi.ui.content.campaigns.placementGroups.placements.export',
    'webUi.ui.content.campaigns.placementGroups.placements.formModal',
    'webUi.ui.content.campaigns.placementsParameter.formModal',
    'ngResource',

    'webUi.service',
    'ui.sortable'
])
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.view', {
                url: '/view/{campaignId}',
                views: {
                    'mainContent@': {
                        controller: 'ContentCampaignsViewController as vm',
                        templateUrl: 'ui/content/campaigns/view/view.tpl.html'
                    }
                },
                leftMenu: true,
                resolve: {
                    campaign: ['$stateParams', 'CampaignService', function ($stateParams, CampaignService) {
                        return CampaignService.getCampaign($stateParams.campaignId);
                    }]
                }
            });
    }])
    .controller('ContentCampaignsViewController',
        [
            '$scope',
            '$state',
            '$timeout',

            'CampaignService',
            'PubSubService',
            'AdService',
            'PlacementGroupService',
            'ModalManagementService',

            'Utils',
            'campaign',
            'hasAdservingEdit',
            'hasAudiencesView',
            'ToasterService',

            /**
         * @function ContentCampaignsViewController
         * @memberOf webUi.ui.content.campaigns
         *
         * @param $scope
         * @param $state
         * @param $timeout
         *
         * @param {CampaignService} CampaignService
         * @param {PubSubService} PubSubService
         * @param {AdService} AdService
         * @param {PlacementGroupService} PlacementGroupService
         * @param {ModalManagementService} ModalManagementService
         * @param Utils
         * @param campaign
         * @param hasAdservingEdit
         * @param hasAudiencesView
         *
         * @returns {ContentCampaignsViewController}
         */
            function ContentCampaignsViewController(
                $scope,
                $state,
                $timeout,

                CampaignService,
                PubSubService,
                AdService,
                PlacementGroupService,
                ModalManagementService,

                Utils,
                campaign,
                hasAdservingEdit,
                hasAudiencesView,
                toast) {

                'use strict';
                
                var vm = this;

                vm.campaign = campaign;
                vm.hasAdservingEdit = hasAdservingEdit;
                vm.hasAudiencesView = hasAudiencesView;
                vm.isEmpty = _.isEmpty;
                vm.isNull = _.isNull;

                vm.sortableOptions = {
                //all available options at http://codepen.io/anon/pen/FnwCj?editors=101 as example
                    update: function (e, ui) {
                        if (!vm.hasAdservingEdit) {
                            ui.item.sortable.cancel();
                        }
                    },
                    stop: function () {
                        var reorderedAds = {};
                        //iterate the list, check if ordering is different than current index
                        _.map(vm.campaign.ads, function (ad, index) {
                        //index is 0-based, ordering is 1-based
                            if (ad.ordering !== index + 1) {
                                ad.ordering = index + 1;
                                reorderedAds[ad.adId] = index + 1;
                            }
                            return ad;
                        });
                        //if we have found ads that changed their order, send the command
                        if (!_.isEmpty(reorderedAds)) {
                            AdService.reorderAds(reorderedAds).then(function () {
                                vm.campaign.hasErrors = validatePlacementGroups(vm.campaign.placementGroups);
                                PubSubService.publishAdOrderChanged();
                            });
                        }
                    },
                    handle: '.sortHandle'
                };

                /**
             * Edit the name of a campaign
             */
                vm.campaignNameOverrideOptions = {
                    editSuccessCallback: function (newValue) {
                        return CampaignService.updateCampaignName(vm.campaign.campaignId, newValue).then(
                            function onSuccess(campaignId) {
                                PubSubService.publishTypeChanged(PubSubService.TYPES.CONTENT.CAMPAIGN, {id: campaignId});
                            },
                            function onError(){}
                        );
                    }
                };

                /**
             * Opens placement export modal
             * @param placementGroup
             */
                vm.openPlacementExportModal = function openPlacementExportModal(campaign, placementGroup) {

                    var dialogsModel = function dialogsModel() {
                        return {
                            placements: placementGroup.placements,
                            campaign: campaign
                        };
                    };

                    ModalManagementService.openDialog(
                        'AdServingPlacementExportController',
                        'ui/content/campaigns/placementGroups/placements/export/export.tpl.html',
                        dialogsModel,
                        {}
                    );
                };

                /**
             * Adds a placement to a placement group
             * @param placementGroup
             */
                vm.addPlacementPlacementParameter = function addPlacementParameter() {

                    var dialogsModel = function() {
                        return {
                            data: {
                                campaign: vm.campaign
                            },
                            onSave: function (placementParam) {
                                CampaignService.addPlacementParameter(vm.campaign.campaignId, placementParam).then(function onSuccess() {
                                    CampaignService.getCampaign(vm.campaign.campaignId).then(function(result) {
                                        if(result) {
                                            vm.campaign = result;
                                        }
                                    });
                                },
                                function onError() {});
                            }
                        };
                    };

                    ModalManagementService.openDialog(
                        'AdServingPlacementParameterFormModalController',
                        'ui/content/campaigns/placementParams/formModal.tpl.html',
                        dialogsModel,
                        {'css': 'modal-medium'}
                    );
                };

                /**
             * Opens a popup for campaign deletion
             */
                vm.confirmDeletePlacementParameter = function confirmDeletePlacementParameter(placementParameter) {

                    var dialogsModel = function(){
                        return {
                            data: {
                                entityType: 'Placement parameter',
                                entityName: placementParameter.name,
                                entityId: vm.campaign.campaignId
                            },
                            onDelete: function onDelete(campaignId) {
                                CampaignService.removePlacementParameter(campaignId, placementParameter.name).then(
                                    function onSuccess() {
                                        CampaignService.getCampaign(campaignId).then(function(result) {
                                            if(result) {
                                                vm.campaign = result;
                                            }
                                        });
                                    },
                                    function onError() {}
                                );
                            },
                            onCancel: function onCancel() {}
                        };
                    };

                    var d = ModalManagementService.openDialog(
                        'DeleteDialogController',
                        'ui/globalui/deleteDialog/deleteDialog.tpl.html',
                        dialogsModel,
                        {'css': 'modal'}
                    );

                    return d;
                };

                /**
             * Opens a popup for campaign deletion
             */
                vm.confirmDeleteCampaign = function confirmDeleteCampaign() {

                    var dialogsModel = function(){
                        return {
                            data: {
                                entityType: 'campaign',
                                entityName: vm.campaign.name,
                                entityId: vm.campaign.campaignId
                            },
                            onDelete: function onDelete(campaignId) {
                                CampaignService.deleteCampaign(campaignId).then(
                                    function onSuccess() {
                                        toast.success('Campaign deleted successfully');
                                        $state.transitionToStateWithSite('site.content.campaigns.dashboard', {});
                                        PubSubService.publishTypeDeleted(PubSubService.TYPES.CONTENT.CAMPAIGN, {});
                                    },
                                    function onError() {}
                                );
                            },
                            onCancel: function onCancel() {}
                        };
                    };

                    var d = ModalManagementService.openDialog(
                        'DeleteDialogController',
                        'ui/globalui/deleteDialog/deleteDialog.tpl.html',
                        dialogsModel,
                        {'css': 'modal'}
                    );

                    return d;
                };

                /**
             * Adds a placement to a placement group
             * @param placementGroup
             */
                vm.addPlacement = function addPlacement(placementGroup) {

                    var dialogsModel = function() {
                        return {
                            data: {
                                placementGroup: placementGroup,
                                placements: placementGroup.placements
                            },
                            onSave: function (placement) {
                                PlacementGroupService.savePlacement(placement).then(function (result) {
                                //should be pushed to vm
                                    placementGroup.placements.push(result);
                                });
                            }
                        };
                    };

                    ModalManagementService.openDialog(
                        'AdServingPlacementFormModalController',
                        'ui/content/campaigns/placementGroups/placements/form/formModal.tpl.html',
                        dialogsModel,
                        {'css': 'modal-medium'}
                    );
                };

                /**
             * Confirms delete for a placement
             * @param placement
             * @param placementGroup
             */
                vm.confirmDeletePlacement = function confirmDeletePlacement(placement, placementGroup) {

                    var dialogsModel = function () {
                        return {
                            data: {
                                entityType: 'placement',
                                entityName: placement.width + ' x ' + placement.height,
                                entityId: placement.placementId
                            },
                            onDelete: function onDelete() {
                                PlacementGroupService.deletePlacement(placement).then(
                                    function onSuccess(result) {
                                        if (result) {
                                            var idx = _.indexOf(placementGroup.placements, placement);
                                            placementGroup.placements.splice(idx, 1);
                                        }
                                    },
                                    function onError(){}
                                );
                            },
                            onCancel: function onCancel() {}
                        };
                    };

                    ModalManagementService.openDialog(
                        'DeleteDialogController',
                        'ui/globalui/deleteDialog/deleteDialog.tpl.html',
                        dialogsModel,
                        {'css': 'modal'}
                    );

                };

                /**
             *
             * @param ad
             * @param placement
             * @returns {boolean}
             */
                vm.adHasDimension = function adHasDimension(ad, placement) {
                    var i;
                    for (i = 0; i < ad.creatives.length; i++) {
                        if (ad.creatives[i].width === placement.width && ad.creatives[i].height === placement.height) {
                            return true;
                        }
                    }
                    return false;
                };

                /**
             *
             * @param ad
             * @param placement
             */
                vm.toggleActivateAdForPlacement = function toggleActivateAdForPlacement(ad, placement) {
                    if (vm.hasAdservingEdit) {
                        PlacementGroupService.toggleActivateAdForPlacement(vm.campaign.campaignId, placement, [placement.linkedAds[ad.adId]]).then(function () {
                            PubSubService.publishAdToggledForPlacement();
                        });
                    }
                };

                vm.toggleActivateAdForAllPlacements = function toggleActivateAdForAllPlacements(ad, placements) {
                    if (vm.hasAdservingEdit) {
                        _.each(placements, function (placement) {
                        // if valid and not active toggle
                            if (placement.linkedAds[ad.adId] && vm.adHasDimension(ad, placement) && !placement.linkedAds[ad.adId].active) {
                                placement.linkedAds[ad.adId].active = true;
                                vm.toggleActivateAdForPlacement(ad, placement);
                            }
                        });
                    }
                };

                vm.editCreative = function editCreative(ad, creative) {
                    $state.transitionToStateWithSite('site.content.campaigns.ads.creatives.form.edit', {
                        adId: ad.adId,
                        creativeId: creative.creativeId
                    });
                };

                /**
             * @todo fix params
             * @param placementGroup
             */
                vm.confirmDeletePlacementGroup = function confirmDeletePlacementGroup(placementGroup) {

                    var dialogsModel = function () {
                        return {
                            data: {
                                entityType: 'placement group',
                                entityName: placementGroup.name,
                                entityId: placementGroup
                            },
                            onDelete: function onDelete(placementGroup) {
                                PlacementGroupService.deletePlacementGroup(placementGroup.placementGroupId, placementGroup.campaignId).then(
                                    function onSuccess() {
                                        PubSubService.publishTypeDeleted(PubSubService.TYPES.CONTENT.PLACEMENT_GROUP, {id: placementGroup.placementGroupId});
                                    },
                                    function onError(){}
                                );
                            },
                            onCancel: function onCancel() {}
                        };
                    };

                    ModalManagementService.openDialog(
                        'DeleteDialogController',
                        'ui/globalui/deleteDialog/deleteDialog.tpl.html',
                        dialogsModel,
                        {'css': 'modal'}
                    );

                };

                /**
             * Returns a active linked ads to a placement
             */
                vm.getActiveAds = function getActiveAds(placement) {
                    return _.where(placement.linkedAds, {'active': true});
                };

                /**
             * Checks if there are active ads for this placement
             */
                vm.hasActiveAds = function hasActiveAds(placement) {
                    var activeLinkedAds = vm.getActiveAds(placement);
                    return !_.isEmpty(activeLinkedAds);
                };

                /**
             * Gets the ad details for the linked ads
             * @param ads - the collection of ads that has the full ad details
             * @param linkedAds - the ads in which we are interested
             */
                vm.getAdDetails = function (ads, linkedAds) {
                    return _.filter(ads, function (ad) {
                        return _.contains(_.pluck(linkedAds, 'adId'), ad.adId);
                    });
                };


                /**
             * Checks whether a placement has errors.
             * A placement has an error:
             * 1. If it has been synced before but currently doesn't have any linked ads.
             * 2. All ads have conditions (so, there is no default ad)
             * 3. An ad has no condition and blocks other ads. Other ads will be considered blocked only if they have
             * creatives for this placement size
             * @param {Object} placement - the placement which we are validating
             * @param {Object} campaign - the campaign to which the placement belongs
             */
                vm.validatePlacement = function (placement, campaign) {
                    placement.error = undefined;

                    var activeLinkedAds = vm.getActiveAds(placement);

                    // Verify that placement has been synced and that it has linked ads
                    if (_.isEmpty(activeLinkedAds)) {
                    // Placement doesn't have linked ads, check syncDate
                        if (!_.isNull(placement.syncDate)) {
                            placement.error = {
                                message: 'No ads defined. The placement is still active on the server'
                            };
                        }

                    } else {

                        // Get the detailed ads
                        var activeAds = vm.getAdDetails(campaign.ads, activeLinkedAds);

                        // Check if all of the active ads have conditions. If that's the case, then there is no default ad
                        // and there should be a error
                        var adsWithConditions = _.filter(activeAds, function (fa) {
                            return !_.isUndefined(fa.conditions) && !_.isEmpty(fa.conditions);
                        });

                        if (adsWithConditions.length === activeAds.length) {
                            placement.error = {
                                message: 'No default ad found. All ads have conditions'
                            };
                        } else {
                        // Look for blocked ads. For each of the active ads:
                        // 1. get the successor ads
                        // 2. if the current ad doesn't have any conditions and there successor ads, display error only
                        // if any of the successor ads have creatives
                            for (var i = 0; i < activeAds.length; i++) {
                                var followingAds = activeAds.slice(i + 1, activeAds.length);

                                // If current ad has no conditions but there are ads after it
                                if (_.isEmpty(activeAds[i].conditions) && !_.isEmpty(followingAds)) {

                                    // Check if the following ads have creatives
                                    var creativesList = _.pluck(followingAds, 'creatives');
                                    var hasCreatives = false;
                                    for (var idx = 0; idx < creativesList.length; idx++) {
                                        if (!_.isEmpty(creativesList[idx])) {
                                            hasCreatives = true;
                                            break;
                                        }
                                    }
                                    if (hasCreatives) {
                                        placement.error = {
                                            message: 'Ad "' + activeAds[i].name + '" has no conditions which blocks ' + followingAds.length + ' following ads'
                                        };
                                        break;
                                    }
                                }
                            }
                        }
                    }
                };

                /**
             * callback function structure for editing a placement group
             */
                vm.pgNameOverrideOptions = {
                    editSuccessCallback: function (newValue, oldValue, editableFieldName, editableObjName) {

                        var placementGroupIndex = editableFieldName.substring(0, editableFieldName.lastIndexOf('.'));
                        var placementGroup = Utils.getScopeValue(editableObjName.$parent, placementGroupIndex, true);

                        return PlacementGroupService.updatePlacementGroupName(
                            placementGroup.placementGroupId,
                            placementGroup.campaignId,
                            newValue
                        );
                    }
                };

                /**
             * Publishes a campaign
             * @param campaign
             */
                vm.publishCampaign = function (campaign) {
                    CampaignService.publishCampaign(campaign.campaignId).then(
                        function onSuccess() {
                            CampaignService.getCampaign(campaign.campaignId).then(
                                function onSuccess(c) {
                                    vm.campaign = c;
                                    vm.campaign.publishedSuccess = true;
                                    PubSubService.publishTypeChanged(PubSubService.TYPES.CONTENT.CAMPAIGN, c);
                                },
                                function onError() {}
                            );
                        },
                        function onError() {
                            vm.campaign.publishedSuccess = false;
                        }
                    );
                };

                $scope.$watch('vm.campaign.placementGroups', function(newVal){
                    vm.campaign.hasErrors = validatePlacementGroups(newVal);
                }, true);

                /**
             * @private
             * @param placementGroups
             */
                var validatePlacementGroups = function validatePlacementGroups(placementGroups) {
                    var hasErrors = false;
                    _.forEach(placementGroups, function (placementGroup) {
                        _.forEach(placementGroup.placements, function (placement) {
                            vm.validatePlacement(placement, vm.campaign);
                            if (!_.isUndefined(placement.error)) {
                                hasErrors = true;
                            }
                        });
                    });
                    return hasErrors;
                // The campaign should be marked with error if any of the placement groups contains invalid placements
                // vm.campaign.hasErrors = hasErrors;
                };

                PubSubService.publishTypeSelected(PubSubService.TYPES.CONTENT.CAMPAIGN, {id: vm.campaign.campaignId});

                /**
             * Display a notification message when an ad is copied
             */
                PubSubService.subscribeAdCopied($scope, function (event, message) {
                    vm.adCopiedTo = {
                        adId: message.adId,
                        adName: message.name,
                        // The details of the campaign to which the ad was copied to
                        campaignId: message.campaignId,
                        campaignName: message.campaignName
                    };
                    // Remove the "Ad copied" notification message after 20s
                    $timeout(function () {
                        vm.adCopiedTo = undefined;
                    }, 20000);
                    // Reload the campaign if the ad was sopied to it
                    if (_.isEqual(vm.campaign.campaignId, message.campaignId)) {
                        CampaignService.getCampaign(vm.campaign.campaignId).then(function (result) {
                            vm.campaign = result;
                        });
                    }
                });

                /**
             * Reload campaign view if its content has changed
             */
                PubSubService.subscribeCampaignContentChanged($scope, function () {
                    CampaignService.getCampaign(vm.campaign.campaignId).then(function (result) {
                        vm.campaign = result;
                    });
                });
            }
        ]);
