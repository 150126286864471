import React from 'react';
import { useAPI } from '~/common/ApiHooks';
import Notification from '~/components/src/Notification';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { addTransition, fetchAllowedTransitions } from '~/workflows/dataService';
import { TransitionModalContentProps } from './types';

const TransitionModalContent = ({
  journeyId,
  triggerId,
  refresh,
  hideModal,
}: TransitionModalContentProps): JSX.Element => {
  const { data, isLoading } = useAPI(() => fetchAllowedTransitions(journeyId, triggerId));

  const allowedTransitionTargets = data || [];

  if (isLoading) return <Spin className="my-10" />;

  return (
    <div className="flex !max-h-[500px] min-h-[500px] flex-col">
      {allowedTransitionTargets.length ? (
        <Notification kind="information">
          {i18n.t('workflow:orchestration.common.modal.notAllStepsAreAvailableForTransition')}
        </Notification>
      ) : (
        <Notification>{i18n.t('workflow:orchestration.common.modal.noStepsAvailableForTransition')}</Notification>
      )}
      <div className="my-2 flex flex-col gap-2 overflow-y-auto">
        {allowedTransitionTargets.map(step => (
          <div
            key={step.stepId}
            className="t-stepName cursor-pointer border border-transparent pb-1 pl-2 pr-2 pt-1 hover:rounded hover:border-gray-800"
            onClick={() => {
              addTransition(journeyId, triggerId, step.stepId).then(() => {
                refresh();
                hideModal();
              });
            }}
          >
            <span className="text-lg text-gray-600 hover:text-gray-800">{step.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransitionModalContent;
