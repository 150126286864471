import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Breadcrumb from '~/components/src/Breadcrumb';
import Icon from '../Icon';
import '../Aligner';
import './styles.scss';
import Badge from '../Badge';
import Tooltip from '../Tooltip';

type IKind = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const titlePart = (
  title?: string,
  kind?: IKind,
  icon?: string,
  img?: string,
  imgClassName?: string,
  crumbs?: ICrumb[],
  deactivated?: boolean,
  badge?: string | number,
  truncateHeader?: boolean,
) => {
  if (!title) return <span />;

  const HeadingLevel = kind || 'h1';

  return (
    <HeadingLevel className={cx('Heading-title flex items-start text-xl text-gray-600 dark:text-slate-200')}>
      {img && (
        <img
          alt={title}
          className={cx('mr-4 h-12 w-12', imgClassName, { 'opacity-50 grayscale': deactivated })}
          src={img}
        />
      )}
      {icon && <Icon iconClass={icon} />}
      <div className="flex flex-col items-start justify-center">
        <Tooltip tooltip={truncateHeader ? title : ''}>
          <p className="flex items-center gap-4">
            <span
              className={cx('text-gray-600', {
                'text-slate-500 line-through dark:text-slate-300': deactivated,
                'w-[500px] truncate': truncateHeader,
              })}
            >
              {title}
            </span>

            {badge && <Badge className="" content={badge} color="blue" testHook="headerBadge" />}
            {deactivated && <Badge content="Deactivated" testHook="deactivated" />}
          </p>
        </Tooltip>
        {crumbs && <Breadcrumb crumbs={crumbs} />}
      </div>
    </HeadingLevel>
  );
};

interface ICrumb {
  title: string;
  pathname?: string;
  onClick?: (...args: any[]) => void;
}

interface HeadingProps {
  children?: ReactNode;
  icon?: string;
  img?: string;
  imgClassName?: string;
  title?: string;
  kind?: IKind;
  className?: string;
  crumbs?: ICrumb[];
  badge?: string | number;
  testHook?: string;
  style?: any;
  deactivated?: boolean;
  truncateHeader?: boolean;
}

const Heading = ({
  icon,
  img,
  imgClassName,
  title,
  children,
  kind,
  className,
  crumbs,
  style,
  badge,
  testHook = 'heading',
  deactivated = false,
  truncateHeader = false,
}: HeadingProps): ReactElement => (
  <div
    className={twMerge(
      cx(
        'Heading flex flex-col items-start justify-between gap-4 py-6 md:flex-row md:items-center md:gap-0',
        className,
        `t-${testHook}`,
      ),
    )}
    style={style}
  >
    <div className="flex flex-col dark:text-white">
      {titlePart(title, kind, icon, img, imgClassName, crumbs, deactivated, badge, truncateHeader)}
    </div>
    <div className="flex flex-1 items-center justify-end gap-4">{children}</div>
  </div>
);

export default Heading;
