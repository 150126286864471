// ATTENTION
// This file is a general wrapper for all connectors used in Audience / JO pages. It does all the communication "magic" between formik and list component.
// Make sure that any changes in this file will affect logic for all connectors.
import React, { Fragment } from 'react';
import { Formik, Form } from 'formik';
import { ContextEffect } from '~/common/containers/ContextEffect';
import './ConnectorGenericForm.scss';

const formTransformations = (connectorFormProps, changedIntegration) => () => {
  const form = {
    ...connectorFormProps.values,
    connectorFormProps,
  };
  return { form, changedIntegration };
};

// This function isn't just random terrible decision and was added for a reason
// Formik couldn't emit the onChange event for the whole form, so the only way is to listen to changes
// to the formik-generated props in it's render method.
// just calling callback function in render cost less code but triggers loop rendering
// so we need to filter out actual changes
// Formik creators don't consider it as an issue. Libraries which solve it do the same what this function
// REFERENCES:
// https://github.com/jaredpalmer/formik/issues/271
// https://github.com/jaredpalmer/formik/pull/1397
// https://github.com/jaredpalmer/formik-persist
// https://github.com/jaredpalmer/formik/issues/1619
// https://github.com/jaredpalmer/formik-effect
function FormWrapper({ connector, connectorFormProps, children }) {
  if (connectorFormProps.values === undefined) {
    return null;
  }
  return (
    <Form name={`integrations.${connector.partnerDetails.partnerType}`} className="ConnectorGenericForm--FormBody">
      {/* Don't try to use onChange on form, it will NOT work. By the moment of execution it has outdated props and nasty bug which is hard to spot */}
      {typeof children === 'function' && children(connectorFormProps)}
    </Form>
  );
}

export const ConnectorGenericForm = ({ onEdit, connector, children, validations, isSubmitted }) => (
  <div className="ConnectorGenericForm">
    <Formik
      name={`integrations.${connector.partnerDetails.partnerType}`}
      initialValues={connector.partnerDetails || {}}
      validationSchema={validations}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {connectorFormProps => (
        <Fragment>
          <ContextEffect
            onChange={onEdit}
            formTransformations={formTransformations(connectorFormProps, connector)}
            effectProps={[connectorFormProps.isValid]}
            shouldChangeOnInit={connector.isNew}
            isSubmitted={isSubmitted}
            submitCount={connectorFormProps.submitCount}
            setTouched={connectorFormProps.setTouched}
            errors={connectorFormProps.errors}
          />
          <FormWrapper connector={connector} connectorFormProps={connectorFormProps}>
            {children}
          </FormWrapper>
        </Fragment>
      )}
    </Formik>
  </div>
);
