/* eslint-disable no-prototype-builtins */
/**
 * Tag Publishing Service
 *
 * @namespace Tagmanagement
 * @description Service for tags publishing
 */
angular.module('webUi.service.tagmanagement.publishing',
    [
        'restangular',
        'webUi.common.Utils',
        'webUi.service.tagmanagement'
    ]
)
/**
 *
 */
    .factory('TagmanagementPublishingService', ['Restangular', '$q', 'Utils', 'PubSubService', function(Restangular, $q, Utils, PubSubService) {

        // the base path through which all calls should go
        var basePath = 'tagmanagement/publishing';


        // sandbox id
        var sandboxId = '00000000-0000-0000-0000-000000000000';

        /**
     *
     * @param snapshotDiff
     * @param sortByField
     * @returns {Array}
     */
        var flattenDiff = function (snapshotDiff, sortByField) {

            var diffElements = [];

            // accept all changes by default
            if(snapshotDiff && snapshotDiff.addedElements) {
                _.forEach(snapshotDiff.addedElements, function(elem) {
                    elem.changeType = 'Added';
                    diffElements.push(elem);
                });
            }

            if(snapshotDiff && snapshotDiff.updatedElements) {
                _.forEach(snapshotDiff.updatedElements, function(elem) {
                    elem.changeType = 'Updated';
                    diffElements.push(elem);
                });
            }

            if(snapshotDiff && snapshotDiff.removedElements) {
                _.forEach(snapshotDiff.removedElements, function(elem) {
                    elem.changeType = 'Removed';
                    diffElements.push(elem);
                });
            }

            if (sortByField) {
                diffElements = _.sortBy(diffElements, sortByField);
            }
            return diffElements;
        };

        /**
     *
     * @param description
     * @param elements
     * @returns {*}
     */
        var publish = function(description, elements) {

            var elementIds = [];

            _.each(elements, function(elem) {
                elementIds.push(elem.elementId);
            });
            var def = $q.defer();

            Restangular.all(basePath).post({description: description, elements: elementIds}).then(function(res){
                def.resolve(res);
                PubSubService.publishEnvironmentChange();
            });

            return def.promise;
        };

        /**
     *
     * @param elements
     * @returns {*}
     */
        var addLinks = function(elements) {
            _.forEach(elements, function(element) {
                if(element.elementType === 'TAG') {
                    element.elementLink = '/tagmanagement/tags/view/' + element.elementId + '?snapshotId=' + element.snapshotId;
                } else if(element.elementType === 'EXPERIMENT') {
                    element.elementLink = '/tagmanagement/experiments/view/' + element.elementId + '?snapshotId=' + element.snapshotId;
                } else if(element.elementType === 'WEBSITEPATHGROUP') {
                    element.elementLink = '/tagmanagement/tags/websitePathGroups/' + element.elementId;
                } else if(element.elementType === 'COOKIEPERMISSION') {
                    element.elementLink = '/tagmanagement/cookiePermission/view/' + element.elementId;
                }
            });

            return elements;
        };

        /**
     *
     * @param configurationSnapshotTo
     * @param configurationSnapshotFrom
     * @returns {*}
     */
        var getDiff = function(configurationSnapshotTo, configurationSnapshotFrom) {
            var deferred = $q.defer();
            if(_.isUndefined(configurationSnapshotFrom)) {
                Restangular.one(basePath, 'diff').get({to: configurationSnapshotTo}).then(function(res){
                    res = Utils.sanitizeRestangularOne(res);
                    deferred.resolve(res);
                });
                return deferred.promise;
            }

            Restangular.one(basePath, 'diff').get({to: configurationSnapshotTo, from: configurationSnapshotFrom}).then(function(res){
                res = Utils.sanitizeRestangularOne(res);
                deferred.resolve(res);
            });

            return deferred.promise;
        };

        /**
     *
     * @returns {*}
     */
        var getLatestDiff = function() {
            return Restangular.one(basePath, 'latestDiff').get();
        };


        /**
     *
     * @returns {*}
     */
        var getPublishHistory = function() {
            return Restangular.one(basePath, 'publishHistory').get();
        };

        /**
     *
     * @returns {*}
     */
        var getReleaseHistory = function() {
            return Restangular.one(basePath, 'releaseHistory').get();
        };

        /**
     *
     * @returns {*}
     */
        var getTagUsers = function(){
            return Restangular.one(basePath, 'tagUsers').get();
        };

        /**
     *
     * @param release
     * @returns {*}
     */
        var getDeploymentHash = function(release){
            return Restangular.all(basePath + '/deploymentHash').post({
                snapshotId: release.snapshotId,
                environmentId: release.environmentId,
                releaseDate: release.releaseDate
            });
        };

        /**
     *
     * @returns {*}
     */
        var getUnpublishedChanges = function(){
            var defered = $q.defer();

            this.getLatestDiff().then(
                function doneCallback(result) {
                    if (result) {
                        var changes = result;
                        var totalChanges = 0;

                        if (
                            changes &&
      changes.hasOwnProperty('addedElements') &&
      changes.hasOwnProperty('updatedElements') &&
      changes.hasOwnProperty('removedElements')
                        ) {
                            totalChanges += changes.addedElements.length;
                            totalChanges += changes.updatedElements.length;
                            totalChanges += changes.removedElements.length;
                        }

                        defered.resolve(totalChanges);
                    }
                },
                function failCallback() {}
            );
            return defered.promise;
        };

        /**
     * RMP API
     */
        return {
        //Props
            basePath: basePath,
            sandboxId: sandboxId,

            //Functions
            flattenDiff: flattenDiff,
            publish: publish,
            addLinks: addLinks,
            getDiff: getDiff,
            getLatestDiff: getLatestDiff,
            getPublishHistory: getPublishHistory,
            getReleaseHistory: getReleaseHistory,
            getTagUsers: getTagUsers,
            getDeploymentHash: getDeploymentHash,
            getUnpublishedChanges: getUnpublishedChanges
        };
    }]);

