/**
 * Render a CodeMirror block for viewing/editing Javascript or HTML code, with optional validation
 */
angular.module('webUi.directive.codemirror', ['ui.codemirror'])

    .directive('svCodemirror', ['Utils', 'CodeMirrorService', '$timeout', function (Utils, CodeMirrorService, $timeout) {
        return {
            scope: {
                cmModel: '@svCodemirror',
                options: '=',
                mode: '@',
                validation: '=?'
            },
            link: function postLink($scope, $element) {

                // codemirror vars
                var cm, cmOptions;

                function calledFromSpecialFunction(codeMirror, functionName, result) {
                    var currentLine = codeMirror.getLine(result.to.line);
                    var minimumSize = functionName.length;
                    if (currentLine.length < minimumSize) {
                        return false;
                    }
                    for (var i = result.to.ch - 1; i >= (result.to.ch - 1) - minimumSize; i--) {
                        var ch = codeMirror.getLine(result.to.line)[i];
                        if (ch === ' ') {
                            return false;
                        }
                        if (ch === '.' && functionNamePrecedesPeriod(minimumSize, currentLine, i, functionName)) {
                            return true;
                        }
                    }
                    return false;
                }

                function functionNamePrecedesPeriod(minimumSize, line, currentPos, functionName) {
                    var stringToCheck = line.substring(currentPos - minimumSize + 1, currentPos);
                    return (stringToCheck + '.' === functionName);
                }

                function filterTrackerAutoCompleteResults(r) {
                    jQuery.each(r.list, function (idx, value) {
                        switch (value) {
                            case 'getProperty':
                                r.list[idx] = 'getProperty(\'\')';
                                break;
                            case 'getExtracted':
                                r.list[idx] = 'getExtracted(\'\')';
                                break;
                            case 'insertScript':
                                r.list[idx] = 'insertScript(\'\')';
                                break;
                            case 'insertScriptCallback':
                                r.list[idx] = 'insertScriptCallback(\'\', function() {  })';
                                break;
                            case '_stTracker':
                                break;
                            case 'insertHtml':
                                r.list[idx] = 'insertHtml(\'\', \'body\')';
                                break;
                            default:
                                r.list[idx] = r.list[idx] + '()';
                        }
                    });
                    return r;
                }

                function defaultHandleChange(scope, cm){
                    scope.setModelValue(cm.getValue());
                    return true;
                }

                //@see http://marijnhaverbeke.nl/blog/codemirror-mode-system.html
                CodeMirror.defineMode('tagplaceholder', function (config, parserConfig) {
                    var overlay = {
                        startState: function () {
                            return {
                                inPlaceholder: false,
                                shouldInsertSpacer: false,
                                placeholderType: ''
                            };
                        },

                        token: function (stream, state) {

                            var left = CodeMirrorService.LEFT;
                            var right = CodeMirrorService.RIGHT;

                            if (!state.inPlaceholder && stream.peek() === left) {
                            // handle <<
                                stream.eat(left);
                                // read placeholder
                                state.placeholderType = stream.next();
                                stream.eat(CodeMirrorService.SEPARATOR);

                                state.inPlaceholder = true;

                                return 'tagPlaceholder-left ' + state.placeholderType;
                            }

                            if (state.inPlaceholder) {

                                if (stream.peek() === right) {
                                    stream.next();
                                    state.inPlaceholder = false;
                                    return 'tagPlaceholder-right ' + state.placeholderType;
                                } else {
                                    stream.next();
                                    return 'tagPlaceholder ' + state.placeholderType;
                                }

                            } else {
                                if(!stream.skipTo(left)) {
                                    stream.skipToEnd();
                                }
                                return null;
                            }
                        }
                    };

                    var mode = CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || 'javascript'), overlay);

                    return mode;
                });

                $scope.setModelValue = function setModelValue(value) {
                    function setScopeValue(value) {
                        var currentScope = $scope;
                        var lastError;
                        while(currentScope !== null) {
                            currentScope = currentScope.$parent;
                            try {
                                Utils.setScopeValue(currentScope, $scope.cmModel, value);
                                Utils.applyIfPossible(currentScope);
                                return;
                            } catch (e) {
                                lastError = e;
                            }
                        }
                        throw lastError;
                    }

                    $scope.resolvedModel = value;
                    setScopeValue(value);
                };

                $scope.validation = $scope.validation || {};

                var completionContext = {
                    _st: window._st,
                    location: window.location,
                    document: window.document
                };
                completionContext.window = completionContext;

                //TODO complete this, ask @koen
                $scope.optionsHtml = {
                    mode: 'htmlmixed',
                    theme: 'eclipse',
                    readOnly: false,
                    lineNumbers: true,
                    indentWithTabs: true,
                    styleSelectedText: true,
                    indentUnit: 4,
                    showCursorWhenSelecting: true,
                    matchBrackets: true,
                    tabMode: 'shift',
                    gutters: ['CodeMirror-lint-markers', 'CodeMirror-linenumbers'],
                    onChange: function onChange(cm) {
                    // Update the original value manually since CodeMirror doesn't do it for us.
                        return defaultHandleChange($scope, cm);
                    },
                    refreshContents: function(code) {
                        cm.setValue(code);
                        if(!($scope.$$phase || $scope.$root.$$phase)) {
                            $scope.$apply();
                        }

                        // reset history
                        setTimeout(function() {
                            cm.clearHistory();
                        }, 900);

                        return true;
                    }
                };

                var errorCodesToIgnore = ['W069', 'W080', 'W083'];

                $scope.defaultOptions = {
                    mode: 'tagplaceholder',
                    theme: 'eclipse',
                    readOnly: false,
                    lineNumbers: true,
                    viewportMargin: Infinity,
                    indentWithTabs: true,
                    styleSelectedText: true,
                    indentUnit: 4,
                    showCursorWhenSelecting: true,
                    matchBrackets: true,
                    tabMode: 'shift',
                    completionContext: completionContext,
                    extraKeys: {
                        'Ctrl-Space': function CtrlSpace(cm) {
                            CodeMirror.showHint(cm, function showHintCallback(e) {
                                var result = CodeMirror.hint.tagplaceholder(e);
                                if (calledFromSpecialFunction(cm, '_stTracker.', result)) {
                                    return filterTrackerAutoCompleteResults(result);
                                }
                                return result;
                            });
                        },
                        'Shift-Tab': 'indentLess'
                    },
                    lintWith: function(text, options) {
                    // enable es3

                        options = options || { esversion: 6, enforcing: { futurehostile: false }, relaxing : { loopfunc: false, sub: false }};

                        var errors = CodeMirror.lint.javascript(text, options);

                        errors = _.reject(errors, function(err) {
                            return _.contains(errorCodesToIgnore, err.code);
                        });

                        angular.forEach(errors, function(error, i) {
                            if (error.message === 'Missing semicolon.') {
                                errors[i].severity = 'warning';
                            }
                        });

                        $scope.validation['errors'] = _.filter(errors, {severity: 'error'});
                        $scope.validation['warnings'] = _.filter(errors, {severity: 'warning'});

                        $scope.lintInProgress = false;

                        if(!($scope.$$phase || $scope.$root.$$phase)) {
                            $scope.$apply();
                        }
                        return errors;
                    },
                    gutters: ['CodeMirror-lint-markers', 'CodeMirror-linenumbers'],
                    // Custom properties
                    onChange: function onChange(cm) {
                        $scope.lintInProgress = true;
                        defaultHandleChange($scope, cm);
                        // If placeholder is inserted generate tagPlaceholders to make them atomic and stuff
                        if(cm.display.prevInput && CodeMirrorService.PLACEHOLDER_REGEX.exec(cm.display.prevInput)) {
                            CodeMirrorService.generateTagPlaceholders(cm);
                        }

                        return true;
                    },
                    refreshContents: function(code) {
                        $scope.lintInProgress = true;
                        cm.setValue(code);
                        if(!($scope.$$phase || $scope.$root.$$phase)) {
                            $scope.$apply();
                        }
                        CodeMirrorService.generateTagPlaceholders(cm);

                        // reset history
                        setTimeout(function() {
                            cm.clearHistory();
                        }, 900);

                        return true;
                    }

                };

                if($scope.mode === 'html') {
                    cmOptions = $.extend(true, $scope.optionsHtml, $scope.options);
                } else {
                    cmOptions = $.extend(true, $scope.defaultOptions, $scope.options);
                }

                cm = CodeMirror.fromTextArea($($element).find('textarea')[0], cmOptions);
                $scope.lintInProgress = false;

                cm.$scope = $scope;

                if (!CodeMirror.hint.tagplaceholder) {
                    var tagPlaceholderHint = function tagPlaceholderHint(editor){
                        var completionOptions = {
                            completionContext: cmOptions.completionContext
                        };
                        return CodeMirrorService.scriptHint(editor, completionOptions);
                    };
                    CodeMirror.registerHelper('hint', 'tagplaceholder', tagPlaceholderHint);
                }

                cm.on('change', cmOptions.onChange);
                // Function exposed to controller, to check if lint is in progress
                $scope.validation.getIsLintingProgress = function() {
                    return $scope.lintInProgress;
                };

                $($element).addClass('codemirror-tag-container');
                $($element).data('codemirrorInstance', cm);
                $scope.$parent.$watch($scope.cmModel, function(value) {
                    $scope.lintInProgress = true;

                    // only refresh when value is different
                    if(value && value !== cm.getValue()) {
                        cmOptions.refreshContents(value);
                    }
                });
                $timeout(function() {
                    cm.refresh();
                });
            },
            templateUrl: function() {
                return 'directive/codemirror/codemirror.tpl.html';
            }
        };
    }]);
