import React, { ReactNode } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router';

export interface RoutedProps {
  location: Location;
  navigate: NavigateFunction;
  params: URLSearchParams;
}

export function withRouter<P extends Partial<RoutedProps>>(Child: React.ComponentType<P>): ReactNode {
  return (props: Omit<P, keyof RoutedProps>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Child {...(props as P)} navigate={navigate} location={location} params={params} />;
  };
}
