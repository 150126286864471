import Bool from './Bool';
import Code from './Code';
import Default from './Default';

export const Detail = Default;

export default {
  Bool,
  Code,
  Default,
};
