import React, { useState } from 'react';
import cx from 'classnames';
import { buildUrl, changeUrl, useAPI } from '~/common';
import i18n from '~/i18n';
import { PickerView } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import Spin from '~/components/src/Spin';
import { getCookiePermissionsVariants } from '../dataService';
import { TCookiePermissionsVariants } from '../types';

type TCookiePermissionVariantModalProps = {
  setShowVariantModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const onConfirm = (selectedVariant: TCookiePermissionsVariants | null) =>
  changeUrl(
    buildUrl(`tagmanagement/cookiePermission/add?variantId=${selectedVariant?.cookiePermissionVariantId}`),
    true,
    true,
  );

const CookiePermissionVariantModal = ({ setShowVariantModal }: TCookiePermissionVariantModalProps) => {
  const [selectedVariant, setSelectedVariant] = useState<TCookiePermissionsVariants | null>(null);
  const { data, isLoading } = useAPI(() => getCookiePermissionsVariants());

  return (
    <PickerView
      pickerTitle={i18n.t('cookie:cookiePermissionVariant.header')}
      handlePickerVisibility={() => {
        setShowVariantModal(false);
      }}
      className="!h-auto !w-[30%]"
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <div className="t-variants">
            {data?.map(item => (
              <div
                className={cx('mt-2 flex cursor-pointer flex-col gap-1 rounded-md border p-4', {
                  'bg-gray-200': selectedVariant?.name === item?.name,
                })}
                onClick={() => {
                  setSelectedVariant(item);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>

          <ActionButtons
            onConfirm={() => onConfirm(selectedVariant)}
            isConfirmEnabled={!!selectedVariant}
            confirmText={i18n.t('cookie:cookiePermissionVariant.select')}
            onDecline={() => {
              setSelectedVariant(null);
              setShowVariantModal(false);
            }}
            testHook="addCookiePermissionGroup"
          />
        </>
      )}
    </PickerView>
  );
};

export default CookiePermissionVariantModal;
