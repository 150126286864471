import React from 'react';

import { Field, FormikErrors, useFormikContext } from 'formik';

import i18n from '~/i18n';
import FormikCheckbox from '~/components/src/FormikCheckbox';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';

import { Model, OptionType } from '../types';
import { TARGET_LABEL_MODES } from '../constants';

const ModelAdvanceSettings = ({
  values,
  errors,
}: {
  values: Model;
  errors: FormikErrors<Model>;
}): React.ReactElement => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <FormikCheckbox
        id="hasAdvancedSettings"
        name="hasAdvancedSettings"
        checked={values.hasAdvancedSettings}
        onChange={() => setFieldValue('hasAdvancedSettings', !values.hasAdvancedSettings)}
        text={i18n.t('ai:new.advancedSetting')}
      />
      <br />
      <br />
      {values.hasAdvancedSettings && (
        <>
          <div className="Models-form1">
            <Field
              name="advancedSettings.trainForMaxEpochs"
              type="number"
              min={0}
              as={FormikInputField}
              label={i18n.t('ai:new.trainForMaxEpochs')}
              placeholder={i18n.t('ai:new.placeholder')}
              errorText={errors.advancedSettings?.trainForMaxEpochs}
              required
            />
            <Field
              name="advancedSettings.predictionDays"
              type="number"
              min={1}
              as={FormikInputField}
              label={i18n.t('ai:new.predictionDays')}
              placeholder={i18n.t('ai:new.placeholder')}
              errorText={errors.advancedSettings?.predictionDays}
              required
            />
            <Field
              name="advancedSettings.stopIfNotImprovedAfterEpochs"
              type="number"
              min={0}
              as={FormikInputField}
              label={i18n.t('ai:new.stopIfNotImprovedAfterEpochs')}
              placeholder={i18n.t('ai:new.placeholder')}
              errorText={errors.advancedSettings?.stopIfNotImprovedAfterEpochs}
              required
            />
            <Field
              name="advancedSettings.targetLabelMode"
              as={FormikSelectField}
              label={i18n.t('ai:new.targetLabelMode')}
              errorText={errors.advancedSettings?.targetLabelMode}
              getOptionLabel={(option: { label: string; value: string }) => option.label}
              getOptionValue={(option: { label: string; value: string }) => option.value}
              customSetFieldValue={(name: string, option: OptionType) => setFieldValue(name, option.value)}
              options={TARGET_LABEL_MODES}
            />
            <Field
              name="advancedSettings.numObservationsPerClass"
              type="number"
              min={0}
              as={FormikInputField}
              label={i18n.t('ai:new.numObservationsPerClass')}
              placeholder={i18n.t('ai:new.placeholder')}
              errorText={errors.advancedSettings?.numObservationsPerClass}
              required
            />
          </div>
          <div className="Models-form2">
            <FormikCheckbox
              id="keepUnlabeledSequences"
              name="advancedSettings.keepUnlabeledSequences"
              disabled={false}
              checked={values.advancedSettings?.keepUnlabeledSequences}
              onChange={() =>
                setFieldValue(
                  'advancedSettings.keepUnlabeledSequences',
                  !values.advancedSettings?.keepUnlabeledSequences,
                )
              }
              text={i18n.t('ai:new.keepUnlabeledSequences')}
            />
            <FormikCheckbox
              id="isUnlabeledFromSingleClass"
              name="advancedSettings.isUnlabeledFromSingleClass"
              disabled={false}
              checked={values.advancedSettings?.isUnlabeledFromSingleClass}
              onChange={() =>
                setFieldValue(
                  'advancedSettings.isUnlabeledFromSingleClass',
                  !values.advancedSettings?.isUnlabeledFromSingleClass,
                )
              }
              text={i18n.t('ai:new.isUnlabeledFromSingleClass')}
            />
            <FormikCheckbox
              id="breakSequencesOnTarget"
              name="advancedSettings.breakSequencesOnTarget"
              disabled={false}
              checked={values.advancedSettings?.breakSequencesOnTarget}
              onChange={() =>
                setFieldValue(
                  'advancedSettings.breakSequencesOnTarget',
                  !values.advancedSettings?.breakSequencesOnTarget,
                )
              }
              text={i18n.t('ai:new.breakSequencesOnTarget')}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ModelAdvanceSettings;
