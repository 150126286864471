import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CampaignsDashboard from './CampaignsDashboard';

const CampaignsPages = () => (
  <Routes>
    <Route path="dashboard" element={<CampaignsDashboard />} />
    <Route path="*" element={<Navigate to="dashboard" replace />} />
  </Routes>
);

export default CampaignsPages;
