import React from 'react';
import cx from 'classnames';
import ModalHeader from '~/modals/containers/ModalHeader';

const SIZE_CLASSNAMES = {
  small: 'w-[500px]',
  large: 'w-[840px]',
};

export interface ModalWindowProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  closeModal: () => void;
  size?: 'small' | 'large';
}

const ModalWindow = ({ title, closeModal, className, children, size = 'large' }: ModalWindowProps): JSX.Element => (
  <div className="ModalWindow-background" onClick={closeModal}>
    <div
      className={cx('ModalWindow-window max-h-[48rem]', SIZE_CLASSNAMES[size], className)}
      onClick={clickEvent => {
        clickEvent.stopPropagation();
      }}
    >
      <ModalHeader title={title} onCloseClick={closeModal} />
      <div className="ModalWindow-content">{children}</div>
    </div>
  </div>
);

export default ModalWindow;
