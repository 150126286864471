import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { reduxForm } from 'redux-form';
import EngagementForm from '../components/EngagementForm';
import { saveEngagement, fetchEngagements, setUserIsEditing } from '../engagements/actions';
import { getAllEngagementNames, isFetchingEngagements, isUserEditing } from '../engagements/selectors';

const FORM_NAME = 'engagementNew';

export class EngagementNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldFetchEngagements: true };
  }

  componentDidMount() {
    this.props.fetchEngagements();
    this.setState({ shouldFetchEngagements: false });
  }

  componentWillUnmount() {
    this.props.setUserIsEditing(false);
  }

  render() {
    const { isFetching, t, ...otherProps } = this.props;
    if (this.state.shouldFetchEngagements || isFetching) {
      return <Spinner />;
    }
    return (
      <Page>
        <SmallWrapper>
          <Heading title={t('new.header')} />
          <Panel>
            <EngagementForm t={t} {...otherProps} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = state => ({
  engagementNames: getAllEngagementNames(state),
  isFetching: isFetchingEngagements(state),
  cancelHref: buildUrl('profiles/engagements'),
  userIsEditing: isUserEditing(state),
});

const mapDispatchToProps = dispatch => ({
  fetchEngagements: () => dispatch(fetchEngagements()),
  onSubmit: values => dispatch(saveEngagement(values)),
  setUserIsEditing: userIsEditing => dispatch(setUserIsEditing(userIsEditing)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    isNew: true,
    initialValues: {
      propertiesWithUsage: [],
      updatedProperties: [],
    },
  }),
  translate(['engagements']),
)(EngagementNewPage);
