import React, { useEffect, useState } from 'react';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import i18n from '~/i18n';
import BtnIcon from '../BtnIcon';

const animatedComponents = makeAnimated();

const Option = props => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
  </div>
);

const ValueContainer = ({ children, ...props }) => {
  const length = children && children[0]?.length;
  const tmpChildren = [...children];
  if (length && length >= 1) {
    tmpChildren[0] = '';
  }
  return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
};

const Dropdown = ({ children, isOpen, target }) => (
  <div className="MultiSelectColumnFilter-dropdown">
    {target}
    {isOpen ? <div className="MultiSelectColumnFilter-dropdown--menu">{children}</div> : null}
  </div>
);

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

function MultiSelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      row.values[id]?.map(label => options.add(label));
    });
    return Array.from(options.values())?.map(row => ({ label: row, value: row }));
  }, [id, preFilteredRows]);

  const [isOpen, setIsOpen] = useState(false);

  const [optionSelected, setOptionSelected] = useState(filterValue || []);

  useEffect(() => {
    if (!filterValue) {
      setOptionSelected([]);
    }
  }, [filterValue]);

  const handleChange = selected => {
    setOptionSelected(selected);
    setFilter(selected.map(option => option.value || undefined));
  };

  return (
    <FieldWrapper label={i18n.t(`common:tableColumns.${id}`)}>
      <Dropdown
        isOpen={isOpen}
        target={
          <div className="MultiSelectColumnFilter-dropdownField flex items-center">
            <button
              className="MultiSelectColumnFilter-dropdownField--labelButton"
              onClick={() => setIsOpen(!isOpen)}
              isSelected={isOpen}
            >
              {optionSelected?.length ? `${optionSelected?.length || 0} Selected` : 'All'}
            </button>
            {optionSelected?.length > 0 && (
              <BtnIcon icon="close" onClick={() => handleChange([])} testHook="clearLabels" className="mr-2 h-6 w-6" />
            )}
          </div>
        }
      >
        <SelectElement
          options={options}
          value={optionSelected}
          onChange={handleChange}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            ValueContainer,
            animatedComponents,
            DropdownIndicator: null,
            IndicatorSeparator: null,
            ClearIndicator: null,
          }}
          menuIsOpen
          autoFocus
          styles={selectStyles}
          placeholder={i18n.t('journey:labels.search')}
        />
      </Dropdown>
    </FieldWrapper>
  );
}

export default MultiSelectColumnFilter;
