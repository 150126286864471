import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';
import Link from '~/components/src/Link';
import cx from 'classnames';
import UsageIcons from './UsageIcons';
import { ALLOWED_NUMBER_OF_PROPERTIES, ENGAGEMENT_USED_IN } from '../engagements/types';

const { Table, Row, Cell, RowActions, HeaderCell } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('list.empty.header')} body={t('list.empty.body')} />;
const NoResults = ({ t }) => <Empty header={t('list.noResults.header')} body={t('list.noResults.body')} />;

const EngagementRow = ({ engagement, props }) => {
  const { selectedEngagement, canEditProfiles, onEditClick, onViewClick, onDeleteClick, onDependantIconClick, t } =
    props;

  const actions = [
    {
      name: t('common.view'),
      isDisabled: false,
      onClick: () => onViewClick(engagement.engagementId),
      tooltip: t('common.view'),
      icon: () => {},
      testHook: 'view',
    },
    {
      name: t('common.edit'),
      isDisabled: !canEditProfiles,
      onClick: () => onEditClick(engagement.engagementId),
      tooltip: t('common.edit'),
      icon: () => {},
      testHook: 'edit',
    },
    {
      name: t('common.delete'),
      type: 'delete',
      isDisabled: !canEditProfiles || engagement.hasDependants,
      onClick: () => onDeleteClick(engagement),
      tooltip: engagement.hasDependants ? t('edit.removeActionDisabledTooltip') : t('common.delete'),
      icon: () => {},
      testHook: 'delete',
    },
  ];
  const isSelected = selectedEngagement && engagement.engagementId === selectedEngagement.engagementId;

  return (
    <Row
      key={engagement.engagementId}
      className={cx({ isSelected }, `t-${engagement.name}Row`, 't-engagementsListTableRow')}
      withActions
    >
      <Cell className="u-size3of12">
        <Link onClick={() => onViewClick(engagement.engagementId)}>{engagement.name}</Link>
      </Cell>
      <Cell className="u-size3of12">{`${engagement.numberOfProperties}/${ALLOWED_NUMBER_OF_PROPERTIES}`}</Cell>
      <Cell className="u-size3of12">{`${engagement.numberOfUsedProperties}/${engagement.numberOfProperties}`}</Cell>
      <Cell className="u-size3of12">
        <UsageIcons
          dependantTypes={engagement.dependantTypes}
          elementUsedIn={ENGAGEMENT_USED_IN}
          elementId={engagement.engagementId}
          onDependantIconClick={onDependantIconClick}
        />
      </Cell>
      <RowActions actions={actions} className="u-size1of12" />
    </Row>
  );
};

export const EngagementsList = props => {
  const { list, stateKey, t } = props;
  return (
    <Panel>
      <Table stateKey={stateKey} testHook="engagementsListTable">
        <Row>
          <SortableHeader className="u-size3of12" sortKey="name" title={t('list.name')} />
          <SortableHeader
            className="u-size3of12"
            sortKey="numberOfProperties"
            sortFn={numberSort('numberOfProperties')}
            title={t('list.numberOfProperties')}
          />
          <SortableHeader
            className="u-size3of12"
            sortKey="numberOfUsedProperties"
            sortFn={numberSort('numberOfUsedProperties')}
            title={t('list.numberOfUsedProperties')}
          />
          <HeaderCell className="u-size3of12">{t('list.usedIn')}</HeaderCell>
          <HeaderCell className="u-size1of12"> </HeaderCell>
        </Row>
        {list.map(engagement => (
          <EngagementRow key={engagement.engagementId} engagement={engagement} props={props} />
        ))}
      </Table>
    </Panel>
  );
};

EngagementsList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  canEditProfiles: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDependantIconClick: PropTypes.func.isRequired,
  selectedEngagement: PropTypes.object,
};

export default compose(
  translate('engagements'),
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate(),
)(EngagementsList);
