import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, isEmail, isValueUnique } from '~/common';
import Permissions from '~/permissions/containers/PermissionsForm';

const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.users.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUnique(value, itemsList);
};
const isEmailUnique = isFormFieldUnique('username');

export const UserAddForm = ({ site, handleSubmit, submitting, backHref, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        label={t('addNew.email.label')}
        name="email"
        placeholder={t('addNew.email.placeholder')}
        component={ReduxFormInputField}
        validate={[isRequired, defaultMaxInput, isEmail, isEmailUnique]}
        type="text"
      />
      <Field
        name="permissions"
        component={Permissions}
        availablePermissions={site.siteModules && site.siteModules.map(item => (item.name ? item.name : item))}
        t={t}
      />
    </div>
    <FormActions t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

UserAddForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  site: PropTypes.object.isRequired,
  backHref: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};

export default UserAddForm;
