import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm, change } from 'redux-form';
import { buildUrl, getParam } from '~/common';
import {
  saveVariant,
  fetchTagExecutionTypesIfNeeded,
  fetchVariantIfNeeded,
} from '~/support/tagtemplates/variants/actions';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import * as selectors from '~/support/tagtemplates/variants/selectors';
import { getClients, getSiteModules } from '~/support/helpers/clientsHelper';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import TagVariantForm from '~/support/components/TagVariantForm';
import { Panel } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import Btn from '~/components/src/Btn';

const FORM_EDIT = 'tagVariantEdit';

export class TagVariantEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      siteModules: [],
    };
  }

  componentDidMount() {
    const { id, sequenceNr, fetchVariant, fetchExecutionTypes } = this.props;
    getClients(document).then(clients => {
      this.setState({ clients });
    });
    getSiteModules(document).then(siteModules => {
      this.setState({ siteModules });
    });
    fetchVariant(id, sequenceNr);
    fetchExecutionTypes();
  }

  render() {
    const { t, ...other } = this.props;
    const { saveNew, handleSubmit } = this.props;

    const SaveNewPart = (
      <Btn color="blue" onClick={() => saveNew(handleSubmit)}>
        {t('variantEdit.saveNew')}
      </Btn>
    );

    return !other.initialValues ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={t('variantEdit.header')} />
        <Panel>
          <TagVariantForm
            t={t}
            clients={this.state.clients}
            siteModules={this.state.siteModules}
            extendActions={SaveNewPart}
            {...other}
          />
        </Panel>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps.params.variantId;
      const sequenceNr = getParam(ownProps.location.search, 'sequenceNr');
      const cancelDefaultHref = `support/tagtemplates/variants/view/${id}`;
      const cancelHref = buildUrl(sequenceNr ? `${cancelDefaultHref}/history/${sequenceNr}` : cancelDefaultHref);
      return {
        id,
        sequenceNr,
        cancelHref,
        executionTypes: selectors.getVariantExecutionTypes(state),
        executionType: selectors.getDefaultExecutionType(state),
        initialValues: selectors.getVariantVersionById(state, id, sequenceNr),
      };
    },
    dispatch => ({
      fetchVariant: (id, sequenceNr) => dispatch(fetchVariantIfNeeded(id, sequenceNr)),
      fetchExecutionTypes: () => dispatch(fetchTagExecutionTypesIfNeeded()),
      onSubmit: values => dispatch(saveVariant(values)),
      saveNew: handleSubmit => {
        dispatch(change(FORM_EDIT, 'incrementVersion', true));
        setTimeout(handleSubmit, 100);
      },
    }),
  ),
  reduxForm({
    form: FORM_EDIT,
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagVariantEdit);
