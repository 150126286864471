import React from 'react';
import PropTypes from 'prop-types';
import SitesList from '~/context/components/SitesList';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { buildUrl, SearchContainer } from '~/common';
import { fetchSitesByClientIfNeeded, getClientSites, isFetchingClientSites } from '~/context/clients';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import LinkIcon from '~/components/src/LinkIcon';

const LIST_KEY = 'CONTEXT/CLIENT/CLIENT_SITES_LIST';
const SEARCH_KEY = 'CONTEXT/CLIENT/CLIENT_SITES_SEARCH';
const searchFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.siteNumber.toString().indexOf(query.toLowerCase()) !== -1;

class ClientSitesContainer extends React.Component {
  componentDidMount() {
    this.props.fetchSites();
  }

  render() {
    const { clientId, isFetching, isContextAdmin, usageHref, stateKey, t } = this.props;
    return (
      <React.Fragment>
        <Heading title={t('view.headings.accountSites')}>
          <SearchContainer stateKey={stateKey} searchKey={SEARCH_KEY} searchFn={searchFn} />
          <LinkIcon
            icon="dataUsage"
            className="h-8 w-8"
            tooltip={t('view.clientSettings.buttons.showUsage.text')}
            url={usageHref}
            testHook="showUsage"
          />
          {isContextAdmin && (
            <LinkIcon
              icon="add"
              color="blue"
              className="h-8 w-8"
              url={buildUrl(`context/sites/add?clientId=${clientId}`)}
              tooltip={t('clients:view.site.button.newSite.text')}
              testHook="addSite"
            />
          )}
        </Heading>
        {isFetching ? <Spinner /> : <SitesList {...this.props} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  stateKey: LIST_KEY,
  list: getClientSites(state),
  siteUrl: item => buildUrl(`context/sites/view/${item.siteId}`),
  usageHref: buildUrl(`context/clients/view/${ownProps.clientId}/sitestats`),
  isFetching: isFetchingClientSites(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchSites: () => dispatch(fetchSitesByClientIfNeeded(ownProps.clientId)),
});

ClientSitesContainer.propTypes = {
  clientId: PropTypes.string.isRequired,
  isContextAdmin: PropTypes.bool.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('clients'))(ClientSitesContainer);
