import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import { TDCAdvertiser, TYahooAdvertiserResponse, TYahooCreatePayload } from './types';
import { DEFAULT_SIZE } from './constants';

const BASE_PATH_DC = `${API_BASE}/admin/connectors/doubleclick`;
const BASE_PATH_YAHOO = `${API_BASE}/admin/connectors/yahoo/advertisers`;
const DC_ADVERTISERS_PATH = `${API_BASE}/v1/partners/doubleclick/advertisers`;
const YAHOO_ADVERTISERS_PATH = `${API_BASE}/v1/partners/yahoo/advertisers`;

export const getDCAdvertisers = (): Promise<TDCAdvertiser[]> => Api.callGet(DC_ADVERTISERS_PATH).then(parseResponse);

export const getDCAdvertiserById = (connectorId: string): Promise<TDCAdvertiser> =>
  Api.callGet(`${BASE_PATH_DC}/${connectorId}`).then(parseResponse);

export const createDCAdvertiser = (payload: TDCAdvertiser) => Api.callPost(BASE_PATH_DC, payload).then(parseResponse);

export const updateDCAdvertiser = (payload: TDCAdvertiser) => Api.callPut(BASE_PATH_DC, payload).then(parseResponse);

export const canDeleteDCAdvertiser = (id: string) => Api.callGet(`${BASE_PATH_DC}/canDelete/${id}`).then(parseResponse);

export const deleteDCAdvertiser = (id: number) => Api.callDelete(`${BASE_PATH_DC}/${id}`).then(parseResponse);

export const getYahooAdvertisers = (page: number): Promise<TYahooAdvertiserResponse> =>
  Api.callGet(`${YAHOO_ADVERTISERS_PATH}?page=${page}&size=${DEFAULT_SIZE}`).then(parseResponse);

export const createYahooAdvertiser = (payload: TYahooCreatePayload): Promise<string> =>
  Api.callPost(BASE_PATH_YAHOO, payload).then(parseResponse);

export const deleteYahooAdvertiser = (id: number): Promise<string> =>
  Api.callDelete(`${BASE_PATH_YAHOO}/${id}`).then(parseResponse);
