import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { PathDataType, NewPathsType, PathExistPayload, PathCanBeDeletedPayload } from './types';

const BASE_PATH = `${API_BASE}/tagmanagement`;

export const getPathList = (path: string, timestamp?: number): Promise<PathDataType> =>
  Api.callGet(`${BASE_PATH}/newPaths/list?path=${path}${timestamp ? `&timestamp=${timestamp}` : ''}`).then(
    parseResponse,
  );

export const getNewPaths = (): Promise<NewPathsType[]> => Api.callGet(`${BASE_PATH}/newPaths`).then(parseResponse);

export const getPathListCount = (path: string): Promise<number> =>
  Api.callGet(`${BASE_PATH}/newPaths/listCount?path=${path}`).then(parseResponse);

export const getPathExist = (path: string): Promise<PathExistPayload> =>
  Api.callGet(`${BASE_PATH}/websitePaths/pathExists?path=${path}`).then(parseResponse);

export const postWebsitePath = (payload: { path: string }): Promise<string> =>
  Api.callPost(`${BASE_PATH}/websitePaths/add`, payload).then(parseResponse);

export const removeWebsitePath = (payload: { path: string }): Promise<string> =>
  Api.callPost(`${BASE_PATH}/websitePaths/remove`, payload).then(parseResponse);

export const getPathCanBeDeleted = (path: string): Promise<PathCanBeDeletedPayload> =>
  Api.callGet(`${BASE_PATH}/tags/websitePathsStrict?path=${path}`).then(parseResponse);
