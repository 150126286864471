import React, { ChangeEventHandler, useEffect } from 'react';
import { I18NextT } from '~/components/src/Common/types';
import { translate } from 'react-i18next';
import { OptionsType } from 'react-select';
import { PROPERTIES_TYPES } from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorContent/ConnectorProperties/utils';
import FormikRadioInput from '~/components/src/FormikRadioInput';
import StoredVariableField from './StoredVariableField';
import StaticField from './StaticField';
import PropertyField from './PropertyField';
import './styles.scss';

const ConnectorPropertyFields = {
  [PROPERTIES_TYPES.STATIC]: StaticField,
  [PROPERTIES_TYPES.PROPERTY]: PropertyField,
  [PROPERTIES_TYPES.PROFILE_VARIABLE]: StoredVariableField,
};

interface OptionalPropertySelectorProps {
  t: I18NextT;
  name: string;
  testHook: string;
  options: OptionsType<{ name: string; profileVarId: string }>;
  onChange: (value: string, type: string) => void;
  value: string;
  type: string;
  error: string;
  onBlur: () => void;
  onUnmount: () => void;
  hasStoredVariables?: boolean;
  hasStaticProperty?: boolean;
  hasProperty?: boolean;
  label?: string;
  id?: string;
}

const OptionalPropertySelector = ({
  t,
  hasStoredVariables,
  hasStaticProperty,
  hasProperty,
  name,
  label,
  id,
  testHook,
  options,
  onChange,
  value,
  type,
  error,
  onBlur,
  onUnmount,
}: OptionalPropertySelectorProps) => {
  const radioBtnFieldName = `radioBtn-${name}`;
  const InputComponent = ConnectorPropertyFields[type];

  useEffect(() => {
    // Setting default type for the empty property input
    onChange(value, type);
    return onUnmount;
  }, []);

  if (InputComponent === undefined) {
    // eslint-disable-next-line no-console
    console.error('Component for this kind of Radio Input is not implemented yet');
  }

  const onPropertyChange = (propertyValue: string) => {
    onChange(propertyValue, type);
  };

  const onRadioBtnChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange('', event.target.value);
  };

  const hasRadioButtons = hasStoredVariables || hasStaticProperty || hasProperty;

  return (
    <React.Fragment>
      {hasRadioButtons && (
        <div className="FormikRadioGroup">
          {hasStaticProperty && (
            <FormikRadioInput
              id={`${radioBtnFieldName}--static`}
              name={radioBtnFieldName}
              checked={type === PROPERTIES_TYPES.STATIC}
              label={t('connectors.optionalProperties.static')}
              value={PROPERTIES_TYPES.STATIC}
              onChange={onRadioBtnChange}
            />
          )}
          {hasProperty && (
            <FormikRadioInput
              id={`${radioBtnFieldName}--property`}
              name={radioBtnFieldName}
              checked={type === PROPERTIES_TYPES.PROPERTY}
              label={t('connectors.optionalProperties.property')}
              value={PROPERTIES_TYPES.PROPERTY}
              onChange={onRadioBtnChange}
            />
          )}
          {hasStoredVariables && (
            <FormikRadioInput
              id={`${radioBtnFieldName}--storedVariable`}
              name={radioBtnFieldName}
              checked={type === PROPERTIES_TYPES.PROFILE_VARIABLE}
              label={t('connectors.optionalProperties.profileVariable')}
              value={PROPERTIES_TYPES.PROFILE_VARIABLE}
              onChange={onRadioBtnChange}
            />
          )}
        </div>
      )}
      {InputComponent && (
        <InputComponent
          name={name}
          label={label}
          id={id}
          testHook={testHook}
          onChange={onPropertyChange}
          options={options}
          value={value}
          error={error}
          onBlur={onBlur}
        />
      )}
    </React.Fragment>
  );
};

OptionalPropertySelector.defaultProps = {
  hasStoredVariables: false,
  hasStaticProperty: false,
  hasProperty: false,
};

export default translate('audiences')(OptionalPropertySelector);
