angular.module('webUi.ui.profiles.partners.configuration.server', [
    'webUi.ui.profiles.partners',
    'ui.bootstrap',
    'ui.router.modal'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.partners.edit', {
                url: '/edit/{partnerId}?refreshToken&error',
                isReact: true,
            });
    }]);


