import React from 'react';
import { ClientViewContainer } from '~/context/clients/containers';
import Page from '~/components/src/Page';
import { MediumWrapper } from '~/components/src/Containers';
import { useParams } from 'react-router-dom';

const ClientViewPage = ({ isContextAdmin }) => {
  const { clientId } = useParams();
  return (
    <Page>
      <MediumWrapper>
        <ClientViewContainer isContextAdmin={isContextAdmin} clientId={clientId} />
      </MediumWrapper>
    </Page>
  );
};

export default ClientViewPage;
