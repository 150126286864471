import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { Heading } from '~/components';
import { twMerge } from 'tailwind-merge';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { changePathShowType } from '../utils';
import * as service from '../dataService';
import { PageDetailsProps } from '../types';
import DeletePathModal from './DeletePathModal';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';

const PageDetails = ({
  lastUpdateTs,
  path,
  addPath,
  isGlobal,
  hasTagEdit,
  hasSubPaths,
  searchQuery,
  setSearchQuery,
  pathExists,
  refetchPathExist,
}: PageDetailsProps): React.ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const removePath = async (path: string) => {
    await service.removeWebsitePath({ path });
    showSuccess({ body: i18n.t('indexedPaths:actions.deletionSuccess') });
    setShowDeleteModal(false);
    refetchPathExist();
  };

  const status = pathExists ? i18n.t('indexedPaths:status.added') : i18n.t('indexedPaths:status.notAdded');

  return (
    <>
      <div className="flex h-24">
        <div className="flex-1">
          <div className="flex items-start gap-2">
            <Heading
              className="-mt-2 inline-block pb-0 pt-0"
              testHook="mlName-heading"
              title={isGlobal ? i18n.t('indexedPaths:title') : i18n.t('indexedPaths:pageDetail')}
            />
          </div>
          {!isGlobal && (
            <>
              <div
                className={twMerge(
                  cx(
                    'mr-1 inline-block rounded-xl border border-green-100 bg-green-50 px-2 font-medium text-green-600',
                    {
                      'border-amber-100 bg-amber-50 text-amber-600': !pathExists,
                    },
                  ),
                )}
              >
                <span>{status}</span>
              </div>
              <span className="ml-1 text-sm text-slate-500">{changePathShowType(path)}</span>
            </>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-end gap-4">
            <SearchElement
              value={searchQuery}
              onChange={e => {
                setSearchQuery(e.currentTarget.value);
              }}
              placeholder={i18n.t('common:actions.search')}
            />
            {!isGlobal && (
              <div className="flex items-center">
                {pathExists && hasTagEdit ? (
                  <>
                    {!hasSubPaths && (
                      <BtnIcon
                        testHook="headerRemovePathBtn"
                        icon="delete"
                        tooltip={i18n.t('indexedPaths:tooltips.remove')}
                        onClick={() => setShowDeleteModal(true)}
                      />
                    )}
                  </>
                ) : (
                  <BtnIcon
                    testHook="headerAddPathBtn"
                    icon="add"
                    color="blue"
                    tooltip={i18n.t('indexedPaths:tooltips.add')}
                    onClick={() => addPath(path, refetchPathExist)}
                  />
                )}
              </div>
            )}
          </div>
          {!isGlobal && (
            <div className="mt-4 self-start text-sm text-slate-500">
              {i18n.t('indexedPaths:lastUpdate')}
              {lastUpdateTs ? (
                <span>
                  {moment(lastUpdateTs).format(DATE_FORMAT)}{' '}
                  <span className="text-r42-blue text-sm">{moment(lastUpdateTs).format(TIME_FORMAT)}</span>
                </span>
              ) : (
                i18n.t('indexedPaths:unknown')
              )}
            </div>
          )}
        </div>
      </div>
      {!isGlobal && showDeleteModal && (
        <DeletePathModal
          path={path}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          removePath={removePath}
        />
      )}
    </>
  );
};

export default PageDetails;
