let csrfToken = null;
let siteId = null;
let assetsPrefix = null;

/**
 * Update Csrf Token
 * @param token
 */
export const setCsrfToken = token => {
  csrfToken = token;
};

/**
 * Get current Csrf Token
 * @returns {String}
 */
export const getCsrfToken = () => csrfToken;

/**
 * Set current Site Id
 * @param id
 */
export const setSiteId = id => {
  siteId = id;
};

/**
 * Get current Site Id
 * @returns {Number}
 */
export const getSiteId = () => siteId;

/**
 * Set current assets prefix
 * @param prefix
 */
export const setAssetsPrefix = prefix => {
  assetsPrefix = prefix;
};

/**
 * Get current assets prefix
 * @returns {String}
 */
export const getAssetsPrefix = () => assetsPrefix;

export default {
  setCsrfToken,
  getCsrfToken,
  setSiteId,
  getSiteId,
  setAssetsPrefix,
  getAssetsPrefix,
};
