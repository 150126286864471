import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormMeta } from 'redux-form';
import { buildUrl } from '~/common/history';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import { Notification } from '~/components';
import { getValueSets, isSetsValidationActive, getValueSetById } from '../urlBuilder/valueSets/selectors';
import {
  fetchValueSets,
  editValueSet,
  handleSetsValidationShow,
  handleSetsValidationHide,
} from '../urlBuilder/valueSets/actions';
import ValueSetsForm from '../components/ValueSetsForm';

const filterValueList = valueSet => {
  if (valueSet.predefinedValuesSetValueList) {
    return {
      ...valueSet,
      predefinedValuesSetValueList: valueSet.predefinedValuesSetValueList.filter(item => item.name || item.value),
    };
  }
  return {
    ...valueSet,
    predefinedValuesSetValueList: [],
  };
};

export class EditValueSetsPage extends Component {
  componentDidMount() {
    this.props.fetchValueSets();
  }
  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('valueSets.formEditValueSet.header')} />
          <Panel>
            <Notification kind="default" className="mb-6">
              {this.props.t('valueSets.formNewValueSet.intro')}
            </Notification>
            <ValueSetsForm editForm={true} backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      initialValues: getValueSetById(state, ownProps.params.valueSetsId),
      valueSets: getValueSets(state),
      fieldsMeta: getFormMeta('valueSetsEdit')(state),
      validateSets: isSetsValidationActive(state),
    }),
    dispatch => ({
      fetchValueSets: () => dispatch(fetchValueSets()),
      onSubmit: valueSet => dispatch(editValueSet(filterValueList(valueSet))),
      handleSetsValidationShow: () => dispatch(handleSetsValidationShow()),
      handleSetsValidationHide: () => dispatch(handleSetsValidationHide()),
    }),
  ),
  reduxForm({
    form: 'valueSetsEdit',
  }),
  translate(['urlBuilder']),
)(EditValueSetsPage);
