// Module
var code = `<div class="box" data-ng-controller="TagmanagementTagFormJourneyConditionController as vm"> <div class="box-title"> <h3><i class="r42-journey"></i> Orchestration rule</h3> </div> <div class="box-content"> <div class="control-group"> <span class="control-label">Orchestration Type *<small>Type of orchestration</small></span> <div class="controls input-container valign-top"> <div class="display-inlineBlock valign-top" data-valid data-ng-change="vm.callbacks.onOrchestrationTypeChanged()" data-ng-model="vm.model.orchestrationType" input-class="input-regular text-uppercase" data-type="select" data-options="vm.data.orchestrationTypes"></div> </div> </div> <div data-ng-if="vm.data.journeys.length === 0" class="my-2 flex items-center gap-4 rounded-lg border border-blue-200 bg-blue-50 p-4 text-slate-600 dark:text-slate-200"> <icon p="information" class="text-blue-600"></icon> <p class="text-lg font-light">There are no orchestration {{vm.model.orchestrationType.toLowerCase()}}s configured, please create one beforehand.</p> </div> <div data-ng-if="vm.data.journeys.length > 0"> <div class="control-group"> <span class="control-label">Orchestration *<small>The chosen orchestration to satisfy the rule</small></span> <div class="controls input-container valign-top"> <div class="display-inlineBlock valign-top" data-valid data-ng-change="vm.callbacks.onJourneyTypeChanged()" data-ng-model="vm.model.journeyType" input-class="input-regular text-uppercase" data-type="select" data-options="vm.data.journeyTypes" data-options-key="id" data-options-value="name"></div> <div class="display-inlineBlock valign-top" data-valid data-ng-change="vm.callbacks.onJourneyChanged()" data-ng-model="vm.model.journeyId" data-type="select" data-options="vm.data.journeys" data-options-key="journeyId" data-options-value="name" input-class="input-large" data-custom-validate="[
                {isRequired: true, msg: 'Journey is mandatory'}
              ]"> <span class="error-block" data-ng-show="templateForm['vm.model.journeyId'].\$showError"> <small>{{templateForm['vm.model.journeyId'].\$errors[0]}}</small> </span> </div> </div> </div> <div class="control-group"> <span class="control-label">Step<small>The chosen step to satisfy the rule</small></span> <div class="controls input-container valign-top"> <div class="display-inlineBlock valign-top" data-valid data-ng-change="vm.callbacks.onStepTypeChanged()" data-ng-model="vm.model.stepType" data-ng-disabled="vm.ui.isStepDisabled" input-class="input-regular text-uppercase" data-type="select" data-options="vm.data.stepTypes" data-options-key="id" data-options-value="name"></div> <div class="display-inlineBlock valign-top" data-valid data-ng-model="vm.model.stepId" input-class="input-large" data-ng-disabled="vm.ui.isStepDisabled || vm.ui.isStepSelectDisabled" data-type="select" data-options="vm.data.steps" data-options-key="nodeId" data-options-value="label"></div> </div> <div data-wrap data-label="Cache in seconds<small>Cache audiences for this many seconds to save data-calls (The default of 0 means no caching)</small>" data-valid data-type="input" data-ng-model="vm.model.cacheInSeconds" data-placeholder="e.g 0" data-custom-validate="[
              {isRequired: true, msg: 'Leave 0 for non specified'},
              {isNumber: true, msg: 'Cache should be a positive number (leave 0 for default)'}
            ]"> <span class="error-block" data-ng-show="templateForm['vm.model.cacheInSeconds'].\$showError"> <small>{{templateForm['vm.model.cacheInSeconds'].\$errors[0]}}</small> </span> </div> </div> </div> <div data-ng-if="vm.ui.showError" class="my-2 flex items-center gap-4 rounded-lg border border-red-200 bg-red-50 p-4 text-slate-600 dark:text-slate-200"> <icon p="error" class="text-red-600"></icon> <p class="text-lg font-light">Orchestration is not configured properly and cannot be saved.</p> </div> </div> </div>`;
// Exports
var _module_exports =code;;
var path = 'ui/tagmanagement/tags/conditions/form/journeyCondition.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;