import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import i18n from '~/i18n';
import UsersList from '~/context/components/UsersList';
import { fetchMatchList, getMatchClientId, getNotMatchedClients, MATCH_USERS_KEY } from '~/context/users';
import { getClients } from '~/support/helpers/clientsHelper.js';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

export class MatchUsers extends Component {
  constructor(props) {
    super(props);
    this.state = { clients: [] };
  }

  componentDidMount() {
    getClients(document).then(clients => {
      this.setState({
        clients,
      });
    });
  }

  render() {
    const { clientId, fetchMatchList, ...props } = this.props;
    return (
      <div>
        <FieldWrapper label={i18n.t('users:match.client')}>
          <SelectElement
            menuPosition="fixed"
            name="clientName"
            value={clientId}
            options={this.state.clients}
            onChange={({ value }) => {
              fetchMatchList(value);
            }}
          />
        </FieldWrapper>
        {clientId && (
          <div className="u-paddingVerticalL">
            <UsersList emptyBody={i18n.t('users:match.empty.body')} {...props} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientId: getMatchClientId(state),
  list: getNotMatchedClients(state),
  stateKey: MATCH_USERS_KEY,
});

const mapDispatchToProps = dispatch => ({
  fetchMatchList: clientId => dispatch(fetchMatchList(clientId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MatchUsers);
