import { useRef, useState } from 'react';

export const useDirtyFormHandler = () => {
  const [showDirtyFormPopup, setShowDirtyFormPopup] = useState(false);
  const cbRef = useRef<() => void>();

  const processDirtyForm = (isDirty: boolean, cb: () => void) => {
    if (isDirty) {
      setShowDirtyFormPopup(true);
      cbRef.current = cb;
    } else {
      cb();
    }
  };

  return { showDirtyFormPopup, setShowDirtyFormPopup, processDirtyForm, cbRef };
};
