/**
 * @class webUi.BeaconStatsService
 * @memberOf webUi
 */
angular.module('webUi.service.remote.beaconStats',
    [
        'restangular',
        'webUi.common.Utils'
    ]
)
    .factory('BeaconStatsService',
        [
            '$q',
            'Restangular',
            'ChartService',
            'Utils',
            /**
             *
             * @param Restangular
             * @param $q
             * @param Utils
             * @param ChartService
             * @returns {{getBeaconStatsChartSeries: Function}}
             */
            function ($q, Restangular, ChartService, Utils) {

                var BASE_PATH = 'remote/beacons/';

                /**
                 *
                 * @param stats
                 * @returns {*[]}
                 */
                var _getChartSeries = function (stats) {
                    return {
                        series: [
                            {
                                name: 'Calls',
                                yAxis: 0,
                                data: _.pluck(stats, 'calls'),
                                pointInterval: 24 * 36e5,
                                pointStart: (stats.length === 0) ? 0 : stats[0].key.dayTimestamp,
                                color: ChartService.colors.GREEN
                            }
                        ],
                        total: _.reduce(_.pluck(stats, 'calls'), function (sum, calls) {
                            return sum + calls;
                        })
                    };
                };

                /**
                 *
                 * @param {UUID} beaconId - the id of the beacon to retrieve stats for
                 * @param {Integer} days - the number of days for which stats should be retrieved
                 * @returns a promise of the stats for the last x days
                 */
                var getBeaconStatsChartSeries = function (beaconId, days) {

                    var deferredChartSeriesPromise = $q.defer();
                    Restangular.one(BASE_PATH + beaconId + '/stats').get({'days': days}).then(
                        function onSuccess(result) {
                            result = Utils.sanitizeRestangularOne(result);
                            var beaconStatsChartSeries = _getChartSeries(result);
                            deferredChartSeriesPromise.resolve(beaconStatsChartSeries);
                        },
                        function onError() {

                        }
                    );
                    return deferredChartSeriesPromise.promise;
                };


                var getBeaconCalls = function () {
                    var beaconsPromise = $q.defer();
                    Restangular.all(BASE_PATH + 'stats/totalUsage').getList().then(function (beacons) {
                        beacons = Utils.sanitizeRestangularAll(beacons);
                        beaconsPromise.resolve(beacons);
                    });
                    return beaconsPromise.promise;
                };

                /**
                 * RPM API
                 * @type {{*}}
                 */
                var service = {
                    getBeaconStatsChartSeries: getBeaconStatsChartSeries,
                    getBeaconCalls: getBeaconCalls
                };
                return service;
            }
        ]
    );
