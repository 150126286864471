import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell } = components;
const { withSort, withPaginate, withFilter, withEmpty } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('list.empty.header')} body={t('list.empty.body')} />;

const NoResults = ({ t }) => <Empty header={t('list.noResults.header')} body={t('list.noResults.body')} />;

const customSort = prop => item => (item[prop] ? item[prop] : 0);
const WIDTHS = {
  VERSION: '10.5%',
  COOKIE: '10.5%',
  OTHERS: '6.5%',
};
const getColumnWidth = columnName => (columnName === 'COOKIEPERMISSION' ? WIDTHS.COOKIE : WIDTHS.OTHERS);
export const BasescriptsList = ({ list, headers, stateKey, buildBasescriptUrl, t }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width={WIDTHS.VERSION} sortKey="version" title={t('list.header.versionTitle')} />
        {headers.map(module => (
          <SortableHeader
            key={module.module.name}
            width={getColumnWidth(module.module.name)}
            sortKey={module.module.name}
            title={module.module.label}
            sortFn={customSort(module.module.name)}
          />
        ))}
      </Row>
      {list.map(basescriptInfo => (
        <Row key={basescriptInfo.basescriptId}>
          <Cell style={{ width: `${WIDTHS.VERSION}` }}>
            <Link href={buildBasescriptUrl(basescriptInfo.basescriptId)}>{basescriptInfo.version}</Link>
          </Cell>
          {headers.map(module => (
            <Cell key={module.module.name} style={{ width: `${getColumnWidth(module.module.name)}` }}>
              {basescriptInfo[module.module.name] || '-'}
            </Cell>
          ))}
        </Row>
      ))}
    </Table>
  </Panel>
);

BasescriptsList.propTypes = {
  list: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  buildBasescriptUrl: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
)(BasescriptsList);
