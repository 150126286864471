export const BASESCRIPTS_FETCH_START = 'app/context/basescripts/BASESCRIPTS_FETCH_START';
export const BASESCRIPTS_FETCH_FAIL = 'app/context/basescripts/BASESCRIPTS_FETCH_FAIL';
export const BASESCRIPTS_FETCH_SUCCESS = 'app/context/basescripts/BASESCRIPTS_FETCH_SUCCESS';

export const BASESCRIPT_MODULES_FETCH_START = 'app/context/basescripts/BASESCRIPT_MODULES_FETCH_START';
export const BASESCRIPT_MODULES_FETCH_FAIL = 'app/context/basescripts/BASESCRIPT_MODULES_FETCH_FAIL';
export const BASESCRIPT_MODULES_FETCH_SUCCESS = 'app/context/basescripts/BASESCRIPT_MODULES_FETCH_SUCCESS';

export const SET_BASESCRIPTS_FILTER = 'app/context/basescripts/SET_BASESCRIPTS_FILTER';
export const SET_SITES_FILTER = 'app/context/basescripts/SET_SITES_FILTER';
export const SET_LINK_SITES_TO_BASESCRIPT_FILTER = 'app/context/basescripts/SET_LINK_SITES_TO_BASESCRIPT_FILTER';
export const REMOVE_BASESCRIPTS_FILTER = 'app/context/basescripts/REMOVE_BASESCRIPTS_FILTER';
export const REMOVE_SITES_FILTER = 'app/context/basescripts/REMOVE_SITES_FILTER';
export const REMOVE_LINK_SITES_TO_BASESCRIPT_FILTER = 'app/context/basescripts/REMOVE_LINK_SITES_TO_BASESCRIPT_FILTER';

export const SAVE_START = 'app/context/basescripts/SAVE_START';
export const SAVE_FAIL = 'app/context/basescripts/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/context/basescripts/SAVE_SUCCESS';

export const RESET_BASESCRIPT_USAGE = 'app/context/basescripts/RESET_BASESCRIPT_USAGE';
export const TOGGLE_BASESCRIPT_USAGE_IN_SITE = 'app/context/basescripts/TOGGLE_BASESCRIPT_USAGE_IN_SITE';
export const USE_BASESCRIPT_IN_SITES_START = 'app/context/basescripts/USE_BASESCRIPT_IN_SITES_START';
export const USE_BASESCRIPT_IN_SITES_FAIL = 'app/context/basescripts/USE_BASESCRIPT_IN_SITES_START';
export const USE_BASESCRIPT_IN_SITES_SUCCESS = 'app/context/basescripts/USE_BASESCRIPT_IN_SITES_SUCCESS';
