import React, { useContext } from 'react';
import { Props } from 'react-select';
import cx from 'classnames';
import _, { get } from 'lodash';
import i18n from '~/i18n';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { Field, useFormikContext } from 'formik';
import BtnIcon from '~/components/src/BtnIcon';
import { TransformationContext } from '../context';
import { getSelectOptionsForSources, removeEmptyValueTransformations } from '../core/util';
import { MappingProps, OptionType } from './types';
import { MappingType } from '../types';

const OptionWithDescription = (props: Props) => {
  const { className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps, name, label } = props;

  return (
    <div
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      {...innerProps}
    >
      {label ? (
        <div>
          <div className="font-medium">{name}</div>
          {name !== label && <div>{label}</div>}
        </div>
      ) : (
        <div className="t-customOption">
          Custom: <span className="font-medium">{name}</span>
        </div>
      )}
    </div>
  );
};

const SourceSelectOption = (props: Props) => (
  <OptionWithDescription {...props} name={props.data.label} label={props.data.description} />
);

export const MappingListHeader = ({ isReadOnly }: { isReadOnly: boolean }): React.ReactElement => (
  <thead className="w-full">
    <tr className="border-b py-2">
      <th className="w-[30%] font-medium">{i18n.t('partners:eventConnector.sourceProperty')}</th>
      <th className="w-[30%] pl-2 font-medium">{i18n.t('partners:eventConnector.destinationProperty')}</th>
      <th className="w-[20%] text-center font-medium">{i18n.t('partners:eventConnector.transformations')}</th>
      <th className="w-[8%] font-medium">{i18n.t('partners:eventConnector.static')}</th>
      <th className="w-[8%] font-medium">{i18n.t('partners:eventConnector.required')}</th>
      {!isReadOnly && <th className="w-[4%]"></th>}
    </tr>
  </thead>
);

const Mapping = ({
  configuredMappings,
  mapping,
  remove,
  mappingPath,
  isReadOnly,
  additionalSources,
}: MappingProps): React.ReactElement => {
  const form = useFormikContext();
  const { setFieldValue, errors } = form;

  const { internalProps, setMappingPath } = useContext(TransformationContext);
  const { selectedSourceOption, sourcesForSelect } = getSelectOptionsForSources(
    mapping.sourceName,
    additionalSources,
    internalProps,
  );

  return (
    <tr className="border-b px-2">
      <td className="w-[30%] pr-2">
        <Field
          as={FormikSelectField}
          name={`${mappingPath}.sourceName`}
          isCreatable
          components={{ Option: SourceSelectOption }}
          value={selectedSourceOption}
          options={sourcesForSelect}
          onChange={(selectedOption: OptionType) => setFieldValue(`${mappingPath}.sourceName`, selectedOption.value)}
          isOptionDisabled={(option: OptionType) =>
            configuredMappings.some((mapping: MappingType) => mapping.sourceName === option.name)
          }
          formatGroupLabel={true}
          formatCreateLabel={() => i18n.t('partners:eventConnector.customProperties')}
          getNewOptionData={(inputValue: string, optionLabel: string) =>
            inputValue
              ? {
                  label: optionLabel,
                  options: [{ label: inputValue, value: inputValue }],
                  __isNew__: true,
                }
              : null
          }
          className="my-4 w-full"
          disabled={isReadOnly}
          errorText={get(errors, `${mappingPath}.sourceName`)}
          customSetFieldValue={(name: string, option: OptionType) => setFieldValue(name, option.value)}
        />
      </td>
      <td className="w-[30%] px-2">
        <Field
          as={FormikInputField}
          name={`${mappingPath}.destinationName`}
          isCreatable
          value={mapping.destinationName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(`${mappingPath}.destinationName`, e.target.value)
          }
          className="my-4 w-full"
          disabled={isReadOnly}
          errorText={get(errors, `${mappingPath}.destinationName`)}
        />
      </td>
      <td className="w-[20%] px-2">
        <div className="flex items-center justify-center">
          <span className={cx('px-1', { 'text-red-500': get(errors, `${mappingPath}.transformations`) })}>
            {i18n.t('partners:eventConnector.valueTransformationsApplied', {
              valueTransformationsCount: removeEmptyValueTransformations(mapping.transformations).length,
            })}
          </span>
          <BtnIcon
            icon={isReadOnly ? 'view' : 'edit'}
            testHook="configureTransformationsButton"
            className={cx('mx-1', { 'text-red-500': get(errors, `${mappingPath}.transformations`) }, 'h-5 w-5 p-0.5')}
            onClick={() => setMappingPath?.(mappingPath)}
          />
        </div>
      </td>
      <td className="w-[8%] px-2">
        <Field
          className="mb-1"
          as={CheckboxElement}
          name={`${mappingPath}.static`}
          id={`${mappingPath}.static`}
          value={mapping.static}
          disabled={isReadOnly}
          onChange={() => setFieldValue(`${mappingPath}.static`, !mapping.static)}
        />
      </td>
      <td className="w-[8%] px-4">
        <Field
          className="mb-1"
          as={CheckboxElement}
          name={`${mappingPath}.required`}
          id={`${mappingPath}.required`}
          value={mapping.required}
          disabled={isReadOnly}
          onChange={() => setFieldValue(`${mappingPath}.required`, !mapping.required)}
        />
      </td>
      {!isReadOnly && (
        <td className="w-[4%] text-center">
          <BtnIcon
            className="h-5 w-5"
            icon="close"
            tooltip={i18n.t('common:actions.delete')}
            onClick={remove}
            disabled={isReadOnly}
          />
        </td>
      )}
    </tr>
  );
};

export default Mapping;
