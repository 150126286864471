import React, { useState } from 'react';
import cx from 'classnames';
import { set } from 'lodash';
import i18n from '~/i18n';
import PickerView from '~/components/src/PickerView';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import {
  DateToEpochSecondsValueTransformation,
  EpochSecondsToDateValueTransformation,
  HashingValueTransformation,
  StringToArrayValueTransformation,
  StringToNumberValueTransformation,
  ValueMappingValueTransformation,
} from '~/profiles/connectors/form/partners/facebookConversions/core/transformerTypes';
import DateValidator from '~/components/src/DateValidator';
import Tooltip from '~/components/src/Tooltip';
import Icons from '~/components/src/Icons';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import {
  Transformer,
  ValueTransformation as TypeValueTransformation,
  DateFormatValueTransformation,
} from '../core/transformerTypes';
import { ValueTransformationProps } from './types';

const DateFormat = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: DateFormatValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => {
  const [isEventPickerVisible, setIsEventPickerVisible] = useState(false);
  const [fieldName, setFieldName] = useState<string | null>(null);
  const handleDateValidationPicker = (fName: string) => {
    setIsEventPickerVisible(true);
    setFieldName(fName);
  };
  return (
    <>
      <div className="ValueTransformation-cell">
        <InputElement
          value={valueTransformation.fields.inputFormat}
          hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.inputFormat`)}
          error={getValidationErrorByPath(`${valueTransformationPath}.fields.inputFormat`)?.message}
          placeholder="Input format"
          onChange={event => {
            valueTransformation.setFieldValue('inputFormat', event.target.value);
          }}
          enableAction
          buttonIcon="calendar"
          buttonTestHook="inputCalendarButton"
          onToggleButton={() => handleDateValidationPicker('inputFormat')}
          testHook="inputFormatInputElement"
        />
      </div>
      <div className="ValueTransformation-cell">
        <InputElement
          value={valueTransformation.fields.outputFormat}
          hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.outputFormat`)}
          placeholder="Output format"
          error={getValidationErrorByPath(`${valueTransformationPath}.fields.outputFormat`)?.message}
          onChange={event => {
            valueTransformation.setFieldValue('outputFormat', event.target.value);
          }}
          enableAction
          buttonIcon="calendar"
          buttonTestHook="outputCalendarButton"
          onToggleButton={() => handleDateValidationPicker('outputFormat')}
          testHook="outputFormatInputElement"
        />
      </div>
      {isEventPickerVisible && (
        <PickerView
          className="EventConnectorTransformationsPage-eventPicker"
          handlePickerVisibility={setIsEventPickerVisible}
          pickerTitle={i18n.t('forms:dateFormat.controls.label')}
        >
          <DateValidator
            initialValue={valueTransformation.fields[fieldName || '']}
            onChange={value => {
              valueTransformation.setFieldValue(fieldName || '', value);
              setIsEventPickerVisible(false);
            }}
          />
        </PickerView>
      )}
    </>
  );
};

const DateToEpochSeconds = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: DateToEpochSecondsValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <div className="ValueTransformation-cell">
    <InputElement
      value={valueTransformation.fields.inputFormat}
      hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.inputFormat`)}
      placeholder="Format"
      onChange={event => {
        valueTransformation.setFieldValue('inputFormat', event.target.value);
      }}
    />
  </div>
);

const EpochSecondsToDate = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: EpochSecondsToDateValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <>
    <div className="ValueTransformation-cell">
      <InputElement
        value={valueTransformation.fields.dateFormat}
        hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.dateFormat`)}
        placeholder="Format"
        onChange={event => {
          valueTransformation.setFieldValue('dateFormat', event.target.value);
        }}
      />
    </div>
    <div className="ValueTransformation-cell">
      <InputElement
        value={valueTransformation.fields.offset}
        hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.offset`)}
        placeholder="Offset"
        onChange={event => {
          valueTransformation.setFieldValue('offset', event.target.value);
        }}
      />
    </div>
  </>
);

const Hashing = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: HashingValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <div className="ValueTransformation-cell ValueTransformation-selectCell">
    <SelectElement
      className="HashingSelect"
      value={valueTransformation.fields.algorithm}
      placeholder="Algorithm"
      hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.algorithm`)}
      options={[{ value: 'SHA256' }]}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.value}
      onChange={selectedOption => {
        valueTransformation.setFieldValue('algorithm', selectedOption.value);
      }}
      menuPosition="fixed"
    />
  </div>
);

const StringToArray = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: StringToArrayValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <div className="ValueTransformation-cell">
    <InputElement
      value={valueTransformation.fields.delimiter}
      hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.delimiter`)}
      placeholder="Delimiter"
      onChange={event => {
        valueTransformation.setFieldValue('delimiter', event.target.value);
      }}
    />
  </div>
);

const StringToNumber = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: StringToNumberValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <div className="flex">
    <div className="ValueTransformation-cell">
      <InputElement
        value={valueTransformation.fields.decimalSeparator || ''}
        hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.decimalSeparator`)}
        error={getValidationErrorByPath(`${valueTransformationPath}.fields.decimalSeparator`)?.message}
        placeholder="Decimal separator"
        onChange={({ target: { value } }) => {
          if (value?.length <= 1) {
            valueTransformation.setFieldValue('decimalSeparator', value);
          }
        }}
      />
    </div>
    <div className="ValueTransformation-cell">
      <InputElement
        value={valueTransformation.fields.groupingSeparator || ''}
        hasError={!!getValidationErrorByPath(`${valueTransformationPath}.fields.groupingSeparator`)}
        error={getValidationErrorByPath(`${valueTransformationPath}.fields.groupingSeparator`)?.message}
        placeholder="Grouping separator"
        onChange={({ target: { value } }) => {
          if (value?.length <= 1) {
            valueTransformation.setFieldValue('groupingSeparator', value);
          }
        }}
      />
    </div>
  </div>
);

const ValueMapping = ({
  valueTransformation,
  valueTransformationPath,
  getValidationErrorByPath,
}: {
  valueTransformation: ValueMappingValueTransformation;
  valueTransformationPath: string;
  getValidationErrorByPath: Transformer['getValidationErrorByPath'];
}) => (
  <div>
    {valueTransformation.fields.valueMappings.map(({ from, to }, valueMappingIndex) => (
      <div className="ValueMapping">
        <div className="ValueTransformation-cell">
          <InputElement
            value={from}
            hasError={
              !!getValidationErrorByPath(`${valueTransformationPath}.fields.valueMappings[${valueMappingIndex}].from`)
            }
            placeholder="From"
            onChange={event => {
              const { valueMappings } = valueTransformation.fields;
              set(valueMappings, `[${valueMappingIndex}].from`, event.target.value);
              valueTransformation.setFieldValue('valueMappings', valueMappings);
            }}
          />
        </div>
        <div className="ValueTransformation-cell">
          <InputElement
            value={to}
            hasError={
              !!getValidationErrorByPath(`${valueTransformationPath}.fields.valueMappings[${valueMappingIndex}].to`)
            }
            placeholder="To"
            onChange={event => {
              const { valueMappings } = valueTransformation.fields;
              set(valueMappings, `[${valueMappingIndex}].to`, event.target.value);
              valueTransformation.setFieldValue('valueMappings', valueMappings);
            }}
          />
        </div>
        <div
          className={cx('ValueTransformation-cell', {
            'value-mapping-delete-button--hidden': valueTransformation.fields.valueMappings.length <= 1,
          })}
        >
          <BtnIcon
            className={cx('value-mapping-delete-button')}
            icon="close"
            tooltip="Delete"
            onClick={() => {
              const { valueMappings } = valueTransformation.fields;
              valueMappings.splice(valueMappingIndex, 1);
              valueTransformation.setFieldValue('valueMappings', valueMappings);
            }}
          />
        </div>
      </div>
    ))}

    <div className="transformer-EventConnectorTransformationsPage-addEventButtonContainer">
      <BtnOutlined
        className="EventConnectorTransformationsPage-addEventButton"
        size="xs"
        icon="add"
        onClick={() => {
          const { valueMappings } = valueTransformation.fields;

          valueMappings.push({
            from: '',
            to: '',
          });

          valueTransformation.setFieldValue('valueMappings', valueMappings);
        }}
      >
        Add custom value mapping
      </BtnOutlined>
    </div>
  </div>
);

const getRenderedElement = (
  valueTransformation: TypeValueTransformation,
  valueTransformationPath: string,
  getValidationErrorByPath: Transformer['getValidationErrorByPath'],
) => {
  switch (valueTransformation.transformationType) {
    case 'DATE_FORMAT':
      return (
        <DateFormat
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );
    case 'DATE_TO_EPOCH_SECONDS':
      return (
        <DateToEpochSeconds
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );
    case 'EPOCH_SECONDS_TO_DATE':
      return (
        <EpochSecondsToDate
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );
    case 'HASHING':
      return (
        <Hashing
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );

    case 'STRING_TO_ARRAY':
      return (
        <StringToArray
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );

    case 'VALUE_MAPPING':
      return (
        <ValueMapping
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );

    case 'STRING_TO_NUMBER':
      return (
        <StringToNumber
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          getValidationErrorByPath={getValidationErrorByPath}
        />
      );
  }

  return null;
};

const ValueTransformation = ({
  valueTransformation,
  options,
  remove,
  getValidationErrorByPath,
  valueTransformationPath,
  index,
  t,
}: ValueTransformationProps): React.ReactElement => {
  const valueTransformationElement = getRenderedElement(
    valueTransformation,
    valueTransformationPath,
    getValidationErrorByPath,
  );

  return (
    <div className="ValueTransformation">
      <div className="ValueTransformation-orderCell ValueTransformation-orderValueCell">{index + 1}</div>
      <div className="ValueTransformation-cell ValueTransformation-transformationTypeCell">
        <SelectElement
          className="ValueTransformation-transformationTypeSelect"
          value={valueTransformation.transformationType}
          options={options}
          getOptionValue={option => option.transformationType}
          getOptionLabel={option => option.title}
          onChange={selectedOption => {
            valueTransformation.setTransformationType(selectedOption.transformationType);
          }}
          menuPosition="fixed"
        />
      </div>
      <div className="ValueTransformation-cell ValueTransformation-ValueCell">{valueTransformationElement}</div>
      {valueTransformation.reason && (
        <Tooltip
          placement="bottom"
          tooltip={`${t('partners:eventConnector.suggestedValueTransformation')}\n${valueTransformation.reason}`}
        >
          <Icons icon="info" className="h-4 w-4 text-gray-400" />
        </Tooltip>
      )}
      <BtnIcon className={cx('mapping-delete-button h-5 w-5')} icon="close" tooltip="Delete" onClick={remove} />
    </div>
  );
};

export default ValueTransformation;
