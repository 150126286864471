import React from 'react';
import i18n from '~/i18n';
import { Heading } from '~/components';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import { MostErrorsProps, TaggingErrorsData } from '../types';

const columnHelper = createColumnHelper<TaggingErrorsData>();

const MostErrors = ({ mostErrors }: MostErrorsProps): React.ReactElement => {
  const getColumns = () => [
    columnHelper.accessor('tagName', {
      header: () => <div className="text-left">{i18n.t('stats:mostErrors.table.headers.tag')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('errors', {
      header: () => <div className="text-center">{i18n.t('stats:mostErrors.table.headers.errors')}</div>,
      cell: info => <div className="text-center">{info.getValue()}</div>,
    }),
    columnHelper.accessor('errorsPercentage', {
      header: () => <div className="text-center">{i18n.t('stats:mostErrors.table.headers.percentage')}</div>,
      cell: info => <div className="text-center">{info.getValue()}</div>,
    }),
  ];

  const data = mostErrors || [];
  return (
    <div className="mt-6">
      <div className="mb-3 flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Heading kind="h3" className="inline-block" title={i18n.t('stats:mostErrors.title')} />
          <Icons icon="info" tooltip={i18n.t('stats:mostErrors.tooltip')} className="h-4 w-4 text-gray-500" />
        </div>
      </div>
      <UITable
        data={data}
        columns={getColumns()}
        enableSorting
        testHook="mostErrorsTable"
        emptyMessage={i18n.t('stats:mostErrors.noData')}
      />
    </div>
  );
};

export default MostErrors;
