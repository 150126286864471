import { combineReducers } from 'redux';
import urlBuilder from './urlBuilder';
import environments from './environments';
import users from './users';
import site from './site';

// Default Admin Reducer
export default combineReducers({
  urlBuilder,
  environments,
  users,
  site,
});
