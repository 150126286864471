import React from 'react';
import Page from '~/components/src/Page';
import CustomerFactsContainer from '../customerFacts/containers/CustomerFactsContainer';

export const CustomerFactsViewPage = ({ canEditProfiles }) => (
  <Page>
    <CustomerFactsContainer canEditProfiles={canEditProfiles} />
  </Page>
);

export default CustomerFactsViewPage;
