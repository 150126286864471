import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { APIKey, CreateAPIKeyType } from './types';

const BASE_PATH = `${API_BASE}/v1/auth`;

export const fetchApiKeys = (): Promise<APIKey[]> => Api.callGet(`${BASE_PATH}/apikey`).then(parseResponse);

export const createAPIKey = (payload: { name: string }): Promise<CreateAPIKeyType> =>
  Api.callPost(`${BASE_PATH}/apikey`, payload).then(parseResponse);

export const deleteAPIKey = (apiKeyId: string): Promise<void> =>
  Api.callDelete(`${BASE_PATH}/apikey/${apiKeyId}`).then(parseResponse);
