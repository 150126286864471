import React from 'react';
import Spinner from '~/components/src/Spinner';

const CustomerProfile = ({ profileIdState, t }) => {
  const { value, error } = profileIdState;

  if (error) return null;

  if (!value) return <Spinner />;

  return (
    <p>
      {t('profileId')}
      <b>{value}</b>
    </p>
  );
};

export default CustomerProfile;
