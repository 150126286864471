import React, { MouseEvent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FaTrash from 'react-icons/lib/fa/trash';
import MdEdit from 'react-icons/lib/md/edit';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';
import { IconBaseProps } from 'react-icon-base';
import Icon from '../Icon';
import './styles.scss';

// TODO check if those are imported separately anywhere, and get rid of those
export const primary = 'primary';
export const secondary = 'secondary';
export const remove = 'remove';
export const simple = 'simple';
export const tiny = 'tiny';
export const small = 'small';
export const normal = 'normal' as const;
export const big = 'big';

const kinds = {
  primary,
  secondary,
  remove,
  simple,
} as const;
export const kind = kinds;

export const sizes = {
  tiny,
  small,
  normal,
  big,
} as const;

interface ButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
  iconClassName?: string;
  disabled?: boolean;
  size?: (typeof sizes)[keyof typeof sizes];
  kind?: (typeof kinds)[keyof typeof kinds];
  isRounded?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  iconDesc?: string;
  iconSize?: number;
  href?: string;
  type?: 'button' | 'reset' | 'submit';
  testHook?: string;
  fullWidth?: boolean;
  customTooltip?: string;
  tooltipPlacement?: Placement;
  label?: string;
  color?: string;
}

const Button = ({
  children,
  className = '',
  iconClassName = '',
  tooltipClassName = '',
  disabled = false,
  size = normal,
  kind = secondary,
  isRounded = false,
  icon,
  iconDesc = '',
  iconSize = 20,
  href,
  onClick,
  type = 'button',
  testHook = 'button',
  fullWidth = false,
  customTooltip = '',
  tooltipPlacement = 'bottom',
  label,
  color = '',
  ...other
}: ButtonProps): JSX.Element => {
  const Element = href ? 'a' : 'button';
  const role = Element === 'a' ? 'button' : undefined;
  const classes = ['Button', className];

  /** Add Button Modifiers * */
  switch (kind) {
    case kinds.primary:
      classes.push('Button--primary');
      break;
    case kinds.secondary:
      classes.push('Button--secondary');
      break;
    case kinds.remove:
      classes.push('Button--remove');
      break;
    case kinds.simple:
      classes.push('Button--simple');
      break;
  }

  /** Add Button Size Modifiers * */
  switch (size) {
    case sizes.tiny:
      classes.push('Button--tiny');
      break;
    case sizes.small:
      classes.push('Button--small');
      break;
    case sizes.normal:
      classes.push('Button--normal');
      break;
    case sizes.big:
      classes.push('Button--big');
      break;
  }

  const tooltip = customTooltip || label || iconDesc;

  if (tooltip)
    return (
      <Tippy className={tooltipClassName} content={tooltip} placement={tooltipPlacement}>
        <Element
          className={cx(
            classes,
            { 'Button--icon': icon, 'Button--rounded': isRounded, 'Button--fullWidth': fullWidth },
            `t-${testHook}`,
          )}
          disabled={disabled}
          href={href}
          onClick={onClick}
          type={type}
          role={role}
          {...other}
        >
          {icon && <Icon className={cx('Button-icon', iconClassName)} size={iconSize} icon={icon} color={color} />}
          {children}
        </Element>
      </Tippy>
    );

  return (
    <span>
      <Element
        className={cx(
          classes,
          { 'Button--icon': icon, 'Button--rounded': isRounded, 'Button--fullWidth': fullWidth },
          `t-${testHook}`,
        )}
        disabled={disabled}
        href={href}
        onClick={onClick}
        type={type}
        role={role}
        {...other}
      >
        {icon && <Icon className={cx('Button-icon', iconClassName)} size={iconSize} icon={icon} color={color} />}
        {children}
      </Element>
    </span>
  );
};

const createIconButton =
  (icon: React.ComponentType<IconBaseProps>) =>
  ({ ...props }: Omit<ButtonProps, 'icon'>) => <Button icon={icon} {...props} />;

export const ButtonDelete = createIconButton(FaTrash);
export const ButtonEdit = createIconButton(MdEdit);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([sizes.tiny, sizes.small, sizes.normal, sizes.big]),
  kind: PropTypes.oneOf([kinds.primary, kinds.secondary, kinds.remove, kinds.simple, null]),
  href: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  role: PropTypes.string,
  icon: PropTypes.func,
  iconDesc: PropTypes.string,
  iconSize: PropTypes.number,
  isRounded: PropTypes.bool,
  testHook: PropTypes.string,
};

export default Button;
