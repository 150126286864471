import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { DataFeed, DataFeedListType, DataFeedRestResponseType, FeedVariable, ImportVariablesPayload } from './types';

const BASE_PATH = `${API_BASE}/content/dataFeed`;

export const fetchDataFeeds = (): Promise<DataFeedListType[]> => Api.callGet(`${BASE_PATH}`).then(parseResponse);

export const fetchDataFeedById = (dataFeedId: string): Promise<DataFeed> =>
  Api.callGet(`${BASE_PATH}/${dataFeedId}`).then(parseResponse);

export const createDataFeed = (payload: DataFeed): Promise<DataFeedListType> =>
  Api.callPost(`${BASE_PATH}`, payload).then(parseResponse);

export const updateDataFeed = (payload: DataFeed): Promise<DataFeedRestResponseType> =>
  Api.callPost(`${BASE_PATH}/update`, payload).then(parseResponse);

export const deleteDataFeed = (dataFeedId: string): Promise<DataFeedRestResponseType> =>
  Api.callDelete(`${BASE_PATH}/${dataFeedId}`).then(parseResponse);

export const fetchImportVariables = (dataFeedId: string): Promise<FeedVariable[]> =>
  Api.callGet(`${BASE_PATH}/${dataFeedId}/importVariables`).then(parseResponse);

export const addImportVariables = (payload: ImportVariablesPayload): Promise<DataFeedRestResponseType> =>
  Api.callPost(`${BASE_PATH}/addVariables`, payload).then(parseResponse);

export const fetchSampleKeyData = (dataFeedId: string, searchString: string): Promise<any> =>
  Api.callGet(`${BASE_PATH}/${dataFeedId}/sample?key=${searchString}`).then(parseResponse);
