angular.module('webUi.ui.tagmanagement.tags.list', [
    'webUi.ui.tagmanagement.tags.base',
    'webUi.ui.tagmanagement.tags.list.newPath',
    'webUi.ui.tagmanagement.tags.list.newTagWarningPopup'
])

    .config(['$stateProvider', function config($stateProvider) {
        var sharedState = {
            leftMenu : true,
            views : {
                '@site.tagmanagement': {
                    controller: 'TagListViewController',
                    templateUrl: 'ui/tagmanagement/tags/list/list.tpl.html'
                }
            },
            resolve : {
                maxResults: ['$stateParams', function($stateParams) {
                    return _.parseInt($stateParams.maxResults, 10) || 25;
                }],
                currentPage: ['$stateParams', function($stateParams) {
                    return _.parseInt($stateParams.page, 10) || 0;
                }],
                tags : ['$stateParams', 'TagmanagementService', 'currentPage', 'maxResults', function($stateParams,   TagmanagementService) {
                    return TagmanagementService.getTagsListForLocation($stateParams.location);
                }],
                tagConditionTypes : ['TagmanagementService', function(TagmanagementService) {
                    return TagmanagementService.getTagConditionTypes();
                }],
                locationBreadcrumbs : ['$stateParams', 'TagmanagementService', function($stateParams,   TagmanagementService) {
                    return TagmanagementService.getLocationBreadcrumbs($stateParams.location);
                }],
                canAddNewTags: ['TagmanagementService', function(TagmanagementService){
                    return TagmanagementService.canAddNewTags();
                }]
            }
        };

        $stateProvider
            .state('site.tagmanagement.tags.list', _.defaultsDeep({
                // use regex for location (match everything & allow location to also contain /)
                url : '/list/{location:.*}?page&maxResults',
                resolve : {
                    selection : ['$stateParams', function($stateParams){
                        return  {
                            type: 'paths',
                            identifierParam: $stateParams.location,
                            locationUrlEncoded: encodeURIComponent($stateParams.location)
                        };
                    }]
                }
            }, sharedState));

        $stateProvider
            .state('site.tagmanagement.tags.all', _.defaultsDeep({
                url: '/all/?page&maxResults',
                resolve: {
                    selection : ['$stateParams', function(){
                        return  {
                            type: 'all'
                        };
                    }]
                }
            }, sharedState));
    }])

    .controller('TagListViewController', ['$scope', '$state', '$stateParams', 'TagmanagementService', 'PubSubService', 'tags', 'tagConditionTypes', 'locationBreadcrumbs', 'selection', 'canAddNewTags', 'hasTagEdit', 'currentPage', 'maxResults',
        function TagListViewController	($scope,   $state,   $stateParams,   TagmanagementService,   PubSubService,   tags,   tagConditionTypes,   locationBreadcrumbs,   selection,   canAddNewTags,   hasTagEdit,   currentPage,	maxResults) {

            // force lowercase
            if($stateParams.location) {
                $stateParams.location = $stateParams.location.toLowerCase();
            }

            $scope.location = $stateParams.location;
            $scope.showDetails = $stateParams.location !== TagmanagementService.GLOBAL_PATH;
            $scope.canAddNewTags = canAddNewTags;
            $scope.contains = function contains(arr, item){
                return _.contains(arr, item);
            };
            $scope.tags = tags;
            $scope.tagConditionTypes = tagConditionTypes;
            $scope.locationBreadcrumbs = locationBreadcrumbs;

            $scope.selection = selection;
            $scope.order = {
                field: 'priority',
                reverse: false
            };
            $scope.paginginfo = {
                page : currentPage,
                perPage : maxResults
            };

            var evaluateChange = function(parameter, newValue, oldValue) {
                // If we have NOT changed the page, return
                if ( newValue === oldValue){
                    return;
                }
                var oldParams = _.cloneDeep($stateParams);
                oldParams[parameter] = newValue;

                // Either show all tags, or go to the selected path
                if (_.isUndefined(oldParams.location)) {
                    $state.go('site.tagmanagement.tags.all', oldParams, {reload: false, notify: false});
                } else {
                    $state.go('site.tagmanagement.tags.list', oldParams, {reload: false, notify: false});
                }
            };

            $scope.$watch('paginginfo.page', evaluateChange.bind(this, 'page'), true);
            $scope.$watch('paginginfo.perPage', evaluateChange.bind(this, 'maxResults'), true);

            //notify the menu that the menu type has changed
            PubSubService.publishTagMenuLocationChanged($scope.selection);

            // check for paths that were not added to the managed paths (by pressing "Cancel" in the popup for adding a new path)
            PubSubService.subscribeNewPathNotAdded($scope, function(event, location) {
                $scope.notManagedPath = location;
            });

            PubSubService.subscribeForType(PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH, $scope, function() {
                $state.transitionToStateWithSite('site.tagmanagement.tags.list', {
                    location: TagmanagementService.GLOBAL_PATH
                });
            });

            $scope.selectTag = function(tag) {
                $state.transitionToStateWithSite('site.tagmanagement.tags.view', {
                    id: tag.tagId,
                    type: $scope.selection.type,
                    identifierParam: $scope.selection.locationUrlEncoded
                });
            };

            $scope.isExcluded = TagmanagementService.isExcludedPaths;

            $scope.pathName = function(path) {
                if(path === TagmanagementService.GLOBAL_PATH ) {
                    return TagmanagementService.GLOBAL_NAME;
                } else {
                    return path;
                }
            };

            // function to create new tag
            $scope.newTag = function() {
                if ( !canAddNewTags || !hasTagEdit ){
                    return;
                }

                if (!_.isUndefined($scope.notManagedPath) && _.isEqual($stateParams.location, $scope.notManagedPath)) {
                    // Show warning popup that new tag cannot be created for a path that is not managed
                    TagmanagementService.openNewTagWarningModal($scope.notManagedPath);
                    return;
                }

                var tagLocation;
                if ( _.isEmpty($stateParams.location) || $stateParams.location === TagmanagementService.GLOBAL_PATH){
                    tagLocation = {
                        clazz: TagmanagementService.LOCATIONS.GLOBAL
                    };
                } else {
                    tagLocation = {
                        clazz: TagmanagementService.LOCATIONS.PATHHIERARCHY,
                        path: $stateParams.location
                    };
                }
                TagmanagementService.createTagModal(tagLocation);

            };

            $scope.$on('hotKeyEvent', function(event, data) {
                // activate newTag on n
                if(!data.altKey && data.charLower === 'n') {
                    $scope.newTag();
                }
            });

            $scope.toggleStatus = function(tag){
                TagmanagementService.updateEnabled(tag.tagId, !tag.status).then(function(){
                    tag.status = !tag.status;
                });
            };
        }
    ]);
