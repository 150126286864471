import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import * as fromModals from '~/modals';
import { VARIABLE_EVENTS } from '~/gaActions';
import { reduxForm, formValueSelector, getFormSyncErrors, getFormSubmitErrors } from 'redux-form';
import {
  fetchVariables,
  deleteVariable,
  saveVariable,
  changeDependantTypesSelectedTab,
  showDependantsContainer,
  hideDependantsContainer,
} from '../../actions';
import {
  getVariables,
  isFetchingVariables,
  isDependantsContainerVisible,
  getVariableById,
  getSelectedVariableId,
  getSelectedTab,
} from '../../selectors';
import VariablesSection from './presenter';

const selector = formValueSelector('variablesNew');

const mapStateToProps = state => ({
  stateKey: 'PROFILES/VARIABLES',
  list: getVariables(state),
  isFetching: isFetchingVariables(state),
  variableType: selector(state, 'variableType'),
  formSyncErrors: getFormSyncErrors('variablesNew')(state),
  formSubmitErrors: getFormSubmitErrors('variablesNew')(state),
  dependantsContainerIsVisible: isDependantsContainerVisible(state),
  selectedVariable: getVariableById(state, getSelectedVariableId(state)),
  selectedTab: getSelectedTab(state),
  submitErrors: getFormSubmitErrors('variablesNew')(state),
});

const mapDispatchToProps = dispatch => ({
  fetchVariables: () => dispatch(fetchVariables()),
  onSubmit: variable => dispatch(saveVariable(variable)),
  onDeleteClick: (variable, t) => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: t('deleteModal.title'),
        message: t('deleteModal.message', { title: variable.name }),
        onConfirm: () => dispatch(deleteVariable(variable.variableId)),
        gaAction: VARIABLE_EVENTS.DELETED,
      }),
    );
  },
  onDependantsTabChange: selectedTab => {
    dispatch(changeDependantTypesSelectedTab(selectedTab));
  },
  onDependantIconClick: (variableId, dependantType) => {
    dispatch(showDependantsContainer(variableId, dependantType));
  },
  onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'variablesNew',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate('variables'),
)(VariablesSection);
