angular.module('webUi.ui.tagmanagement.tags.paths.add', [
    'ngSanitize'
])

    .controller('TagWebsitePathAddController', ['$scope', 'dialogsModel', '$uibModalInstance', '$sanitize','Utils',
        function TagWebsitePathAddController($scope, dialogsModel,   $uibModalInstance,   $sanitize, Utils) {

            $scope.websitePath = {};

            /**
         * Check errors before call typeahead function
         * @param viewValue
         * @param currentPath
         * @returns {Array}
         */
            $scope.getChildPaths = function(viewValue, currentPath) {
                var trusted;
                var result = [];
                var canCall = true;
                try {
                    trusted = $sanitize(viewValue);
                } catch (error) {
                    canCall = false;
                }
                if (canCall) {
                    result = dialogsModel.getChildPaths(trusted, currentPath);
                }

                result.then(function(result) {
                    return result.plain();
                });
            };

            $scope.currentPath = dialogsModel.currentPath;

            $scope.processPath = function(path) {
                return path.toLowerCase();
            };

            $scope.cancel = function() {
                $uibModalInstance.close(false);
            };
            $scope.save = function() {
                $scope.websitePath.name = $scope.websitePath.name.toLowerCase();
                var onSaveCallbackResult = dialogsModel.onSave($scope.websitePath);
                if(Utils.isPromise(onSaveCallbackResult)) {
                    onSaveCallbackResult.then(function(result) {
                        $uibModalInstance.close(result);
                    });
                } else {
                    $uibModalInstance.close(true);
                }

            };

            /**
         * Validate the path doesn't contain %
         * @param {type} value
         * @returns {undefined}
         */
            $scope.isValid = function(value) {
                if (!value) {
                    return true;
                }
                return !_.contains(value, '%');
            };

        }
    ]);
