import React, { useState } from 'react';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import cx from 'classnames';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import BtnIcon from '~/components/src/BtnIcon';
import { DataFeed, FeedVariablesProps } from '../types';

export const FeedVariables = ({
  setFieldValue,
  setFieldError,
  values,
  errors,
}: FeedVariablesProps): React.ReactElement => {
  const [showActions, setShowActions] = useState(false);

  const addFeedVariable = (values: DataFeed) => {
    const feedVariable = values.feedVariableName;
    if (!feedVariable) {
      setFieldError('feedVariableName', i18n.t('dataFeeds:validations.feedVariableIsNotEmpty'));
    } else if (values.feedVariables.find(e => e.name === feedVariable))
      setFieldError('feedVariableName', i18n.t('dataFeeds:validations.feedVariableExists'));
    else {
      setFieldValue('feedVariables', [...values.feedVariables, { name: feedVariable }]);
      setFieldValue('feedVariableName', '');
      setShowActions(false);
    }
  };

  const removeFeedVariable = (values: DataFeed, feedVarableName: string) =>
    setFieldValue(
      'feedVariables',
      values?.feedVariables?.filter(variable => variable.name !== feedVarableName),
    );

  return (
    <div className="my-4 flex">
      <span className="basis-1/5 text-lg">{i18n.t('dataFeeds:form.feedVariables')}</span>
      <div className="min-w-[25%] flex-col">
        <div className="border-b-2 border-dashed border-slate-200 text-gray-500">
          {i18n.t('dataFeeds:form.variableName')}
        </div>
        {values?.feedVariables?.map((feedVariable, index) => (
          <div
            key={index}
            className={cx(
              index % 2 ? 'bg-slate-100' : 'bg-slate-50',
              'flex items-center justify-between gap-2 p-1 hover:bg-slate-200',
            )}
          >
            <span>{feedVariable.name}</span>
            <BtnIcon
              className="right"
              icon="delete"
              onClick={() => removeFeedVariable(values, feedVariable.name)}
            ></BtnIcon>
          </div>
        ))}

        {showActions ? (
          <div className="mt-4 flex w-full items-center gap-2">
            <Field
              className="w-full"
              name="feedVariableName"
              as={FormikInputField}
              errorText={errors.feedVariableName}
            />
            <BtnIcon icon="close" color="gray" onClick={() => setShowActions(false)} />
            <BtnIcon
              color="blue"
              icon="done"
              testHook="addFeedVariableAction"
              onClick={() => addFeedVariable(values)}
            ></BtnIcon>
          </div>
        ) : (
          <BtnOutlined
            className="mb-7 mt-4 pr-6"
            onClick={() => {
              setShowActions(true);
            }}
            icon="add"
            testHook="addFeedVariable"
          >
            {i18n.t('dataFeeds:actions.newFeedVariable')}
          </BtnOutlined>
        )}
      </div>
    </div>
  );
};
