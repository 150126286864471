import i18n from '~/i18n';
import constants from '~/profiles/connectors/form/constants';

const { errorStackColors, infoStackColors } = constants;

// For dynamic red with saturation apply
// const difference = i * 40;
// `rgb(${155 + difference}, ${10 + difference}, ${20 + difference})`

const generateErrorStack = errors =>
  Object.keys(errors).map((key, i) => ({
    name: key,
    data: errors[key],
    stack: 'Error',
    color: errorStackColors[i % errorStackColors.length],
  }));

const generateInfoStack = info =>
  Object.keys(info).map((key, i) => ({
    name: key,
    data: info[key],
    stack: 'Info',
    color: infoStackColors[i % infoStackColors.length],
  }));
const parseStatsCount = (array, field) => {
  const item = _.find(array, { status: field });
  return item ? item.count : 0;
};

const parseError = (length, index, stat, errors) => {
  const errorList = _.filter(
    stat.statusCounts,
    item => item.status !== 'ADD' && item.status !== 'REMOVE' && !item.status.toLowerCase().startsWith('info'),
  );
  errorList.forEach(item => {
    if (!(errors[item.status] && errors[item.status].length)) {
      errors[item.status] = new Array(length).fill(0);
    }
    errors[item.status][index] = item.count;
  });
};

const parseInfo = (length, index, stat, info) => {
  const infoList = _.filter(
    stat.statusCounts,
    item => item.status !== 'ADD' && item.status !== 'REMOVE' && item.status.toLowerCase().startsWith('info'),
  );
  infoList.forEach(item => {
    if (!(info[item.status] && info[item.status].length)) {
      info[item.status] = new Array(length).fill(0);
    }
    info[item.status][index] = item.count;
  });
};

export const prepareForDisplay = stats => {
  if (!stats) return {};

  const statDays = [];
  const added = [];
  const removed = [];
  const info = [];
  const errors = {};
  stats.forEach((stat, i) => {
    statDays.push(stat.day);
    added.push(parseStatsCount(stat.statusCounts, 'ADD'));
    removed.push(parseStatsCount(stat.statusCounts, 'REMOVE'));
    parseInfo(stats.length, i, stat, info);
    parseError(stats.length, i, stat, errors);
  });

  const series = [
    {
      name: i18n.t('audiences:chart.labelAdded'),
      data: added,
      stack: 'Added',
      color: '#417505',
    },
    {
      name: i18n.t('audiences:chart.labelRemoved'),
      data: removed,
      stack: 'Removed',
      color: '#0054b0',
    },
    ...generateInfoStack(info),
    ...generateErrorStack(errors),
  ];

  return {
    xAxis: statDays.map(day => moment(day).format('D. MMM')),
    series,
    noDataMessage: i18n.t('audiences:chart.noStats'),
  };
};
