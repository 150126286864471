import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import i18n from '~/i18n';
import { twMerge } from 'tailwind-merge';
import Icons from '../Icons';
import BtnIcon from '../BtnIcon';

const Notification = ({ children, className, kind = 'default', header, testHook = '', onClose }) => {
  const styles = {
    default: {
      container: 'border-gray-200 bg-gray-100 text-slate-600 dark:text-slate-200',
      button: 'border-gray-200 hover:bg-gray-500 hover:text-white focus:ring-gray-300',
      icon: 'text-slate-600',
    },
    information: {
      container: 'border-blue-200 bg-blue-50 text-slate-600 dark:text-slate-200',
      button: 'border-blue-200 hover:bg-blue-500 hover:text-white focus:ring-blue-300',
      icon: 'text-blue-600',
    },
    warning: {
      container: 'border-orange-200 bg-orange-50 text-slate-600 dark:text-slate-200',
      button: 'border-orange-200 hover:bg-orange-500 hover:text-white focus:ring-orange-300',
      icon: 'text-orange-600',
    },
    error: {
      container: 'border-red-200 bg-red-50 text-slate-600 dark:text-slate-200',
      button: 'border-red-200 hover:bg-red-500 hover:text-white focus:ring-red-300',
      icon: 'text-red-600',
    },
    success: {
      container: 'border-green-200 bg-green-50 text-slate-600 dark:text-slate-200',
      button: 'border-green-200 hover:bg-green-500 hover:text-white focus:ring-green-300',
      icon: 'text-green-600',
    },
  };

  return (
    <article
      className={twMerge(
        cx(
          'mb-2 flex items-start gap-x-6 rounded-lg border p-4',
          styles[kind].container,
          className,
          `t-${testHook || 'notification'}`,
        ),
      )}
    >
      <div className="flex flex-grow flex-col gap-y-2">
        <div className="flex items-center gap-4">
          <Icons icon={kind} className={cx('h-6 w-6', styles[kind].icon)} />
          <div className="flex-1">
            {header && <h3 className="t-notificationHead text-lg font-medium">{header}</h3>}
            {!header && <div className="t-notificationBody text-lg font-light">{children}</div>}
          </div>
        </div>
        {header && <div className="t-notificationBody font-light">{children}</div>}
      </div>

      {onClose && (
        <BtnIcon
          icon="close"
          onClick={onClose}
          className="h-6"
          tooltip={i18n.t('common:notifications.dismiss')}
          testHook="notificationDismiss"
        />
      )}
    </article>
  );
};

Notification.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.string,
  kind: PropTypes.oneOf(['information', 'warning', 'error', 'success']),
  onClose: PropTypes.func,
};

export default Notification;
