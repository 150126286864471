import React from 'react';
import Page from '~/components/src/Page';
import CollectorsContainer from '../collectors/containers/CollectorsContainer';

export const CollectorsViewPage = ({ canEditProfiles }) => (
  <Page>
    <CollectorsContainer canEditProfiles={canEditProfiles} />
  </Page>
);

export default CollectorsViewPage;
