import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { SearchContainer, buildUrl } from '~/common';
import UserInfo from '~/user/components/UserInfo';
import { kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import RouterTabs from '~/components/src/RouterTabs';
import SitesList from '~/context/components/SitesList';
import { Panel } from '~/components/src/Containers';

import * as settingsSelectors from '~/user/settings/selectors';
import { translate } from 'react-i18next';
import SecuritySettingsContainer from '~/user/settings/containers/SecuritySettings';
import { fetchUserSettingsIfNeeded } from '~/user/settings';
import { APIKeysList } from '~/context/components/APIKeys/APIKeysList';

const USER_ACCESSIBLE_SITES_KEY = 'USER/SETTINGS/USER_ACCESSIBLE_SITES';
const FILTER_KEY = 'USER/USER_ACCESSIBLE_SITES';

const filterFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.siteNumber.toString().indexOf(query.toLowerCase()) !== -1 ||
  item.clientName.toLowerCase().indexOf(query.toLowerCase()) !== -1;

const getSiteName = (sites, siteId) => {
  const foundSite = sites.find(site => site.siteId === siteId);
  return foundSite && foundSite.name;
};

const getUserInfoWithDefaultSite = (userInfo, accessibleSites) => ({
  ...userInfo,
  defaultSite: getSiteName(accessibleSites, userInfo.defaultSiteId),
});

export class UserSettingsPage extends Component {
  componentDidMount() {
    this.props.fetchUserSettingsIfNeeded();
  }

  render() {
    const { userInfo, accessibleSites, isLoadingData, siteUrl, t } = this.props;

    return isLoadingData ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={t('view.header')} />
        <RouterTabs kind={kind.PageHeader}>
          <TabList>
            <Tab>{t('tabs.basicSettings')}</Tab>
            <Tab>{t('tabs.securitySettings')}</Tab>
            <Tab>{t('tabs.sites')}</Tab>
            <Tab>{t('tabs.apiAuthentication')}</Tab>
          </TabList>

          <TabPanel>
            <Panel>
              <UserInfo
                user={getUserInfoWithDefaultSite(userInfo, accessibleSites)}
                editBasicSettingsHref={buildUrl('user/settings/edit')}
                t={t}
              />
            </Panel>
          </TabPanel>

          <TabPanel>
            <SecuritySettingsContainer user={userInfo} t={t} />
          </TabPanel>

          <TabPanel>
            <Heading title={t('sites.header')}>
              <SearchContainer stateKey={USER_ACCESSIBLE_SITES_KEY} searchKey={FILTER_KEY} searchFn={filterFn} />
            </Heading>
            <SitesList list={accessibleSites} siteUrl={siteUrl} stateKey={USER_ACCESSIBLE_SITES_KEY} t={t} />
          </TabPanel>

          <TabPanel>
            <APIKeysList t={t} />
          </TabPanel>
        </RouterTabs>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      userInfo: settingsSelectors.getUserInfo(state),
      accessibleSites: settingsSelectors.getAccessibleSites(state),
      isLoadingData:
        settingsSelectors.isFetchingUserSettings(state) || settingsSelectors.shouldFetchUserSettings(state),
      siteUrl: item => buildUrl(`context/sites/view/${item.siteId}`),
    }),
    dispatch => ({
      fetchUserSettingsIfNeeded: () => dispatch(fetchUserSettingsIfNeeded()),
    }),
  ),
  translate(['settings']),
)(UserSettingsPage);
