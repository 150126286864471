import { get } from 'lodash';

/**
 * Get All Collectors
 * @param state
 */
export const getCollectors = state => state.profiles.collectors.allIds.map(id => state.profiles.collectors.byId[id]);

/**
 * Is fetching all collectors currently in progress?
 * @param state
 */
export const isFetchingCollectors = state => get(state, 'profiles.collectors.ui.isFetchingAll');

/**
 * Get Collector By Id
 */
export const getCollectorById = (state, collectorId) => get(state, `profiles.collectors.byId[${collectorId}]`);

/**
 * Get Code Samples By Id
 */
export const getCodeSamplesById = (state, collectorId) =>
  get(state, `profiles.collectors.codeSampleById[${collectorId}]`);
