angular.module('webUi.service.iplock', [
    'restangular'
])

/**
 *
 * A service for IPLock related requests
 */
    .factory('IPLockService', ['Restangular', function (Restangular) {

        var BASE_URL = 'iplock';
        // use a separate Restangular instance that doesn't go through normal api calls
        var restangularInstance = Restangular.withConfig(function (Configurer) {
            Configurer.setBaseUrl(BASE_URL);
        });
        return {
            BASE_URL: BASE_URL,

            sendSmsAuthCode: function () {
                return restangularInstance.one('sendSmsAuthCode').post();
            },

            attemptLogin: function (smsCode) {
                return restangularInstance.one('login').post('', {
                    smsCode: smsCode
                });
            },
            attemptLoginGoogleAuth: function (googleAuthCode) {
                return restangularInstance.one('loginGoogleAuth').post('', {
                    googleAuthCode: googleAuthCode
                });
            },

            getUserInfo: function () {
                return restangularInstance.one('userInfo').get();
            }
        };
    }]);
