import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '~/common';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';

type TriggerAnalysisProps = { triggerAnalysis: any[]; isGoal: boolean; isGoalOrExit: string };

const columnHelper = createColumnHelper<any>();

const TriggerAnalysis = ({ triggerAnalysis, isGoal, isGoalOrExit }: TriggerAnalysisProps) => {
  const getColumns = [
    columnHelper.accessor('nodeName', {
      header: () => <div className="text-left">{i18n.t('workflow:orchestration.triggerStats.branch')}</div>,
      cell: info => <div className="t-branch text-left">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('experimentVariantName', {
      header: () => <div className="text-left">{i18n.t('workflow:orchestration.triggerStats.variantGroup')}</div>,
      cell: info => <div className="t-expVariantName text-left">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('profilesEntered', {
      header: () => <div className="text-right">{i18n.t('workflow:orchestration.triggerStats.entered')}</div>,
      cell: info => <div className="text-right">{formatNumber(info.getValue())}</div>,
      size: 400,
    }),
    columnHelper.accessor('profilesReached', {
      header: () => (
        <div className="text-right">
          {i18n.t(`workflow:orchestration.triggerStats.${isGoal ? 'reachedGoal' : 'exited'}`)}
        </div>
      ),
      cell: info => <div className="text-right">{formatNumber(info.getValue())}</div>,
      size: 400,
    }),
  ];

  return (
    <>
      {triggerAnalysis?.length > 0 && (
        <div className="mb-2 py-2">
          <h2 className="!my-4 text-xl text-gray-600">
            {i18n.t(`workflow:orchestration.triggerStats.${isGoalOrExit}Analysis`)}
          </h2>
          <UITable enableSorting data={triggerAnalysis ?? []} testHook="triggerAnalysis" columns={getColumns} />
        </div>
      )}
    </>
  );
};

export default TriggerAnalysis;
