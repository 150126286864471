import React from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import { IconType } from 'react-icons';
import Icon from '~/components/src/Icon';
import { Panel } from '~/components/src/Containers';
import './styles.scss';
import Btn from '../Btn';

interface EmptyProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  icon?: IconType;
  action?: ((...args: any[]) => void) | null;
  excludePanel?: boolean;
  iconColor?: string;
  iconSize?: number;
  className?: string;
}

const Empty: React.FC<EmptyProps> = ({
  className,
  header,
  icon,
  body,
  action,
  iconColor = '#CCCCCC',
  iconSize = 48,
  excludePanel = false,
}) => {
  const EmptyBody = () => (
    <>
      {icon && <Icon color={iconColor} icon={icon} size={iconSize} className="Empty-icon" />}
      {header && <h3 className="Empty-header text-lg font-medium">{header}</h3>}
      {body && <div className="Empty-body">{body}</div>}
      {action && (
        <Btn onClick={action} testHook="emptyActionButton">
          {i18n.t('generic:refresh')}
        </Btn>
      )}
    </>
  );
  return excludePanel ? (
    <div className={cx('Empty Empty-withoutPanel', className)}>
      <EmptyBody />
    </div>
  ) : (
    <Panel className={cx('Empty', className)}>
      <EmptyBody />
    </Panel>
  );
};

export default Empty;
