import React from 'react';
import { Formik, Field } from 'formik';
import { object } from 'yup';
import i18n from '~/i18n';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { getFormat, dateValidator } from './validation';
import Btn from '../Btn';
import Notification from '../Notification';

const dateValidationSchema = object().shape({
  date: dateValidator,
});

const formatDescriptions = () => [
  ['yyyy', '2014', i18n.t('forms:dateFormat.fourDigitYear')],
  ['M MM', '1..12', i18n.t('forms:dateFormat.monthNumber')],
  ['d dd', '1..31', i18n.t('forms:dateFormat.dayOfMonth')],
  ['x', '1410715640123', i18n.t('forms:dateFormat.timestampInMillis')],
  ['H HH', '0..23', i18n.t('forms:dateFormat.twentyFourHourTime')],
  ['h hh', '1..12', i18n.t('forms:dateFormat.twelveHourTime')],
  ['m mm', '0..59', i18n.t('forms:dateFormat.minutes')],
  ['s ss', '0..59', i18n.t('forms:dateFormat.second')],
  ['S', '0...9', i18n.t('forms:dateFormat.tenthOfSecond')],
  ['- / :', '- / :', i18n.t('forms:dateFormat.separatorsOfDatesTime')],
  [' ', ' ', i18n.t('forms:dateFormat.nonBreakingSpace')],
];

function DateValidator({
  initialValue,
  onChange,
}: {
  initialValue: string;
  onChange: (value: string) => void;
}): JSX.Element {
  return (
    <div className="mb-8 flex flex-col gap-4">
      <Notification kind="information">
        <p>{i18n.t('forms:dateFormat.description.details')}</p>
      </Notification>

      <div className="flex gap-12">
        <div className="flex flex-1 flex-col gap-4">
          <Notification>
            <p className="text-sm">{i18n.t('forms:dateFormat.description.example')}</p>
            <p className="text-sm font-medium">{i18n.t('forms:dateFormat.description.exampleHint')}</p>
            <p className="mt-4 text-sm">{i18n.t('forms:dateFormat.controls.tip')}</p>
            <p className="text-sm font-medium">{i18n.t('forms:dateFormat.controls.tipHint')}</p>
          </Notification>
          <div className="DateFormatModal-form">
            <Formik
              onSubmit={values => {
                onChange(values.date);
              }}
              validationSchema={dateValidationSchema}
              initialValues={{
                date: initialValue,
              }}
            >
              {({ errors, handleSubmit, isValid, dirty, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex gap-4">
                    <Field className="flex-1" name="date" as={FormikInputField} autoFocus={true} />
                    <Btn
                      color="blue"
                      disabled={!isValid || !dirty}
                      type="submit"
                      testHook="saveFormat"
                      className="mt-1 w-28"
                    >
                      {i18n.t('forms:dateFormat.controls.save')}
                    </Btn>
                  </div>
                  <Notification
                    testHook="dateErrorMessage"
                    kind={errors.date ? 'error' : 'success'}
                    className="border-0 p-2"
                  >
                    {errors.date ? (
                      errors.date
                    ) : (
                      <p>
                        {i18n.t('forms:dateFormat.controls.example')}{' '}
                        {isValid && getFormat(values.date)?.formattedString}
                      </p>
                    )}
                  </Notification>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex w-[40%] flex-col gap-2 rounded">
          {formatDescriptions().map(([input, example, description]) => (
            <div key={input} className="flex items-center gap-4">
              <span className="rounded bg-yellow-50 px-1 py-0.5 text-sm text-blue-600">{input}</span>
              <span className="text-sm">{description}</span>
              <span className="text-sm text-gray-400">{example}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DateValidator;
