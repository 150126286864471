import { Field, FormSection, reduxForm } from 'redux-form';

import './src/Badge';

import * as Tabs from './src/Tabs/index';

import Button, { kind as ButtonKind, sizes as ButtonSize } from './src/Button/index';
import Code, { mode as CodeMode } from './src/Code/index';
import Fields, { Detail, Details, Elements, Form, FormActions } from './src/Form';
import {
  R42Ad,
  R42Audience,
  R42CJ,
  R42Partners,
  R42Reset,
  R42Stats,
  R42Step,
  R42Profiles,
  R42Goal,
  R42Expired,
} from './src/R42Icons';
import RulesList, { RuleOptions } from './src/RuleList/index';
import FilterRulesList from './src/FilterRuleList/index';

import Block from './src/Block/Block';
import Breadcrumb from './src/Breadcrumb';
import ButtonGroup from './src/ButtonGroup/index';
import Card from './src/Card';
import Chart from './src/Charts';
import CircleIcon from './src/CircleIcon/index';
import ConnectorCard from './src/ConnectorCard';
import Divider from './src/Divider';
import Dropdown from './src/Dropdown';
import Empty from './src/Empty/index';
import Heading from './src/Heading/index';
import Icon from './src/Icon/index';
import Link from './src/Link/index';
import Notification from './src/Notification/index';
import OverlaySpinner from './src/OverlaySpinner/index';
import Page from './src/Page/index';
import PrivateRoute from './src/PrivateRoute';
import RoundedLabel from './src/RoundedLabel';
import RouterTabs from './src/RouterTabs/index';
import Search from './src/Search/index';
import Select from './src/Form/Elements/Select';
import Spinner from './src/Spinner/index';
import Steps from './src/Steps/index';
import StepsFooter from './src/StepsFooter/index';
import Tree from './src/Tree/index';
import LeftPanel from './src/LeftPanel/LeftPanel.tsx';
import RightPanel from './src/RightPanel/index';
import PageWithLeftMenu from './src/PageWithLeftMenu';
import StackedChart from './src/StackedChart';
import PickerView from './src/PickerView';
import RuleConnectorPicker from './src/RuleConnectorPicker';
import RuleCardGrid from './src/RuleCardGrid';
import ConnectorCardGrid from './src/ConnectorCardGrid';
import Tooltip from './src/Tooltip';
import FrequencySelect from './src/FrequencySelect';
import CardMessage from './src/CardMessage';
import DateRangePicker from './src/DateRangePicker';
import StatusTag from './src/StatusTag';
import SelectColumnFilter from './src/TableFilters/SelectColumnFilter';
import MultiSelectColumnFilter from './src/TableFilters/MultiSelectColumnFilter';
import NumberRangeColumnFilter from './src/TableFilters/NumberRangeColumnFilter';
import DateColumnFilter from './src/TableFilters/DateColumnFilter';
import GoogleButton from './src/GoogleButton';
import SkeletonLoader from './src/SkeletonLoader';

const Components = {
  Block,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Chart,
  CircleIcon,
  Code,
  ConnectorCard,
  Divider,
  Dropdown,
  Empty,
  Field,
  Form,
  FormActions,
  FormSection,
  Heading,
  Icon,
  Link,
  Notification,
  OverlaySpinner,
  Page,
  R42Ad,
  R42Audience,
  R42CJ,
  R42Partners,
  R42Reset,
  R42Stats,
  R42Step,
  R42Profiles,
  R42Goal,
  R42Expired,
  reduxForm,
  RoundedLabel,
  RouterTabs,
  RuleOptions,
  RulesList,
  FilterRulesList,
  Search,
  Select,
  Spinner,
  Steps,
  StepsFooter,
  Tabs,
  Tree,
  LeftPanel,
  RightPanel,
  PageWithLeftMenu,
  StackedChart,
  PickerView,
  RuleConnectorPicker,
  RuleCardGrid,
  ConnectorCardGrid,
  Tooltip,
  FrequencySelect,
  DateRangePicker,
  StatusTag,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  DateColumnFilter,
  MultiSelectColumnFilter,
  GoogleButton,
  SkeletonLoader,
};

const ButtonProps = {
  kind: ButtonKind,
  size: ButtonSize,
};

const CodeProps = {
  mode: CodeMode,
};

export default Components;

export {
  Block,
  Breadcrumb,
  Button,
  ButtonGroup,
  ButtonKind,
  ButtonProps,
  ButtonSize,
  Card,
  Chart,
  CircleIcon,
  Code,
  CodeMode,
  CodeProps,
  ConnectorCard,
  Detail,
  Details,
  Divider,
  Dropdown,
  Elements,
  Empty,
  Field,
  Fields,
  Form,
  FormActions,
  FormSection,
  Heading,
  Icon,
  Link,
  Notification,
  OverlaySpinner,
  Page,
  PrivateRoute,
  R42Ad,
  R42Audience,
  R42CJ,
  R42Partners,
  R42Reset,
  R42Stats,
  R42Step,
  R42Profiles,
  R42Goal,
  R42Expired,
  reduxForm,
  RoundedLabel,
  RouterTabs,
  RuleOptions,
  RulesList,
  FilterRulesList,
  Search,
  Select,
  Spinner,
  Steps,
  StepsFooter,
  Tabs,
  Tree,
  LeftPanel,
  RightPanel,
  PageWithLeftMenu,
  StackedChart,
  PickerView,
  RuleConnectorPicker,
  RuleCardGrid,
  ConnectorCardGrid,
  Tooltip,
  FrequencySelect,
  CardMessage,
  DateRangePicker,
  StatusTag,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  DateColumnFilter,
  MultiSelectColumnFilter,
  GoogleButton,
  SkeletonLoader,
};
