import React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { USER_EDIT_PERMISSIONS_KEY } from '~/permissions';
import { isPhoneNumber, isValidSubnet, defaultMaxInput } from '~/common';
import { ReduxFormInputField, ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import Detail from '~/components/src/Form/Detail/Default';
import Spinner from '~/components/src/Spinner';
import Permissions from '~/permissions/containers/PermissionsForm';
import './EditUserPermissionsToSiteContainer.scss';

export const EditUserPermissionsToSiteContainer = ({
  availablePermissions,
  canEditInfo,
  site,
  user,
  permissions,
  t,
}) => {
  const siteClientId = site && site.clientId;
  const userClientId = user && user.clientId;
  const hasSameClient = !!siteClientId && !!userClientId && siteClientId === userClientId;
  const canEdit = canEditInfo && hasSameClient;
  const detailAlignment = canEdit ? 'right' : 'left';
  const phonePart = !canEdit ? (
    <Detail align={detailAlignment} label={t('users:user.phoneNumber')} value={user.phoneNumber} />
  ) : (
    <Field
      label={t('users:user.phoneNumber')}
      name="phoneNumber"
      component={ReduxFormInputField}
      placeholder={t('users:user.phoneNumber')}
      validate={[isPhoneNumber, defaultMaxInput]}
    />
  );
  const subnetPart = !canEdit ? (
    <Detail label={t('users:user.restrictedSubnets')} value={user.restrictedSubnets} />
  ) : (
    <Field
      className="mb-4"
      label={t('users:userForm.restrictedSubnets.label')}
      name="restrictedSubnets"
      component={ReduxFormTextAreaField}
      placeholder={t('users:userForm.restrictedSubnets.placeholder')}
      hint={t('users:userForm.restrictedSubnets.description')}
      validate={[isValidSubnet, defaultMaxInput]}
    />
  );

  return !site || !user ? (
    <Spinner />
  ) : (
    <div className="EditUserPermissionsToSiteContainer">
      <Detail align={detailAlignment} label={t('users:editPermissions.siteName')} value={site.name} />
      <Detail align={detailAlignment} label={t('users:user.email')} value={user.username} />
      <Detail align={detailAlignment} label={t('users:user.name')} value={user.name} />
      {phonePart}
      {subnetPart}
      <Field
        name="permissions"
        component={Permissions}
        availablePermissions={availablePermissions}
        permissions={permissions}
        t={t}
      />
    </div>
  );
};

export default compose(
  reduxForm({
    form: USER_EDIT_PERMISSIONS_KEY,
  }),
)(EditUserPermissionsToSiteContainer);
