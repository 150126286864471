import newExportActionTypes from './newExportActionTypes';
import newExportInitialState from './newExportInitialState';

const newExportReducer = (state = newExportInitialState, action) => {
  switch (action.type) {
    case newExportActionTypes.SET_IS_REMOTE_CONNECTION_SUCCESSFUL:
      return state.set('isRemoteConnectionSuccessful', action.isRemoteConnectionSuccessful);
    case newExportActionTypes.SET_ARE_CONNECTION_CREDENTIALS_CHECKED:
      return state.set('areConnectionCredentialsChecked', action.areConnectionCredentialsChecked);
    case newExportActionTypes.FETCH_REQUEST_TYPES_START:
      return state.set('isFetchingRequestTypes', true);
    case newExportActionTypes.FETCH_REQUEST_TYPES_FAIL:
      return state.set('isFetchingRequestTypes', false);
    case newExportActionTypes.FETCH_REQUEST_TYPES_SUCCESS:
      return state.set('isFetchingRequestTypes', false).set('requestTypes', action.data);
    case newExportActionTypes.FETCH_EXPORT_TYPES_START:
      return state.set('isFetchingExportTypes', true);
    case newExportActionTypes.FETCH_EXPORT_TYPES_FAIL:
      return state.set('isFetchingExportTypes', false);
    case newExportActionTypes.FETCH_EXPORT_TYPES_SUCCESS:
      return state.set('isFetchingExportTypes', false).set('exportTypes', action.data);
    case newExportActionTypes.FETCH_ENGAGEMENT_TYPES_START:
      return state.set('isFetchingEngagementTypes', true);
    case newExportActionTypes.FETCH_ENGAGEMENT_TYPES_FAIL:
      return state.set('isFetchingEngagementTypes', false);
    case newExportActionTypes.FETCH_ENGAGEMENT_TYPES_SUCCESS:
      return state.set('isFetchingEngagementTypes', false).set('engagementTypes', action.data);
    case newExportActionTypes.CREATE_NEW_REQUEST_START:
      return state.set('isCreatingNewRequest', true);
    case newExportActionTypes.CREATE_NEW_REQUEST_FAIL:
      return state.set('isCreatingNewRequest', false);
    case newExportActionTypes.CREATE_NEW_REQUEST_SUCCESS:
      return state.set('isCreatingNewRequest', false);
    case newExportActionTypes.FETCH_EDIT_EXPORT_START:
      return state.set('isFetchingEditExport', true);
    case newExportActionTypes.FETCH_EDIT_EXPORT_FAIL:
      return state.set('isFetchingEditExport', false);
    case newExportActionTypes.FETCH_EDIT_EXPORT_SUCCESS:
      return state.set('isFetchingEditExport', false);
    case newExportActionTypes.SET_EDIT_EXPORT_DATA:
      return state.set('editExportData', action.editExportData).set('exportId', action.exportId);
    default:
      return state;
  }
};

export default newExportReducer;
