import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from '~/common/withRouter';
import { groupBy, get } from 'lodash';
import { translate } from 'react-i18next';
import RuleCardGrid from '~/components/src/RuleCardGrid';
import { getIcon } from '~/common/modules/rules/utils';
import PickerView from '~/components/src/PickerView';
import Page from '~/components/src/Page';
import { Content } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import { changeUrl } from '~/common/history';
import ActionButtons from '~/components/src/ActionButtons';
import { transformerToPayload } from '~/profiles/connectors/form/partners/facebookConversions/core/utils';
import { getConnectorListPath } from '~/profiles/connectors/containers/commonActions';
import { EVENT_CONNECTOR_CATEGORY } from '~/profiles/connectors/types';
import { EventType } from '~/profiles/connectors/form/partners/facebookConversions/core/transformerTypes';
import { Notification, Spinner } from '~/components';
import { showSuccess } from '~/notificationCenter';
import { SetIsPickerVisibleContext } from '~/components/src/RuleConnectorPicker/context';
import BtnOutlined from '~/components/src/BtnOutlined';
import Event from './Event';
import { EVENTS } from './constants';
import ValueTransformationsModal from './ValueTransformationsModal';
import { saveTransformerConfiguration } from '../core/api';
import withTransformer from './withTransformer';
import { APIValidationErrorType, EventConnectorTransformationsPageProps } from './types';
import './EventConnectorTransformationsPage.scss';

const EventConnectorTransformationsPage = ({
  transformer,
  t,
  params,
  navigate,
}: EventConnectorTransformationsPageProps) => {
  if (!transformer) {
    return <Spinner />;
  }

  const [wasSubmitClicked, setWasSubmitClicked] = useState<boolean>(false);
  const [apiValidationErros, setApiValidationErrors] = useState<any>(null);

  const { eventConnectorId } = params;

  const { events } = transformer;

  const addableEvents = transformer.listAddableEvents();

  const menuTree = Object.values(groupBy(addableEvents, 'eventType')).map(eventsGroup => {
    const type = eventsGroup[0].eventType;

    return {
      key: type,
      title: EVENTS[type].title,
      subtitle: EVENTS[type].subtitle,
      icon: getIcon(EVENTS[type].capitalizedType),
      subMenu: eventsGroup.map(event => ({
        type: event.eventType,
        title: event.eventName,
        key: event.eventName,
      })),
    };
  });

  const hasEvents = events.length > 0;

  const [isEventPickerVisible, setIsEventPickerVisible] = useState(!hasEvents);

  const [mappingPathBeingEdited, setMappingPathBeingEdited] = useState<string>('');

  const openValueTransformationsModal = (mappingPath: string) => {
    setMappingPathBeingEdited(mappingPath);
  };

  const closeValueTransformationsModal = () => {
    setMappingPathBeingEdited('');
  };

  useEffect(() => {
    if (!hasEvents) {
      setIsEventPickerVisible(true);
    }
  }, []);

  return (
    <Page>
      <Content mdOffset={0} lgOffset={0} xlOffset={1} xxlOffset={2} mdSpan={12} lgSpan={12} xlSpan={10} xxlSpan={8}>
        <Heading
          title={t('partners:form.configureTransformations')}
          crumbs={[
            {
              title: t('partners:backToConnectorConfiguration'),
              onClick: () => {
                changeUrl(`profiles/partners/editEvent/${eventConnectorId}`, false);
              },
            },
          ]}
        />

        <div className="EventConnectorTransformationsPage-events flex flex-col gap-4">
          {events &&
            events.map((event, eventIndex) => (
              <Event
                event={event}
                eventPath={`[${eventIndex}]`}
                deleteEvent={transformer.deleteEvent}
                getValidationErrorByPath={transformer.getValidationErrorByPath}
                validationErrors={transformer.validationErrors}
                key={event.id}
                openValueTransformationsModal={openValueTransformationsModal}
                wasSubmitClicked={wasSubmitClicked}
                apiValidationError={apiValidationErros?.find(
                  (err: APIValidationErrorType) => err.eventName === event.name,
                )}
                t={t}
              />
            ))}
          {isEventPickerVisible && (
            <PickerView
              className="EventConnectorTransformationsPage-eventPicker"
              handlePickerVisibility={hasEvents ? setIsEventPickerVisible : undefined}
              pickerTitle={t('partners:eventConnector.chooseEventType')}
            >
              <SetIsPickerVisibleContext.Provider value={setIsEventPickerVisible}>
                <RuleCardGrid
                  menuTree={menuTree}
                  setIsPickerVisible={setIsEventPickerVisible}
                  onSelect={({ type, title }: { type: EventType; title: string }) => {
                    transformer.addEvent(type, title);
                  }}
                />
                {!hasEvents && (
                  <>
                    <Notification testHook="skipButtonWarning" kind="warning">
                      {t('partners:eventConnector.skipButtonWarning')}
                    </Notification>
                    <BtnOutlined
                      size="xs"
                      className="EventConnectorTransformationsPage-skipButton"
                      onClick={e => {
                        e.preventDefault();
                        showSuccess({ header: t('partners:form.notification.connectorSuccessfullyCreated') });
                        changeUrl(getConnectorListPath(EVENT_CONNECTOR_CATEGORY), false);
                      }}
                    >
                      {t('partners:form.skip')}
                    </BtnOutlined>
                  </>
                )}
              </SetIsPickerVisibleContext.Provider>
            </PickerView>
          )}
          {mappingPathBeingEdited && (
            <ValueTransformationsModal
              mapping={get(transformer, `events.${mappingPathBeingEdited}`)}
              mappingPath={mappingPathBeingEdited}
              closeValueTransformationsModal={closeValueTransformationsModal}
              getValidationErrorByPath={transformer.getValidationErrorByPath}
              t={t}
            />
          )}
        </div>
        <div className="EventConnectorTransformationsPage-addEventButtonContainer">
          <BtnOutlined
            className="EventConnectorTransformationsPage-addEventButton"
            size="xs"
            icon="add"
            onClick={e => {
              e.preventDefault();
              setIsEventPickerVisible(true);
            }}
          >
            {t('partners:eventConnector.addEvent')}
          </BtnOutlined>
        </div>
        {wasSubmitClicked && !transformer.isTransformerValid() && (
          <Notification className="my-4" kind="error">
            {t('partners:form.transformerIsNotValid')}
          </Notification>
        )}
        <ActionButtons
          onConfirm={async () => {
            if (!wasSubmitClicked) {
              setWasSubmitClicked(true);
            }

            if (transformer.isTransformerValid()) {
              const payload = transformerToPayload(transformer);
              try {
                await saveTransformerConfiguration(eventConnectorId, payload);
                showSuccess({ header: t('common:notifications.success') });
                changeUrl(getConnectorListPath(EVENT_CONNECTOR_CATEGORY), false);
              } catch (err) {
                setApiValidationErrors(err.status?.error?.validations);
              }
            }
          }}
          onDecline={() => navigate(-1)}
        />
      </Content>
    </Page>
  );
};

export default compose(withRouter, translate(), withTransformer)(EventConnectorTransformationsPage);
