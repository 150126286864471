/* eslint-disable class-methods-use-this */
import { isArray } from 'lodash';
import Api, { API_BASE } from '~/common/ApiService';

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class AdminUsersClass {
  /**
   * Get all users that belong to current site
   * TODO: Should not be required to pass site number
   * @param siteNumber
   */
  getSiteUsers(siteNumber, siteClientId) {
    return Api.callGet(`${API_BASE}/security/siteContextByNumber/${siteNumber}`)
      .then(
        response =>
          response.data && response.data.users && Object.keys(response.data.users).map(key => response.data.users[key]),
      )
      .then(users =>
        users.map(user => ({
          ...user,
          hasSameClient: !!siteClientId && !!user.clientId && siteClientId === user.clientId,
          restrictedSubnets: isArray(user.restrictedSubnets)
            ? user.restrictedSubnets.join(', ')
            : user.restrictedSubnets,
        })),
      )
      .then(result =>
        result.sort((a, b) => {
          const nameA = `${a.username}`.toUpperCase();
          const nameB = `${b.username}`.toUpperCase();
          return nameA.localeCompare(nameB);
        }),
      );
  }

  /**
   * Create a new user and set permissions
   * TODO: Should be one call to the backend
   * @param email
   * @param model
   */
  inviteUser({ email, ...model }) {
    return Api.callPost(`${API_BASE}/admin/inviteUser`, { email })
      .then(result => {
        const payload = {
          userId: result.data,
          ...model,
        };
        return Promise.all([
          Api.callPost(`${API_BASE}/permission/updateSitePermissions`, payload),
          Promise.resolve(payload),
        ]);
      })
      .then(result => result[1]);
  }

  updateInfoUser(model) {
    const calls = [];
    const payload = { ...model, phoneNumber: model?.phoneNumber || null };

    if (model.isPhoneEdited) {
      calls.push(Api.callPost(`${API_BASE}/admin/users/phoneNumber`, payload));
    }

    if (model.isRestrictedSubnetsEdited) {
      calls.push(
        Api.callPost(`${API_BASE}/admin/users/restrictedSubnets`, {
          userId: model.userId,
          restrictedSubnets: `${model.restrictedSubnets}`.replace(/\s+/g, '').split(',') || [],
        }),
      );
    }

    if (calls.length) {
      return Promise.all(calls).then(() => ({ status: 'ok' }));
    }
    return Promise.resolve();
  }
}

const adminUsersService = new AdminUsersClass();
export default adminUsersService;
