angular.module('webUi.directive.styledCheckbox',
    [
        'app-templates'
    ])
    .directive('styledCheckbox', function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'directive/styledCheckbox/styledCheckbox.tpl.html',
            scope: {
                checked: '=styledCheckbox',
                checkboxDisabled: '=?disabled',
                toggle: '=?toggle'
            },
            link: function(scope) {
                var checked = scope.checked;
                var defaultToggle = function(){
                    if(scope.toggleEnabled) {
                        scope.checked = !(scope.checked);
                    }
                };
                if (_.isNull(checked) || _.isUndefined(checked) || !checked) {
                    checked = false;
                }
                scope.checked = checked;
                scope.toggleEnabled = _.isUndefined(scope.checkboxDisabled) ? true : !scope.checkboxDisabled;
                if (scope.toggle){
                    var callback = scope.toggle;
                    scope.toggle = function(){
                        callback.apply(scope);
                        defaultToggle();
                    };
                } else {
                    scope.toggle = defaultToggle;
                }

                scope.idCheckbox = 'checkbox-id' + _.random(0, 1000000);
            }
        };
    });
