/**
 *
 * @description A service for handling default chart colors and options
 * @namespace webUi.service.chart
 * @class webUi.ChartService
 */
angular.module('webUi.service.chart', [])
    .factory(
        'ChartService',
        /**
	 * @class {ChartService} ChartService
	 * @function
	 * @name ChartService
	 * @memberOf webUi.ChartService
	 *
	 * @returns {ChartService}
	 */
        function ChartService() {

            var colors = {
                WHITE: '#ffffff',
                RED: '#d31d1d',
                BLUE: '#068df4',
                YELLOW: '#e1ae14',
                GREEN: '#188142',
                ORANGE: '#d3751d',
                PURPLE: '#8e11a7',
                DARKGREY: '#666',
                LIGHTBLUE: '#83c7fc',
                LIGHTGREEN: '#0ad65c',
                SLATEBLUE: '#6c5ed0',
                DARKGREY2: '#999ea1',
                JOURNEY_GOAL: '#424447',
                LIGHTSLATEGRAY: '#8f9396',
                DIMGRAY: '#5e6062'
            };

            var _charts = {};

            /**
		 * Registers a chart in the registry
		 * @param chart
		 */
            var registerChart = function(chart) {
                if (!_charts[chart.options.r42ChartIdentifier]) {
                    _charts[chart.options.r42ChartIdentifier] = chart;
                }
            };

            /**
		 * Unregisters a chart in the registry
		 * @param chart
		 */
            var unregisterChart = function(chart) {
                if (_charts[chart.options.r42ChartIdentifier]) {
                    delete _charts[chart.options.r42ChartIdentifier];
                }
            };

            /**
		 * Retrieves a registered chart
		 * @param identifier
		 * @returns {*}
		 */
            var getChart = function(identifier) {
                if (_charts[identifier]) {
                    return _charts[identifier];
                }
            };

            /**
		 * Retrieves a color by index, if the index is more than the total num of colors,
		 * will return a mod color of the palette
		 * @param {Integer} colorIndex
		 * @returns {String} color
		 */
            var getColor = function(colorIndex){
                var _colorsAsArray = _.values(colors);
                return _colorsAsArray[colorIndex%(_colorsAsArray.length)];
            };

            var getPlotBands = function(days, color) {

                var data = [];
                while (days > 0) {
                    var date = moment().subtract(days, 'days');
                    if (date.isoWeekday() === 7) {

                        //moment's clone is faulty
                        var _to = moment(date.toDate()).endOf('day').toDate();
                        var _from = date.subtract(1, 'days').startOf('day').toDate();

                        data.push({
                            'to' :  _to,
                            'from' : _from,
                            'color' : color,
                            'label': {
                                'text': 'Weekend - ' + moment(_from).week(),
                                'style': {
                                    'color': '#ccc'
                                }
                            },
                            'id': 'day-' + days,
                            'borderColor': '#666'
                        });
                    }
                    days -= 1;
                }

                return data;
            };


            var getTooltipFormatter = function getTooltipFormatter () {
                var _return = '<span style="font-size:10px">' + moment(this.x).format('D. MMM') + '</span><table>';

                _.forEach(this.points , function(point){
                    var _pointYvalue = Math.abs(point.point.y);
                    _return += '<tr><td style="color:' + point.series.color + ';padding:0">' + point.series.name + ': </td>'
                        + '<td style="padding-left:30px;"><b>' + Highcharts.numberFormat(_pointYvalue, 0) + '</b></td></tr>';
                });

                _return += '</table>';
                return _return;
            };


            /**
		 * RMP API
		 */
            return {
                colors : colors,
                getColor: getColor,
                registerChart: registerChart,
                unregisterChart: unregisterChart,
                getChart: getChart,
                getPlotBands: getPlotBands,
                getTooltipFormatter: getTooltipFormatter
            };
        }
    );

