import { changeUrl } from '~/common';
import * as types from './types';
import AdminUsersService from './dataService.js';

/**
 * Fetch all users that belong to current site
 * @param siteNumber
 */
export const fetchSiteUsers =
  (siteNumber, siteClientId = null) =>
  dispatch => {
    dispatch({
      type: types.USERS_FETCH_START,
    });

    return AdminUsersService.getSiteUsers(siteNumber, siteClientId)
      .then(payload =>
        dispatch({
          type: types.USERS_FETCH_SUCCESS,
          payload,
        }),
      )
      .catch(error => dispatch({ type: types.USERS_FETCH_FAIL, error }));
  };

/**
 * Add new user to current site
 * @param site
 * @param user
 */
export const saveUser = (site, user) => dispatch => {
  if (!user || !user.email) return undefined;

  const model = {
    ...user,
    siteId: site.siteId,
  };

  dispatch({
    type: types.INVITE_USER_START,
  });

  return AdminUsersService.inviteUser(model)
    .then(payload => {
      dispatch({
        type: types.INVITE_USER_SUCCESS,
        payload,
      });
      // Go to users view
      changeUrl('admin/users');
    })
    .catch(error => dispatch({ type: types.INVITE_USER_FAIL, error }));
};

export const updateUserInfo = data => dispatch => {
  if (!data || !(data.isPhoneEdited || data.isRestrictedSubnetsEdited)) {
    return Promise.resolve();
  }

  dispatch({
    type: types.USER_UPDATE_START,
    payload: data,
  });

  return AdminUsersService.updateInfoUser(data)
    .then(() =>
      dispatch({
        type: types.USER_UPDATE_SUCCESS,
        payload: data,
      }),
    )
    .catch(error => dispatch({ type: types.USER_UPDATE_FAIL, error }));
};
