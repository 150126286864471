import i18n from '~/i18n';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const STATS_DATE_FORMAT = 'DD MMMM YYYY';
export const STATS_TIME_FORMAT = 'HH:mm';
export const DURATION_FORMAT = 'H[h] m[m] s[s]';
export const PAGE_SIZE = 50;

export const DEFAULTS = {
  INFERENCE_FREQUENCY: 1,
  TRAINING_FREQUENCY: 7,
  MIN_INFERENCE_FREQUENCY: 5,
} as const;

export const THEME_NAMES = [
  {
    name: 'Red / Green ',
    matchBgColor: 'bg-green-200',
    matchTextColor: 'text-gray',
    notMatchBgColor: 'bg-red-200',
    notMatchTextColor: 'text-gray',
  },
  {
    name: 'Yellow / Purple',
    matchBgColor: 'bg-purple-800',
    matchTextColor: 'text-white',
    notMatchBgColor: 'bg-yellow-400',
    notMatchTextColor: 'text-gray',
  },
];

export const MODEL_STATUS = {
  CREATED: 'Created',
  DEPLOYED: 'Deployed',
  DEPLOYING: 'Deploying',
  DELETING: 'Deleting',
  ERROR: 'Error',
  RETRACTING: 'Retracting', // Used for display purpose AKA Submitted for Retraction
  SUBMITTED: 'Submitted',
  SUBMITTED_FOR_RETRACTION: 'Submitted for Retraction',
  SUBMITTED_FOR_TRAINING: 'Submitted for training',
  TRAINING: 'Training',
  TRAINED: 'Trained',
} as const;

export const MODEL_ACTIONS = {
  AUDIT_LOG: 'AUDIT_LOG',
  DELETE: 'DELETE',
  DEPLOY: 'DEPLOY',
  EDIT: 'EDIT',
  RETRACT: 'RETRACT',
  STATS: 'STATS',
  TRAIN: 'TRAIN',
} as const;

export const MINUTES = {
  label: i18n.t('ai:modelsPage.frequencyTerm.minutes'),
  value: 'minutes' as const,
};

export const HOURS = {
  label: i18n.t('ai:modelsPage.frequencyTerm.hours'),
  value: 'hours' as const,
};

export const DAYS = {
  label: i18n.t('ai:modelsPage.frequencyTerm.days'),
  value: 'days' as const,
};

export const MONTHS = {
  label: i18n.t('ai:modelsPage.frequencyTerm.months'),
  value: 'months' as const,
};

export const DEPLOY_FREQUENCY_TERMS = [MINUTES, HOURS, DAYS, MONTHS];
export const TRAINING_FREQUENCY_TERMS = [DAYS, MONTHS];

export const WITH = { label: i18n.t('ai:modelsPage.targetLabelModes.with'), value: 'WITH' } as const;
export const WITHOUT = { label: i18n.t('ai:modelsPage.targetLabelModes.without'), value: 'WITHOUT' } as const;
export const MIXED = { label: i18n.t('ai:modelsPage.targetLabelModes.mixed'), value: 'MIXED' } as const;

export const TARGET_LABEL_MODES = [WITH, WITHOUT, MIXED];

export const EQUALS = { label: i18n.t('ai:modelsPage.constraints.equals'), value: 'EQUALS' } as const;
const LESS_THAN = { label: i18n.t('ai:modelsPage.constraints.lessThan'), value: 'LESS_THAN' } as const;
const LESS_THAN_EQUAL_TO = {
  label: i18n.t('ai:modelsPage.constraints.lessThanOrEqualTo'),
  value: 'LESS_THAN_EQUAL_TO',
} as const;
const GREATER_THAN = { label: i18n.t('ai:modelsPage.constraints.greaterThan'), value: 'GREATER_THAN' } as const;
const GREATER_THAN_EQUAL_TO = {
  label: i18n.t('ai:modelsPage.constraints.greaterThanOrEqualTo'),
  value: 'GREATER_THAN_EQUAL_TO',
} as const;
const CONTAINS = { label: i18n.t('ai:modelsPage.constraints.contains'), value: 'CONTAINS' } as const;
const STARTS_WITH = { label: i18n.t('ai:modelsPage.constraints.startsWith'), value: 'STARTS_WITH' } as const;
const ENDS_WITH = { label: i18n.t('ai:modelsPage.constraints.endsWith'), value: 'ENDS_WITH' } as const;

export const NUMERIC_CONSTRAINTS = [EQUALS, LESS_THAN, LESS_THAN_EQUAL_TO, GREATER_THAN, GREATER_THAN_EQUAL_TO];
export const STRING_CONSTRAINTS = [EQUALS, CONTAINS, STARTS_WITH, ENDS_WITH];
