import React from 'react';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import LinkIcon from '~/components/src/LinkIcon';
import Page from '~/components/src/Page';
import MessageListContainer from '../messages/containers/MessageListContainer';

export const MessagesPage = ({ t }) => (
  <Page>
    <Heading title={t('list.header')}>
      <LinkIcon
        icon="add"
        className="h-8 w-8"
        color="blue"
        url={buildUrl('support/messages/add')}
        tooltip={t('list.addNew')}
        testHook="addNew"
      />
    </Heading>
    <MessageListContainer />
  </Page>
);

export default translate('messages')(MessagesPage);
