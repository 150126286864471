import React, { useEffect, useState } from 'react';

import InputElement from '~/components/src/Form/Elements/InputElement';

const EditableCell = ({ getValue, row, column, setData, type, min, max, hasError, error, testHook }: any) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    const rowIndex = row.index;
    const columnId = column.id;

    return setData((prev: any) =>
      prev.map((row: any, index: number) =>
        index === rowIndex
          ? {
              ...prev[rowIndex],
              [columnId]: value,
            }
          : row,
      ),
    );
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="t-editableField pb-2">
      <InputElement
        testHook={testHook}
        type={type}
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={onBlur}
        min={min}
        max={max}
        hasError={hasError}
        error={hasError && error}
      />
    </div>
  );
};
export default EditableCell;
