angular.module('webUi.ui.tagmanagement.tags.websitePathGroups.view', [
    'webUi.ui.tagmanagement.tags.base',
    'webUi.ui.tagmanagement.tags.websitePathGroups.path.delete',
    'webUi.ui.tagmanagement.tags.websitePathGroups.path.deleteWebsitePathGroup',
    'ui.bootstrap'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tags.groupView', {
                url : '/websitePathGroups/{groupId}',
                leftMenu : true,
                views : {
                    'mainContent@': {
                        controller: 'TagWebsitePathGroupViewController',
                        templateUrl: 'ui/tagmanagement/tags/websitePathGroups/view/view.tpl.html'
                    }
                },
                resolve: {
                    websitePathGroup : ['WebsitePathGroupService', '$stateParams', function(WebsitePathGroupService, $stateParams){
                        return WebsitePathGroupService.getWebsitePathGroup($stateParams.groupId);
                    }],
                    tagConditionTypes : ['TagmanagementService', function(TagmanagementService){
                        return TagmanagementService.getTagConditionTypes();
                    }]
                }
            });
    }])

    .controller('TagWebsitePathGroupViewController', ['$stateParams', '$state', '$scope', '$uibModal', 'TagmanagementService', 'WebsitePathGroupService', 'Utils', 'websitePathGroup', 'tagConditionTypes', 'hasTagEdit', '$timeout', 'PubSubService', 'ToasterService',
        function TagWebsitePathGroupViewController  (  $stateParams,   $state,   $scope,   $uibModal,   TagmanagementService,   WebsitePathGroupService,   Utils,   websitePathGroup,   tagConditionTypes,   hasTagEdit,   $timeout,   PubSubService, toast) {

            PubSubService.publishTagMenuLocationChanged({
                type: 'groups',
                identifierParam: $stateParams.groupId
            });

            $scope.pathOrder= {
                'field' : 'path'
            };

            $scope.websitePathGroup = websitePathGroup;

            //editable checkbox options
            $scope.withChildrenOverrideOptions = {
                editSuccessCallback: function(newValue, oldValue, editableFieldName, editableObjName){
                    var pathToElement = editableFieldName.substring(0, editableFieldName.lastIndexOf('.'));
                    var elementFromScope = Utils.getScopeValue(editableObjName.$parent, pathToElement, true);
                    return WebsitePathGroupService.updateIncludeChildrenForPath($stateParams.groupId, elementFromScope.path, newValue);
                }
            };

            // add callbacks to the directive
            $scope.nameOverrideOptions = {
                editSuccessCallback: function(newValue){
                    return WebsitePathGroupService.updateName($stateParams.groupId, newValue).then(function () {
                        $scope.websitePathGroup.name = newValue;
                        // Reload linked tags, so their source name is updated as well
                        WebsitePathGroupService.getWebsitePathGroup($stateParams.groupId).then(function(updatedGroup) {
                            $scope.websitePathGroup = updatedGroup;
                            PubSubService.publishTypeChanged(PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH_GROUP, {id: $stateParams.groupId});
                        });
                    });
                }
            };

            $scope.alerts = [];
            var timeout;

            $scope.dropped = function(path) {
                var dragged = path;

                if (dragged === TagmanagementService.GLOBAL_PATH) { // and that it's not the global path
                //log("Dragged element is the Global path");
                    if ( timeout ){
                        $timeout.cancel(timeout);
                    }
                    $scope.errorMessage = 'Global website path cannot be dragged here';
                } else if (_.find($scope.websitePathGroup.paths, function(path) { return _.isEqual(path.path, dragged); }) !== undefined) {
                // and that the websitePathGroup doesn't already contain this path
                //log("Dragged path already exists in the group");
                    if ( timeout ){
                        $timeout.cancel(timeout);
                    }
                    $scope.errorMessage = 'The dragged path already exists in this group';
                } else if (dragged.length > 255) {
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    $scope.errorMessage = 'The dragged path is too long';
                } else {
                    WebsitePathGroupService.addPathToWebsitePathGroup($stateParams.groupId, dragged).then(function() {
                        $scope.websitePathGroup.paths.push({
                            path: dragged,
                            includeChildren: true
                        });
                    });
                }
                timeout = $timeout(function() {
                    $scope.errorMessage = '';
                }, 5000);
            };

            $scope.groupId = $stateParams.groupId;
            $scope.hasTagEdit = hasTagEdit;
            $scope.websitePathGroup = websitePathGroup;
            $scope.tagConditionTypes = tagConditionTypes;

            $scope.selectTag = function(tag) {
                $state.transitionToStateWithSite('site.tagmanagement.tags.view', {
                    id: tag.tagId,
                    type: 'groups',
                    identifierParam: $stateParams.groupId
                });
            };

            // function to create new tag
            $scope.newTag = function() {
                TagmanagementService.createTagModal({
                    clazz: TagmanagementService.LOCATIONS.GROUP,
                    groupId: $stateParams.groupId,
                    groupName: websitePathGroup.name
                });
            };

            $scope.toggleStatus = function(tag){
                TagmanagementService.updateEnabled(tag.tagId, !tag.status).then(function(){
                    tag.status = !tag.status;
                });
            };

            $scope.$on('hotKeyEvent', function(event, data) {
            // activate newTag on n
                if(!data.altKey && data.charLower === 'n') {
                    $scope.newTag();
                }
            });

            $scope.confirmRemovePath = function(path) {
                var d = $uibModal.open( {
                    templateUrl: 'ui/tagmanagement/tags/websitePathGroups/delete/delete.tpl.html',
                    controller: 'WebsitePathGroupPathDeleteController',
                    resolve: {
                        dialogsModel : function() {
                            return {
                                websitePathGroupId : $stateParams.groupId,
                                path: path
                            };
                        }
                    }
                });
                d.result.then(function(result) {
                    if(result) {
                    //update the list of paths
                        _.remove($scope.websitePathGroup.paths, function(currentPath) {
                            return currentPath.path === path;
                        });
                    }
                });
            };

            $scope.confirmDeleteWebsitePathGroup = function(websitePathGroup){
                var d = $uibModal.open( {
                    templateUrl: 'ui/tagmanagement/tags/websitePathGroups/delete/deleteWebsitePathGroup.tpl.html',
                    controller: 'WebsitePathGroupDeleteController',
                    resolve: {
                        dialogsModel : function() {
                            return {
                                websitePathGroup : websitePathGroup
                            };
                        }
                    }
                });
                d.result.then(function(result) {
                    if(result) {
                        WebsitePathGroupService.removeWebsitePathGroup(websitePathGroup.websitePathGroupId).then(function() {
                            toast.success('Experiment deleted successfully');
                            PubSubService.publishTypeDeleted(PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH_GROUP, {id: websitePathGroup.websitePathGroupId});
                            $state.transitionToStateWithSite('site.tagmanagement.tags.groupList', {});
                        });
                    }
                });
            };

            $scope.readablePath = function(path) {
                return path.split('|').join(' > ');
            };
        }
    ]);
