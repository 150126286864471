import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  CreateErrorReportingPayload,
  UpdateEmailsToNotifyPayload,
  UpdateEnabledPayload,
  UpdateErrorThresholdPayload,
  ErrorReporting,
} from './types';

const BASE_PATH = `${API_BASE}/tagmanagement/errorReporting`;

export const getErrorReporting = (): Promise<ErrorReporting> => Api.callGet(`${BASE_PATH}`).then(parseResponse);

export const createErrorReporting = (payload: CreateErrorReportingPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}`, payload).then(parseResponse);

export const updateErrorThreshold = (payload: UpdateErrorThresholdPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/updateErrorThreshold`, payload).then(parseResponse);

export const updateEmailsToNotify = (payload: UpdateEmailsToNotifyPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/updateEmailsToNotify`, payload).then(parseResponse);

export const updateEnabled = (payload: UpdateEnabledPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/updateEnabled`, payload).then(parseResponse);
