import * as fromModals from '~/modals';
import ContextUsersService from './dataService.js';
import * as types from './types';
import * as selectors from './selectors';

/**
 * Fetch all users that can be matched with a client
 * @param clientId
 */
export const fetchMatchList = clientId => dispatch => {
  dispatch({
    type: types.MATCH_FETCH_START,
    payload: clientId,
  });

  if (!clientId) return Promise.resolve();

  return ContextUsersService.getNotMatchedUsersForClient(clientId)
    .then(payload => {
      dispatch({
        type: types.MATCH_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.MATCH_FETCH_FAIL, error }));
};

/**
 * Match recognised users with a client
 */
export const matchUsers = cb => (dispatch, getState) => {
  const clientId = selectors.getMatchClientId(getState());
  const canMatch = selectors.canMatchClients(getState());

  if (!clientId || !canMatch) return Promise.resolve();

  dispatch({
    type: types.MATCH_SAVE_START,
    payload: clientId,
  });

  return ContextUsersService.updateNotMatchedUsersForClient(clientId)
    .then(() => {
      dispatch({
        type: types.MATCH_SAVE_SUCCESS,
        payload: clientId,
      });
      dispatch(fromModals.hideModal());
      return cb?.();
    })
    .catch(error => dispatch({ type: types.MATCH_SAVE_FAIL, error }));
};
