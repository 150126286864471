import React from 'react';
import Icon from 'react-icon-base';

const R42AndCondition = ({ className, ...props }) => (
  <div className={className}>
    <Icon viewBox="0 0 24 24" color={props.color} {...props}>
      <g>
        <path d="M1.273 23.749h11.454a1.25 1.25 0 0 0 0-2.5H1.273a1.25 1.25 0 0 0 0 2.5zM13.977 1.5a1.25 1.25 0 0 0-1.25-1.25H1.273a1.25 1.25 0 0 0 0 2.5h11.454c.69 0 1.25-.56 1.25-1.25zM9.864 8.226a1 1 0 0 0 .707-1.707L7.707 3.655a1 1 0 0 0-1.414 0L3.429 6.519a1 1 0 0 0 .707 1.707H5.5a.25.25 0 0 1 .25.25v7.045a.25.25 0 0 1-.25.25H4.136a1 1 0 0 0-.707 1.707l2.864 2.865a1 1 0 0 0 1.414 0l2.864-2.865a1 1 0 0 0-.707-1.707H8.5a.25.25 0 0 1-.25-.25V8.476a.25.25 0 0 1 .25-.25h1.364z" />
      </g>
    </Icon>
  </div>
);

export default R42AndCondition;
