import React from 'react';
import { Heading, Page } from '~/components';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import i18n from '~/i18n';
import DeliveryNetwork from './components/DeliveryNetwork';
import NewPathCount from './components/NewPathCount';
import Counters from './components/Counters';
import Errors from './components/Errors';

const StatsView = (): JSX.Element => (
  <Page>
    <Heading title="Stats"></Heading>
    <Tabs>
      <TabList className="!mb-8">
        <Tab testHook="deliveryNetworkTab">{i18n.t('stats:tabs.deliveryNetwork')}</Tab>
        <Tab testHook="errorsTab">{i18n.t('stats:tabs.errors')}</Tab>
        <Tab testHook="newPathCountTab">{i18n.t('stats:tabs.newPathCount')}</Tab>
        <Tab testHook="countersTab">{i18n.t('stats:tabs.counters')}</Tab>
      </TabList>

      <TabPanel>
        <DeliveryNetwork />
      </TabPanel>
      <TabPanel>
        <Errors />
      </TabPanel>
      <TabPanel>
        <NewPathCount />
      </TabPanel>
      <TabPanel>
        <Counters />
      </TabPanel>
    </Tabs>
  </Page>
);

export default StatsView;
