angular.module('webUi.ui.profiles.variables.base',
    [
        'webUi.ui.profiles.base'
    ]
).config(['$stateProvider', function config($stateProvider) {
    $stateProvider
        .state('site.profiles.variables', {
            url: '/variables',
            isReact: true,
        });
}]);
