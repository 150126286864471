import { useState, useEffect } from 'react';

type UseApiReturn<PromiseReturn> = {
  data: PromiseReturn | null;
  error: Error | null;
  isLoading: boolean;
};

interface UseApiWithStateReturn<PromiseReturn> {
  state: PromiseReturn | null;
  isLoading: boolean;
  error: Error | null;
  setState: (state: PromiseReturn) => void;
  refetch: () => void;
}

interface UseApiState<PromiseReturn> {
  data: PromiseReturn | null;
  error: Error | null;
}

export const useAPI = <PromiseReturn>(
  promise: () => Promise<PromiseReturn>,
  params: unknown[] = [],
): UseApiReturn<PromiseReturn> => {
  const [state, setState] = useState<UseApiState<PromiseReturn>>({ data: null, error: null });

  useEffect(() => {
    let isMounted = true;
    setState({ data: null, error: null });

    promise()
      .then((response: PromiseReturn) => {
        if (isMounted) {
          setState({ ...state, data: response });
        }
      })
      .catch((error: Error) => {
        if (isMounted) {
          setState({ ...state, error });
        }
      });
    return () => {
      isMounted = false;
    };
  }, [...params]);
  const { data, error } = state;

  return { data, error, isLoading: data === null && error === null };
};

export const useApiWithState = <PromiseReturn>(
  promise: () => Promise<PromiseReturn>,
  params: unknown[] = [],
): UseApiWithStateReturn<PromiseReturn> => {
  const [refetchFlag, setRefetchFlag] = useState(0);
  const [state, setState] = useState<PromiseReturn | null>(null);
  const { data, isLoading, error } = useAPI(promise, [...params, refetchFlag]);

  useEffect(() => {
    if (data !== null) {
      setState(data);
    }
  }, [data]);

  const refetch = () => {
    // This is to ensure that the component reloads
    setRefetchFlag(Math.floor(Date.now()));
  };

  return { state, isLoading, error, setState, refetch };
};
