import React, { useEffect, useRef } from 'react';

import { get } from 'lodash';
import { Field } from 'formik';

import i18n from '~/i18n';
import { validateFilterInput } from '~/profiles/audiences/dataService';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

import { FILTER_CLAZZ } from './constants';
import { TOption, TConstraint, TFilter, TSetFieldValue, TValidateForm, TElement } from './types';
import {
  dateOnValidation,
  dateValidation,
  dateWithinValidation,
  getComponentByClazz,
  getDefaultValueByClazz,
  numberWithinValidation,
  timeFrameValidation,
} from './util';

const makeValidator = (clazz: string, isMandatoryProperty: boolean) => (filter: TFilter) => {
  const value = filter?.value;
  if (isMandatoryProperty) {
    // Mandatory select can never be invalid
    return '';
  }
  switch (clazz) {
    case FILTER_CLAZZ.DATE:
      return dateValidation(value);

    case FILTER_CLAZZ.DATE_ON:
      return dateOnValidation(value);

    case FILTER_CLAZZ.DATE_WITHIN:
      return dateWithinValidation(filter?.rangeFrom, filter?.rangeTo, filter?.timeFrame);

    case FILTER_CLAZZ.NUMBER_WITHIN:
      return numberWithinValidation(filter?.rangeFrom, filter?.rangeTo);

    case FILTER_CLAZZ.TIMEFRAME_WITHIN:
      return timeFrameValidation(filter?.rangeFrom, filter?.rangeTo);

    default:
      return value ? '' : i18n.t('common:errors.required');
  }
};

const backendFilterInputValidator = (selectedConstraint: TConstraint) => (filter: TFilter) => {
  const value = filter?.value;
  if (!value) return i18n.t('common:errors.required');
  const encodedValue = encodeURIComponent(value);
  return validateFilterInput(selectedConstraint.matchType.name, encodedValue)
    .then(() => '')
    .catch(() => i18n.t('common:errors.wrongFormat'));
};

const useClearFilterIfNoConstraintSelected = (
  isInputVisible: boolean,
  propertyIndex: number,
  setFieldValue: TSetFieldValue,
  validateForm: TValidateForm,
) => {
  useEffect(() => {
    if (!isInputVisible) {
      // Removes filter value from form values in case it's not needed
      setFieldValue(`filters[${propertyIndex}].filterValue`, undefined);
      validateForm();
    }
  }, [isInputVisible]);
};

const useClearFilterOnConstraintChange = (
  constraintGroupName: string,
  propertyIndex: number,
  setFieldValue: TSetFieldValue,
  validateForm: TValidateForm,
) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      // Skip clearing value after first render
      isFirstRun.current = false;
    } else {
      // Removes value if constraint group changed
      setFieldValue(`filters[${propertyIndex}].filterValue`, undefined);
      validateForm();
    }
  }, [constraintGroupName]);
};

const getSelectOptionLabel = (option: TOption) => option.name;
const getSelectOptionValue = (value: string) => value;

const FilterValueSelect = ({ value, setFieldValue, selectedProperty, path }: TElement) => {
  const handleChange = (selectedValue: string) => {
    setFieldValue(path, selectedValue);
  };

  return (
    <SelectElement
      value={value}
      options={selectedProperty.restrictedValues}
      onChange={handleChange}
      getOptionLabel={getSelectOptionLabel}
      getOptionValue={getSelectOptionValue}
    />
  );
};

const FilterValue = ({
  field,
  form,
  placeholder,
  clazz,
  propertyIndex,
  setFieldValue,
  setFieldTouched,
  isMandatoryProperty,
  selectedProperty,
  hasNewProperty,
}: TElement) => {
  const path = field.name;

  const touch = () => {
    setFieldTouched(path, true);
  };

  const handleChange = (value: string, key = 'value') => {
    setFieldValue(path, {
      ...form.values.filters[propertyIndex].filterValue,
      clazz,
      [key]: value,
    });
  };

  const initializeDefaultValue = () => setFieldValue(path, getDefaultValueByClazz(clazz));

  const InputComponent = isMandatoryProperty ? FilterValueSelect : getComponentByClazz(clazz);

  const isValid = get(form.errors, path) === undefined;
  const filter = get(form.values, path);

  if (!filter) {
    initializeDefaultValue();
    return null;
  }

  const errorText = get(form.errors, path);
  const isTouched = get(form.touched, path);
  const isErrorVisible = isTouched && !isValid && errorText;

  const value = isMandatoryProperty ? filter : filter.value;

  return (
    <InputComponent
      autoFocus={hasNewProperty}
      value={value}
      onChange={handleChange}
      onBlur={touch}
      propertyIndex={propertyIndex}
      setFieldValue={setFieldValue}
      errorText={isErrorVisible}
      selectedProperty={selectedProperty}
      path={path}
      filter={filter}
      errors={form.errors}
      placeholder={placeholder}
      validateForm={form.validateForm}
    />
  );
};

const Filter = ({
  selectedConstraint,
  propertyIndex,
  placeholder,
  isMandatoryProperty,
  selectedProperty,
  hasNewProperty,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  validateForm,
}: TElement) => {
  const isInputVisible = selectedConstraint && selectedConstraint.matchType.name !== 'ANY';
  const constraintGroupName = get(selectedConstraint, 'group.name');

  const clazz = selectedConstraint && selectedConstraint.filterValue;

  useClearFilterIfNoConstraintSelected(isInputVisible, propertyIndex, setFieldValue, validateForm);

  useClearFilterOnConstraintChange(constraintGroupName, propertyIndex, setFieldValue, validateForm);

  if (!isInputVisible) {
    return null;
  }

  // Field component uses `name` to get field value from Formik context
  const name = `filters[${propertyIndex}].filterValue`;

  const customValidator =
    selectedConstraint.matchType.name === 'REGEX'
      ? backendFilterInputValidator(selectedConstraint)
      : makeValidator(clazz, isMandatoryProperty);

  return (
    <Field
      className={!isInputVisible ? 'u-hidden' : ''}
      hasNewProperty={hasNewProperty}
      name={name}
      component={FilterValue}
      validate={customValidator}
      constraintGroupName={constraintGroupName}
      clazz={clazz}
      propertyIndex={propertyIndex}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      setFieldError={setFieldError}
      isMandatoryProperty={isMandatoryProperty}
      selectedProperty={selectedProperty}
      placeholder={placeholder}
      matchType={selectedConstraint.matchType.name}
    />
  );
};

export default Filter;
