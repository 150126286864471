import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, RowActions, Cell } = components;
const { withEmpty, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('clients.empty.header')} body={t('clients.empty.body')} />;

export const RestrictedClientsList = ({ list, stateKey, t, onDeleteClick }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width="20%" sortKey="clientNumber" title={t('clients.clientNumber')} />
        <SortableHeader width="30%" sortKey="clientName" title={t('clients.clientName')} />
      </Row>
      {list.map(item => {
        const actions = [
          {
            name: t('clients.removeRestriction'),
            tooltip: t('clients.removeRestriction'),
            isDisabled: false,
            icon: 'delete',
            onClick: () => onDeleteClick(item.clientId),
            testHook: 'delete',
            type: 'delete',
          },
        ];
        return (
          <Row key={item.clientId} withActions>
            <Cell style={{ width: '20%' }}>{item.clientNumber}</Cell>
            <Cell style={{ width: '30%' }}>{item.clientName}</Cell>
            <RowActions actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

RestrictedClientsList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withSort(),
  withPaginate({ size: 10 }),
)(RestrictedClientsList);
