import React from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import { twMerge } from 'tailwind-merge';
import Btn from '../Btn';

interface ActionButtonsProps {
  onDecline?: () => void;
  onConfirm?: (e?: React.FormEvent<any>) => void;
  onDelete?: () => void;
  declineText?: string;
  confirmText?: string;
  deleteText?: string;
  isConfirmEnabled?: boolean;
  isDeleteEnabled?: boolean;
  disabledButtonToolTip?: string;
  className?: string;
  testHook?: string;
  paddingClassName?: string | null;
  gaAction?: string;
}

const ActionButtons = ({
  onDecline,
  onConfirm,
  onDelete,
  declineText = i18n.t('common:actions.cancel'),
  confirmText = i18n.t('common:actions.save'),
  deleteText = i18n.t('common:actions.delete'),
  isConfirmEnabled = true,
  isDeleteEnabled = true,
  disabledButtonToolTip = '',
  className,
  testHook,
  gaAction = '',
}: ActionButtonsProps): JSX.Element => (
  <div className={twMerge(cx('ActionButtons flex justify-end gap-2 pt-10', className))}>
    {onDecline && (
      <Btn
        className={cx('ActionButtons-decline', {
          [`t-${testHook}-declineButton`]: testHook,
        })}
        onClick={onDecline}
      >
        {declineText}
      </Btn>
    )}
    {onConfirm && (
      <Btn
        type="submit"
        className={cx('ActionButtons-confirm', {
          [`t-${testHook}-confirmButton`]: testHook,
        })}
        onClick={onConfirm}
        disabled={!isConfirmEnabled}
        tooltip={!isConfirmEnabled ? disabledButtonToolTip : ''}
        color="blue"
        gaAction={gaAction}
      >
        {confirmText}
      </Btn>
    )}
    {onDelete && (
      <Btn
        disabled={!isDeleteEnabled}
        className={cx({ [`t-${testHook}-deleteButton`]: testHook })}
        onClick={onDelete}
        color="red"
        tooltip={!isDeleteEnabled ? disabledButtonToolTip : ''}
        gaAction={gaAction}
      >
        {deleteText}
      </Btn>
    )}
  </div>
);

export default ActionButtons;
