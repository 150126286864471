import React, { Component } from 'react';
import { Field } from 'redux-form';
import { defaultMaxInput, isRequired } from '~/common';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import { USER_EVENTS } from '~/gaActions';

const createDefaultSiteOptions = defaultSites => defaultSites.map(site => ({ value: site.siteId, label: site.name }));

export class UserSettingsEditForm extends Component {
  render() {
    const { initialValues, handleSubmit, submitting, backHref, defaultSites, t, touch } = this.props;
    const whitelistedSubnetsField = (
      <Field
        label={t('userForm.whitelistedSubnets.label')}
        name="whitelistedSubnets"
        size="default"
        component={ReduxFormTextAreaField}
        disabled={true}
      />
    );
    const phoneNumberField = (
      <Field
        label={t('userForm.phone.label')}
        name="phoneNumber"
        component={ReduxFormInputField}
        disabled={true}
        type="text"
      />
    );
    return (
      <Form noValidate onSubmit={handleSubmit} isSubmitting={submitting}>
        <Field
          label={t('userForm.email.label')}
          name="username"
          component={ReduxFormInputField}
          disabled={true}
          type="text"
        />
        <Field
          label={t('userForm.name.label')}
          name="name"
          component={ReduxFormInputField}
          placeholder={t('userForm.name.placeholder')}
          validate={[isRequired, defaultMaxInput]}
          type="text"
        />
        {initialValues.hasAdvancedSecurity && phoneNumberField}
        {initialValues.hasAdvancedSecurity && whitelistedSubnetsField}
        <Field
          label={t('userForm.defaultSite.label')}
          name="defaultSiteId"
          component={ReduxFormSelectField}
          options={createDefaultSiteOptions(defaultSites)}
          validate={isRequired}
          touch={touch}
        />
        <ActionsRow t={t} submitting={submitting} cancelHref={backHref} gaAction={USER_EVENTS.SETTINGS.EDITED} />
      </Form>
    );
  }
}
export default UserSettingsEditForm;
