import React from 'react';
import RulePicker from '~/common/modules/rules/components/RulePicker/index';
import { getPropertyTypesForRule } from '~/components/src/RuleList';
import i18n from '~/i18n';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import '~/components/src/RuleList/styles.scss';
import EventListenerEditCard from './EventListenerEditCard';

const validateEventListeners = (events: any[]) => {
  const hasOnlyValidFilters = (event: { filters: any[] }) =>
    event.filters.length &&
    event.filters.every(
      (filter: { dataField: string; profileVarId: string }) => filter.dataField && filter.profileVarId,
    );

  return events.every((event: any) => hasOnlyValidFilters(event));
};

function EditableEventListenerList({
  eventsListeners,
  onChange,
  showRulePicker = true,
  disallowDuplicates = false,
  ruleTypesTree = [],
  usedVariables = [],
  actionName = i18n.t('workflow:orchestration.eventListener.add'),
  viewOnly = false,
}: any) {
  const handleOnChange = (eventsListeners: any[]) => {
    const areEventsListenersValid = validateEventListeners(eventsListeners);
    onChange({
      areEventsListenersValid,
      eventsListeners,
    });
  };

  const handleAddNewRule = (data: {
    type: string;
    title: string;
    typeLabel: string;
    ruleDefinitionId: string;
    ruleDefinitionName: string;
    ruleName: string;
  }) => {
    const altData = getTitleAndTypeLabel(data.type, data.title);
    const title = data.title || altData.title;
    const typeLabel = data.typeLabel || altData.typeLabel;

    const listeners = [
      ...eventsListeners,
      {
        id: Math.random().toString().slice(2),
        ruleDefinitionId: data.ruleDefinitionId,
        ruleDefinitionName: data.ruleDefinitionName,
        ruleName: data.ruleName,
        title,
        typeLabel,
        type: data.type,
        filters: [],
      },
    ];
    handleOnChange(listeners);
  };

  const handlePropertyChange = (data: { filters: any }, eventIndex: number) => {
    const updatedEventListeners = eventsListeners.map((event: any, index: number) => {
      if (eventIndex === index) {
        return { ...event, filters: data.filters };
      }
      return event;
    });
    handleOnChange(updatedEventListeners);
  };

  const handleEventDelete = (index: number) => {
    const updatedEventListeners = eventsListeners.filter((_: any, i: any) => i !== index);
    handleOnChange(updatedEventListeners);
  };

  return (
    <div className="flex flex-col gap-2">
      {eventsListeners?.map((listener: any, i: number) => {
        const propertyTypes = getPropertyTypesForRule(ruleTypesTree, listener.ruleDefinitionId);

        return (
          <EventListenerEditCard
            key={listener.id}
            id={listener.id}
            title={listener.title}
            type={listener.type}
            typeLabel={listener.typeLabel}
            onChange={(data: any) => handlePropertyChange(data, i)}
            onDelete={() => handleEventDelete(i)}
            isSubmitted={false}
            filters={listener?.filters}
            propertyTypes={propertyTypes}
            availableVariables={usedVariables}
            showRulePicker={showRulePicker}
            viewOnly={viewOnly}
            url={listener.url}
          />
        );
      })}
      {!viewOnly && (
        <RulePicker
          disallowDuplicates={disallowDuplicates}
          selectedRules={eventsListeners}
          onSelect={handleAddNewRule}
          ruleTypesTree={ruleTypesTree}
          actionName={actionName}
          testHook="addEventListner"
          className="u-inlineBlock"
        />
      )}
    </div>
  );
}

export default EditableEventListenerList;
