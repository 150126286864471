import React from 'react';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import moment from 'moment';
import DateRangePicker from '~/components/src/DateRangePicker';
import i18n from '~/i18n';

function DateColumnFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  const [startDate, endDate] = React.useMemo(() => {
    const dates = preFilteredRows.map(val => moment(val.original[id]));
    return [moment.min(dates), moment.max(dates)];
  }, [id, preFilteredRows]);

  return (
    <FieldWrapper label={i18n.t(`common:tableColumns.${id}`)}>
      <DateRangePicker
        className="DateColumnFilter"
        startDate={filterValue[0] || startDate}
        endDate={filterValue[1] || endDate}
        handleDatesChange={({ startDate, endDate }) => setFilter([startDate, endDate])}
        showHint={false}
        openDirection="up"
      />
    </FieldWrapper>
  );
}

export default DateColumnFilter;
