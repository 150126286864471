import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { reduxForm } from 'redux-form';
import CustomerFactForm from '../components/CustomerFactForm';
import { saveCustomerFact, fetchCustomerFacts, setUserIsEditing } from '../customerFacts/actions';
import { getAllCustomerFactNames, isFetchingCustomerFacts, isUserEditing } from '../customerFacts/selectors';

const FORM_NAME = 'externalFactNew';

export class CustomerFactNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldfetchCustomerFacts: true };
  }

  componentDidMount() {
    this.props.fetchCustomerFacts();
    this.setState({ shouldfetchCustomerFacts: false });
  }

  componentWillUnmount() {
    this.props.setUserIsEditing(false);
  }

  render() {
    const { isFetching, t, ...otherProps } = this.props;
    if (this.state.shouldfetchCustomerFacts || isFetching) {
      return <Spinner />;
    }
    return (
      <Page>
        <SmallWrapper>
          <Heading title={t('new.header')} />
          <Panel>
            <CustomerFactForm t={t} {...otherProps} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = state => ({
  initialValues: {
    propertiesWithUsage: [],
    updatedProperties: [],
  },
  externalFactNames: getAllCustomerFactNames(state),
  isFetching: isFetchingCustomerFacts(state),
  cancelHref: buildUrl('profiles/externalFacts'),
  userIsEditing: isUserEditing(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCustomerFacts: () => dispatch(fetchCustomerFacts()),
  onSubmit: values => dispatch(saveCustomerFact(values)),
  setUserIsEditing: userIsEditing => dispatch(setUserIsEditing(userIsEditing)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    isNew: true,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['customerFacts']),
)(CustomerFactNewPage);
