import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import i18n from '~/i18n';
import { buildUrl } from '~/common';
import { Heading, Page } from '~/components';
import { showSuccess } from '~/notificationCenter';
import { LargeWrapper } from '~/components/src/Containers';

import { Model } from './types';
import ModelForm from './components/ModelForm';
import { createModel, updateModel } from './dataService';

import './styles.scss';
import { prepareModelPayload } from './util';

function ModelCreate(): JSX.Element {
  const navigate = useNavigate();

  const params = useParams();
  const isEdit = !!params?.id;
  const modelId = params?.id || '';
  const goToModelsList = () => navigate(buildUrl('ai/models/list'), { replace: true });
  const goToModelsView = (modelId: string) => navigate(buildUrl(`ai/models/view/${modelId}`), { replace: true });

  const handleCreateModel = async (values: Model) => {
    values.predictions.forEach(prediction =>
      prediction.criterias.forEach(criteria => criteria.rules.forEach(rule => delete rule.config)),
    );

    const payload = prepareModelPayload(values);

    if (isEdit) {
      await updateModel(payload, modelId);
      showSuccess({ header: i18n.t('ai:notifications.modelUpdateSuccess') });
      goToModelsView(modelId);
    } else {
      await createModel(payload);
      showSuccess({ header: i18n.t('ai:notifications.modelCreateSuccess') });
      goToModelsList();
    }
  };

  return (
    <Page className="ModelCreatePage">
      <LargeWrapper>
        <Heading
          title={i18n.t(`ai:modelsPage.${isEdit ? 'edit' : 'create'}`)}
          crumbs={[
            {
              title: i18n.t('ai:actions.backToModels'),
              onClick: () => {
                goToModelsList();
              },
            },
          ]}
        ></Heading>
        <ModelForm
          modelId={modelId}
          isEdit={isEdit}
          goToModelsList={goToModelsList}
          handleCreateModel={handleCreateModel}
        />
      </LargeWrapper>
    </Page>
  );
}

export default ModelCreate;
