import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import Heading from './../../../Heading';
import './styles.scss';

export class DetailContainer extends Component {
  render() {
    const { title, onClose, isVisible = true, children, className } = this.props;
    return (
      isVisible && (
        <div
          className={cx('DetailContainer fixed right-0 top-20 z-10 h-full w-1/3 bg-white drop-shadow-md', className)}
        >
          <Heading kind="h2" title={title} className="border-b border-gray-100 p-4">
            <BtnIcon icon="close" onClick={() => onClose()} testHook="closePanel" />
          </Heading>
          <div className="h-[calc(100%-160px)] overflow-auto p-4" id="DetailContainerBody">
            {children}
          </div>
        </div>
      )
    );
  }
}

DetailContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
};

export default DetailContainer;
