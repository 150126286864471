import seamlessImmutable from 'seamless-immutable';

const newExportInitialState = seamlessImmutable({
  isFetchingRequestTypes: false,
  isFetchingExportTypes: false,
  isFetchingEngagementTypes: false,
  isFetchingEditExport: false,
  isCreatingNewRequest: false,
  requestTypes: [],
  exportTypes: [],
  engagementTypes: [],
  isRemoteConnectionSuccessful: false,
  areConnectionCredentialsChecked: false,
  editExportData: {},
  exportId: '',
});

export default newExportInitialState;
