export const AUDIENCES_FETCH_START = 'app/profiles/audiences/AUDIENCES_FETCH_START';
export const AUDIENCES_FETCH_FAIL = 'app/profiles/audiences/AUDIENCES_FETCH_FAIL';
export const AUDIENCES_FETCH_SUCCESS = 'app/profiles/audiences/AUDIENCES_FETCH_SUCCESS';

export const AUDIENCE_DELETE_START = 'app/profiles/audiences/AUDIENCE_DELETE_START';
export const AUDIENCE_DELETE_FAIL = 'app/profiles/audiences/AUDIENCE_DELETE_FAIL';
export const AUDIENCE_DELETE_SUCCESS = 'app/profiles/audiences/AUDIENCE_DELETE_SUCCESS';

export const AUDIENCE_FETCH_START = 'app/profiles/audiences/AUDIENCE_FETCH_START';
export const AUDIENCE_FETCH_FAIL = 'app/profiles/audiences/AUDIENCE_FETCH_FAIL';
export const AUDIENCE_FETCH_SUCCESS = 'app/profiles/audiences/AUDIENCE_FETCH_SUCCESS';

export const AUDIENCE_BREADCRUMBS_FETCH_START = 'app/profiles/audiences/AUDIENCE_BREADCRUMBS_FETCH_START';
export const AUDIENCE_BREADCRUMBS_FETCH_FAIL = 'app/profiles/audiences/AUDIENCE_BREADCRUMBS_FETCH_FAIL';
export const AUDIENCE_BREADCRUMBS_FETCH_SUCCESS = 'app/profiles/audiences/AUDIENCE_BREADCRUMBS_FETCH_SUCCESS';

export const GROUP_ADD_START = 'app/profiles/audiences/GROUP_ADD_START';
export const GROUP_ADD_FAIL = 'app/profiles/audiences/GROUP_ADD_FAIL';
export const GROUP_ADD_SUCCESS = 'app/profiles/audiences/GROUP_ADD_SUCCESS';

export const GROUP_DELETE_START = 'app/profiles/audiences/GROUP_DELETE_START';
export const GROUP_DELETE_FAIL = 'app/profiles/audiences/GROUP_DELETE_FAIL';
export const GROUP_DELETE_SUCCESS = 'app/profiles/audiences/GROUP_DELETE_SUCCESS';

export const MOVE_ITEM_START = 'app/profiles/audiences/MOVE_ITEM_START';
export const MOVE_ITEM_FAIL = 'app/profiles/audiences/MOVE_ITEM_FAIL';
export const MOVE_ITEM_SUCCESS = 'app/profiles/audiences/MOVE_ITEM_SUCCESS';

export const GROUP_ADD_FORM = 'app/profiles/audiences/GROUP_ADD_FORM';
export const AUDIENCES_LIST_KEY = 'app/profiles/audiences/AUDIENCES_LIST_KEY';
export const AUDIENCES_FILTER_KEY = 'app/profiles/audiences/AUDIENCES_FILTER_KEY';

export const SEARCH_AUDIENCES = 'app/profiles/audiences/SEARCH_AUDIENCES';

export const GO_BACK_GROUP = {
  id: '',
  allowChildren: true,
  hasChildren: true,
  childrenIds: [],
  name: '...',
  searchableProperties: null,
  type: 'GROUP',
};

export const ITEM_TYPES = {
  GROUP: 'GROUP',
  SELECTION: 'SELECTION',
};

export const FOLDER_COLOR = '4A89CF';
export const BACKEND_PAGINATION_QTY = 20;

export const DEPENDANTS_INFO_FETCH_START = 'app/profiles/audiences/dependencies/DEPENDANTS_INFO_FETCH_START';
export const DEPENDANTS_INFO_FETCH_SUCCESS = 'app/profiles/audiences/dependencies/DEPENDANTS_INFO_FETCH_SUCCESS';
export const DEPENDANTS_INFO_FETCH_FAIL = 'app/profiles/audiences/dependencies/DEPENDANTS_INFO_FETCH_FAIL';

export const SHOW_DEPENDANTS_CONTAINER = 'app/profiles/audiences/SHOW_DEPENDANTS_CONTAINER';
export const HIDE_DEPENDANTS_CONTAINER = 'app/profiles/audiences/HIDE_DEPENDANTS_CONTAINER';
