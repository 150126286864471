import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import i18n from '~/i18n';
import { getConnectorLogoUrl } from '~/profiles/helpers/connectorsHelper';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { components } from '~/components/src/Table';
import { I18NextT } from '~/components/src/Common/types';
import { UITable } from '~/components/src/UITable';

import UsageIcons, { UsedInItem } from './UsageIcons';
import { CONNECTOR_USED_IN } from '../connectors/types';
import './ConnectorList.scss';

const { RowActions } = components;

const columnHelper = createColumnHelper<any>();

const EmptyList = () => (
  <Empty header={i18n.t('partners:list.empty.header')} body={i18n.t('partners:list.empty.body')} />
);

type ConnectorListProps = {
  actions: (row: any) => void;
  list: any[];
  t: I18NextT;
  onDependantIconClick: () => void;
  columns: string[];
};

export const ConnectorList = ({
  actions,
  list,
  t,
  onDependantIconClick,
  columns = ['NUMBER', 'USEDIN', 'STATUS'],
}: ConnectorListProps): JSX.Element => {
  if (list?.length === 0) {
    return <EmptyList />;
  }

  const getColumns = () =>
    [
      columnHelper.accessor('partnerName', {
        header: () => <div>{i18n.t('partners:list.name')}</div>,
        cell: info => (
          <>
            <img
              alt={info.row.original.partnerName}
              className={cx('ConnectorList-logo', 'ConnectorList-table-logo', {
                'is-disabled': !info.row.original.activated,
              })}
              src={getConnectorLogoUrl(info.row.original.imagePartnerId, info.row.original.partnerType) || undefined}
            />
            <span
              className={cx('ConnectorList-name', { 'ConnectorList-name--inactive': !info.row.original.activated })}
            >
              {info.row.original.url ? (
                <Link testHook="partnerLink" href={info.row.original.url}>
                  {info.getValue()}
                </Link>
              ) : (
                info.getValue()
              )}
            </span>
          </>
        ),
        size: 350,
      }),
      columns.includes('NUMBER') &&
        columnHelper.accessor('partnerNumber', {
          header: () => i18n.t('partners:list.partnerNumber'),
          size: 150,
        }),
      columns.includes('STATUS') &&
        columnHelper.accessor('activated', {
          header: () => i18n.t('partners:list.status'),
          cell: info => (
            <span className={cx({ 'ConnectorList-item--inactive': !info.getValue() })}>
              {t(`partners:list.${info.getValue() ? 'activated' : 'deactivated'}`)}
            </span>
          ),
          size: 100,
        }),
      columns.includes('USEDIN') &&
        columnHelper.accessor('usedIn', {
          header: () => i18n.t('partners:list.usedIn'),
          cell: info => (
            <UsageIcons
              dependantTypes={info.row.original.dependantTypes}
              elementUsedIn={CONNECTOR_USED_IN as UsedInItem[]}
              elementId={info.row.original.partnerId}
              onDependantIconClick={onDependantIconClick}
            />
          ),
          size: 100,
          enableSorting: false,
        }),
      actions &&
        columnHelper.accessor('actions', {
          header: () => <></>,
          cell: info => (
            <div className="flex">
              <RowActions actions={actions(info.row.original)} />
            </div>
          ),
          size: 100,
          enableSorting: false,
        }),
    ].filter(Boolean) as ColumnDef<any, any>[];

  return <UITable testHook="connectorsTable" data={list} columns={getColumns()} enableSorting />;
};

export default ConnectorList;
