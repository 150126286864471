angular.module('webUi.service.time', [
    'webUi.service.security'
])
/**
 * A service to do time and timezone related stuff
 */
    .constant('TimeService.CONVERSION_DIRECTION', {
        FROM_LOCAL_TZ_TO_SITE_TZ: 'FROM_LOCAL_TZ_TO_SITE_TZ',
        FROM_SITE_TZ_TO_LOCAL_TZ: 'FROM_SITE_TZ_TO_LOCAL_TZ'
    })
    .factory('TimeService', ['$window', 'SecurityService', 'TimeService.CONVERSION_DIRECTION', function ($window, SecurityService, CONVERSION_DIRECTION) {

        function getUtcOffsetInMillis(mom) {
            return -1*(mom.utcOffset() * 1000 * 60);
        }

        function internalConvert(value, tz, conversionDirection) {
            var momInBrowserTz = moment(value)
                .local();
            var momInSpecifiedTz = momInBrowserTz.clone()
                .tz(tz);
            var offsetInBrowserTz = getUtcOffsetInMillis(momInBrowserTz);
            var offsetInSpecifiedTz = getUtcOffsetInMillis(momInSpecifiedTz);
            var offsetDifference = offsetInBrowserTz - offsetInSpecifiedTz;

            if (conversionDirection === CONVERSION_DIRECTION.FROM_LOCAL_TZ_TO_SITE_TZ) {
                return momInBrowserTz.valueOf() - offsetDifference;
            } else {
                return momInBrowserTz.valueOf() + offsetDifference;
            }
        }

        function convertWithTz(value, conversionDirection) {
            var tz = SecurityService.getCurrentTz();
            if (!tz){
                return value;
            }
            if (angular.isDate(value)) {
                return new Date(internalConvert(value.getTime(), tz, conversionDirection).valueOf());
            } else {
                return internalConvert(value, tz, conversionDirection).valueOf();
            }
        }

        function getCurrentTimeMillis() {
            return new Date().getTime();
        }

        function getPerformanceTimeMillis() {
            if ($window.performance && _.isFunction($window.performance.now)) {
                // Gets the number of milliseconds since page load
                // (and rounds the result since the value must be an integer).
                return Math.round(window.performance.now());
            }
            return 0;
        }

        return {
            CONVERSION_DIRECTION: CONVERSION_DIRECTION,
            /**
         * Converts a timestamp/Datet in the local timezone of the user to a timestamp/Date in the current site timezone or the reverse
         * @param value {Date|timestamp} the timestamp or date to convert
         * @param conversionDirection {CONVERSION_DIRECTION}
         * @return the converted date
         */
            convertWithTz: convertWithTz,
            getCurrentTimeMillis: getCurrentTimeMillis,
            getPerformanceTimeMillis: getPerformanceTimeMillis
        };
    }]);
