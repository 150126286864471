import React, { ReactNode } from 'react';
import { Field, FormSection, InjectedFormProps } from 'redux-form';
import { isRequired } from '~/common';
import { useAPI } from '~/common/ApiHooks';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import { fetchPayloadTypes } from './service';
import FormHeader from '../formHeader/FormHeader';

type OracleResponsys = {
  t: any;
  touch: any;
  values: {
    name: string;
    partnerNumber: number;
    token: string;
    siteId: string;
    networkId: string;
    advancedSettings: boolean;
    payloadType: string;
  };
  partner: {
    partnerId: number;
    partnerNumber: number;
    partnerType: string;
    partnerName: string;
  };
  canUpdate: boolean;
  change: InjectedFormProps['change'];
};

type PayloadType = {
  label: string;
  name: string;
  partnerNumber: number;
  value?: string;
};

function OracleResponsys({ t, values, partner, canUpdate, change, touch }: OracleResponsys): ReactNode {
  const { data, isLoading: arePayloadTypesLoading } = useAPI(
    () => fetchPayloadTypes().then(response => response.map((type: PayloadType) => ({ ...type, value: type.name }))),
    [],
  );

  const partnerNumber = values.partnerNumber || partner.partnerNumber;

  return (
    <FormSection name={constants.partnerTypes.ORACLE_RESPONSYS}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <>
          <FieldWithEditMode
            label={t('form.formFields.username')}
            name="username"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.password')}
            name="password"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.authenticationEndpoint')}
            name="authenticationEndpoint"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.profileListName')}
            name="profileListName"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={data || []}
            disabled={canUpdate}
            validate={isRequired}
            isLoading={arePayloadTypesLoading}
            onChange={(event, value) => {
              const payloadType = data?.find((type: PayloadType) => type.value === value);
              change('ORACLE_RESPONSYS.partnerNumber', payloadType?.partnerNumber);
            }}
            touch={touch}
          />
          {values?.payloadType === constants.CUSTOMER_ID && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
              disabled={canUpdate}
            />
          )}
        </>
      </ContainerFormSection>
    </FormSection>
  );
}

export default OracleResponsys;
