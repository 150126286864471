import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import { Panel } from '~/components/src/Containers';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import Notification from '~/components/src/Notification';

const { Table, Row, Cell, HeaderCell } = components;
const { withSort, withPaginate, withEmpty, withFilter } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('collectors:list.empty.header')} body={t('collectors:list.empty.body')} />
);

export const CollectorsList = ({ t, list, stateKey, itemUrl }) => (
  <>
    <Notification kind="information">
      <p>{t('collectors:list.trackInfo')}</p>
      <p>{t('collectors:list.cookiesInfo')}</p>
    </Notification>
    <Panel>
      <Table stateKey={stateKey} testHook="collectorsListTable">
        <Row>
          <SortableHeader className="u-size2of4" sortKey="name" title={t('common.collectorsName')} />
          <HeaderCell className="u-size2of4">{t('common.collectorsType')}</HeaderCell>
        </Row>
        {list.map(collector => {
          const {
            collectorId,
            collectorName,
            collectorType: { label },
          } = collector;
          return (
            <Row key={collectorId}>
              <Cell className="u-size2of4">
                <Link href={itemUrl(collectorId)}>{collectorName}</Link>
              </Cell>
              <Cell className="u-size2of4">{label}</Cell>
            </Row>
          );
        })}
      </Table>
    </Panel>
  </>
);

export default compose(
  withPaginate({ size: 11 }),
  withFilter(),
  withEmpty({ component: EmptyList }),
  withSort(),
  withPaginate(),
)(CollectorsList);

CollectorsList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  itemUrl: PropTypes.func,
};
