import React from 'react';
import i18n from '~/i18n';
import cx from 'classnames';
import { Formik } from 'formik';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import Spin from '~/components/src/Spin';
import FormikCheckbox from '~/components/src/FormikCheckbox';
import { showSuccess } from '~/notificationCenter';
import Btn from '~/components/src/Btn';
import { addImportVariables, fetchImportVariables } from './dataService';
import { ImportFeedVariable, SetFieldValue } from './types';

export const ImportVariables = ({
  dataFeedId,
  setShowImportVariablesModal,
  refetchDataFeed,
}: {
  dataFeedId: string;
  setShowImportVariablesModal: (val: boolean) => void;
  refetchDataFeed: () => void;
}): JSX.Element => {
  const { data: importVariables, isLoading } = useAPI(() => fetchImportVariables(dataFeedId), [dataFeedId]);

  const handleImportVariables = async (values: { feedVariables: ImportFeedVariable[] }) => {
    const payload = { ...values, dataFeedId };
    await addImportVariables(payload);
    showSuccess({ header: i18n.t('dataFeeds:notifications.importSuccessful') });
    setShowImportVariablesModal(false);
    refetchDataFeed();
  };

  const handleVariableSelection = (
    variable: ImportFeedVariable,
    feedVariables: ImportFeedVariable[],
    setFieldValue: SetFieldValue,
  ) => {
    const exists = feedVariables.find(fv => fv.name === variable.name);
    if (exists)
      setFieldValue(
        'feedVariables',
        feedVariables.filter(fv => fv.name !== variable.name),
      );
    else setFieldValue('feedVariables', [...feedVariables, variable]);
  };

  const initialValues = {
    feedVariables: [],
  };

  if (isLoading && !importVariables) {
    return <Spin />;
  }

  if (importVariables?.length === 0) {
    return <Notification kind="warning">{i18n.t('dataFeeds:messages.noImportVariables')}</Notification>;
  }

  return (
    <div>
      <Formik onSubmit={handleImportVariables} enableReinitialize={true} initialValues={initialValues}>
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <p>{i18n.t('dataFeeds:messages.importVariablesHeader')}</p>
            <br />
            <p className="border-b-2 border-dashed border-slate-200 pl-2 text-gray-500">
              {i18n.t('dataFeeds:form.variableName')}
            </p>
            {importVariables?.map((variable: ImportFeedVariable, index: number) => (
              <div
                key={index}
                className={cx(
                  index % 2 ? 'bg-slate-100' : 'bg-slate-50',
                  't-importFeedVariables p-2 hover:bg-slate-200',
                )}
              >
                <FormikCheckbox
                  id={`feedVariables.${index}`}
                  name={`feedVariables.${index}`}
                  disabled={false}
                  checked={variable.selected}
                  onChange={() => handleVariableSelection(variable, values.feedVariables, setFieldValue)}
                  text={variable.name}
                />
              </div>
            ))}

            <div className="mt-6 flex justify-end gap-2">
              <Btn onClick={() => setShowImportVariablesModal(false)}>{i18n.t('common:actions.cancel')}</Btn>
              <Btn color="blue" type="submit" testHook="import">
                {i18n.t('dataFeeds:actions.import')}
              </Btn>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
