import React from 'react';
import { FormSection } from 'redux-form';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const MediaMathForm = props => {
  const { t, values, partner } = props;
  return (
    <FormSection name={constants.partnerTypes.MEDIA_MATH}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.cookieSyncMessage')}
        partnerNumber={partner.partnerNumber}
      />
    </FormSection>
  );
};

export default MediaMathForm;
