import React, { useEffect, useState } from 'react';
import { useAPI } from '~/common/ApiHooks';
import cusomterDataService from '~/profiles/customerData/dataService';
import DependantsContainer from '~/profiles/components/DependantsContainer';

const FactDependants = ({ factId, factName, selectedTabIndex, onTabChange, notFoundMessage }) => {
  const [dependants, setDependants] = useState({});
  const { data: dependantsApiResponse } = useAPI(
    () =>
      cusomterDataService.getDependants(factId, {
        toastText: notFoundMessage,
      }),
    [factId],
  );

  useEffect(() => {
    setDependants({ dependantsInfo: dependantsApiResponse });
  }, [dependantsApiResponse]);

  return (
    <DependantsContainer
      element={dependants}
      elementName={factName}
      selectedTab={selectedTabIndex}
      onTabChange={onTabChange}
      showContentDependants={false}
    />
  );
};

export default FactDependants;
