export const PERMISSIONS_FETCH_START = 'app/permissions/PERMISSIONS_FETCH_START';
export const PERMISSIONS_FETCH_FAIL = 'app/permissions/PERMISSIONS_FETCH_FAIL';
export const PERMISSIONS_FETCH_SUCCESS = 'app/permissions/PERMISSIONS_FETCH_SUCCESS';

export const USER_SITE_REMOVE_START = 'app/context/users/userSites/USER_SITE_REMOVE_START';
export const USER_SITE_REMOVE_FAIL = 'app/context/users/userSites/USER_SITE_REMOVE_FAIL';
export const USER_SITE_REMOVE_SUCCESS = 'app/context/users/userSites/USER_SITE_REMOVE_SUCCESS';

export const PERMISSIONS_UPDATE_START = 'app/permissions/PERMISSIONS_UPDATE_START';
export const PERMISSIONS_UPDATE_FAIL = 'app/permissions/PERMISSIONS_UPDATE_FAIL';
export const PERMISSIONS_UPDATE_SUCCESS = 'app/permissions/PERMISSIONS_UPDATE_SUCCESS';

export const USER_EDIT_PERMISSIONS_KEY = 'app/permissions/USER_EDIT_PERMISSIONS_KEY';

export const PERMISSION_DEPENDENCIES = {
  TAG_VIEW: {
    requires: {},
    disables: { TAG_PUBLISH: false, TAG_EDIT: false },
  },
  TAG_EDIT: {
    requires: { TAG_VIEW: true },
    disables: { TAG_PUBLISH: false },
  },
  TAG_PUBLISH: {
    requires: { TAG_EDIT: true, TAG_VIEW: true },
  },
  ADSERVING_VIEW: {
    requires: { PROFILES_VIEW: true },
    disables: { ADSERVING_EDIT: false },
  },
  ADSERVING_EDIT: {
    requires: { ADSERVING_VIEW: true, PROFILES_VIEW: true },
  },
  AUDIENCES_VIEW: {
    requires: { PROFILES_VIEW: true },
    disables: { AUDIENCES_EDIT: false },
  },
  AUDIENCES_EDIT: {
    requires: { AUDIENCES_VIEW: true, PROFILES_VIEW: true, PROFILES_EDIT: true },
  },
  PROFILES_VIEW: {
    requires: {},
    disables: {
      ADSERVING_VIEW: false,
      ADSERVING_EDIT: false,
      CUSTOMER_JOURNEY_VIEW: false,
      CUSTOMER_JOURNEY_EDIT: false,
      PROFILES_EDIT: false,
      AUDIENCES_VIEW: false,
      AUDIENCES_EDIT: false,
    },
  },
  PROFILES_EDIT: {
    requires: { PROFILES_VIEW: true },
    disables: { CUSTOMER_JOURNEY_EDIT: false },
  },
  CUSTOMER_JOURNEY_VIEW: {
    requires: { PROFILES_VIEW: true },
    disables: { CUSTOMER_JOURNEY_EDIT: false },
  },
  CUSTOMER_JOURNEY_EDIT: {
    requires: { CUSTOMER_JOURNEY_VIEW: true, PROFILES_VIEW: true, PROFILES_EDIT: true },
  },
  API_PROFILES_VIEW: {
    disables: { API_PROFILES_EDIT: false },
  },
  API_PROFILES_EDIT: {
    requires: { API_PROFILES_VIEW: true },
    apiPermission: true,
  },
  API_DATAFEED_VIEW: {
    disables: { API_DATAFEED_EDIT: false },
  },
  API_DATAFEED_EDIT: {
    requires: { API_DATAFEED_VIEW: true },
    apiPermission: true,
  },
  API_CUSTOMER_JOURNEY_VIEW: {
    disables: { API_CUSTOMER_JOURNEY_EDIT: false },
  },
  API_CUSTOMER_JOURNEY_EDIT: {
    requires: { API_CUSTOMER_JOURNEY_VIEW: true },
    apiPermission: true,
  },
  API_USER: {
    requires: {},
    disables: {
      API_CONTENT_VIEW: false,
      API_CUSTOMER_JOURNEY_EDIT: false,
      API_CUSTOMER_JOURNEY_VIEW: false,
      API_DATAFEED_EDIT: false,
      API_DATAFEED_VIEW: false,
      API_PROFILES_VIEW: false,
      API_PROFILES_EDIT: false,
      API_PROFILES_STREAM: false,
      API_SEGMENTS_VIEW: false,
    },
  },
};
