angular
    .module('webUi.ui.admin.urlBuilderColumnAdd', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.urlBuilderColumnAdd', {
                url: '/urlbuilder/column/add',
                isReact: true,
            });
        },
    ]);
