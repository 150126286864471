import React from 'react';
import cx from 'classnames';

const RulePickerCard = props => {
  const { icon, title, subtitle, testHook, isDisabled, onClick } = props;

  return (
    <div
      className={cx(
        `t-${testHook}`,
        'RulePickerCard group flex flex-col items-start justify-center gap-2 rounded-md border border-gray-300 bg-white p-2 text-gray-500',
        'hover:cursor-pointer hover:border-gray-800  hover:drop-shadow',
        {
          't-ruleDisabled pointer-events-none cursor-not-allowed opacity-50 grayscale': isDisabled,
        },
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        {icon}
        <span className={cx('t-ruleCardHeaderName text-xl text-gray-800', { 'text-gray-400': isDisabled })}>
          {title}
        </span>
      </div>
      <p className="px-2 font-light text-gray-400 group-hover:text-gray-600">{subtitle}</p>
    </div>
  );
};

export default RulePickerCard;
