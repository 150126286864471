angular.module('webUi.ui.tagmanagement', [
    'webUi.ui.tagmanagement.stats',
    'webUi.ui.tagmanagement.deploy',
    'webUi.ui.tagmanagement.publishing',
    'webUi.ui.tagmanagement.tags',
    'webUi.ui.tagmanagement.experiments',
    'webUi.ui.tagmanagement.cookiePermission',
    'webUi.ui.tagmanagement.publishing',
    'webUi.ui.tagmanagement.errorReporting',
    'webUi.ui.tagmanagement.tagCounters',
    'webUi.ui.tagmanagement.beacons',
    'webUi.ui.tagmanagement.urlbuilder',
    'webUi.ui.tagmanagement.newPaths'
]);
