const newExportActionTypes = {
  SET_IS_REMOTE_CONNECTION_SUCCESSFUL: 'SET_IS_REMOTE_CONNECTION_SUCCESSFUL',
  SET_EDIT_EXPORT_DATA: 'SET_EDIT_EXPORT_DATA',

  SET_ARE_CONNECTION_CREDENTIALS_CHECKED: 'SET_ARE_CONNECTION_CREDENTIALS_CHECKED',

  FETCH_REQUEST_TYPES_START: 'FETCH_REQUEST_TYPES_START',
  FETCH_REQUEST_TYPES_FAIL: 'FETCH_REQUEST_TYPES_FAIL',
  FETCH_REQUEST_TYPES_SUCCESS: 'FETCH_REQUEST_TYPES_SUCCESS',

  FETCH_EXPORT_TYPES_START: 'FETCH_EXPORT_TYPES_START',
  FETCH_EXPORT_TYPES_FAIL: 'FETCH_EXPORT_TYPES_FAIL',
  FETCH_EXPORT_TYPES_SUCCESS: 'FETCH_EXPORT_TYPES_SUCCESS',

  FETCH_ENGAGEMENT_TYPES_START: 'FETCH_ENGAGEMENT_TYPES_START',
  FETCH_ENGAGEMENT_TYPES_FAIL: 'FETCH_ENGAGEMENT_TYPES_FAIL',
  FETCH_ENGAGEMENT_TYPES_SUCCESS: 'FETCH_ENGAGEMENT_TYPES_SUCCESS',

  FETCH_EDIT_EXPORT_START: 'FETCH_EDIT_EXPORT_START',
  FETCH_EDIT_EXPORT_FAIL: 'FETCH_EDIT_EXPORT_FAIL',
  FETCH_EDIT_EXPORT_SUCCESS: 'FETCH_EDIT_EXPORT_SUCCESS',

  CREATE_NEW_REQUEST_START: 'CREATE_NEW_REQUEST_START',
  CREATE_NEW_REQUEST_FAIL: 'CREATE_NEW_REQUEST_FAIL',
  CREATE_NEW_REQUEST_SUCCESS: 'CREATE_NEW_REQUEST_SUCCESS',
};

export default newExportActionTypes;
