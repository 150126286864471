/**
 * @description Angular main module for Profiles -> ExternalFacts -> Dashboard
 * @memberOf webUi.ui.profiles.externalFacts
 * @namespace webUi.ui.profiles.externalFacts.dashboard
 */
angular.module('webUi.ui.profiles.externalFacts.dashboard', [])
    .config([ '$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.externalFacts.dashboard', {
            url : '/dashboard',
            isReact: true,
        });
    }
    ]);
