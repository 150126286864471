import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as consts from './types';
import AdminTypeService from './dataService.js';

/**
 * Fetch types
 */
export const fetchTypes = () => dispatch => {
  dispatch({ type: consts.TYPES_FETCH_START });

  return AdminTypeService.getTypes()
    .then(types => {
      dispatch({ type: consts.TYPES_FETCH_SUCCESS, payload: types });
    })
    .catch(error => dispatch({ type: consts.TYPES_FETCH_FAIL, payload: error }));
};

/**
 * Saves new type
 * @param type
 */
export const saveType = type => dispatch => {
  dispatch({ type: consts.TYPE_SAVE_START });

  return AdminTypeService.saveType(type)
    .then(typeId => {
      dispatch({
        type: consts.TYPE_SAVE_SUCCESS,
        payload: {
          ...type,
          typeId,
        },
      });
      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: consts.TYPE_SAVE_FAIL, payload: error }));
};

/**
 * Edit type
 */
export const editType = type => dispatch => {
  dispatch({ type: consts.TYPE_EDIT_START });

  return AdminTypeService.updateType(type)
    .then(() => {
      dispatch({ type: consts.TYPE_EDIT_SUCCESS, payload: type });

      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: consts.TYPE_EDIT_FAIL, payload: error }));
};

/**
 * Delete type
 * @param typeId
 */
export const deleteType = typeId => dispatch => {
  dispatch({ type: consts.TYPE_DELETE_START });

  return AdminTypeService.deleteType(typeId)
    .then(() => {
      showSuccess({ body: i18n.t('urlBuilder:types.api.deletionSuccess') });
      dispatch({ type: consts.TYPE_DELETE_SUCCESS, payload: typeId });
    })
    .catch(error => dispatch({ type: consts.TYPE_DELETE_FAIL, payload: error }));
};
