import React from 'react';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { translate } from 'react-i18next';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';
import ConnectorProperties from './ConnectorProperties';

export const Custom = ({ onEdit, connector, t, isSubmitted, usedVariables }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} isSubmitted={isSubmitted}>
    {formProps => (
      <>
        <SmallWrapperNoOffset>
          <Field
            as={FormikInputField}
            name="partnerAudienceId"
            label={t('cards.label.externalId')}
            placeholder={t('common.audienceCard.placeholder')}
            errorText={formProps.touched.partnerAudienceId && formProps.errors.partnerAudienceId}
            htmlFor={connector?.partnerDetails?.partnerId}
          />
        </SmallWrapperNoOffset>
        <ConnectorProperties
          isCustomCreatable={true}
          formProps={formProps}
          name="data"
          usedVariables={usedVariables}
          t={translate('audiences')}
        />
      </>
    )}
  </ConnectorGenericForm>
);
