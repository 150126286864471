import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common';
import {
  fetchVariantWithClientsIfNeeded,
  unRestrictClient,
  restrictClients,
  saveVariant,
} from '~/support/cookies/actions';
import * as selectors from '~/support/cookies/selectors';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { Tabs, kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import CookieVariantForm from '~/support/components/CookieForm';
import RestrictedClientsList from '~/support/components/RestrictedClientsList';
import * as fromModals from '~/modals';
import RestrictClientsModal from '~/support/cookies/containers/RestrictClientsModal';
import { Panel } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';

export class EditCookiePage extends Component {
  componentDidMount() {
    this.props.fetchVariantIfNeeded();
  }

  render() {
    const { props } = this;
    const { t, restrictedClients, restrictedClientsKey, unRestrictClient, showRestrictClientsModal, ...other } = props;

    return props.initialValues ? (
      <Page>
        <Tabs kind={kind.heading}>
          <TabList>
            <Tab testHook="tabLinkEdit">{t('tabs.edit')}</Tab>
            <Tab testHook="testLinkClients">{t('tabs.clients')}</Tab>
          </TabList>

          <TabPanel testHook="tabEdit">
            <Panel>
              <CookieVariantForm {...other} t={t} />
            </Panel>
          </TabPanel>

          <TabPanel testHook="tabClients">
            <Heading title={t('clients.header')}>
              <BtnIcon
                icon="restrictUser"
                color="red"
                onClick={() => showRestrictClientsModal(t)}
                testHook="restrictClients"
                tooltip={t('clients.restrictClients')}
              />
            </Heading>
            <RestrictedClientsList
              list={restrictedClients}
              t={t}
              stateKey={restrictedClientsKey}
              onDeleteClick={unRestrictClient}
            />
          </TabPanel>
        </Tabs>
      </Page>
    ) : (
      <Spinner />
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const id = ownProps?.params.variantId;
  return {
    id,
    initialValues: selectors.getVariantById(state, id),
    restrictedClients: selectors.getRestrictedClientsForVariant(state, id),
    restrictedClientsKey: 'SUPPORT/COOKIES/RESTRICTED_CLIENTS',
    cancelHref: buildUrl('support/cookiepermissionforms/list'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps?.params.variantId;
  return {
    fetchVariantIfNeeded: () => dispatch(fetchVariantWithClientsIfNeeded(id)),
    onSubmit: values => dispatch(saveVariant(values)),
    unRestrictClient: clientId => dispatch(unRestrictClient(id, clientId)),
    showRestrictClientsModal: t =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('restrict.header'),
          confirmText: t('restrict.confirmText'),
          onConfirm: () => dispatch(restrictClients(id)),
          content: <RestrictClientsModal variantId={id} t={t} />,
        }),
      ),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'cookieVariantEdit',
  }),
  translate(['cookies']),
)(EditCookiePage);
