import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionButtons from '~/components/src/ActionButtons';
import { hideModal as defaultHideModalAction } from '../actions';
import ModalWrapper from './ModalWrapper.js';
import ModalHeader from './ModalHeader';

import './styles.scss';

const ContentModal = ({
  title,
  content,
  cancelText,
  confirmText,
  onConfirm,
  hideModal,
  t,
  size,
  className,
  contentClassName = '',
  testHook,
}) => {
  const cancelString = cancelText || t('actions.cancel');
  const confirmString = confirmText || t('actions.save');

  const handleConfirm = isConfirmed => () => {
    if (isConfirmed) {
      const confirmPromise = Promise.resolve(onConfirm());
      confirmPromise
        .then(() => {
          hideModal();
        })
        .catch(() => {
          // in form modals it's on purpose
        });
    } else {
      hideModal();
    }
  };

  return (
    <ModalWrapper size={size} className={className}>
      <ModalHeader title={title} onCloseClick={handleConfirm(false)} />
      <div className={`t-modalBody p-4 ${contentClassName}`}>{content}</div>
      {onConfirm && (
        <ActionButtons
          className="pr-4 pb-4 pt-4"
          onConfirm={handleConfirm(true)}
          confirmText={confirmString}
          onDecline={handleConfirm(false)}
          declineText={cancelString}
          testHook={testHook}
        />
      )}
    </ModalWrapper>
  );
};

ContentModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default connect(null, (dispatch, ownProps) => {
  const customHideModalAction = ownProps.hideModal;
  const hideModalAction = customHideModalAction || defaultHideModalAction;
  return { hideModal: () => dispatch(hideModalAction()) };
})(ContentModal);
