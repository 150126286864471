/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/admin/url-builder/columns`;

/**
 * A service for columns related queries.
 */
export class ColumnUrlBuilderClass {
  getColumn(columnId) {
    return Api.callGet(`${BASE_PATH}/${columnId}`).then(response => response.data);
  }

  getColumns() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveColumn(column) {
    return Api.callPost(BASE_PATH, column).then(response => response.data);
  }

  updateColumn(column) {
    return Api.callPut(`${BASE_PATH}/${column.columnId}`, column).then(response => response.data);
  }

  deleteColumn(columnId) {
    return Api.callDelete(`${BASE_PATH}/${columnId}`, columnId).then(response => response.data);
  }
}

const columnUrlBuilderService = new ColumnUrlBuilderClass();
export default columnUrlBuilderService;
