import React, { useRef } from 'react';
import { DndProvider, DragLayerMonitor, DropTargetMonitor, XYCoord, useDrag, useDrop } from 'react-dnd';
import { get } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormikContext } from 'formik';
import cx from 'classnames';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import Btn from '~/components/src/Btn';
import ModalWindow from '~/components/src/ModalWindow/ModalWindow';
import ValueTransformation from './ValueTransformation';
import {
  ValueTransformationDraggableWrapperProps,
  ValueTransformationType,
  ValueTransformationsModalProps,
} from './types';

const VALUE_TRANSFORMATION = 'VALUE_TRANSFORMATION';

const moveValueTransformation = (
  valueTransformations: ValueTransformationType[],
  fromIndex: number,
  toIndex: number,
) => {
  const itemToMove = valueTransformations[fromIndex];
  valueTransformations.splice(fromIndex, 1);
  valueTransformations.splice(toIndex, 0, itemToMove);
  return valueTransformations;
};

const ValueTransformationDraggableWrapper = ({
  index,
  valueTransformations,
  children,
  setFieldValue,
  mappingPath,
}: ValueTransformationDraggableWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ canDrop }, drop] = useDrop({
    accept: VALUE_TRANSFORMATION,
    collect(monitor) {
      return {
        canDrop: monitor.canDrop(),
      };
    },
    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      setFieldValue(
        `${mappingPath}.transformations`,
        moveValueTransformation(valueTransformations, dragIndex, hoverIndex),
      );

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: VALUE_TRANSFORMATION,
    item: (): { index: number } => ({
      index,
    }),
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={cx('mb-2 cursor-move rounded border bg-white px-4 py-2', {
        'opacity-0': isDragging,
        'border-r42-blue border-dashed': canDrop && !isDragging,
      })}
    >
      {children}
    </div>
  );
};

const ValueTransformationsModal = ({
  mapping,
  mappingPath,
  isReadOnly = false,
  closeValueTransformationsModal,
}: ValueTransformationsModalProps): React.ReactElement => {
  const form = useFormikContext();
  const { setFieldValue, values } = form;

  const addValueTransformation = () => {
    const oldTransformation = get(values, `${mappingPath}.transformations`) || [];
    const newTransformation = {
      transformationType: '' as const,
    };

    setFieldValue(`${mappingPath}.transformations`, [...oldTransformation, newTransformation]);
  };

  const removeValueTransformation = (valueTransformationIndex: number) => {
    const transformations = get(values, `${mappingPath}.transformations`) || [];
    transformations.splice(valueTransformationIndex, 1);
    setFieldValue(`${mappingPath}.transformations`, transformations);
  };

  return (
    <ModalWindow title="Configure transformations" closeModal={closeValueTransformationsModal}>
      <>
        {mapping?.transformations?.length > 0 ? (
          <div className="my-2 flex flex-col overflow-auto p-2">
            <div className="flex py-2">
              <div className="ml-2 flex w-[8%] font-medium">{i18n.t('partners:eventConnector.order')}</div>
              <div className="w-[30%] font-medium">{i18n.t('partners:eventConnector.transformation')}</div>
              <div className="flex-1 font-medium">{i18n.t('partners:eventConnector.value')}</div>
            </div>
            <DndProvider backend={HTML5Backend} debugMode>
              {mapping?.transformations.map(
                (valueTransformation: ValueTransformationType, valueTransformationIndex: number) => (
                  <ValueTransformationDraggableWrapper
                    key={valueTransformationIndex}
                    index={valueTransformationIndex}
                    valueTransformations={mapping.transformations}
                    setFieldValue={setFieldValue}
                    mappingPath={mappingPath}
                  >
                    <ValueTransformation
                      valueTransformation={valueTransformation}
                      remove={() => removeValueTransformation(valueTransformationIndex)}
                      valueTransformationPath={`${mappingPath}.transformations[${valueTransformationIndex}]`}
                      index={valueTransformationIndex}
                      isReadOnly={isReadOnly}
                    />
                  </ValueTransformationDraggableWrapper>
                ),
              )}
            </DndProvider>
          </div>
        ) : (
          <div className="mx-2 my-4 p-2">
            <p className="flex justify-center rounded border bg-gray-50 px-2 py-4">
              {i18n.t('transformations:messages.noValueTransformationConfigured')}
            </p>
          </div>
        )}
        {!isReadOnly && (
          <div className="flex items-center justify-between">
            {!isReadOnly && (
              <BtnOutlined
                size="xs"
                icon="add"
                onClick={() => addValueTransformation()}
                testHook="addValueTransformation"
              >
                {i18n.t('partners:eventConnector.addValueTransformation')}
              </BtnOutlined>
            )}
            <Btn color="blue" type="submit" onClick={closeValueTransformationsModal} testHook="saveValueTransformation">
              {i18n.t('common:actions.save')}
            </Btn>
          </div>
        )}
      </>
    </ModalWindow>
  );
};

export default ValueTransformationsModal;
