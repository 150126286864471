angular.module('webUi.ui.context.sites.base', ['webUi.ui.context.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.sites', {
                'abstract': true,
                url: '/sites',
                views : {
                    'mainContent@' : {
                        templateUrl: 'ui/context/sites/base/base.tpl.html'
                    }
                }
            });
    }]);
