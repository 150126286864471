angular.module('webUi.ui.test.validation.popup', ['ui.bootstrap'])

    .controller('ValidationAddNewHobbyController', ['$uibModalInstance', 'dialogsModel', 'ValidService',
        function ValidationAddNewHobbyController($uibModalInstance, dialogsModel, ValidService) {

            var vm = this;
            vm.data = {
                developer: dialogsModel.data.developer,
                hobbies: dialogsModel.data.developer.hobbies
            };

            vm.isEmpty = ValidService.isEmpty;

            vm.isUniqueValue = dialogsModel.data.isUniqueCallback;

            vm.submit = function (newHobby) {
                dialogsModel.onSave(newHobby);
                $uibModalInstance.close(true);
            };

            vm.close = function () {
                $uibModalInstance.close(false);
            };

        }]);


