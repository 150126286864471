angular.module('webUi.ui.tagmanagement.cookiePermission.form', [
    'webUi.ui.tagmanagement.cookiePermission.base',
    'ui.codemirror'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.cookiePermission.form', {
                url : '/add?variantId',
                views : {
                    '@site.tagmanagement' : {
                        templateUrl : 'ui/tagmanagement/cookiePermission/form/form.tpl.html',
                        controller : 'CookiePermissionFormController'
                    }
                },
                leftMenu : false,
                resolve : {
                    formVariant : ['$stateParams', 'CookiePermissionService', function($stateParams, CookiePermissionService) {
                        return CookiePermissionService.getCookiePermissionVariant($stateParams.variantId);
                    }],
                    assetsPrefix: ['$rootScope', function($rootScope){
                        return $rootScope.assetsPrefix;
                    }],
                    groups: ['CookiePermissionService', function(CookiePermissionService){
                        return CookiePermissionService.getCookiePermissionGroups();
                    }],
                    settings: ['formVariant',  function(formVariant) {
                        // expose Settings
                        var settings = {
                            properties: JSON.parse(formVariant.templateDefaults),
                            javascriptSource: formVariant.templateJs,
                            cookiePermissionVariantId: formVariant.cookiePermissionVariantId
                        };
                        return settings;
                    }],
                    isEditingMode: [function(){
                        return false;
                    }]


                }
            })
            .state('site.tagmanagement.cookiePermission.edit', {
                url : '/edit/{settingsId}',
                views : {
                    '@site.tagmanagement' : {
                        templateUrl : 'ui/tagmanagement/cookiePermission/form/form.tpl.html',
                        controller : 'CookiePermissionFormController'
                    }
                },
                leftMenu : false,
                resolve : {
                    settings : ['$stateParams', 'CookiePermissionService', function($stateParams, CookiePermissionService) {
                        return CookiePermissionService.findCookiePermissionSetting($stateParams.settingsId);
                    }],
                    formVariant : ['$stateParams', 'CookiePermissionService', 'settings', function($stateParams, CookiePermissionService, settings) {
                        var variantId = settings.cookiePermissionVariant.cookiePermissionVariantId;
                        return CookiePermissionService.getCookiePermissionVariant(variantId);
                    }],
                    assetsPrefix: ['$rootScope', function($rootScope){
                        return $rootScope.assetsPrefix;
                    }],
                    groups: ['CookiePermissionService', function(CookiePermissionService){
                        return CookiePermissionService.getCookiePermissionGroups();
                    }],
                    isEditingMode: [function(){
                        return true;
                    }]
                }
            })
        ;
    }])

    .controller('CookiePermissionFormController', ['$scope', 'CookiePermissionService', 'CodeMirrorService', '$state', 'formVariant', 'assetsPrefix', 'groups', 'settings', 'isEditingMode',
        function CookiePermissionAddController($scope, CookiePermissionService, CodeMirrorService, $state, formVariant, assetsPrefix, groups, settings, isEditingMode) {

            $scope.settings = settings;
            // expose codemirror options in scope
            $scope.codemirrorOptionsJs = CodeMirrorService.getDefaultJavascriptOptions(false);
            $scope.codemirrorOptionsHtml = CodeMirrorService.getDefaultHtmlOptions(false);
            $scope.codemirrorOptionsCss = CodeMirrorService.getDefaultCssOptions(false);

            // expose permission item groups to scope
            $scope.permissionItemGroups = groups;
            // set the permission group to be the first one of the results
            $scope.newPermissionGroup = $scope.permissionItemGroups[0];

            $scope.templateFormCode = formVariant.templateFormCode;

            // expose submit function to scope
            $scope.submit = function submit() {
                if(!isEditingMode) {
                    CookiePermissionService.addCookiePermissionSetting($scope.settings).then(function(){
                        // when object successfully created, transition to view state
                        $state.transitionToStateWithSite('site.tagmanagement.cookiePermission.list', {});
                    });
                }else {
                    CookiePermissionService.updateCookiePermissionSetting($scope.settings).then(function(){
                        // when object successfully created, transition to view state
                        $state.transitionToStateWithSite('site.tagmanagement.cookiePermission.list', {});
                    });
                }
            };

            // expose preview function to scope
            $scope.preview = function(){
                CookiePermissionService.openCookiePermissionSettingPopup($scope.settings);
            };

            $scope.setsById = CookiePermissionService.getSetsById(assetsPrefix);

            $scope.getSelectedGroupName = function(groupId){
                var groups = $scope.permissionItemGroups;
                for ( var i = 0; i < groups.length; i++ ){
                    if ( groups[i].number === groupId ){
                        return groups[i].name;
                    }
                }
                return '';
            };

            $scope.deletePermissionItem = function(index) {
                $scope.settings.properties.items.splice(index,1);
            };

            $scope.addPermissionItem = function() {
                var text = $scope.newPermissionItemText;
                var groupId = $scope.newPermissionGroup;
                if(text === '') {
                    return;
                }
                $scope.settings.properties['items'].push({
                    content: text,
                    groupId: groupId
                });
                $scope.newPermissionGroup = $scope.permissionItemGroups[0];
                $scope.newPermissionItemText = '';
            };
        }
    ])
    .controller('CookiePopupController', ['$scope', '$uibModalInstance', 'dialogsModel',
        function CookiePopupController($scope,   $uibModalInstance,   dialogsModel){
            window.getSource = function() {
                return dialogsModel.settings.javascriptSource;
            };
            window.getProperties = function() {
                return dialogsModel.settings.properties;
            };

            $scope.refreshPopup = function(){
                $scope.contentRefreshing = true;
                var iframe = $('iframe.sv-cookiePopup')[0];
                iframe.contentDocument.location.reload(true);
                $scope.contentRefreshing = false;
            };

            $scope.closePopup = function(){
                $uibModalInstance.close();
            };

        }]);
