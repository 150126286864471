import { connect } from 'react-redux';
import { selectors } from 'react-redux-composable-list';
import CellSelected from './presenter';
import { select } from '../../helper/services';
import { tableContextWrapper } from '../context';

const mapStateToProps = (state, { stateKey, id, preselected = [], unselectables = [] }) => {
  const isSelected = selectors.getIsSelected(state, stateKey, id);

  return {
    state: select.getSelectState(id, isSelected, preselected, unselectables),
  };
};

export default tableContextWrapper(connect(mapStateToProps)(CellSelected));
