/**
 * @description Angular module for Export data -> View
 * @class ExportViewController
 * @memberOf webUi
 * @namespace webUi.ui.profiles.export
 */
angular.module('webUi.ui.profiles.export.view',
    [
        'webUi.ui.profiles.export.base',
        'webUi.ui.profiles.export.addExportRequest',
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.export.view', {
                url: '/view',
                isReact: true,
            });
    }]);

