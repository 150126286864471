angular.module('webUi.ui.tagmanagement.tags.view.content.codemirrorDialog', ['ui.bootstrap'])


    .controller('CodemirrorDialogController', ['$scope', '$timeout', '$uibModalInstance', 'dialogsModel', '$q', 'TagmanagementService',
        function CodemirrorDialogController($scope, $timeout, $uibModalInstance, dialogsModel, $q, TagmanagementService) {

            $scope.codeMirror = {
                newCode: dialogsModel.tagViewPojo.tag.content.code,
                oldCode: dialogsModel.tagViewPojo.tag.content.code
            };

            $scope.invalidSize = false;


            $scope.changeCodeState = {};

            $scope.cancel = function () {
                dialogsModel.onCancel();
                $uibModalInstance.close(true);
            };

            $scope.save = function () {
                var res = $q.defer();
                TagmanagementService.checkTagContentSize($scope.codeMirror.newCode, dialogsModel.tagViewPojo.tag.compression).then(function (result) {
                    if (result.tagSizeAccepted) {
                        $scope.invalidSize = false;
                        dialogsModel.onSave($scope.codeMirror.newCode, $scope.codeMirror.oldCode);
                        $uibModalInstance.close(true);
                        res.resolve(true);
                    } else {
                        $scope.invalidSize = true;
                        res.resolve(false);
                    }
                });
                return res.promise;
            };

            $scope.$on('hotKeyEvent', function (event, data) {

                // save on ctrl+s
                if (data.ctrlKey && data.charLower === 's') {

                    event.preventDefault();

                    // only if valid code
                    if ($scope.changeCodeState.changeCodeForm.$valid) {
                        $timeout(function () {
                            $scope.save();
                        });
                    }
                }
            });

            $scope.validation = {};
            $scope.$watch('validation.errors', function (res) {
                if (!_.isUndefined(res) && !_.isUndefined($scope.changeCodeState.changeCodeForm)) {
                    $scope.changeCodeState.changeCodeForm.cmModel.$setValidity('validCode', res.length === 0);
                }
            });
        }])
;
