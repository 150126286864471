import React from 'react';
import { Field } from 'redux-form';
import Link from '~/components/src/Link';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import { FormSection } from '~/components/src/Containers';
import constants from '../../constants';

const ExportSettingsForm = props => {
  const { t, formValues, engagementTypes, change } = props.data;
  const exportType = formValues.type && formValues.type.exportType;
  const subHeader = `new.settings.subHeader.${exportType}`;
  const isEngagements = exportType === constants.EXPORT_TYPES.ENGAGEMENTS;
  const isSessions = exportType === constants.EXPORT_TYPES.SESSIONS;
  const isTrackingEventStream = exportType === constants.EXPORT_TYPES.TRACKING_EVENTS_STREAM;
  const selectedEngagementTypes =
    formValues.settings &&
    Array.isArray(formValues.settings.engagementTypes) &&
    engagementTypes.asMutable().filter(el => formValues.settings.engagementTypes.includes(el.optionLabel));

  const shouldRenderSettings = isSessions || isEngagements || isTrackingEventStream;

  const handleSettingsChange = options => {
    if (options) {
      if (_.find(options, { optionId: '*' })) {
        return engagementTypes.asMutable().map(engagement => engagement.type);
      }
      return options.map(option => option.type);
    }
    return [];
  };

  return (
    <>
      {shouldRenderSettings && (
        <FormSection title={t('new.settings.header')} description={t(subHeader)}>
          <>
            {isSessions && (
              <Field
                label={t('new.settings.URL_PARAMETERS.TITLE')}
                name="urlParameters"
                component={ReduxFormInputField}
                placeholder={t('new.settings.URL_PARAMETERS.PLACEHOLDER')}
                type="text"
                suffix={
                  <Link
                    className="u-marginLeftL"
                    onClick={e => {
                      e.preventDefault();
                      change('settings.urlParameters', constants.PRE_DEFINED_GA_PARAMS.join('/,/'));
                    }}
                  ></Link>
                }
              />
            )}

            {isEngagements && (
              <FieldWrapper label={t('new.settings.FILTER.TITLE')} hintText={t('new.settings.FILTER.DEFAULT')}>
                <SelectElement
                  options={engagementTypes.asMutable()}
                  value={selectedEngagementTypes}
                  getOptionValue={option => option.optionId}
                  getOptionLabel={option => option.optionLabel}
                  allowSelectAll={true}
                  isMulti
                  allOption={{
                    optionLabel: t('common:selectAll'),
                    optionId: '*',
                  }}
                  onChange={option => {
                    change('settings.engagementTypes', handleSettingsChange(option));
                  }}
                  testHook="engagements"
                />
              </FieldWrapper>
            )}

            {isTrackingEventStream && (
              <Field
                label={t('new.settings.QUERY.TITLE')}
                name="query"
                component={ReduxFormInputField}
                placeholder={t('new.settings.QUERY.PLACEHOLDER')}
                type="text"
              />
            )}
          </>
        </FormSection>
      )}
    </>
  );
};

export default ExportSettingsForm;
