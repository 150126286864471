import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsMore from 'highcharts/highcharts-more';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import { formatNumber } from '~/common';
import './styles.scss';

NoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);

const Series = props => {
  const options = {
    styledMode: true,
    credits: false,
    chart: {
      type: props.chartType,
      plotBorderWidth: 0,
      height: props.height,
      style: {
        fontFamily: 'Roboto, sans-serif',
        marginTop: '10px',
      },
    },
    title: {
      text: props.title,
    },
    subtitle: {
      text: props.subtitle,
    },
    yAxis: {
      title: {
        text: props.yAxisTitle,
      },
    },
    xAxis: {
      categories: props.xAxisLabels,
      title: {
        text: props.xAxisTitle,
      },
    },
    tooltip: {
      formatter: props.isTooltipShared
        ? // eslint-disable-next-line func-names,space-before-function-paren,object-shorthand
          function formatSharedTooltip() {
            let s = `<b> ${this.x} </b>`;
            this.points.forEach(point => {
              s += `<br/> ${point.series.name} : ${formatNumber(point.y)}`;
            });

            return s;
            // eslint-disable-next-line func-names,space-before-function-paren,object-shorthand
          }
        : function formatTooltip() {
            return `<b> ${this.x} </b> <br/> ${this.series.name} : ${formatNumber(this.y)}`;
          },
      shared: props.isTooltipShared,
    },
    legend: props.legend,
    plotOptions: {
      series: {
        dataLabels: {
          enabled: props.isDataLabelsEnabled,
          format: '{point.name}',
        },
        label: {
          connectorAllowed: props.controlLegends,
        },
        pointStart: props.pointStart,
      },
    },
    series: props.series,
    lang: {
      noData: props.noDataMessage,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
  };

  return (
    <div style={props.style}>
      <HighChartWithZoom highcharts={Highcharts} options={options} />
    </div>
  );
};

Series.propTypes = {
  style: PropTypes.object,
  chartType: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  xAxisTitle: PropTypes.string,
  xAxisLabels: PropTypes.array,
  isTooltipShared: PropTypes.bool,
  legend: PropTypes.objectOf({
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  }),
  isDataLabelsEnabled: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.oneOf([
          PropTypes.number,
          PropTypes.objectOf({
            x: PropTypes.number,
            y: PropTypes.number,
            z: PropTypes.number,
            name: PropTypes.string,
            other: PropTypes.string,
          }),
        ]),
      ),
      color: PropTypes.string,
    }),
  ),
  controlLegends: PropTypes.bool,
  pointStart: PropTypes.any,
  noDataMessage: PropTypes.string,
  height: PropTypes.number,
};

Series.defaultProps = {
  style: {},
  chartType: 'line',
  title: '',
  subtitle: '',
  yAxisTitle: '',
  xAxisTitle: '',
  xAxisLabels: [],
  isTooltipShared: false,
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
  },
  isDataLabelsEnabled: false,
  series: [],
  controlLegends: true,
  pointStart: null,
  noDataMessage: 'No metric is available for the chart.',
  height: 250,
};

export { Series };
