angular.module('webUi.ui.support.tagtemplates.templates.base', ['webUi.ui.support.tagtemplates.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates.templates', {
                url : '/templates',
                leftMenu : true,
                abstract : true
            });
    }]);
