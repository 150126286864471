angular
    .module('webUi.ui.content.datafeeds.view', ['webUi.ui.content.datafeeds.base'])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.content.datafeeds.view', {
                url: '/view/{dataFeedId}',
                isReact: true,
            });
        },
    ]);