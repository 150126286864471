import React from 'react';
import BtnIcon from '~/components/src/BtnIcon';
import './AudienceHeaderButtons.scss';

const getRemoveAudienceTooltip = (t, audience, canEdit) => {
  const { deletability } = audience;
  let label = t('audiencePage.delete');
  if (!deletability.canDelete && deletability.usedBy) {
    label = t('audiencePage.deleteDependencyError');
  } else if (!deletability.canDelete || !canEdit) {
    label = t('audiencePage.permissionError');
  }

  return label;
};

export const AudienceHeaderButtons = ({
  t,
  audience,
  onEditClick,
  onCloneClick,
  onDeleteClick,
  canEdit,
  canClone,
  canDelete,
  showClone,
}) => (
  <div className="flex gap-4">
    {showClone && (
      <BtnIcon
        icon="clone"
        onClick={onCloneClick}
        tooltip={!canClone ? t('audiencePage.permissionError') : t('audiencePage.clone')}
        testHook="cloneAudienceButton"
        disabled={!canClone}
      />
    )}
    <BtnIcon
      icon="delete"
      onClick={onDeleteClick}
      tooltip={getRemoveAudienceTooltip(t, audience, canEdit)}
      testHook="deleteAudienceButton"
      disabled={!canDelete}
    />
    <BtnIcon
      icon="edit"
      color="blue"
      onClick={onEditClick}
      testHook="editAudienceButton"
      disabled={!canEdit}
      tooltip={!canEdit ? t('audiencePage.permissionError') : t('audiencePage.edit')}
    />
  </div>
);
