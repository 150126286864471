import React, { ReactNode, useState } from 'react';
import { Field, FormSection, InjectedFormProps } from 'redux-form';
import { isRequired } from '~/common';
import { useAPI } from '~/common/ApiHooks';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { I18NextT } from '~/components/src/Common/types';
import constants from '../../constants';
import { fetchPayloadTypes, fetchAuthenticationTypes } from './service';
import FormHeader from '../formHeader/FormHeader';
import { PayloadTypeWithValue, AuthenticationTypeWithValue, AuthenticationType, AuthenticationTypeName } from './types';

interface AdFormValues {
  name: string;
  partnerNumber: number;
  token: string;
  siteId: string;
  networkId: string;
  advancedSettings: boolean;
  payloadType: string;
  authenticationType: AuthenticationTypeName;
  [fieldName: string]: string | number | boolean;
}
interface AdFormProps {
  values: AdFormValues;
  partner: {
    partnerId: number;
    partnerNumber: number;
    partnerType: string;
    partnerName: string;
    configuration: Record<string, string | number | AuthenticationType>;
  };
  canUpdate: boolean;
  change: InjectedFormProps['change'];
  touch: InjectedFormProps['touch'];
  t: I18NextT;
}

function AdForm({ t, values, partner, canUpdate, canUpdate: isEditMode, change, touch }: AdFormProps): ReactNode {
  const { data: payloadTypesData } = useAPI(() =>
    fetchPayloadTypes().then(response => response.map((type): PayloadTypeWithValue => ({ ...type, value: type.name }))),
  );

  const { data: authenticationTypesData } = useAPI(() =>
    fetchAuthenticationTypes().then(response =>
      response.map(
        (authenticationType): AuthenticationTypeWithValue => ({
          ...authenticationType,
          value: authenticationType.name,
        }),
      ),
    ),
  );

  const [authenticationTypeChanged, setAuthenticationTypeChanged] = useState(false);

  const payloadTypes: PayloadTypeWithValue[] = payloadTypesData || [];
  const authenticationTypes: AuthenticationTypeWithValue[] = authenticationTypesData || [];

  const partnerNumber = values.partnerNumber || partner.partnerNumber;
  const initialAuthenticationType =
    partner.configuration && (partner.configuration.authenticationType as AuthenticationType | undefined);

  return (
    <FormSection name={constants.partnerTypes.ADFORM}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t(
          `form.notification.${
            partnerNumber === constants.ADFORM_PARTNER_NUMBER ? 'cookieSyncMessage' : 'storeMappingMessage'
          }`,
        )}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <>
          {isEditMode && (
            <Field
              label={t('form.formFields.authenticationType')}
              name="authenticationType"
              component={ReduxFormSelectField}
              options={authenticationTypes}
              getOptionLabel={(option: AuthenticationTypeWithValue) => option.label}
              getOptionValue={(option: AuthenticationTypeWithValue) => option.value}
              disabled={initialAuthenticationType && initialAuthenticationType.name === constants.OAUTH}
              validate={isRequired}
              onChange={(event, value) => {
                change('ADFORM.authenticationType', value);

                if (!authenticationTypeChanged) {
                  setAuthenticationTypeChanged(true);
                }
              }}
              touch={touch}
            />
          )}

          {values.authenticationType === constants.STANDARD && (
            <>
              <Field
                label={t('form.formFields.username')}
                name="username"
                component={ReduxFormInputField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                validate={isRequired}
                type="text"
              />
              <FieldWithEditMode
                label={t('form.formFields.password')}
                name="password"
                component={ReduxFormSensitiveField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                isNeedActivateEditMode={canUpdate}
                validate={isRequired}
                type="text"
              />
            </>
          )}
          {values.authenticationType === constants.OAUTH && (
            <>
              <Field
                label={t('form.formFields.clientId')}
                name="clientId"
                component={ReduxFormInputField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                validate={isRequired}
                type="text"
              />
              <FieldWithEditMode
                label={t('form.formFields.clientSecret')}
                name="clientSecret"
                component={ReduxFormSensitiveField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                isNeedActivateEditMode={canUpdate && !authenticationTypeChanged}
                validate={isRequired}
                type="text"
              />
            </>
          )}
          <Field
            label={t('form.formFields.dataProviderId')}
            name="dataProviderId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            disabled={canUpdate}
            validate={isRequired}
            onChange={(event, value) => {
              const payloadType = payloadTypes.find((type: PayloadTypeWithValue) => type.value === value);
              change('ADFORM.partnerNumber', payloadType?.partnerNumber);
            }}
            touch={touch}
          />
          {values.payloadType === constants.FIRST_PARTY_ID && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
              disabled={canUpdate}
            />
          )}
        </>
      </ContainerFormSection>
    </FormSection>
  );
}

export default AdForm;
