angular.module('webUi.ui.error.base', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.error', {
                'abstract': true,
                url: '/error',
                views : {
                    'mainContent@' : {
                        templateUrl: 'ui/error/base/base.tpl.html'
                    }
                }
            });
    }]);
