import { get, findIndex } from 'lodash';
import { getSites } from '~/context/sites';

export const getUserSites = (state, userId) => get(state, `context.users.userSites.byId[${userId}]`, []);

export const isFetchingUserSites = state => get(state, 'context.users.userSites.ui.isFetchingUserSites');

export const isAddingSites = state => get(state, 'context.users.userSites.ui.isAddingSites');

export const getNonSelectedSites = (state, userId) => {
  const userSites = getUserSites(state, userId);
  return (
    getSites(state)
      .filter(site => findIndex(userSites, { siteId: site.siteId }) === -1)
      // Remove Relay42 demo from the list
      .filter(site => site.siteNumber !== 42)
  );
};
