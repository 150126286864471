/**
 * @description Angular main module for Import/Export -> Export data
 * @memberOf webUi
 * @namespace webUi.ui.profiles.export
 */
angular.module('webUi.ui.profiles.export',
    [
        'webUi.ui.profiles.export.view'
    ]
);
