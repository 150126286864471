import React, { useContext, useState } from 'react';
import { getSiteId, useAPI } from '~/common';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import { getConnectorImage } from '~/profiles/connectors';
import { RULE_GROUP_TYPES } from '~/workflows/constants';
import {
  fetchRuleDefinitions,
  fetchStepData,
  getActivePartners,
  getRuleVariablesForEventListeners,
} from '~/workflows/dataService';
import { JourneyNode, RuleDefinitionsReponse } from '~/workflows/types';
import { onlyStepConnectors, useQuery } from '~/workflows/util';
import { getIntegrations, getUsedVariablesForJO, mapVariableModifications } from '../../Journey/Step/utils';
import { AudienceContext } from '../AudienceContext';
import StepConfiguration from './StepConfiguration';

const fetchRuleTypes = () => fetchRuleDefinitions(RULE_GROUP_TYPES.VARIABLE_MODIFICATION);
const fetchAllAvailableConnectors = () => getActivePartners();
const fetchDataForStepScreen = async (
  workflowId: string,
  triggerId: string | null,
  rootNodeChilds: JourneyNode[],
  nodeId: string,
) => {
  const fetchVariablesForEventListeners = () =>
    triggerId ? getRuleVariablesForEventListeners(workflowId, triggerId) : [];

  const requests = [fetchRuleTypes(), fetchAllAvailableConnectors(), fetchVariablesForEventListeners()];
  if (rootNodeChilds.length > 0) {
    requests.push(fetchStepData(nodeId));
  }
  const [ruleDefinitions, allConnectors, variables, step] = await Promise.all(requests);

  const transformedAllConnectors = onlyStepConnectors(allConnectors).map(connector => {
    const { partnerDetails } = connector;

    const supportedChangeTypes = connector.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    const imageUrl = getConnectorImage(getSiteId())(partnerDetails);

    return {
      activated: connector.activated,
      partnerType: partnerDetails.partnerType,
      partnerName: connector.name,
      partnerId: partnerDetails.partnerId,
      partnerNumber: partnerDetails.partnerNumber,
      image: imageUrl,
      supportsEventListeners,
    };
  });

  const stepName = step?.name || null;
  const stepId = step?.stepId || null;

  const integrations = step ? getIntegrations(step?.integrations) : [];
  const usedVariables = getUsedVariablesForJO(variables);
  const ruleTypesTree = getRulesTreeFromTypes(ruleDefinitions) as unknown as RuleDefinitionsReponse[];
  const variableModifications = step?.variableModifications ? mapVariableModifications(step.variableModifications) : [];

  return {
    ruleTypesTree,
    transformedAllConnectors,
    integrations,
    stepName,
    stepId,
    usedVariables,
    variableModifications,
  };
};

const StepConfigurationWrapper = () => {
  const { experimentSteps, triggerId, workflowId, rootNodeChilds, goToNextStep, refetchTree } =
    useContext(AudienceContext);
  const query = useQuery();
  const nodeId = query.get('nodeId');

  const [stepIdState, setStepIdState] = useState(nodeId || rootNodeChilds?.[0]?.nodeId);
  const { data, isLoading } = useAPI(
    () => fetchDataForStepScreen(workflowId, triggerId, rootNodeChilds, stepIdState),
    [stepIdState],
  );

  const {
    ruleTypesTree = [],
    transformedAllConnectors,
    integrations,
    variableModifications,
    usedVariables,
    stepId,
    stepName,
  } = data || {};

  return (
    <>
      <StepConfiguration
        {...{
          workflowId,
          ruleTypesTree,
          transformedAllConnectors,
          integrations,
          variableModifications,
          usedVariables,
          experimentSteps,
          rootNodeChilds,
          triggerId,
          stepName,
          stepId,
          stepIdState,
          setStepIdState,
          goToNextStep,
          refetchTree,
          isLoading,
        }}
      />
    </>
  );
};

export default StepConfigurationWrapper;
