import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, normalizeToInteger } from '~/common';
import Form from '~/components/src/Form/Form';
import Fields from '~/components/src/Form/Fields';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import BtnOutlined from '~/components/src/BtnOutlined';
import {
  connectionTypes,
  loginTypes,
  loginTypesSftp,
  portBasedOnConnectionType,
} from '../urlBuilder/exportConfigurations';

export class ConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { connectionType: 'SFTP', loginType: 'ANONYMOUS' };
  }

  componentDidMount() {
    this.props.cleanTestConnectionResult();
  }

  handleConnectionTypesChange(value) {
    this.setState({ connectionType: value });
    this.props.change('configuration.port', portBasedOnConnectionType[value]);
  }

  handleLoginTypeChange(value) {
    this.setState({ loginType: value });
  }

  testConnection(valid, testConnection) {
    const forceValidation = this.props.handleSubmit(() => {});
    if (valid) {
      testConnection(this.props.formState.values.configuration);
    } else {
      forceValidation();
    }
  }

  static getTestConnectionStatus(connectionStatus, t) {
    switch (connectionStatus) {
      case null:
        return '';
      case true:
        return <span className="is-correct">{t('exportConfigurations.configForm.testConnection.successMsg')}</span>;
      case false:
        return <span className="is-invalid">{t('exportConfigurations.configForm.testConnection.errorMsg')}</span>;
      default:
        return connectionStatus;
    }
  }

  render() {
    const { handleSubmit, submitting, backHref, valid, t, isConnectionTestSuccessful, testConnection, touch } =
      this.props;
    const username = (
      <Field
        label={t('exportConfigurations.configForm.username.label')}
        name="configuration.username"
        placeholder={t('exportConfigurations.configForm.username.placeholder')}
        component={ReduxFormInputField}
        validate={isRequired}
        type="text"
      />
    );
    const password = (
      <Field
        label={t('exportConfigurations.configForm.password.label')}
        name="configuration.password"
        placeholder={t('exportConfigurations.configForm.password.placeholder')}
        component={ReduxFormSensitiveField}
        validate={isRequired}
      />
    );
    const explicitTls = (
      <Field
        label={t('exportConfigurations.configForm.explicitTls.label')}
        name="explicitTls"
        type="checkbox"
        component={Fields.Checkbox}
      />
    );
    return (
      <Form onSubmit={handleSubmit} isSubmitting={submitting}>
        <Field
          label={t('exportConfigurations.configForm.enabled.label')}
          name="enabled"
          component={ReduxFormCheckboxField}
          hint={t('exportConfigurations.configForm.enabled.hint')}
        />
        <Field
          label={t('exportConfigurations.configForm.connectionType.label')}
          name="configuration.connectionType"
          validate={isRequired}
          options={connectionTypes}
          component={ReduxFormSelectField}
          onChange={(event, value) => {
            this.handleConnectionTypesChange(value);
          }}
          touch={touch}
        />
        <Field
          label={t('exportConfigurations.configForm.loginType.label')}
          name="configuration.loginType"
          validate={isRequired}
          options={this.state.connectionType === 'SFTP' ? loginTypesSftp : loginTypes}
          component={ReduxFormSelectField}
          onChange={(event, value) => {
            this.handleLoginTypeChange(value);
          }}
          touch={touch}
        />
        {this.state.loginType === 'NORMAL' && username}
        {this.state.loginType === 'NORMAL' && password}
        <Field
          label={t('exportConfigurations.configForm.host.label')}
          name="configuration.host"
          placeholder={t('exportConfigurations.configForm.host.placeholder')}
          component={ReduxFormInputField}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('exportConfigurations.configForm.port.label')}
          name="configuration.port"
          placeholder={t('exportConfigurations.configForm.port.placeholder')}
          component={ReduxFormInputField}
          normalize={normalizeToInteger}
          validate={[isRequired, defaultMaxInput]}
          type="number"
        />
        <Field
          label={t('exportConfigurations.configForm.directory.label')}
          name="configuration.directory"
          placeholder={t('exportConfigurations.configForm.directory.placeholder')}
          component={ReduxFormInputField}
          validate={[isRequired, defaultMaxInput]}
          type="text"
        />
        <Field
          label={t('exportConfigurations.configForm.exportAccountId.label')}
          hint={t('exportConfigurations.configForm.exportAccountId.hint')}
          name="exportAccountId"
          placeholder={t('exportConfigurations.configForm.exportAccountId.placeholder')}
          component={ReduxFormInputField}
          validate={[isRequired, defaultMaxInput]}
          type="text"
        />
        {this.state.connectionType === 'FTPS' && explicitTls}
        <div className="u-alignCenter u-marginTopXxl">
          <BtnOutlined
            color="blue"
            onClick={() => this.testConnection(valid, testConnection)}
            testHook="testConnectionButton"
            size="xs"
          >
            {t('exportConfigurations.configForm.testConnection.button')}
          </BtnOutlined>
          <div>{ConfigurationForm.getTestConnectionStatus(isConnectionTestSuccessful, t)}</div>
        </div>

        <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
      </Form>
    );
  }
}

ConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func,
  testConnection: PropTypes.func,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  isConnectionTestSuccessful: PropTypes.bool,
  touch: PropTypes.func,
};

export default ConfigurationForm;
