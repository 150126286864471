export const INVITE_TYPES = [
  { value: 'INVITE_ADVERTISER', label: 'INVITE_ADVERTISER' },
  { value: 'INVITE_PARTNER', label: 'INVITE_PARTNER' },
];

export const ADVERTISERS = {
  yahoo: 'YAHOO',
  doubleClick: 'DOUBLECLICK',
};

export const DEFAULT_SIZE = 20;
