import moment from 'moment';
import { changeUrl } from '~/common';
import * as types from './types';
import * as selectors from './selectors';
import SupportMessagesService from './dataService.js';

/**
 * Fetch message
 * @param messageId
 */
export const fetchMessage = messageId => dispatch => {
  dispatch({
    type: types.MESSAGE_FETCH_START,
    payload: messageId,
  });

  return SupportMessagesService.getMessage(messageId)
    .then(payload => {
      dispatch({
        type: types.MESSAGE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.MESSAGE_FETCH_FAIL, error }));
};

/**
 * Fetch message only if not in store
 * @param messageId
 */
export const fetchMessageIfNeeded = messageId => (dispatch, getState) => {
  if (selectors.getMessageById(getState(), messageId)) {
    return Promise.resolve();
  }
  return dispatch(fetchMessage(messageId));
};

/**
 * Delete message
 * @param messageId
 */
export const deleteMessage = messageId => dispatch => {
  dispatch({
    type: types.DELETE_START,
    payload: messageId,
  });

  // Remove Message on the backend
  return SupportMessagesService.deleteMessage(messageId)
    .then(() => {
      // Remove Message from Store
      dispatch({
        type: types.DELETE_SUCCESS,
        payload: messageId,
      });

      // Go to Message List
      changeUrl('support/messages/list');
    })
    .catch(error => dispatch({ type: types.DELETE_FAIL, error }));
};

/**
 * Save message
 * Updates existing message or creates a new one if there is no messageId
 * @param message
 */
export const saveMessage = message => dispatch => {
  const apiCall = message.messageId ? SupportMessagesService.updateMessage : SupportMessagesService.saveMessage;

  const messageModel = {
    ...message,
    showDate: moment(message.showDate).format('x'),
    hideDate: message.hideDate ? moment(message.hideDate).format('x') : null,
  };

  dispatch({
    type: types.SAVE_START,
    payload: messageModel,
  });

  // Save Message on the backend
  return apiCall(messageModel)
    .then(data => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: messageModel,
      });

      // We need to update Client Name and messageId
      dispatch(fetchMessage(data));

      // Go to the message view
      changeUrl(`support/messages/view/${data}`);
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Fetch all messages
 */
export const fetchMessages = () => (dispatch, getState) => {
  if (selectors.isFetchingMessages(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: types.MESSAGES_FETCH_START,
  });

  return SupportMessagesService.getAllMessages()
    .then(payload => {
      dispatch({
        type: types.MESSAGES_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.MESSAGES_FETCH_FAIL, error }));
};

/**
 * Fetch messages only if not fetched yet
 */
export const fetchMessagesIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchMessages(getState())) {
    return dispatch(fetchMessages());
  }
  return Promise.resolve();
};
