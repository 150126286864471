import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { MediumWrapper } from '~/components/src/Containers';
import { reduxForm, formValueSelector } from 'redux-form';
import * as basescriptSelectors from '~/context/basescripts/selectors';
import {
  fetchBasescriptModulesIfNeeded,
  fetchBasescriptsIfNeeded,
  saveBasescript,
} from '~/context/basescripts/actions';
import BasescriptForm from '~/context/components/BasescriptForm';

const FORM_NAME = 'basescriptNew';
const selectorReduxForm = formValueSelector(FORM_NAME);

export class AddBasescriptPage extends Component {
  componentDidMount() {
    this.props.fetchBasescriptsIfNeeded();
    this.props.fetchBasescriptModulesIfNeeded();
  }

  render() {
    const { t, initialValues, otherBasescripts, basescriptModules } = this.props;
    return (
      <Page>
        <MediumWrapper>
          {/* Make sure to get the initialValues before rendering */}
          {!initialValues ||
          !initialValues.modules.length > 0 ||
          !otherBasescripts.length ||
          !basescriptModules.length ? (
            <Spinner />
          ) : (
            <BasescriptForm {...this.props} t={t} />
          )}
        </MediumWrapper>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => ({
  authenticated: ownProps.authenticated,
  initialValues: basescriptSelectors.getDefaultValues(state),
  otherBasescripts: basescriptSelectors.getOtherBasescripts(state),
  basescriptModules: basescriptSelectors.getBasescriptModules(state),
  cancelHref: buildUrl('context/basescripts/list'),
  formBasescriptModules: selectorReduxForm(state, 'modules'),
});

const mapDispatchToProps = dispatch => ({
  fetchBasescriptsIfNeeded: () => dispatch(fetchBasescriptsIfNeeded()),
  fetchBasescriptModulesIfNeeded: () => dispatch(fetchBasescriptModulesIfNeeded()),
  onSubmit: values => dispatch(saveBasescript(values)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['basescripts']),
)(AddBasescriptPage);
