import { noop } from 'lodash';
import {
  Event,
  EventType,
  CreateTransformer,
  AddableEventDefinition,
  EventPayload,
  TransformerAdditionalParameters,
  ValidationErrorsByPath,
  Sources,
} from './transformerTypes';
import { createEvent } from './event';
import { withChangeHandler, validate } from './utils';
import { EVENT_TYPES } from './constants';
import { transformerSchema } from './validation';

const temporaryId = () => `newEvent-${Math.random().toString().slice(-5)}`;

const getEventPropertiesForEvent = (eventDefinitions: AddableEventDefinition[], type: EventType, name: string) => {
  const eventDefinition = eventDefinitions.find(
    eventDefinition => eventDefinition.eventType === type && eventDefinition.eventName === name,
  );

  return eventDefinition?.properties || [];
};

const createEventsFromPayload = (
  eventsPayload: EventPayload[],
  additionalParameters: TransformerAdditionalParameters,
  onChange: () => void,
) =>
  eventsPayload.map(eventPayload => {
    const eventProperties = getEventPropertiesForEvent(
      additionalParameters.eventDefinitions,
      eventPayload.eventType,
      eventPayload.eventName,
    );

    const sources: Sources = {
      eventProperties,
      internalEventProperties: additionalParameters.internalEventProperties,
    };

    const eventConfiguration = {
      id: temporaryId(),
      type: eventPayload.eventType,
      name: eventPayload.eventName,
      sources,
      mappings: eventPayload.mappings,
    };

    const eventAdditionalParameters = {
      knownDestinations: additionalParameters.knownDestinations,
      knownValueTransformations: additionalParameters.knownValueTransformations,
    };

    return createEvent(eventConfiguration, eventAdditionalParameters, onChange);
  });

export const createTransformer: CreateTransformer = (eventsPayload, additionalParameters, changeCallback = noop) => {
  let _events = [] as Event[];
  let _validationErrorsByPath = {} as ValidationErrorsByPath;

  const onChange = () => {
    _validationErrorsByPath = validate(_events, transformerSchema);
    changeCallback();
  };

  _events = createEventsFromPayload(eventsPayload, additionalParameters, onChange);

  const addEvent = (type: EventType, name: string): Event | null => {
    if (!EVENT_TYPES.includes(type)) {
      // Ignoring unsupported event type
      return null;
    }

    const eventProperties = getEventPropertiesForEvent(additionalParameters.eventDefinitions, type, name);

    const sources: Sources = {
      eventProperties,
      internalEventProperties: additionalParameters.internalEventProperties,
    };

    const eventConfiguration = {
      id: temporaryId(),
      type,
      name,
      sources,
      mappings: [],
    };

    const eventAdditionalParameters = {
      knownDestinations: additionalParameters.knownDestinations,
      knownValueTransformations: additionalParameters.knownValueTransformations,
      shouldAddSuggestedValueTransformations: true,
    };

    const event = createEvent(eventConfiguration, eventAdditionalParameters, onChange);

    _events.push(event);

    return event;
  };

  const deleteEvent = (eventId: string) => {
    _events = _events.filter(event => event.id !== eventId);
  };

  const stateChangingMethods = {
    addEvent,
    deleteEvent,
  };

  const stateChangingMethodsWithOnChange = withChangeHandler(stateChangingMethods, onChange);

  const listAddableEvents = () =>
    additionalParameters.eventDefinitions.filter(potentialEvent => {
      const isEventAddedAlready = _events.find(
        existingEvent =>
          existingEvent.type === potentialEvent.eventType && existingEvent.name === potentialEvent.eventName,
      );

      return !isEventAddedAlready;
    });

  const getValidationErrorByPath = (path: string) => {
    const errors = _validationErrorsByPath[path];
    return Array.isArray(errors) ? errors[0] : undefined;
  };

  const isTransformerValid = () => Object.keys(_validationErrorsByPath).length === 0;

  return {
    get events() {
      // Using a getter instead of direct access to avoid losing reference to _events when using
      // methods that replace the object, like .filter, for example (REASSIGNING?)
      return _events;
    },
    get validationErrors() {
      return _validationErrorsByPath;
    },
    isTransformerValid,
    getValidationErrorByPath,
    listAddableEvents,
    addEvent: stateChangingMethodsWithOnChange.addEvent,
    deleteEvent: stateChangingMethodsWithOnChange.deleteEvent,
  };
};
