import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { changeUrl } from '~/common/history';
import Notification from '~/components/src/Notification';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import ConnectorList from '~/profiles/components/ConnectorList';
import { components } from '~/components/src/Table';
import ConnectorPicker from '~/common/modules/connectors/components/ConnectorPicker';
import { getAssetsPrefix } from '~/common/SecurityMetaService';
import DependantsContainer from './DependantsContainer';
import './ConnectorSection.scss';

const { DetailContainer } = components;

class ConnectorSection extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
  }

  componentDidMount() {
    this.props.fetchData();
    this.props.fetchConnectorTypes();
  }

  componentWillUnmount() {
    const { onDependantsContainerClose } = this.props;
    if (typeof onDependantsContainerClose === 'function') {
      onDependantsContainerClose();
    }
  }

  handleFilterChange(value) {
    this.setState({ filter: value });
  }

  render() {
    const {
      actions,
      canEdit,
      t,
      isFetching,
      list,
      listKey,
      title,
      description,
      onDependantsContainerClose,
      dependantsContainerIsVisible,
      onDependantIconClick,
      onDependantsTabChange,
      selectedConnector,
      selectedTab,
      columns,
      isFetchingConnectorTypes,
      connectorTypes = [],
      isEventType = false,
    } = this.props;

    const addNewButtonText = this.props.addNewButtonText || t('list.addNew');

    const showLoading = isFetching || isFetchingConnectorTypes;

    const filteredConnectors = this.state.filter
      ? list
          ?.filter(
            connector =>
              `${connector.partnerNumber}`.includes(this.state.filter?.trim()?.toLowerCase()) ||
              connector.partnerName?.toLowerCase().includes(this.state.filter?.trim()?.toLowerCase()),
          )
          ?.sort((a, b) => a?.partnerName?.localeCompare(b?.partnerName))
      : list;

    const partnerTypeList = connectorTypes.map(connectorType => {
      const listItem = {
        title: connectorType.partnerName,
        testHook: connectorType.partnerType,
        partnerType: connectorType.partnerType,
        partnerName: connectorType.partnerName,
        partnerNumber: connectorType.partnerNumber,
        image: connectorType.image || `${getAssetsPrefix()}/img/partners/${connectorType.partnerType}.png`,
        key: `${connectorType.partnerType}_${connectorType.partnerNumber}`,
      };

      return listItem;
    });

    return (
      <section>
        <Heading title={title} className="ConnectorSection-heading my-4">
          <SearchElement
            className="ConnectorSection-heading--search"
            value={this.state.filter || ''}
            onChange={e => {
              this.handleFilterChange(e.target.value || undefined);
            }}
            placeholder="Search"
          />
          {canEdit && (
            <ConnectorPicker
              listItems={partnerTypeList}
              actionName={addNewButtonText}
              onSelect={connector =>
                changeUrl(`profiles/partners/form/${isEventType ? 'event' : 'server'}/step2/${connector.partnerType}`)
              }
              isEventType={isEventType}
              areDisabledItemsVisible={true}
            />
          )}
        </Heading>
        <Notification kind="information">
          <p>{description}</p>
        </Notification>
        {showLoading && <Spinner />}
        {!showLoading && (
          <>
            <ConnectorList
              actions={actions}
              list={filteredConnectors}
              stateKey={listKey}
              t={t}
              onDependantIconClick={onDependantIconClick}
              columns={columns}
            />
            {onDependantIconClick && (
              <div>
                <DetailContainer
                  onClose={onDependantsContainerClose}
                  isVisible={dependantsContainerIsVisible}
                  title={`${t('general.connector')} - ${selectedConnector.partnerName}`}
                >
                  <DependantsContainer
                    element={selectedConnector}
                    elementName={selectedConnector.partnerName}
                    onTabChange={onDependantsTabChange}
                    selectedTab={selectedTab}
                    showContentDependants={false}
                    showDataCollectorDependants={false}
                    showFilterDependants={false}
                    showTransformationDependants={false}
                  />
                </DetailContainer>
              </div>
            )}
          </>
        )}
      </section>
    );
  }
}

ConnectorSection.propTypes = {
  canEdit: PropTypes.bool,
  error: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isFetching: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  actions: PropTypes.func,
  list: PropTypes.array.isRequired,
  listKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onDependantsContainerClose: PropTypes.func,
};

export default ConnectorSection;
