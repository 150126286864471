import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { fetchSiteStatistics, fetchSite, fetchSiteModulesIfNeeded } from '~/context/sites/actions';
import * as selectors from '~/context/sites/selectors';
import SitesUsageStatistics from '~/context/components/SitesUsageStatistics';
import { DateRangeForm } from '~/context/components/DateRangeForm';
import { getAngularService } from 'ReactAngular/react.service';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';

const translation = 'sites';

const SHOW_USAGE_STATE_KEY = 'CONTEXT/SITES/SHOW_USAGE_STATE_KEY';

const defaultState = {
  startDate: moment(moment.now()).subtract(1, 'month').startOf('month'),
  endDate: moment(moment.now()),
};

export class SiteViewPage extends Component {
  componentDidMount() {
    this.props.fetchSiteStatistics({
      siteId: this.props.siteId,
      startDate: defaultState.startDate.format('x'),
      endDate: defaultState.endDate.format('x'),
    });
  }

  render() {
    const { backUrl, t, stats, stateKey, navigate } = this.props;
    const ContextService = getAngularService(document, 'ContextService');

    return (
      <Page>
        <Heading
          title={t('sites:usage.header')}
          crumbs={[
            {
              title: t('sites:usage.backToSite'),
              onClick: () => {
                navigate(backUrl);
              },
            },
          ]}
        >
          <BtnIcon
            color="blue"
            icon="download"
            tooltip={t('list.exportSites')}
            onClick={() => ContextService.exportSiteUsage(stats)}
            testHook="exportCSV"
          />
        </Heading>
        <DateRangeForm {...this.props} />
        {!stats || !stats.length ? <Spinner /> : <SitesUsageStatistics list={stats} stateKey={stateKey} t={t} />}
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const { siteId } = ownProps.params;
      return {
        siteId,
        stats: selectors.getSiteStats(state, siteId),
        backUrl: buildUrl(`context/sites/view/${siteId}`),
        stateKey: SHOW_USAGE_STATE_KEY,
        initialValues: defaultState,
      };
    },
    (dispatch, ownProps) => {
      const { siteId } = ownProps.params;
      return {
        siteId,
        fetchSite: id => dispatch(fetchSite(id)),
        fetchSiteModules: () => dispatch(fetchSiteModulesIfNeeded()),
        fetchSiteStatistics: model => dispatch(fetchSiteStatistics(model)),
        onSubmit: values => {
          const { startDate, endDate } = values;
          dispatch(
            fetchSiteStatistics({
              siteId,
              startDate: startDate.isValid() && startDate.format('x'),
              endDate: endDate.isValid() && endDate.format('x'),
            }),
          );
        },
      };
    },
  ),
  reduxForm({
    form: 'SiteUsageForm',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([translation]),
)(SiteViewPage);
