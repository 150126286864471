import * as Yup from 'yup';
import { buildUrl } from '~/common/history';

export const getMediaLibraryOrFolderUrl = (mediaLibraryId: string, folderPath: string[]): string => {
  let url = buildUrl(`content/medialibraries/view/${mediaLibraryId}`);

  if (folderPath.length > 1) {
    const pathWithoutMediaLibraryName = folderPath.slice(1);
    url += `?folderName=${pathWithoutMediaLibraryName.join(encodeURIComponent('~2F'))}`;
  }

  return url;
};

export const makeUniqueNameValidator = (
  existingNames: string[],
  currentName?: string,
): Yup.TestFunction<string | null | undefined> => {
  const validator: Yup.TestFunction<string | null | undefined> = inputValue => {
    if (!inputValue) {
      return false;
    }

    if (typeof currentName === 'string' && currentName === inputValue) {
      return true;
    }

    const lowerCaseNames = existingNames.map(existingName => existingName.toLowerCase());
    const isUsedAlready = lowerCaseNames.includes(inputValue?.toLowerCase());
    return !isUsedAlready;
  };

  return validator;
};
