import React from 'react';
import './styles.scss';

type CircleProgressType = { size?: number; percent?: number; strokeWidth?: number };

const CircleProgress = ({ size = 6, percent = 0, strokeWidth = 20 }: CircleProgressType) => {
  const degree = percent / 1.66 + (percent === 100 ? 1 : 0);
  return (
    <div className="flex items-center">
      <div className={`relative h-${size} w-${size}`}>
        <svg className="h-full w-full" viewBox="0 0 100 100">
          <circle
            className="stroke-current text-gray-200"
            strokeWidth={strokeWidth}
            cx="50"
            cy="50"
            r="40"
            fill="transparent"
          ></circle>
          <circle
            className="progress-ring__circle  stroke-current text-green-600"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            cx="50"
            cy="50"
            r="40"
            fill="transparent"
            strokeDashoffset={`calc(400 - (400 * ${degree} / 100)`}
          ></circle>
        </svg>
      </div>
      <div className="w-9 text-center">
        <span className="ml-1 mt-1 text-xs text-gray-500">{percent} %</span>
      </div>
    </div>
  );
};

export default CircleProgress;
