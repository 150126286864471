import React from 'react';
import Icon from 'react-icon-base';

const R42Goal = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M16.55 7.2a.249.249 0 0 1 0-.39l5.988-4.789c.521-.418.485-.8.417-1S22.674.5 22.006.5H5.993a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h16.013c.668 0 .882-.325.949-.519s.1-.58-.417-1z" />
    <path d="M2.506 0a1.5 1.5 0 0 0-1.5 1.5v21a1.5 1.5 0 0 0 3 0v-21a1.5 1.5 0 0 0-1.5-1.5z" />
  </Icon>
);

export default R42Goal;
