import React from 'react';
import { get, unset } from 'lodash';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import OptionalPropertySelector from '~/profiles/components/OptionalPropertySelector';
import TwoColumnWrapper from '~/common/modules/connectors/components/EditableConnectorsList/TwoColumnWrapper';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import { getDefaultType, getPropertyValue } from './utils';
import './ConnectorProperties.scss';

interface IProperty {
  title: string;
  id: string;
}

interface IConnectorProperties {
  requiredProperties?: IProperty[];
  allOptionalProperties?: IProperty[];
  usedVariables?: { name: string; profileVarId: string }[];
  name: string;
  hasStoredVariables?: boolean;
  hasStaticProperty?: boolean;
  hasProperty?: boolean;
  isCustomCreatable: boolean;
  propertyColumnLabel?: string;
  formProps: any;
  t: any;
}
interface IPropertyRow {
  usedVariables: { name: string; profileVarId: string }[];
  formProps: any;
  label: React.ReactNode;
  hasStoredVariables?: boolean;
  hasStaticProperty?: boolean;
  hasProperty?: boolean;
  name: string;
  isRemovable?: boolean;
  className?: string;
  t: any;
  id?: string;
  testHook?: string;
}
export const PropertyRow = ({
  usedVariables,
  formProps,
  label,
  hasStoredVariables,
  hasStaticProperty,
  hasProperty,
  name,
  isRemovable = false,
  className,
  t,
}: IPropertyRow) => {
  const defaultType = getDefaultType(hasProperty, hasStaticProperty, hasStoredVariables);
  const type = get(formProps.values, `${name}.type`, defaultType);
  const valueFromFormik = get(formProps.values, `${name}.value`);
  const value = getPropertyValue(valueFromFormik, type, usedVariables);
  const error = get(formProps.errors, `${name}.value`);

  const isTouched = get(formProps.touched, name);
  const explicitlyEmpty = get(formProps.values, name) === '';
  const modifiedByUser = isTouched || explicitlyEmpty;
  const errorTextToShow = modifiedByUser && error;

  const onDeleteClick = isRemovable
    ? () => {
        unset(formProps.values, name);
        formProps.setValues(formProps.values);
      }
    : null;

  return (
    <TwoColumnWrapper
      testHook="integrationProperty"
      label={label}
      onDeleteClick={onDeleteClick}
      className={className}
      t={t}
    >
      <OptionalPropertySelector
        name={name}
        options={usedVariables}
        hasStoredVariables={hasStoredVariables}
        hasStaticProperty={hasStaticProperty}
        hasProperty={hasProperty}
        testHook={`integrationProperty-${name}`}
        value={value}
        type={type}
        error={errorTextToShow}
        onChange={(value: string, type: string) => {
          formProps.setFieldValue(name, { value, type });
        }}
        onUnmount={() => {
          // Setting field value to undefined removes this field from Formik state
          formProps.setFieldValue(name, undefined);
        }}
        onBlur={() => {
          formProps.setFieldTouched(name);
        }}
      />
    </TwoColumnWrapper>
  );
};

/**
 *
 * @param {string} name -
 * @param {[{id, title}]} requiredProperties - array of properties which are required to send to a server
 * @param {[{id, title}]} allOptionalProperties - array of optional properties which are not required to send to a server
 * @param {[{id, title}]} usedVariables - array of optional properties which are not required to send to a server
 */
const ConnectorProperties = ({
  requiredProperties = [],
  allOptionalProperties = [],
  usedVariables = [],
  formProps,
  hasStoredVariables = false,
  hasStaticProperty = false,
  hasProperty = false,
  name, // name of the field which we send to a server, it contains properties
  // for example: name = "variables", we send to a server {variables: {property: {type: static, value: testValue}}}
  isCustomCreatable,
  propertyColumnLabel = '',
  t,
}: IConnectorProperties) => {
  const isEqualPropertyId = (value: any) => (property: { id: string }) => property.id === value;
  const variables = formProps.values[name] || {};
  const customArrayofProps = Object.keys(variables).filter(
    key => !allOptionalProperties.some(isEqualPropertyId(key)) && !requiredProperties.some(isEqualPropertyId(key)),
  );
  const hasEmptyCustomProperty = customArrayofProps.some(key => key === ' ');
  const usedOptionalProperties = Object.keys(variables).reduce((acc: IProperty[], key) => {
    const item = allOptionalProperties.find(isEqualPropertyId(key));
    if (item) {
      acc.push(item);
    }
    return acc;
  }, []);

  const availableOptionalProperties = allOptionalProperties.filter(property => !variables[property.id]);

  const rowProps = {
    usedVariables,
    formProps,
    hasStoredVariables,
    hasStaticProperty,
    hasProperty,
  };

  const handleKeyChange = (oldKey: string, newKey: string) => {
    const properties = { ...formProps.values[name] };
    const newProperties = Object.keys(properties)
      .map(key => {
        if (key === oldKey) {
          return newKey;
        }
        return key;
      })
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: properties[key === newKey ? oldKey : key],
        }),
        {},
      );

    formProps.setFieldValue(name, newProperties);
  };

  const hasPropertiesToDisplay = [...requiredProperties, ...usedOptionalProperties, ...customArrayofProps].length > 0;

  return (
    <div>
      {hasPropertiesToDisplay && (
        <div className="flex items-center gap-4 border-b border-gray-200 py-4">
          <div className="ConnectorProperties-propertyColumn">
            {propertyColumnLabel || i18n.t('audiences:connectors.properties.property')}
          </div>
          <div className="ConnectorProperties-valueColumn">{i18n.t('audiences:connectors.properties.value')}</div>
          <div className="h-2 w-10"></div>
        </div>
      )}

      {requiredProperties.map(property => (
        <PropertyRow
          key={property.id}
          id={property.id}
          label={property.title}
          name={`${name}.${property.id}`}
          t={t}
          {...rowProps}
        />
      ))}
      {usedOptionalProperties &&
        usedOptionalProperties.map(property => (
          <PropertyRow
            key={property.id}
            name={`${name}.${property.id}`}
            testHook={`optionalIntegrationProperty-${property.id}`}
            className="ConnectorProperties-selectRow"
            t={t}
            {...rowProps}
            label={
              <SelectElement
                className="ConnectorProperties-select"
                options={availableOptionalProperties}
                getOptionLabel={(option: IProperty) => option.title}
                getOptionValue={(option: IProperty) => option.id}
                value={allOptionalProperties.find(item => item.id === property.id)}
                onChange={(value: IProperty) => {
                  handleKeyChange(property.id, value.id);
                }}
              />
            }
            isRemovable={true}
          />
        ))}
      {isCustomCreatable && (
        <div>
          {customArrayofProps &&
            customArrayofProps.map(key => (
              <PropertyRow
                key={key}
                name={`${name}.${key}`}
                className="ConnectorProperties-selectRow"
                t={t}
                {...rowProps}
                label={
                  <SelectElement
                    isCreatable
                    autoFocus={key === ' '}
                    placeholder={t('common:controls.startTyping')}
                    className="ConnectorProperties-select"
                    value={{ label: key, value: key }}
                    options={[]}
                    isValidNewOption={inputValue => !customArrayofProps.includes(inputValue)}
                    noOptionsMessage={({ inputValue }) =>
                      i18n.t('audiences:connectors.properties.nonUniquePropertyName', { propertyName: inputValue })
                    }
                    onChange={({ value }: { value: string }) => {
                      // Removing dots from custom prop names, because having dots breaks the component
                      // ConnectorList uses dots to access nested properties within the values object
                      const trimmedAndWithoutDots = value.replaceAll(/\./g, '').trim();
                      handleKeyChange(key, trimmedAndWithoutDots);
                    }}
                    formatCreateLabel={(label: string) => `Custom: ${label}`}
                    isClearable={false}
                  />
                }
                isRemovable={true}
              />
            ))}
        </div>
      )}
      <div className="flex gap-2">
        {isCustomCreatable && (
          <BtnOutlined
            icon="add"
            size="xs"
            color="blue"
            onClick={() => {
              formProps.setFieldValue(`${name}. `, { value: '' });
            }}
            disabled={hasEmptyCustomProperty}
            testHook="addSinglePropertyButton"
          >
            {i18n.t('audiences:connectors.properties.addCustomProperty')}
          </BtnOutlined>
        )}
        {availableOptionalProperties?.length > 0 && (
          <BtnOutlined
            icon="add"
            size="xs"
            color="blue"
            onClick={() => {
              formProps.setFieldValue(`${name}.${availableOptionalProperties[0].id}`, { value: '' });
            }}
            testHook="addSinglePropertyButton"
          >
            {i18n.t('audiences:connectors.properties.addProperty')}
          </BtnOutlined>
        )}
        {availableOptionalProperties?.length > 1 && (
          <BtnOutlined
            size="xs"
            color="blue"
            onClick={() => {
              availableOptionalProperties.map(property =>
                formProps.setFieldValue(`${name}.${property.id}`, { value: '' }),
              );
            }}
          >
            {i18n.t('audiences:connectors.properties.addAllProperties')}
          </BtnOutlined>
        )}
      </div>
    </div>
  );
};

export default ConnectorProperties;
