import React, { useState } from 'react';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import i18n from '~/common/formValidation/i18n';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { showSuccess } from '~/notificationCenter';
import { createTaxonomy } from './dataService';
import SelectTaxonomy from './SelectTaxonomy';
import { AddTaxonomyProps, TAddTaxonomyForm } from './types';

const validationSchema = Yup.object().shape({
  parentTaxonomy: Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string(),
  }),
  taxonomyName: Yup.string().required(i18n.t('validation:validation.required')),
  taxonomyDescription: Yup.string().required(i18n.t('validation:validation.required')),
});

const initialValues: TAddTaxonomyForm = {
  parentTaxonomy: undefined,
  taxonomyName: '',
  taxonomyDescription: '',
};

const AddTaxonomy = ({ taxonomies, partnerId, hideModal, setNewTaxonomy }: AddTaxonomyProps) => {
  const [showParentTaxonomySelection, setShowParentTaxonomySelection] = useState(false);

  const handleCreateTaxonomy = async (values: TAddTaxonomyForm) => {
    const { parentTaxonomy, taxonomyName, taxonomyDescription } = values;

    if (!parentTaxonomy) return;

    const payload = {
      name: taxonomyName,
      description: taxonomyDescription,
      parentId: parentTaxonomy.id,
    };
    const newTaxonomy = await createTaxonomy(partnerId, payload);

    setNewTaxonomy('name', newTaxonomy.name);
    setNewTaxonomy('id', newTaxonomy.taxonomyId);
    showSuccess({
      body: i18n.t('audiences:connectors.yahoo.createTaxonomy.confirmText'),
    });
    hideModal();
  };

  return (
    <Formik onSubmit={handleCreateTaxonomy} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, setFieldValue, values, errors, touched, isSubmitting }) =>
        showParentTaxonomySelection ? (
          <>
            <p className="my-2">{i18n.t('audiences:connectors.yahoo.createTaxonomy.title1')}</p>
            <SelectTaxonomy
              selectedTaxonomy={values.parentTaxonomy}
              taxonomies={taxonomies || []}
              partnerId={partnerId}
              canSelectParentNode
              hideModal={() => setShowParentTaxonomySelection(false)}
              setNode={taxonomy => {
                setFieldValue('parentTaxonomy', taxonomy);
                setShowParentTaxonomySelection(false);
              }}
            />
          </>
        ) : (
          <div className="flex flex-col gap-2">
            <div>
              <span>{i18n.t('audiences:connectors.yahoo.createTaxonomy.parentTaxonomy')}</span>
              <div
                className={cx(
                  't-selectParentTaxonomy mt-2 cursor-pointer rounded border border-gray-300 px-2 py-3 text-gray-400',
                  {
                    'border-red-700 bg-red-50': touched.parentTaxonomy && !values.parentTaxonomy?.name,
                  },
                )}
                onClick={() => setShowParentTaxonomySelection(true)}
              >
                {values.parentTaxonomy?.name || i18n.t('audiences:connectors.yahoo.placeholder')}
              </div>
              {touched.parentTaxonomy && !values.parentTaxonomy?.name && (
                <span className="text-sm text-red-700">{i18n.t('validation:validation.required')}</span>
              )}
            </div>

            <Field
              className="my-2"
              as={FormikInputField}
              name="taxonomyName"
              label={i18n.t('audiences:connectors.yahoo.createTaxonomy.taxonomyName')}
              placeholder={i18n.t('audiences:connectors.yahoo.createTaxonomy.placeholder')}
              errorText={errors.taxonomyName}
            />
            <Field
              as={FormikInputField}
              name="taxonomyDescription"
              className="mb-2"
              label={i18n.t('audiences:connectors.yahoo.createTaxonomy.description')}
              placeholder={i18n.t('audiences:connectors.yahoo.createTaxonomy.placeholder')}
              errorText={errors.taxonomyDescription}
            />
            <Notification kind="information">
              {i18n.t('audiences:connectors.yahoo.createTaxonomy.infoText')}
            </Notification>
            <ActionButtons
              testHook="createTaxonomy"
              className="!mr-0 !pt-0"
              isConfirmEnabled={!isSubmitting}
              onConfirm={handleSubmit}
              onDecline={hideModal}
            />
          </div>
        )
      }
    </Formik>
  );
};

export default AddTaxonomy;
