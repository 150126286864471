import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import { isRequired } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
  ReduxFormDateTimeField,
} from '~/components/src/Form/Fields/ReduxFormFields';

export const MessageForm = ({ handleSubmit, submitting, clients, backHref, t, ...props }) => {
  const { touch } = props;
  return (
    <Form onSubmit={handleSubmit} isSubmitting={submitting}>
      <div>
        <Field
          label={t('common.title')}
          name="title"
          component={ReduxFormInputField}
          placeholder={t('form.title.placeholder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('common.client')}
          name="clientId"
          component={ReduxFormSelectField}
          options={clients}
          touch={touch}
        />
        <Field
          label={t('common.showDate')}
          name="showDate"
          component={ReduxFormDateTimeField}
          placeholder={t('form.showDate.placeholder')}
          validate={isRequired}
          touch={touch}
        />
        <Field
          label={t('common.hideDate')}
          name="hideDate"
          component={ReduxFormDateTimeField}
          placeholder={t('form.hideDate.placeholder')}
          touch={touch}
        />
        <Field
          label={t('common.text')}
          name="text"
          component={ReduxFormTextAreaField}
          placeholder={t('form.text.placeholder')}
          validate={isRequired}
        />
      </div>
      <FormActions t={t} submitting={submitting} cancelHref={backHref} />
    </Form>
  );
};
MessageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  clients: PropTypes.array,
  backHref: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  touch: PropTypes.func,
};

export default MessageForm;
