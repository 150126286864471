angular.module('webUi.ui.support.tagtemplates.templates.view', [
    'webUi.ui.support.tagtemplates.templates.base'
])

    .config(['$stateProvider',  function config($stateProvider) {

        $stateProvider
            .state('site.support.tagtemplates.templates.view', {
                url : '/view/{templateId}',
                isReact: true,
                leftMenu: true
            });
    }]);
