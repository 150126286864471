import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import ClientForm from '~/context/clients/components/ClientForm';
import {
  saveClient,
  DEFAULT_DATA_RETENTION_PERIOD,
  LOCATION_DEFAULT_VALUE,
  DEFAULT_PRIORITY_LEVELS,
} from '~/context/clients';
import { validateCriticalRateLimit } from '~/context/clients/records/utils';
import { DEFAULT_API_RATE_LIMIT } from '../../clients/records/types';

const selector = formValueSelector('addClientForm');

const AddClientFormContainer = ({ t, ...other }) => (
  <Page>
    <SmallWrapper>
      <Heading title={t('client.form.newClient')} />
      <Panel>
        <ClientForm {...other} t={t} dataStorageLocationIsEnabled={true} />
      </Panel>
    </SmallWrapper>
  </Page>
);

/**
 *
 * @returns {{backHref: *}}
 */
const mapStateToProps = state => ({
  backHref: buildUrl('context/clients/list'),
  initialValues: {
    apiRateLimit: DEFAULT_API_RATE_LIMIT,
    dataRetentionMonths: DEFAULT_DATA_RETENTION_PERIOD,
    mappingRetentionMonths: null,
    maxAllowedHistoricProcessingDays: null,
    errorAlertsEnabled: true,
    externalAuthentication: null,
    dataStorageLocation: LOCATION_DEFAULT_VALUE,
    priorityLevelCritical: DEFAULT_PRIORITY_LEVELS,
    apiRateLimitEnabled: true,
  },
  isUsageAlertsChecked: selector(state, 'usageAlertsEnabled'),
  isErrorAlertsChecked: selector(state, 'errorAlertsEnabled'),
  isApiRateLimitChecked: selector(state, 'apiRateLimitEnabled'),
  externalAuthentication: selector(state, 'externalAuthentication'),
  isPriorityLevelCriticalChecked: selector(state, 'priorityLevelEnabled'),
  isCritialValueValid: validateCriticalRateLimit(
    selector(state, 'apiRateLimit'),
    selector(state, 'priorityLevelCritical'),
  ),
});
/**
 *
 * @param dispatch
 * @returns {{fetchClients: function(), onFilterChange: function(*=): *}}
 */
const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(saveClient(values)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'addClientForm',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate('clients'),
)(AddClientFormContainer);
