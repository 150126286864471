import seamlessImmutable from 'seamless-immutable';
import { setPromiseState } from '~/common/utils/promiseState';

const connectorFormInitialState = seamlessImmutable({
  promise: {
    fetchingPartnerTypes: setPromiseState(),
    fetchingIdentifierTypes: setPromiseState(),
    fetchingAdvertisers: setPromiseState(),
    fetchingUserIdTypes: setPromiseState(),
    fetchingEndpoints: setPromiseState(),
    fetchingPayloadTypes: setPromiseState(),
    fetchingConsentTypes: setPromiseState(),
    creatingConnector: setPromiseState(),
    fetchingPartnerInformation: setPromiseState(),
    fetchingConnectorDatabases: setPromiseState(),
  },
  partnerTypes: [],
  consentTypes: [],
  partner: {},
  identifierTypes: [],
  endpoints: [],
  endpointLocations: [],
  advertisers: [],
  userIdTypes: [],
  payloadTypes: [],
  partnerInformation: {},
  connectorDatabases: [],
});

export default connectorFormInitialState;
