import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';
import SortCaret from '../../helper/components/SortCaret';

const Sort = ({ isActive, isReverse, onSort, suffix, children }) => {
  const linkClass = ['Table-sort'];
  if (isActive) {
    linkClass.push('is-active');
  }

  return (
    <div onClick={onSort} className={linkClass.join(' ')}>
      {children}
      &nbsp;
      <SortCaret suffix={suffix} isActive={isActive} isReverse={isReverse} />
    </div>
  );
};

Sort.propTypes = {
  isActive: PropTypes.bool,
  isReverse: PropTypes.bool,
  onSort: PropTypes.func,
  children: PropTypes.node,
};

export default Sort;
