import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import TypesForm from '../components/TypesForm';
import * as selectors from '../urlBuilder/types/selectors';
import { editType, fetchTypes } from '../urlBuilder/types/actions';

export class EditTypePage extends Component {
  componentDidMount() {
    this.props.fetchTypes();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('types.formEditType.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('types.formNewType.intro')}</p>
            <TypesForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      initialValues: selectors.getTypeById(state, ownProps.params.typeId),
      types: selectors.getTypes(state),
    }),
    dispatch => ({
      fetchTypes: () => dispatch(fetchTypes()),
      onSubmit: type => dispatch(editType(type)),
    }),
  ),
  reduxForm({
    form: 'typeEdit',
  }),
  translate(['urlBuilder']),
)(EditTypePage);
