import React, { ChangeEvent, FocusEvent } from 'react';
import cx from 'classnames';

interface ToggleElementProps {
  value: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const ToggleElement = ({ value, disabled, name, id, onChange, onBlur, className }: ToggleElementProps): JSX.Element => (
  <label
    className={cx(
      'relative inline-flex items-center',
      { 'cursor-not-allowed opacity-50': disabled },
      { 'cursor-pointer': !disabled },
      className,
    )}
  >
    <input
      type="checkbox"
      checked={value}
      name={name}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      className="peer sr-only"
    />
    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700"></div>
  </label>
);

export default ToggleElement;
