import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl, useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';
import { RowActions } from '~/components/src/Table/components';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { deleteDCAdvertiser, getDCAdvertisers } from '../dataService';
import { TDCAdvertiser } from '../types';
import DeleteAdvertiserModal from './DeleteAdvertiserModal';

type TDV360ListProps = { isSupportUser: boolean };

const columnHelper = createColumnHelper<TDCAdvertiser>();

const DoubleClickList = ({ isSupportUser }: TDV360ListProps) => {
  const [selectedDeletedItem, setSelectedDeletedItem] = useState<TDCAdvertiser | null>(null);
  const { state, isLoading, refetch } = useApiWithState(() => getDCAdvertisers());

  const navigate = useNavigate();

  const onDelete = async () => {
    if (selectedDeletedItem?.dcAdId) {
      await deleteDCAdvertiser(selectedDeletedItem?.dcAdId);
      showSuccess({ body: i18n.t('connectors:advertisers.doubleClick.api.deleted') });
      setSelectedDeletedItem(null);
      refetch();
    }
  };

  const getColumns = () => {
    const columns = [
      columnHelper.accessor('name', {
        header: () => <div className="text-left">{i18n.t('connectors:common.name')}</div>,
        cell: info => <div className="text-left">{info.getValue()}</div>,
        size: 400,
      }),
      columnHelper.accessor('advertiserId', {
        header: () => <div className="text-left">{i18n.t('connectors:common.advertiserId')}</div>,
        cell: info => <div className="text-left">{info.getValue()}</div>,
        size: 400,
      }),
      columnHelper.accessor('inviteType', {
        header: () => <div className="text-left">{i18n.t('connectors:common.inviteType')}</div>,
        cell: info => <div className="text-left">{info.getValue()}</div>,
        size: 400,
      }),
    ];

    if (isSupportUser) {
      columns.push(
        columnHelper.accessor('actions', {
          header: () => <div className="text-center">{i18n.t('connectors:common.actions')}</div>,
          cell: info => {
            const actions = [
              {
                name: i18n.t('connectors:advertisers.edit'),
                tooltip: i18n.t('connectors:advertisers.edit'),
                isDisabled: false,
                icon: 'edit',
                onClick: () => {
                  navigate(buildUrl(`admin/connectors/doubleClick/edit/${info?.row?.original?.dcAdId}`));
                },
                testHook: 'editDc',
              },
              {
                name: i18n.t('connectors:advertisers.delete'),
                tooltip: i18n.t('connectors:advertisers.delete'),
                isDisabled: false,
                icon: 'delete',
                onClick: () => {
                  setSelectedDeletedItem(info?.row?.original || null);
                },
                testHook: 'deleteDC',
                type: 'delete',
              },
            ];
            return (
              <div className="text-center">
                <RowActions actions={actions} />
              </div>
            );
          },
          size: 200,
          enableSorting: false,
        }),
      );
    }

    return columns.filter(Boolean);
  };

  if (isLoading) {
    return <Spin />;
  }
  return (
    <>
      <UITable
        enableSorting
        data={state ?? []}
        stickyHeader={false}
        testHook="dcAdvertisersTable"
        emptyMessage={i18n.t('connectors:advertisers.empty.header')}
        emptyBody={i18n.t('connectors:advertisers.empty.body')}
        columns={getColumns()}
      />

      {selectedDeletedItem && (
        <DeleteAdvertiserModal
          setSelectedDeletedItem={setSelectedDeletedItem}
          onDelete={onDelete}
          id={selectedDeletedItem?.dcAdId?.toString()}
          isDoubleClick
        />
      )}
    </>
  );
};

export default DoubleClickList;
