import React, { Component } from 'react';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import RouterTabs from '~/components/src/RouterTabs';
import { fetchSite, fetchSiteModulesIfNeeded, deleteSite } from '~/context/sites/actions';
import { SITE_TRUNCATE_FORM } from '~/context/sites';
import { kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import * as fromModals from '~/modals';
import SiteDetails from '~/context/components/SiteDetails';
import * as selectors from '~/context/sites/selectors';
import SiteTruncateContainer from '~/context/sites/containers/SiteTruncateContainer';
import UsersContainer from '~/admin/users/containers/UsersContainer';
import { Panel } from '~/components/src/Containers';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';

const translation = 'sites';

export class SiteViewPage extends Component {
  componentDidMount() {
    const { fetchSiteModules, fetchSite } = this.props;
    fetchSiteModules();
    fetchSite();
  }
  render() {
    const {
      t,
      isContextAdmin,
      site,
      siteRaw,
      editSiteUrl,
      urlBasescript,
      onDeleteSite,
      usageSiteUrl,
      truncateData,
      hasContextAdmin,
      loggedInUser,
    } = this.props;
    const ts = t('common:checkbox', { returnObjects: true });

    return !site || !urlBasescript ? (
      <Spinner />
    ) : (
      <Page>
        <RouterTabs kind={kind.heading} isInitial={true}>
          <TabList>
            <Tab>{t('view.tabs.basic')}</Tab>
            <Tab>{t('view.tabs.users')}</Tab>
          </TabList>

          {/* Site Basic Information */}
          <TabPanel testHook="basicInformationPanel">
            <Heading title={site.name}>
              {site && site.allowTruncate && (
                <BtnIcon
                  icon="truncate"
                  onClick={() => truncateData(site, t)}
                  tooltip={t('view.basic.actions.truncateData')}
                  testHook="truncateData"
                />
              )}
              <LinkIcon
                className="h-8 w-8"
                url={usageSiteUrl}
                tooltip={t('view.basic.actions.showUsage')}
                icon="dataUsage"
                testHook="showUsage"
              />
              {hasContextAdmin && (
                <BtnIcon
                  icon="delete"
                  onClick={() => onDeleteSite(site, t)}
                  tooltip={t('view.basic.actions.deleteSite')}
                  testHook="deleteSite"
                />
              )}
              {hasContextAdmin && (
                <LinkIcon
                  className="h-8 w-8"
                  url={editSiteUrl}
                  tooltip={t('view.basic.actions.editSite')}
                  icon="edit"
                  color="blue"
                  testHook="editSite"
                />
              )}
            </Heading>
            <Panel>
              <SiteDetails urlBasescript={urlBasescript} site={site} ts={ts} t={t} />
            </Panel>
          </TabPanel>

          {/* User list panel */}
          <TabPanel testHook="listPanel">
            <UsersContainer
              siteNumber={site.siteNumber}
              fetchSite={fetchSite}
              site={siteRaw}
              isSiteAdmin={false}
              isContextAdmin={isContextAdmin}
              shouldFetchClients={isContextAdmin}
              disableAddingNewUser={true}
              loggedInUser={loggedInUser}
              siteClientId={site.clientId}
            />
          </TabPanel>
        </RouterTabs>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const siteId = ownProps?.params.siteId;
  const hasContextAdmin = ownProps.isContextAdmin;
  return {
    siteId,
    hasContextAdmin,
    siteModules: selectors.getSiteModules(state),
    site: selectors.getSiteViewData(state, siteId),
    siteRaw: selectors.getRawSite(state, siteId),
    urlBasescript: selectors.getBasescripUrlForSite(state, siteId),
    editSiteUrl: buildUrl(`context/sites/edit/${siteId}`),
    usageSiteUrl: buildUrl(`context/sites/view/${siteId}/usage`),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const siteId = ownProps?.params.siteId;
  return {
    fetchSite: () => dispatch(fetchSite(siteId)),
    fetchSiteModules: () => dispatch(fetchSiteModulesIfNeeded()),
    truncateData: (site, t) =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('sites:truncate.header'),
          onConfirm: () => {
            dispatch(submit(SITE_TRUNCATE_FORM));
            return Promise.reject();
          },
          confirmText: t('sites:truncate.actions.confirm'),
          content: <SiteTruncateContainer siteId={site.siteId} t={t} />,
        }),
      ),
    onDeleteSite: (site, t) => {
      dispatch(
        fromModals.showModal(fromModals.DELETE_MODAL, {
          title: t('sites:deleteModal.title'),
          message: t('sites:deleteModal.message', { siteName: site.name }),
          onConfirm: () => dispatch(deleteSite(site.siteId)),
        }),
      );
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate([translation]),
)(SiteViewPage);
