import React from 'react';
import Icon from 'react-icon-base';

const R42Audience = props => (
  <Icon viewBox="0 0 12 13" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M7.934 5.73a.25.25 0 0 0 .06-.422 3.083 3.083 0 0 0-.723-.45.125.125 0 0 1-.018-.219 2.259 2.259 0 1 0-2.46 0 .125.125 0 0 1-.018.219 3.078 3.078 0 0 0-.74.462.25.25 0 0 0 .06.42c.463.206.866.526 1.173.929a.25.25 0 0 0 .2.1h1.067a.25.25 0 0 0 .2-.1c.312-.41.725-.734 1.199-.94zM4.837 2.35a.125.125 0 0 1 .186-.063 2.85 2.85 0 0 0 2.104.421.125.125 0 0 1 .147.13 1.256 1.256 0 1 1-2.437-.488zM5.133 8.489a2.258 2.258 0 1 0-3.487 1.882.125.125 0 0 1-.018.22 3.07 3.07 0 0 0-1.47 1.36.374.374 0 0 0 .33.549h4.773a.374.374 0 0 0 .331-.55 3.07 3.07 0 0 0-1.47-1.36.125.125 0 0 1-.018-.22 2.246 2.246 0 0 0 1.03-1.881zM1.69 8.08a.125.125 0 0 1 .186-.063 2.85 2.85 0 0 0 2.104.421.125.125 0 0 1 .147.129 1.256 1.256 0 1 1-2.437-.487zM10.915 10.9a3.03 3.03 0 0 0-.543-.31.125.125 0 0 1-.018-.22 2.258 2.258 0 1 0-2.458 0 .125.125 0 0 1-.018.22 3.07 3.07 0 0 0-1.47 1.36.374.374 0 0 0 .33.55h4.773a.374.374 0 0 0 .331-.55 3.07 3.07 0 0 0-.927-1.05zM7.94 8.08a.125.125 0 0 1 .186-.063 2.85 2.85 0 0 0 2.104.421.125.125 0 0 1 .148.129 1.256 1.256 0 1 1-2.438-.487z" />
    </g>
  </Icon>
);

export default R42Audience;
