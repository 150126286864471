import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import TextArea from '../Elements/Textarea';

export * from '../Elements/defaultSizes';

const TextareaRow = ({ meta, input, label, disabled, size, placeholder, hint, className }) => (
  <FormRow
    isBlock={true}
    hint={hint}
    disabled={disabled}
    name={input.name}
    label={label}
    meta={meta}
    className={className}
  >
    <TextArea
      disabled={disabled}
      input={input}
      size={size}
      isError={meta.touched && !!meta.error}
      placeholder={placeholder}
      testHook={`${input.name}Textarea`}
    />
  </FormRow>
);

TextareaRow.propTypes = {
  isBlock: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'default', 'wide', 'block']),
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default TextareaRow;
