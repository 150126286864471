angular.module('webUi.ui.content.campaigns.ads.conditions.randomMatching', ['webUi.common.Utils'])

    .controller('AdServingRandomMatchingConditionController', ['$scope','ValidService',
        function AdServingRandomMatchingConditionController    ($scope,  ValidService) {
		
            var conditionClazz = 'RandomMatchingCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            $scope.isValidPercentage = ValidService.isValidPercentage;
		
            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
		
        }
    ]);

