import React, { useEffect, useRef, useState } from 'react';
import Spin from '~/components/src/Spin';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as fromModals from '~/modals';
import { buildUrl, useApiWithState } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { ContextUsersService, matchUsers } from '~/context/users';
import { getAngularService } from 'ReactAngular/react.service';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';
import './Users.scss';
import contextUsersService from '../users/records/dataService';
import UsersTable from '../components/UsersTable';
import i18n from '../i18n';
import MatchUsersModal from '../users/containers/MatchUsersModal';

function useConditionalDebouncedCallback(callback, delay, dependencies, condition) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (condition()) {
      const debounceTimer = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(debounceTimer);
      };
    }
  }, dependencies);
}

const convertToSortString = inputArray => {
  if (inputArray.length === 0) return '';
  const sortArray = inputArray.map(item => `${item.id}${item.desc ? ',desc' : ',asc'}`);
  return sortArray.join(',');
};

export const UsersPage = ({ isContextAdmin, newUserHref = buildUrl('context/users/new'), onMatchClientsClick }) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState([]);
  const [loadingCsvdata, setLoadingCsvData] = useState(false);
  const [page, setPage] = useState(0);
  const prevSearch = useRef('');

  const { state, isLoading, refetch } = useApiWithState(
    () => ContextUsersService.getAllUsers(page, search, convertToSortString(sort)),
    [],
  );
  const { content = [], totalPages } = state || {};
  const isSearchLengthValid = () => {
    const isDifferent = prevSearch.current !== search;
    if (isDifferent) {
      setPage(0);
    }
    return isDifferent;
  };
  useConditionalDebouncedCallback(refetch, search ? 500 : 0, [search], isSearchLengthValid);

  const exportUsers = async () => {
    const ContextService = getAngularService(document, 'ContextService');
    setLoadingCsvData(true);
    const users = await contextUsersService.getAllUsersForExport();
    setLoadingCsvData(false);
    ContextService.exportUsers(
      users.map(user => ({
        ...user,
        clientPojoName: (user.clientPojo && user.clientPojo.name) || '-',
      })),
    );
  };

  useEffect(() => {
    if (page || state) {
      refetch();
    }
  }, [page, sort]);

  useEffect(() => {
    prevSearch.current = search;
  }, [search]);

  const listIsEmpty = content.length === 0;
  const searchIsEmpty = search.length === 0;
  const emptyMessage =
    listIsEmpty && !searchIsEmpty ? i18n.t('users:list.empty.header') : i18n.t('users:list.noResults.header');
  const emptyBody =
    listIsEmpty && searchIsEmpty ? i18n.t('users:list.empty.body') : i18n.t('users:list.noResults.body');
  return (
    <Page>
      <Heading title={i18n.t('users:list.header')}>
        <SearchElement
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={i18n.t('users:list.actions.search')}
        />
        <BtnIcon
          tooltip={i18n.t('users:list.actions.export')}
          icon="download"
          testHook="exportCSV"
          disabled={loadingCsvdata}
          onClick={() => exportUsers()}
        />

        {isContextAdmin && (
          <>
            <BtnIcon
              icon="compare"
              testHook="matchClients"
              onClick={() => onMatchClientsClick(refetch)}
              tooltip={i18n.t('users:list.actions.matchClients')}
            />
            <LinkIcon
              tooltip={i18n.t('users:list.actions.add')}
              icon="add"
              testHook="addUser"
              url={newUserHref}
              color="blue"
            />
          </>
        )}
      </Heading>
      {isLoading ? (
        <Spin />
      ) : (
        <UsersTable
          content={content}
          page={page}
          totalPages={totalPages}
          setPage={setPage}
          emptyMessage={emptyMessage}
          emptyBody={emptyBody}
          sort={sort}
          setSort={setSort}
        />
      )}
    </Page>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onMatchClientsClick: cb =>
    dispatch(
      fromModals.showModal(fromModals.CONTENT_MODAL, {
        title: i18n.t('users:match.header'),
        contentClassName: 'Modal-body--noScroll',
        confirmText: i18n.t('users:match.confirmText'),
        onConfirm: () => dispatch(matchUsers(cb)),
        content: <MatchUsersModal />,
        className: 'Users-matchClientsModal',
      }),
    ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(UsersPage);
