angular.module('webUi.directive.editable.select', ['ui.validate'])

    .controller('SelectEditingController', ['$scope', 'PubSubService', 'ValidService', function SelectEditingController($scope, PubSubService) {

        $scope.localVars = $scope.vars() || {};

        //the opts passed will be shown as options of the select with key and value specified by the properties in vars
        //enums have name/label, objects have id/name, etc
        var key = $scope.key = $scope.localVars['key'] || 'name';
        var value = $scope.value = $scope.localVars['value'] || 'label';

        //flag defined in extra options that determines whether to save just the key or the whole object
        //by default save the entire option object
        $scope.saveKey = $scope.localVars['saveKey'] || false;

        //Used in template
        $scope.isClearable = $scope.localVars['isClearable'] || false;
        $scope.placeholder = $scope.localVars['placeholder'] || 'No option specified';
        $scope.localVars.title = ($scope.localVars.title) ? 'Click to edit: ' + $scope.localVars.title : 'Click to edit';

        $scope.getDisplayValue = function () {
            if ($scope.saveKey) {
            //find option in extraOpts.opts and display the value
                var selectedOption = _.find($scope.selectOptions, function (option) {
                    return option[key] === $scope.editableObject.value;
                });
                return selectedOption[value];
            } else {
                var editableObject;
                if ($scope.useOptionGroups) {
                    editableObject = $scope.editableObject.value[$scope.item];
                    if (_.isObject(editableObject)) {
                        return editableObject[value];
                    }
                    return editableObject;
                } else {
                    editableObject = $scope.editableObject.value;
                    if (_.isObject(editableObject)) {
                        return editableObject[value];
                    }
                    return editableObject;
                }
            }
        };

        $scope.isEmpty = function (val) {
            return !_.isNumber(val) && _.isEmpty(val);
        };

        $scope.clearValue = function () {
            if (!$scope.editableDisabled) {
                PubSubService.publishEditingFinished($scope.options.editableFieldName, null);
            }
        };

        var initOptions = function () {
        // whether or not to use option groups
            $scope.useOptionGroups = !_.isUndefined($scope.localVars['groupKey']);

            if ($scope.useOptionGroups) {
                $scope.groupKey = $scope.localVars['groupKey'] || 'name';
                $scope.groupValue = $scope.localVars['groupValue'] || 'label';

                $scope.group = $scope.localVars['group'] || 'group';
                $scope.item = $scope.localVars['item'] || 'item';

                //compute whether we have multiple groups. If not, doesn't make sense to show the options grouped
                $scope.showGrouped = false;
                var groups = [];
                _.forEach($scope.selectOptions, function (option) {
                    if (!_.contains(groups, option[$scope.group][$scope.groupKey])) {
                        groups.push(option[$scope.group][$scope.groupKey]);
                    }
                });
                if (groups.length > 1) {
                    $scope.showGrouped = true;
                }
            }
        };

        initOptions();

        //TODO enable the beforeSave - check that the editableForm is indeed defined
    /*$scope.state.beforeSave = function () {
        return $scope.editableForm.$valid;
    };*/
    }]);
