angular.module('webUi.ui.tagmanagement.tags.form.step2', [
    'webUi.common.Utils',
    'webUi.service.template'
])
    .controller('TagmanagementTagFormStep2Controller', [
        '$scope',
        '$injector',
        '$compile',
        '$q',
        'Utils',
        'TagmanagementService',
        'TemplateService',

        /**
         *
         * @param $scope
         * @param $injector
         * @param $compile
         * @param $q
         * @param {Utils} Utils
         * @param {TagmanagementService} TagmanagementService
         * @returns {TagmanagementTagFormStep2Controller} TagmanagementTagFormStep2Controller
         */
        function TagmanagementTagFormStep2Controller($scope,
            $injector,
            $compile,
            $q,
            Utils,
            TagmanagementService,
            TemplateService) {

            $scope.templateState.editMode = true;

            TagmanagementService.getTagExecutionTypes().then(function (executionTypes) {
                $scope.tagExecutionTypes = executionTypes;
                if (_.isUndefined($scope.tag.executionType)) {
                    $scope.tag.tagExecutionType = $scope.state.selectedVariant.executionType;
                }
            });

            $scope.state.beforePrev = function () {
                $scope.templateState.editMode = false;
            };

            var cmModel;
            /**
             * Method called before to submit in Form
             * @returns {*}
             */
            $scope.state.beforeSubmit = function () {
                $scope.templateState.editMode = false;
                //If is customTag check if lintInProgress is not running
                if ($scope.isCustomTag() && !(_.isUndefined($scope.validation.getIsLintingProgress))) {
                    return !($scope.validation.getIsLintingProgress());
                }
                return true;
            };
            $scope.state.submitCallback = function () {
            };

            if ($scope.state.selectedVariant.templateEdit) {
                $scope.editTemplate = $scope.state.selectedVariant.templateEdit;
            }

            $scope.initTemplateScope = TemplateService.initTemplateScope.bind(this, $injector, $scope);

            $scope.validation = {'errors': null};

            $scope.$watch('validation.errors', function (res) {
                if (res && $scope.isCustomTag()) {
                    // do valid code check
                    $scope.templateForm.cmModel.$setValidity('validCode', res.length === 0);
                    // do too long check
                    TagmanagementService.checkTagContentSize($scope.tag.content.code, false).then(function (result) {
                        if (cmModel && !result.tagSizeAccepted) {
                            cmModel.$setValidity('isTooLong', false);
                        } else {
                            if (cmModel) {
                                cmModel.$setValidity('isTooLong', true);
                            }
                        }
                    });
                }
                if ($scope.templateForm.cmModel) {
                    cmModel = $scope.templateForm.cmModel;
                }
            });

            $scope.isCustomTag = function () {
                return ($scope.state.selectedTemplate.tagContentType.name === 'TEXT_TAG');
            };
        }
    ]);
