import React from 'react';
import moment from 'moment';
import { CellProps } from 'react-table';
import { ORCHESTRATION_CHART_COLORS } from '~/common/chart';
import i18n from '~/i18n';
import { getJourneyStats } from '~/workflows/dataService';
import { StepDailyStats, StepDailyStatsOrigin } from '../Journey/types';
import { ORIGIN_TYPES } from './constants';
import { ParsedJourneyStats, StatsResponse, StatsType } from './types';

export const getColumnTotal = (info: CellProps<StatsType>, accessor: string): number =>
  React.useMemo(() => info.rows.reduce((sum, row) => row.values[accessor] + sum, 0), [info.rows]);

export const getPercent = (reached: number, entered: number): string =>
  entered && reached ? ((reached / entered) * 100).toFixed(0) : '0';

const getAnalytics = (stats: StatsType[]) => {
  stats.map(stat => {
    stat.totalProfilesReached = stat.goals.reduce((sum, goal) => {
      goal.profilesCount = goal.profilesReachedGoal;
      return sum + goal.profilesReachedGoal;
    }, 0);
    stat.totalProfilesExitedCondition = stat.exitConditions.reduce((sum, exitRule) => {
      exitRule.profilesCount = exitRule.profilesExitedByCondition;
      return sum + exitRule.profilesExitedByCondition;
    }, 0);
    return stat;
  });

  return stats;
};

const parseStatsResponse = (response: StatsResponse) => ({
  journeyName: response.journeyName,
  stepAnalysis: getAnalytics(response.stepAnalysis),
  experimentAnalysis: getAnalytics(response.experimentAnalysis),
  totalStats: {
    profilesActive: response.journeyAnalysis?.profilesActive || 0,
    profilesEnteredJourney: response.journeyAnalysis?.profilesEntered || 0,
    totalProfilesReachedGoal: response.journeyAnalysis?.profilesReachedGoals || 0,
    totalProfilesExited: response.journeyAnalysis?.profilesExited || 0,
  },
});

export const getGoalNames = (stats: StatsType[]): Array<string> =>
  stats[0]?.goals.map((goal: { name: string }) => goal.name) || [];

export const getExitConditionNames = (stats: StatsType[]): Array<string> =>
  stats[0]?.exitConditions.map((exitCondition: { name: string }) => exitCondition.name) || [];

export const getJourneyDataAndStats = async (journeyId: string): Promise<ParsedJourneyStats> => {
  const journeyStats = await getJourneyStats(journeyId);
  return parseStatsResponse(journeyStats);
};

export const mapStepStats = (stats: StepDailyStats[], origin: StepDailyStatsOrigin | undefined) => {
  const entered: number[] = [];
  const enteredByHP: number[] = [];
  const exited: number[] = [];
  const exitedByHP: number[] = [];
  const reachedGoal: number[] = [];
  const reachedGoalByHP: number[] = [];
  const timestamp: string[] = [];

  const isRealTime = origin !== ORIGIN_TYPES.HISTORIC_PROCESSING;

  stats.forEach(stat => {
    if (stat.key.origin === ORIGIN_TYPES.HISTORIC_PROCESSING) {
      enteredByHP.push(stat.profilesAdded);
      exitedByHP.push(
        stat.profilesReachedExit + stat.profilesRemoved + stat.profilesMerged + stat.profilesExitedByCondition,
      );
      reachedGoalByHP.push(stat.profilesReachedGoal);
    } else {
      entered.push(stat.profilesAdded);
      exited.push(
        stat.profilesReachedExit + stat.profilesRemoved + stat.profilesMerged + stat.profilesExitedByCondition,
      );
      reachedGoal.push(stat.profilesReachedGoal);
    }

    timestamp.push(moment(stat.key.timestamp).format('D. MMM'));
  });

  return {
    series: [
      {
        name: i18n.t('workflow:journey.chart.entered'),
        id: 'entered',
        color: ORCHESTRATION_CHART_COLORS.ENTERED,
      },
      {
        name: i18n.t(`workflow:journey.chart.${isRealTime ? 'batched' : 'entered'}`),
        data: enteredByHP,
        stack: 'entered',
        color: isRealTime ? ORCHESTRATION_CHART_COLORS.BATCH : ORCHESTRATION_CHART_COLORS.ENTERED,
        linkedTo: 'entered',
      },
      isRealTime && {
        name: i18n.t('workflow:journey.chart.entered'),
        data: entered,
        stack: 'entered',
        color: ORCHESTRATION_CHART_COLORS.ENTERED,
        linkedTo: 'entered',
      },
      {
        name: i18n.t('workflow:journey.chart.reachedGoal'),
        id: 'reached',
        color: ORCHESTRATION_CHART_COLORS.REACHED,
      },
      {
        name: i18n.t(`workflow:journey.chart.${isRealTime ? 'batched' : 'reachedGoal'}`),
        data: reachedGoalByHP,
        stack: 'reached',
        color: isRealTime ? ORCHESTRATION_CHART_COLORS.BATCH : ORCHESTRATION_CHART_COLORS.REACHED,
        linkedTo: 'reached',
      },
      isRealTime && {
        name: i18n.t('workflow:journey.chart.reachedGoal'),
        data: reachedGoal,
        stack: 'reached',
        color: ORCHESTRATION_CHART_COLORS.REACHED,
        linkedTo: 'reached',
      },
      {
        name: i18n.t('workflow:journey.chart.exited'),
        id: 'exited',
        color: ORCHESTRATION_CHART_COLORS.EXITED,
      },
      {
        name: i18n.t(`workflow:journey.chart.${isRealTime ? 'batched' : 'exited'}`),
        data: exitedByHP,
        stack: 'exited',
        color: isRealTime ? ORCHESTRATION_CHART_COLORS.BATCH : ORCHESTRATION_CHART_COLORS.EXITED,
        linkedTo: 'exited',
      },
      isRealTime && {
        name: i18n.t('workflow:journey.chart.exited'),
        data: exited,
        stack: 'exited',
        color: ORCHESTRATION_CHART_COLORS.EXITED,
        linkedTo: 'exited',
      },
      isRealTime && {
        name: i18n.t('workflow:journey.chart.batched'),
        type: 'line',
        data: [],
        color: ORCHESTRATION_CHART_COLORS.BATCH,
        stack: 'batched',
        events: {
          legendItemClick() {
            const { chart } = this;
            const { series } = chart;

            const batchedStack = series.find((s: Highcharts.Series) => s.options.stack === 'batched');

            series.forEach((s: Highcharts.Series) => {
              if (s.options.name === i18n.t('workflow:journey.chart.batched') && s.options.stack !== 'batched')
                s.setVisible(!batchedStack.visible);
            });
          },
        },
      },
    ].filter(Boolean),
    xAxis: timestamp,
    noDataMessage: i18n.t('workflow:journey.chart.emptyMessage'),
  };
};
