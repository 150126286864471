(function() {
    'use strict';
    function pagingController() {
        var vm = this;

        var localPagingData = {};
        var emptyPagingData = {
            page: 0,
            size: 20,
            sort: [],
            skipCallback: false
        };

        vm.$onInit = function() {
            vm.pagingViewData = {};

            localPagingData = _.extend(emptyPagingData, vm.pagingData);
            vm.pagingData.size = localPagingData.size; // This is used in ui/src/app/filter/paging/paging.js to slice the data array
            if(vm.pagingTableLength !== undefined) {
                localPagingData.total = vm.pagingTableLength;
            }
            updateShownEntries(localPagingData.total, localPagingData.page, localPagingData.size);
        };

        vm.$doCheck = function() {
            if(vm.pagingData.total !== undefined && vm.pagingData.total !== localPagingData.total) {
                localPagingData.total = vm.pagingData.total;
                updateAfterDoCheck(vm.pagingData.search, localPagingData.search, localPagingData.total, localPagingData.page, localPagingData.size);
            } else if (vm.pagingTableLength !== undefined && vm.pagingTableLength !== localPagingData.total) {
                localPagingData.total = vm.pagingTableLength;
                updateAfterDoCheck(vm.pagingData.search, localPagingData.search, localPagingData.total, localPagingData.page, localPagingData.size);
            }
        };

        vm.changePage = function(page) {
            localPagingData.page = page;
            if(vm.callback !== undefined && typeof vm.callback === 'function' && !localPagingData.skipCallback) {
                vm.callback({paging: localPagingData});
            }
            localPagingData.skipCallback = false;
            updateShownEntries(localPagingData.total, localPagingData.page, localPagingData.size);
        };

        vm.isDisabled = function(condition) {
            return localPagingData.page === condition;
        };

        function updateAfterDoCheck(newSearch, oldSearch, total, page, size) {
            if (newSearch !== oldSearch) {
                localPagingData.search = newSearch;
                localPagingData.skipCallback = true; // in case of new search callback is called by frontend
                vm.changePage(0); // in case of new search start from 0 page
            } else {
                updateShownEntries(total, page, size);
            }
        }

        function updateShownEntries(total, page, size) {
            // TODO: Replace with PubSubService after paging and paginginfo directive is replaced with paging component
            vm.pagingData.page = page; // This is used in ui/src/app/filter/paging/paging.js to slice the data array
            vm.pagingViewData.page = page;
            vm.pagingViewData.nrOfEntries = total;
            var leftAndRightDistanceFromCurrentPage = 2;
            var maxNumberOfPagesToShow = 5;

            if(vm.pagingViewData.nrOfEntries === 0) {
                vm.pagingViewData.firstEntry = 0;
                vm.pagingViewData.lastEntry = 0;
            } else {
                vm.pagingViewData.firstEntry = (page * size) + 1;
                vm.pagingViewData.lastEntry = ((page + 1) * size);
                if(vm.pagingViewData.lastEntry > total) {
                    vm.pagingViewData.lastEntry = total;
                }
            }

            var lastPage = Math.max(Math.ceil(vm.pagingViewData.nrOfEntries / size) - 1, 0);
            // start from current page - leftAndRightDistanceFromCurrentPage with a minimum of maxNumberOfPagesToShow pagers, but don't do negative pages
            var pageFrom = Math.max(Math.min(page - leftAndRightDistanceFromCurrentPage, lastPage - (maxNumberOfPagesToShow - 1)), 0);
            // end at current page + leftAndRightDistanceFromCurrentPage with a minimum of maxNumberOfPagesToShow pagers, however not more than available
            var pageTo = Math.min(Math.max(page + leftAndRightDistanceFromCurrentPage, (maxNumberOfPagesToShow - 1)), lastPage);

            if(vm.pagingViewData.nrOfEntries > 0) {
                vm.pagingViewData.shownPages = _.range(pageFrom, pageTo + 1);
            } else {
                vm.pagingViewData.shownPages = [];
            }
            vm.pagingViewData.lastPage = lastPage;
        }
    }

    angular.module('webUi.component.paging', [])
        .component('paging', {
            templateUrl: 'component/paging/paging.tpl.html',
            controller: pagingController,
            controllerAs: 'vm',
            bindings: {
                pagingData: '<',
                callback: '&?',
                pagingTableLength: '<?'
            }
        });
})();
