import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import {
  TCookiePermission,
  TPermissionGroup,
  TCreateCookiePermissionsGroupPayload,
  TUpdateCookiePermissionsGroupPayload,
  TCookiePermissionsVariants,
} from './types';

const BASE_PATH = `${API_BASE}/tagmanagement`;
const BASE_PATH_SETTINGS = `${BASE_PATH}/cookiePermissionSettings`;
const BASE_PATH_GROUPS = `${BASE_PATH}/cookiePermissionGroups`;

export const getCookiePermissions = (): Promise<TCookiePermission[]> =>
  Api.callGet(`${BASE_PATH_SETTINGS}`).then(parseResponse);

export const deleteCookiePermissions = (id: string): Promise<string> =>
  Api.callDelete(`${BASE_PATH_SETTINGS}/${id}`).then(parseResponse);

export const getCookiePermissionsGroups = (): Promise<TPermissionGroup[]> =>
  Api.callGet(`${BASE_PATH_GROUPS}`).then(parseResponse);

export const createCookiePermissionsGroup = (
  payload: TCreateCookiePermissionsGroupPayload,
): Promise<TPermissionGroup> => Api.callPost(`${BASE_PATH_GROUPS}`, payload).then(parseResponse);

export const deleteCookiePermissionsGroup = (id: string): Promise<string> =>
  Api.callDelete(`${BASE_PATH_GROUPS}/${id}`).then(parseResponse);

export const updateCookiePermissionsGroup = (
  payload: TUpdateCookiePermissionsGroupPayload,
): Promise<TPermissionGroup> => Api.callPost(`${BASE_PATH_GROUPS}/edit`, payload).then(parseResponse);

export const getCookiePermissionsVariants = (): Promise<TCookiePermissionsVariants[]> =>
  Api.callGet(`${BASE_PATH}/cookiePermissionVariants`).then(parseResponse);
