angular.module('webUi.ui.error.noSiteAccess', ['webUi.ui.error.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.error.noSiteAccess', {
                url: '/no-site-access',
                templateUrl: 'ui/error/noSiteAccess/noSiteAccess.tpl.html',
                controller: 'NoSiteAccessController',
                resolve: {
                    siteNumber: ['SecurityService', '$q', function (SecurityService, $q) {
                        var def = $q.defer();
                        SecurityService.getSecurityContext().then(function (ctx) {
                            def.resolve(ctx.getCurrentSite().siteNumber);
                        });
                        return def.promise;
                    }]
                }
            });
    }])

    .controller('NoSiteAccessController', ['$rootScope', 'siteNumber', '$scope',
        function NoSiteAccessController($rootScope, siteNumber, $scope) {
            $scope.siteNumber = siteNumber;
        }
    ]);
