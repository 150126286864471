import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';
import Spinner from '../Spinner';

const OverlaySpinner = ({ isActive = false, testHook = 'overlaySpinner' }) =>
  !isActive ? null : (
    <div className={cx('OverlaySpinner', `t-${testHook}`)}>
      <div className="OverlaySpinner-spinner">
        <Spinner />
      </div>
    </div>
  );

OverlaySpinner.propTypes = {
  isActive: PropTypes.bool,
  testHook: PropTypes.string,
};

export default OverlaySpinner;
