import React from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const SelligentForm = props => {
  const { t, values, partner, canUpdate } = props;
  return (
    <FormSection name={constants.partnerTypes.SELLIGENT}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partner.partnerNumber}
      />
      <ContainerFormSection>
        <Field
          label={t('form.formFields.endpoint')}
          name="endpoint"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.username')}
          name="username"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <FieldWithEditMode
          label={t('form.formFields.password')}
          name="password"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default SelligentForm;
