import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell, RowActions } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('urlBuilder:valueSets.list.empty.header')} body={t('urlBuilder:valueSets.list.empty.body')} />
);

export const ValueSetsList = ({ list, stateKey, onEditClick, onDeleteClick, t, isSiteAdmin }) => (
  <Panel>
    <Table stateKey={stateKey} testHook="valuesSetsTable">
      <Row>
        <SortableHeader
          className="u-size1of4"
          sortKey="predefinedValuesSetName"
          title={t('urlBuilder:valueSets.list.name')}
        />
      </Row>
      {list.map(valueSets => {
        const { predefinedValuesSetId, predefinedValuesSetName, canDelete } = valueSets;
        const getDeleteTooltipText = (isSiteAdmin, canDelete) => {
          if (!canDelete) {
            return t('urlBuilder:valueSets.list.deleteInUseError');
          } else if (isSiteAdmin) {
            return t('urlBuilder:valueSets.list.deleteActionButton');
          }
          return t('urlBuilder:valueSets.list.deletePermisionError');
        };

        const actions = [
          {
            name: t('urlBuilder:valueSets.list.editActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:valueSets.list.editActionButton')
              : t('urlBuilder:valueSets.list.editPermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'edit',
            onClick: () => onEditClick(predefinedValuesSetId),
            testHook: 'edit',
          },
          {
            name: t('urlBuilder:valueSets.list.deleteActionButton'),
            tooltip: getDeleteTooltipText(isSiteAdmin, canDelete),
            isDisabled: !isSiteAdmin || !canDelete,
            icon: 'delete',
            onClick: () => onDeleteClick(valueSets, t),
            testHook: 'delete',
            type: 'delete',
          },
        ];

        return (
          <Row key={predefinedValuesSetId} withActions>
            <Cell className="u-size1of4">{predefinedValuesSetName}</Cell>
            <RowActions actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

ValueSetsList.ValueSetsList = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default compose(withSort(), withPaginate({ size: 11 }), withEmpty({ component: EmptyList }))(ValueSetsList);
