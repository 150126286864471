/**
 * @description Modal Service
 * @class ModalManagementService
 * @namespace webUi.service.modalManagement
 */
angular.module('webUi.service.modalManagement',
    [
        'webUi.common.Utils'
    ]
)
    .factory(
        'ModalManagementService',
        [
            '$uibModal',
            /**
         * @function ModalManagementService
         * @memberOf webUi.service
         *
         * @returns ModalManagementService
         */
            function ModalManagementService($uibModal){

                /**
             * @description Opens a dialog
             *
             * @param controller
             * @param template
             * @param dialogsModel
             * @param dialogOptions
             */
                var openDialog = function(controller, template, dialogsModel, dialogOptions) {

                    var options = dialogOptions || {};

                    var dialogsModelFn = dialogsModel;

                    // wrap dialogsModel in function if it is not a function
                    if(!_.isFunction(dialogsModelFn)) {
                        dialogsModelFn = function() {
                            return dialogsModel;
                        };
                    }

                    var d = $uibModal.open({
                        backdrop: 'static',
                        windowClass: (options.css) ? options.css: 'modal modal-medium',
                        keyboard: true,
                        backdropClick: true,
                        controller: controller + ' as vm',
                        templateUrl: template,
                        resolve: {
                            dialogsModel: dialogsModelFn
                        }
                    });

                    return d;
                };

                /**
             * RPM API
             * @type {ModalManagementService}
             */
                var service = {
                    openDialog: openDialog,
                    openDeleteDialog: function(dialogsModel, dialogOptions) {
                        return openDialog('DeleteDialogController', 'ui/globalui/deleteDialog/deleteDialog.tpl.html', dialogsModel, dialogOptions);
                    }
                };
                return service;
            }
        ]
    );
