import React, { useContext } from 'react';
import cx from 'classnames';
import Btn from '~/components/src/Btn';
import i18n from '~/i18n';
import { demoJourneyId } from '~/workflows/fake';
import { isJoDemoMode } from '~/workflows/fake/utils';
import { JourneyContext } from '../JourneyContext';
import { getItemWithExperimentStat } from '../util';
import ExitConditionItem from './ExitConditionItem';
import GoalItem from './GoalItem';
import JourneyStats from './JourneyStats';
import { ExitCondition, Goal } from './types';
import './styles.scss';

type JourneyRightPanelProps = {
  canEdit: boolean;
  goalError: boolean;
  setNodeToDelete: (node: any) => void;
};

const JourneyRightPanel = ({ canEdit, goalError, setNodeToDelete }: JourneyRightPanelProps) => {
  const { journeyData, journeyTree, actions } = useContext(JourneyContext);

  const journeyId = journeyData?.journeyId;
  const { goToCreateGoalScreen, goToCreateExitConditionScreen } = actions;
  const { exitConditions, goals, profilesActive, profilesEnteredJourney, totalProfilesReachedGoal, profilesExited } =
    journeyTree;

  const _isJoDemoMode = isJoDemoMode() && journeyId === demoJourneyId;

  const mappedGoals = getItemWithExperimentStat(journeyTree, goals);
  const mappedExits = getItemWithExperimentStat(journeyTree, exitConditions);

  return (
    <div className="t-journeyRightPanel border-gray=200 flex h-full w-1/5 flex-col border-l bg-gray-50">
      <JourneyStats
        totalEntered={profilesEnteredJourney}
        totalActive={profilesActive}
        totalReachedGoals={totalProfilesReachedGoal}
        profilesExited={profilesExited}
      />
      <div className="grid grid-flow-col grid-rows-1 gap-2  p-4">
        <Btn
          className={cx('w-full', {
            't-addGoal-error border-red-400 !bg-red-400 text-red-50 !ring-red-300': goalError,
          })}
          icon="goal"
          color="amber"
          size="sm"
          onClick={goToCreateGoalScreen}
          disabled={!canEdit}
          testHook="addGoal"
          tooltip={!canEdit ? i18n.t('workflow:orchestration.permissions.notEnoughPermissions') : undefined}
        >
          {i18n.t('workflow:orchestration.goals.addGoalButton')}
        </Btn>
        <Btn
          className={cx('w-full', {
            't-addExitCondition-error border-red-400 !bg-red-400 text-red-50 !ring-red-300': false, // Exit condition doesnt have validation
          })}
          icon="exited"
          color="red"
          size="sm"
          onClick={goToCreateExitConditionScreen}
          disabled={!canEdit}
          testHook="addExitCondition"
          tooltip={!canEdit ? i18n.t('workflow:orchestration.permissions.notEnoughPermissions') : undefined}
        >
          {i18n.t('workflow:orchestration.exits.addExitButton')}
        </Btn>
      </div>
      <div className="flex h-full flex-col gap-4 overflow-y-auto p-4">
        {mappedGoals?.map((goal: Goal) => (
          <GoalItem
            key={goal.nodeId}
            name={goal.label}
            reached={goal.stats.profilesReachedGoal}
            variantStats={goal.stats.variantStats}
            id={goal.nodeId}
            canEdit={canEdit}
            isDemoMode={_isJoDemoMode}
            setNodeToDelete={setNodeToDelete}
          />
        ))}
        {mappedExits?.map((exitCondition: ExitCondition) => (
          <ExitConditionItem
            key={exitCondition.nodeId}
            name={exitCondition.label}
            reached={exitCondition.stats.profilesExitedByCondition}
            variantStats={exitCondition.stats.variantStats}
            id={exitCondition.nodeId}
            canEdit={canEdit}
            isDemoMode={_isJoDemoMode}
            setNodeToDelete={setNodeToDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default JourneyRightPanel;
