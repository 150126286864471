angular.module('webUi.service.tagmanagement.experiments', [
    'restangular',
    'webUi.service.pubSub',
    'webUi.common.Utils'
])

    .factory('ExperimentService', ['Restangular', 'PubSubService', 'Utils', '$q', function(Restangular, PubSubService, Utils, $q){
        var service = {
            /**
		 * Retrieves a promise of a list of all experiments
		 * @returns {Array} a promise list of all experiments
		 */
            getExperiments: function() {
                return Restangular.all('tagmanagement/experiments').getList();
            },

            /**
		 * Saves an experiment
		 * @param {experiment} experiment the experiment to save
		 * @returns A Restangular promise
		 */
            save: function(experiment) {
                return Restangular.all('tagmanagement/experiments').post(experiment);
            },
            /**
		 * Deletes an experiment
		 * @param {UUID} experimentId
		 * @returns {void}
		 */
            deleteExperiment: function(experimentId) {
                return Restangular.one('tagmanagement/experiments', experimentId).remove();
            },
            /**
		 * Can delete an experiment
		 * @param {UUID} experimentId
		 * @returns {true} or {false}
		 */
            canDeleteExperiment: function(experimentId) {
                return Restangular.one('tagmanagement/experiments/canDelete', experimentId).get();
            },

            /************************** Mutators **************************/

            /**
		 * Updates the name of a experiment
		 * @param experimentId
		 * @param name
		 * @return {void}
		 */
            updateExperimentName: function(experimentId, name) {
                return Restangular.one('tagmanagement/experiments').post('updateExperimentName', {
                    experimentId: experimentId,
                    name: name
                });
            },
            /**
		 * Updates the start date of an experiment
		 * @param {UUID} experimentId the id of the experiment
		 * @param startDate
		 * @return {void}
		 */
            updateExperimentStartDate: function(experimentId, startDate) {
                return Restangular.one('tagmanagement/experiments').post('updateExperimentStartDate', {
                    experimentId: experimentId,
                    startDate: startDate
                });
            },
            /**
		 * Updates the end date of an experiment
		 * @param {UUID} experimentId the id of the experiment
		 * @param endDate
		 * @return {void}
		 */
            updateExperimentEndDate: function(experimentId, endDate) {
                return Restangular.one('tagmanagement/experiments').post('updateExperimentEndDate', {
                    experimentId: experimentId,
                    endDate: endDate
                });
            },

            /**
		 * Updates an Experiment Group's name
		 * @param {UUID} experimentId
		 * @param {UUID} experimentGroupId
		 * @param {String} name the new name
		 * @returns {void}
		 */
            updateExperimentGroupName: function(experimentId, experimentGroupId, name) {
                return Restangular.one('tagmanagement/experiments').post('updateExperimentGroupName', {
                    experimentId : experimentId,
                    experimentGroupId : experimentGroupId,
                    name: name
                });
            },
            /**
		 * Updates an Experiment Group's weight
		 * @param {UUID} experimentId
		 * @param {UUID} experimentGroupId
		 * @param {long} weight
		 * @returns {void}
		 */
            updateExperimentGroupWeight: function(experimentId, experimentGroupId, weight){
                return Restangular.one('tagmanagement/experiments').post('updateExperimentGroupWeight', {
                    experimentId : experimentId,
                    experimentGroupId : experimentGroupId,
                    weight: weight
                });
            },

            /**
		 * Updates the notify email of a experiment
		 * @param experimentId
		 * @param name
		 * @return {void}
		 */
            updateExperimentNotifyEmail: function(experimentId, notifyEmail) {
                return Restangular.one('tagmanagement/experiments').post('updateNotifyEmail', {
                    experimentId: experimentId,
                    notifyEmail: notifyEmail
                });
            },

            /************************** POJOs **************************/

            /**
		 * Returns the experiment pojo, used on the right side of the experiments page (the view part)
		 */
            getExperimentViewPojo: function(id, snapshotId) {
                var experimentPromise = $q.defer();

                Restangular.one('tagmanagement/experiments/viewPojos', id).get({ snapshotId: snapshotId }).then(function(experimentViewPojo) {
                    if(experimentViewPojo) {
                        experimentViewPojo = Utils.sanitizeRestangularOne(experimentViewPojo);

                        //@todo marius/koen: remove after migration.
                        //
                        //augments the experiment group object with isControlGroup
                        //for experiments which don't have it. we take the 1st one in array
                        //
                        var _isControlGroupSet = false;
                        _.forEach(experimentViewPojo.experimentGroups, function(experimentGroup) {
                            if (experimentGroup.controlGroup) {
                                _isControlGroupSet = true;
                            }
                        });

                        if (!_isControlGroupSet) {
                            experimentViewPojo.experimentGroups[0].controlGroup = true;
                        }
                        experimentPromise.resolve(experimentViewPojo);
                    }
                });

                return experimentPromise.promise;
            },


            /**
		 * Fetches the pojos that we'll use in listing the possible experiments when adding a rule to a tag (wizard and edit)
		 */
            getExperimentConditionPojos: function() {
                return Restangular.one('tagmanagement/experiments').all('conditionPojos').getList();
            },

            /**
		 * Retrieves a default instance of an experiment group
		 * @returns {ExperimentGroup}
		 */
            getNewDefaultExperimentGroup: function(){
                return {
                    name : '',
                    weight : null
                };
            },

            /**
		 * Retrieves a default instance of an experiment with two ExperimentGroups
		 * @returns {Experiment}
		 */
            getDefaultExperiment: function() {
                return {
                    name: null,
                    notifyEmail: null,
                    significance: 0.95,
                    experimentGroups: [
                        {
                            name : 'Control Group',
                            weight : 1,
                            controlGroup: true
                        },{
                            name : 'Experiment Group',
                            weight : 1,
                            controlGroup: false
                        }
                    ]
                };
            }
        };
        Utils.wrapModifierFunctions(service, function(){
            PubSubService.publishEnvironmentChange();
        });

        return service;
    }]);
