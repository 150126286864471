import { changeUrl } from '~/common';
import * as types from './types';
import * as selectors from './selectors';
import BasecriptService from './dataService';

export const setBasescriptsFilter = query => dispatch => {
  dispatch({
    type: types.SET_BASESCRIPTS_FILTER,
    payload: query,
  });
};

export const removeBasescriptsFilter = () => dispatch => {
  dispatch({
    type: types.REMOVE_BASESCRIPTS_FILTER,
  });
};

export const setSitesFilter = query => dispatch => {
  dispatch({
    type: types.SET_SITES_FILTER,
    payload: query,
  });
};

export const removeSitesFilter = () => dispatch => {
  dispatch({
    type: types.REMOVE_SITES_FILTER,
  });
};

export const setLinkSitesToBasescriptFilter = query => dispatch => {
  dispatch({
    type: types.SET_LINK_SITES_TO_BASESCRIPT_FILTER,
    payload: query,
  });
};

export const removeLinkSitesToBasescriptFilter = () => dispatch => {
  dispatch({
    type: types.REMOVE_LINK_SITES_TO_BASESCRIPT_FILTER,
  });
};

/**
 * Fetch basescripts
 */
export const fetchBasescripts = () => dispatch => {
  dispatch({
    type: types.BASESCRIPTS_FETCH_START,
  });

  return BasecriptService.getBasescripts()
    .then(basescripts => {
      dispatch({
        type: types.BASESCRIPTS_FETCH_SUCCESS,
        payload: basescripts,
      });
    })
    .catch(error => dispatch({ type: types.BASESCRIPTS_FETCH_FAIL, error }));
};

/**
 * Fetch the basecripts only if they are not in the store
 */
export const fetchBasescriptsIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchBasescripts(getState())) {
    return dispatch(fetchBasescripts());
  }
  return Promise.resolve();
};

const moduleSort = (module1, module2) => {
  const module1Label = module1.module.label;
  const module2Label = module2.module.label;
  if (module1Label < module2Label) {
    return -1;
  }
  if (module1Label > module2Label) {
    return 1;
  }
  return 0;
};

/**
 * Fetch basescript modules
 */
export const fetchBasescriptModules = () => dispatch => {
  dispatch({
    type: types.BASESCRIPT_MODULES_FETCH_START,
  });

  return BasecriptService.getBasescriptModules()
    .then(basescriptModules => {
      basescriptModules.sort(moduleSort);
      dispatch({
        type: types.BASESCRIPT_MODULES_FETCH_SUCCESS,
        payload: basescriptModules,
      });
    })
    .catch(error => dispatch({ type: types.BASESCRIPT_MODULES_FETCH_FAIL, error }));
};

/**
 * Fetch the basecript modules only if they are not in the store
 */
export const fetchBasescriptModulesIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchBasescriptModules(getState())) {
    return dispatch(fetchBasescriptModules());
  }
  return Promise.resolve();
};

/**
 * Create or Edit basescript with the passed values
 * @param values
 */
export const saveBasescript = values => dispatch => {
  const ApiCall = values.basescriptId ? BasecriptService.editBasescript : BasecriptService.createBasescript;

  dispatch({
    type: types.SAVE_START,
  });

  return ApiCall(values)
    .then(updatedOrNewBasescript => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: updatedOrNewBasescript,
      });

      // Go to the list view
      changeUrl('context/basescripts/list');
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Reset the state of sites marked to use the basescript
 * @param basescriptId
 */
export const resetBasescriptUsage = basescriptId => ({
  type: types.RESET_BASESCRIPT_USAGE,
  payload: {
    basescriptId,
  },
});

/**
 * Mark/UnMark the site to use the basescript
 * @param siteId
 * @param basescriptId
 */
export const toggleBasescriptUsageInSite = (siteId, basescriptId) => ({
  type: types.TOGGLE_BASESCRIPT_USAGE_IN_SITE,
  payload: {
    siteId,
    basescriptId,
  },
});

/**
 * Use basescript in sites
 */
export const useBasescriptInSites = basescriptId => (dispatch, getState) => {
  const siteIds = selectors.getSitesSelectedToUseBasescript(getState(), basescriptId);

  if (siteIds.length === 0) {
    return Promise.resolve();
  }

  dispatch({
    type: types.USE_BASESCRIPT_IN_SITES_START,
  });

  return BasecriptService.linkBasescriptToSites(basescriptId, siteIds)
    .then(linkedSiteIds => {
      dispatch({
        type: types.USE_BASESCRIPT_IN_SITES_SUCCESS,
        payload: {
          basescriptId,
          siteIds: linkedSiteIds,
        },
      });
    })
    .catch(error => dispatch({ type: types.USE_BASESCRIPT_IN_SITES_FAIL, error }));
};
