angular.module('webUi.ui.tagmanagement.tags.view.placeholderbuttons.placeholderdialog.propertydialog', [])

    .controller('PropertyDialogController', ['$scope', '$stateParams', 'TagmanagementService',
        function PropertyDialogController($scope, $stateParams, TagmanagementService){
            //ugly tricks to circumvent typehead's impossibility to deal with promises
            $scope.names = [];
	
            if ( _.isUndefined($stateParams.location) && !_.isUndefined($stateParams.id)){
                TagmanagementService.getTag($stateParams.id).then(function(tag){
                    TagmanagementService.getTagProperties(tag.tag.location.path).then(function(names){
                        $scope.names = names;
                    });
                });
            } else {
                TagmanagementService.getTagProperties($stateParams.location).then(function(names){
                    $scope.names = names;
                });
            }
	
        }]);

