angular.module('webUi.filter.paging', [])

    .filter('paging', function() {
        return function(input, paging) {

            if(_.isUndefined(input) || _.isNull(input) || _.isNaN(input)) {
                return [];
            }
            // TODO: remove this after paging and paginginfo directive is replaced with paging component
            paging.size = paging.size || paging.perPage;
            // default to page 0
            var page = (typeof paging.page !== 'undefined' ? paging.page : 0);
            // Show all if the number of inputs doesn't exceed the max page size
            if (input.length <= paging.size) {
                return input;
            }
            // calculate start
            var start = (input.length <= page * paging.size) ? 0 : page * paging.size;
            // return slice
            return input.slice(start, start + paging.size);
        };
    });
