import React from 'react';
import moment, { Moment } from 'moment';
import i18n from '~/i18n';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import DateTimeElement from '~/components/src/Form/Elements/DateTimeElement';
import { TElement } from '../types';
import './styles.scss';

const DATE_ON_FORMAT = 'YYYY-MM-DD';

export const DateOnInput = ({ onChange, onBlur, errorText, value, validateForm }: TElement) => {
  const dateValue = moment(value, DATE_ON_FORMAT, true).isValid() ? moment(value) : value;

  const handleOnchange = (value: string | Moment) => {
    if (typeof value === 'string') {
      onChange(value);
    } else {
      const formatString = moment(value).format(DATE_ON_FORMAT);
      onChange(formatString);
    }

    setTimeout(() => {
      validateForm();
    }, 200);
  };

  return (
    <div className="DateFormat-Container">
      <FieldWrapper errorText={errorText}>
        <DateTimeElement
          className="w-full"
          name="dateValue"
          dateFormat={DATE_ON_FORMAT}
          timeFormat={false}
          value={dateValue}
          onChange={handleOnchange}
          hasError={!!errorText}
          onBlur={onBlur}
          placeholder={i18n.t('common:placeholders.dateFormat')}
        />
      </FieldWrapper>
    </div>
  );
};

export default DateOnInput;
