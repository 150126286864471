import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import Empty from '~/components/src/Empty';
import { translate } from 'react-i18next';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell } = components;
const { withEmpty, withSort, withPaginate, withFilter } = enhancements;

const getTotalUsagePerSite = item =>
  item.tagCalls +
  item.adImpressions +
  item.apiCalls +
  item.tdnDataCalls +
  item.pageviews +
  item.bannerviews +
  item.engagements +
  item.conversions +
  item.externalfacts +
  item.otherEvents;

const EmptyList = ({ t }) => <Empty header={t('statistics.empty.header')} body={t('statistics.empty.body')} />;

const NoResults = ({ t }) => <Empty header={t('statistics.noResults.header')} body={t('statistics.noResults.body')} />;

/* eslint-disable camelcase */
const WIDTHS = {
  NAME: '12%',
  SITE_NUMBER: '8%',
  STAT_VAlUE: '8%',
};
/* eslint-enable camelcase */

const props = (sortKey, title, sortFn) => ({
  sortKey,
  title,
  sortFn,
});

export const SitesUsageStatistics = ({ list, totalUsage, stateKey, t }) => (
  <Panel>
    <Table stateKey={stateKey} isTable={true}>
      <colgroup>
        <col style={{ width: WIDTHS.SITE_NUMBER }} />
        <col style={{ width: WIDTHS.NAME }} />
        <col span="10" style={{ width: WIDTHS.STAT_VAlUE }} />
      </colgroup>
      <thead>
        <Row>
          <SortableHeader {...props('siteNumber', t('statistics.siteNumber'))} sortFn={numberSort('siteNumber')} />
          <SortableHeader {...props('siteName', t('statistics.siteName'))} />
          <SortableHeader {...props('tagCalls', t('statistics.tagCalls'))} sortFn={numberSort('tagCalls')} />
          <SortableHeader
            {...props('adImpressions', t('statistics.adImpressions'))}
            sortFn={numberSort('adImpressions')}
          />
          <SortableHeader {...props('apiCalls', t('statistics.apiCalls'))} sortFn={numberSort('apiCalls')} />
          <SortableHeader {...props('tdnCalls', t('statistics.tdnCalls'))} sortFn={numberSort('tdnDataCalls')} />
          <SortableHeader {...props('pageViews', t('statistics.pageViews'))} sortFn={numberSort('pageviews')} />
          <SortableHeader {...props('bannerViews', t('statistics.bannerViews'))} sortFn={numberSort('bannerviews')} />
          <SortableHeader {...props('engagements', t('statistics.engagements'))} sortFn={numberSort('engagements')} />
          <SortableHeader {...props('conversions', t('statistics.conversions'))} sortFn={numberSort('conversions')} />
          <SortableHeader
            {...props('externalFacts', t('statistics.externalFacts'))}
            sortFn={numberSort('externalfacts')}
          />
          <SortableHeader {...props('otherEvents', t('statistics.otherEvents'))} sortFn={numberSort('otherEvents')} />
          <SortableHeader
            {...props('totalUsagePerSite', t('statistics.totalUsagePerSite'))}
            sortFn={numberSort('totalUsagePerSite')}
          />
        </Row>
      </thead>
      <tbody>
        {list.map(item => (
          <Row key={item.siteNumber}>
            <Cell>{item.siteNumber}</Cell>
            <Cell>{item.siteName}</Cell>
            <Cell>{item.tagCalls}</Cell>
            <Cell>{item.adImpressions}</Cell>
            <Cell>{item.apiCalls}</Cell>
            <Cell>{item.tdnDataCalls}</Cell>
            <Cell>{item.pageviews}</Cell>
            <Cell>{item.bannerviews}</Cell>
            <Cell>{item.engagements}</Cell>
            <Cell>{item.conversions}</Cell>
            <Cell>{item.externalfacts}</Cell>
            <Cell>{item.otherEvents}</Cell>
            <Cell>{getTotalUsagePerSite(item)}</Cell>
          </Row>
        ))}
        {!totalUsage ? null : (
          <Row>
            <Cell colSpan={2}>{t('statistics.total')}</Cell>
            <Cell>{totalUsage.tagCalls}</Cell>
            <Cell>{totalUsage.adImpressions}</Cell>
            <Cell>{totalUsage.apiCalls}</Cell>
            <Cell>{totalUsage.tdnDataCalls}</Cell>
            <Cell>{totalUsage.pageviews}</Cell>
            <Cell>{totalUsage.bannerviews}</Cell>
            <Cell>{totalUsage.engagements}</Cell>
            <Cell>{totalUsage.conversions}</Cell>
            <Cell>{totalUsage.externalfacts}</Cell>
            <Cell>{totalUsage.otherEvents}</Cell>
            <Cell>{getTotalUsagePerSite(totalUsage)}</Cell>
          </Row>
        )}
      </tbody>
    </Table>
  </Panel>
);

const siteStatisticsCompose = compose(
  translate('sites'),
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 10 }),
)(SitesUsageStatistics);

siteStatisticsCompose.propTypes = {
  list: PropTypes.array.isRequired,
  totalUsage: PropTypes.object,
  stateKey: PropTypes.string.isRequired,
};

export default siteStatisticsCompose;
