/**
 * @description Angular module for Content -> External -> View
 * @class Contentcampaigns2ViewController
 * @namespace webUi.ui.content.campaigns2.view
 * @memberOf webUi.ui.content.campaigns2
 */
angular.module('webUi.ui.content.campaigns2.view', [
    'webUi.ui.content.campaigns2.base',
])
    /**
     *
     */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns2.view', {
                url: '/view/{campaignId}',
                isReact: true,
            });
    }]);

