/*
  TODO: Split RuleList into 2 components: RuleListView and RuleListEdit.
  These two use cases are very different and require different props.
  Why do this:
   - For easier understanding. RuleList is a weird abstraction over two components that don't have much in common.
   - ESLint will stop complaining about "code complexity overload"
*/
import React from 'react';
import cx from 'classnames';
import { flattenDepth, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import { twMerge } from 'tailwind-merge';
import RuleCardEdit from '~/common/modules/rules/components/RuleCardEdit';
import RuleCardView from '~/common/modules/rules/components/RuleCardView';
import RulePicker from '~/common/modules/rules/components/RulePicker';
import { RulesTypes } from '~/common/modules/rules/types';
import i18n from '~/i18n';
import Notification from '../Notification';
import { R42AndCondition } from '../R42Icons';
import './styles.scss';

export const RuleCardTypes = {
  ViewCard: 'ViewCard',
  EditCard: 'EditCard',
};

export const RuleOptions = {
  AND: 'AND',
  OR: 'OR',
};

const getLineModifier = (ruleIndex, ruleSetLength) => {
  if (ruleSetLength === 1) {
    return 'onlyOneCard';
  }

  if (ruleIndex === 0) {
    return 'first';
  }

  if (ruleIndex === ruleSetLength - 1) {
    return 'last';
  }

  return 'middle';
};

const ConnectionBadge = ({ condition }) => {
  const classNameModifier = condition === RuleOptions.AND ? 'center' : 'left';
  const testHook = condition === RuleOptions.AND ? 'roundedLabelAnd' : 'roundedLabelOr';
  return (
    <div className={cx('RulesList-badgeContainer', `RulesList-badgeContainer--${classNameModifier}`, `t-${testHook}`)}>
      {condition === RuleOptions.AND && <div className="RulesList-separator" />}
      <div
        className={twMerge(
          cx('z-10 mb-6 flex gap-2 rounded-3xl border border-blue-600 bg-blue-50 p-2 text-sm text-blue-600 ', {
            'mb-0': condition === RuleOptions.AND,
          }),
        )}
      >
        {condition === RuleOptions.AND && <R42AndCondition />}
        {condition}
      </div>
    </div>
  );
};

const CriteriaEmptyNotification = ({ isJoTrigger, isSubmitted }) => (
  <Notification kind={isSubmitted ? 'error' : 'information'} header="">
    <p>
      {isJoTrigger
        ? i18n.t('audiences:edit.triggerNeedsAtLeastOneRule')
        : i18n.t('audiences:edit.audienceNeedsAtLeastOneRule')}
    </p>
  </Notification>
);

const getPositiveRules = criteria =>
  criteria.map(criteriaSet => criteriaSet.filter(rule => !rule.negation)).filter(criteriaSet => criteriaSet.length);

const hasFieldErrors = criteria => {
  const flatRules = flattenDepth(criteria, 2);

  const hasErrors = flatRules.some(rule => !isEmpty(rule.errors));

  return hasErrors;
};

const hasOnlyOverlapRules = criteria => {
  if (!criteria.length) {
    return false;
  }

  return !criteria.flat().some(rule => rule.type !== RulesTypes.JourneyOverlap);
};

const hasNoPositiveRules = criteria => !getPositiveRules(criteria).length;

const checkDateConstraint = (isAudienceRule, criteria) => {
  if (isAudienceRule) {
    return criteria.some(crit =>
      crit.some(
        rule => rule?.filters?.length > 1 && rule?.filters?.some(filter => filter?.constraint?.group?.name === 'DATE'),
      ),
    );
  }
  return false;
};

const checkDuplicateEventWithDateProperties = (isAudienceRule, criteria) => {
  if (isAudienceRule) {
    const flatCriterias = criteria.flat();

    // group criterias by rule type
    const hasSameRules = _.groupBy(flatCriterias, 'type');

    // find duplicate criterias with same rule type and label
    const groups = Object.values(hasSameRules);
    const groupWithduplicates = groups.filter(group => group.length > 1);

    // find if any group has same criterias with date filter
    const hasDuplicate = groupWithduplicates?.some(group => {
      // has date rule configured for same rule type
      // skip negated rules
      const criteriasWithDateFilter = group?.filter(
        rule => !rule?.negation && rule?.filters?.find(filter => filter?.filterValue?.clazz === 'DateFilterValue'),
      );

      if (criteriasWithDateFilter.length > 1) {
        let fis = criteriasWithDateFilter?.map(rule => rule.filters);
        fis = fis?.flat();
        const dateFieldArray = _.groupBy(fis, 'dataField');
        const filterdDatFields = Object.keys(dateFieldArray).filter(dataField => dateFieldArray[dataField]?.length > 1);
        return filterdDatFields.length > 0;
      }

      return false;
    });
    return hasDuplicate;
  }
  return false;
};

export const validateCriteria = (criteria, isAudienceRule) => {
  const hasPropertyErrors =
    checkDateConstraint(isAudienceRule, criteria) || checkDuplicateEventWithDateProperties(isAudienceRule, criteria);
  if (hasNoPositiveRules(criteria) || hasFieldErrors(criteria) || hasOnlyOverlapRules(criteria) || hasPropertyErrors) {
    return false;
  }

  return true;
};

const updateCriteria = (criteria, onChange, isAudienceRule) => (updatedRule, formProps) => {
  const nextCriteria = criteria.map(criteriaSet =>
    criteriaSet.map(rule => {
      if (rule.ruleId === updatedRule.ruleId) {
        return { ...rule, ...updatedRule, errors: formProps.errors };
      }

      return rule;
    }),
  );

  onChange({
    criteria: nextCriteria,
    isValid: validateCriteria(nextCriteria, isAudienceRule),
  });
};

const deleteCriteria = (removedRule, criteria, onChange, isAudienceRule) => {
  const nextCriteria = criteria.reduce((acc, criteriaSet) => {
    const filteredCriteriaSet = criteriaSet.filter(rule => rule.ruleId !== removedRule.ruleId);

    if (filteredCriteriaSet.length) {
      acc.push(filteredCriteriaSet);
    }
    return acc;
  }, []);
  onChange({
    criteria: nextCriteria,
    isValid: validateCriteria(nextCriteria, isAudienceRule),
  });
};

const flattenRuleTypesTree = ruleTypesTree =>
  ruleTypesTree.reduce((ruleTypes, ruleTypeOrGroup) => {
    const isGroup = Array.isArray(ruleTypeOrGroup.subMenu);

    if (isGroup) {
      return [...ruleTypes, ...ruleTypeOrGroup.subMenu];
    }

    return [...ruleTypes, ruleTypeOrGroup];
  }, []);

export const getPropertyTypesForRule = (ruleTypesTree, ruleDefinitionId) => {
  const allRuleTypes = flattenRuleTypesTree(ruleTypesTree);

  const ruleType = allRuleTypes.find(ruleType => ruleType.ruleDefinitionId === ruleDefinitionId) || {};

  const propertyTypesForRule = ruleType.availableFilters;

  if (propertyTypesForRule) {
    return Immutable.asMutable(propertyTypesForRule, { deep: true });
  }

  return [];
};

const containsOverlapRule = ruleSet => ruleSet[0].type === RulesTypes.JourneyOverlap;

const RulesList = ({
  criteria = [],
  ruleTypesTree = undefined,
  ruleCardType,
  onChange = undefined,
  addCriteria = undefined,
  isSubmitted = undefined,
  fetchAllVariables = undefined,
  className,
  isJourneyCondition,
  isJoTrigger = false,
  isUsedInJourneys = undefined,
  type = undefined,
  bindFormikForm = undefined,
  enableGroupBy = false,
  defaultWaitTime = undefined,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isAudienceRule = type === 'audience';

  const hasAudienceRuleErrors = checkDateConstraint(isAudienceRule, criteria);

  return (
    <>
      {hasAudienceRuleErrors && ruleCardType === RuleCardTypes.ViewCard && (
        <Notification kind="error">{i18n.t('audiences:rulesNotValid')}</Notification>
      )}
      <div className={cx('RulesList', className)}>
        {criteria.map((ruleSet, setIndex) => (
          <React.Fragment key={`criteria-container-${setIndex}`}>
            <div className="RulesList-OR">
              {/* Render individual rules card */}
              {ruleSet.map((rule, ruleIndex) => {
                if (!rule) {
                  return (
                    <Notification header="" kind="error">
                      <p>Unsupported rule</p>
                    </Notification>
                  );
                }
                const modifier = getLineModifier(ruleIndex, ruleSet.length);
                if (ruleCardType === RuleCardTypes.EditCard) {
                  const propertyTypes = getPropertyTypesForRule(ruleTypesTree, rule.ruleDefinitionId);
                  return (
                    <React.Fragment key={`rule-${rule.ruleId}`}>
                      <div className="RuleCardEdit-container">
                        <div className={cx('RuleCardEdit-line', `RuleCardEdit-line--${modifier}`)}>
                          <div className="RuleCardEdit-lineTop"></div>
                          <div className="RuleCardEdit-lineBottom"></div>
                        </div>
                        <RuleCardEdit
                          key={`rule-${rule.ruleId}`}
                          id={rule.ruleId}
                          type={rule.type}
                          title={rule.title}
                          typeLabel={rule.typeLabel}
                          timeCondition={rule.timeCondition}
                          waitTime={rule.waitTime}
                          isInversed={rule.negation}
                          filters={rule.filters}
                          propertyTypes={propertyTypes}
                          testHook={rule.ruleId}
                          onChange={updateCriteria(criteria, onChange, isAudienceRule)}
                          onDelete={() => deleteCriteria(rule, criteria, onChange, isAudienceRule)}
                          availableVariables={rule.availableVariables}
                          isSubmitted={isSubmitted}
                          fetchAllVariables={fetchAllVariables}
                          isJourneyCondition={isJourneyCondition}
                          isJoTrigger={isJoTrigger}
                          isAudienceRule={isAudienceRule}
                          criteria={criteria}
                          bindFormikForm={bindFormikForm}
                        />
                      </div>
                      {ruleIndex + 1 < ruleSet.length && (
                        <div className="RuleCardEdit-container">
                          <div className="RuleCardEdit-line">
                            <div className="RuleCardEdit-ORBlock"></div>
                          </div>
                          <ConnectionBadge condition={RuleOptions.OR} />
                        </div>
                      )}
                    </React.Fragment>
                  );
                }
                if (ruleCardType === RuleCardTypes.ViewCard) {
                  return (
                    <React.Fragment key={`rule-${rule.ruleId}`}>
                      <div className="RuleCardEdit-container">
                        <div className={cx('RuleCardEdit-line', `RuleCardEdit-line--${modifier}`)}>
                          <div className="RuleCardEdit-lineTop"></div>
                          <div className="RuleCardEdit-lineBottom"></div>
                        </div>
                        <RuleCardView
                          type={rule.type}
                          typeLabel={rule.typeLabel}
                          title={rule.title}
                          ruleDefinitionId={rule.ruleDefinitionId}
                          timeCondition={rule.timeCondition}
                          waitTime={rule.waitTime}
                          isInversed={rule.negation}
                          properties={rule.properties}
                          testHook={rule.ruleId}
                          visibleColumns={['property', 'constraint', 'filter', 'unique'].concat(
                            isJourneyCondition ? [] : ['storedVariable'],
                          )}
                          isAudienceRule={isAudienceRule}
                          criteria={criteria}
                          filters={rule.filters}
                        />
                      </div>
                      {ruleIndex + 1 < ruleSet.length && (
                        <div className="RuleCardEdit-container">
                          <div className="RuleCardEdit-line">
                            <div className="RuleCardEdit-ORBlock"></div>
                          </div>
                          <ConnectionBadge condition={RuleOptions.OR} />
                        </div>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </div>
            {/* End of the individual rule card */}
            {setIndex + 1 < criteria.length && (
              <div className="RuleCardEdit-container">
                <div className={cx('RuleCardEdit-line', 'RuleCardEdit-line--onlyOneCard')}>
                  <div className="RuleCardEdit-AndBlock"></div>
                </div>
                <div className="RuleCardEdit-picker">
                  {ruleCardType === RuleCardTypes.EditCard && !containsOverlapRule(ruleSet) && (
                    <RulePicker
                      onSelect={clickedRuleDescription =>
                        addCriteria(
                          defaultWaitTime,
                          onChange,
                          criteria,
                          isAudienceRule,
                          RuleOptions.OR,
                          clickedRuleDescription,
                          setIndex,
                        )
                      }
                      ruleTypesTree={ruleTypesTree}
                      actionName={i18n.t('audiences:rules.add.orRule')}
                      actionType={RuleOptions.OR}
                      isUsedInJourneys={isUsedInJourneys}
                      enableGroupBy={enableGroupBy}
                    />
                  )}
                  <ConnectionBadge condition={RuleOptions.AND} />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {!!criteria.length && !getPositiveRules(criteria).length && (
        <Notification header="" kind="error">
          <p>{i18n.t('audiences:edit.allRulesCantNegate')}</p>
        </Notification>
      )}
      {!criteria.length && <CriteriaEmptyNotification isJoTrigger={isJoTrigger} isSubmitted={isSubmitted} />}
      {hasOnlyOverlapRules(criteria) && (
        <Notification
          header=""
          kind={isSubmitted ? 'error' : 'information'}
          testHook="overlapMustBeCombinedWithOtherRulesError"
        >
          <p>{i18n.t('audiences:edit.overlapMustBeCombinedWithOtherRules')}</p>
        </Notification>
      )}
    </>
  );
};

RulesList.propTypes = {
  criteria: PropTypes.array,
  RuleCardType: PropTypes.string, // otherwise fails on components with React.forwardRef
};

export default RulesList;
