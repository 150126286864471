import moment from 'moment';
import { DashboardStateType, Action } from './types';

const actionTypes = {
  SET_DATE_RANGE: 'SET_DATE_RANGE',
};

const startDate = moment().subtract(6, 'days').startOf('day');
const endDate = moment().endOf('day');

const initialState = {
  startDate,
  endDate,
  rangeFrom: startDate.toDate().getTime(),
  rangeTo: endDate.toDate().getTime(),
};

function reducer(state: DashboardStateType, action: Action): DashboardStateType {
  switch (action.type) {
    case actionTypes.SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate?.startOf('day'),
        endDate: action.endDate?.endOf('day'),
        rangeFrom: action.startDate?.startOf('day').toDate().getTime(),
        rangeTo: action.endDate?.endOf('day').toDate().getTime(),
      };

    default:
      return state;
  }
}

export { actionTypes, initialState, reducer };
