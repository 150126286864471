angular.module('webUi.service.spinner', [
    'webUi.common.Utils',
    'ui.bootstrap',
    'webUi.ui.shared.spinner'
])

    .factory('SpinnerService', ['$q', 'Utils', '$uibModal',
        function(  $q,   Utils,   $uibModal){

            var defaultConfig = {
                title: 'Busy Processing',
                message: 'The server is currently processing your request, please wait for it to finish.'
            };

            var service = {

                /**
		 * Returns a spinnerWrapper which you can close / show status
		 *
		 * @returns a spinnerWrapper which you can close / show status
		 */
                create: function(config) {
                    var dialog = $uibModal.open({
                        backdrop: 'static',
                        keyboard: false,
                        templateUrl: 'ui/shared/spinner/spinner.tpl.html',
                        controller: 'SpinnerDisplayController',
                        resolve: {
                            spinnerConfig: function() {
                                return _.extend(defaultConfig, config);
                            }
                        }
                    });

                    var spinnerWrapper = function(dialoqRef) {

                        return {
                            close: function() {
                                dialoqRef.close();
                            }
                        };
                    };

                    return spinnerWrapper(dialog);
                }
            };

            return service;
        }]);
