import moment from 'moment';
import i18n from '~/i18n';
import { string } from 'yup';

// Accept only characters for date (Y, M, D) and time (h, m, s) and separators (,.:;/- and space)
// combined regex for date & time, but has an issue because it accepts time characters with date characters and `d` is not the right date character but BE uses a lib which accepts `d` for Date.
const regex = /[^ ymMdhHsSx/,.:-]/;

export const getFormat = (format: string): any => {
  const now = moment();
  const userFormat = format?.replace(/[y]/g, 'Y').replace(/[d]/g, 'D');
  const formattedString = now.format(userFormat);
  return { userFormat, formattedString, now };
};

export const isValueMatching = (value?: string): boolean => {
  if (!value) {
    return false;
  }

  // Back End does not handle conjuctions of x with anything else. So 'x' can be the only value.
  if (value.includes('x') && value.length > 1) {
    return false;
  }
  // If the dateformat contains characters that are not allowed, consider it to be invalid
  if (regex.test(value)) {
    return false;
  }

  const { userFormat, formattedString, now } = getFormat(value);
  // Reconstruct the date using the specified format. The new date should be the same as now
  const dateToCheck = moment(formattedString, userFormat, true);

  // The constructed new date should have the same date, month, year, etc
  const valid = dateToCheck.isValid() && now.startOf('day').diff(dateToCheck.startOf('day')) === 0;

  if (!valid) {
    return false;
  }

  return true;
};

export const dateValidator = string()
  .trim()
  .required(i18n.t('validation:validation.required'))
  .test('date-format', i18n.t('forms:dateFormat.validations.wrongFormat'), value => isValueMatching(value || ''));
