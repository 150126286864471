import React from 'react';
import Dropzone from 'react-dropzone';
import cx from 'classnames';

import '~/components/src/Form/Elements/elements.scss';

const DropzoneElement = ({
  children,
  className,
  hasError,
  onDropAccepted,
  onDropRejected,
  accept,
  testHook,
  multiple,
  disabled,
}) => (
  <Dropzone
    className={cx('DropzoneElement', { 'DropzoneElement--hasError': hasError, [`t-${testHook}`]: testHook }, className)}
    accept={accept}
    onDropAccepted={onDropAccepted}
    onDropRejected={onDropRejected}
    multiple={multiple}
    disabled={disabled}
  >
    {children}
  </Dropzone>
);

export default DropzoneElement;
