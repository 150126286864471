angular.module('webUi.directive.paginginfo', [])
	
    .directive('paginginfo', ['$compile', 'ReflectionService', function ($compile, ReflectionService) {
        var directiveDefinitionObject = {
		
            restrict : 'A',
            scope : {
                paginginfo : '='
            },
            templateUrl : 'directive/paginginfo/paginginfo.tpl.html',
            replace : true,
            link: function (scope, element, attrs) {
			
                // init paging object if undefined and share with parent scope
                if(typeof scope.paginginfo === 'undefined') {
                    scope.paginginfo = ReflectionService.setNestedProperty(scope.$parent, attrs.paginginfo, {});
                }
			
                // default page nr to 0
                if(typeof scope.paginginfo.page === 'undefined') {
                    scope.paginginfo.page = 0;
                }
			
                var updateShownEntries = function() {
                    if(scope.nrOfEntries === 0) {
                        scope.firstEntry = 0;
                        scope.lastEntry = 0;
                    } else {
                        scope.firstEntry = (activePage * perPage) + 1;
                        scope.lastEntry = ((activePage + 1) * perPage);
                    }
                };
			
                /**
			 * Sets the active page and the current page to 0. 
			 */
                var resetActiveAndCurrentPage = function() {
                    activePage = 0;
                    scope.paginginfo.page = 0;
                };
			
                var perPage = 0;
                var activePage = 0;
			
                // watch data to keep count of nr of records
                scope.$parent.$watch(attrs.paginginfoData, function(newValue, oldValue) {
                    if(typeof newValue !== 'undefined') {
                        // if data is number, assume it is the number of records
                        if(_.isNumber(newValue)) {
                            scope.nrOfEntries = newValue;
                            // In case of filtering show results from the 1st page (0)
                            if (newValue !== oldValue) {
                                resetActiveAndCurrentPage();
                            }
                        } else {
                            scope.nrOfEntries = newValue.length;
                            // If we are filtering results, always start from the 1st page
                            if (!_.isUndefined(oldValue) && (newValue.length !== oldValue.length)) {
                                resetActiveAndCurrentPage();
                            }
                        }
					
					
                        updateShownEntries();
                    }
                });
			
                // watch paginginfo
                scope.$parent.$watch(attrs.paginginfo, function(newValue) {
                    if(typeof newValue !== 'undefined') {
                        activePage = newValue.page;
                        perPage = newValue.perPage;
                        updateShownEntries();					
                    }
                }, true);
			
                // helper functions
                scope.min = function(a, b) {
                    return Math.min(a, b);
                };
            }
        };
	
        return directiveDefinitionObject;
    }]);
