import React, { ReactElement } from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import { Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';
import icons from '../Icons/icons';

type LinkIconType = {
  className?: string;
  icon: keyof typeof icons;
  testHook?: string;
  color?: string;
  url: string;
  tooltip?: string;
  placement?: Placement;
  disabled?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const COLORS: Record<string, string> = {
  gray: 'text-gray-600 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-400 bg-gray-200 dark:bg-gray-700',
  blue: 'text-white hover:text-white bg-blue-600 dark:bg-blue-700',
};

const COLOR_STATES: Record<string, string> = {
  gray: `${COLORS.gray} hover:bg-gray-300 dark:hover:bg-gray-600 focus:ring-gray-300  dark:focus:ring-gray-500`,
  blue: `${COLORS.blue} hover:bg-blue-700 dark:hover:bg-blue-600 focus:ring-blue-300  dark:focus:ring-blue-500`,
};

function LinkIcon({
  className,
  icon,
  url,
  color = 'gray',
  disabled = false,
  tooltip,
  placement = 'bottom',
  testHook,
  target,
  ...props
}: LinkIconType): ReactElement {
  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement}>
      <div className={cx('inline-block', { 'cursor-not-allowed': disabled })}>
        <a
          href={url}
          target={target}
          className={cx(
            'relative flex h-8 w-8 cursor-pointer items-center justify-center rounded border-none p-1 no-underline',
            COLORS[color],
            className,
            disabled
              ? 'pointer-events-none cursor-not-allowed opacity-50'
              : `focus:outline-none focus:ring-4 ${COLOR_STATES[color]}`,
            `t-${testHook}`,
          )}
          {...props}
        >
          <Icons icon={icon} />
        </a>
      </div>
    </Tippy>
  );
}

export default LinkIcon;
