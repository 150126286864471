import { createContext } from 'react';
import { TWorkflow } from '~/workflows/types';

type ContextType = {
  journeyData: TWorkflow | null;
  journeyTree: any | null;
  actions: any;
  hasExperiments: boolean;
  experimentSteps: any[];
  showJourneyErrors: boolean;
  refreshJourney: () => void;
};

const defaultValues = {
  journeyData: null,
  journeyTree: null,
  actions: null,
  hasExperiments: false,
  experimentSteps: [],
  showJourneyErrors: false,
  refreshJourney: () => {
    // Empty
  },
};

export const JourneyContext = createContext<ContextType>(defaultValues);
