import Api, { API_BASE, parseResponse } from '~/common/ApiService';

// so looks like MailChimp api don't allow you to geet all lists, and their use pagination
// we don't use pagination in our select list, and probably don't want to use
// let's try to get 1000 audience, and hope that will be enough
const getMailChimpLists = partnerId =>
  Api.callGet(`${API_BASE}/v1/partners/mailchimp/${partnerId}/lists?count=1000&offset=0`, {
    shouldShowToast: false,
  }).then(parseResponse);

export { getMailChimpLists };
