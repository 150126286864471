import React, { ReactElement, useState } from 'react';
import i18n from '~/i18n';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import Alert, { AlertType } from '~/components/src/Alert';
import moment from 'moment';
import Badge from '~/components/src/Badge';
import { ErrorPropertyProps, ErrorType, MappingType } from './types';

function EmptyList(): ReactElement {
  return (
    <Alert
      suffix={i18n.t('connectors:errorStats.emptySuffix')}
      prefix={i18n.t('connectors:errorStats.emptyPrefix')}
      testHook="emptyMessage"
      type={AlertType.DEFAULT}
    />
  );
}

function MappingErrorProperty({ mKey, mValue, mValueClass }: ErrorPropertyProps): ReactElement {
  return (
    <span className={`text-sm text-slate-500 dark:text-slate-400 t-${mKey}`}>
      {i18n.t(`connectors:errorStats.${mKey}`)}
      <span className={cx('ml-2 font-medium text-slate-600 dark:text-slate-300', mValueClass, `t-${mValue}`)}>
        {mValue}
      </span>
    </span>
  );
}

function ErrorsList({ data }: { data: ErrorType[] }): ReactElement {
  const [selectionId, setSelectionId] = useState('');
  if (data.length === 0) {
    return <EmptyList />;
  }
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {data.map((tError: ErrorType, i: number) => (
        <div key={`accordion-collapse-${i}`} className="t-errorItem">
          <h2 id={`accordion-collapse-heading-${i}`}>
            <button
              type="button"
              className={cx(
                't-errorExpandButton flex w-full items-center justify-between border border-gray-200 p-5 text-left font-medium text-gray-500 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800',
                {
                  'border-b-0': i !== data.length - 1,
                  'rounded-t-xl': i === 0,
                  'rounded-b-xl': i === data.length - 1,
                  'rounded-b-none bg-slate-100 dark:bg-gray-900': selectionId === `accordion-collapse-body-${i}`,
                },
              )}
              data-accordion-target={`#accordion-collapse-body-${i}`}
              aria-expanded="true"
              aria-controls={`accordion-collapse-body-${i}`}
              onClick={() =>
                setSelectionId(selectionId === `accordion-collapse-body-${i}` ? '' : `accordion-collapse-body-${i}`)
              }
            >
              <span className="flex flex-col gap-2 md:flex-row md:gap-4">
                <MappingErrorProperty
                  mValueClass="ml-2 text-app-blue-700 dark:text-app-blue-700"
                  mKey="eventType"
                  mValue={tError.eventType}
                />
                <MappingErrorProperty
                  mValueClass="ml-2 text-green-600 dark:text-green-600"
                  mKey="eventName"
                  mValue={tError.eventName}
                />
              </span>

              <div>
                <Badge className="mr-2" content={tError?.mappings?.length || 0} color="red" testHook="errorCount" />
                <Icons
                  icon="downArrow"
                  className={cx('h-6 w-6', { 'rotate-180': selectionId === `accordion-collapse-body-${i}` })}
                />
              </div>
            </button>
          </h2>
          <div
            id={`accordion-collapse-body-${i}`}
            className={cx({ hid: selectionId !== `accordion-collapse-body-${i}` })}
            aria-labelledby="accordion-collapse-heading-1"
          >
            <div className="border border-b-0 border-gray-200 px-5 dark:border-gray-700 dark:bg-gray-900">
              {tError.mappings.map((mError: MappingType, j: number) => (
                <div
                  key={`accordion-collapse-mapping-${j}`}
                  id={`accordion-collapse-mapping-${i}${j}`}
                  className="t-subError flex flex-col-reverse border-b border-gray-200 py-2 dark:border-gray-700 md:flex-row md:justify-between md:gap-4"
                >
                  <div className="grid grid-cols-1 gap-2">
                    <MappingErrorProperty
                      mValueClass="text-app-blue-700 dark:text-app-blue-700"
                      mKey="sourceProperty"
                      mValue={mError.sourceProperty}
                    />
                    <MappingErrorProperty
                      mValueClass="text-app-blue-700 dark:text-app-blue-700"
                      mKey="destinationProperty"
                      mValue={mError.destinationProperty}
                    />
                    <MappingErrorProperty mKey="sourceValue" mValue={mError.sourceValue} />
                    <MappingErrorProperty mKey="transformationType" mValue={mError.transformationType} />
                  </div>
                  <div className="mb-2 border-b border-dashed border-gray-100 pb-2 dark:border-gray-800 md:mb-0 md:w-2/5 md:border-b-0 md:pb-0">
                    <p className="flex justify-between text-sm text-slate-500">
                      <span>{i18n.t('connectors:errorStats.errorMessage')}</span>
                      <span className="text-slate-400">{moment(mError.timestamp).fromNow()}</span>
                    </p>
                    <p className="mt-1 text-red-400">{mError.error.message}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ErrorsList;
