import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { isSameDay, DateRangePicker as Picker } from 'react-dates';
import i18n from '~/i18n';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import BtnOutlined from '../BtnOutlined';
import './styles.scss';

const presets = [
  {
    text: i18n.t('dashboard:rangePicker.today'),
    start: moment().startOf('day'),
    end: moment().endOf('day'),
    testHook: 'today',
  },
  {
    text: i18n.t('dashboard:rangePicker.last7days'),
    start: moment().subtract(6, 'days').startOf('day'),
    end: moment().endOf('day'),
    testHook: '7days',
  },
  {
    text: i18n.t('dashboard:rangePicker.last30days'),
    start: moment().subtract(29, 'days').startOf('day'),
    end: moment().endOf('day'),
    testHook: '30days',
  },
  {
    text: i18n.t('dashboard:rangePicker.last90days'),
    start: moment().subtract(89, 'days').startOf('day'),
    end: moment().endOf('day'),
    testHook: '90days',
  },
];

const DATE_FORMAT = 'DD/MM/YYYY';
const getDaysCount = (startDate, endDate) => Math.round(moment.duration(endDate?.diff(startDate)).asDays()) || 1;
const isValidDateTime = date => !!date && moment(date, DATE_FORMAT, true).isValid();

const DateRangePicker = ({
  testHook = '',
  className = '',
  startDate,
  endDate,
  handleDatesChange,
  showHint = true,
  openDirection = 'down',
  enableScrollDetection = false,
}) => {
  const elementRef = useRef(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [direction, setDirection] = useState(openDirection);

  useEffect(() => {
    const onScroll = () => {
      if (enableScrollDetection && elementRef.current) {
        const element = elementRef.current;
        const viewportHeight = window.innerHeight;
        const elementRect = element.getBoundingClientRect();
        const offset = viewportHeight - elementRect.bottom;

        setDirection(offset < 400 ? 'up' : 'down');
      }
    };
    if (enableScrollDetection) {
      window.addEventListener('scroll', onScroll, true);
      onScroll();
    }

    return () => {
      if (enableScrollDetection) {
        window.removeEventListener('scroll', onScroll, true);
      }
    };
  }, [enableScrollDetection]);

  const DatePresets = () => (
    <div className="grid grid-cols-2 justify-items-stretch gap-4 px-6 pb-4">
      {presets.map(({ text, start, end, testHook }) => {
        const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
        return (
          <BtnOutlined
            className="w-full"
            key={text}
            onClick={() => {
              handleDatesChange({ startDate: start, endDate: end });
              setFocusedInput(null);
            }}
            color={isSelected ? 'blue' : 'gray'}
            disabled={isSelected}
            testHook={testHook}
          >
            {text}
          </BtnOutlined>
        );
      })}
    </div>
  );

  const daysCount = getDaysCount(startDate, endDate);

  const handleChange = ({ startDate, endDate }) => {
    if (isValidDateTime(startDate) && isValidDateTime(endDate)) {
      const adjustedStartDate = (startDate || endDate).startOf('day');
      const adjustedEndDate = (endDate || startDate).endOf('day');

      if (adjustedStartDate && adjustedEndDate) {
        handleDatesChange({ startDate, endDate: endDate || startDate });
      }
    }
  };

  const handleInitialVisibleMonth = () => {
    if (focusedInput === 'endDate' && endDate) {
      return endDate;
    } else if (focusedInput === 'startDate' && startDate) {
      return startDate;
    }
    return moment();
  };

  const startDateId = `START_DATE${testHook ? `_${testHook}` : ''}`;
  const endDateId = `END_DATE${testHook ? `_${testHook}` : ''}`;

  return (
    <div className={cx('relative', className, `t-${testHook}`)} ref={elementRef}>
      <Picker
        className="border-1 peer block w-full appearance-none rounded-lg border-gray-300 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-blue-500"
        displayFormat={DATE_FORMAT}
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={handleChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        renderCalendarInfo={DatePresets}
        initialVisibleMonth={handleInitialVisibleMonth}
        isOutsideRange={day => isAfterDay(day, moment())}
        noBorder
        hideKeyboardShortcutsPanel
        minimumNights={0}
        numberOfMonths={1}
        openDirection={direction}
        small
      />
      {showHint && (
        <label
          htmlFor="default_outlined"
          className="absolute left-1 top-2 z-[1] origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 dark:bg-gray-800 dark:text-gray-400 peer-focus:dark:text-blue-500"
        >
          {daysCount > 1
            ? i18n.t('dashboard:rangePicker.lastndays', { daysCount })
            : i18n.t('dashboard:rangePicker.oneday', { daysCount })}
        </label>
      )}
    </div>
  );
};

export default DateRangePicker;
