import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Btn from '../../Btn';
import BtnIcon from '../../BtnIcon';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPaginate: (page: number) => void;
};

// Formats the pagination array to [1, 2 , 3, 4, ..., 50] based on current page and total pages
const getDisplayPages = (currentPage: number, totalPages: number) => {
  const result = [];
  const initialArray = [1, 2, 3, 4];

  if (totalPages <= 6) {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= totalPages; i++) {
      result.push(i);
    }
  } else if (currentPage <= 3) {
    result.push(...initialArray, '...', totalPages);
  } else if (currentPage >= totalPages - 2) {
    result.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
  } else {
    result.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
  }
  return result;
};

const Pagination = ({ currentPage, totalPages, onPaginate }: PaginationProps) => {
  // Backend usually points to 0 as starting page to handle that scenario.
  const _currPage = currentPage + 1;

  const displayPages = getDisplayPages(_currPage, totalPages);

  return (
    <div className="t-pagination flex items-center justify-center space-x-2 py-4">
      <BtnIcon
        className="!bg-transparent hover:!bg-gray-300"
        icon="cheveronLeft"
        onClick={() => onPaginate(currentPage - 1)}
        disabled={currentPage === 0}
      />
      {displayPages.map((page, index) =>
        typeof page === 'string' ? (
          <span key={index} className="px-3 py-1">
            ...
          </span>
        ) : (
          <Btn
            key={index}
            testHook={`page-${page}`}
            onClick={() => onPaginate(page - 1)}
            color={_currPage === page ? 'blue' : ''}
            className={twMerge(
              cx('!px-3 !py-1.5 hover:!bg-gray-300 focus:!ring-0', { 'hover:!bg-blue-700': _currPage === page }),
            )}
          >
            {page.toString()}
          </Btn>
        ),
      )}
      <BtnIcon
        icon="cheveronRight"
        className="!bg-transparent hover:!bg-gray-300"
        disabled={currentPage === totalPages - 1}
        onClick={() => onPaginate(currentPage + 1)}
      />
    </div>
  );
};

export default Pagination;
