import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import TypesForm from '../components/TypesForm';
import { saveType, fetchTypes } from '../urlBuilder/types/actions';
import { getTypes } from '../urlBuilder/types/selectors';

const initialDefaultValues = {
  keyParameter: 'cpm',
};

export class NewTypePage extends Component {
  componentDidMount() {
    this.props.fetchTypes();
  }
  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('types.formNewType.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('types.formNewType.intro')}</p>
            <TypesForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      initialValues: { ...initialDefaultValues },
      types: getTypes(state),
    }),
    dispatch => ({
      onSubmit: type => dispatch(saveType(type)),
      fetchTypes: () => dispatch(fetchTypes()),
    }),
  ),
  reduxForm({
    form: 'typeNew',
  }),
  translate(['urlBuilder']),
)(NewTypePage);
