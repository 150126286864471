import React, { ReactElement } from 'react';

import cx from 'classnames';
import { FieldArray, FormikErrors } from 'formik';

import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';

import RuleForm from './RuleForm';
import { Criteria, Model, ModelProperty } from '../types';

import './styles.scss';

type CriteriasFormProps = {
  index: number;
  errors: FormikErrors<Model>;
  criterias: Criteria[];
  properties: ModelProperty[];
};

function CriteriasForm({ index, criterias, errors, properties }: CriteriasFormProps): ReactElement {
  const shouldShowTree = (index: number) => criterias?.length > 1 && index !== criterias?.length - 1;
  const shouldShowLastTreeLink = (index: number) => criterias?.length > 1 && index === criterias?.length - 1;

  return (
    <FieldArray
      name={`predictions[${index}].criterias`}
      render={criteriaHelpers => (
        <div className={cx('Criterias', { 'Criterias-multiple': criterias.length > 1 })}>
          {criterias.length > 0 && (
            <div className="Criterias-header">
              <span>{i18n.t('ai:model.predictionColumns.property')}</span>
              <span>{i18n.t('ai:model.predictionColumns.constraint')}</span>
              <span>{i18n.t('ai:model.predictionColumns.filterValue')}</span>
            </div>
          )}
          {criterias?.map((criteria: Criteria, criteriaIndex: number) => (
            <div
              key={criteriaIndex}
              className={cx({
                'Criterias-row': shouldShowTree(criteriaIndex),
                'Criterias-row--last': shouldShowLastTreeLink(criteriaIndex),
              })}
            >
              <RuleForm
                index={index}
                criteriaIndex={criteriaIndex}
                criteriaHelpers={criteriaHelpers}
                rules={criteria.rules}
                errors={errors}
                properties={properties}
              />
            </div>
          ))}
          <BtnOutlined
            size="xs"
            color="blue"
            icon="add"
            testHook="addRule"
            className="mt-4 rounded-full"
            onClick={() => {
              const criteriaItem: Criteria = {
                rules: [
                  {
                    property: '',
                    constraint: '',
                    filterValue: '',
                  },
                ],
              };
              criteriaHelpers.push(criteriaItem);
            }}
          >
            {criterias?.length > 0 ? i18n.t('ai:model.addAnd') : i18n.t('ai:model.addRule')}
          </BtnOutlined>
        </div>
      )}
    />
  );
}

export default CriteriasForm;
