import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Checkbox.scss';

/**
 * This is a fix due to the bug in Redux-form
 * https://github.com/erikras/redux-form/issues/860
 * Usecase: We are stopping the chain event when the user click in cancel
 * meaning validation will not be trigger
 * */
const handleBlur = (event, onBlur = () => {}) => {
  const { relatedTarget } = event;
  if (relatedTarget && relatedTarget.getAttribute('preventvalidation')) {
    event.preventDefault();
  } else {
    onBlur(event);
  }
};

const Checkbox = ({ children, disabled, input, isError, testHook = input.name, ...others }) => {
  const controlled = input.value === undefined ? null : { checked: !!input.value };
  return (
    <label className={cx(`Checkbox t-${testHook} flex items-center`, { 'is-disabled': disabled })}>
      <span className="Checkbox-container">
        <input
          className="Checkbox-input"
          type="checkbox"
          disabled={disabled}
          {...controlled}
          {...input}
          {...others}
          onBlur={event => handleBlur(event, input.onBlur)}
        />
        <span className={cx('Element Element--checkbox Checkbox-element', { 'is-error': isError })} />
        <svg className="Checkbox-svg" viewBox="0 0 52 52">
          <path className="Checkbox-tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </span>
      <span className="Checkbox-label">{children}</span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  isError: PropTypes.bool,
};

export default Checkbox;
