angular
  .module('webUi.ui.tagmanagement.tags.view.content', [
    'webUi.ui.tagmanagement.tags.view.placeholderbuttons',
    'webUi.service.template',
  ])
  .controller('TagViewContentController', [
    '$scope',
    '$stateParams',
    'TagmanagementService',
    'PubSubService',
    'CookiePermissionService',
    'TemplateService',
    '$injector',
    '$uibModal',
    'GoogleAnalyticsService',
    /**
     * @param $scope
     * @param $stateParams
     * @param TagmanagementService
     * @param PubSubService
     * @param CookiePermissionService
     * @param $injector
     * @param $uibModal
     * @param GoogleAnalyticsService
     */
    function TagViewContentController(
      $scope,
      $stateParams,
      TagmanagementService,
      PubSubService,
      CookiePermissionService,
      TemplateService,
      $injector,
      $uibModal,
      GoogleAnalyticsService,
    ) {
      var tagId = $scope.tagViewPojo.tag.tagId;

      //Very expensive watch
      //Nothing to be done but isolate a 'clone' tag object way above (view.js)
      $scope.$watch(
        'tagViewPojo.tag',
        function () {
          $scope.tag = $scope.tagViewPojo.tag;
        },
        true,
      );

      $scope.cmOverrideOptions = {
        readOnly: true,
      };

      var state = ($scope.templateState = { editMode: false });

      $scope.enableTemplateEditing = function ($event, hasTagEdit) {
        if (!hasTagEdit) {
          return;
        }
        PubSubService.publishEditingActivated('tagViewPojo.templateCode');
        state.editMode = true;
        $event.stopPropagation();
      };

      PubSubService.subscribeEditingActivated($scope, function (event, message) {
        if (message.fieldName !== 'tagViewPojo.templateCode') {
          state.editMode = false;
        }
      });

      PubSubService.subscribeEditingCancelled($scope, function () {
        state.editMode = false;
      });

      $scope.descriptionOverrideOptions = {
        editSuccessCallback: function (newValue) {
          return TagmanagementService.updateDescription(tagId, newValue).then(function (modification) {
            $scope.handleModification(modification);
            GoogleAnalyticsService.handleGAEvent('Tag Edited');
          });
        },
      };

      $scope.initTemplateScope = TemplateService.initTemplateScope.bind(this, $injector, $scope);

      $scope.openCodeMirrorPopup = function (hasTagEdit) {
        if (!hasTagEdit) {
          return;
        }
        $uibModal.open({
          windowClass: 'modal-huge',
          backdrop: 'static',
          keyboard: true,
          backdropClick: true,
          templateUrl: 'ui/tagmanagement/tags/view/codemirror-dialog.tpl.html',
          controller: 'CodemirrorDialogController',
          resolve: {
            dialogsModel: function dialogsModel() {
              return {
                tagViewPojo: $scope.tagViewPojo,
                onSave: function onSave(newValue) {
                  TagmanagementService.updateCode(
                    tagId,
                    newValue,
                    $scope.tagViewPojo.tagTemplateVariant.variantId,
                    $scope.tagViewPojo.tagTemplateVariant.sequenceNr,
                  ).then(function (modification) {
                    $scope.tagViewPojo.tag.content.code = newValue;
                    var codemirrorInstance = $('.codemirror-tag-container').data('codemirrorInstance');
                    codemirrorInstance.options.refreshContents(newValue);
                    $scope.handleModification(modification);
                    state.editMode = false;
                    GoogleAnalyticsService.handleGAEvent('Tag Edited');
                  });
                },
                onCancel: function cancel() {
                  state.editMode = false;
                },
              };
            },
          },
        });
      };

      CookiePermissionService.findCookiePermissionSetting($scope.tagViewPojo.tag.content.cookieSettingId).then(
        function (setting) {
          $scope.setting = setting;
        },
      );

      $scope.cookiesEnabledOverrideOptions = {
        editSuccessCallback: function (newValue) {
          return TagmanagementService.updateCookiesEnabled($stateParams.id, newValue);
        },
      };
      $scope.userAgentFilterOptions = {
        editSuccessCallback: function (newValue) {
          return TagmanagementService.updateUserAgentFilter($stateParams.id, newValue);
        },
      };
    },
  ]);
