angular.module('webUi.ui.ai.models.form', ['webUi.ui.ai.models.base'])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.ai.models.form', {
                url: '/new',
                isReact: true
            })
            .state('site.ai.models.edit', {
                url: '/edit/{modelId}',
                isReact: true
            });
    }]);
