import React from 'react';
import cx from 'classnames';
import InputElement from '~/components/src/Form/Elements/InputElement';

import '~/components/src/Form/Elements/elements.scss';
import Icons from '../../Icons';

interface SearchElementProps {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  testHook?: string;
  hasError?: boolean;
  className?: string;
  filledClassName?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  autoFocus?: boolean;
}

const SearchElement = ({
  value,
  disabled,
  placeholder,
  testHook = 'search',
  hasError,
  className,
  onChange,
  onFocus,
  onBlur,
  id,
  name,
  autoFocus = false,
}: SearchElementProps): JSX.Element => (
  <div className={cx('SearchElement', { 'SearchElement--disabled': disabled }, className)}>
    <InputElement
      className="t-searchElement !h-10 !rounded"
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      testHook={testHook}
      hasError={hasError}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
      name={name}
      autoFocus={autoFocus}
    />
    <div className="absolute top-1/2 left-2.5 translate-y-[-55%]">
      <Icons icon="search" className=" h-4 w-4  text-gray-400" />
    </div>
  </div>
);

export default SearchElement;
