/**
 * @description Angular main module for Admin -> Profile Identities
 * @memberOf webUi
 * @namespace webUi.ui.admin.imports
 */
angular.module('webUi.ui.admin.imports', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.admin.imports', {
            url: '/imports',
            isReact: true,
        });
    }]);