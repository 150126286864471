angular
    .module('webUi.ui.profiles.thirdPartyDataCollectors.base', ['webUi.ui.profiles.base'])
    .constant('COLLECTORS_CONSTANT', {
        engagementType: 'ENGAGEMENT',
        externalFactType: 'EXTERNAL FACT',
    })
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.profiles.thirdPartyDataCollectors', {
                url: '/collectors',
                isReact: true,
                leftMenu: true,
            });
        },
    ]);
