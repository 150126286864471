import React from 'react';
import PropTypes from 'prop-types';
import Detail from './Default';
import Code, { mode as codeMode } from '../../Code';

const CodeDetail = ({ label, value, mode = codeMode.js }) => (
  <Detail label={label} value={value}>
    <Code mode={mode} value={value} options={{ readOnly: true }} />
  </Detail>
);

CodeDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  mode: PropTypes.string,
};

export default CodeDetail;
