import React, { useState } from 'react';
import { PickerView } from '~/components';
import DateValidator from '~/components/src/DateValidator';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import Icons from '~/components/src/Icons';
import Tooltip from '~/components/src/Tooltip';
import i18n from '~/i18n';
import { checkShowDateFormatField } from './utils';

const PropertyNameSelect = ({
  propertyIndex,
  selectedProperty,
  selectedPropertyName,
  allSelectedProperties = [],
  options,
  setFieldValue,
  isCreatable,
  ts,
  isClearable,
  hasNewProperty,
}) => {
  const [showDateFormatModal, setShowDateFormatModal] = useState(false);

  const handleChange = (selectedOption, triggeredAction) => {
    const isClearButtonClicked = triggeredAction.action === 'clear';

    if (isClearButtonClicked) {
      // Clears property row
      setFieldValue(`filters[${propertyIndex}]`, {});
      return;
    }

    setFieldValue(`filters[${propertyIndex}].dataField`, selectedOption.value);
    setFieldValue(`filters[${propertyIndex}].dataFieldId`, selectedOption.dataFieldId);
  };

  const handleDateFormatChange = value => {
    const path = `filters[${propertyIndex}].filterValue.dateFormat`;
    setFieldValue(path, value);
    setShowDateFormatModal(false);
  };

  const ModalContents = ({ dateFormat }) => (
    <DateValidator initialValue={dateFormat} onChange={value => handleDateFormatChange(value)} />
  );

  const customPlaceholder = isCreatable ? ts.startTyping : ts.selectProperty;
  const showDateFormatField = checkShowDateFormatField(selectedProperty);

  return (
    <div className="flex flex-col gap-1">
      <Tooltip tooltip={selectedPropertyName} disabled={!selectedPropertyName} placement="bottom">
        <SelectElement
          isCreatable={isCreatable}
          autoFocus={hasNewProperty}
          value={selectedPropertyName}
          options={options}
          onChange={handleChange}
          formatCreateLabel={label => `Custom: ${label}`}
          isClearable={isClearable}
          isOptionDisabled={option => allSelectedProperties.some(prop => prop.dataField === option.value)}
          placeholder={customPlaceholder}
          noOptionsMessage={() => ts.noOptionMessage}
          testHook={`filters-${propertyIndex}__dataField`}
        />
      </Tooltip>
      {showDateFormatField && (
        <div className="absolute  mt-2 flex items-center gap-1 text-gray-500">
          <span className="flex items-center gap-1">
            {i18n.t('forms:dateFormat.modal.title')}
            <Icons icon="info" className="size-4 cursor-pointer" tooltip={i18n.t('forms:dateFormat.tooltip')} />:
            <span className="text-gray-800">{selectedProperty?.filterValue?.dateFormat}</span>
          </span>
          <Icons
            icon="edit"
            className="t-dateFormatModalButton size-4 text-r42-blue ml-1 cursor-pointer"
            onClick={() => setShowDateFormatModal(true)}
            tooltip="Edit"
          />
        </div>
      )}
      {showDateFormatModal && (
        <PickerView
          pickerTitle={i18n.t('forms:dateFormat.modal.title')}
          handlePickerVisibility={() => setShowDateFormatModal(false)}
          className="!h-auto"
        >
          <ModalContents dateFormat={selectedProperty?.filterValue?.dateFormat} />
        </PickerView>
      )}
    </div>
  );
};

export default PropertyNameSelect;
