import { formValueSelector } from 'redux-form';
import { selectors } from 'react-redux-composable-list';
import { changeUrl } from '~/common';
import * as types from './types';
import ContextUsersService from './dataService.js';

export const fetchUserSites = userId => dispatch => {
  dispatch({
    type: types.USER_SITES_FETCH_START,
  });

  return ContextUsersService.getUserSites(userId)
    .then(sites =>
      dispatch({
        type: types.USER_SITES_FETCH_SUCCESS,
        payload: {
          userId,
          sites,
        },
      }),
    )
    .catch(error => dispatch({ type: types.USER_SITES_FETCH_FAIL, error }));
};

export const addUserSites = userId => (dispatch, getState) => {
  const siteIds = selectors.getSelection(getState(), types.USER_SITES_LIST);
  const selector = formValueSelector(types.USER_SITES_LIST);
  const permissions = selector(getState(), 'permissions');
  const model = {
    userId,
    permissions,
  };

  if (!siteIds || !siteIds.length) return changeUrl(`context/users/view/${userId}`);

  dispatch({
    type: types.USER_SITES_ADD_START,
  });

  const apiCalls = siteIds.map(siteId =>
    ContextUsersService.linkUserToSite({
      ...model,
      siteId,
    }),
  );

  return Promise.all(apiCalls)
    .then(() => {
      dispatch({
        type: types.USER_SITES_ADD_SUCCESS,
        payload: { ...model, siteIds },
      });

      // Go to the user view
      changeUrl(`context/users/view/${userId}`);
    })
    .catch(error => dispatch({ type: types.USER_SITES_ADD_FAIL, error }));
};
