import React, { MouseEvent, ReactElement } from 'react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';
import Icons from '../Icons';
import icons from '../Icons/icons';

type BtnOutlinedType = {
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  testHook?: string;
  color?: string;
  size?: string;
  children?: string;
  icon?: keyof typeof icons;
  disabled?: boolean;
  fullWidth?: boolean;
  tooltip?: string;
  placement?: Placement;
  selected?: boolean;
};

const SIZE: Record<string, string> = {
  xs: 'px-3 py-2 text-xs',
  sm: 'px-3 py-2 text-sm',
  bs: 'px-5 py-[0.57rem] text-sm', // to make button same height as normal button
  lg: 'px-5 py-3 text-base',
  xl: 'px-6 py-3.5 text-base',
};

const ICON_SIZE: Record<string, string> = {
  xs: 'w-4 h-4',
  sm: 'w-4 h-4',
  bs: 'w-5 h-5',
  lg: 'w-5 h-5',
  xl: 'w-6 h-6',
};

const COLORS: Record<string, string> = {
  gray: 'text-gray-600 dark:text-gray-400 border-gray-500 dark:border-gray-400',
  blue: 'text-blue-600 dark:text-blue-400 border-blue-500 dark:border-blue-400',
  amber: 'text-amber-600 dark:text-amber-400 border-amber-500 dark:border-amber-400',
  red: 'text-red-500 border-red-500',
};

const COLOR_STATES: Record<string, string> = {
  gray: `${COLORS.gray} hover:bg-gray-700 dark:hover:bg-gray-600 focus:ring-gray-300 dark:focus:ring-gray-500`,
  blue: `${COLORS.blue} hover:bg-blue-700 dark:hover:bg-blue-600 focus:ring-blue-300 dark:focus:ring-blue-500`,
  amber: `${COLORS.amber} hover:bg-amber-700 dark:hover:bg-amber-600 focus:ring-amber-300 dark:focus:ring-amber-500`,
  red: `${COLORS.red} hover:bg-red-600 hover:text-white dark:hover:bg-red-600 focus:ring-red-300 dark:focus:ring-red-500`,
};

function BtnOutlined({
  className,
  onClick,
  children,
  color = 'blue',
  size = 'bs',
  testHook = 'button',
  tooltip,
  placement = 'bottom',
  icon,
  disabled = false,
  fullWidth = true,
  selected = false,
  ...props
}: BtnOutlinedType): ReactElement {
  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement}>
      <div className={cx({ 'inline-block': !fullWidth, block: fullWidth })}>
        <button
          type="button"
          className={cx(
            'flex rounded border text-center font-medium',
            COLORS[color],
            disabled
              ? 'cursor-not-allowed opacity-50'
              : `hover:text-white focus:outline-none focus:ring-4 dark:hover:text-white ${COLOR_STATES[color]}`,
            SIZE[size],
            { 'bg-blue-600 text-white': selected },
            className,
            `t-${testHook}`,
          )}
          onClick={onClick}
          disabled={disabled}
          {...props}
        >
          <div className="m-auto flex items-center gap-x-1.5">
            {icon && <Icons icon={icon} className={cx(ICON_SIZE[size])} />}
            {children}
          </div>
        </button>
      </div>
    </Tippy>
  );
}

export default BtnOutlined;
