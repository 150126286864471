export const USERS_FETCH_START = 'app/admin/users/USERS_FETCH_START';
export const USERS_FETCH_FAIL = 'app/admin/users/USERS_FETCH_FAIL';
export const USERS_FETCH_SUCCESS = 'app/admin/users/USERS_FETCH_SUCCESS';

export const USER_UPDATE_START = 'app/admin/users/USER_UPDATE_START';
export const USER_UPDATE_FAIL = 'app/admin/users/USER_UPDATE_FAIL';
export const USER_UPDATE_SUCCESS = 'app/admin/users/USER_UPDATE_SUCCESS';

export const INVITE_USER_START = 'app/admin/users/INVITE_USER_START';
export const INVITE_USER_FAIL = 'app/admin/users/INVITE_USER_FAIL';
export const INVITE_USER_SUCCESS = 'app/admin/users/INVITE_USER_SUCCESS';

export const USERS_KEY = 'ADMIN/USERS/USER_LIST';
export const USERS_FILTER_KEY = 'ADMIN/USERS/USER_LIST_SEARCH';
