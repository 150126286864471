import React, { useContext } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInputField, FormikSelectField, FormikTextAreaField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { EXPIRY_OPTIONS } from '~/workflows/constants';
import { updateWorkflow } from '~/workflows/dataService';
import { InitialConfigurationProps } from '~/workflows/types';
import { AudienceContext } from '../AudienceContext';
import Actions from './ActionButtons';
import DirtyFormPopup from './DirtyFormPopup';
import { useDirtyFormHandler } from '../hooks';
import StepHeader from './StepHeader';

const InitialConfiguration = ({ audienceData, refetchAudience, workflowId }: InitialConfigurationProps) => {
  const { goToNextStep, isOptional } = useContext(AudienceContext);
  const { processDirtyForm, showDirtyFormPopup, setShowDirtyFormPopup, cbRef } = useDirtyFormHandler();

  const { name, description, expiryTime } = audienceData;

  const handleUpdateWorkflow = async (values: typeof initialValues) => {
    const { name, description, expiry, expiryOption } = values;
    const payload = { name, description, expiryTime: expiry + expiryOption };
    await updateWorkflow(workflowId, payload);
    showSuccess({ body: i18n.t('workflow:create.steps.step1.api.updated') });
    goToNextStep();
    refetchAudience();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    description: Yup.string().trim(),
    expiry: Yup.number().required(i18n.t('validation:validation.required')),
  });

  const initialValues = {
    name,
    description,
    expiry: expiryTime ? expiryTime.substring(0, expiryTime.length - 1) : 30,
    expiryOption: expiryTime ? expiryTime[expiryTime.length - 1] : 'd',
  };

  return (
    <>
      <div className="w-full px-2 md:w-1/2 xl:w-1/3">
        <StepHeader title={i18n.t('workflow:create.steps.step1.title')} isOptional={isOptional} />
        <p className="py-2 text-gray-600">{i18n.t('workflow:create.steps.step1.description')}</p>
        <Formik
          onSubmit={handleUpdateWorkflow}
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({ handleSubmit, isValid, errors, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} className="p-2 py-4">
              <Field
                name="name"
                as={FormikInputField}
                label={i18n.t('workflow:create.fields.name')}
                placeholder={i18n.t('workflow:common.placeholder')}
                errorText={errors?.name}
                autoFocus={true}
              />
              <Field
                name="description"
                className="mt-2"
                as={FormikTextAreaField}
                minRows={2}
                label={i18n.t('workflow:create.fields.description')}
                placeholder={i18n.t('workflow:common.placeholder')}
                errorText={errors?.description}
              />
              <label className="py-2">{i18n.t('workflow:create.fields.expiry')}</label>
              <div className="flex gap-2">
                <Field
                  name="expiry"
                  as={FormikInputField}
                  className="w-full"
                  type="number"
                  placeholder={i18n.t('workflow:common.placeholder')}
                  errorText={errors?.expiry}
                />
                <Field
                  name="expiryOption"
                  className="w-full"
                  as={FormikSelectField}
                  getOptionLabel={(option: { label: string; value: string }) => option.label}
                  getOptionValue={(option: { label: string; value: string }) => option.value}
                  customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                    setFieldValue(name, option.value);
                  }}
                  options={EXPIRY_OPTIONS}
                />
              </div>

              <Actions
                onConfirm={handleSubmit}
                isConfirmEnabled={isValid}
                confirmText={i18n.t('workflow:actions.next')}
                dirty={dirty}
                processDirtyForm={processDirtyForm}
              />
            </form>
          )}
        </Formik>
      </div>

      <DirtyFormPopup {...{ cbRef, showDirtyFormPopup, setShowDirtyFormPopup }} />
    </>
  );
};

export default InitialConfiguration;
