import React, { Component } from 'react';
import queryString from 'query-string';
import { buildUrl, changeUrl, history } from '~/common/history';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { components } from '~/components/src/Table';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import DependantsContainer from '~/profiles/components/DependantsContainer';
import InteractionDetails from '~/profiles/components/InteractionDetails';
import MissedPropertiesList from '~/profiles/components/MissedPropertiesList';
import PropertiesList from '~/profiles/components/PropertiesList';
import { ALLOWED_NUMBER_OF_PROPERTIES, CUSTOMER_FACT_USED_IN } from '~/profiles/customerFacts/types';
import { hasSpecialCharacters } from '~/profiles/util';

const { DetailContainer } = components;

const createDetailContainerTitle = (customerFact, propertyId, t) => {
  if (!customerFact || !propertyId) {
    return '';
  }
  if (customerFact.customerFactId === propertyId) {
    return `${t('general.customerFact')} - ${customerFact.name || ''}`;
  }
  return `${t('general.property')} - ${propertyId}`;
};

const getElementName = (customerFact, propertyId) => {
  if (!propertyId) {
    return '';
  }
  return customerFact.customerFactId === propertyId ? customerFact.name : propertyId;
};

const getDetails = customerFact => ({
  id: customerFact.customerFactId,
  description: customerFact.description,
  typeLabel: customerFact.typeLabel,
  dependantTypes: customerFact.dependantTypes,
  usedProperties: customerFact.usedProperties,
  properties: customerFact.properties,
  insights: customerFact.insights,
});

export class CustomerFactViewPage extends Component {
  async componentDidMount() {
    await this.props.fetchCustomerFactIfNeeded();
    this.props.fetchCustomerFactActivity();
    this.props.fetchCustomerFactTrafficSources();
    this.props.fetchCustomerFactProperties();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      t,
      customerFactTableStateKey,
      propertiesList,
      canEditProfiles,
      canAccessData,
      customerFactId,
      customerFact,
      isFetchingExternalFact,
      onDependantIconClick,
      onDependantsTabChange,
      onDependantsContainerClose,
      selectedPropertyId,
      dependantsContainerIsVisible,
      selectedTab,
      selectedProperty,
      onDeleteCustomerFact,
      isFetchingCustomerFactActivity,
      isFetchingCustomerFactTrafficSources,
      isFetchingCustomerFactProperties,
      isPropertyBeingAdded,
    } = this.props;

    const shouldGoBackToFactsList = !queryString.parse(window.location.search).back;

    const goBackLink = shouldGoBackToFactsList
      ? {
          title: t('general.customerFacts'),
          pathname: buildUrl('profiles/externalFacts/dashboard'),
        }
      : {
          title: t('common:goBack'),
          onClick: () => {
            history.back();
          },
        };

    const handleAddProperties = properties => {
      this.props.onAddProperties(customerFact, properties);
    };

    return isFetchingExternalFact || !customerFact ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={customerFact.name} crumbs={[goBackLink]}>
          <BtnIcon
            icon="delete"
            disabled={!canEditProfiles || customerFact.hasDependants}
            onClick={() => onDeleteCustomerFact(customerFact)}
            testHook="delete"
            tooltip={t('view.deleteTooltip')}
          />
          <BtnIcon
            color="blue"
            icon="edit"
            disabled={!canEditProfiles}
            onClick={() => changeUrl(`profiles/externalFacts/edit/${customerFactId}`)}
            tooltip={t('common.edit')}
          />
        </Heading>
        {hasSpecialCharacters(customerFact.name) && (
          <Notification kind="warning">{t('edit.nameContainsSpecialCharacters')}</Notification>
        )}

        <InteractionDetails
          stateKey={customerFactTableStateKey}
          usedIn={CUSTOMER_FACT_USED_IN}
          maxNumberOfProperties={ALLOWED_NUMBER_OF_PROPERTIES}
          interaction={getDetails(customerFact)}
          onDependantIconClick={onDependantIconClick}
          selectedPropertyId={selectedPropertyId}
          isFetchingActivity={isFetchingCustomerFactActivity}
          isFetchingTrafficSources={isFetchingCustomerFactTrafficSources}
          t={t}
        />

        <Tabs>
          <TabList>
            <Tab testHook="configuredPropertiesTab">{t('tabs.configuredProperties.header')}</Tab>
            <Tab testHook="missedPropertiesTab">{t('tabs.missedProperties.header')}</Tab>
          </TabList>
          <TabPanel>
            <PropertiesList
              t={t}
              list={propertiesList}
              canAccessData={canAccessData}
              usedIn={CUSTOMER_FACT_USED_IN}
              interactionId={customerFactId}
              onDependantIconClick={onDependantIconClick}
              isFetchingProperties={isFetchingCustomerFactProperties}
            />
          </TabPanel>
          <TabPanel>
            <MissedPropertiesList
              t={t}
              list={customerFact?.insights?.missingProperties ?? []}
              isFetchingProperties={isFetchingCustomerFactProperties}
              canEditProfiles={canEditProfiles}
              canAccessData={canAccessData}
              handleAddProperties={handleAddProperties}
              isPropertyBeingAdded={isPropertyBeingAdded}
            />
          </TabPanel>
        </Tabs>

        <DetailContainer
          onClose={onDependantsContainerClose}
          isVisible={dependantsContainerIsVisible}
          title={createDetailContainerTitle(customerFact, selectedPropertyId, t)}
        >
          <DependantsContainer
            element={selectedProperty}
            elementName={getElementName(customerFact, selectedPropertyId)}
            onTabChange={onDependantsTabChange}
            selectedTab={selectedTab}
            showContentDependants={false}
          />
        </DetailContainer>
      </Page>
    );
  }
}

export default CustomerFactViewPage;
