import { connect } from 'react-redux';
import DoubleClickForm from './DoubleClickForm';
import { fetchAdvertisers, fetchUserIdTypes } from './doubleClickActions';
import { getAdvertisers, getUserIdTypes, fetchingAdvertisers, fetchingUserIdTypes } from './doubleClickSelector';

const mapStateToProps = state => ({
  fetchingAdvertisers: fetchingAdvertisers(state),
  fetchingUserIdTypes: fetchingUserIdTypes(state),
  advertisers: getAdvertisers(state),
  userIdTypes: getUserIdTypes(state),
});

const mapDispatchToProps = {
  fetchAdvertisers,
  fetchUserIdTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DoubleClickForm);
