import React from 'react';
import i18n from '~/i18n';

import { I18NextT } from '~/components/src/Common/types';
import BtnIcon from '~/components/src/BtnIcon';
import icons from '~/components/src/Icons/icons';
import './UsageIcons.scss';

const ICONS_BY_TYPE = {
  SELECTION: 'audience',
  THIRD_PARTY_DATA_COLLECTOR: 'connector',
  AD: 'ad',
  JOURNEY: 'journey',
  FILTER: 'filter',
  PROPERTY_TRANSFORMATION: 'transformation',
};

const getTooltipText = (type: string, t: I18NextT) => {
  const tooltipTextByType = {
    SELECTION: t('customerData:entities.selections'),
    THIRD_PARTY_DATA_COLLECTOR: t('customerData:entities.thirdPartyDataCollectors'),
    AD: t('customerData:entities.ads'),
    JOURNEY: t('customerData:entities.workflows'),
    FILTER: t('customerData:entities.filters'),
    PROPERTY_TRANSFORMATION: t('customerData:entities.transformations'),
  } as Record<string, string>;

  return tooltipTextByType[type];
};

export type UsedInItem = keyof typeof ICONS_BY_TYPE;

interface UsageIconsProps {
  elementUsedIn: UsedInItem[];
  dependantTypes: UsedInItem[];
  elementId: string;
  onDependantIconClick: (elementId: string, index: number) => void;
}

const UsageIcons = ({ elementUsedIn, elementId, onDependantIconClick, dependantTypes = [] }: UsageIconsProps) => {
  const items = elementUsedIn.map((type: UsedInItem, index: number) => {
    const isEnabled = dependantTypes?.includes(type);
    return (
      <BtnIcon
        icon={ICONS_BY_TYPE[type] as keyof typeof icons}
        onClick={() => onDependantIconClick(elementId, index)}
        key={type}
        testHook={`${type.toLowerCase()}Button`}
        tooltip={isEnabled ? getTooltipText(type, i18n.t) : ''}
        disabled={!isEnabled}
        color={isEnabled ? 'blue' : 'gray'}
      />
    );
  });

  // Wrapping an array of Elements into Fragment to make TypeScript happy
  return <div className="flex gap-2">{items}</div>;
};

export default UsageIcons;
