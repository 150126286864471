/**
 * A service for AdServing related queries
 */
angular.module('webUi.service.content.placementgroup', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('PlacementGroupService', ['Restangular',
        function(Restangular){

            // the base path through which all calls should go
            var BASE_PATH = 'content/';

            return {
                /*
		 * Placement Groups
		 */

                addPlacementGroup: function(placementGroup) {
                    _.forEach(placementGroup.placements, function(placement){
                        placement.campaignId = placementGroup.campaignId;
                    });
                    return Restangular.all(BASE_PATH + 'campaigns/pg').post({
                        placementGroup: placementGroup
                    });
                },

                updatePlacementGroupName: function(placementGroupId, campaignId, name) {
                    return Restangular.one(BASE_PATH + 'campaigns/pg/').post('name', {
                        placementGroupId: placementGroupId,
                        campaignId: campaignId,
                        name: name
                    });
                },

                deletePlacementGroup : function (placementGroupId, campaignId){
                    return Restangular.one(BASE_PATH + 'campaigns/pg/').post('delete', {
                        placementGroupId: placementGroupId,
                        campaignId: campaignId
                    });
                },

                /*
		 * Default placement sizes
		 */
                getDefaultPlacements: function() {
                    return [
                        {width: 300, height: 250},
                        {width: 336, height: 280},
                        {width: 468, height:  60},
                        {width: 728, height:  90},
                        {width: 120, height: 600},
                        {width: 160, height: 600}
                    ];
                },

                savePlacement: function(placement) {
                    return Restangular.all(BASE_PATH + 'campaigns/pg/placements').post({
                        placement: placement
                    });
                },

                //takes an ad and a placement as parameters and saves the ad in the linkedAds property of the placement
                toggleActivateAdForPlacement: function(campaignId, p, ads) {
                    return Restangular.all(BASE_PATH + 'campaigns/pg/placements/activate').post({
                        campaignId: campaignId,
                        placement: p,
                        linkedAds: ads
                    });
                },

                deletePlacement : function (placement){
                    return Restangular.one(BASE_PATH + 'campaigns/pg/placements').post('delete', {
                        placement: placement
                    });
                }
            };
        }]);
