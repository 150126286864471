import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import classNames from 'classnames';
import { getIcon } from '~/common/modules/rules/utils';
import RuleConnectorPicker from '~/components/src/RuleConnectorPicker';
import RuleCardGrid from '~/components/src/RuleCardGrid';
import { RuleOptions } from '~/components/src/RuleList';
import { RulesTypes } from '~/common/modules/rules/types';
import Spin from '~/components/src/Spin';

export const addIcons = ruleTypesTree =>
  ruleTypesTree.map(ruleType => ({
    ...ruleType,
    icon: getIcon(ruleType.type),
  }));

const removeReusableAudiences = ruleTypesTree =>
  ruleTypesTree.filter(({ type }) => type !== RulesTypes.ReusableAudience);

const removeCurrentAudienceFromReusableAudiences = (ruleTypesTree, audienceId) =>
  ruleTypesTree.map(ruleType => {
    if (ruleType.type === RulesTypes.ReusableAudience) {
      return {
        ...ruleType,
        subMenu: ruleType.subMenu.filter(({ ruleDefinitionId }) => ruleDefinitionId !== audienceId),
      };
    }

    return ruleType;
  });

const removeOverlap = ruleTypesTree => ruleTypesTree.filter(({ type }) => type !== RulesTypes.JourneyOverlap);

const RulePicker = ({
  ruleTypesTree,
  testHook = 'rulePicker',
  className,
  t,
  onSelect,
  actionName,
  actionType,
  isUsedBySegment,
  isUsedInJourneys,
  audienceId,
  disallowDuplicates = false,
  selectedRules = [],
  enableGroupBy,
  usedForFilters,
}) => {
  if (!ruleTypesTree || !ruleTypesTree.length) {
    return <Spin />;
  }

  let menuItems = ruleTypesTree;

  if (disallowDuplicates) {
    menuItems = menuItems.map(mi => {
      if (mi.subMenu && mi.subMenu.length) {
        return {
          ...mi,
          subMenu: mi.subMenu.filter(sm => !selectedRules.find(rm => sm.ruleDefinitionId === rm.ruleDefinitionId)),
        };
      }
      return mi;
    });

    menuItems = menuItems.filter(mi => {
      if (mi.subMenu && mi.subMenu.length) {
        return true;
      }
      return !selectedRules.find(rm => mi.ruleDefinitionId === rm.ruleDefinitionId);
    });
  }

  if (isUsedBySegment) {
    menuItems = removeReusableAudiences(menuItems);
  } else {
    menuItems = removeCurrentAudienceFromReusableAudiences(menuItems, audienceId);
  }

  if (isUsedInJourneys || actionType === RuleOptions.OR) {
    menuItems = removeOverlap(menuItems);
  }

  const menuWithIcons = addIcons(menuItems);

  return (
    <div className={classNames(className, `t-${testHook}`)}>
      <RuleConnectorPicker actionName={actionName} testHookPrefix={testHook}>
        <RuleCardGrid
          usedForFilters={usedForFilters}
          enableGroupBy={enableGroupBy || usedForFilters}
          menuTree={menuWithIcons}
          isSearchable={true}
          searchPlaceholder={t('actions.search')}
          onSelect={onSelect}
          emptyMessage={t('list.emptySearch')}
        />
      </RuleConnectorPicker>
    </div>
  );
};

export default compose(translate('common'))(RulePicker);
