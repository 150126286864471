import React from 'react';
import PropTypes from 'prop-types';
import Heading from '~/components/src/Heading';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import MessageDetail from './MessageDetail.js';
import MessagePreview from './MessagePreview.js';
import BtnIcon from '../BtnIcon';
import LinkIcon from '../LinkIcon';

const Message = ({ message, editLink, onDeleteClick, ts }) => (
  <MediumWrapper className="Message">
    <Heading title={ts.header}>
      <LinkIcon url={editLink} testHook="edit" className="h-8 w-8" icon="edit" tooltip={ts.edit} />
      <BtnIcon tooltip={ts.delete} icon="delete" onClick={() => onDeleteClick(message)} testHook="delete" />
    </Heading>
    <Panel>
      <MessageDetail message={message} ts={ts} />
      <MessagePreview message={message} ts={ts} />
    </Panel>
  </MediumWrapper>
);

Message.propTypes = {
  message: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    clientName: PropTypes.string,
    showDate: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    hideDate: PropTypes.string,
  }).isRequired,
  editLink: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  testHook: PropTypes.string,
  // Translations
  ts: PropTypes.shape({
    header: PropTypes.string.isRequired,
    edit: PropTypes.string.isRequired,
    delete: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    showDate: PropTypes.string.isRequired,
    hideDate: PropTypes.string.isRequired,
  }),
};

export default Message;
