import React, { ReactElement } from 'react';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { useAPI } from '~/common';
import { buildUrl } from '~/common/history';
import Spin from '~/components/src/Spin';
import * as api from '~/profiles/pages/Filters/dataService';
import { Link } from '~/components';
import { getRuleItemUrl } from './util';
import FilterEvent from './FilterEvent';

interface LinkedFilterSidePanelProps {
  filterId: string;
  handleClose: () => void;
}

function LinkedFilterSidePanel({ filterId, handleClose }: LinkedFilterSidePanelProps): ReactElement | null {
  const { data: filter, isLoading } = useAPI(() => api.getFilter(filterId), [filterId]);

  if (!filter && !isLoading) {
    return null;
  }

  const rules = filter?.rules.map(ruleWithoutUrl => {
    const url = getRuleItemUrl(ruleWithoutUrl.type, ruleWithoutUrl.ruleDefinitionId);

    if (url) {
      return {
        ...ruleWithoutUrl,
        url,
      };
    }

    return ruleWithoutUrl;
  });

  return (
    <div className="fixed right-0 top-16 flex h-full w-1/4 justify-end bg-gray-200">
      <div className="h-full w-full overflow-y-auto border-l border-gray-200 bg-white">
        {isLoading ? (
          <Spin className="mt-40" />
        ) : (
          <>
            <div className="sticky top-0">
              <div className="flex items-center justify-between border-b border-gray-200 bg-white p-8 pb-5  ">
                <div className="flex flex-col gap-2">
                  <Link
                    className="text-xl font-medium !text-black hover:!text-blue-800"
                    href={buildUrl(`profiles/filters/view/${filter?.filterId}`)}
                    tooltip={i18n.t('filters:dependencies.viewFilter')}
                  >
                    {filter?.name}
                  </Link>
                </div>
                <BtnIcon
                  icon="close"
                  className="h-6 w-6"
                  tooltip={i18n.t('filters:dependencies.close')}
                  onClick={() => handleClose()}
                />
              </div>
              <div className="sticky flex items-center gap-2 bg-gray-100 p-6">
                <p className="font-medium text-gray-600">{i18n.t('filters:form.events')}</p>
              </div>
            </div>

            <div className="p-4">
              <div className="flex w-full flex-col gap-2 rounded border-dashed border-gray-300">
                {rules?.map(rule => (
                  <FilterEvent
                    key={rule.id}
                    id={rule.id}
                    title={rule.title}
                    type={rule.type}
                    typeLabel={rule.typeLabel}
                    url={rule.url}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LinkedFilterSidePanel;
