import { compose } from 'recompose';
import React from 'react';
import Empty from '~/components/src/Empty';
import SortableHeader, { boolSort } from '~/components/src/Table/sortableHeader';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { components, enhancements } from '~/components/src/Table';
import PropTypes from 'prop-types';

const { Table, Row, Cell } = components;
const { withEmpty, withSort, withPaginate, withFilter } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('linkSites.empty.header')} body={t('linkSites.empty.body')} />;

const NoResults = ({ t }) => <Empty header={t('linkSites.empty.header')} body={t('linkSites.empty.body')} />;

const WIDTHS = {
  SITE_NAME: '40%',
  BASESCRIPT_VERSION: '30%',
  MARKED_TO_USED_BASESCRIPT: '30%',
};

export const BasescriptSitesList = ({ list, stateKey, t, toggleBasescriptUsage, basescript }) => (
  <Table stateKey={stateKey}>
    <Row>
      <SortableHeader width={WIDTHS.SITE_NAME} sortKey="siteName" title={t('linkSites.siteName')} />
      <SortableHeader
        width={WIDTHS.BASESCRIPT_VERSION}
        sortKey="basescriptVersion"
        title={t('linkSites.basescriptVersion')}
      />
      <SortableHeader
        width={WIDTHS.MARKED_TO_USED_BASESCRIPT}
        sortKey="markedToUseBasescript"
        title={t('linkSites.use', { basescriptVersion: basescript.version })}
        sortFn={boolSort('markedToUseBasescript')}
      />
    </Row>
    {list.map((item, index) => (
      <Row key={item.siteId}>
        <Cell style={{ width: `${WIDTHS.SITE_NAME}` }}>{item.siteName}</Cell>
        <Cell style={{ width: `${WIDTHS.BASESCRIPT_VERSION}` }}>
          {item.markedToUseBasescript ? ' ' : item.basescriptVersion}
        </Cell>
        <Cell style={{ width: `${WIDTHS.MARKED_TO_USED_BASESCRIPT}` }}>
          <CheckboxElement
            id={`use${index}`}
            value={item.markedToUseBasescript}
            onChange={() => toggleBasescriptUsage(item.siteId, basescript.basescriptId)}
          />
        </Cell>
      </Row>
    ))}
  </Table>
);

BasescriptSitesList.propTypes = {
  list: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  stateKey: PropTypes.string.isRequired,
  basescript: PropTypes.object.isRequired,
  toggleBasescriptUsage: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 10 }),
)(BasescriptSitesList);
