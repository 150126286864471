import React from 'react';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { FormSection, Field } from 'redux-form';
import { Link, Notification } from '~/components';
import { ReduxFormInputField, ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import { isNumberDash, isRequired, useAPI } from '~/common';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';
import { fetchProductTypes, fetchPayloadTypes, fetchConsentTypes } from './dataService';
import { GoogleCustomerMatchFormProps } from './types';

const GoogleCustomerMatchForm = ({
  t,
  change,
  touch,
  values,
  partner,
  canUpdate: isEditMode,
}: GoogleCustomerMatchFormProps) => {
  const { data: productTypes = [], isLoading: isProductTypesLoading } = useAPI(() => fetchProductTypes());
  const { data: payloadTypes = [], isLoading: isPayloadTypesLoading } = useAPI(() => fetchPayloadTypes());
  const { data: consentTypes = [], isLoading: isConsentTypesLoading } = useAPI(() => fetchConsentTypes());
  if (
    isPayloadTypesLoading ||
    isProductTypesLoading ||
    isConsentTypesLoading ||
    !productTypes ||
    !payloadTypes ||
    !consentTypes
  )
    return <Spin />;
  return (
    <FormSection name={constants.partnerTypes.GOOGLE_CUSTOMER_MATCH}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <div className="flex flex-col gap-4">
          <Field
            label={t('form.formFields.productType')}
            name="productType"
            component={ReduxFormSelectField}
            options={productTypes}
            validate={isRequired}
            disabled={isEditMode}
            onChange={(_event, value: string) => {
              change('GOOGLE_CUSTOMER_MATCH.productType', value);
            }}
            touch={touch}
          />
          <Field
            label={t('form.formFields.customerId')}
            name="customerId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={[isRequired, isNumberDash]}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            disabled={isEditMode}
            onChange={(_event, value: string) => {
              change('GOOGLE_CUSTOMER_MATCH.payloadType', value);
              change(
                'GOOGLE_CUSTOMER_MATCH.partnerNumber',
                payloadTypes.find(item => item.value === value)?.partnerNumber,
              );
            }}
            touch={touch}
          />
        </div>
        <div className="pt-6">
          <Notification kind="information" className="my-6" header={t('form.notification.acceptEUConsent.header')}>
            <p>{t('form.notification.acceptEUConsent.message1.googleCustomerMatch')}</p>
            <p className="mt-2">{t('form.notification.acceptEUConsent.message2')}</p>
            <Link target="_blank" href={t('form.notification.acceptEUConsent.link')}>
              {t('form.notification.acceptEUConsent.link')}
            </Link>
          </Notification>
          <Field
            label={t('form.formFields.acceptEUConsentSelection')}
            name="consentStatus"
            component={ReduxFormSelectField}
            options={consentTypes}
            validate={isRequired}
            touch={touch}
          />
        </div>
      </ContainerFormSection>
    </FormSection>
  );
};

export default GoogleCustomerMatchForm;
