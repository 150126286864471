import moment from 'moment';

import i18n from '~/i18n';

import DateInput from './Date/DateInput';
import TextInput from './Text/TextInput';
import DateOnInput from './Date/DateOnInput';
import NumberInput from './Number/NumberInput';
import DateWithinInput from './Date/DateWithinInput';
import NumberWithInInput from './Number/NumberWithInInput';
import TimeframeWithinInput from './Timeframe/TimeframeWithinInput';
import { DATE_FORMAT, DATE_REGEX, FIELD_DATE_FORMAT, FILTER_CLAZZ } from './constants';

export const getComponentByClazz = (clazz: string) => {
  switch (clazz) {
    case FILTER_CLAZZ.NUMBER:
      return NumberInput;
    case FILTER_CLAZZ.NUMBER_WITHIN:
      return NumberWithInInput;
    case FILTER_CLAZZ.DATE:
      return DateInput;
    case FILTER_CLAZZ.DATE_ON:
      return DateOnInput;
    case FILTER_CLAZZ.DATE_WITHIN:
      return DateWithinInput;
    case FILTER_CLAZZ.TIMEFRAME_WITHIN:
      return TimeframeWithinInput;
    default:
      return TextInput;
  }
};

export const getDefaultValueByClazz = (clazz: string) => {
  switch (clazz) {
    case FILTER_CLAZZ.DATE:
      return { clazz, dateFormat: 'yyyy-MM-dd', timeFrame: 'PAST' };
    case FILTER_CLAZZ.DATE_ON:
      return { clazz, dateFormat: 'yyyy-MM-dd' };
    case FILTER_CLAZZ.DATE_WITHIN:
      return { clazz, dateFormat: 'yyyy-MM-dd', timeFrame: 'PAST', rangeFrom: '', rangeTo: '' };
    case FILTER_CLAZZ.TIMEFRAME_WITHIN:
      return { clazz, dateFormat: 'yyyy-MM-dd HH:mm', rangeFrom: '', rangeTo: '' };
    default:
      return { clazz };
  }
};

export const dateValidation = (value: string) => (DATE_REGEX.test(value) ? '' : i18n.t('common:errors.wrongFormat'));

export const dateOnValidation = (value: string) =>
  moment(value, DATE_FORMAT).format(DATE_FORMAT) === value ? '' : i18n.t('validation:ruleCard.filters.dateon');

const getDuration = (key: string) => {
  switch (key) {
    case 'd':
      return 'days';
    case 'w':
      return 'weeks';
    case 'm':
      return 'months';
    default:
      return 'hours';
  }
};

const checkDatesInRange = (from: string, to: string, operation: 'add' | 'subtract') => {
  const fromDate = moment()[operation](from.replace(/[dwmh]/, ''), getDuration(from.replace(/^([0-9])+/, '')));
  const toDate = moment()[operation](to.replace(/[dwmh]/, ''), getDuration(to.replace(/^([0-9])+/, '')));
  return operation === 'add' ? fromDate.isAfter(toDate, 'day') : fromDate.isBefore(toDate, 'day');
};

export const dateWithinValidation = (from: string, to: string, timeFrame: string) => {
  const isRangeFromValid = DATE_REGEX.test(from);
  const isRangeToValid = DATE_REGEX.test(to);

  if (!isRangeFromValid || !isRangeToValid) return i18n.t('common:errors.wrongFormat');

  if (checkDatesInRange(from, to, timeFrame === 'PAST' ? 'subtract' : 'add'))
    return i18n.t('validation:ruleCard.filters.dateWithinRange');
  return '';
};

export const numberWithinValidation = (from: string, to: string) => {
  const rangeFrom = (typeof from === 'string' || typeof from === 'number') && parseFloat(from);
  const rangeTo = (typeof to === 'string' || typeof to === 'number') && parseFloat(to);

  const isRangeFromValid = typeof rangeFrom === 'number';
  const isRangeToValid = typeof rangeTo === 'number';

  const isValid = isRangeFromValid && isRangeToValid;

  if (!isValid) return i18n.t('validation:ruleCard.filters.numberWithin');
  return rangeFrom < rangeTo ? '' : i18n.t('validation:ruleCard.filters.numberWithinRangeCheck');
};

export const timeFrameValidation = (from: string, to: string) => {
  const rangeFrom = moment(from, FIELD_DATE_FORMAT);
  const rangeTo = moment(to, FIELD_DATE_FORMAT);

  const isRangeFromValid = rangeFrom.isValid();
  const isRangeToValid = rangeTo.isValid();
  const isValid = isRangeFromValid && isRangeToValid;

  if (!isValid) return i18n.t('validation:ruleCard.filters.timeframeWithin');
  return rangeFrom.isBefore(rangeTo) ? '' : i18n.t('validation:ruleCard.filters.timeframeWithinRangeCheck');
};
