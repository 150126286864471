angular.module('webUi.ui.content.campaigns.ads.creatives.mediafilechooser', ['webUi.service'])

    .controller('MediaFileChooserController', ['$scope', 'dialogsModel', '$uibModalInstance', '$timeout',
        function MediaFileChooserController($scope, dialogsModel, $uibModalInstance) {

            $scope.mediaLibrary = dialogsModel.mediaLibrary;
            $scope.filter = dialogsModel.filter;

            $scope.handleFile = function(newFile) {
                if ( !_.isUndefined(newFile) && !_.isUndefined(newFile.name)){
                    $uibModalInstance.close(newFile);
                }
            };

            $scope.close = function() {
                $uibModalInstance.close();
            };
        }
    ]);
