import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import i18n from '~/i18n';
import { Notification } from '~/components';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import { fetchSampleKeyData } from './dataService';
import { SampleKey } from './types';

export const SampleKeys = ({ dataFeedId, sampleKey }: { dataFeedId: string; sampleKey: string }): JSX.Element => {
  const [sampleKeys, setSampleKeys] = useState<Array<SampleKey> | null>(null);

  const handleFetchSampleKeys = async ({ sampleKeyString }: { sampleKeyString: string }) => {
    const sampleKeysObject = await fetchSampleKeyData(dataFeedId, sampleKeyString);
    const keys = Object.keys(sampleKeysObject).map((key: string) => ({
      key,
      value: sampleKeysObject[key],
    }));
    setSampleKeys(keys);
  };

  useEffect(() => {
    if (sampleKey) handleFetchSampleKeys({ sampleKeyString: sampleKey });
  }, [sampleKey]);

  const initialValues = { sampleKeyString: sampleKey || '' };

  const validationSchema = Yup.object().shape({
    sampleKeyString: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  return (
    <div className="my-4 block rounded-md border border-gray-200 border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
      <p className="m-0 text-xl font-medium text-gray-600">{i18n.t('dataFeeds:dataSample')}</p>
      <Formik
        onSubmit={handleFetchSampleKeys}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ handleSubmit, errors, isValid, isSubmitting }) => (
          <form className="my-2 flex items-center gap-2" onSubmit={handleSubmit}>
            <Field
              className="basis-1/3"
              name="sampleKeyString"
              id="sampleKeyString"
              as={FormikInputField}
              placeholder={i18n.t('dataFeeds:form.placeholder')}
              label={i18n.t('dataFeeds:form.sampleKey')}
              errorText={errors?.sampleKeyString}
            />
            <BtnIcon
              disabled={!isValid || isSubmitting}
              className="mt-2"
              icon="download"
              type="submit"
              testHook="retrieveSamples"
              tooltip={i18n.t('dataFeeds:toolTips.retrieveSample')}
            />
          </form>
        )}
      </Formik>
      {sampleKeys &&
        sampleKeys.length > 0 &&
        sampleKeys.map((sampleKey, index) => (
          <div
            key={index}
            className={cx(index % 2 ? 'bg-slate-100' : 'bg-slate-50', ' t-keys flex w-full p-2 hover:bg-slate-200')}
          >
            <span className="basis-1/3 font-medium">{sampleKey.key}</span>
            <span>{sampleKey.value}</span>
          </div>
        ))}
      {sampleKeys && sampleKeys.length === 0 && (
        <Notification testHook="sampleKeysNotification" kind="warning">
          {i18n.t('dataFeeds:messages.noData')}
        </Notification>
      )}
    </div>
  );
};
