/**
 * @description: A directive that is a searchable dropdown menu
 */
angular.module('webUi.directive.searchableSelect', [
    'webUi.service',
    'ui.bootstrap'
])

    .directive('searchableSelect', [
        '$compile',
        '$templateCache',
        '$timeout',
        'Utils',
        'UUIDService',
        'PubSubService',
        function searchableSelectDirective($compile, $templateCache, $timeout, Utils, UUIDService, PubSubService) {

            return {
                restrict: 'E',
                transclude: true,

                scope: {

                    // The collection of items that can be selected
                    // Disabled items should be marked with a property (e.g. _enabled: false) and this property
                    // passed via 'enabledItemPropertyName'
                    items: '=',

                    // Extra variables defining how what properties to use for the selectable object (id, name, etc)
                    // Recognized options are:
                    // idProperty, label, searchFilterProperty, isClearable, selectPlaceholder, addNewPlaceholder, addNewTitle, enabledItemPropertyName
                    // clearSelectionPlaceholder, clearSelectionTittle, searchBoxIdSuffix
                    options: '&',
                    allowAddNewItems: '=?',
                    showSearch: '=?',
                    isClearable: '=?',
                    disabled: '=?',
                    editSuccessCallback: '=',
                    clearSuccessCallback: '=',

                    // Callback for on select
                    onSelect: '&',
                    // Callback for add
                    onAdd: '&',
                    // Callback for clear
                    onClear: '&'
                },

                compile: function compile() {

                    return {

                        pre: function preLink(scope, elem, attrs) {

                            var localOptions = scope.options() || {};
                            var compiledElement;

                            var sObject = scope.selectableObject = {
                                originalValue: null,
                                value: null
                            };

                            var init = function() {

                                //attach to modal or else to the container of the directive
                                scope.appendToParent = angular.element(document.querySelector('.modal-body, .searchable-container-select'));
                                if(_.isEmpty(scope.appendToParent)) {
                                    scope.appendToParent = angular.element(document.querySelector('body'));
                                }
                                /**
							 * Inits options
							 */
                                var initOptions = function () {
                                    scope.elementState = scope.elementState || { selectedIndex: -1 };
                                    //un    ique id
                                    scope.searchBoxId = UUIDService.newId();
                                    scope.value = localOptions['value'];
                                    scope.label = localOptions['label'] || 'name';
                                    // The property on which the filter will be applied. If none is specified, it will choose the object.name
                                    scope.searchFilterProperty = localOptions['searchFilterProperty'] || scope.label;
                                    // Whether or not to display the "Add new" option
                                    // Label placeholders
                                    scope.selectPlaceholder = localOptions['selectPlaceholder'] || 'Select a value';
                                    scope.addNewItemPlaceholder = localOptions['addNewItemPlaceholder'] || 'Add new';
                                    scope.addNewTitle = localOptions['addNewTitle'] || 'Click here to create a new item';
                                    scope.clearSelectionPlaceholder = localOptions['clearSelectionPlaceholder'] || 'Clear selection';
                                    scope.clearSelectionTitle = localOptions['clearSelectionTitle'] || 'Clear selection value';
                                    scope.noItemsFoundPlaceholder = localOptions['noItemsFoundPlaceholder'] || 'No items are available for selection';
                                    if (scope.selectableItems) {
                                        scope.showNoItemsFoundMessage = scope.selectableItems.length < 1;
                                    }
                                    scope.showClear = (!scope.showNoItemsFoundMessage && scope.isClearable && !scope.disabled);
                                    scope.enabledItemPropertyName = localOptions['enabledItemPropertyName'];
                                };

                                /**
							 * Why:
							 * - items needs to be watched (not set in the first run) and originalValue is not set
							 * - scope.value when is set and id->object mapping exists value needs to be the object and not the id
							 */
                                var getAndSetModelValues = function () {
                                    scope.$parent.$watch(attrs['items'], function (items) {

                                        if (!items || items.length === 0) {
                                            return;
                                        }

                                        // Get the declared ngModel
                                        var modelName = attrs['ngModel'];

                                        if (modelName) {
                                            //name of the object. e.g if the modelName is "segment.enhancement.source", then the
                                        // objectName is "segment.enhancement"
                                            var objName = modelName.substring(0, _.lastIndexOf(modelName, '.'));

                                            //name of the property on the object e.g if the model name is "segment.enhancement.source",
                                            //then the propertyName would be "source"
                                            scope.propertyName = modelName.substring(_.lastIndexOf(modelName, '.') + 1);

                                            //get the model object
                                            scope.modelObj = scope.$parent.$eval(objName);

                                            // Update values from the model
                                            if (scope.value) {
                                                sObject.originalValue = _.find(items, function (item) {
                                                    return item[scope.value] === scope.modelObj[scope.propertyName];
                                                });
                                            } else {
                                                sObject.originalValue = scope.modelObj[scope.propertyName];
                                            }
                                            sObject.value = sObject.originalValue;
                                        }

                                    }, true);
                                };

                                var defaults = {
                                    searchText: '',
                                    showSearch: true,
                                    allowAddNewItems: false,
                                    isClearable: false,
                                    disabled: false
                                };

                                // Assign the default values to scope unless they have been already specified
                                _.extend(scope, defaults, function (valueInScope, defaultValue) {
                                    return _.isUndefined(valueInScope) ? defaultValue : valueInScope;
                                });

                                initOptions();
                                getAndSetModelValues();

                                var resizeFn = null;
                                var template = $templateCache.get('directive/searchableSelect/searchableSelect.tpl.html');
                                compiledElement = $compile(template)(scope);

                                elem.after(compiledElement);

                                scope.$on('$destroy', function() {
                                    compiledElement.remove();
                                    if(resizeFn) {
                                        resizeFn.unbind();
                                    }
                                });

                            };


                            var select = function (sc, item) {
                                // The new value is either the selected object, or the selected property
                                sObject.originalValue = sObject.value;
                                sc.modelObj[sc.propertyName] = !sc.value ? item : item[sc.value];

                                // call onSelect
                                if (sc.onSelect) {
                                    sc.onSelect();
                                }
                            };

                            //opens or closes the dropdown
                            var closeDropdown = function (sc) {
                                sc.elementState.showDropdown = false;
                            };

                            var selectAndCloseDropdown = function(sc, item) {
                                select(sc, item);
                                closeDropdown(sc);
                            };

                            var clear = function (sc) {
                                // call onClear
                                if (sc.onClear) {
                                    sc.onClear();
                                }

                                sObject.value = undefined;
                                sObject.originalValue = sObject.value;
                                if (!_.isUndefined(sc.modelObj)) {
                                    sc.modelObj[sc.propertyName] = undefined;
                                }
                            };

                            var clearAndCloseDropdown = function (sc) {
                                clear(sc);
                                closeDropdown(sc);
                            };

                            var editAndCloseDropdown = function (sc, item) {
                                var copiedItem = _.cloneDeep(item);
                                clear(sc);
                                sObject.value = copiedItem;
                                select(sc, copiedItem);
                                closeDropdown(sc);
                            };

                            scope.handleSelect = function (item) {
                                if (scope.enabledItemPropertyName && !item[scope.enabledItemPropertyName]) {
                                    return;
                                }

                                sObject.value = item;

                                // Edit (Clear and select) or Select (if nothing to clear)
                                var postAction = scope.modelObj[scope.propertyName] ?
                                    editAndCloseDropdown : selectAndCloseDropdown;

                                if (scope.editSuccessCallback) {
                                // execute the success callback, and take in mind that it can cancel the event
                                    var editSuccessCallbackResult = scope.editSuccessCallback(sObject.value, sObject.originalValue, scope.modelObj, scope);
                                    closeDropdown(scope);
                                    if (Utils.isPromise(editSuccessCallbackResult)) {

                                        editSuccessCallbackResult.then(function (callbackResult) {

                                            if (callbackResult === false) {
                                                sObject.value = sObject.originalValue;
                                                return;
                                            }

                                            postAction(scope, item);

                                        });
                                    // if the callback returned true, update
                                    } else if (editSuccessCallbackResult) {
                                        postAction(scope, item);
                                    }
                                } else {
                                    postAction(scope, item);
                                }
                            };

                            scope.handleAdd = function () {
                                scope.elementState.showDropdown = false;

                                // call onAdd
                                if (scope.onAdd) {
                                    var addPromise = scope.onAdd();

                                    // if onAdd result is promise wait for new item and select
                                    if(Utils.isPromise(addPromise)) {
                                        addPromise.then(function(newItem) {
                                            scope.handleSelect(newItem);
                                        });
                                    }
                                }
                            };

                            scope.handleClear = function () {
                                scope.elementState.showDropdown = false;

                                if (scope.clearSuccessCallback) {
                                    var clearSuccessCallbackResult = scope.clearSuccessCallback(null, sObject.originalValue, scope.modelObj, scope);
                                    if (Utils.isPromise(clearSuccessCallbackResult)) {
                                        clearSuccessCallbackResult.then(function (res) {
                                            if (res) {
                                                clearAndCloseDropdown(scope);
                                            }
                                        });
                                        return;
                                    }
                                }
                                clearAndCloseDropdown(scope);
                            };

                            scope.handleKeyboardSelect = function(event) {

                                // don't activate when dropdown is closed
                                if(!scope.elementState.showDropdown) {
                                    return;
                                }

                                var curIdx = scope.elementState.selectedIndex;

                                // if none selected, fallback to first
                                if(curIdx === -1) {
                                    curIdx = 0;
                                }

                                var selectedItem = scope.elementState.filteredItems[curIdx];
                                if(selectedItem) {
                                    scope.handleSelect(selectedItem);

                                    if(event) {
                                        event.preventDefault();
                                    }
                                }
                            };

                            scope.navCallback = function(idxDelta) {
                                var curIdx = scope.elementState.selectedIndex;
                                curIdx = curIdx + idxDelta;

                                // don't allow range to go outside of selectable items
                                if(curIdx < -1) {
                                    curIdx = -1;
                                } else if(curIdx >= scope.elementState.filteredItems.length) {
                                    curIdx = scope.elementState.filteredItems.length -1;
                                }

                                scope.elementState.selectedIndex = curIdx;
                            };


                            scope.cancelSelect = function() {
                                closeDropdown(scope);
                            };

                            PubSubService.subscribeBodyClick(scope, function (event, data) {
                                var dropdown = $(elem).next();

                                // close when not click on element or children
                                if (dropdown[0] !== data.element && dropdown.has(data.element).length <= 0) {
                                    scope.elementState.showDropdown = false;
                                }
                            });

                            init();

                        }
                    };

                }
            };
        }
    ]);
