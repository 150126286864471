angular.module('webUi.directive.stopEvent', [])

    .directive('stopEvent', function() {
        return {
            restrict : 'A',

            /**
         * @todo Tag template: With only one argument to the directive, at the container level,
         * the children because they are transcluded they need to be self-manageable to and stopPropagation()
         *
         * @param scope
         * @param element
         * @param attr
         */
            link : function(scope, element, attr) {
                element.bind(attr.stopEvent, function(e) {
                    e.stopPropagation();
                });
            }
        };
    });
