import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

const EditableRow = ({ isVisible = false, className, children }) =>
  isVisible && <div className={cx('flex items-center gap-4', className)}>{children}</div>;

EditableRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditableRow;
