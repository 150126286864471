import seamlessImmutable from 'seamless-immutable';
import { setPromiseState } from '~/common';

const audienceActivityInitialState = seamlessImmutable({
  promise: {
    fetchingAudienceStats: setPromiseState(),
    fetchingPartnerStats: setPromiseState(),
  },
  stats: [],
  partnerStats: [],
  documentationUrl: '',
});

export default audienceActivityInitialState;
