import React from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import BtnOutlined from '~/components/src/BtnOutlined';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { FormikCheckboxField, FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { GlobalSettingsFormProps } from '../types';
import { hasDuplicates, removeTrailingCommas, validationSchema } from '../utils';

const GlobalSettingsForm = ({ initialValues, handleSubmit, showModal, isEdit = false }: GlobalSettingsFormProps) => {
  const { errorThreshold, emailsToNotify, enabled } = initialValues;

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        errorThreshold,
        emailsToNotify,
        enabled,
        emails: removeTrailingCommas(emailsToNotify).split(','),
      }}
    >
      {({ handleSubmit, errors, isValid, isSubmitting, values }) => (
        <div className="flex max-h-full flex-1 flex-col">
          <div className="flex-1 overflow-y-auto p-4">
            <Form>
              <p className="text-l py-2">{i18n.t('errorReporting:form.email')}</p>
              <FieldArray
                name="emails"
                render={arrayHelpers => (
                  <div>
                    {values.emails.map((_email, index) => (
                      <div className="flex">
                        <Field
                          key={index}
                          name={`emails[${index}]`}
                          as={FormikInputField}
                          placeholder={i18n.t('errorReporting:form.placeholder')}
                          errorText={errors?.emails?.[index]}
                          autoFocus={true}
                          className="w-5/6"
                        />
                        <div className="flex w-1/6 justify-center pt-2">
                          <BtnIcon
                            tooltip={i18n.t('errorReporting:updateTooltip')}
                            icon="delete"
                            disabled={false}
                            testHook="editGlobalSettings"
                            onClick={() => values.emails.length > 1 && arrayHelpers.remove(index)}
                          />
                        </div>
                      </div>
                    ))}

                    {!errors?.emails && hasDuplicates(values.emails) && (
                      <div className="mb-3">
                        <span className="text-red-600">{i18n.t('errorReporting:form.validation.duplicate')}</span>
                      </div>
                    )}

                    <BtnOutlined
                      testHook="addEmail"
                      icon="add"
                      onClick={() => arrayHelpers.push('')}
                      color="blue"
                      size="xs"
                      className="mb-4"
                    >
                      {i18n.t('errorReporting:form.addEmail')}
                    </BtnOutlined>
                  </div>
                )}
              />
              <hr className="pt-2" />
              <Field
                name="errorThreshold"
                type="number"
                as={FormikInputField}
                label={i18n.t('errorReporting:form.threshold')}
                placeholder={i18n.t('errorReporting:form.placeholder')}
                errorText={errors.errorThreshold}
              />
              <Field
                name="enabled"
                className="inline-flex flex-row-reverse gap-3"
                as={FormikCheckboxField}
                label={i18n.t('errorReporting:form.enabled')}
                errorText={errors.enabled}
              />
            </Form>
          </div>
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={() => showModal(false)}
            confirmText={i18n.t(`errorReporting:modal.${isEdit ? 'update' : 'create'}.action`)}
            testHook={isEdit ? 'update' : 'create'}
            isConfirmEnabled={isValid && !isSubmitting && !hasDuplicates(values.emails)}
          />
        </div>
      )}
    </Formik>
  );
};

export default GlobalSettingsForm;
