import React from 'react';

import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SensitiveInputElement from '~/components/src/Form/Elements/SensitiveInputElement';
import TextAreaElement from '~/components/src/Form/Elements/TextAreaElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import DateTimeElement from '~/components/src/Form/Elements/DateTimeElement';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import ToggleElement from '~/components/src/Form/Elements/ToggleElement';
import CodeElement from '~/components/src/Form/Elements/Code';
import CheckboxGroupElement from '~/components/src/Form/Fields/CheckboxGroupElement';

/**
 * Hack to fix a bug in Redux Form
 * How to reproduce: focus on an empty, but required input, then click on "Cancel" button. "Cancel" click event won't happen.
 * https://github.com/erikras/redux-form/issues/860
 * */
const makePatchedBlurHandler = onBlur => event => {
  if (event?.relatedTarget && event?.relatedTarget.getAttribute('preventvalidation')) {
    event.preventDefault();
  } else {
    onBlur(event);
  }
};

const makeSelectHandler = (valueKey, isConnected, onChange) => option => {
  if (valueKey) {
    onChange(isConnected ? option : option?.[valueKey]);
  } else {
    onChange(isConnected ? option : option?.value);
  }
};

/* "meta" and "input" objects are passed in by 'redux-form' */
const ReduxFormField = ({
  meta,
  input,
  type,
  label,
  formElement: Element,
  fieldType,
  horizontal,
  className,
  options,
  disabled,
  placeholder,
  hint,
  suffix,
  isConnected,
  getOptionLabel,
  getOptionValue,
  isLoading,
  htmlFor,
  testHook,
  isMulti,
  allowSelectAll,
  allOption,
  infoTooltip,
  ...props
}) => {
  const { name, value, onFocus, onBlur, onChange } = input;
  const { touched, error } = meta;

  const errorText = touched && error;
  const hasError = !!errorText;

  const patchedBlurHandler = makePatchedBlurHandler(onBlur);

  return (
    <FieldWrapper
      label={label}
      errorText={errorText}
      hintText={hint}
      horizontal={horizontal}
      className={className}
      suffix={suffix}
      htmlFor={htmlFor || name}
      name={name}
      fieldType={fieldType}
      infoTooltip={infoTooltip}
    >
      <Element
        name={name}
        type={type}
        value={value}
        hasError={hasError}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={patchedBlurHandler}
        options={options}
        isConnected={isConnected}
        disabled={disabled}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isLoading={isLoading}
        id={htmlFor || name}
        testHook={testHook}
        isMulti={isMulti}
        allowSelectAll={allowSelectAll}
        allOption={allOption}
        {...props}
      />
    </FieldWrapper>
  );
};

export const ReduxFormInputField = props => <ReduxFormField {...props} formElement={InputElement} />;

export const ReduxFormSensitiveField = props => <ReduxFormField {...props} formElement={SensitiveInputElement} />;

export const ReduxFormSelect = props => (
  <SelectElement
    {...props}
    /* Spreading options to get rid of "seamless-immutable" wrapper */
    options={[...props?.options]}
    onChange={makeSelectHandler(props?.valueKey, props?.isConnected, props?.onChange)}
    /*
      Using an empty function for onBlur to fix a 'redux-form' bug with field values vanishing unexpectedly.
      https://github.com/redux-form/redux-form/issues/2768
    */
    onBlur={() => {
      props.touch(props.name);
    }}
    /* Passing null as value clears the select */
    value={props?.value === '' ? null : props?.value}
  />
);

export const ReduxFormSelectField = props => <ReduxFormField {...props} formElement={ReduxFormSelect} />;

export const ReduxFormTextAreaField = props => <ReduxFormField {...props} formElement={TextAreaElement} />;

export const ReduxFormDateTimeField = props => (
  <ReduxFormField
    {...props}
    formElement={DateTimeElement}
    /*
      Using an empty function for onBlur to fix a 'redux-form' bug with field values vanishing unexpectedly.
      https://github.com/redux-form/redux-form/issues/2768
    */
    onBlur={() => {
      props.touch(props.input?.name);
    }}
  />
);

export const ReduxFormCodeField = props => <ReduxFormField {...props} formElement={CodeElement} />;

export const ReduxFormCheckboxField = props => (
  <ReduxFormField {...props} formElement={CheckboxElement} fieldType="inline" />
);

export const ReduxFormToggleField = props => (
  <ReduxFormField {...props} formElement={ToggleElement} fieldType="inline" />
);

export const ReduxFormCheckboxGroupField = props => <ReduxFormField {...props} formElement={CheckboxGroupElement} />;
