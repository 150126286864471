export const PAGE_SIZE = 50;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';

export const ELEMENT_TYPES = {
  TAG: 'TAG',
  EXPERIMENT: 'EXPERIMENT',
  WEBSITEPATHGROUP: 'WEBSITEPATHGROUP',
  COOKIEPERMISSION: 'COOKIEPERMISSION',
  PAGEGROUP: 'PAGEGROUP',
};

export const CHANGE_TYPES = {
  ADDED: 'Added',
  UPDATED: 'Updated',
  REMOVED: 'Removed',
};
