import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import StackedChart from '~/components/src/StackedChart';
import { Panel } from '~/components/src/Containers';
import DateRangePicker from '~/components/src/DateRangePicker';
import { getConnectorLogoUrl } from '~/profiles/helpers/connectorsHelper';
import { buildUrl } from '~/common/history';
import { getConnectorListPath } from '~/profiles/connectors/containers/commonActions';
import { SERVER_CONNECTOR_CATEGORY, EVENT_CONNECTOR_CATEGORY } from '~/profiles/connectors/types';
import '~/profiles/components/DataExchangeGraph/documentationLink.scss';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';
import './styles.scss';
import TransformationErrors from './TransformationErrors';
import ConnectorErrors from './ConnectorErrors';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const Stats = ({ partnerStats, documentationUrl, t, startDate, endDate, handleDatesChange }) => (
  <Panel>
    <Heading title="Statistics" kind="h3" className="Connector-statsHeading u-paddingTopNone">
      <DateRangePicker startDate={startDate} endDate={endDate} handleDatesChange={handleDatesChange} />
    </Heading>
    <div className="DocumentationLink-chartContainer">
      <StackedChart data={partnerStats || {}} />
      {documentationUrl && (
        <div className="DocumentationLink-linkContainer">
          <LinkIcon
            icon="document"
            url={documentationUrl}
            tooltip={t('audiences:chart.documentationLink')}
            target="_blank"
          />
        </div>
      )}
    </div>
  </Panel>
);

export class ConnectorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: defaultDate.startDate,
      endDate: defaultDate.endDate,
    };
    this.fetchPartnerStatsData = this.fetchPartnerStatsData.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
  }

  fetchPartnerStatsData() {
    const { connector, fetchPartnerStats } = this.props;
    const { startDate, endDate } = this.state;
    fetchPartnerStats(
      connector?.partnerId,
      connector?.partnerType,
      null,
      startDate.startOf('day').toDate().getTime(),
      endDate.endOf('day').toDate().getTime(),
    );
  }

  componentDidMount() {
    this.props.fetchData().then(() => {
      this.fetchPartnerStatsData();
    });
  }

  handleDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate }, () => {
      this.fetchPartnerStatsData();
    });
  }

  render() {
    const {
      canEdit,
      connector,
      editLink,
      onDeleteClick,
      t,
      partnerStats,
      areStatsReady,
      isEventConnector,
      documentationUrl,
      connectorId,
    } = this.props;
    const { startDate, endDate } = this.state;

    if (!connector) {
      return <Spinner />;
    }

    const goBackPath = getConnectorListPath(isEventConnector ? EVENT_CONNECTOR_CATEGORY : SERVER_CONNECTOR_CATEGORY);

    const crumbs = [
      {
        title: t('partners:backToList'),
        pathname: buildUrl(goBackPath),
      },
    ];

    const renderConnectorErrorTabs = connectorId => (
      <Tabs>
        <TabList>
          <Tab testHook="tabConnectorErrors">{t('connectors:errorStats.connectorErrorsTitle')}</Tab>
          {isEventConnector && <Tab testHook="tabTransformationErrors">{t('connectors:errorStats.title')}</Tab>}
        </TabList>

        <TabPanel>
          <ConnectorErrors connectorId={connectorId} />
        </TabPanel>
        {isEventConnector && (
          <TabPanel>
            <TransformationErrors connectorId={connectorId} />
          </TabPanel>
        )}
      </Tabs>
    );

    return (
      <Page>
        <Heading
          title={connector.partnerName}
          crumbs={crumbs}
          img={getConnectorLogoUrl(connector.partnerId, connector.partnerType)}
          deactivated={!connector.activated}
          badge={connector.partnerNumber}
        >
          {canEdit && (
            <div className="flex gap-4">
              <BtnIcon
                icon="delete"
                tooltip={t('view.actions.delete')}
                testHook="deleteConnectorButton"
                onClick={() => onDeleteClick(connector)}
              />
              <LinkIcon
                icon="edit"
                tooltip={t('view.actions.edit')}
                testHook="edit"
                url={editLink}
                className="h-8 w-8"
              />
            </div>
          )}
        </Heading>
        {areStatsReady ? (
          <Stats
            startDate={startDate}
            endDate={endDate}
            handleDatesChange={this.handleDatesChange}
            partnerStats={partnerStats}
            documentationUrl={documentationUrl}
            t={t}
          />
        ) : (
          <Spinner />
        )}
        {renderConnectorErrorTabs(connectorId)}
      </Page>
    );
  }
}

ConnectorPage.propTypes = {
  canEdit: PropTypes.bool,
  connector: PropTypes.object,
  crumbs: PropTypes.array.isRequired,
  editLink: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchPartnerStats: PropTypes.func.isRequired,
  partnerStats: PropTypes.object,
  areStatsReady: PropTypes.bool,
  connectorId: PropTypes.string,
};

export default ConnectorPage;
