import React from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, useAPI } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { ReduxFormInputField, ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { fetchAdvertisers, fetchPayloadTypes } from './dataService';
import { YahooFormProps } from './types';

const YahooForm = ({ t, change, touch, values, partner, mode, canUpdate: isEditMode }: YahooFormProps) => {
  const { data: advertisers, isLoading: isAdvertisersLoading } = useAPI(() => fetchAdvertisers());
  const { data: payloadTypes, isLoading: isPayloadTypesLoading } = useAPI(() => fetchPayloadTypes());

  if (isAdvertisersLoading || isPayloadTypesLoading || !advertisers || !payloadTypes) return <Spin />;

  if (isEditMode && !values.advertiser) {
    change('YAHOO.advertiser', advertisers.find(advertiser => advertiser.mdmId === values.mdmId)?.mdmId);
  }

  const isPartnerNumberFieldVisible =
    mode === constants.modes.CREATE_SERVER &&
    values.payloadType &&
    payloadTypes.length > 0 && // Makes sure payloadTypes are loaded before accessing them
    payloadTypes.find(payloadType => payloadType.value === values.payloadType)?.allowsPartnerNumberOverride;

  return (
    <FormSection name={constants.partnerTypes.YAHOO}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <div className="flex flex-col gap-4">
          <Field
            label={t('form.formFields.advertiser')}
            name="advertiser"
            component={ReduxFormSelectField}
            options={advertisers}
            validate={isRequired}
            onChange={(event, mdmId) => {
              change('YAHOO.mdmId', advertisers.find(advertiser => advertiser.mdmId === mdmId)?.mdmId);
            }}
            touch={touch}
          />
          <Field
            label={t('form.formFields.mdmId')}
            name="mdmId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            disabled={true}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            disabled={isEditMode}
            onChange={(event, value) => {
              change(
                'YAHOO.partnerNumber',
                payloadTypes.find(payloadType => payloadType.value === value)?.partnerNumber,
              );
            }}
            touch={touch}
          />
          {isPartnerNumberFieldVisible && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
          )}
        </div>
      </ContainerFormSection>
    </FormSection>
  );
};

export default YahooForm;
