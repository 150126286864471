import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MediaLibrariesPage from '~/content/mediaLibraries/MediaLibrariesPage';
import DataFeedList from '~/content/datafeeds/DataFeedList';
import CampaignsPages from './campaigns';

const ContentPages = (): React.ReactNode => (
  <Routes>
    <Route path="campaigns2/*" element={<CampaignsPages />} />
    <Route path="datafeeds/*" element={<DataFeedList />} />
    <Route path="medialibraries/dashboard" element={<MediaLibrariesPage isDashboardPage={true} />} />
    <Route path="medialibraries/view/:mediaLibraryId" element={<MediaLibrariesPage />} />
  </Routes>
);

export default ContentPages;
