import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import * as fromModals from '~/modals/index';
import { fetchGroup, deleteGroup } from '~/support/tagtemplates/groups/actions';
import * as selectors from '~/support/tagtemplates/groups/selectors';
import TagTemplatesList from '~/support/components/TagTemplatesList';
import Detail from '~/components/src/Form/Detail/Default';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { actionCreators } from 'react-redux-composable-list';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';

const TEMPLATES_KEY = 'SUPPORT/TAG_TEMPLATES';
const FILTER_KEY = 'SUPPORT/TAG_TEMPLATES_FILTER';

const titleFilterFn = query => item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

export class TagTemplateGroup extends Component {
  componentDidMount() {
    const { fetchGroup, id, resetFilter } = this.props;
    fetchGroup(id);
    resetFilter();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && this.props.id !== nextProps.id) {
      this.props.fetchGroup(nextProps.id);
      this.props.resetFilter();
    }
  }

  render() {
    const { props } = this;
    const { group, t, newTemplateHref, editGroupHref, onDeleteClick, onFilterChange } = props;
    const { canDelete, stateKey, templateUrl } = props;

    return group ? (
      <Page>
        {/** Group Information * */}

        <Heading title={t('group.header')}>
          <BtnIcon
            disabled={!canDelete}
            onClick={() => onDeleteClick(group, t)}
            testHook="delete"
            tooltip={t('group.actions.remove')}
            icon="delete"
          />
          <LinkIcon url={editGroupHref} testHook="edit" tooltip={t('group.actions.edit')} icon="edit" />
        </Heading>

        <Detail className="u-marginBottomXxl" label={t('group.name')} value={group.name} />

        {/** Templates belonging to Group * */}

        <Heading kind="h2" title={t('templatesList.header')} testHook="templatesList">
          <SearchElement
            onChange={e => onFilterChange(e.target.value)}
            placeholder={t('templatesList.actions.search')}
          />
          <LinkIcon
            color="blue"
            url={newTemplateHref}
            testHook="addTemplate"
            tooltip={t('templatesList.actions.add')}
            icon="add"
          />
        </Heading>
        <TagTemplatesList
          {...{
            list: group.templates,
            stateKey,
            templateUrl,
            t,
          }}
        />
      </Page>
    ) : (
      <Spinner />
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps?.params.groupId;
      return {
        id,
        editGroupHref: buildUrl(`support/tagtemplates/groups/edit/${id}`),
        newTemplateHref: buildUrl(`support/tagtemplates/templates/add?groupId=${id}`),
        group: selectors.getGroup(state, id),
        canDelete: selectors.canDeleteGroup(state, id),
        stateKey: TEMPLATES_KEY,
        templateUrl: templateId => buildUrl(`support/tagtemplates/templates/view/${templateId}`),
      };
    },
    dispatch => ({
      fetchGroup: id => dispatch(fetchGroup(id)),
      resetFilter: () => dispatch(actionCreators.doResetFilter(TEMPLATES_KEY)),
      onFilterChange: query =>
        query !== ''
          ? dispatch(actionCreators.doSetFilter(TEMPLATES_KEY, FILTER_KEY, titleFilterFn(query)))
          : dispatch(actionCreators.doRemoveFilter(TEMPLATES_KEY, FILTER_KEY)),
      onDeleteClick: (group, t) => {
        dispatch(
          fromModals.showModal(fromModals.DELETE_MODAL, {
            title: t('group.deleteModal.title'),
            message: t('group.deleteModal.message', { name: group.name }),
            onConfirm: () => dispatch(deleteGroup(group.groupId)),
          }),
        );
      },
    }),
  ),
  translate(['tagTemplates']),
)(TagTemplateGroup);
