angular.module('webui.ui.context.users.view', [
    'webUi.ui.context.users.base',
    'ui.bootstrap'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.users.view', {
                url: '/view/{userId}',
                isReact: true,
            });
    }]);