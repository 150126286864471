import * as types from '../types';
import * as selectors from '../selectors';
import CookiesVariantsService from '../dataService';

/**
 * Fetch all cookie permission templates
 */
export const fetchVariants = () => (dispatch, getState) => {
  if (selectors.isFetchingVariants(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: types.COOKIES_FETCH_START,
  });

  return CookiesVariantsService.getVariants()
    .then(payload => {
      dispatch({
        type: types.COOKIES_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.COOKIES_FETCH_FAIL, error }));
};

/**
 * Fetch cookie permission templates only if not fetched yet
 */
export const fetchVariantsIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchVariants(getState())) {
    return dispatch(fetchVariants());
  }
  return Promise.resolve();
};
