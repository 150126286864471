// Module
var code = `<div data-ng-mouseenter="state.focused = true" data-ng-mouseleave="state.focused = false"> <div class="row"> <div class="col-md-12 btn-switch"> <div class="btn-switch-container pull-left"> <div data-ng-repeat="option in selectOptions track by \$index" data-ng-click="selectOption(option)" title="{{option.template}}" class="btn-switch-option pull-left" data-ng-class="{
						active: option.value === state.selection.type,
						inactive: option.value !== state.selection.type,
						static: option.value === 'static',
						variable: option.value === 'variable',
						extracted: option.value === 'extracted',
						property: option.value === 'property'
					}">{{option.label}} </div> </div> <div class="btn-switch-input pull-left"> <div data-ng-if="state.selection.type !== 'variable'"> <div data-dynamic-name="r42ModelName" data-valid data-type="input" data-input-class="input-large" data-placeholder="{{r42Placeholder}}" data-ng-blur="deactivate(\$event)" data-ng-model="state.selection.value" data-custom-watch="state.selection.value" data-ui-validate="validate" data-custom-validate="customValidate"> </div> </div> </div> <div data-ng-if="state.selection.type === 'variable'" class="col-md-4"> <data-searchable-select data-dynamic-name="r42ModelName" data-ng-model="state.selection.value" data-ui-validate="validate" data-ui-validate-watch="'state.selection.type'" data-items="r42Variables" data-options="{label:'profileVarName', value:'profileVarName'}" data-is-clearable="true" data-show-search="true"> </data-searchable-select> </div> </div> </div> <div class="row"> <div data-ng-if="state.selection.type === 'property'" class="col-md-12"> <div data-ng-if="state.availableProperties.length == 0">No recorded properties available</div>&nbsp; <div data-ng-if="state.availableProperties.length > 0">Available properties:</div>&nbsp; <div data-ng-if="state.availableProperties.length > 0" data-ng-repeat="prop in state.availableProperties track by \$index"> <a class="btn btn-secondary pull-left" data-ng-click="state.selection.value = prop">{{prop}}</a> </div> </div> </div> </div> `;
// Exports
var _module_exports =code;;
var path = 'directive/support/template/propertySelection/propertySelection.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;