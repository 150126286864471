/**
 * Directive which renders a tree of nodes
 * Tree has search capabilities, callbacks on initialize, add, remove node
 */
angular.module('webUi.directive.tree', [
    'webUi.directive.tree.add'
])
/**
 *
 */
    .directive('tree', ['$filter', 'TreeService', 'ValidService', 'ModalManagementService','Utils', function($filter, TreeService, ValidService, ModalManagementService, Utils) {
        return {
            restrict: 'E',
            scope: {
                nodes: '=',
                filter: '=',
                vars: '&',
                dragOpts: '&',
                dropOpts: '&',
                canEdit: '=',
                canDelete: '='
            },
            templateUrl: 'directive/tree/tree.tpl.html',

            link: function(scope) {
                var filter = $filter('treeFilter');

                scope.initializeNode = function (node) {
                    node.tree = scope.nodes;
                    node.visible = true;
                    node.expanded = !!node.expanded;
                };

                scope.assignParent = function(node, parentNode) {
                    node.parent = parentNode;
                };

                //retry the vars property which contains callbacks and drag'n'drop information
                scope.localVars = scope.vars() || {};

                scope.onAddNode = scope.localVars.onAddNode;
                scope.onNodeExpand = scope.localVars.onNodeExpand;

                scope.dragOptions = scope.dragOpts() || {};
                scope.dropOptions = scope.dropOpts() || {};
                //fetch the onDrop function from the parent scope
                if (!_.isUndefined(scope.dropOptions.onDrop)) {
                    scope.onDrop = scope.$parent[scope.dropOptions.onDrop];
                }

                /**
             * Function that opens a modal for adding a new path as child of activeNode
             * @param parentNode - the currently selected node that will be parent to the added one (can be undefined)
             */
                scope.addNode = function(parentNode) {

                    if(_.isFunction(scope.onAddNode)) {
                        var onAddNodeCallbackResult = scope.onAddNode(parentNode);
                        if ( Utils.isPromise(onAddNodeCallbackResult) ) {
                            onAddNodeCallbackResult.then(function(result) {
                            // if callback result is false, don't proceed with the adding
                                if ( result !== false ){
                                    if(_.isUndefined(parentNode)) {
                                        parentNode = scope.nodes;
                                    }
                                    TreeService.addNode(parentNode, result);
                                    parentNode.expanded = true;
                                }
                            });
                        } else if ( onAddNodeCallbackResult !== false ){
                        //add the node to the tree structure
                            if(_.isUndefined(parentNode)) {
                                parentNode = scope.nodes;
                            }
                            TreeService.addNode(parentNode, onAddNodeCallbackResult);
                            parentNode.expanded = true;
                        }
                    } else {
                    //default popup to add a simple node
                        var dialogsModel = function() {
                            return {
                                parentNode: parentNode,
                                onSave: function(node) {
                                    if ( _.isUndefined(parentNode) ) {
                                    // no active node, so we are at the root of the tree, make the tree the active one
                                        parentNode = scope.nodes;
                                    }
                                    TreeService.addNode(parentNode, node);
                                    parentNode.expanded = true;
                                }
                            };
                        };
                        ModalManagementService.openDialog(
                            'AddNodeDialogController',
                            'directive/tree/addNode/addNode.tpl.html',
                            dialogsModel,
                            {'css': 'modal-small'}
                        );
                    }
                };

                /**
             * Expand node and call the optional onNodeExpand callback
             * @param node
             */
                scope.toggleNodeExpand = function(node) {
                    node.expanded = !node.expanded;
                    //firstExpanded keeps track of whether this node has been expanded already, so no need to call onNodeExpand again
                    if(scope.onNodeExpand && !node.firstExpanded) {
                        var onNodeExpandCallbackResult = scope.onNodeExpand(node.id);
                        if (Utils.isPromise(onNodeExpandCallbackResult)) {
                            onNodeExpandCallbackResult.then(function (subTree) {
                                node.children = subTree;
                            });
                        // if the callback returned true, update
                        } else if (onNodeExpandCallbackResult) {
                            node.children = onNodeExpandCallbackResult;
                        }
                        node.firstExpanded = true;
                    }
                };

                /**
             * Check if there's an onRemove callback attached to the node and call it
             * Only when the onRemove returns a valid result (aka not false) are we allowed to remove the node from the tree
             * @param node
             */
                scope.deleteNode = function(node) {

                    TreeService.removeNodeWithCallback(node);
                };

                //trigger filtering manually when the value actually changes
                scope.$watch('filter', function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        filter( scope.nodes, newValue );
                    }
                }, true);

                scope.getNodeIcon = function(node) {
                    if(_.isEmpty(node.children)) {
                        return node.closeIcon || 'folder';
                    } else {
                        if(node.expanded) {
                            return node.openIcon || 'folderOpen';
                        } else {
                            return node.closeIcon || 'folderFilled';
                        }
                    }
                };

                scope.TreeService = TreeService;
                scope.isEmpty = ValidService.isEmpty;
            }
        };
    }]);
