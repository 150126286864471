import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { DateRangePickerInputController, DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { Range } from '../types';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

type HPDatePickerProps = {
  range: Range;
  setRange: (num: Range) => void;
  maxSelectionDays: number;
  maxAllowedDays: number;
};

const HPDatePicker = ({ range, setRange, maxSelectionDays, maxAllowedDays }: HPDatePickerProps): JSX.Element => {
  const [_focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(START_DATE);
  const focusedInput = _focusedInput || START_DATE;
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (typeof maxSelectionDays === 'number') {
      setRange({
        startDate: moment()
          .subtract(maxSelectionDays - 1, 'days')
          .startOf('day'),
        endDate: moment(),
      });
    }
  }, [maxSelectionDays]);

  const isOutsideRange = (day: moment.Moment): boolean => {
    if (day === null) {
      return false;
    }
    const before = day.startOf('day').isBefore(moment().subtract(maxAllowedDays, 'days'));
    const after = day.endOf('day').isAfter(moment().endOf('day'));
    return !!(before || after);
  };

  const onDatesChange = ({ startDate, endDate }: Range) => {
    const daysCount = moment.duration(endDate?.diff(startDate)).asDays() || 1;
    let adjustedStartDate = moment(startDate || endDate).startOf('day');
    let adjustedEndDate = moment(endDate || startDate).endOf('day');
    if (focusedInput === START_DATE && daysCount > maxSelectionDays - 1) {
      adjustedEndDate = moment(adjustedStartDate)
        .add(maxSelectionDays - 1, 'days')
        .endOf('day');
    } else if (daysCount < 0 || (focusedInput === END_DATE && daysCount > maxSelectionDays - 1)) {
      // Subtract 1 so the diff b/w start and end dates are 31
      adjustedStartDate = moment(adjustedEndDate)
        .subtract(maxSelectionDays - 1, 'days')
        .startOf('day');
    }
    // Do not allow selecting future dates
    if (adjustedEndDate.isAfter(moment())) adjustedEndDate = moment();

    setRange({
      startDate: adjustedStartDate.startOf('day'),
      endDate: adjustedEndDate,
    });
  };

  return (
    <>
      <div
        className="mx-auto my-0 mt-6 w-[300px]"
        onBlur={() => {
          setIsFocused(!isFocused);
        }}
      >
        <DateRangePickerInputController
          startDate={range.startDate}
          endDate={range.endDate}
          displayFormat="DD/MM/YYYY"
          onFocusChange={(value: FocusedInputShape | null) => setFocusedInput(value)}
          isOutsideRange={(day: any) => isOutsideRange(day)}
          onDatesChange={(range: any) => onDatesChange(range)}
          isFocused={isFocused}
        />
        <div className="flex">
          <div
            className={cx('ml-[36px] mr-[22px] flex-1 -translate-y-2 border-2 border-transparent', {
              'border-blue-300': focusedInput === START_DATE,
            })}
          ></div>
          <div
            className={cx('ml-[22px] mr-[36px] flex-1 -translate-y-2 border-2 border-transparent', {
              'border-blue-300': focusedInput === END_DATE,
            })}
          ></div>
        </div>
      </div>
      <div className="flex justify-center p-1">
        <DayPickerRangeController
          startDate={range.startDate}
          endDate={range.endDate}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          hideKeyboardShortcutsPanel
          numberOfMonths={2}
          minDate={moment().subtract(maxAllowedDays, 'days')}
          maxDate={moment()}
          onFocusChange={(value: FocusedInputShape | null) => setFocusedInput(value)}
          isOutsideRange={(day: any) => isOutsideRange(day)}
          onDatesChange={(range: any) => onDatesChange(range)}
          focusedInput={focusedInput}
          firstDayOfWeek={1}
          daysViolatingMinNightsCanBeClicked
          noBorder
        />
      </div>
    </>
  );
};

export default HPDatePicker;
