/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class SiteInfoClass {
  /**
   * Get basic information for current page
   * TODO: should not be required to pass site number
   * @param siteNumber
   */
  getSiteInfo(siteNumber) {
    return (
      Api.callGet(`${API_BASE}/admin/getSite/${siteNumber}`)
        // Fix lack of GENERAL site module in the backend response
        // use response => response.data once it's fixed
        .then(response =>
          Object.assign(response.data, {
            siteModules: [...((response.data && response.data.siteModules) || []), 'GENERAL'],
          }),
        )
    );
  }

  /**
   * Edit Site Details
   * (Currently only restrictedSubnets is possible)
   * @param model
   */
  updateSite(model) {
    return Api.callPost(`${API_BASE}/admin/security/restrictedSubnets`, model).then(result => result.data);
  }
}

const siteInfoService = new SiteInfoClass();
export default siteInfoService;
