import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common/history';
import { getSiteId } from '~/common/SecurityMetaService';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { MediumWrapper } from '~/components/src/Containers';
import { fetchSiteInfo, getSiteInfo } from '~/admin/site';
import SiteDetail from '~/admin/components/SiteDetail';
import LinkIcon from '~/components/src/LinkIcon';

export class SiteSecurityPage extends Component {
  componentDidMount() {
    this.props.fetchSiteInfo();
  }

  render() {
    const { t, site, isSiteAdmin, editSiteHref } = this.props;
    const ts = t('common:checkbox', { returnObjects: true });
    return (
      <Page>
        <MediumWrapper>
          {/* Site Basic Information */}
          <Heading title={t('info.header')}>
            {!isSiteAdmin ? null : (
              <LinkIcon url={editSiteHref} tooltip={t('info.actions.edit')} icon="edit" className="h-8 w-8" />
            )}
          </Heading>
          <SiteDetail t={t} ts={ts} site={site} />
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      site: getSiteInfo(state),
      editSiteHref: buildUrl('admin/sitesecurity/edit'),
    }),
    dispatch => ({
      fetchSiteInfo: () => dispatch(fetchSiteInfo(getSiteId())),
    }),
  ),
  translate(['site']),
)(SiteSecurityPage);
