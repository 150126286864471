angular.module('webUi.ui.support.cookiepermissionforms.list', [
    'webUi.ui.support.cookiepermissionforms.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.cookiepermissionforms.list', {
                url: '/list',
                isReact: true,
                views : {
                    'mainContent@' : {
                        controller : 'CookiePermissionFormsListController'
                    }
                }
            });
    }])

    .controller('CookiePermissionFormsListController', ['$scope', '$state',
        function CookiePermissionFormsListController ($scope, $state){
            $scope.$on('hotKeyEvent', function onHotKeyEvent(event, data) {
                // activate newMessage on n
                if (!data.altKey && data.charLower === 'n') {
                    $state.transitionToStateWithSite('site.support.cookiepermissionforms.add', {});
                }
            });
        }]);
