import React from 'react';
import PropTypes from 'prop-types';
import Detail from '~/components/src/Form/Detail/Default';
import Details from '~/components/src/Form/Detail';
import Spinner from '~/components/src/Spinner';
import { Panel } from '~/components/src/Containers';

export const SiteDetail = ({ site, t, ts }) =>
  !site ? (
    <Spinner />
  ) : (
    <Panel>
      <Details.Bool label={t('info.advancedSecurity')} value={site.advancedSecurity} translations={ts} />
      <Detail label={t('info.client')} value={site.clientName} />
      <Detail label={t('info.restrictedSubnets.label')} value={site.restrictedSubnets} />
    </Panel>
  );

SiteDetail.propTypes = {
  t: PropTypes.func.isRequired,
  ts: PropTypes.object.isRequired,
  site: PropTypes.object,
};

export default SiteDetail;
