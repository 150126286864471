angular.module('webUi.ui.content.campaigns.ads.view.adRules', [
    'webUi.service.time'
])

    .controller('AdRulesController', ['$scope', '$uibModal', 'TimeService', function AdRulesController($scope, $uibModal, TimeService) {

        $scope.status = {
            showRules: true
        };

        var vm = this;
        vm.isEmptyObject = _.isEmpty;
    
        vm.convertTz = function convertTz(time) {
            return TimeService.convertWithTz(time, TimeService.CONVERSION_DIRECTION.FROM_SITE_TZ_TO_LOCAL_TZ);
        };
    
        vm.getSegmentName = function getSegmentName(segmentId) {
            var segment = $scope.segmentMap[segmentId];
            return segment ? segment.name : segmentId;
        };
    
        vm.openConditionDialog = function openConditionDialog(ad) {
            $uibModal.open({
                backdrop: 'static',
                keyboard: true,
                windowClass: 'modal modal-huge',
                templateUrl: 'ui/content/campaigns/ads/conditions/configure.tpl.html',
                controller: 'AdServingConditionConfigureDialogController',
                resolve: {
                    dialogsModel: function () {
                        return {
                            ad: ad
                        };
                    }
                }
            });
        };
    }]);
