import React from 'react';
import cx from 'classnames';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { DestinationOption } from '~/profiles/connectors/form/partners/facebookConversions/core/transformerTypes';
import { removeEmptyValueTransformations } from '~/profiles/connectors/form/partners/facebookConversions/core/utils';
import { I18NextT } from '~/components/src/Common/types';
import BtnIcon from '~/components/src/BtnIcon';
import { MappingProps } from './types';
import { Sources } from '../core/transformerTypes';
import './Mapping.scss';

const getSelectOptionsForPredefinedSources = (sources: string[], selectedSourceName: string) => {
  const isExisitingEventProperty = sources.includes(selectedSourceName);

  const userCreatedSource = !isExisitingEventProperty && selectedSourceName !== '' ? [selectedSourceName] : [];

  const sourcesForSelect = [...userCreatedSource, ...sources].map(source => ({
    label: source,
    value: source,
    description: source,
  }));

  const selectedSourceOption =
    selectedSourceName === '' ? null : sourcesForSelect.find(option => option.value === selectedSourceName);

  return {
    sourcesForSelect,
    selectedSourceOption,
  };
};

const getSelectOptionsForSources = (sources: Sources, selectedSourceName: string, t: I18NextT) => {
  const eventPropertyOptions = sources.eventProperties.map(eventPropertyName => ({
    label: eventPropertyName,
    value: eventPropertyName,
    description: eventPropertyName,
  }));

  const internalEventPropertyOptions = sources.internalEventProperties.map(({ label, name, description }) => ({
    label,
    value: name,
    description,
  }));

  const isExisitingEventProperty = !![...eventPropertyOptions, ...internalEventPropertyOptions].find(
    option => option.value === selectedSourceName,
  );

  const potentialUserCreatedSource = [{ label: selectedSourceName, value: selectedSourceName }];
  const userCreatedSource =
    !isExisitingEventProperty && selectedSourceName !== ''
      ? [{ label: t('partners:eventConnector.customProperties'), options: potentialUserCreatedSource }]
      : [];

  const sourcesForSelect = [
    ...userCreatedSource,
    { label: 'Event properties', options: eventPropertyOptions },
    { label: 'Standard properties', options: internalEventPropertyOptions },
  ];

  const allOptions = [
    ...(userCreatedSource.length ? userCreatedSource[0].options : []),
    ...eventPropertyOptions,
    ...internalEventPropertyOptions,
  ];

  const selectedSourceOption = allOptions.find(option => option.value === selectedSourceName) || null;

  return {
    sourcesForSelect,
    selectedSourceOption,
  };
};

export const MappingListHeader = ({ t }: { t: I18NextT }): JSX.Element => (
  <div className="MappingListHeader">
    <div className="MappingListHeader-item MappingListHeader-destination">
      {t('partners:eventConnector.destinationProperty')}
    </div>
    <div className="MappingListHeader-item MappingListHeader-source">{t('partners:eventConnector.sourceProperty')}</div>
    <div className="MappingListHeader-item MappingListHeader-transformations">
      {t('partners:eventConnector.transformations')}
    </div>
    <div className="MappingListHeader-item MappingListHeader-required">{t('partners:eventConnector.required')}</div>
    <div className="MappingListHeader-item MappingListHeader-deleteButtonSpace"></div>
  </div>
);

// TODO: Specifying "any" for props type for now. We'll need to upgrade react-select to get proper TS support.
const OptionWithDescription = (props: Record<string, any>) => {
  const { className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
  const { name, label } = props;

  return (
    <div
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      {...innerProps}
    >
      {label ? (
        <div>
          <div className="Mapping-destinationOptionName">{name}</div>
          {name !== label && <div>{label}</div>}
        </div>
      ) : (
        <div className="t-customOption">
          Custom: <span className="Mapping-destinationOptionName">{name}</span>
        </div>
      )}
    </div>
  );
};

const DestinationSelectOption = (props: Record<string, any>) => (
  <OptionWithDescription {...props} name={props.data.name} label={props.data.label} />
);

const SourceSelectOption = (props: Record<string, any>) => (
  <OptionWithDescription {...props} name={props.data.label} label={props.data.description} />
);

const Mapping = ({
  mapping,
  remove,
  mappingPath,
  getValidationErrorByPath,
  validationErrors,
  sources,
  availableDestinations,
  groupedDestinations,
  openValueTransformationsModal,
  wasSubmitClicked,
  t,
}: MappingProps): JSX.Element => {
  const { sourcesForSelect, selectedSourceOption } = Array.isArray(sources)
    ? getSelectOptionsForPredefinedSources(sources, mapping.sourceName)
    : getSelectOptionsForSources(sources, mapping.sourceName, t);

  const isExistingDestination = availableDestinations.find(
    destination => destination.name === mapping.destination.name,
  );

  const potentialUserCreatedDestination: DestinationOption[] = [
    { name: mapping.destination.name, label: mapping.destination.label, element: null, isUsed: false },
  ];

  const userCreatedDestination =
    !isExistingDestination && mapping.destination.name !== ''
      ? [{ label: t('partners:eventConnector.destination.customData'), options: potentialUserCreatedDestination }]
      : [];

  const destinationsForSelect = [...userCreatedDestination, ...groupedDestinations];

  const selectedDestination =
    [...potentialUserCreatedDestination, ...availableDestinations].find(
      destination => destination.name === mapping.destination.name,
    ) || null;

  const usedDestinationNames = groupedDestinations.flatMap(group => group.options).map(option => option.name);

  const hasValueMappingErrors = Object.keys(validationErrors).some(errorPath =>
    errorPath.includes(`${mappingPath}.valueTransformations`),
  );

  return (
    <div className="Mapping">
      <div className="Mapping-item Mapping-destination">
        <SelectElement
          isCreatable
          components={{ Option: DestinationSelectOption }}
          /*  Setting null as value tells react-select that nothing is selected, so it shows "Select.." placeholder */
          value={selectedDestination?.name ? selectedDestination : null}
          options={destinationsForSelect}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.name}
          onChange={selectedOption => {
            mapping.setDestination(selectedOption.name);
          }}
          className="Mapping-destinationSelect"
          disabled={!mapping.isDeletable}
          isOptionDisabled={(option: DestinationOption) => option.isUsed}
          isValidNewOption={inputValue => !usedDestinationNames.includes(inputValue)}
          formatGroupLabel={true}
          formatCreateLabel={() => t('partners:eventConnector.destination.customData')}
          getNewOptionData={(inputValue: string, optionLabel: string) =>
            inputValue
              ? {
                  label: optionLabel,
                  options: [{ name: inputValue }],
                  __isNew__: true,
                }
              : null
          }
          hasError={!!(wasSubmitClicked && getValidationErrorByPath(`${mappingPath}.destination.name`))}
        />
      </div>
      <div className="Mapping-item Mapping-source">
        <SelectElement
          isCreatable={mapping.allowsCustomSources}
          components={{ Option: SourceSelectOption }}
          value={selectedSourceOption}
          options={sourcesForSelect}
          onChange={selectedOption => {
            mapping.setSourceName(selectedOption.value);
          }}
          hasError={!!(wasSubmitClicked && getValidationErrorByPath(`${mappingPath}.sourceName`))}
          formatGroupLabel={true}
          formatCreateLabel={() => t('partners:eventConnector.customProperties')}
          getNewOptionData={(inputValue: string, optionLabel: string) =>
            inputValue
              ? {
                  label: optionLabel,
                  options: [{ label: inputValue, value: inputValue }],
                  __isNew__: true,
                }
              : null
          }
          className="Mapping-sourceSelect"
        />
      </div>
      <div className="Mapping-item Mapping-transformations flex items-center gap-2">
        <span className={cx({ 'text-red-600': hasValueMappingErrors })}>
          {t('partners:eventConnector.valueTransformationsApplied', {
            valueTransformationsCount: removeEmptyValueTransformations(mapping.valueTransformations).length,
          })}
        </span>
        <BtnIcon
          icon="edit"
          testHook="configureTransformationsButton"
          className={cx({ invalid: hasValueMappingErrors }, 'h-5 w-5 p-0.5')}
          onClick={() => {
            openValueTransformationsModal(mappingPath);
          }}
        />
      </div>
      <div className="Mapping-item Mapping-required">
        <CheckboxElement
          value={mapping.required}
          disabled={!mapping.isDeletable}
          onChange={() => {
            mapping.setRequired(!mapping.required);
          }}
        />
      </div>
      <div
        className={cx('Mapping-item', {
          'EventConnectorTransformationsPage-deleteButtonContainer--hidden': !mapping.isDeletable,
        })}
      >
        <BtnIcon
          className="EventConnectorTransformationsPage-deleteButton h-5 w-5"
          icon="close"
          tooltip={t('common:actions.delete')}
          onClick={remove}
        />
      </div>
    </div>
  );
};

export default Mapping;
