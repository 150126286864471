(function() {
    'use strict';

    angular
        .module('webUi.directive.scrollUp', [])
        .directive('scrollUp', scrollUp);

    scrollUp.$inject = [];

    function scrollUp() {

        var link = function (scope, $element) {
            var $scrollTarget = angular.element(scope.scrollTarget);
            var $scrollContainer = angular.element(scope.scrollContainer);

            // Bind events
            $element.on('click', scrollToElement);
            scope.$on('$destroy', onDestroy);

            /**
             * Scroll to element on click event
             */
            function scrollToElement () {
                // if element not in viewport
                if ($scrollTarget.offset().top < 0) {
                    // scroll up
                    $scrollContainer.scrollTo(scope.scrollTarget, 200);
                }
            }

            /**
             * Clean up
             */
            function onDestroy() {
                $element.off('click', scrollToElement);
            }
        };

        return {
            scope: {
                scrollTarget: '@scrollUp',
                scrollContainer: '@scrollUpContainer'
            },
            restrict: 'A',
            link: link
        };

    }

})();
