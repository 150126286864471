angular.module('webUi.directive.sorthandle', [])
	
    .directive('sorthandle', ['$compile', 'ReflectionService', function ($compile, ReflectionService) {
        var directiveDefinitionObject = {
		
            restrict : 'A',
            scope : {
                handle : '=sorthandle'
            },
            templateUrl : 'directive/sorthandle/sorthandle.tpl.html',
            transclude: true,
            link: function (scope, element, attrs) {
					
                // init handle object if undefined and share with parent scope
                if(typeof scope.handle === 'undefined') {
                    scope.handle = ReflectionService.setNestedProperty(scope.$parent, attrs.sorthandle, {});
                }
			
                // change ordering function
                scope.changeOrder = function(field, defaultReverse) {
                    if(scope.handle.field === field) {
                        scope.handle.reverse = !scope.handle.reverse;
                    } else {
                        scope.handle.field = field;
                        scope.handle.reverse = defaultReverse;
                    }
                };
			
                // add field and reverse to scope
                scope.field = attrs.sorthandleField;
                if(_.isString(scope.field) && scope.field.indexOf('[') >= 0) {
                    scope.field = scope.$eval(scope.field);
                }
			
                scope.defaultReverse = typeof attrs.sorthandle === 'undefined';
            }
        };
	
        return directiveDefinitionObject;
    }]);
