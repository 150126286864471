import React, { Component } from 'react';
import Spinner from '~/components/src/Spinner';
import { get, isEmpty } from 'lodash';
import { components } from '~/components/src/Table';
import { SearchContainer } from '~/common';
import Notification from '~/components/src/Notification';
import Heading from '~/components/src/Heading';
import VariablesList from '../../../components/VariablesList';
import DependantsContainer from '../../../components/DependantsContainer';

const { DetailContainer } = components;
const VARIABLES_STATE_KEY = 'PROFILES/VARIABLES';
const VARIABLES_FILTER_KEY = 'PROFILES/VARIABLES/VARIABLES_SEARCH';

const searchFilterFn = query => item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

class VariablesSection extends Component {
  componentDidMount() {
    this.props.fetchVariables();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const { isFetching, t } = this.props;

    if (isFetching) {
      return <Spinner />;
    }

    return (
      <>
        <Heading title={t('list.header')}>
          <SearchContainer stateKey={VARIABLES_STATE_KEY} searchKey={VARIABLES_FILTER_KEY} searchFn={searchFilterFn} />
        </Heading>
        <Notification kind="information">
          <p>{t('list.intro')}</p>
        </Notification>

        <VariablesList
          onTabChange={this.props.onDependantsTabChange}
          list={this.props.list}
          stateKey={this.props.stateKey}
          t={t}
          onDeleteClick={this.props.onDeleteClick}
          variablesNew={this.props.variableType}
          onSubmit={this.props.onSubmit}
          showAddNewItemRow={this.props.showAddNewItemRow}
          shouldShowDetails={this.props.shouldShowDetails}
          get={get}
          isEmpty={isEmpty}
          onDependantIconClick={this.props.onDependantIconClick}
          submitErrors={this.props.submitErrors}
          {...this.props}
        />
        <div>
          <DetailContainer
            isVisible={this.props.dependantsContainerIsVisible}
            onClose={this.props.onDependantsContainerClose}
            title={this.props.selectedVariable ? this.props.selectedVariable.name : ' '}
            className="u-size4of12"
          >
            <DependantsContainer
              element={this.props.selectedVariable}
              elementName={this.props.selectedVariable ? this.props.selectedVariable.name : null}
              onTabChange={this.props.onDependantsTabChange}
              selectedTab={this.props.selectedTab}
              showDataCollectorDependants={false}
              showFilterDependants={false}
              showTransformationDependants={false}
            />
          </DetailContainer>
        </div>
      </>
    );
  }
}

export default VariablesSection;
