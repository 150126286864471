import React from 'react';
import cx from 'classnames';
import { CARD_HEIGHT, CARD_WIDTH } from '../constants';

const CardBase = ({ isStep = false, isVariant = false, invalidWeight = false, isStartTriggerError = false }) => (
  <rect
    className={cx('JourneyView-cardBase fill-white', {
      'stroke-emerald-500': !isStep,
      'stroke-purple-400': isStep && !isVariant,
      'fill-blue-50 stroke-blue-500': isVariant,
      'fill-red-50 stroke-red-500': invalidWeight || isStartTriggerError,
    })}
    strokeWidth={4}
    rx={16}
    width={CARD_WIDTH}
    height={CARD_HEIGHT}
  />
);

export default CardBase;
