import React, { useState } from 'react';
import { translate } from 'react-i18next';
import cx from 'classnames';
import { useAPI } from '~/common/ApiHooks';
import i18n from '~/i18n';
import { buildUrl } from '~/common';
import { I18NextT } from '~/components/src/Common/types';
import DefaultPanel from './DefaultPanel';
import { useDashboard } from '../context';
import { fetchJOStats } from '../dataService';
import { StatsKeyValue, StatsPlaceholder, StatsPropertyWithStatus, StatsTableHead } from './components';

interface Stat {
  journeyId: string;
  status: string;
  journeyName: string;
  profilesEntered: number;
  activeProfiles: number;
  profilesExited: number;
  goalsReached: number;
}

interface StatsTableProps {
  type: string;
  stats: Stat[];
}

const headers = [
  i18n.t('dashboard:table.name'),
  i18n.t('dashboard:table.entered'),
  i18n.t('dashboard:table.active'),
  i18n.t('dashboard:table.exited'),
  i18n.t('dashboard:table.goalsReached'),
];

const StatsTable = ({ type, stats }: StatsTableProps) => (
  <div className="t-joStatsTable flex w-full flex-col">
    <div className="hid w-full grid-cols-7 border-b border-slate-200 py-2 dark:border-slate-400 md:grid">
      {headers.map((header: string, i: number) => (
        <StatsTableHead key={`journeyHead-${i}`} className={cx(i === 0 ? 'col-span-3' : 'text-right')} label={header} />
      ))}
    </div>

    {stats?.map(stat => (
      <div
        key={stat.journeyId}
        className="t-statsTableRow mt-2 grid w-full grid-cols-7 border-b border-slate-200 py-2 dark:border-slate-400 md:border-none"
      >
        <StatsPropertyWithStatus
          className="t-nameCell col-span-7 md:col-span-3"
          status={stat.status}
          name={stat.journeyName}
          redirectUrl={buildUrl(`workflows/${type === 'audience' ? 'view' : 'edit'}/${stat.journeyId}`)}
        />
        <StatsKeyValue
          label={headers[1]}
          value={stat?.profilesEntered}
          valueClassName="col-span-5 t-profilesEnteredCell"
        />
        <StatsKeyValue
          label={headers[2]}
          value={stat?.activeProfiles}
          valueClassName="col-span-5 t-activeProfilesCell"
        />
        <StatsKeyValue
          label={headers[3]}
          value={stat?.profilesExited}
          valueClassName="col-span-5 t-profilesExitedCell"
        />
        <StatsKeyValue label={headers[4]} value={stat?.goalsReached} valueClassName="col-span-5 t-goalsReachedCell" />
      </div>
    ))}
  </div>
);

interface JOPanel {
  t: I18NextT;
  type?: string;
}

const JOPanel = ({ t, type = 'JOURNEY' }: JOPanel) => {
  const dashboardContextValue = useDashboard();
  const rangeFrom = dashboardContextValue?.state.rangeFrom;
  const rangeTo = dashboardContextValue?.state.rangeTo;
  const translationType = type.toLowerCase();

  const [refetch, setRefetch] = useState(false);
  const { data, isLoading, error } = useAPI(() => {
    if (typeof rangeFrom === 'number' && typeof rangeTo === 'number') {
      return fetchJOStats(rangeFrom, rangeTo, type);
    }

    return Promise.resolve();
  }, [rangeFrom, rangeTo, refetch]);

  const journeysCount = data?.activeCount || 0;
  const hasNoContent = !!(error || journeysCount === 0);
  const stats = data?.journeys.slice(0, 3);

  const joUrl = buildUrl('workflows/dashboard');

  return (
    <DefaultPanel
      icon="orchestration"
      title={journeysCount}
      subTitle={t(`dashboard:panels.orchestration.${translationType}.subTitle`)}
      tooltip={t(`dashboard:panels.orchestration.${translationType}.tooltip`)}
      isLoading={isLoading}
      hasNoContent={hasNoContent}
      tooltipPlacement="bottom"
      url={joUrl}
      testHook="joPanel"
    >
      {hasNoContent ? (
        <StatsPlaceholder
          type={translationType}
          icon="orchestration"
          error={error}
          redirectUrl={joUrl}
          onClick={() => setRefetch(!refetch)}
        />
      ) : (
        <StatsTable type={translationType} stats={stats} />
      )}
    </DefaultPanel>
  );
};

export default translate('dashboard')(JOPanel);
