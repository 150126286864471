angular.module('webUi.ui.tagmanagement.tags.conditions.configure', [
    'ui.bootstrap'
])
    .controller('TagManagementConditionConfigureDialogController', [
        '$scope',
        '$uibModalInstance',
        '$q',
        'TagmanagementService',
        'Utils',
        'SecurityService',
        'dialogsModel',
        function TagManagementConditionConfigureDialogController(
            $scope,
            $uibModalInstance,
            $q,
            TagmanagementService,
            Utils,
            SecurityService,
            dialogsModel) {

            'use strict';
            
            var vm = this;

            vm.data = {};
            vm.data.tagViewPojo = dialogsModel.tagViewPojo || {};
            vm.data.conditionGroups = dialogsModel.conditionGroups || {};

            var lastModification;

            SecurityService.getAllSiteFeatures(SecurityService.getCurrentSiteId()).then(function (siteFeatures) {
                if (siteFeatures) {
                    $scope.supportsSelectionCondition = siteFeatures.SELECTION_CONDITION;
                }
            });

            SecurityService.getSecurityContext().then(function (ctx) {
                $scope.hasDataManagementModule = ctx.hasModule('DATA_MANAGEMENT');
            });

            var resetState = function resetState() {
                $scope.state = {};
                $scope.state.activeCondition = undefined;
                $scope.state.beforeSubmit = function (condition) {
                    return condition;
                };
            };
            resetState();

            $scope.navigate = function (condition) {
                $scope.state.activeCondition = condition;
            };

            $scope.cancel = function () {
            // only cancel form
                if ($scope.state.conditions && $scope.state.formCondition) {
                    $scope.state.formCondition = null;
                // reset whole state
                } else {
                    resetState();
                }
            };

            $scope.close = function () {
                $uibModalInstance.close(lastModification);
            };

            $scope.hasCondition = function (conditionType) {
            // Single condition class
                if (!_.isArray(conditionType)) {
                    return _.filter(vm.data.tagViewPojo.tag.conditions, {clazz: conditionType}).length > 0;
                }

                // Multiple condition classes; e.g LandingCondition could be LandingParamCondition or LandingPatternCondition
                // In case of any match, return true
                var hasCondition = false;
                for (var i = 0; i < conditionType.length; i++) {
                    if (_.filter(vm.data.tagViewPojo.tag.conditions, {clazz: conditionType[i].clazz}).length > 0) {
                        hasCondition = true;
                        break;
                    }
                }
                return hasCondition;
            };

            $scope.getCondition = function (conditionType) {
                return _.find(vm.data.tagViewPojo.tag.conditions, {clazz: conditionType});
            };

            $scope.getConditions = function (conditionTypes) {
                if (!_.isArray(conditionTypes)) {
                    conditionTypes = [conditionTypes];
                }
                return _.filter(vm.data.tagViewPojo.tag.conditions, function (condition) {
                    return _.contains(conditionTypes, condition.clazz);
                });
            };

            $scope.deleteCondition = function (condition) {
                var def = $q.defer();
                TagmanagementService.deleteCondition(vm.data.tagViewPojo.tag.tagId, condition.conditionId).then(function (modification) {
                    var conditions = vm.data.tagViewPojo.tag.conditions;
                    for (var i = 0; i < conditions.length; i++) {
                        if (conditions[i].conditionId === condition.conditionId) {
                        // Remove 1 element
                            conditions.splice(i, 1);
                        }
                    }

                    if ($scope.state.conditions) {
                    // remove condition also from state conditions
                        for (var j = 0; j < $scope.state.conditions.length; j++) {
                            if ($scope.state.conditions[j].conditionId === condition.conditionId) {
                                $scope.state.conditions.splice(j, 1);
                            }
                        }
                        $scope.state.formCondition = null;
                    // if not in multiview reset state
                    } else {
                        resetState();
                    }

                    lastModification = modification;
                    def.resolve();
                });
                return def.promise;
            };

            $scope.save = function () {
                var def = $q.defer();
                var isValid = $scope.state.beforeSubmit($scope.state.formCondition);
                if (!isValid) {
                    def.resolve(false);
                } else {
                    var condition = $scope.state.formCondition;

                    TagmanagementService.addCondition(vm.data.tagViewPojo.tag.tagId, condition).then(function (conditionPojo) {
                        conditionPojo = Utils.sanitizeRestangularOne(conditionPojo);

                        addOrReplaceCondition(vm.data.tagViewPojo.tag.conditions, conditionPojo.condition);

                        // push condition also to state conditions
                        if ($scope.state.conditions) {
                            addOrReplaceCondition($scope.state.conditions, conditionPojo.condition);
                            $scope.state.formCondition = null;
                        // if not in multiview reset state
                        } else {
                            resetState();
                        }

                        lastModification = conditionPojo.modification;
                        def.resolve();
                    });
                }
                return def.promise;
            };

            var addOrReplaceCondition = function (conditions, newCondition) {

                var oldIdx = _.findIndex(conditions, {conditionId: newCondition.conditionId});

                if (oldIdx !== -1) {
                    conditions[oldIdx] = newCondition;
                } else {
                    conditions.push(newCondition);
                }
            };
        }
    ]);
