import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { Notification } from '~/components';
import { showSuccess } from '~/notificationCenter';
import ActionButtons from '~/components/src/ActionButtons';
import { TPermissionGroup } from '../types';
import { createCookiePermissionsGroup, updateCookiePermissionsGroup } from '../dataService';

type TCookiePermissionGroupFormProps = {
  selectedGroup: TPermissionGroup | null;
  setShowFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedGroup: React.Dispatch<React.SetStateAction<TPermissionGroup | null>>;
  refetch: () => void;
  permissionGroups: TPermissionGroup[];
};
const CookiePermissionGroupForm = ({
  selectedGroup,
  setShowFormModal,
  setSelectedGroup,
  refetch,
  permissionGroups,
}: TCookiePermissionGroupFormProps) => {
  const isEdit = !!selectedGroup;
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  const onSubmit = async (values: { name: string }) => {
    if (isEdit) {
      await updateCookiePermissionsGroup({ ...values, groupId: selectedGroup?.groupId });
      showSuccess({ body: i18n.t('cookie:permissionGroupForm.api.updated') });
    } else {
      await createCookiePermissionsGroup(values);
      showSuccess({ body: i18n.t('cookie:permissionGroupForm.api.created') });
    }
    setShowFormModal(false);
    setSelectedGroup(null);
    refetch();
  };

  const hasUniqueNameError = (values: { name: string }) => {
    if (isEdit && selectedGroup?.name === values.name) {
      return false;
    }
    return permissionGroups.some(item => item.name === values.name);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{ name: isEdit ? selectedGroup.name : '' }}
      enableReinitialize={true}
    >
      {({ handleSubmit, isValid, errors, isSubmitting, values }) => (
        <form onSubmit={handleSubmit} className="p-2 py-4">
          <Field
            name="name"
            as={FormikInputField}
            label={i18n.t('cookie:permissionGroupForm.form.name')}
            placeholder={i18n.t('cookie:permissionGroupForm.form.placeholder')}
            errorText={errors?.name}
            autoFocus={true}
          />
          {hasUniqueNameError(values) && (
            <Notification testHook="uniqueNameError" kind="error" className="mt-4">
              <span>{i18n.t('cookie:permissionGroupForm.duplicateError')}</span>
            </Notification>
          )}
          <ActionButtons
            onConfirm={handleSubmit}
            isConfirmEnabled={isValid && !isSubmitting && !hasUniqueNameError(values)}
            confirmText={i18n.t(`cookie:permissionGroupForm.action.${isEdit ? 'update' : 'save'}`)}
            onDecline={() => {
              setShowFormModal(false);
              setSelectedGroup(null);
            }}
            testHook="addCookiePermissionGroup"
          />
        </form>
      )}
    </Formik>
  );
};

export default CookiePermissionGroupForm;
