/**
 * Directive used to evaluate custom HTML code and add it to the element
 */
angular.module('webUi.directive.compile', [])

    .directive('compile', ['$compile', '$timeout', function ($compile, $timeout) {
        return function (scope, element, attrs) {

            scope.$watch(

                function (scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.compile);
                },

                function (value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);

                    $timeout(function () {
                        scope.$apply();
                    }, 100);
                }
            );
        };
    }]);

