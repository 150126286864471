angular.module('webUi.filter.treeFilter', [])

    .filter('treeFilter', ['TreeService', function(TreeService) {

        return function(nodes, filterString) {
            _.forEach(nodes, function (subNode) {
                TreeService.visitNode(subNode, filterString);
            });
            return nodes;
        };
    }]);
