import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import * as ApiService from './dataService';

const MIN_VARIABLE_SIZE = 1;

const makeUniqueVariableValidator = existingVariableNames => inputValue => {
  const lowerCaseVariables = existingVariableNames.map(existingVariableName => existingVariableName?.toLowerCase());
  const isUsedAlready = lowerCaseVariables.includes(inputValue?.toLowerCase());
  return !isUsedAlready;
};

const makeValidationSchema = (existingVariableNames, t) =>
  Yup.object().shape({
    name: Yup.string()
      .test(
        'variable-name-is-unique',
        t('variables:common.nameAlreadyExists'),
        makeUniqueVariableValidator(existingVariableNames),
      )
      .matches(/^[A-Za-z]\w*$/, t('variables:common.invalidVariableName'))
      .trim()
      .required(t('validation:validation.required')),
    variableType: Yup.object()
      .shape({
        name: Yup.string(),
        label: Yup.string(),
      })
      .required(t('validation:validation.required')),
    variableSize: Yup.number().when('variableType', {
      is: variableType => variableType.value === 'LIST',
      then: Yup.number()
        .min(MIN_VARIABLE_SIZE, t('validation:validation.tooLow', { min: MIN_VARIABLE_SIZE }))
        .required(t('validation:validation.required')),
    }),
  });

const CreateNewVariableForm = ({ existingVariableNames, updateVariables, hideModal, t }) => {
  const submitForm = formValues => {
    const variableConfiguration = {
      name: formValues.name,
      variableType: {
        name: formValues.variableType.value,
        label: formValues.variableType.label,
      },
    };

    if (formValues.variableSize) {
      variableConfiguration.variableOptions = {
        size: formValues.variableSize,
      };
    }

    return ApiService.createNewVariable(variableConfiguration, t).then(updateVariables);
  };

  const validationSchema = makeValidationSchema(existingVariableNames, t);

  const selectOptions = [
    { value: 'STRING', label: t('variables:common.types.string') },
    { value: 'LIST', label: t('variables:common.types.list') },
  ];

  const initialValues = {
    name: '',
    variableType: selectOptions[0],
  };

  return (
    <Formik onSubmit={submitForm} initialValues={initialValues} validationSchema={validationSchema} validateOnMount>
      {({ handleSubmit, handleChange, handleBlur, values, errors, isValid, isSubmitting }) => (
        <div className="CreateNewVariableForm-content t-createNewVariableForm">
          <div className="CreateNewVariableForm-fieldsContainer">
            <Field
              name="name"
              as={FormikInputField}
              label={t('variables:common.name')}
              onBlur={handleBlur}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              onChange={handleChange}
              errorText={errors.name}
              autoFocus
            />
            <Field
              name="variableType"
              as={FormikSelectField}
              options={selectOptions}
              label={t('variables:common.type')}
              errorText={errors.variableType}
            />
            {values.variableType.value === 'LIST' && (
              <Field
                name="variableSize"
                as={FormikInputField}
                label={t('variables:common.variableOptionsSize')}
                type="number"
                min={MIN_VARIABLE_SIZE}
                placeholder={t('connectors:common.commonField.pleaseProvideValue')}
                errorText={errors.variableSize}
              />
            )}
          </div>
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={hideModal}
            isConfirmEnabled={isValid && !isSubmitting}
            testHook="createNewVariableFormActions"
          />
        </div>
      )}
    </Formik>
  );
};

export default CreateNewVariableForm;
