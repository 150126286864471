import React from 'react';
import { withRouter } from '~/common/withRouter';
import queryString from 'query-string';
import { Tabs } from '../Tabs';

const RouterTabs = withRouter(props => {
  const { navigate, location, children, kind, className = '', isInitial = false, hasParent = false } = props;
  const { tab = 0, initTab = 0 } = queryString.parse(location.search);

  const newSelectedIndex = () => {
    if (isInitial) {
      return initTab ? +initTab : 0;
    }
    return tab ? +tab : 0;
  };

  const setUrlParams = tabIndex => {
    const targetParams = queryString.parse(location.search);
    targetParams.tab = tabIndex;
    if (isInitial) {
      targetParams.tab = tab;
      targetParams.initTab = tabIndex;
    } else if (hasParent) {
      targetParams.initTab = initTab;
    }
    const searchString = queryString.stringify(targetParams);
    return navigate(`?${searchString}`);
  };

  return (
    <Tabs
      className={className}
      onSelect={tabIndex => setUrlParams(tabIndex)}
      selectedIndex={newSelectedIndex()}
      kind={kind}
    >
      {children}
    </Tabs>
  );
});

export default RouterTabs;
