import React, { Fragment, useState } from 'react';
import Notification from '~/components/src/Notification';
import Link from '~/components/src/Link';
import { buildUrl } from '~/common';
import { R42ArrowRight } from '~/components/src/R42Icons';
import cx from 'classnames';
import './styles.scss';

const NotificationSpoiler = ({ title, children }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Fragment>
      <div className="NotificationSpoiler-Header" onClick={() => setExpanded(!expanded)}>
        <div
          className={cx([
            'NotificationSpoiler-Header-SwitchButton',
            expanded ? 'NotificationSpoiler-Header-SwitchButton--rotated' : '',
          ])}
        >
          <R42ArrowRight />
        </div>
        {title}
      </div>
      {expanded && children}
    </Fragment>
  );
};

export const AudienceDependencyNotification = ({ usedInTags, usedInSegments, t }) => {
  if (!usedInTags.length && !usedInSegments.length) {
    return null;
  }
  return (
    <Notification kind="warning" header="">
      <p>{t('edit.dependency.header')}</p>
      {usedInTags.length !== 0 && (
        <NotificationSpoiler title={t('edit.dependency.tags')}>
          <ul>
            {usedInTags.map(tag => (
              <li key={tag.tagId}>
                <Link href={buildUrl(`tagmanagement/tags/view/${tag.tagId}`)}>{tag.name}</Link>
              </li>
            ))}
          </ul>
        </NotificationSpoiler>
      )}
      {usedInSegments.length !== 0 && (
        <NotificationSpoiler title={t('edit.dependency.audiences')}>
          <ul>
            {usedInSegments.map(segment => (
              <li key={segment.segmentId}>
                <Link href={buildUrl(`audiences/segments/view/${segment.segmentId}`)}>{segment.name}</Link>
              </li>
            ))}
          </ul>
        </NotificationSpoiler>
      )}
    </Notification>
  );
};
