import { withState, withHandlers, compose } from 'recompose';

const showMoreState = withState('showMore', 'toggleMore', false);
const classNameState = withState('dropdownClass', 'setDropdownPosition', '');

const enhancedHandlers = withHandlers({
  toggleMore:
    props =>
    (value = props.showMore) => {
      props.toggleMore(value);
    },
  setDropdownPosition:
    props =>
    (value = '') => {
      props.setDropdownPosition(value);
    },
});

const enhanced = compose(showMoreState, classNameState, enhancedHandlers);

export default enhanced;
