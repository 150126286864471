import React from 'react';
import Icon from 'react-icon-base';

const R42EventListener = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g id="event-enabled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="wifi-signal-2" transform="translate(0.000000, 2.000000)" fill="#0054B0" fillRule="nonzero">
        <path
          d="M27.1186667,0.888 C26.7839007,0.541391221 26.2881628,0.402383204 25.8219902,0.524403791 C25.3558177,0.646424379 24.9917577,1.01048433 24.8697371,1.4766569 C24.7477165,1.94282948 24.8867246,2.43856737 25.2333333,2.77333333 C27.8591516,5.39888369 29.3343435,8.96005496 29.3343435,12.6733333 C29.3343435,16.3866117 27.8591516,19.947783 25.2333333,22.5733333 C24.7123447,23.0939538 24.7120463,23.9383447 25.2326667,24.4593333 C25.7532871,24.9803219 26.597678,24.9806204 27.1186667,24.46 C30.2448156,21.3343216 32.0011074,17.0947095 32.0011074,12.674 C32.0011074,8.25329048 30.2448156,4.0136784 27.1186667,0.888 Z"
          id="Path"
        ></path>
        <path
          d="M23.3453333,4.65866667 C22.8173665,4.1826022 22.0087505,4.20449105 21.5073117,4.70842104 C21.0058729,5.21235102 20.9879916,6.0210656 21.4666667,6.54666667 C24.8508655,9.93115132 24.8508655,15.418182 21.4666667,18.8026667 C21.1298843,19.1396872 20.9984829,19.6307783 21.1219601,20.0909506 C21.2454372,20.551123 21.6050339,20.9104655 22.0652934,21.0336173 C22.5255529,21.1567691 23.016551,21.0250205 23.3533333,20.688 C25.4792288,18.5625235 26.6735797,15.6795073 26.6735797,12.6733333 C26.6735797,9.66715937 25.4792288,6.78414314 23.3533333,4.65866667 L23.3453333,4.65866667 Z"
          id="Path"
        ></path>
        <path
          d="M2.66666667,12.6746667 C2.65660014,8.95920015 4.13288923,5.39402201 6.76666667,2.77333333 C7.27196295,2.25016148 7.2647365,1.41855363 6.75042477,0.904241899 C6.23611304,0.389930168 5.40450518,0.382703721 4.88133333,0.888 C1.75518439,4.0136784 -0.00110736054,8.25329048 -0.00110736054,12.674 C-0.00110736054,17.0947095 1.75518439,21.3343216 4.88133333,24.46 C5.40232195,24.9806204 6.2467129,24.9803219 6.76733332,24.4593333 C7.28795374,23.9383447 7.28765527,23.0939538 6.76666667,22.5733333 C4.13253991,19.9539706 2.65612318,16.3894444 2.66666667,12.6746667 L2.66666667,12.6746667 Z"
          id="Path"
        ></path>
        <path
          d="M10.5333333,4.65866667 C10.2832423,4.40829728 9.943879,4.26761779 9.59,4.26761779 C9.236121,4.26761779 8.89675768,4.40829728 8.64666667,4.65866667 C4.22144934,9.08448427 4.22144934,16.2595157 8.64666667,20.6853333 C8.98344903,21.0223539 9.47444709,21.1541025 9.93470662,21.0309507 C10.3949662,20.9077988 10.7545628,20.5484563 10.87804,20.088284 C11.0015171,19.6281116 10.8701157,19.1370205 10.5333333,18.8 C8.90776851,17.1746516 7.99451751,14.9700761 7.99451751,12.6713333 C7.99451751,10.3725905 8.90776851,8.1680151 10.5333333,6.54266667 C11.0529546,6.02215323 11.0529546,5.1791801 10.5333333,4.65866667 L10.5333333,4.65866667 Z"
          id="Path"
        ></path>
        <path
          d="M16,8.49732607 C14.0540837,8.49364613 12.3892254,9.89398416 12.0594836,11.8117626 C11.7297417,13.7295411 12.83127,15.6055302 14.6666667,16.252 L14.6666667,27.4973261 C14.6666667,28.233713 15.2636203,28.8306667 16,28.8306667 C16.7363797,28.8306667 17.3333333,28.233713 17.3333333,27.4973261 L17.3333333,16.252 C19.16873,15.6055302 20.2702583,13.7295411 19.9405164,11.8117626 C19.6107746,9.89398416 17.9459163,8.49364613 16,8.49732607 L16,8.49732607 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </Icon>
);

export default R42EventListener;
