export const MESSAGE_FETCH_START = 'app/support/messages/MESSAGE_FETCH_START';
export const MESSAGE_FETCH_FAIL = 'app/support/messages/MESSAGE_FETCH_FAIL';
export const MESSAGE_FETCH_SUCCESS = 'app/support/messages/MESSAGE_FETCH_SUCCESS';

export const MESSAGES_FETCH_START = 'app/support/messages/MESSAGES_FETCH_START';
export const MESSAGES_FETCH_FAIL = 'app/support/messages/MESSAGES_FETCH_FAIL';
export const MESSAGES_FETCH_SUCCESS = 'app/support/messages/MESSAGES_FETCH_SUCCESS';

export const DELETE_START = 'app/support/messages/DELETE_START';
export const DELETE_FAIL = 'app/support/messages/DELETE_FAIL';
export const DELETE_SUCCESS = 'app/support/messages/DELETE_SUCCESS';

export const SAVE_START = 'app/support/messages/SAVE_START';
export const SAVE_FAIL = 'app/support/messages/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/support/messages/SAVE_SUCCESS';
