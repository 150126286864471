import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Users that can be matched to a client
 * @param state
 * @param action
 * @returns []
 */
export const list = (state = [], action) => {
  switch (action.type) {
    case types.MATCH_FETCH_SUCCESS:
      return action.payload;
    case types.MATCH_FETCH_START:
    case types.MATCH_SAVE_SUCCESS:
      return [];
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null, filter: string, matchClientId: null}}
 */
const defaultUI = {
  error: null,
  matchClientId: null,
};

export const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.MATCH_FETCH_START:
      return {
        ...state,
        matchClientId: action.payload,
      };
    case types.MATCH_SAVE_SUCCESS:
      return {
        ...state,
        matchClientId: null,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  ui,
});
