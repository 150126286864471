import React, { useEffect } from 'react';
import { pick, isEmpty } from 'lodash';
import Immutable from 'seamless-immutable';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import Steps from '~/components/src/Steps';
import StepsFooter from '~/components/src/StepsFooter';
import Spinner from '~/components/src/Spinner';
import EditableRulesList from '~/common/modules/rules/components/EditableRulesList';
import EditableIntegrationsList from '~/common/modules/connectors/components/EditableConnectorsList';
import { MediumWrapper } from '~/components/src/Containers';
import { getUsedVariables } from '~/workflows/form/Journey/Step/utils';
import AudienceGeneralInfoForm from '../../components/AudienceGeneralInfoForm';
import { AudienceDependencyNotification } from '../../components/AudienceDependencyNotification';
import { joinCriteriaVariables } from './utils';
import './styles.scss';

class AudienceEdit extends React.Component {
  state = {
    generalForm: !this.props.isNew ? pick(this.props.audience, ['name', 'description']) : {},
    criteria: !this.props.isNew ? Immutable.asMutable(this.props.audience.criteria, { deep: true }) : [],
    integrations: !this.props.isNew ? Immutable.asMutable(this.props.audience.integrations, { deep: true }) : [],
    isGeneralFormValid: !this.props.isNew,
    isCriteriaValid: !this.props.isNew,
    areIntegrationsValid: true,
    step: 1,
    isSubmitted: false,
  };

  formProps = null;
  bindFormikForm = _formProps => {
    this.formProps = _formProps;
  };

  canSubmit = async () => {
    await this.formProps?.validateForm();
    const { isCriteriaValid, isGeneralFormValid, areIntegrationsValid } = this.state;
    const { canSave } = this.props;
    return !!(isGeneralFormValid && this.formProps?.isValid && areIntegrationsValid && isCriteriaValid && canSave);
  };

  onChangeStep = ({ step }) => {
    this.setState({ step });
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  onSubmit = () => {
    const originalAudience = this.props.audience;
    const audience = {
      criteria: this.state.criteria,
      integrations: this.state.integrations,
      ...this.state.generalForm,
    };
    if (this.props.isNew) {
      return this.props.createAudience(audience, this.props.groupId);
    }
    // Update and create endpoints require different subsets of values
    const payload = {
      ...audience,
      segmentNumber: originalAudience.segmentNumber,
      migrated: originalAudience.migrated,
      historicProcessingTriggered: originalAudience.historicProcessingTriggered,
      historicProcessingNeeded: originalAudience.historicProcessingNeeded,
      deleted: originalAudience.deleted,
      hasTagPartner: originalAudience.hasTagPartner,
    };

    return this.props.updateAudience(payload, originalAudience.segmentId);
  };

  isFormValid = (step = '') => {
    if (step === 'next') {
      return this.state.isGeneralFormValid && this.state.isCriteriaValid;
    }
    return this.state.isGeneralFormValid && this.state.areIntegrationsValid;
  };

  onValidate = (step = '') => {
    this.setState({ isSubmitted: true }, () => {
      if (step === 'next' && this.isFormValid('next')) {
        this.setState({ isSubmitted: false, step: this.state.step + 1 });
      } else if (step === 'save' && this.isFormValid('save')) {
        this.onSubmit();
      }
    });
  };

  onCriteriaUpdate = ({ criteria, isValid }) => this.setState({ criteria, isCriteriaValid: isValid });

  onIntegrationsUpdate = ({ integrations, areIntegrationsValid }) =>
    this.setState({ integrations, areIntegrationsValid, isSubmitted: false });

  onGeneralFormUpdate = ({ generalForm, isValid }) => this.setState({ generalForm, isGeneralFormValid: isValid });

  render() {
    const {
      t,
      editAudienceSteps,
      allConnectors,
      ruleTypesTree,
      audience,
      isNew,
      canSave,
      allVariables,
      fetchAllVariables,
    } = this.props;
    const { step, criteria, integrations } = this.state;

    const editableCriteria = joinCriteriaVariables(criteria, allVariables);

    const usedVariables = getUsedVariables(allVariables, criteria);

    return (
      <div className="flex h-full w-full items-center justify-center bg-gray-50">
        <div className="EditAudience">
          <Heading
            title={isNew ? t('create.header') : t('edit.header')}
            className="EditAudience-heading"
            crumbs={[
              {
                title: t('audiences:back'),
                onClick: () => {
                  this.onCancel();
                },
              },
            ]}
          />
          <Notification kind="information" header="">
            <p>{t('edit.pageDescription')}</p>
          </Notification>
          {audience && (
            <AudienceDependencyNotification
              usedInTags={audience.usedInTags}
              usedInSegments={audience.usedInSegments}
              t={t}
            />
          )}
          <AudienceGeneralInfoForm
            onChange={this.onGeneralFormUpdate}
            audience={audience}
            isNew={isNew}
            isSubmitted={this.state.isSubmitted}
          />
          <Steps
            steps={editAudienceSteps}
            activeStep={step}
            onClick={this.onChangeStep}
            disabled={criteria.length === 0}
          />
          {step === 1 && (
            <EditableRulesList
              criteria={editableCriteria}
              onChange={this.onCriteriaUpdate}
              ruleTypesTree={ruleTypesTree}
              isSubmitted={this.state.isSubmitted}
              fetchAllVariables={fetchAllVariables}
              isUsedBySegment={!!(audience !== null && audience.usedInSegments.length)}
              audienceId={audience?.segmentId}
              bindFormikForm={this.bindFormikForm}
              type="audience"
            />
          )}
          {step === 2 && (
            <MediumWrapper>
              <EditableIntegrationsList
                stageName={this.state.generalForm.name}
                integrations={integrations}
                onChange={this.onIntegrationsUpdate}
                allConnectors={allConnectors}
                isSubmitted={this.state.isSubmitted}
                usedVariables={usedVariables}
              />
            </MediumWrapper>
          )}

          <hr className="EditAudience-footerSeparator" />

          {this.state.isSubmitted && !this.isFormValid(step !== editAudienceSteps.length ? 'next' : 'save') && (
            <Notification kind="error" header="" testHook="audienceFooterErrorNotification">
              <p>{t('edit.validationsErrors.formIsNotValid')}</p>
            </Notification>
          )}
          <StepsFooter
            t={t}
            selectedStep={step}
            totalSteps={editAudienceSteps.length}
            onChange={this.onChangeStep}
            onCancel={this.onCancel}
            canSave={canSave}
            canSubmit={this.canSubmit()}
            onValidate={this.onValidate}
            isNew={isNew}
          />
        </div>
      </div>
    );
  }
}

const AudienceFetchWall = props => {
  const {
    isNew,
    audience,
    ruleTypesTree,
    params,
    isFetchingAudience,
    isFetchingVariables,
    fetchAudience,
    fetchRuleTypes,
    fetchAllVariables,
    fetchConnectors,
  } = props;
  const { audienceId } = params;
  const hasRules = audience !== null && !isEmpty(audience.criteria);
  const shouldRender = (isNew || hasRules) && !isEmpty(ruleTypesTree) && !isFetchingAudience && !isFetchingVariables;

  useEffect(() => {
    fetchRuleTypes();
    fetchAllVariables();
    fetchConnectors();
    if (!isNew && audienceId) {
      fetchAudience(audienceId);
    }
  }, []);
  return shouldRender ? <AudienceEdit {...props} /> : <Spinner />;
};

export default AudienceFetchWall;
