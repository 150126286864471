import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import constants from '../../constants';
import { TConsentTypes, TPayloadType, TProductType } from './types';
import { mapConsentTypes, mapPayloadTypes, mapProductTypes } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/${constants.GOOGLE_CUSTOMER_MATCH}`;

export const fetchProductTypes = (): Promise<TProductType[]> =>
  Api.callGet(`${BASE_PATH}/productTypes`).then(parseResponse).then(mapProductTypes);

export const fetchPayloadTypes = (): Promise<TPayloadType[]> =>
  Api.callGet(`${BASE_PATH}/payloadTypes`).then(parseResponse).then(mapPayloadTypes);

export const fetchConsentTypes = (): Promise<TConsentTypes[]> =>
  Api.callGet(`${BASE_PATH}/consentTypes`).then(parseResponse).then(mapConsentTypes);
