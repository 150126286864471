angular.module('webUi.ui.context.sites.form', ['webUi.ui.context.sites.base', 'ngResource'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.sites.add', {
                url: '/add?clientId',
                isReact: true
            })
            .state('site.context.sites.edit', {
                url: '/edit/{siteId}',
                isReact: true
            });
    }]);
