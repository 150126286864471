import { createContext } from 'react';
import { DataFeedListType } from './types';

type ContextType = {
  hasAdservingEdit: boolean;
  dataFeeds: DataFeedListType[];
  initialDataFeedId: string;
  refetchDataFeeds: () => void;
  setInitialDataFeedId: (val: string) => void;
};

const defaultValues = {
  hasAdservingEdit: false,
  dataFeeds: [],
  initialDataFeedId: '',
  refetchDataFeeds: () => {
    // intentionally left empty
  },
  setInitialDataFeedId: () => {
    // intentionally left empty
  },
};

export const DataFeedContext = createContext<ContextType>(defaultValues);
