angular.module('webUi.ui.tagmanagement.tags.conditions.ip', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormIpConditionController', ['$scope', '$timeout', 'ValidService',
        function TagmanagementTagFormIpConditionController    ($scope, $timeout, ValidService) {

            var conditionClazz = 'IpRuleCondition';
            var getIpFilters = function(){
                var ipFilters = [];

                // convert parts to ipFilters
                _.forEach($scope.state.formCondition.ipFilterParts, function(ipRule) {
                    if ( !_.isUndefined(ipRule.octet1)
                        && !_.isUndefined(ipRule.octet2)
                        && !_.isUndefined(ipRule.octet3)
                        && !_.isUndefined(ipRule.octet4) ){
                        ipFilters.push(ipRule.octet1 + '.' + ipRule.octet2 + '.' + ipRule.octet3 + '.' + ipRule.octet4 + '/' + ipRule.subnetmask);
                    }
                });
                return ipFilters;
            };
            var isValidIpFilter = function(filter){
                var pathRegex = '([0-9]+)\\.([0-9]+)\\.([0-9]+)\\.([0-9]+)/([0-9]+)';
                return filter.match(pathRegex);
            };

            $scope.options = [{value: true, label: 'include'}, {value: false, label: 'exclude'}];

            var isValidOctet = $scope.isValidOctet = function isValidOctet(octet) {
            //is a number in the range [0, 256)
                return ValidService.isNumberRange(octet, 0, 256);
            };

            var isValidSubnetMask = $scope.isValidSubnetMask = function isValidSubnetMask(subnetMask) {
            //is a number in the range [0, 33)
                return ValidService.isNumberRange(subnetMask, 0, 33);
            };

            $scope.isValid = function(ipRule){

                if (_.isUndefined(ipRule.octet1)
                    || _.isUndefined(ipRule.octet2)
                    || _.isUndefined(ipRule.octet3)
                    || _.isUndefined(ipRule.octet4)
                    || _.isUndefined(ipRule.subnetmask)) {
                    return false;
                }
                return (isValidOctet(ipRule.octet1)
                    && isValidOctet(ipRule.octet2)
                    && isValidOctet(ipRule.octet3)
                    && isValidOctet(ipRule.octet4)
                    && isValidSubnetMask(ipRule.subnetmask));
            };

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                include: false,
                ipFilters: []
            };

            $scope.newIpRule = function() {
                $scope.state.formCondition.ipFilterParts.push({subnetmask:32});

                // focus next ip octet
                $timeout(function() {
                    var octets = $('.firstoctet');
                    $(_.last(octets)).focus();
                });
            };
            $scope.removeIpRule = function(ipRuleIndex) {
                $scope.state.formCondition.ipFilterParts.splice(ipRuleIndex, 1) ;
            };

            var ipFilterParts = [];

            var addIpFromString = function(filter) {
                var filterMatch = isValidIpFilter(filter);

                if(filterMatch) {
                    ipFilterParts.push({octet1: filterMatch[1], octet2: filterMatch[2], octet3: filterMatch[3], octet4: filterMatch[4], subnetmask: filterMatch[5]});
                    return true;
                }

                return false;
            };

            $scope.handlePastedIps = function(event) {

                // block default action
                event.preventDefault();

                try {
                    var pasted = event.originalEvent.clipboardData.getData('text/plain');
                    var success = false;

                    var array = pasted.match(/[^\s]+/g);
                    _.forEach(array, function(val) {
                        if(addIpFromString(val)) {
                            success = true;
                        }
                    });

                    if(array.length > 0 && !success) {
                        $(event.target).animate({ 'background-color': 'red'}, 0).animate({ 'background-color': 'inherit'}, 400);
                    } else {
                        $(event.target).animate({ 'background-color': 'green'}, 0).animate({ 'background-color': 'inherit'}, 400);
                    }

                } catch(e) {
                // ignore
                }
            };

            // construct parts from existing ipFilters
            _.forEach($scope.state.formCondition.ipFilters, function(filter) {
                addIpFromString(filter);
            });

            $scope.state.formCondition.ipFilterParts = ipFilterParts;
            if(ipFilterParts.length === 0) {
                $scope.newIpRule();
            }

            $scope.removeIpFilterRule = function(idx) {
                $scope.state.formCondition.ipFilterParts.splice(idx, 1);
            };

            $scope.state.beforeSubmit = function() {
                $scope.state.formCondition.ipFilters = getIpFilters();
                return $scope.state.formCondition.ipFilters.length > 0;
            };
        }
    ]);

