angular.module('webUi.ui.tagmanagement.tagCounters.list', ['webUi.ui.tagmanagement.tagCounters.base']
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tagCounters.list', {
                url : '/list',
                isReact: true,
            });
    }]);

