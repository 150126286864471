import React from 'react';
import Page from '~/components/src/Page';
import { translate } from 'react-i18next';
import VariablesContainer from '../variables/containers/Variables';

export const VariablesViewPage = ({ canEdit }) => (
  <Page>
    <VariablesContainer canEdit={canEdit} />
  </Page>
);

export default translate('variables')(VariablesViewPage);
