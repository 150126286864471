import React from 'react';
import PropTypes from 'prop-types';
import Detail from '~/components/src/Form/Detail/Default';
import Details from '~/components/src/Form/Detail';
import Notification from '~/components/src/Notification';

export const TagVariantDetail = ({ variant, t }) => {
  const ts = t('common:checkbox', { returnObjects: true });

  const staticTemplatePart = variant.pixelEnabled ? (
    <Details.Code label={t('variantForm.templateEditStatic')} value={variant.templateEditStatic} />
  ) : null;

  const removedPart = variant.deleted ? (
    <Notification kind="warning" header={t('common:notifications:warning')}>
      {t('variant.alerts.deleted')}
    </Notification>
  ) : null;

  return (
    <div>
      {removedPart}

      {/* Name */}
      <Detail label={t('variantForm.name.label')} value={variant.name} />

      {/* Generated Name */}
      <Details.Code label={t('variantForm.generatedName.label')} value={variant.generatedName} />

      {/* Description */}
      <Detail label={t('variantForm.description.label')} value={variant.description} />

      {/* Is this variant private? */}
      <Details.Bool label={t('variantForm.private')} value={variant.privateMode} translations={ts} />

      {/* Client */}
      <Detail label={t('variantForm.client')} value={variant.clientName} />

      {/* Site module */}
      <Detail label={t('variantForm.siteModule')} value={variant.siteModule && variant.siteModule.label} />

      {/* Default Priority */}
      <Detail label={t('variantForm.priority.label')} value={variant.defaultPriority} />

      {/* Sorting Order */}
      <Detail label={t('variantForm.order.label')} value={variant.sortingOrder} />

      {/* Execution type */}
      <Detail label={t('variantForm.executionType')} value={variant.executionType && variant.executionType.label} />

      {/* Custom code */}
      <Details.Code label={t('variantForm.customCode')} value={variant.jsCode} />

      {/* Template view */}
      <Details.Code label={t('variantForm.templateView')} value={variant.templateView} mode="xml" />

      {/* Template edit */}
      <Details.Code label={t('variantForm.templateEdit')} value={variant.templateEdit} />

      {/* Static Template Edit */}
      {staticTemplatePart}

      {/* Disabled Template Editing */}
      <Details.Bool label={t('variantForm.disableEditing')} value={variant.hasNoEdit} translations={ts} />

      {/* Template Helper Scope */}
      <Details.Code label={t('variantForm.templateScope')} value={variant.templateScope} />
    </div>
  );
};

TagVariantDetail.propTypes = {
  variant: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default TagVariantDetail;
