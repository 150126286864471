import React from 'react';
import { translate } from 'react-i18next';
import RulePicker from '~/common/modules/rules/components/RulePicker/index';
import { RuleOptions } from '~/components/src/RuleList';
import './AudienceRuleControls.scss';

const AudienceRuleControls = ({
  ruleTypesTree,
  onCriteriaSelect,
  criteria,
  audienceId,
  t,
  isUsedBySegment,
  isUsedInJourneys,
  isOrButtonHidden,
  enableGroupBy,
}) => {
  if (criteria.length === 0) {
    // When we create the first rule - we need to just create it, not to attach to previous one
    return (
      <div className="AudienceEdit-rulePickers">
        <RulePicker
          enableGroupBy={enableGroupBy}
          onSelect={onCriteriaSelect(RuleOptions.AND)}
          ruleTypesTree={ruleTypesTree}
          actionName={t('rules.add.firstRule')}
          actionType={RuleOptions.AND}
          isUsedBySegment={isUsedBySegment}
          isUsedInJourneys={isUsedInJourneys}
          audienceId={audienceId}
          testHook="addAndRule"
          className="u-inlineBlock"
        />
      </div>
    );
  }

  return (
    <div className="AudienceEdit-rulePickers">
      <RulePicker
        enableGroupBy={enableGroupBy}
        onSelect={onCriteriaSelect(RuleOptions.AND)}
        ruleTypesTree={ruleTypesTree}
        actionName={t('rules.add.andRule')}
        actionType={RuleOptions.AND}
        isUsedBySegment={isUsedBySegment}
        isUsedInJourneys={isUsedInJourneys}
        audienceId={audienceId}
        testHook="addAndRule"
      />
      {!isOrButtonHidden && (
        <RulePicker
          enableGroupBy={enableGroupBy}
          onSelect={onCriteriaSelect(RuleOptions.OR)}
          ruleTypesTree={ruleTypesTree}
          actionName={t('rules.add.orRule')}
          actionType={RuleOptions.OR}
          isUsedBySegment={isUsedBySegment}
          isUsedInJourneys={isUsedInJourneys}
          audienceId={audienceId}
          testHook="addOrRule"
        />
      )}
    </div>
  );
};

export default translate('audiences')(AudienceRuleControls);
