import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { buildUrl, useAPI } from '~/common';
import { Empty, Heading, Page, Spinner } from '~/components';
import { fetchModelStatsById } from './dataService';
import TrainingAnalysis from './TrainingAnalysis';

const ModelStats = () => {
  const navigate = useNavigate();
  const params = useParams();
  const modelId = params.id || '';

  const { data: stats, isLoading } = useAPI(() => fetchModelStatsById(modelId));

  const goToModelsList = () => navigate(buildUrl('ai/models/list'), { replace: true });

  if (isLoading || !stats) {
    return <Spinner />;
  }

  return (
    <Page>
      <Heading
        title={i18n.t('ai:modelsPage.stats')}
        crumbs={[
          {
            title: i18n.t('ai:actions.backToModels'),
            onClick: () => {
              goToModelsList();
            },
          },
        ]}
      ></Heading>
      {!stats && <Empty header={i18n.t('ai:modelStats.emptyHeader')} />}
      {stats && (
        <Tabs>
          <TabList>
            <Tab testHook="trainingAnalysisTab">{i18n.t('ai:modelStats.tabs.trainingAnalysis')}</Tab>
            <Tab testHook="productionAnalysisTab" disabled>
              {i18n.t('ai:modelStats.tabs.productionAnalysis')}
            </Tab>
          </TabList>
          <TabPanel>
            <TrainingAnalysis stats={stats} />
          </TabPanel>
          <TabPanel>
            <>{/* TODO */}</>
          </TabPanel>
        </Tabs>
      )}
    </Page>
  );
};

export default ModelStats;
