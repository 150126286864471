(function () {

    storeVariableComponentController.$inject = ['STORE_VARIABLE', 'ValidService'];

    function storeVariableComponentController(STORE_VARIABLE, ValidService) {
        var vm = this;
        vm.$onInit = function () {
            //Expose methods
            vm.onSave = vm.resolve.onSave;
            vm.onCancel = vm.resolve.onCancel;
            vm.save = save;
            vm.close = close;
            vm.isUnique = isUnique;
            vm.isValidName = isValidName;
            vm.isNotJsReservedKeyword = ValidService.isValidNotJsReservedKeyword;
            vm.hasValidType = hasValidType;
            vm.validateLength = validateLength;

            //Expose Data
            vm.data = {
                variable: {},
                availableVariableTypes: STORE_VARIABLE.availableVariableTypes,
                existingVariables: vm.resolve.variables,
                renderMode: 'modal',
                interactionType: 'Variable'
            };

        };

        function save(variable, onSave, modalInstance) {
            //Validate before
            if(_.isEmpty(variable)) {
                return;
            }
            if (variable.variableType.name === 'STRING') {
                delete variable.variableOptions;
            }
            //This component can be used in a stateful modal or in a stateless modal, the save will work for both cases.
            return _.isFunction(onSave) ? onSave(variable) : modalInstance.close(variable);
        }

        function close(onCancel, modalInstance) {
            //This component can be used in a stateful modal or in a stateless modal, the Cancel will work for both cases.
            return _.isFunction(onCancel) ? onCancel() : modalInstance.close(false);
        }

        function isUnique(variableName, existingVariables) {
            return _.isEmpty(variableName) || -1 === _.findIndex(existingVariables, function (variable) {
                return _.get(variable, 'name', '').toLowerCase() === variableName.toLowerCase();
            });
        }

        function isValidName(variableName) {
            return !_.isEmpty(variableName) && ValidService.isValidVariableName(variableName);
        }

        function hasValidType(variable) {
            return !_.isEmpty(variable) && !_.isEmpty(variable.variableType);
        }

        function validateLength(variableName) {
            return ValidService.isValidLength(variableName, STORE_VARIABLE.maxLengthVariableName);
        }
    }

    angular.module('webUi.component.common.storeVariable', [
        'webUi.service.profilesConstants'
    ])
        .component('storeVariable', {
            templateUrl: 'component/common/storeVariable/storeVariable.tpl.html',
            controller: storeVariableComponentController,
            controllerAs: 'vm',
            bindings: {
                modalInstance: '<',
                resolve: '<'
            }
        });
})();
