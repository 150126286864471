import React, { Component } from 'react';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { actionCreators } from 'react-redux-composable-list';
import LinkIcon from '~/components/src/LinkIcon';
import BasescriptsContainer from '../basescripts/containers/BasescriptsContainer';
import { setBasescriptsFilter, removeBasescriptsFilter } from '../basescripts/actions';
import * as selectors from '../basescripts/selectors';

const BASESCRIPTS_KEY = 'CONTEXT/BASESCRIPTS';
const FILTER_KEY = 'CONTEXT/BASESCRIPTS_FILTER';

const versionFilterFn = query => item => item.version.toLowerCase().indexOf(query.toLowerCase()) !== -1;

export class BasescriptsViewPage extends Component {
  render() {
    const { t, onFilterChange, isContextAdmin, filter } = this.props;
    return (
      <Page>
        <Heading title={t('basescripts:header')}>
          <SearchElement
            placeholder={t('basescripts:list.search.placeholder')}
            value={filter}
            onChange={e => onFilterChange(e.target.value)}
          />
          {isContextAdmin && (
            <LinkIcon
              color="blue"
              className="h-8 w-8"
              icon="add"
              url={buildUrl('context/basescripts/add')}
              tooltip={t('basescripts:list.addNew')}
              testHook="addNew"
            />
          )}
        </Heading>
        <BasescriptsContainer />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  filter: selectors.getBasescriptsFilter(state),
});

const mapDispatchToProps = dispatch => ({
  onFilterChange: query => {
    if (query !== '') {
      dispatch(actionCreators.doSetFilter(BASESCRIPTS_KEY, FILTER_KEY, versionFilterFn(query)));
      dispatch(setBasescriptsFilter(query));
    } else {
      dispatch(actionCreators.doRemoveFilter(BASESCRIPTS_KEY, FILTER_KEY));
      dispatch(removeBasescriptsFilter());
    }
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('basescripts'))(BasescriptsViewPage);
