import React from 'react';
import cx from 'classnames';
import ReactTextAreaAutosize from 'react-textarea-autosize';

import '~/components/src/Form/Elements/elements.scss';

const TextAreaElement = ({
  name,
  hasError,
  onBlur,
  onChange,
  value,
  testHook,
  className,
  placeholder = 'Please provide a value',
  id,
  disabled,
  minRows,
}) => (
  <ReactTextAreaAutosize
    className={cx(
      'TextAreaElement',
      { 'TextAreaElement--hasError': hasError, [`t-${testHook || name}`]: testHook || name },
      className,
    )}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    id={id}
    disabled={disabled}
    value={value}
    minRows={minRows}
  />
);

export default TextAreaElement;
