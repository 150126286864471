import React from 'react';
import cx from 'classnames';

const RulePickerItem = props => {
  const { title, onClick } = props;
  return (
    <div
      className={cx(
        `t-${title.replace(/ /g, '')}`,
        'RulePickerItem',
        'rounded border border-transparent bg-white px-4 py-2 text-xl text-gray-500 hover:border-gray-800 hover:text-gray-600 hover:drop-shadow',
      )}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default RulePickerItem;
