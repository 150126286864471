import { connect } from 'react-redux';
import { getEndpoints, getIdentifierTypes, fetchingEndpoints, fetchingIdentifierTypes } from './clangFormSelector';
import { fetchEndpoints, fetchIdentifierTypes } from './clangFormActions';
import ClangForm from './ClangForm';

const mapStateToProps = state => ({
  identifierTypes: getIdentifierTypes(state),
  fetchingIdentifierTypes: fetchingIdentifierTypes(state),
  endpoints: getEndpoints(state),
  fetchingEndpoints: fetchingEndpoints(state),
});

const mapDispatchToProps = {
  fetchIdentifierTypes,
  fetchEndpoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClangForm);
