import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAPI } from '~/common';
import { getAngularService } from 'ReactAngular/react.service';
import WorkflowDashboard from './dashboard';
import { isJoDemoMode } from './fake/utils';
import WorkflowForm from './form';
import Overview from './form/Audience/components/Overview';
import { WorklfowContext } from './WorklfowContext';

const WorkflowPages = (): React.ReactNode => {
  const navigate = useNavigate();
  const SecurityService = getAngularService(document, 'SecurityService');

  const { data: ctx } = useAPI(async () => {
    const context = await SecurityService.getSecurityContext();

    return {
      hasWorkflowJourneyEdit: context.hasPermission('WORKFLOW_JOURNEY_EDIT'),
      hasWorkflowAudienceEdit: context.hasPermission('WORKFLOW_AUDIENCE_EDIT'),
    };
  });

  const joDemoMode = isJoDemoMode();
  const hasWorkflowJourneyEdit = !!ctx?.hasWorkflowJourneyEdit;
  const hasWorkflowAudienceEdit = !!ctx?.hasWorkflowAudienceEdit;

  return (
    <WorklfowContext.Provider value={{ navigate, hasWorkflowJourneyEdit, hasWorkflowAudienceEdit, joDemoMode }}>
      <Routes>
        <Route path="dashboard" element={<WorkflowDashboard />} />
        <Route path="edit/:workflowId/*" element={<WorkflowForm />} />
        <Route path="view/:workflowId" element={<Overview />} />
      </Routes>
    </WorklfowContext.Provider>
  );
};

export default WorkflowPages;
