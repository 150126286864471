import React, { useState } from 'react';
import i18n from '~/i18n';
import { Heading } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { ExperimentStatsHeaderProps, Tcolumn } from '~/workflows/types';

const ExperimentStatsHeader = ({ columns, setColumns, query, setQuery }: ExperimentStatsHeaderProps) => {
  const [showColumns, setShowColumns] = useState(false);

  const onChange = (name: string) => {
    setColumns((prevColumns: Tcolumn[]) =>
      prevColumns.map((column: Tcolumn) => (column.id === name ? { ...column, show: !column.show } : column)),
    );
  };

  return (
    <>
      <Heading kind="h2" title={i18n.t('workflow:stats.experiments.header')}>
        <div className="flex items-center gap-2">
          <SearchElement
            value={query}
            onChange={event => setQuery(event.target.value)}
            placeholder={i18n.t('common:actions.search')}
          />
          <div>
            <BtnIcon
              className="!block"
              icon="filter"
              onClick={() => setShowColumns(!showColumns)}
              color={showColumns ? 'blue' : 'gray'}
            />
            {showColumns && (
              <div className="absolute z-10 -ml-52 mt-2 max-h-[300px] w-[200px] overflow-y-auto border bg-white py-1">
                {columns.map((column: Tcolumn) => (
                  <div className="pointer bg-white p-2 hover:bg-gray-100" key={column.id}>
                    <label className="flex gap-2">
                      <input
                        type="checkbox"
                        name={column?.id}
                        checked={column?.show}
                        onChange={e => onChange(e.target.name)}
                      />
                      <span className="truncate text-ellipsis">{column.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Heading>
    </>
  );
};

export default ExperimentStatsHeader;
