import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { doubleClickOfflineActionTypes } from './doubleClickOfflineActions';

const doubleClickOfflineReducer = (state, action) => {
  switch (action.name) {
    case doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setFulfilled());
    case doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.pending:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setPending());
    case doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.rejected:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setRejected());
    case doubleClickOfflineActionTypes.SET_USER_ID_TYPES:
      return state.set('userIdTypes', action.data);

    case doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.fulfilled:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setFulfilled());
    case doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.pending:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setPending());
    case doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.rejected:
      return state.setIn(['promise', 'fetchingPartnerInformation'], setRejected());
    case doubleClickOfflineActionTypes.SET_PARTNER_INFORMATION:
      return state.set('partnerInformation', action.data);

    default:
      return state;
  }
};

export default doubleClickOfflineReducer;
