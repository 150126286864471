import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Crumb from './Crumb';
import Crumbs from './Crumbs';
import './breadcrumb-styles.scss';
import './folder-icon-styles.scss';

export class Breadcrumb extends Component {
  render() {
    const { fileName, crumbs, className } = this.props;
    return (
      <div className={cx('Breadcrumb', { 'Breadcrumb--withFileName': fileName }, className)}>
        <div className="u-posRelative">
          {fileName && <div className="Breadcrumb-fileName">{fileName}</div>}
          <ol className="Breadcrumb-list">
            {crumbs?.map((item, index, arr) => {
              if (index === 0 || index === arr.length - 1) {
                return (
                  <Crumb
                    title={item.title}
                    pathname={item.pathname}
                    onClick={item.onClick}
                    key={item.pathname + index}
                  />
                );
              } else if (arr.length >= 3 && index === 1) {
                // if there are at least 3 crumbs then
                // use dropdown for intermediate crumbs
                const intermediateCrumbs = arr.filter(
                  (item, index, arr) => !!(arr.length - 1 !== index && index !== 0),
                );
                return <Crumbs key={item.pathname + index} crumbs={intermediateCrumbs} />;
              }
              return null;
            })}
          </ol>
        </div>
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  // crumbs props is collection of objects only for folders
  // if last item is currently opend file is should be passed as fileName
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      pathname: PropTypes.string,
    }),
  ).isRequired,
  // pass fileName to show currently opend file name
  fileName: PropTypes.string,
  className: PropTypes.string,
};

export default Breadcrumb;
