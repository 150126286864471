import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import ClientForm from '~/context/clients/components/ClientForm';
import { validateCriticalRateLimit } from '~/context/clients/records/utils';
import { saveClient, byClientId, fetchClient, isFetchingClients } from '~/context/clients';
import { withRouter } from '~/common/withRouter';

const selector = formValueSelector('updateClientForm');

class UpdateClientFormContainer extends Component {
  componentDidMount() {
    this.props.fetchClient();
  }

  render() {
    const { submitting, t, isFetching } = this.props;
    return (
      <Page>
        <SmallWrapper>
          <Heading title={t('client.form.editClient')} />
          <Panel>
            {isFetching ? (
              <Spinner />
            ) : (
              <ClientForm submitting={submitting} {...this.props} dataStorageLocationIsEnabled={false} isEdit={true} />
            )}
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

/**
 *
 * @param state
 * @param ownProps
 * @returns {{backHref: *, initialValues: *, id}}
 */
const mapStateToProps = (state, ownProps) => {
  const id = ownProps?.params.clientId;
  return {
    backHref: buildUrl(`context/clients/view/${id}`),
    initialValues: byClientId(state, id),
    id,
    isUsageAlertsChecked: selector(state, 'usageAlertsEnabled'),
    isErrorAlertsChecked: selector(state, 'errorAlertsEnabled'),
    isApiRateLimitChecked: selector(state, 'apiRateLimitEnabled'),
    isPriorityLevelCriticalChecked: selector(state, 'priorityLevelEnabled'),
    externalAuthentication: selector(state, 'externalAuthentication'),
    isFetching: isFetchingClients(state),
    isCritialValueValid: validateCriticalRateLimit(
      selector(state, 'apiRateLimit'),
      selector(state, 'priorityLevelCritical'),
    ),
  };
};
/**
 *
 * @param dispatch
 * @param ownProps
 * @returns {{fetchClients: function(), onFilterChange: function(*=): *}}
 */
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: values => dispatch(saveClient(values)),
  fetchClient: () => dispatch(fetchClient(ownProps?.params.clientId)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'updateClientForm',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate('clients'),
)(UpdateClientFormContainer);
