angular.module('webUi.ui.tagmanagement.tags.paths.delete', ['webUi.service', 'ui.bootstrap'])

    .controller('TagWebsitePathDeleteController', ['$scope', '$uibModalInstance', 'dialogsModel', function($scope, $modelInstance, dialogsModel){
        $scope.hasTags = !_.isEmpty(dialogsModel.tags);
        $scope.name = dialogsModel.node.id.replace(/\|/g,' > ');

        $scope.submit = function() {
            $modelInstance.close(true);
        };
        $scope.close = function() {
            $modelInstance.close(false);
        };
    }]);
