/**
 *
 * @description Angular module for urlBuilder -> Base
 * @class AdminUrlBuilderBaseController
 * @memberOf webUi
 * @namespace webUi.ui.admin.urlBuilder
 */
angular
    .module('webUi.ui.admin.urlBuilder.base', ['webUi.ui.admin.base'])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.urlBuilder', {
                url: '/urlbuilder',
                isReact: true,
            });
        },
    ])
    .controller('AdminUrlBuilderBaseController', [
        /**
         * @function AdminUrlBuilderBaseController
         * @memberOf webUi.ui.admin.urlBuilder
         * @returns {AdminUrlBuilderBaseController}
         */
        function AdminUrlBuilderBaseController() {},
    ]);
