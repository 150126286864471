import { ConnectorErrors } from './types';

export const mapErrorResponse = (connectorErrors: ConnectorErrors) => ({
  ...connectorErrors,
  errors: connectorErrors.errors.map(error => ({
    ...error,
    errorCode: error.errorIdentifier,
    // errorSubCode: hasErrorSubCode?.[1],
  })),
});
