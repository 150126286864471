import React, { useState } from 'react';
import ActionButtons from '~/components/src/ActionButtons';
import InputElement from '~/components/src/Form/Elements/InputElement';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { updateExpName } from './dataService';
import { EditExperimentNameProps } from './types';

const EditExperimentName = ({ experiment, closeModal, refetch }: EditExperimentNameProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [name, setName] = useState(experiment.name);

  const handleEdit = async () => {
    setIsUpdating(true);

    const payload = {
      experimentId: experiment.experimentId,
      name,
    };

    await updateExpName(payload).then(() => {
      refetch();
      closeModal();
      showSuccess({ header: i18n.t('experiments:notifications.expNameEditSuccess') });
    });
    setIsUpdating(false);
  };

  return (
    <div className="mt-4 flex flex-col gap-2">
      <p className="text-gray-600">{i18n.t('experiments:form.name')}</p>
      <InputElement
        type="text"
        id="name"
        className="t-experimentName"
        defaultValue={name}
        onChange={e => setName(e.target.value)}
      />
      <ActionButtons
        onConfirm={handleEdit}
        onDecline={() => closeModal()}
        className="m-0 pt-4"
        confirmText={i18n.t('experiments:form.editName')}
        testHook="updateExpName"
        isConfirmEnabled={!!name.trim() && !isUpdating}
      />
    </div>
  );
};

export default EditExperimentName;
