import { getPayloadTypes } from '../../connectorFormService';
import connectorFormActionTypes from '../../connectorFormActionTypes';

const facebookActionTypes = {
  FETCH_PAYLOAD_TYPES: {
    pending: 'FETCH_PAYLOAD_TYPES/pending',
    fulfilled: 'FETCH_PAYLOAD_TYPES/fulfilled',
    rejected: 'FETCH_PAYLOAD_TYPES/rejected',
  },
  SET_PAYLOAD_TYPES: 'SET_PAYLOAD_TYPES',
};

const fetchPayloadTypes = partner => dispatch => {
  dispatch({
    name: facebookActionTypes.FETCH_PAYLOAD_TYPES.pending,
    type: connectorFormActionTypes.FACEBOOK,
  });

  return getPayloadTypes(partner)
    .then(payload => {
      dispatch({
        name: facebookActionTypes.FETCH_PAYLOAD_TYPES.fulfilled,
        type: connectorFormActionTypes.FACEBOOK,
      });
      dispatch({
        name: facebookActionTypes.SET_PAYLOAD_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.FACEBOOK,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.FACEBOOK,
        name: facebookActionTypes.FETCH_PAYLOAD_TYPES.rejected,
      });
    });
};

export { facebookActionTypes, fetchPayloadTypes };
