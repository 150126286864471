import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css';

import i18n from '~/i18n';
import { useAPI } from '~/common';
import { buildUrl } from '~/common/history';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import LeftPanel from '~/components/src/LeftPanel/LeftPanel';
import Notification from '~/components/src/Notification';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spin from '~/components/src/Spin';

import { filterTree, getTreeData } from './utils';
import UrlBuilderContent from './UrlBuilderContent';
import {
  fetchCampaignTypes,
  fetchCampaignsTree,
  fetchColumnTypes,
  fetchColumns,
  fetchPredefinedValues,
} from './dataService';
import { CampaignsTree } from './components/CampaignsTree';
import { CampaignsForm } from './components/CampaignsForm';
import { UrlBuilderContext } from './UrlBuilderContext';
import { CampaignTreeNode } from './types';

const DashboardContent = ({ hasCampaigns }: { hasCampaigns: boolean }) => {
  if (!hasCampaigns) {
    return (
      <div className="ml-8 mr-8 mt-12">
        <Notification kind="information">{i18n.t('campaigns:description.line')}</Notification>
        <Notification kind="information">{i18n.t('campaigns:messages.noCampaigns')}</Notification>
      </div>
    );
  }

  return null;
};

const UrlBuilder = ({
  hasUrlBuilderPermission,
  isDashboardPage,
}: {
  hasUrlBuilderPermission: boolean;
  isDashboardPage?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();

  const { campaignId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const [isNewCampaignFormVisible, setIsNewCampaignFormVisible] = useState(false);
  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(false);
  const [treeData, setTreeData] = useState<CampaignTreeNode[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [refetchTreeFlag, setRefetchTreeFlag] = useState(true);

  const { data, isLoading: isDataLoading } = useAPI(async () => {
    const columns = await fetchColumns();
    const columnTypes = await fetchColumnTypes();
    const predefinedValues = await fetchPredefinedValues();
    const campaignTypes = await fetchCampaignTypes();
    return { columns, columnTypes, predefinedValues, campaignTypes };
  }, []);

  useEffect(() => {
    if (refetchTreeFlag) {
      setIsLoading(true);
      fetchCampaignsTree()
        .then(response => getTreeData(response, [], campaignId))
        .then(setTreeData)
        .then(() => {
          setIsLoading(false);
          setRefetchTreeFlag(false);
        });
    }
  }, [refetchTreeFlag]);

  useEffect(() => {
    if (isDashboardPage && treeData && treeData[0]?.children[0]) {
      // Don't stay on the Dashboard page if we can show a campaign
      navigate(buildUrl(`tagmanagement/urlbuilder/campaign/view/${treeData[0]?.children[0]?.nodeId}`));
    }
  }, [isDashboardPage, treeData]);

  if (isDataLoading || !data || !treeData) return <Spin />;

  const { columns, columnTypes, predefinedValues, campaignTypes } = data;
  const tree = filterTree(treeData || [], searchQuery);

  return (
    <UrlBuilderContext.Provider
      value={{
        hasUrlBuilderPermission,
        columns,
        columnTypes,
        predefinedValues,
        campaignTypes,
        navigate,
        setTreeData,
        refetchTree: () => setRefetchTreeFlag(true),
      }}
    >
      <div className="flex h-full items-stretch overflow-hidden">
        <LeftPanel
          className={cx('w-72', { '!w-96': isLeftPanelExpanded })}
          isLoading={isLoading}
          localStorageKey="urlBuilderLeftPanelExpanded"
        >
          <div className="flex h-full flex-col">
            <div className="relative flex items-center gap-2 border-b border-gray-200 bg-gray-50 p-4">
              <SearchElement
                className="flex-1"
                onChange={event => setSearchQuery(event.target.value)}
                placeholder={i18n.t('campaigns:searchCampaigns')}
                testHook="campaignSearch"
                value={searchQuery}
              />
              <BtnIcon
                color="blue"
                disabled={!hasUrlBuilderPermission}
                icon="add"
                onClick={() => setIsNewCampaignFormVisible(!isNewCampaignFormVisible)}
                testHook="createCampaign"
                tooltip={
                  hasUrlBuilderPermission
                    ? i18n.t('campaigns:createCampaign')
                    : i18n.t('campaigns:createCampaignNoPermissions')
                }
              />
              <BtnIcon
                className={cx('absolute right-[-8px] top-[54px] h-5 w-5 rounded-full p-0.5', {
                  'rotate-180 transition': isLeftPanelExpanded,
                })}
                onClick={() => setIsLeftPanelExpanded(!isLeftPanelExpanded)}
                icon="cheveronRight"
              />
            </div>

            <CampaignsTree
              treeData={tree}
              campaignId={campaignId}
              theme={FileExplorerTheme}
              searchQuery={searchQuery}
            />
          </div>
        </LeftPanel>
        <div className="flex-1 overflow-y-scroll bg-[#f6f6f6]">
          {campaignId ? (
            <UrlBuilderContent campaignId={campaignId} />
          ) : (
            <DashboardContent hasCampaigns={!isLoading && treeData.length > 0} />
          )}
        </div>
      </div>
      {isNewCampaignFormVisible && (
        <PickerView
          handlePickerVisibility={setIsNewCampaignFormVisible}
          pickerTitle={i18n.t('campaigns:form.header')}
          className="!h-auto !w-[40%]"
        >
          <CampaignsForm hideModal={() => setIsNewCampaignFormVisible(false)} />
        </PickerView>
      )}
    </UrlBuilderContext.Provider>
  );
};

export default UrlBuilder;
