angular.module('webUi.service.toast', ['toaster']).factory('ToasterService', ['toaster', function(toaster) {
    var service = {};
    const styles = {
        info: {
            container: 'border border-blue-200 bg-blue-50 text-slate-600 dark:text-slate-200',
            icon: 'text-blue-600',
        },
        warning: {
            container: 'border border-orange-200 bg-orange-50 text-slate-600 dark:text-slate-200',
            icon: 'text-orange-600',
        },
        error: {
            container: 'border border-red-200 bg-red-50 text-slate-600 dark:text-slate-200',
            icon: 'text-red-600',
        },
        success: {
            container: 'border border-green-200 bg-green-50 text-slate-600 dark:text-slate-200',
            icon: 'text-green-600',
        },
    };

    service.success = function(message) {
        toaster.pop({
            body: 'toaster-directive',
            bodyOutputType: 'directive',
            directiveData: {
                type: 'success',
                title:'Success',
                message: message,
                container: styles['success'].container,
                icon: styles['success'].icon,
            },
        });
    };

    service.error = function(message) {
        toaster.pop({
            body: 'toaster-directive',
            bodyOutputType: 'directive',
            directiveData: {
                type: 'error',
                title:'Error',
                message: message,
                container: styles['error'].container,
                icon: styles['error'].icon,
            },
        });
    };
  
    service.warning = function(message) {
        toaster.pop({
            body: 'toaster-directive',
            bodyOutputType: 'directive',
            directiveData: {
                type: 'warning',
                title:'Warning',
                message: message,
                container: styles['warning'].container,
                icon: styles['warning'].icon,
            },
        });
    };
  
    service.info = function(message) {
        toaster.pop({
            body: 'toaster-directive',
            bodyOutputType: 'directive',
            directiveData: {
                type: 'info',
                title: 'Info',
                message: message,
                container: styles['info'].container,
                icon: styles['info'].icon,
            },
        });
    };
  
    return service;
}]);
  