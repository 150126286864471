angular.module('webUi.service.profiles.dependencies', [
    'restangular',
    'webUi.service.profilesConstants'
])
    .factory('DependencyService', ['Restangular', '$q', 'OVERVIEW_TYPES_CONSTANTS', 'USAGE_OVERVIEW_CONSTANTS',
        function( Restangular, $q, OVERVIEW_TYPES_CONSTANTS, USAGE_OVERVIEW_CONSTANTS) {

            var BASE_PATH = 'profiles/dependencies/';


            var getAllDependencies = function getAllDependenciesProperty(elementId, property, isGettingProps) {
                let requestDependencyUrl = `${BASE_PATH}${elementId}`;
                if(isGettingProps) {
                    requestDependencyUrl += `/${property}`;
                }

                return Restangular.one(requestDependencyUrl).get().then(function(result) {
                    if(result) {
                        return Restangular.stripRestangular(result);
                    }
                });
            };

            var getUsedProperties = function getUsedProperties(elementId) {
                return Restangular.one(BASE_PATH + 'canDelete', elementId).get().then(function (canDeleteElement) {
                    if(canDeleteElement) {
                        return Restangular.stripRestangular(canDeleteElement);
                    }
                });
            };

            var hasDependants = function hasDependants(elementId) {
                return Restangular.one(BASE_PATH + 'canDelete', elementId).get().then(function(canDeleteElement) {
                    if(canDeleteElement) {
                        var data = Restangular.stripRestangular(canDeleteElement);
                        return !data.canDelete;
                    }
                });
            };

            var getDependants = function getDependants(elementId) {
                return Restangular.one(BASE_PATH + 'types', elementId).get().then( (results) => {
                    if(results) {
                        return Restangular.stripRestangular(results);
                    }
                });
            };

            var getOverviewTypes = function getOverviewTypes (sourceModule, dependants) {
                let disableType = (sourceModule === OVERVIEW_TYPES_CONSTANTS.interaction) ? OVERVIEW_TYPES_CONSTANTS.disabledAdType : OVERVIEW_TYPES_CONSTANTS.disabledCollectorType;
                let overviewTypes = _.filter(_.cloneDeep(USAGE_OVERVIEW_CONSTANTS.overviewTypes), (type) => {
                    if(!_.isEmpty(dependants)) {
                        type.usedIn = _.indexOf(dependants.dependantTypes, type.dependantKey) !== -1;
                    }
                    if(type.dependantKey === disableType){
                        type.enabled = false;
                    }
                    return type.enabled;
                });

                return overviewTypes;
            };



            return {
                getAllDependencies: getAllDependencies,
                getUsedProperties: getUsedProperties,
                hasDependants: hasDependants,
                getDependants:getDependants,
                getOverviewTypes: getOverviewTypes
            };
        }
    ]);
