angular.module('webUi.directive.draggable', [
    'webUi.service.uuid'
])

    .provider('draggableState', function DraggableStateProvider() {
        var state = {};

        return {
            $get: [function DraggableStateProvider() {
                return {
                    state: state
                };
            }]
        };
    })

/**
 * @description Cannot be used on elements other than a, img for IE reasons (9)
 * @description IE doesn't accept setData/getData type other than "Text" BUT webkits don't accept "Text" :|
 *
 */
    .directive('lvlDraggable', ['$rootScope', 'UUIDService', 'Utils', 'draggableState', function($rootScope, UUIDService, Utils, draggableState) {
        return {
            restrict: 'A',
            link: function(scope, el, attrs) {
            //get drag namespace
                var namespace = attrs['dragNamespace'];

                //watch the expression to check if the element is draggable or not
                scope.$watch(attrs.lvlDraggable, function lvlDraggable(newValue) {
                    angular.element(el).attr('draggable', newValue);
                });

                //get the id of the element
                var id = attrs['id'];

                //assign an id if not existent
                if (!id) {
                    id = UUIDService.newId();
                    angular.element(el).attr('id', id);
                }

                el.bind('dragstart', function dragstart(event) {
                //return false when set draggable to false
                    if (!event.originalEvent.target.draggable) {
                        return;
                    }
                    //set drag ico to the data-name attribute of the dragged element
                    //drag ico = label next to the mouse pointer
                    //@todo: improve feature detection for IE 9...->
                    if('setDragImage' in event.originalEvent.dataTransfer) {
                        var crt;
                        if (!angular.element('.r42-draggable-styled').length) {
                            crt = angular.element(document.createElement('span'));
                            crt.addClass('r42-draggable-styled');
                            angular.element('body').append(crt);
                        } else {
                            crt = angular.element('.r42-draggable-styled');
                        }
                        //Name to show in the box
                        var name = el.data('name') || 'draggableElementName';

                        crt.text(Utils.escapeHtml(name));
                        // Sizes to put the cursor in the place the user select
                        var x = (crt.width() * event.originalEvent.offsetX) / el.width();
                        var y = (crt.height() / 2);
                        // When the user select from a small handle, set 0,0 cursor
                        if (el.width() < 15) {
                            x = 0;
                            y = 0;
                        }
                        event.originalEvent.dataTransfer.setDragImage(crt[0], x, y);
                    }

                    if(el.hasClass('drag-end')) {
                        el.removeClass('drag-end');
                    }

                    el.addClass('drag-start');
                    //id of dragged element is stored with key draggableId
                    var _keyTypeDragEvent = (Utils.detectIe()) ? 'Text' : 'draggableId';
                    event.originalEvent.dataTransfer.setData(_keyTypeDragEvent, id);

                    $rootScope.$emit('DRAG-START', namespace);
                    draggableState.state.activeDragNameSpace = namespace;
                });

                el.bind('dragend', function dragend() {

                    if(el.hasClass('drag-start')) {
                        el.removeClass('drag-start');
                    }

                    el.addClass('drag-end');

                    $rootScope.$emit('DRAG-END');
                    draggableState.state.activeDragNameSpace = undefined;
                });
            }
        };
    }])

/**
 * @description Cannot be used on elements other than a, img for IE reasons
 * @description IE doesn't accept setData/getData type other than "Text"
 */
    .directive('lvlDropTarget', ['$rootScope', '$timeout', 'UUIDService', 'Utils', 'draggableState', 'ValidService', function($rootScope, $timeout, UUIDService, Utils, draggableState, ValidService) {
        return {
            restrict: 'A',
            scope: {
                onDrop: '&',
                onHover: '&',
                dropNamespace: '@'
            },
            link: function postLink(scope, el, attrs) {
                var id = attrs['id'];

                var dragNamespace = '';
                var lvlDroppable = attrs['lvlDropTarget'] !== '' &&  attrs['lvlDropTarget'] !== 'false';
                angular.element(el).attr('droppable', lvlDroppable);
                if(!_.isUndefined(draggableState.state.activeDragNameSpace)) {
                    dragNamespace = draggableState.state.activeDragNameSpace.split(',');
                }

                var dropNamespace = scope.dropNamespace;
                //assign an id if not existent
                if (!id) {
                    id = UUIDService.newId();
                    angular.element(el).attr('id', id);
                }

                // Check if is a valid drag element for current targets
                if (!(ValidService.isEmpty(dragNamespace) || ValidService.isEmpty(dropNamespace)) && _.contains(dragNamespace, dropNamespace)) {
                    el.addClass('droppable-target-active');
                }

                el.bind('dragover', function dragover(event) {
                // Necessary. Allows us to drop.
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    event.originalEvent.dataTransfer.dropEffect = 'move';
                    return false;
                });

                /**
             * Keep a count of when you entered the element, then it won't matter when you enter its children because the count is larger than 0
             * Example of a perfect stackoverflow answer: http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
             *
             */
                var dragEnterCount = 0;
                el.bind('dragenter', function dragenter(event) {

                    dragEnterCount++;

                    $timeout(function() {
                        scope.onHover();
                    }, 300);

                    // Check if is a valid drag element for target
                    if (_.contains(dragNamespace, dropNamespace)) {
                        angular.element(event.target).addClass('droppable-target-over');
                    }
                });

                el.bind('dragleave', function dragleave(event) {
                    dragEnterCount--;
                    //if the net number of enters minus leaves is zero, then consider you left the parent element and remove the class
                    if(dragEnterCount === 0) {
                    // this / e.target is previous target element.
                        angular.element(event.target).removeClass('droppable-target-over');
                    }
                });

                el.bind('drop', function(event) {

                    if (event.preventDefault) {
                        event.preventDefault(); // Necessary. Allows us to drop.
                    }
                    //return false when set droppable to false
                    if (lvlDroppable !== true) {
                        return;
                    }
                    //get the id of the dragged element by key
                    var _keyTypeDragEvent = (Utils.detectIe()) ? 'Text' : 'draggableId';
                    var dragId = event.originalEvent.dataTransfer.getData(_keyTypeDragEvent);
                    var src =  angular.element(document.getElementById(dragId));

                    // Check if is a valid drag element
                    if (!ValidService.isEmpty(dropNamespace)  && !(_.contains(dragNamespace, dropNamespace))) {
                        return;
                    }

                    // This ugly way is to avoid issues un Firefox :(
                    var dest = angular.element(document.getElementById(angular.element(el).attr('id')));

                    //Transport data through drag-value and drop-value attributes
                    scope.onDrop({dragEl: src.data('drag-value'), dropEl: dest.data('drop-value')});
                    dragEnterCount = 0;
                    scope.$apply();
                });

                //Listeners
                var dragStartCleanupFn = $rootScope.$on('DRAG-START', function onDragStart(event, namespace) {
                //get one or more namespaces
                    if (!ValidService.isEmpty(namespace)) {
                        dragNamespace = namespace.split(',');
                    }

                    // Check if is a valid drag element for target
                    if (_.contains(dragNamespace, dropNamespace)) {
                        el.addClass('droppable-target-active');
                    }
                });

                var dragEndCleanupFn = $rootScope.$on('DRAG-END', function onDragEnd() {
                // This ugly way is to avoid issues with the tree Directive
                    var _element = angular.element(document.getElementById(angular.element(el).attr('id')));
                    //@todo marius: fix teh hackz; tl;dr; replace with jq and a better selector
                    angular.element(_element).parent().find('.droppable-target-active').removeClass('droppable-target-active');
                    angular.element(_element).parent().find('.droppable-target-over').removeClass('droppable-target-over');

                    if (angular.element('.r42-draggable-styled').length) {
                        angular.element('.r42-draggable-styled').remove();
                    }
                });
                //Avoid memory leak
                scope.$on('$destroy', function() {
                    dragStartCleanupFn();
                    dragEndCleanupFn();
                });
            }
        };
    }]);
