import React from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import { formatNumber } from '~/common';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import Icon from '~/components/src/Icon';
import { R42Question } from '~/components/src/R42Icons';
import Tooltip from '~/components/src/Tooltip';
import HighchartsMore from 'highcharts/highcharts-more';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import './styles.scss';

NoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);

type StackedChartProps = {
  data: any;
  tooltip?: any;
  className?: string;
  height?: number;
  hideGenealInfo?: boolean;
  reversedYStacks?: boolean;
};

const GeneralStatData = ({ series }: { series: any }) => (
  <div className="StackedChart-generalData">
    <span style={{ color: series?.color }} className="StackedChart-generalData--count">
      {formatNumber(_.sum(series?.data || [])) || 0}
    </span>
    <div>
      <span className="StackedChart-generalData--label">{series?.name}</span>
      {series?.tooltip && (
        <Tooltip tooltip={series?.tooltip}>
          <Icon icon={R42Question} className="StackedChart-generalDataTooltip" size="12" color="#A9A9A9" />
        </Tooltip>
      )}
    </div>
  </div>
);

const StackedChart = ({
  data,
  height,
  tooltip,
  className,
  hideGenealInfo = false,
  reversedYStacks = true,
}: StackedChartProps) => {
  const applyTooltip = () => {
    if (tooltip) {
      return tooltip;
    }
    return {
      formatter() {
        return `<b> ${this.x} </b> <br/> ${this.series.name} : ${this.y}`;
      },
    };
  };

  const options: any = {
    styledMode: true,
    credits: false,
    chart: {
      type: 'column',
      plotBorderWidth: 0,
      height: height || 250,
      style: {
        fontFamily: 'Roboto, sans-serif',
        marginTop: '20px',
      },
      events: {
        load() {
          // Left Blank
        },
      },
    },
    title: null,
    subtitle: null,
    xAxis: {
      categories: data.xAxis,
    },

    yAxis: {
      allowDecimals: false,
      reversedStacks: reversedYStacks,
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: applyTooltip(),
    legend: { align: 'left' },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: data.series,
    lang: {
      noData: data.noDataMessage,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
  };

  return (
    <div className={className}>
      {!hideGenealInfo && (
        <div className="StackedChart-header">
          {data?.series?.map((series: any, i: number) => <GeneralStatData series={series} key={`StackedChart-${i}`} />)}
        </div>
      )}
      <HighChartWithZoom highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StackedChart;
