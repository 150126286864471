angular.module('webUi.ui.test.validation.base', [
    'webUi.ui.test.base'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.test.validation', {
                url: '/validation',
                views : {
                    'leftMenu@' : {
                        templateUrl: 'ui/test/validation/base/base.tpl.html',
                        controller: 'ValidationBaseController as vm'
                    }
                },
                leftMenu : true,
                resolve : {
                    developers: ['TestService', function(TestService) {
                        return TestService.getDevelopers();
                    }]
                }
            });
    }])
    .controller('ValidationBaseController', ['$scope', 'developers', 'TestService', 'PubSubService',
        function ValidationBaseController($scope, developers, TestService, PubSubService) {

            'use strict';
            
            var vm = this;

            vm.data = {
                developers: developers
            };

            PubSubService.subscribeTypeSelected(PubSubService.TYPES.SUPPORT.TEST, $scope, function(event, data) {
                vm.data.selectedDeveloperId = data.id;
            });

            PubSubService.subscribeForType(PubSubService.TYPES.SUPPORT.TEST, $scope, function() {
                TestService.getDevelopers().then(function(developers) {
                    vm.data.developers = developers;
                });
            });

        }]);
