import React, { useContext, useEffect, useState } from 'react';
import { Notification, PickerView } from '~/components';
import DeletePopup from '~/components/src/DeletePopup';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { deleteTransition } from '~/workflows/dataService';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import EditExperiment from '../components/EditExperiment';
import Links from '../components/Links';
import Nodes from '../components/Nodes';
import TransitionModalContent from '../components/TransitionModalContent';
import Zoom from '../components/Zoom';
import { ErrorCodes } from '../constants';
import { JourneyContext } from '../JourneyContext';
import JourneyHeader from '../JourneyHeader';
import { JourneyValidationError } from '../types';
import { CanvasContext } from './CanvasContext';
import { convertForDisplay, deleteLocalStorageValue, getLocalStorageValue } from './utils';
import './styles.scss';

const CanvasSvgWrap = ({ children }: { children: React.ReactChild }) => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
);

const JourneyCanvas = ({
  journeyErrors,
  showJourneyErrors,
  setJourneyErrors,
  setNodeToDelete,
  setShowJourneyErrors,
}: {
  journeyErrors: JourneyValidationError[];
  showJourneyErrors: boolean;
  setJourneyErrors: (errors: any) => void;
  setNodeToDelete: (node: any) => void;
  setShowJourneyErrors: (val: boolean) => void;
}) => {
  const { journeyData, journeyTree, hasExperiments, experimentSteps, refreshJourney } = useContext(JourneyContext);
  const { hasWorkflowJourneyEdit: canEdit } = useContext(WorklfowContext);

  const [collapsedNodeIds, setCollapsedNodeIds] = useState<string[]>([]);
  const [transitionSource, setTransitionSource] = useState(null);
  const [showExperimentSettingsModal, setShowExperimentSettingsModal] = useState(false);
  const [transistionToDelete, setTransistionToDelete] = useState<{ nodeId: string } | null>(null);
  const [showTransistionModal, setShowTransistionModal] = useState<string | null>(null);

  const journeyId = journeyData?.journeyId || '';
  const { nodes, links } = convertForDisplay(journeyTree, journeyId, collapsedNodeIds);

  let isSumOfVariantsValid = true;
  let hasStepsAfterFirstTrigger = false;
  let startTriggerError = false;
  let nodesErrors = [];

  if (showJourneyErrors) {
    startTriggerError = !!journeyErrors.find(error => error.errorCode === ErrorCodes.JOURNEY_HAS_NO_START_TRIGGER);
    nodesErrors = journeyErrors
      .filter((error: any) => error.nodeId || error.stepId)
      .reduce((errorsMap: any, error: any) => {
        const { errorType, errorCode } = error;
        const nodeId = error.stepId || error.nodeId;

        if (!errorsMap[nodeId]) {
          errorsMap[nodeId] = {
            [ErrorCodes.DATA_ERROR]: [],
            [ErrorCodes.STRUCTURE_ERROR]: [],
            other: [],
          };
        }

        if ([ErrorCodes.DATA_ERROR, ErrorCodes.STRUCTURE_ERROR].includes(errorType)) {
          errorsMap[nodeId][errorType].push(errorCode);
        } else {
          errorsMap[nodeId].other.push(errorCode);
        }
        return errorsMap;
      }, {});
  }

  if (experimentSteps.length > 0) {
    hasStepsAfterFirstTrigger = true;
    const weightOfVariants = experimentSteps.reduce((acc, step) => acc + (step?.experimentVariant?.weight || 0), 0);
    isSumOfVariantsValid = weightOfVariants === 100;
  }

  useEffect(() => {
    const stepParentId = getLocalStorageValue(`${journeyId}_stepParentTriggerId`);
    if (hasExperiments && experimentSteps.find(step => step.parentTriggerId === stepParentId)) {
      setShowExperimentSettingsModal(true);
    }
  }, [journeyId]);

  const handleExpandAndCollapse = (nodeId: string) => {
    if (collapsedNodeIds.includes(nodeId)) {
      setCollapsedNodeIds(collapsedNodeIds.filter(id => id !== nodeId));
    } else {
      setCollapsedNodeIds([...collapsedNodeIds, nodeId]);
    }
  };

  const handleDeleteTransistion = () => {
    if (transistionToDelete) {
      deleteTransition(journeyId, transistionToDelete.nodeId);
      showSuccess({ header: i18n.t('workflow:orchestration.transitions.transitionDeleted') });
      refreshJourney();
    }
  };

  const handleHideExperimentSettingsModal = () => {
    deleteLocalStorageValue(`${journeyId}_stepParentTriggerId`);
    setShowExperimentSettingsModal(false);
  };

  return (
    <CanvasContext.Provider
      value={{
        hasExperiments,
        nodesErrors,
        isSumOfVariantsValid,
        transitionSource,
        journeyErrors,
        setJourneyErrors,
        setTransitionSource,
        setTransistionToDelete,
        handleExpandAndCollapse,
        setNodeToDelete,
        setShowTransistionModal,
        setShowJourneyErrors,
      }}
    >
      <div className="JoCanvas">
        <JourneyHeader
          canEdit={canEdit}
          hasStepsAfterFirstTrigger={hasStepsAfterFirstTrigger}
          setShowExperimentSettingsModal={setShowExperimentSettingsModal}
        />
        <CanvasSvgWrap>
          <Zoom nodes={nodes}>
            <Links links={links} />
            <Nodes
              nodes={nodes}
              canEdit={canEdit}
              startTriggerError={startTriggerError}
              showExperimentSettingsModal={() => setShowExperimentSettingsModal(true)}
            />
          </Zoom>
        </CanvasSvgWrap>
        {showExperimentSettingsModal && (
          <PickerView
            className="!h-auto min-h-[300px] !w-auto min-w-[400px]"
            pickerTitle={i18n.t('workflow:journey.experimentModal.title')}
            handlePickerVisibility={handleHideExperimentSettingsModal}
          >
            <EditExperiment
              experimentSteps={experimentSteps}
              fetchJourneyData={refreshJourney}
              hideModal={handleHideExperimentSettingsModal}
            />
          </PickerView>
        )}
        {showTransistionModal && (
          <PickerView
            className="!h-[500px] min-h-[300px] !w-[500px] min-w-[400px]"
            pickerTitle={i18n.t('workflow:orchestration.common.modal.pickStep')}
            handlePickerVisibility={() => setShowTransistionModal(null)}
          >
            <TransitionModalContent
              hideModal={() => setShowTransistionModal(null)}
              journeyId={journeyId}
              refresh={() => refreshJourney()}
              triggerId={showTransistionModal}
            />
          </PickerView>
        )}
        {transistionToDelete && (
          <DeletePopup
            title={i18n.t('workflow:orchestration.common.modal.deleteTransition')}
            onClick={() => handleDeleteTransistion()}
            hidePopup={() => setTransistionToDelete(null)}
          >
            <Notification kind="error">
              {i18n.t('workflow:orchestration.common.modal.confirmTransitionDelete')}
            </Notification>
          </DeletePopup>
        )}
      </div>
    </CanvasContext.Provider>
  );
};

export default JourneyCanvas;
