import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import InputElement from '~/components/src/Form/Elements/InputElement';
import DateTimeElement from '~/components/src/Form/Elements/DateTimeElement';
import TextAreaElement from '~/components/src/Form/Elements/TextAreaElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';

const FormikField = ({
  name,
  type,
  placeholder,
  value,
  label,
  hintText,
  formElement: Element,
  onBlur,
  onChange,
  errorText,
  horizontal,
  className,
  labelClassName,
  min,
  autoFocus,
  options,
  getOptionLabel,
  getOptionValue,
  isLoading,
  suffix,
  customSetFieldValue,
  hideError,
  isOptionDisabled,
  isClearable,
  isCreatable,
  htmlFor,
  testHook,
  enableAction,
  buttonIcon,
  buttonTooltip,
  buttonTestHook,
  onToggleButton,
  isValidDate,
  excludeErrorOrHint,
  isMulti,
  isSearchable,
  isValidNewOption,
  createOptionPosition,
  showOptionsCount,
  closeMenuOnSelect,
  hideSelectedOptions,
  disabled,
  menuPosition,
  components,
  formatGroupLabel,
  formatCreateLabel,
  getNewOptionData,
  elementStyles,
  defaultValue,
  minRows,
  infoTooltip,
}) => {
  const form = useFormikContext();
  const isTouched = get(form.touched, name);
  const errorTextToShow = !hideError && (isTouched || form.submitCount > 0) && errorText;

  return (
    <FieldWrapper
      label={label}
      errorText={errorTextToShow}
      hintText={hintText}
      horizontal={horizontal}
      className={className}
      labelClassName={labelClassName}
      suffix={suffix}
      htmlFor={htmlFor || name}
      name={name}
      excludeErrorOrHint={excludeErrorOrHint}
      infoTooltip={infoTooltip}
    >
      <Element
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        hasError={!!errorTextToShow}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        autoFocus={autoFocus}
        customSetFieldValue={customSetFieldValue}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        isClearable={isClearable}
        isCreatable={isCreatable}
        id={htmlFor || name}
        testHook={testHook}
        enableAction={enableAction}
        buttonIcon={buttonIcon}
        buttonTooltip={buttonTooltip}
        buttonTestHook={buttonTestHook}
        onToggleButton={onToggleButton}
        isValidDate={isValidDate}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isValidNewOption={isValidNewOption}
        createOptionPosition={createOptionPosition}
        showOptionsCount={showOptionsCount}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        setFieldTouched={form.setFieldTouched}
        menuPosition={menuPosition}
        components={components}
        formatGroupLabel={formatGroupLabel}
        formatCreateLabel={formatCreateLabel}
        getNewOptionData={getNewOptionData}
        styles={elementStyles}
        minRows={minRows}
      />
    </FieldWrapper>
  );
};

export const FormikInputField = props => <FormikField {...props} formElement={InputElement} />;

export const FormikTextAreaField = props => <FormikField {...props} formElement={TextAreaElement} />;

export const FormikSelect = ({ customSetFieldValue, setFieldTouched, ...props }) => {
  const { setFieldValue: formikSetFieldValue } = useFormikContext();
  const setFieldValue = customSetFieldValue || formikSetFieldValue;

  return (
    <SelectElement
      {...props}
      onChange={selectedOption => {
        if (!selectedOption) {
          setFieldValue(props.name, '');
          return;
        }
        setFieldValue(props.name, selectedOption);
      }}
      onMenuClose={() => {
        setFieldTouched(props.name);
      }}
    />
  );
};

const FormikDate = ({ customSetFieldValue, ...props }) => {
  const { setFieldValue: formikSetFieldValue } = useFormikContext();
  const setFieldValue = customSetFieldValue || formikSetFieldValue;

  return (
    <DateTimeElement
      {...props}
      timeFormat={false}
      onChange={date => {
        if (!date) {
          setFieldValue(props.name, null);
          return;
        }
        setFieldValue(props.name, date);
      }}
    />
  );
};

export const FormikDateField = props => <FormikField {...props} formElement={FormikDate} />;

export const FormikSelectField = props => <FormikField {...props} formElement={FormikSelect} />;

export const FormikCheckboxField = props => <FormikField {...props} formElement={CheckboxElement} />;
