import React from 'react';
import cx from 'classnames';
import { formatNumber } from '~/common';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { LabelValue } from './LabelValue';

type ItemStatsProps = {
  label: string;
  reached: number;
  conversion?: number | number;
  hasConversion?: boolean;
  testHook: string;
  type: string;
};

const ItemStats = ({
  label,
  reached,
  conversion,
  hasConversion = false,
  testHook = 'stats',
  type,
}: ItemStatsProps): React.ReactElement => (
  <div className={cx(`t-${testHook}`, 'rounded-xl border border-gray-200 bg-white p-2')}>
    <div className={cx(`t-${testHook}Label`, 'text-xs font-medium text-gray-600')}>{label}</div>
    <LabelValue
      label={i18n.t(`workflow:journey.${type === NodeTypes.GOAL ? 'reached' : 'exited'}`)}
      testHook="profilesReached"
      value={formatNumber(reached)}
    />
    {hasConversion && conversion && (
      <LabelValue testHook="conversionRate" label={i18n.t('workflow:journey.conversionRate')} value={conversion} />
    )}
  </div>
);

export default ItemStats;
