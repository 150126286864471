import moment from 'moment';
import { isEqual } from 'lodash';
import { changeNodeAtPath, removeNodeAtPath, toggleExpandedForAll, walk } from 'react-sortable-tree';
import { NewPathsType, NodeInfo } from './types';
import { DATE_FORMAT, TIME_FORMAT } from './constants';

export const convertDate = (time: number): string =>
  `${moment(time).format(DATE_FORMAT)} - ${moment(time).format(TIME_FORMAT)}`;

export const getLastItem = (treeData: NewPathsType[]): NewPathsType | null => {
  if (!treeData.length) {
    return null;
  }

  if (!treeData[treeData.length - 1].children.length) {
    return treeData[treeData.length - 1];
  }

  return getLastItem(treeData[treeData.length - 1].children);
};
export const getRandomId = (): string => Math.random().toString(36).substring(2, 10);

export const addExpandToTreeData = (treeData: NewPathsType[], selectedPathName: string): NewPathsType[] => {
  const expandParents = (node: NewPathsType): boolean => {
    if (node.id === selectedPathName) {
      return true;
    }
    if (node.children.length) {
      const expandedChild = node.children.find(childNode => expandParents(childNode));
      if (expandedChild) {
        node.expanded = true;
        return true;
      }
    }
    return false;
  };

  return treeData.map(node => {
    const shouldExpand = expandParents(node);
    return {
      ...node,
      expanded: shouldExpand,
      children: addExpandToTreeData(node.children, selectedPathName),
    };
  });
};

export const changePathShowType = (name: string): string => (name ? name.replaceAll('|', ' > ') : '');

export const expandDirectory = (
  path: string[],
  treeData: NewPathsType[],
  setTreeData: (tree: NewPathsType[]) => void,
): void => {
  const newTreeData = changeNodeAtPath({
    treeData,
    path,
    newNode: ({ node }: { node: NewPathsType }) => ({ ...node, expanded: !node.expanded }),
    getNodeKey: ({ node }) => node.id,
  });

  setTreeData(newTreeData);
};

export const getNodeKey = ({ node }: { node: NewPathsType; treeIndex: number }): string => node.id;

export const filterTree = (treeData: NewPathsType[], searchQuery: string): NewPathsType[] => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  const goodPaths: string[][] = [];
  const pathsToRemove: string[][] = [];

  // Find all nodes that match the search query and their parents
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      if (nodeInfo.node.name.toLowerCase().includes(lowerCaseSearchQuery)) {
        // If a node matches the search query, save its path, so we don't remove it later
        goodPaths.push(nodeInfo.path);

        // Also save paths of match node's parents
        for (let depth = 1; depth < nodeInfo.path.length; depth += 1) {
          goodPaths.push(nodeInfo.path.slice(0, depth));
        }
      }
    },
    ignoreCollapsed: false,
  });

  // Make a list of nodes that didn't match search query, so that we can remove them
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      const shouldStay = goodPaths.some(goodPath => isEqual(goodPath, nodeInfo.path));
      if (!shouldStay) {
        pathsToRemove.push(nodeInfo.path);
      }
    },
    ignoreCollapsed: false,
  });

  // Sort "paths to remove", to make sure we don't try to remove a path that was removed already
  pathsToRemove.sort((pathA, pathB) => {
    if (pathA.length < pathB.length) {
      return 1;
    }

    if (pathA.length > pathB.length) {
      return -1;
    }

    return 0;
  });

  let updatedTree = treeData;

  // Remove non-matched nodes from the tree
  pathsToRemove.forEach(path => {
    updatedTree = removeNodeAtPath({ treeData: updatedTree, path, getNodeKey, ignoreCollapsed: false });
  });

  if (searchQuery) {
    updatedTree = toggleExpandedForAll({ treeData: updatedTree, expanded: true });
  }

  return updatedTree;
};
