import React from 'react';
import PropTypes from 'prop-types';
import Elements from '~/components/src/Form/Elements';
import { Notification } from '~/components';
import './RequestResetPassword.scss';

const RequestResetPassword = ({ username, onInvalidateChange, t }) => {
  const input = {
    name: 'invalidatePassword',
    onChange: () => onInvalidateChange(),
  };

  return (
    <div className="ResetPassword-body">
      <Notification kind="warning">{t('resetPassword.message', { username })}</Notification>
      <div className="ResetPassword-body--checkbox mt-8">
        <Elements.Checkbox disabled={false} input={input} id={input.name} isError={false}>
          {t('resetPassword.invalidateCurrent')}
        </Elements.Checkbox>
      </div>
    </div>
  );
};

RequestResetPassword.propTypes = {
  username: PropTypes.string.isRequired,
  onInvalidateChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default RequestResetPassword;
