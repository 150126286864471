angular.module('webUi.filter', [
    'webUi.filter.accuracyNumber',
    'webUi.filter.paging',
    'webUi.filter.sortBy',
    'webUi.filter.newlines',
    'webUi.filter.capitalize',
    'webUi.filter.saferegex',
    'webUi.filter.dimensionsFilter',
    'webUi.filter.propFilter',
    'webUi.filter.treeFilter',
    'webUi.filter.orFilter',
    'webUi.filter.breadcrumb',
    'webUi.filter.formatNumber',
    'webUi.filter.groupBy',
    'webUi.filter.deepJson',
    'webUi.filter.bytes',
    'webUi.filter.lower'
]);
