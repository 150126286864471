/**
 * A service for Dashboard related queries
 */
angular.module('webUi.service.messages', [
    'restangular',
    'webUi.common.Utils'
])

    .provider('MessagesService', [function() {
	
        var Service = function(Restangular, $q, Utils) {
	
            // the base path through which all calls should go
            var BASE_PATH = 'messages/';
		
            return {
                /**
			 * Retrieves the basePath through which all calls should go
			 * @returns {String}
			 */
                getBasePath: function(){
                    return BASE_PATH;
                },
						
                getMessages : function () {
                    var messages = $q.defer();
                    Restangular.all(BASE_PATH + 'messages').getList().then(function(result){
                        result = Utils.sanitizeRestangularAll(result);
                        messages.resolve(result);
                    });
                    return messages.promise;
                }
            };
        };
	
        this.$get = ['Restangular', '$q', 'Utils', function(Restangular,   $q,   Utils) {
            return new Service(Restangular, $q, Utils);
        }];
    }]);
