/* eslint-disable no-prototype-builtins */
angular.module('webUi.directive.support.template.r42propertyType', [
    'webUi.directive.support.template.propertyType'
])
    .directive('r42propertyType', [
        function r42propertyType() {
            return {
                restrict: 'E',
                replace: true,
                scope: true,
                compile: function compile() {
                    return {
                        pre: function preLink(scope, iElement, iAttrs) {
                            scope.r42Label = iAttrs['label'];

                            scope.r42ModelMap = (iAttrs.hasOwnProperty('r42ModelMap')) ? iAttrs['r42ModelMap'] : ['type', 'name'];

                            scope.$watch(iAttrs['ngModel'], function (newValue) {
                                scope.r42Model = newValue;
                            });

                        }
                    };
                },
                templateUrl: 'directive/support/template/r42propertyType.tpl.html'
            };
        }
    ]);

