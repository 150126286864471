import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Page } from '~/components';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { showSuccess } from '~/notificationCenter';
import { buildUrl, useAPI } from '~/common';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import DoubleClickForm from './doubleClick/DoubleClickForm';
import { getDCAdvertiserById, updateDCAdvertiser } from './dataService';
import { TDCAdvertiser } from './types';

const AdvertisersFormEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || '';
  const { data: initialValue, isLoading } = useAPI(() => getDCAdvertiserById(id), []);

  const onSubmit = async (payload: TDCAdvertiser) => {
    await updateDCAdvertiser(payload);
    showSuccess({ body: i18n.t('connectors:advertisers.doubleClick.api.updated') });
    navigate(buildUrl('admin/connectors'));
  };
  return (
    <Page>
      <SmallWrapper>
        <Heading kind="h2" title={i18n.t('connectors:advertisers.form.edit')}></Heading>
        <Panel>
          {isLoading ? (
            <Spin />
          ) : (
            <DoubleClickForm
              isEdit={true}
              initialValue={(initialValue as unknown as TDCAdvertiser) || ({} as TDCAdvertiser)}
              onSubmit={onSubmit}
            />
          )}
        </Panel>
      </SmallWrapper>
    </Page>
  );
};

export default AdvertisersFormEdit;
