import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddCookieVariantPage from './CookieAddVariant';
import EditCookieVariantPage from './CookieEditVariant';
import CookieVariantsPage from './CookieVariants';
import Message from './Message';
import EditMessage from './EditMessage';
import NewMessage from './NewMessage';
import Messages from './Messages';
import TagsPages from './tags';

const SupportPages = () => (
  <Routes>
    <Route path="cookiepermissionforms/list" element={<CookieVariantsPage />} />
    <Route path="cookiepermissionforms/form" element={<AddCookieVariantPage />} />
    <Route path="cookiepermissionforms/view/:variantId" element={<EditCookieVariantPage />} />
    <Route path="messages/view/:messageId" element={<Message />} />
    <Route path="messages/edit/:messageId" element={<EditMessage />} />
    <Route path="messages/add" element={<NewMessage />} />
    <Route path="messages/list" element={<Messages />} />
    <Route path="tagtemplates/*" element={<TagsPages />} />
  </Routes>
);

export default SupportPages;
