const getValue = (parameters, key) => {
  const value = parameters[key].value || parameters[key].values;
  return Array.isArray(value) ? value.toString().replace(/,/g, ', ') : value;
};

const getProfileVariables = parameters => {
  const storedVariableNames = Object.keys(parameters);
  return storedVariableNames.map(key => `${key}: ${getValue(parameters, key)}`);
};

export default getProfileVariables;
