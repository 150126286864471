import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as types from '~/user/settings/types';
import { updatePassword, resetErrorPasswordRequest } from '~/user/settings';
import * as settingsSelectors from '~/user/settings/selectors';
import UpdatePasswordForm from '~/user/components/UpdatePasswordForm';

export class UpdatePasswordModalContainer extends Component {
  componentDidMount() {
    this.props.resetErrorPasswordRequest();
  }
  render() {
    return <UpdatePasswordForm {...this.props} />;
  }
}
export default compose(
  connect(
    (state, ownProps) => ({
      user: ownProps.user,
      t: ownProps.t,
      errorPasswordRequest: settingsSelectors.getErrorPasswordRequest(state),
      errorSmsRequest: settingsSelectors.getErrorSmsRequest(state),
    }),
    dispatch => ({
      onSubmit: values => dispatch(updatePassword(values)),
      resetErrorPasswordRequest: () => dispatch(resetErrorPasswordRequest()),
    }),
  ),
  reduxForm({
    form: types.UPDATE_PASSWORD_FORM,
  }),
)(UpdatePasswordModalContainer);
