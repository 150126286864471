import React, { ReactElement, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Formik } from 'formik';

import { buildUrl, changeUrl, useAPI } from '~/common';
import { Heading, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';

import { getTransformation } from './dataService';
import { TransformationDependentType } from './types';
import TransformerDependency from './TransformationDependency';
import Event from './components/Events';
import ValueTransformationsModal from './components/ValueTransformationsModal';
import { TransformationContext } from './context';

function TransformationView(): ReactElement {
  const params = useParams();
  const transformationId = params.transformationId || '';

  const contextValues = useContext(TransformationContext);
  const { canEditProfiles } = contextValues;

  const { data: transformation, isLoading } = useAPI(() => getTransformation(transformationId));
  const [mappingPath, setMappingPath] = useState<string>('');

  if (isLoading) return <Spin />;

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50">
      <div className="w-2/3">
        <TransformationContext.Provider value={{ ...contextValues, setMappingPath }}>
          <Formik onSubmit={() => undefined} initialValues={{}}>
            {({ handleSubmit }) => (
              <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <Heading
                  testHook="transformationHeader"
                  title={transformation?.name || i18n.t('transformations:list.header')}
                  crumbs={[
                    {
                      title: i18n.t('transformations:back'),
                      pathname: buildUrl('profiles/transformations'),
                    },
                  ]}
                  truncateHeader
                >
                  {canEditProfiles && (
                    <BtnIcon
                      onClick={() => changeUrl(`profiles/transformations/edit/${transformationId}`)}
                      testHook="editTransformation"
                      color="blue"
                      icon="edit"
                      tooltip={i18n.t('transformations:tooltips.edit')}
                    />
                  )}
                </Heading>

                <div className="flex w-full flex-col gap-4">
                  <p className="text-xl text-blue-600">{i18n.t('transformations:form.events')}</p>
                  {transformation?.events?.length ? (
                    <div className="flex w-full flex-col gap-2 rounded border-dashed border-gray-300">
                      {transformation.events.map((rule, eventIndex) => (
                        <Event key={eventIndex} event={rule} eventPath={`events[${eventIndex}]`} isReadOnly />
                      ))}
                    </div>
                  ) : (
                    <Notification kind="information">
                      <p>{i18n.t('transformations:messages.noEvents')}</p>
                    </Notification>
                  )}
                </div>
                <div className="mt-10 flex w-full flex-col gap-2">
                  <p className="text-xl text-green-600">{i18n.t('transformations:dependencies.title')}</p>
                  {transformation?.dependants?.length ? (
                    transformation?.dependants?.map((dependency: TransformationDependentType) => (
                      <TransformerDependency key={dependency.partnerId} dependency={dependency} />
                    ))
                  ) : (
                    <Notification kind="information">
                      <p>{i18n.t('transformations:messages.noDependencies')}</p>
                    </Notification>
                  )}
                </div>

                {mappingPath && (
                  <ValueTransformationsModal
                    mapping={get(transformation, mappingPath)}
                    mappingPath={mappingPath}
                    closeValueTransformationsModal={() => setMappingPath('')}
                    isReadOnly
                  />
                )}
              </form>
            )}
          </Formik>
        </TransformationContext.Provider>
      </div>
    </div>
  );
}

export default TransformationView;
