/**
 * ng-flags module
 * Turns country ISO code to flag thumbnail.
 *
 * Author: asafdav - https://github.com/asafdav
 */
angular.module('webUi.directive.flag', [])

    .directive('flag', function() {
        return {
            scope: {
                country: '=flag'
            },
            template: '<span class="f32"><span class="flag {{ country | lowercase }}"></span></span>',
            replace: true
        };
    });

    
