angular.module('webUi.service.common.conditions.commonDateConditionService', [
    'restangular',
    'webUi.common.Utils'
])
/**
 * @namespace commonDateConditionService
 */
    .factory('commonDateConditionService', ['ValidService', function (ValidService) {

        function handleDateCondition ($scope){

            var conditionClazz = 'DateCondition';
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            $scope.inOrder = function () {
                if (ValidService.isEmpty($scope.state.formCondition.startTimestamp) || ValidService.isEmpty($scope.state.formCondition.endTimestamp)) {
                    return true;
                }
                return $scope.state.formCondition.startTimestamp <= $scope.state.formCondition.endTimestamp;
            };

            $scope.hasStartOrEnd = function () {
                return !ValidService.isEmpty($scope.state.formCondition.startTimestamp) || !ValidService.isEmpty($scope.state.formCondition.endTimestamp);
            };

        }

        return {
            handleDateCondition: handleDateCondition
        };
    }]);
