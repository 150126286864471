import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import { withRouter } from '~/common/withRouter';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { SmallWrapper, Panel } from '~/components/src/Containers';
import { updateEnvironment, fetchEnvironments, fetchNonDeletableCodesIfNeeded } from '../environments/actions';
import EnvironmentForm from '../components/EnvironmentForm';
import { getEnvironmentById, getEnvironments, getNonDeletableCodes } from '../environments/selectors';

export class EditEnvironmentPage extends Component {
  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchNonDeletableCodesIfNeeded();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('form.edit')} />
          <Panel>
            <EnvironmentForm {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      initialValues: getEnvironmentById(state, ownProps.params.environmentId),
      environments: getEnvironments(state),
      nonDeletableCodes: getNonDeletableCodes(state),
      backHref: buildUrl('admin/environments'),
    }),
    dispatch => ({
      fetchEnvironments: () => dispatch(fetchEnvironments()),
      fetchNonDeletableCodesIfNeeded: () => dispatch(fetchNonDeletableCodesIfNeeded()),
      onSubmit: environment => dispatch(updateEnvironment(environment)),
    }),
  ),
  translate(['environments']),
  reduxForm({
    form: 'environmentEdit',
  }),
)(EditEnvironmentPage);
