import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import moment from 'moment';
import i18n from '~/i18n';
import { isNull } from 'lodash';
import { Link, SkeletonLoader, Tooltip } from '~/components';
import { UITable } from '~/components/src/UITable';
import { RowActions } from '~/components/src/Table/components';
import { formatNumber } from '~/common';
import Icons from '~/components/src/Icons';
import UsageIcons from './UsageIcons';
import { ALLOWED_NUMBER_OF_PROPERTIES, CUSTOMER_FACT_USED_IN } from '../customerFacts';

const getReadableTtl = ttl => {
  let formattedTtl = '';
  const days = Math.floor(ttl / (3600 * 24));
  ttl -= days * 3600 * 24;
  const hrs = Math.floor(ttl / 3600);
  ttl -= hrs * 3600;
  const mnts = Math.floor(ttl / 60);
  ttl -= mnts * 60;
  if (days !== 0) formattedTtl += `${days}d `;
  if (hrs !== 0) formattedTtl += `${hrs}h `;
  if (mnts !== 0) formattedTtl += `${mnts}m `;
  if (ttl !== 0) formattedTtl += `${ttl}s`;
  return formattedTtl;
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';

const columnHelper = createColumnHelper();

const CustomerFactsList = ({
  list,
  query = '',
  onViewClick,
  canEditProfiles,
  onDeleteClick,
  onEditClick,
  onDependantIconClick,
  shouldShowPropertiesColumn = true,
  shouldShowActionsMenu = true,
  shouldShowExpireDate = false,
  shouldShowTtl = false,
  shouldShowActivity = false,
  isFetchingActivities,
}) => {
  const renderInsightIcon = (hasDependants, hasActivity) => {
    if (hasDependants && !hasActivity) {
      return (
        <Icons
          icon="warning"
          className="size-7 t-insights-warning text-yellow-500"
          tooltip={i18n.t('customerFacts:list.insights.tooltip.warning')}
        />
      );
    } else if (!hasDependants && hasActivity) {
      return (
        <Icons
          icon="report"
          className="size-7 t-insights-error text-red-500"
          tooltip={i18n.t('customerFacts:list.insights.tooltip.error')}
        />
      );
    }
    return <div />;
  };
  const getColumns = () =>
    [
      columnHelper.accessor('name', {
        header: () => <div className="inline-block text-left">{i18n.t('customerFacts:list.name')}</div>,
        cell: info => (
          <div className="text-left">
            <Link
              onClick={() => {
                onViewClick(info?.row?.original?.customerFactId);
              }}
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        size: 300,
      }),
      columnHelper.accessor('typeLabel', {
        header: () => <div className="inline-block text-left">{i18n.t('customerFacts:list.type')}</div>,
        cell: info => <div className="text-left">{info.getValue()}</div>,
        size: 200,
      }),
      shouldShowPropertiesColumn &&
        columnHelper.accessor('numberOfProperties', {
          header: () => (
            <div className="inline-block text-right">{i18n.t('customerFacts:list.numberOfProperties')}</div>
          ),
          cell: info => <div className="text-right">{`${info.getValue()}/${ALLOWED_NUMBER_OF_PROPERTIES}`}</div>,
          size: 200,
        }),
      columnHelper.accessor('numberOfUsedProperties', {
        header: () => (
          <div className="inline-block text-right">{i18n.t('customerFacts:list.numberOfUsedProperties')}</div>
        ),
        cell: info => {
          const customerFact = info?.row?.original;
          return (
            <div className="text-right">{`${customerFact.numberOfUsedProperties}/${customerFact.numberOfProperties}`}</div>
          );
        },
        size: 200,
      }),
      shouldShowTtl &&
        columnHelper.accessor('originalTtl', {
          header: () => <div className="inline-block text-right">{i18n.t('customerFacts:list.ttl')}</div>,
          cell: info => <div className="text-right">{getReadableTtl(info.getValue())}</div>,
          size: 300,
        }),
      shouldShowExpireDate &&
        columnHelper.accessor('expireTimestamp', {
          header: () => <div className="inline-block text-center">{i18n.t('customerFacts:list.expiredDate')}</div>,
          cell: info => (
            <div className="text-center">
              <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>{' '}
              <span className="text-r42-blue text-xs">{moment(info.getValue()).format(TIME_FORMAT)}</span>
            </div>
          ),
          size: 200,
        }),
      shouldShowActivity &&
        columnHelper.accessor('activity', {
          header: () => <div className="text-right">{i18n.t('customerFacts:list.activity')}</div>,
          cell: info => {
            if (isFetchingActivities) {
              return <SkeletonLoader type="text" />;
            }
            const { activity, dependantTypes } = info?.row?.original;
            const hasDependants = dependantTypes.length > 0;
            const hasActivity = activity > 0;

            return (
              <div className="text-right">
                {!isNull(activity) ? (
                  <div className="flex items-center justify-between gap-1 px-1">
                    {renderInsightIcon(hasDependants, hasActivity)}
                    <Tooltip tooltip={i18n.t('customerFacts:general.activityTooltip')}>
                      {formatNumber(activity)}
                    </Tooltip>
                  </div>
                ) : (
                  <span className="text-xs italic text-red-500">{i18n.t('customerFacts:general.activityError')}</span>
                )}
              </div>
            );
          },
          size: 200,
        }),
      columnHelper.accessor('usedIn', {
        header: () => <div className="text-center">{i18n.t('customerFacts:list.usedIn')}</div>,
        cell: info => {
          const customerFact = info?.row?.original;
          return (
            <div className="flex items-center justify-center">
              <UsageIcons
                dependantTypes={customerFact.dependantTypes}
                elementUsedIn={CUSTOMER_FACT_USED_IN}
                elementId={customerFact.customerFactId}
                onDependantIconClick={onDependantIconClick}
              />
            </div>
          );
        },
        size: 400,
        enableSorting: false,
      }),

      shouldShowActionsMenu &&
        columnHelper.accessor('actions', {
          header: () => <div className="text-center">{i18n.t('customerFacts:list.action')}</div>,
          cell: info => {
            const customerFact = info?.row?.original;
            const actions = [
              {
                name: i18n.t('customerFacts:common.view'),
                isDisabled: false,
                onClick: () => onViewClick(customerFact.customerFactId),
                tooltip: i18n.t('customerFacts:common.view'),
                icon: () => {},
                testHook: 'view',
              },
              {
                name: i18n.t('customerFacts:common.edit'),
                isDisabled: !canEditProfiles,
                onClick: () => onEditClick(customerFact.customerFactId),
                tooltip: i18n.t('customerFacts:common.edit'),
                icon: () => {},
                testHook: 'edit',
              },
              {
                name: i18n.t('customerFacts:common.delete'),
                type: 'delete',
                isDisabled: !canEditProfiles || customerFact.hasDependants,
                onClick: () => onDeleteClick(customerFact),
                tooltip: customerFact.hasDependants
                  ? i18n.t('customerFacts:edit.removeActionDisabledTooltip')
                  : i18n.t('customerFacts:common.delete'),
                icon: () => {},
                testHook: 'delete',
              },
            ];
            return (
              <div className="text-center">
                {' '}
                <RowActions actions={actions} />
              </div>
            );
          },
          size: 100,
          enableSorting: false,
        }),
    ].filter(boolean => boolean);
  return (
    <UITable
      testHook="cutomerFactsTable"
      data={list}
      columns={getColumns()}
      emptyMessage={
        query.length !== 0 ? i18n.t('customerFacts:list.noResults.header') : i18n.t('customerFacts:list.empty.header')
      }
      emptyBody={
        query.length !== 0 ? i18n.t('customerFacts:list.noResults.body') : i18n.t('customerFacts:list.empty.body')
      }
      searchQuery={query}
      enableSorting
      enableFilters
    />
  );
};

export default CustomerFactsList;
