import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import Input from '../Elements/Input';

export * from '../Elements/defaultSizes';

const InputRow = ({ meta, input, label, disabled, size, placeholder, type, hint, suffix, ...other }) => (
  <FormRow isBlock={true} hint={hint} disabled={disabled} name={input.name} label={label} meta={meta}>
    <Input
      {...other}
      disabled={disabled}
      input={input}
      id={input.name}
      placeholder={placeholder}
      type={type}
      size={size}
      isError={meta.touched && !!meta.error}
    />
    {suffix}
  </FormRow>
);

InputRow.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['small', 'default', 'wide', 'block']),
  suffix: PropTypes.node,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default InputRow;
