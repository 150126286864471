/**
 * Aggregator module
 */
angular.module('webUi.service.remote', [
    'webUi.service.remote.export',

    'webUi.service.remote.urlbuilder',
    'webUi.service.remote.urlbuildershared',

    'webUi.service.remote.beacons',
    'webUi.service.remote.beaconStats'
]);
