/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class PermissionsClass {
  getSitePermissionsMap() {
    return Api.callGet(`${API_BASE}/security/getPermissionsMap`).then(response =>
      response.data.sort((a, b) => {
        const nameA = `${a.module && a.module.label}`.toUpperCase();
        const nameB = `${b.module && b.module.label}`.toUpperCase();
        return nameA.localeCompare(nameB);
      }),
    );
  }

  removeUserAccessToSite(model) {
    return Api.callDelete(`${API_BASE}/permission/users/${model.userId}/sites/${model.siteId}`);
  }

  editUserAccessToSite(model) {
    return Api.callPost(`${API_BASE}/permission/updateSitePermissions`, model).then(response => response.data);
  }
}

const permissionsService = new PermissionsClass();
export default permissionsService;
