import React, { useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { useAPI, useApiWithState } from '~/common/ApiHooks';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { Link, Notification } from '~/components';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { fetchConsentTypes, fetchUserLists, getConnectorDetails } from './dataService';
import { GoogleCustomerMatchProps, TGoogleCustomerMatchFormValues, TUserListItem } from './types';
import AddUserListModal from './AddUserListModal';

const animatedComponents = makeAnimated();

const validations = () =>
  Yup.object().shape({
    userListId: Yup.string().required(i18n.t('connectors:cards.validation.userListIsRequired')),
    consentStatus: Yup.string().nullable(),
  });

const Option = (props: any) => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <div className="flex w-full flex-col items-center gap-2">
        <div className="flex w-full items-center justify-between">
          <span>{props.data.name}</span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm text-gray-500">
            {i18n.t('audiences:connectors.googleCustomerMatch.status')} {props.data.accountUserListStatus || ''}
          </span>
          <span className="text-sm text-gray-500">
            {i18n.t('audiences:connectors.googleCustomerMatch.lifeSpan')} {props.data.membershipLifeSpan || 0}
          </span>
        </div>
      </div>
    </components.Option>
  </div>
);

const GoogleCustomerMatch = ({ onEdit, isSubmitted, connector, type }: GoogleCustomerMatchProps) => {
  const {
    partnerDetails: { partnerId = '', consentStatus: initialFormConsent },
  } = connector;
  const [showAddUserListModal, setShowAddUserListModal] = useState(false);
  const [overrideConsent, setOverrideConsent] = useState(false);

  const {
    state: userLists = [],
    isLoading: isUserListsLoading,
    refetch: refetchUserLists,
  } = useApiWithState(() => fetchUserLists(partnerId));
  const { data: consentTypes = [], isLoading: isConsentTypesLoading } = useAPI(() => fetchConsentTypes());
  const { data: connectorDetails = {}, isLoading: isConnectorDetailsIsLoading } = useAPI(() =>
    getConnectorDetails(partnerId),
  );
  const { configuration } = connectorDetails || {};

  const handleOverrideChange = (
    value: boolean,
    setFieldValue: FormikProps<TGoogleCustomerMatchFormValues>['setFieldValue'],
  ) => {
    setOverrideConsent(value);
    if (!value) setFieldValue('consentStatus', null);
  };

  if (isConsentTypesLoading || isConnectorDetailsIsLoading) {
    return <Spin />;
  }
  return (
    <ConnectorGenericForm onEdit={onEdit} validations={validations} connector={connector} isSubmitted={isSubmitted}>
      {({ values, touched, errors, validateField, setFieldValue }: FormikProps<TGoogleCustomerMatchFormValues>) => {
        // Set override consent value on load if consent is already configured
        useEffect(() => {
          if (initialFormConsent) {
            setOverrideConsent(true);
            setFieldValue('consentStatus', initialFormConsent);
          } else {
            setFieldValue('consentStatus', null);
          }
        }, []);
        return (
          <>
            <div className="flex gap-4">
              <Field
                name="userListId"
                className="t-userListId w-1/2"
                as={FormikSelectField}
                label={i18n.t('audiences:connectors.googleCustomerMatch.fieldLabel')}
                getOptionLabel={(option: TUserListItem) => option.name}
                getOptionValue={(option: TUserListItem) => option.id}
                customSetFieldValue={(name: string, option: { name: string; id: number }) => {
                  setFieldValue(name, option.id);
                  setFieldValue('userListName', option.name);
                  setTimeout(() => validateField(name)); // Fix validation
                }}
                components={{
                  Option,
                  animatedComponents,
                }}
                testHook="googleCustomerMatchUserList"
                options={userLists || []}
                errorText={touched.userListId && errors.userListId}
                isLoading={isUserListsLoading}
              />
              <BtnIcon
                className="mt-10 rounded-full"
                testHook="googleCustomerMatchAddUserList"
                icon="add"
                tooltip={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.iconDesc')}
                onClick={() => setShowAddUserListModal(true)}
              />
            </div>
            <div className="py-4">
              <Notification
                kind="information"
                className="width my-6"
                header={i18n.t('partners:form.notification.acceptEUConsent.header')}
              >
                <p>{i18n.t('partners:form.notification.acceptEUConsent.message1.googleCustomerMatch')}</p>
                <p className="mt-2">{i18n.t('partners:form.notification.acceptEUConsent.message2')}</p>
                <Link target="_blank" href={i18n.t('partners:form.notification.acceptEUConsent.link')}>
                  {i18n.t('partners:form.notification.acceptEUConsent.link')}
                </Link>
              </Notification>

              <div className="my-4 pb-2">
                <span className="text-gray-600">
                  {i18n.t('partners:form.notification.acceptEUConsent.connectorDefaultText')}:{' '}
                </span>
                <span className="text-r42-blue">
                  {configuration?.consentStatus || i18n.t('partners:form.notification.acceptEUConsent.notConfigured')}
                </span>
              </div>
              <label className="t-overrideConsent inline-flex cursor-pointer items-center gap-3">
                <CheckboxElement
                  name="overrideConsent"
                  value={overrideConsent}
                  onChange={() => handleOverrideChange(!overrideConsent, setFieldValue)}
                />
                <span className="truncate text-ellipsis">
                  {i18n.t('partners:form.notification.acceptEUConsent.overrideLabel', { type })}
                </span>
              </label>

              {overrideConsent && (
                <Field
                  name="consentStatus"
                  as={FormikSelectField}
                  label={i18n.t('partners:form.formFields.acceptEUConsentSelection')}
                  errorText={touched?.consentStatus && errors?.consentStatus}
                  options={consentTypes || []}
                  getOptionLabel={(option: { name: string; label: string }) => option.label}
                  getOptionValue={(option: { name: string; label: string }) => option.name}
                  customSetFieldValue={(name: string, option: { name: string; label: string }) =>
                    setFieldValue(name, option.name)
                  }
                  value={values.consentStatus ?? ''}
                  isLoading={isConsentTypesLoading}
                  className="mt-4 w-1/2"
                />
              )}
            </div>
            {showAddUserListModal && (
              <AddUserListModal
                refetchUserLists={refetchUserLists}
                hideModal={() => setShowAddUserListModal(false)}
                payloadType={configuration?.payloadType}
                partnerId={partnerId}
              />
            )}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default GoogleCustomerMatch;
