import { get } from 'lodash';
import { getDateAndTime } from '../../common/utils/DateUtil';

const isFetchingScheduledExports = state => get(state, 'profiles.export.isFetchingScheduledExports');

const parseAttempts = attempts =>
  attempts.map(attempt => ({
    ...attempt,
    timestamp: getDateAndTime(attempt.timestamp),
  }));

const getScheduledExportsData = state =>
  state.profiles.export.scheduledExportsData.map(row => ({
    ...row,
    createdOn: getDateAndTime(row.createdOn),
    createdOnKey: row.createdOn,
    modifiedOn: getDateAndTime(row.modifiedOn),
    modifiedOnKey: row.modifiedOn,
    lastAttempts: row.lastAttempts ? parseAttempts(row.lastAttempts) : [],
  }));

const isFetchingManualExports = state => get(state, 'profiles.export.isFetchingManualExports');

const getManualExportsData = state =>
  state.profiles.export.manualExportsData.map(row => ({
    ...row,
    from: getDateAndTime(row.from),
    fromKey: row.from,
    to: getDateAndTime(row.to),
    toKey: row.to,
    createdOn: getDateAndTime(row.createdOn),
    createdOnKey: row.createdOn,
    finishedOn: getDateAndTime(row.finishedOn),
    finishedOnKey: row.finishedOn,
  }));

const getSelectedRange = state => get(state, 'profiles.export.selectedRange');

const getError = state => get(state, 'profiles.export.exportError');

export {
  isFetchingScheduledExports,
  getScheduledExportsData,
  isFetchingManualExports,
  getManualExportsData,
  getSelectedRange,
  getError,
};
