import { get } from 'lodash';

/**
 * Get Column By Id
 * @param state
 * @param columnId
 * @returns {*}
 */
export const getColumnById = (state, columnId) => get(state, `admin.urlBuilder.columns.byId[${columnId}]`);

/**
 * Get All Columns
 * @param state
 */
export const getColumns = state =>
  state.admin.urlBuilder.columns.allIds.map(id => state.admin.urlBuilder.columns.byId[id]);

/**
 * Should fetch all columns?
 * @param state
 */
export const shouldFetchColumns = state => get(state, 'admin.urlBuilder.columns.ui.shouldFetchAll');

/**
 * Is fetching all columns currently in progress?
 * @param state
 */
export const isFetchingColumns = state => get(state, 'admin.urlBuilder.columns.ui.isFetchingAll');
