import React, { useState, useEffect } from 'react';
import initTransformer from '~/profiles/connectors/form/partners/facebookConversions/core/initTransformer';
import { Transformer } from '~/profiles/connectors/form/partners/facebookConversions/core/transformerTypes';
import useForceUpdate from './useForceUpdate';
import { EventConnectorTransformationsPageProps } from './types';

const withTransformer =
  (Component: (props: EventConnectorTransformationsPageProps) => JSX.Element) =>
  (props: EventConnectorTransformationsPageProps): React.ReactElement => {
    const [transformer, setTransformer] = useState<Transformer>();
    const forceUpdate = useForceUpdate();

    const { eventConnectorId } = props.params;

    useEffect(() => {
      (async () => {
        const transformer: Transformer = await initTransformer(eventConnectorId, forceUpdate);
        setTransformer(transformer);
      })();
    }, []);

    return <Component {...props} transformer={transformer} />;
  };

export default withTransformer;
