import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import SortableHeader from '~/components/src/Table/sortableHeader';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { components, enhancements } from '~/components/src/Table';
import { SELECTED_AUDIENCES } from '~/profiles/audiences/types';
import './styles.scss';

const { Table, Row, Cell, HeaderCell } = components;
const { withEmpty, withFilter, withSort } = enhancements;

const WIDTHS = {
  NAME: 'u-size10of12',
  STATUS: 'u-size2of12',
};

const EmptyList = ({ t, emptyBody = t('audiences:list.empty.body') }) => (
  <Empty excludePanel header={t('audiences:list.empty.header')} body={emptyBody} />
);

const NoResults = ({ t }) => (
  <Empty excludePanel header={t('audiences:list.noResults.header')} body={t('audiences:list.noResults.generalBody')} />
);

const TableHeader = ({ t }) => (
  <Row>
    <HeaderCell className="Table-cell--selection" />
    <SortableHeader className={WIDTHS.NAME} sortKey="name" title={t('historicProcessing.titleHeaderCell')} />
    <HeaderCell className="Table-cell--selection" />
  </Row>
);

const GetTriggeredAudiences = item => (item.historicProcessingTriggered ? item.segmentId : []);

export const HistoricProcessingList = ({ t, list, isSelectable = false, formProps }) => (
  <Table stateKey={SELECTED_AUDIENCES} isSelectable={isSelectable} className="AudiencesList">
    <TableHeader t={t} />
    {list.map(item => (
      <label key={item.segmentId} for={`audience[${item.segmentId}]`} className="AudiencesList-RowLabel">
        <Row withActions id={item.segmentId} unselectables={GetTriggeredAudiences(item)}>
          <Cell className="Table-cell--selection">
            <CheckboxElement
              name={`audience[${item.segmentId}]`}
              id={`audience[${item.segmentId}]`}
              disabled={item.historicProcessingTriggered}
              value={item.historicProcessingTriggered || formProps.values.audience[item.segmentId]}
              onChange={() =>
                formProps.setFieldValue(`audience[${item.segmentId}]`, !formProps.values.audience[item.segmentId])
              }
            />
          </Cell>
          <Cell className={WIDTHS.NAME}>{item.name}</Cell>
          <Cell className={`Table-cell--status ${WIDTHS.STATUS}`}>
            {item.historicProcessingTriggered && (
              <div className={`AudiencesList-RowStatus ${item.segmentId}-status`}>
                {t('audiences:historicProcessing.processing')}
              </div>
            )}
          </Cell>
        </Row>
      </label>
    ))}
  </Table>
);

HistoricProcessingList.propTypes = {
  list: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
)(HistoricProcessingList);
