import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteSnapshot } from '~/ai/Snapshots/dataService';
import { buildUrl } from '~/common';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import DeletePopup from '~/components/src/DeletePopup';
import { AI_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { SnapshotActionProps } from '../types';

const SnapshotActions = ({
  snapshotInfo,
  refetchData,
  isListPage = false,
}: SnapshotActionProps): React.ReactElement => {
  const { snapshotId, status: snapshotStatus, snapshotName, canDelete } = snapshotInfo;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useNavigate();

  const toggleShowDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleDelete = async () => {
    await deleteSnapshot(snapshotId);
    if (isListPage && refetchData) refetchData();
    else navigate(buildUrl('ai/snapshots/list'));

    showSuccess({ header: i18n.t('ai:notifications.snapshotDeleteSuccess') });
    toggleShowDeleteDialog();
  };

  return (
    <div className="t-snapshotActions flex justify-center gap-1">
      <BtnIcon
        icon="delete"
        disabled={!canDelete}
        onClick={() => toggleShowDeleteDialog()}
        tooltip={
          canDelete
            ? i18n.t('ai:actions.delete')
            : i18n.t('ai:snapshot.actions.delete.disabled', { status: snapshotStatus })
        }
        testHook="deleteSnapshot"
      />
      {showDeleteDialog && (
        <DeletePopup
          title={i18n.t('ai:snapshotsPage.delete.header')}
          hidePopup={() => toggleShowDeleteDialog()}
          onClick={() => handleDelete()}
          gaAction={AI_EVENTS.SNAPSHOT.DELETED}
        >
          <Notification testHook="snapshotDeleteDescription">
            {i18n.t('ai:snapshotsPage.delete.body', { snapshotName })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
};

export default SnapshotActions;
