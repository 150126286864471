import ShowUsageService from './dataService';
import * as types from './types';

export const fetchSiteStatistics = (clientId, startDate, endDate) => dispatch => {
  dispatch({ type: types.CLIENT_VIEW_FETCH_SITE_STATS_START });
  return ShowUsageService.getSiteStatistics(clientId, startDate, endDate)
    .then(response => {
      // TODO remove this when the endpoint is fixed to respond with array
      dispatch({
        type: types.CLIENT_VIEW_FETCH_SITE_STATS_SUCCESS,
        payload: Object.keys(response).map(i => response[i]),
      });
    })
    .catch(error => dispatch({ type: types.CLIENT_VIEW_FETCH_SITE_STATS_FAIL, error }));
};
