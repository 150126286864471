/**
 * @class webUi.service
 * @memberOf webUi
 */
angular.module('webUi.service', [
    'webUi.service.admin',
    'webUi.service.ajaxBusy',
    'webUi.service.chart',
    'webUi.service.codemirror',
    'webUi.service.content',
    'webUi.service.context',
    'webUi.service.csrf',
    'webUi.service.csv',
    'webUi.service.exceptionHandling',
    'webUi.service.ga',
    'webUi.service.helper',
    'webUi.service.iplock',
    'webUi.service.messages',
    'webUi.service.modalManagement',
    'webUi.service.permission',
    'webUi.service.profiles',
    'webUi.service.pubSub',
    'webUi.service.reflection',
    'webUi.service.remote',
    'webUi.service.security',
    'webUi.service.security',
    'webUi.service.spinner',
    'webUi.service.support',
    'webUi.service.tagcounter',
    'webUi.service.tagUsage',
    'webUi.service.tagmanagement',
    'webUi.service.tagmanagement.cookiePermission',
    'webUi.service.tagmanagement.environment',
    'webUi.service.tagmanagement.errorReporting',
    'webUi.service.tagmanagement.experimentStats',
    'webUi.service.tagmanagement.experiments',
    'webUi.service.tagmanagement.newPath',
    'webUi.service.tagmanagement.publishing',
    'webUi.service.tagmanagement.serviceHelper',
    'webUi.service.tagmanagement.websitePath',
    'webUi.service.tagmanagement.websitePathGroup',
    'webUi.service.template',
    'webUi.service.test',
    'webUi.service.time',
    'webUi.service.title',
    'webUi.service.tree',
    'webUi.service.user',
    'webUi.service.uuid',
    'webUi.service.valid',
    'webUi.service.customer',
    'webUi.service.common',
    'webUi.service.toast'
]);

