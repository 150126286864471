import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import Link from '~/components/src/Link';
import Icon from '~/components/src/Icon';
import Empty from '~/components/src/Empty';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';
import MdCheck from 'react-icons/lib/md/check';
import { buildUrl } from '~/common';
import Icons from '~/components/src/Icons';

const { Table, Row, Cell, HeaderCell, RowActions } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t, emptyBody = t('adminUsers:list.empty.body') }) => (
  <Empty header={t('adminUsers:list.empty.header')} body={emptyBody} />
);

const NoResults = ({ t }) => (
  <Empty header={t('adminUsers:list.noResults.header')} body={t('adminUsers:list.noResults.body')} />
);

const WIDTHS = {
  USERNAME: 'u-size1of10',
  NAME: 'u-size1of10',
  PERMISSION: 'Table-permission',
};

const props = (sortKey, title, sortFn) => ({
  sortKey,
  title,
  sortFn,
});

export const UserPermissionsList = ({
  list,
  permissions,
  stateKey,
  onUserLockSettingsClick,
  onEditClick,
  onDeleteClick,
  t,
  isContextAdmin,
}) => {
  const renderActions = item => {
    const actions = item.hasSameClient
      ? [
          {
            name: t('users:userLockSettings.name'),
            tooltip: t('users:userLockSettings.toolTip'),
            isDisabled: false,
            icon: 'lockPerson',
            onClick: () => onUserLockSettingsClick(item),
            testHook: 'lock',
          },
        ]
      : [];

    actions.push(
      {
        name: t('common:actions.edit'),
        tooltip: t('common:actions.edit'),
        isDisabled: false,
        icon: 'edit',
        onClick: () => onEditClick(item),
        testHook: 'edit',
      },
      {
        name: t('common:actions.remove'),
        tooltip: t('common:actions.remove'),
        isDisabled: false,
        icon: 'delete',
        onClick: () => onDeleteClick(item),
        testHook: 'delete',
        type: 'delete',
      },
    );

    return (
      onDeleteClick && (
        <Cell className="Table-stickyCell right-0 z-10 float-right w-32 !p-2">
          <RowActions className=" flex justify-end px-4" actions={actions} iconsLimit={3} />
        </Cell>
      )
    );
  };

  return (
    <Panel>
      <div className="OverflowXScrollContainer OverflowYHiddenContainer">
        <Table
          className="Table--shrinkContent Table--restrictedOnXlScreen Table--addlBottomSpacing Table--withStickyColumns"
          stateKey={stateKey}
          isTable={true}
        >
          <colgroup>
            <col className={WIDTHS.USERNAME} />
            <col className={WIDTHS.NAME} />
            {permissions.map(module =>
              module.permissions.map(permission => <col className={WIDTHS.PERMISSION} key={permission.name} />),
            )}
            <col className="Table-actions" />
          </colgroup>
          <thead>
            <Row>
              <SortableHeader
                rowSpan={2}
                {...props('username', t('list.username'))}
                className="Table-stickyCell z-10"
              />
              <SortableHeader rowSpan={2} {...props('name', t('list.name'))} />
              {permissions.map(item => (
                <HeaderCell colSpan={item.permissions.length} key={item.name}>
                  <div className="u-alignCenter TableSpacingOnXlHeader">{item.label}</div>
                </HeaderCell>
              ))}
              {!onDeleteClick ? null : <HeaderCell className="Table-stickyCell right-0 z-10">&nbsp;</HeaderCell>}
            </Row>
            <Row>
              {permissions.map(item =>
                item.permissions.map(permission => (
                  <Cell className="u-alignCenter Table-cell--min65" key={permission.name}>
                    <span>{permission.label}</span>
                  </Cell>
                )),
              )}
              {!onDeleteClick ? null : <Cell className="Table-stickyCell right-0 z-10">&nbsp;</Cell>}
            </Row>
          </thead>
          <tbody>
            {list.map(item => (
              <Row key={item.userId} withActions className="t-usersRow">
                <Cell className="u-paddingRightL Table-stickyCell">
                  <div className="flex items-center">
                    {isContextAdmin ? (
                      <Link href={buildUrl(`context/users/view/${item.userId}`)} testHook="userEmail">
                        {item.username}
                      </Link>
                    ) : (
                      <span className="t-userEmail">{item.username}</span>
                    )}
                    {item.hasSameClient && item.lock?.locked && (
                      <Icons className="h-5 w-5 text-red-500" icon="lock" tooltip={item.lock.reason} />
                    )}
                  </div>
                </Cell>
                <Cell>
                  <span className="t-userName whitespace-nowrap pr-2">{item.name ?? '-'}</span>
                </Cell>
                {permissions.map(module =>
                  module.permissions.map(permission => (
                    <Cell className="u-alignCenter" key={permission.name}>
                      {item.permissions[permission.name] ? <Icon icon={MdCheck} size={21} /> : <span>&ndash;</span>}
                    </Cell>
                  )),
                )}
                {renderActions(item)}
              </Row>
            ))}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};

UserPermissionsList.propTypes = {
  list: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  onUserLockSettingsClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
)(UserPermissionsList);
