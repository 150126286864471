angular.module('webUi.ui.tagmanagement.tags.websitePathGroups.add', ['webUi.ui.tagmanagement.tags.base'])

    .controller('AddWebsitePathGroupDialogController', ['$scope', 'dialogsModel', '$uibModalInstance',
        function AddWebsitePathGroupDialogController    ($scope,   dialogsModel,   $uibModalInstance) {

            $scope.websitePathGroup = {};

            $scope.cancel = function() {
                $uibModalInstance.close(false);
            };
            $scope.save = function() {
                dialogsModel.onSave($scope.websitePathGroup);
                $uibModalInstance.close(true);
            };
        }
    ]);
