import React, { useState } from 'react';
import { Moment } from 'moment';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { Panel } from '~/components/src/Containers';
import { useApiWithState } from '~/common';
import MostErrors from './MostErrors';
import TagErrors from './TagErrors';
import { getTagErrors, getTaggingErrors } from '../dataService';
import { defaultDate } from '../constants';
import ActivityHeader from './ActivityHeader';

const Errors = (): JSX.Element => {
  const [filter, setFilter] = useState<Record<string, Moment>>({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const { startDate, endDate } = filter;

  const { state, isLoading } = useApiWithState(async () => {
    const [tagErrors, mostErrors] = await Promise.all([
      await getTagErrors(startDate.startOf('day').toDate().getTime(), endDate.endOf('day').toDate().getTime()),
      await getTaggingErrors(startDate.startOf('day').toDate().getTime(), endDate.endOf('day').toDate().getTime()),
    ]);

    return { tagErrors, mostErrors };
  }, [startDate, endDate]);

  const { tagErrors, mostErrors } = state || {};

  if (!mostErrors && !tagErrors && isLoading) {
    return <Spin />;
  }

  return (
    <Panel>
      <ActivityHeader
        startDate={startDate}
        endDate={endDate}
        setFilter={setFilter}
        title={i18n.t('stats:tagErrors.title')}
        tooltip={i18n.t('stats:tagErrors.tooltip')}
      />
      <TagErrors
        {...{
          tagErrors,
        }}
      />
      <MostErrors mostErrors={mostErrors} />
    </Panel>
  );
};

export default Errors;
