import React, { ReactElement, useContext, useState } from 'react';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { useApiWithState } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import { Heading, Notification, Page, PickerView } from '~/components';
import { showSuccess } from '~/notificationCenter';
import {
  deleteLabel,
  fetchLabels,
  fetchWorkflows,
  updateWorkflowLabel,
  DEFAULT_PAGINATION_SIZE,
} from '~/workflows/dataService';
import { WorklfowContext } from '../WorklfowContext';
import Dependencies from './components/Dependencies';
import WorkflowTable from './components/WorkflowTable';
import WorkflowCreateDialog from './components/WorkflowCreateDialog';
import { TFilter, TLabel, TWorkflow } from '../types';
import Filter from './components/Filter';
import StepSearch from './components/StepSearch';

export const filterInitialValue: TFilter = {
  name: '',
  journeyStatus: '',
  historicProcessingStatus: '',
  workflowType: '',
  labelIds: [],
};

const WorkflowDashboard = (): ReactElement => {
  const [showFilter, setShowFilter] = useState(false);
  const [showStepSearch, setShowStepSearch] = useState(false);

  const { hasWorkflowJourneyEdit, hasWorkflowAudienceEdit } = useContext(WorklfowContext);

  const [page, setPage] = useState(0);
  const [selectedDependency, setSelectedDependency] = useState<TWorkflow | null>(null);
  const [showWorkflowCreateDialog, setShowWorkflowCreateDialog] = useState(false);
  const [filters, setFilters] = useState<TFilter>(filterInitialValue);
  const canEditCustomerJourney = hasWorkflowJourneyEdit || hasWorkflowAudienceEdit;
  const journeyExceedMaxLimit = false;

  const {
    state: workflows,
    isLoading: isLoadingWorkflows,
    refetch: refetchWorkflows,
  } = useApiWithState(async () => {
    const { journeyStatus, workflowType, historicProcessingStatus } = filters;
    const payload = {
      page: {
        number: page,
        size: DEFAULT_PAGINATION_SIZE,
      },
      filters: {
        ...filters,
        journeyStatus: journeyStatus || null,
        workflowType: workflowType || null,
        historicProcessingStatus: historicProcessingStatus || null,
      },
    };
    return fetchWorkflows(payload);
  }, [page]);

  const { state: labels, refetch: refetchLabels } = useApiWithState(async () => fetchLabels());

  const updateWorkflowLabels = async (workflowId: string, labels: string[]) => {
    await updateWorkflowLabel(labels, workflowId);
    refetchWorkflows();
    refetchLabels();
  };

  const onDeleteLabel = async (labelId: string) => {
    await deleteLabel(labelId);
    showSuccess({ body: i18n.t('workflow:label.api.deleted') });
    refetchLabels();
  };

  const resetPage = () => {
    if (!page) {
      refetchWorkflows();
    } else {
      setPage(0);
    }
  };

  const toggleFilterMenu = () => {
    setShowFilter(!showFilter);
  };

  const { journeyStatus, workflowType, name, historicProcessingStatus, labelIds } = filters;
  const hasFilter = !!(journeyStatus || workflowType || name || historicProcessingStatus || labelIds.length !== 0);
  return (
    <>
      <Page excludeContainer className="flex flex-col gap-4 p-4">
        <Heading title={i18n.t('workflow:common.header')} className="py-0">
          <BtnIcon
            icon="searchStep"
            tooltip={i18n.t('workflow:common:searchStep')}
            onClick={() => setShowStepSearch(!showStepSearch)}
            testHook="searchStepId"
          />
          <BtnIcon
            icon="filter"
            tooltip={i18n.t('workflow:common:filters')}
            onClick={() => toggleFilterMenu()}
            testHook="workflow-filter"
          />
          {canEditCustomerJourney && (
            <BtnIcon
              icon="add"
              color="blue"
              onClick={() => {
                setShowWorkflowCreateDialog(true);
              }}
              testHook="addNew"
              tooltip={i18n.t(`workflow:common.${journeyExceedMaxLimit ? 'addNewDisabled' : 'addNew'}`)}
              disabled={journeyExceedMaxLimit}
            />
          )}
        </Heading>

        <Notification kind="information">
          <p>{i18n.t('workflow:common.intro')}</p>
        </Notification>

        {isLoadingWorkflows || !workflows ? (
          <Spin />
        ) : (
          <WorkflowTable
            refetchWorkflows={refetchWorkflows}
            workflows={workflows}
            setPage={setPage}
            page={page}
            onDependantIconClick={workflow => setSelectedDependency(workflow)}
            updateWorkflowLabels={updateWorkflowLabels}
            labels={labels ?? []}
            hasFilter={hasFilter}
            onDeleteLabel={onDeleteLabel}
          />
        )}

        {selectedDependency && (
          <Dependencies selectedDependency={selectedDependency} onClose={() => setSelectedDependency(null)} />
        )}
        {showWorkflowCreateDialog && (
          <PickerView
            pickerTitle={i18n.t('workflow:create.header')}
            handlePickerVisibility={setShowWorkflowCreateDialog}
            className="!h-auto min-h-[300px] !w-6/12 min-w-[400px]"
          >
            <WorkflowCreateDialog onClose={() => setShowWorkflowCreateDialog(false)} />
          </PickerView>
        )}
      </Page>
      {showStepSearch && <StepSearch handleClose={() => setShowStepSearch(false)} />}
      {showFilter && (
        <Filter
          {...{
            toggleFilterMenu,
            labels: labels || ({} as TLabel[]),
            filters,
            setFilters,
            totalElements: workflows?.totalElements,
            resetPage,
          }}
        />
      )}
    </>
  );
};

export default WorkflowDashboard;
