angular.module('webUi.service.helper', [
    'ui.bootstrap',
    'webUi.ui.shared.browserNotSupported',
    'webUi.ui.shared.adBlockNotification'
])

    .factory('HelperService', ['$uibModal',
        function($uibModal){

            var service = {

                /**
		 * Returns a spinnerWrapper which you can close / show status
		 *
		 * @returns a spinnerWrapper which you can close / show status
		 */
                showBrowserNotSupported: function() {
                    return $uibModal.open({
                        templateUrl: 'ui/shared/browserNotSupported/popup.tpl.html',
                        controller: 'BrowserNotSupportedController'
                    });
                },

                showAdBlockNotification: function() {
                    return $uibModal.open({
                        templateUrl: 'ui/shared/adBlockNotification/adBlockNotification.tpl.html',
                        controller: 'AdBlockNotificationController'
                    });
                }
            };

            return service;
        }]);

