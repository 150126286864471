import { EVENT_CONNECTOR_CATEGORY } from '~/profiles/connectors/types';

export const commonActions = (item, dispatchProps, ownProps) => {
  if (!ownProps.canEdit) return [];
  const toggle = item.activated ? 'deactivate' : 'activate';
  return [
    {
      name: ownProps.t('partners:list.actions.edit'),
      tooltip: ownProps.t('partners:list.actions.edit'),
      isDisabled: false,
      onClick: () => dispatchProps.onEditClick(item),
      testHook: 'edit',
    },
    {
      name: ownProps.t(`partners:list.actions.${toggle}`),
      tooltip: ownProps.t(`partners:list.actions.${toggle}`),
      isDisabled: false,
      onClick: () => dispatchProps.onActivationClick(item),
      testHook: toggle,
    },
    {
      name: ownProps.t('partners:list.actions.delete'),
      tooltip: ownProps.t('partners:list.actions.delete'),
      isDisabled: false,
      onClick: () => dispatchProps.onDeleteClick(item),
      testHook: 'delete',
      type: 'delete',
    },
  ];
};

export const getConnectorListPath = connectorType => {
  const path = 'profiles/partners/list';

  if (connectorType === EVENT_CONNECTOR_CATEGORY) {
    return `${path}?tab=1`;
  }

  return path;
};
