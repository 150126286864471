import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { Pagination } from '../../components';

const DEFAULT_PAGINATION_SIZE = 20;

const withPaginate = () => Table => {
  const WithPaginate = props => (
    <React.Fragment>
      <Table {...props} />
      <Pagination
        stateKey={props.stateKey}
        paginatedLists={props.paginatedLists}
        currentPage={props.currentPage}
        onPaginate={props.onPaginate}
        dotted={false}
      />
    </React.Fragment>
  );

  const mapStateToProps = (state, { list, params, nodesQty }) => {
    const pages = new Array(Math.ceil(nodesQty / DEFAULT_PAGINATION_SIZE));
    const paginatedLists = pages.fill([]);
    const currentPage = parseInt(params.page, 10) || 0;
    return {
      paginatedLists,
      list: list || [],
      currentPage,
    };
  };

  return withRouter(connect(mapStateToProps)(WithPaginate));
};

export default withPaginate;
