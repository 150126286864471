import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import Dropzone from '../Elements/Dropzone';

export * from '../Elements/defaultSizes';

const DropzoneRow = ({ input, label, disabled, size, placeholder, errorMessage, currentLogo, className }) => (
  <FieldWrapper label={label}>
    <Dropzone
      disabled={disabled}
      input={input}
      currentLogo={currentLogo}
      id={input.name}
      placeholder={placeholder}
      errorMessage={errorMessage}
      size={size}
      className={className}
    />
  </FieldWrapper>
);

DropzoneRow.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['small', 'default', 'wide', 'block']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default DropzoneRow;
