import React, { ReactElement, useContext, useState } from 'react';

import i18n from '~/i18n';
import InputElement from '~/components/src/Form/Elements/InputElement';
import ActionButtons from '~/components/src/ActionButtons';
import { Link } from '~/components';

import { UrlBuilderContext } from '../UrlBuilderContext';
import { URLGeneratorProps } from '../types';

const http = 'http://';
const https = 'https://';

const generateURL = (enteredUrl: string, keyParameter: string, mappingKey: string): string => {
  if (!enteredUrl || enteredUrl === http || enteredUrl === https) {
    return '';
  }
  if (enteredUrl.substring(0, 7) !== http && enteredUrl.substring(0, 8) !== https) {
    enteredUrl = http + enteredUrl;
  }
  const charToAppend = enteredUrl.indexOf('?') > -1 ? '&' : '?';
  return `${enteredUrl + charToAppend + keyParameter}=${mappingKey}`;
};

export const URLGenerator = ({ hideModal, urlBuilderTypeId, mappingKey }: URLGeneratorProps): ReactElement => {
  const { campaignTypes } = useContext(UrlBuilderContext);
  const [url, setUrl] = useState('');

  const type = campaignTypes.find(campaignType => campaignType.typeId === urlBuilderTypeId);
  const keyParameter = type?.keyParameter || '';

  return (
    <>
      <p className="py-4 text-gray-500">{i18n.t('campaigns:urlGenerator.description', { key: mappingKey })}</p>

      <InputElement
        name="urlGeneratorField"
        id="urlGeneratorField"
        onChange={e => setUrl(generateURL(e.target.value, keyParameter, mappingKey))}
      />

      {url && (
        <Link className="flex pt-4 italic" href={url} target="_blank">
          {url}
        </Link>
      )}

      <ActionButtons isConfirmEnabled={false} onDecline={hideModal} declineText={i18n.t('common:close')} />
    </>
  );
};
