import React from 'react';
import Page from '~/components/src/Page';
import EngagementsContainer from '../engagements/containers/EngagementsContainer';

export const EngagementsViewPage = ({ canEditProfiles }) => (
  <Page>
    <EngagementsContainer canEditProfiles={canEditProfiles} />
  </Page>
);

export default EngagementsViewPage;
