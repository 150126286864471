import { combineReducers } from 'redux';
import * as types from './types';
import * as fromTemplates from '../templates';

const defaultAction = {
  payload: {},
};

/**
 * Groups Objects
 * @param state
 * @param action
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  let group;
  let templates;
  switch (action.type) {
    case types.GROUP_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.groupId]: action.payload,
      };
    case fromTemplates.SAVE_SUCCESS:
      group = state[action.payload.groupId];
      templates = (group && group.templates) || [];
      return group
        ? {
            ...state,
            [action.payload.groupId]: {
              ...group,
              templates: [...templates, action.payload],
            },
          }
        : state;
    case fromTemplates.DELETE_SUCCESS:
      group = state[action.payload.groupId];
      templates = (group && group.templates) || [];
      return group
        ? {
            ...state,
            [action.payload.groupId]: {
              ...group,
              templates: templates.filter(template => template.templateId !== action.payload.templateId),
            },
          }
        : state;
    case types.DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Groups Look Up Table
 * @param state
 * @param action
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.GROUP_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      if (state.indexOf(action.payload.groupId) !== -1) {
        return state;
      }
      return [...state, action.payload.groupId];
    case types.DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

/**
 * Simple List of Groups
 * @param state
 * @param action
 * @returns {Array}
 */
const list = (state = [], action) => {
  switch (action.type) {
    case types.GROUPS_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

/**
 * UI Reducer
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};
const ui = (state = defaultUI) => state;

const groupsReducer = combineReducers({
  byId,
  allIds,
  list,
  ui,
});

export default groupsReducer;
