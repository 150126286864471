angular
    .module('webUi.ui.tagmanagement.tags.conditions.journey')
    .service('TagFormJourneyConditionService', ['JourneyService', 'CONDITION_TYPES_CONSTANTS',
        function Service(JourneyService, TYPES) {
            const conditionClazz = 'JourneyCondition';

            function getClassName() {
                return conditionClazz;
            }

            function getJourneyTypes() {
                return [
                    {id: TYPES.IN, name: 'In'},
                    {id: TYPES.NOT_IN, name: 'Not In'}
                ];
            }

            function getStepTypes() {
                return [
                    {id: TYPES.IN_ANY, name: 'In Any Step'},
                    {id: TYPES.IN, name: 'In'},
                    {id: TYPES.NOT_IN, name: 'Not In'}
                ];
            }

            function getEmptyExternalModel() {
                return {
                    clazz: conditionClazz,
                    mode: 'new',
                    inJourneyConditionData: [],
                    inJourneys: [],
                    notInJourneys: [],
                    cacheInSeconds: 0
                };
            }

            // Return current data if in edit or empty if in new mode
            function initModel (data) {
                const isInId = data.inJourneys && data.inJourneys[0];
                const isInItem = data.inJourneyConditionData && data.inJourneyConditionData[0];
                const isNotInId = data.notInJourneys && data.notInJourneys[0];
                let stepType;

                if (isInItem) {
                    stepType = isInItem.isInStep ? TYPES.IN : TYPES.NOT_IN;
                } else {
                    stepType = TYPES.IN_ANY;
                }

                return {
                    orchestrationType: data.journey?.workflowType?.name || 'JOURNEY',
                    journeyType: isNotInId ? TYPES.NOT_IN : TYPES.IN,
                    journeyId: isNotInId || isInId || isInItem && isInItem.journeyId || null,
                    stepId: isInItem && isInItem.stepId || null,
                    stepType: stepType,
                    cacheInSeconds: data.cacheInSeconds || 0
                };
            }

            // Transform internal model to external model structure
            function transformData (data) {
                const isInJourneyData = [];
                const isInJourney = [];
                const isNotInJourney = [];

                if (data.journeyType === TYPES.NOT_IN) {
                    isNotInJourney.push(data.journeyId);
                } else if (data.stepType === TYPES.IN_ANY || !data.stepId) {
                    isInJourney.push(data.journeyId);
                } else {
                    isInJourneyData.push({
                        journeyId: data.journeyId,
                        stepId: data.stepId,
                        isInStep: data.stepType === TYPES.IN
                    });
                }

                return  {
                    inJourneyConditionData: isInJourneyData,
                    inJourneys: isInJourney,
                    notInJourneys: isNotInJourney,
                    cacheInSeconds: data.cacheInSeconds
                };
            }

            // Decorate steps with 'Any step' option
            function getJourneySteps (journeyId) {
                return JourneyService.getSteps(journeyId)
                    .then((steps) => {
                        if (!steps || !steps.length) {
                            steps = [{
                                nodeId: null,
                                label: 'No step configured'
                            }];
                        }
                        return steps;
                    });
            }

            return {
                initModel: initModel,
                transformData: transformData,
                getJourneySteps: getJourneySteps,
                getClassName: getClassName,
                getEmptyExternalModel: getEmptyExternalModel,
                getJourneyTypes: getJourneyTypes,
                getStepTypes: getStepTypes
            };
        }
    ]);
