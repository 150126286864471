angular.module('webUi.ui.ai.base', [
    'webUi.ui.siteState',
    'webUi.service'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.ai', {
            url: '/ai',
            controller: 'AiBaseController as vm',
            abstract: true,
            resolve: {
                hasAdservingView: ['securityContext', function (securityContext) {
                    return securityContext.hasPermission('ADSERVING_VIEW');
                }],
                hasAdservingEdit: ['securityContext', function (securityContext) {
                    return securityContext.hasPermission('ADSERVING_EDIT');
                }],
                hasDataManagementModule: ['securityContext', function (securityContext) {
                    return securityContext.hasModule('DATA_MANAGEMENT');
                }]
            }
        });
    }])

    .controller('AiBaseController', ['$scope', '$state', 'hasAdservingView', 'hasAdservingEdit', 'hasDataManagementModule',
        function AiBaseController($scope, $state, hasAdservingView, hasAdservingEdit, hasDataManagementModule) {
            $scope.hasAdservingView = hasAdservingView;
            $scope.hasAdservingEdit = hasAdservingEdit;
            $scope.hasDataManagementModule = hasDataManagementModule;
        }
    ]);
