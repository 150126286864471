/**
 * @description Angular module for UrlBuilder -> View
 * @class AdminUrlBuilderViewController
 * @memberOf webUi
 * @namespace webUi.ui.admin.urlBuilder
 */
angular.module('webUi.ui.admin.urlBuilder.view', ['webUi.ui.admin.urlBuilder.base']).config([
    '$stateProvider',
    function config($stateProvider) {
        $stateProvider.state('site.admin.urlBuilder.view', {
            url: '/',
            isReact: true,
        });
    },
]);
