/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/admin/url-builder/types`;

/**
 * A service for types related queries.
 */
export class TypeUrlBuilderClass {
  getType(typesId) {
    return Api.callGet(`${BASE_PATH}/${typesId}`).then(response => response.data);
  }

  getTypes() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveType(type) {
    return Api.callPost(BASE_PATH, type).then(response => response.data);
  }

  updateType(type) {
    return Api.callPut(`${BASE_PATH}/${type.typeId}`, type).then(response => response.data);
  }

  deleteType(typeId) {
    return Api.callDelete(`${BASE_PATH}/${typeId}`, typeId).then(response => response.data);
  }
}

const typeUrlBuilderService = new TypeUrlBuilderClass();
export default typeUrlBuilderService;
