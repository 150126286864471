import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import OptionalPropertySelector from '~/profiles/components/OptionalPropertySelector';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import TwoColumnWrapper from '~/common/modules/connectors/components/EditableConnectorsList/TwoColumnWrapper';
import {
  PROPERTIES_TYPES,
  getPropertyValue,
} from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorContent/ConnectorProperties/utils';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    eventType: Yup.string().required(t('connectors.mobpro.mobproEventTypeValidation')),
  });

const Mobpro = ({ onEdit, connector, t, isSubmitted, usedVariables }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <>
        <SmallWrapperNoOffset>
          <Field
            as={FormikInputField}
            name="eventType"
            label={t('connectors.mobpro.mobproEventType')}
            placeholder={t('connectors.mobpro.placeholder')}
            errorText={formProps.touched.eventType && formProps.errors.eventType}
          />
        </SmallWrapperNoOffset>

        <TwoColumnWrapper label={t('connectors.mobpro.eventParameter')} t={t}>
          <OptionalPropertySelector
            name="eventParameter"
            options={usedVariables}
            formProps={formProps}
            hasStoredVariables={true}
            id="formikDropdown-eventParameter"
            value={getPropertyValue(formProps.values.eventParameter, PROPERTIES_TYPES.PROFILE_VARIABLE, usedVariables)}
            type={PROPERTIES_TYPES.PROFILE_VARIABLE}
            onChange={value => {
              formProps.setFieldValue('eventParameter', value);
            }}
          />
        </TwoColumnWrapper>
      </>
    )}
  </ConnectorGenericForm>
);

export default compose(connect(null), translate('audiences'))(Mobpro);
