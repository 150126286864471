import _, { get } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import i18n from '~/i18n';
import constants from '~/profiles/connectors/form/constants';

const selectStats = state => get(state, 'profiles.audienceActivity.stats');
const selectPartnerStats = state => get(state, 'profiles.audienceActivity.partnerStats');
const selectDocumentationUrl = state => get(state, 'profiles.audienceActivity.documentationUrl');

const { errorStackColors, infoStackColors } = constants;
const getAudienceStats = createSelector(selectStats, stats => {
  if (stats) {
    const added = stats.map(stat => stat.totalCallsAdded);
    const removed = stats.map(stat => stat.totalCallsRemoved);
    const segmentSize = stats.map(stat => stat.segmentSize);
    const timestamp = stats.map(stat => moment(stat.key.timestamp).format('D. MMM'));

    return {
      stats,
      added,
      removed,
      segmentSize,
      timestamp,
    };
  }
  return {};
});

const parseStatsCount = (array, field) => {
  const item = _.find(array, { status: field });
  return item ? item.count : 0;
};

const parseError = (length, index, stat, errors) => {
  const errorList = _.filter(
    stat.statusCounts,
    item => item.status !== 'ADD' && item.status !== 'REMOVE' && !item.status.toLowerCase().startsWith('info'),
  );
  errorList.forEach(item => {
    if (!(errors[item.status] && errors[item.status].length)) {
      errors[item.status] = new Array(length).fill(0);
    }
    errors[item.status][index] = item.count;
  });
};

const parseInfo = (length, index, stat, info) => {
  const infoList = _.filter(
    stat.statusCounts,
    item => item.status !== 'ADD' && item.status !== 'REMOVE' && item.status.toLowerCase().startsWith('info'),
  );
  infoList.forEach(item => {
    if (!(info[item.status] && info[item.status].length)) {
      info[item.status] = new Array(length).fill(0);
    }
    info[item.status][index] = item.count;
  });
};

// For dynamic red with saturation apply
// const difference = i * 40;
// `rgb(${155 + difference}, ${10 + difference}, ${20 + difference})`

const generateErrorStack = errors =>
  Object.keys(errors).map((key, i) => ({
    name: key,
    data: errors[key],
    stack: 'Error',
    color: errorStackColors[i % errorStackColors.length],
  }));

const generateInfoStack = info =>
  Object.keys(info).map((key, i) => ({
    name: key,
    data: info[key],
    stack: 'Info',
    color: infoStackColors[i % infoStackColors.length],
  }));

const getPartnerStats = (isEventConnector, t) =>
  createSelector(selectPartnerStats, stats => {
    if (!stats) return {};

    const days = [];
    const added = [];
    const removed = [];
    const errors = {};
    const info = [];

    stats.forEach((stat, i) => {
      days.push(stat.day);
      added.push(parseStatsCount(stat.statusCounts, 'ADD'));
      removed.push(parseStatsCount(stat.statusCounts, 'REMOVE'));
      parseInfo(stats.length, i, stat, info);
      parseError(stats.length, i, stat, errors);
    });

    let series;

    if (isEventConnector) {
      series = [
        {
          name: t('audiences:chart.labelEventsSent'),
          data: added,
          stack: 'Events sent',
          color: '#417505',
          tooltip: t('audiences:chart.eventsSentTooltip'),
        },
        ...generateErrorStack(errors),
      ];
    } else {
      series = [
        {
          name: t('audiences:chart.labelAdded'),
          data: added,
          stack: 'Added',
          color: '#417505',
        },
        {
          name: t('audiences:chart.labelRemoved'),
          data: removed,
          stack: 'Removed',
          color: '#0054b0',
        },
        ...generateInfoStack(info),
        ...generateErrorStack(errors),
      ];
    }

    return {
      xAxis: days.map(day => moment(day).format('D. MMM')),
      series,
      noDataMessage: i18n.t('audiences:chart.noStats'),
    };
  });

const getFetchingAudienceStats = state => get(state, 'profiles.audienceActivity.promise.fetchingAudienceStats');
const getFetchingPartnerStats = state => get(state, 'profiles.audienceActivity.promise.fetchingPartnerStats');

export { getAudienceStats, getFetchingAudienceStats, getFetchingPartnerStats, getPartnerStats, selectDocumentationUrl };
