import { combineReducers } from 'redux';
import basescripts from './basescripts';
import clients from './clients';
import sites from './sites';
import users from './users';

export * from './sites';

// Default Context reducer
export default combineReducers({
  basescripts,
  sites,
  clients,
  users,
});
