import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '~/components/src/PrivateRoute';
import { getAngularService } from 'ReactAngular/react.service';
import { history } from '~/common';
import constants from '~/profiles/connectors/form/constants';
import EventConnectorTransformationsPage from '~/profiles/connectors/form/partners/facebookConversions/components/EventConnectorTransformationsPage';
import CollectorsViewPage from './Collectors';
import NewCollectorPage from './NewCollector';
import EditCollectorPage from './EditCollector';
import CollectorPage from './Collector';
import VariablesViewPage from './Variables';
import EngagementsViewPage from './Engagements';
import EngagementViewPage from './EngagementView';
import EngagementEditPage from './EngagementEdit';
import EngagementNewPage from './EngagementNew';
import CustomerFactsViewPage from './CustomerFacts';
import CustomerFactViewPage from './CustomerFactView';
import CustomerFactEditPage from './CustomerFactEdit';
import CustomerFactNewPage from './CustomerFactNew';
import ConnectorPage from './Connector';
import ConnectorsPage from './Connectors';
import ExportContainer from '../export/ExportContainer';
import { NewExportContainer } from '../export/new';
import NewConnectorContainer from '../connectors/form/ConnectorFormContainer';
import CustomerData from './CustomerData';
import Dashboard from './Dashboard';
import FiltersPage from './Filters/FiltersPage';
import TransformationsPage from './Transformations/TransformationsPage';
import './styles.scss';

const PROFILES_EDIT_PERMISSION = 'PROFILES_EDIT';
const DATA_ACCESS_PERMISSION = 'DATA_ACCESS';

const { CREATE_SERVER, CREATE_EVENT, EDIT_SERVER, EDIT_EVENT } = constants.modes;

class ProfilesPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      canEditConnectors: false,
      canEditExports: false,
      canEditProfiles: false,
    };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isReady: true,
        canEditConnectors: context.hasPermission(PROFILES_EDIT_PERMISSION),
        canEditExports: context.hasPermission(DATA_ACCESS_PERMISSION),
        canEditProfiles: context.hasPermission(PROFILES_EDIT_PERMISSION),
      });
    });
  }

  render() {
    const { isReady, canEditConnectors, canEditExports, canEditProfiles } = this.state;
    return (
      isReady && (
        <div className="Profiles">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="collectors/dashboard" element={<CollectorsViewPage canEditProfiles={canEditProfiles} />} />
            <Route
              path="collectors/add"
              element={<PrivateRoute authenticated={canEditProfiles} component={NewCollectorPage} />}
            />
            <Route
              path="collectors/edit/:collectorId"
              element={<PrivateRoute authenticated={canEditProfiles} component={EditCollectorPage} />}
            />
            <Route path="collectors/view/:collectorId" element={<CollectorPage canEditProfiles={canEditProfiles} />} />
            <Route path="partners/list" element={<ConnectorsPage canEdit={canEditConnectors} />} />
            <Route path="partners/list/stats/:connectorId" element={<ConnectorPage canEdit={canEditConnectors} />} />
            <Route
              path="partners/list/eventStats/:connectorId"
              element={<ConnectorPage canEdit={canEditConnectors} isEventConnector={true} />}
            />
            <Route path="variables/view" element={<VariablesViewPage canEdit={canEditProfiles} />} />
            <Route path="engagements" element={<EngagementsViewPage canEditProfiles={canEditProfiles} />} />
            <Route path="engagements/dashboard" element={<EngagementsViewPage canEditProfiles={canEditProfiles} />} />
            <Route
              path="engagements/view/:engagementId"
              element={<EngagementViewPage canEditProfiles={canEditProfiles} />}
            />
            <Route
              path="engagements/edit/:engagementId"
              element={<PrivateRoute authenticated={canEditProfiles} component={EngagementEditPage} />}
            />
            <Route
              path="engagements/add"
              element={<PrivateRoute authenticated={canEditProfiles} component={EngagementNewPage} />}
            />
            <Route path="filters/*" element={<FiltersPage canEditProfiles={canEditProfiles} />} />
            <Route path="transformations/*" element={<TransformationsPage canEditProfiles={canEditProfiles} />} />

            <Route
              path="export"
              element={<ExportContainer key={this.props?.params?.type} canEditProfiles={canEditExports} />}
            />
            <Route
              path="export/view"
              element={<ExportContainer key={this.props?.params?.type} canEditProfiles={canEditExports} />}
            />
            <Route path="customerData/dashboard" element={<CustomerData />} />
            <Route path="export/add" element={<NewExportContainer navigator={history} />} />
            <Route path="export/edit/:id" element={<NewExportContainer isEdit />} />
            <Route
              path="partners/form/server/step2/:partnerType"
              element={<NewConnectorContainer canEdit={canEditConnectors} canUpdate={false} mode={CREATE_SERVER} />}
            />
            <Route
              path="partners/form/event/step2/:partnerType"
              element={<NewConnectorContainer canEdit={canEditConnectors} canUpdate={false} mode={CREATE_EVENT} />}
            />
            <Route
              path="partners/edit/:connectorId"
              element={<NewConnectorContainer canEdit={canEditConnectors} canUpdate={true} mode={EDIT_SERVER} />}
            />
            <Route
              path="partners/editEvent/:connectorId"
              element={<NewConnectorContainer canEdit={canEditConnectors} canUpdate={true} mode={EDIT_EVENT} />}
            />
            <Route
              path="partners/editEvent/:eventConnectorId/transformations"
              element={<EventConnectorTransformationsPage />}
            />
            <Route path="externalFacts" element={<CustomerFactsViewPage canEditProfiles={canEditProfiles} />} />
            <Route
              path="externalFacts/dashboard"
              element={<CustomerFactsViewPage canEditProfiles={canEditProfiles} />}
            />
            <Route
              path="externalFacts/view/:customerFactId"
              element={<CustomerFactViewPage canEditProfiles={canEditProfiles} />}
            />

            <Route
              path="externalFacts/edit/:customerFactId"
              element={<PrivateRoute authenticated={canEditProfiles} component={CustomerFactEditPage} />}
            />
            <Route
              path="externalFacts/add"
              element={<PrivateRoute authenticated={canEditProfiles} component={CustomerFactNewPage} />}
            />
          </Routes>
        </div>
      )
    );
  }
}

export default ProfilesPages;
