import React from 'react';
import { useAPI } from '~/common';
import RuleCardView from '~/common/modules/rules/components/RuleCardView';
import { Heading } from '~/components';
import { INTEGRATION_TYPE } from '~/components/src/ConnectorCard';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import AudienceConnectors from '~/profiles/components/AudienceConnectors';
import { getLogoUrl, getPropertiesFromConnector } from '~/profiles/connectors';
import { fetchStepData } from '~/workflows/dataService';
import { getRuleTypeByCapitalizedValue } from '~/workflows/form/Journey/Step/utils';
import { Step } from '~/workflows/form/Journey/types';
import { TStepDetailsProps } from '~/workflows/types';

const StepDetails = ({ stepId }: TStepDetailsProps) => {
  const { data, isLoading } = useAPI(() => (stepId ? fetchStepData(stepId) : Promise.resolve({} as Step)), [stepId]);

  const connectors = data?.integrations.map((integration: any) => {
    const properties = getPropertiesFromConnector(integration);

    return {
      ...integration,
      properties,
      logoUrl: getLogoUrl(integration),
      integrationType: INTEGRATION_TYPE.serverToServer,
    };
  });

  const eventListeners =
    data?.variableModifications.map(
      (variableModification: {
        ruleType: { name: any };
        ruleName: any;
        properties: { propertyName: any; variableName: any }[];
        variableModificationId: any;
      }) => {
        const ruleType = getRuleTypeByCapitalizedValue(variableModification.ruleType.name);
        const { title, typeLabel } = getTitleAndTypeLabel(ruleType, variableModification.ruleName);

        const properties = variableModification.properties.map(
          (property: { propertyName: any; variableName: any }) => ({
            property: property.propertyName,
            storedVariable: property.variableName,
          }),
        );

        return {
          ruleType,
          title,
          typeLabel,
          properties,
          variableModificationId: variableModification.variableModificationId,
        };
      },
    ) || [];

  if (isLoading) return <Spin />;

  return (
    <>
      <Heading title={i18n.t('workflow:overview.headers.connectors')} kind="h4" testHook="eventListenersHeading" />
      <AudienceConnectors connectors={connectors} />
      {eventListeners.length > 0 && (
        <Heading
          title={i18n.t('workflow:overview.headers.eventListeners')}
          kind="h4"
          testHook="eventListenersHeading"
        />
      )}
      {eventListeners.map(listener => (
        <RuleCardView
          type={listener.ruleType}
          title={listener.title}
          typeLabel={listener.typeLabel}
          properties={listener.properties}
          timeCondition={null}
          visibleColumns={['property', 'configuredVariable']}
          testHook="eventListenerCard"
          key={listener.variableModificationId}
        />
      ))}
    </>
  );
};

export default StepDetails;
