import React, { useRef } from 'react';
import { DndProvider, useDrag, useDrop, DropTargetMonitor, DragLayerMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cx from 'classnames';
import BtnOutlined from '~/components/src/BtnOutlined';
import Btn from '~/components/src/Btn';
import ModalWindow from '~/components/src/ModalWindow/ModalWindow';
import {
  ValueTransformationsModalProps,
  ValueTransformationDragItem,
  ValueTransformationDraggableWrapperProps,
} from './types';
import ValueTransformation from './ValueTransformation';
import './ValueTransformationsModal.scss';

const VALUE_TRANSFORMATION = 'VALUE_TRANSFORMATION';

const ValueTransformationDraggableWrapper = ({
  id,
  index,
  moveValueTransformation,
  children,
}: ValueTransformationDraggableWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ canDrop }, drop] = useDrop({
    accept: VALUE_TRANSFORMATION,
    collect(monitor) {
      return {
        canDrop: monitor.canDrop(),
      };
    },
    hover(item: ValueTransformationDragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveValueTransformation(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: VALUE_TRANSFORMATION,
    item: (): ValueTransformationDragItem => ({
      id,
      index,
    }),
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={cx('ValueTransformationDraggableWrapper', {
        'ValueTransformationDraggableWrapper--hidden': isDragging,
        'ValueTransformationDraggableWrapper--withDottedBorder': canDrop && !isDragging,
      })}
    >
      {children}
    </div>
  );
};

const ValueTransformationsModal = ({
  mapping,
  mappingPath,
  closeValueTransformationsModal,
  getValidationErrorByPath,
  t,
}: ValueTransformationsModalProps): JSX.Element => (
  <ModalWindow title="Configure transformations" closeModal={closeValueTransformationsModal}>
    <>
      <div className="ValueTransformationsModal-transformationsContainer">
        {mapping.valueTransformations.length > 0 && (
          <div className="ValueTransformationsHeader">
            <div className="ValueTransformationsHeader-item ValueTransformation-orderCell">
              {t('partners:eventConnector.order')}
            </div>
            <div className="ValueTransformationsHeader-item ValueTransformation-transformationTypeCell">
              {t('partners:eventConnector.transformation')}
            </div>
            <div className="ValueTransformationsHeader-item ValueTransformation-ValueCell">
              {t('partners:eventConnector.value')}
            </div>
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          {mapping.valueTransformations.map((valueTransformation, valueTransformationIndex) => (
            <ValueTransformationDraggableWrapper
              id={valueTransformation.id}
              index={valueTransformationIndex}
              moveValueTransformation={mapping.moveValueTransformation}
              key={valueTransformation.id}
            >
              <ValueTransformation
                valueTransformation={valueTransformation}
                options={mapping.listAvailableValueTransformations()}
                remove={() => {
                  mapping.deleteValueTransformation(valueTransformation.id);
                }}
                getValidationErrorByPath={getValidationErrorByPath}
                valueTransformationPath={`${mappingPath}.valueTransformations[${valueTransformationIndex}]`}
                index={valueTransformationIndex}
                t={t}
              />
            </ValueTransformationDraggableWrapper>
          ))}
        </DndProvider>
        <BtnOutlined
          className="EventConnectorTransformationsPage-addEventButton"
          size="xs"
          icon="add"
          onClick={mapping.addValueTransformation}
        >
          {t('partners:eventConnector.addValueTransformation')}
        </BtnOutlined>
      </div>
      <div className="ValueTransformationsModal-saveButton">
        <Btn color="blue" type="submit" onClick={closeValueTransformationsModal}>
          {t('common:actions.save')}
        </Btn>
      </div>
    </>
  </ModalWindow>
);

export default ValueTransformationsModal;
