angular.module('webUi.filter.saferegex', [])

    .filter('saferegex', function() {
        return function(input) {

            if(_.isUndefined(input)) {
                return '';
            }

            // escape
            return input.replace(/[-[\]/{}()*+?\\^$|]/g, '\\$&');
        };
    });
