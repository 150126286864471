import React from 'react';
import Spinner from '~/components/src/Spinner';
import Empty from '~/components/src/Empty';
import AudiencesTable from './AudiencesTable';

const STATE_KEY = 'CUSTOMER_DATA_AUDIENCE';

const CustomerAudiencesComponent = ({ customerAudiencesState, t }) => {
  const notFoundMessage = t('notFound');

  const { value, error } = customerAudiencesState;

  if (error) {
    return <Empty body={notFoundMessage} />;
  }

  if (!value) return <Spinner />;

  if (!value.length) return <Empty body={notFoundMessage} />;

  return <AudiencesTable list={value} stateKey={STATE_KEY} />;
};

export default CustomerAudiencesComponent;
