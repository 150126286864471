angular.module('webUi.ui.tagmanagement.tags.conditions.landing', ['ui.validate'])

    .controller('TagmanagementTagFormLandingConditionController', [
        '$scope', 'ValidService', 'TagmanagementService', 'Utils',
        function TagmanagementTagFormLandingConditionController ($scope, ValidService, TagmanagementService, Utils) {

            TagmanagementService.getMatchTypes().then(function(matchTypes) {
                $scope.matchTypes = Utils.objectMap(matchTypes, 'name');
            });

            $scope.setFixedMatchTypeFor = function(conditionClass) {
                //when we select the rule type to be LandingPatternCondition aka Entire URL, the matchType is fixed to a regex
                if(conditionClass && conditionClass === 'LandingPatternCondition') {
                    $scope.state.formCondition.matchType = 'REGEX'; //hardcoded
                } else {
                    $scope.state.formCondition.matchType = null;
                }
            };


            /**
		 * Check whether we should show a value input field (with validation) based on the allowed values of the matchType in this form
		 * @param inArray - the array of matchType values that would trigger showing a particular value input field
		 */
            $scope.showParameterValue = function(inArray) {
                var matchType = $scope.state.formCondition.matchType;
                if(!matchType) {
                    return false;
                }
                // matchType can be either literal string value or enum object, so check both
                return _.contains(inArray, matchType.name) || _.contains(inArray, matchType);
            };

            $scope.conditionClazzez = ['LandingPatternCondition', 'LandingParamCondition'];

            $scope.state.conditions = $scope.getConditions($scope.conditionClazzez);

            $scope.newCondition = function() {
                $scope.state.formCondition = {
                    clazz: 'LandingParamCondition',
                    mode: 'new'
                };
            };

            $scope.selectCondition = function(condition) {
                $scope.state.formCondition = angular.copy(condition);
                $scope.state.formCondition.mode = 'edit'; //?
                //if the matchType is null, assume it's a ReferralPatternCondition and set to REGEX
                $scope.state.formCondition.matchType = $scope.state.formCondition.matchType || 'REGEX';
            };

            /**
         * Whenever the match type changes, clear the property value
         */
            $scope.clearConditionValue = function () {
                $scope.state.formCondition.value = undefined;
            };
        }
    ]);

