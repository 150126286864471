import PropTypes from 'prop-types';
import React from 'react';
import { noop } from '../../helper/util/noop';

function getTooltip(page, step, get = noop) {
  const fromValue = get(step[0]);
  const toValue = get(step[step.length - 1]);

  return !(fromValue || toValue)
    ? `Page ${page + 1}`
    : `Page ${page + 1} - From ${fromValue || 'N/A'} to ${toValue || 'N/A'}`;
}

const Step = ({ step, currentPage, page, get, onPaginate }) => {
  const tooltip = getTooltip(page, step, get);

  const btnClass = ['Pagination-step t-paginationLink'];
  if (page === currentPage) {
    btnClass.push('is-active');
  }

  return (
    <button className={btnClass.join(' ')} onClick={() => onPaginate(page)} title={tooltip} type="button">
      {page + 1}
    </button>
  );
};

Step.propTypes = {
  step: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  get: PropTypes.func,
  onPaginate: PropTypes.func.isRequired,
};

export default Step;
