angular
    .module('webUi.ui.admin.importsView', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.importsView', {
                url: '/imports/view/{importId}',
                isReact: true,
            });
        },
    ]);
