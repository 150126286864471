import React, { useState } from 'react';
import { Notification } from '~/components';
import DeletePopup from '~/components/src/DeletePopup';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { deleteCampaign } from '../dataService';

type DeleteCampaignProps = {
  campaignId: string;
  campaignName: string;
  callback?: () => void;
  setIsPickerVisible: (val: boolean) => void;
};

function DeleteCampaign({ campaignId, campaignName, setIsPickerVisible, callback }: DeleteCampaignProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteCampaign(campaignId).then(() => {
      if (callback) {
        callback();
      }
      setIsPickerVisible(false);
      showSuccess({ header: i18n.t('external:notifications.campaignDeleteSuccess') });
    });
    setIsDeleting(false);
  };

  return (
    <DeletePopup
      isLoading={isDeleting}
      title={i18n.t('external:form.deleteHeader')}
      hidePopup={() => setIsPickerVisible(false)}
      onClick={() => handleDelete()}
    >
      <Notification>{i18n.t('external:form.deleteMessage', { campaignName })}</Notification>
    </DeletePopup>
  );
}

export default DeleteCampaign;
