import React from 'react';
import cx from 'classnames';
import '~/profiles/components/Dnd.scss';

export function defaultDragConnect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const DragComponent = ({ className, children, connectDragSource, isDragging }) =>
  connectDragSource(
    <div className={cx('Dnd-source Dnd-item', { 'is-dragging': isDragging }, className)}>{children}</div>,
  );

export default DragComponent;
