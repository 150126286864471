import React, { useEffect, useState } from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import FormikCheckbox from '~/components/src/FormikCheckbox';
import Spin from '~/components/src/Spin';
import { AI_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { getAngularService } from 'ReactAngular/react.service';
import {
  DAYS,
  DEFAULTS,
  DEPLOY_FREQUENCY_TERMS,
  HOURS,
  MINUTES,
  MODEL_STATUS,
  TRAINING_FREQUENCY_TERMS,
} from '../constants';
import { deployModel, fetchModels } from '../dataService';
import { DeployModelFormValues, DeployModelProps } from '../types';

const initialValues: DeployModelFormValues = {
  onlineInferencing: false,
  inferenceFrequency: DEFAULTS.INFERENCE_FREQUENCY,
  inferenceFrequencyTerm: HOURS.value,
  trainingFrequency: DEFAULTS.TRAINING_FREQUENCY,
  trainingFrequencyTerm: DAYS.value,
};

const validationSchema = Yup.object().shape({
  onlineInferencing: Yup.boolean(),
  inferenceFrequency: Yup.number().when('inferenceFrequencyTerm', {
    is: MINUTES.value,
    then: Yup.number().when('onlineInferencing', {
      is: true,
      then: Yup.number().required(i18n.t('validation:validation.required')),
      otherwise: Yup.number()
        .positive(i18n.t('validation:validation.isPositiveNumber'))
        .integer(i18n.t('validation:validation.wholeNumber'))
        .min(
          DEFAULTS.MIN_INFERENCE_FREQUENCY,
          i18n.t('ai:validations.minInferenceFrequency', {
            min: `${DEFAULTS.MIN_INFERENCE_FREQUENCY} ${MINUTES.label}`,
          }),
        )
        .required(i18n.t('validation:validation.required')),
    }),
    otherwise: Yup.number()
      .positive(i18n.t('validation:validation.isPositiveNumber'))
      .integer(i18n.t('validation:validation.wholeNumber'))
      .required(i18n.t('validation:validation.required')),
  }),
  inferenceFrequencyTerm: Yup.string().required(i18n.t('validation:validation.required')),
  trainingFrequency: Yup.number()
    .positive(i18n.t('validation:validation.isPositiveNumber'))
    .integer(i18n.t('validation:validation.wholeNumber'))
    .required(i18n.t('validation:validation.required')),
  trainingFrequencyTerm: Yup.string().required(i18n.t('validation:validation.required')),
});

const handleOnlineInferencing = async (
  setFieldValue: FormikHelpers<DeployModelFormValues>['setFieldValue'],
  onlineInferencing: boolean,
) => {
  await setFieldValue('onlineInferencing', onlineInferencing);
  if (onlineInferencing) {
    setFieldValue('inferenceFrequency', 0);
    setFieldValue('inferenceFrequencyTerm', MINUTES.value);
  } else {
    setFieldValue('inferenceFrequency', DEFAULTS.MIN_INFERENCE_FREQUENCY);
  }
};

function DeployModel({ modelId, hideModal }: DeployModelProps): JSX.Element {
  const SecurityService = getAngularService(document, 'SecurityService');
  const [maxAiModelLimit, setMaxAiModelLimit] = useState<number | null>(null);
  const { data: models, isLoading } = useAPI(() => fetchModels());

  useEffect(() => {
    SecurityService.getSecurityContext().then((context: any) => {
      const { currentSiteId, sites } = context;
      const maxAiModelLimit = sites[currentSiteId]?.maxAiModelLimit;
      setMaxAiModelLimit(maxAiModelLimit);
    });
  }, []);

  const modelExceedMaxDeployLimit = models
    ? maxAiModelLimit !== null &&
      models.filter(model => model.status === MODEL_STATUS.DEPLOYING || model.status === MODEL_STATUS.DEPLOYED)
        ?.length >= maxAiModelLimit
    : false;

  const handleDeployAction = async (values: DeployModelFormValues) => {
    await deployModel(modelId, values);
    showSuccess({ header: i18n.t('ai:notifications.modelDeploySuccess') });
    hideModal();
  };

  if (isLoading) return <Spin />;

  return (
    <div>
      <Formik onSubmit={handleDeployAction} validationSchema={validationSchema} initialValues={initialValues}>
        {({ handleSubmit, setFieldValue, errors, values, isValid }) => (
          <form onSubmit={handleSubmit}>
            <>
              <div className="py-4">
                <FormikCheckbox
                  id="onlineInferencing"
                  name="onlineInferencing"
                  onChange={() => handleOnlineInferencing(setFieldValue, !values.onlineInferencing)}
                  text={i18n.t('ai:new.realTimeInferencing')}
                />
              </div>
              <FieldWrapper label={i18n.t('ai:new.inferenceFrequency')} className="DeployModel-row" excludeErrorOrHint>
                <Field
                  name="inferenceFrequency"
                  as={FormikInputField}
                  placeholder={i18n.t('ai:new.inferenceFrequency')}
                  errorText={errors.inferenceFrequency}
                  autoFocus={true}
                  type="number"
                  disabled={values.onlineInferencing}
                  min={1}
                  className="DeployModel-numberInputField"
                />
                <Field
                  name="inferenceFrequencyTerm"
                  as={FormikSelectField}
                  placeholder={i18n.t('ai:new.inferenceFrequencyTerm')}
                  options={DEPLOY_FREQUENCY_TERMS}
                  disabled={values.onlineInferencing}
                  getOptionLabel={(option: { label: string; value: string }) => option.label}
                  getOptionValue={(option: { label: string; value: string }) => option.value}
                  customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                    setFieldValue(name, option.value);
                  }}
                  errorText={errors.inferenceFrequencyTerm}
                  className="DeployModel-selectField"
                  menuPosition="absolute"
                />
              </FieldWrapper>
              <FieldWrapper label={i18n.t('ai:new.trainingFrequency')} className="DeployModel-row" excludeErrorOrHint>
                <Field
                  name="trainingFrequency"
                  as={FormikInputField}
                  placeholder={i18n.t('ai:new.trainingFrequency')}
                  errorText={errors.trainingFrequency}
                  type="number"
                  min={1}
                  className="DeployModel-numberInputField"
                />
                <Field
                  name="trainingFrequencyTerm"
                  as={FormikSelectField}
                  placeholder={i18n.t('ai:new.trainingFrequencyTerm')}
                  options={TRAINING_FREQUENCY_TERMS}
                  getOptionLabel={(option: { label: string; value: string }) => option.label}
                  getOptionValue={(option: { label: string; value: string }) => option.value}
                  customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                    setFieldValue(name, option.value);
                  }}
                  errorText={errors.trainingFrequencyTerm}
                  className="DeployModel-selectField"
                  menuPosition="absolute"
                />
              </FieldWrapper>
            </>
            {modelExceedMaxDeployLimit && (
              <Notification kind="error">{i18n.t('ai:actions.deployDisabled')}</Notification>
            )}
            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={hideModal}
              isConfirmEnabled={isValid && !modelExceedMaxDeployLimit}
              className="ModelActionsForm-actions"
              confirmText={i18n.t('ai:actions.deploy')}
              testHook="deployModel"
              gaAction={AI_EVENTS.MODEL.DEPLOYED}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default DeployModel;
