angular.module('webUi.directive.support.template.r42formElement', [])

    .directive('r42formElement', ['Utils', '$timeout',
        function (Utils, $timeout) {
            return {
                restrict: 'E',
                replace: true,
                scope: true,
                link: function(scope, elem, attrs) {
				
                    scope.r42Label = attrs['label'];
                    scope.r42Type = attrs['type'] || 'text';
                    scope.r42Description = attrs['description'];
				
                    if(attrs['ngModel']) {
                        scope.r42ModelName = attrs['ngModel'].substring(_.lastIndexOf(attrs['ngModel'], '.') + 1);
                        scope.r42Model = attrs['ngModel'].replace('tag.content.variables.', '');
                    }
				
                    scope.r42Placeholder = attrs['placeholder'] || 'Please provide a value';
                    scope.r42Required = attrs['required'] || false;
				
                    if(attrs['r42Validate']) {
                        scope.r42Validate = Utils.getScopeValue(scope.$parent, attrs['r42Validate']);
                    }
				
                    if(attrs['svFocus']) {
                        $timeout(function() {
                            $(elem).find('input').focus();
                        });
                    }
                },
                transclude: true,
                templateUrl: 'directive/support/template/r42formElement.tpl.html'
            };
        }
    ]);
