import { reset } from 'redux-form';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { fetchDependantsInfo } from '../dependencies/actions';
import * as types from './types';
import AdminVariablesService from './dataService.js';

/**
 * Fetch variables
 */
export const fetchVariables = () => dispatch => {
  dispatch({
    type: types.VARIABLES_FETCH_START,
  });

  return AdminVariablesService.getVariables()
    .then(variables => {
      dispatch({
        type: types.VARIABLES_FETCH_SUCCESS,
        payload: variables,
      });
    })
    .catch(error => dispatch({ type: types.VARIABLES_FETCH_FAIL, error }));
};

/**
 * Delete variable
 * @param variableId
 */
export const deleteVariable = variableId => dispatch => {
  dispatch({
    type: types.VARIABLES_DELETE_START,
  });

  return AdminVariablesService.deleteVariable(variableId)
    .then(() => {
      // Remove variable from Store
      showSuccess({ body: i18n.t('variables:api.deletionSuccess') });
      dispatch({
        type: types.VARIABLES_DELETE_SUCCESS,
        payload: variableId,
      });
    })
    .catch(error => dispatch({ type: types.VARIABLES_DELETE_FAIL, error }));
};

/**
 * Save variable
 * @param variable
 */
export const saveVariable = variable => dispatch => {
  dispatch({
    type: types.VARIABLES_SAVE_START,
  });

  return AdminVariablesService.saveVariable(variable)
    .then(variable => {
      dispatch({
        type: types.VARIABLES_SAVE_SUCCESS,
        payload: {
          ...variable,
        },
      });
      dispatch(reset(types.VARIABLES_NEW_FORM_NAME));
    })
    .catch(error => dispatch({ type: types.VARIABLES_SAVE_FAIL, error }));
};

/**
 * Change the tab in the dependants container
 */
export const changeDependantTypesSelectedTab = selectedTab => dispatch => {
  dispatch({
    type: types.VARIABLES_DEPENDANTS_TYPE_TAB_CHANGE,
    payload: selectedTab,
  });
};

/**
 * Show the container for the usage overview of the variable
 */
export const showDependantsContainer = (variableId, selectedTab) => dispatch => {
  dispatch(fetchDependantsInfo(variableId));
  dispatch({
    type: types.VARIABLES_SHOW_DEPENDANTS_CONTAINER,
    payload: { variableId, selectedTab },
  });
};

/**
 * Hide the container for the usage overview of the variable
 */
export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.VARIABLES_HIDE_DEPENDANTS_CONTAINER,
  });
};
