import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { truncateString } from '~/common/utils/StringUtils';
import { PickerView, Tooltip } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { DATE_FORMAT, PAGE_SIZE, TIME_FORMAT } from '../constants';
import { deployChanges, getDiff } from '../dataService';
import { ReleasesProps, TEnvironment, TPublishHistory } from '../types';
import { DeploymentProgress } from './DeploymentProgress';
import { Differences } from './Differences';

const columnHelper = createColumnHelper<TPublishHistory>();

const handleExpandClick = async (row: Row<TPublishHistory>) => {
  if (!row.getIsExpanded()) {
    const diff = await getDiff(row.original.parentId, row.original.snapshotId);
    row.original = { ...row.original, diff };
  }
  row.toggleExpanded();
};

const Releases = ({ releases, environments, hasTagEdit, hasTagPublish, refetch }: ReleasesProps) => {
  const totalPages = Math.ceil(releases.length / PAGE_SIZE || 1);
  const [paginatedList, setPaginatedList] = useState(releases);
  const [showDeployModal, setShowDeployModal] = useState<null | { row: TPublishHistory; env: TEnvironment }>(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    setPaginatedList(releases.slice(start, end));
  }, [page, releases]);

  const mappedEnvironments = environments.map(env => ({
    ...env,
    snapshotIndex: releases.findIndex(release => release.snapshotId === env.activeConfigurationSnapshotId),
  }));

  const handleDeployConfig = async ({ row, env }: { row: TPublishHistory; env: TEnvironment }) => {
    const payload = {
      configurationSnapshotId: row.snapshotId,
      environmentId: env.environmentId,
    };

    await deployChanges(payload);
    showSuccess({ header: i18n.t('publishing:notifications.deploySuccess') });
    await refetch();
  };

  const getColumns = () => {
    const columns = [
      columnHelper.accessor('expander', {
        id: 'expander',
        header: () => <span></span>,
        cell: ({ row }) =>
          row.getCanExpand() && (
            <button className="t-expandButton pointer" onClick={() => handleExpandClick(row)}>
              <Icons className="h-6 w-6 text-r42-blue" icon={row.getIsExpanded() ? 'downArrow' : 'cheveronRight'} />
            </button>
          ),
        size: 10,
      }),
      columnHelper.accessor('publishDescription', {
        header: () => <span>{i18n.t('publishing:history.table.description')}</span>,
        cell: info => (
          <Tooltip tooltip={info.getValue()}>
            <span className="t-publishDescription">{truncateString(info.getValue(), 40)}</span>
          </Tooltip>
        ),
        size: 200,
      }),
      columnHelper.accessor('publishUserName', {
        header: () => <span>{i18n.t('publishing:history.table.publishedBy')}</span>,
        cell: info => <span className="t-publishUserName">{info.getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('publishDate', {
        header: () => <span>{i18n.t('publishing:history.table.publishedAt')}</span>,
        cell: info => (
          <p className="t-publishDate">
            {moment(info.getValue()).format(DATE_FORMAT)}
            <br />
            <small className="text-r42-blue">{moment(info.getValue()).format(TIME_FORMAT)}</small>
          </p>
        ),
        size: 100,
      }),
    ];

    const mappedCols = mappedEnvironments.map(env =>
      columnHelper.accessor(env.environmentName, {
        header: () => <span className="flex justify-center">{env.environmentName}</span>,
        cell: ({ row }) => (
          <DeploymentProgress
            row={row}
            env={env}
            page={page}
            setShowDeployModal={setShowDeployModal}
            lastRow={paginatedList.length - 1 === row.index}
            disabled={!(hasTagEdit && (hasTagPublish || !env.requiresAdmin) && !row.original.historic)}
          />
        ),
        size: 100,
      }),
    );

    return [...columns, ...mappedCols];
  };

  return (
    <>
      <UITable
        testHook="releases"
        data={paginatedList}
        columns={getColumns()}
        canExpand
        renderSubComponent={Differences}
        totalPages={totalPages}
        page={page}
        onPaginate={setPage}
        emptyMessage={i18n.t('publishing:messages.noRecords')}
        emptyBody={i18n.t('publishing:messages.noReleasesBody')}
        enablePagination
      />
      {showDeployModal && (
        <PickerView
          handlePickerVisibility={() => setShowDeployModal(null)}
          pickerTitle={i18n.t('publishing:history.actions.title')}
          className="!h-auto !w-1/3"
        >
          <div>
            <p className="t-confirmText">
              {i18n.t('publishing:history.actions.deployText', {
                name: showDeployModal.row.publishDescription,
                env: showDeployModal.env.environmentName,
              })}
            </p>
            <ActionButtons
              testHook="deployConfig"
              onDecline={() => setShowDeployModal(null)}
              onConfirm={() => handleDeployConfig(showDeployModal)}
              confirmText={i18n.t('publishing:history.actions.deploy')}
            />
          </div>
        </PickerView>
      )}
    </>
  );
};

export default Releases;
