import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import { defaultMaxInput, isEmails, isRequired } from '~/common';
import { FormSection } from '~/components/src/Containers';

const isValidEmails = values => {
  const emails = values.replace(/,+/g, ',').split(',');
  return isEmails(emails.map(item => item.trim()));
};

const ExportNotifyForm = props => {
  const { t } = props.data;
  return (
    <FormSection title={t('new.notify.header')} description={t('new.notify.subHeader')}>
      <Field
        label={t('new.notify.email.label')}
        name="email"
        placeholder={t('new.notify.email.placeholder')}
        component={ReduxFormTextAreaField}
        validate={[isRequired, defaultMaxInput, isValidEmails]}
        type="text"
      />
    </FormSection>
  );
};

export default ExportNotifyForm;
