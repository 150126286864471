angular.module('webUi.filter.deepJson', [])
/**
 * Duplicate the json filter included in angular,
 * but with the inner angular properties starting with $
 */
    .filter('deepJson', [function () {

        var allowed = ['$error', '$invalid', '$showError', '$errors'];

        function isWindow(obj) {

            return obj !== null && obj === obj.window;
        }

        function isScope(obj) {
            return obj && obj.$evalAsync && obj.$watch;
        }

        function toJsonReplacer(key, value) {
            var val = value;

            if (typeof key === 'string' && key.charAt(0) === '$' && allowed.indexOf(key) === -1) {

                val = undefined;
            } else if (isWindow(value)) {
                val = '$WINDOW';
            } else if (value && document === value) {
                val = '$DOCUMENT';
            } else if (isScope(value)) {
                val = '$SCOPE';
            }

            return val;
        }

        /**
     * @ngdoc function
     * @name angular.toJson
     * @module ng
     * @kind function
     *
     * @description
     * Serializes input into a JSON-formatted string. Includes properties with leading $ characters
     *
     * @param {Object|Array|Date|string|number} obj Input to be serialized into JSON.
     * @param {boolean=} pretty If set to true, the JSON output will contain newlines and whitespace.
     * @returns {string|undefined} JSON-ified string representing `obj`.
     */
        return function toJson(obj, pretty) {
            if (typeof obj === 'undefined') {
                return undefined;
            }
            return JSON.stringify(obj, toJsonReplacer, pretty ? '  ' : null);
        };

    }]);
