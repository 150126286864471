import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, uniq, uniqBy, isObject } from 'lodash';
import { Field, FormSection } from 'redux-form';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput } from '~/common';
import Form from '~/components/src/Form/Form';
import i18n from '~/context/i18n';
import { ReduxFormInputField, ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import { DEFAULT_R42_CONNECTOR } from '../../collectors/types';

const collectorTypeOptions = {
  ENGAGEMENT: 'ENGAGEMENT',
  CUSTOMER_FACT: 'EXTERNAL_FACT',
};

export const collectorTypesOptions = [
  { value: collectorTypeOptions.ENGAGEMENT, label: i18n.t('collectors:form.collectorTypesOptions.engagement.label') },
  {
    value: collectorTypeOptions.CUSTOMER_FACT,
    label: i18n.t('collectors:form.collectorTypesOptions.customerFact.label'),
  },
];

const multiSelectStyles = {
  multiValue: (base, state) => (state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base),
  multiValueLabel: (base, state) =>
    state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base,
  multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
};

// It's not reusable in a scope of other select fields and relies on partner logic
const extendFixedProperties = (options, partners) => {
  if (!Array.isArray(options)) {
    return options;
  }

  return uniq([DEFAULT_R42_CONNECTOR, ...options])
    .map(valueOption => partners.find(option => option === valueOption))
    .map(el => (el && el.optionId === DEFAULT_R42_CONNECTOR.optionId ? { ...el, isFixed: true } : el))
    .sort((a, b) => (a.optionId > b.optionId ? 1 : -1));
};

const hasProperties = (list, itemId) => {
  if (!itemId || isEmpty(list) || isEmpty(list.filter(item => item.id === itemId)[0].properties)) return false;
  return true;
};

const getProperties = (list, itemId) => list.filter(item => item.id === itemId)[0].properties;

export class CollectorForm extends Component {
  onChangeCollectorType() {
    this.props.change('engagementOrFactId', null);
  }
  onChangeEngagementType() {
    this.props.change('properties', []);
  }
  render() {
    const {
      handleSubmit,
      backHref,
      t,
      submitting = false,
      collectorType,
      engagements,
      externalFacts,
      engagementOrFactId,
      partners,
      touch,
    } = this.props;

    const collectorTypesName = get(collectorType, 'name', null);
    const engagementsList = engagements.map(item => ({ value: item.id, label: item.name }));
    const externalFactsList = externalFacts.map(item => ({ value: item.id, label: item.name }));

    const onFixedSelectChange =
      fieldProps =>
      (value, { action, removedValue }) => {
        const options = [DEFAULT_R42_CONNECTOR, ...partners];
        const parseOptionsChange = value => {
          if (_.find(value, { optionId: '*' })) {
            const uniqueOptions = uniqBy(options, 'optionId');
            return uniqueOptions;
          }
          return value;
        };
        if (action === 'pop-value' && removedValue.isFixed) {
          return undefined;
        }
        return value ? fieldProps.input.onChange(parseOptionsChange(value)) : undefined;
      };

    const handlePropertiesChange = (value, options) => {
      if (value) {
        if (value.indexOf(t('common:selectAll')) !== -1) {
          return options;
        }
        return value;
      }
      return [];
    };

    return (
      <Form onSubmit={handleSubmit} className="t-collectorsForm CollectorsForm">
        <Field
          label={t('collectors:form.name.label')}
          name="collectorName"
          placeholder={t('collectors:form.name.placeholder')}
          component={ReduxFormInputField}
          validate={[isRequired, defaultMaxInput]}
          testHook="collectorName"
        />
        <FormSection name="collectorType">
          <Field
            label={t('collectors:form.collectorType.label')}
            name="name"
            onChange={() => {
              this.onChangeCollectorType();
            }}
            component={ReduxFormSelectField}
            validate={isRequired}
            options={collectorTypesOptions}
            testHook="collectorType"
            touch={touch}
          />
        </FormSection>
        {collectorTypesName === collectorTypeOptions.ENGAGEMENT && (
          <Field
            label={t('collectors:form.engagementType.label')}
            name="engagementOrFactId"
            onChange={() => {
              this.onChangeEngagementType();
            }}
            component={ReduxFormSelectField}
            validate={isRequired}
            options={engagementsList || []}
            testHook="engagementType"
            touch={touch}
          />
        )}
        {collectorTypesName === collectorTypeOptions.CUSTOMER_FACT && (
          <Field
            label={t('collectors:form.customerFactType.label')}
            name="engagementOrFactId"
            onChange={() => {
              this.onChangeEngagementType();
            }}
            component={ReduxFormSelectField}
            validate={isRequired}
            options={externalFactsList || []}
            testHook="engagementType"
            touch={touch}
          />
        )}
        {collectorTypesName === collectorTypeOptions.ENGAGEMENT && hasProperties(engagements, engagementOrFactId) && (
          <Field
            name="properties"
            component={ReduxFormSelectField}
            label={t('collectors:form.properties.label')}
            testHook="properties"
            options={getProperties(engagements, engagementOrFactId) || []}
            isMulti
            allowSelectAll
            isConnected
            getOptionValue={option => option}
            getOptionLabel={option => option}
            allOption={t('common:selectAll')}
            touch={touch}
            onChange={(event, selectedValues) => {
              /*
                preventDefault here tells 'redux-form' to skip automatic value setting.
                We are doing in manually, using "change" below.
                https://redux-form.com/8.3.0/docs/api/field.md/#-code-onchange-event-newvalue-previousvalue-name-gt-void-code-optional-
              */
              event.preventDefault();

              const newValue = handlePropertiesChange(
                selectedValues,
                getProperties(engagements, engagementOrFactId) || [],
              );

              this.props.change('properties', newValue);
            }}
          />
        )}
        {collectorTypesName === collectorTypeOptions.CUSTOMER_FACT &&
          hasProperties(externalFacts, engagementOrFactId) && (
            <Field
              name="properties"
              component={ReduxFormSelectField}
              label={t('collectors:form.properties.label')}
              testHook="properties"
              options={getProperties(externalFacts, engagementOrFactId) || []}
              isMulti
              allowSelectAll
              isConnected
              getOptionValue={option => option}
              getOptionLabel={option => option}
              allOption={t('common:selectAll')}
              touch={touch}
              onChange={(event, selectedValues) => {
                /*
                preventDefault here tells 'redux-form' to skip automatic value setting.
                We are doing in manually, using "change" below.
                https://redux-form.com/8.3.0/docs/api/field.md/#-code-onchange-event-newvalue-previousvalue-name-gt-void-code-optional-
              */
                event.preventDefault();

                const newValue = handlePropertiesChange(
                  selectedValues,
                  getProperties(externalFacts, engagementOrFactId) || [],
                );

                this.props.change('properties', newValue);
              }}
            />
          )}
        {(collectorTypesName === collectorTypeOptions.ENGAGEMENT ||
          collectorTypesName === collectorTypeOptions.CUSTOMER_FACT) && (
          <Field
            name="syncPartners"
            component={props => {
              const options = [DEFAULT_R42_CONNECTOR, ...partners];
              const getValues = values => {
                if (values && !isObject(values[0])) {
                  return values?.filter(id => !!id).map(id => partners.find(partner => partner.optionId === id));
                }
                return values;
              };
              const initialValues = extendFixedProperties(getValues(props.meta?.initial) || [], options);
              const value = extendFixedProperties(getValues(props.input.value), options);

              return (
                <FieldWrapper label={t('collectors:form.syncPartners.label')}>
                  <SelectElement
                    options={options}
                    value={value}
                    defaultValue={initialValues}
                    getOptionValue={option => option.optionId}
                    getOptionLabel={option => option.optionLabel}
                    isMulti
                    isClearable={false}
                    allowSelectAll={true}
                    allOption={{
                      optionLabel: t('common:selectAll'),
                      optionId: '*',
                    }}
                    onChange={onFixedSelectChange(props)}
                    styles={multiSelectStyles}
                    testHook="partners"
                  />
                </FieldWrapper>
              );
            }}
          />
        )}
        <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
      </Form>
    );
  }
}

CollectorForm.propTypes = {
  handleSubmit: PropTypes.func,
  touch: PropTypes.func.isRequired,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  collectorTypes: PropTypes.object,
  engagements: PropTypes.array,
  externalFacts: PropTypes.array,
  engagementOrFactId: PropTypes.string,
};

export default CollectorForm;
