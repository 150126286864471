angular
    .module('webUi.ui.content.medialibraries.dashboard', [])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.content.medialibraries.dashboard', {
                url: '/dashboard',
                isReact: true,
            });
        },
    ]);