import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl, getParam } from '~/common';
import { saveVariant, fetchTagExecutionTypesIfNeeded } from '~/support/tagtemplates/variants/actions';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import * as selectors from '~/support/tagtemplates/variants/selectors';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import TagVariantForm from '~/support/components/TagVariantForm';
import { getClients, getSiteModules } from '~/support/helpers/clientsHelper';
import { Panel } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

const FORM_ADD = 'tagVariantNew';

export class TagTemplateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      siteModules: [],
    };
  }

  componentDidMount() {
    getClients(document).then(clients => {
      this.setState({ clients });
    });
    getSiteModules(document).then(siteModules => {
      this.setState({ siteModules });
    });
    this.props.fetchExecutionTypes();
  }

  render() {
    const { t, ...other } = this.props;

    return (
      <Page>
        <Heading title={t('variantForm.header')} />
        <Panel>
          <TagVariantForm t={t} clients={this.state.clients} siteModules={this.state.siteModules} {...other} />
        </Panel>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const templateId = getParam(ownProps.location.search, 'templateId');
      return {
        templateId,
        cancelHref: buildUrl(`support/tagtemplates/templates/view/${templateId}`),
        executionTypes: selectors.getVariantExecutionTypes(state),
        initialValues: {
          templateId,
          defaultPriority: 10,
          sortingOrder: 1,
          executionType: selectors.getDefaultExecutionType(state),
        },
      };
    },
    dispatch => ({
      fetchExecutionTypes: () => dispatch(fetchTagExecutionTypesIfNeeded()),
      onSubmit: values => dispatch(saveVariant(values)),
    }),
  ),
  reduxForm({
    form: FORM_ADD,
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagTemplateGroup);
