import { get } from 'lodash';

/**
 * Get current clientId for matching
 * @param state
 */
export const getMatchClientId = state => get(state, 'context.users.matchClients.ui.matchClientId');

/**
 * Get all not matched clients for user
 * @param state
 */
export const getNotMatchedClients = state => get(state, 'context.users.matchClients.list');

/**
 * Should make a call to save not matched clients?
 * @param state
 */
export const canMatchClients = state => getNotMatchedClients(state).length > 0;
