import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import { history } from '~/common';
import i18n from '~/i18n';
import 'tippy.js/dist/tippy.css';
import 'react-dates/lib/css/_datepicker.css';
import configureStore from './configureStore';
import ReactRoot from './ReactRoot';

// Create a new store
export const store = configureStore();

// Render React
const run = () => {
  const root = document.getElementById('reactRoot');
  if (!root) {
    return;
  }

  ReactDOM.render(<ReactRoot store={store} history={history} i18n={i18n} />, root);
};

// Run React app when DOM is ready
const loadedStates = ['complete', 'loaded', 'interactive'];
if (loadedStates.includes(document.readyState) && document.body) {
  run();
} else {
  window.addEventListener('DOMContentLoaded', run, false);
}
