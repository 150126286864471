angular.module('webUi.service.test', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('TestService', ['$q', 'UUIDService', function($q, UUIDService){

        var BASE_PATH = 'test/';

        var defaultDevelopers = [
            {developerId: 'koen32d6-1605-4580-8cfe-7805c8e9d64d', firstName: 'Koen', lastName: 'Bos'},
            {developerId: 'tomas2d6-1605-4580-8cfe-7805c8e9d64d', firstName: 'Tomas', lastName: 'Salfischberger'}
        ];

        var nationalities = [
            {code: 'RO', name: 'Romanian'},
            {code: 'NL', name: 'Dutch'},
            {code: 'EN', name: 'English'},
            {code: 'BG', name: 'Bulgarian'},
            {code: 'CN', name: 'Chinese'},
            {code: 'UN', name: 'Ungabunga'}
        ];

        var service = {

            getBasePath: function() {
                return BASE_PATH;
            },

            getNationalities: function getNationalities() {
                var deferred = $q.defer();
                deferred.resolve(nationalities);
                return deferred.promise;
            },

            getDevelopers: function() {
                var deferred = $q.defer();
                deferred.resolve(defaultDevelopers);
                return deferred.promise;
            },

            getDeveloper: function(developerId) {
                return _.indexBy(defaultDevelopers, 'developerId')[developerId];
            },

            createDeveloper: function(createCommand) {
                createCommand.developerId = UUIDService.newId();
                var deferred = $q.defer();
                defaultDevelopers.push(createCommand);
                deferred.resolve(createCommand.developerId);
                return deferred.promise;
            },

            updateDeveloper : function(updateCommand) {
                var deferred = $q.defer();
                //defaultDevelopers.push(updateCommand);
                deferred.resolve(updateCommand.developerId);
                return deferred.promise;
            },

            deleteDeveloper : function() {
                var deferred = $q.defer();
                deferred.resolve({});
                return deferred.promise;
            }

        };

        return service;
    }]);
