import React from 'react';
import { Field } from 'redux-form';
import { isRequired } from '~/common';
import { ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection } from '~/components/src/Containers';
import constants from '../constants';

const ExportFrequencyForm = props => {
  const { t, touch } = props.data;
  const frequencyTypes = [
    { value: constants.FREQUENCY_TYPES.DAILY, label: t('new.frequencyTypes.daily') },
    { value: constants.FREQUENCY_TYPES.WEEKLY, label: t('new.frequencyTypes.weekly') },
    { value: constants.FREQUENCY_TYPES.MONTHLY, label: t('new.frequencyTypes.monthly') },
  ];
  return (
    <FormSection
      title={t('new.frequency.header')}
      description={`${t('new.frequency.subHeader')} ${t('new.frequency.hint')}`}
    >
      <Field
        label={t('new.frequency.title')}
        name="frequency"
        component={ReduxFormSelectField}
        options={frequencyTypes}
        validate={isRequired}
        touch={touch}
      />
    </FormSection>
  );
};

export default ExportFrequencyForm;
