angular.module('webUi.directive.scopeAttribute', [])

    .directive('scopeAttribute', ['Utils', function (Utils) {

        var setOnScope = function(scope, scopeAttribute, value) {
        //Enables usage of nested asignment (scope attribute is like object.property
            if(scopeAttribute.indexOf('.') === -1) {
                scope[scopeAttribute] = value;
            } else{
                Utils.setScopeValue(scope, scopeAttribute, value);
            }
        };

        var directiveDefinitionObject = {

            restrict : 'A',
            link: function (scope, element, attrs) {

                // init handle object if undefined and share with parent scope
                if(attrs.scopeAttributeWatch) {

                    // set current value on scope
                    setOnScope(scope, attrs.scopeAttribute, scope.$eval(attrs.scopeAttributeWatch));

                    scope.$watch(attrs.scopeAttributeWatch, function(newValue) {
                        setOnScope(scope, attrs.scopeAttribute, newValue);
                    }, true);
                }
            }
        };

        return directiveDefinitionObject;
    }]);
