angular.module('webUi.service.content.datafeed', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('DataFeedService', ['Restangular', '$q', 'Utils', function (Restangular, $q, Utils) {

        // The base path through which all calls should go
        var BASE_PATH = 'content/';
        var service = {

            /**
         *
         * @param {UUID} id the dataFeedId
         * @param {String} sampleKey
         *
         * @returns {Object} a promise of the sample with key/values representing the sample
         */
            getDataSample: function (id, sampleKey) {
                var dataSamplePromise = $q.defer();
                Restangular.one(BASE_PATH + 'dataFeed/' + id, 'sample').get({key: sampleKey}).then(function (dataSample) {
                    if (dataSample) {
                        dataSample = Utils.sanitizeRestangularOne(dataSample);
                        dataSamplePromise.resolve(dataSample);
                    }
                });
                return dataSamplePromise.promise;
            },

            /**
         *
         * @param {UUID} id the dataFeedId
         * @returns {Object} a promise of the dataFeed
         */
            getDataFeed: function (id) {
                var dataFeedPromise = $q.defer();
                Restangular.one(BASE_PATH + 'dataFeed', id).get().then(function (dataFeed) {
                    if (dataFeed) {
                        dataFeed = Utils.sanitizeRestangularOne(dataFeed);
                        dataFeedPromise.resolve(dataFeed);
                    }
                });
                return dataFeedPromise.promise;
            },

            canDeleteDataFeed: function (id) {
                var canDeletePromise = $q.defer();
                Restangular.one(BASE_PATH + 'dataFeed/' + id + '/canDelete').get().then(function (result) {
                    if (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        canDeletePromise.resolve(result);
                    }
                });
                return canDeletePromise.promise;
            },

            /**
         * @returns {List} a promise of the dataFeeds
         */
            getDataFeeds: function () {
                var dataFeedPromises = $q.defer();

                Restangular.all(BASE_PATH + 'dataFeed').getList().then(function (dataFeeds) {
                    if (dataFeeds) {
                        dataFeeds = Utils.sanitizeRestangularAll(dataFeeds);
                        dataFeedPromises.resolve(dataFeeds);
                    }
                });
                return dataFeedPromises.promise;
            },

            /**
         * Create a datafeed object by command object
         *
         * @returns id of the newly created datafeed
         */
            createDataFeed: function (createCommand) {
                var dataFeedIdPromise = $q.defer();
                Restangular.all(BASE_PATH + 'dataFeed').post(createCommand).then(function (dataFeedId) {
                    if (dataFeedId) {
                        dataFeedId = Utils.sanitizeRestangularOne(dataFeedId);
                        dataFeedIdPromise.resolve(dataFeedId);
                    }
                });
                return dataFeedIdPromise.promise;
            },

            /**
         * Update dataFeed object based on updateCommand
         */
            updateDataFeed: function (updateCommand) {
                return Restangular.all(BASE_PATH + 'dataFeed/update').post(updateCommand);
            },

            /**
         * Import/add a list of feed variables to this data feed
         * @param dataFeedId
         * @param feedVariables
         * @returns {*}
         */
            importFeedVariables: function (dataFeedId, feedVariables) {
                return Restangular.all(BASE_PATH + 'dataFeed/addVariables').post({
                    dataFeedId: dataFeedId,
                    feedVariables: feedVariables
                });
            },

            /**
         * Delete a datafeed object by id
         */
            deleteDataFeed: function (dataFeedId) {
                return Restangular.one(BASE_PATH + 'dataFeed', dataFeedId).remove();
            },

            /**
         * Get the feed variables defined in cassandra for potential import
         * @param dataFeedId
         * @returns {*}
         */
            getFeedVariables: function (dataFeedId) {
                var feedVariablesPromise = $q.defer();
                Restangular.one(BASE_PATH + 'dataFeed/', dataFeedId).getList('importVariables').then(function (feedVariables) {
                    feedVariables = Utils.sanitizeRestangularOne(feedVariables);
                    feedVariablesPromise.resolve(feedVariables);
                });
                return feedVariablesPromise.promise;
            }

        };

        return service;
    }]);

