angular.module('webUi.ui.tagmanagement.cookiePermission.removeGroupModal', ['ngResource', 'webUi.service', 'ui.bootstrap'])

    .controller('RemoveGroupModalController', ['$scope', 'dialogsModel', '$uibModalInstance', 'CookiePermissionService',
        function RemoveGroupModalController( $scope,   dialogsModel,   $uibModalInstance, CookiePermissionService) {

            $scope.group = dialogsModel.group;

            $scope.deleteGroup = function() {
                CookiePermissionService.deleteCookiePermissionGroup($scope.group.groupId).then(function() {
                    $uibModalInstance.close(true);
                });
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
