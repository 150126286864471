import React from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

type AdnuntiusFormProps = {
  t: any;
  values: {
    name: string;
    partnerNumber: number;
    token: string;
    siteId: string;
    networkId: string;
    advancedSettings: boolean;
  };
  partner: {
    partnerId: number;
    partnerNumber: number;
    partnerType: string;
    partnerName: string;
  };
  canUpdate: boolean;
  dirty: boolean;
  change: any;
};

function AdnuntiusForm({ t, values, partner, canUpdate }: AdnuntiusFormProps) {
  return (
    <FormSection name={constants.partnerTypes.ADNUNTIUS}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <>
          <FieldWithEditMode
            label={t('form.formFields.systemAccessToken')}
            name="token"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.siteId')}
            name="siteId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.networkId')}
            name="networkId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.teamId')}
            name="teamId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolderOptional')}
            type="text"
          />
        </>
      </ContainerFormSection>
    </FormSection>
  );
}

export default AdnuntiusForm;
