import i18n from '~/i18n';

export const APIMethods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const APIMethodTypes = [
  { label: i18n.t('common:apiMethods.get'), value: APIMethods.get },
  { label: i18n.t('common:apiMethods.post'), value: APIMethods.post },
  { label: i18n.t('common:apiMethods.put'), value: APIMethods.put },
  { label: i18n.t('common:apiMethods.delete'), value: APIMethods.delete },
];
