import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';

function UniqueValues({ propertyIndex, formProps, placeholder }) {
  const { errors, setFieldValue } = formProps;
  const name = `filters[${propertyIndex}].minListSize`;
  const validateField = value => {
    let error;
    if (!value) {
      error = i18n.t('common:errors.required');
      // eslint-disable-next-line no-restricted-globals
    } else if (isNaN(value) || value < 1 || value > 9999) {
      error = i18n.t('common:errors.notANumber');
    }
    return error;
  };

  useEffect(() => {
    const value = get(formProps.values, name, 1);
    setFieldValue(name, value);
  }, []);

  const errorText = errors.filters && errors.filters[propertyIndex] && errors.filters[propertyIndex].minListSize;
  return (
    <Field
      as={FormikInputField}
      name={name}
      validate={validateField}
      placeholder={placeholder}
      type="number"
      errorText={errorText}
      testHook="uniqueValuesParameter"
    />
  );
}

export default UniqueValues;
