angular.module('webUi.ui.content.campaigns.ads.conditions.placementParam', ['webUi.common.Utils'])

    .controller('AdServingPlacementParamConditionController', ['$scope', 'ValidService',
        function AdServingPlacementParamConditionController    ($scope,   ValidService) {
		
            var conditionClazz = 'PlacementParamCondition';
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            $scope.isEmpty = ValidService.isEmpty;

            $scope.validateRegex = ValidService.isValidPattern;
		
            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
        }
    ]);

