/**
 * MODULE SHOULD BE REMOVED, when the database migration of the existing tags is done
 */
angular.module('webUi.service.profiles.engagements', [
    'webUi.service.profilesConstants'
])
    .factory('EngagementService', ['InteractionService', 'INTERACTION_CONSTANTS',
        function(InteractionService, INTERACTION_CONSTANTS){

            var getEngagements = _.bind(InteractionService.getInteractions, this, INTERACTION_CONSTANTS.engagementType);

            return {
                getEngagements: getEngagements
            };
        }]);

