import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import { Link } from '~/components';
import { Panel } from '~/components/src/Containers';
import { components } from '~/components/src/Table';
import { withSort } from '~/components/src/Table/enhancements';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { getProfileVariables } from './util';

const { Table, Row, Cell } = components;
const MIN_VALUES_TO_DISPLAY = 5;

const WIDTHS = {
  workflowName: '30%',
  workflowType: '10%',
  stepName: '20%',
  variables: '30%',
  status: '20%',
};

const baseUrl = 'workflows';

const journeyUrl = (type, journeyId) =>
  buildUrl(`${baseUrl}/${type === WORKFLOW_TYPE.Audience ? 'view' : 'edit'}/${journeyId}`);

const stepUrl = (type, journeyId, stepId) => {
  const url =
    type === WORKFLOW_TYPE.Audience
      ? `${baseUrl}/view/${journeyId}?step=3&nodeId=${stepId}`
      : `${baseUrl}/edit/${journeyId}/step/${stepId}`;

  return buildUrl(url);
};

const renderProfileVariables = variables => {
  if (!variables) return <></>;

  const mappedVariables = getProfileVariables(variables);
  const [showMore, setShowMore] = useState(false);
  const valuesToDisplay = showMore ? mappedVariables?.length : MIN_VALUES_TO_DISPLAY;

  return (
    <Fragment>
      {mappedVariables?.slice(0, valuesToDisplay)?.map(variable => (
        <li key={variable}>{variable}</li>
      ))}
      {mappedVariables?.length > MIN_VALUES_TO_DISPLAY && !showMore && (
        <Link onClick={() => setShowMore(!showMore)}> [+] Show more</Link>
      )}
    </Fragment>
  );
};

const renderTableRows = data =>
  data.map(journey => {
    const { journeyId, journeyName, workflowType, stepName, stepId, status, variables } = journey;
    return (
      <Row key={journeyId}>
        <Cell style={{ width: WIDTHS.workflowName }}>
          <a target="_self" className="Link" href={journeyUrl(workflowType.name, journeyId)}>
            {journeyName}
          </a>
        </Cell>
        <Cell style={{ width: WIDTHS.workflowType }}>{workflowType.label}</Cell>
        <Cell style={{ width: WIDTHS.stepName }}>
          <a target="_self" className="Link" href={stepUrl(workflowType.name, journeyId, stepId)}>
            {stepName}
          </a>
        </Cell>
        <Cell style={{ width: WIDTHS.variables, flexDirection: 'column' }}>{renderProfileVariables(variables)}</Cell>
        <Cell style={{ width: WIDTHS.status }}>{status}</Cell>
      </Row>
    );
  });

const JourneyTable = ({ list, t, stateKey }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width={WIDTHS.workflowName} sortKey="workflowName" title={t('workflow.workflowName')} />
        <SortableHeader width={WIDTHS.workflowType} sortKey="workflowType" title={t('workflow.workflowType')} />
        <SortableHeader width={WIDTHS.stepName} sortKey="stepName" title={t('workflow.stepName')} />
        <SortableHeader width={WIDTHS.variables} sortKey="variables" title={t('audience.variables')} />
        <SortableHeader width={WIDTHS.status} sortKey="status" title={t('workflow.status')} />
      </Row>
      {renderTableRows(list)}
    </Table>
  </Panel>
);

JourneyTable.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string.isRequired,
};

JourneyTable.defaultProps = {
  list: [],
};

export default compose(withSort(), translate('customerData'))(JourneyTable);
