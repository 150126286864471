export const DEFAULT_GROUPS = [
  {
    name: 'Control Group',
    weight: 1,
    controlGroup: true,
  },
  {
    name: 'Experiment Group',
    weight: 1,
    controlGroup: false,
  },
];

export const SELECT_DAYS = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 90 days', value: 90 },
];
