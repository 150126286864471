import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UrlBuilder from './UrlBuilder';

const UrlBuilderPages = ({ hasUrlBuilderPermission }: { hasUrlBuilderPermission: boolean }): React.ReactElement => (
  <Routes>
    <Route
      path="campaign/dashboard"
      element={<UrlBuilder hasUrlBuilderPermission={hasUrlBuilderPermission} isDashboardPage={true} />}
    />
    <Route
      path="campaign/view/:campaignId"
      element={<UrlBuilder hasUrlBuilderPermission={hasUrlBuilderPermission} />}
    />
  </Routes>
);

export default UrlBuilderPages;
