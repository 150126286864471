angular.module('webUi.ui.profiles.partners.form', [
    'webUi.ui.profiles.partners.server.form',
    'webUi.ui.profiles.partners.server.form.step2',
    'webUi.ui.profiles.partners.event.form',
    'webUi.ui.profiles.partners.event.form.step2',
]).config(['$stateProvider', function config($stateProvider) {
    $stateProvider
        .state('site.profiles.partners.form', {
            url: '/form',
            isReact: true,
        });
}]);