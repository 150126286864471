// Requests stream splittes into 2 priority levels: critical and non-critical.
// The user should be able to configure the percentage of critical requests.
// But values of them should not be 0
// Formulas are:
// Critical Value = (ApiRateLimit * CriticalRequestPercentage) / 100
// Non Critical Value = ApiRateLimit * (100 - CriticalRequestPercentage) / 100
export const validateCriticalRateLimit = (apiRateLimit, priorityLevel) => {
  const criticalRateLimit = Math.floor((apiRateLimit * priorityLevel) / 100);
  const nonCriticalRateLimit = Math.floor((apiRateLimit * (100 - priorityLevel)) / 100);
  if (criticalRateLimit > 0 && nonCriticalRateLimit > 0) {
    return true;
  }
  return false;
};
