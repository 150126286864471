import React, { useState } from 'react';
import { translate } from 'react-i18next';
import cx from 'classnames';
import { useAPI } from '~/common/ApiHooks';
import { buildUrl } from '~/common';
import { I18NextT } from '~/components/src/Common/types';
import ConnectorsService from '~/profiles/connectors/dataService';
import i18n from '~/i18n';
import DefaultPanel from './DefaultPanel';
import { useDashboard } from '../context';
import { fetchPartnerStats } from '../dataService';
import { StatsKeyValue, StatsPlaceholder, StatsPropertyWithNumber, StatsTableHead } from './components';

interface ConnectorsPanelProps {
  t: I18NextT;
}

interface IEventConnector {
  eventConnectorId: string;
  name: string;
  destinationPartnerId: string;
  destinationPartnerType: string;
  activated: boolean;
}

interface PartnerStatsItemWithUrl extends PartnerStatsResponseItem {
  url: string;
}

interface PartnerStatsWithUrls extends PartnerStatsResponse {
  partners: PartnerStatsItemWithUrl[];
}
interface PartnerStatsResponseItem {
  partnerNumber: number;
  partnerId: string;
  partnerName: string;
  added: number;
  removed: number;
  error: number;
}
interface PartnerStatsResponse {
  activeCount: number;
  totalCount: number;
  partners: PartnerStatsResponseItem[];
}

interface StatsTableProps {
  stats: PartnerStatsItemWithUrl[];
}

const headers = [
  i18n.t('dashboard:table.partnerNoAndName'),
  i18n.t('dashboard:table.added'),
  i18n.t('dashboard:table.removed'),
  i18n.t('dashboard:table.partnerError'),
];

const StatsTable = ({ stats }: StatsTableProps) => (
  <div className="t-connectorsStatsTable flex w-full flex-col">
    <div className="hid w-full grid-cols-6 border-b border-slate-200 py-2 dark:border-slate-400 md:grid">
      {headers.map((header: string, i: number) => (
        <StatsTableHead
          key={`connectorsHead-${i}`}
          className={cx(i === 0 ? 'col-span-3' : 'text-right')}
          label={header}
        />
      ))}
    </div>

    {stats?.map(stat => (
      <div
        key={stat.partnerId}
        className="t-statsTableRow mt-2 grid w-full grid-cols-6 border-b border-slate-200 py-2 dark:border-slate-400 md:border-none"
      >
        <StatsPropertyWithNumber
          className="t-nameCell col-span-6 md:col-span-3"
          number={stat.partnerNumber}
          name={stat.partnerName}
          redirectUrl={stat.url}
        />
        <StatsKeyValue label={headers[1]} value={stat?.added} valueClassName="col-span-4 t-addedCell" />
        <StatsKeyValue label={headers[2]} value={stat?.removed} valueClassName="col-span-4 t-removedCell" />
        <StatsKeyValue label={headers[3]} value={stat?.error} valueClassName="col-span-4 t-errorsCell" />
      </div>
    ))}
  </div>
);

const addUrlsToPartners = (partners: PartnerStatsResponseItem[], eventConnectors: IEventConnector[]) =>
  partners.map(partner => {
    const correspondingEventConnector = eventConnectors.find(
      eventConnector => eventConnector.destinationPartnerId === partner.partnerId,
    );

    const url = correspondingEventConnector
      ? buildUrl(`profiles/partners/list/eventStats/${correspondingEventConnector.eventConnectorId}`)
      : buildUrl(`profiles/partners/list/stats/${partner.partnerId}`);

    return {
      ...partner,
      url,
    };
  });

const ConnectorsPanel: React.FC<ConnectorsPanelProps> = ({ t }) => {
  const dashboardContextValue = useDashboard();
  const rangeFrom = dashboardContextValue?.state.rangeFrom;
  const rangeTo = dashboardContextValue?.state.rangeTo;

  const [refetch, setRefetch] = useState(false);

  const {
    data,
    isLoading,
    error,
  }: {
    data: PartnerStatsWithUrls | null;
    isLoading: boolean;
    error: any;
  } = useAPI(async (): Promise<PartnerStatsWithUrls | null> => {
    if (typeof rangeFrom === 'number' && typeof rangeTo === 'number') {
      const [partnerStats, eventConnectors]: [PartnerStatsResponse, IEventConnector[]] = await Promise.all([
        fetchPartnerStats(rangeFrom, rangeTo),
        ConnectorsService.getEventConnectors(),
      ]);

      return {
        ...partnerStats,
        partners: addUrlsToPartners(partnerStats.partners, eventConnectors),
      };
    }

    return Promise.resolve(null);
  }, [rangeFrom, rangeTo, refetch]);

  const connectorsCount = data?.activeCount || 0;
  const hasNoContent = !!(error || connectorsCount === 0);
  const stats = data?.partners.slice(0, 3) || [];
  const connectorsUrl = buildUrl('profiles/partners/list');

  return (
    <DefaultPanel
      icon="connector"
      title={connectorsCount}
      subTitle={t('panels.connector.subTitle')}
      tooltip={t('panels.connector.tooltip')}
      isLoading={isLoading}
      hasNoContent={hasNoContent}
      url={connectorsUrl}
      testHook="connectorsPanel"
    >
      {hasNoContent ? (
        <StatsPlaceholder
          icon="connector"
          error={error}
          redirectUrl={connectorsUrl}
          onClick={() => setRefetch(!refetch)}
        />
      ) : (
        <StatsTable stats={stats} />
      )}
    </DefaultPanel>
  );
};

export default translate('dashboard')(ConnectorsPanel);
