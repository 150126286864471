import { TriggerDailyStats } from '../form/Journey/types';

const data = {
  nodeId: '01dfc3db-c77c-4fbe-8476-588508c6ab5c',
  profilesEnteredJourney: 12131310,
  stats: [
    {
      origin: 'DEFAULT',
      profilesExitedByCondition: 12118,
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 21115,
          profilesEnteredVariant: 3123,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 32143,
          profilesEnteredVariant: 5234,
        },
      ],
      dayTimestamp: 1695160800000,
    },
    {
      profilesExitedByCondition: 2321,
      origin: 'HISTORIC_PROCESSING',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 5314,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695247200000,
    },
    {
      profilesExitedByCondition: 0,
      origin: 'DEFAULT',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 12110,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 4233,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695333600000,
    },
    {
      profilesExitedByCondition: 0,
      origin: 'DEFAULT',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695420000000,
    },
    {
      profilesExitedByCondition: 0,
      origin: 'HISTORIC_PROCESSING',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695506400000,
    },
    {
      profilesExitedByCondition: 0,
      origin: 'DEFAULT',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 21211,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695592800000,
    },
    {
      profilesExitedByCondition: 0,
      origin: 'DEFAULT',
      variantStats: [
        {
          experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
          name: 'Experiment variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
        {
          experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
          name: 'Control group variant',
          profilesReached: 0,
          profilesEnteredVariant: 0,
        },
      ],
      dayTimestamp: 1695679200000,
    },
  ],
} as TriggerDailyStats;

const getExitDemoGraph = (origin?: string) => ({
  ...data,
  stats: origin ? data.stats.filter(stat => stat.origin === origin) : data.stats,
});

export default getExitDemoGraph;
