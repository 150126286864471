import { NavigateFunction } from 'react-router-dom';
import { buildUrl } from '~/common';

const makeGoToViewTriggerScreen = (journeyId: string, navigate: NavigateFunction) => (triggerId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/trigger/${triggerId}`));
};

const makeGoToCreateTriggerScreen = (journeyId: string, navigate: NavigateFunction) => (parentStepId: string) => {
  let url = `workflows/edit/${journeyId}/triggerNew`;

  if (parentStepId) {
    url += `?parentStepId=${parentStepId}`;
  }

  navigate(buildUrl(url));
};

export const makeGoToEditTriggerScreen = (journeyId: string, navigate: NavigateFunction) => (triggerId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/triggerEdit/${triggerId}`));
};

const makeGoToViewStepScreen = (journeyId: string, navigate: NavigateFunction) => (stepId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/step/${stepId}`));
};

const makeGoToCreateStepScreen = (journeyId: string, navigate: NavigateFunction) => (parentTriggerId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/stepNew/addPartner?parentTriggerId=${parentTriggerId}`));
};

const makeGoToEditStepScreen =
  (journeyId: string, navigate: NavigateFunction) => (stepId: string, parentTriggerId: string) => {
    navigate(buildUrl(`workflows/edit/${journeyId}/stepEdit/${stepId}?parentTriggerId=${parentTriggerId}`));
  };

const makeGoToStepStatisticsScreen = (journeyId: string, navigate: NavigateFunction) => (stepId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/statsView/step/${stepId}`));
};

const makeGoToGoalStatisticsScreen = (journeyId: string, navigate: NavigateFunction) => (goalId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/statsView/goal/${goalId}`));
};

export const makeGoToJourneyView = (journeyId: string, navigate: NavigateFunction) => () => {
  navigate(buildUrl(`workflows/edit/${journeyId}`));
};

export const makeGoToJourneyStatsView = (journeyId: string, navigate: NavigateFunction) => () => {
  navigate(buildUrl(`workflows/edit/${journeyId}/stats`));
};

export const makeGoToViewGoalScreen = (journeyId: string, navigate: NavigateFunction) => (goalId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/goal/${goalId}`));
};
export const makeGoToEditGoalScreen = (journeyId: string, navigate: NavigateFunction) => (goalId: string) => {
  navigate(buildUrl(`workflows/edit/${journeyId}/goalEdit/${goalId}`));
};

export const makeGoToCreateGoalScreen = (journeyId: string, navigate: NavigateFunction) => () => {
  navigate(buildUrl(`workflows/edit/${journeyId}/goalNew`));
};

export const makeGoToEditExitConditionScreen =
  (journeyId: string, navigate: NavigateFunction) => (exitConditionId: string) => {
    navigate(buildUrl(`workflows/edit/${journeyId}/exitConditionEdit/${exitConditionId}`));
  };

export const makeGoToCreateExitConditionScreen = (journeyId: string, navigate: NavigateFunction) => () => {
  navigate(buildUrl(`workflows/edit/${journeyId}/exitConditionNew`));
};

export const makeGoToExitConditionStatisticsScreen =
  (journeyId: string, navigate: NavigateFunction) => (exitConditionId: string) => {
    navigate(buildUrl(`workflows/edit/${journeyId}/statsView/exitCondition/${exitConditionId}`));
  };

export const makeGoToViewExitConditionScreen =
  (journeyId: string, navigate: NavigateFunction) => (exitConditionId: string) => {
    navigate(buildUrl(`workflows/edit/${journeyId}/exitCondition/${exitConditionId}`));
  };

export const makeRedirects = (journeyId: string, navigate: NavigateFunction) => ({
  goToViewTriggerScreen: makeGoToViewTriggerScreen(journeyId, navigate),
  goToCreateTriggerScreen: makeGoToCreateTriggerScreen(journeyId, navigate),
  goToEditTriggerScreen: makeGoToEditTriggerScreen(journeyId, navigate),
  goToViewStepScreen: makeGoToViewStepScreen(journeyId, navigate),
  goToCreateStepScreen: makeGoToCreateStepScreen(journeyId, navigate),
  goToEditStepScreen: makeGoToEditStepScreen(journeyId, navigate),
  goToStepStatisticsScreen: makeGoToStepStatisticsScreen(journeyId, navigate),
  goToGoalStatisticsScreen: makeGoToGoalStatisticsScreen(journeyId, navigate),
  goToJourneyView: makeGoToJourneyView(journeyId, navigate),
  goToJourneyStatsView: makeGoToJourneyStatsView(journeyId, navigate),
  goToViewGoalScreen: makeGoToViewGoalScreen(journeyId, navigate),
  goToEditGoalScreen: makeGoToEditGoalScreen(journeyId, navigate),
  goToCreateGoalScreen: makeGoToCreateGoalScreen(journeyId, navigate),
  goToEditExitConditionScreen: makeGoToEditExitConditionScreen(journeyId, navigate),
  goToCreateExitConditionScreen: makeGoToCreateExitConditionScreen(journeyId, navigate),
  goToExitConditionStatisticsScreen: makeGoToExitConditionStatisticsScreen(journeyId, navigate),
  goToViewExitConditionScreen: makeGoToViewExitConditionScreen(journeyId, navigate),
});
