import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import Spinner from '~/components/src/Spinner';
import { changeUrl } from '~/common';
import * as fromModals from '~/modals';
import { fetchEnvironments, deleteEnvironment, fetchNonDeletableCodesIfNeeded } from '../actions';
import { getEnvironments, isFetchingEnvironments, getNonDeletableCodes } from '../selectors';
import EnvironmentsList from '../../components/EnvironmentsList';
import i18n from '../../i18n';

const redirectToEditPage = environmentId => {
  changeUrl(`admin/environments/edit/${environmentId}`);
};

class EnvironmentsContainer extends Component {
  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchNonDeletableCodesIfNeeded();
  }

  render() {
    const { isFetching, ...props } = this.props;
    if (isFetching) {
      return <Spinner />;
    }
    return (
      <EnvironmentsList
        list={props.list}
        stateKey={props.stateKey}
        onEditClick={redirectToEditPage}
        onDeleteClick={props.onDeleteClick}
        nonDeletableCodes={props.nonDeletableCodes}
        t={props.t}
        isSiteAdmin={props.isSiteAdmin}
      />
    );
  }
}

const mapStateToProps = state => ({
  stateKey: 'ADMIN/ENVIRONMENTS',
  list: getEnvironments(state),
  nonDeletableCodes: getNonDeletableCodes(state),
  isFetching: isFetchingEnvironments(state),
});

const mapDispatchToProps = dispatch => ({
  fetchEnvironments: () => dispatch(fetchEnvironments()),
  fetchNonDeletableCodesIfNeeded: () => dispatch(fetchNonDeletableCodesIfNeeded()),
  onDeleteClick: environment => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: i18n.t('environments:deleteModal.title'),
        message: i18n.t('environments:deleteModal.message', { title: environment.environmentName }),
        onConfirm: () => dispatch(deleteEnvironment(environment.environmentId)),
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('connectors'))(EnvironmentsContainer);
