import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ExperimentDashboard from './ExperimentDashboard';
import ExperimentView from './ExperimentView';
import NewExperiment from './NewExperiment';

const ExperimentsPages = ({ hasTagEdit }: { hasTagEdit: boolean }) => (
  <Routes>
    <Route path="dashboard" element={<ExperimentDashboard hasTagEdit={hasTagEdit} />} />
    <Route path="view/:experimentId" element={<ExperimentView hasTagEdit={hasTagEdit} />} />
    <Route path="add" element={<NewExperiment />} />
  </Routes>
);

export default ExperimentsPages;
