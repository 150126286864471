// Module
var code = `<div class="box"> <div class="box-title"> <h3> <i class="r42-app-admin"></i><span>{{vm.data.developer.firstName}} {{vm.data.developer.lastName}}</span> </h3> <div class="pull-right"> <div class="btn-group flex gap-2"> <div data-button-icon data-ng-click="vm.updateDeveloper(vm.data.developer.developerId)" title="Edit external fact" title="Add new group" icon="setting"> </div> <div data-button-icon data-ng-click="vm.confirmDeleteDeveloper(vm.data.developer)" title="Delete external fact" icon="delete"> </div> </div> </div> </div> <div class="box-content"> <div class="form-horizontal"> <div data-wrap data-label="First name"> <div data-editable="vm.data.developer.firstName" data-callback-options="nameOverrideOptions" data-custom-validate="{ isRequired: true}"> </div> </div> <div data-wrap data-label="Last name"> <div data-editable="vm.data.developer.lastName" data-callback-options="nameOverrideOptions" data-custom-validate="{ isRequired: true}"> </div> </div> <div data-wrap data-label="Developer number"> <div data-editable="vm.data.developer.number" data-callback-options="editableOptionsOverride" data-custom-validate="[
						{ isRequired: true},
						{isNumber: true},
						{isUnique: isUnique(\$value), msg :'Counter number is already assigned'}
					]"> </div> </div> <div data-wrap data-label="Personal description"> <div data-editable="vm.data.developer.description" data-editable-template="textarea" class="input-xlarge" data-custom-validate="{ isRequired: true}"></div> </div> <div data-wrap data-label="Accepted terms"> <div class="text-block"> <div data-editable="vm.data.developer.agree" data-editable-template="checkbox" data-vars="{yesValue: 'Yes', noValue:'No'}"></div> </div> </div> </div> </div> </div>`;
// Exports
var _module_exports =code;;
var path = 'ui/test/validation/view/view.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;