/*
 * Client Page duck
 * */
import { combineReducers } from 'redux';
import domainList from './domains';
import clientList from './records';
import siteList from './sites';
import siteStats from './showUsage';

export * from './domains';
export * from './records';
export * from './showUsage';
export * from './sites';

export default combineReducers({
  clientList,
  domainList,
  siteList,
  siteStats,
});
