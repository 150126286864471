import React, { useState } from 'react';

import R42Eye from '~/components/src/R42Icons/R42Eye';
import R42EyeClosed from '~/components/src/R42Icons/R42EyeClosed';
import InputElement from '~/components/src/Form/Elements/InputElement';

const SensitiveInputElement = ({
  value,
  disabled,
  placeholder,
  testHook,
  hasError,
  className,
  onChange,
  onFocus,
  onBlur,
  id,
  name,
  autoComplete = 'off',
}) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const toggle = () => {
    setIsRevealed(!isRevealed);
  };

  return (
    <div className="SensitiveInputElement">
      <InputElement
        type={isRevealed ? 'text' : 'password'}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        testHook={testHook}
        hasError={hasError}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        name={name}
        autoComplete={autoComplete}
      />
      <div className="SensitiveInputElement-eyeIcon" onClick={toggle}>
        {isRevealed ? <R42EyeClosed width={32} height={32} /> : <R42Eye width={32} height={32} />}
      </div>
    </div>
  );
};

export default SensitiveInputElement;
