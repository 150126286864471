import { get } from 'lodash';

export const getVariantVersionById = (state, variantId, sequenceNr = 'latest') =>
  get(state, `support.tags.variants.byId[${variantId}][${sequenceNr}]`);

export const isVariantLocked = (state, variantId, sequenceNr) => {
  const variant = getVariantVersionById(state, variantId, sequenceNr);
  return variant && variant.locked;
};

export const getVariantExecutionTypes = state => get(state, 'support.tags.variants.executionTypes', []);

export const getDefaultExecutionType = state => {
  const types = getVariantExecutionTypes(state);
  return types && types[0] && types[0].value;
};

export const getVariantHistory = (state, variantId) => get(state, `support.tags.variants.history[${variantId}]`, []);
