import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { reduxForm, isValid } from 'redux-form';
import ConnectorForm from './ConnectorForm';
import { getInitialValues, getPartner, selectFormValues } from './connectorFormSelector';
import { fetchDetails, createConnectorAction, clearConnectorForm, updateConnectorAction } from './connectorFormActions';
import { getConnectors } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  connectors: getConnectors(state),
  partner: getPartner(state),
  initialValues: getInitialValues(state, ownProps),
  formValues: selectFormValues(state),
  isValid: isValid('connectorForm')(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () =>
    ownProps.canUpdate
      ? dispatch(updateConnectorAction(ownProps.mode))
      : dispatch(createConnectorAction(ownProps.mode)),
  fetchDetails: () => dispatch(fetchDetails(ownProps?.params.connectorId, ownProps?.params.partnerType, ownProps.mode)),
  clearConnectorForm: () => dispatch(clearConnectorForm()),
  dispatch,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'connectorForm',
    enableReinitialize: true,
  }),
  translate('partners'),
)(ConnectorForm);
