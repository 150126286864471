const audienceActivityActionTypes = {
  FETCH_STATS: {
    pending: 'FETCH_STATS/pending',
    fulfilled: 'FETCH_STATS/fulfilled',
    rejected: 'FETCH_STATS/rejected',
  },
  FETCH_PARTNER_STATS: {
    pending: 'FETCH_PARTNER_STATS/pending',
    fulfilled: 'FETCH_PARTNER_STATS/fulfilled',
    rejected: 'FETCH_PARTNER_STATS/rejected',
  },

  SET_AUDIENCE_STATS: 'SET_AUDIENCE_STATS',
  SET_PARTNER_STATS: 'SET_PARTNER_STATS',
  SET_DOCUMENTATION_URL: 'SET_DOCUMENTATION_URL',
};

export default audienceActivityActionTypes;
