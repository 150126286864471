/**
 * @description Angular main module for Content -> campaigns2
 * @memberOf webUi.ui.content
 * @namespace webUi.ui.content.campaigns2
 */
angular.module('webUi.ui.content.campaigns2',
    [
        'webUi.ui.content.campaigns2.view',
        'webUi.ui.content.campaigns2.form',
        'webUi.ui.content.campaigns2.dashboard',
        'webUi.ui.content.campaigns2.ads',
        'webUi.ui.content.campaigns2.placementGroups',
      
    ]
);
