import React from 'react';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/i18n';
import { useHPContext } from '../context';
import { Step1Props } from '../types';
import { HpOptionSelector } from './HpOptionSelector';

export const Step1 = ({ hideModal }: Step1Props): React.ReactElement => {
  const hpContext = useHPContext();

  const { selectedHpOptions, setCurrentStep } = hpContext;

  return (
    <div className="flex h-auto w-[640px] flex-col">
      <>
        <HpOptionSelector />
        <div className="flex justify-end p-3">
          <ActionButtons
            onConfirm={() => setCurrentStep(2)}
            isConfirmEnabled={selectedHpOptions.length > 0}
            confirmText={i18n.t('common:actions.next')}
            onDecline={hideModal}
            testHook="hpOptions"
            paddingClassName={null}
          />
        </div>
      </>
    </div>
  );
};
