import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('templatesList.empty.header')} body={t('templatesList.empty.body')} />;

const NoResults = ({ t }) => (
  <Empty header={t('templatesList.noResults.header')} body={t('templatesList.noResults.body')} />
);

export const TagTemplatesList = ({ list, stateKey, t, templateUrl }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader sortKey="name" title={t('templatesList.name')} />
      </Row>
      {list.map(item => (
        <Row key={item.templateId}>
          <Cell>
            <Link href={templateUrl(item.templateId)}>{item.name}</Link>
          </Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

TagTemplatesList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  templateUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
)(TagTemplatesList);
