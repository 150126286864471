import React from 'react';
import { Heading, Page } from '~/components';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import CookiePermissionSettings from './components/CookiePermissionSettings';
import CookiePermissionGroup from './components/CookiePermissionGroup';
import { TListProps } from './types';

const CookiePermissionList = ({ hasTagEdit }: TListProps) => (
  <Page>
    <Heading kind="h1" title={i18n.t('cookie:header')}></Heading>
    <Tabs>
      <TabList>
        <Tab testHook="cookiePermissionsSettingsTab">{i18n.t('cookie:tab.cookiePermissions')}</Tab>
        <Tab testHook="cookiePermissionsGroupsTab">{i18n.t('cookie:tab.permissionGroups')}</Tab>
      </TabList>
      <TabPanel>
        <CookiePermissionSettings hasTagEdit={hasTagEdit} />
      </TabPanel>
      <TabPanel>
        <CookiePermissionGroup hasTagEdit={hasTagEdit} />
      </TabPanel>
    </Tabs>
  </Page>
);

export default CookiePermissionList;
