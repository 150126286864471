import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CookiePermissionList from './CookiePermissionList';

type TCookiePermission = { hasTagEdit: boolean };
const CookiePermission = ({ hasTagEdit }: TCookiePermission) => (
  <Routes>
    <Route path="list" element={<CookiePermissionList hasTagEdit={hasTagEdit} />} />
  </Routes>
);

export default CookiePermission;
