angular.module('webUi.service.profiles.thirdPartyDataCollectors', [
    'restangular'
])

    .factory('CollectorService', ['Restangular', '$q', 'ChartService',
        function (Restangular, $q, ChartService) {

            var BASE_PATH = 'profiles/collectors';

            var RELAY42_ADSERVER_PARTNER = {
                id: 0,
                name: 'Relay42 Content Platform'
            };

            var service = {

                getRelay42AdserverPartner: function () {
                    return RELAY42_ADSERVER_PARTNER;
                },

                /**
                 *
                 * @param {UUID} collectorId the collector
                 * @returns {Object} a promise of the collector
                 */
                getCollector: function (collectorId) {
                    var collectorPromise = $q.defer();
                    Restangular.one(BASE_PATH + '/' + collectorId).get().then(function (collector) {
                        if (collector) {
                            service.getCollectorCode(collectorId).then(function (code) {
                                collector.code = code;
                                collectorPromise.resolve(Restangular.stripRestangular(collector));
                            });

                        }
                    });
                    return collectorPromise.promise;
                },

                /**
                 * @returns {List} a promise of the collectors
                 */
                getCollectors: function () {
                    var collectorsPromises = $q.defer();

                    Restangular.all(BASE_PATH).getList().then(function (collectors) {
                        if (collectors) {
                            collectorsPromises.resolve(Restangular.stripRestangular(collectors));
                        }
                    });
                    return collectorsPromises.promise;
                },

                /**
                 * Create an collector object by command object
                 *
                 * @returns id of the newly created collector
                 */
                createCollector: function createCollector(createCommand) {
                    var collectorIdPromise = $q.defer();
                    Restangular.all(BASE_PATH).post(createCommand).then(function (collectorId) {
                        if (collectorId) {
                            collectorIdPromise.resolve(Restangular.stripRestangular(collectorId));
                        }
                    });
                    return collectorIdPromise.promise;
                },

                /**
                 * Update collector object based on updateCommand
                 */
                updateCollector: function updateCollector(updateCommand) {
                    return Restangular.all(BASE_PATH + '/update').post(updateCommand);
                },

                /**
                 * Delete an collector object by id
                 */
                deleteCollector: function deleteCollector(collectorId) {
                    return Restangular.one(BASE_PATH + '/' + collectorId).remove();
                },

                getCollectorCode: function getCollectorCode(collectorId) {
                    var codePromise = $q.defer();
                    Restangular.one(BASE_PATH + '/' + collectorId + '/code').get().then(function (code) {
                        if (code) {
                            codePromise.resolve(code);
                        }
                    });
                    return codePromise.promise;
                },

                getCollectorTypes: function getCollectorTypes() {
                    var collectorTypesPromise = $q.defer();

                    Restangular.all(BASE_PATH + '/collectorTypes').getList().then(function (collectorTypes) {
                        if (collectorTypes) {
                            collectorTypesPromise.resolve(Restangular.stripRestangular(collectorTypes));
                        }
                    });
                    return collectorTypesPromise.promise;
                },

                /**
                 *
                 * @param {UUID} collectorId - the id of the collector to retrieve stats for
                 * @param {Integer} days - the number of days for which stats should be retrieved
                 * @returns a promise of the stats for the last x days
                 */
                getCollectorStatsChartSeries: function getCollectorStatsChartSeries(collectorId, days) {

                    var getChartSeries = function getChartSeries(stats) {
                        return {
                            series: [
                                {
                                    name: 'Data partner calls',
                                    yAxis: 0,
                                    data: _.pluck(stats, 'collectorCalls'),
                                    pointInterval: 24 * 36e5,
                                    pointStart: (stats.length === 0) ? 0 : stats[0].key.dayTimestamp,
                                    color: ChartService.colors.GREEN,
                                    type: 'column'
                                },
                                {
                                    name: 'New profiles',
                                    yAxis: 0,
                                    data: _.pluck(stats, 'newProfiles'),
                                    opposite: true,
                                    pointInterval: 24 * 36e5,
                                    pointStart: (stats.length === 0) ? 0 : stats[0].key.dayTimestamp,
                                    color: ChartService.colors.BLUE,
                                    type: 'line'
                                },
                                {
                                    name: 'Unique profiles',
                                    yAxis: 0,
                                    opposite: true,
                                    data: _.pluck(stats, 'uniqueProfiles'),
                                    pointInterval: 24 * 36e5,
                                    pointStart: (stats.length === 0) ? 0 : stats[0].key.dayTimestamp,
                                    color: ChartService.colors.RED,
                                    type: 'line'
                                }
                            ],
                            total: _.reduce(_.pluck(stats, 'collectorCalls'), function (sum, calls) {
                                return sum + calls;
                            }),
                            newProfiles: _.reduce(_.pluck(stats, 'newProfiles'), function (sum, profiles) {
                                return sum + profiles;
                            }),
                            uniqueProfiles: _.reduce(_.pluck(stats, 'uniqueProfiles'), function (sum, profiles) {
                                return sum + profiles;
                            })
                        };
                    };

                    var deferredChartSeriesPromise = $q.defer();
                    Restangular.one(BASE_PATH  + '/' + collectorId + '/stats').get({'days': days}).then(
                        function onSuccess(result) {
                            var collectorStatsChartSeries = getChartSeries(Restangular.stripRestangular(result));
                            deferredChartSeriesPromise.resolve(collectorStatsChartSeries);
                        },
                        function onError() {
                        }
                    );
                    return deferredChartSeriesPromise.promise;
                },

                getSyncPartners: function getSyncPartners() {
                    var syncPartnersPromise = $q.defer();

                    Restangular.all(BASE_PATH + '/syncPartners').getList().then(function (syncPartners) {
                        if (syncPartners) {
                            syncPartnersPromise.resolve(Restangular.stripRestangular(syncPartners));
                        }
                    });
                    return syncPartnersPromise.promise;
                }

            };

            return service;
        }]);

