import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { tripolisActionTypes } from './tripolisActions';

const tripolisReducer = (state, action) => {
  switch (action.name) {
    case tripolisActionTypes.FETCH_CONNECTOR_DATABASES.fulfilled:
      return state.setIn(['promise', 'fetchingConnectorDatabases'], setFulfilled());
    case tripolisActionTypes.FETCH_CONNECTOR_DATABASES.pending:
      return state.setIn(['promise', 'fetchingConnectorDatabases'], setPending());
    case tripolisActionTypes.FETCH_CONNECTOR_DATABASES.rejected:
      return state.setIn(['promise', 'fetchingConnectorDatabases'], setRejected());
    case tripolisActionTypes.SET_CONNECTOR_DATABASES:
      return state.set('connectorDatabases', action.data);

    default:
      return state;
  }
};

export default tripolisReducer;
