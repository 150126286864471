import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { appNexusActionTypes } from './appNexusActions';

const appNexusReducer = (state, action) => {
  switch (action.name) {
    case appNexusActionTypes.FETCH_ADVERTISERS.fulfilled:
      return state.setIn(['promise', 'fetchingAdvertisers'], setFulfilled());
    case appNexusActionTypes.FETCH_ADVERTISERS.pending:
      return state.setIn(['promise', 'fetchingAdvertisers'], setPending());
    case appNexusActionTypes.FETCH_ADVERTISERS.rejected:
      return state.setIn(['promise', 'fetchingAdvertisers'], setRejected());
    case appNexusActionTypes.SET_ADVERTISERS:
      return state.set('advertisers', action.data);

    default:
      return state;
  }
};

export default appNexusReducer;
