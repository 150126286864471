import React from 'react';
import InputElement from '~/components/src/Form/Elements/InputElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import { TElement } from '../types';

const TextInput = ({
  value = '',
  placeholder = '',
  onChange,
  errorText,
  onBlur = null,
  autoFocus = false,
}: TElement) => (
  <FieldWrapper errorText={errorText}>
    <InputElement
      type="text"
      value={value}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur}
      autoFocus={autoFocus}
      placeholder={placeholder}
      hasError={!!errorText}
      className="RuleCardEdit-textInput"
    />
  </FieldWrapper>
);

export default TextInput;
