angular.module('webUi.service.tagmanagement.environment', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.tagmanagement'
])

    .factory('TagmanagementEnvironmentService', ['Restangular', '$q', 'Utils', function(Restangular, $q, Utils) {

        // the base path through which all calls should go
        var basePath = 'tagmanagement/environments';

        var restangularInstance = Restangular.withConfig(function(Configurer) {
            Configurer.setRequestInterceptor(function(elem, operation, path) {

                //log(operation, path);
                if (operation === 'post' && ( path === basePath + '/requiresAdmin' || path === basePath + '/sampleRate' ) ) {
                    //log(elem);
                    return elem[0];
                }
                return elem;
            });
        });


        return {

            getAll: function(isPublishing = false) {
                var queryParms = '';
                if (!isPublishing) {
                    queryParms = '?liveIncluded=true';
                }
                var all = $q.defer();
                restangularInstance.all(basePath + queryParms).getList().then(function(result){
                    all.resolve(Utils.sanitizeRestangularAll(result));
                });
                return all.promise;
            },

            changeEnvironmentsRequiresAdmin : function(environments) {
                var envs = environments;
                if ( !_.isArray(environments) ){
                    envs = [environments];
                }

                var sendObj = [];

                _.forEach(envs, function(environment){
                    sendObj.push({
                        environmentId: environment.environmentId,
                        requiresAdmin: environment.requiresAdmin
                    });
                });

                return restangularInstance.one(basePath + '/requiresAdmin').customPOST([sendObj]);
            },

            /**
		 * Changes the sampleRate of an environment or bunch of envs
		 * @param {Array<Environment>} environments
		 * @returns {UUID} a promise of the environmentIds
		 */
            changeEnvironmentSampleRate : function(environments) {
                var envs = environments;
                if ( !_.isArray(environments) ){
                    envs = [environments];
                }
                var sendObj = [];
                _.forEach(envs, function(environment){
                    sendObj.push({
                        environmentId: environment.environmentId,
                        sampleRate: environment.sampleRate
                    });
                });
                return restangularInstance.one(basePath + '/sampleRate').customPOST([sendObj]);
            },

            deploy : function (environmentId, snapshotId) {
                return restangularInstance.all(basePath + '/deploy').post({
                    configurationSnapshotId: snapshotId,
                    environmentId: environmentId
                });
            }
        };
    }]);

