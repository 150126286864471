import React, { Component } from 'react';
import queryString from 'query-string';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { buildUrl, changeUrl, history } from '~/common';
import * as fromModals from '~/modals';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { components } from '~/components/src/Table';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import i18n from '../../i18n';
import DependantsContainer from '../components/DependantsContainer';
import InteractionDetails from '../components/InteractionDetails';
import MissedPropertiesList from '../components/MissedPropertiesList';
import PropertiesList from '../components/PropertiesList';
import {
  addProperties,
  changeDependantTypesSelectedTab,
  deleteEngagement,
  fetchEngagementActivity,
  fetchEngagementIfNeeded,
  fetchEngagementProperties,
  fetchEngagementTrafficSources,
  hideDependantsContainer,
  showDependantsContainer,
} from '../engagements/actions';
import {
  getEngagementById,
  getPropertiesTableInfo,
  getSelectedId,
  getSelectedProperty,
  getSelectedTab,
  isDependantsContainerVisible,
  isFetchingEngagement,
  isFetchingEngagementActivity,
  isFetchingEngagementProperties,
  isFetchingEngagementTrafficSources,
  isPropertyBeingAdded,
} from '../engagements/selectors';
import { ALLOWED_NUMBER_OF_PROPERTIES, ENGAGEMENT_USED_IN } from '../engagements/types';

const { DetailContainer } = components;

const createDetailContainerTitle = (engagement, propertyId, t) => {
  if (!engagement || !propertyId) {
    return '';
  }
  if (engagement.engagementId === propertyId) {
    return `${t('general.engagement')} - ${engagement.type || ''}`;
  }
  return `${t('general.property')} - ${propertyId}`;
};

const getElementName = (engagement, propertyId) => {
  if (!propertyId) {
    return '';
  }
  return engagement.engagementId === propertyId ? engagement.type : propertyId;
};

const getDetails = engagement => ({
  id: engagement.engagementId,
  description: engagement.description,
  dependantTypes: engagement.dependantTypes,
  usedProperties: engagement.usedProperties,
  properties: engagement.properties,
  insights: engagement.insights,
});

export class EngagementViewPage extends Component {
  async componentDidMount() {
    await this.props.fetchEngagementIfNeeded();
    this.props.fetchEngagementActivity();
    this.props.fetchEngagementTrafficSources();
    this.props.fetchEngagementProperties();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      t,
      engagementTableStateKey,
      propertiesList,
      canEditProfiles,
      engagementId,
      canAccessData,
      engagement,
      isFetchingEngagement,
      onDependantIconClick,
      onDependantsTabChange,
      onDependantsContainerClose,
      selectedPropertyId,
      dependantsContainerIsVisible,
      selectedTab,
      selectedProperty,
      onDeleteEngagement,
      isFetchingEngagementActivity,
      isFetchingEngagementTrafficSources,
      isFetchingEngagementProperties,
      isPropertyBeingAdded,
    } = this.props;
    const shouldGoBackToEngagementsList = !queryString.parse(window.location.search).back;

    const goBackLink = shouldGoBackToEngagementsList
      ? {
          title: t('general.back'),
          pathname: buildUrl('profiles/engagements'),
        }
      : {
          title: t('common:goBack'),
          onClick: () => {
            history.back();
          },
        };

    const handleAddProperties = properties => {
      this.props.onAddProperties(engagement, properties);
    };

    return isFetchingEngagement || !engagement ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={engagement.type} crumbs={[goBackLink]}>
          <BtnIcon
            icon="delete"
            disabled={!canEditProfiles || engagement.hasDependants}
            onClick={() => onDeleteEngagement(engagement)}
            testHook="delete"
            tooltip={t('view.deleteTooltip')}
          />
          <BtnIcon
            icon="edit"
            disabled={!canEditProfiles}
            onClick={() => changeUrl(`profiles/engagements/edit/${engagementId}`)}
            tooltip={t('common.edit')}
            color="blue"
          />
        </Heading>
        <InteractionDetails
          stateKey={engagementTableStateKey}
          usedIn={ENGAGEMENT_USED_IN}
          maxNumberOfProperties={ALLOWED_NUMBER_OF_PROPERTIES}
          interaction={getDetails(engagement)}
          onDependantIconClick={onDependantIconClick}
          selectedPropertyId={selectedPropertyId}
          isFetchingActivity={isFetchingEngagementActivity}
          isFetchingTrafficSources={isFetchingEngagementTrafficSources}
          t={t}
        />

        <Tabs>
          <TabList>
            <Tab testHook="configuredPropertiesTab">{t('tabs.configuredProperties.header')}</Tab>
            <Tab testHook="missedPropertiesTab">{t('tabs.missedProperties.header')}</Tab>
          </TabList>
          <TabPanel>
            <PropertiesList
              t={t}
              list={propertiesList}
              usedIn={ENGAGEMENT_USED_IN}
              interactionId={engagementId}
              canAccessData={canAccessData}
              isFetchingProperties={isFetchingEngagementProperties}
              onDependantIconClick={onDependantIconClick}
            />
          </TabPanel>
          <TabPanel>
            <MissedPropertiesList
              t={t}
              list={engagement?.insights?.missingProperties ?? []}
              canAccessData={canAccessData}
              canEditProfiles={canEditProfiles}
              id={engagementId}
              handleAddProperties={handleAddProperties}
              isFetchingProperties={isFetchingEngagementProperties}
              isPropertyBeingAdded={isPropertyBeingAdded}
            />
          </TabPanel>
        </Tabs>

        <DetailContainer
          onClose={onDependantsContainerClose}
          isVisible={dependantsContainerIsVisible}
          title={createDetailContainerTitle(engagement, selectedPropertyId, t)}
        >
          <DependantsContainer
            element={selectedProperty}
            elementName={getElementName(engagement, selectedPropertyId)}
            onTabChange={onDependantsTabChange}
            selectedTab={selectedTab}
            showContentDependants={false}
          />
        </DetailContainer>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const engagementId = ownProps?.params.engagementId;
  const { canEditProfiles } = ownProps;
  return {
    engagementId,
    canEditProfiles,
    engagement: getEngagementById(state, engagementId),
    isFetchingEngagement: isFetchingEngagement(state),
    isFetchingEngagementActivity: isFetchingEngagementActivity(state),
    isFetchingEngagementTrafficSources: isFetchingEngagementTrafficSources(state),
    isFetchingEngagementProperties: isFetchingEngagementProperties(state),
    isPropertyBeingAdded: isPropertyBeingAdded(state),
    engagementTableStateKey: `PROFILES/ENGAGEMENTS/${engagementId}/INFO`,
    propertyStateKey: `PROFILES/ENGAGEMENTS/${engagementId}/PROPERTIES`,
    propertiesList: getPropertiesTableInfo(state, engagementId),
    dependantsContainerIsVisible: isDependantsContainerVisible(state),
    selectedPropertyId: getSelectedId(state),
    selectedTab: getSelectedTab(state),
    selectedProperty: getSelectedProperty(state, engagementId, getSelectedId(state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const engagementId = ownProps?.params.engagementId;
  return {
    fetchEngagementIfNeeded: () => dispatch(fetchEngagementIfNeeded(engagementId)),
    fetchEngagementActivity: () => dispatch(fetchEngagementActivity(engagementId)),
    fetchEngagementTrafficSources: () => dispatch(fetchEngagementTrafficSources(engagementId)),
    fetchEngagementProperties: () => dispatch(fetchEngagementProperties(engagementId)),
    onDependantIconClick: (engagementId, dependantType, propertyId) => {
      dispatch(showDependantsContainer(engagementId, dependantType, propertyId));
    },
    onDependantsTabChange: selectedTab => {
      dispatch(changeDependantTypesSelectedTab(selectedTab));
    },
    onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
    onAddProperties: (engagement, properties) => {
      dispatch(addProperties(engagement, properties));
    },
    onDeleteEngagement: engagement => {
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('engagements:list.deleteModal.title'),
          message: i18n.t('engagements:list.deleteModal.message', { name: engagement.type }),
          onConfirm: () => dispatch(deleteEngagement(engagement.engagementId, engagement.hasDependants)),
        }),
      );
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate(['engagements']),
)(EngagementViewPage);
