import Api, { API_BASE } from '~/common/ApiService';

const fetchIdentifierTypes = t =>
  Api.callGet(`${API_BASE}/v1/partners/twitter/identifierTypes`, {
    toastText: t('audiences:connectors.twitter.identifierTypesFetchingError'),
  }).then(response => response.data);

const fetchAccounts = (consumerKey, consumerSecret, accessToken, tokenSecret, t) =>
  Api.callPost(
    `${API_BASE}/v1/partners/twitter/accounts`,
    { consumerKey, consumerSecret, accessToken, tokenSecret },
    {
      toastText: t('audiences:connectors.twitter.accountsFetchingError'),
    },
  ).then(response => response.data);

export default { fetchIdentifierTypes, fetchAccounts };
