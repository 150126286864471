import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import './styles.scss';

const Dropdown = props => (
  <OutsideClickHandler onOutsideClick={() => props.toggleDropdown()}>
    <div className={`Dropdown ${props.className}`}>
      {props.actions.map(action => (
        <a
          key={action.name}
          onClick={() => {
            action.onClick();
            props.toggleDropdown();
          }}
          className={`Dropdown-link
              ${action.isDisabled ? 'Dropdown-link--disabled' : ''} Dropdown-link--${action.type ? action.type : ''}`}
        >
          {action.name}
        </a>
      ))}
    </div>
  </OutsideClickHandler>
);

Dropdown.propTypes = {
  actions: PropTypes.array.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Dropdown;
