export const GROUPS_FETCH_START = 'app/support/tagtemplates/groups/GROUPS_FETCH_START';
export const GROUPS_FETCH_FAIL = 'app/support/tagtemplates/groups/GROUPS_FETCH_FAIL';
export const GROUPS_FETCH_SUCCESS = 'app/support/tagtemplates/groups/GROUPS_FETCH_SUCCESS';

export const GROUP_FETCH_START = 'app/support/tagtemplates/groups/GROUP_FETCH_START';
export const GROUP_FETCH_FAIL = 'app/support/tagtemplates/groups/GROUP_FETCH_FAIL';
export const GROUP_FETCH_SUCCESS = 'app/support/tagtemplates/groups/GROUP_FETCH_SUCCESS';

export const DELETE_START = 'app/support/tagtemplates/groups/DELETE_START';
export const DELETE_FAIL = 'app/support/tagtemplates/groups/DELETE_FAIL';
export const DELETE_SUCCESS = 'app/support/tagtemplates/groups/DELETE_SUCCESS';

export const SAVE_START = 'app/support/tagtemplates/groups/SAVE_START';
export const SAVE_FAIL = 'app/support/tagtemplates/groups/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/support/tagtemplates/groups/SAVE_SUCCESS';
