import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import AdminConfigurationService from './dataService.js';

/**
 * Fetch configurations
 */
export const fetchConfigurations = () => dispatch => {
  dispatch({ type: types.CONFIGURATIONS_FETCH_START });

  return AdminConfigurationService.getConfigurations()
    .then(configurations => {
      dispatch({ type: types.CONFIGURATIONS_FETCH_SUCCESS, payload: configurations });
    })
    .catch(error => dispatch({ type: types.CONFIGURATIONS_FETCH_FAIL, payload: error }));
};

/**
 * Saves new configuration
 * @param configuration
 */
export const saveConfiguration = configuration => dispatch => {
  dispatch({ type: types.CONFIGURATION_SAVE_START });

  return AdminConfigurationService.saveConfiguration(configuration)
    .then(configurationId => {
      dispatch({
        type: types.CONFIGURATION_SAVE_SUCCESS,
        payload: {
          ...configuration,
          configId: configurationId,
        },
      });
      changeUrl('admin/urlbuilder/');
    })
    .catch(error => {
      if (error === types.CONFIGTEST_CONNECTION_ERROR) {
        dispatch({ type: types.CONFIGTEST_FETCH_SUCCESS, payload: { success: false } });
      } else {
        dispatch({ type: types.CONFIGURATION_SAVE_FAIL, payload: error });
      }
    });
};

/**
 * Edit configuration
 */
export const editConfiguration = configuration => dispatch => {
  dispatch({ type: types.CONFIGURATION_EDIT_START });

  return AdminConfigurationService.updateConfiguration(configuration)
    .then(() => {
      dispatch({ type: types.CONFIGURATION_EDIT_SUCCESS, payload: configuration });

      changeUrl('admin/urlbuilder/');
    })
    .catch(error => {
      if (error === types.CONFIGTEST_CONNECTION_ERROR) {
        dispatch({ type: types.CONFIGTEST_FETCH_SUCCESS, payload: { success: false } });
      } else {
        dispatch({ type: types.CONFIGURATION_EDIT_FAIL, payload: error });
      }
    });
};

/**
 * Delete configuration
 * @param configurationId
 */
export const deleteConfiguration = configurationId => dispatch => {
  dispatch({ type: types.CONFIGURATION_DELETE_START });

  return AdminConfigurationService.deleteConfiguration(configurationId)
    .then(() => {
      showSuccess({ body: i18n.t('urlBuilder:exportConfigurations.api.deletionSuccess') });
      dispatch({ type: types.CONFIGURATION_DELETE_SUCCESS, payload: configurationId });
    })
    .catch(error => dispatch({ type: types.CONFIGURATION_DELETE_FAIL, payload: error }));
};

/**
 * Test configuration
 */
export const testConnection = configuration => dispatch => {
  dispatch({ type: types.CONFIGTEST_FETCH_START });

  return AdminConfigurationService.testConnection(configuration)
    .then(isConnected => {
      dispatch({ type: types.CONFIGTEST_FETCH_SUCCESS, payload: isConnected });
    })
    .catch(error => dispatch({ type: types.CONFIGTEST_FETCH_FAIL, payload: error }));
};

/**
 * Test configuration
 */
export const cleanTestConnectionResult = () => dispatch => {
  dispatch({ type: types.CONFIGTEST_CLEANUP });
};

export const startExport = configId => dispatch => {
  dispatch({
    type: types.REPORT_START,
    payload: {
      configId,
      lastAttempt: Date.now(),
    },
  });

  return AdminConfigurationService.runReport(configId)
    .then(() =>
      dispatch({
        type: types.REPORT_SUCCESS,
        payload: {
          configId,
          lastSuccess: Date.now(),
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: types.REPORT_FAIL,
        payload: {
          configId,
          error,
        },
      }),
    );
};
