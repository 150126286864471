import React, { Component } from 'react';
import _ from 'lodash';
import { Field, FormSection } from 'redux-form';
import { isRequired, numberRange, tokenIsRequired } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { GoogleButton } from '~/components';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const renderMessage = t => (
  <ol>
    <li>{t('form.notification.oAuthMessageStep1', { partner: 'Google' })}</li>
    <li>{t('form.notification.oAuthMessageStep2')}</li>
    <li>{t('form.notification.oAuthMessageStep3')}</li>
    <li>{t('form.notification.oAuthMessageStep4')}</li>
    <li>{t('form.notification.oAuthMessageStep5')}</li>
  </ol>
);

class DoubleClickForm extends Component {
  constructor(props) {
    super(props);
    this.partnerRangeValidation = numberRange(1, 9999);
  }

  componentDidMount() {
    const { fetchUserIdTypes, fetchPartnerInformation } = this.props;
    fetchUserIdTypes(constants.DOUBLE_CLICK_OFFLINE_API_PARAM);
    fetchPartnerInformation();
  }

  componentDidUpdate() {
    const { change, values } = this.props;

    const params = new URL(window.location.href).searchParams;
    const error = params.get('error');
    const refreshToken = params.get(constants.refreshToken);
    const partnerValues = JSON.parse(localStorage.getItem('partnerValues'));

    if (refreshToken && !_.isEmpty(values) && !values.refreshToken) {
      change('DOUBLE_CLICK_OFFLINE.refreshToken', refreshToken);
      change('DOUBLE_CLICK_OFFLINE.userProfileId', partnerValues.userProfileId || '');
      change('DOUBLE_CLICK_OFFLINE.advertiserId', partnerValues.advertiserId || '');
      change('DOUBLE_CLICK_OFFLINE.name', partnerValues.name || '');
    }
    // Filling fields once from localStorage in case access denied. This is only in 2 places, thats why refactoring for now is not a good idea
    if (error === 'access_denied' && !_.isEmpty(values) && !values.userProfileId) {
      change('DOUBLE_CLICK_OFFLINE.userProfileId', partnerValues.userProfileId || '');
      change('DOUBLE_CLICK_OFFLINE.advertiserId', partnerValues.advertiserId || '');
      change('DOUBLE_CLICK_OFFLINE.name', partnerValues.name || '');
    }
  }

  render() {
    const { t, values, change, partner, userIdTypes, redirectToAuthorizationUrl, canUpdate, touch } = this.props;

    const getPartnerNumber = value => {
      const userIdType = userIdTypes.find(userIdType => userIdType.name === value);
      change('DOUBLE_CLICK_OFFLINE.overRidePartnerNumber', userIdType.allowsPartnerNumberOverride);
      return userIdType.partnerNumber || constants.DOUBLE_CLICK_PARTNER_NUMBERS[value];
    };

    return (
      <FormSection name={constants.partnerTypes.DOUBLE_CLICK_OFFLINE}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.cookieSyncMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection
          title={t('form.formFields.refreshToken')}
          description={t('form.notification.oAuthMessage', { partner: 'Google Ads' })}
          extra={renderMessage(t)}
        >
          <Field
            label={t('form.formFields.userProfileId')}
            name="userProfileId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.advertiserId')}
            name="advertiserId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <div className="u-marginVerticalL u-flex-justify-end">
            <GoogleButton
              disabled={!values.advertiserId}
              onClick={() => redirectToAuthorizationUrl()}
              label={t('form.formFields.grantPermissionWithGoogle')}
            />
          </div>
          <FieldWithEditMode
            label={t('form.formFields.refreshToken')}
            name="refreshToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={canUpdate}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={!canUpdate && tokenIsRequired}
            type="text"
            disabled={true}
          />
          <Field
            label={t('form.formFields.userIdType')}
            name="userIdType"
            validate={isRequired}
            component={ReduxFormSelectField}
            options={userIdTypes}
            disabled={canUpdate}
            onChange={(e, val) => change('DOUBLE_CLICK_OFFLINE.partnerNumber', getPartnerNumber(val))}
            touch={touch}
          />
          {values.overRidePartnerNumber && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              validate={[isRequired, this.partnerRangeValidation]}
              type="text"
              disabled={canUpdate}
            />
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default DoubleClickForm;
