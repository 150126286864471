import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import UserSettingsEditForm from '~/user/components/UserSettingsEditForm';
import * as settingsSelectors from '~/user/settings/selectors';
import { buildUrl } from '~/common';
import { fetchUserSettingsIfNeeded, updateUserSettings } from '~/user/settings';
import { Panel, MediumWrapper } from '~/components/src/Containers';

const USER_SETTINGS_EDIT_FORM = 'USER/SETTINGS/USER_SETTINGS_EDIT';

export class UserSettingsEdit extends Component {
  componentDidMount() {
    this.props.fetchUserSettingsIfNeeded();
  }

  render() {
    const { props } = this;
    const { isLoadingData, ...other } = props;
    return isLoadingData ? (
      <Spinner />
    ) : (
      <Page>
        <MediumWrapper>
          <Heading title={props.t('edit.header')} />
          <Panel>
            <UserSettingsEditForm {...other} />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      defaultSites: settingsSelectors.getDefaultSites(state),
      isLoadingData:
        settingsSelectors.isFetchingUserSettings(state) || settingsSelectors.shouldFetchUserSettings(state),
      backHref: buildUrl('user/settings/view'),
      initialValues: settingsSelectors.getUserInfo(state),
    }),
    dispatch => ({
      fetchUserSettingsIfNeeded: () => dispatch(fetchUserSettingsIfNeeded()),
      onSubmit: values => dispatch(updateUserSettings(values)),
    }),
  ),
  reduxForm({
    form: USER_SETTINGS_EDIT_FORM,
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['settings']),
)(UserSettingsEdit);
