import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '~/common';
import { getAllRuleVariables, getRulesDefinitions } from '~/common/modules/rules/dataService';
import { Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Heading from '~/components/src/Heading';
import RulesList, { RuleCardTypes } from '~/components/src/RuleList';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { fetchTrigger } from '~/workflows/dataService';
import { JourneyContext } from '../JourneyContext';
import { prepareDataForViewTrigger } from './utils';

const ViewTrigger = ({ canEdit, type = NodeTypes.TRIGGER }: { canEdit: boolean; type?: string }) => {
  const params = useParams();

  const triggerId = params.goalId || params.exitConditionId || params.triggerId || '';
  const { data, isLoading, error } = useAPI(async () => {
    const [trigger, ruleDefinitions, variables] = await Promise.all([
      fetchTrigger(triggerId),
      getRulesDefinitions(type),
      getAllRuleVariables(),
    ]);

    return prepareDataForViewTrigger(trigger, ruleDefinitions, variables);
  });

  const { actions } = useContext(JourneyContext);
  const { goToEditGoalScreen, goToEditExitConditionScreen, goToEditTriggerScreen, goToJourneyView } = actions;

  if (isLoading) return <Spin />;
  if (!data || error) return <Spin />;

  const { title, criteria } = data;
  const isGoal = type === NodeTypes.GOAL;
  const isExitCondition = type === NodeTypes.EXIT_BY_CONDITION;
  const isJourneyCondition = isGoal || isExitCondition;

  const editTrigger = () => {
    if (isGoal) return goToEditGoalScreen(triggerId);
    if (isExitCondition) return goToEditExitConditionScreen(triggerId);
    return goToEditTriggerScreen(triggerId);
  };

  return (
    <Page>
      <Heading
        title={title}
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => goToJourneyView(),
          },
        ]}
      >
        {canEdit && (
          <BtnIcon
            testHook="viewTriggerActions"
            color="blue"
            onClick={editTrigger}
            tooltip={i18n.t('common:actions.edit')}
            icon="edit"
          />
        )}
      </Heading>
      <div>
        <RulesList
          criteria={criteria}
          ruleCardType={RuleCardTypes.ViewCard}
          className="ViewTrigger-rulesList"
          isJourneyCondition={isJourneyCondition}
        />
      </div>
    </Page>
  );
};

export default ViewTrigger;
