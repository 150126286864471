import React from 'react';
import { Notification } from '~/components';
import { useAPI } from '~/common';
import i18n from '~/i18n';
import DeletePopup from '~/components/src/DeletePopup';
import { TDCAdvertiser, TYahooAdvertiser } from '../types';
import { canDeleteDCAdvertiser } from '../dataService';

type DeleteAdvertiserModalProps = {
  id?: string;
  setSelectedDeletedItem:
    | React.Dispatch<React.SetStateAction<TDCAdvertiser | null>>
    | React.Dispatch<React.SetStateAction<TYahooAdvertiser | null>>;
  onDelete: () => void;
  isDoubleClick?: boolean;
};
const DeleteAdvertiserModal = ({
  setSelectedDeletedItem,
  onDelete,
  id = '',
  isDoubleClick,
}: DeleteAdvertiserModalProps): JSX.Element => {
  const { data, isLoading } = useAPI(() => (isDoubleClick ? canDeleteDCAdvertiser(id) : Promise.resolve()));
  const { canDelete = true } = data || {};

  return (
    <>
      {!isLoading && (
        <DeletePopup
          title={i18n.t('connectors:advertisers.deleteModal.header')}
          hidePopup={() => setSelectedDeletedItem(null)}
          onClick={canDelete ? onDelete : undefined}
        >
          <Notification>
            {i18n.t(`connectors:advertisers.deleteModal.${canDelete ? 'message' : 'canNotDelete'}`)}
          </Notification>
        </DeletePopup>
      )}
    </>
  );
};

export default DeleteAdvertiserModal;
