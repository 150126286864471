import { combineReducers } from 'redux';
import * as types from './types';

const list = (state = [], action) => {
  switch (action.type) {
    case types.PERMISSIONS_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

/**
 * UI State
 */
const defaultUI = {
  isFetchingPermissions: false,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.PERMISSIONS_FETCH_START:
      return {
        ...state,
        isFetchingPermissions: true,
      };
    case types.PERMISSIONS_FETCH_SUCCESS:
    case types.PERMISSIONS_FETCH_FAIL:
      return {
        ...state,
        isFetchingPermissions: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  ui,
});
