import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { mapCustomerFileSourceOptions } from './utils';

const BASE_URL = `${API_BASE}/v1/partners/facebook`;
const getFacebookURL = parnerId => `${BASE_URL}/${parnerId}/audiences`;

const getFacebookList = parnerId =>
  Api.callGet(getFacebookURL(parnerId), { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

const addAudienceInFacebook = (parnerId, payload) =>
  Api.callPost(getFacebookURL(parnerId), payload, { shouldHandleCommonErrors: false }).then(parseResponse);

const getCustomerFileSource = () =>
  Api.callGet(`${BASE_URL}/customerFileSources`).then(parseResponse).then(mapCustomerFileSourceOptions);

export { getFacebookList, addAudienceInFacebook, getCustomerFileSource };
