import { uniq } from 'lodash';
import * as Yup from 'yup';
import i18n from '~/i18n';

const min = 0;
const max = 100;
export const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .of(
      Yup.string()
        .email(i18n.t('validation:validation.invalidEmail'))
        .required(i18n.t('validation:validation.required')),
    )
    .required(i18n.t('validation:validation.required')),
  errorThreshold: Yup.number()
    .min(min, i18n.t('validation:validation.invalidNumberRange', { min, max }))
    .max(max, i18n.t('validation:validation.invalidNumberRange', { min, max }))
    .required(i18n.t('validation:validation.required')),
});

export const removeTrailingCommas = (inputString = '') => {
  const regex = /,+$/;
  return inputString.replace(regex, '');
};

export const hasDuplicates = (array: string[]) => {
  const uniqueValues = uniq(array);
  return uniqueValues.length !== array.length;
};

export const joinWithComma = (str: string[]) => str.join(',');
