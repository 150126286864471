import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MdNavigateNext } from 'react-icons/md';
import Icon from '../Icon';
import './crumb-styles.scss';
import * as constants from './constants';

const Crumb = ({ title, pathname, onClick }) => {
  const linkProps = {};
  if (pathname) {
    linkProps.href = pathname;
  } else if (onClick) {
    linkProps.onClick = onClick;
  }

  const isLink = Object.keys(linkProps).length;

  return (
    <li className="Crumb">
      {isLink ? (
        <a className={cx('Crumb-link', { 'is-active': isLink })} {...linkProps}>
          {title}
        </a>
      ) : (
        <span className="Crumb-link">{title}</span>
      )}
      <Icon
        icon={MdNavigateNext}
        size={constants.CRUMB_POINTER_SIZE}
        className="Crumb-pointer u-marginRightM"
        color={constants.CRUMB_POINTER_COLOR}
      />
    </li>
  );
};

Crumb.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.any,
};

export default Crumb;
