import constants from '../../connectors/form/constants';

export default {
  seven: 7,
  thirty: 30,
  ninety: 90,
  chartColorGreen: '#199C49',
  chartColorOrange: '#f5a623',
  chartColorGrey: '#8e9396',
  chartColorBlue: '#368ee0',
  chartColorDarkBlue: '#0054b0',
  chartColorPurple: '#A200FF',
  chartColorRed: '#FF3232',
  optimizely: constants.partnerTypes.OPTIMIZELY,
};
