import React, { ReactElement, useEffect } from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  ColumnSort,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Panel } from '~/components/src/Containers';
import Empty from '../Empty';
import Pagination from './components/Pagination';
import './UITable.scss';

type UITableProps = {
  data: any[];
  columns: ColumnDef<any, any>[];
  stickyHeader?: boolean;
  className?: string;
  emptyClassName?: string;
  testHook?: string;
  enableSorting?: boolean;
  enableBackendSorting?: boolean;
  enableFilters?: boolean;
  enablePagination?: boolean;
  searchQuery?: string;
  emptyMessage?: string;
  emptyBody?: string;
  page?: number;
  totalPages?: number;
  sort?: ColumnSort[];
  canExpand?: boolean;
  onPaginate?: (page: number) => void;
  setSort?: (sort: SortingState) => void;
  renderSubComponent?: ({ row }: { row: Row<any> }) => any;
};

function UITable({
  data,
  columns,
  stickyHeader = true,
  className,
  emptyClassName,
  testHook,
  enableSorting = false,
  enableBackendSorting = false,
  enableFilters = false,
  enablePagination = false,
  searchQuery = '',
  emptyMessage,
  emptyBody,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPaginate = () => {},
  page = 0,
  totalPages = 0,
  sort = [],
  canExpand = false,
  setSort,
  renderSubComponent,
}: UITableProps): ReactElement {
  const [sorting, setSorting] = React.useState<SortingState>(sort);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank,
    });
    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns: React.useMemo(() => columns, [columns]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: searchQuery,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    enableSorting: enableSorting || enableBackendSorting,
    enableFilters,
    manualSorting: enableBackendSorting,
    autoResetExpanded: true,
    getRowCanExpand: () => canExpand,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    setSort?.(sorting);
  }, [sorting]);

  // Reset expanded rows on change of data
  useEffect(() => {
    if (canExpand) table.resetExpanded();
  }, [data]);

  // This component needs emptyMessage to display emptyState
  if ((emptyMessage || emptyBody) && table.getRowModel().rows.length === 0) {
    return <Empty className={twMerge(cx('mt-5', emptyClassName))} header={emptyMessage} body={emptyBody} />;
  }

  // enable pagination and make list to show page numbers
  const pages = enablePagination ? new Array(totalPages) : [];

  const TableRow = ({ row }: any) => (
    <>
      <tr className={`t-${testHook}-row border-b border-gray-100 hover:bg-gray-50`}>
        {row.getVisibleCells().map((cell: any) => (
          <td
            key={cell.id}
            className={`${!cell?.column?.columnDef?.meta?.removePadding ? 'px-4 py-2' : ''} ${
              cell?.column?.columnDef?.meta?.color
            }`}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
      {renderSubComponent && row.getIsExpanded() && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
        </tr>
      )}
    </>
  );

  return (
    <>
      <Panel className="flex-1 p-0">
        <table className={`t-${testHook} w-full ${className}`}>
          <thead className={cx('rounded-t bg-gray-50', { 'sticky top-0 z-[1] border-x': stickyHeader })}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                    className={cx(
                      `t-${testHook}-header`,
                      'w-full border-x border-b border-gray-200 p-4 font-medium text-gray-800',
                      {
                        'text-center': header.colSpan > 1,
                      },
                      'first:rounded-tl-md first:border-l-0 last:rounded-tr-md last:border-r-0',
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={cx({
                          'text-center-outer flex cursor-pointer select-none': header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() ? (
                          <span className="ml-1">
                            {{
                              asc: '↑',
                              desc: '↓',
                            }[header.column.getIsSorted() as string] ?? null}
                          </span>
                        ) : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} row={row} />
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map(footerGroup => {
              const hasFooter = footerGroup.headers.some(header => header.column.columnDef.footer);
              if (!hasFooter) return null;

              return (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map(header => (
                    <td key={header.id} colSpan={header.colSpan} className="px-4 py-2">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tfoot>
        </table>
      </Panel>
      {enablePagination && totalPages > 0 && onPaginate && (
        <Pagination totalPages={totalPages} currentPage={page || 0} onPaginate={onPaginate} />
      )}
    </>
  );
}

export default UITable;
