import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import identitiesService from '~/admin/identities/dataService';
import { openDeleteConfigurationModal } from '~/admin/identities/actions';

import Spinner from '~/components/src/Spinner';
import { components as tableComponents } from '~/components/src/Table';
import { Panel } from '~/components/src/Containers';
import { Notification } from '~/components';

import IdentityConfigurationRows from './IdentityConfigurationRows';
import AddIdentityConfigurationRow from './AddIdentityConfigurationRow';

import './styles.scss';

const { Table, Row, HeaderCell } = tableComponents;

const IdentityConfigurationTable = ({ openDeleteConfigurationModal, t }) => {
  const [identityConfigurations, setIdentityConfigurations] = useState(null);

  useEffect(() => {
    identitiesService
      .fetchIdentityConfigurations()
      .then(identityMappingsApiResponse => {
        setIdentityConfigurations(identityMappingsApiResponse);
      })
      .catch(errorResult => {
        if (errorResult?.status?.error?.code === 'ERR_TWO_FACTOR_AUTHENTICATION') {
          // Ignore "2FA needed to continue" errors to avoid a brief crashing before doing a redirect to /iplock
          return;
        }

        throw errorResult;
      });
  }, []);

  const addIdentityConfiguration = newMapping => {
    setIdentityConfigurations([...identityConfigurations, newMapping]);
  };

  const deleteIdentityConfiguration = partnerNumber => {
    const updatedMappings = identityConfigurations.filter(
      configuration => configuration.partnerNumber !== partnerNumber,
    );
    setIdentityConfigurations(updatedMappings);
  };

  const isFetchingConfigurations = identityConfigurations === null;
  const isEmptyResponse = identityConfigurations && identityConfigurations.length === 0;

  if (isFetchingConfigurations) {
    return <Spinner />;
  }

  return (
    <Panel>
      {isEmptyResponse && <Notification kind="warning">{t('profileIdentities:emptyResponse')}</Notification>}
      <Table stateKey="IDENTITIES_CONFIGURATION_TABLE" testHook="identitiesConfigurationTable">
        <Row>
          <HeaderCell className="u-size1of4">{t('profileIdentities:partnerNumber')}</HeaderCell>
        </Row>
        <IdentityConfigurationRows
          identityConfigurations={identityConfigurations}
          openDeleteConfigurationModal={openDeleteConfigurationModal}
          deleteIdentityConfiguration={deleteIdentityConfiguration}
          t={t}
        />
        <AddIdentityConfigurationRow
          identityConfigurations={identityConfigurations}
          addIdentityConfiguration={addIdentityConfiguration}
          t={t}
        />
      </Table>
    </Panel>
  );
};

const mapDispatchToProps = {
  openDeleteConfigurationModal,
};

export default compose(connect(null, mapDispatchToProps))(IdentityConfigurationTable);
