angular.module('webUi.directive.linkIcon',
    [
        'app-templates'
    ])
    .directive('linkIcon', function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'directive/linkIcon/linkIcon.tpl.html',
            scope: { buttonColor: '@?', icon: '@?', buttonClass: '@?', buttonLink: '@?', buttonSize: '@?' },
            compile: function () {
                return {
                    pre: function (scope, el, attrs) {
                        scope.buttonLink = attrs.buttonLink || DEFAULTS.buttonLink;
                        scope.buttonSize = attrs.buttonSize || DEFAULTS.buttonSize;
                        scope.buttonColor = attrs.buttonColor || DEFAULTS.buttonColor;
                        scope.buttonClass = DEFAULTS.buttonClass +
                            COLORS[scope.buttonColor] +
                            SIZE[scope.buttonSize] +
                            (attrs.disabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[scope.buttonColor]}`);
                    },
                };
            }
        };
    });

var DEFAULTS = {
    buttonLink: '#',
    buttonColor: 'blue',
    buttonClass: 'flex h-8 w-8 items-center justify-center rounded p-1',
    buttonSize: 'bs',
};

var COLORS = {
    gray: 'text-gray-600 dark:text-gray-400  bg-gray-200 dark:bg-gray-700',
    blue: 'text-white bg-blue-600 dark:bg-blue-700',
    blueTransparent: 'text-r42-blue bg-transparent dark:bg-transparent',
    red: 'text-white bg-red-600 dark:bg-red-700',
};

var COLOR_STATES = {
    gray: `${COLORS.gray} hover:text-white hover:bg-gray-300 dark:hover:bg-gray-600 focus:ring-gray-300 dark:focus:ring-gray-500`,
    blue: `${COLORS.blue} hover:text-white hover:bg-blue-700 dark:hover:bg-blue-600 focus:ring-blue-300 dark:focus:ring-blue-500`,
    red: `${COLORS.red} hover:text-white hover:bg-red-700 dark:hover:bg-red-600 focus:ring-red-300 dark:focus:ring-red-500`,
    blueTransparent: `${COLORS.blueTransparent} hover:text-white hover:bg-transparent focus:ring-0 dark:focus:ring-0`,
};

var SIZE = {
    xs: 'px-3 py-2 text-xs',
    sm: 'px-3 py-2 text-sm',
    bs: 'px-5 py-2.5 text-sm',
    lg: 'px-5 py-3 text-base',
    xl: 'px-6 py-3.5 text-base',
};