import { connect } from 'react-redux';
import { getEndpointLocations, getPayloadTypes } from './tradeDeskSelector';
import { fetchEndpointLocations, fetchPayloadTypes } from './tradeDeskActions';
import TradeDeskForm from './TradeDeskForm';

const mapStateToProps = state => ({
  endpointLocations: getEndpointLocations(state),
  payloadTypes: getPayloadTypes(state),
});

const mapDispatchToProps = {
  fetchEndpointLocations,
  fetchPayloadTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeDeskForm);
