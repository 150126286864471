/* eslint-disable class-methods-use-this */
import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const API_USERS = `${API_BASE}/context/users`;
export const USERS_DEFAULT_PAGINATION_SIZE = 20;
/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ContextUsersClass {
  getAllUsers(page = 0, search = '', sort = '', size = USERS_DEFAULT_PAGINATION_SIZE) {
    return Api.callGet(`${API_USERS}/list?page=${page}&size=${size}&search=${search}&sort=${sort}`).then(parseResponse);
  }

  getAllUsersForExport() {
    return Api.callGet(`${API_USERS}`).then(response => response.data);
  }

  getNotMatchedUsersForClient(clientId) {
    return Api.callGet(`${API_USERS}/${clientId}/nomatch`).then(response => response.data);
  }

  updateNotMatchedUsersForClient(clientId) {
    return Api.callPost(`${API_USERS}/${clientId}/bindClient`, { clientId });
  }
  addUser(user) {
    return Api.callPost(API_USERS, user).then(response => response.data);
  }

  editUser(user) {
    return Api.callPut(`${API_USERS}`, user).then(response => response.data);
  }

  getUser(userId) {
    return Api.callGet(`${API_USERS}/${userId}`).then(response => response.data);
  }

  requestPasswordReset(model) {
    return Api.callPost(`${API_USERS}/requestPasswordReset`, model).then(response => response.data);
  }
}

const contextUsersService = new ContextUsersClass();
export default contextUsersService;
