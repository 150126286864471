angular.module('webUi.ui.globalui.home', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.home', {
                url: '/',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/globalui/home/home.tpl.html',
                        controller: 'WebUIHomeController'
                    }
                },
                resolve: {
                    // TODO: Uncomment to enable dashboard, and remove the other redirect
                    redirect: ['$state', '$stateParams', '$q', '$timeout', function ($state, $stateParams, $q, $timeout) {
                        $timeout(function () {
                            $state.go('site.dashboard', {
                                siteNumber: $stateParams.siteNumber
                            });
                        });
                        return $q.defer().promise;
                    }]
                }
            });
    }])

    .controller('WebUIHomeController', ['$rootScope',
        function WebUIHomeController($rootScope) {
            $rootScope.errorPage = true;
        }
    ]);
