import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '~/common';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { Notification } from '~/components';

type ExperimentAnalysisProps = { variantAnalysis: any[]; isGoal: boolean; isGoalOrExit: string };

const columnHelper = createColumnHelper<any>();

const ExperimentAnalysis = ({ variantAnalysis, isGoal, isGoalOrExit }: ExperimentAnalysisProps) => {
  const getColumns = [
    columnHelper.accessor('experimentVariantName', {
      header: () => <div className="text-left">{i18n.t('workflow:orchestration.triggerStats.variant')}</div>,
      cell: info => <div className="t-expVariantName text-left">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('weight', {
      header: () => <div className="text-right">{i18n.t('workflow:orchestration.triggerStats.weight')}</div>,
      cell: info => <div className="text-right">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('profilesEntered', {
      header: () => <div className="text-right">{i18n.t('workflow:orchestration.triggerStats.entered')}</div>,
      cell: info => <div className="text-right">{formatNumber(info.getValue())}</div>,
      size: 400,
    }),
    columnHelper.accessor('profilesReached', {
      header: () => (
        <div className="text-right">
          {i18n.t(`workflow:orchestration.triggerStats.${isGoal ? 'reachedGoal' : 'exited'}`)}
        </div>
      ),
      cell: info => <div className="text-right">{formatNumber(info.getValue())}</div>,
      size: 400,
    }),
    columnHelper.accessor('conversionRate', {
      header: () => <div className="text-right">{i18n.t('workflow:orchestration.triggerStats.conversionRate')}</div>,
      cell: info => <div className="t-convRate text-right">{info.getValue()}</div>,
      size: 400,
    }),
  ];

  return (
    <>
      {variantAnalysis?.length > 0 && (
        <div className="mb-2 py-2">
          <h2 className="!my-4 text-xl text-gray-600">
            {i18n.t('workflow:orchestration.triggerStats.experimentStatisticsSectionName')}
          </h2>
          <Notification kind="information" className="TriggertStats-notification">
            {i18n.t(`workflow:orchestration.triggerStats.${isGoalOrExit}ConversionRateFormula`)}
          </Notification>
          <UITable enableSorting data={variantAnalysis ?? []} testHook="variantAnalysis" columns={getColumns} />
        </div>
      )}
    </>
  );
};

export default ExperimentAnalysis;
