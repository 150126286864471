import React from 'react';
import { FormSection, Field } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import constants from '~/profiles/connectors/form/constants';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FormHeader from '../formHeader/FormHeader';

const AdobeForm = props => {
  const { t, canUpdate, values, partner } = props;

  return (
    <FormSection name={constants.partnerTypes.ADOBE}>
      <FormHeader
        t={t}
        title={values.name}
        partner={partner}
        description={t('form.notification.partnerNumber')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <FieldWithEditMode
          label={t('form.formFields.accessKey')}
          name="accessKey"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />
        <FieldWithEditMode
          label={t('form.formFields.secretKey')}
          name="secretKey"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />
        <FieldWithEditMode
          label={t('form.formFields.apiSecret')}
          name="apiSecret"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.awsBucket')}
          name="bucket"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.awsRegion')}
          name="region"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.accountToken')}
          name="accountToken"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.userId')}
          name="userId"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.retentionWindowInDays')}
          name="retentionWindowInDays"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="number"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default AdobeForm;
