import React from 'react';
import { FormSection } from 'redux-form';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const Relay42ApiForm = props => {
  const { t, values, partner } = props;
  return (
    <FormSection name={constants.partnerTypes.RELAY42_API}>
      <FormHeader t={t} partner={partner} title={values.name} partnerNumber={partner.partnerNumber} />
    </FormSection>
  );
};

export default Relay42ApiForm;
