import { CHART_COLORS, getTooltipFormatter } from '~/common/chart';
import i18n from '~/i18n';

const getOptions = () => ({
  title: {
    text: '',
  },
  chart: {
    height: 300,
    alignTicks: true,
  },
  xAxis: [
    {
      type: 'datetime',
      startOnTick: false,
      endOnTick: false,
    },
  ],
  yAxis: [
    {
      opposite: false,
      title: {
        text: i18n.t('collectors:chart.yLabel'),
        style: {
          color: CHART_COLORS.GREEN,
        },
      },
      min: null,
      minTickInterval: 1,
      labels: {
        style: {
          color: CHART_COLORS.GREEN,
        },
      },
    },
  ],
  tooltip: {
    formatter() {
      return getTooltipFormatter.apply(this);
    },
    shared: true,
    useHTML: true,
  },
});

const getSeries = stats => [
  {
    id: 'dataPartnerCalls',
    name: i18n.t('collectors:chart.series.dataPartnerCalls'),
    yAxis: 0,
    data: stats.map(item => item.collectorCalls),
    pointInterval: 24 * 36e5,
    pointStart: stats.length === 0 ? 0 : stats[0].key.dayTimestamp,
    color: CHART_COLORS.GREEN,
    type: 'column',
  },
  {
    id: 'newProfiles',
    name: i18n.t('collectors:chart.series.newProfiles'),
    yAxis: 0,
    opposite: true,
    data: stats.map(item => item.newProfiles),
    pointInterval: 24 * 36e5,
    pointStart: stats.length === 0 ? 0 : stats[0].key.dayTimestamp,
    color: CHART_COLORS.BLUE,
    type: 'line',
  },
  {
    id: 'uniqueProfiles',
    name: i18n.t('collectors:chart.series.uniqueProfiles'),
    yAxis: 0,
    opposite: true,
    data: stats.map(item => item.uniqueProfiles),
    pointInterval: 24 * 36e5,
    pointStart: stats.length === 0 ? 0 : stats[0].key.dayTimestamp,
    color: CHART_COLORS.RED,
    type: 'line',
  },
];

export const getChartConfig = stats => ({
  ...getOptions(),
  series: getSeries(stats),
});
