angular.module('webUi.directive.validation.validateForm', [])

    .directive('validateForm', [ '$timeout', 'Utils', 'PubSubService', function($timeout, Utils, PubSubService) {
        return {
            restrict: 'A',
            link : function postLink(scope, element) {

                var formElement = scope.formElement = $(element[0]);
                // use element if form, else look for closest parent
                if(!formElement.is('form')) {
                    formElement = formElement.parents('form');
                }
                var formName = formElement.attr('name');
                scope.form = Utils.getScopeValue(scope, formName, true, true);

                //publish the form submit event for this form name
                formElement.on('submit', function() {
                    PubSubService.publishFormValidate(formName);
                });

            }
        };
    }]);
