angular.module('webUi.ui.support.tagtemplates.base', ['webUi.ui.support.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates', {
                url: '/tagtemplates',
                views: {
                    'leftMenu@': {
                        controller: 'TagTemplatesBaseTreeController',
                        templateUrl: 'ui/support/tagtemplates/base/base.tpl.html'
                    }
                },
                resolve: {
                    treeData: ['TagTemplateService', function (TagTemplateService) {
                        return TagTemplateService.getGroupTreeData();
                    }]
                },
                leftMenu: true
            });
    }])

    .controller('TagTemplatesBaseTreeController', ['$scope', '$location', 'TagTemplateService', 'PubSubService', 'TreeService', 'treeData',
        function TagTemplatesBaseTreeController($scope, $location, TagTemplateService, PubSubService, TreeService, treeData) {

            // make groupTreeData available in scope
            $scope.treeData = treeData;

            $scope.onDroppedVariant = function onDroppedVariant(variantId, templateId) {
                TagTemplateService.copyVariantToTemplate(variantId, templateId);
            };

            // on any of the events in topic SUPPORT, reload tree
            PubSubService.subscribeSupport($scope, function () {
                TagTemplateService.getGroupTreeData().then(function (treeData) {
                    $scope.treeData = treeData;
                });
            });

            PubSubService.subscribeNodeSelected($scope, function (event, data) {
                TreeService.activateNodeById($scope.treeData, data.id);
            });

            $scope.$on('hotKeyEvent', function (event, data) {
                // activate new group on n
                if (!data.altKey && data.charLower === 'n') {
                    $location.path($scope.prefix + '/support/tagtemplates/groups/add');
                }
            });
        }]);
