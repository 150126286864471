import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '~/common';
import { Heading, Page } from '~/components';
import Btn from '~/components/src/Btn';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import YahooList from './yahoo/YahooList';
import DoubleClickList from './doubleClick/DoubleClickList';
import { useQuery } from './utils';

type TConnectorsProps = {
  isSupportUser: boolean;
};

const Advertisers = ({ isSupportUser }: TConnectorsProps) => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const query = useQuery();

  const tabIndex = query?.get('tabIndex') || 0;

  useEffect(() => {
    setIndex(+tabIndex);
  }, [tabIndex]);

  return (
    <Page>
      <Tabs
        selectedIndex={index}
        onSelect={index => {
          setIndex(index);
        }}
      >
        <TabList>
          <Tab testHook="DCAdvertiser-tab">{i18n.t('connectors:advertisers.tabs.doubleClick')}</Tab>
          <Tab testHook="yahooAdvertiser-tab">{i18n.t('connectors:advertisers.tabs.yahoo')}</Tab>
        </TabList>
        <TabPanel>
          <Heading>
            {isSupportUser && (
              <Btn
                color="blue"
                icon="add"
                testHook="addAdvertiser"
                onClick={() => {
                  navigate(buildUrl('admin/connectors/doubleClick/add'));
                }}
              >
                {i18n.t('connectors:advertisers.doubleClick.add')}
              </Btn>
            )}
          </Heading>
          <DoubleClickList isSupportUser={isSupportUser} />
        </TabPanel>
        <TabPanel>
          <Heading>
            {isSupportUser && (
              <Btn
                color="blue"
                icon="add"
                testHook="addMdmId"
                onClick={() => {
                  navigate(buildUrl('admin/connectors/yahoo/add'));
                }}
              >
                {i18n.t('connectors:advertisers.yahoo.add')}
              </Btn>
            )}
          </Heading>
          <YahooList />
        </TabPanel>
      </Tabs>
    </Page>
  );
};

export default Advertisers;
