import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Rules Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = Immutable({}), action = defaultAction) => {
  let newState = {};
  switch (action.type) {
    case types.RULE_FETCH_SUCCESS:
      newState = action.payload.reduce(
        (acc, rule) => ({
          ...acc,
          [rule.ruleDefinitionId]: rule,
        }),
        {},
      );
      return state.merge(newState);
    default:
      return state;
  }
};

const defaultUI = Immutable({
  isFetching: false,
  error: null,
});

/**
 * Rules Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = Immutable([]), action = defaultAction) => {
  switch (action.type) {
    case types.RULE_FETCH_SUCCESS:
      return action.payload.map(rule => rule.ruleDefinitionId);
    default:
      return state;
  }
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.RULE_FETCH_START:
      return state.set('isFetching', true);
    case types.RULE_FETCH_SUCCESS:
    case types.RULE_FETCH_FAIL:
      return state.set('isFetching', false);
    default:
      return state;
  }
};

const defaultVariables = Immutable({
  isFetching: false,
  error: null,
  values: [],
});

const variables = (state = defaultVariables, action = defaultAction) => {
  switch (action.type) {
    case types.RULE_VARIABLES_FETCH_START:
      return state.set('isFetching', true);
    case types.RULE_VARIABLES_FETCH_SUCCESS:
      return state.merge({ values: action.payload, isFetching: false });
    case types.RULE_VARIABLES_FETCH_FAIL:
      return state.set('isFetching', false);
    case types.RULE_VARIABLES_REFETCH_START:
      return state.set('isRefetching', true);
    case types.RULE_VARIABLES_REFETCH_SUCCESS:
      return state.merge({ values: action.payload, isRefetching: false });
    case types.RULE_VARIABLES_REFETCH_FAIL:
      return state.set('isRefetching', false);
    default:
      return state;
  }
};

const rulesReducer = combineReducers({
  byId,
  allIds,
  variables,
  ui,
});

export default rulesReducer;
