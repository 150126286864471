import { get, findIndex } from 'lodash';
import { getClients } from '~/context/clients';

export const getUserClients = (state, userId) => get(state, `context.users.userClients.byId[${userId}]`, []);

export const isFetchingUserClients = state => get(state, 'context.users.userClients.ui.isFetchingUserClients');

export const getNonSelectedClients = (state, userId) => {
  const userClients = getUserClients(state, userId);
  return getClients(state).filter(client => findIndex(userClients, { clientId: client.clientId }) === -1);
};

export const isAddingClients = state => get(state, 'context.users.userClients.ui.isAddingClients');
