import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import ColumnForm from '../components/ColumnForm';
import { saveColumn, fetchColumns } from '../urlBuilder/columns/actions';
import { getColumns } from '../urlBuilder/columns/selectors';

const maxValueSupported = 2147483647;

const getNextOrderNumber = columns => {
  const orderNumberList = columns.map(({ orderNumber }) => orderNumber);
  return Math.max(...orderNumberList) + 1;
};

const restrictNrToMaxSupported = number => (number > maxValueSupported ? maxValueSupported : number);

export class NewColumnPage extends Component {
  componentDidMount() {
    this.props.fetchColumns();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('columns.formNewColumn.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('columns.formNewColumn.intro')}</p>
            <ColumnForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      columns: getColumns(state),
      initialValues: {
        orderNumber: restrictNrToMaxSupported(getNextOrderNumber(getColumns(state))),
      },
    }),
    dispatch => ({
      onSubmit: column => dispatch(saveColumn(column)),
      fetchColumns: () => dispatch(fetchColumns()),
    }),
  ),
  reduxForm({
    form: 'columnNew',
    enableReinitialize: true,
  }),
  translate(['urlBuilder']),
)(NewColumnPage);
