import React from 'react';
import Detail from '~/components/src/Form/Detail/Default';
import Link from '~/components/src/Link';
import { buildUrl } from '~/common';
import PropTypes from 'prop-types';
import BtnIcon from '~/components/src/BtnIcon';

function ClientDetails(props) {
  const { t } = props;
  const checkboxValue = value => (value ? t('view.clientSettings.checkbox.yes') : t('view.clientSettings.checkbox.no'));
  return (
    <div>
      <Detail label={t('view.clientSettings.labels.clientNumber')} value={props.client.clientNumber} />

      <Detail label={t('view.clientSettings.labels.name')} value={props.client.name} />

      <Detail
        label={t('view.clientSettings.labels.usageAlertCheckbox')}
        value={checkboxValue(props.client.usageAlertsEnabled)}
      />

      {props.client.dataStorageLocation && (
        <Detail label={t('view.clientSettings.labels.dataStorageLocation')} value={props.client.dataStorageLocation} />
      )}

      {props.client.usageAlertsEnabled && (
        <Detail label={t('view.clientSettings.labels.alertThreshold')} value={props.client.usageAlertsThreshold} />
      )}

      <Detail
        label={t('view.clientSettings.labels.rateAlertsCheckbox')}
        value={checkboxValue(props.client.errorAlertsEnabled)}
      />

      {props.client.errorAlertsEnabled && (
        <Detail label={t('view.clientSettings.labels.alertErrorThreshold')} value={props.client.errorAlertsThreshold} />
      )}

      {props.client?.externalAuthentication && (
        <Detail
          label={t('view.clientSettings.labels.externalAuthentication')}
          value={
            props.client?.externalAuthentication?.enabled
              ? t('view.clientSettings.checkbox.yes')
              : t('view.clientSettings.checkbox.no')
          }
        />
      )}

      <Detail
        label={t('view.clientSettings.labels.rateLimitCheckbox')}
        value={checkboxValue(props.client.apiRateLimitEnabled)}
      />

      {props.client.apiRateLimitEnabled && (
        <Detail label={t('view.clientSettings.labels.apiRateLimit')} value={props.client.apiRateLimit} />
      )}

      <Detail
        label={t('view.clientSettings.labels.rateLimitPriorityLevelsCheckbox')}
        value={checkboxValue(props.client.priorityLevelEnabled)}
      />

      {props.client.priorityLevelEnabled && (
        <Detail
          label={t('view.clientSettings.labels.apiRateLimitCritical')}
          value={props.client.priorityLevelCritical}
        />
      )}

      <Detail label={t('view.clientSettings.labels.dataRetention')} value={props.client.dataRetentionMonths} />

      <Detail label={t('view.clientSettings.labels.mappingsRetention')} value={props.client.mappingRetentionMonths} />

      <Detail
        label={t('view.clientSettings.labels.maxAllowedHistoricProcessingDays')}
        value={props.client.maxAllowedHistoricProcessingDays}
      />

      <Detail label={t('view.clientSettings.labels.passwordPolicy')} value={props.isContextAdmin}>
        <BtnIcon
          icon="edit"
          tooltip={t('view.clientSettings.buttons.editPassPolicy.text')}
          onClick={() => props.updatePasswordPolicy(props.client)}
        />
      </Detail>

      <Detail label={t('view.clientSettings.labels.clientManagers')} value={props.accountManagers.length}>
        {props.accountManagers && props.accountManagers.length > 0 ? (
          <React.Fragment>
            {props.accountManagers.map((val, index) => (
              <Link key={val.userId} href={buildUrl(`context/users/view/${val.userId}`)}>
                {(index ? ', ' : '') + val.name}
              </Link>
            ))}
          </React.Fragment>
        ) : (
          t('view.clientSettings.texts.noClientManagers')
        )}
      </Detail>
    </div>
  );
}

ClientDetails.propTypes = {
  t: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  updatePasswordPolicy: PropTypes.func.isRequired,
  isContextAdmin: PropTypes.bool,
  accountManagers: PropTypes.array,
};
export default ClientDetails;
