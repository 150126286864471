import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { withSort } from '~/components/src/Table/enhancements';
import { Link } from '~/components';
import { Panel } from '~/components/src/Containers';
import { buildUrl } from '~/common';
import { components } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import getProfileVariables from './selector';

const MIN_VALUES_TO_DISPLAY = 5;
const { Table, Row, Cell } = components;
const WIDTHS = {
  audienceName: '25%',
  segmentName: '10%',
  variables: '25%',
};

const audienceUrl = audienceId => buildUrl(`audiences/segments/view/${audienceId}`);

const renderProfileVariables = parameters => {
  const audienceVariables = getProfileVariables(parameters);

  const [showMore, setShowMore] = useState(false);
  const valuesToDisplay = showMore ? audienceVariables.length : MIN_VALUES_TO_DISPLAY;

  return (
    <Fragment>
      {audienceVariables.slice(0, valuesToDisplay).map(variable => (
        <li key={variable}>{variable}</li>
      ))}
      {audienceVariables.length > MIN_VALUES_TO_DISPLAY && !showMore && (
        <Link onClick={() => setShowMore(!showMore)}> [+] Show more</Link>
      )}
    </Fragment>
  );
};

const renderTableRows = data =>
  data.map(audience => {
    const { audienceId, audienceName, segmentName, parameters } = audience;
    return (
      <Row key={audienceId}>
        <Cell style={{ width: WIDTHS.audienceName }}>
          <a target="_self" className="Link" href={audienceUrl(audienceId)}>
            {audienceName}
          </a>
        </Cell>
        <Cell style={{ width: WIDTHS.segmentName }}>{segmentName}</Cell>
        <Cell style={{ width: WIDTHS.variables, flexDirection: 'column' }}>{renderProfileVariables(parameters)}</Cell>
      </Row>
    );
  });

const AudiencesTable = ({ list, t, stateKey }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width={WIDTHS.audienceName} sortKey="audienceName" title={t('audience.audienceName')} />
        <SortableHeader width={WIDTHS.segmentName} sortKey="segmentName" title={t('audience.segmentName')} />
        <SortableHeader width={WIDTHS.variables} sortKey="variables" title={t('audience.variables')} />
      </Row>
      {renderTableRows(list)}
    </Table>
  </Panel>
);

AudiencesTable.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string.isRequired,
};

AudiencesTable.defaultProps = {
  list: [],
};

export default compose(withSort(), translate('customerData'))(AudiencesTable);
