import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl } from '~/common';
import { Link } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import DeleteCampaign from './components/DeleteCampaign';
import { DATE_FORMAT, TIME_FORMAT } from './constants';
import { DeleteActionProps, TCampaign } from './types';

const columnHelper = createColumnHelper<TCampaign>();

const DeleteAction = ({ campaign, refetchCampaigns }: DeleteActionProps) => {
  const [isDeletePickerVisible, setIsDeletePickerVisible] = useState(false);
  return (
    <>
      <BtnIcon
        icon="delete"
        onClick={() => setIsDeletePickerVisible(true)}
        testHook="deleteCampaign"
        tooltip={i18n.t('external:actions.delete')}
      />
      {isDeletePickerVisible && (
        <DeleteCampaign
          campaignId={campaign.campaignId}
          campaignName={campaign.name}
          setIsPickerVisible={setIsDeletePickerVisible}
          callback={refetchCampaigns}
        />
      )}
    </>
  );
};

const getDateAndTime = (date: number | string) => (
  <p>
    {moment(date).format(DATE_FORMAT)}
    <br />
    <small className="text-r42-blue">{moment(date).format(TIME_FORMAT)}</small>
  </p>
);

const getColumns = (refetchCampaigns: () => void) => [
  columnHelper.accessor('name', {
    header: () => <span>{i18n.t('external:table.name')}</span>,
    cell: info => (
      <div className="flex items-center gap-2">
        <span
          className={twMerge(
            cx('block h-2 w-2 rounded-full bg-orange-500', {
              'bg-green-500': !info.row.original.changed,
            }),
          )}
        />

        <Link className="t-name w-[30%]" href={buildUrl(`content/campaigns2/view/${info?.row.original.campaignId}`)}>
          <span className="elipsis truncate">{info.getValue()}</span>
        </Link>
      </div>
    ),
    size: 300,
    minSize: 300,
  }),
  columnHelper.accessor('compliant', {
    header: () => <div className="text-center">{i18n.t('external:table.compliant')}</div>,
    cell: info => (
      <div className="t-campaignCompliant text-center">
        <Icons
          className={twMerge(cx('h-6 w-6 text-red-500', { 'text-green-600': info.getValue() }))}
          icon={info.getValue() ? 'done' : 'close'}
        />
      </div>
    ),
  }),
  columnHelper.accessor('createdOn', {
    header: () => <div>{i18n.t('external:table.createdOn')}</div>,
    cell: info => <div className="t-createdOn">{getDateAndTime(info.getValue())}</div>,
  }),
  columnHelper.accessor('actions', {
    header: () => <div className="text-center">{i18n.t('external:table.actions')}</div>,
    cell: info => (
      <div className="text-center">
        <DeleteAction campaign={info.row.original} refetchCampaigns={refetchCampaigns} />
      </div>
    ),
  }),
];

const CampaignsTable = ({ campaigns, refetchCampaigns }: { campaigns: TCampaign[]; refetchCampaigns: () => void }) => (
  <UITable
    testHook="campaignsTable"
    emptyMessage={i18n.t('external:messages.emptyList')}
    emptyBody={i18n.t('external:messages.emptyListBody')}
    data={campaigns}
    columns={getColumns(refetchCampaigns)}
  />
);

export default CampaignsTable;
