angular.module('webUi.filter.accuracyNumber', [])

    .filter('accuracyNumber', ['$filter', function($filter) {
	
        var accuracy = [];
        var zero = 0;
        for(var i = 1 ; i <= 10; i++) {
            accuracy.push(zero.toPrecision(i));
        }
	
        return function(number, fractionSize) {
		
            var result = $filter('number')(number, fractionSize);
		
            return (result === accuracy[fractionSize]) ? '<' + result : result;
        };
    }]);
