/**
 * @description Angular main module for Content
 * @memberOf wwebUi.ui
 * @namespace webUi.ui.content
 */
angular.module('webUi.ui.content', [
    'webUi.ui.content.campaigns',
    'webUi.ui.content.campaigns2',
    'webUi.ui.content.datafeeds',
    'webUi.ui.content.medialibraries'
]);
