import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getSiteId } from '~/common/SecurityMetaService';
import Page from '~/components/src/Page';
import UsersContainer from '~/admin/users/containers/UsersContainer';
import { fetchSiteInfo, getSiteClientId, getSiteInfo, isFetchingInfo } from '~/admin/site';
import Spin from '~/components/src/Spin';

export class UsersPage extends Component {
  componentDidMount() {
    this.props.fetchSiteInfo();
  }

  render() {
    const { site, siteNumber, isSiteAdmin, isContextAdmin, siteClientId, loggedInUser, isFetchingInfo } = this.props;
    return (
      <Page>
        {isFetchingInfo ? (
          <Spin />
        ) : (
          <UsersContainer
            canEditInfo={true}
            siteNumber={siteNumber}
            site={site}
            isSiteAdmin={isSiteAdmin}
            isContextAdmin={isContextAdmin}
            shouldFetchClients={false}
            loggedInUser={loggedInUser}
            siteClientId={siteClientId}
          />
        )}
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      site: getSiteInfo(state),
      siteNumber: getSiteId(),
      siteClientId: getSiteClientId(state),
      isFetchingInfo: isFetchingInfo(state),
    }),
    dispatch => ({
      fetchSiteInfo: () => dispatch(fetchSiteInfo(getSiteId())),
    }),
  ),
  translate(['adminUsers']),
)(UsersPage);
