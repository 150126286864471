import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Value Sets Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.VALUESETS_FETCH_SUCCESS:
      return action.payload.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.predefinedValuesSetId]: currentValue,
        }),
        {},
      );
    case types.VALUESETS_SAVE_SUCCESS:
    case types.VALUESETS_EDIT_SUCCESS:
      return {
        ...state,
        [action.payload.predefinedValuesSetId]: action.payload,
      };
    case types.VALUESETS_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Value Sets Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.VALUESETS_FETCH_SUCCESS:
      return action.payload.map(item => item.predefinedValuesSetId);
    case types.VALUESETS_SAVE_SUCCESS:
      return [...state, action.payload.predefinedValuesSetId];
    case types.VALUESETS_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

/**
 * Handles values set validation errors
 * @param state
 * @param action
 * @returns bool
 */
const isValueSetsValidationActive = (state = false, action = defaultAction) => {
  switch (action.type) {
    case types.VALUESETS_VALIDATION_SHOW:
      return true;
    case types.VALUESETS_VALIDATION_HIDE:
      return false;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.VALUESET_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
      };
    case types.VALUESETS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
      };
    default:
      return state;
  }
};

const adminValueSetsReducer = combineReducers({
  byId,
  allIds,
  isValueSetsValidationActive,
  ui,
});

export default adminValueSetsReducer;
