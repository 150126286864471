import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { createColumnHelper } from '@tanstack/react-table';
import { useAPI, useApiWithState } from '~/common';
import { truncateString } from '~/common/utils/StringUtils';
import { DateRangePicker, Heading, StackedChart, Tooltip } from '~/components';
import { Panel } from '~/components/src/Containers';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { getExitConditionAnalysis, getGoalAnalysis } from '~/workflows/dataService';
import { TTriggerAnalysis, TTriggerStatsProps } from '~/workflows/types';
import { getTriggerDailyStats, mapTriggerStats } from '../../Journey/Trigger/utils';
import { ORIGIN_TYPES, TRIGGER_STATS_TABS } from '../constants';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};
const columnHelper = createColumnHelper<TTriggerAnalysis>();

const TriggerStats = ({ workFlowId, triggers, totalEntered, isGoal = false }: TTriggerStatsProps) => {
  const [selectedTrigger, setSelectedTrigger] = useState<string>(triggers?.[0]?.nodeId || '');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [dateRange, setDateRange] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const { startDate, endDate } = dateRange;
  const origin = selectedTabIndex ? ORIGIN_TYPES.HISTORIC_PROCESSING : undefined;

  const { data: triggerDailyStats, isLoading: isTriggerStatsLoading } = useAPI(() => {
    if (selectedTrigger)
      return getTriggerDailyStats(
        workFlowId,
        selectedTrigger,
        startDate,
        endDate,
        isGoal ? NodeTypes.GOAL : NodeTypes.EXIT_BY_CONDITION,
        origin,
      );
    return Promise.resolve(null);
  }, [workFlowId, selectedTrigger, startDate, endDate, origin]);

  const { state: analysisData, isLoading: isLoadingAnalysis } = useApiWithState(() => {
    if (selectedTrigger) {
      return isGoal
        ? getGoalAnalysis(workFlowId, selectedTrigger)
        : getExitConditionAnalysis(workFlowId, selectedTrigger);
    }
    return Promise.resolve(null);
  }, [selectedTrigger]);

  useEffect(() => {
    setSelectedTrigger(triggers?.[0]?.nodeId || '');
  }, [triggers]);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setDateRange({
      startDate,
      endDate,
    });
  };

  const calcConversionRate = (entered: number, reached: number | undefined) => {
    if (entered == null || reached == null || entered === 0) {
      return '0%';
    }

    return `${Math.round((reached / entered) * 100)}%`;
  };

  const getColumns = () => [
    columnHelper.accessor('experimentVariantName', {
      header: () => <div className="text-left">{i18n.t('workflow:stats.trigger.table.header.variantGroup')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 1200,
    }),
    columnHelper.accessor('weight', {
      header: () => <div className="text-right">{i18n.t('workflow:stats.trigger.table.header.weight')}</div>,
      cell: info => <div className="text-right">{info.getValue()}%</div>,
      size: 400,
    }),
    columnHelper.accessor('profilesEntered', {
      header: () => <div className="text-right">{i18n.t('workflow:stats.trigger.table.header.entered')}</div>,
      cell: info => <div className="text-right">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor(isGoal ? 'profilesReachedGoal' : 'profilesExitedByCondition', {
      header: () => (
        <div className=" text-right">
          {i18n.t(`workflow:stats.trigger.table.header.${isGoal ? 'reached' : 'exited'}`)}
        </div>
      ),
      cell: info => <div className="text-right">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('conversionRate', {
      header: () => <div className=" text-right">{i18n.t('workflow:stats.trigger.table.header.conversionRate')}</div>,
      cell: info => (
        <div className="text-right">
          {calcConversionRate(
            info?.row?.original?.profilesEntered,
            isGoal ? info?.row?.original?.profilesReachedGoal : info?.row?.original?.profilesExitedByCondition,
          )}
        </div>
      ),
      size: 400,
    }),
  ];

  const hasExperiments = analysisData?.variantAnalysis?.length >= 2 || analysisData?.exitConditionAnalysis?.length >= 2;

  return (
    <>
      <div className="mt-5 flex">
        {triggers?.map(item => (
          <div
            key={item.nodeId}
            className={twMerge(
              cx('t-triggerCard mb-5 mr-2 flex h-14 w-44 cursor-pointer rounded-lg border border-gray-300 p-3', {
                'border-blue-500': selectedTrigger === item.nodeId,
              }),
            )}
            onClick={() => setSelectedTrigger(item.nodeId)}
          >
            <div className="flex flex-col justify-center">
              <Tooltip tooltip={item.label}>
                <div className="flex items-center gap-1">
                  <Icons
                    icon={`${isGoal ? 'goal' : 'exit'}`}
                    className={twMerge(cx('h-5 w-5 text-red-600', { 'text-amber-500': isGoal }))}
                  />
                  <div className="t-profilesActive">
                    <span className={cx('text-gray700', { 'text-blue-600': selectedTrigger === item.nodeId })}>
                      {truncateString(item.label, 12)}
                    </span>
                  </div>
                </div>
              </Tooltip>
              <div className="t-profilesActive flex items-center gap-2">
                <span className="text-sm text-gray-400">
                  {i18n.t(`workflow:stats.trigger.${isGoal ? 'goal' : 'exit'}.card.secondary`)}:
                </span>
                <span className={cx('text-gray700 text-sm', { 'text-blue-600': selectedTrigger === item.nodeId })}>
                  {isGoal ? item?.profilesReached : item?.profilesExitedByCondition}
                </span>
              </div>
              <div className="t-profilesActive flex items-center gap-2">
                <span className="text-sm text-gray-400">
                  {i18n.t(`workflow:stats.trigger.${isGoal ? 'goal' : 'exit'}.card.conversionRate`)}:
                </span>
                <span className={cx('text-gray700 text-sm', { 'text-blue-600': selectedTrigger === item.nodeId })}>
                  {calcConversionRate(totalEntered, isGoal ? item?.profilesReached : item?.profilesExitedByCondition)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isLoadingAnalysis ? (
        <Spin />
      ) : (
        <>
          {hasExperiments && (
            <>
              <Heading kind="h2" title={i18n.t(`workflow:stats.trigger.${isGoal ? 'goal' : 'exit'}.analysis`)} />
              <UITable
                testHook="analysisTable"
                data={isGoal ? analysisData?.variantAnalysis || [] : analysisData?.exitConditionAnalysis || []}
                columns={getColumns()}
              />
            </>
          )}
        </>
      )}

      <div>
        <div className="absolute right-4 mt-7">
          <DateRangePicker startDate={startDate} endDate={endDate} handleDatesChange={handleDatesChange} />
        </div>
        <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
          <TabList>
            {TRIGGER_STATS_TABS.map(origin => (
              <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                <span className="flex items-center gap-2">
                  {origin.label}
                  {origin?.description && (
                    <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                  )}
                </span>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Panel className="mt-7">
          {isTriggerStatsLoading ? (
            <Spin />
          ) : (
            <StackedChart
              hideGenealInfo
              reversedYStacks={false}
              height={300}
              className="t-triggerStatisticsChart"
              data={mapTriggerStats(triggerDailyStats?.stats || [], isGoal)}
            />
          )}
        </Panel>
      </div>
    </>
  );
};

export default TriggerStats;
