/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/profiles/dependencies`;

/**
 * A service for dependencies related queries.
 */
export class DependenciesClass {
  getDependantsInfo(elementId) {
    return Api.callGet(`${BASE_PATH}/${elementId}`).then(response => response.data);
  }

  getPropertyDependantsInfo(elementId, propertyId) {
    return Api.callGet(`${BASE_PATH}/${elementId}/${propertyId}`).then(response => response.data);
  }

  getDependantsConnectorInfo(partnerId) {
    return Api.callGet(`${API_BASE}/v1/partners/${partnerId}/dependencies`).then(response => response.data);
  }
}

const DependenciesService = new DependenciesClass();
export default DependenciesService;
