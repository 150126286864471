angular.module('webUi.directive.support.template.propertyType', [
    'webUi.service.tagmanagement',
    'webUi.directive.support.template.propertySelection'
])

    .directive('templatePropertyType', [
        'TemplatingPropertySelectionService',
        'ValidService',
        /**
     * @param TemplatingPropertySelectionService
     * @returns {*}
     */
        function templatePropertyType(TemplatingPropertySelectionService, ValidService) {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    r42Model: '='
                },
                templateUrl: 'directive/support/template/propertyType/propertyType.tpl.html',
                link: function (scope) {

                    scope.selectOptions = TemplatingPropertySelectionService.getAvailableOptions();

                    scope.getLabelFor = function (type) {
                        if (_.isUndefined(type)) {
                            return 'Invalid value';
                        }

                        var prop = _.find(scope.selectOptions, {value: type});
                        if (prop && prop.label) {
                            return prop.label;
                        } else {
                            return 'Invalid value';
                        }
                    };

                    scope.getValueFor = function (type, value) {
                        if(type === TemplatingPropertySelectionService.VARIABLE_PROPERTY) {
                            return ValidService.isEmpty(value) ? '' : value.split(':')[0];
                        } else {
                            return value;
                        }
                    };
                }
            };
        }
    ]);
