const exportActionTypes = {
  FETCH_SCHEDULED_EXPORTS_START: 'FETCH_SCHEDULED_EXPORTS_START',
  FETCH_SCHEDULED_EXPORTS_FAIL: 'FETCH_SCHEDULED_EXPORTS_FAIL',
  FETCH_SCHEDULED_EXPORTS_SUCCESS: 'FETCH_SCHEDULED_EXPORTS_SUCCESS',

  FETCH_MANUAL_EXPORTS_START: 'FETCH_MANUAL_EXPORTS_START',
  FETCH_MANUAL_EXPORTS_FAIL: 'FETCH_MANUAL_EXPORTS_FAIL',
  FETCH_MANUAL_EXPORTS_SUCCESS: 'FETCH_MANUAL_EXPORTS_SUCCESS',

  SET_SELECTED_RANGE: 'SET_SELECTED_RANGE',

  SET_EXPORT_ERROR: 'SET_EXPORT_ERROR',
};

export default exportActionTypes;
