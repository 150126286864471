// Module
var code = `<div class="box" data-ng-controller="TagmanagementTagFormSelectionConditionController"> <div class="box-title"> <h3><i class="r42-monitor"></i> Audience rule</h3> </div> <div class="box-content"> <div data-ng-if="segments.length === 0" class="my-2 flex items-center gap-4 rounded-lg border border-blue-200 bg-blue-50 p-4 text-slate-600 dark:text-slate-200"> <icon p="information" class="text-blue-600"></icon> <p class="text-lg font-light">There are no audiences configured, please create an audience beforehand.</p> </div> <div data-ng-if="segments.length > 0"> <div data-wrap data-label="Audience<small>The chosen audience to satisfy the rule</small>" data-valid data-ng-model="internal.selection" data-type="select" data-options="segments" data-options-key="segmentId" data-options-value="name" data-custom-validate="[
						{isRequired: true, msg: 'Audience is mandatory'}
					]"> <span class="error-block" data-ng-show="templateForm['internal.selection'].\$showError"> <small>{{templateForm['internal.selection'].\$errors[0]}}</small> </span> </div> <div class="control-group"> <label class="control-label"> Must be <small> Whether the user must belong or not to the audience </small> </label> <div class="controls input-container"> <input type="radio" name="inSelection" data-ng-model="internal.inSelection" data-ng-value="true"/>&nbsp;In Audience &nbsp;&nbsp; <input type="radio" name="inSelection" data-ng-model="internal.inSelection" data-ng-value="false"/>&nbsp;Not In Audience </div> </div> <div data-wrap data-label="Cache in seconds<small>Cache audiences for this many seconds to save data-calls (The default of 0 means no caching)</small>" data-valid data-type="input" data-ng-model="state.formCondition.cacheInSeconds" data-placeholder="e.g 0" data-custom-validate="[
							{isRequired: true, msg: 'Leave 0 for non specified'},
							{isNumber: true, msg: 'Cache should be a positive number (leave 0 for default)'}
					]"> <span class="error-block" data-ng-show="templateForm['state.formCondition.cacheInSeconds'].\$showError"> <small>{{templateForm['state.formCondition.cacheInSeconds'].\$errors[0]}}</small> </span> </div> </div> </div> </div>`;
// Exports
var _module_exports =code;;
var path = 'ui/tagmanagement/tags/conditions/form/selectionCondition.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;