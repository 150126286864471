angular.module('webUi.ui.tagmanagement.tags.conditions.cookiePermission', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormCookiePermissionController', ['$scope', 'CookiePermissionService',
        function TagmanagementTagFormCookiePermissionController    ($scope,   CookiePermissionService) {

            var conditionClazz = 'CookiePermissionCondition';
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            CookiePermissionService.getCookiePermissionExecutionTypes().then(function(executionTypes) {
                $scope.cookiePermissionExecutionTypes = executionTypes;
            });

            CookiePermissionService.getCookiePermissionGroups().then(function(groups) {
                $scope.groups = groups;
            });

            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
        }
    ]);

