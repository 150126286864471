import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import { SmallWrapper, Panel } from '~/components/src/Containers';
import { getEngagements, getCustomerFacts, getSyncPartners } from '~/profiles/helpers/clientsHelper';
import { saveCollector } from '../collectors/actions';
import CollectorForm from '../components/CollectorsForm/CollectorsForm';
import { COLLECTORS_NEW_NAME } from '../collectors/types';

const selector = formValueSelector(COLLECTORS_NEW_NAME);

export class NewCollectorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engagements: [],
      externalFacts: [],
      partners: [],
    };
  }

  componentDidMount() {
    // AngularJS Service, not migrated to redux yet
    getEngagements(document).then(engagements => {
      this.setState({ engagements });
    });
    getCustomerFacts(document).then(externalFacts => {
      this.setState({ externalFacts });
    });
    getSyncPartners(document).then(partners => {
      this.setState({ partners });
    });
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('form.new')} />
          <Panel>
            <CollectorForm
              backHref={buildUrl('profiles/collectors/dashboard')}
              {...this.props}
              engagements={this.state.engagements}
              externalFacts={this.state.externalFacts}
              partners={this.state.partners}
              initialValues={{ allPartners: this.state.partners }}
            />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      collectorType: selector(state, 'collectorType'),
      engagementOrFactId: selector(state, 'engagementOrFactId'),
    }),
    dispatch => ({
      onSubmit: collector =>
        dispatch(
          saveCollector({
            ...collector,
            properties: collector.properties || [],
            syncPartners: collector?.syncPartners?.map(partner => partner.optionId) || [],
          }),
        ),
    }),
  ),
  reduxForm({
    form: COLLECTORS_NEW_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['collectors']),
)(NewCollectorPage);
