import React from 'react';
import { formatNumber } from '~/common';
import { Tooltip } from '~/components';
import i18n from '~/i18n';
import { TGeneral } from '~/workflows/types';
import { getPercent } from '../util';

const GeneralStats = ({ stats }: { stats: TGeneral }) => {
  const { profilesEnteredJourney, profilesActive, totalProfilesReachedGoal, profilesExited } = stats;

  const formatValue = (step: number, testHook: string) => {
    const percentage = getPercent(step, profilesEnteredJourney);

    return (
      <div className="flex items-center justify-end gap-1">
        <Tooltip tooltip={i18n.t(`workflow:journey.journeyStatistics.messages.${testHook}Note`)}>
          <p className={`t-${testHook}`}>{formatNumber(step)}</p>
        </Tooltip>
        <p className={`t-${testHook}Percent text-xs text-blue-400`}>{`${percentage}%`}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <table className="border bg-white">
        <thead>
          <tr>
            <td className="w-[13%] rounded-tl-lg p-3 text-gray-500">{i18n.t('workflow:journey.overview')}</td>
            <td className="w-[12%] p-3 text-end text-gray-500">{i18n.t('workflow:journey.entered')}</td>
            <td className="w-[12%] p-3 text-end text-gray-500">
              <Tooltip
                tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalActiveNote', { type: 'audience' })}
              >
                <span className="cursor-pointer truncate text-ellipsis text-gray-500">
                  {i18n.t('workflow:journey.active')}
                </span>
              </Tooltip>
            </td>
            <td className="w-[12%] p-3 text-end text-gray-500">
              <Tooltip tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalReachedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-500">
                  {i18n.t('workflow:journey.reached')}
                </span>
              </Tooltip>
            </td>
            <td className="w-[13%] p-3 text-end text-gray-500">
              <Tooltip tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalExitedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-500">
                  {i18n.t('workflow:journey.expired')}
                </span>
              </Tooltip>
            </td>
            <td className="w-[12%] p-3 text-end text-gray-500">
              <Tooltip tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalExitedNote')}>
                <span className="cursor-pointer truncate text-ellipsis">{i18n.t('workflow:journey.optedOut')}</span>
              </Tooltip>
            </td>
            <td className="w-[12%] p-3 text-end text-gray-500">
              <Tooltip tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalExitedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-500">
                  {i18n.t('workflow:journey.merged')}
                </span>
              </Tooltip>
            </td>
            <td className="w-[14%] p-3 pr-4 text-end text-gray-500">
              <Tooltip tooltip={i18n.t('workflow:journey.journeyStatistics.messages.totalExitedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-500">
                  {i18n.t('workflow:journey.exitConditions')}
                </span>
              </Tooltip>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-3 text-gray-500">{i18n.t('workflow:journey.totalProfiles')}</td>
            <td className="t-totalProfilesEntered p-3 text-end">{formatNumber(profilesEnteredJourney)}</td>
            <td className="p-3">{formatValue(profilesActive, 'totalProfilesActive')}</td>
            <td className="p-3">{formatValue(totalProfilesReachedGoal, 'totalProfilesReached')}</td>
            <td className="p-3">{formatValue(profilesExited.totalProfilesExpired, 'totalProfilesExpired')}</td>
            <td className="p-3">{formatValue(profilesExited.totalProfilesRemoved, 'totalProfilesRemoved')}</td>
            <td className="p-3">{formatValue(profilesExited.totalProfilesMerged, 'totalProfilesMerged')}</td>
            <td className="p-3 pr-4">
              {formatValue(profilesExited.totalProfilesExitedByCondition, 'totalProfilesExitedByCondition')}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GeneralStats;
