import React from 'react';
import { formatNumber } from '~/common';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import { LabelValue } from './LabelValue';
import { ProfilesExited } from './types';
import './styles.scss';

type JourneyStatsProps = {
  totalEntered: number;
  totalActive: number;
  totalReachedGoals: number;
  profilesExited: ProfilesExited;
};

const JourneyStats = ({
  totalEntered,
  totalActive,
  totalReachedGoals,
  profilesExited,
}: JourneyStatsProps): React.ReactElement => {
  const { totalProfilesExpired, totalProfilesRemoved, totalProfilesMerged, totalProfilesExitedByCondition } =
    profilesExited;
  return (
    <div className="t-journeyOverview border-b border-gray-200 bg-white p-4">
      <div className="mb-2 flex items-center gap-2 text-gray-600">
        <Icons icon="profiles" className="h-6 w-6" />
        <p className="font-medium ">{i18n.t('workflow:journey.profiles')}</p>
      </div>
      <LabelValue
        testHook="totalEntered"
        label={i18n.t('workflow:journey.entered')}
        value={formatNumber(totalEntered)}
      />
      <LabelValue
        testHook="totalReached"
        label={i18n.t('workflow:journey.reachedGoals')}
        value={formatNumber(totalReachedGoals)}
      />
      <LabelValue testHook="totalActive" label={i18n.t('workflow:journey.active')} value={formatNumber(totalActive)} />
      <div className="my-2 border-b border-gray-400"></div>
      <LabelValue
        testHook="totalExitedByCondition"
        label={i18n.t('workflow:journey.exitConditions')}
        value={formatNumber(totalProfilesExitedByCondition)}
      />
      <LabelValue
        testHook="expired"
        label={i18n.t('workflow:journey.expired')}
        value={formatNumber(totalProfilesExpired)}
      />
      <LabelValue
        testHook="removed"
        label={i18n.t('workflow:journey.optedOut')}
        value={formatNumber(totalProfilesRemoved)}
      />
      <LabelValue
        testHook="merged"
        label={i18n.t('workflow:journey.merged')}
        value={formatNumber(totalProfilesMerged)}
      />
    </div>
  );
};

export default JourneyStats;
