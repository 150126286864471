/**
 * @description Angular module for UserService
 * @class UserService
 * @namespace webUi.service.user
 */
angular.module('webUi.service.user', [
    'restangular',
    'ui.bootstrap',
    'webUi.ui.globalui.welcome'
])

/**
 *
 * A service for User related requests
 */
    .factory('UserService', ['Restangular', '$uibModal', '$q',
        /**
	 * @function UserService
	 * @param {Restangular} Restangular
	 * @param {$uibModal} $uibModal
	 * @param {$q} $q
	 * @returns {UserService}
	 */
        function UserService (Restangular, $uibModal, $q){
            var BASE_URL = 'users/current/';
            var R42_WELCOME_SCREEN_SHOW = 'R42_WELCOME_SCREEN_SHOW';
            var updateAuxiliaryData = function updateAuxiliaryData (auxiliaryDataKey, auxiliaryData){
                return Restangular.one(BASE_URL).post('auxiliaryData', {
                    auxiliaryDataKey : auxiliaryDataKey,
                    auxiliaryData: auxiliaryData
                });
            };
            /**
	 * Updates
	 * @param {String} newPassword
	 * @param {String} oldPassword
	 * @param {Integer} smsCode (required if 2FA auth is enable
	 * @returns {$q@call;defer.promise}
	 */
            var updatePassword = function updatePassword(newPassword, oldPassword, smsCode){
                var def = $q.defer();
                var passwordChangeRequest = {
                    oldPasswordHash : oldPassword,
                    newPasswordHash : newPassword
                };
                if ( !_.isEmpty(smsCode) ){
                    passwordChangeRequest.smsCode = smsCode;
                }
                Restangular.one(BASE_URL).post('password', passwordChangeRequest).then(function(res){
                    def.resolve(Restangular.stripRestangular(res));
                });
                return def.promise;
            };
            var updatePhoneNumber = function updatePhoneNumber(phoneNumber, smsCode){
                var def = $q.defer();
                var phoneNumberChangeRequest = {
                    phoneNumber: phoneNumber
                };
                if ( !_.isEmpty(smsCode) ){
                    phoneNumberChangeRequest.smsCode = smsCode;
                }
                Restangular.one(BASE_URL).post('phoneNumber', phoneNumberChangeRequest).then(function(res){
                    def.resolve(Restangular.stripRestangular(res));
                });
                return def.promise;
            };
            var requestSms = function requestSms(phoneNumber){
                var smsCodeRequest = {};
                if ( !_.isEmpty(phoneNumber) ){
                    smsCodeRequest.phoneNumber = phoneNumber;
                }
                return Restangular.one(BASE_URL).post('sms', smsCodeRequest);
            };
            return {
                BASE_URL : BASE_URL,
                R42_WELCOME_SCREEN_SHOW: R42_WELCOME_SCREEN_SHOW,
                updateName : function(name){
                    return Restangular.one(BASE_URL).post('name', {
                        name: name
                    });
                },
                unlinkOpenId : function (){
                    return Restangular.one(BASE_URL).post('unlinkOpenId',{});
                },
                updatePassword : updatePassword,
                requestSms : requestSms,
                updatePhoneNumber : updatePhoneNumber,
                updateDefaultSite : function(siteId){
                    return Restangular.one(BASE_URL).post('setDefaultSite', {
                        siteId : siteId
                    });
                },
                updateAuxiliaryData: updateAuxiliaryData,

                shouldShowWelcomeScreen: function(securityContext){
                    var shouldShowWelcomeScreen = window.sessionStorage[R42_WELCOME_SCREEN_SHOW];

                    if ( shouldShowWelcomeScreen === 'false' ){
                        return false;
                    }

                    var auxiliaryData = securityContext.auxiliaryData;

                    if (!_.isUndefined(auxiliaryData) && auxiliaryData[R42_WELCOME_SCREEN_SHOW] === false){
                        return false;
                    }

                    return true;
                },

                showWelcomeScreen: function(){

                    // after show, do not show again in session
                    window.sessionStorage[R42_WELCOME_SCREEN_SHOW] = false;

                    var dialog = $uibModal.open({
                        templateUrl: 'ui/globalui/welcome/welcome.tpl.html',
                        controller: 'WelcomeController'
                    });
                    dialog.result.then(function(res) {

                        // if don't show again is checked, save this in user profile
                        if ( !_.isUndefined(res) && res.notAgain === true ){
                            updateAuxiliaryData(R42_WELCOME_SCREEN_SHOW, false);
                        }
                    });
                },
                googleAuth: function () {
                    return Restangular.one(BASE_URL + '/googleAuth').get();
                },
                verifyGoogleAuth: function(secretKey, googleAuthCode){
                    var verifyGoogleAuthRequest = {
                        googleAuthKey: secretKey,
                        googleAuthCode: googleAuthCode
                    };
                    return Restangular.one(BASE_URL).post('verifyGoogleAuth', verifyGoogleAuthRequest);
                },
                makeDefault2FA: function(authenticationType){
                    var makeDefault2FARequest = {
                        authenticationType: authenticationType
                    };
                    return Restangular.one(BASE_URL).post('makeDefault2FA', makeDefault2FARequest);
                },
                removeDefault2FA: function(authenticationType){
                    var removeDefault2FARequest = {
                        authenticationType: authenticationType
                    };
                    return Restangular.one(BASE_URL).post('removeDefault2FA', removeDefault2FARequest);
                },
                openUserSettings : function() {
                    return Restangular.one(BASE_URL + '/settings').get();
                }
            };
        }]);

