const setPromiseState = () => ({
  isPending: false,
  isFulfilled: false,
  isRejected: false,
});

const setPending = () => ({
  isPending: true,
  isFulfilled: false,
  isRejected: true,
});

const setFulfilled = () => ({
  isPending: false,
  isFulfilled: true,
  isRejected: false,
});

const setRejected = () => ({
  isPending: false,
  isFulfilled: false,
  isRejected: true,
});

export { setPromiseState, setPending, setFulfilled, setRejected };
