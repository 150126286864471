angular.module('webUi.service.support.cookiepermissionform', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('CookiePermissionFormService', ['Restangular', '$q', 'Utils', 'SecurityService', '$state',
        function(Restangular, $q, Utils, SecurityService, $state){
	
            // the base path through which all calls should go
            var BASE_PATH = 'support/cookiepermissionforms/';
	
            var handlePermissions = function(){
                SecurityService.getSecurityContext().then(function(securityContext){
                    if (!securityContext.isSupportEngineer() ){
                        $state.transitionToStateWithSite('site.error.403', {});
                        return;
                    }
                });
            };
	
            return {
                getBasePath : function(){
                    return BASE_PATH;
                },
                /**
		 * Retrieves a promise of the list of all CookiePermissionVariants
		 * @returns {Array} a promise of the list of all CookiePermissionVariants
		 */		
                getAllCookiePermissionVariants: function () {
			
                    handlePermissions();
			
                    var deferred = $q.defer();
			
                    Restangular.all(BASE_PATH).getList()
                        .then(function(result) {
                            result = Utils.sanitizeRestangularAll(result);
                            deferred.resolve(result);
                        });
                    return deferred.promise;
                },
                /**
		 * Retrieves a promise of a CookiePermissionVariant with the specified cookiePermissionVariantId
		 * @param {UUID} cookiePermissionVariantId
		 * @returns {Object} a promise of the CookiePermissionVariant with the specified cookiePermissionVariantId
		 * CookiePermissionFormService#getCookiePermissionVariant
		 */		
                getCookiePermissionVariant: function (cookiePermissionVariantId) {
                    handlePermissions();
                    var deferred = $q.defer();
                    Restangular.one(BASE_PATH + cookiePermissionVariantId).get().then(function(result){
                        deferred.resolve(Utils.sanitizeRestangularOne(result));
                    });
                    return deferred.promise;
			
                },
		
                /**
		 * Posts that a property  has changed
		 * @param {String} url
		 * @param {Object} command
		 */		
                postPropertyChange: function(url, command) {
                    return Restangular.one(BASE_PATH).post(url, command);
                },
                /**
		 * 
		 * @param {Object} cookiePermissionVariant
		 * @returns {UUID} a promise of the UUID of the created object
		 */		
                createCookiePermissionVariant : function(cookiePermissionVariant){
                    return Restangular.one(BASE_PATH).post('', cookiePermissionVariant);
                },
		
                /**
		 * 
		 * @param {Object} cookiePermissionVariant
		 * @returns {UUID} a promise of the UUID of the updated object
		 */		
                editCookiePermissionVariant : function(cookiePermissionVariant){
                    return Restangular.one(BASE_PATH).post('edit', cookiePermissionVariant);
                },
				
                unrestrictClient: function(cookiePermissionVariantId, clientId){
                    return Restangular.one(BASE_PATH + 'unrestrictClient').post('', {
                        cookiePermissionVariantId: cookiePermissionVariantId,
                        clientId: clientId
                    });
                },
                /**
		 * Restricts the form with the specified id for the specified clientIds
		 */
                restrictClients: function(cookiePermissionVariantId, clientIds){
                    return Restangular.one(BASE_PATH + 'restrictClients').post('', {
                        cookiePermissionVariantId: cookiePermissionVariantId,
                        clientIds: clientIds
                    });
                }
            };
	
        }]);
