import React from 'react';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { Formik, Field } from 'formik';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { I18NextT } from '~/components/src/Common/types';
import { AddGoogleAdsUserFormProps } from './types';

const makeUniqueUserNameValidator = (existingUserNames: string[]) => {
  const validator: Yup.TestFunction<string | null | undefined> = inputValue => {
    if (!inputValue) {
      return true;
    }

    const lowerCaseVariables = existingUserNames.map(existingVariableName => existingVariableName?.toLowerCase());
    const isUsedAlready = lowerCaseVariables.includes(inputValue?.toLowerCase());
    return !isUsedAlready;
  };

  return validator;
};

const makeValidationSchema = (existingUserNames: string[], isMobileId: boolean, t: I18NextT) => {
  const validations = {
    userName: Yup.string()
      .trim()
      .test(
        'user-name-is-unique',
        t('audiences:connectors.googleAds.userAdd.userNameAlreadyExists'),
        makeUniqueUserNameValidator(existingUserNames),
      )
      .required(t('validation:validation.required')),
  } as Record<string, any>;

  if (isMobileId) {
    validations.appId = Yup.string().trim().required(t('validation:validation.required'));
  }

  return Yup.object().shape(validations);
};

const initialValues = {
  userName: '',
  userDesc: '',
};

const AddGoogleAdsUserForm = ({
  hideModal,
  createNewUser,
  setFieldValue,
  t,
  isMobileId,
  existingUserNames,
}: AddGoogleAdsUserFormProps) => {
  const handleAddUser = ({ userName, userDesc, appId }: { userName: string; userDesc: string; appId?: string }) => {
    createNewUser({ name: userName, description: userDesc, appId }, setFieldValue);
  };

  return (
    <Formik
      onSubmit={handleAddUser}
      validationSchema={makeValidationSchema(existingUserNames, isMobileId, t)}
      initialValues={initialValues}
    >
      {({ handleSubmit, errors }) => (
        <>
          <Field
            name="userName"
            as={FormikInputField}
            label={t('connectors.googleAds.userAdd.nameLabel')}
            placeholder={t('connectors.googleAds.userAdd.placeholder')}
            errorText={errors.userName}
            autoFocus={true}
          />
          <Field
            name="userDesc"
            as={FormikInputField}
            label={t('connectors.googleAds.userAdd.descriptionLabel')}
            placeholder={t('connectors.googleAds.userAdd.placeholder')}
            errorText={errors.userDesc}
          />
          {isMobileId && (
            <Field
              name="appId"
              as={FormikInputField}
              label={t('connectors.googleAds.userAdd.applicationId')}
              placeholder={t('connectors.googleAds.userAdd.placeholder')}
              errorText={errors.appId}
            />
          )}
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={hideModal}
            testHook="addGoogleAdsUserFormButtons"
            className="mr-0"
          />
        </>
      )}
    </Formik>
  );
};

export default translate('audiences')(AddGoogleAdsUserForm);
