import exportActionTypes from './exportActionTypes';
import { getScheduledExports, getManualExports, deleteExport, downloadExport } from './exportService';

const fetchScheduledExports = () => dispatch => {
  dispatch({ type: exportActionTypes.FETCH_SCHEDULED_EXPORTS_START });

  return getScheduledExports()
    .then(payload => dispatch({ type: exportActionTypes.FETCH_SCHEDULED_EXPORTS_SUCCESS, data: payload.data }))
    .catch(() => {
      dispatch({ type: exportActionTypes.FETCH_SCHEDULED_EXPORTS_FAIL });
    });
};

const fetchManualExports = days => dispatch => {
  dispatch({ type: exportActionTypes.FETCH_MANUAL_EXPORTS_START });

  return getManualExports(days)
    .then(payload => {
      dispatch({ type: exportActionTypes.FETCH_MANUAL_EXPORTS_SUCCESS, data: payload.data });
    })
    .catch(() => {
      dispatch({ type: exportActionTypes.FETCH_MANUAL_EXPORTS_FAIL });
    });
};

const deleteScheduledExport = id => dispatch =>
  deleteExport(id)
    .then(() => {
      dispatch(fetchScheduledExports());
    })
    .catch(() => {});

const setSelectedRange = selectedRange => dispatch => {
  dispatch({ type: exportActionTypes.SET_SELECTED_RANGE, selectedRange });
  dispatch(fetchManualExports(selectedRange));
};

const downloadManualExport = id => dispatch => downloadExport(id, dispatch);

const setExportError = exportError => ({
  type: exportActionTypes.SET_EXPORT_ERROR,
  exportError,
});

export {
  fetchScheduledExports,
  fetchManualExports,
  setSelectedRange,
  deleteScheduledExport,
  downloadManualExport,
  setExportError,
};
