import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

const validations = Yup.object().shape({
  amnetClientId: Yup.string().required('Amnet Client Id is required'),
  amnetSegmentKey: Yup.string().required('Amnet Segment Key is required'),
});

export const Amnet = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="amnetClientId"
          label={t('cards.label.amnetId')}
          placeholder={t('common.audienceCard.placeholder')}
          errorText={formProps.touched.amnetClientId && formProps.errors.amnetClientId}
        />
        <Field
          as={FormikInputField}
          name="amnetSegmentKey"
          label={t('cards.label.amnetSegmentKey')}
          placeholder={t('common.audienceCard.placeholder')}
          errorText={formProps.touched.amnetSegmentKey && formProps.errors.amnetSegmentKey}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
