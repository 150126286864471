import React from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';

const RulePickerItem = props => {
  const { title, onClick, usedForFilters = false, isSelected = false } = props;
  return (
    <div
      className={cx(
        `t-${title.replace(/ /g, '')}`,
        'RulePickerItem',
        'flex items-center gap-2 rounded border border-transparent bg-white px-4 py-2 text-xl text-gray-500 hover:border-gray-800 hover:text-gray-600 hover:drop-shadow',
      )}
      onClick={onClick}
    >
      {usedForFilters && (
        <div className="size-6 flex items-center justify-center border ">
          <Icons icon={isSelected ? 'done' : 'null'} className="size-5" />
        </div>
      )}
      {title}
    </div>
  );
};

export default RulePickerItem;
