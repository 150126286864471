import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import OverlaySpinner from '../OverlaySpinner';

const Form = ({ isSubmitting = false, testHook = 'form', children, ...props }) => {
  const classes = cx(props.className, `t-${testHook}`);
  return (
    <form className={classes} {...props} autoComplete="off">
      {children}
      <OverlaySpinner isActive={isSubmitting} />
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Form;
