import { buildUrl } from '~/common';
import { RulesMap } from '~/common/modules/rules/selectors';
import { RulesTypes } from '~/common/modules/rules/types';

export const getRuleItemUrl = (ruleType: keyof typeof RulesMap, ruleDefinitionId: string): string => {
  if (ruleType === RulesTypes.Engagement) {
    return buildUrl(`profiles/engagements/view/${ruleDefinitionId}?back=1`);
  }

  if (ruleType === RulesTypes.ExternalFact) {
    return buildUrl(`profiles/externalFacts/view/${ruleDefinitionId}?back=1`);
  }

  if (ruleType === RulesTypes.AiFact) {
    return buildUrl(`profiles/externalFacts/view/${ruleDefinitionId}?back=1`);
  }

  return '';
};
