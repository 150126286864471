/**
 * @description Angular main module for Customer Journey -> Dashboard
 * @memberOf webUi.ui.workflows
 * @namespace webUi.ui.workflows.pages
 */

angular.module('webUi.ui.workflows.pages', ['webUi.ui.siteState', 'webUi.ui.workflows.form']).config([
  '$stateProvider',
  function config($stateProvider) {
    $stateProvider
      .state('site.workflows.form.exitConditionEdit', {
        url: '/exitConditionEdit/{goalId}',
        isReact: true,
      })
      .state('site.workflows.form.exitConditionNew', {
        url: '/exitConditionNew',
        isReact: true,
      })
      .state('site.workflows.form.goalEdit', {
        url: '/goalEdit/{goalId}',
        isReact: true,
      })
      .state('site.workflows.form.goalNew', {
        url: '/goalNew',
        isReact: true,
      })
      .state('site.workflows.form.stats', {
        url: '/stats',
        isReact: true,
      })
      .state('site.workflows.form.statsView', {
        url: '/statsView/{nodeType}/{stepOrGoalId}',
        isReact: true,
      })
      .state('site.workflows.form.step', {
        url: '/step/{stepId}',
        isReact: true,
      })
      .state('site.workflows.form.stepEdit', {
        url: '/stepEdit/{stepId}',
        isReact: true,
      })
      .state('site.workflows.form.stepNew', {
        url: '/stepNew/addPartner',
        isReact: true,
      })
      .state('site.workflows.form.trigger', {
        url: '/trigger/{triggerId}',
        isReact: true,
      })
      .state('site.workflows.form.goal', {
        url: '/goal/{goalId}',
        isReact: true,
      })
      .state('site.workflows.form.exitCondition', {
        url: '/exitCondition/{exitConditionId}',
        isReact: true,
      })
      .state('site.workflows.form.triggerEdit', {
        url: '/triggerEdit/{triggerId}',
        isReact: true,
      })
      .state('site.workflows.form.triggerNew', {
        url: '/triggerNew',
        isReact: true,
      });
  },
]);
