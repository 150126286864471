import React from 'react';
import cx from 'classnames';
import Spinner from '~/components/src/Spinner';
import './styles.scss';

interface LeftPanelProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  localStorageKey: string;
  onTransitionEnd?: React.TransitionEventHandler<HTMLDivElement>;
}

const LeftPanel = ({ children, className, isLoading = false, onTransitionEnd }: LeftPanelProps): React.ReactElement => (
  <div className={cx('LeftPanel', className)} onTransitionEnd={onTransitionEnd}>
    {isLoading ? <Spinner /> : <>{children}</>}
  </div>
);

export default LeftPanel;
