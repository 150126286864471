import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import moment from 'moment';
import { buildUrl } from '~/common';
import { getAngularService } from 'ReactAngular/react.service';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import DateRangeForm from '~/context/components/DateRangeForm';
import { fetchSiteStatistics, getSitesStats, isFetchingClientSitesStats, getTotalUsage } from '~/context/clients';
import SitesUsageStatistics from '~/context/components/SitesUsageStatistics';
import BtnIcon from '~/components/src/BtnIcon';

const SHOW_USAGE_STATE_KEY = 'CONTEXT/CLIENT/SHOW_USAGE_STATE_KEY';
const defaultDates = {
  startDate: moment(moment.now()).subtract(1, 'month').startOf('month'),
  endDate: moment(moment.now()).subtract(1, 'month').endOf('month'),
};

/**
 * ShowUsageContainer component. It would be loaded in page component
 */
class ShowUsageContainer extends React.Component {
  componentDidMount() {
    const ContextService = getAngularService(document, 'ContextService');
    this.props.onSubmit(defaultDates);
    this.exportCsv = () => ContextService.exportSiteUsage(this.props.list);
  }

  render() {
    const { backUrl, isFetching, t, totalUsage, list, stateKey, navigate } = this.props;

    return (
      <div>
        <Heading
          title={t('clients:view.modals.showUsage.title')}
          crumbs={[
            {
              title: t('sites:usage.backToSite'),
              onClick: () => {
                navigate(backUrl);
              },
            },
          ]}
        >
          <BtnIcon
            tooltip={t('clients:button.exportCsv.text')}
            icon="download"
            onClick={() => this.exportCsv()}
            testHook="exportCSV"
          />
        </Heading>
        <DateRangeForm {...this.props} />
        {isFetching ? (
          <Spinner />
        ) : (
          <SitesUsageStatistics totalUsage={totalUsage} list={list} stateKey={stateKey} t={t} />
        )}
      </div>
    );
  }
}

ShowUsageContainer.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      initialValues: defaultDates,
      backUrl: buildUrl(`context/clients/view/${ownProps.params.clientId}`),
      list: getSitesStats(state),
      totalUsage: getTotalUsage(state),
      stateKey: SHOW_USAGE_STATE_KEY,
      isFetching: isFetchingClientSitesStats(state),
    }),
    (dispatch, ownProps) => ({
      onSubmit: values => {
        const { startDate, endDate } = values;
        dispatch(fetchSiteStatistics(ownProps.clientId, startDate.format('x'), endDate.format('x')));
      },
    }),
  ),
  reduxForm({ form: 'ClientSitesUsageForm', touchOnChange: true, touchOnBlur: true }),
  translate('clients'),
)(ShowUsageContainer);
