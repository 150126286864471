import PropTypes from 'prop-types';
import React from 'react';
import { tableContextWrapper, excludeContextualProps } from '../context';

const HeaderCell = ({ style, className = '', children, isTable, ...props }) => {
  const Element = isTable ? 'th' : 'div';
  const DOMProps = excludeContextualProps(props);

  return (
    <Element className={`Table-cell Table-cell--header ${className}`} style={style} {...DOMProps}>
      {children}
    </Element>
  );
};

HeaderCell.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isTable: PropTypes.bool.isRequired,
};

export default tableContextWrapper(HeaderCell);
