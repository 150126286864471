import React from 'react';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { translate } from 'react-i18next';

import Notification from '~/components/src/Notification';
import { MediumWrapper } from '~/components/src/Containers';
import IdentityConfigurationTable from './IdentityConfigurationTable';

import './styles.scss';

export const Identities = ({ t }) => (
  <Page>
    <MediumWrapper>
      <Heading title={t('profileIdentities:pageTitle')} />
      <Notification kind="information" header="" className="Identities-tipBox">
        <span>{t('profileIdentities:pageDescription')}</span>
      </Notification>
      <IdentityConfigurationTable t={t} />
    </MediumWrapper>
  </Page>
);

export default translate()(Identities);
