import React, { ReactElement, useState } from 'react';
import { buildUrl, useAPI } from '~/common';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '~/i18n';
import { Heading, Notification, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import { Panel } from '~/components/src/Containers';
import Editor from '@monaco-editor/react';
import { fetchBeaconById } from './dataService';
import { BeaconObject } from './types';
import BeaconStats from './BeaconStats';
import DeleteBeacon from './DeleteBeacon';

function BeaconView({ siteNumber, hasTagEdit }: { siteNumber?: number; hasTagEdit: boolean }): ReactElement {
  const params = useParams();
  const navigate = useNavigate();
  const beaconId = params.beaconId || '';
  const { data, isLoading: isBeaconLoading, error: isBeaconError } = useAPI(() => fetchBeaconById(beaconId));
  const [isDeletePickerVisible, setIsDeletePickerVisible] = useState(false);

  const goToBeaconsList = () => navigate(buildUrl('tagmanagement/beacons'), { replace: true });

  function getBeaconEndpoint(beacon: BeaconObject) {
    if (siteNumber) {
      return `//${beacon?.trackingHostname}/vt-${siteNumber}?bi=${beacon?.beaconNumber}&ibi=${beacon?.checksum}`;
    }
    return '';
  }

  function getBeaconEndpointJS(beacon: BeaconObject) {
    return `<script type="text/javascript">\n\tvar _stViewTrack = new Image(); _stViewTrack.src = "${getBeaconEndpoint(
      beacon,
    )}&cb=" + new Date().getTime();\n</script>`;
  }

  function getBeaconEndpointNonJS(beacon: BeaconObject) {
    return `<img src="${getBeaconEndpoint(beacon)}" width="1" height="1">`;
  }

  if (isBeaconLoading || !data) {
    return <Spin />;
  }

  if (isBeaconError) {
    <Notification kind="error">
      {typeof isBeaconError === 'string' ? isBeaconError : JSON.stringify(isBeaconError)}
    </Notification>;
  }

  return (
    <Page>
      <Heading
        title={data?.beaconName}
        crumbs={[
          {
            title: i18n.t('beacons:back'),
            onClick: () => {
              goToBeaconsList();
            },
          },
        ]}
      >
        {hasTagEdit && (
          <BtnIcon
            icon="delete"
            onClick={() => setIsDeletePickerVisible(true)}
            testHook="deleteBeacon"
            tooltip={i18n.t('beacons:actions.delete')}
          />
        )}
      </Heading>
      {isDeletePickerVisible && (
        <DeleteBeacon beacon={data} setIsPickerVisible={setIsDeletePickerVisible} callback={goToBeaconsList} />
      )}
      <Panel className="flex w-80 flex-col gap-4">
        <p className="flex justify-between">
          <span className="text-gray-400">{i18n.t('beacons:view.beaconIdentifier')} : </span>
          {data?.beaconNumber}
        </p>
        <p className="flex justify-between">
          <span className="text-gray-400">{i18n.t('beacons:view.checksum')} : </span>
          {data?.checksum}
        </p>
        <p className="flex justify-between">
          <span className="text-gray-400">{i18n.t('beacons:view.trackingHostname')} : </span>
          {data?.trackingHostname}
        </p>
      </Panel>
      <BeaconStats beaconId={beaconId} />
      <p className="mt-10 text-xl text-gray-600">{i18n.t('beacons:view.beaconJS')}</p>
      <Editor
        className="my-4 h-20 flex-1 rounded"
        defaultLanguage="javascript"
        value={getBeaconEndpointJS(data)}
        options={{
          readOnly: true,
          minimap: {
            enabled: false,
          },
        }}
      />
      <p className="mt-10 text-xl text-gray-600">{i18n.t('beacons:view.beaconNoNJS')}</p>
      <Editor
        className="my-4 h-20 flex-1 rounded"
        defaultLanguage="javascript"
        value={getBeaconEndpointNonJS(data)}
        options={{
          readOnly: true,
          minimap: {
            enabled: false,
          },
        }}
      />
    </Page>
  );
}

export default BeaconView;
