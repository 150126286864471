/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_CLIENTS = `${API_BASE}/context/clients`;
const API_CLIENT_DOMAINS = `${API_BASE}/context/clientDomains`;

class ContextClientDomainClass {
  /**
   * Get all domains for given client
   * @param clientId
   */
  fetchDomains(clientId) {
    return Api.callGet(`${API_CLIENTS}/${clientId}/clientDomains`).then(response => response.data);
  }

  /**
   * Get all domains for given client
   * @param clientId
   */
  saveDomains(domain, isEdit) {
    return Api.callPost(`${API_CLIENT_DOMAINS}/${isEdit ? 'edit' : 'add'}`, domain).then(response => response.data);
  }

  /**
   * Get all domains for given client
   * @param clientId
   */
  removeDomain(domainId) {
    return Api.callDelete(`${API_CLIENT_DOMAINS}/${domainId}`).then(response => response.data);
  }
}

const ContextClientDomainService = new ContextClientDomainClass();

export default ContextClientDomainService;
