angular.module('webUi.ui.globalui.welcome', [
    'webUi.service.security'
])

    .controller('WelcomeController', ['$scope', '$rootScope', '$uibModalInstance', 'SecurityService', function($scope, $rootScope, $uibModalInstance, SecurityService){
      
        $scope.assetsPrefix = $rootScope.assetsPrefix;

        SecurityService.getSecurityContext().then(function(context){
            $scope.hasTagViewPermission = context.hasPermission('TAG_VIEW');
        });

        $scope.welcome = {
            notAgain: false
        };

        $scope.closeModal = function(){
            $uibModalInstance.close($scope.welcome);
        };

    }]);
