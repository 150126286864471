angular.module('webUi.ui.content.campaigns.ads.creatives.preview', ['webUi.service'])

    .controller('AdservingCreativesPreviewController', ['$scope', '$uibModalInstance', 'dialogsModel', 'SecurityService', 'CreativePreviewService',
        function AdservingCreativesPreviewController($scope, $uibModalInstance, dialogsModel, SecurityService, CreativePreviewService) {
            SecurityService.getSecurityContext().then(function(context) {

                if (!_.isUndefined(dialogsModel.creative)) {
                    $scope.creative = dialogsModel.creative;
                    $scope.ad = dialogsModel.ad;
                    $scope.dataFeedKeys = dialogsModel.dataFeedKeys;
                    $scope.siteNumber = context.getCurrentSite().siteNumber;
                    $scope.allowMulti = dialogsModel.allowMulti;
                }

                $scope.loadIframeContent = function() {

                    // for backwards compatiblity requestParameter mapping of size 1 is dealt like none multi key
                    var isMulti = dialogsModel.isRequestParameter ? $scope.dataFeedKeys.length > 1 : $scope.allowMulti;

                    CreativePreviewService.previewCreative($scope.siteNumber, $scope.ad, $scope.creative, $scope.dataFeedKeys, isMulti).then(function(result) {
                        $scope.writeContentToIframe(result);
                    });
                };

                $scope.writeContentToIframe = function(content) {
                    content += '<style>body { margin: 0px; }</style>';
                    var ifrm = angular.element.find('#previewContent')[0];
                    ifrm = (ifrm.contentWindow) ? ifrm.contentWindow : (ifrm.contentDocument.document) ? ifrm.contentDocument.document : ifrm.contentDocument;
                    ifrm.document.open();
                    ifrm.document.write(content);
                    ifrm.document.close();
                };

            });

            $scope.close = function() {
                $uibModalInstance.close();
            };
        }
    ]);
