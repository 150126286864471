import React, { useContext } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import { CARD_WIDTH, HALF_CARD_HEIGHT, CARD_HEIGHT, HALF_CARD_WIDTH } from '../constants';
import { LineForTransitionProps, TLine, TLink, TransitionLineProps } from './types';
import { CanvasContext } from '../JourneyCanvas/CanvasContext';

const Line = ({ source, target, className = '' }: TLine & { className?: string }) => {
  const xTurnOffset = 30;

  const arrowStart = `${source.x + CARD_WIDTH + 4}, ${source.y + HALF_CARD_HEIGHT}`;
  const firstTurn = `${target.x - xTurnOffset}, ${source.y + HALF_CARD_HEIGHT}`;
  const secondTurn = `${target.x - xTurnOffset}, ${target.y + HALF_CARD_HEIGHT}`;
  const arrowEnd = `${target.x - 5}, ${target.y + HALF_CARD_HEIGHT}`;

  return (
    <polyline
      key={target.data.nodeId}
      points={`${arrowStart} ${firstTurn} ${secondTurn} ${arrowEnd}`}
      className={cx('fill-transparent stroke-gray-500 stroke-[2px]', className)}
    />
  );
};

const LineForTransition = ({ source, target, hasError, className }: LineForTransitionProps) => {
  const { transitionSource } = useContext(CanvasContext);
  const isValid = transitionSource === target?.data?.nodeId;
  const first = `${source.x + HALF_CARD_WIDTH + 4}, ${source?.y + CARD_HEIGHT}`;
  const arrowStart = `${source.x + HALF_CARD_WIDTH + 4}, ${
    target?.y < CARD_HEIGHT + HALF_CARD_HEIGHT ? source?.y - CARD_HEIGHT * 2 : source?.y + CARD_HEIGHT * 6
  }`;
  const firstTurn = `${target.x + CARD_WIDTH + 110}, ${
    target?.y < CARD_HEIGHT + HALF_CARD_HEIGHT ? source?.y - CARD_HEIGHT * 2 : source?.y + CARD_HEIGHT * 6
  }`;
  const last = `${target.x + CARD_WIDTH + HALF_CARD_HEIGHT + 40}, ${target?.y + HALF_CARD_HEIGHT}`;

  return (
    <polyline
      key={target.data.nodeId}
      points={`${first} ${arrowStart} ${firstTurn} ${last}`}
      className={cx(
        'cursor-pointer fill-transparent',
        { 'stroke-green-500 stroke-[6px]': isValid, 'stroke-red-400 stroke-[6px]': hasError },
        className,
      )}
    />
  );
};

const JourneyLine = ({ source, target }: TLine) => <Line source={source} target={target} />;

const ExperimentLine = ({ source, target }: TLine) => (
  <Line
    source={source}
    target={target}
    className="JourneyView-lineBetweenNodes--experiment fill-transparent stroke-blue-400 stroke-2"
  />
);

const TransitionLine = ({ source, target, hasError }: TransitionLineProps) => (
  <LineForTransition
    source={source}
    target={target}
    hasError={hasError}
    className="JourneyView-lineBetweenNodes--transition"
  />
);

const Lines = ({ links }: { links: TLink[] }) => {
  const { nodesErrors } = useContext(CanvasContext);
  return (
    <>
      {links.map(({ source, target, isTransition = false }) => {
        const isExperiment = source?.data?.children?.length > 1;
        const LineComponent = isExperiment ? ExperimentLine : JourneyLine;
        const nodeErrors = nodesErrors[target?.data?.nodeId];
        const hasError = get(nodeErrors, 'STRUCTURE_ERROR.length', 0) > 0;

        if (isTransition) {
          return (
            <TransitionLine
              key={`${source.data.nodeId}_${target.data.nodeId}`}
              source={source}
              target={target}
              hasError={hasError}
            />
          );
        }

        return (
          <LineComponent key={`${source?.data?.nodeId}_${target?.data?.nodeId}`} source={source} target={target} />
        );
      })}
    </>
  );
};

const Links = ({ links }: { links: TLink[] }) => <Lines links={links} />;

export default Links;
