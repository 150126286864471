import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, isValueUnique } from '~/common';
import Form from '~/components/src/Form/Form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import i18n from '../../i18n';

export const isNotNegativeNumber = value => {
  if (parseInt(value, 10) < 1) {
    return i18n.t('urlBuilder:types.typesForm.isNegativeNumberError');
  }
  return undefined;
};

// TODO refactor
const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.types.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUnique(value, itemsList);
};

const isNameUnique = isFormFieldUnique('name');

export const TypesForm = ({ handleSubmit, submitting, backHref, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <Field
      label={t('types.typesForm.name.label')}
      name="name"
      placeholder={t('types.typesForm.name.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, isNameUnique, defaultMaxInput]}
      type="text"
    />
    <Field
      label={t('types.typesForm.prefix.label')}
      name="prefix"
      placeholder={t('types.typesForm.prefix.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, defaultMaxInput]}
      type="text"
    />
    <Field
      label={t('types.typesForm.keyParameter.label')}
      name="keyParameter"
      placeholder={t('types.typesForm.keyParameter.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, defaultMaxInput, isNotNegativeNumber]}
      type="text"
    />
    <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

TypesForm.propTypes = {
  handleSubmit: PropTypes.func,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default TypesForm;
