/**
 * @class webUi.ContextService
 * @memberOf webUi
 *
 * Service module for the Context part of the Relay42 platform.
 * Lists users and their permissions for current site, invites user to site, grants permissions and deletes access.
 */
angular.module('webUi.service.context', [
    'restangular',
    'webUi.common.Utils',
    'ui.bootstrap'
])

    .provider('ContextService', [function () {

        var BASE_PATH = 'context/';

        var DEFAULT_BLOCK_ALL_SUBNET = '0.0.0.0/32';

        /**
         * @function ContextService
         * @memberOf webUi.ContextService
         *
         * @description This is an angularjs service for getting/mutating context data.
         *
         * @param {Restangular} Restangular
         * @param $q
         * @param Utils
         * @param CsvService
         * @param $uibModal
         * @param SecurityCommonService
         *
         * @returns {ContextService}
         */
        var ContextService = function ContextService(Restangular, $q, Utils, CsvService) {

            return {

                BASE_PATH: BASE_PATH,
                DEFAULT_BLOCK_ALL_SUBNET: DEFAULT_BLOCK_ALL_SUBNET,

                /**
                 * @returns all clients within the system
                 */
                getAllClients: function () {
                    var def = $q.defer();
                    Restangular.all('context/clients').getList().then(function (clients) {
                        clients = Utils.sanitizeRestangularAll(clients);
                        def.resolve(clients);
                    });
                    return def.promise;
                },

                /**
                 * @returns {Array} of promises having the SiteModules
                 */
                getSiteModules: function () {
                    var modulesDeferred = $q.defer();
                    Restangular.all('context/sites/siteModules').getList().then(function (siteModules) {
                        modulesDeferred.resolve(Utils.sanitizeRestangularAll(siteModules));
                    });
                    return modulesDeferred.promise;
                },


                exportUsers: function (users) {
                    CsvService.exportAsFile(
                        CsvService.generateFileName('users', true),
                        users,
                        [
                            'userId',
                            'username',
                            'name',
                            user => user.lastLoginDate ? moment(user.lastLoginDate).toISOString() : '-',
                            user => user.resetExpireDate ? moment(user.resetExpireDate).toISOString() : '-',
                            user => user.clientPojo?.clientId || '-',
                            user => user.clientPojo?.name || '-',
                            user => user.clientPojo?.clientNumber || '-',
                            user => user.clientPojo?.dataRetentionMonths || '-',
                            user => user.defaultSiteId || '-',
                            user => user.defaultSiteNumber || '-',
                            user => user.siteName || '-',
                            user => user.lock?.locked || '-',
                            user => user.lock?.reason || '-',
                            'contextAdmin',
                            'supportEngineer',
                            'hasTwoFactorAuthentication',
                            user => user.default2FA?.name || '-',
                            'hasGoogleAuthEnabled',
                            'hasSMSAuthEnabled',
                            'hasOpenId',
                            user => user.openId || '-',
                            user => user.phoneNumber || '-',
                            user => user.restrictedSubnets || '-',
                            user => user.whitelistedSubnets || '-'
                        ],
                        [
                            'Id', 'Username', 'Name', 'Last Login', 'Reset expiry date', 'Client ID', 'Client name', 'Client number',
                            'Client data retention (months)', 'Default site ID', 'Default site number', 'Site name', 'Locked',
                            'Locked Reason', 'Context admin', 'Support engineer', 'Has 2FA', 'Default 2FA type', 'Google auth enabled',
                            'SMS auth enabled', 'Has Open ID', 'Open ID', 'Phone number', 'Restricted subnets', 'Whitelisted subnets'
                        ]);
                },

                exportClients: function (clients) {
                    CsvService.exportAsFile(
                        CsvService.generateFileName('clients', true),
                        clients,
                        ['clientId', 'name', 'clientNumber'],
                        ['Id', 'Name', 'Number']);
                },

                exportSites: function (sites) {
                    CsvService.exportAsFile(
                        CsvService.generateFileName('sites', true),
                        sites,
                        ['siteId', 'name', 'siteNumber', 'clientName', 'siteModules'],
                        ['Id', 'Name', 'Number', 'Client', 'Modules']);
                },

                exportSiteUsage: function (siteUsage) {
                    var exportData = siteUsage.slice(0);
                    exportData.push(this.makeTotals(siteUsage));
                    CsvService.exportAsFile(
                        CsvService.generateFileName('siteUsage', true),
                        exportData,
                        ['siteNumber', 'siteName', 'tagCalls', 'adImpressions', 'apiCalls', 'tdnDataCalls',
                            'pageviews', 'bannerviews', 'engagements', 'conversions', 'externalfacts', 'otherEvents'],
                        ['Id', 'Name', 'Tag calls', 'Ad impressions', 'Api calls', 'TDN data calls',
                            'Page views', 'Banner views', 'Engagements', 'Conversions', 'External Facts', 'Other events']);

                },

                makeTotals: function makeTotals(siteUsage) {
                    return {
                        siteNumber: '', siteName: 'Total:',
                        tagCalls: this.sumOf(siteUsage, 'tagCalls'),
                        adImpressions: this.sumOf(siteUsage, 'adImpressions'),
                        apiCalls: this.sumOf(siteUsage, 'apiCalls'),
                        tdnDataCalls: this.sumOf(siteUsage, 'tdnDataCalls'),
                        pageviews: this.sumOf(siteUsage, 'pageviews'),
                        bannerviews: this.sumOf(siteUsage, 'bannerviews'),
                        engagements: this.sumOf(siteUsage, 'engagements'),
                        conversions: this.sumOf(siteUsage, 'conversions'),
                        externalfacts: this.sumOf(siteUsage, 'externalfacts'),
                        otherEvents: this.sumOf(siteUsage, 'otherEvents')
                    };
                },

                sumOf: function sumOf(data, key) {
                    var sum = 0;
                    _.forEach(data, function (value) {
                        sum = sum + value[key];
                    });
                    return sum;
                },

            };
        };

        this.$get = ['Restangular', '$q', 'Utils', 'CsvService', '$uibModal', 'SecurityCommonService', function (Restangular, $q, Utils, CsvService) {
            return new ContextService(Restangular, $q, Utils, CsvService);
        }];
    }]);

