/* eslint-disable no-param-reassign */
import { changeUrl, normalizeValue } from '~/common';
import { isArray, isString } from 'lodash';
import { fetchBasescripts } from '~/context/basescripts/actions';
import * as fromModals from '~/modals';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import * as types from './types';
import * as selectors from './selectors';
import ContextSitesService from './dataService.js';

/**
 * Fetch Site
 */
export const fetchSite = siteId => dispatch => {
  dispatch({
    type: types.SITE_FETCH_START,
    payload: siteId,
  });

  return ContextSitesService.getSite(siteId)
    .then(payload => {
      dispatch({
        type: types.SITE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.SITE_FETCH_FAIL, error }));
};

/**
 * Fetch site only if not fetched yet
 */
export const fetchSiteIfNeeded = siteId => (dispatch, getState) => {
  if (selectors.getSite(getState(), siteId)) {
    return Promise.resolve();
  }
  return dispatch(fetchSite(siteId));
};

/**
 * Fetch all sites
 */
export const fetchSites = () => (dispatch, getState) => {
  if (selectors.isFetchingSites(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: types.SITES_FETCH_START,
  });

  return ContextSitesService.getAllSites()
    .then(payload => {
      dispatch({
        type: types.SITES_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.SITES_FETCH_FAIL, error }));
};

/**
 * Fetch sites only if not fetched yet
 */
export const fetchSitesIfNeeded = () => (dispatch, getState) => {
  if (selectors.shouldFetchSites(getState())) {
    return dispatch(fetchSites());
  }
  return Promise.resolve();
};

function transformSubnets(restrictedSubnets) {
  if (restrictedSubnets && isString(restrictedSubnets)) {
    // Convert the string into an array elements
    return restrictedSubnets.split(',').map(item => item.trim());
  }
  if (isArray(restrictedSubnets)) {
    // it's an array
    return restrictedSubnets;
  }
  return null;
}

function getSiteModel(site) {
  if (!site.siteModules.RECOMMENDER) {
    delete site.maxInputsForAIModel;
    delete site.maxAiModelLimit;
  }

  if (!site.siteModules.CUSTOMER_JOURNEY) {
    delete site.maxJourneyLimit;
  }
  // it only accept site modules with true value
  site.siteModules = Object.keys(site.siteModules).filter(key => site.siteModules[key] === true);
  let maxTags;
  let restrictedSubnetsTransformed;
  if (site.siteId) {
    // set maxTags to undefined if Tag Management is not defined
    maxTags = isArray(site.siteModules) && site.siteModules.includes('TAG_MANAGEMENT') ? site.maxTags : null;
    restrictedSubnetsTransformed = transformSubnets(site.restrictedSubnets);
  }

  return {
    ...site,
    name: normalizeValue(site.name),
    maxTags,
    restrictedSubnets: restrictedSubnetsTransformed,
  };
}

/**
 * Save site
 * Updates existing group or creates a new one if there is no siteId in saving object
 *
 * @param site
 */
export const saveSite = site => dispatch => {
  const isCreatingSite = !site.siteId;
  const apiCall = site.siteId ? ContextSitesService.updateSite : ContextSitesService.saveSite;

  const model = {
    ...getSiteModel(site),
    // UI-2134 temporary : Hardcoded it to be WEB
    platformType: {
      name: 'WEB',
    },
  };

  dispatch({
    type: types.SAVE_START,
    payload: model,
  });

  // Save Message on the backend
  return apiCall(model)
    .then(siteId => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: {
          ...model,
          siteId,
        },
      });
      if (isCreatingSite) {
        dispatch(fetchBasescripts());
      }
      // Go to the site list
      changeUrl(`context/sites/view/${siteId}`);
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Fetch Timezones
 */
export const fetchTimezones = () => dispatch => {
  dispatch({
    type: types.TIMEZONES_FETCH_START,
  });

  return ContextSitesService.getTimezones()
    .then(payload => {
      dispatch({
        type: types.TIMEZONES_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.TIMEZONES_FETCH_FAIL, error }));
};

/**
 * Fetch Timezones if not is store
 */
export const fetchTimezonesIfNeeded = () => (dispatch, getState) => {
  const timezones = selectors.getTimezones(getState());
  if (timezones && timezones.length) {
    return Promise.resolve();
  }
  return dispatch(fetchTimezones());
};

/**
 * Fetch SiteModules
 */
export const fetchSiteModules = () => dispatch => {
  dispatch({
    type: types.SITE_MODULES_FETCH_START,
  });

  return ContextSitesService.getSiteModules()
    .then(payload => {
      dispatch({
        type: types.SITE_MODULES_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.SITE_MODULES_FETCH_FAIL, error }));
};

/**
 * Fetch SiteModules if not is store
 */
export const fetchSiteModulesIfNeeded = () => (dispatch, getState) => {
  if (selectors.getSiteModules(getState()).length) {
    return Promise.resolve();
  }
  return dispatch(fetchSiteModules());
};

export const deleteSite = siteId => dispatch => {
  dispatch({
    type: types.SITE_DELETE_START,
  });

  return ContextSitesService.deleteSite(siteId)
    .then(() => {
      showSuccess({ body: i18n.t('sites:api:deletionSuccess') });
      dispatch({
        type: types.SITE_DELETE_SUCCESS,
        payload: siteId,
      });

      // Go to the sites list
      changeUrl('context/sites/list');
    })
    .catch(error => dispatch({ type: types.SITE_DELETE_FAIL, error }));
};

export const fetchSiteStatistics = model => dispatch => {
  if (!model || !model.siteId || !model.startDate || !model.endDate) return undefined;

  dispatch({
    type: types.STATS_FETCH_START,
    payload: model,
  });

  return ContextSitesService.fetchStats(model)
    .then(payload => {
      dispatch({
        type: types.STATS_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.STATS_FETCH_FAIL, error }));
};

export const truncateSite = values => dispatch => {
  if (!values || values.confirmation !== types.SITE_TRUNCATE_CONFIRMATION) {
    return Promise.reject();
  }

  dispatch({
    type: types.SITE_TRUNCATE_START,
    payload: values.siteId,
  });

  return ContextSitesService.truncateSite(values.siteId)
    .then(() => {
      dispatch({
        type: types.SITE_TRUNCATE_SUCCESS,
        payload: {
          siteId: values.siteId,
        },
      });
      dispatch(fromModals.hideModal());
    })
    .catch(error => dispatch({ type: types.SITE_TRUNCATE_FAIL, error }));
};

export const setFilter = query => dispatch => {
  dispatch({
    type: types.SET_FILTER,
    payload: query,
  });
};

export const removeFilter = () => dispatch => {
  dispatch({
    type: types.REMOVE_FILTER,
  });
};
