/* eslint-disable no-prototype-builtins */
angular.module('webUi.service.tagmanagement.serviceHelper', [
    'webUi.common.Utils'
])
    .factory('TagmanagementServiceHelper', [
    /**
     * @returns {TagmanagementServiceHelper}
     */
        function TagmanagementServiceHelper(){

            /**
         * Extracts the property name from the label
         * @see TagmanagementService.getMobileProperties
         * @param property
         * @returns {*}
         */
            var getPropertyNameFromLabel = function getMobileProperty(propertyParameter, mobileProperties) {
                if (!propertyParameter || !mobileProperties ) {
                    return false;
                }
                var _propertyName;
                if (mobileProperties.length > 0) {
                    var _mobilePropertiesIndexed = _.indexBy(mobileProperties, function(mobileProperty){
                        return mobileProperty.name;
                    });
                    _propertyName = (_mobilePropertiesIndexed.hasOwnProperty(propertyParameter)) ? _mobilePropertiesIndexed[propertyParameter].label : null;
                }
                return _propertyName;
            };

            var service = {
                getPropertyNameFromLabel: getPropertyNameFromLabel
            };

            return service;
        }
    ]);

