/**
 * Block an action from propagating its effects higher up the DOM
 */
angular.module('webUi.directive.blockKey', ['ui.keypress'])

    .directive('blockKey', function() {

        var keysByCode = {
            8: 'backspace',
            9: 'tab',
            13: 'enter',
            27: 'esc',
            32: 'space',
            33: 'pageup',
            34: 'pagedown',
            35: 'end',
            36: 'home',
            37: 'left',
            38: 'up',
            39: 'right',
            40: 'down',
            45: 'insert',
            46: 'delete'
        };

        return {
            restrict : 'A',

            /**
         * @todo Tag template: With only one argument to the directive, at the container level,
         * the children because they are transcluded they need to be self-manageable to and stopPropagation()
         *
         * @param scope
         * @param element
         * @param attr
         */
            link : function(scope, element, attr) {

                var keys = scope.$eval(attr['blockKey']);
                if(!_.isArray(keys)) {
                    keys = [keys];
                }

                element.bind('keydown keyup keypress', function(e) {

                    var key = keysByCode[e.which];
                    if(!key) {
                        key = String.fromCharCode(e.which).toLowerCase();
                    }

                    if(_.contains(keys, key)) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
            }
        };
    });
