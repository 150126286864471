import React, { useState, useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { useAPI } from '~/common/ApiHooks';
import FormHeader from '~/profiles/connectors/form/partners/formHeader/FormHeader';
import { ReduxFormSensitiveField, ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired } from '~/common/formValidation';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import CONNECTOR_CONSTANTS from '~/profiles/connectors/form/constants';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import BtnOutlined from '~/components/src/BtnOutlined';
import twitterApi from './service';

const partnerNumbersByIdentifierType = {
  [CONNECTOR_CONSTANTS.TWITTER_ID]: CONNECTOR_CONSTANTS.TWITTER_PARTNER_NUMBERS.TWITTER_ID,
  [CONNECTOR_CONSTANTS.HASHED_EMAIL]: CONNECTOR_CONSTANTS.TWITTER_PARTNER_NUMBERS.HASHED_EMAIL,
};

const TwitterForm = ({ t, partner, values, canUpdate, change, touch }) => {
  const { consumerKey, consumerSecret, accessToken, tokenSecret, accountName, accountId } = values;

  const { data: identifierTypes, isLoading: areIdentifierTypesLoading } = useAPI(() =>
    twitterApi.fetchIdentifierTypes(t),
  );

  const [areAccountsLoading, setAreAccountsLoading] = useState(false);

  const [accountSelectOptions, setAccountSelectOptions] = useState([]);

  const [areSensitiveFieldsModified, setAreSensitiveFieldsModified] = useState(false);

  useEffect(() => {
    if (canUpdate && !areSensitiveFieldsModified) {
      // Show configured Twitter account to user, if connector is open in "edit" mode and no fields are modified yet
      setAccountSelectOptions([
        {
          label: accountName,
          value: accountId,
          name: accountId,
        },
      ]);
    }
  }, [accountName, accountId]);

  const clearAccountSelect = () => {
    if (!areSensitiveFieldsModified) {
      setAreSensitiveFieldsModified(true);
    }

    setAccountSelectOptions([]);
    change('TWITTER.accountId', '');
  };

  const selectOptions =
    identifierTypes?.map(type => ({
      label: type.label,
      value: type.name,
      name: type.name,
    })) || [];

  const isRequestAccountsButtonEnabled = Boolean(
    values.consumerKey && values.consumerSecret && values.accessToken && values.tokenSecret,
  );

  const populateAccountSelect = async () => {
    setAreAccountsLoading(true);

    let accounts;

    try {
      accounts = await twitterApi.fetchAccounts(consumerKey, consumerSecret, accessToken, tokenSecret, t);
    } catch {
      setAreAccountsLoading(false);
      return;
    }

    const accountSelectOptions = accounts.map(account => ({
      label: account.name,
      value: account.id,
      name: account.id,
    }));

    setAccountSelectOptions(accountSelectOptions);

    if (accountSelectOptions.length > 0) {
      change('TWITTER.accountId', accountSelectOptions[0].value);
      change('TWITTER.accountName', accountSelectOptions[0].label);
    }

    setAreAccountsLoading(false);
  };

  return (
    <FormSection name={CONNECTOR_CONSTANTS.partnerTypes.TWITTER}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.cookieSyncMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <FieldWithEditMode
          name="consumerKey"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          label={t('form.formFields.consumerKey')}
          type="text"
          placeholder={t('form.formFields.inputPlaceHolder')}
          onChange={clearAccountSelect}
        />

        <FieldWithEditMode
          name="consumerSecret"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          label={t('form.formFields.consumerSecret')}
          type="text"
          placeholder={t('form.formFields.inputPlaceHolder')}
          onChange={clearAccountSelect}
        />

        <FieldWithEditMode
          name="accessToken"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          label={t('form.formFields.accessToken')}
          placeholder={t('form.formFields.inputPlaceHolder')}
          type="text"
          onChange={clearAccountSelect}
        />

        <FieldWithEditMode
          name="tokenSecret"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          label={t('form.formFields.tokenSecret')}
          placeholder={t('form.formFields.inputPlaceHolder')}
          type="text"
          onChange={clearAccountSelect}
        />
        <div className="my-4 flex justify-end">
          <BtnOutlined
            testHook="loadAccountsButton"
            disabled={!isRequestAccountsButtonEnabled}
            onClick={populateAccountSelect}
          >
            {t('audiences:connectors.twitter.loadAccountOptions')}
          </BtnOutlined>
        </div>
        <Field
          label={t('form.formFields.account')}
          name="accountId"
          component={ReduxFormSelectField}
          validate={isRequired}
          options={accountSelectOptions}
          onChange={(event, selectedAccountId) => {
            change('TWITTER.accountId', selectedAccountId);

            const selectedAccountName = accountSelectOptions.find(({ value }) => value === selectedAccountId).label;
            change('TWITTER.accountName', selectedAccountName);
          }}
          isLoading={areAccountsLoading}
          disabled={canUpdate && !areSensitiveFieldsModified}
          placeholder={t('audiences:connectors.twitter.selectAccount')}
          noOptionsMessage={() => t('audiences:connectors.twitter.clickLoadAccounts')}
          touch={touch}
        />

        <Field
          label={t('form.formFields.identifierType')}
          name="identifierType"
          component={ReduxFormSelectField}
          validate={isRequired}
          options={selectOptions}
          disabled={canUpdate}
          onChange={(event, selectedIdentifierType) => {
            change('TWITTER.identifierType', selectedIdentifierType);
            change('TWITTER.partnerNumber', partnerNumbersByIdentifierType[selectedIdentifierType]);
          }}
          isLoading={areIdentifierTypesLoading}
          touch={touch}
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default TwitterForm;
