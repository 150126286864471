import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';
import { WORKFLOW_TYPE } from '../constants';
import { fetchWorkflowData } from '../dataService';
import AudienceForm from './Audience';
import JourneyForm from './Journey';
import Statistics from './Statistics';

const WorkflowForm = () => {
  const params = useParams();
  const workflowId = params.workflowId || '';

  const {
    state: workflowData,
    isLoading,
    refetch: refetchWorkflow,
  } = useApiWithState(async () => fetchWorkflowData(workflowId), [workflowId]);

  if (isLoading || !workflowData) return <Spin />;

  const BaseComponent =
    workflowData.workflowType?.name === WORKFLOW_TYPE.Audience ? (
      <AudienceForm audienceData={workflowData} refetchAudience={refetchWorkflow} />
    ) : (
      <JourneyForm journeyData={workflowData} refetchJourney={refetchWorkflow} />
    );

  return (
    <>
      <Routes>
        <Route path="stats" element={<Statistics workflowData={workflowData} />} />
        <Route path="/*" element={BaseComponent} />
      </Routes>
    </>
  );
};

export default WorkflowForm;
