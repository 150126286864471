export const CONFIGURATIONS_FETCH_START = 'app/admin/urlBuilder/CONFIGURATIONS_FETCH_START';
export const CONFIGURATIONS_FETCH_SUCCESS = 'app/admin/urlBuilder/CONFIGURATIONS_FETCH_SUCCESS';
export const CONFIGURATIONS_FETCH_FAIL = 'app/admin/urlBuilder/CONFIGURATIONS_FETCH_FAIL';

export const CONFIGURATION_SAVE_START = 'app/admin/urlBuilder/CONFIGURATION_SAVE_START';
export const CONFIGURATION_SAVE_SUCCESS = 'app/admin/urlBuilder/CONFIGURATION_SAVE_SUCCESS';
export const CONFIGURATION_SAVE_FAIL = 'app/admin/urlBuilder/CONFIGURATION_SAVE_FAIL';

export const CONFIGURATION_EDIT_START = 'app/admin/urlBuilder/CONFIGURATION_EDIT_START';
export const CONFIGURATION_EDIT_SUCCESS = 'app/admin/urlBuilder/CONFIGURATION_EDIT_SUCCESS';
export const CONFIGURATION_EDIT_FAIL = 'app/admin/urlBuilder/CONFIGURATION_EDIT_FAIL';

export const CONFIGURATION_DELETE_START = 'app/admin/urlBuilder/CONFIGURATION_DELETE_START';
export const CONFIGURATION_DELETE_SUCCESS = 'app/admin/urlBuilder/CONFIGURATION_DELETE_SUCCESS';
export const CONFIGURATION_DELETE_FAIL = 'app/admin/urlBuilder/CONFIGURATION_DELETE_FAIL';

export const CONFIGTEST_FETCH_START = 'app/admin/urlBuilder/CONFIGTEST_FETCH_START';
export const CONFIGTEST_FETCH_SUCCESS = 'app/admin/urlBuilder/CONFIGTEST_FETCH_SUCCESS';
export const CONFIGTEST_FETCH_FAIL = 'app/admin/urlBuilder/CONFIGTEST_FETCH_FAIL';
export const CONFIGTEST_CLEANUP = 'app/admin/urlBuilder/CONFIGTEST_CLEANUP';
export const CONFIGTEST_CONNECTION_ERROR = 'app/admin/urlBuilder/CONFIGTEST_CONNECTION_ERROR';

export const REPORT_START = 'app/admin/urlBuilder/REPORT_START';
export const REPORT_SUCCESS = 'app/admin/urlBuilder/REPORT_SUCCESS';
export const REPORT_FAIL = 'app/admin/urlBuilder/REPORT_FAIL';

export const connectionTypes = [
  { value: 'SFTP', label: 'sftp' },
  { value: 'FTP', label: 'ftp' },
  { value: 'FTPS', label: 'ftps' },
];

export const loginTypes = [
  { value: 'ANONYMOUS', label: 'Anonymous' },
  { value: 'NORMAL', label: 'Normal (username and password)' },
];

export const loginTypesSftp = [{ value: 'NORMAL', label: 'Normal (username and password)' }];

export const portBasedOnConnectionType = {
  SFTP: 22,
  FTP: 21,
  FTPS: 21,
};
