import React from 'react';
import { PickerView } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { useAPI } from '~/common';
import i18n from '~/i18n';
import { DeletePathModalProps } from '../types';
import { getPathCanBeDeleted } from '../dataService';
import { changePathShowType } from '../utils';

const DeletePathModal = ({
  path,
  setShowDeleteModal,
  showDeleteModal,
  removePath,
}: DeletePathModalProps): JSX.Element => {
  const { data, isLoading } = useAPI(() => getPathCanBeDeleted(path), [path]);
  const { totalResults } = data || {};
  const canBeDeleted = !isLoading && totalResults === 0;
  return (
    <>
      {showDeleteModal && !isLoading && (
        <PickerView
          className="!h-auto !w-1/4"
          pickerTitle={i18n.t('indexedPaths:delete.title')}
          handlePickerVisibility={() => setShowDeleteModal(false)}
        >
          <>
            <p className="t-deleteText">
              {i18n.t(`indexedPaths:delete.${canBeDeleted ? 'message' : 'warning'}`, {
                path: changePathShowType(path),
              })}
            </p>
            <ActionButtons
              testHook="removePath"
              onDecline={() => setShowDeleteModal(false)}
              onDelete={canBeDeleted ? () => removePath(path) : undefined}
            />
          </>
        </PickerView>
      )}
    </>
  );
};

export default DeletePathModal;
