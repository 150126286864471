angular
    .module('ui.bootstrap.datetimepicker', ['ui.bootstrap', 'webUi.service.time'])

    .constant('datetimepickerDefaults', {
        dateFormat: 'dd/MM/yyyy',
        showWeeks: false,
        hourStep: 1,
        minuteStep: 1,
        showMeridian: true,
        currentText: 'Today',
        clearText: 'Clear',
        closeText: 'Done',
        closeOnDateSelection: true,
        datetimepickerAppendToBody: false,
        showButtonBar: true,
        dateInputClass: 'input-large',
        readonlyDate: true,
        readonlyTime: true,
    })

/**
   * Allows to transform between the site timezone and local timezone in the datetimepicker
   */
    .directive('datetimepickerRespectTimezone', [
        'TimeService',
        function (TimeService) {
            return {
                restrict: 'A',
                priority: 1,
                require: 'ngModel',
                link: function (scope, element, attrs, ctrl) {
                    //Only apply the directive if is defined in the datetimepicker directive
                    if (!attrs.datetimepickerRespectTimezone) {
                        return;
                    }
                    //Value to send and store in backend
                    ctrl.$parsers.push(function (value) {
                        if (!value) {
                            return;
                        }
                        return TimeService.convertWithTz(value, TimeService.CONVERSION_DIRECTION.FROM_LOCAL_TZ_TO_SITE_TZ);
                    });
                    //Value to show the user in the interface
                    ctrl.$formatters.push(function (value) {
                        if (!value) {
                            return;
                        }
                        return TimeService.convertWithTz(value, TimeService.CONVERSION_DIRECTION.FROM_SITE_TZ_TO_LOCAL_TZ);
                    });
                },
            };
        },
    ])
    .directive('datetimepicker', [
        'datetimepickerDefaults',
        'Utils',
        function (datetimepickerDefaults, Utils) {
            if (angular.version.full < '1.1.4') {
                return {
                    restrict: 'EA',
                    template:
            '<divclass="my-2 flex items-center gap-4 rounded-lg border border-error-200 bg-error-50 p-4 text-slate-600 dark:text-slate-200"><icon p="error" class="text-error-600"></icon><p class="text-lg font-light">Angular 1.1.4 or above is required for datetimepicker to work correctly</p></div>',
                };
            }
            return {
                restrict: 'EA',
                require: 'ngModel',
                scope: {
                    ngModel: '=',
                    ngModelOptions: '=',
                    dayFormat: '=',
                    monthFormat: '=',
                    yearFormat: '=',
                    dayHeaderFormat: '=',
                    dayTitleFormat: '=',
                    monthTitleFormat: '=',
                    showWeeks: '=',
                    startingDay: '=',
                    yearRange: '=',
                    dateFormat: '=',
                    ngDisabled: '=',
                    minDate: '=',
                    maxDate: '=',
                    dateOptions: '=',
                    dateDisabled: '&',
                    ngChange: '&',
                    changeDelay: '=',
                    hourStep: '=',
                    minuteStep: '=',
                    showMeridian: '=',
                    meridians: '=',
                    mousewheel: '=',
                    readonlyTime: '@',
                },
                template: function (elem, attrs) {
                    var showTime = angular.isDefined(attrs['showTime']) || false;

                    function dashCase(name) {
                        return name.replace(/[A-Z]/g, function (letter, pos) {
                            return (pos ? '-' : '') + letter.toLowerCase();
                        });
                    }

                    function createAttr(innerAttr, dateTimeAttrOpt) {
                        var dateTimeAttr = angular.isDefined(dateTimeAttrOpt) ? dateTimeAttrOpt : innerAttr;
                        if (attrs[dateTimeAttr]) {
                            return dashCase(innerAttr) + '="' + dateTimeAttr + '" ';
                        } else if (angular.isDefined(datetimepickerDefaults[dateTimeAttr])) {
                            return dashCase(innerAttr) + '="' + datetimepickerDefaults[dateTimeAttr] + '" ';
                        } else {
                            return '';
                        }
                    }

                    function createFuncAttr(innerAttr, funcArgs, dateTimeAttrOpt) {
                        var dateTimeAttr = angular.isDefined(dateTimeAttrOpt) ? dateTimeAttrOpt : innerAttr;
                        if (attrs[dateTimeAttr]) {
                            return dashCase(innerAttr) + '="' + dateTimeAttr + '({' + funcArgs + '})" ';
                        } else {
                            return '';
                        }
                    }

                    function createEvalAttr(innerAttr, dateTimeAttrOpt) {
                        var dateTimeAttr = angular.isDefined(dateTimeAttrOpt) ? dateTimeAttrOpt : innerAttr;
                        if (attrs[dateTimeAttr]) {
                            return dashCase(innerAttr) + '="' + attrs[dateTimeAttr] + '" ';
                        } else if (angular.isDefined(datetimepickerDefaults[dateTimeAttr])) {
                            return dashCase(innerAttr) + '="' + datetimepickerDefaults[dateTimeAttr] + '" ';
                        } else {
                            return dashCase(innerAttr);
                        }
                    }

                    function createAttrConcat(previousAttrs, attr) {
                        return previousAttrs + createAttr.apply(null, attr);
                    }
                    var tmpl =
            '<div class="!flex !items-center !gap-2 !flex-row"><div class="flex !items-center !gap-2">' +
            '<div data-button-icon icon="calendar" data-ng-click="open($event)"/> <input ng-change=\'model_change()\' type="text" ng-click="open($event)" is-open="opened" ng-model="ngModel" ' +
            'data-ng-attr-data-datetimepicker-respect-timezone=\'{{ngModelOptions.respectTimezone}}\'' +
            [
                ['minDate'],
                ['ngDisabled'],
                ['maxDate'],
                ['dayFormat'],
                ['monthFormat'],
                ['yearFormat'],
                ['dayHeaderFormat'],
                ['dayTitleFormat'],
                ['monthTitleFormat'],
                ['startingDay'],
                ['showWeeks'],
                ['yearRange'],
                ['datepickerOptions', 'dateOptions'],
            ].reduce(createAttrConcat, '') +
            createFuncAttr('dateDisabled', 'date: date, mode: mode') +
            createEvalAttr('uibDatepickerPopup', 'dateFormat') +
            createEvalAttr('datepickerAppendToBody', 'datetimepickerAppendToBody') +
            createEvalAttr('ngReadonly', 'readonlyDate') +
            createEvalAttr('currentText') +
            createEvalAttr('closeText') +
            createEvalAttr('clearText') +
            createEvalAttr('showButtonBar') +
            createEvalAttr('class', 'dateInputClass') +
            '/>\n' + '' +
            '</div>\n';

                    if (showTime) {
                        tmpl +=
              '<div data-ng-show="ngModel" ng-model="ngModel" ng-change="model_change()" style="display:inline-block"' +
              'data-ng-attr-data-datetimepicker-respect-timezone=\'{{ngModelOptions.respectTimezone}}\'' +
              '>\n' +
              '<div uib-timepicker ' +
              [['hourStep'], ['minuteStep'], ['showMeridian'], ['meridians'], ['mousewheel']].reduce(
                  createAttrConcat,
                  '',
              ) +
              createEvalAttr('readonlyInput', 'readonlyTime') +
              '></div>\n' +
              '</div></div>';
                    }
                    return tmpl;
                },
                controller: [
                    '$scope',
                    '$attrs',
                    '$timeout',
                    '$rootScope',
                    function ($scope, $attrs, $timeout, $rootScope) {
                        $scope.datetimepickerId = 'datetimepicker-' + $scope.$id + '-' + Math.floor(Math.random() * 10000);
                        $scope.model_change = function () {
                            if (!$scope.ngDisabled && angular.isDefined($scope.ngModel)) {
                                $scope.ngModel = angular.isDate($scope.ngModel) ? $scope.ngModel.getTime() : $scope.ngModel;
                            }
                        };

                        $scope.open = function ($event) {
                            $event.preventDefault();
                            $event.stopPropagation();
                            if (!$scope.ngDisabled) {
                                $scope.opened = true;
                            }

                            $rootScope.$broadcast('openDatepicker', { id: $scope.datetimepickerId });
                        };

                        $rootScope.$on('openDatepicker', function (event, message) {
                            if (message.id !== $scope.datetimepickerId) {
                                $scope.opened = false;
                            }
                        });
                    },
                ],
                link: function (scope, element, attrs) {
                    scope.$watch(
                        function () {
                            return scope.ngModel;
                        },
                        function (ngModel) {
                            Utils.setScopeValue(scope.$parent, attrs.ngModel, angular.isDate(ngModel) ? ngModel.getTime() : ngModel);
                        },
                    );
                },
            };
        },
    ]);
