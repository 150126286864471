import { createContext, useContext } from 'react';
import { HpOptionsType } from './types';

type HPContextType = {
  hasGoals: boolean;
  hasExits: boolean;
  selectedHpOptions: HpOptionsType[];
  reprocessingModes: HpOptionsType[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setSelectedHpOptions: (options: HpOptionsType[]) => void;
};

const HPContext = createContext<HPContextType>({
  hasGoals: false,
  hasExits: false,
  selectedHpOptions: [],
  reprocessingModes: [],
  currentStep: 1,
  setCurrentStep: () => {
    // Intentionally left blank
  },
  setSelectedHpOptions: () => {
    // Intentionally left blank
  },
});

const useHPContext = (): HPContextType => useContext(HPContext);

export { HPContext, useHPContext };
