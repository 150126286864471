import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import Form from '~/components/src/Form/Form';
import { isRequired, minLength, isNewPasswordRepeatMatching } from '~/common';
import { ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import CardMessage, { MessageTypes } from '~/components/src/CardMessage';
import UserSettingsService from '~/user/settings/dataService';
import './styles.scss';

const minPasswordValidation = minLength(12);

const PasswordErrors = ({ errorPasswordRequest, t }) => (
  <div>
    {errorPasswordRequest.violations &&
      errorPasswordRequest.violations.map(errorMessage => (
        <CardMessage message={errorMessage} type={MessageTypes.ERROR} key={errorMessage} />
      ))}
    {!errorPasswordRequest.passwordsMatching && (
      <CardMessage message={t('settings:errors.errorOldPassword')} type={MessageTypes.ERROR} />
    )}
  </div>
);

const UpdatePasswordForm = ({ handleSubmit, submitting, t, errorPasswordRequest, user }) => {
  useEffect(() => {
    if (user?.hasSMSAuthEnabled || user?.hasGoogleAuthEnabled) {
      UserSettingsService.checkIf2FactorAuthNeeded();
    }
  }, []);

  return (
    <div className="UpdatePasswordForm">
      <Form onSubmit={handleSubmit} isSubmitting={submitting}>
        <Field
          label={t('settings:updatePassword.oldPassword.label')}
          name="oldPassword"
          placeholder={t('settings:updatePassword.oldPassword.placeholder')}
          component={ReduxFormSensitiveField}
          validate={isRequired}
        />
        <Field
          label={t('settings:updatePassword.newPassword.label')}
          name="newPassword"
          placeholder={t('settings:updatePassword.newPassword.placeholder')}
          component={ReduxFormSensitiveField}
          validate={[isRequired, minPasswordValidation]}
        />
        <Field
          label={t('settings:updatePassword.newPasswordRepeat.label')}
          name="newPasswordRepeat"
          placeholder={t('settings:updatePassword.newPasswordRepeat.placeholder')}
          component={ReduxFormSensitiveField}
          validate={[isRequired, isNewPasswordRepeatMatching, minPasswordValidation]}
        />
      </Form>
      {errorPasswordRequest && <PasswordErrors errorPasswordRequest={errorPasswordRequest} t={t} />}
    </div>
  );
};

export default UpdatePasswordForm;
