angular.module('webUi.ui.support.tagtemplates.groups.base', ['webUi.ui.support.tagtemplates.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates.groups', {
                url: '/groups',
                leftMenu: true,
                abstract: true
            });
    }]);
