angular.module('webUi.service.reflection', [])

    .factory('ReflectionService', [function () {
	
        return {
		
            /**
		 * Set a fieldvalue on a targetObject, this field is allowed to be nested
		 * 
		 * Example: setNestedProperty(targetObject, 'example.field', 3); 
		 * 
		 * @return value set on the object
		 */
            setNestedProperty : function(targetObject, field, value) {
			
                var fields = field.split('.');
                var cur = targetObject;
			
                // recurse over nested fields
                for(var i = 0; i < fields.length - 1; i++) {
                    if(typeof cur[fields[i]] === 'undefined') {
                        cur[fields[i]] = {};
                    }
				
                    cur = cur[fields[i]];
                }
			
                // set value
                cur[_.last(fields)] = value;
			
                return cur[_.last(fields)];
            }
        };
    }]);
