/**
 * Directive for rendering a element with popover and disable the inital action
 */
angular.module('webUi.directive.actionElement', [
    'ui.bootstrap',
    'webUi.service.uuid'
]
)
    .directive('actionElement', [
        '$compile',
        '$timeout',
        'UUIDService',
        function actionElementDirective($compile, $timeout, UUIDService) {

            var addPopoverText = function (elem, attrs, text, defaultText) {
                elem.attr('popover-append-to-body', true);
                elem.attr('uib-popover', text || defaultText);
                elem.attr('popover-placement', attrs.popoverPlacement || 'left');
                elem.attr('popover-trigger', attrs.popoverTrigger || 'focus');
                elem.attr('popover-title', attrs.popoverTitle || '');
            };

            return {
                //
                restrict: 'A',
                replace: false,
                transclude: false,
                //
                priority: 599,
                //
                require: '?ngClick',

                /**
                 *
                 * @param element
                 * @param attrs
                 * @returns {{pre: Function, post: Function}}
                 */
                compile: function compile(element, attrs) {

                    element.removeAttr('action-element');
                    element.removeAttr('data-action-element');

                    element.removeAttr('ng-if');
                    element.removeAttr('data-ng-if');

                    var options = {};
                    options.uuid = UUIDService.newId();

                    options.elementType = attrs.elementType || 'button';

                    element.attr('data-action-element-identifier', options.uuid);

                    var recompileIfNeeded = function recompileIfNeeded(actionNotAllowed, elem, scope) {
                        var originalNgClick = elem.attr('data-ng-click') || elem.attr('ng-click');
                        if (actionNotAllowed) {
                            addPopoverText(elem, attrs, attrs.actionNotAllowedText, 'Action not allowed');
                            if (originalNgClick) {
                                elem.attr('data-action-element-ng-click', originalNgClick);
                                elem.removeAttr('data-ng-click');
                                elem.removeAttr('ng-click');
                            }
                            $compile(elem)(scope);

                        } else {
                            if (originalNgClick) {
                                elem.attr('data-ng-click', originalNgClick);
                                elem.removeAttr('data-action-element-ng-click');
                            }
                            if (options.elementType === 'flowButton') {
                                elem.attr('data-flow-btn', '');
                                $compile(elem)(scope);
                            }
                        }
                    };

                    return {
                        pre: function preLink(scope, elem, attrs) {

                            options.actionEnabled = scope.$eval(attrs.actionEnabled);
                            options.actionNotAllowed = scope.$eval(attrs.actionNotAllowed);

                            if (!options.actionEnabled) {
                                addPopoverText(elem, attrs, attrs.popoverText, 'Insufficient permissions');

                                if (options.elementType === 'button') {
                                    //just add the disabled class but keep the others ones, like btn-lg
                                    elem.addClass('btn-disabled');
                                    elem.removeClass('btn-primary');
                                    elem.removeClass('btn-secondary');
                                }

                                if (options.elementType === 'statusIcon') {
                                    elem.attr('class', 'statusIcon');
                                }

                                if (options.elementType === 'flowButton') {
                                    elem.attr('class', 'btn btn-disabled');
                                }

                                $compile(elem)(scope);

                            } else {
                                recompileIfNeeded(options.actionNotAllowed, elem, scope);
                            }

                            scope.$watch(
                                attrs.actionNotAllowed,
                                function (recompile) {
                                    recompileIfNeeded(recompile, elem, scope);
                                }
                            );

                        },


                        /**
                         *
                         * @param scope
                         * @param elem
                         * @param attrs
                         * @param controller
                         */
                        post: function postLink(scope, elem) {

                            //Disable listener on the element
                            if (!options.actionEnabled || options.actionNotAllowed) {
                                var realElem = elem.parent().find('[data-action-element-identifier="' + options.uuid + '"]');
                                realElem.unbind('click');

                                // preventDefault if it is a submit button or link
                                if (realElem.attr('type') === 'submit' || realElem[0].nodeName.toUpperCase() === 'A') {
                                    realElem.click(function ($event) {
                                        $event.preventDefault();
                                    });
                                }
                            }
                        }
                    };
                }
            };
        }
    ]);
