angular.module('webUi.ui.content.campaigns.ads.conditions.segment', ['webUi.common.Utils'])

    .controller('AdServingSegmentConditionController', ['$scope', 'CookiePermissionService', 'SegmentService','ValidService',
        function AdServingSegmentConditionController    ($scope,   CookiePermissionService,   SegmentService,  ValidService) {

            SegmentService.getSegments().then(function(result) {
                $scope.segments = result;
            });

            $scope.isEmpty = ValidService.isEmpty;

            var conditionClazz = 'SegmentCondition';
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            $scope.state.beforeSubmit = function(condition) {
                if(condition.segmentId !== undefined) {
                    var segment = _.find($scope.segments, {'segmentId': condition.segmentId});
                    condition.name = segment.name;
                }
                return condition;
            };

            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
        }
    ]);

