import { set, flatMap } from 'lodash';

export const getPropertyType = (selectedProperty, propertyTypes) => {
  const isCustomPropertySelected = !propertyTypes.find(propertyType => propertyType.dataField === selectedProperty);

  const genericPropertyType = isCustomPropertySelected ? null : selectedProperty;

  return propertyTypes.find(propertyType => propertyType.dataField === genericPropertyType);
};

const groupByConstraintType = availableConstraints =>
  availableConstraints.reduce((groups, constraint) => {
    const constraintType = constraint.group.name;
    const groupItem = {
      label: constraint.matchType.label,
      value: constraint,
    };

    const isGroupCreated = typeof groups[constraintType] === 'object';

    if (isGroupCreated) {
      const optionsWithNewItem = [...groups[constraintType].options, groupItem];
      return set(groups, `${constraintType}.options`, optionsWithNewItem);
    }

    const newGroup = { label: constraint.group.label, options: [groupItem] };
    return set(groups, constraintType, newGroup);
  }, {});

export const getGroupedOptionsList = availableConstraints => {
  const grouped = groupByConstraintType(availableConstraints);

  return Object.values(grouped);
};

export const isConstraintValidForSelectedProperty = (selectedConstraint, selectedPropertyType) =>
  selectedPropertyType.availableConstraints.some(
    constraint => constraint.matchType.name === selectedConstraint.matchType.name,
  );

export const findSelectedOption = (selectedConstraintName, groupedOptions) => {
  const flatOptions = flatMap(groupedOptions, group => group.options);

  return flatOptions.find(option => option.value.matchType.name === selectedConstraintName);
};

// "Experiment" rule type has default constraint in "availableConstraints" instead of "defaultConstraint"
export const getDefaultConstraint = selectedPropertyType =>
  selectedPropertyType.defaultConstraint || selectedPropertyType.availableConstraints[0] || null;
