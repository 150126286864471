angular.module('webUi.ui.tagmanagement.tags.conditions.browserType', ['webUi.common.Utils'])
    .constant('CONDITION_ADD_TYPE', {
        browser: 'BROWSER',
        device: 'DEVICE'
    })
    .controller('TagmanagementTagFormBrowserTypeConditionController', ['$scope', '$timeout', 'TagmanagementService', 'CONDITION_ADD_TYPE',
        function TagmanagementTagFormBrowserTypeConditionController    ($scope,   $timeout,   TagmanagementService, CONDITION_ADD_TYPE) {

            const conditionClazz = 'BrowserTypeCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                include: true,
                browserVersions: [],
                deviceTypes: []
            };

            $scope.options = [{value: true, label: 'include only'}, {value: false, label: 'exclude'}];

            TagmanagementService.getBrowserTypes().then(function(types) {
                $scope.browserTypes = types;
            });

            $scope.removeBrowser = function(idx) {
                $scope.state.formCondition.browserVersions.splice(idx, 1);
            };

            $scope.addBrowser = function() {
                addCondition(CONDITION_ADD_TYPE.browser);
            };

            TagmanagementService.getDeviceTypes().then(function(types) {
                $scope.possibleDeviceTypes = types;
            });

            $scope.removeDeviceType = function(idx) {
                $scope.state.formCondition.deviceTypes.splice(idx, 1);
            };

            $scope.addDeviceType = function() {
                addCondition(CONDITION_ADD_TYPE.device);
            };

            function addCondition(conditionType) {
                const emptyCondition = {name: '', label:''};
                let selector;
                if (conditionType === CONDITION_ADD_TYPE.browser) {
                    $scope.state.formCondition.browserVersions.push(emptyCondition);
                    selector = '.browserSelect select';
                } else {
                    $scope.state.formCondition.deviceTypes.push(emptyCondition);
                    selector = '.deviceTypeSelect select';
                }

                // focus on next browser field
                $timeout(function() {
                    var browserSelects = $(selector);
                    $(_.last(browserSelects)).focus();
                });
            }

            $scope.state.beforeSubmit = function () {
                return ($scope.state.formCondition.browserVersions.length > 0 || $scope.state.formCondition.deviceTypes.length > 0);
            };

        }
    ]);

