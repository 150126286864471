
/**
 * @description Angular main module for Profiles -> Transformations
 * @memberOf webUi
 * @namespace webUi.ui.profiles.transformations
 */
angular.module('webUi.ui.profiles.transformations',
    [
        'webUi.ui.profiles.transformations.dashboard',
        'webUi.ui.profiles.transformations.form',
        'webUi.ui.profiles.transformations.view'
    ]
);