import React, { useContext, useState } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { formatNumber } from '~/common';
import { useAPI } from '~/common/ApiHooks';
import { Notification, Page } from '~/components';
import { Panel } from '~/components/src/Containers';
import DateRangePicker from '~/components/src/DateRangePicker';
import Heading from '~/components/src/Heading';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import StackedChart from '~/components/src/StackedChart';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import i18n from '~/i18n';
import DataExchangeGraph from '~/profiles/components/DataExchangeGraph/DataExchangeGraph';
import { fetchStepDailyStats, fetchStepData, fetchStepTotalStats } from '~/workflows/dataService';
import { JourneyContext } from '../JourneyContext';
import { mapStepStats } from '../Trigger/utils';

const ORIGINS = [
  { name: 'DEFAULT', label: i18n.t('workflow:journey.chart.default') as string },
  {
    name: 'HISTORIC_PROCESSING',
    label: i18n.t('workflow:journey.chart.historicProcessing') as string,
    description: i18n.t('workflow:journey.chart.historicProcessingDescription') as string,
  },
] as const;

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const StepStats = () => {
  const { stepId } = useParams() as { stepId: string };
  const { journeyData, journeyTree, actions } = useContext(JourneyContext);
  const { goToJourneyView } = actions;

  const journeyId = journeyData?.journeyId as string;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filter, setFilter] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const { startDate, endDate } = filter;
  const { data: stepData, error: stepFetchError } = useAPI(() => fetchStepData(stepId), [stepId]);

  const { data: hasStartTriggerParent } = useAPI(async () => {
    const startTriggerChildren = journeyTree.nodes.children;
    const hasStartTriggerParent = !!startTriggerChildren.find(({ nodeId }: { nodeId: string }) => nodeId === stepId);
    return hasStartTriggerParent;
  }, []);

  const { data: dailyStatsResponse, error: stepFetchDailyStatsError } = useAPI(() => {
    if (typeof hasStartTriggerParent === 'boolean') {
      const promises = [
        fetchStepDailyStats(
          journeyId,
          stepId,
          startDate.startOf('day').toDate().getTime(),
          endDate.endOf('day').toDate().getTime(),
          'DEFAULT',
          i18n.t('workflow:orchestration.steps.fetchDailyStatsFailed'),
        ),
      ];

      if (hasStartTriggerParent) {
        promises.push(
          fetchStepDailyStats(
            journeyId,
            stepId,
            startDate.startOf('day').toDate().getTime(),
            endDate.endOf('day').toDate().getTime(),
            'HISTORIC_PROCESSING',
            i18n.t('workflow:orchestration.steps.fetchDailyStatsFailed'),
          ),
        );
      }

      return Promise.all(promises);
    }

    return Promise.resolve([]);
  }, [journeyId, stepId, startDate, endDate, hasStartTriggerParent]);

  const { data: stepTotalStats, error: stepFetchTotalStatsError } = useAPI(
    () => fetchStepTotalStats(journeyId, stepId),
    [journeyId, stepId],
  );

  const tooltip = {
    formatter() {
      const header = `<p style="font-size:12px; font-weight:bold; margin: -4px 0 2px 2px;">${this.points[0].key}</p>`;
      const barValues = this.points
        .map(
          (point: any) => `<table style="padding:0">
                <tr>
                    <td style="color:${point.series.color};padding:2px">${point.series.name}: </td>
                    <td style="padding:2px"><b>${formatNumber(point.y)}</b></td>
                </tr>
            </table>`,
        )
        .join('');
      return header + barValues;
    },
    footerFormat: null,
    backgroundColor: '#F1F1F1',
    shared: true,
    useHTML: true,
    crosshairs: true,
    className: 'JourneyView-stepStatisticsChart',
  };

  const stepDailyStats = dailyStatsResponse?.[selectedTabIndex];

  if (stepFetchError || stepFetchDailyStatsError || stepFetchTotalStatsError) {
    return (
      <div className="my-4 p-4">
        <Notification kind="error" className="t-joStatisticsErrorMessage">
          {i18n.t('common:errors.genericError')}
        </Notification>
      </div>
    );
  }

  const isDataLoaded = stepData && stepDailyStats && stepTotalStats;

  if (!isDataLoaded) return <Spin />;

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setFilter({
      startDate,
      endDate,
    });
  };

  return (
    <Page excludeContainer>
      <div className="m-auto !w-3/4">
        <Heading
          title={stepData.name}
          crumbs={[
            {
              title: i18n.t('workflow:journey.back'),
              onClick: () => goToJourneyView(),
            },
          ]}
          testHook="stepStatsPageHeading"
        >
          <DateRangePicker
            className=""
            testHook="stepStatsDatePicker"
            startDate={startDate}
            endDate={endDate}
            handleDatesChange={handleDatesChange}
          />
        </Heading>
        {!isNil(stepTotalStats.totalProfilesActive) && (
          <div className="flex items-center gap-2">
            <Icons icon="profiles" className="h-8 w-8 text-blue-500" />
            <p className="PageTitleHeader-totalProfiles flex gap-2">
              {i18n.t('workflow:journey.chart.totalProfiles')}{' '}
              <span className="font-bold">{formatNumber(stepTotalStats.totalProfilesActive)}</span>
            </p>
          </div>
        )}
        {hasStartTriggerParent && (
          <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
            <TabList>
              {ORIGINS.map(origin => (
                <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                  {origin.label}
                  {'description' in origin && (
                    <>
                      <span className="ml-2"></span>
                      <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                    </>
                  )}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        )}
        <Panel className="mt-7">
          <div className="JourneyView-statistics--chart-container u-marginTopXl">
            <StackedChart
              className="t-stepStatisticsChart"
              data={mapStepStats(stepDailyStats, ORIGINS[selectedTabIndex].name)}
              tooltip={tooltip}
            />
          </div>
        </Panel>
        <DataExchangeGraph integrations={stepData.integrations} sourceId={stepData.stepId} type="orchestration" />
      </div>
    </Page>
  );
};

export default StepStats;
