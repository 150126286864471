import React, { Component } from 'react';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import queryString from 'query-string';
import { buildUrl, changeUrl, history } from '~/common';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { components } from '~/components/src/Table';
import * as fromModals from '~/modals';
import BtnIcon from '~/components/src/BtnIcon';
import {
  fetchEngagementIfNeeded,
  showDependantsContainer,
  deleteEngagement,
  hideDependantsContainer,
  changeDependantTypesSelectedTab,
} from '../engagements/actions';
import {
  isFetchingEngagement,
  getEngagementById,
  getPropertiesTableInfo,
  isDependantsContainerVisible,
  getSelectedId,
  getSelectedTab,
  getSelectedProperty,
} from '../engagements/selectors';
import i18n from '../../i18n';
import PropertiesList from '../components/PropertiesList';
import InteractionDetails from '../components/InteractionDetails';
import DependantsContainer from '../components/DependantsContainer';
import { ALLOWED_NUMBER_OF_PROPERTIES, ENGAGEMENT_USED_IN } from '../engagements/types';

const { DetailContainer } = components;

const createDetailContainerTitle = (engagement, propertyId, t) => {
  if (!engagement || !propertyId) {
    return '';
  }
  if (engagement.engagementId === propertyId) {
    return `${t('general.engagement')} - ${engagement.type || ''}`;
  }
  return `${t('general.property')} - ${propertyId}`;
};

const getElementName = (engagement, propertyId) => {
  if (!propertyId) {
    return '';
  }
  return engagement.engagementId === propertyId ? engagement.type : propertyId;
};

const getDetails = engagement => ({
  id: engagement.engagementId,
  description: engagement.description,
  dependantTypes: engagement.dependantTypes,
  usedProperties: engagement.usedProperties,
  properties: engagement.properties,
});

export class EngagementViewPage extends Component {
  componentDidMount() {
    this.props.fetchEngagementIfNeeded();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      t,
      propertyStateKey,
      engagementTableStateKey,
      propertiesList,
      canEditProfiles,
      engagementId,
      engagement,
      isFetchingEngagement,
      onDependantIconClick,
      onDependantsTabChange,
      onDependantsContainerClose,
      selectedPropertyId,
      dependantsContainerIsVisible,
      selectedTab,
      selectedProperty,
      onDeleteEngagement,
    } = this.props;

    const shouldGoBackToEngagementsList = !queryString.parse(window.location.search).back;

    const goBackLink = shouldGoBackToEngagementsList
      ? {
          title: t('general.back'),
          pathname: buildUrl('profiles/engagements'),
        }
      : {
          title: t('common:goBack'),
          onClick: () => {
            history.back();
          },
        };

    return isFetchingEngagement || !engagement ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={engagement.type} crumbs={[goBackLink]}>
          <BtnIcon
            icon="delete"
            disabled={!canEditProfiles || engagement.hasDependants}
            onClick={() => onDeleteEngagement(engagement)}
            testHook="delete"
            tooltip={t('view.deleteTooltip')}
          />
          <BtnIcon
            icon="edit"
            disabled={!canEditProfiles}
            onClick={() => changeUrl(`profiles/engagements/edit/${engagementId}`)}
            tooltip={t('common.edit')}
            color="blue"
          />
        </Heading>
        <InteractionDetails
          stateKey={engagementTableStateKey}
          usedIn={ENGAGEMENT_USED_IN}
          maxNumberOfProperties={ALLOWED_NUMBER_OF_PROPERTIES}
          interaction={getDetails(engagement)}
          onDependantIconClick={onDependantIconClick}
          selectedPropertyId={selectedPropertyId}
          t={t}
        />

        <PropertiesList
          t={t}
          stateKey={propertyStateKey}
          list={propertiesList}
          canEditProfiles={canEditProfiles}
          onDependantIconClick={onDependantIconClick}
          usedIn={ENGAGEMENT_USED_IN}
          interactionId={engagementId}
          selectedPropertyId={selectedPropertyId}
        />

        <DetailContainer
          onClose={onDependantsContainerClose}
          isVisible={dependantsContainerIsVisible}
          title={createDetailContainerTitle(engagement, selectedPropertyId, t)}
        >
          <DependantsContainer
            element={selectedProperty}
            elementName={getElementName(engagement, selectedPropertyId)}
            onTabChange={onDependantsTabChange}
            selectedTab={selectedTab}
            showContentDependants={false}
          />
        </DetailContainer>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const engagementId = ownProps?.params.engagementId;
  const { canEditProfiles } = ownProps;
  return {
    engagementId,
    canEditProfiles,
    engagement: getEngagementById(state, engagementId),
    isFetchingEngagement: isFetchingEngagement(state),
    engagementTableStateKey: `PROFILES/ENGAGEMENTS/${engagementId}/INFO`,
    propertyStateKey: `PROFILES/ENGAGEMENTS/${engagementId}/PROPERTIES`,
    propertiesList: getPropertiesTableInfo(state, engagementId),
    dependantsContainerIsVisible: isDependantsContainerVisible(state),
    selectedPropertyId: getSelectedId(state),
    selectedTab: getSelectedTab(state),
    selectedProperty: getSelectedProperty(state, engagementId, getSelectedId(state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const engagementId = ownProps?.params.engagementId;
  return {
    fetchEngagementIfNeeded: () => dispatch(fetchEngagementIfNeeded(engagementId)),
    onDependantIconClick: (engagementId, dependantType, propertyId) => {
      dispatch(showDependantsContainer(engagementId, dependantType, propertyId));
    },
    onDependantsTabChange: selectedTab => {
      dispatch(changeDependantTypesSelectedTab(selectedTab));
    },
    onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
    onDeleteEngagement: engagement => {
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('engagements:list.deleteModal.title'),
          message: i18n.t('engagements:list.deleteModal.message', { name: engagement.type }),
          onConfirm: () => dispatch(deleteEngagement(engagement.engagementId, engagement.hasDependants)),
        }),
      );
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate(['engagements']),
)(EngagementViewPage);
