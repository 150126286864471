import React from 'react';
import i18n from '~/i18n';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

interface FrequencySelectProps {
  className?: string;
  name: string;
  onChange: (option: { value: number; label: string }) => void;
  value: number;
}

const FrequencyTypes = [
  { value: 7, label: i18n.t('audiences:chart.frequency.seven') },
  { value: 30, label: i18n.t('audiences:chart.frequency.thirty') },
  { value: 90, label: i18n.t('audiences:chart.frequency.ninety') },
];

const FrequencySelect = ({ name, onChange, value, className }: FrequencySelectProps): React.ReactElement => (
  <SelectElement
    name={name}
    onChange={onChange}
    value={value}
    className={className}
    options={FrequencyTypes}
    isClearable={false}
  />
);

export default FrequencySelect;
