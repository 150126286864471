angular
    .module('webUi.ui.admin.urlBuilderTypeEdit', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.urlBuilderTypeEdit', {
                url: '/urlbuilder/type/edit/{typeId}',
                isReact: true,
            });
        },
    ]);
