import { getAngularService } from 'ReactAngular/react.service';
import customerFactsDataService from '~/profiles/customerFacts/dataService';

export const getEngagements = document => {
  const EngagementService = getAngularService(document, 'EngagementService');
  return EngagementService.getEngagements().then(result =>
    result.map(item => ({
      id: item.engagementId,
      name: item.type,
      properties: item.properties,
    })),
  );
};

export const getCustomerFacts = () =>
  customerFactsDataService.getCustomerFacts().then(result =>
    result.map(item => ({
      id: item.customerFactId,
      name: item.name,
      properties: item.properties,
    })),
  );

export const getSyncPartners = document => {
  const CollectorService = getAngularService(document, 'CollectorService');
  return CollectorService.getSyncPartners().then(result =>
    result.map(item => ({
      optionId: item.id,
      optionLabel: item.name,
    })),
  );
};
