export default {
  SCHEDULE_EXPORTS_LIMIT: 10,
  DATE_RANGE: 90,
  SCHEDULE_EXPORTS_LIMIT_TOAST_ID: 'SCHEDULE_EXPORTS_LIMIT_TOAST_ID',
  REQUEST_TYPES: {
    SCHEDULED: 'SCHEDULED',
    MANUAL: 'MANUAL',
    MANUAL_REMOTE: 'MANUAL_REMOTE',
  },
  EXPORT_TYPES: {
    ENGAGEMENTS: 'ENGAGEMENTS',
    SESSIONS: 'SESSIONS',
    TRACKING_EVENTS_STREAM: 'TRACKING_EVENTS_STREAM',
  },
  LANDING_URL_PARAMS: 'LANDING_URL_PARAMS',
  PRE_DEFINED_GA_PARAMS: ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'],
  FREQUENCY_TYPES: {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
  },
  CONNECTION_TYPES: {
    SFTP: 'SFTP',
    FTP: 'FTP',
    FTPS: 'FTPS',
  },
  LOGIN_TYPES: {
    ANONYMOUS: 'ANONYMOUS',
    NORMAL: 'NORMAL',
  },
};
