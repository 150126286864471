import * as Yup from 'yup';
import { round } from 'lodash';
import i18n from '~/i18n';
import { WITHOUT } from './constants';
import { Model, ModelDetail, ModelProperty, SnapshotDetail } from './types';
import { SnapshotProperty } from '../types';

export const modelInitialValues = {
  modelName: '',
  snapshotId: null,
  hasAdvancedSettings: false,
  predictions: [{ name: '', criterias: [{ rules: [{ property: '', constraint: '', filterValue: '' }] }] }],
  inputsToInclude: [],
  advancedSettings: {
    trainForMaxEpochs: 30,
    predictionDays: 14,
    stopIfNotImprovedAfterEpochs: 3,
    targetLabelMode: WITHOUT.value,
    numObservationsPerClass: 20000,
    keepUnlabeledSequences: true,
    isUnlabeledFromSingleClass: true,
    breakSequencesOnTarget: false,
  },
};

const inputsToIncludeValidation = (maxInputsForAIModel: number) =>
  Yup.array()
    .max(
      maxInputsForAIModel,
      maxInputsForAIModel
        ? i18n.t('ai:validations.maxInputProperties', { max: maxInputsForAIModel })
        : i18n.t('ai:validations.predictivePropertiesLimit'),
    )
    .required(i18n.t('validation:validation.required'));

export const modelValidationSchema = (maxPredictionDays = 0, maxInputsForAIModel = 0): Yup.ObjectSchema<any> =>
  Yup.object().shape({
    modelName: Yup.string().trim().required(i18n.t('validation:validation.required')),
    snapshotId: Yup.string().nullable().required(i18n.t('validation:validation.required')),
    predictions: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().trim().required(i18n.t('validation:validation.required')),
          criterias: Yup.array()
            .of(
              Yup.object().shape({
                rules: Yup.array().of(
                  Yup.object().shape({
                    property: Yup.string().required(i18n.t('validation:validation.required')),
                    constraint: Yup.string().required(i18n.t('validation:validation.required')),
                    filterValue: Yup.string().required(i18n.t('validation:validation.required')),
                  }),
                ),
              }),
            )
            .min(1, i18n.t('ai:validations.minOneRule')),
        }),
      )
      .min(1, i18n.t('ai:validations.minOnePrediction')),
    inputsToInclude: inputsToIncludeValidation(maxInputsForAIModel),
    hasAdvancedSettings: Yup.boolean(),
    advancedSettings: Yup.object().when('hasAdvancedSettings', {
      is: true,
      then: Yup.object().shape({
        trainForMaxEpochs: Yup.number().required(i18n.t('validation:validation.required')),
        stopIfNotImprovedAfterEpochs: Yup.number().required(i18n.t('validation:validation.required')),
        targetLabelMode: Yup.string(),
        predictionDays: (maxPredictionDays
          ? Yup.number()
              .required(i18n.t('validation:validation.required'))
              .max(
                maxPredictionDays,
                i18n.t('ai:validations.maxFuturePredictionLength', {
                  max: maxPredictionDays,
                }),
              )
          : Yup.number().required(i18n.t('validation:validation.required'))
        ).min(1, i18n.t('ai:validations.minFuturePredictionLength', { min: 1 })),
        numObservationsPerClass: Yup.number().required(i18n.t('validation:validation.required')),
        keepUnlabeledSequences: Yup.boolean(),
        isUnlabeledFromSingleClass: Yup.boolean(),
        breakSequencesOnTarget: Yup.boolean(),
      }),
    }),
  });

export const mapModel = (model: ModelDetail): ModelDetail => ({
  ...model,
  inputsToInclude: model.inputsToInclude.map(input => ({ label: input, value: input })),
});

export const mapSnapshot = (snapshot: SnapshotDetail): any =>
  (snapshot.properties?.map((property: SnapshotProperty) => ({
    label: property.name,
    value: property.name,
    isNumeric: 'stats' in property,
    ...property,
  })) as ModelProperty[]) || [];

export const prepareModelPayload = (model: Model): Model => ({
  modelId: model.modelId,
  modelName: model.modelName,
  snapshotId: model.snapshotId,
  predictions: model.predictions,
  hasAdvancedSettings: model.hasAdvancedSettings,
  advancedSettings: model.advancedSettings,
  inputsToInclude: model.inputsToInclude.map((property: any) => property.value),
});

export const decimalToPercent = (value: number) => {
  if (value > 0) return round(value * 100, 2);
  return 0;
};
