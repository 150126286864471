export const VALUESETS_FETCH_START = 'app/admin/urlBuilder/VALUESETS_FETCH_START';
export const VALUESETS_FETCH_SUCCESS = 'app/admin/urlBuilder/VALUESETS_FETCH_SUCCESS';
export const VALUESETS_FETCH_FAIL = 'app/admin/urlBuilder/VALUESETS_FETCH_FAIL';

export const VALUESET_FETCH_START = 'app/admin/urlBuilder/VALUESET_FETCH_START';
export const VALUESET_FETCH_SUCCESS = 'app/admin/urlBuilder/VALUESET_FETCH_SUCCESS';
export const VALUESET_FETCH_FAIL = 'app/admin/urlBuilder/VALUESET_FETCH_FAIL';

export const VALUESETS_SAVE_START = 'app/admin/urlBuilder/VALUESETS_SAVE_START';
export const VALUESETS_SAVE_SUCCESS = 'app/admin/urlBuilder/VALUESETS_SAVE_SUCCESS';
export const VALUESETS_SAVE_FAIL = 'app/admin/urlBuilder/VALUESETS_SAVE_FAIL';

export const VALUESETS_EDIT_START = 'app/admin/urlBuilder/VALUESETS_EDIT_START';
export const VALUESETS_EDIT_SUCCESS = 'app/admin/urlBuilder/VALUESETS_EDIT_SUCCESS';
export const VALUESETS_EDIT_FAIL = 'app/admin/urlBuilder/VALUESETS_EDIT_FAIL';

export const VALUESETS_DELETE_START = 'app/admin/urlBuilder/VALUESET_DELETE_START';
export const VALUESETS_DELETE_SUCCESS = 'app/admin/urlBuilder/VALUESET_DELETE_SUCCESS';
export const VALUESETS_DELETE_FAIL = 'app/admin/urlBuilder/VALUESET_DELETE_FAIL';

export const VALUESETS_VALIDATION_SHOW = 'app/admin/urlBuilder/VALUESETS_VALIDATION_SHOW';
export const VALUESETS_VALIDATION_HIDE = 'app/admin/urlBuilder/VALUESETS_VALIDATION_HIDE';
