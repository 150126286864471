import { ITEM_TYPES } from '~/profiles/audiencesTree';

const itemActions = (item, dispatchProps, ownProps) => {
  const { t } = ownProps;
  if (item.type === ITEM_TYPES.SELECTION) {
    let array = [
      {
        name: t('audiences:list.actions.view'),
        tooltip: t('audiences:list.actions.view'),
        isDisabled: false,
        icon: 'view',
        onClick: () => dispatchProps.onViewAudience(item),
        testHook: 'view',
      },
    ];
    if (ownProps.canEdit) {
      array = [
        ...array,
        {
          name: t('audiences:list.actions.edit'),
          tooltip: t('audiences:list.actions.edit'),
          isDisabled: false,
          icon: 'edit',
          onClick: () => dispatchProps.onEditAudience(item),
          testHook: 'edit',
        },
        {
          name: t('audiences:list.actions.delete'),
          tooltip: t('audiences:list.actions.delete'),
          isDisabled: false,
          icon: 'delete',
          onClick: () => dispatchProps.onDeleteAudience(item),
          testHook: 'delete',
          type: 'delete',
        },
      ];
    }
    return array;
  }

  if (ownProps.canEdit) {
    return [
      {
        name: t('audiences:list.actions.delete'),
        tooltip: item.hasChildren
          ? t('audiences:list.actions.deleteGroupDisabled')
          : t('audiences:list.actions.delete'),
        icon: 'delete',
        isDisabled: item.hasChildren,
        onClick: () => dispatchProps.onDeleteGroup(item),
        testHook: 'delete',
        type: 'delete',
      },
    ];
  }
  return [];
};

export default itemActions;
