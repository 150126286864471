import React, { useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { buildUrl } from '~/common';
import { Heading, Notification, Page } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { createExperiment } from './dataService';
import { ExperimentGroups } from './components/ExperimentGroups';
import { DEFAULT_GROUPS } from './constants';
import { TNewExperiment } from './types';

const initialValues = {
  name: '',
  experimentGroups: DEFAULT_GROUPS,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  experimentGroups: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().trim().required(i18n.t('validation:validation.required')),
      weight: Yup.number().min(0).max(100).required(i18n.t('validation:validation.required')),
      controlGroup: Yup.bool(),
    }),
  ),
});

const NewExperiment = () => {
  const navigate = useNavigate();
  const [isCreatingExperiment, setIsCreatingExperiment] = useState(false);

  const goToExperimentsList = () => navigate(buildUrl('tagmanagement/experiments/dashboard'), { replace: true });

  const handleCreateExperiment = async (values: TNewExperiment) => {
    setIsCreatingExperiment(true);
    await createExperiment(values);
    showSuccess({ header: i18n.t('experiments:notifications.experimentCreateSuccess') });
    setIsCreatingExperiment(false);
    goToExperimentsList();
  };

  return (
    <Page>
      <Heading
        title={i18n.t('experiments:form.create')}
        crumbs={[
          {
            title: i18n.t('experiments:back'),
            onClick: () => {
              goToExperimentsList();
            },
          },
        ]}
      ></Heading>

      <Formik onSubmit={handleCreateExperiment} validationSchema={validationSchema} initialValues={initialValues}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              className="w-1/3"
              name="name"
              as={FormikInputField}
              label={i18n.t('experiments:form.name')}
              placeholder={i18n.t('experiments:form.placeholder')}
              errorText={errors.name}
              autoFocus={true}
            />
            <p className="mt-6 pb-2 text-xl text-gray-600">{i18n.t('experiments:form.expGroups')}</p>

            <FieldArray name="experimentGroups" component={ExperimentGroups} />

            <Notification className="mt-6" kind="information">
              {i18n.t('experiments:messages.confirmNote')}
            </Notification>
            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={() => goToExperimentsList()}
              className="m-0 mt-4"
              confirmText={i18n.t('experiments:form.create')}
              testHook="createExperiment"
              isConfirmEnabled={!isCreatingExperiment}
            />
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default NewExperiment;
