import i18n from '~/i18n';
import { showError } from '~/notificationCenter';
import audienceActivityActionTypes from './actionTypes';
import { getAudienceStats, getPartnerStats } from './service';

const fetchAudienceStats = (segmentId, startDate, endDate) => dispatch => {
  dispatch({ type: audienceActivityActionTypes.FETCH_STATS.pending });

  return getAudienceStats(segmentId, startDate, endDate)
    .then(payload => {
      const stats = payload.data;
      dispatch({
        type: audienceActivityActionTypes.SET_AUDIENCE_STATS,
        data: stats,
      });
      dispatch({
        type: audienceActivityActionTypes.FETCH_STATS.fulfilled,
      });
    })
    .catch(error => {
      dispatch({ type: audienceActivityActionTypes.FETCH_STATS.rejected });

      if (error.status && error.status.error.errorType) {
        dispatch(
          showError({
            header: i18n.t('partners:form.notification.error'),
            body: error.status.error.message,
          }),
        );
      }
    });
};

const fetchPartnerStats = (partnerId, partnerType, sourceId, startDate, endDate) => dispatch => {
  dispatch({ type: audienceActivityActionTypes.FETCH_PARTNER_STATS.pending });

  return getPartnerStats(partnerId, partnerType, sourceId, startDate, endDate)
    .then(payload => {
      const { stats } = payload.data;
      const { partnerErrorCodeDocUrl } = payload.data?.statsMetadata;

      dispatch({
        type: audienceActivityActionTypes.SET_PARTNER_STATS,
        data: stats,
      });
      dispatch({
        type: audienceActivityActionTypes.SET_DOCUMENTATION_URL,
        data: partnerErrorCodeDocUrl,
      });
      dispatch({
        type: audienceActivityActionTypes.FETCH_PARTNER_STATS.fulfilled,
      });
    })
    .catch(error => {
      dispatch({ type: audienceActivityActionTypes.FETCH_PARTNER_STATS.rejected });

      if (error.status && error.status.error.errorType) {
        dispatch(
          showError({
            header: i18n.t('partners:form.notification.error'),
            body: error.status.error.message,
          }),
        );
      }
    });
};

export { fetchAudienceStats, fetchPartnerStats };
