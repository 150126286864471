import React from 'react';
import i18n from '~/i18n';
import cx from 'classnames';
import { SelectedConnector } from './types';
import StatusTag from '../StatusTag';
import './style.scss';

interface ConnectorPickerCardProps {
  image: string;
  title: string;
  onSelect: (selectedConnector: SelectedConnector) => void;
  setIsPickerVisible: (b: boolean) => void;
  testHook: string;
  partnerNumber: number;
  partnerType: string;
  partnerName: string;
  partnerId?: string;
  activated?: boolean;
  supportsEventListeners?: boolean;
  status?: string;
  isDisabled?: boolean;
  isEventType?: boolean;
}

const ConnectorPickerCard = (props: ConnectorPickerCardProps): React.ReactElement => {
  const {
    image,
    title,
    onSelect,
    setIsPickerVisible,
    testHook,
    partnerNumber,
    partnerId,
    partnerType,
    partnerName,
    supportsEventListeners,
    status,
    activated = true,
    isDisabled = false,
    isEventType = false,
  } = props;
  const disableConnector = !activated || isDisabled;

  return (
    <div
      className={cx(`t-${testHook} ConnectorPickerCard`, {
        'ConnectorPickerCard-disabled': isDisabled,
      })}
      onClick={() => {
        const selectedConnector: SelectedConnector = {
          partnerId,
          partnerType,
          partnerNumber,
          partnerName,
          supportsEventListeners,
          activated,
        };

        onSelect(selectedConnector);
        setIsPickerVisible(false);
      }}
    >
      {status && <StatusTag text={status} />}
      <img
        src={image}
        className={cx('ConnectorPickerCard-logo', {
          'ConnectorPickerCard-logo--deactivated': !activated || disableConnector,
        })}
      />
      <div
        className={cx('ConnectorPickerCard-info', {
          'ConnectorPickerCard-info--deactivated': !activated || disableConnector,
        })}
      >
        <p className="ConnectorPickerCard-info--name" title={title}>
          {title}
        </p>
        {!isEventType && (
          <p className="ConnectorPickerCard-info--number">{i18n.t('connectors:partnerNumber', { partnerNumber })}</p>
        )}
      </div>
    </div>
  );
};

export default ConnectorPickerCard;
