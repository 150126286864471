import { useEffect, useState, useRef } from 'react';

export const useCooldown = seconds => {
  const intervalIdRef = useRef(null);

  const stopCooldown = () => {
    if (intervalIdRef.current) {
      window.clearInterval(intervalIdRef.current);
    }
  };

  useEffect(() => stopCooldown, []);

  const [secondsLeft, setSecondsLeft] = useState(0);

  const startCooldown = () => {
    stopCooldown();
    setSecondsLeft(seconds);

    intervalIdRef.current = window.setInterval(() => {
      setSecondsLeft(secondsLeft => {
        if (secondsLeft <= 0) {
          stopCooldown();
          return secondsLeft;
        }

        return secondsLeft - 1;
      });
    }, 1000);
  };

  const isCooldownInProgress = secondsLeft > 0;

  return { secondsLeft, startCooldown, isCooldownInProgress };
};

export const secondsToTime = seconds => moment(seconds * 1000).format('mm:ss');
