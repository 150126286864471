angular.module('webUi.service.remote.beacons', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('BeaconService', [
        '$q',
        'Restangular',
        'Utils',
        function ($q, Restangular, Utils) {

            var BASE_PATH = 'remote/beacons';

            var service = {

                getBasePath: function () {
                    return BASE_PATH;
                },

                /**
                 *
                 * @returns {*}
                 */
                getBeacons: function () {

                    var beaconsPromise = $q.defer();

                    Restangular.all(BASE_PATH).getList().then(
                        function onSuccess(beacons) {
                            if (beacons) {
                                beacons = Utils.sanitizeRestangularAll(beacons);
                                beaconsPromise.resolve(beacons);
                            }
                        },
                        function onError() {
                        }
                    );

                    return beaconsPromise.promise;
                },

                /**
                 *
                 * @param beaconId
                 * @returns {*}
                 */
                getBeacon: function (beaconId) {

                    var beaconPromise = $q.defer();

                    Restangular.one(BASE_PATH, beaconId).get().then(
                        function onSuccess(beacon) {
                            if (beacon) {
                                beacon = Utils.sanitizeRestangularOne(beacon);
                                beaconPromise.resolve(beacon);
                            }
                        },
                        function onError() {
                        }
                    );

                    return beaconPromise.promise;
                },

                /**
                 *
                 * @param createCommand
                 * @returns {*}
                 */
                createBeacon: function (createCommand) {

                    var beaconPromise = $q.defer();

                    Restangular.all(BASE_PATH).post(createCommand).then(
                        function onSuccess(beaconId) {
                            if (beaconId) {
                                beaconId = Utils.sanitizeRestangularOne(beaconId);
                                beaconPromise.resolve(beaconId);
                            }
                        }
                    );

                    return beaconPromise.promise;
                },

                /**
                 * Updates a beacon
                 *
                 * @param beaconId
                 * @param beaconName
                 * @returns {*}
                 */
                updateBeacon: function (beaconId, beaconName) {

                    var _beacon = {
                        beaconId: beaconId,
                        beaconName: beaconName
                    };

                    var beaconPromise = $q.defer();

                    Restangular.all(BASE_PATH + '/' + beaconId).customPUT(_beacon).then(
                        function onSuccess() {
                            beaconPromise.resolve({'beaconId': beaconId});
                        },
                        function onError() {
                        }
                    );

                    return beaconPromise.promise;

                },

                /**
                 *
                 * @param beaconId
                 * @returns {*}
                 */
                deleteBeacon: function (beaconId) {
                    return Restangular.one(BASE_PATH, beaconId).remove();
                }
            };

            return service;
        }]
    );
