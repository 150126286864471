/**
 * @description Service coordinating the URL Builder Calls
 * @class URLBuilderService
 * @memberOf webUi.service.remote.urlbuilder
 * @namespace webUi.service.remote.urlbuilder
 */
angular.module('webUi.service.remote.urlbuilder',
    [
        'restangular',
        'ui.bootstrap',
        'webUi.service'
    ]
)
    .factory('URLBuilderService', [
        '$q',
        '$uibModal',
        'Restangular',
        'Utils',
        'ValidService',
        'PubSubService',
        'URLBuilderSharedService',
        /**
     * @function URLBuilderService
     * @memberOf webUi.service.remote.urlbuilder
     *
     * @param $q
     * @param {Restangular} Restangular
     * @param {Utils} Utils
     *
     * @returns {URLBuilderService}
     *
     */
        function URLBuilderService($q, $uibModal, Restangular, Utils, ValidService, PubSubService, URLBuilderSharedService) {
            var BASE_PATH = 'remote/url-builder/';

            var TREE_STATES = [
                null,        //level 0
                'site.tagmanagement.urlbuilder.campaign.view'      //level 1
            ];

            var TREE_STATES_ID = [
                null,        //level 0
                'campaignId'      //level 1
            ];


            /**
         * @returns {String}
         */
            var getBasePath = function () {
                return BASE_PATH;
            };



            /**
         * @description Gets all Campaigns
         * @returns {*}
         */
            var getCampaigns = function () {
                var campaignPromise = $q.defer();

                Restangular.all(BASE_PATH + 'campaigns').getList().then(
                    function onSuccess(response) {
                        if (response) {
                            campaignPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return campaignPromise.promise;
            };

            /**
          * @description Gets all Campaigns for a given typeid
          * @returns {*}
          */
            var getCampaignsByType = function (typeId) {
                var campaignPromise = $q.defer();

                if (!typeId) {
                    campaignPromise.resolve({});
                    return campaignPromise.promise;
                }

                Restangular.all(BASE_PATH + 'campaigns/type/' + typeId).getList().then(
                    function onSuccess(response) {
                        if(response) {
                            campaignPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return campaignPromise.promise;
            };

            /**
          * @description Gets a Campaign by id
          * @returns {*}
          */
            var getCampaign = function (campaignId) {
                var campaignPromise = $q.defer();

                if (!campaignId) {
                    campaignPromise.resolve({});
                    return campaignPromise.promise;
                }

                Restangular.one(BASE_PATH + 'campaigns', campaignId).get().then(
                    function onSuccess(response) {
                        if(response) {
                            campaignPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return campaignPromise.promise;
            };

            /**
         * @description Creates a campaign
         * @param {Object} createCommand
         * @returns {*}
         */
            var addCampaign = function (createCommand) {
                var campaignPromise = $q.defer();
                Restangular.all(BASE_PATH + 'campaigns').post(createCommand).then(
                    function onSuccess(response) {
                        if (response) {
                            campaignPromise.resolve(response);
                        }
                    },
                    function onError() { }
                );
                return campaignPromise.promise;
            };

            /**
         * @description Updates a campaign
         * @param {Object} updateCommand
         * @returns {*}
         */
            var updateCampaign = function (updateCommand) {

                var campaignPromise = $q.defer();
                Restangular.all(BASE_PATH + 'campaigns/'+ updateCommand.campaignId).customPUT(updateCommand).then(
                    function onSuccess() {
                        campaignPromise.resolve({});

                    },
                    function onError() { }
                );
                return campaignPromise.promise;
            };

            /**
         * @description Deletes a campaign
         * @param campaignId
         * @returns {*}
         */
            var deleteCampaign = function deleteCampaign(campaignId) {

                var campaignPromise = $q.defer();

                Restangular.one(BASE_PATH + 'campaigns', campaignId).remove().then(
                    function onSuccess() {
                        campaignPromise.resolve({});
                    },
                    function onError() {
                    }
                );
                return campaignPromise.promise;
            };


            /**
          * @description Gets Mappings by campaignId
          * @returns {*}
          */
            var getMappings = function (campaignId) {
                var mappingPromise = $q.defer();

                if (!campaignId) {
                    mappingPromise.resolve({});
                    return mappingPromise.promise;
                }

                Restangular.all(BASE_PATH + 'mappings' + '/' + campaignId).getList().then(
                    function onSuccess(response) {
                        if (response) {
                            mappingPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {
                    }
                );
                return mappingPromise.promise;

            };

            var getColumnTypes = function(){
                var columnTypes = $q.defer();
                Restangular.all(BASE_PATH + 'column-types').getList().then(
                    function onSuccess(response) {
                        if (response) {
                            var _response = Restangular.stripRestangular(response);
                            columnTypes.resolve(_response);
                        }
                    },
                    function onError() {}
                );
                return columnTypes.promise;
            };

            var getColumns = function() {
                var columnTypes = $q.defer();
                Restangular.all(BASE_PATH + 'columns').getList().then(
                    function onSuccess(response) {
                        if (response) {
                            var _response = Restangular.stripRestangular(response);
                            columnTypes.resolve(_response);
                        }
                    },
                    function onError() {
                    }
                );
                return columnTypes.promise;
            };

            var getTypes = function() {
                var types = $q.defer();
                Restangular.all(BASE_PATH + 'types').getList().then(
                    function onSuccess(response) {
                        if (response) {
                            types.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {
                    }
                );
                return types.promise;
            };

            var getType = function(typeId){
                var type = $q.defer();
                Restangular.one(BASE_PATH + 'types', typeId).get().then(
                    function onSuccess(response){
                        if (response) {
                            type.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {

                    }
                );
                return type.promise;
            };

            /**
         * @description Creates a mapping
         * @param {Object} createCommand
         * @returns {*}
         */
            var addMapping = function(createCommand) {

                var request = {};

                if (createCommand.campaignId) {
                    request.campaignId = createCommand.campaignId;
                }

                if (createCommand.urlBuilderTypeId) {
                    request.urlBuilderTypeId = createCommand.urlBuilderTypeId;
                }

                if (createCommand.mappingData) {
                    request.mappingData = createCommand.mappingData;
                }

                var mappingPromise = $q.defer();

                Restangular.all(BASE_PATH + 'mappings').post(request).then(
                    function onSuccess(response) {
                        mappingPromise.resolve(response);
                    },
                    function onError() {
                    }
                );
                return mappingPromise.promise;
            };

            /**
         * @description Updates a mapping
         * @param {Object} updateCommand
         * @returns {*}
         */
            var updateMapping = function updateMapping(updateCommand) {

                var mappingPromise = $q.defer();

                var request = {};

                if (updateCommand.mappingId) {
                    request.mappingId = updateCommand.mappingId;
                } else {
                    mappingPromise.resolve({'error': 'MappingId ID not specified'});
                    return mappingPromise.promise;
                }

                if (updateCommand.campaignId) {
                    request.campaignId = updateCommand.campaignId;
                }

                if (updateCommand.mappingKey) {
                    request.mappingKey = updateCommand.mappingKey;
                }
                if (updateCommand.mappingData) {
                    request.mappingData = updateCommand.mappingData;
                }

                /*jshint es5: true */
                Restangular.all(BASE_PATH + 'mappings').customPUT(request).then(
                    function onSuccess() {
                        mappingPromise.resolve({'mappingId': request.mappingId});
                    },
                    function onError() {
                        mappingPromise.reject({'error': 'Mapping not updated'});
                    }
                );

                return mappingPromise.promise;
            };

            /**
         * @description Deletes a mapping
         * @param mappingId
         * @returns {*}
         */
            var deleteMapping = function deleteMapping(campaignId, mappingId) {

                var mappingPromise = $q.defer();

                Restangular.one(BASE_PATH + 'campaigns', campaignId).one('mappings', mappingId).remove().then(
                    function onSuccess() {
                        mappingPromise.resolve({});
                    },
                    function onError() {}
                );
                return mappingPromise.promise;
            };

            /**
          * @description Gets a list of predefined value sets for the site
          * @returns {*}
          */
            var getPredefinedValueSets = function () {

                var predefinedValueSetsPromise = $q.defer();

                Restangular.one(BASE_PATH + 'predefined-values').get().then(
                    function onSuccess(response) {
                        if(response) {
                            predefinedValueSetsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return predefinedValueSetsPromise.promise;
            };

            /**
         * Returns url builder types and campaigns in a tree structure
         * @returns {$q}
         */
            var getTreeData = function () {
                var tree = $q.defer();

                var restangularCall = Restangular.all('remote/url-builder/tree');
                restangularCall.getList().then(function (result) {
                    result = Restangular.stripRestangular(result);
                    tree.resolve(Utils.parseNodes(result, TREE_STATES, TREE_STATES_ID));
                });
                return tree.promise;
            };

            var canAddCampaign = function(urlBuilderTypes, columns) {
                return !ValidService.isEmpty(urlBuilderTypes) && !ValidService.isEmpty(columns);
            };

            var addNewCampaign = function addNewCampaign(urlBuilderTypes, columns, columnTypes, urlBuilderPredefinedValueSets) {
                //first parameter is the campaign, in this case null
                var modalDismissed = URLBuilderSharedService.openNewCampaignPopup(null, urlBuilderTypes, columns, columnTypes, urlBuilderPredefinedValueSets);

                modalDismissed.result.then(function (campaign) {
                    if (campaign) {
                        addCampaign(campaign).then(function (campaignId) {
                            PubSubService.publishTypeCreated(PubSubService.TYPES.REMOTE.URLB_CAMPAIGN, {id: campaignId});
                        });
                    }
                });
            };

            /**
         * RMP API
         * @type {UrlBuilderConfigService}
         */
            var service = {
                getBasePath : getBasePath,

                getCampaign : getCampaign,
                getCampaigns : getCampaigns,
                getCampaignsByType : getCampaignsByType,
                addCampaign : addCampaign,
                updateCampaign : updateCampaign,
                deleteCampaign : deleteCampaign,

                // Campaign-column mappings
                getMappings : getMappings,
                addMapping : addMapping,
                updateMapping : updateMapping,
                deleteMapping : deleteMapping,

                // Utility methods for retrieving the columns and types configured in Admin/URL Builder
                getColumnTypes : getColumnTypes,
                getColumns : getColumns,
                getTypes : getTypes,
                getType : getType,

                getPredefinedValueSets: getPredefinedValueSets,
                // Left menu tree (types & campaigns)
                getTreeData: getTreeData,
                canAddCampaign:canAddCampaign,
                addNewCampaign: addNewCampaign
            };
            return service;
        }
    ]);
