angular.module('webUi.service.profiles.segments', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('SegmentService', ['Restangular', '$q', 'Utils',
        function SegmentService( Restangular, $q, Utils,) {

            // The base path through which all calls should go
            var BASE_PATH = 'profiles/';

            var service = {
                /**
                 * @returns {List} Promise of the segments
                 */
                getSegments: function () {
                    var segmentsDeferred = $q.defer();

                    Restangular.all(BASE_PATH + 'segments').getList().then(function (segments) {
                        if (segments) {
                            segments = Utils.sanitizeRestangularAll(segments);
                            segmentsDeferred.resolve(segments);
                        }
                    });
                    return segmentsDeferred.promise;
                },
            };

            return service;
        }
    ]);
