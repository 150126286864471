import { combineReducers } from 'redux';
import groups from './groups';
import templates from './templates';
import variants from './variants';

// Common
export const TAGTEMPLATES_TRANSLATIONS_KEY = 'tagTemplates';

// Default Support Reducer
export default combineReducers({
  groups,
  templates,
  variants,
});
