import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const RightPanel = props => <div className="RightPanel">{props.children}</div>;

RightPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RightPanel;
