import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'recompose';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { components, enhancements, DEFAULT_SIZE } from '~/components/src/Table';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ t }) => <Empty header={t('variantsList.empty.header')} body={t('variantsList.empty.body')} />;

const NoResults = ({ t }) => (
  <Empty header={t('variantsList.noResults.header')} body={t('variantsList.noResults.body')} />
);

const WIDTHS = {
  ORDER: '10%',
  NAME: '30%',
  PRIORITY: '10%',
  PRIVATE: '10%',
  TYPE: '15%',
  CLIENT: '25%',
};

export const TagVariantsList = ({ list, stateKey, t, variantUrl }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader
          width={WIDTHS.ORDER}
          sortKey="sortingOrder"
          title={t('variantsList.sortingOrder')}
          sortFn={numberSort('sortingOrder')}
        />
        <SortableHeader width={WIDTHS.NAME} sortKey="name" title={t('variantsList.name')} />
        <SortableHeader
          width={WIDTHS.PRIORITY}
          sortKey="defaultPriority"
          title={t('variantsList.defaultPriority')}
          sortFn={numberSort('defaultPriority')}
        />
        <SortableHeader width={WIDTHS.PRIVATE} sortKey="privateMode" title={t('variantsList.privateMode')} />
        <SortableHeader width={WIDTHS.TYPE} sortKey="executionType" title={t('variantsList.executionType')} />
        <SortableHeader width={WIDTHS.CLIENT} sortKey="clientName" title={t('variantsList.clientName')} />
      </Row>
      {list.map(item => (
        <Row key={item.variantId} className={cx({ 'Table-row--removed': item.deleted })}>
          <Cell style={{ width: WIDTHS.ORDER }}>{item.sortingOrder}</Cell>
          <Cell style={{ width: WIDTHS.NAME }}>
            <Link href={variantUrl(item)}>{item.name}</Link>
          </Cell>
          <Cell style={{ width: WIDTHS.PRIORITY }}>{item.defaultPriority}</Cell>
          <Cell style={{ width: WIDTHS.PRIVATE }}>
            {item.privateMode ? t('common:checkbox.truthy') : t('common:checkbox.falsy')}
          </Cell>
          <Cell style={{ width: WIDTHS.TYPE }}>{item.executionType && item.executionType.label}</Cell>
          <Cell style={{ width: WIDTHS.CLIENT }}>{item.clientName || t('variantsList.allClients')}</Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

TagVariantsList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  variantUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: DEFAULT_SIZE }),
)(TagVariantsList);
