import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { components } from '~/components/src/Table';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import Detail from '~/components/src/Form/Detail/Default';
import Link from '~/components/src/Link';
import SortableHeader from '~/components/src/Table/sortableHeader';
import RowTwoFactorAuth from '~/user/components/RowTwoFactorAuth';
import { Panel } from '~/components/src/Containers';
import { buildUrl } from '~/common';

const WIDTHS = {
  TYPE: 'u-size3of10',
  PHONE: 'u-size3of10',
  DEFAULT: 'u-size2of10',
};

const authenticationType = {
  SMS: 'SMS',
  GOOGLE_AUTH: 'GOOGLE_AUTH',
};

const { Table, Row } = components;

const props = (className, sortKey, title) => ({
  className,
  sortKey,
  title,
});

const ShowChangePasswordModal = ({ showChangePasswordModal, user }) => {
  useEffect(() => {
    showChangePasswordModal(user);
  }, []);
  return null;
};

export const SecuritySettings = ({
  t,
  user,
  onAddSmsToken,
  onRemoveSmsToken,
  onRemoveTimebasedToken,
  onAddTwoFactorAuth,
  stateKey,
  makeDefault2FA,
  showChangePasswordModal,
  unlinkOpenId,
}) => (
  <>
    <Routes>
      <Route
        path="change-password"
        element={<ShowChangePasswordModal showChangePasswordModal={showChangePasswordModal} user={user} />}
      />
    </Routes>
    <div>
      <Heading title={t('settings:securitySettings.label')} />
      {user?.clientPojo?.externalAuthenticationEnabled ? (
        <Notification kind="information">
          <p>{t('settings:securitySettings.idpSecuritySettingsPlaceholder')}</p>
        </Notification>
      ) : (
        <>
          <Detail label={t('settings:securitySettings.password')} value={user.password}>
            <Link href={buildUrl('user/settings/view/change-password?tab=1')}>{user.password}</Link>
          </Detail>
          <Detail label={t('settings:securitySettings.openId.googleAccount')} value={user.userId}>
            {user.hasOpenId && (
              <Link onClick={() => unlinkOpenId(user)}>{t('settings:securitySettings.openId.unlink')}</Link>
            )}
            {!user.hasOpenId && <span>{t('settings:securitySettings.openId.notLinked')}</span>}
          </Detail>
        </>
      )}
      <div>
        <Heading title={t('settings:securitySettings.twoFactorAuthentication.label')} />
        {!user?.hasGoogleAuthEnabled && (
          <Notification kind="error">
            <p>{t('settings:securitySettings.set2FAWithToken')}</p>
          </Notification>
        )}
        {user?.hasSMSAuthEnabled && (
          <Notification kind="warning">
            <p>{t('settings:securitySettings.default2FAWithToken')}</p>
          </Notification>
        )}
        <Panel>
          <Table stateKey={stateKey}>
            <Row>
              <SortableHeader {...props(WIDTHS.TYPE, 'type', t('settings:securitySettings.list.type'))} />
              <SortableHeader {...props(WIDTHS.PHONE, 'number', t('settings:securitySettings.list.number'))} />
              <SortableHeader
                {...props(WIDTHS.DEFAULT, 'default2FA', t('settings:securitySettings.list.default2FA'))}
              />
              <div className="Table-cell Table-cell--header Table-actions" />
            </Row>
            <RowTwoFactorAuth
              user={user}
              nameType={t('settings:securitySettings.twoFactorAuthentication.timebasedToken')}
              hasTwoFactorEnabled={user.hasGoogleAuthEnabled}
              authenticationType={authenticationType.GOOGLE_AUTH}
              authenticationValue={t('settings:securitySettings.twoFactorAuthentication.empty')}
              actions={{ onAdd: onAddTwoFactorAuth, onReset: onAddTwoFactorAuth, onRemove: onRemoveTimebasedToken }}
              makeDefault2FA={makeDefault2FA}
              t={t}
            />
            {user.hasSMSAuthEnabled && (
              <RowTwoFactorAuth
                user={user}
                nameType={t('settings:securitySettings.twoFactorAuthentication.smsToken')}
                hasTwoFactorEnabled={user.hasSMSAuthEnabled}
                authenticationType={authenticationType.SMS}
                authenticationValue={user.phoneNumber}
                actions={{ onAdd: onAddSmsToken, onReset: onAddSmsToken, onRemove: onRemoveSmsToken }}
                makeDefault2FA={makeDefault2FA}
                t={t}
              />
            )}
          </Table>
        </Panel>
      </div>
    </div>
  </>
);

export default SecuritySettings;
