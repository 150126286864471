import React, { useContext, useEffect, useState } from 'react';
import { useAPI } from '~/common';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import Spin from '~/components/src/Spin';
import { transformCriteria } from '~/profiles/audiences';
import { addNamesFromRuleDefinitionsToPath } from '~/profiles/audiences/audienceUtils';
import { RULE_GROUP_TYPES } from '~/workflows/constants';
import { fetchRuleDefinitions, fetchTrigger } from '~/workflows/dataService';
import { useAllVariables } from '~/workflows/hooks';
import { CreateTriggerPayload, RuleDefinitionsReponse, TRule } from '~/workflows/types';
import { removeItemsNotAvailableInOrchestration, useQuery } from '~/workflows/util';
import { AudienceContext } from '../AudienceContext';
import ExitConditionConfiguration from './ExitConditionConfiguration';

const ExitConditionWrapper = () => {
  const { exitConditions, workflowId, setValidations, refetchTree } = useContext(AudienceContext);
  const query = useQuery();
  const nodeId = query.get('nodeId');

  const [selectedExitCondition, setSelectedExitCondition] = useState<string | null>(
    nodeId || exitConditions?.[0]?.nodeId,
  );

  const { data: exitConditionData, isLoading: exitConditionIsLoading = false } = useAPI(
    async () => (selectedExitCondition ? fetchTrigger(selectedExitCondition) : Promise.resolve()),
    [selectedExitCondition],
  );

  const { allVariables, refetchAllVariables, isLoading: isVariablesLoading } = useAllVariables();

  const { data = [], isLoading: isRuleDefsLoading } = useAPI(() =>
    fetchRuleDefinitions(RULE_GROUP_TYPES.EXIT_BY_CONDITION),
  );

  const isLoading = isVariablesLoading || isRuleDefsLoading;

  useEffect(() => {
    setValidations([]);
  }, [selectedExitCondition]);

  const ruleTypesTree = removeItemsNotAvailableInOrchestration(getRulesTreeFromTypes(data)) as TRule[];

  const loadTrigger = (): CreateTriggerPayload | null => {
    if (isLoading || !exitConditionData) {
      return null;
    }

    const triggerWithNames = addNamesFromRuleDefinitionsToPath(
      data as RuleDefinitionsReponse[],
      'criteria',
    )(exitConditionData) as CreateTriggerPayload;
    const transformedCriteria = transformCriteria(triggerWithNames?.criteria);

    return {
      ...triggerWithNames,
      criteria: transformedCriteria,
    };
  };

  const trigger = loadTrigger();

  return (
    <>
      {!isLoading ? (
        <ExitConditionConfiguration
          selectedExitCondition={selectedExitCondition}
          setSelectedExitCondition={setSelectedExitCondition}
          exitConditions={exitConditions}
          allVariables={allVariables ?? []}
          ruleTypesTree={ruleTypesTree}
          refetchAllVariables={refetchAllVariables}
          triggerData={selectedExitCondition ? trigger : null}
          workflowId={workflowId}
          refetchTree={refetchTree}
          isLoading={exitConditionIsLoading}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};

export default ExitConditionWrapper;
