/*
 * Service module for the Site Admin part of the Relay42 platform.
 * Lists users and their permissions for current site, invites user to site, grants permissions and deletes access.
 * Add/edit/delete environments.
 */
//TODO split this service into two smaller services
angular.module('webUi.service.admin.users', [
    'restangular'
])

    .factory('AdminService', ['Restangular', '$q', 'CsvService', 'Utils', 'SecurityCommonService', function (Restangular, $q, CsvService, Utils, SecurityCommonService) {

        var BASE_PATH = 'admin/';

        var adminService = {

            /**
         * Retrieves current site
         * @returns current site
         */
            getCurrentSite: function (siteNumber) {
                var sitePromise = $q.defer();
                Restangular.one(BASE_PATH + 'getSite', siteNumber).get().then(function(res) {
                    sitePromise.resolve(Restangular.stripRestangular(res));
                });
                return sitePromise.promise;
            },

            changeSiteRestrictedSubnets: function(siteId, restrictedSubnetsString) {
                var identifier = {siteId: siteId};
                var endPointUrl = BASE_PATH + 'security/restrictedSubnets';
                SecurityCommonService.updateRestrictedSubnets(identifier, endPointUrl, restrictedSubnetsString);
            },

            /**
         * @param {UUID} userId
         * @param {String} phoneNumber (can be null or undefined)
         * @returns {UUID} a promise of the uuid of the user
         */
            changePhoneNumber: function (userId, phoneNumber) {
                return Restangular.all(BASE_PATH + 'users/phoneNumber').post({
                    userId: userId,
                    phoneNumber: phoneNumber || null
                });
            },

            /**
         * @returns all clients within the system
         */
            getAllClients: function () {
                var def = $q.defer();

                Restangular.all(BASE_PATH + 'clients').getList().then(function (clients) {
                    clients = Utils.sanitizeRestangularAll(clients);
                    def.resolve(clients);
                });

                return def.promise;
            },


            /**
         *
         * @param {UUID} userId
         * @param {String} restrictedSubnetsString the String of restricted subnets
         * @returns {UUID} a promise of the uuid of the user
         */
            changeRestrictedSubnets: function changeRestrictedSubnets(userId, restrictedSubnetsString) {
                var identifier = {userId: userId};
                var endPointUrl = BASE_PATH + 'users/restrictedSubnets';
                SecurityCommonService.updateRestrictedSubnets(identifier, endPointUrl, restrictedSubnetsString);
            },

            /**
         * Send invitation and grant permission to user identified by email
         */
            inviteUser: function (email) {
                return Restangular.one(BASE_PATH + 'inviteUser').post('', {
                    'email': email
                });
            },

            /**
         * Retrieves a list of all tag environments for a site
         * @returns {Array} a promise of all environments
         */
            getTagEnvironments: function () {
                var prom = $q.defer();
                Restangular.one(BASE_PATH + 'tagmanagement/environments').get().then(function(environments){
                    prom.resolve(Restangular.stripRestangular(environments));
                });
                return prom.promise;

            },

            /**
         * Create a tag environment
         * @param {TagEnvironmentCreateCommand} createCommand
         * @returns {promise}
         */
            createTagEnvironment: function (createCommand) {
                var prom = $q.defer();
                Restangular.all(BASE_PATH + 'tagmanagement/environments').post(createCommand).then(function(environmentId){
                    prom.resolve(environmentId);
                });
                return prom.promise;
            },

            /**
         * Create a tag environment
         * @param {TagEnvironmentCreateCommand} updateCommand
         * @returns {promise}
         */
            updateTagEnvironment: function (updateCommand) {
                var prom = $q.defer();
                Restangular.all(BASE_PATH + 'tagmanagement/environments/' + updateCommand.environmentId).customPUT(updateCommand).then(function(environmentId){
                    prom.resolve(environmentId);
                });
                return prom.promise;
            },

            /**
         * There are some environments that can't be deleted
         * @returns {Array} a promise of an array containing the String values of the non deletable environment codes.
         */
            getNonDeletableEnvironmentCodes: function () {
                return Restangular.one(BASE_PATH + 'tagmanagement/environments/nonDeletableCodes').get();
            },

            /**
         * Deletes a tag environment
         * @param {UUID} environmentId
         * @returns {promise}
         */
            deleteTagEnvironment: function (environmentId) {
                return Restangular.one(BASE_PATH + 'tagmanagement/environments', environmentId).remove();
            },

            exportUsers: function (users, siteNumber, availableWebuiPermissions, availableApiPermissions) {

                /**
             * Joins the user permissions into a single string (comma separated). If the user has no permissions, returns "-"
             * @param {Object} user - the user. Expected to have a permissions field
             * @param {Array} lookupPermissions - the permissions from which to lookup the names. Excepts each entry of the array to have name and label
             * @returns {String} - a comma separated string of the user's permissions
             */
                var joinPermissions = function joinPermissions(user, lookupPermissions) {

                    var permissionsString = '';

                    var userPermissions = _.keys(user['permissions']);
                    if (userPermissions) {
                        _.forEach(userPermissions, function (permission, index) {
                        // Find the label for the permission
                            var wp = _.find(lookupPermissions, {'name': permission});
                            // Add the permission to the string
                            if (wp) {
                                permissionsString += wp.label;
                                if (index !== userPermissions.length - 1) {
                                    permissionsString += ',';
                                }
                            }
                        });
                    }

                    if (_.isEmpty(permissionsString)) {
                        permissionsString = '-';
                    }
                    return permissionsString;
                };

                CsvService.exportAsFile(
                    CsvService.generateFileName('accounts-site-' + siteNumber, true),
                    users,
                    ['userId', 'username', 'name', function (user) {
                        var lastLoginDate = user['lastLoginDate'];
                        if (lastLoginDate) {
                            return moment(lastLoginDate).toISOString();
                        } else {
                            return '-';
                        }
                    }, function (user) {
                        return joinPermissions(user, availableWebuiPermissions);
                    }, function (user) {
                        return joinPermissions(user, availableApiPermissions);
                    }],
                    ['Id', 'Username', 'Name', 'Last Login', 'WebUI Roles', 'API Roles']);
            }
        };

        return adminService;
    }]);
