angular.module('webUi.ui.tagmanagement.tags.conditions', [
    'webUi.ui.tagmanagement.tags.conditions.configure',
    'webUi.ui.tagmanagement.tags.conditions.cookiePermission',
    'webUi.ui.tagmanagement.tags.conditions.deduplication',
    'webUi.ui.tagmanagement.tags.conditions.event',
    'webUi.ui.tagmanagement.tags.conditions.experiment',
    'webUi.ui.tagmanagement.tags.conditions.browserType',
    'webUi.ui.tagmanagement.tags.conditions.countryMatch',
    'webUi.ui.tagmanagement.tags.conditions.date',
    'webUi.ui.tagmanagement.tags.conditions.environmentExclusion',
    'webUi.ui.tagmanagement.tags.conditions.ip',
    'webUi.ui.tagmanagement.tags.conditions.landing',
    'webUi.ui.tagmanagement.tags.conditions.pageProperty',
    'webUi.ui.tagmanagement.tags.conditions.referral',
    'webUi.ui.tagmanagement.tags.conditions.selection',
    'webUi.ui.tagmanagement.tags.conditions.timeOfDay',
    'webUi.ui.tagmanagement.tags.conditions.mobileProperty',
    'webUi.ui.tagmanagement.tags.conditions.journey'
]);
