import React from 'react';
import { FormSection, Field } from 'redux-form';
import { history } from '~/common/history';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FormHeader from '~/profiles/connectors/form/partners/formHeader/FormHeader';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { isRequired } from '~/common/formValidation';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import BtnOutlined from '~/components/src/BtnOutlined';
import { FacebookConversionsFormProps } from './types';

const FacebookConversionsForm = ({ values, partner, canUpdate, t }: FacebookConversionsFormProps): JSX.Element => (
  <FormSection name="FACEBOOK_CONVERSIONS">
    <FormHeader t={t} title={values.name} partner={partner} partnerNumber={partner.partnerNumber} />
    <ContainerFormSection>
      <Field
        label={t('form.formFields.pixelId')}
        name="pixelId"
        component={ReduxFormInputField}
        placeholder={t('form.formFields.inputPlaceHolder')}
        validate={isRequired}
        type="text"
      />
      <FieldWithEditMode
        label={t('form.formFields.accessToken')}
        name="accessToken"
        component={ReduxFormSensitiveField}
        isNeedActivateEditMode={canUpdate}
        placeholder={t('form.formFields.inputPlaceHolder')}
        validate={isRequired}
        type="text"
      />
      {canUpdate && (
        <div className="flex items-center">
          <BtnOutlined
            className="mr-4"
            onClick={() => {
              history.push(`${history.location.pathname}/transformations`);
            }}
            tooltip={t('form.edit')}
          >
            {t('form.edit')}
          </BtnOutlined>
          <span>{t('form.transformations')}</span>
        </div>
      )}
    </ContainerFormSection>
  </FormSection>
);

export default FacebookConversionsForm;
