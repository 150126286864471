angular.module('webUi.ui.context.users.base', ['webUi.ui.context.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.users', {
                'abstract': true,
                url: '/users',
                views : {
                    'mainContent@' : {
                        templateUrl: 'ui/context/users/base/base.tpl.html'
                    }
                }
            });
		
		
    }]);
