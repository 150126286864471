import React from 'react';
import Highcharts from 'highcharts';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import { TagErrorsProps } from '../types';

const TagErrors = ({ tagErrors }: TagErrorsProps): React.ReactElement => (
  <>{tagErrors && <HighChartWithZoom highcharts={Highcharts} options={tagErrors} />}</>
);

export default TagErrors;
