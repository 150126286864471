import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Engagements Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.ENGAGEMENT_FETCH_SUCCESS:
    case types.ENGAGEMENT_SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.engagementId]: action.payload,
      };
    case types.ENGAGEMENTS_FETCH_SUCCESS:
      nextState = {};
      action.payload.forEach(engagement => {
        nextState[engagement.engagementId] = engagement;
      });
      return nextState;
    case types.ENGAGEMENTS_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Engagements Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.ENGAGEMENT_FETCH_SUCCESS:
    case types.ENGAGEMENT_SAVE_SUCCESS:
      if (state.indexOf(action.payload.engagementId) !== -1) {
        return state;
      }
      return [...state, action.payload.engagementId];
    case types.ENGAGEMENTS_FETCH_SUCCESS:
      return action.payload.map(engagement => engagement.engagementId);
    case types.ENGAGEMENTS_DELETE_SUCCESS:
      nextState = [...state];
      nextState.splice(
        state.findIndex(item => item === action.payload),
        1,
      );
      return nextState;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingEngagement: false,
  isFetchingEngagements: false,
  dependantsContainerIsVisible: false,
  selectedId: null,
  selectedTab: 0,
  userIsEditing: false,
  error: null,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.ENGAGEMENT_FETCH_START:
      return {
        ...state,
        isFetchingEngagement: true,
      };
    case types.ENGAGEMENT_FETCH_SUCCESS:
    case types.ENGAGEMENT_FETCH_FAIL:
      return {
        ...state,
        isFetchingEngagement: false,
      };
    case types.ENGAGEMENTS_FETCH_START:
      return {
        ...state,
        isFetchingEngagements: true,
      };
    case types.ENGAGEMENTS_FETCH_SUCCESS:
    case types.ENGAGEMENTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingEngagements: false,
      };
    case types.SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedId: action.payload.propertyId || action.payload.engagementId,
        selectedTab: action.payload.selectedTab,
      };
    case types.HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedId: null,
        selectedTab: 0,
      };
    case types.DEPENDANTS_TYPE_TAB_CHANGE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case types.SET_USER_IS_EDITING:
      return {
        ...state,
        userIsEditing: action.payload,
      };
    default:
      return state;
  }
};

const engagementsReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default engagementsReducer;
