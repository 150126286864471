import { combineReducers } from 'redux';
import * as types from './types';
import * as dependencyTypes from '../dependencies/types';

const defaultAction = {
  payload: {},
};

/**
 * Variables Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.VARIABLES_SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.variableId]: action.payload,
      };
    case types.VARIABLES_FETCH_SUCCESS:
      nextState = {};
      action.payload.forEach(variable => {
        nextState[variable.variableId] = variable;
      });
      return nextState;
    case types.VARIABLES_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    case dependencyTypes.DEPENDANTS_INFO_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], dependantsInfo: action.payload.dependantsInfo },
      };
    default:
      return state;
  }
};

/**
 * Variables Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.VARIABLES_SAVE_SUCCESS:
      return [...state, action.payload.variableId];
    case types.VARIABLES_FETCH_SUCCESS:
      return action.payload.map(variables => variables.variableId);
    case types.VARIABLES_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  error: null,
  dependantsContainerIsVisible: false,
  selectedVariableId: null,
  selectedTab: 0,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.VARIABLES_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
      };
    case types.VARIABLES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
      };
    case types.VARIABLES_SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedVariableId: action.payload.variableId,
        selectedTab: action.payload.selectedTab,
      };
    case types.VARIABLES_HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedVariableId: null,
        selectedTab: 0,
      };
    case types.VARIABLES_DEPENDANTS_TYPE_TAB_CHANGE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

const adminVariablesReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default adminVariablesReducer;
