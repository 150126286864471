angular.module('webUi.ui.tagmanagement.errorReporting.view', [
    'webUi.ui.tagmanagement.errorReporting.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.errorReporting.view', {
                url: '/view',
                isReact: true,

            });
    }]);

   