angular.module('webUi.directive.tree.add', [
    'ngSanitize'
])

    .controller('AddNodeDialogController', ['$scope', 'dialogsModel', '$uibModalInstance',
        function AddNodeDialogController    ($scope,   dialogsModel,   $uibModalInstance) {

            $scope.node = {};

            $scope.parentNode = dialogsModel.parentNode;

            $scope.cancel = function() {
                $uibModalInstance.close(false);
            };
            $scope.save = function() {
                dialogsModel.onSave($scope.node);
                $uibModalInstance.close(true);
            };

        }
    ]);
