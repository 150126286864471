import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FormError from '../Elements/Error';

const FormRow = ({ className, classNameLabel, children, disabled, name, label, hint, meta: { touched, error } }) => {
  const Label = disabled ? 'span' : 'label';

  return (
    <div className={cx('Form-field ', `t-${name}Field`, className)}>
      {label && (
        <Label className={cx('Form-label', classNameLabel)} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className="Form-element">
        {children}
        <span className="Form-hint u-block u-marginTopS">{hint}</span>
        <div className="Form-error">{touched && error && <FormError error={error} />}</div>
      </div>
    </div>
  );
};

FormRow.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

FormRow.defaultProps = {
  meta: {
    touched: false,
    error: '',
  },
};

export default FormRow;
