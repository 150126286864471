import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/support/profile/mappingconfiguration`;

const profileIdentitiesService = {
  fetchIdentityConfigurations: () => Api.callGet(BASE_PATH).then(response => response.data),
  deleteIdentityConfiguration: configurationId => Api.callDelete(`${BASE_PATH}/${configurationId}`),
  addIdentityConfiguration: formValues => Api.callPost(BASE_PATH, formValues).then(response => response.data),
};

export default profileIdentitiesService;
