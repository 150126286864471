/**
 * @description Angular module for user settings
 * @class UserSettingsController
 * @namespace webUi.ui.user.settings
 */
angular.module('webUi.ui.user.settings', [
    'webUi.ui.user.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.user.changePassword', {
                url : '/settings/view/change-password',
                isReact: true
            })
            .state('site.user.settings', {
                url : '/settings/view',
                isReact: true
            });
    }]);
