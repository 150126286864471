/**
 * @description Angular main module for Content -> Campaigns -> Base (left)
 * @memberOf webUi.ui.content.campaigns
 * @namespace webUi.ui.content.campaigns.base
 */
angular.module('webUi.ui.content.campaigns.base',
    ['webUi.ui.content.base']
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns', {
                url: '/campaigns',

                views : {
                    'leftMenu@' : {
                        controller: 'ContentCampaignBaseController as vm',
                        templateUrl: 'ui/content/campaigns/base/base.tpl.html'
                    }
                },
                leftMenu: true,
                resolve : {
                    campaigns : ['CampaignService', function(CampaignService) {
                        return CampaignService.getCampaigns();
                    }]
                }
            });
    }])
    .controller('ContentCampaignBaseController',
        [
            '$scope',
            'CampaignService',
            'AdService',
            'PubSubService',
            'hasAdservingEdit',
            'campaigns',
            /**
         *
         * @function ContentCampaignBaseController
         * @memberOf webUi.ui.content.campaigns.base
         *
         * @param $scope
         * @param CampaignService
         * @param AdService
         * @param PubSubService
         * @param hasAdservingEdit
         * @param campaigns
         *
         * @returns ConentCampaignBaseController
         */
            function ContentCampaignBaseController($scope, CampaignService, AdService, PubSubService, hasAdservingEdit, campaigns) {
                'use strict';
                
                var vm = this;

                vm.hasAdservingEdit = hasAdservingEdit;
                vm.campaigns = campaigns;

                /* Copy ad on drop  */
                vm.onDroppedAd = function(adId, campaignId) {
                    if (!vm.hasAdservingEdit) {
                    //not enough permissions
                        return;
                    }
                    if(_.isUndefined(adId)) {
                    //dragged something else, abort
                        return;
                    }

                    AdService.getAd(adId).then(function(adPojo) {
                        AdService.copyAd(adPojo.adId, campaignId).then(function(newCampaignId) {
                            if (newCampaignId) {
                                var campaignName = _.find(vm.campaigns, {'campaignId': newCampaignId}).name;
                                PubSubService.publishAdCopied(adId, adPojo.name, campaignId, campaignName);
                            }
                        });
                    });
                };

                /**
             * for campaign add/edit/delete, refresh the campaign lsit
             */
                PubSubService.subscribeForType(PubSubService.TYPES.CONTENT.CAMPAIGN, $scope, function() {
                    CampaignService.getCampaigns().then(function(result) {
                        vm.campaigns = result;
                    });
                });

                /**
             * Listen to campaign changes (e.g ads add/delete, ads reordering, placement ad link/unlink
             */
                PubSubService.subscribeCampaignContentChanged($scope, function() {
                    CampaignService.getCampaigns().then(function(result) {
                        vm.campaigns = result;
                    });
                });

                /**
             *
             */
                PubSubService.subscribeTypeSelected(PubSubService.TYPES.CONTENT.CAMPAIGN, $scope, function(event, message){
                    if (message.id) {
                        vm.selectedCampaignId = message.id;
                    } else {
                        vm.selectedCampaignId = null;
                    }
                });
            }
        ]);
