import { from, of } from 'rxjs';
import { map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { setAudiences, setAudiencesError } from './actions';
import { getAudiencesPaginated } from './dataService';
import { SEARCH_AUDIENCES } from './types';

export const searchAudiencesEpic = action$ =>
  action$.pipe(
    ofType(SEARCH_AUDIENCES),
    debounceTime(1000),
    map(action => action.payload),
    switchMap(({ page, groupId, query }) =>
      from(getAudiencesPaginated(page, groupId, query)).pipe(
        map(audiences => setAudiences(audiences)),
        catchError(error => of(setAudiencesError(error))),
      ),
    ),
  );
