import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { doubleClickActionTypes } from './doubleClickActions';

const doubleClickReducer = (state, action) => {
  switch (action.name) {
    case doubleClickActionTypes.FETCH_ADVERTISERS.fulfilled:
      return state.setIn(['promise', 'fetchingAdvertisers'], setFulfilled());
    case doubleClickActionTypes.FETCH_ADVERTISERS.pending:
      return state.setIn(['promise', 'fetchingAdvertisers'], setPending());
    case doubleClickActionTypes.FETCH_ADVERTISERS.rejected:
      return state.setIn(['promise', 'fetchingAdvertisers'], setRejected());
    case doubleClickActionTypes.SET_ADVERTISERS:
      return state.set('advertisers', action.data);

    case doubleClickActionTypes.FETCH_USER_ID_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setFulfilled());
    case doubleClickActionTypes.FETCH_USER_ID_TYPES.pending:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setPending());
    case doubleClickActionTypes.FETCH_USER_ID_TYPES.rejected:
      return state.setIn(['promise', 'fetchingUserIdTypes'], setRejected());
    case doubleClickActionTypes.SET_USER_ID_TYPES:
      return state.set('userIdTypes', action.data);

    default:
      return state;
  }
};

export default doubleClickReducer;
