import React, { Component } from 'react';
import ColumnsContainer from './ColumnsContainer';
import TypesContainer from './TypesContainer';
import ValueSetsContainer from './ValueSetsContainer';
import ExportConfigurationsContainer from './ExportConfigurationsContainer';

import './UrlBuilderContainer.scss';

class UrlBuilderContainer extends Component {
  render() {
    const { isSiteAdmin } = this.props;
    return (
      <div className="UrlBuilderContainer">
        <ColumnsContainer key="Columns" isSiteAdmin={isSiteAdmin} />
        <TypesContainer key="Types" isSiteAdmin={isSiteAdmin} />
        <ValueSetsContainer key="ValueSets" isSiteAdmin={isSiteAdmin} />
        <ExportConfigurationsContainer key="ExportConfigurations" isSiteAdmin={isSiteAdmin} />
      </div>
    );
  }
}

export default UrlBuilderContainer;
