import React from 'react';
import cx from 'classnames';
import { Placement } from 'tippy.js';
import Tooltip from '../Tooltip';
import './styles.scss';

interface BlockProps {
  tooltip?: string;
  tooltipPlacement?: Placement;
  isSuccessful?: boolean;
  className?: string;
}

const Block = (props: BlockProps) => (
  <Tooltip tooltip={props?.tooltip} placement={props?.tooltipPlacement || 'bottom'}>
    <span className={cx('Block', { 'Block--success': props.isSuccessful }, props.className)} />
  </Tooltip>
);

export default Block;
