import React, { useState } from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl } from '~/common';
import { Link } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import DeleteExperiment from './DeleteExperiment';
import { ExperimentObject } from './types';

const columnHelper = createColumnHelper<ExperimentObject>();

const DeleteAction = ({
  hasTagEdit,
  experiment,
  refetchExperiments,
}: {
  experiment: ExperimentObject;
  hasTagEdit: boolean;
  refetchExperiments: () => void;
}) => {
  const [isDeletePickerVisible, setIsDeletePickerVisible] = useState(false);
  return (
    <>
      <BtnIcon
        icon="delete"
        onClick={() => setIsDeletePickerVisible(true)}
        testHook="deleteExperiment"
        disabled={!hasTagEdit}
        tooltip={hasTagEdit ? i18n.t('experiments:actions.delete') : i18n.t('experiments:tooltips.noPermission')}
      />
      {isDeletePickerVisible && (
        <DeleteExperiment
          experimentId={experiment.experimentKey.experimentId}
          experimentName={experiment.name}
          setIsPickerVisible={setIsDeletePickerVisible}
          callback={refetchExperiments}
        />
      )}
    </>
  );
};

const getDateAndTime = (date: number | string) => (
  <p>
    {moment(date).format('DD/MM/YYYY')}
    <br />
    <small className="text-r42-blue">{moment(date).format('HH:mm:ss')}</small>
  </p>
);

const getColumns = (refetchExperiments: () => void, hasTagEdit: boolean) => [
  columnHelper.accessor('name', {
    header: () => <span>{i18n.t('experiments:table.name')}</span>,
    cell: info => (
      <Link
        className="t-name w-[30%]"
        href={buildUrl(`tagmanagement/experiments/view/${info?.row.original.experimentKey.experimentId}`)}
      >
        <span className="elipsis truncate"> {info.getValue()}</span>
      </Link>
    ),
    size: 200,
    minSize: 200,
  }),
  columnHelper.accessor('experimentNumber', {
    header: () => <div>{i18n.t('experiments:table.number')}</div>,
    cell: info => <div className="t-experimentNumber">{info.getValue()}</div>,
  }),
  columnHelper.accessor('lastModified', {
    header: () => <div>{i18n.t('experiments:table.lastUpdated')}</div>,
    cell: info => <div className="t-lastModified">{getDateAndTime(info.getValue())}</div>,
  }),
  columnHelper.accessor('actions', {
    header: () => <div className="text-center">{i18n.t('experiments:table.actions')}</div>,
    cell: info => (
      <div className="text-center">
        <DeleteAction hasTagEdit={hasTagEdit} experiment={info.row.original} refetchExperiments={refetchExperiments} />
      </div>
    ),
  }),
];

const ExperimentsTable = ({
  hasTagEdit,
  data,
  refetchExperiments,
}: {
  hasTagEdit: boolean;
  data: ExperimentObject[];
  refetchExperiments: () => void;
}) => (
  <UITable
    testHook="experimentsTable"
    emptyMessage={i18n.t('experiments:messages.emptyList')}
    emptyBody={i18n.t('experiments:messages.emptyListBody')}
    data={data}
    columns={getColumns(refetchExperiments, hasTagEdit)}
  />
);

export default ExperimentsTable;
