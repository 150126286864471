angular.module('webUi.service.journey', [
    'webUi.ui.siteState',
    'restangular',
    'ui.router',
    'webUi.common.Utils'
])
/**
 * @namespace JourneyService
 */
    .factory('JourneyService', [
        'Restangular',
        '$q',
        '$state',
        'PubSubService',

        function JourneyService(Restangular, $q, $state, PubSubService) {

            // The base path through which all calls should go
            var BASE_PATH_OLD = 'customer/journeys';
            var BASE_PATH = 'journeyorchestration/';

            /**
                 * get journey details
                 * @param journeyId
                 * @returns {*}
                 */
            var getJourney = function getJourney(journeyId) {
                var journeyPromise = $q.defer();
                Restangular.one(BASE_PATH_OLD + '/' + journeyId).get().then(
                    function onSuccess(journey) {
                        // Backend returns 200 for nonexisting journey id, so
                        // this is a workaround to handle this here
                        if (journey) {
                            journeyPromise.resolve(Restangular.stripRestangular(journey));
                        } else {
                            journeyPromise.reject();
                        }
                    },
                    function onError(error) {
                        error.errorHandled = true;
                        journeyPromise.reject(error);
                    });
                return journeyPromise.promise;
            };
            
            /**
                 * Get the journey list from backend
                 * @returns {*}
                 */
            var getJourneysList = function getJourneysList(type) {
                var journeysListPromise = $q.defer();
                Restangular.all(`${BASE_PATH_OLD}?workflowType=${type}`).getList().then(function (journeys) {
                    journeysListPromise.resolve(Restangular.stripRestangular(journeys));
                });
                return journeysListPromise.promise;
            };

            var createJourney = function createJourney(journey) {
                return Restangular.all(BASE_PATH_OLD).post(journey);
            };

            var createAndOpenEmptyJourney = function createAndOpenEmptyJourney() {
                var journey = {
                    name: 'New Journey',
                    description: '',
                    nodes: {},
                    goals: []
                };

                createJourney(journey).then(
                    function onSuccess(journeyId) {
                        PubSubService.publishTypeCreated(PubSubService.TYPES.CUSTOMER.JOURNEY, {id: journeyId});
                        $state.transitionToStateWithSite('site.customer.journeys.form', {journeyId: journeyId});
                    }
                );
            };

            var getSteps = function getSteps(journeyId) {
                return Restangular.one(BASE_PATH + journeyId + '/tree').get().then((result) => {
                    const tree = Restangular.stripRestangular(result);
                    if (tree.nodes) {
                        return flattenJourneyTree(tree.nodes).filter((item) => {
                            return item.type === 'STEP';
                        });
                    } else {
                        return [];
                    }
                }, function onError(error) {
                    error.errorHandled = true;
                });
            };

            var getStep = function getStep(stepId) {
                var deferred = $q.defer();
                Restangular.one(BASE_PATH_OLD + '/steps/' + stepId).get().then(
                    function onSuccess(steps) {
                        deferred.resolve(Restangular.stripRestangular(steps));
                    },
                    function onError(error) {
                        error.errorHandled = true;
                        deferred.reject();
                    });
                return deferred.promise;
            };

            function flattenJourneyTree (node) {
                const flat = [];

                flat.push({
                    label: node.label,
                    nodeId: node.nodeId,
                    type: node.type
                });

                if (node.children && node.children.length) {
                    node.children.forEach((childNode) => {
                        flat.push(...flattenJourneyTree(childNode));
                    });
                }

                return flat;
            }


            return {
                getJourney: getJourney,
                getJourneysList: getJourneysList,
                createJourney: createJourney,
                createAndOpenEmptyJourney: createAndOpenEmptyJourney,
                getStep: getStep,
                getSteps: getSteps,
            };
        }
    ]
    );
