// Module
var code = ` <div data-wrap data-label="Skillz<small>Custom validation per entry: you can have zero skills in the list, but each one you have has to be unique; editables validate on save</small>"> <table class="table table-hover" style="width:500px"> <thead> <tr> <th>Skill name</th> <th></th> </tr> </thead> <tr data-ng-repeat="(skillIdx, skill) in vm.data.developer.skills track by skillIdx"> <td> <div data-type="input" data-editable="vm.data.developer.skills.{{skillIdx}}" data-placeholder="Skill" data-custom-validate="[
						{ isRequired:true },
						{ isUniqueValue: vm.isUniqueValue(\$value, vm.data.developer.skills, skillIdx), msg: 'Skill already exists' }
					]"> </div> </td> <td><a data-ng-click="vm.data.developer.skills.splice(skillIdx, 1)"><i class="r42-trash"></i></a></td> </tr> <tr data-ng-form name="vm.newSkillForm" class="innerForm" data-isolate-form> <td> <div data-valid data-type="input" data-ng-model="vm.data.newSkill" data-custom-validate="[
						{isRequired: true},
						{isUnique: vm.isUniqueValue(vm.data.newSkill, vm.data.developer.skills), msg: 'Skill already exists'}
					]" data-placeholder="Skill"> <span class="error-block" data-ng-show="vm.newSkillForm['vm.data.newSkill'].\$showError"> <small>{{vm.newSkillForm['vm.data.newSkill'].\$errors[0]}}</small> </span> </div> </td> <td> <button type="button" class="btn btn-mini" data-ng-click="vm.addNewSkill(vm.data.newSkill)" title="Add skill"><i class="r42-plus"></i></button> </td> </tr> </table> </div> <hr/> <div data-wrap data-label="Foreign languages<small>You must have at least two in the list; you are allowed to add duplicates, but you will trigger error on submit</small>" data-valid data-ng-model="vm.data.developer.languages" data-custom-watch="vm.data.developer.languages" data-custom-validate="{hasMin: vm.hasMin(vm.data.developer.languages, 2), msg: 'You need to be bilingual'}"> <table class="table table-hover" style="width:500px"> <thead> <tr> <th>Language</th> <th></th> </tr> </thead> <tr data-ng-repeat="(langIdx, language) in vm.data.developer.languages track by langIdx"> <td> <div data-valid data-ng-model="vm.data.developer.languages[langIdx]" data-custom-watch="vm.data.developer.languages" data-custom-validate="[
						{isRequired: true},
						{isUnique: vm.isUniqueValue(vm.data.developer.languages[langIdx], vm.data.developer.languages, langIdx), msg: 'You cannot have two identical'}
					]"> <div data-type="input" data-editable="vm.data.developer.languages.{{langIdx}}" data-placeholder="Skill"></div> <span class="error-block" data-ng-show="validationForm['vm.data.developer.languages[{{langIdx}}]'].\$showError"> <small data-ng-bind="validationForm['vm.data.developer.languages[{{langIdx}}]'].\$errors[0]"></small> </span> </div> </td> <td><a data-ng-click="vm.data.developer.languages.splice(langIdx, 1)"><i class="r42-trash"></i></a></td> </tr> <tr data-ng-form name="vm.newLanguageForm" class="innerForm" data-isolate-form> <td> <div data-valid data-type="input" data-ng-model="vm.data.newLanguage" data-custom-validate="{isRequired: true}" data-placeholder="E.g. swahili"> <span class="error-block" data-ng-show="vm.newLanguageForm['vm.data.newLanguage'].\$showError"> <small>{{vm.newLanguageForm['vm.data.newLanguage'].\$errors[0]}}</small> </span> </div> </td> <td> <button type="button" class="btn btn-mini" data-ng-click="vm.addNewLanguage(vm.data.newLanguage)" title="Add language"><i class="r42-plus"></i></button> </td> </tr> </table> <span class="error-block" data-ng-show="validationForm['vm.data.developer.languages'].\$showError"> <small>{{validationForm['vm.data.developer.languages'].\$errors[0]}}</small> </span> </div> `;
// Exports
var _module_exports =code;;
var path = 'ui/test/validation/form/showcase/custom.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;