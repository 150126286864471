(function() {

    angular.module('webUi.component.common.tipBox', [])

        .component('tipBox', {
            templateUrl: 'component/common/tipBox/tipBox.tpl.html',
            bindings: {
                assetsPrefix: '<',
                content: '<'
            }
        });

})();
