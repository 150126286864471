/**
 *
 */
angular.module('webUi.ui.profiles',
    [
        'webUi.ui.profiles.externalFacts',
        'webUi.ui.profiles.variables',
        'webUi.ui.profiles.engagements',
        'webUi.ui.profiles.thirdPartyDataCollectors',
        'webUi.ui.profiles.partners',
        'webUi.ui.profiles.export',
        'webUi.ui.profiles.customerData',
        'webUi.ui.profiles.filters',
        'webUi.ui.profiles.transformations'
    ]
);
