import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash';
import { Field } from 'formik';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { useAPI } from '~/common/ApiHooks';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import CardMessage from '~/components/src/CardMessage';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';

import { getMailChimpLists } from './service';

const validations = t =>
  Yup.object().shape({
    mailChimpList: Yup.object().shape({
      name: Yup.string().required(t('cards.validation.fieldIsRequired')),
      id: Yup.string(),
    }),
  });

const MailChimp = ({ onEdit, connector, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const { data, error, isLoading: isListLoading } = useAPI(() => getMailChimpLists(partnerId), [partnerId]);
  const options = get(data, 'lists', []);

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <Field
              name="mailChimpList"
              as={FormikSelectField}
              label={t('cards.label.mailChimpList')}
              errorText={formProps?.touched?.mailChimpList && formProps?.errors?.mailChimpList?.name}
              options={options}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={formProps.values.mailChimpList}
              isLoading={isListLoading}
              isClearable={true}
            />
          </SmallWrapperNoOffset>
          {error && <CardMessage message={t('cards.errors.mailChimp')} />}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default MailChimp;
