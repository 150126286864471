angular.module('webUi.ui.content.campaigns.ads.form', ['webUi.ui.content.campaigns.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.content.campaigns.ads.form', {
            url: '/form?campaignId',
            views: {
                'mainContent@': {
                    templateUrl: 'ui/content/campaigns/ads/form/form.tpl.html',
                    controller: 'AdservingCampaignAdFormController as vm'
                }
            },
            leftMenu: true,
            resolve: {
                creativeTypes: ['AdService', function (AdService) {
                    return AdService.getCreativeTypes();
                }],
                mediaLibraries: ['MediaLibraryService', function (MediaLibraryService) {
                    return MediaLibraryService.getMediaLibraries();
                }],
                dataFeeds: ['DataFeedService', 'hasDataManagementModule', function (DataFeedService, hasDataManagementModule) {
                    if (hasDataManagementModule) {
                        return DataFeedService.getDataFeeds();
                    }
                    return [];
                }],
                profileVariables: ['VariableService', 'hasDataManagementModule', function (VariableService, hasDataManagementModule) {
                    if (hasDataManagementModule) {
                        return VariableService.getVariables();
                    }
                    return [];
                }],
                campaign: ['CampaignService', '$stateParams', function (CampaignService, $stateParams) {
                    return CampaignService.getCampaign($stateParams.campaignId);
                }]
            }
        });
    }])

    .controller('AdservingCampaignAdFormController', [
        '$state',
        'AdService',
        'MediaLibraryService',
        'ValidService',
        'creativeTypes',
        'mediaLibraries',
        'dataFeeds',
        'profileVariables',
        'hasDataManagementModule',
        'campaign',
        function AdservingCampaignAdFormController(
            $state,
            AdService,
            MediaLibraryService,
            ValidService,
            creativeTypes,
            mediaLibraries,
            dataFeeds,
            profileVariables,
            hasDataManagementModule,
            campaign) {

            'use strict';
            
            var vm = this;

            vm.data = {};
            vm.data.creativeTypes = creativeTypes;
            vm.data.mediaLibraries = mediaLibraries;
            vm.data.dataFeeds = dataFeeds;
            vm.data.profileVariables = profileVariables;
            vm.data.hasDataManagementModule = hasDataManagementModule;

            vm.data.ad = {
                campaignName: campaign.name,
                campaignId: campaign.campaignId,
                conditions: []
            };

            vm.data.selectOptions = [{
                label: 'Profile variable',
                value: 'profileVariable'
            }, {
                label: 'Placement parameter',
                value: 'placementParam'
            }];

            vm.data.selectedOption = {
                type: 'profileVariable'
            };

            vm.hasDataFeedOption = function hasDataFeedOption(ad, selectedOption) {
            //assume it's always one of two options
                if (selectedOption.type === 'profileVariable') {
                    return !ValidService.isEmpty(ad.profileVariableId);
                } else {
                    return !ValidService.isEmpty(ad.placementParameter);
                }
            };

            vm.hasValidMediaLibrary = function hasValidMediaLibrary (ad) {
                if (ad.creativeType === 'IMAGE' || ad.creativeType === 'FLASH') {
                    return !ValidService.isEmpty(ad.mediaLibraryId);
                }
                return true;
            };

            vm.selectOption = function selectOption(selectedOption, option) {
                selectedOption.type = option.value;
                if (selectedOption.type === 'profileVariable') {
                    vm.data.ad.placementParameter = '';
                } else {
                    vm.data.ad.profileVariableId = null;
                }
            };

            vm.createNewLibrary = function createNewLibrary() {
                var dialogsModel = {
                    onSave: function (newLibrary) {
                        MediaLibraryService.insertMediaLibrary(newLibrary).then(function (newMediaLibraryId) {
                            // Select the newly created library
                            vm.data.ad.mediaLibraryId = newMediaLibraryId;

                            // Refresh the list of media libraries
                            MediaLibraryService.getMediaLibraries().then(function (mediaLibraries) {
                                vm.data.mediaLibraries = mediaLibraries;
                            });
                        }
                        );
                    }
                };
                return MediaLibraryService.newLibraryModal(dialogsModel);
            };

            vm.submit = function (ad) {
                AdService.addAd(ad).then(function (adId) {
                    $state.transitionToStateWithSite('site.content.campaigns.ads.view', {adId: adId});
                });
            };

            /**
         * Jump function
         * @param campaignId
         */
            vm.viewCampaign = function (campaignId) {
                $state.transitionToStateWithSite('site.content.campaigns.view', {
                    campaignId: campaignId
                });
            };
        }]);
