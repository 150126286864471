angular
    .module('webUi.ui.admin.importsAdd', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.importsAdd', {
                url: '/imports/add',
                isReact: true,
            });
        },
    ]);
