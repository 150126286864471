import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Btn from '../Btn';
import LinkBtn from '../LinkBtn';

const ActionsRow = ({
  actionsClassName,
  submitting,
  cancelHref,
  onCancel,
  submitText,
  cancelText,
  extend,
  t,
  isDisableSave,
  onConfirm,
  gaAction = '',
}) => {
  const submitTranslation = submitText || t('common:actions.save');
  const cancelTranslation = cancelText || t('common:actions.cancel');

  const cancelPart = cancelHref ? (
    <LinkBtn testHook="cancel" preventvalidation="true" type="button" disabled={submitting} href={cancelHref}>
      {cancelTranslation}
    </LinkBtn>
  ) : (
    <Btn testHook="cancel" preventvalidation="true" type="button" disabled={submitting} onClick={onCancel}>
      {cancelTranslation}
    </Btn>
  );

  return (
    <div className="mt-4 flex justify-end">
      <div className={cx('flex items-center gap-4', actionsClassName)}>
        {cancelPart}
        {extend}
        <Btn
          color="blue"
          testHook="save"
          disabled={isDisableSave || submitting}
          onClick={onConfirm}
          type="submit"
          gaAction={gaAction}
        >
          {submitTranslation}
        </Btn>
      </div>
    </div>
  );
};

ActionsRow.propTypes = {
  isDisableSave: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  actionsClassName: PropTypes.string,
  cancelHref: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onCancel: PropTypes.func,
  extend: PropTypes.node,
  t: PropTypes.func.isRequired,
  gaAction: PropTypes.string,
};

export default ActionsRow;
