import { get } from 'lodash';

const fetchingAdvertisers = state => get(state, 'profiles.connectorsForm.promise.fetchingAdvertisers');
const fetchingUserIdTypes = state => get(state, 'profiles.connectorsForm.promise.fetchingUserIdTypes');
const getAdvertisers = state =>
  get(state, 'profiles.connectorsForm.advertisers').map(type => ({ ...type, value: type.dcAdId, label: type.name }));
const getUserIdTypes = state =>
  get(state, 'profiles.connectorsForm.userIdTypes').map(type => ({ ...type, value: type.name }));

export { fetchingAdvertisers, fetchingUserIdTypes, getAdvertisers, getUserIdTypes };
