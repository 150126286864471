import React, { useState } from 'react';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { createColumnHelper } from '@tanstack/react-table';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import { Panel } from '~/components/src/Containers';
import i18n from '~/i18n';
import { Dropdown, Heading } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { ModelStats, TDetailProps, TTrainingAnalysisProps } from './types';
import { decimalToPercent } from './util';
import { STATS_DATE_FORMAT, STATS_TIME_FORMAT, THEME_NAMES } from './constants';

const columnHelper = createColumnHelper<{ metric: string } & Record<string, number>>();

const Detail = ({ label, value, valueClassName = '', labelClassName = '', testHook = '', tooltip }: TDetailProps) => (
  <p className={`text-lg ${testHook ? `t-${testHook}` : ''}`}>
    <Tippy className={`t-${testHook}`} content={tooltip} disabled={!tooltip} placement="top">
      <span className={`text-gray-500 ${labelClassName}`}>{label}:</span>
    </Tippy>{' '}
    <span className={valueClassName}>{value}</span>
  </p>
);
const TrainingAnalysis = ({ stats }: TTrainingAnalysisProps) => {
  const { actionInitiatedAt, status, properties } = stats;
  const [themeColor, setThemeColor] = useState<string>(THEME_NAMES[0]?.name);
  const [openDropDown, setOpenDropDown] = useState(false);

  const toggleDropdown = () => {
    setOpenDropDown(!openDropDown);
  };
  const getColumns = (stats: ModelStats) => {
    const rowKeys = Object.keys(stats.results);
    const columnKeys = Object.keys(stats.results[rowKeys[0]] || {});

    const theme = THEME_NAMES?.find(i => i.name === themeColor);

    return [
      columnHelper.group({
        id: 'name',
        header: '',
        columns: [
          columnHelper.accessor('metric', {
            header: () => <span>{i18n.t('ai:modelStats.actual')}</span>,
            cell: info => <span className="font-medium text-gray-800">{info.getValue()}</span>,
            size: 50,
            meta: { color: 'bg-gray-50 border-gray-200 border-b border-r' },
          }),
        ],
      }),
      columnHelper.group({
        id: 'Predicted',
        header: () => <span className="text-center">{i18n.t('ai:modelStats.predicted')}</span>,
        columns: [
          ...columnKeys.map(key =>
            columnHelper.accessor(key, {
              header: () => <div className="text-left">{key}</div>,
              cell: info => {
                const rowData = info.row.original;
                const value = info.getValue();
                const isMatch = key === rowData.metric;
                const bgColor = isMatch ? theme?.matchBgColor : theme?.notMatchBgColor;
                const textColor = isMatch ? theme?.matchTextColor : theme?.notMatchTextColor;

                return <div className={`px-6 py-4 text-left font-medium ${bgColor} ${textColor}`}>{value}</div>;
              },
              size: 50,
              meta: { removePadding: true },
            }),
          ),
        ],
      }),
    ];
  };

  const getData = (stats: ModelStats) =>
    Object.entries(stats.results).map(([metric, values]) => ({
      metric,
      ...(typeof values === 'object' && values !== null ? values : {}),
    }));

  return (
    <>
      <div className="m-3 flex flex-col gap-2">
        <p className="text-2xl font-medium">
          <span>{i18n.t('ai:modelStats.analysis')}</span>:{' '}
          <span>{moment(actionInitiatedAt).format(STATS_DATE_FORMAT)} </span>
          <span className="text-lg font-normal">{moment(actionInitiatedAt).format(STATS_TIME_FORMAT)}</span>
        </p>
        <Detail
          label={i18n.t('ai:modelStats.status')}
          value={i18n.t('ai:modelStats.finished')}
          valueClassName="text-green-500"
        />
        {properties?.maxPredictionDays && (
          <Detail
            label={i18n.t('ai:modelStats.predictionDay')}
            value={`${properties.maxPredictionDays} ${i18n.t('ai:modelStats.days')}`}
            testHook="perdictionDaysInfo"
          />
        )}
      </div>
      <div className="flex items-center gap-2">
        <Heading kind="h3" className="inline-block !p-2" title={i18n.t('ai:modelStats.analysisResults')} />
      </div>
      <Panel>
        <Detail
          label={i18n.t('ai:modelStats.modelStatus')}
          value={status}
          testHook="status"
          labelClassName="font-medium text-gray-800"
        />
        <Detail
          label={i18n.t('ai:modelStats.precision')}
          tooltip={i18n.t('ai:modelStats.tooltip.precision')}
          value={`${decimalToPercent(properties.precision)}%`}
          testHook="precision"
          labelClassName="font-medium text-gray-800"
        />
        <Detail
          label={i18n.t('ai:modelStats.accuracy')}
          tooltip={i18n.t('ai:modelStats.tooltip.accuracy')}
          value={`${decimalToPercent(properties.accuracy)}%`}
          testHook="accuracy"
          labelClassName="font-medium text-gray-800"
        />
        <Detail
          label={i18n.t('ai:modelStats.f1Score')}
          tooltip={i18n.t('ai:modelStats.tooltip.f1Score')}
          value={`${decimalToPercent(properties.f1Score)}%`}
          testHook="f1Score"
          labelClassName="font-medium text-gray-800"
        />
        <Detail
          label={i18n.t('ai:modelStats.recall')}
          tooltip={i18n.t('ai:modelStats.tooltip.recall')}
          value={properties.recall ? `${decimalToPercent(properties.recall)}%` : 'NA'}
          testHook="recall"
          labelClassName="font-medium text-gray-800"
        />
      </Panel>
      <Panel className="mt-5">
        <div className="my-4 ml-1 flex items-center justify-between">
          <div className="flex flex-row items-center gap-1">
            <Heading kind="h3" className="inline-block !py-2" title={i18n.t('ai:modelStats.confusionMatrix')} />
            <Icons icon="info" className="h-6 w-5 text-gray-400" tooltip={i18n.t('ai:modelStats.metricsTooltip')} />
          </div>
          <div>
            <BtnIcon
              icon="setting"
              onClick={() => {
                toggleDropdown();
              }}
              testHook="changeTableTheme"
            />
            {openDropDown && (
              <Dropdown
                actions={THEME_NAMES.map(item => ({
                  ...item,
                  onClick: () => {
                    setThemeColor(item.name);
                  },
                }))}
                toggleDropdown={toggleDropdown}
              />
            )}
          </div>
        </div>
        <UITable data={getData(stats)} columns={getColumns(stats)} testHook="modelStatsTable" />
      </Panel>
    </>
  );
};

export default TrainingAnalysis;
