import { get } from 'lodash';

export const getAllClientDomains = state =>
  state.context.clients.domainList.allIds.map(id => state.context.clients.domainList.byId[id]);
/**
 * get a domain
 * @param state
 * @param domainId
 * @returns {*}
 */
export const getClientDomain = (state, domainId) => get(state, `context.clients.domainList.byId.${domainId}`);
/**
 *
 * @param state
 * @returns {*}
 */
export const isFetchingDomains = state => get(state, 'context.clients.domainList.ui.isFetching');

/**
 * State flag
 * @param state
 * @returns {*}
 */
export const shouldFetchDomains = state => get(state, 'context.clients.domainList.ui.shouldFetchAll');
