angular.module('webUi.filter.dimensionsFilter', [])

    .filter('dimensionsFilter', [function() {
        return function(input) {

            if(!_.isArray(input)) {
                return input;
            }

            var sorted = _.sortBy(input, function(placement) {
                if(!placement.placementNumber) {
                    return 0;
                }
                return this.floor(placement.placementNumber) || 0;
            }, Math);

            return sorted;
        };
    }]);
