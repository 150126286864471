import SecurityMetaService from '~/common/SecurityMetaService.js';
import history, { setAngularDeps } from '~/common/history';

/**
 * @namespace webUi
 */
angular
  .module('webUi', [
    'LocalStorageModule',
    'app-templates',
    'component-templates',
    'ui.router',
    'webUi.ui',
    'webUi.filter',
    'webUi.service',
    'webUi.directive',
    'webUi.component',
    'ui.scrollfix',
    'webUi.common.Utils',
    'webUi.service.ajaxBusy',
    'restangular',
    'ui.bootstrap',
    'ngSanitize',
    'ngAnimate',
    'flow',
    'ui.keypress',
    'react',
    'toaster',
  ])
  /**
   * Avoid warning logs in the console from old accordion syntax in accordion,
   * is necessary because in cookie permission template we can not upgrade to the new uib-accordion
   * NOTE: to upgrade the version 1.0 ui-bootstrap they remove the old code for accordion so is a breaking change
   */
  .value('$accordionSuppressWarning', true)
  .config([
    '$provide',
    '$locationProvider',
    '$urlRouterProvider',
    '$httpProvider',
    '$exceptionHandlerProvider',
    'RestangularProvider',
    'flowFactoryProvider',
    'UUIDServiceProvider',
    'UtilsProvider',
    'localStorageServiceProvider',
    /**
     * @param $provide
     * @param $locationProvider
     * @param $urlRouterProvider
     * @param $httpProvider
     * @param $exceptionHandlerProvider
     * @param RestangularProvider
     * @param flowFactoryProvider
     */

    function config(
      $provide,
      $locationProvider,
      $urlRouterProvider,
      $httpProvider,
      $exceptionHandlerProvider,
      RestangularProvider,
      flowFactoryProvider,
      UUIDServiceProvider,
      UtilsProvider,
      localStorageServiceProvider,
    ) {
      // Check for useNext cookie
      const useNextCookie = document.cookie
        ? document.cookie.split('; ').find(row => row.startsWith('useNext='))
        : undefined;

      const useNextValueFromCookie = useNextCookie ? useNextCookie.split('=')[1] : undefined;

      // Check for useNext in localStorage
      const useNextValueFromLocalStorage = localStorage.getItem('useNext');

      // Determine if we should redirect to V2 based on the environment
      const isLocalHost = window.location.hostname === 'localhost';
      const useNextValue = isLocalHost ? useNextValueFromCookie : useNextValueFromLocalStorage;

      // Redirect to V2 if useNextValue is true
      if (useNextValue === 'true') {
        const { pathname, search } = window.location;
        const encodedQueryString = encodeURIComponent(`${pathname}${search}`);
        const redirectUrl = encodedQueryString !== '/' ? encodedQueryString : '/auth';

        const redirectTo = isLocalHost
          ? `https://localhost:5173?redirectUrl=${redirectUrl}`
          : `/frontend/v2/index.html?redirectUrl=${redirectUrl}`;

        window.location.href = redirectTo;
        return;
      }

      var body = document.getElementsByTagName('body')[0];

      // Create the toaster-container element
      var toasterContainer = document.createElement('toaster-container');
      toasterContainer.setAttribute('toaster-options', "{'close-button': true, 'time-out': 5000}");

      // Append the toaster-container element to the body
      body.appendChild(toasterContainer);

      localStorageServiceProvider.setPrefix('r42WebUi');
      localStorageServiceProvider.setStorageType('sessionStorage');

      $locationProvider.html5Mode(true);

      // for backwards compatibility with old browser
      flowFactoryProvider.factory = fustyFlowFactory;

      // fallback to 404
      $urlRouterProvider.otherwise(function ($injector) {
        var $location = $injector.get('$location');
        var siteResult = $location.path().match(/\/site-[0-9]+/g);
        if (siteResult) {
          return siteResult[0] + '/error/404';
        }

        // don't redirect to 404 on /, let the gotoDefault state handle it which is kicked in later
        if ($location.path() !== '/') {
          return '/site-undefined/error/404';
        }
      });

      /**
       * Make all Restangular requests to go through the main api
       */
      RestangularProvider.setBaseUrl('/api');

      RestangularProvider.addResponseInterceptor(function CustomResponseExtractor(
        responseBody,
        operation,
        what,
        url,
        response,
        deferred,
      ) {
        var siteId = $httpProvider.defaults.headers.common['X-siteId'];
        var $exceptionHandler = $exceptionHandlerProvider.$get();

        if (responseBody && responseBody.status && responseBody.status.success) {
          // Extract the data from the result
          return responseBody.data;
        } else if (_.isEmpty(responseBody) && response.status >= 200 && response.status <= 299) {
          return null;
        }

        var r42Error = !_.isEmpty(responseBody) && responseBody.status ? responseBody.status.error.code : null;

        if (r42Error === 'ERR_TWO_FACTOR_AUTHENTICATION') {
          document.location.href = '/iplock';
        } else if (r42Error === 'ERR_OUTSIDE_OF_SUBNET' || r42Error === 'ERR_USER_BLOCKED') {
          document.location.href = '/iplock?outsideOfSubnet=true';
        } else if (r42Error === 'ERR_NO_SITE_ACCESS') {
          document.location.href = '/site-' + siteId + '/error/no-site-access';
        } else if (r42Error === 'ERR_LOGIN_REQUIRED') {
          // on 401 force page refresh to show login page
          // eslint-disable-next-line no-self-assign
          document.location.href = document.location.href;
        } else if (r42Error === 'ERR_FORBIDDEN' || response.status === 403) {
          // on 403 show access denied page
          document.location.href = '/site-' + siteId + '/error/403';
        } else if (r42Error === 'ERR_NOTFOUND') {
          // on 404 show access denied page
          document.location.href = '/site-' + siteId + '/error/404';
        } else if (r42Error === 'ERR_PASSWORD_RESET') {
          document.location.href =
            '/login/passwordReset?username=' + responseBody.status.user + '&hash=' + responseBody.status.hash;
        }

        //any unhandled error in the restangular promise should be handled
        deferred.promise['catch'](function (maybeHandledResponse) {
          if (!maybeHandledResponse || !maybeHandledResponse.errorHandled) {
            $exceptionHandler('RestangularError', maybeHandledResponse);
          }
        });

        //if the error was not a specific error, reject it
        deferred.reject(response);
      });

      // Define AccessDenied errors
      window.AccessDeniedError = function (message) {
        this.name = 'AccessDeniedError';
        this.message = message || '';
      };
      window.AccessDeniedError.prototype = Error.prototype;
    },
  ])

  /**
   * @namespace AppController
   * @memberOf webUi
   * @returns {AppController}
   */
  .controller('AppController', [
    '$scope',
    '$rootScope',
    '$location',
    '$urlRouter',
    '$timeout',
    '$document',
    '$state',
    '$uibModalStack',
    'SecurityService',
    'TitleService',
    'PubSubService',
    'ExceptionHandlerHelperService',
    'UserService',
    'HelperService',
    'PermissionService',
    'CsrfService',
    'GoogleAnalyticsService',
    'localStorageService',
    'TimeService',
    function (
      $scope,
      $rootScope,
      $location,
      $urlRouter,
      $timeout,
      $document,
      $state,
      $uibModalStack,
      SecurityService,
      TitleService,
      PubSubService,
      ExceptionHandlerHelperService,
      UserService,
      HelperService,
      PermissionService,
      CsrfService,
      GoogleAnalyticsService,
      localStorageService,
      TimeService,
    ) {
      var securityContext;
      var startLoadTimeMillis = TimeService.getCurrentTimeMillis();
      var isFirstPageLoad = true;

      setAngularDeps($urlRouter, $timeout, $rootScope);

      SecurityService.getSecurityContext().then(function (context) {
        securityContext = context;
      });

      var altBlock = ['A', 'C', 'D', 'R', 'S', 'T', 'U'];
      var ctrlBlock = ['S', 'P'];
      var controlKeys = [16, 17, 18];
      var handleKeyDown = function handleKeyDown(event) {
        var keycodeChar = String.fromCharCode(event.which);
        var data = {
          keycode: event.which,
          charLower: keycodeChar.toLowerCase(),
          charUpper: keycodeChar,
          altKey: event.altKey,
          ctrlKey: event.ctrlKey || event.metaKey,
          shiftKey: event.shiftKey,
        };

        // Only match events that bubble to body or with special key (but not just the special key)
        if (
          (event.target.nodeName.toLowerCase() === 'body' || data.altKey || data.ctrlKey) &&
          !_.contains(controlKeys, data.keycode)
        ) {
          $rootScope.$broadcast('hotKeyEvent', data);
        }

        if (data.altKey && _.contains(altBlock, keycodeChar)) {
          event.preventDefault();
          return false;
        }

        if (data.ctrlKey && _.contains(ctrlBlock, keycodeChar)) {
          event.preventDefault();
          return false;
        }
      };

      $document.keydown(handleKeyDown);

      /**
       * app init function
       * @param params
       * @param {String} params.defaultSite
       * @param {String} params.assetsPrefix
       * @param {String} params.demoSiteNumber
       */
      var initHandler = function (params) {
        /**
         * Global app params
         * @type {{defaultSite: String, assetsPrefix: String, demoSiteNumber: String}}
         */
        $rootScope.r42Params = params || {};

        CsrfService.setCsrfToken(params.csrfToken);
        SecurityMetaService.setCsrfToken(params.csrfToken);

        var path = $location.path();
        if (path === '/' || path === '') {
          SecurityService.goToDefaultState();
        }

        $rootScope.assetsPrefix = params.assetsPrefix;
        SecurityMetaService.setAssetsPrefix(params.assetsPrefix);
      };

      var siteIdRegex = new RegExp('/site-([0-9]+)');

      // track time spent
      var startUsingTimeMillis = 0;
      var timeSpent = 0;
      var lastActiveTimeMillis = TimeService.getCurrentTimeMillis();

      function handleVisibilityChange() {
        if (document.hidden) {
          // Calculate time spent on the page before it was hidden
          timeSpent += TimeService.getCurrentTimeMillis() - lastActiveTimeMillis;
        } else {
          lastActiveTimeMillis = TimeService.getCurrentTimeMillis();
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // log state changes
      $scope.$on('$locationChangeStart', function (event, url) {
        var siteIdResult = siteIdRegex.exec(url);

        if (siteIdResult !== null) {
          const id = parseInt(siteIdResult[1], 10);
          SecurityService.setCurrentSite(id);
          SecurityMetaService.setSiteId(id);
        }
      });

      // TODO FIX ME: THIS IS AN HOT FIX, WE HAVE TO FIND A PROPER SOLUTION TO AVOID THE COMPARISON
      // Update React View
      $rootScope.$on('$locationChangeSuccess', function () {
        const url = $location.url();
        if (
          !url.includes('tagmanagement/tags/list')
          // && !url.includes('tagmanagement/newPaths/list')
        ) {
          history.replace(url);
        }
      });

      // check time zone of user browser and site are the same or not
      var isDifferenceTimeZone = function () {
        var userOffset = new Date().getTimezoneOffset();
        var siteOffset = SecurityService.getCurrentOffset();

        return siteOffset === userOffset;
      };
      /**
       * A more appropriate place to do the permission handling would be in $viewContentLoading or $stateChangeStart but in the latter
       * the security context is not done loading and the first one doesn't fire in parent views
       */
      $scope.$on('$viewContentLoaded', function () {
        var currentState = $state.current;
        //skip change for states when security context is not fully initialized
        if (currentState && securityContext && !_.isUndefined(securityContext.currentSiteId)) {
          var isAllowed = PermissionService.isAllowedToGoToNavState(currentState, securityContext);
          if (!isAllowed) {
            $state.transitionToStateWithSite('site.error.403', {});
          }
          $scope.hasTimezoneDifference = !isDifferenceTimeZone();
        }
      });

      $scope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
        const isBeta = localStorage.getItem('useBeta') === 'true';
        const isPre = localStorage.getItem('usePre') === 'true';
        if (!isFirstPageLoad) {
          startLoadTimeMillis = TimeService.getCurrentTimeMillis();
        }

        const queryParams = $location.search();
        // We check false explicitly as false is a valid parameter
        if (queryParams.useBeta !== undefined) {
          localStorage.setItem('useBeta', queryParams.useBeta);
        }

        if (queryParams.useJoDemoMode !== undefined) {
          localStorage.setItem('useJoDemoMode', queryParams.useJoDemoMode);
        }

        // if we activated the showIntros in the previous step, don't do it for the next one
        if (fromParams.showIntros) {
          toParams.showIntros = null;
        }
        //this builds the url we want to access. We are storing it to the session just to use it when redirecting from successful iplock login
        var nextUrl = $state.href(toState, toParams, { inherit: true });
        localStorageService.set('nextUrl', nextUrl);
        //this builds the current url, we are using it to return back if the user exists advanced security when requested for 2FA
        var currentUrl = $state.href(fromState, fromParams);
        localStorageService.set('currentUrl', currentUrl);
        // check existing modals and close them, only if doesn't have a state
        if (!toState.modal && !toState.isSubModal) {
          var topModal;
          while (typeof (topModal = $uibModalStack.getTop()) !== 'undefined') {
            topModal.key.close();
          }
        }
        ExceptionHandlerHelperService.resetDigestError();
        if (isBeta || isPre) {
          const intercept = $scope.interceptPreliminaryFeatures({
            toParams,
            $state,
            e,
          });
          if (toState.beta && (isBeta || isPre)) {
            intercept(toState.beta);
          }
          if (toState.alpha && isPre) {
            intercept(toState.alpha);
          }
        }

        if (!isFirstPageLoad) {
          timeSpent += TimeService.getCurrentTimeMillis() - lastActiveTimeMillis;
        }
      });
      /**
       * In some modules we require to set some style, like make a full-fluid template without padding
       * @param state
       */
      $scope.setStylingDependingModule = function setStylingDependingModule(state) {
        //If the state is journey, hide subnavigation and add class 'pane-min' to avoid empty space between the menu and content
        if (
          _.startsWith(state.name, 'site.user') ||
          _.startsWith(state.name, 'site.dashboard') ||
          _.startsWith(state.name, 'site.workflows') ||
          _.startsWith(state.name, 'site.audiences')
        ) {
          $scope.makeFullFluidTemplate = true;
        } else {
          $scope.makeFullFluidTemplate = false;
        }
      };

      $scope.interceptPreliminaryFeatures =
        ({ toParams, $state, e }) =>
        substitution => {
          const { paramsMap, name } = substitution;
          const paramsNew = Object.keys(paramsMap).reduce((acc, next) => {
            acc[next] = toParams[paramsMap[next]];
            return acc;
          }, toParams);
          $state.go(name, paramsNew, { reload: false, inherit: true });
          e.preventDefault();
        };

      $scope.$on('$stateChangeSuccess', function (event, state) {
        var pageLoadTimeMillis = TimeService.getCurrentTimeMillis() - startLoadTimeMillis;
        TitleService.updateComponent(state);
        $scope.isReact = state.isReact;

        $scope.leftMenuEnabled = typeof state.leftMenu !== 'undefined' && state.leftMenu;
        $scope.setStylingDependingModule(state);
        GoogleAnalyticsService.callGA(state, $location.absUrl(), pageLoadTimeMillis, isFirstPageLoad, timeSpent);
        isFirstPageLoad = false;
        SecurityService.getSecurityContext().then(function (context) {
          if (UserService.shouldShowWelcomeScreen(context)) {
            UserService.showWelcomeScreen();
          }
        });

        startUsingTimeMillis = TimeService.getCurrentTimeMillis();
        lastActiveTimeMillis = startUsingTimeMillis;
        timeSpent = 0;
      });

      $scope.$on('$stateChangeError', function (event, state, params, fromState, fromParams, error) {
        r42log('Failed state switch ' + state.name + ' with params: ' + JSON.stringify(params, error));

        if (error instanceof AccessDeniedError) {
          $state.go('site.error.403', { siteNumber: SecurityService.getCurrentSite() });
        }
      });

      $scope.init = initHandler;
      $scope.leftMenuEnabled = false;
      $scope.hasTimezoneDifference = false;

      /**
       * Fixes firefox toElement
       * @param $event
       */
      $scope.registerBodyClick = function ($event) {
        var clickedElement = $event.toElement || $event.target;
        PubSubService.publishBodyClick(clickedElement, $event);
      };

      /**
       * Activate resizable menu
       */
      var initMenuResize = function (menuDiv, contentDiv, storageVar) {
        $(menuDiv).resizable({
          handles: 'e',
          minWidth: 287,
          maxWidth: 600,
          resize: function () {
            var menuWidth = parseInt($(menuDiv).css('width'), 10);
            localStorage.setItem(storageVar, menuWidth);
            resizeContentWithMenu(contentDiv, menuWidth);
          },
        });

        if (localStorage.getItem(storageVar) !== null) {
          var menuWidth = _.parseInt(localStorage.getItem(storageVar));
          $(menuDiv).css('width', menuWidth);
          resizeContentWithMenu(contentDiv, menuWidth);
        } else {
          resizeContentWithMenu(contentDiv, $(menuDiv).width());
        }
      };

      var resizeContentWithMenu = function (contentDiv, menuWidth) {
        var sizeLeft = parseInt(menuWidth + 3 + $('#main-navigation').width(), 10);
        $(contentDiv).css('left', sizeLeft);
        $(window).resize();
      };

      initMenuResize($('#left'), $('#main'), 'menuSize');

      ad_block_test(function (block) {
        if (block && !window.sessionStorage.adBlockNotification) {
          HelperService.showAdBlockNotification();
          window.sessionStorage.adBlockNotification = true;
        }
      });

      startUsingTimeMillis = TimeService.getCurrentTimeMillis();
      lastActiveTimeMillis = startUsingTimeMillis;
    },
  ]);

/**
 * Paul Irish safe console.log
 * @todo marius: extend with crossbrowser one @ https://github.com/patik/console.log-wrapper
 */
window.r42log = function () {
  r42log.history = r42log.history || [];
  r42log.history.push(arguments);
  if (window.console) {
    /* eslint no-console: "off" */
    console.log(Array.prototype.slice.call(arguments));
  }
};
