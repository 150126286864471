import React, { ReactElement } from 'react';

import { Field, FieldArray, FormikErrors, getIn } from 'formik';

import i18n from '~/i18n';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';

import CriteriasForm from './CriteriasForm';
import { Model, ModelProperty, Prediction } from '../types';

import './styles.scss';

type PredictionsFormProps = {
  predictions: Prediction[];
  errors: FormikErrors<Model>;
  properties: ModelProperty[];
};

function PredictionsForm({ predictions, errors, properties }: PredictionsFormProps): ReactElement {
  const getCriteriaErrorText = (index: number) => {
    if (typeof getIn(errors, `predictions[${index}].criterias`) === 'string') {
      return getIn(errors, `predictions[${index}].criterias`);
    }
    return null;
  };

  return (
    <FieldArray
      name="predictions"
      render={predictionHelpers => (
        <div>
          {predictions?.map((prediction: Prediction, index: number) => (
            <div key={index} className="Predictions" id={`predictions.${index}`}>
              <div className="Predictions-header">
                <Field
                  className="Predictions-header--name"
                  as={FormikInputField}
                  name={`predictions[${index}].name`}
                  label={i18n.t('ai:model.predictionName')}
                  errorText={getIn(errors, `predictions[${index}].name`)}
                />
                <BtnIcon
                  icon="delete"
                  tooltip={i18n.t('ai:model.deletePrediction')}
                  onClick={() => predictionHelpers.remove(index)}
                  testHook="predictionsDelete"
                />
              </div>
              <br />

              <CriteriasForm index={index} criterias={prediction.criterias} errors={errors} properties={properties} />
              <br />
              {getCriteriaErrorText(index) && (
                <Notification kind="error">
                  <p>{getCriteriaErrorText(index)}</p>
                </Notification>
              )}
            </div>
          ))}
          {properties.length > 0 && (
            <BtnOutlined
              className="mt-4"
              icon="add"
              size="xs"
              testHook="addPredictions"
              onClick={() => predictionHelpers.push({ name: '', criterias: [] })}
            >
              {i18n.t('ai:model.addPrediction')}
            </BtnOutlined>
          )}
        </div>
      )}
    />
  );
}

export default PredictionsForm;
