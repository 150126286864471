import { useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import React, { ReactElement, useContext, useState } from 'react';
import * as Yup from 'yup';
import { buildUrl, useAPI, changeUrl } from '~/common';
import Spin from '~/components/src/Spin';
import ActionButtons from '~/components/src/ActionButtons';
import EditableRulesList from '~/common/modules/filterRules/components/EditableRulesList';
import { joinCriteriaVariables } from '~/workflows/util';
import { shapeCriteriaRequest } from '~/profiles/audiences/utils';
import { Heading } from '~/components';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { FormikInputFieldType } from '~/context/components/APIKeys/types';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { getFilter, getRulesDefinitions, createFilter, updateFilter } from './dataService';
import { FilterListType, FilterPayload } from './types';
import { getRulesTreeFromTypes } from './util';
import { FiltersContext } from './FiltersContext';

const FieldLengths = {
  MIN: 2,
  MAX: 255,
} as const;

const makeUniqueFilterValidator = (existingFilters: FilterListType[]) => (inputValue: FormikInputFieldType) => {
  const lowerCaseVariables = existingFilters.map(existingFilter => existingFilter.name.toLowerCase());
  const isUsedAlready = inputValue && lowerCaseVariables.includes(inputValue?.toLowerCase());
  return !isUsedAlready;
};

function FilterEdit(): ReactElement {
  const params = useParams();
  const filterId = params.filterId || '';
  const isEdit = !!filterId;
  const [isValid, setIsValid] = useState(isEdit);

  const { data: rulesTree, isLoading: isLoadingRulesTree } = useAPI(async () => {
    const ruleDefinitions = await getRulesDefinitions();
    return getRulesTreeFromTypes(ruleDefinitions);
  });

  const { data: filter, isLoading: isLoadingFilter } = useAPI(() => {
    if (filterId) {
      return getFilter(filterId);
    }
    return Promise.resolve(null);
  }, [filterId]);

  const { filters: existingFilters, refetchFilters } = useContext(FiltersContext);

  const mapCriteria = (criteria: any) => joinCriteriaVariables(criteria || [], []);

  const initialValues = {
    name: filter?.name || '',
    rules: filter?.rules || [],
    criteria: filterId ? mapCriteria(filter?.rules?.map(item => [item]) || []) : [],
  };

  const formValidations = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(FieldLengths.MIN, i18n.t('validation:validation.minLength', { min: FieldLengths.MIN }))
      .max(FieldLengths.MAX, i18n.t('validation:validation.maxLength', { max: FieldLengths.MAX }))
      .test(
        'filterName-name-is-unique',
        i18n.t('filters:validation.uniqueFilter'),
        makeUniqueFilterValidator(existingFilters.filter(filter => filter.filterId !== filterId)),
      )
      .required(i18n.t('validation:validation.required')),
    criteria: Yup.array().min(1),
  });

  const handleSubmit = async (values: any) => {
    const payload: FilterPayload = {
      name: values?.name.trim(),
      filterRules: shapeCriteriaRequest(values?.criteria)?.map((item: { rules: any[] }) => item?.rules[0]),
    };

    if (filterId) {
      await updateFilter(filterId, payload);
      showSuccess({ header: i18n.t('filters:form.updateSuccess') });
      changeUrl(`profiles/filters/view/${filterId}`);
    } else {
      await createFilter(payload);
      showSuccess({ header: i18n.t('filters:form.createSuccess') });
      changeUrl('profiles/filters');
    }
    refetchFilters();
  };

  if ((!!filterId || isLoadingFilter) && isLoadingRulesTree) {
    return <Spin />;
  }
  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50 pb-6">
      <div className="flex w-2/3 flex-col">
        <Heading
          title={i18n.t(`filters:form.${filterId ? 'edit' : 'create'}`)}
          crumbs={[
            {
              title: i18n.t('filters:back'),
              pathname: buildUrl('profiles/filters'),
            },
          ]}
        />
        <div className="mt-4 flex flex-col gap-4">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={formValidations}
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
          >
            {({ handleSubmit, errors, isValid: formIsValid, isSubmitting, touched, values, setFieldValue }) => (
              <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <label>{i18n.t('filters:form.nameLabel')}</label>
                <Field
                  as={FormikInputField}
                  name="name"
                  className="w-2/5"
                  placeholder={i18n.t('filters:form.namePlaceholder')}
                  errorText={touched.name && errors.name}
                />
                <EditableRulesList
                  criteria={values.criteria}
                  ruleTypesTree={rulesTree || []}
                  onChange={(criteriaState: any) => {
                    setFieldValue('criteria', criteriaState.criteria);
                    setIsValid(criteriaState.isValid);
                  }}
                  isSubmitted={isSubmitting}
                  enableGroupBy
                  isValid={isValid}
                />
                <ActionButtons
                  testHook="createFilter"
                  isConfirmEnabled={formIsValid && values?.criteria?.length > 0 && isValid}
                  onConfirm={handleSubmit}
                  onDecline={() => changeUrl('profiles/filters')}
                  confirmText={i18n.t('common:actions.save')}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default FilterEdit;
