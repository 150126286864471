import React, { useState } from 'react';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import DeletePopup from '~/components/src/DeletePopup';
import { Notification, PickerView } from '~/components';
import { showSuccess } from '~/notificationCenter';
import {
  removeTagCounter,
  updateTagCounterDescription,
  updateTagCounterName,
  updateTagCounterNumber,
  updateTagCounterShownOnDashboard,
} from '../dataService';
import { IUpdate, TTagCounterActionsProps, TTagCountersForm } from '../types';
import TagCounterForm from './TagCounterForm';

const TagCounterActions = ({ tagCounter, refetchTagCounters, hasTagEdit }: TTagCounterActionsProps) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { name, counterId } = tagCounter;

  const handleDelete = async () => {
    await removeTagCounter(tagCounter?.counterId);
    showSuccess({ body: i18n.t('tagCounters:form.api.deleted') });
    setShowDeleteDialog(false);
    refetchTagCounters();
  };

  const handleUpdate = async (values: TTagCountersForm) => {
    const { name, description, number, shownOnDashboard } = values;

    const updates: IUpdate[] = [
      { key: 'name', value: name, updater: updateTagCounterName },
      { key: 'description', value: description, updater: updateTagCounterDescription },
      { key: 'number', value: number, updater: updateTagCounterNumber },
      { key: 'shownOnDashboard', value: shownOnDashboard, updater: updateTagCounterShownOnDashboard },
    ];

    const updatePromises = updates
      .filter(({ key, value }) => value !== tagCounter?.[key])
      .map(({ key, value, updater }) => updater({ [key]: value, counterId }));

    if (updatePromises.length > 0) {
      await Promise.all(updatePromises);
    }

    showSuccess({ body: i18n.t('tagCounters:form.api.updated') });
    refetchTagCounters();
    setShowForm(false);
  };

  return (
    <>
      <div className="flex justify-center gap-1">
        <BtnIcon
          disabled={!hasTagEdit}
          icon="edit"
          onClick={() => setShowForm(true)}
          tooltip={i18n.t(`tagCounters:actions.${hasTagEdit ? 'edit' : 'noPermission'}`)}
          testHook="editTagCounter"
        />
        <BtnIcon
          disabled={!hasTagEdit}
          icon="delete"
          onClick={() => setShowDeleteDialog(true)}
          tooltip={i18n.t(`tagCounters:actions.${hasTagEdit ? 'delete' : 'noPermission'}`)}
          testHook="deleteTagCounter"
        />
        {showDeleteDialog && (
          <DeletePopup
            title={i18n.t('tagCounters:delete.title')}
            hidePopup={() => setShowDeleteDialog(false)}
            onClick={() => handleDelete()}
          >
            <Notification>{i18n.t('tagCounters:delete.message', { name })}</Notification>
          </DeletePopup>
        )}
      </div>

      {showForm && (
        <PickerView
          pickerTitle={i18n.t('tagCounters:form.edit')}
          handlePickerVisibility={setShowForm}
          className="!h-auto !w-1/3"
        >
          <TagCounterForm counterId={counterId} onSubmit={handleUpdate} setShowForm={setShowForm} />
        </PickerView>
      )}
    </>
  );
};

export default TagCounterActions;
