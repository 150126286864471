/**
 * Method to create form data to be send as multipart
 * Backend still requires fields from Flowjs uploader
 * @param file
 * @returns {FormData}
 */
export const prepareImageFormData = file => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('flowTotalChunks', 1);
  formData.append('flowChunkNumber', 1);
  formData.append('flowIdentifier', `${file.size}-${file.name}`.replace('.', ''));
  formData.append('flowFilename', file.name);
  return formData;
};
