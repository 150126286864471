import React from 'react';
import { isNull } from 'lodash';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import Icons from '~/components/src/Icons';
import { RowActions } from '~/components/src/Table/components';
import { Link, Tooltip, SkeletonLoader } from '~/components';
import { formatNumber } from '~/common';
import UsageIcons from './UsageIcons';
import { ALLOWED_NUMBER_OF_PROPERTIES, ENGAGEMENT_USED_IN } from '../engagements';

const columnHelper = createColumnHelper();

const EngagementsList = ({
  list,
  onViewClick,
  onDependantIconClick,
  canEditProfiles,
  onEditClick,
  onDeleteClick,
  query,
  isFetchingActivities,
}) => {
  const renderInsightIcon = (hasDependants, hasActivity) => {
    if (hasDependants && !hasActivity) {
      return (
        <Icons
          icon="warning"
          className="size-7 t-insights-warning text-yellow-500"
          tooltip={i18n.t('engagements:list.insights.tooltip.warning')}
        />
      );
    } else if (!hasDependants && hasActivity) {
      return (
        <Icons
          icon="report"
          className="size-7 t-insights-error text-red-500"
          tooltip={i18n.t('engagements:list.insights.tooltip.error')}
        />
      );
    }
    return <div />;
  };
  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="inline-block text-left">{i18n.t('engagements:list.name')}</div>,
      cell: info => (
        <div className="text-left">
          <Link onClick={() => onViewClick(info?.row?.original?.engagementId)}>{info.getValue()}</Link>
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('numberOfProperties', {
      header: () => <div className="text-right">{i18n.t('engagements:list.numberOfProperties')}</div>,
      cell: info => <div className="text-right">{`${info.getValue()}/${ALLOWED_NUMBER_OF_PROPERTIES}`}</div>,
      size: 300,
    }),
    columnHelper.accessor('numberOfUsedProperties', {
      header: () => <div className="text-right">{i18n.t('engagements:list.numberOfUsedProperties')}</div>,
      cell: info => {
        const engagement = info?.row?.original;
        return (
          <div className="text-right">{`${engagement.numberOfUsedProperties}/${engagement.numberOfProperties}`}</div>
        );
      },
      size: 300,
    }),
    columnHelper.accessor('activity', {
      header: () => <div className="text-right">{i18n.t('engagements:list.activity')}</div>,
      cell: info => {
        if (isFetchingActivities) {
          return <SkeletonLoader type="text" />;
        }
        const { activity, dependantTypes } = info?.row?.original;
        const hasDependants = dependantTypes.length > 0;
        const hasActivity = activity > 0;

        return (
          <div className="text-right">
            {!isNull(activity) ? (
              <div className="flex items-center justify-between gap-1 px-1">
                {renderInsightIcon(hasDependants, hasActivity)}
                <Tooltip tooltip={i18n.t('engagements:general.activityTooltip')}>{formatNumber(activity)}</Tooltip>
              </div>
            ) : (
              <span className="text-xs italic text-red-500">{i18n.t('engagements:general.activityError')}</span>
            )}
          </div>
        );
      },
      size: 300,
    }),
    columnHelper.accessor('usedIn', {
      header: () => <div className="text-center">{i18n.t('engagements:list.usedIn')}</div>,
      cell: info => {
        const engagement = info?.row?.original;

        return (
          <div className="flex items-center justify-center">
            <UsageIcons
              dependantTypes={engagement.dependantTypes}
              elementUsedIn={ENGAGEMENT_USED_IN}
              elementId={engagement.engagementId}
              onDependantIconClick={onDependantIconClick}
            />
          </div>
        );
      },
      size: 400,
      enableSorting: false,
    }),
    columnHelper.accessor('action', {
      header: () => <div className="text-center">{i18n.t('engagements:list.action')}</div>,
      cell: info => {
        const engagement = info?.row?.original;

        const actions = [
          {
            name: i18n.t('engagements:common.view'),
            isDisabled: false,
            onClick: () => onViewClick(engagement.engagementId),
            tooltip: i18n.t('engagements:common.view'),
            icon: () => {},
            testHook: 'view',
          },
          {
            name: i18n.t('engagements:common.edit'),
            isDisabled: !canEditProfiles,
            onClick: () => onEditClick(engagement.engagementId),
            tooltip: i18n.t('engagements:common.edit'),
            icon: () => {},
            testHook: 'edit',
          },
          {
            name: i18n.t('engagements:common.delete'),
            type: 'delete',
            isDisabled: !canEditProfiles || engagement.hasDependants,
            onClick: () => onDeleteClick(engagement),
            tooltip: engagement.hasDependants
              ? i18n.t('engagements:edit.removeActionDisabledTooltip')
              : i18n.t('engagements:common.delete'),
            icon: () => {},
            testHook: 'delete',
          },
        ];
        return (
          <div className="text-center">
            <RowActions actions={actions} />
          </div>
        );
      },
      size: 100,
      enableSorting: false,
    }),
  ];
  return (
    <UITable
      testHook="engagementsTable"
      data={list}
      columns={getColumns()}
      emptyMessage={
        query.length !== 0 ? i18n.t('engagements:list.noResults.header') : i18n.t('engagements:list.empty.header')
      }
      emptyBody={query.length !== 0 ? i18n.t('engagements:list.noResults.body') : i18n.t('engagements:list.empty.body')}
      searchQuery={query}
      enableSorting
      enableFilters
    />
  );
};

export default EngagementsList;
