angular.module('webUi.ui.content.campaigns.placementGroups.placements.export', [ 'webUi.service', 'ui.bootstrap' ])

    .controller('AdServingPlacementExportController', [ '$scope', '$uibModalInstance', 'dialogsModel', 'CodeMirrorService', 'PlacementService', 'SecurityService',
        function AdServingPlacementExportController( $scope, $uibModalInstance, dialogsModel, CodeMirrorService, PlacementService, SecurityService) {

            var vm = this;

            vm.campaign = dialogsModel.campaign;

            vm.codemirrorOptions = CodeMirrorService.getDefaultHtmlOptions(true);

            vm.placementHTML = function(placementData, adserverHost){
                _.each(placementData, function(placement){
                    placement.code = PlacementService.getPlacementExportCode(dialogsModel.campaign, placement, SecurityService.getCurrentSite(), adserverHost);
                });
            };

            vm.placementJS = function(placementData, adserverHost){
                _.each(placementData, function(placement){
                    placement.code = PlacementService.getPlacementExportCodeJs(dialogsModel.campaign, placement, SecurityService.getCurrentSite(), adserverHost);
                });
            };

            PlacementService.getAdserverHost().then(function(adserverHost){
                var placements = dialogsModel.placements;
                var placementData = [];

                _.each(placements, function(placement){
                    placementData.push(_.cloneDeep(placement));
                });

                vm.adserverHost = adserverHost;
                vm.placementData = placementData;

                vm.placementHTML(vm.placementData, vm.adserverHost);
            });

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
