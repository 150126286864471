export default {
  isBlockSuccessful: 'FINISHED_SUCCESSFULLY',
  scheduledColumns: [
    {
      sortKey: 'frequency',
      title: 'frequency',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: 'exportType',
      title: 'type',
      columnSize: 'u-size2of12',
    },
    {
      sortKey: 'remoteConnection',
      title: 'remoteConnection',
      columnSize: 'u-size2of12',
    },
    {
      sortKey: 'lastAttempts',
      title: 'lastAttempts',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: 'createdOnKey',
      title: 'createdOn',
      columnSize: 'u-size2of12',
      type: 'date',
    },
    {
      sortKey: 'modifiedOnKey',
      title: 'lastModified',
      columnSize: 'u-size2of12',
      type: 'date',
    },
    {
      sortKey: 'size',
      title: 'size',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: '',
      title: 'actions',
      columnSize: 'u-size1of12',
    },
  ],
  manualColumns: [
    {
      sortKey: 'type',
      title: 'type',
      columnSize: 'u-size2of12',
    },
    {
      sortKey: 'status',
      title: 'status',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: 'fromKey',
      title: 'exportStartDate',
      columnSize: 'u-size1of12',
      type: 'date',
    },
    {
      sortKey: 'toKey',
      title: 'exportEndDate',
      columnSize: 'u-size1of12',
      type: 'date',
    },
    {
      sortKey: 'createdOnKey',
      title: 'createdOn',
      columnSize: 'u-size1of12',
      type: 'date',
    },
    {
      sortKey: 'createdBy',
      title: 'createBy',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: 'finishedOnKey',
      title: 'finishedDate',
      columnSize: 'u-size1of12',
      type: 'date',
    },
    {
      sortKey: 'fileSize',
      title: 'size',
      columnSize: 'u-size1of12',
    },
    {
      sortKey: 'requestConfiguration',
      title: 'details',
      columnSize: 'u-size2of12',
    },
    {
      sortKey: 'utils',
      title: 'actions',
      columnSize: 'u-size1of12',
    },
  ],
};
