import { FILTER_CLAZZ } from './components/RuleCardEdit/Filter/constants';

export const RULE_FETCH_START = 'app/rules/RULE_FETCH_START';
export const RULE_FETCH_FAIL = 'app/rules/RULE_FETCH_FAIL';
export const RULE_FETCH_SUCCESS = 'app/rules/RULE_FETCH_SUCCESS';
export const RULE_VARIABLES_FETCH_START = 'app/rules/RULE_VARIABLES_FETCH_START';
export const RULE_VARIABLES_FETCH_FAIL = 'app/rules/RULE_VARIABLES_FETCH_FAIL';
export const RULE_VARIABLES_FETCH_SUCCESS = 'app/rules/RULE_VARIABLES_FETCH_SUCCESS';
export const RULE_VARIABLES_REFETCH_START = 'app/rules/RULE_VARIABLES_REFETCH_START';
export const RULE_VARIABLES_REFETCH_FAIL = 'app/rules/RULE_VARIABLES_REFETCH_FAIL';
export const RULE_VARIABLES_REFETCH_SUCCESS = 'app/rules/RULE_VARIABLES_REFETCH_SUCCESS';

export const RulesTypes = {
  Engagement: 'EngagementRule',
  Conversion: 'ConversionRule',
  ExternalFact: 'CustomRule',
  AiFact: 'AIFactRule',
  Experiment: 'ExperimentRule',
  Visit: 'VisitRule',
  ReusableAudience: 'ReusableSelectionRule',
  FixedTime: 'FixedTimeRule',
  DynamicWaitTime: 'DynamicWaitTimeRule',
  JourneyOverlap: 'JourneyOverlapRule',
  BannerView: 'BannerViewRule',
  DeleteMergedData: 'DeleteMergedDataRule',
  DeleteProfile: 'DeleteProfileRule',
  DeleteProfileMapping: 'DeleteProfileMappingRule',
  PageView: 'PageViewRule',
  Sync: 'SyncRule',
  ThirdPartySync: 'ThirdPartySyncRule',
  UserPreferences: 'UserPreferencesRule',
  JourneyProfileEnteredRule: 'JourneyProfileEnteredRule',
  JourneyProfileEnteredStepRule: 'JourneyProfileEnteredStepRule',
  JourneyProfileExitedByConditionRule: 'JourneyProfileExitedByConditionRule',
  JourneyProfileExpiredRule: 'JourneyProfileExpiredRule',
  JourneyProfileKickedOutRule: 'JourneyProfileKickedOutRule',
  JourneyProfileMergedRule: 'JourneyProfileMergedRule',
  JourneyProfileReachedGoalRule: 'JourneyProfileReachedGoalRule',
} as const;

export const RulesTypeNames = {
  ENGAGEMENT: 'EngagementRule',
  CONVERSION: 'ConversionRule',
  EXTERNAL: 'CustomRule',
  AI: 'AIFactRule',
  EXPERIMENT: 'ExperimentRule',
  VISIT: 'VisitRule',
  REUSABLE_SELECTION: 'ReusableSelectionRule',
  FIXED_WAIT_TIME: 'FixedTimeRule',
  DYNAMIC_WAIT_TIME: 'DynamicWaitTimeRule',
  JOURNEY_OVERLAP: 'JourneyOverlapRule',
  BANNER_VIEW: 'BannerViewRule',
  DELETE_MERGED_DATA: 'DeleteMergedDataRule',
  DELETE_PROFILE: 'DeleteProfileRule',
  DELETE_PROFILE_MAPPING: 'DeleteProfileMappingRule',
  PAGE_VIEW: 'PageViewRule',
  SYNC: 'SyncRule',
  THIRD_PARTY_SYNC: 'ThirdPartySyncRule',
  USER_PREFERENCES: 'UserPreferencesRule',
  Journey_Profile_Entered_Rule: 'JourneyProfileEnteredRule',
  Journey_Profile_Entered_Step_Rule: 'JourneyProfileEnteredStepRule',
  Journey_Profile_Exited_By_Condition_Rule: 'JourneyProfileExitedByConditionRule',
  Journey_Profile_Expired_Rule: 'JourneyProfileExpiredRule',
  Journey_Profile_Kicked_Out_Rule: 'JourneyProfileKickedOutRule',
  Journey_Profile_Merged_Rule: 'JourneyProfileMergedRule',
  Journey_Profile_Reached_Goal_Rule: 'JourneyProfileReachedGoalRule',
} as const;

export type FilterValueClassName = keyof typeof FILTER_CLAZZ;
