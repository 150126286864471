export default {
  nodeId: '01dfc3db-c77c-4fbe-8476-588508c6ab5c',
  name: 'Goal',
  totalProfilesReachedGoal: 312138,
  variantAnalysis: [
    {
      experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
      experimentVariantName: 'Experiment Variant',
      weight: 50,
      profilesEntered: 41219,
      profilesReachedGoal: 13123,
    },
    {
      experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
      experimentVariantName: 'Control group Variant',
      weight: 50,
      profilesEntered: 34538,
      profilesReachedGoal: 1232,
    },
  ],
  goalAnalysis: [
    {
      nodeId: '3adc46f4-7e4b-4389-9bff-8b33bc2062e5',
      nodeName: 'Control group',
      experimentVariantId: 'df5e03f8-81c1-484f-ba5f-9da5e2b8e465',
      experimentVariantName: 'Control group Variant',
      profilesEntered: 312218,
      profilesReachedGoal: 41313,
    },
    {
      nodeId: '51684374-2d17-4689-b54b-596696cdb65f',
      nodeName: 'Experiment',
      experimentVariantId: '8eb72fdf-f102-49ce-a253-8062afcdf80f',
      experimentVariantName: 'Experiment Variant',
      profilesEntered: 34549,
      profilesReachedGoal: 3754,
    },
  ],
};
