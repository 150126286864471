import React from 'react';
import { twMerge } from 'tailwind-merge';
import { TStepCardsProps } from '~/workflows/types';
import cx from 'classnames';
import { Tooltip } from '~/components';
import { truncateString } from '~/common/utils/StringUtils';

const StepCards = ({ allSteps, selectedStep, setSelectedStep }: TStepCardsProps) => (
  <div className="flex">
    {allSteps?.map(item => (
      <div
        key={item.nodeId}
        className={twMerge(
          cx('t-experimentStep mr-2 h-14 w-32 cursor-pointer flex-col rounded-lg border border-gray-300 p-2', {
            'border-blue-500': selectedStep === item.nodeId,
          }),
        )}
        onClick={() => setSelectedStep(item.nodeId)}
      >
        <div className="flex justify-between">
          <Tooltip tooltip={item.label}>
            <span
              className={twMerge(
                cx('text-gray-600', {
                  'text-blue-600': selectedStep === item.nodeId,
                }),
              )}
            >
              {truncateString(item.label, 12)}
            </span>
          </Tooltip>
        </div>
        <div className="t-profilesActive flex items-center gap-2">
          <span className="text-sm text-gray-400">Profiles:</span>
          {item.profilesActive}
        </div>
      </div>
    ))}
  </div>
);

export default StepCards;
