import React from 'react';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import { buildUrl } from '~/common';
import { INVITE_TYPES } from '../constants';
import { TDCAdvertiser } from '../types';

type TDoubleClickForm = {
  isEdit?: boolean;
  initialValue?: TDCAdvertiser;
  onSubmit: (payload: TDCAdvertiser) => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  advertiserId: Yup.string().trim().required(i18n.t('validation:validation.required')),
});

const DoubleClickForm = ({
  isEdit,
  initialValue = { name: '', advertiserId: '', inviteType: '' },
  onSubmit,
}: TDoubleClickForm) => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValue}
      enableReinitialize={true}
    >
      {({ handleSubmit, isValid, errors, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-2 py-4">
          <Field
            className="mb-3"
            name="name"
            as={FormikInputField}
            label={i18n.t('connectors:advertisers.doubleClick.form.name')}
            placeholder={i18n.t('connectors:advertisers.doubleClick.form.placeholder')}
            errorText={errors?.name}
            autoFocus={true}
          />

          <Field
            className="mb-3"
            name="advertiserId"
            type="number"
            as={FormikInputField}
            label={i18n.t('connectors:advertisers.doubleClick.form.advertiserId')}
            placeholder={i18n.t('connectors:advertisers.doubleClick.form.placeholder')}
            disabled={isEdit}
            errorText={errors?.advertiserId}
          />

          <Field
            className="mb-3"
            name="inviteType"
            label={i18n.t('connectors:advertisers.doubleClick.form.inviteType')}
            as={FormikSelectField}
            getOptionLabel={(option: { label: string; value: string }) => option.label}
            getOptionValue={(option: { label: string; value: string }) => option.value}
            customSetFieldValue={(name: string, option: { label: string; value: string }) => {
              setFieldValue(name, option.value);
            }}
            options={INVITE_TYPES}
            errorText={errors?.inviteType}
          />
          <ActionButtons
            testHook="doubleClick"
            isConfirmEnabled={isValid && !isSubmitting}
            onConfirm={handleSubmit}
            onDecline={() => {
              navigate(buildUrl('admin/connectors'));
            }}
            confirmText={i18n.t(`connectors:advertisers.form.actions.${isEdit ? 'edit' : 'save'}`)}
          />
        </form>
      )}
    </Formik>
  );
};

export default DoubleClickForm;
