import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MdFolder from 'react-icons/lib/md/folder';
import MdNavigateNext from 'react-icons/lib/md/navigate-next';
import * as constants from './constants';
import Icon from '../Icon';
import './crumbs-styles.scss';
import './folder-icon-styles.scss';

class Crumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  render() {
    const { crumbs } = this.props;
    return (
      <li className="Crumbs">
        <button onClick={this.showDropdownMenu} className="Crumbs-triggerBtn">
          &#8230;
        </button>
        <Icon
          icon={MdNavigateNext}
          size={constants.CRUMB_POINTER_SIZE}
          className="Crumb-pointer u-marginRightM"
          color={constants.CRUMB_POINTER_COLOR}
        />
        <ul className={cx('Crumbs-list', { 'u-block': this.state.displayMenu })}>
          {crumbs.map((item, index) => (
            <li className="Crumbs-item" key={item.pathname + index}>
              <Icon
                icon={MdFolder}
                size={constants.FOLDER_ICON_SIZE}
                className="FolderIcon FolderIcon--small"
                color={constants.FOLDER_ICON_COLOR}
              />
              <a className="Crumbs-link" href={item.pathname}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </li>
    );
  }
}

Crumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      pathname: PropTypes.string,
    }),
  ).isRequired,
};

export default Crumbs;
