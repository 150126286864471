import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import { getSitesForSelect, fetchSitesIfNeeded } from '~/context/sites';
import { saveUser } from '../users/records/actions';
import UserForm from '../components/UserForm';
import './UserNew.scss';

const USER_FORM_NEW = 'userNew';
const USERS_TRANSLATION_KEY = 'users';

export class UserNewPage extends Component {
  componentDidMount() {
    if (!this.props.sites.length) {
      this.props.fetchSites();
    }
  }

  render() {
    const { props } = this;
    return (
      <Page className="UserNewPage">
        {!props.sites.length ? (
          <Spinner />
        ) : (
          <SmallWrapper>
            <Heading title={props.t('new.header')} />
            <Panel>
              <UserForm {...props} />
            </Panel>
          </SmallWrapper>
        )}
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      sites: getSitesForSelect(state),
      backHref: buildUrl('context/users/list'),
      initialValues: {
        sentInvite: true,
      },
    }),
    dispatch => ({
      fetchSites: () => dispatch(fetchSitesIfNeeded()),
      onSubmit: values => dispatch(saveUser(values)),
    }),
  ),
  reduxForm({
    form: USER_FORM_NEW,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([USERS_TRANSLATION_KEY]),
)(UserNewPage);
