angular.module('webUi.directive.autocompleteDropdown', [
    'webUi.service',
    'ui.bootstrap',
    'ui.keypress'
])

    .directive('autocompleteDropdown', ['$timeout', 'PubSubService', function ($timeout, PubSubService) {
        return {
            restrict: 'E',
            scope: {
                initialItems: '=items',
                selectedItem: '=?selectedItem',
                label: '@label',
                value: '@value',
                allowCustom: '=?allowCustom',
                options:'=?options',
                onSelect: '&?onSelect',
                onClear: '&?onClear',
                customValidate: '=?customValidate',
                form: '=form'

            },
            templateUrl : 'directive/autocompleteDropdown/autocompleteDropdown.tpl.html',
            link: {
                pre: function (scope) {
                    'use strict';
                    scope.options = scope.options || {};
                    scope.options.placeholder = scope.options.placeholder || 'Start typing...';
                    scope.isEmpty = _.isEmpty;
                    scope.selected = {
                        item:  scope.selectedItem
                    };
                    scope.selectedItemOriginalValue = _.clone(scope.selectedItem);

                    scope.onClear =scope.onClear || function(){};
                    scope.onSelect =scope.onSelect || function(){};

                    scope.onSelectCallback = function onSelectCallback($event) {
                        //Don't trigger any submit, just call the callbacks
                        $event.preventDefault();
                        //This is to make sure the model is updated with the user option, either custom or selecting from the list
                        $timeout(function() {
                            if(scope.selected.item === scope.selectedItemOriginalValue) {
                                //FIXME: Because of data-valid directive not updating the errors we have to publish the form
                                PubSubService.publishFormValidate(scope.form.$name);
                                return false;
                            }
                            if (!_.isUndefined(scope.selected.item) && !_.isEmpty(scope.selected.item)) {
                                var customParam = {};
                                //When we need to pass a custom parameter i.e param = {dataField: scope.selectedItem}
                                customParam[scope.value] = scope.selected.item;
                                if (!_.isEmpty(customParam)) {
                                    scope.onSelect(customParam);
                                    //Avoid issue with minLength zero, show the list
                                    scope.selected.item = undefined;
                                }
                            } else {
                                scope.clearSelectedItem();
                            }
                        }, 150);

                    };

                    scope.clearSelectedItem = function () {
                        //Keep the original value, if the user put in blank the selectedItem, call the callback with the original value as a custom param
                        if(!_.isEmpty(scope.selected.item) || _.isEmpty(scope.selected.item) && !_.isEmpty(scope.selectedItemOriginalValue)) {
                            var param = {};
                            //If selectItem is not empty send it or else send the original value
                            scope.selectedItemOriginalValue = scope.selected.item || scope.selectedItemOriginalValue;
                            //Custom param with the original Value
                            param[scope.value] = scope.selectedItemOriginalValue;
                            if (!_.isEmpty(param)) {
                                scope.onClear(param);
                                //Avoid issue with minLength zero, show the list
                                scope.selected.item = undefined;
                            }
                        }else{
                            scope.onClear();
                        }
                    };
                    //Always refresh the items if one of them change
                    scope.$watchCollection('initialItems', function(newItems) {
                        if(_.isUndefined(newItems)) {return;}
                        scope.items= _.filter(newItems, function(item){
                            return !!item[scope.label];
                        });
                    });

                    //some items come with null in the label, we filter them out
                    scope.items= _.filter(scope.initialItems, function(item){
                        return !!item[scope.label];
                    });

                }
            }
        };
    }]);
