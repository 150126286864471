import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import Link from '~/components/src/Link';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import './DependantsList.scss';

const { Table, Row, Cell } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t, hasAccess, emptyListMessageBody }) => {
  if (!hasAccess) {
    return <Empty excludePanel header={t('list.noAccess.header')} body={t('list.noAccess.body')} />;
  }
  return <Empty excludePanel header={t('list.empty.header')} body={emptyListMessageBody} />;
};

const DependantObjectType = ({ type, dependantInfo }) => {
  let objectForUrl = 'audiences/segments';
  if (type.includes('DATA_COLLECTOR')) {
    objectForUrl = 'profiles/collectors';
  }
  if (type.includes('AD')) {
    objectForUrl = 'content/campaigns/ads';
  }
  if (type.includes('FILTER')) {
    objectForUrl = 'profiles/filters';
  }
  if (type.includes('PROPERTY_TRANSFORMATION')) {
    objectForUrl = 'profiles/transformations';
  }
  return (
    <Link className="Dependancy-link" href={buildUrl(`${objectForUrl}/view/${dependantInfo.id}`)}>
      {dependantInfo.name}
    </Link>
  );
};

export const DependantsList = ({ list, stateKey, t }) => (
  <Table stateKey={stateKey} testHook="dependantsListTable">
    <Row>
      <SortableHeader sortKey="name" title={t('list.header')} />
    </Row>
    {list.map(dependantInfo => (
      <Row key={dependantInfo.id}>
        <Cell>
          <DependantObjectType type={stateKey} dependantInfo={dependantInfo} />
        </Cell>
      </Row>
    ))}
  </Table>
);

DependantsList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  emptyListMessageBody: PropTypes.string.isRequired,
};

export default compose(withEmpty({ component: EmptyList }), withSort(), withPaginate())(DependantsList);
