import React, { useEffect } from 'react';
import Heading from '~/components/src/Heading';
import { Formik } from 'formik';
import HistoricProcessingList from '~/profiles/components/HistoricProcessingList';
import Notification from '~/components/src/Notification';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import Page from '~/components/src/Page';
import { SearchContainer } from '~/common';
import { SELECTED_AUDIENCES } from '~/profiles/audiences/types';
import Btn from '~/components/src/Btn';
import './styles.scss';

const AUDIENCES_FILTER_KEY = 'PROFILES/AUDIENCES/AUDIENCES_SEARCH';

const searchFilterFn = query => item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

const HistoricProcessingPage = ({ audiencesList, t, handleSubmit, initialValues, fetchData, navigate, ...props }) => {
  useEffect(() => {
    fetchData();
  }, []);
  if (!audiencesList.length) return null;
  return (
    <Page>
      <MediumWrapper>
        <Heading title={t('historicProcessing.header')}>
          <SearchContainer stateKey={SELECTED_AUDIENCES} searchKey={AUDIENCES_FILTER_KEY} searchFn={searchFilterFn} />
        </Heading>
        <Notification kind="information" header="">
          <p>{t('historicProcessing.notificationText')}</p>
        </Notification>
        <Panel>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {formProps => (
              <form onSubmit={formProps.handleSubmit}>
                <div>
                  <HistoricProcessingList
                    list={audiencesList}
                    t={t}
                    isSelectable={true}
                    formProps={formProps}
                    {...props}
                  />
                  <div className="flex justify-end gap-2 pt-4">
                    <Btn onClick={() => navigate(-1)}>{t('historicProcessing.cancel')}</Btn>
                    <Btn
                      color="blue"
                      onClick={formProps.handleSubmit}
                      disabled={!Object.values(formProps.values.audience).some(checked => checked)}
                    >
                      {t('historicProcessing.triggerHP')}
                    </Btn>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Panel>
      </MediumWrapper>
    </Page>
  );
};

export default HistoricProcessingPage;
