import compose from 'lodash/fp/compose';
import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  mergeAudienceWithActivePartners,
  addNamesFromRuleDefinitionsToPath,
  mergeAudienceWithVariables,
} from './audienceUtils';

const BASE_PROFILES_PATH = `${API_BASE}/profiles`;
const BASE_SEGMENTS_PATH = `${BASE_PROFILES_PATH}/segments`;
const BASE_PARTNERS_PATH = `${API_BASE}/v1/partners`;

export const getFullAudiences = () => Api.callGet(BASE_SEGMENTS_PATH).then(parseResponse);

const getActivePartners = () => Api.callGet(`${BASE_PARTNERS_PATH}/listActive`).then(parseResponse);

const getSegment = id => Api.callGet(`${BASE_SEGMENTS_PATH}/${id}`).then(parseResponse);

export const cloneSegment = id => Api.callPost(`${BASE_SEGMENTS_PATH}/${id}/clone`, { id }).then(parseResponse);

const getRulesDefinitions = () => Api.callGet(`${BASE_PROFILES_PATH}/ruleDefinitions`).then(parseResponse);

const getVariables = () => Api.callGet(`${BASE_PROFILES_PATH}/variables`).then(parseResponse);

export const triggerHistoricProcessing = audiences =>
  Api.callPost(`${BASE_SEGMENTS_PATH}/historicProcessing`, audiences).then(parseResponse);

export const getAudience = id =>
  Promise.all([getActivePartners(), getSegment(id), getRulesDefinitions(), getVariables()]).then(
    ([activePartners, audience, ruleDefinitions, variables]) => {
      const mergeAudience = compose(
        mergeAudienceWithActivePartners(activePartners),
        addNamesFromRuleDefinitionsToPath(ruleDefinitions, 'segment.criteria'),
        mergeAudienceWithVariables(variables),
      );

      return mergeAudience(audience);
    },
  );

export const canDeleteAudience = id => Api.callGet(`${BASE_SEGMENTS_PATH}/canDelete/${id}`).then(parseResponse);

export const deleteAudience = id => Api.callDelete(`${BASE_SEGMENTS_PATH}/${id}`).then(parseResponse);

export const getAudiencesTree = () => Api.callGet(`${BASE_SEGMENTS_PATH}/tree`).then(response => response.data);

export const createAudienceGroup = groupName =>
  Api.callPost(`${BASE_SEGMENTS_PATH}/groups`, { name: groupName }).then(response => response.data);

export const deleteAudienceGroup = groupId =>
  Api.callDelete(`${BASE_SEGMENTS_PATH}/groups/${groupId}`).then(response => response.data);

export const linkAudience = (elementId, groupId) => {
  let payload = { elementId };
  if (groupId) {
    payload = { ...payload, groupId };
  }
  return Api.callPost(`${BASE_SEGMENTS_PATH}/groups/link`, payload);
};

export const createAudience = payload => Api.callPost(BASE_SEGMENTS_PATH, payload).then(response => response.data);

export const updateAudience = payload =>
  Api.callPost(`${BASE_SEGMENTS_PATH}/edit`, payload).then(response => response.data);

export const validateFilterInput = (matchType, fieldValue) =>
  Api.callGet(`${API_BASE}/validateField?matchType=${matchType}&fieldValue=${fieldValue}`, {
    shouldShowToast: false,
  }).then(response => response.data);

export default {
  getAudience,
  canDeleteAudience,
  cloneSegment,
  deleteAudience,
  triggerHistoricProcessing,
  getAudiencesTree,
  createAudienceGroup,
  deleteAudienceGroup,
  linkAudience,
};
