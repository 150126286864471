import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl, changeUrl, SearchContainer } from '~/common';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import DomainList from '~/context/clients/components/DomainList';
import {
  fetchClientDomainsIfNeeded,
  deleteClientDomain,
  getAllClientDomains,
  isFetchingDomains,
} from '~/context/clients';
import * as modals from '~/modals';
import i18n from '~/context/i18n';
import LinkIcon from '~/components/src/LinkIcon';

const DOMAIN_LIST_STATE_KEY = 'CONTEXT/CLIENT/DOMAIN_LIST';
const DOMAIN_DOMAIN_STATE_KEY = 'CONTEXT/CLIENT/DOMAIN_SEARCH';

const searchFn = query => item => item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1;

class DomainsListContainer extends Component {
  componentDidMount() {
    this.props.fetchDomains();
  }

  render() {
    const { isFetching, isContextAdmin, hrefNew, stateKey, t } = this.props;
    return (
      <>
        <Heading title={t('clients:view.headings.domains')}>
          <SearchContainer stateKey={stateKey} searchKey={DOMAIN_DOMAIN_STATE_KEY} searchFn={searchFn} />
          {isContextAdmin && (
            <LinkIcon
              className="h-8 w-8"
              color="blue"
              icon="add"
              url={hrefNew}
              tooltip={t('clients:view.domains.button.newDomain.text')}
            />
          )}
        </Heading>
        {isFetching ? <Spinner /> : <DomainList {...this.props} />}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  stateKey: DOMAIN_LIST_STATE_KEY,
  list: getAllClientDomains(state),
  isFetching: isFetchingDomains(state),
  hrefNew: buildUrl(`context/clients/view/${ownProps.clientId}/domain/new`),
  onEditClick: item => {
    changeUrl(`context/clients/view/${item.clientId}/domain/edit/${item.clientDomainId}`);
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDomains: () => dispatch(fetchClientDomainsIfNeeded(ownProps.clientId)),
  onDeleteClick: domain =>
    dispatch(
      modals.showModal(modals.DELETE_MODAL, {
        title: i18n.t('clients:view.modals.deleteDomain.title'),
        message: i18n.t('clients:view.modals.deleteDomain.message', { domainName: domain.description }),
        onConfirm: () => dispatch(deleteClientDomain(domain)),
      }),
    ),
});

DomainsListContainer.propTypes = {
  clientId: PropTypes.string.isRequired,
  isContextAdmin: PropTypes.bool.isRequired,
};

/**
 * Composing and connecting the component with the store.
 */
export default compose(connect(mapStateToProps, mapDispatchToProps), translate('clients'))(DomainsListContainer);
