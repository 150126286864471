import React, { Component } from 'react';
import Heading from '~/components/src/Heading';
import { Panel } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import CheckboxGroupElement from '~/components/src/Form/Fields/CheckboxGroupElement';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { convertPermissionsToArray, updateState, calculatePermissions } from './helpers';
import './styles.scss';

const PermissionFields = ({ permissions, statePermissions, onChange }) =>
  permissions.map((module, index) => (
    <div className="PermissionFields" key={index}>
      <div className="PermissionFields-groupLabel">{module.label}</div>
      <CheckboxGroupElement
        onChange={onChange}
        options={module.permissions}
        value={module.permissions.reduce(
          (acc, current) => ({ ...acc, [current.name]: !!statePermissions[current.name] }),
          {},
        )}
      />
    </div>
  ));

export class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = updateState(
      {
        hasApi: (this.props.permissions && this.props.permissions.API_USER) || false,
        permissions: this.props.permissions || {},
      },
      this.props,
    );
  }

  componentDidMount() {
    this.props.fetchPermissionsMap();
    this.setState((prevState, props) => updateState(prevState, props));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiPermissions.length !== this.props.uiPermissions.length) {
      this.setState((prevState, props) => updateState(prevState, props));
    }
  }

  render() {
    const { input, withPanels, t } = this.props;
    const { uiPermissions, apiPermissions } = this.state;
    const onChange = (updatedValue, changedName, changedValue) =>
      this.setState(prevState => {
        const nextState = {
          ...prevState,
          permissions: calculatePermissions({ ...prevState.permissions, ...updatedValue }, changedName, changedValue),
        };
        input.onChange(convertPermissionsToArray(nextState.permissions));
        return nextState;
      });

    if (!(uiPermissions.length || apiPermissions.length)) return <Spinner />;

    const PanelOrNothing = withPanels ? Panel : React.Fragment;

    return (
      <div>
        <Heading title={t('UI Permissions')} />
        <PanelOrNothing>
          <PermissionFields permissions={uiPermissions} statePermissions={this.state.permissions} onChange={onChange} />
        </PanelOrNothing>
        <Heading title={t('API Permissions')} />
        <PanelOrNothing>
          <FieldWrapper
            className="PermissionsForm-apiPermissionsField"
            htmlFor="hasApi"
            label={t('Grant API permissions?')}
            fieldType="inline"
          >
            <CheckboxElement
              id="hasApi"
              value={this.state.hasApi}
              onBlur={() => {}}
              onChange={() => {
                this.setState(prevState => {
                  const nextState = {
                    hasApi: !prevState.hasApi,
                    permissions: calculatePermissions(
                      {
                        ...prevState.permissions,
                        API_USER: !prevState.hasApi,
                      },
                      'API_USER',
                      !prevState.hasApi,
                    ),
                  };
                  input.onChange(convertPermissionsToArray(nextState.permissions));
                  return nextState;
                });
              }}
            />
          </FieldWrapper>
          {!this.state.hasApi ? null : (
            <PermissionFields
              permissions={apiPermissions}
              statePermissions={this.state.permissions}
              onChange={onChange}
            />
          )}
        </PanelOrNothing>
      </div>
    );
  }
}

export default Permissions;
