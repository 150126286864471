/* eslint-disable class-methods-use-this */

import Api, { API_BASE } from '~/common/ApiService';

const API_USERS = `${API_BASE}/users/current`;

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class UserSettingsClass {
  updateUserSettings(user) {
    return Api.callPut(`${API_USERS}`, user).then(response => response.data);
  }
  requestSms(phoneNumber) {
    return Api.callPost(`${API_USERS}/sms`, { phoneNumber }).then(response => response.data);
  }

  checkIf2FactorAuthNeeded() {
    // Sending an empty POST request to the "change password" endpoint.
    // The response would be a 422 if 2FA is needed. 422 will cause a redirect to /iplock.
    return Api.callPost(`${API_USERS}/password`, {}, { shouldShowToast: false })
      .then(response => response.data)
      .catch(() => {
        // If 2FA is not needed response would be a 403. We ignore it.
      });
  }

  updatePhoneNumber(phoneNumber, smsCode) {
    return Api.callPost(`${API_USERS}/phoneNumber`, { phoneNumber, smsCode }).then(response => response.data);
  }
  removeTwoFactorAuthentication(authenticationType) {
    return Api.callPost(`${API_USERS}/removeDefault2FA`, { authenticationType }).then(response => response.data);
  }
  verifyGoogleAuth(googleAuthCode, googleAuthKey) {
    return Api.callPost(`${API_USERS}/verifyGoogleAuth`, { googleAuthKey, googleAuthCode });
  }
  makeDefault2FA(authenticationType) {
    const makeDefault2FARequest = {
      authenticationType,
    };
    return Api.callPost(`${API_USERS}/makeDefault2FA`, makeDefault2FARequest).then(response => response.data);
  }
  getGoogleAuth() {
    return Api.callGet(`${API_USERS}/googleAuth`).then(response => response.data);
  }
  unlinkOpenId() {
    return Api.callPost(`${API_USERS}/unlinkOpenId`, {}).then(response => response.data);
  }
  updatePassword(newPassword, oldPassword, smsCode) {
    const passwordChangeRequest = {
      oldPasswordHash: oldPassword,
      newPasswordHash: newPassword,
    };
    if (smsCode) {
      passwordChangeRequest.smsCode = smsCode;
    }
    return Api.callPost(`${API_USERS}/password`, passwordChangeRequest).then(response => response.data);
  }
}

const userSettingsService = new UserSettingsClass();
export default userSettingsService;
