import * as types from './types';

export const showModal = (type, props) => ({
  type: types.SHOW_MODAL,
  payload: {
    type,
    props,
  },
});

export const showDeleteModal = props => showModal(types.DELETE_MODAL, props);

export const hideModal = () => ({
  type: types.HIDE_MODAL,
});
