import { formatNumber } from '~/common';

export const renderValue = (item, days = 1, type = 'totalSegmentSize') => {
  const value = item.segmentStats ? item.segmentStats[days][type] : 0;
  switch (type) {
    case 'totalSegmentSize':
      return formatNumber(item.segmentSize);
    case 'totalCallsAdded':
      return formatNumber(value, { prefix: '+' });
    case 'totalCallsRemoved':
      return formatNumber(value, { prefix: '-' });
    default:
      return '';
  }
};
