angular.module('webUi.ui.content.campaigns.ads.view.confirmLibraryChange', ['ui.bootstrap'])

    .controller('ConfirmLibraryChangeController', ['$uibModalInstance',
        function ConfirmLibraryChangeController($uibModalInstance) {
            'use strict';
            var vm = this;

            vm.confirm = function() {
                $uibModalInstance.close(true);
            };

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
