import React, { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { PickerView, SkeletonLoader } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import SamplesDialog from './SamplesDialog';
import UsageIcons from './UsageIcons';
import { TConfiguredProperty, TSampleRow } from './types';

const columnHelper = createColumnHelper<TConfiguredProperty & { id: string }>();

const PAGE_SIZE = 15;

interface PropertiesListProps {
  t: TFunction;
  usedIn: any[];
  interactionId: string;
  list: TConfiguredProperty[];
  canAccessData: boolean;
  isFetchingProperties: boolean;
  onDependantIconClick: (interactionId: string, index: number, name: string) => void;
}

export const PropertiesList = ({
  t,
  list,
  usedIn,
  canAccessData,
  interactionId,
  isFetchingProperties,
  onDependantIconClick,
}: PropertiesListProps) => {
  const [showSamplesDialog, setShowSamplesDialog] = useState(false);
  const [selectedRowSamples, setSelectedRowSamples] = useState<TSampleRow[]>([]);
  const [paginatedList, setPaginatedList] = useState(list);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const handleShowSamples = (samples: TSampleRow[]) => {
    setSelectedRowSamples(samples);
    setShowSamplesDialog(true);
  };

  useEffect(() => {
    const pages = list.length / PAGE_SIZE || 1;
    setTotalPages(Math.ceil(pages));
    setPage(0);
  }, []);

  useEffect(() => {
    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    setPaginatedList(list.slice(start, end));
  }, [page, list]);

  const renderFoundInSamples = (property: TConfiguredProperty) => {
    if (isFetchingProperties)
      return (
        <div className="text-center">
          <SkeletonLoader type="text" width={100} />
        </div>
      );

    const getSamplesToolTip = (canAccessData: boolean, samplesLength: number) => {
      if (canAccessData) {
        return samplesLength > 0 ? t('actions.samples') : t('actions.noSamples');
      }
      return t('messages.noPermission');
    };

    const { frequency, percentage, samples } = property;

    if (!frequency) return <div className="text-center text-xs italic text-gray-500">{t('messages.notFound')}</div>;

    return (
      <div className="grid grid-cols-2 items-center gap-2">
        <div className="flex items-center justify-end gap-2">
          {frequency} <small className="text-r42-blue">({parseFloat(percentage).toFixed(2)}%)</small>
        </div>
        <BtnIcon
          icon="visit"
          className="size-6"
          disabled={!canAccessData || samples?.length === 0}
          onClick={() => handleShowSamples(samples)}
          tooltip={getSamplesToolTip(canAccessData, samples.length)}
        />
      </div>
    );
  };

  const columns = [
    columnHelper.accessor('id', {
      header: () => <div className="text-left">{t('propertiesTable.number')}</div>,
      cell: info => <div className="text-left">{parseInt(info.row.id, 10) + 1}</div>,
      size: 50,
    }),
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{t('propertiesTable.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('frequency', {
      header: () => <div className="text-center">{t('propertiesTable.frequency')}</div>,
      cell: ({ row }) => renderFoundInSamples(row.original),
      size: 200,
    }),
    columnHelper.accessor('dependantTypes', {
      header: () => <div className="text-center">{t('propertiesTable.usedIn')}</div>,
      cell: info => (
        <div className="flex justify-center">
          <UsageIcons
            dependantTypes={info.getValue()}
            elementUsedIn={usedIn}
            elementId={interactionId}
            onDependantIconClick={(interactionId, index) =>
              onDependantIconClick(interactionId, index, info.row.original.name)
            }
          />
        </div>
      ),
    }),
  ];

  return (
    <div className="mt-2">
      <UITable
        data={paginatedList ?? []}
        columns={columns}
        page={page}
        totalPages={totalPages}
        enableSorting
        enablePagination={totalPages > 1}
        onPaginate={setPage}
        testHook="missedPropertiesTable"
        emptyMessage={t('tabs.configuredProperties.empty.header')}
        emptyBody={t('tabs.configuredProperties.empty.body')}
      />

      {showSamplesDialog && (
        <PickerView
          pickerTitle={t('actions.samples')}
          handlePickerVisibility={() => setShowSamplesDialog(false)}
          className="!h-auto !max-h-[400px] !w-[500px]"
        >
          <SamplesDialog t={t} samples={selectedRowSamples} />
        </PickerView>
      )}
    </div>
  );
};

export default PropertiesList;
