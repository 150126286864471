/*
 * A service for serving Media Library queries
 */

angular.module('webUi.service.content.medialibrary', [
    'restangular', 'webUi.common.Utils', 'webUi.service.security', 'ui.bootstrap'
])

    .factory('MediaLibraryService', ['Restangular', 'Utils', 'SecurityService', '$q', 'ModalManagementService', 'PubSubService',
        function MediaLibraryService(Restangular, Utils, SecurityService, $q, ModalManagementService, PubSubService) {

            var BASE_PATH = 'content/medialibraries';

            var TREE_STATES = [
                'site.content.medialibraries.view',        //level 0
                'site.content.medialibraries.view'         //level 1
            ];

            var TREE_STATES_ID = [
                'mediaLibraryId',        //level 0
                ['name', 'folderName']      //level 1
            ];

            var insertMediaLibrary = function insertMediaLibrary(mediaLibrary) {
                var mediaLibraryIdPromise = $q.defer();

                // post a new medialibrary create event
                Restangular.all(BASE_PATH).post(mediaLibrary).then(function (mediaLibraryId) {
                    mediaLibraryIdPromise.resolve(mediaLibraryId);
                });

                return mediaLibraryIdPromise.promise;
            };

            var getMediaLibraries = function getMediaLibraries() {
                var mediaLibraries = $q.defer();

                Restangular.one(BASE_PATH).getList().then(function (result) {
                    result = Utils.sanitizeRestangularAll(result);
                    mediaLibraries.resolve(result);
                });

                return mediaLibraries.promise;
            };

            /**
             * Returns the media libraries in a tree structure
             * @param {type} mediaLibraryClassName = medialibrary-node
             * @returns {$q@call;defer.promise}
             */
            var getMediaLibraryTreeData = function getMediaLibraryTreeData() {
                var mediaLibraries = $q.defer();

                var restangularCall = Restangular.all(BASE_PATH + '/tree');
                restangularCall.getList().then(function (result) {
                    result = Utils.sanitizeRestangularAll(result);
                    mediaLibraries.resolve(Utils.parseNodes(result, TREE_STATES, TREE_STATES_ID));
                });
                return mediaLibraries.promise;
            };

            /**
             * Returns the media library pojo associated with this id
             * @param {type} mediaLibraryId
             * @returns {$q@call;defer.promise}
             */
            var getMediaLibrary = function getMediaLibrary(mediaLibraryId, folderName) {
                var mediaLibrary = $q.defer();
                if (_.isUndefined(folderName) || _.isEmpty(folderName)) {
                    Restangular.one(BASE_PATH, mediaLibraryId).get().then(function (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        mediaLibrary.resolve(result);
                    });
                } else {
                    Restangular.one(BASE_PATH + '/' + mediaLibraryId + '/list?path=' + folderName).get().then(function (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        mediaLibrary.resolve(result);
                    });
                }
                return mediaLibrary.promise;
            };

            /**
             * Deletes the media library associated with this id
             * @param {type} mediaLibraryId
             * @returns {unresolved}
             */
            var deleteMediaLibrary = function deleteMediaLibrary(mediaLibraryId) {
                return Restangular.one(BASE_PATH, mediaLibraryId).remove();
            };

            var updateMediaLibraryName = function updateMediaLibraryName(mediaLibraryId, name) {
                return Restangular.one(BASE_PATH).post('updateMediaLibraryName', {
                    mediaLibraryId: mediaLibraryId,
                    name: name
                });
            };


            var createMediaLibrary = function createMediaLibrary($state) {
                var dialogsModel = function () {
                    return {
                        onSave: function (mediaLibrary) {
                            insertMediaLibrary(mediaLibrary).then(
                                function onSuccess(mediaLibraryId) {
                                    // notify listening scopes that a new Media Library has been added
                                    PubSubService.publishTypeCreated(PubSubService.TYPES.CONTENT.MEDIA_LIBRARY, {id: mediaLibraryId});
                                    $state.transitionToStateWithSite('site.content.medialibraries.view', {mediaLibraryId: mediaLibraryId});
                                },
                                function onError() {
                                });
                        }
                    };
                };
                newLibraryModal(dialogsModel);
            };

            var newLibraryModal = function newLibraryModal(dialogsModel) {
                return ModalManagementService.openDialog(
                    'MediaLibrariesFormController',
                    'ui/content/medialibraries/form/form.tpl.html',
                    dialogsModel,
                    {'css': 'modal-medium'}
                );
            };

            /**
             * Creates a new folder with this name for the selected media library
             * @param {type} mediaLibraryId - the id of the media library which is being modified
             * @param {type} folderName - the name of the new folder
             * @returns
             */
            var createNewFolder = function (mediaLibraryId, path, folderName) {
                return Restangular.one(BASE_PATH).post(mediaLibraryId + '/create', {
                    path: path,
                    folderName: folderName
                });
            };

            /**
             * Deletes a media file
             * @param {type} filename
             * @returns {unresolved}
             */
            var deleteFile = function (file, mediaLibraryId) {
                return Restangular.one(BASE_PATH + '/' + mediaLibraryId + '/deleteFile?file=' + encodeURIComponent(file.prefix + file.name)).remove();
            };

            var getLocationBreadcrumbs = function (mediaLibrary, folderName) {
                var crumbs = [];
                if (!_.isUndefined(folderName) && !_.isEmpty(folderName)) {
                    var crumb = {
                        name: mediaLibrary.name
                    };
                    crumbs.push(crumb);
                    var subPaths = folderName.split('/');
                    for (var i = 0; i < subPaths.length; i++) {
                        crumb = {
                            fullPath: (i !== 0) ? crumbs[i].fullPath + '/' + subPaths[i] : subPaths[i],
                            name: subPaths[i]
                        };
                        crumbs.push(crumb);
                    }
                }
                return crumbs;
            };


            return {
                BASE_PATH: BASE_PATH,
                insertMediaLibrary: insertMediaLibrary,
                getMediaLibraries: getMediaLibraries,
                getMediaLibraryTreeData: getMediaLibraryTreeData,
                getMediaLibrary: getMediaLibrary,
                deleteMediaLibrary: deleteMediaLibrary,
                updateMediaLibraryName: updateMediaLibraryName,
                createMediaLibrary: createMediaLibrary,
                newLibraryModal: newLibraryModal,
                createNewFolder: createNewFolder,
                deleteFile: deleteFile,
                getLocationBreadcrumbs: getLocationBreadcrumbs
            };
        }]);
