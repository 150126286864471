/**
 * @description Angular module for urlbuilder -> Base
 * @class UrlBuilderBaseController
 * @namespace webUi.ui.tagmanagement.urlbuilder.base
 */
angular.module('webUi.ui.tagmanagement.urlbuilder.base',
    [
        'webUi.ui.tagmanagement.base'
    ]
).config(['$stateProvider', function config($stateProvider) {
    $stateProvider.state('site.tagmanagement.urlbuilder', {
        url: '/urlbuilder',
        isReact: true,
    });
}]);