export const VARIANT_FETCH_START = 'app/support/tagtemplates/variants/VARIANT_FETCH_START';
export const VARIANT_FETCH_FAIL = 'app/support/tagtemplates/variants/VARIANT_FETCH_FAIL';
export const VARIANT_FETCH_SUCCESS = 'app/support/tagtemplates/variants/VARIANT_FETCH_SUCCESS';

export const DELETE_START = 'app/support/tagtemplates/variants/DELETE_START';
export const DELETE_FAIL = 'app/support/tagtemplates/variants/DELETE_FAIL';
export const DELETE_SUCCESS = 'app/support/tagtemplates/variants/DELETE_SUCCESS';

export const SAVE_START = 'app/support/tagtemplates/variants/SAVE_START';
export const SAVE_FAIL = 'app/support/tagtemplates/variants/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/support/tagtemplates/variants/SAVE_SUCCESS';

export const LOCK_START = 'app/support/tagtemplates/variants/LOCK_START';
export const LOCK_FAIL = 'app/support/tagtemplates/variants/LOCK_FAIL';
export const LOCK_SUCCESS = 'app/support/tagtemplates/variants/LOCK_SUCCESS';

export const EXECUTION_TYPES_FETCH_START = 'app/support/tagtemplates/variants/EXECUTION_TYPES_FETCH_START';
export const EXECUTION_TYPES_FETCH_SUCCESS = 'app/support/tagtemplates/variants/EXECUTION_TYPES_FETCH_SUCCESS';
export const EXECUTION_TYPES_FETCH_FAIL = 'app/support/tagtemplates/variants/EXECUTION_TYPES_FETCH_FAIL';

export const VARIANT_HISTORY_FETCH_START = 'app/support/tagtemplates/variants/VARIANT_HISTORY_FETCH_START';
export const VARIANT_HISTORY_FETCH_FAIL = 'app/support/tagtemplates/variants/VARIANT_HISTORY_FETCH_FAIL';
export const VARIANT_HISTORY_FETCH_SUCCESS = 'app/support/tagtemplates/variants/VARIANT_HISTORY_FETCH_SUCCESS';
