import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

const customerDataSearchSchema = Yup.object().shape({
  partnerType: Yup.number()
    .typeError('Must be a number')
    .min(1, 'Please enter the partner type (partner number)')
    .max(1000000, 'Partner type is too long')
    .required('Required'),
  partnerId: Yup.string().required('Required'),
});

const renderFormContains =
  t =>
  ({ touched, errors, values, handleSubmit }) => {
    const canSubmit = values.partnerType && values.partnerId && !errors.partnerType && !errors.partnerId;

    return (
      <div className="CustomerDataSearch">
        <form onSubmit={handleSubmit} className="flex items-center justify-end gap-4">
          <Field
            as={FormikInputField}
            name="partnerType"
            errorText={touched.partnerType && errors.partnerType}
            value={values.partnerType}
            label={t('identifiers.partnerType')}
            placeholder={t('identifiers.partnerType')}
            className="CustomerDataSearch-partnerType "
            horizontal
          />
          <Field
            as={FormikInputField}
            name="partnerId"
            label={t('identifiers.partnerId')}
            placeholder={t('identifiers.partnerId')}
            errorText={touched.partnerId && errors.partnerId}
            value={values.partnerId}
            className="CustomerDataSearch-partnerId"
            horizontal
          />
          <BtnIcon icon="search" color="blue" type="submit" disabled={!canSubmit} tooltip={t('search')} />
        </form>
      </div>
    );
  };

export const CustomerDataSearch = ({ onSearch, initialValues, t }) => (
  <Formik
    onSubmit={onSearch}
    initialValues={initialValues}
    validationSchema={customerDataSearchSchema}
    enableReinitialize={true}
  >
    {renderFormContains(t)}
  </Formik>
);
