angular.module('webUi.ui.content.campaigns.ads.creativeList', [
    'ui.bootstrap',
    'webUi.ui.content.campaigns.ads.creatives.preview',
    'webUi.ui.content.campaigns.ads.creatives.delete',
    'webUi.service'])

    .controller('AdservingCreativeListController', ['$scope', '$state', '$uibModal', 'CreativeService', 'CreativePreviewService', 'SecurityService',
        function AdservingCreativeListController($scope, $state, $uibModal, CreativeService, CreativePreviewService, SecurityService) {

            var previousPreviewKeys = [];

            $scope.previewCreative = function(ad, creative) {
                SecurityService.getSecurityContext().then(function(result) {
                    CreativePreviewService.handlePreview(ad, creative, result.hasModule('DATA_MANAGEMENT'), previousPreviewKeys).then(function(res) {

                        // keep track of previous data keys
                        if(res) {
                            previousPreviewKeys = res;
                        }
                    });
                });
            };

            $scope.confirmDeleteCreative = function(creative) {
                $uibModal.open( {
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/content/campaigns/ads/creatives/delete/delete.tpl.html',
                    controller: 'AdservingCreativeDeleteController',
                    resolve: {
                        dialogsModel : function() {
                            return {
                                creative: creative,
                                onDelete: function() {
                                    CreativeService.deleteCreative(creative).then(function() {
                                        var idx = _.indexOf($scope.ad.creatives, creative);
                                        $scope.ad.creatives.splice(idx, 1);
                                    });
                                }
                            };
                        }
                    }
                });
            };
        }]);
