const scrollIntoView = (query: string): void => {
  const input = document.querySelector(query);
  input?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  });
};

export default scrollIntoView;
