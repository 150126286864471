angular.module('webUi.ui.content.datafeeds.form', ['webUi.ui.content.datafeeds.base'])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.content.datafeeds.add', {
                url: '/add',
                isReact: true,
            });
            $stateProvider.state('site.content.datafeeds.edit', {
                url: '/edit/{dataFeedId}',
                isReact: true,
            });
        },
    ]);
