import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import Notification from '~/components/src/Notification';
import i18n from '~/i18n';
import { TypeOptions } from 'react-toastify';
import cx from 'classnames';
import { ValueOf } from '~/common/types';

export const TOAST_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'information',
  SUCCESS: 'success',
} as const;

export const TOAST_HEADER_DEFAULT = {
  [TOAST_TYPES.ERROR]: i18n.t('common:notifications.error'),
  [TOAST_TYPES.WARNING]: i18n.t('common:notifications.warning'),
  [TOAST_TYPES.INFO]: i18n.t('common:notifications.info'),
  [TOAST_TYPES.SUCCESS]: i18n.t('common:notifications.success'),
};

export type ToastType = TypeOptions;
export type NotificationType = ValueOf<typeof TOAST_TYPES>;

interface ToastProps {
  kind?: NotificationType;
  header?: string;
  body?: ReactNode;
  closeToast?: () => void;
  className?: string;
}

const Toast = ({
  kind = TOAST_TYPES.INFO,
  header = TOAST_HEADER_DEFAULT[kind],
  body = '',
  closeToast,
  className,
}: ToastProps): ReactElement => (
  <Notification kind={kind} header={header} onClose={closeToast} className={cx(className, 'p-2')} testHook="toast">
    {body}
  </Notification>
);

interface ToastShakeProps {
  children: (className: string) => ReactElement;
  count: number;
}

export const ToastShake = ({ children, count }: ToastShakeProps): ReactElement | null => {
  const [hasAnimation, setHasAnimation] = useState(false);

  useEffect(() => {
    setHasAnimation(false);

    setTimeout(() => {
      setHasAnimation(true);
    }, 50);
  }, [count]);

  if (typeof children === 'function') {
    return children(hasAnimation ? 'ToastShake ToastShake--shaking' : 'ToastShake');
  }

  return null;
};

export default Toast;
