/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_SITES = `${API_BASE}/context/sites`;

/**
 * A service for Sites related queries
 * This is not the whole context sites methods, we will do the migration
 * as soon as we need the methods
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ContextSitesClass {
  getAllSites() {
    return Api.callGet(`${API_SITES}`).then(response => response.data);
  }

  getSite(siteId) {
    // promise all Api.callGet(`${API_SITES}/${siteId}/basescript`)

    return Api.callGet(`${API_SITES}/${siteId}`)
      .then(response =>
        Promise.all([Api.callGet(`${API_SITES}/${siteId}/basescript`), Promise.resolve(response && response.data)]),
      )
      .then(result => ({
        ...result[1],
        basescript: {
          ...((result[0] && result[0].data) || {}),
        },
      }));
  }

  getSiteModules() {
    return Api.callGet(`${API_SITES}/siteModules`).then(response => response.data);
  }

  getTimezones() {
    return Api.callGet(`${API_SITES}/timeZones`).then(response => response.data);
  }

  saveSite(site) {
    return Api.callPost(`${API_SITES}/add`, site).then(response => response.data);
  }

  updateSite(updatedSite) {
    return Api.callPost(`${API_SITES}/edit`, updatedSite).then(response => response.data);
  }

  deleteSite(siteId) {
    return Api.callDelete(`${API_SITES}/${siteId}`).then(response => response.data);
  }

  fetchStats(model) {
    const { siteId, startDate, endDate } = model;
    const path = `${API_BASE}/context/usage/site/${siteId}?rangeFrom=${startDate}&rangeTo=${endDate}`;
    return Api.callGet(path).then(response => response.data);
  }

  truncateSite(siteId) {
    return Api.callPost(`${API_SITES}/truncate`, { siteId }).then(response => response.data);
  }
}

const contextSitesService = new ContextSitesClass();
export default contextSitesService;
