import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

type propsType = {
  highcharts: typeof Highcharts;
  options: Highcharts.Options;
  zoomType?: 'x' | 'y' | 'xy';
};
export const HighChartWithZoom = ({ options, highcharts, zoomType = 'xy' }: propsType): React.ReactElement => (
  <HighchartsReact highcharts={highcharts} options={{ ...options, chart: { ...options.chart, zoomType } }} />
);
