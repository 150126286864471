import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm, formValueSelector } from 'redux-form';
import { buildUrl } from '~/common';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { fetchTimezonesIfNeeded, fetchSiteModulesIfNeeded, saveSite, fetchSiteIfNeeded } from '~/context/sites/actions';
import * as selectors from '~/context/sites/selectors';
import SiteForm from '~/context/components/SiteForm';
import * as fromClients from '~/context/clients';
import { MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

const FORM_NAME = 'siteEditPage';
const translation = 'sites';
const selectorReduxForm = formValueSelector(FORM_NAME);

export class SiteEditPage extends Component {
  componentDidMount() {
    const { fetchTimezones, fetchSiteModules, fetchClients, siteId, fetchSite } = this.props;
    fetchTimezones();
    fetchSiteModules();
    fetchClients();
    fetchSite(siteId);
  }
  render() {
    const { props } = this;
    const { t } = props;

    return (
      <Page>
        <MediumWrapper>
          <Heading title={t('edit.header')} />
          {!props.initialValues ? <Spinner /> : <SiteForm {...props} />}
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const { siteId } = ownProps.params;
      return {
        siteId,
        isSiteEditable: true,
        cancelHref: buildUrl(`context/sites/view/${siteId}`),
        timezones: selectors.getTimezonesForSelect(state),
        siteModules: selectors.getSiteModules(state),
        clients: fromClients.getClientsForSelect(state),
        initialValues: selectors.getSite(state, siteId),
        isTagModuleSelected: selectorReduxForm(state, 'siteModules.TAG_MANAGEMENT'),
        isAIModuleEnabled: selectorReduxForm(state, 'siteModules.RECOMMENDER'),
        isJOModuleEnabled: selectorReduxForm(state, 'siteModules.CUSTOMER_JOURNEY'),
        formState: state?.form[FORM_NAME],
      };
    },
    dispatch => ({
      fetchSite: siteId => dispatch(fetchSiteIfNeeded(siteId)),
      fetchTimezones: () => dispatch(fetchTimezonesIfNeeded()),
      fetchSiteModules: () => dispatch(fetchSiteModulesIfNeeded()),
      onSubmit: values => dispatch(saveSite(values)),
      fetchClients: () => dispatch(fromClients.fetchClientsIfNeeded()),
    }),
  ),
  reduxForm({
    form: FORM_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([translation]),
)(SiteEditPage);
