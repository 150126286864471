import React, { ReactElement } from 'react';
import { Col, Row } from 'react-grid-system';
import { twMerge } from 'tailwind-merge';
import cx from 'classnames';
import './styles.scss';
import { ContentProps, PanelProps, ContainerWrapperProps, BaseFormSectionProps, FormSectionProps } from './types';

export const Panel = ({ children, className, onClick, style }: PanelProps): ReactElement => (
  <div
    className={twMerge(
      cx('block rounded-md border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800', className),
    )}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

export const Content = ({
  children,
  className,
  xxlOffset,
  xxlSpan,
  xlOffset,
  xlSpan,
  lgOffset,
  lgSpan,
  mdOffset,
  mdSpan,
}: ContentProps): JSX.Element => (
  <Row className={className}>
    <Col
      offset={{ xxl: xxlOffset, xl: xlOffset, lg: lgOffset, md: mdOffset }}
      xxl={xxlSpan}
      xl={xlSpan}
      lg={lgSpan}
      md={mdSpan}
    >
      {children}
    </Col>
  </Row>
);

export const SmallWrapper = ({ children, className }: ContainerWrapperProps): ReactElement => (
  <Content xlOffset={3} lgOffset={2} mdOffset={2} xlSpan={6} lgSpan={8} mdSpan={8} className={className}>
    {children}
  </Content>
);

export const SmallWrapperNoOffset = ({ children, className }: ContainerWrapperProps): ReactElement => (
  <Content xlSpan={6} lgSpan={8} mdSpan={8} className={cx(className)}>
    {children}
  </Content>
);

export const MediumWrapper = ({ children, className, xlOffset = 2 }: ContainerWrapperProps): ReactElement => (
  <Content xlOffset={xlOffset} lgOffset={2} mdOffset={0} xlSpan={8} lgSpan={8} mdSpan={12} className={className}>
    {children}
  </Content>
);

export const MediumWrapperNoOffset = ({ children, className }: ContainerWrapperProps): ReactElement => (
  <Content xlSpan={8} lgSpan={8} mdSpan={12} className={className}>
    {children}
  </Content>
);

export const LargeWrapper = ({ children, className }: ContainerWrapperProps): ReactElement => (
  <Content xlOffset={1} lgOffset={2} mdOffset={0} xlSpan={10} lgSpan={8} mdSpan={14} className={className}>
    {children}
  </Content>
);

export const BaseFormSection = ({ left, children, className }: BaseFormSectionProps): ReactElement => (
  <Row className={cx('BaseFormSection', className)}>
    <Col className="BaseFormSection-left" xl={6} lg={6} md={6}>
      {left}
    </Col>
    <Col className="BaseFormSection-right" xl={6} lg={6} md={6}>
      {children}
    </Col>
  </Row>
);

export const FormSection = ({ title, description, children, extra }: FormSectionProps): ReactElement => (
  <BaseFormSection
    left={
      <>
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
        {extra}
      </>
    }
  >
    {children}
  </BaseFormSection>
);
