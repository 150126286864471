import { uniqBy } from 'lodash';

const removeCustomTypes = propertyTypes => propertyTypes.filter(propertyType => propertyType.dataField !== null);

const removeUnfinishedFields = configuredProperties =>
  configuredProperties.filter(configuredProperty => configuredProperty.dataField !== undefined);

export const getPropertySelectOptions = (propertyTypes, configuredProperties = []) => {
  // "Property" select options are:
  // available property types + all custom properties created by user

  const nonCustomTypes = removeCustomTypes(propertyTypes).map(({ dataField, dataFieldId }) => ({
    dataField,
    dataFieldId,
  }));

  const allUsedTypes = removeUnfinishedFields(configuredProperties).map(({ dataField, dataFieldId }) => ({
    dataField,
    dataFieldId,
  }));

  const uniqueTypes = uniqBy([...nonCustomTypes, ...allUsedTypes], 'dataField');

  return uniqueTypes.map(({ dataField, dataFieldId }) => ({
    value: dataField,
    label: dataField,
    dataFieldId,
  }));
};
