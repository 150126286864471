angular.module('webUi.ui.tagmanagement.cookiePermission.view', [])

    .config(['$stateProvider', function config($stateProvider) {

        $stateProvider
            .state('site.tagmanagement.cookiePermission.view', {
                url:'/view/{settingsId}',
                views : {
                    '@site.tagmanagement' : {
                        templateUrl : 'ui/tagmanagement/cookiePermission/view/view.tpl.html',
                        controller : 'CookiePermissionViewController'
                    }
                },
                leftMenu : false,
                resolve : {
                    settings : ['$stateParams', 'CookiePermissionService', function($stateParams, CookiePermissionService) {
                        return CookiePermissionService.findCookiePermissionSetting($stateParams.settingsId);
                    }],
                    assetsPrefix: ['$rootScope', function($rootScope){
                        return $rootScope.assetsPrefix;
                    }],
                    groups : ['CookiePermissionService', function(CookiePermissionService) {
                        return CookiePermissionService.getCookiePermissionGroups();
                    }],
                    canDeleteSettings: ['$stateParams', 'CookiePermissionService', function($stateParams, CookiePermissionService){
                        return CookiePermissionService.canDelete($stateParams.settingsId);
                    }]
                }
            });
    }])

    .controller('CookiePermissionViewController', ['$state', '$scope', '$uibModal', 'CookiePermissionService', 'CodeMirrorService', 'settings', 'groups', 'assetsPrefix', 'canDeleteSettings', 'ToasterService',
        function CookiePermissionViewController ($state, $scope, $uibModal, CookiePermissionService, CodeMirrorService, settings, groups, assetsPrefix, canDeleteSettings, toast){
            // exposes codemirror settings
            $scope.codemirrorOptionsJs = CodeMirrorService.getDefaultJavascriptOptions(true);
            $scope.codemirrorOptionsHtml = CodeMirrorService.getDefaultHtmlOptions(true);
            $scope.codemirrorOptionsCss = CodeMirrorService.getDefaultCssOptions(true);

            $scope.canDeleteSettings = canDeleteSettings;
            // permission item group
            $scope.permissionItemGroups = groups;
            $scope.settings = settings;

            $scope.isEmpty = function(val) {
                return !_.isNumber(val) && _.isEmpty(val);
            };

            $scope.readonlyOptions = {
                readOnly: true
            };

            // function for preview
            $scope.preview = function(){
                CookiePermissionService.openCookiePermissionSettingPopup($scope.settings);
            };

            $scope.setsById = CookiePermissionService.getSetsById(assetsPrefix);

            $scope.getSelectedGroupName = function(groupId){
                var groups = $scope.permissionItemGroups;
                for ( var i = 0; i < groups.length; i++ ){
                    if ( groups[i].number === groupId ){
                        return groups[i].name;
                    }
                }
                return '';
            };

            // remove a cookie permission obj
            $scope.remove = function(settings){
                $uibModal.open({
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/tagmanagement/cookiePermission/delete/delete.tpl.html',
                    controller: 'CookiePermissionDeleteModalController',
                    resolve: {
                        dialogsModel: function() {
                            return {
                                cookieSetting: settings
                            };
                        }
                    }
                }).result.then(function(res){
                    if ( res ){
                        CookiePermissionService.deleteCookiePermissionSetting(settings.settingsId).then(function(){
                            toast.success('Cookie permission deleted successfully');
                            $state.transitionToStateWithSite('site.tagmanagement.cookiePermission.list', {});
                        });
                    }
                });
            };

        }]);

