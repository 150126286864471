/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_SITES = `${API_BASE}/context/sites`;

export class ClientSitesClass {
  /**
   * Get Sites by Client
   * @param clientId
   * @returns {*}
   */
  getSitesByClient(clientId) {
    return Api.callGet(`${API_SITES}?clientId=${clientId}`).then(response => response.data);
  }
}

const clientSitesService = new ClientSitesClass();

export default clientSitesService;
