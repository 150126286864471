import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import Spinner from '~/components/src/Spinner';
import { fetchBasescriptsIfNeeded, fetchBasescriptModulesIfNeeded } from '../actions';
import {
  getBasecriptsTableInfo,
  getBasescriptModules,
  isFetchingBasescripts,
  isFetchingBasescriptModules,
} from '../selectors';
import BasescriptsList from '../../components/BasescriptsList';

const BASESCRIPTS_PATH = 'context/basescripts/view/';

const buildBasescriptUrl = basescriptId => buildUrl(BASESCRIPTS_PATH + basescriptId);

class BasescriptsContainer extends Component {
  componentDidMount() {
    this.props.fetchBasescripts();
    this.props.fetchBasescriptModules();
  }

  render() {
    const { isFetching, ...props } = this.props;
    if (isFetching) {
      return <Spinner />;
    }
    return <BasescriptsList buildBasescriptUrl={buildBasescriptUrl} {...props} />;
  }
}

const mapStateToProps = state => ({
  stateKey: 'CONTEXT/BASESCRIPTS',
  list: getBasecriptsTableInfo(state),
  headers: getBasescriptModules(state),
  isFetching: isFetchingBasescripts(state) || isFetchingBasescriptModules(state),
});

const mapDispatchToProps = dispatch => ({
  fetchBasescripts: () => dispatch(fetchBasescriptsIfNeeded()),
  fetchBasescriptModules: () => dispatch(fetchBasescriptModulesIfNeeded()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('basescripts'))(BasescriptsContainer);
