import React, { ReactNode } from 'react';
import * as Yup from 'yup';
import { Field, FormikHandlers } from 'formik';
import { I18NextT } from '~/components/src/Common/types';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

interface OracleResponsysProps {
  t: I18NextT;
  onEdit: FormikHandlers['handleChange'];
  connector: any;
  isSubmitted: boolean;
}

const validations = (t: I18NextT) =>
  Yup.object().shape({
    eventName: Yup.string().required(t('cards.validation.eventName')),
  });

function OracleResponsys({ onEdit, connector, t, isSubmitted }: OracleResponsysProps): ReactNode {
  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {(formProps: any) => (
        <SmallWrapperNoOffset>
          <Field
            as={FormikInputField}
            name="eventName"
            label={t('cards.label.eventName')}
            placeholder={t('common.audienceCard.placeholder')}
            errorText={formProps.touched.eventName && formProps.errors.eventName}
          />
        </SmallWrapperNoOffset>
      )}
    </ConnectorGenericForm>
  );
}

export default OracleResponsys;
