import React, { useState } from 'react';
import { useAPI } from '~/common';
import { Notification, PickerView } from '~/components';
import DeletePopup from '~/components/src/DeletePopup';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { canDeleteExperiment, deleteExperiment } from './dataService';

type DeleteExperimentProps = {
  experimentId: string;
  experimentName: string;
  callback?: () => void;
  setIsPickerVisible: (val: boolean) => void;
};

function DeleteExperiment({ experimentId, experimentName, setIsPickerVisible, callback }: DeleteExperimentProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { data, isLoading } = useAPI(() => canDeleteExperiment(experimentId));

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteExperiment(experimentId).then(() => {
      if (callback) {
        callback();
      }
      setIsPickerVisible(false);
      showSuccess({ header: i18n.t('experiments:notifications.experimentDeleteSuccess') });
    });
    setIsDeleting(false);
  };

  const canDelete = data?.canDelete || false;

  return (
    <>
      {!isLoading &&
        (canDelete ? (
          <DeletePopup
            isLoading={isDeleting}
            title={i18n.t('experiments:form.deleteHeader')}
            hidePopup={() => setIsPickerVisible(false)}
            onClick={() => handleDelete()}
          >
            <Notification>{i18n.t('experiments:form.deleteMessage', { experimentName })}</Notification>
          </DeletePopup>
        ) : (
          <PickerView
            className="!h-auto !w-1/2"
            pickerTitle={i18n.t('experiments:form.deleteHeader')}
            handlePickerVisibility={() => setIsPickerVisible(false)}
          >
            <Notification kind="warning">
              {i18n.t('experiments:messages.deleteDisabled', { experimentName })}
            </Notification>
          </PickerView>
        ))}
    </>
  );
}

export default DeleteExperiment;
