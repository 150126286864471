import React from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import i18n from '~/common/formValidation/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { showSuccess } from '~/notificationCenter';
import { createNewAudience } from './dataService';

type CreateAudienceProps = {
  partnerId: string;
  payLoadType: string;
  hideModal: () => void;
  refetchAudiences: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  name: '',
};

const CreateAudience = ({ partnerId, payLoadType, hideModal, refetchAudiences }: CreateAudienceProps) => {
  const { setFieldValue } = useFormikContext();
  const handleCreateAudience = async (values: { name: string }) => {
    const audience = await createNewAudience(partnerId, values);

    await setFieldValue('audienceId', audience.audienceId);
    await setFieldValue('audienceName', audience.name);
    refetchAudiences();
    hideModal();
    showSuccess({
      body: i18n.t('audiences:connectors.linkedin.create.success'),
    });
  };

  return (
    <Formik onSubmit={handleCreateAudience} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, isSubmitting }) => (
        <div className="t-audienceForm flex flex-col gap-2">
          <div className="my-2 flex items-center gap-1">
            {i18n.t('audiences:connectors.linkedin.create.type')}
            <span className="t-payloadType text-gray-500">{payLoadType}</span>
          </div>
          <Field
            className="my-2"
            as={FormikInputField}
            name="name"
            label={i18n.t('audiences:connectors.linkedin.create.name')}
            placeholder={i18n.t('audiences:connectors.linkedin.create.placeholder')}
            errorText={errors.name}
          />
          <ActionButtons
            testHook="createLinkedinAudience"
            className="!mr-0 !pt-0"
            isConfirmEnabled={!isSubmitting}
            onConfirm={handleSubmit}
            onDecline={hideModal}
          />
        </div>
      )}
    </Formik>
  );
};

export default CreateAudience;
