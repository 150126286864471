import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { useAPI } from '~/common/ApiHooks';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import * as ApiService from './service';

const initialValues = {
  name: '',
  floodlightActivityGroupId: '',
  countingMethod: '',
  tagType: '',
};

const makeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('validation:validation.required')),
    floodlightActivityGroupId: Yup.string().trim().required(t('validation:validation.required')),
    countingMethod: Yup.string().trim().required(t('validation:validation.required')),
    tagType: Yup.string().trim().required(t('validation:validation.required')),
  });

const useCountingMethodOptions = (setFieldValue, t) => {
  const { data: countingMethodOptions } = useAPI(
    () =>
      ApiService.fetchCountingMethods(t).then(countingMethodOptions => {
        setFieldValue('countingMethod', countingMethodOptions[0].name);
        return countingMethodOptions;
      }),
    [],
  );

  return countingMethodOptions;
};

const useTagTypeOptions = (setFieldValue, t) => {
  const { data: tagTypeOptions } = useAPI(
    () =>
      ApiService.fetchTagTypes(t).then(tagTypeOptions => {
        setFieldValue('tagType', tagTypeOptions[0].name);
        return tagTypeOptions;
      }),
    [],
  );

  return tagTypeOptions;
};

const AddFloodlightActivityForm = ({ partnerId, selectNewlyCreatedActivity, hideModal, t }) => {
  const validationSchema = makeValidationSchema(t);

  const submitForm = activityFields =>
    ApiService.createFloodlightActivity(partnerId, activityFields, t).then(createdActivity => {
      const { id, configurationId, name } = createdActivity;
      selectNewlyCreatedActivity({ id, configurationId, name });
      hideModal();
    });

  return (
    <Formik onSubmit={submitForm} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, setFieldValue, errors, touched, isValid, isSubmitting }) => {
        const getValidationError = fieldName => touched[fieldName] && errors[fieldName];

        const countingMethodOptions = useCountingMethodOptions(setFieldValue, t);
        const tagTypeOptions = useTagTypeOptions(setFieldValue, t);

        return (
          <div className="DoubleClickOffline-floodlightFormContent">
            <Field
              as={FormikInputField}
              name="name"
              label={t('connectors.doubleClickOffline.activityName')}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              errorText={getValidationError('name')}
              autoFocus={true}
            />
            <Field
              as={FormikInputField}
              name="floodlightActivityGroupId"
              label={t('connectors.doubleClickOffline.groupId')}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              errorText={getValidationError('floodlightActivityGroupId')}
            />
            <Field
              as={FormikSelectField}
              name="countingMethod"
              label={t('connectors.doubleClickOffline.countingMethod')}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              error={getValidationError('countingMethod')}
              options={countingMethodOptions || []}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.name}
              isClearable={false}
              customSetFieldValue={(name, option) => setFieldValue(name, option.name)}
            />
            <Field
              as={FormikSelectField}
              name="tagType"
              label={t('connectors.doubleClickOffline.tagType')}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              error={getValidationError('tagType')}
              options={tagTypeOptions || []}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.name}
              customSetFieldValue={(name, option) => setFieldValue(name, option.name)}
              isClearable={false}
            />
            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={hideModal}
              isConfirmEnabled={isValid && !isSubmitting}
              className="DoubleClickOffline-addFloodlightActivityFormButtons"
              testHook="addFloodlightActivityFormButtons"
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddFloodlightActivityForm;
