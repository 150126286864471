/**
 * @description Angular module for urlbuilder -> Base
 * @class UrlBuilderBaseController
 * @namespace webUi.ui.tagmanagement.urlbuilder.campaign.base
 */
angular.module('webUi.ui.tagmanagement.urlbuilder.campaign.base',
    [
        'ui.bootstrap',
        'webUi.ui.tagmanagement.urlbuilder.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.tagmanagement.urlbuilder.campaign', {
            url: '/campaign',
            isReact: true,
        });
    }]);