angular.module('webUi.ui.content.base', [
    'webUi.ui.siteState',
    'webUi.service'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.content', {
            url: '/content',
            controller: 'ContentBaseController as vm',
            abstract: true,
            resolve: {
                hasAdservingView: ['securityContext', function (securityContext) {
                    return securityContext.hasPermission('ADSERVING_VIEW');
                }],
                hasAdservingEdit: ['securityContext', function (securityContext) {
                    return securityContext.hasPermission('ADSERVING_EDIT');
                }],
                hasDataManagementModule: ['securityContext', function (securityContext) {
                    return securityContext.hasModule('DATA_MANAGEMENT');
                }],
                hasAudiencesView: ['securityContext', function (securityContext) {
                    return securityContext.hasModule('AUDIENCES') && securityContext.hasPermission('AUDIENCES_VIEW');
                }]
            }
        });
    }])

//TODO it's vm-ed above, but there's still scope?
    .controller('ContentBaseController', ['$scope', '$state', 'hasAdservingView', 'hasAdservingEdit', 'hasDataManagementModule', 'hasAudiencesView',
        function ContentBaseController($scope, $state, hasAdservingView, hasAdservingEdit, hasDataManagementModule, hasAudiencesView) {
            $scope.hasAdservingView = hasAdservingView;
            $scope.hasAdservingEdit = hasAdservingEdit;
            $scope.hasDataManagementModule = hasDataManagementModule;
            $scope.hasAudiencesView = hasAudiencesView;
        }
    ]);
