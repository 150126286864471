(function() {
    interactionPropertiesController.$inject = ['DependencyService', 'ValidService', 'OVERVIEW_TYPES_CONSTANTS', 'PubSubService'];
    function interactionPropertiesController(DependencyService, ValidService, OVERVIEW_TYPES_CONSTANTS, PubSubService) {
        var vm = this;

        vm.$onInit = function () {
            // Data
            vm.data = {};
            vm.data.interaction = {};
            vm.data.interaction.interactionId = vm.interactionId;
            vm.data.newProperty = {};
            vm.selectedProperty = false;
            vm.sourceModule = OVERVIEW_TYPES_CONSTANTS.interaction;
            vm.entityTitle = `${vm.interactionType}: ${vm.interactionName}`;
            vm.isGettingProps = false;


            // Methods
            vm.openOverviewProperties = openOverviewProperties;
            vm.openOverviewInteraction = openOverviewInteraction;
            vm.closeOverview = closeOverview;
            vm.interactionDependants = vm.dependants;

            initializeProperties(vm.data.interaction.interactionId, vm.properties, vm.dependants);
            vm.data.propagatePropertiesCallback = vm.propagatePropertiesCallback;
        };

        var initializeProperties = function initializeProperties(interactionId, properties, dependants) {
            if (_.isEmpty(interactionId)) {
                vm.data.interaction.properties = [];
            } else {
                vm.data.interaction.properties = _.map(properties, property => {
                    return {
                        used: _.some(dependants.usedProperties,{name: property}),
                        value: property,
                        dependants: _.find(dependants.usedProperties, {name:property}) || {dependantTypes: []},
                        interactionId: interactionId,
                    };
                });

            }
        };

        vm.getNumberPropertiesLeft = function () {
            return vm.limit - vm.data.interaction.properties.length;
        };

        vm.canAddNewProperty = function canAddNewProperty() {
            if (typeof vm.limit !== 'undefined') {
                return (_.size(vm.data.interaction.properties) < vm.limit);
            }
            return true;
        };

        vm.addNewProperty = function addNewProperty($event, input, form) {
            var newProperty = vm.data.newProperty;
            var properties = vm.data.interaction.properties;
            var propagatePropertiesCallback = vm.data.propagatePropertiesCallback;

            // Show errors if somebody pressed enter or clicked submit on empty field
            input.$setDirty();
            PubSubService.publishFormValidate(form.$name);

            // We don't display error when input is focused, so in order to see error
            // onEnter we need to blur input
            $event && $event.target && $event.target.blur();

            // Leave if input is invalid
            if (!input.$valid) { return false; }

            // Only if possible to add new property
            if (vm.canAddNewProperty()) {
                if (newProperty.value && vm.isNewUniqueProperty(newProperty.value)) {
                    properties.push({
                        used: false,
                        value: newProperty.value
                    });
                    vm.data.newProperty = {};
                    // clean input state to avoid blinking error
                    input.$setPristine();
                    // refocus form;
                    $('input#data-newProperty-value').focus();
                    // var someProps = _.map(vm.data.interaction.properties, 'value');
                    propagatePropertiesCallback({properties: _.map(vm.data.interaction.properties, 'value')});
                    return true;
                }
            }
            return false;
        };

        /**
         * Method used for the editable directive, I have to pass this editProperty as an object to the editable directive
         * Not possible apply pure functions suggestions
         * @type {{editSuccessCallback: interactionPropertiesController.editProperty.editSuccessCallback}}
         */
        vm.editPropertyCallback = {
            editSuccessCallback: function(newValue, oldValue) {
                var indexOldProperty = _.findIndex(vm.data.interaction.properties, {'value': oldValue});
                if(_.isEmpty(newValue) || indexOldProperty === -1) {
                    return false;
                }
                vm.data.interaction.properties[indexOldProperty].value = newValue;
                vm.data.propagatePropertiesCallback({properties: _.map(vm.data.interaction.properties, 'value')});
                return true;
            }
        };

        vm.validateProperty = function validateProperty(property) {
            if (property !== undefined && property.length !== 0) {
                return false;
            }
            return true;
        };

        /**
         * Deletes a property
         * @param index
         */
        vm.deleteProperty = function deleteProperty(property, properties, propagatePropertiesCallback) {
            //if empty or property is used don't delete
            if(_.isEmpty(property) || property.used) {
                return;
            }
            _.remove(properties, {value: property.value});
            propagatePropertiesCallback({properties: _.map(vm.data.interaction.properties, 'value')});
        };

        /**
         *
         * @description check that the properties have unique names ignore empty strings
         * @returns {boolean}
         */
        vm.isUniqueProperty = function isUniqueProperty(property, newPropertyValue) {
            var indexOldPropertyValue = _.findIndex(vm.data.interaction.properties, {'value': property.value});
            var propertiesWithoutCurrentProperty = _.without(_.pluck(vm.data.interaction.properties, 'value'), vm.data.interaction.properties[indexOldPropertyValue].value);
            return !_.contains(propertiesWithoutCurrentProperty, newPropertyValue);
        };

        vm.isNewUniqueProperty = function isNewUniqueProperty(newProperty) {
            return !_.contains(_.pluck(vm.data.interaction.properties, 'value'), newProperty);
        };

        vm.isNotJsReservedKeyword = function isNotJsReservedKeyword(name) {
            return ValidService.isValidNotJsReservedKeyword(name);
        };

        /**
         * open the overview of the inner properties
         * @param property
         */
        function openOverviewProperties(property) {
            vm.isGettingProps = true;
            vm.selectedProperty = property;
        }

        /**
         * open the overview dependencies from the parent interaction
         * @param name
         * @param interactionId
         */
        function openOverviewInteraction(name, interactionId) {
            vm.isGettingProps = false;
            vm.selectedProperty = {
                value: name,
                interactionId: interactionId
            };
        }

        function closeOverview() {
            vm.selectedProperty = false;
        }
    }

    angular.module('webUi.component.profiles.shared.interactionProperties', ['webUi.service', 'webUi.service.profilesConstants'])
        .component('interactionProperties', {
            templateUrl: 'component/profiles/shared/interactionProperties/interactionProperties.tpl.html',
            transclude: true,
            controller: interactionPropertiesController,
            controllerAs: 'vm',
            bindings: {
                isEditing: '<',
                properties: '<',
                limit: '<',
                interactionId: '<?',
                interactionName: '<?',
                interactionType: '<?',
                dependants: '<?',
                propagatePropertiesCallback: '&?'
            }
        });
})();
