import React, { useState } from 'react';
import { Field, FormSection } from 'redux-form';

import { ReduxFormInputField, ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, isPositiveNumber } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import BtnOutlined from '~/components/src/BtnOutlined';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import './styles.scss';

const { CREATE_SERVER } = constants.modes;

const AzureEventHubForm = props => {
  const { t, canUpdate, mode, values, partner } = props;

  const [isConnectionStringFieldVisible, setIsConnectionStringFieldVisible] = useState(!canUpdate);
  return (
    <FormSection name={constants.partnerTypes.AZURE_EVENTHUB}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        {mode === CREATE_SERVER && (
          <Field
            label={t('form.formFields.partnerNumberLabel')}
            name="partnerNumber"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={[isRequired, isPositiveNumber]}
          />
        )}
        {isConnectionStringFieldVisible ? (
          <Field
            label={t('form.formFields.connectionString')}
            name="connectionString"
            component={ReduxFormTextAreaField}
            size="medium"
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={[isRequired]}
            type="text"
          />
        ) : (
          <BtnOutlined
            size="xs"
            onClick={() => {
              setIsConnectionStringFieldVisible(true);
            }}
            testHook="configureConnectionStringButton"
          >
            {t('form.formFields.configureConnectionStringButton')}
          </BtnOutlined>
        )}
      </ContainerFormSection>
    </FormSection>
  );
};

export default AzureEventHubForm;
