import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getSite, truncateSite, SITE_TRUNCATE_FORM, SITE_TRUNCATE_CONFIRMATION } from '~/context/sites';
import SiteTruncate from '~/context/components/SiteTruncate';
import i18n from '../../i18n';

const isConfirmationValid = value =>
  value && value.toString().trim() === SITE_TRUNCATE_CONFIRMATION ? undefined : i18n.t('sites:truncate.invalidText');

export default compose(
  connect(
    (state, ownProps) => ({
      site: getSite(state, ownProps.siteId),
      t: ownProps.t,
      isConfirmationValid,
      confirmationText: SITE_TRUNCATE_CONFIRMATION,
      initialValues: {
        siteId: ownProps.siteId,
      },
    }),
    dispatch => ({
      onSubmit: values => dispatch(truncateSite(values)),
    }),
  ),
  reduxForm({
    form: SITE_TRUNCATE_FORM,
  }),
)(SiteTruncate);
