import React from 'react';
import cx from 'classnames';
import { IconType } from 'react-icons';

interface IconProps {
  size?: string | number;
  color?: string;
  className?: string;
  icon?: IconType;
  iconClass?: string;
}

const Icon = ({ size = 15, color, className, icon: IconContent, iconClass, ...other }: IconProps) => {
  // SVG icon
  if (IconContent) {
    return (
      <span className={cx('Icon', className)} {...other}>
        <IconContent size={size} color={color} />
      </span>
    );
  }
  // Font Icon class
  if (iconClass) {
    return <i className={cx(iconClass, className)} {...other} />;
  }

  return null;
};

export default Icon;
