import React from 'react';
import PropTypes from 'prop-types';
import Notification from '~/components/src/Notification';
import Dropdown from '~/components/src/Dropdown';
import Page from '~/components/src/Page';
import OverlaySpinner from '~/components/src/OverlaySpinner';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spinner from '~/components/src/Spinner';
import Heading from '~/components/src/Heading';
import { getAngularService } from 'ReactAngular/react.service';
import { components } from '~/components/src/Table';
import AudienceList from '~/profiles/components/AudienceList';
import EmptyList from '~/profiles/components/AudienceListEmpty';
import DependantsContainer from '~/profiles/components/DependantsContainer';
import { changeUrl } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

const { DetailContainer } = components;

class AudiencesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      query: '',
      isSupportEngineer: false,
      canEditAudience: false,
    };
    this.toggleMore = this.toggleMore.bind(this);
    this.checkPage = this.checkPage.bind(this);
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');

    SecurityService.getSecurityContext()
      .then(context => {
        this.setState({
          isSupportEngineer: context.isSupportEngineer(),
          canEditAudience: context.hasPermission('AUDIENCES_EDIT'),
        });
      })
      .catch(() => {});

    this.props.fetchData();
    this.props.fetchGroup();
  }

  componentDidUpdate(prevProps) {
    const { fetchData, params, list } = this.props;

    if (
      prevProps?.params.page !== params.page ||
      params.groupId !== prevProps.groupId ||
      prevProps?.list.length !== list.length // for fixing pagination bug after deletion
    ) {
      fetchData(this.state.query);
    }
    if (prevProps?.list.length !== this.props.list.length || prevProps.isFetching !== this.props.isFetching) {
      this.checkPage();
    }
  }

  componentWillUnmount() {
    const { onDependantHideClick } = this.props;
    if (typeof onDependantHideClick === 'function') {
      onDependantHideClick();
    }
  }

  checkPage() {
    const { isFetching, lastPage, list, params, onPaginate, emptyQty } = this.props;

    // If current page has no elements let's move to the last one
    // We need emptyQty as in subfolders we add "..." folder to allow move audiences up,
    // so empty list has always 1 element
    if (!isFetching && list.length === emptyQty && params.page > 0) {
      onPaginate(lastPage);
    }
  }

  toggleMore() {
    this.setState(prevState => ({
      showMore: !prevState.showMore,
    }));
  }

  render() {
    const {
      actions,
      audienceUrl,
      canEdit,
      crumbs,
      dropdownActions,
      group,
      groupUrl,
      list,
      nodesQty,
      onMoveAudience,
      onPaginate,
      stateKey,
      isMoving,
      isFetching,
      emptyQty,
      searchAudiences,
      onDependantIconClick,
      match: { params: { groupId, page } = {} } = {},
      t,
      isDependantsContainerVisible,
      selectedAudience,
      onDependantHideClick,
    } = this.props;
    const { showMore } = this.state;
    const SegmentStatsService = getAngularService(document, 'SegmentStatsService');

    return (
      <Page className="AudiencesPage">
        <Heading title={group?.name || t('list.header')} crumbs={crumbs}>
          <>
            <SearchElement
              className="HeaderActions-listSearch"
              value={this.state.query}
              onChange={e => {
                this.setState({ query: e.target.value });
                return searchAudiences({ query: e.target.value, groupId, page });
              }}
              placeholder={t('common:actions.search')}
            />
            <BtnIcon
              icon="download"
              testHook="exportCSV"
              tooltip={t('list.actions.export')}
              onClick={() => SegmentStatsService.exportToCsv()}
            />
            <BtnIcon
              icon="historicProcessing"
              testHook="historicalViewAudienceButton"
              tooltip={t('audiencePage.historicalView')}
              onClick={() => changeUrl('audiences/segments/hp/')}
              disabled={!this.state.canEditAudience}
            />
            {canEdit && (
              <div className="inline">
                <BtnIcon
                  icon="add"
                  color="blue"
                  testHook="addAudience"
                  tooltip={t('list.actions.add')}
                  onClick={() => this.toggleMore()}
                />
                {showMore && (
                  <Dropdown
                    className="addAudiences"
                    actions={dropdownActions}
                    toggleDropdown={() => this.toggleMore()}
                  />
                )}
              </div>
            )}
          </>
        </Heading>
        <Notification kind="information">
          <p>{t('list.explainer')}</p>
        </Notification>
        {isFetching && <Spinner />}
        {!isFetching && list.length === emptyQty && <EmptyList t={t} searchQuery={this.state.query} />}

        {!isFetching && list.length > emptyQty && (
          <AudienceList
            onPaginate={onPaginate}
            nodesQty={nodesQty}
            onMoveAudience={onMoveAudience}
            audienceUrl={audienceUrl}
            groupUrl={groupUrl}
            list={list}
            stateKey={stateKey}
            actions={actions}
            t={t}
            canEdit={canEdit}
            onDependantIconClick={onDependantIconClick}
          />
        )}
        <div>
          <DetailContainer
            className="!top-10"
            onClose={onDependantHideClick}
            isVisible={isDependantsContainerVisible}
            title={t('list.dependency')}
          >
            <DependantsContainer
              element={selectedAudience}
              elementName={selectedAudience ? selectedAudience.name : null}
              selectedTab={0}
              showContentDependants={false}
              showDataCollectorDependants={false}
              showJourneyDependants={false}
              showFilterDependants={false}
              showTransformationDependants={false}
            />
          </DetailContainer>
        </div>
        <OverlaySpinner isActive={isMoving} />
      </Page>
    );
  }
}

AudiencesPage.propTypes = {
  actions: PropTypes.func,
  dropdownActions: PropTypes.array,
  audienceUrl: PropTypes.func,
  groupUrl: PropTypes.func,
  canEdit: PropTypes.bool,
  nodesQty: PropTypes.number,
  emptyQty: PropTypes.number,
  lastPage: PropTypes.number,
  crumbs: PropTypes.array,
  group: PropTypes.object,
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  onMoveAudience: PropTypes.func.isRequired,
  onPaginate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isMoving: PropTypes.bool,
  searchAudiences: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AudiencesPage;
