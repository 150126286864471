import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Fields from '~/components/src/Form/Fields';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import { Panel } from '~/components/src/Containers';
import {
  isRequired,
  isPositiveNumber,
  isValidSubnet,
  defaultMaxInput,
  defaultMaxText,
  normalizeToInteger,
} from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
  ReduxFormCheckboxGroupField,
} from '~/components/src/Form/Fields/ReduxFormFields';

export const SiteForm = ({
  handleSubmit,
  submitting,
  cancelHref,
  clients,
  timezones,
  isSiteEditable,
  isTagModuleSelected,
  isAIModuleEnabled,
  isJOModuleEnabled,
  siteModules,
  t,
  touch,
  formState,
}) => {
  const maxTagsPart =
    !isSiteEditable || !isTagModuleSelected ? null : (
      <Field
        label={t('form.maxTags.label')}
        name="maxTags"
        component={ReduxFormInputField}
        placeholder={t('form.maxTags.placeholder')}
        normalize={normalizeToInteger}
        validate={[isRequired, isPositiveNumber]}
        type="number"
      />
    );
  const restrictedSubnetsPart = !isSiteEditable ? null : (
    <Field
      label={t('form.restrictedSubnets.label')}
      name="restrictedSubnets"
      component={ReduxFormTextAreaField}
      placeholder={t('form.restrictedSubnets.placeholder')}
      validate={[isValidSubnet, defaultMaxText]}
    />
  );
  const maxInputsForAIModel = () => (
    <Field
      label={t('form.maxInputsForAIModel.label')}
      name="maxInputsForAIModel"
      component={ReduxFormInputField}
      placeholder={t('form.maxInputsForAIModel.placeholder')}
      normalize={normalizeToInteger}
      validate={[isRequired, isPositiveNumber]}
      type="number"
    />
  );

  const maxJourneyLimit = () => {
    const hasValue = formState?.values?.maxJourneyLimit ? [isPositiveNumber] : [];
    return (
      <Field
        label={t('form.maxJourneyLimit.label')}
        name="maxJourneyLimit"
        component={ReduxFormInputField}
        placeholder={t('form.maxJourneyLimit.placeholder')}
        normalize={normalizeToInteger}
        validate={hasValue}
        type="number"
      />
    );
  };

  const maxAiModelLimit = () => {
    const hasValue = formState?.values?.maxAiModelLimit ? [isPositiveNumber] : [];
    return (
      <Field
        label={t('form.maxAiModelLimit.label')}
        name="maxAiModelLimit"
        component={ReduxFormInputField}
        placeholder={t('form.maxAiModelLimit.placeholder')}
        normalize={normalizeToInteger}
        validate={hasValue}
        type="number"
      />
    );
  };

  return (
    <Panel>
      <Form onSubmit={handleSubmit} isSubmitting={submitting}>
        <div>
          <Field
            label={t('common.name')}
            name="name"
            component={ReduxFormInputField}
            placeholder={t('form.name.placeholder')}
            validate={[isRequired, defaultMaxInput]}
            type="text"
          />
          <Field
            label={t('common.clientName')}
            name="clientId"
            component={ReduxFormSelectField}
            placeholder={t('form.clientName.placeholder')}
            validate={isRequired}
            options={clients}
            touch={touch}
          />
          <Field
            label={t('common.timezone')}
            name="timeZone"
            component={ReduxFormSelectField}
            placeholder={t('form.timezone.placeholder')}
            validate={isRequired}
            options={timezones}
            touch={touch}
          />
          {restrictedSubnetsPart}
          <Field label={t('form.demosite.label')} name="demo" component={Fields.Checkbox} />
          <Field label={t('form.allowTruncate.label')} name="allowTruncate" component={Fields.Checkbox} />
          <Field label={t('form.advancedSecurity.label')} name="advancedSecurity" component={Fields.Checkbox} />
          <br />
          <Field
            label={t('form.siteModules.label')}
            name="siteModules"
            component={ReduxFormCheckboxGroupField}
            options={siteModules}
          />
          {isJOModuleEnabled && maxJourneyLimit()}
          {isAIModuleEnabled && maxInputsForAIModel()}
          {isAIModuleEnabled && maxAiModelLimit()}
          {maxTagsPart}
        </div>
        <FormActions t={t} submitting={submitting} cancelHref={cancelHref} />
      </Form>
    </Panel>
  );
};

SiteForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  isSiteEditable: PropTypes.bool,
  isTagModuleSelected: PropTypes.bool,
  isAIModuleEnabled: PropTypes.bool,
  isJOModuleEnabled: PropTypes.bool,
  clients: PropTypes.array,
  timezones: PropTypes.array,
  siteModules: PropTypes.array,
};

export default SiteForm;
