import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Configurations Objects
 * @param state
 * @param action
 * @returns {{}}
 */

const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.CONFIGURATIONS_FETCH_SUCCESS:
      return action.payload.reduce(
        (accumulator, currentValue) =>
          // connectionConfiguration is renamed to configuration
          // due to inconsistent endpoints
          // TODO change back end

          ({
            ...accumulator,
            [currentValue.configId]: {
              ...currentValue,
              configuration: currentValue.connectionConfiguration,
              connectionConfiguration: null,
            },
          }),
        {},
      );
    case types.CONFIGURATION_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    case types.REPORT_START:
      return {
        ...state,
        [action.payload.configId]: {
          ...state[action.payload.configId],
          lastAttempt: action.payload.lastAttempt,
        },
      };
    case types.REPORT_SUCCESS:
      return {
        ...state,
        [action.payload.configId]: {
          ...state[action.payload.configId],
          lastSuccess: action.payload.lastSuccess,
        },
      };
    case types.REPORT_FAIL:
      return {
        ...state,
        [action.payload.configId]: {
          ...state[action.payload.configId],
          lastError: action.payload.error,
        },
      };
    default:
      return state;
  }
};

/**
 * Configurations Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.CONFIGURATIONS_FETCH_SUCCESS:
      return action.payload.map(item => item.configId);
    case types.CONFIGURATION_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

/**
 * We will keep an array of non deletable evironment codes
 * @param state
 * @param action
 * @returns bool
 */
const currentConnectionTest = (state = null, action = defaultAction) => {
  switch (action.type) {
    case types.CONFIGTEST_FETCH_SUCCESS:
      return action.payload;
    case types.CONFIGTEST_CLEANUP:
      return null;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  isRunningReport: false,
  error: null,
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.CONFIGURATIONS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
      };
    case types.CONFIGURATIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
      };
    case types.REPORT_START:
      return {
        ...state,
        error: null,
        isRunningReport: true,
      };
    case types.REPORT_SUCCESS:
      return {
        ...state,
        isRunningReport: false,
      };
    case types.REPORT_FAIL:
      return {
        ...state,
        error: action.payload.error,
        isRunningReport: false,
      };
    default:
      return state;
  }
};

const adminConfigurationReducer = combineReducers({
  byId,
  allIds,
  currentConnectionTest,
  ui,
});

export default adminConfigurationReducer;
