import { RulesMap } from '~/common/modules/rules/selectors';

export const EVENTS = {
  ENGAGEMENT: {
    ...RulesMap.EngagementRule,
    capitalizedType: 'EngagementRule',
  },
  EXTERNAL_FACT: {
    ...RulesMap.CustomRule,
    capitalizedType: 'CustomRule',
  },
};
