angular.module('webUi.service.common.securityCommonService', [
    'restangular',
    'webUi.common.Utils'
])
/**
 * @namespace SecurityCommonService
 */
    .factory('SecurityCommonService', ['Restangular', function (Restangular) {

        function updateRestrictedSubnets (identifier, endPointUrl, restrictedSubnetsString) {
            var restrictedSubnets;
            if (_.isEmpty(restrictedSubnetsString)) {
                restrictedSubnets = null;
            } else {
                restrictedSubnets = [];
                var split = restrictedSubnetsString.split(',');
                for (var i = 0; i < split.length; i++) {
                    restrictedSubnets.push(split[i].trim());
                }
            }
            var payload = _.assign(identifier, {restrictedSubnets: restrictedSubnets});

            return Restangular.all(endPointUrl).post(payload);
        }


        return {
            updateRestrictedSubnets:updateRestrictedSubnets
        };
    }]);
