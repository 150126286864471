import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Empty from '~/components/src/Empty';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell, RowActions } = components;
const { withPaginate, withEmpty, withFilter } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('clients:view.domains.list.empty.header')} body={t('clients:view.domains.list.empty.body')} />
);

const NoResults = ({ t }) => (
  <Empty header={t('clients:view.domains.list.noResult.header')} body={t('clients:view.domains.list.noResult.body')} />
);

const props = (sortKey, title, sortFn) => ({
  sortKey,
  title,
  sortFn,
});

/**
 * Client List component
 * [stateless]
 * @param list
 * @param stateKey
 * @param clientUrl
 * @param onDeleteClick
 * @param t
 * @returns {*}
 * @constructor
 */
const DomainList = ({ list, stateKey, onDeleteClick, onEditClick, isContextAdmin, client, t }) => {
  const hasExternalAuthentication = client?.externalAuthentication?.enabled;
  const disableLastDomain = hasExternalAuthentication && list.length === 1;

  const deleteTooltip = () => {
    if (disableLastDomain) {
      return t('clients:view.domains.delete.disabledForAuth');
    }
    if (!isContextAdmin) {
      return t('clients:view.domains.edit.disabledText');
    }
    return t('clients:view.domains.delete.enabledText');
  };
  return (
    <Panel>
      <Table stateKey={stateKey}>
        <Row>
          <SortableHeader {...props('description', t('clients:view.domains.columns.description'))} />
        </Row>
        {list.map(item => {
          const actions = [
            {
              name: t('clients:view.domains.edit.enabledText'),
              tooltip: !isContextAdmin
                ? t('clients:view.domains.edit.disabledText')
                : t('clients:view.domains.edit.enabledText'),
              isDisabled: !isContextAdmin,
              icon: 'edit',
              onClick: () => onEditClick({ ...item }),
              testHook: 'edit',
            },
            {
              name: t('clients:view.domains.delete.enabledText'),
              tooltip: deleteTooltip(),
              isDisabled: !isContextAdmin || disableLastDomain,
              icon: 'delete',
              onClick: e => onDeleteClick(item, e),
              testHook: 'delete',
              type: 'delete',
            },
          ];

          return (
            <Row key={item.clientDomainId} withActions>
              <Cell>{item.description}</Cell>
              <RowActions actions={actions} />
            </Row>
          );
        })}
      </Table>
    </Panel>
  );
};

DomainList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  isContextAdmin: PropTypes.bool.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withPaginate({ size: 25 }),
)(DomainList);
