angular.module('webUi.ui.tagmanagement.experiments.dashboard', ['webUi.ui.tagmanagement.experiments.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.experiments.dashboard', {
                url : '/dashboard',
                isReact: true
            });
    }]);

