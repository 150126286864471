import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, isPositiveNumber, normalizeToInteger } from '~/common';
import Form from '~/components/src/Form/Form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';

export const ColumnForm = ({ handleSubmit, submitting, backHref, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <Field
      label={t('columns.editColumnForm.columnName.label')}
      name="name"
      placeholder={t('columns.editColumnForm.columnName.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, defaultMaxInput]}
      type="text"
    />
    <Field
      label={t('columns.editColumnForm.orderNumber.label')}
      name="orderNumber"
      placeholder={t('columns.editColumnForm.orderNumber.placeholder')}
      component={ReduxFormInputField}
      normalize={normalizeToInteger}
      validate={[isRequired, defaultMaxInput, isPositiveNumber]}
      type="number"
    />
    <Field
      label={t('columns.editColumnForm.description.label')}
      name="description"
      placeholder={t('columns.editColumnForm.description.placeholder')}
      component={ReduxFormInputField}
      validate={defaultMaxInput}
      type="text"
    />
    <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

ColumnForm.propTypes = {
  handleSubmit: PropTypes.func,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default ColumnForm;
