/**
 * A fixed scrollfix that handles dynamic content
 * @see https://github.com/angular-ui/ui-utils
 * @see http://codepen.io/ygotthilf/pen/FABhk
 */
angular.module('ui.scrollfix', [])
.directive('uiScrollfix', ['$window', function ($window) {
    function getWindowScrollTop() {
        if (angular.isDefined($window.pageYOffset)) {
            return $window.pageYOffset;
        } else {
            var iebody = (document.compatMode && document.compatMode !== 'BackCompat') ? document.documentElement : document.body;
            return iebody.scrollTop;
        }
    }
    return {
        require: '^?uiScrollfixTarget',
        link: function (scope, elm, attrs, uiScrollfixTarget) {
            var absolute = true,
                shift = 0,
                fixLimit,
                $target = uiScrollfixTarget && uiScrollfixTarget.$element || angular.element($window);

            if (!attrs.uiScrollfix) {
                absolute = false;
            } else if (typeof (attrs.uiScrollfix) === 'string') {
                // charAt is generally faster than indexOf: http://jsperf.com/indexof-vs-charat
                if (attrs.uiScrollfix.charAt(0) === '-') {
                    absolute = false;
                    shift = -parseFloat(attrs.uiScrollfix.substr(1));
                } else if (attrs.uiScrollfix.charAt(0) === '+') {
                    absolute = false;
                    shift = parseFloat(attrs.uiScrollfix.substr(1));
                }
            }

            fixLimit = absolute ? attrs.uiScrollfix : elm[0].offsetTop + shift;

            function onScroll() {

                var limit = absolute ? attrs.uiScrollfix : elm[0].offsetTop + shift;

                // if pageYOffset is defined use it, otherwise use other crap for IE
                var offset = uiScrollfixTarget ? $target[0].scrollTop : getWindowScrollTop();
                if (!elm.hasClass('ui-scrollfix') && offset > limit) {
                    elm.addClass('ui-scrollfix');
                    fixLimit = limit;
                } else if (elm.hasClass('ui-scrollfix') && offset < fixLimit) {
                    elm.removeClass('ui-scrollfix');
                }
            }

            $target.on('scroll', onScroll);

            // Unbind scroll event handler when directive is removed
            scope.$on('$destroy', function () {
                $target.off('scroll', onScroll);
            });
        }
    };
}])
.directive('uiScrollfixTarget', [function () {
    return {
        controller: ['$element', function ($element) {
            this.$element = $element;
        }]
    };
}]);
