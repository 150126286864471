angular.module('webUi.ui.admin.users', [
    'webUi.ui.admin.base'
])
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.admin.users', {
                url : '/users',
                isReact: true,
            })
            .state('site.admin.usersAdd', {
                url : '/users/add',
                isReact: true,
            });
    }]);
