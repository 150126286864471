import { get } from 'lodash';
import { DEFAULT_DATA_RETENTION_PERIOD, DEFAULT_API_RATE_LIMIT } from './types';

/**
 * get all clients
 * @param state
 */
export const getClients = state =>
  state.context.clients.clientList.allClientsIds.map(id => state.context.clients.clientList.byClientId[id]);

/**
 *
 * @param state
 * @param id
 * @returns {*}
 */
export const byClientId = (state, id) => {
  const client = get(state, `context.clients.clientList.byClientId[${id}]`);
  return !client
    ? null
    : {
        ...client,
        dataRetentionMonths: client.dataRetentionMonths || DEFAULT_DATA_RETENTION_PERIOD,
        mappingRetentionMonths: client.mappingRetentionMonths || null,
        apiRateLimit: client.apiRateLimit || DEFAULT_API_RATE_LIMIT,
      };
};

/**
 *
 * @param state
 * @param id
 * @returns {*}
 */
export const getClientPasswordPolicy = (state, id) =>
  get(state, `context.clients.clientList.byClientId[${id}].passwordPolicy`);

/**
 * State flag
 * @param state
 * @returns {*}
 */
export const shouldFetchClients = state => get(state, 'context.clients.clientList.ui.shouldFetchAll');
/**
 *
 * @param state
 * @returns {*}
 */
export const isFetchingClients = state => get(state, 'context.clients.clientList.ui.isFetching');

export const getFilter = state => get(state, 'context.clients.clientList.ui.filter');

export const getClientsForSelect = state => {
  const clients = getClients(state);
  return clients.map(client => ({
    value: client.clientId,
    label: client.name,
  }));
};
