import React, { useState } from 'react';
import cx from 'classnames';
import { Field, FormikProps } from 'formik';
import { components } from 'react-select';
import * as Yup from 'yup';
import { useApiWithState } from '~/common/ApiHooks';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import makeAnimated from 'react-select/animated';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { VISIBILITY } from './constants';
import CreateAddressBook from './CreateAddressBook';
import { fetchAllAddressBooks } from './dataService';

const validations = Yup.object().shape({
  addressBookId: Yup.number().required(i18n.t('validation:validation.required')),
});

type DotdigitalProps = {
  onEdit: () => void;
  isSubmitted: boolean;
  connector: {
    configuration: any;
    partnerDetails: {
      partnerId: string;
      name: string;
    };
  };
};

const animatedComponents = makeAnimated();

const Option = (props: any) => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full flex-col gap-1">
          <span>{props.data.name}</span>
          <span className="text-sm text-gray-500">
            {i18n.t('audiences:connectors.dotdigital.contacts')}: {props.data.contacts || 0}
          </span>
        </div>

        <span
          className={cx('rounded-full border bg-green-100 px-2 py-1 text-green-700', {
            'bg-indigo-100 text-indigo-500': props.data?.visibility?.name === VISIBILITY.PRIVATE,
          })}
        >
          {props.data.visibility.label}
        </span>
      </div>
    </components.Option>
  </div>
);

const Dotdigital = ({ onEdit, connector, isSubmitted }: DotdigitalProps) => {
  const { partnerId } = connector.partnerDetails;
  const [showCreateAddressBookModal, setShowCreateAddressBookModal] = useState(false);

  const {
    state: addressBooks,
    isLoading,
    refetch: refetchAddressBooks,
  } = useApiWithState(() => fetchAllAddressBooks(partnerId));

  if (isLoading)
    return (
      <div className="mx-16 my-6">
        <Spin />
      </div>
    );

  return (
    <ConnectorGenericForm onEdit={onEdit} validations={validations} connector={connector} isSubmitted={isSubmitted}>
      {(formikProps: FormikProps<{ addressBookId: string }>) => {
        const { setFieldValue, errors } = formikProps;

        return (
          <>
            <div className="flex w-2/3 items-center gap-2">
              <Field
                className="mb-3 w-full"
                name="addressBookId"
                label={i18n.t('audiences:connectors.dotdigital.addressBook')}
                placeholder={i18n.t('audiences:connectors.dotdigital.placeholder')}
                as={FormikSelectField}
                getOptionLabel={(option: { name: string; id: number }) => option.name}
                getOptionValue={(option: { name: string; id: number }) => option.id}
                customSetFieldValue={(name: string, option: { name: string; id: string }) => {
                  setFieldValue(name, option.id);
                  setFieldValue('addressBookName', option.name);
                  setTimeout(() => formikProps.validateField('addressBookId'));
                }}
                options={addressBooks || []}
                components={{
                  Option,
                  animatedComponents,
                }}
                errorText={errors?.addressBookId}
              />
              <BtnIcon
                testHook="createAddressBook"
                icon="add"
                className="rounded-full"
                tooltip={i18n.t('audiences:connectors.dotdigital.tooltips.create')}
                onClick={() => setShowCreateAddressBookModal(true)}
              />
            </div>

            {showCreateAddressBookModal && (
              <PickerView
                pickerTitle={i18n.t('audiences:connectors.dotdigital.create.title')}
                className="!h-auto !w-1/2 lg:!w-1/3 xl:!w-1/4"
              >
                <CreateAddressBook
                  partnerId={partnerId}
                  hideModal={() => setShowCreateAddressBookModal(false)}
                  refetchAddressBooks={refetchAddressBooks}
                />
              </PickerView>
            )}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default Dotdigital;
