import React, { ReactElement, useState } from 'react';
import i18n from '~/i18n';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import { Notification } from '~/components';
import Alert, { AlertType } from '~/components/src/Alert';
import moment from 'moment';
import Badge from '~/components/src/Badge';
import { ErrorPropertyProps, ErrorType, ErrorGroupType, ConnectorErrors } from './types';

function EmptyList(): ReactElement {
  return (
    <Alert
      testHook="emptyMessage"
      suffix={i18n.t('connectors:errorStats.emptyConnectorSuffix')}
      prefix={i18n.t('connectors:errorStats.emptyPrefix')}
      type={AlertType.DEFAULT}
    />
  );
}

function MappingErrorProperty({ mKey, mValue, mValueClass }: ErrorPropertyProps): ReactElement {
  return (
    <span className={`text-slate-500 dark:text-slate-400 t-${mKey}`}>
      {i18n.t(`connectors:errorStats.${mKey}`)}
      <span className={cx('ml-2 font-medium text-slate-600 dark:text-slate-300', mValueClass, `t-${mValue}`)}>
        {mValue}
      </span>
    </span>
  );
}

function ErrorsList({ data }: { data: ConnectorErrors }): ReactElement {
  const { errors } = data;

  const [selectionId, setSelectionId] = useState('');
  if (!errors || errors?.length === 0) {
    return <EmptyList />;
  }
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {errors.map((tError: ErrorGroupType, i: number) => (
        <div key={`accordion-collapse-${i}`} className="t-errorItem">
          <h2 id={`accordion-collapse-heading-${i}`}>
            <button
              type="button"
              className={cx(
                't-errorExpandButton flex w-full items-center justify-between border border-gray-200 px-5 py-4 text-left font-medium text-gray-500 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800',
                {
                  'border-b-0': i !== errors.length - 1,
                  'rounded-t-xl': i === 0,
                  'rounded-b-xl': i === errors.length - 1,
                  'rounded-b-none bg-slate-100 dark:bg-gray-900': selectionId === `accordion-collapse-body-${i}`,
                },
              )}
              data-accordion-target={`#accordion-collapse-body-${i}`}
              aria-expanded="true"
              aria-controls={`accordion-collapse-body-${i}`}
              onClick={() =>
                setSelectionId(selectionId === `accordion-collapse-body-${i}` ? '' : `accordion-collapse-body-${i}`)
              }
            >
              <span className="flex flex-col gap-1">
                <MappingErrorProperty
                  mValueClass="text-sm ml-2 text-app-blue-700 dark:text-app-blue-700"
                  mKey="errorCode"
                  mValue={tError.errorCode}
                />
                {tError.errorSubCode && (
                  <MappingErrorProperty
                    mValueClass="text-sm ml-2 text-app-blue-700 dark:text-app-blue-700"
                    mKey="errorSubCode"
                    mValue={tError.errorSubCode}
                  />
                )}
              </span>

              <div>
                <Badge className="mr-2" content={tError?.count || 0} color="red" testHook="errorCount" />
                <Icons
                  icon="downArrow"
                  className={cx('h-6 w-6', { 'rotate-180': selectionId === `accordion-collapse-body-${i}` })}
                />
              </div>
            </button>
          </h2>
          <div
            id={`accordion-collapse-body-${i}`}
            className={cx({ hid: selectionId !== `accordion-collapse-body-${i}` })}
            aria-labelledby="accordion-collapse-heading-1"
          >
            <div className="border border-b-0 border-gray-200 px-5 dark:border-gray-700 dark:bg-gray-900">
              {tError.uniqueErrors.map((mError: ErrorType, j: number) => (
                <div
                  key={`accordion-collapse-mapping-${j}`}
                  id={`accordion-collapse-mapping-${i}${j}`}
                  className="flex flex-col-reverse border-b border-gray-200 py-2 dark:border-gray-700 md:flex-row md:justify-between md:gap-4"
                >
                  <div className="grid grid-cols-1 gap-2">
                    <div className="flex flex-col gap-2">
                      <MappingErrorProperty
                        mValueClass="text-app-blue-700 dark:text-app-blue-700"
                        mKey="errorTitle"
                        mValue={mError.title}
                      />
                      <MappingErrorProperty
                        mValueClass="text-app-gray-700 font-normal dark:text-app-blue-700"
                        mKey="message"
                        mValue={mError.message}
                      />
                    </div>
                  </div>
                  <div className="mb-2 border-b border-dashed border-gray-100 pb-2 dark:border-gray-800 md:mb-0 md:w-2/5 md:border-b-0 md:pb-0">
                    <p className="flex justify-between text-sm text-slate-500">
                      <span>{i18n.t('connectors:errorStats.errorMetaData')}</span>
                      <span className="text-slate-400">{moment(mError.timestamp).fromNow()}</span>
                    </p>
                    <pre className="t-metaData mt-1 text-red-400">{JSON.stringify(mError.metaData, null, 4)}</pre>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ErrorsList;
