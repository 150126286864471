import React from 'react';
import cx from 'classnames';

type LabelValueProps = {
  label: string;
  value: string | number;
  testHook?: string;
};

export const LabelValue = ({ label, value, testHook = 'label' }: LabelValueProps): React.ReactElement => (
  <div className="flex w-full justify-between">
    <p className="text-gray-500">{label}</p>
    <p className={cx(`t-${testHook}`, 'font-medium text-gray-800')}>{value}</p>
  </div>
);
