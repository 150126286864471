import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners`;

const getDoubleClickURL = partnerId => `${BASE_PATH}/doubleclick/${partnerId}/userLists`;

const getDoubleClickUsersList = partnerId =>
  Api.callGet(getDoubleClickURL(partnerId), {
    shouldShowToast: false,
  }).then(parseResponse);

const addUserListInDoubleClick = (parnerId, payload, t) =>
  Api.callPost(getDoubleClickURL(parnerId), payload, {
    toastText: t('audiences:connectors.doubleClick.userListAdd.error'),
    shouldShowToast: true,
  }).then(parseResponse);

export { getDoubleClickUsersList, addUserListInDoubleClick };
