import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { groupBy } from 'lodash';
import { SetIsPickerVisibleContext } from '~/components/src/RuleConnectorPicker/context';
import i18n from '~/i18n';
import Btn from '../Btn';
import BtnIcon from '../BtnIcon';
import Empty from '../Empty';
import Icons from '../Icons';
import SearchElement from '../Form/Elements/SearchElement';
import RulePickerCard from '../RulePickerCard';
import RulePickerItem from '../RulePickerItem';
import SelectableListItem from '../SelectableListItem';
import './style.scss';

const RuleCardGrid = props => {
  const {
    menuTree,
    isSearchable = false,
    searchPlaceholder = '',
    onSelect,
    emptyMessage = '',
    enableGroupBy = false,
    usedForFilters,
  } = props;
  const [selectedGroupName, setSelectedGroupName] = useState(null);

  const menu = Object.entries({ ...(usedForFilters ? { All: menuTree } : {}), ...groupBy(menuTree, 'groupName') });
  const setIsPickerVisible = useContext(SetIsPickerVisibleContext);

  const [audienceRules, setAudienceRules] = useState(menuTree);
  const [filter, setFilter] = useState('');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedRules, setSelectedRules] = useState([]);

  const handleClick = rule => {
    setFilter('');
    if (!rule) {
      setShowSubMenu(false);
      setAudienceRules(menuTree);
    } else if (rule.customSubMenu) {
      rule.customSubMenu.fetchData().then(data => {
        setShowSubMenu(true);
        setAudienceRules(data);
      });
    } else if (rule.subMenu) {
      setSelectedGroupName(null);
      setShowSubMenu(true);
      setAudienceRules(rule.subMenu);
    } else {
      const { ruleDefinitionId, type, title, availableFilters, extra, ruleDefinitionName, ruleName } = rule;

      const clickedRuleDescription = {
        ruleDefinitionId,
        type,
        title,
        availableFilters,
        ruleDefinitionName: ruleDefinitionName || title,
        ruleName: ruleName || title,
        extra: extra || null,
      };
      if (!usedForFilters) {
        onSelect(clickedRuleDescription);
        setIsPickerVisible(false);
      } else {
        const isAlreadySelected = selectedRules.some(
          selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId,
        );

        if (isAlreadySelected) {
          setSelectedRules(
            selectedRules.filter(selectedRule => selectedRule.ruleDefinitionId !== rule.ruleDefinitionId),
          );
        } else {
          setSelectedRules([...selectedRules, clickedRuleDescription]);
        }
      }
    }
  };

  const filteredRules = audienceRules.filter(
    rule =>
      rule.title.toLowerCase().includes(filter.trim().toLowerCase()) &&
      (!selectedGroupName || selectedGroupName === 'All' || rule.groupName === selectedGroupName),
  );

  const filteredSubitems = audienceRules
    .filter(({ subMenu }) => Array.isArray(subMenu) && subMenu.length > 0)
    .reduce((allSubitems, rule) => {
      const subitems = rule.subMenu.map(subitem => ({
        title: subitem.title,
        description: rule.title,
        icon: rule.icon,
        key: subitem.ruleDefinitionId,
        subitem,
      }));

      return [...allSubitems, ...subitems];
    }, [])
    .filter(({ title }) => title.toLowerCase().includes(filter.trim().toLowerCase()));

  const hasSearchResults = !!(filteredRules.length || filteredSubitems.length);
  const enableGroup = enableGroupBy && menu?.length > 1;

  const addEvents = () => {
    onSelect(selectedRules);
    setIsPickerVisible(false);
  };

  // eslint-disable-next-line no-unused-vars
  const getCategoryIcon = ([_category, rules]) => {
    const totalRules = rules.length;
    const selectedRulesCount = rules.filter(rule =>
      selectedRules.some(selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId),
    ).length;

    if (selectedRulesCount === totalRules) {
      return 'done';
    }
    if (selectedRulesCount > 0) {
      return 'minus';
    }
    return '';
  };

  // eslint-disable-next-line no-unused-vars
  const handleToggleCategorySelect = ([category, rules]) => {
    let updatedRules = [...selectedRules];

    // Check if it's the "All" item
    const isAllSelected = category === 'All';

    if (isAllSelected) {
      // Flatten the rules to include the parent and all children
      const allRules = rules.flatMap(rule => [rule, ...(rule.subMenu || [])]);

      const allRulesSelected = allRules.every(rule =>
        updatedRules.some(selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId),
      );

      if (allRulesSelected) {
        // Remove all rules and their submenus from selectedRules
        const ruleIds = allRules.map(rule => rule.ruleDefinitionId);
        updatedRules = updatedRules.filter(selectedRule => !ruleIds.includes(selectedRule.ruleDefinitionId));
      } else {
        // Add all rules and their submenus to selectedRules
        const missingRules = allRules.filter(
          rule => !updatedRules.some(selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId),
        );
        updatedRules.push(...missingRules);
      }
    } else {
      // Process other categories (individual categories with or without subMenus)
      rules.forEach(rule => {
        // Check if the rule has subMenu (children)
        const ruleAndSubMenu =
          rule.subMenu && rule.subMenu.length > 0
            ? rule.subMenu // Only push subMenu items if they exist
            : [rule]; // Otherwise, push the parent rule alone

        const totalRules = rules.length;
        const selectedRulesCount = rules.filter(rule =>
          selectedRules.some(selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId),
        ).length;

        if (totalRules === selectedRulesCount) {
          // Remove all selected rules (parent and children) from selectedRules
          const ruleIds = ruleAndSubMenu.map(rule => rule.ruleDefinitionId);
          updatedRules = updatedRules.filter(selectedRule => !ruleIds.includes(selectedRule.ruleDefinitionId));
        } else {
          // Add missing rules (parent and children) to selectedRules
          const missingRules = ruleAndSubMenu.filter(
            subRule => !updatedRules.some(selectedRule => selectedRule.ruleDefinitionId === subRule.ruleDefinitionId),
          );
          updatedRules.push(...missingRules);
        }
      });
    }

    // Update the selectedRules state at the end
    setSelectedRules(updatedRules);
  };

  const handleToggleClick = rule => isSelected => {
    let updatedRules = [...selectedRules];

    if (!rule?.subMenu) {
      const isAlreadySelected = updatedRules.some(
        selectedRule => selectedRule.ruleDefinitionId === rule.ruleDefinitionId,
      );

      if (isAlreadySelected) {
        updatedRules = updatedRules.filter(selectedRule => selectedRule.ruleDefinitionId !== rule.ruleDefinitionId);
      } else {
        updatedRules.push(rule);
      }
    } else if (isSelected) {
      const subMenuIds = rule.subMenu.map(sub => sub.ruleDefinitionId);
      updatedRules = updatedRules.filter(selectedRule => !subMenuIds.includes(selectedRule.ruleDefinitionId));
    } else {
      const newSubMenuItems = rule.subMenu.filter(
        sub => !updatedRules.some(selectedRule => selectedRule.ruleDefinitionId === sub.ruleDefinitionId),
      );
      updatedRules.push(...newSubMenuItems);
    }

    setSelectedRules(updatedRules);
  };

  const isSomeOfChildSelected = rule => {
    if (!rule?.subMenu) return false;

    return rule.subMenu.some(sub =>
      selectedRules.some(selected => selected?.ruleDefinitionId === sub.ruleDefinitionId),
    );
  };

  return (
    <>
      {isSearchable && (
        <SearchElement
          className="RuleCard-search"
          placeholder={searchPlaceholder}
          onChange={e => {
            setSelectedGroupName(null);
            setFilter(e.target.value);
          }}
          value={filter}
          autoFocus
        />
      )}
      <>
        {showSubMenu ? (
          <div className="h-full overflow-y-auto overflow-x-hidden">
            <BtnIcon
              testHook="RuleCard-goBackButton"
              icon="back"
              tooltip={i18n.t('audiences:backButton')}
              onClick={() => handleClick()}
            />
            <div className="RuleCard-list">
              {filteredRules.map(rule => (
                <RulePickerItem
                  key={rule.key || rule.ruleDefinitionId}
                  title={rule.title}
                  onClick={() => {
                    handleClick(rule);
                  }}
                  usedForFilters={usedForFilters}
                  isSelected={selectedRules.some(
                    selectedRule =>
                      selectedRule.ruleDefinitionId === rule.ruleDefinitionId && selectedRule.title === rule.title,
                  )}
                />
              ))}
            </div>
          </div>
        ) : (
          hasSearchResults && (
            <div className="RuleCard-initialViewSearchResults">
              <div className="flex">
                {enableGroup && menu.length > 0 && filter.length === 0 && (
                  <div className="absolute w-1/5">
                    {!!selectedGroupName && !usedForFilters && (
                      <div
                        onClick={() => {
                          setSelectedGroupName(null);
                        }}
                        className="mb-4 flex cursor-pointer items-center justify-between rounded border bg-white px-4 py-2 text-lg text-gray-700 hover:border-gray-800 hover:text-gray-600 hover:drop-shadow"
                      >
                        <span>{i18n.t('audiences:clearFilter')}</span>
                        <Icons icon="close" className="mr-1 h-4 w-4" />
                      </div>
                    )}
                    {menu.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleClick(); // setShowSubMenu(false);
                          setSelectedGroupName(item[0]);
                        }}
                        className={cx(
                          'mb-4 flex cursor-pointer items-center justify-between rounded border bg-white py-2 text-lg text-gray-700 hover:border-gray-800 hover:text-gray-600  hover:drop-shadow',
                          {
                            'border-gray-800': selectedGroupName === item[0],
                            'px-2': usedForFilters,
                            'px-4': !usedForFilters,
                          },
                        )}
                      >
                        {usedForFilters ? (
                          <div className="flex">
                            <div className="size-4 mr-2 flex items-center justify-center border">
                              <Icons
                                // eslint-disable-next-line no-nested-ternary
                                icon={getCategoryIcon(item)}
                                className="size-4"
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleToggleCategorySelect(item);
                                }}
                              />
                            </div>
                            <span className="text-sm">{item[0]}</span>
                          </div>
                        ) : (
                          <span>{item[0]}</span>
                        )}
                        <span className="ms-2 inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-200 text-xs font-semibold text-gray-700">
                          {item[1].length}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                <div className={cx({ 'ml-[23%] w-4/5': enableGroup && filter.length === 0 })}>
                  <div className="RuleCard-grid">
                    {filteredRules.map(rule => (
                      <RulePickerCard
                        icon={rule.icon}
                        title={rule.title}
                        subtitle={rule.subtitle}
                        isDisabled={rule.isDisabled}
                        key={rule.ruleDefinitionId || rule.key}
                        testHook={rule.type || rule.key}
                        onClick={() => {
                          handleClick(rule);
                        }}
                        usedForFilters={usedForFilters}
                        isSelected={
                          selectedRules.some(
                            selectedRule =>
                              selectedRule.ruleDefinitionId === rule.ruleDefinitionId &&
                              selectedRule.title === rule.title,
                          ) ||
                          rule?.subMenu?.every(sub =>
                            selectedRules.some(selectedRule => selectedRule.ruleDefinitionId === sub.ruleDefinitionId),
                          )
                        }
                        isSomeOfChildSelected={isSomeOfChildSelected(rule)}
                        handleToggleClick={handleToggleClick(rule)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {filter && (
                <div>
                  {filteredSubitems.map(({ title, description, icon, subitem, key }) => (
                    <SelectableListItem
                      title={title}
                      description={description}
                      icon={icon}
                      key={key}
                      onClick={() => {
                        handleClick(subitem);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </>
      {usedForFilters && hasSearchResults && (
        <div className="mx-1 my-2 flex flex-row-reverse items-center">
          <Btn onClick={() => addEvents()} color="blue" testHook="addEvents">
            {i18n.t('audiences:addEvents')}
          </Btn>
          <span className="t-eventsCount mx-3 text-sm text-gray-600">
            {i18n.t('audiences:eventsCount', { count: selectedRules?.length })}
          </span>
        </div>
      )}
      {!hasSearchResults && <Empty excludePanel body={emptyMessage} />}
    </>
  );
};

export default RuleCardGrid;
