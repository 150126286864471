import { connect } from 'react-redux';
import FacebookForm from './FacebookForm';
import { fetchPayloadTypes } from './facebookActions';
import { fetchingPayloadTypes, getPayloadTypes } from './facebookSelector';

const mapStateToProps = state => ({
  fetchingPayloadTypes: fetchingPayloadTypes(state),
  payloadTypes: getPayloadTypes(state),
});

const mapDispatchToProps = {
  fetchPayloadTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookForm);
