import React, { useState } from 'react';
import cx from 'classnames';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { useAPI } from '~/common/ApiHooks';
import { Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import AddTaxonomy from './AddTaxonomy';
import { fetchAllTaxonomies } from './dataService';
import SelectTaxonomy from './SelectTaxonomy';
import { TSetFieldValue, TTaxonomy } from './types';
import { findNode } from './utils';

const validations = Yup.object().shape({
  id: Yup.string().trim().required(i18n.t('validation:validation.required')),
  name: Yup.string().trim(),
});

type YahooProps = {
  onEdit: () => void;
  isSubmitted: boolean;
  connector: {
    partnerDetails: {
      partnerId: string;
      id: string;
    };
  };
};

const Yahoo = ({ onEdit, connector, isSubmitted }: YahooProps) => {
  const { partnerId, id: selectedTaxonomyId } = connector.partnerDetails;
  const { data: taxonomies, isLoading } = useAPI(() => fetchAllTaxonomies(partnerId));

  const [showCreateTaxonomyModal, setShowCreateTaxonomyModal] = useState(false);
  const [showTaxnomiesSelectionModal, setShowTaxnomiesSelectionModal] = useState(false);

  if (isLoading)
    return (
      <div className="mx-16 my-6">
        <Spin />
      </div>
    );

  const handleShowTaxonomySelect = () => {
    if (!taxonomies?.length) return;
    setShowTaxnomiesSelectionModal(true);
  };

  const handleTaxonomySelection = async (taxonomy: TTaxonomy, setFieldValue: TSetFieldValue) => {
    await setFieldValue('id', taxonomy.id);
    await setFieldValue('name', taxonomy.name);
    setShowTaxnomiesSelectionModal(false);
  };

  const selectedTaxonomy = (taxonomies && findNode(taxonomies, selectedTaxonomyId)) || undefined;

  return (
    <ConnectorGenericForm onEdit={onEdit} validations={validations} connector={connector} isSubmitted={isSubmitted}>
      {(formikProps: FormikProps<{ id: string; name: string }>) => {
        const { setFieldValue, values, errors, touched } = formikProps;
        return (
          <>
            <p className="py-2">{i18n.t('audiences:connectors.yahoo.taxonomy')}</p>
            <div className="flex w-2/3 items-center gap-2">
              <div
                className={cx('t-selectTaxonomy w-2/3 cursor-pointer rounded border border-gray-300 px-2 py-3', {
                  'text-gray-400 ': !selectedTaxonomy?.name && !values.name,
                  '!cursor-not-allowed': !taxonomies?.length,
                  'border-red-700 bg-red-50': touched.id && errors.id,
                })}
                onClick={() => handleShowTaxonomySelect()}
              >
                {selectedTaxonomy?.name || // If taxonomy is present in tree
                  values.name || // Newly created taxonomy not present in tree
                  selectedTaxonomyId || // When backend doesnt return taxonomy name display Id
                  i18n.t('audiences:connectors.yahoo.placeholder')}
              </div>
              <BtnIcon
                testHook="createTaxonomy"
                icon="add"
                className="rounded-full"
                disabled={!taxonomies?.length}
                tooltip={i18n.t('audiences:connectors.yahoo.tooltips.create')}
                onClick={() => setShowCreateTaxonomyModal(true)}
              />
            </div>
            {touched.id && errors.id && (
              <span className="text-sm text-red-700">{i18n.t('validation:validation.required')}</span>
            )}

            {!taxonomies?.length && (
              <Notification className="mt-4" kind="error">
                {i18n.t('audiences:connectors.yahoo.notifications.noTaxonomies')}
              </Notification>
            )}

            {!selectedTaxonomy?.name && !values.name && selectedTaxonomyId && (
              <Notification className="mt-4" kind="warning">
                {i18n.t('audiences:connectors.yahoo.notifications.taxonomyCreationInProgress')}
              </Notification>
            )}

            {showTaxnomiesSelectionModal && (
              <PickerView
                pickerTitle={i18n.t('audiences:connectors.yahoo.selectTaxonomy.title')}
                className="!h-auto !w-1/2 lg:!w-1/3 xl:!w-1/4"
              >
                <SelectTaxonomy
                  selectedTaxonomy={selectedTaxonomy}
                  taxonomies={taxonomies || []}
                  partnerId={partnerId}
                  hideModal={() => setShowTaxnomiesSelectionModal(false)}
                  setNode={taxonomy => handleTaxonomySelection(taxonomy, setFieldValue)}
                />
              </PickerView>
            )}
            {showCreateTaxonomyModal && (
              <PickerView
                pickerTitle={i18n.t('audiences:connectors.yahoo.createTaxonomy.title2')}
                className="!h-auto !w-1/2 lg:!w-1/3 xl:!w-1/4"
              >
                <AddTaxonomy
                  partnerId={partnerId}
                  taxonomies={taxonomies || []}
                  hideModal={() => setShowCreateTaxonomyModal(false)}
                  setNewTaxonomy={setFieldValue}
                />
              </PickerView>
            )}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default Yahoo;
