import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Cell, RowActions } from '~/components/src/Table/components';
import { change, getFormValues, getFormSyncErrors, Field } from 'redux-form';
import { size } from 'lodash';
import { components } from '~/components/src/Table';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, defaultMaxInput, isValueUniqueCaseInsensitive } from '~/common';
import enhanced from './PropertiesFormCompose';
import PropertyRow from './PropertyRow';

const { Table, Row, EditableRow, HeaderCell, ListButtonItem, EditableRowErrors } = components;

const onDelete = (propertyName, props) => {
  const filteredProperties = props.input.value.filter(property => propertyName !== property.name);
  const filteredUpdatedProperties = props.formValues.updatedProperties.filter(property => propertyName !== property);
  props.input.onChange(filteredProperties);
  props.changeFormValue('updatedProperties', filteredUpdatedProperties);
};

const propertyNameIsUnique = (value, allValues, props) =>
  isValueUniqueCaseInsensitive(value.trim(), props.values.updatedProperties);

const createListButtonItem = (
  numberOfProperties,
  maxNumberOfProperties,
  shouldShowAddNewItemRow,
  setUserIsEditing,
  shouldGoInEditMode,
  t,
  changeFormValue,
) => {
  if (numberOfProperties < maxNumberOfProperties) {
    return (
      <ListButtonItem
        onClick={event => {
          if (shouldGoInEditMode) {
            changeFormValue('newProperty', '');
            shouldShowAddNewItemRow(true);
            setUserIsEditing(true);
          }
          event.preventDefault();
        }}
      >
        {t('edit.addNewButton')}
      </ListButtonItem>
    );
  }
  return <EditableRowErrors errors={[t('form.maxNumberOfProperties', { maxNumberOfProperties })]} />;
};

const onAddProperty = (formValues, validationErrors, changeFormValue, setUserIsEditing, shouldShowAddNewItemRow) => {
  if (!validationErrors.newProperty) {
    changeFormValue('propertiesWithUsage', [
      ...formValues.propertiesWithUsage,
      { name: formValues.newProperty.trim(), dependantTypes: [] },
    ]);
    changeFormValue('updatedProperties', [...formValues.updatedProperties, formValues.newProperty.trim()]);
    changeFormValue('newProperty', '');
    shouldShowAddNewItemRow(false);
    setUserIsEditing(false);
  }
};

const onCancelProperty = (changeFormValue, setUserIsEditing, shouldShowAddNewItemRow) => {
  shouldShowAddNewItemRow(false);
  changeFormValue('newProperty', '');
  setUserIsEditing(false);
};

export const PropertiesForm = props => {
  const {
    list,
    stateKey,
    formValues,
    formName,
    validationErrors,
    showAddNewItemRow,
    shouldShowAddNewItemRow,
    selectedPropertyId,
    changeFormValue,
    elementId,
    usedIn,
    setUserIsEditing,
    userIsEditing,
    maxNumberOfProperties,
    t,
  } = props;

  const listButtonItem = createListButtonItem(
    size(formValues.updatedProperties),
    maxNumberOfProperties,
    shouldShowAddNewItemRow,
    setUserIsEditing,
    !userIsEditing,
    t,
    changeFormValue,
  );
  return (
    <Table stateKey={stateKey} testHook="propertiesFormTable" className="PropertiesFormTable">
      <Row>
        <HeaderCell className="u-size1of12">{t('property.number')}</HeaderCell>
        <HeaderCell className="u-size10of12">{t('property.name')}</HeaderCell>
        <HeaderCell className="u-size1of12"> </HeaderCell>
      </Row>
      {list.map((property, index) => (
        <Field
          key={property.name}
          name={`propertiesWithUsage.${index}`}
          type="text"
          size="block"
          component={PropertyRow}
          className="mt-4 flex-1"
          index={index}
          initialValue={formValues.propertiesWithUsage[index].name}
          onDelete={propertyName => {
            onDelete(propertyName, props);
          }}
          property={property}
          selectedPropertyId={selectedPropertyId}
          usedIn={usedIn}
          elementId={elementId}
          setUserIsEditing={setUserIsEditing}
          userIsEditing={userIsEditing}
          formName={formName}
          t={t}
        />
      ))}
      {size(list) < maxNumberOfProperties && (
        <EditableRow isVisible={showAddNewItemRow} className="flex items-center">
          <Cell className="w-4">{size(list) + 1}</Cell>
          <Cell className="flex-1">
            <Field
              name="newProperty"
              component={ReduxFormInputField}
              autoFocus={true}
              validate={[isRequired, defaultMaxInput, propertyNameIsUnique]}
              className="mt-4 flex-1"
              testHook="variableNameInput"
              onBlur={() => {
                onAddProperty(formValues, validationErrors, changeFormValue, setUserIsEditing, shouldShowAddNewItemRow);
              }}
            />
          </Cell>
          <RowActions
            actions={[
              {
                name: t('common:actions.remove'),
                icon: 'delete',
                onClick: () => {
                  onCancelProperty(changeFormValue, setUserIsEditing, shouldShowAddNewItemRow);
                },
                testHook: 'delete',
                type: 'delete',
              },
            ]}
          />
        </EditableRow>
      )}
      {listButtonItem}
    </Table>
  );
};

PropertiesForm.propTypes = {
  list: PropTypes.array.isRequired,
  elementId: PropTypes.string,
  usedIn: PropTypes.array,
  selectedPropertyId: PropTypes.string,
  maxNumberOfProperties: PropTypes.number,
  shouldShowAddNewItemRow: PropTypes.func,
  showAddNewItemRow: PropTypes.bool,
  userIsEditing: PropTypes.bool,
  setUserIsEditing: PropTypes.func,
};

/* State Props */
const mapStateToProps = (state, ownProps) => ({
  formValues: getFormValues(ownProps.formName)(state),
  validationErrors: getFormSyncErrors(ownProps.formName)(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFormValue: (fieldName, fieldValue) => dispatch(change(ownProps.formName, fieldName, fieldValue)),
});

export default enhanced(compose(connect(mapStateToProps, mapDispatchToProps))(PropertiesForm));
