import React, { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { Notification } from '~/components';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';

type EventPropertiesProps = {
  data?: string[];
  isLoading: boolean;
  error?: Error | null;
};

function EventProperties({ data, isLoading, error }: EventPropertiesProps) {
  const [_, copy] = useCopyToClipboard();
  const [showProperties, setShowProperties] = useState(false);
  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Notification kind="error">{error}</Notification>;
  }

  return (
    <div className="divide-y divide-solid pb-2">
      <div
        className="flex cursor-pointer items-center justify-between bg-gray-100 px-4 py-2"
        onClick={() => setShowProperties(!showProperties)}
      >
        <p className="text-md font-medium text-gray-600">{i18n.t('partners:form.formFields.properties')}</p>
        <div className="flex items-center gap-2">
          <span className="rounded-full bg-white p-2 text-sm">{String(data?.length)}</span>
          <Icons icon={showProperties ? 'upArrow' : 'downArrow'} className="h-4 w-4" />
        </div>
      </div>
      {showProperties && (
        <>
          {data?.map((property: string, i: number) => (
            <div
              key={`property-${i}`}
              className="group flex cursor-pointer items-center justify-between gap-2 rounded-md  p-4 text-left hover:bg-gray-50"
              onClick={() => copy(`\${${property}}`)}
            >
              <p>{property}</p>
              <Icons
                icon="copy"
                className="invisible h-4 w-4 text-gray-400 hover:text-gray-600 group-hover:visible"
                tooltip="copy"
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
export default EventProperties;
