angular.module('webUi.ui.context.sites.list', ['webUi.ui.context.sites.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.sites.list', {
                url: '/list',
                controller: 'ContextSitesListController',
                isReact: true
            });
    }])

    .controller('ContextSitesListController', ['$scope', '$location', 'allowEdit',
        function ContextSitesListController($scope, $location, allowEdit) {

            if(allowEdit) {
                $scope.$on('hotKeyEvent', function(event, data) {
                    // activate newSite on n
                    if(!data.altKey && data.charLower === 'n') {
                        $location.path($scope.prefix + '/context/sites/add');
                    }
                });
            }
        }
    ]);
