import React from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import './TwoColumnWrapper.scss';

const TwoColumnWrapper = ({ label, onDeleteClick, children, testHook, className, t }) => (
  <div
    className={cx('mt-4 flex items-start gap-4', className, {
      'TwoColumnWrapper--nonDeletable': !onDeleteClick,
      [`t-${testHook}`]: testHook,
    })}
  >
    <div className="flex-1">{label}</div>

    <div className="flex-1">{children}</div>

    {onDeleteClick && (
      <BtnIcon
        icon="close"
        tooltip={t('audiences:connectors.removeItem')}
        className="mt-3.5 h-4 w-4 p-0.5"
        onClick={onDeleteClick}
        testHook="deleteConnector"
      />
    )}
  </div>
);

export default TwoColumnWrapper;
