import React from 'react';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { PasswordPolicyForm } from '~/context/clients/components';
import { buildUrl } from '~/common';
import { updatePasswordPolicy, fetchClientIfNeeded, getClientPasswordPolicy } from '~/context/clients';
import { Panel } from '~/components/src/Containers';

class PasswordPolicyContainer extends React.Component {
  componentDidMount() {
    this.props.fetchClientIfNeeded();
  }

  render() {
    const { initialValues, t } = this.props;
    return (
      <>
        <Heading title={t('clients:view.clientSettings.labels.passwordPolicy')} />
        <Panel>{!initialValues ? <Spinner /> : <PasswordPolicyForm {...this.props} />}</Panel>
      </>
    );
  }
}

PasswordPolicyContainer.propTypes = {
  clientId: PropTypes.string.isRequired,
  isContextAdmin: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    (state, ownProps) => ({
      backHref: buildUrl(`context/clients/view/${ownProps.clientId}`),
      initialValues: getClientPasswordPolicy(state, ownProps.clientId),
    }),
    (dispatch, ownProps) => ({
      onSubmit: value => dispatch(updatePasswordPolicy(ownProps.clientId, value)),
      fetchClientIfNeeded: () => dispatch(fetchClientIfNeeded(ownProps.clientId)),
    }),
  ),
  translate('clients'),
)(PasswordPolicyContainer);
