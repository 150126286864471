import React, { useState } from 'react';
import i18n from '~/i18n';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import DateTimeElement from '~/components/src/Form/Elements/DateTimeElement';
import moment, { Moment } from 'moment';
import { FIELD_DATE_FORMAT } from '../constants';
import { TElement } from '../types';

const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm:ss';

export const TimeframeWithinInput = ({ onBlur, onChange, errorText, filter, validateForm }: TElement) => {
  const [editTime, setEditTime] = useState(false);

  const rangeFrom = moment(filter?.rangeFrom, FIELD_DATE_FORMAT, true).isValid()
    ? moment(filter?.rangeFrom)
    : filter?.rangeFrom;
  const rangeTo = moment(filter?.rangeTo, FIELD_DATE_FORMAT, true).isValid()
    ? moment(filter?.rangeTo)
    : filter?.rangeTo;

  const handleChange = (key: string, value: string) => {
    onChange(value, key);
    setTimeout(() => {
      validateForm();
    }, 200);
  };

  const handleRangeChange = (key: string, value: Moment | string, endOf = false) => {
    if (typeof value === 'string') handleChange(key, value);
    else {
      let rangeValue = value.format(FIELD_DATE_FORMAT);
      if (!editTime) {
        rangeValue = endOf
          ? value.endOf('day').format(FIELD_DATE_FORMAT)
          : value.startOf('day').format(FIELD_DATE_FORMAT);
      }

      handleChange(key, rangeValue);
    }
  };

  return (
    <div>
      <div className="!flex gap-1">
        <FieldWrapper errorText={errorText}>
          <div className="flex gap-1">
            <DateTimeElement
              className="w-full"
              name="rangeFrom"
              dateFormat={DATE_FORMAT}
              timeFormat={editTime && TIME_FORMAT}
              value={rangeFrom}
              onChange={e => handleRangeChange('rangeFrom', e)}
              hasError={!!errorText}
              onBlur={onBlur}
              placeholder={i18n.t('common:placeholders.from')}
            />
            <DateTimeElement
              className="w-full"
              dateFormat={DATE_FORMAT}
              timeFormat={editTime && TIME_FORMAT}
              name="rangeTo"
              value={rangeTo}
              onChange={e => handleRangeChange('rangeTo', e, true)}
              hasError={!!errorText}
              onBlur={onBlur}
              placeholder={i18n.t('common:placeholders.to')}
            />
          </div>
        </FieldWrapper>
      </div>
      <div className="flex items-center gap-1 py-1">
        <input
          type="checkbox"
          id="editTime"
          name="editTime"
          checked={editTime}
          onChange={() => setEditTime(!editTime)}
        />
        <label>{i18n.t('common:placeholders.editTime')}</label>
      </div>
    </div>
  );
};

export default TimeframeWithinInput;
