import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    turnSegmentKey: Yup.string().required(t('cards.validation.segmentKey')),
    turnClientId: Yup.string().required(t('cards.validation.clientId')),
  });

export const Turn = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="turnClientId"
          label={t('cards.label.turnClientId')}
          placeholder={t('common.commonField.placeholder')}
          errorText={formProps.touched.turnClientId && formProps.errors.turnClientId}
        />
        <Field
          as={FormikInputField}
          name="turnSegmentKey"
          label={t('cards.label.turnSegmentKey')}
          placeholder={t('common.commonField.placeholder')}
          errorText={formProps.touched.turnSegmentKey && formProps.errors.turnSegmentKey}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
