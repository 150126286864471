import PropTypes from 'prop-types';
import React from 'react';

import { select } from '../../helper/services';

import '../styles.scss';
import './style.scss';

const CLASS_MAPPING = {
  [select.SELECT_STATES.selected]: 'Table-row--selected',
  [select.SELECT_STATES.notSelected]: 'Table-row--selectable',
  [select.SELECT_STATES.preSelected]: 'Table-row--preselected',
  [select.SELECT_STATES.unselectable]: 'Table-row--unselectable',
};

const RowSelectable = ({ selectState, onSelect, children }) => {
  const rowClass = ['Table-row', CLASS_MAPPING[selectState]];

  const onClick =
    selectState === select.SELECT_STATES.selected || selectState === select.SELECT_STATES.notSelected
      ? onSelect
      : () => {};

  return (
    <div onClick={onClick} className={rowClass.join(' ')}>
      {children}
    </div>
  );
};

const RowNormal = ({ style, withActions, isTable, className = '', children }) => {
  const Element = isTable ? 'tr' : 'div';
  return (
    <Element className={`Table-row ${className} ${withActions ? 'Table-row--actions' : ''}`} style={style}>
      {children}
    </Element>
  );
};

const Row = ({ isSelectable, ...props }) => (isSelectable ? <RowSelectable {...props} /> : <RowNormal {...props} />);

Row.propTypes = {
  isSelectable: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RowSelectable.propTypes = {
  selectState: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

RowNormal.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Row;
