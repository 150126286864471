import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components } from '~/components/src/Table';
import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors, Field, change } from 'redux-form';
import cx from 'classnames';
import { isRequired, defaultMaxInput } from '~/common';
import i18n from '~/common/formValidation/i18n';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';

const { Row, RowActions, Cell } = components;

const propertyNameIsUnique = (value, allValues) => {
  const numberOfAppearances = allValues.updatedProperties.reduce(
    (numberOfCopies, property) =>
      property?.trim().toLowerCase() === value?.trim().toLowerCase() ? numberOfCopies + 1 : numberOfCopies,
    0,
  );
  return numberOfAppearances <= 1 ? undefined : i18n.t('validation:validation.isUnique');
};

const onAddProperty = that => {
  const { index, formValues, validationErrors, changeFormValue, setUserIsEditing } = that.props;
  if (!validationErrors.updatedProperties || !validationErrors.updatedProperties[index]) {
    that.setState({ showEditableRow: false });
    setUserIsEditing(false);
    changeFormValue(`updatedProperties[${index}]`, formValues.updatedProperties[index].trim());
    changeFormValue(`propertiesWithUsage[${index}]`, {
      ...formValues.propertiesWithUsage[index],
      name: formValues.updatedProperties[index].trim(),
    });
  }
};

const onCancelProperty = that => {
  const { index, initialValue, changeFormValue, setUserIsEditing } = that.props;
  that.setState({ showEditableRow: false });
  setUserIsEditing(false);
  changeFormValue(`updatedProperties[${index}]`, initialValue);
};

export class PropertyRow extends Component {
  constructor(props) {
    super(props);
    this.state = { showEditableRow: false };
  }

  render() {
    const { index, input, selectedPropertyId, onDelete, t } = this.props;
    const property = input.value;
    const isSelected = selectedPropertyId && selectedPropertyId === property.name;
    const actions = [
      {
        name: t('common:actions.remove'),
        tooltip:
          property.dependantTypes.length > 0 ? t('edit.removeActionDisabledTooltip') : t('common:actions.remove'),
        isDisabled: property.dependantTypes.length > 0,
        icon: 'delete',
        onClick: () => {
          onDelete(property.name);
        },
        testHook: 'delete',
        type: 'delete',
      },
    ];
    const editableRow = (
      <Row
        key={property.name}
        className={cx({ isSelected }, `t-${property.name}-Row`, 't-propertiesListTableRow', 'flex items-center gap-4')}
        withActions
      >
        <Cell className="mt-2 w-4">{index + 1}</Cell>
        <Cell className="flex-1">
          <Field
            name={`updatedProperties[${index}]`}
            component={ReduxFormInputField}
            className="mt-4 flex-1"
            testHook="propertyNameInput"
            validate={[isRequired, defaultMaxInput, propertyNameIsUnique]}
            onBlur={() => onAddProperty(this)}
            disabled={property.dependantTypes.length > 0}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onAddProperty(this);
              } else if (event.key === 'Escape') {
                onCancelProperty(this);
              }
            }}
          />
        </Cell>
        <RowActions actions={actions} className="mt-2" />
      </Row>
    );

    return editableRow;
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => ({
  formValues: getFormValues(ownProps.formName)(state),
  validationErrors: getFormSyncErrors(ownProps.formName)(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFormValue: (fieldName, fieldValue) => dispatch(change(ownProps.formName, fieldName, fieldValue)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PropertyRow);

PropertyRow.propTypes = {
  t: PropTypes.func,
  index: PropTypes.number,
  initialValue: PropTypes.string,
  elementId: PropTypes.string,
  usedIn: PropTypes.array,
  onDelete: PropTypes.func,
  setUserIsEditing: PropTypes.func,
  userIsEditing: PropTypes.bool,
};
