import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class TradeDeskForm extends Component {
  componentDidMount() {
    const { fetchEndpointLocations, fetchPayloadTypes } = this.props;
    fetchEndpointLocations(constants.TRADEDESK);
    fetchPayloadTypes(constants.TRADEDESK);
  }

  render() {
    const { t, values, partner, canUpdate, endpointLocations, touch, payloadTypes, change } = this.props;
    const partnerNumber = values.partnerNumber || partner.partnerNumber;
    return (
      <FormSection name={constants.partnerTypes.TRADEDESK}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.cookieSyncMessage')}
          partnerNumber={partnerNumber}
        />
        <ContainerFormSection>
          <Field
            label={t('form.formFields.advertiserId')}
            name="advertiserId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.secretKey')}
            name="secretKey"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.endpointLocation')}
            name="location"
            component={ReduxFormSelectField}
            options={endpointLocations}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            validate={isRequired}
            touch={touch}
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            disabled={canUpdate}
            validate={isRequired}
            onChange={(event, value) => {
              const payloadType = payloadTypes.find(type => type.value === value);
              change('TRADEDESK.partnerNumber', payloadType?.partnerNumber);
            }}
            touch={touch}
          />
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default TradeDeskForm;
