import { combineReducers } from 'redux';
import * as types from './types';

/**
 * All sites of a currently viewing client
 * @param state
 * @param action
 * @returns []
 */
const list = (state = [], action) => {
  switch (action.type) {
    case types.CONTEXT_FETCH_CLIENT_SITES_SUCCESS:
      return action.payload;
    case types.CONTEXT_FETCH_CLIENT_SITES_START:
    case types.CONTEXT_FETCH_CLIENT_SITES_FAIL:
      return [];
    default:
      return state;
  }
};

const defaultUI = {
  isFetching: false,
  shouldFetchAll: true,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.CONTEXT_FETCH_CLIENT_SITES_START:
      return {
        ...state,
        isFetching: true,
        shouldFetchAll: false,
      };
    case types.CONTEXT_FETCH_CLIENT_SITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shouldFetchAll: true,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  ui,
});
