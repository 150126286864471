import React, { useContext } from 'react';
import cx from 'classnames';
import { formatNumber } from '~/common/utils/NumberUtil';
import { NodeTypes } from '~/workflows/constants';
import { ADD_BUTTON_LINE_LENGTH, CARD_HEIGHT, CARD_WIDTH, HALF_CARD_HEIGHT, NODE_PADDING_RIGHT } from '../constants';
import { JourneyContext } from '../JourneyContext';
import { ButtonsPanel, DeleteIcon, EditIcon, IconButton, ViewIcon } from './ButtonsPanel';
import { getIconPositions, TriggerIcon } from './Icons';
import { HoverRegionUnderCard, Node, TransitionButtonLine } from './Node';
import ProfilesCount from './ProfilesCount';
import { RoundExperimentButton, RoundStepButton, RoundStepOrTransitionButton } from './RoundButton';
import {
  AddStepButtonOnALineProps,
  TransitionButtonProps,
  TriggerNodePlaceholderProps,
  TriggerNodeProps,
} from './types';
import { CanvasContext } from '../JourneyCanvas/CanvasContext';

const AddStepButtonOnALine = ({ x, y, className, hasError, children }: AddStepButtonOnALineProps) => (
  <g transform={`translate(${x}, ${y})`} className={className}>
    <line
      className={cx('stroke-gray-500', { 'stroke-red-500': hasError })}
      x1={0}
      y1={16}
      x2={ADD_BUTTON_LINE_LENGTH}
      y2={16}
      strokeDasharray="4 3"
      strokeWidth={2}
    />
    <g transform={`translate(${ADD_BUTTON_LINE_LENGTH}, 0)`} className="JourneyView-rectangularButton">
      {children}
    </g>
  </g>
);

const TransitionButton = ({ x, y, className, hasError, onClick, onDeleteTrigger }: TransitionButtonProps) => (
  <TransitionButtonLine
    x={x}
    y={y}
    className={className}
    hasError={hasError}
    text="Transition"
    onClick={onClick}
    onDeleteTrigger={onDeleteTrigger}
  />
);

export const TriggerNode = ({ x, y, data, parent, canEdit, hasLinkError }: TriggerNodeProps) => {
  const { actions } = useContext(JourneyContext);
  const { hasExperiments, transitionSource, setTransitionSource, setNodeToDelete, setTransistionToDelete } =
    useContext(CanvasContext);
  const { goToViewTriggerScreen, goToCreateStepScreen, goToEditTriggerScreen } = actions;

  const isStartTrigger = data.type === NodeTypes.START_TRIGGER;
  const hasNextStep = data.children.length > 0;

  const showExperimentButton = hasExperiments ? data.children.length > 1 : true;

  const buttons = [
    {
      icon: ViewIcon,
      testHook: 'view-trigger',
      onClick: () => goToViewTriggerScreen(data.nodeId),
    },
  ];
  if (canEdit) {
    buttons.push({
      icon: EditIcon,
      testHook: 'edit-trigger',
      onClick: () => goToEditTriggerScreen(data.nodeId),
    });
  }

  if (!isStartTrigger && canEdit) {
    buttons.push({
      icon: DeleteIcon,
      testHook: 'delete-trigger',
      onClick: () => setNodeToDelete(data),
    });
  }

  const iconPositions = getIconPositions(buttons.length);
  const reachedMaxVariants = data.children.length === 3;

  return (
    <>
      <Node x={x} y={y} Icon={TriggerIcon} data={data} parent={parent} className="JourneyView-triggerNode">
        {!isStartTrigger && (
          <ProfilesCount
            profilesCount={formatNumber(data.profilesCount)}
            experimentWeight={0}
            isVariant={false}
            isWeightValid={true}
            isTrigger={true}
          />
        )}
        <HoverRegionUnderCard />

        <ButtonsPanel x={0} y={CARD_HEIGHT + 16} panelWidth={0}>
          {buttons.map((buttonConfig, buttonIndex) => (
            <IconButton
              icon={buttonConfig.icon}
              onClick={buttonConfig.onClick}
              x={iconPositions[buttonIndex]}
              key={buttonIndex}
              testHook={buttonConfig.testHook}
            />
          ))}
        </ButtonsPanel>
      </Node>
      {canEdit && hasNextStep && showExperimentButton && !reachedMaxVariants && (
        <RoundExperimentButton
          x={x + CARD_WIDTH + NODE_PADDING_RIGHT / 2}
          y={y + HALF_CARD_HEIGHT}
          className="t-create-experiment-step-button"
          tooltipText="Add experiment"
          tooltipWidth={200}
          tooltipHeight={36}
          onClick={() => {
            goToCreateStepScreen(data.nodeId);
          }}
        />
      )}
      {canEdit && !hasNextStep && !data.hasTransition && (
        <AddStepButtonOnALine x={x + CARD_WIDTH} y={y + HALF_CARD_HEIGHT - 16} hasError={hasLinkError}>
          {!isStartTrigger ? (
            <RoundStepOrTransitionButton
              className="t-step-transition-button"
              x={16}
              y={16}
              hasError={hasLinkError}
              triggerId={data.nodeId}
            />
          ) : (
            <RoundStepButton
              x={16}
              y={16}
              hasError={hasLinkError}
              onClick={() => {
                goToCreateStepScreen(data.nodeId);
              }}
            />
          )}
        </AddStepButtonOnALine>
      )}

      {data.hasTransition && (
        <TransitionButton
          className={`t-transition-button-${data.nodeId}`}
          hasError={hasLinkError}
          x={x + CARD_WIDTH}
          y={y + HALF_CARD_HEIGHT - 16}
          onClick={() => {
            const dataNodeId = data.nodeId;
            let nodeId = transitionSource;
            if (nodeId === dataNodeId) {
              nodeId = '';
            } else {
              nodeId = dataNodeId;
            }
            return setTransitionSource(nodeId);
          }}
          onDeleteTrigger={() => setTransistionToDelete(data)}
        />
      )}
    </>
  );
};

export const TriggerNodePlaceholder = ({ x, y, data, parent, hasNodeError }: TriggerNodePlaceholderProps) => {
  const { actions } = useContext(JourneyContext);
  return (
    <Node
      hasError={hasNodeError}
      Icon={TriggerIcon}
      x={x}
      y={y}
      data={data}
      parent={parent}
      title="Click to add a trigger"
      isStartTrigger
      className="JourneyView-triggerNodePlaceholder"
      onClick={() => {
        actions.goToCreateTriggerScreen();
      }}
    />
  );
};
