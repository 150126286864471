angular.module('webUi.ui.audiences.base', [
    'webUi.ui.siteState',
    'webUi.service.profilesConstants'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.audiences', {
                url: '/audiences',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/audiences/base/base.tpl.html',
                        controller: 'AudiencesBaseController as vm'
                    }
                },
                resolve: {
                    hasProfilesView: ['securityContext', function (securityContext) {
                        return securityContext.hasPermission('PROFILES_VIEW');
                    }],
                    hasProfilesEdit: ['securityContext', function (securityContext) {
                        return securityContext.hasPermission('PROFILES_EDIT');
                    }],
                    hasDataManagementModule: ['securityContext', function (securityContext) {
                        return securityContext.hasModule('DATA_MANAGEMENT');
                    }],
                    hasAdservingView: ['securityContext', function (securityContext) {
                        return securityContext.hasPermission('ADSERVING_VIEW');
                    }],
                    hasAdservingEdit: ['securityContext', function (securityContext) {
                        return securityContext.hasPermission('ADSERVING_EDIT');
                    }],
                    hasDataAccess: ['securityContext', function (securityContext) {
                        return securityContext.hasPermission('DATA_ACCESS');
                    }],
                    isSupportEngineer: ['securityContext', function (securityContext) {
                        return securityContext.isSupportEngineer();
                    }]
                }
            });
    }])
    .controller('AudiencesBaseController', ['$scope', 'hasProfilesView', 'hasProfilesEdit', 'hasDataManagementModule','hasAdservingView','hasAdservingEdit',
        function AudiencesBaseController($scope, hasProfilesView, hasProfilesEdit, hasDataManagementModule, hasAdservingView, hasAdservingEdit) {
            $scope.hasProfilesView = hasProfilesView;
            $scope.hasProfilesEdit = hasProfilesEdit;
            $scope.hasDataManagementModule = hasDataManagementModule;
            $scope.hasAdservingView = hasAdservingView;
            $scope.hasAdservingEdit = hasAdservingEdit;
        }
    ]);
