import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { useAPI } from '~/common/ApiHooks';
import { Field } from 'formik';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import CardMessage from '~/components/src/CardMessage';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import getAppNexusSegmentsList from './service';

const validations = t =>
  Yup.object().shape({
    segment: Yup.object().shape({
      name: Yup.string().required(t('connectors:cards.validation.appNexusSegment')),
      segmentId: Yup.string(),
    }),
  });

const AppNexus = ({ onEdit, connector, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const [listItems, setListItems] = useState([]);
  const { data, error, isLoading: isListLoading } = useAPI(() => getAppNexusSegmentsList(partnerId), [partnerId]);

  useEffect(() => {
    setListItems(data || []);
  }, [data]);

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <Field
              testHook="formikDropdown--appNexusSegmentegmentList"
              name="segment"
              as={FormikSelectField}
              label={t('connectors.appNexus.fieldLabel')}
              errorText={formProps?.touched?.segment && formProps?.errors?.segment?.name}
              options={listItems}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.segmentId}
              value={formProps.values.segment}
              isLoading={isListLoading}
              shouldSetObject={true}
            />
          </SmallWrapperNoOffset>
          {error && <CardMessage message={t('connectors.appNexus.segmentList.genericError')} />}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default translate('audiences')(AppNexus);
