angular.module('webUi.directive.editable.textarea', ['ui.validate'])

    .controller('TextareaEditingController', ['$scope', 'ValidService', function TextareaEditingController ($scope, ValidService){

        $scope.localVars = $scope.vars() || {};

        $scope.$watch('customValidation', function(newValue) {
            if(!ValidService.isEmpty(newValue)) {
                ValidService.setValidationTokens(newValue, $scope.editableForm.value, $scope.editableObject.value);
            }
        });

        /**
     * Process the input before saving, also returning the validity of the form
     * @returns {boolean} whether the save is allowed to proceed or not
     */
        $scope.state.beforeSave = function () {
            return $scope.editableForm.$valid;
        };

    }]);
