import { toInteger, isNumber, isFinite } from 'lodash';

interface FormatNumberParameters {
  prefix?: string;
  suffix?: string;
}

export const formatNumber = (number: unknown, { prefix = '', suffix = '' }: FormatNumberParameters = {}): string => {
  if (number === 0) {
    return '0';
  }

  if (isNumber(number) && isFinite(number)) {
    return `${prefix}${number.toLocaleString()}${suffix}`;
  }

  return '';
};

export const formatPercentage = (conversionRate: number) =>
  typeof conversionRate === 'number' &&
  conversionRate.toLocaleString('en', { style: 'percent', maximumFractionDigits: 1 });

export const normalizeToInteger = (value: unknown): number | null => {
  if (!value && value !== 0) {
    return null;
  }

  return toInteger(value);
};

export const intToString = (number: number): string | number => {
  if (number < 1000) {
    return number;
  }

  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];

  let i;
  // eslint-disable-next-line no-plusplus
  for (i = si.length - 1; i > 0; i--) {
    if (number >= si[i].v) {
      break;
    }
  }
  return (number / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].s;
};
