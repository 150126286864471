import ClientSitesService from './dataService';
import * as selectors from './selectors';
import * as types from './types';

/**
 *
 * @param clientId
 * @returns {function(*)}
 */
export const fetchSitesByClient = clientId => dispatch => {
  dispatch({ type: types.CONTEXT_FETCH_CLIENT_SITES_START });
  return ClientSitesService.getSitesByClient(clientId)
    .then(response => {
      dispatch({
        type: types.CONTEXT_FETCH_CLIENT_SITES_SUCCESS,
        payload: [...response],
      });
    })
    .catch(error => dispatch({ type: types.CONTEXT_FETCH_CLIENT_SITES_FAIL, error }));
};

export const fetchSitesByClientIfNeeded = clientId => (dispatch, getState) => {
  if (selectors.shouldFetchClientSites(getState())) {
    return dispatch(fetchSitesByClient(clientId));
  }
  return Promise.resolve();
};
