export const PROPERTIES_TYPES = {
  STATIC: 'static',
  PROPERTY: 'property',
  PROFILE_VARIABLE: 'variable',
};

export const getDefaultType = (hasProperty, hasStaticProperty, hasStoredVariables) => {
  if (hasProperty) {
    return PROPERTIES_TYPES.PROPERTY;
  }

  if (hasStaticProperty) {
    return PROPERTIES_TYPES.STATIC;
  }

  if (hasStoredVariables) {
    return PROPERTIES_TYPES.PROFILE_VARIABLE;
  }

  return PROPERTIES_TYPES.STATIC;
};

export const getPropertyValue = (valueFromFormik, valueType, usedVariables) => {
  if (valueType === PROPERTIES_TYPES.PROFILE_VARIABLE) {
    const isVariableSelectedInIntegrations = usedVariables.filter(variable => variable.name === valueFromFormik);
    return isVariableSelectedInIntegrations.length > 0 ? valueFromFormik : '';
  }

  return valueFromFormik;
};
