import React, { ChangeEvent, FocusEvent } from 'react';
import cx from 'classnames';

import '~/components/src/Form/Elements/elements.scss';

interface CheckboxElementProps {
  value: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const CheckboxElement = ({
  value,
  disabled,
  name,
  id,
  onChange,
  onBlur,
  className,
}: CheckboxElementProps): JSX.Element => (
  <div
    className={cx(
      'CheckboxElement',
      { 'CheckboxElement--checked': value, 'CheckboxElement--disabled': disabled },
      className,
    )}
  >
    <input
      type="checkbox"
      checked={value}
      name={name}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      className="CheckboxElement-hiddenInput"
    />
    <svg className="CheckboxElement-box" viewBox="0 0 52 52">
      <path className="CheckboxElement-tick" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
);

export default CheckboxElement;
