/**
 * @namespace webUi.ui.globalui
 * @module webUi.ui.globalui
 */
angular.module('webUi.ui.globalui', [
    'webUi.ui.globalui.home',
    'webUi.ui.globalui.errorPopup',
    'webUi.ui.globalui.navigation',
    'webUi.ui.globalui.welcome',
    'webUi.ui.globalui.deleteDialog'
]);
