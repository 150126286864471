angular.module('webUi.ui.help.base', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.help', {
                'abstract': true,
                url: '/help',
                views : {
                    'mainContent@' : {
                        templateUrl: 'ui/help/base/base.tpl.html'
                    }
                }
            });
    }]);
