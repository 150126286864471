import React from 'react';
import PieChart from '~/components/src/PieChart';
import i18n from '~/i18n';

interface DataSourceChartProps {
  data: any[];
}

const DataSourceChart: React.FC<DataSourceChartProps> = ({ data }) => {
  const chartData = {
    title: i18n.t('customerFacts:messages.dataSourceTitle'),
    titleTooltip: i18n.t('customerFacts:messages.dataSourceTooltip'),
    data: data.map(source => ({
      name: source.label,
      y: source.value,
    })),
  };

  return <PieChart chartData={chartData} width={300} height={250} />;
};

export default DataSourceChart;
