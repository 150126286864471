import React from 'react';
import i18n from '~/i18n';
import { PickerView } from '~/components';
import { showSuccess } from '~/notificationCenter';
import { joinWithComma } from '../utils';
import GlobalSettingsForm from './GlobalSettingsForm';
import { CreateErrorReportingPayload, EditGlobalSettingsProps } from '../types';
import { updateEmailsToNotify, updateEnabled, updateErrorThreshold } from '../dataService';

const EditGlobalSettings = ({ setShowEditModal, refetch, errorReporting }: EditGlobalSettingsProps) => {
  const { errorThreshold, emailsToNotify, enabled, settingsId } = errorReporting || {};

  const handleSubmit = async (values: CreateErrorReportingPayload & { emails: string[] }) => {
    const payload = { settingsId };
    const emailToNotifyUpdatePromise =
      joinWithComma(values?.emails) !== emailsToNotify
        ? updateEmailsToNotify({ ...payload, emailsToNotify: joinWithComma(values?.emails) })
        : Promise.resolve();

    const errorThresholdUpdatePromise =
      values?.errorThreshold !== errorThreshold
        ? updateErrorThreshold({ ...payload, errorThreshold: values?.errorThreshold })
        : Promise.resolve();

    const enabledUpdatePromise =
      values?.enabled !== enabled ? updateEnabled({ ...payload, enabled: values?.enabled }) : Promise.resolve();

    await Promise.all([emailToNotifyUpdatePromise, errorThresholdUpdatePromise, enabledUpdatePromise]);

    showSuccess({ header: i18n.t('errorReporting:modal.update.api.updated') });
    setShowEditModal(false);
    refetch();
  };

  return (
    <PickerView
      className="!max-h-[70%] !w-[500px]"
      pickerTitle={i18n.t('errorReporting:modal.update.title')}
      handlePickerVisibility={setShowEditModal}
    >
      <GlobalSettingsForm
        showModal={setShowEditModal}
        handleSubmit={handleSubmit}
        initialValues={errorReporting}
        isEdit={true}
      />
    </PickerView>
  );
};

export default EditGlobalSettings;
