import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { submit } from 'redux-form';
import { compose } from 'recompose';
import * as fromModals from '~/modals';
import { hideModal as defaultHideModal } from '~/modals/actions';
import * as types from '~/user/settings/types';
import { deleteTwoFactorAuthentication, makeDefault2FA, unlinkOpenId } from '~/user/settings';
import UpdatePhoneModalContainer from '~/user/settings/containers/UpdatePhoneModalContainer';
import TwoFactorAuthModalContainer from '~/user/settings/containers/TwoFactorAuthModalContainer';
import UpdatePasswordModalContainer from '~/user/settings/containers/UpdatePasswordModalContainer';
import { changeUrl } from '~/common/history';
import SecuritySettings from './presenter';

const mapStateToProps = (state, ownProps) => {
  const { t, user } = ownProps;
  return {
    user,
    t,
    stateKey: 'UserSettingsKey',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { t, user } = ownProps;
  return {
    onAddSmsToken: () =>
      dispatch(
        fromModals.showModal(fromModals.CUSTOM_MODAL, {
          content: <UpdatePhoneModalContainer user={user} t={t} />,
        }),
      ),
    onRemoveSmsToken: phoneNumber =>
      dispatch(
        fromModals.showDeleteModal({
          title: t('settings:deletePhone.title'),
          message: t('settings:deletePhone.confirmationContent', { phoneNumber }),
          onConfirm: () => dispatch(deleteTwoFactorAuthentication('SMS')),
        }),
      ),
    onAddTwoFactorAuth: () =>
      dispatch(
        fromModals.showModal(fromModals.CUSTOM_MODAL, {
          content: <TwoFactorAuthModalContainer user={user} t={t} />,
        }),
      ),
    onRemoveTimebasedToken: () =>
      dispatch(
        fromModals.showDeleteModal({
          title: t('settings:deleteTimebased.title'),
          message: t('settings:deleteTimebased.confirmationContent'),
          onConfirm: () => dispatch(deleteTwoFactorAuthentication('GOOGLE_AUTH')),
        }),
      ),
    makeDefault2FA: authenticationType => dispatch(makeDefault2FA(authenticationType)),
    unlinkOpenId: () => dispatch(unlinkOpenId()),
    showChangePasswordModal: user =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('settings:updatePassword.title'),
          onConfirm: () => {
            dispatch(submit(types.UPDATE_PASSWORD_FORM));
            changeUrl('user/settings/view?tab=1');
            return Promise.reject();
          },
          hideModal: () => () => {
            dispatch(defaultHideModal());
            changeUrl('user/settings/view?tab=1');
          },
          content: <UpdatePasswordModalContainer user={user} t={t} />,
        }),
      ),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(SecuritySettings);
