import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Collecting all clients by IDs
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const byClientId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.CONTEXT_CLIENTS_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(item => {
        nextState[item.clientId] = item;
      });
      return nextState;
    case types.CONTEXT_CLIENT_SAVE_SUCCESS:
    case types.CONTEXT_CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.clientId]: action.payload,
      };
    case types.CONTEXT_CLIENT_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload.clientId];
      return nextState;

    default:
      return state;
  }
};
/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const allClientsIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.CONTEXT_CLIENTS_FETCH_SUCCESS:
      return action.payload.map(item => item.clientId);

    case types.CONTEXT_CLIENT_SAVE_SUCCESS:
    case types.CONTEXT_CLIENT_FETCH_SUCCESS:
      if (state.findIndex(item => item === action.payload.clientId) === -1) {
        return [...state, action.payload.clientId];
      }
      return state;
    case types.CONTEXT_CLIENT_DELETE_SUCCESS:
      nextState = [...state];
      nextState.splice(
        state.findIndex(item => item === action.payload.clientId),
        1,
      );
      return nextState;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetching: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetching: false,
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.CONTEXT_CLIENT_FETCH_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.CONTEXT_CLIENTS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.CONTEXT_CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.CONTEXT_CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byClientId,
  allClientsIds,
  ui,
});
