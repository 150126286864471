import React, { Component } from 'react';
import _ from 'lodash';
import { Field, FormSection } from 'redux-form';
import { isRequired, tokenIsRequired } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { GoogleButton, Link, Notification } from '~/components';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const renderMessage = t => (
  <ol>
    <li>{t('form.notification.oAuthMessageStep1', { partner: 'Google' })}</li>
    <li>{t('form.notification.oAuthMessageStep2')}</li>
    <li>{t('form.notification.oAuthMessageStep3')}</li>
    <li>{t('form.notification.oAuthMessageStep4')}</li>
    <li>{t('form.notification.oAuthMessageStep5')}</li>
  </ol>
);

class GoogleAdsForm extends Component {
  componentDidMount() {
    const { fetchPayloadTypes, fetchConsentTypes, fetchPartnerInformation } = this.props;
    fetchPayloadTypes(constants.GOOGLE_ADS);
    fetchConsentTypes(constants.GOOGLE_ADS);
    fetchPartnerInformation();
  }

  componentDidUpdate() {
    const { change, values } = this.props;

    const params = new URL(window.location.href).searchParams;

    const refreshToken = params.get(constants.refreshToken);
    const partnerValues = JSON.parse(localStorage.getItem('partnerValues')) || {};

    if (refreshToken && !_.isEmpty(values) && !values.refreshToken) {
      change('GOOGLE_ADS.name', partnerValues.name || '');
      change('GOOGLE_ADS.refreshToken', refreshToken || '');
      change('GOOGLE_ADS.clientCustomerId', partnerValues.clientCustomerId || '');
      change('GOOGLE_ADS.managerCustomerId', partnerValues.managerCustomerId || '');
      change('GOOGLE_ADS.payloadType', partnerValues.payloadType || '');
      change('GOOGLE_ADS.consent', partnerValues.consent || '');
      change('GOOGLE_ADS.partnerNumber', partnerValues.partnerNumber || '');
    }
  }

  render() {
    const {
      t,
      values,
      partner,
      payloadTypes,
      consentTypes,
      change,
      redirectToAuthorizationUrl,
      touch,
      mode,
      canUpdate: isEditMode,
    } = this.props;

    const payloadTypesByName = payloadTypes.reduce((payloadTypesByName, payloadType) => {
      payloadTypesByName[payloadType.name] = payloadType;
      return payloadTypesByName;
    }, {});

    const isPartnerNumberFieldVisible =
      mode === constants.modes.CREATE_SERVER &&
      values.payloadType &&
      Object.keys(payloadTypesByName).length > 0 && // Makes sure payloadTypes are loaded before accessing them
      payloadTypesByName[values.payloadType].allowsPartnerNumberOverride;

    return (
      <FormSection name={constants.partnerTypes.GOOGLE_ADS}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection
          title={t('form.formFields.refreshToken')}
          description={t('form.notification.oAuthMessage', { partner: 'Google Ads' })}
          extra={renderMessage(t)}
        >
          <div className="u-marginVerticalL u-flex-justify-end">
            <GoogleButton
              onClick={() => redirectToAuthorizationUrl()}
              label={t('form.formFields.grantPermissionWithGoogle')}
            />
          </div>
          <FieldWithEditMode
            label={t('form.formFields.refreshToken')}
            name="refreshToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={isEditMode}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={!isEditMode && tokenIsRequired}
            type="text"
            disabled={true}
          />
          <Field
            label={t('form.formFields.clientCustomerId')}
            name="clientCustomerId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.managerCustomerId')}
            name="managerCustomerId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            disabled={isEditMode}
            onChange={(event, value) => {
              change('GOOGLE_ADS.partnerNumber', payloadTypesByName[value].partnerNumber);
            }}
            touch={touch}
          />
          {isPartnerNumberFieldVisible && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
          )}
          <div className="pt-6">
            <Notification kind="information" className="my-6" header={t('form.notification.acceptEUConsent.header')}>
              <p>{t('form.notification.acceptEUConsent.message1.googleAds')}</p>
              <p className="mt-2">{t('form.notification.acceptEUConsent.message2')}</p>
              <Link target="_blank" href={t('form.notification.acceptEUConsent.link')}>
                {t('form.notification.acceptEUConsent.link')}
              </Link>
            </Notification>
            <Field
              label={t('form.formFields.acceptEUConsentSelection')}
              name="consent"
              component={ReduxFormSelectField}
              options={consentTypes}
              validate={isRequired}
              touch={touch}
            />
          </div>
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default GoogleAdsForm;
