import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TagCounterStats from './TagCounterStats';
import TagCounterList from './TagCounterList';
import { TTagCounterPagesProps } from './types';

const TagCounterPages = ({ hasTagEdit }: TTagCounterPagesProps) => (
  <Routes>
    <Route path="list" element={<TagCounterList hasTagEdit={hasTagEdit} />} />
    <Route path="stats/:counterIds" element={<TagCounterStats />} />
  </Routes>
);

export default TagCounterPages;
