import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common';
import { fetchGroupIfNeeded, saveGroup } from '~/support/tagtemplates/groups/actions';
import * as selectors from '~/support/tagtemplates/groups/selectors';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import TagGroupForm from '~/support/components/TagGroupForm';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

export class TagTemplateGroup extends Component {
  componentDidMount() {
    const { fetchGroup, id } = this.props;
    fetchGroup(id);
  }

  render() {
    const { props } = this;
    const { t, initialValues, ...other } = props;

    return initialValues ? (
      <Page>
        <MediumWrapper>
          <Heading title={t('groupForm.header')} />
          <Panel>
            <TagGroupForm t={t} {...other} />
          </Panel>
        </MediumWrapper>
      </Page>
    ) : (
      <Spinner />
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps?.params.groupId;
      return {
        id,
        initialValues: selectors.getGroup(state, ownProps?.params.groupId),
        backHref: buildUrl(`support/tagtemplates/groups/view/${ownProps?.params.groupId}`),
      };
    },
    dispatch => ({
      fetchGroup: id => dispatch(fetchGroupIfNeeded(id)),
      onSubmit: values => dispatch(saveGroup(values)),
    }),
  ),
  reduxForm({
    form: 'tagGroupEdit',
  }),
  translate(['tagTemplates']),
)(TagTemplateGroup);
