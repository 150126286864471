import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Spinner from '~/components/src/Spinner';
import { Tabs, TabList, Tab, TabPanel } from '~/components/src/Tabs';
import DependantsList from './DependantsList';
import DependantJourneysList from './DependantJourneysList';

const dataIsLoaded = (...dependantTypes) => dependantTypes.some(dependantType => !!dependantType);

export class DependantsContainer extends Component {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      element,
      elementName,
      onTabChange,
      t,
      selectedTab,
      showSelectionDependants,
      showContentDependants,
      showDataCollectorDependants,
      showJourneyDependants,
      showFilterDependants,
      showTransformationDependants,
    } = this.props;

    if (!element || !element.dependantsInfo) {
      return <Spinner />;
    }

    const {
      selectionDependants,
      contentDependants,
      dataCollectorDependants,
      journeyDependants,
      filterDependants,
      transformationDependants,
    } = element.dependantsInfo;

    if (!dataIsLoaded(selectionDependants, contentDependants, dataCollectorDependants, journeyDependants)) {
      return <Spinner />;
    }

    return (
      <Tabs selectedIndex={selectedTab} onSelect={selectedTab => onTabChange(selectedTab)}>
        <TabList>
          {showSelectionDependants && <Tab testHook="audiencesTab">{t('general.audiences')}</Tab>}
          {showContentDependants && <Tab testHook="adTab">{t('general.ad')}</Tab>}
          {showDataCollectorDependants && <Tab testHook="dataCollectorsTab">{t('general.dataCollectors')}</Tab>}
          {showJourneyDependants && <Tab testHook="workflowsTab">{t('general.workflows')}</Tab>}
          {showFilterDependants && <Tab testHook="filtersTab">{t('general.filters')}</Tab>}
          {showTransformationDependants && <Tab testHook="transformationsTab">{t('general.transformations')}</Tab>}
        </TabList>

        {/* Selections/Audiences */}
        {showSelectionDependants && (
          <TabPanel>
            <DependantsList
              stateKey="PROFILES/DEPENDANTS/SELECTION"
              hasAccess={selectionDependants.userHasAccessToData}
              list={selectionDependants.userHasAccessToData ? selectionDependants.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.audiences') })}
              t={t}
            />
          </TabPanel>
        )}

        {/* Contents */}
        {showContentDependants && (
          <TabPanel>
            <DependantsList
              stateKey="PROFILES/DEPENDANTS/AD"
              hasAccess={contentDependants.userHasAccessToData}
              list={contentDependants.userHasAccessToData ? contentDependants.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.ad') })}
              t={t}
            />
          </TabPanel>
        )}

        {/* DataCollectors */}
        {showDataCollectorDependants && (
          <TabPanel>
            <DependantsList
              stateKey="PROFILES/DEPENDANTS/DATA_COLLECTOR"
              hasAccess={dataCollectorDependants.userHasAccessToData}
              list={dataCollectorDependants.userHasAccessToData ? dataCollectorDependants.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.dataCollectors') })}
              t={t}
            />
          </TabPanel>
        )}
        {/* Journeys with triggers */}
        {showJourneyDependants && (
          <TabPanel>
            <DependantJourneysList
              stateKey="PROFILES/DEPENDANTS/JOURNEY"
              hasAccess={journeyDependants.userHasAccessToData}
              list={journeyDependants.userHasAccessToData ? journeyDependants.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.workflows') })}
              t={t}
            />
          </TabPanel>
        )}
        {showFilterDependants && (
          <TabPanel>
            <DependantsList
              stateKey="PROFILES/DEPENDANTS/FILTER"
              hasAccess={filterDependants?.userHasAccessToData}
              list={filterDependants?.userHasAccessToData ? filterDependants?.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.filters') })}
              t={t}
            />
          </TabPanel>
        )}
        {showTransformationDependants && (
          <TabPanel>
            <DependantsList
              stateKey="PROFILES/DEPENDANTS/PROPERTY_TRANSFORMATION"
              hasAccess={transformationDependants?.userHasAccessToData}
              list={transformationDependants?.userHasAccessToData ? transformationDependants?.dependantsSet : []}
              emptyListMessageBody={t('list.empty.body', { elementName, elements: t('general.transformations') })}
              t={t}
            />
          </TabPanel>
        )}
      </Tabs>
    );
  }
}

DependantsContainer.propTypes = {
  element: PropTypes.object,
  elementName: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  showSelectionDependants: PropTypes.bool,
  showContentDependants: PropTypes.bool,
  showDataCollectorDependants: PropTypes.bool,
  showJourneyDependants: PropTypes.bool,
  showFilterDependants: PropTypes.bool,
  showTransformationDependants: PropTypes.bool,
};

DependantsContainer.defaultProps = {
  showSelectionDependants: true,
  showContentDependants: true,
  showDataCollectorDependants: true,
  showJourneyDependants: true,
  showFilterDependants: true,
  showTransformationDependants: true,
};

export default translate('dependants')(DependantsContainer);
