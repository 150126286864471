import React, { ReactElement } from 'react';
import cx from 'classnames';
import { translate } from 'react-i18next';
import { Placement } from 'tippy.js';
import { Panel } from '~/components/src/Containers';
import HumanNumber from '~/components/src/HumanNumber';
import Icons from '~/components/src/Icons';
import icons from '~/components/src/Icons/icons';
import LinkIcon from '~/components/src/LinkIcon';
import Spin from '~/components/src/Spin';
import Tooltip from '~/components/src/Tooltip';
import i18n from '~/i18n';

interface DefaultPanelProps {
  className?: string;
  icon: keyof typeof icons;
  title: number;
  subTitle: React.ReactNode;
  isLoading: boolean;
  tooltip?: string;
  hasNoContent?: boolean;
  children?: React.ReactNode;
  tooltipPlacement?: Placement;
  url?: string;
  testHook?: string;
}

interface PanelHeaderProps {
  icon: keyof typeof icons;
  title: number;
  subTitle: React.ReactNode;
  url?: string;
  tooltip?: string;
  tooltipPlacement?: Placement;
}

export const PanelHeader = ({
  icon,
  title,
  subTitle,
  tooltip,
  tooltipPlacement = 'bottom',
  url,
}: PanelHeaderProps): ReactElement => (
  <div className="mb-4 flex w-full justify-between">
    <div className="flex items-center gap-4">
      <Icons icon={icon} className="h-10 w-10 rounded bg-slate-400 bg-opacity-10 p-2 text-slate-400" />
      <div>
        <div className="text-xl font-medium normal-nums leading-6 text-emerald-500">
          <HumanNumber number={title} testHook="itemsCount" />
        </div>
        <div className="t-panelName flex items-center font-medium text-slate-600 dark:text-slate-400">
          <span>{subTitle}</span>
          {tooltip && (
            <Tooltip className="ml-1" placement={tooltipPlacement} tooltip={tooltip}>
              <Icons
                icon="info"
                className="h-5 w-5 rounded bg-slate-300 bg-opacity-10 p-0.5 text-slate-500 dark:bg-slate-700 dark:text-slate-400"
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
    <div>
      {url && <LinkIcon icon="openInNew" url={url} tooltip={i18n.t('dashboard:showAll')} className="h-6 w-6" />}
    </div>
  </div>
);

const DefaultPanel = ({
  className,
  icon,
  title,
  subTitle,
  tooltip,
  isLoading,
  hasNoContent,
  children,
  url,
  testHook,
  tooltipPlacement = 'bottom',
}: DefaultPanelProps) => {
  const hasContent = !hasNoContent;

  return (
    <Panel
      className={cx(className, 'flex flex-col items-center', {
        'justify-center': isLoading,
        [`t-${testHook}`]: testHook,
      })}
    >
      {isLoading ? (
        <div className="py-16">
          <Spin />
        </div>
      ) : (
        <>
          {hasContent && (
            <PanelHeader
              icon={icon}
              title={title}
              subTitle={subTitle}
              tooltip={tooltip}
              tooltipPlacement={tooltipPlacement}
              url={url}
            />
          )}
          {children}
        </>
      )}
    </Panel>
  );
};

export default translate('dashboard')(DefaultPanel);
