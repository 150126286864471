import React from 'react';
import PropTypes from 'prop-types';
import { Detail } from '../Form';
import '../Form/form.scss';
import '../Form/Elements/elements.scss';

const MessageDetail = ({ message, ts }) => (
  <div className="MessageDetail">
    <Detail label={ts.title} value={message.title} />
    <Detail label={ts.client} value={message.clientName} />
    <Detail label={ts.showDate} value={message.showDate}>
      {message.showDate}
    </Detail>
    <Detail label={ts.hideDate} value={message.hideDate}>
      {message.hideDate}
    </Detail>
  </div>
);

MessageDetail.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    clientName: PropTypes.string,
    showDate: PropTypes.string.isRequired,
    hideDate: PropTypes.string,
  }).isRequired,
  // Translations
  ts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    showDate: PropTypes.string.isRequired,
    hideDate: PropTypes.string.isRequired,
  }),
};

export default MessageDetail;
