import React from 'react';
import { translate } from 'react-i18next';
import * as Yup from 'yup';
import { useAPI } from '~/common/ApiHooks';
import { ConnectorGenericForm } from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorGenericForm';
import { Field, FormikProps } from 'formik';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import CardMessage from '~/components/src/CardMessage';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { fetchMarketoCampaigns } from './service';
import ConnectorProperties from '../ConnectorProperties';

const makeValidationSchema = (t: any) =>
  Yup.object().shape({
    smartCampaign: Yup.object().required(t('validation:validation.required')),
  });

interface IConnectorIntegration {
  onEdit: () => void;
  connector: any;
  t: any;
  isSubmitted: boolean;
  usedVariables?: {
    name: string;
    profileVarId: string;
  }[];
}
interface ISmartCampaign {
  name: string;
  id: string;
}
interface IMarketoFormValues {
  smartCampaign: ISmartCampaign;
  variables?: any;
}
const Marketo = ({ onEdit, connector, t, isSubmitted, usedVariables }: IConnectorIntegration) => {
  const { partnerId } = connector.partnerDetails;

  const { data: marketoCampaigns, isLoading, error } = useAPI(() => fetchMarketoCampaigns(partnerId));

  if (!marketoCampaigns) return null;

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      connector={connector}
      validations={makeValidationSchema(t)}
      isSubmitted={isSubmitted}
    >
      {(formProps: FormikProps<IMarketoFormValues>) => (
        <>
          <SmallWrapperNoOffset>
            <Field
              name="smartCampaign"
              as={FormikSelectField}
              label={t('audiences:connectors.marketo.smartCampaign')}
              errorText={formProps?.touched?.smartCampaign && formProps?.errors?.smartCampaign?.name}
              options={marketoCampaigns}
              getOptionLabel={(option: ISmartCampaign) => option.name}
              getOptionValue={(option: ISmartCampaign) => option.id}
              value={formProps.values.smartCampaign}
              isLoading={isLoading}
              testHook="marketoCampaignsDropdown"
            />
          </SmallWrapperNoOffset>

          <ConnectorProperties
            propertyColumnLabel="Lead field"
            isCustomCreatable={true}
            formProps={formProps}
            name="variables"
            usedVariables={usedVariables}
            hasStoredVariables={true}
            t={t}
          />

          {error && <CardMessage message={t('audiences:connectors.marketo.campaignFetchingError')} />}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default translate('audiences')(Marketo);
