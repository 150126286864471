angular.module('webUi.ui.tagmanagement.newPaths.list', [
    'webUi.ui.tagmanagement.newPaths.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.newPaths.list', {
                url: '/list/{location:.*}?page&maxResults',
                isReact: true,

            });
    }]);

   