import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import GoogleAds from './presenter';
import { openCreateUserModal, hideModal } from './actions';

const mapDispatchToProps = {
  openCreateUserModal,
  hideModal,
};

export default compose(connect(null, mapDispatchToProps), translate('audiences'))(GoogleAds);
