import React, { CSSProperties } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import i18n from '~/common/formValidation/i18n';
import { menuPortalStyle } from '~/common/utils/menuPortalStyle';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { showSuccess } from '~/notificationCenter';
import { VISIBILITY_TYPES } from './constants';
import { createNewAddressBook } from './dataService';

type CreateAddressBookProps = {
  partnerId: string;
  hideModal: () => void;
  refetchAddressBooks: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation:validation.required')),
  visibility: Yup.object().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  name: '',
  visibility: VISIBILITY_TYPES[0],
};

const CreateAddressBook = ({ partnerId, hideModal, refetchAddressBooks }: CreateAddressBookProps) => {
  const { setFieldValue } = useFormikContext();
  const handleCreateAddressBook = async (values: { name: string; visibility: { label: string; value: string } }) => {
    const payload = {
      ...values,
      visibility: values.visibility.value,
    };
    const addressBook = await createNewAddressBook(partnerId, payload);

    await setFieldValue('addressBookId', addressBook.id);
    await setFieldValue('addressBookName', addressBook.name);
    refetchAddressBooks();
    hideModal();
    showSuccess({
      body: i18n.t('audiences:connectors.dotdigital.create.success'),
    });
  };

  return (
    <Formik onSubmit={handleCreateAddressBook} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, isSubmitting }) => (
        <div className="t-addressBookForm flex flex-col gap-2">
          <Field
            className="my-2"
            as={FormikInputField}
            name="name"
            label={i18n.t('audiences:connectors.dotdigital.create.name')}
            placeholder={i18n.t('audiences:connectors.dotdigital.create.placeholder')}
            errorText={errors.name}
          />

          <Field
            className="my-2"
            as={FormikSelectField}
            name="visibility"
            label={i18n.t('audiences:connectors.dotdigital.create.visibility')}
            placeholder={i18n.t('audiences:connectors.dotdigital.create.placeholder')}
            errorText={errors.visibility}
            options={VISIBILITY_TYPES}
            menuPosition="fixed"
            // Fix for menu displaying at random places
            elementStyles={{
              menuPortal: (provided: CSSProperties) => menuPortalStyle(provided),
            }}
          />

          <ActionButtons
            testHook="createDotdigitalAddressBook"
            className="!mr-0 !pt-0"
            isConfirmEnabled={!isSubmitting}
            onConfirm={handleSubmit}
            onDecline={hideModal}
          />
        </div>
      )}
    </Formik>
  );
};

export default CreateAddressBook;
