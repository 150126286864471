import _ from 'lodash';
import React from 'react';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import i18n from '~/i18n';

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      if (row.values[id] && row.values[id] !== '-') {
        options.add(row.values[id]);
      }
    });
    return Array.from(options.values())
      .filter(row => !!row)
      .map(row => {
        if (row && _.isObject(row)) {
          return { label: row.label, value: row.name };
        }
        return { label: row, value: row };
      });
  }, [id, preFilteredRows]);

  const defaultValue = { value: '', label: 'All' };

  return (
    <FieldWrapper label={i18n.t(`common:tableColumns.${id}`)}>
      <SelectElement
        defaultValue={defaultValue}
        options={[defaultValue, ...options]}
        value={filterValue || defaultValue}
        onChange={option => setFilter(option.value || undefined)}
      />
    </FieldWrapper>
  );
}

export default SelectColumnFilter;
