/**
 *
 * @description
 * @class DeleteDialogController
 * @memberOf webUi.globalui
 * @namespace webUi.ui.globalui.deleteDialog
 */
angular.module('webUi.ui.globalui.deleteDialog', [
    'ngResource',
    'ui.bootstrap'
])
    .controller('DeleteDialogController', ['$uibModalInstance', 'dialogsModel',
    /**
    * @function DeleteDialogController
    * @memberOf webUi.ui.globalui.deleteDialog
    *
    * @param $uibModalInstance
    * @param dialogsModel
    *
    * @returns {DeleteDialogController}
    */
        function DeleteDialogController($uibModalInstance, dialogsModel) {
            'use strict';
            
            var vm = this;

            vm.data = {};
            vm.data = _.merge(vm.data, dialogsModel.data);

            vm.cancel = function () {
                dialogsModel.onCancel(vm.data.entityId);
                $uibModalInstance.close(false);
            };

            vm.submit = function () {
                dialogsModel.onDelete(vm.data.entityId);
                $uibModalInstance.close(true);
            };
        }]
    );
