import { sortBy } from 'lodash';
import icons from '~/components/src/Icons/icons';
import { getAngularService } from 'ReactAngular/react.service';
import { CHANGE_TYPES, ELEMENT_TYPES } from './constants';
import { getLatestDiff, getTagUsers } from './dataService';
import { TChangeElement, TChangeElementMapped, TUnpublishedChanges } from './types';

const addLink = (element: TChangeElement) => {
  if (element.elementType === ELEMENT_TYPES.TAG) {
    return `tagmanagement/tags/view/${element.elementId}?snapshotId=${element.snapshotId}`;
  } else if (element.elementType === ELEMENT_TYPES.EXPERIMENT) {
    return `tagmanagement/experiments/view/${element.elementId}?snapshotId=${element.snapshotId}`;
  } else if (element.elementType === ELEMENT_TYPES.WEBSITEPATHGROUP) {
    return `tagmanagement/tags/websitePathGroups/${element.elementId}`;
  } else if (element.elementType === ELEMENT_TYPES.COOKIEPERMISSION) {
    return `tagmanagement/cookiePermission/view/${element.elementId}`;
  }
  return '#';
};

const addIcon = (elementType: string): keyof typeof icons => {
  switch (elementType) {
    case ELEMENT_TYPES.EXPERIMENT:
      return 'experiment';
    case ELEMENT_TYPES.PAGEGROUP:
    case ELEMENT_TYPES.WEBSITEPATHGROUP:
      return 'group';
    case ELEMENT_TYPES.COOKIEPERMISSION:
      return 'cookiepermission';
    default:
      return 'tag';
  }
};

export const getLatestChanges = async () => {
  const SecurityService = getAngularService(document, 'SecurityService');
  const context = await SecurityService.getSecurityContext();
  const latestDiff = await getLatestDiff();
  const tagUsers = await getTagUsers();

  return latestDiff.map(diff => {
    const users = diff.modifiedBy.map((userId: string) => tagUsers.find(user => user.userId === userId)?.name) || [];
    const dependencies = diff.dependencies
      .map(depId => latestDiff.find(diff => diff.elementId === depId))
      .filter(Boolean);
    const acceptChange = diff.lastModifiedBy === context.getUser()?.userId;
    return { ...diff, modifiedBy: users?.join(', '), dependencies, acceptChange };
  });
};

export const mapLatestDiff = (latestDiff: TUnpublishedChanges) => {
  if (!latestDiff) return [];
  const diffElements: TChangeElementMapped[] = [];

  if (latestDiff.addedElements) {
    latestDiff.addedElements.forEach(elem => {
      const newElem = {
        ...elem,
        changeType: CHANGE_TYPES.ADDED,
        elementLink: addLink(elem),
        icon: addIcon(elem.elementType),
      };
      diffElements.push(newElem);
    });
  }

  if (latestDiff.updatedElements) {
    latestDiff.updatedElements.forEach(elem => {
      const newElem = {
        ...elem,
        changeType: CHANGE_TYPES.UPDATED,
        elementLink: addLink(elem),
        icon: addIcon(elem.elementType),
      };
      diffElements.push(newElem);
    });
  }

  if (latestDiff.removedElements) {
    latestDiff.removedElements.forEach(elem => {
      const newElem = {
        ...elem,
        changeType: CHANGE_TYPES.REMOVED,
        elementLink: addLink(elem),
        icon: addIcon(elem.elementType),
      };
      diffElements.push(newElem);
    });
  }

  return sortBy(diffElements, 'elementTypes');
};
