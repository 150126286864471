import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import i18n from '~/context/i18n';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { SearchContainer, buildUrl } from '~/common';
import { fetchUserClients, getUserClients, isFetchingUserClients, removeClientManager } from '~/context/users';
import ClientList from '~/context/components/ClientList';
import LinkBtn from '~/components/src/LinkBtn';

const searchFilterFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.clientNumber.toString().indexOf(query.toLowerCase()) !== -1;

const USER_CLIENTS_KEY = 'CONTEXT/USERS/USER_CLIENTS_LIST';
const USER_CLIENTS_FILTER_KEY = 'CONTEXT/USERS/USER_CLIENTS_SEARCH';

class UserListContainer extends Component {
  componentDidMount() {
    this.props.fetchUserClients();
  }

  render() {
    const { props } = this;
    const { isFetching, t } = props;
    const { isContextAdmin, user, stateKey, userClients, clientUrl, onDeleteClient, promoteToManager } = props;

    const adminActions = !isContextAdmin
      ? {}
      : {
          onDeleteClick: client => onDeleteClient(user, client),
        };

    return (
      <div>
        <Heading title={t('view.clients.header')}>
          <SearchContainer stateKey={USER_CLIENTS_KEY} searchKey={USER_CLIENTS_FILTER_KEY} searchFn={searchFilterFn} />

          {isContextAdmin && (
            <LinkBtn url={promoteToManager} color="blue">
              {t('view.clients.actions.promoteToManager')}
            </LinkBtn>
          )}
        </Heading>
        <ClientList
          list={userClients}
          t={t}
          stateKey={stateKey}
          clientUrl={clientUrl}
          emptyBody={t('view.clients.emptyList')}
          {...adminActions}
        />
        <Spinner isActive={isFetching} />
      </div>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const id = ownProps.userId;
  return {
    id,
    stateKey: USER_CLIENTS_KEY,
    promoteToManager: buildUrl(`context/users/promoteUserToClientAccountManager/${id}`),
    userClients: getUserClients(state, id),
    clientUrl: item => buildUrl(`context/clients/view/${item.clientId}`),
    isFetching: isFetchingUserClients(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.userId;
  return {
    fetchUserClients: () => dispatch(fetchUserClients(id)),
    onDeleteClient: (user, client) => {
      const userName = (user && user.name) || '';
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('users:view.clients.deleteModal.title'),
          message: i18n.t('users:view.clients.deleteModal.message', { userName, clientName: client.name }),
          onConfirm: () => dispatch(removeClientManager(id, client.clientId)),
        }),
      );
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(['users', 'clients']),
)(UserListContainer);
