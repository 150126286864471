import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import i18n from '~/i18n';
import { CONTENT_MODAL } from '~/modals/types';
import { showModal } from '~/modals/actions';
import { isHistoricProcessingStarted, getFullAudiences } from '~/profiles/audiences/selectors';
import { runHistoricProcessing, fetchFullAudiences } from '~/profiles/audiences/actions';
import { Notification } from '~/components';
import { SELECTED_AUDIENCES } from '~/profiles/audiences/types';
import HistoricProcessingPage from './presenter';

const mapStateProps = state => {
  const audiencesList = getFullAudiences(state);
  // We need to define negative values to make all formik inputs controllable
  const emptyValues = audiencesList.reduce((acc, { segmentId }) => {
    acc[segmentId] = false;
    return acc;
  }, {});
  const initialValues = { audience: emptyValues };
  return {
    stateKey: SELECTED_AUDIENCES,
    audiencesList,
    isFetching: false,
    isSubmitting: isHistoricProcessingStarted(state),
    initialValues,
  };
};

export const makeShowHPConfirmationModal = values => dispatch => {
  const action = showModal(CONTENT_MODAL, {
    title: i18n.t('audiences:historicProcessing.confirmationModalTitle'),
    confirmText: i18n.t('audiences:historicProcessing.confirm'),
    content: <Notification kind="warning">{i18n.t('audiences:historicProcessing.confirmationModalText')}</Notification>,
    onConfirm: () => {
      dispatch(runHistoricProcessing(values));
    },
  });

  dispatch(action);
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch(fetchFullAudiences()),
  handleSubmit: values => {
    const selectedAudiences = Object.keys(values?.audience).filter(audId => values?.audience[audId]);
    if (selectedAudiences.length > 1) {
      dispatch(runHistoricProcessing(values));
    } else {
      dispatch(makeShowHPConfirmationModal(values));
    }
  },
});

export default compose(
  translate('audiences'),
  withRouter,
  connect(mapStateProps, mapDispatchToProps),
)(HistoricProcessingPage);
