import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Formik, Field } from 'formik';
import i18n from '~/common/formValidation/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';

const validationSchema = Yup.object().shape({
  groupName: Yup.string().trim().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  groupName: '',
};

const GroupAdd = ({ handleAddGroup, hideModal, t }) => (
  <Formik onSubmit={handleAddGroup} validationSchema={validationSchema} initialValues={initialValues}>
    {({ handleSubmit, errors }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="groupName"
          as={FormikInputField}
          label={t('groupAdd.name.label')}
          placeholder={t('groupAdd.name.placeholder')}
          errorText={errors.groupName}
          autoFocus={true}
        />
        <ActionButtons onConfirm={handleSubmit} onDecline={hideModal} className="mr-0" />
      </form>
    )}
  </Formik>
);

GroupAdd.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(translate('audiences'))(GroupAdd);
