import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MdPlayArrow from 'react-icons/lib/md/play-arrow';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';

const { Table, Row, HeaderCell, Cell, RowActions } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t }) => (
  <Empty
    header={t('urlBuilder:exportConfigurations.list.empty.header')}
    body={t('urlBuilder:exportConfigurations.list.empty.body')}
  />
);

export const ExportConfigList = ({ list, stateKey, onEditClick, onStartClick, onDeleteClick, t, isSiteAdmin }) => (
  <Panel>
    <Table stateKey={stateKey} testHook="configurationTable">
      <Row>
        <SortableHeader
          className="u-size3of12"
          sortKey="remoteConnection"
          title={t('urlBuilder:exportConfigurations.list.remoteConnection')}
        />
        <SortableHeader
          className="u-size1of12"
          sortKey="exportAccountId"
          title={t('urlBuilder:exportConfigurations.list.exportAccountId')}
        />
        <SortableHeader
          className="u-size2of12"
          sortKey="lastAttempt"
          title={t('urlBuilder:exportConfigurations.list.lastAttempt')}
        />
        <SortableHeader
          className="u-size2of12"
          sortKey="lastSuccess"
          title={t('urlBuilder:exportConfigurations.list.lastSuccess')}
        />
        <SortableHeader
          className="u-size1of12"
          sortKey="enabled"
          title={t('urlBuilder:exportConfigurations.list.enabled')}
        />
        <SortableHeader
          className="u-size2of12"
          sortKey="addedOn"
          title={t('urlBuilder:exportConfigurations.list.addedOn')}
        />
        <HeaderCell className="u-size1of12" />
      </Row>
      {list.map(exportConfig => {
        const {
          configId,
          exportAccountId,
          lastAttempt,
          lastSuccess,
          enabled,
          lastError,
          addedOn,
          configuration: {
            connectionType: { label: connectionType },
            port,
            host,
            directory,
          },
        } = exportConfig;
        const shouldDisplayError = lastAttempt && lastAttempt > lastSuccess;
        const errorMessage =
          shouldDisplayError && (lastError || t('urlBuilder:exportConfigurations.list.report.error'));
        const getConnectionName = (connectionType, host, port, directory) =>
          `${connectionType} to: ${host}:${port}:${directory}`;
        const actions = [
          {
            name: t('urlBuilder:exportConfigurations.list.report.run'),
            tooltip: !enabled
              ? t('urlBuilder:exportConfigurations.list.report.disabled')
              : errorMessage || t('urlBuilder:exportConfigurations.list.report.run'),
            isDisabled: !enabled || !isSiteAdmin,
            icon: MdPlayArrow,
            onClick: () => onStartClick(exportConfig),
            testHook: 'runReport',
          },
          {
            name: t('urlBuilder:exportConfigurations.list.editActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:exportConfigurations.list.editActionButton')
              : t('urlBuilder:exportConfigurations.list.editPermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'edit',
            onClick: () => onEditClick(configId),
            testHook: 'edit',
          },
          {
            name: t('urlBuilder:exportConfigurations.list.deleteActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:exportConfigurations.list.deleteActionButton')
              : t('urlBuilder:exportConfigurations.list.deletePermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'delete',
            onClick: () => onDeleteClick(exportConfig, t),
            testHook: 'delete',
            type: 'delete',
          },
        ];
        return (
          <Row key={configId} withActions>
            <Cell className="u-size3of12">{getConnectionName(connectionType, host, port, directory)}</Cell>
            <Cell className="u-size1of12">{exportAccountId || ' '}</Cell>
            <Cell className="u-size2of12">{lastAttempt}</Cell>
            <Cell className="u-size2of12">{lastSuccess}</Cell>
            <Cell className="u-size1of12">{enabled ? t('common:checkbox.truthy') : t('common:checkbox.falsy')}</Cell>
            <Cell className="u-size2of12">{addedOn}</Cell>
            <RowActions className="u-size1of12" actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

ExportConfigList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onStartClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default compose(withSort(), withPaginate({ size: 11 }), withEmpty({ component: EmptyList }))(ExportConfigList);
