const percentageIndicatorIds = ['errorsPercentage'];

const renderPoint = point => `<tr>
    <td style="color:${point.series.color};padding:0">${point.series.name}: </td>
    <td style="padding-left:30px;">
      <b>${Highcharts.numberFormat(Math.abs(point.point.y), 0)} 
      ${percentageIndicatorIds.includes(point?.series?.options?.id) ? '%' : ''}
      </b>
    </td>
  </tr>`;

export function getTooltipFormatter() {
  const renderedPoints = this.points.map(point => renderPoint(point));

  return `
    <span style="font-size:10px">${moment(this.x).format('D. MMM')}</span>
    <table> ${renderedPoints.join('')} </table>
  `;
}

export const CHART_COLORS = {
  WHITE: '#ffffff',
  RED: '#d31d1d',
  BLUE: '#068df4',
  YELLOW: '#e1ae14',
  GREEN: '#188142',
  ORANGE: '#d3751d',
  PURPLE: '#8e11a7',
  DARKGREY: '#666',
  LIGHTBLUE: '#83c7fc',
  LIGHTGREEN: '#0ad65c',
  SLATEBLUE: '#6c5ed0',
  DARKGREY2: '#999ea1',
  JOURNEY_GOAL: '#424447',
  LIGHTSLATEGRAY: '#8f9396',
  DIMGRAY: '#5e6062',
};

export const ORCHESTRATION_CHART_COLORS = {
  ENTERED: '#2563EB',
  REACHED: '#16A34A',
  EXITED: '#F87171',
  BATCH: '#FBBF24',
};
