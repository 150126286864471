import React, { useState } from 'react';
import moment from 'moment';
import { RowActions } from '~/components/src/Table/components';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import { UITable } from '~/components/src/UITable';
import { Link, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { buildUrl, changeUrl } from '~/common';
import DeletePopup from '~/components/src/DeletePopup';
import { showSuccess } from '~/notificationCenter';
import { FilterListRowType, FilterListType, FiltersTableProps } from './types';
import { deleteFilter } from './dataService';

const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm:ss';

const filterFilters = (filters: FilterListType[], query: string) =>
  query ? filters.filter(filter => filter.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) : filters;

const columnHelper = createColumnHelper<FilterListRowType>();

function FiltersTable({
  filtersList,
  filterQuery,
  canEditProfiles,
  refetchFilters,
  setSelectedFilter,
}: FiltersTableProps): JSX.Element {
  const [selectedDeleteFilter, setSelectedDeleteFilter] = useState<FilterListType | null>(null);

  const handleDelete = async (filterId: string) => {
    await deleteFilter(filterId);
    refetchFilters();
    setSelectedDeleteFilter(null);
    showSuccess({ header: i18n.t('filters:form.deleteSuccess') });
  };

  const filters = filterFilters(filtersList, filterQuery);

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('connectors:common.name')}</div>,
      cell: ({ row }) => (
        <div className="text-left">
          <Link className="t-filterName" href={buildUrl(`profiles/filters/view/${row.original.filterId}`)}>
            <span className="elipsis truncate">{row.original.name}</span>
          </Link>
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div className="text-left">{i18n.t('filters:columns.createdAt')}</div>,
      cell: info => (
        <div className="text-left">
          <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>{' '}
          <span className="text-r42-blue text-xs">{moment(info.getValue()).format(TIME_FORMAT)}</span>
        </div>
      ),
      size: 200,
    }),
    columnHelper.accessor('lastModifiedAt', {
      header: () => <div className="text-left">{i18n.t('filters:columns.lastUpdate')}</div>,
      cell: info => (
        <div className="text-left">
          <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>{' '}
          <span className="text-r42-blue text-xs">{moment(info.getValue()).format(TIME_FORMAT)}</span>
        </div>
      ),
      size: 200,
    }),
    columnHelper.accessor('dependants', {
      header: () => <div className="text-center">{i18n.t('filters:columns.dependants')}</div>,
      cell: ({ row }) => {
        const hasDependats = row.original.dependants.length > 0;
        return (
          <div className="text-center">
            <BtnIcon
              color={hasDependats ? 'blue' : 'gray'}
              icon="mappingsAndTransformations"
              onClick={() => setSelectedFilter(row.original)}
              testHook="filterDependants"
              tooltip={hasDependats ? i18n.t('filters:list.connector') : ''}
              disabled={!hasDependats}
            />
          </div>
        );
      },
      size: 100,
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => <div className="text-center">{i18n.t('workflow:table.header.actions')}</div>,
      cell: ({ row }) => {
        const actions = [
          {
            name: i18n.t('filters:list.edit'),
            tooltip: i18n.t('filters:list.edit'),
            icon: 'edit',
            onClick: () => {
              changeUrl(`profiles/filters/edit/${row.original.filterId}`);
            },
            testHook: 'editFilter',
          },
          {
            name: i18n.t(`filters:list.${row.original.dependants.length > 0 ? 'disableDelete' : 'delete'}`),
            tooltip: i18n.t(`filters:list.${row.original.dependants.length > 0 ? 'disableDelete' : 'delete'}`),
            isDisabled: row.original.dependants.length > 0,
            icon: 'delete',
            onClick: () => {
              setSelectedDeleteFilter(row.original);
            },
            testHook: 'deleteFilter',
          },
        ];
        return <div className="text-center">{canEditProfiles && <RowActions actions={actions} />}</div>;
      },
      size: 100,
    }),
  ];

  return (
    <div>
      <UITable
        data={filters ?? []}
        columns={getColumns()}
        emptyMessage={i18n.t('filters:list.emptyHeader')}
        emptyBody={i18n.t('filters:list.emptyBody')}
        testHook="filtersTable"
      />
      {selectedDeleteFilter && (
        <DeletePopup
          title={i18n.t('filters:list.deleteHeader')}
          hidePopup={() => setSelectedDeleteFilter(null)}
          onClick={() => handleDelete(selectedDeleteFilter.filterId)}
        >
          <Notification testHook="filterDeleteDescription">
            {i18n.t('filters:list.deleteBody', {
              name:
                selectedDeleteFilter.name.length > 50
                  ? `${selectedDeleteFilter.name.slice(0, 45)}...`
                  : selectedDeleteFilter.name,
            })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
}

export default FiltersTable;
