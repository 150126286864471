angular
    .module('webUi.ui.tagmanagement.tags.conditions.journey', [
        'webUi.common.Utils',
        'ui.validate'
    ])
    .constant('CONDITION_TYPES_CONSTANTS', {
        IN: 'IN',
        NOT_IN: 'NOT_IN',
        IN_ANY: 'IN_ANY'
    });
