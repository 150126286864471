/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_TAGS = `${API_BASE}/support/tagtemplates`;
const API_GROUPS = `${API_TAGS}/groups`;

/**
 * A service for Tag Templates related queries
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class SupportTagsClass {
  getGroup(groupId) {
    return Api.callGet(`${API_GROUPS}/${groupId}`).then(response => response.data);
  }

  getGroups() {
    return Api.callGet(`${API_GROUPS}`).then(response => response.data);
  }

  deleteGroup(groupId) {
    return Api.callDelete(`${API_GROUPS}/${groupId}`);
  }

  saveGroup(group) {
    return Api.callPost(`${API_GROUPS}/add`, group).then(response => response.data);
  }

  updateGroup(group) {
    return Api.callPost(`${API_GROUPS}/edit`, group).then(response => response.data);
  }
}

const supportTagsService = new SupportTagsClass();
export default supportTagsService;
