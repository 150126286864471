/**
 * @description Angular main module for Profiles -> Engagements -> Base (left)
 * @memberOf webUi.ui.profiles.engagements
 * @namespace webUi.ui.profiles.engagements.base
 */
angular.module('webUi.ui.profiles.engagements.base',
    [
        'webUi.ui.profiles.base'
    ]
)
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.engagements', {
                url: '/engagements',
                isReact : true,
            });
    }]);
