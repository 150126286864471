angular.module('webUi.directive.medialibraryFileChooser', ['webUi.service',  'ui.bootstrap'])

    .directive('medialibraryFileChooser', function() {
        return {
            restrict: 'E',
            scope: {
                mediaLibraryId: '=mediaLibraryId',
                displayFileSize: '=?displayFileSize',
                filterType: '=?filterType',
                onSelectFile: '=?'
            },
            templateUrl: 'directive/medialibraryFileChooser/medialibraryFileChooser.tpl.html',
            controller: 'MediaLibraryFileChooserController'
        };
    })

    .controller('MediaLibraryFileChooserController', ['$scope', '$uibModal', 'MediaLibraryService',
        function MediaLibraryFileChooserController ($scope, $uibModal, MediaLibraryService) {

            // HTML does not have restrictions
            var availableFilterTypes = {
                img: ['.jpg', '.jpeg','.gif','.png', '.bmp', '.tif', '.tiff'],
                flash: ['.swf']
            };

            // Assign the default values to scope unless they have been already specified
            _.defaults($scope, {
                locationBreadcrumbs: [],
                displayFileSize : false,
                filterType: ''
            });

            // get selectedFilters
            var selectedFilters = [];
            if(availableFilterTypes[$scope.filterType]) {
                selectedFilters = availableFilterTypes[$scope.filterType];
            }

            // Find the media library
            MediaLibraryService.getMediaLibrary($scope.mediaLibraryId).then(function(result) {
                $scope.mediaLibrary = result;
                // Filter the media files based on the specified filters (if any)
                if (!_.isEmpty($scope.filterType)) {
                    $scope.mediaLibrary.mediaFiles = filterMediaFiles($scope.mediaLibrary.mediaFiles, selectedFilters);
                }
            });

            /**
     * Lists the contents for the selected filepath and medialibrary
     * @param {type} mediaLibraryId
     * @param {type} filepath
     * @returns
     */
            $scope.list = function(mediaLibrary, filepath) {
                MediaLibraryService.getMediaLibrary(mediaLibrary.mediaLibraryId, filepath).then(function(result) {
                    $scope.mediaLibrary = result;
                    if (!_.isEmpty($scope.filterType)) {
                        $scope.mediaLibrary.mediaFiles = filterMediaFiles($scope.mediaLibrary.mediaFiles, selectedFilters);
                    }
                    updateBreadcrumbs($scope.mediaLibrary, filepath);
                });
            };

            var updateBreadcrumbs = function(mediaLibrary, filepath) {
                $scope.locationBreadcrumbs = MediaLibraryService.getLocationBreadcrumbs(mediaLibrary, filepath, false);
            };

            /**
     * Filters the provided mediafies based on their extension and the provided filter
     * @param {type} mediaFiles - the files to be filtered
     * @param {type} extensionsToFilter - array of file extensions to filter on
     * @returns {Array} the filtered result (including folders)
     */
            var filterMediaFiles = function(mediaFiles, extensionsToFilter) {

                // nothing to filter
                if(extensionsToFilter.length <= 0) {
                    return mediaFiles;
                }

                var filteredFiles = [];
                _.forEach(mediaFiles, function(mediaFile) {
                    // Check if the file passes the filter
                    if (!mediaFile.folder) {
                        var extension = mediaFile.name.substring(mediaFile.name.lastIndexOf('.'));
                        if (_.contains(extensionsToFilter, extension)) {
                            filteredFiles.push(mediaFile);
                        }
                    } else {
                        // Add the folder
                        filteredFiles.push(mediaFile);
                    }
                });
                return filteredFiles;
            };
        }]);
