import React from 'react';
import PropTypes from 'prop-types';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { compose } from 'recompose';
import classNames from 'classnames';
import Link from '~/components/src/Link';
import { components, enhancements } from '~/components/src/Table';
import { ITEM_TYPES } from '~/profiles/audiencesTree';
import { Panel } from '~/components/src/Containers';
import UsageIcons from '~/profiles/components/UsageIcons';
import Icons from '~/components/src/Icons';
import DndSource, { defaultDragConnect } from '../DragComponent';
import DndTarget, { defaultDropConnect } from '../DropComponent';
import { renderValue } from './renderValue';
import { renderHeader } from './header';
import './AudienceList.scss';

const { Table, Row, Cell, RowActions } = components;
const { withBackendPaginate } = enhancements;

const WIDTHS = {
  NAME: 'u-size2of10',
  SIZE: 'u-size1of10',
  ACTIVITIES: 'u-size2of10',
  ACTIVITY: 'u-size1of10',
  USED_IN: 'u-size1of10',
};

const audienceSource = {
  beginDrag(props) {
    return {
      audience: props.item,
    };
  },
};

const isAudience = item => item.type === ITEM_TYPES.SELECTION;
const isGroup = item => item.type === ITEM_TYPES.GROUP;

const StatCell = ({ type = '', audienceListItem, days }) => {
  const typesMap = {
    add: 'totalCallsAdded',
    remove: 'totalCallsRemoved',
  };

  return (
    <Cell
      className={classNames('u-alignCenter', {
        'u-green': type === 'add',
        'u-red': type === 'remove',
      })}
    >
      {isAudience(audienceListItem) && renderValue(audienceListItem, days, typesMap[type])}
    </Cell>
  );
};

export const AudienceList = ({
  audienceUrl,
  actions,
  groupUrl,
  onMoveAudience,
  list,
  stateKey,
  t,
  onDependantIconClick,
}) => {
  const groupTarget = {
    drop(props, monitor) {
      const audience = monitor.getItem();
      onMoveAudience(props.group, audience.audience);
    },
  };

  const MoveSource = DragSource(ITEM_TYPES.SELECTION, audienceSource, defaultDragConnect)(DndSource);
  const MoveTarget = DropTarget(ITEM_TYPES.SELECTION, groupTarget, defaultDropConnect)(DndTarget);

  const SourceCell = ({ audienceUrl, item }) => (
    <MoveSource item={item}>
      <div className="flex items-center gap-x-2">
        <Icons icon="audience" className="h-6 w-6 shrink-0 text-gray-400" />
        <Link href={audienceUrl(item.id)}>{item.groupName ? `${item.groupName}: ${item.name}` : item.name}</Link>
      </div>
    </MoveSource>
  );

  const TargetCell = ({ groupUrl, item }) => (
    <MoveTarget group={item}>
      <div className="flex items-center gap-x-2">
        <Icons icon="folder" className="h-6 w-6 shrink-0 text-gray-400" />
        <Link href={groupUrl(item.id)}>{item.name}</Link>
        {item.isNew && <span className="Badge Badge--success u-marginLeftM">New</span>}
      </div>
    </MoveTarget>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Panel>
        <Table stateKey={stateKey} isTable={true}>
          <colgroup>
            <col className={WIDTHS.NAME} />
            <col className={WIDTHS.SIZE} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.ACTIVITY} />
            <col className={WIDTHS.USED_IN} />
            <col className="Table-actions" />
          </colgroup>
          {renderHeader(t)}
          <tbody>
            {list.map(item => (
              <Row key={item.id} withActions>
                <Cell>
                  {isAudience(item) && <SourceCell audienceUrl={audienceUrl} item={item} />}
                  {isGroup(item) && <TargetCell groupUrl={groupUrl} item={item} />}
                </Cell>
                <StatCell audienceListItem={item} />
                <StatCell type="add" audienceListItem={item} days={1} />
                <StatCell type="remove" audienceListItem={item} days={1} />
                <StatCell type="add" audienceListItem={item} days={7} />
                <StatCell type="remove" audienceListItem={item} days={7} />
                <StatCell type="add" audienceListItem={item} days={30} />
                <StatCell type="remove" audienceListItem={item} days={30} />
                <Cell className={classNames(WIDTHS.USEDIN, 'u-alignCenter')}>
                  <UsageIcons
                    dependantTypes={item.dependantTypes}
                    elementUsedIn={['SELECTION']}
                    elementId={item.partnerId}
                    onDependantIconClick={() => onDependantIconClick(item.id)}
                  />
                </Cell>
                <RowActions actions={actions(item)} />
              </Row>
            ))}
          </tbody>
        </Table>
      </Panel>
    </DndProvider>
  );
};

AudienceList.propTypes = {
  actions: PropTypes.func,
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  audienceUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withBackendPaginate())(AudienceList);
