import React from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import Link from '~/components/src/Link';
import { buildUrl } from '~/common';
import i18n from '../i18n';

interface ContentType {
  clientName: string | null;
  lastLoginDate: number | null;
  lock: { locked: boolean; reason: string };
  name: string;
  userId: string;
  username: string;
}
interface UsersTableProps {
  content: ContentType[];
  page: number;
  totalPages: number;
  setPage: () => void;
  emptyBody: string;
  emptyMessage: string;
  setSort: () => void;
  sort: [];
}

const columnHelper = createColumnHelper<ContentType>();

export const UsersTable = ({
  content,
  page,
  totalPages,
  setPage,
  emptyBody,
  emptyMessage,
  setSort,
  sort,
}: UsersTableProps): React.ReactElement => {
  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="inline-block text-left">{i18n.t('users:list.name')}</div>,
      cell: info => (
        <div className="flex items-center text-left">
          <Link href={buildUrl(`context/users/view/${info?.row?.original.userId}`)}>{info?.row?.original.name}</Link>
          {info?.row?.original.lock?.locked && (
            <Icons className="ml-1 h-5 w-5 text-red-500" icon="lock" tooltip={info?.row?.original.lock.reason} />
          )}
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('username', {
      header: () => <div className="inline-block text-left">{i18n.t('users:list.username')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 400,
    }),
    columnHelper.accessor('lastLoginDate', {
      header: () => <div className="inline-block text-left">{i18n.t('users:list.lastLogin')}</div>,
      cell: info => (
        <div className=" text-left">
          {info.getValue() ? (
            <span>
              {moment(info.getValue()).format('DD/MM/YYYY')}{' '}
              <span className="text-sm text-r42-blue">{moment(info.getValue()).format('HH:mm:ss')}</span>
            </span>
          ) : (
            '-'
          )}
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('clientName', {
      header: () => <div className="inline-block text-left">{i18n.t('users:list.clientName')}</div>,
      cell: info => <div className="text-left">{info.getValue() || '-'}</div>,
      size: 400,
      enableSorting: false,
    }),
  ];

  return (
    <UITable
      testHook="users"
      data={content}
      columns={getColumns()}
      enablePagination
      enableBackendSorting
      sort={sort}
      setSort={setSort}
      totalPages={totalPages}
      page={page}
      onPaginate={setPage}
      emptyMessage={emptyMessage}
      emptyBody={emptyBody}
    />
  );
};

export default UsersTable;
