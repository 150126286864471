import { useReducer } from 'react';
import { PathDataType, PayloadType } from './types';
import { ActionTypes } from './constants';

const reducer = (state: PathDataType, payload: PayloadType): PathDataType => {
  switch (payload.action) {
    case ActionTypes.ADD_PATH:
      return {
        ...state,
        subPaths: state.subPaths.map(subPath =>
          subPath.name === payload.path ? { ...subPath, exists: true } : { ...subPath },
        ),
      };

    case ActionTypes.REMOVE_PATH:
      return {
        ...state,
        subPaths: state.subPaths.map(subPath =>
          subPath.name === payload.path ? { ...subPath, exists: false } : { ...subPath },
        ),
      };

    case ActionTypes.ADD_DATA:
      return payload.data;

    case ActionTypes.SHOW_MORE:
      return { ...state, subPaths: [...state.subPaths, ...payload.subPaths] };
    default:
      return state;
  }
};

export const useIndexedPaths = (): { state: PathDataType; dispatch: React.Dispatch<PayloadType> } => {
  const [state, dispatch] = useReducer(reducer, {} as PathDataType);

  return { state, dispatch };
};
