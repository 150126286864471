import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import { numberRange, isRequired } from '~/common';
import { CHAR_SET_SELECT } from '~/context/clients';

const minCharsRangeValidation = numberRange(12, 32);
const rotationPeriod = numberRange(1, 365);
const rotationHistory = numberRange(1, 15);

export const PasswordPolicyForm = ({ t, handleSubmit, submitting, backHref, touch }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        label={t('clients:view.modals.passwordPolicy.form.minChars.label')}
        name="minChars"
        component={ReduxFormInputField}
        placeholder={t('clients:view.modals.passwordPolicy.form.minChars.placeholder')}
        validate={[isRequired, minCharsRangeValidation]}
        type="number"
      />
      <Field
        label={t('clients:view.modals.passwordPolicy.form.charSet.label')}
        name="charSet"
        component={ReduxFormSelectField}
        options={CHAR_SET_SELECT}
        hint={t('clients:view.modals.passwordPolicy.form.charSet.hint')}
        touch={touch}
      />
      <Field
        label={t('clients:view.modals.passwordPolicy.form.rotationPeriod.label')}
        name="rotationPeriod"
        component={ReduxFormInputField}
        placeholder={t('clients:view.modals.passwordPolicy.form.rotationPeriod.placeholder')}
        validate={rotationPeriod}
        type="number"
        hint={t('clients:view.modals.passwordPolicy.form.rotationPeriod.hint')}
      />
      <Field
        label={t('clients:view.modals.passwordPolicy.form.rotationHistory.label')}
        name="rotationHistory"
        component={ReduxFormInputField}
        placeholder={t('clients:view.modals.passwordPolicy.form.rotationHistory.placeholder')}
        validate={rotationHistory}
        type="number"
        hint={t('clients:view.modals.passwordPolicy.form.rotationHistory.hint')}
      />
      <Field
        label={t('clients:view.modals.passwordPolicy.form.invalidatePassword.label')}
        name="invalidatePasswords"
        component={ReduxFormCheckboxField}
      />
    </div>
    <FormActions t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

PasswordPolicyForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  backHref: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'passwordPolicyForm', touchOnChange: true, touchOnBlur: true }))(
  PasswordPolicyForm,
);
