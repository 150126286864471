/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_COOKIES = `${API_BASE}/support/cookiepermissionforms`;
export class CookiesVariantsClass {
  getVariants() {
    return Api.callGet(API_COOKIES).then(response => response.data);
  }

  getVariant(variantId) {
    return Api.callGet(`${API_COOKIES}/${variantId}`).then(response => response.data);
  }

  createVariant(variant) {
    return Api.callPost(API_COOKIES, variant).then(response => response.data);
  }

  editVariant(variant) {
    return Api.callPost(`${API_COOKIES}/edit`, variant).then(response => response.data);
  }

  unRestrictClient(cookiePermissionVariantId, clientId) {
    const body = {
      cookiePermissionVariantId,
      clientId,
    };
    return Api.callPost(`${API_COOKIES}/unrestrictClient`, body).then(response => response.data);
  }

  restrictClients(cookiePermissionVariantId, clientIds) {
    return Api.callPost(`${API_COOKIES}/restrictClients`, {
      cookiePermissionVariantId,
      clientIds,
    }).then(response => response.data);
  }
}

const cookiesVariantsService = new CookiesVariantsClass();
export default cookiesVariantsService;
