import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { buildUrl, getParam } from '~/common';
import {
  saveTemplate,
  fetchTagContentTypesIfNeeded,
  fetchPlatformTypesIfNeeded,
} from '~/support/tagtemplates/templates/actions';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import { getGroupsSelect } from '~/support/tagtemplates/groups/selectors';
import { fetchGroups } from '~/support/tagtemplates/groups/actions';
import * as selectors from '~/support/tagtemplates/templates/selectors';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import TagTemplateForm from '~/support/components/TagTemplateForm';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';

const FORM_ADD = 'tagTemplateNew';

export class TagTemplateAdd extends Component {
  componentDidMount() {
    this.props.fetchPlatformTypes();
    this.props.fetchTagContentTypes();
    if (this.props.isGroupEditable) {
      this.props.fetchGroups();
    }
  }

  render() {
    const { t, ...other } = this.props;

    return (
      <Page>
        <MediumWrapper>
          <Heading title={t('templateNew.header')} />
          <Panel>
            <TagTemplateForm t={t} {...other} />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const groupId = getParam(ownProps.location.search, 'groupId');
      const cancelHref = groupId
        ? buildUrl(`support/tagtemplates/groups/view/${groupId}`)
        : buildUrl('support/tagtemplates');
      const groups = groupId ? [] : getGroupsSelect(state);
      return {
        isGroupEditable: !groupId,
        groups,
        initialValues: {
          groupId,
        },
        cancelHref,
        contentTypes: selectors.getTagContentTypes(state),
        platformTypes: selectors.getPlatformTypes(state),
      };
    },
    dispatch => ({
      fetchGroups: () => dispatch(fetchGroups()),
      fetchPlatformTypes: () => dispatch(fetchPlatformTypesIfNeeded()),
      fetchTagContentTypes: () => dispatch(fetchTagContentTypesIfNeeded()),
      onSubmit: values => dispatch(saveTemplate(values)),
    }),
  ),
  reduxForm({
    form: FORM_ADD,
    enableReinitialize: true,
  }),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagTemplateAdd);
