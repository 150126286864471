import React, { useState, useContext } from 'react';
import { PartnerTypeListItem, ConnectorListItem } from '~/common/modules/connectors/components/ConnectorPicker/types';
import { SelectedConnector } from '~/components/src/ConnectorPickerCard/types';
import { SetIsPickerVisibleContext } from '~/components/src/RuleConnectorPicker/context';
import ConnectorPickerCard from '../ConnectorPickerCard';
import Empty from '../Empty';
import SearchElement from '../Form/Elements/SearchElement';
import './style.scss';

interface ConnectorCardGridProps {
  listItems: (PartnerTypeListItem | ConnectorListItem)[];
  isSearchable: boolean;
  searchPlaceholder: string;
  onSelect: (selectedConnector: SelectedConnector) => void;
  emptyMessage?: string;
  emptyHeader?: string;
  emptyBody?: string;
  isEventType?: boolean;
  areDisabledItemsVisible?: boolean;
}

const ConnectorCardGrid = (props: ConnectorCardGridProps): React.ReactElement => {
  const {
    listItems,
    isSearchable = false,
    searchPlaceholder = '',
    onSelect,
    emptyMessage = '',
    areDisabledItemsVisible = false,
    isEventType = false,
  } = props;

  const setIsPickerVisible = useContext(SetIsPickerVisibleContext);

  const [filter, setFilter] = useState('');

  const filteredConnectors = listItems
    .filter(
      connector =>
        `${connector.partnerNumber}`.includes(filter.trim().toLowerCase()) ||
        connector.title.toLowerCase().includes(filter.trim().toLowerCase()),
    )
    .filter(connector => {
      if (areDisabledItemsVisible) {
        return true;
      }
      return !connector.isDisabled;
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      {isSearchable && (
        <SearchElement
          className="mb-6"
          testHook="searchConnectors"
          placeholder={searchPlaceholder}
          onChange={e => setFilter(e.target.value)}
          value={filter}
        />
      )}
      <div className="ConnectorCardGrid">
        {filteredConnectors.map(connector => (
          <ConnectorPickerCard
            {...connector}
            onSelect={onSelect}
            setIsPickerVisible={setIsPickerVisible}
            testHook={connector.partnerType}
            isEventType={isEventType}
            key={connector.key}
          />
        ))}
      </div>
      {!filteredConnectors.length && <Empty excludePanel body={emptyMessage} />}
    </>
  );
};

export default ConnectorCardGrid;
