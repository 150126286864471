import React from 'react';
import Icon from 'react-icon-base';

const R42Expired = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M.5 17.692h1.787a.247.247 0 0 1 .246.226l.438 5.259a.5.5 0 0 0 .493.454h3.96a.5.5 0 0 0 .493-.454l.438-5.259a.247.247 0 0 1 .247-.226h.573a.251.251 0 0 0 .177-.075.246.246 0 0 0 .07-.18 7.912 7.912 0 0 1 1.315-4.611.242.242 0 0 0 .036-.187A5.442 5.442 0 0 0 0 13.732V17.2a.5.5 0 0 0 .5.492z" />
    <circle cx="5.444" cy="3.834" r="3.464" />
    <path d="M10.815 18.685a6.482 6.482 0 0 0 4.973 4.78 7.233 7.233 0 0 0 1.532.165 6.77 6.77 0 0 0 4.859-2.015 6.324 6.324 0 0 0 1.649-5.915 6.483 6.483 0 0 0-4.975-4.776 6.85 6.85 0 0 0-6.388 1.85 6.327 6.327 0 0 0-1.65 5.911zm7.621-5.822a4.442 4.442 0 0 1 1.1.4.247.247 0 0 1 .058.4l-6 5.743a.247.247 0 0 1-.384-.052 3.952 3.952 0 0 1-.458-1.116 4.3 4.3 0 0 1 1.135-4.069 4.806 4.806 0 0 1 3.45-1.42 5.254 5.254 0 0 1 1.099.114zm-2.23 8.667a4.485 4.485 0 0 1-1.093-.395.248.248 0 0 1-.058-.4l6-5.743a.249.249 0 0 1 .385.053 4.027 4.027 0 0 1 .457 1.112 4.3 4.3 0 0 1-1.134 4.071 4.893 4.893 0 0 1-4.557 1.302z" />
    <path d="M.5 17.692h1.787a.247.247 0 0 1 .246.226l.438 5.259a.5.5 0 0 0 .493.454h3.96a.5.5 0 0 0 .493-.454l.438-5.259a.247.247 0 0 1 .247-.226h.573a.251.251 0 0 0 .177-.075.246.246 0 0 0 .07-.18 7.912 7.912 0 0 1 1.315-4.611.242.242 0 0 0 .036-.187A5.442 5.442 0 0 0 0 13.732V17.2a.5.5 0 0 0 .5.492z" />
    <circle cx="5.444" cy="3.834" r="3.464" />
    <path d="M10.815 18.685a6.482 6.482 0 0 0 4.973 4.78 7.233 7.233 0 0 0 1.532.165 6.77 6.77 0 0 0 4.859-2.015 6.324 6.324 0 0 0 1.649-5.915 6.483 6.483 0 0 0-4.975-4.776 6.85 6.85 0 0 0-6.388 1.85 6.327 6.327 0 0 0-1.65 5.911zm7.621-5.822a4.442 4.442 0 0 1 1.1.4.247.247 0 0 1 .058.4l-6 5.743a.247.247 0 0 1-.384-.052 3.952 3.952 0 0 1-.458-1.116 4.3 4.3 0 0 1 1.135-4.069 4.806 4.806 0 0 1 3.45-1.42 5.254 5.254 0 0 1 1.099.114zm-2.23 8.667a4.485 4.485 0 0 1-1.093-.395.248.248 0 0 1-.058-.4l6-5.743a.249.249 0 0 1 .385.053 4.027 4.027 0 0 1 .457 1.112 4.3 4.3 0 0 1-1.134 4.071 4.893 4.893 0 0 1-4.557 1.302z" />
  </Icon>
);

export default R42Expired;
