import { Field, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useContext, useState } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { buildUrl, useAPI, changeUrl } from '~/common';
import { Heading, Notification, PickerView, RuleCardGrid } from '~/components';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { addIcons } from '~/common/modules/rules/components/RulePicker';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import Spin from '~/components/src/Spin';
import BtnOutlined from '~/components/src/BtnOutlined';
import { showSuccess } from '~/notificationCenter';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import { createTransformation, getTransformation, updateTransformation } from './dataService';
import { getRulesDefinitions } from '../Filters/dataService';
import { addEvent, mapTransformationEventsPayload } from './core/util';
import { makeTransformationSchema } from './core/validation';
import { TransformationContext } from './context';
import ValueTransformationsModal from './components/ValueTransformationsModal';
import { EventType, TransformationPayload } from './types';
import Event from './components/Events';
import { EventType as Rule } from '../Filters/types';

function TransformationEdit(): ReactElement {
  const [isEventPickerVisible, setIsEventPickerVisible] = useState<boolean>(false);
  const [mappingPath, setMappingPath] = useState<string>('');

  const params = useParams();
  const transformationId = params.transformationId || '';

  const contextValues = useContext(TransformationContext);
  const { transformations: existingTransformations, refetchTransformations } = contextValues;

  const { data: rulesTree, isLoading: isRulesLoading } = useAPI(async () => {
    const ruleDefinitions = await getRulesDefinitions();
    return getRulesTreeFromTypes(ruleDefinitions);
  });

  const { data: transformation } = useAPI(() => {
    if (transformationId) {
      return getTransformation(transformationId);
    }
    return Promise.resolve(null);
  }, [transformationId]);

  const initialValues = { name: transformation?.name || '', events: transformation?.events || [] };
  const formSchema = makeTransformationSchema(
    existingTransformations.filter(transformation => transformation.transformationId !== transformationId),
  );

  const handleSubmit = async (values: { name: string; events: EventType[] }) => {
    const payload = {
      name: values.name.trim(),
      events: mapTransformationEventsPayload(values.events),
    };

    if (transformationId) {
      await updateTransformation(transformationId, payload);
      showSuccess({ header: i18n.t('transformations:form.updateSuccess') });
      changeUrl(`profiles/transformations/view/${transformationId}`);
    } else {
      await createTransformation(payload);
      showSuccess({ header: i18n.t('transformations:form.createSuccess') });
      changeUrl('profiles/transformations');
      refetchTransformations();
    }
  };

  const newEvent = (
    selectedRule: Rule,
    events: EventType[],
    setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'],
  ) => {
    setIsEventPickerVisible(false);
    setFieldValue('events', [...events, addEvent(selectedRule)]);
  };

  const removeEvent = (
    selectedEventIndex: number,
    events: EventType[],
    setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'],
  ) => {
    events.splice(selectedEventIndex, 1);
    setFieldValue('events', events);
  };

  if (isRulesLoading) return <Spin />;

  const rulesWithIcons = addIcons(rulesTree);

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50 pb-6">
      <div className="flex w-2/3 flex-col">
        <Heading
          title={i18n.t(`transformations:form.${transformationId ? 'edit' : 'create'}`)}
          crumbs={[
            {
              title: i18n.t('transformations:back'),
              pathname: buildUrl('profiles/transformations'),
            },
          ]}
        />
        <Notification kind="information">
          <p>{i18n.t('transformations:form.message')}</p>
        </Notification>
        <div className="mt-4 flex flex-col gap-4">
          <TransformationContext.Provider value={{ ...contextValues, setMappingPath }}>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={formSchema}
              validateOnMount
              initialValues={initialValues}
              enableReinitialize
            >
              {({ handleSubmit, errors, isValid, isSubmitting, touched, values, setFieldValue }) => (
                <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                  <label>{i18n.t('transformations:form.nameLabel')}</label>
                  <Field
                    as={FormikInputField}
                    disabled={isSubmitting}
                    name="name"
                    className="w-2/5"
                    placeholder={i18n.t('transformations:form.namePlaceholder')}
                    errorText={touched.name && errors.name}
                  />

                  <div className="flex flex-col gap-2">
                    {values.events?.map((event, eventIndex) => (
                      <Event
                        key={eventIndex}
                        event={event}
                        eventPath={`events[${eventIndex}]`}
                        deleteEvent={() => removeEvent(eventIndex, values.events, setFieldValue)}
                      />
                    ))}
                  </div>
                  {!isValid && values.events.length === 0 && (
                    <Notification kind="error">{i18n.t('transformations:messages.minOneEvent')}</Notification>
                  )}
                  <div className="mt-8 flex items-center justify-between">
                    <BtnOutlined
                      icon="add"
                      size="xs"
                      onClick={() => setIsEventPickerVisible(!isEventPickerVisible)}
                      disabled={values.events.length >= 1}
                      tooltip={values.events.length >= 1 ? i18n.t('transformations:messages.canHaveOneEvent') : ''}
                      testHook="addEvent"
                    >
                      {i18n.t('transformations:form.addEvent')}
                    </BtnOutlined>
                    <Btn color="blue" type="submit" testHook="addUpdateTransformation" disabled={!isValid}>
                      {i18n.t(`transformations:form.${transformationId ? 'update' : 'create'}`)}
                    </Btn>
                  </div>

                  {isEventPickerVisible && (
                    <PickerView
                      pickerTitle={i18n.t('transformations:form.addEvent')}
                      handlePickerVisibility={() => setIsEventPickerVisible(!isEventPickerVisible)}
                    >
                      <RuleCardGrid
                        menuTree={rulesWithIcons}
                        isSearchable={true}
                        searchPlaceholder={i18n.t('common:actions.search')}
                        onSelect={(event: Rule) => newEvent(event, values.events, setFieldValue)}
                        emptyMessage={i18n.t('list.emptySearch')}
                      />
                    </PickerView>
                  )}

                  {mappingPath && (
                    <ValueTransformationsModal
                      mapping={get(values, mappingPath)}
                      mappingPath={mappingPath}
                      closeValueTransformationsModal={() => setMappingPath('')}
                    />
                  )}
                </form>
              )}
            </Formik>
          </TransformationContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default TransformationEdit;
