export const SITES_FETCH_START = 'app/context/sites/SITES_FETCH_START';
export const SITES_FETCH_FAIL = 'app/context/sites/SITES_FETCH_FAIL';
export const SITES_FETCH_SUCCESS = 'app/context/sites/SITES_FETCH_SUCCESS';

export const SITE_FETCH_START = 'app/context/sites/SITE_FETCH_START';
export const SITE_FETCH_FAIL = 'app/context/sites/SITE_FETCH_FAIL';
export const SITE_FETCH_SUCCESS = 'app/context/sites/SITE_FETCH_SUCCESS';

export const SITE_DELETE_START = 'app/context/sites/SITE_DELETE_START';
export const SITE_DELETE_FAIL = 'app/context/sites/SITE_DELETE_FAIL';
export const SITE_DELETE_SUCCESS = 'app/context/sites/SITE_DELETE_SUCCESS';

export const TIMEZONES_FETCH_START = 'app/context/sites/TIMEZONES_FETCH_START';
export const TIMEZONES_FETCH_FAIL = 'app/context/sites/TIMEZONES_FETCH_FAIL';
export const TIMEZONES_FETCH_SUCCESS = 'app/context/sites/TIMEZONES_FETCH_SUCCESS';

export const SITE_MODULES_FETCH_START = 'app/context/sites/SITE_MODULES_FETCH_START';
export const SITE_MODULES_FETCH_FAIL = 'app/context/sites/SITE_MODULES_FETCH_FAIL';
export const SITE_MODULES_FETCH_SUCCESS = 'app/context/sites/SITE_MODULES_FETCH_SUCCESS';

export const SET_FILTER = 'app/context/sites/SET_FILTER';
export const REMOVE_FILTER = 'app/context/sites/REMOVE_FILTER';

export const SAVE_START = 'app/context/sites/SAVE_START';
export const SAVE_SUCCESS = 'app/context/sites/SAVE_SUCCESS';
export const SAVE_FAIL = 'app/context/sites/SAVE_FAIL';

export const STATS_FETCH_START = 'app/context/sites/STATS_FETCH_START';
export const STATS_FETCH_SUCCESS = 'app/context/sites/STATS_FETCH_SUCCESS';
export const STATS_FETCH_FAIL = 'app/context/sites/STATS_FETCH_FAIL';

export const SITE_TRUNCATE_START = 'app/context/sites/SITE_TRUNCATE_START';
export const SITE_TRUNCATE_SUCCESS = 'app/context/sites/SITE_TRUNCATE_SUCCESS';
export const SITE_TRUNCATE_FAIL = 'app/context/sites/SITE_TRUNCATE_FAIL';

export const SITE_TRUNCATE_CONFIRMATION = '4242';
export const SITE_TRUNCATE_FORM = 'app/context/sites/SITE_TRUNCATE_FORM';
