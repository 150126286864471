import { get } from 'lodash';

const fetchingPayloadTypes = state => get(state, 'profiles.connectorsForm.promise.fetchingPayloadTypes');
const fetchingConsentTypes = state => get(state, 'profiles.connectorsForm.promise.fetchingConsentTypes');
const getPayloadTypes = state =>
  get(state, 'profiles.connectorsForm.payloadTypes').map(type => ({ ...type, value: type.name }));
const getConsentTypes = state =>
  get(state, 'profiles.connectorsForm.consentTypes').map(type => ({ ...type, value: type.name }));
const fetchingPartnerInformation = state => get(state, 'profiles.connectorsForm.promise.fetchingPartnerInformation');
const getPartnerInformation = state => get(state, 'profiles.connectorsForm.partnerInformation');

export {
  fetchingPayloadTypes,
  fetchingConsentTypes,
  getPayloadTypes,
  getConsentTypes,
  fetchingPartnerInformation,
  getPartnerInformation,
};
