import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Notification from '~/components/src/Notification';
import { useAPI } from '~/common/ApiHooks';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import * as ApiService from './service';
import './styles.scss';

const initialValues = {
  name: '',
  categoryId: '',
};

const makeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('validation:validation.required')),
    categoryId: Yup.string().trim().required(t('validation:validation.required')),
  });

const AddSegmentForm = ({ partnerId, selectNewlyCreatedSegment, hideModal, t }) => {
  const validationSchema = makeValidationSchema(t);

  const submitForm = values => {
    const segment = {
      name: values.name,
      categoryId: values.categoryId.id,
    };
    return ApiService.createSegment(partnerId, segment, t).then(createdSegment => {
      selectNewlyCreatedSegment(createdSegment);
      hideModal();
    });
  };

  const { data: categoryOptions, error: categoriesAPIError } = useAPI(() => ApiService.fetchCategories(partnerId), []);

  return (
    <Formik onSubmit={submitForm} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, touched, isValid, isSubmitting }) => (
        <>
          <Field
            name="name"
            as={FormikInputField}
            label={t('connectors.adform.categoryName')}
            placeholder={t('connectors:common.commonField.pleaseProvideValue')}
            errorText={touched.name && errors.name}
            autoFocus={true}
          />
          <Field
            name="categoryId"
            as={FormikSelectField}
            label={t('connectors.adform.categoryId')}
            errorText={touched.categoryId && errors.categoryId}
            options={categoryOptions || []}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            testHook="categorySelect"
          />
          {categoriesAPIError && (
            <Notification className="u-marginTopL" kind="error" header="">
              {t('connectors.adform.categoriesApiError')}
            </Notification>
          )}
          <ActionButtons
            className="mr-0"
            onConfirm={handleSubmit}
            onDecline={hideModal}
            isConfirmEnabled={isValid && !isSubmitting}
            testHook="addSegmentFormButtons"
          />
        </>
      )}
    </Formik>
  );
};

export default AddSegmentForm;
