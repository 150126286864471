import { get } from 'lodash';

export const getTemplateById = (state, templateId) => get(state, `support.tags.templates.byId[${templateId}]`);

export const getTemplateVariants = (state, templateId) => {
  const template = getTemplateById(state, templateId);
  return template && template.variants && template.variants.sort((a, b) => a.sortingOrder - b.sortingOrder);
};

export const showDeleted = state => get(state, 'support.tags.templates.ui.showDeleted');

export const getTagContentTypes = state => get(state, 'support.tags.templates.tagContentTypes');

export const getPlatformTypes = state => get(state, 'support.tags.templates.platformTypes');
