/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_USERS = `${API_BASE}/context/users`;

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ContextUserClientsClass {
  getClientsForUser(userId) {
    return Api.callGet(`${API_USERS}/${userId}/clientAccounts`).then(response => response.data);
  }

  demoteUserFromClientManager(model) {
    return Api.callPost(`${API_USERS}/demoteUserFromClientAccountManager`, model).then(response => response.data);
  }

  promoteUserToClientManager(model) {
    return Api.callPost(`${API_USERS}/promoteUserToClientAccountManager`, model).then(response => response.data);
  }
}

const contextUserClientsService = new ContextUserClientsClass();
export default contextUserClientsService;
