import { createContext } from 'react';
import { FilterListType } from './types';

type ContextType = {
  canEditProfiles: boolean;
  filters: FilterListType[];
  refetchFilters: () => void;
};

const defaultValues = {
  canEditProfiles: false,
  filters: [],
  refetchFilters: () => {
    // intentionally left empty
  },
};

export const FiltersContext = createContext<ContextType>(defaultValues);
