import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Page } from '~/components';
import i18n from '~/i18n';
import { buildUrl } from '~/common';
import { showSuccess } from '~/notificationCenter';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import YahooForm from './yahoo/YahooForm';
import DoubleClickForm from './doubleClick/DoubleClickForm';
import { TDCAdvertiser, TYahooCreatePayload } from './types';
import { createDCAdvertiser, createYahooAdvertiser } from './dataService';
import { ADVERTISERS } from './constants';

const AdvertisersFormCreate = () => {
  const params = useParams();
  const navigate = useNavigate();

  const connectorName = params.connector || '';

  const isYahoo = connectorName.toLocaleUpperCase() === ADVERTISERS.yahoo;
  const isDoubleClick = connectorName.toLocaleUpperCase() === ADVERTISERS.doubleClick;

  const onSubmit = async (payload: TYahooCreatePayload | TDCAdvertiser) => {
    switch (connectorName.toLocaleUpperCase()) {
      case ADVERTISERS.doubleClick:
        await createDCAdvertiser(payload as TDCAdvertiser);
        showSuccess({ body: i18n.t('connectors:advertisers.doubleClick.api.created') });
        navigate(buildUrl('admin/connectors?tabIndex=0'));
        break;
      case ADVERTISERS.yahoo:
        await createYahooAdvertiser(payload as TYahooCreatePayload);
        showSuccess({ body: i18n.t('connectors:advertisers.yahoo.api.created') });
        navigate(buildUrl('admin/connectors?tabIndex=1'));
        break;
    }
  };

  return (
    <Page>
      <SmallWrapper>
        <Heading kind="h2" title={i18n.t('connectors:advertisers.form.new')}></Heading>
        <Panel>
          {isYahoo && <YahooForm onSubmit={onSubmit} />}
          {isDoubleClick && <DoubleClickForm isEdit={false} onSubmit={onSubmit} />}
        </Panel>
      </SmallWrapper>
    </Page>
  );
};

export default AdvertisersFormCreate;
