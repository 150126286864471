import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAngularService } from 'ReactAngular/react.service';
import Spinner from '~/components/src/Spinner';
import PrivateRoute from '../../components/src/PrivateRoute/index';
import UrlBuilderPage from './UrlBuilder';
import EnvironmentsViewPage from './Environments';
import NewEnvironmentPage from './NewEnvironment';
import EditEnvironmentPage from './EditEnvironment';
import NewColumnPage from './NewColumn';
import EditColumnPage from './EditColumn';
import NewTypePage from './NewType';
import EditTypePage from './EditType';
import NewValueSetsPage from './NewValueSets';
import EditValueSetsPage from './EditValueSets';
import NewConfigurationPage from './NewConfiguration';
import EditConfigurationPage from './EditConfiguration';
import UsersPage from './Users';
import UsersAddPage from './UsersAdd';
import EditSiteSecurityPage from './EditSiteSecurity';
import SiteSecurityPage from './SiteSecurity';
import IdentitiesPage from './Identities';
import Imports from './Imports';
import AddImports from './Imports/AddImports';
import AdvertisersFormCreate from './advertisers/AdvertisersFormCreate';
import AdvertisersFormEdit from './advertisers/AdvertisersFormEdit';
import Advertisers from './advertisers';

class AdminPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSupportUser: false,
      isSiteAdmin: false,
      isSupportUserPermFetched: false,
      isSiteAdminPermFetched: false,
    };
  }
  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isSupportUser: context.isSupportEngineer(),
        isContextAdmin: context.isContextAdmin(),
        isSupportUserPermFetched: true,
        isSiteAdmin: context.hasPermission('SITEADMIN'),
        isSiteAdminPermFetched: true,
        loggedInUser: context.user,
      });
    });
  }

  render() {
    if (!this.state.isSupportUserPermFetched || !this.state.isSiteAdminPermFetched) {
      return <Spinner />;
    }
    const { isContextAdmin, isSupportUser, isSiteAdmin, loggedInUser } = this.state;
    return (
      <Routes>
        <Route path="environments" element={<EnvironmentsViewPage isSiteAdmin={isSiteAdmin} />} />
        <Route
          path="environments/add"
          element={<PrivateRoute authenticated={isSiteAdmin} component={NewEnvironmentPage} />}
        />
        <Route
          path="environments/edit/:environmentId"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditEnvironmentPage} />}
        />
        <Route path="urlbuilder" element={<UrlBuilderPage isSiteAdmin={isSiteAdmin} />} />

        <Route
          path="urlbuilder/column/edit/:columnId"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditColumnPage} />}
        />
        <Route
          path="urlbuilder/column/add"
          element={<PrivateRoute authenticated={isSiteAdmin} component={NewColumnPage} />}
        />
        <Route
          path="urlbuilder/type/edit/:typeId"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditTypePage} />}
        />
        <Route
          path="urlbuilder/type/add"
          element={<PrivateRoute authenticated={isSiteAdmin} component={NewTypePage} />}
        />
        <Route
          path="urlbuilder/valueSets/edit/:valueSetsId"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditValueSetsPage} />}
        />
        <Route
          path="urlbuilder/valueSets/add"
          element={<PrivateRoute authenticated={isSiteAdmin} component={NewValueSetsPage} />}
        />
        <Route
          path="urlbuilder/configuration/add"
          element={<PrivateRoute authenticated={isSiteAdmin} component={NewConfigurationPage} />}
        />
        <Route
          path="urlbuilder/configuration/edit/:configurationId"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditConfigurationPage} />}
        />
        <Route path="connectors" element={<Advertisers isSupportUser={isSupportUser} />} />
        <Route
          path="connectors/:connector/edit/:id"
          element={<PrivateRoute authenticated={isSupportUser} component={AdvertisersFormEdit} />}
        />
        <Route
          path="connectors/:connector/add"
          element={<PrivateRoute authenticated={isSupportUser} component={AdvertisersFormCreate} />}
        />
        <Route
          path="users"
          element={<UsersPage isSiteAdmin={isSiteAdmin} isContextAdmin={isContextAdmin} loggedInUser={loggedInUser} />}
        />
        <Route path="users/add" element={<PrivateRoute authenticated={isSiteAdmin} component={UsersAddPage} />} />
        <Route path="sitesecurity" element={<SiteSecurityPage isSiteAdmin={isSiteAdmin} />} />
        <Route
          path="sitesecurity/edit"
          element={<PrivateRoute authenticated={isSiteAdmin} component={EditSiteSecurityPage} />}
        />
        <Route path="identities" element={<IdentitiesPage />} />
        <Route path="imports" element={<Imports isSiteAdmin={isSiteAdmin} isSupportUser={isSupportUser} />} />
        <Route
          path="imports/add"
          element={<PrivateRoute authenticated={isSiteAdmin || isSupportUser} component={AddImports} />}
        />
        <Route path="imports/view/:importId" element={<AddImports />} />
      </Routes>
    );
  }
}

export default AdminPages;
