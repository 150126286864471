import React from 'react';
import i18n from '~/i18n';

type TStepHeaderProps = {
  title: string;
  isOptional: boolean;
};
const StepHeader = ({ title, isOptional }: TStepHeaderProps) => (
  <p className="text-2xl text-r42-blue">
    {title}
    {isOptional && <span className="ml-0.5 text-xs text-r42-blue">{i18n.t('workflow:common.optional')}</span>}
  </p>
);

export default StepHeader;
