import React, { useContext } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { formatNumber } from '~/common';
import { useAPI } from '~/common/ApiHooks';
import { Page } from '~/components';
import Heading from '~/components/src/Heading';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { JourneyContext } from '../JourneyContext';
import ExperimentAnalysis from './components/ExperimentAnalysis';
import TriggerAnalysis from './components/TriggerAnalysis';
import { getTriggerStats } from './utils';
import TriggerStatsChart from './components/TriggerStatsChart';

const TriggerStats = ({ type = NodeTypes.GOAL }: { type: keyof typeof NodeTypes }) => {
  const params = useParams();

  const triggerId = params.goalId || params.exitConditionId || '';
  const isGoal = type === NodeTypes.GOAL;

  const isGoalOrExit = isGoal ? 'goal' : 'exit';

  const { journeyData, actions } = useContext(JourneyContext);
  const { goToJourneyView } = actions;
  const journeyId = journeyData?.journeyId;

  if (!triggerId || !journeyId) return <></>;

  const { data: triggerStatsData, isLoading } = useAPI(
    () => getTriggerStats(journeyId, triggerId, type),
    [journeyId, triggerId],
  );

  const { totalProfilesReachedExitCondition, triggerAnalysis, totalProfilesReachedGoal, name, variantAnalysis } =
    triggerStatsData || {};

  const ProfilesHeader = () => (
    <div className="flex items-center gap-2">
      <Icons icon={isGoalOrExit} className={cx('h-6 w-6 text-amber-500', { 'text-red-500': !isGoal })} />
      <p className="PageTitleHeader-totalProfiles flex gap-2">
        {i18n.t(`workflow:orchestration.triggerStats.${isGoal ? 'totalProfilesReachedGoal' : 'totalProfilesExited'}`)}
        <span className="font-bold">
          {formatNumber(isGoal ? totalProfilesReachedGoal : totalProfilesReachedExitCondition)}
        </span>
      </p>
    </div>
  );

  return (
    <Page>
      <Heading
        testHook="triggerStatsDataTitle"
        title={name}
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => goToJourneyView(),
          },
        ]}
      />
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <ProfilesHeader />
          <ExperimentAnalysis variantAnalysis={variantAnalysis} isGoal={isGoal} isGoalOrExit={isGoalOrExit} />
          <TriggerAnalysis triggerAnalysis={triggerAnalysis} isGoal={isGoal} isGoalOrExit={isGoalOrExit} />
        </>
      )}

      <TriggerStatsChart journeyId={journeyId} triggerId={triggerId} type={type} isGoalOrExit={isGoalOrExit} />
    </Page>
  );
};

export default TriggerStats;
