import Api, { API_BASE } from '~/common/ApiService';
import { isCustomConnector, isDIYConnector } from '~/profiles/helpers/connectorsHelper';

const BASE_PATH = `${API_BASE}/v1/partners`;
const EVENT_CONNECTOR_BASE_PATH = '/api/v1/eventConnectors';

const getIdentifierTypes = partner => Api.callGet(`${BASE_PATH}/${partner}/identifierTypes`).then(response => response);

const getEndpoints = partner => Api.callGet(`${BASE_PATH}/${partner}/endpoints`).then(response => response);

const getAdvertisers = partner => Api.callGet(`${BASE_PATH}/${partner}/advertisers`).then(response => response);

const getUserIdTypes = partner => Api.callGet(`${BASE_PATH}/${partner}/userIdTypes`).then(response => response);

const getPayloadTypes = partner => Api.callGet(`${BASE_PATH}/${partner}/payloadTypes`).then(response => response);

const getPartnerInformation = url => Api.callGet(BASE_PATH + url).then(response => response);

const getEndpointLocations = partner => Api.callGet(`${BASE_PATH}/${partner}/locations`).then(response => response);

const getQueryString = params =>
  Object.keys(params)
    .map(key => {
      const value = params[key];
      if (!value) return null;
      return `${key}=${params[key]}`;
    })
    .filter(x => x != null)
    .join('&');

const getConnectorDatabases = (partner, params) => {
  const queryString = getQueryString(params);
  const url = `${BASE_PATH}/${partner}/contactDatabaseId?${queryString}`;
  return Api.callGet(url, { shouldShowToast: false }).then(response => response);
};

const getAppNexusAdvertisers = (partner, params) => {
  const queryString = getQueryString(params);
  const url = `${BASE_PATH}/${partner}/advertisers?${queryString}`;
  return Api.callGet(url, { shouldShowToast: false }).then(response => response);
};

const requestConnectorCreation = requestPayload =>
  Api.callPost(`${BASE_PATH}/create`, requestPayload, { shouldShowToast: false });

const requestEventConnectorCreation = requestPayload =>
  Api.callPost(EVENT_CONNECTOR_BASE_PATH, requestPayload, { shouldShowToast: false });

const requestConnectorUpdate = requestPayload =>
  Api.callPut(`${BASE_PATH}/update`, requestPayload, { shouldShowToast: false });

const requestLogoUpload = (siteId, connectorId, partnerType, requestBody) => {
  let path = null;
  if (isCustomConnector(partnerType)) {
    path = `/api/v1/partners/custom/${siteId}/${connectorId}/logo`;
  } else if (isDIYConnector(partnerType)) {
    path = `/api/v1/eventConnectors/${siteId}/${connectorId}/logo`;
  }
  return Api.callPostMultiPart(path, requestBody);
};

const updateEventConnector = (eventConnectorId, payload) =>
  Api.callPut(`${EVENT_CONNECTOR_BASE_PATH}/${eventConnectorId}`, payload);

export {
  getIdentifierTypes,
  getEndpoints,
  getAdvertisers,
  getUserIdTypes,
  getPayloadTypes,
  getPartnerInformation,
  getConnectorDatabases,
  getAppNexusAdvertisers,
  requestEventConnectorCreation,
  requestConnectorCreation,
  requestConnectorUpdate,
  requestLogoUpload,
  getEndpointLocations,
  updateEventConnector,
};
