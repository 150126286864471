import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { buildUrl, changeUrl } from '~/common';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import {
  fetchAudiences,
  fetchAudienceOrGroupIfNeeded,
  openCreateAudienceGroupModal,
  openDeleteAudienceGroupModal,
  deleteAudience,
  moveItem,
  getAudiences,
  getAudienceOrGroup,
  getNodesQty,
  isFetchingAudiences,
  isMovingItem,
  searchAudiences,
  AUDIENCES_LIST_KEY,
  BACKEND_PAGINATION_QTY,
  getSelectedAudience,
  isAudienceDependantsContainerVisible,
  showDependantsContainer,
  hideDependantsContainer,
} from '~/profiles/audiencesTree';
import AudiencesPage from './presenter';
import itemActions from './itemActions';

export default compose(
  translate('audiences'),
  withRouter,
  connect(
    (state, ownProps) => {
      const { groupId } = ownProps?.params;
      const nodesQty = getNodesQty(state);
      return {
        groupId,
        emptyQty: groupId ? 1 : 0,
        list: getAudiences(state, groupId),
        group: getAudienceOrGroup(state, groupId),
        audienceUrl: id => buildUrl(`audiences/segments/view/${id}`),
        groupUrl: id => (id ? buildUrl(`audiences/segments/list/${id}/0`) : buildUrl('audiences/segments/list/0')),
        nodesQty,
        lastPage: Math.ceil(nodesQty / BACKEND_PAGINATION_QTY) - 1,
        onPaginate: page =>
          groupId
            ? changeUrl(`audiences/segments/list/${groupId}/${page}`)
            : changeUrl(`audiences/segments/list/${page}`),
        stateKey: AUDIENCES_LIST_KEY,
        isMoving: isMovingItem(state),
        isFetching: isFetchingAudiences(state),
        selectedAudience: getSelectedAudience(state),
        isDependantsContainerVisible: isAudienceDependantsContainerVisible(state),
        crumbs:
          (groupId && [
            {
              title: i18n.t('audiences:breadcrumbs.root'),
              pathname: buildUrl('audiences/segments/dashboard'),
            },
          ]) ||
          [],
      };
    },
    (dispatch, ownProps) => ({
      fetchData: searchQuery => dispatch(fetchAudiences(ownProps.params.page, ownProps.params.groupId, searchQuery)),
      fetchGroup: () => dispatch(fetchAudienceOrGroupIfNeeded(ownProps.params.groupId)),
      searchAudiences: payload => dispatch(searchAudiences(payload)),
      onGroupAddClick: () => dispatch(openCreateAudienceGroupModal()),
      onDeleteGroup: ({ name, id }) => dispatch(openDeleteAudienceGroupModal(name, id)),
      onDeleteAudience: item =>
        dispatch(
          fromModals.showDeleteModal({
            title: i18n.t('audiences:audienceDelete.title'),
            message: i18n.t('audiences:audienceDelete.message', { name: item.name }),
            onConfirm: () => dispatch(deleteAudience(item)),
          }),
        ),
      onEditAudience: item => {
        changeUrl(`audiences/segments/${item.id}/edit`);
      },
      onViewAudience: item => {
        changeUrl(`audiences/segments/view/${item.id}`);
      },
      onMoveAudience: (groupId, audienceId) => {
        dispatch(moveItem(groupId, audienceId));
      },
      onDependantIconClick: audienceId => {
        dispatch(showDependantsContainer(audienceId));
      },
      onDependantHideClick: () => dispatch(hideDependantsContainer()),
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      actions: item => itemActions(item, dispatchProps, ownProps),
      dropdownActions: [
        {
          name: i18n.t('audiences:list.actions.addAudience'),
          tooltip: i18n.t('audiences:list.actions.addAudience'),
          isDisabled: false,
          onClick: () => {
            const { groupId } = stateProps;
            let url = 'audiences/segments/add';
            if (groupId) {
              url += `?parent=${groupId}`;
            }

            changeUrl(url);
          },
          testHook: 'addAudience',
          type: 'addAudience',
        },
        {
          name: i18n.t('audiences:list.actions.addGroup'),
          tooltip: i18n.t('audiences:list.actions.addGroup'),
          isDisabled: false,
          onClick: dispatchProps.onGroupAddClick,
          testHook: 'addGroup',
          type: 'addGroup',
        },
      ],
    }),
  ),
)(AudiencesPage);
