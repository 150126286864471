angular.module('webUi.ui.context.users.form', ['webUi.ui.context.users.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.users.form', {
                url: '/new',
                isReact: true
            })
            .state('site.context.users.edit', {
                url: '/edit/{userId}',
                isReact: true
            });
    }]);
