export const AI_EVENTS = {
  MODEL: {
    CREATED: 'AI Model Created',
    DELETED: 'AI Model Deleted',
    DEPLOYED: 'AI Model Deployed',
    EDITED: 'AI Model Edited',
    TRAINED: 'AI Model Trained',
  },
  SNAPSHOT: {
    CREATED: 'AI Snapshot Created',
    DELETED: 'AI Snapshot Deleted',
  },
};

export const CONNECTOR_EVENTS = {
  CREATED: 'Connector Created',
  DELETED: 'Connector Deleted',
  EDITED: 'Connector Edited',
};

export const CUSTOMER_FACT_EVENTS = {
  CREATED: 'Customer Fact Created',
  EDITED: 'Customer Fact Edited',
};

export const ENGAGEMENT_EVENTS = {
  CREATED: 'Engagement Created',
  EDITED: 'Engagement Edited',
};

export const EXPORT_EVENTS = {
  CREATED: 'Export Configuration Created',
  EDITED: 'Export Configuration Edited',
};

export const USER_EVENTS = {
  CREATED: 'User Created',
  EDITED: 'User Edited',
  PERMISSION: {
    MODIFIED: 'User Permission Modified',
  },
  SETTINGS: {
    EDITED: 'User Settings Edited',
  },
};

export const SECURITY_EVENTS = {
  TWO_FA: {
    ENABLED: '2FA Enabled', // Unused, not able to configure
  },
};

export const VARIABLE_EVENTS = {
  CREATED: 'Variable Created',
  DELETED: 'Variable Deleted',
};

export const WORKFLOW_EVENTS = {
  CREATED: 'Workflow Created',
  DELETED: 'Workflow Deleted',
  PUBLISHED: 'Workflow Published',
};

export const TAG_EVENTS = {
  PUBLISHED: 'Tags Published',
};
