import React from 'react';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import Tooltip from '~/components/src/Tooltip';

const PropertyNameSelect = ({
  propertyIndex,
  selectedPropertyName,
  allSelectedProperties = [],
  options,
  setFieldValue,
  isCreatable,
  ts,
  isClearable,
  hasNewProperty,
}) => {
  const handleChange = (selectedOption, triggeredAction) => {
    const isClearButtonClicked = triggeredAction.action === 'clear';

    if (isClearButtonClicked) {
      // Clears property row
      setFieldValue(`filters[${propertyIndex}]`, {});
      return;
    }

    setFieldValue(`filters[${propertyIndex}].dataField`, selectedOption.value);
    setFieldValue(`filters[${propertyIndex}].dataFieldId`, selectedOption.dataFieldId);
  };

  const customPlaceholder = isCreatable ? ts.startTyping : ts.selectProperty;

  return (
    <Tooltip tooltip={selectedPropertyName} disabled={!selectedPropertyName} placement="bottom">
      <SelectElement
        isCreatable={isCreatable}
        autoFocus={hasNewProperty}
        value={selectedPropertyName}
        options={options}
        onChange={handleChange}
        formatCreateLabel={label => `Custom: ${label}`}
        isClearable={isClearable}
        isOptionDisabled={option => allSelectedProperties.some(prop => prop.dataField === option.value)}
        placeholder={customPlaceholder}
        noOptionsMessage={() => ts.noOptionMessage}
        testHook={`filters-${propertyIndex}__dataField`}
      />
    </Tooltip>
  );
};

export default PropertyNameSelect;
