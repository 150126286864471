import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import CustomerFactService from './dataService';
import * as selectors from './selectors';
import { fetchDependantsInfo, fetchPropertyDependantsInfo } from '../dependencies/actions';

/**
 * Fetch customer facts
 */
export const fetchCustomerFacts = () => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACTS_FETCH_START,
  });

  return CustomerFactService.getCustomerFacts()
    .then(customerFacts => {
      dispatch({
        type: types.CUSTOMER_FACTS_FETCH_SUCCESS,
        payload: customerFacts,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACTS_FETCH_FAIL, error }));
};

/**
 * Fetch engagement activities
 */
export const fetchCustomerFactsActivities = () => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACTS_ACTIVITIES_FETCH_START,
  });

  return CustomerFactService.getCustomerFactsActivity()
    .then(activities => {
      dispatch({
        type: types.CUSTOMER_FACTS_ACTIVITIES_FETCH_SUCCESS,
        payload: activities,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACTS_ACTIVITIES_FETCH_FAIL, error }));
};

/**
 * Fetch customer fact
 */
export const fetchCustomerFact = customerFactId => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACT_FETCH_START,
  });

  return CustomerFactService.getCustomerFact(customerFactId)
    .then(customerFact => {
      dispatch({
        type: types.CUSTOMER_FACT_FETCH_SUCCESS,
        payload: customerFact,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_FETCH_FAIL, error }));
};

/**
 * Fetch customer fact activity
 */
export const fetchCustomerFactActivity = customerFactId => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACT_ACTIVITY_FETCH_START,
  });

  return CustomerFactService.getCustomerFactActivity(customerFactId)
    .then(activity => {
      dispatch({
        type: types.CUSTOMER_FACT_ACTIVITY_FETCH_SUCCESS,
        payload: activity,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_ACTIVITY_FETCH_FAIL, error }));
};

/**
 * Fetch customer fact traffic source
 */
export const fetchCustomerFactTrafficSources = customerFactId => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_START,
  });

  return CustomerFactService.getCustomerFactTrafficSources(customerFactId)
    .then(trafficSource => {
      dispatch({
        type: types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_SUCCESS,
        payload: trafficSource,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_FAIL, error }));
};

/**
 * Fetch customer fact properties
 */
export const fetchCustomerFactProperties = customerFactId => dispatch => {
  dispatch({
    type: types.CUSTOMER_FACT_PROPERTIES_FETCH_START,
  });

  return CustomerFactService.getCustomerFactProperties(customerFactId)
    .then(properties => {
      dispatch({
        type: types.CUSTOMER_FACT_PROPERTIES_FETCH_SUCCESS,
        payload: properties,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_PROPERTIES_FETCH_FAIL, error }));
};

/**
 * Fetch the customer fact only if it is not in the store
 */
export const fetchCustomerFactIfNeeded = customerFactId => (dispatch, getState) => {
  if (selectors.getCustomerFactById(getState(), customerFactId)) {
    return Promise.resolve();
  }
  return dispatch(fetchCustomerFact(customerFactId));
};

/**
 * Create or Edit customer fact with the passed values
 * @param values
 */
export const saveCustomerFact = values => dispatch => {
  const fact = {
    customerFactId: values.customerFactId,
    name: values.name,
    description: values.description,
    type: values.type,
    properties: values.propertiesWithUsage.map(property => property.name),
  };

  const ApiCall = values.customerFactId ? CustomerFactService.editCustomerFact : CustomerFactService.createCustomerFact;

  dispatch({
    type: types.CUSTOMER_FACT_SAVE_START,
  });

  return ApiCall(fact)
    .then(newFact => {
      dispatch({
        type: types.CUSTOMER_FACT_SAVE_SUCCESS,
        payload: newFact,
      });

      // Go to the list view
      changeUrl('profiles/externalFacts');
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_SAVE_FAIL, error }));
};

/**
 * Add properties to customer fact
 * @param values
 * @param properties
 */
export const addProperties = (values, properties) => dispatch => {
  const customerFact = {
    customerFactId: values.customerFactId,
    type: values.type,
    name: values.name,
    description: values.description,
    properties: [...values.properties, ...properties],
  };

  dispatch({
    type: types.CUSTOMER_FACT_ADD_PROPERTY_START,
  });

  return CustomerFactService.editCustomerFact(customerFact)
    .then(updatedCustomerFact => {
      dispatch({
        type: types.CUSTOMER_FACT_ADD_PROPERTY_SUCCESS,
        payload: updatedCustomerFact,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACT_ADD_PROPERTY_FAIL, error }));
};

/**
 * Delete customer fact if it does not have dependants
 */
export const deleteCustomerFact = (customerFactId, hasDependants) => dispatch => {
  if (hasDependants) {
    return Promise.resolve();
  }
  dispatch({ type: types.CUSTOMER_FACTS_DELETE_START });
  return CustomerFactService.deleteCustomerFact(customerFactId)
    .then(() => {
      showSuccess({ body: i18n.t('customerFacts:api.deletionSuccess') });
      changeUrl('profiles/externalFacts/dashboard');
      dispatch({
        type: types.CUSTOMER_FACTS_DELETE_SUCCESS,
        payload: customerFactId,
      });
    })
    .catch(error => dispatch({ type: types.CUSTOMER_FACTS_DELETE_FAIL, error }));
};

/**
 * Show the container for the usage overview of the customer fact/property
 */
export const showDependantsContainer = (customerFactId, selectedTab, propertyId) => dispatch => {
  if (propertyId) {
    dispatch(fetchPropertyDependantsInfo(customerFactId, propertyId));
  } else {
    dispatch(fetchDependantsInfo(customerFactId));
  }
  dispatch({
    type: types.SHOW_DEPENDANTS_CONTAINER,
    payload: {
      customerFactId,
      propertyId,
      selectedTab,
    },
  });
};

/**
 * Hide the container for the usage overview of the customer fact
 */
export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.HIDE_DEPENDANTS_CONTAINER,
  });
};

/**
 * Change the tab in the dependants container
 */
export const changeDependantTypesSelectedTab = selectedTab => dispatch => {
  dispatch({
    type: types.DEPENDANTS_TYPE_TAB_CHANGE,
    payload: selectedTab,
  });
};

/**
 * Set if the user is currently editing some property or not
 */
export const setUserIsEditing = isUserEditing => dispatch => {
  dispatch({
    type: types.SET_USER_IS_EDITING,
    payload: isUserEditing,
  });
};
