import React, { useState } from 'react';
import DeletePopup from '~/components/src/DeletePopup';
import { showSuccess } from '~/notificationCenter';
import { useApiWithState } from '~/common';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { Heading, Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { RowActions } from '~/components/src/Table/components';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import Spin from '~/components/src/Spin';
import { deleteCookiePermissionsGroup, getCookiePermissionsGroups } from '../dataService';
import { TPermissionGroup } from '../types';
import CookiePermissionGroupForm from './CookiePermissionGroupForm';

const columnHelper = createColumnHelper<TPermissionGroup>();
type TCookiePermissionGroupProps = { hasTagEdit: boolean };

const CookiePermissionGroup = ({ hasTagEdit }: TCookiePermissionGroupProps) => {
  const [selectedGroup, setSelectedGroup] = useState<TPermissionGroup | null>(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { state, isLoading, refetch } = useApiWithState(() => getCookiePermissionsGroups());

  const renderActions = (
    info: CellContext<TPermissionGroup, any>,
    setSelectedGroup: React.Dispatch<React.SetStateAction<TPermissionGroup | null>>,
    setShowFormModal: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const actions = [
      {
        name: i18n.t('cookie:list.permissionGroup.edit'),
        tooltip: hasTagEdit
          ? i18n.t('cookie:list.permissionGroup.edit')
          : i18n.t('cookie:list.permissionGroup.noPermission'),
        isDisabled: !hasTagEdit,
        icon: 'edit',
        onClick: () => {
          setSelectedGroup(info?.row?.original);
          setShowFormModal(true);
        },
        testHook: 'editDc',
      },
      {
        name: i18n.t(`cookie:list.permissionGroup.${info?.row?.original?.usedInTags ? 'cannotDelete' : 'delete'}`),
        tooltip: hasTagEdit
          ? i18n.t(`cookie:list.permissionGroup.${info?.row?.original?.usedInTags ? 'cannotDelete' : 'delete'}`)
          : i18n.t('cookie:list.permissionGroup.noPermission'),
        isDisabled: !hasTagEdit || info?.row?.original?.usedInTags,
        icon: 'delete',
        onClick: () => {
          setSelectedGroup(info?.row?.original);
          setShowDeleteDialog(true);
        },
        testHook: 'deletePG',
        type: 'delete',
      },
    ];
    return (
      <div className="text-center">
        <RowActions actions={actions} />
      </div>
    );
  };

  const getColumns = (
    setSelectedGroup: React.Dispatch<React.SetStateAction<TPermissionGroup | null>>,
    setShowFormModal: React.Dispatch<React.SetStateAction<boolean>>,
  ) => [
    columnHelper.accessor('number', {
      header: () => <div className="text-left">{i18n.t('cookie:list.permissionGroup.table.number')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 100,
    }),
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('cookie:list.permissionGroup.table.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 700,
    }),
    columnHelper.accessor('actions', {
      header: () => <div className="text-center">{i18n.t('cookie:list.permissionGroup.table.actions')}</div>,
      cell: info => renderActions(info, setSelectedGroup, setShowFormModal),
      size: 100,
    }),
  ];

  const handleDelete = async () => {
    if (selectedGroup) {
      await deleteCookiePermissionsGroup(selectedGroup?.groupId);
      showSuccess({ body: i18n.t('cookie:permissionGroupForm.api.deleted') });
      setShowDeleteDialog(false);
      setSelectedGroup(null);
      refetch();
    }
  };
  if (isLoading) return <Spin className="mt-8" />;
  return (
    <>
      <Heading kind="h1" title={i18n.t('cookie:tab.permissionGroups')}>
        <BtnIcon
          tooltip={i18n.t('cookie:list.permissionGroup.add')}
          color="blue"
          icon="add"
          testHook="addGroup"
          onClick={() => {
            setShowFormModal(true);
          }}
        />
      </Heading>
      <UITable
        data={state ?? []}
        testHook="cookiePermissionGroupsTable"
        emptyMessage={i18n.t('cookie:list.permissionGroup.empty.header')}
        emptyBody={i18n.t('cookie:list.permissionGroup.empty.body')}
        columns={getColumns(setSelectedGroup, setShowFormModal)}
      />

      {showFormModal && (
        <PickerView
          pickerTitle={i18n.t(`cookie:permissionGroupForm.${selectedGroup ? 'edit' : 'add'}.header`)}
          handlePickerVisibility={() => {
            setShowFormModal(false);
            setSelectedGroup(null);
          }}
          className="!h-auto !w-[30%]"
        >
          <CookiePermissionGroupForm
            permissionGroups={state || []}
            selectedGroup={selectedGroup}
            setShowFormModal={setShowFormModal}
            setSelectedGroup={setSelectedGroup}
            refetch={refetch}
          />
        </PickerView>
      )}

      {showDeleteDialog && (
        <DeletePopup
          title={i18n.t('cookie:permissionGroupForm.delete.header')}
          hidePopup={() => {
            setShowDeleteDialog(false);
            setSelectedGroup(null);
          }}
          onClick={() => handleDelete()}
        >
          <Notification>
            {i18n.t('cookie:permissionGroupForm.delete.message', { name: selectedGroup?.name })}
          </Notification>
        </DeletePopup>
      )}
    </>
  );
};

export default CookiePermissionGroup;
