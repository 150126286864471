angular.module('webUi.service.support.tagtemplate', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('TagTemplateService', ['Restangular', '$q', 'Utils', function (Restangular, $q, Utils) {

        var BASE_PATH = 'support/tagtemplates/';

        var onInitTagTemplateNode = function (node) {
            if (node.type === 'TAG_TEMPLATE') {
                node.sref = 'site.support.tagtemplates.templates.view( {templateId:' + JSON.stringify(node.id) + '})';
                node.canDelete = false;
                node.dropEnabled = true;
                node.dropNamespace = 'tagtemplatevariant';
                node.dragEnabled = false;
            } else {
                node.sref = 'site.support.tagtemplates.groups.view( {groupId:' + JSON.stringify(node.id) + '})';
                node.canDelete = false;
                node.dragEnabled = false;
                node.dropEnabled = false;
            }
        };

        var isLatestVersion = function isLatestVersion($stateParams, variantHistory) {
            if (_.isNull($stateParams.sequenceNr) || _.isUndefined($stateParams.sequenceNr)) {
                return true;
            }
            var latestVersionVariant = _.find(variantHistory, function (variant) {
                if (!variant.deleted) {
                    return variant;
                }
            });
            //variantHistory is an array that's ordered with the latest sequenceNr first
            return latestVersionVariant.sequenceNr === _.parseInt($stateParams.sequenceNr);
        };


        return {
            /**
             * The base path through which all requests should go
             * @returns {String}
             */
            getBasePath: function getBasePath() {
                return BASE_PATH;
            },
            /**
             * Retrieves a promise of the possible execution times
             * @returns {@exp;executionTypes@pro;promise}
             */
            getTagExecutionTypes: function getTagExecutionTypes() {
                var executionTypes = $q.defer();

                Restangular.all(BASE_PATH + 'variants/executiontypes').getList().then(function (result) {
                    result = Utils.sanitizeRestangularAll(result);
                    executionTypes.resolve(result);
                });
                return executionTypes.promise;
            },

            /**
             * Retrieves the group tree data with sub-entities (tag templates)
             * * @returns {Map} a promise of the tree data
             */
            getGroupTreeData: function getGroupTreeData() {
                var promiseTree = $q.defer();

                this.getGroups().then(function (result) {
                    result = Utils.sanitizeRestangularAll(result);
                    var nodes = Utils.parseNodes(result, [], [], onInitTagTemplateNode);
                    promiseTree.resolve(nodes);
                });
                return promiseTree.promise;
            },

            /**
             * Retrieves a group by its id
             * @param {UUID} groupId
             * @returns {Object} a promise of the group
             */
            getGroup: function getGroup(groupId) {
                var group = $q.defer();
                Restangular.one(BASE_PATH + 'groups', groupId).get().then(function (result) {
                    result = Utils.sanitizeRestangularOne(result);
                    group.resolve(result);
                });
                return group.promise;
            },

            getGroups: function getGroups() {
                return Restangular.all(BASE_PATH + 'groups').getList();
            },

            getTemplate: function getTemplate(templateId) {
                var template = $q.defer();
                Restangular.one(BASE_PATH + 'templates', templateId).get()
                    .then(function (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        template.resolve(result);
                    });
                return template.promise;
            },

            getVariant: function getVariant(variantId) {
                var variant = $q.defer();
                Restangular.one(BASE_PATH + 'variants', variantId).get().then(function (result) {
                    result = Utils.sanitizeRestangularOne(result);
                    variant.resolve(result);
                });
                return variant.promise;
            },

            getVariantWithVersion: function getVariantWithVersion(variantId, sequenceNr) {
                var variant = $q.defer();
                Restangular.one(BASE_PATH + 'variants').get({
                    variantId: variantId,
                    sequenceNr: sequenceNr
                }).then(function (result) {
                    result = Utils.sanitizeRestangularOne(result);
                    variant.resolve(result);
                });
                return variant.promise;
            },

            /**
             * Get all versions of this variant identified by id, including the latest version
             */
            getVariantHistory: function getVariantHistory(variantId) {
                var variants = $q.defer();
                Restangular.all(BASE_PATH + 'variantHistory').getList({variantId: variantId}).then(function (results) {
                    results = Utils.sanitizeRestangularAll(results);
                    variants.resolve(results);
                });
                return variants.promise;
            },

            saveGroup: function saveGroup(group) {
                return Restangular.all(BASE_PATH + 'groups/add').post(group);
            },

            updateGroup: function updateGroup(group) {
                return Restangular.all(BASE_PATH + 'groups/edit').post(group);
            },

            deleteGroup: function deleteGroup(groupId) {
                return Restangular.one(BASE_PATH + 'groups', groupId).remove();
            },

            saveTemplate: function saveTemplate(template) {
                return Restangular.all(BASE_PATH + 'templates/add').post(template);
            },

            updateTemplate: function updateTemplate(template) {
                return Restangular.all(BASE_PATH + 'templates/edit').post(template);
            },

            deleteTemplate: function deleteTemplate(templateId) {
                return Restangular.one(BASE_PATH + 'templates', templateId).remove();
            },

            saveVariant: function saveVariant(variant) {
                return Restangular.all(BASE_PATH + 'variants/add').post(variant);
            },

            updateVariant: function updateVariant(variant) {
                var updatePromise = $q.defer();
                Restangular.all(BASE_PATH + 'variants/edit').post(variant).then(
                    function onSuccess(response) {
                        updatePromise.resolve(response);
                    },
                    function onError(error) {
                        error.errorHandled = true;
                        updatePromise.reject({error: error.data.status.error});
                    });
                return updatePromise.promise;
            },

            updateVariantLockFlag: function updateVariantLockFlag(variantId, sequenceNr, locked) {
                return Restangular.all(BASE_PATH + 'variants/updateLockFlag').post({
                    variantId: variantId,
                    sequenceNr: sequenceNr,
                    locked: locked
                });
            },

            deleteVariant: function deleteVariant(variantId, sequenceNr) {
                return Restangular.all(BASE_PATH + 'variants/delete').post({
                    variantId: variantId,
                    sequenceNr: sequenceNr
                });
            },

            getTagtemplateLogoNames: function getTagtemplateLogoNamesfunction() {
                return Restangular.one(BASE_PATH + 'templates/allLogos').get();
            },

            getTagContentTypes: function getTagContentTypes() {
                var deferred = $q.defer();
                // retrieve data and set cache for next usage
                Restangular.one(BASE_PATH + 'templates/tagContentTypes').get().then(function (result) {
                    if (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        deferred.resolve(result);
                    }
                });
                return deferred.promise;
            },

            getPlatformTypes: function getPlatformTypes() {
                var deferred = $q.defer();
                // retrieve data and set cache for next usage
                Restangular.one(BASE_PATH + 'templates/platformTypes').get().then(function (result) {
                    if (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        deferred.resolve(result);
                    }
                });
                return deferred.promise;
            },

            /**
             * Function is used in the CookieSync tag template, do NOT delete
             * @returns {*|promise}
             */
            getCookieSyncPartners: function getCookieSyncPartners() {
                var deferred = $q.defer();
                // retrieve data and set cache for next usage
                Restangular.one('tagmanagement/cookieSyncPartners').get().then(function (result) {
                    if (result) {
                        result = Restangular.stripRestangular(result);
                        deferred.resolve(result);
                    }
                });
                return deferred.promise;
            },

            copyVariantToTemplate: function copyVariantToTemplate(variantId, templateId) {
                return Restangular.all(BASE_PATH + 'variants/copy').post({
                    variantId: variantId,
                    templateId: templateId
                });
            },

            isLatestVersion: isLatestVersion
        };
    }]);
