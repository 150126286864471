angular.module('webUi.ui.tagmanagement.tags', [
    'webUi.ui.tagmanagement.tags.form',
    'webUi.ui.tagmanagement.tags.view',
    'webUi.ui.tagmanagement.tags.list',
    'webUi.ui.tagmanagement.tags.paths',
    'webUi.ui.tagmanagement.tags.websitePathGroups',
    'webUi.ui.tagmanagement.tags.errors',
    'webUi.ui.tagmanagement.tags.history',
    'webUi.ui.tagmanagement.tags.delete'
]);
