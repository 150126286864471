import { findIndex, isNil, inRange } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import i18nValidation from './i18n';
import { validateSubnet } from './validationSubnets';

const moment = extendMoment(Moment);

/**
 * Generic validation rules
 * @TODO:
 * It's a temporary solution. We need to choose a proper library that has more rules and prevents xss
 * The rules should be the same on frontend and backend validation
 */

/**
 * Number Value should fit in the range
 * @param min
 * @param max
 * @returns {function(*=): undefined}
 */
export const numberRange = (min, max) => value =>
  inRange(value, min, max + 1) ? undefined : i18nValidation.t('validation:validation.invalidNumberRange', { min, max });

/**
 * Value cannot be empty
 * @param value
 * @returns {*}
 */
export const isRequired = value =>
  !isNil(value) && value.toString().trim() ? undefined : i18nValidation.t('validation:validation.required');

/**
 * Value cannot be empty
 * @param value
 * @returns {*}
 */
export const tokenIsRequired = value =>
  value && value.toString().trim() ? undefined : i18nValidation.t('validation:validation.tokenRequired');

/**
 * Value cannot be longer than {max}
 * @param max
 */
export const maxLength = max => value =>
  (value && value.toString().length <= max) || !value
    ? undefined
    : i18nValidation.t('validation:validation.maxLength', { max });

/**
 * Value cannot be shorter than {min}
 * @param min
 */
export const minLength = min => value =>
  (value && value.toString().length >= min) || !value
    ? undefined
    : i18nValidation.t('validation:validation.minLength', { min });

/**
 * Default Input Max Length
 */
export const defaultMaxInput = maxLength(255);

/**
 * Default Textarea Max Length
 */
export const defaultMaxText = maxLength(65000);

/**
 * Value must be a valid email address
 * @param value
 * @returns {*}
 */
export const isEmail = value =>
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/i.test(value)
    ? undefined
    : i18nValidation.t('validation:validation.invalidEmail');

/**
 * Value must be a valid email addresses
 * @param values
 * @returns {*}
 */
export const isEmails = values => {
  let errors = values.map(value => isEmail(value));
  errors = errors.filter(error => error !== undefined);
  return errors[0] || undefined;
};

/**
 * Value must be a valid url
 * @param str
 * @returns {*}
 */
export const isUrl = str =>
  // eslint-disable-next-line max-len
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/?\S*)?$/i.test(
    str,
  )
    ? undefined
    : i18nValidation.t('validation:validation.invalidUrl');

/**
 * Value must be a valid url with protocol
 * @param str
 * @returns {*}
 */
export const isUrlWithProtocol = str =>
  // eslint-disable-next-line max-len
  /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/?\S*)?$/i.test(
    str,
  )
    ? undefined
    : i18nValidation.t('validation:validation.invalidUrl');

/**
 * Is typed value unique in a given set?
 * DEPRICATED
 * @param set
 * @param property
 * @param message
 */
export const isUnique = (set, property, message) => value =>
  findIndex(set, { [`${property}`]: value && value.toString().trim() }) === -1
    ? undefined
    : message || i18nValidation.t('validation:validation.isUnique');

/**
 * Change all multispaces to one and remove all spaces from beginning and end of the value
 */
export const normalizeValue = value => `${value.replace(/ +(?= )/g, '')}`.trim();

/**
 * Remove all spaces
 */
export const removeSpaces = value => `${value.replace(/ +/g, '')}`.trim();

/**
 * check is a valid subnet
 * @param value
 * @returns {*}
 */
export const isValidSubnet = value =>
  validateSubnet(value) ? undefined : i18nValidation.t('validation:validation.isValidSubnet');

/**
 * check is a valid variable name
 * @param value
 * @returns {*}
 */
export const isValidVariableName = value =>
  value && /^[\u0E00-\u0E7Fa-zA-Z]{1}[\u0E00-\u0E7Fa-zA-Z0-9_]{1,}$/.test(value)
    ? undefined
    : i18nValidation.t('validation:validation.isValidVariableName');

/**
 * Value should be a positive number with maximum value integer supported in backend
 * numberRange will return undefined if there is not error
 * @returns {function(*=): undefined}
 */
export const isPositiveNumber = value =>
  value && numberRange(1, 2147483647)(value) === undefined
    ? undefined
    : i18nValidation.t('validation:validation.isPositiveNumber', {});

/**
 * Value must be alphanumeric
 * @param value
 * @param message
 */
export const isAlphanumeric = value =>
  value && /^[a-zA-Z0-9]*$/.test(value) ? undefined : i18nValidation.t('validation:validation.alphanumeric');

/**
 * Is typed value unique in a given array?
 * @param value
 * @param list
 * @param message
 */
export const isValueUnique = (value, list, message) =>
  value && !list.includes(normalizeValue(value))
    ? undefined
    : message || i18nValidation.t('validation:validation.isUnique');

/**
 * Is typed value unique in a given array? The comparison is case insensitive.
 * @param value
 * @param list
 * @param message
 */
export const isValueUniqueCaseInsensitive = (value, list, message) => {
  const normalizedList = list ? list.map(listValue => listValue?.toLowerCase()) : [];
  return value && !normalizedList.includes(normalizeValue(value.toLowerCase()))
    ? undefined
    : message || i18nValidation.t('validation:validation.isUnique');
};

/**
 * Is Valid date using moment js library
 * @param value
 * @param message
 */
export const isValidDate = value =>
  value && moment(value).isValid() ? undefined : i18nValidation.t('validation:validation.isValidDate');

/**
 * End date should be after start date
 * @param startDate
 * @param endDate
 * @param message
 */
export const endDateIsAfterStartDate = (endDate, values) =>
  endDate && values.startDate && moment(endDate).isAfter(moment(values.startDate))
    ? undefined
    : i18nValidation.t('validation:validation.endDateIsAfterStartDate');

/**
 * End date should be same or after start date
 * @param startDate
 * @param endDate
 * @param message
 */
export const endDateIsSameOrAfterStartDate = (endDate, values) =>
  endDate && values.startDate && moment(endDate).isSameOrAfter(moment(values.startDate))
    ? undefined
    : i18nValidation.t('validation:validation.endDateIsAfterStartDate');

/**
 * start date should be same or before end date
 * @param startDate
 * @param endDate
 * @param message
 */
export const startDateIsSameOrBeforeEndDate = (startDate, values) =>
  startDate && values.endDate && moment(startDate).isSameOrBefore(moment(values.endDate))
    ? undefined
    : i18nValidation.t('validation:validation.startDateIsBeforeEndDate');

/**
 * is start date and end date in range
 * @param startDate
 * @param endDate
 * @param message
 */
export const isStartDateAndEndDateInRange = (rangeValue, values) => {
  if (rangeValue && values.startDate && values.endDate) {
    const endDateWithEndOfTheDay = moment(values.endDate).endOf('day');
    const range = moment().range(values.startDate, endDateWithEndOfTheDay);
    const diff = range.diff('days', true);

    if (diff > rangeValue) {
      return i18nValidation.t('validation:validation.startDateAndEndDateNotInRange', { rangeValue });
    }
  }
  return undefined;
};

/**
 * start date should be before end date
 * @param startDate
 * @param endDate
 * @param message
 */
export const startDateIsBeforeEndDate = (startDate, values) =>
  startDate && values.endDate && moment(startDate).isBefore(moment(values.endDate))
    ? undefined
    : i18nValidation.t('validation:validation.startDateIsBeforeEndDate');

/**
 * Creates a validation function to check if date format is correct
 * "value" is either a string or Moment object
 * @param dateFormat String like 'DD/MM/YYYY'
 */
export const makeIsDateFormatValid = dateFormat => value => {
  if (!moment(value, dateFormat, true).isValid()) {
    return i18nValidation.t('validation:validation.wrongDateFormat', { dateFormat });
  }

  return undefined;
};

/**
 * Is typed value a valid phone number?
 * @param value
 */
export const isPhoneNumber = value =>
  (value && value.replace(/\+/, '').match(/^[0-9]+$/) !== null) || !value
    ? undefined
    : i18nValidation.t('validation:validation.phoneNumber');

/**
 * End date should be before start date
 * @param startDate
 * @param endDate
 * @param message
 */
export const isNewPasswordRepeatMatching = (newPasswordRepeat, values) =>
  newPasswordRepeat && values.newPassword && newPasswordRepeat === values.newPassword
    ? undefined
    : i18nValidation.t('validation:validation.newPasswordRepeatDoNotMatch');

/**
 * Value must only contain numbers and dashes
 * @param value
 * @returns {*}
 */
export const isNumberDash = value =>
  value && /^(?!-+$)[0-9-]+$/.test(value) ? undefined : i18nValidation.t('validation:validation.numberDash');

/**
 * Value must be a number
 * @param value
 * @returns {*}
 */
export const isNumber = value =>
  value && Number.isInteger(Number(value)) ? undefined : i18nValidation.t('validation:validation.isNumber');
