import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Highcharts from 'highcharts';
import moment from 'moment';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import { Panel } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import Code from '~/components/src/Code';
import { Tabs, kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { withRouter } from '~/common/withRouter';
import { buildUrl } from '~/common';
import * as fromModals from '~/modals';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';
import { DateRangePicker } from '~/components';
import { fetchCollectors, fetchCodeForCollector, deleteCollector } from '../collectors/actions';
import { getCollectorById, isFetchingCollectors, getCodeSamplesById } from '../collectors/selectors';
import { getCollectorStatsChart } from './../collectors';
import './styles.scss';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

export class CollectorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: null,
      startDate: defaultDate.startDate,
      endDate: defaultDate.endDate,
    };
    this.getChartData = this.getChartData.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchCollectors();
    this.props.fetchCodeForCollector(this.props.collectorId);
    this.getChartData(this.props.collectorId);
  }

  getChartData(collectorId) {
    const { startDate, endDate } = this.state;
    getCollectorStatsChart(
      collectorId,
      startDate.startOf('day').toDate().getTime(),
      endDate.endOf('day').toDate().getTime(),
    ).then(chart => {
      this.setState({
        chart,
      });
    });
  }

  getSumOfTotalCalls() {
    const { series } = this.state.chart.config;
    return series.filter(item => item.id === 'dataPartnerCalls')[0].data.reduce((a, b) => a + b, 0);
  }

  handleDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate }, () => {
      this.getChartData(this.props.collectorId);
    });
  }

  render() {
    const { isFetching, editItem, onDeleteClick, t, collector, canEditProfiles } = this.props;
    if (isFetching || !this.props.collector) {
      return <Spinner />;
    }

    const chartTitle = this.state.chart && `${t('collectors:common.totalCalls')}: ${this.getSumOfTotalCalls() || '0'}`;
    const { startDate, endDate } = this.state;
    return (
      <Page>
        <Heading title={collector.collectorName}>
          {canEditProfiles && (
            <>
              <BtnIcon icon="delete" onClick={() => onDeleteClick(collector)} tooltip={t('deleteBtnTooltip')} />
              <LinkIcon
                className="h-8 w-8"
                icon="edit"
                url={editItem}
                testHook="edit"
                tooltip={t('common:actions.edit')}
                color="blue"
              />
            </>
          )}
        </Heading>

        <Tabs kind={kind.heading}>
          <TabList>
            <Tab testHook="dataPartnerActivity">{t('collectors:common.dataPartnerActivity')}</Tab>
            <Tab testHook="dataPartnerJavascript">{t('collectors:common.dataPartnerJavascript')}</Tab>
            <Tab testHook="dataPartnerHTML">{t('collectors:common.dataPartnerHTML')}</Tab>
          </TabList>
          <TabPanel testHook="dataPartnerActivity">
            <Panel>
              <Heading title={chartTitle} kind="h3" className="Collector-statsHeading u-paddingTopNone">
                <DateRangePicker startDate={startDate} endDate={endDate} handleDatesChange={this.handleDatesChange} />
              </Heading>
              {this.state.chart && <HighChartWithZoom highcharts={Highcharts} options={this.state.chart.config} />}
            </Panel>
          </TabPanel>
          <TabPanel testHook="dataPartnerJavaScript">
            <Code
              mode="javascript"
              options={{ lint: false, readOnly: true }}
              value={this.props.code && this.props.code.collectorJs}
              label="dataPartnerJavaScriptLabel"
            />
          </TabPanel>
          <TabPanel testHook="dataPartnerHtml">
            <Code
              mode="xml"
              options={{ readOnly: true }}
              value={this.props.code && this.props.code.collectorHtml}
              label="dataPartnerHtmlLabel"
            />
          </TabPanel>
        </Tabs>
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const collectorId = ownProps && ownProps.params.collectorId;
  return {
    collectorId,
    collector: getCollectorById(state, ownProps.params.collectorId),
    code: getCodeSamplesById(state, ownProps.params.collectorId),
    isFetching: isFetchingCollectors(state),
    editItem: buildUrl(`profiles/collectors/edit/${collectorId}`),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCollectors: () => dispatch(fetchCollectors()),
  fetchCodeForCollector: id => dispatch(fetchCodeForCollector(id)),
  onDeleteClick: collector => {
    dispatch(
      fromModals.showDeleteModal({
        title: i18n.t('collectors:deleteModal.title'),
        message: i18n.t('collectors:deleteModal.message', { title: collector.collectorName }),
        onConfirm: () => dispatch(deleteCollector(collector.collectorId)),
      }),
    );
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate('collectors'),
)(CollectorPage);
