import React from 'react';
import Icon from 'react-icon-base';

const R42Stats = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M1.25 17h3.5a.249.249 0 0 0 .25-.25v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a.249.249 0 0 0 .25.25z" />
    <path d="M8 8.75a1 1 0 0 0-1 1v7a.249.249 0 0 0 .25.25h3.5a.249.249 0 0 0 .25-.25v-7a1 1 0 0 0-1-1z" />
    <path d="M14 10.75a1 1 0 0 0-1 1v5a.249.249 0 0 0 .25.25h3.5a.249.249 0 0 0 .25-.25v-5a1 1 0 0 0-1-1z" />
    <path d="M19.25 17h3.5a.249.249 0 0 0 .25-.25v-12a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v12a.249.249 0 0 0 .25.25z" />
    <path d="M1 20.25h22a1 1 0 0 0 0-2H1a1 1 0 0 0 0 2z" />
  </Icon>
);

export default R42Stats;
