import React from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { UITable } from '~/components/src/UITable';
import { Icon, Link, Empty, Spinner } from '~/components';
import MdWarning from 'react-icons/lib/md/warning';
import i18n from '~/i18n';
import { buildUrl } from '~/common';
import Tooltip from '~/components/src/Tooltip';
import SnapshotActions from './components/SnapshotActions';
import { TableSnapshotRowType, SnapshotStatusType, SnapshotTableProps } from '../types';
import './styles.scss';

const columnHelper = createColumnHelper<TableSnapshotRowType>();

export const SnapshotStatus = {
  RUNNING: 'Running',
  FINISHED: 'Finished',
  SUBMITTED: 'Submitted',
  DELETING: 'Deleting',
  ERROR: 'Error',
} as const;

const EmptyList = () => (
  <Empty header={i18n.t('ai:snapshotsPage.list.emptyHeader')} body={i18n.t('ai:snapshotsPage.list.emptyBody')} />
);

function SnapshotTable({ snapshots, isFetching, refetchSnapshots }: SnapshotTableProps): JSX.Element {
  const dateFormat = 'DD/MM/YY';
  const timeFormat = 'HH:mm:ss';

  const data = snapshots?.map(snapshot => ({
    ...snapshot,
    queryDateRange: `${moment(snapshot.startTs).format(dateFormat)} - ${moment(snapshot.endTs).format(dateFormat)}`,
    createdAt: moment(snapshot.createdAt).format(dateFormat),
    createdAtTime: moment(snapshot.createdAt).format(timeFormat),
  }));

  const SnapshotStatusTag = ({
    status,
    tooltipText = '',
  }: {
    status: SnapshotStatusType;
    tooltipText?: string | null;
  }) => {
    if (status.toLowerCase() === SnapshotStatus.ERROR.toLowerCase() && tooltipText)
      return (
        <Tooltip className="u-marginS" tooltip={tooltipText}>
          <Icon icon={MdWarning} color="red" /> {status}
        </Tooltip>
      );

    if (status.toLowerCase() === SnapshotStatus.RUNNING.toLowerCase())
      return (
        <>
          <span className="SnapshotTable-statusCircle SnapshotTable-statusCircle--running" />
          {status}
        </>
      );

    if (status.toLowerCase() === SnapshotStatus.DELETING.toLowerCase())
      return (
        <>
          <span className="SnapshotTable-statusCircle SnapshotTable-statusCircle--deleting" />
          {status}
        </>
      );
    return <> {status} </>;
  };

  const columns = [
    columnHelper.accessor('snapshotName', {
      header: () => <div>{i18n.t('ai:columns.name')}</div>,
      cell: ({ row }) =>
        row.original.status.toLowerCase() === SnapshotStatus.FINISHED.toLowerCase() ? (
          <Link className="t-snapshotName" href={buildUrl(`ai/snapshots/view/${row.original.snapshotId}`)}>
            {row?.original.snapshotName}
          </Link>
        ) : (
          <p className="t-snapshotName SnapshotTable-name--disabled">{row.original.snapshotName}</p>
        ),
      size: 200,
      minSize: 200,
    }),
    columnHelper.accessor('queryDateRange', {
      header: () => <div>{i18n.t('ai:columns.queryDateRange')}</div>,
      size: 200,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{i18n.t('ai:columns.createdAt')}</div>,
      cell: ({ row }) => (
        <div>
          {row.original.createdAt}
          <br />
          <small className="text-r42-blue">{row.original.createdAtTime}</small>
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <div>{i18n.t('ai:columns.status')}</div>,
      cell: ({ row }) => (
        <div>
          <p
            className={`t-snapshotTableStatus SnapshotTable-status SnapshotTable-status--${row.original.status.toLowerCase()}`}
          >
            <SnapshotStatusTag status={row.original.status} tooltipText={row.original.error} />
          </p>
        </div>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <div className="text-center">{i18n.t('ai:columns.actions')}</div>,
      cell: ({ row }) => {
        const { snapshotId, snapshotName, status, canDelete } = row.original;
        return (
          <SnapshotActions
            snapshotInfo={{ snapshotId, snapshotName, status, canDelete }}
            isListPage={true}
            refetchData={refetchSnapshots}
          />
        );
      },
    }),
  ];

  if (isFetching) {
    return <Spinner />;
  }

  if (snapshots?.length === 0) {
    return <EmptyList />;
  }

  return <UITable testHook="snapshotTable" data={data || []} columns={columns} />;
}

export default SnapshotTable;
