angular.module('webUi.ui.tagmanagement.publishing', [
    'webUi.ui.tagmanagement.base',
    'webUi.ui.siteState',
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.publishing', {
                url : '/publishing',
                isReact: true,
            });
    }]);