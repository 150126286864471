import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchVariantHistory, showVariant } from '~/support/tagtemplates/variants/actions';
import * as selectors from '~/support/tagtemplates/variants/selectors';
import VariantHistoryList from '~/support/components/TagVariantHistoryList';

export class VariantHistoryModal extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    return <VariantHistoryList {...this.props} />;
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      t: ownProps.t,
      stateKey: 'SUPPORT/TEMPLATES/VARIANTS/VARIANT_HISTORY',
      list: selectors.getVariantHistory(state, ownProps.variant.variantId),
    }),
    (dispatch, ownProps) => ({
      showVariant: variant => dispatch(showVariant(variant)),
      fetchData: () => dispatch(fetchVariantHistory(ownProps.variant.variantId)),
    }),
  ),
)(VariantHistoryModal);
