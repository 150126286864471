import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';
import Empty from '~/components/src/Empty';

const { Table, Row, Cell, RowActions } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('urlBuilder:types.list.empty.header')} body={t('urlBuilder:types.list.empty.body')} />
);

export const TypesList = ({ list, stateKey, onEditClick, onDeleteClick, t, isSiteAdmin }) => (
  <Panel>
    <Table stateKey={stateKey} testHook="typesTable">
      <Row>
        <SortableHeader className="u-size1of4" sortKey="name" title={t('urlBuilder:types.list.name')} />
        <SortableHeader className="u-size1of4" sortKey="prefix" title={t('urlBuilder:types.list.prefix')} />
        <SortableHeader className="u-size1of4" sortKey="keyParameter" title={t('urlBuilder:types.list.keyParameter')} />
      </Row>
      {list.map(type => {
        const { typeId, name, prefix, keyParameter, canDelete } = type;
        const getDeleteTooltipText = (isSiteAdmin, canDelete) => {
          if (!canDelete) {
            return t('urlBuilder:types.list.deleteInUseError');
          } else if (isSiteAdmin) {
            return t('urlBuilder:types.list.deleteActionButton');
          }
          return t('urlBuilder:types.list.deletePermisionError');
        };

        const actions = [
          {
            name: t('urlBuilder:types.list.editActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:types.list.editActionButton')
              : t('urlBuilder:types.list.editPermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'edit',
            onClick: () => onEditClick(typeId),
            testHook: 'edit',
          },
          {
            name: t('urlBuilder:types.list.deleteActionButton'),
            tooltip: getDeleteTooltipText(isSiteAdmin, canDelete),
            isDisabled: !isSiteAdmin || !canDelete,
            icon: 'delete',
            onClick: () => onDeleteClick(type, t),
            testHook: 'delete',
            type: 'delete',
          },
        ];

        return (
          <Row key={typeId} withActions>
            <Cell className="u-size1of4">{name}</Cell>
            <Cell className="u-size1of4">{prefix}</Cell>
            <Cell className="u-size1of4">{keyParameter}</Cell>
            <RowActions actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

TypesList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default compose(withSort(), withPaginate({ size: 11 }), withEmpty({ component: EmptyList }))(TypesList);
