import React, { ReactElement } from 'react';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';
import icons from './icons';

type IconsProps = {
  icon: keyof typeof icons;
  className?: string;
  tooltip?: ReactElement | string;
  placement?: Placement;
  testHook?: string;
  onClick?: () => void;
};

function Icons({
  icon,
  className,
  tooltip,
  placement = 'bottom',
  testHook = 'icon-tooltip',
  onClick,
}: IconsProps): ReactElement {
  const paths = icons[icon]?.paths?.map((data: any, index: number) => <path key={index} {...data} />);
  const viewBox = icons[icon]?.viewBox || '0 0 20 20';

  return (
    <Tippy className={`t-${testHook}`} content={tooltip} disabled={!tooltip} placement={placement}>
      <svg
        onClick={onClick}
        width="100%"
        height="100%"
        className={className}
        fill="currentColor"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {paths}
      </svg>
    </Tippy>
  );
}

export default Icons;
