import React, { useState } from 'react';
import { Field } from 'redux-form';
import Button from '~/components/src/Button';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { isRequired } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { Notification } from '~/components';
import { FormSection } from '~/components/src/Containers';
import constants from '../constants';

const getTestConnectionStatus = (connectionStatus, areConnectionCredentialsChecked, t) => {
  if (!areConnectionCredentialsChecked) {
    return null;
  }

  switch (connectionStatus) {
    case null:
      return '';
    case true:
      return <Notification kind="success">{t('new.connection.testConnection.successMsg')}</Notification>;
    case false:
      return <Notification kind="error">{t('new.connection.testConnection.errorMsg')}</Notification>;
    default:
      return <Notification kind="warning">{connectionStatus}</Notification>;
  }
};

const ExportConnectionForm = props => {
  const {
    t,
    formValues,
    check,
    isRemoteConnectionSuccessful,
    areConnectionCredentialsChecked,
    setAreConnectionCredentialsChecked,
    setIsRemoteConnectionSuccessful,
    touch,
    isEdit,
  } = props.data;
  const connectionTypes = [
    { value: constants.CONNECTION_TYPES.SFTP, label: t('new.connectionTypes.sftp') },
    { value: constants.CONNECTION_TYPES.FTP, label: t('new.connectionTypes.ftp') },
    { value: constants.CONNECTION_TYPES.FTPS, label: t('new.connectionTypes.ftps') },
  ];

  const [isFieldsModified, setIsFieldsModified] = useState(false);

  if (isEdit && !isFieldsModified) {
    setIsRemoteConnectionSuccessful(true);
    setAreConnectionCredentialsChecked(true);
  }
  const getLoginTypes = () => {
    switch (formValues.connection && formValues.connection.connectionType) {
      case constants.CONNECTION_TYPES.SFTP:
        return [{ value: constants.LOGIN_TYPES.NORMAL, label: t('new.loginTypes.normal') }];
      case constants.CONNECTION_TYPES.FTP:
      case constants.CONNECTION_TYPES.FTPS:
      default:
        return [
          { value: constants.LOGIN_TYPES.ANONYMOUS, label: t('new.loginTypes.anonymous') },
          { value: constants.LOGIN_TYPES.NORMAL, label: t('new.loginTypes.normal') },
        ];
    }
  };

  const isNormalConnection = formValues.connection && formValues.connection.loginType === constants.LOGIN_TYPES.NORMAL;

  return (
    <FormSection title={t('new.connection.header')} description={t('new.connection.subHeader')}>
      <div
        onChange={() => {
          setIsFieldsModified(true);
          setAreConnectionCredentialsChecked(false);
        }}
      >
        <Field
          label={t('new.connection.connectionTypeTitle')}
          name="connectionType"
          component={ReduxFormSelectField}
          options={connectionTypes}
          validate={isRequired}
          touch={touch}
        />

        <Field
          label={t('new.connection.loginTypeTitle')}
          name="loginType"
          component={ReduxFormSelectField}
          options={getLoginTypes()}
          validate={isRequired}
          touch={touch}
        />

        {isNormalConnection && (
          <Field
            label={t('new.connection.userNameTitle')}
            name="username"
            component={ReduxFormInputField}
            placeholder={t('new.connection.PLACEHOLDER')}
            validate={isRequired}
            type="text"
          />
        )}

        {isNormalConnection && (
          <FieldWithEditMode
            label={t('new.connection.passwordTitle')}
            name="password"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={isEdit}
            placeholder={t('new.connection.PLACEHOLDER')}
            validate={isRequired}
            type="text"
            testHook="password"
            dependentFieldEdit={isFieldsModified}
          />
        )}

        <Field
          label={t('new.connection.hostTitle')}
          name="host"
          component={ReduxFormInputField}
          placeholder={t('new.connection.PLACEHOLDER')}
          validate={isRequired}
          type="text"
        />

        <Field
          label={t('new.connection.portTitle')}
          name="port"
          component={ReduxFormInputField}
          placeholder={t('new.connection.PLACEHOLDER')}
          validate={isRequired}
          type="text"
        />

        <Field
          label={t('new.connection.directoryTitle')}
          name="directory"
          component={ReduxFormInputField}
          placeholder={t('new.connection.PLACEHOLDER')}
          validate={isRequired}
          type="text"
        />

        <div className="NewExport-testConnection">
          <Button
            kind="secondary"
            onClick={() => check()}
            testHook="testConnectionButton"
            disabled={isRemoteConnectionSuccessful && areConnectionCredentialsChecked}
          >
            {t('new.connection.testConnection.button')}
          </Button>
          <div className="w-full py-2">
            {getTestConnectionStatus(isRemoteConnectionSuccessful, areConnectionCredentialsChecked, t)}
          </div>
        </div>
      </div>
    </FormSection>
  );
};

export default ExportConnectionForm;
