import React, { ReactElement, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { buildUrl, changeUrl, useAPI } from '~/common';
import { Heading } from '~/components';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import { getFilter } from './dataService';
import { FiltersContext } from './FiltersContext';
import EventsTab from './components/EventsTab';

function FilterView(): ReactElement {
  const params = useParams();
  const filterId = params.filterId || '';
  const { canEditProfiles } = useContext(FiltersContext);
  const { data: filter, isLoading } = useAPI(() => getFilter(filterId));
  const { filterRules: rules } = filter || {};

  if (isLoading) return <Spin />;

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50">
      <div className="w-2/3">
        <Heading
          testHook="filterHeader"
          title={filter?.name || i18n.t('filters:list.header')}
          crumbs={[
            {
              title: i18n.t('filters:back'),
              pathname: buildUrl('profiles/filters'),
            },
          ]}
          truncateHeader
        >
          {canEditProfiles && (
            <BtnIcon
              onClick={() => changeUrl(`profiles/filters/edit/${filterId}`)}
              testHook="editFilter"
              color="blue"
              icon="edit"
              tooltip={i18n.t('filters:tooltips.edit')}
            />
          )}
        </Heading>
        <EventsTab {...{ rules }} />
      </div>
    </div>
  );
}

export default FilterView;
