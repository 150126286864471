import React from 'react';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import InputElement from '~/components/src/Form/Elements/InputElement';

const StaticField = ({ value, onChange, label, error, onBlur, name, testHook }) => (
  <FieldWrapper label={label} name={name} errorText={error}>
    <InputElement
      name={name}
      placeholder="Add Value"
      value={value}
      className={`t-${testHook}`}
      onBlur={onBlur}
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  </FieldWrapper>
);

export default StaticField;
