import { get } from 'lodash';
import { changeUrl } from '~/common/history';
import { showError } from '~/notificationCenter';
import i18n from '~/i18n';

const PERMISSION_NOTIFICATION_ID = 'PERMISSION_NOTIFICATION_ID';

export const handleCommonErrors = (response, responseJson) => {
  if (response.status === 403) {
    showError({
      body: i18n.t('common:errors.noPermission'),
      toastId: PERMISSION_NOTIFICATION_ID,
    });

    return { isCommonErrorHandled: true };
  }

  if (response.status === 404) {
    changeUrl('error/404');

    return { isCommonErrorHandled: true };
  }

  const error = get(responseJson, 'status.error.code', null);

  switch (error) {
    case 'ERR_TWO_FACTOR_AUTHENTICATION':
      changeUrl('/iplock', true, true);
      break;
    case 'ERR_OUTSIDE_OF_SUBNET':
    case 'ERR_USER_BLOCKED':
      changeUrl('/iplock?outsideOfSubnet=true', true, true);
      break;
    case 'ERR_NO_SITE_ACCESS':
      changeUrl('error/no-site-access');
      break;
    case 'ERR_LOGIN_REQUIRED':
      // on 401 force page refresh to show login page
      changeUrl(document.location.href, true, true);
      break;
    case 'ERR_FORBIDDEN':
      changeUrl('error/403');
      break;
    case 'ERR_NOTFOUND':
      changeUrl('error/404');
      break;
    case 'ERR_PASSWORD_RESET':
      changeUrl(
        `/login/passwordReset?username=${responseJson.status.user}&hash=${responseJson.status.hash}`,
        true,
        true,
      );
      break;
    default:
      return { isCommonErrorHandled: false };
  }

  return { isCommonErrorHandled: true };
};
