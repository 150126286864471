import * as fromModals from '~/modals';
import * as selectors from '~/user/settings/selectors';
import UserSettingsService from '../dataService.js';
import * as types from '../types';

export const resetErrorSmsRequest = () => dispatch => {
  dispatch({
    type: types.RESET_ERROR_SMS_REQUEST,
  });
};

export const resetErrorUpdatePhoneRequest = () => dispatch => {
  dispatch({
    type: types.RESET_ERROR_UPDATE_PHONE_REQUEST,
  });
};

export const resetErrorTimebasedRequest = () => dispatch => {
  dispatch({
    type: types.RESET_ERROR_TIMEBASED_REQUEST,
  });
};

export const resetSmsTokenStep = () => dispatch => {
  dispatch({
    type: types.RESET_SMS_TOKEN_STEP,
  });
};

export const confirmSmsToken = smsCode => (dispatch, getState) => {
  dispatch({
    type: types.PHONE_UPDATE_START,
  });
  const phoneNumber = selectors.getPhoneNumber(getState());
  return UserSettingsService.updatePhoneNumber(phoneNumber, smsCode)
    .then(response => {
      if (response.succeeded) {
        dispatch(fromModals.hideModal());
        dispatch(resetSmsTokenStep());
        dispatch({
          type: types.PHONE_UPDATE_SUCCESS,
          payload: response.default2FA,
        });
      } else {
        dispatch({ type: types.PHONE_UPDATE_FAIL, payload: response });
      }
    })
    .catch(error => dispatch({ type: types.PHONE_UPDATE_FAIL, error }));
};

export const requestSmsToken = phoneNumber => dispatch => {
  dispatch({
    type: types.SMS_REQUEST_START,
  });

  return UserSettingsService.requestSms(phoneNumber)
    .then(() => {
      dispatch({
        type: types.SMS_REQUEST_SUCCESS,
        payload: {
          phoneNumber,
        },
      });
    })
    .catch(error => dispatch({ type: types.SMS_REQUEST_FAIL, error }));
};

export const addSmsToken = formValues => (dispatch, getState) => {
  const smsTokenStep = selectors.getSmsTokenStep(getState());
  if (smsTokenStep === 2 && formValues.phoneNumber) {
    dispatch(confirmSmsToken(formValues.smsCode));
  } else {
    dispatch({ type: types.GO_TO_SMS_CODE_CONFIRMATION_STEP });
  }
};

export const confirmTimebasedToken = (googleAuthCode, secretKey) => dispatch => {
  dispatch({
    type: types.TIMEBASED_UPDATE_START,
  });
  return UserSettingsService.verifyGoogleAuth(googleAuthCode, secretKey)
    .then(response => {
      if (response && response.status && response.status.success) {
        dispatch(fromModals.hideModal());
        dispatch({
          type: types.TIMEBASED_UPDATE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({ type: types.TIMEBASED_UPDATE_FAIL });
      }
    })
    .catch(error => dispatch({ type: types.TIMEBASED_UPDATE_FAIL, error }));
};

export const addTwoFactorAuth = formValues => (dispatch, getState) => {
  const timebasedTokenStep = selectors.getTimebasedTokenStep(getState());
  if (timebasedTokenStep === 2) {
    dispatch(confirmTimebasedToken(formValues.googleAuthCode, formValues.secretKey));
  } else {
    dispatch({
      type: types.TIMEBASED_REQUEST_SUCCESS,
    });
  }
};

export const resetTimebasedTokenStep = () => dispatch => {
  dispatch({
    type: types.RESET_TIMEBASED_TOKEN_STEP,
  });
};

export const makeDefault2FA = authenticationType => dispatch =>
  UserSettingsService.makeDefault2FA(authenticationType)
    .then(response => {
      if (response) {
        dispatch({
          type: types.MAKE_DEFAULT_SUCCESS,
          payload: response,
        });
      }
    })
    .catch(error => dispatch({ type: types.MAKE_DEFAULT_FAIL, error }));

export const deleteTwoFactorAuthentication = authenticationType => dispatch => {
  dispatch({
    type: types.TWO_FACTOR_AUTH_DELETE_START,
  });
  return UserSettingsService.removeTwoFactorAuthentication(authenticationType)
    .then(payload => {
      dispatch({
        type: types.TWO_FACTOR_AUTH_DELETE_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.TWO_FACTOR_AUTH_DELETE_FAIL, error }));
};
