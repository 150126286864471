import _, { get, omit } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { getLogoUrl, getPropertiesFromConnector } from '~/profiles/connectors';
import { INTEGRATION_TYPE } from '~/components/src/ConnectorCard';
import {
  flattenCriteria,
  addPropertiesToCriteria,
  addTitleAndTypeLabel,
  getTitleAndTypeLabel,
} from '~/profiles/audiences/audienceUtils';
import { RulesTypes } from '~/common/modules/rules/types';
import i18n from '../i18n';
import { makeIntegration } from './utils';

/**
 * Get Audience
 * @param state
 * @param audienceId
 */
export const getAudience = (state, audienceId) => get(state, `profiles.audiences.byId.${audienceId}`);

export const transformCriteria = savedCriteria =>
  savedCriteria.map(group =>
    group.rules.map(configuredRule => {
      const type = configuredRule?.clazz;

      const { title, typeLabel } = getTitleAndTypeLabel(type, configuredRule.ruleName);

      const criteria = {
        type,
        title,
        typeLabel,
        ruleDefinitionId: configuredRule?.ruleDefinitionId,
        ruleId: configuredRule?.ruleId,
        ruleType: configuredRule?.ruleType,
      };

      if (type === RulesTypes.FixedTime) {
        return {
          ...criteria,
          waitTime: configuredRule.waitTime,
        };
      }

      if (type === RulesTypes.JourneyOverlap) {
        return {
          ...criteria,
          negation: configuredRule.negation,
          title: configuredRule?.dependantJourneyName,
          dependantJourneyId: configuredRule?.dependantJourneyId,
        };
      }

      return {
        ...criteria,
        negation: configuredRule.negation,
        timeCondition: configuredRule.timeCondition,
        filters: configuredRule?.filters?.map(filter => ({
          dataField: filter.dataField,
          dataFieldId: filter.dataFieldId,
          constraint: filter.constraint,
          filterValue: filter.filterValue,
          minListSize: filter.minListSize,
          storedVariable: filter.storedVariable,
          profileVarId: filter.profileVarId,
        })),
      };
    }),
  );

const getIntegrations = providedConnectors =>
  providedConnectors.map(connector => {
    /* "variant" field is always null and appears to be useless for audience view / edit */
    const partnerDetails = omit(connector.partnerDetails, ['variant']);
    return makeIntegration(connector.name, partnerDetails, [], connector.activated);
  });

export const getEditableAudience = audienceId =>
  createSelector(
    state => getAudience(state, audienceId),
    audience => {
      if (!audience) {
        return null;
      }

      const criteria = transformCriteria(audience.segment.criteria);
      const integrations = getIntegrations(audience.segment.integrations);
      const { enhancements } = audience.segment;

      return {
        ...audience.segment,
        criteria,
        integrations,
        enhancements,
        usedInTags: audience.usedInTags,
        usedInSegments: audience.usedInSegments,
      };
    },
  );

// In our naming FETCH_FULL_AUDIENCES means "all without pagination". Needs to be refactored.
export const getFullAudience = (state, audienceId) => get(state, `profiles.audiences.fullAudiences.${audienceId}`);

export const getFullAudiences = state =>
  get(state, 'profiles.audiences.allIds', []).map(id => getFullAudience(state, id));

const getDetailsForAudience = _.memoize(audience => {
  const details = [];
  if (!audience) return details;

  if (audience.apiIdentifier) {
    details.push({
      label: i18n.t('audiences:view.details.apiId'),
      value: audience.apiIdentifier,
    });
  }

  if (!audience.segment) return details;

  if (audience.segment.description) {
    details.push({
      label: i18n.t('audiences:view.details.description'),
      value: audience.segment.description,
    });
  }

  if (audience.segmentLastModifiedByName && audience.segment.lastModified) {
    details.push({
      label: i18n.t('audiences:view.details.lastUpdatedLabel'),
      value: i18n.t('audiences:view.details.lastUpdatedValue', {
        name: audience.segmentLastModifiedByName,
        date: moment(audience.segment.lastModified).format('HH:mm, D MMMM, YYYY'),
      }),
    });
  }

  return details;
});

export const getAudienceDetails = (state, audienceId) => {
  const audience = getAudience(state, audienceId);
  return getDetailsForAudience(audience);
};

const getRulesCombinationsForAudience = _.memoize(audience => {
  if (!audience) {
    return [];
  }

  const flatCriteria = flattenCriteria(audience.segment.criteria);
  return addTitleAndTypeLabel(addPropertiesToCriteria(flatCriteria));
});

export const getAudienceRulesCombinations = (state, audienceId) => {
  const audience = getAudience(state, audienceId);
  return getRulesCombinationsForAudience(audience);
};

export const getConnectorsForAudience = _.memoize(audience => {
  if (!audience) {
    return [];
  }

  return audience.segment.integrations.map(integration => {
    const properties = getPropertiesFromConnector(integration);

    return {
      ...integration,
      properties,
      logoUrl: getLogoUrl(integration),
      integrationType: INTEGRATION_TYPE.serverToServer,
    };
  });
});

export const getAudienceConnectors = (state, audienceId) => {
  const audience = getAudience(state, audienceId);

  return getConnectorsForAudience(audience);
};

/**
 * Is fetching one audiences currently in progress?
 * @param state
 */
export const isFetchingAudience = state => get(state, 'profiles.audiences.ui.isFetchingAudience');

export const isSavingAudience = state => get(state, 'profiles.audiences.ui.isSavingAudience');

export const isFetchingTree = state => get(state, 'profiles.audiences.ui.isFetchingTree');

export const isHistoricProcessingStarted = state => get(state, 'profiles.audiences.ui.hpIsStarted');

const createTreeMappings = (node, audienceId) => {
  const isDirectory = node.type && node.type === 'GROUP';
  const expanded =
    isDirectory && node.children && !_.isEmpty(node.children.find(childNode => childNode.id === audienceId));
  return {
    ...node,
    title: node.name,
    isDirectory,
    expanded,
    createNewNodeAllowed: node.allowChildren || false,
    removeExistingNodeAllowed: node.children && node.children.length === 0,
    children: node.children && node.children.map(childNode => createTreeMappings(childNode)),
  };
};

const addMappingsForAudienceId = _.memoize((tree, audienceId) =>
  tree.map(data => createTreeMappings(data, audienceId)),
);

export const getTreeData = (state, audienceId) => {
  const tree = get(state, 'profiles.audiences.ui.treeData');
  return addMappingsForAudienceId(tree, audienceId);
};
