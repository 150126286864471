import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Fields from '~/components/src/Form/Fields';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, defaultMaxText } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
} from '~/components/src/Form/Fields/ReduxFormFields';

export const TagTemplateForm = ({
  handleSubmit,
  submitting,
  cancelHref,
  initialValues,
  groups,
  platformTypes,
  contentTypes,
  isGroupEditable,
  currentLogoPath,
  t,
  touch,
}) => {
  const isEdit = !!initialValues.templateId;

  const groupPart = !isGroupEditable ? null : (
    <Field
      label={t('templateForm.group')}
      name="group"
      component={ReduxFormSelectField}
      options={groups}
      touch={touch}
    />
  );

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          label={t('templateForm.name.label')}
          name="name"
          component={ReduxFormInputField}
          placeholder={t('templateForm.name.placeholder')}
          validate={[isRequired, defaultMaxInput]}
        />
        {groupPart}
        <Field
          label={t('templateForm.description.label')}
          name="description"
          component={ReduxFormTextAreaField}
          placeholder={t('templateForm.description.placeholder')}
          validate={defaultMaxText}
        />
        <Field
          label={t('templateForm.platformType')}
          name="platformType"
          disabled={isEdit}
          component={ReduxFormSelectField}
          options={platformTypes}
          validate={isRequired}
          touch={touch}
        />
        <Field
          label={t('templateForm.tagContentType')}
          name="tagContentType"
          disabled={isEdit}
          component={ReduxFormSelectField}
          options={contentTypes}
          validate={isRequired}
          touch={touch}
        />
        <Field
          label={t('templateForm.logo')}
          name="logo"
          currentLogo={currentLogoPath}
          component={Fields.Dropzone}
          placeholder={t('common:dropzone.default')}
          errorMessage={t('templateForm.logoNotAccepted')}
        />
      </div>
      <ActionsRow t={t} submitting={submitting} cancelHref={cancelHref} />
    </form>
  );
};

TagTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  groups: PropTypes.array,
  contentTypes: PropTypes.array,
  platformTypes: PropTypes.array,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default TagTemplateForm;
