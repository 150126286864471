import { get } from 'lodash';

/**
 * Get Dependants Info By Id
 * @param state
 * @param elementId
 * @returns {*}
 */
export const getDependantsInfoById = (state, elementId) => get(state, `profiles.dependencies.byId[${elementId}]`);

/**
 * Get Property Dependants Info By Id and propertyId
 * @param state
 * @param elementId
 * @returns {*}
 */
export const getPropertyDependantsInfoById = (state, elementId, propertyId) =>
  get(state, `profiles.dependencies.byId[${elementId}].properties[${propertyId}]`);
