(function() {

    UsageOverviewAlertController.$inject = ['$rootScope', 'USAGE_OVERVIEW_CONSTANTS'];

    function UsageOverviewAlertController($rootScope, USAGE_OVERVIEW_CONSTANTS) {
        var vm = this;

        vm.$onInit = function $onInit() {
            vm.assetsPrefix = $rootScope.assetsPrefix;
            vm.overviewAlertMessages = USAGE_OVERVIEW_CONSTANTS.overviewAlertMessages;
        };

    }

    angular.module('webUi.component.profiles.shared.usageOverviewAlert', [
        'webUi.service.profilesConstants'
    ])

        .component('usageOverviewAlert', {
            controller: UsageOverviewAlertController,
            controllerAs: 'vm',
            templateUrl: 'component/profiles/shared/usageOverviewAlert/usageOverviewAlert.tpl.html',
            bindings: {
                type: '<',
                text: '<?',
                sourceDependency: '<?'
            }
        });

}());
