import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CircleIcon = ({ icon, iconBackgroundColor, iconColor, size, iconSize }) => {
  const Icon = icon;
  return (
    <div
      className="CircleIcon"
      style={{
        backgroundColor: iconBackgroundColor,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {Icon && <Icon size={iconSize} color={iconColor} />}
    </div>
  );
};

CircleIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  iconBackgroundColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  size: PropTypes.number,
  iconSize: PropTypes.number,
  className: PropTypes.string,
};

CircleIcon.defaultProps = {
  iconColor: '#ffffff',
  size: 40,
  iconSize: 20,
};

export default CircleIcon;
