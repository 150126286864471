import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TagTemplateGroupAddPage from './TagTemplateGroupAdd';
import TagTemplateGroupEditPage from './TagTemplateGroupEdit';
import TagTemplateGroupPage from './TagTemplateGroup';
import TagTemplatePage from './TagTemplate';
import TagTemplateAddPage from './TagTemplateAdd';
import TagTemplateEditPage from './TagTemplateEdit';
import TagVariantPage from './TagVariant';
import TagVariantAddPage from './TagVariantAdd';
import TagVariantEditPage from './TagVariantEdit';
import TagsSidebar from './TagsSidebar';
import './index.scss';

const TagsPages = () => (
  <div className="Tags TagsTemplates">
    <aside>
      <TagsSidebar />
    </aside>
    <div className="TagsTemplates-right">
      <Routes>
        <Route path="groups/add" element={<TagTemplateGroupAddPage />} />
        <Route path="groups/edit/:groupId" element={<TagTemplateGroupEditPage />} />
        <Route path="groups/view/:groupId" element={<TagTemplateGroupPage />} />
        <Route path="templates/add" element={<TagTemplateAddPage />} />
        <Route path="templates/view/:templateId" element={<TagTemplatePage />} />
        <Route path="templates/edit/:templateId" element={<TagTemplateEditPage />} />
        <Route path="variants/add" element={<TagVariantAddPage />} />
        <Route exact path="variants/view/:variantId" element={<TagVariantPage />} />
        <Route path="variants/view/:variantId/history/:sequenceNr" element={<TagVariantPage />} />
        <Route path="variants/edit/:variantId" element={<TagVariantEditPage />} />
      </Routes>
    </div>
  </div>
);

export default TagsPages;
