import React from 'react';
import moment from 'moment';
import i18n from '~/i18n';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

const getTimeValue = (timeFrame, time) => {
  if (timeFrame === 'FUTURE') return i18n.t('audiences:rules.future', { time });
  if (timeFrame === 'PAST') return i18n.t('audiences:rules.past', { time });
  return '';
};

const getDateWithinValue = (timeFrame, filterValue) => {
  const time = `${filterValue.rangeFrom} to ${filterValue.rangeTo}`;
  if (timeFrame === 'FUTURE') return i18n.t('audiences:rules.future', { time });
  if (timeFrame === 'PAST') return i18n.t('audiences:rules.past', { time });
  return '';
};

export const GetFilter = ({ filterValue }) => {
  const filterMap = {
    ExperimentFilterValue: () => <div>{filterValue.name}</div>,

    DateFilterValue: () => <div>{getTimeValue(filterValue.timeFrame, filterValue.value)}</div>,

    DateWithinFilterValue: () => <div>{getDateWithinValue(filterValue.timeFrame, filterValue)}</div>,

    NumberWithinFilterValue: () => (
      <div>
        {filterValue.rangeFrom} to {filterValue.rangeTo}
      </div>
    ),

    TimeFrameWithinFilterValue: () => (
      <div className="flex flex-col items-center text-sm">
        <span>{moment(filterValue.rangeFrom).format(DATE_FORMAT)}</span>
        <span>{i18n.t('audiences:rules.to')}</span>
        <span>{moment(filterValue.rangeTo).format(DATE_FORMAT)}</span>
      </div>
    ),

    default: () => <div>{filterValue.value}</div>,
  };

  const getFilterVisibleName = filterMap[filterValue.clazz] || filterMap.default;
  return getFilterVisibleName();
};
