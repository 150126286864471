import React, { useState } from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { buildUrl, useApiWithState } from '~/common';
import Link from '~/components/src/Link';
import DeletePopup from '~/components/src/DeletePopup';
import { UITable } from '~/components/src/UITable';
import { Heading, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { RowActions } from '~/components/src/Table/components';
import { showSuccess } from '~/notificationCenter';
import { deleteCookiePermissions, getCookiePermissions } from '../dataService';
import { TCookiePermission } from '../types';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';
import CookiePermissionVariantModal from './CookiePermissionVariantModal';

const columnHelper = createColumnHelper<TCookiePermission>();

type TCookiePermissionProps = { hasTagEdit: boolean };

const CookiePermissionSettings = ({ hasTagEdit }: TCookiePermissionProps) => {
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedCookie, setSelectedCookie] = useState<TCookiePermission | null>(null);

  const { state, isLoading, refetch } = useApiWithState(() => getCookiePermissions(), []);

  const handleDelete = async () => {
    if (selectedCookie) {
      await deleteCookiePermissions(selectedCookie?.cookiePermissionSettingKey?.settingsId);
      showSuccess({ body: i18n.t('cookie:permissionCookieFrom.api.deleted') });
      setSelectedCookie(null);
      setShowDeleteDialog(false);
      refetch();
    }
  };

  const getColumns = (
    setSelectedCookie: React.Dispatch<React.SetStateAction<TCookiePermission | null>>,
    setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  ) => [
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('cookie:list.permission.table.name')}</div>,
      cell: info => (
        <div className="text-left">
          <Link
            href={buildUrl(
              `tagmanagement/cookiePermission/view/${info?.row?.original?.cookiePermissionSettingKey?.settingsId}`,
            )}
          >
            {info.getValue()}
          </Link>
        </div>
      ),
      size: 700,
    }),

    columnHelper.accessor('lastModified', {
      header: () => <div className="text-left">{i18n.t('cookie:list.permission.table.lastEdit')}</div>,
      cell: info => (
        <div className="flex flex-col text-left">
          <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>
          <span className="text-xs text-r42-blue">{moment(info.getValue()).format(TIME_FORMAT)}</span>
        </div>
      ),
      size: 500,
    }),
    columnHelper.accessor('actions', {
      header: () => <div className="text-center">{i18n.t('cookie:list.permissionGroup.table.actions')}</div>,
      cell: info => {
        const actions = [
          {
            name: i18n.t('cookie:list.permission.delete'),
            tooltip: hasTagEdit
              ? i18n.t('cookie:list.permission.delete')
              : i18n.t('cookie:list.permission.noPermission'),
            isDisabled: !hasTagEdit,
            icon: 'delete',
            onClick: () => {
              setSelectedCookie(info?.row?.original);
              setShowDeleteDialog(true);
            },
            testHook: 'deleteDc',
            type: 'delete',
          },
        ];
        return (
          <div className="text-center">
            <RowActions actions={actions} />
          </div>
        );
      },
      size: 100,
    }),
  ];

  if (isLoading) return <Spin className="mt-8" />;
  return (
    <>
      <Heading kind="h1" title={i18n.t('cookie:tab.cookiePermissions')}>
        <BtnIcon
          tooltip={i18n.t('cookie:list.permission.add')}
          color="blue"
          icon="add"
          testHook="addVariant"
          onClick={() => {
            setShowVariantModal(true);
          }}
        />
      </Heading>
      <UITable
        data={state ?? []}
        testHook="cookiePermissionSettingsTable"
        emptyMessage={i18n.t('cookie:list.permission.empty.header')}
        emptyBody={i18n.t('cookie:list.permission.empty.body')}
        columns={getColumns(setSelectedCookie, setShowDeleteDialog)}
      />

      {showDeleteDialog && (
        <DeletePopup
          title={i18n.t('cookie:permissionCookieFrom.delete.header')}
          hidePopup={() => {
            setShowDeleteDialog(false);
            setSelectedCookie(null);
          }}
          onClick={() => handleDelete()}
        >
          <Notification>
            {i18n.t('cookie:permissionCookieFrom.delete.message', { name: selectedCookie?.name })}
          </Notification>
        </DeletePopup>
      )}
      {showVariantModal && <CookiePermissionVariantModal setShowVariantModal={setShowVariantModal} />}
    </>
  );
};

export default CookiePermissionSettings;
