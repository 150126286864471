(function() {
    VariablesListController.$inject = ['OVERVIEW_TYPES_CONSTANTS'];

    function VariablesListController(OVERVIEW_TYPES_CONSTANTS) {
        var vm = this;

        vm.$onInit = function $onInit() {
            // Data
            vm.variable = null;
            vm.sourceModule = OVERVIEW_TYPES_CONSTANTS.variable;
            vm.isGettingProps = false;
            // Methods
            vm.closeOverview = closeOverview;
            vm.openOverview = openOverview;
        };

        function openOverview(variable) {
            vm.variable = variable;
        }

        function closeOverview() {
            vm.variable = null;
        }

    }

    angular.module('webUi.component.profiles.variables.variablesList', ['webUi.service.profilesConstants'])

        .component('variablesList', {
            templateUrl: 'component/profiles/variables/variablesList/variablesList.tpl.html',
            controller: VariablesListController,
            controllerAs: 'vm',
            bindings: {
                variables: '<',
                onRemove: '<',
                hasProfilesEdit: '<'
            }
        });

})();
