import { combineReducers } from 'redux';
import { get } from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Audiences Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = Immutable({}), action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.GROUP_ADD_SUCCESS:
      return state.merge({
        [action.payload.id]: action.payload,
      });
    case types.GROUP_DELETE_SUCCESS:
      return state.without(action.payload.id);
    case types.MOVE_ITEM_SUCCESS: {
      nextState = state.merge({
        [action.payload.audienceId]: {
          ...state[action.payload.audienceId],
          parentId: action.payload.groupId,
        },
      });

      if (action.payload.groupId) {
        nextState = nextState.merge({
          [action.payload.groupId]: {
            ...state[action.payload.groupId],
            hasChildren: true,
            childrenIds: [...get(state, '[action.payload.audienceId].childrenIds', []), action.payload.audienceId],
          },
        });
      }
      return nextState;
    }
    case types.AUDIENCE_FETCH_SUCCESS:
      return state.merge({ ...action.payload }, { deep: true });
    case types.AUDIENCES_FETCH_SUCCESS:
      return state.merge({ ...action.payload.nodes });
    case types.AUDIENCE_DELETE_SUCCESS:
      if (action.payload.parentId) {
        const newChildren = state[action.payload.parentId].childrenIds.filter(id => id !== action.payload.id);
        nextState = state.merge({
          [action.payload.parentId]: {
            ...state[action.payload.parentId],
            childrenIds: newChildren,
            hasChildren: !!newChildren.length,
          },
        });
      } else {
        nextState = Immutable({ ...state });
      }
      return nextState.without(action.payload.id);
    case types.DEPENDANTS_INFO_FETCH_SUCCESS:
      return state.merge({
        [action.payload.id]: {
          ...state[action.payload.id],
          dependantsInfo: action.payload.dependantsInfo,
        },
      });
    default:
      return state;
  }
};

/**
 * Audiences Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = Immutable([]), action = defaultAction) => {
  switch (action.type) {
    case types.GROUP_ADD_SUCCESS:
      if (state.indexOf(action.payload.id) !== -1) {
        return state;
      }
      return Immutable([action.payload.id, ...state]);
    case types.AUDIENCES_FETCH_SUCCESS:
      return Immutable(Object.keys(action.payload.nodes || []));
    case types.GROUP_DELETE_SUCCESS:
    case types.AUDIENCE_DELETE_SUCCESS:
      return Immutable(state.filter(id => id !== action.payload.id));
    default:
      return state;
  }
};

const defaultUI = Immutable({
  isFetchingAll: false,
  isMovingItem: false,
  error: null,
  nodesQty: null,
  dependantsContainerIsVisible: false,
  selectedTab: 0,
  selectedId: null,
});

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.SEARCH_AUDIENCES:
    case types.AUDIENCES_FETCH_START:
      return state.set('isFetchingAll', true);
    case types.AUDIENCES_FETCH_SUCCESS:
    case types.AUDIENCES_FETCH_FAIL:
      return state.merge({
        isFetchingAll: false,
        nodesQty:
          action.payload && action.payload.totalNodes !== undefined ? action.payload.totalNodes : state.nodesQty,
      });
    case types.MOVE_ITEM_START:
      return state.set('isMovingItem', true);
    case types.MOVE_ITEM_SUCCESS:
    case types.MOVE_ITEM_FAIL:
      return state.set('isMovingItem', false);
    case types.SHOW_DEPENDANTS_CONTAINER:
      return state.merge({
        dependantsContainerIsVisible: true,
        selectedId: action.payload.audienceId,
      });
    case types.HIDE_DEPENDANTS_CONTAINER:
      return state.merge({
        dependantsContainerIsVisible: false,
        selectedId: null,
      });
    default:
      return state;
  }
};

/**
 * Audiences BreadCrumbs Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const breadcrumbs = (state = Immutable({}), action = defaultAction) => {
  switch (action.type) {
    case types.AUDIENCE_BREADCRUMBS_FETCH_SUCCESS:
      return state.merge({ ...action.payload });
    default:
      return state;
  }
};

const audiencesTreeReducer = combineReducers({
  byId,
  allIds,
  ui,
  breadcrumbs,
});

export default audiencesTreeReducer;
