angular.module('webUi.ui.error.404', ['webUi.ui.error.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.error.404', {
                url : '/404',
                templateUrl : 'ui/error/404/404.tpl.html',
                controller : 'Error404Controller'
            });
    }])

    .controller('Error404Controller', ['$rootScope',
        function Error404Controller(    $rootScope) {
            $rootScope.errorPage = true;
        }]);
