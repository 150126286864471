import i18n from '~/i18n';
import { StepDailyStatsOrigin } from '../Journey/types';

export const VARIANT_STATS_TABS = [
  { name: 'ALL', label: i18n.t('workflow:journey.chart.all') },
  {
    name: 'BATCH',
    label: i18n.t('workflow:journey.chart.batch'),
    description: i18n.t('workflow:journey.chart.batchDescription'),
  },
];

export const ORIGIN_TYPES = {
  DEFAULT: 'DEFAULT' as StepDailyStatsOrigin,
  HISTORIC_PROCESSING: 'HISTORIC_PROCESSING' as StepDailyStatsOrigin,
};

export const TRIGGER_STATS_TABS = [
  { name: ORIGIN_TYPES.DEFAULT, label: i18n.t('workflow:journey.chart.all') },
  {
    name: ORIGIN_TYPES.HISTORIC_PROCESSING,
    label: i18n.t('workflow:journey.chart.batched'),
    description: i18n.t('workflow:journey.chart.batchDescription'),
  },
];

export const EXPERIMENTS_STATS_TABLE_COLUMNS = [
  {
    id: 'profilesEntered',
    label: i18n.t('workflow:stats.experiments.table.header.entered'),
    show: true,
  },
  {
    id: 'profilesEnteredHp',
    label: i18n.t('workflow:stats.experiments.table.header.enteredViaBatch'),
    show: true,
  },
  {
    id: 'totalProfilesReached',
    label: i18n.t('workflow:stats.experiments.table.header.totalReached'),
    show: true,
  },
  {
    id: 'totalProfilesExitedCondition',
    label: i18n.t('workflow:stats.experiments.table.header.totalExitConditions'),
    show: true,
  },
  {
    id: 'goals',
    label: i18n.t('workflow:stats.experiments.table.header.goals'),
    show: true,
  },
  {
    id: 'exitConditions',
    label: i18n.t('workflow:stats.experiments.table.header.exitConditions'),
    show: true,
  },
];
