import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as _ from 'lodash';
import './step-styles.scss';
import './steps-styles.scss';

const Step = ({ text, onClick, index, activeStep }) => (
  <li className={cx('Step', { 'is-active': index < activeStep })} key={`step-body-${index}`}>
    <span
      className={cx('Step-counter', { 'is-active': index < activeStep }, { 'is-clickable': onClick })}
      onClick={onClick}
    >
      {index + 1}
    </span>
    {index + 1 === activeStep ? text : ''}
  </li>
);

Step.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number,
  activeStep: PropTypes.number,
};

const Steps = ({ steps, activeStep = 1, className, onClick, disabled }) => (
  <ul className={cx('Steps', className)}>
    {steps.map((step, index) => (
      <Step
        key={`step-${index}`}
        text={step.title}
        index={index}
        activeStep={activeStep}
        onClick={_.memoize(() => {
          if (step.onClick) {
            step.onClick();
          }
          if (onClick && !disabled) {
            onClick({ step: index + 1 });
          }
        })}
      />
    ))}
  </ul>
);

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  activeStep: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Steps;
