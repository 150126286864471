/**
 * @description Angular main module for Profiles -> Engagements
 * @memberOf webUi
 * @namespace webUi.ui.profiles.engagements
 */
angular.module('webUi.ui.profiles.engagements',
    [
        'webUi.ui.profiles.engagements.dashboard',
        'webUi.ui.profiles.engagements.form',
        'webUi.ui.profiles.engagements.view'
    ]
);
