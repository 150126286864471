angular.module('webUi.ui.tagmanagement.tags.conditions.environmentExclusion', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormEnvironmentExclusionConditionController', ['$scope', 'TagmanagementService', 'TagmanagementEnvironmentService', 
        function TagmanagementTagFormEnvironmentExclusionConditionController    ($scope,  TagmanagementService,   TagmanagementEnvironmentService) {
        
            TagmanagementEnvironmentService.getAll().then(function(environments) {
                $scope.environments = environments;
            });

            var conditionClazz = 'EnvironmentExclusionCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                environments: []
            };
        
            $scope.state.beforeSubmit = function() {
                return $scope.state.formCondition.environments.length > 0;
            };
                
            $scope.isExcluded = function(envId) {
                return _.contains($scope.state.formCondition.environments, envId);
            };
        }
    ]);

