import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { EditableCell, UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { updateRequiresAdmin, updateSampleRate } from '../dataService';
import { EnvironmentConfigurationModalProps, TEnvironment } from '../types';

const columnHelper = createColumnHelper<TEnvironment>();

const EnvironmentConfigurationModal = ({ environments, refetch, closeModal }: EnvironmentConfigurationModalProps) => {
  const [updatedEnvironments, setUpdatedEnvironments] = useState(environments);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEnvironmentUpdate = async () => {
    const isSubmitEnabled = !updatedEnvironments.find(env => env.sampleRate < 0 || env.sampleRate > 100);

    if (!isSubmitEnabled) return;
    setIsSubmitting(true);
    await updateRequiresAdmin(
      updatedEnvironments.map(({ environmentId, requiresAdmin }) => ({ environmentId, requiresAdmin })),
    );
    await updateSampleRate(updatedEnvironments.map(({ environmentId, sampleRate }) => ({ environmentId, sampleRate })));
    setIsSubmitting(false);
    showSuccess({ header: i18n.t('publishing:notifications.envConfigSuccess') });
    refetch();
    closeModal();
  };

  const handleUpdateRequiresAdmin = (environment: TEnvironment) => {
    setUpdatedEnvironments(
      updatedEnvironments.map(env => {
        if (env.environmentId === environment.environmentId) return { ...env, requiresAdmin: !env.requiresAdmin };
        return env;
      }),
    );
  };

  const getColumns = [
    columnHelper.accessor('environmentName', {
      header: () => <span>{i18n.t('publishing:environments.envName')}</span>,
      cell: info => <span className="t-environmentName">{info.getValue()}</span>,
      size: 200,
    }),
    columnHelper.accessor('environmentCode', {
      header: () => <span>{i18n.t('publishing:environments.envCode')}</span>,
      cell: info => <span className="t-environmentCode">{info.getValue()}</span>,
      size: 100,
    }),
    columnHelper.accessor('requiresAdmin', {
      header: () => <span>{i18n.t('publishing:environments.requiresAdmin')}</span>,
      cell: info => (
        <label className="t-overrideConsent inline-flex cursor-pointer items-center gap-3">
          <CheckboxElement
            id="requiresAdminField"
            value={info.getValue()}
            onChange={() => handleUpdateRequiresAdmin(info.row.original)}
          />
          <span className="truncate text-ellipsis">
            {i18n.t(`common:checkbox.${info.getValue() ? 'truthy' : 'falsy'}`)}
          </span>
        </label>
      ),
      size: 200,
    }),
    columnHelper.accessor('sampleRate', {
      header: () => <span>{i18n.t('publishing:environments.sampleRate')}</span>,
      cell: info => (
        <EditableCell
          {...info}
          testHook="sampleRateField"
          type="number"
          setData={setUpdatedEnvironments}
          min={0}
          max={100}
          hasError={info.getValue() < 0 || info.getValue() > 100}
          error={i18n.t('publishing:errors.sampleRate')}
        />
      ),
      size: 200,
    }),
  ];

  return (
    <>
      <UITable testHook="envConfig" data={updatedEnvironments} columns={getColumns} />
      <Notification className="mt-4" kind="information">
        {i18n.t('publishing:environments.note')}
      </Notification>
      <ActionButtons
        isConfirmEnabled={!isSubmitting}
        testHook="updateEnv"
        onDecline={() => closeModal()}
        onConfirm={() => handleEnvironmentUpdate()}
      />
    </>
  );
};

export default EnvironmentConfigurationModal;
