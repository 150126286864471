import { getPartnerInformation, getUserIdTypes } from '../../connectorFormService';
import { getPartnerInformation as getPartnerInformationSelector } from './doubleClickOfflineSelector';
import connectorFormActionTypes from '../../connectorFormActionTypes';
import constants from '../../constants';
import { selectFormValues } from '../../connectorFormSelector';

const doubleClickOfflineActionTypes = {
  FETCH_USER_ID_TYPES: {
    pending: 'FETCH_USER_ID_TYPES/pending',
    fulfilled: 'FETCH_USER_ID_TYPES/fulfilled',
    rejected: 'FETCH_USER_ID_TYPES/rejected',
  },
  FETCH_PARTNER_INFORMATION: {
    pending: 'FETCH_PARTNER_INFORMATION/pending',
    fulfilled: 'FETCH_PARTNER_INFORMATION/fulfilled',
    rejected: 'FETCH_PARTNER_INFORMATION/rejected',
  },
  SET_USER_ID_TYPES: 'SET_USER_ID_TYPES',
  SET_PARTNER_INFORMATION: 'SET_PARTNER_INFORMATION',
};

const fetchUserIdTypes = partner => dispatch => {
  dispatch({
    name: doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.pending,
    type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
  });

  return getUserIdTypes(partner)
    .then(payload => {
      dispatch({
        name: doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.fulfilled,
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
      });
      dispatch({
        name: doubleClickOfflineActionTypes.SET_USER_ID_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
        name: doubleClickOfflineActionTypes.FETCH_USER_ID_TYPES.rejected,
      });
    });
};

const fetchPartnerInformation = () => dispatch => {
  const currentUrl = window.location.href;
  const redirectUrl = `/${constants.DOUBLE_CLICK_OFFLINE_API_PARAM}/authorizationUrl?redirectUrl=${encodeURI(
    currentUrl,
  )}`;

  dispatch({
    name: doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.pending,
    type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
  });

  return getPartnerInformation(redirectUrl)
    .then(payload => {
      dispatch({
        name: doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.fulfilled,
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
      });
      dispatch({
        name: doubleClickOfflineActionTypes.SET_PARTNER_INFORMATION,
        data: payload.data,
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.DOUBLE_CLICK_OFFLINE,
        name: doubleClickOfflineActionTypes.FETCH_PARTNER_INFORMATION.rejected,
      });
    });
};

const redirectToAuthorizationUrl = () => (dispatch, getState) => {
  const partnerInformation = getPartnerInformationSelector(getState());
  window.location.href = partnerInformation.url;
  localStorage.setItem('partnerValues', JSON.stringify(selectFormValues(getState())));
};

export { doubleClickOfflineActionTypes, fetchUserIdTypes, redirectToAuthorizationUrl, fetchPartnerInformation };
