import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';
import cx from 'classnames';
import UsageIcons from './UsageIcons';

const { Table, Row, Cell, HeaderCell } = components;
const { withEmpty, withSort, withPaginate } = enhancements;

const PAGE_SIZE = 15;

const EmptyList = ({ t }) => <Empty header={t('property.empty.header')} body={t('property.empty.body')} />;

const createPropertyRow = (property, index, otherProps) => {
  const { selectedPropertyId, onDependantIconClick, interactionId, usedIn } = otherProps;
  const isSelected = selectedPropertyId && selectedPropertyId === property.name;

  return (
    <Row key={property.name} className={cx({ isSelected }, `t-${property.name}Row`, 't-propertiesListTableRow')}>
      <Cell className="u-size1of10">{index + 1}</Cell>
      <Cell className="u-size6of10">{property.name}</Cell>
      <Cell className="u-size3of10">
        <UsageIcons
          dependantTypes={property.dependantTypes}
          elementUsedIn={usedIn}
          elementId={interactionId}
          onDependantIconClick={(interactionId, index) => onDependantIconClick(interactionId, index, property.name)}
        />
      </Cell>
    </Row>
  );
};

export const PropertiesList = props => {
  const { list, stateKey, currentPage, t } = props;
  return (
    <Panel>
      <Table stateKey={stateKey} testHook="propertiesListTable">
        <Row>
          <HeaderCell className="u-size1of10">{t('property.number')}</HeaderCell>
          <SortableHeader className="u-size6of10" sortKey="name" title={t('property.name')} />
          <HeaderCell className="u-size3of10">{t('property.usedIn')}</HeaderCell>
        </Row>
        {list.map((property, index) => createPropertyRow(property, currentPage * PAGE_SIZE + index, props))}
      </Table>
    </Panel>
  );
};

PropertiesList.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  usedIn: PropTypes.array.isRequired,
  onDependantIconClick: PropTypes.func.isRequired,
  interactionId: PropTypes.string.isRequired,
  selectedPropertyId: PropTypes.string,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withSort(),
  withPaginate({ size: PAGE_SIZE }),
)(PropertiesList);
