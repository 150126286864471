import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from '~/common/withRouter';
import { fetchRuleTypes, fetchAllVariables } from '~/common/modules/rules/actions';
import {
  getRulesTree,
  getAllRuleVariables,
  isFetchingVariables as selectIsFetchingVariables,
} from '~/common/modules/rules/selectors';
import { RulesTypes } from '~/common/modules/rules/types';
import { getParam } from '~/common';
import i18n from '~/i18n';
import {
  fetchAudience,
  getEditableAudience,
  createAudience,
  updateAudience,
  isFetchingAudience as selectIsFetchingAudience,
  isSavingAudience,
} from '~/profiles/audiences';
import AudienceEdit from './presenter';
import { getServerConnectors, fetchConnectors } from '../../connectors';
import { getAllConnectorsForIntegrationsScreen } from './utils';

const editAudienceSteps = [
  { title: i18n.t('audiences:edit.configureAudience') },
  { title: i18n.t('audiences:edit.chooseAction') },
];

const mapStateToProps = (state, ownProps) => {
  const audience = getEditableAudience(ownProps?.params.audienceId)(state);
  const ruleTypesTree = getRulesTree(state, {
    [RulesTypes.FixedTime]: true,
    [RulesTypes.DynamicWaitTime]: true,
    [RulesTypes.JourneyOverlap]: true,
  });
  const allVariables = getAllRuleVariables(state);
  const serverConnectors = getServerConnectors(state);
  const allConnectors = getAllConnectorsForIntegrationsScreen(serverConnectors);
  const canSave = !isSavingAudience(state);
  const isFetchingVariables = selectIsFetchingVariables(state);
  const isFetchingAudience = selectIsFetchingAudience(state);

  return {
    initialValues: {
      propertiesWithUsage: [],
      updatedProperties: [],
    },
    groupId: getParam(ownProps.location.search, 'parent'),
    audience,
    ruleTypesTree,
    editAudienceSteps,
    allConnectors,
    allVariables,
    canSave,
    isFetchingAudience,
    isFetchingVariables,
  };
};

const mapDispatchToProps = dispatch => ({
  createAudience: (values, groupId) => dispatch(createAudience(values, groupId)),
  updateAudience: (values, audienceId) => dispatch(updateAudience(values, audienceId)),
  fetchAudience: audienceId => dispatch(fetchAudience(audienceId)),
  fetchRuleTypes: () => dispatch(fetchRuleTypes()),
  fetchConnectors: () => dispatch(fetchConnectors()),
  fetchAllVariables: isRefetch => dispatch(fetchAllVariables(isRefetch)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), translate('audiences'))(AudienceEdit);
