/**
 * Service methods to use with the tree
 */
angular.module('webUi.service.tree', ['webUi.common.Utils'])

    .factory('TreeService', ['Utils', '$q', function TreeService(Utils, $q) {

        var findNodeByNameRecursive = function (tree, nodeName) {
            var nodeNames = nodeName.split('|');
            var currentNode = tree;

            for (var i = 0; i < nodeNames.length; i++) {

                // search for node in current level
                var filteredNode = findNodeByName(currentNode, nodeNames.slice(0, i + 1).join('|'));

                if (_.isUndefined(filteredNode)) {
                    return currentNode;
                } else if (_.isUndefined(filteredNode.children)) {
                    return filteredNode;
                }

                if (!filteredNode.parent) {
                    filteredNode.parent = currentNode;
                }

                currentNode = filteredNode;
            }
            return currentNode;
        };

        var findNodeByNameExact = function (tree, nodeName) {

            if (_.isEmpty(nodeName)) {
                return;
            }

            var res = findNodeByNameRecursive(tree, nodeName);
            if (!_.isUndefined(res) && res.id && !_.isEqual(nodeName.toLowerCase(), res.id.toLowerCase())) {
                return;
            }
            return res;
        };

        var findNodeById = function (currentNode, id) {
            var i, currentChild, result;

            if (id === currentNode.id) {
                return currentNode;
            } else {

                //Use a for loop instead of forEach to avoid nested functions
                // Otherwise "return" will not work properly
                for (i = 0; i < currentNode.children.length; i++) {
                    currentChild = currentNode.children[i];

                    // Search in the current child
                    result = findNodeById(currentChild, id);

                    // Return the result if the node has been found
                    if (result !== false) {
                        return result;
                    }
                }
                // The node has not been found and we have no more options
                return false;
            }
        };

        var findNodeByIdRecursive = function (tree, id) {
            var i, result;
            if (_.isArray(tree)) {

                for (i = 0; i < tree.length; i++) {
                    result = findNodeById(tree[i], id);
                    if (result !== false) {
                        return result;
                    }
                }
            } else {
                return findNodeById(id);
            }
        };

        var findNodeByName = function (parentNode, id) {

            var searchStack = _.isArray(parentNode) ? parentNode : parentNode.children;

            return _.find(searchStack, function (node) {
                return node.id.toLowerCase() === id.toLowerCase();
            });
        };

        var nodeMatchesFilter = function (node, filterStr) {
            var getEscapeRegex = function (str) {
                return (str + '').replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
            };
            var match = getEscapeRegex(filterStr);
            var regex = new RegExp('.*' + match + '.*', 'i');

            var matchesNodeName = !!regex.exec(node.name);

            if (!matchesNodeName) {
            // check if any of the node searchable properties match the filter
                var searchableProperties = node.searchableProperties;
                if (!_.isUndefined(searchableProperties) && !_.isNull(searchableProperties) && _.isArray(searchableProperties)) {
                    for (var i = 0; i < searchableProperties.length; i++) {
                        var matchesProperty = !!regex.exec(searchableProperties[i].value);
                        if (matchesProperty) {
                            return matchesProperty;
                        }
                    }
                }
            }
            return matchesNodeName;
        };

        var expandParents = function (node) {
            var currentNode = node;
            while (!_.isUndefined(currentNode.parent)) {
                currentNode = currentNode.parent;
                currentNode.expanded = true;
            }
        };

        var deactivateChildren = function (node) {
            node.active = false;
            if (node.children) {
                _.map(node.children, deactivateChildren);
            }
        };

        /**
     * Check if any of the children of this node matches the filter string
     * @param node the current node
     * @filterStr the filter string value
     */
        var isSubmatch = function (node, filterStr) {
            var hasSubmatch = function (childNode, filterStr) {
                if (childNode) {
                    childNode.match = nodeMatchesFilter(childNode, filterStr);
                    if (childNode.match) {
                        return true;
                    } else {
                        if (childNode.children) {
                            for (var i = 0; i < childNode.children.length; i++) {
                                var has = hasSubmatch(childNode.children[i], filterStr);
                                if (has) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            };
            if (!node) {
                return false;
            }
            if (node.children) {
                for (var j = 0; j < node.children.length; j++) {
                    var has = hasSubmatch(node.children[j], filterStr);
                    if (has) {
                        return true;
                    }
                }
            }
            return false;
        };

        /**
     * Visit a tree stating at this node using the filterStr
     * If node matches search, mark it with match=true
     * Otherwise check if any of its children match and keep the node expanded
     * TODO add handling a callback for each node if needed
     */
        var visitNode = function (node, filterStr) {

            if (!node) {
                return;
            }
            node.match = nodeMatchesFilter(node, filterStr);
            if (node.match || isSubmatch(node, filterStr)) {
                node.expanded = true;
            }
            if (node.children && node.children.length > 0) {
                _.forEach(node.children, function (child) {
                    visitNode(child, filterStr);
                });
            }
        };

        var removeNode = function (node) {

            // node is not a top level node
            if (!_.isUndefined(node.parent) && !_.isArray(node.parent)) {
                _.remove(node.parent.children, function (child) {
                    var shouldBeRemoved = (child === node);
                    if (node.active && shouldBeRemoved) {
                        node.tree.hasActive = false;
                    }
                    return shouldBeRemoved;
                });
            } else {
                _.remove(node.tree, function (child) {
                    var shouldBeRemoved = (child === node);
                    if (node.active && shouldBeRemoved) {
                        node.tree.hasActive = false;
                    }
                    return shouldBeRemoved;
                });
            }
        };

        /**
     * Check if there's an onRemove callback attached to the node and call it
     * Only when the onRemove returns a valid result (aka not false) are we allowed to remove the node from the tree
     * @param node
     */
        var removeNodeWithCallback = function(node) {

            var deferred = $q.defer();

            // if a callback is specified, execute it and check its result before removing teh node
            if ( _.isFunction(node.onRemove) ) {
                var onRemoveCallbackResult = node.onRemove(node);

                if ( Utils.isPromise(onRemoveCallbackResult) ){
                    onRemoveCallbackResult.then(function(result){
                    // if callback result is false, don't remove
                        if ( result !== false ){
                        //proceed with removing the node from the tree
                            removeNode(node);
                            deferred.resolve(true);
                        } else {
                            deferred.resolve(false);
                        }
                    });
                } else if ( onRemoveCallbackResult !== false ){
                //proceed with removing the node from the tree
                    removeNode(node);
                    deferred.resolve(true);
                }
            } else {
            //proceed with removing the node from the tree
                removeNode(node);
                deferred.resolve(true);
            }
            return deferred.promise;
        };

        var removeNodeById = function removeNodeById(nodeId, tree) {
            var node = findNodeByIdRecursive(tree, nodeId);
            if (node) {
                removeNode(node, tree);
            }
        };

        var addNode = function (parentNode, nodeToAdd) {
            function getArrayToAddTo(toNode) {
                if (_.isArray(toNode)) {
                    return toNode;
                } else {
                    if (_.isUndefined(toNode.children)) {
                        toNode.children = [];
                    }
                    return toNode.children;
                }
            }

            function add(toArray, node) {
                var nodeName = node.name;
                if (!_.isString(nodeName)) {
                    return;
                }
                nodeName = nodeName.toLowerCase();
                for (var i = 0; i < toArray.length; i++) {
                    if (nodeName === toArray[i].name.toLowerCase()) {
                        return;
                    }
                }

                toArray.push(node);
            }

            var arrayToAddTo = getArrayToAddTo(parentNode);
            add(arrayToAddTo, nodeToAdd);
        };

        var activateNodeById = function (tree, nodeId) {
            var node = findNodeByIdRecursive(tree, nodeId);
            if (node) {
                activateNode(node, tree);
            }
        };

        var updateTreeNode = function (tree, nodeId, name) {
            var node = findNodeByIdRecursive(tree, nodeId);
            node.name = name;
        };

        var activateNode = function (node, tree) {

            // handles the on activate callback if it was defined
            function handleOnActivateCallback(node) {
                if (!_.isUndefined(node.onActivate) && _.isFunction(node.onActivate)) {
                    node.onActivate(node);
                }
            }

            var isActive = angular.copy(node.active);
            _.forEach(tree, function (eachNode) {
                deactivateChildren(eachNode);
            });

            expandParents(node);
            node.active = true;
            tree.hasActive = true;
            tree.activeNode = node;

            if (isActive) {
                return;
            } else {
                handleOnActivateCallback(node);
            }
        };

        return {
            clickNode: function (node) {
                if (!_.isUndefined(node.onClick) && _.isFunction(node.onClick)) {
                    node.onClick(node);
                }
            },
            visitNode: visitNode,
            isSubmatch: isSubmatch,
            removeNodeById: removeNodeById,
            removeNode: removeNode,
            removeNodeWithCallback: removeNodeWithCallback,
            addNode: addNode,
            findNodeByName: findNodeByNameRecursive,
            findNodeByNameExact: findNodeByNameExact,
            findNodeById: findNodeByIdRecursive,
            activateNode: activateNode,
            activateNodeById: activateNodeById,
            updateTreeNode: updateTreeNode,
            isVisible: function (node, filter) {
                return _.isEmpty(filter) || node.match || isSubmatch(node, filter);
            }
        };
    }]);
