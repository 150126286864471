import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserSettingsPage from './UserSettings';
import UserSettingsEditPage from './UserSettingsEdit';

const UserPages = () => (
  <Routes>
    <Route path="settings/view/*" element={<UserSettingsPage />} />
    <Route path="settings/edit" element={<UserSettingsEditPage />} />
  </Routes>
);

export default UserPages;
