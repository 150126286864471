import { pick } from 'lodash';
import * as fromModals from '~/modals';
import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess, showError } from '~/notificationCenter';

import * as types from './types';
import * as AudienceService from './dataService.js';
import * as AudienceTreeService from '../audiencesTree/dataService.js';
import { shapeCriteriaRequest, shapeIntegrationsRequest } from './utils';

export const fetchFullAudiences = () => dispatch => {
  dispatch({
    type: types.FULL_AUDIENCES_FETCH_START,
  });

  return AudienceService.getFullAudiences()
    .then(audiences => {
      dispatch({
        type: types.FULL_AUDIENCES_FETCH_SUCCESS,
        payload: audiences,
      });
    })
    .catch(error => dispatch({ type: types.FULL_AUDIENCES_FETCH_FAIL, error }));
};

export const fetchAudience =
  (id, cache = true) =>
  dispatch => {
    if (!id) return Promise.resolve();

    dispatch({
      type: types.AUDIENCE_FETCH_START,
      payload: {
        id,
      },
    });

    if (!cache) {
      dispatch({
        type: types.AUDIENCE_FETCH_ERASE,
      });
    }

    return Promise.all([AudienceService.getAudience(id), AudienceService.canDeleteAudience(id)])
      .then(([audience, deletability]) => {
        dispatch({
          type: types.AUDIENCE_FETCH_SUCCESS,
          payload: { [id]: { ...audience, deletability } },
        });
      })
      .catch(error => dispatch({ type: types.AUDIENCE_FETCH_FAIL, error }));
  };

export const fetchAudiencesTree = () => dispatch => {
  dispatch({
    type: types.AUDIENCES_TREE_FETCH_START,
  });
  return AudienceService.getAudiencesTree()
    .then(payload => {
      dispatch({
        type: types.AUDIENCES_TREE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.AUDIENCES_TREE_FETCH_FAIL, error }));
};

export const runHistoricProcessing = values => dispatch => {
  const segmentIds = values.audience ? Object.keys(values.audience).filter(key => values.audience[key]) : [];

  dispatch({
    type: types.HISTORIC_PROCESSING_START,
  });

  return AudienceService.triggerHistoricProcessing({
    segmentIds,
  })
    .then(() => {
      dispatch({
        type: types.HISTORIC_PROCESSING_SUCCESS,
        payload: { segmentIds },
      });
      dispatch(fetchFullAudiences()).then(() => {
        changeUrl('audiences/segments/list/0');
        showSuccess({ body: i18n.t('audiences:historicProcessing.success') });
      });
    })
    .catch(error => dispatch({ type: types.HISTORIC_PROCESSING_FAIL, error }));
};

export const deleteAudience = id => dispatch => {
  if (!id) return Promise.resolve();

  dispatch({
    type: types.AUDIENCE_REMOVE_START,
  });

  return AudienceService.deleteAudience(id)
    .then(() => {
      dispatch({
        type: types.AUDIENCE_REMOVE_SUCCESS,
        payload: { success: true },
      });
      changeUrl('audiences/segments/list/0');
      showSuccess({ body: i18n.t('audiences:audiencePage.audienceDeleted') });
    })
    .catch(error => dispatch({ type: types.AUDIENCE_REMOVE_FAIL, error }));
};

export const cloneAudience = (id, groupId) => dispatch => {
  if (!id) return Promise.resolve();

  dispatch({
    type: types.AUDIENCE_CLONE_START,
  });

  return AudienceService.cloneSegment(id)
    .then(cloned => {
      dispatch({
        type: types.AUDIENCE_CLONE_SUCCESS,
        payload: cloned,
      });
      const url = `audiences/segments/view/${cloned}`;
      showSuccess({ body: i18n.t('audiences:audiencePage.audienceCloned') });
      if (groupId) {
        AudienceTreeService.moveItem(groupId, cloned);
      }
      changeUrl(url);
    })
    .catch(error => {
      dispatch({ type: types.AUDIENCE_CLONE_FAIL, error });
      showError({ body: i18n.t('audiences:audiencePage.audienceCloneFailed') });
    })
    .finally(() => dispatch(fetchAudiencesTree()));
};

export const linkAudience = (elementId, groupId) => dispatch =>
  AudienceService.linkAudience(elementId, groupId)
    .then(() => showSuccess({ body: i18n.t('audiences:audiencePage.audienceMoved') }))
    .catch(() => showError({ body: i18n.t('audiences:audiencePage.audienceMoveFailed') }))
    .finally(() => dispatch(fetchAudiencesTree()));

export const openDeleteAudienceDialog = (id, title) => dispatch =>
  AudienceService.canDeleteAudience(id).then(res => {
    if (res.canDelete) {
      dispatch(
        fromModals.showModal(fromModals.DELETE_MODAL, {
          title: i18n.t('audiences:audiencePage.deleteModal.title'),
          message: i18n.t('audiences:audiencePage.deleteModal.message', { title }),
          onConfirm: () => dispatch(deleteAudience(id)),
        }),
      );
    } else {
      dispatch(
        fromModals.showModal(fromModals.NOTIFICATION_MODAL, {
          title: i18n.t('audiences:audiencePage.deleteNotificationModal.title'),
          notification: i18n.t('audiences:audiencePage.deleteNotificationModal.notification', {
            audience: title,
          }),
          confirmText: i18n.t('audiences:audiencePage.deleteNotificationModal.confirmText'),
        }),
      );
    }
  });

export const createAudience = (formData, groupId) => dispatch => {
  const criteria = shapeCriteriaRequest(formData.criteria);
  const integrations = shapeIntegrationsRequest(formData.integrations);

  dispatch({
    type: types.AUDIENCE_SAVE_START,
  });
  const payload = {
    id: null,
    name: formData.name,
    description: formData.description,
    lastModifiedBy: null,
    lastModifiedDate: null,
    criteria,
    integrations,
    enhancements: formData.enhancements,
  };
  return AudienceService.createAudience(payload)
    .then(data => {
      if (!groupId || !data) {
        return Promise.resolve(data);
      }
      return AudienceTreeService.moveItem(groupId, data);
    })
    .then(id => {
      dispatch({
        type: types.AUDIENCE_SAVE_SUCCESS,
        payload: { id },
      });
      changeUrl(`audiences/segments/view/${id}`);
      showSuccess({
        body: i18n.t('audiences:list.alerts.audienceHasBeenCreated'),
      });
    })
    .catch(error => {
      dispatch({
        type: types.AUDIENCE_SAVE_FAIL,
        error,
      });
    });
};

export const updateAudience = (formData, audienceId) => dispatch => {
  const criteria = shapeCriteriaRequest(formData.criteria);
  const integrations = shapeIntegrationsRequest(formData.integrations);

  dispatch({
    type: types.AUDIENCE_UPDATE_START,
  });
  const payload = {
    segmentId: audienceId,
    criteria,
    integrations,
    enhancements: formData.enhancements,
    ...pick(formData, [
      'name',
      'description',
      'deleted',
      'hasTagPartner',
      'description',
      'historicProcessingNeeded',
      'historicProcessingTriggered',
      'migrated',
      'segmentNumber',
    ]),
  };
  return AudienceService.updateAudience(payload)
    .then(() => {
      dispatch({
        type: types.AUDIENCE_UPDATE_SUCCESS,
        payload: { id: audienceId },
      });
      changeUrl(`audiences/segments/view/${audienceId}`);
      showSuccess({
        body: i18n.t('audiences:list.alerts.audienceHasBeenUpdated'),
      });
    })
    .catch(error => {
      dispatch({
        type: types.AUDIENCE_UPDATE_FAIL,
        error,
      });
    });
};
