import React, { ReactElement } from 'react';
import cx from 'classnames';
import Icons from '../Icons';
import icons from '../Icons/icons';

export const AlertType = {
  INFO: 'INFO',
  DANGER: 'DANGER',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DEFAULT: 'DEFAULT',
} as const;

export type AlertProps = {
  icon?: keyof typeof icons;
  prefix?: string;
  suffix?: string;
  type: keyof typeof AlertType;
  testHook?: string;
};

function Alert({
  icon = 'help',
  suffix,
  prefix,
  type = AlertType.DEFAULT,
  testHook = 'alert',
}: AlertProps): ReactElement {
  const style = {
    INFO: 'bg-blue-100 text-blue-700 dark:bg-blue-200 dark:text-blue-800',
    DANGER: 'bg-red-100 text-red-700 dark:bg-red-200 dark:text-red-800',
    SUCCESS: 'bg-green-100 text-green-700 dark:bg-green-200 dark:text-green-800',
    WARNING: 'bg-yellow-100 text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800',
    DEFAULT: 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300',
  };

  return (
    <div>
      <div className={cx('flex items-center gap-2 rounded-lg p-4 text-sm', style[type] || style.DEFAULT)} role="alert">
        {icon && <Icons icon={icon} className="h-6 w-6" />}
        <div className={`t-${testHook}`}>
          {prefix && <span className="font-medium">{prefix} </span>}
          {suffix}
        </div>
      </div>
    </div>
  );
}

export default Alert;
