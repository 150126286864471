import React, { useState, useCallback } from 'react';
import { FormSection, Field } from 'redux-form';
import { isRequired, isPositiveNumber } from '~/common';
import { ReduxFormInputField, ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import DropzoneElement from '~/components/src/Form/Elements/DropzoneElement';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import BtnOutlined from '~/components/src/BtnOutlined';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';
import './styles.scss';

const { CREATE_SERVER } = constants.modes;

const isValidJson = stringContent => {
  try {
    const parsed = JSON.parse(stringContent);
    // Checks that it's a normal object, not an array, null or something else.
    return Object.prototype.toString.call(parsed) === '[object Object]';
  } catch {
    return false;
  }
};

const jsonPlaceholder = `{
  "type": "service_account",
  "project_id": "...
  ...`;

const GooglePubSubForm = props => {
  const { t, canUpdate, mode, values, partner, change } = props;

  const containsValidJson = useCallback(
    fieldValue => (isValidJson(fieldValue) ? '' : t('validation:validation.mustBeValidJson')),
    [t],
  );

  const [isJsonCertificateFieldVisible, setIsJsonCertificateFieldVisible] = useState(!canUpdate);

  const [dropzoneState, setDropzoneState] = useState({
    hasError: false,
    message: (
      <>
        <div>{t('validation:validation.dropJsonCertificateHere')}</div>
        <div>{t('validation:validation.pasteJsonBelow')}</div>
      </>
    ),
  });

  const onDropAccepted = async acceptedFiles => {
    const jsonFile = acceptedFiles[0];
    const stringContent = await jsonFile.text();

    if (isValidJson(stringContent)) {
      change('GOOGLE_PUBSUB.certificate', stringContent);
      setDropzoneState({
        hasError: false,
        message: t('validation:validation.jsonUploadSuccess', { jsonFileName: jsonFile.name }),
      });
    } else {
      setDropzoneState({
        hasError: true,
        message: t('validation:validation.providedJsonFileIsInvalid'),
      });
    }
  };

  const onDropRejected = () => {
    setDropzoneState({
      hasError: true,
      message: t('validation:validation.onlyJsonFilesAccepted'),
    });
  };

  return (
    <FormSection name={constants.partnerTypes.GOOGLE_PUBSUB}>
      <FormHeader
        t={t}
        title={values.name}
        partner={partner}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        {mode === CREATE_SERVER && (
          <Field
            label={t('form.formFields.partnerNumberLabel')}
            name="partnerNumber"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={[isRequired, isPositiveNumber]}
            type="text"
          />
        )}
        <Field
          label={t('connectors:cards.label.project')}
          name="project"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('connectors:cards.label.topic')}
          name="topic"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
      <ContainerFormSection
        title={isJsonCertificateFieldVisible && t('form.formFields.serviceAccountJsonCertificate')}
        extra={
          isJsonCertificateFieldVisible && (
            <DropzoneElement
              accept={{ 'application/json': [] }}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              multiple={false}
              hasError={dropzoneState.hasError}
              className="ConnectorList-dropzone u-marginTopM"
            >
              {dropzoneState.message}
            </DropzoneElement>
          )
        }
      >
        {isJsonCertificateFieldVisible ? (
          <Field
            label={t('form.formFields.serviceAccountJsonCertificate')}
            name="certificate"
            className="GooglePubSubForm-jsonCertificateField"
            component={ReduxFormTextAreaField}
            placeholder={jsonPlaceholder}
            validate={[isRequired, containsValidJson]}
          />
        ) : (
          <BtnOutlined
            onClick={() => {
              setIsJsonCertificateFieldVisible(true);
            }}
            testHook="configureJsonCertificateButton"
            className="GooglePubSubForm-configureButton"
          >
            {t('form.formFields.configureServiceAccountJsonCertificate')}
          </BtnOutlined>
        )}
      </ContainerFormSection>
    </FormSection>
  );
};

export default GooglePubSubForm;
