import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '~/components/src/Spinner';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { SearchContainer, buildUrl } from '~/common';
import LinkIcon from '~/components/src/LinkIcon';
import Heading from '~/components/src/Heading';
import { fetchCollectors } from '../actions';
import CollectorsList from '../../components/CollectorsList';
import { getCollectors, isFetchingCollectors } from '../selectors';

const COLLECTORS_STATE_KEY = 'PROFILES/COLLECTORS';
const COLLECTORS_FILTER_KEY = 'PROFILES/COLLECTORS/COLLECTORS_SEARCH';

const searchFilterFn = query => item => item.collectorName.toLowerCase().indexOf(query.toLowerCase()) !== -1;

class CollectorsContainer extends Component {
  componentDidMount() {
    this.props.fetchCollectors();
  }
  render() {
    const { isFetching, t, canEditProfiles, itemUrl } = this.props;
    if (isFetching) {
      return <Spinner />;
    }
    return (
      <div>
        <Heading title={t('list.header')}>
          <SearchContainer
            stateKey={COLLECTORS_STATE_KEY}
            searchKey={COLLECTORS_FILTER_KEY}
            searchFn={searchFilterFn}
          />
          {canEditProfiles && (
            <LinkIcon
              icon="add"
              color="blue"
              testHook="addNew"
              tooltip={t('list.addNew')}
              href={buildUrl('profiles/collectors/add')}
              className="h-8 w-8"
            />
          )}
        </Heading>
        <CollectorsList list={this.props.list} stateKey={this.props.stateKey} t={t} itemUrl={itemUrl} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: 'PROFILES/COLLECTORS',
  list: getCollectors(state),
  isFetching: isFetchingCollectors(state),
  itemUrl: item => buildUrl(`profiles/collectors/view/${item}`),
});

const mapDispatchToProps = dispatch => ({
  fetchCollectors: () => dispatch(fetchCollectors()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('collectors'))(CollectorsContainer);
