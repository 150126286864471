import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Customer Facts Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  let prevstate;
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.customerFactId]: {
          ...action.payload,
        },
      };
    case types.CUSTOMER_FACT_ACTIVITY_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_PROPERTIES_FETCH_SUCCESS:
      nextState = { ...state };
      prevstate = nextState[action.payload.id] || {};
      nextState[action.payload.id] = {
        ...prevstate,
        insights: {
          ...prevstate.insights,
          ...action.payload,
        },
      };
      return nextState;
    case types.CUSTOMER_FACT_ADD_PROPERTY_SUCCESS: {
      const nextState = { ...state };
      const { externalFactId } = action.payload;
      const prevState = nextState[externalFactId] || {};
      const prevInsights = prevState.insights || {};
      const missingProperties = prevInsights.missingProperties || [];
      const usedProperties = prevInsights.usedProperties || [];
      const addedProperties = missingProperties.filter(prop => action.payload.properties.includes(prop.name));
      const updatedInsights = {
        ...prevInsights,
        missingProperties: missingProperties.filter(prop => !action.payload.properties.includes(prop.name)),
        usedProperties: addedProperties.length ? [...usedProperties, ...addedProperties] : usedProperties,
      };

      nextState[externalFactId] = {
        ...prevState,
        ...action.payload,
        insights: updatedInsights,
      };
      return nextState;
    }
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(customerFact => {
        nextState[customerFact.customerFactId] = {
          ...nextState[customerFact.customerFactId],
          ...customerFact,
          activity: nextState[customerFact.customerFactId]?.activity ?? null,
        };
      });
      return nextState;
    case types.CUSTOMER_FACTS_ACTIVITIES_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(activity => {
        const customerFactId = activity.eventId;
        if (!nextState[customerFactId]) {
          nextState[customerFactId] = { activity: activity.eventActivity };
        } else {
          nextState[customerFactId] = {
            ...nextState[customerFactId],
            activity: activity.eventActivity,
          };
        }
      });
      return nextState;
    case types.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Customer facts Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_SAVE_SUCCESS:
      if (state.indexOf(action.payload.customerFactId) !== -1) {
        return state;
      }
      return [...state, action.payload.customerFactId];
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
      return action.payload.map(customerFact => customerFact.customerFactId);
    case types.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = [...state];
      nextState.splice(
        state.findIndex(item => item === action.payload),
        1,
      );
      return nextState;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingCustomerFact: false,
  isFetchingCustomerFactActivity: false,
  isFetchingCustomerFactTrafficSources: false,
  isFetchingCustomerFactProperties: false,
  isFetchingCustomerFacts: false,
  isFetchingCustomerFactsActivities: false,
  dependantsContainerIsVisible: false,
  selectedId: null,
  selectedTab: 0,
  userIsEditing: false,
  isPropertyBeingAdded: false,
  error: null,
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFact: true,
      };
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFact: false,
      };
    case types.CUSTOMER_FACT_ACTIVITY_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFactActivity: true,
      };
    case types.CUSTOMER_FACT_ACTIVITY_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_ACTIVITY_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFactActivity: false,
      };
    case types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFactTrafficSources: true,
      };
    case types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFactTrafficSources: false,
      };
    case types.CUSTOMER_FACT_PROPERTIES_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFactProperties: true,
      };
    case types.CUSTOMER_FACT_PROPERTIES_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_PROPERTIES_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFactProperties: false,
      };
    case types.CUSTOMER_FACTS_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFacts: true,
      };
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
    case types.CUSTOMER_FACTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFacts: false,
      };
    case types.CUSTOMER_FACTS_ACTIVITIES_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFactsActivities: true,
      };
    case types.CUSTOMER_FACTS_ACTIVITIES_FETCH_SUCCESS:
    case types.CUSTOMER_FACTS_ACTIVITIES_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFactsActivities: false,
      };
    case types.CUSTOMER_FACT_ADD_PROPERTY_START:
      return {
        ...state,
        isPropertyBeingAdded: true,
      };
    case types.CUSTOMER_FACT_ADD_PROPERTY_SUCCESS:
    case types.CUSTOMER_FACT_ADD_PROPERTY_FAIL:
      return {
        ...state,
        isPropertyBeingAdded: false,
      };
    case types.SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedId: action.payload.propertyId || action.payload.customerFactId,
        selectedTab: action.payload.selectedTab,
      };
    case types.HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedId: null,
        selectedTab: 0,
      };
    case types.DEPENDANTS_TYPE_TAB_CHANGE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case types.SET_USER_IS_EDITING:
      return {
        ...state,
        userIsEditing: action.payload,
      };
    default:
      return state;
  }
};

const customerFactsReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default customerFactsReducer;
