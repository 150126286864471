import React from 'react';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { translate } from 'react-i18next';
import LinkIcon from '~/components/src/LinkIcon';
import EnvironmentsContainer from '../environments/containers/EnvironmentsContainer';

export const EnvironmentsViewPage = ({ t, isSiteAdmin }) => (
  <Page>
    <Heading title={t('list.header')}>
      <LinkIcon
        color="blue"
        url={buildUrl('admin/environments/add')}
        tooltip={t('list.addNew')}
        disabled={!isSiteAdmin}
        testHook="addNew"
        icon="add"
        className="h-8 w-8"
      />
    </Heading>
    <EnvironmentsContainer isSiteAdmin={isSiteAdmin} />
  </Page>
);

export default translate('environments')(EnvironmentsViewPage);
