import { get } from 'lodash';

/**
 * Get the user info
 * @param state
 * @returns the user info
 */
export const getUserInfo = state => get(state, 'user.settings.userInfo');

/**
 * Get the accessible sites for the user
 * @param state
 * @returns the accessible sites
 */
export const getAccessibleSites = state => get(state, 'user.settings.accessibleSites');
/**
 * Is fetching the user settings currently in progress?
 * @param state
 */
export const isFetchingUserSettings = state => get(state, 'user.settings.ui.isFetchingUserSettings');

/**
 * Should the user settings bet fetched?
 * @param state
 */
export const shouldFetchUserSettings = state => get(state, 'user.settings.ui.shouldFetchUserSettings');

/**
 * Get the sites that can be set as default for the current user
 * @param state
 * @returns the default sites
 */
export const getDefaultSites = state => {
  const accessibleSites = get(state, 'user.settings.accessibleSites');
  return Array.isArray(accessibleSites) ? accessibleSites.filter(site => site.canBeDefaultSite) : [];
};

export const getSmsTokenStep = state => get(state, 'user.settings.ui.smsTokenStep');

export const getTimebasedTokenStep = state => get(state, 'user.settings.ui.timebasedTokenStep');

export const getPhoneNumber = state => get(state, 'user.settings.userInfo.phoneNumber');

export const getIsRequestPasswordFailed = state => get(state, 'user.settings.ui.isRequestPasswordFailed');

export const getSmsCode = state => get(state, 'user.settings.userInfo.smsCode');

export const getErrorPasswordRequest = state => get(state, 'user.settings.errorRequest.updatePassword');

export const getErrorUpdatePhoneRequest = state => get(state, 'user.settings.errorRequest.updatePhone');

export const getErrorSmsRequest = state => get(state, 'user.settings.errorRequest.smsToken');

export const getErrorTimeBasedTokenRequest = state => get(state, 'user.settings.errorRequest.timeBasedToken');
