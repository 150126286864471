import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeUrl, buildUrl, getSiteId } from '~/common';
import * as fromModals from '~/modals';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import Heading from '~/components/src/Heading';
import LinkIcon from '~/components/src/LinkIcon';
import Notification from '~/components/src/Notification';
import Spinner from '~/components/src/Spinner';
import OverlaySpinner from '~/components/src/OverlaySpinner';

import {
  startExport,
  fetchConfigurations,
  deleteConfiguration,
  getConfigurations,
  isFetchingConfigurations,
  isRunningReport,
  getLatestError,
} from '../exportConfigurations';
import ExportConfigList from '../../components/ExportConfigList';

const redirectToEditPage = configId => {
  changeUrl(`admin/urlbuilder/configuration/edit/${configId}`);
};
class ExportConfigContainer extends Component {
  componentDidMount() {
    this.props.fetchConfigurations();
  }

  render() {
    const { error, isRunningReport, isFetching, onDeleteClick, onStartClick, isSiteAdmin, stateKey, t, list } =
      this.props;
    if (isFetching) {
      return <Spinner />;
    }
    return (
      <section>
        <Heading title={t('urlBuilder:exportConfigurations.header')} kind="h2">
          <LinkIcon
            icon="download"
            className="h-8 w-8"
            url={`assets/${getSiteId()}/urlbuilder/sampleReport`}
            disabled={!isSiteAdmin}
            target="_blank"
            testHook="sampleReport"
            tooltip={t('urlBuilder:exportConfigurations.downloadSampleButton')}
          />
          <LinkIcon
            color="blue"
            className="h-8 w-8"
            url={buildUrl('admin/urlbuilder/configuration/add')}
            disabled={!isSiteAdmin}
            testHook="addConfig"
            tooltip={t('urlBuilder:exportConfigurations.newConfigurationButton')}
            icon="add"
          />
        </Heading>
        {error && (
          <Notification kind="error" header={t('urlBuilder:errors.connectionError')}>
            {error}
          </Notification>
        )}
        <ExportConfigList
          list={list}
          stateKey={stateKey}
          onStartClick={onStartClick}
          onEditClick={redirectToEditPage}
          onDeleteClick={onDeleteClick}
          t={t}
          isSiteAdmin={isSiteAdmin}
        />
        <OverlaySpinner isActive={isRunningReport} />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: 'ADMIN/URLBUILDER/EXPORTCONFIG',
  list: getConfigurations(state),
  isFetching: isFetchingConfigurations(state),
  isRunningReport: isRunningReport(state),
  error: getLatestError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchConfigurations: () => dispatch(fetchConfigurations()),
  onStartClick: item => dispatch(startExport(item.configId)),
  onDeleteClick: (exportConfig, t) => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: t('urlBuilder:exportConfigurations.deleteConfirmationModal.header'),
        message: t('urlBuilder:exportConfigurations.deleteConfirmationModal.message', {
          name: exportConfig.configuration.connectionType.name,
          host: exportConfig.configuration.host,
          port: exportConfig.configuration.port,
        }),
        onConfirm: () => dispatch(deleteConfiguration(exportConfig.configId)),
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('urlBuilder'))(ExportConfigContainer);
