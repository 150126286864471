import { get } from 'lodash';

/**
 * Get all users for current site
 * @param state
 */
export const getUsersForCurrentSite = state => get(state, 'admin.users.list');

/**
 * Is currently fetching site users
 * @param state
 */
export const isFetchingUsers = state => get(state, 'admin.users.ui.isFetchingUsers');

const selectors = {
  getUsersForCurrentSite,
  isFetchingUsers,
};

export default selectors;
