angular.module('webUi.ui.tagmanagement.tags.websitePathGroups.groupList', ['webUi.ui.tagmanagement.tags.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tags.groupList', {
                url : '/websitePathGroups',
                leftMenu : true,
                views : {
                    'mainContent@': {
                        controller: 'WebsitePathGroupListController',
                        templateUrl: 'ui/tagmanagement/tags/websitePathGroups/list/groupList.tpl.html'
                    }
                }
            });
    }])

    .controller('WebsitePathGroupListController', ['PubSubService', function(PubSubService){
        PubSubService.publishTagMenuLocationChanged({ type: 'groups' });
    }]);
