import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl, getParam } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { fetchTimezonesIfNeeded, fetchSiteModulesIfNeeded, saveSite } from '~/context/sites/actions';
import * as selectors from '~/context/sites/selectors';
import * as fromClients from '~/context/clients';
import SiteForm from '~/context/components/SiteForm';
import { MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import { DEFAULT_AI_MODEL_LIMIT, DEFAULT_JO_LIMIT, DEFAULT_MAX_INPUTS_FOR_AI_MODEL } from './Sites';

const FORM_NAME = 'siteAddPage';
const translation = 'sites';

export class SiteAddPage extends Component {
  componentDidMount() {
    const { fetchTimezones, fetchSiteModules, fetchClients } = this.props;
    fetchTimezones();
    fetchSiteModules();
    fetchClients();
  }
  render() {
    const { props } = this;
    const { t } = props;

    return (
      <Page>
        <MediumWrapper>
          <Heading title={t('new.header')} />
          <SiteForm {...props} />
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const formState = state.form[FORM_NAME];

      const initialValues = {
        clientId: getParam(ownProps.location.search, 'clientId') || null,
        advancedSecurity: false,
        allowTruncate: false,
        demo: false,
        siteModules: {},
        maxJourneyLimit: DEFAULT_JO_LIMIT,
        maxAiModelLimit: DEFAULT_AI_MODEL_LIMIT,
        maxInputsForAIModel: DEFAULT_MAX_INPUTS_FOR_AI_MODEL,
      };
      return {
        cancelHref: buildUrl('context/sites/list'),
        timezones: selectors.getTimezonesForSelect(state),
        siteModules: selectors.getSiteModules(state),
        clients: fromClients.getClientsForSelect(state),
        initialValues,
        isAIModuleEnabled: formState?.values?.siteModules?.RECOMMENDER || false,
        isJOModuleEnabled: formState?.values?.siteModules?.CUSTOMER_JOURNEY || false,
        formState: state.form[FORM_NAME],
      };
    },
    dispatch => ({
      fetchTimezones: () => dispatch(fetchTimezonesIfNeeded()),
      fetchSiteModules: () => dispatch(fetchSiteModulesIfNeeded()),
      fetchClients: () => dispatch(fromClients.fetchClientsIfNeeded()),
      onSubmit: values => dispatch(saveSite(values)),
    }),
  ),
  reduxForm({
    form: FORM_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([translation]),
)(SiteAddPage);
