/**
 * @description Angular module for Content -> campaigns2 -> Dashboard
 * @class ContentCampaignsDashboardController
 * @namespace webUi.ui.content.campaigns2.dashboard
 * @memberOf webUi.ui.content.campaigns2
 */
angular.module('webUi.ui.content.campaigns2.dashboard', [
    'webUi.ui.content.campaigns2.base'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns2.dashboard', {
                url : '/dashboard',
                isReact: true,
            });
    }]);