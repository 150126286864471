import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormSelectField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection } from '~/components/src/Containers';
import { isRequired } from '~/common';

const ExportTypeForm = props => {
  const { t, requestTypes, exportTypes, formValues, touch } = props.data;
  const exportDescriptionAndFields = {
    BANNER_VIEWS: {
      description: t('new.exportTypes.bannerViews.description'),
      fields: t('new.exportTypes.bannerViews.fields'),
    },
    CONVERSIONS: {
      description: t('new.exportTypes.conversions.description'),
      fields: t('new.exportTypes.conversions.fields'),
    },
    CONVERSION_SESSIONS: {
      description: t('new.exportTypes.conversionSessions.description'),
      fields: t('new.exportTypes.conversionSessions.fields'),
    },
    CONVERSION_BY_TYPE: {
      description: t('new.exportTypes.conversionByType.description'),
      fields: t('new.exportTypes.conversionByType.fields'),
    },
    SESSIONS: {
      description: t('new.exportTypes.sessions.description'),
      fields: t('new.exportTypes.sessions.fields'),
    },
    ENGAGEMENTS: {
      description: t('new.exportTypes.engagements.description'),
      fields: t('new.exportTypes.engagements.fields'),
    },
    TRACKING_EVENTS_STREAM: {
      description: t('new.exportTypes.historicEventExport.description'),
      fields: t('new.exportTypes.historicEventExport.fields'),
    },
  };
  return (
    <FormSection
      title={t('new.typeHeader')}
      description={
        formValues?.type?.exportType && exportDescriptionAndFields[formValues?.type?.exportType]?.description
      }
    >
      <Field
        label={t('new.requestTypesTitle')}
        name="requestType"
        component={ReduxFormSelectField}
        options={requestTypes}
        validate={isRequired}
        touch={touch}
      />

      <Field
        label={t('new.exportTypesTitle')}
        name="exportType"
        component={ReduxFormSelectField}
        options={exportTypes}
        validate={isRequired}
        hint={exportDescriptionAndFields[formValues?.type?.exportType]?.fields}
        touch={touch}
      />
    </FormSection>
  );
};

export default ExportTypeForm;
