import { get, size } from 'lodash';
import i18n from '~/i18n';
import * as dependencySelectors from '../dependencies/selectors';
/**
 * Get customer fact By Id
 * @param state
 * @param customerFactId
 * @returns {*}
 */
export const getCustomerFactById = (state, id) => {
  const customerFact = get(state, `profiles.customerFacts.byId[${id}]`);
  const types = {
    AI: 'customerFacts:general.typeAI',
    EXTERNAL: 'customerFacts:general.typeExternal',
  };

  if (customerFact && types[customerFact.type]) {
    customerFact.typeLabel = i18n.t(types[customerFact.type]);
  }

  return customerFact;
};

/**
 * Get the currently selected customer fact with its dependants info
 * @param state
 * @param customerFactId
 */
export const getSelectedCustomerFact = (state, id) => {
  const customerFact = getCustomerFactById(state, id);
  const customerFactDependantsInfo = dependencySelectors.getDependantsInfoById(state, id);
  return {
    ...customerFact,
    dependantsInfo: customerFactDependantsInfo,
  };
};

/**
 * Get all customer facts
 * @param state
 */
export const getCustomerFacts = state => state.profiles.customerFacts.allIds.map(id => getCustomerFactById(state, id));

/**
 * Get the names of all the customer facts
 * @param state
 */
export const getAllCustomerFactNames = state =>
  state.profiles.customerFacts.allIds.map(id => getCustomerFactById(state, id).name);

/**
 * Is customer fact fetch currently in progress?
 * @param state
 */
export const isFetchingCustomerFact = state => get(state, 'profiles.customerFacts.ui.isFetchingCustomerFact');

/**
 * Is customer fact activity fetch currently in progress?
 * @param state
 */
export const isFetchingCustomerFactActivity = state =>
  get(state, 'profiles.customerFacts.ui.isFetchingCustomerFactActivity');

/**
 * Is customer fact properties fetch currently in progress?
 * @param state
 */
export const isFetchingCustomerFactProperties = state =>
  get(state, 'profiles.customerFacts.ui.isFetchingCustomerFactProperties');

/**
 * Is customer fact traffic sources fetch currently in progress?
 * @param state
 */
export const isFetchingCustomerFactTrafficSources = state =>
  get(state, 'profiles.customerFacts.ui.isFetchingCustomerFactTrafficSources');

/**
 * Is adding property in progress
 * @param state
 */
export const isPropertyBeingAdded = state => get(state, 'profiles.customerFacts.ui.isPropertyBeingAdded');

/**
 * Is fetching all engagement activities currently in progress?
 * @param state
 */
export const isFetchingCustomerFactsActivities = state =>
  get(state, 'profiles.customerFacts.ui.isFetchingCustomerFactsActivities');

/**
 * Is fetching all customer facts currently in progress?
 * @param state
 */
export const isFetchingCustomerFacts = state => get(state, 'profiles.customerFacts.ui.isFetchingCustomerFacts');

/**
 * Is the dependants container visible?
 * @param state
 */
export const isDependantsContainerVisible = state =>
  get(state, 'profiles.customerFacts.ui.dependantsContainerIsVisible');

/**
 * Get the currently selected customer facts/property
 * @param state
 */
export const getSelectedId = state => get(state, 'profiles.customerFacts.ui.selectedId');

/**
 * Get the currently selected tab
 * @param state
 */
export const getSelectedTab = state => get(state, 'profiles.customerFacts.ui.selectedTab');

/**
 * Get the currently selected customer fact/property
 * @param customerFactId the customer fact id
 * @param propertyId the property id, can be null
 * @param state
 */
export const getSelectedProperty = (state, customerFactId, propertyId) => {
  if (customerFactId === propertyId) {
    return getSelectedCustomerFact(state, customerFactId);
  }
  const propertyDependantsInfo = dependencySelectors.getPropertyDependantsInfoById(state, customerFactId, propertyId);
  return {
    name: propertyId,
    dependantsInfo: propertyDependantsInfo,
  };
};

/**
 * Get all the data for the customer facts needed for showing in table
 * @param state
 */
export const getCustomerFactsTableInfo = state =>
  getCustomerFacts(state).map(customerFact => ({
    ...customerFact,
    numberOfUsedProperties: size(customerFact.usedProperties),
    numberOfProperties: size(customerFact.properties),
  }));

/**
 * Get all the data for the customer fact properties needed for showing in a table
 * @param state
 * @param customerFactId the id of the customer fact
 */
export const getPropertiesTableInfo = (state, customerFactId) => {
  const customerFact = getCustomerFactById(state, customerFactId);
  if (!customerFact) {
    return [];
  }

  const propertiesInsights = customerFact.insights?.usedProperties || [];

  return customerFact.properties.map(propertyName => {
    const propertyWithDependants = customerFact.usedProperties.find(usedProperty => usedProperty.name === propertyName);
    const usedPropertiesInsights = propertiesInsights.find(usedProperty => usedProperty.name === propertyName);

    return {
      name: propertyName,
      dependantTypes: propertyWithDependants?.dependantTypes || [],
      frequency: usedPropertiesInsights?.frequency || 0,
      percentage: usedPropertiesInsights?.percentage || '0.00',
      samples: usedPropertiesInsights?.samples || [],
    };
  });
};

/**
 * Is user currently editing some property
 * @param state
 */
export const isUserEditing = state => get(state, 'profiles.customerFacts.ui.userIsEditing');
