import React from 'react';
import Icon from 'react-icon-base';

const R42Add = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M12 20a8 8 0 1 0-8-8 8.009 8.009 0 0 0 8 8zm-4.174-8.696h3.304a.174.174 0 0 0 .174-.174V7.826a.696.696 0 0 1 1.392 0v3.304c0 .096.078.174.174.174h3.304a.696.696 0 0 1 0 1.392H12.87a.174.174 0 0 0-.174.174v3.304a.696.696 0 0 1-1.392 0V12.87a.174.174 0 0 0-.174-.174H7.826a.696.696 0 0 1 0-1.392z"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </Icon>
);

export default R42Add;
