angular.module('webUi.service.common.conditionsCommonService', [])
/**
 * @namespace ConditionsCommonService
 * Service to handle all the logic related with conditions in tags, partners and campaigns
 */
    .factory('ConditionsCommonService', [function () {

        var moveToNextStep = function moveToNextStep(state, steps) {
            var currentStepIdx = getCurrentIndex(steps, state);
            // if allowed to continue
            if (state.beforeSubmit()) {
                if (state.activeStep.nextOverride) {
                    setActiveStep(state, _.find(steps, {'id': state.activeStep.nextOverride}));
                } else if (currentStepIdx !== steps.length - 1) {
                    var nextStep = steps[currentStepIdx + 1];
                    //I don't want to set the next step to be one of the popular conditions
                    if(state.activeStep.headerState < nextStep.headerState) {
                        setActiveStep(state, nextStep);
                    }
                }
                state.submitCallback();
            }
            return state;
        };

        var moveToPrevStep = function moveToPrevStep(state, steps) {
            state.beforePrev();

            // use prev override
            if (state.activeStep.prevOverride) {
                setActiveStep(state, _.find(steps, {'id': state.activeStep.prevOverride}));
                // goto previous by idx if possible
            } else {
                var currentStepIdx = getCurrentIndex(steps, state);
                if (currentStepIdx !== 0) {
                    setActiveStep(state, steps[currentStepIdx - 1]);
                }
            }
            return state;
        };

        var setActiveStep = function setActiveStep(state, step) {
            state.prevStep = state.activeStep;
            state.activeStep = step;
            return state;
        };

        var hasCondition = function hasCondition(conditions, conditionType) {
            return _.filter(conditions, {clazz: conditionType}).length > 0;
        };

        var getCondition = function getCondition(conditions, conditionType) {
            return _.find(conditions, {clazz: conditionType});
        };

        var removeCondition = function removeCondition(conditions, conditionType) {
            //mutate the array conditions
            _.remove(conditions, {'clazz': conditionType});
        };

        var addOrReplaceCondition = function addOrReplaceCondition(conditions, newCondition) {
            //add or replace condition, the array will be mutated
            var oldIdx = _.findIndex(conditions, {clazz: newCondition.clazz});
            if (oldIdx !== -1) {
                conditions[oldIdx] = newCondition;
            } else {
                conditions.push(newCondition);
            }
        };

        var getCurrentIndex = function getCurrentIndex(steps, state) {
            return _.findIndex(steps, {'id': state.activeStep.id});
        };




        return {
            moveToNextStep: moveToNextStep,
            moveToPrevStep: moveToPrevStep,
            setActiveStep: setActiveStep,
            hasCondition: hasCondition,
            getCondition: getCondition,
            removeCondition: removeCondition,
            addOrReplaceCondition: addOrReplaceCondition
        };
    }]);
