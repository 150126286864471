import React, { useEffect } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import Notification from '~/components/src/Notification';
import { isRequired, isPhoneNumber } from '~/common';
import Btn from '~/components/src/Btn';
import ModalHeader from '~/modals/containers/ModalHeader';
import BtnOutlined from '~/components/src/BtnOutlined';
import { useCooldown, secondsToTime } from './useCooldown';

const SmsCodeStep = ({ phoneNumber, requestSmsToken, errorUpdatePhoneRequest, errorSmsRequest, t }) => {
  const { startCooldown, isCooldownInProgress, secondsLeft } = useCooldown(180);

  const requestSmsWithCooldown = () => {
    requestSmsToken(phoneNumber);
    startCooldown();
  };

  useEffect(() => {
    requestSmsWithCooldown();
  }, []);

  return (
    <>
      <Notification kind="information" className={cx({ hid: !isCooldownInProgress })}>
        <div>{t('settings:confirmationSMSToken.confirmationContent', { phoneNumber })}</div>
        <span>
          {t('settings:confirmationSMSToken.cooldownMessage')}{' '}
          <span className="font-medium">{secondsToTime(secondsLeft)}</span>
        </span>
      </Notification>
      <div className="Aligner--baseline">
        <Field
          label={t('settings:confirmationSMSToken.smsCode.label')}
          name="smsCode"
          component={ReduxFormInputField}
          type="text"
          placeholder={t('settings:confirmationSMSToken.smsCode.placeholder')}
          validate={[isRequired]}
          autoFocus
        />
        <BtnOutlined
          className="mt-2"
          onClick={requestSmsWithCooldown}
          testHook="resendSmsToken"
          disabled={isCooldownInProgress}
        >
          {t('settings:requestSms.label')}
        </BtnOutlined>
      </div>
      {errorUpdatePhoneRequest && (
        <Notification kind="error" header={t('settings:errors.header')}>
          {!errorUpdatePhoneRequest.smsCodeCorrect && t('settings:errors.smsCodeCorrect')}
        </Notification>
      )}
      {errorSmsRequest && (
        <Notification kind="error" header={t('settings:updatePassword.errors.header')}>
          <span>{errorSmsRequest}</span>
        </Notification>
      )}
    </>
  );
};

export const UpdatePhoneForm = ({
  handleSubmit,
  submitting,
  t,
  smsTokenStep,
  phoneNumber,
  requestSmsToken,
  onCancel,
  errorUpdatePhoneRequest,
  errorSmsRequest,
}) => {
  const title =
    smsTokenStep === 1
      ? t('settings:addSmsToken.titleAddPhoneNumber')
      : t('settings:addSmsToken.titleConfirmPhoneNumer');

  const submitButtonText = smsTokenStep === 1 ? t('settings:actionsForm.next') : t('settings:actionsForm.save');

  const StepsForm =
    smsTokenStep === 1 ? (
      <Field
        label={t('settings:addSmsToken.phoneNumber.label')}
        name="phoneNumber"
        component={ReduxFormInputField}
        type="text"
        placeholder={t('settings:addSmsToken.phoneNumber.placeholder')}
        validate={[isRequired, isPhoneNumber]}
      />
    ) : (
      <SmsCodeStep
        phoneNumber={phoneNumber}
        requestSmsToken={requestSmsToken}
        errorUpdatePhoneRequest={errorUpdatePhoneRequest}
        errorSmsRequest={errorSmsRequest}
        t={t}
      />
    );

  return (
    <>
      <ModalHeader title={title} onCloseClick={onCancel} />
      <Form onSubmit={handleSubmit} isSubmitting={submitting}>
        <div className="Modal-body PhoneConfirmation p-4">{StepsForm}</div>
        <div className="flex items-center justify-end gap-2 p-4">
          <Btn testHook="cancel" preventvalidation="true" disabled={submitting} onClick={onCancel}>
            {t('settings:actionsForm.cancel')}
          </Btn>
          <Btn testHook="save" color="blue" type="submit" disabled={submitting}>
            {submitButtonText}
          </Btn>
        </div>
      </Form>
    </>
  );
};

export default UpdatePhoneForm;
