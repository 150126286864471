import React from 'react';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import SelectElement from '~/components/src/Form/Elements/SelectElement';

const StoredVariableField = ({ value, options, label, id, onChange, name, error, onBlur, testHook }) => (
  <FieldWrapper name={name} label={label} errorText={error}>
    <SelectElement
      name={name}
      id={id}
      options={options}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.profileVarId}
      value={options.find(item => item.name === value)}
      isClearable={true}
      onChange={value => {
        if (value === null) {
          onChange('');
        } else {
          onChange(value.name);
        }
      }}
      onBlur={onBlur}
      testHook={testHook}
    />
  </FieldWrapper>
);

export default StoredVariableField;
