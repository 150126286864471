import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isFunction } from 'lodash';
import { components, enhancements } from '~/components/src/Table';
import Link from '~/components/src/Link';
import Empty from '~/components/src/Empty';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell, Selection, SelectionCell } = components;
const { withEmpty, withFilter, withSelectables, withSort, withPaginate } = enhancements;

const EmptyList = ({ t, emptyBody = t('sites:table.empty.body') }) => (
  <Empty header={t('sites:table.empty.header')} body={emptyBody} />
);

const NoResults = ({ t }) => (
  <Empty header={t('sites:table.noResults.header')} body={t('sites:table.noResults.body')} />
);

const WIDTHS = {
  NAME: '30%',
  SITE_NUMBER: '20%',
  CLIENT_NAME: '30%',
  TIME_ZONE: '20%',
};

const props = (width, sortKey, title, sortFn) => ({
  width,
  sortKey,
  title,
  sortFn,
});

const ConditionalLink = ({ value = 'name', url, item, className }) =>
  url && isFunction(url) ? (
    <Link className={className} href={url(item)}>
      {item[value]}
    </Link>
  ) : (
    <span>{item[value]}</span>
  );

export const SitesList = ({ isSelectable = false, list, stateKey, siteUrl, t }) => (
  <Panel>
    <Table stateKey={stateKey} isSelectable={isSelectable}>
      <Row>
        <SelectionCell />
        <SortableHeader {...props(`${WIDTHS.NAME}`, 'name', t('sites:common.name'))} />
        <SortableHeader
          {...props(`${WIDTHS.SITE_NUMBER}`, 'siteNumber', t('sites:common.siteNumber'))}
          sortFn={numberSort('siteNumber')}
        />
        <SortableHeader {...props(`${WIDTHS.CLIENT_NAME}`, 'clientName', t('sites:common.clientName'))} />
        <SortableHeader {...props(`${WIDTHS.TIME_ZONE}`, 'timeZone', t('sites:common.timezone'))} />
      </Row>
      {list.map(item => (
        <Row key={item.siteId} id={item.siteId}>
          <SelectionCell>
            <Selection id={item.siteId} />
          </SelectionCell>
          <Cell style={{ width: `${WIDTHS.NAME}` }}>
            <ConditionalLink url={siteUrl} item={item} className="t-siteName" />
          </Cell>
          <Cell style={{ width: `${WIDTHS.SITE_NUMBER}` }}>
            <ConditionalLink url={siteUrl} item={item} value="siteNumber" />
          </Cell>
          <Cell style={{ width: `${WIDTHS.CLIENT_NAME}` }}>{item.clientName}</Cell>
          <Cell style={{ width: `${WIDTHS.TIME_ZONE}` }}>{item.timeZone}</Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

SitesList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  siteUrl: PropTypes.func,
  emptyBody: PropTypes.string,
  isSelectable: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default compose(
  withSelectables(),
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 20 }),
)(SitesList);
