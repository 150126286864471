import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getAngularService } from 'ReactAngular/react.service';
import { SearchContainer, buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import ClientList from '~/context/components/ClientList';
import { isFetchingClients, getClients, fetchClientsIfNeeded } from '~/context/clients';
import { withRouter } from '~/common/withRouter';
import LinkIcon from '~/components/src/LinkIcon';
import BtnIcon from '~/components/src/BtnIcon';

const FILTER_KEY = 'CONTEXT/CLIENT_LIST_FILTER';
const CLIENT_TABLE_STATE_KEY = 'CONTEXT/CLIENT_LIST';

/**
 * Filter function used for searching into the client list
 * @param query
 * @returns {function(*)}
 */
const filterFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.clientNumber.toString().indexOf(query.toLowerCase()) !== -1;

/**
 * Client List Container class
 *
 * It contains the context->client list page
 * which is
 * header, search, add new, list of clients
 */
class ClientListContainer extends Component {
  componentDidMount() {
    this.props.fetchClients();
  }

  render() {
    const { isContextAdmin, isFetching, exportCsv, stateKey, list, t } = this.props;

    return (
      <div>
        <Heading title={t('list.title')}>
          <SearchContainer stateKey={stateKey} searchKey={FILTER_KEY} searchFn={filterFn} />

          <BtnIcon
            icon="download"
            tooltip={t('button.exportCsv.text')}
            onClick={() => exportCsv(list)}
            testHook="exportCSV"
          />
          {isContextAdmin && (
            <LinkIcon
              color="blue"
              icon="add"
              className="h-8 w-8"
              url={buildUrl('context/clients/add')}
              tooltip={t('button.addNew.text')}
              testHook="addNew"
            />
          )}
        </Heading>

        <ClientList isSelectable={false} {...this.props} />
        <Spinner isActive={isFetching} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isContextAdmin: ownProps.isContextAdmin,
  isFetching: isFetchingClients(state),
  list: getClients(state),
  stateKey: CLIENT_TABLE_STATE_KEY,
  clientUrl: item => buildUrl(`context/clients/view/${item.clientId}`),
  exportCsv: clients => {
    const ContextService = getAngularService(document, 'ContextService');
    ContextService.exportClients(clients);
  },
});

const mapDispatchToProps = dispatch => ({
  fetchClients: () => {
    dispatch(fetchClientsIfNeeded());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate('clients'),
)(ClientListContainer);
