angular.module('webUi.ui.shared.removeAccess', ['ui.bootstrap'])

    .controller('RemoveUserAccessForSiteController',['PermissionService','$uibModalInstance','dialogsModel','$scope', 'ToasterService',
        function RemoveUserAccessForSiteController(   PermissionService , $uibModalInstance , dialogsModel , $scope, toast) {

            $scope.user = dialogsModel.user;
            $scope.site = dialogsModel.site;

            /* modal actions */
            $scope.close = function() {
                $uibModalInstance.close(false);
            };
            $scope.removeAccess = function() {
                PermissionService.removeAccess($scope.user.userId, $scope.site.siteId).then(function() {
                    toast.success('Access deleted successfully');
                    $uibModalInstance.close(true);
                });
            };
        }
    ]);
