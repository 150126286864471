import { CSSProperties } from 'react';
import { walk, removeNodeAtPath, toggleExpandedForAll, changeNodeAtPath } from 'react-sortable-tree';
import { isEmpty, isEqual, sortBy } from 'lodash';

import { buildUrl } from '~/common';
import { exportCSVFile } from '~/common/utils/ExportToFile';

import { fetchCampaignDetails } from './dataService';
import {
  CampaignDetails,
  CampaignMapping,
  CampaignTree,
  CampaignTreeNode,
  CampaignsPayload,
  MappingColumn,
  NodeInfo,
  PredefinedValue,
  PredefinedValueSet,
} from './types';
import { COLUMN_TYPES } from './constants';

export const getRandomId = (): string => Math.random().toString(36).substring(2, 10);

export const getTreeData = (
  campaignsTree: CampaignTree[],
  folderPath: string[] = [],
  selectedCampaignId = '',
): CampaignTreeNode[] =>
  campaignsTree
    .sort((nodeA, nodeB) => {
      // Sort alphabetically
      if (nodeA.name.toLowerCase() > nodeB.name.toLowerCase()) {
        return 1;
      } else if (nodeA.name.toLowerCase() < nodeB.name.toLowerCase()) {
        return -1;
      }
      return 0;
    })
    .map(node => {
      const expanded =
        node.children.length && !isEmpty(node.children.find(childNode => childNode.id === selectedCampaignId));
      return {
        id: getRandomId(),
        title: node.name,
        expanded: !!expanded,

        children: getTreeData(node.children, [...folderPath, node.name]),
        nodeId: node.id,
        folderPath: [...folderPath, node.name],
      };
    });

export const getNodeKey = ({ node }: { node: CampaignTreeNode; treeIndex: number }): string => node.id;

export const filterTree = (treeData: CampaignTreeNode[], searchQuery: string): CampaignTreeNode[] => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  const goodPaths: string[][] = [];
  const pathsToRemove: string[][] = [];

  // Find all nodes that match the search query and their parents
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      if (nodeInfo.node.title.toLowerCase().includes(lowerCaseSearchQuery)) {
        // If a node matches the search query, save its path, so we don't remove it later
        goodPaths.push(nodeInfo.path);

        // Also save paths of match node's parents
        for (let depth = 1; depth < nodeInfo.path.length; depth += 1) {
          goodPaths.push(nodeInfo.path.slice(0, depth));
        }
      }
    },
    ignoreCollapsed: false,
  });

  // Make a list of nodes that didn't match search query, so that we can remove them
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      const shouldStay = goodPaths.some(goodPath => isEqual(goodPath, nodeInfo.path));
      if (!shouldStay) {
        pathsToRemove.push(nodeInfo.path);
      }
    },
    ignoreCollapsed: false,
  });

  // Sort "paths to remove", to make sure we don't try to remove a path that was removed already
  pathsToRemove.sort((pathA, pathB) => {
    if (pathA.length < pathB.length) {
      return 1;
    }

    if (pathA.length > pathB.length) {
      return -1;
    }

    return 0;
  });

  let updatedTree = treeData;

  // Remove non-matched nodes from the tree
  pathsToRemove.forEach(path => {
    updatedTree = removeNodeAtPath({ treeData: updatedTree, path, getNodeKey, ignoreCollapsed: false });
  });

  if (searchQuery) {
    updatedTree = toggleExpandedForAll({ treeData: updatedTree, expanded: true });
  }

  return updatedTree;
};

export const getNodeUrl = (nodeId: string): string => buildUrl(`tagmanagement/urlbuilder/campaign/view/${nodeId}`);

export const getLastItem = (treeData: CampaignTreeNode[]): CampaignTreeNode | null => {
  if (!treeData.length) {
    return null;
  }

  if (!treeData[treeData.length - 1].children.length) {
    return treeData[treeData.length - 1];
  }

  return getLastItem(treeData[treeData.length - 1].children);
};

export const expandDirectory = (
  path: string[],
  treeData: CampaignTreeNode[],
  setTreeData: (tree: CampaignTreeNode[]) => void,
): void => {
  const newTreeData = changeNodeAtPath({
    treeData,
    path,
    newNode: ({ node }: { node: CampaignTreeNode }) => ({ ...node, expanded: !node.expanded }),
    getNodeKey: ({ node }) => node.id,
  });

  setTreeData(newTreeData);
};

export const filterColumns = (columns: MappingColumn[], columnConfiguration: MappingColumn[]): MappingColumn[] => {
  const filteredColumns: MappingColumn[] = [];
  columns.forEach(column => {
    const colId = column.columnId;
    const colConfig = columnConfiguration.find(({ columnId }) => columnId === colId);

    if (colConfig && colConfig.columnType && colConfig.columnType.name !== COLUMN_TYPES.DISABLED) {
      filteredColumns.push({
        ...column,
        columnType: colConfig.columnType,
        defaultValue: colConfig.defaultValue,
      });
    }
  });

  return sortBy(filteredColumns, ['orderNumber', 'name']);
};

export const getMappedCampaignDetails = async (
  campaignId: string,
  columns: MappingColumn[],
): Promise<CampaignDetails> => {
  const campaignDetails = await fetchCampaignDetails(campaignId);

  const { columnConfiguration } = campaignDetails;
  const filteredColumns = filterColumns(columns, columnConfiguration);
  return { ...campaignDetails, filteredColumns };
};

export const getPredefinedValueSetById = (setId: string, predefinedValues: PredefinedValue[]): PredefinedValueSet[] => {
  const setList = predefinedValues.find(
    ({ predefinedValuesSetId }: { predefinedValuesSetId: string }) => predefinedValuesSetId === setId,
  );
  if (setList && setList.predefinedValuesSetValueList) {
    return setList.predefinedValuesSetValueList;
  }
  return [];
};

export const getPredefinedValue = (
  setId: string,
  valueId: string,
  predefinedValues: PredefinedValue[],
): PredefinedValueSet | undefined => {
  if (predefinedValues && setId) {
    const setList = predefinedValues.find(
      ({ predefinedValuesSetId }: { predefinedValuesSetId: string }) => predefinedValuesSetId === setId,
    );
    return setList?.predefinedValuesSetValueList.find(({ id }: { id: string }) => id === valueId);
  }
  return undefined;
};

export const mapOptionType = (
  options: { label: string; name: string }[],
): { label: string; value: string; name: string }[] => options.map(option => ({ ...option, value: option.name }));

export const prepareDataForExport = (
  campaignDetails: CampaignDetails,
  campaignMappings: CampaignMapping[],
  predefinedValues: PredefinedValue[],
): void => {
  const { columnConfiguration, name: campaignName } = campaignDetails;

  const headers = columnConfiguration.map(col => col.name);
  headers.unshift('Key');
  const fileName = `mappings_${campaignName}`;
  const items: string[][] = [];

  campaignMappings.forEach(mapping => {
    const values = [mapping.mappingKey];
    columnConfiguration.forEach(column => {
      let val;
      if (column.columnType.name === COLUMN_TYPES.PREDEFINED_VALUES) {
        val = getPredefinedValue(column.defaultValue, mapping.mappingData[column.columnId], predefinedValues)?.label;
      } else {
        val = mapping.mappingData[column.columnId];
      }
      values.push(val || 'n/a');
    });
    items.push(values);
  });

  exportCSVFile(headers, items, fileName);
};

export const menuPortalStyle = (portalStyles: CSSProperties): CSSProperties => {
  delete portalStyles.left;
  delete portalStyles.top;
  return { ...portalStyles };
};

export const prepareCampaignPayload = (campaignForm: CampaignsPayload): CampaignsPayload => {
  const { campaignId, name, urlBuilderTypeId, columnConfiguration } = campaignForm;

  return {
    campaignId,
    name,
    urlBuilderTypeId,
    columnConfiguration: columnConfiguration.map(config => ({
      columnId: config.columnId,
      columnType: config.columnType,
      defaultValue: config.defaultValue,
    })),
  };
};
