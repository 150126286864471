import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import cx from 'classnames';
import './styles.scss';

const FormikCheckbox = ({ name, disabled, checked, isError, text = '', onChange, id }) => (
  <label className={cx('Checkbox', { 'is-disabled': disabled }, 'Checkbox-label flex items-center')}>
    <Field
      className="Checkbox-input"
      type="checkbox"
      id={id || name}
      name={name}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
    <span className={cx('Element Element--checkbox Checkbox-element', { 'is-error': isError })}>
      <svg className="Checkbox-svg" viewBox="0 0 52 52">
        <path className="Checkbox-tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </span>

    {text && (
      <label htmlFor={id} className="Checkbox-label-text">
        {text}
      </label>
    )}
  </label>
);

FormikCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  Field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  isError: PropTypes.bool,
};

export default FormikCheckbox;
