import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { changeUrl } from '~/common';
import Spinner from '~/components/src/Spinner';
import Notification from '~/components/src/Notification';
import Heading from '~/components/src/Heading';
import { components } from '~/components/src/Table';
import * as fromModals from '~/modals';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Badge from '~/components/src/Badge';
import { Tab, TabList, TabPanel, Tabs } from '~/components/src/Tabs';
import MissedEventsList from '~/profiles/components/MissedEventsList';
import {
  deleteCustomerFact,
  fetchCustomerFacts,
  fetchCustomerFactsActivities,
  showDependantsContainer,
  hideDependantsContainer,
  changeDependantTypesSelectedTab,
} from '../actions';
import {
  getCustomerFactsTableInfo,
  isFetchingCustomerFacts,
  isFetchingCustomerFactsActivities,
  isDependantsContainerVisible,
  getSelectedCustomerFact,
  getSelectedId,
  getSelectedTab,
  getMissedEvents,
} from '../selectors';
import CustomerFactsList from '../../components/CustomerFactsList';
import i18n from '../../i18n';
import DependantsContainer from '../../components/DependantsContainer';

const { DetailContainer } = components;
const CUSTOMER_FACTS_STATE_KEY = 'PROFILES/CUSTOMER_FACTS';

class CustomerFactsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  componentDidMount() {
    this.props.fetchCustomerFactsActivities();
    this.props.fetchCustomerFacts();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      t,
      isFetching,
      selectedCustomerFact,
      dependantsContainerIsVisible,
      selectedTab,
      canEditProfiles,
      ondeleteCustomerFact,
      onDependantsTabChange,
      onDependantsContainerClose,
      missedEvents,
      ...otherProps
    } = this.props;

    if (isFetching) {
      return <Spinner />;
    }

    const detailContainerTitle = selectedCustomerFact ? selectedCustomerFact.name : '';
    const { query } = this.state;

    return (
      <div>
        <Heading title={t('list.header')}>
          <SearchElement
            value={query}
            onChange={e => this.setState({ query: e.target.value.toLowerCase() })}
            placeholder={i18n.t('common:actions.search')}
          />
          {canEditProfiles && (
            <BtnIcon
              icon="add"
              tooltip={t('create.header')}
              color="blue"
              onClick={() => changeUrl('profiles/externalFacts/add')}
              testHook="create"
            />
          )}
        </Heading>
        <Notification kind="information">
          <p>{t('general.description')}</p>
        </Notification>

        <Tabs className="!pt-2">
          <TabList>
            <Tab testHook="configuredFacts">{t('listTabs.configured')}</Tab>
            <Tab testHook="missingFacts" className="flex items-center gap-1">
              {t('listTabs.missed')} <Badge content={missedEvents.length} />
            </Tab>
          </TabList>
          <TabPanel>
            <CustomerFactsList
              onViewClick={customerFactId => changeUrl(`profiles/externalFacts/view/${customerFactId}`)}
              onEditClick={customerFactId => changeUrl(`profiles/externalFacts/edit/${customerFactId}`)}
              onDeleteClick={ondeleteCustomerFact}
              onTabChange={onDependantsTabChange}
              selectedCustomerFact={selectedCustomerFact}
              canEditProfiles={canEditProfiles}
              shouldShowActivity
              query={query}
              {...otherProps}
            />
            <div>
              <DetailContainer
                onClose={onDependantsContainerClose}
                isVisible={dependantsContainerIsVisible}
                title={`${t('general.customerFact')} - ${detailContainerTitle}`}
              >
                <DependantsContainer
                  element={selectedCustomerFact}
                  elementName={selectedCustomerFact ? selectedCustomerFact.name : null}
                  onTabChange={onDependantsTabChange}
                  selectedTab={selectedTab}
                  showContentDependants={false}
                />
              </DetailContainer>
            </div>
          </TabPanel>
          <TabPanel>
            <MissedEventsList
              missedEvents={missedEvents}
              canEditProfiles={canEditProfiles}
              eventType="customer facts"
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: CUSTOMER_FACTS_STATE_KEY,
  list: getCustomerFactsTableInfo(state),
  missedEvents: getMissedEvents(state),
  isFetching: isFetchingCustomerFacts(state),
  isFetchingActivities: isFetchingCustomerFactsActivities(state),
  dependantsContainerIsVisible: isDependantsContainerVisible(state),
  selectedCustomerFact: getSelectedCustomerFact(state, getSelectedId(state)),
  selectedTab: getSelectedTab(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCustomerFacts: () => dispatch(fetchCustomerFacts()),
  fetchCustomerFactsActivities: () => dispatch(fetchCustomerFactsActivities()),
  ondeleteCustomerFact: customerFact => {
    dispatch(
      fromModals.showDeleteModal({
        title: i18n.t('customerFacts:list.deleteModal.title'),
        message: i18n.t('customerFacts:list.deleteModal.message', { name: customerFact.name }),
        onConfirm: () => dispatch(deleteCustomerFact(customerFact.customerFactId, customerFact.hasDependants)),
      }),
    );
  },
  onDependantIconClick: (customerFactId, dependantType) => {
    dispatch(showDependantsContainer(customerFactId, dependantType));
  },
  onDependantsTabChange: selectedTab => {
    dispatch(changeDependantTypesSelectedTab(selectedTab));
  },
  onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('customerFacts'),
)(CustomerFactsContainer);
