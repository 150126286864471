/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_TAGS = `${API_BASE}/support/tagtemplates`;
const API_VARIANTS = `${API_TAGS}/variants`;

/**
 * A service for Tag Templates related queries
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class SupportTagsClass {
  getVariant({ variantId }) {
    return Api.callGet(`${API_VARIANTS}/${variantId}`).then(response => response.data);
  }

  getVariantWithVersion({ variantId, sequenceNr }) {
    return Api.callGet(`${API_VARIANTS}?variantId=${variantId}&sequenceNr=${sequenceNr}`).then(res => res.data);
  }

  getVariantHistory(variantId) {
    return Api.callGet(`${API_TAGS}/variantHistory?variantId=${variantId}`).then(response => {
      // the first item is not a historical variant, but current one
      const data = response.data || [];
      return data.slice(1);
    });
  }

  deleteVariant(variantId, sequenceNr) {
    return Api.callPost(`${API_VARIANTS}/delete`, {
      variantId,
      sequenceNr,
    }).then(response => response.data);
  }

  deleteVariantProper(variantId, sequenceNr) {
    return Api.callDelete(`${API_VARIANTS}/${variantId}/${sequenceNr}`);
  }

  saveVariant(variant) {
    return Api.callPost(`${API_VARIANTS}/add`, variant).then(response => response.data);
  }

  updateVariant(variant) {
    return Api.callPost(`${API_VARIANTS}/edit`, variant).then(response => response.data);
  }

  toggleLock({ variantId, sequenceNr, locked }) {
    return Api.callPost(`${API_VARIANTS}/updateLockFlag`, {
      variantId,
      sequenceNr,
      locked,
    }).then(response => response.data);
  }

  getTagExecutionTypes() {
    return Api.callGet(`${API_VARIANTS}/executiontypes`).then(response => response.data);
  }
}

const supportTagsService = new SupportTagsClass();
export default supportTagsService;
