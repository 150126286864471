/**
 * Users Duck
 */
import { combineReducers } from 'redux';
import matchClients from '~/context/users/matchClients/reducers';
import records from './records';
import userClients from './userClients';
import userSites from './userSites';

export * from './records';
export * from './matchClients';
export * from './userClients';
export * from './userSites';

// export { default } from './reducers';
export default combineReducers({
  records,
  matchClients,
  userClients,
  userSites,
});
