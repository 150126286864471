import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { buildUrl } from '~/common/history';
import { getSiteId } from '~/common/SecurityMetaService';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { fetchSiteInfoIfNeeded, getSiteInfo, updateSite } from '~/admin/site';
import { isValidSubnet, defaultMaxText } from '~/common';
import { ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';

export class EditSiteSecurity extends Component {
  componentDidMount() {
    this.props.fetchSiteInfo();
  }

  render() {
    const { t, site, handleSubmit, submitting, cancelHref } = this.props;
    return !site.siteId ? (
      <Spinner />
    ) : (
      <Page>
        <SmallWrapper>
          <Heading title={t('siteEdit.header')} />
          <Panel>
            <Form onSubmit={handleSubmit} isSubmitting={submitting}>
              <div>
                <Field
                  label={t('info.restrictedSubnets.label')}
                  name="restrictedSubnets"
                  component={ReduxFormTextAreaField}
                  placeholder={t('info.restrictedSubnets.placeholder')}
                  validate={[isValidSubnet, defaultMaxText]}
                />
              </div>
              <FormActions t={t} submitting={submitting} cancelHref={cancelHref} />
            </Form>
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => {
      const site = getSiteInfo(state);
      return {
        initialValues: site,
        site,
        cancelHref: buildUrl('admin/sitesecurity'),
      };
    },
    dispatch => ({
      fetchSiteInfo: () => dispatch(fetchSiteInfoIfNeeded(getSiteId())),
      onSubmit: user => dispatch(updateSite(user)),
    }),
  ),
  reduxForm({
    form: 'editSite',
    enableReinitialize: true,
  }),
  translate(['site']),
)(EditSiteSecurity);
