import { convertToCSV } from './ConvertToCSV';

export function exportCSVFile(headers: any, items: any[], fileName?: string): void {
  if (headers) {
    items.unshift(headers);
  }

  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilename = fileName ? `${fileName}.csv` : 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body?.removeChild(link);
  }
}

export function exportYAMLFile(items: string, fileName?: string): void {
  const exportedFilename = fileName ? `${fileName}.yaml` : 'import.yaml';

  const blob = new Blob([items], { type: 'text/yaml;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body?.removeChild(link);
  }
}
