angular.module('webUi.ui.tagmanagement.stats', ['webUi.ui.tagmanagement.base', 'webUi.common.Utils'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.stats', {
                url : '/stats',
                isReact: true,
            });
    }]);

  