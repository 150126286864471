import React from 'react';
import { FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';

const MailChimpForm = props => {
  const { t, canUpdate, values, partner } = props;

  return (
    <FormSection name={constants.partnerTypes.MAILCHIMP}>
      <FormHeader
        t={t}
        title={values.name}
        partner={partner}
        description={t('form.notification.partnerNumber')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <FieldWithEditMode
          label={t('form.formFields.apiKey')}
          name="token"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default MailChimpForm;
