import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles.scss';

const ButtonGroup = ({ children, className = '' }) => <div className={cx('ButtonGroup', className)}>{children}</div>;

ButtonGroup.propTypes = {
  children: PropTypes.node,
};

export default ButtonGroup;
