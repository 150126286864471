import React from 'react';
import { Field, FormSection } from 'redux-form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const MobProForm = props => {
  const { t, values, partner } = props;
  return (
    <FormSection name={constants.partnerTypes.MOB_PRO}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.cookieSyncMessage')}
        partnerNumber={partner.partnerNumber}
      />
      <ContainerFormSection>
        <Field
          label={t('form.formFields.customer')}
          name="customer"
          placeholder={t('form.formFields.inputPlaceHolder')}
          component={ReduxFormInputField}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.advertiserId')}
          name="advertiserId"
          placeholder={t('form.formFields.inputPlaceHolder')}
          component={ReduxFormInputField}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default MobProForm;
