/**
 * @description Angular main module for Content -> Campaigns
 * @memberOf webUi.ui.content
 * @namespace webUi.ui.content.campaigns
 */
angular.module('webUi.ui.content.campaigns',
    [
        'webUi.ui.content.campaigns.view',
        'webUi.ui.content.campaigns.form',
        'webUi.ui.content.campaigns.dashboard',

        'webUi.ui.content.campaigns.ads',
        'webUi.ui.content.campaigns.ads.view',
        'webUi.ui.content.campaigns.ads.form',
        'webUi.ui.content.campaigns.ads.creatives',
        'webUi.ui.content.campaigns.ads.creatives.form',

        'webUi.ui.content.campaigns.placementGroups',
        'webUi.ui.content.campaigns.placementGroups.form'
    ]
);
