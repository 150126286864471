import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { actionCreators } from 'react-redux-composable-list';
import SearchElement from '~/components/src/Form/Elements/SearchElement';

export class SearchContainer extends Component {
  componentWillUnmount() {
    this.props.onUnmount();
  }

  render() {
    const { className, onFilterChange, t } = this.props;
    return (
      <SearchElement
        className={className}
        onChange={e => onFilterChange(e.target.value)}
        placeholder={t('actions.search')}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { stateKey, searchKey, searchFn } = ownProps;
  return {
    onUnmount: () => dispatch(actionCreators.doRemoveFilter(stateKey, searchKey)),
    onFilterChange: query => {
      if (query !== '') {
        dispatch(actionCreators.doSetFilter(stateKey, searchKey, searchFn(query)));
      } else {
        dispatch(actionCreators.doRemoveFilter(stateKey, searchKey));
      }
    },
  };
};

const SearchConnected = compose(connect(null, mapDispatchToProps), translate(['common']))(SearchContainer);

SearchConnected.propTypes = {
  stateKey: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  searchFn: PropTypes.func.isRequired,
};

export default SearchConnected;
