import React from 'react';
import { Field, FormikHandlers } from 'formik';
import { translate } from 'react-i18next';
import { I18NextT } from '~/components/src/Common/types';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from './../ConnectorGenericForm';
import ConnectorProperties from './ConnectorProperties';

interface StreamsProps {
  t: I18NextT;
  onEdit: FormikHandlers['handleChange'];
  connector: any;
  isSubmitted: boolean;
  stageName: string;
  usedVariables: any;
}

function Streams({ stageName, onEdit, connector, isSubmitted, t, usedVariables }: StreamsProps) {
  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} isSubmitted={isSubmitted} validations={null}>
      {(formProps: any) => (
        <div>
          <SmallWrapperNoOffset>
            <Field
              as={FormikInputField}
              name="sourceAliasName"
              label={t('connectors.label.sourceAliasName')}
              placeholder={t('connectors.placeholder')}
              errorText={formProps.touched.sourceAliasName && formProps.errors.sourceAliasName}
              hintText={t('connectors.hint.sourceAliasName')}
              enableAction
              buttonTooltip={t('connectors.fill')}
              buttonTestHook="sourceAliasNameButton"
              buttonIcon="copy"
              onToggleButton={() => formProps.setFieldValue('sourceAliasName', stageName)}
            />
          </SmallWrapperNoOffset>

          <ConnectorProperties
            isCustomCreatable={true}
            formProps={formProps}
            name="data"
            usedVariables={usedVariables}
            t={t}
          />
        </div>
      )}
    </ConnectorGenericForm>
  );
}

export default translate('audiences')(Streams);
