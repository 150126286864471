export function convertToCSV(objArray: string): string {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  array.forEach((element: any) => {
    let line = '';
    Object.values(element).forEach(val => {
      if (line !== '') line += ',';
      line += val;
    });
    str += `${line}\r\n`;
  });

  return str;
}
