import React from 'react';
import _ from 'lodash';
import Spinner from '~/components/src/Spinner';
import Empty from '~/components/src/Empty';
import IdentifiersTable from './IdentifiersTable';

const STATE_KEY = 'CUSTOMER_DATA_MAPPINGS';

const CustomerIdentifiersComponent = ({ customerIdentifiersState, t }) => {
  const notFoundMessage = t('notFound');

  const { value, error } = customerIdentifiersState;

  if (error) {
    return <Empty body={notFoundMessage} />;
  }

  if (!value) return <Spinner />;

  if (_.isEmpty(value)) return <Empty body={notFoundMessage} />;

  // conver object to list for sorting
  const data = Object.entries(value).map(item => ({
    key: item[0],
    value: item[1].join('\n'),
  }));
  return <IdentifiersTable list={data} stateKey={STATE_KEY} />;
};

export default CustomerIdentifiersComponent;
