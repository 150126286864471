import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'formik';
import * as Yup from 'yup';
import { translate } from 'react-i18next';

import { useAPI } from '~/common/ApiHooks';
import * as fromModals from '~/modals';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorGenericForm';
import ConnectorProperties, {
  PropertyRow,
} from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorContent/ConnectorProperties';
import BtnIcon from '~/components/src/BtnIcon';
import { Notification } from '~/components';
import Icons from '~/components/src/Icons';
import * as ApiService from './service';
import AddFloodlightActivityForm from './AddFloodlightActivityForm';
import './styles.scss';

const makeValidations = t =>
  Yup.object().shape({
    floodlightActivity: Yup.object().required(t('connectors:cards.validation.doubleClickFloodlightActivity')),
  });

const useFloodlightActivities = partnerId => {
  const [floodlightActivities, setFloodlightActivities] = useState(null);

  const { data, error } = useAPI(() => ApiService.fetchFloodlightActivities(partnerId), []);

  useEffect(() => {
    setFloodlightActivities(data);
  }, [data]);

  const addFloodlightActivity = activity => {
    setFloodlightActivities([...floodlightActivities, activity]);
  };

  return { floodlightActivities, addFloodlightActivity, floodlightActivitiesError: error };
};

const openFloodlightModal = (selectNewlyCreatedActivity, partnerId, t) => dispatch => {
  const hideModal = () => dispatch(fromModals.hideModal());

  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: t('connectors.doubleClickOffline.newFloodlightActivity'),
      className: 'DoubleClickOffline-floodlightModal',
      content: (
        <AddFloodlightActivityForm
          partnerId={partnerId}
          selectNewlyCreatedActivity={selectNewlyCreatedActivity}
          hideModal={hideModal}
          t={t}
        />
      ),
    }),
  );
};

const FloodlightActivityField = ({ formProps, partnerId, openFloodlightModal, t }) => {
  const { floodlightActivities, addFloodlightActivity, floodlightActivitiesError } = useFloodlightActivities(partnerId);

  const selectNewlyCreatedActivity = activity => {
    addFloodlightActivity(activity);
    formProps.setFieldValue('floodlightActivity', activity);
  };

  return (
    <div className="DoubleClickOffline-floodlightActivityField">
      <SmallWrapperNoOffset>
        <div className="flex items-center gap-4">
          <Field
            name="floodlightActivity"
            as={FormikSelectField}
            label={t('connectors.doubleClickOffline.floodlightActivity')}
            errorText={formProps.touched.floodlightActivity && formProps.errors.floodlightActivity}
            options={floodlightActivities || []}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            testHook="floodlightActivitySelect"
            className="flex-1"
          />
          <BtnIcon
            className="mt-3 rounded-full"
            icon="add"
            testHook="addFloodlightActivity"
            tooltip={t('connectors.doubleClickOffline.createNewFloodlightActivity')}
            onClick={() => openFloodlightModal(selectNewlyCreatedActivity, partnerId, t)}
          />
        </div>
      </SmallWrapperNoOffset>
      {floodlightActivitiesError && (
        <Notification kind="error">{t('connectors.doubleClickOffline.floodlightActivityApiError')}</Notification>
      )}
      <hr />
    </div>
  );
};

const OptionalPropertiesField = ({ formProps, partnerId, usedVariables, t }) => {
  const { data: userDefinedVariables, error } = useAPI(() => ApiService.fetchUserDefinedVariables(partnerId), []);

  if (error) {
    return <Notification kind="error">{t('connectors.doubleClickOffline.userDefinedVariablesApiError')}</Notification>;
  }

  return (
    <ConnectorProperties
      name="variables"
      allOptionalProperties={userDefinedVariables || []}
      hasStaticProperty={true}
      hasStoredVariables={true}
      formProps={formProps}
      usedVariables={usedVariables}
      t={t}
    />
  );
};

const DoubleClickOffline = ({ onEdit, connector, openFloodlightModal, t, isSubmitted, usedVariables }) => {
  const { partnerId } = connector.partnerDetails;

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      connector={connector}
      validations={makeValidations(t)}
      isSubmitted={isSubmitted}
    >
      {formProps => (
        <Fragment>
          <FloodlightActivityField
            formProps={formProps}
            partnerId={partnerId}
            openFloodlightModal={openFloodlightModal}
            t={t}
          />
          <PropertyRow
            name="conversionVariableName"
            usedVariables={usedVariables}
            formProps={formProps}
            hasStoredVariables={true}
            label={
              <>
                {t('connectors.doubleClickOffline.conversionValue')}
                <Icons icon="info" className="ml-2 h-4 w-4 text-gray-400" tooltip={t('common:optionalField')} />
              </>
            }
          />
          <OptionalPropertiesField formProps={formProps} usedVariables={usedVariables} partnerId={partnerId} t={t} />
        </Fragment>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { openFloodlightModal }), translate('audiences'))(DoubleClickOffline);
