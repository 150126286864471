angular.module('webUi.ui.admin.base', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.admin', {
            'abstract': true,
            url: '/admin',
            views: {
                'mainContent@': {
                    templateUrl: 'ui/admin/base/base.tpl.html',
                    controller: 'AdminBaseController as vm'
                }
            }
        });
    }])

    .controller('AdminBaseController', [function AdminBaseController() {
        
    }]);
