import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { showSuccess } from '~/notificationCenter';
import { useAPI } from '~/common';
import Spin from '~/components/src/Spin';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/common/formValidation/i18n';
import { Notification } from '~/components';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SOURCE_COUNTRY_SELECT_ALL, currency } from './constants';
import { AmazonDspAudienceFormFieldsProps, DspFormFields, DspFormPayload } from './types';
import { createAmazonDspAudience, getCountryCodes } from './dataService';
import CountrySelect from './countrySelect';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation:validation.required')),
  description: Yup.string().required(i18n.t('validation:validation.required')),
  ttl: Yup.number()
    .nullable()
    .min(0, i18n.t('validation:validation.tooLow', { min: 0 }))
    .required(i18n.t('validation:validation.required')),
  cpm: Yup.number().nullable().required(i18n.t('validation:validation.required')),
  currency: Yup.string().nullable().required(i18n.t('validation:validation.required')),
  countryCodes: Yup.array().required(i18n.t('audiences:connectors.amazonDsp.noCountryError')),
});

const initialValues: DspFormFields = {
  name: '',
  description: '',
  cpm: null,
  ttl: null,
  currency: null,
  countryCodes: [],
};

const AddAmazonDspUserForm = ({
  hideModal,
  partnerId,
  setFieldValue,
}: AmazonDspAudienceFormFieldsProps): React.ReactNode => {
  const { data = [], isLoading } = useAPI(() => getCountryCodes());
  const handleAddAmazonDsp = async (values: DspFormFields) => {
    const { name, description, cpm, currency, ttl, countryCodes } = values;
    const payload: DspFormPayload = {
      name,
      description,
      ttl,
      audienceFees: [{ cpmCents: cpm, currency }],
      countryCodes: countryCodes.includes(SOURCE_COUNTRY_SELECT_ALL.value)
        ? [SOURCE_COUNTRY_SELECT_ALL.value]
        : countryCodes,
    };
    const audience = await createAmazonDspAudience(partnerId, payload);
    setFieldValue('audience', audience);
    showSuccess({
      body: i18n.t('audiences:connectors.amazonDsp.createAudience.confirmText'),
    });
    hideModal();
  };

  if (isLoading) return <Spin />;
  return (
    <Formik onSubmit={handleAddAmazonDsp} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, setFieldValue, isSubmitting, values }) => (
        <>
          <Field
            as={FormikInputField}
            name="name"
            label={i18n.t('audiences:connectors.amazonDsp.audienceName')}
            placeholder={i18n.t('audiences:connectors.amazonDsp.createAudience.placeholder')}
            errorText={errors.name}
          />
          <Field
            as={FormikInputField}
            name="description"
            label={i18n.t('audiences:connectors.amazonDsp.audienceDescription')}
            placeholder={i18n.t('audiences:connectors.amazonDsp.createAudience.placeholder')}
            errorText={errors.description}
          />
          <Field
            type="number"
            as={FormikInputField}
            name="ttl"
            label={i18n.t('audiences:connectors.amazonDsp.TTL')}
            placeholder={i18n.t('audiences:connectors.amazonDsp.createAudience.placeholder')}
            errorText={errors.ttl}
          />
          <CountrySelect
            labels={data}
            setCountryCodes={values => setFieldValue('countryCodes', values)}
            countryCodes={values?.countryCodes || []}
            hasError={!!errors?.countryCodes}
          />

          <p className="my-5 text-lg font-bold">{i18n.t('audiences:connectors.amazonDsp.audienceFees')}</p>

          <Field
            type="number"
            as={FormikInputField}
            name="cpm"
            label={i18n.t('audiences:connectors.amazonDsp.CPM')}
            placeholder={i18n.t('audiences:connectors.amazonDsp.createAudience.placeholder')}
            errorText={errors.cpm}
          />
          <Field
            name="currency"
            as={FormikSelectField}
            label={i18n.t('audiences:connectors.amazonDsp.currency')}
            errorText={errors.currency}
            getOptionLabel={(option: { code: string; name: string }) => option.code}
            getOptionValue={(option: { code: string; name: string }) => option.code}
            customSetFieldValue={(name: string, option: { code: string; name: string }) => {
              setFieldValue(name, option.code);
            }}
            options={currency || []}
            menuPosition="fixed"
          />
          <Notification kind="information">
            {i18n.t('audiences:connectors.amazonDsp.createAudience.infoText')}
          </Notification>
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={hideModal}
            isConfirmEnabled={!isSubmitting}
            className="mr-0"
            testHook="createAudience"
          />
        </>
      )}
    </Formik>
  );
};

export default AddAmazonDspUserForm;
