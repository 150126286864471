import { get } from 'lodash';
import constants from './constants';

const isFetchingRequestTypes = state => get(state, 'profiles.newExport.isFetchingRequestTypes');
const isFetchingExportTypes = state => get(state, 'profiles.newExport.isFetchingExportTypes');
const isFetchingEngagementTypes = state => get(state, 'profiles.newExport.isFetchingEngagementTypes');
const isCreatingNewRequest = state => get(state, 'profiles.newExport.isCreatingNewRequest');
const getRequestTypes = state => get(state, 'profiles.newExport.requestTypes');
const getExportTypes = state => get(state, 'profiles.newExport.exportTypes');
const getEngagementTypes = state => get(state, 'profiles.newExport.engagementTypes');
const getFormValues = state => get(state, 'form.exportNew.values');
const isRemoteConnectionSuccessful = state => get(state, 'profiles.newExport.isRemoteConnectionSuccessful');
const isFetchingEditExport = state => get(state, 'profiles.newExport.isFetchingEditExport');
const getEditExportData = state => get(state, 'profiles.newExport.editExportData');
const getExportId = state => get(state, 'profiles.newExport.exportId');
const areConnectionCredentialsChecked = state => get(state, 'profiles.newExport.areConnectionCredentialsChecked');
const getInitialValues = state => {
  const editExportData = getEditExportData(state);

  return {
    type: {
      requestType: editExportData.requestType,
      exportType: editExportData.exportType,
    },
    notify: {
      email: editExportData.notifyEmails.join(', '),
    },
    connection: {
      connectionType: editExportData.connectionConfig.connectionType.name,
      loginType: editExportData.connectionConfig.loginType.name,
      host: editExportData.connectionConfig.host,
      port: editExportData.connectionConfig.port,
      username: editExportData.connectionConfig.username,
      password: editExportData.connectionConfig.password,
      directory: editExportData.connectionConfig.directory,
    },
    frequency: {
      frequency: editExportData.frequency.name,
    },
    settings: {
      urlParameters: editExportData.exportReportConfiguration.map(config => config.param).join('/,/'),
      engagementTypes:
        _.find(editExportData.exportReportConfiguration, { exportType: constants.EXPORT_TYPES.ENGAGEMENTS })
          ?.engagements || [],
      query: editExportData.exportReportConfiguration.length && editExportData.exportReportConfiguration[0].query,
    },
  };
};

export {
  isFetchingRequestTypes,
  isFetchingExportTypes,
  isFetchingEngagementTypes,
  isCreatingNewRequest,
  getRequestTypes,
  getExportTypes,
  getEngagementTypes,
  getFormValues,
  isRemoteConnectionSuccessful,
  getEditExportData,
  getExportId,
  getInitialValues,
  isFetchingEditExport,
  areConnectionCredentialsChecked,
};
