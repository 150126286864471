import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '~/components/src/Page';
import MessageContainer from '../messages/containers/MessageContainer';

export const MessagePage = () => {
  const { messageId } = useParams();
  return (
    <Page>
      <MessageContainer id={messageId} />
    </Page>
  );
};

export default MessagePage;
