import React, { useState } from 'react';
import { translate } from 'react-i18next';
import cx from 'classnames';
import { useAPI } from '~/common/ApiHooks';
import { buildUrl } from '~/common';
import { I18NextT } from '~/components/src/Common/types';
import i18n from '~/i18n';
import DefaultPanel from './DefaultPanel';
import { useDashboard } from '../context';
import { fetchAudienceStats } from '../dataService';
import { StatsKeyValue, StatsPlaceholder, StatsPropertyWithNumber, StatsTableHead } from './components';

type AudienceStat = {
  segmentId: string;
  segmentNumber: number;
  segmentName: string;
  activeProfiles: number;
  added: number;
  removed: number;
};

type StatsTableProps = {
  stats: AudienceStat[];
};

const headers = [
  i18n.t('dashboard:table.audienceNoAndName'),
  i18n.t('dashboard:table.active'),
  i18n.t('dashboard:table.added'),
  i18n.t('dashboard:table.removed'),
];

const StatsTable = ({ stats }: StatsTableProps) => (
  <div className="t-audiencesStatsTable flex w-full flex-col">
    <div className="hid w-full grid-cols-6 border-b border-slate-200 py-2 dark:border-slate-400 md:grid">
      {headers.map((header: string, i: number) => (
        <StatsTableHead
          key={`audienceHead-${i}`}
          className={cx(i === 0 ? 'col-span-3' : 'text-right')}
          label={header}
        />
      ))}
    </div>

    {stats?.map(stat => (
      <div
        key={stat.segmentId}
        className="t-statsTableRow mt-2 grid w-full grid-cols-6 border-b border-slate-200 py-2 dark:border-slate-400 md:border-none"
      >
        <StatsPropertyWithNumber
          className="t-nameCell col-span-6 md:col-span-3"
          number={stat.segmentNumber}
          name={stat.segmentName}
          redirectUrl={buildUrl(`audiences/segments/view/${stat.segmentId}`)}
        />
        <StatsKeyValue
          label={headers[1]}
          value={stat?.activeProfiles}
          valueClassName="col-span-4 t-activeProfilesCell"
        />
        <StatsKeyValue label={headers[2]} value={stat?.added} valueClassName="col-span-4 t-addedCell" />
        <StatsKeyValue label={headers[3]} value={stat?.removed} valueClassName="col-span-4 t-removedCell" />
      </div>
    ))}
  </div>
);

interface AudiencesPanelProps {
  t: I18NextT;
}

const AudiencesPanel = ({ t }: AudiencesPanelProps) => {
  const [refetch, setRefetch] = useState(false);

  const dashboardContextValue = useDashboard();
  const rangeFrom = dashboardContextValue?.state.rangeFrom;
  const rangeTo = dashboardContextValue?.state.rangeTo;

  const { data, isLoading, error } = useAPI(() => {
    if (typeof rangeFrom === 'number' && typeof rangeTo === 'number') {
      return fetchAudienceStats(rangeFrom, rangeTo);
    }

    return Promise.resolve();
  }, [rangeFrom, rangeTo, refetch]);

  const audiencesCount = data?.activeCount || 0;
  const hasNoContent = audiencesCount === 0;
  const stats = data?.segments.slice(0, 3);
  const dataManagementUrl = buildUrl('audiences/segments/list/0');

  return (
    <DefaultPanel
      icon="audience"
      title={audiencesCount}
      subTitle={t('panels.audience.subTitle')}
      tooltip={t('panels.audience.tooltip')}
      isLoading={isLoading}
      hasNoContent={hasNoContent}
      tooltipPlacement="bottom"
      url={dataManagementUrl}
      testHook="audiencesPanel"
    >
      {hasNoContent ? (
        <StatsPlaceholder
          icon="audience"
          error={error}
          redirectUrl={dataManagementUrl}
          onClick={() => setRefetch(!refetch)}
        />
      ) : (
        <StatsTable stats={stats} />
      )}
    </DefaultPanel>
  );
};

export default translate('dashboard')(AudiencesPanel);
