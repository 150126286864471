import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { changeUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import Notification from '~/components/src/Notification';
import { components } from '~/components/src/Table';
import * as fromModals from '~/modals';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import BtnIcon from '~/components/src/BtnIcon';
import {
  deleteEngagement,
  fetchEngagements,
  fetchEngagementActivities,
  showDependantsContainer,
  hideDependantsContainer,
  changeDependantTypesSelectedTab,
} from '../actions';
import {
  getEngagementsTableInfo,
  isFetchingEngagements,
  isFetchingEngagementActivities,
  isDependantsContainerVisible,
  getSelectedEngagement,
  getSelectedId,
  getSelectedTab,
} from '../selectors';
import EngagementsList from '../../components/EngagementsList';
import i18n from '../../i18n';
import DependantsContainer from '../../components/DependantsContainer';

const { DetailContainer } = components;

export class EngagementsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }
  componentDidMount() {
    this.props.fetchEngagementActivities();
    this.props.fetchEngagements();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      t,
      isFetching,
      selectedEngagement,
      dependantsContainerIsVisible,
      selectedTab,
      canEditProfiles,
      onDeleteEngagement,
      onDependantsTabChange,
      onDependantsContainerClose,
      ...otherProps
    } = this.props;

    if (isFetching) {
      return <Spinner />;
    }

    const detailContainerTitle = selectedEngagement ? selectedEngagement.type : '';
    const { query } = this.state;

    return (
      <div>
        <Heading title={t('list.header')}>
          <SearchElement
            value={query}
            onChange={e => this.setState({ query: e.target.value.toLowerCase() })}
            placeholder={i18n.t('common:actions.search')}
          />
          {canEditProfiles && (
            <BtnIcon
              icon="add"
              tooltip={t('create.header')}
              color="blue"
              onClick={() => changeUrl('profiles/engagements/add')}
              testHook="create"
            />
          )}
        </Heading>
        <Notification kind="information">
          <p>{t('general.description')}</p>
        </Notification>
        <div>
          <EngagementsList
            onViewClick={engagementId => changeUrl(`profiles/engagements/view/${engagementId}`)}
            onEditClick={engagementId => changeUrl(`profiles/engagements/edit/${engagementId}`)}
            onDeleteClick={onDeleteEngagement}
            onTabChange={onDependantsTabChange}
            selectedEngagement={selectedEngagement}
            canEditProfiles={canEditProfiles}
            query={query}
            {...otherProps}
          />
          <div>
            <DetailContainer
              onClose={onDependantsContainerClose}
              isVisible={dependantsContainerIsVisible}
              title={`${t('general.engagement')} - ${detailContainerTitle}`}
            >
              <DependantsContainer
                element={selectedEngagement}
                elementName={selectedEngagement ? selectedEngagement.type : null}
                onTabChange={onDependantsTabChange}
                selectedTab={selectedTab}
                showContentDependants={false}
              />
            </DetailContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list: getEngagementsTableInfo(state),
  isFetching: isFetchingEngagements(state),
  isFetchingActivities: isFetchingEngagementActivities(state),
  dependantsContainerIsVisible: isDependantsContainerVisible(state),
  selectedEngagement: getSelectedEngagement(state, getSelectedId(state)),
  selectedTab: getSelectedTab(state),
});

const mapDispatchToProps = dispatch => ({
  fetchEngagements: () => dispatch(fetchEngagements()),
  fetchEngagementActivities: () => dispatch(fetchEngagementActivities()),
  onDeleteEngagement: engagement => {
    dispatch(
      fromModals.showDeleteModal({
        title: i18n.t('engagements:list.deleteModal.title'),
        message: i18n.t('engagements:list.deleteModal.message', { name: engagement.name }),
        onConfirm: () => dispatch(deleteEngagement(engagement.engagementId, engagement.hasDependants)),
      }),
    );
  },
  onDependantIconClick: (engagementId, dependantType) => {
    dispatch(showDependantsContainer(engagementId, dependantType));
  },
  onDependantsTabChange: selectedTab => {
    dispatch(changeDependantTypesSelectedTab(selectedTab));
  },
  onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('engagements'))(EngagementsContainer);
