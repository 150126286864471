import React, { useState } from 'react';
import { useApiWithState } from '~/common';
import { Heading, Page, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import EnvironmentConfigurationModal from './components/EnvironmentConfigurationModal';
import History from './components/History';
import { UnpublishedTable } from './components/UnpublishedTable';
import { getEnvironments } from './dataService';
import { getLatestChanges } from './util';

const PublishingView = ({ hasTagEdit, hasTagPublish }: { hasTagPublish: boolean; hasTagEdit: boolean }) => {
  const {
    state: latestChanges,
    isLoading: isLatestChangesLoading,
    refetch: refetchLatestChanges,
  } = useApiWithState(() => getLatestChanges());

  const {
    state: environments,
    isLoading: isEnvironmentsLoading,
    refetch: refetchEnvironments,
  } = useApiWithState(() => getEnvironments());

  const refetch = () => {
    refetchLatestChanges();
    refetchEnvironments();
  };

  const [showEnvironmentConfigModal, setShowEnvironmentConfigModal] = useState(false);

  if (isLatestChangesLoading || isEnvironmentsLoading || !latestChanges || !environments) {
    return <Spin />;
  }

  return (
    <Page>
      <Heading title={i18n.t('publishing:title')}>
        <BtnIcon
          icon="setting"
          color="blue"
          onClick={() => setShowEnvironmentConfigModal(true)}
          testHook="editEnvironmentSetting"
          disabled={!hasTagEdit}
          tooltip={hasTagEdit ? i18n.t('publishing:editEnv') : i18n.t('common:errors.noPermission')}
        />
      </Heading>
      <p className="mt-2 pb-2 text-xl text-gray-600">{i18n.t('publishing:latestChanges.title')}</p>
      <UnpublishedTable latestChanges={latestChanges} environments={environments} refetch={refetch} />
      <History
        hasTagEdit={hasTagEdit}
        hasTagPublish={hasTagPublish}
        environments={environments}
        refetchEnvironments={refetchEnvironments}
      />
      {showEnvironmentConfigModal && (
        <PickerView
          className="!h-auto !w-auto"
          pickerTitle={i18n.t('publishing:environments.title')}
          handlePickerVisibility={() => setShowEnvironmentConfigModal(false)}
        >
          <EnvironmentConfigurationModal
            environments={environments}
            refetch={refetchEnvironments}
            closeModal={() => setShowEnvironmentConfigModal(false)}
          />
        </PickerView>
      )}
    </Page>
  );
};

export default PublishingView;
