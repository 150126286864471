import { combineReducers } from 'redux';
import { USER_SITE_REMOVE_SUCCESS } from '~/permissions';
import * as types from './types';

/**
 * List of users for current site
 * @param state
 * @param action
 * @returns []
 */
const list = (state = [], action) => {
  switch (action.type) {
    case types.USERS_FETCH_SUCCESS:
      return action.payload;
    case types.USER_UPDATE_SUCCESS:
      return state.map(item => {
        if (item.userId !== action.payload.userId) {
          return item;
        }
        return {
          ...item,
          phoneNumber: action.payload.phoneNumber,
          restrictedSubnets: action.payload.restrictedSubnets,
        };
      });
    case USER_SITE_REMOVE_SUCCESS:
      return state.filter(item => item.userId !== action.payload.userId);
    default:
      return state;
  }
};

/**
 * UI State
 */
const defaultUI = {
  isFetchingUsers: false,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.USERS_FETCH_START:
      return {
        ...state,
        isFetchingUsers: true,
      };
    case types.USERS_FETCH_SUCCESS:
    case types.USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingUsers: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  ui,
});
