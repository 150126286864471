import React from 'react';
import { FormSection, Field } from 'redux-form';
import { isRequired, isPositiveNumber } from '~/common';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';

const { CREATE_SERVER } = constants.modes;

const KinesisForm = props => {
  const { t, values, partner, mode, canUpdate } = props;

  return (
    <FormSection name={constants.partnerTypes.KINESIS}>
      <FormHeader
        t={t}
        title={values.name}
        partner={partner}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        {mode === CREATE_SERVER && (
          <Field
            label={t('form.formFields.partnerNumberLabel')}
            name="partnerNumber"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={[isRequired, isPositiveNumber]}
            type="text"
          />
        )}
        <FieldWithEditMode
          label={t('form.formFields.kinesisAccessKey')}
          name="accessKey"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <FieldWithEditMode
          label={t('form.formFields.kinesisSecretKey')}
          name="secretKey"
          component={ReduxFormSensitiveField}
          isNeedActivateEditMode={canUpdate}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.awsRegion')}
          name="region"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
        <Field
          label={t('form.formFields.streamName')}
          name="streamName"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default KinesisForm;
