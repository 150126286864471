import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import connectorFormActionTypes from './connectorFormActionTypes';
import connectorFormInitialState from './connectorFormInitialState';
import doubleClickReducer from './partners/doubleClick/doubleClickReducer';
import clangReducer from './partners/clang/clangReducer';
import facebookReducer from './partners/facebook/facebookReducer';
import googleAdsReducer from './partners/googleAds/googleAdsReducer';
import doubleClickOfflineReducer from './partners/doubleClickOffline/doubleClickOfflineReducer';
import tripolisReducer from './partners/tripolis/tripolisReducer';
import appNexusReducer from './partners/appNexus/appNexusReducer';
import tradeDeskReducer from './partners/tradeDesk/tradeDeskReducer';

const connectorFormReducer = (state = connectorFormInitialState, action) => {
  switch (action.type) {
    case connectorFormActionTypes.CLANG:
      return clangReducer(state, action);
    case connectorFormActionTypes.DOUBLE_CLICK:
      return doubleClickReducer(state, action);
    case connectorFormActionTypes.FACEBOOK:
      return facebookReducer(state, action);
    case connectorFormActionTypes.GOOGLE_ADS:
      return googleAdsReducer(state, action);
    case connectorFormActionTypes.DOUBLE_CLICK_OFFLINE:
      return doubleClickOfflineReducer(state, action);
    case connectorFormActionTypes.TRIPOLIS:
      return tripolisReducer(state, action);
    case connectorFormActionTypes.APPNEXUS:
      return appNexusReducer(state, action);
    case connectorFormActionTypes.TRADEDESK:
      return tradeDeskReducer(state, action);

    case connectorFormActionTypes.FETCH_PARTNER_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingPartnerTypes'], setFulfilled());
    case connectorFormActionTypes.FETCH_PARTNER_TYPES.pending:
      return state.setIn(['promise', 'fetchingPartnerTypes'], setPending());
    case connectorFormActionTypes.FETCH_PARTNER_TYPES.rejected:
      return state.setIn(['promise', 'fetchingPartnerTypes'], setRejected());
    case connectorFormActionTypes.SET_PARTNER_TYPES:
      return state.set('partnerTypes', action.data);
    case connectorFormActionTypes.SET_PARTNER:
      return state.set('partner', action.partner);

    case connectorFormActionTypes.CREATE_CONNECTOR.fulfilled:
      return state.setIn(['promise', 'creatingConnector'], setFulfilled());
    case connectorFormActionTypes.CREATE_CONNECTOR.pending:
      return state.setIn(['promise', 'creatingConnector'], setPending());
    case connectorFormActionTypes.CREATE_CONNECTOR.rejected:
      return state.setIn(['promise', 'creatingConnector'], setRejected());

    case connectorFormActionTypes.CLEAR_CONNECTOR_FORM:
      return connectorFormInitialState;

    default:
      return state;
  }
};

export default connectorFormReducer;
