export const CUSTOMER_FACTS_FETCH_START = 'app/profiles/customerFacts/CUSTOMER_FACTS_FETCH_START';
export const CUSTOMER_FACTS_FETCH_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACTS_FETCH_FAIL';
export const CUSTOMER_FACTS_FETCH_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACTS_FETCH_SUCCESS';

export const CUSTOMER_FACTS_ACTIVITIES_FETCH_START = 'app/profiles/customerFacts/CUSTOMER_FACTS_ACTIVITIES_FETCH_START';
export const CUSTOMER_FACTS_ACTIVITIES_FETCH_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACTS_ACTIVITIES_FETCH_FAIL';
export const CUSTOMER_FACTS_ACTIVITIES_FETCH_SUCCESS =
  'app/profiles/customerFacts/CUSTOMER_FACTS_ACTIVITIES_FETCH_SUCCESS';

export const CUSTOMER_FACT_FETCH_START = 'app/profiles/customerFacts/CUSTOMER_FACT_FETCH_START';
export const CUSTOMER_FACT_FETCH_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACT_FETCH_FAIL';
export const CUSTOMER_FACT_FETCH_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACT_FETCH_SUCCESS';

export const CUSTOMER_FACT_ACTIVITY_FETCH_START = 'app/profiles/customerFacts/CUSTOMER_FACT_ACTIVITY_FETCH_START';
export const CUSTOMER_FACT_ACTIVITY_FETCH_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACT_ACTIVITY_FETCH_FAIL';
export const CUSTOMER_FACT_ACTIVITY_FETCH_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACT_ACTIVITY_FETCH_SUCCESS';

export const CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_START =
  'app/profiles/customerFacts/CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_START';
export const CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_FAIL =
  'app/profiles/customerFacts/CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_FAIL';
export const CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_SUCCESS =
  'app/profiles/customerFacts/CUSTOMER_FACT_TRAFFIC_SOURCES_FETCH_SUCCESS';

export const CUSTOMER_FACT_PROPERTIES_FETCH_START = 'app/profiles/customerFacts/CUSTOMER_FACT_PROPERTIES_FETCH_START';
export const CUSTOMER_FACT_PROPERTIES_FETCH_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACT_PROPERTIES_FETCH_FAIL';
export const CUSTOMER_FACT_PROPERTIES_FETCH_SUCCESS =
  'app/profiles/customerFacts/CUSTOMER_FACT_PROPERTIES_FETCH_SUCCESS';

export const CUSTOMER_FACT_ADD_PROPERTY_START = 'app/profiles/customerFacts/CUSTOMER_FACT_ADD_PROPERTY_START';
export const CUSTOMER_FACT_ADD_PROPERTY_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACT_ADD_PROPERTY_FAIL';
export const CUSTOMER_FACT_ADD_PROPERTY_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACT_ADD_PROPERTY_SUCCESS';

export const CUSTOMER_FACT_SAVE_START = 'app/profiles/customerFacts/CUSTOMER_FACT_SAVE_START';
export const CUSTOMER_FACT_SAVE_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACT_SAVE_FAIL';
export const CUSTOMER_FACT_SAVE_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACT_SAVE_SUCCESS';

export const CUSTOMER_FACTS_DELETE_START = 'app/profiles/customerFacts/CUSTOMER_FACTS_DELETE_START';
export const CUSTOMER_FACTS_DELETE_FAIL = 'app/profiles/customerFacts/CUSTOMER_FACTS_DELETE_FAIL';
export const CUSTOMER_FACTS_DELETE_SUCCESS = 'app/profiles/customerFacts/CUSTOMER_FACTS_DELETE_SUCCESS';

export const SHOW_DEPENDANTS_CONTAINER = 'app/profiles/customerFacts/SHOW_DEPENDANTS_CONTAINER';
export const HIDE_DEPENDANTS_CONTAINER = 'app/profiles/customerFacts/HIDE_DEPENDANTS_CONTAINER';
export const DEPENDANTS_TYPE_TAB_CHANGE = 'app/profiles/customerFacts/DEPENDANTS_TYPE_TAB_CHANGE';

export const SET_USER_IS_EDITING = 'app/profiles/engagements/SET_USER_IS_EDITING';

export const CUSTOMER_FACT_USED_IN = [
  'SELECTION',
  'THIRD_PARTY_DATA_COLLECTOR',
  'JOURNEY',
  'FILTER',
  'PROPERTY_TRANSFORMATION',
];
export const ALLOWED_NUMBER_OF_PROPERTIES = 64;
