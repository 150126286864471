import { toArray, filter } from 'lodash';
import { getAngularService } from 'ReactAngular/react.service';
import * as fromModals from '~/modals';
import { changeUrl, normalizeValue } from '~/common';
import * as selectors from '~/user/settings/selectors';
import UserSettingsService from '../dataService.js';
import * as types from '../types';

/**
 * Fetch user
 */
export const fetchUserSettings = () => dispatch => {
  const SecurityService = getAngularService(document, 'SecurityService');
  dispatch({
    type: types.USER_SETTINGS_FETCH_START,
  });

  return SecurityService.getSecurityContext()
    .then(context => {
      dispatch({
        type: types.USER_SETTINGS_FETCH_SUCCESS,
        payload: {
          user: { ...context.getUser(), password: 'xxxxx' },
          accessibleSites: toArray(filter(context.getSites(), item => !item.deleted && !item.interactiveDemo)),
        },
      });
    })
    .catch(error => {
      dispatch({ type: types.USER_SETTINGS_FETCH_FAIL, error });
    });
};

/**
 * Fetch user settings only if not in store
 */
export const fetchUserSettingsIfNeeded = () => (dispatch, getState) => {
  const userInfo = selectors.getUserInfo(getState());
  if (userInfo && userInfo.userId) {
    return Promise.resolve();
  }
  return dispatch(fetchUserSettings());
};

/**
 * Update the user settings
 * @param userSettings
 */
export const updateUserSettings = userSettings => dispatch => {
  const model = {
    userId: userSettings.userId,
    name: normalizeValue(userSettings.name),
    defaultSiteId: userSettings.defaultSiteId,
  };

  dispatch({ type: types.USER_SETTINGS_UPDATE_START });

  // Update user settings on the backend
  return UserSettingsService.updateUserSettings(model)
    .then(settingsAreUpdatedSuccessfully => {
      if (settingsAreUpdatedSuccessfully) {
        dispatch({
          type: types.USER_SETTINGS_UPDATE_SUCCESS,
          payload: model,
        });
      }

      // Go to the user settings view
      changeUrl('user/settings/view');
    })
    .catch(error => dispatch({ type: types.USER_SETTINGS_UPDATE_FAIL, error }));
};

export const closeModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

export const resetErrorPasswordRequest = () => dispatch => {
  dispatch({
    type: types.RESET_ERROR_PASSWORD_REQUEST,
  });
};

export const updatePassword = formValues => dispatch => {
  dispatch({
    type: types.UPDATE_PASSWORD_START,
  });

  return UserSettingsService.updatePassword(formValues.newPassword, formValues.oldPassword, formValues.smsCode)
    .then(response => {
      if (response.succeeded) {
        dispatch(fromModals.hideModal());
        dispatch({
          type: types.UPDATE_PASSWORD_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: types.UPDATE_PASSWORD_FAIL,
          payload: response,
        });
      }
    })
    .catch(error => dispatch({ type: types.UPDATE_PASSWORD_FAIL, error }));
};

export const unlinkOpenId = () => dispatch =>
  UserSettingsService.unlinkOpenId()
    .then(response => {
      if (response) {
        dispatch({
          type: types.UNLINK_OPEN_ID,
        });
      }
    })
    .catch(error => dispatch({ type: types.UNLINK_OPEN_ID, error }));
