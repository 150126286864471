angular.module('webUi.directive.support.template.propertySelection', [
    'webUi.service.tagmanagement',
    'webUi.service.pubSub'
])
    .factory('TemplatingPropertySelectionService', [
        'TagmanagementService',
        '$q',
        /**
     *
     * @param TagmanagementService
     * @param $q
     * @returns {TemplatingPropertySelectionService}
     */
        function TemplatingPropertySelectionService(TagmanagementService, $q) {

            var STATIC_PROPERTY = 'static';
            var PATH_PROPERTY = 'property';
            var EXTRACTED_PROPERTY = 'extracted';
            var VARIABLE_PROPERTY = 'variable';

            var availableOptionsForWebPlatform = [{
                value: STATIC_PROPERTY,
                label: 'Static',
                template: 'The value is used as is'
            }, {
                value: PATH_PROPERTY,
                label: 'Property',
                template: 'Retrieves a property in your javascript code'
            }, {
                value: EXTRACTED_PROPERTY,
                label: 'Extracted',
                template: 'The value given will search for an element in the HTML page depending on what you fill in. If you provide a # symbol in the beginning, it will search for an element by id. Otherwise it searches for element by tagName or name and returns the first one.'
            }, {
                value: VARIABLE_PROPERTY,
                label: 'Profile Variable',
                template: 'Value extracted from the given variable'
            }];

            var availableOptionsForMobilePlatform = [{
                value: STATIC_PROPERTY,
                label: 'Static',
                template: 'The value is used as is'
            }, {
                value: PATH_PROPERTY,
                label: 'Property',
                template: 'Retrieves a property from your data layer'
            }];

            var getAvailableOptions = function getAvailableOptions(platformType) {
                if (platformType === 'mobile') {
                    return availableOptionsForMobilePlatform;
                }
                return availableOptionsForWebPlatform;
            };

            var getPropertiesForType = function getPropertiesForType(type, location) {
                var deferred = $q.defer();

                if (_.isEmpty(type)) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                switch (type) {

                    case 'static':
                        deferred.resolve([]);
                        break;

                    case 'property':
                        if (_.isEmpty(location)) {
                            deferred.resolve([]);
                            break;
                        }
                        TagmanagementService.getTagProperties(location).then(
                            function onSuccess(tagProps) {
                                deferred.resolve(tagProps);
                            },
                            function onError() {}
                        );
                        break;

                    case 'extracted':
                        deferred.resolve([]);
                        break;
                    case 'variable':
                        deferred.resolve([]);
                        break;
                    default:
                        throw new Error('Unparsable type ' + type + ' provided');
                }
                return deferred.promise;
            };

            return {
                STATIC_PROPERTY: STATIC_PROPERTY,
                PATH_PROPERTY: PATH_PROPERTY,
                EXTRACTED_PROPERTY: EXTRACTED_PROPERTY,
                VARIABLE_PROPERTY: VARIABLE_PROPERTY,
                getAvailableOptions: getAvailableOptions,
                getPropertiesForType: getPropertiesForType
            };
        }
    ])
    .controller('TemplatingPropertySelectionController', [
        '$scope',
        'PubSubService',
        'TemplatingPropertySelectionService',
        /**
     * @param $scope
     * @param TemplatingPropertySelectionService
     * @returns {TemplatingPropertySelectionController}
     */
        function TemplatingPropertySelectionController($scope, PubSubService, TemplatingPropertySelectionService) {

            $scope.selectOption = function (option) {
                $scope.state.selection.type = option.value;
            };

            $scope.r42Variables = $scope.r42Variables || [];

            PubSubService.subscribe(PubSubService.TYPES.GLOBAL.PROFILE_VARS, $scope, function(event, msg) {
                if(!_.isEmpty(msg.profileVars) && !_.isArray(msg.profileVars)) {
                    msg.profileVars = _.toArray(msg.profileVars);
                }
                $scope.r42Variables = msg.profileVars;
            });

            $scope.$watch('state.selection.type', function (newValue, oldValue) {
                if (!_.isUndefined(oldValue) && !_.isEqual(newValue, oldValue)) {
                    $scope.state.selection.value = undefined;
                }
                TemplatingPropertySelectionService.getPropertiesForType(newValue, $scope.state.location).then(function (props) {
                    $scope.state.availableProperties = props;
                });
            }, false);
        }
    ])
    .directive('templatePropertySelection', ['TemplatingPropertySelectionService', 'PubSubService',
    /**
     * @param {TemplatingPropertySelectionService} TemplatingPropertySelectionService
     * @param {PubSubService} PubSubService
     * @returns {templatePropertySelection}
     */
        function templatePropertySelection(TemplatingPropertySelectionService, PubSubService) {

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    r42Model: '=ngModel',
                    r42Validate: '=r42Validate',
                    r42ModelName: '@r42ModelName',
                    r42Placeholder: '@r42Placeholder',
                    r42Required: '@r42Required',
                    r42Location: '=r42Location',
                    r42Types: '@r42Types',
                    r42Variables: '=?r42Variables'
                },

                link: function link(scope, element, attrs) {

                    var defaultTypes = [
                        TemplatingPropertySelectionService.STATIC_PROPERTY,
                        TemplatingPropertySelectionService.PATH_PROPERTY,
                        TemplatingPropertySelectionService.EXTRACTED_PROPERTY];

                    var allowedTypes = scope.$eval(attrs['r42Types']) || defaultTypes;

                    var defaultType = attrs['r42DefaultType'] || allowedTypes[0];
                    var defaultValue = attrs['r42DefaultValue'] || '';

                    scope.r42Model = scope.r42Model || {};
                    scope.r42PlatformType = attrs['r42PlatformType'] || 'web';
                    scope.r42MobileShowRemoveEmpty= (attrs['r42MobileShowRemoveEmpty'] && scope.r42PlatformType === 'mobile') || false;
                    scope.state = {active: false, location: scope.r42Location, selection: scope.r42Model};

                    scope.selectOptions = TemplatingPropertySelectionService.getAvailableOptions(scope.r42PlatformType);

                    scope.selectOptions = _.filter(scope.selectOptions, function(opt) {
                        if(allowedTypes.indexOf(opt.value) === -1) {
                            return false;
                        }
                        return true;
                    });

                    scope.validate = {};
                    //odd function declaration on object literal
                    var _r42Validate = (typeof(scope.r42Validate) === 'function') ?  scope.r42Validate() : scope.r42Validate;
                    _.forEach(_r42Validate, function (val, name) {
                        scope.validate[name] = 'state.selection.type !== "static" || ' + val;
                    });
                    //transform the string into boolean
                    scope.customValidate = {};
                    scope.r42Required =  (scope.r42Required === 'true');
                    if(scope.r42Required) {
                        scope.customValidate['isRequired'] = true;
                    }


                    scope.templateForm = scope.$parent.templateForm;
                    scope.templateHelper = scope.$parent.templateHelper;

                    // check for validity of mapped object
                    if (!_.has(scope.r42Model, 'type') ||
                    !_.has(scope.r42Model, 'value') ||
                    !_.isObject(_.find(scope.selectOptions, {value: scope.r42Model.type}))) {
                        scope.state.selection.type = defaultType;
                        scope.state.selection.value = defaultValue;
                    }

                    //process existing values so that it doesn't contain the segment number attached
                    if(scope.r42Model['type'] === TemplatingPropertySelectionService.VARIABLE_PROPERTY && !_.isUndefined(scope.r42Model['value'])) {
                        scope.r42Model['value'] = scope.r42Model['value'].split(':')[0];
                    }

                    scope.isInputField = function isInputField(selectionType) {
                        return selectionType && selectionType !== TemplatingPropertySelectionService.VARIABLE_PROPERTY;
                    };

                    scope.deactivate = function (event) {
                        if (scope.state.focused) {
                            event.preventDefault();
                            return false;
                        } else {
                            scope.state.active = false;
                        }
                    };

                    if (attrs['r42FullModelName']) {
                        PubSubService.subscribe(attrs['r42FullModelName'], scope.$parent, function (event, msg) {
                            if (msg && msg.type && msg.value) {
                                scope.state.selection.type = msg.type;
                                scope.state.selection.value = msg.value;
                            } else {
                                scope.state.selection.type = defaultType;
                                scope.state.selection.value = defaultValue;
                            }
                        });
                    }

                    /**
                 * @param value
                 * @returns {boolean}
                 */
                    scope.isUnique = function() {
                        return true;
                    };
                },
                templateUrl: 'directive/support/template/propertySelection/propertySelection.tpl.html',
                controller: 'TemplatingPropertySelectionController'
            };
        }
    ]);
