import React from 'react';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    gateName: Yup.string().required(t('validation:validation.required')),
  });

const Selligent = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="gateName"
          label={t('cards.label.seligentGateNameId')}
          placeholder={t('common.audienceCard.placeholder')}
          errorText={formProps.touched.gateName && formProps.errors.gateName}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);

export default Selligent;
