/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { includes } from 'lodash';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Basescripts Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.basescriptId]: action.payload,
      };
    case types.BASESCRIPTS_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(basescript => {
        nextState[basescript.basescriptId] = basescript;
      });
      return nextState;
    case types.USE_BASESCRIPT_IN_SITES_SUCCESS:
      nextState = { ...state };
      Object.entries(nextState).forEach(([basescriptId, basescript]) => {
        if (basescriptId === action.payload.basescriptId) {
          action.payload.siteIds.forEach(siteId =>
            basescript.linkedToSites.push({
              basescriptId,
              siteId,
              used: true,
            }),
          );
        } else {
          basescript.linkedToSites = basescript.linkedToSites.filter(
            linkedToSite => !includes(action.payload.siteIds, linkedToSite.siteId),
          );
        }
      });
      return nextState;
    default:
      return state;
  }
};

/**
 * Basescripts Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.SAVE_SUCCESS:
      if (state.indexOf(action.payload.basescriptId) !== -1) {
        return state;
      }
      return [...state, action.payload.basescriptId];
    case types.BASESCRIPTS_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(basescript => {
        if (state.indexOf(basescript.basescriptId) === -1) {
          nextState.push(basescript.basescriptId);
        }
      });
      return nextState;
    default:
      return state;
  }
};

/**
 * Basescript Modules Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const basescriptModules = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.BASESCRIPT_MODULES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingBasescripts: false,
  shouldFetchBasescripts: true,
  isFetchingBasescriptModules: false,
  shouldFetchBasescriptModules: true,
  error: null,
  basescriptsFilter: '',
  sitesFilter: '',
  linkSitesToBasescriptFilter: '',
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.BASESCRIPTS_FETCH_START:
      return {
        ...state,
        isFetchingBasescripts: true,
        shouldFetchBasescripts: false,
      };
    case types.BASESCRIPTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingBasescripts: false,
        shouldFetchBasescripts: false,
      };
    case types.BASESCRIPT_MODULES_FETCH_START:
      return {
        ...state,
        isFetchingBasescriptModules: true,
        shouldFetchBasescriptModules: false,
      };
    case types.BASESCRIPT_MODULES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingBasescriptModules: false,
        shouldFetchBasescriptModules: false,
      };
    case types.SET_BASESCRIPTS_FILTER:
      return {
        ...state,
        basescriptsFilter: action.payload,
      };
    case types.REMOVE_BASESCRIPTS_FILTER:
      return {
        ...state,
        basescriptsFilter: '',
      };
    case types.SET_SITES_FILTER:
      return {
        ...state,
        sitesFilter: action.payload,
      };
    case types.REMOVE_SITES_FILTER:
      return {
        ...state,
        sitesFilter: '',
      };
    case types.SET_LINK_SITES_TO_BASESCRIPT_FILTER:
      return {
        ...state,
        linkSitesToBasescriptFilter: action.payload,
      };
    case types.REMOVE_LINK_SITES_TO_BASESCRIPT_FILTER:
      return {
        ...state,
        linkSitesToBasescriptFilter: '',
      };
    default:
      return state;
  }
};

const useByBasescriptId = (state = {}, action = defaultAction) => {
  let nextState;
  let sitesToBeUsedByBasescript;
  switch (action.type) {
    case types.RESET_BASESCRIPT_USAGE:
      return {
        ...state,
        [action.payload.basescriptId]: [],
      };
    case types.TOGGLE_BASESCRIPT_USAGE_IN_SITE:
      nextState = { ...state };
      sitesToBeUsedByBasescript = nextState[action.payload.basescriptId] || [];
      if (sitesToBeUsedByBasescript.indexOf(action.payload.siteId) !== -1) {
        sitesToBeUsedByBasescript = sitesToBeUsedByBasescript.filter(siteId => siteId !== action.payload.siteId);
      } else {
        sitesToBeUsedByBasescript = [...sitesToBeUsedByBasescript, action.payload.siteId];
      }
      nextState[action.payload.basescriptId] = sitesToBeUsedByBasescript;
      return nextState;
    default:
      return state;
  }
};

const basescriptsReducer = combineReducers({
  byId,
  allIds,
  basescriptModules,
  ui,
  useByBasescriptId,
});

export default basescriptsReducer;
