import React from 'react';
import './styles.scss';

interface StatusTagProps {
  text: string;
  className?: string;
}

const StatusTag = ({ text, className }: StatusTagProps): React.ReactElement => (
  <span className={`StatusTag ${className}`}>{text}</span>
);

export default StatusTag;
