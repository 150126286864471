/**
 * @class webUi.InteractionService
 * @memberOf webUi
 */
angular.module('webUi.service.profiles.interactions', [
    'restangular',
    'webUi.common.Utils'
])
    .factory('InteractionService',
        [
            'Restangular',
            '$q',
            /**
             * @function InteractionService
             * @memberOf webUi.InteractionService
             * @description This is an angularjs service.
             *
             * @param {Restangular} Restangular
             * @param $q
             * @param Utils
             *
             * @returns {InteractionService}
             */
            function InteractionService(Restangular, $q){

                // The base path through which all calls should go
                var ENGAGEMENT_BASE_PATH = 'profiles/engagements';
                var EXTERNAL_FACT_BASE_PATH = 'profiles/externalfacts';

                var ENGAGEMENT_INTERACTION_TYPE = 'ENGAGEMENT';

                /**
                 * @description Gets base path given interaction type
                 * @param interactionType - interaction type (Engagement or External fact)
                 */
                var getBasePath = function(interactionType) {
                    return interactionType.toUpperCase() === ENGAGEMENT_INTERACTION_TYPE ? ENGAGEMENT_BASE_PATH : EXTERNAL_FACT_BASE_PATH;
                };

                /**
                 * @description Gets interaction given the id and interaction type
                 * @param id - interaction id
                 * @param interactionType - interaction type (Engagement or External fact)
                 */
                var getInteraction = function(id, interactionType) {
                    var interactionPromise = $q.defer();
                    var basePath = getBasePath(interactionType);

                    Restangular.one(basePath + '/', id).get().then(function(interaction) {
                        if(interaction) {
                            interaction = Restangular.stripRestangular(interaction);
                            interactionPromise.resolve(interaction);
                        }
                    });
                    return interactionPromise.promise;
                };

                /**
                 * @description Gets all interactions given interaction type
                 * @param interactionType - interaction type (Engagement or External fact)
                 */
                var getInteractions = function(interactionType) {
                    var interactionIdPromise = $q.defer();
                    var basePath = getBasePath(interactionType);

                    Restangular.all(basePath).getList().then(function(interactions) {
                        if(interactions) {
                            interactions = Restangular.stripRestangular(interactions);
                            interactionIdPromise.resolve(interactions);
                        }
                    });
                    return interactionIdPromise.promise;
                };

                return {
                    getBasePath: getBasePath,
                    getInteraction: getInteraction,
                    getInteractions: getInteractions,
                };
            }
        ]);
