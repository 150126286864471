import React from 'react';
import { withState } from 'recompose';
import cx from 'classnames';
import { Field } from 'redux-form';
import i18n from '~/i18n';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import BtnOutlined from '~/components/src/BtnOutlined';

/**
 * Wrapper to redux-form field, which allow to show button for enable edit mode
 */
const FieldWithEditMode = ({
  label,
  isEditMode,
  setEditMode,
  dependentFieldEdit = false,
  wrapperLabel = '',
  ...props
}) => (
  <>
    {!isEditMode && !dependentFieldEdit ? (
      <FieldWrapper label={wrapperLabel} isEditMode={isEditMode} className={cx({ [props.className]: !!wrapperLabel })}>
        <BtnOutlined
          className={cx('mr-4', { 'w-full py-3': !!wrapperLabel })}
          onClick={() => setEditMode(true)}
          testHook="showInputButton"
          fullWidth={!!wrapperLabel}
        >
          {i18n.t('partners:form.edit')}
        </BtnOutlined>
        {!wrapperLabel && <span>{label}</span>}
      </FieldWrapper>
    ) : (
      <Field label={label} {...props} />
    )}
  </>
);

export default withState(
  'isEditMode',
  'setEditMode',
  ({ isNeedActivateEditMode = false }) => !isNeedActivateEditMode,
)(FieldWithEditMode);
