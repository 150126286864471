import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { facebookActionTypes } from './facebookActions';

const facebookReducer = (state, action) => {
  switch (action.name) {
    case facebookActionTypes.FETCH_PAYLOAD_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setFulfilled());
    case facebookActionTypes.FETCH_PAYLOAD_TYPES.pending:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setPending());
    case facebookActionTypes.FETCH_PAYLOAD_TYPES.rejected:
      return state.setIn(['promise', 'fetchingPayloadTypes'], setRejected());
    case facebookActionTypes.SET_PAYLOAD_TYPES:
      return state.set('payloadTypes', action.data);

    default:
      return state;
  }
};

export default facebookReducer;
