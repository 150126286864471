import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { FetchTotalStatsResponse } from './types';

const BASE_PATH = `${API_BASE}/dashboard`;

const params = (rangeFrom: number, rangeTo: number, filterType: string | null = null, size = 3) =>
  `rangeFrom=${rangeFrom}&rangeTo=${rangeTo}${filterType ? `&workflowType=${filterType}` : ''}&size=${size}`;

export const fetchAudienceStats = (rangeFrom: number, rangeTo: number) =>
  Api.callGet(`${BASE_PATH}/segments?${params(rangeFrom, rangeTo)}`).then(parseResponse);

export const fetchJOStats = (rangeFrom: number, rangeTo: number, filterType: string) =>
  Api.callGet(`${BASE_PATH}/journeys?${params(rangeFrom, rangeTo, filterType)}`).then(parseResponse);

export const fetchTotalStats = (rangeFrom: number, rangeTo: number): Promise<FetchTotalStatsResponse> =>
  Api.callGet(`${BASE_PATH}/siteTotalUsage?${params(rangeFrom, rangeTo)}`).then(parseResponse);

export const fetchPartnerStats = (rangeFrom: number, rangeTo: number) =>
  Api.callGet(`${BASE_PATH}/partners?${params(rangeFrom, rangeTo)}`).then(parseResponse);
