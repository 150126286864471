import moment, { Moment } from 'moment';
import { compose } from 'redux';
import { formatPercentage } from '~/common';
import { CHART_COLORS, ORCHESTRATION_CHART_COLORS } from '~/common/chart';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { transformCriteria } from '~/profiles/audiences';
import {
  addNamesFromRuleDefinitions,
  addNamesFromRuleDefinitionsToPath,
  addNamesToJourneyOverlapRules,
  addPropertiesToCriteria,
  addTitleAndTypeLabel,
  addVariablesToCriteria,
  flattenCriteria,
} from '~/profiles/audiences/audienceUtils';
import { shapeCriteriaRequest } from '~/profiles/audiences/utils';
import { NodeTypes } from '~/workflows/constants';
import {
  createExitCondition,
  createGoal,
  createTriggers,
  fetchExitConditionDailyStats,
  fetchExitConditionStats,
  fetchGoalDailyStats,
  fetchGoalStats,
  fetchTrigger,
  updateTriggers,
} from '~/workflows/dataService';
import { RuleDefinitionsReponse } from '~/workflows/types';
import { removeItemsNotAvailableInOrchestration } from '~/workflows/util';
import { ORIGIN_TYPES } from '../../Statistics/constants';
import {
  GoalStatsHighchartsData,
  StepDailyStats,
  StepDailyStatsOrigin,
  StepStatsHighchartsData,
  TriggerDailyStats,
  TriggerDailyStatsItem,
} from '../types';

export const fetchTriggerIfEdit = (triggerId: string | undefined) => {
  const isEdit = !!triggerId;

  if (isEdit) {
    return fetchTrigger(triggerId);
  }

  return Promise.resolve({});
};

const handleCreateStartTrigger = ({ journeyId, triggerData }: any) => {
  const payload = {
    type: NodeTypes.START_TRIGGER,
    name: triggerData.title,
    journeyId,
    criteria: shapeCriteriaRequest(triggerData.criteria),
  };

  return createTriggers(payload).then(() => {
    showSuccess({ header: i18n.t('workflow:orchestration.triggers.triggerCreated') });
  });
};

const handleCreateTriggerAfterStep = ({ journeyId, triggerData, parentStepId }: any) => {
  const payload = {
    type: NodeTypes.TRIGGER,
    name: triggerData.title,
    journeyId,
    parentStepId,
    criteria: shapeCriteriaRequest(triggerData.criteria),
  };

  return createTriggers(payload).then(() => {
    showSuccess({ header: i18n.t('workflow:orchestration.triggers.triggerCreated') });
  });
};

const handleCreateGoal = ({ journeyId, triggerData }: any) => {
  const payload = {
    name: triggerData.title,
    journeyId,
    criteria: shapeCriteriaRequest(triggerData.criteria),
  };

  return createGoal(payload).then(() => {
    showSuccess({ header: i18n.t('workflow:orchestration.goals.goalCreated') });
  });
};

const handleCreateExitCondition = ({ journeyId, triggerData }: any) => {
  const payload = {
    name: triggerData.title,
    journeyId,
    criteria: shapeCriteriaRequest(triggerData.criteria),
  };

  return createExitCondition(payload, journeyId).then(() => {
    showSuccess({ header: i18n.t('workflow:orchestration.exits.exitConditionCreated') });
  });
};

const handleUpdateTrigger = ({ journeyId, triggerId, triggerData, type = NodeTypes.TRIGGER }: any) => {
  const isGoal = type === NodeTypes.GOAL;
  const isExitCondition = type === NodeTypes.EXIT_BY_CONDITION;

  let successHeader = i18n.t('workflow:orchestration.triggers.triggerSaved');
  if (isGoal) successHeader = i18n.t('workflow:orchestration.goals.goalSaved');
  if (isExitCondition) successHeader = i18n.t('workflow:orchestration.exits.exitConditionSaved');

  const updateCriteriaPayload = {
    journeyId,
    name: triggerData.title,
    triggerId,
    criteria: shapeCriteriaRequest(triggerData.criteria),
  };

  return updateTriggers(updateCriteriaPayload).then(() => {
    showSuccess({ header: successHeader });
  });
};

const onlyFieldsUsedInTriggerView = (criteria: any[]) =>
  criteria.map((ruleSet: any) =>
    ruleSet.map((rule: any) => ({
      ...rule,
      type: rule?.type,
      ruleType: rule?.ruleType,
      ruleId: rule?.ruleId,
      title: rule?.title,
      typeLabel: rule?.typeLabel,
      timeCondition: rule?.timeCondition,
      waitTime: rule?.waitTime,
      properties: rule?.properties,
      negation: rule?.negation,
    })),
  );

export const prepareDataForViewTrigger = (trigger: any, ruleDefinitions: any, variables: any) => {
  const criteria: any = compose(
    onlyFieldsUsedInTriggerView,
    addTitleAndTypeLabel,
    addPropertiesToCriteria,
    flattenCriteria,
    addVariablesToCriteria(variables),
    addNamesToJourneyOverlapRules,
    addNamesFromRuleDefinitions(ruleDefinitions),
  )(trigger.criteria);

  return {
    triggerId: trigger.triggerId,
    title: trigger.name,
    type: trigger.type,
    criteria: criteria || [],
  };
};

export const getTitleAndSubmitHandler = (triggerId: string | undefined, parentStepId: string | null, type: string) => {
  const isGoal = type === NodeTypes.GOAL;
  const isExitCondition = type === NodeTypes.EXIT_BY_CONDITION;

  if (!triggerId && !parentStepId && !isGoal && !isExitCondition) {
    return {
      pageTitle: i18n.t('workflow:orchestration.triggers.createStartTrigger'),
      submitHandler: handleCreateStartTrigger,
    };
  }

  if (!triggerId && parentStepId && !isGoal && !isExitCondition) {
    return {
      pageTitle: i18n.t('workflow:orchestration.triggers.createTrigger'),
      submitHandler: handleCreateTriggerAfterStep,
    };
  }
  if (triggerId && !isGoal && !isExitCondition) {
    return {
      pageTitle: i18n.t('workflow:orchestration.triggers.editTrigger'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (triggerId && !isExitCondition && isGoal) {
    return {
      pageTitle: i18n.t('workflow:orchestration.goals.editGoal'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (triggerId && isExitCondition && !isGoal) {
    return {
      pageTitle: i18n.t('workflow:orchestration.exits.editExitCondition'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (isGoal && !isExitCondition) {
    return {
      pageTitle: i18n.t('workflow:orchestration.goals.createGoal'),
      submitHandler: handleCreateGoal,
    };
  }

  return {
    pageTitle: i18n.t('workflow:orchestration.exits.createExitCondition'),
    submitHandler: handleCreateExitCondition,
  };
};

export const fetchRuleTypesAndTrigger = (
  ruleDefinitions: RuleDefinitionsReponse[],
  trigger: any,
  excludeRules = {},
) => {
  let transformedTrigger: any = null;
  if (trigger) {
    transformedTrigger = addNamesFromRuleDefinitionsToPath(ruleDefinitions, 'criteria')(trigger);
    transformedTrigger = {
      ...transformedTrigger,
      criteria: transformCriteria(transformedTrigger.criteria),
    };
  }
  return [
    removeItemsNotAvailableInOrchestration(getRulesTreeFromTypes(ruleDefinitions, excludeRules)),
    transformedTrigger,
  ];
};

export const mapStepStats = (stats: StepDailyStats[], origin: StepDailyStatsOrigin): StepStatsHighchartsData => {
  const entered: number[] = [];
  const exited: number[] = [];
  const nextStep: number[] = [];
  const reachedGoal: number[] = [];
  const timestamp: string[] = [];

  stats.forEach(stat => {
    entered.push(stat.profilesAdded);
    exited.push(stat.profilesReachedExit + stat.profilesRemoved + stat.profilesMerged + stat.profilesExitedByCondition);
    nextStep.push(stat.profilesMovedToNextStep);
    reachedGoal.push(stat.profilesReachedGoal);
    timestamp.push(moment(stat.key.timestamp).format('D. MMM'));
  });

  return {
    series: [
      {
        name: i18n.t('workflow:journey.chart.entered'),
        data: entered,
        stack: 'entered',
        color: '#417505',
      },
      ...(origin === 'HISTORIC_PROCESSING'
        ? []
        : [
            {
              name: i18n.t('workflow:journey.chart.exitedJourney'),
              data: exited,
              stack: 'exited',
              color: '#0054b0',
            },
            {
              name: i18n.t('workflow:journey.chart.enteredNextStep'),
              data: nextStep,
              stack: 'nextStep',
              color: '#715ed0',
            },
            {
              name: i18n.t('workflow:journey.chart.reachedGoal'),
              data: reachedGoal,
              stack: 'reachedGoal',
              color: '#ffa638',
            },
          ]),
    ],
    xAxis: timestamp,
    noDataMessage: i18n.t('workflow:journey.chart.emptyMessage'),
  };
};

export const mapTriggerStats = (stats: TriggerDailyStatsItem[], isGoal: boolean): GoalStatsHighchartsData => {
  const keys: string[] = [];
  const batchKeys: string[] = [];
  const colors = [CHART_COLORS.GREEN, CHART_COLORS.BLUE, CHART_COLORS.LIGHTGREEN];
  const legends: Record<string, number[]> = {};
  const batchLegends: Record<string, number[]> = {};
  const timestamp: string[] = [];

  const addStatToLegend = (key: string, value: number, keys: string[], legends: Record<string, number[]>) => {
    if (!keys.includes(key)) {
      keys.push(key);
      legends[key] = [value];
    } else {
      legends[key].push(value);
    }
  };

  const processDefaultStats = (stat: TriggerDailyStatsItem, keys: string[], legends: Record<string, number[]>) => {
    if (stat.variantStats.length === 0) {
      addStatToLegend(
        'Default',
        (isGoal ? stat.profilesReachedGoal : stat.profilesExitedByCondition) || 0,
        keys,
        legends,
      );
    } else {
      stat.variantStats.forEach(variant => addStatToLegend(variant.name, variant.profilesReached, keys, legends));
    }
  };

  stats.forEach(stat => {
    const timeStampKey = moment(stat.dayTimestamp).format('D. MMM');
    if (!timestamp.includes(timeStampKey)) {
      timestamp.push(timeStampKey);
    }

    if (stat.origin === ORIGIN_TYPES.HISTORIC_PROCESSING) {
      processDefaultStats(stat, batchKeys, batchLegends);
    } else {
      processDefaultStats(stat, keys, legends);
    }
  });

  const series = [
    ...keys.map((key, index) => ({
      name: keys[index],
      data: legends[key],
      stack: index,
      color: colors[index],
    })),
    ...batchKeys.map((key, index) => ({
      name: 'Batched',
      data: batchLegends[key],
      stack: index,
      color: ORCHESTRATION_CHART_COLORS.BATCH,
      linkedTo: index,
    })),

    {
      name: i18n.t('workflow:journey.chart.batched'),
      type: 'line',
      data: [],
      color: ORCHESTRATION_CHART_COLORS.BATCH,
      stack: 'batched',
      events: {
        legendItemClick() {
          const { chart } = this;
          const { series } = chart;

          const batchedStack = series.find((s: Highcharts.Series) => s.options.stack === 'batched');

          series.forEach((s: Highcharts.Series) => {
            if (s.options.name === i18n.t('workflow:journey.chart.batched') && s.options.stack !== 'batched')
              s.setVisible(!batchedStack.visible);
          });
        },
      },
    },
  ];

  return {
    series,
    xAxis: timestamp,
    noDataMessage: i18n.t('workflow:journey.chart.emptyMessage'),
  };
};

const mapStats = (data: any, type: string) => {
  const triggerAnalysis = type === NodeTypes.GOAL ? data.goalAnalysis : data.exitConditionAnalysis;

  const stats = {
    ...data,
    variantAnalysis: data?.variantAnalysis?.map((variant: any) => ({
      ...variant,
      profilesReached: type === NodeTypes.GOAL ? variant.profilesReachedGoal : variant.profilesExitedByCondition,
      conversionRate: variant.profilesEntered
        ? formatPercentage(
            (type === NodeTypes.GOAL ? variant.profilesReachedGoal : variant.profilesExitedByCondition) /
              variant.profilesEntered,
          )
        : 0,
    })),
    triggerAnalysis: triggerAnalysis.map((row: any) => ({
      ...row,
      profilesReached: type === NodeTypes.GOAL ? row.profilesReachedGoal : row.profilesExitedByCondition,
    })),
  };
  return stats;
};

export const getTriggerStats = async (journeyId: string, triggerId: string, type: string) => {
  const data =
    type === NodeTypes.GOAL
      ? await fetchGoalStats(journeyId, triggerId)
      : await fetchExitConditionStats(journeyId, triggerId);

  return mapStats(data, type);
};

export const getTriggerDailyStats = (
  journeyId: string,
  triggerId: string,
  startDate: Moment,
  endDate: Moment,
  type: string,
  origin?: string,
): Promise<TriggerDailyStats> => {
  if (type === NodeTypes.GOAL)
    return fetchGoalDailyStats(
      journeyId,
      triggerId,
      startDate.startOf('day').toDate().getTime(),
      endDate.endOf('day').toDate().getTime(),
      origin,
    );
  return fetchExitConditionDailyStats(
    journeyId,
    triggerId,
    startDate.startOf('day').toDate().getTime(),
    endDate.endOf('day').toDate().getTime(),
    origin,
  );
};
