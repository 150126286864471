import React, { useContext, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import EditableRulesList from '~/common/modules/rules/components/EditableRulesList';
import { RulesTypes } from '~/common/modules/rules/types';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import Icons from '~/components/src/Icons';
import { shapeCriteriaRequest } from '~/profiles/audiences/utils';
import { EXPIRY_OPTIONS, NODE_SHOWN_NAME, NodeTypes } from '~/workflows/constants';
import { createTriggers, updateTriggers } from '~/workflows/dataService';
import { TRule, TargetRuleConfigurationProps, TriggerCriteriaRule } from '~/workflows/types';
import { joinCriteriaVariables } from '~/workflows/util';
import { AudienceContext } from '../AudienceContext';
import Actions from './ActionButtons';
import { fetchJourneyOverlaps } from '../../utils';
import DirtyFormPopup from './DirtyFormPopup';
import { useDirtyFormHandler } from '../hooks';
import StepHeader from './StepHeader';

const TargetRuleConfiguration = ({
  allVariables = [],
  ruleTypesTree = [],
  triggerData,
  workflowId,
  triggerId,
  refetchAllVariables,
  goToNextStep,
  refetchTree,
}: TargetRuleConfigurationProps): React.ReactElement => {
  const isEdit = !!triggerId;
  const { isOptional } = useContext(AudienceContext);
  const [isValid, setIsValid] = useState(isEdit);
  const { processDirtyForm, showDirtyFormPopup, setShowDirtyFormPopup, cbRef } = useDirtyFormHandler();

  const { defaultTimeCondition } = triggerData || {};

  const validationSchema = Yup.object().shape({
    description: Yup.string().trim(),
    timeframe: Yup.number().required(i18n.t('validation:validation.required')),
  });

  const mapCriteria = (criteria: any) => joinCriteriaVariables(criteria || [], allVariables || []);

  const initialValues = {
    timeframe: defaultTimeCondition ? defaultTimeCondition.substring(0, defaultTimeCondition.length - 1) : 30,
    timeframeOption: defaultTimeCondition ? defaultTimeCondition[defaultTimeCondition.length - 1] : 'd',
    criteria: mapCriteria(triggerData?.criteria ?? []),
  };
  const handleUpdateTargetRules = async (values: typeof initialValues) => {
    const { timeframe, timeframeOption } = values;
    const payload = {
      ...triggerData,
      name: NODE_SHOWN_NAME.rules,
      defaultTimeCondition: timeframe + timeframeOption,
      criteria: shapeCriteriaRequest(values?.criteria) as { rules: TriggerCriteriaRule[] }[],
      journeyId: workflowId,
      type: NodeTypes.START_TRIGGER,
    };

    if (triggerId) {
      await updateTriggers(payload);
      showSuccess({ body: i18n.t('workflow:create.steps.step2.api.updated') });
    } else {
      await createTriggers(payload);
      showSuccess({ body: i18n.t('workflow:create.steps.step2.api.created') });
    }
    refetchTree();
    goToNextStep();
  };

  const alreadySelectedOverlapJourneyIds =
    triggerData?.criteria ||
    []
      .flat()
      .filter((rule: TRule) => rule.type === RulesTypes.JourneyOverlap)
      .map((rule: any) => rule?.dependantJourneyId);

  const isOverlapRulesLimitReached = alreadySelectedOverlapJourneyIds.length >= 5;

  const ruleTypes = ruleTypesTree?.map((rule: TRule) => {
    if (rule.type === RulesTypes.JourneyOverlap) {
      return {
        ...rule,
        isDisabled: isOverlapRulesLimitReached,
        subtitle: isOverlapRulesLimitReached
          ? i18n.t('audiences:rules.descriptions.JourneyOverlapRuleLimitReached', { limit: 5 })
          : rule.subtitle,
        subMenu: null,
        customSubMenu: {
          fetchData: () => fetchJourneyOverlaps(rule, workflowId, alreadySelectedOverlapJourneyIds),
        },
      };
    }
    return rule;
  });

  return (
    <>
      <div className="px-2">
        <StepHeader title={i18n.t('workflow:create.steps.step2.title')} isOptional={isOptional} />

        <Formik
          onSubmit={handleUpdateTargetRules}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange
        >
          {({ handleSubmit, isValid: formIsValid, errors, isSubmitting, setFieldValue, values, dirty }) => (
            <form onSubmit={handleSubmit} className="p-2 py-4">
              <div className="w-1/3">
                <label className="py-2">
                  {i18n.t('workflow:create.steps.step2.fields.timeframe')}
                  <Icons
                    className="mx-2 h-5 w-5 text-gray-500"
                    icon="info"
                    tooltip={i18n.t('workflow:create.steps.step2.fields.tooltip')}
                  />
                </label>

                <div className="mb-4 flex gap-2">
                  <Field
                    name="timeframe"
                    as={FormikInputField}
                    className="w-full"
                    type="number"
                    placeholder={i18n.t('workflow:common.placeholder')}
                    errorText={errors?.timeframe}
                  />
                  <Field
                    name="timeframeOption"
                    className="w-full"
                    as={FormikSelectField}
                    getOptionLabel={(option: { label: string; value: string }) => option.label}
                    getOptionValue={(option: { label: string; value: string }) => option.value}
                    customSetFieldValue={(name: string, option: { label: string; value: string }) => {
                      setFieldValue(name, option.value);
                    }}
                    options={EXPIRY_OPTIONS}
                  />
                </div>
              </div>

              <EditableRulesList
                criteria={values.criteria}
                ruleTypesTree={ruleTypes}
                onChange={(criteriaState: any) => {
                  setFieldValue('criteria', mapCriteria(criteriaState.criteria));
                  setIsValid(criteriaState.isValid);
                }}
                fetchAllVariables={refetchAllVariables}
                isJourneyCondition={false}
                isSubmitted={isSubmitting}
                isUsedInJourneys={false}
                defaultWaitTime={`${values.timeframe}${values.timeframeOption}`}
                isJoTrigger
                enableGroupBy
              />

              <Actions
                onConfirm={handleSubmit}
                isConfirmEnabled={formIsValid && values?.criteria?.length > 0 && isValid}
                confirmText={i18n.t('workflow:actions.next')}
                dirty={dirty}
                processDirtyForm={processDirtyForm}
                checkByIsConfirm
              />
            </form>
          )}
        </Formik>
      </div>

      <DirtyFormPopup {...{ cbRef, showDirtyFormPopup, setShowDirtyFormPopup }} />
    </>
  );
};

export default TargetRuleConfiguration;
