import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get, isFunction } from 'lodash';
import { components, enhancements } from '~/components/src/Table';
import Link from '~/components/src/Link';
import Empty from '~/components/src/Empty';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { getDateAndTime } from '~/common/utils/DateUtil';
import { Panel } from '~/components/src/Containers';
import i18n from '~/i18n';

const { Table, Row, Cell } = components;
const { withEmpty, withFilter, withSort, withPaginate } = enhancements;

const EmptyList = ({ emptyBody }) => <Empty header={i18n.t('users:list.empty.header')} body={emptyBody} />;

const NoResults = () => (
  <Empty header={i18n.t('users:list.noResults.header')} body={i18n.t('users:list.noResults.body')} />
);

const WIDTHS = {
  NAME: 'u-size3of10',
  EMAIL: 'u-size3of10',
  LAST_LOGIN: 'u-size2of10',
  CLIENT_NAME: 'u-size2of10',
};

const props = (className, sortKey, title, sortFn) => ({
  className,
  sortKey,
  title,
  sortFn,
});

const UserName = ({ url, item }) =>
  url && isFunction(url) ? <Link href={url(item)}>{item.name}</Link> : <span>{item.name}</span>;

export const UsersList = ({ list, stateKey, userUrl, withPanel }) => {
  const PanelOrNothing = withPanel ? Panel : React.Fragment;

  return (
    <PanelOrNothing>
      <Table stateKey={stateKey} testHook="users">
        <Row>
          <SortableHeader {...props(`${WIDTHS.NAME}`, 'name', i18n.t('users:list.name'))} />
          <SortableHeader {...props(`${WIDTHS.EMAIL}`, 'username', i18n.t('users:list.username'))} />
          <SortableHeader {...props(`${WIDTHS.LAST_LOGIN}`, 'lastLoginDate', i18n.t('users:list.lastLogin'))} />
          <SortableHeader {...props(`${WIDTHS.CLIENT_NAME}`, 'clientPojoName', i18n.t('users:list.clientName'))} />
        </Row>
        {list.map(item => (
          <Row key={item.userId}>
            <Cell className={WIDTHS.NAME}>
              <UserName url={userUrl} item={item} />
            </Cell>
            <Cell className={WIDTHS.EMAIL}>{item.username}</Cell>
            <Cell className={WIDTHS.LAST_LOGIN}>{getDateAndTime(item.lastLoginDate)}</Cell>
            <Cell className={WIDTHS.CLIENT_NAME}>{get(item, 'clientPojo.name', ' ')}</Cell>
          </Row>
        ))}
      </Table>
    </PanelOrNothing>
  );
};

UsersList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  userUrl: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default compose(
  withEmpty({ component: EmptyList }),
  withFilter(),
  withEmpty({ component: NoResults }),
  withSort(),
  withPaginate({ size: 25 }),
)(UsersList);
