import React, { ReactElement, useContext, useState } from 'react';
import { changeUrl } from '~/common';
import { Heading, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import i18n from '~/i18n';
import FilterDependencyList from './FilterDependencyList';
import FiltersTable from './FiltersTable';
import { FilterListType } from './types';
import { FiltersContext } from './FiltersContext';

function FiltersDashboard(): ReactElement {
  const [query, setQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<FilterListType | null>(null);
  const { canEditProfiles, filters: filtersList, refetchFilters } = useContext(FiltersContext);

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50">
      <div className="w-3/4">
        <Heading title={i18n.t('filters:list.header')}>
          <div className="flex items-center gap-2">
            <SearchElement
              testHook="filterSearch"
              value={query}
              onChange={e => setQuery(e.target.value || '')}
              placeholder="Search"
            />
            {canEditProfiles && (
              <BtnIcon
                icon="add"
                onClick={() => changeUrl('profiles/filters/add')}
                testHook="createFilter"
                color="blue"
                tooltip={i18n.t('filters:tooltips.add')}
              />
            )}
          </div>
        </Heading>
        <Notification kind="information">
          <p>{i18n.t('filters:list.description')}</p>
        </Notification>
        <FiltersTable
          filtersList={filtersList}
          filterQuery={query}
          canEditProfiles={canEditProfiles}
          refetchFilters={refetchFilters}
          setSelectedFilter={setSelectedFilter}
        />
        <FilterDependencyList selectedFilter={selectedFilter} handleClose={() => setSelectedFilter(null)} />
      </div>
    </div>
  );
}

export default FiltersDashboard;
