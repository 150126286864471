angular.module('webUi.ui.error.403', ['webUi.ui.error.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.error.403', {
                url : '/403',
                templateUrl : 'ui/error/403/403.tpl.html',
                controller : 'Error403Controller'
            });
    }])

    .controller('Error403Controller', ['$rootScope', 
        function Error403Controller($rootScope) {
            $rootScope.errorPage = true;
        }]);
