import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import AdminColumnService from './dataService.js';

/**
 * Fetch columns
 */
export const fetchColumns = () => dispatch => {
  dispatch({ type: types.COLUMNS_FETCH_START });

  return AdminColumnService.getColumns()
    .then(columns => {
      dispatch({ type: types.COLUMNS_FETCH_SUCCESS, payload: columns });
    })
    .catch(error => dispatch({ type: types.COLUMNS_FETCH_FAIL, payload: error }));
};

/**
 * Saves new column
 * @param column
 */
export const saveColumn = column => dispatch => {
  dispatch({ type: types.COLUMN_SAVE_START });

  return AdminColumnService.saveColumn(column)
    .then(columnId => {
      dispatch({
        type: types.COLUMN_SAVE_SUCCESS,
        payload: {
          ...column,
          columnId,
        },
      });
      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: types.COLUMN_SAVE_FAIL, payload: error }));
};

/**
 * Edit column
 */
export const editColumn = column => dispatch => {
  dispatch({ type: types.COLUMN_EDIT_START });

  return AdminColumnService.updateColumn(column)
    .then(() => {
      dispatch({ type: types.COLUMN_EDIT_SUCCESS, payload: column });

      changeUrl('admin/urlbuilder/');
    })
    .catch(error => dispatch({ type: types.COLUMN_EDIT_FAIL, payload: error }));
};

/**
 * Delete column
 * @param columnId
 */
export const deleteColumn = columnId => dispatch => {
  dispatch({ type: types.COLUMN_DELETE_START });

  return AdminColumnService.deleteColumn(columnId)
    .then(() => {
      showSuccess({ body: i18n.t('urlBuilder:columns.api.deletionSuccess') });
      dispatch({ type: types.COLUMN_DELETE_SUCCESS, payload: columnId });
    })
    .catch(error => dispatch({ type: types.COLUMN_DELETE_FAIL, payload: error }));
};
