/*
    Table Component
    forked from:
    SmallImprovements/react-redux-composable-list
 */
import reducers, { actionCreators, selectors } from 'react-redux-composable-list';

import * as components from './components';
import * as enhancements from './enhancements';

export { components, enhancements, actionCreators, selectors };

export const DEFAULT_SIZE = 10;

export default reducers;
