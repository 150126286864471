import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { buildUrl } from '~/common';
import { Tooltip } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import Icons from '~/components/src/Icons';
import { WORKFLOW_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { createWorkflow } from '~/workflows/dataService';
import { TWorkFlowPayload } from '~/workflows/types';
import { WorklfowContext } from '~/workflows/WorklfowContext';

const generateWorkFlowPayload = (option: string): TWorkFlowPayload => {
  const name = option === WORKFLOW_TYPE.Audience ? 'New Audience' : 'New Journey';
  return {
    name,
    description: '',
    expiryTime: '30d',
    workflowType: option,
  };
};

const WorkflowCreateDialog = ({ onClose }: { onClose: () => void }) => {
  const { navigate, hasWorkflowAudienceEdit, hasWorkflowJourneyEdit } = useContext(WorklfowContext);
  const [selectedOption, setSelectedOption] = useState<string>(WORKFLOW_TYPE.Audience);

  const handleWorkflowCreate = async () => {
    const payload = generateWorkFlowPayload(selectedOption);
    const res = await createWorkflow(payload);
    navigate(buildUrl(`workflows/edit/${res}`));
  };

  const options = [WORKFLOW_TYPE.Audience, WORKFLOW_TYPE.Journey];

  useEffect(() => {
    if (!(hasWorkflowAudienceEdit && hasWorkflowJourneyEdit)) {
      const option = hasWorkflowAudienceEdit ? WORKFLOW_TYPE.Audience : WORKFLOW_TYPE.Journey;
      setSelectedOption(option);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 px-2">
      <h4>{i18n.t('workflow:create:description')}</h4>

      <div className="grid grid-cols-2 gap-2">
        {options.map(option => {
          const optionLowerCase: any = option.toLowerCase();
          const isOptionSelected = option === selectedOption;
          const hasPermission =
            (option === WORKFLOW_TYPE.Audience && !hasWorkflowAudienceEdit) ||
            (option === WORKFLOW_TYPE.Journey && !hasWorkflowJourneyEdit);
          return (
            <Tooltip
              key={`option-${option}`}
              tooltip={hasPermission ? i18n.t('workflow:create:notAllowed') : undefined}
            >
              <div
                className={twMerge(
                  cx(
                    `t-${option} relative box-border h-full flex-1 cursor-pointer rounded border border-gray-200 hover:border-blue-400`,
                    {
                      'border-blue-500 bg-blue-50': isOptionSelected,
                      'cursor-not-allowed bg-gray-100': hasPermission,
                    },
                  ),
                )}
                onClick={() => !hasPermission && setSelectedOption(option)}
              >
                <div className="flex flex-col items-center gap-2 p-4">
                  <Icons
                    icon={isOptionSelected ? 'done' : 'blank'}
                    className={twMerge(
                      cx('absolute right-1 top-1 h-4 w-4 rounded-full border p-0.5', {
                        'border-blue-500 bg-blue-500 text-white': isOptionSelected,
                      }),
                    )}
                  />
                  <div className={cx('flex items-center gap-2', 'text-blue-500')}>
                    <Icons icon={optionLowerCase} className={cx('h-8 w-8 rounded-full border p-1.5')} />
                    <p className="text-lg font-medium">{i18n.t(`workflow:create:${optionLowerCase}`)}</p>
                  </div>
                  <p className="text-center text-gray-600">{i18n.t(`workflow:create:${optionLowerCase}Desc`)}</p>
                </div>
              </div>
            </Tooltip>
          );
        })}
      </div>

      <ActionButtons
        className="!pt-4"
        onConfirm={handleWorkflowCreate}
        onDecline={onClose}
        isConfirmEnabled={!!selectedOption}
        confirmText={i18n.t('workflow:actions.next')}
        testHook="create"
        gaAction={WORKFLOW_EVENTS.CREATED}
      />
    </div>
  );
};

export default WorkflowCreateDialog;
