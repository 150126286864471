/**
 * @class webUi.service
 * @memberOf webUi
 */
angular.module('webUi.directive', [
    'webUi.directive.blockKey',
    'webUi.directive.codemirror',
    'webUi.directive.compile',
    'webUi.directive.dragToActivate',
    'webUi.directive.editable',
    'webUi.directive.highcharts',
    'webUi.directive.optionalPropertySelection',
    'webUi.directive.flag',
    'iso-3166-country-codes',
    'webUi.directive.paging',
    'webUi.directive.paginginfo',
    'webUi.directive.scopeAttribute',
    'webUi.directive.scrollUp',
    'webUi.directive.searchbox',
    'webUi.directive.searchresults',
    'webUi.directive.sorthandle',
    'webUi.directive.styledCheckbox',
    'webUi.directive.stopEvent',
    'webUi.directive.svcolorpicker',
    'webUi.directive.svfocus',
    'webUi.directive.tagList',
    'webUi.directive.support.template',
    'webUi.directive.tree',
    'webUi.directive.simpleTree',
    'webUi.directive.draggable',
    'webUi.directive.dynamicName',
    'webUi.directive.medialibraryFileChooser',
    'webUi.directive.validation',
    'webUi.directive.searchableSelect',
    'webUi.directive.clickAndWait',
    'webUi.directive.actionElement',
    'webUi.directive.wrap',
    'ui.bootstrap.datetimepicker',
    'webUi.directive.autocompleteDropdown',
    'webUi.directive.icon',
    'webUi.directive.button',
    'webUi.directive.buttonIcon',
    'webUi.directive.linkButton',
    'webUi.directive.linkIcon',
    'webUi.directive.toast',

]);

