import React from 'react';

import './styles.scss';

const SelectableListItem = ({ title, description, icon, onClick }) => (
  <div className="SelectableListItem" onClick={onClick}>
    <div>{icon}</div>
    <div className="SelectableListItem-textContainer">
      <div className="SelectableListItem-title">{title}</div>
      <div className="SelectableListItem-description">{description}</div>
    </div>
  </div>
);

export default SelectableListItem;
