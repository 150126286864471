import React from 'react';
import cx from 'classnames';
import { CARD_WIDTH } from '../constants';
import { ButtonPanelProps, TIconButtonProps, TIconProps, TTooltipForJourneyTreeProps } from './types';
import './styles.scss';

export const ViewIcon = ({ x, y, color }: TIconProps) => (
  <g fill={color} transform={`translate(${x}, ${y}) scale(1.6)`}>
    <path d="M10 13.354q1.583 0 2.688-1.104 1.104-1.104 1.104-2.688 0-1.583-1.104-2.687Q11.583 5.771 10 5.771q-1.583 0-2.688 1.104-1.104 1.104-1.104 2.687 0 1.584 1.104 2.688Q8.417 13.354 10 13.354Zm0-1.542q-.938 0-1.594-.656-.656-.656-.656-1.594 0-.937.656-1.593.656-.657 1.594-.657.938 0 1.594.657.656.656.656 1.593 0 .938-.656 1.594-.656.656-1.594.656Zm0 4.021q-2.917 0-5.312-1.541-2.396-1.542-3.605-4.146-.041-.104-.073-.261-.031-.156-.031-.323 0-.166.031-.322.032-.157.073-.261 1.209-2.604 3.615-4.125T10 3.333q2.896 0 5.302 1.521 2.406 1.521 3.615 4.125.041.104.073.261.031.156.031.322 0 .167-.031.323-.032.157-.073.261-1.209 2.604-3.605 4.146-2.395 1.541-5.312 1.541Zm0-6.271Zm0 4.521q2.333 0 4.281-1.218 1.948-1.219 2.969-3.303-1.021-2.083-2.969-3.281T10 5.083q-2.333 0-4.292 1.198Q3.75 7.479 2.729 9.562q1.021 2.084 2.979 3.303Q7.667 14.083 10 14.083Z" />
  </g>
);

export const EditIcon = ({ x, y, color }: TIconProps) => (
  <g fill={color} transform={`translate(${x}, ${y}) scale(1.6)`}>
    <path d="M4.25 15.75H5.479L12.479 8.75L11.25 7.521L4.25 14.521ZM16.188 7.542 12.458 3.812 13.479 2.792Q14 2.271 14.719 2.271Q15.438 2.271 15.958 2.792L17.208 4.042Q17.708 4.542 17.708 5.281Q17.708 6.021 17.208 6.521ZM14.958 8.771 6.229 17.5H2.5V13.771L11.229 5.042ZM11.875 8.146 11.25 7.521 12.479 8.75Z" />
  </g>
);

export const StatisticsIcon = ({ x, y, color }: TIconProps) => (
  <g fill={color} transform={`translate(${x}, ${y}) scale(1.6)`}>
    <path d="M10.75 9.25h5.708q-.25-2.271-1.843-3.865-1.594-1.593-3.865-1.843Zm-1.5 7.208V3.542q-2.458.291-4.104 2.125Q3.5 7.5 3.5 9.979q0 2.5 1.646 4.344 1.646 1.844 4.104 2.135Zm1.5 0q2.25-.27 3.854-1.875 1.604-1.604 1.854-3.833H10.75ZM10 10Zm0 8q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.125t1.719-2.542Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.708 2.552-1.084 1.094-2.542 1.719Q11.667 18 10 18Z" />
  </g>
);

export const DeleteIcon = ({ x, y, color }: TIconProps) => (
  <g fill={color} transform={`translate(${x}, ${y}) scale(1.6)`}>
    <path d="M5.896 17.5Q5.167 17.5 4.656 16.99Q4.146 16.479 4.146 15.75V5.125H3.333V3.375H7.542V2.5H12.458V3.375H16.667V5.125H15.833V15.75Q15.833 16.479 15.323 16.99Q14.812 17.5 14.083 17.5ZM14.083 5.125H5.896V15.75Q5.896 15.75 5.896 15.75Q5.896 15.75 5.896 15.75H14.083Q14.083 15.75 14.083 15.75Q14.083 15.75 14.083 15.75ZM7.458 14H9.208V6.875H7.458ZM10.771 14H12.521V6.875H10.771ZM5.896 5.125V15.75Q5.896 15.75 5.896 15.75Q5.896 15.75 5.896 15.75Q5.896 15.75 5.896 15.75Q5.896 15.75 5.896 15.75Z" />
  </g>
);

export const IconButton = ({ icon, x = 0, y = 0, onClick, testHook }: TIconButtonProps) => {
  const IconComponent = icon;
  const testHookClass = testHook ? `t-${testHook}-button` : '';

  return (
    <g
      transform={`translate(${x}, ${y})`}
      className={cx('cursor-pointer fill-gray-400 hover:fill-gray-600', testHookClass)}
      onClick={onClick}
    >
      <rect x={0} y={0} width={32} height={32} className="opacity-0" />
      <IconComponent x={6} y={7} />
    </g>
  );
};

const PanelBackground = ({ panelWidth }: { panelWidth?: number }) => (
  <rect
    className="JourneyView-nodeButtonsPanelBackground"
    x="0"
    y={0}
    width={panelWidth || CARD_WIDTH}
    height={48}
    filter="url(#jo-card-shadow)"
    strokeWidth={2}
    rx={8}
  />
);

const PanelArrow = ({ panelWidth }: { panelWidth?: number }) => {
  const WIDTH = panelWidth ? panelWidth / 2 : CARD_WIDTH / 2;
  return (
    <>
      <polyline
        className="JourneyView-nodeButtonsPanelTriangle"
        points={`${WIDTH - 8}, 1 ${WIDTH}, -8, ${WIDTH + 8}, 1`}
        strokeWidth={2}
      />
      <line className="JourneyView-nodeButtonsPanelTriangleHiddenLine" x1={WIDTH - 9} y1="1" x2={WIDTH + 9} y2="1" />
    </>
  );
};

export const ButtonsPanel = ({ x, y, scale = 1, children, panelWidth }: ButtonPanelProps) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className="JourneyView-nodeButtonsPanel">
    <PanelBackground panelWidth={panelWidth} />
    <PanelArrow panelWidth={panelWidth} />
    {children}
  </g>
);

const TooltipBackground = ({
  width,
  height,
  hasShadow = false,
}: {
  width: number;
  height: number;
  hasShadow?: boolean;
}) => (
  <rect
    className="JourneyView-tooltipBackground"
    x={0}
    y={0}
    width={width}
    height={height}
    rx="4"
    filter={hasShadow ? 'url(#jo-card-shadow)' : ''}
  />
);

const BottomTooltipArrow = ({ x = 0, y = 0 }) => (
  <>
    <polyline className="JourneyView-tooltipTriangle" points={`${x - 8},${y + 1} ${x},${y - 8}, ${x + 8},${y + 1}`} />
    <line className="JourneyView-tooltipTriangleHiddenLine" x1={x - 9} y1={1} x2={x + 9} y2={1} />
  </>
);

const TopTooltipArrow = ({ x = 0, y = 0 }) => (
  <>
    <polyline className="JourneyView-tooltipTriangle" points={`${x - 8},${y - 1} ${x},${y + 9}, ${x + 8},${y - 1}`} />
    <line className="JourneyView-tooltipTriangleHiddenLine" x1={x - 9} y1={y} x2={x + 9} y2={y} />
  </>
);

export const TooltipForJourneyTree = ({
  x = 0,
  y = 0,
  width = 200,
  height = 32,
  text,
  hasShadow,
  position = 'bottom',
}: TTooltipForJourneyTreeProps) => (
  <g transform={`translate(${x} ${y})`} className="JourneyView-tooltip">
    <TooltipBackground width={width} height={height} hasShadow={hasShadow} />
    {position === 'bottom' && <BottomTooltipArrow x={width / 2} y={0} />}
    {position === 'top' && <TopTooltipArrow x={width / 2} y={height} />}
    <foreignObject x={0} y={0} width={width} height={height}>
      <div className="JourneyView-tooltipLabel text-3xl">{text}</div>
    </foreignObject>
  </g>
);
