import seamlessImmutable from 'seamless-immutable';

const exportsInitialState = seamlessImmutable({
  isFetchingScheduledExports: false,
  scheduledExportsData: [],
  isFetchingManualExports: false,
  manualExportsData: [],
  selectedRange: '7',
  exportError: '',
});

export default exportsInitialState;
