angular.module('webUi.ui.context.basescripts.view', [])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.basescripts.view', {
                url: '/view/{basescriptId}',
                isReact: true,
            })
            .state('site.context.basescripts.add', {
                url: '/add',
                isReact: true
            });
    }]);
