angular.module('webUi.ui.test.validation.view',[
    'webUi.ui.test.validation.base',
    'ui.bootstrap'
])

    .config([ '$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.test.validation.view', {
            url : '/view/{developerId}',
            views : {
                '@site.test' : {
                    templateUrl : 'ui/test/validation/view/view.tpl.html',
                    controller : 'ValidationViewController as vm'
                }
            },
            resolve: {
                developer: ['TestService', '$stateParams', function(TestService, $stateParams) {
                    return TestService.getDeveloper($stateParams.developerId);
                }]
            },
            leftMenu : true
        });
    } ])
    .controller('ValidationViewController', ['$state','$stateParams','developer','TestService','ModalManagementService','PubSubService',
        function ValidationViewController($state, $stateParams, developer, TestService, ModalManagementService, PubSubService) {

            'use strict';
            
            var vm = this;

            vm.data = {
                developer: developer
            };

            vm.updateDeveloper = function updateDeveloper(developerId) {
                $state.transitionToStateWithSite('site.test.validation.edit', { developerId: developerId });
            };

            /**
     * Generic modal window opened
     * It takes the custom data and the onDelete specific to this entity
     * @param developer
     */
            vm.confirmDeleteDeveloper = function confirmDeleteDeveloper(developer) {
                var dialogsModel = function(){
                    return {
                        data: {
                            entityType: 'developer',
                            entityName: developer.firstName + developer.lastName,
                            entityId: developer.developerId
                        },
                        onDelete: function onDelete(developerId) {
                            TestService.deleteDeveloper(developerId).then(
                                function onSuccess() {
                                    PubSubService.publishTypeDeleted(PubSubService.TYPES.SUPPORT.TEST, {});
                                    $state.transitionToStateWithSite('site.test.validation.dashboard', {});
                                },
                                function onError() {}
                            );
                        },
                        onCancel: function onCancel() {}
                    };
                };

                var d = ModalManagementService.openDialog(
                    'DeleteDialogController',
                    'ui/globalui/deleteDialog/deleteDialog.tpl.html',
                    dialogsModel,
                    {'css': 'modal'}
                );

                return d;
            };

            vm.onDelete = function () {

            };

            PubSubService.publishTypeSelected(PubSubService.TYPES.SUPPORT.TEST, {id: $stateParams.developerId});

        }]);
