import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import constants from '../../constants';
import { TAdvertiserMapped, TPayloadType } from './types';
import { mapAdvertisers, mapPayloadTypes } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/${constants.YAHOO}`;

const fetchAdvertisers = (): Promise<TAdvertiserMapped[]> =>
  Api.callGet(`${BASE_PATH}/advertisers`).then(parseResponse).then(mapAdvertisers);

const fetchPayloadTypes = (): Promise<TPayloadType[]> =>
  Api.callGet(`${BASE_PATH}/payloadTypes`).then(parseResponse).then(mapPayloadTypes);

export { fetchAdvertisers, fetchPayloadTypes };
