import { get, isArray } from 'lodash';

/**
 * Get All Users
 * @param state
 */
export const getUsers = state => {
  const users = state.context.users.records.allIds.map(id => state.context.users.records.byId[id]);
  return users.map(user => ({
    ...user,
    clientPojoName: (user.clientPojo && user.clientPojo.name) || '-',
  }));
};

/**
 * Should fetch all users?
 * @param state
 */
export const shouldFetchUsers = state => get(state, 'context.users.records.ui.shouldFetchAll');

/**
 * Is fetching all users currently in progress?
 * @param state
 */
export const isFetchingUsers = state => get(state, 'context.users.records.ui.isFetchingAll');

/**
 * Get user by its id
 * @param state
 * @param id
 */
export const getUserById = (state, id) => get(state, `context.users.records.byId[${id}]`);

const arrayToString = array => (isArray(array) ? array.join(', ') : array);

/**
 * Get user by its id and transform subnets to strings
 * @param state
 * @param id
 * @returns {{restrictedSubnets: *, whitelistedSubnets: *}}
 */
export const getUserByIdForDetail = (state, id) => {
  const user = getUserById(state, id);
  return {
    ...user,
    restrictedSubnets: arrayToString(get(user, 'restrictedSubnets')),
    whitelistedSubnets: arrayToString(get(user, 'whitelistedSubnets')),
  };
};

/**
 * Is user already fetched?
 * @param state
 * @param userId
 */
export const shouldFetchUser = (state, userId) => !getUserById(state, userId);

/**
 * Should current password be invalidated?
 * @param state
 */
export const shouldInvalidatePassword = state => get(state, 'context.users.records.ui.invalidatePassword');

/**
 * Get current search query
 * @param state
 */
export const getFilter = state => get(state, 'context.users.records.ui.filter');
