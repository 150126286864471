import React from 'react';
import PropTypes from 'prop-types';
import Detail from '~/components/src/Form/Detail/Default';
import Details from '~/components/src/Form/Detail';
import Link from '~/components/src/Link';

export const SiteDetails = ({ site, urlBasescript, t, ts }) => {
  const isBeta = localStorage.getItem('useBeta') === 'true';

  const {
    siteModules,
    siteNumber,
    name,
    clientName,
    platformType,
    timezone,
    allowTruncate,
    advancedSecurity,
    restrictedSubnets,
    maxTags,
    maxAiModelLimit,
    maxInputsForAIModel,
    maxJourneyLimit,
    basescript,
  } = site;

  const properSiteModules = siteModules || [];

  return (
    <div>
      <Detail label={t('common.siteNumber')} value={siteNumber} />
      <Detail label={t('common.name')} value={name} />
      <Detail label={t('common.clientName')} value={clientName} />
      <Detail label={t('common.platformType.label')} value={platformType?.label} />
      <Detail label={t('common.timezone')} value={timezone} />
      <Details.Bool label={t('common.allowTruncate.label')} value={allowTruncate} translations={ts} />
      <Details.Bool label={t('common.advancedSecurity.label')} value={advancedSecurity} translations={ts} />
      <Detail label={t('common.restrictedSubnets.label')} value={restrictedSubnets} />
      <Detail label={t('common.siteModules.label')} value={properSiteModules}>
        <ul className="u-paddingLeftNone">
          {properSiteModules.map((module, i) => (
            <li key={`module_${i}`}>{module}</li>
          ))}
        </ul>
      </Detail>
      <Detail label={t('form.maxTags.label')} value={maxTags} />
      {properSiteModules.includes('AI Models') && isBeta && (
        <>
          <Detail label={t('form.maxAiModelLimit.label')} value={maxAiModelLimit} />
          <Detail label={t('form.maxInputsForAIModel.label')} value={maxInputsForAIModel} />
        </>
      )}
      {properSiteModules.includes('Orchestration') && (
        <Detail label={t('form.maxJourneyLimit.label')} value={maxJourneyLimit} />
      )}
      <Detail label={t('common.basescript.label')} value={basescript.version}>
        <Link href={urlBasescript}>{basescript.version}</Link>
      </Detail>
    </div>
  );
};

SiteDetails.propTypes = {
  site: PropTypes.object,
  ts: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  urlBasescript: PropTypes.string,
};

export default SiteDetails;
