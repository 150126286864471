const filterTreeData = (pattern, treeData) => {
  if (pattern === '') {
    return treeData;
  }

  // TODO: Simplify filtering logic, write unit tests
  // Possibly use "walk" or "find" helpers from react-sortable-tree
  // JIRA link: https://synovite.atlassian.net/browse/WUI-873
  const filterElement = (acc, next) => {
    const element = { ...next };
    if (element.isDirectory) {
      if (!pattern.length && !element.children.length) {
        acc.push(element);
      }
      element.children = element.children.reduce(filterElement, []);
      if (element.children.length) {
        element.expanded = pattern !== '' ? true : element.expanded;
        acc.push(element);
      }
      return acc;
    }

    const matches = element.searchableProperties.filter(param => param.value.toLowerCase().includes(pattern));
    if (matches.length || element.name.toLowerCase().includes(pattern)) {
      acc.push(element);
    }
    return acc;
  };

  return treeData.reduce(filterElement, []);
};

export default filterTreeData;
