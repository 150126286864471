import React from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '~/common';
import i18n from '~/i18n';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import { TYahooAdvertiser, TYahooCreatePayload } from '../types';

type TYahooForm = {
  initialValue?: TYahooAdvertiser;
  onSubmit: (payload: TYahooCreatePayload) => void;
};
const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  mdmId: Yup.number()
    .typeError(i18n.t('validation:validation.isPositiveNumber'))
    .min(1, i18n.t('validation:validation.isPositiveNumber'))
    .required(i18n.t('validation:validation.required')),
});
const YahooForm = ({ initialValue = { name: '', mdmId: '' }, onSubmit }: TYahooForm) => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValue}
      enableReinitialize={true}
    >
      {({ handleSubmit, isValid, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-2 py-4">
          <Field
            className="mb-3"
            name="name"
            as={FormikInputField}
            label={i18n.t('connectors:advertisers.yahoo.form.name')}
            placeholder={i18n.t('connectors:advertisers.yahoo.form.placeholder')}
            errorText={errors?.name}
            autoFocus={true}
          />

          <Field
            className="mb-3"
            name="mdmId"
            as={FormikInputField}
            label={i18n.t('connectors:advertisers.yahoo.form.mdmId')}
            placeholder={i18n.t('connectors:advertisers.yahoo.form.placeholder')}
            errorText={errors?.mdmId}
          />

          <ActionButtons
            testHook="yahoo"
            isConfirmEnabled={isValid && !isSubmitting}
            onConfirm={handleSubmit}
            onDecline={() => {
              navigate(buildUrl('admin/connectors?tabIndex=1'));
            }}
            confirmText={i18n.t('connectors:advertisers.form.actions.save')}
          />
        </form>
      )}
    </Formik>
  );
};

export default YahooForm;
