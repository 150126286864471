/**
 *
 */
angular.module('webUi.ui.profiles.externalFacts',
    [
        'webUi.ui.profiles.externalFacts.view',
        'webUi.ui.profiles.externalFacts.form',
        'webUi.ui.profiles.externalFacts.dashboard'
    ]
);
