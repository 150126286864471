export const TEMPLATE_FETCH_START = 'app/support/tagtemplates/templates/TEMPLATE_FETCH_START';
export const TEMPLATE_FETCH_FAIL = 'app/support/tagtemplates/templates/TEMPLATE_FETCH_FAIL';
export const TEMPLATE_FETCH_SUCCESS = 'app/support/tagtemplates/templates/TEMPLATE_FETCH_SUCCESS';

export const DELETE_START = 'app/support/tagtemplates/templates/DELETE_START';
export const DELETE_FAIL = 'app/support/tagtemplates/templates/DELETE_FAIL';
export const DELETE_SUCCESS = 'app/support/tagtemplates/templates/DELETE_SUCCESS';

export const SAVE_START = 'app/support/tagtemplates/templates/SAVE_START';
export const SAVE_FAIL = 'app/support/tagtemplates/templates/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/support/tagtemplates/templates/SAVE_SUCCESS';

export const TOGGLE_DELETED = 'app/support/tagtemplates/templates/TOGGLE_DELETED';

export const TAG_CONTENT_TYPES_FETCH_START = 'app/support/tagtemplates/templates/TAG_CONTENT_TYPES_FETCH_START';
export const TAG_CONTENT_TYPES_FETCH_SUCCESS = 'app/support/tagtemplates/templates/TAG_CONTENT_TYPES_FETCH_SUCCESS';
export const TAG_CONTENT_TYPES_FETCH_FAIL = 'app/support/tagtemplates/templates/TAG_CONTENT_TYPES_FETCH_FAIL';

export const PLATFORM_TYPES_FETCH_START = 'app/support/tagtemplates/templates/PLATFORM_TYPES_FETCH_START';
export const PLATFORM_TYPES_FETCH_SUCCESS = 'app/support/tagtemplates/templates/PLATFORM_TYPES_FETCH_SUCCESS';
export const PLATFORM_TYPES_FETCH_FAIL = 'app/support/tagtemplates/templates/PLATFORM_TYPES_FETCH_FAIL';
