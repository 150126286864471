/**
 * A service for AdServing related queries
 */
angular.module('webUi.service.content.ad', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('AdService', ['Restangular', '$q', 'Utils',
        function(Restangular,   $q,   Utils){
	
            // the base path through which all calls should go
            var BASE_PATH = 'content/';
	
            return {
		
                getAdsForCampaign: function(campaignId) {
                    var ads = $q.defer();
                    Restangular.one(BASE_PATH + 'campaigns/' + campaignId + '/ads').getList().then(function(result){
                        result = Utils.sanitizeRestangularAll(result);
                        ads.resolve(result);
                    });
                    return ads.promise;
                },
		
                getAd : function (adId){
                    var ad = $q.defer();
                    Restangular.one(BASE_PATH + 'ads', adId).get().then(function(result){
                        result = Utils.sanitizeRestangularOne(result);
                        ad.resolve(result);
                    });
                    return ad.promise;
                },
		
                addAd: function(ad) {
                    return Restangular.all(BASE_PATH + 'ads').post(ad);
                },
		
                /**
		 * Copies the ad with this id to the selected campaign
		 * @param {type} AdId - the id of the ad to copy
		 * @param {type} campaignId - the campaign to which the ad should be copied
		 * @returns {unresolved}
		 */
                copyAd: function(adId, campaignId) {
                    return Restangular.all(BASE_PATH + 'ads/copy').post({
                        oldAdId: adId,
                        newCampaignId: campaignId
                    });
                },
		
                getCreativeTypes: function() {
                    var creativeTypes = $q.defer();
                    Restangular.one(BASE_PATH + 'ads/creativeTypes').getList().then(function(result){
                        // TODO: temporary fix to get rid of VIDEO type, BE will remove it from the API
                        let filteredResult = result?.filter(creativeType => creativeType?.name !== 'VIDEO');
                        filteredResult = Utils.sanitizeRestangularAll(filteredResult);
                        creativeTypes.resolve(filteredResult);
                    });
                    return creativeTypes.promise;
                },
		
                updateAdName: function(adId, name) {
                    return Restangular.one(BASE_PATH + 'ads').post('name', {
                        adId: adId,
                        name: name
                    });
                },
		
                updateAdClickUrl: function(adId, clickUrl) {
                    return Restangular.one(BASE_PATH + 'ads').post('clickUrl', {
                        adId: adId,
                        clickUrl: clickUrl
                    });
                },
		
                updateAdDataFeed: function(adId, dataFeedId) {
                    return Restangular.one(BASE_PATH + 'ads').post('dataFeed', {
                        adId: adId,
                        dataFeedId: dataFeedId
                    });
                },
		
                updateAdProfileVariable: function(adId, profileVariableId) {
                    return Restangular.one(BASE_PATH + 'ads').post('profileVariable', {
                        adId: adId,
                        profileVariableId: profileVariableId
                    });
                },
		
                updateAdPlacementParameter: function(adId, placementParameter) {
                    return Restangular.one(BASE_PATH + 'ads').post('placementParameter', {
                        adId: adId,
                        placementParameter: placementParameter
                    });
                },
		
                updateAdMediaLibrary: function(adId, mediaLibraryId) {
                    return Restangular.one(BASE_PATH + 'ads').post('mediaLibrary', {
                        adId: adId,
                        mediaLibraryId: mediaLibraryId
                    });
                },
		
                deleteAd : function (adId){
                    return Restangular.one(BASE_PATH + 'campaigns/ads', adId).remove();
                },
		
                /**
		 * Reorder any number of ads represented as a map of adId to ordering
		 */
                reorderAds: function(ads) {
                    return Restangular.one(BASE_PATH + 'ads').post('reorder', {
                        ads: ads
                    });
                },
		
                /**
		 * @deprecated
		 * Old ad ordering change, only two at a time
		 */
                changeAdOrder: function(firstAd, secondAd) {
			
                    var ads = {};
                    ads[firstAd.adId] = secondAd.ordering;
                    ads[secondAd.adId] = firstAd.ordering;
                    return Restangular.one(BASE_PATH + 'ads').post('reorder', {
                        ads: ads
                    });
                },
		
                /**
		 * Returns a collection of ads that are linked to this media library
		 * @param {type} mediaLibraryId
		 * @returns {ad.promise}
		 */
                getAdsForMediaLibrary: function(mediaLibraryId) {
                    var ads = $q.defer();
                    Restangular.one(BASE_PATH + 'ads/medialibrary', mediaLibraryId).getList().then(function(result){
                        result = Utils.sanitizeRestangularAll(result);
                        ads.resolve(result);
                    });
                    return ads.promise;
                },

                /*
		 * Ad Conditions
		 */

                /**
		 * Adds or updates a condition to the ad with the given adId, depending on whether the condition has a conditionId or not
		 * 
		 * @param adId
		 * @param condition
		 * 
		 * @returns the promise of the post
		 */
                submitCondition: function(adId, condition) {
                    return Restangular.one(BASE_PATH + 'ads').post('condition', {
                        adId: adId,
                        condition: condition
                    });
                },
		
                removeCondition: function(adId, conditionId) {
                    return Restangular.one(BASE_PATH + 'ads', adId).one('condition', conditionId).remove();
                }
            };
        }]);
