angular.module('webUi.ui.content.campaigns.ads.view.confirmDataFeedChange', ['ui.bootstrap'])

    .controller('ConfirmDataFeedChangeController', ['$uibModalInstance',
        function ConfirmDataFeedChangeController($uibModalInstance) {
            'use strict';
            var vm = this;

            vm.confirm = function() {
                $uibModalInstance.close(true);
            };

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
