import { withState, withHandlers, compose } from 'recompose';

const enhanced = compose(
  withState('showAddNewItemRow', 'shouldShowAddNewItemRow', false),
  withHandlers({
    shouldShowAddNewItemRow: props => value => {
      props.shouldShowAddNewItemRow(value);
      return false;
    },
  }),
);

export default enhanced;
