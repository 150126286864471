import Api, { API_BASE } from '~/common/ApiService';
import { PartnerType } from '~/profiles/types';
import ProfileConnectorsService from '~/profiles/connectors/dataService';
import {
  FetchKnownValueTransformationsResponse,
  FetchKnownDestinationsResponseItem,
  TransformerPayload,
  EventPayload,
  InternalEventPropertyResponseItem,
} from './transformerTypes';

export const fetchKnownDestinations = (partnerType: PartnerType): Promise<FetchKnownDestinationsResponseItem[]> =>
  Api.callGet(`${API_BASE}/v1/eventConnectors/partnerEventProperties?partnerType=${partnerType}`).then(
    response => response.data,
  );

export const fetchKnownValueTransformations = (
  partnerType: PartnerType,
): Promise<FetchKnownValueTransformationsResponse['data']> =>
  Api.callGet(`${API_BASE}/v1/eventConnectors/valueTransformations?partnerType=${partnerType}`).then(
    response => response.data,
  );

export const saveTransformerConfiguration = (
  eventConnectorId: string,
  transformerConfigurationPayload: TransformerPayload,
) =>
  Api.callPost(
    `${API_BASE}/v1/eventConnectors/${eventConnectorId}/transformerConfiguration`,
    transformerConfigurationPayload,
  );

export const getEventConnectorTypeById = async (eventConnectorId: string) => {
  const eventConnectors = await ProfileConnectorsService.getEventConnectors();
  return eventConnectors.find(connector => connector.eventConnectorId === eventConnectorId).destinationPartnerType;
};

export const fetchTransformerEvents = (eventConnectorId: string): Promise<EventPayload[]> =>
  Api.callGet(`${API_BASE}/v1/eventConnectors/${eventConnectorId}/transformerConfiguration`).then(
    response => response?.data?.eventTransformations || [],
  );

export const fetchInternalEventProperties = (): Promise<InternalEventPropertyResponseItem[]> =>
  Api.callGet('/api/v1/eventConnectors/internalEventProperties').then(response => response.data);
