import { createContext } from 'react';
import { ExitCondition, Goal, JourneyContents, JourneyNode, TExperiment } from '~/workflows/types';

type ContextType = {
  triggerId: string | null;
  experimentSteps: TExperiment[];
  rootNodeChilds: JourneyNode[];
  goals: Goal[];
  exitConditions: ExitCondition[];
  workflowId: string;
  goToNextStep: () => void;
  refetchTree: () => void;
  validations: boolean[];
  setValidations: React.Dispatch<React.SetStateAction<boolean[]>>;
  setTree: (state: JourneyContents) => void;
  updateSearchParams: (nodeId: string) => void;
  isOptional: boolean;
  audienceName: string;
};

const defaultValues = {
  triggerId: null,
  experimentSteps: [],
  rootNodeChilds: [],
  workflowId: '',
  goals: [],
  exitConditions: [],
  goToNextStep: () => {
    //
  },
  refetchTree: () => {
    //
  },
  validations: [],
  setValidations: () => {
    //
  },
  setTree: () => {
    //
  },
  updateSearchParams: (_nodeId: string) => {
    //
  },
  isOptional: false,
  audienceName: '',
};

export const AudienceContext = createContext<ContextType>(defaultValues);
