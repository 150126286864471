import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormMeta } from 'redux-form';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import { Notification } from '~/components';
import ValueSetsForm from '../components/ValueSetsForm';
import { saveValueSet, handleSetsValidationShow, handleSetsValidationHide } from '../urlBuilder/valueSets/actions';
import { getValueSets, isSetsValidationActive } from '../urlBuilder/valueSets/selectors';

const filterValueList = valueSet => {
  if (valueSet.predefinedValuesSetValueList) {
    return {
      ...valueSet,
      predefinedValuesSetValueList: valueSet.predefinedValuesSetValueList.filter(item => item.name || item.value),
    };
  }
  return {
    ...valueSet,
    predefinedValuesSetValueList: [],
  };
};

export class NewValueSets extends Component {
  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('valueSets.formNewValueSet.header')} />
          <Panel>
            <Notification kind="default" className="mb-6">
              {this.props.t('valueSets.formNewValueSet.intro')}
            </Notification>
            <ValueSetsForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      fieldsMeta: getFormMeta('valueSetsNew')(state),
      valueSets: getValueSets(state),
      validateSets: isSetsValidationActive(state),
    }),
    dispatch => ({
      onSubmit: valueSet => dispatch(saveValueSet(filterValueList(valueSet))),
      handleSetsValidationShow: () => dispatch(handleSetsValidationShow()),
      handleSetsValidationHide: () => dispatch(handleSetsValidationHide()),
    }),
  ),
  reduxForm({
    form: 'valueSetsNew',
  }),
  translate(['urlBuilder']),
)(NewValueSets);
