import React, { useState } from 'react';
import cx from 'classnames';
import { Field, FormikProps } from 'formik';
import { components } from 'react-select';
import * as Yup from 'yup';
import { useAPI, useApiWithState } from '~/common/ApiHooks';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import makeAnimated from 'react-select/animated';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import CreateAudience from './CreateAudience';
import { fetchAllAudiences, getConnectorDetails } from './dataService';

const validations = Yup.object().shape({
  audienceId: Yup.number().required(i18n.t('validation:validation.required')),
  audienceName: Yup.string(),
});

type LinkedInProps = {
  onEdit: () => void;
  isSubmitted: boolean;
  connector: {
    configuration: any;
    partnerDetails: {
      partnerId: string;
      name: string;
    };
  };
};

const animatedComponents = makeAnimated();

const Option = (props: any) => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <div className="flex w-full flex-col items-center gap-2">
        <div className="flex w-full items-center justify-between">
          <span>{props.data.name}</span>
          <span
            className={cx('text-amber-500', {
              'text-green-500': props.data.status === 'READY',
              'text-red-600': ['FAILED', 'EXPIRED'].includes(props.data.status),
            })}
          >
            {props.data.status}
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm text-gray-500">Size: {props.data.audienceSize || 0}</span>
          <span className="text-sm text-gray-500">Match Count: {props.data.matchedCount || 0}</span>
        </div>
      </div>
    </components.Option>
  </div>
);

const LinkedIn = ({ onEdit, connector, isSubmitted }: LinkedInProps) => {
  const { partnerId } = connector.partnerDetails;
  const [showCreateAudienceModal, setShowCreateAudienceModal] = useState(false);

  const { data: connectorDetails, isLoading: isConfigLoading } = useAPI(() => getConnectorDetails(partnerId));
  const {
    state: audiences,
    isLoading,
    refetch: refetchAudiences,
  } = useApiWithState(() => fetchAllAudiences(partnerId));

  if (isLoading || isConfigLoading)
    return (
      <div className="mx-16 my-6">
        <Spin />
      </div>
    );

  const payLoadType = connectorDetails?.configuration?.payloadType || 'N/A';

  return (
    <ConnectorGenericForm onEdit={onEdit} validations={validations} connector={connector} isSubmitted={isSubmitted}>
      {(formikProps: FormikProps<{ audienceId: string }>) => {
        const { setFieldValue, errors } = formikProps;

        return (
          <>
            <div className="flex w-2/3 items-center gap-2">
              <Field
                className="mb-3 w-full"
                name="audienceId"
                label={i18n.t('audiences:connectors.linkedin.audience')}
                placeholder={i18n.t('audiences:connectors.linkedin.placeholder')}
                as={FormikSelectField}
                getOptionLabel={(option: { name: string; audienceId: number }) => option.name}
                getOptionValue={(option: { name: string; audienceId: number }) => option.audienceId}
                customSetFieldValue={(name: string, option: { name: string; audienceId: string }) => {
                  setFieldValue(name, option.audienceId);
                  setFieldValue('audienceName', option.name);
                  setTimeout(() => formikProps.validateField('audienceId')); // Fix validation
                }}
                options={audiences || []}
                components={{
                  Option,
                  animatedComponents,
                }}
                errorText={errors?.audienceId}
              />
              <BtnIcon
                testHook="createAudience"
                icon="add"
                className="rounded-full"
                disabled={false}
                tooltip={i18n.t('audiences:connectors.linkedin.tooltips.create')}
                onClick={() => setShowCreateAudienceModal(true)}
              />
            </div>

            {showCreateAudienceModal && (
              <PickerView
                pickerTitle={i18n.t('audiences:connectors.linkedin.create.title')}
                className="!h-auto !w-1/2 lg:!w-1/3 xl:!w-1/4"
              >
                <CreateAudience
                  partnerId={partnerId}
                  payLoadType={payLoadType}
                  hideModal={() => setShowCreateAudienceModal(false)}
                  refetchAudiences={refetchAudiences}
                />
              </PickerView>
            )}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default LinkedIn;
