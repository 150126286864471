/**
 *
 * @description Angular module for Export Data -> Base
 * @class ExportBaseController
 * @memberOf webUi
 * @namespace webUi.ui.profiles.export
 */
angular.module('webUi.ui.profiles.export.base', [
    'webUi.ui.profiles.base'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.export', {
                url: '/export',
                isReact: true,
            });
    }]);
