import React from 'react';
import { Field } from 'redux-form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import { isRequired, defaultMaxInput } from '~/common';

export const TagGroupForm = ({ handleSubmit, submitting, backHref, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        label={t('groupForm.name.label')}
        name="name"
        component={ReduxFormInputField}
        placeholder={t('groupForm.name.placeholder')}
        validate={[isRequired, defaultMaxInput]}
        type="text"
      />
    </div>
    <FormActions t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

export default TagGroupForm;
