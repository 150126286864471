import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { withSort } from '~/components/src/Table/enhancements';
import { Panel } from '~/components/src/Containers';
import { components } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import './CustomerDataMappings.scss';

const { Table, Row, Cell } = components;

const WIDTHS = {
  partnerType: '30%',
  partnerMappings: '70%',
};

const IdentifiersTable = ({ list, t, stateKey }) => (
  <Panel>
    <Table stateKey={stateKey}>
      <Row>
        <SortableHeader width={WIDTHS.partnerType} sortKey="partnerType" title={t('identifiers.partnerNumber')} />
        <SortableHeader
          width={WIDTHS.partnerMappings}
          sortKey="partnerMappings"
          title={t('identifiers.partnerMappings')}
        />
      </Row>
      {list.map(({ key, value }) => (
        <Row key={key}>
          <Cell style={{ width: WIDTHS.partnerType }}>{key}</Cell>
          <Cell style={{ width: WIDTHS.partnerMappings }}>
            <div className="CustomerDataMappings-cellContent">{value}</div>
          </Cell>
        </Row>
      ))}
    </Table>
  </Panel>
);

IdentifiersTable.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string.isRequired,
};

IdentifiersTable.defaultProps = {
  list: [],
};

export default compose(withSort(), translate('customerData'))(IdentifiersTable);
