angular
    .module('webUi.ui.admin.urlBuilderTypeAdd', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.urlBuilderTypeAdd', {
                url: '/urlbuilder/type/add',
                isReact: true,
            });
        },
    ]);
