import React from 'react';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import UrlBuilderContainer from '../urlBuilder/containers/UrlBuilderContainer';

export const UrlBuilderPage = ({ t, isSiteAdmin }) => (
  <Page>
    <Heading title={t('urlBuilder:view.header')} />
    <UrlBuilderContainer isSiteAdmin={isSiteAdmin} />
  </Page>
);

export default translate('urlBuilder')(UrlBuilderPage);
