import React from 'react';
import cx from 'classnames';
import ActionButtons from '~/components/src/ActionButtons';
import ModalWindow, { ModalWindowProps } from './ModalWindow';

interface DeleteModalProps extends Omit<ModalWindowProps, 'size'> {
  onDelete: () => void;
  onDecline?: () => void;
}

export const DeleteModal = ({
  onDelete,
  onDecline,
  title,
  closeModal,
  className,
  children,
}: DeleteModalProps): JSX.Element => (
  <ModalWindow size="small" title={title} closeModal={closeModal} className={cx('p-0', className)}>
    {children}
    <ActionButtons onDecline={onDecline || closeModal} onDelete={onDelete} testHook="deleteModal" />
  </ModalWindow>
);

export default DeleteModal;
