import React, { useContext, useState } from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { JourneyContext } from '../JourneyContext';
import ItemStats from './ItemStats';
import { JourneyItem } from './JourneyItem';
import { ButtonType, ItemVariantStats } from './types';
import './styles.scss';

type GoalItemProps = {
  className?: string;
  name: string;
  reached: number;
  id: string;
  variantStats: ItemVariantStats[];
  canEdit: boolean;
  isDemoMode: boolean;
  setNodeToDelete: (node: any) => void;
};

const GoalItem = ({
  className,
  name,
  reached,
  id,
  variantStats = [],
  canEdit,
  isDemoMode = false,
  setNodeToDelete,
}: GoalItemProps): React.ReactElement => {
  const { actions } = useContext(JourneyContext);
  const { goToGoalStatisticsScreen, goToViewGoalScreen, goToEditGoalScreen } = actions;

  const [isExpanded, setIsExpanded] = useState(false);

  let buttons: ButtonType[] = [
    {
      icon: 'view',
      testHook: 'view-goal-button',
      onClick: () => goToViewGoalScreen(id),
    },
    {
      icon: 'statistics',
      testHook: 'statistic-goal-button',
      onClick: () => goToGoalStatisticsScreen(id),
    },
  ];

  if (canEdit) {
    buttons.push(
      {
        icon: 'edit',
        testHook: 'edit-goal-button',
        onClick: () => {
          goToEditGoalScreen(id);
        },
      },
      {
        icon: 'delete',
        testHook: 'delete-trigger-button',
        onClick: () => {
          setNodeToDelete({ nodeId: id, label: name, type: NodeTypes.GOAL });
        },
      },
    );
  }

  if (isDemoMode) buttons = buttons.filter(btn => btn.icon === 'statistics');

  let isShowVariantStats = variantStats.length > 0;

  if (variantStats.length === 1 && variantStats[0].profilesReached === reached) {
    isShowVariantStats = false;
  }

  return (
    <JourneyItem
      name={name}
      className={cx('t-journeyGoal', className)}
      type={NodeTypes.GOAL}
      onClick={() => setIsExpanded(!isExpanded)}
      isShowVariantStats={isShowVariantStats}
      isExpanded={isExpanded}
      buttons={
        <>
          {buttons.map((buttonConfig, buttonIndex) => (
            <BtnIcon
              className="h-5 w-5 bg-transparent p-0 text-gray-400 hover:bg-transparent hover:text-gray-600"
              icon={buttonConfig.icon}
              onClick={buttonConfig.onClick}
              key={buttonIndex}
              testHook={buttonConfig.testHook}
            />
          ))}
        </>
      }
      footer={
        <div className="flex flex-col gap-1 rounded-b-xl bg-gray-50 p-1">
          <ItemStats
            testHook="goalStats"
            label={i18n.t('workflow:journey.total')}
            reached={reached}
            type={NodeTypes.GOAL}
          />
          {isShowVariantStats && isExpanded && (
            <div className="flex flex-col gap-1">
              {variantStats.map((variant, index) => (
                <ItemStats
                  key={index}
                  testHook="variantStats"
                  label={variant.name}
                  reached={variant.profilesReached}
                  conversion={variant.conversionRate}
                  hasConversion
                  type={NodeTypes.GOAL}
                />
              ))}
            </div>
          )}
        </div>
      }
    />
  );
};

export default GoalItem;
