import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { createColumnHelper } from '@tanstack/react-table';
import { changeUrl } from '~/common';
import { Link, Notification, PickerView } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import Btn from '~/components/src/Btn';
import BtnOutlined from '~/components/src/BtnOutlined';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import { TAG_EVENTS } from '~/gaActions';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { deployChanges, publishChanges } from '../dataService';
import { TChangeElementMapped, TEnvironment, UnpublishedTableProps } from '../types';

const columnHelper = createColumnHelper<TChangeElementMapped>();

export const UnpublishedTable = ({ latestChanges, environments, refetch }: UnpublishedTableProps) => {
  const [filteredChanges, setFilteredChanges] = useState(latestChanges);
  const [showConfirmationModal, setShowConfirmationModal] = useState<null | any>(null);
  const [isPublishing, setIsPublishing] = useState(false);

  useEffect(() => {
    setFilteredChanges(latestChanges);
  }, [latestChanges]);

  if (!latestChanges.length)
    return (
      <Notification className="my-2" kind="information">
        {i18n.t('publishing:messages.noUnpublishedChanges')}
      </Notification>
    );

  const initialValues = {
    description: '',
    environment: environments?.[0] || {},
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required(i18n.t('validation:validation.required')),
    environment: Yup.object(),
  });

  const validateChanges = (values: { description: string; environment: TEnvironment }) => {
    const selectedChanges = filteredChanges.filter(change => change.acceptChange);
    if (!selectedChanges.length) return;

    const elements = selectedChanges.map(change => change.elementId);
    setShowConfirmationModal({ ...values, elements });
  };

  const handleDeployPublish = async (values: {
    description: string;
    environment: TEnvironment;
    elements: string[];
  }) => {
    setIsPublishing(true);
    const { description, environment, elements } = values;

    const configurationSnapshotId = await publishChanges({ description, elements });
    await deployChanges({ configurationSnapshotId, environmentId: environment.environmentId });
    setIsPublishing(false);
    showSuccess({ header: i18n.t('publishing:notifications.publishDeploySuccess') });
    refetch();
  };

  const updateSelected = (row: TChangeElementMapped) => {
    setFilteredChanges(
      filteredChanges.map(change => {
        if (change.elementId === row.elementId) return { ...change, acceptChange: !change.acceptChange };
        return change;
      }),
    );
  };

  const toggleAll = () => {
    const state = filteredChanges.some(change => change.acceptChange === false);
    setFilteredChanges(filteredChanges.map(change => ({ ...change, acceptChange: !!state })));
  };

  const getColumns = [
    columnHelper.accessor('acceptChange', {
      header: () => <div className="text-center">{i18n.t('publishing:latestChanges.select')}</div>,
      cell: info => (
        <div className="flex justify-center">
          <CheckboxElement
            id="acceptChangeField"
            className="t-acceptChangeField"
            value={!!info.getValue()}
            onChange={() => updateSelected(info.row.original)}
          />
        </div>
      ),
      size: 50,
    }),
    columnHelper.accessor('elementName', {
      header: () => <span>{i18n.t('publishing:latestChanges.type')}</span>,
      cell: info => (
        <div className="flex items-center gap-2">
          <Icons className="text-r42-blue h-5 w-5" icon={info.row.original.icon} />
          <Link onClick={() => changeUrl(info.row.original.elementLink)} testHook="elementName">
            {info.getValue()}
          </Link>
        </div>
      ),
      size: 250,
    }),
    columnHelper.accessor('dependencies', {
      header: () => <span>{i18n.t('publishing:latestChanges.dependsOn')}</span>,
      cell: info => (
        <span>
          {info.getValue()?.map((dependency: any, index: number) => (
            <span key={`dependency-${index}`} className="flex items-center gap-1">
              <Icons className="h-5 w-5" icon={dependency?.icon} />
              {dependency?.elementName}
            </span>
          ))}
        </span>
      ),
      size: 200,
    }),
    columnHelper.accessor('modifiedBy', {
      header: () => <span>{i18n.t('publishing:latestChanges.modifiedBy')}</span>,
      cell: info => <span className="t-modifiedBy">{info.getValue()}</span>,
      size: 200,
    }),
    columnHelper.accessor('changeType', {
      header: () => <span>{i18n.t('publishing:latestChanges.change')}</span>,
      cell: info => <span className="t-changeType">{info.getValue()}</span>,
      size: 100,
    }),
  ];

  return (
    <div className="flex flex-col gap-4 border-b pb-4">
      <UITable testHook="unPublished" data={filteredChanges} columns={getColumns} />
      <div className="flex items-center gap-4">
        <BtnOutlined onClick={() => toggleAll()} testHook="toggleAll">
          {i18n.t('publishing:latestChanges.toggleAll')}
        </BtnOutlined>
        {!filteredChanges.some(change => change.acceptChange === true) && (
          <span className="t-selectionError text-red-500">{i18n.t('publishing:messages.selectOne')}</span>
        )}
      </div>

      <Formik
        onSubmit={validateChanges}
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnMount
      >
        {({ errors, isValid, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="my-2 !flex items-baseline gap-4">
            <span>{i18n.t('publishing:latestChanges.form.description')}</span>
            <Field name="description" className="mb-2 w-1/4" as={FormikInputField} errorText={errors.description} />
            <span>{i18n.t('publishing:latestChanges.form.environment')}</span>
            <Field
              name="environment"
              className="w-1/5"
              as={FormikSelectField}
              errorText={errors.environment}
              getOptionLabel={(option: TEnvironment) => option.environmentName}
              getOptionValue={(option: TEnvironment) => option.environmentId}
              customSetFieldValue={(name: string, option: TEnvironment) => setFieldValue(name, option)}
              options={environments || []}
            />
            <Btn
              color="blue"
              onClick={() => handleSubmit()}
              testHook="publishAndDeploy"
              disabled={!isValid}
              gaAction={TAG_EVENTS.PUBLISHED}
            >
              {i18n.t('publishing:latestChanges.form.action')}
            </Btn>
          </Form>
        )}
      </Formik>
      {showConfirmationModal && (
        <PickerView
          handlePickerVisibility={() => setShowConfirmationModal(null)}
          pickerTitle={i18n.t('publishing:latestChanges.publishTitle')}
          className="!h-auto !w-1/3"
        >
          <div>
            <p className="t-confirmText">
              {i18n.t('publishing:messages.publishChanges', {
                count: showConfirmationModal.elements.length,
                env: showConfirmationModal.environment.environmentName,
              })}
            </p>
            <ActionButtons
              testHook="publishConfig"
              onDecline={() => setShowConfirmationModal(null)}
              onConfirm={() => handleDeployPublish(showConfirmationModal)}
              confirmText={i18n.t('publishing:latestChanges.publishAction')}
              isConfirmEnabled={!isPublishing}
            />
          </div>
        </PickerView>
      )}
    </div>
  );
};
