/**
 * @description Angular main module for Profiles -> Engagements
 * @memberOf webUi
 * @namespace webUi.ui.profiles.engagements
 */
angular.module('webUi.ui.profiles.filters',
    [
        'webUi.ui.profiles.filters.dashboard',
        'webUi.ui.profiles.filters.form',
        'webUi.ui.profiles.filters.view'
    ]
);