angular.module('webUi.ui.tagmanagement.tags.websitePathGroups.path.deleteWebsitePathGroup', ['webUi.service'])

    .controller('WebsitePathGroupDeleteController', ['$scope', 'dialogsModel', '$uibModalInstance',

        function WebsitePathGroupDeleteController($scope, dialogsModel, $uibModalInstance) {

            $scope.websitePathGroup = dialogsModel.websitePathGroup;

            $scope.confirm = function(){
                $uibModalInstance.close(true);
            };
            $scope.close = function() {
                $uibModalInstance.close(false);
            };

        }]);
