/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

/**
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ContextUserSitesClass {
  getUserSites(userId) {
    return Api.callGet(`${API_BASE}/security/userContext/${userId}`).then(
      response =>
        response.data &&
        response.data.sites &&
        Object.keys(response.data.sites)
          // Fix missing site module from backend endpoint
          // Change to .map(i => response.data.sites[i]) once backend is fixed
          .map(i => ({
            ...response.data.sites[i],
            siteModules: [...response.data.sites[i].siteModules, 'GENERAL'],
          }))
          // Remove Relay42 demo from the list
          .filter(site => site.siteNumber !== 42),
    );
  }

  linkUserToSite(model) {
    return Api.callPost(`${API_BASE}/context/users/linkUserToSite`, model).then(response => response.data);
  }
}

const contextUserSitesService = new ContextUserSitesClass();
export default contextUserSitesService;
