angular.module('webUi.ui.tagmanagement.beacons.view', [
    'webUi.ui.tagmanagement.beacons.base',
])
/**
 *
 */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.beacons.view', {
                url: '/{beaconId}',
                isReact: true
            });
    }]);
