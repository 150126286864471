angular.module('webUi.ui.globalui.errorPopup', ['ui.bootstrap'])

    .controller('ErrorPopupController', ['Restangular', '$uibModalInstance', '$location', 'dialogsModel',

        function ErrorPopupController(Restangular,   $uibModalInstance,   $location,   dialogsModel) {

            'use strict';
            
            var vm = this;

            vm.data = {
                knownError: dialogsModel.error.knownError,
                submittedError: dialogsModel.error.submitted,
                supportData: dialogsModel.supportData,
                thankYou: false,
                submitted: false
            };
            vm.submitRequest = function(message) {
                if ( _.isEmpty(message) ){
                    return;
                }
                var supportRequest = {
                    message : message,
                    url : $location.absUrl(),
                    identifier : dialogsModel.error.identifier,
                    sentryEventId: dialogsModel.error.sentryEventId
                };

                Restangular.all('errorReportContact').post(supportRequest).then(function(result) {
                    vm.data.submitted = true;
                    vm.data.thankYou = result;
                });
            };

            vm.closeThankYou = function(){
                $uibModalInstance.close(true);
            };

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
