import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Code, { mode } from '../../Code';

export { mode };

const CodeElement = ({
  disabled,
  mode,
  options,
  name,
  onChange,
  onBlur,
  onFocus,
  isError,
  testHook = name,
  ...props
}) => (
  <Code
    id={name}
    className={cx(`Element Element--code t-${testHook}`, { 'is-error': isError })}
    options={{
      ...options,
      readOnly: disabled,
    }}
    mode={mode}
    autoCursor={false}
    onChange={(editor, data, value) => onChange(value)}
    onBlur={() => onBlur()}
    onFocus={() => onFocus()}
    {...props}
  />
);

CodeElement.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  mode: PropTypes.oneOf(['css', 'xml', 'javascript']),
  options: PropTypes.any,
  isError: PropTypes.bool,
};

export default CodeElement;
