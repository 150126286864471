import { changeUrl } from '~/common';
import * as types from './types';
import SiteInfoService from './dataService.js';
import * as selectors from './selectors';

/**
 * Fetch basic site information
 * @param siteNumber
 */
export const fetchSiteInfo = siteNumber => dispatch => {
  dispatch({
    type: types.INFO_FETCH_START,
  });

  return SiteInfoService.getSiteInfo(siteNumber)
    .then(payload =>
      dispatch({
        type: types.INFO_FETCH_SUCCESS,
        payload,
      }),
    )
    .catch(error => dispatch({ type: types.INFO_FETCH_FAIL, error }));
};

/**
 * Fetch basic site information only if not in store
 * @param siteNumber
 */
export const fetchSiteInfoIfNeeded = siteNumber => (dispatch, getState) => {
  const site = selectors.getSiteInfo(getState());
  if (!site.siteNumber || site.siteNumber !== siteNumber) {
    return dispatch(fetchSiteInfo(siteNumber));
  }
  return Promise.resolve();
};

/**
 * Update site details
 * (currently only restrictedSubnets is supported)
 * @param site
 */
export const updateSite = site => dispatch => {
  const model = {
    siteId: site.siteId,
    restrictedSubnets: (site.restrictedSubnets && site.restrictedSubnets.replace(/\s+/g, '').split(',')) || [],
  };

  dispatch({
    type: types.UPDATE_SITE_START,
  });

  return SiteInfoService.updateSite(model)
    .then(() => {
      dispatch({
        type: types.UPDATE_SITE_SUCCESS,
        payload: model,
      });
      // Go to users view
      changeUrl('admin/sitesecurity');
    })
    .catch(error => dispatch({ type: types.UPDATE_SITE_FAIL, error }));
};
