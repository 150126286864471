(function() {

    UsageOverviewController.$inject = ['DependencyService', 'USAGE_OVERVIEW_CONSTANTS'];

    function UsageOverviewController(DependencyService, USAGE_OVERVIEW_CONSTANTS) {
        var vm = this;

        vm.$onInit = function $onInit() {

            // Methods
            vm.displayProperty = displayProperty;
            vm.selectTab = selectTab;

            // Data
            vm.selectedTab = 1;
            vm.dependencies = [];
            vm.items = [];
            vm.overviewTypes = DependencyService.getOverviewTypes(vm.sourceModule);
            vm.overviewAlertMessages = USAGE_OVERVIEW_CONSTANTS.overviewAlertMessages;
        };

        vm.$onChanges = function $onChanges() {
            getUsedProperties(vm.entityId, vm.entityName, vm.isGettingProps);
        };

        function selectTab(tab) {
            vm.selectedTab = tab;
        }

        function getUsedProperties(entityId, entityName, isGettingProps) {
            DependencyService.getAllDependencies(entityId, entityName, isGettingProps).then(function(result) {
                vm.dependencies = result;
                vm.items = transformDependencies(vm.overviewTypes, result);
            });
        }

        function transformDependencies(overviewTypes, dependencies) {
            return _.map(overviewTypes, function(overviewType, index) {
                return {
                    id: index + 1,
                    title: overviewType.name,
                    propertyKeys: overviewType.propertyKeys,
                    properties: dependencies[overviewType.key][overviewType.subKey],
                    hasData: dependencies[overviewType.key].hasData,
                    userHasAccessToData: dependencies[overviewType.key].userHasAccessToData
                };
            });
        }

        /**
         * Make sure the property has a key and is wrapped in array
         */
        function displayProperty(property, key) {
            return wrapInArray(property[key] ? property[key] : property);
        }

        function wrapInArray(item) {
            return !(item instanceof Array) ? [item] : item;
        }

    }

    angular.module('webUi.component.profiles.shared.usageOverview', [
        'webUi.service.profilesConstants'
    ])

        .component('usageOverview', {
            controller: UsageOverviewController,
            controllerAs: 'vm',
            templateUrl: 'component/profiles/shared/usageOverview/usageOverview.tpl.html',
            bindings: {
                entityId: '<',
                entityName: '<',
                entityTitle: '<',
                entityType: '<',
                sourceModule: '<',
                isGettingProps: '<',
                onCancel: '&'
            }
        });

})();
