import React, { useState } from 'react';
import BtnOutlined from '../BtnOutlined';
import PickerView from '../PickerView';
import { SetIsPickerVisibleContext } from './context';

const RuleConnectorPicker = props => {
  const { actionName, children } = props;
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  return (
    <div>
      <BtnOutlined size="xs" color="blue" testHook="ruleConnectorPickerButton" onClick={() => setIsPickerVisible(true)}>
        {actionName}
      </BtnOutlined>
      {isPickerVisible && (
        <PickerView handlePickerVisibility={setIsPickerVisible} pickerTitle={actionName}>
          <SetIsPickerVisibleContext.Provider value={setIsPickerVisible}>{children}</SetIsPickerVisibleContext.Provider>
        </PickerView>
      )}
    </div>
  );
};

export default RuleConnectorPicker;
