import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { buildUrl, useApiWithState } from '~/common';
import Icons from '~/components/src/Icons';
import { Heading, Notification, Page, PickerView } from '~/components';
import Spin from '~/components/src/Spin';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import { UITable } from '~/components/src/UITable';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import { createTagCounter, fetchTagCounters } from './dataService';
import { TTagCounter, TTagCounterListProps, TTagCountersForm } from './types';
import TagCounterForm from './components/TagCounterForm';
import TagCounterActions from './components/TagCounterActions';

const columnHelper = createColumnHelper<TTagCounter>();

const TagCounterList = ({ hasTagEdit }: TTagCounterListProps) => {
  const navigate = useNavigate();
  const [selectedCounters, setSelectedCounters] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [showForm, setShowForm] = useState(false);

  const { state: tagCountersData, isLoading, refetch: refetchTagCounters } = useApiWithState(() => fetchTagCounters());

  const toggleSelectedCounters = (element: string) => {
    setSelectedCounters(prevSelectedCounters =>
      prevSelectedCounters.includes(element)
        ? prevSelectedCounters.filter(e => e !== element)
        : [...prevSelectedCounters, element],
    );
  };

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('tagCounters:table.header.name')}</div>,
      cell: info => {
        const counterId = info?.row?.original?.counterId;
        return (
          <div className="flex flex-row items-center text-left">
            <CheckboxElement
              id={`checkbox-${counterId}`}
              className="mr-3"
              value={selectedCounters?.includes(counterId)}
              onChange={() => {
                toggleSelectedCounters(counterId);
              }}
            />
            {info.getValue()}
          </div>
        );
      },
      size: 600,
    }),
    columnHelper.accessor('number', {
      header: () => <div className="text-left">{i18n.t('tagCounters:table.header.counterNumber')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 300,
    }),

    columnHelper.accessor('description', {
      header: () => <div className="text-left">{i18n.t('tagCounters:table.header.description')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 600,
    }),
    columnHelper.accessor('shownOnDashboard', {
      header: () => <div className="text-center">{i18n.t('tagCounters:table.header.onDashboard')}</div>,
      cell: info => (
        <div className="text-center">
          {info.getValue() ? (
            <Icons icon="done" className="h-6 w-6 text-green-600" />
          ) : (
            <Icons icon="minus" className="h-6 w-6 text-gray-200" />
          )}
        </div>
      ),
      size: 200,
    }),
    columnHelper.accessor('actions', {
      header: () => <div className="text-center">{i18n.t('tagCounters:table.header.actions')}</div>,
      cell: info => (
        <TagCounterActions
          tagCounter={info?.row?.original}
          refetchTagCounters={refetchTagCounters}
          hasTagEdit={hasTagEdit}
        />
      ),
      size: 200,
    }),
  ];

  const handleCreate = async (values: TTagCountersForm) => {
    await createTagCounter(values);
    showSuccess({ body: i18n.t('tagCounters:form.api.created') });
    refetchTagCounters();
    setShowForm(false);
  };

  if (isLoading) return <Spin />;
  return (
    <>
      <Page>
        <Heading title={i18n.t('tagCounters:title')}>
          <SearchElement
            value={query}
            onChange={e => setQuery(e.target.value.toLowerCase())}
            placeholder={i18n.t('common:actions.search')}
          />
          <BtnIcon
            tooltip={i18n.t('tagCounters:statsBtn')}
            icon="statistics"
            testHook="statsBtn"
            disabled={selectedCounters?.length === 0}
            onClick={() => {
              navigate(buildUrl(`tagmanagement/tagCounters/stats/${selectedCounters.join(',')}`));
            }}
          />

          <BtnIcon
            tooltip={hasTagEdit ? i18n.t('tagCounters:addNew') : i18n.t('tagCounters:actions.noPermission')}
            icon="add"
            color="blue"
            disabled={!hasTagEdit}
            testHook="addCounter"
            onClick={() => {
              setShowForm(true);
            }}
          />
        </Heading>
        <Notification kind="information" testHook="tagCounterInfoBox">
          <p>{i18n.t('tagCounters:info')}</p>
        </Notification>

        <UITable
          enableSorting
          data={tagCountersData ?? []}
          stickyHeader={false}
          testHook="tagCountersTable"
          emptyMessage={i18n.t('tagCounters:empty.header')}
          emptyBody={query.length === 0 ? i18n.t('tagCounters:empty.body') : ''}
          columns={getColumns()}
          searchQuery={query}
          enableFilters
        />
      </Page>

      {showForm && (
        <PickerView
          pickerTitle={i18n.t('tagCounters:form.create')}
          handlePickerVisibility={setShowForm}
          className="!h-auto !w-1/3"
        >
          <TagCounterForm onSubmit={handleCreate} setShowForm={setShowForm} />
        </PickerView>
      )}
    </>
  );
};

export default TagCounterList;
