import React from 'react';
import cx from 'classnames';
import Detail from '~/components/src/Form/Detail/Default';
import { Panel } from '~/components/src/Containers';

const AudienceDetails = ({ details = [], className }) => (
  <div className={cx('u-paddingTopXl', className)}>
    <Panel>
      {details.map(({ label, value }, index) => (
        <div key={index} className="AudienceDetails-detailWrapper u-marginTopXs">
          <Detail label={label} value={value} />
        </div>
      ))}
    </Panel>
  </div>
);

export default AudienceDetails;
