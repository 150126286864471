/**
 * A service for AdServing related queries
 */
angular.module('webUi.service.content.campaign', [
    'restangular',
    'webUi.common.Utils',
    'webUi.service.security'
])

    .factory('CampaignService', ['Restangular', '$q', 'Utils',

        function(Restangular, $q, Utils){

            // the base path through which all calls should go
            var BASE_PATH = 'content/';

            return {
                getCampaigns: function getCampaigns() {
                    var campaignsPromise = $q.defer();

                    Restangular.all(BASE_PATH + 'campaigns').getList().then(
                        function onSuccess(response) {
                            if (response) {
                                campaignsPromise.resolve(Restangular.stripRestangular(response));
                            }
                        },
                        function onError() {}
                    );
                    return campaignsPromise.promise;

                },

                getCampaign : function (campaignId) {
                    var sortAds = function(ads) {
                        return _.sortBy(ads, 'ordering');
                    };

                    var campaign = $q.defer();
                    Restangular.one(BASE_PATH + 'campaigns', campaignId).get().then(function(result){
                        result = Utils.sanitizeRestangularOne(result);

                        // Sort the campaign ads
                        result.ads = sortAds(result.ads);
                        campaign.resolve(result);
                    });
                    return campaign.promise;
                },

                saveCampaign: function(campaign) {
                    return Restangular.all(BASE_PATH + 'campaigns').post(campaign);
                },

                updateCampaignName: function(campaignId, name) {
                    return Restangular.one(BASE_PATH + 'campaigns/').post('name', {
                        campaignId: campaignId,
                        name: name
                    });
                },

                addPlacementParameter: function(campaignId, placementParameter) {
                    return Restangular.one(BASE_PATH + 'campaigns/').post('addPlacementParameter', {
                        campaignId: campaignId,
                        placementParameter: placementParameter
                    });
                },

                removePlacementParameter: function(campaignId, paramName) {
                    return Restangular.one(BASE_PATH + 'campaigns/').post('removePlacementParameter', {
                        campaignId: campaignId,
                        name: paramName
                    });
                },

                deleteCampaign : function (campaignId){
                    return Restangular.one(BASE_PATH + 'campaigns', campaignId).remove();
                },

                publishCampaign : function(campaignId){
                    return Restangular.one(BASE_PATH + 'publishing').post('',{
                        campaignId: campaignId
                    });
                }
            };
        }
    ]);
