angular.module('webUi.directive.editable.default', ['ui.validate'])

    .controller('DefaultEditingController', ['$scope', 'ValidService', function DefaultEditingController($scope, ValidService) {

        var vars = $scope.localVars || {};

        $scope.$watch('customValidation', function(newValue) {
            if(!ValidService.isEmpty(newValue)) {
                ValidService.setValidationTokens(newValue, $scope.editableForm.value, $scope.editableObject.value);
            }
        });

        /**
     * Process the input before saving, also returning the validity of the form
     * @returns {boolean} whether the save is allowed to proceed or not
     */
        $scope.state.beforeSave = function () {
            if ($scope.editableForm.$invalid) {
                return false; //can't save
            } else {
                var value = $scope.editableObject.value;
                $scope.editableObject.value = vars.isNumber ? parseFloat(value) : value;
                return true; //proceed with save
            }
        };
    }]);
