import React from 'react';
import PropTypes from 'prop-types';
import Detail from './Default';

const BoolDetail = ({ label, value, translations }) => {
  // We want to pass some truthy value to make sure Detail will be rendered
  const truthyValue = 'forceShow';
  return (
    <Detail label={label} value={truthyValue}>
      {value ? translations.truthy : translations.falsy}
    </Detail>
  );
};

BoolDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  translations: PropTypes.shape({
    truthy: PropTypes.string.isRequired,
    falsy: PropTypes.string.isRequired,
  }).isRequired,
};

export default BoolDetail;
