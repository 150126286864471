import { get } from 'lodash';
import { getSiteId } from '~/common/SecurityMetaService';
import { selectEventConnectorById } from '~/profiles/connectors/form/connectorFormSelector';
import { buildUrl } from '~/common/history';
import { SERVER_CONNECTOR_CATEGORY } from './types';
import * as dependencySelectors from '../dependencies/selectors';
import { getConnectorImage } from './connectorUtils';
import { isCustomConnector } from '../helpers/connectorsHelper';

/**
 * Get Connector By Id
 * @param state
 * @param id
 * @returns {*}
 */
export const getConnectorById = (state, id) => get(state, `profiles.connectors.byId[${id}]`);

export const selectConnectorForStatsScreen = (connectorId, isEventConnector) => state => {
  const connector = getConnectorById(state, connectorId);
  const eventConnector = selectEventConnectorById(state, connectorId);

  if (isEventConnector && eventConnector) {
    return {
      eventConnectorId: eventConnector.eventConnectorId,
      partnerId: eventConnector.destinationPartnerId,
      partnerName: eventConnector.name,
      partnerType: eventConnector.destinationPartnerType,
      activated: eventConnector.activated,
    };
  }

  if (!isEventConnector && connector) {
    return {
      partnerNumber: connector.partnerNumber,
      partnerId: connector.partnerId,
      partnerName: connector.partnerName,
      partnerType: connector.partnerType,
      activated: connector.activated,
    };
  }

  return null;
};

export const selectIsFetchingEventConnectors = state => get(state, 'profiles.eventConnectors.isFetching');

/**
 * Get the currently selected connector with its dependants info
 * @param state
 * @param connectorId
 */
export const getSelectedConnector = (state, connectorId) => {
  const connector = getConnectorById(state, connectorId);
  const connectorDependantsInfo = dependencySelectors.getDependantsInfoById(state, connectorId);
  return {
    ...connector,
    dependantsInfo: connectorDependantsInfo,
  };
};

/**
 * Get All Connectors
 * @param state
 * @returns []
 */
export const getConnectors = state => state.profiles.connectors.allIds.map(id => state.profiles.connectors.byId[id]);

/**
 * Get All Server Connectors
 * @param state
 * @returns []
 */
export const getServerConnectors = state =>
  getConnectors(state)
    .filter(item => {
      const dependantTypes = item.dependantTypes || [];
      return !dependantTypes.includes('EVENT_CONNECTOR');
    })
    .map(connector => ({
      ...connector,
      image: getConnectorImage(getSiteId())(connector),
      url: buildUrl(`profiles/partners/list/stats/${connector.partnerId}`),
      ...(isCustomConnector(connector.partnerType) && { imagePartnerId: connector?.partnerId }),
    }));

/**
 * Should fetch all Connectors?
 * @param state
 * @param category
 * @returns bool
 */
export const shouldFetchConnectors = (state, category) =>
  category === SERVER_CONNECTOR_CATEGORY
    ? get(state, 'profiles.connectors.ui.shouldFetchServerConnectors')
    : get(state, 'profiles.connectors.ui.shouldFetchTagConnectors');

/**
 * Is fetching all Connectors currently in progress?
 * @param state
 * @param category
 * @returns bool
 */
export const isFetchingConnectors = (state, category) =>
  category === SERVER_CONNECTOR_CATEGORY
    ? get(state, 'profiles.connectors.ui.isFetchingServerConnectors')
    : get(state, 'profiles.connectors.ui.isFetchingTagConnectors');

/**
 * Is the dependants container visible?
 * @param state
 */
export const isDependantsContainerVisible = state => get(state, 'profiles.connectors.ui.dependantsContainerIsVisible');

/**
 * Get the currently selected engagement/property
 * @param state
 */
export const getSelectedId = state => get(state, 'profiles.connectors.ui.selectedId');

/**
 * Get the currently selected tab
 * @param state
 */
export const getSelectedTab = state => get(state, 'profiles.connectors.ui.selectedTab');

export const getConnectorTypes = state => get(state, 'profiles.connectors.ui.connectorTypes');
export const getEventConnectorTypes = state => get(state, 'profiles.connectors.ui.connectorTypes');
export const isFetchingConnectorTypes = state => get(state, 'profiles.connectors.ui.isFetchingConnectorTypes');
