/**
 * @description Angular module for urlbuilder campaign
 */
angular.module('webUi.ui.tagmanagement.urlbuilder.campaign',
    [
        'webUi.ui.tagmanagement.urlbuilder.base',
        'webUi.ui.tagmanagement.urlbuilder.campaign.dashboard',
        'webUi.ui.tagmanagement.urlbuilder.campaign.view'
    ]
);
