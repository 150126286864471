angular.module('webUi.ui.content.campaigns.ads.creatives.clickPreview', [
    'webUi.ui.content.campaigns.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.ads.creatives.clickPreview', {
                url: '/clickPreview?u',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/content/campaigns/ads/creatives/clickPreview/clickPreview.tpl.html',
                        controller: 'AdservingClickPreviewController as vm'
                    }
                },
                leftMenu: true
            });
    }])

    .controller('AdservingClickPreviewController',['$scope', '$stateParams',
        function AdservingClickPreviewController($scope, $stateParams) {
            'use strict';
            
            var vm = this;
        
            vm.data = {
                clickURL: $stateParams.u
            };

        }]);
