angular.module('webUi.ui.shared.editPermissions', [])

/**
 * Modal controller to edit permissions of a specific user for a specific site
 * @param $uibModalInstance
 * @param dialogsModel
 * @param $scope
 * @param SecurityService
 */
    .controller('EditUserPermissionsForSiteController', ['$uibModalInstance', 'dialogsModel', '$scope', 'PermissionService', '$q', 'AdminService', 'ValidService',
        function EditUserPermissionsForSiteController($uibModalInstance, dialogsModel, $scope, PermissionService, $q, AdminService, ValidService) {

            $scope.user = dialogsModel.user;
            $scope.hasSameClient = dialogsModel.user.clientId === dialogsModel.site.clientId;
            $scope.username = dialogsModel.user.username;
            $scope.site = dialogsModel.site;
            $scope.webuiPermissionGroups = dialogsModel.permissionGroups.webuiPermissionGroups;
            $scope.allApiPermissions = dialogsModel.permissionGroups.allApiPermissions;
            $scope.givenPermissions = _.clone(dialogsModel.givenPermissions);
            $scope.givenPermissions.apiPermissions = {};
            $scope.handleRelatedPermissions = PermissionService.handleRelatedPermissions;
            _.forEach(_.keys($scope.givenPermissions), function (key) {
                // if(  key.startsWith("API_"))


                var apiStr = 'API_';
                //key.slice(0, apiStr.length) == apiStr
                if (key.slice(0, apiStr.length) === apiStr) {

                    $scope.givenPermissions.apiPermissions[key] = $scope.givenPermissions[key];
                    delete $scope.givenPermissions[key];
                }
            });

            $scope.apiPermissionsEnabled = (!_.isEmpty($scope.givenPermissions.apiPermissions) || false);

            $scope.handleNewAccess = function () {
                var finalPermissions = [];

                for (var permission in $scope.givenPermissions) {
                    if ($scope.givenPermissions[permission] && permission !== 'apiPermissions') {
                        finalPermissions.push(permission);
                    }
                }

                // Process the api permissions
                var apiPermissions = [];
                // Leave only enabled API permissions (with value true)
                _.forEach(_.keys($scope.givenPermissions.apiPermissions), function (key) {
                    if ($scope.givenPermissions.apiPermissions[key]) {
                        apiPermissions.push(key);
                    }
                });
                // Add the API_Permissions to the final roles to be saved
                if (!_.isEmpty(apiPermissions)) {
                    finalPermissions.push(apiPermissions);
                }

                finalPermissions = _.flatten(finalPermissions);

                $uibModalInstance.close(finalPermissions);
            };

            $scope.close = function () {
                $uibModalInstance.close(false);
            };

            $scope.toggleApiPermissions = function () {

                // If disabling api permissions checkbox, clear all API permissions for this user
                $scope.givenPermissions.apiPermissions = {};

                // If enabling api permissions, add the API_USER permission
                if ($scope.apiPermissionsEnabled) {
                    $scope.givenPermissions.apiPermissions['API_USER'] = true;
                }
            };

            /**
             * Watch changes in the different collections to handle permissions
             */
            $scope.$watchCollection('givenPermissions', function (newNames, oldNames) {
                _.transform(newNames, function (result, n, key) {
                    if (!_.isEqual(n, oldNames[key])) {
                        result[key] = n;
                        PermissionService.handleRelatedPermissions(key, n, $scope.givenPermissions);
                    }
                });
            });
            $scope.$watchCollection('givenPermissions.apiPermissions', function (newNames, oldNames) {
                _.transform(newNames, function (result, n, key) {
                    if (!_.isEqual(n, oldNames[key])) {
                        result[key] = n;
                        PermissionService.handleRelatedPermissions(key, n, $scope.givenPermissions);
                    }
                });
            });

            $scope.phoneNumberOptions = {
                editSuccessCallback: function (newPhoneValue) {
                    var def = $q.defer();
                    AdminService.changePhoneNumber($scope.user.userId, newPhoneValue).then(function () {
                        def.resolve(newPhoneValue);
                    });
                    return def.promise;
                }
            };

            $scope.restrictedSubnetsOptions = {
                editSuccessCallback: function (newSubnetValue) {
                    AdminService.changeRestrictedSubnets($scope.user.userId, newSubnetValue);
                    $scope.user.restrictedSubnets = newSubnetValue;
                    return true;
                }
            };

            $scope.isValidPhone = function isValidPhone(phonenum) {
                if (_.isUndefined(phonenum) || _.isEmpty(phonenum)) {
                    return true;
                }
                phonenum = phonenum.replace(/\+/, '');
                return phonenum.match(/^[0-9]+$/) !== null;
            };

            $scope.isValidSubnet = ValidService.isValidSubnet;
        }]);
