/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_CLIENTS = `${API_BASE}/context/usage/client`;

export class ClientSitesUsageClass {
  getSiteStatistics(clientId, from, to) {
    return Api.callGet(`${API_CLIENTS}/${clientId}?rangeFrom=${from}&rangeTo=${to}`).then(response => response.data);
  }
}

const ClientSitesUsageService = new ClientSitesUsageClass();

export default ClientSitesUsageService;
