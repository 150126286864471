import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, isUrl } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class CustomForm extends Component {
  render() {
    const { t, values, partner, canUpdate, isSupportEngineer } = this.props;
    return (
      <FormSection name={constants.partnerTypes.CUSTOM}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection
          title={t('form.formFields.urls')}
          description={t('form.notification.customConnectorUrls')}
        >
          {!canUpdate && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
          )}
          <Field
            label={t('form.formFields.new')}
            name="newUrl"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={values.newUrl && isUrl}
            type="text"
            disabled={!isSupportEngineer}
          />
          <Field
            label={t('form.formFields.removed')}
            name="removedUrl"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={values.removedUrl && isUrl}
            type="text"
            disabled={!isSupportEngineer}
          />
          <Field
            label={t('form.formFields.modified')}
            name="modifiedUrl"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={values.modifiedUrl && isUrl}
            type="text"
            disabled={!isSupportEngineer}
          />
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default CustomForm;
