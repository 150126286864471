angular.module('webUi.ui.tagmanagement.tags.conditions.event', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormEventConditionController', ['$scope',
        function TagmanagementTagFormEventConditionController($scope) {

            var conditionClazz = 'EventCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };

            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };

        }
    ]);
