import React from 'react';
import numeral from 'numeral';
import { isNumber } from 'lodash';
import cx from 'classnames';
import Tooltip from '~/components/src/Tooltip';
import { formatNumber } from '~/common';

interface HumanNumber {
  (props: { number: number; formatFrom?: number; className?: string; testHook?: string }): React.ReactElement;
}

const HumanNumber: HumanNumber = ({ number, formatFrom = 10000, className, testHook }) => {
  const combinedClassName = cx(className, 'cursor-pointer', { [`t-${testHook}`]: testHook });

  if (!isNumber(number)) {
    return <span className={combinedClassName}>{number}</span>;
  } else if (number < formatFrom) {
    return <span className={combinedClassName}>{formatNumber(number)}</span>;
  }

  return (
    <Tooltip tooltip={formatNumber(number)}>
      <span className={combinedClassName}>{numeral(number).format('0.0a')}</span>
    </Tooltip>
  );
};

export default HumanNumber;
