import React, { useContext } from 'react';
import { Field } from 'redux-form';
import { getConnectorLogoUrl, isCustomConnector, isDIYConnector } from '~/profiles/helpers/connectorsHelper';
import Fields from '~/components/src/Form/Fields';
import { isRequired, maxLength } from '~/common';
import { ReduxFormInputField, ReduxFormToggleField } from '~/components/src/Form/Fields/ReduxFormFields';
import { ConnectorHeaderFormSection } from './ConnectorHeaderFormSection';
import { ConnectorModeContext } from '../../connectorModeContext';
import constants from '../../constants';

const defaultMaxInput = maxLength(64);

const FormHeader = props => {
  const { mode } = useContext(ConnectorModeContext);
  const { t, partner, title, description, partnerNumber, children } = props;
  const { partnerId, partnerType, partnerName } = partner;

  const logoUrl = getConnectorLogoUrl(partnerId, partnerType);

  const imageFieldProps = {
    component: Fields.Image,
    src: logoUrl,
    alt: partnerName,
    className: 'ConnectorEdit-logo !max-w-[200px]',
    excludeErrorOrHint: true,
  };

  const dropzoneFieldProps = {
    component: Fields.Dropzone,
    currentLogo: logoUrl,
    placeholder: t('form.formFields.logoDropzonePlaceholder'),
    errorMessage: t('form.formFields.logoNotAccepted'),
    className: 'ConnectorList-dropzone',
  };

  const logoProps =
    isCustomConnector(partnerType) || isDIYConnector(partnerType) ? dropzoneFieldProps : imageFieldProps;

  const showProcessJourneyEvents =
    (mode === constants.modes.CREATE_EVENT || mode === constants.modes.EDIT_EVENT) &&
    partnerType !== constants.partnerTypes.FACEBOOK_CONVERSIONS;

  return (
    <ConnectorHeaderFormSection
      title={title}
      description={description && `${description} ${partnerNumber}`}
      logo={<Field name="logo" {...logoProps} />}
    >
      <Field
        name="name"
        component={ReduxFormInputField}
        validate={[isRequired, defaultMaxInput]}
        label={t('form.formFields.name')}
        type="text"
      />
      {showProcessJourneyEvents && (
        <Field
          className="mt-5"
          label={t('form.formFields.enableJourneyEvents')}
          name="processJourneyEvents"
          component={ReduxFormToggleField}
        />
      )}
      {children}
    </ConnectorHeaderFormSection>
  );
};

export default FormHeader;
