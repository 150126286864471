import React, { ReactElement, useEffect, useState } from 'react';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import i18n from '~/i18n';
import { Field } from 'redux-form';
import { get } from 'lodash';
import { Notification } from '~/components';
import { isRequired, useAPI } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { fetchAuthTypes } from './dataService';
import { AuthFieldProps, AuthorizationBuilderProps } from './types';

const AuthField = ({ field, touch, canUpdate, authTypeModified, values }: AuthFieldProps) => {
  const getFieldType = () => {
    switch (field.type) {
      case 'password':
        return ReduxFormSensitiveField;
      case 'select':
        return ReduxFormSelectField;
      case 'text':
      default:
        return ReduxFormInputField;
    }
  };
  const isFieldRequired = !canUpdate ? isRequired : [];
  const options = field?.options?.map(type => ({ label: type.label, value: type.name })) || [];

  return authTypeModified ? (
    <Field
      className="flex-1"
      label={field.label}
      name={`authorization.${field.value}`}
      component={getFieldType()}
      placeholder={i18n.t('partners:form.formFields.inputPlaceHolder')}
      validate={isFieldRequired}
      options={options}
      touch={touch}
    />
  ) : (
    <FieldWithEditMode
      wrapperLabel={field.label}
      className="flex-1"
      label={field.label}
      name={`authorization.${field.value}`}
      component={getFieldType()}
      isNeedActivateEditMode={canUpdate && get(values, `authorization.${field.value}`) === undefined}
      placeholder={i18n.t('partners:form.formFields.inputPlaceHolder')}
      validate={isFieldRequired}
      options={options}
      touch={touch}
    />
  );
};

const AuthorizationBuilder = ({ touch, values, change, canUpdate }: AuthorizationBuilderProps): ReactElement => {
  const { data: authTypes, error: authTypesError } = useAPI(() => fetchAuthTypes());

  const types = authTypes?.map(type => type.type)?.map(type => ({ label: type.label, value: type.name }));
  const fields = authTypes?.find(type => type?.type?.name === values?.authorization?.authorizationType)?.fields;

  const [authTypeModified, setAuthTypeModified] = useState(false);

  if (authTypesError) {
    return <Notification kind="error">{i18n.t('partners:eventConnector.errors.authTypesFetchError')}</Notification>;
  }

  useEffect(() => {
    if (authTypes && !values?.authorization?.authorizationType) {
      change('DIY.authorization', { authorizationType: authTypes[0]?.type.name });
    }
  }, [authTypes]);

  const handleAuthTypeChange = (event: unknown, value: string) => {
    if (values.authorization.authorizationType !== value) {
      change('DIY.authorization', { authorizationType: value });
      setAuthTypeModified(true);
    }
  };

  return (
    <div className="my-4 flex gap-4">
      <Field
        className="flex-1"
        label={i18n.t('partners:form.formFields.authorizationType')}
        name="authorization.authorizationType"
        component={ReduxFormSelectField}
        options={types || []}
        validate={[isRequired]}
        onChange={handleAuthTypeChange}
        touch={touch}
      />
      {fields?.map((field, index) => (
        <AuthField
          field={field}
          touch={touch}
          canUpdate={canUpdate}
          authTypeModified={authTypeModified}
          values={values}
          key={index}
        />
      ))}
    </div>
  );
};

export default AuthorizationBuilder;
