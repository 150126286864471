import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { reduxForm } from 'redux-form';
import { withRouter } from '~/common/withRouter';
import { components } from '~/components/src/Table';
import { SmallWrapper, Panel } from '~/components/src/Containers';
import { isArray } from 'lodash';
import EngagementForm from '../components/EngagementForm';
import {
  fetchEngagementIfNeeded,
  saveEngagement,
  showDependantsContainer,
  hideDependantsContainer,
  changeDependantTypesSelectedTab,
  setUserIsEditing,
} from '../engagements/actions';
import {
  isFetchingEngagement,
  getEngagementById,
  getPropertiesTableInfo,
  isDependantsContainerVisible,
  getSelectedId,
  getSelectedTab,
  getSelectedProperty,
  isUserEditing,
} from '../engagements/selectors';
import DependantsContainer from '../components/DependantsContainer';

const { DetailContainer } = components;

const FORM_NAME = 'engagementEdit';
const getInitialValues = (state, engagementId) => {
  const selectedEngagement = getEngagementById(state, engagementId);
  const updatedProperties =
    selectedEngagement && isArray(selectedEngagement.properties)
      ? selectedEngagement.properties.map(property => property)
      : [];
  return {
    ...selectedEngagement,
    propertiesWithUsage: getPropertiesTableInfo(state, engagementId),
    updatedProperties,
  };
};

export class EditEngagementPage extends Component {
  componentDidMount() {
    this.props.fetchEngagementIfNeeded();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
    this.props.setUserIsEditing(false);
  }

  render() {
    const { props } = this;
    const {
      t,
      isLoadingData,
      initialValues,
      onDependantsTabChange,
      onDependantsContainerClose,
      selectedPropertyId,
      dependantsContainerIsVisible,
      selectedTab,
      selectedProperty,
      ...formProps
    } = props;

    return isLoadingData || !initialValues.engagementId ? (
      <Spinner />
    ) : (
      <Page>
        <SmallWrapper>
          <Heading title={t('edit.header')} />
          <Panel>
            <EngagementForm
              initialValues={initialValues}
              selectedPropertyId={selectedPropertyId}
              {...formProps}
              t={t}
            />
            <DetailContainer
              onClose={onDependantsContainerClose}
              isVisible={dependantsContainerIsVisible}
              title={`${t('general.property')} - ${selectedPropertyId}`}
            >
              <DependantsContainer
                element={selectedProperty}
                elementName={selectedPropertyId || ''}
                onTabChange={onDependantsTabChange}
                selectedTab={selectedTab}
                showContentDependants={false}
              />
            </DetailContainer>
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const { engagementId } = ownProps?.params;
  return {
    initialValues: getInitialValues(state, engagementId),
    cancelHref: buildUrl('profiles/engagements'),
    isLoadingData: isFetchingEngagement(state),
    dependantsContainerIsVisible: isDependantsContainerVisible(state),
    selectedPropertyId: getSelectedId(state),
    selectedTab: getSelectedTab(state),
    selectedProperty: getSelectedProperty(state, engagementId, getSelectedId(state)),
    userIsEditing: isUserEditing(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { engagementId } = ownProps?.params;
  return {
    fetchEngagementIfNeeded: () => dispatch(fetchEngagementIfNeeded(engagementId)),
    onSubmit: values => dispatch(saveEngagement(values)),
    onDependantIconClick: (engagementId, dependantType, propertyId) => {
      dispatch(showDependantsContainer(engagementId, dependantType, propertyId));
    },
    onDependantsTabChange: selectedTab => {
      dispatch(changeDependantTypesSelectedTab(selectedTab));
    },
    onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
    setUserIsEditing: userIsEditing => dispatch(setUserIsEditing(userIsEditing)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  }),
  translate(['engagements']),
)(EditEngagementPage);
