import React from 'react';

import { useNavigate } from 'react-router-dom';

import i18n from '~/i18n';
import { useApiWithState } from '~/common';
import { buildUrl } from '~/common/history';
import { Heading, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';

import ModelsTable from './ModelsTable';
import { fetchModels } from './dataService';

function ModelsList(): React.ReactElement {
  const navigate = useNavigate();
  const { state: models, isLoading, refetch: refetchModels } = useApiWithState(() => fetchModels());

  return (
    <Page className="ModelPage">
      <Heading title={i18n.t('ai:models')}>
        <BtnIcon
          icon="add"
          color="blue"
          testHook="addModel"
          onClick={() => navigate(buildUrl('ai/models/new'), { replace: true })}
          tooltip={i18n.t('ai:actions.createModel')}
        />
      </Heading>
      <ModelsTable models={models} isLoading={isLoading} refetchModels={refetchModels} />
    </Page>
  );
}

export default ModelsList;
