angular.module('webUi.directive.dynamicName', [])

    .directive('dynamicName', ['$compile', '$parse', function($compile, $parse) {
        return {
            restrict: 'A',
            terminal: true,
            priority: 500,
            link: function(scope, element, attrs) {
                // get name
                var name = $parse(attrs['dynamicName'])(scope);
                // remove attr to avoid recursion
                delete(attrs['dynamicName']);
                element.removeAttr('data-dynamic-name');
                element.removeAttr('dynamic-name');
                // set and compile
                element.attr('name', name);
                $compile(element)(scope);
            }
        };
    }]);
