/**
 * @description: Do not attempt to nest form in form (e.g. template form + editable); see select editable
 */
angular.module('webUi.directive.editable', ['webUi.directive.editable.types', 'webUi.service'])

    .directive('editable', function () {
        return {
            restrict: 'A',
            templateUrl: 'directive/editable/editable.tpl.html',
            priority: 2,
            scope: {
                editableFieldName: '@editable',
                editableTemplate: '@',
                callbackOptions: '=?',
                vars: '&', // Extra variables
                editableDisabled: '=?'
            },
            replace: true,
            transclude: true,
            controller: ['$scope', '$element', '$attrs', 'PubSubService', 'ValidService', 'Utils', '$timeout',
                function EditableController($scope, $element, $attrs, PubSubService, ValidService, Utils, $timeout) {
                    // define default options
                    $scope.defaultOptions = {
                        // Callback. Should return a promise
                        editSuccessCallback: function () {
                            return true;
                        },
                        editCancelCallback: function () {
                        },
                        editableFieldName: $scope.editableFieldName,
                        editableTemplate: (_.isEmpty($scope.editableTemplate) ? 'default' : $scope.editableTemplate)
                    };

                    $scope.editableId = Math.random();

                    var initVars = function () {
                        var vars = $scope.localVars = $scope.vars() || {};

                        vars['placeholder'] = vars['placeholder'] || 'No value specified';
                        vars['title'] = (vars['title']) ? 'Click to edit: ' + vars['title'] : 'Click to edit';
                        return vars;
                    };

                    initVars();

                    //read whether to show the action buttons or not, by default yes
                    $scope.showButtons = $attrs.showButtons ? $scope.$parent.$eval($attrs.showButtons) : true;

                    $scope.$watch('state.editing', function (value) {
                        if (value) {
                            initVars();
                        }
                    });

                    // define editableObject
                    var editableObject = $scope.editableObject = {
                        value: null,
                        originalValue: null
                    };

                    $scope.state = {
                        editing: false
                    };

                    // get value from parent scope
                    Utils.recursiveThen(Utils.getScopeValue($scope.$parent, $scope.editableFieldName, false), function (resolvedValue) {
                        editableObject.originalValue = resolvedValue;
                        editableObject.value = _.cloneDeep(resolvedValue);
                    });

                    //evaluate the custom validation object/array every time the editable value changes (regardless of saving)
                    //validation object can contain a few predefined function names as strings - isRequired, isNumber
                    $scope.$watch('editableObject.value', function (newValue) {
                        $scope.customValidation = $scope.$parent.$eval($attrs['customValidate'], {'$value': newValue});
                    });

                    $scope.$watch(
                        /**
                         * @param $scope
                         * @returns {*}
                         */
                        function ($scope) {
                            return Utils.getScopeValue($scope.$parent, $scope.editableFieldName, false);
                        },
                        /**
                         * @param newField
                         * @param oldField
                         */
                        function (newField, oldField) {
                            // re-subscribe on editingFinished with updated editableFieldName
                            if (newField !== oldField) {
                                editableObject.originalValue = newField;
                                editableObject.value = _.cloneDeep(newField);
                            }
                        }
                    );

                    // watch options and set correct templates
                    $scope.$watch('callbackOptions', function () {
                        $scope.options = $.extend(true, {}, $scope.defaultOptions, $scope.callbackOptions || {});

                        $scope.editingTmpl = 'directive/editable/' + $scope.options.editableTemplate + '/editing.tpl.html';
                        $scope.notEditingTmpl = 'directive/editable/' + $scope.options.editableTemplate + '/notEditing.tpl.html';

                        initSelectOptions();
                    });

                    var promisePending = false;

                    // Subscribe to activating the editing state
                    PubSubService.subscribeEditingActivated($scope, function (event, message) {
                        var activateEditing = $scope.editableFieldName === message.fieldName && $scope.editableId === message.id;
                        var saved;
                        // save if we were editing
                        if ($scope.state.editing && !activateEditing) {
                            saved = $scope.save();
                            // reset value if not able to save (validation failed)
                            if (!saved) {
                                $scope.editableObject.value = $scope.editableObject.originalValue;
                            }
                        } else if (activateEditing) {
                            // activate focus if we are going to edit
                            $timeout(function () {
                                $element.find('.editable-focusElement').focus();
                            });
                        }

                        $scope.state.editing = activateEditing;
                    });

                    //Subscribe to cancelling the editing state
                    PubSubService.subscribeEditingCancelled($scope, function () {
                        $scope.cancel();
                    });

                    // Subscribe to all editing finished, with no particular editable name (click outside of an editable)
                    PubSubService.subscribeBodyClick($scope, function () {
                        //check if THIS editable is in editing state
                        if ($scope.state.editing) {
                            $scope.save();
                        }
                    });

                    //Subscribe to editing finished for a specific fieldName
                    var subscribeEditingFinished = function () {

                        //if the editable was already subscribed to editingFinished, unsubscribe
                        if (!_.isUndefined($scope.editingFinishedUnsubscribeFn)) {
                            $scope.editingFinishedUnsubscribeFn();
                        }

                        //http://docs.angularjs.org/api/ng.$rootScope.Scope#methods_$on
                        //$scope.$on returns a de-registration function, which we store in case we want to subscribe to editingFinished with an updated editableFieldName
                        $scope.editingFinishedUnsubscribeFn = PubSubService.subscribeEditingFinished($scope, $scope.editableFieldName, function (event, message) {
                            // ignore if promise is still pending
                            if (promisePending) {
                                return;
                            }

                            if(message.id !== $scope.editableId) {
                                return;
                            }
                            // if no change, just disable editing
                            if (ValidService.areEqual(message.val, editableObject.originalValue)) {
                                $scope.state.editing = false;
                                return;
                            }
                            // execute the success callback, and take in mind that it can cancel the event
                            var editSuccessCallbackResult = $scope.options.editSuccessCallback(message.val, editableObject.originalValue, $scope.editableFieldName, $scope);

                            // if callback was a promise
                            if (Utils.isPromise(editSuccessCallbackResult)) {
                                promisePending = true;

                                editSuccessCallbackResult.then(function (callbackResult) {
                                    if (callbackResult === false) {
                                        return;
                                    }
                                    // set the value and originalValue
                                    editableObject.value = angular.copy(message.val);
                                    editableObject.originalValue = angular.copy(message.val);
                                    Utils.setScopeValue($scope.$parent, $scope.editableFieldName, message.val);
                                });
                                editSuccessCallbackResult['finally'](function () {
                                    $scope.state.editing = false;
                                    promisePending = false;
                                });
                                // if the callback returned true, update
                            } else if (editSuccessCallbackResult) {
                                editableObject.value = angular.copy(message.val);
                                editableObject.originalValue = angular.copy(message.val);
                                Utils.setScopeValue($scope.$parent, $scope.editableFieldName, message.val);
                                $scope.state.editing = false;
                            }
                        });
                    };

                    //call the subscribe on editingFinished defined above
                    subscribeEditingFinished();

                    //if the editableFieldName changes for some reason (for example multiple checkboxes with indices that are reordered by a filter),
                    // watch the change and assign the new value to the $scope.options
                    $scope.$watch('editableFieldName', function (newField, oldField) {

                        // re-subscribe on editingFinished with updated editableFieldName
                        if (newField !== oldField) {
                            $scope.options.editableFieldName = newField;
                            subscribeEditingFinished();
                        }
                    });

                    // make state to editing
                    $scope.changeStateToEditing = function ($event) {
                        if ($event) {
                            $event.stopPropagation();
                        }

                        if (!$scope.editableDisabled) {
                            PubSubService.publishEditingActivated($scope.options.editableFieldName, $scope.editableId);
                        }
                    };

                    /**
                     * Base save function
                     * Important: this function will be shadowed by save functions in child scopes;
                     * those respective save functions can just call publishEditingFinished when they're done.
                     */
                    $scope.save = function save($event) {
                        if($event) {
                            $event.preventDefault();
                        }
                        var canSave = true;
                        //call beforeSave if it exists, for pre-processing and validation
                        if ($scope.state && $scope.state.beforeSave) {
                            canSave = $scope.state.beforeSave();
                        }
                        if (canSave) { //if validation is respected
                            //trigger an editing finished for this specific field name with the implied value already on the scope
                            PubSubService.publishEditingFinished($scope.options.editableFieldName, $scope.editableObject.value, $scope.editableId);
                        }
                        return canSave;
                    };

                    /**
                     * Function to exit the editable state by cancelling the save and reverting to the original value
                     */
                    $scope.cancel = function cancel() {
                        $scope.editableObject.value = angular.copy($scope.editableObject.originalValue);
                        $scope.options.editCancelCallback();
                        $scope.state.editing = false;
                    };

                    $scope.lodash = _;
                    $scope.isEmpty = ValidService.isEmpty;
                    $scope.isNumber = ValidService.isNumber;

                    $scope.selectOptions = null;
                    //transfer select options
                    $scope.$parent.$watch('selectOptions', function (newValue) {
                        $scope.selectOptions = newValue;
                    });

                    var initSelectOptions = function () {
                        $scope.selectOptions = null;
                        if ($attrs['selectOptions']) {
                            var selectOptions = $attrs['selectOptions'];
                            $scope.$parent.$watch(selectOptions, function (newValue) {
                                $scope.selectOptions = newValue;
                            });
                        }
                    };

                }]
        };
    });
