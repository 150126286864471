/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/profiles/variables`;

/**
 * A service for connector related queries.
 */
export class AdminVariablesClass {
  getVariables() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  deleteVariable(variableId) {
    return Api.callDelete(`${BASE_PATH}/${variableId}`, variableId).then(response => response.data);
  }

  saveVariable(variable) {
    return Api.callPost(BASE_PATH, variable).then(response => response.data);
  }
}

const adminConnectorsService = new AdminVariablesClass();
export default adminConnectorsService;
