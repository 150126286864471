import React from 'react';
import i18n from '~/i18n';
import { PickerView } from '~/components';
import { showSuccess } from '~/notificationCenter';
import GlobalSettingsForm from './GlobalSettingsForm';
import { CreateErrorReportingPayload, CreateGlobalSettingsProps } from '../types';
import { createErrorReporting } from '../dataService';
import { joinWithComma } from '../utils';

const initialValues: CreateErrorReportingPayload = {
  emailsToNotify: '',
  enabled: false,
  errorThreshold: '',
};

const CreateGlobalSettings = ({ setShowCreateModal, refetch }: CreateGlobalSettingsProps) => {
  const handleSubmit = async (values: CreateErrorReportingPayload & { emails: string[] }) => {
    const { emails, enabled, errorThreshold } = values;
    await createErrorReporting({ enabled, errorThreshold, emailsToNotify: joinWithComma(emails) });
    showSuccess({ header: i18n.t('errorReporting:modal.create.api.created') });
    setShowCreateModal(false);
    refetch();
  };

  return (
    <PickerView
      className="!h-auto !w-1/3"
      pickerTitle={i18n.t('errorReporting:modal.create.title')}
      handlePickerVisibility={setShowCreateModal}
    >
      <GlobalSettingsForm showModal={setShowCreateModal} handleSubmit={handleSubmit} initialValues={initialValues} />
    </PickerView>
  );
};

export default CreateGlobalSettings;
