angular.module('webUi.ui.tagmanagement.beacons.base',
    [
        'webUi.ui.tagmanagement.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.beacons', {
                url: '/beacons',
                isReact : true,
            });
    }]);
