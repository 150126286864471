import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

const TagTemplateGroup = () => <div />;

export default compose(
  connect(
    () => ({}),
    () => ({}),
  ),
  translate(['tagTemplates']),
)(TagTemplateGroup);
