/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { changeUrl } from '~/common/history';

export class AngularRedirect extends Component {
  componentDidMount() {
    setTimeout(() => {
      changeUrl(`error/${this.props.errorCode}`);
    }, 10);
  }

  render() {
    return <div />;
  }
}

AngularRedirect.propTypes = {
  errorCode: PropTypes.number.isRequired,
};

const PrivateRoute = ({ component, authenticated, ...props }) => {
  const newProps = { ...props, authenticated };
  return authenticated ? React.createElement(component, newProps) : <AngularRedirect errorCode={403} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  authenticated: PropTypes.bool.isRequired,
};

export default PrivateRoute;
