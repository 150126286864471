import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import Fields from '~/components/src/Form/Fields';
import { isRequired, numberRange, isUnique } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import BtnOutlined from '~/components/src/BtnOutlined';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class TripolisForm extends Component {
  constructor(props) {
    super(props);

    this.partnerRangeValidation = numberRange(2000, 3000);
    this.isPartnerUnique = isUnique(
      this.props.connectors,
      'partnerNumber',
      this.props.t('form.notification.isPartnerUniqueError'),
    );
  }

  componentDidMount() {
    this.fetchConnectorDatabases();
  }

  fetchConnectorDatabases() {
    const { partner, fetchConnectorDatabases, canUpdate, values } = this.props;

    const { password } = values;

    if (canUpdate) {
      const { client, instanceNumber, username } = partner.configuration;

      const params = {
        partner: constants.TRIPOLIS,
        partnerId: partner.partnerId,
        client,
        instanceNumber,
        username,
      };

      if (password) params.password = password;
      return fetchConnectorDatabases(params);
    }

    if (!values.username || !values.client || !password) return null;

    return fetchConnectorDatabases({
      password,
      partner: constants.TRIPOLIS,
      client: values.client,
      instanceNumber: values.instanceNumber,
      username: values.username,
    });
  }

  clearDatabases() {
    const { setConnectorDatabases, change } = this.props;
    setConnectorDatabases([]);
    change('TRIPOLIS.contactDatabaseId', null);
  }

  render() {
    const { t, values, partner, databases, canUpdate, touch } = this.props;
    return (
      <FormSection name={constants.partnerTypes.TRIPOLIS}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          <Field
            label={t('form.formFields.client')}
            name="client"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            onChange={this.clearDatabases.bind(this)}
            type="text"
          />
          <Field
            label={t('form.formFields.instanceNumber')}
            name="instanceNumber"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
            hint={t('form.formFields.instanceNumberHint')}
          />
          <Field
            label={t('form.formFields.username')}
            name="username"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            onChange={this.clearDatabases.bind(this)}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.password')}
            name="password"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            isNeedActivateEditMode={canUpdate}
            onChange={this.clearDatabases.bind(this)}
            type="text"
          />
          <div className="my-4 flex justify-end">
            <BtnOutlined
              disabled={!values.username || !values.client || (!values.password && !canUpdate)}
              onClick={() => this.fetchConnectorDatabases()}
            >
              {t('form.formFields.showDatabases')}
            </BtnOutlined>
          </div>
          <Field
            label={t('form.formFields.contactDatabase')}
            name="contactDatabaseId"
            component={ReduxFormSelectField}
            options={databases}
            disabled={databases.length === 0}
            validate={isRequired}
            touch={touch}
          />
          {!canUpdate && (
            <span>
              <Field
                label={t('form.formFields.advancedSettings')}
                name="advancedSettings"
                component={Fields.Checkbox}
                disabled={canUpdate}
              />
              {values.advancedSettings && (
                <Field
                  label={t('form.formFields.partnerNumber')}
                  name="partnerNumber"
                  component={ReduxFormInputField}
                  validate={[isRequired, this.partnerRangeValidation, this.isPartnerUnique]}
                  type="text"
                  disabled={canUpdate}
                />
              )}
            </span>
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default TripolisForm;
