import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners`;

const getClangCampaignsList = partnerId =>
  Api.callGet(`${BASE_PATH}/clang/${partnerId}/executableCampaigns`, {
    shouldShowToast: false,
  }).then(parseResponse);

export { getClangCampaignsList };
