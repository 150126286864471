import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import * as TabsComponent from '~/components/src/Tabs';
import ServerConnectors from '~/profiles/connectors/containers/ServerConnectors';
import EventConnectors from '~/profiles/connectors/containers/EventConnectors';
import RouterTabs from '~/components/src/RouterTabs';

const { kind, Tab, TabList, TabPanel } = TabsComponent;

export const ConnectorsPage = ({ t, canEdit }) => (
  <Page>
    <RouterTabs kind={kind.heading}>
      <TabList>
        <Tab testHook="tabServerLink">{t('list.tabs.server')}</Tab>
        <Tab testHook="tabEventLink">{t('list.tabs.event')}</Tab>
      </TabList>

      <TabPanel testHook="tabServer">
        <ServerConnectors t={t} canEdit={canEdit} />
      </TabPanel>

      <TabPanel testHook="tabEvent">
        <EventConnectors t={t} canEdit={canEdit} />
      </TabPanel>
    </RouterTabs>
  </Page>
);

ConnectorsPage.propTypes = {
  canEdit: PropTypes.bool,
};

export default compose(translate('partners'))(ConnectorsPage);
