import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Detail = ({ label, value, children, className, textColor = '', valueNotFound = false }) => {
  if (!value && !children) return null;
  return (
    <div className={cx('flex items-center gap-2', className)}>
      <span className=" text-gray-500">{label}:</span>
      <div className="">
        <span
          className={cx(
            `break-words font-medium t-${label.split(' ').join('')} ${textColor ? `text-${textColor}-500` : ''}`,
            {
              'font-normal italic text-gray-700': valueNotFound,
            },
          )}
        >
          {children || value}
        </span>
      </div>
    </div>
  );
};

Detail.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.node.isRequired,
  value: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Detail;
