import React, { useEffect, useState } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { truncateString } from '~/common/utils/StringUtils';
import { Tooltip } from '~/components';
import BtnOutlined from '~/components/src/BtnOutlined';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { PAGE_SIZE } from '../constants';
import { getDiff } from '../dataService';
import { DeploymentsProps, TReleaseHistory } from '../types';
import { Differences } from './Differences';

const columnHelper = createColumnHelper<TReleaseHistory>();

const Deployments = ({ deployments, environments }: DeploymentsProps) => {
  const [selectedEnv, setSelectedEnv] = useState(environments?.[0]?.environmentId || '');
  const [filteredDeployments, setFilteredDeployments] = useState(deployments);
  const [paginatedList, setPaginatedList] = useState(deployments);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  const filterDeploymentByEnv = (envId: string) => {
    const filteredList = deployments.filter(dep => dep.environmentId === envId) || [];
    setFilteredDeployments(filteredList);
    // Reset Pagination
    const pages = filteredList.length / PAGE_SIZE || 1;
    setTotalPages(Math.ceil(pages));
    setPage(0);
  };

  useEffect(() => {
    filterDeploymentByEnv(selectedEnv);
  }, [selectedEnv]);

  useEffect(() => {
    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    setPaginatedList(filteredDeployments.slice(start, end));
  }, [page, filteredDeployments]);

  const handleExpandClick = async (row: Row<TReleaseHistory>) => {
    if (!row.getIsExpanded()) {
      const diff = await getDiff(row.original.parentId, row.original.snapshotId);
      row.original = { ...row.original, diff };
    }
    row.toggleExpanded();
  };

  const getColumns = () => [
    columnHelper.accessor('expander', {
      id: 'expander',
      header: () => <span></span>,
      cell: ({ row }) =>
        row.getCanExpand() && (
          <button className="t-expandButton pointer" onClick={() => handleExpandClick(row)}>
            <Icons className="h-6 w-6 text-r42-blue" icon={row.getIsExpanded() ? 'downArrow' : 'cheveronRight'} />
          </button>
        ),
      size: 20,
    }),
    columnHelper.accessor('publishDescription', {
      header: () => <span>{i18n.t('publishing:history.table.description')}</span>,
      cell: info => (
        <Tooltip tooltip={info.getValue()}>
          <span className="t-publishDescription">{truncateString(info.getValue(), 40)}</span>
        </Tooltip>
      ),
      size: 200,
    }),
    columnHelper.accessor('releaseUserName', {
      header: () => <span>{i18n.t('publishing:history.table.deployedBy')}</span>,
      cell: info => <span className="t-releaseUserName">{info.getValue()}</span>,
      size: 100,
    }),
    columnHelper.accessor('environmentName', {
      header: () => <span>{i18n.t('publishing:history.table.envName')}</span>,
      cell: info => (
        <span className="t-environmentName rounded-full bg-gray-500 px-2 py-1 text-sm text-white">
          {info.getValue()}
        </span>
      ),
      size: 100,
    }),
  ];

  return (
    <>
      <div className="flex items-center gap-2 px-2 py-4">
        <span className="text-lg text-gray-600">{i18n.t('publishing:history.title')}</span>

        {environments.map(env => (
          <BtnOutlined
            key={env.environmentId}
            testHook="envTab"
            onClick={() => setSelectedEnv(env.environmentId)}
            selected={selectedEnv === env.environmentId}
          >
            {env.environmentName}
          </BtnOutlined>
        ))}
      </div>

      <UITable
        testHook="deployments"
        data={paginatedList}
        columns={getColumns()}
        canExpand
        renderSubComponent={Differences}
        totalPages={totalPages}
        page={page}
        onPaginate={setPage}
        emptyMessage={i18n.t('publishing:messages.noRecords')}
        emptyBody={i18n.t('publishing:messages.noDeploymentsBody')}
        enablePagination
      />
    </>
  );
};

export default Deployments;
