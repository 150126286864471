/**
 * @description Service coordinating the data export(s)
 * @class ExportRequestService
 * @memberOf webUi
 * @namespace webUi.service.remote.export
 */
angular.module('webUi.service.remote.export',
    [
        'restangular',
        'webUi.common.Utils'
    ]
)
    .factory('ExportRequestService', [
        'Restangular',
        '$q',
        'Utils',
        /**
     * @function ExportRequestService
     * @memberOf webUi.ExportRequestService
     *
     * @param {Restangular} Restangular
     * @param $q
     *
     * @returns {ExportRequestService}
     *
     */
        function ExportRequestService(Restangular, $q, Utils){

            var BASE_PATH = 'remote/export-requests';

            /**
         * Default 'from' used for loading export requests
         * @type {number|*}
         */
            var DEFAULT_VIEW_DATE_FROM = Utils.getStartOfTheDayOneWeekInThePast();

            /**
         * Default 'to' used for loading export requests
         * @type {number|*}
         */
            var DEFAULT_VIEW_DATE_TO = Utils.getEndOfToday();

            /**
         * @returns {String}
         */
            var getBasePath = function(){
                return BASE_PATH;
            };

            /**
         * @description Gets all the available export request types (e.g MANUAL, SCHEDULED)
         * @returns {*[]}
         */
            var getRequestTypes = function getRequestTypes() {
                var requestTypesPromise = $q.defer();

                Restangular.all(BASE_PATH + '/automationTypes').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            requestTypesPromise.resolve(Restangular.stripRestangular(response));
                        }
                    }
                );

                return requestTypesPromise.promise;
            };

            /**
         * @description Gets all (available) export types
         * @returns {*}
         */
            var getExportTypes = function() {

                var exportTypesPromise = $q.defer();

                Restangular.one(BASE_PATH + '/export-types').get().then(
                    function onSuccess(response) {
                        if(response) {
                            exportTypesPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError(){}
                );
                return exportTypesPromise.promise;
            };

            /**
         * @description Gets the frequency options for scheduled export requests
         * @returns {*[]}
         */
            var getFrequencyOptions = function getFrequencyOptions() {
                var frequencyOptionsPromise = $q.defer();

                Restangular.all(BASE_PATH + '/exportFrequencies').getList().then(
                    function onSuccess(response) {
                        if(response) {
                            frequencyOptionsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    }
                );

                return frequencyOptionsPromise.promise;
            };

            /**
         * @description Gets one export information
         * @param {String} exportId
         * @returns {*}
         */
            var getExportRequest = function(exportId) {

                var exportPromise = $q.defer();

                Restangular.one(BASE_PATH, exportId).get().then(
                    function onSuccess(response) {
                        if(response) {
                            exportPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError(){}
                );
                return exportPromise.promise;
            };

            /**
         * @description Gets one export information
         * @param {String} exportId
         * @returns {*}
         */
            var getExportScheduleRequest = function(exportId) {

                var exportPromise = $q.defer();

                Restangular.one(BASE_PATH + '/scheduled', exportId).get().then(
                    function (response) {
                        if(response) {
                            exportPromise.resolve(Restangular.stripRestangular(response));
                        }
                    }
                );
                return exportPromise.promise;
            };

            /**
         * @description Gets all exports information
         *
         * @param {DateTime} dateFrom
         * @param {DateTime} dateTo
         *
         * @returns {*}
         */
            var getExportRequests = function(period) {

                var exportsPromise = $q.defer();

                var dateFrom;
                var dateTo = DEFAULT_VIEW_DATE_TO;

                if (!period) {
                    dateFrom = DEFAULT_VIEW_DATE_FROM;
                } else {
                    var _period = parseInt(period, 10);
                    dateFrom  = moment().subtract(_period, 'days').startOf('day').toDate().getTime();
                }

                Restangular.one(BASE_PATH).get({'dateFrom': dateFrom, 'dateTo': dateTo}).then(
                    function onSuccess(response) {
                        if (response) {
                            exportsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    },
                    function onError() {}
                );
                return exportsPromise.promise;
            };

            /**
         * @description Gets all scheduled exports
         * @returns {*|r.promise|promise|promise.promise|jQuery.promise|deferred.promise}
         */
            var getScheduledExportRequests = function getScheduledExportRequests() {

                var scheduledExportsPromise = $q.defer();

                Restangular.all(BASE_PATH + '/scheduled').getList().then(
                    function onSuccess(result) {
                        if (result) {
                            scheduledExportsPromise.resolve(Restangular.stripRestangular(result));
                        }
                    }
                );
                return scheduledExportsPromise.promise;
            };


            /**
         * @description Creates an export request
         * @param {Object} createCommand
         * @returns {*}
         */
            var createExportRequest = function(createCommand) {

                var path = BASE_PATH;
                var _request = {};

                if (createCommand.range._from) {
                    _request.start = moment(createCommand.range._from).startOf('day').toDate().getTime();
                }

                if (createCommand.range._to) {
                    _request.end = moment(createCommand.range._to).endOf('day').toDate().getTime();
                }

                if (createCommand.notifyEmails) {
                    _request.notifyEmails = createCommand.notifyEmails;
                }

                if (createCommand.exportType) {
                    _request.exportType = createCommand.exportType;
                }

                if (createCommand.exportReportConfiguration) {
                    _request.exportReportConfiguration = createCommand.exportReportConfiguration;
                }
                if (createCommand.requestType === 'MANUAL_REMOTE') {
                    _request.connectionConfig = createCommand.connectionConfig;
                    path = BASE_PATH + '/manual-remote';
                }
                var exportsPromise = $q.defer();


                Restangular.all(path).post(_request).then(
                    function onSuccess(response) {
                        exportsPromise.resolve(Restangular.stripRestangular(response));
                    },
                    function onError(error) {
                        error.errorHandled = true;
                        exportsPromise.reject({
                            error: error.data.status.error
                        });
                    }
                );
                return exportsPromise.promise;
            };
            /**
         * Check if can create a new export request
         */
            var canCreateNewExport = function(newExportType) {
                var canCreateNewExport = $q.defer();
                if(newExportType === 'SCHEDULED') {
                    Restangular.one(BASE_PATH + '/scheduled/canCreate').get().then(
                        function onSuccess(response) {
                            if(response) {
                                canCreateNewExport.resolve(Restangular.stripRestangular(response));
                            }
                        }
                    );
                }else {
                    canCreateNewExport.resolve({canCreate:true});
                }
                return canCreateNewExport.promise;
            };
            var createScheduledExport = function createScheduledExport(createCommand) {
                var scheduledExportsPromise = $q.defer();

                Restangular.all(BASE_PATH + '/scheduled').post(createCommand).then(
                    function onSuccess(response) {
                        if(response) {
                            scheduledExportsPromise.resolve(Restangular.stripRestangular(response));
                        }
                    }
                );
                return scheduledExportsPromise.promise;
            };

            var updateScheduledExport = function updateScheduledExport(updateCommand) {
                return Restangular.all(BASE_PATH + '/scheduled/edit').post(updateCommand);
            };

            var deleteScheduledExport = function deleteScheduledExport(deleteCommand) {
                var promiseDelete = $q.defer();

                Restangular.all(BASE_PATH + '/scheduled/' + deleteCommand).remove().then(
                    function onSuccess (result) {
                        promiseDelete.resolve(Restangular.stripRestangular(result));
                    }
                );
                return promiseDelete.promise;
            };


            var addRemoteManualCredentials = function addRemoteManualCredentials(exportRequest) {

                var _request = {};
                if (exportRequest.id) {
                    _request.exportRequestId = exportRequest.id;
                }
                if (exportRequest.connectionConfig) {
                    _request.connectionConfig = exportRequest.connectionConfig;
                }
                if (exportRequest.notifyEmails) {
                    _request.notifyEmails = exportRequest.notifyEmails;
                }
                var promiseAddRemoteManualCredentials = $q.defer();

                Restangular.all(BASE_PATH + '/manual-remote/addRemoteManualCredentials').post(_request).then(
                    function onSuccess(response) {
                        if (response) {
                            promiseAddRemoteManualCredentials.resolve(Restangular.stripRestangular(response));
                        }
                    }
                );
                return promiseAddRemoteManualCredentials.promise;
            };

            /**
         * RPM API
         * @type {ExportRequestService}
         */
            var service = {

                DEFAULT_VIEW_DATE_FROM: DEFAULT_VIEW_DATE_FROM,
                DEFAULT_VIEW_DATE_TO: DEFAULT_VIEW_DATE_TO,

                getBasePath: getBasePath,
                getRequestTypes: getRequestTypes,
                getFrequencyOptions: getFrequencyOptions,
                getExportTypes: getExportTypes,
                getExportRequest: getExportRequest,
                getExportRequests: getExportRequests,
                getScheduledExportRequests: getScheduledExportRequests,
                createExportRequest: createExportRequest,
                createScheduledExport: createScheduledExport,
                deleteScheduledExport: deleteScheduledExport,
                getExportScheduleRequest: getExportScheduleRequest,
                updateScheduledExport: updateScheduledExport,
                canCreateNewExport: canCreateNewExport,
                addRemoteManualCredentials: addRemoteManualCredentials
            };
            return service;
        }
    ]
    );

