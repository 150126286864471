import React from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import { formatNumber } from '~/common';
import { SkeletonLoader, Tooltip } from '~/components';
import Detail from '~/components/src/Form/Detail/Default';
import DataSourceChart from './DataSourceChart';
import UsageIcons from './UsageIcons';

const getNumberOfUsedProperties = interaction => {
  const numberOfUsedProperties = size(interaction.usedProperties);
  return numberOfUsedProperties > 0 ? `${numberOfUsedProperties}/${size(interaction.properties)}` : null;
};

export const InteractionDetails = props => {
  const {
    t,
    usedIn,
    interaction,
    onDependantIconClick,
    maxNumberOfProperties,
    isFetchingActivity,
    isFetchingTrafficSources,
  } = props;
  const { insights } = interaction;

  const renderActivity = () => {
    if (isFetchingActivity) return <SkeletonLoader type="text" width={100} />;

    return insights && (insights.activity !== null || insights.activity !== undefined) ? (
      <Tooltip tooltip={t('general.activityTooltip')}>{formatNumber(insights.activity)}</Tooltip>
    ) : (
      <span className="text-xs italic text-red-500">{t('general.activityError')}</span>
    );
  };

  const renderDataSourceChart = () => {
    if (isFetchingTrafficSources) return <SkeletonLoader type="image" height={250} width={300} />;

    if (insights?.trafficSources === null)
      return (
        <div className="size-72 flex items-center justify-center rounded-md border border-red-500">
          <small className="italic text-red-600 ">{t('messages.datasourceError')}</small>
        </div>
      );

    return <DataSourceChart data={insights?.trafficSources ?? []} />;
  };

  return (
    <section className="mx-2 mt-2 flex justify-between">
      <div className="flex flex-col gap-6">
        <Detail
          label={t('view.description')}
          value={interaction.description || t('view.noDescription')}
          valueNotFound={!interaction.description}
        />
        <Detail label={t('view.type')} value={interaction.typeLabel} />
        <Detail
          label={t('view.numberOfProperties')}
          value={`${size(interaction.properties)}/${maxNumberOfProperties}`}
        />
        <Detail label={t('view.numberOfUsedProperties')} value={getNumberOfUsedProperties(interaction)} />
        <Detail label={t('view.activity')} value={renderActivity()} />
        <Detail label={t('view.usedIn')} value={interaction.dependantTypes}>
          <UsageIcons
            dependantTypes={interaction.dependantTypes}
            elementUsedIn={usedIn}
            elementId={interaction.id}
            onDependantIconClick={onDependantIconClick}
          />
        </Detail>
      </div>
      {renderDataSourceChart()}
    </section>
  );
};

InteractionDetails.propTypes = {
  t: PropTypes.func.isRequired,
  interaction: PropTypes.object.isRequired,
  usedIn: PropTypes.array.isRequired,
  maxNumberOfProperties: PropTypes.number.isRequired,
  onDependantIconClick: PropTypes.func.isRequired,
  selectedPropertyId: PropTypes.string,
};

export default InteractionDetails;
