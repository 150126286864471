import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import './CheckboxGroup.scss';

export * from '../Elements/defaultSizes';

const CheckboxGroupElement = ({ options, value, onChange }) => {
  // Array with the existing elements {tags: true, data: false}
  const currentElements = value;
  return (
    <>
      {options.map(option => (
        <div className={`CheckboxGroup t-${option.name}CheckboxGroup`} key={option.name}>
          <FieldWrapper label={option.label} htmlFor={option.name} fieldType="inline">
            <CheckboxElement
              id={option.name}
              value={!!currentElements[option.name]}
              onChange={() => {
                const updatedElements = { ...currentElements };
                updatedElements[option.name] = !updatedElements[option.name];
                onChange(updatedElements, option.name, updatedElements[option.name]);
              }}
            />
          </FieldWrapper>
        </div>
      ))}
    </>
  );
};

CheckboxGroupElement.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired,
};

export default CheckboxGroupElement;
