import { changeNodeAtPath, find } from 'react-sortable-tree';
import { TTaxonomy, TTaxonomyTreeNode } from './types';

const mapTaxonomy = (taxonomy: TTaxonomy): TTaxonomyTreeNode => ({
  ...taxonomy,
  title: taxonomy.name,
  expanded: false,
  selected: false,
  children: taxonomy.subTaxonomy?.map(subTax => mapTaxonomy(subTax)) || [],
});

export const mapTreeData = (taxonomies: TTaxonomy[]) => taxonomies.map(taxonomy => mapTaxonomy(taxonomy));

export const getNodeKey = ({ node }: { node: TTaxonomyTreeNode }) => node.id;

export const unselectAll = (treeData: TTaxonomyTreeNode[]): TTaxonomyTreeNode[] =>
  treeData?.map(node => ({ ...node, selected: false, children: unselectAll(node.children) })) || [];

export const expandDirectory = (
  path: (string | number)[],
  treeData: TTaxonomyTreeNode[],
  selectEnabled: boolean,
  setTreeData: (tree: TTaxonomyTreeNode[]) => void,
): void => {
  const unselectedTree = unselectAll(treeData);

  const newTreeData = changeNodeAtPath({
    treeData: unselectedTree,
    path,
    newNode: ({ node }: { node: TTaxonomyTreeNode }) => ({
      ...node,
      expanded: !selectEnabled ? true : !node.expanded,
      selected: selectEnabled && !node.selected,
    }),
    getNodeKey,
  });

  setTreeData(newTreeData);
};

export const selectNode = (
  path: (string | number)[],
  treeData: TTaxonomyTreeNode[],
  setTreeData: (tree: TTaxonomyTreeNode[]) => void,
): void => {
  const unselectedTree = unselectAll(treeData);

  const newTreeData = changeNodeAtPath({
    path,
    treeData: unselectedTree,
    newNode: ({ node }: { node: TTaxonomyTreeNode }) => ({ ...node, selected: !node.selected }),
    getNodeKey,
  });

  setTreeData(newTreeData);
};

export const expandToSelectedNode = (
  treeData: TTaxonomyTreeNode[],
  selectedNode: TTaxonomyTreeNode,
  path: (string | number)[],
): TTaxonomyTreeNode[] =>
  treeData.map(node => ({
    ...node,
    expanded: !!path.includes(node.id),
    selected: node.id === selectedNode.id,
    children: expandToSelectedNode(node.children, selectedNode, path),
  }));

export const findNode = (treeData: TTaxonomyTreeNode[], id: string) => {
  const results = find({
    treeData,
    getNodeKey,
    searchMethod: ({ node }) => node.id === id,
  });

  return results.matches?.[0]?.node || {};
};
