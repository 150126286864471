import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withState } from 'recompose';
import Link from '../Link';

export const ShowMore = ({
  list,
  renderItem,
  isExpand,
  setExpand,
  btnClassName,
  initialCount = 3,
  ts = { showMore: 'Show more...' },
}) => {
  const shortListProperties = list.slice(0, initialCount);
  const otherProperties = list.slice(initialCount);

  const isShowBtn = !isExpand && otherProperties.length > 0;

  return (
    <React.Fragment>
      {shortListProperties.map(renderItem)}
      {isExpand && otherProperties.map(renderItem)}
      {isShowBtn && (
        <div className="u-flex">
          <Link className={cx('u-marginTopS', btnClassName)} onClick={() => setExpand(true)}>
            <span>{ts.showMore}</span>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

ShowMore.propTypes = {
  list: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  initialCount: PropTypes.number,
  btnClassName: PropTypes.string,
  ts: PropTypes.shape({
    showMore: PropTypes.string,
  }),
};

export default withState('isExpand', 'setExpand', false)(ShowMore);
