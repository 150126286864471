/**
 * @description Angular module for UrlBuilder -> Dashboard
 * @class UrlBuilderCampaignDashboardController
 * @namespace webUi.ui.tagmanagement.urlbuilder.campaign.dashboard
 * @memberOf webUi.ui.tagmanagement.urlbuilder.campaign
 */
angular.module('webUi.ui.tagmanagement.urlbuilder.campaign.dashboard',
    [
        'webUi.service',
        'webUi.ui.tagmanagement.urlbuilder.campaign.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.urlbuilder.campaign.dashboard', {
                url : '/dashboard',
                isReact: true,
            });
    }]);