import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';
import * as types from './types';

const defaultAction = {
  payload: {},
};

const allIds = (state = Immutable([]), action = defaultAction) => {
  switch (action.type) {
    case types.FULL_AUDIENCES_FETCH_SUCCESS:
      return Immutable(action.payload.map(audience => audience.segmentId));
    default:
      return state;
  }
};

/**
 * Audiences Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = Immutable({}), action = defaultAction) => {
  switch (action.type) {
    case types.AUDIENCE_FETCH_ERASE:
      return Immutable({});
    case types.AUDIENCE_FETCH_SUCCESS:
      return state.merge({ ...action.payload });
    default:
      return state;
  }
};

const fullAudiences = (state = Immutable({}), action = defaultAction) => {
  switch (action.type) {
    case types.FULL_AUDIENCES_FETCH_SUCCESS:
      return state.merge(
        action.payload.reduce(
          (acc, val) => ({
            ...acc,
            [val.segmentId]: val,
          }),
          {},
        ),
      );
    default:
      return state;
  }
};

const defaultUI = Immutable({
  isFetchingAudience: false,
  isFetchingTree: false,
  isSavingAudience: false,
  hpIsStarted: false,
  error: null,
  treeData: [],
});

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.AUDIENCE_FETCH_START:
      return state.set('isFetchingAudience', true);
    case types.AUDIENCE_FETCH_SUCCESS:
    case types.AUDIENCE_FETCH_FAIL:
      return state.set('isFetchingAudience', false);
    case types.HISTORIC_PROCESSING_START:
      return state.set('hpIsStarted', true);
    case types.HISTORIC_PROCESSING_SUCCESS:
    case types.HISTORIC_PROCESSING_FAIL:
      return state.set('hpIsStarted', false);
    case types.AUDIENCES_TREE_FETCH_START:
      return state.set('isFetchingTree', true);
    case types.AUDIENCES_TREE_FETCH_SUCCESS:
      return state.set('treeData', action.payload).set('isFetchingTree', false);
    case types.AUDIENCES_TREE_FETCH_FAIL:
      return state.set('isFetchingTree', false);
    case types.AUDIENCE_SAVE_START:
    case types.AUDIENCE_UPDATE_START:
      return state.set('isSavingAudience', true);
    case types.AUDIENCE_SAVE_FAIL:
    case types.AUDIENCE_UPDATE_FAIL:
      return state.set('isSavingAudience', false);
    case types.AUDIENCE_SAVE_SUCCESS:
    case types.AUDIENCE_UPDATE_SUCCESS:
      return state.set('isSavingAudience', false);

    default:
      return state;
  }
};

const audiencesTreeReducer = combineReducers({
  fullAudiences,
  allIds,
  byId,
  ui,
});

export default audiencesTreeReducer;
