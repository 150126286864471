import React from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import Detail from '~/components/src/Form/Detail/Default';
import UsageIcons from './UsageIcons';

const getNumberOfUsedProperties = interaction => {
  const numberOfUsedProperties = size(interaction.usedProperties);
  return numberOfUsedProperties > 0 ? `${numberOfUsedProperties}/${size(interaction.properties)}` : null;
};

export const InteractionDetails = props => {
  const { interaction, usedIn, maxNumberOfProperties, onDependantIconClick, t } = props;
  return (
    <section className="u-marginVerticalXl">
      <Detail label={t('view.description')} value={interaction.description || t('view.noDescription')} />
      <Detail label={t('view.type')} value={interaction.typeLabel} />
      <Detail label={t('view.numberOfProperties')} value={`${size(interaction.properties)}/${maxNumberOfProperties}`} />
      <Detail label={t('view.numberOfUsedProperties')} value={getNumberOfUsedProperties(interaction)} />
      <Detail label={t('view.usedIn')} value={interaction.dependantTypes}>
        <UsageIcons
          dependantTypes={interaction.dependantTypes}
          elementUsedIn={usedIn}
          elementId={interaction.id}
          onDependantIconClick={onDependantIconClick}
        />
      </Detail>
    </section>
  );
};

InteractionDetails.propTypes = {
  t: PropTypes.func.isRequired,
  interaction: PropTypes.object.isRequired,
  usedIn: PropTypes.array.isRequired,
  maxNumberOfProperties: PropTypes.number.isRequired,
  onDependantIconClick: PropTypes.func.isRequired,
  selectedPropertyId: PropTypes.string,
};

export default InteractionDetails;
