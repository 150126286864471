import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import Notification from '~/components/src/Notification';
import ModalHeader from '~/modals/containers/ModalHeader';
import * as fromModals from '~/modals';
import { resetTimebasedTokenStep, addTwoFactorAuth } from '~/user/settings';
import * as types from '~/user/settings/types';
import { isRequired, isPositiveNumber } from '~/common';
import * as settingsSelectors from '~/user/settings/selectors';
import UserSettingsService from '~/user/settings/dataService';
import Btn from '~/components/src/Btn';

export class TwoFactorAuthModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorAuth: {},
    };
  }
  componentDidMount() {
    this.props.onResetTimebasedTokenStep();
    UserSettingsService.getGoogleAuth().then(response => {
      this.setState({ twoFactorAuth: response });
    });
  }

  render() {
    const { tokenError, handleSubmit, submitting, t, timebasedTokenStep, onCancel } = this.props;
    const { twoFactorAuth } = this.state;
    const sendButtonText =
      timebasedTokenStep === 1
        ? t('settings:timebasedToken.actions.step1')
        : t('settings:timebasedToken.actions.step2');

    const errorTokenRequest = tokenError && (
      <Notification kind="error" header={t('settings:errors.header')}>
        {t('settings:errors.timeBasedToken')}
      </Notification>
    );
    const modalBodyPart =
      timebasedTokenStep === 1 ? (
        <React.Fragment>
          <ol>
            <li>{t('settings:timebasedToken.descriptionContent.step1')}</li>
            <li>{t('settings:timebasedToken.descriptionContent.step2')}</li>
            <li>{t('settings:timebasedToken.descriptionContent.step3')}</li>
          </ol>
          <div className="Aligner">
            {twoFactorAuth && <img src={`data:image/PNG;base64,${twoFactorAuth.qrCode}`} />}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Notification className="u-marginTopXl" kind="information" header={t('settings:timebasedToken.headerStep2')}>
            <p>{t('settings:timebasedToken.confirmationContent')}</p>
            <Field
              label={t('settings:timebasedToken.label')}
              name="googleAuthCode"
              component={ReduxFormInputField}
              type="text"
              validate={[isRequired, isPositiveNumber]}
            />
          </Notification>
        </React.Fragment>
      );
    this.props.dispatch(change(types.ADD_TIMEBASED_TOKEN_FORM, 'secretKey', twoFactorAuth.secretKey));
    return (
      <Form onSubmit={handleSubmit} isSubmitting={submitting}>
        <ModalHeader title={t('settings:timebasedToken.headerText')} onCloseClick={onCancel} />
        <div className="Modal-body p-4">
          {modalBodyPart}
          {errorTokenRequest}
        </div>
        <div className="modal-footer flex items-center justify-end gap-2 p-4">
          <Btn testHook="cancel" onClick={onCancel} disabled={submitting}>
            {t('settings:actionsForm.cancel')}
          </Btn>
          <Btn color="blue" testHook="save" disabled={submitting} type="submit">
            {sendButtonText}
          </Btn>
        </div>
      </Form>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      user: ownProps.user,
      t: ownProps.t,
      timebasedTokenStep: settingsSelectors.getTimebasedTokenStep(state),
      tokenError: settingsSelectors.getErrorTimeBasedTokenRequest(state),
    }),
    dispatch => ({
      onSubmit: values => dispatch(addTwoFactorAuth(values)),
      onCancel: () => dispatch(fromModals.hideModal()),
      onResetTimebasedTokenStep: () => dispatch(resetTimebasedTokenStep()),
    }),
  ),
  reduxForm({
    form: types.ADD_TIMEBASED_TOKEN_FORM,
  }),
)(TwoFactorAuthModalContainer);
