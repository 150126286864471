import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';

const Divider = ({ className, width }) => <div style={{ width: `${width}px` }} className={cx('Divider', className)} />;

Divider.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
