import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { actionCreators } from 'react-redux-composable-list';
import { buildUrl } from '~/common';
import * as fromModals from '~/modals';
import * as selectors from '~/support/tagtemplates/templates/selectors';
import { TAGTEMPLATES_TRANSLATIONS_KEY } from '~/support/tagtemplates';
import { fetchTemplate, deleteTemplate, toggleDeleted } from '~/support/tagtemplates/templates/actions';
import Detail from '~/components/src/Form/Detail/Default';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import TagVariantsList from '~/support/components/TagVariantsList';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';

const FALLBACK_IMAGE_PATH = '/assets/img/templates/no-image.png';

export class TagTemplate extends Component {
  constructor(props) {
    super(props);
    this.logoRef = React.createRef();
  }

  componentDidMount() {
    const { fetchTemplate, id } = this.props;
    fetchTemplate(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.props.fetchTemplate(nextProps.id);
    }
  }

  render() {
    const { props } = this;
    const { template, t, editTemplateHref, onDeleteClick } = props;
    const { areAnyDeleted, showDeleted, variants } = props;
    const { onFilterChange, toggleDeleted, newVariantHref, stateKey, variantUrl } = props;

    const toggleDeletedPart = !areAnyDeleted ? null : (
      <BtnIcon
        onClick={toggleDeleted}
        icon="delete"
        tooltip={showDeleted ? t('variantsList.actions.hideDeleted') : t('variantsList.actions.showDeleted')}
      />
    );

    return !template ? (
      <Spinner />
    ) : (
      <Page>
        <Heading title={t('template.header')}>
          <BtnIcon
            icon="delete"
            onClick={() => onDeleteClick(template, t)}
            testHook="delete"
            tooltip={t('template.actions.remove')}
          />
          <LinkIcon icon="edit" tooltip={t('template.actions.edit')} url={editTemplateHref} />
        </Heading>

        <Detail label={t('template.name')} value={template.name} />
        <Detail label={t('template.description')} value={template.description} />
        <Detail label={t('template.groupName')} value={template.groupName} />
        <Detail label={t('template.platformType')} value={template.platformType && template.platformType.label} />
        <Detail label={t('template.tagContentType')} value={template.tagContentType && template.tagContentType.label} />
        <Detail className="u-marginBottomXxl" label={t('template.logo')} value={template.logo}>
          <img
            ref={this.logoRef}
            onError={() => {
              // HACK: Fixes endless placeholder image loading loop. In rare cases when no-image.png doesn't exist on server
              // (because of deployment issues or smth else), this onError callback gets triggered endlessly
              // So we set the "src" attribute value only if image exists on server
              const fallbackImage = new Image();

              fallbackImage.onload = () => {
                this.logoRef.current.src = FALLBACK_IMAGE_PATH;
              };

              fallbackImage.src = FALLBACK_IMAGE_PATH;
            }}
            src={
              template.logo === null
                ? FALLBACK_IMAGE_PATH
                : `/assets/img/templates/${encodeURIComponent(template.logo)}`
            }
            alt={t('template.logo')}
          />
        </Detail>

        <Heading kind="h2" title={t('variantsList.header')}>
          <SearchElement
            onChange={e => onFilterChange(e.target.value)}
            placeholder={t('variantsList.actions.search')}
          />
          <LinkIcon icon="add" url={newVariantHref} color="blue" tooltip={t('variantsList.actions.add')} />
          {toggleDeletedPart}
        </Heading>
        <TagVariantsList
          {...{
            list: variants,
            stateKey,
            variantUrl,
            t,
          }}
        />
      </Page>
    );
  }
}

const titleFilterFn = query => item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

export default compose(
  withRouter,
  connect(
    (state, ownProps) => {
      const id = ownProps.params.templateId;
      const template = selectors.getTemplateById(state, id);
      const variantsAll = selectors.getTemplateVariants(state, id);
      const variantNoDeleted = variantsAll && variantsAll.filter(item => !item.deleted);
      const areAnyDeleted = variantsAll && variantNoDeleted && variantsAll.length !== variantNoDeleted.length;
      const showDeleted = selectors.showDeleted(state);
      const variants = showDeleted ? variantsAll : variantNoDeleted;
      return {
        id,
        editTemplateHref: buildUrl(`support/tagtemplates/templates/edit/${id}`),
        newVariantHref: buildUrl(`support/tagtemplates/variants/add?templateId=${id}`),
        template,
        variants,
        areAnyDeleted,
        showDeleted,
        stateKey: 'TAG_TEMPLATE_VARIANTS',
        variantUrl: variant => buildUrl(`support/tagtemplates/variants/view/${variant.variantId}`),
      };
    },
    dispatch => ({
      toggleDeleted: () => dispatch(toggleDeleted()),
      fetchTemplate: id => dispatch(fetchTemplate(id)),
      onFilterChange: query =>
        query !== ''
          ? dispatch(actionCreators.doSetFilter('TAG_TEMPLATE_VARIANTS', 'TITLE_FILTER', titleFilterFn(query)))
          : dispatch(actionCreators.doRemoveFilter('TAG_TEMPLATE_VARIANTS', 'TITLE_FILTER')),
      onDeleteClick: (template, t) => {
        dispatch(
          fromModals.showModal(fromModals.DELETE_MODAL, {
            title: t('template.deleteModal.title'),
            message: t('template.deleteModal.message', { name: template.name }),
            onConfirm: () => dispatch(deleteTemplate(template)),
          }),
        );
      },
    }),
  ),
  translate([TAGTEMPLATES_TRANSLATIONS_KEY]),
)(TagTemplate);
