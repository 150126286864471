/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const API_CLIENTS = `${API_BASE}/context/clients`;
/**
 * Clients List service for fetching or any server calls in terms of Clients List view.
 *
 * @description class would be extended while migration from Angular to React.
 */
class ContextClientsClass {
  /**
   * GET all clients
   */
  getAllClients() {
    return Api.callGet(`${API_CLIENTS}`).then(response => response.data);
  }

  saveClient(requestBody) {
    const endpoint = requestBody.clientId ? 'edit' : 'add';
    return Api.callPost(`${API_CLIENTS}/${endpoint}`, requestBody).then(response =>
      this.getClient(response && response.data),
    );
  }

  getClient(clientId) {
    return Api.callGet(`${API_CLIENTS}/${clientId}`).then(response => response.data);
  }

  /**
   * GET list of all client managers.
   * @param clientId
   */
  getAccountManagers(clientId) {
    return Api.callGet(`${API_CLIENTS}/${clientId}/accountManagers`).then(response => response.data);
  }

  /**
   * DELETE client from the list of clients
   * @param clientId
   * @returns {*}
   */
  deleteClient(clientId) {
    return Api.callDelete(`${API_CLIENTS}/${clientId}`).then(response => response.data);
  }

  updatePaswwordPolicy(clientId, passwordPolicy) {
    return Api.callPost(`${API_CLIENTS}/passwordPolicy/edit`, {
      ...passwordPolicy,
      clientId,
    }).then(response => response.data);
  }

  getDataRegion() {
    return Api.callGet(`${API_CLIENTS}/dataStorageLocations`).then(response => response.data);
  }
}

const ContextClientsService = new ContextClientsClass();

export default ContextClientsService;
