/**
 * Get Cookie Template By Id
 * @param state
 * @param id
 * @returns {*}
 */
export const getVariantById = (state, id) => state.support.cookies.byId[id];

/**
 * Get All Clients for a given Cookie Template Variant
 * @param state
 * @param id
 * @returns {Array}
 */
export const getClientsForVariant = (state, id) => {
  const clientsIdsObj = state.support.cookies.clientsByVariant[id] || {};
  return Object.keys(clientsIdsObj).map(key => clientsIdsObj[key]);
};

/**
 * Get Restricted Clients for a given Cookie Template Variant
 * @param state
 * @param id
 * @returns {Array}
 */
export const getRestrictedClientsForVariant = (state, id) => {
  const clientLinks = getClientsForVariant(state, id);
  return clientLinks.filter(item => item.restricted);
};

/**
 * Get Not Restricted Clients for a given Cookie Template Variant
 * @param state
 * @param id
 * @returns {Array}
 */
export const getUnRestrictedClientsForVariant = (state, id) => {
  const clientLinks = getClientsForVariant(state, id);
  return clientLinks.filter(item => !item.restricted);
};

/**
 * Get Client Ids that are marked to be restricted for a given Cookie Template Variant
 * @param state
 * @param id
 * @returns {Array}
 */
export const getClientIdsMarkedToRestrict = (state, id) => {
  const clientsObj = state.support.cookies.clientsByVariant[id];
  return (clientsObj && Object.keys(clientsObj).filter(key => clientsObj[key].markedToRestrict)) || [];
};

/**
 * Get All Cookie Templates
 * @param state
 */
export const getVariants = state => state.support.cookies.allIds.map(id => state.support.cookies.byId[id]);

/**
 * Should fetch all cookie templates?
 * @param state
 */
export const shouldFetchVariants = state => state.support.cookies.ui.shouldFetchAll;

/**
 * Is fetching all cookie templates currently in progress?
 * @param state
 */
export const isFetchingVariants = state => state.support.cookies.ui.isFetchingAll;
