/**
 * TODO candidate for removal
 */
angular.module('webUi.filter.orFilter', [])

    .filter('orFilter', [function() {

        return function(input, filterString, properties) {
            return _.filter(input, function(object) {

                if(!filterString) {
                    return true;
                }

                filterString = filterString.toLowerCase();

                for(var i =0; i < properties.length; i++) {
                    var value = '' + object[properties[i]];

                    if(value.toLowerCase().indexOf(filterString) > -1) {
                        return true;
                    }
                }

                return false;
            });
        };
    }]);
