import Immutable from 'seamless-immutable';
import { uniqBy } from 'lodash';

export const joinCriteriaVariables = (criteria, variables) => {
  const variablesToRules = Immutable.asMutable(variables).reduce((hashmap, variable) => {
    hashmap[variable.variableId] = {
      // ES6 Set exclude duplicates by design, so we write less code
      dependantGroups: new Set(),
      dependantRules: new Set(),
    };
    return hashmap;
  }, {});

  criteria.forEach((group, groupIndex) =>
    group
      .filter(rule => Array.isArray(rule.filters))
      .forEach(rule =>
        rule?.filters
          .filter(({ profileVarId }) => profileVarId && profileVarId !== null)
          .forEach(({ profileVarId }) => {
            const meta = variablesToRules[profileVarId];
            if (meta) {
              meta.dependantGroups.add(groupIndex);
              meta.dependantRules.add(rule.ruleId);
            }
          }),
      ),
  );

  const result = criteria.map((group, groupIndex) =>
    group.map(rule => {
      // to prevent mutations of rule, since it's the reference
      const newRule = { ...rule };
      newRule.availableVariables = variables.map(variable => {
        const meta = variablesToRules[variable.variableId];
        const isDependantOnGroup = !!meta.dependantGroups.size && !meta.dependantGroups.has(groupIndex);
        const isDenendantOnRule = !!meta.dependantRules.size && meta.dependantRules.has(rule.ruleId);
        const isDisabled = isDependantOnGroup || isDenendantOnRule;

        return { ...variable, disabled: isDisabled };
      });
      return newRule;
    }),
  );
  return result;
};

export const getVariablesUsedInRules = criteria => {
  const allFilters = criteria
    .flat()
    .flatMap(({ filters }) => filters)
    .filter(filterValues => typeof filterValues !== 'undefined');

  const variables = allFilters
    .filter(({ profileVarId }) => typeof profileVarId !== 'undefined' && profileVarId !== null)
    .map(({ profileVarId, profileVarName }) => ({
      id: profileVarId,
      name: profileVarName,
    }));

  return uniqBy(variables, 'id');
};

export const getAllConnectorsForIntegrationsScreen = serverConnectors =>
  serverConnectors.map(({ image, partnerId, partnerType, partnerNumber, partnerName, configuration, activated }) => ({
    activated,
    image,
    partnerId,
    partnerType,
    partnerNumber,
    partnerName,
    configuration,
  }));
