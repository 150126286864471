import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader, { numberSort } from '~/components/src/Table/sortableHeader';
import Empty from '~/components/src/Empty';
import { Panel } from '~/components/src/Containers';

const { Table, Row, Cell, RowActions } = components;
const { withSort, withPaginate, withEmpty } = enhancements;

const EmptyList = ({ t }) => (
  <Empty header={t('urlBuilder:columns.list.empty.header')} body={t('urlBuilder:columns.list.empty.body')} />
);

export const ColumnsList = ({ list, stateKey, onEditClick, onDeleteClick, t, isSiteAdmin }) => (
  <Panel>
    <Table stateKey={stateKey} testHook="columnTable">
      <Row>
        <SortableHeader
          className="u-size1of4"
          sortKey="orderNumber"
          sortFn={numberSort('orderNumber')}
          title={t('urlBuilder:columns.list.orderNumber')}
        />
        <SortableHeader className="u-size1of4" sortKey="name" title={t('urlBuilder:columns.list.name')} />
        <SortableHeader className="u-size1of4" sortKey="description" title={t('urlBuilder:columns.list.description')} />
      </Row>
      {list.map(column => {
        const { columnId, orderNumber, name, description } = column;
        const actions = [
          {
            name: t('urlBuilder:columns.list.editActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:columns.list.editActionButton')
              : t('urlBuilder:columns.list.editPermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'edit',
            onClick: () => onEditClick(columnId),
            testHook: 'edit',
          },
          {
            name: t('urlBuilder:columns.list.deleteActionButton'),
            tooltip: isSiteAdmin
              ? t('urlBuilder:columns.list.deleteActionButton')
              : t('urlBuilder:columns.list.deletePermisionError'),
            isDisabled: !isSiteAdmin,
            icon: 'delete',
            onClick: () => onDeleteClick(column, t),
            testHook: 'delete',
            type: 'delete',
          },
        ];

        return (
          <Row key={columnId} withActions>
            <Cell className="u-size1of4">{orderNumber}</Cell>
            <Cell className="u-size1of4">{name}</Cell>
            <Cell className="u-size1of4">{description}</Cell>
            <RowActions actions={actions} />
          </Row>
        );
      })}
    </Table>
  </Panel>
);

ColumnsList.propTypes = {
  list: PropTypes.array,
  stateKey: PropTypes.string,
  t: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default compose(withSort(), withPaginate({ size: 11 }), withEmpty({ component: EmptyList }))(ColumnsList);
