import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import cx from 'classnames';
import makeAnimated from 'react-select/animated';
import BtnIcon from '~/components/src/BtnIcon';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import i18n from '~/i18n';
import { SOURCE_COUNTRY_SELECT_ALL } from '../constants';
import { TCountryCode } from '../types';

const animatedComponents = makeAnimated();

const Option = (props: any) => (
  <div>
    <components.Option {...props} className="!flex !items-center">
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />
      <label className="!mx-3 !my-2">{props.label}</label>
    </components.Option>
  </div>
);

const ValueContainer = ({ children, ...props }: any) => {
  const length = children && children[0]?.length;
  const tmpChildren = [...children];
  if (length && length >= 1) {
    tmpChildren[0] = '';
  }
  return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
};

const Dropdown = ({
  children,
  isOpen,
  target,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  target: React.ReactNode;
}) => (
  <div className="t-dataSourceCountry relative bg-white">
    {target}
    {isOpen ? <div className="absolute z-[4] w-full bg-white shadow-md">{children}</div> : null}
  </div>
);
const selectStyles = {
  control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: (styles: any, { isSelected }: any) => ({
    ...styles,
    background: isSelected && '#0054b010',
    color: isSelected && '#0054b0',
  }),
};

const LabelSelect = ({
  labels,
  countryCodes = [],
  setCountryCodes,
  hasError,
}: {
  labels: TCountryCode[];
  countryCodes: string[];
  setCountryCodes: React.Dispatch<React.SetStateAction<string[]>>;
  hasError: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const allWasSelected = useRef<boolean>(false);

  const options = [
    SOURCE_COUNTRY_SELECT_ALL,
    ...labels.map((item: TCountryCode) => ({
      label: `${item.name} (${item.code})`,
      value: item.code,
    })),
  ];

  const optionsCounts = labels?.length;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!dropdownRef?.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChange = (selected: any = []) => {
    const items = selected?.map((option: { value: string }) => option.value || undefined) || [];
    const isAllSelected = items.includes(SOURCE_COUNTRY_SELECT_ALL.value);
    const allItemsSelected =
      items.filter((option: string) => option !== SOURCE_COUNTRY_SELECT_ALL.value)?.length === optionsCounts;

    if (isAllSelected && !allWasSelected.current) {
      allWasSelected.current = true;
      const allItems = options.map((option: { value: string }) => option.value);
      setCountryCodes(allItems);
    } else if (!isAllSelected && allWasSelected.current) {
      allWasSelected.current = false;
      setCountryCodes([]);
    } else if (allWasSelected.current && !allItemsSelected) {
      allWasSelected.current = false;
      setCountryCodes(items.filter((option: string) => option !== SOURCE_COUNTRY_SELECT_ALL.value));
    } else if (!isAllSelected && allItemsSelected) {
      setCountryCodes([SOURCE_COUNTRY_SELECT_ALL.value, ...items]);
    } else {
      setCountryCodes(items);
    }
  };

  const hasCountry = countryCodes?.length > 0;

  return (
    <>
      <FieldWrapper label={i18n.t('audiences:connectors.amazonDsp.dataSourceCountry')}>
        <Dropdown
          isOpen={isOpen}
          target={
            <div
              className={cx('flex w-full items-center rounded border border-solid text-left leading-5 shadow-none', {
                'border-gray-300': !hasError,
                'border-red-800': hasError,
              })}
            >
              <button
                className={cx('border-[none] min-h-[38px] flex-1 rounded px-3 py-0 text-left', {
                  'bg-red-50': hasError,
                  'text-gray-400': !hasCountry,
                })}
                onClick={() => setIsOpen(!isOpen)}
              >
                {`${countryCodes?.filter(item => item !== SOURCE_COUNTRY_SELECT_ALL.value)?.length || 0} Selected`}
              </button>
              {hasCountry && (
                <BtnIcon
                  icon="close"
                  onClick={() => setCountryCodes([])}
                  testHook="clearLabels"
                  className="mr-2 h-6 w-6"
                />
              )}
            </div>
          }
        >
          <div ref={dropdownRef}>
            <SelectElement
              options={options}
              value={countryCodes.map((item: string) => ({ value: item }))}
              onChange={handleChange}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
                ValueContainer,
                animatedComponents,
                DropdownIndicator: null,
                IndicatorSeparator: null,
                ClearIndicator: null,
              }}
              menuIsOpen
              autoFocus
              styles={selectStyles}
              placeholder={i18n.t('audiences:connectors.amazonDsp.search')}
            />
          </div>
        </Dropdown>
      </FieldWrapper>
      {hasError && <p className="text-sm text-red-800">{i18n.t('audiences:connectors.amazonDsp.noCountryError')}</p>}
    </>
  );
};

export default LabelSelect;
