import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { buildUrl, useAPI, useApiWithState } from '~/common';
import { Notification } from '~/components';
import Btn from '~/components/src/Btn';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { NODE_SHOWN_NAME, NodeTypes, OVERVIEW_MENU, OVERVIEW_MENU_NAMES } from '~/workflows/constants';
import { fetchJourneyTree, fetchWorkflowData, getConfiguration } from '~/workflows/dataService';
import { TStepConfiguration, TTriggerConfiguration } from '~/workflows/types';
import { useQuery } from '~/workflows/util';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import Header from './Header';
import StepCard from './StepCard';
import StepDetails from './StepDetails';
import TriggerCard from './TriggerCard';
import TriggerDetails from './TriggerDetails';

const getTypeByNodeId = (
  nodeId: string,
  startTrigger: TTriggerConfiguration,
  steps: TStepConfiguration[],
  goals: TTriggerConfiguration[],
  exits: TTriggerConfiguration[],
) => {
  if (startTrigger.nodeId === nodeId) return NodeTypes.START_TRIGGER;
  if (steps.find(step => step.nodeId === nodeId)) return NodeTypes.STEP;
  if (goals.find(goal => goal.nodeId === nodeId)) return NodeTypes.GOAL;
  if (exits.find(exit => exit.nodeId === nodeId)) return NodeTypes.EXIT_BY_CONDITION;
  return null;
};

const Overview = () => {
  const params = useParams();
  const query = useQuery();
  const workflowId = params.workflowId || '';
  const nodeId = query.get('nodeId');

  const [menu, setMenu] = useState(OVERVIEW_MENU);
  const [selectedTrigger, setSelectedTrigger] = useState<string | null>(null);
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string>();

  const { navigate, hasWorkflowAudienceEdit } = useContext(WorklfowContext);

  const { data: treeData, isLoading: isTreeLoading } = useAPI(() => fetchJourneyTree(workflowId), []);
  const { data: configurationData, isLoading } = useAPI(() => getConfiguration(workflowId), []);
  const {
    state: audienceData,
    isLoading: isAudienceLoading,
    refetch: refetchAudience,
  } = useApiWithState(async () => fetchWorkflowData(workflowId), [workflowId]);

  const { startTrigger, goals = [], exitConditions = [], steps = [] } = configurationData || {};

  const toggleMenu = (name: string) => {
    setMenu(prevMenu =>
      prevMenu.map(menuItem => (menuItem.name === name ? { ...menuItem, expand: !menuItem.expand } : menuItem)),
    );
  };

  const selectItem = (type: string) => (id: string) => {
    setSelectedType(type);
    if (type === NodeTypes.STEP) {
      setSelectedStepId(id);
      setSelectedTrigger(null);
    } else {
      setSelectedStepId(null);
      setSelectedTrigger(id);
    }
  };

  useEffect(() => {
    if (!isLoading && startTrigger) {
      const type = nodeId && getTypeByNodeId(nodeId, startTrigger, steps, goals, exitConditions);

      if (type) {
        selectItem(type)(nodeId);
      } else {
        selectItem(NodeTypes.TRIGGER)(startTrigger?.nodeId);
      }
    }
  }, [isLoading]);

  const getMenu = (name: string) => menu.find(menuItem => menuItem.name === name);
  const confMenu = getMenu(OVERVIEW_MENU_NAMES.configuration);
  const goalsMenu = getMenu(OVERVIEW_MENU_NAMES.goals);
  const exitMenu = getMenu(OVERVIEW_MENU_NAMES.exit);

  if (isLoading || isTreeLoading || isAudienceLoading || !configurationData || !treeData || !audienceData)
    return <Spin />;

  const rootNodeChilds = treeData?.nodes?.children || [];
  const hasStep = rootNodeChilds?.length > 0 && steps?.length > 0;
  const canEditPublishButton = hasWorkflowAudienceEdit && hasStep && !!startTrigger;
  const isSingleStep = steps?.length === 1;

  return (
    <>
      <Header
        workflowId={workflowId}
        treeData={treeData}
        hasGoals={!!goals?.length}
        hasExits={!!exitConditions?.length}
        audienceData={audienceData}
        canEditPublishButton={canEditPublishButton}
        refetchAudience={refetchAudience}
      />

      <div className="flex h-full bg-slate-50">
        <div className="h-full w-[30%] overflow-y-auto border-r px-4 py-2 xl:w-[20%]">
          <div className="flex flex-col gap-4 pb-56">
            {/* Configuration */}
            <div
              className="flex cursor-pointer justify-between p-2"
              onClick={() => toggleMenu(OVERVIEW_MENU_NAMES.configuration)}
            >
              <span className="text-lg font-medium">{confMenu?.label}</span>
              <Icons icon={confMenu?.expand ? 'downArrow' : 'upArrow'} className="h-7 w-7" />
            </div>

            {confMenu?.expand && (
              <>
                {startTrigger ? (
                  <TriggerCard
                    label={NODE_SHOWN_NAME.rules}
                    testHook="startTrigger"
                    trigger={startTrigger}
                    onClick={selectItem(NodeTypes.START_TRIGGER)}
                    selectedTrigger={selectedTrigger}
                  />
                ) : (
                  <Notification className="mb-6" kind="information">
                    {i18n.t('workflow:overview.empty.startTrigger')}
                  </Notification>
                )}

                {steps?.map(step => (
                  <StepCard
                    testHook={step?.label}
                    key={step.nodeId}
                    step={step}
                    onClick={selectItem(NodeTypes.STEP)}
                    selectedStepId={selectedStepId}
                    label={isSingleStep ? NODE_SHOWN_NAME.connectors : step.label}
                  />
                ))}
              </>
            )}

            <hr className="my-2 text-gray-600" />
            {/* Goals */}
            <div
              className="flex cursor-pointer justify-between p-2"
              onClick={() => toggleMenu(OVERVIEW_MENU_NAMES.goals)}
            >
              <span className="text-lg font-medium">{goalsMenu?.label}</span>
              <Icons icon={goalsMenu?.expand ? 'downArrow' : 'upArrow'} className="h-7 w-7" />
            </div>

            {goalsMenu?.expand && (
              <>
                {(!goals || goals?.length === 0) && (
                  <Notification className="mb-6" kind="information">
                    {i18n.t('workflow:overview.empty.goals')}
                  </Notification>
                )}
                {goals?.map(goal => (
                  <TriggerCard
                    key={goal.nodeId}
                    trigger={goal}
                    onClick={selectItem(NodeTypes.GOAL)}
                    selectedTrigger={selectedTrigger}
                    label={goal?.label}
                    testHook="goals"
                  />
                ))}
              </>
            )}

            <hr className="my-2 text-gray-600" />
            {/* Exit conditions */}
            <div
              className="flex cursor-pointer justify-between p-2"
              onClick={() => toggleMenu(OVERVIEW_MENU_NAMES.exit)}
            >
              <span className="text-lg font-medium">{exitMenu?.label}</span>
              <Icons icon={exitMenu?.expand ? 'downArrow' : 'upArrow'} className="h-7 w-7" />
            </div>

            {exitMenu?.expand && (
              <>
                {(!exitConditions || exitConditions?.length === 0) && (
                  <Notification className="mb-6" kind="information">
                    {i18n.t('workflow:overview.empty.exit')}
                  </Notification>
                )}
                {exitConditions?.map(exitCondition => (
                  <TriggerCard
                    key={exitCondition.nodeId}
                    trigger={exitCondition}
                    onClick={selectItem(NodeTypes.EXIT_BY_CONDITION)}
                    selectedTrigger={selectedTrigger}
                    label={exitCondition?.label}
                    testHook="exitConditions"
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="w-[50%] overflow-y-auto p-10 xl:w-[60%]">
          {/* Generic error message */}
          {(!startTrigger || !hasStep) && (
            <Notification className="mb-6 " kind="warning">
              <div className="flex items-center justify-between">
                <span>{i18n.t('workflow:overview.empty.audienceError.message')}</span>
                <Btn
                  color="gray"
                  testHook="configureStep"
                  onClick={() => {
                    navigate(buildUrl(`workflows/edit/${workflowId}?step=${!startTrigger ? '1' : '3'}`));
                  }}
                >
                  {i18n.t('workflow:overview.empty.audienceError.action')}
                </Btn>
              </div>
            </Notification>
          )}
          {selectedTrigger && <TriggerDetails triggerId={selectedTrigger} type={selectedType} />}
          {selectedStepId && <StepDetails stepId={selectedStepId} />}
        </div>
      </div>
    </>
  );
};

export default Overview;
