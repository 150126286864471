import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useAPI } from '~/common';
import { CHART_COLORS } from '~/common/chart';
import { Chart, DateRangePicker } from '~/components';
import { Panel } from '~/components/src/Containers';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { fetchActivityStats } from '~/workflows/dataService';
import { TActivityStatsProps } from '~/workflows/types';
import StepChart from './StepChart';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};
const ActivityStats = ({ workflowId, allSteps }: TActivityStatsProps) => {
  const [dateRange, setDateRange] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const { startDate, endDate } = dateRange;

  const { data: activityStats, isLoading } = useAPI(
    () =>
      fetchActivityStats(
        workflowId,
        startDate.startOf('day').toDate().getTime(),
        endDate.endOf('day').toDate().getTime(),
      ),
    [workflowId, startDate, endDate],
  );

  const handleDatesChange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
    setDateRange({
      startDate,
      endDate,
    });
  };

  const { series = [], timestamp = [] } = activityStats || {};
  const hasSteps = allSteps && allSteps.length > 0;

  return (
    <div className="flex flex-col gap-2 pt-4">
      <Panel className="mt-7">
        <div className="flex justify-end py-2">
          <DateRangePicker
            testHook="activityDatePicker"
            startDate={startDate}
            endDate={endDate}
            handleDatesChange={handleDatesChange}
          />
        </div>

        {isLoading ? (
          <Spin />
        ) : (
          <Chart
            chartType="line"
            series={[
              {
                color: CHART_COLORS.BLUE,
                name: i18n.t('workflow:stats.activity.profilesActive'),
                data: series || [],
              },
            ]}
            legend={{
              layout: 'horizontal',
              align: 'left',
              verticalAlign: 'bottom',
            }}
            xAxisLabels={timestamp}
            noDataMessage={i18n.t('workflow:stats.activity.noData')}
          />
        )}
      </Panel>

      {hasSteps && <StepChart workflowId={workflowId} allSteps={allSteps} />}
    </div>
  );
};

export default ActivityStats;
