import React, { ReactElement } from 'react';
import { useApiWithState } from '~/common';
import { exportCSVFile } from '~/common/utils/ExportToFile';
import { Heading } from '~/components';
import Alert, { AlertType } from '~/components/src/Alert';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { formattedCSVData, headers } from './csvExport';
import { fetchTransformerErrors } from './dataServices';
import ErrorsList from './ErrorsList';
import { TransformationErrorsProps } from './types';

function TransformationErrors({ connectorId }: TransformationErrorsProps): ReactElement {
  const {
    state: data,
    isLoading,
    error,
    refetch: refetchErrors,
  } = useApiWithState(() => fetchTransformerErrors(connectorId));

  return (
    <Panel className="mt-4">
      <Heading
        title={i18n.t('connectors:errorStats.title')}
        kind="h3"
        className="Connector-statsHeading u-paddingTopNone"
        testHook="errorHeading"
      >
        <BtnIcon
          icon="refresh"
          testHook="refreshErrors"
          onClick={() => refetchErrors()}
          tooltip={i18n.t('connectors:errorStats.refresh')}
        />
        {data && data?.length > 0 && (
          <BtnIcon
            className="ml-4"
            icon="download"
            tooltip={i18n.t('connectors:errorStats.download')}
            onClick={() => exportCSVFile(headers, formattedCSVData(data), 'errors')}
          />
        )}
      </Heading>
      {error && (
        <Alert
          testHook="apiErrorAlert"
          type={AlertType.DANGER}
          suffix={error.message || i18n.t('common:errors.genericError')}
        />
      )}
      {isLoading && <Spin />}
      {!isLoading && !error && data && <ErrorsList data={data} />}
    </Panel>
  );
}

export default TransformationErrors;
