const getValue = (variables: any) => {
  if (Array.isArray(variables)) {
    return variables.join(', ');
  }

  const value = variables.value || variables.values;
  return Array.isArray(value) ? value.toString().replace(/,/g, ', ') : value;
};

export const getProfileVariables = (variables: any) => {
  const storedVariableNames = Object.keys(variables);
  return storedVariableNames.map(key => `${key}: ${getValue(variables[key])}`);
};
