import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Page } from '~/components';
import i18n from '~/i18n';
import { buildUrl } from '~/common';
import Counters from '../stats/components/Counters';

const TagCounterStats = () => {
  const params = useParams();
  const counterIds = params.counterIds || '';

  const crumbs = [
    {
      title: i18n.t('tagCounters:stats.backTo'),
      pathname: buildUrl('tagmanagement/tagCounters/list'),
    },
  ];
  return (
    <Page>
      <Heading crumbs={crumbs} title={i18n.t('tagCounters:stats.title')}></Heading>
      <Counters counterIds={counterIds} withBulkIds />
    </Page>
  );
};

export default TagCounterStats;
