export const ENVIRONMENTS_FETCH_START = 'app/admin/environment/ENVIRONMENTS_FETCH_START';
export const ENVIRONMENTS_FETCH_SUCCESS = 'app/admin/environment/ENVIRONMENTS_FETCH_SUCCESS';
export const ENVIRONMENTS_FETCH_FAIL = 'app/admin/environment/ENVIRONMENTS_FETCH_FAIL';

export const ENVIRONMENT_UPDATE_START = 'app/admin/environment/ENVIRONMENT_UPDATE_START';
export const ENVIRONMENT_UPDATE_SUCCESS = 'app/admin/environment/ENVIRONMENT_UPDATE_SUCCESS';
export const ENVIRONMENT_UPDATE_FAIL = 'app/admin/environment/ENVIRONMENT_UPDATE_FAIL';

export const ENVIRONMENT_SAVE_START = 'app/admin/environment/ENVIRONMENT_SAVE_START';
export const ENVIRONMENT_SAVE_SUCCESS = 'app/admin/environment/ENVIRONMENT_SAVE_SUCCESS';
export const ENVIRONMENT_SAVE_FAIL = 'app/admin/environment/ENVIRONMENT_SAVE_FAIL';

export const ENVIRONMENT_DELETE_START = 'app/admin/environment/ENVIRONMENT_DELETE_START';
export const ENVIRONMENT_DELETE_SUCCESS = 'app/admin/environment/ENVIRONMENT_DELETE_SUCCESS';
export const ENVIRONMENT_DELETE_FAIL = 'app/admin/environment/ENVIRONMENT_DELETE_FAIL';

export const CANTDELETE_FETCH_START = 'app/admin/environment/CANTDELETE_FETCH_START';
export const CANTDELETE_FETCH_SUCCESS = 'app/admin/environment/CANTDELETE_FETCH_SUCCESS';
export const CANTDELETE_FETCH_FAIL = 'app/admin/environment/CANTDELETE_FETCH_FAIL';
