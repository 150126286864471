export const AUDIENCE_FETCH_ERASE = 'app/profiles/audiences/AUDIENCE_FETCH_ERASE';
export const AUDIENCE_FETCH_START = 'app/profiles/audiences/AUDIENCE_FETCH_START';
export const AUDIENCE_FETCH_FAIL = 'app/profiles/audiences/AUDIENCE_FETCH_FAIL';
export const AUDIENCE_FETCH_SUCCESS = 'app/profiles/audiences/AUDIENCE_FETCH_SUCCESS';

export const AUDIENCES_TREE_FETCH_START = 'app/profiles/audiences/AUDIENCES_TREE_FETCH_START';
export const AUDIENCES_TREE_FETCH_FAIL = 'app/profiles/audiences/AUDIENCES_TREE_FETCH_FAIL';
export const AUDIENCES_TREE_FETCH_SUCCESS = 'app/profiles/audiences/AUDIENCES_TREE_FETCH_SUCCESS';

export const HISTORIC_PROCESSING_START = 'app/profiles/audiences/HISTORIC_PROCESSING_START';
export const HISTORIC_PROCESSING_FAIL = 'app/profiles/audiences/HISTORIC_PROCESSING_FAIL';
export const HISTORIC_PROCESSING_SUCCESS = 'app/profiles/audiences/HISTORIC_PROCESSING_SUCCESS';

export const AUDIENCE_REMOVE_START = 'app/profiles/audiences/AUDIENCE_REMOVE_START';
export const AUDIENCE_REMOVE_FAIL = 'app/profiles/audiences/AUDIENCE_REMOVE_FAIL';
export const AUDIENCE_REMOVE_SUCCESS = 'app/profiles/audiences/AUDIENCE_REMOVE_SUCCESS';

export const AUDIENCE_CLONE_START = 'app/profiles/audiences/AUDIENCE_CLONE_START';
export const AUDIENCE_CLONE_FAIL = 'app/profiles/audiences/AUDIENCE_CLONE_FAIL';
export const AUDIENCE_CLONE_SUCCESS = 'app/profiles/audiences/AUDIENCE_CLONE_SUCCESS';

export const SELECTED_AUDIENCES = 'app/profiles/audiences/SELECTED_AUDIENCES';

export const FULL_AUDIENCES_FETCH_START = 'app/profiles/audiences/FULL_AUDIENCES_FETCH_START';
export const FULL_AUDIENCES_FETCH_SUCCESS = 'app/profiles/audiences/FULL_AUDIENCES_FETCH_SUCCESS';
export const FULL_AUDIENCES_FETCH_FAIL = 'app/profiles/audiences/FULL_AUDIENCES_FETCH_FAIL';

export const AUDIENCE_SAVE_START = 'app/profiles/audiences/AUDIENCE_SAVE_START';
export const AUDIENCE_SAVE_FAIL = 'app/profiles/audiences/AUDIENCE_SAVE_FAIL';
export const AUDIENCE_SAVE_SUCCESS = 'app/profiles/audiences/AUDIENCE_SAVE_SUCCESS';

export const AUDIENCE_UPDATE_START = 'app/profiles/audiences/AUDIENCE_SAVE_START';
export const AUDIENCE_UPDATE_FAIL = 'app/profiles/audiences/AUDIENCE_SAVE_FAIL';
export const AUDIENCE_UPDATE_SUCCESS = 'app/profiles/audiences/AUDIENCE_SAVE_SUCCESS';
