import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl } from '~/common';
import Btn from '~/components/src/Btn';
import Link from '~/components/src/Link';
import Spin from '~/components/src/Spin';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { JourneyDependantsProps } from './Journey/components/types';
import { DependantJourney } from './Journey/types';
import { WORKFLOW_TYPE } from '../constants';

const columnHelper = createColumnHelper<DependantJourney>();

const baseUrl = 'workflows';

const getWorkflowUrl = (type: string, id: string) =>
  buildUrl(`${baseUrl}/${type === WORKFLOW_TYPE.Audience ? 'view' : 'edit'}/${id}`);

const getTriggerUrl = (type: string, id: string, triggerId: string) => {
  const url =
    type === WORKFLOW_TYPE.Audience
      ? `${baseUrl}/view/${id}?nodeId=${triggerId}`
      : `${baseUrl}/edit/${id}/triggerEdit/${triggerId}`;
  return buildUrl(url);
};

export const WorkflowDependants = ({ list, hideModal }: JourneyDependantsProps) => {
  const getColumns = () => [
    columnHelper.accessor('journeyName', {
      header: () => <div>{i18n.t('dependants:list.journeyHeader')}</div>,
      cell: ({ getValue, row }) => (
        <Link
          className="JourneyDependancy-link"
          href={getWorkflowUrl(row.original.workflowType.name, row.original.journeyId)}
          onClick={hideModal}
          testHook="journeyLink"
        >
          {getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('triggerName', {
      header: () => <div>{i18n.t('dependants:list.triggerHeader')}</div>,
      cell: ({ getValue, row }) => {
        const { workflowType, journeyId, triggerId } = row.original;

        return (
          <Link
            className="JourneyDependancy-link"
            href={getTriggerUrl(workflowType.name, journeyId, triggerId)}
            onClick={hideModal}
            testHook="triggerLink"
          >
            {getValue()}
          </Link>
        );
      },
    }),
  ];

  if (!list) {
    return <Spin />;
  }

  return (
    <>
      <UITable
        data={list ?? []}
        testHook="dependantsListTable"
        emptyMessage={i18n.t('workflow:list.empty.header')}
        emptyBody={i18n.t('workflow:list.empty.body')}
        columns={getColumns()}
      />
      <div className="flex justify-end pt-2">
        <Btn onClick={hideModal}>{i18n.t('common:close')}</Btn>
      </div>
    </>
  );
};

export default WorkflowDependants;
