export default {
  data: {
    nodes: {
      nodeId: 'e3636378-78a3-4c46-a395-4a8791082364',
      type: 'START_TRIGGER',
      label: 'Visit customer app',
      children: [
        {
          nodeId: 'ef49d7d3-3e6c-4cdc-964b-4a4b6ab11553',
          type: 'STEP',
          label: 'Target via social media',
          children: [
            {
              nodeId: '7ed532bf-8506-4419-b65e-f83a58080e63',
              type: 'TRIGGER',
              label: 'Trip detail view',
              children: [
                {
                  nodeId: '7da53ad4-4aa8-4fee-bcc8-e7f3b8db98f6',
                  type: 'STEP',
                  label: 'Trip Personalisation',
                  hasTransition: true,
                  children: [
                    {
                      nodeId: 'dacbb333-fabf-411b-8fb6-74ae893224a7',
                      type: 'TRIGGER',
                      label: 'Skip',
                      children: [
                        {
                          nodeId: '79443888-15c8-44fd-9cbf-6e1650beb6b7',
                          type: 'STEP',
                          label: 'Reached Booking',
                          children: [
                            {
                              nodeId: '7a83f0b3-6be3-4989-8b54-56dd1e003a71',
                              type: 'TRIGGER',
                              label: 'Set User Preferences',
                              children: [
                                {
                                  nodeId: 'c4f80842-892e-47a5-9a54-906a7a7352bc',
                                  type: 'STEP',
                                  label: 'Existing customer',
                                  hasTransition: true,
                                  children: [
                                    {
                                      nodeId: '34c78b12-e09b-4362-bfc6-1a759e9e561a',
                                      type: 'TRIGGER',
                                      label: 'Return Booking',
                                      hasTransition: true,
                                      transitionNodeId: '7da53ad4-4aa8-4fee-bcc8-e7f3b8db98f6',
                                      children: [],
                                    },
                                  ],
                                  experimentVariant: {
                                    experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
                                    name: 'Target via social media Variant',
                                    weight: 100,
                                  },
                                  stats: {
                                    profilesInStep: 0,
                                  },
                                  hasVariableModifications: false,
                                },
                              ],
                            },
                          ],
                          experimentVariant: {
                            experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
                            name: 'Target via social media Variant',
                            weight: 100,
                          },
                          stats: {
                            profilesInStep: 0,
                          },
                          hasVariableModifications: false,
                        },
                      ],
                    },
                    {
                      nodeId: 'ecd872da-167c-4dcb-ae6e-227497268e05',
                      type: 'TRIGGER',
                      label: 'Business Booking',
                      hasTransition: true,
                      transitionNodeId: 'c4f80842-892e-47a5-9a54-906a7a7352bc',
                      children: [],
                    },
                  ],
                  experimentVariant: {
                    experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
                    name: 'Target via social media Variant',
                    weight: 100,
                  },
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
            },
            {
              nodeId: '5098625b-562d-4e9a-8ef7-1956c1685dc4',
              type: 'TRIGGER',
              label: 'Wait 30 min',
              children: [
                {
                  nodeId: '8bd5227e-d305-4093-90c9-754dd5783bc1',
                  type: 'STEP',
                  label: 'Retarget via other channels',
                  children: [
                    {
                      nodeId: '8871660f-eb55-49bd-a760-8eff7bffcfed',
                      type: 'TRIGGER',
                      label: 'My Trigger',
                      children: [],
                    },
                  ],
                  experimentVariant: {
                    experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
                    name: 'Target via social media Variant',
                    weight: 100,
                  },
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
            },
          ],
          experimentVariant: {
            experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
            name: 'Target via social media Variant',
            weight: 100,
          },
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
    },
    goals: [],
    profilesEnteredJourney: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      variantStats: [
        {
          experimentVariantId: '3fc2a331-f4fe-4359-a732-9f708690ecc0',
          name: 'Target via social media Variant',
          profilesRemoved: 0,
          profilesExpired: 0,
        },
      ],
    },
  },
  workflowType: {
    name: 'JOURNEY',
    label: 'Journey',
  },
  status: {
    success: true,
  },
};
