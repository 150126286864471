import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import PickerView from '~/components/src/PickerView';
import {
  getAllTransformations,
  getLinkedTransformations,
  getTransformation,
} from '~/profiles/pages/Transformations/dataService';
import { TransformationType } from '~/profiles/pages/Transformations/types';
import LinkedTransformationSidePanel from './LinkedTransformationSidePanel';
import ItemsPickerContent from './ItemsPickerContent';
import constants from './constants';

interface LinkedTransformationsProps {
  deleteLinkedTransformation: (transformationId: string) => void;
  linkedTransformations: TransformationType[];
  setLinkedTransformations: (transformations: TransformationType[]) => void;
  setActiveSidePanel: (panel: string | null) => void;
  activeSidePanel: string;
}

function LinkedTransformations({
  deleteLinkedTransformation,
  linkedTransformations,
  setLinkedTransformations,
  setActiveSidePanel,
  activeSidePanel,
}: LinkedTransformationsProps): ReactElement {
  const { connectorId } = useParams() as { connectorId: string };
  const { data: allTransformations } = useAPI(() => getAllTransformations());

  useEffect(() => {
    if (connectorId) getLinkedTransformations(connectorId).then(setLinkedTransformations);
  }, []);

  const [isSelectionModalVisible, setIsSelectionModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  return (
    <>
      <div className="flex items-center justify-between py-4">
        <p className="text-xl font-medium text-gray-600">{i18n.t('transformations:list.header')}</p>
        <BtnOutlined
          icon="filter"
          color="blue"
          size="xs"
          onClick={() => {
            setIsSelectionModalVisible(true);
          }}
          testHook="selectTransformations"
        >
          {i18n.t('transformations:form.addTransformations')}
        </BtnOutlined>
      </div>

      {linkedTransformations && linkedTransformations.length ? (
        <div className="flex flex-col gap-2">
          {linkedTransformations.map(transformation => (
            <Panel
              className="flex cursor-pointer items-center justify-between hover:bg-gray-50"
              key={transformation.transformationId}
              onClick={() => {
                setSelectedId(transformation.transformationId);
                setActiveSidePanel(constants.Transformation);
              }}
            >
              <div className="flex items-center gap-2">
                <Icons icon="filter" className="h-8 w-8 rounded bg-gray-100 p-1 text-gray-600" />
                <p className="text-gray-600">{transformation.name}</p>
              </div>
              <BtnIcon
                icon="delete"
                tooltip={i18n.t('common:delete')}
                onClick={event => {
                  /* Prevent the side panel from opening */
                  event.stopPropagation();
                  deleteLinkedTransformation(transformation.transformationId);
                }}
              />
            </Panel>
          ))}
        </div>
      ) : (
        <Notification kind="information">{i18n.t('transformations:messages.noLinkedTransformations')}</Notification>
      )}

      {isSelectionModalVisible && linkedTransformations && (
        <PickerView
          pickerTitle={i18n.t('transformations:form.addTransformations')}
          handlePickerVisibility={setIsSelectionModalVisible}
          className={cx('!max-h-[80%] !w-[500px]', {
            '!h-fit': !(allTransformations && allTransformations?.length > 0),
          })}
        >
          <ItemsPickerContent
            allItems={allTransformations || []}
            items={linkedTransformations}
            idKey="transformationId"
            hideModal={() => setIsSelectionModalVisible(false)}
            onSave={setLinkedTransformations}
            getItemDetails={getTransformation}
          />
        </PickerView>
      )}

      {selectedId && activeSidePanel === 'Transformation' && (
        <LinkedTransformationSidePanel
          selectedId={selectedId}
          handleClose={() => {
            setSelectedId('');
            setActiveSidePanel(null);
          }}
        />
      )}
    </>
  );
}

export default LinkedTransformations;
