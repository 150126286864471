import React from 'react';
import { components } from '~/components/src/Table';

const { Row, HeaderCell } = components;

export const renderHeader = t => (
  <thead>
    <Row>
      <HeaderCell rowSpan={2}> {t('list.name')}</HeaderCell>
      <HeaderCell className="u-alignCenter" rowSpan={2}>
        {t('list.size')}
      </HeaderCell>
      <HeaderCell className="u-alignCenter" colSpan={2}>
        {t('list.activity1')}
      </HeaderCell>
      <HeaderCell className="u-alignCenter" colSpan={2}>
        {t('list.activity7')}
      </HeaderCell>
      <HeaderCell className="u-alignCenter" colSpan={2}>
        {t('list.activity30')}
      </HeaderCell>
      <HeaderCell className="u-alignCenter" rowSpan={2}>
        {t('list.usedIn')}
      </HeaderCell>
      <HeaderCell rowSpan={2}>&nbsp;</HeaderCell>
    </Row>
    <Row>
      <HeaderCell className="u-alignCenter">{t('list.added')}</HeaderCell>
      <HeaderCell className="u-alignCenter">{t('list.removed')}</HeaderCell>
      <HeaderCell className="u-alignCenter">{t('list.added')}</HeaderCell>
      <HeaderCell className="u-alignCenter">{t('list.removed')}</HeaderCell>
      <HeaderCell className="u-alignCenter">{t('list.added')}</HeaderCell>
      <HeaderCell className="u-alignCenter">{t('list.removed')}</HeaderCell>
    </Row>
  </thead>
);
