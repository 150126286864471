import React, { ReactElement, useState } from 'react';

import moment from 'moment';

import i18n from '~/i18n';
import { useAPI } from '~/common';
import { Empty } from '~/components';
import Spin from '~/components/src/Spin';
import BtnIcon from '~/components/src/BtnIcon';

import { AuditLogProps, AuditLogType } from '../types';
import { fetchAuditLogByModelId } from '../dataService';
import { DATE_FORMAT, DURATION_FORMAT, MODEL_STATUS, TIME_FORMAT } from '../constants';

const EmptyList = () => (
  <Empty
    className="flex w-full"
    header={i18n.t('ai:model.auditLog.emptyHeader')}
    body={i18n.t('ai:model.auditLog.emptyBody')}
  />
);

const getRunTime = (log: AuditLogType, index: number, logs: AuditLogType[]) => {
  switch (log.status.name.toLowerCase()) {
    case MODEL_STATUS.CREATED.toLowerCase():
    case MODEL_STATUS.TRAINED.toLowerCase():
    case MODEL_STATUS.DEPLOYED.toLowerCase(): {
      if (typeof logs[index + 1] === 'undefined') {
        return '';
      }
      const duration = moment(log.timestamp)
        .utc()
        .diff(moment(logs[index + 1].timestamp).utc());
      return (
        <>
          <span className="text-gray-400">{i18n.t('ai:model.auditLog.runTime')}:</span>
          <span> {moment(duration).utc().format(DURATION_FORMAT)}</span>
        </>
      );
    }
    default:
      return '';
  }
};

function AuditLog({ selectedModelId, handleClose }: AuditLogProps): ReactElement {
  if (!selectedModelId) return <></>;

  const [pageIndex, setPageIndex] = useState<number>(0);
  const { data: auditLog, isLoading } = useAPI(() => fetchAuditLogByModelId(selectedModelId, pageIndex), [pageIndex]);

  return (
    <div
      className="fixed left-0 top-16 z-[2] flex h-full w-full justify-end bg-gray-400/50"
      onClick={() => handleClose()}
    >
      <div
        className="h-full w-1/3 overflow-y-auto border-l border-gray-200 bg-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="sticky top-0">
          <div className="fixed z-20 flex w-[31%] items-center justify-between border-b border-gray-200 bg-white bg-white p-6">
            <div className="flex flex-col gap-2">
              <p className="text-gray-400">{i18n.t('ai:model.auditLog.title')}</p>
              <p className="t-auditLogModelName text-xl font-medium text-gray-600">{auditLog?.modelName}</p>
            </div>
            <BtnIcon
              icon="close"
              className="z-20 h-6 w-6"
              tooltip={i18n.t('common:actions.close')}
              onClick={() => handleClose()}
              testHook="closeAuditLog"
            />
          </div>
          {isLoading || !auditLog ? (
            <div className="relative top-96 z-20">
              <Spin />
            </div>
          ) : (
            <div className="relative flex items-center gap-2 p-6 py-32">
              {auditLog?.empty ? (
                <EmptyList />
              ) : (
                <div className="flex w-full flex-col">
                  {auditLog?.logs.map((log, index) => (
                    <div key={index} className="flex w-full border-b last:border-hidden">
                      <div>
                        <div
                          className={`AuditLog-statusCircle AuditLog-statusCircle--${log.status.name.toLowerCase()}`}
                        ></div>
                        {index < auditLog?.logs.length - 1 && <hr className="relative h-full w-0.5 bg-gray-300" />}
                      </div>

                      <div className="t-auditLogRow flex w-full flex-col p-2">
                        <p className="flex justify-between py-1">
                          <span
                            className={`t-auditLogStatus AuditLog-status--${log.status.name.toLowerCase()} text-lg`}
                          >
                            {log.status.label}
                          </span>
                          <span className="t-auditLogTime">
                            {moment(log.timestamp).format(DATE_FORMAT)}{' '}
                            <span className="text-sm text-r42-blue">{moment(log.timestamp).format(TIME_FORMAT)}</span>
                          </span>
                        </p>
                        {log.status.label.toLowerCase() === MODEL_STATUS.ERROR.toLowerCase() ? (
                          <p className="t-auditMessage text-red-500">{log.message}</p>
                        ) : (
                          <p className="flex justify-between gap-1">
                            <span className="t-runTime">{getRunTime(log, index, auditLog?.logs)}</span>
                            <span className="t-user"> {log.user}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {!auditLog?.empty && !isLoading && (
            <div className="fixed bottom-0 z-20 h-14 w-1/3 border-t bg-white">
              <div className="flex items-center justify-center gap-2 p-2">
                <BtnIcon
                  icon={auditLog?.first ? 'remove' : 'cheveronLeft'}
                  disabled={auditLog?.first}
                  onClick={() => setPageIndex((auditLog?.number || 0) - 1)}
                  tooltip={i18n.t('common:pagination.previous')}
                  testHook="previous"
                />
                <span className="text-gray-500">
                  {(auditLog?.number || 0) + 1}/ {auditLog?.totalPages}
                </span>
                <BtnIcon
                  icon={auditLog?.last ? 'remove' : 'cheveronRight'}
                  disabled={auditLog?.last}
                  onClick={() => setPageIndex((auditLog?.number || 0) + 1)}
                  tooltip={i18n.t('common:pagination.next')}
                  testHook="next"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuditLog;
