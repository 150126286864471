import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';

const Card = ({ children, className, isActive = false }) => (
  <div
    className={cx('Card', className, {
      'is-active': isActive,
    })}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export default Card;
