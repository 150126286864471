import { combineReducers } from 'redux';
import cookies from './cookies';
import messages from './messages';
import tags from './tagtemplates';

export * from './messages';

// Default Support Reducer
export default combineReducers({
  cookies,
  messages,
  tags,
});
