import { changeUrl } from '~/common';
import * as types from '../types';
import * as selectors from '../selectors';
import CookiesVariantsService from '../dataService';

/**
 * Fetch cookie permission template
 * @param variantId
 */
export const fetchVariant = variantId => dispatch => {
  dispatch({
    type: types.COOKIE_FETCH_START,
    payload: variantId,
  });

  return CookiesVariantsService.getVariant(variantId)
    .then(payload => {
      dispatch({
        type: types.COOKIE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.COOKIE_FETCH_FAIL, error }));
};

/**
 * Fetch cookie permission template with clients only if not in store
 * @param variantId
 */
export const fetchVariantWithClientsIfNeeded = variantId => (dispatch, getState) => {
  if (!variantId) return false;
  if (selectors.getClientsForVariant(getState(), variantId).length) {
    return Promise.resolve();
  }
  return dispatch(fetchVariant(variantId));
};

/**
 * Create or Edit (update) cookie permission template with passed values
 * @param values
 */
export const saveVariant = values => dispatch => {
  const ApiCall = values.cookiePermissionVariantId
    ? CookiesVariantsService.editVariant
    : CookiesVariantsService.createVariant;

  dispatch({
    type: types.SAVE_START,
  });

  return ApiCall(values)
    .then(newVariantId => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: {
          cookiePermissionVariantId: newVariantId,
          ...values,
        },
      });

      // Go to the list view
      changeUrl('support/cookiepermissionforms/list');
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};
