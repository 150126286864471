import React from 'react';
import i18n from '~/i18n';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification } from '~/components';
import { UITable } from '~/components/src/UITable';
import { TMissedEvent } from './types';

const columnHelper = createColumnHelper<TMissedEvent & { id: string }>();

interface MissedEventsListProps {
  missedEvents: TMissedEvent[];
  canEditProfiles: boolean;
  eventType: string;
}

const MissedEventsList: React.FC<MissedEventsListProps> = ({ missedEvents, eventType }) => {
  const columns = [
    columnHelper.accessor('id', {
      header: () => <div className="text-left">{i18n.t('customerFacts:missedEventsTable.number')}</div>,
      cell: info => <div className="text-left">{info.row.index + 1}</div>,
      size: 50,
    }),
    columnHelper.accessor('eventName', {
      header: () => <div className="text-left">{i18n.t('customerFacts:missedEventsTable.eventName')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('eventActivity', {
      header: () => <div className="text-right">{i18n.t('customerFacts:missedEventsTable.eventActivity')}</div>,
      cell: info => <div className="text-right">{info.getValue()}</div>,
    }),
  ];

  return (
    <div className="flex flex-col gap-2">
      <Notification kind="error" className="mt-2 py-3">
        {i18n.t('customerFacts:messages.missingEventsText', { eventType })}
      </Notification>

      <UITable
        data={missedEvents ?? []}
        columns={columns}
        testHook="missedEventsTable"
        emptyMessage={i18n.t('customerFacts:missedEventsTable.empty.header', { eventType })}
        emptyBody={i18n.t('customerFacts:missedEventsTable.empty.body', { eventType })}
        emptyClassName="mt-0"
      />
    </div>
  );
};

export default MissedEventsList;
