angular.module('webUi.filter.sortBy', [])

    .filter('sortBy', ['$filter', function($filter) {
        return function(input, sortHandle) {
        
            if(!sortHandle) {
                return input;
            }
        
            return $filter('orderBy')(input, sortHandle.field, sortHandle.reverse);
        };
    }]);
