/**
 * @description Angular main module for Profiles -> Filters -> Base (left)
 * @memberOf webUi.ui.profiles.filters
 * @namespace webUi.ui.profiles.filters.base
 */
angular.module('webUi.ui.profiles.filters.base',
    [
        'webUi.ui.profiles.base'
    ]
)
    /**
    *
    */
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.filters', {
                url: '/filters',
                isReact: true,
            });
    }]);