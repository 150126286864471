import React from 'react';
import * as Yup from 'yup';
import { Field, Formik, useFormikContext } from 'formik';
import { showSuccess } from '~/notificationCenter';
import { PickerView } from '~/components';
import i18n from '~/i18n';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import { createUserList } from './dataService';
import { isMobileAdvertiserID } from './utils';
import { AddUserListModalProps, TCreateUserList } from './types';

const AddUserListModal = ({ refetchUserLists, hideModal, payloadType, partnerId }: AddUserListModalProps) => {
  const { setFieldValue } = useFormikContext();
  const isMobile = isMobileAdvertiserID(payloadType);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('validation:validation.required')),
    description: Yup.string().required(i18n.t('validation:validation.required')),
    appId: Yup.string().when([], {
      is: () => isMobile,
      then: Yup.string().required(i18n.t('validation:validation.required')),
      otherwise: Yup.string(),
    }),
  });

  const initialValues = {
    name: '',
    description: '',
    appId: '',
  };

  const handleCreateUserList = async (values: TCreateUserList) => {
    const payload: TCreateUserList = {
      name: values.name,
      description: values?.description,
      ...(isMobile ? { appId: values.appId } : {}),
    };
    const { name, id } = await createUserList(payload, partnerId);

    setFieldValue('userListId', id);
    setFieldValue('userListName', name);
    refetchUserLists();
    hideModal();
    showSuccess({
      body: i18n.t('audiences:connectors.googleCustomerMatch.userAdd.success'),
    });
  };

  return (
    <PickerView
      className="!h-auto !w-1/2 lg:!w-1/3 xl:!w-1/4"
      pickerTitle={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.header')}
    >
      <Formik onSubmit={handleCreateUserList} validationSchema={validationSchema} initialValues={initialValues}>
        {({ handleSubmit, errors, isSubmitting }) => (
          <div className="t-userListForm flex flex-col gap-2">
            <div className="my-2 flex items-center gap-1">
              <span className="font-bold">{i18n.t('audiences:connectors.googleCustomerMatch.userAdd.type')}</span>
              <span className="t-payloadType text-gray-500">{payloadType}</span>
            </div>
            <Field
              as={FormikInputField}
              name="name"
              label={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.name')}
              placeholder={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.placeholder')}
              errorText={errors.name}
            />
            <Field
              as={FormikInputField}
              name="description"
              label={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.description')}
              placeholder={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.placeholder')}
              errorText={errors.description}
            />
            {isMobile && (
              <Field
                as={FormikInputField}
                name="appId"
                label={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.appId')}
                placeholder={i18n.t('audiences:connectors.googleCustomerMatch.userAdd.placeholder')}
                errorText={errors.appId}
              />
            )}
            <ActionButtons
              testHook="createGCMUser"
              className="!mr-0 mt-2 !pt-0"
              isConfirmEnabled={!isSubmitting}
              onConfirm={handleSubmit}
              onDecline={hideModal}
            />
          </div>
        )}
      </Formik>
    </PickerView>
  );
};

export default AddUserListModal;
