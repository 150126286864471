import React, { useState } from 'react';

import moment, { Moment } from 'moment';
import i18n from '~/i18n';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import DateValidator from '~/components/src/DateValidator';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import DateTimeElement from '~/components/src/Form/Elements/DateTimeElement';
import { TElement } from '../types';
import './styles.scss';

const DATE_ON_FORMAT = 'YYYY-MM-DD';

export const DateOnInput = ({ onChange, onBlur, errorText, filter, value, validateForm }: TElement) => {
  const [showDateFormatModal, setShowDateFormatModal] = useState(false);

  const dateValue = moment(value, DATE_ON_FORMAT, true).isValid() ? moment(value) : value;

  const onModalChange = (value: string) => {
    onChange(value, 'dateFormat');
    setShowDateFormatModal(false);
  };

  const handleOnchange = (value: string | Moment) => {
    if (typeof value === 'string') {
      onChange(value);
    } else {
      const formatString = moment(value).format(DATE_ON_FORMAT);
      onChange(formatString);
    }

    setTimeout(() => {
      validateForm();
    }, 200);
  };

  const ModalContents = ({ dateFormat }: { dateFormat: string }) => (
    <DateValidator initialValue={dateFormat} onChange={value => onModalChange(value)} />
  );

  return (
    <>
      <div className="DateFormat-Container">
        <FieldWrapper errorText={errorText}>
          <DateTimeElement
            className="w-full"
            name="dateValue"
            dateFormat={DATE_ON_FORMAT}
            timeFormat={false}
            value={dateValue}
            onChange={handleOnchange}
            hasError={!!errorText}
            onBlur={onBlur}
            placeholder={i18n.t('common:placeholders.dateFormat')}
          />
        </FieldWrapper>

        <BtnIcon
          className="mt-1 h-10 w-10"
          onClick={() => setShowDateFormatModal(true)}
          icon="calendar"
          testHook="dateFormatModalButton"
        />
      </div>
      {showDateFormatModal && (
        <PickerView
          pickerTitle={i18n.t('forms:dateFormat.modal.title')}
          handlePickerVisibility={() => setShowDateFormatModal(false)}
          className="!h-auto"
        >
          <ModalContents dateFormat={filter.dateFormat} />
        </PickerView>
      )}
    </>
  );
};

export default DateOnInput;
