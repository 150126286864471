angular.module('webUi.service.title',
    [
        'restangular',
        'webUi.service.pubSub'
    ]
)
    .factory('TitleService', [
        '$rootScope', '$document', 'PubSubService',
        function ($rootScope, $document, PubSubService) {

            var _currentSiteName;
            var _currentComponent;

            var updateTitle = function() {
                var newTitle = 'Relay42';

                if(typeof _currentSiteName !== 'undefined') {
                    newTitle += ' - ' + _currentSiteName;
                }

                if(typeof _currentComponent !== 'undefined') {

                    var componentName;

                    if(_currentComponent.name.indexOf('site.content') === 0) {
                        componentName = 'Content';
                    } else if(_currentComponent.name.indexOf('site.context') === 0) {
                        componentName = 'Context Admin';
                    } else if(_currentComponent.name.indexOf('site.profiles') === 0) {
                        componentName = 'Profiles';
                    } else if(_currentComponent.name.indexOf('site.admin') === 0) {
                        componentName = 'Admin';
                    } else if(_currentComponent.name.indexOf('site.support') === 0) {
                        componentName = 'Support';
                    } else if(_currentComponent.name.indexOf('site.reporting') === 0) {
                        componentName = 'Reporting';
                    } else if(_currentComponent.name.indexOf('site.tagmanagement') === 0) {
                        componentName = 'TagManagement';
                    } else if(_currentComponent.name.indexOf('site.user') === 0) {
                        componentName = 'User Settings';
                    }

                    if(typeof componentName !== 'undefined') {
                        newTitle += ' - ' + componentName;
                    }
                }

                $document.context.title = newTitle;
            };

            /**
		 * Sets the current site on the securityContext object
		 * @param siteName
		 */
            var setCurrentSite = function(siteName) {
                if(typeof siteName === 'undefined') {
                    return;
                }
                _currentSiteName = siteName;
                updateTitle();
            };

            /**
		 * Get (a promise for) the securityContext object
		 * @param state
		 */
            var updateComponent = function(state) {
                if(typeof state === 'undefined') {
                    return;
                }
                _currentComponent = state;
                updateTitle();
            };

            PubSubService.subscribeSiteSwitch($rootScope, function(event, message) {
                if (message.name) {
                    setCurrentSite(message.name);
                }
            });

            return {
                setCurrentSite: setCurrentSite,
                updateComponent: updateComponent
            };
        }
    ]);
