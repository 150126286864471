angular.module('webUi.ui.profiles.externalFacts.view',[
    'webUi.ui.profiles.externalFacts.base',
    'ui.bootstrap'
])
/**
 *
 */
    .config([ '$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.externalFacts.view', {
            url : '/view/{externalFactId}',
            isReact: true,
        });
    } ]);
