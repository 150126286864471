import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/i18n';
import { PickerView } from '~/components';
import { showSuccess } from '~/notificationCenter';
import { createBeacon } from './dataService';

type NewBeaconProps = {
  refetchBeacons: () => void;
  setIsPickerVisible: (val: boolean) => void;
};

const initialValues = {
  beaconName: '',
};

const validationSchema = () =>
  Yup.object().shape({
    beaconName: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

function NewBeacon({ refetchBeacons, setIsPickerVisible }: NewBeaconProps): JSX.Element {
  const [isCreatingBeacon, setIsCreatingBeacon] = useState(false);

  const handleCreateBeacon = (values: { beaconName: string }) => {
    setIsCreatingBeacon(true);
    createBeacon(values).then(() => {
      showSuccess({ header: i18n.t('beacons:notifications.beaconCreateSuccess') });
      setIsPickerVisible(false);
      setIsCreatingBeacon(false);
      refetchBeacons();
    });
  };

  return (
    <PickerView
      handlePickerVisibility={setIsPickerVisible}
      pickerTitle={i18n.t('beacons:new')}
      className="!h-auto !w-1/3"
    >
      <Formik onSubmit={handleCreateBeacon} validationSchema={validationSchema()} initialValues={initialValues}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="beaconName"
              as={FormikInputField}
              label={i18n.t('beacons:form.name')}
              placeholder={i18n.t('beacons:form.placeholder')}
              errorText={errors.beaconName}
              autoFocus={true}
            />
            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={() => setIsPickerVisible(false)}
              className="m-0 mt-4"
              confirmText={i18n.t('beacons:form.create')}
              testHook="createBeacon"
              isConfirmEnabled={!isCreatingBeacon}
            />
          </form>
        )}
      </Formik>
    </PickerView>
  );
}

export default NewBeacon;
