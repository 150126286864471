import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Columns Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.COLUMNS_FETCH_SUCCESS:
      return action.payload.reduce((accumulator, currentValue) => {
        if (currentValue.description === null) {
          return {
            ...accumulator,
            [currentValue.columnId]: { ...currentValue, description: '' },
          };
        }
        return {
          ...accumulator,
          [currentValue.columnId]: currentValue,
        };
      }, {});
    case types.COLUMN_SAVE_SUCCESS:
    case types.COLUMN_EDIT_SUCCESS:
      return {
        ...state,
        [action.payload.columnId]: action.payload,
      };
    case types.COLUMN_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Columns Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.COLUMNS_FETCH_SUCCESS:
      return action.payload.map(item => item.columnId);
    case types.COLUMN_SAVE_SUCCESS:
      return [...state, action.payload.columnId];
    case types.COLUMN_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.COLUMNS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
      };
    case types.COLUMNS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
      };
    default:
      return state;
  }
};

const adminColumnReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default adminColumnReducer;
