import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAngularService } from 'ReactAngular/react.service';
import PrivateRoute from '~/components/src/PrivateRoute';
import ClientsPage from './Clients';
import ClientPage from './Client';
import ClientAddPage from './ClientAdd';
import ClientEditPage from './ClientEdit';
import DomainAddEditPage from './DomainAddEdit';
import ClientSitesUsagePage from './ClientSitesUsage';
import PasswordPolicyPage from './PasswordPolicy';

class ClientPages extends Component {
  constructor(props) {
    super(props);
    this.state = { isContextAdmin: false };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isContextAdmin: context.isContextAdmin(),
        isReady: true,
      });
    });
  }

  render() {
    const { isReady, isContextAdmin } = this.state;
    if (isReady) {
      return (
        <Routes>
          <Route path="list" element={<ClientsPage isContextAdmin={isContextAdmin} />} />
          <Route path="add" element={<PrivateRoute authenticated={isContextAdmin} component={ClientAddPage} />} />
          <Route
            path="edit/:clientId"
            element={<PrivateRoute authenticated={isContextAdmin} component={ClientEditPage} />}
          />
          <Route path="view/:clientId" element={<ClientPage isContextAdmin={isContextAdmin} />} />
          <Route
            path="view/:clientId/domain/new"
            element={<PrivateRoute authenticated={isContextAdmin} component={DomainAddEditPage} />}
          />
          <Route
            path="view/:clientId/domain/edit/:clientDomainId"
            element={<PrivateRoute authenticated={isContextAdmin} component={DomainAddEditPage} />}
          />
          <Route path="view/:clientId/sitestats" element={<ClientSitesUsagePage />} />
          <Route
            path="view/:clientId/passwordpolicy"
            element={<PasswordPolicyPage isContextAdmin={isContextAdmin} />}
          />
        </Routes>
      );
    }
    return null;
  }
}

export default ClientPages;
