angular.module('webUi.ui.error.404Unknown', [
    'ui.router',
    'webUi.service'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('404', {
                url : '/site-undefined/error/404',
                resolve : {
                    securityContext : ['SecurityService', '$stateParams', function(SecurityService) {
                        return SecurityService.getSecurityContext();
                    }],
                    prefix : ['SecurityService', function(SecurityService) {
                        return '/site-' + SecurityService.getCurrentSite();
                    }]
                },
                views: {
                    'mainContent': {
                        templateUrl : 'ui/error/404Unknown/404Unknown.tpl.html',
                        controller : 'Error404UnknownController'
                    }
                }
            });
    }])

    .controller('Error404UnknownController', ['$rootScope',
        function Error404UnknownController(    $rootScope) {
            $rootScope.errorPage = true;
        }
    ]);
