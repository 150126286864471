import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isRequired, defaultMaxInput, isValueUnique, removeSpaces } from '~/common';
import FormActions from '~/components/src/Form/ActionsDefault';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import { Notification } from '~/components';

const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.domains.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUnique(value, itemsList);
};
const isDomainUnique = isFormFieldUnique('description');

const SaveDomainForm = props => {
  const { t, handleSubmit, submitting, backHref } = props;
  return (
    <Form onSubmit={handleSubmit} isSubmitting={submitting}>
      <Notification className="mb-4">{t('clients:view.modals.saveDomain.info')}</Notification>
      <Field
        component={ReduxFormInputField}
        name="description"
        label={t('clients:view.modals.saveDomain.input.label')}
        validate={[isRequired, defaultMaxInput, isDomainUnique]}
        placeholder={t('clients:view.modals.saveDomain.input.placeholder')}
        normalize={removeSpaces}
      />
      <FormActions t={t} submitting={submitting} cancelHref={backHref} />
    </Form>
  );
};

SaveDomainForm.propTypes = {
  t: PropTypes.func.isRequired,
  backHref: PropTypes.string.isRequired,
  domains: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
export default SaveDomainForm;
