import { makeIntegration } from '~/workflows/form/Journey/Step/utils';

export const makeIntegrationChangeHandler =
  integrations =>
  ({ changedIntegration, form }) => {
    const updatedIntegrations = integrations.map(integration => {
      if (integration.partnerDetails.partnerId !== changedIntegration.partnerDetails.partnerId) {
        return integration;
      }

      const formikBag = form.connectorFormProps;

      const { partnerName } = changedIntegration;

      const partnerDetails = {
        ...changedIntegration.partnerDetails,
        ...formikBag.values,
      };

      return makeIntegration(
        partnerName,
        partnerDetails,
        integration.supportsEventListeners,
        integration.activated,
        !formikBag.isValid,
      );
    }, []);

    const areIntegrationsValid = !updatedIntegrations.some(integration => integration.hasError);
    return { areIntegrationsValid, integrations: updatedIntegrations };
  };

export const makeIntegrationDeleteHandler = integrations => integrationToDelete => {
  const updatedIntegrations = integrations.filter(
    integration => integration.partnerDetails.partnerId !== integrationToDelete.partnerDetails.partnerId,
  );
  const areIntegrationsValid = !updatedIntegrations.some(integration => integration.hasError);

  return { areIntegrationsValid, integrations: updatedIntegrations };
};

export const makeIntegrationAddHandler = integrations => selectedConnector => {
  const { partnerId, partnerType, partnerNumber, partnerName, supportsEventListeners, activated } = selectedConnector;

  const partnerDetails = {
    partnerId,
    partnerType,
    partnerNumber,
  };

  const newIntegration = makeIntegration(partnerName, partnerDetails, supportsEventListeners, activated);

  const updatedIntegrations = [...integrations, newIntegration];
  const areIntegrationsValid = !updatedIntegrations.some(integration => integration.hasError);

  return { areIntegrationsValid, integrations: updatedIntegrations };
};
