import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Field } from 'formik';
import { useAPI } from '~/common/ApiHooks';
import { ConnectorGenericForm } from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorGenericForm';
import * as fromModals from '~/modals';
import { showSuccess } from '~/notificationCenter/actions';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import { Notification } from '~/components';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { AddTwitterAudienceForm } from './AddTwitterAudienceForm';
import { fetchTwitterAudiences, createTwitterAudience } from './service';

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const openAddTwitterAudienceModal = (createAudience, setFieldValue, hideModal, t) => dispatch => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: t('audiences:connectors.twitter.newAudience'),
      content: (
        <AddTwitterAudienceForm
          createAudience={createAudience}
          setFieldValue={setFieldValue}
          hideModal={hideModal}
          t={t}
        />
      ),
    }),
  );
};

const makeValidationSchema = t =>
  Yup.object().shape({
    audience: Yup.object().required(t('validation:validation.required')),
  });

const Twitter = ({ onEdit, connector, openAddTwitterAudienceModal, hideModal, t, isSubmitted }) => {
  const [twitterAudiences, setTwitterAudiences] = useState([]);

  const { partnerId } = connector.partnerDetails;

  const { data, isLoading, error } = useAPI(() => fetchTwitterAudiences(partnerId));

  useEffect(() => {
    setTwitterAudiences(data || []);
  }, [data]);

  const createAudience = async (name, setFieldValue) => {
    const newlyCreatedAudience = await createTwitterAudience(name, partnerId, t);
    setTwitterAudiences([...twitterAudiences, newlyCreatedAudience]);
    setFieldValue('audience', newlyCreatedAudience);
    showSuccess({
      body: t('common:notifications.created', { name }),
    });
    hideModal();
  };

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      connector={connector}
      validations={makeValidationSchema(t)}
      isSubmitted={isSubmitted}
    >
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <div className="flex items-center gap-4">
              <Field
                name="audience"
                as={FormikSelectField}
                label={t('audiences:connectors.twitter.audience')}
                errorText={formProps.touched.audience && formProps.errors.audience}
                options={twitterAudiences}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isLoading={isLoading}
                testHook="twitterAudiencesDropdown"
                className="flex-1"
              />
              <BtnIcon
                className="mt-3 rounded-full"
                icon="add"
                testHook="addTwitterAudience"
                tooltip={t('audiences:connectors.twitter.createNewAudience')}
                onClick={() => {
                  openAddTwitterAudienceModal(createAudience, formProps.setFieldValue, hideModal, t);
                }}
              />
            </div>
          </SmallWrapperNoOffset>
          {error && (
            <Notification testHook="audiencesFetchingError" kind="error">
              {t('audiences:connectors.twitter.audiencesFetchingError')}
            </Notification>
          )}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { hideModal, openAddTwitterAudienceModal }), translate())(Twitter);
