/**
 * A service to handle CodeMirror defaults and other codemirror related functions
 */
angular.module('webUi.service.codemirror', [])

    .factory('CodeMirrorService', [function(){

        var LEFT = String.fromCharCode(171);
        var RIGHT = String.fromCharCode(187);
        var SEPARATOR = ':';
        var PLACEHOLDER_REGEX = new RegExp('(' + LEFT + '.' + SEPARATOR + '.+?' + RIGHT + ')','g');

        var PLACEHOLDER_CODES = {
            PROPERTY_PLACEHOLDER: 'p',
            EXTRACTED_PLACEHOLDER: 'x'
        };

        var getPlaceholderTitle = function(placeholderCode, placeholderValue){
            var returnVal;
            switch (placeholderCode){
                case PLACEHOLDER_CODES.PROPERTY_PLACEHOLDER:
                    returnVal = 'The placeholder will be replaced by the property with the following name: ' + placeholderValue;
                    break;
                case PLACEHOLDER_CODES.EXTRACTED_PLACEHOLDER:
                    returnVal = 'The placeholder will be replaced with the HTML element with the following css selector: ' + placeholderValue;
                    break;
                default:
                    returnVal = '';
            }
            return returnVal;
        };

        var JAVASCRIPT_KEYWORDS = ['break', 'case', 'catch', 'continue', 'debugger', 'default', 'delete', 'do', 'else', 'false', 'finally', 'for', 'function',
            'if', 'in', 'instanceof', 'new', 'null', 'return', 'switch', 'throw', 'true', 'try', 'typeof', 'var', 'void', 'while', 'with'];

        var STRING_PROPS = ['charAt', 'charCodeAt', 'indexOf', 'lastIndexOf', 'substring', 'substr', 'slice', 'trim', 'trimLeft', 'trimRight',
            'toUpperCase', 'toLowerCase', 'split', 'concat', 'match', 'replace', 'search'];

        var FUNC_PROPS = ['prototype', 'apply', 'call', 'bind'];

        var ARRAY_PROPS = ['length', 'concat', 'join', 'splice', 'push', 'pop', 'shift', 'unshift', 'slice', 'reverse', 'sort', 'indexOf',
            'lastIndexOf', 'every', 'some', 'filter', 'forEach', 'map', 'reduce', 'reduceRight'];

        function getCompletions(token, context, keywords, options) {

            function shouldAdd(word, alreadyFoundWords, tokenString) {
                return (word.lastIndexOf(tokenString, 0) === 0 && !_.contains(alreadyFoundWords, word));
            }
            function gatherCompletions(alreadyFoundWords, obj, tokenString) {
                if (_.isString(obj)){
                    _.each(STRING_PROPS, function(prop){
                        if (shouldAdd(prop, alreadyFoundWords, tokenString)){
                            alreadyFoundWords.push(prop);
                        }
                    });
                } else if (_.isArray(obj)) {
                    _.each(ARRAY_PROPS, function(prop){
                        if (shouldAdd(prop, alreadyFoundWords, tokenString)){
                            alreadyFoundWords.push(prop);
                        }
                    });
                } else if (_.isFunction(obj)) {
                    _.each(FUNC_PROPS, function(prop){
                        if (shouldAdd(prop, alreadyFoundWords, tokenString)){
                            alreadyFoundWords.push(prop);
                        }
                    });
                }
                for (var prop in obj){
                    if (shouldAdd(prop, alreadyFoundWords, tokenString)){
                        alreadyFoundWords.push(prop);
                    }
                }
            }
            var found = [];
            var tokenString = token.string;
            if (!_.isEmpty(context)){
                var obj = context.pop();
                var base;
                if (obj.type && obj.type.indexOf('variable') === 0) {
                    if (options && options.completionContext) {
                        base = options.completionContext[obj.string];
                    }
                    base = base || window[obj.string];
                } else if (obj.type === 'string') {
                    base = '';
                } else if (obj.type === 'atom') {
                    base = 1;
                } else if (obj.type === 'function') {
                    //dunno
                }
                while (base !== null && !_.isEmpty(context)){
                    base = base[context.pop().string];
                }
                if (base !== null){
                    gatherCompletions(found, base, tokenString);
                }
            } else {
                gatherCompletions(found, options.completionContext, tokenString);
                _.forEach(keywords, function(keyword){
                    if (shouldAdd(keyword, found, tokenString)){
                        found.push(keyword);
                    }
                });
            }

            return found;
        }

        function scriptHint(editor, options) {
            var cur = editor.getCursor();
            var token = editor.getTokenAt(cur);
            var tprop = token;
            if (/\b(?:string|comment)\b/.test(token.type)){
                return;
            }
            token.state = CodeMirror.innerMode(editor.getMode(), token.state).state;

            // If it's not a 'word-style' token, ignore the token.
            if (!/^[\w$_]*$/.test(token.string)) {
                token = tprop = {start: cur.ch, end: cur.ch, string: '', state: token.state,
                    type: token.string === '.' ? 'property' : null};
            }
            // If it is a property, find out what it is a property of.
            var context = [];
            while (tprop.type === 'property') {
                tprop = editor.getTokenAt(new CodeMirror.Pos(cur.line, tprop.start));
                if (tprop.string !== '.'){
                    return;
                }
                tprop = editor.getTokenAt(new CodeMirror.Pos(cur.line, tprop.start));
                context.push(tprop);
            }
            if (_.isUndefined(options) || _.isNull(options) || _.isUndefined(options.completionContext) || _.isNull(options.completionContext)){
                options = {
                    completionContext: window
                };
            }
            return {
                list: getCompletions(token, context, JAVASCRIPT_KEYWORDS, options),
                from: new CodeMirror.Pos(cur.line, token.start),
                to: new CodeMirror.Pos(cur.line, token.end)
            };
        }

        return {
            /**
		 *
		 * @returns {Object} the default options to be used for html(and mixed) codemirrors
		 */
            getDefaultHtmlOptions : function(readonly) {
                return {
                    mode : {
                        name: 'htmlmixed'
                    },
                    theme : 'eclipse',
                    readOnly : readonly,
                    lineNumbers : true,
                    indentWithTabs: true,
                    indentUnit: 4,
                    matchBrackets: true,
                    viewportMargin: Infinity
                };
            },
            /**
		 *
		 * @returns {Object} the default options to be used for css codemirrors
		 */
            getDefaultCssOptions: function(readonly) {
                return {
                    mode : 'css',
                    theme : 'eclipse',
                    readOnly : readonly,
                    lineNumbers : true,
                    indentWithTabs: true,
                    indentUnit: 4,
                    matchBrackets: true,
                    lintWith: null
                };
            },
            /**
		 *
		 * @returns {Object} the default options to be used for javascript codemirrors
		 */
            getDefaultJavascriptOptions : function(readonly){
                return {
                    mode: 'javascript',
                    theme: 'eclipse',
                    readOnly: readonly,
                    lineNumbers: true,
                    indentWithTabs: true,
                    indentUnit: 4,
                    fixedGutter: false,
                    matchBrackets: true,
                    tabMode: 'shift',
                    gutters: ['CodeMirror-lint-markers'],
                    lintWith: CodeMirror.javascriptValidator,
                    extraKeys: {
                        'Ctrl-Space': function(cm) {
                            CodeMirror.showHint(cm);
                        },
                        'Shift-Tab': 'indentLess'
                    }
                };
            },
            /**
		 * Inserts a placeholder into the specified codemirror instance and focuses it
		 * @param {Codemirror instance} codemirror
		 * @param {Placeholder} placeholder
		 * @returns {void}
		 */
            insertPlaceholder: function(codemirror, placeholder){
                var inserted = LEFT + placeholder.code + ':' + placeholder.value + RIGHT;
                var title = getPlaceholderTitle(placeholder.code, placeholder.value);
                this.insertText(codemirror, inserted, title);
            },

            /**
		 * Inserts text into the specified codemirror instance and focuses it
		 * @param {Codemirror instance} codemirror
		 * @param {text} the string to insert
		 * @param {overrideOptions} override the default options for insert {atomic: true, readOnly: false, addToHistory: true, title: undefined}
		 * @returns {void}
		 */
            insertText: function(codemirror, text, overrideOptions) {

                var codemirrorDoc = codemirror.doc;
                var cursor = codemirrorDoc.getCursor();

                var options = _.extend({
                    atomic:true,
                    readOnly: false,
                    addToHistory: true,
                    title: undefined
                }, overrideOptions);

                codemirrorDoc.replaceSelection(text);
                codemirrorDoc.markText({
                    line:cursor.line,
                    ch:cursor.ch
                }, {
                    line:cursor.line,
                    ch:cursor.ch + text.length
                },
                options
                );

                if(!codemirror.hasFocus()) {
                    codemirror.focus();
                    codemirror.setCursor(cursor);
                }
                codemirror.options.onChange(codemirror);
            },

            /**
		 * Goes through the whole codemirror, and wherever it finds a tag placeholder, it marks its opening, value and closing tags as an atomic text
		 * @param {Codemirror instance} codemirror
		 */
            generateTagPlaceholders: function(codemirror){
                codemirror.eachLine(function(line){
                    var desc = codemirror.lineInfo(line);
                    var m = PLACEHOLDER_REGEX.exec(desc.text);

                    function getPlaceholder(placeholderText){
                        var separatorIdx = placeholderText.indexOf(SEPARATOR);
                        var code = placeholderText.substring(LEFT.length, separatorIdx);
                        var value = placeholderText.substring(separatorIdx + 1, placeholderText.length - RIGHT.length );
                        return {
                            code: code,
                            value: value
                        };
                    }
                    while(m !== null) {
                        //mark a section of the text as a textMarker
                        var start = m.index;
                        var end = m.index + m[0].length;
                        var placeholder = getPlaceholder(m[0]);
                        var title = getPlaceholderTitle(placeholder.code, placeholder.value);

                        codemirror.markText({
                            line:desc.line,
                            ch:start
                        }, {
                            line:desc.line, ch:end
                        }, {
                            atomic:true,
                            readOnly: false,
                            title: title
                        }
                        );
                        m = PLACEHOLDER_REGEX.exec(desc.text);
                    }
                });
            },
            /**
		 * Retrieves the title of a placeholder (the tooltip)
		 * @param {String} placeholderCode the placeholder code, @see PLACEHOLDER_CODES
		 * @param {String} placeholderValue
		 * @return {String} the placeholder title
		 */
            getPlaceholderTitle: getPlaceholderTitle,
            scriptHint: scriptHint,
            LEFT: LEFT,
            RIGHT: RIGHT,
            JAVASCRIPT_KEYWORDS: JAVASCRIPT_KEYWORDS,
            PLACEHOLDER_REGEX: PLACEHOLDER_REGEX,
            PLACEHOLDER_CODES: PLACEHOLDER_CODES,
            SEPARATOR: SEPARATOR
        };
    }]);
