angular.module('webUi.ui.tagmanagement.tags.conditions.timeOfDay', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormTimeOfDayConditionController', ['$scope', 'TagmanagementService','ValidService',
        function TagmanagementTagFormTimeOfDayConditionController    ($scope,   TagmanagementService,  ValidService) {

            var conditionClazz = 'TimeOfDayCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                timezone: jstz.determine().name()
            };

            var init = function(formCondition) {
                //set hours and minutes manually for start time
                formCondition.startTime = new Date();

                if(!ValidService.isEmpty(formCondition.startTimeHour)) {
                    formCondition.startTime.setHours(formCondition.startTimeHour);
                }
                if(!ValidService.isEmpty(formCondition.startTimeMinute)) {
                    formCondition.startTime.setMinutes(formCondition.startTimeMinute);
                }

                //same for end time
                formCondition.endTime = new Date();
                if(!ValidService.isEmpty(formCondition.endTimeHour)) {
                    formCondition.endTime.setHours(formCondition.endTimeHour);
                }
                if(!ValidService.isEmpty(formCondition.endTimeMinute)) {
                    formCondition.endTime.setMinutes(formCondition.endTimeMinute);
                }
            };

            init($scope.state.formCondition);

            TagmanagementService.getTimezones().then(function(timezones) {
                $scope.timezones = timezones;
            });

            $scope.state.beforeSubmit = function(formCondition) {

                //return $scope.state.formCondition.browsers.length > 0;
                formCondition.startTimeHour = formCondition.startTime.getHours();
                formCondition.startTimeMinute = formCondition.startTime.getMinutes();

                formCondition.endTimeHour = formCondition.endTime.getHours();
                formCondition.endTimeMinute = formCondition.endTime.getMinutes();

                delete formCondition.startTime;
                delete formCondition.endTime;

                return true;
            };

        }
    ]);

