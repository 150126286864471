import React, { ReactElement } from 'react';
import cx from 'classnames';

type BadgeProps = {
  content: string | number;
  className?: string;
  testHook?: string;
  color?: keyof typeof COLOR;
};

const COLOR: Record<string, string> = {
  blue: 'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800',
  red: 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900',
  gray: 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-300',
  green: 'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900',
  yellow: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900',
};

function Badge({ content, className, color = 'gray', testHook }: BadgeProps): ReactElement {
  return (
    <span className={cx('rounded px-2.5 py-0.5 text-xs', className, COLOR[color], `t-${testHook}`)}>{content}</span>
  );
}

export default Badge;
