import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    segmentName: Yup.string().required(t('connectors:cards.validation.segmentName')),
  });

const ClangBatch = ({ onEdit, connector, t, isSubmitted }) => (
  <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
    {formProps => (
      <SmallWrapperNoOffset>
        <Field
          as={FormikInputField}
          name="segmentName"
          label={t('connectors:cards.label.segmentName')}
          placeholder={t('connectors:common.audienceCard.placeholder')}
          errorText={formProps.touched.segmentName && formProps.errors.segmentName}
        />
      </SmallWrapperNoOffset>
    )}
  </ConnectorGenericForm>
);
export default compose(connect(null), translate('audiences'))(ClangBatch);
