angular.module('webUi.directive.paging', [])
	
    .directive('paging', ['$compile', 'ReflectionService', function ($compile, ReflectionService) {
        var directiveDefinitionObject = {
		
            restrict : 'A',
            scope : {
                paging : '='
            },
            replace : true,
            templateUrl : 'directive/paging/paging.tpl.html',
            link: function (scope, element, attrs) {
			
                // init paging object if undefined and share with parent scope
                if(typeof scope.paging === 'undefined') {
                    scope.paging = ReflectionService.setNestedProperty(scope.$parent, attrs.paging, {});
                }
			
                // default page nr to 0
                if(typeof scope.paging.page === 'undefined') {
                    scope.paging.page = 0;
                }

                // default page size to 25
                if(typeof scope.paging.perPage === 'undefined') {
                    scope.paging.perPage = 25;
                }
			
                // watch data to keep count of nr of records
                scope.$parent.$watch(attrs.pagingData, function(newValue) {
                    if(typeof newValue !== 'undefined') {

                        // if data is number, assume it is the number of records
                        if(_.isNumber(newValue)) {
                            scope.nrOfEntries = newValue;						
                        } else {
                            scope.nrOfEntries = newValue.length;
                        }
                    }
                });
			
                // watch page / perPage and nrOfEntries to show pagers
                scope.$watch('paging.page + paging.perPage + nrOfEntries', function() {
				
                    var lastPage = Math.max(Math.ceil(scope.nrOfEntries / scope.paging.perPage) - 1, 0);
				
                    // start from page - 2 with a minumum of 5 pagers, but don't do negative pages
                    var pageFrom = Math.max(Math.min(scope.paging.page - 2, lastPage - 4), 0);
                    // end at page + 2 with a minumum of 5 pagers, however not more than available
                    var pageTo = Math.min(Math.max(scope.paging.page + 2, 4), lastPage);
				
                    if(scope.nrOfEntries > 0) {
                        scope.shownPages = _.range(pageFrom, pageTo + 1);
                    } else {
                        scope.shownPages = [];
                    }
				
                    scope.lastPage = lastPage;
                });
			
                // helper functions
                scope.min = function(a, b) {
                    return Math.min(a, b);
                };
                scope.max = function(a, b) {
                    return Math.max(a, b);
                };
            }
        };
	
        return directiveDefinitionObject;
    }]);
