import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '~/components';
import { useAPI, useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';
import { getAllTransformations, getInternalEventProperties, getValueTransformation } from './dataService';
import TransformationsDashboard from './TransformationsDashboard';
import TransformationView from './TransformationView';
import TransformationEdit from './TransformationEdit';
import { TransformationContext } from './context';

const TransformationsPage = ({ canEditProfiles }: { canEditProfiles: boolean }): React.ReactNode => {
  const {
    state: transformations,
    isLoading,
    refetch: refetchTransformations,
  } = useApiWithState(() => getAllTransformations());

  const { data: internalProps, isLoading: isInternalPropsLoading } = useAPI(() => getInternalEventProperties(), []);
  const { data: valTransforms, isLoading: isValTransformsLoading } = useAPI(() => getValueTransformation(), []);

  if (!transformations || isLoading || isInternalPropsLoading || isValTransformsLoading) {
    return <Spin />;
  }

  return (
    <TransformationContext.Provider
      value={{
        canEditProfiles,
        transformations,
        internalProps: internalProps || [],
        valTransforms: valTransforms || [],
        refetchTransformations,
      }}
    >
      <Routes>
        <Route path="/" element={<TransformationsDashboard />} />
        <Route path="dashboard" element={<TransformationsDashboard />} />
        <Route path="view/:transformationId" element={<TransformationView />} />
        <Route
          path="edit/:transformationId"
          element={<PrivateRoute authenticated={canEditProfiles} component={TransformationEdit} />}
        />
        <Route path="add" element={<PrivateRoute authenticated={canEditProfiles} component={TransformationEdit} />} />
      </Routes>
    </TransformationContext.Provider>
  );
};

export default TransformationsPage;
