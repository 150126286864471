import React, { useState } from 'react';
import { useAPI } from '~/common';
import Spin from '~/components/src/Spin';
import DataExchangeGraph from '~/profiles/components/DataExchangeGraph/DataExchangeGraph';
import { fetchStepData } from '~/workflows/dataService';
import { TconnectorStatsProps } from '~/workflows/types';
import StepCards from './StepCards';

const ConnectorStats = ({ workflowId, allSteps }: TconnectorStatsProps) => {
  const [selectedStep, setSelectedStep] = useState(allSteps[0].nodeId);

  const { data: stepDetails, isLoading: isStepDetailsLoading } = useAPI(() => {
    if (selectedStep) return fetchStepData(selectedStep);
    return Promise.resolve(null);
  }, [workflowId, selectedStep]);

  return (
    <div className="flex flex-col gap-2 pt-4">
      {allSteps && allSteps.length > 1 && (
        <StepCards allSteps={allSteps || []} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
      )}

      <div>
        {isStepDetailsLoading || !stepDetails ? (
          <Spin />
        ) : (
          <DataExchangeGraph
            integrations={stepDetails.integrations}
            sourceId={stepDetails.stepId}
            type="orchestration"
          />
        )}
      </div>
    </div>
  );
};

export default ConnectorStats;
