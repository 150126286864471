import React, { Fragment } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from '~/i18n';
import { buildUrl, useApiWithState } from '~/common';
import { Heading, Notification, Page, Spinner } from '~/components';

import { fetchModelById } from './dataService';
import ModelActions from './components/ModelActions';
import { DATE_FORMAT, MODEL_STATUS, TIME_FORMAT } from './constants';
import { AdvanceSettingsType, ModelDetail, Prediction } from './types';

import './styles.scss';

const getDisplayValue = (value: boolean) =>
  value ? i18n.t('common:checkbox.truthy') : i18n.t('common:checkbox.falsy');

const ModelDetails = ({ model }: { model: ModelDetail }) => {
  const modelLastTrainedAt = model?.lastTrainedAt && moment(model.lastTrainedAt);
  const modelLastDeployedAt = model?.lastDeployedAt && moment(model.lastDeployedAt);

  return (
    <>
      {model?.maxPredictionDays && (
        <Notification kind="information" testHook="perdictionDaysInfo">
          <ul className="ml-4 !list-disc">
            <li>
              {i18n.t('ai:notifications.futurePredictionDaysInfo', { days: model.advancedSettings.predictionDays })}
            </li>
            <li>{i18n.t('ai:notifications.maxFuturePredictionDaysInfo', { days: model.maxPredictionDays })}</li>
          </ul>
        </Notification>
      )}
      <p className="ModelView-label leading-8">
        {i18n.t('ai:model.snapshotName')}:
        {model?.snapshotName && (
          <span className="t-snapshotName font-medium text-slate-700"> {model?.snapshotName}</span>)}
        {!model?.snapshotName && (
          <span className="t-snapshotName text-slate-700 italic"> {i18n.t('ai:model.snapshotNoAvailable')}</span>)}
      </p>
      <p className="ModelView-label leading-8">
        {i18n.t('ai:model.status')}:
        <span
          className={`ModelTable-status font-medium text-slate-700 ModelTable-status--${model?.status?.toLowerCase()}`}
        >
          {model?.status}
        </span>
      </p>
      {modelLastTrainedAt && (
        <p className="ModelView-label leading-8">
          {i18n.t('ai:model.lastTrainedAt')}:{' '}
          <span className="text-slate-700">
            {modelLastTrainedAt.format(DATE_FORMAT)} <small>{modelLastTrainedAt.format(TIME_FORMAT)}</small>
          </span>
        </p>
      )}
      {modelLastDeployedAt && (
        <p className="ModelView-label leading-8">
          {i18n.t('ai:model.lastDeployedAt')}:{' '}
          <span className="text-slate-700">
            {modelLastDeployedAt.format(DATE_FORMAT)} <small>{modelLastDeployedAt.format(TIME_FORMAT)}</small>
          </span>
        </p>
      )}
      {model?.status?.toLowerCase() === MODEL_STATUS.ERROR.toLowerCase() && (
        <p className="ModelView-label leading-8">
          {i18n.t('ai:model.error')}: <span className="ModelTable-status--error text-slate-700">{model?.error}</span>
        </p>
      )}
    </>
  );
};

const PredictionsMap = ({ predictions }: { predictions: Prediction[] }) => (
  <>
    <p className="my-4 text-xl font-medium text-slate-600">{i18n.t('ai:predictions')}</p>
    {predictions?.map((prediction: Prediction, index: number) => (
      <div className="PredictionView" key={index}>
        <div className="PredictionView-head">
          <p className="PredictionView-head--name">
            {`${i18n.t('ai:view.predictionName')}: `}
            <span className="font-medium text-slate-700">{prediction.name}</span>
          </p>
          <div className="PredictionView-row">
            <p>{i18n.t('ai:columns.property')}</p>
            <p>{i18n.t('ai:columns.constraint')}</p>
            <p>{i18n.t('ai:columns.filterValue')}</p>
          </div>
        </div>
        {prediction.criterias?.map((criteria, criteriaIndex) => (
          <Fragment key={criteriaIndex}>
            <div className={cx('PredictionView-and', { 'PredictionView-and--group': criteria.rules.length > 1 })}>
              {criteria.rules.map((rule, ruleIndex) => (
                <Fragment key={ruleIndex}>
                  <div
                    className={cx('PredictionView-row PredictionView-or', {
                      'PredictionView-row--nested': prediction.criterias?.length > 1,
                    })}
                  >
                    <p>{rule.property}</p>
                    <p>{rule.constraint}</p>
                    <p>{rule.filterValue}</p>
                  </div>
                  {criteria.rules.length > 1 && ruleIndex < criteria.rules.length - 1 && (
                    <p className="PredictionView-or--label">{i18n.t('ai:model.or')}</p>
                  )}
                </Fragment>
              ))}
              {prediction.criterias.length > 1 && criteriaIndex < prediction.criterias.length - 1 && (
                <p className="PredictionView-and--label">{i18n.t('ai:model.and')}</p>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    ))}
  </>
);

const AdvanceSettings = ({ settings }: { settings: AdvanceSettingsType }) => (
  <>
    <hr />
    <div className="my-4">
      <p className="text-xl font-medium text-slate-600 ">{i18n.t('ai:new.advancedSetting')}</p>
      <div className="ModelView-settings">
        <div className="ModelView-settings--values">
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.trainForMaxEpochs')}:{' '}
            <span className="font-medium text-slate-700">{settings.trainForMaxEpochs}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.predictionDays')}:{' '}
            <span className="font-medium text-slate-700">{settings.predictionDays}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.stopIfNotImprovedAfterEpochs')}:{' '}
            <span className="font-medium text-slate-700">{settings?.stopIfNotImprovedAfterEpochs}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.targetLabelMode')}:{' '}
            <span className="font-medium text-slate-700">{settings?.targetLabelMode}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.numObservationsPerClass')}:{' '}
            <span className="font-medium text-slate-700">{settings?.numObservationsPerClass}</span>
          </p>
        </div>
        <div className="ModelView-settings--check">
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.keepUnlabeledSequences')}:{' '}
            <span className="font-medium text-slate-700">{getDisplayValue(settings.keepUnlabeledSequences)}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.isUnlabeledFromSingleClass')}:{' '}
            <span className="font-medium text-slate-700">{getDisplayValue(settings.isUnlabeledFromSingleClass)}</span>
          </p>
          <p className="ModelView-label leading-8">
            {i18n.t('ai:new.breakSequencesOnTarget')}:{' '}
            <span className="font-medium text-slate-700">{getDisplayValue(settings.breakSequencesOnTarget)}</span>
          </p>
        </div>
      </div>
    </div>
  </>
);

function ModelView(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const modelId = params.id || '';
  const { state: model, isLoading, refetch: refetchModel } = useApiWithState(() => fetchModelById(modelId));

  const goToModelsList = () => navigate(buildUrl('ai/models/list'), { replace: true });

  if (isLoading || !model) {
    return (
      <Page className="ModelView">
        <Spinner />
      </Page>
    );
  }

  const inferenceFrequency =
    model?.deploymentSettings?.inferenceFrequency && model?.deploymentSettings?.inferenceFrequencyTerm
      ? `${model?.deploymentSettings?.inferenceFrequency} ${model?.deploymentSettings?.inferenceFrequencyTerm}`
      : '—';

  const trainingFrequency =
    model?.deploymentSettings?.trainingFrequency && model?.deploymentSettings?.trainingFrequencyTerm
      ? `${model?.deploymentSettings?.trainingFrequency} ${model?.deploymentSettings?.trainingFrequencyTerm}`
      : '—';

  return (
    <Page className="ModelView">
      <Heading
        title={model?.modelName}
        crumbs={[
          {
            title: i18n.t('ai:actions.backToModels'),
            onClick: () => goToModelsList(),
          },
        ]}
      >
        <ModelActions
          allowedActions={model.actions}
          modelInfo={{ modelId: model.modelId, modelName: model.modelName }}
          refetchData={refetchModel}
        />
      </Heading>
      {model && <ModelDetails model={model} />}
      <br />
      <hr />
      <PredictionsMap predictions={model?.predictions || []} />
      {model?.inputsToInclude && model?.inputsToInclude.length > 0 && (
        <>
          <hr />
          <div className="my-4">
            <p className="text-xl font-medium text-slate-600 ">{i18n.t('ai:model.additionalProperties')}</p>
            <div className="Models-additionalProperties my-2">
              {model.inputsToInclude.map((property, index) => (
                <li key={index}>{property.value}</li>
              ))}
            </div>
          </div>
        </>
      )}
      {model?.hasAdvancedSettings && <AdvanceSettings settings={model.advancedSettings} />}
      {model?.deploymentSettings && (
        <>
          <hr />
          <p className="my-4 text-xl font-medium text-slate-600">{i18n.t('ai:model.deploymentSettings')}</p>
          <div className="ModelView-settings--values">
            <p className="ModelView-label leading-8">
              {i18n.t('ai:new.inferenceFrequency')}:{' '}
              <span className="font-medium text-slate-700">
                {model?.deploymentSettings?.onlineInferencing ? i18n.t('ai:modelsPage.realTime') : inferenceFrequency}
              </span>
            </p>
            <p className="ModelView-label leading-8">
              {i18n.t('ai:new.trainingFrequency')}:{' '}
              <span className="font-medium text-slate-700">{trainingFrequency}</span>
            </p>
          </div>
        </>
      )}
    </Page>
  );
}

export default ModelView;
