import React from 'react';
import { getIcon } from '~/common/modules/rules/utils';
import { I18NextT } from '~/components/src/Common/types';
import BtnOutlined from '~/components/src/BtnOutlined';
import { Notification } from '~/components';
import { DestinationOption } from '../core/transformerTypes';
import Mapping, { MappingListHeader } from './Mapping';
import { EventProps } from './types';
import { EVENTS } from './constants';
import CardHeader from './CardHeader';

const groupOptions = (
  knownDestinations: DestinationOption[],
  t: I18NextT,
): { label: string; options: DestinationOption[] }[] => {
  const groups = {
    userData: { label: t('partners:eventConnector.destination.userData'), options: [] as DestinationOption[] },
    customData: { label: t('partners:eventConnector.destination.customData'), options: [] as DestinationOption[] },
    others: { label: t('partners:eventConnector.destination.others'), options: [] as DestinationOption[] },
  };

  knownDestinations.forEach(destination => {
    if (destination.element === 'user_data') {
      groups.userData.options.push(destination);
    } else if (destination.element === 'custom_data') {
      groups.customData.options.push(destination);
    } else {
      groups.others.options.push(destination);
    }
  });

  return Object.values(groups);
};

const Event = ({
  event,
  eventPath,
  getValidationErrorByPath,
  validationErrors,
  wasSubmitClicked,
  deleteEvent,
  openValueTransformationsModal,
  apiValidationError,
  t,
}: EventProps): React.ReactElement => {
  const error = getValidationErrorByPath(`${eventPath}.mappings`);
  const errorText = error && t(`partners:eventConnector.errors.${error.message}`);

  const groupedDestinations = groupOptions(event.listAvailableDestinations(), t);

  return (
    <div className="Event RuleCardEdit flex rounded-md border border-gray-300 bg-white p-4">
      <div className="w-full">
        <CardHeader
          title={event.name}
          subtitle={EVENTS[event.type].title}
          tooltipText={t(`audiences:rules.descriptions.${EVENTS[event.type].capitalizedType}`)}
          icon={getIcon(EVENTS[event.type].capitalizedType)}
          onDelete={() => {
            deleteEvent(event.id);
          }}
          t={t}
        />
        <div className="Event-mappingsContainer">
          {event.mappings.length > 0 && <MappingListHeader t={t} />}
          {event.mappings.map((mapping, mappingIndex) => (
            <Mapping
              mapping={mapping}
              mappingPath={`${eventPath}.mappings[${mappingIndex}]`}
              getValidationErrorByPath={getValidationErrorByPath}
              validationErrors={validationErrors}
              key={mapping.id}
              remove={() => {
                event.deleteMapping(mapping.id);
              }}
              sources={mapping.listAvailableSources()}
              availableDestinations={event.listAvailableDestinations()}
              groupedDestinations={groupedDestinations}
              openValueTransformationsModal={openValueTransformationsModal}
              wasSubmitClicked={wasSubmitClicked}
              t={t}
            />
          ))}
          {errorText && wasSubmitClicked && <Notification kind="error">{errorText}</Notification>}
          {apiValidationError && (
            <Notification kind="error" header={apiValidationError.error}>
              {apiValidationError.message}
            </Notification>
          )}
          <BtnOutlined
            size="xs"
            onClick={e => {
              e.preventDefault();
              event.addMapping();
            }}
            icon="add"
            className="RuleCardEdit-addPropertyBtn mt-4"
            testHook="addMappingButton"
          >
            {t('partners:eventConnector.addMapping')}
          </BtnOutlined>
        </div>
      </div>
    </div>
  );
};

export default Event;
