angular.module('webUi.ui.context.basescripts.list', ['webUi.ui.context.basescripts.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.basescripts.list', {
                url: '/list',
                isReact: true,
            });
    }]);

