export default {
  '160335e4-c7bf-4068-83ae-f68137b47e1a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Update product variable with interest',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
  '160335e4-c7bf-4068-83ae-f68137b47e2a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Added to cart but abandoned',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
            {
              nodeId: 'ba5fac59-e4b8-47ee-acff-707f755067a6',
              type: 'TRIGGER',
              label: 'Searching for jeans',
              children: [
                {
                  nodeId: 'c7f66390-b180-4439-aef3-a72e074b98a6',
                  type: 'STEP',
                  label: 'Retargeting on paid media with related offers',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
  '160335e4-c7bf-4068-83ae-f68137b47e3a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Added to cart but abandoned',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  hasTransition: true,
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
            {
              nodeId: 'ba5fac59-e4b8-47ee-acff-707f755067a6',
              type: 'TRIGGER',
              label: 'Searching for jeans',
              children: [
                {
                  nodeId: 'c7f66390-b180-4439-aef3-a72e074b98a6',
                  type: 'STEP',
                  label: 'Retargeting on paid media with related offers',
                  children: [
                    {
                      nodeId: 'ca17783c-fb18-4e31-8fd6-f130841ead8c',
                      type: 'TRIGGER',
                      label: 'No engagement for 7 days',
                      children: [
                        {
                          nodeId: 'df52ced5-d549-482d-bea5-bf509c73f166',
                          type: 'STEP',
                          label: 'Email campaign with limited time offer',
                          children: [
                            {
                              nodeId: 'aae9f964-3804-4a4e-b720-382f8b2b9040',
                              type: 'TRIGGER',
                              label: 'No engagement',
                              hasTransition: true,
                              children: [],
                              transitionNodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                              stats: {
                                profilesEntered: 0,
                              },
                            },
                          ],
                          stats: {
                            profilesInStep: 0,
                          },
                          hasVariableModifications: false,
                        },
                      ],
                      stats: {
                        profilesEntered: 0,
                      },
                    },
                  ],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: '1cf1f06b-c505-47d6-82ad-31041555d208',
        label: 'Exit from No engagement',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
};
