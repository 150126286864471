import { TConsentTypes, TPayloadType, TProductType } from './types';

export const mapPayloadTypes = (payloadTypes: TPayloadType[]) =>
  payloadTypes.map(payloadType => ({ ...payloadType, value: payloadType.name }));

export const mapProductTypes = (productTypes: TProductType[]) =>
  productTypes.map(productType => ({ ...productType, value: productType.name }));

export const mapConsentTypes = (consentTypes: TConsentTypes[]) =>
  consentTypes.map(consentType => ({ ...consentType, value: consentType.name }));
