import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import constants from '../../constants';
import { TAccountOption, TPayloadType } from './types';
import { mapAccounts, mapPayloadTypes } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/${constants.LINKEDIN}`;

export const fetchPayloadTypes = (): Promise<TPayloadType[]> =>
  Api.callGet(`${BASE_PATH}/payloadTypes`).then(parseResponse).then(mapPayloadTypes);

export const fetchAccountIDs = (refreshToken: string): Promise<TAccountOption[]> =>
  Api.callGet(`${BASE_PATH}/accounts?refreshToken=${refreshToken}`).then(parseResponse).then(mapAccounts);

export const fetchAuthorizationUrl = () => {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  return Api.callGet(`${BASE_PATH}/authorizationUrl?redirectUrl=${encodeURI(currentUrl)}`).then(parseResponse);
};
