import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import { isRequired } from '~/common';
import { mode } from '~/components/src/Code';
import { ReduxFormInputField, ReduxFormCodeField } from '~/components/src/Form/Fields/ReduxFormFields';

export const CookieVariantForm = ({ cancelHref, handleSubmit, submitting, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        component={ReduxFormInputField}
        label={t('form.name.label')}
        name="name"
        placeholder={t('form.name.placeholder')}
        type="text"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.html}
        label={t('form.templateFormCode.label')}
        name="templateFormCode"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.html}
        label={t('form.templateViewCode.label')}
        name="templateViewCode"
        validate={isRequired}
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.js}
        label={t('form.templateDefaults.label')}
        name="templateDefaults"
      />
      <Field
        component={ReduxFormCodeField}
        isBlock={true}
        mode={mode.js}
        label={t('form.templateJs.label')}
        name="templateJs"
      />
    </div>
    <FormActions t={t} submitting={submitting} cancelHref={cancelHref} />
  </Form>
);

CookieVariantForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default CookieVariantForm;
