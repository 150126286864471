import React, { CSSProperties, ReactElement, useContext } from 'react';

import * as Yup from 'yup';
import { get } from 'lodash';
import { Formik, Field, Form, FieldArray, FormikErrors, FormikHelpers } from 'formik';

import i18n from '~/i18n';
import { Notification } from '~/components';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { buildUrl } from '~/common/history';
import { showSuccess } from '~/notificationCenter';
import ActionButtons from '~/components/src/ActionButtons';

import { menuPortalStyle, prepareCampaignPayload } from '../utils';
import { createCampaign, updateCampaign } from '../dataService';
import { UrlBuilderContext } from '../UrlBuilderContext';
import { CampaignsFormProps, MappingColumn, ColumnType, PredefinedValue, CampaignsPayload } from '../types';
import { COLUMN_TYPES } from '../constants';

const renderColumnConfiguration = (
  columns: MappingColumn[],
  columnTypes: ColumnType[],
  predefinedValues: PredefinedValue[],
  values: CampaignsPayload,
  errors: FormikErrors<CampaignsPayload>,
  setFieldValue: FormikHelpers<CampaignsPayload>['setFieldValue'],
) =>
  columns.map((column, index) => {
    const columnType = get(values, `columnConfiguration[${index}].columnType`)?.name;
    return (
      <div className="flex w-full items-center gap-2 pt-4" key={column.columnId}>
        <span className="mb-6 w-[20%]">{column.name}</span>
        <Field
          as={FormikSelectField}
          className="w-[40%]"
          options={columnTypes || []}
          errorText={get(errors, `columnConfiguration[${index}].columnType`)}
          name={`columnConfiguration[${index}].columnType`}
          getOptionLabel={(option: { label: string; value: string }) => option.label}
          getOptionValue={(option: { label: string; value: string }) => option.value}
          customSetFieldValue={(name: string, option: { label: string; value: string }) => {
            setFieldValue(name, option);
            setFieldValue(`columnConfiguration[${index}].columnId`, column.columnId);
          }}
          placeholder={i18n.t('campaigns:form.columnType')}
          testHook="columnType"
          menuPosition="fixed"
          // Fix for menu displaying at random places
          elementStyles={{
            menuPortal: (provided: CSSProperties) => menuPortalStyle(provided),
          }}
        />
        {[COLUMN_TYPES.PREDEFINED, COLUMN_TYPES.PREDEFINED_VALUES].includes(columnType) && (
          <Field
            as={columnType === COLUMN_TYPES.PREDEFINED_VALUES ? FormikSelectField : FormikInputField}
            className="w-[40%]"
            errorText={get(errors, `columnConfiguration[${index}].defaultValue`)}
            name={`columnConfiguration[${index}].defaultValue`}
            placeholder={i18n.t('campaigns:form.defaultValue')}
            testHook="defaultValue"
            options={predefinedValues || []}
            getOptionLabel={(option: { predefinedValuesSetName: string; predefinedValuesSetId: string }) =>
              option.predefinedValuesSetName
            }
            getOptionValue={(option: { predefinedValuesSetName: string; predefinedValuesSetId: string }) =>
              option.predefinedValuesSetId
            }
            customSetFieldValue={(
              name: string,
              option: { predefinedValuesSetName: string; predefinedValuesSetId: string },
            ) => setFieldValue(name, option.predefinedValuesSetId)}
          />
        )}
      </div>
    );
  });

export const CampaignsForm = ({ hideModal, campaignDetails }: CampaignsFormProps): ReactElement => {
  const isEditMode = !!campaignDetails;
  const { campaignTypes, columns, columnTypes, predefinedValues, refetchTree, navigate } =
    useContext(UrlBuilderContext);

  const handleSubmit = async (values: CampaignsPayload) => {
    const payload = prepareCampaignPayload(values);
    if (isEditMode) {
      await updateCampaign(payload, campaignDetails.campaignId);
      refetchTree();
      showSuccess({ header: i18n.t('campaigns:campaignUpdated') });
    } else {
      const newlyCreatedCampaignId = await createCampaign(payload);
      navigate(buildUrl(`tagmanagement/urlbuilder/campaign/view/${newlyCreatedCampaignId}`));
      refetchTree();
      showSuccess({ header: i18n.t('campaigns:campaignCreated') });
    }
    hideModal();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    urlBuilderTypeId: Yup.string().trim().required(i18n.t('validation:validation.required')),
    columnConfiguration: Yup.array(),
  });

  const initialValues = {
    urlBuilderTypeId: '',
    name: '',
    columnConfiguration: columns.map(col => ({
      ...col,
      columnType: columnTypes[0],
    })),
  };

  return (
    <div className="h-auto max-h-[72vh] overflow-y-auto px-2">
      <Formik
        enableReinitialize={true}
        initialValues={isEditMode ? campaignDetails : initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors, setFieldValue, values }) => (
          <Form className="py-2">
            <Field
              as={FormikSelectField}
              autoFocus
              options={campaignTypes || []}
              label={i18n.t('campaigns:form.type')}
              errorText={errors.urlBuilderTypeId}
              name="urlBuilderTypeId"
              getOptionLabel={(option: { name: string; typeId: string }) => option.name}
              getOptionValue={(option: { name: string; typeId: string }) => option.typeId}
              customSetFieldValue={(name: string, option: { name: string; typeId: string }) =>
                setFieldValue(name, option.typeId)
              }
              placeholder={i18n.t('campaigns:placeholders.select')}
              testHook="urlBuilderTypeSelect"
            />
            <Field
              label={i18n.t('campaigns:form.name')}
              as={FormikInputField}
              errorText={errors.name}
              name="name"
              placeholder={i18n.t('campaigns:placeholders.input')}
              testHook="urlBuilderName"
            />
            <hr className="pt-2" />
            <p className="py-2 text-xl">{i18n.t('campaigns:form.columns')}</p>

            <Notification kind="information">{i18n.t('campaigns:messages.formDescription')}</Notification>
            <FieldArray
              name="columnConfiguration"
              render={() =>
                renderColumnConfiguration(columns, columnTypes, predefinedValues, values, errors, setFieldValue)
              }
            />

            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={hideModal}
              className="m-0 pt-4"
              confirmText={i18n.t(`common:actions.${isEditMode ? 'update' : 'create'}`)}
              testHook="createCampaign"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
