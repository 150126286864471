import React from 'react';
import Icon from 'react-icon-base';

const R42Step = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M12 2.614a7.25 7.25 0 0 0-6.148 11.093.75.75 0 1 0 1.271-.795 5.751 5.751 0 1 1 9.745.011.75.75 0 0 0 1.27.8A7.25 7.25 0 0 0 12 2.614z" />
    <path d="M16.417 15.288l-3.742-1.117a.249.249 0 0 1-.178-.239V9.364a1 1 0 0 0-2 0v7.064a.25.25 0 0 1-.456.142c-1.489-2.138-3.855-1.449-2.761.224l2.912 4.367a.5.5 0 0 0 .416.223h5.436a.5.5 0 0 0 .492-.409c.218-1.185.774-4.167.774-4.167a1.281 1.281 0 0 0-.893-1.52z" />
  </Icon>
);

export default R42Step;
