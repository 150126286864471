/* eslint-disable no-prototype-builtins */
/**
 * @description Angular module for optionalPropertySelection
 * @module webUi.directive
 * @namespace webUi.directive
 * @class OptionalPropertySelectionController
 */
angular.module('webUi.directive.optionalPropertySelection',
    [
        'webUi.service.pubSub'
    ]
)
    .controller('OptionalPropertySelectionController', [
        '$scope',
        /**
         *
         * @param $scope
         * @returns {OptionalPropertySelectionController}
         */
        function OptionalPropertySelectionController($scope) {
            /**
             * @param $event
             * @param newProperty
             */
            $scope.add = function add($event, newProperty) {

                if (!_.isEmpty(newProperty)) {

                    var flaggedForAdding = true;

                    if ($scope.r42Validate) {
                        var isUnique = !_.find($scope.properties, function (property) {
                            return (property[$scope.state.modelKey] === newProperty);
                        });
                        flaggedForAdding = (isUnique) ? true : false;
                    }

                    if (flaggedForAdding) {

                        $scope.ngModel[newProperty] = _.extend({}, $scope.state.modelMap.reduce(
                            function (o, v) {
                                if (!o.hasOwnProperty(v)) {
                                    o[v] = null;
                                }
                                return o;
                            }, {}
                        ));

                        var _property = {};
                        _property[$scope.state.modelKey] = newProperty;
                        $scope.properties.push(_property);

                    } else {
                        $scope.state.validation['isUnique'] = true;
                    }

                    $scope.state.newFixedProperty = '';
                    $scope.state.newCustomProperty = '';

                }
                $event.preventDefault();
            };

            $scope.addAll = function ($event, availableProps) {
                $scope.addAllProps(availableProps);
                $event.preventDefault();
            };

            $scope.addAllProps = function addAllProps(props) {
                _.forEach(props, function (prop) {
                    $scope.ngModel[prop] = {};
                    $scope.properties.push({name: prop});
                    $scope.state.newFixedProperty = '';
                    $scope.state.newCustomProperty = '';

                });
            };


            $scope.remove = function (idx) {
                var prop = $scope.properties[idx];
                // remove model property and set inactive
                delete $scope.ngModel[prop.name];
                // remove the property from the list of properties, so the prop chooser is displayed
                delete $scope.properties[idx];
                // remove empty/undefined properties
                $scope.properties = _.filter($scope.properties, function (p) {
                    return !_.isEmpty(p);
                });
                prop.name = null;
            };

            $scope.showDeleteButton = function showDeleteButton(propName) {
                return !$scope.hideDeleteButton || !_.isEmpty($scope.requiredProperties) && !_.includes($scope.requiredProperties, propName);
            };

            $scope.hasRemainingNotAssigned = function (existingOptions) {
                return existingOptions.length < $scope.availableProps.length;
            };

            $scope.findAllAvailableOptions = function (existingProperties, availableProps) {
                return _.difference(availableProps, _.pluck(existingProperties, 'name'));
            };

        }
    ])
    .directive('optionalPropertySelection', [
        '$parse',
        'Utils',
        'PubSubService',
        function optionalPropertySelection($parse, Utils, PubSubService) {
            return {
                templateUrl: 'directive/optionalPropertySelection/optionalPropertySelection.tpl.html',
                restrict: 'E',
                scope: {
                    ngModel: '=',
                    variables: '=?variables',
                    availableProps: '=?props',
                    requiredProperties: '=?',
                    hideDeleteButton: '=?'
                },
                replace: true,
                require: 'ngModel',
                controller: 'OptionalPropertySelectionController',

                link: function (scope, element, attrs) {

                    // statics
                    scope.r42Label = attrs.label || 'Property';
                    scope.defaultPropertyType = attrs['r42DefaultType'] || 'static';
                    scope.r42Required = attrs['r42Required'] || false;
                    scope.r42PlatformType = attrs['r42PlatformType'] || 'web';
                    scope.r42MobileShowRemoveEmpty = attrs['r42MobileShowRemoveEmpty'] || false;
                    scope.r42Placeholder = attrs.placeholder || 'Custom property name';
                    scope.r42Types = attrs['types'];

                    if (attrs.hasOwnProperty('r42Validate')) {
                        scope['r42Validate'] = $parse(attrs['r42Validate']);
                    }

                    // state
                    var initState = function () {
                        // init addType on predefined unless there are no properties and custom is allowed
                        scope.state = scope.state || {};
                        scope.state.allowCustom = (attrs.allowCustom === 'true') || false;
                        scope.state.addType = scope.availableProps.length > 0 ? 'predefined' : attrs.allowCustom ? 'custom' : '';
                        scope.state.location = scope.$parent.tag && scope.$parent.tag.location ? scope.$parent.tag.location : null;
                        scope.state.modelKey = attrs.hasOwnProperty('r42ModelKey') ? attrs['r42ModelKey'] : 'name';
                        scope.state.modelMap = attrs.hasOwnProperty('r42ModelMap') ? attrs['r42ModelMap'].split(',') : ['type', 'value'];
                        scope.state.isRemovable = (attrs['r42Removable'] === 'true') || false;

                        scope.state.validation = scope.state.validation || {};

                        if (scope.requiredProperties) {
                            if (!_.isUndefined(scope.properties) && _.isEmpty(scope.properties)) {
                                scope.addAllProps(scope.requiredProperties);
                            }
                        }
                    };

                    var getExistingProperties = function () {
                        var existingProperties = [];
                        _.forEach(_.keys(scope.ngModel), function (prop) {
                            existingProperties.push(_.zipObject([scope.state.modelKey], [prop]));
                        });
                        scope.properties = _.sortBy(existingProperties, scope.state.modelKey);
                    };

                    // properties
                    scope.availableProps = scope.availableProps || [];
                    scope.requiredProperties = scope.requiredProperties || {};
                    scope.$parent.$watch(attrs.props, function (newVal) {
                        if (newVal) {
                            scope.availableProps = newVal;
                            getExistingProperties();
                            initState();
                        }
                    });

                    initState();

                    // initialize model with empty, if undefined
                    if (_.isUndefined(scope.ngModel)) {
                        scope.ngModel = {};
                        Utils.setScopeValue(scope.$parent, attrs['ngModel'], scope.ngModel, true);
                    }

                    // get existing properties from model

                    if (attrs['ngModel']) {
                        PubSubService.subscribe(attrs['ngModel'], scope, function () {
                            if (scope.ngModel) {
                                scope.ngModel = {};
                            }

                            if (scope.properties && _.isArray(scope.properties)) {
                                scope.properties.forEach(function (item, index, object) {
                                    object.splice(index, 1);
                                });
                            }
                        });
                    }
                }
            };
        }
    ]);
