import React from 'react';
import Icon from 'react-icon-base';

const R42Reset = props => (
  <Icon viewBox="0 0 64 64" {...props}>
    <g>
      <path
        d="M37.707 9.586c-13.234 0-24 10.766-24 24v13.171l-8.586-8.585L2.293
         41l13.414 13.414L29.121 41l-2.828-2.828-8.586 8.585V33.586c0-11.028
         8.972-20 20-20s20 8.972 20 20c0 5.342-2.08 10.364-5.858 14.143l2.829
         2.828a23.846 23.846 0 0 0 7.029-16.971c0-13.234-10.766-24-24-24z"
      />
    </g>
  </Icon>
);

export default R42Reset;
