import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import * as api from '~/profiles/pages/Filters/dataService';
import PickerView from '~/components/src/PickerView';
import { Filter, FilterWithRules } from '~/profiles/pages/Filters/types';
import LinkedFilterSidePanel from './LinkedFilterSidePanel';
import ItemsPickerContent from './ItemsPickerContent';
import constants from './constants';

interface LinkedFiltersProps {
  deleteLinkedFilter: (filterId: string) => void;
  linkedFilters: Filter[];
  setLinkedFilters: (filters: FilterWithRules[]) => void;
  setActiveSidePanel: (panel: string | null) => void;
  activeSidePanel: string;
}

function LinkedFilters({
  deleteLinkedFilter,
  linkedFilters,
  setLinkedFilters,
  setActiveSidePanel,
  activeSidePanel,
}: LinkedFiltersProps): ReactElement {
  const { connectorId } = useParams() as { connectorId: string };
  const { data: allFilters } = useAPI(() => api.getAllFilters());

  useEffect(() => {
    if (connectorId) api.getLinkedFilters(connectorId).then(setLinkedFilters);
  }, []);

  const [isFilterSelectionModalVisible, setIsFilterSelectionModalVisible] = useState(false);
  const [activeFilterId, setActiveFilterId] = useState('');

  return (
    <>
      <div className="flex items-center justify-between py-4">
        <p className="text-xl font-medium text-gray-600">{i18n.t('filters:list.header')}</p>
        <BtnOutlined
          icon="filter"
          color="blue"
          size="xs"
          onClick={() => {
            setIsFilterSelectionModalVisible(true);
          }}
          testHook="selectFilters"
        >
          {i18n.t('filters:form.addFilter')}
        </BtnOutlined>
      </div>

      {linkedFilters && linkedFilters.length ? (
        <div className="flex flex-col gap-2">
          {linkedFilters.map(filter => (
            <Panel
              className="flex cursor-pointer items-center justify-between hover:bg-gray-50"
              key={filter.filterId}
              onClick={() => {
                setActiveFilterId(filter.filterId);
                setActiveSidePanel(constants.Filter);
              }}
            >
              <div className="flex items-center gap-2">
                <Icons icon="filter" className="h-8 w-8 rounded bg-gray-100 p-1 text-gray-600" />
                <p className="text-gray-600">{filter.name}</p>
              </div>
              <BtnIcon
                icon="delete"
                tooltip={i18n.t('common:delete')}
                onClick={event => {
                  /* Prevent the side panel from opening */
                  event.stopPropagation();
                  deleteLinkedFilter(filter.filterId);
                }}
              />
            </Panel>
          ))}
        </div>
      ) : (
        <Notification kind="information">{i18n.t('filters:messages.noLinkedFilters')}</Notification>
      )}

      {isFilterSelectionModalVisible && linkedFilters && (
        <PickerView
          pickerTitle={i18n.t('filters:form.addFilter')}
          handlePickerVisibility={setIsFilterSelectionModalVisible}
          className={cx('!max-h-[80%] !w-[500px]', { '!h-fit': !(allFilters && allFilters?.length > 0) })}
        >
          <ItemsPickerContent
            allItems={allFilters || []}
            items={linkedFilters}
            idKey="filterId"
            hideModal={() => {
              setIsFilterSelectionModalVisible(false);
            }}
            onSave={setLinkedFilters}
            getItemDetails={api.getFilter}
          />
        </PickerView>
      )}

      {activeFilterId && activeSidePanel === constants.Filter && (
        <LinkedFilterSidePanel
          filterId={activeFilterId}
          handleClose={() => {
            setActiveFilterId('');
            setActiveSidePanel(null);
          }}
        />
      )}
    </>
  );
}

export default LinkedFilters;
