import React from 'react';
import PropTypes from 'prop-types';
import './select.css';
import { Select as CustomizedSelect } from './ReactSelect';

const handleChange = (key, option, isConnected, onChange) => {
  if (key) {
    onChange(isConnected ? option : option && option[key]);
  } else {
    onChange(isConnected ? option : option && option.value);
  }
};

const Select = ({
  disabled,
  input: { name, value, onBlur = () => {}, onChange, ...others },
  options,
  isError,
  testHook = name,
  isConnected,
  clearable,
  className,
  ...props
}) => {
  let selectValue;

  // Pass null as value to clear the select. Passing '' won't clear it visually.
  if (value === null) {
    selectValue = null;
  } else {
    selectValue = value || '';
  }

  return (
    <CustomizedSelect
      isLoading={!options || !options.length}
      {...props}
      id={name}
      className={`${className || ''} t-${testHook} ${isError ? 'is-error' : ''}`}
      disabled={disabled}
      value={selectValue}
      onBlur={() => onBlur()}
      onChange={option => handleChange(props.valueKey, option, isConnected, onChange)}
      options={options}
      clearable={clearable}
      {...others}
    />
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'wide', 'block']),
  options: PropTypes.array,
  isError: PropTypes.bool,
  isConnected: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  clearable: true,
};

export default Select;
