import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const MessagePreview = ({ message, ts }) => (
  <div className="MessagePreview">
    <h3 className="MessagePreview-title">{ts.preview}</h3>
    <div className="MessagePreview-message Message">
      <h4 className="Message-title">{message.title}</h4>
      <div className="Message-meta">
        <span className="Message-date">
          <Icon iconClass="r42-calendar" />
          {message.showDate}
        </span>
      </div>
      <div className="Message-body" dangerouslySetInnerHTML={{ __html: message.text }} />
    </div>
  </div>
);

MessagePreview.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    showDate: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  // Translations
  ts: PropTypes.shape({
    preview: PropTypes.string.isRequired,
  }).isRequired,
};

export default MessagePreview;
