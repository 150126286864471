import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FormikRadioInput = ({ name = 'formikRadioInputName', id, value, onChange, label, checked }) => (
  <label htmlFor={id} className="FormikRadioInput-container">
    <input id={id} type="radio" name={name} value={value} checked={checked} onChange={onChange} />
    <span className="FormikRadioInput-text">{label}</span>
    <span className="FormikRadioInput-checkmark"></span>
  </label>
);

FormikRadioInput.propTypes = {
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
};

export default FormikRadioInput;
