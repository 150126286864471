import React, { ReactElement, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { EventType } from '~/profiles/pages/Transformations/types';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import { useAPI } from '~/common';
import { buildUrl } from '~/common/history';
import Icons from '~/components/src/Icons';
import * as api from '~/profiles/pages/Transformations/dataService';
import Spin from '~/components/src/Spin';
import { Link } from '~/components';
import CardHeader from '~/profiles/pages/Transformations/components/CardHeader';
import { getIcon } from '~/common/modules/rules/utils';
import { useCopyToClipboard } from 'usehooks-ts';
import { getRuleItemUrl } from './util';
import { fetchInternalEventProperties } from './partners/facebookConversions/core/api';

interface LinkedTransformationSidePanelProps {
  selectedId: string;
  handleClose: () => void;
}
interface Property {
  sourceName: string;
  destinationName: string;
}
const columnHelper = createColumnHelper<Property>();

function LinkedTransformationSidePanel({
  selectedId,
  handleClose,
}: LinkedTransformationSidePanelProps): ReactElement | null {
  const [value, copy] = useCopyToClipboard();
  const [showProperties, setShowProperties] = useState(false);
  const { data: transformation, isLoading } = useAPI(() => api.getTransformation(selectedId), [selectedId]);
  const { data: standardProperties, isLoading: isStandardPropertiesLoading } = useAPI(
    () => fetchInternalEventProperties(),
    [],
  );

  if (!transformation && !isLoading) {
    return null;
  }
  const events = transformation?.events || [];

  const getColumns = () => [
    columnHelper.accessor('sourceName', {
      header: () => <div className="text-left">{i18n.t('partners:eventConnector.sourceProperty')}</div>,
      cell: info => (
        <div
          className="group flex cursor-pointer items-center justify-between gap-2 text-left"
          onClick={() => copy(`\${${info.getValue()}}`)}
        >
          <p>{info.getValue()}</p>
          <Icons
            icon="copy"
            className="invisible h-4 w-4 text-gray-400 hover:text-gray-600 group-hover:visible"
            tooltip="copy"
          />
        </div>
      ),
    }),
    columnHelper.accessor('destinationName', {
      header: () => <div className="text-left">{i18n.t('partners:eventConnector.destinationProperty')}</div>,
      cell: info => (
        <div
          className="group flex cursor-pointer items-center justify-between gap-2 text-left"
          onClick={() => copy(`\${${info.getValue()}}`)}
        >
          <p>{info.getValue()}</p>
          <Icons
            icon="copy"
            className="invisible h-4 w-4 text-gray-400 hover:text-gray-600 group-hover:visible"
            tooltip="copy"
          />
        </div>
      ),
    }),
  ];

  const properties = (event: EventType) =>
    event?.mappings?.map(({ destinationName, sourceProperty: { sourceName, sourceValue } }) => ({
      destinationName,
      sourceName: sourceName || sourceValue || '-',
    })) || [];

  return (
    <div className="fixed right-0 top-16 flex h-full w-1/4 justify-end bg-gray-200">
      <div className="h-full w-full border-l border-gray-200 bg-white">
        {isLoading ? (
          <Spin className="mt-40" />
        ) : (
          <>
            <div className="sticky top-0">
              <div className="flex items-center justify-between border-b border-gray-200 bg-white p-8 pb-5  ">
                <div className="flex flex-col gap-2">
                  <Link
                    className="text-xl font-medium text-gray-600 hover:!text-blue-800"
                    href={buildUrl(`profiles/transformations/view/${transformation?.transformationId}`)}
                    tooltip={i18n.t('transformations:dependencies.viewTransformation')}
                  >
                    {transformation?.name}
                  </Link>
                </div>
                <BtnIcon
                  icon="close"
                  className="h-6 w-6"
                  tooltip={i18n.t('transformations:dependencies.close')}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
            {isStandardPropertiesLoading ? (
              <Spin className="mt-40" />
            ) : (
              <div className={cx('divide-y divide-solid  pb-2', { 'border-b-2 border-gray-200': showProperties })}>
                <div
                  className="flex cursor-pointer items-center justify-between bg-gray-100 px-4 py-2"
                  onClick={() => setShowProperties(!showProperties)}
                >
                  <p className="text-md font-medium text-gray-600">
                    {i18n.t('partners:eventConnector.standardProperties')}
                  </p>
                  <div className="flex items-center gap-2">
                    <span className="rounded-full bg-white p-2 text-sm">{String(standardProperties?.length)}</span>
                    <Icons icon={showProperties ? 'upArrow' : 'downArrow'} className="h-4 w-4" />
                  </div>
                </div>
                {showProperties && (
                  <>
                    {standardProperties?.map((property, i) => (
                      <div
                        key={`property-${i}`}
                        className="group flex cursor-pointer items-center justify-between gap-2 rounded-md  px-4 py-2 text-left hover:bg-gray-50"
                        onClick={() => copy(`\${${property.name}}`)}
                      >
                        <p>
                          {property.name} <span className="pl-2 text-gray-400">({property.label})</span>
                        </p>
                        <Icons
                          icon="copy"
                          className="invisible h-4 w-4 text-gray-400 hover:text-gray-600 group-hover:visible"
                          tooltip="copy"
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
            <div className="mb-24 p-4">
              {events.map((event, eventIndex) => (
                <>
                  <div key={eventIndex} className="flex w-full flex-col gap-2 rounded border border-gray-300">
                    <CardHeader
                      title={event.rule.ruleName}
                      subtitle={event.rule.ruleDefinitionName}
                      tooltipText={i18n.t(`audiences:rules.descriptions.${event.rule.clazz}`)}
                      icon={getIcon(event.rule.clazz)}
                      isReadOnly={true}
                      url={getRuleItemUrl(event.rule.clazz, event.rule.ruleDefinitionId)}
                    >
                      {properties(event).length !== 0 && (
                        <div className="mt-4 overflow-auto">
                          <UITable stickyHeader={true} data={properties(event)} columns={getColumns()} />
                        </div>
                      )}
                    </CardHeader>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LinkedTransformationSidePanel;
