import React, { useState } from 'react';
import { translate } from 'react-i18next';
import Spinner from '~/components/src/Spinner';
import Empty from '~/components/src/Empty';
import DetailContainer from '~/components/src/Table/components/DetailContainer';
import CustomerFactsList from '~/profiles/components/CustomerFactsList';
import FactDependants from './FactDependants';

import { transformIntoFactsById, getProfileFactsForTable, goToFactPage } from './utils';

const PROFILE_FACTS_TABLE_STATE_KEY = 'PROFILE_FACTS_TABLE';

const CustomerFactsComponent = ({ customerFactsState, t }) => {
  const notFoundMessage = t('customerData:notFound');

  const { value, error } = customerFactsState;

  const profileFactsById = transformIntoFactsById(value, t);

  const [selectedFactId, setSelectedFactId] = useState('');
  const [isDependantsPopupVisible, setIsDependantsPopupVisible] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  if (error) return <Empty body={notFoundMessage} />;

  if (!profileFactsById) return <Spinner />;

  const handleDependantClick = (selectedFactId, clickedDependantIndex) => {
    setIsDependantsPopupVisible(true);
    setSelectedFactId(selectedFactId);
    setSelectedTabIndex(clickedDependantIndex);
  };

  const closePopup = () => {
    setIsDependantsPopupVisible(false);
    setSelectedFactId('');
  };

  const allProfileFacts = getProfileFactsForTable(profileFactsById);
  const selectedProfileFact = profileFactsById[selectedFactId] || null;
  const selectedProfileFactName = selectedProfileFact && selectedProfileFact.name;

  const popupTitle = `${t('customerFacts:general.customerFact')} - ${selectedProfileFactName}`;

  if (!allProfileFacts.length) {
    return <Empty body={notFoundMessage} />;
  }

  return (
    <React.Fragment>
      <CustomerFactsList
        list={allProfileFacts}
        selectedCustomerFact={selectedProfileFact}
        shouldShowPropertiesColumn={false}
        shouldShowTtl={true}
        shouldShowExpireDate={true}
        shouldShowActionsMenu={false}
        onDependantIconClick={handleDependantClick}
        onViewClick={goToFactPage}
        stateKey={PROFILE_FACTS_TABLE_STATE_KEY}
      />
      <DetailContainer isVisible={isDependantsPopupVisible} title={popupTitle} onClose={closePopup}>
        <FactDependants
          factId={selectedFactId}
          factName={selectedProfileFactName}
          selectedTabIndex={selectedTabIndex}
          onTabChange={setSelectedTabIndex}
          notFoundMessage={notFoundMessage}
        />
      </DetailContainer>
    </React.Fragment>
  );
};

export default translate()(CustomerFactsComponent);
