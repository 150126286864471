angular.module('webUi.ui.support.tagtemplates.variants.form', [
    'webUi.ui.support.tagtemplates.variants.base']
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates.variants.add', {
                url: '/add?templateId',
                isReact: true,
                leftMenu: true
            })
            .state('site.support.tagtemplates.variants.edit', {
                url: '/edit/{variantId}?sequenceNr?scrollTo',
                isReact: true,
                leftMenu: true
            });
    }]);
