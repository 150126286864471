import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import SitesList from '~/context/components/SitesList';
import Heading from '~/components/src/Heading';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spinner from '~/components/src/Spinner';
import * as selectors from '~/context/sites/selectors';
import { fetchSitesIfNeeded, setFilter, removeFilter } from '~/context/sites/actions';
import { actionCreators } from 'react-redux-composable-list';
import { getAngularService } from 'ReactAngular/react.service';
import LinkIcon from '~/components/src/LinkIcon';
import BtnIcon from '~/components/src/BtnIcon';

const SITES_KEY = 'CONTEXT/SITES_LIST';
const FILTER_KEY = 'CONTEXT/SITES_LIST_FILTER';

const nameFilterFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.siteNumber.toString().indexOf(query.toLowerCase()) !== -1;

class SitesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { isContextAdmin: false };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isContextAdmin: context.isContextAdmin(),
      });
    });
    this.props.fetchSites();
  }

  render() {
    const { t, isFetching, onFilterChange, filter } = this.props;
    const ContextService = getAngularService(document, 'ContextService');
    return (
      <div>
        <Heading title={t('list.header')}>
          <SearchElement
            className="Aligner-item"
            value={filter}
            onChange={e => onFilterChange(e.target.value)}
            placeholder={t('table.actions.search')}
          />
          <BtnIcon
            icon="download"
            onClick={() => ContextService.exportSites(this.props.list)}
            testHook="exportCSV"
            tooltip={t('list.exportSites')}
          />
          {!this.state.isContextAdmin ? null : (
            <LinkIcon
              className="h-8 w-8"
              icon="add"
              color="blue"
              url={buildUrl('context/sites/add')}
              tooltip={t('list.addNew')}
              testHook="addNew"
            />
          )}
        </Heading>
        <SitesList {...this.props} />
        <Spinner isActive={isFetching} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: selectors.isFetchingSites(state),
  filter: selectors.getFilter(state),
  list: selectors.getSites(state),
  stateKey: SITES_KEY,
  siteUrl: item => buildUrl(`context/sites/view/${item.siteId}`),
});

const mapDispatchToProps = dispatch => ({
  fetchSites: () => dispatch(fetchSitesIfNeeded()),
  onFilterChange: query => {
    if (query !== '') {
      dispatch(actionCreators.doSetFilter(SITES_KEY, FILTER_KEY, nameFilterFn(query)));
      dispatch(setFilter(query));
    } else {
      dispatch(actionCreators.doRemoveFilter(SITES_KEY, FILTER_KEY));
      dispatch(removeFilter());
    }
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('sites'))(SitesListContainer);
