/* eslint-disable no-prototype-builtins */
/**
 * @description Angular module for UrlBuilder -> View.
 * @class UrlBuilderCampaignViewController
 * @namespace webUi.ui.tagmanagement.urlbuilder.campaign.view
 * @memberOf webUi.ui.tagmanagement.urlbuilder.campaign
 */
angular
    .module('webUi.ui.tagmanagement.urlbuilder.campaign.view', [
        'webUi.service', 
    ])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.tagmanagement.urlbuilder.campaign.view', {
                url: '/view/{campaignId}',
                isReact: true,
            });
        },
    ]);
