import React from 'react';
import { Field } from 'redux-form';
import { FormSection } from '~/components/src/Containers';
import {
  endDateIsSameOrAfterStartDate,
  startDateIsSameOrBeforeEndDate,
  isStartDateAndEndDateInRange,
  isRequired,
  isValidDate,
} from '~/common';
import { ReduxFormDateTimeField } from '~/components/src/Form/Fields/ReduxFormFields';
import constants from '../constants';

const formEndDateIsAfterStartDate = (endDate, values) => endDateIsSameOrAfterStartDate(endDate, values.range);
const formStartDateIsBeforeEndDate = (startDate, values) => startDateIsSameOrBeforeEndDate(startDate, values.range);
const formStartDateAndEndDateInRange = (endDate, values) =>
  isStartDateAndEndDateInRange(constants.DATE_RANGE, values.range);
const ExportRangeForm = props => {
  const { t, touch } = props.data;
  return (
    <FormSection title={t('new.rangeHeader')} description={t('new.rangeSubHeader')}>
      <Field
        label={t('new.rangeStartDate.title')}
        name="startDate"
        component={ReduxFormDateTimeField}
        validate={[isRequired, isValidDate, formStartDateIsBeforeEndDate]}
        timeFormat={false}
        touch={touch}
      />

      <Field
        label={t('new.rangeEndDate.title')}
        name="endDate"
        component={ReduxFormDateTimeField}
        validate={[isRequired, isValidDate, formEndDateIsAfterStartDate, formStartDateAndEndDateInRange]}
        timeFormat={false}
        touch={touch}
      />
    </FormSection>
  );
};

export default ExportRangeForm;
