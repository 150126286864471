import * as fromModals from '~/modals';

export const openDeleteConfigurationModal = (partnerNumber, configurationId, requestNumberRemoval, t) => dispatch => {
  dispatch(
    fromModals.showDeleteModal({
      title: t('profileIdentities:deleteModal.title'),
      message: t('profileIdentities:deleteModal.message', { partnerNumber }),
      onConfirm: () => requestNumberRemoval(configurationId),
    }),
  );
};
