import React, { useState } from 'react';
import cx from 'classnames';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import Icons from '~/components/src/Icons';
import { NewPathsType, SideBarProps } from '../types';
import { addExpandToTreeData, expandDirectory, filterTree, getLastItem } from '../utils';
import { globalNode } from '../constants';

const rowHeight = 32;
const maxScrollbarHeight = 17; // Taken from here: https://codepen.io/sambible/post/browser-scrollbar-widths
const lastRowHeight = rowHeight + maxScrollbarHeight;
const styleToHideHorizontalScrollbar = { marginBottom: `-${maxScrollbarHeight}px` };

const IndexedPathTree = ({ newPaths, path: pathName, changePath, searchQuery }: SideBarProps): React.ReactElement => {
  const [treeData, setTreeData] = useState<NewPathsType[]>(() => [
    globalNode,
    ...addExpandToTreeData(newPaths, pathName),
  ]);
  const lastItem = getLastItem(treeData);

  const handleNodeClick = (rowData: any, treeData: NewPathsType[]) => {
    const { path, node } = rowData;
    expandDirectory(path, treeData, setTreeData);
    changePath(node.id);
  };

  const tree = filterTree(treeData || [], searchQuery);
  return (
    <div
      className={cx('IndexedPathTree -ml-1 box-border h-auto flex-1 px-4 py-2 duration-100 ease-in')}
      style={styleToHideHorizontalScrollbar}
    >
      <SortableTree
        canDrag={false}
        getNodeKey={({ node }: { node: any }) => node.id}
        generateNodeProps={(treeItem: any) => ({
          title: () => {
            const { node, path } = treeItem;
            const isLeafNode = path.length === 1;
            const isItemSelected = node.id === pathName;

            return (
              <p
                className={cx('flex cursor-pointer items-center gap-2 text-base', {
                  'font-medium text-blue-600': isItemSelected,
                  'text-gray-600 hover:text-blue-500': !isItemSelected,
                })}
                onClick={() => handleNodeClick({ node, path, isLeafNode }, tree)}
              >
                <Icons
                  icon={
                    // eslint-disable-next-line no-nested-ternary
                    isLeafNode
                      ? node.expanded && node.children.length !== 0
                        ? 'folderOpen'
                        : 'folderFilled'
                      : 'folder'
                  }
                  className={cx('h-5 w-5')}
                />
                <span className="t-treeItem">{node.name}</span>
              </p>
            );
          },
        })}
        onChange={(updatedTreeData: NewPathsType[]) => {
          setTreeData(updatedTreeData);
        }}
        rowHeight={({ node }: { node: any }) => (node.id === lastItem?.id ? lastRowHeight : rowHeight)}
        theme={FileExplorerTheme}
        treeData={tree}
      />
    </div>
  );
};

export default IndexedPathTree;
