angular.module('webUi.service.tagmanagement.websitePath', ['restangular'])

    .factory('WebsitePathService', ['Restangular', '$q', function (Restangular, $q) {

        var BASE_PATH = 'tagmanagement/websitePaths';

        var getPathsLike = function(parentPath, subPath){
            return Restangular.one(BASE_PATH + '/pathsLike').get({
                parentPath: parentPath,
                subPath: subPath
            });
        };

        return {
            removeWebsitePath: function (path) {
                return Restangular.one(BASE_PATH + '/remove').post('', {
                    path: path
                });
            },
            addWebsitePath: function (path) {
                return Restangular.one(BASE_PATH + '/add').post('', {
                    path: path
                });
            },

            getPathsLike: getPathsLike,

            getChildPaths: function (viewVal, parentNode) {

                var deferred = $q.defer();
                function getParentPath(viewVal, nodus) {
                    function getNodePath(nodus) {
                        if (_.isUndefined(nodus) || _.isArray(nodus)) {
                            return '';
                        } else {
                            return nodus.id;
                        }
                    }

                    var parentPath = getNodePath(nodus);
                    var nestedPath = viewVal.substring(0, _.lastIndexOf(viewVal, '|'));
                    if (_.isEmpty(parentPath)) {
                        return nestedPath;
                    } else {
                        if (_.isEmpty(nestedPath)) {
                            return parentPath;
                        } else {
                            return parentPath + '|' + nestedPath;
                        }
                    }
                }

                function getSubPath(viewVal) {
                    return viewVal.substring(_.lastIndexOf(viewVal, '|') + 1, viewVal.length);
                }

                var parentPath = getParentPath(viewVal, parentNode);
                var subPath = getSubPath(viewVal);

                getPathsLike(parentPath, subPath).then(function (resolvedResults) {
                //don't add the search value as first if it's identical to smth in the list
                    if (resolvedResults.indexOf(viewVal) === -1) {
                        resolvedResults.unshift(viewVal);
                        deferred.resolve(resolvedResults);
                    }
                });

                return deferred.promise;
            },

            /**
         * @param path
         * @returns {*}
         */
            pathExists: function (path) {
                return Restangular.one(BASE_PATH + '/pathExists').get({
                    path: path
                });
            },

            /**
         * retrieves the base path through which all rest requests should go
         * @returns {String} the base path
         */
            getBasePath: function () {
                return BASE_PATH;
            }
        };
    }]);
