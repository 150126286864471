angular.module('webUi.ui.support.base', ['webUi.ui.siteState'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support', {
                'abstract': true,
                url: '/support',
                resolve : {
                    allowView: ['securityContext', function getPermissions(securityContext) {
                        if (securityContext.isContextAdmin() || securityContext.isSupportEngineer()) {
                            return true;
                        }
                        throw new AccessDeniedError('No access allowed');
                    }],
                    allowEdit : ['securityContext', function getPermissions(securityContext){
                        return securityContext.isContextAdmin() || securityContext.isSupportEngineer();
                    }]
                }
            });
    }]);
