angular.module('webUi.ui.content.campaigns.ads.view', [
    'webUi.ui.content.campaigns.base',
    'webUi.ui.content.campaigns.ads.delete',
    'webUi.ui.content.campaigns.ads.view.adRules',
    'webUi.ui.content.campaigns.ads.view.confirmDataFeedChange',
    'webUi.ui.content.campaigns.ads.view.confirmLibraryChange',
    'webUi.ui.content.campaigns.ads.conditions',
    'webUi.ui.content.campaigns.ads.creativeList'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.ads.view', {
                url : '/view/{adId}?autoOpenConditions',
                views : {
                    'mainContent@' : {
                        controller : 'AdservingCampaignAdViewController',
                        templateUrl : 'ui/content/campaigns/ads/view/view.tpl.html'
                    }
                },
                leftMenu : true,
                resolve: {
                    ad: ['$stateParams', 'AdService', function($stateParams, AdService){
                        return AdService.getAd($stateParams.adId);
                    }],
                    dimensions: ['CreativeService', 'ad', function(CreativeService, ad){
                        return CreativeService.getCreativeDimensions(ad.campaignId);
                    }],
                    mediaLibraries : ['MediaLibraryService', function(MediaLibraryService) {
                        return MediaLibraryService.getMediaLibraries();
                    }],
                    campaign: ['CampaignService', 'ad', function(CampaignService, ad) {
                        return CampaignService.getCampaign(ad.campaignId);
                    }],
                    hasDataManagementModule: ['SecurityService', '$q', function(SecurityService, $q){
                        var def = $q.defer();
                        SecurityService.getSecurityContext().then(function(securityContext){
                            def.resolve(securityContext.hasModule('DATA_MANAGEMENT'));
                        });
                        return def.promise;
                    }],
                    dataFeeds: ['DataFeedService', 'hasDataManagementModule', function(DataFeedService, hasDataManagementModule) {
                        if (hasDataManagementModule) {
                            return DataFeedService.getDataFeeds();
                        }
                        return [];
                    }],
                    profileVariables: ['VariableService', 'hasDataManagementModule', function(VariableService, hasDataManagementModule) {
                        if (hasDataManagementModule) {
                            return VariableService.getVariables();
                        }
                        return [];
                    }],
                    segments: ['SegmentService', 'hasDataManagementModule', 'hasAudiencesView', function(SegmentService, hasDataManagementModule, hasAudiencesView) {
                        if (hasDataManagementModule && hasAudiencesView) {
                            return SegmentService.getSegments();
                        }
                        return [];
                    }]
                }
            });
    }])

    .controller('AdservingCampaignAdViewController',[
        '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$q',
        'AdService', 'CreativeService', 'ValidService', 'PubSubService',
        'Utils', 'hasAdservingEdit', 'hasDataManagementModule',
        'ad', 'dimensions', 'mediaLibraries', 'campaign',  'dataFeeds', 'profileVariables', 'segments', 'hasAudiencesView', 'ToasterService',
        function AdservingCampaignAdViewController(
            $scope, $state, $stateParams, $timeout, $uibModal, $q,
            AdService, CreativeService, ValidService, PubSubService,
            Utils, hasAdservingEdit, hasDataManagementModule,
            ad, dimensions, mediaLibraries, campaign, dataFeeds, profileVariables, segments, hasAudiencesView, toast) {

            $scope.ad = ad;
            $scope.campaign = campaign;
            $scope.mediaLibraries = mediaLibraries;
            $scope.hasAdservingEdit = hasAdservingEdit;
            $scope.hasAudiencesView = hasAudiencesView;
            $scope.dataFeeds = dataFeeds;
            $scope.profileVariables = profileVariables;
            $scope.hasDataManagementModule = hasDataManagementModule;
            $scope.segmentMap = Utils.objectMap(segments, 'segmentId');

            $scope.selectOptions = [{
                label:'Profile variable',
                value:'profileVariable'
            },{
                label:'Placement parameter',
                value:'placementParam'
            }];

            $scope.prefillSelectedOption = function() {
                var isProfileVarSelected = !_.isNull(ad.profileVariable) && !_.isUndefined(ad.profileVariable);
                $scope.selectedOption = {
                    type: isProfileVarSelected ? 'profileVariable' : 'placementParam'
                };
            };

            $scope.prefillSelectedOption();

            $scope.selectOption = function(option) {
                $scope.selectedOption.type = option.value;
            };

            //show corresponding campaign in the left tree
            PubSubService.publishTypeSelected(PubSubService.TYPES.CONTENT.CAMPAIGN, {id: $scope.ad.campaignId});

            // automatically open condition dialog based on state param
            if(hasAdservingEdit && $state.params.autoOpenConditions) {
                $timeout(function() {
                    $uibModal.open({
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: 'modal modal-huge',
                        templateUrl: 'ui/content/campaigns/ads/conditions/configure.tpl.html',
                        controller: 'AdServingConditionConfigureDialogController',
                        resolve: {
                            dialogsModel: function() {
                                return {
                                    ad: ad
                                };
                            }
                        }
                    });
                });
            }

            $scope.adNameOverrideOptions = {
                editSuccessCallback: function(newValue){
                    return AdService.updateAdName($scope.ad.adId, newValue);
                }
            };

            $scope.clickUrlOverrideOptions = {
                editSuccessCallback: function(newValue){
                    return AdService.updateAdClickUrl($scope.ad.adId, newValue);
                }
            };

            $scope.dataFeedOverrideOptions = function(newValue, oldValue){
                var def = $q.defer();
                var newDataFeedId = ValidService.isEmpty(newValue) ? null : newValue.dataFeedId;
                var oldDataFeedId = ValidService.isEmpty(oldValue) ? null : oldValue.dataFeedId;

                // don't do anything if the old value is equal to the new value
                if ( _.isEqual(newDataFeedId, oldDataFeedId) ){
                    return;
                }

                var d = $uibModal.open( {
                    backdrop: 'static',
                    keyboard: true,
                    templateUrl: 'ui/content/campaigns/ads/view/confirmDataFeedChange.tpl.html',
                    controller: 'ConfirmDataFeedChangeController as vm'
                });

                d.result.then(function (result) {
                    if (result) {
                        AdService.updateAdDataFeed($scope.ad.adId, newDataFeedId).then(function (res) {
                            def.resolve(res);
                            AdService.getAd($stateParams.adId).then(function(res) {
                                $scope.ad = res;
                            });
                        });
                    } else {
                        def.resolve(false);
                    }
                });
                return def.promise;
            };

            $scope.profileVariablesOverrideOptions = {
                editSuccessCallback: function(newValue){
                // Clear the placement param
                    AdService.updateAdPlacementParameter($scope.ad.adId, null);
                    $scope.ad.placementParameter = '';
                    return AdService.updateAdProfileVariable($scope.ad.adId, newValue.variableId);
                }
            };

            $scope.placementParameterOverrideOptions = {
                editSuccessCallback: function(newValue){
                // Clear the profile variable
                    AdService.updateAdProfileVariable($scope.ad.adId, null);
                    $scope.ad.profileVariable = null;
                    return AdService.updateAdPlacementParameter($scope.ad.adId, newValue);
                }
            };

            /**
         * A callback to the editing of medialibrary to notify the user with a popup that changing a medialibrary might make their ads to fail
         * @param {MediaLibrary} newValue
         * @param {MediaLibrary} oldValue
         * @param {type} editableObjName
         * @param {scope} the scope of the editable
         * @returns {promise} a promise of the dialog or undefined if the newValue is the same as oldValue
         */
            $scope.mediaLibraryOverrideOptions =  function(newValue, oldValue){
                var newMediaLibraryId = ValidService.isEmpty(newValue) ? null :  newValue.mediaLibraryId;
                var oldMediaLibraryId = ValidService.isEmpty(oldValue) ? null :  oldValue.mediaLibraryId;

                // don't do anything if the old value is equal to the new value
                if ( _.isEqual(newMediaLibraryId, oldMediaLibraryId) ){
                    return;
                }

                var def = $q.defer();
                var d = $uibModal.open( {
                    backdrop: 'static',
                    keyboard: true,
                    templateUrl: 'ui/content/campaigns/ads/view/confirmLibraryChange.tpl.html',
                    controller: 'ConfirmLibraryChangeController as vm'
                });

                d.result.then(function(result) {
                    if (result) {
                        AdService.updateAdMediaLibrary($scope.ad.adId, newMediaLibraryId).then(function(result) {
                            def.resolve(result);
                        });
                    } else {
                        def.resolve(false);
                    }
                });
                return def.promise;
            };

            PubSubService.subscribeCreativeListChanged($scope, function(){
                CreativeService.getCreativesForAd($scope.ad.adId).then(function(creatives) {
                    $scope.ad.creatives = creatives;
                });
            });

            $scope.confirmDeleteAd = function() {
                var d = $uibModal.open({
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/content/campaigns/ads/delete/delete.tpl.html',
                    controller: 'AdservingAdDeleteController as vm',
                    resolve: {
                        dialogsModel : function() {
                            return {
                                data: {
                                    adId : $scope.ad.adId,
                                    adName : $scope.ad.name,
                                    campaignId: $scope.ad.campaignId
                                }
                            };
                        }
                    }
                });
                d.result.then(function(result) {
                    if(result) {
                        AdService.deleteAd(result.adId).then(function(){
                            toast.success('Ad deleted successfully');
                            $state.transitionToStateWithSite('site.content.campaigns.view', {
                                campaignId: result.campaignId
                            });
                        });
                    }
                });
            };
        }]);
