angular.module('webUi.ui.tagmanagement.cookiePermission.addGroupModal', ['ngResource', 'webUi.service', 'ui.bootstrap'])

    .controller('AddGroupModalController', ['$scope', 'dialogsModel', '$uibModalInstance',
        function AddGroupModalController( $scope,   dialogsModel,   $uibModalInstance) {

            $scope.isUnique = dialogsModel.isUnique;
            $scope.group = {};

            $scope.cancel = function() {
                $uibModalInstance.close(false);
            };

            $scope.save = function() {
                dialogsModel.onSave($scope.group);
                $uibModalInstance.close(true);
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };

        }]);
