import React from 'react';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import RuleConnectorPicker from '~/components/src/RuleConnectorPicker';
import ConnectorCardGrid from '~/components/src/ConnectorCardGrid';
import { I18NextT } from '~/components/src/Common/types';
import { SelectedConnector } from '~/components/src/ConnectorPickerCard/types';
import { PartnerTypeListItem, ConnectorListItem } from './types';

import './styles.scss';

interface ConnectorPickerProps {
  listItems: PartnerTypeListItem[] | ConnectorListItem[];
  testHook: string;
  className: string;
  t: I18NextT;
  onSelect: (selectedConnector: SelectedConnector) => void;
  actionName: string;
  isEventType?: boolean;
  areDisabledItemsVisible?: boolean;
}

const ConnectorPicker = ({
  listItems,
  testHook = 'connectorPicker',
  className = 'ConnectorPicker-container',
  t,
  onSelect,
  actionName,
  areDisabledItemsVisible,
  isEventType = false,
}: ConnectorPickerProps) => (
  <div className={classNames(className, `t-${testHook}`)}>
    <RuleConnectorPicker actionName={actionName}>
      <ConnectorCardGrid
        listItems={listItems}
        isSearchable={true}
        searchPlaceholder={t('actions.search')}
        onSelect={onSelect}
        emptyMessage={t('list.emptySearch')}
        isEventType={isEventType}
        areDisabledItemsVisible={areDisabledItemsVisible}
      />
    </RuleConnectorPicker>
  </div>
);

export default translate('common')(ConnectorPicker);
