import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';
import RowAction from './RowAction';
import enhanced from './rowCompose';
import Dropdown from '../../../Dropdown';
import { tableContextWrapper } from '../context';

const renderActions = props => {
  const maxIconsVisible = props.iconsLimit || 2;
  if (props.actions.length > maxIconsVisible) {
    return (
      <RowAction
        tooltip=""
        isDisabled={false}
        icon="more"
        onClick={() => props.toggleMore(!props.showMore)}
        testHook="more"
      />
    );
  }
  return props.actions.map(action => (
    <RowAction
      key={action.tooltip}
      tooltip={action.tooltip}
      isDisabled={action.isDisabled}
      icon={action.icon}
      onClick={action.onClick}
      testHook={action.testHook}
    />
  ));
};

const RowActions = props => {
  const Element = props.isTable ? 'td' : 'div';

  return (
    <Element
      className={cx('Table-cell Table-actions', props.className, {
        'Table-actions--active': props.showMore,
      })}
    >
      <div className="inline-flex gap-1">
        {renderActions(props)}
        {props.showMore && (
          <Dropdown
            actions={props.actions}
            className={props.dropdownClass}
            toggleDropdown={() => props.toggleMore(!props.showMore)}
          />
        )}
      </div>
    </Element>
  );
};

RowActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool.isRequired,
      icon: PropTypes.func,
      onClick: PropTypes.func.isRequired,
      testHook: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ),
  isTable: PropTypes.bool.isRequired,
  showMore: PropTypes.bool,
  toggleMore: PropTypes.func,
  dropdownClass: PropTypes.string,
  className: PropTypes.string,
  setDropdownPosition: PropTypes.func,
};

export default enhanced(tableContextWrapper(RowActions));
