angular
  .module('webUi.ui.tagmanagement.tags.view.header', [
    'webUi.ui.tagmanagement.base',
    'webUi.ui.tagmanagement.tags.view.codepopup',
    'rt.encodeuri',
  ])
  .controller('TagViewHeaderController', [
    '$scope',
    'TagmanagementService',
    '$uibModal',
    '$state',
    'ValidService',
    'ToasterService',
    'GoogleAnalyticsService',
    function TagViewHeaderController(
      $scope,
      TagmanagementService,
      $uibModal,
      $state,
      ValidService,
      toast,
      GoogleAnalyticsService,
    ) {
      var tagId = $scope.tagViewPojo.tag.tagId;
      $scope.tagId = tagId;
      $scope.historicVersion = !$scope.tagViewPojo.latestVersion;

      // add callbacks to the directive
      $scope.nameOverrideOptions = {
        editSuccessCallback: function (newValue) {
          return TagmanagementService.updateName(tagId, newValue).then(function (modification) {
            $scope.handleModification(modification);
            GoogleAnalyticsService.handleGAEvent('Tag Edited');
          });
        },
      };

      $scope.hasCodePopup = function (tag) {
        return tag.content.clazz === 'TemplateTagContent';
      };

      $scope.showCodePopup = function (tag) {
        $uibModal.open({
          windowClass: 'modal-huge',
          templateUrl: 'ui/tagmanagement/tags/view/codepopup/codepopup.tpl.html',
          controller: 'TagViewCodePopupDialogController',
          resolve: {
            dialogsModel: function dialogsModel() {
              return {
                tag: tag,
                hasTagEdit: $scope.hasTagEdit,
              };
            },
          },
        });
      };

      $scope.deleteTag = function () {
        var dialog = $uibModal.open({
          templateUrl: 'ui/tagmanagement/tags/delete/delete.tpl.html',
          controller: 'TagmanagementTagDeleteController',
          resolve: {
            dialogsModel: function () {
              return {
                tag: $scope.tagViewPojo.tag,
              };
            },
          },
        });
        dialog.result.then(function (confirm) {
          if (confirm) {
            TagmanagementService.deleteTag(tagId).then(function () {
              toast.success('Tag deleted successfully');
              $state.transitionToStateWithSite('site.tagmanagement.tags.list', {
                location: TagmanagementService.GLOBAL_PATH,
              });
            });
            GoogleAnalyticsService.handleGAEvent('Tag Deleted');
          }
        });
      };

      $scope.showErrorStats = function () {
        $scope.isDisableShowErrorStats = true;
        $uibModal
          .open({
            windowClass: 'modal-huge',
            templateUrl: 'ui/tagmanagement/tags/errors/errors.tpl.html',
            controller: 'TagErrorsViewController',
            resolve: {
              tagViewPojo: function () {
                return $scope.tagViewPojo;
              },
              recentTagErrors: function () {
                return TagmanagementService.getRecentTagErrors(tagId);
              },
              errorsPerBrowser: function () {
                return TagmanagementService.getErrorsPerBrowser(tagId);
              },
              errorStats: function () {
                return TagmanagementService.getErrorStats(tagId);
              },
            },
          })
          .result.then(function () {
            $scope.isDisableShowErrorStats = false;
          });
      };

      $scope.showTagHistory = function () {
        $scope.isDisableShowTagHistory = true;
        $uibModal
          .open({
            windowClass: 'modal-huge',
            templateUrl: 'ui/tagmanagement/tags/history/history.tpl.html',
            controller: 'TagHistoryController',
            resolve: {
              tagViewPojo: function () {
                return $scope.tagViewPojo;
              },
              tagHistory: function () {
                return TagmanagementService.getTagHistory(tagId);
              },
              deployMap: function () {
                return TagmanagementService.getTagDeployMap(tagId);
              },
            },
          })
          .result.then(function () {
            $scope.isDisableShowTagHistory = false;
          });
      };

      $scope.markFixed = function (tagPojo) {
        TagmanagementService.markFixed(tagPojo.tag.tagId).then(function () {
          tagPojo.hasExceededErrorThreshold = false;
        });
      };

      $scope.toggleStatus = function (tag) {
        TagmanagementService.updateEnabled(tag.tagId, !tag.enabled).then(function () {
          $scope.handleModification();
        });
        GoogleAnalyticsService.handleGAEvent('Tag Edited');
      };

      $scope.priorityOverrideOptions = {
        editSuccessCallback: function (newValue) {
          return TagmanagementService.updatePriority(tagId, newValue).then(function (modification) {
            $scope.handleModification(modification);
            GoogleAnalyticsService.handleGAEvent('Tag Edited');
          });
        },
      };

      /**
       * Total Errors
       * @type {string|*}
       */
      TagmanagementService.getErrorCount(tagId).then(function (errorCount) {
        $scope.tagErrorCount = '';
        if (errorCount > 0) {
          var rounded = Math.round(errorCount * 10) / 10;
          if (rounded - Math.floor(rounded) > 0) {
            $scope.tagErrorCount = rounded.toFixed(1) + '%';
          } else {
            $scope.tagErrorCount = rounded.toFixed(0) + '%';
          }
        }
      });

      /**
       *
       */
      $scope.showTagAdvancedSettings = function () {
        $uibModal.open({
          windowClass: 'modal-large',
          templateUrl: 'ui/tagmanagement/tags/view/advanced-settings.tpl.html',
          controller: 'TagViewAdvancedSettingsController',
          resolve: {
            dialogsModel: function dialogsModel() {
              return {
                tagViewPojo: $scope.tagViewPojo,
                hasTagEdit: $scope.hasTagEdit,
                tagExecutionTypes: $scope.tagExecutionTypes,
                handleModification: $scope.handleModification,
              };
            },
          },
        });
      };

      $scope.isNumberRange = ValidService.isNumberRange;
    },
  ]);
