import { get } from 'lodash';

/**
 * Get Basic Information for current Site
 * @param state
 */
export const getSiteInfo = state => get(state, 'admin.site.info');

/**
 * Get current Site clientId
 * @param state
 */
export const getSiteClientId = state => get(state, 'admin.site.info.clientId');

/**
 * Is currently fetching site info
 * @param state
 */
export const isFetchingInfo = state => get(state, 'admin.site.ui.isFetchingInfo');

const selectors = {
  getSiteInfo,
  isFetchingInfo,
};

export default selectors;
