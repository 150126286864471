import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from 'react-redux-composable-list';
import { Pagination } from '../../components';

const DEFAULT_PAGINATION_SIZE = 15;

const paginateList = (list, size) =>
  list.reduce((memo, item, i) => {
    const newMemo = [...memo];
    if (i % size) {
      newMemo[newMemo.length - 1].push(item);
    } else {
      newMemo[newMemo.length] = [item];
    }
    return newMemo;
  }, []);

const withPaginate =
  (configuration = {}) =>
  Table => {
    const WithPaginate = props => (
      <React.Fragment>
        <Table {...props} />
        <Pagination
          stateKey={props.stateKey}
          paginatedLists={props.paginatedLists}
          currentPage={props.currentPage}
          onPaginate={props.onPaginate}
          dotted={false}
        />
      </React.Fragment>
    );

    const mapStateToProps = (state, { list, stateKey }) => {
      const paginatedLists = paginateList(list, configuration.size || DEFAULT_PAGINATION_SIZE);
      const currentPage = selectors.getCurrentPage(state, stateKey, paginatedLists);
      const paginatedList = paginatedLists[currentPage];
      return {
        paginatedLists,
        list: paginatedList || [],
        currentPage,
      };
    };

    const mapDispatchToProps = (dispatch, { stateKey }) => ({
      onPaginate: bindActionCreators(page => actionCreators.doSetPage(stateKey, page), dispatch),
    });

    return connect(mapStateToProps, mapDispatchToProps)(WithPaginate);
  };

export default withPaginate;
