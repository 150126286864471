angular.module('webUi.service.csrf', [
    'restangular'
])
    .factory('CsrfService', ['$q', 'Restangular', '$http',
        function CsrfService($q, Restangular, $http) {

            var _csrfToken;
            /**
         * Sets the csrfToken on all requests
         */
            var setCsrfToken = function setCsrfToken (csrfToken) {
                _csrfToken = csrfToken;
                $http.defaults.headers.common['X-CSRF-TOKEN'] = _csrfToken;
            };

            var getCsrfToken = function getCsrfToken(){
                return _csrfToken;
            };

            return {
                setCsrfToken:setCsrfToken,
                getCsrfToken:getCsrfToken
            };
        }]);
