import React, { ReactElement, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import { useApiWithState } from '~/common';
import { Notification } from '~/components';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import CircleProgress from '~/components/src/CircleProgress';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { AUDIENCE_STEPS } from '~/workflows/constants';
import { fetchJourneyTree } from '~/workflows/dataService';
import { AudienceFormProps } from '~/workflows/types';
import { formatExpiryTime, getExperimentNodes, useQuery } from '~/workflows/util';
import { AudienceContext } from './AudienceContext';

export const useTree = (workflowId: string) => {
  const [validations, setValidations] = useState<boolean[]>([]);
  const {
    state,
    isLoading,
    refetch: refetchTree,
    setState: setTree,
  } = useApiWithState(() => fetchJourneyTree(workflowId), []);

  const triggerId = state?.nodes?.nodeId;
  const goals = state?.goals || [];
  const exitConditions = state?.exitConditions || [];
  const rootNodeChilds = state?.nodes?.children || [];
  const experimentSteps = state
    ? getExperimentNodes(state?.nodes).experimentSteps.map(
        ({ nodeId, experimentVariant: { name, weight, experimentVariantId } }: any) => ({
          nodeId,
          name,
          weight,
          experimentVariantId,
        }),
      )
    : [];
  const percentage = [
    1, // 1.Configure Audience
    triggerId ? 1 : 0, // 2.Configure Target Rules
    experimentSteps.length > 0 ? 1 : 0, // 3.Configure Experiments (Optional)
    rootNodeChilds?.length > 0 ? 1 : 0, // 4.Configure Connectors
    goals.length > 0 ? 1 : 0, // 5.Configure Goals (Optional)
    exitConditions.length > 0 ? 1 : 0, // 6.Configure Exit Conditions (Optional)
  ];
  return {
    treeIsloading: !state || isLoading,
    triggerId,
    goals,
    exitConditions,
    rootNodeChilds,
    validations,
    setValidations,
    refetchTree,
    setTree,
    experimentSteps: state
      ? getExperimentNodes(state?.nodes).experimentSteps.map(
          ({ nodeId, experimentVariant: { name, weight, experimentVariantId } }: any) => ({
            nodeId,
            name,
            weight,
            experimentVariantId,
          }),
        )
      : [],
    percentage,
    calcTotalPercentage: () => {
      const sum = percentage.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 15;
      return sum === 90 ? 100 : sum;
    },
  };
};

const AudienceForm = ({ audienceData, refetchAudience }: AudienceFormProps): ReactElement => {
  const { hasWorkflowAudienceEdit } = useContext(WorklfowContext);
  const [firstTreeLoading, setFirstTreeLoading] = useState(false);
  const query = useQuery();
  const [_searchParams, setSearchParams] = useSearchParams();
  const step = query.get('step');
  const nodeId = query.get('nodeId');
  const [selectedOption, setSelectedOption] = useState<number>(Number(step) || 0);

  const RightPanel = AUDIENCE_STEPS[selectedOption].component;
  const params = useParams();
  const workflowId = params.workflowId || '';

  const {
    triggerId = null,
    experimentSteps = [],
    refetchTree,
    rootNodeChilds = [],
    goals,
    exitConditions,
    validations,
    setValidations,
    treeIsloading,
    setTree,
    calcTotalPercentage,
    percentage,
  } = useTree(workflowId);

  useEffect(() => {
    if (!treeIsloading) {
      setFirstTreeLoading(true);
    }
  }, [treeIsloading]);

  const updateSearchParams = (nodeId?: string) => {
    setSearchParams({
      step: selectedOption.toString(),
      ...(nodeId ? { nodeId } : {}),
    });
  };

  const goToPrevStep = () => {
    updateSearchParams();
    setSelectedOption(selectedOption ? selectedOption - 1 : 0);
  };

  const goToNextStep = () => {
    updateSearchParams();
    setSelectedOption(selectedOption + 1);
  };

  const canSelectStep = (step: number) => {
    if (!hasWorkflowAudienceEdit) {
      return false;
    }
    switch (step) {
      case 0:
      case 1:
        return true;
      case 2:
      case 3:
        return !!triggerId;
      case 4:
      case 5:
        return !!triggerId && rootNodeChilds?.length > 0;
      default:
        return false;
    }
  };

  useEffect(() => {
    setSearchParams({
      step: selectedOption.toString(),
      ...(nodeId ? { nodeId } : {}),
    });
  }, [selectedOption]);

  return (
    <AudienceContext.Provider
      value={{
        triggerId,
        experimentSteps,
        workflowId,
        goToNextStep,
        refetchTree,
        rootNodeChilds,
        goals,
        exitConditions,
        validations,
        setValidations,
        setTree,
        updateSearchParams,
        isOptional: AUDIENCE_STEPS?.[selectedOption]?.isOptional,
        audienceName: audienceData?.name,
      }}
    >
      <>
        {firstTreeLoading ? (
          <div>
            <div className="sticky top-0 z-[1] flex items-center justify-between gap-2 border-b bg-white p-3 text-2xl">
              <div className="flex gap-2">
                <Icons className="w-7 text-blue-600" icon="audience" />
                <div className="flex flex-col">
                  {audienceData?.name}
                  <div className="flex items-center gap-1 text-sm text-gray-500">
                    <Icons icon="schedule" className="h-4 w-4" />
                    {formatExpiryTime(audienceData?.expiryTime)}
                  </div>
                </div>
              </div>
              <CircleProgress size={10} percent={calcTotalPercentage()} strokeWidth={12} />
            </div>
            <div className="flex h-full">
              <div className="flex h-full w-[30%] flex-col gap-4 overflow-y-auto border-r bg-white px-4 py-2 xl:w-[20%]">
                {AUDIENCE_STEPS.map((step, index) => (
                  <div
                    onClick={() => {
                      if (canSelectStep(index)) {
                        updateSearchParams();
                        setSelectedOption(index);
                      }
                    }}
                    key={step.id}
                    className={cx(
                      'flex flex-col rounded-md border p-4 first:mt-2',
                      canSelectStep(index) ? 'cursor-pointer ' : 'cursor-not-allowed opacity-50',
                      {
                        'border-r42-blue bg-blue-50': selectedOption === index,
                      },
                    )}
                  >
                    <div className="flex justify-between">
                      <div>
                        <span className={cx('text-sm', { 'text-bold text-r42-blue': selectedOption === index })}>
                          {index + 1}. {step.title}
                        </span>
                        {step?.isOptional && (
                          <span className="text-r42-blue ml-0.5 text-xs">{i18n.t('workflow:common.optional')}</span>
                        )}
                      </div>
                      <CircleProgress percent={percentage[index] * 100} />
                    </div>
                    {selectedOption === index && (
                      <>
                        {step.descriptions.map((item, index) => (
                          <div className="my-1 pl-4" key={index}>
                            <Icons
                              className={cx(
                                'mb-1 mr-2 h-6 w-6',
                                validations[index] ? 'text-green-600' : 'text-gray-600',
                              )}
                              icon={validations[index] ? 'done' : 'close'}
                            />
                            <span className="text-sm text-gray-700">{item}</span>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex-1 px-8 py-4">
                {canSelectStep(selectedOption) ? (
                  <>
                    {selectedOption !== 0 && (
                      <div className="mb-2 flex cursor-pointer items-center" onClick={() => goToPrevStep()}>
                        <Icons icon="cheveronLeft" className="h-8 w-8 text-gray-500" />
                        <span className=" text-gray-500">{i18n.t('workflow:common:back')}</span>
                      </div>
                    )}
                    <RightPanel audienceData={audienceData} refetchAudience={refetchAudience} workflowId={workflowId} />
                  </>
                ) : (
                  <>
                    <Notification kind="information">
                      {i18n.t(`workflow:common:${hasWorkflowAudienceEdit ? 'dependsOn' : 'audeincePermission'}`)}
                    </Notification>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Spin />
        )}
      </>
    </AudienceContext.Provider>
  );
};

export default AudienceForm;
