import React, { useState } from 'react';
import Highcharts from 'highcharts';
import Select from 'react-select';
import { useAPI } from '~/common';
import { Heading, Notification } from '~/components';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { SELECT_DAYS } from '../constants';
import { fetchExperimentStats } from '../dataService';

const ExperimentStats = ({ experimentId }: { experimentId: string }) => {
  const [days, setDays] = useState(90);

  const {
    data: stats,
    isLoading: isStatsLoading,
    error: isStatsError,
  } = useAPI(() => fetchExperimentStats(experimentId, days), [days]);

  const renderGraph = () => {
    if (isStatsLoading) {
      return (
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      );
    }

    if (isStatsError) {
      return (
        <Notification kind="error">
          {typeof isStatsError === 'string' ? isStatsError : JSON.stringify(isStatsError)}
        </Notification>
      );
    }

    if (!stats.series.length) {
      return (
        <Notification kind="information" testHook="noExperimentStats">
          {i18n.t('experiments:messages.noExperimentStats')}
        </Notification>
      );
    }

    return <HighChartWithZoom highcharts={Highcharts} options={stats} />;
  };

  return (
    <>
      <Heading title={i18n.t('experiments:view.experimentStats')} kind="h3" className="py-4">
        <div className="w-56">
          <Select
            className="t-selectDays"
            defaultValue={SELECT_DAYS[1]}
            value={SELECT_DAYS.filter(option => option.value === days)[0]}
            onChange={(option: any) => setDays(option ? option.value : SELECT_DAYS[1].value)}
            options={SELECT_DAYS}
          />
        </div>
      </Heading>

      {renderGraph()}
    </>
  );
};

export default ExperimentStats;
