angular
    .module('webUi.ui.audiences.segments.dashboard', [
        'webUi.ui.audiences.segments.base',
        'webUi.service.profilesConstants',
    ])
    /**
     *
     */
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.audiences.segments.dashboard', {
                    // changing url affects /webui-query-system/src/main/java/nl/synovite/webui/query/system/email/EmailEventHandler.java
                    url: '/dashboard?groupSelections',
                    views: {
                        '@site.audiences': {
                            controller: [
                                '$state',
                                function($state) {
                                    return $state.go('site.audiences.segments.list', { page: 0 }, { location: 'replace' });
                                },
                            ],
                        },
                    },
                })
                .state('site.audiences.segments.list', {
                    url: '/list/:page',
                    isReact: true,
                })
                .state('site.audiences.segments.sublist', {
                    url: '/list/:groupId/:page',
                    isReact: true,
                });
        },
    ]);
