import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildUrl, useApiWithState } from '~/common';
import { Heading, Notification, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { fetchExperiments } from './dataService';
import ExperimentsTable from './ExperimentsTable';
import { ExperimentObject } from './types';

const ExperimentDashboard = ({ hasTagEdit }: { hasTagEdit: boolean }) => {
  const navigate = useNavigate();

  const {
    state: data,
    isLoading,
    error,
    refetch: refetchExperiments,
  } = useApiWithState(async () => fetchExperiments());

  const goToCreateScreen = () => navigate(buildUrl('tagmanagement/experiments/add'));

  const [query, setQuery] = useState('');
  const [filteredExperiments, setFilteredExperiments] = useState(data);

  useEffect(() => {
    setFilteredExperiments(data);
  }, [data]);

  useEffect(() => {
    if (query) {
      const filteredExperiments = data?.filter((experiment: ExperimentObject) =>
        experiment.name.toLowerCase().includes(query),
      );
      setFilteredExperiments(filteredExperiments || []);
    } else {
      setFilteredExperiments(data);
    }
  }, [query]);

  const isEmpty = filteredExperiments?.length === 0;

  return (
    <Page>
      <Heading title={i18n.t('experiments:title')}>
        <SearchElement
          testHook="experimentsSearch"
          value={query}
          onChange={e => setQuery(e.target.value.toLowerCase())}
          placeholder={i18n.t('common:actions.search')}
        />
        <BtnIcon
          icon="add"
          color="blue"
          disabled={!hasTagEdit}
          testHook="addExperiment"
          onClick={() => goToCreateScreen()}
          tooltip={hasTagEdit ? i18n.t('experiments:new') : i18n.t('experiments:tooltips.noPermission')}
        />
      </Heading>
      {!isEmpty && error && <Notification kind="error">{error}</Notification>}
      {isLoading && !data ? (
        <Spin />
      ) : (
        <ExperimentsTable
          hasTagEdit={hasTagEdit}
          data={filteredExperiments || []}
          refetchExperiments={refetchExperiments}
        />
      )}
    </Page>
  );
};

export default ExperimentDashboard;
