import React from 'react';
import queryString from 'query-string';
import cusomterDataService from '~/profiles/customerData/dataService';
import RouterTabs from '~/components/src/RouterTabs';
import Notification from '~/components/src/Notification';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import { TabList, Tab, TabPanel } from '~/components/src/Tabs';
import { buildSearchUrl, changeUrl } from '~/common';
import CustomerFactsComponent from '~/profiles/pages/CustomerData/CustomerFacts/CustomerFactsComponent';
import { getAngularService } from 'ReactAngular/react.service';
import { CustomerDataSearch } from '../../components/CustomerDataSearch';
import CustomerJourneyComponent from './CustomerJourney/CustomerJourneyComponent';
import CustomerIdentifiersComponent from './CustomerIdentifiers/CustomerIdentifiersComponent';
import CustomerAudiencesComponent from './CustomerAudiences/CustomerAudiencesComponent';
import CustomerEventsComponent from './CustomerEvents/CustomerEventsComponent';
import CustomerProfile from './CustomerProfile';

const CUSTOMER_JOURNEY_VIEW = 'CUSTOMER_JOURNEY_VIEW';
const DATA_ACCESS_PERMISSION = 'DATA_ACCESS';
const AUDIENCES_VIEW = 'AUDIENCES_VIEW';

export class CustomerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerType: '',
      partnerId: '',
      canViewCustomerJourney: false,
      canAccessAudience: false,
      canAccessData: false,
      profileId: {
        value: null,
        error: null,
      },
      customerIdentifiers: {
        value: null,
        error: null,
      },
      customerAudiences: {
        value: null,
        error: null,
      },
      customerJourney: {
        value: null,
        error: null,
      },
      customerFacts: {
        value: null,
        error: null,
      },
      customerEvents: {
        value: null,
        error: null,
      },
    };

    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    const { partnerType, partnerId, selectedTab } = queryString.parse(this.props.location.search);
    this.setState({ partnerId, partnerType, selectedTab });

    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      // TODO: Remove when Audiences is moved out of dev Change to WORKFLOW_VIEW
      const canViewCustomerJourney = context.hasPermission(CUSTOMER_JOURNEY_VIEW);
      const canAccessData = context.hasPermission(DATA_ACCESS_PERMISSION);
      const canAccessAudience = context.hasPermission(AUDIENCES_VIEW);

      this.setState({
        canViewCustomerJourney,
        canAccessAudience,
        canAccessData,
      });

      this.fetchData({
        partnerId,
        partnerType,
        canViewCustomerJourney,
        canAccessAudience,
        canAccessData,
      });
    });
  }

  fetchData({ partnerId, partnerType, canViewCustomerJourney, canAccessData, canAccessAudience }) {
    if (!partnerId || !partnerType) {
      return;
    }

    const encodedPartnerId = encodeURIComponent(partnerId);
    const encodedPartnerType = encodeURIComponent(partnerType);

    this.updateProfileId(encodedPartnerType, encodedPartnerId);

    if (canAccessData) {
      this.updateCustomerIdentifiers(encodedPartnerType, encodedPartnerId);
      this.updateCustomerFacts(encodedPartnerType, encodedPartnerId);
      this.updateCustomerEvents(encodedPartnerType, encodedPartnerId);
    }

    if (canAccessAudience) {
      this.updateCustomerAudiences(encodedPartnerType, encodedPartnerId);
    }

    if (canViewCustomerJourney) {
      this.updateCustomerJourney(encodedPartnerType, encodedPartnerId);
    }
  }

  onSearch(values) {
    const { search } = this.props.location;
    const { canViewCustomerJourney, canAccessData, canAccessAudience } = this.state;
    const { partnerId, partnerType } = values;

    this.setState({ partnerId, partnerType });

    this.fetchData({
      partnerId,
      partnerType,
      canViewCustomerJourney,
      canAccessData,
      canAccessAudience,
    });

    const url = buildSearchUrl('profiles/customerData/dashboard', values, search);
    changeUrl(url);
  }

  updateProfileId(partnerType, partnerId) {
    this.setState({ profileId: { value: null, error: null } });

    cusomterDataService
      .getProfileId(partnerType, partnerId, { shouldShowToast: false })
      .then(profileIdApiResponse => {
        this.setState({ profileId: { value: profileIdApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ profileId: { value: null, error: true } });
      });
  }

  updateCustomerIdentifiers(partnerType, partnerId) {
    this.setState({ customerIdentifiers: { value: null, error: null } });

    cusomterDataService
      .getCustomerIdentifiers(partnerType, partnerId, { shouldShowToast: false })
      .then(customerIdentifiersApiResponse => {
        this.setState({ customerIdentifiers: { value: customerIdentifiersApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ customerIdentifiers: { value: null, error: true } });
      });
  }

  updateCustomerEvents(partnerType, partnerId) {
    this.setState({ customerEvents: { value: null, error: null } });
    cusomterDataService
      .getCustomerEvents(partnerType, partnerId, { shouldShowToast: false })
      .then(customerEventsApiResponse => {
        this.setState({ customerEvents: { value: customerEventsApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ customerEvents: { value: null, error: true } });
      });
  }

  updateCustomerAudiences(partnerType, partnerId) {
    this.setState({ customerAudiences: { value: null, error: null } });

    cusomterDataService
      .getCustomerAudiences(partnerType, partnerId, { shouldShowToast: false })
      .then(customerAudiencesApiResponse => {
        this.setState({ customerAudiences: { value: customerAudiencesApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ customerAudiences: { value: null, error: true } });
      });
  }

  updateCustomerJourney(partnerType, partnerId) {
    this.setState({ customerJourney: { value: null, error: null } });

    cusomterDataService
      .getCustomerJourney(partnerType, partnerId, { shouldShowToast: false })
      .then(customerJourneyApiResponse => {
        this.setState({ customerJourney: { value: customerJourneyApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ customerJourney: { value: null, error: true } });
      });
  }

  updateCustomerFacts(partnerType, partnerId) {
    this.setState({ customerFacts: { value: null, error: null } });

    cusomterDataService
      .getCustomerFacts(partnerType, partnerId, { shouldShowToast: false })
      .then(customerFactsApiResponse => {
        this.setState({ customerFacts: { value: customerFactsApiResponse, error: null } });
      })
      .catch(() => {
        this.setState({ customerFacts: { value: null, error: true } });
      });
  }

  render() {
    const { t } = this.props;
    const { partnerType, partnerId, canViewCustomerJourney, canAccessData, canAccessAudience } = this.state;
    return (
      <Page>
        <Heading title={t('title')}>
          <CustomerDataSearch
            onSearch={this.onSearch}
            t={t}
            initialValues={{
              partnerType,
              partnerId,
            }}
          />
        </Heading>
        {(!partnerType || !partnerId) && (
          <Notification kind="information" header="">
            <p>{t('identifiers.initialMessage')}</p>
          </Notification>
        )}
        {partnerType && partnerId && (
          <>
            <CustomerProfile profileIdState={this.state.profileId} t={t} />
            <RouterTabs kind="heading">
              <TabList>
                {canAccessData && <Tab testHook="identifiers">{t('tabs.identifiers')}</Tab>}
                {canAccessAudience && <Tab testHook="audiences">{t('tabs.audiences')}</Tab>}
                {canViewCustomerJourney && <Tab testHook="journeys">{t('tabs.workflows')}</Tab>}
                {canAccessData && (
                  <>
                    <Tab testHook="facts">{t('tabs.facts')}</Tab>
                    <Tab testHook="events">{t('tabs.events')}</Tab>
                  </>
                )}
              </TabList>
              {canAccessData && (
                <TabPanel>
                  <CustomerIdentifiersComponent customerIdentifiersState={this.state.customerIdentifiers} t={t} />
                </TabPanel>
              )}
              {canAccessAudience && (
                <TabPanel>
                  <CustomerAudiencesComponent customerAudiencesState={this.state.customerAudiences} t={t} />
                </TabPanel>
              )}
              {canViewCustomerJourney && (
                <TabPanel>
                  <CustomerJourneyComponent customerJourneyState={this.state.customerJourney} t={t} />
                </TabPanel>
              )}
              {canAccessData && (
                <>
                  <TabPanel>
                    <CustomerFactsComponent customerFactsState={this.state.customerFacts} t={t} />
                  </TabPanel>
                  <TabPanel>
                    <CustomerEventsComponent customerEventsState={this.state.customerEvents} t={t} />
                  </TabPanel>
                </>
              )}
            </RouterTabs>
          </>
        )}
      </Page>
    );
  }
}
