angular.module('webUi.ui.audiences.segments.form', [
    'webUi.ui.audiences.segments.base',
]).config(['$stateProvider', function config($stateProvider) {
    $stateProvider.state('site.audiences.segments.add', {
        url: '/add?parent',
        isReact: true,
    });
    $stateProvider.state('site.audiences.segments.edit', {
        isReact: true,
        url : '/{segmentId}/edit',
    });
}]);
