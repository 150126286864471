import { getSubmenuForRuleType, hasSubMenu, RulesMap, RulesOrder } from '~/common/modules/filterRules/selectors';
import { compose } from 'redux';
import { addPropertiesToCriteria, addTitleAndTypeLabel, flattenCriteria } from '~/profiles/audiences/audienceUtils';
import { RuleDefinition, FilterRule, Filter, FilterWithRules } from './types';

export const getRulesTreeFromTypes = (ruleTypes: RuleDefinition[]): any[] => {
  if (ruleTypes?.length === 0) {
    return [];
  }

  const filteredRulesOrder = RulesOrder.filter(ruleTypeName =>
    ruleTypes?.some(ruleType => ruleType.clazz === ruleTypeName),
  );

  return filteredRulesOrder.map(type => {
    const { title, subtitle } = RulesMap[type];
    const subMenu = hasSubMenu(type) ? getSubmenuForRuleType(ruleTypes, type) : null;
    const ruleType = ruleTypes?.find(ruleType => ruleType.clazz === type);

    const ruleDefinitionId = ruleType?.ruleDefinitionId || null;
    const availableFilters = ruleType?.filters || [];

    return {
      type,
      subtitle,
      subMenu,
      availableFilters,
      ruleDefinitionId,
      title,
      ruleDefinitionName: ruleType?.ruleDefinitionName,
      ruleName: ruleType?.ruleName,
      ruleCategory: ruleType?.ruleCategory,
      groupName: ruleType?.ruleCategory?.label || '',
    };
  });
};

export const mapFilterToEvent = (filter: Filter): FilterWithRules => ({
  ...filter,
  rules: filter?.filterRules?.map((rule: RuleDefinition) => ({
    id: rule.ruleDefinitionId,
    ruleDefinitionId: rule.ruleDefinitionId,
    title: rule.ruleName,
    typeLabel: rule.ruleDefinitionName,
    type: rule.clazz,
    filters: rule.filters,
    ruleDefinitionName: rule.ruleDefinitionName,
    ruleName: rule.ruleName,
    groupName: rule?.ruleCategory?.label || '',
    ruleId: rule.ruleId,
  })),
});

export const mapEventToFilter = (events: FilterRule[]): RuleDefinition[] =>
  events.map((event: FilterRule) => ({
    ruleDefinitionId: event.ruleDefinitionId,
    ruleName: event.ruleName,
    ruleDefinitionName: event.ruleDefinitionName,
    clazz: event.type,
    filters: event.filters,
  }));

const onlyFieldsUsedInTriggerView = (criteria: any[]) =>
  criteria.map((ruleSet: any) =>
    ruleSet.map((rule: any) => ({
      ...rule,
      type: rule?.type,
      ruleType: rule?.ruleType,
      ruleId: rule?.ruleId,
      title: rule?.title,
      typeLabel: rule?.typeLabel,
      timeCondition: rule?.timeCondition,
      waitTime: rule?.waitTime,
      properties: rule?.properties,
      negation: rule?.negation,
    })),
  );

export const prepareDataForViewTrigger = (trigger: any) => {
  const criteria: any = compose(
    onlyFieldsUsedInTriggerView,
    addTitleAndTypeLabel,
    addPropertiesToCriteria,
    flattenCriteria,
  )(trigger.criteria);
  return {
    triggerId: trigger.triggerId,
    title: trigger.name,
    type: trigger.type,
    criteria: criteria || [],
  };
};
