import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import { get } from 'lodash';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css';
import './styles.scss';
import Icons from '../Icons';

const rowHeight = 32;
const maxScrollbarHeight = 17; // Taken from here: https://codepen.io/sambible/post/browser-scrollbar-widths
const lastRowHeight = rowHeight + maxScrollbarHeight;
const styleToHideHorizontalScrollbar = { marginBottom: `-${maxScrollbarHeight}px` };

const expandDirectory = (path, treeData, handleTreeChange) => {
  const newTreeData = changeNodeAtPath({
    treeData,
    path,
    newNode: ({ node }) => ({ ...node, expanded: !node.expanded }),
    getNodeKey: ({ node }) => node.id,
  });

  handleTreeChange(newTreeData);
};

const handleNodeClick = (rowInfo, onNodeClick, treeData, handleTreeChange) => {
  if (onNodeClick) {
    onNodeClick(rowInfo);
  }

  if (rowInfo.node.isDirectory) {
    expandDirectory(rowInfo.path, treeData, handleTreeChange);
  }
};

const getLastTreeItem = treeData => {
  if (treeData.length === 0) {
    return [];
  }

  const lastItem = treeData[treeData.length - 1];

  if (lastItem.children.length) {
    return getLastTreeItem(lastItem.children);
  }

  return lastItem;
};

const Tree = props => {
  const { testHook, className, onNodeClick, handleTreeChange, treeData, selectedNode, handleMove, getRowButtons } =
    props;

  const isSelected = nodeId => get(selectedNode, 'node.id') === nodeId;

  const lastTreeItemId = getLastTreeItem(treeData).id;

  return (
    <div className={cx('Tree h-full', `t-${testHook}`, className)} style={styleToHideHorizontalScrollbar}>
      <SortableTree
        scaffoldBlockPxWidth={24}
        // Last item is taller to hide the horizontal scrollbar below the screen
        rowHeight={({ node }) => (node.id === lastTreeItemId ? lastRowHeight : rowHeight)}
        treeData={treeData}
        theme={FileExplorerTheme}
        onChange={newData => handleTreeChange(newData)}
        canDrag={({ node }) => !node.dragDisabled && !node.isDirectory}
        canDrop={({ nextParent, prevParent }) => {
          const prevParentId = prevParent && prevParent.id;
          const nextParentId = nextParent && nextParent.id;
          return (!prevParent && !!nextParent) || prevParentId !== nextParentId;
        }}
        getNodeKey={({ node }) => node.id}
        onMoveNode={handleMove}
        canNodeHaveChildren={node => node.isDirectory}
        generateNodeProps={rowInfo => ({
          title: ({ node, path, treeIndex }) => (
            <p
              className={cx(
                'treeItem flex cursor-pointer items-center gap-2 text-base',
                {
                  'font-medium text-blue-600': isSelected(node.id),
                },
                {
                  'text-gray-600 hover:text-blue-500': !isSelected(node.id),
                },
              )}
              onClick={() => handleNodeClick({ node, path, treeIndex }, onNodeClick, treeData, handleTreeChange)}
            >
              <Icons
                // eslint-disable-next-line no-nested-ternary
                icon={rowInfo.node.isDirectory ? (rowInfo.node.expanded ? 'folderOpen' : 'folder') : 'audience'}
                className={cx('h-5 w-5')}
              />
              {node.title}
            </p>
          ),
          buttons: getRowButtons(rowInfo),
          style: rowInfo.node.id === lastTreeItemId ? { height: `${rowHeight}px` } : {},
        })}
      />
    </div>
  );
};

Tree.propTypes = {
  className: PropTypes.string,
  testHook: PropTypes.string,
  treeData: PropTypes.array.isRequired,
  selectedNode: PropTypes.object,
  onNodeClick: PropTypes.func.isRequired,
  handleTreeChange: PropTypes.func,
  getRowButtons: PropTypes.func,
};

Tree.defaultProps = {
  className: '',
  testHook: 'tree',
  selectedNode: {},
  handleTreeChange: () => {},
  handleMove: () => {},
  getRowButtons: () => {},
};

export default Tree;
