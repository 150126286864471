import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { SaveDomainContent } from '~/context/clients/components';
import { fetchClientDomainsIfNeeded, getAllClientDomains, saveClientDomain, getClientDomain } from '~/context/clients';
import { Panel } from '~/components/src/Containers';

class DomainFormContainer extends React.Component {
  componentDidMount() {
    this.props.fetchDomains();
  }

  render() {
    const { clientDomainId, initialValues, isEdit, t } = this.props;
    const viewTitle = clientDomainId ? t('clients:view.headings.editDomain') : t('clients:view.headings.addDomain');

    return isEdit && !(initialValues && initialValues.clientDomainId) ? (
      <Spinner />
    ) : (
      <>
        <Heading title={viewTitle} />
        <Panel>
          <SaveDomainContent {...this.props} />
        </Panel>
      </>
    );
  }
}

DomainFormContainer.propTypes = {
  clientId: PropTypes.string,
  clientDomainId: PropTypes.string,
};

export default compose(
  connect(
    (state, ownProps) => ({
      isEdit: !!ownProps.clientDomainId,
      initialValues: ownProps.clientDomainId ? getClientDomain(state, ownProps.clientDomainId) : {},
      domains: getAllClientDomains(state),
      backHref: buildUrl(`context/clients/view/${ownProps.clientId}`),
    }),
    (dispatch, ownProps) => ({
      fetchDomains: () => dispatch(fetchClientDomainsIfNeeded(ownProps.clientId)),
      onSubmit: values => dispatch(saveClientDomain(values, ownProps.clientId, !!ownProps.clientDomainId)),
    }),
  ),
  translate('clients'),
  reduxForm({ form: 'clientDomainForm' }),
)(DomainFormContainer);
