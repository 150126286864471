import React from 'react';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import { PagePropertiesProps, PropertyType } from '../types';

const columnHelper = createColumnHelper<PropertyType>();

const PageProperties = ({ properties, searchQuery }: PagePropertiesProps): React.ReactElement => {
  const getColumns = () => [
    columnHelper.accessor('propertyName', {
      header: () => <div className="text-left">{i18n.t('indexedPaths:tables.pageProperties.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('local', {
      header: () => <div className="text-left">{i18n.t('indexedPaths:tables.pageProperties.local')}</div>,
      cell: info => (
        <div className="text-left">
          {info?.row?.original?.local ? i18n.t('indexedPaths:local') : i18n.t('indexedPaths:remote')}
        </div>
      ),
    }),
  ];

  return (
    <div className="mt-10">
      <UITable
        emptyMessage={i18n.t('indexedPaths:empty.pageProperties.header')}
        emptyBody={searchQuery.length !== 0 ? i18n.t('indexedPaths:empty.body') : ''}
        testHook="pagePropertiesTable"
        data={properties}
        columns={getColumns()}
        searchQuery={searchQuery}
        enableFilters
        enableSorting
      />
    </div>
  );
};

export default PageProperties;
