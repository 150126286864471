import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import cx from 'classnames';
import './styles.scss';

const COLORS = {
  emerald: 'text-emerald-400 hover:text-emerald-600',
  green: 'text-green-400 hover:text-green-600',
  blue: 'text-app-blue-700 hover:text-app-blue-900',
  gray: 'text-gray-500 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-200',
  slate: 'text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200',
};

const Link = ({
  testHook = 'link',
  children,
  className = '',
  disabled = false,
  color = 'blue',
  tooltip = '',
  placement = 'bottom',
  wrapperClassName = '',
  ...props
}) => {
  const Element = props.href || props.onClick ? 'a' : 'div';

  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement}>
      <div className={cx('inline-block', wrapperClassName)}>
        <Element
          className={cx('cursor-pointer border-none no-underline', className, COLORS[color], `t-${testHook}`, {
            'pointer-events-none opacity-75': disabled,
          })}
          disabled
          {...props}
        >
          {children}
        </Element>
      </div>
    </Tippy>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  testHook: PropTypes.string,
};

export default Link;
