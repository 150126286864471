import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

interface PageWithLeftMenuProps {
  className?: string;
  children: React.ReactNode;
}
const PageWithLeftMenu = ({ className, children }: PageWithLeftMenuProps): React.ReactElement => (
  <div className={classNames('PageWithLeftMenu', className)}>{children}</div>
);

PageWithLeftMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWithLeftMenu;
