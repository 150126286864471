import React, { ReactElement, useEffect, useState } from 'react';
import { useApiWithState } from '~/common';
import _ from 'lodash';
import i18n from '~/i18n';
import { Heading, Notification, Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { exportCSVFile } from '~/common/utils/ExportToFile';
import moment from 'moment';
import Spin from '~/components/src/Spin';
import { fetchBeacons, fetchTotalUsage } from './dataService';
import { BeaconObject, TotalUsageObject } from './types';
import BeaconsTable from './BeaconsTable';
import NewBeacon from './NewBeacon';
import { formattedCSVData, headers } from './csvExport';

function index({ hasTagEdit }: { hasTagEdit: boolean }): ReactElement {
  const prepareDataForView = (beacons: BeaconObject[], totalUsage: TotalUsageObject[]) => {
    const merged = _.merge(_.keyBy(beacons, 'beaconId'), _.keyBy(totalUsage, 'beaconId'));
    const values = _.values(merged);
    return values;
  };

  const {
    state: data,
    isLoading,
    error,
    refetch: refetchBeacons,
  } = useApiWithState(async () => {
    const [beacons, totalUsage] = await Promise.all([fetchBeacons(), fetchTotalUsage()]);

    return prepareDataForView(beacons, totalUsage);
  });

  const [query, setQuery] = useState('');
  const [filteredBeacons, setFilteredBeacons] = useState(data);
  const [isCreatePickerVisible, setIsCreatePickerVisible] = useState(false);

  useEffect(() => {
    setFilteredBeacons(data);
  }, [data]);

  useEffect(() => {
    if (query) {
      const filteredBeacons = data?.filter((beacon: BeaconObject) => beacon.beaconName.toLowerCase().includes(query));
      setFilteredBeacons(filteredBeacons || []);
    } else {
      setFilteredBeacons(data);
    }
  }, [query]);

  const isEmpty = filteredBeacons?.length === 0;

  return (
    <Page>
      <Heading title={i18n.t('beacons:title')}>
        <SearchElement
          value={query}
          onChange={e => setQuery(e.target.value.toLowerCase())}
          placeholder={i18n.t('common:actions.search')}
        />

        <BtnIcon
          tooltip={i18n.t('beacons:csv.export')}
          icon="download"
          testHook="exportBeacon"
          onClick={() =>
            exportCSVFile(
              headers,
              formattedCSVData(data || []),
              i18n.t('beacons:csv.fileName', { time: moment().format('L') }),
            )
          }
        />

        {hasTagEdit && (
          <BtnIcon
            tooltip={i18n.t('beacons:new')}
            icon="add"
            color="blue"
            disabled={false}
            testHook="addBeacon"
            onClick={() => setIsCreatePickerVisible(true)}
          />
        )}
      </Heading>
      {!isEmpty && error && <Notification kind="error">{error}</Notification>}
      {isEmpty && !error && (
        <Notification>
          <p>{i18n.t('beacons:notifications.emptyList')}</p>
        </Notification>
      )}
      {isLoading && !data ? (
        <Spin />
      ) : (
        <BeaconsTable data={filteredBeacons || []} hasTagEdit={hasTagEdit} refetchBeacons={refetchBeacons} />
      )}
      {isCreatePickerVisible && (
        <NewBeacon setIsPickerVisible={setIsCreatePickerVisible} refetchBeacons={refetchBeacons} />
      )}
    </Page>
  );
}

export default index;
