import React from 'react';
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification } from '~/components';
import { UITable } from '~/components/src/UITable';
import { TSampleRow } from './types';

const columnHelper = createColumnHelper<TSampleRow>();

interface SamplesDialogProps {
  t: TFunction;
  samples: TSampleRow[];
}

const SamplesDialog: React.FC<SamplesDialogProps> = ({ t, samples }) => {
  const columns = [
    columnHelper.accessor('id', {
      header: () => <div className="text-left">{t('tabs.samples.no')}</div>,
      cell: info => <div className="text-left">{parseInt(info.row.id, 10) + 1}</div>,
      size: 50,
    }),
    columnHelper.accessor('value', {
      header: () => <div className="text-left">{t('tabs.samples.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('frequency', {
      header: () => <div className="text-right">{t('tabs.samples.frequency')}</div>,
      cell: info => <div className="text-right">{info.getValue()}</div>,
    }),
  ];

  return (
    <div className="my-2 overflow-y-auto">
      <Notification kind="information" className="py-2" header="" onClose={undefined}>
        {t('tabs.samples.notification')}
      </Notification>
      <UITable data={samples ?? []} testHook="missedPropertiesTable" columns={columns} />
    </div>
  );
};

export default SamplesDialog;
