import React from 'react';
import * as d3 from 'd3';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { deleteStep, deleteTrigger } from '~/workflows/dataService';
import { formatPercentage } from '~/common';

export const getDeleteTitle = (type: keyof typeof NodeTypes) => {
  switch (type) {
    case NodeTypes.STEP:
      return i18n.t('workflow:orchestration.common.modal.deleteStep');
    case NodeTypes.EXIT_BY_CONDITION:
      return i18n.t('workflow:orchestration.common.modal.deleteexit');
    case NodeTypes.GOAL:
      return i18n.t('workflow:orchestration.common.modal.deletegoal');
    default:
      return i18n.t('workflow:orchestration.common.modal.deletetrigger');
  }
};

export const getDeleteAction = (type: keyof typeof NodeTypes) => {
  switch (type) {
    case NodeTypes.STEP:
      return deleteStep;
    default:
      return deleteTrigger;
  }
};

export const getModalMessage = (stepName: string, data: any, showVariantDeletionMessage = false) => {
  const childNodes = d3
    .hierarchy(data)
    .descendants()
    .map(node => node.data.label);

  return (
    <div className="text-gray-500">
      <p>{i18n.t('workflow:orchestration.common.modal.confirmationDelete', { name: stepName })}</p>
      {showVariantDeletionMessage && <p>{i18n.t('workflow:orchestration.common.modal.variantsDeleteMessage')}</p>}
      {childNodes.length > 1 && (
        <>
          <span>{i18n.t('workflow:orchestration.common.modal.confirmNodeDeletion')}</span>
          <p className="py-2 text-gray-600">{i18n.t('workflow:orchestration.common.modal.itemsDelete')}</p>
          <ul className="pl-6">
            {childNodes.map((node, index) => (
              <li className="list-disc" key={index}>
                {node}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export const getItemWithExperimentStat = (journeyTree: any, items: any[] = []) => {
  const steps = journeyTree?.nodes?.children ?? [];

  return items.map(item => {
    const variantStats = item.stats.variantStats.map((variant: any) => {
      const step = steps.find(
        (step: any) => step?.experimentVariant?.experimentVariantId === variant?.experimentVariantId,
      );
      const variantWeight = step?.experimentVariant?.weight || 0;

      let conversionRate = formatPercentage(0);
      if (variant.profilesEnteredVariant) {
        conversionRate = formatPercentage(variant.profilesReached / variant.profilesEnteredVariant);
      }

      return { ...variant, weight: variantWeight, conversionRate };
    });
    return { ...item, stats: { ...item.stats, variantStats } };
  });
};
