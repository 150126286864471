/* eslint-disable no-prototype-builtins */
angular.module('webUi.service.security', [
    'restangular',
    'webUi.service.pubSub'
])
/**
 *
 */
    .factory('SecurityService', ['$q', 'Restangular', '$http', '$rootScope', '$location', 'PubSubService',
        /**
 *
 * @param $q
 * @param Restangular
 * @param $http
 * @param $rootScope
 * @param $location
 * @param PubSubService
 * @returns {{getCurrentSite: getCurrentSite, getCurrentSiteId: getCurrentSiteId, getAllSitePermissions: getAllSitePermissions, getSitePermissionsMap: getSitePermissionsMap,
 * getPermissionGroupsForSite: getPermissionGroupsForSite, getUserContext: getUserContext, getSiteContext: getSiteContext, getSiteContextByNumber: getSiteContextByNumber, getPrefix: getPrefix, setCurrentSite: setCurrentSite, getSecurityContext: getSecurityContext, goToDefaultState: gotoDefaultState}}
 * @constructor
 */
        function SecurityService ($q,   Restangular,   $http,   $rootScope,   $location,   PubSubService) {

            var _securityContext = instantiateSecurityContext(undefined, undefined, undefined);
            var _securityContextDeferred = $q.defer();
            var _activateDefaultState = false;

            var goToDefaultState = function() {
                this.getSecurityContext().then(function(context){
                    if(_.isUndefined(context.user)) {
                        _activateDefaultState = true;
                        return;
                    }
                    var params = $rootScope.r42Params;



                    var redirectHome = function(siteNumber) {
                        $location.path('/site-' + siteNumber + '/');
                    };


                    var siteId = context.findSiteIdNumber(parseInt(params.defaultSite, 10), true);
                    var site = context.getSites()[siteId];

                    if (site === undefined) { // if user has no sites, just show user settings with site-0
                        $location.path('/site-0/user/settings');
                    } else {
                        //redirect to home page/ tagMan page of first site in list
                        var realSiteNumber = site.siteNumber;
                        service.setCurrentSite(realSiteNumber);
                        redirectHome(realSiteNumber);
                    }
                });
            };

            function SecurityContext(user, sites, auxiliaryData){
                this.user = user;
                this.sites = sites;
                this.auxiliaryData = auxiliaryData;

                this.currentSiteId = undefined;
                this.currentSiteNumber = undefined;
            }

            SecurityContext.prototype = {
                getUser: function getUser(){
                    return this.user;
                },
                getSites: function getSites(){
                    return this.sites;
                },
                isContextAdmin: function isContextAdmin(){
                    if ( _.isUndefined(this.user) || _.isNull(this.user) ){
                        return false;
                    }
                    return !!this.user.contextAdmin;
                },
                isSupportEngineer: function isSupportEngineer(){
                    if ( _.isUndefined(this.user) || _.isNull(this.user) ){
                        return false;
                    }
                    return !!this.user.supportEngineer;
                },
                getAvailableModules: function getAvailableModules (){
                    return this.getAvailableModulesForSite(this.currentSiteId, this.sites);
                },
                getAvailableModulesForSite: function getAvailableModulesForSite(siteId){
                    if (_.isEmpty(this.sites)) {
                        return [];
                    }
                    if (_.isUndefined(this.sites[siteId])) {
                        return [];
                    }
                    return this.sites[siteId].siteModules;
                },
                hasModuleForSite:  function hasModuleForSite (siteId, moduleName){
                    var modules = this.getAvailableModulesForSite(siteId, this.sites);
                    var found = _.find(modules, {name: moduleName});
                    return !_.isUndefined(found);
                },
                hasModule: function hasModule(moduleName){
                    return this.hasModuleForSite(this.currentSiteId, moduleName);
                },
                findSiteIdNumber: function (siteNumber, fallbackToFirst) {
                    if (!_.isUndefined(siteNumber) && !_.isNumber(siteNumber)) {
                        siteNumber = parseInt(siteNumber, 10);
                    }
                    // find uuid
                    for (var siteId in this.sites) {
                        if (!this.sites.hasOwnProperty(siteId)) {
                            continue;
                        }
                        if (this.sites[siteId].siteNumber === siteNumber) {
                            return siteId;
                        }
                    }
                    if (fallbackToFirst) {
                        var allPossibleSites = _.filter(this.sites, {interactiveDemo: false});
                        if (allPossibleSites.length > 0) {
                            return allPossibleSites[0].siteId;
                        }
                    }
                    //explicit return of undefined just to make it more clear
                    return undefined;
                },
                hasPermissionForSite: function hasPermissionForSite (siteId, permission){
                    if (_.isUndefined(siteId) || _.isUndefined(this.sites[siteId])) {
                        return false;
                    }
                    if (this.sites[siteId].permissions[permission]) {
                        return this.sites[siteId].permissions[permission];
                    }
                    return false;
                },
                hasPermission: function hasPermission(permission){
                    if (permission === 'SUPPORT') {
                        return this.isSupportEngineer();
                    }
                    return this.hasPermissionForSite(this.currentSiteId, permission);
                },
                getCurrentSite: function getCurrentSite(){
                    return this.sites[this.currentSiteId];
                }
            };

            function instantiateSecurityContext (user, sites, auxiliaryData){
                return new SecurityContext(user, sites, auxiliaryData);
            }

            Restangular.one('security', 'myContext').get().then(function(context) {
                if(_.isUndefined(context)) {
                    return;
                }

                context = Restangular.stripRestangular(context);
                _securityContext = instantiateSecurityContext(context.user, context.sites, context.auxiliaryData);

                // activate default state do not resolve
                if(_activateDefaultState) {
                    service.gotoDefaultState();
                }

                _securityContextDeferred.resolve(_securityContext);


                var currentSiteId = _securityContext.findSiteIdNumber(_securityContext.currentSiteNumber, false);
                _securityContext.currentSiteId = currentSiteId;
                if(!_.isUndefined(currentSiteId)) {
                    PubSubService.publishSiteSwitch(_securityContext.sites[currentSiteId]);
                    return;
                }
            });

            var service = {

                instantiateSecurityContext: instantiateSecurityContext,
                /**
         * Get the current site from the securityContext object
         * @returns current site
         */
                getCurrentSite : function getCurrentSite () {
                    return _securityContext.currentSiteNumber;
                },

                /**
         * Returns the current site's timezone
         * @returns {String} the current site's timezone
         */
                getCurrentTz: function getCurrentTz() {
                    return _securityContext.getCurrentSite().timeZone || 'Europe/Amsterdam';
                },
                
                /**
         * Returns the current site's offset
         * @returns {Number} the current site's offSet
         */
                getCurrentOffset: function getCurrentOffset() {
                    var date = new Date();
                    var utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
                    var tzDate = new Date(date.toLocaleString('en-US', { timeZone: this.getCurrentTz()}));
                    
                    return (utcDate.getTime() - tzDate.getTime()) / 6e4;
                },
                /**
         * Get the current site id from the securityContext object
         * @returns current site id
         */
                getCurrentSiteId : function getCurrentSiteId () {
                    return _securityContext.currentSiteId;
                },

                /**
         * Returns all possible permissions for a user
         * @returns {Object} containing WebUI and Api permissions. { webuiPermissions: [SitePermission], apiPermissions: [ApiPermission] }
         */
                getAllSitePermissions: function getAllSitePermissions() {
                    var sitePermissionsPromise = $q.defer();
                    Restangular.one('security/allSitePermissions').get().then(function(res) {
                        if (res) {
                            sitePermissionsPromise.resolve(Restangular.stripRestangular(res));
                        }
                    });
                    return sitePermissionsPromise.promise;
                },

                /**
         * Returns all available features for a site
         * @returns A list of SitePermissions
         */
                getAllSiteFeatures: function getAllSiteFeatures (siteId) {
                    return Restangular.one('security/getSiteFeaturesMap/' + siteId).get();
                },

                /**
         * Full list of permissions per module
         * @returns $promise
         */
                getSitePermissionsMap: function getSitePermissionsMap() {
                    var permissionsMapPromise = $q.defer();
                    Restangular.all('security/getPermissionsMap').getList().then(function(res) {
                        if (res) {
                            permissionsMapPromise.resolve(Restangular.stripRestangular(res));
                        }
                    });
                    return permissionsMapPromise.promise;
                },

                getPermissionGroupsForSite: function getPermissionGroupsForSite (siteId) {
                    var permissionGroupsPromise = $q.defer();
                    Restangular.all('security/permissionGroupsForSite/' + siteId).getList().then(function(res) {
                        if (res) {
                            permissionGroupsPromise.resolve(Restangular.stripRestangular(res));
                        }
                    });
                    return permissionGroupsPromise.promise;
                },

                /**
         * get the security context of a user
         *
         * @returns A map of SitePermissions per site
         */
                getUserContext: function getUserContext (userId) {
                    return Restangular.one('security/userContext/' + userId).get();
                },

                /**
         * get the security context of a site
         *
         * @returns A map of SitePermissions per user
         */
                getSiteContext: function getSiteContext (siteId) {
                    return Restangular.one('security/siteContext/' + siteId).get();
                },

                /**
         * get the security context of a site
         *
         * @returns A map of SitePermissions per user
         */
                getSiteContextByNumber: function getSiteContextByNumber (siteNumber) {
                    return Restangular.one('security/siteContextByNumber/' + siteNumber).get();
                },

                /**
         * Shorthand to get the current site prefix e.g ('/site-101')
         */
                getPrefix : function getPrefix () {
                    return 'site-' + _securityContext.currentSiteNumber;
                },

                /**
         * Sets the current site on the securityContext object
         */
                setCurrentSite : function setCurrentSite (siteNumber) {
                    if(!_.isNumber(siteNumber)) {
                        return;
                    }

                    //don't remove (it is needed cause Restangular goes into making the request BEFORE setting of the current site
                    $http.defaults.headers.common['X-siteId'] = siteNumber;
                    _securityContext.currentSiteNumber = siteNumber;

                    //don't remove
                    this.getSecurityContext().then(function(context){
                        context.currentSiteNumber = siteNumber;
                        $http.defaults.headers.common['X-siteId'] = siteNumber;
                        if (!_.isUndefined(context.sites)) {
                            var newSiteId = context.findSiteIdNumber(siteNumber, false);
                            var currentSiteId = context.currentSiteId;
                            if (currentSiteId !== newSiteId) {
                                context.currentSiteId = newSiteId;
                                PubSubService.publishSiteSwitch(context.sites[newSiteId]);
                            }
                        }
                    });
                },

                /**
         * Get (a promise for) the securityContext object
         */
                getSecurityContext : function getSecurityContext () {
                    return _securityContextDeferred.promise;
                },

                /**
        Goes to default state of the app
         */
                goToDefaultState: goToDefaultState

            };

            return service;
        }]);
