import { changeUrl } from '~/common';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './types';
import EngagementsService from './dataService';
import * as selectors from './selectors';
import { fetchDependantsInfo, fetchPropertyDependantsInfo } from '../dependencies/actions';

/**
 * Fetch engagements
 */
export const fetchEngagements = () => dispatch => {
  dispatch({
    type: types.ENGAGEMENTS_FETCH_START,
  });

  return EngagementsService.getEngagements()
    .then(engagements => {
      dispatch({
        type: types.ENGAGEMENTS_FETCH_SUCCESS,
        payload: engagements,
      });
    })
    .catch(error => dispatch({ type: types.ENGAGEMENTS_FETCH_FAIL, error }));
};

/**
 * Fetch engagement
 */
export const fetchEngagement = engagementId => dispatch => {
  dispatch({
    type: types.ENGAGEMENT_FETCH_START,
  });

  return EngagementsService.getEngagement(engagementId)
    .then(engagement => {
      dispatch({
        type: types.ENGAGEMENT_FETCH_SUCCESS,
        payload: engagement,
      });
    })
    .catch(error => dispatch({ type: types.ENGAGEMENT_FETCH_FAIL, error }));
};

/**
 * Fetch the engagement only if it is not in the store
 */
export const fetchEngagementIfNeeded = engagementId => (dispatch, getState) => {
  if (selectors.getEngagementById(getState(), engagementId)) {
    return Promise.resolve();
  }
  return dispatch(fetchEngagement(engagementId));
};

/**
 * Create or Edit engagment with the passed values
 * @param values
 */
export const saveEngagement = values => dispatch => {
  const engagement = {
    engagementId: values.engagementId,
    type: values.type,
    description: values.description,
    properties: values.propertiesWithUsage.map(property => property.name),
  };

  const ApiCall = values.engagementId ? EngagementsService.editEngagement : EngagementsService.createEngagement;

  dispatch({
    type: types.ENGAGEMENT_SAVE_START,
  });

  return ApiCall(engagement)
    .then(updatedOrNewEngagement => {
      dispatch({
        type: types.ENGAGEMENT_SAVE_SUCCESS,
        payload: updatedOrNewEngagement,
      });

      // Go to the list view
      changeUrl('profiles/engagements');
    })
    .catch(error => dispatch({ type: types.ENGAGEMENT_SAVE_FAIL, error }));
};

/**
 * Delete engagement if it does not have dependants
 */
export const deleteEngagement = (engagementId, hasDependants) => dispatch => {
  if (hasDependants) {
    return Promise.resolve();
  }
  dispatch({ type: types.ENGAGEMENTS_DELETE_START });
  return EngagementsService.deleteEngagement(engagementId)
    .then(() => {
      showSuccess({ body: i18n.t('engagements:api.deletionSuccess') });
      changeUrl('profiles/engagements');
      dispatch({
        type: types.ENGAGEMENTS_DELETE_SUCCESS,
        payload: engagementId,
      });
    })
    .catch(error => dispatch({ type: types.ENGAGEMENTS_DELETE_FAIL, error }));
};

/**
 * Show the container for the usage overview of the engagement/property
 */
export const showDependantsContainer = (engagementId, selectedTab, propertyId) => dispatch => {
  if (propertyId) {
    dispatch(fetchPropertyDependantsInfo(engagementId, propertyId));
  } else {
    dispatch(fetchDependantsInfo(engagementId));
  }
  dispatch({
    type: types.SHOW_DEPENDANTS_CONTAINER,
    payload: {
      engagementId,
      propertyId,
      selectedTab,
    },
  });
};

/**
 * Hide the container for the usage overview of the engagement
 */
export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.HIDE_DEPENDANTS_CONTAINER,
  });
};

/**
 * Change the tab in the dependants container
 */
export const changeDependantTypesSelectedTab = selectedTab => dispatch => {
  dispatch({
    type: types.DEPENDANTS_TYPE_TAB_CHANGE,
    payload: selectedTab,
  });
};

/**
 * Set if the user is currently editing some property or not
 */
export const setUserIsEditing = isUserEditing => dispatch => {
  dispatch({
    type: types.SET_USER_IS_EDITING,
    payload: isUserEditing,
  });
};
