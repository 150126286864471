import React from 'react';
import Page from '~/components/src/Page';
import { SmallWrapper } from '~/components/src/Containers';
import { SaveDomainContainer } from '~/context/clients/containers';
import { useParams } from 'react-router-dom';

const DomainAddEditPage = () => {
  const { clientDomainId, clientId } = useParams();
  return (
    <Page>
      <SmallWrapper>
        <SaveDomainContainer clientId={clientId} clientDomainId={clientDomainId} />
      </SmallWrapper>
    </Page>
  );
};

export default DomainAddEditPage;
