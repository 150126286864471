import React, { useContext } from 'react';
import * as d3 from 'd3';
import { useParams } from 'react-router-dom';
import { getSiteId, useAPI } from '~/common';
import RuleCardView from '~/common/modules/rules/components/RuleCardView';
import { Page } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import ConnectorCard from '~/components/src/ConnectorCard';
import Empty from '~/components/src/Empty';
import Heading from '~/components/src/Heading';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import { getPropertiesFromConnector } from '~/profiles/connectors';
import { getConnectorImage } from '~/profiles/connectors/connectorUtils';
import { fetchStepData } from '~/workflows/dataService';
import { JourneyContext } from '../JourneyContext';
import { getRuleTypeByCapitalizedValue } from './utils';
import CopyID from '../../Audience/components/CopyID';

const Integrations = ({ integrations }: any) => {
  const imageUrl = getConnectorImage(getSiteId());

  return (
    <>
      <Heading title={i18n.t('workflow:orchestration.steps.integrations')} kind="h4" testHook="integrationsHeading" />
      {integrations.map((connector: any) => (
        <ConnectorCard
          key={connector.partnerDetails.partnerId}
          className="u-marginBottomXxl"
          connector={connector}
          name={connector.name}
          logoUrl={imageUrl(connector.partnerDetails)}
          properties={getPropertiesFromConnector(connector)}
          integrationType={connector.integrationType}
          supportsEventListeners={connector.supportsEventListeners}
        />
      ))}
    </>
  );
};

const EventListeners = ({ eventListeners }: any) => (
  <div>
    <Heading title={i18n.t('workflow:orchestration.steps.eventListeners')} kind="h4" testHook="eventListenersHeading" />
    {eventListeners.map((listener: any) => (
      <RuleCardView
        type={listener.ruleType}
        title={listener.title}
        typeLabel={listener.typeLabel}
        properties={listener.properties}
        timeCondition={null}
        visibleColumns={['property', 'configuredVariable']}
        testHook="eventListenerCard"
        key={listener.variableModificationId}
      />
    ))}
  </div>
);

const ViewStep = ({ nodes, canEdit }: any) => {
  const { stepId } = useParams();
  const descendants = d3.hierarchy(nodes).descendants() || [];
  const parentTrigger = descendants.find(node => node.data.nodeId === stepId)?.parent;

  const { actions } = useContext(JourneyContext);
  const { goToEditStepScreen, goToJourneyView } = actions;

  if (!stepId) return <></>;

  const { data, isLoading } = useAPI(() => fetchStepData(stepId), [stepId]);

  if (isLoading || !data) return <Spin />;

  const name = data?.name;
  const integrations = (data?.integrations || []).map(integration => {
    const supportedChangeTypes = integration.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    return {
      ...integration,
      supportsEventListeners,
    };
  });
  const variableModifications = data?.variableModifications || [];

  const eventListeners = variableModifications.map(variableModification => {
    const ruleType = getRuleTypeByCapitalizedValue(variableModification.ruleType.name);
    const { title, typeLabel } = getTitleAndTypeLabel(ruleType, variableModification.ruleName);

    const properties = variableModification.properties.map(property => ({
      property: property.propertyName,
      storedVariable: property.variableName,
    }));
    return {
      ruleType,
      title,
      typeLabel,
      properties,
      variableModificationId: variableModification.variableModificationId,
    };
  });

  return (
    <Page>
      <Heading
        title={name}
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => goToJourneyView(),
          },
        ]}
      >
        <div className="flex flex-col items-end">
          {canEdit && (
            <BtnIcon
              testHook="viewStepActions"
              color="blue"
              onClick={() => goToEditStepScreen(stepId, parentTrigger?.data.nodeId)}
              icon="edit"
              tooltip={i18n.t('common:actions.edit')}
            />
          )}
          <CopyID id={stepId} className="mt-2" />
        </div>
      </Heading>
      <div>
        {!integrations.length ? (
          <Empty
            header={i18n.t('workflow:orchestration.steps.noIntegrationsTitle')}
            body={i18n.t('workflow:orchestration.steps.noIntegrationsMessage')}
          />
        ) : (
          <Integrations integrations={integrations} />
        )}
      </div>
      {eventListeners.length > 0 && <EventListeners eventListeners={eventListeners} />}
    </Page>
  );
};

export default ViewStep;
