import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import BtnIcon from '~/components/src/BtnIcon';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import i18n from '~/i18n';
import { TLabel } from '~/workflows/types';
import './style.scss';

const animatedComponents = makeAnimated();

const Option = (props: any) => (
  <div className="MultiSelectColumnFilter-option">
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
  </div>
);

const ValueContainer = ({ children, ...props }: any) => {
  const length = children && children[0]?.length;
  const tmpChildren = [...children];
  if (length && length >= 1) {
    tmpChildren[0] = '';
  }
  return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
};

const Dropdown = ({
  children,
  isOpen,
  target,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  target: React.ReactNode;
}) => (
  <div className="MultiSelectColumnFilter-dropdown">
    {target}
    {isOpen ? <div className="MultiSelectColumnFilter-dropdown--menu">{children}</div> : null}
  </div>
);

const selectStyles = {
  control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const LabelSelect = ({
  labels,
  labelIds = [],
  setLabelIds,
}: {
  labels: TLabel[];
  labelIds: string[];
  setLabelIds: (value: string[]) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const options = labels.map((item: TLabel) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!dropdownRef?.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChange = (selected: any) => {
    setLabelIds(selected.map((option: { value: string }) => option.value || undefined));
  };
  return (
    <FieldWrapper label="Labels">
      <Dropdown
        isOpen={isOpen}
        target={
          <div className="MultiSelectColumnFilter-dropdownField flex items-center">
            <button className="MultiSelectColumnFilter-dropdownField--labelButton" onClick={() => setIsOpen(!isOpen)}>
              {labelIds?.length ? `${labelIds?.length || 0} Selected` : 'All'}
            </button>
            {labelIds?.length > 0 && (
              <BtnIcon icon="close" onClick={() => setLabelIds([])} testHook="clearLabels" className="mr-2 h-6 w-6" />
            )}
          </div>
        }
      >
        <div ref={dropdownRef}>
          <SelectElement
            options={options}
            value={labelIds.map((item: string) => ({ value: item }))}
            onChange={handleChange}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              ValueContainer,
              animatedComponents,
              DropdownIndicator: null,
              IndicatorSeparator: null,
              ClearIndicator: null,
            }}
            menuIsOpen
            autoFocus
            styles={selectStyles}
            placeholder={i18n.t('journey:labels.search')}
          />
        </div>
      </Dropdown>
    </FieldWrapper>
  );
};

export default LabelSelect;
