import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { MediumWrapper, Panel } from '~/components/src/Containers';
import './newExport.scss';
import ExportForm from './ExportForm';

class NewExportComponent extends Component {
  componentDidMount() {
    this.props.fetchRequestTypes();
    this.props.fetchExportTypes();
    this.props.fetchEngagementTypes();
    if (this.props.exportId) {
      this.props.fetchEditExportData(this.props.exportId);
    }
  }

  componentWillUnmount() {
    this.props.setEditExportData();
    this.props.setIsRemoteConnectionSuccessful();
    this.props.setAreConnectionCredentialsChecked(false);
  }

  render() {
    const { t, engagementTypes, isFetchingEditExport, location, isEdit } = this.props;
    const { search } = location;
    return isFetchingEditExport ? (
      <Spinner />
    ) : (
      <Page>
        <MediumWrapper>
          <Heading title={isEdit ? t('edit.header') : t('new.header')} />
          <Panel>
            <ExportForm
              backHref={buildUrl(`profiles/export${search}`)}
              {...this.props}
              allEngagements={engagementTypes.map(data => data.optionId)}
            />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

NewExportComponent.propTypes = {
  t: PropTypes.func.isRequired,
  fetchEditExportData: PropTypes.func.isRequired,
  isFetchingRequestTypes: PropTypes.bool.isRequired,
  isFetchingExportTypes: PropTypes.bool.isRequired,
  isFetchingEngagementTypes: PropTypes.bool.isRequired,
  isFetchingEditExport: PropTypes.bool.isRequired,
  requestTypes: PropTypes.array.isRequired,
  exportTypes: PropTypes.array.isRequired,
  engagementTypes: PropTypes.array.isRequired,
  fetchRequestTypes: PropTypes.func.isRequired,
  fetchExportTypes: PropTypes.func.isRequired,
  fetchEngagementTypes: PropTypes.func.isRequired,
  setEditExportData: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  check: PropTypes.func.isRequired,
  setIsRemoteConnectionSuccessful: PropTypes.func.isRequired,
  isRemoteConnectionSuccessful: PropTypes.bool.isRequired,
  exportId: PropTypes.string,
  editExportData: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default NewExportComponent;
