import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LeftPanel, Page, PageWithLeftMenu, RightPanel } from '~/components';
import Spin from '~/components/src/Spin';
import BtnIcon from '~/components/src/BtnIcon';
import cx from 'classnames';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { changeUrl, useAPI, useApiWithState } from '~/common';
import { Tabs, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { showError, showSuccess } from '~/notificationCenter';
import { getNewPaths, getPathExist, getPathList, postWebsitePath } from './dataService';
import { PathDataType } from './types';
import SubPathsTable from './components/SubPathsTable';
import UrlsTable from './components/UrlsTable';
import PageProperties from './components/PageProperties';
import PageDetails from './components/PageDetails';
import { useIndexedPaths } from './hooks';
import { ActionTypes, GLOBAL_PATH } from './constants';
import IndexedPathTree from './components/IndexedPathTree';
import './IndexedPathsPage.scss';

const IndexedPaths = ({ hasTagEdit }: { hasTagEdit: boolean }): JSX.Element => {
  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(false);
  const [treeSearchQuery, setTreeSetQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { path = GLOBAL_PATH } = useParams();
  const isGlobal = path === GLOBAL_PATH;
  const { state, dispatch } = useIndexedPaths();

  const { data: newPaths = [], isLoading: newPathsIsLoading } = useAPI(() => getNewPaths(), []);
  const {
    state: pathExistsPayload,
    isLoading: pathExistsLoading,
    refetch: refetchPathExist,
  } = useApiWithState(() => getPathExist(path), [path]);
  const { data: pathData, isLoading: pathDataIsLoading } = useAPI(() => getPathList(path), [path]);

  const { pathExists } = pathExistsPayload || {};

  const addPath = async (path: string, callback: () => void) => {
    if (path.includes('%')) {
      showError({ body: i18n.t('indexedPaths:illegalPathMessage') });
      return;
    }
    await postWebsitePath({ path });
    showSuccess({ body: i18n.t('indexedPaths:actions.added', { path }) });
    callback?.();
  };

  const changePath = (path: string) => {
    changeUrl(`tagmanagement/newPaths/list/${path}`);
  };

  const clearSearchInput = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    dispatch({ action: ActionTypes.ADD_DATA, data: pathData || ({} as PathDataType) });
  }, [pathData]);

  useEffect(() => {
    clearSearchInput();
  }, [path]);

  const { urls = [], subPaths = [], properties = [], lastUpdateTs } = (state as PathDataType) || {};

  if (newPathsIsLoading) return <Spin />;

  return (
    <PageWithLeftMenu className="Audience u-flex-row">
      <LeftPanel
        className={cx('w-72', { '!w-96': isLeftPanelExpanded })}
        isLoading={newPathsIsLoading}
        localStorageKey="indexedPathsLeftPanelExpanded"
      >
        <div className="flex h-full flex-col">
          <div className="relative flex items-center gap-2 border-b border-gray-200 bg-gray-50 p-4">
            <SearchElement
              className="flex-1"
              onChange={event => {
                setTreeSetQuery(event.target.value);
              }}
              placeholder={i18n.t('indexedPaths:treeSearch')}
              testHook="indexedPathSearch"
              value={treeSearchQuery}
            />
            <BtnIcon
              className={cx('absolute right-[-8px] top-[54px] z-10 h-5 w-5 rounded-full p-0.5', {
                'rotate-180 transition': isLeftPanelExpanded,
              })}
              onClick={() => setIsLeftPanelExpanded(!isLeftPanelExpanded)}
              icon="cheveronRight"
            />
          </div>

          <IndexedPathTree
            newPaths={newPaths || []}
            path={path}
            changePath={changePath}
            searchQuery={treeSearchQuery}
          />
        </div>
      </LeftPanel>
      <RightPanel>
        {!pathDataIsLoading && !pathExistsLoading ? (
          <Page className="u-paddingXl">
            <PageDetails
              pathExists={pathExists}
              refetchPathExist={refetchPathExist}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              hasSubPaths={subPaths.length > 0}
              lastUpdateTs={lastUpdateTs}
              path={path}
              addPath={addPath}
              isGlobal={isGlobal}
              hasTagEdit={hasTagEdit}
            />

            {isGlobal ? (
              <SubPathsTable
                searchQuery={searchQuery}
                addPath={addPath}
                subPaths={subPaths}
                path={path}
                dispatch={dispatch}
                hasTagEdit={hasTagEdit}
                changePath={changePath}
              />
            ) : (
              <>
                <Tabs onSelect={clearSearchInput}>
                  <TabList>
                    <Tab testHook="activityTabIndexedPaths">{i18n.t('indexedPaths:tabs.indexedPaths')}</Tab>
                    <Tab testHook="activityTabUrls">{i18n.t('indexedPaths:tabs.urls')}</Tab>
                    <Tab testHook="activityTabPageProperties">{i18n.t('indexedPaths:tabs.pageProperties')}</Tab>
                  </TabList>
                  <TabPanel>
                    <SubPathsTable
                      searchQuery={searchQuery}
                      addPath={addPath}
                      subPaths={subPaths}
                      path={path}
                      dispatch={dispatch}
                      hasTagEdit={hasTagEdit}
                      changePath={changePath}
                    />
                  </TabPanel>
                  <TabPanel>
                    <UrlsTable urls={urls} searchQuery={searchQuery} />
                  </TabPanel>
                  <TabPanel>
                    <PageProperties properties={properties} searchQuery={searchQuery} />
                  </TabPanel>
                </Tabs>
              </>
            )}
          </Page>
        ) : (
          <Spin />
        )}
      </RightPanel>
    </PageWithLeftMenu>
  );
};

export default IndexedPaths;
