import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners/linkedin`;

export const fetchAllAudiences = (partnerId: string) =>
  Api.callGet(`${BASE_PATH}/${partnerId}/audiences`, {}).then(parseResponse);

export const createNewAudience = (partnerId: string, payload: { name: string }) =>
  Api.callPost(`${BASE_PATH}/${partnerId}/audiences`, payload).then(parseResponse);

export const getConnectorDetails = (partnerId: string) =>
  Api.callGet(`${API_BASE}/v1/partners/${partnerId}`).then(parseResponse);
