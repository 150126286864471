import React, { MouseEvent } from 'react';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import { I18NextT } from '~/components/src/Common/types';
import './CardHeader.scss';

interface CardHeaderProps {
  title?: string;
  subtitle?: string;
  tooltipText: string;
  onDelete: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  icon: React.ReactNode;
  t: I18NextT;
}

const CardHeader = ({ title, subtitle, tooltipText, onDelete, icon, t }: CardHeaderProps): React.ReactElement => (
  <div className="CardHeader-container">
    <div className="CardHeader-nameAndIcon">
      {icon && <div className="CardHeader-icon">{icon}</div>}
      <div className="CardHeader-titleContainer">
        {title && (
          <span className="CardHeader-title flex items-center gap-2">
            {title}
            <Icons className="h-4 w-4" icon="info" tooltip={tooltipText} />
          </span>
        )}
        {subtitle && <div className="CardHeader-description">{subtitle}</div>}
      </div>
      <div className="CardHeader-actions u-flex">
        <BtnIcon
          icon="delete"
          onClick={onDelete}
          tooltip={t('common:actions.delete')}
          testHook="CardHeader-deleteButton"
          className="h-6 w-6"
        />
      </div>
    </div>
  </div>
);

export default CardHeader;
