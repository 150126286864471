import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import Spin from '~/components/src/Spin';
import { RowActions } from '~/components/src/Table/components';
import { UITable } from '~/components/src/UITable';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import { useApiWithState } from '~/common';
import { deleteYahooAdvertiser, getYahooAdvertisers } from '../dataService';
import { TYahooAdvertiser } from '../types';
import DeleteAdvertiserModal from '../doubleClick/DeleteAdvertiserModal';

const columnHelper = createColumnHelper<TYahooAdvertiser>();

const YahooList = () => {
  const [page, setPage] = useState(0);
  const [selectedDeletedItem, setSelectedDeletedItem] = useState<TYahooAdvertiser | null>(null);
  const { state, isLoading, refetch } = useApiWithState(() => getYahooAdvertisers(page), [page]);

  const onDelete = async () => {
    if (selectedDeletedItem?.yahooAdId) {
      await deleteYahooAdvertiser(selectedDeletedItem?.yahooAdId);
      showSuccess({ body: i18n.t('connectors:advertisers.yahoo.api.deleted') });
      setSelectedDeletedItem(null);
      refetch();
    }
  };

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('connectors:common.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 600,
    }),
    columnHelper.accessor('mdmId', {
      header: () => <div className="text-left">{i18n.t('connectors:common.mdmId')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
      size: 600,
    }),

    columnHelper.accessor('canDelete', {
      header: () => <div className="text-center">{i18n.t('connectors:common.actions')}</div>,
      cell: info => {
        const actions = [
          {
            name: i18n.t('connectors:advertisers.delete'),
            tooltip: i18n.t(`connectors:advertisers.deleteModal.${info.getValue() ? 'delete' : 'canNotDelete'}`),
            isDisabled: !info.getValue(),
            icon: 'delete',
            onClick: () => {
              setSelectedDeletedItem(info?.row?.original || null);
            },
            testHook: 'deleteYahoo',
            type: 'delete',
          },
        ];
        return (
          <div className="text-center">
            <RowActions actions={actions} />
          </div>
        );
      },
      size: 200,
      enableSorting: false,
    }),
  ];

  if (isLoading) return <Spin />;

  const { content = [], totalPages } = state || {};
  return (
    <>
      <UITable
        enableSorting
        data={content}
        stickyHeader={false}
        testHook="yahooAdvertisersTable"
        emptyMessage={i18n.t('connectors:advertisers.empty.header')}
        emptyBody={i18n.t('connectors:advertisers.empty.body')}
        columns={getColumns()}
        enablePagination
        onPaginate={setPage}
        page={page}
        totalPages={totalPages}
      />

      {selectedDeletedItem && (
        <DeleteAdvertiserModal setSelectedDeletedItem={setSelectedDeletedItem} onDelete={onDelete} />
      )}
    </>
  );
};

export default YahooList;
