import React from 'react';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import i18n from '~/i18n';
import { TimeFrameOptions } from '../constants';
import { TElement } from '../types';
import './styles.scss';

export const DateWithinInput = ({ onBlur, onChange, errorText, filter, autoFocus }: TElement) => {
  const selectedOption = TimeFrameOptions.find(option => option.value === filter.timeFrame);

  return (
    <div className="!flex gap-2">
      <FieldWrapper errorText={errorText}>
        <div className="flex gap-2">
          <InputElement
            name="rangeFrom"
            value={filter?.rangeFrom}
            onChange={e => onChange(e.target.value, 'rangeFrom')}
            hasError={!!errorText}
            onBlur={onBlur}
            autoFocus={autoFocus}
            placeholder={i18n.t('common:placeholders.from')}
          />
          <InputElement
            name="rangeTo"
            value={filter?.rangeTo}
            onChange={e => onChange(e.target.value, 'rangeTo')}
            hasError={!!errorText}
            onBlur={onBlur}
            placeholder={i18n.t('common:placeholders.to')}
          />
        </div>
      </FieldWrapper>

      <SelectElement
        className="DateFormat-dateSelect"
        value={selectedOption}
        options={TimeFrameOptions}
        onChange={selectedOption => onChange(selectedOption.value, 'timeFrame')}
      />
    </div>
  );
};

export default DateWithinInput;
