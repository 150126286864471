import React, { MouseEvent, ReactElement } from 'react';
import cx from 'classnames';
import { Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';
import { handleGAEvent } from '~/components/gaHandler';
import Icons from '../Icons';
import icons from '../Icons/icons';

type BtnType = {
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  testHook?: string;
  children?: string | ReactElement;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
  tooltip?: string | false;
  placement?: Placement;
  disabled?: boolean;
  size?: string;
  icon?: keyof typeof icons;
  iconClassName?: string;
  gaAction?: string;
};

const ICON_SIZE: Record<string, string> = {
  xs: 'w-4 h-4',
  sm: 'w-4 h-4',
  bs: 'w-5 h-5',
  lg: 'w-5 h-5',
  xl: 'w-6 h-6',
};

const SIZE: Record<string, string> = {
  xs: 'px-3 py-2 text-xs',
  sm: 'px-3 py-2 text-sm',
  bs: 'px-5 py-2.5 text-sm',
  lg: 'px-5 py-3 text-base',
  xl: 'px-6 py-3.5 text-base',
};

const COLORS: Record<string, string> = {
  gray: 'text-gray-600 dark:text-gray-400  bg-gray-200 dark:bg-gray-700',
  blue: 'text-white bg-blue-600 dark:bg-blue-700',
  amber: 'text-white bg-amber-500 dark:bg-amber-600',
  red: 'text-white bg-red-500 dark:bg-red-600',
};

const COLOR_STATES: Record<string, string> = {
  gray: `${COLORS.gray} hover:bg-gray-300 hover:text-gray-600 dark:hover:bg-gray-600 focus:ring-gray-300 dark:focus:ring-gray-500`,
  blue: `${COLORS.blue} hover:bg-blue-700 hover:text-white dark:hover:bg-blue-600 focus:ring-blue-300 dark:focus:ring-blue-500`,
  amber: `${COLORS.amber} hover:bg-amber-700 hover:text-white dark:hover:bg-amber-600 focus:ring-amber-300 dark:focus:ring-amber-500`,
  red: `${COLORS.red} hover:bg-red-700 hover:text-white dark:hover:bg-red-600 focus:ring-red-300 dark:focus:ring-red-500`,
};

function Btn({
  className,
  onClick,
  children,
  color = 'gray',
  testHook = 'button',
  tooltip,
  placement = 'bottom',
  disabled = false,
  type = 'button',
  icon,
  size = 'bs',
  iconClassName = '',
  gaAction = '',
  ...props
}: BtnType): ReactElement {
  const handleClick = async (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (gaAction) {
      handleGAEvent({ gaAction });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement}>
      <div className="inline-block">
        <button
          type={type}
          className={cx(
            'flex rounded px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4',
            COLORS[color],
            disabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[color]}`,
            SIZE[size],
            `t-${testHook}`,
            className,
          )}
          onClick={handleClick}
          disabled={disabled}
          {...props}
        >
          <div className="m-auto flex items-center gap-x-1.5">
            {icon && <Icons icon={icon} className={cx(ICON_SIZE[size], iconClassName)} />}
            {children}
          </div>
        </button>
      </div>
    </Tippy>
  );
}

export default Btn;
