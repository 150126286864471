/**
 * @description Utility Service for the URL Builder part in Remote
 * @class URLBuilderSharedService
 * @memberOf webUi.service.remote.urlbuilder
 * @namespace webUi.service.remote.urlbuilder
 */
angular.module('webUi.service.remote.urlbuildershared',
    [
        'restangular',
        'webUi.common.Utils'
    ]
)
    .factory('URLBuilderSharedService', [
        '$uibModal',
        /**
     * @function URLBuilderSharedService
     * @memberOf webUi.service.remote.urlbuilder
     *
     * @param $uibModal
     *
     * @returns {URLBuilderSharedService}
     *
     */
        function URLBuilderSharedService($uibModal) {

            /**
         * Opens a modal window to add or edit the campaign
         * @param {URLBuilderType} urlBuilderType
         * @param Array{URLBuilderColumn} urlBuilderColumns
         * @param Array{URLBuilderColumnType} urlBuilderColumnTypes
         * @returns {promise} of the campaign popup modal
         */
            var openNewCampaignPopup = function (campaign, urlBuilderTypes, urlBuilderColumns, urlBuilderColumnTypes, urlBuilderPredefinedValueSets) {
                return $uibModal.open({
                    backdrop: 'static',
                    keyboard: true,
                    backdropClick: true,
                    templateUrl: 'ui/tagmanagement/urlbuilder/campaign/form/form.tpl.html',
                    controller: 'URLBuilderCampaignFormController as vm',
                    resolve: {
                        dialogsModel: function () {
                            return {
                                data: {
                                    campaign: campaign,
                                    urlBuilderTypes: urlBuilderTypes,
                                    urlBuilderColumns: urlBuilderColumns,
                                    urlBuilderColumnTypes: urlBuilderColumnTypes,
                                    urlBuilderPredefinedValueSets: urlBuilderPredefinedValueSets
                                },
                                onCancel: function () {
                                //r42log("User canceled urlBuilder campaign modal:", result);
                                }
                            };
                        }
                    }
                });
            };

            /**
         * RMP API
         * @type {UrlBuilderConfigService}
         */
            var service = {

                openNewCampaignPopup: openNewCampaignPopup
            };
            return service;
        }
    ]);
