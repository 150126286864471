/* eslint-disable no-prototype-builtins */
angular.module('webUi.ui.siteState', [
    'ui.router',
    'webUi.service'
])

    .config(['$stateProvider','$urlMatcherFactoryProvider', function config($stateProvider, $urlMatcherFactoryProvider) {
        $urlMatcherFactoryProvider.strictMode(false);

        $stateProvider.state('site', {
            'abstract' : true,
            url : '/site-{siteNumber:[0-9]+}?demoEnabled&demoState&showIntros',
            resolve : {
                securityContext : ['SecurityService', '$stateParams', function(SecurityService) {
                    return SecurityService.getSecurityContext();
                }],
                prefix : ['SecurityService', function(SecurityService) {
                    return '/site-' + SecurityService.getCurrentSite();
                }]
            },
            onEnter : ['SecurityService', '$rootScope', function(SecurityService, $rootScope) {
                $rootScope.prefix = '/' + SecurityService.getPrefix();
            }],
            views : {
                'navigation' : {
                    controller: 'NavController',
                    templateUrl: 'ui/globalui/navigation/navigation.tpl.html'
                }
            }

        });

    }])
    // define a directive, extending $state, so we don't have to pass current site every time
    .config([
        '$provide', function($provide) {
            $provide.decorator('$state', [
                '$delegate',
                '$stateParams',
                function ($delegate, $stateParams) {

                    //parse function copied from the uiSref directive in angular-ui-router, slightly modified
                    function parseStateRef(ref) {
                        var parsed = ref.replace(/\n/g, ' ').match(/^([^(]+?)\s*(\((.*)\))?$/);
                        if (!parsed || parsed.length !== 4) {
                            throw new Error('Invalid state ref \'' + ref + '\'');
                        }
                        return { state: parsed[1], params: parsed[3] || {} };
                    }

                    /**
                     * Makes a transition to a given state keeping in mind the site.
                     * Takes params either through the state string, or in stateParams
                     *
                     * @param state
                     * @param stateParams
                     * @param options
                     * @returns {*}
                     */
                    $delegate.transitionToStateWithSite = function(state, stateParams, options){
                        stateParams = stateParams || {};
                        stateParams.siteNumber = stateParams.siteNumber || $stateParams.siteNumber;
                        /*
                            The state can be sent simple or with params in the form of
                            site.tagmanagement.newPaths.list({"location":"sv_root"})
                        */
                        var parsedStateWithParams = parseStateRef(state);
                        var simpleState = parsedStateWithParams.state;
                        var extraParams = angular.fromJson(parsedStateWithParams.params);

                        // Augment the passed stateParams with the ones passed into the state
                        if(extraParams !== null && typeof extraParams === 'object') {
                            for (var key in extraParams) {
                                if (extraParams.hasOwnProperty(key)) {
                                    stateParams[key] = extraParams[key];
                                }
                            }
                        }
                        //navigate to the simple state with extra params
                        $delegate.go(simpleState, stateParams, options);
                    };
                    return $delegate;
                }
            ]);
        }
    ]
    );
