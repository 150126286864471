import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import * as fromModals from '~/modals';
import {
  resetSmsTokenStep,
  addSmsToken,
  requestSmsToken,
  resetErrorSmsRequest,
  resetErrorUpdatePhoneRequest,
} from '~/user/settings';
import * as types from '~/user/settings/types';
import * as settingsSelectors from '~/user/settings/selectors';
import UpdatePhoneForm from '~/user/components/UpdatePhoneForm';

const selectorReduxForm = formValueSelector(types.ADD_SMS_TOKEN_FORM);

export class UpdatePhoneModalContainer extends Component {
  componentDidMount() {
    const { resetErrorUpdatePhoneRequest } = this.props;
    resetErrorUpdatePhoneRequest();
  }

  render() {
    return <UpdatePhoneForm {...this.props} />;
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      t: ownProps.t,
      initialValues: {
        phoneNumber: ownProps.user && ownProps.user.phoneNumber,
      },
      phoneNumber: selectorReduxForm(state, 'phoneNumber'),
      smsTokenStep: settingsSelectors.getSmsTokenStep(state),
      errorUpdatePhoneRequest: settingsSelectors.getErrorUpdatePhoneRequest(state),
      errorSmsRequest: settingsSelectors.getErrorSmsRequest(state),
    }),
    dispatch => ({
      onSubmit: values => dispatch(addSmsToken(values)),
      onCancel: () => {
        dispatch(fromModals.hideModal());
        dispatch(resetSmsTokenStep());
      },
      requestSmsToken: phoneNumber => dispatch(requestSmsToken(phoneNumber)),
      resetErrorSmsTokenRequest: () => dispatch(resetErrorSmsRequest()),
      resetErrorUpdatePhoneRequest: () => dispatch(resetErrorUpdatePhoneRequest()),
    }),
  ),
  reduxForm({
    form: types.ADD_SMS_TOKEN_FORM,
  }),
)(UpdatePhoneModalContainer);
