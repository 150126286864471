import React, { useState, useRef, ReactElement, useEffect, useCallback } from 'react';
import Editor from '@monaco-editor/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { exportYAMLFile } from '~/common/utils/ExportToFile';
import { buildUrl, useAPI } from '~/common';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { Notification } from '~/components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BtnOutlined from '~/components/src/BtnOutlined';
import DeletePopup from '~/components/src/DeletePopup';
import Btn from '~/components/src/Btn';
import { ImportPayload } from './types';
import { deleteImport, fetchImport, submitImport } from './dataService';

function CopyFilePath({ filePath }: { filePath: string }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);
  return (
    <Notification header={i18n.t('adminImports:fields.filePath')}>
      <div className="flex flex-col gap-4">
        <div className="t-filePath rounded bg-white p-2 text-sm text-gray-700 dark:text-gray-300">{filePath}</div>

        <CopyToClipboard text={filePath} onCopy={() => setCopied(true)}>
          <BtnOutlined size="xs" onClick={() => ({})} className="w-full" color="gray" testHook="copy">
            {i18n.t(`adminImports:fields.${copied ? 'copied' : 'copy'}`)}
          </BtnOutlined>
        </CopyToClipboard>
      </div>
    </Notification>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function AddImports(): ReactElement {
  const params = useParams();
  const importId = params.importId || '';
  const navigate = useNavigate();
  const goImportsList = () => navigate(buildUrl('admin/imports'), { replace: true });

  const fetchData = useCallback(() => {
    if (!importId) return Promise.resolve(undefined);
    return fetchImport(importId);
  }, [importId]);

  const { data: importContents, isLoading, error } = useAPI(() => fetchData());

  const [contents, setContents] = useState('');
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    if (importContents) {
      setContents(importContents.contents);
    }
  }, [importContents]);

  const inputRef = useRef(null);

  function handleClick() {
    const { current } = inputRef;
    (current || { click: () => ({}) }).click();
  }

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async e => {
      const text = e.target?.result;
      if (text?.toString()) {
        setContents(text.toString());
      }
    };
    reader.readAsText(fileObj);
  };

  const handleSubmit = (values: ImportPayload) => {
    const payload = {
      ...values,
    };
    submitImport(payload)
      .then(() => {
        showSuccess({ header: i18n.t('adminImports:notifications.importSubmitSuccess') });
        goImportsList();
      })
      .catch(() => ({}));
  };

  const handleDelete = () => {
    deleteImport(importId)
      .then(() => {
        showSuccess({ header: i18n.t('adminImports:notifications.deleteSuccess') });
        goImportsList();
      })
      .catch(() => ({}));
  };

  const formik = useFormik({
    initialValues: {
      name: importContents?.name || '',
    },
    onSubmit: values => {
      handleSubmit({ ...values, contents });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(i18n.t('validation:validation.required')),
    }),
    enableReinitialize: true,
  });

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Notification kind="error">{error.message}</Notification>;
  }

  return (
    <div className="flex h-full">
      <Editor
        className="t-editor flex-1 p-4"
        defaultLanguage="yaml"
        value={contents}
        options={{ readOnly: !!importId }}
        onChange={(value?: string) => setContents(value || '')}
      />
      <div className="flex w-3/12 flex-col justify-between bg-gray-100 p-4">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-4">
          <div className="relative">
            <label htmlFor="name" className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
              {i18n.t('adminImports:fields.name')}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              disabled={!!importId}
              className="block w-[94%] rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && <small className="text-red-600">{formik.errors.name}</small>}
          </div>
          {importId ? (
            <>
              <Btn
                color="blue"
                onClick={() => exportYAMLFile(contents, formik.values.name)}
                className="w-full"
                testHook="download"
              >
                {i18n.t('adminImports:fields.download')}
              </Btn>
              <Btn onClick={() => setShowModel(true)} className="w-full" testHook="delete">
                {i18n.t('adminImports:fields.delete')}
              </Btn>
            </>
          ) : (
            <>
              <Btn
                type="submit"
                color="blue"
                disabled={!formik.isValid || !contents}
                className="w-full"
                testHook="submit"
                tooltip={formik.isValid && !contents ? i18n.t('adminImports:notifications.emptyContent') : ''}
              >
                {i18n.t('adminImports:fields.submit')}
              </Btn>
              <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={handleFileChange} />
              <Btn onClick={handleClick} className="w-full" testHook="openFile">
                {i18n.t('adminImports:fields.openFile')}
              </Btn>
            </>
          )}
        </form>
        {importId && importContents && <CopyFilePath filePath={importContents.filePath} />}
        {showModel && (
          <DeletePopup
            title={i18n.t('adminImports:alert.deleteHeader', { importFile: `${importContents?.name}.yaml` })}
            hidePopup={() => setShowModel(false)}
            onClick={() => handleDelete()}
          >
            <Notification>{i18n.t('adminImports:alert.deleteMessage')}</Notification>
          </DeletePopup>
        )}
      </div>
    </div>
  );
}

export default AddImports;
