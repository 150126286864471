import { get } from 'lodash';

/**
 * Get ValueSet By Id
 * @param state
 * @param ValueSetId
 * @returns {*}
 */
export const getValueSetById = (state, ValueSetId) => get(state, `admin.urlBuilder.valueSets.byId[${ValueSetId}]`);

/**
 * Get All ValueSets
 * @param state
 */
export const getValueSets = state =>
  state.admin.urlBuilder.valueSets.allIds.map(id => state.admin.urlBuilder.valueSets.byId[id]);

/**
 * Should fetch all valueSets?
 * @param state
 */
export const shouldFetchValueSets = state => get(state, 'admin.urlBuilder.valueSets.ui.shouldFetchAll');

/**
 * Is fetching all valueSets currently in progress?
 * @param state
 */
export const isFetchingValueSets = state => get(state, 'admin.urlBuilder.valueSets.ui.isFetchingAll');

/**
 * Is fetching all valueSets currently in progress?
 * @param state
 */
export const isSetsValidationActive = state => get(state, 'admin.urlBuilder.valueSets.isValueSetsValidationActive');
