import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  TransformationType,
  TransformationListType,
  TransformationPayload,
  TransformationRestResponseType,
  InternalEventPropsType,
} from './types';
import { mapInternalEventProps, mapValueTransformations, mapTransformation } from './core/util';
import { EventDependencies, MappedValueTransformationOptionType } from './components/types';

const API_TRANSFORMATIONS = `${API_BASE}/v1/transformations`;
const API_PROFILES = `${API_BASE}/v1/eventConnectors`;

export const getAllTransformations = (): Promise<TransformationListType[]> =>
  Api.callGet(API_TRANSFORMATIONS).then(parseResponse);

export const getTransformation = (transformationID: string): Promise<TransformationType> =>
  Api.callGet(`${API_TRANSFORMATIONS}/${transformationID}`).then(parseResponse).then(mapTransformation);

export const getLinkedTransformations = (eventConnectorId: string): Promise<TransformationType[]> =>
  Api.callGet(`${API_PROFILES}/${eventConnectorId}/transformations`).then(parseResponse);

export const createTransformation = (payload: TransformationPayload): Promise<TransformationRestResponseType> =>
  Api.callPost(`${API_TRANSFORMATIONS}`, payload).then(parseResponse);

export const updateTransformation = (
  transformationID: string,
  payload: TransformationPayload,
): Promise<TransformationRestResponseType> =>
  Api.callPut(`${API_TRANSFORMATIONS}/${transformationID}`, payload).then(parseResponse);

export const deleteTransformation = (transformationID: string): Promise<TransformationRestResponseType> =>
  Api.callDelete(`${API_TRANSFORMATIONS}/${transformationID}`).then(parseResponse);

export const getInternalEventProperties = (): Promise<InternalEventPropsType[]> =>
  Api.callGet(`${API_PROFILES}/internalEventProperties`).then(parseResponse).then(mapInternalEventProps);

export const getValueTransformation = (): Promise<MappedValueTransformationOptionType[]> =>
  Api.callGet(`${API_PROFILES}/valueTransformations`).then(parseResponse).then(mapValueTransformations);

export const getDependencies = (eventType: string): Promise<EventDependencies[]> =>
  Api.callGet(`${API_BASE}/profiles/${eventType}`).then(parseResponse);
