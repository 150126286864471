import React, { useEffect } from 'react';

import SelectElement from '~/components/src/Form/Elements/SelectElement';

import {
  getPropertyType,
  getGroupedOptionsList,
  findSelectedOption,
  isConstraintValidForSelectedProperty,
  getDefaultConstraint,
} from './utils';
import { CONSTRAINT_GROUP_TYPE } from '../constants';

const useDefaultConstraint = (selectedConstraint, selectedPropertyType, fieldName, setFieldValue) => {
  useEffect(() => {
    // Selects default constraint value in case no constraint is selected
    if (!selectedConstraint || !isConstraintValidForSelectedProperty(selectedConstraint, selectedPropertyType)) {
      const defaultConstraint = getDefaultConstraint(selectedPropertyType);
      setFieldValue(fieldName, defaultConstraint);
    }
  }, [selectedPropertyType]);
};

const ConstraintSelect = ({
  propertyIndex,
  selectedPropertyName,
  selectedConstraint,
  propertyTypes,
  setFieldValue,
  hasNewProperty,
}) => {
  const selectedPropertyType = getPropertyType(selectedPropertyName, propertyTypes);
  const fieldName = `filters[${propertyIndex}].constraint`;
  const uniqueValues = `filters[${propertyIndex}].minListSize`;

  useDefaultConstraint(selectedConstraint, selectedPropertyType, fieldName, setFieldValue);

  if (!selectedConstraint) {
    return null;
  }

  const handleChange = selectedOption => {
    setFieldValue(fieldName, selectedOption.value);
    setFieldValue('hasNewProperty', true);

    // To set unique values to 1 when date or timeframe is selected.
    if ([CONSTRAINT_GROUP_TYPE.DATE, CONSTRAINT_GROUP_TYPE.TIMEFRAME].includes(selectedOption.value?.group?.name))
      setFieldValue(uniqueValues, 1);
  };

  const groupedOptions = getGroupedOptionsList(selectedPropertyType?.availableConstraints);

  const selectedOption = findSelectedOption(selectedConstraint.matchType.name, groupedOptions);

  const groupsCount = groupedOptions.length;

  const optionsList = groupsCount > 1 ? groupedOptions : groupedOptions[0].options;

  return (
    <SelectElement
      autoFocus={hasNewProperty}
      value={selectedOption}
      options={optionsList}
      onChange={handleChange}
      testHook={`filters-${propertyIndex}__constraint`}
    />
  );
};

const Constraint = ({
  propertyIndex,
  selectedPropertyName,
  selectedConstraint,
  propertyTypes,
  setFieldValue,
  hasNewProperty,
}) => {
  if (!selectedPropertyName) {
    return null;
  }

  return (
    <ConstraintSelect
      propertyIndex={propertyIndex}
      selectedPropertyName={selectedPropertyName}
      selectedConstraint={selectedConstraint}
      propertyTypes={propertyTypes}
      setFieldValue={setFieldValue}
      hasNewProperty={hasNewProperty}
    />
  );
};

export default Constraint;
