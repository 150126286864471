import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import {
  ExperimentObject,
  TCumulativeStats,
  TExperimentDetails,
  TExpGroupNamePayload,
  TExpGroupWeightPayload,
  TExpNamePayload,
  TNewExperiment,
  TTagStatusPayload,
} from './types';
import { mapExperimentStats } from './util';

const BASE_PATH = `${API_BASE}/tagmanagement/experiments`;
const TAG_BASE_PATH = `${API_BASE}/tagmanagement/tags`;

export const fetchExperiments = (): Promise<ExperimentObject[]> => Api.callGet(BASE_PATH).then(parseResponse);

export const fetchExperimentById = (experimentId: string): Promise<TExperimentDetails> =>
  Api.callGet(`${BASE_PATH}/viewPojos/${experimentId}`).then(parseResponse);

export const fetchCumulativeStats = (experimentId: string): Promise<TCumulativeStats[]> =>
  Api.callGet(`${BASE_PATH}/${experimentId}/stats-cumulative`).then(parseResponse);

export const fetchExperimentStats = (experimentId: string, days: number): Promise<any> =>
  Api.callGet(`${BASE_PATH}/${experimentId}/stats?days=${days}`)
    .then(parseResponse)
    .then(response => mapExperimentStats(response, days));

export const createExperiment = (payload: TNewExperiment): Promise<ExperimentObject> =>
  Api.callPost(BASE_PATH, payload).then(parseResponse);

export const deleteExperiment = (experimentId: string): Promise<void> =>
  Api.callDelete(`${BASE_PATH}/${experimentId}`).then(parseResponse);

export const updateExpGroupName = (payload: TExpGroupNamePayload): Promise<void> =>
  Api.callPost(`${BASE_PATH}/updateExperimentGroupName`, payload).then(parseResponse);

export const updateExpGroupWeight = (payload: TExpGroupWeightPayload): Promise<void> =>
  Api.callPost(`${BASE_PATH}/updateExperimentGroupWeight`, payload).then(parseResponse);

export const updateExpName = (payload: TExpNamePayload): Promise<void> =>
  Api.callPost(`${BASE_PATH}/updateExperimentName`, payload).then(parseResponse);

export const canDeleteExperiment = (experimentId: string): Promise<{ canDelete: boolean }> =>
  Api.callGet(`${BASE_PATH}/canDelete/${experimentId}`).then(parseResponse);

export const toggleTagStatus = (payload: TTagStatusPayload): Promise<void> =>
  Api.callPost(`${TAG_BASE_PATH}/enabled`, payload).then(parseResponse);
