import { getEndpoints, getIdentifierTypes } from '../../connectorFormService';
import connectorFormActionTypes from '../../connectorFormActionTypes';

const clangActionTypes = {
  FETCH_IDENTIFIER_TYPES: {
    pending: 'FETCH_IDENTIFIER_TYPES/pending',
    fulfilled: 'FETCH_IDENTIFIER_TYPES/fulfilled',
    rejected: 'FETCH_IDENTIFIER_TYPES/rejected',
  },
  FETCH_ENDPOINTS: {
    pending: 'FETCH_ENDPOINTS/pending',
    fulfilled: 'FETCH_ENDPOINTS/fulfilled',
    rejected: 'FETCH_ENDPOINTS/rejected',
  },
  SET_IDENTIFIER_TYPES: 'SET_IDENTIFIER_TYPES',
  SET_ENDPOINTS: 'SET_ENDPOINTS',
};

const fetchIdentifierTypes = partner => dispatch => {
  dispatch({
    name: clangActionTypes.FETCH_IDENTIFIER_TYPES.pending,
    type: connectorFormActionTypes.CLANG,
  });

  return getIdentifierTypes(partner)
    .then(payload => {
      dispatch({
        name: clangActionTypes.FETCH_IDENTIFIER_TYPES.fulfilled,
        type: connectorFormActionTypes.CLANG,
      });
      dispatch({
        name: clangActionTypes.SET_IDENTIFIER_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.CLANG,
      });
    })
    .catch(() => {
      dispatch({
        name: clangActionTypes.FETCH_IDENTIFIER_TYPES.rejected,
        type: connectorFormActionTypes.CLANG,
      });
    });
};

const fetchEndpoints = partner => dispatch => {
  dispatch({
    name: clangActionTypes.FETCH_ENDPOINTS.pending,
    type: connectorFormActionTypes.CLANG,
  });

  return getEndpoints(partner)
    .then(payload => {
      dispatch({
        name: clangActionTypes.FETCH_ENDPOINTS.fulfilled,
        type: connectorFormActionTypes.CLANG,
      });
      dispatch({
        name: clangActionTypes.SET_ENDPOINTS,
        data: payload.data,
        type: connectorFormActionTypes.CLANG,
      });
    })
    .catch(() => {
      dispatch({
        name: clangActionTypes.FETCH_ENDPOINTS.rejected,
        type: connectorFormActionTypes.CLANG,
      });
    });
};

export { clangActionTypes, fetchIdentifierTypes, fetchEndpoints };
