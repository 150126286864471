/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';
import { GetEngagementsResponseItem, EngagementPayload } from './containers/types';

const BASE_PATH = `${API_BASE}/profiles/engagements`;

/**
 * A service for engagement related queries.
 */
export class EngagementsClass {
  getEngagements(): Promise<GetEngagementsResponseItem[]> {
    return Api.callGet(`${BASE_PATH}/dependencies`).then(response => response.data);
  }

  getEngagement(engagementId: string) {
    return Api.callGet(`${BASE_PATH}/${engagementId}/dependencies`).then(response => response.data);
  }

  createEngagement(engagement: EngagementPayload) {
    return Api.callPost(BASE_PATH, engagement).then(response => response.data);
  }

  editEngagement(engagement: EngagementPayload) {
    return Api.callPost(`${BASE_PATH}/update`, engagement).then(response => response.data);
  }

  deleteEngagement(engagementId: string) {
    return Api.callDelete(`${BASE_PATH}/${engagementId}`);
  }
}

const EngagementsService = new EngagementsClass();
export default EngagementsService;
