// Module
var code = `<div> <div class="form-horizontal"> <div class="control-group" data-ng-if="ad.creativeType.name !== 'REDIRECT' && ad.creativeType.name !== 'VIDEO'"> <label class="control-label left">Click Url</label> <div class="controls"> <span data-editable-disabled="!hasAdservingEdit" data-editable="ad.clickUrl" data-callback-options="clickUrlOverrideOptions" data-vars="{title: ad.clickUrl, placeholder: 'No value specified', styleClazz: 'ellipsis ellipsis-400'}"></span> </div> </div> <div class="control-group" data-ng-if="hasDataManagementModule && (ad.creativeType.name === 'HTML' || ad.creativeType.name === 'JAVASCRIPT')"> <label class="control-label left">Data feed</label> <div class="controls" style="margin-bottom:20px"> <data-searchable-select disabled="!hasAdservingEdit" data-items="dataFeeds" data-ng-model="ad.dataFeed" data-options="{label:'name', searchBoxIdSuffix:'df', noItemsFoundPlaceholder: 'No data feeds are available for selection'}" data-is-clearable="true" data-disabled="!hasAdservingEdit" data-show-search="false" data-edit-success-callback="dataFeedOverrideOptions" data-clear-success-callback="dataFeedOverrideOptions"> </data-searchable-select> <div class="btn-switch" data-ng-if="ad.dataFeed.dataFeedId"> <div class="btn-switch-container pull-left"> <div data-ng-repeat="option in selectOptions track by \$index" class="btn-switch-option pull-left" data-ng-click="hasAdservingEdit && selectOption(option)" data-ng-class="{
							active: option.value === selectedOption.type,
							inactive: option.value !== selectedOption.type,
							profilevariable: option.value === 'profileVariable',
							placementparam: option.value === 'placementParam'}">{{option.label}} </div> </div> <div class="btn-switch-input pull-left" data-ng-if="ad.dataFeed.dataFeedId"> <span data-ng-if="selectedOption.type === 'profileVariable'" data-editable-disabled="!hasAdservingEdit" data-editable="ad.profileVariable" data-editable-template="select" data-callback-options="profileVariablesOverrideOptions" data-vars="{key:'variableId', value:'name'}" data-select-options="profileVariables"> </span> <span data-ng-if="selectedOption.type === 'placementParam'" data-editable-disabled="!hasAdservingEdit" data-editable="ad.placementParameter" data-callback-options="placementParameterOverrideOptions"> </span> </div> </div> </div> </div> <div class="control-group" data-ng-if="ad.creativeType.name !== 'REDIRECT' && ad.creativeType.name !== 'VIDEO'"> <label class="control-label">Media library</label> <div class="controls"> <data-searchable-select disabled="!hasAdservingEdit" data-items="mediaLibraries" data-ng-model="ad.mediaLibrary" data-options="{label:'name', searchBoxIdSuffix:'mLib', noItemsFoundPlaceholder: 'No media libraries are available for selection'}" data-is-clearable="ad.creativeType.name === 'HTML' || ad.creativeType.name === 'REDIRECT' || ad.creativeType.name === 'JAVASCRIPT' " data-disabled="!hasAdservingEdit" data-show-search="false" data-edit-success-callback="mediaLibraryOverrideOptions" data-clear-success-callback="mediaLibraryOverrideOptions"> </data-searchable-select> </div> </div> </div> </div> `;
// Exports
var _module_exports =code;;
var path = 'ui/content/campaigns/ads/view/adProperties.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;