export const USERS_FETCH_START = 'app/context/users/USERS_FETCH_START';
export const USERS_FETCH_FAIL = 'app/context/users/USERS_FETCH_FAIL';
export const USERS_FETCH_SUCCESS = 'app/context/users/USERS_FETCH_SUCCESS';

export const SET_FILTER = 'app/context/users/SET_FILTER';
export const REMOVE_FILTER = 'app/context/users/REMOVE_FILTER';

export const USERS_KEY = 'CONTEXT/USERS/LIST';
export const USERS_FILTER_KEY = 'CONTEXT/USERS/LIST_FILTER';

export const USER_FETCH_START = 'app/context/users/USER_FETCH_START';
export const USER_FETCH_FAIL = 'app/context/users/USER_FETCH_FAIL';
export const USER_FETCH_SUCCESS = 'app/context/users/USER_FETCH_SUCCESS';

export const TOGGLE_PASSWORD_INVALIDATION = 'app/context/users/TOGGLE_PASSWORD_INVALIDATION';

export const REQUEST_NEW_PASSWORD_START = 'app/context/users/REQUEST_NEW_PASSWORD_START';
export const REQUEST_NEW_PASSWORD_FAIL = 'app/context/users/REQUEST_NEW_PASSWORD_FAIL';
export const REQUEST_NEW_PASSWORD_SUCCESS = 'app/context/users/REQUEST_NEW_PASSWORD_SUCCESS';

export const USER_SAVE_START = 'app/context/users/USER_SAVE_START';
export const USER_SAVE_FAIL = 'app/context/users/USER_SAVE_FAIL';
export const USER_SAVE_SUCCESS = 'app/context/users/USER_SAVE_SUCCESS';

export const USER_EDIT_START = 'app/context/users/USER_EDIT_START';
export const USER_EDIT_FAIL = 'app/context/users/USER_EDIT_FAIL';
export const USER_EDIT_SUCCESS = 'app/context/users/USER_EDIT_SUCCESS';
