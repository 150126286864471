import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import constants from '../../constants';
import { TBaseUrl, TPayloadType } from './types';
import { mapBaseUrls, mapPayloadTypes } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/${constants.AIRSHIP}`;

export const fetchBaseUrls = (): Promise<TBaseUrl[]> =>
  Api.callGet(`${BASE_PATH}/baseUrls`).then(parseResponse).then(mapBaseUrls);

export const fetchPayloadTypes = (): Promise<TPayloadType[]> =>
  Api.callGet(`${BASE_PATH}/payloadTypes`).then(parseResponse).then(mapPayloadTypes);
