import useDeepCompareEffectNoCheck from 'use-deep-compare-effect';
import { useRef, useEffect } from 'react';
import { values, mapValues, isObject } from 'lodash';
import { useFormikContext } from 'formik';

// Do not emit initial change for predefined entries
// BUT emit a change for recently added entity, as empty form may be invalid.
const isTouched = form => form.dirty || values(form.touched).length;

// Make sure effectProps length is constant when used
export const ContextEffect = ({
  onChange,
  shouldChangeOnInit,
  formTransformations = val => val,
  effectProps = [],
  isSubmitted,
  errors = {},
  setTouched,
}) => {
  const form = useFormikContext();
  const isMount = useRef(false);
  const mapValuesDeep = (v, callback) => (isObject(v) ? mapValues(v, v => mapValuesDeep(v, callback)) : callback(v));

  useEffect(() => {
    if (isSubmitted) {
      setTouched(mapValuesDeep(errors, () => true));
    }
  }, [isSubmitted, errors]);
  useDeepCompareEffectNoCheck(() => {
    // If we just initialized form with existing values
    if (!shouldChangeOnInit && !isTouched(form) && !isMount.current) {
      isMount.current = true;
      return;
    }
    const value = formTransformations(form);
    onChange(value, form);
  }, [form.values, form.errors, form.isValid, ...effectProps]);
  return null;
};
