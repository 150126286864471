import React, { useContext } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import EditableIntegrationsList from '~/common/modules/connectors/components/EditableConnectorsList';
import { truncateString } from '~/common/utils/StringUtils';
import { NODE_SHOWN_NAME } from '~/workflows/constants';
import { Notification, Tooltip } from '~/components';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { createStep, updateStep } from '~/workflows/dataService';
import { StepConfigurationProps } from '~/workflows/types';
import { shapeEventListeners, shapeIntegrationsRequest } from '~/workflows/util';
import EditableEventListenerList from '../../Journey/Step/components/EditableEventListenerList';
import { AudienceContext } from '../AudienceContext';
import { useDirtyFormHandler } from '../hooks';
import Actions from './ActionButtons';
import DirtyFormPopup from './DirtyFormPopup';
import StepHeader from './StepHeader';

const StepConfiguration = ({
  ruleTypesTree,
  transformedAllConnectors,
  integrations = [],
  variableModifications = [],
  usedVariables,
  experimentSteps,
  rootNodeChilds,
  triggerId,
  stepName,
  stepId,
  stepIdState,
  setStepIdState,
  goToNextStep,
  refetchTree,
  isLoading,
}: StepConfigurationProps) => {
  const { workflowId, updateSearchParams, isOptional, audienceName } = useContext(AudienceContext);
  const { processDirtyForm, showDirtyFormPopup, setShowDirtyFormPopup, cbRef } = useDirtyFormHandler();

  const handleSubmit = async (values: typeof initialValues) => {
    const { name } = values;
    const integrations: any = shapeIntegrationsRequest(values.integrations);
    const variableModifications: any = shapeEventListeners(values.eventsListeners);
    if (rootNodeChilds.length === 0 && triggerId) {
      await createStep({
        name: values.name,
        integrations,
        variableModifications,
        parentTriggerId: triggerId,
        journeyId: workflowId,
      });
      showSuccess({ body: i18n.t('workflow:create.steps.step4.api.created') });
    } else {
      await updateStep({
        name,
        journeyId: workflowId,
        integrations,
        variableModifications,
        stepId,
      });
      showSuccess({ body: i18n.t('workflow:create.steps.step4.api.updated') });
    }
    refetchTree();
    goToNextStep();
  };

  const initialValues = {
    name: stepName || NODE_SHOWN_NAME.connectors,
    integrations,
    areIntegrationsValid: true,
    areEventsListenersValid: true,
    eventsListeners: variableModifications,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  const showForm = !isLoading;

  return (
    <>
      <div className="px-2">
        <StepHeader title={i18n.t('workflow:create.steps.step4.title')} isOptional={isOptional} />
        <p className="py-2 text-gray-600">{i18n.t('workflow:create.steps.step4.addConnectors')}</p>

        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
          validateOnMount
        >
          {({ submitCount, handleSubmit, setFieldValue, isValid, values, dirty }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-3/5">
                <div className="flex">
                  {experimentSteps.map((item, index) => (
                    <div
                      key={item.nodeId}
                      className={twMerge(
                        cx(
                          't-experimentStep mb-5 mr-2 flex h-14 w-32 flex-col justify-center rounded-lg border border-gray-300 p-2',
                          {
                            'border-blue-500': stepIdState === item.nodeId,
                          },
                        ),
                      )}
                      onClick={() => {
                        processDirtyForm(dirty, () => {
                          const nodeId = item?.nodeId as string;
                          updateSearchParams(nodeId);
                          setStepIdState(nodeId);
                        });
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-500">Variant {index + 1}</span>
                        <span
                          className={twMerge(
                            cx('text-sm text-gray-500', {
                              'text-blue-500': stepIdState === item.nodeId,
                            }),
                          )}
                        >
                          {item.weight}%
                        </span>
                      </div>
                      <Tooltip tooltip={item.name}>
                        <span
                          className={twMerge(
                            cx('text-gray-600', {
                              'text-blue-600': stepIdState === item.nodeId,
                            }),
                          )}
                        >
                          {truncateString(item.name, 15)}
                        </span>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                {showForm ? (
                  <>
                    <EditableIntegrationsList
                      stageName={audienceName || values?.name}
                      allConnectors={transformedAllConnectors}
                      integrations={values?.integrations}
                      onChange={({ integrations, areIntegrationsValid }: any) => {
                        setFieldValue('integrations', integrations);
                        setFieldValue('areIntegrationsValid', areIntegrationsValid);
                      }}
                      usedVariables={usedVariables}
                      isDefaultConnectorDisplayed={false}
                      isSubmitted={!!submitCount}
                    />
                    <p className="my-2 py-3 text-gray-600">
                      {i18n.t('workflow:create.steps.step4.configureEventListeners')}
                    </p>
                    <Notification
                      className="mb-5"
                      header={i18n.t('workflow:create.steps.step4.eventListeners.infoHeader')}
                    >
                      <ol className="px-6 py-2">
                        <li>{i18n.t('workflow:create.steps.step4.eventListeners.infoItem0')}</li>
                        <li>{i18n.t('workflow:create.steps.step4.eventListeners.infoItem1')}</li>
                        <li>{i18n.t('workflow:create.steps.step4.eventListeners.infoItem2')}</li>
                        <li>{i18n.t('workflow:create.steps.step4.eventListeners.infoItem3')}</li>
                        <li>{i18n.t('workflow:create.steps.step4.eventListeners.infoItem4')}</li>
                      </ol>
                    </Notification>

                    <EditableEventListenerList
                      eventsListeners={values?.eventsListeners}
                      ruleTypesTree={ruleTypesTree}
                      usedVariables={usedVariables}
                      onChange={({ areEventsListenersValid, eventsListeners }: any) => {
                        setFieldValue('eventsListeners', eventsListeners);
                        setFieldValue('areEventsListenersValid', areEventsListenersValid);
                      }}
                    />

                    {!(isValid && values.areIntegrationsValid && values.areEventsListenersValid) && (
                      <Notification kind="error" className="my-8">
                        <p>
                          {!values.areEventsListenersValid
                            ? i18n.t('workflow:orchestration.eventListener.formInvalid')
                            : i18n.t('workflow:orchestration.steps.formInvalid')}
                        </p>
                      </Notification>
                    )}
                    <Actions
                      onConfirm={handleSubmit}
                      isConfirmEnabled={isValid && values?.areEventsListenersValid && values?.areIntegrationsValid}
                      confirmText={i18n.t('workflow:actions.next')}
                      dirty={dirty}
                      processDirtyForm={processDirtyForm}
                    />
                  </>
                ) : (
                  <Spin className="mt-5" />
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>

      <DirtyFormPopup {...{ cbRef, showDirtyFormPopup, setShowDirtyFormPopup }} />
    </>
  );
};

export default StepConfiguration;
