import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import i18n from '~/i18n';
import { Tooltip } from '~/components';
import { UITable } from '~/components/src/UITable';
import { formatNumber } from '~/common';
import { TExperimentStats, ExperimentStatsProps, Tcolumn } from '~/workflows/types';
import { truncateString } from '~/common/utils/StringUtils';
import { EXPERIMENTS_STATS_TABLE_COLUMNS } from '../constants';
import ExperimentStatsHeader from './ExperimentStatsHeader';

const getPercent = (reached: number, entered: number): string =>
  entered && reached ? ((reached / entered) * 100).toFixed(0) : '0';

const prepareCellWithPercentage = (value: number, testHook: string, entered: number) => {
  const className = testHook.replace(' ', '');
  return (
    <div className="flex items-center">
      <p className={`t-${className} flex-1 text-right`}>{formatNumber(value)}</p>
      <p className={`t-${className}Percent w-4 pl-1 text-right text-xs text-blue-400`}>
        {`${getPercent(value, entered)}%`}
      </p>
    </div>
  );
};

const columnHelper = createColumnHelper<TExperimentStats>();

const ExperimentStats = ({ experimentsStats, totalEntered }: ExperimentStatsProps) => {
  const [query, setQuery] = useState('');
  const [columns, setColumns] = useState<Tcolumn[]>(EXPERIMENTS_STATS_TABLE_COLUMNS);

  const { goals, stats, exitConditions } = experimentsStats;

  const getColumnTotal = (id: string): number =>
    stats.reduce((sum: number, stat: TExperimentStats) => sum + stat[id], 0);

  const shouldShowColumn = (columnId: string) => columns.find(item => item.id === columnId)?.show;

  const hasGoal = goals?.length > 0;
  const hasExitCondition = exitConditions?.length > 0;

  const getColumns = () => [
    columnHelper.group({
      id: 'mainDetail',
      header: '',
      columns: [
        columnHelper.accessor('experimentVariantName', {
          header: () => <span>{i18n.t('workflow:stats.experiments.table.header.variant')}</span>,
          cell: info => (
            <Tooltip tooltip={info.getValue()}>
              <div className="text-left">{truncateString(info.getValue(), 12)}</div>
            </Tooltip>
          ),
          size: 200,
          minSize: 200,
          footer: () => <span className="font-medium">{i18n.t('workflow:stats.experiments.table.footer.total')}</span>,
        }),
        ...(shouldShowColumn('profilesEntered')
          ? [
              columnHelper.accessor('profilesEntered', {
                header: () => (
                  <div className="text-right">
                    <span>{i18n.t('workflow:stats.experiments.table.header.entered')}</span>
                  </div>
                ),
                cell: info => <div className="text-right">{info.getValue()}</div>,
                size: 200,
                minSize: 200,
              }),
            ]
          : []),
        ...(shouldShowColumn('profilesEnteredHp')
          ? [
              columnHelper.accessor('profilesEnteredHp', {
                header: () => (
                  <div className="text-right">
                    <span>{i18n.t('workflow:stats.experiments.table.header.enteredViaBatch')}</span>
                  </div>
                ),
                cell: info => <div className="text-right">{info.getValue()}</div>,
                size: 200,
                minSize: 200,
              }),
            ]
          : []),
      ],
    }),
    ...(shouldShowColumn('goals') && hasGoal
      ? [
          columnHelper.group({
            id: 'goals',
            header: i18n.t('workflow:stats.experiments.table.header.goals'),
            columns: [
              ...goals.map((goal: string) =>
                columnHelper.accessor(goal, {
                  header: () => (
                    <Tooltip tooltip={goal}>
                      <div className="text-right">
                        <span>{truncateString(goal, 12)}</span>
                      </div>
                    </Tooltip>
                  ),
                  cell: info =>
                    prepareCellWithPercentage(
                      info.getValue(),
                      `goals-${goal}`,
                      info.row.original.profilesEntered + info.row.original.profilesEnteredHp,
                    ),
                  size: 200,
                  minSize: 200,
                  footer: info => (
                    <span>
                      {prepareCellWithPercentage(getColumnTotal(info.header.id), `goals-${goal}-total`, totalEntered)}
                    </span>
                  ),
                }),
              ),
            ],
          }),
        ]
      : []),
    ...(shouldShowColumn('totalProfilesReached') && hasGoal
      ? [
          columnHelper.group({
            id: 'totalProfilesReached',
            header: '',
            columns: [
              columnHelper.accessor('totalProfilesReached', {
                header: () => (
                  <div className="text-right">
                    <span>{i18n.t('workflow:stats.experiments.table.header.totalReached')}</span>
                  </div>
                ),
                cell: info =>
                  prepareCellWithPercentage(
                    info.getValue(),
                    'totalProfilesReached',
                    info.row.original.profilesEntered + info.row.original.profilesEnteredHp,
                  ),
                size: 200,
                minSize: 200,
                footer: info => (
                  <span>
                    {prepareCellWithPercentage(
                      getColumnTotal(info.header.id),
                      'totalProfilesReached-total',
                      totalEntered,
                    )}
                  </span>
                ),
              }),
            ],
          }),
        ]
      : []),
    ...(shouldShowColumn('exitConditions') && hasExitCondition
      ? [
          columnHelper.group({
            id: 'exitConditions',
            header: i18n.t('workflow:stats.experiments.table.header.exitConditions'),
            columns: [
              ...exitConditions.map((exit: string) =>
                columnHelper.accessor(exit, {
                  header: () => (
                    <Tooltip tooltip={exit}>
                      <div className="text-right">
                        <span>{truncateString(exit, 12)}</span>
                      </div>
                    </Tooltip>
                  ),
                  cell: info =>
                    prepareCellWithPercentage(
                      info.getValue(),
                      `exit-${exit}`,
                      info.row.original.profilesEntered + info.row.original.profilesEnteredHp,
                    ),
                  size: 200,
                  minSize: 200,
                  footer: info => (
                    <span>
                      {prepareCellWithPercentage(getColumnTotal(info.header.id), `exit-${exit}-total`, totalEntered)}
                    </span>
                  ),
                }),
              ),
            ],
          }),
        ]
      : []),
    ...(shouldShowColumn('totalProfilesExitedCondition') && hasExitCondition
      ? [
          columnHelper.group({
            id: 'totalProfilesExitedCondition',
            header: '',
            columns: [
              columnHelper.accessor('totalProfilesExitedCondition', {
                header: () => (
                  <div className="text-right">
                    <span>{i18n.t('workflow:stats.experiments.table.header.totalExitConditions')}</span>
                  </div>
                ),
                cell: info =>
                  prepareCellWithPercentage(
                    info.getValue(),
                    'totalProfilesExitedCondition',
                    info.row.original.profilesEntered + info.row.original.profilesEnteredHp,
                  ),
                size: 200,
                minSize: 200,
                footer: info => (
                  <span>
                    {prepareCellWithPercentage(
                      getColumnTotal(info.header.id),
                      'totalProfilesExitedCondition-total',
                      totalEntered,
                    )}
                  </span>
                ),
              }),
            ],
          }),
        ]
      : []),
  ];
  return (
    <>
      <ExperimentStatsHeader columns={columns} setColumns={setColumns} query={query} setQuery={setQuery} />
      <UITable
        enableFilters
        searchQuery={query}
        data={stats ?? []}
        testHook="experimentsStatsTable"
        columns={getColumns()}
      />
    </>
  );
};

export default ExperimentStats;
