// Module
var code = `<form name="engagementForm" class="interaction-properties dependency-overview-list form-horizontal form-wizard wizard-vertical ui-formwizard" novalidate data-validate-form> <div class="wrapper-overview-table"> <table class="table-hover table-nomargin table" ng-class="{'short-table': vm.selectedProperty}"> <thead> <tr> <th width="70%">{{vm.interactionType}} and its Properties</th> <th width="30%" colspan="2" data-ng-if="vm.interactionId">Used In</th> </tr> </thead> <tbody> <tr data-ng-if="vm.interactionId" data-ng-click="vm.openOverviewInteraction(vm.interactionName, vm.interactionId)" data-ng-class="{'selected': vm.selectedProperty.value === vm.interactionName}"> <td>{{vm.interactionName}}</td> <td> <used-in data-ng-if="vm.interactionId" data-source-module="vm.sourceModule" data-dependants="vm.interactionDependants"></used-in> </td> <td></td> </tr> <tr class="table-overview-content" data-ng-repeat="property in vm.data.interaction.properties | orderBy:[ 'value'] track by property.value" data-ng-class="{'selected': vm.selectedProperty === property}"> <td class="properties" data-ng-click="!vm.isEditing && vm.openOverviewProperties(property)"> <span data-ng-if="!property.used && vm.isEditing" data-editable="property.value" data-show-buttons="true" data-callback-options="vm.editPropertyCallback" data-custom-validate="[
						{isRequired:true},
						{isUnique: vm.isUniqueProperty(property, \$value), msg: 'Properties should be unique'},
						{isNonJsReserved: vm.isNotJsReservedKeyword(property.value), msg: 'Name should not be a Javascript reserved keyword (if, for, class etc.)'}
					]"> </span> <span data-ng-if="!vm.isEditing || property.used"> {{property.value}} </span> </td> <td data-ng-click="vm.openOverviewProperties(property)"> <used-in data-ng-if="property.interactionId" data-source-module="vm.sourceModule" data-dependants="property.dependants"></used-in> </td> <td class="align-left"> <button type="button" class="btn btn-mini" data-action-element data-action-enabled="true && !property.used" data-action-not-allowed="property.used" data-action-not-allowed-text="You can't delete this property because it's used by at least one audience or orchestration" data-ng-show="vm.isEditing" data-ng-click="vm.deleteProperty(property, vm.data.interaction.properties, vm.data.propagatePropertiesCallback)" title="Remove property"> <i class="r42-trash"></i> </button> </td> </tr> </tbody> <tbody data-ng-switch="vm.canAddNewProperty()" data-ng-form name="addPropertyForm" data-ng-show="vm.isEditing" class="innerForm" novalidate data-isolate-form data-validate-form> <tr data-ng-switch-when="true" class="table-overview-content"> <td class="properties"> <div data-valid data-type="input" data-placeholder="New Property" data-input-class="input-large" data-ng-model="vm.data.newProperty.value" data-custom-watch="vm.data.newProperty.value" data-ng-keyup="\$event.keyCode === 13 ? vm.addNewProperty(\$event, addPropertyForm['vm.data.newProperty.value'], addPropertyForm) : null" data-custom-validate="[
							{isRequired:true},
							{isUnique: vm.isNewUniqueProperty(vm.data.newProperty.value), msg: 'Properties should be unique'},
							{isNonJsReserved: vm.isNotJsReservedKeyword(vm.data.newProperty.value), msg: 'Name should not be a Javascript reserved keyword (if, for, class etc.)'}
						]"> <span class="error-block" data-ng-show="addPropertyForm['vm.data.newProperty.value'].\$showError"> <small>{{addPropertyForm['vm.data.newProperty.value'].\$errors[0]}}</small> </span> </div> </td> <td></td> <td class="align-left"> <button type="button" class="btn btn-mini" data-ng-click="vm.addNewProperty(\$event, addPropertyForm['vm.data.newProperty.value'], addPropertyForm)" title="Add property"> <i class="r42-plus"></i> </button> </td> </tr> <tr data-ng-switch-when="false"> <td colspan="3" class="my-2 flex items-center gap-4 rounded-lg border border-orange-200 bg-orange-50 p-4 text-slate-600 dark:text-slate-200"> <icon p="warning" class="text-orange-600"></icon> <p class="text-lg font-light">Maximum number of properties reached ({{vm.limit}}).</p> </td> </tr> <tr ng-if="vm.getNumberPropertiesLeft()>0"> <td colspan="3" class=""> You can configure <span class="bold">{{vm.getNumberPropertiesLeft()}}</span> more properties </td> </tr> </tbody> </table> </div> <div class="expand-overview" ng-if="vm.selectedProperty && vm.selectedProperty.interactionId"> <usage-overview is-getting-props="vm.isGettingProps" entity-type="vm.interactionType" entity-title="vm.entityTitle" entity-name="vm.selectedProperty.value" entity-id="vm.selectedProperty.interactionId" source-module="vm.sourceModule" on-cancel="vm.closeOverview()"> </usage-overview> </div> </form> `;
// Exports
var _module_exports =code;;
var path = 'component/profiles/shared/interactionProperties/interactionProperties.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;