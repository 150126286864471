angular.module('webUi.ui.globalui.navigation', [
    'webUi.ui.siteState',
    'restangular',
    'ui.highlight',
    'ui.keypress',
    'webUi.service.pubSub',
    'react'
])

    .controller('NavController',['$scope', '$rootScope', '$location', '$timeout', '$state', 'PubSubService', 'PermissionService', 'TagmanagementService', 'TagmanagementPublishingService', 'securityContext', 'helperAngularReactService',
        function NavController(   $scope,  $rootScope,   $location,   $timeout,   $state,   PubSubService,   PermissionService,    TagmanagementService,   TagmanagementPublishingService,   securityContext, helperAngularReactService) {

            $scope.currentUser = securityContext.getUser();
            $scope.isContextAdmin = securityContext.isContextAdmin();
            $scope.isSupportEngineer = securityContext.isSupportEngineer();
            $scope.hasTagViewPermission = securityContext.hasPermission('TAG_VIEW');
            $scope.hasApiAccess = securityContext.hasPermission('API_USER');
            $scope.isSiteAdmin = securityContext.hasPermission('SITEADMIN');
            $scope.displaySiteItems = $location.path().indexOf('no-site-access') === -1;
            $scope.isJoDemoMode = localStorage.getItem('useJoDemoMode') === 'true';

            var availableSites = PermissionService.getAvailableSites(securityContext);
            var getTopState = function (state) {
                // return first two parts
                return state.split('.').splice(0, 2).join('.');
            };
            var currentActiveState = '';

            /**
     * On state initialization, compute the nav and subnav items dynamically
     */
            $scope.$on('$stateChangeSuccess', function (event, state) {
                // Determine the current state so we can style the current navbar item with another color
                var currentNavState = $scope.currentNavState = getTopState(state.name);

                // retrieve unpublished when switching to tag management
                if (currentNavState.indexOf('site.tagmanagement') === 0) {
                    getAndSetUnpublishedChanges();
                }

                var modes = {
                    usePre: 'Pre',
                    useBeta: 'Beta',
                    useDemo: 'Demo'
                };

                var determineMode = function () {
                    for (const [key, value] of Object.entries(modes)) {
                        if (localStorage.getItem(key) === 'true') {
                            $scope['is'+ value] = true;
                            return value;
                        }
                    }
                    return null;
                };
                //the state that we switched to
                currentActiveState = state.name;

                // different modes access
                var dev = {
                    remove: [],
                    left: [],
                    modules: [],
                };
                var demo = {
                    remove: [],
                    left: [],
                    modules: [],
                };
                var beta = {
                    remove: [],
                    left: ['r42-ai'],
                    modules: ['r42-ai-snapshots', 'r42-ai-models'],
                };
                var prod = {
                    remove: dev.remove.concat(beta.remove, demo.remove),
                    left: dev.left.concat(beta.left, demo.left),
                    modules: dev.modules.concat(beta.modules, demo.modules),
                };

                var navItems = PermissionService.getAvailableNavItems(securityContext);
                var leftItems = navItems.left;
                var filteredLeftItems, filteredSubNavItems;
                var subNavItems = navItems.sub[currentNavState];

                var mode = determineMode();

                switch (mode) {
                    case modes.useBeta:
                        filteredLeftItems = leftItems?.filter(item => (!prod.left.includes(item.id) || beta.left.includes(item.id)) && !beta.remove.includes(item.id));
                        filteredSubNavItems = subNavItems?.filter(item => !prod.modules.includes(item.id) || beta.modules.includes(item.id));
                        break;
                    case modes.useDemo:
                        filteredLeftItems = leftItems?.filter(item => (!prod.left.includes(item.id) || demo.left.includes(item.id)) && !demo.remove.includes(item.id));
                        filteredSubNavItems = subNavItems?.filter(item => !prod.modules.includes(item.id) || beta.modules.includes(item.id));
                        break;
                    case modes.usePre:
                        filteredLeftItems = leftItems;
                        filteredSubNavItems = subNavItems;
                        break;
                    default:
                        filteredLeftItems = leftItems?.filter(item => !prod.left.includes(item.id));
                        filteredSubNavItems = subNavItems?.filter(item => !prod.modules.includes(item.id));
                        break;
                }

                $scope.leftNavItems =  filteredLeftItems;
                $scope.rightNavItems = navItems.right;
                $scope.subNavItems = filteredSubNavItems;
                $scope.showInsufficientPermissionsNotificationBar = false;
                $scope.isJoDemoMode = localStorage.getItem('useJoDemoMode') === 'true';
                $scope.hideBanner = localStorage.getItem('hideVersionBanner') === 'true';
            });

            $scope.subTreeActive = function (subState) {
                return currentActiveState.indexOf(subState) !== -1;
            };

            /**
     * Iterate the given navItems list whether the given key matches the item keycode.
     * If yes, navigate to its corresponding linkState
     * @param key
     * @param navItems
     */
            var handleHotKey = function(key, navItems) {
                for(var i=0; i < navItems.length; i ++) {
                    var navItem = navItems[i];
                    if(navItem && navItem.linkState && key === navItem.keycode) {
                        $state.transitionToStateWithSite(navItem.linkState, {});
                    }
                }
            };

            $scope.$on('hotKeyEvent', function (event, data) {

                if (!data.altKey && !data.ctrlKey) {
                    // if keycode is "s" activate search
                    if (data.charLower === 's') {
                        document.getElementById('siteSearchButton').click();
                    }

                } else if (!data.shiftKey && data.altKey) {
                    //user pressed ALT + key, check if any nav item has this shortcut
                    event.preventDefault();
                    handleHotKey(data.charLower, $scope.leftNavItems);
                    handleHotKey(data.charLower, $scope.rightNavItems);

                } else if (data.shiftKey && data.altKey) {
                    //user pressed ALT + SHIFT + key, check if any sub-nav item has this shortcut
                    event.preventDefault();
                    handleHotKey(data.charLower, $scope.subNavItems);
                }
            });

            PubSubService.subscribeEnvironmentChange($scope, function () {
                getAndSetUnpublishedChanges();
            });

            $scope.siteMatcher = function (searchString) {
                if (_.isUndefined(searchString) || _.isEmpty(searchString)) {
                    return true;
                }
                return function (site) {
                    return _.contains(site.name.toLowerCase(), searchString.toLowerCase()) ||
                _.contains(site.siteNumber.toString(), searchString) ||
                _.contains(site.clientName.toLowerCase(), searchString.toLowerCase());
                };
            };

            $scope.handleLogout = function (event) {

                event.preventDefault();

                try {
                    window.sessionStorage.clear();
                    $('#logoutForm').submit();
                } catch (e) {
                    // ignore
                }
            };

            var getAndSetUnpublishedChanges = function() {
                TagmanagementPublishingService.getUnpublishedChanges().then(function (result) {
                    $scope.unpublishedChanges = (parseInt(result, 10) > 99) ? '99+' : result;
                });
            };

            /**
     * Site switching related logic
     */

            $scope.navCallback = function(idxDelta) {
                var curIdx = $scope.siteSelection.selectedIndex;
                curIdx = curIdx + idxDelta;

                // don't allow range to go outside of selectable items
                if(curIdx < -1) {
                    curIdx = -1;
                } else if(curIdx >= $scope.siteSelection.filteredSites.length) {
                    curIdx = $scope.siteSelection.filteredSites.length -1;
                }

                $scope.siteSelection.selectedIndex = curIdx;
            };

            $scope.submitSiteSearch = function () {

                var curIdx = $scope.siteSelection.selectedIndex;

                // if none selected, fallback to first
                if(curIdx === -1) {
                    curIdx = 0;
                }

                var result = $scope.siteSelection.filteredSites[curIdx];

                if (typeof result !== 'undefined') {
                    $scope.siteSelection.searchText = '';
                    switchSite(result);
                }

                // close site search
                $timeout(closeSiteSearch);
            };

            $scope.cancelSiteSearch = function () {
                // close site search
                $timeout(closeSiteSearch);
            };

            var closeSiteSearch = function () {
                $('#siteSearch input')[0].blur();
                $('#navigation')[0].click();
            };

            var switchSite = function (site) {
                $location.path('/site-' + site.siteNumber + '/');
                helperAngularReactService.resetStore();
            };

            var focusSiteSearch = function () {
                // Cheating :-)
                // Caught you !
                $timeout(function () {
                    $('#siteSearch input')[0].focus();
                });
            };

            var restApiSiteBasePath = 'docs/index/site-';

            $scope.siteSelection = {
                availableSites: availableSites,
                currentSite: securityContext.getCurrentSite(),
                selectedIndex: -1
            };

            if($scope.hasApiAccess){
                $scope.siteSelection.restApiSitePath = restApiSiteBasePath + $scope.siteSelection.currentSite.siteNumber;
            }

            PubSubService.subscribeSiteSwitch($scope, function (event, message) {
                if (message) {
                    $scope.siteSelection.currentSite = message;
                }
            });


            $scope.switchSite = switchSite;

            $scope.focusSiteSearch = focusSiteSearch;

            $scope.resetDevMods = function() {
                const isPre = localStorage.getItem('usePre') === 'true';
                const isBeta = localStorage.getItem('useBeta') === 'true';
                const isDemo = localStorage.getItem('useDemo') === 'true';
                const isJoDemoMode = localStorage.getItem('useJoDemoMode') === 'true';
                const hideBanner = localStorage.getItem('hideVersionBanner') === 'true';

                if(isPre) localStorage.setItem('usePre', false);
                if(isBeta) localStorage.setItem('useBeta', false);
                if(isJoDemoMode) localStorage.setItem('useJoDemoMode', false);
                if(isDemo) localStorage.setItem('useDemo', false);
                if(hideBanner) localStorage.setItem('hideVersionBanner', false);

                var url = $rootScope.prefix || '';
                location.replace(url + '/');
            };

            $scope.showInsufficientPermissionsWarning = function() {
                $scope.showInsufficientPermissionsNotificationBar = true;
                // Remove the "Insufficient permissions" notification message after 20s
                $timeout(function () {
                    $scope.showInsufficientPermissionsNotificationBar = false;
                }, 5000);
            };

            $scope.closeNotification = function() {
                $scope.hasTimezoneDifference = false;
            };

        }]);
