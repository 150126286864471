import React from 'react';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { TWorkflow } from '~/workflows/types';
import AudienceStatistics from './AudienceStatistics';
import JourneyStatistics from './JourneyStatistics';

const Statistics = ({ workflowData }: { workflowData: TWorkflow }) =>
  workflowData.workflowType?.name === WORKFLOW_TYPE.Audience ? (
    <AudienceStatistics workflowData={workflowData} />
  ) : (
    <JourneyStatistics />
  );

export default Statistics;
