import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { buildUrl, useAPI } from '~/common';
import { getAllRuleVariables } from '~/common/modules/rules/dataService';
import { PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import Link from '~/components/src/Link';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { HP_STATUS, WORKFLOW_STATUS, WORKFLOW_TYPE } from '~/workflows/constants';
import { fetchDependencies, validateJourney } from '~/workflows/dataService';
import { formatExpiryTime } from '~/workflows/util';
import TriggerHistoricProcesingButton from '../../HistoricProcessing/components/TriggerHistoricProcesingButton';
import TriggerHP from '../../HistoricProcessing/TriggerHP';
import ViewScheduledHP from '../../HistoricProcessing/ViewScheduledHP';
import WorkflowDependants from '../../WorkflowDependants';
import EditJourney from '../EditJourney';
import { CanvasContext } from '../JourneyCanvas/CanvasContext';
import { JourneyContext } from '../JourneyContext';
import WorkflowStatusTag from '../../WorkflowStatusTag';
import ValidationModal from '../../Publish/ValidationModal';
import PublishButton from '../../Publish/PublishButton';
import PublishModal from '../../Publish/PublishModal';
import CopyID from '../../Audience/components/CopyID';

const JourneyHeader = ({
  canEdit,
  hasStepsAfterFirstTrigger,
  setShowExperimentSettingsModal,
}: {
  canEdit: boolean;
  hasStepsAfterFirstTrigger: boolean;
  setShowExperimentSettingsModal: (val: boolean) => void;
}) => {
  const { journeyData, hasExperiments, journeyTree, actions, refreshJourney } = useContext(JourneyContext);
  const { journeyErrors, setJourneyErrors, setShowJourneyErrors } = useContext(CanvasContext);

  const { goToJourneyStatsView } = actions;
  const { data: variables } = useAPI(() => getAllRuleVariables());

  if (!journeyData || !journeyTree) return <Spin />;

  const { name, journeyId, expiryTime, historicProcessing, state, workflowType } = journeyData;
  const { goals, exitConditions } = journeyTree;

  const { data: dependants } = useAPI(async () => fetchDependencies(journeyId));

  const [showJourneyEdit, setShowJourneyEdit] = useState(false);
  const [showDependantJourneys, setShowDependantJourneys] = useState(false);
  const [showHistoricProcessingModal, setShowHistoricProcessingModal] = useState(false);
  const [showHistoricProcessRunningModal, setShowHistoricProcessRunningModal] = useState(false);
  const [showValidationErrorsModal, setShowValidationErrorsModal] = useState(false);
  const [showJourneyPublishModal, setShowJourneyPublishModal] = useState(false);

  const handleValidateJourney = async () => {
    const validationResponse = await validateJourney(journeyId);
    if (validationResponse.length) {
      setJourneyErrors(validationResponse);
      setShowValidationErrorsModal(true);
    } else {
      setShowJourneyPublishModal(true);
    }
  };

  const journeyStatusText =
    state.name === WORKFLOW_STATUS.PUBLISHED
      ? i18n.t('workflow:orchestration.publish.publishedLabel')
      : i18n.t('workflow:orchestration.publish.unpublishedChangesLabel');

  const dashboardLink = buildUrl('workflows/dashboard');
  const isHpRunning = historicProcessing.state?.name === HP_STATUS.RUNNING;
  const isHpScheduled = historicProcessing.state?.name === HP_STATUS.SCHEDULED;

  return (
    <>
      <div className="flex border-b border-gray-200 bg-white pb-4 pl-4 pr-4 pt-4">
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <RouterLink to={dashboardLink} className="PageTitleHeader-titleContainer--link">
              <Icons
                icon="back"
                tooltip={i18n.t('workflow:orchestration.header.backToJourneyList')}
                className="h-6 w-6 text-gray-400"
              />
            </RouterLink>
            <Icons icon="journey" className="h-6 w-6 text-blue-600" />
            <div className="">
              <p className="">{name}</p>
            </div>
          </div>
          <CopyID id={journeyId} className="ml-8 mt-1" />
          <div className="ml-8 mt-1 flex items-center gap-1 text-sm">
            <WorkflowStatusTag status={state.name} text={journeyStatusText} />

            <div className="flex items-center gap-1">
              <Icons icon="schedule" className="h-4 w-4" />
              {formatExpiryTime(expiryTime)}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <BtnIcon
            icon="statistics"
            className=""
            onClick={() => goToJourneyStatsView()}
            tooltip={i18n.t('workflow:orchestration.tooltips.journeyStats')}
            testHook="journeyStats"
          />
          <BtnIcon
            icon="setting"
            testHook="journeySettings"
            onClick={() => setShowJourneyEdit(true)}
            tooltip={i18n.t(
              `workflow:orchestration.${canEdit ? 'tooltips.journeySettings' : 'permissions.notEnoughPermissions'}`,
            )}
            disabled={!canEdit}
          />

          {hasStepsAfterFirstTrigger && hasExperiments && (
            <BtnIcon
              icon="experiment"
              testHook="editExperiment"
              onClick={() => setShowExperimentSettingsModal(true)}
              tooltip={i18n.t(
                `workflow:orchestration.${
                  canEdit ? 'tooltips.experimentSettings' : 'permissions.notEnoughPermissions'
                }`,
              )}
              disabled={!canEdit}
            />
          )}
          <TriggerHistoricProcesingButton
            hpJobData={historicProcessing}
            eligible={!!historicProcessing.eligible}
            isHpRunning={isHpRunning}
            isHpScheduled={isHpScheduled}
            canEdit={canEdit}
            setShowHistoricProcessingModal={setShowHistoricProcessingModal}
            setShowHistoricProcessRunningModal={setShowHistoricProcessRunningModal}
          />
          <PublishButton
            canEdit={canEdit}
            state={state}
            validateJourney={handleValidateJourney}
            isHpRunningOrScheduled={isHpRunning || isHpScheduled}
          />
        </div>
      </div>
      {dependants && dependants.length > 0 && (
        <div className="flex h-12 items-center justify-center bg-amber-500">
          <Icons className="mx-2 h-6 w-6 text-white" icon="overlap" />
          <p className="text-white">
            {i18n.t('workflow:orchestration.header.overlapInformation', { type: workflowType?.label })}
          </p>
          <Link
            className="t-journeyOverlap-infoBarLink ml-1 font-medium !text-white !underline hover:!text-blue-800"
            onClick={() => setShowDependantJourneys(true)}
          >
            {i18n.t('workflow:orchestration.header.overlapDependencyLink')}
          </Link>
        </div>
      )}
      {showJourneyEdit && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:journey.journeyModal.title')}
          handlePickerVisibility={() => setShowJourneyEdit(false)}
        >
          <EditJourney hideModal={() => setShowJourneyEdit(false)} />
        </PickerView>
      )}
      {showDependantJourneys && (
        <PickerView
          className="!h-auto min-h-[200px] !w-1/3 min-w-[400px]"
          pickerTitle={i18n.t('workflow:journey.dependencyModal.title', { type: workflowType?.label })}
          handlePickerVisibility={() => setShowDependantJourneys(false)}
        >
          <WorkflowDependants list={dependants || []} hideModal={() => setShowDependantJourneys(false)} />
        </PickerView>
      )}
      {showHistoricProcessingModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.tooltips.triggerHistoricProcessing')}
          handlePickerVisibility={() => setShowHistoricProcessingModal(false)}
        >
          <TriggerHP
            hasGoals={!!goals.length}
            hasExits={!!exitConditions.length}
            journeyId={journeyId}
            fetchJourneyData={refreshJourney}
            hideModal={() => setShowHistoricProcessingModal(false)}
          />
        </PickerView>
      )}
      {showHistoricProcessRunningModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.tooltips.scheduledHistoricProcessing')}
          handlePickerVisibility={() => setShowHistoricProcessRunningModal(false)}
        >
          <ViewScheduledHP
            hasGoals={!!goals.length}
            hasExits={!!exitConditions.length}
            hpJobId={historicProcessing.jobId || ''}
            journeyId={journeyId}
            fetchJourneyData={refreshJourney}
            hideModal={() => setShowHistoricProcessRunningModal(false)}
          />
        </PickerView>
      )}

      {showValidationErrorsModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.publish.title')}
          handlePickerVisibility={() => setShowValidationErrorsModal(false)}
        >
          <ValidationModal
            journeyName={name}
            validations={journeyErrors}
            variables={variables}
            journeyTree={journeyTree}
            hideModal={() => setShowValidationErrorsModal(false)}
            setShowJourneyErrors={setShowJourneyErrors}
            workflowType={WORKFLOW_TYPE.Journey}
          />
        </PickerView>
      )}
      {showJourneyPublishModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.publish.title')}
          handlePickerVisibility={() => setShowJourneyPublishModal(false)}
        >
          <PublishModal
            journeyName={name}
            isHpRunningOrScheduled={isHpRunning || isHpScheduled}
            isLatestVersion={false}
            journeyId={journeyId}
            refreshJourney={refreshJourney}
            hideModal={() => setShowJourneyPublishModal(false)}
          />
        </PickerView>
      )}
    </>
  );
};

export default JourneyHeader;
