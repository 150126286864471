import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import './notificationCenter.scss';

const Bounce = cssTransition({
  enter: 'NotificationCenter-bounceInRight',
  exit: 'NotificationCenter-bounceOutRight',
});

const NotificationCenter = () => (
  <ToastContainer
    transition={Bounce}
    autoClose={5000}
    closeOnClick={false}
    closeButton={false}
    draggable={false}
    newestOnTop
    className="NotificationCenter NotificationCenter--topRight"
    toastClassName="NotificationCenter-toast"
    progressClassName="NotificationCenter-progressBar"
  />
);

export default NotificationCenter;
