import { NewPathsType } from './types';

export enum ActionTypes {
  ADD_PATH = 'ADD_PATH',
  REMOVE_PATH = 'REMOVE_PATH',
  ADD_DATA = 'ADD_DATA',
  SHOW_MORE = 'SHOW_MORE',
}

export const GLOBAL_PATH = 'sv_root';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';

export const globalNode: NewPathsType = {
  id: GLOBAL_PATH,
  name: 'Global',
  children: [],
  searchableProperties: null,
  allowChildren: true,
  type: null,
  expanded: false,
};
