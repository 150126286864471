import React from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import { getIcon } from '~/common/modules/rules/utils';
import { Link } from '~/components';
import i18n from '~/i18n';
import { useAPI } from '~/common';
import { RulesTypes } from '~/common/modules/rules/types';
import { getEngagementProperties, getFactProperties } from './dataService';
import EventProperties from './EventProperties';

type FilterEventProps = {
  type: string;
  title: string;
  typeLabel: string;
  url: string;
  id: string;
};

type EngagementPropertiesProps = {
  id: string;
};

function EngagementProperties({ id }: EngagementPropertiesProps) {
  const { data, isLoading, error } = useAPI(() => getEngagementProperties(id));
  return <EventProperties data={data?.properties} isLoading={isLoading} error={error} />;
}

function FactProperties({ id }: EngagementPropertiesProps) {
  const { data, isLoading, error } = useAPI(() => getFactProperties(id));
  return <EventProperties data={data?.properties} isLoading={isLoading} error={error} />;
}

function FilterEvent({ type, title, typeLabel, url, id }: FilterEventProps) {
  const icon = getIcon(type);
  return (
    <div className={cx('flex flex-col justify-between rounded-md border border-gray-200 bg-white px-2 ', `t-${type}`)}>
      <div className="flex items-center p-2">
        <div className="flex w-full items-center gap-2 p-2">
          {icon}
          <div>
            {title && (
              <div className="flex items-center gap-1">
                <p className="text-lg font-medium text-gray-800">{url ? <Link href={url}>{title}</Link> : title}</p>
                <Icons
                  icon="info"
                  className="h-6 w-5 p-0.5 text-gray-400"
                  tooltip={i18n.t(`audiences:rules.descriptions.${type}`)}
                />
              </div>
            )}
            {typeLabel && <p className="text-sm text-gray-500">{typeLabel}</p>}
          </div>
        </div>
      </div>
      {type === RulesTypes.Engagement && <EngagementProperties id={id} />}
      {(type === RulesTypes.AiFact || type === RulesTypes.ExternalFact) && <FactProperties id={id} />}
    </div>
  );
}

export default FilterEvent;
