import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';

const ImageRow = ({ label, alt, src, className, excludeErrorOrHint }) => (
  <FieldWrapper label={label} excludeErrorOrHint={excludeErrorOrHint}>
    <img alt={alt} src={src} className={className} />
  </FieldWrapper>
);

ImageRow.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default ImageRow;
