import {
  CreateEvent,
  Event,
  Mapping,
  MappingPayload,
  EventAdditionalParameters,
  DestinationOption,
  Sources,
} from './transformerTypes';
import { temporaryMappingId, createMapping } from './mapping';
import { withChangeHandler } from './utils';

const createMappingsFromPayload = (
  mappings: MappingPayload[],
  sources: Sources,
  mandatoryDestinationNames: string[],
  additionalParameters: EventAdditionalParameters,
  onChange: () => void,
) =>
  mappings.map(mappingPayload => {
    const mappingConfiguration = {
      id: temporaryMappingId(),
      sourceName: mappingPayload.sourceName,
      sources,
      destinationName: mappingPayload.destinationName,
      isDeletable: !mandatoryDestinationNames.includes(mappingPayload.destinationName),
      required: mappingPayload.required,
      valueTransformations: mappingPayload.transformations,
    };

    return createMapping(mappingConfiguration, additionalParameters, onChange);
  });

export const createEvent: CreateEvent = (eventConfiguration, additionalParameters, onChange) => {
  // NOTE: mandatoryDestinationNames are global for the whole transformer
  const mandatoryDestinationNames = additionalParameters.knownDestinations
    .filter(destination => destination.required)
    .map(({ name }) => name);

  let _mappings = createMappingsFromPayload(
    eventConfiguration.mappings,
    eventConfiguration.sources,
    mandatoryDestinationNames,
    additionalParameters,
    onChange,
  );

  if (!_mappings.length) {
    _mappings = mandatoryDestinationNames.map(mandatoryDestinationName => {
      const mappingConfiguration = {
        id: temporaryMappingId(),
        sourceName: '',
        sources: eventConfiguration.sources,
        destinationName: mandatoryDestinationName,
        isDeletable: false,
        required: true,
        valueTransformations: [],
      };

      return createMapping(mappingConfiguration, additionalParameters, onChange);
    });
  }

  const addMapping = (): Mapping => {
    const mappingConfiguration = {
      id: temporaryMappingId(),
      sourceName: '',
      sources: eventConfiguration.sources,
      destinationName: '',
      isDeletable: true,
      required: false,
      valueTransformations: [],
    };

    const mappingAdditionalParameters = {
      knownDestinations: additionalParameters.knownDestinations,
      knownValueTransformations: additionalParameters.knownValueTransformations,
      shouldAddSuggestedValueTransformations: true,
    };

    const newMapping = createMapping(mappingConfiguration, mappingAdditionalParameters, onChange);

    _mappings.push(newMapping);

    return newMapping;
  };

  const deleteMapping = (mappingId: string) => {
    _mappings = _mappings.filter(mapping => mapping.id !== mappingId);
  };

  const methods = withChangeHandler({ addMapping, deleteMapping }, onChange);

  const listAvailableDestinations = (): DestinationOption[] =>
    additionalParameters.knownDestinations?.map(destination => {
      const isUsed = !!_mappings.find(mapping => mapping.destination.name === destination.name);
      return { name: destination.name, label: destination.label, element: destination.element, isUsed };
    });

  const event = {
    id: eventConfiguration.id,
    type: eventConfiguration.type,
    name: eventConfiguration.name,
    get mappings() {
      return _mappings;
    },
    addMapping: methods.addMapping,
    deleteMapping: methods.deleteMapping,
    listAvailableDestinations,
  } as Event;

  return event;
};
