/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';
import i18n from '~/i18n';
import { GetCustomerFactsResponse, GetCustomerFactsResponseItem } from './containers/types';

const BASE_PATH = `${API_BASE}/profiles/externalfacts`;

/**
 * A service for customer fact related queries.
 */

const addCustomerFactId = (fact: GetCustomerFactsResponseItem | Record<string, never> = {}) => ({
  ...fact,
  customerFactId: fact.externalFactId,
});

const removeCustomerFactId = (
  fact: (GetCustomerFactsResponseItem & { customerFactId: string }) | Record<string, never> = {},
) => {
  const { customerFactId, ...newFact } = fact;
  if (customerFactId) {
    newFact.externalFactId = customerFactId;
  }
  return newFact;
};

const cusomterFactService = {
  getCustomerFacts(): Promise<GetCustomerFactsResponse['data']> {
    return Api.callGet(`${BASE_PATH}/dependencies`)
      .then(response => response.data)
      .then((data = []) => data.map(addCustomerFactId));
  },

  getCustomerFact(customerFactId: string) {
    return Api.callGet(`${BASE_PATH}/${customerFactId}/dependencies`)
      .then(response => response.data)
      .then(addCustomerFactId);
  },

  createCustomerFact(customerFact: GetCustomerFactsResponseItem & { customerFactId: string }) {
    const params = removeCustomerFactId(customerFact);
    const toastText = i18n.t('common:errors.invalidForm');
    return Api.callPost(BASE_PATH, params, toastText).then(response => response.data);
  },

  editCustomerFact(customerFact: GetCustomerFactsResponseItem & { customerFactId: string }) {
    return Api.callPost(`${BASE_PATH}/update`, removeCustomerFactId(customerFact)).then(response => response.data);
  },

  deleteCustomerFact(customerFactId: string) {
    return Api.callDelete(`${BASE_PATH}/${customerFactId}`);
  },
};

export default cusomterFactService;
