export const CONNECTOR_FETCH_START = 'app/profiles/connectors/CONNECTOR_FETCH_START';
export const CONNECTOR_FETCH_FAIL = 'app/profiles/connectors/CONNECTOR_FETCH_FAIL';
export const CONNECTOR_FETCH_SUCCESS = 'app/profiles/connectors/CONNECTOR_FETCH_SUCCESS';

export const SERVER_CONNECTORS_FETCH_START = 'app/profiles/connectors/SERVER_CONNECTORS_FETCH_START';
export const SERVER_CONNECTORS_FETCH_FAIL = 'app/profiles/connectors/SERVER_CONNECTORS_FETCH_FAIL';
export const SERVER_CONNECTORS_FETCH_SUCCESS = 'app/profiles/connectors/SERVER_CONNECTORS_FETCH_SUCCESS';

export const EVENT_CONNECTORS_FETCH_START = 'app/profiles/connectors/EVENT_CONNECTORS_FETCH_START';
export const EVENT_CONNECTORS_FETCH_FAIL = 'app/profiles/connectors/EVENT_CONNECTORS_FETCH_FAIL';
export const EVENT_CONNECTORS_FETCH_SUCCESS = 'app/profiles/connectors/EVENT_CONNECTORS_FETCH_SUCCESS';

export const CONNECTOR_TYPES_FETCH_START = 'app/profiles/connectors/CONNECTOR_TYPES_FETCH_START';
export const CONNECTOR_TYPES_FETCH_FAIL = 'app/profiles/connectors/CONNECTOR_TYPES_FETCH_FAIL';
export const CONNECTOR_TYPES_FETCH_SUCCESS = 'app/profiles/connectors/CONNECTOR_TYPES_FETCH_SUCCESS';

export const TAG_CONNECTORS_FETCH_START = 'app/profiles/connectors/TAG_CONNECTORS_FETCH_START';
export const TAG_CONNECTORS_FETCH_FAIL = 'app/profiles/connectors/TAG_CONNECTORS_FETCH_FAIL';
export const TAG_CONNECTORS_FETCH_SUCCESS = 'app/profiles/connectors/TAG_CONNECTORS_FETCH_SUCCESS';

export const DELETE_START = 'app/profiles/connectors/DELETE_START';
export const DELETE_FAIL = 'app/profiles/connectors/DELETE_FAIL';
export const DELETE_SUCCESS = 'app/profiles/connectors/DELETE_SUCCESS';

export const TOGGLE_ACTIVATION_START = 'app/profiles/connectors/TOGGLE_ACTIVATION_START';
export const TOGGLE_ACTIVATION_FAIL = 'app/profiles/connectors/TOGGLE_ACTIVATION_FAIL';
export const TOGGLE_ACTIVATION_SUCCESS = 'app/profiles/connectors/TOGGLE_ACTIVATION_SUCCESS';

export const TOGGLE_EVENT_ACTIVATION_SUCCESS = 'app/profiles/connectors/TOGGLE_EVENT_ACTIVATION_SUCCESS';

export const SAVE_START = 'app/profiles/connectors/SAVE_START';
export const SAVE_FAIL = 'app/profiles/connectors/SAVE_FAIL';
export const SAVE_SUCCESS = 'app/profiles/connectors/SAVE_SUCCESS';

export const SERVER_CONNECTORS_KEY = 'PROFILES/CONNECTORS/SERVER_LIST';
export const EVENT_CONNECTORS_KEY = 'PROFILES/CONNECTORS/EVENT_LIST';

export const SERVER_CONNECTOR_CATEGORY = 'SERVER';
export const TAG_CONNECTOR_CATEGORY = 'TAG_BASED';
export const EVENT_CONNECTOR_CATEGORY = 'EVENT';

export const SHOW_DEPENDANTS_CONTAINER = 'app/profiles/connectors/SHOW_DEPENDANTS_CONTAINER';
export const HIDE_DEPENDANTS_CONTAINER = 'app/profiles/connectors/HIDE_DEPENDANTS_CONTAINER';
export const DEPENDANTS_TYPE_TAB_CHANGE = 'app/profiles/connectors/DEPENDANTS_TYPE_TAB_CHANGE';

export const CONNECTOR_USED_IN = ['SELECTION', 'JOURNEY'];
