export const TYPE_FETCH_START = 'app/admin/urlBuilder/TYPE_FETCH_START';
export const TYPE_FETCH_SUCCESS = 'app/admin/urlBuilder/TYPE_FETCH_SUCCESS';
export const TYPE_FETCH_FAIL = 'app/admin/urlBuilder/TYPE_FETCH_FAIL';

export const TYPES_FETCH_START = 'app/admin/urlBuilder/TYPES_FETCH_START';
export const TYPES_FETCH_SUCCESS = 'app/admin/urlBuilder/TYPES_FETCH_SUCCESS';
export const TYPES_FETCH_FAIL = 'app/admin/urlBuilder/TYPES_FETCH_FAIL';

export const TYPE_SAVE_START = 'app/admin/urlBuilder/TYPE_SAVE_START';
export const TYPE_SAVE_SUCCESS = 'app/admin/urlBuilder/TYPE_SAVE_SUCCESS';
export const TYPE_SAVE_FAIL = 'app/admin/urlBuilder/TYPE_SAVE_FAIL';

export const TYPE_EDIT_START = 'app/admin/urlBuilder/TYPE_EDIT_START';
export const TYPE_EDIT_SUCCESS = 'app/admin/urlBuilder/TYPE_EDIT_SUCCESS';
export const TYPE_EDIT_FAIL = 'app/admin/urlBuilder/TYPE_EDIT_FAIL';

export const TYPE_DELETE_START = 'app/admin/urlBuilder/TYPES_DELETE_START';
export const TYPE_DELETE_SUCCESS = 'app/admin/urlBuilder/TYPES_DELETE_SUCCESS';
export const TYPE_DELETE_FAIL = 'app/admin/urlBuilder/TYPES_DELETE_FAIL';
