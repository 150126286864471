angular.module('webUi.ui.support.tagtemplates.variants.view', ['webUi.ui.support.tagtemplates.variants.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates.variants.viewHistory', {
                url: '/view/{variantId}/history/{sequenceNr}',
                isReact: true,
                leftMenu: true
            })
            .state('site.support.tagtemplates.variants.view', {
                url: '/view/{variantId}?sequenceNr',
                isReact: true,
                leftMenu: true
            });
    }]);
