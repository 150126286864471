angular.module('webUi.ui.tagmanagement.tags.errors', [
    'webUi.ui.tagmanagement.tags.base',
    'ui.bootstrap'
])

    .controller('TagErrorsViewController', [ '$scope', '$uibModalInstance', 'tagViewPojo', 'recentTagErrors', 'errorsPerBrowser', 'TagmanagementService',
        function TagErrorsViewController( $scope, $uibModalInstance,  tagViewPojo, recentTagErrors,   errorsPerBrowser, TagmanagementService){

            $scope.stats = {
                recentErrors: recentTagErrors,
                errorsPerBrowser: {
                    options: {
                        chart: {
                            zoomType: 'xy'
                        },
                        yAxis: {
                            title: {
                                text: 'Number of Errors'
                            }
                        },
                        tooltip: {
                            shared: true
                        }
                    },
                    series: errorsPerBrowser
                },
                tagErrors: {
                    options: {
                        chart: {
                            zoomType: 'xy'
                        },
                        yAxis: [{
                            title: {
                                text: 'Error Ratio'
                            },
                            min: 0
                        }, {}],
                        tooltip: {
                            formatter: function(){
                                var pointData = this.point.options;
                                if ( _.isUndefined(pointData.info) ){
                                    return '<b>Error Ratio</b>: ' + this.y.toFixed(2) + '%' +
                                '<br>Errors: ' + pointData.err
                                +'<br>Tag Calls: ' + pointData.total;
                                } else {
                                    return pointData.info;
                                }
                            }
                        }
                    },
                    series: [],
                    days: 90
                }
            };

            $scope.$watch('stats.tagErrors.days', function(newValue) {
                if(typeof newValue === 'undefined') {
                    return;
                }

                TagmanagementService.getErrorStats(tagViewPojo.tag.tagId, newValue).then(function(result) {
                    $scope.stats.tagErrors.series = result;
                });
            });

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
