angular.module('webUi.ui.audiences.segments.view', [])
    .config(['$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.audiences.segments.view', {
                    isReact: true,
                    url : '/view/{segmentId}',
                })
                .state('site.audiences.segments.historic', {
                    isReact: true,
                    url : '/hp/{audienceId}',
                });
        }
    ]);
