import React from 'react';
import PropTypes from 'prop-types';
import Detail from '~/components/src/Form/Detail/Default';
import LinkIcon from '~/components/src/LinkIcon';

export const UserInfo = ({ user, editBasicSettingsHref, t }) => {
  const whitelistedSubnetsInfo = <Detail label={t('user.whitelistedSubnets')} value={user.whitelistedSubnets} />;
  const phoneNumberInfo = <Detail label={t('user.phoneNumber')} value={user.phoneNumber} />;

  return (
    <div>
      <Detail label={t('common.email')} value={user.username} />
      <Detail label={t('common.name')} value={user.name} />
      <Detail label={t('common.defaultSite')} value={user.defaultSite} />
      {phoneNumberInfo}
      {whitelistedSubnetsInfo}
      <LinkIcon className="h-8 w-8" icon="edit" url={editBasicSettingsHref}>
        {t('common.actions.edit')}
      </LinkIcon>
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  editBasicSettingsHref: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default UserInfo;
