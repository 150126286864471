import React from 'react';
import { translate } from 'react-i18next';
import RulePicker from '~/common/modules/rules/components/RulePicker/index';
import { RuleOptions } from '~/components/src/RuleList';
import './AudienceRuleControls.scss';

const FilterRuleControls = ({ ruleTypesTree, onCriteriaSelect, criteria, audienceId }) => {
  if (criteria.length === 0) {
    return (
      <div className="AudienceEdit-rulePickers">
        <RulePicker
          onSelect={onCriteriaSelect}
          ruleTypesTree={ruleTypesTree}
          actionName="Add an event"
          actionType={RuleOptions.AND}
          audienceId={audienceId}
          testHook="addAndRule"
          className="u-inlineBlock"
          usedForFilters
        />
      </div>
    );
  }

  return (
    <div className="AudienceEdit-rulePickers">
      <RulePicker
        onSelect={onCriteriaSelect}
        ruleTypesTree={ruleTypesTree}
        actionName="Add an event"
        actionType={RuleOptions.AND}
        audienceId={audienceId}
        testHook="addAndRule"
        usedForFilters
      />
    </div>
  );
};

export default translate('audiences')(FilterRuleControls);
