import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl, changeUrl } from '~/common';
import i18n from '~/context/i18n';
import * as modals from '~/modals';
import Spinner from '~/components/src/Spinner';
import Heading from '~/components/src/Heading';
import RouterTabs from '~/components/src/RouterTabs';
import { Panel } from '~/components/src/Containers';
import { kind, TabList, Tab, TabPanel } from '~/components/src/Tabs';
import ClientDetails from '~/context/clients/components/ClientDetails';
import {
  fetchClientIfNeeded,
  fetchSitesByClientIfNeeded,
  deleteClient,
  isFetchingClients,
  getClientSites,
  byClientId,
  ContextClientsService,
} from '~/context/clients';
import BtnIcon from '~/components/src/BtnIcon';
import LinkIcon from '~/components/src/LinkIcon';
import DomainsListContainer from './DomainsListContainer';
import ClientSitesContainer from './ClientSitesContainer';

/**
 * Client View container class present view of a chosen client by the user.
 */
class ClientViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Lifecycle hook
   */
  componentDidMount() {
    this.props.fetchClientIfNeeded();
    this.props.fetchSitesIfNeeded();
    ContextClientsService.getAccountManagers(this.props.clientId).then(response => {
      this.setState({ accountManagers: response });
    });
  }

  /**
   * Render method.
   * @returns {*}
   */
  render() {
    const { props } = this;
    const { client, sites, t, deleteClient, isContextAdmin } = props;
    const { accountManagers } = this.state;
    return (
      <>
        <RouterTabs kind={kind.heading}>
          <TabList>
            <Tab testHook="basicInfo">{t('view.headings.basicSettings')}</Tab>
            <Tab testHook="domains">{t('view.headings.domains')}</Tab>
            <Tab testHook="clientSites">{t('view.headings.accountSites')}</Tab>
          </TabList>
          <TabPanel>
            {!accountManagers || props.isFetching ? (
              <Spinner />
            ) : (
              <>
                <Heading title={t('view.headings.basicSettings')}>
                  {isContextAdmin && (
                    <BtnIcon
                      icon="delete"
                      onClick={() => deleteClient(client)}
                      testHook="deleteClient"
                      disabled={!!sites.length}
                      tooltip={
                        sites.length
                          ? t('view.clientSettings.buttons.deleteClient.disabledTooltip')
                          : t('view.clientSettings.buttons.deleteClient.title')
                      }
                    />
                  )}
                  {isContextAdmin && (
                    <LinkIcon
                      color="blue"
                      className="h-8 w-8"
                      icon="edit"
                      tooltip={t('view.clientSettings.buttons.editClient.text')}
                      url={buildUrl(`context/clients/edit/${props.clientId}`)}
                      testHook="editClient"
                    />
                  )}
                </Heading>
                <Panel>
                  <ClientDetails {...props} accountManagers={accountManagers} />
                </Panel>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <DomainsListContainer isContextAdmin={props.isContextAdmin} clientId={props.clientId} client={client} />
          </TabPanel>
          <TabPanel>
            <ClientSitesContainer isContextAdmin={props.isContextAdmin} clientId={props.clientId} />
          </TabPanel>
        </RouterTabs>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isContextAdmin: ownProps.isContextAdmin,
  client: byClientId(state, ownProps.clientId),
  isFetching: isFetchingClients(state),
  sites: getClientSites(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchClientIfNeeded: () => dispatch(fetchClientIfNeeded(ownProps.clientId)),
  fetchSitesIfNeeded: () => dispatch(fetchSitesByClientIfNeeded(ownProps.clientId)),
  deleteClient: client =>
    dispatch(
      modals.showModal(modals.DELETE_MODAL, {
        title: i18n.t('clients:view.modals.deleteClient.title'),
        message: i18n.t('clients:view.modals.deleteClient.message', { clientName: client.name }),
        onConfirm: () => dispatch(deleteClient(ownProps.clientId)),
      }),
    ),
  updatePasswordPolicy: client => {
    changeUrl(`context/clients/view/${client.clientId}/passwordpolicy`);
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('clients'))(ClientViewContainer);
