import React from 'react';
import { translate } from 'react-i18next';
import Spinner from '~/components/src/Spinner';
import Empty from '~/components/src/Empty';
import JourneyTable from './JourneyTable';

const STATE_KEY = 'CUSTOMER_DATA_JOURNEY';

const CustomerJourneyComponent = ({ customerJourneyState, t }) => {
  const notFoundMessage = t('notFound');

  const { value, error } = customerJourneyState;

  if (error) {
    return <Empty body={notFoundMessage} />;
  }

  if (!value) return <Spinner />;

  if (!value.length) return <Empty body={notFoundMessage} />;

  const list = value.map(item => ({ ...item, status: item?.state?.label }));

  return <JourneyTable list={list} stateKey={STATE_KEY} />;
};

export default translate('customerData')(CustomerJourneyComponent);
