import React from 'react';
import { UnControlled as CodeComponent } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';

import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';

import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/addon/mode/overlay.js';
import 'codemirror/addon/selection/mark-selection.js';

import './styles.scss';

const CodeMirror = window.CodeMirror || {
  javascriptValidator: null,
  showHint: () => {},
};

export const mode = {
  css: 'css',
  js: 'javascript',
  html: 'xml',
};

const defaultOptions = {
  [mode.js]: {
    mode: 'javascript',
    theme: 'eclipse',
    readOnly: false,
    lineNumbers: true,
    indentWithTabs: true,
    indentUnit: 4,
    fixedGutter: false,
    matchBrackets: true,
    tabMode: 'shift',
    gutters: ['CodeMirror-lint-markers'],
    lint: true,
    lineWrapping: true,
    lintWith: CodeMirror.javascriptValidator,
    extraKeys: {
      'Ctrl-Space': function CtrlSpace(cm) {
        CodeMirror.showHint(cm);
      },
      'Shift-Tab': 'indentLess',
    },
  },
  [mode.html]: {
    mode: {
      name: 'htmlmixed',
    },
    theme: 'eclipse',
    readOnly: false,
    lineNumbers: true,
    fixedGutter: false,
    indentWithTabs: true,
    lineWrapping: true,
    indentUnit: 4,
    gutters: ['CodeMirror-lint-markers'],
    matchBrackets: true,
    viewportMargin: Infinity,
  },
  [mode.css]: {
    mode: 'css',
    theme: 'eclipse',
    readOnly: false,
    lineNumbers: true,
    fixedGutter: false,
    lineWrapping: true,
    indentWithTabs: true,
    indentUnit: 4,
    matchBrackets: true,
    lintWith: null,
    gutters: ['CodeMirror-lint-markers'],
  },
};

const Code = ({ options, mode, ...props }) => (
  <CodeComponent
    options={{
      ...defaultOptions[mode],
      ...options,
    }}
    {...props}
  />
);

export default Code;
