angular.module('webUi.ui.tagmanagement.tags.history', [
    'webUi.ui.tagmanagement.tags.base',
    'ui.bootstrap'
])

    .controller('TagHistoryController', [ '$scope', '$uibModalInstance', 'tagViewPojo', 'tagHistory', 'deployMap',
        function TagHistoryController( $scope,   $uibModalInstance,   tagViewPojo,   tagHistory,   deployMap){

            $scope.tagViewPojo = tagViewPojo;
            $scope.tagHistory = tagHistory;

            $scope.getEnvironmentsForVersion = function(sequenceNr) {
                var environments = [];

                _.forEach(deployMap, function(environment) {
                    if(environment.deployedSequenceNr === sequenceNr) {
                        environments.push(environment.tagEnvironmentName);
                    }
                });

                return environments;
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
