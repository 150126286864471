import { connect } from 'react-redux';
import { withRouter } from '~/common/withRouter';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import {
  fetchAudience,
  getAudience,
  getAudienceDetails,
  getAudienceConnectors,
  getAudienceRulesCombinations,
  getTreeData,
  isFetchingTree,
  isFetchingAudience,
  openDeleteAudienceDialog,
  cloneAudience,
  fetchAudiencesTree,
  linkAudience,
} from '~/profiles/audiences';
import {
  fetchAudienceBreadcrumbs,
  getBreadcrumbs,
  openCreateAudienceGroupModal,
  openDeleteAudienceGroupModal,
  getParentId,
} from '~/profiles/audiencesTree';
import { AudienceView } from './presenter';

const mapStateToProps = (state, ownProps) => ({
  isFetchingAudience: isFetchingAudience(state),
  isFetchingTree: isFetchingTree(state),
  details: getAudienceDetails(state, ownProps?.params.audienceId),
  audience: getAudience(state, ownProps?.params.audienceId),
  connectors: getAudienceConnectors(state, ownProps?.params.audienceId),
  breadcrumbs: getBreadcrumbs(state, ownProps?.params.audienceId),
  criteria: getAudienceRulesCombinations(state, ownProps?.params.audienceId),
  treeData: getTreeData(state, ownProps?.params.audienceId),
  parentId: getParentId(state, ownProps?.params.audienceId),
});

const mapDispatchToProps = {
  fetchAudience,
  fetchAudienceBreadcrumbs,
  openCreateAudienceGroupModal,
  openDeleteAudienceGroupModal,
  openDeleteAudienceDialog,
  cloneAudience,
  fetchAudiencesTree,
  linkAudience,
};

const enhance = compose(translate('audiences'), withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(AudienceView);
