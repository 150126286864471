import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from '~/components';
import Icons from '~/components/src/Icons';
import cx from 'classnames';
import Chart from '~/components/src/Charts';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import { Panel } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import DataExchangeGraph from '~/profiles/components/DataExchangeGraph/DataExchangeGraph';
import { formatNumber } from '~/common';
import i18n from '~/i18n';

import constants from './constants';
import './style.scss';

const getFrequencyTypes = () => [
  { value: constants.seven, label: i18n.t('audiences:chart.frequency.seven') },
  { value: constants.thirty, label: i18n.t('audiences:chart.frequency.thirty') },
  { value: constants.ninety, label: i18n.t('audiences:chart.frequency.ninety') },
];

const legend = {
  layout: 'horizontal',
  align: 'left',
  verticalAlign: 'bottom',
};

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const GeneralStatData = ({ stats = [], label, className = '' }) => (
  <div className="AudienceActivity-generalData">
    <span className={cx('AudienceActivity-generalData--count', className)}>{formatNumber(_.last(stats)) || 0}</span>
    <span className="AudienceActivity-generalData--label">{label}</span>
  </div>
);

export const ActivityHeader = ({
  days,
  headerTitle,
  className = '',
  headingClassName = '',
  tooltipText = '',
  setFrequency,
  name,
}) => (
  <div className={className}>
    <Heading title={headerTitle} kind="h3" className={cx('AudienceActivity-heading', headingClassName)}>
      {tooltipText && (
        <>
          <span className="ml-2"></span>
          <Icons icon="info" tooltip={tooltipText} className="h-4 w-4 text-gray-500" />
        </>
      )}
      <SelectElement
        name={name}
        value={days}
        onChange={selectedOption => setFrequency(selectedOption.value)}
        options={getFrequencyTypes()}
        clearable={false}
        className="AudienceActivity-select"
      />
    </Heading>
  </div>
);

class AudienceActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: defaultDate.startDate,
      endDate: defaultDate.endDate,
    };
    this.fetchStats = this.fetchStats.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
  }

  fetchStats() {
    const { startDate, endDate } = this.state;
    this.props.fetchStats(
      this.props.audience.segment.segmentId,
      startDate.startOf('day').toDate().getTime(),
      endDate.endOf('day').toDate().getTime(),
    );
  }

  handleDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate }, () => {
      this.fetchStats();
    });
  }

  componentDidMount() {
    this.fetchStats();
  }

  render() {
    const { t, stats, audience } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <div className="AudienceActivity" key={`audienceAcitivityContainer-${audience.segment.segmentId}`}>
        <Heading title={t('profiles')} kind="h3" className="AudienceActivity-heading">
          <DateRangePicker startDate={startDate} endDate={endDate} handleDatesChange={this.handleDatesChange} />
        </Heading>
        <Panel>
          <div className="u-md-size12of12">
            <div className="AudienceActivity-heading--data">
              <GeneralStatData stats={stats.segmentSize} label={t('statistics.totalSize')} className="TotalSizeColor" />
              <GeneralStatData stats={stats.added} label={t('statistics.added')} className="AddedTodayColor" />
              <GeneralStatData stats={stats.removed} label={t('statistics.removed')} className="RemovedTodayColor" />
            </div>
            <div>
              <Chart
                series={[
                  {
                    color: constants.chartColorOrange,
                    name: t('chart.labelAudienceSize'),
                    data: stats.segmentSize || [],
                  },
                ]}
                legend={legend}
                yAxisTitle=""
                xAxisLabels={stats.timestamp}
                noDataMessage={t('chart.noData')}
              />
            </div>

            <div>
              <Chart
                chartType="column"
                series={[
                  {
                    color: constants.chartColorGreen,
                    name: t('chart.labelAdded'),
                    data: stats.added || [],
                  },
                  {
                    color: constants.chartColorDarkBlue,
                    name: t('chart.labelRemoved'),
                    data: stats.removed || [],
                  },
                ]}
                legend={legend}
                xAxisLabels={stats.timestamp}
                noDataMessage={t('chart.noData')}
              />
            </div>
          </div>
        </Panel>
        <DataExchangeGraph integrations={audience.segment.integrations} sourceId={audience.segment.segmentId} />
      </div>
    );
  }
}

AudienceActivity.propTypes = {
  fetchStats: PropTypes.func.isRequired,
  stats: PropTypes.object,
  fetchingAudienceStats: PropTypes.object,
  audience: PropTypes.object.isRequired,
};

export default AudienceActivity;
