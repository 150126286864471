import Checkbox from './Checkbox';
import Code from './Code';
import Dropzone from './Dropzone';
import Input from './Input';
import CleanInput from './CleanInput';
import Select from './Select';
import Textarea from './Textarea';
import CheckboxGroup from './CheckboxGroup';
import Image from './Image';

export default {
  Checkbox,
  Code,
  Dropzone,
  Input,
  CleanInput,
  Select,
  Textarea,
  CheckboxGroup,
  Image,
};
