import { getSubmenuForRuleType, hasSubMenu, RulesMap, RulesOrder } from '~/common/modules/rules/selectors';
import { RuleDefinition, FilterRule, Filter, FilterWithRules } from './types';

export const getRulesTreeFromTypes = (ruleTypes: RuleDefinition[]): any[] => {
  if (ruleTypes?.length === 0) {
    return [];
  }

  const filteredRulesOrder = RulesOrder.filter(ruleTypeName =>
    ruleTypes?.some(ruleType => ruleType.clazz === ruleTypeName),
  );

  return filteredRulesOrder.map(type => {
    const { title, subtitle } = RulesMap[type];
    const subMenu = hasSubMenu(type) ? getSubmenuForRuleType(ruleTypes, type) : null;
    const ruleType = ruleTypes?.find(ruleType => ruleType.clazz === type);

    const ruleDefinitionId = ruleType?.ruleDefinitionId || null;
    const availableFilters = ruleType?.filters || [];

    return {
      type,
      subtitle,
      subMenu,
      availableFilters,
      ruleDefinitionId,
      title,
      ruleDefinitionName: ruleType?.ruleDefinitionName,
      ruleName: ruleType?.ruleName,
    };
  });
};

export const mapFilterToEvent = (filter: Filter): FilterWithRules => ({
  ...filter,
  rules: filter.rules.map((rule: RuleDefinition) => ({
    id: rule.ruleDefinitionId,
    ruleDefinitionId: rule.ruleDefinitionId,
    title: rule.ruleName,
    typeLabel: rule.ruleDefinitionName,
    type: rule.clazz,
    filters: rule.filters,
    ruleDefinitionName: rule.ruleDefinitionName,
    ruleName: rule.ruleName,
  })),
});

export const mapEventToFilter = (events: FilterRule[]): RuleDefinition[] =>
  events.map((event: FilterRule) => ({
    ruleDefinitionId: event.ruleDefinitionId,
    ruleName: event.ruleName,
    ruleDefinitionName: event.ruleDefinitionName,
    clazz: event.type,
    filters: event.filters,
  }));
