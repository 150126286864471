angular.module('webUi.ui.tagmanagement.tags.form', [
    'webUi.common.Utils', 'webUi.ui.tagmanagement.tags.base', 'webUi.service.support', 'ui.codemirror', 'ui.bootstrap',
    'webUi.ui.tagmanagement.tags.form.step1',
    'webUi.ui.tagmanagement.tags.form.step2',
    'webUi.ui.tagmanagement.tags.form.step3'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tags.form', {
                url: '/form?location',
                views: {
                    '@site.tagmanagement': {
                        templateUrl: 'ui/tagmanagement/tags/form/form.tpl.html',
                        controller: 'TagmanagementTagFormController'
                    }
                },
                leftMenu: false
            });
    }])
    .controller('TagmanagementTagFormController', [
        '$scope',
        '$uibModalInstance',
        'TagmanagementService',
        'securityContext',
        'dialogsModel',
        'Utils',
        'ConditionsCommonService',
        /**
         * @function TagmanagementTagFormController
         * @memberOf webUi.ui.tagmanagement.tags.form
         *
         * @param $scope
         * @param $uibModalInstance
         * @param TagmanagementService
         * @param securityContext
         * @param dialogsModel
         *
         *
         * @returns {TagmanagementTagFormController}
         */
        function TagmanagementTagFormController($scope,
            $uibModalInstance,
            TagmanagementService,
            securityContext,
            dialogsModel,
            Utils,
            ConditionsCommonService) {

            // Command object
            $scope.tag = {
                name: '',
                description: '',
                content: undefined,
                priority: 10,
                location: dialogsModel.tagLocation,
                conditions: []
            };

            TagmanagementService.getReadableLocation(dialogsModel.tagLocation).then(function (location) {
                $scope.location = location;
            });

            // Object that contains the state so we can share it between steps
            var state = $scope.state = $scope.state || {
                beforePrev: function () {

                },
                beforeSubmit: function () {
                    return true;
                },
                submitCallback: function () {

                },
                isSupportEngineer: securityContext.isSupportEngineer()
            };

            // Defines steps
            var steps = $scope.steps = [{
                id: 'templateSelect',
                text: 'Select Template',
                templateUrl: 'ui/tagmanagement/tags/form/step1.tpl.html',
                hideNavigation: true,
                header: true,
                headerState: 0
            }, {
                id: 'templateConfiguration',
                text: 'Configure Template',
                templateUrl: 'ui/tagmanagement/tags/form/step2.tpl.html',
                header: true,
                headerState: 1
            }, {
                id: 'templateFinalize',
                text: 'Finalize Template',
                templateUrl: 'ui/tagmanagement/tags/form/step3.tpl.html',
                header: true,
                headerState: 2,
                nextLabel: 'Save'
            }, {
                id: 'cookiePermissionCondition',
                templateUrl: 'ui/tagmanagement/tags/conditions/form/cookiePermissionCondition.tpl.html',
                headerState: 2,
                prevOverride: 'templateFinalize',
                nextOverride: 'templateFinalize',
                nextLabel: 'Update'
            }, {
                id: 'eventCondition',
                templateUrl: 'ui/tagmanagement/tags/conditions/form/eventCondition.tpl.html',
                headerState: 2,
                prevOverride: 'templateFinalize',
                nextOverride: 'templateFinalize',
                nextLabel: 'Update'
            }, {
                id: 'experimentCondition',
                templateUrl: 'ui/tagmanagement/tags/conditions/form/experimentCondition.tpl.html',
                headerState: 2,
                prevOverride: 'templateFinalize',
                nextOverride: 'templateFinalize',
                nextLabel: 'Update'
            }, {
                id: 'deduplicationCondition',
                templateUrl: 'ui/tagmanagement/tags/conditions/form/deduplicationCondition.tpl.html',
                headerState: 2,
                prevOverride: 'templateFinalize',
                nextOverride: 'templateFinalize',
                nextLabel: 'Update'
            }
            ];

            var setActiveStep = function (step) {
                state = ConditionsCommonService.setActiveStep(state, step);
            };

            setActiveStep(steps[0]);

            // Helper functions
            $scope.close = function () {
                $uibModalInstance.close();
            };

            $scope.templateState = {};

            $scope.navigate = function (step) {

                // convert id step to step object
                if (_.isString(step)) {
                    step = _.find(steps, {'id': step});
                }

                if (step.headerState <= state.activeStep.headerState) {
                    setActiveStep(step);
                }
            };

            $scope.prevStep = function () {
                state =  ConditionsCommonService.moveToPrevStep(state, steps);
            };

            $scope.nextStep = function () {
                state = ConditionsCommonService.moveToNextStep(state,steps);
            };


            $scope.hasCondition = function (conditionType) {
                return ConditionsCommonService.hasCondition($scope.tag.conditions, conditionType);
            };

            $scope.getCondition = function (conditionType) {
                return ConditionsCommonService.getCondition($scope.tag.conditions, conditionType);
            };

            $scope.removeCondition = function (conditionType) {
                ConditionsCommonService.removeCondition($scope.tag.conditions, conditionType, state, steps);
                setActiveStep(_.find(steps, {'id': state.activeStep.nextOverride}));
            };

            $scope.addOrReplaceCondition = function (newCondition) {
                ConditionsCommonService.addOrReplaceCondition($scope.tag.conditions, newCondition);
            };
        }
    ]);
