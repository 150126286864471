angular.module('webUi.ui.content.campaigns.placementGroups.placements.formModal', [ 'ngResource', 'webUi.service', 'ui.bootstrap' ])

    .controller('AdServingPlacementFormModalController', [ '$scope', '$uibModalInstance', 'dialogsModel', 'PlacementGroupService', 'ValidService',
        function AdServingPlacementFormModalController( $scope, $uibModalInstance, dialogsModel, PlacementGroupService, ValidService) {

            $scope.placement = {
                placementGroupId: dialogsModel.data.placementGroup.placementGroupId,
                campaignId: dialogsModel.data.placementGroup.campaignId
            };
            $scope.placements = dialogsModel.data.placements;
            $scope.placementGroup = dialogsModel.data.placementGroup;

            $scope.isValidPlacement = function(p) {
                if(!p || !p.width  || !p.height) {
                    return false;
                }
                return ValidService.isPositiveNumber(p.width) && ValidService.isPositiveNumber(p.height);
            };

            $scope.isUniquePlacement = function(p) {
                if(ValidService.isEmpty(p) || (ValidService.isEmpty(p.width) && ValidService.isEmpty(p.height))) {
                    return true;
                }
                var foundIdx = _.findIndex($scope.placements, function(placement) {
                    return placement.width === _.parseInt(p.width) && placement.height === _.parseInt(p.height);
                });
                return foundIdx === -1;
            };

            var initDimensions = function (existingPlacements, defaultPlacements) {
                _.forEach(defaultPlacements, function(p) {
                    _.forEach(existingPlacements, function(existing) {
                        if (existing.width === p.width && existing.height === p.height) {
                            p.exists = true;
                        }
                    });
                });
                return defaultPlacements;
            };

            $scope.dimensions = initDimensions(dialogsModel.data.placementGroup.placements, PlacementGroupService.getDefaultPlacements());

            /**
		 * Sets the dimension of a creative
		 *
		 * @param {type} dimension
		 * @returns {undefined}
		 */
            $scope.selectDimension = function(dimension) {
                $scope.placement.width = dimension.width;
                $scope.placement.height = dimension.height;
            };

            $scope.submit = function() {
                dialogsModel.onSave($scope.placement);
                $uibModalInstance.close(true);
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
