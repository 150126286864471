import { combineReducers } from 'redux';
import * as types from './types';
import i18n from '../i18n';

const defaultAction = {
  payload: {},
};

const userInfo = (state = {}, action = defaultAction) => {
  switch (action.type) {
    case types.USER_SETTINGS_FETCH_SUCCESS:
      return action.payload.user;
    case types.USER_SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        defaultSiteId: action.payload.defaultSiteId,
      };
    case types.SMS_REQUEST_SUCCESS:
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      };
    case types.PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.TIMEBASED_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.MAKE_DEFAULT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.TWO_FACTOR_AUTH_DELETE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.UNLINK_OPEN_ID:
      return {
        ...state,
        hasOpenId: false,
      };
    default:
      return state;
  }
};

const accessibleSites = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.USER_SETTINGS_FETCH_SUCCESS:
      return action.payload.accessibleSites;
    default:
      return state;
  }
};

const defaultErrorRequest = {
  updatePassword: null,
  updatePhone: null,
  smsToken: null,
  timeBasedToken: null,
};

const errorRequest = (state = defaultErrorRequest, action = defaultAction) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updatePassword: { ...action.payload },
      };
    case types.RESET_ERROR_PASSWORD_REQUEST:
    case types.UPDATE_PASSWORD_START:
      return {
        ...state,
        updatePassword: null,
      };
    case types.RESET_ERROR_UPDATE_PHONE_REQUEST:
    case types.PHONE_UPDATE_START:
      return {
        ...state,
        updatePhone: null,
      };
    case types.PHONE_UPDATE_FAIL:
      return {
        ...state,
        updatePhone: { smsCodeCorrect: action.payload.smsCodeCorrect },
      };
    case types.SMS_REQUEST_FAIL:
      return {
        ...state,
        smsToken: !action.error || i18n.t('settings:errors.responseError'),
      };
    case types.RESET_SMS_TOKEN_STEP:
    case types.SMS_REQUEST_START:
      return {
        ...state,
        smsToken: null,
      };
    case types.TIMEBASED_UPDATE_FAIL:
      return {
        ...state,
        timeBasedToken: action.errorMessage || i18n.t('settings:errors.timeBasedToken'),
      };
    case types.RESET_TIMEBASED_TOKEN_STEP:
    case types.TIMEBASED_UPDATE_START:
      return {
        ...state,
        timeBasedToken: null,
      };
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingUserSettings: false,
  shouldFetchUserSettings: true,
  smsTokenStep: 1,
  timebasedTokenStep: 1,
  isRequestingSmsToken: false,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.USER_SETTINGS_FETCH_START:
      return {
        ...state,
        isFetchingUserSettings: true,
        shouldFetchUserSettings: false,
      };
    case types.USER_SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingUserSettings: false,
        shouldFetchUserSettings: false,
      };
    case types.SET_ACCESSIBLE_SITES_FILTER:
      return {
        ...state,
        accessibleSitesFilter: action.payload,
      };
    case types.REMOVE_ACCESSIBLE_SITES_FILTER:
      return {
        ...state,
        accessibleSitesFilter: '',
      };
    case types.GO_TO_SMS_CODE_CONFIRMATION_STEP:
      return {
        ...state,
        smsTokenStep: 2,
      };
    case types.SMS_REQUEST_START:
      return {
        ...state,
        isRequestingSmsToken: true,
      };
    case types.SMS_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestingSmsToken: false,
      };
    case types.SMS_REQUEST_FAIL:
      return {
        ...state,
        smsTokenStep: 1,
        isRequestingSmsToken: false,
      };
    case types.TIMEBASED_REQUEST_SUCCESS:
      return {
        ...state,
        timebasedTokenStep: 2,
      };
    case types.RESET_SMS_TOKEN_STEP:
      return {
        ...state,
        smsTokenStep: 1,
      };
    case types.RESET_TIMEBASED_TOKEN_STEP:
      return {
        ...state,
        timebasedTokenStep: 1,
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isRequestPasswordFailed: false,
      };
    default:
      return state;
  }
};

const settingsReducer = combineReducers({
  userInfo,
  accessibleSites,
  errorRequest,
  ui,
});

export default settingsReducer;
