import { createContext } from 'react';

type ContextType = {
  nodesErrors: any;
  isSumOfVariantsValid: boolean;
  hasExperiments: boolean;
  transitionSource: null | string;
  journeyErrors: any;
  setJourneyErrors: (errors: any) => void;
  setTransitionSource: (id: any) => void;
  setTransistionToDelete: (id: any) => void;
  handleExpandAndCollapse: (nodeId: string) => void;
  setNodeToDelete: (node: any) => void;
  setShowTransistionModal: (val: string | null) => void;
  setShowJourneyErrors: (val: boolean) => void;
};

const defaultValues = {
  nodesErrors: [],
  isSumOfVariantsValid: false,
  hasExperiments: false,
  transitionSource: null,
  journeyErrors: null,
  setJourneyErrors: () => {
    // Empty
  },
  setTransitionSource: () => {
    // Empty
  },
  handleExpandAndCollapse: () => {
    // Empty
  },
  setTransistionToDelete: () => {
    // Empty
  },
  setShowTransistionModal: () => {
    // Empty
  },
  setNodeToDelete: () => {
    // Empty
  },
  setShowJourneyErrors: () => {
    // Empty
  },
};

export const CanvasContext = createContext<ContextType>(defaultValues);
