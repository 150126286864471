import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import { withRouter } from '~/common/withRouter';
import { getEngagements, getCustomerFacts, getSyncPartners } from '~/profiles/helpers/clientsHelper.js';
import { SmallWrapper, Panel } from '~/components/src/Containers';
import { updateCollector, fetchCollectors, fetchCodeForCollector } from '../collectors/actions';
import CollectorForm from '../components/CollectorsForm/CollectorsForm';
import { getCollectorById, isFetchingCollectors, getCodeSamplesById } from '../collectors/selectors';
import { COLLECTORS_EDIT_NAME } from '../collectors/types';

const selector = formValueSelector(COLLECTORS_EDIT_NAME);

export class EditCollectorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engagements: [],
      externalFacts: [],
      partners: [],
    };
  }

  componentDidMount() {
    // AngularJS Service, not migrated to redux yet
    getEngagements(document).then(engagements => {
      this.setState({ engagements });
    });
    getCustomerFacts(document).then(externalFacts => {
      this.setState({ externalFacts });
    });
    getSyncPartners(document).then(partners => {
      this.setState({ partners });
    });
    this.props.fetchCollectors();
    this.props.fetchCodeForCollector(this.props.collectorId);
  }

  render() {
    const { isFetching, collectorId, t } = this.props;
    if (isFetching) {
      return <Spinner />;
    }
    return (
      <Page>
        <SmallWrapper>
          <Heading title={t('form.edit')} />
          <Panel>
            <CollectorForm
              backHref={buildUrl(`profiles/collectors/view/${collectorId}`)}
              {...this.props}
              engagements={this.state.engagements}
              externalFacts={this.state.externalFacts}
              partners={this.state.partners}
            />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      collectorId: ownProps?.params.collectorId,
      collectorType: selector(state, 'collectorType'),
      engagementOrFactId: selector(state, 'engagementOrFactId'),
      initialValues: getCollectorById(state, ownProps.params.collectorId),
      isFetching: isFetchingCollectors(state),
      code: getCodeSamplesById(state, ownProps?.params.collectorId),
    }),
    dispatch => ({
      updateCollector: (code, collector) => dispatch(updateCollector({ ...collector, code })),
      fetchCodeForCollector: id => dispatch(fetchCodeForCollector(id)),
      fetchCollectors: () => dispatch(fetchCollectors()),
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      onSubmit: collector =>
        dispatchProps.updateCollector(stateProps.code, {
          ...collector,
          syncPartners:
            collector?.syncPartners?.map(partner => (typeof partner === 'object' ? partner.optionId : partner)) || [],
        }),
    }),
  ),
  reduxForm({
    form: COLLECTORS_EDIT_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['collectors']),
)(EditCollectorPage);
