import React from 'react';
import Link from '~/components/src/Link';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import { UrlType, UrlsTableProps } from '../types';

const columnHelper = createColumnHelper<UrlType>();

const UrlsTable = ({ urls, searchQuery }: UrlsTableProps): JSX.Element => {
  const getColumns = () => [
    columnHelper.accessor('environment', {
      header: () => <div className="text-left">{i18n.t('indexedPaths:tables.urls.name')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('url', {
      header: () => <div className="text-left">{i18n.t('indexedPaths:tables.urls.link')}</div>,
      cell: info => (
        <div className="text-left">
          <Link href={info.getValue()} target="_blank">
            {i18n.t('indexedPaths:goToPage')}
          </Link>
        </div>
      ),
    }),
  ];

  return (
    <div className="mt-10">
      <UITable
        emptyMessage={i18n.t('indexedPaths:empty.urls.header')}
        emptyBody={searchQuery.length !== 0 ? i18n.t('indexedPaths:empty.body') : ''}
        testHook="urlsTable"
        data={urls}
        columns={getColumns()}
        searchQuery={searchQuery}
        enableSorting
        enableFilters
      />
    </div>
  );
};

export default UrlsTable;
