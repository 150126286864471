import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAPI, useApiWithState } from '~/common';
import { Page } from '~/components';
import DeletePopup from '~/components/src/DeletePopup';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { NodeTypes } from '~/workflows/constants';
import { fetchDependencies, fetchJourneyTree } from '~/workflows/dataService';
import { JourneyNode, TWorkflow } from '~/workflows/types';
import { getExperimentNodes } from '~/workflows/util';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import { ErrorCodes } from './constants';
import JourneyCanvas from './JourneyCanvas';
import { setLocalStorageValue } from './JourneyCanvas/utils';
import { JourneyContext } from './JourneyContext';
import JourneyRightPanel from './JourneyRightPanel';
import { makeRedirects } from './redirects';
import EditStep from './Step/EditStep';
import StepStats from './Step/StepStats';
import ViewStep from './Step/ViewStep';
import EditTrigger from './Trigger/EditTrigger';
import TriggerStats from './Trigger/TriggerStats';
import ViewTrigger from './Trigger/ViewTrigger';
import { JourneyValidationError } from './types';
import { getDeleteAction, getDeleteTitle, getModalMessage } from './util';

const JourneyForm = ({ journeyData, refetchJourney }: { journeyData: TWorkflow; refetchJourney: () => void }) => {
  const { navigate, hasWorkflowJourneyEdit: canEdit } = useContext(WorklfowContext);
  const { journeyId } = journeyData;
  const [nodeToDelete, setNodeToDelete] = useState<JourneyNode | null>(null);
  const [journeyErrors, setJourneyErrors] = useState<JourneyValidationError[]>([]);
  const [showJourneyErrors, setShowJourneyErrors] = useState(false);

  const {
    state: journeyTree,
    isLoading,
    refetch: refetchTree,
  } = useApiWithState(async () => fetchJourneyTree(journeyId));

  const { data: dependencies, isLoading: isDependenciesLoading } = useAPI(
    () => fetchDependencies(journeyId),
    [journeyId],
  );

  if (isLoading || !journeyTree || isDependenciesLoading) return <Spin />;

  const actions = makeRedirects(journeyId, navigate);
  const isUsedInJourneys = dependencies?.length !== 0;

  const { hasExperiments, experimentSteps } = getExperimentNodes(journeyTree.nodes);

  const refreshJourney = () => {
    refetchJourney();
    refetchTree();
  };

  const handleNodeDelete = async (nodeToDelete: JourneyNode) => {
    const deleteAction = getDeleteAction(nodeToDelete.type);
    await deleteAction(journeyId, nodeToDelete.nodeId);

    if (nodeToDelete.type === NodeTypes.STEP)
      setLocalStorageValue(`${journeyId}_stepParentTriggerId`, nodeToDelete?.parentTriggerId);

    showSuccess({ body: i18n.t(`workflow:orchestration.common.modal.${nodeToDelete.type.toLowerCase()}Deleted`) });
    setNodeToDelete(null);
    refreshJourney();
  };

  let goalError = false;

  if (showJourneyErrors) {
    goalError = !!journeyErrors.find(error => error.errorCode === ErrorCodes.JOURNEY_HAS_NO_GOALS);
  }

  const BaseComponent = (
    <Page className="flex h-full overflow-hidden !p-0" excludeContainer={true}>
      <div className="absolute left-0 flex h-full w-full overflow-hidden">
        <JourneyCanvas
          journeyErrors={journeyErrors}
          showJourneyErrors={showJourneyErrors}
          setJourneyErrors={setJourneyErrors}
          setNodeToDelete={setNodeToDelete}
          setShowJourneyErrors={setShowJourneyErrors}
        />
        <JourneyRightPanel goalError={goalError} canEdit={canEdit} setNodeToDelete={setNodeToDelete} />
      </div>
      {nodeToDelete && (
        <DeletePopup
          onClick={() => handleNodeDelete(nodeToDelete)}
          title={getDeleteTitle(nodeToDelete.type)}
          hidePopup={() => setNodeToDelete(null)}
        >
          {getModalMessage(nodeToDelete.label, nodeToDelete)}
        </DeletePopup>
      )}
    </Page>
  );

  return (
    <JourneyContext.Provider
      value={{ hasExperiments, showJourneyErrors, experimentSteps, journeyData, journeyTree, actions, refreshJourney }}
    >
      <Routes>
        <Route path="/" element={BaseComponent} />
        <Route path="trigger/:triggerId" element={<ViewTrigger canEdit={canEdit} />} />
        <Route path="step/:stepId" element={<ViewStep nodes={journeyTree.nodes} canEdit={canEdit} />} />
        <Route path="goal/:goalId" element={<ViewTrigger type={NodeTypes.GOAL} canEdit={canEdit} />} />
        <Route
          path="exitCondition/:exitConditionId"
          element={<ViewTrigger type={NodeTypes.EXIT_BY_CONDITION} canEdit={canEdit} />}
        />
        <Route path="triggerNew" element={<EditTrigger isUsedInJourneys={isUsedInJourneys} />} />
        <Route path="goalNew" element={<EditTrigger type={NodeTypes.GOAL} />} />
        <Route path="exitConditionNew" element={<EditTrigger type={NodeTypes.EXIT_BY_CONDITION} />} />
        <Route path="triggerEdit/:triggerId" element={<EditTrigger isUsedInJourneys={isUsedInJourneys} />} />
        <Route path="goalEdit/:triggerId" element={<EditTrigger type={NodeTypes.GOAL} />} />
        <Route path="exitConditionEdit/:triggerId" element={<EditTrigger type={NodeTypes.EXIT_BY_CONDITION} />} />
        <Route path="stepNew/addPartner" element={<EditStep journeyId={journeyId} />} />
        <Route path="stepEdit/:stepId" element={<EditStep journeyId={journeyId} />} />
        <Route path="statsView/step/:stepId" element={<StepStats />} />
        <Route path="statsView/goal/:goalId" element={<TriggerStats type={NodeTypes.GOAL} />} />
        <Route
          path="statsView/exitCondition/:exitConditionId"
          element={<TriggerStats type={NodeTypes.EXIT_BY_CONDITION} />}
        />
      </Routes>
    </JourneyContext.Provider>
  );
};

export default JourneyForm;
