angular.module('webUi.directive.support.template.r42propertySelection',
    [
        'webUi.directive.support.template.propertySelection'
    ]
)
    .directive('r42propertySelection', [
        'Utils',
        '$timeout',
        /**
     * @param Utils
     * @param $timeout
     */
        function (Utils, $timeout) {
            return {
                restrict: 'E',
                replace: true,
                scope: true,
                compile: function compile() {
                    return {
                        pre: function preLink(scope, iElement, iAttrs) {

                            scope.r42Label = iAttrs['label'];
                            scope.r42Description = iAttrs['description'];
                            scope.r42HelpText = iAttrs['r42HelpText'];
                            scope.r42ShowHelpPopover = !_.isUndefined(scope.r42HelpText);
                            scope.r42HelpTextPlacement = iAttrs['r42HelpTextPlacement'] || 'right';
                            scope.r42ModelName = iAttrs['ngModel'].substring(_.lastIndexOf(iAttrs['ngModel'], '.') + 1);
                            scope.r42FullModelName = iAttrs['ngModel'];
                            scope.r42ModelMap = iAttrs['r42ModelMap'];
                            scope.r42PlatformType= iAttrs['r42PlatformType']  || 'web';
                            scope.r42MobileShowRemoveEmpty= (iAttrs['r42MobileShowRemoveEmpty'] && scope.r42PlatformType === 'mobile')  || false;

                            // initialize model when undefined
                            var scopeValue = Utils.getScopeValue(scope.$parent, scope.r42FullModelName, false, true);
                            if(_.isNull(scopeValue)) {
                                scopeValue = {};
                                Utils.setScopeValue(scope.$parent, scope.r42FullModelName, scopeValue, true);
                            }
                            scope.scopeValue = scopeValue;
                            scope.r42Model = iAttrs['ngModel'].replace('tag.content.variables.', '');

                            // reset on scope destroy
                            scope.$on('$destroy', function() {
                                if(scope.templateState && scope.templateState.editMode) {
                                    Utils.setScopeValue(scope.$parent, scope.r42FullModelName, null, true);
                                }
                            });

                            scope.r42DefaultType = iAttrs['defaultType'];
                            scope.r42Types = iAttrs['types'];
                            scope.r42DefaultValue = iAttrs['defaultValue'];
                            scope.r42Placeholder = iAttrs['placeholder'] || 'Please provide a value';
                            scope.r42Required = iAttrs['required'] || false;

                            if(iAttrs['r42Validate']) {
                                scope.r42Validate = Utils.getScopeValue(scope.$parent, iAttrs['r42Validate'], false, true);
                            }

                            if(iAttrs['svFocus']) {
                                $timeout(function() {
                                    $(iElement).find('input').focus();
                                });
                            }
                        }
                    };
                },
                transclude: true,
                templateUrl: 'directive/support/template/r42propertySelection.tpl.html'
            };
        }
    ]);
