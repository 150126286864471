import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import ProfileConnectors from '~/profiles/connectors/dataService';
import { IAddUserPayload } from './types';

const PARTNER_NAME = 'googleAds';

const getGoogleAdsUsersListURL = (partnerId: string) =>
  `${API_BASE}/v1/partners/${PARTNER_NAME}/${partnerId}/userLists`;

const getGoogleAdsUsersList = (partnerId: string) =>
  Api.callGet(getGoogleAdsUsersListURL(partnerId), { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

const addUserToGoogleAds = (partnerId: string, payload: IAddUserPayload) =>
  Api.callPost(getGoogleAdsUsersListURL(partnerId), payload, { shouldShowToast: true }).then(parseResponse);

const getConsentTypes = (partner = PARTNER_NAME) =>
  Api.callGet(`${API_BASE}/v1/partners/${partner}/consentTypes`).then(parseResponse);

const getPayloadAndConsentType = (partnerId: string) =>
  ProfileConnectors.getConnectorDetail(partnerId).then(connector => ({
    payloadType: connector?.configuration?.payloadType?.name,
    consent: connector?.configuration?.consent,
  }));

export { addUserToGoogleAds, getConsentTypes, getGoogleAdsUsersList, getPayloadAndConsentType };
