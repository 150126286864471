angular.module('webUi.directive.highcharts', [])
    .directive('highcharts', ['$timeout', function ($timeout) {
        var directiveDefinitionObject = {
            restrict: 'EA',
            scope: {},
            controller: [
                '$scope', '$element', '$attrs',
                'ChartService', 'UUIDService',

                function webUiDirectiveHighcharts($scope, $element, $attrs, ChartService, UUIDService) {

                    var MILLIS_IN_DAY = 24 * 36e5;

                    var calculateStep = function (length) {
                        switch (true) {
                            case length >= 180:
                                return 10;
                            case length >= 90:
                                return 6;
                            case length >= 60:
                                return 4;
                            case length >= 30:
                                return 2;
                            default:
                                return 1;
                        }
                    };

                    var _r42ChartIdentifier = $attrs['hcIdentifier'] || UUIDService.newId();
                    var options = {
                        chart: {
                            renderTo: $element[0]
                        },
                        legend: {
                            enabled: false
                        },
                        credits: {
                            enabled: false
                        },
                        r42ChartIdentifier: _r42ChartIdentifier
                    };

                    if ($attrs.hcBaseOptions === 'date') {
                        $.extend(true, options, {
                            chart: {
                                height: 250,
                                events: {
                                    load: function (event) {
                                        ChartService.registerChart(event.target);
                                    }
                                }
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                type: 'datetime',
                                title: {
                                    text: 'Date'
                                },
                                dateTimeLabelFormats: {
                                    day: '%e. %b',
                                    month: '%M %y'
                                },
                                tickInterval: 3600 * 1000 * 24
                            },
                            yAxis: {
                                min: 0
                            }
                        });
                    }

                    // Override options if we have them
                    if ($attrs.hcOptions) {
                        var extendOptions = $scope.$parent.$eval($attrs.hcOptions);
                        $.extend(true, options, extendOptions);
                    }

                    // make tickaxes equivalent with legend labels
                    Highcharts.setOptions({
                        global: {
                            useUTC: false
                        }
                    });

                    /**
                     * Load Highcharts
                     * @type {Highcharts.Chart}
                     */
                    var chart = $scope.chart = new Highcharts.Chart(options);

                    // Watch the data.
                    $scope.$parent.$watch($attrs.hcSeries, function (newSeries) {

                        if (typeof newSeries === 'undefined') {
                            return;
                        }

                        for (var i = chart.series.length - 1; i >= 0; i--) {
                            chart.series[i].remove(false);
                        }

                        for (var j = 0; j < newSeries.length; j++) {

                            var newSerie = newSeries[j];

                            if ($attrs.hcBaseOptions === 'date') {

                                if (!newSerie.pointInterval) {
                                    newSerie.pointInterval = MILLIS_IN_DAY;
                                }

                                if (newSerie.data.length > 0 && _.isNumber(newSerie.data[0])) {

                                    // if point interval is in full days
                                    if (newSerie.pointInterval % MILLIS_IN_DAY === 0) {

                                        var dateStart = moment(newSerie.pointStart);
                                        var daysInBetween = newSerie.pointInterval / MILLIS_IN_DAY;

                                        var newData = [];

                                        for (var k = 0; k < newSerie.data.length; k++) {
                                            newData.push({name: dateStart.toDate().getTime(), y: newSerie.data[k]});
                                            dateStart.add(daysInBetween, 'days');
                                        }

                                        newSerie.data = newData;
                                    }
                                }
                            }

                            chart.addSeries(newSerie, false);
                        }

                        if ($attrs.hcBaseOptions === 'date') {
                            var points = 0;

                            for (var l = 0; l < newSeries.length; l++) {
                                points = Math.max(points, newSeries[l].data.length);
                            }

                            if (chart && chart.xAxis && chart.xAxis[0]) {
                                if (chart.xAxis[0].options && chart.xAxis[0].options.labels) {
                                    chart.xAxis[0].options.labels.step = calculateStep(points);
                                }
                                chart.xAxis[0].isDirty = true;
                            }
                        }
                        chart.redraw();


                    }, true);

                    // Watch the options for updates about yAxis
                    $scope.$parent.$watch($attrs.hcOptions, function (newOptions) {
                        if (newOptions.yAxis && newOptions.yAxis.length === 2) {
                            chart.yAxis[0].options.max = newOptions.yAxis[0].max;
                            chart.yAxis[0].options.min = newOptions.yAxis[0].min;
                            chart.yAxis[1].options.max = newOptions.yAxis[1].max;
                            chart.yAxis[1].options.min = newOptions.yAxis[1].min;
                            chart.redraw();
                        }
                    }, true);

                    //destroy the chart on directive destroy
                    $scope.$on('$destroy', function () {
                        ChartService.unregisterChart(chart);
                        chart.destroy();
                    });

                }],
            compile: function compile() {
                return {
                    post: function postLink(scope) {
                        // trick to resize chart to fit it's container after the drawing
                        $timeout(function () {
                            if (scope.chart) {
                                scope.chart.reflow();
                            }
                        }, 10);
                    }
                };
            }
        };

        return directiveDefinitionObject;
    }]);
