import { combineReducers } from 'redux';
import * as fromModals from '~/modals';
import { PHONE_UPDATE_SUCCESS } from '~/user/settings/types';
import { USER_UPDATE_SUCCESS } from '~/admin/users';
import * as types from './types';

/**
 * User Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.USER_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: action.payload,
      };
    case types.USER_EDIT_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          ...action.payload,
        },
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          phoneNumber: action.payload.phoneNumber,
          restrictedSubnets: action.payload.restrictedSubnets,
        },
      };
    case types.USERS_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(user => {
        nextState[user.userId] = user;
      });
      return nextState;
    case PHONE_UPDATE_SUCCESS:
      // TODO: check solution one store for user, right now solving:
      // case when the phone is updated from user settings as sms token
      if (action.payload && action.payload.userId) {
        return {
          ...state,
          [action.payload.userId]: {
            ...state[action.payload.userId],
            ...action.payload,
          },
        };
      }
      return state;
    default:
      return state;
  }
};

/**
 * Users Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.USER_FETCH_SUCCESS:
      if (state.indexOf(action.payload.userId) !== -1) {
        return state;
      }
      return [...state, action.payload.userId];
    case types.USERS_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(user => {
        if (state.indexOf(user.userId) === -1) {
          nextState.push(user.userId);
        }
      });
      return nextState;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null, filter: string, matchClientId: null}}
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
  filter: '',
  invalidatePassword: false,
};

export const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.USERS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case types.REMOVE_FILTER:
      return {
        ...state,
        filter: '',
      };
    case fromModals.SHOW_MODAL:
      return {
        ...state,
        invalidatePassword: false,
      };
    case types.TOGGLE_PASSWORD_INVALIDATION:
      return {
        ...state,
        invalidatePassword: !state.invalidatePassword,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  ui,
});
