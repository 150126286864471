import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, omit, isNumber } from 'lodash';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import cx from 'classnames';
import i18n from '~/i18n';
import { Field, Formik, FieldArray } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { ContextEffect } from '~/common';
import { getIcon } from '~/common/modules/rules/utils';
import { RulesTypes } from '~/common/modules/rules/types';
import { Table, Cell } from '~/components/src/Table/components';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import { canBeInverted, hasTimeCondition, supportsAddingProperties } from '~/common/modules/rules/selectors';
import CardMessage from '~/components/src/CardMessage';
import BtnOutlined from '~/components/src/BtnOutlined';
import Icons from '~/components/src/Icons';
import BtnIcon from '~/components/src/BtnIcon';
import PropertyNameSelect from './Property';
import Constraint from './Constraint';
import Filter from './Filter';
import { parseTimeCondition, canAddPropertyRow, getSelectedProperty, shouldRenderUniqueConstraint } from './utils';
import { getPropertySelectOptions } from './Property/utils';
import UniqueValues from './UniqueValues';
import StoredVariablesInput from './StoredVariablesInput';
import { CONSTRAINT_MATCH_TYPE } from './constants';
import './styles.scss';

const TheadCell = ({ children, className }) => (
  <div className={`${className} RuleCardEdit-table-head Table-cell Table-cell--header`}>{children}</div>
);

const handleAddPropertyAction = (newProperty, formProps, disabled) => {
  if (!disabled) {
    const newProperties = [{ ...newProperty, key: Date.now() }];
    if (formProps.values.filters) {
      formProps.setFieldValue('filters', [...formProps.values.filters, ...newProperties]);
    } else {
      formProps.setFieldValue('filters', [...newProperties]);
    }
    formProps.setFieldValue('hasNewProperty', true);
  }
};

const AddPropertyBtn = ({ formProps, newProperty, disabled, ts }) => (
  <BtnOutlined
    className="my-2 ml-4 mt-4"
    onClick={() => handleAddPropertyAction(newProperty, formProps, disabled)}
    icon="add"
    testHook="ruleCardAddPropertyBtn"
    disabled={disabled}
    size="xs"
    color="gray"
  >
    {ts.addProperty}
  </BtnOutlined>
);

const DeleteProperty = ({ isMandatoryProperty, arrayHelpers, propertyIndex }) => {
  if (isMandatoryProperty) {
    return null;
  }

  return (
    <BtnIcon
      testHook="RuleCardEdit-DeleteProperty"
      className="h-4 w-4 p-0.5"
      icon="remove"
      tooltip={i18n.t('common:actions.remove')}
      onClick={() => {
        arrayHelpers.remove(propertyIndex);
      }}
    />
  );
};

const Properties = ({
  formProps,
  arrayHelpers,
  propertyTypes,
  type,
  availableVariables,
  fetchAllVariables,
  ts,
  isJourneyCondition = false,
  hasDateRuleConstraint,
  shouldShowDeleteProperty = true, // This property shows or hides the delete property button.
  shouldShowUniqueValuesCell = true, // This property shows or hides the unique values column. This is needed as in some cases the cell should appear but not the contents
}) => {
  const propertySelectOptions = getPropertySelectOptions(propertyTypes, formProps.values.filters);
  const canCreateCustomProperty = propertyTypes.some(propertyType => propertyType.dataField === null);
  return (
    <div className="RuleCardEdit-propertyRow">
      {formProps?.values?.filters?.map((property, propertyIndex) => {
        const selectedPropertyName = property.dataField;
        const selectedProperty = getSelectedProperty(selectedPropertyName, propertyTypes);

        const isMandatoryProperty = get(selectedProperty, 'mandatory', false);

        const hasPropertyError = property?.constraint?.group?.name === 'DATE' && hasDateRuleConstraint;

        return (
          <div
            key={property.key ? property.key : property.dataField}
            className={cx('Table-row RuleCardEdit-table-row t-ruleCardPropertyRow !items-baseline', {
              'RuleCardEdit-table-row--error': hasPropertyError,
            })}
          >
            <Cell className="RuleCardEdit-propertyNameCell" testHook="propertyNameSelectContainer">
              <PropertyNameSelect
                propertyIndex={propertyIndex}
                selectedPropertyName={selectedPropertyName}
                allSelectedProperties={formProps.values.filters}
                options={propertySelectOptions}
                setFieldValue={formProps.setFieldValue}
                isCreatable={canCreateCustomProperty}
                isClearable={!isMandatoryProperty}
                ts={ts}
                hasNewProperty={formProps.values.hasNewProperty}
              />
            </Cell>
            <Cell className="RuleCardEdit-constraintCell" testHook="constraintSelectContainer">
              <Constraint
                propertyIndex={propertyIndex}
                selectedPropertyName={selectedPropertyName}
                selectedConstraint={property.constraint}
                propertyTypes={propertyTypes}
                setFieldValue={formProps.setFieldValue}
                hasNewProperty={formProps.values.hasNewProperty}
              />
            </Cell>
            <Cell className="RuleCardEdit-filterCell" testHook="filterValueInputContainer">
              <Filter
                selectedConstraint={property.constraint}
                propertyIndex={propertyIndex}
                setFieldValue={formProps.setFieldValue}
                setFieldError={formProps.setFieldError}
                setFieldTouched={formProps.setFieldTouched}
                selectedProperty={selectedProperty}
                selectedPropertyName={selectedPropertyName}
                validateForm={formProps.validateForm}
                propertyTypes={propertyTypes}
                isMandatoryProperty={isMandatoryProperty}
                placeholder={i18n.t('common:controls.addValue')}
                hasNewProperty={formProps.values.hasNewProperty}
              />
            </Cell>
            {shouldShowUniqueValuesCell && (
              <Cell className="RuleCardEdit-uniqueValuesCell">
                {property.constraint?.matchType.name !== CONSTRAINT_MATCH_TYPE.EQUALS &&
                  shouldRenderUniqueConstraint(property.constraint, type) && (
                    <UniqueValues
                      propertyIndex={propertyIndex}
                      formProps={formProps}
                      placeholder={i18n.t('common:controls.addValue')}
                    />
                  )}
              </Cell>
            )}
            {!isJourneyCondition && (
              <Cell className="RuleCardEdit-variablesCell">
                <StoredVariablesInput
                  selectedPropertyName={selectedPropertyName}
                  selectedVariable={property.variable}
                  propertyIndex={propertyIndex}
                  setFieldValue={formProps.setFieldValue}
                  availableVariables={availableVariables}
                  fetchAllVariables={fetchAllVariables}
                />
              </Cell>
            )}
            {shouldShowDeleteProperty && (
              <Cell className={cx('RuleCardEdit-deletePropertyContainer', 't-deletePropertyContainer')}>
                <DeleteProperty
                  isMandatoryProperty={isMandatoryProperty}
                  arrayHelpers={arrayHelpers}
                  propertyIndex={propertyIndex}
                />
              </Cell>
            )}
          </div>
        );
      })}
    </div>
  );
};

const validateField = (value, timeUnit) => {
  let error;
  if (!isNumber(value)) {
    error = i18n.t('common:errors.required');
  } else if (value <= 0) {
    error = i18n.t('common:errors.positiveNumber');
  } else if (
    (timeUnit === 'm' && value > 8409600) ||
    (timeUnit === 'h' && value > 140160) ||
    (timeUnit === 'd' && value > 5840) ||
    (timeUnit === 'w' && value > 834.29)
  ) {
    error = i18n.t('audiences:rules.errors.FixedTimeRule');
  }
  return error;
};

export const checkDuplicateEventWithDateProperties = (isAudienceRule, criteria, type, title, filters) => {
  if (isAudienceRule) {
    const flatCriterias = criteria.flat();

    // group criterias by rule type
    const groupedEvents = _.groupBy(flatCriterias, 'type');

    // find all rules configured with this event type
    const eventsArray = groupedEvents[type];

    // group all duplicate rules configured with same event type
    const groupedRules = _.groupBy(eventsArray, 'title');

    // find all rules configured with this event type and this title
    const rulesArray = groupedRules[title];

    if (rulesArray?.length > 1) {
      const filteredArray = rulesArray?.filter(
        rule => !rule?.negation && rule?.filters?.find(filter => filter?.filterValue?.clazz === 'DateFilterValue'),
      );
      if (filteredArray.length > 1) {
        let fis = filteredArray?.map(rule => rule.filters);
        fis = fis?.flat();
        const dateFieldArray = _.groupBy(fis, 'dataField');

        const filterdDateFields = Object.keys(dateFieldArray).filter(
          dataField => dateFieldArray[dataField]?.length > 1,
        );

        const hasDateConstraint = filters?.some(filter => filterdDateFields?.includes(filter.dataField));
        return hasDateConstraint;
      }
      return false;
    }
    return false;
  }
  return false;
};

const RuleCardEditContent = ({
  onDelete,
  title,
  type,
  typeLabel,
  testHook = 'ruleCardEdit',
  ts,
  t,
  timeSettings,
  availableVariables,
  newProperty = {},
  formProps = {
    values: {},
    errors: {},
  },
  propertyTypes,
  id,
  fetchAllVariables,
  isJourneyCondition,
  filters,
  isAudienceRule,
  criteria,
}) => {
  const icon = getIcon(type);

  const hasDateRuleConstraint =
    !!isAudienceRule && filters?.length > 1 && filters?.some(filter => filter?.constraint?.group?.name === 'DATE');

  const hasDuplicateRuleWithDateConstraint =
    isAudienceRule && checkDuplicateEventWithDateProperties(isAudienceRule, criteria, type, title, filters);

  // This logic is refactored from If else to switch to better support multiple rule types
  const renderCriteria = type => {
    switch (type) {
      case RulesTypes.FixedTime:
        return (
          <div className="RuleCardEdit-waitTimeSettings">
            <Field
              as={FormikInputField}
              className="RuleCardEdit-waitTimeParameter"
              name="waitTimeValue"
              type="number"
              errorText={formProps.errors.waitTimeValue}
              validate={value => validateField(value, formProps.values.waitTimeUnit)}
              min={1}
            />
            <SelectElement
              options={[
                { value: 'm', label: ts.minutes },
                { value: 'h', label: ts.hours },
                { value: 'd', label: ts.days },
                { value: 'w', label: ts.weeks },
              ]}
              value={formProps.values.waitTimeUnit}
              onChange={option => {
                formProps.setFieldValue('waitTimeUnit', option.value);
                formProps.setFieldError('waitTimeValue', validateField(formProps.values.waitTimeValue, option.value));
              }}
              testHook="waitTimeUnit"
            />
          </div>
        );

      case RulesTypes.DynamicWaitTime:
        return (
          <FieldArray
            name="filters"
            render={arrayHelpers => (
              <div className="RuleCardEdit-properties">
                <Table stateKey="testKey" className="RuleCardEdit-table" testHook="ruleCardPropertiesTable">
                  {formProps?.values?.filters?.length > 0 && (
                    <div className="Table-row RuleCardEdit-tableHeadRow">
                      <TheadCell className="RuleCardEdit-propertyNameCell">{ts.variables}</TheadCell>
                      <TheadCell className="RuleCardEdit-constraintCell">{ts.constraint}</TheadCell>
                      <TheadCell className="RuleCardEdit-filterCell">{ts.filter}</TheadCell>
                    </div>
                  )}
                  <Properties
                    formProps={formProps}
                    arrayHelpers={arrayHelpers}
                    propertyTypes={propertyTypes}
                    availableVariables={availableVariables}
                    fetchAllVariables={fetchAllVariables}
                    type={type}
                    ts={ts}
                    isJourneyCondition={true}
                    hasDateRuleConstraint={false}
                    shouldShowDeleteProperty={false}
                    shouldShowUniqueValuesCell={false}
                  />
                </Table>
              </div>
            )}
          />
        );
      default:
        return (
          <FieldArray
            name="filters"
            render={arrayHelpers => (
              <div className="RuleCardEdit-properties">
                <Table stateKey="testKey" className="RuleCardEdit-table" testHook="ruleCardPropertiesTable">
                  {formProps?.values?.filters?.length > 0 && (
                    <div className="Table-row RuleCardEdit-tableHeadRow">
                      <TheadCell className="RuleCardEdit-propertyNameCell">{ts.property}</TheadCell>
                      <TheadCell className="RuleCardEdit-constraintCell">{ts.constraint}</TheadCell>
                      <TheadCell className="RuleCardEdit-filterCell">{ts.filter}</TheadCell>
                      <TheadCell className="RuleCardEdit-uniqueValuesCell">
                        {ts.unique}
                        <Icons icon="help" className="h-6 w-6 p-1 text-gray-400" tooltip={ts.uniqueValuesTooltip} />
                      </TheadCell>
                      {!isJourneyCondition && (
                        <TheadCell className="RuleCardEdit-variablesCell">
                          {ts.storedVariable}
                          <Icons icon="help" className="h-6 w-6 p-1 text-gray-400" tooltip={ts.storedVariableTooltip} />
                        </TheadCell>
                      )}
                      <TheadCell className="RuleCardEdit-deletePropertyContainer" />
                    </div>
                  )}
                  <Properties
                    formProps={formProps}
                    arrayHelpers={arrayHelpers}
                    propertyTypes={propertyTypes}
                    availableVariables={availableVariables}
                    fetchAllVariables={fetchAllVariables}
                    type={type}
                    ts={ts}
                    isJourneyCondition={isJourneyCondition}
                    hasDateRuleConstraint={hasDateRuleConstraint || hasDuplicateRuleWithDateConstraint}
                  />
                  {hasDateRuleConstraint && <CardMessage message={i18n.t('audiences:edit.dateRuleConstraint')} />}
                  {hasDuplicateRuleWithDateConstraint && (
                    <CardMessage message={i18n.t('audiences:duplicateDateRuleError')} />
                  )}
                  {supportsAddingProperties(type) && (
                    <AddPropertyBtn
                      formProps={formProps}
                      newProperty={newProperty}
                      disabled={!canAddPropertyRow(propertyTypes, formProps.values.filters)}
                      ts={ts}
                    />
                  )}
                </Table>
              </div>
            )}
          />
        );
    }
  };

  return (
    <div
      className={cx(
        't-ruleCardEdit mb-6 flex w-full rounded-md border border-gray-200 bg-white p-2',
        `t-${type}`,
        `t-${testHook}`,
        {
          'border-red-300': formProps.values.isInversed,
        },
      )}
    >
      <div className="t-ruleCardEditInfo w-full">
        <div className="flex items-center gap-2">
          {icon}
          <div className="flex-1">
            {title && (
              <span className="t-ruleCardEditTitle flex items-center gap-1 py-0.5 text-xl font-medium text-gray-800">
                {title}
                <Icons
                  icon="info"
                  className="h-6 w-5 p-0.5 text-gray-400"
                  tooltip={t(`audiences:rules.descriptions.${type}`)}
                />
              </span>
            )}
            {typeLabel && <p className="t-ruleCardEditDescription text-sm text-gray-400">{typeLabel}</p>}
          </div>
          <div className="RuleCardEdit-actions flex items-center gap-2">
            {hasTimeCondition(type) && timeSettings && (
              <div className="flex items-center">
                <div className="mt-1 flex items-center rounded border border-gray-300 pl-2 hover:border-gray-400">
                  <Icons icon="schedule" className="h-7 w-7 text-gray-500" />
                  <Field
                    as={FormikInputField}
                    className="RuleCardEdit-timeParameter !h-10 w-20 !rounded"
                    name="timeValue"
                    type="number"
                    errorText={formProps.errors.timeValue}
                    validate={value => validateField(value, formProps.values.timeUnit)}
                    min={1}
                  />
                  <SelectElement
                    options={[
                      { value: 'm', label: ts.minutes },
                      { value: 'h', label: ts.hours },
                      { value: 'd', label: ts.days },
                      { value: 'w', label: ts.weeks },
                    ]}
                    classNamePrefix="timeUnit"
                    className="mb-1 !h-10 w-36 !rounded"
                    value={formProps.values.timeUnit}
                    onChange={option => {
                      formProps.setFieldValue('timeUnit', option.value);
                      formProps.setFieldError('timeValue', validateField(formProps.values.timeValue, option.value));
                    }}
                  />
                </div>
              </div>
            )}
            {canBeInverted(type) && (
              <BtnIcon
                id={`${id}_isInversed`}
                name="isInversed"
                icon="inverse"
                color={formProps.values.isInversed ? 'red' : 'gray'}
                tooltip={t(formProps.values.isInversed ? 'actions.removeInverse' : 'actions.inverse')}
                onClick={() => formProps.setFieldValue('isInversed', !formProps.values.isInversed)}
                testHook="isInversedButton"
                className={formProps.values.isInversed ? 't-isInversed' : 't-isNotInversed'}
              />
            )}
            <BtnIcon
              icon="delete"
              onClick={onDelete}
              tooltip={i18n.t('common:actions.delete')}
              className="RuleCardEdit-deleteRule"
            />
          </div>
        </div>

        {renderCriteria(type)}
      </div>
    </div>
  );
};

RuleCardEditContent.defaultProps = {
  ts: {
    days: 'Days',
    weeks: 'Weeks',
    hours: 'Hours',
    minutes: 'Minutes',
    property: 'Property',
    variables: 'Variables',
    constraint: 'Constraint',
    filter: 'Filter Value',
    unique: 'Unique Values',
    storedVariable: 'Store in variables',
    inversed: 'NOT',
    addProperty: 'Add a property',
    storedVariableTooltip:
      "The interactions in this trigger can contain data such as the ID of a viewed product. If you want to use this data, you can link it to a variable by filling the 'Store in a variable' field. Leave the field blank if you do not want to use this data. Note that if you choose to use this data, it will be sent to all connectors configured in the following step",
    uniqueValuesTooltip: 'Set a restriction on the minimal amount of unique values which match this filter',
    startTyping: 'Start typing',
    selectProperty: 'Select',
    noOptionMessage: 'No result, it does not support custom values',
  },
  timeSettings: true,
};

RuleCardEditContent.propTypes = {
  name: PropTypes.string,
  typeLabel: PropTypes.string,
  testHook: PropTypes.string,
  formProps: PropTypes.object,
  timeSettings: PropTypes.bool,
  filters: PropTypes.array,
  ts: PropTypes.shape({
    days: PropTypes.string.isRequired,
    weeks: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
  }),
};

const transformTime = (timeValue, timeUnit) => {
  if (!timeValue || !timeUnit) {
    return null;
  }
  return `${timeValue}${timeUnit}`;
};

// Pre-save action for ContextEffect
const formTransformations = (formProps, id) => () => {
  const { timeValue, timeUnit, waitTimeValue, waitTimeUnit, isInversed, filters } = formProps.values;
  const transformation = { negation: isInversed, ruleId: id, filters };
  return {
    ...transformation,
    waitTime: transformTime(waitTimeValue, waitTimeUnit),
    timeCondition: transformTime(timeValue, timeUnit),
  };
};

const RuleCardEdit = props => {
  const cardProps = omit(props, ['initialValues', 'onChange']);
  const {
    onChange,
    timeCondition,
    waitTime,
    isInversed,
    id,
    filters,
    isSubmitted,
    isJourneyCondition,
    bindFormikForm,
  } = props;
  const [timeValue, timeUnit] = parseTimeCondition(timeCondition);
  const [waitTimeValue, waitTimeUnit] = parseTimeCondition(waitTime);

  const initialValues = {
    timeUnit,
    timeValue,
    waitTimeValue,
    waitTimeUnit,
    isInversed,
    filters,
  };

  return (
    <Formik initialValues={initialValues} validateOnChange={false}>
      {formProps => {
        bindFormikForm?.(formProps);
        return (
          <Fragment>
            <ContextEffect
              onChange={onChange}
              formTransformations={formTransformations(formProps, id)}
              isSubmitted={isSubmitted}
              submitCount={formProps.submitCount}
              setTouched={formProps.setTouched}
              errors={formProps.errors}
              validateOnBlur={true}
              enableReinitialize={true}
            />
            <RuleCardEditContent formProps={formProps} {...cardProps} isJourneyCondition={isJourneyCondition} />
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default compose(translate('common'))(RuleCardEdit);
