import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Customer Facts Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.customerFactId]: action.payload,
      };
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
      nextState = {};
      action.payload.forEach(customerFact => {
        nextState[customerFact.customerFactId] = customerFact;
      });
      return nextState;
    case types.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * Customer facts Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  let nextState;
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_SAVE_SUCCESS:
      if (state.indexOf(action.payload.customerFactId) !== -1) {
        return state;
      }
      return [...state, action.payload.customerFactId];
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
      return action.payload.map(customerFact => customerFact.customerFactId);
    case types.CUSTOMER_FACTS_DELETE_SUCCESS:
      nextState = [...state];
      nextState.splice(
        state.findIndex(item => item === action.payload),
        1,
      );
      return nextState;
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingCustomerFact: false,
  isFetchingCustomerFacts: false,
  dependantsContainerIsVisible: false,
  selectedId: null,
  selectedTab: 0,
  userIsEditing: false,
  error: null,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.CUSTOMER_FACT_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFact: true,
      };
    case types.CUSTOMER_FACT_FETCH_SUCCESS:
    case types.CUSTOMER_FACT_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFact: false,
      };
    case types.CUSTOMER_FACTS_FETCH_START:
      return {
        ...state,
        isFetchingCustomerFacts: true,
      };
    case types.CUSTOMER_FACTS_FETCH_SUCCESS:
    case types.CUSTOMER_FACTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingCustomerFacts: false,
      };
    case types.SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedId: action.payload.propertyId || action.payload.customerFactId,
        selectedTab: action.payload.selectedTab,
      };
    case types.HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedId: null,
        selectedTab: 0,
      };
    case types.DEPENDANTS_TYPE_TAB_CHANGE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case types.SET_USER_IS_EDITING:
      return {
        ...state,
        userIsEditing: action.payload,
      };
    default:
      return state;
  }
};

const customerFactsReducer = combineReducers({
  byId,
  allIds,
  ui,
});

export default customerFactsReducer;
