angular.module('webUi.ui.tagmanagement.cookiePermission.variantselect', [
    'webUi.ui.tagmanagement.cookiePermission.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.cookiePermission.variantselect', {
                url : '/variantselect',
                views : {
                    '@site.tagmanagement' : {
                        templateUrl : 'ui/tagmanagement/cookiePermission/variantselect/variantselect.tpl.html',
                        controller : 'CookiePermissionVariantSelectController as vm'
                    }
                },
                leftMenu : false,
                resolve : {
                    cookiePermissionVariants : ['CookiePermissionService', function(CookiePermissionService) {
                        return CookiePermissionService.getAvailableCookiePermissionVariants();
                    }]
                }
            });
    }])

    .controller('CookiePermissionVariantSelectController', ['$state', '$scope', 'cookiePermissionVariants','ValidService',
        function CookiePermissionVariantSelectController($state, $scope, cookiePermissionVariants, ValidService){

            var vm = this;
            // expose the available form variants in scope
            vm.cookiePermissionVariants = cookiePermissionVariants;

            vm.variantSelected = function() {
                return !ValidService.isEmpty(vm.selectedId);
            };

            // set the selected form variant
            vm.setSelected = function(id){
                vm.selectedId = id;
            };

            /**
     * Button function
     * @param selectedId
     */
            vm.goToCookiePermissionForm = function(selectedId) {
                $state.transitionToStateWithSite('site.tagmanagement.cookiePermission.form', {
                    variantId: selectedId
                });
            };

            /**
    * Button Cancel
    */
            vm.goToCookiePermissionList = function() {
                $state.transitionToStateWithSite('site.tagmanagement.cookiePermission.list');
            };

        }]);
