import { showSuccess, showWarning } from '~/notificationCenter';
import i18n from '~/i18n';
import { getAudienceOrGroup } from '../selectors';
import * as types from '../types';
import * as AudienceService from '../dataService.js';

export const searchAudiences = payload => ({
  type: types.SEARCH_AUDIENCES,
  payload,
});

export const fetchAudiencesStart = () => ({
  type: types.AUDIENCES_FETCH_START,
});

export const setAudiences = payload => ({
  type: types.AUDIENCES_FETCH_SUCCESS,
  payload,
});

export const setAudiencesError = error => ({
  type: types.AUDIENCES_FETCH_FAIL,
  error,
});

/**
 * Fetch audiences for given group or for root level
 * @param {*} page
 * @param {*} parentId
 * @param {*} query
 */
export const fetchAudiences = (page, parentId, query) => dispatch => {
  dispatch(fetchAudiencesStart());
  return AudienceService.getAudiencesPaginated(page, parentId, query)
    .then(payload => {
      dispatch(setAudiences(payload));
    })
    .catch(error => dispatch(setAudiencesError(error)));
};

export const fetchAudienceBreadcrumbs = () => dispatch => {
  dispatch({
    type: types.AUDIENCE_BREADCRUMBS_FETCH_START,
  });
  return AudienceService.getAudienceTree()
    .then(payload => {
      dispatch({
        type: types.AUDIENCE_BREADCRUMBS_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error =>
      dispatch({
        type: types.AUDIENCE_BREADCRUMBS_FETCH_FAIL,
        error,
      }),
    );
};

/**
 * Delete passed audience
 * @param {*} item
 */
export const deleteAudience = item => dispatch => {
  dispatch({
    type: types.AUDIENCE_DELETE_START,
  });

  return AudienceService.canRemoveAudience(item.id)
    .then(result => {
      if (result.canDelete) {
        return AudienceService.removeAudience(item.id);
      }
      showWarning({ body: i18n.t('audiences:list.alerts.cannotRemoveUsed', { itemName: item.name }) });
      return Promise.reject();
    })
    .then(() => {
      showSuccess({ body: i18n.t('audiences:api.deletionSuccess') });
      dispatch({
        type: types.AUDIENCE_DELETE_SUCCESS,
        payload: {
          id: item.id,
          parentId: item.parentId,
        },
      });
    })
    .catch(error => dispatch({ type: types.AUDIENCE_DELETE_FAIL, error }));
};

/**
 * Fetch single Audience or Group
 * @param {UUID} id
 */
export const fetchAudienceOrGroup = id => dispatch => {
  if (!id) return Promise.resolve();

  dispatch({
    type: types.AUDIENCE_FETCH_START,
    payload: {
      id,
    },
  });

  return AudienceService.getAudienceOrGroup(id)
    .then(payload => {
      dispatch({
        type: types.AUDIENCE_FETCH_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.AUDIENCE_FETCH_FAIL, error }));
};

/**
 * Fetch Audience or Group only if not alreday in Store
 * @param {UUID} id
 */
export const fetchAudienceOrGroupIfNeeded = id => (dispatch, getState) => {
  if (getAudienceOrGroup(getState(), id)) {
    return Promise.resolve();
  }
  return dispatch(fetchAudienceOrGroup(id));
};

export const fetchDependantsInfo = elementId => dispatch => {
  dispatch({
    type: types.DEPENDANTS_INFO_FETCH_START,
  });

  return AudienceService.fetchAudienceDependantsInfo(elementId)
    .then(dependantsInfo => {
      dispatch({
        type: types.DEPENDANTS_INFO_FETCH_SUCCESS,
        payload: { id: elementId, dependantsInfo },
      });
    })
    .catch(error => dispatch({ type: types.DEPENDANTS_INFO_FETCH_FAIL, error }));
};

export const showDependantsContainer = audienceId => dispatch => {
  dispatch(fetchDependantsInfo(audienceId));
  dispatch({
    type: types.SHOW_DEPENDANTS_CONTAINER,
    payload: {
      audienceId,
    },
  });
};

export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.HIDE_DEPENDANTS_CONTAINER,
  });
};
