import React, { useState } from 'react';
import { Moment } from 'moment';
import { Chart, Empty } from '~/components';
import Spin from '~/components/src/Spin';
import { useApiWithState } from '~/common';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import { getTagCounterStats } from '~/tagManagement/tagCounter/dataService';
import { Panel } from '~/components/src/Containers';
import ActivityHeader from './ActivityHeader';
import { CounterStatsData, CounterTableStats } from '../types';
import { getCounterStats } from '../dataService';
import { defaultDate } from '../constants';

const columnHelper = createColumnHelper<CounterTableStats>();

type TCountersProps = {
  counterIds?: string;
  withBulkIds?: boolean;
};

// This component used in tags/stats & tags/counters
const Counters = ({ counterIds, withBulkIds = false }: TCountersProps): React.ReactElement => {
  const [filter, setFilter] = useState<Record<string, Moment>>({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const { startDate, endDate } = filter;

  const { state: counterStats, isLoading } = useApiWithState<CounterStatsData>(
    () =>
      withBulkIds
        ? getTagCounterStats(
            startDate.startOf('day').toDate().getTime(),
            endDate.endOf('day').toDate().getTime(),
            counterIds,
          )
        : getCounterStats(startDate.startOf('day').toDate().getTime(), endDate.endOf('day').toDate().getTime()),
    [startDate, endDate],
  );

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <div className="text-left">{i18n.t('stats:counters.table.headers.counterName')}</div>,
      cell: info => <div className="text-left">{info.getValue()}</div>,
    }),
    columnHelper.accessor('totalStats', {
      header: () => <div className="text-center">{i18n.t('stats:counters.table.headers.hits')}</div>,
      cell: info => <div className="text-center">{info.getValue()}</div>,
    }),
  ];

  const { tableStats = [], series = [], timestamp = [] } = counterStats || {};

  if (isLoading && !counterStats) {
    return <Spin />;
  }
  if (tableStats.length === 0) {
    return <Empty header={i18n.t('stats:counters.notConfigured')} />;
  }
  return (
    <Panel>
      <ActivityHeader
        startDate={startDate}
        endDate={endDate}
        setFilter={setFilter}
        title={i18n.t('stats:counters.title')}
        tooltip={i18n.t('stats:counters.tooltip')}
      />

      <Chart
        chartType="column"
        series={series}
        xAxisLabels={timestamp}
        xAxisTitle={i18n.t('stats:chart.xAxisTitle')}
        yAxisTitle={i18n.t('stats:counters.yAxisTitle')}
        noDataMessage={i18n.t('stats:counters.noData')}
        height={290}
      />

      <div className="mt-7">
        {tableStats.length > 0 && (
          <UITable data={tableStats} columns={getColumns()} enableSorting testHook="counterTable" />
        )}
      </div>
    </Panel>
  );
};

export default Counters;
