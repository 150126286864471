import { showError, hideNotification, showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import { changeUrl } from '~/common/history';
import ConnectorsService from '~/profiles/connectors/dataService';
import { getConnectorListPath } from '~/profiles/connectors/containers/commonActions';
import * as types from './types';
import * as selectors from './selectors';
import ProfileConnectorsService from './dataService';
import { fetchDependantsConnectorInfo } from '../dependencies/actions';

const DELETE_NOTIFICATION_ID = 'CONNECTOR_USED_TOGGLE_ID';
const TOGGLE_NOTIFICATION_ID = 'CONNECTOR_USED_DELETE_ID';

/**
 * Fetch all connectors
 */
export const fetchConnectors =
  (connectorCategory = types.SERVER_CONNECTOR_CATEGORY) =>
  (dispatch, getState) => {
    if (selectors.isFetchingConnectors(getState(), connectorCategory)) {
      return Promise.resolve();
    }

    let fetchStartActionType = types.SERVER_CONNECTORS_FETCH_START;
    let fetchSuccessActionType = types.SERVER_CONNECTORS_FETCH_SUCCESS;
    let fetchFailActionType = types.SERVER_CONNECTORS_FETCH_FAIL;

    if (connectorCategory === types.TAG_CONNECTOR_CATEGORY) {
      fetchStartActionType = types.TAG_CONNECTORS_FETCH_START;
      fetchSuccessActionType = types.TAG_CONNECTORS_FETCH_SUCCESS;
      fetchFailActionType = types.TAG_CONNECTORS_FETCH_FAIL;
    }

    dispatch({
      type: fetchStartActionType,
    });

    return ProfileConnectorsService.getAllConnectors(connectorCategory)
      .then(connectors => {
        dispatch({
          type: fetchSuccessActionType,
          payload: connectors,
        });
      })
      .catch(error => dispatch({ type: fetchFailActionType, error }));
  };

export const fetchConnectorTypes = type => async dispatch => {
  dispatch({ type: types.CONNECTOR_TYPES_FETCH_START });

  let connectorTypes;
  const isEventConnector = type && type === 'events';
  try {
    connectorTypes = await ProfileConnectorsService.getConnectorTypes(
      isEventConnector ? types.EVENT_CONNECTOR_CATEGORY : types.SERVER_CONNECTOR_CATEGORY,
    );
  } catch {
    return dispatch({ type: types.CONNECTOR_TYPES_FETCH_FAIL });
  }

  const isBeta = localStorage.getItem('useBeta') === 'true';
  if (!isBeta) {
    connectorTypes = connectorTypes?.filter(type => type?.partnerType !== 'DIY');
  }

  return dispatch({
    type: types.CONNECTOR_TYPES_FETCH_SUCCESS,
    payload: connectorTypes,
  });
};

export const fetchEventConnectors = () => async dispatch => {
  dispatch({ type: types.EVENT_CONNECTORS_FETCH_START });

  let eventConnectors;
  try {
    eventConnectors = await ProfileConnectorsService.getEventConnectors();
  } catch {
    return dispatch({ type: types.EVENT_CONNECTORS_FETCH_FAIL });
  }

  return dispatch({
    type: types.EVENT_CONNECTORS_FETCH_SUCCESS,
    payload: eventConnectors,
  });
};

/**
 * Fetch connectors only if not fetched yet
 */
export const fetchConnectorsIfNeeded = connectorCategory => (dispatch, getState) => {
  if (selectors.shouldFetchConnectors(getState(), connectorCategory)) {
    return dispatch(fetchConnectors(connectorCategory));
  }
  return Promise.resolve();
};

/**
 * Activate / Deactivate connector
 * @param connector
 */
export const toggleActivation = connector => dispatch => {
  const payload = {
    partnerId: connector.partnerId,
    activated: !connector.activated,
  };

  dispatch({
    type: types.TOGGLE_ACTIVATION_START,
  });

  hideNotification(TOGGLE_NOTIFICATION_ID);

  return ProfileConnectorsService.toggleActivation(payload)
    .then(success => {
      if (success) {
        return dispatch({
          type: types.TOGGLE_ACTIVATION_SUCCESS,
          payload,
        });
      }
      throw new Error(TOGGLE_NOTIFICATION_ID);
    })
    .catch(error => {
      if (error && error.message === TOGGLE_NOTIFICATION_ID) {
        showError({
          body: i18n.t('partners:errors.deactivateUsed', { partnerName: connector.partnerName }),
          toastId: TOGGLE_NOTIFICATION_ID,
        });
      }
      dispatch({ type: types.TOGGLE_ACTIVATION_FAIL, payload: connector });
    });
};

export const toggleEventConnectorActivation = (eventConnectorId, activated, partnerName) => async dispatch => {
  try {
    await ConnectorsService.toggleEventConnectorActivation(eventConnectorId, activated);
  } catch {
    showError({
      body: i18n.t('partners:errors.deactivateUsed', { partnerName }),
    });
  }

  dispatch({ type: types.TOGGLE_EVENT_ACTIVATION_SUCCESS, payload: { eventConnectorId, activated: !activated } });
};

const handleDeleteSuccess = (dispatch, connector, goToList) => {
  if (goToList) {
    const redirectPath = getConnectorListPath(
      connector.eventConnectorId ? types.EVENT_CONNECTOR_CATEGORY : types.SERVER_CONNECTOR_CATEGORY,
    );

    changeUrl(redirectPath);
  }

  return dispatch({
    type: types.DELETE_SUCCESS,
    payload: connector,
  });
};

/**
 * Delete Connector
 * @param connector
 */
export const deleteConnector = (connector, goToList) => dispatch => {
  dispatch({
    type: types.DELETE_START,
  });
  hideNotification(DELETE_NOTIFICATION_ID);

  const deleteRequest = connector.eventConnectorId
    ? () => ProfileConnectorsService.deleteEventConnector(connector.eventConnectorId)
    : () => ProfileConnectorsService.deleteConnector(connector.partnerId);

  return deleteRequest()
    .then(success => {
      if (success) {
        showSuccess({
          body: i18n.t('partners:api.deletionSuccess'),
        });
        return handleDeleteSuccess(dispatch, connector, goToList);
      }
      throw new Error(DELETE_NOTIFICATION_ID);
    })
    .catch(error => {
      if (error && error.message === DELETE_NOTIFICATION_ID) {
        showError({
          body: i18n.t('partners:errors.deleteUsed', { partnerName: connector.partnerName }),
          toastId: DELETE_NOTIFICATION_ID,
        });
      }
      dispatch({ type: types.DELETE_FAIL, payload: connector });
    });
};

/**
 * Show the container for the usage overview of the engagement/property
 */
export const showDependantsContainer = (connectorId, selectedTab) => dispatch => {
  dispatch(fetchDependantsConnectorInfo(connectorId));

  dispatch({
    type: types.SHOW_DEPENDANTS_CONTAINER,
    payload: {
      connectorId,
      selectedTab,
    },
  });
};

/**
 * Hide the container for the usage overview of the engagement
 */
export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.HIDE_DEPENDANTS_CONTAINER,
  });
};

/**
 * Change the tab in the dependants container
 */
export const changeDependantTypesSelectedTab = selectedTab => dispatch => {
  dispatch({
    type: types.DEPENDANTS_TYPE_TAB_CHANGE,
    payload: selectedTab,
  });
};
