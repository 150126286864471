import { setPending, setFulfilled, setRejected } from '~/common/utils/promiseState';
import { clangActionTypes } from './clangFormActions';

const clangReducer = (state, action) => {
  switch (action.name) {
    case clangActionTypes.FETCH_IDENTIFIER_TYPES.fulfilled:
      return state.setIn(['promise', 'fetchingIdentifierTypes'], setFulfilled());
    case clangActionTypes.FETCH_IDENTIFIER_TYPES.pending:
      return state.setIn(['promise', 'fetchingIdentifierTypes'], setPending());
    case clangActionTypes.FETCH_IDENTIFIER_TYPES.rejected:
      return state.setIn(['promise', 'fetchingIdentifierTypes'], setRejected());
    case clangActionTypes.SET_IDENTIFIER_TYPES:
      return state.set('identifierTypes', action.data);

    case clangActionTypes.FETCH_ENDPOINTS.fulfilled:
      return state.setIn(['promise', 'fetchingEndpoints'], setFulfilled());
    case clangActionTypes.FETCH_ENDPOINTS.pending:
      return state.setIn(['promise', 'fetchingEndpoints'], setPending());
    case clangActionTypes.FETCH_ENDPOINTS.rejected:
      return state.setIn(['promise', 'fetchingEndpoints'], setRejected());
    case clangActionTypes.SET_ENDPOINTS:
      return state.set('endpoints', action.data);

    default:
      return state;
  }
};

export default clangReducer;
