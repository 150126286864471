import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Router, Route, Routes, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import SupportPages from '~/support/pages';
import ContextPages from '~/context/pages';
import AdminPages from '~/admin/pages';
import UserPages from '~/user/pages';
import ProfilesPages from '~/profiles/pages';
import ContentPages from '~/content/ContentPages';
import Dashboard from '~/profiles/pages/Dashboard';
import AI from '~/ai';
import DevPage from '~/dev/DevPage';
import ModalRoot from '~/modals/containers/ModalRoot';
import NotificationCenter from '~/notificationCenter/notificationCenter';
import { FallbackComponent } from './configureSentry';
import AudiencesRoot from './profiles/pages/AudiencesRoot';
import TagManagementPages from './tagManagement/TagManagementPages';
import WorkflowPages from './workflows/WorkflowPages';

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);
  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

const OnRouteChange = () => {
  // By default React Router doesn't do this,
  // so if you go from one long page to another long page
  // you will land in the middle of your destination page, not on top
  // https://reactrouter.com/web/guides/scroll-restoration
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollingElement = document.getElementById('main');
    scrollingElement.scrollTo(0, 0);
  }, [pathname]);

  try {
    sessionStorage.setItem('r42WebUi.nextUrl', window.location.href);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Can't save current URL to session storage", error);
  }

  return null;
};

const ReactRoot = ({ store, history, i18n }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <Provider store={store}>
      <CustomRouter history={history}>
        <div>
          <OnRouteChange />
          <I18nextProvider i18n={i18n} initialLanguage="en">
            <>
              <Routes>
                <Route path=":prefix/dashboard" element={<Dashboard />} />
                <Route path=":prefix/profiles/*" element={<ProfilesPages />} />
                <Route path=":prefix/tagmanagement/*" element={<TagManagementPages />} />
                <Route path=":prefix/audiences/*" element={<AudiencesRoot />} />
                <Route path=":prefix/ai/*" element={<AI />} />
                <Route path=":prefix/content/*" element={<ContentPages />} />
                <Route path=":prefix/admin/*" element={<AdminPages />} />
                <Route path=":prefix/support/*" element={<SupportPages />} />
                <Route path=":prefix/context/*" element={<ContextPages />} />
                <Route path=":prefix/user/*" element={<UserPages />} />
                <Route path=":prefix/workflows/*" element={<WorkflowPages />} />
                {process.env.NODE_ENV === 'development' && <Route path="/dev" element={<DevPage />} />}
              </Routes>

              <React.Fragment>
                <NotificationCenter />
                <ModalRoot />
              </React.Fragment>
            </>
          </I18nextProvider>
        </div>
      </CustomRouter>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default ReactRoot;
