angular.module('webUi.ui.shared.spinner', ['ui.bootstrap'])

    .controller('SpinnerDisplayController',['$uibModalInstance','spinnerConfig','$scope',
        function SpinnerDisplayController(   $uibModalInstance , spinnerConfig , $scope) {

            $scope.spinnerConfig = spinnerConfig;

            /* modal actions */
            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
