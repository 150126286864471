import { RulesTypes } from '~/common/modules/rules/types';
import i18n from '~/i18n';
import { EventType, InternalEventPropsType, MappingType, TransformationType } from '../types';
import { EventType as Rule, RuleDefinition } from '../../Filters/types';
import { ADDITIONAL_SOURCES_EVENTS, mappingPropertyTypes, transformationTypes } from './constants';
import { getDependencies } from '../dataService';
import {
  EventDependencies,
  EventProperties,
  MappedValueTransformationOptionType,
  ValueTransformationOptionType,
  ValueTransformationType,
} from '../components/types';

export const removeEmptyValueTransformations = (
  valueTransformations: ValueTransformationType[],
): ValueTransformationType[] => valueTransformations?.filter(({ transformationType }) => !!transformationType) || [];

export const mapValueTransformations = (
  transformationTypes: ValueTransformationOptionType[],
): MappedValueTransformationOptionType[] =>
  transformationTypes
    .flatMap(resp => resp.options)
    .map(({ type, name }) => ({ transformationType: type, title: name }));

export const mapInternalEventProps = (internalProps: InternalEventPropsType[]): InternalEventPropsType[] =>
  internalProps.map((val: InternalEventPropsType) => ({
    ...val,
    value: val.name,
  }));

export const mapEventDependencies = (dependencies: EventDependencies[], ruleName: string): EventProperties[] => {
  const eventDeps = dependencies.find(item => (item.name || item.type) === ruleName);
  if (!eventDeps) return [];
  return eventDeps.properties.map((prop: string) => ({ label: prop, name: prop, value: prop, description: prop }));
};

export const mapTransformationEventsPayload = (events: EventType[]): EventType[] =>
  events.map(event => ({
    ...event,
    mappings: event.mappings.map((mapping: MappingType) => ({
      destinationName: mapping.destinationName,
      required: mapping.required,
      sourceProperty: {
        propertyType: mapping.static
          ? mappingPropertyTypes.STATIC_SOURCE_PROPERTY
          : mappingPropertyTypes.EVENT_SOURCE_PROPERTY,
        [mapping.static ? 'sourceValue' : 'sourceName']: mapping.sourceName,
      },
      transformations: removeEmptyValueTransformations(mapping.transformations).map(valTransforms => {
        if (
          valTransforms.transformationType === transformationTypes.EPOCH_SECONDS_TO_DATE &&
          'offset' in valTransforms
        ) {
          // Do not include empty offset into payload
          const { offset } = valTransforms;
          if (offset === '') {
            delete valTransforms.offset;
          }
        }
        return valTransforms;
      }),
    })),
  }));

export const mapTransformation = (transformation: TransformationType): TransformationType => ({
  ...transformation,
  events: transformation.events.map(event => ({
    ...event,
    mappings: event.mappings.map(mapping => ({
      ...mapping,
      sourceName:
        mapping.sourceProperty.propertyType === mappingPropertyTypes.STATIC_SOURCE_PROPERTY
          ? mapping.sourceProperty.sourceValue || ''
          : mapping.sourceProperty.sourceName || '',
      static: mapping.sourceProperty.propertyType === mappingPropertyTypes.STATIC_SOURCE_PROPERTY,
    })),
  })),
});

export const addMapping = (sourceName = '', destinationName = ''): MappingType => {
  const mappingConfiguration = {
    sourceName,
    destinationName,
    required: false,
    static: false,
    transformations: [],
    sourceProperty: {
      sourceName: '',
      propertyType: mappingPropertyTypes.EVENT_SOURCE_PROPERTY,
    },
  };

  return mappingConfiguration;
};

export const addEvent = (selectedRule: Rule): EventType => {
  const eventConfig = {
    rule: {
      clazz: selectedRule.type,
      filters: selectedRule.availableFilters || [],
      ruleDefinitionId: selectedRule.ruleDefinitionId,
      ruleDefinitionName: selectedRule.ruleDefinitionName,
      ruleName: selectedRule.ruleName,
    },
    mappings: [addMapping()],
  };

  return eventConfig;
};

export const fetchAdditionalSources = async (rule: RuleDefinition): Promise<EventProperties[]> => {
  if (rule.clazz && ADDITIONAL_SOURCES_EVENTS.find(type => type === rule.clazz)) {
    let additionalSources = [];
    if (rule.clazz === RulesTypes.Engagement) additionalSources = await getDependencies('engagements');
    else additionalSources = await getDependencies('externalfacts');
    return mapEventDependencies(additionalSources, rule.ruleName);
  }
  return [];
};

export const getSelectOptionsForSources = (
  sourceName = '',
  additionalSources: EventProperties[] = [],
  internalProps: InternalEventPropsType[] = [],
) => {
  const sources = [...(additionalSources || []), ...(internalProps || [])];

  const isExisitingEventProperty = !!sources.find((option: InternalEventPropsType) => option.value === sourceName);

  const potentialUserCreatedSource = [{ label: sourceName, value: sourceName }];
  const userCreatedSource =
    !isExisitingEventProperty && sourceName !== ''
      ? [{ label: i18n.t('partners:eventConnector.customProperties'), options: potentialUserCreatedSource }]
      : [];

  const sourcesForSelect = [
    ...userCreatedSource,
    { label: i18n.t('partners:eventConnector.eventProperties'), options: additionalSources },
    { label: i18n.t('partners:eventConnector.standardProperties'), options: internalProps },
  ];

  const allOptions = [
    ...(userCreatedSource.length ? userCreatedSource[0].options : []),
    ...(additionalSources || []),
    ...(internalProps || []),
  ];

  const selectedSourceOption = allOptions.find(option => option.value === sourceName) || null;
  return { selectedSourceOption, sourcesForSelect };
};
