import React, { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import cx from 'classnames';
import SortableTree, { walk, removeNodeAtPath, toggleExpandedForAll, ThemeProps } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as Yup from 'yup';
import { getAngularService } from 'ReactAngular/react.service';
import BtnIcon from '~/components/src/BtnIcon';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import LeftPanel from '~/components/src/LeftPanel/LeftPanel';
import Link from '~/components/src/Link';
import Icons from '~/components/src/Icons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { buildUrl } from '~/common/history';
import { showSuccess } from '~/notificationCenter';
import Notification from '~/components/src/Notification';
import 'react-sortable-tree/style.css';
import { getMediaLibraryOrFolderUrl, makeUniqueNameValidator } from './utils';
import api from './dataService';
import MediaLibraryContent from './MediaLibraryContent';
import { MediaLibrariesSortableTreeNode, NodeInfo, CreateMediaLibraryFormProps, MediaLibrariesTreeNode } from './types';
import './MediaLibrariesPage.scss';

const getRandomId = () => Math.random().toString(36).substring(2, 10);

const getTreeData = (
  mediaLibrariesTreeNode: MediaLibrariesTreeNode[],
  folderPath: string[] = [],
): MediaLibrariesSortableTreeNode[] =>
  mediaLibrariesTreeNode
    .sort((nodeA, nodeB) => {
      // Sort alphabetically
      if (nodeA.name > nodeB.name) {
        return 1;
      } else if (nodeA.name < nodeB.name) {
        return -1;
      }
      return 0;
    })
    .map(node => ({
      id: getRandomId(),
      title: node.name,
      expanded: false,
      children: getTreeData(node.children, [...folderPath, node.name]),
      mediaLibraryId: node.id,
      folderPath: [...folderPath, node.name],
    }));

const getNodeKey = ({ node }: { node: MediaLibrariesSortableTreeNode; treeIndex: number }) => node.id;

const filterTree = (
  treeData: MediaLibrariesSortableTreeNode[],
  searchQuery: string,
): MediaLibrariesSortableTreeNode[] => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  const goodPaths: string[][] = [];
  const pathsToRemove: string[][] = [];

  // Find all nodes that match the search query and their parents
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      if (nodeInfo.node.title.toLowerCase().includes(lowerCaseSearchQuery)) {
        // If a node matches the search query, save its path, so we don't remove it later
        goodPaths.push(nodeInfo.path);

        // Also save paths of match node's parents
        for (let depth = 1; depth < nodeInfo.path.length; depth += 1) {
          goodPaths.push(nodeInfo.path.slice(0, depth));
        }
      }
    },
    ignoreCollapsed: false,
  });

  // Make a list of nodes that didn't match search query, so that we can remove them
  walk({
    treeData,
    getNodeKey,
    callback: (nodeInfo: NodeInfo) => {
      const shouldStay = goodPaths.some(goodPath => isEqual(goodPath, nodeInfo.path));
      if (!shouldStay) {
        pathsToRemove.push(nodeInfo.path);
      }
    },
    ignoreCollapsed: false,
  });

  // Sort "paths to remove", to make sure we don't try to remove a path that was removed already
  pathsToRemove.sort((pathA, pathB) => {
    if (pathA.length < pathB.length) {
      return 1;
    }

    if (pathA.length > pathB.length) {
      return -1;
    }

    return 0;
  });

  let updatedTree = treeData;

  // Remove non-matched nodes from the tree
  pathsToRemove.forEach(path => {
    updatedTree = removeNodeAtPath({ treeData: updatedTree, path, getNodeKey, ignoreCollapsed: false });
  });

  if (searchQuery) {
    updatedTree = toggleExpandedForAll({ treeData: updatedTree, expanded: true });
  }

  return updatedTree;
};

const DashboardContent = ({ hasMediaLibraries }: { hasMediaLibraries: boolean }) => {
  if (!hasMediaLibraries) {
    return (
      <div className="mt-12 ml-8 mr-8">
        <Notification kind="information">{i18n.t('mediaLibraries:whatAreMls')}</Notification>
        <Notification kind="information">{i18n.t('mediaLibraries:noMlsExist')}</Notification>
      </div>
    );
  }

  return null;
};

const CreateMediaLibraryForm = ({
  setIsNewMediaLibraryFormVisible,
  navigate,
  existingMediaLibraryNames,
  fetchMediaLibrariesTree,
  setTree,
}: CreateMediaLibraryFormProps) => (
  <Formik
    initialValues={{ name: '' }}
    onSubmit={async values => {
      setIsNewMediaLibraryFormVisible(false);
      const newlyCreatedMediaLibraryId = await api.createMediaLibrary(values.name);
      showSuccess({
        header: i18n.t('mediaLibraries:mlCreated'),
        body: i18n.t('mediaLibraries:mlNameNowAvailable', { mlName: values.name }),
      });
      fetchMediaLibrariesTree().then(getTreeData).then(setTree);
      navigate(buildUrl(`content/medialibraries/view/${newlyCreatedMediaLibraryId}`));
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
        .trim()
        .required(i18n.t('validation:validation.required'))
        .test(
          'media-library-name-is-unique',
          i18n.t('mediaLibraries:mlNameMustBeUnique'),
          makeUniqueNameValidator(existingMediaLibraryNames),
        ),
    })}
  >
    {({ handleSubmit, errors, isValid }) => (
      <Form className="flex items-start gap-2 py-2 px-4">
        <Field
          as={FormikInputField}
          autoFocus
          className="flex-1"
          errorText={errors.name}
          name="name"
          placeholder={i18n.t('mediaLibraries:newMlName')}
          testHook="createMediaLibraryNameInput"
        />
        <BtnIcon
          className="mt-2"
          color="gray"
          icon="close"
          onClick={() => {
            setIsNewMediaLibraryFormVisible(false);
          }}
          testHook="createMediaLibraryCancelButton"
          tooltip={i18n.t('common:actions.cancel')}
        />
        <BtnIcon
          className="mt-2"
          color="blue"
          disabled={!isValid}
          icon="done"
          onClick={() => {
            handleSubmit();
          }}
          testHook="createMediaLibrarySubmitButton"
          tooltip={i18n.t('common:actions.create')}
        />
      </Form>
    )}
  </Formik>
);

interface MediaLibrariesTreeProps {
  treeData: MediaLibrariesSortableTreeNode[];
  searchQuery: string;
  mediaLibraryId: string | undefined;
  folderPath: string[];
  setTree: (tree: MediaLibrariesSortableTreeNode[]) => void;
  theme: ThemeProps;
}

const rowHeight = 32;
const maxScrollbarHeight = 17; // Taken from here: https://codepen.io/sambible/post/browser-scrollbar-widths
const lastRowHeight = rowHeight + maxScrollbarHeight;
const styleToHideHorizontalScrollbar = { marginBottom: `-${maxScrollbarHeight}px` };

const getLastItem = (treeData: MediaLibrariesSortableTreeNode[]): MediaLibrariesSortableTreeNode | null => {
  if (!treeData.length) {
    return null;
  }

  if (!treeData[treeData.length - 1].children.length) {
    return treeData[treeData.length - 1];
  }

  return getLastItem(treeData[treeData.length - 1].children);
};

const MediaLibrariesTree = ({
  treeData,
  searchQuery,
  mediaLibraryId,
  folderPath,
  setTree,
  theme,
}: MediaLibrariesTreeProps) => {
  const lastItem = getLastItem(treeData);

  return (
    <div
      className={cx('MediaLibrariesTree -ml-1 box-border h-auto flex-1 px-4 py-2 duration-100 ease-in')}
      style={styleToHideHorizontalScrollbar}
    >
      <SortableTree
        canDrag={false}
        generateNodeProps={(treeItem: any) => ({
          title: () => {
            const isMediaLibrary = treeItem.path.length === 1;

            const isItemSelected =
              treeItem.node.mediaLibraryId === mediaLibraryId &&
              treeItem.node.folderPath.slice(1).join('.') === folderPath.join('.');

            return (
              <Link href={getMediaLibraryOrFolderUrl(treeItem.node.mediaLibraryId, treeItem.node.folderPath)}>
                <>
                  {isMediaLibrary ? (
                    <Icons icon={treeItem.node.expanded ? 'folderOpen' : 'media'} className="mr-1 h-6 w-6" />
                  ) : (
                    <Icons icon="folderFilled" className="mr-1 h-6 w-6" />
                  )}{' '}
                  <span
                    className={cx('t-treeItem', {
                      't-selectedTreeItem -ml-1 -mr-1 bg-r42-blue pl-1 pr-1 text-white': isItemSelected,
                    })}
                  >
                    {treeItem.node.title}
                  </span>
                </>
              </Link>
            );
          },
        })}
        onChange={(updatedTreeData: any) => {
          if (!searchQuery) {
            setTree(updatedTreeData);
          }
        }}
        rowHeight={({ node }: { node: any }) => (node.id === lastItem?.id ? lastRowHeight : rowHeight)}
        theme={theme}
        treeData={treeData}
      />
    </div>
  );
};

const MediaLibrariesPage = ({ isDashboardPage }: { isDashboardPage?: boolean }): JSX.Element => {
  const navigate = useNavigate();

  const { mediaLibraryId } = useParams();
  const [searchParams] = useSearchParams();
  const folderNameParameter = searchParams.get('folderName');
  const folderPath = useMemo(
    () => (folderNameParameter ? folderNameParameter.split('~2F') : []),
    [folderNameParameter],
  );

  const [searchQuery, setSearchQuery] = useState('');

  const [tree, setTree] = useState<MediaLibrariesSortableTreeNode[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isNewMediaLibraryFormVisible, setIsNewMediaLibraryFormVisible] = useState(false);

  const [hasAdservingEdit, setHasAdservingEdit] = useState(false);

  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(false);

  useEffect(() => {
    getAngularService(document, 'SecurityService')
      .getSecurityContext()
      .then((context: { hasPermission: (permission: string) => boolean }) => {
        setHasAdservingEdit(context.hasPermission('ADSERVING_EDIT'));
      });

    api
      .fetchMediaLibrariesTree()
      .then(getTreeData)
      .then(setTree)
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isDashboardPage && tree[0]) {
      // Don't stay on the Dashboard page if we can show a media library
      navigate(buildUrl(`content/medialibraries/view/${tree[0].mediaLibraryId}`));
    }
  }, [isDashboardPage, tree]);

  const existingMediaLibraryNames = tree.map(mediaLibrary => mediaLibrary.title);

  const treeData = filterTree(tree || [], searchQuery);

  return (
    <div className="flex h-full items-stretch overflow-hidden">
      <LeftPanel
        className={cx('w-72', { '!w-96': isLeftPanelExpanded })}
        isLoading={isLoading}
        localStorageKey="mediaLibrariesLeftPanelExpanded"
      >
        <div className="flex h-full flex-col">
          <div className="relative flex items-center gap-2 border-b border-gray-200 bg-gray-50 p-4">
            <SearchElement
              className="flex-1"
              onChange={event => {
                setSearchQuery(event.target.value);
              }}
              placeholder={i18n.t('mediaLibraries:searchMls')}
              testHook="mlSearchInput"
              value={searchQuery}
            />
            <BtnIcon
              color="blue"
              disabled={!hasAdservingEdit}
              icon="add"
              onClick={() => {
                setIsNewMediaLibraryFormVisible(!isNewMediaLibraryFormVisible);
              }}
              testHook="createMediaLibraryButton"
              tooltip={
                hasAdservingEdit ? i18n.t('mediaLibraries:createMl') : i18n.t('mediaLibraries:createMlNoPermissions')
              }
            />
            <BtnIcon
              className={cx('absolute right-[-8px] top-[54px] z-10 h-5 w-5 rounded-full p-0.5', {
                'rotate-180 transition': isLeftPanelExpanded,
              })}
              onClick={() => setIsLeftPanelExpanded(!isLeftPanelExpanded)}
              icon="cheveronRight"
            />
          </div>

          {isNewMediaLibraryFormVisible && (
            <CreateMediaLibraryForm
              setIsNewMediaLibraryFormVisible={setIsNewMediaLibraryFormVisible}
              navigate={navigate}
              fetchMediaLibrariesTree={api.fetchMediaLibrariesTree}
              existingMediaLibraryNames={existingMediaLibraryNames}
              setTree={setTree}
              getTreeData={getTreeData}
            />
          )}
          <MediaLibrariesTree
            treeData={treeData}
            searchQuery={searchQuery}
            mediaLibraryId={mediaLibraryId}
            folderPath={folderPath}
            setTree={setTree}
            theme={FileExplorerTheme}
          />
        </div>
      </LeftPanel>
      <div className="flex-1 overflow-y-scroll bg-[#f6f6f6]">
        {mediaLibraryId ? (
          <MediaLibraryContent
            existingMediaLibraryNames={existingMediaLibraryNames}
            fetchMediaLibrariesTree={api.fetchMediaLibrariesTree}
            folderPath={folderPath}
            getTreeData={getTreeData}
            hasAdservingEdit={hasAdservingEdit}
            mediaLibraryId={mediaLibraryId}
            setTree={setTree}
          />
        ) : (
          <DashboardContent hasMediaLibraries={!isLoading && tree.length > 0} />
        )}
      </div>
    </div>
  );
};

export default MediaLibrariesPage;
