import React from 'react';
import ModalHeader from '~/modals/containers/ModalHeader';
import { twMerge } from 'tailwind-merge';
import cx from 'classnames';
import './style.scss';

interface PickerViewProps {
  pickerTitle: string;
  handlePickerVisibility?: (isVisible: boolean) => void;
  children: React.ReactChild;
  className?: string;
}

const PickerView = ({ pickerTitle, handlePickerVisibility, children, className }: PickerViewProps): JSX.Element => {
  const closeHandler = handlePickerVisibility ? () => handlePickerVisibility(false) : undefined;

  return (
    <div className="ModalBG" onClick={closeHandler}>
      <div
        className={twMerge(
          cx('picker flex flex-col rounded-md border border-gray-400 bg-white drop-shadow', className),
        )}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader title={pickerTitle} onCloseClick={closeHandler} />
        <div className="picker-content">{children}</div>
      </div>
    </div>
  );
};

export default PickerView;
