/**
 * @class webUi.service.common
 * @memberOf webUi.service
 */
angular.module('webUi.service.common', [
    'webUi.service.common.securityCommonService',
    'webUi.service.common.conditionsCommonService',
    'webUi.service.common.conditions',
    'webUi.service.common.remoteConnectionConfigService'
]);
