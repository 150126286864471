export default {
  data: {
    nodes: {
      nodeId: '47763f51-2786-4ec6-a4e8-674f49a92af0',
      type: 'START_TRIGGER',
      label: 'FCL created + enrollmentid=em15692213 rename',
      children: [
        {
          nodeId: '9414e827-0215-4575-b5d0-08e4559510bd',
          type: 'STEP',
          label: 'Optimizely bucketing (ereg_poc_001)',
          hasTransition: true,
          children: [
            {
              nodeId: 'af4d4307-fc45-40f5-8fcc-715d2978a950',
              type: 'TRIGGER',
              label: 'Variation 1 (10%)',
              children: [
                {
                  nodeId: '8b005e5b-2b4c-4ef7-b42b-5ed8d936e381',
                  type: 'STEP',
                  label: 'Do Nothing',
                  children: [],
                  experimentVariant: {
                    experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
                    name: 'Optimizely bucketing (ereg_poc_001) Variant',
                    weight: 100,
                  },
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
            },
            {
              nodeId: '82562e15-e700-4b18-8f70-1ebf4c1910d4',
              type: 'TRIGGER',
              label: 'Variation 2 (90%)',
              children: [
                {
                  nodeId: '593a0f65-fdaa-4b7f-9929-a3f09b1e7e10',
                  type: 'STEP',
                  label: '[2] AdobeTarget (onsite) + Google customer match',
                  children: [
                    {
                      nodeId: '1ec38c48-9941-4848-87ac-3c0dadc085ea',
                      type: 'TRIGGER',
                      label: 'Wait 1 day',
                      children: [
                        {
                          nodeId: '1f694f56-dea0-4f62-b637-2ce15c9bdce4',
                          type: 'STEP',
                          label: '[2] SFMC + AdobeTarget + Google Customer Match',
                          children: [
                            {
                              nodeId: '103e29c7-63b7-489b-9783-ed8bca307a13',
                              type: 'TRIGGER',
                              label: 'Clicked "skip this step"',
                              hasTransition: true,
                              transitionNodeId: '9414e827-0215-4575-b5d0-08e4559510bd',
                              children: [],
                            },
                          ],
                          experimentVariant: {
                            experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
                            name: 'Optimizely bucketing (ereg_poc_001) Variant',
                            weight: 100,
                          },
                          stats: {
                            profilesInStep: 0,
                          },
                          hasVariableModifications: false,
                        },
                      ],
                    },
                    {
                      nodeId: 'd24a246f-cf50-4a2e-8603-93582e8bf946',
                      type: 'TRIGGER',
                      label: 'Clicked "skip this step"',
                      hasTransition: true,
                      transitionNodeId: '9414e827-0215-4575-b5d0-08e4559510bd',
                      children: [],
                    },
                  ],
                  experimentVariant: {
                    experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
                    name: 'Optimizely bucketing (ereg_poc_001) Variant',
                    weight: 100,
                  },
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
            },
          ],
          experimentVariant: {
            experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
            name: 'Optimizely bucketing (ereg_poc_001) Variant',
            weight: 100,
          },
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
    },
    goals: [
      {
        nodeId: 'e71b9325-c208-49ab-855b-c215634ae1ad',
        label: 'WREG Account Registration Complete (fcl + ean linked)',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [
            {
              experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
              name: 'Optimizely bucketing (ereg_poc_001) Variant',
              profilesReached: 0,
              profilesEnteredVariant: 0,
            },
          ],
        },
      },
    ],
    profilesEnteredJourney: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      variantStats: [
        {
          experimentVariantId: 'f93a9714-62ab-4510-8c35-70b8ea8d555f',
          name: 'Optimizely bucketing (ereg_poc_001) Variant',
          profilesRemoved: 0,
          profilesExpired: 0,
        },
      ],
    },
  },
  status: {
    success: true,
  },
};
