/**
 * @class webUi.ExperimentStatsService
 * @memberOf webUi
 */
angular.module('webUi.service.tagmanagement.experimentStats',
    [
        'restangular',
        'webUi.common.Utils'
    ]
)
    .factory('ExperimentStatsService',
        [
            '$q',
            'Restangular',
            'ChartService',
            'Utils',
            /**
		 * @param Restangular
		 * @param $q
		 * @param Utils
		 * @param ChartService
		 * @returns {{*}}
		 */
            function ExperimentStatsService($q, Restangular, ChartService, Utils){

                var BASE_PATH = 'tagmanagement/experiments';

                /**
			 *
			 * @param experimentId
			 * @param days
			 * @returns {*}
			 */
                var getCumulativeStatsForExperiment = function (experimentId) {

                    var deferredCumulativeStatsPromise = $q.defer();

                    Restangular.oneUrl(BASE_PATH + '/' + experimentId + '/stats-cumulative').get().then(
                        function onSuccess(result) {
                            var _result = Utils.sanitizeRestangularOne(result);

                            if (_result) {
                                deferredCumulativeStatsPromise.resolve(_result);
                            }
                        },
                        function onError(){
                            deferredCumulativeStatsPromise.resolve({'error':'error'});
                        }
                    );
                    return deferredCumulativeStatsPromise.promise;
                };

                /**
			 *
			 * @param experimentId
			 * @returns {*}
			 */
                var getStatsForExperiment = function (experimentId, days) {

                    var deferredStatsPromise = $q.defer();

                    Restangular.oneUrl(BASE_PATH + '/' + experimentId + '/stats').get({'days': days}).then(
                        function onSuccess(result) {

                            var _returnData = {chartSeries: null, chartOptions: null};
                            var _result = Utils.sanitizeRestangularOne(result);

                            if (_result) {
                                _returnData.series = _getChartSeries(_result, days);
                                _returnData.options = _getChartOptions(days);
                                _returnData.days = days;
                                deferredStatsPromise.resolve(_returnData);
                            }
                        },
                        function onError() {
                            deferredStatsPromise.resolve({'error': 'error'});
                        }
                    );
                    return deferredStatsPromise.promise;
                };

                /**
			 *
			 * @param stats
			 * @returns {*[]}
			 */
                var _getChartSeries = function(stats, days) {

                    var _return = [];
                    var _index = 2;

                    var _pointInterval;
                    if (days === 7) {
                        _pointInterval = 3600 * 1000; //hourly
                    }
                    if (days === 30 || days === 90) {
                        _pointInterval = 3600 * 24 * 1000; //daily
                    }

                    _.each(stats, function(experimentGroupStats, experimentGroupId){
                        if (experimentGroupStats.length > 0) {
                            _return.push({
                                name: 'Conversion for ' + experimentGroupStats[0]['experimentGroupName'],
                                data: _.pluck(experimentGroupStats, 'cumulativeConversionRate'),
                                yAxis: 0,
                                pointInterval: _pointInterval,
                                pointStart: (experimentGroupStats.length === 0) ? 0 : experimentGroupStats[0].timestamp,
                                color: ChartService.getColor(_index, true),
                                animation: false,
                                r42ChartSeriesIdentifier: experimentGroupId,
                                r42ChartSeriesVisible: true
                            });
                            _index++;
                        }
                    });

                    return _return;
                };

                /**
			 * plot bands - weekend - need to be calculated
			 * @returns {*}
			 */
                var _getChartOptions = function(days) {

                    var _chartOptions = {};

                    var _tickInterval;
                    if (days === 7) {
                        _tickInterval = 3600 * 1000; //hourly
                    }
                    if (days === 30 || days === 90) {
                        _tickInterval = 24 * 3600 * 1000; //daily
                    }

                    _chartOptions = {
                        chart: {
                            zoomType: 'xy',
                            type: 'spline',
                            spacingBottom: 30
                        },
                        xAxis: {
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                month: '%e.%m'
                            },
                            tickInterval: _tickInterval
                        },
                        yAxis: {
                            title: {
                                text: 'Conversion rate (%)'
                            }
                        },
                        plotOptions: {
                            spline: {
                                fillOpacity: 0.4,
                                lineWidth: 3,
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        tooltip: {
                            formatter: function() {
                                //
                                var _return = '<span style="font-size:10px">' + moment(this.x).format('D. MMM') + '</span><table>';

                                _.forEach(this.points , function(point){
                                    var _pointYvalue = point.point.y;
                                    //point.series
                                    _return += '<tr><td style="color:' + point.series.color + ';padding:0">' + point.series.name + ': </td>'
        + '<td style="padding-left:30px;"><b>' + Highcharts.numberFormat(_pointYvalue, 2) + '%</b></td></tr>';
                                });

                                _return += '</table>';
                                return _return;
                            },
                            shared: true,
                            useHTML: true
                        }
                    };

                    _chartOptions.xAxis.plotBands = ChartService.getPlotBands(days, '#efefef');

                    return _chartOptions;
                };

                /**
			 * RPM API
			 * @type {{*}}
			 */
                var service = {
                    getCumulativeStatsForExperiment: getCumulativeStatsForExperiment,
                    getStatsForExperiment: getStatsForExperiment
                };
                return service;
            }
        ]
    );
