/* eslint-disable no-prototype-builtins */
angular.module('webUi.ui.content.campaigns.ads.conditions.configure', ['ui.bootstrap'])

    .controller('AdServingConditionConfigureDialogController', ['$scope', '$uibModalInstance', 'dialogsModel', 'AdService', 'Utils', 'SecurityService','ValidService','PubSubService', 'ToasterService',
        function AdServingConditionConfigureDialogController(    $scope,   $uibModalInstance,   dialogsModel,   AdService,   Utils, SecurityService, ValidService, PubSubService, toast) {

            SecurityService.getSecurityContext().then(function(securityContext) {
                if (securityContext) {
                    $scope.hasDataManagementModule = securityContext.hasModule('DATA_MANAGEMENT');
                    $scope.hasAudiencesView = securityContext.hasModule('AUDIENCES') && securityContext?.hasPermission('AUDIENCES_VIEW');
                }
            });

            var ad = $scope.ad = dialogsModel.ad;

            var resetState = function() {
                $scope.state = {};
                $scope.state.activeCondition = undefined;
                $scope.state.beforeSubmit = function(condition) {
                    return condition;
                };
            };

            resetState();

            $scope.navigate = function(condition) {
                $scope.state.activeCondition = condition;
            };

            $scope.cancel = function() {
                // only cancel form
                if($scope.state.conditions && $scope.state.formCondition) {
                    $scope.state.formCondition = null;
                    // reset whole state
                } else {
                    resetState();
                }
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };

            $scope.hasCondition = function(conditionType) {
                return _.filter($scope.ad.conditions, { clazz: conditionType }).length > 0;
            };

            $scope.getCondition = function(conditionType) {
                return _.find($scope.ad.conditions, { clazz: conditionType });
            };

            $scope.getConditions = function(conditionTypes) {

                if(!_.isArray(conditionTypes)) {
                    conditionTypes = [conditionTypes];
                }

                return _.filter($scope.ad.conditions, function(condition) {
                    return _.contains(conditionTypes, condition.clazz);
                });
            };

            $scope.deleteCondition = function (condition) {
                AdService.removeCondition($scope.ad.adId, condition.conditionId).then(function() {
                    removeConditionFromList(ad.conditions, condition);
                    toast.success('Condition deleted successfully');
                    if($scope.state.conditions) {
                        // remove condition also from state conditions
                        removeConditionFromList($scope.state.conditions, condition);
                        $scope.state.formCondition = null;
                        // if not in multiview reset state
                    } else {
                        resetState();
                    }
                });
            };

            var removeConditionFromList = function(conditions, newCondition) {
                for (var key in conditions) {
                    if (conditions.hasOwnProperty(key) && conditions[key].conditionId === newCondition.conditionId) {
                        delete conditions[key]; //remove condition from list
                    }
                }
            };

            $scope.save = function() {
                if($scope.state.beforeSubmit($scope.state.formCondition)) {
                    var condition = $scope.state.formCondition;

                    AdService.submitCondition($scope.ad.adId, condition).then(function(conditionPojo) {
                        if(ValidService.isEmpty(conditionPojo)) {
                            PubSubService.publishFormValidate($scope.adConditionForm.$name);
                            return;
                        }
                        conditionPojo = Utils.sanitizeRestangularOne(conditionPojo);

                        addOrReplaceCondition($scope.ad.conditions, conditionPojo.condition);

                        // push condition also to state conditions
                        if($scope.state.conditions) {
                            addOrReplaceCondition($scope.state.conditions, conditionPojo.condition);
                            $scope.state.formCondition = null;
                            // if not in multiview reset state
                        } else {
                            resetState();
                        }
                    });
                }
            };

            var addOrReplaceCondition = function(conditions, newCondition) {
                conditions[newCondition.conditionType.name] = newCondition;
            };
        }]);
