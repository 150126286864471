/**
 * @description Angular module for TagTemplateContentController
 * @module webUi.ui.tagmanagement.tags.view.content
 * @namespace webUi.ui.tagmanagement.tags.view.content
 * @class TagTemplateContentController
 */
angular
  .module('webUi.ui.tagmanagement.tags.view.content.templateContentEdit', [])

  .controller('TagTemplateContentController', [
    '$scope',
    '$q',
    '$timeout',
    'Utils',
    'TagmanagementService',
    'PubSubService',
    'Restangular',
    'GoogleAnalyticsService',
    /**
     * @param $scope
     * @param $q
     * @param $timeout
     * @param Utils
     * @param TagmanagementService
     * @param PubSubService
     * @param Restangular
     * @constructor
     */
    function TagTemplateContentController(
      $scope,
      $q,
      $timeout,
      Utils,
      TagmanagementService,
      PubSubService,
      Restangular,
      GoogleAnalyticsService,
    ) {
      var tagId = $scope.tagViewPojo.tag.tagId;

      //Doesn't belong here
      $scope.tag = angular.copy($scope.tagViewPojo.tag);

      //Needs to go - like content.js - access via tagViewPojo in template (support) instead of separate copy
      $scope.tagTemplate = $scope.tagViewPojo.tagTemplate;
      $scope.tagTemplateVariant = $scope.tagViewPojo.tagTemplateVariant;

      /**
       *
       * @description uses pubsub to signal change in $scope > tagViewPojo > tagContent
       * @todo tagViewPojo has a dual 'state' view-edit and partial references to a member
       * in the object tree don't work (re-assigning a partial object)
       * @param newContent
       */
      $scope.save = function save(newContent, oldContent) {
        //Because sometimes the evaluation of tokens in the valid directive happens before new fields are rendered, publish the form to re-Evaluate the form before save
        PubSubService.publishFormValidate($scope.templateForm.$name);
        //Ask again if the form still valid
        if ($scope.templateForm.$valid) {
          if (_.isEqual(oldContent, newContent)) {
            $scope.templateState.editMode = false;
          } else {
            TagmanagementService.updateContent(tagId, newContent).then(
              function onSuccess(res) {
                var tagModificationPojo = Restangular.stripRestangular(res);
                PubSubService.publishTypeChanged(PubSubService.TYPES.TAGMANAGEMENT.TAG, {
                  tagContent: newContent,
                  tagModificationPojo: tagModificationPojo,
                });
                $scope.templateState.editMode = false;
                GoogleAnalyticsService.handleGAEvent('Tag Edited');
              },
              function onError() {},
            );
          }
        }
      };

      $scope.cancel = function () {
        $scope.templateState.editMode = false;
      };
    },
  ]);
