import React, { useEffect } from 'react';

import { Field, FormikErrors, useFormikContext } from 'formik';

import i18n from '~/i18n';
import { SnapshotProperty } from '~/ai/types';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';

import { Model, ModelProperty, OptionType, Prediction } from '../types';

import '../styles.scss';

const filterPredictionProperties = (predictions: Prediction[], snapshotProperties: ModelProperty[]) => {
  const predictionProperties = predictions
    .map(prediction => prediction.criterias.map(criteria => criteria.rules.map(rule => rule.property)))
    .flat(2);
  return snapshotProperties.filter(sp => !predictionProperties.includes(sp.name));
};

const ModelPredictiveProperties = ({
  values,
  errors,
  snapshotProperties,
}: {
  values: Model;
  errors: FormikErrors<Model>;
  snapshotProperties: SnapshotProperty[];
}): React.ReactElement => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const { predictions, inputsToInclude } = values;
    const filteredProps = filterPredictionProperties(predictions, snapshotProperties);
    const filtered = inputsToInclude.filter(ip => filteredProps.find(sp => sp.value === ip.value));
    setFieldValue('inputsToInclude', filtered);
  }, []);

  return (
    <div className="Models-inputProps">
      <Field
        className="Models-inputProps--field"
        label={i18n.t('ai:new.inputsToInclude.label')}
        name="inputsToInclude"
        options={filterPredictionProperties(values.predictions, snapshotProperties)}
        as={FormikSelectField}
        placeholder={i18n.t('ai:new.inputsToInclude.placeholder')}
        errorText={errors.inputsToInclude}
        getOptionLabel={(option: OptionType) => option.label}
        getOptionValue={(option: OptionType) => option.value}
        customSetFieldValue={(name: string, options: OptionType[]) => setFieldValue(name, options)}
        isMulti
        isSearchable
        showOptionsCount
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
    </div>
  );
};

export default ModelPredictiveProperties;
