import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Field } from 'formik';
import * as Yup from 'yup';
import { useAPI } from '~/common/ApiHooks';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import BtnIcon from '~/components/src/BtnIcon';
import { Notification } from '~/components';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { addAudienceInFacebook, getFacebookList } from './service';
import AddFacebookAudienceForm from './AddFacebookAudienceForm';

const makeValidations = t =>
  Yup.object().shape({
    audience: Yup.object().shape({
      name: Yup.string().required(t('connectors:cards.validation.audience')),
      audienceId: Yup.string(),
    }),
  });

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const openAudienceModal = (createNewAudience, setFieldValue) => dispatch => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:connectors.facebook.audienceAdd.header'),
      confirmText: i18n.t('audiences:connectors.facebook.audienceAdd.confirmText'),
      content: (
        <AddFacebookAudienceForm
          hideModal={() => dispatch(hideModal())}
          createNewAudience={createNewAudience}
          setFieldValue={setFieldValue}
        />
      ),
    }),
  );
};

const Facebook = ({ onEdit, connector, openAudienceModal, hideModal, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const [listItems, setListItems] = useState([]);
  const { data, error, isLoading: isListLoading } = useAPI(() => getFacebookList(partnerId), [partnerId]);

  useEffect(() => {
    setListItems(data || []);
  }, [data]);

  const createAudience = (name, description, optOutUrl, customerFileSource, setFieldValue) => {
    const payload = {
      name,
      description,
      optOutUrl,
      customerFileSource,
    };
    addAudienceInFacebook(partnerId, payload)
      .then(response => {
        setListItems([...listItems, response]);
        setFieldValue('audience', response);
        hideModal();
      })
      .catch(() => {});
  };

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      connector={connector}
      validations={makeValidations(t)}
      isSubmitted={isSubmitted}
    >
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <div className="flex items-center gap-4">
              <Field
                name="audience"
                as={FormikSelectField}
                label={t('connectors.facebook.fieldLabel')}
                errorText={formProps.touched.audience && formProps.errors.audience && formProps.errors.audience.name}
                options={listItems}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.audienceId}
                isLoading={isListLoading}
                testHook="facebookAudience"
                className="flex-1"
              />
              <BtnIcon
                className="mt-3 rounded-full"
                icon="add"
                testHook="addSegment"
                tooltip={t('connectors.facebook.audienceAdd.iconDesc')}
                onClick={() => openAudienceModal(createAudience, formProps.setFieldValue)}
              />
            </div>
          </SmallWrapperNoOffset>
          {error && <Notification kind="error">{t('connectors.facebook.audienceAdd.genericError')}</Notification>}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { hideModal, openAudienceModal }), translate('audiences'))(Facebook);
