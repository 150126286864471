import { get } from 'lodash';

/**
 * Get Environment By Id
 * @param state
 * @param environmentId
 * @returns {*}
 */
export const getEnvironmentById = (state, environmentId) => get(state, `admin.environments.byId[${environmentId}]`);

/**
 * Get All Environments
 * @param state
 */
export const getEnvironments = state => state.admin.environments.allIds.map(id => state.admin.environments.byId[id]);

/**
 * Should fetch all environments?
 * @param state
 */
export const shouldFetchEnvironments = state => get(state, 'admin.environments.ui.shouldFetchAll');

/**
 * Is fetching all environments currently in progress?
 * @param state
 */
export const isFetchingEnvironments = state => get(state, 'admin.environments.ui.isFetchingAll');

/**
 * Get non deletable codes
 * @param state
 */
export const getNonDeletableCodes = state => get(state, 'admin.environments.nonDeletableCodes');
