/**
 *
 * @description Angular module for Export data -> Add Export Request
 * @class AddExportRequestController
 * @memberOf webUi
 * @namespace webUi.ui.profiles.export
 */
angular.module('webUi.ui.profiles.export.addExportRequest',
    [
        'webUi.ui.profiles.export.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.export.add', {
                url: '/add',
                leftMenu: false,
                isReact: true,
            })
            .state('site.profiles.export.edit', {
                url: '/edit/{id}',
                leftMenu: false,
                isReact: true,
            });
    }]);
