import React, { useState } from 'react';
import { Field } from 'formik';
import { createColumnHelper } from '@tanstack/react-table';
import { Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import BtnOutlined from '~/components/src/BtnOutlined';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { TNewExperimentGroup } from '../types';
import { formatFloatValue } from '../util';
import ExpGroupModal from './ExpGroupModal';

const columnHelper = createColumnHelper<TNewExperimentGroup>();

export const ExperimentGroups = (arrayProps: any) => {
  const { form, remove, push, replace } = arrayProps;

  const [selectedGroup, setSelectedGroup] = useState<TNewExperimentGroup | null>();
  const [showGroupModel, setShowGroupModel] = useState(false);
  const { setFieldValue, values } = form;

  const closeGroupModal = () => {
    setShowGroupModel(false);
    setSelectedGroup(null);
  };

  const experimentGroups = values.experimentGroups || [];

  const totalWeight = experimentGroups.reduce((acc: number, val: TNewExperimentGroup) => acc + val.weight, 0);

  const updateControlGroup = (id: string) => {
    experimentGroups.forEach((eg: TNewExperimentGroup, index: number) =>
      setFieldValue(`experimentGroups[${index}].controlGroup`, index === parseInt(id, 10)),
    );
  };

  const renderActions = (row: TNewExperimentGroup, index: string) => (
    <div className="flex items-center justify-center gap-2">
      <BtnIcon
        color="gray"
        icon="edit"
        onClick={() => {
          setSelectedGroup(row);
          setShowGroupModel(true);
        }}
        testHook="editGroup"
        tooltip={i18n.t('common:actions.edit')}
      />
      {experimentGroups?.length > 2 && (
        <BtnIcon
          testHook="deleteGroup"
          tooltip={i18n.t('common:actions.delete')}
          color="gray"
          icon="delete"
          onClick={() => remove(parseInt(index, 10))}
        />
      )}
    </div>
  );

  const getColumns = () => [
    columnHelper.accessor('name', {
      header: () => <span>{i18n.t('experiments:traffic.name')}</span>,
      cell: info => <span>{info.getValue()}</span>,
      size: 250,
    }),
    columnHelper.accessor('weight', {
      header: () => <div className="text-right">{i18n.t('experiments:traffic.trafficWeight')}</div>,
      cell: info => (
        <div className="flex items-center justify-end gap-2">
          <span>{info.getValue()}</span>
          <span className="text-xs text-r42-blue">({formatFloatValue(info.getValue() / totalWeight, 2, true)}%)</span>
        </div>
      ),
      size: 100,
    }),
    columnHelper.accessor('controlGroup', {
      header: () => <div className="text-center">{i18n.t('experiments:traffic.controlGroup')}</div>,
      cell: info => (
        <div className="text-center">
          <Field
            className="t-controlGroup !h-6 !w-6"
            id={`experimentGroups[${info.row.id}].controlGroup`}
            name={`experimentGroups[${info.row.id}].controlGroup`}
            type="checkbox"
            checked={experimentGroups[info.row.id].controlGroup}
            onChange={() => updateControlGroup(info.row.id)}
          />
        </div>
      ),
      size: 100,
    }),
    columnHelper.accessor('actions', {
      header: () => <div className="text-center">{i18n.t('experiments:traffic.actions')}</div>,
      cell: info => renderActions(info.row.original, info.row.id),
      size: 100,
    }),
  ];

  return (
    <>
      <Notification kind="information" testHook="noLinkedTags">
        {i18n.t('experiments:messages.expGroupMessage')}
      </Notification>
      <UITable testHook="expGroupTable" data={experimentGroups} columns={getColumns()} />

      <BtnOutlined icon="add" className="mt-4" color="blue" testHook="addGroup" onClick={() => setShowGroupModel(true)}>
        {i18n.t('experiments:form.group.add')}
      </BtnOutlined>
      {showGroupModel && (
        <PickerView
          className="!h-auto !w-1/3"
          pickerTitle={i18n.t('experiments:form.group.label')}
          handlePickerVisibility={() => closeGroupModal()}
        >
          <ExpGroupModal
            group={selectedGroup}
            addGroup={group => push(group)}
            closeModal={() => closeGroupModal()}
            editGroup={group => {
              const index = experimentGroups.findIndex((eg: TNewExperimentGroup) => eg.name === selectedGroup?.name);
              replace(index, group);
            }}
          />
        </PickerView>
      )}
    </>
  );
};
