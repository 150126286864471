import i18n from '~/i18n';

export const DATE_FORMAT = 'YYYY-MM-DD';

// 1d, 12M, 7w
export const DATE_REGEX = /^([0-9])+[dwmh]$/i;

export const FIELD_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const FIELD_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const TimeFrameOptions = [
  {
    label: i18n.t('audiences:rules.time.inThePast'),
    value: 'PAST',
  },
  {
    label: i18n.t('audiences:rules.time.inTheFuture'),
    value: 'FUTURE',
  },
];

export const FILTER_CLAZZ = {
  DATE: 'DateFilterValue',
  DATE_ON: 'DateOnFilterValue',
  DATE_WITHIN: 'DateWithinFilterValue',
  NUMBER: 'NumberFilterValue',
  NUMBER_WITHIN: 'NumberWithinFilterValue',
  STRING: 'StringFilterValue',
  TIMEFRAME_WITHIN: 'TimeFrameWithinFilterValue',
} as const;
