/**
 * @description Angular module for Content -> Campaigns -> Form
 * @class ContentCampaignFormController
 * @namespace webUi.ui.content.campaigns.form
 * @memberOf webUi.ui.content.campaigns
 */
angular.module('webUi.ui.content.campaigns.form',
    [
        'webUi.ui.content.campaigns.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.form', {
                url : '/form',
                views : {
                    'mainContent@' : {
                        templateUrl : 'ui/content/campaigns/form/form.tpl.html',
                        controller : 'ContentCampaignFormController as vm'
                    }
                },
                leftMenu : true,
                resolve : {
                    defaultPlacements : ['PlacementGroupService', function(PlacementGroupService) {
                        return PlacementGroupService.getDefaultPlacements();
                    }]
                }
            });
    }])
    .controller('ContentCampaignFormController',
        [
            '$scope',
            '$state',
            'CampaignService',
            'PubSubService',
            'ValidService',
            'Utils',
            'defaultPlacements',
            /**
     * @function ContentCampaignFormController
     * @memberOf webUi.ui.content.campaigns.form
     *
     * @param $scope
     * @param $state
     * @param CampaignService
     * @param PubSubService
     * @param {ValidService} ValidService
     * @param Utils
     * @param defaultPlacements
     *
     * @returns ContentCampaignsDashboardController
     */
            function ContentCampaignFormController($scope, $state, CampaignService, PubSubService, ValidService, Utils, defaultPlacements) {
                'use strict';
                
                var vm = this;

                vm.campaign = {
                    compliant: false,
                    placementGroup : {
                        name: 'Default placements',
                        placements: defaultPlacements
                    },
                    placementParameters: []
                };

                //validation function checking that there are placements
                vm.hasPlacements = function() {
                    return vm.campaign.placementGroup.placements.length > 0;
                };

                //check that the new placement to be added is valid numerically
                vm.isValidPlacement = function(p) {
                    if(!p || !p.width  || !p.height) {
                        return true;
                    }
                    return ValidService.isPositiveNumber(p.width) && ValidService.isPositiveNumber(p.height);
                };

                //check that the new placement to be added is valid numerically
                vm.isValidParameter = function(p) {
                    return !_.isUndefined(p) && !_.isEmpty(p.name);
                };

                vm.isUniquePlacement = function(p) {

                    if(ValidService.isEmpty(p) || (ValidService.isEmpty(p.width) && ValidService.isEmpty(p.height))) {
                        return true;
                    }

                    var foundIdx = _.findIndex(vm.campaign.placementGroup.placements, function(placement) {
                        return placement.width === _.parseInt(p.width) && placement.height === _.parseInt(p.height);
                    });

                    return foundIdx === -1;
                };


                vm.isUniqueParameter = function(p) {

                    if(ValidService.isEmpty(p)) {
                        return true;
                    }

                    var foundIdx = _.findIndex(vm.campaign.placementParameters, function(param) {
                        if(ValidService.isEmpty(param.name) || ValidService.isEmpty(p.name)) {
                            return -1;
                        }
                        return param.name.toLowerCase() === p.name.toLowerCase();
                    });

                    return foundIdx === -1;
                };

                //only add the newPlacement if the inner form is valid
                vm.addPlacement = function(p) {
                    PubSubService.publishFormValidate($scope.newPlacementForm.$name);
                    if($scope.newPlacementForm.$valid) {
                        vm.campaign.placementGroup.placements.push({width: _.parseInt(p.width), height: _.parseInt(p.height) });
                        vm.newPlacement = {};
                        $('#newPlacement-width').focus();
                    }
                };

                //only add the newParameter if the inner form is valid
                vm.addParameter = function(p) {
                    PubSubService.publishFormValidate($scope.newParameterForm.$name);
                    if($scope.newParameterForm.$valid) {
                        vm.campaign.placementParameters.push({name:p.name, defaultValue: p.defaultValue });
                        vm.newParameter = {};
                        $('#newParameter-name').focus();
                    }
                };

                /**
         * removes placement from group
         * @param placement
         */
                vm.removePlacement = function (placement) {
                    vm.campaign.placementGroup.placements = _.without(vm.campaign.placementGroup.placements, placement);
                };

                vm.removeParameter = function (parameter) {
                    vm.campaign.placementParameters = _.without(vm.campaign.placementParameters, parameter);
                };

                vm.submit = function() {
                    CampaignService.saveCampaign(vm.campaign).then(function(campaignId) {
                        // notify listening scopes that a new Campaign has been added
                        PubSubService.publishTypeCreated(PubSubService.TYPES.CONTENT.CAMPAIGN, {});
                        $state.transitionToStateWithSite('site.content.campaigns.view', {campaignId: campaignId});
                    });
                };
            }]);


