import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ReduxFormInputField, ReduxFormCheckboxField } from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import {
  isRequired,
  defaultMaxInput,
  maxLength,
  isAlphanumeric,
  numberRange,
  isValueUniqueCaseInsensitive,
  normalizeToInteger,
} from '~/common';

const toLowerCase = value => value && value.toLowerCase();

const environmentCodeMaxLength = maxLength(10);

const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.environments.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUniqueCaseInsensitive(value, itemsList);
};

const isNameUnique = isFormFieldUnique('environmentName');

const isCodeUnique = isFormFieldUnique('environmentCode');

const isOrderWithinRange = numberRange(0, 1000);

const isSampleRateWithinRange = numberRange(0, 100);

const isFieldDisabled = (initialValues, nonDeletableCodes) => {
  if (!initialValues || !nonDeletableCodes) return false;
  return nonDeletableCodes.includes(initialValues.environmentCode);
};

export const EnviromentForm = ({ handleSubmit, backHref, t, nonDeletableCodes, initialValues, submitting = false }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <Field
      label={t('environments:form.environmentName.label')}
      name="environmentName"
      placeholder={t('environments:form.environmentName.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, isNameUnique, defaultMaxInput]}
      type="text"
      disabled={isFieldDisabled(initialValues, nonDeletableCodes)}
    />
    <Field
      label={t('environments:form.environmentCode.label')}
      name="environmentCode"
      placeholder={t('environments:form.environmentCode.placeholder')}
      component={ReduxFormInputField}
      validate={[isRequired, isCodeUnique, isAlphanumeric, environmentCodeMaxLength]}
      disabled={isFieldDisabled(initialValues, nonDeletableCodes)}
      normalize={toLowerCase}
    />
    <Field
      label={t('environments:form.environmentOrder.label')}
      name="environmentOrder"
      placeholder={t('environments:form.environmentOrder.placeholder')}
      component={ReduxFormInputField}
      normalize={normalizeToInteger}
      validate={[isRequired, isOrderWithinRange, defaultMaxInput]}
      type="number"
    />
    <Field
      label={t('environments:form.sampleRate.label')}
      name="sampleRate"
      placeholder={t('environments:form.sampleRate.placeholder')}
      component={ReduxFormInputField}
      normalize={normalizeToInteger}
      validate={[isRequired, isSampleRateWithinRange, defaultMaxInput]}
      type="number"
    />
    <Field label={t('environments:form.requiresAdmin.label')} name="requiresAdmin" component={ReduxFormCheckboxField} />
    <FormActions t={t} submitting={submitting} cancelHref={backHref} />
  </Form>
);

EnviromentForm.propTypes = {
  handleSubmit: PropTypes.func,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default EnviromentForm;
