import moment from 'moment';
import { CHART_COLORS } from '~/common/chart';
import { CounterStatsPayload } from '../stats/types';
import { CounterStatsData } from './types';

export const getNextAvailableCounterNumber = (counterNumbers: number[]): number => {
  let min = 1;
  while (counterNumbers.includes(min)) {
    min += 1;
  }
  return min;
};

export const generateRangeQueryParams = (startDate: number, endDate: number): string =>
  `rangeFrom=${startDate}&rangeTo=${endDate}`;

const getColorByIndex = (index: number) => Object.values(CHART_COLORS)[index];

export const parseCounterStats = (result: CounterStatsPayload): CounterStatsData => {
  const stats = Object.entries(result).map(([key, counterStats], index) => {
    const requestCounts = counterStats.map((stat: { requestCount: number }) => stat.requestCount);
    const timestamp = counterStats.map((stat: { key: { dayTimestamp: moment.MomentInput } }) =>
      moment(stat.key.dayTimestamp).format('D. MMM'),
    );

    const series = {
      name: key,
      data: requestCounts,
      color: getColorByIndex(index + 1),
      timestamp,
    };

    const totalStats = requestCounts.reduce((sum: number, count: number) => sum + count, 0);
    const tableStats = {
      totalStats,
      name: key,
    };
    return { series, tableStats };
  });

  const series = stats.map(stat => stat.series);
  const tableStats = stats.map(stat => stat.tableStats);
  const timestamp = series.length ? series?.[0]?.timestamp : [];

  return {
    series,
    tableStats,
    timestamp,
  };
};
