export default {
  stepAnalysis: [
    {
      nodeName: 'Experiment',
      experimentVariantName: 'Experiment Variant',
      profilesEnteredHp: 272189,
      profilesEntered: 63120,
      profilesExited: 37221,
      profilesRemoved: 642,
      profilesExpired: 326411,
      profilesMerged: 431,
      profilesMovedToNextSteps: 8731,
      profilesActive: 74222,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 9642,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 8941,
        },
      ],
    },
    {
      nodeName: 'Show link to call center information',
      experimentVariantName: 'Experiment Variant',
      profilesEnteredHp: 0,
      profilesEntered: 4429,
      profilesExited: 4426,
      profilesRemoved: 142,
      profilesExpired: 4245,
      profilesMerged: 0,
      profilesMovedToNextSteps: 267,
      profilesActive: 2140,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 7677,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 7893,
        },
      ],
    },
    {
      nodeName: 'Show digital solution page',
      experimentVariantName: 'Experiment Variant',
      profilesEnteredHp: 0,
      profilesEntered: 35658,
      profilesExited: 3765,
      profilesRemoved: 0,
      profilesExpired: 3567,
      profilesMerged: 0,
      profilesMovedToNextSteps: 0,
      profilesActive: 0,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 9156,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 2245,
        },
      ],
    },
    {
      nodeName: 'Control group',
      experimentVariantName: 'Control group Variant',
      profilesEnteredHp: 0,
      profilesEntered: 0,
      profilesExited: 0,
      profilesRemoved: 0,
      profilesExpired: 0,
      profilesMerged: 0,
      profilesMovedToNextSteps: 0,
      profilesActive: 0,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 0,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 0,
        },
      ],
    },
  ],
  experimentAnalysis: [
    {
      experimentVariantName: 'Experiment Variant',
      profilesEnteredHp: 0,
      profilesEntered: 42569,
      profilesExited: 4656,
      profilesRemoved: 561,
      profilesExpired: 4556,
      profilesMerged: 0,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 9156,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 0,
        },
      ],
    },
    {
      experimentVariantName: 'Control group Variant',
      profilesEnteredHp: 0,
      profilesEntered: 3854,
      profilesExited: 3457,
      profilesRemoved: 0,
      profilesExpired: 3734,
      profilesMerged: 0,
      goals: [
        {
          name: 'Journey Goal',
          profilesReachedGoal: 134,
        },
      ],
      exitConditions: [
        {
          name: 'Journey Exit',
          profilesExitedByCondition: 0,
        },
      ],
    },
  ],
  journeyAnalysis: {
    journeyId: '68676371-3d8c-405f-b37a-fc0a1ba1d967',
    profilesEntered: 254347,
    profilesReachedGoals: 26475,
    profilesExited: 42941,
    profilesActive: 76362,
  },
  journeyName: 'Demo Journey',
} as any;
