import { get } from 'lodash';

/**
 * Get All Variables
 * @param state
 */
export const getVariables = state => state.profiles.variables.allIds.map(id => state.profiles.variables.byId[id]);

/**
 * Is fetching all variables currently in progress?
 * @param state
 */
export const isFetchingVariables = state => get(state, 'profiles.variables.ui.isFetchingAll');

/**
 * Is fetching all valueSets currently in progress?
 * @param state
 */
export const variablesNewSyncErrors = state => get(state, 'form.variablesNew.syncErrors');

/**
 * Is the dependants container visible?
 * @param state
 */
export const isDependantsContainerVisible = state => get(state, 'profiles.variables.ui.dependantsContainerIsVisible');

/**
 * Get the currently selected variable
 * @param state
 */
export const getSelectedVariableId = state => get(state, 'profiles.variables.ui.selectedVariableId');

/**
 * Get the currently selected tab
 * @param state
 */
export const getSelectedTab = state => get(state, 'profiles.variables.ui.selectedTab');

/**
 * Get Variable By Id
 * @param state
 * @param variableId
 * @returns {*}
 */
export const getVariableById = (state, variableId) => get(state, `profiles.variables.byId[${variableId}]`);
