import { combineReducers } from 'redux';
import * as types from './types';

const actionDefault = {
  payload: {},
};

/**
 * Groups Objects
 * @param state
 * @param action
 */
const byId = (state = {}, action = actionDefault) => {
  let version;
  let oldVariant;
  let oldVariantVersion;
  switch (action.type) {
    case types.VARIANT_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      version = action.sequenceNr ? action.sequenceNr : 'latest';
      return {
        ...state,
        [action.payload.variantId]: {
          [version]: {
            ...action.payload,
            incrementVersion: false,
          },
        },
      };
    case types.DELETE_SUCCESS:
      version = action.sequenceNr ? action.sequenceNr : 'latest';
      oldVariant = state[action.payload.variantId] || {};
      oldVariantVersion = oldVariant[version];
      return {
        ...state,
        [action.payload.variantId]: {
          [version]: {
            ...oldVariantVersion,
            deleted: true,
          },
        },
      };
    case types.LOCK_SUCCESS:
      version = action.sequenceNr ? action.sequenceNr : 'latest';
      oldVariant = state[action.payload.variantId] || {};
      oldVariantVersion = oldVariant[version];
      return {
        ...state,
        [action.payload.variantId]: {
          [version]: {
            ...oldVariantVersion,
            locked: action.payload.locked,
          },
        },
      };
    default:
      return state;
  }
};

/**
 * Groups Look Up Table
 * @param state
 * @param action
 */
const allIds = (state = [], action = actionDefault) => {
  switch (action.type) {
    case types.VARIANT_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      if (state.indexOf(action.payload.variantId) !== -1) {
        return state;
      }
      return [...state, action.payload.variantId];
    default:
      return state;
  }
};

const history = (state = {}, action = actionDefault) => {
  switch (action.type) {
    case types.VARIANT_HISTORY_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.variantId]: action.payload.list,
      };
    default:
      return state;
  }
};

/**
 * Tag Variant Execution Types
 * @param state
 * @param action
 * @returns {Array}
 */
const executionTypes = (state = [], action = actionDefault) => {
  switch (action.type) {
    case types.EXECUTION_TYPES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const variantsReducer = combineReducers({
  byId,
  allIds,
  history,
  executionTypes,
});

export default variantsReducer;
