angular.module('webUi.service.tagmanagement.websitePathGroup', [ 
    'restangular',
    'webUi.service.pubSub',
    'webUi.common.Utils'
])

    .factory('WebsitePathGroupService', [ 'Restangular', '$q', 'Utils', 'PubSubService', function(Restangular, $q, Utils, PubSubService) {
        var service =  {

            /**
		 * Retrieve WebsitePathGroups
		 */
            getWebsitePathGroup : function(groupId) {
                var deferredList = $q.defer();
                Restangular.one('tagmanagement/websitePathGroups', groupId).get().then(function(res) {
                    deferredList.resolve(Utils.sanitizeRestangularOne(res));
                });
                return deferredList.promise;
            },

            /**
		 * Retrieves all WebsitePathGroups
		 */
            getWebsitePathGroups : function() {
                var deferredList = $q.defer();
                Restangular.all('tagmanagement/websitePathGroups').getList().then(function(res) {
                    deferredList.resolve(Utils.sanitizeRestangularAll(res));
                });
                return deferredList.promise;
            },

            /**
		 * Saves a new WebsitePathGroup
		 * 
		 * @param {websitePathGroup} The WebsitePathGroup object
		 */
            addWebsitePathGroup : function(websitePathGroup) {
                return Restangular.all('tagmanagement/websitePathGroups').post({
                    name : websitePathGroup.name,
                    websitePaths : []
                });
            },

            /**
		 * Adds a new path to a WebsitePathGroup
		 * 
		 * @param {websitePathGroupId} The WebsitePathGroup object id
		 */
            addPathToWebsitePathGroup : function(websitePathGroupId, path) {
                return Restangular.all('tagmanagement/websitePathGroups/paths').post({
                    websitePathGroupId : websitePathGroupId,
                    websitePaths : [ path ]
                });
            },

            /**
		 * Updates whether a path in this websitePathGroup should be included
		 * with all of its subchildren or not
		 * 
		 * @param websitePathGroupId the WebsitePathGroup id
		 * @param path the string path, for identification
		 * @param the new value for the boolean, true/false
		 */
            updateIncludeChildrenForPath : function(websitePathGroupId, path, includeChildren) {
                return Restangular.all('tagmanagement/websitePathGroups/includeChildren').post({
                    websitePathGroupId : websitePathGroupId,
                    path : path,
                    includeChildren : includeChildren
                });
            },
		
            /**
		 * Updates whether a path in this websitePathGroup should be included
		 * with all of its subchildren or not
		 * 
		 * @param websitePathGroupId the WebsitePathGroup id
		 * @param path the string path, for identification
		 * @param the new value for the boolean, true/false
		 */
            updateName : function(websitePathGroupId, name) {
                return Restangular.all('tagmanagement/websitePathGroups/name').post({
                    websitePathGroupId : websitePathGroupId,
                    name : name
                });
            },

            /**
		 * Removes a path from a websitePathGroup
		 * 
		 * @param websitePathGroupId the WebsitePathGroup id
		 * @param path the string path, for identification
		 */
            removePath : function(websitePathGroupId, path) {
                return Restangular.all('tagmanagement/websitePathGroups/removePath').post({
                    websitePathGroupId : websitePathGroupId,
                    websitePath : path
                });
            },
		
            removeWebsitePathGroup : function(websitePathGroupId){
                return Restangular.one('tagmanagement/websitePathGroups', websitePathGroupId).remove();
            }
        };
	
        Utils.wrapModifierFunctions(service, function(){
            PubSubService.publishEnvironmentChange();
        });
	
        return service;
    } ]);
