angular.module('webUi.ui.test.validation.dashboard',[
    'webUi.ui.test.validation.base'
])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.test.validation.dashboard', {
                url : '/dashboard',
                leftMenu : true,
                views : {
                    '@site.test': {
                        controller: 'ValidationDashboardController as vm',
                        templateUrl: 'ui/test/validation/dashboard/dashboard.tpl.html'
                    }
                },
                resolve: { }
            }
            );
    }
    ])

    .controller('ValidationDashboardController', [ 'PubSubService', function ValidationDashboardController(PubSubService) {
        PubSubService.publishTypeSelected(PubSubService.TYPES.SUPPORT.TEST, {id: null});
    }]);
