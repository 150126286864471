import React, { useState } from 'react';
import cx from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import { changeUrl } from '~/common';
import { Link, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { toggleTagStatus } from '../dataService';
import { TLinkedTag, TPageResult } from '../types';

const columnHelper = createColumnHelper<TPageResult>();

const renderUsageIcons = (conditions: any, tagId: string) => {
  const goToConditions = () =>
    changeUrl(`tagmanagement/tags/view/${tagId}?autoOpenConditions=true&identifierParam=undefined`, false, true);
  return (
    <div className="flex justify-center gap-2">
      <BtnIcon
        icon="cookiepermission"
        color={conditions?.COOKIEPERMISSION?.active ? 'blue' : 'gray'}
        disabled={!conditions?.COOKIEPERMISSION?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.cookiePermission')}
      />
      <BtnIcon
        icon="experiment"
        color={conditions?.EXPERIMENT?.active ? 'blue' : 'gray'}
        disabled={!conditions?.EXPERIMENT?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.experiment')}
      />
      <BtnIcon
        icon="deduplication"
        color={conditions?.DEDUPLICATION?.active ? 'blue' : 'gray'}
        disabled={!conditions?.DEDUPLICATION?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.deduplication')}
      />
      <BtnIcon
        icon="event"
        color={conditions?.EVENT?.active ? 'blue' : 'gray'}
        disabled={!conditions?.EVENT?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.event')}
      />
      <BtnIcon
        icon="selection"
        color={conditions?.SELECTION?.active ? 'blue' : 'gray'}
        disabled={!conditions?.SELECTION?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.selection')}
      />
      <BtnIcon
        icon="journey"
        color={conditions?.JOURNEY?.active ? 'blue' : 'gray'}
        disabled={!conditions?.JOURNEY?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.journey')}
      />
      <BtnIcon
        icon="visitorbased"
        color={conditions?.VISITORBASED?.active ? 'blue' : 'gray'}
        disabled={!conditions?.VISITORBASED?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.visitorBased')}
      />
      <BtnIcon
        icon="pagebased"
        color={conditions?.PAGEBASED?.active ? 'blue' : 'gray'}
        disabled={!conditions?.PAGEBASED?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.pageBased')}
      />
      <BtnIcon
        icon="other"
        color={conditions?.OTHER?.active ? 'blue' : 'gray'}
        disabled={!conditions?.OTHER?.active}
        onClick={() => goToConditions()}
        tooltip={i18n.t('experiments:linkedTags.tooltips.other')}
      />
    </div>
  );
};

export const LinkedTags = ({ linkedTags }: { linkedTags: TLinkedTag }) => {
  const [rows, setRows] = useState<TPageResult[]>(linkedTags.pageResults);

  const handleToggleStatus = (id: number, status: boolean, tagId: string) => {
    const payload = { tagId, enabled: !status };
    toggleTagStatus(payload).then(() => {
      const newRows = [...rows];
      newRows[id].status = !status;
      setRows(newRows);
    });
  };

  const getColumns = () => [
    columnHelper.accessor('priority', {
      header: () => <div>{i18n.t('experiments:linkedTags.priority')}</div>,
      cell: info => <div>{info.getValue()}</div>,
      enableSorting: true,
      size: 50,
    }),
    columnHelper.accessor('name', {
      header: () => <span>{i18n.t('experiments:linkedTags.name')}</span>,
      cell: info => (
        <Link
          className={cx('t-tagName', { '!text-gray-300': !info.row.original.status })}
          onClick={() => changeUrl(`tagmanagement/tags/view/${info.row.original.tagId}`, false, true)}
        >
          {info.getValue()}
        </Link>
      ),
      enableSorting: true,
      size: 400,
    }),
    columnHelper.accessor('path', {
      header: () => <span>{i18n.t('experiments:linkedTags.source')}</span>,
      cell: info => (
        <Link
          className={cx('t-tagSource', { '!text-gray-300': !info.row.original.status })}
          onClick={() => changeUrl(`tagmanagement/tags/list/${info.getValue()}`, false, true)}
        >
          {info.getValue()?.replaceAll('|', ' > ')}
        </Link>
      ),
      enableSorting: false,
      size: 250,
    }),
    columnHelper.accessor('status', {
      header: () => <div className="text-center">{i18n.t('experiments:linkedTags.status')}</div>,
      cell: info => (
        <div className="text-center">
          <BtnIcon
            onClick={() => handleToggleStatus(parseInt(info.row.id, 10), info.getValue(), info.row.original.tagId)}
            icon={info.getValue() ? 'pause' : 'play'}
            tooltip={i18n.t(`experiments:linkedTags.statusAction.${info.getValue() ? 'disable' : 'enable'}`)}
            testHook="toggleTagStatus"
          />
        </div>
      ),
      enableSorting: false,
      size: 100,
    }),
    columnHelper.accessor('conditions', {
      header: () => <div className="text-center">{i18n.t('experiments:linkedTags.rules')}</div>,
      cell: info => renderUsageIcons(info.getValue(), info.row.original.tagId),
      enableSorting: false,
    }),
  ];

  return (
    <>
      {!rows.length ? (
        <Notification kind="information" testHook="noLinkedTags">
          {i18n.t('experiments:messages.noLinkedTags')}
        </Notification>
      ) : (
        <UITable testHook="linkedTags" data={rows} columns={getColumns()} enableSorting />
      )}
    </>
  );
};
