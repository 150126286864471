import { getAdvertisers, getUserIdTypes } from '../../connectorFormService';
import connectorFormActionTypes from '../../connectorFormActionTypes';

const doubleClickActionTypes = {
  FETCH_ADVERTISERS: {
    pending: 'FETCH_ADVERTISERS/pending',
    fulfilled: 'FETCH_ADVERTISERS/fulfilled',
    rejected: 'FETCH_ADVERTISERS/rejected',
  },
  FETCH_USER_ID_TYPES: {
    pending: 'FETCH_USER_ID_TYPES/pending',
    fulfilled: 'FETCH_USER_ID_TYPES/fulfilled',
    rejected: 'FETCH_USER_ID_TYPES/rejected',
  },
  SET_ADVERTISERS: 'SET_ADVERTISERS',
  SET_USER_ID_TYPES: 'SET_USER_ID_TYPES',
};

const fetchAdvertisers = partner => dispatch => {
  dispatch({
    name: doubleClickActionTypes.FETCH_ADVERTISERS.pending,
    type: connectorFormActionTypes.DOUBLE_CLICK,
  });

  return getAdvertisers(partner)
    .then(payload => {
      dispatch({
        name: doubleClickActionTypes.FETCH_ADVERTISERS.fulfilled,
        type: connectorFormActionTypes.DOUBLE_CLICK,
      });
      dispatch({
        name: doubleClickActionTypes.SET_ADVERTISERS,
        data: payload.data,
        type: connectorFormActionTypes.DOUBLE_CLICK,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.DOUBLE_CLICK,
        name: doubleClickActionTypes.FETCH_ADVERTISERS.rejected,
      });
    });
};

const fetchUserIdTypes = partner => dispatch => {
  dispatch({
    name: doubleClickActionTypes.FETCH_USER_ID_TYPES.pending,
    type: connectorFormActionTypes.DOUBLE_CLICK,
  });

  return getUserIdTypes(partner)
    .then(payload => {
      dispatch({
        name: doubleClickActionTypes.FETCH_USER_ID_TYPES.fulfilled,
        type: connectorFormActionTypes.DOUBLE_CLICK,
      });
      dispatch({
        name: doubleClickActionTypes.SET_USER_ID_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.DOUBLE_CLICK,
      });
    })
    .catch(() => {
      dispatch({
        name: doubleClickActionTypes.FETCH_USER_ID_TYPES.rejected,
        type: connectorFormActionTypes.DOUBLE_CLICK,
      });
    });
};

export { doubleClickActionTypes, fetchAdvertisers, fetchUserIdTypes };
