angular.module('webUi.ui.profiles.partners.stats',[])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.profiles.partners.list.partnerStats', {
                url: '/stats/{partnerId}',
                isReact: true
            });

        $stateProvider
            .state('site.profiles.partners.list.eventPartnerStats', {
                url: '/eventStats/{partnerId}',
                isReact: true
            });
    }]);
