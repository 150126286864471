import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DropzoneElement from '~/components/src/Form/Elements/DropzoneElement';
import './Dropzone.scss';

const Dropzone = ({ disabled, input, placeholder, currentLogo, className, errorMessage, ...other }) => {
  const [isError, setIsError] = useState(false);

  const files = input.value;
  let file;

  switch (files && typeof files) {
    case 'string':
      file = {
        preview: currentLogo,
        name: files,
      };
      break;
    case 'object':
      [file] = files;
      break;
  }

  const infoPart = file ? (
    <div className="Dropzone-preview">
      <span className="Dropzone-title">{file.name}</span>
      <img src={file.preview} alt={file.name} className="Dropzone-preview-image" />
    </div>
  ) : null;

  const descPart = (
    <div>
      <p>{isError ? errorMessage : placeholder}</p>
    </div>
  );

  return (
    <DropzoneElement
      {...other}
      className={cx('Element--dropzone Dropzone', className, isError ? 'is-error' : '')}
      disabled={disabled}
      accept="image/jpeg, image/png"
      maxSize={10000000} // 10 MB
      multiple={false}
      name={input.name}
      onDropAccepted={acceptedFiles => {
        setIsError(false);
        input.onChange(acceptedFiles);
      }}
      onDropRejected={() => {
        setIsError(true);
        // Cleaning input removes the preview
        input.onChange('');
      }}
    >
      {infoPart}
      {descPart}
    </DropzoneElement>
  );
};

Dropzone.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.any,
  }),
  placeholder: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  className: PropTypes.string,
};

export default Dropzone;
