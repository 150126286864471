import React, { ReactElement } from 'react';
import { getConnectorLogoUrl } from '~/profiles/helpers/connectorsHelper';
import { changeUrl } from '~/common';
import { TransformationDependentType } from './types';

function TransformerDependency({ dependency }: { dependency: TransformationDependentType }): ReactElement {
  return (
    <div
      className="t-transformationDependants flex h-16 cursor-pointer items-center gap-4 rounded border border-gray-300 bg-white px-4 py-1 hover:bg-gray-50"
      onClick={() => changeUrl(`profiles/partners/editEvent/${dependency.partnerId}`)}
    >
      <img
        alt={dependency.partnerName}
        className="!w-10"
        src={getConnectorLogoUrl(dependency.partnerId, dependency.partnerType, true) || ''}
      />
      <div>
        <p className="text-lg font-medium text-gray-800">{dependency.partnerName}</p>
      </div>
    </div>
  );
}

export default TransformerDependency;
