angular.module('webUi.ui.help.basescript', ['webUi.ui.help.base'])


    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.help.basescript', {
                url : '/basescript',
                views : {
                    '@site.help' : {
                        templateUrl : 'ui/help/basescript/basescript.tpl.html',
                        controller: 'HelpBasescriptController'
                    }
                },
                leftMenu : false,
                resolve: {
                    basescriptCode: ['TagmanagementService', function(TagmanagementService){
                        return TagmanagementService.getBasescriptCode();
                    }],
                    defaultBasescriptCode: ['TagmanagementService', function(TagmanagementService){
                        return TagmanagementService.BASESCRIPT;
                    }]
                }
            });
    }])

    .controller('HelpBasescriptController', ['$scope', 'basescriptCode', 'defaultBasescriptCode',
        function HelpBasescriptController(    $scope,   basescriptCode,  defaultBasescriptCode){
	
            $scope.nonEditable = {
                readOnly: true
            };		
            $scope.basescript = {
                code: basescriptCode,
                defaultCode: defaultBasescriptCode
            };
        }]);

