import React from 'react';
import Page from '~/components/src/Page';
import { SmallWrapper } from '~/components/src/Containers';
import { PasswordPolicyContainer } from '~/context/clients/containers';
import { useParams } from 'react-router-dom';

const PasswordPolicyPage = ({ isContextAdmin }) => {
  const { clientId } = useParams();
  return (
    <Page>
      <SmallWrapper>
        <PasswordPolicyContainer isContextAdmin={isContextAdmin} clientId={clientId} />
      </SmallWrapper>
    </Page>
  );
};

export default PasswordPolicyPage;
