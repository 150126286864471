import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners`;

const getAppNexusSegmentsListURL = partnerId => `${BASE_PATH}/appnexus/${partnerId}/segments`;

const getAppNexusSegmentsList = partnerId =>
  Api.callGet(getAppNexusSegmentsListURL(partnerId), { shouldShowToast: false }).then(parseResponse);

export default getAppNexusSegmentsList;
