export const USER_CLIENTS_FETCH_START = 'app/context/users/USER_CLIENTS_FETCH_START';
export const USER_CLIENTS_FETCH_FAIL = 'app/context/users/USER_CLIENTS_FETCH_FAIL';
export const USER_CLIENTS_FETCH_SUCCESS = 'app/context/users/USER_CLIENTS_FETCH_SUCCESS';

export const ADD_CLIENT_MANAGER_START = 'app/context/users/ADD_CLIENT_MANAGER_START';
export const ADD_CLIENT_MANAGER_FAIL = 'app/context/users/ADD_CLIENT_MANAGER_FAIL';
export const ADD_CLIENT_MANAGER_SUCCESS = 'app/context/users/ADD_CLIENT_MANAGER_SUCCESS';

export const REMOVE_CLIENT_MANAGER_START = 'app/context/users/REMOVE_CLIENT_MANAGER_START';
export const REMOVE_CLIENT_MANAGER_FAIL = 'app/context/users/REMOVE_CLIENT_MANAGER_FAIL';
export const REMOVE_CLIENT_MANAGER_SUCCESS = 'app/context/users/REMOVE_CLIENT_MANAGER_SUCCESS';

export const USER_PROMOTE_LIST = 'app/context/users/USER_PROMOTE_LIST';
