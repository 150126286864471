import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import Checkbox from '../Elements/Checkbox';
import './CheckboxGroup.scss';

export * from '../Elements/defaultSizes';

const CheckboxGroup = ({ isControlled = false, meta, input, label, options, disabled }) => {
  // Array with the existing elements {tags: true, data: false}
  const currentElements = input.value;
  const inputProps = value =>
    isControlled
      ? {
          value,
        }
      : {
          defaultChecked: value,
        };

  return (
    <FormRow disabled={true} name="" label={label} meta={meta}>
      {options.map(option => (
        <div className={`CheckboxGroup t-${option.name}CheckboxGroup`} key={option.name}>
          <Checkbox
            disabled={disabled}
            input={{
              name: option.name,
              onChange: () => {
                currentElements[option.name] = !currentElements[option.name];
                input.onChange(currentElements, option.name, currentElements[option.name]);
              },
              ...inputProps(!!currentElements[option.name]),
            }}
            id={option.name}
            isError={meta.touched && !!meta.error}
          >
            {option.label}
          </Checkbox>
        </div>
      ))}
    </FormRow>
  );
};

CheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  options: PropTypes.array,
  label: PropTypes.any.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default CheckboxGroup;
