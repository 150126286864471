/**
 * @description Angular module for UI
 * @namespace webUi.ui
 * @memberof webUi
 * @module webUi.ui
 */
angular.module('webUi.ui', [
  'webUi.ui.dashboard',
  'webUi.ui.context',
  'webUi.ui.error',
  'webUi.ui.globalui',
  'webUi.ui.iplock',
  'webUi.ui.siteState',
  'webUi.ui.tagmanagement',
  'webUi.ui.support',
  'webUi.ui.content',
  'webUi.ui.ai',
  'webUi.ui.profiles',
  'webUi.ui.audiences',
  'webUi.ui.user',
  'webUi.ui.admin',
  'webUi.ui.help',
  'webUi.ui.shared',
  'webUi.ui.test',
  'webUi.ui.workflows',
  ...(process.env.NODE_ENV === 'development' ? ['webUi.ui.dev'] : []),
]);
