angular.module('webUi.ui.content.campaigns.ads.conditions.dataFeed', [])

    .controller('AdServingDataFeedConditionController', ['$scope', function AdServingDataFeedConditionController($scope) {
    
        $scope.state.beforeSubmit = function(condition) {
            return condition;
        };
		
        $scope.state.submitCallback = function() {
            return false;
        };
    }
    ]);

