import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import * as types from './types';

/**
 * Messages Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.SERVER_CONNECTORS_FETCH_SUCCESS:
    case types.TAG_CONNECTORS_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(connector => {
        nextState[connector.partnerId] = connector;
      });
      return nextState;
    case types.DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload.partnerId];
      return nextState;
    case types.TOGGLE_ACTIVATION_SUCCESS:
      return {
        ...state,
        [action.payload.partnerId]: {
          ...state[action.payload.partnerId],
          activated: action.payload.activated,
        },
      };
    default:
      return state;
  }
};

/**
 * Messages Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.SERVER_CONNECTORS_FETCH_SUCCESS:
    case types.TAG_CONNECTORS_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(connector => {
        if (state.indexOf(connector.partnerId) === -1) {
          nextState.push(connector.partnerId);
        }
      });
      return nextState;
    case types.DELETE_SUCCESS:
      return state.filter(id => id !== action.payload.partnerId);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingServerConnectors: false,
  shouldFetchServerConnectors: true,
  isFetchingTagConnectors: false,
  shouldFetchTagConnectors: true,
  dependantsContainerIsVisible: false,
  selectedId: null,
  selectedTab: 0,
};
const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.SERVER_CONNECTORS_FETCH_START:
      return {
        ...state,
        isFetchingServerConnectors: true,
        shouldFetchServerConnectors: false,
      };
    case types.SERVER_CONNECTORS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingServerConnectors: false,
        shouldFetchServerConnectors: false,
      };
    case types.TAG_CONNECTORS_FETCH_START:
      return {
        ...state,
        isFetchingTagConnectors: true,
        shouldFetchTagConnectors: false,
      };
    case types.TAG_CONNECTORS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingTagConnectors: false,
        shouldFetchTagConnectors: false,
      };
    case types.SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedId: action.payload.connectorId,
        selectedTab: action.payload.selectedTab,
      };
    case types.HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedId: null,
        selectedTab: 0,
      };
    case types.DEPENDANTS_TYPE_TAB_CHANGE:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case types.CONNECTOR_TYPES_FETCH_START:
      return {
        ...state,
        isFetchingConnectorTypes: true,
      };
    case types.CONNECTOR_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        connectorTypes: action.payload,
        isFetchingConnectorTypes: false,
      };
    case types.CONNECTOR_TYPES_FETCH_FAIL:
      return {
        ...state,
        isFetchingConnectorTypes: false,
      };
    default:
      return state;
  }
};

const connectorsReducer = combineReducers({
  byId,
  allIds,
  ui,
});

const eventsById = (state = {}, action) => {
  switch (action.type) {
    case types.EVENT_CONNECTORS_FETCH_SUCCESS:
      return keyBy(action.payload, ({ eventConnectorId }) => eventConnectorId);
    case types.TOGGLE_EVENT_ACTIVATION_SUCCESS: {
      const { eventConnectorId, activated } = action.payload;

      return {
        ...state,
        [eventConnectorId]: {
          ...state[eventConnectorId],
          activated,
        },
      };
    }
    case types.DELETE_SUCCESS: {
      const nextState = { ...state };
      delete nextState[action.payload.eventConnectorId];
      return nextState;
    }
    default:
      return state;
  }
};

const isFetchingEvents = (state = false, action) => {
  switch (action.type) {
    case types.EVENT_CONNECTORS_FETCH_START:
      return true;
    case types.EVENT_CONNECTORS_FETCH_SUCCESS:
    case types.EVENT_CONNECTORS_FETCH_FAIL:
      return false;
    default:
      return state;
  }
};

export const eventConnectorsReducer = combineReducers({
  byId: eventsById,
  isFetching: isFetchingEvents,
});

export default connectorsReducer;
