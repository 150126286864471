import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';

import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { Heading, Notification, PickerView, Tooltip } from '~/components';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import { UITable } from '~/components/src/UITable';

import { createMapping, deleteMapping, updateMapping } from '../dataService';
import { UrlBuilderContext } from '../UrlBuilderContext';
import {
  MappingColumn,
  MappingDataType,
  CampaignMapping,
  MappingsProps,
  PredefinedValue,
  PredefinedValueSet,
} from '../types';
import { getPredefinedValue, getPredefinedValueSetById, prepareDataForExport } from '../utils';
import { COLUMN_TYPES } from '../constants';
import { URLGenerator } from './URLGenerator';

const columnHelper = createColumnHelper<CampaignMapping>();

const getActionsComponent = (
  row: Row<CampaignMapping>,
  hasUrlBuilderPermission: boolean,
  editMappingIds: string[],
  setEditMappingIds: (ids: string[]) => void,
  handleDeleteMapping: (mappingId: string) => void,
  handleUpdateMapping: (campaign: CampaignMapping) => Promise<void>,
) => {
  const { mappingId } = row.original;
  const editmode = editMappingIds.includes(mappingId);
  return (
    <div className="float-right flex w-[20%] items-center justify-end gap-2">
      <BtnIcon
        color="gray"
        disabled={!hasUrlBuilderPermission}
        icon={editmode ? 'done' : 'edit'}
        onClick={() =>
          editmode ? handleUpdateMapping(row.original) : setEditMappingIds([...editMappingIds, mappingId])
        }
        testHook="editUpdateMapping"
        tooltip={
          hasUrlBuilderPermission
            ? i18n.t(`common:actions.${editmode ? 'update' : 'edit'}`)
            : i18n.t('campaigns:noPermissions')
        }
      />
      <BtnIcon
        color="gray"
        disabled={!hasUrlBuilderPermission}
        icon="delete"
        onClick={() => handleDeleteMapping(mappingId)}
        testHook="deleteMapping"
        tooltip={i18n.t(hasUrlBuilderPermission ? 'common:actions.delete' : 'campaigns:noPermissions')}
      />
      {hasUrlBuilderPermission}
    </div>
  );
};

const renderMappingDataColumn = (
  columns: MappingColumn[],
  editMappingIds: string[],
  predefinedValues: PredefinedValue[],
) => {
  if (!columns) return [];
  const mappedColumns = columns?.map(column =>
    columnHelper.accessor(`mappingData.${column.columnId}`, {
      id: `mappingData.${column.columnId}`,
      header: () => <Tooltip tooltip={column.description}>{column.name}</Tooltip>,
      cell: ({ row }) => {
        const { mappingId, mappingData } = row.original;
        const editmode = editMappingIds.includes(mappingId);

        switch (column.columnType.name) {
          case COLUMN_TYPES.USER_INPUT:
            return editmode ? (
              <InputElement
                type="text"
                id={`mappingData.${column.columnId}`}
                defaultValue={mappingData[column.columnId]}
                onChange={e => {
                  mappingData[column.columnId] = e.target.value;
                }}
              />
            ) : (
              <span className={`t-mappingData.${column.columnId}`}>{mappingData[column.columnId] || 'N/A'}</span>
            );
          case COLUMN_TYPES.PREDEFINED_VALUES: {
            const predefinedValue = getPredefinedValue(
              column.defaultValue,
              mappingData[column.columnId],
              predefinedValues,
            );

            return editmode ? (
              <SelectElement
                id={`mappingData.${column.columnId}`}
                options={getPredefinedValueSetById(column.defaultValue, predefinedValues)}
                getOptionLabel={(option: PredefinedValueSet) => option.label}
                getOptionValue={(option: PredefinedValueSet) => option.value}
                defaultValue={predefinedValue}
                onChange={(option: PredefinedValueSet) => {
                  mappingData[column.columnId] = option.id;
                }}
              />
            ) : (
              <span className={`t-mappingData.${column.columnId} `}>{predefinedValue?.label || 'N/A'}</span>
            );
          }
          default:
            return (
              <span className={`t-mappingData.${column.columnId} font-mono text-sm text-gray-500`}>
                {mappingData[column.columnId] || 'N/A'}
              </span>
            );
        }
      },
    }),
  );

  return mappedColumns;
};

export const Mappings = ({
  campaignMappings,
  campaignDetails,
  refetchCampaignMappings,
}: MappingsProps): ReactElement => {
  const [editMappingIds, setEditMappingIds] = useState<string[]>([]);
  const [urlGeneratorMappingKey, setUrlGeneratorMappingKey] = useState('');

  const { hasUrlBuilderPermission, predefinedValues } = useContext(UrlBuilderContext);
  const { campaignId, filteredColumns, urlBuilderTypeId } = campaignDetails;

  const handleDeleteMapping = async (mappingId: string) => {
    await deleteMapping(campaignId, mappingId);
    refetchCampaignMappings();
  };

  const handleExport = () => {
    prepareDataForExport(campaignDetails, campaignMappings, predefinedValues);
  };

  const handleCreateNewMapping = async () => {
    const mappingData: MappingDataType = {};

    filteredColumns.forEach((column: MappingColumn) => {
      mappingData[column.columnId] = column.defaultValue ? column.defaultValue : '';
    });

    const newMapping = {
      campaignId,
      urlBuilderTypeId,
      mappingData,
    };
    await createMapping(newMapping);
    refetchCampaignMappings();
  };

  const handleUpdateMapping = async (columns: CampaignMapping) => {
    await updateMapping(columns);
    // disable editMode and remove from editMappingIds
    setEditMappingIds(state => state.filter(id => id !== columns?.mappingId));
  };

  const mappedDataColumns = renderMappingDataColumn(
    filteredColumns,
    editMappingIds,
    predefinedValues,
  ) as ColumnDef<CampaignMapping>[];

  const getColumns = () => [
    columnHelper.accessor('mappingKey', {
      header: () => <span>{i18n.t('campaigns:mappings.key')}</span>,
      cell: info => (
        <span
          className="t-mappingKey w-full cursor-pointer text-r42-blue"
          onClick={() => {
            setUrlGeneratorMappingKey(info.getValue());
          }}
        >
          {info.getValue()}
        </span>
      ),
    }),
    ...mappedDataColumns,
    columnHelper.accessor('actions', {
      id: 'actions',
      cell: ({ row }) =>
        getActionsComponent(
          row,
          hasUrlBuilderPermission,
          editMappingIds,
          setEditMappingIds,
          handleDeleteMapping,
          handleUpdateMapping,
        ),
      header: () => <></>,
    }),
  ];

  const [filteredRows, setFilteredRows] = useState(campaignMappings);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setFilteredRows(campaignMappings);
  }, [campaignMappings]);

  return (
    <>
      <Heading className="py-0" testHook="mappingHeading" title={i18n.t('campaigns:form.mappings')}>
        <SearchElement
          testHook="mappingKeySearch"
          value={query}
          onChange={e => {
            setQuery(e.target.value);
            setFilteredRows(
              campaignMappings.filter(mapping =>
                mapping.mappingKey.toLowerCase().includes(e.target.value.toLowerCase()),
              ),
            );
          }}
          placeholder={i18n.t('campaigns:searchMappings')}
        />
        <BtnIcon
          icon="download"
          tooltip={i18n.t('campaigns:tooltips.export')}
          testHook="exportMappings"
          disabled={!hasUrlBuilderPermission}
          onClick={() => handleExport()}
        />
        <BtnIcon
          icon="add"
          color="blue"
          tooltip={i18n.t('campaigns:tooltips.addMapping')}
          testHook="addMappings"
          disabled={!hasUrlBuilderPermission}
          onClick={() => handleCreateNewMapping()}
        />
      </Heading>
      {!campaignMappings?.length ? (
        <Notification kind="information" testHook="noMappings">
          {i18n.t('campaigns:messages.noMappings')}
        </Notification>
      ) : (
        <UITable testHook="mappingTable" data={filteredRows} columns={getColumns()} />
      )}
      {urlGeneratorMappingKey && (
        <PickerView
          handlePickerVisibility={() => {
            setUrlGeneratorMappingKey('');
          }}
          pickerTitle={i18n.t('campaigns:urlGenerator.header')}
          className="!h-auto !w-[40%]"
        >
          <URLGenerator
            hideModal={() => setUrlGeneratorMappingKey('')}
            urlBuilderTypeId={urlBuilderTypeId}
            mappingKey={urlGeneratorMappingKey}
          />
        </PickerView>
      )}
    </>
  );
};
