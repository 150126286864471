angular.module('webUi.ui.tagmanagement.tags.view.right', ['webUi.service'
])
    .controller('TagViewRightController', [
        '$scope',
        '$uibModal',
        '$state',
        '$timeout',
        '$filter',
        'TagmanagementService',
        'TagmanagementServiceHelper',
        'CookiePermissionService',
        'TagmanagementEnvironmentService',
        'Utils',
        'ISO3166',
        'TimeService',
        'JourneyService',
        'SecurityService',
        /**
         *
         * @param $scope
         * @param $uibModal
         * @param $state
         * @param $timeout
         * @param $filter
         * @param {TagmanagementService} TagmanagementService
         * @param CookiePermissionService
         * @param TagmanagementEnvironmentService
         * @param Utils
         * @param ISO3166
         */
        function TagViewRightController(
            $scope,
            $uibModal,
            $state,
            $timeout,
            $filter,
            TagmanagementService,
            TagmanagementServiceHelper,
            CookiePermissionService,
            TagmanagementEnvironmentService,
            Utils,
            ISO3166,
            TimeService,
            JourneyService,
            SecurityService) {

            var tagViewPojo = $scope.tagViewPojo;

            function initConditions() {
                if (!tagViewPojo || !tagViewPojo.tag || !tagViewPojo.tag.conditions) { return; }
                $scope.$watchCollection('tagViewPojo.tag.conditions', (newValue) => {
                    initJourney(newValue);
                });
            }

            initConditions();

            function initJourney(conditions) {
                var inJourneyData;
                var journeyId;
                var notInJourneyId;
                var condition = _.findWhere(conditions, {clazz: 'JourneyCondition'});

                // Leave if no Journey condition defined
                if (!condition) { return; }
                notInJourneyId = condition.notInJourneys[0];
                inJourneyData = condition.inJourneyConditionData[0];
                journeyId = notInJourneyId || condition.inJourneys[0] || inJourneyData && inJourneyData.journeyId;

                // Leave if empty condition
                if (!journeyId) { return; }
                condition.journeyId = journeyId;
                condition.isNotInJourney = !!notInJourneyId;
                if (inJourneyData) {
                    condition.stepId = inJourneyData.stepId;
                    condition.isInStep = inJourneyData.isInStep;
                }

                // Fetch Journey info
                JourneyService.getJourney(journeyId)
                    .then((journey) => {
                        condition.journey = journey;
                    });

                // Fetch Step info if defined
                if (condition.stepId) {
                    JourneyService.getStep(condition.stepId)
                        .then((step) => {
                            condition.step = step;
                        });
                }
            }

            $scope.status = {
                showLocation: true,
                showRules: true
            };


            SecurityService.getSecurityContext().then(function(securityContext) {
                if (securityContext) {
                    $scope.hasAudiencesView = securityContext?.hasModule('AUDIENCES') && securityContext?.hasPermission('AUDIENCES_VIEW');
                    $scope.hasJourneyView = securityContext?.hasModule('CUSTOMER_JOURNEY') && securityContext?.hasPermission('WORKFLOW_VIEW');
                }
            });

            $scope.convertTz = function convertTz(time) {
                return TimeService.convertWithTz(time, TimeService.CONVERSION_DIRECTION.FROM_SITE_TZ_TO_LOCAL_TZ);
            };
            $scope.countryMap = ISO3166;

            TagmanagementService.getReadableLocation(tagViewPojo.tag.location).then(function (location) {
                $scope.readableLocation = location;
            });

            TagmanagementService.getSegments().then(function (segments) {
                $scope.segments = segments;
            });
            $scope.getSegment = function (segmentId, segments) {
                return _.find(segments, {segmentId: segmentId});
            };

            CookiePermissionService.getCookiePermissionGroups().then(function (groups) {
                $scope.cookiePermissionGroups = groups;
                $scope.getCookiePermissionGroupName = function (groupId) {
                    var gr = _.find($scope.cookiePermissionGroups, function (group) {
                        return group.groupId === groupId;
                    });
                    return gr.name;
                };
            });

            $scope.environments = {};
            var LOCATIONS = $scope.locationTypes = TagmanagementService.LOCATIONS;

            //build a map of environmentCode to environmentName for friendly output
            TagmanagementEnvironmentService.getAll().then(function (environments) {
                for (var i = 0; i < environments.length; i++) {
                    $scope.environments[environments[i].environmentId] = environments[i].environmentName;
                }
            });

            $scope.toggleInheritenance = function () {
                var newLocation = {
                    clazz: tagViewPojo.tag.location.clazz === LOCATIONS.PATHHIERARCHY ? LOCATIONS.SINGLEPATH : LOCATIONS.PATHHIERARCHY,
                    path: tagViewPojo.tag.location.path
                };

                // update location
                TagmanagementService.updateLocation(tagViewPojo.tag.tagId, newLocation).then(function (modification) {
                    tagViewPojo.tag.location = newLocation;
                    TagmanagementService.getReadableLocation(newLocation).then(function (location) {
                        $scope.readableLocation = location;
                        $scope.handleModification(modification);
                    });
                });
            };

            $scope.openConditionDialog = function () {
                var conditionModal = $uibModal.open({
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: 'modal-huge',
                    templateUrl: 'ui/tagmanagement/tags/conditions/configure.tpl.html',
                    controller: 'TagManagementConditionConfigureDialogController as vm',
                    resolve: {
                        dialogsModel: function () {
                            return {
                                tagViewPojo: tagViewPojo,
                                conditionGroups: $scope.conditionGroups || {}
                            };
                        }
                    }
                });
                conditionModal.result.then(function (modification) {
                    if (modification) {
                        $scope.handleModification(modification);
                    }
                });
            };

            $scope.experimentName = function (experiments, experimentId) {
                var exp = _.find(experiments, {'experimentId': experimentId});
                return exp ? exp.name : '';
            };

            $scope.experimentGroupName = function (experiments, experimentId, experimentGroupId) {
                var groupName = '';
                var experiment = _.find(experiments, {'experimentId': experimentId});
                if (experiment) {
                    var group = _.find(experiment.experimentGroups, {'experimentGroupId': experimentGroupId});
                    if (group) {
                        groupName = group.name;
                    }
                }
                return groupName;
            };

            $scope.getCountryName = function getCountryName(countryMap, countryCode) {
                var countryName = countryMap.getCountryName(countryCode.toUpperCase());
                if (_.isUndefined(countryName)) {
                    return '';
                }
                return $filter('capitalize')(countryName);
            };

            /**
             * @description Formatter for 'excluded paths'
             * @example level 1 > level 1.1
             *
             * @todo Make it not self executable && path provided by external service
             */
            $scope.excludedPaths = (function (excludedPaths) {
                /**
                 * @todo Split to provide full tree of the path in case links need to be clicked individually
                 * @type Array
                 * @private
                 */
                var _excludedPaths = [];
                _.forEach(excludedPaths, function (excludedPath) {
                    _excludedPaths.push({
                        'href': $scope.prefix + '/tagmanagement/tags/list/' + excludedPath,
                        'path': excludedPath.split('|').join(' > ')
                    });
                });
                return _excludedPaths;
            })(tagViewPojo.tag.excludedPaths);

            // automatically open condition dialog based on state param (only if really true)
            if ($state.params.autoOpenConditions && $scope.hasTagEdit) {
                $timeout(function () {
                    $scope.openConditionDialog();
                });
            }

            TagmanagementService.getMobileProperties().then(
                function onSuccess(mobileProperties) {
                    $scope.mobileProperties = mobileProperties;
                },
                function onError(){}
            );

            $scope.showMobilePropertyLabel = function getPropertyNameFromLabel(propertyParameter){
                return TagmanagementServiceHelper.getPropertyNameFromLabel(propertyParameter, $scope.mobileProperties);
            };

            $scope.removeExclusion = function removeExclusion(tag, path){
                TagmanagementService.removePathExclusion(tag.tagId, path).then(function onSuccess(modification){
                    $scope.handleModification(modification);
                });
            };

            /**
             * @description Formatter for 'excluded paths'
             * @example level 1 > level 1.1
             *
             * @todo Make it not self executable && path provided by external service
             */
            $scope.getExcludedPath = function getExcludedPath(excludedPath) {
                /**
                 * @todo Split to provide full tree of the path in case links need to be clicked individually
                 * @type Array
                 * @private
                 */
                return {'sref': 'site.tagmanagement.tags.list({location: excludedPath})', 'name': excludedPath.split('|').join(' > '), 'path': excludedPath};
            };
        }
    ]);
