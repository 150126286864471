import { createContext } from 'react';

type ContextType = {
  setSelectedModelId: (id: string) => void;
};

const defaultValues = {
  setSelectedModelId: () => {
    // intentionally left empty
  },
};

export const ModelContext = createContext<ContextType>(defaultValues);
