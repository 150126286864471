/**
 * @description Angular module for Content -> campaigns2 -> Form
 * @class ContentCampaignFormController
 * @namespace webUi.ui.content.campaigns2.form
 * @memberOf webUi.ui.content.campaigns2
 */
angular.module('webUi.ui.content.campaigns2.form',
    [
        'webUi.ui.content.campaigns2.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns2.form', {
                url : '/form',
                isReact: true,
            });
    }]);
