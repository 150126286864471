import { get } from 'lodash';
import { getDateAndTime } from '~/common/utils/DateUtil';

/**
 * Get Configuration By Id
 * @param state
 * @param configurationId
 * @returns {*}
 */
export const getConfigurationById = (state, configurationId) => {
  const config = get(state, `admin.urlBuilder.configurations.byId[${configurationId}]`);
  return {
    ...config,
    lastAttempt: getDateAndTime(config.lastAttempt),
    lastSuccess: getDateAndTime(config.lastSuccess),
    addedOn: getDateAndTime(config.addedOn),
    lastModifiedOn: getDateAndTime(config.lastModifiedOn),
  };
};

/**
 * Get All Configurations
 * @param state
 */
export const getConfigurations = state =>
  state.admin.urlBuilder.configurations.allIds.map(id => {
    const config = state.admin.urlBuilder.configurations.byId[id];
    return {
      ...config,
      lastAttempt: getDateAndTime(config.lastAttempt),
      lastSuccess: getDateAndTime(config.lastSuccess),
      addedOn: getDateAndTime(config.addedOn),
      lastModifiedOn: getDateAndTime(config.lastModifiedOn),
    };
  });

/**
 * Should fetch all configurations?
 * @param state
 */
export const shouldFetchConfigurations = state => get(state, 'admin.urlBuilder.configurations.ui.shouldFetchAll');

/**
 * Is fetching all configurations currently in progress?
 * @param state
 */
export const isFetchingConfigurations = state => get(state, 'admin.urlBuilder.configurations.ui.isFetchingAll');

/**
 * Has current connection test been successful?
 * @param state
 */
export const isConnectionTestSuccessful = state =>
  get(state, 'admin.urlBuilder.configurations.currentConnectionTest.success');

/**
 * Is currently running any report?
 * @param state
 */
export const isRunningReport = state => get(state, 'admin.urlBuilder.configurations.ui.isRunningReport');

/**
 * Get the latest error for running reports
 * @param state
 */
export const getLatestError = state => get(state, 'admin.urlBuilder.configurations.ui.error');
