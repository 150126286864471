import React, { MouseEvent, ReactElement } from 'react';
import Icons from '~/components/src/Icons';
import BtnOutlined from '../BtnOutlined';

type EmptyPlaceholderType = {
  icon?: string;
  title?: string;
  subTitle?: string | ReactElement;
  action?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
};

function EmptyPlaceholder({ icon, title, subTitle, onClick, action }: EmptyPlaceholderType): ReactElement {
  return (
    <div className="flex max-w-md flex-1 flex-col items-center justify-center p-4">
      {icon && <Icons icon={icon} className="h-10 w-10 rounded bg-slate-400 bg-opacity-10 p-1 text-slate-400" />}
      {title && <p className="pt-4 text-center text-lg font-medium dark:text-white">{title}</p>}
      {subTitle && <p className="pb-4 text-center text-slate-400">{subTitle}</p>}
      {onClick && action && (
        <BtnOutlined onClick={onClick} testHook="emptyPlaceholderAction" size="xs" color="emerald">
          {action}
        </BtnOutlined>
      )}
    </div>
  );
}

export default EmptyPlaceholder;
