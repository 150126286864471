/**
 * Directive that shows search results in connection, but independent, from the <b>searchbox</b> directive
 */
angular.module('webUi.directive.searchresults', [
    'ui.bootstrap'
])

    .directive('searchResults', ['$document', '$compile', '$uibPosition', function ($document, $compile, $uibPosition) {
        return {
            replace: true,
            transclude: true,
            scope: {
                searchString:'=',
                items:'=',
                onClick: '&'
            },

            link: function(scope, element, attrs) {

                //read appendToBody and evaluate it on the parent scope
                var appendToBody = angular.isDefined(attrs.appendToBody) ? scope.$parent.$eval(attrs.appendToBody) : false;
                //read the options (key-value strings) TODO improve these to work with a simple array of strings
                scope.itemOptions = scope.$parent.$eval(attrs.itemOptions);
                scope.itemKey = scope.itemOptions.key;
                scope.itemValue = scope.itemOptions.value;

                scope.position = appendToBody ? $uibPosition.offset(element) : $uibPosition.position(element);
                scope.position.top = scope.position.top + element.prop('offsetHeight');
                scope.position.left = scope.position.left + 300; //hardcoded TODO marius

                //TODO some hardcoded elements here, such as "Countries"
                var elem = angular.element(
                    '<div class="searchResults suggestions" data-ng-show="searchString.length > 0" ng-style="{top: position.top+\'px\', left: position.left+\'px\'}">' +
     '<p><b>Countries containing "<i>{{searchString}}</i>"</b></p>' +
     '<ul class="countryList list-unstyled">' +
      '<li data-ng-repeat="item in items | filter:searchString | filter:{hidden:false}">' +
       '<a title="{{item[itemValue] | capitalize}}" data-ng-click="onClick({item: item[itemKey]})">' +
        '<span data-flag="item[itemKey]"></span>' +
        '<span data-ng-bind-html="item[itemValue] | capitalize | highlight:searchString"></span> ({{item[itemKey] | uppercase}})' +
       '</a>' +
      '</li>' +
     '</ul>' +
    '</div>');

                var compiled = $compile(elem)(scope);

                if ( appendToBody ) {
                    $document.find('body').append(compiled);
                } else {
                    element.after(compiled);
                }

                scope.$on('$destroy', function() {
                    compiled.remove();
                });

            }
        };
    }]);
