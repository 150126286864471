import React, { useState, ReactNode } from 'react';
import { Heading, PickerView, Page } from '~/components';
import i18n from '~/i18n';
import moment from 'moment';
import { showSuccess } from '~/notificationCenter/actions';
import { useApiWithState } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import SnapshotTable from './SnapshotTable';
import NewSnapshot from './NewSnapshot';
import { fetchSnapshotList, createSnapshot } from './dataService';
import { SnapshotRequestType } from '../types';

function SnapshotsList(): ReactNode {
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const { state: snapshots, isLoading, refetch: refetchSnapshots } = useApiWithState(() => fetchSnapshotList());

  const handleCreateSnapshot = async (values: SnapshotRequestType) => {
    const now = new Date();
    const payload = {
      snapshotName: values.snapshotName,
      startTs: moment(values.startTs).startOf('day').toDate().getTime(),
      endTs: moment(values.endTs).isSame(now, 'day')
        ? now.getTime()
        : moment(values.endTs).endOf('day').toDate().getTime(),
    };

    await createSnapshot(payload);
    showSuccess({ header: i18n.t('ai:notifications.snapshotCreateSuccess') });
    setIsPickerVisible(false);
    refetchSnapshots();
  };

  return (
    <Page className="SnapshotPage">
      <Heading title={i18n.t('ai:snapshots')}>
        <BtnIcon
          icon="add"
          color="blue"
          testHook="addSnapshot"
          onClick={() => setIsPickerVisible(true)}
          tooltip={i18n.t('ai:actions.addSnapshot')}
        />
        {isPickerVisible && (
          <PickerView
            handlePickerVisibility={setIsPickerVisible}
            pickerTitle={i18n.t('ai:new.title')}
            className="SnapshotPicker"
          >
            <NewSnapshot hideModal={() => setIsPickerVisible(false)} createSnapshot={handleCreateSnapshot} />
          </PickerView>
        )}
      </Heading>
      <SnapshotTable snapshots={snapshots} isFetching={isLoading} refetchSnapshots={refetchSnapshots} />
    </Page>
  );
}

export default SnapshotsList;
