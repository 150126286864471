import React from 'react';
import PropTypes from 'prop-types';
import { useAPI } from '~/common/ApiHooks';
import { Field } from 'redux-form';
import FormActions from '~/components/src/Form/ActionsDefault';
import CardMessage from '~/components/src/CardMessage';
import { mode } from '~/components/src/Code';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormCheckboxField,
  ReduxFormCodeField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import Spinner from '~/components/src/Spinner';
import { isRequired, numberRange, defaultMaxInput, normalizeToInteger } from '~/common';
import ContextClientsService from '~/context/clients/records/dataService';
import { MINIMUM_DATA_RETENTION_PERIOD, MINIMUM_HP_DAYS } from '~/context/clients';

import './ClientForm.scss';

const parseValue = list => ({
  ...list,
  label: list.label,
  value: list.name,
});

const isValidRetentionRange = numberRange(MINIMUM_DATA_RETENTION_PERIOD, 99);
const isValidHistoricProcessingRange = numberRange(MINIMUM_HP_DAYS, 365); // Minimum to Max of 365 days.
const isValidPercentage = numberRange(1, 100);
const isValidApiRateLimit = numberRange(1, 10000);

const isValidMappingsRetentionRange = value => {
  if (!value && value !== 0) {
    return undefined;
  }

  return isValidRetentionRange(value);
};

const ClientForm = ({
  handleSubmit,
  submitting,
  backHref,
  t,
  isUsageAlertsChecked,
  isErrorAlertsChecked,
  isApiRateLimitChecked,
  dataStorageLocationIsEnabled,
  isPriorityLevelCriticalChecked,
  externalAuthentication,
  isCritialValueValid,
  touch,
  isEdit = false,
}) => {
  const notFoundMessage = t('client.dataStorageLocation.notFound');
  const { data, error } = useAPI(ContextClientsService.getDataRegion);
  if (error) return <p>{notFoundMessage}</p>;
  if (!data) return <Spinner />;
  const locationOptions = data.map(parseValue);
  const authenticationProtocalOptions = [{ value: 'SAML', label: 'SAML' }];

  return (
    <Form onSubmit={handleSubmit} isSubmitting={submitting} className="ClientForm">
      <div>
        <Field
          label={t('client.nameField.label')}
          name="name"
          component={ReduxFormInputField}
          placeholder={t('client.nameField.placeholder')}
          validate={[isRequired, defaultMaxInput]}
          type="text"
        />
        <Field
          label={t('client.dataRetentionMonths.label')}
          name="dataRetentionMonths"
          component={ReduxFormInputField}
          normalize={normalizeToInteger}
          placeholder={t('client.dataRetentionMonths.placeholder')}
          validate={[isRequired, isValidRetentionRange]}
          type="number"
        />
        <Field
          label={t('client.mappingRetentionMonths.label')}
          name="mappingRetentionMonths"
          component={ReduxFormInputField}
          normalize={normalizeToInteger}
          placeholder={t('client.mappingRetentionMonths.placeholder')}
          validate={isValidMappingsRetentionRange}
          type="number"
        />
        <Field
          label={t('client.maxAllowedHistoricProcessingDays.label')}
          name="maxAllowedHistoricProcessingDays"
          component={ReduxFormInputField}
          normalize={normalizeToInteger}
          placeholder={t('client.maxAllowedHistoricProcessingDays.placeholder')}
          validate={isValidHistoricProcessingRange}
          type="number"
        />
        {dataStorageLocationIsEnabled && (
          <Field
            label={t('client.dataStorageLocation.label')}
            name="dataStorageLocation"
            component={ReduxFormSelectField}
            placeholder={t('client.dataStorageLocation.placeholder')}
            validate={isRequired}
            options={locationOptions}
            hint={t('client.dataStorageLocation.notification')}
            touch={touch}
          />
        )}
        <div className="ClientForm-fiedWithCheckbox">
          <Field
            label={t('client.usageAlertField.label')}
            name="usageAlertsEnabled"
            component={ReduxFormCheckboxField}
          />
          {isUsageAlertsChecked && (
            <Field
              hint={t('client.usageThresholdField.label')}
              name="usageAlertsThreshold"
              component={ReduxFormInputField}
              normalize={normalizeToInteger}
              placeholder={t('client.usageThresholdField.placeholder')}
              validate={[isRequired, isValidPercentage]}
              type="number"
            />
          )}
        </div>

        <div className="ClientForm-fiedWithCheckbox">
          <Field
            label={t('client.partnerAlertField.label')}
            name="errorAlertsEnabled"
            component={ReduxFormCheckboxField}
          />
          {isErrorAlertsChecked && (
            <Field
              hint={t('client.thresholdField.label')}
              name="errorAlertsThreshold"
              component={ReduxFormInputField}
              normalize={normalizeToInteger}
              placeholder={t('client.thresholdField.placeholder')}
              validate={[isRequired, isValidPercentage]}
              type="number"
            />
          )}
        </div>

        <div className="ClientForm-fiedWithCheckbox">
          <Field
            label={t('client.externalAuthentication.label')}
            name="externalAuthentication.enabled"
            component={ReduxFormCheckboxField}
            className="t-externalAuthenticationCheckbox"
          />
          {externalAuthentication?.enabled && (
            <>
              <Field
                label={t('client.externalAuthentication.authenticationProtocol.label')}
                name="externalAuthentication.authenticationProtocol"
                component={ReduxFormSelectField}
                placeholder={t('client.externalAuthentication.authenticationProtocol.placeholder')}
                validate={isRequired}
                options={authenticationProtocalOptions}
                touch={touch}
                className="t-externalAuthenticationAuthenticationProtocol"
              />
              <Field
                component={ReduxFormCodeField}
                isBlock={true}
                mode={mode.html}
                label={t('client.externalAuthentication.metadata.label')}
                name="externalAuthentication.metadata"
                validate={isEdit ? null : isRequired}
                className="t-externalAuthenticationMetadata"
              />
            </>
          )}
        </div>

        <div className="ClientForm-fiedWithCheckbox">
          <Field
            label={t('client.apiRateLimitEnabledField.label')}
            name="apiRateLimitEnabled"
            component={ReduxFormCheckboxField}
          />
          {isApiRateLimitChecked && (
            <Field
              hint={t('client.apiRateLimitField.label')}
              name="apiRateLimit"
              component={ReduxFormInputField}
              normalize={normalizeToInteger}
              placeholder={t('client.apiRateLimitField.placeholder')}
              validate={[isRequired, isValidApiRateLimit]}
              type="number"
            />
          )}
        </div>
        {isApiRateLimitChecked && (
          <div className="ClientForm-fiedWithCheckbox">
            <Field
              label={t('client.priorityLevelsEnabledField.label')}
              name="priorityLevelEnabled"
              component={ReduxFormCheckboxField}
            />
            {isPriorityLevelCriticalChecked && (
              <>
                <CardMessage
                  type="INFO"
                  className="ClientForm-CRLInfo"
                  message={
                    <>
                      <p>{t('client.criticalRequestsPercentage.information.arlFormula')}</p>
                      <p>{t('client.criticalRequestsPercentage.information.crpFormula')}</p>

                      <hr />

                      <p className="ClientForm-CRLInfo--message">
                        {t('client.criticalRequestsPercentage.information.description')}
                      </p>
                      <p className="ClientForm-CRLInfo--formula">
                        {t('client.criticalRequestsPercentage.information.criticalValueFormula')}
                      </p>
                      <p className="ClientForm-CRLInfo--formula">
                        {t('client.criticalRequestsPercentage.information.nonCriticalValueFormula')}
                      </p>

                      <p className="ClientForm-CRLInfo--message">
                        {t('client.criticalRequestsPercentage.information.footer')}
                      </p>
                    </>
                  }
                />
                <Field
                  hint={t('client.criticalRequestsPercentage.label')}
                  name="priorityLevelCritical"
                  component={ReduxFormInputField}
                  normalize={normalizeToInteger}
                  placeholder={t('client.criticalRequestsPercentage.placeholder')}
                  validate={[isRequired, isValidPercentage]}
                  type="number"
                />
              </>
            )}
          </div>
        )}
      </div>
      {!isCritialValueValid && isPriorityLevelCriticalChecked && (
        <CardMessage message={t('client.criticalRequestsPercentage.validationError')} />
      )}
      <FormActions
        t={t}
        submitting={submitting}
        cancelHref={backHref}
        isDisableSave={!isCritialValueValid && isPriorityLevelCriticalChecked}
      />
    </Form>
  );
};

ClientForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  backHref: PropTypes.string.isRequired,
  isUsageAlertsChecked: PropTypes.bool,
  isEdit: PropTypes.bool,
  isErrorAlertsChecked: PropTypes.bool,
  isApiRateLimitChecked: PropTypes.bool,
  isPriorityLevelCriticalChecked: PropTypes.bool,
  t: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
};

export default ClientForm;
