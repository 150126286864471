import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '~/components/src/Spinner';
import { changeUrl, buildUrl } from '~/common';
import * as fromModals from '~/modals';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import Heading from '~/components/src/Heading';
import LinkIcon from '~/components/src/LinkIcon';
import { fetchTypes, deleteType } from '../types/actions';
import { getTypes, isFetchingTypes } from '../types/selectors';
import TypesList from '../../components/TypesList';

const redirectToEditPage = typeId => {
  changeUrl(`admin/urlbuilder/type/edit/${typeId}`);
};

export class TypesContainer extends Component {
  componentDidMount() {
    this.props.fetchTypes();
  }

  render() {
    const { isFetching, isSiteAdmin, onDeleteClick, stateKey, list, t } = this.props;

    return (
      <section>
        <Heading title={t('urlBuilder:types.header')} kind="h2">
          <LinkIcon
            icon="add"
            color="blue"
            url={buildUrl('admin/urlbuilder/type/add')}
            className="h-8 w-8"
            disabled={!isSiteAdmin}
            testHook="addType"
            tooltip={t('urlBuilder:types.newTypeButton')}
          />
        </Heading>
        {isFetching ? (
          <Spinner />
        ) : (
          <TypesList
            list={list}
            stateKey={stateKey}
            onEditClick={redirectToEditPage}
            onDeleteClick={onDeleteClick}
            t={t}
            isSiteAdmin={isSiteAdmin}
          />
        )}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: 'ADMIN/URLBUILDER/TYPES',
  list: getTypes(state),
  isFetching: isFetchingTypes(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTypes: () => dispatch(fetchTypes()),
  onDeleteClick: (type, t) => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: t('urlBuilder:types.deleteConfirmationModal.header'),
        message: t('urlBuilder:types.deleteConfirmationModal.message', { name: type.name }),
        onConfirm: () => dispatch(deleteType(type.typeId)),
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('urlBuilder'))(TypesContainer);
