import React from 'react';
import { Moment } from 'moment';
import DateRangePicker from '~/components/src/DateRangePicker';
import { Heading } from '~/components';
import Icons from '~/components/src/Icons';
import { ActivityHeaderProps } from '../types';

const ActivityHeader = ({
  startDate,
  endDate,
  tooltip,
  title,
  setFilter,
  testHook,
  enableScrollDetection = false,
}: ActivityHeaderProps): React.ReactElement => (
  <div className="mb-8 flex items-center justify-between gap-2">
    <div className="flex items-center gap-2">
      <Heading kind="h3" className="inline-block" title={title} />
      {tooltip && <Icons icon="info" tooltip={tooltip} className="h-4 w-4 text-gray-500" />}
    </div>
    <DateRangePicker
      testHook={testHook}
      startDate={startDate}
      endDate={endDate}
      handleDatesChange={({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
        setFilter({ startDate, endDate });
      }}
      enableScrollDetection={enableScrollDetection}
    />
  </div>
);

export default ActivityHeader;
