import i18n from '~/i18n';

export const currency = [
  { code: 'USD', name: 'US dollars' },
  { code: 'CAD', name: 'Canadian dollars' },
  { code: 'EUR', name: 'euros' },
  { code: 'AED', name: 'UAE dirhams' },
  { code: 'AFN', name: 'Afghan Afghanis' },
  { code: 'ALL', name: 'Albanian lekë' },
  { code: 'AMD', name: 'Armenian drams' },
  { code: 'ARS', name: 'Argentine pesos' },
  { code: 'AUD', name: 'Australian dollars' },
  { code: 'AZN', name: 'Azerbaijani manats' },
  { code: 'BAM', name: 'Bosnia-Herzegovina convertible marks' },
  { code: 'BDT', name: 'Bangladeshi takas' },
  { code: 'BGN', name: 'Bulgarian leva' },
  { code: 'BHD', name: 'Bahraini dinars' },
  { code: 'BIF', name: 'Burundian francs' },
  { code: 'BND', name: 'Brunei dollars' },
  { code: 'BOB', name: 'Bolivian bolivianos' },
  { code: 'BRL', name: 'Brazilian reals' },
  { code: 'BWP', name: 'Botswanan pulas' },
  { code: 'BYN', name: 'Belarusian rubles' },
  { code: 'BZD', name: 'Belize dollars' },
  { code: 'CDF', name: 'Congolese francs' },
  { code: 'CHF', name: 'Swiss francs' },
  { code: 'CLP', name: 'Chilean pesos' },
  { code: 'CNY', name: 'Chinese yuan' },
  { code: 'COP', name: 'Colombian pesos' },
  { code: 'CRC', name: 'Costa Rican colóns' },
  { code: 'CVE', name: 'Cape Verdean escudos' },
  { code: 'CZK', name: 'Czech Republic korunas' },
  { code: 'DJF', name: 'Djiboutian francs' },
  { code: 'DKK', name: 'Danish kroner' },
  { code: 'DOP', name: 'Dominican pesos' },
  { code: 'DZD', name: 'Algerian dinars' },
  { code: 'EEK', name: 'Estonian kroons' },
  { code: 'EGP', name: 'Egyptian pounds' },
  { code: 'ERN', name: 'Eritrean nakfas' },
  { code: 'ETB', name: 'Ethiopian birrs' },
  { code: 'GBP', name: 'British pounds sterling' },
  { code: 'GEL', name: 'Georgian laris' },
  { code: 'GHS', name: 'Ghanaian cedis' },
  { code: 'GNF', name: 'Guinean francs' },
  { code: 'GTQ', name: 'Guatemalan quetzals' },
  { code: 'HKD', name: 'Hong Kong dollars' },
  { code: 'HNL', name: 'Honduran lempiras' },
  { code: 'HRK', name: 'Croatian kunas' },
  { code: 'HUF', name: 'Hungarian forints' },
  { code: 'IDR', name: 'Indonesian rupiahs' },
  { code: 'ILS', name: 'Israeli new sheqels' },
  { code: 'INR', name: 'Indian rupees' },
  { code: 'IQD', name: 'Iraqi dinars' },
  { code: 'IRR', name: 'Iranian rials' },
  { code: 'ISK', name: 'Icelandic krónur' },
  { code: 'JMD', name: 'Jamaican dollars' },
  { code: 'JOD', name: 'Jordanian dinars' },
  { code: 'JPY', name: 'Japanese yen' },
  { code: 'KES', name: 'Kenyan shillings' },
  { code: 'KHR', name: 'Cambodian riels' },
  { code: 'KMF', name: 'Comorian francs' },
  { code: 'KRW', name: 'South Korean won' },
  { code: 'KWD', name: 'Kuwaiti dinars' },
  { code: 'KZT', name: 'Kazakhstani tenges' },
  { code: 'LBP', name: 'Lebanese pounds' },
  { code: 'LKR', name: 'Sri Lankan rupees' },
  { code: 'LTL', name: 'Lithuanian litai' },
  { code: 'LVL', name: 'Latvian lati' },
  { code: 'LYD', name: 'Libyan dinars' },
  { code: 'MAD', name: 'Moroccan dirhams' },
  { code: 'MDL', name: 'Moldovan lei' },
  { code: 'MGA', name: 'Malagasy Ariaries' },
  { code: 'MKD', name: 'Macedonian denari' },
  { code: 'MMK', name: 'Myanma kyats' },
  { code: 'MOP', name: 'Macanese patacas' },
  { code: 'MUR', name: 'Mauritian rupees' },
  { code: 'MXN', name: 'Mexican pesos' },
  { code: 'MYR', name: 'Malaysian ringgits' },
  { code: 'MZN', name: 'Mozambican meticals' },
  { code: 'NAD', name: 'Namibian dollars' },
  { code: 'NGN', name: 'Nigerian nairas' },
  { code: 'NIO', name: 'Nicaraguan córdobas' },
  { code: 'NOK', name: 'Norwegian kroner' },
  { code: 'NPR', name: 'Nepalese rupees' },
  { code: 'NZD', name: 'New Zealand dollars' },
  { code: 'OMR', name: 'Omani rials' },
  { code: 'PAB', name: 'Panamanian balboas' },
  { code: 'PEN', name: 'Peruvian nuevos soles' },
  { code: 'PHP', name: 'Philippine pesos' },
  { code: 'PKR', name: 'Pakistani rupees' },
  { code: 'PLN', name: 'Polish zlotys' },
  { code: 'PYG', name: 'Paraguayan guaranis' },
  { code: 'QAR', name: 'Qatari rials' },
  { code: 'RON', name: 'Romanian lei' },
  { code: 'RSD', name: 'Serbian dinars' },
  { code: 'RUB', name: 'Russian rubles' },
  { code: 'RWF', name: 'Rwandan francs' },
  { code: 'SAR', name: 'Saudi riyals' },
  { code: 'SDG', name: 'Sudanese pounds' },
  { code: 'SEK', name: 'Swedish kronor' },
  { code: 'SGD', name: 'Singapore dollars' },
  { code: 'SOS', name: 'Somali shillings' },
  { code: 'SYP', name: 'Syrian pounds' },
  { code: 'THB', name: 'Thai baht' },
  { code: 'TND', name: 'Tunisian dinars' },
  { code: 'TOP', name: 'Tongan paʻanga' },
  { code: 'TRY', name: 'Turkish Lira' },
  { code: 'TTD', name: 'Trinidad and Tobago dollars' },
  { code: 'TWD', name: 'New Taiwan dollars' },
  { code: 'TZS', name: 'Tanzanian shillings' },
  { code: 'UAH', name: 'Ukrainian hryvnias' },
  { code: 'UGX', name: 'Ugandan shillings' },
  { code: 'UYU', name: 'Uruguayan pesos' },
  { code: 'UZS', name: 'Uzbekistan som' },
  { code: 'VEF', name: 'Venezuelan bolívars' },
  { code: 'VND', name: 'Vietnamese dong' },
  { code: 'XAF', name: 'CFA francs BEAC' },
  { code: 'XOF', name: 'CFA francs BCEAO' },
  { code: 'YER', name: 'Yemeni rials' },
  { code: 'ZAR', name: 'South African rand' },
  { code: 'ZMK', name: 'Zambian kwachas' },
  { code: 'ZWL', name: 'Zimbabwean Dollar' },
];

export const SOURCE_COUNTRY_SELECT_ALL = { label: i18n.t('audiences:connectors.amazonDsp.selectAll'), value: 'All' };
