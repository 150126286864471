import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import {
  TChangeElementMapped,
  TDeployChangePayload,
  TDeploymentHashPayload,
  TEnvAdminPayload,
  TEnvironment,
  TEnvSampleRatePayload,
  TPublishChangePayload,
  TPublishHistory,
  TReleaseHistory,
  TTagUsers,
} from './types';
import { mapLatestDiff } from './util';

const BASE_PATH = `${API_BASE}/tagmanagement/publishing`;
const ENV_BASE_BATH = `${API_BASE}/tagmanagement/environments`;

export const getLatestDiff = (): Promise<TChangeElementMapped[]> =>
  Api.callGet(`${BASE_PATH}/latestDiff`).then(parseResponse).then(mapLatestDiff);

export const getPublishingHistory = (): Promise<TPublishHistory[]> =>
  Api.callGet(`${BASE_PATH}/publishHistory`).then(parseResponse);

export const getReleaseHistory = (): Promise<TReleaseHistory[]> =>
  Api.callGet(`${BASE_PATH}/releaseHistory`).then(parseResponse);

export const getTagUsers = (): Promise<TTagUsers[]> => Api.callGet(`${BASE_PATH}/tagUsers`).then(parseResponse);

export const getEnvironments = (): Promise<TEnvironment[]> => Api.callGet(ENV_BASE_BATH).then(parseResponse);

export const getDiff = (from: string, to: string): Promise<TChangeElementMapped[]> => {
  const url = `${BASE_PATH}/diff?${from ? `from=${from}&` : ''}to=${to}`;
  return Api.callGet(url).then(parseResponse).then(mapLatestDiff);
};

export const publishChanges = (payload: TPublishChangePayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}`, payload).then(parseResponse);

export const deployChanges = (payload: TDeployChangePayload): Promise<void> =>
  Api.callPost(`${ENV_BASE_BATH}/deploy`, payload).then(parseResponse);

export const updateRequiresAdmin = (payload: TEnvAdminPayload[]): Promise<void> =>
  Api.callPost(`${ENV_BASE_BATH}/requiresAdmin`, payload).then(parseResponse);

export const updateSampleRate = (payload: TEnvSampleRatePayload[]): Promise<void> =>
  Api.callPost(`${ENV_BASE_BATH}/sampleRate`, payload).then(parseResponse);

export const getDeploymentHash = (payload: TDeploymentHashPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/deploymentHash`, payload).then(parseResponse);
