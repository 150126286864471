import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { WORKFLOW_STATUS } from '~/workflows/constants';

const WorkflowStatusTag = ({ status, text }: { status: string; text: string }) => (
  <div
    className={twMerge(
      cx('rounded-xl border border-green-100 bg-green-50 px-2 font-medium text-green-600', {
        'border-amber-100 bg-amber-50 text-amber-600': status !== WORKFLOW_STATUS.PUBLISHED,
      }),
    )}
  >
    <span className="t-journeyStatusText">{text}</span>
  </div>
);

export default WorkflowStatusTag;
