/*
  TODO: Split RuleList into 2 components: RuleListView and RuleListEdit.
  These two use cases are very different and require different props.
  Why do this:
   - For easier understanding. RuleList is a weird abstraction over two components that don't have much in common.
   - ESLint will stop complaining about "code complexity overload"
*/
import React from 'react';
import cx from 'classnames';
import { flattenDepth, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import RuleCardEdit from '~/common/modules/filterRules/components/RuleCardEdit';
import RuleCardView from '~/common/modules/filterRules/components/RuleCardView';
import i18n from '~/i18n';
import Notification from '../Notification';
import './styles.scss';

export const RuleCardTypes = {
  ViewCard: 'ViewCard',
  EditCard: 'EditCard',
};

export const RuleOptions = {
  AND: 'AND',
  OR: 'OR',
};

const getLineModifier = (ruleIndex, ruleSetLength) => {
  if (ruleSetLength === 1) {
    return 'onlyOneCard';
  }

  if (ruleIndex === 0) {
    return 'first';
  }

  if (ruleIndex === ruleSetLength - 1) {
    return 'last';
  }

  return 'middle';
};

const CriteriaEmptyNotification = ({ isSubmitted }) => (
  <Notification kind={isSubmitted ? 'error' : 'information'} header="">
    <p>{i18n.t('filters:list.emptyBody')}</p>
  </Notification>
);

const hasFieldErrors = criteria => {
  const flatRules = flattenDepth(criteria, 2);

  const hasErrors = flatRules.some(rule => !isEmpty(rule.errors));

  return hasErrors;
};

const checkDateConstraint = (isAudienceRule, criteria) => {
  if (isAudienceRule) {
    return criteria.some(crit =>
      crit.some(
        rule => rule?.filters?.length > 1 && rule?.filters?.some(filter => filter?.constraint?.group?.name === 'DATE'),
      ),
    );
  }
  return false;
};

const checkDuplicateEventWithDateProperties = (isAudienceRule, criteria) => {
  if (isAudienceRule) {
    const flatCriterias = criteria.flat();

    // group criterias by rule type
    const hasSameRules = _.groupBy(flatCriterias, 'type');

    // find duplicate criterias with same rule type and label
    const groups = Object.values(hasSameRules);
    const groupWithduplicates = groups.filter(group => group.length > 1);

    // find if any group has same criterias with date filter
    const hasDuplicate = groupWithduplicates?.some(group => {
      // has date rule configured for same rule type
      // skip negated rules
      const criteriasWithDateFilter = group?.filter(
        rule => !rule?.negation && rule?.filters?.find(filter => filter?.filterValue?.clazz === 'DateFilterValue'),
      );

      if (criteriasWithDateFilter.length > 1) {
        let fis = criteriasWithDateFilter?.map(rule => rule.filters);
        fis = fis?.flat();
        const dateFieldArray = _.groupBy(fis, 'dataField');
        const filterdDatFields = Object.keys(dateFieldArray).filter(dataField => dateFieldArray[dataField]?.length > 1);
        return filterdDatFields.length > 0;
      }

      return false;
    });
    return hasDuplicate;
  }
  return false;
};

export const validateCriteria = (criteria, isAudienceRule) => {
  const hasPropertyErrors =
    checkDateConstraint(isAudienceRule, criteria) || checkDuplicateEventWithDateProperties(isAudienceRule, criteria);
  if (hasFieldErrors(criteria) || hasPropertyErrors) {
    return false;
  }

  return true;
};

const updateCriteria = (criteria, onChange, isAudienceRule) => (updatedRule, formProps) => {
  const nextCriteria = criteria.map(criteriaSet =>
    criteriaSet.map(rule => {
      if (rule.ruleId === updatedRule.ruleId) {
        return { ...rule, ...updatedRule, errors: formProps.errors };
      }

      return rule;
    }),
  );

  onChange({
    criteria: nextCriteria,
    isValid: validateCriteria(nextCriteria, isAudienceRule),
  });
};

const deleteCriteria = (removedRule, criteria, onChange, isAudienceRule) => {
  const nextCriteria = criteria.reduce((acc, criteriaSet) => {
    const filteredCriteriaSet = criteriaSet.filter(rule => rule.ruleId !== removedRule.ruleId);

    if (filteredCriteriaSet.length) {
      acc.push(filteredCriteriaSet);
    }
    return acc;
  }, []);
  onChange({
    criteria: nextCriteria,
    isValid: validateCriteria(nextCriteria, isAudienceRule),
  });
};

const flattenRuleTypesTree = ruleTypesTree =>
  ruleTypesTree.reduce((ruleTypes, ruleTypeOrGroup) => {
    const isGroup = Array.isArray(ruleTypeOrGroup.subMenu);

    if (isGroup) {
      return [...ruleTypes, ...ruleTypeOrGroup.subMenu];
    }

    return [...ruleTypes, ruleTypeOrGroup];
  }, []);

export const getPropertyTypesForRule = (ruleTypesTree, ruleDefinitionId) => {
  const allRuleTypes = flattenRuleTypesTree(ruleTypesTree);

  const ruleType = allRuleTypes.find(ruleType => ruleType.ruleDefinitionId === ruleDefinitionId) || {};

  const propertyTypesForRule = ruleType.availableFilters;

  if (propertyTypesForRule) {
    return Immutable.asMutable(propertyTypesForRule, { deep: true });
  }

  return [];
};

const RulesList = ({
  criteria = [],
  selectedHeader = 'All',
  ruleTypesTree = undefined,
  ruleCardType,
  onChange = undefined,
  isSubmitted = undefined,
  fetchAllVariables = undefined,
  className,
  isJourneyCondition,
  type = undefined,
  bindFormikForm = undefined,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isAudienceRule = type === 'audience';

  const hasAudienceRuleErrors = checkDateConstraint(isAudienceRule, criteria);
  const filteredCriteria =
    selectedHeader === 'All' ? criteria : criteria.filter(cr => cr[0].groupName === selectedHeader);

  return (
    <>
      {hasAudienceRuleErrors && ruleCardType === RuleCardTypes.ViewCard && (
        <Notification kind="error">{i18n.t('audiences:rulesNotValid')}</Notification>
      )}
      <div className={cx('RulesList', className)}>
        {filteredCriteria.length > 0 && (
          <>
            {filteredCriteria.map((ruleSet, setIndex) => (
              <React.Fragment key={`criteria-container-${setIndex}`}>
                <div className="RulesList-OR">
                  {/* Render individual rules card */}
                  {ruleSet?.map((rule, ruleIndex) => {
                    if (!rule) {
                      return (
                        <Notification header="" kind="error">
                          <p>Unsupported rule</p>
                        </Notification>
                      );
                    }
                    const modifier = getLineModifier(ruleIndex, ruleSet.length);
                    if (ruleCardType === RuleCardTypes.EditCard) {
                      const propertyTypes = getPropertyTypesForRule(ruleTypesTree, rule.ruleDefinitionId);
                      return (
                        <React.Fragment key={`rule-${rule.ruleId}`}>
                          <div className="RuleCardEdit-container">
                            <div className={cx('RuleCardEdit-line', `RuleCardEdit-line--${modifier}`)}>
                              <div className="RuleCardEdit-lineTop"></div>
                              <div className="RuleCardEdit-lineBottom"></div>
                            </div>
                            <RuleCardEdit
                              key={`rule-${rule.ruleId}`}
                              id={rule.ruleId}
                              type={rule.type}
                              title={rule.title}
                              typeLabel={rule.typeLabel}
                              timeCondition={rule.timeCondition}
                              waitTime={rule.waitTime}
                              isInversed={rule.negation}
                              filters={rule.filters}
                              propertyTypes={propertyTypes}
                              testHook={rule.ruleId}
                              onChange={updateCriteria(criteria, onChange, isAudienceRule)}
                              onDelete={() => deleteCriteria(rule, criteria, onChange, isAudienceRule)}
                              availableVariables={rule.availableVariables}
                              isSubmitted={isSubmitted}
                              fetchAllVariables={fetchAllVariables}
                              isJourneyCondition={isJourneyCondition}
                              isAudienceRule={isAudienceRule}
                              criteria={criteria}
                              bindFormikForm={bindFormikForm}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (ruleCardType === RuleCardTypes.ViewCard) {
                      return (
                        <React.Fragment key={`rule-${rule.ruleId}`}>
                          <div className="RuleCardEdit-container">
                            <div className={cx('RuleCardEdit-line', `RuleCardEdit-line--${modifier}`)}>
                              <div className="RuleCardEdit-lineTop"></div>
                              <div className="RuleCardEdit-lineBottom"></div>
                            </div>
                            <RuleCardView
                              type={rule.type}
                              typeLabel={rule.typeLabel}
                              title={rule.title}
                              ruleDefinitionId={rule.ruleDefinitionId}
                              timeCondition={rule.timeCondition}
                              waitTime={rule.waitTime}
                              isInversed={rule.negation}
                              properties={rule.properties}
                              testHook={rule.ruleId}
                              visibleColumns={['property', 'constraint', 'filter']}
                              isAudienceRule={isAudienceRule}
                              criteria={criteria}
                              filters={rule.filters}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </div>
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      {!criteria.length && <CriteriaEmptyNotification isSubmitted={isSubmitted} />}
    </>
  );
};

RulesList.propTypes = {
  criteria: PropTypes.array,
  RuleCardType: PropTypes.string, // otherwise fails on components with React.forwardRef
};

export default RulesList;
