import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { isNull, isUndefined } from 'lodash';
import Notification from '~/components/src/Notification';
import { isRequired, numberRange } from '~/common';
import { Link } from '~/components';
import {
  ReduxFormCheckboxField,
  ReduxFormInputField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class DoubleClickForm extends Component {
  constructor(props) {
    super(props);
    this.partnerRangeValidation = numberRange(1, 9999);
  }

  componentDidMount() {
    const {
      fetchAdvertisers,
      fetchUserIdTypes,
      values: { processConsent },
      change,
    } = this.props;
    fetchAdvertisers(constants.DOUBLE_CLICK);
    fetchUserIdTypes(constants.DOUBLE_CLICK);

    if (isNull(processConsent) || isUndefined(processConsent)) {
      change('DOUBLE_CLICK.processConsent', false);
    }
  }

  render() {
    const { t, advertisers, userIdTypes, values, change, partner, canUpdate, isSubmitted, touch } = this.props;

    const getPartnerNumber = value => {
      const userIdType = userIdTypes.find(userIdType => userIdType.name === value);
      change('DOUBLE_CLICK.overRidePartnerNumber', userIdType.allowsPartnerNumberOverride);
      return userIdType.partnerNumber || constants.DOUBLE_CLICK_PARTNER_NUMBERS[value];
    };

    return (
      <FormSection name={constants.partnerTypes.DOUBLE_CLICK}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.cookieSyncMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          {advertisers.length ? (
            <Field
              label={t('form.formFields.advertiser')}
              name="inviteType"
              component={ReduxFormSelectField}
              options={advertisers}
              validate={isRequired}
              isConnected={true}
              touch={touch}
              onChange={(event, option) => {
                change(
                  'DOUBLE_CLICK.advertiserId',
                  advertisers.find(advertiser => advertiser.dcAdId === option.dcAdId).advertiserId,
                );
              }}
            />
          ) : (
            <>
              <Field name="inviteType" component={() => null} validate={isRequired} />
              <Notification
                kind={isSubmitted ? 'error' : 'information'}
                header={t('form.notification.noAdvertisersHeader')}
              >
                <p>{t('form.notification.noAdvertisersSubHeader')}</p>
                <p>{t('form.notification.noAdvertisersBody')}</p>
                <p>{t('form.notification.noAdvertisersFooter')}</p>
              </Notification>
            </>
          )}
          {values.inviteType && advertisers.length > 0 && (
            <Field
              label={t('form.formFields.advertiserId')}
              name="advertiserId"
              component={ReduxFormInputField}
              readOnly
              type="text"
              disabled={canUpdate}
            />
          )}
          <Field
            label={t('form.formFields.userIdType')}
            name="userIdType"
            component={ReduxFormSelectField}
            validate={isRequired}
            options={userIdTypes}
            disabled={canUpdate}
            onChange={(e, value) => change('DOUBLE_CLICK.partnerNumber', getPartnerNumber(value))}
            touch={touch}
          />

          <Notification kind="information" className="my-6" header={t('form.notification.acceptEUConsent.header')}>
            <p>{t('form.notification.acceptEUConsent.message1.dV360')}</p>
            <p className="mt-2">{t('form.notification.acceptEUConsent.message2')}</p>
            <Link target="_blank" href={t('form.notification.acceptEUConsent.link')}>
              {t('form.notification.acceptEUConsent.link')}
            </Link>
          </Notification>

          <Field
            label={t('form.formFields.acceptEUConsent')}
            name="processConsent"
            component={ReduxFormCheckboxField}
          />

          {values.overRidePartnerNumber && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              validate={[isRequired, this.partnerRangeValidation]}
              type="text"
              disabled={canUpdate}
            />
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default DoubleClickForm;
