import React from 'react';
import PropTypes from 'prop-types';
import ReactTextAreaAutosize from 'react-textarea-autosize';
import { size as sizes } from './defaultSizes';

/**
 * This is a fix due to the bug in Redux-form
 * https://github.com/erikras/redux-form/issues/860
 * Usecase: We are stopping the chain event when the user click in cancel
 * meaning validation will not be trigger
 * */
const handleBlur = (event, onBlur) => {
  const { relatedTarget } = event;
  if (relatedTarget && relatedTarget.getAttribute('preventvalidation')) {
    event.preventDefault();
  } else {
    onBlur(event);
  }
};

const TextArea = ({ disabled, input, size = sizes.wide, placeholder, isError, testHook = input.name }) => (
  <ReactTextAreaAutosize
    {...input}
    id={input.name}
    className={`Element Element--textarea Element--${size} t-${testHook} ${isError ? 'is-error' : ''}`}
    disabled={disabled}
    placeholder={placeholder}
    onBlur={event => handleBlur(event, input.onBlur)}
    onChange={param => {
      input.onChange(param);
    }}
  />
);

TextArea.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'wide', 'block']),
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
};

export default TextArea;
