import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Get all the site information by the id
 * @param state
 * @param action
 * @returns {{}}
 */
const bySiteId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.SITE_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.siteId]: action.payload,
      };
    case types.SITES_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(site => {
        nextState[site.siteId] = site;
      });
      return nextState;
    case types.SITE_DELETE_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    default:
      return state;
  }
};

/**
 * We will keep an array with just the ids of all the sites
 * @param state
 * @param action
 * @returns {*}
 */
const allSitesIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.SITE_FETCH_SUCCESS:
    case types.SAVE_SUCCESS:
      if (state.indexOf(action.payload.siteId) !== -1) {
        return state;
      }
      return [...state, action.payload.siteId];
    case types.SITES_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(site => {
        if (state.indexOf(site.siteId) === -1) {
          nextState.push(site.siteId);
        }
      });
      return nextState;
    case types.SITE_DELETE_SUCCESS:
      return state.filter(siteId => siteId !== action.payload);
    default:
      return state;
  }
};

/**
 * Simple List of Timezones
 * @param state
 * @param action
 * @returns {Array}
 */
const timezones = (state = [], action) => {
  switch (action.type) {
    case types.TIMEZONES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const siteStats = (state = {}, action) => {
  switch (action.type) {
    case types.STATS_FETCH_START:
      return {
        ...state,
        [action.payload.siteId]: null,
      };
    case types.STATS_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.siteId]: [action.payload],
      };
    default:
      return state;
  }
};

/**
 * Simple List of siteModules
 * @param state
 * @param action
 * @returns {Array}
 */
const siteModules = (state = [], action) => {
  switch (action.type) {
    case types.SITE_MODULES_FETCH_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
  filter: '',
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.SITES_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.SITES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case types.REMOVE_FILTER:
      return {
        ...state,
        filter: '',
      };
    default:
      return state;
  }
};

export default combineReducers({
  bySiteId,
  allSitesIds,
  timezones,
  siteModules,
  siteStats,
  ui,
});
