angular.module('webUi.ui.tagmanagement.base', ['webUi.ui.siteState'])

    .config(['$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.tagmanagement', {
                    'abstract': true,
                    url: '/tagmanagement',
                    views: {
                        'mainContent@': {
                            templateUrl: 'ui/tagmanagement/base/base.tpl.html',
                            controller: 'TagmanagementBaseController'
                        }
                    },
                    resolve: {
                        hasTagEdit: ['securityContext',
                            function getPermissions(securityContext) {
                                return securityContext.hasPermission('TAG_EDIT');
                            }
                        ],
                        hasTagPublish: ['securityContext',
                            function getPermissions(securityContext) {
                                return securityContext.hasPermission('TAG_PUBLISH');
                            }
                        ],
                        unpublishedChanges: ['TagmanagementPublishingService',
                            function getUnpublishedChanges(TagmanagementPublishingService) {
                                return TagmanagementPublishingService.getUnpublishedChanges();
                            }
                        ],
                        hasUrlBuilderModule: ['securityContext', function (securityContext) {
                            return securityContext.hasModule('URL_BUILDER');
                        }]
                    }
                });
        }
    ])

    .controller('TagmanagementBaseController', ['$scope', '$state', 'securityContext', 'PubSubService', 'TagmanagementPublishingService', 'hasTagEdit', 'hasTagPublish', 'unpublishedChanges',
        function TagmanagementBaseController($scope, $state, securityContext, PubSubService, TagmanagementPublishingService, hasTagEdit, hasTagPublish, unpublishedChanges) {

            // if user has no role redirect to 403
            if (!securityContext.hasPermission('TAG_VIEW')) {
                $state.transitionToStateWithSite('site.error.403', {});
                return;
            }

            $scope.hasTagEdit = hasTagEdit;
            $scope.hasTagPublish = hasTagPublish;
            $scope.unpublishedChanges = unpublishedChanges;

            PubSubService.subscribeEnvironmentChange($scope, function(){
                TagmanagementPublishingService.getUnpublishedChanges().then(function(result){
                    $scope.unpublishedChanges = result;
                });
            });
        }
    ]);
