import { RulesTypes } from '~/common/modules/rules/types';
import i18n from '~/i18n';

export const ADDITIONAL_SOURCES_EVENTS = [RulesTypes.Engagement, RulesTypes.ExternalFact, RulesTypes.AiFact];

export const transformationTypes = {
  DATE_FORMAT: 'DATE_FORMAT',
  DATE_TO_EPOCH_SECONDS: 'DATE_TO_EPOCH_SECONDS',
  EPOCH_MILLIS_TO_SECONDS: 'EPOCH_MILLIS_TO_SECONDS',
  EPOCH_SECONDS_TO_DATE: 'EPOCH_SECONDS_TO_DATE',
  EPOCH_SECONDS_TO_MILLIS: 'EPOCH_SECONDS_TO_MILLIS',
  HASHING: 'HASHING',
  COUNTRY_CODE: 'COUNTRY_CODE',
  STRING_TO_ARRAY: 'STRING_TO_ARRAY',
  STRING_TO_NUMBER: 'STRING_TO_NUMBER',
  STRING_TO_BOOLEAN: 'STRING_TO_BOOLEAN',
  VALUE_MAPPING: 'VALUE_MAPPING',
};

export const mappingPropertyTypes = {
  STATIC_SOURCE_PROPERTY: 'STATIC_SOURCE_PROPERTY',
  EVENT_SOURCE_PROPERTY: 'EVENT_SOURCE_PROPERTY',
};

export const FieldLengths = {
  MIN: 2,
  MAX: 255,
} as const;

export const hashingOptions = [{ value: 'SHA256' }];

export const VALUEMAPPING_DEFAULT_OPTION = [
  { label: i18n.t('partners:eventConnector.valueTransformationsDefaults.source'), value: 'SOURCE' },
  { label: i18n.t('partners:eventConnector.valueTransformationsDefaults.custom'), value: 'CUSTOM' },
];
