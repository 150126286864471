import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Detail from '~/components/src/Form/Detail/Default';
import Details from '~/components/src/Form/Detail';
import { getDateAndTime } from '~/common/utils/DateUtil';

const UserDetail = ({ user, t, ts }) => (
  <div>
    <Detail label={t('user.email')} value={user.username} />
    <Detail label={t('user.name')} value={user.name} />
    <Detail label={t('user.defaultSite')} value={user.defaultSiteId}>
      {t('user.defaultSiteValue', { siteName: user.siteName, siteNr: user.defaultSiteNumber })}
    </Detail>
    <Detail label={t('user.phoneNumber')} value={user.phoneNumber} />
    <Detail label={t('user.whitelistedSubnets')} value={user.whitelistedSubnets} />
    <Detail label={t('user.restrictedSubnets')} value={user.restrictedSubnets} />
    <Details.Bool label={t('user.lock.locked')} value={user.lock?.locked} translations={ts} />
    <Detail textColor="red" label={t('user.lock.reason')} value={user.lock?.reason} />
    {user.lock?.locked && (
      <Detail label={t('users:user.lock.lockedBy')}>
        <div className="flex items-center gap-1">
          <span>{user.lock?.lockedBy}</span>
          <span className="text-sm text-blue-500">on {moment(user.lock?.lockedAt).format('DD/MM/YYYY')}</span>
        </div>
      </Detail>
    )}
    <Detail label={t('user.lastLogin')} value={user.lastLoginDate}>
      {getDateAndTime(user.lastLoginDate)}
    </Detail>
    <Detail label={t('user.client')} value={user.clientPojo && user.clientPojo.name} />
  </div>
);

UserDetail.propTypes = {
  user: PropTypes.object.isRequired,
  ts: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default UserDetail;
