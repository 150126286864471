import { get } from 'lodash';

export const getGroup = (state, groupId) => get(state, `support.tags.groups.byId[${groupId}]`);

export const canDeleteGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.templates && group.templates.length === 0;
};

export const getGroups = state => get(state, 'support.tags.groups.list') || [];

export const getGroupsSelect = state => {
  const groups = getGroups(state);
  return groups.map(item => ({
    value: { id: item.id, label: item.name },
    label: item.name,
  }));
};
