import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { DspFormPayload } from './types';

const apiPath = `${API_BASE}/v1/partners/amazondsp`;

const fetchAmazonAudience = (amazondspId: string, audienceId: string): Promise<any> =>
  Api.callGet(`${apiPath}/${amazondspId}/audience/${audienceId}`, {}).then(parseResponse);

const createAmazonDspAudience = (audienceId: string, payload: DspFormPayload): Promise<any> =>
  Api.callPost(`${apiPath}/${audienceId}/audience`, payload).then(parseResponse);

export const getCountryCodes = () => Api.callGet(`${apiPath}/countries`, {}).then(parseResponse);

export { fetchAmazonAudience, createAmazonDspAudience };
