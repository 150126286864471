/**
 *
 */
angular
    .module('webUi.ui.profiles.thirdPartyDataCollectors.form', ['webUi.ui.profiles.thirdPartyDataCollectors.base'])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.profiles.thirdPartyDataCollectors.add', {
                url: '/add',
                isReact: true,
                leftMenu: false,
            });
        },
    ]);
