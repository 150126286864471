import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import '../styles.scss';
import { tableContextWrapper, excludeContextualProps } from '../context';

const Cell = ({ style, className = '', children, isTable, testHook, ...props }) => {
  const Element = isTable ? 'td' : 'div';
  const DOMProps = excludeContextualProps(props);
  return (
    <Element className={cx('Table-cell', className, { [`t-${testHook}`]: !!testHook })} style={style} {...DOMProps}>
      {children === undefined && 'n/a'}
      {children !== undefined && children}
    </Element>
  );
};

Cell.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  isTable: PropTypes.bool.isRequired,
  testHook: PropTypes.string,
};

Cell.defaultProps = {
  isTable: false,
};

export default tableContextWrapper(Cell);
