angular.module('webUi.ui.tagmanagement.cookiePermission.list', [
    'webUi.ui.tagmanagement.cookiePermission.base',
    'ui.bootstrap',
    'webUi.ui.tagmanagement.cookiePermission.addGroupModal',
    'webUi.ui.tagmanagement.cookiePermission.removeGroupModal'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.cookiePermission.list', {
                url: '/list',
                isReact: true
            });
    }]);
