import React from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';

const RulePickerCard = props => {
  const {
    icon,
    title,
    subtitle,
    testHook,
    isDisabled,
    onClick,
    usedForFilters = false,
    isSelected = false,
    isSomeOfChildSelected = false,
    handleToggleClick,
  } = props;

  return (
    <div
      className={cx(
        `t-${testHook}`,
        'RulePickerCard group relative items-start justify-center gap-2 rounded-md border border-gray-300 bg-white p-2 text-gray-500',
        'hover:cursor-pointer hover:border-gray-800 hover:drop-shadow',
        {
          't-ruleDisabled pointer-events-none cursor-not-allowed opacity-50 grayscale': isDisabled,
          'flex flex-col': !usedForFilters,
        },
      )}
      onClick={onClick}
    >
      {usedForFilters && (
        <div className="absolute top-2 right-2">
          <div className="size-6 flex items-center justify-center border ">
            <Icons
              // eslint-disable-next-line no-nested-ternary
              icon={isSelected ? 'done' : isSomeOfChildSelected ? 'minus' : 'null'}
              className="size-5"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleToggleClick(isSelected);
              }}
            />
          </div>
        </div>
      )}
      <div className="flex w-11/12 items-center gap-2">
        {icon}
        <span className={cx('t-ruleCardHeaderName text-xl text-gray-800', { 'text-gray-400': isDisabled })}>
          {title}
        </span>
      </div>
      <p className="px-2 font-light text-gray-400 group-hover:text-gray-600">{subtitle}</p>
    </div>
  );
};

export default RulePickerCard;
