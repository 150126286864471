angular.module('webUi.ui.shared.adBlockNotification', ['ui.bootstrap'])

    .controller('AdBlockNotificationController',['$uibModalInstance','$scope',
        function AdBlockNotificationController(   $uibModalInstance , $scope) {

            /* modal actions */
            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
