import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '~/components';
import { useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';

import FiltersDashboard from './FiltersDashboard';
import FilterView from './FilterView';
import FilterEdit from './FilterEdit';
import { getAllFilters } from './dataService';
import { FiltersContext } from './FiltersContext';

const FiltersPage = ({ canEditProfiles }: { canEditProfiles: boolean }): React.ReactNode => {
  const { state: filters, isLoading, refetch: refetchFilters } = useApiWithState(() => getAllFilters());

  if (!filters || isLoading) {
    return <Spin />;
  }

  return (
    <FiltersContext.Provider value={{ canEditProfiles, filters, refetchFilters }}>
      <Routes>
        <Route path="/" element={<FiltersDashboard />} />
        <Route path="dashboard" element={<FiltersDashboard />} />
        <Route path="view/:filterId" element={<FilterView />} />
        <Route
          path="edit/:filterId"
          element={<PrivateRoute authenticated={canEditProfiles} component={FilterEdit} />}
        />
        <Route path="add" element={<PrivateRoute authenticated={canEditProfiles} component={FilterEdit} />} />
      </Routes>
    </FiltersContext.Provider>
  );
};

export default FiltersPage;
