import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeUrl, buildUrl } from '~/common';
import * as fromModals from '~/modals';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import Heading from '~/components/src/Heading';
import LinkIcon from '~/components/src/LinkIcon';
import Spinner from '~/components/src/Spinner';
import { isFetchingValueSets, getValueSets } from '../valueSets/selectors';
import { fetchValueSets, deleteValueSet } from '../valueSets/actions';
import ValuesSetsList from '../../components/ValuesSetsList';

const redirectToEditPage = valueSetsId => {
  changeUrl(`admin/urlbuilder/valueSets/edit/${valueSetsId}`);
};

class ValueSetsContainer extends Component {
  componentDidMount() {
    this.props.fetchValueSets();
  }

  render() {
    const { isFetching, isSiteAdmin, onDeleteClick, stateKey, list, t } = this.props;

    return (
      <section>
        <Heading title={t('urlBuilder:valueSets.header')} kind="h2">
          <LinkIcon
            icon="add"
            color="blue"
            className="h-8 w-8"
            url={buildUrl('admin/urlbuilder/valueSets/add')}
            disabled={!isSiteAdmin}
            testHook="addValueSet"
            tooltip={t('urlBuilder:valueSets.newValueSetButton')}
          />
        </Heading>
        {isFetching ? (
          <Spinner />
        ) : (
          <ValuesSetsList
            list={list}
            stateKey={stateKey}
            onEditClick={redirectToEditPage}
            onDeleteClick={onDeleteClick}
            t={t}
            isSiteAdmin={isSiteAdmin}
          />
        )}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: 'ADMIN/URLBUILDER/VALUESETS',
  list: getValueSets(state),
  isFetching: isFetchingValueSets(state),
});

const mapDispatchToProps = dispatch => ({
  fetchValueSets: () => dispatch(fetchValueSets()),
  onDeleteClick: (valueSet, t) => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: t('urlBuilder:valueSets.deleteConfirmationModal.header'),
        message: t('urlBuilder:valueSets.deleteConfirmationModal.message', {
          name: valueSet.predefinedValuesSetName,
        }),
        onConfirm: () => dispatch(deleteValueSet(valueSet.predefinedValuesSetId)),
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('urlBuilder'))(ValueSetsContainer);
