angular.module('webUi.service.support.contact', [
])

/**
 * A service for contacting synovite support related activities
 */
    .factory('SupportContactService', [function(){
	
        var svc = {
            getSupportPhone: function(){
                return '020-303-5800';
            },
            getSupportEmail: function(){
                return 'support@relay42.com';
            }
        };
	
        return svc;
    }]);
