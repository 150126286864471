angular.module('webUi.directive.svcolorpicker', [
    'colorpicker.module'
])

    .directive('svcolorpicker', function(){

        var directiveOptions = {
            restrict : 'A',
            scope: {
                colorAttribute: '='
            },
            templateUrl: 'directive/svcolorpicker/svcolorpicker.tpl.html',
            link: function (scope, element, attrs) {

                var showPalette = true;
                if ( !_.isUndefined(attrs.svcolorpickerHidePalette) ){
                    showPalette = false;
                    if ( attrs.svcolorpickerHidePalette === 'false' || attrs.svcolorpickerHidePalette === false ){
                        showPalette = true;
                    }
                }
                scope.showPalette = showPalette;
                scope.pickerEnabled = _.isUndefined(attrs.pickerEnabled) ? true: (attrs.pickerEnabled === 'false' ? false : true);
            }

        };

        return directiveOptions;
    });
