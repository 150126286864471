import { changeUrl } from '~/common';
import { showSuccess } from '~/notificationCenter';
import i18n from '~/i18n';
import ContextClientDomainService from './dataService';
import * as types from './types';
import * as selectors from './selectors';

/**
 *
 * @param clientId
 * @returns {function(*)}
 */
export const fetchClientDomains = clientId => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_START });
  return ContextClientDomainService.fetchDomains(clientId)
    .then(response => {
      const responseState = response.map(item => ({ ...item, clientId }));
      dispatch({
        type: types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_SUCCESS,
        payload: [...responseState],
      });
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_FAIL, error }));
};

export const fetchClientDomainsIfNeeded = clientId => (dispatch, getState) => {
  if (selectors.shouldFetchDomains(getState())) {
    return dispatch(fetchClientDomains(clientId));
  }
  return Promise.resolve();
};

export const deleteClientDomain = domain => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_START });
  return ContextClientDomainService.removeDomain(domain.clientDomainId)
    .then(() => {
      showSuccess({ body: i18n.t('clients:view.domains.api.deletionSuccess') });
      dispatch({
        type: types.CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_SUCCESS,
        payload: { ...domain },
      });
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_FAIL, error }));
};

export const saveClientDomain = (values, clientId, isEdit) => dispatch => {
  dispatch({ type: types.CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_START });
  const model = { ...values, clientId };
  return ContextClientDomainService.saveDomains(model, isEdit)
    .then(response => {
      dispatch({
        type: types.CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_SUCCESS,
        payload: {
          ...model,
          clientDomainId: response,
        },
      });
      changeUrl(`context/clients/view/${clientId}`);
    })
    .catch(error => dispatch({ type: types.CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_FAIL, error }));
};
