import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import i18n from '~/context/i18n';
import * as fromModals from '~/modals';
import { withRouter } from '~/common/withRouter';
import {
  Export,
  fetchScheduledExports,
  fetchManualExports,
  setSelectedRange,
  deleteScheduledExport,
  downloadManualExport,
  isFetchingScheduledExports,
  getScheduledExportsData,
  isFetchingManualExports,
  getManualExportsData,
  getSelectedRange,
  getError,
} from './index';

const mapStateToProps = state => ({
  isFetchingScheduledExports: isFetchingScheduledExports(state),
  scheduledExportsData: getScheduledExportsData(state),
  isFetchingManualExports: isFetchingManualExports(state),
  manualExportsData: getManualExportsData(state),
  selectedRange: getSelectedRange(state),
  error: getError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchScheduledExports: () => {
    dispatch(fetchScheduledExports());
  },
  fetchManualExports: days => {
    dispatch(fetchManualExports(days));
  },
  setSelectedRange: selectedRange => {
    dispatch(setSelectedRange(selectedRange));
  },
  onDeleteExport: (type, id) => {
    dispatch(
      fromModals.showDeleteModal({
        title: i18n.t('exports:actions.delete.header'),
        message: i18n.t('exports:actions.delete.confirmationText', { name: type }),
        onConfirm: () => dispatch(deleteScheduledExport(id)),
      }),
    );
  },
  downloadManualExport: id => {
    dispatch(downloadManualExport(id));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), translate('exports'))(Export);
