import * as d3 from 'd3';
import { ADD_BUTTON_LENGTH, ADD_BUTTON_LINE_LENGTH, CARD_HEIGHT, CARD_WIDTH, ZOOM_MIN_LIMIT } from '../constants';

const HEADER_HEIGHT = 125;
const RIGHT_PANEL_WIDTH = 320;
const CANVAS_PADDING = 50;

const getChartWidth = (nodes: any[]) => {
  const xOrigins = nodes.map(({ x }) => x);
  const minX = Math.min.apply(null, xOrigins);
  const maxX = Math.max.apply(null, xOrigins);
  return Math.abs(minX) + maxX + CARD_WIDTH + ADD_BUTTON_LINE_LENGTH + ADD_BUTTON_LENGTH;
};

const getChartHeight = (nodes: any[]) => {
  const yOrigins = nodes.map(({ y }) => y);
  const minY = Math.min.apply(null, yOrigins);
  const maxY = Math.max.apply(null, yOrigins);
  return Math.abs(minY) + maxY + CARD_HEIGHT;
};

const getZoomViewportDimensions = (zoomBaseElement: any) => {
  let zoomBBox: any = {};
  try {
    zoomBBox = zoomBaseElement.getBBox();
  } catch {
    /*
        This try-catch is needed to avoid the "NS_ERROR_FAILURE" error that happens in Firefox
        Firefox throws an error when .getBBox() is called on an SVG element that is not visible
        This error happens only if Angular JO is rendered and React JO is hidden
        The error will go away by itself once we migrate JO to React
        TODO: Remove try-catch once we migrate JO to React
      */
  }

  const zoomViewportHeight = zoomBBox?.height || window.innerHeight - HEADER_HEIGHT;
  // For some reason browser returns incorrect "zoomBBox.width", so we have to fallback to hardcoded value
  const zoomViewportWidth = window.innerWidth - RIGHT_PANEL_WIDTH;

  return [zoomViewportWidth, zoomViewportHeight];
};

const getInitialZoom = (
  chartWidth: number,
  chartHeight: number,
  zoomViewportWidth: number,
  zoomViewportHeight: number,
) => {
  const availableWidth = zoomViewportWidth - CANVAS_PADDING * 2;
  const widthRatioToFit = availableWidth / chartWidth;

  const availableHeight = zoomViewportHeight - CANVAS_PADDING * 2;
  const heightRatioToFit = availableHeight / chartHeight;

  const ratio = widthRatioToFit < heightRatioToFit ? availableWidth / chartWidth : availableHeight / chartHeight;

  if (ratio > 1) {
    // Initial zoom can't be bigger than 1
    return 1;
  } else if (ratio < ZOOM_MIN_LIMIT) {
    // Initial zoom can't be smaller than minimum zoom limit
    return ZOOM_MIN_LIMIT + 0.1;
  }

  return ratio;
};

export const getInitialChartTransform = (nodes: any[], zoomBaseElement: any) => {
  const chartWidth = getChartWidth(nodes);
  const chartHeight = getChartHeight(nodes);

  const [zoomViewportWidth, zoomViewportHeight] = getZoomViewportDimensions(zoomBaseElement);

  const initialZoom = getInitialZoom(chartWidth, chartHeight, zoomViewportWidth, zoomViewportHeight);

  const initialX = CANVAS_PADDING;

  const yOrigins = nodes.map(({ y }) => y);
  const minY = Math.min.apply(null, yOrigins);

  const chartHeightInScreenUnits = chartHeight * initialZoom;
  const topLeftChartCornerOffset = Math.abs(minY) * initialZoom;
  const initialY = (zoomViewportHeight - chartHeightInScreenUnits) / 2 + topLeftChartCornerOffset;
  const initialPositionTransform = d3.zoomIdentity.translate(initialX, initialY).scale(initialZoom);

  return initialPositionTransform;
};
