angular.module('webUi.service.common.remoteConnectionConfigService', [
    'restangular'
])

    .factory('RemoteConnectionConfigService', [
        'Restangular',
        '$q',
        function(Restangular, $q) {

            var BASE_PATH = '/remoteConnectionTest';

            /**
         * @returns {String}
         */
            var getBasePath = function(){
                return BASE_PATH;
            };

            /**
         * Available connection types
         * @type {{key: string, value: string}[]}
         */
            var getConnectionTypes = function getConnectionTypes() {
            //TODO: replace with call to the server
            //return connectionTypes;
                var promiseConection = $q.defer();
                var connectionType= [
                    {'key': 'SFTP', 'value': 'sftp'},
                    {'key': 'FTP', 'value': 'ftp'},
                    {'key': 'FTPS', 'value': 'ftps'}
                ];
                promiseConection.resolve(connectionType);
                return promiseConection.promise;
            };
            var getLoginTypes = function getLoginTypes() {
                var promiseLogin = $q.defer();
                //TODO: replace with call from the server
                var loginTypes = [
                    {'key': 'ANONYMOUS', 'value': 'Anonymous'},
                    {'key': 'NORMAL', 'value': 'Normal (username and password)'}
                ];

                promiseLogin.resolve(loginTypes);
                return promiseLogin.promise;
            };

            /**
         * Filters the available login types based on the connection type. E.g for sftp only Anonymous
         * @param connectionType
         * @returns {Object}
         */
            var getLoginTypesForConnectionType = function getLoginTypesForConnectionType(connectionType, loginTypes) {
                if (connectionType !== 'SFTP') {
                    return loginTypes;
                } else {
                    return _.where(loginTypes, {'key': 'NORMAL'});
                }
            };

            var getPortForConnectionType = function getPortForConnectionType(connectionType) {
                return (connectionType === 'SFTP') ? 22 :21;
            };


            /**
         * Validates a port number
         * @param portNumber
         * @returns {boolean}
         */
            var isValidPortNumber = function isValidPortNumber(portNumber) {
                if (!portNumber) {
                    return true;
                }

                var _port = parseInt(portNumber, 10);
                if (_port > 0 && _port < 65535) {
                    return true;
                }
                return false;
            };
            /**
         * Validate Connection
         * @param connectionConfig
         * @returns {Deferred}
         */
            var validateConnection = function validateConnection(connectionConfig) {
                var validationConnectionPromise = $q.defer();
                Restangular.all(BASE_PATH + '/check').post(connectionConfig).then(
                    function onSuccess(result) {
                        if(result) {
                            validationConnectionPromise.resolve(Restangular.stripRestangular(result));
                        }
                    });
                return validationConnectionPromise.promise;
            };

            return {
                getBasePath: getBasePath,
                getConnectionTypes: getConnectionTypes,
                getLoginTypes: getLoginTypes,
                getLoginTypesForConnectionType: getLoginTypesForConnectionType,
                getPortForConnectionType: getPortForConnectionType,
                isValidPortNumber: isValidPortNumber,
                validateConnection: validateConnection
            };
        }]);

