/**
 * @namespace webUi.directive.editable.datepicker
 */
angular.module('webUi.directive.editable.datepicker', ['ui.validate'])

    .controller('DatePickerEditingController',
        [
            '$scope',
            '$timeout',
            'ValidService',

            /**
         *
         * @param $scope
         * @param PubSubService
         * @param $timeout
         * @constructor
         */
            function DatePickerEditingController ($scope, $timeout, ValidService) {

                $scope.editableObject.value = ($scope.editableObject.value ? new Date($scope.editableObject.value) : null);

                $scope.$watch('customValidation', function(newValue) {
                    if(!ValidService.isEmpty(newValue)) {
                        ValidService.setValidationTokens(newValue, $scope.editableForm.date, $scope.editableObject.value);
                    }
                });

                $scope.state.beforeSave = function() {
                    return $scope.editableForm.$valid;
                };

                $timeout(function() {
                    $('.datetimepicker-wrapper input').click();
                });
            }
        ]
    );
