import React, { useState } from 'react';
import moment from 'moment';
import { useAPI } from '~/common/ApiHooks';
import { SecurityContext } from '~/common/types';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showError, showSuccess } from '~/notificationCenter/actions';
import { triggerHistoricProcessing } from '~/workflows/dataService';
import { getAngularService } from 'ReactAngular/react.service';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { DEFAULTS, HPWorkflowTypes } from './constants';
import { HPContext } from './context';
import { HpOptionsType, Range, TriggerHPProps } from './types';
import { mapReprocessingModes } from './util';

const TriggerHP = ({
  hasGoals,
  hasExits,
  journeyId,
  workflowType = HPWorkflowTypes.Journey,
  hideModal,
  fetchJourneyData,
}: TriggerHPProps) => {
  const [selectedHpOptions, setSelectedHpOptions] = useState<HpOptionsType[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const { data: reprocessingModes, isLoading: isReprocessingModesLoading } = useAPI(async () =>
    mapReprocessingModes(journeyId, workflowType),
  );

  const { data, isLoading } = useAPI(async () => {
    const SecurityService = getAngularService(document, 'SecurityService');
    const context = (await SecurityService.getSecurityContext()) as SecurityContext;
    const { currentSiteId, sites } = context;
    const retentionMonthsCount = sites[currentSiteId]?.dataRetentionMonths || DEFAULTS.DATA_RETENTION_MONTHS;
    const maxAllowedHistoricProcessingDays =
      sites[currentSiteId]?.maxAllowedHistoricProcessingDays || DEFAULTS.ALLOWED_HP_DAYS;

    return { maxAllowedHistoricProcessingDays, retentionMonthsCount };
  });

  const maxAllowedHistoricProcessingDays = data?.maxAllowedHistoricProcessingDays || DEFAULTS.ALLOWED_HP_DAYS;
  const daysDiff =
    moment.duration(moment().diff(moment().subtract(data?.retentionMonthsCount, 'months'))).asDays() || 1;
  const maxAllowedSelectionDays = daysDiff <= DEFAULTS.DATA_RETENTION_DAYS ? daysDiff : DEFAULTS.DATA_RETENTION_DAYS;

  const [range, setRange] = useState<Range>({
    startDate: moment().subtract(maxAllowedHistoricProcessingDays - 1, 'days'),
    endDate: moment(),
  });

  const handleHPTrigger = () => {
    const hasOtherFacts = selectedHpOptions.find(mode => !mode.hasOnlyCustomerFacts);
    const reprocessingModes = selectedHpOptions.map(({ id }) => id);
    const rangeFrom = hasOtherFacts ? range.startDate.valueOf() : null;
    const rangeTo = hasOtherFacts ? range.endDate.valueOf() : null;

    const payload = {
      rangeFrom,
      rangeTo,
      reprocessingModes,
    };

    triggerHistoricProcessing(journeyId, payload)
      .then(() => {
        showSuccess({ header: i18n.t('workflow:orchestration.historicProcessing.success', { workflowType }) });
        fetchJourneyData();
        hideModal();
      })
      .catch(() => {
        showError({
          header: i18n.t('workflow:orchestration.historicProcessing.fail', { workflowType }),
          body: i18n.t('common:tryAgainLater'),
        });
      });
  };

  if (isLoading || isReprocessingModesLoading) return <Spin />;

  return (
    <HPContext.Provider
      value={{
        hasGoals,
        hasExits,
        reprocessingModes: reprocessingModes || [],
        selectedHpOptions,
        currentStep,
        setCurrentStep,
        setSelectedHpOptions,
      }}
    >
      {currentStep === 1 && <Step1 hideModal={hideModal} />}
      {currentStep === 2 && (
        <Step2
          maxAllowedHistoricProcessingDays={maxAllowedHistoricProcessingDays}
          maxAllowedSelectionDays={maxAllowedSelectionDays}
          handleHPTrigger={handleHPTrigger}
          range={range}
          setRange={setRange}
        />
      )}
    </HPContext.Provider>
  );
};
export default TriggerHP;
