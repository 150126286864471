/* eslint-disable no-prototype-builtins */
angular.module('webUi.service.tagcounter', [
    'restangular',
    'webUi.common.Utils'
])

    .factory('TagCounterService', ['Restangular', '$q', 'Utils', 'ChartService', function (Restangular, $q, Utils, ChartService) {

        // the base path through which all calls should go
        var basePath = 'tagmanagement/';

        var getCounterIds = function (counters) {
            var counterIds = [];
            _.forEach(counters, function (counter) {
                counterIds.push(counter.counterId);
            });
            return counterIds;
        };

        /**
         * Calculates sum of integers array - used within a loop, so that we don't get jslint error
         */
        var sumArray = function (array) {
            return _.reduce(array, function (sum, element) {
                return sum + element;
            });
        };

        var counterStatsResolver = function (toReturn) {
            return function (result) {
                var toReturnObjectStats = getCounterTableSeriesStats(Utils.sanitizeRestangularOne(result));
                toReturn.resolve({
                    'series': toReturnObjectStats.series,
                    'tableStats': toReturnObjectStats.tableStats
                });
            };
        };

        var getCounterTableSeriesStats = function getCounterTableSeriesStats(serverStats) {
            //initialize the series
            var stats = {
                series: [],
                tableStats: []
            };

            var colorIndex = 2;
            for (var key in serverStats) {
                if (serverStats.hasOwnProperty(key)) {
                    //key is counter name, stats[key] holds the stats for the current counter
                    stats.series.push({
                        name: key,
                        data: _.pluck(serverStats[key], 'requestCount'),
                        pointInterval: 24 * 36e5,
                        pointStart: serverStats[key].length === 0 ? 0 : serverStats[key][0].key.dayTimestamp,
                        color: ChartService.getColor(colorIndex)
                    });
                    colorIndex++;
                    //sum the stats for one counter to show in table
                    var totalStats = sumArray(_.pluck(serverStats[key], 'requestCount'));
                    var name = key;

                    stats.tableStats.push({
                        'totalStats': totalStats,
                        'name': name
                    });
                }
            }
            return stats;
        };

        return {
            // retrieves the base path
            getBasePath: function () {
                return basePath;
            },

            getTagCounter: function (counterId) {

                var counter = $q.defer();
                Restangular.one(basePath + 'tagCounters', counterId).get().then(function (result) {
                    if (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        counter.resolve(result);
                    }
                });
                return counter.promise;
            },

            getTagCounters: function () {
                return Restangular.all(basePath + 'tagCounters').getList();
            },


            updateName: function (counterId, name) {
                return Restangular.one(basePath + 'tagCounters').post('name', {
                    counterId: counterId,
                    name: name
                });
            },

            updateNumber: function (counterId, number) {
                return Restangular.one(basePath + 'tagCounters').post('number', {
                    counterId: counterId,
                    number: number
                });
            },

            updateDescription: function (counterId, description) {
                return Restangular.one(basePath + 'tagCounters').post('description', {
                    counterId: counterId,
                    description: description
                });
            },

            updateShownOnDashboard: function (counterId, shownOnDashboard) {
                return Restangular.one(basePath + 'tagCounters').post('shownOnDashboard', {
                    counterId: counterId,
                    shownOnDashboard: shownOnDashboard
                });
            },

            deleteTagCounter: function (counterId) {
                return Restangular.one(basePath + 'tagCounters', counterId).remove();
            },

            postTagCounter: function (counter) {
                return Restangular.all(basePath + 'tagCounters').post(counter);
            },

            /**
             * Get counter stats for the tag counters that are shown on dashboard
             * @param days number of days back in time
             * returns an object in the form of
             *    {
     *		"series" : seriesForHighcharts,
     *		"tableStats" : totalStatsForTable
     *	}
             */
            getCounterStats: function (days) {
                var toReturn = $q.defer();

                var daysPojo = {'days': days};

                Restangular.one(basePath + 'tagCounterStatsAll').get(daysPojo).then(
                    function (result) {
                        var toReturnObjectStats = getCounterTableSeriesStats(Utils.sanitizeRestangularOne(result));
                        toReturn.resolve({
                            'series': toReturnObjectStats.series,
                            'tableStats': toReturnObjectStats.tableStats
                        });
                    });
                return toReturn.promise;
            },

            /**
             * Gets the tag counter stats for the selected counters
             * @param counters an array of counter objects for which the stats is retrieved
             * @param days the number of days selected
             * returns an object in the form of
             *    {
         *      "series" : seriesForHighcharts,
         *      "tableStats" : totalStatsForTable
         *    }
             */
            getCounterStatsForCounters: function (days, counters) {
                var toReturn = $q.defer();
                var counterIds = getCounterIds(counters);
                var daysPojo = {'days': days, 'ids': counterIds};
                Restangular.one(basePath + 'tagCounterStatsSelected')
                    .get(daysPojo)
                    .then(counterStatsResolver(toReturn));

                return toReturn.promise;
            },

            getCounterStatsForCountersRange: function (rangeFrom, rangeTo, counters) {
                var toReturn = $q.defer();
                var counterIds = getCounterIds(counters);
                var dateRangePojo = {rangeFrom: rangeFrom, rangeTo: rangeTo, 'ids': counterIds};
                Restangular.one(basePath + 'tagCounterStatsSelectedRange')
                    .get(dateRangePojo)
                    .then(
                        counterStatsResolver(toReturn),
                        function onError(error) {
                            error.errorHandled = true;
                            toReturn.reject();
                        });
                return toReturn.promise;
            },

            /**
             * Return a promise for stats of just one counter identified by counterId
             */
            getTagCounterStats: function (counterId) {
                var counterStats = $q.defer();
                Restangular.one(basePath + 'tagCounterStats', counterId).get()
                    .then(function (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        counterStats.resolve(result);
                    });
                return counterStats.promise;
            },

            /**
             * Return tag counter numbers for existing counters
             */
            getTagCounterNumbers: function () {

                var counterNumbers = $q.defer();
                Restangular.one(basePath + 'tagCounterNumbers').get()
                    .then(function (result) {
                        result = Utils.sanitizeRestangularOne(result);
                        counterNumbers.resolve(result);
                    });
                return counterNumbers.promise;
            },

            getNextAvailableCounterNumber: function (counterNumbers) {
                var min = 1;
                while (_.contains(counterNumbers, min)) {
                    min++;
                }
                return min;
            }
        };
    }]);

