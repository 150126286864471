import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import fileSize from 'filesize';
import Tooltip from '~/components/src/Tooltip';
import { components } from '~/components/src/Table';
import './styles.scss';

const { Row, Cell, RowActions } = components;

const ManualExportsComponent = props => {
  const { row, actions } = props;

  const getDetails = obj => {
    const arr = [];
    Object.keys(obj).map(key => arr.push(`${key}: ${obj[key]}`));
    return arr.join(',');
  };

  const fileSizeLabel = row.fileSize ? fileSize(row.fileSize) : '-';

  return (
    <Row withActions>
      <Cell className="u-size2of12" title={row.type}>
        {row.type}
      </Cell>
      <Cell className="u-size1of12" title={row.status}>
        {row.status}
      </Cell>
      <Cell className="u-size1of12" title={row.from}>
        {row.from}
      </Cell>
      <Cell className="u-size1of12" title={row.to}>
        {row.to}
      </Cell>
      <Cell className="u-size1of12" title={row.createdOn}>
        {row.createdOn}
      </Cell>
      <Cell className="u-size1of12" title={row.createdBy}>
        <div className="truncate">
          <Tooltip tooltip={row.createdBy} placement="top">
            <span>{row.createdBy}</span>
          </Tooltip>
        </div>
      </Cell>
      <Cell className="u-size1of12" title={row.finishedOn}>
        {row.finishedOn}
      </Cell>
      <Cell className="u-size1of12" title={fileSizeLabel}>
        {fileSizeLabel}
      </Cell>
      <Cell
        className="u-size2of12"
        title={_.isEmpty(row.requestConfiguration) ? '-' : getDetails(row.requestConfiguration)}
      >
        {_.isEmpty(row.requestConfiguration) ? '-' : getDetails(row.requestConfiguration)}
      </Cell>
      <RowActions className="u-size1of12 u-alignCenter flex" actions={row.downloadable ? actions : []} />
    </Row>
  );
};

ManualExportsComponent.propTypes = {
  row: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
};

export default ManualExportsComponent;
