import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Btn from '~/components/src/Btn';
import { hideModal } from '../actions';
import ModalWrapper from './ModalWrapper';
import ModalHeader from './ModalHeader';
import './styles.scss';

const NotificationModal = ({ title, notification, confirmText, hideModal }) => (
  <ModalWrapper>
    <ModalHeader title={title} onCloseClick={hideModal} />
    <div className="Modal-body p-4">{notification}</div>
    <div className="modal-footer">
      <Btn color="blue" onClick={hideModal}>
        {confirmText}
      </Btn>
    </div>
  </ModalWrapper>
);

NotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  notification: PropTypes.any.isRequired,
  confirmText: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(null, { hideModal })(NotificationModal);
