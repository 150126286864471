import React from 'react';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import InputElement from '~/components/src/Form/Elements/InputElement';
// import { intToString } from '~/common';
import i18n from '~/i18n';
import './style.scss';

function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <FieldWrapper label={i18n.t(`common:tableColumns.${id}`)}>
      <div className="NumberRangeColumnFilter">
        <InputElement
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          min={0}
        />
        <span className="NumberRangeColumnFilter-space">-</span>
        <InputElement
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          min={0}
        />
      </div>
    </FieldWrapper>
  );
}

export default NumberRangeColumnFilter;
