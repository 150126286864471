import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, getFormValues } from 'redux-form';
import { isRequired, isValueUniqueCaseInsensitive } from '~/common';
import { maxLength } from '~/common/formValidation';
import { Notification } from '~/components';
import FormActions from '~/components/src/Form/ActionsDefault';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Form from '~/components/src/Form/Form';
import { CUSTOMER_FACT_EVENTS } from '~/gaActions';
import PropertiesForm from '../components/PropertiesForm';
import { ALLOWED_NUMBER_OF_PROPERTIES, CUSTOMER_FACT_USED_IN } from '../customerFacts/types';
import { hasSpecialCharacters } from '../util';

const shouldBe64CharactersOrLess = maxLength(64);

const externalFactNameIsUnique = (value, allValues, props) => {
  if (!props.isNew) return undefined;
  return isValueUniqueCaseInsensitive(value.trim(), props.externalFactNames);
};

export const CustomerFactForm = ({
  selectedPropertyId,
  initialValues,
  factName,
  propertiesWithUsage,
  handleSubmit,
  submitting,
  cancelHref,
  onDependantIconClick,
  setUserIsEditing,
  userIsEditing,
  t,
  form,
  touch,
}) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        label={t('edit.name')}
        name="name"
        component={ReduxFormInputField}
        placeholder={t('edit.namePlaceholder')}
        validate={[isRequired, shouldBe64CharactersOrLess, externalFactNameIsUnique]}
        type="text"
        disabled={!!initialValues.customerFactId}
      />
      {hasSpecialCharacters(factName) && (
        <Notification className="my-4" kind="warning">
          {t('edit.nameContainsSpecialCharacters')}
        </Notification>
      )}
      <Field
        label={t('edit.description')}
        name="description"
        component={ReduxFormTextAreaField}
        placeholder={t('edit.descriptionPlaceholder')}
      />
      <Field
        label={t('edit.type')}
        name="type"
        validate={[isRequired]}
        component={ReduxFormSelectField}
        options={[
          { value: 'EXTERNAL', label: t('general.typeExternal') },
          { value: 'AI', label: t('general.typeAI') },
        ]}
        touch={touch}
      />
      <Field
        name="propertiesWithUsage"
        formName={form}
        component={PropertiesForm}
        elementId={initialValues.customerFactId}
        onDependantIconClick={onDependantIconClick}
        selectedPropertyId={selectedPropertyId}
        initialValues={initialValues}
        list={propertiesWithUsage}
        stateKey={`PROFILES/EXTERNALFACTS/${initialValues.customerFactId || 'NEW'}/PROPERTIES/EDIT/TABLE`}
        setUserIsEditing={setUserIsEditing}
        userIsEditing={userIsEditing}
        usedIn={CUSTOMER_FACT_USED_IN}
        maxNumberOfProperties={ALLOWED_NUMBER_OF_PROPERTIES}
        t={t}
      />
    </div>
    <FormActions
      t={t}
      submitting={submitting}
      cancelHref={cancelHref}
      gaAction={form === 'customerFactEdit' ? CUSTOMER_FACT_EVENTS.EDITED : CUSTOMER_FACT_EVENTS.CREATED}
    />
  </Form>
);

CustomerFactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  selectedPropertyId: PropTypes.string,
  onDependantIconClick: PropTypes.func,
  userIsEditing: PropTypes.bool,
  setUserIsEditing: PropTypes.func,
  t: PropTypes.func.isRequired,
};

/* State Props */
const mapStateToProps = (state, ownProps) => ({
  factName: getFormValues(ownProps.form)(state).name,
  propertiesWithUsage: getFormValues(ownProps.form)(state).propertiesWithUsage,
});

export default compose(connect(mapStateToProps, null))(CustomerFactForm);
