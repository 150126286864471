import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from 'react-redux-composable-list';
import Sort from './presenter';
import { tableContextWrapper } from '../context';

function mapStateToProps(state, { sortKey, stateKey }) {
  const { sortKey: stateSortKey, isReverse: stateIsReverse } = selectors.getSort(state, stateKey);
  const isActive = stateSortKey === sortKey;
  const isReverse = stateIsReverse && isActive;

  return {
    isActive,
    isReverse,
  };
}

function mapDispatchToProps(dispatch, { sortKey, sortFn, stateKey }) {
  return {
    onSort: bindActionCreators(() => actionCreators.doTableSort(stateKey, sortKey, sortFn), dispatch),
  };
}

export default tableContextWrapper(connect(mapStateToProps, mapDispatchToProps)(Sort));
