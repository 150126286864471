import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import { getClientsForSelect, fetchClientsIfNeeded } from '~/context/clients';
import { getUserById, editUser, fetchUser } from '~/context/users';
import { withRouter } from '~/common/withRouter';
import UserFormEdit from '../components/UserFormEdit';

const USER_FORM_EDIT = 'CONTEXT/FORM/USER_EDIT';
const USERS_TRANSLATION_KEY = 'users';

const selector = formValueSelector(USER_FORM_EDIT);

export class UserNewPage extends Component {
  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchClients();
  }

  render() {
    const { props } = this;
    return !props.initialValues ? (
      <Spinner />
    ) : (
      <Page>
        <SmallWrapper>
          <Heading title={props.t('edit.header')} />
          <Panel>
            <UserFormEdit {...props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      backHref: buildUrl(`context/users/view/${ownProps?.params.userId}`),
      clients: getClientsForSelect(state),
      initialValues: getUserById(state, ownProps?.params.userId),
      isUserLocked: selector(state, 'lock.locked'),
    }),
    (dispatch, ownProps) => ({
      fetchUser: () => dispatch(fetchUser(ownProps?.params.userId)),
      fetchClients: () => dispatch(fetchClientsIfNeeded()),
      onSubmit: values => dispatch(editUser(values)),
    }),
  ),
  reduxForm({
    form: USER_FORM_EDIT,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate([USERS_TRANSLATION_KEY]),
)(UserNewPage);
