/**
 *
 * Rewriting of a tree directive (almost) from scratch
 */
angular.module('webUi.directive.simpleTree', [
    'webUi.directive.svfocus'
])
    .directive('simpleTree', function () {
        return {
            restrict: 'E',
            scope: {
                nodes: '=',
                vars: '&' // Extra variables
            },
            templateUrl: 'directive/treeSimple/simpleTree.tpl.html',
            controller: 'SimpleTreeController'
        };
    })
/**
 *
 */
    .controller('SimpleTreeController', ['$scope', 'TreeService', '$uibModal', 'Utils',
        function SimpleTreeController($scope, TreeService, $uibModal, Utils) {

            $scope.initializeNode = function (node) {
                node.tree = $scope.nodes;
                node.visible = true;
                node.expanded = node.expanded ? true : false;
            };

            $scope.assignParent = function (node, parentNode) {
                node.parent = parentNode;
            };

            //retry the vars property which contains info on whether the nodes are draggable, droppable and
            // the name of the onDrop functions
            $scope.localVars = $scope.vars() || {};

            $scope.isEmpty = function isEmpty(val){
                return _.isEmpty(val);
            };

            //callback to execute when expanding a node
            $scope.onNodeExpand = $scope.localVars.onNodeExpand;

            $scope.toggleNodeExpand = function (node) {
                node.expanded = !node.expanded;
                if ($scope.onNodeExpand) {
                    if (!node.firstExpanded) {
                        var subTreeCallbackResult = $scope.onNodeExpand(node.key);

                        if (Utils.isPromise(subTreeCallbackResult)) {
                            subTreeCallbackResult.then(function (subTree) {
                                node.children = subTree;
                            });
                        // if the callback returned true, update
                        } else if (subTreeCallbackResult) {
                            node.children = subTreeCallbackResult;
                        }
                        node.firstExpanded = true;
                    }

                }
            };

            $scope.getNodeIcon = function (node) {
                if (_.isEmpty(node.children)) {
                    return node.closeIcon || 'r42-folder-empty-closed';
                } else {
                    if (node.expanded) {
                        return node.openIcon || 'r42-folder-full-open';
                    } else {
                        return node.closeIcon || 'r42-folder-full-closed';
                    }
                }
            };

            $scope.TreeService = TreeService;
        }
    ]);
