import React, { ReactElement, useEffect, useState } from 'react';
import { Heading, Notification, Page, Tooltip } from '~/components';
import { buildUrl, useAPI, changeUrl } from '~/common';
import i18n from '~/i18n';
import Icons from '~/components/src/Icons';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import Spin from '~/components/src/Spin';
import moment from 'moment';
import { Panel } from '~/components/src/Containers';
import LinkIcon from '~/components/src/LinkIcon';
import { fetchImports } from './dataService';
import { ImportType } from './types';

type ImportsType = {
  isSiteAdmin: boolean;
  isSupportUser: boolean;
};

function Imports({ isSiteAdmin, isSupportUser }: ImportsType): ReactElement {
  const { data, isLoading } = useAPI(() => fetchImports());
  const [filteredFiles, setFilteredFiles] = useState(data);
  const [query, setQuery] = useState('');

  const redirectToViewPage = (importId: string) => {
    changeUrl(`admin/imports/view/${importId}`);
  };

  useEffect(() => {
    setFilteredFiles(data);
  }, [data]);

  useEffect(() => {
    if (query) {
      const newFiles = data?.filter(
        (importFile: ImportType) =>
          importFile.name.toLowerCase().includes(query) || importFile.importId.toLowerCase().includes(query),
      );
      setFilteredFiles(newFiles || []);
    } else {
      setFilteredFiles(data);
    }
  }, [query]);

  const isEmpty = filteredFiles?.length === 0;

  return (
    <Page>
      <Heading title={i18n.t('adminImports:imports')}>
        <SearchElement
          value={query}
          onChange={e => setQuery(e.target.value.toLowerCase())}
          placeholder={i18n.t('common:actions.search')}
        />
        <LinkIcon
          url={buildUrl('admin/imports/add')}
          tooltip={i18n.t('adminImports:new')}
          icon="add"
          color="blue"
          disabled={!(isSiteAdmin || isSupportUser)}
          testHook="addNew"
        />
      </Heading>
      <Panel className="flex-1">
        {isEmpty && (
          <Notification>
            <p>{i18n.t('adminImports:notifications.emptyList')}</p>
          </Notification>
        )}
        {!isEmpty && isLoading ? (
          <Spin />
        ) : (
          <div className="flex flex-col">
            {filteredFiles?.map((imp: ImportType) => (
              <div
                key={imp.importId}
                className="t-import relative flex cursor-pointer flex-col flex-wrap items-center justify-between gap-2 border-b border-gray-200 py-2 md:flex-row"
                onClick={() => redirectToViewPage(imp.importId)}
              >
                <Tooltip tooltip={`${imp.name}`}>
                  <div className="flex items-start gap-2 md:items-center">
                    <Icons icon="yamlFile" className="h-6 w-6 text-gray-400 md:h-8 md:w-8" />
                    <div className="flex flex-1 flex-col">
                      <p className="font-medium text-gray-600 md:max-w-prose">{imp.importId}.yaml</p>
                      <p className="text-gray-400 md:max-w-prose md:truncate">{imp.name}</p>
                    </div>
                  </div>
                </Tooltip>
                <div className="ms:justify-end flex w-full items-end justify-between md:w-auto md:flex-col">
                  <p className="text-sm">{moment(imp.lastModifiedAt).format('DD MMM yyyy')}</p>
                  <p className="text-sm text-gray-400">{imp.lastModifiedBy}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </Panel>
    </Page>
  );
}

export default Imports;
