angular.module('webUi.service.tagmanagement', [
    'restangular',
    'webUi.common.Utils',
    'ui.bootstrap',
    'webUi.service.chart',
    'webUi.service.tagmanagement.websitePath',
    'webUi.service.tagmanagement.websitePathGroup',
    'webUi.service.tree',
    'webUi.service.pubSub',
    'webUi.service.modalManagement',
    'webUi.service.security',
    'webUi.service.toast'
])

    .factory('TagmanagementService', [
        'Restangular',
        'ChartService',
        'WebsitePathGroupService',
        'TreeService',
        'WebsitePathService',
        'SecurityService',
        '$q',
        'Utils',
        '$uibModal',
        'PubSubService',
        'ModalManagementService',
        'ToasterService',
        /**
         *
         * @param Restangular
         * @param ChartService
         * @param WebsitePathGroupService
         * @param TreeService
         * @param WebsitePathService
         * @param SecurityService
         * @param $q
         * @param Utils
         * @param $uibModal
         * @param PubSubService
         * @returns {TagmanagementService}
         */
        function TagmanagementService(Restangular, ChartService, WebsitePathGroupService, TreeService, WebsitePathService, SecurityService, $q, Utils, $uibModal, PubSubService, ModalManagementService, toast) {

            // The base path through which all calls should go
            var BASE_PATH = 'tagmanagement/';

            // The site structure global location
            var GLOBAL_PATH = 'sv_root';
            var GLOBAL_NAME = 'Global';

            var TREE_STATES = [
                'site.tagmanagement.tags.list'
            ];

            var TREE_STATES_ID = [
                'location'
            ];

            var BASESCRIPT = '(function(a,d,e,b,f,c,s){a[b]=a[b]||function(){a[b].q.push(arguments);};\n' +
                'a[b].q=[];c=d.createElement(e);c.async=1;c.src="//tdn.r42tag.com/lib/"+f+".js";\n' +
                's=d.getElementsByTagName(e)[0];s.parentNode.insertBefore(c,s);})\n' +
                '(window,document,"script","_st", "{scriptName}");\n\n' +
                '// Add additional settings here (optional)\n\n' +
                '_st("loadTags");';

            var LOCATIONS = {
                GLOBAL: 'GlobalLocation',
                SINGLEPATH: 'SinglePageLocation',
                PATHHIERARCHY: 'PageHierarchyLocation',
                GROUP: 'WebsitePathGroupLocation'
            };

            var TAG_CONDITION_GROUPING_OPTIONS = {
                CONFIGURATION_GROUP_NAME: 'configurationGroupName',
                TAG_LIST_GROUP_NAME: 'tagListGroupName'
            };

            var enumCache = {};

            var getCachedEnums = function (entityType) {
                var deferred = $q.defer();

                // check cache
                if (!_.isUndefined(enumCache[entityType])) {
                    deferred.resolve(enumCache[entityType]);
                } else {
                    // retrieve data and set cache for next usage
                    Restangular.all(BASE_PATH + entityType).getList().then(function (result) {
                        if (result) {
                            result = Utils.sanitizeRestangularAll(result);
                            enumCache[entityType] = result;
                            deferred.resolve(result);
                        }
                    });
                }
                return deferred.promise;
            };

            /**
             * Callback to add a website path as child to a parent node (with modal)
             * @param parentNode
             * @returns {*|r.promise|promise|d.promise}
             */
            var addWebsitePathNode = function addWebsitePathNode(parentNode) {
                var outerDeferred = $q.defer();

                var dialogsModel = function() {
                    return {
                        getChildPaths: WebsitePathService.getChildPaths,
                        globalPathName: GLOBAL_NAME,
                        currentPath: parentNode,
                        onSave: function(websitePath) {
                            //websitePath represents the entire object
                            var nodeId;
                            if (_.isUndefined(parentNode) || parentNode.id === GLOBAL_PATH) {
                                nodeId = websitePath.name;
                            } else {
                                nodeId = parentNode.id + '|' + websitePath.name;
                            }
                            var innerDeferred = $q.defer();
                            WebsitePathService.addWebsitePath(nodeId).then(function(success) {
                                if(success) {
                                    innerDeferred.resolve({
                                        name: websitePath.name,
                                        id: nodeId,
                                        onRemove: onRemoveTagTreeNode,
                                        sref: TREE_STATES[0] + '({"location":"' + nodeId + '"})',
                                        srefId: {
                                            location: encodeURIComponent(nodeId)
                                        },
                                        allowChildren: true,
                                        children: []
                                    });
                                }
                            });
                            return innerDeferred.promise;
                        }
                    };
                };

                var d = ModalManagementService.openDialog(
                    'TagWebsitePathAddController',
                    'ui/tagmanagement/tags/paths/add/add.tpl.html',
                    dialogsModel,
                    {'css': 'modal-small'}
                );
                d.result.then(function(newNode) {
                    if(newNode) {
                        outerDeferred.resolve(newNode);
                    }
                });
                return outerDeferred.promise;
            };

            var onRemoveTagTreeNode = function onRemoveTagTreeNode(node) {
                var removedNode = $q.defer();
                getTagsListStrict(node.id).then(function (tags) {
                    var d = $uibModal.open({
                        templateUrl: 'ui/tagmanagement/tags/paths/delete/delete.tpl.html',
                        controller: 'TagWebsitePathDeleteController',
                        windowClass: 'modal-small',
                        resolve: {
                            dialogsModel: function () {
                                return {
                                    tags: tags.pageResults,
                                    node: node
                                };
                            }
                        }
                    });

                    d.result.then(function (result) {
                        if (result) {
                            WebsitePathService.removeWebsitePath(node.id).then(function () {
                                // hello
                                toast.success('Path deleted successfully');
                                removedNode.resolve(result);
                                PubSubService.publishTypeDeleted(PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH, {});
                            });
                        }
                    });
                });
                return removedNode.promise;
            };


            var getTagsListStrict = function (locationObj) {
                var deferredList = $q.defer();
                var location = getLocation(locationObj);
                var queryObj = {
                    path: location
                };
                Restangular.one(BASE_PATH + 'tags/websitePathsStrict').get(queryObj).then(function (res) {
                    res = Utils.sanitizeRestangularOne(res);
                    deferredList.resolve(res);
                });
                return deferredList.promise;
            };

            var getLocation = function getLocation(location) {
                if (!_.isObject(location)) {
                    if (_.isUndefined(location)) {
                        return '';
                    }
                    return location.toLowerCase();
                } else {
                    switch (location.clazz) {
                        case(LOCATIONS.GLOBAL):
                            return GLOBAL_PATH;
                        case(LOCATIONS.SINGLEPATH):
                        case(LOCATIONS.PATHHIERARCHY):
                            return location.path.toLowerCase();
                        case(LOCATIONS.GROUP):
                            return '';
                        default:
                            throw new Error('Unsupported location type provided');
                    }
                }
            };

            var getTagsListForLocation = function (locationObj) {
                var deferredList = $q.defer();
                var location = getLocation(locationObj);
                var queryObj = {
                    path: location
                };

                Restangular.one(BASE_PATH + 'tags/websitePaths').get(queryObj).then(function (res) {
                    res = Utils.sanitizeRestangularOne(res);
                    deferredList.resolve(res);
                });
                return deferredList.promise;
            };

            var getTagsListForExport = function getTagsListForExport(tagIds) {
                var def = $q.defer();
                Restangular.all(BASE_PATH + 'tags/export').post(tagIds).then(function (result) {
                    result = Restangular.stripRestangular(result);
                    def.resolve(result);
                });
                return def.promise;
            };

            /**
             * A method for users with only tag_view permissions to query the campaigns list
             * Used in the Content placement tag template
             * @returns {Array} of the campaigns
             * @see https://synovite.atlassian.net/browse/RP-2091
             * @see CampaignService#getCampaignData
             */
            var getCampaigns = function getCampaigns() {
                var campaigns = $q.defer();
                var restangularCall = Restangular.all(BASE_PATH + 'campaigns');
                restangularCall.getList().then(function (result) {
                    campaigns.resolve(Restangular.stripRestangular(result));
                });
                return campaigns.promise;
            };

            /**
             * A method for users with only tag_view permissions to query a campaign
             * Used in the Content placement tag template
             * @param {UUID} campaignId
             * @returns {Campaign}
             * @see https://synovite.atlassian.net/browse/RP-2091
             * @see CampaignService#getCampaign
             */
            var getCampaign = function getCampaign(campaignId) {
                var sortAds = function sortAds(ads) {
                    return _.sortBy(ads, 'ordering');
                };
                var campaign = $q.defer();
                Restangular.one(BASE_PATH + 'campaigns', campaignId).get().then(function (result) {
                    // Sort the campaign ads
                    result.ads = sortAds(result.ads);
                    campaign.resolve(Restangular.stripRestangular(result));
                });
                return campaign.promise;
            };


            /**
             * Gets mobile properties k-v list
             */
            var getMobileProperties = function mobileProperties() {
                var deferred = $q.defer();
                Restangular.all(BASE_PATH + 'mobileProperties').getList().then(function (result) {
                    if (result) {
                        var mobileProperties = _.map(result, function (mobileProperty) {
                            return _.pick(mobileProperty.property, ['name', 'label']);
                        });
                        deferred.resolve(mobileProperties);
                    }
                });
                return deferred.promise;
            };

            var isExcludedPaths = function isExcludedPaths(tag, location) {
                return _.includes(tag.excludedPaths, location);
            };

            var service = {
                /**
                 * the site structure global location
                 */
                GLOBAL_PATH: GLOBAL_PATH,
                GLOBAL_NAME: GLOBAL_NAME,
                BASESCRIPT: BASESCRIPT,
                LOCATIONS: LOCATIONS,
                TAG_CONDITION_GROUPING_OPTIONS: TAG_CONDITION_GROUPING_OPTIONS,

                getBasePath: function () {
                    return BASE_PATH;
                },

                getBasescriptCode: function () {
                    var def = $q.defer();

                    Restangular.one(BASE_PATH + 'basescript').get().then(function (res) {
                        if (_.isUndefined(res)) {
                            def.resolve(undefined);
                        } else {
                            var version = res.version;
                            def.resolve(BASESCRIPT.replace('{scriptName}', version));
                        }
                    });
                    return def.promise;
                },

                /**
                 *
                 * @param {UUID} tagId the tag id
                 * @returns {Object} a promise of the tag
                 */
                getTag: function (tagId, snapshotId) {
                    var tagPromise = $q.defer();
                    Restangular.one(BASE_PATH + 'tags', tagId).get({snapshotId: snapshotId}).then(function (tagViewPojo) {
                        if (tagViewPojo) {
                            tagViewPojo = Utils.sanitizeRestangularOne(tagViewPojo);
                            tagPromise.resolve(tagViewPojo);
                        }
                    });
                    return tagPromise.promise;
                },

                /**
                 *
                 * @param {UUID} id the tag id
                 * @returns {Object} a promise of the tag
                 */
                getTagHistory: function (id) {
                    var tagPromise = $q.defer();
                    Restangular.one(BASE_PATH + 'tags/' + id + '/history').get().then(function (tagHistory) {
                        if (tagHistory) {
                            tagHistory = Utils.sanitizeRestangularAll(tagHistory);
                            tagPromise.resolve(tagHistory);
                        }
                    });
                    return tagPromise.promise;
                },

                /**
                 *
                 * @param {UUID} id the tag id
                 * @returns {Object} a promise of the tag
                 */
                getTagDeployMap: function (id) {
                    var tagPromise = $q.defer();
                    Restangular.one(BASE_PATH + 'tags/' + id + '/deployMap').get().then(function (deployMap) {
                        if (deployMap) {
                            deployMap = Utils.sanitizeRestangularAll(deployMap);
                            tagPromise.resolve(deployMap);
                        }
                    });
                    return tagPromise.promise;
                },

                /**
                 *
                 * @returns {List} a promise of the templates
                 */
                getTemplates: function () {
                    var tagTemplatesPromise = $q.defer();
                    Restangular.all(BASE_PATH + 'tagtemplates/templates').getList().then(
                        function onSuccess(res) {
                            tagTemplatesPromise.resolve(Utils.sanitizeRestangularAll(res));
                        },
                        function onError() { }
                    );
                    return tagTemplatesPromise.promise;
                },

                /**
                 * Saves a Tag
                 *
                 * @param {tagViewPojo} tag the tag to save
                 * @returns A Restangular promise
                 */
                save: function (tag) {
                    return Restangular.all(BASE_PATH + 'tags').post(tag);
                },

                /**
                 * Deletes a tag
                 *
                 * @param {tagId} tagId to delete
                 */
                deleteTag: function (tagId) {
                    return Restangular.one(BASE_PATH + 'tags', tagId).remove();
                },

                /**
                 * Copies the given tag
                 *
                 * @param {tag} tag: The tag to copy
                 * @param {tagLocation} newLocation: The location where we want to copy this tag to.
                 *              By default it's the same location (including type) as the original one, but it doesn't need to be.
                 * @Returns A Restangular promise that will result in a UUID on success
                 */
                copy: function (tag, newTagName, newLocation) {
                    return Restangular.one(BASE_PATH + 'tags').post('copy', {
                        //oldTag
                        oldTagId: tag.tagId,
                        newTagName: newTagName,
                        newTagLocation: newLocation
                    });
                },

                /**
                 * Adds a condition to the tag with the given tagId
                 *
                 * @param tagId
                 * @param condition
                 *
                 * @returns the promise of the post
                 */
                addCondition: function (tagId, rule) {
                    return Restangular.one(BASE_PATH + 'tags').post('condition', {
                        tagId: tagId,
                        condition: rule
                    });
                },

                /**
                 * Deletes a condition from the tag
                 *
                 * @param tagId
                 * @param conditionId
                 *
                 * @returns the promise of the removal
                 */
                deleteCondition: function (tagId, conditionId) {
                    return Restangular.one(BASE_PATH + 'tags', tagId).one('condition', conditionId).remove();
                },
                /**
                 * Adds a path exclusion to a tag
                 * @param {UUID} tagId
                 * @param {String} path
                 * @returns {UUID} the promise of the tag id
                 */
                addPathExclusion: function (tagId, path) {
                    return Restangular.one(BASE_PATH + 'tags').post('exclusion', {
                        tagId: tagId,
                        add: true,
                        path: decodeURIComponent(path)
                    });
                },
                /**
                 *
                 * @param {Tree} tree
                 * @param {String} newPath (e.g) "chocobo|final|fantasy"
                 * @param {boolean} hasTagEdit
                 * @returns {modal}
                 */
                openNewPathModal: function (tree, newPath, hasTagEdit) {
                    return $uibModal.open({
                        templateUrl: 'ui/tagmanagement/tags/list/newPath/newPath.tpl.html',
                        controller: 'NewTagPathController',
                        resolve: {
                            dialogsModel: function () {
                                return {
                                    tree: tree,
                                    newPath: newPath,
                                    hasTagEdit: hasTagEdit
                                };
                            }
                        }
                    });
                },
                /**
                 * Opens a popup explaining why creating a new tag for this path is not possible
                 * @param {String} newPath
                 * @returns {modal}
                 */
                openNewTagWarningModal: function (newPath) {
                    return $uibModal.open({
                        backdrop: 'static',
                        keyboard: true,
                        backdropClick: true,
                        templateUrl: 'ui/tagmanagement/tags/list/newTagWarningPopup/newTagWarningPopup.tpl.html',
                        controller: 'NewTagWarningController',
                        resolve: {
                            dialogsModel: function () {
                                return {
                                    newPath: newPath
                                };
                            }
                        }
                    });
                },
                /**
                 * Remove a path exclusion to a tag
                 * @param {UUID} tagId
                 * @param {String} path
                 * @returns {UUID} the promise of the tag id
                 */
                removePathExclusion: function (tagId, path) {
                    return Restangular.one(BASE_PATH + 'tags').post('exclusion', {
                        tagId: tagId,
                        add: false,
                        path: decodeURIComponent(path)
                    });
                },

                /**
                 * Updates the name of a tag
                 *
                 * @param tagId
                 * @param name
                 */
                updateName: function (tagId, name) {
                    return Restangular.one(BASE_PATH + 'tags').post('name', {
                        tagId: tagId,
                        name: name
                    });
                },

                /**
                 * Updates the priority of the tag, returning a promise of the tagI
                 * @param {UUID} tagId
                 * @param {int} priority
                 * @returns {UUID} a promise of the uuid of the tag
                 */
                updatePriority: function (tagId, priority) {
                    return Restangular.one(BASE_PATH + 'tags').post('priority', {
                        tagId: tagId,
                        priority: priority
                    });
                },

                /**
                 * Updates the description of a tag
                 *
                 * @param tagId
                 * @param description
                 */
                updateDescription: function (tagId, description) {
                    return Restangular.one(BASE_PATH + 'tags').post('description', {
                        tagId: tagId,
                        description: description
                    });
                },

                /**
                 * Updates the code of a tag
                 * @param tagId
                 * @param code
                 */
                updateCode: function (tagId, code, variantId, sequenceNr) {
                    return Restangular.one(BASE_PATH + 'tags').post('code', {
                        tagId: tagId,
                        content: {
                            clazz: 'TextTagContent',
                            code: code,
                            variantId: variantId,
                            sequenceNr: sequenceNr
                        }
                    });
                },

                /**
                 * Updates the content of a tag
                 * @param tagId
                 * @param content
                 */
                updateContent: function (tagId, content) {
                    return Restangular.one(BASE_PATH + 'tags').post('content', {
                        tagId: tagId,
                        content: content
                    });
                },

                /**
                 * Updates the compression property of a tag
                 * @param {UUID} tagId
                 * @param {boolean} compression
                 * @returns {UUID} a promise of the UUID of the tag that was edited
                 */
                updateCompression: function (tagId, compression) {
                    return Restangular.one(BASE_PATH + 'tags').post('compression', {
                        tagId: tagId,
                        compression: compression
                    });
                },

                /**
                 * Updates the execution type of a tag
                 * @param {UUID} tagId
                 * @param {Object} tagExecutionType
                 * @returns {UUID} a promise of the UUID of the tag that was edited
                 */
                updateTagExecutionType: function (tagId, tagExecutionType) {
                    return Restangular.one(BASE_PATH + 'tags').post('tagExecutionType', {
                        tagId: tagId,
                        tagExecutionType: tagExecutionType
                    });
                },

                /**
                 * Updates the enabled property of a tag
                 * @param {UUID} tagId
                 * @param {boolean} enabled
                 * @returns {UUID} a promise of the UUID of the tag that was edited
                 */
                updateEnabled: function (tagId, enabled) {
                    return Restangular.one(BASE_PATH + 'tags').post('enabled', {
                        tagId: tagId,
                        enabled: enabled
                    });
                },

                /**
                 * Updates the location of the tag
                 *
                 * @param tagId
                 * @param location
                 */
                updateLocation: function (tagId, location) {
                    return Restangular.one(BASE_PATH + 'tags').post('location', {
                        tagId: tagId,
                        location: location
                    });
                },

                /**
                 *
                 * @param {type} tagId
                 * @param {type} cookiesEnabled
                 * @returns {UUID} a promise of the tag uuid
                 */
                updateCookiesEnabled: function (tagId, cookiesEnabled) {
                    return Restangular.one(BASE_PATH + 'tags').post('cookiesEnabled', {
                        tagId: tagId,
                        cookiesEnabled: cookiesEnabled
                    });
                },
                /**
                 * Updates the userAgentFilter property of a tagContent that is CookieSetting
                 * @param {type} tagId
                 * @param {type} userAgentFilter
                 * @returns {UUID} a promise of the tag uuid
                 */
                updateUserAgentFilter: function (tagId, userAgentFilter) {
                    return Restangular.one(BASE_PATH + 'tags').post('userAgentFilter', {
                        tagId: tagId,
                        userAgentFilter: userAgentFilter
                    });
                },

                /**
                 * Retrieves a list of tags at the specified location
                 * @param {String|Object} locationObj (url encoded) or the object containing the clazz prop itself. If it is null or undefined, will give you all tags.
                 * @returns {Array} a promise of the list of tags at the specified location
                 */
                getTagsListForLocation: getTagsListForLocation,

                getTagsListForExport: getTagsListForExport,

                /**
                 * Returns a location object including a readable location and the "key" from a locationType object
                 */
                getReadableLocation: function (tagLocationType) {

                    var deferredLocation = $q.defer();
                    if (tagLocationType.clazz === LOCATIONS.GLOBAL) {
                        deferredLocation.resolve({
                            name: GLOBAL_NAME,
                            key: GLOBAL_PATH
                        });
                    } else if (tagLocationType.clazz === LOCATIONS.SINGLEPATH) {
                        deferredLocation.resolve({
                            name: 'Single Path: ' + tagLocationType.path.replace(/\|/g, ' > '),
                            key: tagLocationType.path
                        });

                    } else if (tagLocationType.clazz === LOCATIONS.PATHHIERARCHY) {

                        deferredLocation.resolve({
                            name: 'Path Hierarchy: ' + tagLocationType.path.replace(/\|/g, ' > '),
                            key: tagLocationType.path
                        });

                    } else if (tagLocationType.clazz === LOCATIONS.GROUP) {
                        var group = WebsitePathGroupService.getWebsitePathGroup(tagLocationType.groupId);

                        group.then(function (group) {

                            deferredLocation.resolve({
                                name: 'Group: ' + group.name,
                                key: group.websitePathGroupId
                            });
                        });
                    } else {
                        deferredLocation.resolve({
                            name: 'Unknown location',
                            key: null
                        });
                    }

                    return deferredLocation.promise;
                },

                /**
                 * Returns a list of all locations
                 * @param {type} location
                 * @returns {@exp;@call;decodeURIComponent@call;split}
                 */
                getLocationBreadcrumbs: function (location) {
                    var crumbs = [];

                    if (_.isEmpty(location)) {
                        return crumbs;
                    }

                    if (location === GLOBAL_PATH) {
                        return [{
                            fullPath: GLOBAL_PATH,
                            fullPathURIEncoded: encodeURIComponent(GLOBAL_PATH),
                            name: GLOBAL_NAME
                        }];
                    }

                    var subPaths = decodeURIComponent(location).split('|');
                    var fullPath;

                    for (var i = 0; i < subPaths.length; i++) {

                        if (_.isUndefined(fullPath)) {
                            fullPath = subPaths[i];
                        } else {
                            fullPath += '|' + subPaths[i];
                        }

                        var crumb = {
                            fullPath: fullPath,
                            fullPathURIEncoded: encodeURIComponent(fullPath),
                            name: subPaths[i]
                        };

                        crumbs.push(crumb);
                    }
                    return crumbs;
                },

                /**
                 * Makes a Restangular call to the server and retrieves the tagTree and groups, then parses it to make it match the expected dynatree format
                 *
                 * @returns structureMap with paths in the tagTreeData format, which is usable by dynatree and a list of groups
                 */
                getTagStructure: function (skipSrefs) {
                    var onInitTagTreeNode = function(node, obj, level) {
                        if(level === 0 && node.id === GLOBAL_PATH) {
                            node.addClass = 'node-global';
                            node.closeIcon = 'globe';
                            node.canDelete = false;
                        }
                    };
                    var structureDeferred = $q.defer();
                    Restangular.one(BASE_PATH + 'structure').get().then(function (result) {
                        if (result) {
                            result.paths[GLOBAL_PATH] = {};
                            result = Utils.sanitizeRestangularOne(result);
                            if(skipSrefs) {
                                structureDeferred.resolve({
                                    groups: result.groups,
                                    paths: Utils.parseNodes(result.paths, null, null, onInitTagTreeNode, onRemoveTagTreeNode)
                                });
                            } else {
                                structureDeferred.resolve({
                                    groups: result.groups,
                                    paths: Utils.parseNodes(result.paths, TREE_STATES, TREE_STATES_ID, onInitTagTreeNode, onRemoveTagTreeNode)
                                });
                            }
                            /*var structure = {
                                groups: result.groups,
                                paths: Utils.parseNodes(result.paths, TREE_STATES, TREE_STATES_ID, onInitTagTreeNode, onRemoveTagTreeNode)
                            };*/
                            // structureDeferred.resolve(structure);
                        }
                    });
                    return structureDeferred.promise;
                },

                /**
                 * Activates the node at the specified location
                 * @param {Object} treeData (the unproxied tree data)
                 * @param {string} location @see $stateParams.location (e.g. 'some|nested|location')
                 * @param {boolean} ignorecase (default true)
                 * @returns {Void}
                 */
                activateTagTreeNode: function (location, treeData) {
                    if (_.isEmpty(location)) {
                        location = GLOBAL_NAME;
                    }

                    var node = TreeService.findNodeByNameExact(treeData, location);
                    if (!_.isUndefined(node)) {
                        TreeService.activateNode(node, treeData);
                    }
                },

                /**
                 * Returns a promise of a tag location dialog edit
                 * @param {type} parentObject the parent that contains the location
                 * @returns a promise of a tag location dialog edit
                 */
                createTagModal: function (tagLocation) {
                    var location;

                    if (!_.isUndefined(tagLocation)) {
                        location = _.cloneDeep(tagLocation);
                    } else {
                        location = {
                            clazz: LOCATIONS.GLOBAL
                        };
                    }

                    var dialog = $uibModal.open({
                        windowClass: 'modal-huge',
                        backdrop: 'static',
                        keyboard: true,
                        templateUrl: 'ui/tagmanagement/tags/form/form.tpl.html',
                        controller: 'TagmanagementTagFormController',
                        resolve: {
                            dialogsModel: function () {
                                return {
                                    tagLocation: location
                                };
                            },
                            securityContext: function () {
                                return SecurityService.getSecurityContext();
                            }
                        }
                    });
                    return Utils.modalPromise(dialog);
                },

                /**
                 * Retrieves an array of the possible tag execution types
                 * @returns {Array} of the possible tag execution types
                 */
                getTagExecutionTypes: function () {
                    var deferred = $q.defer();

                    // check cache
                    if (!_.isUndefined(enumCache['tagExecutionTypes'])) {
                        deferred.resolve(enumCache['tagExecutionTypes']);
                    } else {
                        // retrieve data and set cache for next usage
                        Restangular.one(BASE_PATH + 'tagExecutionTypes').get().then(function (result) {
                            if (result) {
                                result = Utils.sanitizeRestangularOne(result);
                                enumCache['tagExecutionTypes'] = result;
                                deferred.resolve(result);
                            }
                        });
                    }
                    return deferred.promise;
                },

                /**
                 * Get the list of enums which represent the TagConditionType
                 */
                getTagConditionTypes: function () {
                    return getCachedEnums('tagConditionTypes');
                },

                /**
                 * @returns {Array} or the possible tag conditions based on the platform type
                 */
                getAvailableTagConditions: function () {
                    var def = $q.defer();
                    Restangular.all(BASE_PATH + 'tags/availableConditions').getList().then(function (result) {
                        if (result) {
                            result = Restangular.stripRestangular(result);
                            def.resolve(result);
                        }
                    });
                    return def.promise;
                },

                /**
                 * Gets the available tag conditions and groups them by the selected property. In case no "groupBy" argument is provided, the tag will be grouped by the "configurationGroupName"
                 * which is the grouping used the tag configuration popup (so "Most popular", "Visitor based", "Page based", "Other"
                 * @param {String} groupBy the property on which to group the tag conditions
                 * @returns {Array} the available tag conditions for this platform typed, grouped by the selected property
                 */
                getTagConditionsGroupedBy: function (groupBy) {

                    var hasLandingConditions = function hasLandingConditions(availableTagConditions) {
                        if (!availableTagConditions) {
                            return false;
                        }
                        return _.where(availableTagConditions, {condition: {name: 'LANDING_PARAM'}}).length > 0
                            || _.where(availableTagConditions, {condition: {name: 'LANDING_PATTERN'}}).length > 0;
                    };

                    var hasReferralConditions = function hasReferralConditions(availableTagConditions) {
                        if (!availableTagConditions) {
                            return false;
                        }
                        return _.where(availableTagConditions, {condition: {name: 'REFERRAL_PARAM'}}).length > 0
                            || _.where(availableTagConditions, {condition: {name: 'REFERRAL_PATTERN'}}).length > 0;

                    };

                    /**
                     * Creates a "Landing Condition" with the correct template for the "LANDING_PARAM" and "LANDING_PATTERN" conditions out of the available tag conditions.
                     * @param {*} availableTagConditions
                     * @returns {*}
                     */
                    var createLandingCondition = function (availableTagConditions) {

                        var landingParam = _.find(availableTagConditions, {condition: {name: 'LANDING_PARAM'}});
                        var landingPattern = _.find(availableTagConditions, {condition: {name: 'LANDING_PATTERN'}});

                        var landingConditionClasses = [];
                        if (landingParam) {
                            landingConditionClasses.push(landingParam);
                        }
                        if (landingPattern) {
                            landingConditionClasses.push(landingPattern);
                        }
                        return {
                            clazz: landingConditionClasses,
                            template: 'landingCondition',
                            condition: {
                                name: 'LANDING_CONDITION',
                                label: 'Page URL'
                            },
                            configurationGroupName: landingParam.configurationGroupName,
                            tagListGroupName: landingParam.tagListGroupName
                        };
                    };

                    /**
                     * Creates a "Referral Condition" with the correct (single) template for the "REFERRAL_PARAM" and "REFERRAL_PATTERN" conditions out of the available tag conditions.
                     * @param {type} availableTagConditions
                     * @returns {TagmanagementService.service.getTagConditionsGroupedBy.createReferralCondition.TagmanagementServiceAnonym$41}
                     */
                    var createReferralCondition = function (availableTagConditions) {

                        var referralParam = _.find(availableTagConditions, {condition: {name: 'REFERRAL_PARAM'}});
                        var referralPattern = _.find(availableTagConditions, {condition: {name: 'REFERRAL_PATTERN'}});

                        var referralConditionClasses = [];
                        if (referralParam) {
                            referralConditionClasses.push(referralParam);
                        }
                        if (referralPattern) {
                            referralConditionClasses.push(referralPattern);
                        }

                        return {
                            clazz: referralConditionClasses,
                            template: 'referralCondition',
                            condition: {
                                name: 'REFERRAL_CONDITION',
                                label: 'Referral URL'
                            },
                            configurationGroupName: referralParam.configurationGroupName,
                            tagListGroupName: referralParam.tagListGroupName
                        };
                    };


                    var def = $q.defer();

                    if (!groupBy) {
                        groupBy = TAG_CONDITION_GROUPING_OPTIONS.CONFIGURATION_GROUP_NAME;
                    }

                    this.getAvailableTagConditions().then(function (availableTagConditions) {

                        if (!availableTagConditions) {
                            return [];
                        }

                        // Group the tag conditions
                        var grouping = _.indexBy(_.pluck(availableTagConditions, groupBy), function (res) {
                            return res.name;
                        });

                        _.forEach(availableTagConditions, function (item) {

                            // Skip LandingPattern/LandingParam condition as they are grouped into one template, and will be added later "manually"
                            // Skip ReferralPattern/ReferralParam conditions as they are grouped into one template and will be added later "manually"
                            if (item.condition.name === 'LANDING_PARAM' || item.condition.name === 'LANDING_PATTERN' || item.condition.name === 'REFERRAL_PARAM' || item.condition.name === 'REFERRAL_PATTERN') {
                                return;
                            }

                            // Add "template" property to each of the tag conditions
                            item.template = item.clazz.charAt(0).toLowerCase() + item.clazz.slice(1);

                            if (!grouping[item.configurationGroupName.name].conditions) {
                                grouping[item.configurationGroupName.name].conditions = [];
                            }
                            grouping[item.configurationGroupName.name].conditions.push(item);
                        });

                        if (hasLandingConditions(availableTagConditions)) {
                            var landingCondition = createLandingCondition(availableTagConditions);
                            if (landingCondition) {
                                grouping[landingCondition.configurationGroupName.name].conditions.push(landingCondition);
                            }
                        }
                        if (hasReferralConditions(availableTagConditions)) {
                            var referralCondition = createReferralCondition(availableTagConditions);
                            if (referralCondition) {
                                grouping[referralCondition.configurationGroupName.name].conditions.push(referralCondition);
                            }
                        }

                        // TODO: FIXME: the sorting of the groups should be probably done elsewhere and the names of the groups shouldn't be
                        // hardcoded here as mobile tags might not have the same groups
                        // Sort the tag groups; Order should be: "MOST_POPULAR", "VISITOR_BASED", "PAGE_BASED", "OTHER"
                        var sortedAndGroupedTagConditions = [];

                        sortedAndGroupedTagConditions.push(grouping['MOST_POPULAR']);
                        sortedAndGroupedTagConditions.push(grouping['VISITORBASED']);
                        sortedAndGroupedTagConditions.push(grouping['PAGEBASED']);
                        sortedAndGroupedTagConditions.push(grouping['OTHER']);

                        // Remove undefined elements if any
                        sortedAndGroupedTagConditions = _.compact(sortedAndGroupedTagConditions);
                        def.resolve(sortedAndGroupedTagConditions);
                    });
                    return def.promise;
                },

                /**
                 * Retrieves an array of the possible browser types
                 * @returns {Array} of the possible browser types
                 */
                getBrowserTypes: function () {
                    return getCachedEnums('browserTypes');
                },

                /**
                 * Retrieves an array of the possible device types
                 * @returns {Array} of the possible device types
                 */
                getDeviceTypes: function () {
                    return getCachedEnums('deviceTypes');
                },

                /**
                 * Retrieves an array of the possible page property equalizers
                 * @returns {Array} of the possible page property equalizers
                 */
                getMatchTypes: function () {
                    var promiseGetMatchTypes = $q.defer();
                    Restangular.all(BASE_PATH + 'matchTypes').getList().then(function(matchTypes){
                        promiseGetMatchTypes.resolve(Restangular.stripRestangular(matchTypes));
                    });
                    return promiseGetMatchTypes.promise;
                },

                getTimezones: function () {
                    return getCachedEnums('timezones');
                },

                //TODO MIHAI cached
                getHttpMethods: function () {
                    var promiseGetHttpMethods = $q.defer();
                    Restangular.all(BASE_PATH + 'httpMethods').getList().then(function(httpMethods){
                        httpMethods = Restangular.stripRestangular(httpMethods);
                        promiseGetHttpMethods.resolve(httpMethods);
                    });
                    return promiseGetHttpMethods.promise;
                },

                /**
                 * Retrieves a list of the available tag placeholder types
                 * @param {type} scriptVersion
                 * @returns {Array}
                 */
                getAvailablePlaceholderTypes: function () {
                    return [{
                        name: 'Property',
                        clazz: 'property',
                        code: 'p',
                        template: 'Retrieves a property in your javascript code'
                    }, {
                        name: 'Extracted',
                        clazz: 'extracted',
                        code: 'x',
                        template: 'The value given will search for an element in the HTML page depending on what you fill in. If you provide a # symbol in the beginning, it will search for an element by id. Otherwise it searches for element by tagName or name and returns the first one.'
                    }];
                },
                /**
                 * @param {String} location the location of the tag
                 * @returns {Array} a promise an array of the tag property names defined for the specified tag
                 */
                getTagProperties: function (location) {
                    if (_.isUndefined(location) || _.isNull(location)) {
                        location = '';
                    }
                    var loc = getLocation(location);
                    return Restangular.all(BASE_PATH + 'tags/tagProperties?location=' + encodeURIComponent(loc)).getList();
                },
                /**
                 * Retrieves a list of the recent tag errors
                 * @param {UUID} tagId
                 * @returns {Array} a promise of the recent tag errors
                 */
                getRecentTagErrors: function (tagId) {
                    return Restangular.all(BASE_PATH + 'tags/' + tagId + '/errors/recentErrors').getList();
                },
                /**
                 * Retrieves a list of the normalized tag errors to be used in a highcharts display model
                 * @param {UUID} tagId
                 * @param {int} days number of days to query
                 * @returns {Array} a promise of thelist of the normalized tag error stats (errors/total) to be used in a highcharts display model
                */
                getErrorStats: function (tagId, days) {
                    /**
                     * @param tagErrorStats
                     * @returns {Array}
                     */
                    function parse(tagErrorStats) {

                        function parseRatio(ratioObj) {
                            if (ratioObj.totalTimesLoaded === 0) {
                                return {
                                    y: 0,
                                    info: 'Insufficient info to display stat'
                                };
                            } else {
                                var y = parseFloat((ratioObj.errorsCount * 100 / ratioObj.totalTimesLoaded));
                                return {
                                    y: ( y > 100) ? 100 : y,
                                    err: ratioObj.errorsCount,
                                    total: ratioObj.totalTimesLoaded
                                };
                            }
                        }

                        var data = [];
                        for (var i = 0; i < tagErrorStats.length; i++) {
                            var ratioObj = tagErrorStats[i];
                            data.push(parseRatio(ratioObj));
                        }
                        return data;
                    }

                    var deferredSeries = $q.defer();

                    days = days || 90;
                    Restangular.all(BASE_PATH + 'tags/' + tagId + '/errors/errorStats').getList({days: days}).then(function (tagErrorStats) {
                        tagErrorStats = Utils.sanitizeRestangularAll(tagErrorStats);
                        var parsedSeries = [{
                            name: 'Error Ratio',
                            data: parse(tagErrorStats),
                            color: ChartService.colors.BLUE,
                            max: 100,
                            min: 0,
                            pointInterval: 24 * 36e5,
                            pointStart: tagErrorStats.length === 0 ? 0 : tagErrorStats[0].timestamp
                        }];

                        deferredSeries.resolve(parsedSeries);
                    });

                    return deferredSeries.promise;
                },

                /**
                 * Retrieves a list of the chart series for the browser, then modifies them to fit highcharts' display model and returns that promise
                 * @param {UUID} tagId
                 * @returns {Array} a list of the chart series for the browser, then modifies them to fit highcharts' display model and returns that promise
                 */
                getErrorsPerBrowser: function (tagId) {
                    function parse(seriesItem, itemIdx) {

                        function parseData(seriesItemData) {
                            var data = [];
                            for (var i = 0; i < seriesItemData.length; i++) {
                                data.push(
                                    seriesItemData[i].errorsCount
                                );
                            }
                            return data;
                        }

                        var seriesDef = {
                            name: seriesItem.browserName,
                            data: parseData(seriesItem.stats),
                            pointInterval: 24 * 36e5,
                            color: ChartService.getColor(itemIdx),
                            pointStart: seriesItem.stats.length === 0 ? 0 : seriesItem.stats[0].timestamp
                        };
                        return seriesDef;
                    }

                    var deferredSeries = $q.defer();
                    Restangular.all(BASE_PATH + 'tags/' + tagId + '/errors/errorsPerBrowser').getList().then(function (series) {
                        var parsedSeries = [];

                        for (var i = 0; i < series.length; i++) {
                            parsedSeries[i] = parse(series[i], i + 1); // i + 1 for ignoring white color , white color hidden the chart
                        }
                        deferredSeries.resolve(parsedSeries);
                    });
                    return deferredSeries.promise;
                },

                /**
                 * @param {UUID} tagId
                 * @returns {int} total error count over the last (X:5) days
                 */
                getErrorCount: function (tagId) {
                    return Restangular.one(BASE_PATH + 'tags/' + tagId + '/errors/errorCount').get({
                        tagId: tagId
                    });
                },

                /**
                 * Marks the tag as fixed. This would reset the error count for this tag
                 * @param {UUID} tagId
                 */
                markFixed: function (tagId) {
                    return Restangular.one(BASE_PATH + 'tags/markFixed').post('', {
                        'tagId': tagId
                    });
                },

                /**
                 * For a tag that has content of 'TagTemplateContent' type, would run through freemarker, replace all vars with their equivalent, and will return
                 * the generated code.
                 * @param {UUID} tagId
                 * @param {long} sequenceNr
                 * @returns {String} a promise of the code
                 */
                getTagTemplateCode: function (tagId, sequenceNr) {
                    return Restangular.one(BASE_PATH + 'tagtemplates/templates/templateCode').get({
                        tagId: tagId,
                        sequenceNr: sequenceNr
                    });
                },
                /**
                 * Checks a custom tag content is correct size and able to be saved (due to compression done back end
                 * @param {String} code
                 * @param {Boolean} compressed
                 * @returns {Boolean} the result
                 */
                checkTagContentSize: function (code, compressed) {
                    var deferred = $q.defer();
                    Restangular.one(BASE_PATH + 'tags/checkTagSize').post('', {
                        code: code,
                        compressed: compressed
                    }).then(function (result) {
                        if (result) {
                            deferred.resolve(result);
                        }

                    });
                    return deferred.promise;
                },


                /**
                 * Converts a tag that has content of 'TagTemplateContent' type into a tag that has 'TextTagContent' (basically custom tag) with the specified code
                 * @param {UUID} tagId
                 * @param {String} code
                 * @returns {UUID} a promise of the tagId of the modified tag (same as the tagId param)
                 */
                convertTagToCustom: function (tagId, code) {
                    return Restangular.one(BASE_PATH + 'tags/convertToCustom').post('', {
                        tagId: tagId,
                        code: code
                    });
                },

                /**
                 * Given a destination where to copy a tag, return the tagLocation object that can be global, path hierarchy or websitePathGroup
                 * @param destination the given destination which can be a path (including Global) or group
                 */
                getNewTagLocationForDestination: function (destination, destinationType) {
                    if (destinationType === 'paths' && destination === GLOBAL_PATH) {
                        return {
                            clazz: LOCATIONS.GLOBAL,
                            path: GLOBAL_PATH
                        };
                    } else if (destinationType === 'paths' && !_.isEmpty(destination)) {
                        return {
                            clazz: LOCATIONS.PATHHIERARCHY,
                            path: destination
                        };
                    } else if (destinationType === 'groups') {
                        return {
                            clazz: LOCATIONS.GROUP,
                            groupId: destination
                        };
                    } else {
                        throw new Error('Invalid destination and/or destinationType for copying a tag');
                    }
                },

                addWebsitePathNode: addWebsitePathNode,

                /**
                 * Retrieves a copy name for the tag about to be copied.
                 * @param tagName - original tag to copy
                 * @param existingTags - the tags that already exist at that location
                 */
                getCopyName: function (tagName, existingTags) {

                    //assume we attach this suffix to all copies, as in "TagName - copy" or "TagName - copy(2)" etc
                    var COPY_LITERAL = ' - copy';
                    var tagNameCopy;

                    var copyStrIdx = tagName.lastIndexOf(COPY_LITERAL);
                    if (copyStrIdx === -1) { //if it's the original name, there's no copy suffix, then return the name with the copy suffix
                        tagNameCopy = tagName + COPY_LITERAL;
                    } else {
                        //original tag name
                        var originalTagName = tagName.substring(0, copyStrIdx);
                        //suffix after original tag name, can be " - copy" or " - copy(3)" etc
                        var copyStr = tagName.substring(copyStrIdx, tagName.length);
                        if (copyStr.length === COPY_LITERAL.length) { //if it's just a first copy, return " - copy(2)" attached to the original name
                            tagNameCopy = originalTagName + COPY_LITERAL + '(2)';
                        } else {
                            //parse the iteration of the copy and attach the next iteration
                            var copyIteration = copyStr.substring(copyStr.indexOf('(') + 1, copyStr.indexOf(')'));
                            var nextIteration = parseInt(copyIteration, 10) + 1;
                            tagNameCopy = originalTagName + COPY_LITERAL + '(' + nextIteration + ')';
                        }
                    }
                    //check if name exists in location
                    if (_.find(existingTags, function (existing) {
                        return existing.name === tagNameCopy;
                    })) {
                        //call the getCopyName recursively
                        return this.getCopyName(tagNameCopy, existingTags);
                    } else {
                        return tagNameCopy;
                    }
                },
                /**
                 * Checks whether new tags can be added to the site
                 * @returns {Boolean} whether more tags can be added to the site
                 */
                canAddNewTags: function () {
                    return Restangular.one(BASE_PATH + 'canAddNewTags').get();
                },

                getSegments: function () {
                    var promiseGetSegments = $q.defer();
                    Restangular.one(BASE_PATH + 'segments').get().then(function(segments) {
                        promiseGetSegments.resolve(Restangular.stripRestangular(segments));
                    });
                    return promiseGetSegments.promise;
                },

                getCampaigns: getCampaigns,
                getCampaign: getCampaign,
                getMobileProperties: getMobileProperties,
                isExcludedPaths: isExcludedPaths
            };

            Utils.wrapModifierFunctions(service, function () {
                PubSubService.publishEnvironmentChange();
            });

            return service;
        }]);
