import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Variants (Cookie Permission Templates) Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.SAVE_SUCCESS:
    case types.COOKIE_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.cookiePermissionVariantId]: action.payload,
      };
    case types.COOKIES_FETCH_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(variant => {
        nextState[variant.cookiePermissionVariantId] = variant;
      });
      return nextState;
    default:
      return state;
  }
};

/**
 * Variants (Cookie Permission Templates) Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.SAVE_SUCCESS:
    case types.COOKIE_FETCH_SUCCESS:
      if (state.indexOf(action.payload.cookiePermissionVariantId) !== -1) {
        return state;
      }
      return [...state, action.payload.cookiePermissionVariantId];
    case types.COOKIES_FETCH_SUCCESS:
      nextState = [...state];
      action.payload.forEach(variant => {
        if (state.indexOf(variant.cookiePermissionVariantId) === -1) {
          nextState.push(variant.cookiePermissionVariantId);
        }
      });
      return nextState;
    default:
      return state;
  }
};

/**
 * Clients for a variant (cookie permission template) by clientId
 * @param state
 * @param action
 * @returns {{}}
 */
const clientsByVariant = (state = {}, action) => {
  let clientsById;
  let clientLink;
  let clients;
  let oldClients;
  let oldClient;
  switch (action.type) {
    case types.COOKIE_FETCH_SUCCESS:
      clientsById = {};
      action.payload.clientLinks.forEach(item => {
        clientsById[item.clientId] = {
          ...item,
          markedToRestrict: false,
        };
      });
      return {
        ...state,
        [action.payload.cookiePermissionVariantId]: clientsById,
      };
    case types.CLIENT_TOGGLE_RESTRICTION:
      clientLink = state[action.payload.variantId][action.payload.clientId];
      return {
        ...state,
        [action.payload.variantId]: {
          ...state[action.payload.variantId],
          [action.payload.clientId]: {
            ...clientLink,
            markedToRestrict: !clientLink.markedToRestrict,
          },
        },
      };
    case types.CLIENTS_RESTRICT_SUCCESS:
      clients = state[action.payload.variantId];
      Object.keys(clients).forEach(key => {
        const item = clients[key];
        clients[key] = {
          ...item,
          restricted: item.restricted || item.markedToRestrict,
          markedToRestrict: false,
        };
      });

      return {
        ...state,
        [action.payload.variantId]: clients,
      };

    case types.CLIENT_UNRESTRICT_SUCCESS:
      oldClients = state[action.payload.variantId];
      oldClient = oldClients[action.payload.clientId];
      return {
        ...state,
        [action.payload.variantId]: {
          ...oldClients,
          [action.payload.clientId]: {
            ...oldClient,
            restricted: false,
          },
        },
      };
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetchingAll: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetchingAll: false,
  shouldFetchAll: true,
  error: null,
};
const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.COOKIES_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.COOKIES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  clientsByVariant,
  ui,
});
