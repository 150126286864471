import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { SmallWrapper, Panel } from '~/components/src/Containers';
import { saveEnvironment, fetchNonDeletableCodesIfNeeded, fetchEnvironments } from '../environments/actions';
import EnvironmentForm from '../components/EnvironmentForm';
import { getEnvironments, getNonDeletableCodes } from '../environments/selectors';

const initialDefaultValues = {
  environmentOrder: 20,
  sampleRate: 5,
};

export class NewEnvironmentsPage extends Component {
  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchNonDeletableCodesIfNeeded();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('form.new')} />
          <Panel>
            <EnvironmentForm backHref={buildUrl('admin/environments')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      initialValues: { ...initialDefaultValues },
      environments: getEnvironments(state),
      nonDeletableCodes: getNonDeletableCodes(state),
    }),
    dispatch => ({
      onSubmit: environment => dispatch(saveEnvironment(environment)),
      fetchNonDeletableCodesIfNeeded: () => dispatch(fetchNonDeletableCodesIfNeeded()),
      fetchEnvironments: () => dispatch(fetchEnvironments()),
    }),
  ),
  reduxForm({
    form: 'environmentNew',
  }),
  translate(['environments']),
)(NewEnvironmentsPage);
