import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Field, FieldArray } from 'redux-form';
import cx from 'classnames';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, isValueUnique } from '~/common';
import Form from '~/components/src/Form/Form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import BtnIcon from '~/components/src/BtnIcon';
import ListButtonItem from '~/components/src/Table/components/ListButtonItem/presenter';
import i18n from '../../i18n';

class RenderValueSets extends Component {
  constructor(props) {
    super(props);
    this.state = { editIndex: 0, editedRow: null };
  }
  componentDidMount() {
    if (this.props.editForm) {
      this.stopEditing();
    }
  }
  startEditing(index) {
    this.setState({ editIndex: index });
  }
  stopEditing() {
    this.setState({ editIndex: -1 });
  }
  showValidationMessage(index, fieldName) {
    if (!get(this.props, `meta.error[${index}]`)) return undefined;
    const currentSetErrors = this.props.meta.error[index];
    return currentSetErrors[fieldName];
  }
  handleAddBlank(e) {
    e.preventDefault();
    if (this.areValueSetsValid()) {
      this.props.handleSetsValidationHide();
      this.props.fields.push({});
      this.startEditing(this.props.fields.length);
    } else {
      this.props.handleSetsValidationShow();
    }
  }
  areValueSetsValid() {
    if (this.props.meta.error === undefined) {
      return true;
    }
    return false;
  }
  getErrorMessage(index, fieldName) {
    const isFieldTouched = get(this.props, `fieldsMeta[${index}].label.touched`);
    return (isFieldTouched || this.props.validateSets) && this.showValidationMessage(index, fieldName);
  }
  confirmInput() {
    if (this.areValueSetsValid()) {
      this.stopEditing();
    } else {
      this.props.handleSetsValidationShow();
    }
  }
  editInput(index, fields) {
    if (this.areValueSetsValid()) {
      // store in state current values before edit
      this.setState(
        {
          editedRow: {
            editedIndex: index,
            label: fields.get(index).label,
            value: fields.get(index).value,
          },
        },
        this.startEditing(index),
      );
    } else {
      this.props.handleSetsValidationShow();
    }
  }
  removeRow(index, editIndex) {
    this.props.fields.remove(index);
    if (index === editIndex) {
      this.stopEditing();
    } else {
      this.startEditing(editIndex - 1);
    }
  }
  cancelEdit(index, editIndex, fields) {
    // if already had some data
    if (this.state.editedRow && this.state.editedRow.editedIndex === index) {
      fields.remove(index);
      fields.insert(index, { label: this.state.editedRow.label, value: this.state.editedRow.value });
      this.stopEditing();
    } else {
      fields.remove(index);
    }
  }
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.confirmInput();
    }
  }
  render() {
    const { editIndex } = this.state;
    return (
      <section className="EditableList mb-6">
        <div className="EditableList-row EditableList-headerRow u-paddingLeftXl">
          <div className="EditableList-headerItem u-marginRightM">
            {i18n.t('urlBuilder:valueSets.valueSetForm.value.label')}
          </div>
          <div className="EditableList-headerItem">{i18n.t('urlBuilder:valueSets.valueSetForm.label.label')}</div>
        </div>
        {this.props.fields.map((valuesSets, index, fields) => (
          <div className={cx('EditableList-row', { 'EditableList-row--beingEdited': index === editIndex })} key={index}>
            <div className="EditableList-field u-marginRightM u-size4of12">
              {index === editIndex ? (
                <>
                  <Field
                    name={`${valuesSets}.label`}
                    component={ReduxFormInputField}
                    placeholder={this.props.t('urlBuilder:valueSets.valueSetForm.value.placeholder')}
                    onKeyPress={this.handleKeyPress.bind(this)}
                  />
                  <span className="EditableList-error Element-error">{this.getErrorMessage(index, 'label')}</span>
                </>
              ) : (
                fields.get(index).label
              )}
            </div>
            <div className="EditableList-field u-marginRightM u-size4of12">
              {index === editIndex ? (
                <>
                  <Field
                    name={`${valuesSets}.value`}
                    component={ReduxFormInputField}
                    placeholder={this.props.t('urlBuilder:valueSets.valueSetForm.label.placeholder')}
                    onKeyPress={this.handleKeyPress.bind(this)}
                  />
                  <span className="EditableList-error Element-error">{this.getErrorMessage(index, 'value')}</span>
                </>
              ) : (
                fields.get(index).value
              )}
            </div>
            <div className="flex gap-2">
              {index === editIndex ? (
                <>
                  <BtnIcon icon="done" onClick={() => this.confirmInput()} />
                  <BtnIcon icon="close" onClick={() => this.cancelEdit(index, editIndex, fields)} />
                </>
              ) : (
                <>
                  <BtnIcon icon="edit" onClick={() => this.editInput(index, fields)} />
                  <BtnIcon icon="delete" onClick={() => this.removeRow(index, editIndex)} />
                </>
              )}
            </div>
          </div>
        ))}
        <ListButtonItem onClick={e => this.handleAddBlank(e)}>
          <span>{i18n.t('urlBuilder:valueSets.valueSetForm.addRow.label')}</span>
        </ListButtonItem>
      </section>
    );
  }
}

const valuesSetsValidation = values => {
  if (!values) return undefined;
  const predefinedValuesSetValueList = [];
  const valueSetslabelsList = values.map(item => item.label);

  values.forEach((valuesSet, setIndex) => {
    const valuesSetsErrors = {};
    // labels list excluding value from current row
    const labelsListExcludingCurrent = [...valueSetslabelsList];
    labelsListExcludingCurrent[setIndex] = '';

    if (!valuesSet.label) {
      valuesSetsErrors.label = i18n.t('urlBuilder:valueSets.valueSetForm.value.validation.empty');
      predefinedValuesSetValueList[setIndex] = valuesSetsErrors;
    } else if (labelsListExcludingCurrent.includes(valuesSet.label)) {
      valuesSetsErrors.label = i18n.t('urlBuilder:valueSets.valueSetForm.value.validation.unique');
      predefinedValuesSetValueList[setIndex] = valuesSetsErrors;
    }

    if (!valuesSet.value) {
      valuesSetsErrors.value = i18n.t('urlBuilder:valueSets.valueSetForm.value.validation.empty');
      predefinedValuesSetValueList[setIndex] = valuesSetsErrors;
    }
  });

  if (predefinedValuesSetValueList.some(obj => !isEmpty(obj))) {
    return predefinedValuesSetValueList;
  }

  return undefined;
};

const isFormFieldUnique = fieldName => (value, allValues, props) => {
  let itemsList = [];
  if (props) {
    itemsList = props.valueSets.map(item => item[fieldName]);
  }
  if (props.initialValues) {
    itemsList = itemsList.filter(item => item !== props.initialValues[fieldName]);
  }
  return isValueUnique(value, itemsList);
};

const isNameUnique = isFormFieldUnique('predefinedValuesSetName');

class ValueSetsForm extends React.Component {
  toggleSectionValidation(e) {
    e.preventDefault();
    this.props.touch('predefinedValuesSetValueList.value');
    this.props.touch('predefinedValuesSetValueList.label');
  }

  render() {
    const {
      handleSubmit,
      submitting,
      backHref,
      t,
      validateSets,
      handleSetsValidationShow,
      handleSetsValidationHide,
      fieldsMeta,
      editForm,
    } = this.props;
    return (
      <section>
        <Form onSubmit={handleSubmit} isSubmitting={submitting}>
          <Field
            label={t('urlBuilder:valueSets.valueSetForm.valueSetName.label')}
            name="predefinedValuesSetName"
            placeholder={t('urlBuilder:valueSets.valueSetForm.valueSetName.placeholder')}
            component={ReduxFormInputField}
            validate={[isRequired, isNameUnique, defaultMaxInput]}
            type="text"
          />
          <FieldArray
            name="predefinedValuesSetValueList"
            validate={valuesSetsValidation}
            component={RenderValueSets}
            handleSetsValidationShow={handleSetsValidationShow}
            handleSetsValidationHide={handleSetsValidationHide}
            fieldsMeta={fieldsMeta && fieldsMeta.predefinedValuesSetValueList}
            validateSets={validateSets}
            editForm={editForm}
            t={t}
          />
          <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
        </Form>
      </section>
    );
  }
}

ValueSetsForm.propTypes = {
  handleSubmit: PropTypes.func,
  backHref: PropTypes.string,
  t: PropTypes.func,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default ValueSetsForm;
