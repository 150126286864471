angular.module('webUi.ui.tagmanagement.tags.conditions.mobileProperty', [
    'webUi.common.Utils'
])
    .controller('TagmanagementTagFormMobilePropertyConditionController', [
        '$scope',
        '$timeout',
        'TagmanagementService',
        'TagmanagementServiceHelper',
        'ValidService',
        'Utils',
        /**
     * @param $scope
     * @param $timeout
     * @param TagmanagementService
     * @param ValidService
     * @param Utils
     */
        function TagmanagementTagFormMobilePropertyConditionController(
            $scope,
            $timeout,
            TagmanagementService,
            TagmanagementServiceHelper,
            ValidService,
            Utils) {

            var conditionClazz = 'MobilePropertyCondition';

            TagmanagementService.getMatchTypes().then(
                function onSuccess(matchTypes) {
                    $scope.matchTypes = Utils.objectMap(matchTypes, 'name');
                },
                function onError(){}
            );

            TagmanagementService.getMobileProperties().then(
                function onSuccess(mobileProperties) {
                    $scope.mobileProperties = mobileProperties;
                },
                function onError(){}
            );

            $scope.isValidPattern = function isValidPattern(pattern) {
            //don't validate as pattern is we're not looking for a regex
            // matchType can be either literal string value or enum object
                var isPattern = _.contains(['REGEX', 'NOT_REGEX'], $scope.state.formCondition.matchType.name) ||
                _.contains(['REGEX', 'NOT_REGEX'], $scope.state.formCondition.matchType);
                if (!isPattern) {
                    return true;
                }
                return ValidService.isValidPattern(pattern);
            };

            $scope.state.conditions = _.clone($scope.getConditions(conditionClazz));

            $scope.newCondition = function newCondition() {
                $scope.state.formCondition = {
                    clazz: conditionClazz,
                    mode: 'new'
                };
            };

            $scope.showProperty = function getPropertyNameFromLabel(propertyParameter){
                return TagmanagementServiceHelper.getPropertyNameFromLabel(propertyParameter, $scope.mobileProperties);
            };

            /**
         * Check whether we should show a propertyValue input field (with validation) based on the allowed values of the matchType in this form
         * @param inArray - the array of matchType values that would trigger showing a particular propertyValue input field
         */
            $scope.showPropertyValue = function showPropertyValue(inArray) {
                var matchType = $scope.state.formCondition.matchType;
                if(!matchType) {
                    return false;
                }
                // matchType can be either literal string value or enum object, so check both
                return _.contains(inArray, matchType.name) || _.contains(inArray, matchType);
            };

            $scope.selectCondition = function selectCondition(condition) {
                $scope.state.formCondition = angular.copy(condition);
                $scope.state.formCondition.mode = 'edit';
                $scope.state.formCondition.matchTypeIdx = _.findIndex($scope.matchTypes, condition.matchType);
            };

            /**
         * Whenever the match type changes, clear the property value
         */
            $scope.clearConditionValue = function () {
                $scope.state.formCondition.value = undefined;
            };
        }
    ]);
