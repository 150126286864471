import { get } from 'lodash';

/**
 *
 * @param state
 * @returns {*}
 */
export const getSitesStats = state => get(state, 'context.clients.siteStats.list');

/**
 * Get the Total Usage object with sum of stats for all sites
 * @param state
 * @returns {*}
 */
export const getTotalUsage = state => {
  const siteStats = getSitesStats(state);

  if (!siteStats || siteStats.length <= 1) return null;

  // get the keys we will use for the total usage
  // tagCalls, addImpressions, etc...
  const keys = Object.keys(siteStats[0]);

  return keys.reduce((accumulator, key) => {
    switch (key) {
      case 'siteName':
      case 'siteId':
      case 'siteNumber':
      case 'rangeFrom':
      case 'rangeTo':
      case 'empty':
        return {
          ...accumulator,
          [key]: null,
        };
      default:
        return {
          ...accumulator,
          [key]: siteStats.reduce((acc, current) => acc + current[key], 0),
        };
    }
  }, {});
};

/**
 *
 * @param state
 * @returns {*}
 */
export const isFetchingClientSitesStats = state => get(state, 'context.clients.siteStats.ui.isFetching');
