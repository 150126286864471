import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import { useAPI } from '~/common';
import { buildUrl } from '~/common/history';
import Link from '~/components/src/Link';
import HumanNumber from '~/components/src/HumanNumber';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { useDashboard } from './context';
import { fetchTotalStats } from './dataService';
import { MetricProps, MetricsProps, FetchTotalStatsResponse } from './types';

const TOTAL_CALLS_PROPERTIES = [
  'pageviews',
  'engagements',
  'conversions',
  'externalfacts',
  'adImpressions',
  'bannerviews',
  'otherEvents',
  'apiCalls',
  'tagCalls',
  'tdnDataCalls',
];

const MetricContiainer = ({
  children,
  label,
  className,
}: {
  children: ReactNode;
  label: string;
  className?: string;
}) => (
  <div className={cx('p-4', className)}>
    <p className="text-md font-medium dark:text-white">{label}</p>
    {children}
  </div>
);

const Metric = ({ label, value, icon, url, testHook }: MetricProps) => (
  <div className={cx('flex items-center gap-4', { [`t-${testHook}`]: testHook })}>
    <Icons icon={icon} className="h-10 w-10 rounded bg-slate-400 bg-opacity-10 p-2 text-slate-400" />
    <div>
      <p className="t-metricValue text-xl font-medium normal-nums leading-6 text-emerald-500">
        <HumanNumber number={value || 0} />
      </p>
      <p className="t-metricLabel font-medium text-slate-600 dark:text-slate-400">
        {url ? (
          <Link className="Link--text" href={buildUrl('tagmanagement/stats')}>
            {i18n.t('dashboard:panels.tags.tagErrors')}
          </Link>
        ) : (
          label
        )}
      </p>
    </div>
  </div>
);

const getEvents = (totalStats: FetchTotalStatsResponse) => [
  {
    label: i18n.t('dashboard:panels.events.pageViews'),
    value: totalStats.pageviews || 0,
    icon: 'visit',
    name: 'pageViews',
  },
  {
    label: i18n.t('dashboard:panels.events.engagements'),
    value: totalStats.engagements || 0,
    icon: 'engagement',
    name: 'engagements',
  },
  {
    label: i18n.t('dashboard:panels.events.conversions'),
    value: totalStats.conversions || 0,
    icon: 'conversion',
    name: 'conversions',
  },
  {
    label: i18n.t('dashboard:panels.events.externalFacts'),
    value: totalStats.externalfacts || 0,
    icon: 'externalFact',
    name: 'externalFacts',
  },
  {
    label: i18n.t('dashboard:panels.events.adImpressions'),
    value: totalStats.adImpressions || 0,
    icon: 'ad',
    name: 'adImpressions',
  },
  {
    label: i18n.t('dashboard:panels.events.bannerViews'),
    value: totalStats.bannerviews || 0,
    icon: 'banner',
    name: 'bannerViews',
  },
  {
    label: i18n.t('dashboard:panels.events.otherEvents'),
    value: totalStats.otherEvents || 0,
    icon: 'otherEvents',
    name: 'otherEvents',
  },
  {
    label: i18n.t('dashboard:panels.events.apiCalls'),
    value: totalStats.apiCalls || 0,
    icon: 'apiCalls',
    name: 'apiCalls',
  },
];

const getTags = (totalStats: FetchTotalStatsResponse) => [
  {
    label: i18n.t('dashboard:panels.tags.tagCalls'),
    value: totalStats.tagCalls || 0,
    icon: 'tag',
    name: 'tagCalls',
  },
  {
    label: i18n.t('dashboard:panels.tags.tagErrors'),
    value: totalStats.tagErrors || 0,
    icon: 'tagError',
    url: buildUrl('tagmanagement/stats'),
    name: 'tagErrors',
  },
  {
    label: i18n.t('dashboard:panels.tags.tdnDataCalls'),
    value: totalStats.tdnDataCalls || 0,
    icon: 'tdnCall',
    name: 'tdnDataCalls',
  },
];

function Metrics({ canViewEvents, canViewTags, canViewTotalCalls }: MetricsProps): ReactElement {
  const dashboardContextValue = useDashboard();
  const rangeFrom = dashboardContextValue?.state.rangeFrom;
  const rangeTo = dashboardContextValue?.state.rangeTo;

  const { data, isLoading } = useAPI(async () => {
    if (typeof rangeFrom === 'number' && typeof rangeTo === 'number') {
      const totalStats = await fetchTotalStats(rangeFrom, rangeTo);

      const events = getEvents(totalStats);
      const tags = getTags(totalStats);

      const totalCallsCount = Object.entries(totalStats).reduce((totalCallsCount, [propertyName, propertyValue]) => {
        if (TOTAL_CALLS_PROPERTIES.includes(propertyName)) {
          totalCallsCount += propertyValue;
        }

        return totalCallsCount;
      }, 0);

      return { totalCallsCount, events, tags };
    }

    return null;
  }, [rangeFrom, rangeTo]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center bg-gray-50 p-24 dark:bg-gray-800">
        <Spin />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 divide-y divide-gray-200 bg-slate-50 dark:divide-gray-700 dark:bg-gray-800 lg:grid-cols-5 lg:divide-y-0 lg:divide-x">
      {canViewTotalCalls && (
        <MetricContiainer
          label={i18n.t('dashboard:panels.metrics.totalCalls')}
          className="t-totalCallsSection flex flex-col"
        >
          <p className="flex flex-1 items-center justify-center text-4xl text-emerald-500 lg:text-7xl">
            <HumanNumber number={data?.totalCallsCount || 0} />
          </p>
        </MetricContiainer>
      )}

      {canViewEvents && (
        <MetricContiainer label={i18n.t('dashboard:panels.metrics.events')} className="t-eventsSection col-span-3">
          <div className="mt-4 grid grid-cols-2 gap-4 md:grid-cols-3">
            {data?.events.map((event, i) => (
              <Metric
                key={`event-${i}`}
                label={event.label}
                value={event.value}
                icon={event.icon}
                testHook={event.name}
              />
            ))}
          </div>
        </MetricContiainer>
      )}

      {canViewTags && (
        <MetricContiainer label={i18n.t('dashboard:panels.metrics.tags')}>
          <div className="t-tagsSection mt-4 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-1">
            {data?.tags.map((tag, i) => (
              <Metric
                key={`tag-${i}`}
                label={tag.label}
                value={tag.value}
                icon={tag.icon}
                url={tag.url}
                testHook={tag.name}
              />
            ))}
          </div>
        </MetricContiainer>
      )}
    </div>
  );
}

export default Metrics;
