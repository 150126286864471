/**
 * Aggregator module
 */
angular.module('webUi.service.content', [
    'webUi.service.content.ad',
    'webUi.service.content.campaign',
    'webUi.service.content.creative',
    'webUi.service.content.creative.preview',
    'webUi.service.content.datafeed',
    'webUi.service.content.placement',
    'webUi.service.content.placementgroup',
    'webUi.service.content.medialibrary'
]);
