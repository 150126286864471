import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import {
  fetchCustomerFactIfNeeded,
  showDependantsContainer,
  deleteCustomerFact,
  hideDependantsContainer,
  changeDependantTypesSelectedTab,
  fetchCustomerFactTrafficSources,
  fetchCustomerFactProperties,
  fetchCustomerFactActivity,
  addProperties,
} from '~/profiles/customerFacts/actions';
import {
  isFetchingCustomerFact,
  getCustomerFactById,
  getPropertiesTableInfo,
  isDependantsContainerVisible,
  getSelectedId,
  getSelectedTab,
  getSelectedProperty,
  isFetchingCustomerFactActivity,
  isFetchingCustomerFactTrafficSources,
  isFetchingCustomerFactProperties,
  isPropertyBeingAdded,
} from '~/profiles/customerFacts/selectors';
import i18n from '~/i18n';
import CustomerFactViewPage from './presenter';

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const customerFactId = ownProps?.params.customerFactId;
  const { canEditProfiles } = ownProps;
  return {
    customerFactId,
    canEditProfiles,
    customerFact: getCustomerFactById(state, customerFactId),
    isFetchingCustomerFact: isFetchingCustomerFact(state),
    isFetchingCustomerFactActivity: isFetchingCustomerFactActivity(state),
    isFetchingCustomerFactTrafficSources: isFetchingCustomerFactTrafficSources(state),
    isFetchingCustomerFactProperties: isFetchingCustomerFactProperties(state),
    isPropertyBeingAdded: isPropertyBeingAdded(state),
    customerFactTableStateKey: `PROFILES/CUSTOMER_FACTS/${customerFactId}/INFO`,
    propertyStateKey: `PROFILES/CUSTOMER_FACTS/${customerFactId}/PROPERTIES`,
    propertiesList: getPropertiesTableInfo(state, customerFactId),
    dependantsContainerIsVisible: isDependantsContainerVisible(state),
    selectedPropertyId: getSelectedId(state),
    selectedTab: getSelectedTab(state),
    selectedProperty: getSelectedProperty(state, customerFactId, getSelectedId(state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const customerFactId = ownProps?.params.customerFactId;
  return {
    fetchCustomerFactIfNeeded: () => dispatch(fetchCustomerFactIfNeeded(customerFactId)),
    fetchCustomerFactActivity: () => dispatch(fetchCustomerFactActivity(customerFactId)),
    fetchCustomerFactTrafficSources: () => dispatch(fetchCustomerFactTrafficSources(customerFactId)),
    fetchCustomerFactProperties: () => dispatch(fetchCustomerFactProperties(customerFactId)),
    onDependantIconClick: (customerFactId, dependantType, propertyId) => {
      dispatch(showDependantsContainer(customerFactId, dependantType, propertyId));
    },
    onDependantsTabChange: selectedTab => {
      dispatch(changeDependantTypesSelectedTab(selectedTab));
    },
    onAddProperties: (customerFact, properties) => {
      dispatch(addProperties(customerFact, properties));
    },
    onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
    onDeleteCustomerFact: customerFact => {
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('customerFacts:list.deleteModal.title'),
          message: i18n.t('customerFacts:list.deleteModal.message', { name: customerFact.type }),
          onConfirm: () => dispatch(deleteCustomerFact(customerFact.customerFactId, customerFact.hasDependants)),
        }),
      );
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate(['customerFacts']),
)(CustomerFactViewPage);
