import { RulesTypes } from '~/common/modules/filterRules/types';
import { CONSTRAINT_GROUP_TYPE } from './constants';

export const parseTimeCondition = timeCondition => {
  let number = null;
  let unit = null;
  if (timeCondition) {
    unit = timeCondition.slice(-1);
    number = parseInt(timeCondition, 10);
  }
  return [number, unit];
};

export const canAddPropertyRow = (propertyTypes, configuredRows = []) => {
  const canCreateCustomProperty = propertyTypes.some(propertyType => propertyType.dataField === null);
  const hasEmptyRows = configuredRows.some(row => row.dataField === undefined);

  const maxRowsCount = propertyTypes.length;
  const isRowsCountLimitReached = configuredRows.length === maxRowsCount;

  if (canCreateCustomProperty) {
    return !hasEmptyRows;
  }

  return !hasEmptyRows && !isRowsCountLimitReached;
};

export const shouldRenderUniqueConstraint = (selectedConstraint, type) => {
  switch (type) {
    case RulesTypes.Visit:
    case RulesTypes.Conversion:
      return !!selectedConstraint;
    case RulesTypes.Engagement:
      return (
        !!selectedConstraint &&
        ![CONSTRAINT_GROUP_TYPE.DATE, CONSTRAINT_GROUP_TYPE.TIMEFRAME].includes(selectedConstraint.group.name)
      );
    default:
      return false;
  }
};

export const getSelectedProperty = (selectedPropertyName, propertyTypes) =>
  propertyTypes.find(propertyType => propertyType.dataField === selectedPropertyName);
