import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { size as sizes } from './defaultSizes';

/**
 * This is a fix due to the bug in Redux-form
 * https://github.com/erikras/redux-form/issues/860
 * Usecase: We are stopping the chain event when the user click in cancel
 * meaning validation will not be trigger
 * */
const handleBlur = (event, onBlur) => {
  const { relatedTarget } = event;
  if (relatedTarget && relatedTarget.getAttribute('preventvalidation')) {
    event.preventDefault();
  } else {
    onBlur(event);
  }
};

const Input = ({
  disabled,
  input = { onBlur: () => {} },
  size = sizes.default,
  placeholder,
  type = 'text',
  isError,
  className,
  testHook = (input && input.name) || 'input',
  ...other
}) => (
  <input
    {...input}
    {...other}
    onBlur={event => handleBlur(event, input.onBlur)}
    disabled={disabled}
    placeholder={placeholder}
    type={type}
    className={cx(
      'Element Element--input',
      `Element--${size}`,
      `t-${testHook}`,
      className,
      { 'is-disabled': disabled },
      { 'is-error': isError },
    )}
  />
);

Input.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.any,
  }),
  size: PropTypes.oneOf(['small', 'default', 'wide', 'block']),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isError: PropTypes.bool,
  className: PropTypes.string,
};

export default Input;
