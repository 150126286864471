import React, { useContext } from 'react';

import { useAPI, useApiWithState } from '~/common';
import Spin from '~/components/src/Spin';

import { CampaignHeader } from './components/CampaignHeader';
import { Mappings } from './components/Mappings';
import { UrlBuilderContext } from './UrlBuilderContext';
import { getMappedCampaignDetails } from './utils';
import { fetchCampaignMappings } from './dataService';

const UrlBuilderContent = ({ campaignId }: { campaignId: string }): JSX.Element => {
  const contextValues = useContext(UrlBuilderContext);
  const { columns } = contextValues;

  const {
    state: campaignDetails,
    isLoading: isCampaignsLoading,
    refetch: refetchSelectedCampaign,
  } = useApiWithState(() => getMappedCampaignDetails(campaignId, columns), [campaignId]);

  const {
    state: campaignMappings,
    isLoading: isMappingsLoading,
    refetch: refetchCampaignMappings,
  } = useApiWithState(() => fetchCampaignMappings(campaignId), [campaignId]);

  const refetchCampaign = () => {
    refetchSelectedCampaign();
    refetchCampaignMappings();
  };

  if (isCampaignsLoading || isMappingsLoading || !campaignMappings || !campaignDetails) return <Spin />;

  return (
    <div className="ml-8 mr-5">
      <div className="mt-8 flex h-24 flex-col gap-8">
        <div className="flex-1 ">
          <CampaignHeader campaignDetails={campaignDetails} refetchCampaign={refetchCampaign} />
        </div>
        <hr />
        <Mappings
          campaignMappings={campaignMappings}
          campaignDetails={campaignDetails}
          refetchCampaignMappings={refetchCampaignMappings}
        />
      </div>
    </div>
  );
};

export default UrlBuilderContent;
