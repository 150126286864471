import React from 'react';
import i18n from '~/i18n';

const DataFeedInfo = (): React.ReactElement => (
  <>
    <p>{i18n.t('dataFeeds:description.line1')}</p>
    <p className="pb-4">{i18n.t('dataFeeds:description.line2')}</p>
    <p className="pb-2 text-lg">{i18n.t('dataFeeds:description.productInfo')}</p>
    <p className="pb-4">{i18n.t('dataFeeds:description.productInfoDesc')}</p>
    <p className="pb-2 text-lg">{i18n.t('dataFeeds:description.otherDataFeed')}</p>
    <p className="pb-2">{i18n.t('dataFeeds:description.otherDataFeedDesc')}</p>
  </>
);

export default DataFeedInfo;
