import React, { useState } from 'react';
import moment from 'moment';
import { TStepChartProps } from '~/workflows/types';
import { DateRangePicker, StackedChart } from '~/components';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import Icons from '~/components/src/Icons';
import { Panel } from '~/components/src/Containers';
import Spin from '~/components/src/Spin';
import { fetchStepStats } from '~/workflows/dataService';
import { useAPI } from '~/common';
import { mapStepStats } from '../util';
import { ORIGIN_TYPES, VARIANT_STATS_TABS } from '../constants';
import StepCards from './StepCards';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const StepChart = ({ workflowId, allSteps }: TStepChartProps) => {
  const [selectedStep, setSelectedStep] = useState(allSteps[0].nodeId);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [dateRange, setDateRange] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const { startDate, endDate } = dateRange;

  const origin = selectedTabIndex ? ORIGIN_TYPES.HISTORIC_PROCESSING : undefined;

  const { data: stepStats, isLoading: isStepStatsLoading } = useAPI(() => {
    if (selectedStep)
      return fetchStepStats(
        workflowId,
        selectedStep,
        startDate.startOf('day').toDate().getTime(),
        endDate.endOf('day').toDate().getTime(),
        origin,
      );
    return Promise.resolve(null);
  }, [workflowId, selectedStep, selectedTabIndex, startDate, endDate]);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setDateRange({
      startDate,
      endDate,
    });
  };

  return (
    <div className="flex flex-col gap-2 pt-4">
      {allSteps && allSteps.length > 1 && (
        <StepCards allSteps={allSteps || []} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
      )}
      <div>
        <div className="absolute right-4 mt-7">
          <DateRangePicker
            testHook="stepStatsDatePicker"
            startDate={startDate}
            endDate={endDate}
            handleDatesChange={handleDatesChange}
          />
        </div>
        <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
          <TabList>
            {VARIANT_STATS_TABS.map(origin => (
              <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                <span className="flex items-center gap-2">
                  {origin.label}
                  {origin?.description && (
                    <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                  )}
                </span>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Panel className="mt-7">
          {isStepStatsLoading ? (
            <Spin />
          ) : (
            <StackedChart
              hideGenealInfo
              height={300}
              className="t-stepStatisticsChart"
              data={mapStepStats(stepStats || [], origin)}
            />
          )}
        </Panel>
      </div>
    </div>
  );
};

export default StepChart;
