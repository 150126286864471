import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Form from '~/components/src/Form/Form';
import { ReduxFormDateTimeField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, endDateIsAfterStartDate, startDateIsBeforeEndDate, makeIsDateFormatValid } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import './DateRangeForm.scss';

const DATE_FORMAT = 'DD/MM/YYYY';

const isDateFormatValid = makeIsDateFormatValid(DATE_FORMAT);

export const DateRangeForm = ({ handleSubmit, reset, submitting, t, ...props }) => {
  const { touch } = props;
  return (
    <Form onSubmit={handleSubmit} isSubmitting={submitting}>
      <div className="flex items-center gap-4">
        <Field
          label={t('rangeDates:labelStartDate')}
          name="startDate"
          component={ReduxFormDateTimeField}
          placeholder={t('rangeDates:placeholderStartDate')}
          validate={[isRequired, isDateFormatValid, startDateIsBeforeEndDate]}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
          touch={touch}
        />
        <Field
          label={t('rangeDates:labelEndDate')}
          name="endDate"
          component={ReduxFormDateTimeField}
          placeholder={t('rangeDates:placeholderEndDate')}
          validate={[isRequired, isDateFormatValid, endDateIsAfterStartDate]}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
          touch={touch}
        />

        <div className="mt-2.5 flex items-center gap-4">
          <BtnIcon
            onClick={reset}
            icon="close"
            disabled={submitting}
            tooltip={t('rangeDates:reset')}
            testHook="reset"
          />
          <BtnIcon
            testHook="search"
            color="blue"
            icon="search"
            type="submit"
            disabled={submitting}
            tooltip={t('rangeDates:submitText')}
          />
        </div>
      </div>
    </Form>
  );
};

DateRangeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  t: PropTypes.func,
  touch: PropTypes.func,
};

export default DateRangeForm;
