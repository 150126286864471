import React from 'react';
import i18n from '~/i18n';
import moment from 'moment';
import cx from 'classnames';
import { THistoricProcessing } from '~/workflows/types';
import icons from '~/components/src/Icons/icons';
import Icons from '~/components/src/Icons';
import { DATE_FORMAT, HP_STATUS_ICON_STYLES } from '~/workflows/constants';

const getHPIconData = (hpStatus: string): { icon: keyof typeof icons; iconClass: string } => {
  const status = hpStatus.toUpperCase();
  const style = HP_STATUS_ICON_STYLES[status as keyof typeof HP_STATUS_ICON_STYLES];
  return style || HP_STATUS_ICON_STYLES['DEFAULT' as keyof typeof HP_STATUS_ICON_STYLES];
};

const HPStatus = ({ hpData }: { hpData: THistoricProcessing }) => {
  const iconData = getHPIconData(hpData?.state?.name || '');
  const tooltip = (
    <>
      {i18n.t('workflow:common.hpStatus')}: <b>{hpData?.state?.label}</b>
      <br />
      {i18n.t('workflow:common.hpLastRun')}: {moment(hpData?.triggeredOn).format(DATE_FORMAT)}
    </>
  );

  return (
    <div className="mr-4 text-center">
      <Icons
        icon={iconData.icon}
        tooltip={hpData?.state?.label ? tooltip : ''}
        className={cx('t-hpStatus', iconData.iconClass, 'h-10 w-10 rounded p-2')}
      />
    </div>
  );
};

export default HPStatus;
