import { get } from 'lodash';

const fetchingAdvertisers = state => get(state, 'profiles.connectorsForm.promise.fetchingAdvertisers');
const getAdvertisers = state =>
  get(state, 'profiles.connectorsForm.advertisers').map(advertiser => ({
    ...advertiser,
    value: advertiser.id,
    label: advertiser.name,
  }));

export { fetchingAdvertisers, getAdvertisers };
