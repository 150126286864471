import React, { ReactElement, useContext, useState } from 'react';
import moment from 'moment';

import i18n from '~/i18n';
import { buildUrl } from '~/common';
import { Heading, Notification, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import DeletePopup from '~/components/src/DeletePopup';

import { deleteCampaign } from '../dataService';
import { UrlBuilderContext } from '../UrlBuilderContext';
import { CampaignsForm } from './CampaignsForm';
import { CampaignDetails } from '../types';
import { DATE_FORMAT } from '../constants';

export const CampaignHeader = ({
  campaignDetails,
  refetchCampaign,
}: {
  campaignDetails: CampaignDetails;
  refetchCampaign: () => void;
}): ReactElement => {
  const contextValues = useContext(UrlBuilderContext);
  const { hasUrlBuilderPermission, navigate, refetchTree } = contextValues;
  const [deleteModalCampaignId, setDeleteModalCampaignId] = useState<string>('');
  const [isEditCamaignVisible, setIsEditCamaignVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteCampaign = async () => {
    setIsDeleting(true);
    setDeleteModalCampaignId('');
    await deleteCampaign(deleteModalCampaignId);
    setIsDeleting(false);
    navigate(buildUrl('tagmanagement/urlbuilder/campaign/dashboard'));
    refetchTree();
  };

  const handleHideCampaignFormModal = () => {
    setIsEditCamaignVisible(false);
    refetchCampaign();
  };

  const { name, campaignId, addedBy, addedOn } = campaignDetails;

  return (
    <>
      <Heading className="py-0" testHook="campaignHeading" title={name}>
        <BtnIcon
          icon="delete"
          tooltip={i18n.t('campaigns:tooltips.deleteCampaign')}
          testHook="deleteCampaign"
          disabled={!hasUrlBuilderPermission}
          onClick={() => setDeleteModalCampaignId(campaignId)}
        />
        <BtnIcon
          icon="edit"
          color="blue"
          tooltip={i18n.t('campaigns:tooltips.editCampaign')}
          testHook="editCampaign"
          disabled={!hasUrlBuilderPermission}
          onClick={() => setIsEditCamaignVisible(true)}
        />
      </Heading>
      <p className="t-created text-gray-500">
        {i18n.t('campaigns:view.createdBy')} <span className="font-semibold">{addedBy}</span>{' '}
        {i18n.t('campaigns:view.createdOn')}{' '}
        <span className="font-semibold">{moment(addedOn).format(DATE_FORMAT)}</span>
      </p>
      {deleteModalCampaignId && (
        <DeletePopup
          isLoading={isDeleting}
          title={i18n.t('campaigns:deleteModalHeader')}
          hidePopup={() => setDeleteModalCampaignId('')}
          onClick={handleDeleteCampaign}
        >
          <Notification testHook="deleteCampaignText">{i18n.t('campaigns:deleteAreYouSure', { name })}</Notification>
        </DeletePopup>
      )}
      {isEditCamaignVisible && (
        <PickerView
          handlePickerVisibility={setIsEditCamaignVisible}
          pickerTitle={i18n.t('campaigns:form.update')}
          className="!h-auto !w-[40%]"
        >
          <CampaignsForm hideModal={() => handleHideCampaignFormModal()} campaignDetails={campaignDetails} />
        </PickerView>
      )}
    </>
  );
};
