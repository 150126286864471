import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Form from '~/components/src/Form/Form';
import { ReduxFormInputField } from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, defaultMaxInput } from '~/common';
import { Notification } from '~/components';

export const TruncateSite = ({ confirmationText, isConfirmationValid, handleSubmit, submitting, site, t }) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <Notification kind="warning">
      {t('sites:truncate.body', {
        siteName: site.name,
        confirmationText,
      })}
    </Notification>
    <Field
      label={t('sites:truncate.confirmation.label')}
      name="confirmation"
      component={ReduxFormInputField}
      placeholder={t('sites:truncate.confirmation.placeholder', {
        confirmationText,
      })}
      validate={[isRequired, defaultMaxInput, isConfirmationValid]}
      autoComplete="off"
      type="text"
    />
    <Notification>{t('sites:truncate.info')}</Notification>
  </Form>
);

TruncateSite.propTypes = {
  site: PropTypes.object,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmationText: PropTypes.string.isRequired,
  isConfirmationValid: PropTypes.func.isRequired,
};

export default TruncateSite;
