angular.module('webUi.ui.tagmanagement.tags.view.codepopup', [
    'ui.codemirror'
])

    .controller('TagViewCodePopupDialogController', ['$scope', '$state', '$uibModalInstance', 'dialogsModel', 'TagmanagementService',
        function TagViewCodePopupDialogController($scope,   $state,   $uibModalInstance,   dialogsModel,   TagmanagementService){

            var tag = dialogsModel.tag;
            $scope.tag = tag;

            $scope.hasTagEdit = dialogsModel.hasTagEdit;

            $scope.overrideOptions = {
                readOnly: true
            };

            $scope.code = {
                tagTemplateCode: ''
            };

            TagmanagementService.getTagTemplateCode(tag.tagId, tag.sequenceNr).then(function(result){
                $scope.code.tagTemplateCode = result.code;
                $scope.code.success = result.success;
            });

            $scope.convertTagToCustom = function(tagId, code){
                TagmanagementService.convertTagToCustom(tagId, code).then(function(){
                    $state.go('site.tagmanagement.tags.view', {
                        id: tagId
                    },{
                        reload: true
                    });
                    $uibModalInstance.close(true);
                });
            };

            $scope.close = function(){
                $uibModalInstance.close(true);
            };

        }]);

