import React from 'react';
import Icon from 'react-icon-base';

const R42CJ = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M6.54545455,15.2727273 L0,15.2727273 L0,8.72727273 L6.54545455,8.72727273 L6.54545455,10.9090909 L8.72727273,10.9090909 L8.72727273,2.18181818 L15.2727273,2.18181818 L15.2727273,0 L21.8181818,0 L21.8181818,6.54545455 L15.2727273,6.54545455 L15.2727273,4.36363636 L10.9090909,4.36363636 L10.9090909,10.9090909 L13.0909091,10.9090909 L13.0909091,8.72727273 L19.6363636,8.72727273 L19.6363636,15.2727273 L13.0909091,15.2727273 L13.0909091,13.0909091 L10.9090909,13.0909091 L10.9090909,19.6363636 L15.2727273,19.6363636 L15.2727273,17.4545455 L21.8181818,17.4545455 L21.8181818,24 L15.2727273,24 L15.2727273,21.8181818 L8.72727273,21.8181818 L8.72727273,13.0909091 L6.54545455,13.0909091 L6.54545455,15.2727273 Z M19.6363636,19.6363636 L17.4545455,19.6363636 L17.4545455,21.8181818 L19.6363636,21.8181818 L19.6363636,19.6363636 Z M17.4545455,10.9090909 L15.2727273,10.9090909 L15.2727273,13.0909091 L17.4545455,13.0909091 L17.4545455,10.9090909 Z M4.36363636,10.9090909 L2.18181818,10.9090909 L2.18181818,13.0909091 L4.36363636,13.0909091 L4.36363636,10.9090909 Z M19.6363636,2.18181818 L17.4545455,2.18181818 L17.4545455,4.36363636 L19.6363636,4.36363636 L19.6363636,2.18181818 Z"></path>
  </Icon>
);

export default R42CJ;
