angular.module('webUi.ui.content.campaigns.ads.delete',
    [
        'ngResource',
        'ui.bootstrap'
    ]
)
    .controller('AdservingAdDeleteController', [
        '$uibModalInstance',
        'dialogsModel',
        function AdservingAdDeleteController($uibModalInstance, dialogsModel) {

            'use strict';
            var vm = this;
            vm.data = {};

            //extend data with modal model data
            _.assign(vm.data, dialogsModel.data);

            vm.confirm = function() {
                $uibModalInstance.close({
                    adId: vm.data.adId,
                    campaignId: vm.data.campaignId
                });
            };

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
