import React from 'react';
import CardMessage from '~/components/src/CardMessage';
import { I18NextT } from '~/components/src/Common/types';

const errorTypes = [
  'GoogleAdsUserListUnrecoverableException',
  'GoogleAdsRecoverableException',
  'GoogleAdsAuthenticationRecoverableException',
  'GoogleAdsAuthorizationRecoverableException',
];

export const getError = (errorType: string, t: I18NextT) => {
  switch (errorType) {
    case errorTypes[2]:
      return (
        <p>
          <span className="font-medium">{t('connectors.googleAds.usersList.authErrorHeader')}</span>
          <ol className="mt-2 px-4">
            <li>{t('connectors.googleAds.usersList.authenticationCheck1')}</li>
            <li>{t('connectors.googleAds.usersList.authenticationCheck2')}</li>
            <li>{t('connectors.googleAds.usersList.authenticationCheck3')}</li>
          </ol>
        </p>
      );
    case errorTypes[3]:
      return (
        <p>
          <span className="font-medium">{t('connectors.googleAds.usersList.authErrorHeader')}</span>
          <ol className="mt-2 px-4">
            <li>{t('connectors.googleAds.usersList.authorizationCheck1')}</li>
            <li>{t('connectors.googleAds.usersList.authorizationCheck2')}</li>
          </ol>
        </p>
      );
    case errorTypes[0]:
    case errorTypes[1]:
    default:
      return <p>{t('connectors.googleAds.usersList.genericError')}</p>;
  }
};
