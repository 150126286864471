angular.module('webUi.directive.searchbox', [])

    .directive('searchBox', function () {
        return {

            replace: true,
            transclude: true,
            scope: { searchBinding:'=', searchOnEnter:'&', searchOnEsc:'&', searchNavCallback:'=?' },
            templateUrl : 'directive/searchbox/searchbox.tpl.html',

            compile: function compile() {
                return {
                    post: function(scope, element, attrs) {

                        scope._origin = attrs.id;

                        scope.cancel = function($event) {
                            scope.searchBinding = '';
                            if(scope.searchOnEsc) {
                                scope.searchOnEsc();
                            }
                            $event.preventDefault();
                        };

                        scope.up = function() {
                            if(scope.searchNavCallback) {
                                scope.searchNavCallback(-1);
                            }
                        };

                        scope.down = function() {
                            if(scope.searchNavCallback) {
                                scope.searchNavCallback(1);
                            }
                        };

                        scope.submit = function($event) {
                            if(scope.searchOnEnter) {
                                scope.searchOnEnter();
                            }
                            $event.preventDefault();
                        };
                    }
                };
            }
        };
    });
