/* eslint-disable class-methods-use-this */
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/profiles/collectors`;

/**
 * A service for collectors related queries.
 */
export class AdminCollectorClass {
  getCollectors() {
    return Api.callGet(BASE_PATH).then(response => response.data);
  }

  saveCollector(collector) {
    return Api.callPost(BASE_PATH, collector).then(response => response.data);
  }

  updateCollector(collector) {
    return Api.callPost(`${BASE_PATH}/update`, collector).then(response => response.data);
  }

  getPartnerStats(connectorId, startDate, endDate) {
    return Api.callGet(`${BASE_PATH}/${connectorId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}`).then(
      response => response.data,
    );
  }

  getCode(connectorId) {
    return Api.callGet(`${BASE_PATH}/${connectorId}/code`).then(response => response.data);
  }

  deleteCollector(collectorId) {
    return Api.callDelete(`${BASE_PATH}/${collectorId}`).then(response => response.data);
  }
}

const adminCollectorService = new AdminCollectorClass();
export default adminCollectorService;
