import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { selectors, actionCreators } from 'react-redux-composable-list';
import { Field, reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import { fetchSites } from '~/context/sites';
import {
  getNonSelectedSites,
  getUserById,
  isAddingSites,
  isFetchingUserSites,
  fetchUserSites,
  addUserSites,
  USER_SITES_LIST,
  fetchUser,
} from '~/context/users';
import FormActions from '~/components/src/Form/ActionsDefault';
import Detail from '~/components/src/Form/Detail/Default';
import Form from '~/components/src/Form/Form';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import Page from '~/components/src/Page';
import { Panel } from '~/components/src/Containers';
import Permissions from '~/permissions/containers/PermissionsForm';
import SitesList from '~/context/components/SitesList';
import { SearchContainer } from '~/common';
import { withRouter } from '~/common/withRouter';
import './LinkUserToSite.scss';

const USERS_TRANSLATION_KEY = 'users';
const LINK_USER_SEARCH_KEY = 'CONTEXT/USERS/LINK_USER_TO_SITE_SEARCH';

const searchFn = query => item =>
  item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  item.siteNumber.toString().indexOf(query.toLowerCase()) !== -1;

export class LinkUserToSite extends Component {
  componentDidMount() {
    this.props.fetchSites();
    this.props.fetchUserSites();
    this.props.fetchUser();
  }

  componentWillUnmount() {
    this.props.cleanSelection();
  }

  render() {
    const { user, isFetching, cancelUrl, sites, onSave, isSubmitting, stateKey, t, selected } = this.props;
    return (
      <Page className="LinkUserToSite">
        {isFetching || !user ? (
          <Spinner />
        ) : (
          <>
            <Heading title={t('linkSite.header')} />
            <Panel>
              <Detail label={t('user.email')} value={user.username} />
              <Detail label={t('user.name')} value={user.name} />
              <Detail label={t('user.phoneNumber')} value={user.phoneNumber} />
            </Panel>
            <Field name="permissions" component={Permissions} t={t} withPanels />
            <Heading title={t('linkSite.sitesHeader')}>
              <SearchContainer stateKey={stateKey} searchKey={LINK_USER_SEARCH_KEY} searchFn={searchFn} />
            </Heading>
            <Form onSubmit={onSave} submitting={isSubmitting}>
              <SitesList
                isSelectable={true}
                list={sites}
                t={t}
                stateKey={stateKey}
                emptyBody={t('linkSite.emptySites.body')}
              />
              <FormActions
                actionsClassName="u-marginLeftNone"
                cancelHref={cancelUrl}
                submitting={isSubmitting}
                isDisableSave={!selected.length}
                submitText={t('linkSite.actions.choose')}
                t={t}
              />
            </Form>
          </>
        )}
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      cancelUrl: buildUrl(`context/users/view/${ownProps.params.userId}`),
      sites: getNonSelectedSites(state, ownProps.params.userId),
      stateKey: USER_SITES_LIST,
      selected: selectors.getSelection(state, USER_SITES_LIST),
      isSubmitting: isAddingSites(state),
      isFetching: isFetchingUserSites(state),
      user: getUserById(state, ownProps.params.userId),
    }),
    (dispatch, ownProps) => ({
      fetchUser: () => dispatch(fetchUser(ownProps.params.userId)),
      fetchSites: () => dispatch(fetchSites()),
      fetchUserSites: () => dispatch(fetchUserSites(ownProps.params.userId)),
      onSave: e => {
        e.preventDefault();
        dispatch(addUserSites(ownProps.params.userId));
      },
      cleanSelection: () => dispatch(actionCreators.doSelectItemsReset(USER_SITES_LIST)),
    }),
  ),
  reduxForm({
    form: USER_SITES_LIST,
  }),
  translate([USERS_TRANSLATION_KEY]),
)(LinkUserToSite);
