import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl } from '~/common';
import { translate } from 'react-i18next';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import Spinner from '~/components/src/Spinner';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { MediumWrapper } from '~/components/src/Containers';
import { Tabs, kind, Tab, TabList, TabPanel } from '~/components/src/Tabs';
import SitesLinkedToBasescript from '~/context/components/SitesLinkedToBasescript';
import * as fromModals from '~/modals';
import { includes } from 'lodash';
import { actionCreators } from 'react-redux-composable-list';
import LinkSitesToBasescriptModal from '~/context/basescripts/containers/LinkSitesToBasescriptModal';
import { reduxForm, formValueSelector } from 'redux-form';
import * as basescriptSelectors from '~/context/basescripts/selectors';
import * as siteSelectors from '~/context/sites/selectors';
import {
  fetchBasescriptModulesIfNeeded,
  fetchBasescriptsIfNeeded,
  saveBasescript,
} from '~/context/basescripts/actions';
import { fetchSitesIfNeeded } from '~/context/sites/actions';
import BasescriptForm from '~/context/components/BasescriptForm';
import { withRouter } from '~/common/withRouter';
import BtnIcon from '~/components/src/BtnIcon';
import { setSitesFilter, removeSitesFilter, useBasescriptInSites } from '../basescripts/actions';

const SITES_LINKED_TO_BASESCRIPT_KEY = 'CONTEXT/BASESCRIPTS/SITES_LINKED_TO_BASESCRIPT';
const FILTER_KEY = 'CONTEXT/SITES_LINKED_TO_BASESCRIPT_FILTER';
const FORM_NAME = 'basescriptEdit';
const selectorReduxForm = formValueSelector(FORM_NAME);

const siteFilterFn = query => item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

export class EditBasescriptPage extends Component {
  componentDidMount() {
    this.props.fetchBasescriptsIfNeeded();
    this.props.fetchBasescriptModulesIfNeeded();
    this.props.fetchSitesIfNeeded();
  }

  render() {
    const { props } = this;
    const {
      t,
      authenticated,
      onFilterChange,
      filter,
      buildSiteLink,
      showLinkSitesToBasescriptModal,
      basescriptSites,
      isLoadingData,
      ...other
    } = props;
    return isLoadingData ? (
      <Spinner />
    ) : (
      <Page>
        <MediumWrapper>
          <Tabs kind={kind.heading}>
            <TabList>
              <Tab>{t('tabs.edit')}</Tab>
              <Tab>{t('tabs.sites')}</Tab>
            </TabList>

            <TabPanel>
              <BasescriptForm authenticated={authenticated} {...other} t={t} />
            </TabPanel>

            <TabPanel>
              <Heading title={t('sites.header')}>
                <SearchElement
                  placeholder={t('list.search.placeholder')}
                  value={filter}
                  onChange={e => onFilterChange(e.target.value)}
                />
                {authenticated && (
                  <BtnIcon
                    icon="add"
                    color="blue"
                    onClick={() => showLinkSitesToBasescriptModal(t)}
                    tooltip={t('additionalSites')}
                  />
                )}
              </Heading>
              <SitesLinkedToBasescript
                list={basescriptSites}
                t={t}
                buildSiteLink={buildSiteLink}
                stateKey={SITES_LINKED_TO_BASESCRIPT_KEY}
              />
            </TabPanel>
          </Tabs>
        </MediumWrapper>
      </Page>
    );
  }
}

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const id = ownProps?.params.basescriptId;
  return {
    id,
    initialValues: basescriptSelectors.getBasescriptById(state, id),
    otherBasescripts: basescriptSelectors.getOtherBasescripts(state, id),
    basescriptModules: basescriptSelectors.getBasescriptModules(state),
    formBasescriptModules: selectorReduxForm(state, 'modules'),
    basescriptSites: siteSelectors.getSites(state).filter(site => {
      const basescript = basescriptSelectors.getBasescriptById(state, id);
      if (basescript) {
        basescript.linkedToSites = basescript.linkedToSites || [];
        const siteIdsLinkedToBasescript = basescript.linkedToSites.map(linkedToSite => linkedToSite.siteId);
        return includes(siteIdsLinkedToBasescript, site.siteId);
      }
      return false;
    }),
    filter: basescriptSelectors.getSitesFilter(state),
    cancelHref: buildUrl('context/basescripts/list'),
    buildSiteLink: siteId => buildUrl(`context/sites/view/${siteId}`),
    isLoadingData:
      basescriptSelectors.isFetchingBasescripts(state) ||
      basescriptSelectors.isFetchingBasescriptModules(state) ||
      siteSelectors.isFetchingSites(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps?.params.basescriptId;
  return {
    fetchBasescriptsIfNeeded: () => dispatch(fetchBasescriptsIfNeeded()),
    fetchBasescriptModulesIfNeeded: () => dispatch(fetchBasescriptModulesIfNeeded()),
    fetchSitesIfNeeded: () => dispatch(fetchSitesIfNeeded()),
    showLinkSitesToBasescriptModal: t =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('linkSites.header'),
          confirmText: t('linkSites.confirmText'),
          onConfirm: () => dispatch(useBasescriptInSites(id)),
          content: <LinkSitesToBasescriptModal basescriptId={id} t={t} />,
        }),
      ),
    onSubmit: values => dispatch(saveBasescript(values)),
    onFilterChange: query => {
      if (query !== '') {
        dispatch(actionCreators.doSetFilter(SITES_LINKED_TO_BASESCRIPT_KEY, FILTER_KEY, siteFilterFn(query)));
        dispatch(setSitesFilter(query));
      } else {
        dispatch(actionCreators.doRemoveFilter(SITES_LINKED_TO_BASESCRIPT_KEY, FILTER_KEY));
        dispatch(removeSitesFilter());
      }
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['basescripts']),
)(EditBasescriptPage);
