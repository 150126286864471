import React from 'react';
import Icon from 'react-icon-base';

const R42Cloud = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M23.75,13.869a5.707,5.707,0,0,0-1.739-4.088,5.6,5.6,0,0,0-3.369-1.554.25.25,0,0,1-.19-.12A7.532,7.532,0,0,0,4.646,10.349a.25.25,0,0,1-.226.2A4.507,4.507,0,0,0,.25,15.038a4.252,4.252,0,0,0,1.214,3.154,5.267,5.267,0,0,0,3.493,1.351H18.543a.922.922,0,0,0,.129-.009A5.7,5.7,0,0,0,23.75,13.869Zm-5.281,3.674H4.974a3.3,3.3,0,0,1-2.106-.777,2.3,2.3,0,0,1-.618-1.728,2.507,2.507,0,0,1,2.505-2.506,2.522,2.522,0,0,1,.517.054,1,1,0,0,0,1.2-.92A5.533,5.533,0,0,1,17,9.632a.96.96,0,0,0,.941.57,3.666,3.666,0,0,1,3.805,3.667A3.706,3.706,0,0,1,18.469,17.543Z" />
  </Icon>
);

export default R42Cloud;
