import { Api } from '~/common';
import { API_BASE, parseResponse } from '~/common/ApiService';
import { FallbackJson } from '~/common/types';
import {
  addFakeDataForJourney,
  demoJourneydetails,
  demoJourneyId,
  demoJourneyListData,
  getFakeGoalStats,
  getFakeJourneyStats,
  getFakeStepStats,
  getFakeTotalStatsForStep,
  getFakeTrees,
  getFakeTriggerData,
  getFakeVersions,
  makeFake,
} from '~/workflows/fake';
import { RULE_GROUP_TYPES } from './constants';
import demoGoalStats from './fake/demoGoalStats';
import demoJourney from './fake/demoJourney';
import demoJourneyStats from './fake/demoJourneyStats';
import demoTree from './fake/demoTree';
import fedExTree from './fake/fedExTree';
import { isJoDemoMode } from './fake/utils';
import { HPJobDetails, HPReprocessingModeResponse, TriggerHpPayloadType } from './form/HistoricProcessing/types';
import {
  GoalStats,
  OverlapCandidate,
  Step,
  StepDailyStats,
  StepDailyStatsOrigin,
  TotalStepStats,
  TriggerDailyStats,
  UpdateExperimentsPayload,
} from './form/Journey/types';
import { StatsResponse, TActivityStats } from './form/Statistics/types';
import {
  ActivePartnerResponseItem,
  CreateExperimentsPayload,
  CreateTriggerPayload,
  JourneyContents,
  RuleDefinitionsReponse,
  StepPayload,
  TAudience,
  TConfiguration,
  TDependency,
  TTriggerAnalysisData,
  TGeneral,
  TLabel,
  TStepData,
  TTriggerData,
  TWorkflow,
  TWorkflowPayload,
  TWorkFlowPayload,
  TWorkflowsResponse,
  UpdateTriggerPayload,
} from './types';
import { mapExperimentsStats, parseActivityStats } from './util';
import getExitDemoGraph from './fake/demoExitGraph';
import getGoalDemoGraph from './fake/demoGoalGraph';

const OLD_BASE_PATH = `${API_BASE}/customer/journeys`;
const BASE_PATH = `${API_BASE}/journeyorchestration`;

export const DEFAULT_PAGINATION_SIZE = 20;

export const fetchWorkflows = (payload: TWorkflowPayload): Promise<TWorkflowsResponse> =>
  Api.callPost(`${BASE_PATH}/journeys/search`, payload)
    .then(parseResponse)
    .then(data => {
      const content = isJoDemoMode() ? [demoJourneyListData, ...data.content] : data.content;
      return { ...data, content };
    });

export const fetchDependencies = (workflowId: string): Promise<TDependency[]> => {
  if (isJoDemoMode() && workflowId === demoJourneyId) return Promise.resolve([]);
  return Api.callGet(`${BASE_PATH}/${workflowId}/dependants`).then(parseResponse);
};

export const getOverlapCandidates = (journeyId: string): Promise<OverlapCandidate[]> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/overlapCandidates`).then(parseResponse);

export const getJourneyStats = (journeyId: string): Promise<StatsResponse> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourneyStats);
  return Api.callGet(`${BASE_PATH}/${journeyId}/goals/stats`)
    .then(parseResponse)
    .then(makeFake((response: StatsResponse) => getFakeJourneyStats(response)));
};

export const updateWorkflowLabel = (payload: string[], workflowId: string): Promise<TLabel[]> =>
  Api.callPut(`${BASE_PATH}/${workflowId}/labels`, payload).then(parseResponse);

export const deleteWorkflow = (workflowId: string): Promise<void> =>
  Api.callDelete(`${OLD_BASE_PATH}/${workflowId}`).then(parseResponse);

export const fetchLabels = (): Promise<TLabel[]> => Api.callGet(`${API_BASE}/labels`).then(parseResponse);

export const createWorkflow = (payload: TWorkFlowPayload): Promise<TAudience> =>
  Api.callPost(OLD_BASE_PATH, payload).then(parseResponse);

export const fetchWorkflowData = (workflowId: string): Promise<TWorkflow> => {
  if (isJoDemoMode() && workflowId === demoJourneyId) return Promise.resolve(demoJourneydetails);

  return Api.callGet(`${OLD_BASE_PATH}/${workflowId}`).then(response => {
    const { data } = response;
    const versions = getFakeVersions(workflowId);
    return { ...data, versions };
  });
};

export const updateWorkflow = (id: string, payload: TAudience): Promise<TAudience> =>
  Api.callPut(`${BASE_PATH}/${id}`, payload).then(parseResponse);

export const createTriggers = (payload: CreateTriggerPayload): Promise<string> =>
  Api.callPost(`${OLD_BASE_PATH}/triggers`, payload).then(parseResponse);

export const updateTriggers = (payload: UpdateTriggerPayload): Promise<any> =>
  Api.callPut(`${OLD_BASE_PATH}/triggers`, payload);

export const fetchRuleDefinitions = (rule = RULE_GROUP_TYPES.ALL): Promise<RuleDefinitionsReponse[]> =>
  Api.callGet(`${BASE_PATH}/ruleDefinitions?ruleGroup=${rule}`).then(parseResponse);

export const getRuleVariablesForEventListeners = (workflowId: string, triggerId: string) =>
  Api.callGet(`${BASE_PATH}/${workflowId}/triggers/variables?previousTriggerId=${triggerId}`).then(parseResponse);

export const getActivePartners = (): Promise<ActivePartnerResponseItem[]> =>
  Api.callGet(`${BASE_PATH}/partners`).then(parseResponse);

export const fetchJourneyTree = (workflowId: string, versionId?: string): Promise<JourneyContents> => {
  if (isJoDemoMode() && workflowId === demoJourneyId) return Promise.resolve(demoJourney);
  return Api.callGet(`${BASE_PATH}/${workflowId}/tree${versionId ? `?versionId=${versionId}` : ''}`)
    .then(response => {
      if (workflowId === 'f6c541ed-267d-4800-a8ba-cba6774803c8') {
        return demoTree.data;
      } else if (workflowId === '24d9088b-a357-4c09-b6bd-fb672b186b40') {
        return fedExTree.data;
      } else if (workflowId === '160335e4-c7bf-4068-83ae-f68137b47e0d' && versionId) {
        return getFakeTrees(workflowId, versionId);
      }
      return response.data;
    })
    .then(makeFake((response: JourneyContents) => addFakeDataForJourney(workflowId, response)));
};

export const updateStep = (payload: StepPayload) => Api.callPut(`${OLD_BASE_PATH}/steps`, payload);

export const createStep = (payload: StepPayload) => Api.callPost(`${OLD_BASE_PATH}/steps`, payload);

export const updateExperiments = (payload: CreateExperimentsPayload[], workflowId: string, triggerId: string) =>
  Api.callPut(`${BASE_PATH}/${workflowId}/triggers/${triggerId}/steps`, payload).then(parseResponse);

export const updateExperimentWeights = (payload: UpdateExperimentsPayload): Promise<FallbackJson> =>
  Api.callPut(`${BASE_PATH}/experimentvariants`, payload) as Promise<FallbackJson>;

export const fetchStepData = (stepId: string): Promise<Step> =>
  Api.callGet(`${OLD_BASE_PATH}/steps/${stepId}`).then(parseResponse);

export const createGoal = (payload: CreateTriggerPayload): Promise<string> =>
  Api.callPost(`${OLD_BASE_PATH}/goals`, payload).then(parseResponse);

export const createExitCondition = (payload: CreateTriggerPayload, workflowId: string): Promise<string> =>
  Api.callPost(`${BASE_PATH}/journeys/${workflowId}/exitconditions`, payload).then(parseResponse);

export const deleteTrigger = (journeyId: string, triggerId: string): Promise<string> =>
  Api.callDelete(`${OLD_BASE_PATH}/${journeyId}/triggers/${triggerId}`).then(parseResponse);

export const deleteStep = (journeyId: string, stepId: string): Promise<string> =>
  Api.callDelete(`${OLD_BASE_PATH}/${journeyId}/steps/${stepId}`).then(parseResponse);

export const getHistoricProcessingModes = (journeyId: string): Promise<HPReprocessingModeResponse> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/historicprocessing/reprocessingModes`).then(parseResponse);

export const fetchTrigger = (triggerId: string) =>
  Api.callGet(`${OLD_BASE_PATH}/triggers/${triggerId}`).then(parseResponse);

export const getHistoricProcessingDetails = (journeyId: string, jobId: string): Promise<HPJobDetails> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/historicprocessing/${jobId}`).then(parseResponse);

export const stopHistoricProcessingJob = (journeyId: string, jobId: string): Promise<FallbackJson> =>
  Api.callPost(
    `${BASE_PATH}/${journeyId}/historicprocessing/${jobId}/cancel`,
    {},
    {
      shouldShowToast: false,
    },
  ) as Promise<FallbackJson>;

export const triggerHistoricProcessing = (journeyId: string, payload: TriggerHpPayloadType): Promise<FallbackJson> =>
  Api.callPost(`${BASE_PATH}/${journeyId}/historicprocessing`, payload, {
    shouldShowToast: false,
  }) as Promise<FallbackJson>;

export const updateBatchExperiments = (payload: any): Promise<FallbackJson> =>
  Api.callPut(`${BASE_PATH}/experimentvariants`, payload) as Promise<FallbackJson>;

export const validateJourney = (journeyId: string): Promise<any[]> =>
  Api.callGet(`${OLD_BASE_PATH}/${journeyId}/validate`).then(parseResponse);

export const publishJourney = (journeyId: string): Promise<FallbackJson> =>
  Api.callPost(`${OLD_BASE_PATH}/publish`, { journeyId }) as Promise<FallbackJson>;

export const getPreviousTrigger = (stepId: string) =>
  Api.callGet(`${OLD_BASE_PATH}/${stepId}/previousTrigger`).then(parseResponse);

export const fetchGoalDailyStats = (
  journeyId: string,
  goalId: string,
  startDate: number,
  endDate: number,
  origin?: string,
): Promise<TriggerDailyStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(getGoalDemoGraph(origin));
  return Api.callGet(
    `${BASE_PATH}/${journeyId}/goal/${goalId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}${
      origin ? `&origin=${origin}` : ''
    }`,
  )
    .then(parseResponse)
    .then(makeFake((response: TriggerDailyStats) => getFakeTriggerData(goalId, startDate, endDate, response)));
};

export const fetchExitConditionDailyStats = (
  journeyId: string,
  exitConditionId: string,
  startDate: number,
  endDate: number,
  origin?: string,
): Promise<TriggerDailyStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(getExitDemoGraph(origin));
  return Api.callGet(
    `${BASE_PATH}/${journeyId}/exitconditions/${exitConditionId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}${
      origin ? `&origin=${origin}` : ''
    }`,
  )
    .then(parseResponse)
    .then(makeFake((response: TriggerDailyStats) => getFakeTriggerData(exitConditionId, startDate, endDate, response)));
};

export const fetchGoalStats = (journeyId: string, goalId: string): Promise<GoalStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoGoalStats);
  return Api.callGet(`${BASE_PATH}/${journeyId}/goals/${goalId}/stats`)
    .then(parseResponse)
    .then(makeFake((response: GoalStats) => getFakeGoalStats(goalId, response)));
};

export const fetchExitConditionStats = (journeyId: string, exitConditionId: string): Promise<GoalStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoGoalStats);
  return Api.callGet(`${BASE_PATH}/${journeyId}/exitconditions/${exitConditionId}/analysis`)
    .then(parseResponse)
    .then(makeFake((response: GoalStats) => getFakeGoalStats(exitConditionId, response)));
};

export const fetchStepDailyStats = (
  journeyId: string,
  stepId: string,
  startDate: number,
  endDate: number,
  origin: StepDailyStatsOrigin = 'DEFAULT',
  errorMessage: string,
): Promise<StepDailyStats[]> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourneyStats);
  return Api.callGet(
    `${OLD_BASE_PATH}/${journeyId}/dailyStats/${stepId}?rangeFrom=${startDate}&rangeTo=${endDate}&origin=${origin}`,
    {
      toastText: errorMessage,
    },
  )
    .then(parseResponse)
    .then(makeFake(() => getFakeStepStats(journeyId, stepId, origin)));
};

export const fetchStepTotalStats = (journeyId: string, stepId: string): Promise<TotalStepStats> =>
  Api.callGet(`${OLD_BASE_PATH}/${journeyId}/totalStats/${stepId}`)
    .then(parseResponse)
    .then(makeFake(() => getFakeTotalStatsForStep(journeyId, stepId)));

export const fetchAllowedTransitions = (
  journeyId: string,
  triggerId: string,
): Promise<{ stepId: string; name: string }[]> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/triggers/${triggerId}/nextSteps`).then(parseResponse);

export const addTransition = (journeyId: string, triggerId: string, stepId: string) =>
  Api.callPost(`${BASE_PATH}/journeys/${journeyId}/triggers/${triggerId}/link`, { stepId });

export const deleteTransition = (journeyId: string, triggerId: string): Promise<string> =>
  Api.callDelete(`${BASE_PATH}/journeys/${journeyId}/triggers/${triggerId}/link`).then(parseResponse);

export const getGeneralStats = (workFlowId: string): Promise<TGeneral> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/stats/general`).then(parseResponse);

export const getWorkflowSteps = (workFlowId: string): Promise<TStepData[]> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/stats/steps`).then(parseResponse);

export const fetchStepStats = (
  journeyId: string,
  stepId: string,
  startDate: number,
  endDate: number,
  origin?: StepDailyStatsOrigin | boolean,
) =>
  Api.callGet(
    `${OLD_BASE_PATH}/${journeyId}/dailyStats/${stepId}?rangeFrom=${startDate}&rangeTo=${endDate}${
      origin ? `&origin=${origin}` : ''
    }`,
  ).then(parseResponse);

export const getConfiguration = (workFlowId: string): Promise<TConfiguration> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/configuration`).then(parseResponse);

export const deleteLabel = (labelId: string): Promise<string> =>
  Api.callDelete(`${API_BASE}/labels/${labelId}`).then(parseResponse);

export const fetchActivityStats = (workflowId: string, startDate: number, endDate: number): Promise<TActivityStats> =>
  Api.callGet(`${BASE_PATH}/${workflowId}/stats/activity?rangeFrom=${startDate}&rangeTo=${endDate}`)
    .then(parseResponse)
    .then(parseActivityStats);

export const getExitConditionStats = (workFlowId: string): Promise<TTriggerData[]> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/stats/exitconditions`).then(parseResponse);

export const getExperimentsStats = (workFlowId: string): Promise<ReturnType<typeof mapExperimentsStats>> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/experimentvariants/stats`).then(parseResponse).then(mapExperimentsStats);

export const getExitConditionAnalysis = (workFlowId: string, nodeId: string): Promise<TTriggerAnalysisData> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/exitconditions/${nodeId}/analysis`).then(parseResponse);

export const getGoalStats = (workFlowId: string): Promise<TTriggerData[]> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/stats/goals`).then(parseResponse);

export const getGoalAnalysis = (workFlowId: string, nodeId: string): Promise<any> =>
  Api.callGet(`${BASE_PATH}/${workFlowId}/goals/${nodeId}/stats`).then(parseResponse);

// This is repeated but does not handle common errors like 404.
export const fetchStepDataBypassError = (stepId: string): Promise<Step> =>
  Api.callGet(`${OLD_BASE_PATH}/steps/${stepId}`, { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );
