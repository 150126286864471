import PropTypes from 'prop-types';
import React from 'react';
import { Cell } from './index';
import { tableContextWrapper } from './context';

const SelectionCell = ({ isSelectable, children = <span /> }) =>
  !isSelectable ? null : <Cell className="Table-cell--selection">{children}</Cell>;

SelectionCell.propTypes = {
  isSelectable: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default tableContextWrapper(SelectionCell);
