import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import Spin from '~/components/src/Spin';
import Btn from '~/components/src/Btn';
import * as Yup from 'yup';
import i18n from '~/i18n';
import { buildUrl, useAPI } from '~/common';
import { showSuccess } from '~/notificationCenter';
import { createDataFeed, fetchDataFeedById, updateDataFeed } from '../dataService';
import { DataFeed } from '../types';
import { FeedVariables } from './FeedVariables';
import { DataFeedContext } from '../DataFeedContext';

function DataFeedForm(): React.ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const dataFeedId = params.id;
  const { dataFeeds, refetchDataFeeds } = useContext(DataFeedContext);

  let initialValues: DataFeed = {
    name: '',
    feedPrefix: '',
    feedVariables: [],
    feedVariableName: '',
  };

  if (dataFeedId) {
    const { data: dataFeed, isLoading } = useAPI(() => fetchDataFeedById(dataFeedId), [dataFeedId]);

    if (isLoading) {
      return <Spin />;
    }
    if (dataFeed) initialValues = { ...dataFeed };
  }

  const goToDataFeedList = () => navigate(buildUrl('content/datafeeds/dashboard'), { replace: true });
  const goToDataFeedView = (dataFeedId: string) =>
    navigate(buildUrl(`content/datafeeds/view/${dataFeedId}`), { replace: true });

  const handleDataFeedAction = async (values: DataFeed) => {
    delete values.feedVariableName;
    if (dataFeedId) {
      await updateDataFeed(values);
      showSuccess({ header: i18n.t('dataFeeds:notifications.updateSuccessful') });
      goToDataFeedView(dataFeedId);
    } else {
      const newDataFeed = await createDataFeed(values);
      showSuccess({ header: i18n.t('dataFeeds:notifications.createSuccessful') });
      goToDataFeedView(newDataFeed.dataFeedId);
    }
    refetchDataFeeds();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(i18n.t('validation:validation.required')),
    feedPrefix: Yup.string()
      .trim()
      .test('duplicateCheck', i18n.t('dataFeeds:validations.feedPrefixExists'), value => {
        if (dataFeedId) return true;
        return !dataFeeds.find(feed => feed.feedPrefix === value);
      })
      .required(i18n.t('validation:validation.required')),
    feedVariables: Yup.array(),
  });

  return (
    <div className="mb-4 block rounded-md border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
      <p className="m-0 p-0 text-xl font-medium text-gray-600">{i18n.t('dataFeeds:form.createTitle')}</p>
      <Formik
        onSubmit={handleDataFeedAction}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ handleSubmit, errors, values, setFieldError, setFieldValue, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="flex items-center gap-2">
              <Field
                className="basis-1/3"
                name="name"
                id="name"
                as={FormikInputField}
                value={values.name}
                label={i18n.t('dataFeeds:form.name')}
                placeholder={i18n.t('dataFeeds:form.placeholder')}
                errorText={errors?.name}
              />
              <Field
                className="basis-1/3"
                name="feedPrefix"
                id="feedPrefix"
                as={FormikInputField}
                disabled={!!dataFeedId}
                value={values.feedPrefix}
                label={i18n.t('dataFeeds:form.feedPrefix')}
                placeholder={i18n.t('dataFeeds:form.placeholder')}
                errorText={errors?.feedPrefix}
              />
            </div>
            <Field
              name="feedVariables"
              as={FeedVariables}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
            />
            <div className="flex justify-end gap-2">
              <Btn onClick={() => (dataFeedId ? goToDataFeedView(dataFeedId) : goToDataFeedList())} testHook="cancel">
                {i18n.t('common:actions.cancel')}
              </Btn>
              <Btn color="blue" type="submit" testHook="createOrUpdateFeed" disabled={!isValid || isSubmitting}>
                {dataFeedId ? i18n.t('common:actions.update') : i18n.t('common:actions.create')}
              </Btn>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default DataFeedForm;
