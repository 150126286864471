import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';

const initialValues = {
  name: '',
};

const makeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(t('validation:validation.required')),
  });

export const AddTwitterAudienceForm = ({ createAudience, setFieldValue, hideModal, t }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={makeValidationSchema(t)}
    onSubmit={({ name }) => createAudience(name, setFieldValue)}
  >
    {({ handleSubmit, handleChange, errors, isValid, isSubmitting }) => (
      <>
        <Field
          name="name"
          as={FormikInputField}
          label={t('common:name')}
          placeholder={t('connectors:common.commonField.pleaseProvideValue')}
          onChange={handleChange}
          errorText={errors.name}
          autoFocus={true}
        />
        <ActionButtons
          onConfirm={handleSubmit}
          confirmText={t('common:actions.create')}
          onDecline={hideModal}
          isConfirmEnabled={isValid && !isSubmitting}
          testHook="addTwitterAudienceFormButtons"
          className="mr-0"
        />
      </>
    )}
  </Formik>
);
