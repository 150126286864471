angular.module('webUi.ui.admin.inviteusermodal', ['ui.bootstrap'])

    .controller('AdminInviteUserModalController', ['AdminService', 'ValidService', 'PermissionService','$uibModalInstance', 'dialogsModel', '$scope',
        function AdminInviteUserModalController(AdminService, ValidService, PermissionService, $uibModalInstance, dialogsModel, $scope) {

            $scope.invitedUser = {};
            $scope.webuiPermissionGroups = dialogsModel.webuiPermissionGroups;
            $scope.allApiPermissions = dialogsModel.allApiPermissions;
            $scope.apiPermissionsEnabled = false;
            $scope.chosenRoles = {};
            $scope.chosenRoles.apiPermissions = {};
            $scope.handleRelatedPermissions = PermissionService.handleRelatedPermissions;
            $scope.isValidEmail = ValidService.isValidEmail;

            /* modal actions */
            $scope.close = function () {
                $uibModalInstance.close(false);
            };

            $scope.isUniqueUsername = function (username) {
                if (!_.isUndefined(username)) {
                    var existingUsernames = _.transform(_.pluck(dialogsModel.users, 'username'), function (result, username) {
                        return result.push(username.toLowerCase());
                    });
                    return !_.contains(existingUsernames, username.toLowerCase());
                }
                return true;
            };

            $scope.toggleApiPermissions = function () {

                // If disabling api permissions checkbox, clear all API permissions for this user
                $scope.chosenRoles.apiPermissions = {};

                // If enabling api permissions, add the API_USER permission
                if ($scope.apiPermissionsEnabled) {
                    $scope.chosenRoles.apiPermissions['API_USER'] = true;
                }
            };

            $scope.handleUserInvite = function () {

                AdminService.inviteUser($scope.invitedUser.email).then(function (result) {
                    var finalRoles = [];

                    // Add the selected webui roles
                    for (var role in $scope.chosenRoles) {
                        if ($scope.chosenRoles[role] && role !== 'apiPermissions') {
                            finalRoles.push(role);
                        }
                    }

                    // Process the api permissions
                    var apiPermissions = [];

                    // Leave only enabled API permissions (with value true)
                    _.forEach(_.keys($scope.chosenRoles.apiPermissions), function (key) {
                        if ($scope.chosenRoles.apiPermissions[key]) {
                            apiPermissions.push(key);
                        }
                    });
                    // Add the API_Permissions to the final roles to be saved
                    if (!_.isEmpty(apiPermissions)) {
                        finalRoles.push(apiPermissions);
                    }

                    finalRoles = _.flatten(finalRoles);

                    var newUser = {
                        userId: result,
                        roles: finalRoles
                    };

                    $uibModalInstance.close(newUser);
                });
            };

            /**
         * Watch changes in the different collections to handle permissions
         */
            $scope.$watchCollection('chosenRoles', function(newNames, oldNames) {
                _.transform(newNames, function(result, name, key) {
                    if (!_.isEqual(name, oldNames[key])) {
                        result[key] = name;
                        PermissionService.handleRelatedPermissions(key, name, $scope.chosenRoles);
                    }
                });
            });

            $scope.$watchCollection('chosenRoles.apiPermissions', function(newNames, oldNames) {
                _.transform(newNames, function(result, name, key) {
                    if (!_.isEqual(name, oldNames[key])) {
                        result[key] = name;
                        PermissionService.handleRelatedPermissions(key, name, $scope.chosenRoles);
                    }
                });
            });
        }
    ]);
