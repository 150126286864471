angular.module('webUi.service.uuid', [])

/**
 * A service for retrieving UUIDS
 * @see <a href="https://github.com/monicao/angular-uuid4">angular-uuid4</a>
 */
    .provider('UUIDService', function() {
        var svc = {
            /**
		 *
		 * @returns UUID
		 */
            newId: function() {
                function _p8(s) {
                    var p = (Math.random().toString(16)+'000000000').substr(2,8);
                    return s ? '-' + p.substr(0,4) + '-' + p.substr(4,4) : p ;
                }
                return _p8() + _p8(true) + _p8(true) + _p8();
            },

            /**
		 *
		 * @returns {string}
		 */
            empty: function() {
                return '00000000-0000-0000-0000-000000000000';
            }
        };
        return {
            get: function(){
                return svc;
            },
            $get: [function(){
                return svc;
            }]
        };
    });
