import React from 'react';
import Highcharts from 'highcharts';
import HighChartWithZoom from '~/components/src/HighChartWithZoom';
import HighchartsMore from 'highcharts/highcharts-more';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

NoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);

type PieChartProps = {
  chartData: any;
  height?: number;
  width?: number;
};

const PieChart = ({ chartData, height, width }: PieChartProps) => {
  const { title, subtitle, data, titleTooltip } = chartData || {};

  const options: any = {
    styledMode: true,
    credits: false,
    chart: {
      type: 'pie',
      plotBorderWidth: 0,
      height: height || 350,
      width,
      style: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
    colors: ['#007bff', '#dc3545', '#ffc107', '#28a745', '#6c757d'],
    title: {
      text: `<span title="${titleTooltip}">${title}</span>`,
      useHTML: true,
      align: 'left',
      style: {
        fontSize: '18px',
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>',
    },
    subtitle: {
      text: subtitle,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br><span style="font-weight: normal;">{point.percentage:.1f}%</span>',
          style: {
            fontSize: '12px',
          },
        },
      },
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data,
      },
    ],
    lang: {
      noData: 'No data available',
    },
  };

  return <HighChartWithZoom highcharts={Highcharts} options={options} />;
};

export default PieChart;
