import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAngularService } from 'ReactAngular/react.service';
import Audiences from './Audiences';
import AudienceView from './AudienceView';
import AudienceEdit from './AudienceEdit';
import HistoricProcessingPage from './HistoricProcessing';
import './styles.scss';

const PROFILES_EDIT_PERMISSION = 'PROFILES_EDIT';
const AUDIENCES_EDIT_PERMISSION = 'AUDIENCES_EDIT';
const DATA_ACCESS_PERMISSION = 'DATA_ACCESS';

class AudiencesRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      canEditConnectors: false,
      canEditExports: false,
      canEditProfiles: false,
    };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      this.setState({
        isReady: true,
        canEditConnectors: context.hasPermission(PROFILES_EDIT_PERMISSION),
        canEditExports: context.hasPermission(DATA_ACCESS_PERMISSION),
        canEditProfiles: context.hasPermission(AUDIENCES_EDIT_PERMISSION),
      });
    });
  }

  render() {
    const { isReady, canEditProfiles } = this.state;
    return (
      isReady && (
        <div className="Profiles">
          <Routes>
            <Route path="segments/list/:page" element={<Audiences canEdit={canEditProfiles} />} />
            <Route path="segments/list/:groupId/:page" element={<Audiences canEdit={canEditProfiles} />} />
            <Route path="segments/view/:audienceId" element={<AudienceView />} />
            <Route path="segments/add" element={<AudienceEdit isNew={true} />} />
            <Route path="segments/:audienceId/edit" element={<AudienceEdit isNew={false} />} />
            <Route path="segments/hp/" element={<HistoricProcessingPage />} />
          </Routes>
        </div>
      )
    );
  }
}

export default AudiencesRoot;
