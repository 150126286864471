angular.module('webUi.ui.content.campaigns.ads.creatives.delete', ['ngResource', 'webUi.service', 'ui.bootstrap'])

    .controller('AdservingCreativeDeleteController', ['$scope', 'PubSubService', '$uibModalInstance', 'dialogsModel', 'ToasterService',
        function AdservingCreativeDeleteController($scope, PubSubService, $uibModalInstance, dialogsModel, toast) {
            $scope.creative = dialogsModel.creative;
            $scope.creative.name = $scope.creative.width + ' x ' + $scope.creative.height;

            $scope.deleteCreative = function() {
                toast.success('Creative deleted successfully');
                dialogsModel.onDelete($scope.creative);
                $uibModalInstance.close(true);
            };

            $scope.close = function() {
                $uibModalInstance.close(false);
            };
        }]);
