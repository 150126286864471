import React, { ReactElement, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { changeUrl } from '~/common';
import { UITable } from '~/components/src/UITable';
import { BeaconObject, MergedBeaconObject } from './types';
import DeleteBeacon from './DeleteBeacon';

const columnHelper = createColumnHelper<MergedBeaconObject>();

const redirectToStatsPage = (beaconId: string) => {
  changeUrl(`tagmanagement/beacons/${beaconId}`);
};

const DeleteAction = ({ refetchBeacons, beacon }: { beacon: BeaconObject; refetchBeacons: () => void }) => {
  const [isDeletePickerVisible, setIsDeletePickerVisible] = useState(false);
  return (
    <>
      <BtnIcon
        icon="delete"
        onClick={() => setIsDeletePickerVisible(true)}
        testHook="deleteBeacon"
        tooltip={i18n.t('beacons:actions.delete')}
      />
      {isDeletePickerVisible && (
        <DeleteBeacon beacon={beacon} setIsPickerVisible={setIsDeletePickerVisible} callback={refetchBeacons} />
      )}
    </>
  );
};

const getColumns = (hasTagEdit: boolean, refetchBeacons: () => void) => [
  columnHelper.group({
    id: 'name',
    header: '',
    columns: [
      columnHelper.accessor('beaconName', {
        header: () => <span>{i18n.t('beacons:table.name')}</span>,
        cell: info => (
          <span
            className="cursor-pointer text-blue-600"
            onClick={() => redirectToStatsPage(info?.row.original.beaconId)}
          >
            {info.getValue()}
          </span>
        ),
        size: 200,
        minSize: 200,
      }),
    ],
  }),
  columnHelper.group({
    header: i18n.t('beacons:table.activity'),

    columns: [
      columnHelper.accessor('numberOfCallsToday', {
        header: () => <div className="text-center">{i18n.t('beacons:table.today')}</div>,
        cell: info => <div className="text-center">{info.getValue()}</div>,
      }),
      columnHelper.accessor('numberOfCallsSevenDays', {
        header: () => <div className="text-center">{i18n.t('beacons:table.sevenDays')}</div>,
        cell: info => <div className="text-center">{info.getValue()}</div>,
      }),
      columnHelper.accessor('numberOfCallsThirtyDays', {
        header: () => <div className="text-center">{i18n.t('beacons:table.thirtyDays')}</div>,
        cell: info => <div className="text-center">{info.getValue()}</div>,
      }),
    ],
  }),
  columnHelper.group({
    id: 'actions',
    header: '',
    columns: [
      columnHelper.accessor('beaconId', {
        header: () => <div className="text-center">{i18n.t('beacons:table.actions')}</div>,
        cell: info => (
          <div className="t-modelActions flex items-center justify-center gap-4">
            {hasTagEdit && <DeleteAction beacon={info?.row.original} refetchBeacons={refetchBeacons} />}
          </div>
        ),
        size: 100,
        minSize: 100,
      }),
    ],
  }),
];

function BeaconsTable({
  data,
  hasTagEdit,
  refetchBeacons,
}: {
  data: MergedBeaconObject[];
  hasTagEdit: boolean;
  refetchBeacons: () => void;
}): ReactElement {
  return <UITable data={data} columns={getColumns(hasTagEdit, refetchBeacons)} />;
}

export default BeaconsTable;
