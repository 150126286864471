import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, numberRange, isUnique, isPositiveNumber } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class ExactTargetForm extends Component {
  constructor(props) {
    super(props);
    this.partnerRangeValidation = numberRange(2000, 3000);
    this.isPartnerUnique = isUnique(
      this.props.connectors,
      'partnerNumber',
      this.props.t('form.notification.isPartnerUniqueError'),
    );
  }

  render() {
    const { t, values, partner, canUpdate } = this.props;

    return (
      <FormSection name={constants.partnerTypes.EXACT_TARGET}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          {/* WUI-1567 Using domain untill backend updates it to tenantSpecificEndpoint */}
          <Field
            label={t('form.formFields.tenantSpecificEndpoint')}
            name="domain"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.clientKey')}
            name="exactTargetClientId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.clientSecret')}
            name="exactTargetClientSecret"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          {canUpdate && (
            <>
              <Field
                label={t('form.formFields.segmentKey')}
                name="segmentKey"
                type="text"
                component={ReduxFormInputField}
                validate={isRequired}
                disabled={true}
              />
              <Field
                label={t('form.formFields.variablesKey')}
                name="variablesKey"
                type="text"
                component={ReduxFormInputField}
                validate={isRequired}
                disabled={true}
              />
            </>
          )}
          {!canUpdate && (
            <span>
              <Field
                label={t('form.formFields.advancedSettings')}
                name="advancedSettings"
                component={ReduxFormCheckboxField}
                disabled={canUpdate}
              />
              {values.advancedSettings && (
                <>
                  <Field
                    label={t('form.formFields.partnerNumber')}
                    name="partnerNumber"
                    type="text"
                    component={ReduxFormInputField}
                    validate={!canUpdate && [isRequired, isPositiveNumber, this.isPartnerUnique]}
                    disabled={canUpdate}
                  />
                  <Field
                    label={t('form.formFields.segmentKey')}
                    name="segmentKey"
                    type="text"
                    component={ReduxFormInputField}
                    validate={isRequired}
                    disabled={canUpdate}
                  />
                  <Field
                    label={t('form.formFields.variablesKey')}
                    name="variablesKey"
                    type="text"
                    component={ReduxFormInputField}
                    validate={isRequired}
                    disabled={canUpdate}
                  />
                </>
              )}
            </span>
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default ExactTargetForm;
