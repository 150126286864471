import React from 'react';
import { Column, useTable, UseTableRowProps } from 'react-table';
import cx from 'classnames';
import i18n from '~/i18n';
import Notification from '~/components/src/Notification';
import Link from '~/components/src/Link';
import { buildUrl } from '~/common/history';
import { LinkedAd } from './types';

const LINKED_ADS_CELL_TEST_HOOKS: Record<string, string> = {
  ordering: 't-orderCell',
  name: 't-nameCell',
  creatives: 't-creativeSizesCell',
};

const SizeBadge = ({ width, height }: { width: number; height: number }) => (
  <div className="rounded border border-gray-300 pl-1 pr-1 text-slate-600">
    {width}x{height}
  </div>
);

interface LinkedAdsTableProps {
  linkedAds: LinkedAd[];
}

const LinkedAdsTable = ({ linkedAds }: LinkedAdsTableProps): React.ReactElement => {
  const columns: Column<LinkedAd>[] = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'ordering',
        Cell: ({ row }: { row: UseTableRowProps<LinkedAd> }) => <div>{row.index + 1}</div>,
      },
      {
        Header: i18n.t('common:name'),
        accessor: 'name',
        Cell: ({ row }: { row: UseTableRowProps<LinkedAd> }) => (
          <Link href={buildUrl(`content/campaigns/ads/view/${row.original.adId}`)}>{row.original.name}</Link>
        ),
      },
      {
        Header: i18n.t('mediaLibraries:creativeSizes'),
        accessor: 'creatives',
        Cell: ({ row }: { row: UseTableRowProps<LinkedAd> }) => (
          <div className="flex gap-2">
            {row.original.creatives.map(({ width, height }) => (
              <div key={`${width}x${height}`}>
                <SizeBadge width={width} height={height} />
              </div>
            ))}
          </div>
        ),
      },
    ],
    [linkedAds],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: linkedAds,
    autoResetSortBy: false,
    autoResetFilters: false,
  });

  if (!linkedAds.length) {
    return (
      <Notification kind="information" testHook="noLinkedAds">
        {i18n.t('mediaLibraries:noAdsLinked')}{' '}
        <Link href={buildUrl('content/campaigns/dashboard')}>{i18n.t('mediaLibraries:externalSection')}</Link>.
      </Notification>
    );
  }

  return (
    <div className="rounded border border-gray-300 bg-white pl-8 pr-8 pt-2 pb-2">
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="select-none border-b border-b-[#eaeaea] pt-5 pb-5 font-medium first:pl-7 last:pr-7"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="t-linkedAdRow border-b border-b-[#eaeaea] last:border-0">
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className={cx('pt-3 pb-3 first:pl-7 last:pr-7', {
                      [LINKED_ADS_CELL_TEST_HOOKS[cell.column.id]]: LINKED_ADS_CELL_TEST_HOOKS[cell.column.id],
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LinkedAdsTable;
