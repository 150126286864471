import React from 'react';
import Modal from 'react-modal';
import cx from 'classnames';

import './styles.scss';

const selectReactRoot = () => document.getElementById('reactRoot');

const ModalWrapper = ({ children, size, className }) => {
  const style = size ? { width: `${size}px` } : {};
  return (
    <div>
      <Modal
        appElement={document.getElementById('content')}
        parentSelector={selectReactRoot}
        overlayClassName="modal fade in"
        className="flexible-modal t-modal flex items-center justify-center"
        bodyOpenClassName="modal-open"
        isOpen={true}
      >
        <div className={cx('Modal-content rounded border border-gray-300 bg-white', className)} style={style}>
          {children}
        </div>
      </Modal>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default ModalWrapper;
