import { getAngularService } from 'ReactAngular/react.service';

const defaultClient = {
  value: '',
  label: 'All clients',
};

export const getClients = document => {
  const ContextService = getAngularService(document, 'ContextService');
  return ContextService.getAllClients().then(result => {
    const transformed = result.map(item => ({
      value: item.clientId,
      label: item.name,
    }));

    return [defaultClient, ...transformed];
  });
};

export const getSiteModules = document => {
  const ContextService = getAngularService(document, 'ContextService');
  return ContextService.getSiteModules().then(result =>
    result.map(item => ({
      value: { id: item.name, label: item.label },
      label: item.label,
    })),
  );
};
