/**
 * @class webUi.ExternalFactService
 * MODULE SHOULD BE REMOVED, when the database migration of the existing tags is done
 * @memberOf webUi
 */
angular.module('webUi.service.profiles.externalfacts', [
    'webUi.service.profilesConstants'
])
    .factory('ExternalFactService', ['InteractionService', 'INTERACTION_CONSTANTS',
        function(InteractionService, INTERACTION_CONSTANTS){

            var getExternalFacts = _.bind(InteractionService.getInteractions, this, INTERACTION_CONSTANTS.externalFactType);

            return {
                getExternalFacts: getExternalFacts
            };
        }]);
