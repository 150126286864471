angular.module('webUi.filter.newlines', [])

    .filter('newlines', function() {
        return function(text) {
            if(typeof text === 'undefined') {
                return '';
            }
		
            var result = _.escape(text);
		
            return result.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\r/g, '<br/>');
        };
    });
