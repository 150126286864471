/* eslint-disable class-methods-use-this */
import i18n from '~/i18n';
import Api, { API_BASE } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/profiles/segments`;

export const getAudiencesPaginated = (page = 0, parentId, query) => {
  const searchQuery = query ? `&search=${query}` : '';
  const parentQuery = parentId ? `&parentId=${parentId}` : '';
  return Api.callGet(`${BASE_PATH}/list?page=${page}${parentQuery}&size=20${searchQuery}&sort=name`).then(
    response =>
      response.data && {
        nodes: response.data.nodes.reduce(
          (acc, node) => ({
            ...acc,
            [node.id]: {
              ...node,
              hasChildren: !!(node.childrenIds && node.childrenIds.length),
              parentId,
            },
          }),
          {},
        ),
        totalNodes: response.data.totalNodes,
      },
  );
};

export const getTree = nodes => {
  const result = [];

  function getChilds(nodes, parentId = null) {
    nodes.forEach(node => {
      result.push({
        id: node.id,
        name: node.name,
        parentId,
      });
      if (node.children) {
        getChilds(node.children, node.id);
      }
    });
  }

  getChilds(nodes);

  return result.reduce(
    (acc, val) => ({
      ...acc,
      [val.id]: {
        ...val,
      },
    }),
    {},
  );
};

export const getAudienceTree = () =>
  Api.callGet(`${BASE_PATH}/tree`)
    .then(response => response.data)
    .then((nodes = []) => getTree(nodes));

/**
 * Needs to be implemented on the backend
 * @param id
 */
export const getAudienceOrGroup = () =>
  // return Api.callGet(`${BASE_PATH}/${id}`).then(response => response.data);
  getAudiencesPaginated(0).then(response => response.nodes);

/**
 * Checks if given Audience can be removed (if it's used)
 * @param {UUID} id
 */
export const canRemoveAudience = id => Api.callGet(`${BASE_PATH}/canDelete/${id}`).then(response => response.data);

/**
 * Removes single Audience
 * @param {UUID} id
 */
export const removeAudience = id => Api.callDelete(`${BASE_PATH}/${id}`).then(response => response.data);

/**
 * Adds a new group
 * @param {String} name
 */
export const addGroup = name =>
  Api.callPost(`${BASE_PATH}/groups`, { name }, { toastText: i18n.t('common:groups.groupNotCreated') }).then(
    response => response.data,
  );

/**
 * Removes a single Group
 * @param {UUID} id
 */
export const removeGroup = id =>
  Api.callDelete(`${BASE_PATH}/groups/${id}`, { id, shouldShowToast: false }).then(response => response.data);

/**
 * Moves single element to or from the group
 * @param {*} groupId
 * @param {*} elementId
 */
export const moveItem = (groupId, elementId) =>
  Api.callPost(`${BASE_PATH}/groups/link`, { elementId, groupId }).then(() => elementId); // No real response there

export const fetchAudienceDependantsInfo = audienceId =>
  Api.callGet(`/api/profiles/dependencies/${audienceId}`).then(response => response.data);

export default {
  getAudiencesPaginated,
  getAudienceTree,
  getAudienceOrGroup,
  canRemoveAudience,
  removeAudience,
  addGroup,
  removeGroup,
  moveItem,
  fetchAudienceDependantsInfo,
};
