angular.module('webUi.ui.tagmanagement.tags.conditions.experiment', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormExperimentConditionController', ['$scope', 'ExperimentService', 'Utils',
        function TagmanagementTagFormExperimentConditionController    ($scope,   ExperimentService,   Utils) {
	
            ExperimentService.getExperimentConditionPojos().then(function(experimentConditionPojos) {
                experimentConditionPojos = Utils.sanitizeRestangularAll(experimentConditionPojos);
                $scope.experimentConditionPojos = experimentConditionPojos;
                $scope.experimentGroupsByExperimentId = Utils.objectMap($scope.experimentConditionPojos, 'experimentId');

                //reset the experimentGroupId when the experiment changes
                $scope.$watch('state.formCondition.experimentId', function(newValue, oldValue) {
                    if(newValue !== oldValue) {
                        $scope.state.formCondition.experimentGroupId = null;
                    }
                });
            });

            var conditionClazz = 'ExperimentCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz
            };
		
            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
        }
    ]);
