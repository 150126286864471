import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../Notification';
import Btn from '../Btn';
import BtnOutlined from '../BtnOutlined';

const StepsFooter = props => {
  const onPreviousStep = () => {
    props.onChange({ step: props.selectedStep - 1 });
  };

  const renderPreviousStep = () => {
    const { t } = props;
    return (
      <>
        <Btn color="gray" className="u-marginRightM" testHook="cancelButton" onClick={props.onCancel}>
          {t('common:actions.cancel')}
        </Btn>
        {props.selectedStep !== 1 && (
          <BtnOutlined className="u-marginRightL" testHook="previousStepButton" onClick={onPreviousStep}>
            {t('common:actions.previousStep')}
          </BtnOutlined>
        )}
      </>
    );
  };

  const renderNextStep = () => {
    const { t, canSave, isNew } = props;
    if (props.selectedStep !== props.totalSteps) {
      return (
        <Btn color="blue" testHook="nextStepButton" onClick={() => props.onValidate('next')}>
          {t('common:actions.nextStep')}
        </Btn>
      );
    }

    let finalButtonText;

    if (isNew) {
      finalButtonText = canSave ? t('common:actions.create') : t('common:actions.creating');
    } else {
      finalButtonText = canSave ? t('common:actions.save') : t('common:actions.saving');
    }

    return (
      <Btn color="blue" testHook="saveButton" onClick={() => props.onValidate('save')} disabled={!canSave}>
        {finalButtonText}
      </Btn>
    );
  };

  return (
    <div className="flex justify-end">
      {props.disabledMessage && props.disabled ? (
        <Notification kind="information">
          <p>{props.disabledMessage}</p>
        </Notification>
      ) : null}
      <div className="flex gap-x-4">
        {renderPreviousStep()}
        {renderNextStep()}
      </div>
    </div>
  );
};

StepsFooter.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  canCancel: PropTypes.bool, // modal window with confirmation to be added as a separate story
  canSubmit: PropTypes.bool,
  selectedStep: PropTypes.number,
  totalSteps: PropTypes.number,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  t: PropTypes.func,
};

export default StepsFooter;
