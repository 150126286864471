import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import CardMessage from '~/components/src/CardMessage';
import { BaseFormSection } from '~/components/src/Containers';
import FormActions from '~/components/src/Form/ActionsDefault';
import Form from '~/components/src/Form/Form';
import { EXPORT_EVENTS } from '~/gaActions';
import constants from './constants';
import ExportConnectionForm from './form/ExportConnectionForm';
import ExportFrequencyForm from './form/ExportFrequencyForm';
import ExportNotifyForm from './form/ExportNotifyForm';
import ExportRangeForm from './form/ExportRangeForm';
import ExportSettingsForm from './form/ExportSettingsForm';
import ExportTypeForm from './form/ExportTypeForm';
import './newExport.scss';

const ValidationErrors = ({
  isSubmitted,
  isValid,
  isRemoteConnectionSuccessful,
  areConnectionCredentialsChecked,
  requestType,
  t,
}) => {
  if (isSubmitted) {
    if (!isValid) {
      return <CardMessage className="ExportForm-validationMessage" message={t('formIsNotValid')} />;
    }

    const requiresConnectionCheckBeforeSaving =
      requestType === constants.REQUEST_TYPES.SCHEDULED || requestType === constants.REQUEST_TYPES.MANUAL_REMOTE;

    if (requiresConnectionCheckBeforeSaving && areConnectionCredentialsChecked && !isRemoteConnectionSuccessful) {
      return <CardMessage className="ExportForm-validationMessage" message={t('failedFtpConnectionCheck')} />;
    }
  }

  return null;
};

const ExportForm = props => {
  const {
    t,
    submitting,
    handleSubmit,
    backHref,
    formValues,
    isValid,
    isRemoteConnectionSuccessful,
    areConnectionCredentialsChecked,
    isEdit,
  } = props;
  const type = formValues && formValues.type;
  const requestType = type && formValues.type.requestType;
  const exportType = type && formValues.type.exportType;

  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Form onSubmit={handleSubmit} isSubmitting={submitting}>
      <div>
        <FormSection name="type">
          <ExportTypeForm data={props} />
        </FormSection>
        {!_.isEmpty(formValues) && (
          <div>
            <FormSection name="settings">
              <ExportSettingsForm data={props} />
            </FormSection>
            {exportType && requestType === constants.REQUEST_TYPES.SCHEDULED ? (
              <div>
                <FormSection name="frequency">
                  <ExportFrequencyForm data={props} />
                </FormSection>
                <FormSection name="connection">
                  <ExportConnectionForm data={props} />
                </FormSection>
              </div>
            ) : (
              exportType &&
              requestType && (
                <FormSection name="range">
                  <ExportRangeForm data={props} />
                </FormSection>
              )
            )}
            {exportType && requestType === constants.REQUEST_TYPES.MANUAL_REMOTE && (
              <FormSection name="connection">
                <ExportConnectionForm data={props} />
              </FormSection>
            )}
          </div>
        )}
        <FormSection name="notify">
          <ExportNotifyForm data={props} />
        </FormSection>
        <BaseFormSection>
          <ValidationErrors
            isValid={isValid}
            isSubmitted={isSubmitted}
            isRemoteConnectionSuccessful={isRemoteConnectionSuccessful}
            areConnectionCredentialsChecked={areConnectionCredentialsChecked}
            requestType={requestType}
            t={t}
          />
        </BaseFormSection>
      </div>
      <FormActions
        t={t}
        submitting={submitting}
        cancelHref={backHref}
        onConfirm={() => setIsSubmitted(true)}
        submitText={isEdit ? t('common:actions.update') : t('common:actions.create')}
        gaAction={isEdit ? EXPORT_EVENTS.EDITED : EXPORT_EVENTS.CREATED}
      />
    </Form>
  );
};

ExportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  clients: PropTypes.array,
  backHref: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default ExportForm;
