import React from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { changeUrl } from '~/common';
import { truncateString } from '~/common/utils/StringUtils';
import { Link, Tooltip } from '~/components';
import Icons from '~/components/src/Icons';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { TChangeElementMapped, TPublishHistory, TReleaseHistory } from '../types';

const columnHelper = createColumnHelper<TChangeElementMapped>();

export const Differences = ({ row }: { row: Row<TPublishHistory | TReleaseHistory> }) => {
  const differences = row.original.diff || [];

  const getColumns = [
    columnHelper.accessor('elementName', {
      header: () => <span>{i18n.t('publishing:latestChanges.type')}</span>,
      cell: info => (
        <div className="flex items-center gap-2">
          <Icons className="h-5 w-5 text-r42-blue" icon={info.row.original.icon} />
          <Tooltip tooltip={info.getValue()}>
            <Link onClick={() => changeUrl(info.row.original.elementLink)} testHook="elementName">
              {truncateString(info.getValue(), 30)}
            </Link>
          </Tooltip>
        </div>
      ),
      size: 400,
    }),
    columnHelper.accessor('changeType', {
      header: () => <span>{i18n.t('publishing:latestChanges.change')}</span>,
      cell: info => <span className="t-changeType">{info.getValue()}</span>,
      size: 100,
    }),
  ];

  return (
    <div className="w-1/2 pb-4 pl-14 ">
      <UITable
        testHook="history"
        emptyBody={i18n.t('publishing:messages.noRecords')}
        data={differences}
        columns={getColumns}
        emptyClassName="!m-0 pb-0"
      />
    </div>
  );
};
