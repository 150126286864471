import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { getAudienceStats, getFetchingAudienceStats } from './selectors';
import { fetchAudienceStats } from './actions';
import AudienceActivity from '../../components/AudienceActivity';

const mapStateToProps = state => ({
  stats: getAudienceStats(state),
  fetchingAudienceStats: getFetchingAudienceStats(state),
});

const mapDispatchToProps = {
  fetchStats: fetchAudienceStats,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('audiences'))(AudienceActivity);
