angular
    .module('webUi.ui.admin.urlBuilderValueSetsEdit', [])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.admin.urlBuilderValueSetsEdit', {
                url: '/urlbuilder/valueSets/edit/{valueSetsId}',
                isReact: true,
            });
        },
    ]);
