angular.module('webUi.ui.tagmanagement.experiments.view',
    [
        'webUi.ui.tagmanagement.experiments.base',
        'ui.bootstrap'
    ])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.tagmanagement.experiments.view', {
                    url: '/view/{experimentId}?snapshotId',
                    isReact: true
                });
        }
    ]);