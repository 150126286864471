angular.module('webUi.directive.editable.checkbox', [])

    .controller('CheckboxNotEditingController', ['$scope', function CheckboxNotEditingController ($scope) {
        var defaultValues = {
            yesValue: 'Yes',
            noValue: 'No'
        };
	
        $scope.vars = $.extend({}, defaultValues, $scope.vars());
    }]);
