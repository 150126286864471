angular.module('webUi.ui.tagmanagement.tags.conditions.countryMatch', [
    'webUi.directive.flag',
    'iso-3166-country-codes'
])

    .controller('TagmanagementTagFormCountryMatchConditionController', ['$scope', 'ISO3166', 'filterFilter',
        function TagmanagementTagFormCountryMatchConditionController    ($scope,   ISO3166,   filterFilter) {

            var conditionClazz = 'CountryMatchCondition';
		
            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                include: true,
                countryCodes: []
            };
		
            $scope.options = [{value: true, label: 'include'}, {value: false, label: 'exclude'}];
		
            $scope.state.beforeSubmit = function() {
                return $scope.state.formCondition.countryCodes.length > 0;
            };
			
            /********** Country selection **********/
            $scope.availableCountries = [];
            _.each(_.keys(ISO3166.codeToCountry), function(country) {
                $scope.availableCountries.push({
                    countryCode: country,
                    hidden: false,
                    countryName: ISO3166.getCountryName(country)
                });
            });
        
            $scope.search = {
                countrySearchString: ''
            };
		
            $scope.addCountry = function addCountry (countryCode) {
                var containsCountry = function containsCountry (availableCountries, countryCode){
                    var found = false;
                    _.forEach(availableCountries, function(country){
                        if ( country.countryCode.toUpperCase() === countryCode.toUpperCase() ){
                            found = true;
                            return false;
                        }
                    });
                    return found;
                };
                var availableCountries = $scope.availableCountries;
                var chosenCountries = $scope.state.formCondition.countryCodes;
            
                if ( countryCode 
                    && !_.contains(chosenCountries, countryCode.toUpperCase()) 
                    && containsCountry(availableCountries, countryCode) ) {
                    var countryCodeUpperCase = countryCode.toUpperCase();
                    chosenCountries.push(countryCodeUpperCase);
                    //hide the country that has already been added
                    _.forEach(availableCountries, function (country) {
                        if (country.countryCode.toUpperCase() === countryCodeUpperCase) {
                            country.hidden = true;
                            return false;
                        }
                    });
                }
            
                $scope.search.countrySearchString = '';
            };
		
            $scope.removeCountry = function(index) {
                //get the country code that we're supposed to eliminate from the condition
                var countryCode = $scope.state.formCondition.countryCodes[index];
                if(index > -1) {
                    //splice from the condition
                    $scope.state.formCondition.countryCodes.splice(index, 1);
                    //make it show again in the list of available countries
                    _.forEach($scope.availableCountries, function(country) {
                        if(country.countryCode === countryCode) {
                            country.hidden = false;
                            return false;
                        }
                    });
                }
            };
		
            $scope.searchEnterPressed = function searchEnterPressed(countrySearchString, availableCountries) {
                var searchResults = filterFilter(
                    _.filter(availableCountries, {hidden: false}),
                    countrySearchString
                );
                var firstResult = searchResults[0];
           
                if(!_.isUndefined(firstResult)) {
                    $scope.addCountry(firstResult.countryCode);
                }
            };
        }
    ]);

