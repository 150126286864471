(function() {
    'use strict';

    angular
        .module('webUi.service.tagUsage', [
            'restangular'
        ])
        .service('TagUsageService', TagUsageService);

    TagUsageService.$inject = ['Restangular'];

    function TagUsageService(Restangular) {

        /**
         * Get tag errors from last x days
         * @param days
         */
        var getErrors = function (days) {
            return Restangular.one('tagUsage/errors').get({
                days: days
            }).then(function(result){
                var list = result && result.tagUsage;

                // Return an empty array if no results
                if (!result || !result.tagUsage) {
                    return [];
                }

                // Decorate list items with errorsPercentage
                list = list.map(function (item) {
                    item.errorsPercentage = (item.errors / item.calls > 1) ? 100 : item.errors / item.calls * 100;
                    return item;
                });

                // Sort the list based on errorsPercentage
                list = _.sortByOrder(list, ['errorsPercentage', 'tagName'], ['desc', 'asc']);

                // Return just an array instead of whole result
                return list;
            });
        };

        /**
         * Service methods
         */
        return {
            getErrors: getErrors
        };

    }

})();
