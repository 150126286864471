/**
 * URL builder Duck
 */
import { combineReducers } from 'redux';
import columns from './columns';
import types from './types';
import valueSets from './valueSets';
import configurations from './exportConfigurations';

export * from './columns';
export * from './types';
export * from './valueSets';
export * from './exportConfigurations';

export default combineReducers({
  columns,
  types,
  valueSets,
  configurations,
});
