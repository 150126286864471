angular.module('webUi.ui.support.tagtemplates.templates.form', [
    'webUi.ui.support.tagtemplates.templates.base'
])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.tagtemplates.templates.add', {
                url: '/add?groupId',
                isReact: true,
                leftMenu: true
            })
            .state('site.support.tagtemplates.templates.edit', {
                url: '/edit/{templateId}',
                isReact: true,
                leftMenu: true
            });
    }]);
