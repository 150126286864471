export const USER_SETTINGS_FETCH_START = 'app/user/settings/USER_SETTINGS_FETCH_START';
export const USER_SETTINGS_FETCH_FAIL = 'app/user/settings/USER_SETTINGS_FETCH_FAIL';
export const USER_SETTINGS_FETCH_SUCCESS = 'app/user/settings/USER_SETTINGS_FETCH_SUCCESS';

export const USER_SETTINGS_UPDATE_START = 'app/user/settings/USER_SETTINGS_UPDATE_START';
export const USER_SETTINGS_UPDATE_FAIL = 'app/user/settings/USER_SETTINGS_UPDATE_FAIL';
export const USER_SETTINGS_UPDATE_SUCCESS = 'app/user/settings/USER_SETTINGS_UPDATE_SUCCESS';

export const SET_ACCESSIBLE_SITES_FILTER = 'app/user/settings/SET_ACCESSIBLE_SITES_FILTER';
export const REMOVE_ACCESSIBLE_SITES_FILTER = 'app/user/settings/REMOVE_ACCESSIBLE_SITES_FILTER';

export const PHONE_UPDATE_START = 'app/user/settings/PHONE_UPDATE_START';
export const PHONE_UPDATE_FAIL = 'app/user/settings/PHONE_UPDATE_FAIL';
export const PHONE_UPDATE_SUCCESS = 'app/user/settings/PHONE_UPDATE_SUCCESS';
export const RESET_ERROR_UPDATE_PHONE_REQUEST = 'app/user/settings/RESET_ERROR_UPDATE_PHONE_REQUEST';

export const SMS_REQUEST_START = 'app/user/settings/SMS_REQUEST_START';
export const SMS_REQUEST_FAIL = 'app/user/settings/SMS_REQUEST_FAIL';
export const SMS_REQUEST_SUCCESS = 'app/user/settings/SMS_REQUEST_SUCCESS';
export const RESET_ERROR_SMS_REQUEST = 'app/user/settings/RESET_ERROR_SMS_REQUEST';

export const TIMEBASED_REQUEST_START = 'app/user/settings/TIMEBASED_REQUEST_START';
export const TIMEBASED_REQUEST_FAIL = 'app/user/settings/TIMEBASED_REQUEST_FAIL';
export const TIMEBASED_REQUEST_SUCCESS = 'app/user/settings/TIMEBASED_REQUEST_SUCCESS';
export const RESET_ERROR_TIMEBASED_REQUEST = 'app/user/settings/RESET_ERROR_TIMEBASED_REQUEST';

export const TIMEBASED_UPDATE_START = 'app/user/settings/TIMEBASED_UPDATE_START';
export const TIMEBASED_UPDATE_FAIL = 'app/user/settings/TIMEBASED_UPDATE_FAIL';
export const TIMEBASED_UPDATE_SUCCESS = 'app/user/settings/TIMEBASED_UPDATE_SUCCESS';

export const TWO_FACTOR_AUTH_DELETE_START = 'app/user/settings/TWO_FACTOR_AUTH_DELETE_START';
export const TWO_FACTOR_AUTH_DELETE_FAIL = 'app/user/settings/TWO_FACTOR_AUTH_DELETE_FAIL';
export const TWO_FACTOR_AUTH_DELETE_SUCCESS = 'app/user/settings/TWO_FACTOR_AUTH_DELETE_SUCCESS';

export const UPDATE_PASSWORD_START = 'app/user/settings/UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_FAIL = 'app/user/settings/UPDATE_PASSWORD_FAIL';
export const UPDATE_PASSWORD_SUCCESS = 'app/user/settings/UPDATE_PASSWORD_SUCCESS';
export const RESET_ERROR_PASSWORD_REQUEST = 'app/user/settings/RESET_ERROR_PASSWORD_REQUEST';

export const RESET_SMS_TOKEN_STEP = 'app/user/settings/RESET_SMS_TOKEN_STEP';
export const ADD_SMS_TOKEN_FORM = 'app/user/settings/ADD_SMS_TOKEN_FORM';

export const RESET_TIMEBASED_TOKEN_STEP = 'app/user/settings/RESET_TIMEBASED_TOKEN_STEP';
export const ADD_TIMEBASED_TOKEN_FORM = 'app/user/settings/ADD_TIMEBASED_TOKEN_FORM';
export const UPDATE_PASSWORD_FORM = 'app/user/settings/UPDATE_PASSWORD_FORM';

export const MAKE_DEFAULT_SUCCESS = 'app/user/settings/MAKE_DEFAULT_SUCCESS';
export const MAKE_DEFAULT_FAIL = 'app/user/settings/MAKE_DEFAULT_FAIL';
export const UNLINK_OPEN_ID = 'app/user/settings/UNLINK_OPEN_ID';

export const GO_TO_SMS_CODE_CONFIRMATION_STEP = 'app/user/settings/GO_TO_SMS_CODE_CONFIRMATION_STEP';
