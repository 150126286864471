angular.module('webUi.directive.wrap', [])

/** A directive that ads a label and/or description and info popover to an input */
    .directive('wrap', [function () {

        var generateTemplate = function (attrs, innerHtml) {
            var label = attrs['label'] || '';
            var description = attrs['description'] || '';
            var helpText = attrs['helpText'] || '';
            var helpTextPlacement = attrs['helpTextPlacement'] || 'right';
            var childClass = attrs['childClass'] || '';

            var labelElem = '<label class="control-label">' + label;
            if (!_.isEmpty(helpText)) {
                labelElem +=
                '<span data-uib-popover="' + helpText + '"' +
                'data-popover-trigger="mouseenter" data-popover-placement="' + helpTextPlacement + '">' +
                '<i class="r42-help-circled"></i>' +
                '</span>';
            }
            if (!_.isEmpty(description)) {
                labelElem += '<small>' + description + '</small>';
            }
            labelElem += '</label>';

            return '<div class="control-group">' +
                    labelElem +
                    `<div class="controls input-container ${childClass}">` + innerHtml + '</div>' +
                '</div>';
        };

        return {
            restrict: 'A',
            template: function (elem, attrs) {
                return generateTemplate(attrs, elem.html());
            },
            replace: true,
            link: function () {
            }
        };
    }]);
