import moment from 'moment';

const DATE_TIME_FORMAT = 'DD MMM YYYY H:mm A';
const DATE_FORMAT = 'DD MMM YYYY';
const TIME_FORMAT = 'H:mm A';

const getDate = (x, dateFormat = DATE_FORMAT) => (x ? moment(x).format(dateFormat) : '-');
const getTime = x => (x ? moment(x).format(TIME_FORMAT) : '-');

const getDateAndTime = x => (x ? moment(x).format(DATE_TIME_FORMAT) : '-');

export { getDate, getDateAndTime, getTime };
