export const TOOLTIP_WIDTH = 200;

export const CARD_WIDTH = 320;
export const CARD_HEIGHT = 140;
export const HALF_CARD_HEIGHT = CARD_HEIGHT / 2;
export const HALF_CARD_WIDTH = CARD_WIDTH / 2;
export const ADD_BUTTON_LINE_LENGTH = 90;
export const ADD_BUTTON_LENGTH = 80;

export const NODE_PADDING_RIGHT = 220;
export const NODE_PADDING_BOTTOM = 160;

export const ZOOM_MIN_LIMIT = 0.3;
export const ZOOM_MAX_LIMIT = 2;
export const ZOOM_BUTTON_FACTOR = 0.5;

export const DATE_FORMAT = 'DD/MM/YYYY';
export const MAX_CHARACTERS_NAME_OF_JOURNEY_TRIGGER_OR_STEP = 255;

export const ErrorCodes = {
  DATA_ERROR: 'DATA_ERROR',
  STRUCTURE_ERROR: 'STRUCTURE_ERROR',
  JOURNEY_HAS_NO_START_TRIGGER: 'JOURNEY_HAS_NO_START_TRIGGER',
  JOURNEY_HAS_NO_GOALS: 'JOURNEY_HAS_NO_GOALS',
};
