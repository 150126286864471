import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { withRouter } from '~/common/withRouter';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import {
  editConfiguration,
  testConnection,
  fetchConfigurations,
  cleanTestConnectionResult,
} from '../urlBuilder/exportConfigurations/actions';
import * as selectors from '../urlBuilder/exportConfigurations/selectors';
import ConfigurationForm from '../components/ConfigurationForm';

export class EditConfigurationPage extends Component {
  componentDidMount() {
    this.props.fetchConfigurations();
  }

  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('exportConfigurations.formEditConfiguration.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('exportConfigurations.formEditConfiguration.intro')}</p>
            <ConfigurationForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      isConnectionTestSuccessful: selectors.isConnectionTestSuccessful(state),
      formState: state.form.configurationEdit,
      initialValues: selectors.getConfigurationById(state, ownProps.params.configurationId),
    }),
    dispatch => ({
      fetchConfigurations: () => dispatch(fetchConfigurations()),
      onSubmit: configuration => dispatch(editConfiguration(configuration)),
      testConnection: configuration => dispatch(testConnection(configuration)),
      cleanTestConnectionResult: () => dispatch(cleanTestConnectionResult()),
    }),
  ),
  reduxForm({
    form: 'configurationEdit',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['urlBuilder']),
)(EditConfigurationPage);
