/* eslint-disable newline-per-chained-call */
import Api, { API_BASE } from '~/common/ApiService';
import moment from 'moment';
import { getSiteId } from '~/common/SecurityMetaService';
import { setExportError } from './index';

const BASE_PATH = `${API_BASE}/remote/export-requests`;

const getStartDate = days => moment().subtract(parseInt(days, 10), 'days').startOf('day').toDate().getTime();
const getTodayStartDate = () => moment().startOf('day').toDate().getTime();
const getTodayEndDate = () => moment().endOf('day').toDate().getTime();

const getDates = days => {
  if (days === '0') {
    return { from: getTodayStartDate(), to: getTodayEndDate() };
  }
  return { from: getStartDate(days), to: getTodayEndDate() };
};

const getScheduledExports = () => Api.callGet(`${BASE_PATH}/scheduled`);

const getManualExports = days => {
  const { from, to } = getDates(days);
  return Api.callGet(`${BASE_PATH}?dateFrom=${from}&dateTo=${to}`);
};

const deleteExport = id => Api.callDelete(`${BASE_PATH}/scheduled/${id}`).then(() => {});

const downloadExport = (id, dispatch) => {
  const URL = `/assets/${getSiteId()}/exports/download/${id}`;

  const xhr = new XMLHttpRequest();

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      dispatch(setExportError(''));
      window.open(URL, '_blank');
    } else {
      dispatch(setExportError(xhr.responseText));
    }
  };

  xhr.open('head', URL);
  xhr.send(null);
};

export { getScheduledExports, getManualExports, deleteExport, downloadExport };
