angular.module('webUi.ui.tagmanagement.tags.form.step3', [
    'webUi.ui.tagmanagement.tags.conditions'
])
    .controller('TagmanagementTagFormStep3Controller', [
        '$scope',
        '$state',
        'TagmanagementService',
        'ValidService',
        /**
         * @function TagmanagementTagFormStep3Controller
         * @memberOf webUi.ui.tagmanagement.tags.form
         *
         * @param $scope
         * @param TagmanagementService
         * @param $state
         *
         * @returns {TagmanagementTagFormStep3Controller}
         */
        function TagmanagementTagFormStep3Controller($scope, $state, TagmanagementService, ValidService) {
            'use strict';

            var vm = this;
            vm.data = {};

            $scope.isTagNameLengthValid = ValidService.isValidLength;

            // reset formCondition to deal with the redirect after adding a condition
            $scope.state.formCondition = null;

            if (!_.isEmpty($scope.state.selectedVariant.generatedName)) {
                try {
                    /*jslint evil: true */
                    var nameGenerator = eval('(' + $scope.state.selectedVariant.generatedName + ');');
                    $scope.tag.name = nameGenerator($scope.tag, $scope.state.templateHelper);
                } catch (exception) {
                    // ignore
                }
            }

            //@todo marius/elizabeth: move this to backend
            TagmanagementService.getTagConditionsGroupedBy(TagmanagementService.TAG_CONDITION_GROUPING_OPTIONS.CONFIGURATION_GROUP_NAME).then(
                function onSuccess(conditionGroups) {
                    vm.data.conditionGroups = $scope.$parent.conditionGroups = _.where(conditionGroups, {name: 'MOST_POPULAR'});
                },
                function onError() {
                }
            );

            $scope.state.submitCallback = function () {

                TagmanagementService.save($scope.tag).then(
                    function onSuccess(tagId) {
                        $state.transitionToStateWithSite('site.tagmanagement.tags.view', {
                            id: tagId
                        });
                    },
                    function onError() {
                    }
                );
            };
        }
    ]);

