import React from 'react';
import Icon from 'react-icon-base';

const R42AnimatedDashboard = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M.793.786a1 1 0 0 0-.293.707v11.015a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1l-8-.015a1 1 0 0 0-.707.293z" />
    <path d="M22.5.493l-8 .014a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V1.493a1 1 0 0 0-1-1z" />
    <path d="M10.207 23.214a1 1 0 0 0 .293-.707v-5a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v4.985a1 1 0 0 0 1 1l8 .014a1 1 0 0 0 .707-.292z" />
    <path d="M22.5 10.508h-8a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1l8-.014a1 1 0 0 0 1-1V11.508a1 1 0 0 0-1-1z" />
    <animate
      attributeType="XML"
      attributeName="fill"
      values="#EAEAEA;#DADADA;#CCCCCC;#EAEAEA"
      dur="3s"
      repeatCount="indefinite"
    />
  </Icon>
);

export default R42AnimatedDashboard;
