import React, { useContext } from 'react';
import * as d3 from 'd3';
import ActionButtons from '~/components/src/ActionButtons';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import { buildUrl } from '~/common';
import { JourneyContents } from '~/workflows/types';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { MAX_CHARACTERS_NAME_OF_JOURNEY_TRIGGER_OR_STEP } from '../Journey/constants';

type ValidationModalProps = {
  journeyName: string;
  validations: any;
  variables: any;
  journeyTree: any;
  workflowType: string;
  workflowId?: string;
  hideModal: () => void;
  setShowJourneyErrors: (val: boolean) => void;
};

const getVariableNameById = (variableId: string, variables: any[]) =>
  variables.find(variable => variable.variableId === variableId).name;

const getStepNameById = (stepId: string, journeyTree: JourneyContents) => {
  const hierarchy = d3.hierarchy(journeyTree?.nodes || []);
  return hierarchy.descendants().find(node => node.data.nodeId === stepId)?.data.label;
};

const ValidationModal = ({
  journeyName,
  validations,
  variables,
  journeyTree,
  workflowType = WORKFLOW_TYPE.Journey,
  workflowId = '',
  hideModal,
  setShowJourneyErrors,
}: ValidationModalProps) => {
  const normalizedWorkflowType: string = workflowType.toLowerCase();
  const { navigate } = useContext(WorklfowContext);
  const parseValidation = (validation: any) => {
    const { nodeName, nodeType, errorCode, stepId, variableId } = validation;

    const constructData = (
      reason: string,
      message: string,
      { isCustomReason = false, isCustomMessage = false } = {},
    ) => ({
      name: errorCode,
      reason: isCustomReason
        ? reason
        : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.${reason}`),
      message: isCustomMessage
        ? message
        : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.${message}`),
    });

    const type =
      nodeType === i18n.t('workflow:orchestration.goals.goal')
        ? i18n.t('workflow:orchestration.goals.goal')
        : i18n.t('workflow:orchestration.triggers.trigger');

    switch (errorCode) {
      case 'JOURNEY_HAS_NO_START_TRIGGER':
        return constructData('mustHaveOneStartTrigger', 'hasNoStartTrigger');
      case 'JOURNEY_ALREADY_HAS_START_TRIGGER':
        return constructData('mustHaveOneStartTrigger', 'hasaStartTrigger');
      case 'JOURNEY_HAS_MORE_THAN_ONE_START_TRIGGERS':
        return constructData('mustHaveOneStartTrigger', 'hasaMoreStartTrigger');
      case 'JOURNEY_HAS_NO_GOALS':
        return constructData('mustHaveOneGoal', 'noGoal');
      case 'STEP_PARENT_DOES_NOT_EXIST':
        return constructData('stepMustBeCreatedFromExistingTrigger', 'canNotAddNodeToTrigger');
      case 'INVALID_EXPERIMENT_TOTAL_WEIGHT':
        return constructData('experimentWeigtValidation', 'defaultMessage');
      case 'PARENT_CHILD_OF_SAME_TYPE':
        return constructData(
          'parentChildMustBeDifferent',
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.parentType`, { nodeName }),
          { isCustomMessage: true },
        );
      case 'INVALID_NAME':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nameConstaint`, {
            size: MAX_CHARACTERS_NAME_OF_JOURNEY_TRIGGER_OR_STEP,
          }),
          'journeyNodeInvalid',
          { isCustomReason: true },
        );
      case 'TRIGGER_DOES_NOT_HAVE_STEP':
        return constructData(
          'triggerMustHaveExactlyOneStep',
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nodeHasNoStep`, { nodeName }),
          { isCustomMessage: true },
        );
      case 'TRIGGER_MUST_HAVE_MIN_ONE_RULE':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.mustHaveRule`, { type }),
          nodeName
            ? i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nodeHasNoRule`, { nodeName })
            : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.triggerGoalHasNoRules`),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'TRIGGER_ALL_RULES_NEGATED':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.mustHaveOneRuleNotNegated`, {
            type,
          }),
          nodeName
            ? i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nodeHasAllRulesNegated`, {
                nodeName,
              })
            : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.triggerGoalHasRulesNegated`),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'INVALID_STRING_FILTER':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.ruleWithInvalidString`, { type }),
          nodeName
            ? i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nodeHasInvalidString`, {
                nodeName,
              })
            : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.triggerGoalHasInvalidString`),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'INVALID_TIME_DURATION_FORMAT':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.ruleMustHaveValidTime`, {
            type: nodeType,
            nodeName,
          }),
          nodeName
            ? i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.nodeHasInvalidTime`, {
                nodeName,
              })
            : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.triggerGoalHasInvalidTime`),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'VARIABLE_NOT_PRESENT_IN_PREVIOUS_JOURNEY_TRIGGERS': {
        const variableName = getVariableNameById(variableId, variables);
        const stepName = getStepNameById(stepId, journeyTree);

        return constructData(
          'pleaseCheckPrecedingTriggers',
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.stepUsesRemovedVariable`, {
            stepName,
            variableName,
          }),
          {
            isCustomMessage: true,
          },
        );
      }
      case 'FLEXIBLE_TRANSITION_CANNOT_LINK_TO_DIFFERENT_EXPERIMENT':
        return constructData(
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.differentExperiment`),
          i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.cannotLinkToDifferentExperiment`, {
            nodeName,
          }),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'FLEXIBLE_TRANSITION_POSSIBLE_LOOP_CONDITION':
        return constructData('cannotBeLoop', 'possibleLoopCondition');
      default:
        return constructData('defaultReason', 'defaultMessage');
    }
  };

  return (
    <div className="">
      <p className="t-journeyName mb-4 rounded bg-blue-50 p-2 text-xl text-blue-500">
        <Icons icon="journey" className="mr-2 h-6 w-6" /> {journeyName}
      </p>
      <div className="JourneyModal-validationContent flex flex-col gap-4">
        {validations &&
          validations.map((validation: any) => {
            const data = parseValidation(validation);
            return (
              <div key={data.name} className="flex gap-2">
                <Icons icon="error" className="h-6 w-6 text-red-500" />
                <div className="flex flex-col">
                  <p className={`t-${data.name}-message JourneyModal-message`}>{data?.message}</p>
                  <p className={`JourneyModal-reason t-${data.name}-reason text-sm text-gray-400`}>{data?.reason}</p>
                </div>
              </div>
            );
          })}
      </div>
      <ActionButtons
        confirmText={
          workflowType === WORKFLOW_TYPE.Audience
            ? i18n.t('workflow:overview.actions.edit')
            : i18n.t(`workflow:orchestration.validations.${normalizedWorkflowType}.showErrorsOnCanvas`)
        }
        onDecline={hideModal}
        testHook="validateJourney"
        onConfirm={() => {
          hideModal();
          setShowJourneyErrors(true);

          if (workflowType === WORKFLOW_TYPE.Audience) {
            navigate(buildUrl(`workflows/edit/${workflowId}`));
          }
        }}
      />
    </div>
  );
};

export default ValidationModal;
