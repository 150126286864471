angular.module('webUi.ui.content.campaigns.placementsParameter.formModal', [ 'ngResource', 'webUi.service', 'ui.bootstrap' ])

    .controller('AdServingPlacementParameterFormModalController', [ '$scope', '$uibModalInstance', 'dialogsModel', 'CampaignService', 'ValidService',
        function AdServingPlacementParameterFormModalController( $scope, $uibModalInstance, dialogsModel, CampaignService, ValidService) {

            var vm = this;
            var campaign = dialogsModel.data.campaign;

            vm.newParameter = {};

            //check that the new placement to be added is valid numerically
            vm.isValidParameter = function(p) {
                return !_.isUndefined(p) && !_.isEmpty(p.name);
            };

            vm.isUniqueParameter = function(p) {

                if(ValidService.isEmpty(p) || _.isUndefined(campaign.placementParameters)) {
                    return true;
                }

                var foundIdx = _.findIndex(campaign.placementParameters, function(param) {
                    return p.name && param.name.toLowerCase() === p.name.toLowerCase();
                });

                return foundIdx === -1;
            };

            vm.submit = function() {
                dialogsModel.onSave(vm.newParameter);
                $uibModalInstance.close(true);
            };

            vm.close = function() {
                $uibModalInstance.close(false);
            };
        }
    ]);
