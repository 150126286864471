import React, { Component } from 'react';
import { FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class OptimizelyForm extends Component {
  render() {
    const { t, values, partner, canUpdate } = this.props;
    return (
      <FormSection name={constants.partnerTypes.OPTIMIZELY}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          <FieldWithEditMode
            label={t('form.formFields.accessToken')}
            name="accessToken"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default OptimizelyForm;
