angular.module('webUi.ui.tagmanagement.tags.conditions.deduplication', ['webUi.common.Utils'])

    .controller('TagmanagementTagFormDeduplicationConditionController', ['$scope', '$timeout', 'ValidService',
        function TagmanagementTagFormDeduplicationConditionController    ($scope,   $timeout, ValidService) {
        
            var conditionClazz = 'DeduplicationCondition';

            $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) || {
                clazz: conditionClazz,
                markers: [{ 'markerGroup': '', 'markerNames': '' }]
            };

            $scope.isEmptyMarker = function(marker) {
                return ValidService.isEmpty(marker.markerGroup) || ValidService.isEmpty(marker.markerNames);
            };

            $scope.addMarker = function() {
                $scope.state.formCondition.markers.push({
                    'markerGroup': '',
                    'markerNames': ''
                });
            
                // focus on next markerGroup field
                $timeout(function() {
                    var markerGroups = $('.markerGroup');
                    $(_.last(markerGroups)).focus();
                });
            };
        
            $scope.removeMarker = function(index) {
                $scope.state.formCondition.markers.splice(index, 1);
            };
        
            $scope.state.submitCallback = function() {
                $scope.addOrReplaceCondition($scope.state.formCondition);
            };
        }
    ]);

