/********************************************************************************************************************
 * Service that handles DefaultErrorSettings view and form pages, as well as the Restangular calls
 * 
 *******************************************************************************************************************/
angular.module('webUi.service.tagmanagement.errorReporting', [
    'restangular'
])

    .factory('DefaultErrorSettingsService', ['Restangular', function(Restangular){
	
        // the base path through which all calls should go
        var basePath = 'tagmanagement/errorReporting';

        return {
            /**
		 * Retrieves the base path through which all requests go
		 * @returns {String} the base path
		 */
            getBasePath: function(){
                return basePath;
            },
		
            /**
		 * Retrieves a promise of the global error settings object
		 * @returns {Object} a promise object of global error settings
		 */
            getErrorSettings: function() {
                return Restangular.one(basePath).get();
            },
		
            /**
		 * Posts the global error settings
		 * @param {errorSettings} errorSettings the global error settings
		 * @returns A Restangular promise 
		 */
            save: function(errorSettings) {
                return Restangular.all(basePath).post(errorSettings);
            },
            /**
		 * Deletes the error settings
		 * @param {UUID} settingsId
		 * @returns {void}
		 */
            deleteErrorSettings: function(settingsId) {
                return Restangular.one(basePath, settingsId).remove();
            },
            /**
		 * Updates the errorThreshold of the global error settings 
		 * @param settingsId
		 * @param errorThreshold
		 * @return {void}
		 */
            updateErrorThreshold: function(settingsId, errorThreshold) {
			
                return Restangular.one(basePath).post('updateErrorThreshold', {
                    'settingsId' : settingsId, 
                    'errorThreshold': errorThreshold
                });
            },
            /**
		 * Updates the notification emails for the global error settings
		 * @param {UUID} settingsId
		 * @param startDate
		 * @return {void}
		 */
            updateEmailsToNotify: function(settingsId, emailsToNotify){
                return Restangular.one(basePath).post('updateEmailsToNotify', {
                    'settingsId' : settingsId, 
                    'emailsToNotify': emailsToNotify
                });
            },
            /**
		 * Updates the enabled flag of the global error settings
		 * @param {UUID} settingsId
		 * @param enabled
		 * @return {void}
		 */
            updateEnabled: function(settingsId, enabled){
                return Restangular.one(basePath).post('updateEnabled', {
                    'settingsId' : settingsId, 
                    'enabled': enabled
                });
            }
        };
    }]);

