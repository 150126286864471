import * as types from './types';
import * as RuleService from './dataService.js';

export const fetchRuleTypes = () => (dispatch: any) => {
  dispatch({
    type: types.RULE_FETCH_START,
    payload: {},
  });

  return RuleService.getRulesDefinitionsForAudience()
    .then((rules: any = []) => {
      dispatch({
        type: types.RULE_FETCH_SUCCESS,
        payload: rules,
      });
    })
    .catch((error: any) => dispatch({ type: types.RULE_FETCH_FAIL, error }));
};

export const fetchAllVariables = (isRefetch: any) => (dispatch: any) => {
  dispatch({
    type: isRefetch ? types.RULE_VARIABLES_REFETCH_START : types.RULE_VARIABLES_FETCH_START,
    payload: {},
  });

  return RuleService.getAllRuleVariables()
    .then((variables: any = []) => {
      dispatch({
        type: isRefetch ? types.RULE_VARIABLES_REFETCH_SUCCESS : types.RULE_VARIABLES_FETCH_SUCCESS,
        payload: variables,
      });
    })
    .catch((error: any) =>
      dispatch({ type: isRefetch ? types.RULE_VARIABLES_REFETCH_FAIL : types.RULE_VARIABLES_FETCH_FAIL, error }),
    );
};
