import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import { Panel, SmallWrapper } from '~/components/src/Containers';
import ConfigurationForm from '../components/ConfigurationForm';
import {
  saveConfiguration,
  testConnection,
  isConnectionTestSuccessful,
  cleanTestConnectionResult,
} from '../urlBuilder/exportConfigurations';

export class NewConfigurationPage extends Component {
  render() {
    return (
      <Page>
        <SmallWrapper>
          <Heading title={this.props.t('exportConfigurations.formNewConfiguration.header')} />
          <Panel>
            <p className="u-marginBottomXxl">{this.props.t('exportConfigurations.formNewConfiguration.intro')}</p>
            <ConfigurationForm backHref={buildUrl('admin/urlbuilder/')} {...this.props} />
          </Panel>
        </SmallWrapper>
      </Page>
    );
  }
}

export default compose(
  connect(
    state => ({
      isConnectionTestSuccessful: isConnectionTestSuccessful(state),
      formState: state.form.configurationNew,
      stateKey: 'ADMIN/NEWCONFIGURATION',
    }),
    dispatch => ({
      onSubmit: configuration => dispatch(saveConfiguration(configuration)),
      testConnection: configuration => dispatch(testConnection(configuration)),
      cleanTestConnectionResult: () => dispatch(cleanTestConnectionResult()),
    }),
  ),
  reduxForm({
    form: 'configurationNew',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate(['urlBuilder']),
)(NewConfigurationPage);
