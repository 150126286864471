import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionCreators } from 'react-redux-composable-list';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import * as basescriptSelectors from '~/context/basescripts/selectors';
import * as siteSelectors from '~/context/sites/selectors';
import {
  resetBasescriptUsage,
  toggleBasescriptUsageInSite,
  setLinkSitesToBasescriptFilter,
  removeLinkSitesToBasescriptFilter,
} from '../actions';
import BasecriptSitesList from '../../components/BasescriptSitesList';

const LINK_SITES_TO_BASESCRIPT_KEY = 'CONTEXT/BASESCRIPTS/LINK_SITES_TO_BASESCRIPT';
const LINK_SITES_TO_BASESCRIPT_FILTER_KEY = 'CONTEXT/BASESCRIPTS/LINK_SITES_TO_BASESCRIPT_FILTER';

const getTableInfoForSitesLinkedToOtherBasescripts = (state, basescriptId) => {
  const basescript = basescriptSelectors.getBasescriptById(state, basescriptId);
  const sitesNotLinkedToBasescript = siteSelectors.getSitesNotLinkedToBasescript(state, basescript);
  return basescriptSelectors.getSitesBasescriptTableInfo(state, sitesNotLinkedToBasescript, basescriptId);
};

const nameFilterFn = query => item => item.siteName.toLowerCase().indexOf(query.toLowerCase()) !== -1;

export class LinkSitesToBasescriptModal extends Component {
  componentWillUnmount() {
    this.props.resetBasescriptUsage();
  }

  render() {
    const { t, filter, onFilterChange } = this.props;
    return (
      <div>
        <SearchElement
          placeholder={t('basescripts:list.search.placeholder')}
          value={filter}
          onChange={e => onFilterChange(e.target.value)}
        />
        <BasecriptSitesList {...this.props} />
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      t: ownProps.t,
      stateKey: LINK_SITES_TO_BASESCRIPT_KEY,
      basescript: basescriptSelectors.getBasescriptById(state, ownProps.basescriptId),
      list: getTableInfoForSitesLinkedToOtherBasescripts(state, ownProps.basescriptId),
      filter: basescriptSelectors.getLinkSitesToBasescriptFilter(state),
    }),
    (dispatch, ownProps) => ({
      resetBasescriptUsage: () => dispatch(resetBasescriptUsage(ownProps.basescriptId)),
      toggleBasescriptUsage: (siteId, basescriptId) => dispatch(toggleBasescriptUsageInSite(siteId, basescriptId)),
      onFilterChange: query => {
        if (query !== '') {
          dispatch(
            actionCreators.doSetFilter(
              LINK_SITES_TO_BASESCRIPT_KEY,
              LINK_SITES_TO_BASESCRIPT_FILTER_KEY,
              nameFilterFn(query),
            ),
          );
          dispatch(setLinkSitesToBasescriptFilter(query));
        } else {
          dispatch(actionCreators.doRemoveFilter(LINK_SITES_TO_BASESCRIPT_KEY, LINK_SITES_TO_BASESCRIPT_FILTER_KEY));
          dispatch(removeLinkSitesToBasescriptFilter());
        }
      },
    }),
  ),
)(LinkSitesToBasescriptModal);
