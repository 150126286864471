angular.module('webUi.ui.tagmanagement.cookiePermission.delete', ['ui.bootstrap'])

    .controller('CookiePermissionDeleteModalController', ['$scope', '$uibModalInstance', 'dialogsModel',
        function CookiePermissionDeleteModalController($scope, $uibModalInstance, dialogsModel) {

            $scope.cookieSetting = dialogsModel.cookieSetting;

            $scope.cancel = function () {
                $uibModalInstance.close(false);
            };
            $scope.accept = function () {
                $uibModalInstance.close(true);
            };
        }
    ]);

