import React, { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import CheckboxElement from '~/components/src/Form/Elements/CheckboxElement';
import Heading from '~/components/src/Heading';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import i18n from '~/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import Notification from '~/components/src/Notification';

type ItemsPickerContentProps = {
  allItems: any[];
  items: any[];
  idKey: string;
  hideModal: () => void;
  onSave: (items: any[]) => void;
  getItemDetails: (id: string) => void;
};

function ItemsPickerContent({
  allItems,
  items,
  idKey,
  hideModal,
  onSave,
  getItemDetails,
}: ItemsPickerContentProps): ReactElement {
  const [selectedItems, setSelectedItems] = useState(
    allItems.filter(item => items.find(selectedItem => selectedItem[idKey] === item[idKey])),
  );

  const [nonSelectedItems, setNonSelectedFilters] = useState(
    allItems.filter(item => !items.find(selectedItem => selectedItem[idKey] === item[idKey])),
  );

  const [searchQuery, setSearchQuery] = useState('');

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);

  const visibleNonSelectedItems = sortBy(
    nonSelectedItems.filter(nonSelectedItem => nonSelectedItem.name.toLowerCase().includes(searchQuery.toLowerCase())),
    'name',
  );

  const handleSave = async () => {
    const itemDetails = await Promise.all(selectedItems.map(selectedItem => getItemDetails(selectedItem[idKey])));
    onSave(itemDetails);
    hideModal();
  };

  const selectItem = (item: any) => {
    setNonSelectedFilters(nonSelectedItems.filter(nonSelectedItem => nonSelectedItem[idKey] !== item[idKey]));
    setSelectedItems([...selectedItems, item]);
  };

  const deselectItem = (item: any) => {
    setSelectedItems(selectedItems.filter(selectedItem => selectedItem[idKey] !== item[idKey]));
    setNonSelectedFilters([...nonSelectedItems, item]);
  };

  const hasItems = allItems.length > 0;
  const hasSelectedItems = selectedItems.length > 0;
  const hasVisibleNonSelectedItems = visibleNonSelectedItems.length > 0;

  return (
    <div className="flex max-h-full flex-1 flex-col gap-3">
      {!hasItems ? (
        <Notification kind="warning">{i18n.t('itemPicker:form.noItems')}</Notification>
      ) : (
        hasVisibleNonSelectedItems && (
          <>
            <div>
              <Heading className="pb-0 pt-0" kind="h5" title={i18n.t('itemPicker:form.nonSelectedItems')} />
              <SearchElement
                onChange={event => setSearchQuery(event.target.value)}
                placeholder={i18n.t('itemPicker:form.searchNonSelectedItems')}
                value={searchQuery}
                testHook="searchFilter"
              />
            </div>
            <div className="flex-1 overflow-y-scroll rounded bg-gray-50 p-4">
              {visibleNonSelectedItems.map(item => (
                <div className="t-filterSelection mb-4 flex gap-2" key={item[idKey]}>
                  <CheckboxElement value={false} id={item[idKey]} onChange={() => selectItem(item)} />
                  <label className="flex-1 cursor-pointer truncate" htmlFor={item[idKey]}>
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </>
        )
      )}
      {hasItems && hasSelectedItems && (
        <>
          <Heading className="pb-0 pt-0" kind="h5" title={i18n.t('itemPicker:form.selectedItems')} />
          {!hasSelectedItems && hasItems && (
            <Notification kind="information">{i18n.t('itemPicker:form.pleaseSelectSomething')}</Notification>
          )}
          {hasSelectedItems && (
            <div className="mb-8 flex-1 overflow-y-scroll rounded bg-gray-50 p-4">
              {selectedItems.map(item => (
                <div className="mb-4 flex gap-2" key={item[idKey]}>
                  <CheckboxElement value={true} id={item[idKey]} onChange={() => deselectItem(item)} />
                  <label className="flex-1 cursor-pointer" htmlFor={item[idKey]}>
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <ActionButtons
        className="!p-0"
        isConfirmEnabled={isSaveButtonEnabled && hasSelectedItems}
        onConfirm={e => {
          e?.preventDefault();
          setIsSaveButtonEnabled(false);
          handleSave();
        }}
        onDecline={hideModal}
        testHook="itemsSelect"
      />
    </div>
  );
}

export default ItemsPickerContent;
