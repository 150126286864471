import moment from 'moment';
import { CHART_COLORS, getTooltipFormatter } from '~/common/chart';
import i18n from '~/i18n';
import {
  CounterStatsPayload,
  TaggingCallsData,
  TaggingCallsPayload,
  ParseTaggingErrors,
  TaggingErrorsData,
  NewPathsPayload,
  NewPathsData,
  CounterStatsData,
} from './types';

const getColorByIndex = (index: number) => Object.values(CHART_COLORS)[index];

export const parseTaggingErrors = (result: ParseTaggingErrors): TaggingErrorsData[] => {
  const list = (result && result.tagUsage) || [];

  const sortedList = list
    .map(item => ({
      ...item,
      errorsPercentage: `${(item.errors / item.calls > 1 ? 100 : (item.errors / item.calls) * 100).toFixed(2)} %`,
    }))
    .sort((a, b) => b.errors - a.errors);

  return sortedList;
};

export const parseCounterStats = (result: CounterStatsPayload): CounterStatsData => {
  const stats = Object.entries(result).map(([key, counterStats], index) => {
    const requestCounts = counterStats.map((stat: { requestCount: number }) => stat.requestCount);
    const timestamp = counterStats.map((stat: { key: { dayTimestamp: moment.MomentInput } }) =>
      moment(stat.key.dayTimestamp).format('D. MMM'),
    );

    const series = {
      name: key,
      data: requestCounts,
      color: getColorByIndex(index + 1),
      timestamp,
    };

    const totalStats = requestCounts.reduce((sum: number, count: number) => sum + count, 0);
    const tableStats = {
      totalStats,
      name: key,
    };
    return { series, tableStats };
  });

  const series = stats.map(stat => stat.series);
  const tableStats = stats.map(stat => stat.tableStats);
  const timestamp = series.length ? series?.[0]?.timestamp : [];

  return {
    series,
    tableStats,
    timestamp,
  };
};

export const parseTaggingCalls = (result: TaggingCallsPayload[]): TaggingCallsData => {
  const series = result.map((item: { tagCalls: number }) => item.tagCalls) || [];
  const timestamp = result.map((stat: { key: { timestamp: moment.MomentInput } }) =>
    moment(stat.key.timestamp).format('D. MMM'),
  );
  const total = result.reduce((total, entry) => total + entry.tagCalls, 0);

  return { series, timestamp, total };
};

export const parseNewPaths = (result: NewPathsPayload[]): NewPathsData => {
  const series = result.map(item => item.count) || [];
  const timestamp = result.map(stat => moment(stat.key.timestamp).format('D. MMM'));
  const total = result.reduce((total, entry) => total + entry.count, 0);

  return {
    total,
    timestamp,
    series,
  };
};

export const getOptions = () => ({
  title: {
    text: '',
  },
  chart: {
    height: 300,
    alignTicks: true,
  },
  xAxis: [
    {
      type: 'datetime',
      title: {
        text: i18n.t('stats:chart.xAxisTitle'),
      },
      startOnTick: false,
      endOnTick: false,
    },
  ],
  yAxis: [
    {
      opposite: false,
      title: {
        text: i18n.t('stats:counters.leftSide'),
        style: {
          color: CHART_COLORS.DIMGRAY,
        },
      },
      min: null,
      minTickInterval: 1,
      labels: {
        style: {
          color: CHART_COLORS.DIMGRAY,
        },
      },
    },
    {
      title: {
        text: i18n.t('stats:counters.rightSide'),
      },
      labels: {
        formatter() {
          return `${this.value} %`;
        },
      },

      min: 0.001,
      max: 100,
      type: 'logarithmic',
      minorTickInterval: 1,
      opposite: true,
    },
  ],
  tooltip: {
    formatter() {
      return getTooltipFormatter.apply(this);
    },
    shared: true,
    useHTML: true,
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSeries = (stats: TaggingCallsPayload[]) => [
  {
    id: 'errorsNumber',
    name: i18n.t('stats:counters.chart.errorsNumber'),
    yAxis: 0,
    data: stats.map(item => item.tagErrors),
    pointInterval: 24 * 36e5,
    pointStart: stats.length === 0 ? 0 : stats[0].key.dayTimestamp,
    color: CHART_COLORS.YELLOW,
    type: 'column',
  },
  {
    id: 'errorsPercentage',
    name: i18n.t('stats:counters.chart.errorsPercentage'),
    yAxis: 1,
    opposite: true,
    data: stats.map(element => {
      if (element.tagCalls === 0 || element.tagErrors === 0) {
        return NaN;
      }
      const result = (element.tagErrors / element.tagCalls) * 100;

      return result > 100 ? 100 : result;
    }),
    pointInterval: 24 * 36e5,
    pointStart: stats.length === 0 ? 0 : stats[0].key.dayTimestamp,
    color: CHART_COLORS.RED,
    type: 'line',
  },
];

export const parseTagErrors = (
  result: TaggingCallsPayload[],
): ReturnType<typeof getOptions> & { series: ReturnType<typeof getSeries> } => ({
  ...getOptions(),
  series: getSeries(result),
});

export const generateRangeQueryParams = (startDate: number, endDate: number): string =>
  `rangeFrom=${startDate}&rangeTo=${endDate}`;
