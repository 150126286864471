import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { TTaxonomyPayload, TTaxonomyTreeNode } from './types';
import { mapTreeData } from './utils';

const BASE_PATH = `${API_BASE}/v1/partners/yahoo`;

export const fetchAllTaxonomies = (partnerId: string): Promise<TTaxonomyTreeNode[]> =>
  Api.callGet(`${BASE_PATH}/${partnerId}/taxonomy`).then(parseResponse).then(mapTreeData);

export const createTaxonomy = (partnerId: string, payload: TTaxonomyPayload) =>
  Api.callPost(`${BASE_PATH}/${partnerId}/taxonomy`, payload).then(parseResponse);

export const refetchTaxonomy = (partnerId: string): Promise<TTaxonomyTreeNode[]> =>
  Api.callGet(`${BASE_PATH}/${partnerId}/taxonomy/refresh`).then(parseResponse).then(mapTreeData);
