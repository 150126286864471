import { buildUrl } from '~/common';
import { get, isArray, find, includes } from 'lodash';

/**
 * Get All Sites
 * @param state
 */
export const getSites = state => state.context.sites.allSitesIds.map(id => state.context.sites.bySiteId[id]);

export const getRawSite = (state, siteId) => {
  const site = get(state, `context.sites.bySiteId[${siteId}`, {});
  // Fix lack of GENERAL site module in the backend response
  return {
    ...site,
    siteModules: [...(site.siteModules || []), 'GENERAL'],
  };
};

export const getSite = (state, siteId) => {
  const site = get(state, `context.sites.bySiteId[${siteId}`);
  // Backend is returning only the Id when saved, in order to merge the data I need to set maxTags with default value
  const maxDefaultTags = 1000;
  return !site
    ? null
    : {
        ...site,
        maxTags: !site.maxTags ? maxDefaultTags : site.maxTags,
        restrictedSubnets:
          site.restrictedSubnets && isArray(site.restrictedSubnets) && site.restrictedSubnets.join(','),
        siteModules:
          site &&
          isArray(site.siteModules) &&
          site.siteModules.reduce(
            (modules, module) => ({
              ...modules,
              [module]: true,
            }),
            {},
          ),
      };
};

export const getTimezonesForSelect = state =>
  get(state, 'context.sites.timezones').map(timezone => ({ value: timezone, label: timezone }));

export const getTimezones = state => get(state, 'context.sites.timezones');

export const getSiteStats = (state, siteId) => get(state, `context.sites.siteStats[${siteId}]`, []);

/**
 * getSiteModules, we were explicit filtering GENERAL module in the old code
 * @param state
 */

export const getSiteModules = state =>
  get(state, 'context.sites.siteModules').filter(module => module.name !== 'GENERAL');

/**
 * Get All Sites in a select data format
 * @param state
 */
export const getSitesForSelect = state => {
  const sites = getSites(state);
  return sites.map(site => ({
    value: { id: site.siteId, label: site.name, siteAdvancedSecurity: site.advancedSecurity },
    label: site.name,
  }));
};

/**
 * Should fetch all sites?
 * @param state
 */
export const shouldFetchSites = state => get(state, 'context.sites.ui.shouldFetchAll');

/**
 * Is fetching all sites currently in progress?
 * @param state
 */
export const isFetchingSites = state => get(state, 'context.sites.ui.isFetchingAll');

export const getFilter = state => get(state, 'context.sites.ui.filter');

export const getSitesNotLinkedToBasescript = (state, basescript) =>
  getSites(state).filter(
    site =>
      !includes(
        basescript.linkedToSites.map(linkedSite => linkedSite.siteId),
        site.siteId,
      ),
  );

export const getSiteViewData = (state, siteId) => {
  const site = get(state, `context.sites.bySiteId[${siteId}`);
  // Backend is returning only the Id when saved, in order to merge the data I need to set maxTags with default value
  const maxDefaultTags = 1000;
  return !site
    ? null
    : {
        ...site,
        maxTags: !site.maxTags ? maxDefaultTags : site.maxTags,
        restrictedSubnets:
          site.restrictedSubnets && isArray(site.restrictedSubnets) && site.restrictedSubnets.join(','),
        siteModules:
          site &&
          isArray(site.siteModules) &&
          site.siteModules.reduce((modules, module) => {
            const siteModules = getSiteModules(state);
            const siteModuleObject = find(siteModules, { name: module });
            if (siteModuleObject) {
              modules.push(siteModuleObject.label);
            }
            return modules;
          }, []),
      };
};

export const getBasescripUrlForSite = (state, siteId) => {
  const site = getSiteViewData(state, siteId);
  return !site || !site.basescript ? null : buildUrl(`context/basescripts/view/${site.basescript.basescriptId}`);
};
