import React, { ReactElement, useContext, useState, useMemo, useCallback } from 'react';
import i18n from '~/i18n';
import InputElement from '~/components/src/Form/Elements/InputElement';
import ActionButtons from '~/components/src/ActionButtons';
import { Link } from '~/components';
import { UrlBuilderContext } from '../UrlBuilderContext';
import { URLGeneratorProps } from '../types';

const HTTP = 'http://';
const HTTPS = 'https://';

const formatURL = (url: string): string => {
  if (!url || url === HTTP || url === HTTPS) return '';
  return url.startsWith(HTTP) || url.startsWith(HTTPS) ? url.trim() : HTTPS + url.trim();
};

const generateURL = (baseURL: string, key: string, value: string): string => {
  if (!baseURL) return '';
  const separator = baseURL.includes('?') ? '&' : '?';
  return `${baseURL}${separator}${key}=${value}`;
};

export const URLGenerator = ({ hideModal, urlBuilderTypeId, mappingKey }: URLGeneratorProps): ReactElement => {
  const { campaignTypes } = useContext(UrlBuilderContext);
  const [inputUrl, setInputUrl] = useState('');
  const [warning, setWarning] = useState(false);

  const keyParameter = useMemo(
    () => campaignTypes.find(({ typeId }) => typeId === urlBuilderTypeId)?.keyParameter || '',
    [campaignTypes, urlBuilderTypeId],
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawUrl = e.target.value.trim();
    setWarning(rawUrl.startsWith(HTTP));
    setInputUrl(rawUrl);
  }, []);

  const generatedUrl = useMemo(() => {
    const formattedUrl = formatURL(inputUrl);
    return generateURL(formattedUrl, keyParameter, mappingKey);
  }, [inputUrl, keyParameter, mappingKey]);

  return (
    <>
      <p className="py-4 text-gray-500">{i18n.t('campaigns:urlGenerator.description', { key: mappingKey })}</p>

      <InputElement name="urlGeneratorField" id="urlGeneratorField" onChange={handleChange} defaultValue={HTTPS} />

      {warning && <p className="t-warning pt-2 text-yellow-500">{i18n.t('campaigns:urlGenerator.warning')}</p>}
      {generatedUrl && (
        <Link className="flex pt-4 italic" href={generatedUrl} target="_blank" testHook="generatedUrl">
          {generatedUrl}
        </Link>
      )}

      <ActionButtons isConfirmEnabled={false} onDecline={hideModal} declineText={i18n.t('common:close')} />
    </>
  );
};
