(function() {

    UsedInController.$inject = ['$rootScope', 'DependencyService'];

    function UsedInController($rootScope, DependencyService) {
        var vm = this;

        vm.$onInit = function $onInit() {
            vm.assetsPrefix = $rootScope.assetsPrefix;
            vm.overviewTypes = DependencyService.getOverviewTypes(vm.sourceModule, vm.dependants);
        };

    }

    angular.module('webUi.component.profiles.shared.usedIn', ['webUi.service'])

        .component('usedIn', {
            controller: UsedInController,
            controllerAs: 'vm',
            templateUrl: 'component/profiles/shared/usedIn/usedIn.tpl.html',
            bindings: {
                sourceModule: '<',
                dependants: '<'
            }
        });

})();
