import React, { useContext } from 'react';
import { get } from 'lodash';
import { formatNumber } from '~/common';
import i18n from '~/i18n';
import { JourneyContext } from '../JourneyContext';
import { ButtonsPanel, DeleteIcon, EditIcon, IconButton, StatisticsIcon, ViewIcon } from './ButtonsPanel';
import { CARD_HEIGHT, CARD_WIDTH, HALF_CARD_HEIGHT, NODE_PADDING_RIGHT } from '../constants';
import { ExpandCollapseButton } from './ExpandCollapseButton';
import { AntennaIcon, ExperimentIcon, getIconPositions, StepIcon } from './Icons';
import { ButtonOnALine, HoverRegionUnderCard, Node } from './Node';
import ProfilesCount from './ProfilesCount';
import { RoundAddButton } from './RoundButton';
import { StepNodeProps, TBase } from './types';
import { CanvasContext } from '../JourneyCanvas/CanvasContext';

const AddTriggerButton = ({ x, y, onClick, className, hasError }: TBase) => (
  <ButtonOnALine
    text="Add trigger"
    x={x}
    y={y}
    onClick={onClick}
    className={className}
    hasError={hasError}
    isTrigger={true}
  />
);

export const StepNode = ({
  x,
  y,
  data,
  parent,
  canEdit,
  hasLinkError,
  hasNodeError,
  showExperimentSettingsModal,
}: StepNodeProps) => {
  const { actions } = useContext(JourneyContext);
  const { isSumOfVariantsValid, setNodeToDelete, handleExpandAndCollapse } = useContext(CanvasContext);

  const { goToCreateTriggerScreen, goToViewStepScreen, goToEditStepScreen, goToStepStatisticsScreen } = actions;

  const experimentWeight = get(data, 'experimentVariant.weight', null);

  const hasChildrenTriggers = data.children.length > 0;

  const buttons = [
    {
      icon: ViewIcon,
      testHook: 'view-step',
      onClick: () => {
        goToViewStepScreen(data.nodeId);
      },
    },
    {
      icon: StatisticsIcon,
      testHook: 'step-statistics',
      onClick: () => {
        goToStepStatisticsScreen(data.nodeId);
      },
    },
  ];

  if (canEdit) {
    buttons.push(
      {
        icon: EditIcon,
        testHook: 'edit-step',
        onClick: () => {
          goToEditStepScreen(data.nodeId, parent?.data?.nodeId);
        },
      },
      {
        icon: DeleteIcon,
        testHook: 'delete-step',
        onClick: () => {
          setNodeToDelete({ ...data, parentTriggerId: parent?.data?.nodeId });
        },
      },
    );
  }
  const iconPositions = getIconPositions(buttons.length);
  const isVariant = parent.children.length > 1;
  const showExpandCollapse = hasChildrenTriggers || data.isChildrenCollapsed;

  return (
    <>
      {canEdit && !data.isChildrenCollapsed && (
        <>
          {hasChildrenTriggers ? (
            <RoundAddButton
              x={x + CARD_WIDTH + NODE_PADDING_RIGHT / 2}
              y={y + HALF_CARD_HEIGHT}
              tooltipText="Add trigger"
              tooltipWidth={200}
              tooltipHeight={36}
              onClick={() => {
                goToCreateTriggerScreen(data.nodeId);
              }}
              className="t-create-trigger-button"
            />
          ) : (
            <AddTriggerButton
              x={x + CARD_WIDTH}
              y={y + HALF_CARD_HEIGHT - 16}
              onClick={() => {
                goToCreateTriggerScreen(data.nodeId);
              }}
              className="t-create-trigger-button"
              hasError={hasLinkError}
            />
          )}
        </>
      )}
      <Node
        Icon={isVariant ? ExperimentIcon : StepIcon}
        RightIcon={data.hasVariableModifications && AntennaIcon}
        x={x}
        y={y}
        hasError={hasNodeError}
        data={data}
        parent={parent}
        isStep={true}
        isVariant={isVariant}
        isWeightValid={isSumOfVariantsValid}
        className="JourneyView-stepNode"
      >
        <ProfilesCount
          profilesCount={formatNumber(data.profilesCount)}
          experimentWeight={experimentWeight || 0}
          isVariant={isVariant}
          isWeightValid={isSumOfVariantsValid}
          showExperimentSettingsModal={showExperimentSettingsModal}
        />
        {showExpandCollapse && (
          <ExpandCollapseButton
            isCollapsed={data?.isChildrenCollapsed}
            tooltipText={i18n.t(`workflow:journey.${data?.isChildrenCollapsed ? 'expandNodes' : 'collapseNodes'}`)}
            className="t-expand-collapse-trigger-button"
            x={CARD_WIDTH}
            y={HALF_CARD_HEIGHT}
            tooltipWidth={200}
            tooltipHeight={36}
            onClick={() => handleExpandAndCollapse(data.nodeId)}
          />
        )}
        <HoverRegionUnderCard />
        <ButtonsPanel x={0} y={CARD_HEIGHT + 16}>
          {buttons.map((buttonConfig, buttonIndex) => (
            <IconButton
              icon={buttonConfig.icon}
              onClick={buttonConfig.onClick}
              x={iconPositions[buttonIndex]}
              key={buttonIndex}
              testHook={buttonConfig.testHook}
            />
          ))}
        </ButtonsPanel>
      </Node>
    </>
  );
};
