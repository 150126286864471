import React from 'react';
import '~/profiles/components/Dnd.scss';

export function defaultDropConnect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const DropComponent = ({ connectDropTarget, isOver, children }) =>
  connectDropTarget(
    <div className="Dnd-target Dnd-item">
      {children}
      {isOver && <span className="Dnd-targetLayer" />}
    </div>,
  );

export default DropComponent;
