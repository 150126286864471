/**
 * Aggregator module
 */
angular.module('webUi.service.profiles', [
    'webUi.service.profiles.segments',
    'webUi.service.profiles.segments.stats',
    'webUi.service.profiles.variables',
    'webUi.service.profiles.thirdPartyDataCollectors',
    'webUi.service.profiles.dependencies',
    'webUi.service.profiles.externalfacts',
    'webUi.service.profiles.engagements',
    'webUi.service.profilesConstants',
    'webUi.service.profiles.interactions',
]);
