import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'formik';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import BtnOutlined from '~/components/src/BtnOutlined';
import { Notification } from '~/components';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import AddAmazonDsp from './AddAmazonDspUserForm';
import { fetchAmazonAudience } from './dataService';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';

const validations = t =>
  Yup.object().shape({
    audience: Yup.object({
      id: Yup.string()
        .trim()
        .required(t('validation:validation.isRequiredTo', { field: 'search' })),
      name: Yup.string().required(t('validation:validation.required')),
      description: Yup.string().required(t('validation:validation.required')),
    }).required(t('connectors:cards.validation.amazonDspAudience')),
  });

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const openAmazonDspModal = (partnerId, setFieldValue) => dispatch => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:connectors.amazonDsp.createAudience.title'),
      confirmText: i18n.t('audiences:connectors.amazonDsp.createAudience.confirmText'),
      content: (
        <AddAmazonDsp hideModal={() => dispatch(hideModal())} partnerId={partnerId} setFieldValue={setFieldValue} />
      ),
    }),
  );
};

const AmazonDsp = ({ onEdit, connector, openAmazonDspModal, t, isSubmitted }) => {
  const [showNotFound, setShowNotFound] = useState(false);

  const { partnerId } = connector.partnerDetails;

  const searchAudience = async (audienceId = '', setFieldValue) => {
    setFieldValue('audience', { id: audienceId });
    setShowNotFound(false);
    try {
      const audience = await fetchAmazonAudience(partnerId, audienceId);
      setFieldValue('audience', audience);
    } catch (err) {
      setShowNotFound(true);
    }
  };

  return (
    <ConnectorGenericForm
      onEdit={onEdit}
      validations={validations(t)}
      connector={connector}
      isSubmitted={isSubmitted}
      validateOnBlur={true}
    >
      {({ setFieldValue, values, errors }) => (
        <>
          <div className="flex items-center">
            <div className="flex-1">
              <Field
                name="audience.id"
                as={FormikInputField}
                label={t('connectors.amazonDsp.audienceId')}
                placeholder={t('connectors.amazonDsp.placeholder')}
                autoFocus={true}
                className="flex-1"
                errorText={errors.audience?.id}
              />
            </div>
            <div className="flex-1 items-center gap-4">
              <BtnOutlined
                icon="search"
                className="mx-2 mt-3"
                fullWidth={false}
                color="gray"
                onClick={() => searchAudience(values?.audience?.id, setFieldValue)}
                size="bs"
                disabled={!values.audience?.id}
                testHook="searchAudience"
              >
                {t('connectors.amazonDsp.actions.search')}
              </BtnOutlined>
              <BtnOutlined
                icon="add"
                className="mx-2 mt-3"
                fullWidth={false}
                color="blue"
                onClick={() => openAmazonDspModal(partnerId, setFieldValue)}
                size="bs"
                testHook="addNewAudience"
              >
                {t('connectors.amazonDsp.actions.add')}
              </BtnOutlined>
            </div>
          </div>

          {showNotFound && !values?.audience?.name && (
            <Notification kind="error">{t('connectors.amazonDsp.notFound')}</Notification>
          )}

          <SmallWrapperNoOffset>
            {values?.audience?.name && (
              <Field
                name="audience.name"
                as={FormikInputField}
                label={t('connectors.amazonDsp.audienceName')}
                placeholder={t('connectors.amazonDsp.placeholder')}
                className="flex-1"
                disabled={true}
              />
            )}
            {values?.audience?.description && (
              <Field
                name="audience.description"
                as={FormikInputField}
                label={t('connectors.amazonDsp.audienceDescription')}
                placeholder={t('connectors.amazonDsp.placeholder')}
                className="flex-1"
                disabled={true}
              />
            )}
          </SmallWrapperNoOffset>
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { hideModal, openAmazonDspModal }), translate('audiences'))(AmazonDsp);
