import React, { useState } from 'react';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import EditableRulesList from '~/common/modules/filterRules/components/EditableRulesList';
import { RuleCardTypes } from '~/components/src/FilterRuleList';
import { useAPI } from '~/common';
import { Notification } from '~/components';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';
import { FilterRule } from '../types';
import { getRulesDefinitions } from '../dataService';
import { getRulesTreeFromTypes, prepareDataForViewTrigger } from '../util';

type TEventsTabProps = { rules: FilterRule[] | undefined };

const EventsTab = ({ rules }: TEventsTabProps) => {
  const [query, setQuery] = useState('');

  const { data: rulesTree, isLoading } = useAPI(async () => {
    const ruleDefinitions = await getRulesDefinitions();
    return getRulesTreeFromTypes(ruleDefinitions);
  });

  if (isLoading) {
    return <Spin />;
  }
  const { criteria } = prepareDataForViewTrigger({ criteria: rules?.map(item => ({ rules: [item] })) });

  const filteredRule = () =>
    criteria?.filter((item: { groupName: string; ruleName: string; ruleDefinitionName: string }[]) => {
      const queryLower = query.toLowerCase();
      return (
        item[0].ruleName?.toLowerCase().includes(queryLower) ||
        item[0].ruleDefinitionName?.toLowerCase().includes(queryLower) ||
        item[0].groupName?.toLowerCase().includes(queryLower)
      );
    });
  return (
    <>
      <div className="mb-5 flex justify-end">
        <SearchElement
          value={query}
          onChange={e => {
            setQuery(e.target.value);
          }}
          placeholder={i18n.t('common:actions.search')}
          testHook="search"
        />
      </div>
      {filteredRule()?.length ? (
        <EditableRulesList
          criteria={filteredRule()}
          ruleTypesTree={rulesTree || []}
          ruleCardType={RuleCardTypes.ViewCard}
        />
      ) : (
        <Notification kind="information">
          <p> {i18n.t('filters:messages.noEvents')}</p>
        </Notification>
      )}
    </>
  );
};

export default EventsTab;
