import React from 'react';
import { Notification, PickerView } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/i18n';

type TDirtyFormPopupProps = {
  showDirtyFormPopup: boolean;
  setShowDirtyFormPopup: React.Dispatch<React.SetStateAction<boolean>>;
  cbRef: React.MutableRefObject<(() => void) | undefined>;
};

const DirtyFormPopup = ({ showDirtyFormPopup, setShowDirtyFormPopup, cbRef }: TDirtyFormPopupProps) => (
  <>
    {showDirtyFormPopup && (
      <PickerView
        className="!h-auto min-h-[200px] !w-[500px]"
        pickerTitle={i18n.t('workflow:dirtyFormPopup.title')}
        handlePickerVisibility={() => {
          setShowDirtyFormPopup(false);
        }}
      >
        <>
          <Notification kind="warning" className="m-2">
            {i18n.t('workflow:dirtyFormPopup.message')}
          </Notification>
          <ActionButtons
            className="mx-4"
            onConfirm={() => {
              setShowDirtyFormPopup(false);
              cbRef?.current?.();
            }}
            onDecline={() => setShowDirtyFormPopup(false)}
            confirmText={i18n.t('workflow:dirtyFormPopup.actions.yes')}
            testHook="next"
          />
        </>
      </PickerView>
    )}
  </>
);

export default DirtyFormPopup;
