import * as types from '../types';
import * as selectors from '../selectors';
import CookiesVariantsService from '../dataService';

/**
 * Mark/UnMark client (of a given cookie permission template) for restriction
 * @param variantId
 * @param clientId
 */
export const toggleRestriction = (variantId, clientId) => ({
  type: types.CLIENT_TOGGLE_RESTRICTION,
  payload: {
    variantId,
    clientId,
  },
});

/**
 * Unrestrict single client for a given cookie permission template
 * @param variantId
 * @param clientId
 */
export const unRestrictClient = (variantId, clientId) => dispatch => {
  dispatch({
    type: types.CLIENT_UNRESTRICT_START,
  });

  return CookiesVariantsService.unRestrictClient(variantId, clientId)
    .then(() => {
      dispatch({
        type: types.CLIENT_UNRESTRICT_SUCCESS,
        payload: {
          variantId,
          clientId,
        },
      });
    })
    .catch(error => dispatch({ type: types.CLIENT_UNRESTRICT_FAIL, error }));
};

/**
 * Restrict all clients marked for restriction (if any) for a given cookie permission template
 * @param variantId
 */
export const restrictClients = variantId => (dispatch, getState) => {
  const clientIds = selectors.getClientIdsMarkedToRestrict(getState(), variantId);

  if (clientIds.length === 0) {
    return Promise.resolve();
  }

  dispatch({
    type: types.CLIENTS_RESTRICT_START,
  });

  return CookiesVariantsService.restrictClients(variantId, clientIds)
    .then(() => {
      dispatch({
        type: types.CLIENTS_RESTRICT_SUCCESS,
        payload: {
          variantId,
          clientIds,
        },
      });
    })
    .catch(error => dispatch({ type: types.CLIENTS_RESTRICT_FAIL, error }));
};
