angular.module('webUi.ui.admin', [
    'webUi.ui.admin.users',
    'webUi.ui.admin.environments',
    'webUi.ui.admin.environmentsAdd',
    'webUi.ui.admin.environmentsEdit',
    'webUi.ui.admin.urlBuilder',
    'webUi.ui.admin.urlBuilderColumnAdd',
    'webUi.ui.admin.urlBuilderColumnEdit',
    'webUi.ui.admin.urlBuilderTypeAdd',
    'webUi.ui.admin.urlBuilderTypeEdit',
    'webUi.ui.admin.urlBuilderValueSetsAdd',
    'webUi.ui.admin.urlBuilderValueSetsEdit',
    'webUi.ui.admin.urlBuilderExportConfigAdd',
    'webUi.ui.admin.urlBuilderExportConfigEdit',
    'webUi.ui.admin.connectors',
    'webUi.ui.admin.connectorsAdd',
    'webUi.ui.admin.connectorsEdit',
    'webUi.ui.admin.siteSecurity',
    'webUi.ui.admin.identities',
    'webUi.ui.admin.imports',
    'webUi.ui.admin.importsAdd',
    'webUi.ui.admin.importsView',
]);
