import { changeUrl } from '~/common';
import { selectors } from 'react-redux-composable-list';
import * as types from './types';
import ContextUsersService from './dataService.js';

export const fetchUserClients = userId => dispatch => {
  dispatch({
    type: types.USER_CLIENTS_FETCH_START,
  });

  return ContextUsersService.getClientsForUser(userId)
    .then(clients =>
      dispatch({
        type: types.USER_CLIENTS_FETCH_SUCCESS,
        payload: {
          userId,
          clients,
        },
      }),
    )
    .catch(error => dispatch({ type: types.USER_CLIENTS_FETCH_FAIL, error }));
};

export const addClientManager = userId => (dispatch, getState) => {
  const clientIds = selectors.getSelection(getState(), types.USER_PROMOTE_LIST);
  const model = {
    userId,
    clientIds,
  };

  if (!clientIds || !clientIds.length) return changeUrl(`context/users/view/${userId}`);

  dispatch({
    type: types.ADD_CLIENT_MANAGER_START,
    payload: model,
  });

  return ContextUsersService.promoteUserToClientManager(model)
    .then(() => {
      dispatch({
        type: types.ADD_CLIENT_MANAGER_SUCCESS,
        payload: model,
      });

      // Go to the user view
      changeUrl(`context/users/view/${userId}`);
    })
    .catch(error => dispatch({ type: types.ADD_CLIENT_MANAGER_FAIL, error }));
};

export const removeClientManager = (userId, clientId) => dispatch => {
  const model = {
    userId,
    clientId,
  };

  dispatch({
    type: types.REMOVE_CLIENT_MANAGER_START,
    payload: model,
  });

  return ContextUsersService.demoteUserFromClientManager(model)
    .then(() =>
      dispatch({
        type: types.REMOVE_CLIENT_MANAGER_SUCCESS,
        payload: model,
      }),
    )
    .catch(error => dispatch({ type: types.REMOVE_CLIENT_MANAGER_FAIL, error }));
};
