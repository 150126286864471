import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/profiles/cdp`;

const cusomterDataService = {
  getProfileId: (partnerType, partnerId, errorHandlingOptions) =>
    Api.callGet(`${BASE_PATH}/profileId?partnerType=${partnerType}&partnerId=${partnerId}`, errorHandlingOptions).then(
      parseResponse,
    ),
  getCustomerIdentifiers: (partnerType, partnerId, errorHandlingOptions) =>
    Api.callGet(`${BASE_PATH}/mappings?partnerType=${partnerType}&partnerId=${partnerId}`, errorHandlingOptions).then(
      parseResponse,
    ),
  getCustomerAudiences: (partnerType, partnerId, errorHandlingOptions) =>
    Api.callGet(`${BASE_PATH}/audience?partnerType=${partnerType}&partnerId=${partnerId}`, errorHandlingOptions).then(
      parseResponse,
    ),
  getCustomerJourney: (partnerType, partnerId, errorHandlingOptions) =>
    Api.callGet(
      `${BASE_PATH}/journeyorchestration?partnerType=${partnerType}&partnerId=${partnerId}`,
      errorHandlingOptions,
    ).then(parseResponse),
  getCustomerFacts: (partnerType, partnerId, errorHandlingOptions) =>
    Api.callGet(`${BASE_PATH}/facts?partnerType=${partnerType}&partnerId=${partnerId}`, errorHandlingOptions).then(
      parseResponse,
    ),
  getDependants: (factId, errorHandlingOptions) =>
    Api.callGet(`${API_BASE}/profiles/dependencies/${factId}`, errorHandlingOptions).then(parseResponse),
};

export default cusomterDataService;
