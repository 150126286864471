angular.module('webUi.ui.content.campaigns.ads.creatives', [
    'webUi.ui.content.campaigns.ads.creatives.form', 
    'webUi.ui.content.campaigns.ads.creatives.form.datafeedkeychooser', 
    'webUi.ui.content.campaigns.ads.creatives.preview', 
    'webUi.ui.content.campaigns.ads.creatives.clickPreview', 
    'webUi.ui.content.campaigns.ads.creatives.mediafilechooser'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.ads.creatives', {
                url: '/creatives',
                'abstract': true
            });
    }]);
