angular.module('webUi.ui.profiles.transformations.view',
    [
        'webUi.ui.profiles.transformations.base'
    ]
)
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider.state('site.profiles.transformations.view', {
            url: '/view/{transformerId}',
            isReact: true,
        });
    }]);