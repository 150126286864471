import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import '../styles.scss';
import { TableContext } from '../context';

class Table extends Component {
  render() {
    const { isTable, style, className, children, testHook, stateKey, isSelectable = false } = this.props;
    const childContext = {
      stateKey,
      isTable: !!isTable,
      isSelectable: !!isSelectable,
    };
    const Element = isTable ? 'table' : 'div';

    return (
      <TableContext.Provider value={childContext}>
        <Element className={cx(className, 'Table t-table u-sizeFull', `t-${testHook}`)} style={style}>
          {children}
        </Element>
      </TableContext.Provider>
    );
  }
}

Table.defaultProps = {
  className: '',
};

Table.propTypes = {
  stateKey: PropTypes.string.isRequired,
  isTable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Table;
