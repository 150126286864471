angular.module('webUi.ui.tagmanagement.tags.base', [
    'webUi.ui.tagmanagement.base',
    'webUi.common.Utils'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.tagmanagement.tags', {
            //the 'type' param can be one of the 'all', 'paths', 'groups', while the 'identifierParam'
            //can be either the location (for 'paths') or the groupId (for 'groups')
                url: '/tags?type&identifierParam',
                views : {
                    'leftMenu@' : {
                        templateUrl: 'ui/tagmanagement/tags/base/base.tpl.html',
                        controller: 'TagTreeViewController'
                    }
                },
                resolve : {
                    structureData : ['TagmanagementService', function(TagmanagementService) {
                        return TagmanagementService.getTagStructure();
                    }],

                    hasTagEdit: ['securityContext', function(securityContext){
                        return securityContext.hasPermission('TAG_EDIT');
                    }],

                    canAddNewTags: ['TagmanagementService', function(TagmanagementService){
                        return TagmanagementService.canAddNewTags();
                    }]
                },
                leftMenu : true
            });
    }])

    .controller('TagTreeViewController',['$scope','$stateParams','PubSubService','TagmanagementService','TreeService','$q','$state','ModalManagementService','WebsitePathGroupService','structureData','hasTagEdit','canAddNewTags','WebsitePathService',
        function TagTreeViewController($scope, $stateParams, PubSubService, TagmanagementService, TreeService, $q, $state,  ModalManagementService, WebsitePathGroupService, structureData, hasTagEdit, canAddNewTags, WebsitePathService) {

            function determineSelection (){
                if ( _.isEmpty($stateParams.type) ){
                    return {
                        type: 'paths'
                    };
                } else {
                    return {
                        type: $stateParams.type,
                        identifierParam: $stateParams.identifierParam
                    };
                }
            }

            $scope.structureData = structureData;
            $scope.hasTagEdit = hasTagEdit;
            $scope.canAddNewTags = canAddNewTags;
            // global tag path
            $scope.globalTagPath = TagmanagementService.GLOBAL_PATH;
            $scope.selection = determineSelection();

            $scope.$watch('selection.type', function(newValue, oldValue){
                if ( newValue === oldValue && !_.isUndefined(newValue)){
                    return;
                }

                var oldParams = $state.params;
                oldParams.type = newValue;

                if ( newValue === 'all' ) {
                    $state.go('site.tagmanagement.tags.all', oldParams, {
                        reload: true,
                        notify: true,
                        location: true
                    });
                } else {
                    $state.go('site.tagmanagement.tags.list', oldParams, {
                        reload: false,
                        notify: false,
                        location: false
                    });
                }
            });

            //onAddNode callback for adding paths to the tree
            $scope.addWebsitePath = TagmanagementService.addWebsitePathNode;

            //when dropping the copy button, activate the copy function
            $scope.onDrop = function(tagId, path) {

                var destination = path;
                var destinationType = $scope.selection.type;

                TagmanagementService.getTag(tagId).then(function(tagViewPojo) {
                    var newTagLocation = TagmanagementService.getNewTagLocationForDestination(destination, destinationType);
                    //fetch the existing tags at the location, so we don't assign a duplicate name

                    //any better ideas?
                    TagmanagementService.getTagsListForLocation(newTagLocation).then(function(result) {
                        //get all tags that exist at this location
                        var existingTagsAtLocation = result.pageResults;
                        //generate the tagCopy name based on the existing tag names in this location
                        var newTagName = TagmanagementService.getCopyName(tagViewPojo.tag.name, existingTagsAtLocation);
                        //create a tag copy with the generated name
                        TagmanagementService.copy(tagViewPojo.tag, newTagName, newTagLocation).then(function(newTagId) {
                            $state.transitionToStateWithSite('site.tagmanagement.tags.view', {
                                id: newTagId
                            });
                        });
                    });

                });
            };

            // A new WebsitePathGroup has been created/edited/deleted - refresh the list
            PubSubService.subscribeForType(PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH_GROUP, $scope, function() {
                TagmanagementService.getTagStructure().then(function(structureData) {
                    $scope.structureData.groups = structureData.groups;
                });
            });

            //subscribe to the change of the left menu in tagmanagement
            PubSubService.subscribeTagMenuLocationChanged($scope, function(event, data) {
                $scope.selection = data;
                var location = data.identifierParam;

                if ( data.type === 'paths' ) {

                    // set location to globalTagPath if undefined
                    location = location || TagmanagementService.GLOBAL_PATH;

                    // check if location exists, if not, open a modal to add it

                    if ( location !== TagmanagementService.GLOBAL_PATH && location.toLowerCase() !== TagmanagementService.GLOBAL_NAME.toLowerCase() ){
                        WebsitePathService.pathExists(location).then(function(res){
                            if ( res.pathExists === false ){
                                var modal = TagmanagementService.openNewPathModal($scope.structureData.paths, location, hasTagEdit);
                                modal.result.then(function(res) {
                                    if (res) {
                                        WebsitePathService.addWebsitePath(location).then(function() {
                                            TagmanagementService.getTagStructure().then(function(structur) {
                                                if (structur) {
                                                    $scope.structureData = structur;
                                                    TagmanagementService.activateTagTreeNode(location, $scope.structureData.paths);
                                                }
                                            });
                                        });
                                    } else {
                                        // Since the path was not added to the managed paths, it should not be possible to create new tags on this location
                                        // Thus publish an event that the path we stumbled upon was not added
                                        PubSubService.publishNewPathNotAdded(location);
                                    }
                                });
                            }
                        });
                    }
                    TagmanagementService.activateTagTreeNode(location, $scope.structureData.paths);
                }
            });

            $scope.updateSelectionType = function($event, type) {
                $scope.selection = {
                    type: type
                };
                $event.preventDefault();
            };

            $scope.newWebsitePathGroup = function() {

                var dialogsModel = function() {
                    return {
                        onSave: function(websitePathGroup) {
                            WebsitePathGroupService.addWebsitePathGroup(websitePathGroup).then(function(websitePathGroupId) {
                                //publish the new pathGroup
                                PubSubService.publishTypeCreated( PubSubService.TYPES.TAGMANAGEMENT.WEBSITE_PATH_GROUP, { id: websitePathGroupId } );
                                $state.transitionToStateWithSite('site.tagmanagement.tags.groupView', {groupId: websitePathGroupId});
                            });
                        }
                    };
                };

                ModalManagementService.openDialog(
                    'AddWebsitePathGroupDialogController',
                    'ui/tagmanagement/tags/websitePathGroups/add/add.tpl.html',
                    dialogsModel,
                    {'css': 'modal-small'}
                );
            };

            /**
     *  Depending on state (group/path) enable draggable of the tree
     *  @see draggable.js
     */
            $scope.$on('$stateChangeSuccess', function(event, state) {
                if (state.name === 'site.tagmanagement.tags.groupView') {
                    $scope.enableDraggable = true;
                } else {
                    $scope.enableDraggable = false;
                }
            });

        }]);
