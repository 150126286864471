import Api, { API_BASE, parseResponse } from '~/common/ApiService';

import { mapModel } from './util';
import { SnapshotType } from '../types';
import { PAGE_SIZE } from './constants';
import {
  AuditLogData,
  DeployModelFormValues,
  Model,
  ModelConfig,
  ModelDetail,
  ModelRestResponseType,
  ModelStats,
  ModelTableRowType,
} from './types';

const BASE_PATH = `${API_BASE}/v1/jai`;

export const fetchModels = (): Promise<ModelTableRowType[]> => Api.callGet(`${BASE_PATH}/models`).then(parseResponse);

export const fetchModelById = (modelId: string): Promise<ModelDetail> =>
  Api.callGet(`${BASE_PATH}/models/${modelId}`).then(parseResponse).then(mapModel);

export const deployModel = (
  modelId: string,
  deploymentSettings: DeployModelFormValues,
): Promise<ModelRestResponseType> =>
  Api.callPost(`${BASE_PATH}/models/${modelId}/deploy`, { deploymentSettings }).then(parseResponse);

export const trainModel = (modelId: string): Promise<ModelRestResponseType> =>
  Api.callPost(`${BASE_PATH}/models/${modelId}/train`, undefined, { shouldShowToast: false }).then(parseResponse);

export const createModel = (payload: Model): Promise<ModelRestResponseType> =>
  Api.callPost(`${BASE_PATH}/models`, payload).then(parseResponse);

export const updateModel = (payload: Model, modelId: string): Promise<ModelRestResponseType> =>
  Api.callPut(`${BASE_PATH}/models/${modelId}`, payload).then(parseResponse);

export const retractModel = (modelId: string): Promise<ModelRestResponseType> =>
  Api.callPost(`${BASE_PATH}/models/${modelId}/retract`, undefined, { shouldShowToast: false }).then(parseResponse);

export const deleteModel = (modelId: string): Promise<ModelRestResponseType> =>
  Api.callDelete(`${BASE_PATH}/models/${modelId}`, { shouldShowToast: false }).then(parseResponse);

export const fetchModelStatsById = (modelId: string): Promise<ModelStats | undefined> =>
  Api.callGet(`${BASE_PATH}/models/${modelId}/stats`).then(parseResponse);

// TODO: Update Fetch snapshots for models. (Post MVP)
// Route: /snapshots?status=FINISHED&includeProperties=false
export const fetchSnapshots = (): Promise<SnapshotType[]> => Api.callGet(`${BASE_PATH}/snapshots`).then(parseResponse);

export const fetchModelConfig = (): Promise<ModelConfig> =>
  Api.callGet(`${BASE_PATH}/models/config`).then(parseResponse);

export const fetchAuditLogByModelId = (modelId: string, pageIndex = 0, pageSize = PAGE_SIZE): Promise<AuditLogData> =>
  Api.callGet(`${BASE_PATH}/models/${modelId}/audittrail?page=${pageIndex}&size=${pageSize}`).then(parseResponse);
