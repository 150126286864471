import { get } from 'lodash';

const getIdentifierTypes = state =>
  get(state, 'profiles.connectorsForm.identifierTypes').map(type => ({ ...type, value: type.name }));
const getEndpoints = state =>
  get(state, 'profiles.connectorsForm.endpoints').map(type => ({ ...type, value: type.name }));
const fetchingIdentifierTypes = state => get(state, 'profiles.connectorsForm.promise.fetchingIdentifierTypes');
const fetchingEndpoints = state => get(state, 'profiles.connectorsForm.promise.fetchingEndpoints');

export { getIdentifierTypes, fetchingIdentifierTypes, getEndpoints, fetchingEndpoints };
