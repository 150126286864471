angular.module('webUi.ui.context.basescripts.base', ['webUi.ui.context.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.context.basescripts', {
                'abstract': true,
                url: '/basescripts',
                views : {
                    'mainContent@' : {
                        templateUrl: 'ui/context/basescripts/base/base.tpl.html'
                    }
                }
            });
    }]);

