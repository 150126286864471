import React, { useState } from 'react';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { getAngularService } from 'ReactAngular/react.service';
import Badge from '~/components/src/Badge';
import { flatten, groupBy } from 'lodash';
import RulesList, { RuleCardTypes, validateCriteria } from '~/components/src/FilterRuleList';
import FilterRuleControls from '~/profiles/components/FilterRuleControls';
import { RulesTypes } from '~/common/modules/filterRules/types';
import { Tabs, Tab, TabList } from '~/components/src/Tabs';

import { RULE_DEFAULT_FIXED_WAIT_TIME, canBeInverted } from '~/common/modules/filterRules/selectors';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import { getMandatoryProperties, mapFiltersForDynamicTimeTrigger } from './utils';

const makeNewRule = (ruleId, clickedRuleDescription, ruleTypesTree) => {
  const { ruleDefinitionId, type, title: clickedItemTitle, availableFilters, extra } = clickedRuleDescription;
  const { title, typeLabel } = getTitleAndTypeLabel(type, clickedItemTitle);

  const newRule = {
    ruleId,
    ruleDefinitionId,
    type,
    title,
    typeLabel,
    groupName: ruleTypesTree.find(({ type: ruleType }) => ruleType === type)?.groupName,
  };

  if (type === RulesTypes.FixedTime) {
    newRule.waitTime = RULE_DEFAULT_FIXED_WAIT_TIME;
  }

  if (type === RulesTypes.DynamicWaitTime) {
    // Assign all filters to the Dynamic time rule. It should have one property
    newRule.filters = mapFiltersForDynamicTimeTrigger(availableFilters || []);
  }

  if (type === RulesTypes.JourneyOverlap) {
    newRule.dependantJourneyId = extra.dependantJourneyId;
  }

  if (canBeInverted(type)) {
    newRule.negation = false;
  }

  const mandatoryProperties = getMandatoryProperties(availableFilters);

  if (mandatoryProperties.length) {
    newRule.filters = mandatoryProperties;
  }

  return newRule;
};

const renderTabs = (criteria, setSelectedHeader, isValid = true) => {
  // Flatten the array to make grouping easier
  const flattenedCriteria = flatten(criteria);
  const totalCount = flattenedCriteria.length;
  // Group by `groupName`
  const groupedCriteria = groupBy(flattenedCriteria, 'groupName');

  const headerTitles = Object.keys(groupedCriteria);
  return (
    <Tabs className="mb-5">
      <TabList>
        {['All', ...headerTitles]?.map(item => (
          <Tab
            testHook="eventsTab"
            onClick={() => setSelectedHeader(item)}
            key={item}
            hasError={item === 'All' && !isValid}
          >
            {item}
            <Badge content={item === 'All' ? totalCount : groupedCriteria[item]?.length} className="mx-2" />
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

const EditableRulesList = ({
  criteria,
  ruleTypesTree,
  onChange,
  isSubmitted,
  isUsedBySegment,
  audienceId,
  type,
  bindFormikForm,
  enableGroupBy,
  ruleCardType = RuleCardTypes.EditCard,
  isValid = true,
}) => {
  const [selectedHeader, setSelectedHeader] = useState('All');

  const addCriteria = clickedRuleDescription => {
    const nextCriteriaList = [...criteria];
    clickedRuleDescription.forEach(element => {
      const UUIDService = getAngularService(document, 'UUIDService');
      const ruleId = UUIDService.newId();
      const newRule = makeNewRule(ruleId, element, ruleTypesTree);
      nextCriteriaList.push([newRule]);
    });
    onChange({ criteria: nextCriteriaList, isValid: validateCriteria(nextCriteriaList, true) });
  };

  return (
    <>
      <div>
        {ruleCardType === RuleCardTypes.EditCard && (
          <FilterRuleControls
            enableGroupBy={enableGroupBy}
            onCriteriaSelect={addCriteria}
            criteria={criteria}
            ruleTypesTree={ruleTypesTree}
            isUsedBySegment={isUsedBySegment}
            isOrButtonHidden={true}
            audienceId={audienceId}
          />
        )}
        {criteria.length > 0 && renderTabs(criteria, setSelectedHeader, isValid)}

        <RulesList
          criteria={criteria}
          selectedHeader={selectedHeader}
          ruleTypesTree={ruleTypesTree}
          ruleCardType={ruleCardType}
          onChange={onChange}
          addCriteria={addCriteria}
          isSubmitted={isSubmitted}
          type={type}
          bindFormikForm={bindFormikForm}
          enableGroupBy={enableGroupBy}
        />
      </div>
    </>
  );
};

export default compose(translate('audiences'))(EditableRulesList);
