// Module
var code = ` <span class="dynatree-node {{node.addClass}}" data-ng-init="initializeNode(node);" data-ng-class="{
		'dynatree-folder' : node.children.length>0,
		'dynatree-has-children' : node.children.length>0,
		'dynatree-ico-ef' : node.children.length>0,
		'dynatree-expanded' : node.expanded,
		'dynatree-nosubmatch' : filter && !TreeService.isSubmatch(node, filter),
		'dynatree-active' : node.active,
		'dynatree-focused' : node.active,
		'dynatree-exp-e' : node.expanded,
		'dynatree-exp-c' : !node.expanded,
		'dynatree-ico-ef' : node.expanded && node.children.length>0,
		'dynatree-ico-cf' : !node.expanded && node.children.length>0,
		'dynatree-lastsib' : node.collectionInfo.last,
		'dynatree-ico-c' : isEmpty(node.children),
		'sv-dynatree-invisible': !TreeService.isVisible(node, filter)
	}"> <span style="display:inline-block" data-ng-click="toggleNodeExpand(node);" data-ng-class="{
				'dynatree-connector' : isEmpty(node.children),
				'dynatree-expander' : node.children.length>0
			}"> </span> <span class="r42-icon-large" data-ng-class="getNodeIcon(node)" data-ng-click="TreeService.activateNode(node, nodes);"></span> <a data-ng-if="node.sref" data-ui-sref="{{node.sref}}" data-on-hover="node.expanded = true;" data-name="{{node.name}}" data-ng-click="TreeService.activateNode(node, nodes);" data-ng-bind="node.name"></a> <span data-ng-if="!node.sref" class="sv-dynatree-href-replacement" data-name="{{node.name}}" data-ng-click="toggleNodeExpand(node);" data-ng-bind="node.name"></span> </span> <ul class="dynatree-container" data-ng-init="parentNode = node"> <li data-ng-init="assignParent(node, parentNode)" data-ng-if="parentNode.expanded" data-ng-repeat="node in node.children | orderBy:'name'" data-ng-include data-src="'directive/treeSimple/simpleTreeNode.tpl.html'"></li> <li data-ng-if="treeEditable && node.canAdd" data-ng-init="treeStateAddNew = {active : false};" class="dynatree-add-external"> <span data-ng-if="!treeStateAddNew.active" data-ng-click="addNodeCallback(node)"> <i class="r42-plus"></i><span class="dynatree-add-label">Add campaign</span> </span> </li> </ul> `;
// Exports
var _module_exports =code;;
var path = 'directive/treeSimple/simpleTreeNode.tpl.html';
window.angular.module('app-templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;