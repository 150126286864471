import { ValidationError } from 'yup';
import { groupBy } from 'lodash';
import { Transformer, TransformerPayload, ValueTransformation, ValidationErrorsByPath } from './transformerTypes';
import { transformationTypes, mappingPropertyTypes } from './constants';

export const withChangeHandler = (methods, onChange) =>
  Object.keys(methods).reduce(
    (methodsWithChangeHandler, methodName) => ({
      ...methodsWithChangeHandler,
      [methodName]: (...args: any[]) => {
        const returnValue = methods[methodName](...args);
        onChange();
        return returnValue;
      },
    }),
    {},
  );

export const removeEmptyValueTransformations = (valueTransformations: ValueTransformation[]): ValueTransformation[] =>
  valueTransformations?.filter(({ transformationType }) => transformationType !== '') || [];

export const transformerToPayload = (transformer: Transformer): TransformerPayload => {
  const eventTransformations = transformer.events.map(event => ({
    eventName: event.name,
    eventType: event.type,
    mappings: event.mappings.map(mapping => {
      const sourceProperty = mapping.allowsCustomSources
        ? {
            propertyType: mappingPropertyTypes.EVENT_SOURCE_PROPERTY,
            sourceName: mapping.sourceName,
          }
        : {
            propertyType: mappingPropertyTypes.STATIC_SOURCE_PROPERTY,
            sourceValue: mapping.sourceName,
          };

      return {
        sourceName: mapping.sourceName, // this field will be deprecated later and is left in for backwards compatibility,
        sourceProperty,
        destinationName: mapping.destination.name,
        required: mapping.required,
        transformations: removeEmptyValueTransformations(mapping.valueTransformations).map(valueTransformation => {
          if (valueTransformation.transformationType === transformationTypes.EPOCH_SECONDS_TO_DATE) {
            // Do not include empty offset into payload
            const { offset, ...otherFields } = valueTransformation.fields;

            if (offset === '') {
              return {
                transformationType: transformationTypes.EPOCH_SECONDS_TO_DATE,
                ...otherFields,
              };
            }
          }

          return {
            transformationType: valueTransformation.transformationType,
            ...valueTransformation.fields,
          };
        }),
      };
    }),
  }));

  return {
    configuration: {
      eventTransformations,
    },
  };
};

export const validate = (objectToValidate: any, schema: any): ValidationErrorsByPath => {
  try {
    schema.validateSync(objectToValidate, { abortEarly: false });
    return {};
  } catch (error) {
    const validationError = error as ValidationError;
    const validationErrorsByPath = groupBy(validationError.inner, 'path');

    return validationErrorsByPath;
  }
};
