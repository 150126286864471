import { get } from 'lodash';
import { getDateAndTime } from '~/common/utils/DateUtil';

/**
 * Get Message By Id
 * @param state
 * @param id
 * @returns {*}
 */
export const getMessageById = (state, id) => {
  const message = get(state, `support.messages.byId[${id}]`);
  return message
    ? {
        ...message,
        showDate: getDateAndTime(message.showDate),
        hideDate: getDateAndTime(message.hideDate),
      }
    : null;
};

/**
 * Get All Messages
 * @param state
 */
export const getMessages = state =>
  state.support.messages.allIds.map(id => {
    const message = state.support.messages.byId[id];
    return message
      ? {
          ...message,
          showDate: getDateAndTime(message.showDate),
          hideDate: getDateAndTime(message.hideDate),
        }
      : null;
  });

/**
 * Should fetch all messages?
 * @param state
 */
export const shouldFetchMessages = state => get(state, 'support.messages.ui.shouldFetchAll');

/**
 * Is fetching all messages currently in progress?
 * @param state
 */
export const isFetchingMessages = state => get(state, 'support.messages.ui.isFetchingAll');
