angular.module('webUi.ui.content.campaigns.ads.creatives.form', [
    'webUi.ui.content.campaigns.base',
    'ui.bootstrap'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.content.campaigns.ads.creatives.form', {
                url: '/form/{adId}',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/content/campaigns/ads/creatives/form/form.tpl.html',
                        controller: 'AdservingCreativeFormController'
                    }
                },
                leftMenu: true,
                resolve: {
                    ad: ['$stateParams', 'AdService', function ($stateParams, AdService) {
                        return AdService.getAd($stateParams.adId);
                    }],
                    creative: ['CreativeService', 'ad', function (CreativeService, ad) {
                        // No creative to resolve. We are creating a new one
                        return CreativeService.initDefaultCreative(ad);
                    }],
                    dimensions: ['CreativeService', 'ad', function (CreativeService, ad) {
                        return CreativeService.getCreativeDimensions(ad.campaignId, ad.adId);
                    }],
                    mediaLibrary: ['MediaLibraryService', 'ad', function (MediaLibraryService, ad) {
                        if (!_.isNull(ad.mediaLibrary)) {
                            return MediaLibraryService.getMediaLibrary(ad.mediaLibrary.mediaLibraryId);
                        }
                    }],
                    clickTrackerPlaceholders: ['CreativeService', function (CreativeService) {
                        return CreativeService.getClickTrackerPlaceholders();
                    }],
                    profileVariables: ['VariableService', 'hasDataManagementModule', function (VariableService, hasDataManagementModule) {
                        if (hasDataManagementModule) {
                            return VariableService.getVariables();
                        }
                        return [];
                    }],
                    campaign: ['CampaignService', 'ad', function (CampaignService, ad) {
                        return CampaignService.getCampaign(ad.campaignId);
                    }],
                    segments: ['SegmentService', 'hasDataManagementModule', 'hasAudiencesView', function (SegmentService, hasDataManagementModule, hasAudiencesView) {
                        if (hasDataManagementModule && hasAudiencesView) {
                            return SegmentService.getSegments();
                        }
                        return [];
                    }]
                }
            })

            .state('site.content.campaigns.ads.creatives.form.edit', {
                url: '/edit/{creativeId}',
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/content/campaigns/ads/creatives/form/form.tpl.html',
                        controller: 'AdservingCreativeFormController'
                    }
                },
                leftMenu: true,
                resolve: {
                    creative: ['$stateParams', 'CreativeService', function ($stateParams, CreativeService) {
                        return CreativeService.getCreative($stateParams.adId, $stateParams.creativeId);
                    }]
                }
            });
    }])

    .controller('AdservingCreativeFormController', ['$scope', '$state', '$stateParams', '$uibModal', 'CreativeService', 'CreativePreviewService', 'CodeMirrorService', 'ValidService', 'dimensions', 'ad', 'creative', 'mediaLibrary', 'campaign', 'clickTrackerPlaceholders',
        'profileVariables', 'hasDataManagementModule', 'hasAdservingEdit', 'segments',
        function AdservingCreativeFormController($scope, $state, $stateParams, $uibModal, CreativeService, CreativePreviewService, CodeMirrorService, ValidService, dimensions, ad, creative, mediaLibrary, campaign, clickTrackerPlaceholders,
            profileVariables, hasDataManagementModule, hasAdservingEdit, segments) {


            $scope.ad = ad;
            $scope.creative = creative;
            $scope.campaign = campaign;
            $scope.dimensions = dimensions;
            $scope.mediaLibrary = mediaLibrary;

            $scope.clickTrackerPlaceholders = clickTrackerPlaceholders;
            $scope.profileVariables = profileVariables;
            $scope.placementParameters = campaign.placementParameters;

            $scope.hasDataManagementModule = hasDataManagementModule;
            $scope.hasAdservingEdit = hasAdservingEdit;
            $scope.segments = segments;

            /**
             * Opens the creative preview popup
             * @param {type} ad
             * @param {type} creative
             * @param {boolean} hasDataManagementModule
             * @returns {undefined}
             */
            var previousPreviewState;

            $scope.preview = function (activatedByHotkey) {

                CreativePreviewService
                    .handlePreview(ad, creative, hasDataManagementModule, previousPreviewState, activatedByHotkey)
                    .then(function (res) {
                        // keep track of previous data keys
                        if (res) {
                            previousPreviewState = res;
                        }

                        // restore cursor for HTML ad
                        if (_.isEqual(creative.content.clazz, 'HtmlCreativeContent')) {
                            var codemirrorInstance = $('#codemirror-creative-container').data('codemirrorInstance');
                            if (codemirrorInstance) {
                                codemirrorInstance.focus();
                            }
                        }
                    });
            };


            $scope.$on('hotKeyEvent', function (event, data) {
                if (data.ctrlKey) {
                    if (data.charLower === 'p') {
                        $scope.preview(true);
                    }
                }
            });

            $scope.showMediaFileChooser = function (mediaLibrary, filter, modelObj, modelObjName) {
                var dialog = $uibModal.open({
                    templateUrl: 'ui/content/campaigns/ads/creatives/creativeContent/creativeMediaFileChooser.tpl.html',
                    controller: 'MediaFileChooserController',
                    resolve: {
                        dialogsModel: function () {
                            return {
                                mediaLibrary: mediaLibrary,
                                filter: filter
                            };
                        }
                    }
                });
                dialog.result.then(function (result) {
                    if (result) {

                        // if modelObj is given set result on modelObj
                        if (modelObj) {
                            modelObj[modelObjName] = result.path;
                            // else add to codemirror instance
                        } else {
                            var codemirrorInstance = $('#codemirror-creative-container').data('codemirrorInstance');
                            //fetch the imported tag based on the file name (js/img only at the moment)
                            CodeMirrorService.insertText(codemirrorInstance, CreativeService.getImportTagForCodeMirror(result.path), {atomic: false});
                        }

                    }
                });
            };

            /**
             * Sets the dimension of a creative
             *
             * @param {type} dimension
             * @returns {undefined}
             */
            $scope.selectDimension = function (dimension) {
                $scope.creative.width = dimension.width;
                $scope.creative.height = dimension.height;
            };

            $scope.injectDataFeed = function injectDataFeed(ad) {
                var value = CreativeService.getDataFeedPlaceholder(ad);
                $scope.injectPlaceholder(value);
            };

            $scope.injectProfileVar = function injectProfileVar(profileVar) {
                var value = CreativeService.getProfileVarPlaceholder(profileVar);
                $scope.injectPlaceholder(value, {atomic: false});
            };

            $scope.injectPlacementParam = function injectPlacementParam(placementParam) {
                var value = CreativeService.getPlacementParameterPlaceholder(placementParam);
                $scope.injectPlaceholder(value);
            };

            $scope.injectPlaceholder = function (placeholderValue, overrideOptions) {
                if (!_.isEmpty(placeholderValue)) {
                    var codemirrorInstance = $('#codemirror-creative-container').data('codemirrorInstance');
                    CodeMirrorService.insertText(codemirrorInstance, placeholderValue, overrideOptions);
                }
            };

            /**
             * Saves/updates the creative
             * @param {type} creative
             * @returns {undefined}
             */
            $scope.submit = function (creative) {

                if (!creative.creativeId) { //save new
                    CreativeService.addCreative(creative).then(function () {
                        $state.transitionToStateWithSite('site.content.campaigns.ads.view', {adId: $stateParams.adId});
                    });
                } else { //update existing
                    CreativeService.updateCreative(creative).then(function () {
                        $state.transitionToStateWithSite('site.content.campaigns.ads.view', {adId: creative.adId});
                    });
                }
            };

            /**
             * Custom validation functions
             */
            $scope.validCreativeSizes = function (c) {
                if (ValidService.isEmpty(c) || ValidService.isEmpty(c.height) || ValidService.isEmpty(c.width)) {
                    return false;
                }
                return ValidService.isPositiveNumber(c.width) && ValidService.isPositiveNumber(c.height);

            };

            $scope.creativeExists = function (creative) {
                if (_.isUndefined(_.find(ad.creatives, function (c) {
                    return c.width === _.parseInt(creative.width) && c.height === _.parseInt(creative.height) && c.creativeId !== creative.creativeId;
                }))) {
                    return true;
                }

                // not found, so ok
                return false;
            };

            $scope.isValidProtocol = function (urlString) {
                var httpsStr = 'https://';
                var httpStr = 'http://';
                //urlString.slice(0, httpsStr.length) == httpsStr || urlString.slice(0, httpStr.length) == httpStr
                if (urlString === null || urlString.slice(0, httpsStr.length) === httpsStr || urlString.slice(0, httpStr.length) === httpStr) {
                    return true;
                }
                return false;

            };

        }]);
