import { get } from 'lodash';
import { changeUrl } from '~/common';
import * as fromModals from '~/modals';
import * as types from './types';
import * as selectors from './selectors';
import SupportTagsService from './dataService';

/**
 * Fetch variant
 * @param variantId
 * @param sequenceNr
 */
export const fetchVariant = (variantId, sequenceNr) => dispatch => {
  const apiCall = sequenceNr === undefined ? SupportTagsService.getVariant : SupportTagsService.getVariantWithVersion;

  dispatch({
    type: types.VARIANT_FETCH_START,
    payload: variantId,
  });

  return apiCall({ variantId, sequenceNr })
    .then(payload => {
      dispatch({
        type: types.VARIANT_FETCH_SUCCESS,
        payload,
        sequenceNr,
      });
    })
    .catch(error => dispatch({ type: types.VARIANT_FETCH_FAIL, error }));
};

/**
 * Fetch variant only if not in store
 * @param variantId
 * @param sequenceNr
 */
export const fetchVariantIfNeeded = (variantId, sequenceNr) => (dispatch, getState) => {
  if (selectors.getVariantVersionById(getState(), variantId, sequenceNr)) {
    return Promise.resolve();
  }
  return dispatch(fetchVariant(variantId, sequenceNr));
};

/**
 * Delete variant
 * @param variant
 */
export const deleteVariant = variant => dispatch => {
  const { variantId, sequenceNr, templateId } = variant;

  dispatch({
    type: types.DELETE_START,
    payload: { variantId, sequenceNr },
  });

  // Remove Message on the backend
  return SupportTagsService.deleteVariant(variantId, sequenceNr)
    .then(() => {
      // Remove Message from Store
      dispatch({
        type: types.DELETE_SUCCESS,
        payload: { variantId, sequenceNr },
      });

      // Go to Message List
      changeUrl(`support/tagtemplates/templates/view/${templateId}`);
    })
    .catch(error => dispatch({ type: types.DELETE_FAIL, error }));
};

export const lockVariant = variant => dispatch => {
  const { variantId, sequenceNr, locked } = variant;

  const payload = {
    variantId,
    sequenceNr,
    locked: !locked,
  };

  dispatch({
    type: types.LOCK_START,
    payload,
  });

  return SupportTagsService.toggleLock(payload)
    .then(() => {
      dispatch({
        type: types.LOCK_SUCCESS,
        payload,
      });
    })
    .catch(error => dispatch({ type: types.LOCK_FAIL, error }));
};

/**
 * Save variant
 * Updates existing variant or creates a new one if there is no variantId in saving object
 * @param variant
 */
export const saveVariant = variant => dispatch => {
  const payload = {
    ...variant,
    templateEditStatic: variant.pixelEnabled ? variant.templateEditStatic : null,
  };

  const model = {
    ...payload,
    executionType: get(variant, 'executionType.id') || get(variant, 'executionType.name'),
    siteModule: get(variant, 'siteModule.id') || get(variant, 'siteModule.name'),
  };

  const apiCall = variant.variantId ? SupportTagsService.updateVariant : SupportTagsService.saveVariant;

  dispatch({
    type: types.SAVE_START,
    payload,
  });

  // Save Variant on the backend
  return apiCall(model)
    .then(data => {
      dispatch({
        type: types.SAVE_SUCCESS,
        payload: {
          ...payload,
          variantId: data.variantId,
          sequenceNr: data.sequenceNr,
        },
      });

      // Go to the message view
      changeUrl(`support/tagtemplates/variants/view/${data.variantId}?sequenceNr=${data.sequenceNr}`);
    })
    .catch(error => dispatch({ type: types.SAVE_FAIL, error }));
};

/**
 * Fetch Tag Execution Types
 */
export const fetchTagExecutionTypes = () => dispatch => {
  dispatch({
    type: types.EXECUTION_TYPES_FETCH_START,
  });

  return SupportTagsService.getTagExecutionTypes()
    .then(payload => {
      dispatch({
        type: types.EXECUTION_TYPES_FETCH_SUCCESS,
        payload: payload.map(item => ({
          value: { id: item.name, label: item.label },
          label: item.label,
        })),
      });
    })
    .catch(error => dispatch({ type: types.EXECUTION_TYPES_FETCH_FAIL, error }));
};

/**
 * Fetch Tag Execution Types if not in store
 */
export const fetchTagExecutionTypesIfNeeded = () => (dispatch, getState) => {
  if (selectors.getVariantExecutionTypes(getState()).length) {
    return Promise.resolve();
  }
  return dispatch(fetchTagExecutionTypes());
};

/**
 * Fetch all variant history versions
 * @param variantId
 */
export const fetchVariantHistory = variantId => dispatch => {
  dispatch({
    type: types.VARIANT_HISTORY_FETCH_START,
    payload: variantId,
  });

  return SupportTagsService.getVariantHistory(variantId)
    .then(list => {
      dispatch({
        type: types.VARIANT_HISTORY_FETCH_SUCCESS,
        payload: {
          variantId,
          list,
        },
      });
    })
    .catch(error => dispatch({ type: types.VARIANT_HISTORY_FETCH_FAIL, error }));
};

/**
 * Redirect to variant historic view
 * @param variant
 */
export const showVariant = variant => dispatch => {
  changeUrl(`support/tagtemplates/variants/view/${variant.variantId}/history/${variant.sequenceNr}`);
  dispatch(fromModals.hideModal());
};
