import { buildAssetsUrl } from '~/common/history';
import { getSiteId } from '~/common/SecurityMetaService';
import constants from '~/profiles/connectors/form/constants';

export const isCustomConnector = partnerType => partnerType === constants.partnerTypes.CUSTOM;
export const isDIYConnector = partnerType => partnerType === constants.partnerTypes.DIY;

const getCustomLogoUrl = (siteId, id, partnerType, isConnectorId) => {
  if (!id) return null;

  let path = null;
  if (isCustomConnector(partnerType)) {
    path = `/api/v1/partners/custom/${siteId}/${id}/logo`;
  } else if (isDIYConnector(partnerType)) {
    // When we use connectorId for getting logos, "partnerType=DIY" is required
    path = `/api/v1/eventConnectors/${siteId}/${id}/logo${isConnectorId ? '?partnerType=DIY' : ''}`;
  }
  return path;
};

const getPartnerConnectorUrl = partnerType => (partnerType ? buildAssetsUrl(`img/partners/${partnerType}.png`) : null);

export const getConnectorLogoUrl = (id, partnerType, isConnectorId = false) => {
  const siteId = getSiteId();
  if (isCustomConnector(partnerType) || isDIYConnector(partnerType)) {
    return getCustomLogoUrl(siteId, id, partnerType, isConnectorId);
  }
  return getPartnerConnectorUrl(partnerType);
};
