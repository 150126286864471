import React, { useState, ReactElement } from 'react';
import { components, OptionProps } from 'react-select';
import _ from 'lodash';
import cx from 'classnames';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import i18n from '~/i18n';
import BtnIcon from '../BtnIcon';
import './style.scss';

interface Label {
  canDelete?: boolean;
  id: string;
  name: string;
}

interface LabelSelectProps {
  labels: string[];
  options: Label[];
  journeyId: string;
  updateJourneyLabels: (journeyId: string, labels: string[]) => void;
  isUpdatingLabels?: boolean;
  onDeleteLabel?: (labelId: string) => void;
  testHook?: string;
}

const LabelSelect = ({
  labels,
  options,
  updateJourneyLabels,
  journeyId,
  isUpdatingLabels,
  onDeleteLabel,
  testHook,
}: LabelSelectProps): ReactElement => {
  const [rowLabels, setRowLabels] = useState(
    labels?.map(label => ({ label, value: label?.toLowerCase()?.replace(/\W/g, '') })) || [],
  );

  const labelOptions = options.map(option => ({
    label: option?.name,
    value: option?.name?.toLowerCase()?.replace(/\W/g, ''),
    canDelete: option?.canDelete,
    id: option?.id,
  }));

  const updateLabels = () => {
    const updatedArray = labels;
    const newArray = rowLabels?.map(row => row.label);
    const isEqual = _.isEqual(_.sortBy(updatedArray), _.sortBy(newArray));
    if (!isEqual) {
      updateJourneyLabels(journeyId, newArray || []);
    }
  };

  const validateNewOption = (inputValue: string) =>
    inputValue !== '' &&
    inputValue.length < 50 &&
    !labelOptions.some(l => l.label.toLocaleLowerCase() === inputValue.toLocaleLowerCase());

  const Option = (props: OptionProps<any>) => {
    const {
      data: { canDelete, id },
    } = props;
    return (
      <components.Option {...props}>
        <div className={cx('flex items-center justify-between', `t-${testHook}-option`)}>
          <div>{props.children}</div>
          <BtnIcon
            icon="delete"
            className="h-6 w-6"
            disabled={!canDelete}
            onClick={e => {
              e.stopPropagation();
              onDeleteLabel?.(id || '');
            }}
            tooltip={i18n.t(`workflow:label.${canDelete ? 'delete' : 'isUsed'}`)}
            testHook="deleteLabel"
          />
        </div>
      </components.Option>
    );
  };

  return (
    <SelectElement
      isMulti
      isClearable
      isSearchable
      isCreatable
      options={labelOptions}
      classNamePrefix="labelSelect"
      value={rowLabels}
      placeholder=""
      onChange={option => {
        setRowLabels(option || []);
      }}
      onBlur={updateLabels}
      components={{
        ...(onDeleteLabel ? { Option } : {}),
        ClearIndicator: null,
      }}
      isValidNewOption={validateNewOption}
      noOptionsMessage={() => i18n.t('journey:labels.empty')}
      closeMenuOnSelect={false}
      menuPlacement="auto"
      maxMenuHeight={140}
      isLoading={isUpdatingLabels}
      formatCreateLabel={(label: string) => `New label (Max 50 chars): ${label}`}
      testHook={testHook}
    />
  );
};

export default LabelSelect;
