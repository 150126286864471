import { combineReducers } from 'redux';
import * as types from './types';

const defaultAction = {
  payload: {},
};

/**
 * Collectors Objects
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action = defaultAction) => {
  switch (action.type) {
    case types.COLLECTORS_FETCH_SUCCESS: {
      const nextState = { ...state };
      action.payload.forEach(collector => {
        nextState[collector.collectorId] = collector;
      });
      return nextState;
    }
    case types.COLLECTORS_SAVE_SUCCESS:
      return {
        ...state,
        [action.payload.collectorId]: action.payload,
      };
    case types.COLLECTORS_DELETE_SUCCESS: {
      const nextState = { ...state };
      delete nextState[action.payload];
      return nextState;
    }
    default:
      return state;
  }
};

/**
 * Collectors Code Sample
 * @param state
 * @param action
 * @returns {{}}
 */
const codeSampleById = (state = {}, action = defaultAction) => {
  switch (action.type) {
    case types.CODE_FETCH_SUCCESS: {
      const nextState = { ...state };
      nextState[action.payload.collectorId] = { ...action.payload.code };
      return nextState;
    }
    default:
      return state;
  }
};

/**
 * Collectors Look Up Table
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = [], action = defaultAction) => {
  switch (action.type) {
    case types.COLLECTORS_FETCH_SUCCESS: {
      const nextState = [...state];
      action.payload.forEach(collector => {
        if (state.indexOf(collector.collectorId) === -1) {
          nextState.push(collector.collectorId);
        }
      });
      return nextState;
    }
    case types.COLLECTORS_SAVE_SUCCESS:
      return [...state, action.payload.collectorId];
    case types.COLLECTORS_DELETE_SUCCESS:
      return state.filter(id => id !== action.payload);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  error: null,
};
const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.COLLECTORS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.COLLECTORS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    default:
      return state;
  }
};

const adminVariablesReducer = combineReducers({
  byId,
  allIds,
  codeSampleById,
  ui,
});

export default adminVariablesReducer;
