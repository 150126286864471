angular.module('webUi.ui.support.messages.list', ['webUi.ui.support.messages.base'])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.support.messages.list', {
                url: '/list',
                isReact: true,
                views : {
                    'mainContent@' : {
                        controller: 'SupportMessagesListController'
                    }
                }
            });
    }])

    .controller('SupportMessagesListController', ['$scope', '$location',
        function SupportMessagesListController($scope, $location) {
            $scope.$on('hotKeyEvent', function(event, data) {
                // activate newMessage on n
                if(!data.altKey && data.charLower === 'n') {
                    $location.path($scope.prefix + '/support/messages/add');
                }
            });
        }
    ]);
