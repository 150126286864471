import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { buildUrl } from '~/common/history';
import Heading from '~/components/src/Heading';
import Page from '~/components/src/Page';
import Spinner from '~/components/src/Spinner';
import { Panel, MediumWrapper } from '~/components/src/Containers';
import { withRouter } from '~/common/withRouter';
import { fetchMessageIfNeeded, saveMessage } from '../messages/actions';
import * as selectors from '../messages/selectors';
import { getClients } from '../helpers/clientsHelper';
import MessageForm from '../components/MessageForm';

export class EditMessagePage extends Component {
  constructor(props) {
    super(props);
    this.state = { clients: [] };
  }

  componentDidMount() {
    const { fetchMessage, id } = this.props;
    // AngularJS Service, not migrated to redux yet
    getClients(document).then(clients => {
      this.setState({
        clients,
      });
    });
    fetchMessage(id);
  }

  render() {
    const { props, state } = this;

    if (!props.initialValues) {
      return <Spinner />;
    }

    return (
      <Page>
        <MediumWrapper>
          <Heading title={props.t('edit.header')} />
          <Panel>
            <MessageForm
              backHref={buildUrl(`support/messages/view/${props.initialValues.messageId}`)}
              clients={state.clients}
              {...props}
            />
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, ownProps) => ({
      id: ownProps?.params.messageId,
      initialValues: selectors.getMessageById(state, ownProps?.params.messageId),
    }),
    dispatch => ({
      fetchMessage: id => dispatch(fetchMessageIfNeeded(id)),
      onSubmit: values => dispatch(saveMessage(values)),
    }),
  ),
  reduxForm({
    form: 'messageEdit',
    touchOnChange: true,
    touchOnBlur: true,
  }),
  translate('messages'),
)(EditMessagePage);
